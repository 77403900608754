<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h3 class="content-header-title float-left mb-2">Issue Management</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link :to="{ name: 'IssueList', params: { search: true }}"
                    >Issue Management List</router-link
                    >
                  </li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Issue</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card border-warning" >
            <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
                <div class="card-header py-1 px-2" style="background-color: #f1f2f3;">
                    <h3 class="card-title">
                        Create Issue
                    </h3>
                </div>
              <div class="card-body pt-1">
                  <div v-if="this.isRedirectedFromFunc" class="mb-1" >
                      <div class="table-responsive" style="overflow-x: inherit !important;">
                          <table class="table table-sm dataTableListing table-hover table-striped table-bordered" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center">Project Name</th>
                                      <th scope="col" class="text-center">Functionality Name</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td class="text-center">{{this.view.projectname}}</td>
                                      <td class="text-center">{{this.view.functionlityname}}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div v-if="this.isRedirectedFromBtmView" class="mb-1" >
                      <div class="table-responsive" style="overflow-x: inherit !important;">
                          <table class="table table-sm dataTableListing table-hover table-striped table-bordered" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center" v-if="!this.business.haveMultProj">Project Name</th>
                                      <th scope="col" class="text-center">Business Name</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td class="text-center" v-if="!this.business.haveMultProj">{{this.business.btmprojname}}</td>
                                      <td class="text-center"><span v-if="this.view.businessname">{{this.view.businessname}}</span><span v-else>NA</span></td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <form class="form form-vertical">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group mb-1">
                              <label for="projectlistdd">Issue Name</label>
                                  <input type="text" id="issuename" class="form-control" name="" placeholder="Issue Name" v-model="issue.issuename" @blur="validateForm('t42',$event)"
                              v-bind:class="{ 
                                  'form-control': true,
                                  '': !validText(issue.issuename) && issuenameBlured, }"
                                  v-on:blur="issuenameBlured = true"/>
                                  <div class="errorinputmsg" v-if="!validText(issue.issuename) && issuenameBlured"> Issue name must be length of 5 at least and only letters are allowed</div>
                              </div>
                          </div>
                          <div class="col-md-6" v-if="(!this.isRedirectedFromFunc && !this.isRedirectedFromBtmView) || (this.isRedirectedFromBtmView && this.business.haveMultProj)">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Project Name</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="issue.project" :options="issue.projectlist" @input="selissuecsproject" @close="nullifyFuncTask"/>
                              <div class="errorinputmsg" v-if="this.errors.issue.project">{{ this.errors.issue.project }}</div>
                              </div>
                          </div>
                          <div class="col-md-6" v-if="!this.isRedirectedFromFunc && !this.isThisBusinessRole">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Functionality</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select functionality name" v-model="issue.functionality" :options="issue.functionalitylist" @input="selissuecsfunctionality" @close="validateForm('t42',$event)"/>
                              </div>
                          </div>
                          <div class="col-md-6" v-if="!this.isThisBusinessRole">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Micro Task Name</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 4 chars to search micro task" v-model="issue.task" :options="issue.tasklist" @close="validateForm('t42',$event)" v-on:search-change="taskChange"/>
                              </div>
                          </div>
                          <div class="col-md-6" v-if="!this.isRedirectedFromBtmView">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Business Task Code</label>
                              <treeselect class="capitalisetext" placeholder="Enter Business Task Code" :disabled="isBtmFieldDisabled" v-model="issue.businesstaskcode" :options="issue.businesscodelist" @input="selbtmcode" @close="validateForm('t47',$event)"/>
                              <div class="errorinputmsg" v-if="(this.errors.issue.businesstaskcode) && !isBtmFieldDisabled">{{ this.errors.issue.businesstaskcode }}</div>
                              </div>
                          </div>
                          <div class="col-md-6" v-if="!this.isRedirectedFromBtmView">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Business Task Name</label>
                              <treeselect class="capitalisetext" placeholder="Enter Business Task Name" :disabled="isBtmFieldDisable" v-model="issue.businesstaskid" :options="issue.businesstasklist" @input="selbtmid" @close="validateForm('t47',$event)"/>
                              <div class="errorinputmsg" v-if="(this.errors.issue.businesstaskid) && !isBtmFieldDisable">{{ this.errors.issue.businesstaskid }}</div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Issue Type</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Issue Type" v-model="issue.type" :options="issue.typelist" @input="selissuecstype" @close="validateForm('t42',$event)"/>
                              <div class="errorinputmsg" v-if="this.errors.issue.type">{{ this.errors.issue.type }}</div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Issue Severity</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Issue Severity" v-model="issue.sevairty" :options="issue.sevairtylist" @input="selissuecssevairty" @close="validateForm('t42',$event)"/>
                              <div class="errorinputmsg" v-if="this.errors.issue.sevairty">{{ this.errors.issue.sevairty }}</div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Issue Priority</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Issue Priority" v-model="issue.priority" :options="issue.prioritylist" @input="selissuecspriority" @close="validateForm('t42',$event)"/>
                              <div class="errorinputmsg" v-if="this.errors.issue.priority">{{ this.errors.issue.priority }}</div>
                              </div>
                          </div>
                          <div class="col-md-3">
                              <div class="form-group mb-1">
                              <label for="allotedto">Assigned To</label>
                              <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search" v-model="issue.allotedto" :options="issue.allotedtolist" @input="selallotedto" @close="validateForm('t42',$event)" v-on:search-change="getEmpallocatedtoProject"/>
                              <div class="errorinputmsg" v-if="this.errors.issue.allotedto">{{ this.errors.issue.allotedto }}</div>
                              </div>
                          </div>
                          <div class="col-md-3">
                              <div class="form-group mb-1">
                              <label for="">Subscribers</label>
                              <treeselect class="projroleassprojectdd capitalisetext" :multiple="true" :clear-on-select="true" placeholder="Enter Min 4 Chars To Search" v-model="issue.subscribers" :options="issue.subscriberslist" @input="selSubscribers" @close="validateForm('t42',$event)" v-on:search-change="getEmpallocatedtoProject"/>
                              <div class="errorinputmsg" v-if="this.errors.issue.subscribers">{{ this.errors.issue.subscribers }}</div>
                              </div>
                          </div>
                          <div class="form-group col-12">
                              <label class="form-label" for="projectmdllistdd">Issue Scenario</label>
                                  <ckeditor :editor="editor" placeholder="Select Issue Scenario" v-model="issue.scenario" :config="editorConfig" 
                                  @ready="prefill"
                                  tag-name="textarea"
                                  v-bind:class="{ 
                                  'form-control': true,
                                  'is-invalid': !valid2Text(issue.scenario) && scenarioBlured, }"
                                  v-on:blur="scenarioBlured = true"/>
                                  <div class="invalid-feedback">Enter issue scenario</div>                                           
                          </div>
                          
                          <div class="col-md-6">
                              <div class="form-group mb-1">
                              <label for="reasonofissue">Reason of Issue</label>
                              <ckeditor :editor="editor" placeholder="Reason of Issue" v-model="issue.reasonofissue"
                              :config="editorConfig" tag-name="textarea"
                              v-bind:class="{ 
                                  'form-control': true,
                                  'is-invalid': !valid2Text(issue.reasonofissue) && reasonBlured, }"
                                  v-on:blur="reasonBlured = true"/>
                                  <div class="invalid-feedback"> Reason of issue required</div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group mb-1">
                              <label for="impactofissue">Impact of Issue</label>
                              <ckeditor :editor="editor"  placeholder="Impact of Issue" v-model="issue.impactofissue"
                              :config="editorConfig" tag-name="textarea"
                              v-bind:class="{ 
                                  'form-control': true,
                                  'is-invalid': !valid2Text(issue.impactofissue) && impactBlured, }"
                                  v-on:blur="impactBlured = true"/>
                                  <div class="invalid-feedback"> Impact of issue required</div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group mb-1">
                              <label for="projectmdllistdd">Issue Status</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Issue Status" :disabled="true" v-model="issue.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validateForm('t42',$event)"/>
                              <div class="errorinputmsg" v-if="this.errors.issue.status">{{ this.errors.issue.status }}</div>
                              </div>
                          </div>
                      </div>
                  </form>
                  <div class="col-md-6 px-0" style="margin-top:24px">
                      <div class="form-group">
                          <div class="form-group">
                                  <label for="attachment" class="btn btn-sm btn-relief-secondary mb-0 mr-75">Select image/docs/videos</label>
                                  
                                  <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                                  <div class="table-responsive my-75" v-if="issue.attachmentview">
                                      <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                          <thead>
                                              <tr>
                                                  <th scope="col">SR#</th>
                                                  <th scope="col">Document Name</th>
                                                  <th scope="col">Description</th>
                                                  <th scope="col">Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr v-for="(data, k) in issue.attachmentview.data" :key="k">
                                                  <td>{{k+1}}</td>
                                                  <td>
                                                      <a :href="data.presignurl">{{
                                                      data.link | basename
                                                      }}</a>
                                                  </td>
                                                  <td>{{ data.info }}</td>
                                                  <td>
                                                      <button type="button" class="btn btn-sm danger" @click="removes3file(k,issue.attachmentview.data)">
                                                      <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                      </button>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                                  <div class="errorinputmsg" v-if="this.errors.issue.attachment">{{ this.errors.issue.attachment }}</div>
                                  <div class="table-responsive my-75" v-if="file.length>0">
                                      <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                          <thead>
                                              <tr>
                                                  <th scope="col">SR#</th>
                                                  <th scope="col">Document Name</th>
                                                  <th scope="col">Description</th>
                                                  <th scope="col">Action</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr v-for="(image, key) in file" :key="key">
                                                  <td>{{ key+1 }}</td>
                                                  <td>{{ image.name }}</td>
                                                  <td>
                                                      <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="issue.filedescp[key]"/>
                                                  </td>
                                                  <td class="text-center">
                                                      <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                      </button>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                                  <button class="btn btn-sm btn-relief-secondary mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                          </div>
                      </div>
                    </div>
                    <div class="col-12 mg-top1 text-right">
                      <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t42',$event)" id="submitbtnt42" v-bind:disabled="issue.disblesavebtn">Submit</button>
                      <button type="reset" class="btn btn-outline-secondary mr-1" @click="resetForm()">Reset</button>
                      <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                    </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <style>
  
  .createbtnparentdiv{
      padding-right: 0% !important;
  }
  .displayblk{
      display:block !important;
  }
  .createbtn{
      border-color: #053e52 !important;
      color: #fff !important;
      background-color: #008000 !important;
      padding: 0.386rem 0.5rem !important;
  }
  </style>
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
  import { EditIcon, EyeIcon, Trash2Icon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon } from 'vue-feather-icons'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Pagination from 'vue-pagination-2'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import commonMethods from '../../utils/commonMethods';
  export default {
    name:'IssueCreate',
    mixins: [ commonMethods ],
    components:{
      Loading,
      VueElementLoading,
      vSelect,
      EditIcon,
      EyeIcon,
      Treeselect,
      SearchIcon,
      GridIcon,
      CodesandboxIcon,
      CloudIcon,
      ChromeIcon,
      BoxIcon,
      Link2Icon,
      PackageIcon,
      Pagination,
      MoreVerticalIcon,
      PlusIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ClipboardIcon,
      ckeditor: CKEditor.component,
      Trash2Icon,
    },    
    data() {
      return {
        btcredirection: null,
        btcqamanager: null,
        btcqalead: null,
        userrole:null,
        isThisBusinessRole: false,
        roleid: 0,
        loginuser:'',
        isLoading: false,
        fullPage: true,
        stylevariation:apiUrl.stylevariation,
        companymasterlist: [],
        editRowData:[],
        glbMdl:'',
        glbMdlLbl:'issue',
        TimeFormat: apiUrl.TimeFormat,
        issue:{
          issuename:null,
          project:null,
          projectlist:null,
          subscriberslist: [],
          subscribers: [],
          functionality:null,
          functionalitylist:[],
          type:null,
          typelist:null,
          scenario:null,
          scenariolist:null,
          attachment:null,
          attachmentview:null,
          attachmentnew: null,
          sevairty:null,
          sevairtylist:null,
          priority:null,
          businesstaskid:null,
          businesstaskcode:null,
          businesstasklist : [],
          businesscodelist : [],
          prioritylist:[{
              id:'low',
              label:'Low'
          },{
              id:'medium',
              label:'Medium'
          },{
              id:'high',
              label:'High'
          }],
          allotedto:null,
          allotedtolist:[],
          reasonofissue:null,
          impactofissue:null,
          status: 'open',
          statuslist: apiUrl.issueStatuslist,
          iseditstate:false,
          disblesavebtn:false,
          task: null,
          tasklist: [],
          filedescp: [],
        },
        business: {
            btmprojlist : null,
            btmprojidlist: null,
            haveMultProj: false,
            btmprojname: null,
        },
        file: [],
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        pagefeatures:[],
        showtabledata:true,
        showproject:false,
        modulelist:[],
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        errors:{
          issue:{
            issuename:null,
            project:null,
            functionality:null,
            type:null,
            scenario:null,
            attachment:null,
            sevairty:null,
            priority:null,
            allotedto:null,
            reasonofissue:null,
            impactofissue:null,
            status:null,
            task: null,
            subscribers: null,
            businesstaskid:null,
            businesstaskcode:null
          }
        },
        isDetActive:true,
        isRequestActive:true,
        isedit: false,
        card:'card',
        border_warning:'border-warning',
        editor: ClassicEditor,
        isBtmFieldDisabled: false,
        isBtmFieldDisable: false,
        editorConfig: {
          toolbar: {
            items: [
              'heading', '|', 'bold',
              'italic', '|', 'bulletedList',
              'numberedList', '|', 'insertTable', '|',
              'undo', 'redo', '|',
            ],
          },
        },
        search:{
          searchprojectname :null,
          functionality: null,
          task:null,
          issuename:null,
          type:null,
          sevairty:null,
          priority:null,
          status:null,
          allotedto:null,
          projectnameList: [],
          functionalitylist: [],
          allotedtolist: [],
          tasklist: [],
        },
        projectnameList:[],
        issuenameList:[],
        view: {
          projectname: null,
          functionlityname: null,
          typename: null,
          sevairtyname: null,
          businessname: null,
        },
        s3bucket: apiUrl.s3url ,
          issuenameBlured:false,
          scenarioBlured:false,
          reasonBlured:false,
          impactBlured:false,
          tokendata:null,
          isRedirectedFromFunc: false,
          isRedirectedFromBtmView: false,
          accepttype:apiUrl.uploadfiletype,
          validFileExtensions:apiUrl.validFileExtensions,
          typewisesubscriber:[],
          isRedirectedFromTR: false,
          trCode: null,
          tddata: null,
      }
    },
    created() {
      this.pagefeatures = {}
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/assignments') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    },
    mounted(){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');  
          this.userrole = window.localStorage.getItem("userrole")
          this.roleid = this.undt.roleid
          if(this.roleid && parseInt(this.roleid) === 520) {
              this.isThisBusinessRole = true
          }
          if(!this.isThisBusinessRole) {
              this.getApplicationdata('t43','issue')
          } else {
              //this.issue.typelist = apiUrl.businessRoleType
              this.issue.status = 'open'
          }
          this.getApplicationdata('t44','issue')
          if (this.$route.params.backbtnstate != undefined) {
              if (this.$route.params.backbtnstate == 'addissue') {
                  let cd = this.$route.params.clickeddata
                  this.isRedirectedFromFunc = true
                  this.issue.project = cd.projectid
                  this.issue.functionality = cd.functionlitymasterid
                  this.view.projectname = cd.projectname
                  this.view.functionlityname = cd.functionlitynamewithsection
                //   this.getEmpallocatedtoProject(this.issue.project, 'issue')
                  this.getbusinesstaskname(this.issue.project, 'issue')
                  this.getbusinesstaskcode(this.issue.project, 'issue')
                  this.getApplicationdata('t37','issue')
              } else if (this.$route.params.backbtnstate === 'createBTMIssue') {
                    let cd = this.$route.params.clickeddata
                    this.isRedirectedFromBtmView = true
                    this.issue.businesstaskid = parseInt(cd.moduleid)
                    this.view.businessname = cd.modulename
                    this.business.btmprojlist = cd.projectnameist
                    this.business.btmprojidlist = cd.projectist.split(',').map(i => parseInt(i))
                    this.getTasktypeConfigLists(this.business.btmprojidlist);
                    this.business.haveMultProj = this.business.btmprojidlist.length > 1
                    if(!this.business.haveMultProj) {
                        this.issue.project = parseInt(cd.projectist)
                        this.business.btmprojname = cd.projectnameist
                        // this.getEmpallocatedtoProject(this.issue.project, 'issue')
                        this.getbusinesstaskcode(this.issue.project, 'issue')
                        this.getApplicationdata('t37','issue')
                    }
                    if(this.userrole == 'product') {
                        let pid = parseInt(cd.projectist)
                        this.getallsublist(pid);
                        this.issue.sevairty = 3;
                        this.issue.priority = 'medium';
                        if(cd.taskowner && cd.taskowner != null) {
                            this.getEmpIDByName(513, cd.taskowner, true);
                        }
                        if(cd.productstackholder && cd.productstackholder != null) {
                            this.getEmpIDByName(131, cd.productstackholder, true);
                        }
                        if(cd.qalead && cd.qalead != null) {
                            this.getEmpIDByName(509, cd.qalead, true);
                        }
                        if(cd.qamanager && cd.qamanager != null) {
                            this.getEmpIDByName(508, cd.qamanager, true);
                        }
                    }
                    if(cd.taskstatus && cd.taskstatus == 'UAT') {
                        this.issue.type = 20;
                    }
                    this.btcredirection = 'createBTMIssue'
              } else if (this.$route.params.backbtnstate === 'createTicketIssue') {
                let tddata = this.$route.params.clickeddata;
                if(tddata) {
                    this.isRedirectedFromTR = true
                    this.issue.type = 18;
                    this.view.projectname = tddata.rowTData.projectname
                    this.issue.project = tddata.rowTData.projectid
                    this.issue.sevairty = parseInt(tddata.rowTData.sevairty);
                    this.issue.subscribers = tddata.issuecreateSub;
                    this.issue.subscriberslist = [];
                    this.issue.subscriberslist = tddata.issuecreateSubArry;
                    this.tddata = tddata.rowTData;
                    this.trCode = 'Imported from '+ tddata.rowTData.issuecode +' created by Business team.';
                }
              }
          }
          this.getProjectAllocatedToEmp()
      }
    },
    methods:{
        getTasktypeConfigLists(projectid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: parseInt(projectid.toString()),
                trackername: 'Issue Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    this.sortTypeList(slist, 'alltype')
                } else {
                    this.issue.typelist = [];
                }
            });
      },
      prefill( editor ) {
        this.issue.scenario = this.trCode;
      },
      sortTypeList(typelist, type){
        let numArrFromTypelist = [];
        typelist.forEach(element => {
            if(element.id != null) {
                numArrFromTypelist.push(element.id);
            }
        });
        if(numArrFromTypelist.length>0) {
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                moduletype: 'issue',
                typearr: numArrFromTypelist,
                projecttype: type
            };
            axios({
                method: "POST",
                url: 'api/ticket/sortTypeList',
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.issue.typelist = result.data.data.rows;
                    this.issue.typelist.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                  });
                } else {
                    this.issue.typelist = [];
                }
            });
        }
      },
      getallsublist(pid) {
        this.input = { projectid:pid,useremail: this.undt.username,empcode: this.undt.userid}
        if(this.isThisBusinessRole) {
            this.input.roleid = 484
        }
        axios({
        'method': 'POST',
        'url': 'api/listing/getEmpallocatedtoProject',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
                this.issue.subscriberslist = result.data.data;
                let decryptdepartmenttext = this.issue.subscriberslist.map(items =>{

                    let tempemplabel1 = items.label;
                    let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                    if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                    }
                    return items;
                })
            }else{
                this.issue.subscriberslist = [];
            }
        })
      },
      getEmpallocatedtoProject(node){
        if(this.issue.project && (node && node.length > 3)){
            this.input = { projectid:this.issue.project,useremail: this.undt.username,empcode: this.undt.userid, asigneename:node.toLowerCase()}
            if(this.isThisBusinessRole) {
                this.input.roleid = 484
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getEmpallocatedtoProject',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    let decryptdepartmenttext = result.data.data.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                    this.issue.allotedtolist=result.data.data;
                    this.issue.subscriberslist = result.data.data;
                }else{
                    this.issue.allotedtolist = [];
                    this.issue.subscriberslist = [];
                }
            })
        }
      },
      selSubscribers(state) {
        this.issue.subscribers = []
        this.issue.subscribers = state
        if(!state || (state && state.length < 1)) {
          this.errors.issue.subscribers = 'Subscribers are required'
        } else {
          this.errors.issue.subscribers = ''
        }
      },
      getbusinesstaskname(){
          this.inputappl = {
              projectid: this.issue.project,
              empid:this.undt.userid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
              moduletype: 'issue'
          }
          axios({
          'method': 'POST',
          'url': 'api/searching/getBusinessTaskbyProject',
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                  this.issue.businesstasklist=result.data.data;
              }else{
                  this.issue.businesstasklist=[];
              }
        }).catch(e => {
        this.displayError(e)
        })
      },
      getbusinesstaskcode(){
          this.inputappl = {
              projectid: this.issue.project,
              empid:this.undt.userid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
              moduletype: 'issue'
          }
          axios({
          'method': 'POST',
          'url': 'api/searching/getBusinessTaskCode',
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                  this.issue.businesscodelist=result.data.data
              }else{
                  this.issue.businesscodelist=[]
              }
          })
      },
      myrequestcollapse() {
          this.isedit = false
          this.issue.iseditstate = false
          this.issue.issuename = null
          this.issue.project = null
          this.issue.functionality = null
          this.issue.type = null
          this.issue.scenario = ''
          this.issue.sevairty = null
          this.issue.priority = null
          this.issue.allotedto = null
          this.issue.reasonofissue = null
          this.issue.impactofissue = null
          this.issue.status = null
          this.attachment = null
          this.issue.attachmentview = null
          this.clearerrorForm()
      },
      resetForm() {
          this.errors.issue.allotedto = null   
          this.issuenameBlured = null
          this.scenarioBlured = null
          this.reasonBlured = null
          this.impactBlured = null   
          this.issue.issuename = null
          if((!this.isRedirectedFromFunc && !this.isRedirectedFromBtmView) || (this.isRedirectedFromBtmView || this.business.haveMultProj) ) {
              this.issue.project = null
          }
          if(!this.isRedirectedFromFunc) {
              this.issue.functionality = null
          }
          this.issue.type = null
          this.issue.scenario = ''
          this.issue.sevairty = null
          this.issue.priority = null
          this.issue.allotedto = null
          this.issue.reasonofissue = ''
          this.issue.impactofissue = ''
          this.reasonBlured = null
          this.impactBlured = null
          this.issue.attachment = null
          this.issue.attachmentview = null
          this.issue.attachmentnew= null
          if(!this.isRedirectedFromBtmView) {
              this.issue.businesstaskid = null
          }
          this.issue.businesstaskcode = null
          this.isBtmFieldDisabled = false
          this.isBtmFieldDisable = false
          this.file = []
          this.issue.filedescp = []
          this.issue.disblesavebtn = false
          this.issue.subscribers = [];
          this.issue.subscriberslist = [];
          this.issue.allotedtolist = [];
          this.clearerrorForm()
       },
      handleCancel() {
          this.issue.disblesavebtn = false;
          this.$router.push({ name: 'IssueList', params: { search: true }})
      },
      redirecttourl:function(btnstate,rowdata){
          rowdata.backbtnstate=btnstate
          this.$router.push({ 
              name: 'SectionModule',
              path: '/sectionmodule',
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata
              }
          })
      },
      getProjectName(projectid) {
        let proj = this.issue.projectlist.filter(proj => proj.id == projectid)
        if(proj && proj.length) {
          return proj[0].label
        }
        return null
      },
      getEmpIDByName(roleid, name, prflag) {
          let url = "api/listing/getEmpIDByName";
          this.input = {
            roleid: parseInt(roleid),
            empname: name,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              if (result.data.errorCode == 0) {
                  if(prflag) {
                    if (roleid == 509) {
                        this.issue.allotedto = parseInt( result.data.data[0].empid)
                    }
                    if (roleid == 508) {
                        this.issue.subscribers.push(result.data.data[0].empid);
                    }
                    if (roleid == 513) {
                        this.issue.subscribers.push(result.data.data[0].empid);
                    }
                    if (roleid == 131) {
                        this.issue.subscribers.push(result.data.data[0].empid);
                    }
                  }
              }           
          });
      },
      savewizard: function(mdl,objThis){  
          this.valid = this.validateForm(mdl,objThis);
          if (this.valid) {
              this.isLoading = true;
              let tblNm='t42'
              if(mdl) tblNm=mdl
              this.input = {
                  tbl: tblNm,
                  section: 'issue',
                  createdby: this.undt.username,
                  empid: this.undt.userid,
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
                  userrole:this.userrole,
              }
              let apiURL='api/issue/create'
              this.input.issuename=this.issue.issuename
              this.input.project=this.issue.project
              this.input.projectname = this.getProjectName(this.issue.project)
              this.input.functionality=this.issue.functionality
              this.input.task = this.issue.task
              this.input.type=this.issue.type
              this.input.scenario=this.issue.scenario
              this.input.attachment=this.issue.attachmentnew
              this.input.sevairty=this.issue.sevairty
              this.input.priority=this.issue.priority
              this.input.allotedto=this.issue.allotedto
              this.input.reasonofissue=this.issue.reasonofissue
              this.input.impactofissue=this.issue.impactofissue
              this.input.subscribers = this.issue.subscribers
              this.input.status=this.issue.status
              this.input.moduletype='issue'
              if(this.isRedirectedFromTR) {
                this.input.isRedirectedFromTR = this.isRedirectedFromTR;
                this.input.tddata = this.tddata;
              }
              if(this.issue.businesstaskid){
                  let code = this.issue.businesscodelist.filter(i => i.businesstaskmasterid == this.issue.businesstaskid)
                  this.input.businesstaskcode = (code[0].id)
                  this.input.businesstaskid=this.issue.businesstaskid
              }
              if(this.issue.businesstaskcode){
                  let code = this.issue.businesstasklist.filter(i => i.businesstaskcode == this.issue.businesstaskcode)
                  this.input.businesstaskid = (code[0].id)
                  this.input.businesstaskcode=this.issue.businesstaskcode
              }
              if(this.issue.iseditstate == true){
                  apiURL='api/master/commonmaster/update'
                  if(tblNm == 't42'){
                      this.input.issueid=this.editRowData.issueid
                      
                      apiURL='api/issue/update'
                  }
                  if(this.issue.attachmentnew && this.issue.attachment !== null) {
                      let jsonobj1 = JSON.parse(this.issue.attachmentnew);
                      let jsonobj2 = this.issue.attachment;
                      let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                      this.input.attachment= JSON.stringify({data: arraymerge});
                  }
                  this.input.lastmodifiedby=this.undt.username
                  delete this.input.createdby;
              }
              if(this.isedit) {
                  this.isedit = false
                  apiURL='api/master/commonmaster/update'
                  if(tblNm == 't42'){
                      this.input.issueid=this.editRowData.issueid
                      apiURL='api/issue/update'
                  }
                  this.input.lastmodifiedby=this.undt.username
                  delete this.input.createdby;
              }
              this.isAddState=false
              this.input.useremail= this.undt.username
              this.input.empcode= this.undt.userid
              // if (this.btcredirection != null && this.btcredirection == 'createBTMIssue' && this.btcqalead != null && this.btcqamanager != null) {
              //     this.input.type = 20;
              //     // this.input.allotedto = this.btcqalead;
              //     // this.input.subscribers = [this.btcqamanager];
              // }
              axios({
              'method': 'POST',
              'url': apiURL,
              'data': this.input,
              'headers':{'authorization':this.tokendata}
              })
              .then(result => {
                  this.isLoading = false;             
                  if(result.data.errorCode == 0){
                      this.file =[]
                      this.issue.filedescp =[]
                      Swal.fire({
                          title: "Success!",
                          text: result.data.msg,
                          icon: 'success',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                      })
                      this.myrequestcollapse()
                        if (this.$route.params.backbtnstate === 'createBTMIssue') {
                          this.$router.push('/businesstask/view?businessid='+this.issue.businesstaskid)
                        } else {
                          this.$router.push({ name: 'IssueList', params: { search: true }});
                        }
                  } else if(result.data.errorCode == 3){
                      Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                      }).then(function() {
                      window.location.href = "/#/login";
                      })
                  } else {
                      Swal.fire({
                          title: "Failed",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                      })
                  }
              }).catch(e => {
        this.displayError(e)
        })
          }
      },
      getProjectAllocatedToEmp() {
          this.isLoading = true
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            moduletype: 'issue',
            isfrompage:'createpage'
          };
          if(this.isRedirectedFromBtmView && this.business.haveMultProj) {
              this.input.projectidarr = this.business.btmprojidlist
          }
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.issue.projectlist = result.data.data;
              } else {
                  this.issue.projectlist = [];
              }
        }).catch(e => {
        this.displayError(e)
        })
      },
      projectChange: function (node){
          if(node && node.length>1){
              this.isLoading = true;
              let url = "api/listing/getProjectAllocatedToEmp";
              this.input = {
                  projectname:node.toLowerCase(),
                  useremail: this.undt.username,
                  empid: this.undt.userid,
                  empcode: this.undt.userid,
                  moduletype: 'issue'
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.issue.projectlist = result.data.data;
                  } else {
                      this.issue.projectlist = [];
                  }
            }).catch(e => {
            this.displayError(e)
            })
          } 
      },
      validText : function(inputval) {
          var re = /[A-Za-z0-9].{4,}/;
          if(inputval){
              return re.test(inputval.toLowerCase());
          }
      },
      valid2Text : function(inputval) {
              var re = /[A-Za-z0-9].{0,}/;
              if(inputval){
                  return re.test(inputval.toLowerCase());
              }
          },
      selbtmid(state, value) {
          if(!state) {
              this.errors.issue.businesstaskid = "Business task name required"
              this.issue.businesstaskcode = null
              this.isBtmFieldDisabled = false
          } else {
              this.errors.issue.businesstaskid = ""
              if(this.issue.businesstaskid){
                  let code = this.issue.businesscodelist.filter(i => i.businesstaskmasterid == this.issue.businesstaskid)
                  this.issue.businesstaskcode = code[0].id
                  this.isBtmFieldDisable = true
                  this.isBtmFieldDisabled = false
              }
          }
      },
      selbtmcode(state, value) {
          if(!state) {
              this.errors.issue.businesstaskcode = "Business task code required"
              this.issue.businesstaskid = null
              this.isBtmFieldDisable = false
          } else {
              this.errors.issue.businesstaskcode = ""
              if(this.issue.businesstaskcode){
                  let code = this.issue.businesstasklist.filter(i => i.businesstaskcode == this.issue.businesstaskcode)
                  this.issue.businesstaskid = code[0].id
                  this.isBtmFieldDisable = false
                  this.isBtmFieldDisabled = true
              }
          }
      },
      validateForm: function(mdl,objThis){
          this.isAddState=false
          var isValid=true;
          this.issuenameBlured = true;
          this.scenarioBlured = true;
          this.reasonBlured = true;
          this.impactBlured = true;
          if(mdl=="t42"){
              if(!this.issue.issuename){
                  this.errors.issue.issuename="Issue name required";
                  isValid= false;
              } else {
                this.errors.issue.issuename = ""
              }
              if(!this.issue.project){
                  this.errors.issue.project="Project is required";
                  isValid= false;
              } else {
                  this.errors.issue.project = null;
              }
              if(!this.issue.businesstaskid && !this.issue.businesstaskcode && (this.issue.type != 9 && this.issue.type != 18)){
                  this.errors.issue.businesstaskid="Business task name is required";
                  isValid= false;
              } else {
                  this.errors.issue.businesstaskid = null;
              }
              if(!this.issue.businesstaskcode && !this.issue.businesstaskid && (this.issue.type != 9 && this.issue.type != 18)){
                  this.errors.issue.businesstaskcode="Business task code is required";
                  isValid= false;
              }else {
                  this.errors.issue.businesstaskcode = null;
              }
              if(!this.issue.type){
                  this.errors.issue.type="Issue type required";
                  isValid= false;
              }
              if(!this.issue.scenario){
                  this.errors.issue.scenario="Issue scenario required";
                  isValid= false;
              } else {
                  this.errors.issue.scenario="";
              }
              if(!this.issue.reasonofissue) {
                this.errors.issue.reasonofissue="Reason of issue required";
                isValid= false;
              } else {
                  this.errors.issue.reasonofissue = ""
              }
              if(!this.issue.allotedto){
                  this.errors.issue.allotedto="Assigned to required";
                  isValid= false;
              } else {
                  this.errors.issue.allotedto = "";
              }
              if(!this.issue.sevairty){
                  this.errors.issue.sevairty="Issue severity required";
                  isValid= false;
              }
              if(!this.issue.priority){
                  this.errors.issue.priority="Issue priority required";
                  isValid= false;
              }
              if(!this.issue.status){
                  this.errors.issue.status="Issue status required";
                  isValid= false;
              }
              if(!this.issue.impactofissue) {
                this.errors.issue.impactofissue="Impact of issue required";
                isValid= false;
              } else {
                this.errors.issue.impactofissue=""
              }
              if((!this.issue.subscribers) || (this.issue.subscribers && this.issue.subscribers.length < 1)) {
                  this.errors.issue.subscribers = 'Subscribers are required'
                  isValid = false
              } else {
                  this.errors.issue.subscribers = ''
              }
          }
          return isValid
      },
      clearerrorForm: function(){
          this.errors.issue.issuename = null
          this.errors.issue.project = null
          this.errors.issue.functionality = null
          this.errors.issue.type = null
          this.errors.issue.scenario = null
          this.errors.issue.attachment = null
          this.errors.issue.sevairty = null
          this.errors.issue.priority = null
          this.errors.issue.status = null
          this.errors.issue.impactofissue = null
          this.errors.issue.businesstaskid = null
          this.errors.issue.businesstaskcode = null
      },
      openEditModule: function(currRowData, flag){
          this.editRowData = currRowData
          this.view.projectname = currRowData.projectname
          this.view.functionlityname = currRowData.functionlityname
          this.view.typename = currRowData.typename
          this.view.sevairtyname = currRowData.sevairtyname
          this.issue.issuename = currRowData.issuename
          this.issue.project = currRowData.project
          this.issue.functionality = currRowData.functionality
          this.issue.task = currRowData.taskid
          this.issue.type = currRowData.issuetype
          this.issue.scenario = currRowData.scenario
          this.issue.attachment = currRowData.attachment
          if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
              this.issue.attachmentview =[];
              this.getDownloadLink(currRowData.attachment);
          }
          this.issue.sevairty = currRowData.sevairty
          this.issue.priority = currRowData.priority
          this.issue.allotedto = currRowData.allotedto
          this.issue.reasonofissue = currRowData.reasonofissue
          this.issue.impactofissue = currRowData.impactofissue
          this.issue.status = currRowData.status
          this.issue.iseditstate = true
          this.isAddState=true
          if(flag==0){
              this.isedit=true
              this.isRequestActive=true
              this.mydetailscollapse()
          }
      },
      getDownloadLink(data) {
        if(data.data.length>0) {
          let payload = {
            documents:data.data,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          axios({
              method: "POST",
              url: '/api/imageupload/getdownloadlink',
              data: payload,
              'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            if(result.data.status) {
              this.issue.attachmentview = result.data;
            }
          }).catch(e => {
        this.displayError(e)
        })
        }
      },
      dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
      },
      nullifyFuncTask() {
          this.issue.functionality = null
          this.issue.task = null
      },
      selissuecsproject: function(state,value){
          this.issue.project=null
          this.issue.project=state
          if(!this.isRedirectedFromBtmView) {
              this.issue.businesstaskid = null
              this.issue.businesstaskcode = null
          }
          if(!state){
              this.issue.functionality = null
              this.issue.task = null
              this.issue.tasklist = []
              this.issue.allotedto = null
              this.issue.subscribers = []
              this.issue.allotedtolist = []
              this.errors.issue.project='Project name is required'
              this.issue.functionalitylist=[]
              this.issue.businesstaskid = null
              this.issue.businesstaskcode = null
          }else{
              this.errors.issue.project=null
              if(this.isRedirectedFromTR == false) {
                  this.issue.type = null
              }
              this.getbusinesstaskname(state, 'issue')
              this.getbusinesstaskcode(state, 'issue')
              this.getApplicationdata('t37','issue')
              this.getTasktypeConfigLists(state);
          }
      },
      selissuecsfunctionality: function(state,value){
          this.issue.functionality=null
          this.issue.task = null
          this.issue.tasklist = []
          this.issue.functionality=state
  
          if(!state){
              this.issue.task = null
              this.issue.tasklist = []
          }
      },
      taskChange(node) {
          if(node && node.length>1 && this.issue.functionality){
              this.isLoading = true;
              let url = "api/master/commonmaster/getModuleDropdownOption";
              this.input = {
                  tbl: 't18',
                  taskname:node.toLowerCase(),
                  functionalityid:this.issue.functionality,
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.issue.tasklist = result.data.data;
                  } else {
                      this.issue.tasklist = [];
                  }
              });
          }
      },
      selissuecstype: function(state,value){
          this.issue.type=null
          this.issue.type=state
          if(state == 9 || state == 18){
              this.errors.issue.businesstaskcode = null
              this.errors.issue.businesstaskid = null
              this.errors.issue.type=null
          }
          
          if(this.userrole=='product' && state == "18" && this.$route.params.backbtnstate != 'createBTMIssue' && this.issue.project != null){
            this.issue.priority='medium'
            this.getEmpByIssueType(131);
            this.errors.issue.type=null
          }
          
          if(this.userrole=='product' && state == "18" && this.$route.params.backbtnstate != 'createBTMIssue' && this.issue.businesstaskcode != null && this.issue.businesstaskid != null){
            this.issue.priority='medium'
            this.getEmpByIssueType(131);
            this.errors.issue.type=null
          } else {
              if(!state && this.isAddState == false){
                  this.errors.issue.type='Type required'
              }else{                
                  this.errors.issue.type=null
              }
          }
      },      
      getEmpByIssueType(roleid) {
          let url = "api/issue/getEmpByIssueType";
          this.input = {
            roleid: parseInt(roleid),
            projectid: this.issue.project,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          if(this.issue.businesstaskid != null){
            this.input.btcid = this.issue.businesstaskid
          }
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              if (result.data.errorCode == 0) {
                this.typewisesubscriber = result.data.data
                let name = this.undt.firstname+' '+this.undt.lastname
                this.issue.subscriberslist = this.typewisesubscriber
                this.issue.subscriberslist.push({"id":this.undt.userid, "label":name})
                // this.issue.subscribers.push(this.typewisesubscriber[0].id,this.undt.userid)
                this.issue.subscribers.push(...this.typewisesubscriber.map(item => item.id));

              }           
          });
      },
      selissuecsscenario: function(state,value){
          this.issue.scenario=''
          this.issue.scenario=state
          if(!state && this.isAddState == false){
              this.errors.issue.scenario='Scenario required'
          }else{                
              this.errors.issue.scenario=''
          }
      },
      selissuecssevairty: function(state,value){
          this.issue.sevairty=null
          this.issue.sevairty=state
          if(!state && this.isAddState == false){
              this.errors.issue.sevairty='Sevairty required'
          }else{                
              this.errors.issue.sevairty=null
          }
      },
      selissuecspriority: function(state,value){
          this.issue.priority=null
          this.issue.priority=state
          if(!state && this.isAddState == false){
              this.errors.issue.priority='Priority required'
          }else{                
              this.errors.issue.priority=null
          }
      },
      selissuecsstatus: function(state,value){
          this.issue.status=null
          this.issue.status=state
          if(!state && this.isAddState == false){
              this.errors.issue.status='Status required'
          }else{                
              this.errors.issue.status=null
          }
      },
      selallotedto: function(state,value){
          this.issue.allotedto=null
          this.issue.allotedto=state
          if(!state && this.isAddState == false){
              this.errors.issue.allotedto='Assigned To required'
          }else{                
              this.errors.issue.allotedto=null
          }
      },
      getApplicationdata: function(mdl,curracttab){            
          this.pageoffset=1
          this.inputappl = {
              tbl:mdl,
              offset:this.pageoffset,
              limit:apiUrl.LIMIT
          }
          let apiURL='api/master/commonmaster/list'
          if(mdl == 't11'){
              apiURL='api/listing/getprojectlist'
              this.inputappl = {
                  tbl:mdl
              }
          }else if(mdl == 't37' && curracttab == 'issue'){
              
              this.inputappl = {};
              apiURL = "api/listing/getfunctionalitybyprojectid";
              this.inputappl.projectid = this.issue.project
          }else if(mdl == 't37' && curracttab == 'issueSearch'){
              this.inputappl = {};
              apiURL = "api/listing/getfunctionalitybyprojectid";
              this.inputappl.projectid = this.search.searchprojectname
          }else if(mdl == 't43'){
              apiURL='api/master/commonmaster/customselect'
              /* this.inputappl.projectmoduleid=this.issue.project */
              this.inputappl.isSearch= false
              this.inputappl.section= "issue"
              this.inputappl.isFilterSelect= 1
              this.inputappl.isFilterPayload= {}
          }else if(mdl == 't44'){
              apiURL='api/master/commonmaster/customselect'
              this.inputappl.isSearch= false
              this.inputappl.section= "issue"
              this.inputappl.isFilterSelect= 1
              this.inputappl.isFilterPayload= {}
          }else if(mdl == 't26'){
              apiURL='api/master/commonmaster/getModuleDropdownOption'
              this.inputappl = {
                  tbl: "t26"
              }
          } else if (mdl == 't18' && curracttab == 'issue') {
              this.issue.task = null
              this.issue.tasklist = []
              apiURL = 'api/master/commonmaster/getModuleDropdownOption'
              this.inputappl = {
                  tbl: mdl,
                  useremail: this.undt.username,
                  functionalityid: this.issue.functionality
              }
          } else if (mdl == 't18' && curracttab == 'issueSearch') {
              apiURL = 'api/master/commonmaster/getModuleDropdownOption'
              this.inputappl = {
                  tbl: mdl,
                  useremail: this.undt.username,
                  functionalityid: this.search.functionality
              }
          }
          this.inputappl.useremail= this.undt.username
          this.inputappl.empcode= this.undt.userid
          axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
          })
          .then(result => {             
              if(result.data.errorCode == 0){
                  if(mdl == 't11'){
                      if(curracttab == 'issue'){
                          
                          return true
                      }
                  }else if(mdl == 't37' && curracttab == 'issue'){
                      this.issue.functionalitylist=result.data.data
                        this.issue.functionalitylist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                        });
                      return true
                  }else if(mdl == 't37' && curracttab == 'issueSearch'){
                      this.search.functionalitylist=result.data.data
                      this.search.functionalitylist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                        });
                      return true
                  }else if(mdl == 't43'){
                      if(curracttab == 'issue'){
                          //this.issue.typelist=result.data.data.rows
                          return true
                      }
                  }else if(mdl == 't44'){
                      if(curracttab == 'issue'){
                          this.issue.sevairtylist=result.data.data.rows
                          return true
                      }
                  }else if(mdl == 't26'){
                      if(curracttab == 'issue'){
                          
                          return true
                      }
                  } else if(mdl == 't18' && curracttab == 'issue') {
                      this.issue.tasklist = result.data.data
                      if(this.issue.iseditstate) {
                          this.issue.task = this.editRowData.taskid
                      } else {
                          this.issue.task = null
                      }
                      return true
                  } else if(mdl == 't18' && curracttab == 'issueSearch') {
                      this.search.tasklist = result.data.data
                      return true
                  }
              } else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            } else {
                  if(mdl == 't37' && curracttab == 'issue'){
                      this.issue.functionalitylist=[]
                      return true
                  } else if(mdl == 't18' && curracttab == 'issue'){
                      this.issue.tasklist = []
                      return true
                  }
              }
        }).catch(e => {
        this.displayError(e)
        })
      },
      onSelect: function(e){
          this.file = e.target.files[0];
      },
      onUpload(){
          if(this.file){
              this.isLoading=true
              if(this.file.length !== 0){
                  let arrGlbMdl=this.glbMdl.split('##')
                  let inputEle='';
                  let btnEle='';
                  if(arrGlbMdl.length > 0){
                      if(typeof arrGlbMdl[0] != 'undefined'){
                          inputEle=arrGlbMdl[0];
                      }
                      if(typeof arrGlbMdl[1] != 'undefined'){
                          btnEle=arrGlbMdl[1];
                      }
                  }
                  if(inputEle == 't42'){
                      btnEle='issuemgt'
                  }
                  this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                  let formData = new FormData();
                  
                  let maxsize = 0;
                  for (let index = 0; index < this.file.length; index++) {
                      formData.append("imagefile", this.file[index]);
                      maxsize+= this.file[index].size
                  }
  
                  //formData.append("imagefile[]", this.file);
                  formData.append("empcode", this.undt.userid);
                  formData.append("useremail", this.undt.username);
                  formData.append("moduletype", 'issuemgt');
                  formData.append("fcount", parseInt(this.file.length));
                  
                  if (maxsize > 1000 * 1000 * 25) {
                      this.isLoading = false;
                      Swal.fire({
                          title: "Failed",
                          text: "Your document uploaded above 25mb not allow",
                          icon: "info",
                          customClass: {
                          confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      })
                     this.issue.disblesavebtn=true
                      this.isLoading=false
                      return false; 
                  }
                  axios({
                      'content-type':'multipart/form-data',
                      'method': 'POST',
                      'url': this.apiURL,
                      'data':formData,
                      'headers':{'authorization':this.tokendata}
                  })
                  .then(result => { 
                      this.isLoading=false;            
                      if(result.data.status){
                          
                          this.issue.disblesavebtn=false
                          
                          const bindinfo = result.data.data.map((file, index) => {
                                  return {"link":file,"info":this.issue.filedescp[index]}                
                              });
                              let jsonobj = {data:bindinfo}
                              this.issue.attachmentnew = JSON.stringify(jsonobj);
                          Swal.fire({
                              title: "Success!",
                              text: result.data.msg,
                              icon: 'success',
                              customClass: {
                                  confirmButton: "btn btn-primary"
                              },
                              buttonsStyling: !1
                          })
                      } else {
                          Swal.fire({
                              title: "",
                              text: result.data.msg,
                              icon: 'info',
                              customClass: {
                                  confirmButton: "btn btn-primary"
                              },
                              buttonsStyling: !1
                          })
                          this.issue.attachment=null
                      }
                  }).catch(e => {
        this.displayError(e)
        })
              }else{
                      this.isLoading=false; 
                  Swal.fire({
                      title: "",
                      text: 'Select image/docs/videos',
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
                  this.issue.attachment=null
              }
          } else {
              this.isLoading=false; 
              Swal.fire({
                  title: "",
                  text: 'Select image/docs/videos',
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
              })
              this.issue.attachment=null
          }
      },
      onFileChange(e) {
        var validFileExtensions = apiUrl.validFileExtensions
        let selectedFiles = e.target.files;
        for (let i=0; i < selectedFiles.length; i++)
        {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
              var sCurExtension = validFileExtensions[j];
              if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                  blnValid = true;
                  break;
              }
            }
            if (!blnValid) {
              Swal.fire({
                    title: "ERROR",
                    text: "Sorry, Invalid Type of Extension File..!!",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
              })
              return false;
            }
            this.file.push(selectedFiles[i]);
        }
        if(this.file.length>0) {
            this.issue.disblesavebtn=true
        }
        document.getElementById('attachment').value = ""
      },
      removeImage (index) {
          if(this.file.length>0) {
              this.file.splice(index, 1);
              this.issue.filedescp.splice(index, 1);
              var removeimg = JSON.parse(this.issue.attachmentnew)
              if(removeimg){
                  var remove = removeimg.data.splice(index,1)
                  if(remove.length > 0)
                  this.issue.attachmentnew = JSON.stringify({data:removeimg.data})
                  else {
                  this.issue.attachmentnew = null
                  }
              }
          }   
          if(this.file.length < 1) {
              this.issue.disblesavebtn = false
          }      
      },
      removes3file(key,data) {
          if(data.length>0) {                
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to delete this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                  if (result.isConfirmed) {
                      if(this.editRowData.issueid !== undefined) {
                          let deletefile = data[key];
                          data.splice(key, 1);
                          const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                          let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                          axios({
                          method: "POST",
                          url: 'api/issue/s3deleteissue',
                          data: {
                              issueid:this.editRowData.issueid,
                              attachment:payload,
                              deletefile: JSON.stringify(deletefile) ,
                              useremail: this.undt.username,
                              empcode: this.undt.userid,
                          },
                          'headers':{'authorization':this.tokendata}
                      }).then((result) => {
                          if (result.data.status) {
                              
                              Swal.fire(
                              'Deleted!',
                              result.data.msg,
                              'success'
                              )
                          } else {
                          Swal.fire(
                              'Deleted!',
                              result.data.msg,
                              'error'
                              )                        
                          }
                      });
                      } else {
                          Swal.fire(
                              'IssueId!',
                              'Issueid should not blank',
                              'info'
                              )
                      }    
                  }
              }).catch(e => {
        this.displayError(e)
        })
          }
          
      },
    }, 
    filters: {
      basename: function (value) {
          if (!value) return ''
          return value.substr(value.lastIndexOf("/") + 1)
      }
  }
  }
  </script>