<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-2">Update Business Task</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'Business Task List', params: { search: true }}">Business Task Master List</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Update Business Task Master</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: isRequestActive},card_content,collapse]" style="margin-top:10px;">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="project-name">Business Task Name </label>
                                        <input class="form-control" type="text" placeholder="Task Name" v-model="tasktype.name"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.name">{{ this.errors.tasktype.name }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="">Business Project Name</label>
                                        <treeselect class="projroleassprojectdd" placeholder="Select Business Project Name" :options="tasktype.businessnamelist" v-model="tasktype.businessname" @input='selectBusinessname' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.businessname">{{ this.errors.tasktype.businessname }}</div> 
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="redmineid">Redmine/Reference ID </label>
                                        <input class="form-control" type="text" placeholder="Redmine/Reference ID " v-model="tasktype.redmineid" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.redmineid">{{ this.errors.tasktype.redmineid }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Project Name</label>
                                        <treeselect :disabled="issubtask" class="projectlistdd capitalisetext" :multiple="true" :clear-on-select="true" placeholder="Enter min 3chars to search project name" v-model="tasktype.projectid" :options="tasktype.projectlist" @input="seltpprojectdata" @deselect="deSelectbSubscribers" @close="validateForm('t9',$event)" v-on:search-change="getProjectAllocatedToEmp"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.projectid">{{ this.errors.tasktype.projectid }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="">Project Module</label>
                                        <treeselect class="projroleassprojectdd capitalisetext" placeholder="Select Project Module" :multiple="true" :clear-on-select="true" :options="tasktype.projectmodulelist" v-model="tasktype.projectmodules" @input="selProjectMOdules" @close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.projectmodules">{{ this.errors.tasktype.projectmodules }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="">Subscribers</label>
                                        <treeselect class="projroleassprojectdd capitalisetext" placeholder="Select Subscribers" :multiple="true" :clear-on-select="true" :options="tasktype.subscriberslist" v-model="tasktype.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.subscribers">{{ this.errors.tasktype.subscribers }}</div>
                                    </div>
                                
                                    <div class="col-md-4 mb-1">
                                        <div class="form-group">
                                        <label for="applicationmasterdd">Task Type
                                            <BTCtypetext/>
                                        </label>
                                        <input class="form-control capitalisetext" v-model="tasktype.type" placeholder="Select Type" :options="btmtasktypelist" @input="selecttypePriority" v-if="btmtasktypelist.length<=1" disabled/>
                                        <treeselect v-model="tasktype.type" placeholder="Select Type" :options="btmtasktypelist" @input="selecttypePriority" v-else/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.type">{{ this.errors.tasktype.type }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="Department">Task Status</label>
                                        <input class="form-control capitalisetext" placeholder="Select Task Status" :options="taskstatus" @input="selecttaskstatus" v-model="tasktype.tstatus" v-if="taskstatus.length <=1" disabled/>
                                        <treeselect placeholder="Select Task Status" :options="taskstatus" @input="selecttaskstatus" v-model="tasktype.tstatus" v-else/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.tstatus">{{ this.errors.tasktype.tstatus }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="key">Task Severity </label>
                                        <treeselect placeholder="Select Severity" :options="tasktypekeylist" @input="selectserPriority" v-model="tasktype.key" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.key">{{ this.errors.tasktype.key }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="project-name">Task Level </label>
                                        <treeselect placeholder="Select Task Level" :options="tasklevellist" @input="seltaskleveldata" v-model="tasktype.level" @close="validate('t9')" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.level">{{ this.errors.tasktype.level }}</div>
                                    </div>
                                    <div v-if="issubtask==false" class="form-group col-md-4">
                                        <label for="priority">Task Priority</label>
                                        <input v-if="pagefeatures.updatepriority || loginpersonname==editRowData.projectmaanager" :disabled="false" class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }"  placeholder="Enter the Numbers Only..." v-model="tasktype.btmpriority"/> 
                                        <input v-else :disabled="true" class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }"  placeholder="Enter the Numbers Only..." v-model="tasktype.btmpriority"/> 
                                    </div>
                                    <div v-if="(this.tasktype.projectid && this.tasktype.projectid.length==1) || issubtask == true || isoldbtc == true" class="form-group col-md-4">
                                        <label class="form-label" for="subpriority">Task Sub Priority</label>
                                        <input v-if="pagefeatures.updatemodulepriority || loginpersonname==editRowData.productstackholder" :disabled="false"  class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" placeholder="Enter the Numbers Only..." v-model="tasktype.subpriority" />
                                        <input v-else :disabled="true" class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" placeholder="Enter the Numbers Only..." v-model="tasktype.subpriority" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="Department">Department</label>
                                        <treeselect placeholder="Select Department" :options="tasktype.deptlist" @input="selectbtmDept" @select="deSelectbtmDept" v-model="tasktype.dept" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.dept">{{ this.errors.tasktype.dept }}</div>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="bsh">HOD Name</label>
                                        <treeselect :disabled="readonlyflds.hodnamefld" class="projroleassprojectdd" placeholder="Select HOD Name " :options="tasktype.bshlist" v-model="tasktype.bsh"  @input="selectBSH" @blur="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.bsh">{{ this.errors.tasktype.bsh }}</div> 
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Requester</label>
                                        <treeselect class="projectlistdd capitalisetext" placeholder="Enter min 4 chars to search requester name" :clear-on-select="true" v-model="tasktype.requester" @input="selRequester" :options="requesterlist" v-on:search-change="getRequesterOnSearch"/>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Requester HOD</label>
                                        <treeselect class="projectlistdd capitalisetext" placeholder="Select Requester HOD Name" :clear-on-select="true" v-model="tasktype.requester_hod" :options="requesterhodlist" />
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="taskmanager">Task Manager</label>
                                        <treeselect :disabled="readonlyflds.taskmanagerfld" class="projroleassprojectdd" placeholder="Select Task Manager" :options="tasktype.taskmanagerlist" v-model="tasktype.taskmanager" @input='selTaskmanager' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskmanager">{{ this.errors.tasktype.taskmanager }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="taskowner">Task Owner (Lead)</label>
                                        <treeselect :disabled="readonlyflds.taskownerfld" class="projroleassprojectdd" placeholder="Select Task Owner" :options="tasktype.taskownerlist" v-model="tasktype.taskowner" @input='selTaskOwner'/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskowner">{{ this.errors.tasktype.taskowner }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="promanager">Project Manager</label>
                                        <treeselect :disabled="readonlyflds.promanagerfld" class="projroleassprojectdd" placeholder="Select Project Manager" :options="tasktype.pmlist" v-model="tasktype.proman" @input='selectProMan' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.proman">{{ this.errors.tasktype.proman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="progmanager">Program Manager</label>
                                        <treeselect :disabled="readonlyflds.progmanfld" class="projroleassprojectdd" placeholder="Select Program Manager" :options="tasktype.proglist" v-model="tasktype.progman" @input='selectProgMan' />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.progman">{{ this.errors.tasktype.progman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="engmanager">Engineering Manager</label>
                                        <treeselect :disabled="readonlyflds.engmanagerfld" class="projroleassprojectdd" placeholder="Select Engineering Manager" :options="tasktype.emlist" v-model="tasktype.engman" @input='selectEngMan' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.engman">{{ this.errors.tasktype.engman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="qamanager">QA Manager</label>
                                        <treeselect :disabled="readonlyflds.qamanagerfld" class="projroleassprojectdd" placeholder="Select QA Manager" :options="tasktype.qamnglist" v-model="tasktype.qamanager" @input='selectQAMan' v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.qamanager">{{ this.errors.tasktype.qamanager }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="qalead">QA Lead</label>
                                        <treeselect :disabled="readonlyflds.qaleadfld" class="projroleassprojectdd" placeholder="Select QA Lead" :options="tasktype.qaleadlist" v-model="tasktype.qalead" @input='selectQALead' v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.qalead">{{ this.errors.tasktype.qalead }}</div>
                                    </div>
                                    <div class="form-group datePicker col-md-4">
                                        <label for="">Start Date</label>
                                            <date-picker placeholder="Select Start Date" v-model="tasktype.startdate" valueType="format" class="startdate"  v-on:pick="validateDate('t9',$event)"
                                            :disabled="isdisableds" @change="onchangestartdate()"></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.startdate">{{ this.errors.tasktype.startdate }}</div>
                                    </div>

                                    <div class="form-group datePicker col-md-4">
                                        <label for="">Estimated End Date</label>
                                            <date-picker :disabled="isenableestimatedate" placeholder="Select Estimated End Date" v-model="tasktype.enddate" valueType="format" class="startdate" v-on:pick="validateDate('t9',$event)" @change="onchangedate()" :disabled-date="disabledBefore" ></date-picker>
                                            <div class="errorinputmsg" v-if="this.errors.tasktype.enddate && isenableestimatedate == false">{{ this.errors.tasktype.enddate }}</div>
                                            <small class="capitalisetext text-danger" v-if="this.isapproved_edd && this.isapproved_edd == 'Pending'"> This Estimated Date is Pending for the approval - <strong> {{this.updated_edd ? dateFormatnew(this.updated_edd): 'NA'}} </strong>.</small>

                                    </div>
                                    <div class="form-group col-md-4" v-if="(editRowData.estimatedddate &&  tasktype.enddate != editRowData.estimatedddate && tasktype.tstatus != 'Product Pipeline' && tasktype.tstatus != 'Product WIP' && tasktype.tstatus != 'Product Completed' && tasktype.tstatus != 'Product WIP 2')">
                                        <label class="form-label" for="edd_reason">Estimate Update Reason </label>
                                        <textarea class="form-control" placeholder="Enter the estimate update reason here" v-model="tasktype.edd_reason" v-bind:class="{'form-control':true, 'is-invalid' : !validText(tasktype.edd_reason)}" ></textarea>                                        
                                        <div class="invalid-feedback" v-if="this.errors.tasktype.edd_reason">{{ this.errors.tasktype.edd_reason }}</div>   
                                    </div>
                                    <div class="form-group datePicker col-md-4">
                                        <label for="Department">Status Due Date</label>
                                        <date-picker placeholder="Select Status Due Date" v-model="tasktype.duedate" valueType="format" class="startdate" :disabled-date="disabledBefore" v-on:pick="validateForm('t9',$event)"></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.duedate">{{ this.errors.tasktype.duedate }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="projectmdllistdd">Ballpark Estimate (in days)</label>
                                            <treeselect :disabled="disableballpark" class="projectmdllistdd capitalisetext" placeholder="Select Ballpark Estimate in days" v-model="tasktype.taskestimates" :options="estimateshours" @input="selestimateshours" @close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskestimates">{{ this.errors.tasktype.taskestimates }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="References">Reference (Please mention the origin from where this task came from) </label>
                                        <input class="form-control" type="text" placeholder="Reference (Please mention the origin from where this task came from) " v-model="tasktype.references" v-bind:class="{'form-control':true, 'is-invalid' : !validText(tasktype.references)}" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.references">{{ this.errors.tasktype.references }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <div class="form-group">
                                            <label for="projectmdllistdd">Billing Country</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Billing Country" v-model="tasktype.billingcountry" :options="tasktype.billingcountrylist" />
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="bscontext">Business Context
                                            <BTCcontexttext/>
                                        </label>
                                        <treeselect placeholder="Select Business Context" :options="tasktype.businesscontextList"  v-model="tasktype.businesscontext"/>
                                        <div class="errorinputmsg" v-if="!this.tasktype.businesscontext">{{'Business Context is required'}}</div>
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label class="form-label">Security Sensitive</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Security Sensitive" v-model="tasktype.securitytag"  :options="securitylist" @input='selectsecurity' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.securitytag">{{ this.errors.tasktype.securitytag }}</div>
                                    </div>
                                    <div class="form-group col-md-8">
                                       <div class="form-group" v-if="tasktype.securitytag">
                                           <label class="form-label" for="project-name">Security Justification</label>
                                           <textarea class="form-control" placeholder="Enter security comment or justification" rows="1" v-model="tasktype.securitycomment"></textarea>
                                           <div class="invalid-feedback">Security Justification is required</div>
                                           <div class="errorinputmsg" v-if="this.errors.tasktype.securitycomment">{{ this.errors.tasktype.securitycomment }}</div>
                                       </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="form-label" for="project-name">Task Description </label>
                                    <ckeditor :editor="editor" placeholder="Task Description" v-model="tasktype.desc" :config="editorConfig" tag-name="textarea" ></ckeditor>
                                    <div class="errorinputmsg" v-if="this.errors.tasktype.desc">{{ this.errors.tasktype.desc }}</div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_success_criteria">Business Success Criteria </label>
                                        <ckeditor :editor="editor" placeholder="Define clear  expectations from business to make it successful." v-model="tasktype.project_success_criteria" :config="editorConfig" tag-name="textarea"
                                            v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_success_criteria) && project_success_criteriaBlured, }"
                                                v-on:blur="project_success_criteriaBlured = true"/>
                                        <div class="invalid-feedback">Business success criteria is required</div>                                           
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_fail_criteria">Business Failure Criteria </label>
                                        <ckeditor :editor="editor" placeholder="Business Failure Criteria" v-model="tasktype.project_fail_criteria" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_fail_criteria) && project_fail_criteriaBlured, }"
                                                v-on:blur="project_fail_criteriaBlured = true"/>
                                        <div class="invalid-feedback">Business failure criteria is required</div>                                           
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_assumptions">Business Assumptions </label>
                                        <ckeditor :editor="editor" placeholder="Business Assumptions" v-model="tasktype.project_assumptions" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{'form-control': true,'is-invalid': !validText(tasktype.project_assumptions) && project_assumptionsBlured, }"
                                                v-on:blur="project_assumptionsBlured = true"/>
                                        <div class="invalid-feedback">Business assumptions is required</div>                                           
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_roi">Business ROI </label>
                                        <ckeditor :editor="editor" placeholder="Business ROI" v-model="tasktype.project_roi" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_roi) && project_roiBlured, }"
                                                v-on:blur="project_roiBlured = true"/>
                                        <div class="invalid-feedback">Business ROI is required</div>                                           
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="business_impact">Business Impact </label>
                                        <ckeditor :editor="editor" placeholder="Business Impact" v-model="tasktype.business_impact" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{'form-control': true, 'is-invalid': !validText(tasktype.business_impact) && business_impactBlured, }"
                                                v-on:blur="business_impactBlured = true"/>
                                        <div class="invalid-feedback">Business impact is required</div>                                           
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="impact_of_live_feature">Impact on other live features if any </label>
                                        <ckeditor :editor="editor" placeholder="Impact on other live features if any." v-model="tasktype.impact_of_live_feature" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.impact_of_live_feature) && impact_of_live_featureBlured, }"
                                                v-on:blur="impact_of_live_featureBlured = true"/>
                                        <div class="invalid-feedback">Impact on other live features if any is required</div>                                           
                                    </div>
                                </div> 

                                <!-- it will displayed only for old btc -->
                                <div class="row" v-if="isoldbtc == true">
                                <div class="col-sm-12" v-if="moduledocs.length > 0" > 
                                    <div v-for="(pdata, index) in moduledocs" :key="index" >
                                        <div class="card-body px-1 py-0">
                                      
                                            <form>
                                                <fieldset class="fieldset_border_vi mb-2">
                                                    <legend>{{moduledocs[index].projectname}} : {{moduledocs[index].projectmodulename}}</legend>
                                                <div class="row">
                                                    <div class="form-group col-md-3 business_hidden_input_space" >
                                                        <input hidden class="form-control" type="text" placeholder="Project Name" :disabled="isprojectSelected" :value="moduledocs[index].projectname"/>
                                                    </div>
                                                    <div class="form-group col-md-3 business_hidden_input_space">
                                                        <input hidden class="form-control" type="text" placeholder="Project Module Name" :disabled="isprojectmoduleSelected" :value="moduledocs[index].projectmodulename" />
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <label class="form-label" for="modulepriority">Project Module Priority </label>
                                                        <input class="form-control" type="number" placeholder="Enter project module priority in numbers" v-model="moduledocs[index].modulepriority" v-if="loginpersonname==editRowData.productstackholder || loginpersonname==tasktype.taskmanager" :disabled="false"/>
                                                        <input class="form-control" type="number" placeholder="Enter project module priority in numbers" v-model="moduledocs[index].modulepriority" :disabled="true" v-else/>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <label for="Department">Project Module Status</label>
                                                        <input placeholder="Select Project Module Status" class="form-control capitalisetext" :options="taskstatus" v-model="moduledocs[index].modulestatus" v-if="moduledocs[index].modulestatus && taskstatus.length<=1" disabled/>
                                                        <treeselect placeholder="Select Project Module Status" class="projectmdllistdd capitalisetext" :options="taskstatus" v-model="moduledocs[index].modulestatus" v-else/>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                       <label for="Department"> &nbsp;</label>
                                                       <div class="text-right bg-white rounded w-100 mb-1">
                                                    <button v-if="pagefeatures.producttask && pdata.moduleid" type="button" class="btn btn-relief-success" :id="'submitbtnt3'+index" data-dismiss="modal" @click="redirecttourl('create', pdata, 'singlemodule')" href="javascript:void(0);" ><plus-circle-icon size="1.5x" class="custom-class mr-50"></plus-circle-icon>Add Task</button>
                                                    <div class="avatar btn-relief-primary rounded ml-1" v-if="moduledocs[index].modulestatus =='Product Pipeline' || moduledocs[index].modulestatus =='Product WIP' || moduledocs[index].modulestatus =='Product Completed' || moduledocs[index].modulestatus =='Product WIP 2'">
                                                        <div class="avatar-content_addbtn btn_padding-update_btn" @click="addModuleFileField(doclist, pdata.modulefiles)">
                                                            <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3 " style="height:3"></plus-square-icon>
                                                            Add Document
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive" v-if="pdata.editedmodulefiles.length>0">
                                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">SR#</th>
                                                        <th scope="col">Document Name</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Comment</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(image, key) in pdata.editedmodulefiles" :key="key">
                                                        <td class="text-center">{{ key+1 }}</td>
                                                        <td>{{ image.documentname | basename }}</td>
                                                        <td>{{ image.title }}</td>
                                                        <td>{{ image.docdescription }}</td>
                                                        <td>{{ image.comment }}</td>
                                                       <td>
                                                            <div class="avatar bg-info rounded m-1">
                                                                <div class="avatar-content">
                                                                    <a @click="getDownloadedLink(image)" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white"></download-icon></a>
                                                                </div>
                                                            </div>
                                                        </td> 
                                                    </tr>
                                                 </tbody>
                                            </table>
                                        </div>
                                        <div class="row mt-1" v-for="(doclist, idx) in pdata.modulefiles" :key="`phoneInput-${index+idx}`">   
                                            <div class="form-group col-md-3" >
                                                <label class="form-label">Document Title </label>
                                                <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title"/>
                                                <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="form-label">Description</label>
                                                <textarea class="form-control"  rows="1" placeholder="Description" v-model="doclist.desc"></textarea>
                                                <div class="errorinputmsg" v-if="!doclist.desc">Document description is required</div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="form-label">Comment</label>
                                                <textarea class="form-control" rows="1" placeholder="Comment" v-model="doclist.comment"/>
                                                <div class="errorinputmsg" v-if="!doclist.comment">Comment is required</div>
                                            </div>
                                            <div class="form-group col-md-3" style="margin-top:25px">
                                                <label for="attachment" class="btn btn-relief-success mb-0">Browse</label>
                                                <input  type="file" id="attachment" hidden :accept="accepttype" ref="file" @change="onSelectDoc($event)"/>
                                                <div class="errorinputmsg" v-if="doclist.attachment == ''">Attachment is required</div>
                                                <button class="btn btn-relief-info  ml-1 uploadbutton" :data-id="idx" v-on:click.stop.prevent="onUploadDoc($event, index, doclist, 'modulefile', idx)" v-if="moduledocs[index].modulestatus =='Product Pipeline' || moduledocs[index].modulestatus =='Product WIP' || moduledocs[index].modulestatus =='Product Completed' || moduledocs[index].modulestatus =='Product WIP 2'"> Upload</button>
                                                <div v-if="(idx>0)" class="avatar btn-relief-danger rounded ml-1" :class="[{display:isActive}]">
                                                    <div class="avatar-content" @click="removeModuleFileField(index, idx, pdata.modulefiles)">
                                                        <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="table-responsive my-75" v-if="doclist.tempfilename">
                                              <table class="table table-sm table-bordered mb-0" aria-describedby="mydesc">  
                                                <thead>
                                                      <tr>
                                                          <th scope="col">Document Title</th>
                                                          <th scope="col">Document Name</th>
                                                          <th scope="col">Action</th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <td>{{ doclist.tempdoctitile }}</td>
                                                          <td>{{ doclist.tempfilename.filename | basename }}</td>
                                                          <td>
                                                              <button type="button" class="btn btn-sm danger" @click="removeImage(index, idx)">
                                                                  <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                              </button>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </table>
                                          </div>
                                        </div>

                                        </fieldset>
                                        </form>
                                    </div>
                                    </div>
                                </div>
                                </div>
  
                                <div class=" form-group mb-0 text-right " >
                                    <button type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt9" data-dismiss="modal" v-on:click.stop.prevent="saveData('t9',$event)">Submit</button>
                                    <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{display:addocument},card,border_warning]"  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header" style="cursor:pointer" v-on:click="myadddocrequestollapse">
                        <div class="avatar bg-light-primary rounded" >
                            <div class="avatar-content">
                                <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></plus-square-icon>
                            </div>
                        </div>
                        <h4 class="card-title">Upload Business Task Document: {{this.tasktype.name}}</h4>
                    </div>
                    <div v-bind:class="[{show: isdocsRequestActive},card_content,collapse]" style="margin-top:10px;">
                        <div class="card-body">
                            <form>
                                <div class="row" v-for="(doclist, index) in docs" :key="`phoneInput-${index}`">
                                    <div class="form-group col-md-3" >
                                        <label class="form-label">Document Title </label>
                                        <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title" v-bind:class="{'form-control':true, 'is-invalid' : !validText(doclist.title)}"/>
                                        <div class="invalid-feedback">Document title must be  greater than 5 char, and you must enter a letters</div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label">Description</label>
                                        <textarea class="form-control"  rows="3" placeholder="Description" v-model="doclist.desc" v-bind:class="{'form-control':true, 'is-invalid' : !validText(doclist.desc)}"></textarea>
                                        <div class="invalid-feedback">Task description must be  greater than 5 char, and you must enter a letters</div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label">Comment</label>
                                        <textarea class="form-control" rows="3" placeholder="Comment" v-model="doclist.comment" v-bind:class="{'form-control':true, 'is-invalid' : !validText(doclist.comment)}"/>
                                        <div class="invalid-feedback">Comment must be  greater than 5 char, and you must enter a letters</div>
                                    </div>
                                    <div class="form-group col-md-3" style="margin-top:25px">
                                        <label for="attachment" class="btn btn-sm btn-primary">Browse</label>
                                        <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelect($event) "/>
                                        <button class="btn btn-sm btn-info ml-1 uploadbutton" :data-id="index" v-on:click.stop.prevent="onUpload($event,index)"> Upload</button>
                                        <div class="avatar bg-success rounded ml-1">
                                            <div class="avatar-content" @click="addField(doclist, docs)">
                                                <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                            </div>
                                        </div>
                                        <div class="avatar bg-danger rounded ml-1" :class="[{display:isActive}]">
                                            <div class="avatar-content" @click="removeField(index, docs)">
                                                <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group d-flex flex-wrap mb-0" >
                                    <button  type="button" class="btn btn-relief-primary mr-1"  data-dismiss="modal" v-on:click.stop.prevent="saveDocument">Save</button>
                                    <button type="button" class="btn btn-outline-secondary"  @click="docsuploadcollapse">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> 
                <div v-bind:class="[{display:editdocument},card,border_warning]"  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header" style="cursor:pointer" v-on:click="myeditdocrequestollapse">
                        <div class="avatar bg-light-primary rounded" >
                            <div class="avatar-content">
                                <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></plus-square-icon>
                            </div>
                        </div>
                        <h4 class="card-title">Edit Upload Business Task Documents: {{this.tasktype.name}}</h4>
                        <div class="heading-elements" style="color:blue;">
                        </div>
                    </div>
                    <div v-bind:class="[{show: isEditDocsRequestActive},card_content,collapse]" style="margin-top:10px;">
                        <div class="card-body">
                            <form>
                                <div class="row" v-for="(editdoclist, index) in tasktype.documentlist" :key="`phoneInput1-${index}`">
                                
                                <div class="form-group col-md-2" >
                                    <label class="form-label">Title </label>
                                    <input class="form-control" type="text" placeholder="Document Title" v-model="editdoclist.title" v-bind:class="{'form-control':true, 'is-invalid' : !validText(editdoclist.title)}"/>
                                    <div class="invalid-feedback">Document title must be  greater than 5 char, and you must enter a letters</div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label">Description</label>
                                    <textarea class="form-control"  rows="2" placeholder="Task Description" v-model="editdoclist.description" v-bind:class="{'form-control':true, 'is-invalid' : !validText(editdoclist.description)}"></textarea>
                                    <div class="invalid-feedback">Task description must be  greater than 5 char, and you must enter a letters</div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label">Comment</label>
                                    <textarea class="form-control" rows="2" placeholder="Comment" v-model="editdoclist.comment" v-bind:class="{'form-control':true, 'is-invalid' : !validText(editdoclist.comment)}"/>
                                    <div class="invalid-feedback">Comment must be  greater than 5 char, and you must enter a letters</div>
                                </div>
                                <div class="form-group col-md-4" style="margin-top:25px">
                                    <label for="attachment" class="btn btn-sm btn-primary">Browse</label>
                                    <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelect($event) "/>
                                    <div class="avatar bg-success rounded ml-1">
                                        <div class="avatar-content" @click="addField(editdoclist, tasktype.documentlist,'edit')">
                                            <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                        </div>
                                    </div>
                                    <div class="avatar bg-danger rounded ml-1" v-if="!editdoclist.presignedUrl">
                                         <div class="avatar-content" @click="removeEditField(index,tasktype.documentlist,editdoclist)">
                                            <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                        </div>
                                    </div>
                                    <div class="avatar bg-info rounded ml-1" v-if="editdoclist.presignedUrl">
                                        <div class="avatar-content">
                                            <a :href="editdoclist.presignedUrl" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor"></download-icon></a>
                                        </div>
                                    </div>
                                    <div class="avatar bg-light rounded ml-1">
                                        <button type="button" class="btn btn-relief-primary mr-1 " :id="'submitbtnt'+index" data-dismiss="modal" v-on:click.stop.prevent="UpdateDocument(editdoclist)">Update</button>
                                    </div>
                                </div>
                            </div>
                             <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click="docsuploadcollapse">Cancel</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div> 
                 <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                         <div class="modal-header">
                            <h4 class="model-title" for="tstatus" style="margin-left: 200px;">Enter the Estimation hours in Tech WIP</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <div class="row">
                            <form class="form form-vertical">
                                <div class="form-group col-12">
                                    <label class="form-label" for="tstatus">Please enter the effort estimation required for this task (Development efforts + QA efforts + external dependency if any</label>
                                </div>
                                    <div class="form-group col-md-6">
                                        <input id="swal-input1" class="form-control col-md-8" style="margin-left: 200px;" v-model="estimatehrs" type="000:00" for="number" @blur="validateHHHMM(estimatehrs)" placeholder="Please enter efforts in hours" >
                                        <div class="errorinputmsg" v-if="this.errors.validestimatehrs" style="margin-left: 200px;">{{ this.errors.validestimatehrs }}</div>
                                    </div>
                                    <div class="form-group col-md-12 flex--items">
                                        <input type="checkbox" id="accept" v-model="selcheckbox"/>
                                        <label class="form-label col-md-lg-12" style="margin-left: 10px;">Hereby I acknowledge that efforts entered are best of my knowledge and experience of engineering manager and projects leads on story points and efforts estimation</label>           
                                    </div>
                                    <div class="errorinputmsg" v-if="this.errors.isChecked" style="margin-left: 10px;">{{ this.errors.isChecked }}</div>

                                <div class="form-group d-flex flex-wrap mt-1 mb-0 flex--items" >
                                    <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" :disabled="!this.validateHHHMM(this.estimatehrs) || !this.isChecked()" v-on:click.stop.prevent="saveestimatedhrs($event)">Submit</button>
                                    <button type="reset" class="btn btn-outline-secondary" @click="closeModal()">Cancel</button>
                                </div>
                            </form>
                        </div>
                        </div>    
                    </div>
                    </div>
                 </div>
            </section>
        </div>           
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { PlusIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,EditIcon,SearchIcon,MoreVerticalIcon,Edit2Icon,
ClipboardIcon,EyeIcon,UploadCloudIcon,PlusCircleIcon,Trash2Icon } from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMethods from '../../utils/commonMethods';
import BTCtypetext from "../constanttemplates/BTCtypetext";
import BTCcontexttext from "../constanttemplates/BTCcontexttext";

export default {
    name:'BTMUpdate',
    components:{
        Loading,PlusSquareIcon,DownloadIcon,EyeIcon,UploadCloudIcon,PlusCircleIcon,
        VueElementLoading,MoreVerticalIcon,Edit2Icon,MinusSquareIcon,ClipboardIcon,
        vSelect,PlusIcon,
        EditIcon,
        Treeselect,
        DatePicker,
        SearchIcon,
        Pagination,
        ckeditor: CKEditor.component,
        Trash2Icon, BTCtypetext, BTCcontexttext
    },
    mixins: [ commonMethods ],

    data() {
        return {
            statusconfig:apiUrl.statusconfig,
            accepttype:apiUrl.uploadfiletype,
            prevarrlen: null,
            isdisableds: false,
            editmoduledocs:[],
            projectmultiplename: [],
            securitylist: [
                { id: 1, label: 'Yes'},
                { id: 0,label: 'No'},
            ],
            multidimensionalArray: [],
            finalmultidimensionalArray: [],
            finalvalidationArray: [],
            newroleid: null,
            moduledocs:[],
            isprojectSelected: true,
            isprojectmoduleSelected: true,
            isAllModuleDocsAdded: true,
            doclist:[],
            myfiles: [],
            selcheckbox:null,
            validestimatehrs:true,
            isinputtechwiphrs:false,
            estimatehrs: null,
            businesstaskcodelistopts:null,
            undt:'',
            isSearchRequestActive:false,
            istrue:false,
            show:'display',
            isdisabled:true,
            isActive:true,
            isLoading: false,
            fullPage: true,
            companymasterlist: [],
            tasktypelist: [],
            typenamelist: [],
            projectlist: [],
            taskassignmentList:[],
            showAddForm:false,
            selected: null,
            editRowData:[],
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            projectType:[],
            projectdescription:'',
            tasktypekeylist:apiUrl.tasktypekeylist.sort((a, b) => a.label.localeCompare(b.label)),
            requesterlist: [],
            requesterhodlist: [],
            tasktype:{
                plannertitle:null,
                securitycomment: null,
                securitytag: null,
                requester:null,
                requester_hod:null,
                ehrs:null,
                oldstatus:null,
                name:null,
                edit:false,
                projectid:null,
                parentbtcid:null,
                subscriberslist: [],
                projectmodulelist:[],
                taskmanagerlist: [],
                pmodulelist: [],
                taskownerlist:[],
                subscribers: null,
                projectmodules: null,
                projectmoduleid: null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                desc:null,
                level:null,
                descerror:null,
                levelerror:null,
                type: null,
                key: null,
                redmineid: null,
                btmpriority: null,
                typelist:apiUrl.typelist,
                admintypelist:apiUrl.admintypelist,
                keylist:apiUrl.keylist,
                referenceslist:apiUrl.referenceslist,
                references: null,
                bsh: null,
                pmlist: null,
                proglist: null,
                progman: null,
                emlist: null,
                proman: null,
                engman: null,
                businessnamelist: null,
                businessname: null,
                psh: null,
                taskmanager: null,
                taskowner: null,
                priorityerr: null,
                dept: null,
                depterr: null,
                deptlist:apiUrl.deptlist.sort((a, b) => a.label.localeCompare(b.label)),
                startdate: null,
                enddate: null,
                edd_reason: null,
                tstatus:null,
                isedit:false,
                taskid:null,
                subpriority: null,
                project_success_criteria:null,
                project_fail_criteria:null,
                project_assumptions:null,
                project_roi:null,
                business_impact:null,
                impact_of_live_feature:null,
                bshlist: [],
                duedate: null,
                qalead: null,
                qaleadlist:[],
                qamanager:null,
                qamnglist:[],
                documentlist:[{
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:''
                }],
                taskestimates: null,
                billingcountry:null,
                billingcountrylist:apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
                businesscontextList:apiUrl.businesscontextList.sort((a, b) => a.label.localeCompare(b.label)),
                businesscontext:null
            },
            tasknamemaster:apiUrl.tasknamemaster,
            taskstatus: [],
            taskBlured:false,
            valid : false, 
            projvalue:null,
            applvalue:null,
            applicoptions:[ {
                id: '',
                label: ''
            }],
            startdate:null,
            errors: {
                isChecked:null,
                validestimatehrs:null,
                startdate: null,
                enddate: null,
                duedate: null,
                tasktype:{
                    securitycomment: null,
                    name:null,
                    projectid:null,
                    desc:null,
                    level:null,
                    descerror:null,
                    securitytag:null,
                    levelerror:null,
                    type: null,
                    key: null,
                    redmineid: null,
                    btmpriority: null,
                    references: null,
                    bsh: null,
                    psh: null,
                    priorityerr: null,
                    dept: null,
                    depterr: null,
                    startdate: null,
                    enddate: null,
                    edd_reason: null,
                    tstatus:null,
                    subscribers: null,
                    project_success_criteria:null,
                    project_fail_criteria:null,
                    project_assumptions:null,
                    project_roi:null,
                    business_impact:null,
                    impact_of_live_feature:null,
                    businessname:null,
                    taskmanager:null,
                    taskowner:null,
                    duedate: null,
                    taskestimates: null,
                    projectmodules: null,
                    progman: null,
                    proman: null,
                },
                docs:{
                    title:null,
                    docs:null,
                    comment:null,
                    attachment:null
                } 
            },
            tasklevel:null,
            tasklevellist:[ {
                id: '',
                label: ''
            }],
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            projectnameist:[],
            loginuscondition:'',
            loginpersonname:'',
            attachment: [],
            attachmentview: null,
            docs:[
                {
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:''
                }    
            ],
            editdoclist:[

            ],
            file: '',
            alldocs:[],
            isDetActive:false,
            isRequestActive:true,
            isdocsRequestActive:false,
            isEditDocsRequestActive: false,
            card_content:'card-content',
            collapse:'collapse',
            files:[],
            loginusername:'',
            addocument:true,
            editdocument: true,
            card:'card',
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
               toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertTable',
                        '|',
                        'undo',
                        'redo', '|',
                    ],
                },
            },
            formActionLabel: 'Create Business Task',
            taskdata:[],
            tokendata:null,
            project_success_criteriaBlured:null,
            project_fail_criteriaBlured:null,
            project_assumptionsBlured:null,
            project_roiBlured:null,
            business_impactBlured:null,
            impact_of_live_featureBlured:null,
            estimateshours: apiUrl.estimateshours,
            taskstartdate: this.dateFormatnew(new Date()),
            isenableestimatedate: true,
            isapproved_edd: null,      
            updated_edd: null, 
            issubtask: false,
            havingsubtasks: false,
            btmtasktypelist: [],
            isshow: null,
            isoldbtc: null,
            oldBTCFlagDate: apiUrl.oldBTCFlagDate,
            readonlyflds: {
                hodnamefld: false,
                taskmanagerfld: false,
                taskownerfld: false,
                promanagerfld: false,
                engmanagerfld: false,
                progmanfld: false,
                qamanagerfld:false,
                qaleadfld: false,
            },
            securitytagchk: null,
            securitycommentBlured: false,
            ballparkvalitestatus: [],
            disableballpark: false,
        }
    },  
    mounted(){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.loginusername=userdata.username
            this.empid = userdata.userid
            this.loginpersonname=userdata.firstname+' '+userdata.lastname;
            this.loginuscondition = userdata.userid
            this.tasktype.deptlist = apiUrl.deptlist
            this.tasktype.typelist = apiUrl.typelist
            // this.getProjectList();
            this.getApplicationdata('t24','task');
            this.getbusinesstasklistbyid(this.$route.query.businessid)
            this.getSubscribers(this.$route.query.businessid, 'businesstask')
            this.getBusinessname();
            // this.getProjectAllocatedToEmp();
            
            this.$nextTick(() => {
                const contentContainers = document.querySelectorAll(".content-container");
                contentContainers.forEach(function (container) {
                const content = container.querySelector(".content02");
                const readMoreBtn = container.querySelector(".read-more");
                const readLessBtn = container.querySelector(".read-less");

                readMoreBtn.addEventListener("click", function () {
                    readMoreBtn.style.display = "none";
                    readLessBtn.style.display = "block";
                    content.classList.add("read-remove");
                });

                readLessBtn.addEventListener("click", function () {
                    readMoreBtn.style.display = "block";
                    readLessBtn.style.display = "none";
                    content.classList.remove("read-remove");
                });
                });
            });
        }
    },
    created() {
        this.pagefeatures = {}
        if(window.localStorage.getItem('pagefeatures')){
            var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/businesstask/list') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        }
    },
    filters: {
        basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
        }
    },
    methods:{
        getspecificbtcstatus(status) {
            let url = "api/bt/getspecificbtcstatus";
            this.input = {
                useremail: this.undt.username,
                empcode: parseInt(this.undt.userid),
                modulestatus: 'Tech Pipeline',
                moduletype: 'Businesstask',
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if (result.data.errorCode == 0) {
                    this.ballparkvalitestatus = result.data.data.rows.statuslist;
                    if(this.ballparkvalitestatus && this.ballparkvalitestatus != null && this.ballparkvalitestatus != '') {
                        let arr = this.ballparkvalitestatus.split(',');
                        console.log(arr)
                        const found = arr.some(el => el == status);
                        console.log(found)
                        if(found) {
                            if(this.editRowData.taskestimates != null && this.editRowData.taskestimates != ''){
                                this.disableballpark = true;
                            }
                        }
                    }
                } else {
                    this.ballparkvalitestatus = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        selRequester(state) {
            if(state){
                this.getRequesterHODOnSearch(state);
            }else{
                this.tasktype.requester_hod = null;
            }
        },
        getRequesterOnSearch(node) {
            if(node != null && node != '' && node.length > 4) {
                this.isLoading = true;
                let url = "api/master/getemployeebyname";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    empname: node ? node.toLowerCase() : null,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.requesterlist = result.data.data.rows;
                        let decryptdesignationtext = this.requesterlist.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                    } else {
                        this.requesterlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } else {
                this.requesterlist = [];
            }
        },
        getRequesterOnSearchDefault(id) {
            this.isLoading = true;
            let url = "api/master/getemployeebyname";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                empname: null,
                empids: [id]
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.requesterlist = result.data.data.rows;
                    let decryptdesignationtext = this.requesterlist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.requesterlist = [];
                }
            }).catch(e => {
                    this.displayError(e)
                });
        },
        getRequesterHODOnSearch(id) {
            this.isLoading = true;
            let url = "api/listing/getRequesterOnSearch";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                requesterid: id,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.requesterhodlist = result.data.data.rows;
                } else {
                    this.requesterhodlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        removeImage (itemindex, rowindex) {
            if(itemindex!=null && rowindex!=null) {
                this.moduledocs[itemindex]['modulefiles'][rowindex]['tempfilename'] = null; 
                this.moduledocs[itemindex]['modulefiles'][rowindex]['tempdoctitile'] = null; 
            }
        },
        getDownloadedLink(data, curr=null) {
            if(data) {
                let payload = {
                documents:[{link:data.documentname,useremail: this.undt.username,empcode: this.undt.userid}],
                useremail: this.undt.username,
                empcode: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    this.isLoading = false
                    if(result.data.status) {
                        if(result.data.data[0].presignurl){
                            window.open(result.data.data[0].presignurl, '_blank')     
                        }
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        redirecttourl:function(btnstate,rowdata, flag=null){
            rowdata.backbtnstate=btnstate
            if(btnstate == 'create') {
                this.$router.push({
                    name: 'TaskCreate',
                    path: '/tasks/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddatafrombtmview:rowdata
                    }
                })
            }
        },
        removeDocFile(docid, mid, pid){
            axios({
                method: "POST",
                url: "api/bt/deletePModuleDoc",
                data: { docid:docid, mid: mid, pid: pid, bid: this.$route.query.businessid,
                useremail: this.undt.username,empcode: this.undt.userid },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0) {
                    this.moduledocs.forEach(async element => {
                        const objWithIdIndex = element.editedmodulefiles.findIndex((obj) => obj.businessdocmasterid === docid);
                        if (objWithIdIndex > -1) {
                          element.editedmodulefiles.splice(objWithIdIndex, 1);
                        }
                    });
                }
            });
        },
        getProjectByBusinessTaskEDIT(moduleids, bid, type, removedadded){
            if(!removedadded){
              this.moduledocs = []
            }
            this.input = { midarr:moduleids, type: type, bid: parseInt(bid),useremail: this.undt.username,
empcode: this.undt.userid }
            let url = "api/bt/getProModuleDetailsWithProject";
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                if(result.data.errorCode == 0) {
                    for(let i = 0; i < result.data.data.length; i++){
                        let editedDocs = [];
                        //get Docs
                        if(removedadded){
                            let reqdata = { mid:result.data.data[i].moduleid, bid: parseInt(bid), pid: result.data.data[i].pid,useremail: this.undt.username,
empcode: this.undt.userid}
                            let url = "api/bt/getProModuleDocDetailsWithProject";
                            axios({
                                method: "POST", url: url, data: reqdata,
                                'headers':{'authorization':this.tokendata}
                            }).then(result1 => { 
                                if(result1.data.errorCode == 0) {
                                   if(result1.data.data.length > 0){ //2
                                    for(let doc=0; doc<result1.data.data.length; doc++){
                                        let singleeditedDocs = {
                                            'businessdocmasterid':result1.data.data[doc].businessdocmasterid,
                                            'documentname':result1.data.data[doc].documentname,
                                            'title':result1.data.data[doc].title,
                                            'docdescription':result1.data.data[doc].description,
                                            'comment':result1.data.data[doc].comment,
                                            'createdby':result1.data.data[doc].createdby,
                                            'updatedby':result1.data.data[doc].updatedby,
                                            'docverison':result1.data.data[doc].docverison,
                                            'taskid':result1.data.data[doc].taskid,
                                        }
                                        editedDocs.push(singleeditedDocs)
                                    }
                                   }
                                }  else if(result.data.errorCode == 3){
                                    Swal.fire({
                                    title: "Session Expired...!!",
                                    text: result.data.msg,
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                    }).then(function() {
                                        window.location.href = "/#/login";
                                    })
                                }     
                            });
                        }
                        let moduleArr = {
                            'projectid':result.data.data[i].pid,
                            'projectname': result.data.data[i].pname,
                            'projectmoduleid': result.data.data[i].moduleid,
                            'projectmodulename': result.data.data[i].projectmodulename,
                            'modulestatus': result.data.data[i].modulestatus,
                            'modulepriority': result.data.data[i].modulepriority,
                            'projectmoduleids': result.data.data[i].projectmoduleid,
                            'moduleid':result.data.data[i].businesstaskmasterid,
                            'businesstaskcode':result.data.data[i].businesstaskcode,
                            'projectmodulenames':result.data.data[i].projectmodulename,
                            'businesstaskmoduleassignmentid':result.data.data[i].businesstaskmoduleassignmentid,
                            'modulename':result.data.data[i].taskname,
                            'projectist':result.data.data[i].pid,
                            'projectnameist':result.data.data[i].pname,
                            'projectmoduleist':result.data.data[i].moduleid,
                            'projectmodulenamenameist':result.data.data[i].projectmodulename,
                            'createdby': result.data.data[i].createdby,
                            'productstackholder': result.data.data[i].productstackholder,
                             modulefiles: [],
                             editedmodulefiles: (editedDocs)?editedDocs:[],
                        }
                        this.moduledocs.push(moduleArr)
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getProjectByBusinessTaskUPDATE(moduleids, bid, type){
            this.input = { 
                midarr:moduleids,
                type: type,
                bid: parseInt(bid),
                useremail: this.undt.username,
                empcode:this.undt.userid 
            }
            let url = "api/bt/getProModuleDetailsWithProject";
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                if(result.data.errorCode == 0) {
                    for(let i = 0; i < result.data.data.length; i++){
                        let prevData = this.moduledocs;
                        
                        let moduleArr = {
                            'projectid':result.data.data[i].pid,
                            'projectname': result.data.data[i].pname,
                            'projectmoduleid': result.data.data[i].moduleid,
                            'projectmodulename': result.data.data[i].projectmodulename,
                            'modulestatus': result.data.data[i].modulestatus,
                            'modulepriority': result.data.data[i].modulepriority,
                            'createdby': result.data.data[i].createdby,
                            'productstackholder': result.data.data[i].productstackholder,
                            modulefiles: [],
                            editedmodulefiles: [],


                        }
                        this.moduledocs.push(moduleArr)
                    }
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        removeModuleFileField(index, idx, fieldType) {
            if(fieldType && fieldType.length>1){
                fieldType.splice(idx,1)
            }            
        },
        isChecked(){
            let z = document.getElementById('accept')
            if(!z) return false 
            if(!z.checked){
                this.errors.isChecked = "Please accept terms and conditions"
            
            }else {
                this.errors.isChecked = ""
            }
            return z.checked
        },
        closeModal(){
            this.validestimatehrs = false
            this.estimatehrs = null
            $('#technologypopup').modal('hide');
        },
        getSubscribers(moduleid, moduletype){
            this.isLoading = true;
            let url = "api/subscribers/getSubscribersByModuleid";
            this.input = { moduleid, moduletype ,useremail: this.undt.username,empcode: this.undt.userid};
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                let decryptdepartmenttext = result.data.data.rows.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
                this.tasktype.subscribers = result.data.data.rows.map(d => d.id)
                } else {
                this.tasktype.subscribers = null
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getModules(projectid, moduleid, moduletype){
            this.isLoading = true;
            let url = "api/master/businesstask/getmoduleid";
            this.input = { projectid,moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid};
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.projectmodules = result.data.data.map(d => d.id);

                    let moduleids =[];
                    if(this.tasktype.projectmodules && this.tasktype.projectmodules.length > 0 && this.tasktype.projectmodulelist && this.tasktype.projectmodulelist.length > 0){
                        this.tasktype.projectmodules.forEach(item => {
                            let checkexistprojectmodules = this.tasktype.projectmodulelist.find(el => el.id == item);
                            if(!checkexistprojectmodules){
                                moduleids.push(item);
                            }
                        })
                    }
                    if(moduleids && moduleids.length>0){
                        this.getProjectbyMOdulesbyid(moduleids);
                    }
                    
                } else {
                    this.tasktype.projectmodules = null;
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        addModuleFileField(pdata, fieldType, opt) {
            if(opt=='edit') {
                let row = fieldType[fieldType.length-1];
                if(row.businessdocmasterid == undefined) return false
            }            
            this.isActive=false
            if(opt === 'moduledocs') {
                fieldType.push({
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:'',
                    filename: null,
                    batchid: null,
                    isUploaded: false,
                    taskid:this.tasktype.taskid,
                    projectname: pdata.projectname,
                    projectid: pdata.projectid,
                    projectmodulename: pdata.projectmodulename,
                    projectmoduleid: pdata.moduleid,
                    modulepriority: pdata.modulepriority,
                    modulestatus: pdata.modulestatus,
                    businesstaskmoduleassignmentid:pdata.businesstaskmoduleassignmentid,
                    businesstaskmasterid:pdata.businesstaskmasterid,
                });
            } else {
                fieldType.push({
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:'',
                    filename: null,
                    batchid: null,
                    isUploaded: false,
                    tempfilename: null,
                    tempdoctitile: null,
                })
            }
        },
        getEmpallocatedtoProject(projectid) {
            axios({
                method: "POST",
                url: "api/listing/getEmpallocatedtoProject",
                data: { projectidarr: projectid, moduletype: 'businesstask',useremail: this.undt.username,empcode: this.undt.userid },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.subscriberslist = result.data.data;
                    let decryptdepartmenttext = this.tasktype.subscriberslist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.tasktype.subscriberslist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectbyMOdules(projectid) {
            axios({
                method: "POST",
                url: "api/master/businesstask/getprojectmodulebyprojectid",
                data: { projectidarr: projectid, moduletype: 'businesstask',useremail: this.undt.username,empcode: this.undt.userid, isfrompage: 'updatepage'},
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.projectmodulelist = result.data.data
                } else {
                    this.tasktype.projectmodulelist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectbyMOdulesbyid(moduleids) {
            axios({
                method: "POST",
                url: "api/master/businesstask/getprojectmodulebyprojectid",
                data: { moduleidarr: moduleids, moduletype: 'businesstask',useremail: this.undt.username,empcode: this.undt.userid},
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.length > 0){

                        if(result.data.data && result.data.data.length > 0 && this.tasktype.projectmodulelist && this.tasktype.projectmodulelist.length > 0){
                            result.data.data.forEach(item => {

                                let checkexistprojectmodules = this.tasktype.projectmodulelist.find(el => el.id == item.id);
                                if(!checkexistprojectmodules){
                                    this.tasktype.projectmodulelist.push(item);
                                }

                            })
                        }
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        selectProMan(state){
            if(!state){
                this.errors.tasktype.proman = 'Project Manager is required'
            } else {
                this.errors.tasktype.proman = ''         
            }
        },
        selectProgMan(state){
            // if(!state){
            //     this.errors.tasktype.progman = 'Program Manager is required'
            // } else {
            //     this.errors.tasktype.progman = ''         
            // }
        },
        selectEngMan(state){
            if(!state){
                this.errors.tasktype.engman = 'Engineering Manager is required'
            } else {
                this.errors.tasktype.engman = ''         
            }
        },
        selectQAMan(state){
            if(!state){
                this.errors.tasktype.qamanager = 'QA Manager is required'
            } else {
                this.errors.tasktype.qamanager = ''
            }
        },
        selectQALead(state){
            if(!state){
                this.errors.tasktype.qalead = 'QA Lead is required'
            } else {
                this.errors.tasktype.qalead = ''
            }
        },
        getbusinesstasklistbyid(moduleid){
            this.isLoading = true;
            let url = "api/master/taskmaster/getBusinessTaskByID";
            this.input = {
                businesstaskmasterid:moduleid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0]
                    if(this.pagefeatures.editbusinesstask || this.undt.username == this.editRowData.createdby || this.loginpersonname == this.editRowData.productstackholder || this.loginpersonname == this.editRowData.qalead || this.loginpersonname == this.editRowData.qamanager || this.loginpersonname == this.editRowData.taskowner || this.loginpersonname == this.editRowData.projectmaanager || this.loginpersonname == this.editRowData.programmanager){
                        this.openEditModule(this.editRowData,0)
                        this.getTaskListByBusinessTask(moduleid)
                    }else{
                        this.editRowData=[]
                        Swal.fire({
                            title: "Failed",
                            text: "Your are not authorised user to update this task",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.$router.push({ name: 'Business Task List', params: { search: true }});                        
                    }
                }  else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }else {
                    this.editRowData = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getprojectmaprolide(empcode, trackername, valchk1)
        { 
            if(valchk1.length>0)
            {
                let url = "api/fieldtracker/getfieldconfigdataroleid";
                this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                projectid: valchk1
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers': { 'authorization': this.tokendata }
                }).then((result) => {    
                    if (result.data.errorCode == 0) {
                        this.newroleid = result.data.data[0]['roleid'];
                        this.getfieldconfigdatabyid(empcode, trackername, valchk1);
                    } else {

                    }
                }).catch(e => {
                this.displayError(e)
                });
            }
        },
        async getfieldconfigdatabyid(empcode, trackername, valchk1) {
            try {
            let projectidarray = []
            if(valchk1)
            {
                for (var j = 0; j < valchk1.length; j++) {
                    projectidarray[j]= valchk1[j];
                }    
                if(this.newroleid)
                {
                    this.isLoading = true
                    let url = "api/fieldtracker/getmultiplefieldconfigdatabyid";
                    this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    roleid: this.newroleid,
                    projectidarray: projectidarray,
                    trackername: trackername
                    };
                    axios({
                        method: "POST",
                        url: url,
                        data: this.input,
                        'headers': { 'authorization': this.tokendata }
                    }).then((result) => {
                        this.isLoading = false;
                        if (result.data.errorCode == 0) {
                            this.setSelectedParameter(result.data.data)
                            this.readOnlyFieldsValidation();
                        } else {
                            this.finalvalidationArray = [];
                            this.multidimensionalArray = [];
                        }
                    }).catch(e => {
                    this.displayError(e)
                });
                }
            }
        } catch (error) { 
            console.log('err');
        }

        },
        setSelectedParameter(variable) {
        const masterkey=[];
        this.multidimensionalArray = [];
        for (var k = 0; k < variable.length; k++) {
            var fieldconfigstring = JSON.stringify(variable[k].fieldconfig);
            var fieldconfigstringarray = JSON.parse(fieldconfigstring);
            var firstindex = "";
            var secondindex = "";
            for (var i = 0; i < fieldconfigstringarray.length; i++) {
            var data = "";
            firstindex = Object.keys(fieldconfigstringarray[i][0])[0];
            const textarray = [];
            masterkey.push(firstindex);
                for (var j = 0; j < fieldconfigstringarray[i][0][firstindex].length; j++) {
                    secondindex = Object.keys(fieldconfigstringarray[i][0][firstindex][j])[0];
                    var validationText='';
                    validationText = fieldconfigstringarray[i][0][firstindex][j][secondindex][0]['validation'][0];
                    textarray[secondindex]=validationText;
                    data = textarray;
                    if(this.tasktype.tstatus == secondindex )
                    {
                        const fixedKey = firstindex;
                        const newData = validationText;
                        if (!this.multidimensionalArray[fixedKey]) {
                            if(this.tasktype.tstatus == secondindex )
                            this.$set(this.multidimensionalArray, fixedKey, []);
                        }
                        else
                        {
                            if(this.tasktype.tstatus == secondindex )
                            this.$set(this.multidimensionalArray, fixedKey, []);
                        }    
                        this.multidimensionalArray[fixedKey].push(newData);
                    }
                }
            }
        }
        const uniqueArray = [...new Set(masterkey)];
        this.finalvalidationArray = [];
        for (var i = 0 ; i < uniqueArray.length; i++)
        {
            if(this.multidimensionalArray.hasOwnProperty(uniqueArray[i]))
            this.finalvalidationArray[uniqueArray[i]]=this.multidimensionalArray[uniqueArray[i]][0];
        }        
        },
        openEditModule(currRowData,flag){
            this.attachment = null
            this.attachmentview = null
            this.editRowData = currRowData
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle){
                $('#modulename'+inputEle).val(currRowData.modulename)
            }
            if(btnEle){
                $('#'+btnEle).find('#submitbtn'+inputEle).addClass(btnEle)
                $('#'+btnEle).find('#submitbtn'+inputEle).attr('data-isedit',true)
            }
            $('#glbsearchinpele').val('')
            this.tasktype.projectlist = JSON.parse(localStorage.getItem('projectlist'))
            this.tasktype.name = currRowData.modulename
            this.tasktype.desc = currRowData.moduledesc
            this.tasktype.level = currRowData.modulelevel
            this.havingsubtasks = (parseInt(currRowData.havingsubtasks) > 0) ? true : false;
            let arrprojidlist=currRowData.projectist.split(",")
            if(this.havingsubtasks == true){
                this.tasktype.projectid=arrprojidlist.map(d => d)
            }else{
                this.tasktype.projectid=arrprojidlist.map(d => parseInt(d))
            }
            this.tasktype.redmineid = currRowData.redmineid
            this.issubtask = currRowData.parentbtcid == null ? false : true;
            this.isshow = currRowData.isshow;
            let createddate = this.dateFormatnew(currRowData.createddate); 
            this.isoldbtc = (createddate > this.oldBTCFlagDate) ? false : true;
            this.tasktype.parentbtcid = currRowData.parentbtcid;
            if(currRowData.tasktype =='Business Task'){
                this.tasktype.type = 'Business Task - New Feature'
            }
            else if(currRowData.tasktype =='Enhancement'){
                this.tasktype.type = 'Business Task - Enhancement'
            }
            else if(currRowData.tasktype =='CR'){
                this.tasktype.type = 'CR'
            }
            else if(currRowData.tasktype =='Escalation'){
                this.tasktype.type = 'Escalation'
            }
            else if(currRowData.tasktype =='Process'){
                this.tasktype.type = 'Process'
            }
            else if(currRowData.tasktype =='Program'){
                this.tasktype.type = 'Program'
            }
            else if(currRowData.tasktype =='Support'){
                this.tasktype.type = 'Support'
            }
            else if(currRowData.tasktype =='Tech Task'){
                this.tasktype.type = 'Tech Task'
            }
            this.tasktype.key = currRowData.taskkey
            this.tasktype.btmpriority = currRowData.priority
            this.tasktype.references = currRowData.reference
            this.tasktype.bsh = currRowData.businessstackholder
            this.tasktype.securitytag = currRowData.securitytag
            this.tasktype.securitycomment = currRowData.securitycomment
            this.tasktype.proman = currRowData.projectmaanager
            this.tasktype.progman = currRowData.programmanager
            this.tasktype.engman = currRowData.engineeringmaanager
            this.tasktype.dept = currRowData.department
            this.tasktype.psh = currRowData.productstackholder
            this.tasktype.taskmanager = currRowData.productstackholder
            this.tasktype.requester = currRowData.requester
            this.tasktype.requester_hod = currRowData.requester_hod
            if(this.tasktype.requester && this.tasktype.requester!=null){
                this.getRequesterOnSearchDefault(this.tasktype.requester)
            }
            this.tasktype.taskowner = currRowData.taskowner
            this.tasktype.startdate = currRowData.startdate
            this.tasktype.enddate = currRowData.estimatedddate
            this.isapproved_edd = currRowData.isapproved_edd
            this.updated_edd = currRowData.updated_edd
            this.tasktype.tstatus = currRowData.taskstatus
            this.tasktype.oldstatus= currRowData.taskstatus
            this.tasktype.ehrs = currRowData.estimatedhours
            this.tasktype.projectnamestr = currRowData.projectnameist
            this.tasktype.taskid = currRowData.moduleid
            this.getEmpallocatedtoProject(currRowData.projectid)
            this.tasktype.subpriority = currRowData.subpriority
            this.tasktype.project_success_criteria = currRowData.project_success_criteria
            this.tasktype.project_fail_criteria = currRowData.project_fail_criteria
            this.tasktype.project_assumptions = currRowData.project_assumptions
            this.tasktype.project_roi = currRowData.project_roi
            this.tasktype.business_impact = currRowData.business_impact
            this.tasktype.impact_of_live_feature = currRowData.impact_of_live_feature
            this.tasktype.businessname = currRowData.businessname
            this.tasktype.duedate = currRowData.due_date
            this.tasktype.taskestimates = currRowData.taskestimates
            this.tasktype.qalead = currRowData.qalead
            this.tasktype.qamanager = currRowData.qamanager
            this.tasktype.billingcountry = currRowData.billingcountry
            this.tasktype.plannertitle = (currRowData.plannertitle && currRowData.plannertitle != "")? currRowData.plannertitle : null;
            this.tasktype.businesscontext = currRowData.businesscontext
            if(currRowData.parentbtcid == null && currRowData.isshow == null){
                this.taskstatus.push({id:'OnHold',label:'OnHold'}, {id:'Discarded',label:'Discarded'}, { id: currRowData.taskstatus, label: currRowData.taskstatus});
                let known = {}
                this.taskstatus = this.taskstatus.filter(item => !known.hasOwnProperty(item.id) && (known[item.id] = true)
                )
            } else {
                this.getBStatusConfigList(this.tasktype.projectid,this.tasktype.tstatus);
            }
            if(currRowData.tasktype && currRowData.tasktype != null) {
              this.getTasktypeConfigLists(null, currRowData.tasktype);
            }
            if(currRowData.projectmoduleist) {
                let arrpmodulelist=currRowData.projectmoduleist.split(",")
                this.tasktype.pmodulelist=arrpmodulelist.map(d => parseInt(d))
                this.prevarrlen = this.tasktype.pmodulelist.length;
                    this.getProjectByBusinessTaskEDIT(this.tasktype.pmodulelist, this.$route.query.businessid, 'update', true)
            }
            
            this.getprojectmaprolide(this.loginuscondition,'1', this.tasktype.projectid);
            this.getspecificbtcstatus(currRowData.taskstatus)

            if(flag==1 || flag ==3){
                this.getDocumetList(currRowData)
            }

            if(this.docs){
                for(const i in this.docs){
                    this.docs[i].comment=null
                    this.docs[i].desc=null
                    this.docs[i].title=null
                    if(i>0){
                        this.docs.splice(i, 1);
                    }
                }
            }
            if(flag==2){
                this.isdocsRequestActive=true
                this.addocument=false
                this.editdocument=true
            }

            if(flag==3){
                this.isdocsRequestActive=true
                this.editdocument=false
                this.addocument=true
                this.isEditDocsRequestActive = true
            }
            if(flag==0){
                this.tasktype.isedit=true
                this.isRequestActive=true
                this.addocument = true
                this.formActionLabel = `Edit Business Task: ${currRowData.modulename}`
            }
            this.isAddState=true
            if(this.tasktype.startdate && this.tasktype.startdate!=null) {
                this.isenableestimatedate = false;
            } else {
                this.isenableestimatedate = true;
            }
            this.projectChange(this.tasktype.projectid ? this.tasktype.projectid : []);
        },
        formatData(data) {
            return data
        },
        docsuploadcollapse:function(){
            if(this.docs){
                for(const i in this.docs){
                    this.docs[i].comment=null
                    this.docs[i].desc=null
                    this.docs[i].title=null
                    if(i>0){
                        this.docs.splice(i, 1);
                    }
                }
            }
            this.isDetActive=true
            this.addocument=true
            this.editdocument = true
            this.isdocsRequestActive=!this.isdocsRequestActive
            
        },
        myeditdocrequestollapse: function() {
            this.isEditDocsRequestActive =!this.isEditDocsRequestActive
        },
        myadddocrequestollapse: function() {
            this.isdocsRequestActive =!this.isdocsRequestActive
        },
        myrequestollapse:function(){
            this.tasktype.name=null
            this.tasktype.proman=null
            this.tasktype.progman=null
            this.tasktype.engman=null
            this.tasktype.desc= ''
            this.tasktype.securitycomment = ''
            this.tasktype.securitytag = null
            this.tasktype.level=null
            this.tasktype.projectid=null
            this.tasktype.type=null
            this.tasktype.key=null
            this.tasktype.redmineid=null
            this.tasktype.btmpriority=null
            this.tasktype.references=null
            this.tasktype.bsh=null
            this.tasktype.psh=null
            this.tasktype.taskmanager=null
            this.tasktype.taskowner=null
            this.tasktype.priorityerr=null
            this.tasktype.dept=null
            this.tasktype.depterr=null
            this.tasktype.startdate = null
            this.tasktype.enddate = null
            this.tasktype.edd_reason = null
            this.tasktype.tstatus = null
            this.tasktype.project_success_criteria=null
            this.tasktype.project_fail_criteria=null
            this.tasktype.project_assumptions=null
            this.tasktype.project_roi=null
            this.tasktype.business_impact=null
            this.tasktype.impact_of_live_feature=null
            this.formActionLabel = 'Create Business Task'
        },
        handleCancel() {
            this.$router.push({ name: 'Business Task List', params: { search: true }});
        },
        addField(value, fieldType,opt='add') {
            if(opt=='edit') {
                let row = fieldType[fieldType.length-1];
                if(row.businessdocmasterid == undefined) return false
            }            
            this.isActive=false
            fieldType.push({ value: "" });
        },
        removeField(index, fieldType) {
            if(fieldType && fieldType.length>1){
                this.docs.splice(index,1);
                this.attachment.splice(index,1);
            }            
        },
        removeEditDoc(index, fieldType, data, key) {  
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                if(data.businessdocmasterid !== undefined) {
                    axios({
                    method: "POST",
                    url: 'api/workstep/deletebusinesstaskdoc',
                    data: {id:data.businessdocmasterid,deleteby:this.undt.username,useremail: this.undt.username,empcode: this.undt.userid},
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.status) {
                        const objWithIdIndex = this.moduledocs[index].editedmodulefiles.findIndex((obj) => obj.businessdocmasterid === data.businessdocmasterid);
                        if (objWithIdIndex > -1) {
                          this.moduledocs[index].editedmodulefiles.splice(objWithIdIndex, 1);
                        }
                        Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'success'
                        )
                    } else {
                       Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'error'
                        )                        
                    }
                });
                } else {
                    fieldType.splice(index, 1);
                }    
            }
            })
        },
        removeEditField(index, fieldType, data) {            
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                if(data.businessdocmasterid !== undefined) {
                    axios({
                    method: "POST",
                    url: 'api/workstep/deletebusinesstaskdoc',
                    data: {id:data.businessdocmasterid,deleteby:this.undt.username,useremail: this.undt.username,empcode: this.undt.userid},
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.status) {
                        fieldType.splice(index, 1);
                        Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'success'
                        )
                    } else {
                       Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'error'
                        )                        
                    }
                });
                } else {
                    fieldType.splice(index, 1);
                }    
            }
            })
        },           
        getProjectList() {
            this.isLoading = true;
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'updatepage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    localStorage.setItem('projectlist', JSON.stringify(result.data.data))
                } else {
                    this.tasktype.projectlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        dateFormatterSales: function(dt) {
            let dateoptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
            };
            return dt.toLocaleDateString("en-GB", dateoptions);
        },
        UpdateDoc(objdata) {
            if(objdata.title == "" || objdata.title == undefined || objdata.docdescription == "" || objdata.docdescription == undefined || objdata.comment == "" || objdata.comment == undefined) {
                return false; 
            } else {    
                if(this.myfiles && this.myfiles.length) {
                    this.isLoading=true
                    let url = "api/s3upload/editnewbusinessdocument";
                    this.myfiles.forEach(file => {
                        if (file.size > 1024 * 1024 * 5) {
                            Swal.fire({
                                title: "Failed",
                                text: "Your document uploaded above 5mb not allow",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            this.isLoading=false
                            return false; 
                        } 
                        if(file.name) {
                            let formData = new FormData();
                            if(objdata.businessdocmasterid) {
                                let newversion =  (objdata.docverison !== '' && objdata.docverison !== undefined && objdata.docverison != null) ? Number(objdata.docverison.substr(1)) +1:'v1';
                                formData.append("version","v"+newversion );
                                formData.append("updatedby",this.undt.username );
                                formData.append("taskid", objdata.taskid );
                                formData.append("businessdocid", objdata.businessdocmasterid);
                                url = "api/s3upload/updatebusinessdocument";
                            }
                            formData.append("imagefile", file);
                            formData.append("moduletype", 'businesstask')
                            formData.append("title", objdata.title);
                            formData.append("desc", objdata.docdescription);
                            formData.append("comment", objdata.comment);
                            formData.append("empcode", this.undt.userid);
                            formData.append("useremail", this.undt.username);
                            axios({
                                "content-type": "multipart/form-data",
                                method: "POST",
                                url: url,
                                data: formData,
                                'headers':{'authorization':this.tokendata}
                            }).then((result) => {
                                if (result.data.status) {
                                    this.myfiles=[];
                                    
                                    Swal.fire({
                                        title: "Success!",
                                        text: result.data.msg,
                                        icon: "success",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    });
                                    this.isLoading=false
                                } else {
                                    Swal.fire({
                                        title: "Failed",
                                        text: "Select business task image/docs/videos",
                                        icon: "info",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    })
                                    this.isLoading=false
                                }
                            }).catch(e => {
                                this.displayError(e)
                            })
                        }
                    })                
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: "Select document",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }
        },
        UpdateDocument(objdata) {
            if(objdata.title == "" || objdata.title == undefined || objdata.description == "" || objdata.description == undefined || objdata.comment == "" || objdata.comment == undefined) {
                return false; 
            } else {    
                if(this.files && this.files.length) {
                    this.isLoading=true
                    let url = "api/s3upload/editnewbusinessdocument";
                    this.files.forEach(file => {
                        if (file.size > 1024 * 1024 * 5) {
                            Swal.fire({
                                title: "Failed",
                                text: "Your document uploaded above 5mb not allow",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            this.isLoading=false
                            return false; 
                        } 
                        if(file.name) {
                            let formData = new FormData();
                            if(objdata.businessdocmasterid) {
                                let newversion =  objdata.docverison !== '' && objdata.docverison !== undefined ? Number(objdata.docverison.substr(1)) +1:'v1';
                                formData.append("version","v"+newversion );
                                formData.append("updatedby",this.undt.username );
                                formData.append("taskid", objdata.taskid );
                                formData.append("businessdocid", objdata.businessdocmasterid);
                                url = "api/s3upload/updatebusinessdocument";
                            } else {
                                url = "api/s3upload/editnewbusinessdocument";
                                formData.append("createdby",this.undt.username );
                                formData.append("taskid", this.tasktype.taskid);
                            }
                            formData.append("imagefile", file);
                            formData.append("moduletype", 'businesstask')
                            formData.append("title", objdata.title);
                            formData.append("desc", objdata.description);
                            formData.append("comment", objdata.comment);
                            formData.append("useremail", this.undt.username);
                            formData.append("empcode", this.undt.userid);
                            
                            axios({
                                "content-type": "multipart/form-data",
                                method: "POST",
                                url: url,
                                data: formData,
                                'headers':{'authorization':this.tokendata}
                            }).then((result) => {
                                if (result.data.status) {
                                    this.files=[];
                                    let currRowData = {
                                        moduleid: this.tasktype.taskid
                                    } 
                                    this.getDocumetList(currRowData)
                                    Swal.fire({
                                        title: "Success!",
                                        text: result.data.msg,
                                        icon: "success",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    });
                                    this.isLoading=false
                                } else {
                                    Swal.fire({
                                        title: "Failed",
                                        text: "Select business task image/docs/videos",
                                        icon: "info",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    })
                                    this.isLoading=false
                                }
                            }).catch(e => {
                            this.displayError(e)
                        })
                        }
                    })                
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: "Select document",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }
        },
        saveestimatedhrs(e){      
            let z = document.getElementById('accept').checked
            if(this.estimatehrs && z){
                this.tasktype.ehrs = this.estimatehrs;
                this.isinputtechwiphrs = true;
                this.validestimatehrs = true;
                this.btnEle=true
                $('#technologypopup').modal('hide');
                this.saveData('t9')
            }            
        },
        getBStatusConfigList(pid, status) {
            this.taskstatus = [];
            if(this.statusconfig){
                let payload = {
                    empcode: this.undt.userid,
                    useremail: this.undt.username,
                    projectid: pid,
                    trackername: 'Businesstask',
                    currStatus: status,
                    bid: parseInt(this.$route.query.businessid),
                };
                axios({
                    method: "POST",
                    url: '/api/testcase/getBStatusConfigList',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    if(result.data.errorCode == 0){
                        this.taskstatus = result.data.data.rows;
                        this.taskstatus.sort((a, b) => a.label.localeCompare(b.label));
                    } else {
                        this.taskstatus = [];
                    }
                });
                
                let prevStatus = this.tasktype.tstatus;
                if (prevStatus == 'OnHold' || prevStatus == 'Discarded') {
                    axios({
                        method: "POST",
                        url: "api/master/businesstask/getPrevStatusFromLogs",
                        data: { status: prevStatus, businesstaskId: this.$route.query.businessid ,useremail: this.undt.username,empcode: this.undt.userid},
                        'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                        if (result.data.errorCode == 0) {
                            let pushedVar = {id: result.data.data[0].oldval,label: result.data.data[0].oldval }
                            const found = this.taskstatus.find(obj => obj.id == result.data.data[0].oldval);
                            if(!found){
                                this.taskstatus.push(pushedVar);
                            }
                        }
                    });
                }
            }
            // else{
            //     if(this.issubtask != false){
            //         this.getTaskStatusByCondition(this.tasktype.tstatus);
            //     }
            // }
        },
        getTaskListByBusinessTask(businesstaskid,state){
            this.isLoading = true;
            let url = "api/master/businesstask/getTaskListByBusinessTask";
            this.input = { businesstaskid ,moduletype:this.taskmodule,empcode: this.undt.userid,useremail: this.undt.username, issubtask: this.issubtask, isshow: this.isshow, orderBy: state};
            if(this.taskmodule == 1) {
                this.input.allottedto = parseInt(this.undt.userid)
                this.input.useremail = this.undt.username
            }
            this.input.limit = apiUrl.LIMIT
            this.input.offset = this.t_pageoffset
            this.input.labelname = this.labelname,
            this.input.taskcode = 'true'
            this.input.taskname = 'true'
            this.input.taskstatus = 'true'
            this.input.tasktype = 'true'
            this.input.taskproject = 'true'
            this.input.taskassignee = 'true'
            this.input.taskassignor = 'true'
            this.input.parenttaskcode = 'true'
            this.input.order = this.order
            this.input.searchTask
            this.input.searchStatus
            if(this.searchTerm) {
                let code = this.searchTerm.split('-')[0]
                    if (code == 'TK' || code == 'tk'){
                            this.input.searchCode = this.searchTerm.toUpperCase()
                        } 
                        else {
                            this.input.searchCode = null
                            this.input.searchTask = this.searchTerm
                            this.input.searchTask = this.input.searchTask.toLowerCase()
                        }
            }
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasklistofbusiness = result.data.data.rows
                    this.t_totalcount = result.data.data.count;
                    this.t_pageCount = Math.ceil(this.t_totalcount / this.t_pagelimit)
                } else {
                    this.tasklistofbusiness = []
                    this.t_totalcount = 0
                    this.t_pageCount =0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        saveData(mdl,objThis){
            let isValid = true;
            isValid = this.validateForm(mdl,objThis);

            if(this.isshow == 1 && this.editRowData.taskstatus != "Tech WIP"  && this.tasktype.plannertitle == null && this.tasktype.tstatus == "Tech WIP"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please tag planner to this BTC and Try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                isValid = false;
            }

            let openSecurityTasks = this.tasklistofbusiness.filter(el => el.status == 'open' && el.reviewers == "securityanalyst");
            if(openSecurityTasks.length>0 && this.tasktype.tstatus == "UAT"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the Security Setup tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                isValid = false;
            }
            let openEstimationTasks = this.tasklistofbusiness.filter(el => el.status == 'open' && el.reviewers == "estimationteam");
            if(openEstimationTasks.length>0 && this.tasktype.tstatus == "Tech Pipeline"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the Estimation Review tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                isValid = false;
            }

            let openTAskslist = this.tasklistofbusiness.filter(el => el.status == 'open' && el.tasktype == "WBS");
            if(openTAskslist.length>0 && this.tasktype.tstatus != "Tech Pipeline"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the WBS tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                isValid = false;
            }
            if(!this.tasktype.ehrs && this.tasktype.tstatus == "Tech WIP"){
                if(isValid){
                    this.validestimatehrs = false;
                    $('#technologypopup').modal('show');
                }
            }else{
                this.validestimatehrs = true;
                // this.tasktype.ehrs =''
            }
            if (isValid && this.validestimatehrs) {
                this.isLoading = true;
                this.clearerrorForm();
                let nameobj=document.getElementById('modulename'+mdl)
                let tblNm='t11'
                if(mdl) tblNm=mdl
                this.input = {
                    createdby: this.undt.username,
                    empid: this.undt.userid,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/master/businesstask/updateBusinessProject'
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                if(mdl == 't9'){
                    this.input.name=this.tasktype.name
                    if(this.tasktype.desc){
                        this.input.desc=this.tasktype.desc
                    }

                    if(this.tasktype.securitytag==1){
                        this.input.securitytag = 1;
                        this.input.securitycomment = this.tasktype.securitycomment;
                    }else if(this.tasktype.securitytag==0){
                        this.input.securitytag = 0;
                    }else{
                        this.input.securitytag=null
                    }
                       
                    if(this.tasktype.project_success_criteria){
                        this.input.project_success_criteria=this.tasktype.project_success_criteria
                    }
                    if(this.tasktype.project_fail_criteria){
                        this.input.project_fail_criteria=this.tasktype.project_fail_criteria
                    }
                    if(this.tasktype.project_roi){
                        this.input.project_roi=this.tasktype.project_roi
                    }
                    if(this.tasktype.business_impact){
                        this.input.business_impact=this.tasktype.business_impact
                    }
                    if(this.tasktype.impact_of_live_feature){
                        this.input.impact_of_live_feature=this.tasktype.impact_of_live_feature
                    }
                    if(this.tasktype.project_assumptions){
                        this.input.project_assumptions=this.tasktype.project_assumptions
                    }
                    if(this.tasktype.level != null && this.tasktype.level != 'undefined'){
                        this.input.level=this.tasktype.level
                    }
                    if(this.tasktype.projectid != null && this.tasktype.projectid != 'undefined'){
                        this.input.projectid=JSON.stringify(this.tasktype.projectid)
                    }
                    if(this.tasktype.redmineid != null && this.tasktype.redmineid != ""){
                        this.input.redmineid = this.tasktype.redmineid
                    }else{
                        this.input.redmineid = null
                    }
                    if(this.tasktype.taskmanager != null && this.tasktype.taskmanager != ""){
                        this.input.productstackholder = this.tasktype.taskmanager
                    }else{
                        this.input.productstackholder = null
                    }
                    if(this.tasktype.taskowner != null && this.tasktype.taskowner != ""){
                        this.input.taskowner = this.tasktype.taskowner
                    }else{
                        this.input.taskowner = null
                    }
                    if(this.tasktype.type == 'Business Task - New Feature'){
                        this.input.tasktype = 'Business Task'
                    } else
                    if(this.tasktype.type=='Business Task - Enhancement') {
                        this.input.tasktype ='Enhancement'
                    } else{
                        this.input.tasktype = this.tasktype.type
                    }
                    // this.input.tasktype = this.tasktype.type
                    this.input.taskkey = this.tasktype.key
                    this.input.priority = this.tasktype.btmpriority
                    this.input.reference = this.tasktype.references
                    this.input.businessstackholder = this.tasktype.bsh
                    this.input.requester =  this.tasktype.requester?this.tasktype.requester:null
                    this.input.requester_hod = this.tasktype.requester_hod?this.tasktype.requester_hod:null
                    this.input.projectmaanager = this.tasktype.proman?(this.tasktype.proman).toLowerCase():null;
                    this.input.engineeringmaanager = this.tasktype.engman?(this.tasktype.engman).toLowerCase():null;
                    this.input.department = this.tasktype.dept
                    this.input.startdate = this.tasktype.startdate
                    this.input.estimatedddate = this.tasktype.enddate?this.tasktype.enddate:null;
                    this.input.attachment = this.attachment
                    this.input.taskstatus = this.tasktype.tstatus
                    this.input.estimatedhours = this.estimatehrs
                    this.input.subscribers = this.tasktype.subscribers
                    this.input.qalead = this.tasktype.qalead
                    this.input.qamanager = this.tasktype.qamanager
                    if(this.tasktype.projectmodules != null && this.tasktype.projectmodules != 'undefined'){
                        this.input.projectmodules=JSON.stringify(this.tasktype.projectmodules)
                    } 
                    this.input.estimatedhours = this.tasktype.ehrs
                    this.input.subpriority = this.tasktype.subpriority
                    this.input.businessname = this.tasktype.businessname
                    this.input.module_code = this.editRowData.businesstaskcode
                    this.input.business_code = this.editRowData.businesstaskcode
                    if(this.tasktype.taskstatus == 'Tech Pipeline'){
                        this.input.techpipelinedate = this.editRowData.techpipelinedate
                    }
                    this.input.duedate = this.tasktype.duedate
                    this.input.taskestimates = this.tasktype.taskestimates
                    
                    if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'Product WIP 2'){
                        if(this.editRowData.estimatedddate && this.editRowData.estimatedddate!=null){
                            this.input.prev_edd = this.editRowData.estimatedddate
                        }
                        if(this.editRowData.estimatedddate!=this.tasktype.enddate){
                            this.input.edd_reason = this.tasktype.edd_reason
                            this.input.edd_counter = this.editRowData.edd_counter + 1
                        }
                    }
                    this.input.projectmoduledata = this.moduledocs
                }
                this.input.issubtask = this.issubtask;
                this.input.isshow = this.isshow;
                this.input.isupdatedependencies = true;
                this.input.programmanager = this.tasktype.progman?(this.tasktype.progman).toLowerCase():null;
                if(this.input.programmanager && this.input.programmanager != null){
                    const rowFound = this.tasktype.proglist.filter(el => el.id == this.input.programmanager);
                    if(rowFound.length>0) {
                        this.input.programmanagerid = rowFound?rowFound[0].employeeid:null;
                    }
                }
                if(this.input.qalead){
                    const rowFound1 = this.tasktype.qaleadlist.filter(el => el.id == this.input.qalead);
                    if(rowFound1.length>0) {
                        this.input.qaleadid = rowFound1?rowFound1[0].employeeid:null;
                    }
                }
                if(this.input.qamanager && this.input.qamanager != null){
                    const rowFound2 = this.tasktype.qamnglist.filter(el => el.id == this.input.qamanager);
                    if(rowFound2.length>0) {
                        this.input.qamanagerid = rowFound2?rowFound2[0].employeeid:null;
                    }
                }
                if(this.input.taskowner && this.input.taskowner != null){
                    const rowFound3 = this.tasktype.taskownerlist.filter(el => el.id == this.input.taskowner);
                    if(rowFound3.length>0) {
                        this.input.taskownerid = rowFound3?rowFound3[0].employeeid:null;
                    }
                }
                if(this.input.projectmaanager && this.input.projectmaanager != null){
                    const rowFound4 = this.tasktype.pmlist.filter(el => el.id == this.input.projectmaanager);
                    if(rowFound4.length>0) {
                        this.input.projectmanagerid = rowFound4?rowFound4[0].employeeid:null;
                    }
                }
                if(this.input.engineeringmaanager && this.input.engineeringmaanager != null){
                    const rowFound5 = this.tasktype.emlist.filter(el => el.id == this.input.engineeringmaanager);
                    if(rowFound5.length>0) {
                        this.input.engineeringmanagerid = rowFound5?rowFound5[0].employeeid:null;
                    }
                }
                
                if(this.tasktype.isedit){
                    this.input.id=this.editRowData.moduleid
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                    this.tasktype.name=null
                    this.tasktype.desc=''
                    this.tasktype.level=null
                    this.tasktype.projectid=null
                    this.tasktype.isedit=false
                    this.estimatehrs=null
                }
                this.input.billingcountry = this.tasktype.billingcountry
                this.input.businesscontext = this.tasktype.businesscontext ? this.tasktype.businesscontext : null


                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {  
                    this.isLoading = false;           
                    if(result.data.errorCode == 0){
                       Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.$router.push({ name: 'Business Task List', params: { search:true }});
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.$router.push({ name: 'Business Task List', params: { search: true }});
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        onchangedate(){
            if(this.tasktype.enddate == null){
                this.tasktype.edd_reason = null;
            }
        },
        onchangestartdate(){
            if(this.tasktype.startdate && this.tasktype.startdate!=null) {
                this.isenableestimatedate = false;
            } else {
                this.isenableestimatedate = true;
            }
        },
        validateDate(mdl) {
            var startDate = this.tasktype.startdate
            var endDate = this.tasktype.enddate
            if(mdl === 't9') {
                if ((Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.tasktype.enddate = "Estimated date should be greater than Start date"
                    this.tasktype.enddate = null
                } else {
                    this.errors.tasktype.enddate = ''
                }
            }
        },
        validateHHHMM:function(str){
            if(!str) return false
            let z = /^([0-9][0-9]|[0-9][0-9][0-9]):[0-5][0-9]$/.test(str); 
            if(!z){
                this.errors.validestimatehrs = "Please enter hours in HHH:MM format"
            }else {
                this.errors.validestimatehrs = ""
            }
            return z;
        },
        readOnlyFieldsValidation(isValid) {
            if(this.finalvalidationArray['HOD Name'])
            {
                this.errors.tasktype.bsh='';
                if(this.finalvalidationArray['HOD Name'] == 'Required')
                {
                    if(!this.tasktype.bsh){
                        this.errors.tasktype.bsh="HOD name is required!";
                        isValid= false;
                    }
                    this.readonlyflds.hodnamefld = false;
                } else if (this.finalvalidationArray['HOD Name'] == 'Read-Only') {
                    this.readonlyflds.hodnamefld = true;
                } 
            } else {
                if(!this.tasktype.bsh){
                    this.errors.tasktype.bsh="HOD name is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.bsh='';
                }
                this.readonlyflds.hodnamefld = false;
            }

            if(this.finalvalidationArray['Project Manager'])
            {
                this.errors.tasktype.proman='';
                if(this.finalvalidationArray['Project Manager'] == 'Required')
                {
                    if(!this.tasktype.proman){
                    this.errors.tasktype.proman="Project Manager is required!";
                    isValid= false;
                    }
                    this.readonlyflds.promanagerfld = false;
                } else if (this.finalvalidationArray['Project Manager'] == 'Read-Only') {
                    this.readonlyflds.promanagerfld = true;
                }
            } else {
                if(!this.tasktype.proman){
                    this.errors.tasktype.proman="Project Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.proman='';
                }
                this.readonlyflds.promanagerfld = false;
            }
              
            if(this.finalvalidationArray['Task Manager'])
            {
                this.errors.tasktype.taskmanager='';
                if(this.finalvalidationArray['Task Manager'] == 'Required')
                {
                    if(!this.tasktype.taskmanager){
                        this.errors.tasktype.taskmanager="Task Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.taskmanagerfld = false;
                } else if (this.finalvalidationArray['Task Manager'] == 'Read-Only') {
                    this.readonlyflds.taskmanagerfld = true;
                }
            } else {
                if(!this.tasktype.taskmanager){
                    this.errors.tasktype.taskmanager="Task Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.taskmanager='';
                }
                this.readonlyflds.taskmanagerfld = false;
            }

            if(this.finalvalidationArray['Task Owner'])
            {
                this.errors.tasktype.taskowner='';
                if(this.finalvalidationArray['Task Owner'] == 'Required')
                {
                    if(!this.tasktype.taskowner){
                        this.errors.tasktype.taskowner="Task Owner is required!";
                        isValid= false;
                    }
                    this.readonlyflds.taskownerfld = false;
                } else if (this.finalvalidationArray['Task Owner'] == 'Read-Only') {
                    this.readonlyflds.taskownerfld = true;
                }
            } else {
                if(!this.tasktype.taskowner){
                    this.errors.tasktype.taskowner="Task Owner is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.taskowner='';
                }
                this.readonlyflds.taskownerfld = false;
            }
            
            if(this.finalvalidationArray['Engineering Manager'])
            {
                this.errors.tasktype.engman='';
                if(this.finalvalidationArray['Engineering Manager'] == 'Required')
                {
                    if(!this.tasktype.engman){
                        this.errors.tasktype.engman="Engineering Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.engmanagerfld = false;
                } else if (this.finalvalidationArray['Engineering Manager'] == 'Read-Only') {
                    this.readonlyflds.engmanagerfld = true;
                } 
            } else {
                if(!this.tasktype.engman){
                    this.errors.tasktype.engman="Engineering Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.engman='';
                }
                this.readonlyflds.engmanagerfld = false;
            }

            if(this.finalvalidationArray['QA Manager'])
            {
                this.errors.tasktype.qamanager='';
                if(this.finalvalidationArray['QA Manager'] == 'Required')
                {
                    if(!this.tasktype.qamanager){
                        this.errors.tasktype.qamanager="QA Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.qamanagerfld = false;
                } else if (this.finalvalidationArray['QA Manager'] == 'Read-Only') {
                    this.readonlyflds.qamanagerfld = true;
                } 
            } else {
                if(this.tasktype.tstatus == "Tech and Business Review" || this.tasktype.tstatus == "UAT"){
                    if(!this.tasktype.qamanager && this.tasktype.qamanager==null){
                        this.errors.tasktype.qamanager="QA Manager is required.";
                        isValid= false;
                    }
                }
                else if(!this.tasktype.qamanager){
                    this.errors.tasktype.qamanager="QA Manager is required";
                    // isValid= false;
                } else {
                    this.errors.tasktype.qamanager='';
                }
                this.readonlyflds.qamanagerfld = false;
            }

            if(this.finalvalidationArray['QA Lead'])
            {
                this.errors.tasktype.qalead='';
                if(this.finalvalidationArray['QA Lead'] == 'Required')
                {
                    if(!this.tasktype.qalead){
                        this.errors.tasktype.qalead="QA Lead is required!";
                        isValid= false;
                    }
                    this.readonlyflds.qaleadfld = false;
                } else if (this.finalvalidationArray['QA Lead'] == 'Read-Only') {
                    this.readonlyflds.qaleadfld = true;
                } 
            } else {
                if(this.tasktype.tstatus == "Tech and Business Review" || this.tasktype.tstatus == "UAT"){
                    if(!this.tasktype.qalead && this.tasktype.qalead==null){
                        this.errors.tasktype.qalead="QA Lead is required.";
                        isValid= false;
                    }
                }
                else if(!this.tasktype.qalead){
                    this.errors.tasktype.qalead="QA Lead is required";
                    // isValid= false;
                } else {
                    this.errors.tasktype.qalead='';
                }
                this.readonlyflds.qaleadfld = false;
            }

            if(this.finalvalidationArray['Program Manager'])
            {
                this.errors.tasktype.progman='';
                if(this.finalvalidationArray['Program Manager'] == 'Required')
                {
                    if(!this.tasktype.progman){
                        this.errors.tasktype.progman="Program Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.progmanfld = false;
                } else if (this.finalvalidationArray['Program Manager'] == 'Read-Only') {
                    this.readonlyflds.progmanfld = true;
                } 
            } else {
                this.readonlyflds.progmanfld = false;
            }
            return isValid;
        },
        validateForm(mdl,objThis){
            this.clearerrorForm()
            this.isAddState=false
            this.project_success_criteriaBlured = true;
            this.project_fail_criteriaBlured = true;
            this.project_assumptionsBlured = true;
            this.project_roiBlured = true;
            this.business_impactBlured = true;
            this.impact_of_live_featureBlured = true;
            var isValid = true;
            isValid = this.readOnlyFieldsValidation(isValid);
            this.moduledocs.forEach(element => {
                if(element.modulefiles && element.modulefiles.length>0)
                {
                    element.modulefiles.forEach(modulefilesele => {
                        if((!modulefilesele.title && modulefilesele.title == "") || 
                        (!modulefilesele.desc && modulefilesele.desc == "") || 
                        (!modulefilesele.comment && modulefilesele.comment == "") || modulefilesele.attachment == "") {
                            isValid = false;
                        } 
                    });
                }
            });
            
            if(mdl == 't9'){
                if(this.finalvalidationArray['Business Task Name']) {
                    this.errors.tasktype.name='';
                    if(this.finalvalidationArray['Business Task Name'] == 'Required')
                    {
                        if(!this.tasktype.name){
                            this.errors.tasktype.name="Business Task Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.name){
                        this.errors.tasktype.name="Business Task name is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.name='';
                    }
                }
                if(this.finalvalidationArray['Redmine ID']) {
                    this.errors.tasktype.redmineid='';
                    if(this.finalvalidationArray['Redmine ID'] == 'Required')
                    {
                        if(!this.tasktype.redmineid){
                            this.errors.tasktype.redmineid="Redmine/Reference ID is required!";
                            isValid= false;
                        }
                    }
                }
                let flag=false
                if(flag){
                    if(!this.tasktype.project_success_criteria){
                        this.errors.tasktype.project_success_criteria="Business success criteria is required";
                        isValid= false;
                    }
                    if(!this.tasktype.project_fail_criteria){
                        this.errors.tasktype.project_fail_criteria="Business failure criteria is required";
                        isValid= false;
                    }
                    if(!this.tasktype.project_assumptions){
                        this.errors.tasktype.project_assumptions="Business assumptions is required";
                        isValid= false;
                    }
                    if(!this.tasktype.project_roi){
                        this.errors.tasktype.project_roi="Business ROI is required";
                        isValid= false;
                    }
                    if(!this.tasktype.business_impact){
                        this.errors.tasktype.business_impact="Business impact is required";
                        isValid= false;
                    }
                    if(!this.tasktype.impact_of_live_feature){
                        this.errors.tasktype.impact_of_live_feature="Impact on other live features if any is required";
                        isValid= false;
                    }
                }
                if(this.finalvalidationArray['Task Description']) {
                    this.errors.tasktype.desc='';
                    if(this.finalvalidationArray['Task Description'] == 'Required')
                    {
                        if(!this.tasktype.desc){
                            this.errors.tasktype.desc="Task Description is required!";
                            isValid= false;
                        }
                    }
                }else{
                    if(!this.tasktype.desc){
                        this.errors.tasktype.desc="Business task description is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.desc='';
                    }
                }
                if(this.finalvalidationArray['Security Sensitive']) {
                    this.errors.tasktype.securitytag='';
                    if(this.finalvalidationArray['Security Sensitive'] == 'Required')
                    {
                        if(!this.tasktype.securitytag){
                            this.errors.tasktype.securitytag="Security Sensitive is required!";
                            isValid= false;
                        }
                    }
                }else{
                    if(this.tasktype.tstatus =='Product Pipeline'||this.tasktype.tstatus =='Product Completed'||this.tasktype.tstatus =='Product WIP'||this.tasktype.tstatus =='Product WIP 2'||this.tasktype.tstatus =='Tech and Business Review'|| this.tasktype.tstatus == 'OnHold' || this.tasktype.tstatus == 'Discarded'){
                    this.errors.tasktype.securitytag=''
                         }else if(this.tasktype.securitytag == null){
                    this.errors.tasktype.securitytag="Security Sensitive is required";
                    isValid=false;
                }
                }
               
                if(this.tasktype.securitytag) {
                    if(this.finalvalidationArray['Security Justification']) {
                        this.errors.tasktype.securitycomment='';
                        if(this.finalvalidationArray['Security Justification'] == 'Required')
                        {
                            if(!this.tasktype.securitycomment){
                                this.errors.tasktype.securitycomment="security justification is required!";
                                isValid= false;
                            }
                        }
                    }else{
                        if(!this.tasktype.securitycomment){
                            this.errors.tasktype.securitycomment="Business security justification is required";
                            isValid= false;
                        }else{
                            this.errors.tasktype.securitycomment='';
                        }
                    }
                }

                if(this.finalvalidationArray['Project Name']) {
                    this.errors.tasktype.projectid = '';
                    if(this.finalvalidationArray['Project Name'] == 'Required')
                    {
                        if((!this.tasktype.projectid) || (this.tasktype.projectid && this.tasktype.projectid.length < 1)){
                            this.errors.tasktype.projectid="Project Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if((!this.tasktype.projectid) || (this.tasktype.projectid && this.tasktype.projectid.length < 1)){
                        this.errors.tasktype.projectid="Project required";
                        isValid= false;
                    }
                }

                if(this.finalvalidationArray['Task Level']) {
                    this.errors.tasktype.level='';
                    if(this.finalvalidationArray['Task Level'] == 'Required')
                    {
                        if(!this.tasktype.level){
                            this.errors.tasktype.level="Task Level is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.level){
                        this.errors.tasktype.level="Task level is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.level='';
                    }
                }
                if(this.finalvalidationArray['Task Type']) {
                    this.errors.tasktype.type='';
                    if(this.finalvalidationArray['Task Type'] == 'Required')
                    {
                        if(!this.tasktype.type){
                            this.errors.tasktype.type="Task Type is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.type){
                        this.errors.tasktype.type="Task type is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.type='';
                    }
                }

                if(this.finalvalidationArray['Task Severity']) {
                    this.errors.tasktype.key='';
                    if(this.finalvalidationArray['Task Severity'] == 'Required')
                    {
                        if(!this.tasktype.key){
                            this.errors.tasktype.key="Task Severity is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.key){
                        this.errors.tasktype.key="Task severity is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.key='';
                    }
                }

                if(this.finalvalidationArray['Reference']) {
                    this.errors.tasktype.references='';
                    if(this.finalvalidationArray['Reference'] == 'Required')
                    {
                        if(!this.tasktype.references){
                            this.errors.tasktype.references="Reference is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.references){
                        this.errors.tasktype.references="Task references is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.references='';
                    }
                }

                if(this.finalvalidationArray['Department'])
                {
                    this.errors.tasktype.dept='';
                    if(this.finalvalidationArray['Department'] == 'Required')
                    {
                        if(!this.tasktype.dept){
                        this.errors.tasktype.dept="Department is required!";
                        isValid= false;
                        }
                    }
                }
                else
                {
                    if(!this.tasktype.dept){
                        this.errors.tasktype.dept="Department is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.dept='';
                    }
                }

                if(this.finalvalidationArray['Task Status'])
                {
                    this.errors.tasktype.tstatus='';
                    if(this.finalvalidationArray['Task Status'] == 'Required')
                    {
                        if(!this.tasktype.tstatus){
                            this.errors.tasktype.tstatus="Task Status is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.tstatus){
                        this.errors.tasktype.tstatus="Task status is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.tstatus='';
                    }         
                }

                if(this.finalvalidationArray['Ballpark Estimate'])
                {
                    this.errors.tasktype.taskestimates = '';
                    if(this.finalvalidationArray['Ballpark Estimate'] == 'Required')
                    {
                        if(this.tasktype.tstatus && (this.tasktype.tstatus == "Product WIP") || (this.tasktype.tstatus == "Product Pipeline") || (this.tasktype.tstatus == "Product Completed") || (this.tasktype.tstatus == "OnHold") || (this.tasktype.tstatus == "Discarded")|| (this.tasktype.tstatus == "Tech and Business Review") || (this.tasktype.tstatus == "Product WIP 2") || (this.tasktype.tstatus == "Tech Issue Resolution")) {
                            this.errors.tasktype.taskestimates="";
                        }else{
                            if(this.tasktype.taskestimates){
                                this.errors.tasktype.taskestimates = '';
                            }else{
                                this.errors.tasktype.taskestimates = 'Ballpark Estimate in days is required!'
                                isValid = false
                            }
                        }
                    }
                } else {
                    if(this.tasktype.tstatus && (this.tasktype.tstatus == "Product WIP") || (this.tasktype.tstatus == "Product Pipeline") || (this.tasktype.tstatus == "Product Completed") || (this.tasktype.tstatus == "OnHold") || (this.tasktype.tstatus == "Discarded")|| (this.tasktype.tstatus == "Tech and Business Review") || (this.tasktype.tstatus == "Product WIP 2") || (this.tasktype.tstatus == "Tech Issue Resolution")) {
                        this.errors.tasktype.taskestimates="";
                    }else{
                        if(this.tasktype.taskestimates){
                            this.errors.tasktype.taskestimates = '';
                        }else{
                            this.errors.tasktype.taskestimates = 'Ballpark Estimate in days is required'
                            isValid = false
                        }
                    }
                }
                
                if(this.finalvalidationArray['Estimated End Date'])
                {
                    this.errors.tasktype.enddate = '';
                    this.errors.tasktype.edd_reason = '';
                    if(this.finalvalidationArray['Estimated End Date'] == 'Required')
                    {
                        if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product WIP 2' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'OnHold' && this.tasktype.tstatus != 'Discarded' && this.tasktype.tstatus != 'Tech and Business Review' && this.tasktype.tstatus != 'Tech Pipeline'){
                            if (this.tasktype.startdate && !this.tasktype.enddate) {
                                this.errors.tasktype.enddate="Estimated date is required!";
                                    isValid= false;
                            }
                            if(this.editRowData.estimatedddate &&  this.tasktype.enddate && this.tasktype.enddate != this.editRowData.estimatedddate){
                                if(!this.tasktype.edd_reason){
                                    this.errors.tasktype.edd_reason="Estimated Update Reason is required";
                                    isValid = false;
                                }
                            }
                        }
                        if(this.tasktype.tstatus == "Tech WIP"){
                            if(!this.tasktype.enddate && this.tasktype.enddate==null){
                                this.errors.tasktype.enddate="Estimated date is required";
                                isValid= false;
                            }
                        }
                    }
                } else {
                    if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product WIP 2' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'OnHold' && this.tasktype.tstatus != 'Discarded' && this.tasktype.tstatus != 'Tech and Business Review' && this.tasktype.tstatus != 'Tech Pipeline'){
                        if (this.tasktype.startdate && !this.tasktype.enddate) {
                            this.errors.tasktype.enddate="Estimated date is required";
                                isValid= false;
                        }
                        if(this.editRowData.estimatedddate &&  this.tasktype.enddate && this.tasktype.enddate != this.editRowData.estimatedddate){
                            if(!this.tasktype.edd_reason){
                                this.errors.tasktype.edd_reason="Estimated Update Reason is required";
                                isValid = false;
                            }
                        }
                    }
                    if(this.tasktype.tstatus == "Tech WIP"){
                        if(!this.tasktype.enddate && this.tasktype.enddate==null){
                            this.errors.tasktype.enddate="Estimated date is required";
                            isValid= false;
                        }
                    }
                }
               
                if(this.finalvalidationArray['Start Date'])
                {
                    this.errors.tasktype.startdate = '';
                    if(this.finalvalidationArray['Start Date'] == 'Required')
                    {
                        if(this.tasktype.tstatus == "Tech WIP"){
                            if(!this.tasktype.startdate && this.tasktype.startdate==null){
                                this.errors.tasktype.startdate="Start Date is required!";
                                isValid= false;
                            }
                        }
                    }
                }else{
                    if(this.tasktype.tstatus == "Tech WIP"){
                        if(!this.tasktype.startdate && this.tasktype.startdate==null){
                            this.errors.tasktype.startdate="Start date is required";
                            isValid= false;
                        }
                    }
                }

                
                if(this.finalvalidationArray['Status Due Date'])
                {
                    this.errors.tasktype.duedate = ''
                    if(this.finalvalidationArray['Status Due Date'] == 'Required')
                    {
                        if(this.tasktype.tstatus != this.editRowData.taskstatus){
                            if((this.tasktype.tstatus != 'OnHold') && (this.tasktype.tstatus != 'Completed') && (this.tasktype.tstatus != 'Discarded')){
                                if(!this.tasktype.duedate || this.tasktype.duedate == "" || this.tasktype.duedate == null){
                                    this.errors.tasktype.duedate="Status Due Date is required!";
                                    isValid= false;
                                }
                            }
                        }
                    }
                } else {
                    if(this.tasktype.tstatus != this.editRowData.taskstatus){
                        if((this.tasktype.tstatus != 'OnHold') && (this.tasktype.tstatus != 'Completed') && (this.tasktype.tstatus != 'Discarded')){
                            if(!this.tasktype.duedate || this.tasktype.duedate == "" || this.tasktype.duedate == null){
                                this.errors.tasktype.duedate="Status due date is required";
                                isValid= false;
                            }
                        }else{
                            this.errors.tasktype.duedate = ''
                        }
                    }
                }
                
                if(this.finalvalidationArray['Business Project Name'])
                {
                    this.errors.tasktype.businessname='';
                    if(this.finalvalidationArray['Business Project Name'] == 'Required')
                    {
                        if(!this.tasktype.businessname){
                            this.errors.tasktype.businessname="Business Project Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.businessname){
                        this.errors.tasktype.businessname="Business project name is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.businessname='';
                    }
                }
                
                if(this.finalvalidationArray['Subscribers'])
                {
                    this.errors.tasktype.subscribers='';
                    if(this.finalvalidationArray['Subscribers'] == 'Required')
                    {
                        if((!this.tasktype.subscribers) || (this.tasktype.subscribers && this.tasktype.subscribers.length < 1)) {
                            this.errors.tasktype.subscribers="Subscribers are required!";
                            isValid= false;
                        }
                    }
                } else {
                    if((!this.tasktype.subscribers) || (this.tasktype.subscribers && this.tasktype.subscribers.length < 1)) {
                        this.errors.tasktype.subscribers = 'Subscribers are required'
                        isValid = false
                    } else {
                        this.errors.tasktype.subscribers = ''
                    }
                }

                if(this.finalvalidationArray['Project Module'])
                {
                    this.errors.tasktype.projectmodules="";
                    if(this.finalvalidationArray['Project Module'] == 'Required')
                    {
                        if(this.tasktype.projectmodules == null || this.tasktype.projectmodules == '' || this.tasktype.projectmodules == []) {
                            this.errors.tasktype.projectmodules="Project Module is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(this.tasktype.projectmodules == null || this.tasktype.projectmodules == '' || this.tasktype.projectmodules == []) {
                        this.errors.tasktype.projectmodules="Project Module is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.projectmodules="";
                    }
                }
            
                let allDataFilled =  this.tasktype.type
                && this.tasktype.key
                && this.tasktype.references && this.tasktype.bsh
                && this.tasktype.dept 
                if(!allDataFilled) {
                    isValid = false
                }
            }else if(mdl=="doc"){
                for(const index in this.docs){
                    if(!this.validText(this.docs[index].title) || !this.validText(this.docs[index].comment) || !this.validText(this.docs[index].desc)){
                        isValid=false
                    }
                }                
            }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                isChecked:null,
                validestimatehrs:null,
                tasktype:{
                    name:null,
                    projectid:null,
                    desc:null,
                    level:null,
                    startdate: null,
                    enddate: null,
                    duedate: null
                }
            };
        },
        validText : function(inputval) {
            let re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validRedmineID : function(inputval) {
            let re = /[A-Za-z0-9].{2,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            if(tblNm=="t9"){
                if(this.tasktype.name){
                    this.inputval = this.tasktype.name
                    this.valid = true;
                }
                this.valid=false;
                if(this.tasktype.desc){
                    this.inputval = this.tasktype.desc
                    this.valid = true
                }else{
                    this.tasktype.descerror = true
                }
                this.valid=false;
                if(this.tasktype.level){
                    this.inputval = this.tasktype.level
                    this.valid = true
                }else{
                    this.tasktype.levelerror = true
                }
                return this.valid;
            }
            if( this.inputval && this.validText(this.inputval)){
                this.valid = true;
            }
            
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateFormatnew(value) {
            if (!value) return "";
            return moment(String(value)).format('YYYY-MM-DD');
        },
        getProjectAllocatedToEmp: function(node) {
            if(node && node.length > 3){
                this.isLoading = true
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname: node.toLowerCase(),
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    isfrompage: 'updatepage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers': { 'authorization': this.tokendata }
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.projectlist = result.data.data;
                    localStorage.setItem('projectlist', JSON.stringify(result.data.data))
                } else {
                    this.tasktype.projectlist = [];
                    localStorage.removeItem('projectlist')
                }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        projectChange(pid){
            if(pid && pid.length>0){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    empid: parseInt(this.undt.userid),
                    projectidarr: pid,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    isfrompage: 'isprojectid'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        if(result.data.data && result.data.data.length > 0){
                            this.tasktype.projectlist = result.data.data;
                        }else{
                            this.tasktype.projectlist = []
                        }
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getBusinessname() {
            this.input = {
                empcode: this.undt.userid,
                useremail:this.undt.username
            }
            axios({
                method: "POST",
                url: "api/listing/getBusinessname",
                data: this.input,
                headers: { authorization : this.tokendata },
            })
            .then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.businessnamelist = result.data.data.rows
                } else {
                    this.tasktype.businessnamelist = []
                }
            });
        },
        /*To get Project allocated Manager details*/
        getProjectManagerDetails(roleid,projectid) {
            axios({
                method: "POST",
                url: "api/listing/getProjectManagerDetails",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,projectidarr:projectid,empcode: this.undt.userid, useremail: this.undt.username},
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==477){
                        this.tasktype.pmlist = result.data.data.rows
                    }else if(roleid==492){
                        this.tasktype.emlist = result.data.data.rows
                    }else if(roleid==503){
                        this.tasktype.proglist = result.data.data.rows
                    }
                }else{
                    if(roleid==477){
                        this.tasktype.pmlist = [];
                    }else if(roleid==492){
                        this.tasktype.emlist = [];
                    }else if(roleid==503){
                        this.tasktype.proglist = [];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getTaskmanager(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.empid,
                useremail: this.undt.username,
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid, useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==131){
                        this.tasktype.taskmanagerlist=result.data.data.rows;
                    }else if (roleid==513){
                        this.tasktype.taskownerlist=result.data.data.rows;
                    }
                    
                }else{
                    if(roleid==131){
                        this.tasktype.taskmanagerlist=[]
                    }else if (roleid==513){
                        this.tasktype.taskownerlist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getApplicationdata(mdl,curracttab){
            this.isLoading=true
            this.pageoffset=1
            
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            if(mdl=="t11"){
                this.inputappl.limit=500
            }
            this.inputappl.empcode = this.undt.userid
            this.inputappl.useremail= this.undt.username
            let apiURL='api/master/commonmaster/list'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.projectType=[];
                    this.projectType=result.data.data.rows
                    let tmpClassNm=''
                        if(mdl == 't25'){
                            if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                            else if(curracttab == 'tasktype-tab'){
                                this.task.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasknamemaster=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine -tab'){
                                this.typenamelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't17'){
                            if(curracttab == 'taskname-tab'){
                                tmpClassNm='tasknametaskleveldd'
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true                                
                            }
                        }else if(mdl == 't2'){
                            tmpClassNm='devBusinessVerticleData'
                        }else if(mdl == 't23'){
                            if(curracttab == 'tasktype-tab'){
                                this.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't24'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasklevel=result.data.data.rows
                                return true
                            }else if(curracttab == 'task'){
                                this.tasklevellist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't11'){
                            this.tasktype.projectlist=result.data.data.rows
                        }                              
                        else if(mdl == 't21'){
                            if(curracttab == 'taskassignment-tab'){
                                this.assignment.employeeoptions=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.employeeoptions=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't18'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskdefineList=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskassignment-tab'){
                                this.assignment.tasklist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't19'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskassignmentList=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't9'){
                            if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.businesstasklist=result.data.data.rows
                                return true
                            }
                        }
                        let payload={
                            'classnm':tmpClassNm
                        }
                        this.fillDropdown(payload);
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
            // return retResponse;
        },
        fillDropdown(data){
            return ;
        },
        selectserPriority(state, value) {
            this.tasktype.key = null
            this.tasktype.key = state
        },
        selecttypePriority(state, value) {
            this.tasktype.type = null
            this.tasktype.type = state

        },
        selectrefPriority(state, value) {
            this.tasktype.references = null
            this.tasktype.references = state
        },
        selecttaskstatus(state, value) {
            this.tasktype.tstatus = null
            this.tasktype.tstatus = state
            if((state != 'OnHold') && (state != 'Completed') && (state != 'Discarded')){
                if(this.tasktype.tstatus != this.editRowData.taskstatus){
                    this.tasktype.duedate = ''
                    this.errors.tasktype.duedate = 'Status due date is required'
                    // this.tasktype.taskestimates = null
                    this.errors.tasktype.taskestimates = ""
                    if(this.tasktype.tstatus == "Tech WIP" ){
                        this.tasktype.startdate = null
                        this.tasktype.enddate = null
                    }
                }
            }else{
                this.errors.tasktype.duedate = ''
            }
            if((state == "Product WIP") || (state== "Product Pipeline") || (state == "Product Completed") || (state == "OnHold") || (state == "Discarded")) {
                this.errors.tasktype.taskestimates = ''
            }
            if(this.tasktype.tstatus != this.editRowData.taskstatus){
                if (this.tasktype.tstatus == 'Product WIP') {
                    this.tasktype.startdate = this.taskstartdate
                    this.tasktype.enddate = ''
                    //this.errors.tasktype.enddate = 'Estimated date is required'
                    this.isdisableds= true
                }else{
                    this.isdisableds= false
                    this.tasktype.startdate = this.editRowData.startdate
                    this.tasktype.enddate = this.editRowData.estimatedddate
                    //this.errors.tasktype.enddate = ''
                }
            }
            if (this.tasktype.tstatus == 'Product WIP') {
                this.isdisableds= true
                if(this.tasktype.startdate && this.tasktype.startdate!=null) {
                    this.isenableestimatedate = false;
                } else {
                    this.isenableestimatedate = true;
                }
            }else{
                this.isdisableds= false
            }
            this.getprojectmaprolide(this.loginuscondition,'1',this.tasktype.projectid);
        },
        selestimateshours(tstatus, value) {
            if(tstatus && (this.tasktype == "Product WIP") || (this.tasktype == "Product Pipeline") || (this.tasktype == "Product Completed") || (this.tasktype.tstatus == "OnHold") || (this.tasktype.tstatus == "Discarded")|| (this.tasktype.tstatus == "Tech and Business Review") || (this.tasktype.tstatus == "Product WIP 2")) {
                this.errors.tasktype.taskestimates = ''
            } else {
                if(tstatus){
                    this.errors.tasktype.taskestimates = '';
                }else{
                    this.errors.tasktype.taskestimates = 'Ballpark Estimate in days is required'
                }
            }
        },
        selectBSH(state) {
            if(!state){
                this.errors.tasktype.bsh = 'HOD name is required'
            } else {
                this.errors.tasktype.bsh = ''         
            }
        },
        selectBusinessname(state){
            if(!state){
                this.errors.tasktype.businessname = 'Business project name is required'
            } else {
                this.errors.tasktype.businessname = ''         
            }
        },
        selectbtmDept(state, value) {
            if(!state){
                this.tasktype.depterr = true
                this.errors.tasktype.dept = "Department is required"
                this.tasktype.bshlist = []
            } else {                
                this.tasktype.depterr = false
                this.errors.tasktype.dept = ""
                if(this.tasktype.projectid.length>0){
                    this.getBSHlist(state, this.tasktype.projectid)
                }
            }
        },
        deSelectbtmDept() {
            this.tasktype.bsh = null
            this.tasktype.bshlist = []
        },
        getBSHlist(dept, projectid) {
            axios({
                method: "POST",
                url: "api/listing/getBTCHODlist",
                data: { dept:[dept],empcode: this.undt.userid,useremail: this.undt.username, projectid:projectid },
                headers: { authorization : this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.bshlist = result.data.data.rows
                } else {
                    this.tasktype.bshlist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        seltaskleveldata(state,value){
            this.tasktype.level=null
            this.tasktype.level=state
            if(!state && this.isAddState == false){
                this.tasktype.levelerror=true
            }else{                
                this.tasktype.levelerror=false
            }
        },
        selectsecurity(state){
            if(this.tasktype.tstatus =='Product Pipeline'||this.tasktype.tstatus =='Product Completed'||this.tasktype.tstatus =='Product WIP'||this.tasktype.tstatus =='Product WIP 2'||this.tasktype.tstatus =='Tech and Business Review'|| this.tasktype.tstatus == 'OnHold' || this.tasktype.tstatus == 'Discarded')
                {
                    this.errors.tasktype.securitytag=''
                }else if(state==null){
                this.errors.tasktype.securitytag = 'Security Sensitive is required'
            } else {
                this.errors.tasktype.securitytag = ''
            } 
        },
        selProjectMOdules(state){
            this.tasktype.projectmodules = null
            this.tasktype.projectmodules = state
            if(!state) {
                this.errors.tasktype.projectmodules = 'Project Module is required'
            } else {
                this.errors.tasktype.projectmodules = ''
            }

            //DO not change this logic without discuss with core team
            let newarr = state;
            let prevarr = this.tasktype.pmodulelist;
            let added = null;
            let addedarr = [];
            let finalArr = this.moduledocs;
            let addedConter = 0;
            let removedConter = 0;

            //check for items added or removed
            if(newarr.length > this.prevarrlen){
              addedConter++; 
            }else if(newarr.length < this.prevarrlen){
              removedConter++;
            }

            //pushing the items into the newly added array
            for(let n=0; n<newarr.length; n++){
              let isfound = false;
              for(let p=0; p<prevarr.length; p++){
                if(newarr[n]===prevarr[p]){
                    isfound = true;
                }
              }
              if(!isfound){
                  added = newarr[n] //set
                  addedarr.push(newarr[n]) //pushed
              }
            }

            //creating the new items
            if(addedConter>0){
              if(added!=null && [added].length>0){
                if(!state.includes(added)==false){
                  const alredyexist = finalArr.find((obj) => obj.projectmoduleid === added);
                  if(alredyexist==undefined){
                      this.prevarrlen = state.length;
                      this.getProjectByBusinessTaskUPDATE([added])
                  }
                }
              }
                //checking for prev added element - to add again same element
                if(prevarr.includes(added)==false){
                  prevarr.forEach(preObj => {
                      const objWithIdIndex = finalArr.find((obj) => obj.projectmoduleid === preObj);
                      if(objWithIdIndex==undefined){
                        if(state.includes(preObj)==true){
                          this.getProjectByBusinessTaskEDIT([preObj], this.$route.query.businessid, 'update', true)
                        }
                      }
                  });
                } 
            }

            if((newarr.length != (finalArr.length+1))){
              finalArr.forEach(finalarrObj => {
                let foundq = false;
                let removednew = [];
                  newarr.forEach(onnk=>{
                    if(state.includes(finalarrObj.projectmoduleid)){
                      foundq = true;
                    }
                  })
                  if(!foundq){
                    const objWithIdIndex = finalArr.findIndex((obj) => obj.projectmoduleid === finalarrObj.projectmoduleid);
                    if (objWithIdIndex > -1) {
                      finalArr.splice(objWithIdIndex, 1);
                      this.prevarrlen = state.length;
                    }
                  }
              })
            }

            //removing the items
            if(removedConter>0){
              prevarr.forEach(preObj => {
              const found = newarr.find(newObj => JSON.stringify(newObj) == JSON.stringify(preObj));
                if(!found) {
                  if(preObj){
                    const objWithIdIndex = finalArr.findIndex((obj) => obj.projectmoduleid === preObj);
                    if (objWithIdIndex > -1) {
                      finalArr.splice(objWithIdIndex, 1);
                      this.prevarrlen = state.length;
                    }
                  }
                }
              });
            }
        },
        getProjectQAdata(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==509 && state.length >0){
                        this.tasktype.qaleadlist=result.data.data.rows;
                    }else if (roleid==508 && state.length >0){
                        this.tasktype.qamnglist=result.data.data.rows;
                    }
                }else{
                    if(roleid==509){
                        this.tasktype.qaleadlist=[]
                    }else if (roleid==508){
                        this.tasktype.qamnglist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        selSubscribers(state) {
            this.tasktype.subscribers = null
            this.tasktype.subscribers = state
            if(!state || (state && state.length < 1)) {
                this.errors.tasktype.subscribers = 'Subscribers are required'
            } else {
                this.errors.tasktype.subscribers = ''
            }
        },
        selTaskmanager(state){
            if(!state){
                this.errors.tasktype.taskmanager = 'Task Manager is required'
            } else {
                this.errors.tasktype.taskmanager = ''         
            }
        },
        selTaskOwner(state){
            if(!state){
                this.errors.tasktype.taskowner = 'Task Owner is required'
            } else {
                this.errors.tasktype.taskowner = ''         
            }
        },
        seltpprojectdata(state,value){
            this.tasktype.projectid=null
            this.tasktype.pmlist = []
            this.tasktype.emlist = []
            this.tasktype.proglist = []
            if (!state.length >0) {
                this.tasktype.qalead = null
                this.tasktype.qamanager = null
                this.tasktype.qaleadlist = []
                this.tasktype.qamnglist = []
            }
            if(this.havingsubtasks == true) {
                this.tasktype.projectid=state.map(d => parseInt(d))
            } else {
                this.tasktype.projectid=state.map(d => d.toString())
            }
            this.tasktype.projectmodulelist = []
            this.tasktype.projectmodules = null

            if(!state){
                this.tasktype.projectlist = []
                this.tasktype.subscribers = null
                this.tasktype.projectmodules = null
                this.tasktype.qalead = null
                this.tasktype.qamanager = null
                this.tasktype.projectmodulelist = []
                this.tasktype.pmlist = []
                this.tasktype.proglist = []
                this.tasktype.emlist = []
                this.tasktype.qaleadlist = []
                this.tasktype.qamnglist = []
                this.tasktype.projectmoduleid = null
                this.errors.tasktype.projectid="Project Required"
            }else{                
                this.errors.tasktype.projectid=null
                this.getEmpallocatedtoProject(state)
                this.getProjectbyMOdules(state)
                this.getProjectManagerDetails(492,state)
                this.getProjectManagerDetails(477,state)
                this.getProjectManagerDetails(503,state)
                this.getTaskmanager(131,state)
                this.getTaskmanager(513,state)
                this.getProjectQAdata(509,state);
                this.getProjectQAdata(508,state);
                this.getTasktypeConfigLists(state);
                if(state && state.length > 0){
                    this.getModules(state,this.$route.query.businessid,'businesstask')
                }
                if(this.issubtask != false && state != this.editRowData.projectist){
                    this.getBStatusConfigList(state,this.tasktype.tstatus)
                }
                this.getprojectmaprolide(this.loginuscondition,'1', this.tasktype.projectid);
            }
        },
        deSelectbSubscribers() {
            this.tasktype.subscribers = null
        },
        notBeforeToday: function (date) {
            this.tasktype.duedate = "";
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        // notBeforeTodaydate: function (date) {
        //     return date < new Date(new Date().setHours(0, 0, 0, 0));
        // },
        disabledBefore(date) {
            const oneMonthFromFromDate = moment(this.tasktype.startdate).add(1, 'month');
            const startOfOneMonth = oneMonthFromFromDate.startOf('day').toDate();
            const startOfFromDate = moment(this.tasktype.startdate).startOf('day').toDate();
            return date > startOfOneMonth || date < startOfFromDate;
        },
        onSelect(event){
            this.files= event.target.files;
            this.isdisabled=false             
        },
        onSelectDoc(event){
            this.myfiles = event.target.files;
            if(this.myfiles.length>0)
            this.isdisabled=true             
        },
        saveDocument(){
            this.input=[]
            let isValid = this.validateForm('doc',this.docs)
            if(isValid){
                this.isLoading = true;
                if(this.attachment && this.attachment.length == this.docs.length){
                    for(const index in this.attachment){
                        this.input.push({
                            documentid:this.attachment[index].batchid,
                            documentname:this.attachment[index].filename,
                            title:this.docs[index].title,
                            desc:this.docs[index].desc,
                            comment:this.docs[index].comment,
                            version:'v1',
                            createdby:this.undt.username,
                            taskid:this.tasktype.taskid,
                            empcode: this.undt.userid,
                            useremail: this.undt.username
                        })
                    }
                    if(this.input.length>0){
                        let url = "api/workstep/bulkinsert";
                        axios({
                            method: "POST",
                            url: url,
                            data: {document:this.input},
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading = false;
                            this.attachment=[]
                            if (result.data.errorCode == 0) {                                
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                                this.docsuploadcollapse()
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Failed to upload document",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        }).catch(e => {
                        this.displayError(e)
                    });
                    }else{
                        this.isLoading = false;
                    }
                }else {
                    Swal.fire({
                        title: "Failed",
                        text: "All Fields are compulsory",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading = false;
                }
            }
        },
        async onUploadDoc(event, index, doclist, moduletype=null, rowindex) {    
            this.apiURL = "api/s3upload/uploadDocumentToS3";
            if(this.myfiles.length) {
                this.isLoading=true
                this.myfiles.forEach(file => {
                    if (file.size > 1024 * 1024 * 21) {
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 21mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false; 
                    } 
                    if(file.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file);
                        formData.append("empcode", this.undt.userid);
                        formData.append("useremail", this.undt.username);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            this.myfiles=[];
                            if (result.data.status) {
                                this.isdisabled=false
                                doclist.attachment= result.data.data
                                doclist.isUploaded = true
                                this.moduledocs[index]['modulefiles'][rowindex]['tempfilename'] = result.data.data;
                                this.moduledocs[index]['modulefiles'][rowindex]['tempdoctitile'] = doclist.title;
                               
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        })
                    } 
                })
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "Select document",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }            
        },
        getTasktypeConfigLists(projectid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: parseInt(projectid),
                trackername: 'BTM Task Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    slist.sort((a, b) => a.label.localeCompare(b.label));
                    this.btmtasktypelist = slist;                    
                } else {
                    this.btmtasktypelist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        async onUpload(event,index) {            
            this.apiURL = "api/s3upload/uploadDocumentToS3";
            if(this.files.length) {
                this.isLoading=true
                this.files.forEach(file => {
                    if (file.size > 1024 * 1024 * 21) {
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 21mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false; 
                    } 
                    if(file.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file);
                        formData.append("empcode", this.undt.userid);
                        formData.append("useremail", this.undt.username);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            this.files=[];
                            if (result.data.status) {
                                if(this.attachment){
                                    this.attachment[index] = result.data.data;
                                }else{
                                    this.attachment = []
                                    this.attachment[index]= result.data.data;
                                }
                                this.attachmentview = result.data.data;
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        }).catch(e => {
                            this.displayError(e)
                        })
                    } 
                })
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "Select document",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }            
        },
    }
}
</script>
<style>
.ck-content{
    min-height: 150px !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.display{
    display: none !important;
}

.pop-up {
    width: 70% !important;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>