<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-9 px-0">
                        <h4 class="content-header-title float-left mb-0">Test Cases Group and Test Cases Bulk Mapping</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to='/testcasetask/list'>Test Case</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Test Cases Bulk Mapping</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-warning filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
                <div class="container-fluid" >
                <div class="row" >
                    <div class="w-auto px-1 mobile-padding-0-2">
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                        </div>
                    </div>
                    <h4 class="card-title d-inline-flex ml-1">Search Bulk Mapping Details</h4>
                    </div>
                    <div class="ml-auto d-flex">
                        <div class="px-1 text-right mobile-padding-0-2" v-if="pagefeatures.createbulkgroup" >
                            <div class="input-group-append float-right">
                                <router-link to="/testcase/BulkmappingCreate"><span class="btn btn-sm btn-relief-success" > <span class="mobile_hide_bt">Create Bulk Mapping </span><plus-circle-icon size="1.5x"></plus-circle-icon> </span></router-link>
                            </div>
                        </div>
                        <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                </span>
                </div>
                </div>
                </div>
            </div>
            <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
                <div class="card-body pt-1 px-sm-mobile-10">
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                       <label class="form-label lable-left">Group Code</label>
                      
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 char of Group Code" :options="groupcodelist" v-model="search.mappingcode"  v-on:search-change="mapCodeChangeSearch" @input="clearCodeList" :maxlength="maxLength"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                    <label class="form-label lable-left">Group Name</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 char of Group Name" v-model="search.groupmappname" v-on:search-change="groupmapnameChange" :options="groupmapnameList"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                    <label class="form-label lable-left">Status</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="search.status" :options="statuslist" />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 px-0">
                    <button type="submit" class="btn btn-relief-primary mt-2 tcbuilkbtns" @click="getMappingList(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 tcbuilkbtns" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                    <button type="move" class="btn btn-relief-primary mt-2 tcbuilkbtns" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <section id="card-navigation">
                <div class="file-manager-content-body">
                    <div class="drives">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="proModuleList">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="table-responsive datatableview">
                                                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="Bulk Mapping">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="srno first-col-sticky align-middle py-1">Sr#</th>
                                                                    <th scope="col" class="second-col-sticky align-middle">Group Code</th>
                                                                    <th scope="col" class="align-middle">Group Name</th>
                                                                    <th scope="col" class="align-middle">Status</th>
                                                                    <th scope="col" class="align-middle">Created Details</th>
                                                                    <th scope="col" class="align-middle">Date Details</th>
                                                                    <th scope="col" class="clsaction align-middle">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-for="(data,index) in mappinglist">        
                                                                    <tr v-bind:key="data.moduleid">
                                                                        <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                        <td data-column="Group Code" class="second-col-sticky"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">
                                                                            <span class="font-weight-bold sitebluecolor">{{data.groupmappingcode}}</span>
                                                                        </td>
                                                                        <td data-column="Group Name"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">
                                                                            <span class="font-weight-bold sitebluecolor">{{data.modulename}}</span>
                                                                        </td>
                                                                        <td data-column="Status">
                                                                            <span  class="font-weight-bold capitalisetext">{{data.status}}</span>
                                                                        </td>
                                                                        <td data-column="Created Details">
                                                                            <span class="release_hr_tag_span"> Created By :</span>
                                                                            {{data.createdby.split("@")[0]}}
                                                                            <hr class="release_hr_tag_tab">
                                                                            <span class="release_hr_tag_span"> Created Date :</span>
                                                                            {{dateMonthFormat(data.createddate)}}
                                                                        </td>
                                                                        <td data-column="Date Details">
                                                                            <span class="release_hr_tag_span"> Reviewed Date :</span>
                                                                            {{data.reviewddate?dateMonthFormat(data.reviewddate):'NA'}}
                                                                            <hr class="release_hr_tag_tab">
                                                                            <span class="release_hr_tag_span"> Approved Date :</span>
                                                                            {{data.approveddate?dateMonthFormat(data.approveddate):'NA'}}
                                                                        </td>
                                                                        <td data-column="Actions" class="clsaction" >
                                                                            <div class="dropdown">
                                                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <a class="dropdown-item devEditEle" v-if="data.status != 'Approved'" @click="redirecttourl('edit', data)" href="javascript:void(0);">
                                                                                        <div class="d-flex align-items-center">
                                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                            <span>Edit</span>
                                                                                        </div>
                                                                                    </a>
                                                                                    <a v-else-if="data.status == 'Approved' && pagefeatures.approvebulkgroup" class="dropdown-item devEditEle"  @click="redirecttourl('edit', data)" href="javascript:void(0);">
                                                                                        <div class="d-flex align-items-center">
                                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                            <span>Edit</span>
                                                                                        </div>
                                                                                    </a>
                                                                                    <a href="javascript:void(0);" class="dropdown-item devEditEle" @click="redirecttourl('view', data)">
                                                                                        <div class="d-flex align-items-center"> 
                                                                                        <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                                                        <span>View Details</span>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="mappinglist.length==0 && isresponce">
                                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                    <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<style scoped>
.tcbuilkbtns{
    margin:2px 5px;
}

</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,EyeIcon,SearchIcon,MoreVerticalIcon,XIcon,ExternalLinkIcon} from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
    name:'TCBulkMapping',
    mixins: [ commonMethods ],
    components:{
        Loading,
        Treeselect,
        VueElementLoading,
        Pagination,Edit2Icon,EyeIcon,
        MoreVerticalIcon,
        PlusCircleIcon,PlusSquareIcon,
        SearchIcon,
        XIcon,ExternalLinkIcon
    },
    data(){
        return{
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            mappinglist:[],
            tokendata:null,
            isSearchBoxActive: false,
            card_content:'card-content',
            collapse:'collapse',
            maxLength:14,
            search:{
                mappingcode: null,
                groupmappname: null,
                status: null
            },
            groupcodelist: [],
            groupmapnameList:[],
            statuslist: apiUrl.tcscriptstatuslist.sort((a, b) => a.label.localeCompare(b.label)),
            isresponce: false,
            pagefeatures:[]
        }
    },
    created() {
        this.pagefeatures = {};
        var pagefeatures1 = JSON.parse(
          window.atob(window.localStorage.getItem("pagefeatures"))
        );
        pagefeatures1.forEach((element) => {
          if (element.page === "/testcase/groupbulkmapping") {
            this.pagefeatures[element.featurename] = element.featureaccess;
          }
        });
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)  
                this.tokendata = window.localStorage.getItem('token');
                this.email = userdata.username
                this.empid = userdata.userid
                this.undt = userdata
                // this.getMappingList();
                if(this.$route.params.search){
                    let search = JSON.parse(localStorage.getItem('tcbulkmapsearchItems'));
                    if(search){
                        this.getSearchItems(search);
                        this.getMappingList();
                    }else{
                        localStorage.removeItem('tcbulkmapsearchItems');
                        this.getMappingList();
                    }
                }else{
                    localStorage.removeItem('tcbulkmapsearchItems');
                    this.getMappingList();
                }
            }
        }
        $(".filter_rotation_onlick").click(function(){
            $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getMappingList()
        },
        searchBoxCollapse() {
            this.isSearchBoxActive = !this.isSearchBoxActive
        },
        getSearchItems(search) {
            this.groupcodelist = JSON.parse(localStorage.getItem('groupcodelist'));
            this.search.mappingcode = search.mappingcode;
            this.groupmapnameList = JSON.parse(localStorage.getItem('groupmapnameList'));
            this.search.groupmappname = search.groupmappname;
            this.search.status = search.status;
            this.isSearchBoxActive = true;
        },
        getMappingList(objThis) {
            if(objThis) {
                this.page=1
                localStorage.setItem('tcbulkmapsearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            
            let search = JSON.parse(localStorage.getItem('tcbulkmapsearchItems'))
            this.input = {
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid 
            };
            if(search){
                this.input.mappingcode = search.mappingcode
                this.input.groupmappname = search.groupmappname
                this.input.status = search.status
                this.input.offset= this.pageoffset
            }
            let url = 'api/groupbulkmapping/getMappingList' 
            axios({
                method: 'POST',
                url,
                data: this.input,
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.isresponce = true;
                    this.mappinglist = result.data.data.rows
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    this.mappinglist = []
                    this.totalcount = 0
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                }
            }).catch(e => {
    this.displayError(e)
    })
        },
        mapCodeChangeSearch(node) {
            if(node && node.length>1) {
                axios({
                'method': 'POST',
                'url': 'api/groupbulkmapping/getgroupmappingcode',
                'data': {
                    groupmappingcode: node.toUpperCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                },
                'headers':{'authorization':this.tokendata}
                }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.groupcodelist = result.data.data;
                    localStorage.setItem('groupcodelist', JSON.stringify(result.data.data));
                } else {
                    this.groupcodelist = [];
                    localStorage.removeItem('groupcodelist');
                }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        clearCodeList(state) {
            if(!state) {
                this.search.mappingcode = null;
            }
        },
        groupmapnameChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/groupbulkmapping/getGroupmapnameList";
                this.input = {
                    groupmappname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.groupmapnameList = result.data.data;
                    localStorage.setItem('groupmapnameList', JSON.stringify(result.data.data))
                } else {
                    this.groupmapnameList = [];
                    localStorage.removeItem('groupmapnameList')
                }
                }).catch(e => {
    this.displayError(e)
    })
            } 
        },
        clearSearchFields: function() {
            this.groupcodelist = [];
            this.search.mappingcode=null;
            this.groupmapnameList = [];
            this.search.groupmappname=null;
            this.search.status = null;
            localStorage.removeItem('tcbulkmapsearchItems');
            this.page=1;
            this.getMappingList();
        },

        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
            
        redirecttourl:function(btnstate,rowdata){
            if(rowdata){
                rowdata.backbtnstate=btnstate
                if(btnstate=="edit"){
                    this.$router.push({ 
                        path: `/testcase/BulkmappingUpdate/?groupmappingid=${rowdata.groupmappingid}`,
                        params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                        }
                    })
                } else if(btnstate=="view"){
                    this.$router.push({ 
                        path: `/testcase/groupbulkView/?groupmappingid=${rowdata.groupmappingid}`,
                        params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                        }
                    })
                }      
            }
        },
        dateMONFormat(value){
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },

        ExportXL() {
        this.islistLoading = true;
        this.exportxlsdata = [];
        this.exporterr = "";
        this.input = {
            limit:null,
            offset:null,
            mappingcode : this.search.mappingcode,
            groupmappname : this.search.groupmappname,
            status : this.search.status,
            useremail: this.undt.username,
            empcode: this.undt.userid
        };
        axios({
            method: "POST",
            url: `api/groupbulkmapping/getMappingList`,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.islistLoading = false;
            if (result.data.errorCode == 0) {
                this.exportxlsdata = result.data.data.rows;
                let filename = "";
                this.download(result.data.data.rows,filename)
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }
        }).catch(e => {
    this.displayError(e)
    })
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
            let ShowLabel = 'TCBulkMappingReportDetails_'+filename
            let JSONData = readExportdata
            let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            let CSV = ''
            if (ShowLabel) {
                CSV = 'Sr#,Group Code,Group Name,Status,Created Date,Created By,Reviewed Date,Approved Date' + '\r\n'
            }
            let row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let mappingcode = index.hasOwnProperty('groupmappingcode') ? index.groupmappingcode == null||index.groupmappingcode.toLowerCase() == 'na'?'':index.groupmappingcode : '';
            let groupmappname = index.hasOwnProperty('modulename') ? index.modulename == null||index.modulename.toLowerCase() == 'na'?'':index.modulename : '';
            let status = index.hasOwnProperty('status') ? index.status : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
            let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
            let reviewddate = index.hasOwnProperty('reviewddate') ? index.reviewddate : '';
            let approveddate = index.hasOwnProperty('approveddate') ? index.approveddate : '';
            createddate = this.dateMONFormat(createddate)
            reviewddate = this.dateMONFormat(reviewddate)
            approveddate = this.dateMONFormat(approveddate)
                row += '"' + srno + '",',
                row += '"' + mappingcode + '",',
                row += '"' + groupmappname + '",',
                row += '"' + status + '",',
                row += '"' + createddate + '",'
                row += '"' + createdby + '",',
                row += '"' + reviewddate + '",',
                row += '"' + approveddate + '",',
                row += '\r\n'
            }
        CSV += row
        if (CSV === '') {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
          return;
        }
        let fileName = 'TCBulkMappingDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        } else {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        }
    },
    export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    }

    }
}
</script>