<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-1 px-0 pad_top_mar_bot-5">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Project Modules</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to='/dashboard'>Dashboard</router-link></li>
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Wizard',path:'project',btnstate:'view',rowdata:backpagedata})">Projects</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">
                    <div class="row" v-if="showfilterboxsection">
                        <div class="col-12">
                             <div class="card card-body" style="margin-top: 10px">
                              <div class="row breadcrumbs-top">
                                <div class="col-12 title">
                                   <h4 class="card-title">SEARCH PROJECT DETAILS</h4>
                                 </div>
                              </div>
                             <div class="row" >
                             <div class="col-sm-6 col-md-6 col-lg-3 mb-1 " style="margin-left:40px">
                                                    <label class="form-label lable-left">Company Name</label>
                                                    <treeselect
                                                    class="projectmdllistdd capitalisetext"
                                                    v-model="search.searchCompany"
                                                    :options="search.companylistopts"
                                                      @input="selectcompany"
                                                    />
                                                </div>
                                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label lable-left">Project</label>
                                                    <treeselect
                                                    class="projectmdllistdd capitalisetext"
                                                    v-model="search.searchProject"
                                                    :options="search.projectlistopts"
                                                    
                                                    />
                                                </div>
                                                   
                                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                                    <button
                                                    type="submit"
                                                    class="btn btn-primary mt-2 mr-1"
                                                    @click="getfilterprojectmodlist()"
                                                    >
                                                    Search
                                                    </button>
                                                    <button
                                                    type="reset"
                                                    class="btn btn-outline-secondary mt-2"
                                                    @click="resetRecords()"
                                                    >
                                                    Cancel
                                                    </button>
                                                </div>
                                                </div>
                                             </div> 
                                            <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important; cursor : pointer;" v-on:click="projectCollapse">
                                                <div class="card-header">
                                                    <div class="avatar bg-light-info rounded" style="margin-left:39%">
                                                     <div class="avatar-content">
                                                    <grid-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></grid-icon>
                                                </div>
                                            </div>
                                        <h4 class="card-title" style="margin-left:-449px;">PROJECT DETAILS</h4>
                                        <div class="heading-elements" style="color:blue;">
                                            <ul class="list-inline mb-0">
                                                <li>
                                                    <a data-action="collapse"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-if="showprojects" style="margin-top:10px">
                                    <div class="card-body">
                                        <div class="row" v-if="showprojects">
                                            <div  v-for="(data1,index1) in projectlist" class="col-lg-3 col-md-6 col-12" v-bind:key="index1">
                                                <div v-bind:key="data1.projectid" class="card border cursor-pointer" @click="getprojectmodulelist(data1)" :class="[stylevariation[data1.companyid].label, activeprojectid == data1.projectid ? 'bxshadow' : 'shadow-none']">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between text-white">
                                                            <box-icon size="40x" class="custom-class"></box-icon>
                                                        </div>
                                                        <div class="my-1">
                                                            <h4 class="capitalisetext text-white">{{data1.projectname}}</h4>
                                                            <h5 class="capitalisetext text-white">{{dateFormat(data1.createddate)}}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card border-warning filter_card_searchbgst" >
                        <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                            <div class="container-fluid">
                            <div class="row fc_my-05" >
                                <div class="px-1 w-auto mobile-padding-0-2">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                            ></search-icon>
                                        </div>
                                        </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search Project Module Details</h4>
                                </div>
                                <div class="ml-auto d-flex">
                                <div  class=" mx-1  text-right mobile-padding-0-2">
                                    <div class="input-group-append displayblk">
                                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" :data-target="currentmodule" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false">
                                             <span class="mobile_hide_bt">Create {{glbMdlLbl}}</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></button>
                                    </div>
                                </div>
                                <span class="rotetions_view_arrow mr-1"> 
                                <em class="las la-angle-down"></em>
                                </span>
                            </div>                                
                            </div>
                            </div>
                        </div>
                      <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                        <div class="card-body pt-2 px-sm-mobile-10">
                          <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                              <label class="form-label lable-left">Project Name</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search Project Name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selprojectid"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                              <label class="form-label lable-left">Application</label>
                              <treeselect class="projectmdllistdd" placeholder="Select Application Name" v-model="search.applicationMasterid" :options="search.applicationMasterOpts" @input="selApplicationid"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                              <label class="form-label lable-left">Project Module</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 4chars to search project module name" 
                              v-on:search-change="projectmoduleChange" v-model="search.projectModuleid" :options="search.projectModuleOpts"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                <label class="form-label lable-left">Project Module Status</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Project Module Status" v-model="search.modulestatus" :options="search.modulestatuslist"/>
                            </div>
                                                       
                            <div class="col-sm-6 col-md-6 col-lg-12 mb-0 text-right">
                            <lable> &nbsp;</lable>
                              <button type="submit" class="btn btn-relief-primary mt-1 mr-1" @click="getprojectmodulelist(search)"> <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                              <button type="reset" class="btn btn-outline-secondary mt-1 mr-1 " @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span></button>
                              <button type="move" class="btn btn-relief-primary mt-1" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="row" id="proModuleList">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="" style="padding-left:0px !important;padding-top:0px !important;">
                                                    <div class="row">                       
                                                        <div class="col-md-9">
                                                            <div class="input-group-append" v-if="showfilterboxsection">
                                                            </div>
                                                        </div> 
                                                        
                                                    </div>
                                                </div>
                                                <div class="table-responsive datatableview" style="" v-if="showtabledata">
                                                    <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="srno first-col-sticky py-1 align-middle">Sr#</th>
                                                                <th scope="col" class="second-col-sticky align-middle" style="min-width:180px;">Project Module Name</th>
                                                                <th scope="col" class="align-middle">Description</th>
                                                                <th scope="col" class="align-middle">Project Name</th>
                                                                <th scope="col" class="centeralign align-middle">Total Applications</th>
                                                                <th scope="col" class="centeralign align-middle">Total App Modules</th>
                                                                <th scope="col" class="align-middle" style="min-width:100px;">Created Date</th>
                                                                <th scope="col" class="clsaction align-middle">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(data,index) in companymasterlist">        
                                                                <tr v-bind:key="data.moduleid">
                                                                    <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                    <td data-column="Project Module Name" class="second-col-sticky">
                                                                        <span v-if="data.projectlevel !== 3" class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('view',data)">{{data.projectmodulename}}</span>
                                                                        <span v-else class="font-weight-normal capitalisetext">{{data.projectmodulename}} </span>
                                                                        <span class="px-1">
                                                                        <span v-if="data.isactive == 1" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">Active</span>
                                                                        <span v-else class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">Inactive</span>
                                                                       </span>
                                                                    </td>
                                                                    <td data-column="Description">
                                                                        <span class="font-weight-normal capitalisetext" v-if="data.description.length > 10">{{data.description.substring(0,10+1)+'...'}}</span>
                                                                        <span class="font-weight-normal capitalisetext" v-if="data.description.length <= 10">{{data.description}}</span>
                                                                    </td>
                                                                    <td data-column="Project Name">
                                                                        <span class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('projectId',data)">{{data.projectname}}</span>
                                                                    </td>
                                                                    <td data-column="Total Applications" class="centeralign">
                                                                        <span class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" style="cursor:pointer;" @click="redirecttourl('viewapp', data)">{{data.totalapp}}</span>
                                                                    </td>
                                                                    <td data-column="Total App Modules" class="centeralign">
                                                                        <span v-if="data.projectlevel !== 3" class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" style="cursor:pointer;" @click="redirecttourl('view', data)">{{data.totalappmodule}}</span>
                                                                        <span v-else class="font-weight-normal capitalisetext">{{data.totalappmodule}}</span>
                                                                    </td>
                                                                    <td data-column="Created Date">{{dateFormat(data.createddate)}}</td>
                                                                    <td data-column="Actions" class="clsaction">                                                                  
                                                                        <div class="dropdown">
                                                                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                            </button>
                                                                            <div class="dropdown-menu">
                                                                                <div v-if="data.projectlevel == 2">
                                                                                    <a class="dropdown-item devEditEle" href="javascript:void(0);" @click="redirecttowsurl('view',data)">
                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                    <span>View Workstep</span>
                                                                                    </a>
                                                                                    <a class="dropdown-item" @click="redirecttowsurl('edit',data)" href="javascript:void(0);" v-if="data.isactive ==1 && (data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                        <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                        <span>Add Workstep</span>
                                                                                    </a>
                                                                                </div>
                                                                                <div v-if="data.projectlevel >= 4 || !data.projectlevel">
                                                                                    <a class="dropdown-item" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                                        <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                        <span>View App. Module</span>
                                                                                    </a>
                                                                                    <a class="dropdown-item" @click="redirecttourl('edit',data)" href="javascript:void(0);" v-if="data.isactive ==1 && (data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                        <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                        <span>Add App. Module</span>
                                                                                    </a>
                                                                                </div>
                                                                                <a class="dropdown-item devEditEle" href="javascript:void(0);"   data-toggle="modal" :data-target="currentmodule" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                    <span>Edit</span>
                                                                                </a>
                                                                                <a
                                                                                    class="dropdown-item devEditEle"
                                                                                    v-if="data.isactive == 0 || data.isactive ==null || data.isactive ==''"
                                                                                    type="submit"
                                                                                    href="javascript:void(0);"
                                                                                    @click="moduleisactive(data,1)"
                                                                                    id="submitbtnt18"
                                                                                    data-backdrop="static" data-keyboard="false"
                                                                                >
                                                                                    <check-icon size="1.5x" class="custom-class mr-50"></check-icon>
                                                                                    <span> Active Project module</span> 
                                                                                </a>
                                                                                <a class="dropdown-item devEditEle"
                                                                                    v-else-if="data.isactive ==1"
                                                                                    type="submit"
                                                                                    href="javascript:void(0);"
                                                                                    @click="moduleisactive(data,0)"
                                                                                    id="submitbtnt18"
                                                                                    data-backdrop="static" data-keyboard="false"
                                                                                >
                                                                                <i class="custom-class mr-50 las las la-ban"></i>
                                                                                    <span> Deactivate Project module</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                            <template v-if="companymasterlist.length==0 && isresponce">
                                                                <tr><td colspan="14" style="text-align:center">No record found</td></tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                </div>
                                <div class="pageCenter text-center">
                                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
            </section>
        </div>
        <div class="modal modal-slide-in fade" id="projectmodulemaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#3E4757 !important" @click="resetForm()">×</button>
                    <div class="modal-header mb-1 leftsidepopupstyle px-1" style="background-color:#ECEEFB !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <span class="align-middle mx-0">Add/Update Project Module Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm mobile-padding-0-2 px-0">
                        
                        <form>
                             
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Project Name</label>
                                    <treeselect placeholder="Enter min 4 chars to search Project Name" v-model="projectmodule.project" :options="projectmodule.projectlist" @select="selProject" v-on:search-change="projectChangeonform"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Application Name</label>
                                    <treeselect placeholder="Select Application Name" v-model="projectmodule.applicationid" :options="projectmodule.applicationlist" :multiple="true" :clear-on-select="true" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="project-name">Project Module Name </label>
                                    <input id="modulenamet36" name="" class="form-control" type="text" placeholder="Project Module Name " v-model="projectmodule.name" v-bind:class="{'form-control':true, '' : !validText(projectmodule.name)}"/>
                                    <div class="errorinputmsg" v-if="!validText(projectmodule.name)">Project module name must be  greater than 5 char</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="prmoduledescription">Project Module Description</label>
                                    <textarea class="form-control" id="prmoduledescription" name="" rows="3" placeholder="Project Module Description" v-model="projectmodule.desc" v-bind:class="{'form-control':true, '' : !validText(projectmodule.desc)}"></textarea>
                                    <div class="errorinputmsg" v-if="!validText(projectmodule.desc)">Project module description must be  greater than 5 char</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">                                    
                                    <label for="lblattachment" class="d-block">Attachment</label>
                                    <label for="attachment" class="btn btn-sm btn-relief-primary mb-0 mr-75">Select image/docs/videos</label>
                                    
                                    <input type="file" id="attachment" hidden multiple accept="image/*" ref="file" @change="onFileChange"/>
                                    <div class="table-responsive my-75" v-if="projectmodule.attachmentview">
                                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, k) in projectmodule.attachmentview.data" :key="k">
                                                    <td>{{k+1}}</td>
                                                    <td>
                                                        <a :href="data.presignurl" target="_blank">{{
                                                        data.link | basename
                                                        }}</a>
                                                    </td>
                                                    <td>{{ data.info }}</td>
                                                    <td>
                                                        <button type="button" class="btn btn-sm danger" @click="removes3file(k,projectmodule.attachmentview.data)">
                                                            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="errorinputmsg" v-if="this.errors.projectmodule.attachment">{{ this.errors.projectmodule.attachment }}</div>
                                    <div class="table-responsive my-75" v-if="file.length>0">
                                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(image, key) in file" :key="key">
                                                    <td>{{ key+1 }}</td>
                                                    <td>{{ image.name }}</td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="projectmodule.filedescp[key]"/>
                                                    </td>
                                                    <td>
                                                        <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button class="btn btn-sm btn-relief-primary mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex flex-wrap mb-0" >
                                    <button   type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal" v-on:click.stop.prevent="savewizard('t36',$event)">Submit</button>
                                    <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { ExternalLinkIcon,PlusSquareIcon,Trash2Icon,XIcon,PlusCircleIcon,Edit2Icon,PlusIcon,MoreVerticalIcon,PackageIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,CheckIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import jwt from 'jsonwebtoken'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'Masters',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        jwt,
        PlusSquareIcon,
        Trash2Icon,
        PlusCircleIcon,
        XIcon,
        ExternalLinkIcon,CheckIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            search: {
                status: false,
                searchCompany: null,
                searchProject:null,
                searchProjectType: null,
                searchBusiness: null,
                projectlistopts:null,
                companylistopts:null,
                searchProjectname:null,
                projectModuleOpts: [],
                applicationMasterOpts: [],
                applicationMasterid: null,
                projectModuleid: null,
                modulestatus: null,
                modulestatuslist:[
                   { label: "Active", id: 1 },
                    { label: "Inactive", id: 0 }
                   
                ]
            },
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            showprojectdata: [],
            showroledata: [],
            showempdata: [],
            isProjectSel: 0,
            isProjectRoleSel: 0,
            showAddForm:false,
            selected: null,
            countryName: [
                { label: 'select_value', id: 'Select Value' },
                { label: 'Russia', id: 'Russia' },
                { label: 'Canada', id: 'Canada' },
                { label: 'China', id: 'China' },
                { label: 'United States', id: 'United States' },
                { label: 'Brazil', id: 'Brazil' },
                { label: 'Australia', id: 'Australia' },
                { label: 'India', id: 'India' },
            ],
            editRowData:[],
            glbMdl:'',
            isRoleAlloc:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            projectType:[],
            projectdescription:'',
            isProjectMsr:'',
            viewprojectlist:0,
            projoptions:[ {
                id: '',
                label: ''
            }],
            roleoptions:[ {
                id: '',
                label: ''
            }],
            employeeoptions:[ {
                id: '',
                label: ''
            }],
            projvalue:null,
            emplvalue:null,
            rolevalue:null,
            projectmodule:{
                name:null,
                project:null,
                projectlist:[],
                desc:null,
                attachment:null,
                attachmentview:null,
                disblesavebtn:true,
                projectdisbale:false,
                iseditstate:false,
                projectname:false,
                businesslist:null,
                business:null,
                projecttypelist:null,
                projecttype:null,
                companylist:null,
                company:null,
                applicationid:null,
                applicationlist:null,
                filedescp:[],
                businessname:null,
                projecttypename:null,
                companyname:null,
            },
            errors: {
                project:{
                    name:null,
                    desc:null,
                    projecttype:null,
                    business:null,
                    company:null,
                    country:'india',
                },
                roleallocation:{
                    role:null,
                    employee:null,
                },
                roleassign:{
                    proj:null,
                    role:null,
                    empl:null
                },
                projectmodule:{
                    name:null,
                    project:null,
                    desc:null,
                    attachment:null,
                    attachmentview:null
                },
            },
            file:[],
            attachmentold: [],
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            currcalltype:'CAMP',
            allapicallauthkey:'',
            prevtabRowData:[],
            projecttabRowData:[],
            roleassitabRowData:[],
            showtabledata:true,
            projectlist:[],
            showprojectmodule:false,
            showprojects:true,
            projectcurrentstate:'local',
            activeprojectid:'',
            clickedprojdata:null,
            showfilterboxsection:false,
            currentmodule:'#projectmodulemaster',
            showaddmodulebtn:false,
            isSearchRequestActive:false,
            projectnameList:[],
            applicationMasterid: [],
            projectModuleid: [],
            collapse:'collapse',
            card_content:'card-content',
            backpagedata:null,
            s3bucket: apiUrl.s3url,
            tokendata: null,
            isresponce:false,
            validFileExtensions:apiUrl.validFileExtensions
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/assignments') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
            }
            this.getApplicationdata('t1','projectmodule')
            this.projectcurrentstate='local'
            if(window.location.host === 'smartworks.firstcry.tech'){
                this.projectcurrentstate='live'
            }else if(window.location.host === '65.0.57.28:9000'){
                this.projectcurrentstate='stage'
            }
            let rowdata={};
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view') {
                    let search = JSON.parse(localStorage.getItem('promodulesearchItems'))
                    if(search && (search.hasOwnProperty("searchProjectname") || search.hasOwnProperty("projectname"))) {
                        this.backpagedata = this.$route.params.clickeddata;
                        if(this.$route.params && this.$route.params.clickeddata && this.$route.params.clickeddata.projectid){
                            this.search.searchProjectname = this.$route.params.clickeddata.projectid
                            rowdata.searchProjectname = this.search.searchProjectname
                        }
                        this.getapplictionlist(this.$route.params.clickeddata.projectid);
                        this.getSearchItems(search);
                        this.isSearchRequestActive = true;
                    } else {
                        localStorage.removeItem('promodulesearchItems');
                        this.backpagedata=this.$route.params.clickeddata
                        this.getprojectmodulelist(this.$route.params.clickeddata)
                        this.projectmodule.project = this.$route.params.clickeddata.projectid
                        this.projectChangeonformbyid(this.projectmodule.project ? [this.projectmodule.project] :[]);
                        this.getapplictionlist(this.$route.params.clickeddata.projectid);
                        this.showaddmodulebtn=true
                    }
                    if(this.$route.params.backbtnstate == 'edit'){
                        this.projectmodule.project = parseInt(this.backpagedata.projectid);
                        this.projectChangeonformbyid(this.projectmodule.project ? [this.projectmodule.project] :[]);
                        setTimeout(function(){
                            $('#commonaddmodule').click();
                        });
                    }
                }
            }else{
                localStorage.removeItem('promodulesearchItems');
                this.getprojectmodulelist(rowdata);
            }
        }  
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        }); 
    },
    methods:{
        projectCollapse:function(){
            this.showprojects=!this.showprojects
        },
        redirecttobreadkumb:function(objThis){
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(rowdata.projectlevel == 2 ) {
                this.$router.push({ 
                    name: 'Work Step',
                    path: '/workstep',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if(btnstate == 'view' || btnstate == 'edit'){
                this.$router.push({ 
                    name: 'Project Application Module',
                    path: '/applicationmodule',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if(btnstate == 'viewapp'){
                this.$router.push({ 
                    name: 'Project Application',
                    path: '/application',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if (btnstate == 'projectId'){
                this.$router.push({
                    path: '/project/summary?pid='+rowdata.projectid,
                })
            }
        },
        redirecttowsurl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'Work Step',
                path: '/workstep',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
       
        getfilterprojectmodlist:function(){
          this.isLoading=true
          let url = 'api/master/commonmaster/customselect'
          this.input = {
            "tbl": "t11",
            "offset":1,
            "limit":1000,
            "isSearch":false,
            "section":"project",
            "isFilterSelect":1,
            "isFilterPayload":{},
            useremail: this.undt.username,empcode: this.undt.userid
          }
           if(this.search.searchCompany != null ) {
            this.input.isFilterPayload.companyid = this.search.searchCompany
           }
           if(this.search.searchProject != null ) {
            this.input.isFilterPayload.projectid = this.search.searchProject
           }
          
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0) {            
                 this.projectlist=result.data.data.rows;
                      }else {
                    this.projectlist=[]
                }
                }).catch(e => {
          this.displayError(e)
        })          
            
        },
        resetRecords: function () { 
            this.projectnameList = null;
            this.search.searchCompany = null;
            this.search.searchProject = null;
            this.search.searchProjectname = null;
            this.search.projectModuleid = null;
            this.search.modulestatus = null;
            localStorage.removeItem('promodulesearchItems')
            localStorage.removeItem('promoduleprojectnameList')
            localStorage.removeItem('promoduleprojectModuleOpts')
            localStorage.removeItem('promoduleapplicationMasterOpts')
            let rowdata={}
            this.getprojectmodulelist(rowdata);
        },
        resetForm: function () {
            this.file=[] 
            this.projectmodule.attachmentnew = null
            this.projectmodule.attachmentview = null
            this.projectmodule.attachment = null 
            this.projectmodule.desc=null
            this.projectmodule.name=null  
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                    localStorage.setItem('promoduleprojectnameList', JSON.stringify(result.data.data));
                    } else {
                        this.projectnameList = [];
                        localStorage.removeItem('promoduleprojectnameList');
                    }
                }).catch(e => {
                this.displayError(e)
                });
            }
        },
        projectChangebyid: function (projectid){
            if(projectid && projectid.length>0){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectidarr: projectid,
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                    } else {
                        this.projectnameList = [];
                    }
                }).catch(e => {
                this.displayError(e)
                });
            }
        },
        projectChangeonform: function (node){
            if(node && node.length>3){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'createpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.projectmodule.projectlist = result.data.data;
                    } else {
                        this.projectmodule.projectlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        projectChangeonformbyid: function (pid){
            if(pid && pid.length>0){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectidarr: pid,
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'isprojectid'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.projectmodule.projectlist =[];
                        this.projectmodule.projectlist = result.data.data;
                    } else {
                        this.projectmodule.projectlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        projectmoduleChange: function(node, instanceId) {
            if(node && node.length>3) {
                this.isLoading = true;
                let url = "api/master/commonmaster/getModuleDropdownOption";
                this.input = {
                    tbl: "t36",
                    projectmodulename:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectModuleOpts = result.data.data;
                        localStorage.setItem('promoduleprojectModuleOpts', JSON.stringify(result.data.data))
                    } else {
                        this.search.projectModuleOpts = [];
                        localStorage.removeItem('promoduleprojectModuleOpts')
                    }
                }).catch(e => {
          this.displayError(e)
        });
            }
        },
        selprojectid(state, value) {
            this.search.searchProjectname = state;
            this.search.projectMasterid = state;
            if (!state && !this.isAddState) {
                this.search.applicationMasterid = null
                this.search.applicationMasterOpts = null;
            } else {
                this.getDropdownApplicationdata("t12");
                // this.getDropdownApplicationdata("t36");
            }
        },
        selApplicationid(state, value) {
            this.search.applicationMasterid = state;
            if (!state && !this.isAddState) {
                this.search.projectModuleid = null;
                this.search.projectModuleOpts = [];
            } else {
                this.getDropdownApplicationdata("t12");
            }
        },
        getDropdownApplicationdata(mdl) {
            let apiUrla = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail:this.undt.username,
                empcode: this.undt.userid
            }
            if(mdl === "t12") {
                this.input.projectMasterid = this.search.projectMasterid
            } 
            if(mdl === "t36") {
                this.input.applicationMasterid = this.search.applicationMasterid
            } 
            axios({
            'method': 'POST',
            'url': apiUrla,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                            localStorage.setItem('promoduleapplicationMasterOpts', JSON.stringify(result.data.data))
                          }
                        else if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                            localStorage.setItem('promoduleprojectModuleOpts', JSON.stringify(result.data.data))
                            this.loadProjectModulename("t36")
                          }
                    }
                    else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else{
                        this.search.applicationMasterOpts= [];
                        localStorage.removeItem('promoduleapplicationMasterOpts');
                    }
                }).catch(e => {
          this.displayError(e)
        })
        },
        getprojectmodulebyid(moduleid){
            let apiUrlb = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: 't36',
                useremail:this.undt.username,
                empcode: this.undt.userid,
                projectModuleid: moduleid
            }
            axios({
            'method': 'POST',
            'url': apiUrlb,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode==0){
                    this.search.projectModuleOpts = result.data.data
                    localStorage.setItem('promoduleprojectModuleOpts', JSON.stringify(result.data.data))
                }else{
                    this.search.projectModuleOpts= []
                    localStorage.removeItem('promoduleprojectModuleOpts');
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        loadProjectModulename(mdl){
            let apiUrlb = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail:this.undt.username,
                projectMasterid: this.search.projectMasterid,
                applicationMasterid: this.search.applicationMasterid,
                empcode: this.undt.userid
            }
            axios({
            'method': 'POST',
            'url': apiUrlb,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode==0){
                        this.search.projectModuleOpts = result.data.data
                        localStorage.setItem('promoduleprojectModuleOpts', JSON.stringify(result.data.data))
                    }else{
                        this.search.projectModuleOpts= []
                        localStorage.removeItem('promoduleprojectModuleOpts');
                    }
                }).catch(e => {
          this.displayError(e)
        })
        },
        getprojectlist:function(){
            this.isLoading=true
            let url = 'api/master/commonmaster/customselect'
            this.input = {
                "tbl":"t11",
                "offset":1,
                "limit":1000,
                "isSearch":false,
                "section":"project",
                "isFilterSelect":0,
                "isFilterPayload":{},
                "useremail":this.undt.username,
                "empcode": this.undt.userid
            }
          
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false;             
                if(result.data.errorCode == 0){                    
                    this.projectlist=result.data.data.rows;
                }else{
                    this.projectlist=[]
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        getSearchItems(search) {
            this.isSearchRequestActive = true
            this.projectnameList = JSON.parse(localStorage.getItem('promoduleprojectnameList'))
            this.search.applicationMasterOpts = JSON.parse(localStorage.getItem('promoduleapplicationMasterOpts'))
            this.search.projectModuleOpts = JSON.parse(localStorage.getItem('promoduleprojectModuleOpts'))
            this.search.searchProjectname  = this.$route.params.clickeddata.projectid ? this.$route.params.clickeddata.projectid : this.search.searchProjectname ? this.search.searchProjectname : search.searchProjectname ? search.searchProjectname : search.projectid ? search.projectid : null;
            this.search.applicationMasterid  = search.applicationMasterid ? search.applicationMasterid :null;
            this.search.projectModuleid  = search.projectModuleid ? search.projectModuleid :null;
            this.search.modulestatus = search.modulestatus ? search.modulestatus :null;
            if(this.$route.params.clickeddata){
                this.search.searchProjectname = this.$route.params.clickeddata.projectid
            }
            this.projectChangebyid(this.search.searchProjectname ? [this.search.searchProjectname] :[]);
            if(this.search.projectModuleid && this.search.projectModuleid !=null){
                this.getprojectmodulebyid(this.search.projectModuleid);
            }
            this.getprojectmodulelist(this.search);
        },
        getprojectmodulelist(rowdata){
            this.islistLoading=true
            if(rowdata) {
                localStorage.setItem('promodulesearchItems', JSON.stringify(rowdata))
            }
            this.clickedprojdata=rowdata
            let setmoduleform='projectmodulemaster'
            let glbMdlLblval='Project Module'
            this.companymasterlist = []
            let tblNm='t36'
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                this.glbMdlLbl = glbMdlLblval;
            }
            this.input = {
                tbl:tblNm,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                projectMasterid: rowdata.projectid ?rowdata.projectid:(rowdata.projectMasterid ?rowdata.projectMasterid: rowdata.searchProjectname),
                projectModuleid: rowdata.projectModuleid,
                applicationMasterid:rowdata.applicationMasterid,
                uniqueid: this.undt.userid,
                empcode: this.undt.userid,
                modulestatus: rowdata.modulestatus
            }
            this.input.isSearch=false
            let url = 'api/projectmodule/list' 
            // this.getApplicationdata('t11','projectmodule')             
            this.getApplicationdata('t3','projectmodule')
            this.getApplicationdata('t2','projectmodule')
            this.getApplicationdata('t1','projectmodule')
           
            this.activeprojectid=this.clickedprojdata.projectid
            this.projectmodule.projectname=this.clickedprojdata.projectname
            this.projectmodule.projecttype=this.clickedprojdata.projecttypeid
            this.projectmodule.businessname=this.clickedprojdata.businessname
            this.projectmodule.projecttypename=this.clickedprojdata.projecttypename
            this.projectmodule.companyname=this.clickedprojdata.companyname
            this.projectmodule.business=this.clickedprojdata.businessverticleid
            this.projectmodule.company=this.clickedprojdata.companyid
            this.projectmodule.project=this.activeprojectid
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isresponce=true
                this.islistLoading=false;
                if(result.data.errorCode == 0){
                    this.showprojects=false
                    this.showprojectmodule=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    var existstatedata = this;
                    setTimeout(function(){ 
                        $('#commonaddmodule').attr('data-target','#'+setmoduleform)
                        $('.devEditEle').attr('data-target','#'+setmoduleform)
                        /* if(rowdata.backbtnstate == 'edit'){
                            $('#commonaddmodule').click();
                        } */
                    }, 1000);
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else{
                    this.showprojectmodule=true;
                    this.companymasterlist=[];
                    this.totalcount = 0
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    /* Swal.fire({
                        title: "",
                        text: "Project module data not found for : "+rowdata.projectname,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    }) */
                    setTimeout(function(){ 
                        $('#commonaddmodule').attr('data-target','#'+setmoduleform)
                        $('.devEditEle').attr('data-target','#'+setmoduleform)
                        /* if(rowdata.backbtnstate == 'edit'){
                            $('#commonaddmodule').click();
                        } */
                    }, 1000);
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            this.getprojectmodulelist(this.clickedprojdata)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#proModuleList").offset().top -70
                });
            }, 500);
            
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            this.projectBlured=true
            this.projectdescBlured=true
            this.projectcountryBlured=true
            this.appenvhosturlBlured=true
            this.appenvhostdetailspathBlured=true
            this.appenvhostpathBlured=true
            this.appenvhostBlured=true
            this.appenvnameBlured=true
            this.appenvtypeBlured=true
            this.applinameBlured=true

            if(tblNm=="t11" && (this.validText(this.project.name) && this.validText(this.project.desc))){
                this.valid = true;
            }
            if(tblNm=="t12" && (this.validText(this.application.name))){
                this.valid = true;
            }
            if(tblNm=="t13" && (this.validText(this.appenv.name) && this.validText(this.appenv.host) && this.validText(this.appenv.hostpath) && this.validText(this.appenv.hostdetails)  && this.validText(this.appenv.hosturl) && this.valid2Text(this.appenv.type) )){
                this.valid = true;
            }            
        },
        setSelected(value)
        {
            this.selected=value
        },
        addModule(objThis){
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't36'){
                this.projectmodule.disblesavebtn=true
            }
            $('.devUniqueForm').find('input[type=text]').val('')
            $(objThis.currentTarget.dataset.target).find('#submitbtn'+inputEle).attr('data-isedit',false)
            $('#glbsearchinpele').val('')
            this.isAddState=true
            this.projectmodule.name =null
            this.projectmodule.desc =null
            this.projectmodule.attachmentview =null
            this.projectmodule.projectdisbale=true
            if(this.projectmodule.project == null){
                this.projectmodule.projectdisbale=false
            }
            this.projectmodule.iseditstate=false;
            this.isSearchRequestActive = true
        },
        onFileChange(e) {
        var validFileExtensions = apiUrl.validFileExtensions
        let selectedFiles = e.target.files;
            for (let i=0; i < selectedFiles.length; i++)
            {
                var blnValid = false;
                for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                    var sCurExtension = validFileExtensions[j];
                    if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        blnValid = true;
                        break;
                    }
                }
                
                if (!blnValid) {
                    Swal.fire({
                        title: "ERROR",
                        text: "Sorry, Invalid Type of Extension File..!!",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return false;
                }
                this.file.push(selectedFiles[i]);
            }
            if(this.file.length>0) {
                this.projectmodule.disblesavebtn=true
            }
        },
        removeImage (index) {
            if(this.file.length>0) {
                this.file.splice(index, 1);
                this.projectmodule.filedescp.splice(index, 1);
                var removeimg = JSON.parse(this.projectmodule.attachmentnew)
                if(removeimg){
                var remove = removeimg.data.splice(index,1)
                if(remove.length > 0)
                this.projectmodule.attachmentnew = JSON.stringify({data:removeimg.data})
                else{
                  this.projectmodule.attachmentnew = null
                }
              }
            }
            if(this.file.length < 1) {
                this.projectmodule.disblesavebtn = false
            }            
        },
        removes3file(key,data) {
            if(data.length>0) {                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to delete this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        if(this.editRowData.id !== undefined) {
                            let deletefile = data[key];
                            data.splice(key, 1);
                            const result2 = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                            let payload = result2.length > 0 ? JSON.stringify({ data: result2 }) : null;
                            axios({
                            method: "POST",
                            url: 'api/projectmodule/s3deleteprojectmodule',
                            data: {
                                id:this.editRowData.id,
                                attachment:payload,
                                deletefile: JSON.stringify(deletefile),
                                useremail: this.undt.username,
                                empcode: this.undt.userid
                            },
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            if (result.data.status) {
                                //this.issue.attachmentview.data = data.splice(key, 1);
                                //fieldType.splice(index, 1);
                                Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'success'
                                )
                            } else {
                            Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'error'
                                )                        
                            }
                        });
                        } else {
                            Swal.fire(
                                'IssueId!',
                                'Issueid should not blank',
                                'info'
                                )
                        }    
                    }
                })                
            }
        },
        savewizard(mdl,objThis){
            if(mdl=="t36"){
                this.validate(mdl)
            }else{
                this.valid=true;
            }            
            this.valid = this.validateForm(mdl,objThis);
            if (this.valid) {
                let nameobj=document.getElementById('modulename'+mdl)
                let tblNm='t36'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'assignment',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                let apiURL='api/master/commonmaster/save'
                
                if(mdl == 't36'){
                    this.input.name=this.projectmodule.name
                    this.input.projectid=this.projectmodule.project
                    if(this.projectmodule.applicationid){
                        this.input.applicationid=JSON.stringify(this.projectmodule.applicationid)
                    }                    
                    this.input.desc=this.projectmodule.desc
                    this.input.section='assignment'
                   
                    this.input.attachment=this.projectmodule.attachmentnew
                    apiURL='api/projectmodule/create'
                    delete this.input.type;
                }
                
                if(this.projectmodule.iseditstate == true){
                    apiURL='api/master/commonmaster/update'
                    if(tblNm == 't36'){
                        this.input.id=this.editRowData.projectmoduleid
                        apiURL='api/projectmodule/update'
                    }
                    if(this.projectmodule.attachmentnew && this.projectmodule.attachment !== null) {
                        let jsonobj1 = JSON.parse(this.projectmodule.attachmentnew);
                        let jsonobj2 = this.projectmodule.attachment;
                        if(jsonobj1.data && jsonobj2.data) {
                            let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                            this.input.attachment= JSON.stringify({data: arraymerge});
                        }
                    }
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                this.isAddState=false
                var currobj=objThis.currentTarget.value;
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.file =[]
                        this.projectmodule.filedescp =[]
                        this.projectmodule.attachmentnew = null
                        //$('.close').click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.editRowData={};
                        if(this.clickedprojdata.backbtnstate == 'view' || this.clickedprojdata.backbtnstate == 'edit'){
                            this.editRowData={
                                projectid:this.projectmodule.project,
                                projectname:this.projectmodule.projectname,
                                projecttypeid:this.projectmodule.projecttype,
                                businessverticleid:this.projectmodule.business,
                                companyid:this.projectmodule.company,
                                backbtnstate:this.clickedprojdata.backbtnstate
                            }
                        }
                        this.getprojectmodulelist(this.search);
                        $('.close').click();
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
                this.displayError(e)
                })
            }
        },
        getprojectmoduledata(){
            let apiURL = 'api/listing/getprojectmoduledatabyprojectid'
            this.isAddState=false
            this.input = {
                tbl: 't11',
                projectid:this.projectmodule.project,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.projectmodule.iseditstate = false
                if(result.data.errorCode == 0){
                    this.projectmodule.name = result.data.data[0].projmdlname
                    this.projectmodule.project = result.data.data[0].projectid
                    this.projectmodule.desc = result.data.data[0].projmdldescription
                    this.projectmodule.attachment = result.data.data[0].attachment
                    this.projectmodule.attachmentview = result.data.data[0].attachment
                    this.projectmodule.iseditstate = true
                    this.prevtabRowData=result.data.data[0];
                }
                else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
            }).catch(e => {
          this.displayError(e)
        });            
        },
        validateForm(mdl,objThis){
            this.clearerrorForm();
            this.isAddState=false
            var isValid=true;
            if(mdl=="t36"){
                /* if(!this.projectmodule.name){
                    this.errors.projectmodule.name="Project required";
                    isValid= false;
                } */
                if(this.projectmodule.project == null || this.projectmodule.project == '' || this.projectmodule.project.length == 0){
                    this.errors.projectmodule.project="Project required";
                    isValid= false;
                }
            }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                projectmodule:{
                    name:null,
                    project:null,
                    desc:null,
                    attachment:null,
                    attachmentview:null,
                    disblesavebtn:false
                },
            };
        },
        openEditModule(currRowData){
            this.editRowData = currRowData
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle){
                $('#modulename'+inputEle).val(currRowData.modulename)
            }
            if(btnEle){
                $('#'+btnEle).find('#submitbtn'+inputEle).addClass(btnEle)
                $('#'+btnEle).find('#submitbtn'+inputEle).attr('data-isedit',true)
            }
            $('#glbsearchinpele').val('')
            if(inputEle=="t36"){
                this.projectmodule.name = currRowData.modulename
                this.projectmodule.project = currRowData.projectid
                if(this.projectmodule.project){
                    this.projectChangeonformbyid(this.projectmodule.project ? [this.projectmodule.project] :[]);
                }
                this.getapplictionlist(currRowData.projectid);
                this.projectmodule.applicationid=null
                if(currRowData.applicationid>0){
                    this.projectmodule.applicationid=[]
                    this.projectmodule.applicationid.push(currRowData.applicationid)
                }
                this.projectmodule.projectdisbale=true
                this.projectmodule.desc = currRowData.description
                this.projectmodule.attachmentview =null;
                this.projectmodule.attachment =  currRowData.attachment ? JSON.parse(currRowData.attachment):[];
                
                if(currRowData.attachment && JSON.parse(currRowData.attachment).hasOwnProperty('data')) {
                    this.getDownloadLink(JSON.parse(currRowData.attachment));
                }
                this.attachmentold = currRowData.attachment;
                this.projectmodule.projectname=currRowData.projectname
                this.projectmodule.projecttype=currRowData.projecttypeid
                this.projectmodule.business=currRowData.businessverticleid
                this.projectmodule.company=currRowData.companyid
                this.projectmodule.iseditstate = true
                this.getprojectmoduleapplications(currRowData);
            }
            this.isAddState=true
        },
        getDownloadLink(data) {
            if(data.data.length>0) {
                let payload = {
                    documents:data.data,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    if(result.data.status) {
                        this.projectmodule.attachmentview = result.data;
                    }
                }).catch(e => {
          this.displayError(e)
        });
            }
        },
        getprojectmoduleapplications: function(rowdata){
            this.inputprojappl = {
                id:rowdata.id,
                useremail: this.undt.username,empcode: this.undt.userid
            }
            let apiURL='api/projectmodule/getapplications'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.projectmodule.applicationid=[]
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                            this.projectmodule.applicationid.push(element.applicationid)
                        }
                    });
                }else{
                    this.projectmodule.applicationid=null
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
          dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail:this.undt.username,
                empcode : this.undt.userid
            }
            if(curracttab == 'projectmodule'){
                this.inputappl = {
                    tbl:mdl,
                    useremail:this.undt.username,
                    empcode : this.undt.userid
                }
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                        if(mdl == 't2'){
                            this.projectmodule.businesslist=result.data.data.rows
                            return true;                            
                        }else if(mdl == 't11'){
                            if(curracttab == 'projectmodule'){
                                this.projectmodule.projectlist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't3'){
                            if(curracttab == 'projectmodule'){
                                this.projectmodule.projecttypelist=result.data.data.rows
                                return true;
                            }
                        }else if(mdl == 't1'){
                            this.projectmodule.companylist=result.data.data.rows
                            this.search.companylistopts=result.data.data.rows
                            return true;
                        }
                }
            }).catch(e => {
                this.displayError(e)
            })
            
        },

        selectcompany(state, value) {
            this.search.searchProject =null;
            this.search.searchCompany = state;
            if (!state) {
                 this.search.projectlistopts = null;
             } else {
                 this.getProjectbycompanyid();
                 }
             },

 
         getProjectbycompanyid(){
            this.apiURL="api/listing/getprojectlistbycompanyid"
            this.input={
                "tbl":"t11",
                "companyid":this.search.searchCompany,
                useremail: this.undt.username,empcode: this.undt.userid
                }
                axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                if(result.data.errorCode == 0){
                this.search.projectlistopts = result.data.data;
                }
                else{
                this.search.projectlistopts=[]
                }
              }).catch(e => {
                this.displayError(e)
                })
           },

        getThirdPartyAPIcall(mdl,curracttab){
            var calltype=''
            
            calltype=this.currcalltype
            this.pageoffset=1
            this.inputthrid = {
                tbl:mdl,
                offset:this.pageoffset,
                token:this.undt.tokenorg,
                limit:apiUrl.LIMIT,
            }
            var apiURL='api/master/commonmaster/thirdpartyapicall' 
            if(mdl == 't10'){
                apiURL='api/thirdpartyapicall/getdesignations'
                this.inputthrid.currentState=calltype
            }else if(mdl == 't21'){
                apiURL='api/thirdpartyapicall/getemployees'
                this.inputthrid.currentState=calltype
                if(this.rolevalue){                    
                    this.inputthrid.roleid=parseInt(this.rolevalue)
                }else{
                    this.inputthrid.roleid=this.roleallocation.role?parseInt(this.roleallocation.role):143
                }
            }
            this.inputthrid.currentPlatform='localhost'
            if(window.location.host === 'smartworks.firstcry.tech'){
                this.inputthrid.currentPlatform='live'
            }else if(window.location.host === '65.0.57.28:9000'){
                this.inputthrid.currentPlatform='stage'
            }
            this.allapicallauthkey = jwt.sign(this.inputthrid, apiUrl.encpaccessapitoken, {
                expiresIn: '24h' 
            });
            var encpaccessapitoken=this.allapicallauthkey; 
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': {encpaccessapitoken},
            
            })
            .then(result => {
                if(result.data.status == true){
                    if(mdl == 't10'){
                        if(curracttab == 'roleallocation-tab'){
                            if(this.inputthrid.currentState == 'fcsmart'){
                                this.roleallocation.rolelist = result.data.data.rows
                            }else{
                                this.roleallocation.rolelist = result.data.data
                            }
                            return true;
                        }else if(curracttab == 'projectrolesele-tab'){
                            if(this.inputthrid.currentState == 'fcsmart'){
                                this.roleselection.rolelist = result.data.data.rows
                            }else{
                                this.roleselection.rolelist = result.data.data
                            }
                            //this.roleselection.rolelist=result.data.data
                            return true;
                        }else if(curracttab == 'projectroleassign-tab'){
                            //this.roleoptions=result.data.data
                            if(this.inputthrid.currentState == 'fcsmart'){
                                this.project.roleoptions = result.data.data.rows
                            }else{
                                this.project.roleoptions = result.data.data
                            }
                            return true;
                        }
                    }else if(mdl == 't21'){
                        if(curracttab == 'roleallocation-tab'){
                            if(this.inputthrid.currentState == 'fcsmart'){
                                this.roleallocation.employelist = result.data.data.rows
                            }else{
                                this.roleallocation.employelist=result.data.data
                            }
                            return true;
                        }else if(curracttab == 'projectroleassign-tab'){
                            this.employeeoptions=result.data.data
                            return true;
                        }
                    }
                }else if(result.data.status == false){
                    if(mdl == 't10'){
                        if(curracttab == 'roleallocation-tab'){
                            this.roleallocation.rolelist = [{
                                id:'',
                                label:'Issue in CAM Integration API'
                            }]
                            return true;
                        }else if(curracttab == 'projectrolesele-tab'){
                            this.roleselection.rolelist=[{
                                id:'',
                                label:'Issue in CAM Integration API'
                            }]
                            return true;
                        }else if(curracttab == 'projectroleassign-tab'){
                            this.project.roleoptions=[{
                                id:'',
                                label:'Issue in CAM Integration API'
                            }]
                            return true;
                        }
                    }else if(mdl == 't21'){
                        if(curracttab == 'roleallocation-tab'){
                            this.roleallocation.employelist=[{
                                id:'',
                                label:'Issue in CAM Integration API'
                            }]
                            return true;
                        }else if(curracttab == 'projectroleassign-tab'){
                            this.employeeoptions=[{
                                id:'',
                                label:'Issue in CAM Integration API'
                            }]
                            return true;
                        }
                    }
                }
            });
        },
        getprojectdata(objThis){
            var projId='';
            if(typeof objThis.currentTarget.attributes.projid != 'undefined'){
                projId=objThis.currentTarget.attributes.projid.value
            }
            var rowdata=''
            if(typeof objThis.currentTarget.attributes.modueldata != 'undefined'){
                rowdata=JSON.parse(objThis.currentTarget.attributes.modueldata.value)
            }
            var url = 'api/master/commonmaster/customselect'
            var tblNm = 't11'
            if(objThis.currentTarget.dataset.currstate == 'role'){
                tblNm = 't14'
            }else if(objThis.currentTarget.dataset.currstate == 'proj'){
                tblNm = 't11'
            }else if(objThis.currentTarget.dataset.currstate == 'emp'){
                tblNm = 't15'
            }else if(objThis.currentTarget.dataset.currstate == 'empalloc'){
                tblNm = 't22'
            }
            this.input = {
                tbl:tblNm,
                isFilterSelect:1,
                filterid:projId,
                isFilterPayload:rowdata,
                section:'assignment',
                useremail: this.undt.username,empcode: this.undt.userid
            }
            //this.input.section='assignment'
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(tblNm == 't14'){
                        this.showroledata=result.data.data;
                    }else if(tblNm == 't11'){
                        this.showprojectdata=result.data.data;
                    }else if(tblNm == 't15' || tblNm == 't22'){
                        this.showempdata=result.data.data;
                    }
                }
            })
        },
        selprojmdlproj(state,value){
            this.projectmodule.project=null
            this.projectmodule.project=state
            if(!state && this.isAddState == false){
                this.errors.projectmodule.project='Project required'
            }else{                
                this.errors.projectmodule.project=null
            }
        },
        getfiltereddata(tblNm){
            this.pageoffset=1
            var url = 'api/master/commonmaster/customselect'
            this.input = {
                tbl:tblNm,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            this.input.isSearch=true
            this.input.section='assignment'
            this.input.isFilterSelect=1
            this.input.isFilterPayload={}
            if(tblNm == 't26'){
                this.input.roleid=parseInt(this.rolevalue)
            }else if(tblNm == 't14'){
                this.input.projectid=this.projvalue
                this.input.offset=this.pageoffset
                this.input.limit=apiUrl.LIMIT
            }else if(tblNm == 't36'){
                this.input.projectid=this.applicationmodule.projectid
            }else if(tblNm == 'sectionmodule'){
                url='api/listing/getproappbyappmoduleid'
                this.input = {
                    appmoduelid:this.sectionmodule.applicationmoduleid
                }
            }else if(tblNm == 'functionalitymodule'){
                url='api/listing/getproappbysecmasterid'
                this.input = {
                    sectionmasterid:this.functionalitymodule.sectionmasterid
                }
            }else if(tblNm == 'prjempbyrole'){
                url='api/listing/getemoloyeedetailsbyrole' 
                this.input = {
                    dept:'it',
                    designation:JSON.stringify(this.project.rolevalue),
                    roleid:JSON.stringify(this.project.rolevalue)
                }
            }
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(tblNm == 't14'){
                        this.roleoptions=result.data.data.rows
                    }else if(tblNm == 't26'){
                        this.employeeoptions=result.data.data.rows
                    }else if(tblNm == 't36'){
                        
                        this.applicationmodule.projectmodulelist=result.data.data.rows
                    }else if(tblNm == 'sectionmodule'){
                        this.sectionmodule.projectname=result.data.data[0].projectname
                        this.sectionmodule.projectid=result.data.data[0].projectid
                        this.sectionmodule.applicationname=result.data.data[0].applicationname
                        this.sectionmodule.applicationid=result.data.data[0].applicationid
                    }else if(tblNm == 'functionalitymodule'){
                        this.functionalitymodule.projectname=result.data.data[0].projectname
                        this.functionalitymodule.projectid=result.data.data[0].projectid
                        this.functionalitymodule.applicationname=result.data.data[0].applicationname
                        this.functionalitymodule.applicationid=result.data.data[0].applicationid
                        this.functionalitymodule.applicationmodulename=result.data.data[0].applicationmodulename
                        this.functionalitymodule.applicationmoduleid=result.data.data[0].applicationmoduleid
                    }else if(tblNm == 'prjempbyrole'){
                        this.employeeoptions=result.data.data
                    }else if(tblNm == 'programoptions'){
                        this.project.programoptions=result.data.data
                    }else if(tblNm == 'projectmgroptions'){
                        this.project.projectmgroptions=result.data.data
                    }else if(tblNm == 'apmoptions'){
                        this.project.apmoptions=result.data.data
                    }else if(tblNm == 'srengmanager'){
                        this.project.srengmanager=result.data.data
                    }else if(tblNm == 'engmanager'){
                        this.project.engmanager=result.data.data
                    }else if(tblNm == 'prolead'){
                        this.project.prolead=result.data.data
                    }else if(tblNm == 'modlead'){
                        this.project.modlead=result.data.data
                    }else if(tblNm == 'srsoluarch'){
                        this.project.srsoluarch=result.data.data
                    }else if(tblNm == 'soluarch'){
                        this.project.soluarch=result.data.data
                    }else if(tblNm == 'srqamgr'){
                        this.project.srqamgr=result.data.data
                    }else if(tblNm == 'qamgr'){
                        this.project.qamgr=result.data.data
                    }else if(tblNm == 'srsecumgr'){
                        this.project.srsecumgr=result.data.data
                    }else if(tblNm == 'srbusianly'){
                        this.project.srbusianly=result.data.data
                    }else if(tblNm == 'busianly'){
                        this.project.busianly=result.data.data
                    }
                }else{
                    if(tblNm == 't14'){
                        this.roleoptions=null
                        this.errors.roleassign.role="no role found"
                    }else if(tblNm == 't22'){
                        this.employeeoptions=null
                        this.errors.roleassign.empl="no employee found"
                    }else if(tblNm == 't36'){
                        this.errors.applicationmodule.projectmoduleid="no project module found"
                    }else if(tblNm == 'sectionmodule'){
                        this.errors.applicationmodule.projectmoduleid="no project module found"
                        this.errors.functionalitymodule.applicationid="no application found"
                        this.errors.functionalitymodule.applicationmoduleid="no application module found"
                    }else if(tblNm == 'functionalitymodule'){
                        this.errors.functionalitymodule.projectid="no project found"
                        this.errors.functionalitymodule.applicationid="no application found"
                        this.errors.functionalitymodule.applicationmoduleid="no application module found"
                    }
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        onSelect(){
            this.file = this.$refs.file.files[0];
        },
        getAttachment(currRow){
            this.apiURL="api/master/fileupload/getImageFileUrl"
            this.inputattachment={
                empcode:this.undt.userid,
                moduleid:currRow.id,
                useremail: this.undt.username,
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':this.inputattachment
            })
            .then(result => {
                 if(result.data.status){
                    this.projectmodule.disblesavebtn=false
                    this.projectmodule.attachmentview=result.data.data;
                 }
            }).catch(e => {
          this.displayError(e)
        })
        },
        onUpload(){
            if(this.file){
                this.isLoading=true
                if(this.file.length !== 0){
                    
                    this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                    let formData = new FormData();
                    
                    
                    let maxsize = 0;
                    for (let index = 0; index < this.file.length; index++) {
                        formData.append("imagefile", this.file[index]);
                        maxsize+= this.file[index].size
                    }

                    
                    formData.append("empcode", this.undt.userid);
                    formData.append("useremail",this.undt.username);
                    formData.append("moduletype", projectmodulemaster);
                    formData.append("fcount", parseInt(this.file.length));
                    if (maxsize > 1024 * 1024 * 25) {
                        this.isLoading = false;
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 25mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        this.projectmodule.disblesavebtn = true
                        return false; 
                    } else {
                        axios({
                            'content-type':'multipart/form-data',
                            'method': 'POST',
                            'url': this.apiURL,
                            'data':formData,
                            'headers':{'authorization':this.tokendata}

                        })
                        .then(result => { 
                            this.isLoading=false;            
                            this.projectmodule.disblesavebtn=false
                            if(result.data.status){
                                
                                const bindinfo = result.data.data.map((file, index) => {
                                    return {"link":file,"info":this.projectmodule.filedescp[index]}                
                                });
                                let jsonobj = {data:bindinfo}
                                this.projectmodule.attachmentnew = JSON.stringify(jsonobj);
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            } else {
                                Swal.fire({
                                    title: "",
                                    text: 'Failed to upload',
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                                this.projectmodule.attachment=null
                            }
                        })
                    }
                }else{
                     this.isLoading=false; 
                    Swal.fire({
                        title: "",
                        text: 'Select image/docs/videos',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.projectmodule.attachment=null
                }
            } else {
                this.isLoading=false; 
                Swal.fire({
                    title: "",
                    text: 'Select image/docs/videos',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.projectmodule.attachment=null
            }
        },
        selProject(state,value){
            this.projectmodule.project=null
            this.projectmodule.project=state
            if(!state && this.isAddState == false){
                this.projectmodule.project='Project id required'
            }else{                
                this.projectmodule.project=state.id
                this.projectmodule.projecttype=state.projecttypeid
                this.projectmodule.business=state.businessverticleid
                this.projectmodule.company=state.companyid
                this.getapplictionlist(state.id);
            }
        },
        getapplictionlist:function(projectid){
            let url = 'api/projectapplication/getAplicationlist'
            this.input = {
               projectid:projectid,
               empcode: this.undt.userid,
               useremail:this.undt.username,
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    
                    this.projectmodule.applicationlist=result.data.data.rows
                }else{
                    this.projectmodule.applicationid=null
                    this.projectmodule.applicationlist=null
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        moduleisactive:function(isdata,isactive) {
            this.isLoading = true;
            this.input = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectmoduleid: isdata.id,
                isactive: isactive
            };
            axios({
            method: "POST",
            url: `api/projectmodule/activeinactiveprojectmodule`,
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if(result.data.errorCode == 0) {
                    this.getprojectmodulelist(this.search);
                    Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                    })
                }else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            });
        },
        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit:null,
                offset:null,
                projectid:this.search.projectname,
                projectMasterid: this.search.projectMasterid,
                applicationMasterid: this.search.applicationMasterid,
                useremail:this.undt.username,
                tbl:'t36',
                empcode: this.undt.userid,
                modulestatus: this.search.modulestatus,
                uniqueid: this.undt.userid
            }; 
            axios({
                method: "POST",
                url: `api/projectmodule/list`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.islistLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
          this.displayError(e)
        });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'projectmodulereport'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#,Project Module Name,Description,Project Name,Total Applications,Total App Modules,Project Module Status,Created Date' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename ==null || index.projectmodulename == 'NA' ? '' : index.projectmodulename: '';
            let description = index.hasOwnProperty('description') ? index.description == null || index.description == 'NA' ? '' : index.description : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname == null || index.projectname == 'NA' ? '' : index.projectname : '';
            let totalapp = index.hasOwnProperty('totalapp') ? index.totalapp ==null || index.totalapp == 'NA'?'' : index.totalapp : '';
            let totalappmodule = index.hasOwnProperty('totalappmodule') ? index.totalappmodule == null || index.totalappmodule == 'NA'?'':index.totalappmodule : '';
            let isactive = index.hasOwnProperty('isactive') ? index.isactive ==null || index.isactive == ''? 'Inactive' : index.isactive ==0 ? 'Inactive' : index.isactive ==1 ? 'Active' :'Inactive' : 'Inactive';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ?'':index.createddate : '';  
            createddate = this.dateMONFormat(createddate);  
                row += '"' + srno + '",',
                row += '"' + projectmodulename + '",',
                row += '"' + description + '",',
                row += '"' + projectname + '",',
                row += '"' + totalapp + '",',
                row += '"' + totalappmodule + '",',
                row += '"' + isactive + '",',
                row += '"' + createddate + '",',
                row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            return;
            }
            var fileName = 'projectmodulereport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });    
        }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    },
    filters: {
        basename: function (value) {
            if (!value) return ''
            //value = value.toString()
            return value.substr(value.lastIndexOf("/") + 1)
        },
    },
      
}
</script>
