<template>
    <div class="app-content content ">
        <loading  :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper pb-1 mt-1">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-9 px-0">
                        <h4 class="content-header-title float-left mb-0">Blocker Modules Report</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link to='/releaseplanner/feedback/report'>Blocker Modules Report</router-link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="dashboardmysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Blocker Modules Report Dashboard</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div> 
                </div>

                <div class="card-body pt-1" v-bind:class="[{show: dashboardisSearchRequestActive},card_content,collapse]">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                            <label class="form-label lable-left">Module Type</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Module Type" v-model="search.moduletype" :options="moduletypelist"/>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                            <label class="form-label lable-left">Project Name</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.projectid" :options="projectnameList" @input="selectproject"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                            <label class="form-label lable-left">Status</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" :multiple="true" v-model="search.status" :options="blockerlist"/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-0  text-right">
                            <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getBlocketDataList(search,1)"> 
                                <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                            </button>
                            <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="resetRecords()">
                                <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                            </button>
                            <button type="move" class="btn btn-relief-primary mt-2 ml-1" @click="ExportXL(search)"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <h4 class="card-title d-inline-flex ml-1">Business Tasks, Micro Tasks, Issues Blocker List</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div >
                    <section id="card-navigation">
                        <div class="file-manager-content-body">
                            <div class="drives">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tab-content" id="apprisalformtable">
                                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div class="row" id="proModuleList">
                                                    <div class="col-12">
                                                        <div class="card">
                                                            <div class="table-responsive">
                                                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="align-middle srno " style="min-width:45px;">Sr#</th>
                                                                            <th scope="col" class="align-middle  text-center">Moduletype</th>
                                                                            <th scope="col" class="align-middle  text-center">Blocker Code/Name</th>
                                                                            <th scope="col" class="align-middle  text-center">Blocked by Code/Name </th>
                                                                            <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Status </th>
                                                                            <th scope="col" class="align-middle  text-center">Project Name</th>
                                                                            <th scope="col" class="align-middle  text-center">Project Manager</th>
                                                                            <th scope="col" class="align-middle  text-center">Engineering Manager </th>
                                                                            <th scope="col" class="align-middle  text-center">QA Lead </th>
                                                                            <th scope="col" class="align-middle  text-center">Created By/Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(data,index) in blockerdatalist">        
                                                                            <tr v-bind:key="index">
                                                                                <td class="srno">{{ index+1+currPageNum*pagelimit }}</td>
                                                                                <td class="capitalisetext">
                                                                                    {{ data.moduletype }}
                                                                                </td>
                                                                                <td class="details_column_table">
                                                                                     <span @click="redirecttourl(data.moduletype, data.blockertaskid)" href="javascript:void(0);" style="cursor: pointer;" class="heading_column_text sitebluecolor">{{data.blockercode}}</span>
                                                                                     <p class="hr_border-bottom" >
                                                                                     {{ data.blockername }}</p>
                                                                                </td>

                                                                                <td class=" text-center details_column_table">
                                                                                     <span @click="redirecttourl(data.moduletype, data.blockedbytaskid)" href="javascript:void(0);" style="cursor: pointer;" class="heading_column_text sitebluecolor">{{data.blockedbycode}}</span>
                                                                                     <p class="hr_border-bottom">
                                                                                     {{ data.blockedbyname }}</p>
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{  data.status }}
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{  data.projectname }}
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    <span v-if="data.projectmaanager">{{  data.projectmaanager }}</span>
                                                                                    <span v-else>NA</span>
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    <span v-if="data.engineeringmaanager">{{  data.engineeringmaanager }}</span>
                                                                                    <span v-else>NA</span>
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    <span v-if="data.qalead">{{  data.qalead }}</span>
                                                                                    <span v-else>NA</span>
                                                                                </td>
                                                                                <td class="details_column_table">
                                                                                     <span class="heading_column_text">{{data.createdby.split('@')[0]}}</span>
                                                                                     <hr class="hr_border-bottom" />
                                                                                     {{ dateMonthFormat(data.createddate) }}
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-if="blockerdatalist.length==0">
                                                                            <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pageCenter text-center">
                                            <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,MoreVerticalIcon,EyeIcon,XIcon,ExternalLinkIcon,MonitorIcon } from "vue-feather-icons";
import { SearchIcon } from "vue-feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'BlockerModuleReport',
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,MoreVerticalIcon,EyeIcon,
        SearchIcon,XIcon,ExternalLinkIcon,MonitorIcon,
        Treeselect
    },
    mixins: [ commonMethods ],
    data(){
        return{
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            totalcount1: 0,
            pageCount1: 0,
            page1: 1,
            pageoffset1:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            blockerdatalist:[],
            isSearchRequestActive:false,
            dashboardisSearchRequestActive:true,
            search:{
                modulecode: null,
                moduletype: null,
                projectid: null,
                status: null,
            },
            trackerlist:[],
            loginuseremail: '',
            pagefeatures:[],
            employeenamelist: [],
            trackerdetails: [],
            disabledexport: true,
            deparmentlist: [],
            releasenotelist: [],
            loginuserid: null,
            blockerlist : apiUrl.blockertypes.sort((a, b) => a.label.localeCompare(b.label)),
            moduletypelist:[{
                label:'Business Task',
                id:'businesstask'
            },{
                label:'Issue',
                id:'issue'
            },{
                label:'Task',
                id:'task'
            }],
            projectnameList: [],
            moduledetailslist: [],
        }
    },
    mounted(){
        let permission = true; //this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.loginuseremail = userdata.emailaddress;
                this.undt=userdata
                this.loginuserid = this.undt.userid;
                this.getBlocketDataList();
            }
        }
        $(".filter_rotation_onlick").click(function(){
             $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        clearSearchTermList(state) {
            if(!state) {
                this.moduledetailslist   = []
            }
        },
        searchAll(node) {
            if(node && node.length>6) {
                axios({
                    'method': 'POST',
                    'url': 'api/blocker/getblockercodes',
                    'data': {
                        node: node,
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    if(result.data.errorCode == 0) {
                        this.moduledetailslist = result.data.data.rows
                    } else {
                        this.moduledetailslist =  []
                    }
                })
            }
    
        },
        selectproject(state, value) {
            this.pageoffset=1
            this.search.projectid = state;
        }, 
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.pageoffset=1
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage:'listingpage'
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                } else {
                    this.projectnameList = [];
                }
                }).catch(e => {
                this.displayError(e)
                });
            }
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive = !this.isSearchRequestActive
        },
        dashboardmysearchrequestollapse:function(){
            this.dashboardisSearchRequestActive = !this.dashboardisSearchRequestActive
        },
        selectTname(state){
            if(state.length==0){
                this.disabledexport = true;
            }
        },
        clickCallback: function (pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            this.getBlocketDataList(this.search);
        },
        resetRecords: function() {
            this.search.modulecode = null;
            this.search.moduletype = null;
            this.search.projectid = null;
            this.search.status = null;
            this.pageoffset = 1
            this.page = 1
            this.getBlocketDataList();
        },
        redirecttourl:function(moduletype,rowdata){
            if(moduletype == "businesstask"){
                let routeData = this.$router.resolve({ 
                path: `/businesstask/view?businessid=${parseInt(rowdata)}`,
                params: {
                    backbtnstate:'view',
                    clickeddata:parseInt(rowdata)
                }
                })
                window.open(routeData.href, '_blank');
            } else if(moduletype == "issue") {
                let routeData1 = this.$router.resolve({ 
                path: `/issues/view?issueid=${parseInt(rowdata)}`,
                params: {
                    backbtnstate:'view',
                    clickeddata:parseInt(rowdata)
                }
                })
                window.open(routeData1.href, '_blank');
            } else if(moduletype == "task") {
                let routeData2 = this.$router.resolve({ 
                path: `/tasks/view?taskid=${parseInt(rowdata)}`,
                params: {
                    backbtnstate:'view',
                    clickeddata:parseInt(rowdata)
                }
                })
                window.open(routeData2.href, '_blank');
            }
        },
        getBlocketDataList(objThis,flag){
            this.isLoading = true
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                offset: this.pageoffset,
                limit: apiUrl.LIMIT
            }
            if(flag == 1) {
                this.page = 1;
                this.pageoffset = 1;
            }

            this.input.moduletype = this.search.moduletype;
            this.input.projectid = this.search.projectid;
            this.input.status = this.search.status;
            this.input.modulecode = this.search.modulecode;

            axios({
                'method': 'POST',
                'url': 'api/blocker/getBlockerModuleReport',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.blockerdatalist = result.data.data.rows;
                    this.totalcount = result.data.data.count;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.blockerdatalist=[]
                    this.totalcount = 0;
                    this.pageCount =0;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY');
        },
        ExportXL(objThis) {
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                offset: null,
                limit: null
            }
            if(objThis) {
                this.input.moduletype = this.search.moduletype;
                this.input.projectid = this.search.projectid;
                this.input.status = this.search.status;
                this.input.modulecode = this.search.modulecode;
            }
            axios({
                method: "POST",
                url: `api/blocker/getBlockerModuleReport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows, filename)
                } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
            var ShowLabel = 'BlockerModuleReport'
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#, Moduletype,Blocker Code,Blockr Name,Blocked by Code,Blockd By Name,Status,Project Name,Project Manager,ENGINEERING Manage,QA Lead, Created Date, Created by';
                    CSV += '\r\n';
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype == null || index.moduletype == 'NA' ? '' : index.moduletype : '';
                let blockedbycode = index.hasOwnProperty('blockedbycode') ? index.blockedbycode == null || index.blockedbycode == 'NA' ? '' : index.blockedbycode : '';
                let blockedbyname = index.hasOwnProperty('blockedbyname') ? index.blockedbyname == null || index.blockedbyname == 'NA' ? '' : index.blockedbyname : '';
                let blockercode = index.hasOwnProperty('blockercode') ? index.blockercode == null || index.blockercode == 'NA' ? '' : index.blockercode : '';
                let blockername = index.hasOwnProperty('blockername') ? index.blockername == null || index.blockername == 'NA' ? '' : index.blockername : '';
                let status = index.hasOwnProperty('status') ? index.status == null || index.status == 'NA' ? '' : index.status : '';
                let projectname = index.hasOwnProperty('projectname') ? index.projectname == null || index.projectname == 'NA' ? '' : index.projectname : '';
                let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager == null || index.projectmaanager == 'NA' ? '' : index.projectmaanager : '';
                let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager == null || index.engineeringmaanager == 'NA' ? '' : index.engineeringmaanager : '';
                let qalead = index.hasOwnProperty('qalead') ? index.qalead == null || index.qalead == 'NA' ? '' : index.qalead : '';               
                let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ? '' : index.createddate : '';               
                let createdby = index.hasOwnProperty('createdby') ? index.createdby == null || index.createdby == 'NA' ? '' : index.createdby : '';               
                createdby = (createdby!=null || createdby!='')? createdby.split('@')[0] : 'NA';

                row += '"' + srno + '",',
                row += '"' + moduletype + '",',
                row += '"' + blockercode + '",',
                row += '"' + blockername + '",',
                row += '"' + blockedbycode + '",',
                row += '"' + blockedbyname + '",',
                row += '"' + status + '",',
                row += '"' + projectname + '",',
                row += (projectmaanager == null || projectmaanager == '') ? "NA," : '"'+ projectmaanager + '",'
                row += (engineeringmaanager == null || engineeringmaanager == '') ? "NA," : '"'+ engineeringmaanager + '",'
                row += (qalead == null || qalead == '') ? "NA," : '"'+ qalead + '",'
                row += '"' + this.dateMonthFormat(createddate) + '",',            
                row += '"' + createdby + '",',
                row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                return;
                }
                var fileName = 'BlockerModuleReport'+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export Report successful",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });   
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        }
    }
}
</script>