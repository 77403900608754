<template>

    <div class="app-content content">
        <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-12 col-12 mb-1 px-0 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-0">Job Openings</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <!-- <li class="breadcrumb-item sitebluecolor cursorpointercls"><h6>Executive - Human Resource (EXE-HR(BB))</h6></li>&nbsp; -->
                                <li>{{isjdcode ? `Job Code: ${isjdcode}`: isreqcode ? `(Requisition Code: ${isreqcode})` :''}}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section class="modern-horizontal-wizard ">
                <div class="wizard-modern modern-wizard-example card ">
                    <div class="bs-stepper-content pt-0 px-0">
                        <div  class="">
                            <div class="row mx-0">
                            <section  class="w-100">
                                <div class=" match-height">
                                    <div class="col-xl-12 col-lg-12 px-0">
                                        <div class="card">
                                        <div class="card-body pt-1 px-0">
                                            <div class="nav d-flex justify-content-between align-items-center px-1 hrms_raise_border ">
                                                <div class="container_hrms_raise">
                                                    <div class="card-header">
                                                        <div class="d-flex align-items-center" >
                                                                <em data-feather='file-text' class="side-bar_feather mr-1"></em>
                                                                <h2 class="card-title"> {{ iscandidatelist ? 'Candidate Listing' : 'Raise A Requisition'}} </h2>
                                                        </div>
        
                                                    </div>
                                                </div>
                                                <div class="addManagePositis_requisition buttons_hrms_view align-items-center ml-auto">
                                                    <div class="buttons_hrms_view demo-inline-spacing">
                                                        <div class="btn-group hrms-bt-mr-5" v-if="iscandidatelist">
                                                            <button type="button" class="btn btn-outline-primary"  @click="Exportdata(candidatedetails.searchalldata)">
                                                             <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                                                            </button>
                                                        </div>
                                                        <div class="btn-group hrms-bt-mr-5">
                                                            <button class="btn btn-outline-primary" type="button" id="dropdownMenuButton1" v-bind:class="[{active: requistionlista}]" @click="redirecttourl('raiserequisition')">
                                                                <em class="las la-list raiserequistion_size"></em> Requisition List
                                                            </button>
                                                        </div>
                                                        <!-- <div class="btn-group hrms-bt-mr-5">
                                                            <button class="btn btn-outline-primary" type="button" id="dropdownMenuButton2" v-bind:class="[{active: candodatelista}]" @click="selectcandidatelist">
                                                                <a id="jobResume-tab" data-toggle="tab" aria-controls="jobAppResume" href="#jobAppResume" role="tab" aria-selected="true"> <em class="las la-list raiserequistion_size"></em> Candidate Listing</a>
                                                            </button>
                                                        </div> -->
                                                        <!-- <div class="btn-group hrms-bt-mr-5" v-bind:class="[{active: raiserequistiona}]" @click="selectrequisition">
                                                            <button class="btn btn-outline-primary" type="button" id="dropdownMenuButton3"> <em class="las la-plus raiserequistion_size"></em> Raise Requisition</a>
                                                            </button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-content main_contaniner_hrms_raise">
        
                                            <div class="tab-pane active" id="jobAppResume" role="tabpanel" aria-labelledby="jobResume-tab" style="overflow: unset;" v-if="iscandidatelist">
                                                    <div class="tab-content main_contaniner_hrms_requistion pt-1">
                                                        <div class="container d-flex">
                                                            <ul class="nav nav-tabs mb-0" role="tablist">
                                                                <li class="nav-item">
                                                                <a class="nav-link active" id="job-tab1" data-toggle="tab" href="#job-dis" aria-controls="home" role="tab" aria-selected="true">All candidates {{candidatedetails.allcandidatelist.length}} </a>
                                                                </li>
                                                                <!-- <li class="nav-item">
                                                                <a class="nav-link" id="profile-tab1" data-toggle="tab" href="#profile1" aria-controls="profile" role="tab" aria-selected="false"> All Positions 0 </a>
                                                                </li> -->
                                                            </ul>
                                                            <div class="w-auto ml-auto">
                                                                <div class="search_div_raise_req buttons_hrms_view">
                                                                    <div class="input-group input-group-merge mb-0 " style="margin-right:5px;">
                                                                        <input type="text" class="form-control" placeholder="Search By Name/Email/Phone" aria-label="Search..." aria-describedby="basic-addon-search2" v-model="candidatedetails.searchalldata"/>
                                                                    </div>
                                                                    <button type="button" class="btn btn-outline-primary hrms-bt-mr-5 raiserequistion_size1" @click="getallcandidatelist(candidatedetails.searchalldata)">
                                                                        <span class=" cursor-pointer">
                                                                            <em class="la la-search"></em>
                                                                        </span>
                                                                    </button>
                                                                    <button type="button" class="btn btn-outline-primary hrms-bt-mr-5 raiserequistion_size1" @click="resetcandidatedeatils()">
                                                                        <span class="cursor-pointer">
                                                                            <em class="las la-sync-alt"></em>
                                                                        </span>
                                                                    </button>
                                                                    

                                                                    <!-- <div class="demo-inline-spacing  buttons_hrms_view " style="margin-left:5px;">
                                                                    <button type="button" class="btn btn-gradient-secondary mr-0 mt-0 d-flex"><em class="las la-filter " style="    font-size: 16px;
                                                                    margin-right: 5px;"></em> Filter</button>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                        </div>
                                                    <div class="tab-pane active" id="job-dis" aria-labelledby="job-tab1" role="tabpanel">
            
            <section id="vertical-tabs">
            <div class="row match-height">
            <div class="col-xl-12 col-lg-12">
            <div class="card">
            <div class="card-body pt-0 mr-0 px-0">
            <div class=" row nav-vertical_margin_x5 pl-1">
            <div class="tab-content col-md-12">
            
            <!-- <div class="bg-light-primary hrms_candidates_details">
                <ul class="row mx-0 design-planning-wrapper mb-2 py-75">
                    <li class="design-planning">
                        TOTAL APPLICATIONS 0
                    </li>
                    <li class="design-planning">
                        IN SHORTLISTING 0
            
                    </li>
                    <li class="design-planning">
                        IN EVALUATION 0
                    </li>
                    <li class="design-planning">
                        IN OFFER STAGE 0
                    </li>
                    <li class="design-planning">
                        AVTIVE EMPLOYEES 0
                    </li>
                    <li class="design-planning">
                        WITHDRAWN 0
                    </li>
                    <li class="design-planning">
                        REJECTED CANDIDATES 0
                    </li>
                </ul>
            </div> -->
                
                <div class="" id="basic-table">
                    <div class="card_views_hrms
                        ">
                        <div class="tab-pane active" id="candidatetable" aria-labelledby="jobdetails-tab-list" role="tab">
                            <div class="table-responsive">
                                <table class="table" aria-describedby="mydesc">
                                    <thead>
                                    <tr>
                                        <!-- <th scope="col" class="align-middle">
                                        <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                            <input type="checkbox" class="custom-control-input mr-1 mr-0" id="business-checkbox-1" />
                                            <label class="custom-control-label mr-0" for="business-checkbox-1"></label>
                    
                                        </div>
                                        </th> -->
                                        <th scope="col" class="text-center align-middle"><em data-feather='settings'></em></th>
                                        <th class="text-center  align-middle">Sr.No</th>
                                        <th scope="col" style="min-width: 150px;" class="text-left  align-middle"> Name & Id</th>
                                        <th scope="col" style="min-width: 200px;" class="text-left  align-middle"> Email & Phone Number </th>
                                        <th scope="col" style="min-width: 130px;" class="text-left align-middle"> Source</th>
                                        <th scope="col" style="min-width: 180px;" class="text-left align-middle"> Application Date</th>
                                        <th scope="col" style="min-width: 180px;" class="text-center align-middle"> Overall Status</th>
                                        <!-- <th scope="col" class="text-center align-middle"> Shortlisting</th> -->
                                        <!-- <th scope="col" class="text-center align-middle"> Sccreening</th> -->
                                        <!-- <th scope="col" class="text-center align-middle"> Interview Round 1</th> -->
                                        <!-- <th scope="col" class="text-center align-middle"> Offer Stage</th> -->
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(candidate,canindx) in candidatedetails.allcandidatelist" v-bind:key="canindx">
                                        <!-- <td class="text-center">
                                            <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                                <input type="checkbox" class="custom-control-input mr-1 mr-0" :id="'business-checkbox-2'+canindx" />
                                                <label class="custom-control-label mr-0" :for="'business-checkbox-2'+canindx"></label>
                                            </div>
                                        </td> -->
                                        <td class="clsaction" >
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item devEditEle" style="cursor: pointer;" href="javascript:void(0);" v-on:click="getLogtimeDownloadLink(candidate.cv)">
                                                        <div class="d-flex align-items-center">
                                                            <download-icon size="1.5x" class="custom-class mr-50" ></download-icon>
                                                            <span>Download CV</span>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item devEditEle" style="cursor: pointer;" href="javascript:void(0);" v-on:click="getLogtimeDownloadLink(candidate.cv)">
                                                        <div class="d-flex align-items-center">
                                                            <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                            <span>View CV</span>
                                                        </div>
                                                    </a>

                                                    <a class="dropdown-item devEditEle" data-toggle="modal" data-target="#edit_candidates_from"  @click="candidateeditreqid(candidate,'Edit Application Log')" >       
                                                    <div class="d-flex align-items-center"> 
                                                        <edit-2-icon size="1.5x" class="custom-class mr-50" ></edit-2-icon>                
                                                        <span>Edit Application</span>
                                                    </div>
                                                    </a>
                                                    <a href="javascript:void(0);" class="dropdown-item devEditEle" data-toggle="modal" data-target="#view_candidates_applicationlog" @click="candidateeditreqid(candidate,'View Application Log')">
                                                        <div class="d-flex align-items-center"> 
                                                            <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                            <span>View Application Log</span>
                                                        </div>
                                                    </a>
                                                    <a class="dropdown-item devEditEle" data-toggle="modal" data-target="#add_candidates_source" @click="seteditreqid(candidate)" > 
                                                    <div class="d-flex align-items-center"> 
                                                        <edit-2-icon size="1.5x" class="custom-class mr-50" ></edit-2-icon>
                                                        <span>Update Source</span>
                                                    </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center font-weight-normal">{{ canindx+1+currPageNum*pagelimit }}</td>
                                        <td class="text-left font-weight-normal"> {{ candidate.candidatename?candidate.candidatename:'NA'}}</td>
                                        <td class="text-left font-weight-normal">
                                        <h6>{{candidate.email}}</h6>
                                        <span>{{candidate.contactno}}</span>
                                        </td>
                                        <td class="text-left font-weight-normal"> {{ candidate.jobsourcename?candidate.jobsourcename:'NA' }} </td>
                                        <td class="text-left font-weight-normal">
                                        <h6> {{ candidate.applicationdate ? dateMonthFormat(candidate.applicationdate):'NA'}}</h6>
                                        <!-- <span> 16 seconds ago</span> -->
                                        </td>
                                        <td class="text-center font-weight-normal">
                                            <a class="btn badge badge-pill badge-light-success" href="javascript:void(0);" style="margin-bottom : 0.5rem;" @click="redirectpage('viewpage', candidate)" v-if="candidate.status.includes('Complated') || candidate.status.includes('Make Offer')">{{ candidate.status ? candidate.status :'NA' }}</a>
                                            <a class="btn badge badge-pill badge-light-danger" href="javascript:void(0);" style="margin-bottom : 0.5rem;" @click="redirectpage('viewpage', candidate)" v-else-if="candidate.status.includes('Rejected in the Process')">{{ candidate.status ? candidate.status :'NA' }}</a>
                                            <a class="btn badge badge-pill badge-light-warning" href="javascript:void(0);" style="margin-bottom : 0.5rem;" @click="redirectpage('viewpage', candidate)" v-else>{{ candidate.status ? candidate.status :'NA' }}</a>
                                            <!-- <span class="sitebluecolor card-text font-small-3">Shortlist Applicant <em data-feather='chevron-right'></em></span> -->
                                        </td>
                                        <!-- <td class="text-center font-weight-normal"> - </td> -->
                                        <!-- <td class="text-center font-weight-normal"> - </td> -->
                                        <!-- <td class="text-center font-weight-normal"> <span class="badge badge-pill badge-light-warning mr-1">Pending</span></td> -->
                                        <!-- <td class="text-center font-weight-normal"> <span class="badge badge-pill badge-light-warning mr-1"> - </span></td> -->
                                    </tr>
                                    <template v-if="candidatedetails.allcandidatelist.length ==0">
                                        <tr><td colspan="14" style="text-align: center;">Data Not found</td></tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pageCenter text-center mt-2">
                                <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="callbacktable"/>
                            </div>
                        </div>
                    </div>
                </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </section>
                                                    </div>
                                                    <div class="tab-pane px-1" id="profile1" aria-labelledby="profile-tab1" role="tabpanel">
            
                                                    <div class="search_div_raise_req">
                                                    <div class="input-group input-group-merge mb-0">
                                                        <input type="text" class="form-control" placeholder="Search..." aria-label="Search..." aria-describedby="basic-addon-search2" />
                                                            <div class="input-group-append">
                                                            <span class="input-group-text cursor-pointer">
                                                                <em data-feather="search"></em>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="demo-inline-spacing ml-1 buttons_hrms_view">
                                                    <button type="button" class="btn btn-gradient-secondary mr-0 mt-0 d-flex"> <em data-feather='filter' class="hrms-bt-mr-5"></em> Filter</button>
                                                    </div>
                                                </div>
                                                    <div class="" id="basic-table">
                                                    <div class="card_views_hrms
                                                        ">
                                                        <div class="table-responsive">
                                                            <table class="table" aria-describedby="mydesc">
                                                                <thead>
                                                                <tr>
                                                                    <th scope="col" class="align-middle">
                                                                    <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                                                        <input type="checkbox" class="custom-control-input mr-1 mr-0" id="business-checkbox-1" />
                                                                        <label class="custom-control-label mr-0" for="business-checkbox-1"></label>
            
                                                                    </div>
                                                                    </th>
                                                                    <th scope="col" class="align-middle" >&nbsp;</th>
                                                                    <th scope="col" class="align-middle" style="min-width: 130px;" > Name & ID</th>
                                                                    <th scope="col" class="align-middle" style="min-width: 200px;"> Email & Phone Number </th>
                                                                    <th scope="col" class="align-middle" style="min-width: 130px;"> Source</th>
                                                                    <th scope="col" class="align-middle" style="min-width: 180px;"> Application Date</th>
                                                                    <th scope="col" class="align-middle" style="min-width: 150px;"> Overall Status</th>
                                                                    <th scope="col" class="align-middle"> Shortlisting</th>
                                                                    <th scope="col" class="align-middle"> Sccreening</th>
                                                                    <!-- <th scope="col" class="align-middle"> Interview Round 1</th> -->
                                                                    <th scope="col" class="align-middle"> Offer Stage</th>
                                                                </tr>
                                                                </thead>
            
            
                                                                <tbody>
            
                                                                <tr>
                                                                    <td class="align-middle">
                                                                    <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                                                        <input type="checkbox" class="custom-control-input mr-1 mr-0" id="business-checkbox-2" />
                                                                        <label class="custom-control-label mr-0" for="business-checkbox-2"></label>
            
                                                                    </div>
            
                                                                    </td>
                                                                    <td class="align-middle">
                                                                        <div class="dropdown">
                                                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown">
                                                                            <em data-feather="more-vertical"></em>
                                                                        </button>
                                                                        <div class="dropdown-menu">
                                                                            <a class="dropdown-item" href="javascript:void(0);">
                                                                                <span>Download CV</span>
                                                                            </a>
                                                                            <a class="dropdown-item" href="javascript:void(0);">
                                                                                <span>View CV</span>
                                                                            </a>
                                                                            <a class="dropdown-item devEditEle" data-toggle="modal" data-target="#edit_candidates_from" @click="candidateeditreqid(candidate,'Edit Application Log')">
                                                                                <span>Edit Application</span>
                                                                            </a>
                                                                            <a class="dropdown-item" href="javascript:void(0);">
                                                                                <span>View Application Log</span>
                                                                            </a>
                                                                            <a class="dropdown-item" href="javascript:void(0);">
                                                                                <span>Update Source</span>
                                                                            </a>
                                                                        </div>
                                                                    </div></td>
                                                                    <td class="align-middle"> Rahul rao</td>
                                                                    <td class="align-middle">
                                                                    <h6>useremail</h6>
                                                                    <span> user phone</span>
                                                                    </td>
                                                                    <td class="align-middle"> Job Portal</td>
                                                                    <td class="align-middle">
                                                                    <h6> 06-Oct-2022</h6>
                                                                    <span> 16 seconds ago</span>
            
                                                                    </td>
                                                                    <td class="align-middle"> Shortlist</td>
                                                                    <td > </td>
                                                                    <td > </td>
                                                                    <!-- <td > <span class="badge badge-pill badge-light-warning mr-1">Pending</span></td> -->
                                                                    <td > <span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
        
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="container text-center">
                                                        <nav aria-label="Page navigation example">
                                                                <ul class="pagination mt-1 d-flex justify-content-center">
                                                                <li class="page-item prev"><a class="page-link" href="javascript:void(0);"></a></li>
                                                                <li class="page-item"><a class="page-link" href="javascript:void(0);">1</a></li>
                                                                <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                                                                <li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
                                                                <li class="page-item active" aria-current="page">
                                                                    <a class="page-link" href="javascript:void(0);">4</a>
                                                                </li>
                                                                <li class="page-item"><a class="page-link" href="javascript:void(0);">5</a></li>
                                                                <li class="page-item"><a class="page-link" href="javascript:void(0);">6</a></li>
                                                                <li class="page-item"><a class="page-link" href="javascript:void(0);">7</a></li>
                                                                <li class="page-item next"><a class="page-link" href="javascript:void(0);"></a></li>
                                                            </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class=""  style="overflow: unset;" v-if="israiserequisition">
                                                <div class="" v-bind:style="{display: displayblk}" id="position_table" aria-hidden="true" v-bind:class="[{show: isshowtable}]" v-if="noofreplacement">
                                                    <div class="card border mt-2 mx-2">
                                                        <div class="card-body  text-editar_discription_jobs masters_detailstabing_pills">
                                                            <ul class="nav nav-pills nav-pill-warning pt-0" role="tablist">
                                                                <!-- <li class="nav-item">
                                                                    <a class="nav-link active" id="positions_Details" data-toggle="tab" href="#positions" aria-controls="positions" role="tab" aria-selected="true">Positions Details</a>
                                                                </li>
                                                                <li class="nav-item">
                                                                    <a class="nav-link" id="positions_New-tab" data-toggle="tab" href="#positions_New" aria-controls="positions_New" role="tab" aria-selected="false">New</a>
                                                                </li> -->
                                                                <li class="nav-item">
                                                                    <a class="nav-link active" id="positions_replacement-tab" data-toggle="tab" href="#positions_replacement" aria-controls="positions_replacement" role="tab" aria-selected="false">Replacement</a>
                                                                </li>
                                                            </ul>
                                                            <div class="tab-content">
                                                                <div class="tab-pane active" id="positions" aria-labelledby="positions_Details" role="tabpanel">
                                                                    <div class="col-md-12 text-center mt-1 mb-1">
                                                                        <span class="required-field"> All fields marked with asterisk are required.</span>
                                                                    </div>
                                                                    <div class="" id="basic-table">
                                                                        <div class="card_views_hrms
                                                                            ">
                                                                            <div class=" datatableview" style="">
                                                                                <table class="table" aria-describedby="mydesc">
                                                                                    <thead>
                                                                                    <tr>
                                                                                        <th scope="col" style="width: 40px;" class="text-center">
                                                                                            Position Number
                                                                                        </th>
                                                                                        <th scope="col" style="min-width: 130px;" class="text-center"> Location <span class="required-field"></span>  <em class="las la-copy side-bar_line_copy mr-1"></em></th>
                                                                                        <th scope="col" style="min-width: 130px;" class="text-center"> Replacement For <span class="required-field"></span> </th>
                                                                                        <th scope="col" style="min-width: 190px;" class="text-center"> Reporting Manager <em class="las la-copy side-bar_line_copy mr-1"></em></th>
                                                                                        <th scope="col" style="min-width: 180px;" class="text-center"> Employee Type <span class="required-field"></span> <em class="las la-copy side-bar_line_copy mr-1"></em> </th>
                        
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(rep,repind) in noofreplacementArray" v-bind:key="repind">
                                                                                            <td data-column=" Position Number">
                                                                                                <div class="form-group">
                                                                                                <input type="number" id="number" class="form-control text-center" :placeholder="repind+1" disabled/>
                                                                                            </div>
                                                                                            </td>
                                                                                            <td data-column="Location">
                                                                                            <div class="form-group">
                                                                                                <treeselect placeholder="Select Location" class="projectlistdd capitalisetext" v-model="rep.location" :options="locationlist"/>
                                                                                            </div>
                                                                                            </td>
                                                                                            <td data-column="Replacement For ">
                                                                                            <div class="form-group">
                                                                                                <treeselect placeholder="Select Employee" class="projectlistdd capitalisetext" v-model="rep.replacementfor" :options="replacementforlist" @input="selreplacementfor"/>
                                                                                            </div>
                                                                                            </td>
                                                                                            <td data-column="Reporting Manager">
                                                                                            <div class="form-group">
                                                                                                <treeselect placeholder="Select Reporting Manager" class="projectlistdd capitalisetext" v-model="rep.reportingmns" :options="reportingmnglist"/>
                                                                                            </div>
                                                                                            </td>
                                                                                            <td data-column="Employee Type">
                                                                                            <div class="form-group">
                                                                                                <treeselect placeholder="Select Emp Type" class="projectlistdd capitalisetext" v-model="rep.emptype" :options="emptypelist"/>
                                                                                            </div>
                                                                                            </td>
                                                                                        </tr>
                        
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="tab-pane" id="positions_New" aria-labelledby="positions_New-tab" role="tabpanel">
                                                                    <p>
                                                                    Two
                                                                    </p>
                                                                </div>
                                                                <div class="tab-pane" id="positions_replacement" aria-labelledby="positions_replacement-tab" role="tabpanel">
                                                                    <p>
                                                                    Three
                                                                    </p>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-10 col-md-10 col-lg-9 m-auto bs-stepper horizontal wizard-modern modern-vertical-wizard-example">
                                                    <div class="bs-stepper-header job-openings_requistion">
                                                        <div class="step">
                                                            <!-- <button type="button" class="step-trigger" v-on:click="positionsselection('none',false,'step1')"> -->
                                                                <button type="button" class="step-trigger">
                                                                <span class="bs-stepper-label">
                                                                    <span class="bs-stepper-title">Basic Details</span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div class="step">
                                                            <!-- <button type="button" class="step-trigger" v-on:click="positionsselection('none',false,'step2')"> -->
                                                                <button type="button" class="step-trigger">
                                                                <span class="bs-stepper-label">
                                                                    <span class="bs-stepper-title"> Job Details</span>
                
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div class="step">
                                                            <!-- <button type="button" class="step-trigger" v-on:click="positionsselection('',true,'step3')"> -->
                                                                <button type="button" class="step-trigger">
                                                                <span class="bs-stepper-label">
                                                                    <span class="bs-stepper-title" data-target="#position_table">Position Selection</span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div class="step">
                                                            <!-- <button type="button" class="step-trigger" v-on:click="positionsselection('none',false,'step4')"> -->
                                                                <button type="button" class="step-trigger">
                                                                <span class="bs-stepper-label">
                                                                    <span class="bs-stepper-title">Other Details</span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div class="step">
                                                            <!-- <button type="button" class="step-trigger" v-on:click="positionsselection('none',false,'step5')"> -->
                                                                <button type="button" class="step-trigger">
                                                                <span class="bs-stepper-label">
                                                                    <span class="bs-stepper-title">Hiring Team</span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="bs-stepper-content mobile-padding-15-10">
                                                        <div id="basicDetailsVew" class="" v-if="isstape1open">
                                                            <div class="row mb-2">
                                                            <div class="col-sm-4 content-header mb-0">
                                                                <h5 class="mb-0">Basic Details</h5>
                                                            </div>
                                                            <div class="col-auto text-center ml-auto">
                                                                <span class="required-field text-warning"> All fields marked with asterisk are required.</span>
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="form-group col-md-6 mb-0">
                                                                    <div class="form-group">
                                                                        <label for="basicSelect" class="d-flex">Company Name<span class="required-field"></span></label>
                                                                        <treeselect placeholder="Select Company" class="projectlistdd capitalisetext" v-model="companyname" :options="companynamelist" @input="selectcompany" :disabled="isdetailsdesabled"/>
                                                                        <div class="errorinputmsg" v-if="this.errors.companyname">{{ this.errors.companyname }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group col-md-6 mb-0">
                                                                    <div class="form-group">
                                                                        <label class="d-flex" for="basicSelect">Business Unit</label>
                                                                        <treeselect placeholder="Select Business Resources" class="projectlistdd capitalisetext" v-model="businessunit" :options="businessunitlist"/>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group col-md-6 mb-0">
                                                                    <div class="form-group">
                                                                        <label class="d-flex" for="basicSelect"> Department Name<span class="required-field"></span></label>
                                                                        <treeselect placeholder="Select Department" class="projectlistdd capitalisetext" v-model="department" :options="departmentlist" @input="selectdepartment" :disabled="isdetailsdesabled"/>
                                                                        <div class="errorinputmsg" v-if="this.errors.department">{{ this.errors.department }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group col-md-6 mb-0">
                                                                    <div class="form-group">
                                                                        <label class="d-flex" for="basicSelect"> Designation Name<span class="required-field"></span></label>
                                                                        <treeselect placeholder="Select Designation" class="projectlistdd capitalisetext" v-model="designation" :options="designationlist" @input="selectdesignation" :disabled="isdetailsdesabled"/>
                                                                        <div class="errorinputmsg" v-if="this.errors.designation">{{ this.errors.designation }}</div>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <button class="btn btn-relief-primary btn-prev" disabled v-on:click="positionsselection('none',false,'step1')">
                                                                    <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                </button>
                                                                <button class="btn btn-relief-success btn-next" v-on:click="positionsselection('none',false,'step2')">
                                                                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                                                                    <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div id="job_details_view" class="" v-if="isstape2open">
                                                            <div class="row">
                                                            <div class="col-sm-4 content-header">
                                                                <h5 class="mb-0">Job Details</h5>
                                                            </div>
                                                            <div class="w-auto ml-auto text-center px-1">
                                                                <span class="required-field text-warning"> All fields marked with asterisk are required.</span>
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="demo-spacing-0 col-md-12 mb-1">
                                                                        <div class="alert alert-warning mt-1 alert-validation-msg" role="alert">
                                                                            <div class="alert-body">
                                                                                <span>There is no Job description tagged to this Designations. Please configure the job Description by ...</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                <div class="form-group col-md-4 mb-0">
                                                                    <div class="form-group">
                                                                        <label  for="basicSelect"> Experience Range </label>
                                                                        <treeselect placeholder="Select Experience Range From" class="projectlistdd" v-model="experiencerangefrom" :options="experiencerangefromlist" @input="selectexpfrom" :disabled="isdetailsdesabled"/>
                                                                        <div class="errorinputmsg" v-if="this.errors.experiencerangefrom">{{ this.errors.experiencerangefrom }}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group col-md-4 mb-0">
                                                                    <div class="form-group">
                                                                        <label  for="basicSelect"> &nbsp; </label>
                                                                        <treeselect placeholder="Select Experience Range To" class="projectlistdd" v-model="experiencerangeto" :options="experiencerangetolist" @input="selectexpto" :disabled="isdetailsdesabled"/>
                                                                        <div class="errorinputmsg" v-if="this.errors.experiencerangeto">{{ this.errors.experiencerangeto }}</div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div class="row">
                                                                <div class="form-group col-md-3 ">
                                                                    <div class="form-group">
                                                                        <label  for="basicSelect"> Salary Range (Currency) </label>
                                                                        <treeselect placeholder="Select Salary Currency" class="projectlistdd capitalisetext" v-model="salarycurrency" :options="salarycurrencylist"/>
                                                                        </div>
                                                                </div>
                                                                <div class="form-group col-md-3 ">
                                                                    <label class="form-label" for="vertical-modern-first-name">Salry Range (Min)</label>
                                                                    <input type="number" id="sarlayfirst-name" class="form-control" placeholder="Enter Number Only" v-model="minsalary" 
                                                                    v-bind:class="{
                                                                    'form-control': true,
                                                                    '': !validText2(minsalary)}"/>
                                                                    <div class="errorinputmsg" v-if="this.errors.minsalary && !validText2(minsalary)">{{ this.errors.minsalary }}</div>
                                                                </div>
                                                                <div class="form-group col-md-3 ">
                                                                    <label class="form-label" for="vertical-modern-first-name">Salry Range (Max)</label>
                                                                    <input type="number" id="sarlayfirst-name" class="form-control" placeholder="Enter Number Only" v-model="maxsalary"
                                                                    v-bind:class="{
                                                                    'form-control': true,
                                                                    '': !validText2(maxsalary)}"/>
                                                                    <div class="errorinputmsg" v-if="this.errors.maxsalary && !validText2(maxsalary)">{{ this.errors.maxsalary }}</div>
                                                                </div>
                                                                <div class="form-group col-md-3 mb-0">
                                                                    <div class="form-group">
                                                                        <label  for="basicSelect"> Salary Timeframe</label>
                                                                        <treeselect placeholder="Select Salary Timeframe" class="projectlistdd capitalisetext" v-model="salaryfimeframe" :options="salaryfimeframelist"/>
                                                                        </div>
                                                                </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="demo-spacing-0 col-md-12 mb-1">
                                                                        <div class="alert alert-primary mt-1 alert-validation-msg" role="alert">
                                                                            <div class="fill_belowsectionsbg" style="padding:8px;">
                                                                                <em class="las la-info-circle mr-50 align-middle"></em>
                                                                                <span > Fill below sections if you have any specific instruction for recruiters.</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                <!-- <div class="form-group col-md-6">
                                                                    <label class="form-label" for="vertical-modern-first-name">Recruitment Start Date</label>
                                                                    <date-picker placeholder="Select Recruitment Start Date" type="datetime" format="YYYY-MM-DD HH:mm" v-model="recruitmentstartdate" valueType="format"
                                                                    class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeTodaydate"></date-picker>
                                                                </div> -->
                                                                </div>
                                                                <div class="row">
                                                                <div class="form-group col-md-6">
                                                                    <label class="form-label" for="vertical-modern-first-name">Additional Roles & Responsibilities</label>
                                                                    <textarea class="form-control" placeholder="Enter Roles & Responsibilities" name="validationBioBootstrap" rows="1" required="" v-model="rolesresponsibilites"></textarea>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label class="form-label" for="vertical-modern-first-name">Additional Skills</label>
                                                                    <textarea class="form-control" placeholder="Enter Skills" name="validationBioBootstrap" rows="1" required="" v-model="additionalskills"></textarea>
                                                                </div>
                                                                </div>
                
                
                                                            <div class="d-flex justify-content-between">
                                                                <button class="btn btn-relief-primary btn-prev" v-on:click="positionsselection('none',false,'step1')">
                                                                    <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                </button>
                                                                <button class="btn btn-relief-success btn-next" v-on:click="positionsselection('',true,'step3')">
                                                                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                                                                    <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div id="position-selection-job" class="" v-if="isstape3open ==true">
                                                            <div class="col-md-8 py-1 m-auto">
                                                            <div class="content-header">
                                                                <h5 class="mb-0">Position Selection</h5>
                
                                                            </div>
                                                            <div class="row">
                                                                <div class="form-group col-md-12">
                                                                    <label class="form-label" for="vertical-modern-address">Number of New Positions</label>
                                                                    <input type="number" class="form-control" placeholder="Enter Number Only" pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';" @keypress="validNumber" v-model="noofnewpositions"/>
                                                                </div>
                                                                <div class="form-group col-md-12">
                                                                    <label class="form-label" for="">Number of Replacement Postion(s)</label>
                                                                    <input type="number" id="" class="form-control" placeholder="Enter Number Only" pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';" @keypress="validNumber" v-model="noofreplacement"/>
                                                                </div>
                                                                <div class="form-group col-md-12">
                                                                    <label class="form-label" for="">Number of Postion(s)</label>
                                                                    <input type="text" id="" class="form-control" placeholder="Enter Number Only" pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';" @keypress="validNumber" v-model="noofpositions"/>
                                                                </div>
                                                            </div>
                
                                                            <div class="d-flex justify-content-between">
                                                                <button class="btn btn-relief-primary btn-prev" v-on:click="positionsselection('none',false,'step2')">
                                                                    <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                </button>
                                                                <button class="btn btn-relief-success btn-next" v-on:click="positionsselection('none',false,'step4')">
                                                                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                                                                    <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                                                                </button>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div id="otherDetails_job" class="" v-if="isstape4open">
                                                            <div class="row">
                                                            <div class="col-sm-4 content-header">
                                                                <h5 class="mb-0">Other Details</h5>
                                                            </div>
                                                            <div class="w-auto ml-auto text-center px-1">
                                                                <span class="required-field text-warning"> All fields marked with asterisk are required.</span>
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="form-group col-md-6">
                                                                    <label class="form-label" for="Requirements">Comments/Instructions</label>
                                                                    <textarea class="form-control" placeholder="Enter Comments/Instructions" name="Requirements" rows="1" required="" v-model="instructions"></textarea>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label class="form-label" for="Requirements">Asset Requirements</label>
                                                                    <textarea class="form-control" placeholder="Enter Asset Requirements" name="Requirements" rows="1" required="" v-model="assetrequirements"></textarea>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label class="form-label" for="">Reporting Manager <span class="required-field"></span></label>
                                                                    <treeselect placeholder="Select Reporting Manager" class="projectlistdd capitalisetext" v-model="reportingmng" :options="allreportingmnglist" @input="selectrmanager"/>
                                                                    <div class="errorinputmsg" v-if="this.errors.reportingmng">{{ this.errors.reportingmng }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <button class="btn btn-relief-primary btn-prev" v-on:click="positionsselection('',true,'step3')">
                                                                    <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                </button>
                                                                <button class="btn btn-relief-success btn-next" v-on:click="positionsselection('none',false,'step5')">
                                                                    <span class="align-middle d-sm-inline-block d-none">Next</span>
                                                                    <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                                                                </button>
                                                                <!-- <button class="btn btn-relief-success" @click="submitdata()">Submit</button> -->
                                                            </div>
                                                        </div>
                                                        <div id="hiring_team" class="" v-if="isstape5open==true">
                                                            <div class="row">
                                                            <div class="col-sm-4 content-header">
                                                                <h5 class="mb-0">Hiring Team</h5>
                                                            </div>
                                                            <div class="w-auto ml-auto text-center px-1">
                                                                <span class="required-field text-warning"> All fields marked with asterisk are required.</span>
                                                            </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-6 mb-1">
                                                                    <label> Select Hiring Lead<span class="required-field"></span></label>
                                                                    <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 4 chars to search Hiring Lead" v-model="hiring.lead" :options="leadlist"  @close="validateForm()" v-on:search-change="getemprole($event,1)" @input="selectlead"/>
                                                                    <div class="errorinputmsg" v-if="this.errors.lead">{{ this.errors.lead }}</div>
                                                                </div>
                                                                <div class="col-md-6 mb-1">
                                                                    <label> Select Shortlisting Team</label>
                                                                    <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 4 chars to search Shortlisting Team" v-model="hiring.shortlisting" :options="shortlistinglist"  @close="validateForm()" v-on:search-change="getemprole($event,2)"/>
                                                                </div>
                                                                <div class="col-md-6 mb-1">
                                                                    <label> Select Screening Team</label>
                                                                    <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 4 chars to search Screening Team" v-model="hiring.screening" :options="screeninglist"  @close="validateForm()" v-on:search-change="getemprole($event,3)"/>
                                                                </div>
                                                                <div class="col-md-6 mb-1">
                                                                    <label> Select Scheduling Team</label>
                                                                    <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 4 chars to search Scheduling Team" v-model="hiring.scheduling" :options="schedulinglist"  @close="validateForm()" v-on:search-change="getemprole($event,4)"/>
                                                                </div>
                                                                <div class="col-md-6 mb-1">
                                                                    <label> Select Hiring Team</label>
                                                                    <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 4 chars to search Hiring Team" v-model="hiring.team" :options="teamlist"  @close="validateForm()" v-on:search-change="getemprole($event,5)"/>
                                                                </div>
                                                                <div class="col-md-6 mb-1">
                                                                    <label> Final Approval By<span class="required-field"></span></label>
                                                                    <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 4 chars to search final approval by" v-model="hiring.apprvalby" :options="apprvalbylist"  @close="validateForm()" v-on:search-change="getemprole($event,6)" @input="selectapprvalby"/>
                                                                    <div class="errorinputmsg" v-if="this.errors.apprvalby">{{ this.errors.apprvalby }}</div>
                                                                </div>
                
                                                                <div v-if="isApiResponseSuccessful" class="col-md-6 mb-1" v-for="(item, index) in roundlist" :key="index">
                                                                    <div>
                                                                        <label>Select Member For Interview Round {{ index + 1 }}<span class="required-field"></span></label>
                                                                        <treeselect class="projectlistdd capitalisetext" :placeholder="'Enter min 4 chars to search Member For Interview Round ' + [ index + 1 ]" v-model="hiring.interviewteam[index]" :options="interviewerslist" @close="validateForm()" v-on:search-change="getemprole($event,7)"/>
                                                                        <div class="errorinputmsg" v-if="hiring.interviewteam[index] == null && issubmit">Member For Interview Round {{ index + 1 }} is Required</div>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="d-flex justify-content-between" >
                                                                <button class="btn btn-relief-primary btn-prev" v-on:click="positionsselection('none',false,'step4')">
                                                                    <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                    <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                </button>
                                                                <button  type="button"  class="btn btn-relief-success" @click="submitdata()">Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="scrolling-inside-modal">
                                                <div class="modal fade" id="view_candidates_applicationlog" tabindex="-1" role="dialog" aria-labelledby="view_candidates_applicationlog_scroll" aria-hidden="true">
                                                    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="view_candidates_applicationlog_scroll"> View Application Log</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <section id="card-navigation">
                                                                <div class="file-manager-content-body">
                                                                    <div class="drives">
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="tab-content" id="apprisalformtable">
                                                                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                                                        <div class="row" id="proModuleList">
                                                                                            <div class="col-12">
                                                                                                <div class="card">
                                                                                                    <div class="table-responsive">
                                                                                                        <div class="modal-body flex-grow-1">
                                                                                                            <div class="row ">
                                                                                                                <div class="col-md-12 col-lg-6"> 
                                                                                                                    <div class="" style="margin-bottom:8px;"> <strong> Candidate Name:</strong> {{ candidatefnameedit+' '+candidatelnameedit }}</div>
                                                                                                                </div>
                                                                                                                <div class="col-md-12 col-lg-6 text-lg-right"> 
                                                                                                                    <div> <strong>Application date:</strong> {{ dateMonthFormat(candidateapplicationdate) }}</div> </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    <div class="col-sm-12">
                                                                                                        <div class="table-responsive px-0 mt-0 mb-2">
                                                                                                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th scope="col" class="align-middle srno first-col-sticky" style="width:45px;">Sr#</th>
                                                                                                                        <th scope="col" class="align-middle second-col-sticky" style="width: 150px;"> Stage Name </th>
                                                                                                                        <th scope="col" class="align-middle " style="min-width: 120px;"> Stage Status </th>
                                                                                                                        <th scope="col" class="align-middle " > Action BY </th>
                                                                                                                        <th scope="col" class="align-middle" style="min-width: 150px;"> Action Date </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    <template v-for="(data,index) in candidatedetails.applicationloglist" >        
                                                                                                                        <tr v-bind:key="index">
                                                                                                                            <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                                                                            <td class="capitalisetext second-col-sticky capitalisetext">
                                                                                                                                {{ data.stagename }}
                                                                                                                            </td>
                                                                                                                            <td class="capitalisetext">
                                                                                                                                {{ data.stagestatus }}
                                                                                                                            </td>
                                                                                                                            <td class="capitalisetext">
                                                                                                                                {{ data.lastmodifiedby ? extractNameFromEmail(data.lastmodifiedby): extractNameFromEmail(data.createdby) }}
                                                                                                                            </td>
                                                                                                                            <td>
                                                                                                                                {{ data.lastmodifieddate ? dateMonthFormat(data.lastmodifieddate) : dateMonthFormat(data.createddate) }}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </template>
                                                                                                                    <template v-if="candidatedetails.applicationloglist.length==0">
                                                                                                                        <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                                                                    </template>
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
        
        
    <div class="scrolling-inside-modal">
        <div class="modal fade" id="edit_candidates_from" tabindex="-1" role="dialog" aria-labelledby="edit_candidates_from_scroll" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="edit_candidates_from_scroll"> Edit Application</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    <div class="modal-body">
        <form action="#"
    class="dropzones dropzone-areas"
    id="dpz-single-filess"
    >
    <div class="">
    <div
    class="row raise_requistionbg_upload card_views_hrms"
    id="raise_requistionbg_upload">
    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
    <div class="form-group">
    <label for="basicSelect">Source
    </label>
    <treeselect
    class="projectmdllistdd capitalisetext" v-model="candidatesource" :options="jobportalsourcelist" @input="selsource" placeholder="Select Source"/>
    <div class="errorinputmsg" v-if="errors.jobportalsource">{{ errors.jobportalsource }}</div>
    </div>
    </div>
    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
    <div class="form-group">
    <label for="basicSelect">
    Select Job Portal
    </label>
    <treeselect
        class="projectmdllistdd capitalisetext"
        v-model="candidateeditjobportalname"
        :options="jobportalnamelist"
        @input="selportal"
        placeholder="Select Job Portal"
    />
    <div class="errorinputmsg" v-if="errors.jobportalname">{{ errors.jobportalname }}</div>
    </div>
    </div>
    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
    <div class="form-group">
    <label class="form-label" for=""
    >Application Date</label
    >
    <date-picker id="EndDate" placeholder="Select Application Date" type="datetime" format="YYYY-MM-DD" v-model="this.dateFormat(candidateapplicationdate)" valueType="format"
    class="startdate capitalisetext" :minute-step="5" disabled @input="selcurrdate"></date-picker>
    <div class="errorinputmsg" v-if="errors.currentDate">{{ errors.currentDate }}</div>
    </div>
    </div>

    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
    <div class="form-group">
    <label for="basicSelect">Candidate First Name
    </label>
    <input class="form-control" type="text" v-model="candidatefnameedit" @input="selcandidatefname" placeholder="Enter Candidate First Name"/>
    <div class="errorinputmsg" v-if="errors.candidatefname">{{ errors.candidatefname }}</div>
    </div>
    </div>

    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
    <div class="form-group">
    <label for="basicSelect">Candidate Last Name
    </label>
    <input class="form-control" type="text" v-model="candidatelnameedit" @input="selcandidatelname" placeholder="Enter Candidate Last Name"/>
    <div class="errorinputmsg" v-if="errors.candidatelname">{{ errors.candidatelname }}</div>
    </div>
    </div>

    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
    <div class="form-group">
    <label for="basicSelect">Candidate Contact No
    </label>
    <input class="form-control" type="text" @keypress="validNumber" v-model="candidateeditcontactno" @input="selcontactno" placeholder="Enter Candidate Contact No" maxlength="10"/>
    <div class="errorinputmsg" v-if="errors.candidatecontact">{{ errors.candidatecontact }}</div>
    </div>
    </div>

    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4">
    <div class="form-group">
    <label for="basicSelect">Candidate Email
    </label>
    <input class="form-control" type="text" v-model="candidateeditemail" @input="selcandidateemail" placeholder="Enter Candidate Email"/>
    <div class="errorinputmsg" v-if="errors.candidateemail">{{ errors.candidateemail }}</div>
    </div>
    </div>

    <div
    class="form-group col-md-8"
    >
    <div class="form-group">
    <label class="w-100 d-block"> &nbsp;</label>
    <div id="dropzone-examples">
    <label for="attachment" class="btn btn-sm btn-primary mb-75 mr-75">Select Pdf</label>
    <button class="btn btn-sm btn-primary mb-75 mr-75" v-on:click.stop.prevent="onUpload()">
    Upload</button>
    {{ newfile ? newfile :candidatecv ? showmappingname(candidatecv) :'' }}
    <input
    type="file"
    id="attachment"
    hidden
    accept="application/pdf"
    ref="file"
    @change="onSelect"
    />
    <!-- <div class="invalid-feedback">
    project desc must be greater than 5
    char
    </div> -->
    </div>
    </div>
    </div>
    </div>
    <div class="modal-footer">
    <button type="button" class="btn btn-relief-secondary" data-dismiss="modal" @click="cancelForm()">Cancel</button>
    <button type="button" class="btn btn-relief-success" data-dismiss="modal" id="submitbtnt90" v-bind:disabled="resumeupload.disblesavebtn" v-on:click.stop.prevent="saveuploadresume($event)" > Submit </button>
    </div>
    </div>
    </form>
        </div>
        </div>
        </div>
        </div>


    <div class="scrolling-inside-modal">
        <div class="modal fade" id="add_candidates_source" tabindex="-1" role="dialog" aria-labelledby="add_candidates_source_scroll" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-sm" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="add_candidates_source_scroll"> Update Source</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form action="#"
    class="dropzones dropzone-areas"
    id="dpz-single-filess"
    >
    <div class="modal-body">
    <div
    class="row raise_requistionbg_upload card_views_hrms"
    id="raise_requistionbg_source">

    <div class="form-group col-md-6">
    <div class="form-group">
    <label for="basicSelect">Candidate Name
    </label>
    <input class="form-control" type="text" :value="candidateFnamesource+' '+candidateLnamesource" disabled>
    </div>
    </div>

    <div class="form-group col-md-6">
    <div class="form-group">
    <label for="basicSelect">Source
    </label>
    <treeselect
    class="projectmdllistdd capitalisetext" :options="jobportalsourcelist" v-model="jobportalsourceedit" />
    </div>
    </div>
    
    <div class="invalid-feedback">
    project desc must be greater than 5
    char
    </div> 
    </div>
    <div class="modal-footer px-0">
    <button type="button" class="btn btn-relief-secondary" data-dismiss="modal" @click="cancelForm()">Cancel</button>
    <button type="button" class="btn btn-relief-success" data-dismiss="modal" id="submitbtnt70"  v-on:click.stop.prevent="updatesource($event)" > Submit </button>
    </div>
    </div>
    </form>
</div>
        </div>
        </div>
        </div>
        </div>

        
        </div>
        </div>
        </div>
        </div>
                                </div>
                            </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    </template>
    <style>
    .required-field::after {
    content: "*";
    color: red;
    font-size: 18px;
    display: inline-block;
    margin-left: 5px;
    }
    </style>
    <script>
    import "vue-loading-overlay/dist/vue-loading.css";
    import DatePicker from "vue2-datepicker";
    import "vue2-datepicker/index.css";
    import axios from 'axios'
    import moment from 'moment'
    import VueElementLoading from 'vue-element-loading';
    import vSelect from 'vue-select';
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import Pagination from 'vue-pagination-2';
    import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon,ExternalLinkIcon } from 'vue-feather-icons';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import commonMethods from '../../utils/commonMethods';
    import VueTimepicker from 'vue2-timepicker'
    import apiUrl from '../../constants'
    
    export default {
        name: "RaiseRequisition",
        components:{
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,DatePicker,VueTimepicker,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,ExternalLinkIcon
    },
     mixins: [ commonMethods ],
        data() {
        return {
            defaultValue: new Date(),
            isLoading: false,
            fullPage: true,
            tokendata:null,
            jdid: null,
            status: null,
            jobportalname: null,
            candidatefname: null,
            candidatelname: null,
            candidatecontact: null,
            candidateemail: null,
            applicationdate: null,
            applicationdateval:null, 
            editid:null,
            jsid:null,
            candidateFnamesource:null,
            candidateLnamesource:null,            
            jobportalsource: null,
            jobportalsourceedit: null,
            companyname: null,
            companynamelist: [],
            businessunit: null,
            file : [],
            businessunitlist: [{
                id: 'Firstcry',
                label: 'Firstcry'
            },{
                id: 'Firstcry.com',
                label: 'Firstcry.com'
            }],
            resumeupload: {
            disblesavebtn:false,
            filedescp:[],
            attachmentlink: ""
            },
            jobportalnamelist: [
              {
                id: "",
                label: "",
              },
            ],
            jobportalsourcelist: [
              {
                id: "",
                label: "",
              },
            ],
            currentDate: '',
            department: null,
            departmentlist: [],
            designation: null,
            designationlist: [],
            experiencerangefrom: null,
            experiencerangefromlist: [
                {id:'0',label:'0'},
                {id:'1',label:'1'},
                {id:'2',label:'2'},
                {id:'3',label:'3'},
                {id:'4',label:'4'},
                {id:'5',label:'5'},
                {id:'6',label:'6'},
                {id:'7',label:'7'},
                {id:'8',label:'8'},
                {id:'9',label:'9'},
                {id:'10',label:'10'},
                {id:'11',label:'11'},
                {id:'12',label:'12'},
                {id:'13',label:'13'},
                {id:'14',label:'14'},
                {id:'15',label:'15'},
                {id:'16',label:'16'},
                {id:'17',label:'17'},
                {id:'18',label:'18'},
                {id:'19',label:'19'},
                {id:'20',label:'20'}
            ],
            experiencerangeto: null,
            experiencerangetolist: [
            {id:'0',label:'0'},
                {id:'1',label:'1'},
                {id:'2',label:'2'},
                {id:'3',label:'3'},
                {id:'4',label:'4'},
                {id:'5',label:'5'},
                {id:'6',label:'6'},
                {id:'7',label:'7'},
                {id:'8',label:'8'},
                {id:'9',label:'9'},
                {id:'10',label:'10'},
                {id:'11',label:'11'},
                {id:'12',label:'12'},
                {id:'13',label:'13'},
                {id:'14',label:'14'},
                {id:'15',label:'15'},
                {id:'16',label:'16'},
                {id:'17',label:'17'},
                {id:'18',label:'18'},
                {id:'19',label:'19'},
                {id:'20',label:'20'}
            ],
            salarycurrency: null,
            salarycurrencylist: [
                {id:'INR',label:'INR'},
                {id:'Dollar',label:'Dollar'}
            ],
            salaryfimeframe: null,
            salaryfimeframelist:[
                {id:'Annual',label:'Annual'},
                {id:'Month',label:'Month'}
            ],
            candidateeditid: null,
            candidatefnameedit: null,
            candidatelnameedit: null,
            candidateeditereqid: null,
            candidateeditstatus: null,
            candidatesource: null,
            candidatecv: null,
            candidateapplicationdate: null,
            candidateeditemail: null,
            candidateeditcontactno: null,
            candidateeditjobportalname:null,
            minsalary: null,
            maxsalary: null,
            recruitmentstartdate: null,
            rolesresponsibilites: null,
            additionalskills: null,
            instructions: null,
            assetrequirements: null,
            reportingmng: null,
            reportingmnglist: [],
            allreportingmnglist:[],
            noofnewpositions: null,
            noofreplacement: null,
            noofpositions: null,
            isshowtable: false,
            displayblk:'none',
            location: null,
            locationlist: [],
            replacementfor: null,
            roundlist:[],
            replacementforlist: [],
            replacementreportingmng: null,
            emptype: null,
            emptypelist: [],
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            candidatedetails:{
               allcandidatelist: [],
               searchalldata: null,
               applicationloglist: []
            },
            errors:{
                experiencerangefrom: null,
                experiencerangeto: null,
                minsalary: null,
                maxsalary: null,
                companyname: null,
                department: null,
                designation: null,
                reportingmng: null,
                jobportalsource: null,
                jobportalname: null,
                jobportalsource: null,
                currentDate: null,
                candidatefname: null,
                candidatelname: null,
                candidatecontact: null,
                candidateemail: null,
                attachmentlink: null,
                lead: null,
                shortlisting: null,
                screening: null,
                scheduling: null,
                interviewteam:null,
                team: null,
                apprvalby: null
            },
            empRoleData: [],      
            hiring: {
                lead: null,
                shortlisting: null,
                screening: null,
                scheduling: null,
                interviewteam: [],
                team: null,
                apprvalby: null
            },
            israiserequisition: false,
            iscandidatelist: false,
            dspblk: '',
            isstape1open: false,
            isstape2open: false,
            isstape3open: false,
            isstape4open: false,
            isstape5open: false,
            isApiResponseSuccessful: false,
            isjdid: null,
            isjdcode: null,
            raiserequistiona: false,
            candodatelista: false,
            requistionlista: false,
            isreqcode: null,
            isreqid: null,
            newfile: null,
            activeflag:null,
            reqjddata: null,
            leadlist: [],
            shortlistinglist: [],
            screeninglist: [],
            schedulinglist: [],
            teamlist: [],
            apprvalbylist: [],
            interviewerslist: [],
            issubmit: false,
            isdetailsdesabled :false
        }
    },
    computed:{
        noofreplacementArray(){
            let noArray = [];
            for(let i=0; i < this.noofreplacement;i++){
                noArray.push(i);
                noArray[i]={
                    location:null,
                    replacementfor:null,
                    reportingmns:null,
                    emptype:null
                }
            }
            let addd = this.noofnewpositions ? parseInt(this.noofnewpositions) : null
            if(addd){
                this.noofpositions = addd+parseInt(noArray.length);
            }else if(noArray.length){
                this.noofpositions = parseInt(noArray.length);
            }
            return noArray;
        },
    },
    mounted() {
        $(function () {
            'use strict';
            var bsStepper = document.querySelectorAll('.bs-stepper'),
                select = $('.select2'),
                modernWizard = document.querySelector('.modern-wizard-example')
            select.each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                placeholder: 'Select value',
                dropdownParent: $this.parent()
                });
            });
    
            if (typeof modernWizard !== undefined && modernWizard !== null) {
                var modernStepper = new Stepper(modernWizard, {
                linear: false
                });
                $(modernWizard)
                .find('.btn-next')
                .on('click', function () {
                    modernStepper.next();
                });
                $(modernWizard)
                .find('.btn-prev')
                .on('click', function () {
                    modernStepper.previous();
                });
                // $(modernWizard)
                // .find('.btn-submit')
                // .on('click', function () {
                //     alert('Submitted..!!');
                // });
            }
    
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.getcompanylist();
            this.getDepartments();
            this.getlocationlist();
            this.getnotactiveemp();
            this.getemptypelist();
            this.getAllManagerList();
            this.getJobportal();
            this.getJobsource();
            this.setCurrentDate();
            if(this.$route.params && this.$route.params.clickeddata && this.$route.params.clickeddata.backbtnstate && this.$route.params.clickeddata.backbtnstate =='raisrequisition'){
                this.jdid = parseInt(this.$route.params.clickeddata.jdid);
                this.isjdcode = this.$route.params.clickeddata.jdcode;
                this.israiserequisition =true;
                this.iscandidatelist =false;
                this.raiserequistiona =true;
                this.candodatelista =false;
                this.requistionlista =false;
                this.dspblk ='block';
                this.slestep1();
                if(this.jdid && this.jdid !=null){
                    this.getinterviewround(this.jdid);
                    this.getjddetailsbyid(this.jdid);
                }
            }else if(this.$route && this.$route.params.clickeddata && this.$route.params.clickeddata.name && this.$route.params.clickeddata.name =='requistionlisting'){
                this.isjdid = parseInt(this.$route.params.clickeddata.jdcodeid);
                this.isjdcode = this.$route.params.clickeddata.jdcode;
                this.candodatelista =true;
                this.iscandidatelist =true;
                this.getallcandidatelist(null,this.isjdid);
            }else if(this.$route && this.$route.params.clickeddata && this.$route.params.clickeddata.backbtnstate && this.$route.params.clickeddata.backbtnstate =='reqcandidatelisting'){
                this.isjdid = null;
                this.isjdcode = null;
                this.iscandidatelist =true;
                this.candodatelista =true;
                this.isreqid = parseInt(this.$route.params.clickeddata.reqid);
                this.isreqcode = this.$route.params.clickeddata.reqcode;
                this.getallcandidatelist(null,null,this.isreqid);
            }else{
                this.iscandidatelist =true;
                this.candodatelista =true;
                this.israiserequisition =false;
                this.getallcandidatelist();
            }
        }
    },
    methods:{
        setCurrentDate() {
          const now = new Date();
          const year = now.getFullYear();
          let month = now.getMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }
          let day = now.getDate();
          if (day < 10) {
            day = `0${day}`;
          }
          this.currentDate = `${day}-${month}-${year}`;
        },
        cancelForm() {
            this.$router.push('/hrms/raiserequisition')
        },
        getemprole(node,isflag) {
            if (node && node.length > 3) {
                this.isLoading = true;
                this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empname: node
                }
                axios({
                    'method': 'POST',
                    'url': 'api/hrms/getemprole',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                    if(isflag ==1){
                        this.leadlist = result.data.data.rows;
                    }else if(isflag ==2){
                        this.shortlistinglist = result.data.data.rows;
                    }else if(isflag ==3){
                        this.screeninglist = result.data.data.rows;
                    }else if(isflag ==4){
                        this.schedulinglist = result.data.data.rows;
                    }else if(isflag ==5){
                        this.teamlist = result.data.data.rows;
                    }else if(isflag ==6){
                        this.apprvalbylist = result.data.data.rows;
                    }else if(isflag ==7){
                        this.interviewerslist = result.data.data.rows;
                    }else{
                        this.empRoleData = result.data.data.rows;
                    }
                }else{
                        this.empRoleData=[]
                    }
                }).catch(e => {
                 this.displayError(e)
                });
            }
        },
        saveuploadresume(objThis){
                let valid = true;
                valid = this.validateuploadresumeForm(objThis);
                if (valid) {
                    this.isLoading = true;
                    this.input = {
                        useremail: this.undt.username.trim(),
                        empcode: this.undt.userid.trim(),
                        jobportalname: this.candidateeditjobportalname,
                        candidatecontact: this.candidateeditcontactno.trim(),
                        candidateemail: this.candidateeditemail.trim(),
                        attachmentlink: this.resumeupload.attachmentlink,
                        candidatelname: this.candidatelnameedit.trim(),
                        candidatefname: this.candidatefnameedit.trim(),
                        jobportalsource: this.candidatesource,
                        candidateeditid: this.candidateeditid,
                        candidateeditreqid: this.candidateeditereqid,
                        // candidateeditstatus: this.candidateeditstatus.trim(),
                    }
                    let apiURL= 'api/candidate/update'    
                    axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {
                        this.isLoading = false;
                        if(result.data.errorCode == 0){
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            $(".close").click();
                            this.$router.push('/hrms/raiserequisition')
                            this.getallcandidatelist();
                        } else if(result.data.errorCode == 3){
                            Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                        }else if(result.data.errorCode == -1){
                            Swal.fire({
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        } else {
                            Swal.fire({
                                title: "",
                                text: result.data.msg,
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        }
                    })
                    .catch(e => {

                        this.displayError(e)
                        this.isLoading = false;
                        Swal.fire({
                            title: "",
                            text: e,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    })
                }
            },
            updatesource(objThis){
                //this.valid = this.validateuploadresumeForm(objThis);
                this.valid =true;
                if (this.valid) {
                    this.isLoading = true;
                    this.input = {                      
                        useremail: this.undt.username,
                        empcode: parseInt(this.undt.userid),
                        hcid:parseInt(this.editid),
                        reqid: parseInt(this.candidatereqidsource),
                        jobportalsource: this.jobportalsourceedit,
                        candidatestatus: this.candidatestatussource,
                    }                     
                    let apiURL= 'api/candidate/updatesource'         
                    axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {
                        this.isLoading = false;
                        if(result.data.errorCode == 0){
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            $(".close").click();
                            // window.location.reload();
                            // this.$router.push('/hrms/raiserequisition')
                            this.getallcandidatelist();
                        } else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                                }).then(function() {
                                    window.location.href = "/#/login";
                                })
                            } else {
                            Swal.fire({
                                title: "",
                                text: result.data.msg,
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            $(".close").click();
                            // window.location.reload();
                        }
                    })
                    .catch(e => {
                        this.displayError(e)
                        this.isLoading = false;
                        Swal.fire({
                            title: "",
                            text: e,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    })
                }
            },
            onUpload(){
                if(this.file){
                    this.isLoading=true
                    let formData = new FormData();

                    formData.append("empcode", this.undt.userid);
                    formData.append("useremail", this.undt.username);
                    formData.append("moduletype", 'resumeupload');
                    formData.append("fcount", '1');

                    let maxsize = 0;
                    for (let index = 0; index < this.file.length; index++) {
                        formData.append("imagefile", this.file[index]);
                        maxsize+= this.file[index].size
                    }

                    formData.empcode=this.undt.userid;
                    formData.useremail=this.undt.username;
                    formData.moduletype='resumeupload';
                    formData.fcount= '1';
                    formData.imagefile = this.file;
                    formData.empcode = this.undt.userid;
                    formData.moduletype= 'resumeupload';
                    formData.append("imagefile", this.file);
                    this.apiURL="api/master/fileupload/uploadResuleFileToS3"
                    if(this.file.length !== 0){
                    let arrGlbMdl = 1;
                        if(arrGlbMdl > 0){
                            let maxsize = 0;
                            for (let index = 0; index < this.file.length; index++) {
                                formData.append("imagefile", this.file[index]);
                                maxsize+= this.file[index].size
                            }
                            if (maxsize > 1000 * 1000 * 25) {
                                this.isLoading = false;
                                Swal.fire({
                                    title: "Failed",
                                    text: "Your document uploaded above 25mb not allow",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                                this.isLoading=false
                                this.resumeupload.disblesavebtn = true
                                return false;
                            }
                            axios({
                                'content-type':'multipart/form-data',
                                'method': 'POST',
                                'url': this.apiURL,
                                'data':formData,
                                'headers':{'authorization':this.tokendata}
                            })
                            .then(result => {
                                this.isLoading=false;
                                if(result.data.status){
                                    this.resumeupload.disblesavebtn=false
                                    // this.resumeupload.attachmentlink= result.data.data[0];
                                    const bindinfo = result.data.data.map((file, index) => {
                                        return {"link":file,"info":this.resumeupload.filedescp[index]}
                                    });
                                    let jsonobj = {data:bindinfo}
                                    this.resumeupload.attachmentlink = JSON.stringify(jsonobj);
                                    Swal.fire({
                                        title: "Success!",
                                        text: result.data.msg,
                                        icon: 'success',
                                        customClass: {
                                            confirmButton: "btn btn-primary"
                                        },
                                        buttonsStyling: !1
                                    })
                                }
                            })
                        }else{
                            this.isLoading=false;
                            Swal.fire({
                                title: "Failed",
                                text: "Your document uploaded above 25mb not allow",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            this.isLoading=false
                            return false;
                        }
                        // axios({
                        //     'content-type':'multipart/form-data',
                        //     'method': 'POST',
                        //     'url': this.apiURL,
                        //     'data':formData,
                        //     'headers':{'authorization':this.tokendata}
                        // })
                        // .then(result => {
                        //     this.isLoading=false;
                        //     if(result.data.status){
                        //         this.resumeupload.disblesavebtn=false
                        //         // this.resumeupload.attachmentlink= result.data.data[0];
                        //         const bindinfo = result.data.data.map((file, index) => {
                        //                 return {"link":file,"info":this.resumeupload.filedescp[index]}
                        //             });
                        //             let jsonobj = {data:bindinfo}
                        //             this.resumeupload.attachmentlink = JSON.stringify(jsonobj);
                        //         Swal.fire({
                        //             title: "Success!",
                        //             text: result.data.msg,
                        //             icon: 'success',
                        //             customClass: {
                        //                 confirmButton: "btn btn-primary"
                        //             },
                        //             buttonsStyling: !1
                        //         })
                        //     } else {
                        //         Swal.fire({
                        //             title: "",
                        //             text: result.data.msg,
                        //             icon: 'info',
                        //             customClass: {
                        //                 confirmButton: "btn btn-primary"
                        //             },
                        //             buttonsStyling: !1
                        //         })
                        //         this.resumeupload.attachment=null
                        //     }
                        // })
                    }else{
                        this.isLoading=false;
                        Swal.fire({
                            title: "",
                            text: 'Select Pdf',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resumeupload.attachment=null
                    }
                } else {
                    this.isLoading=false;
                    Swal.fire({
                        title: "",
                        text: 'Select Pdf',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.resumeupload.attachment=null
                }
            },
            onSelect() {
                    this.file = this.$refs.file.files[0];
                    let allotype = ['application/pdf'];
                    if (!allotype.includes(this.file.type)) {
                        Swal.fire({
                            title: "Failed",
                            text: 'Please Select only pdf file',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.file =[];
                        return
                    }
                    this.newfile = this.file.name;
                    this.errors.attachmentlink =null;
                    this.resumeupload.disblesavebtn =true;
                },
                getinterviewround(jdid) {
                let url = 'api/hrms/getinterviewround'
                axios({
                    method: 'POST',
                    url,
                    data:{useremail: this.undt.username,empcode: this.undt.userid,jdid:jdid},
                    headers: { authorization: this.tokendata }
                }).then(result => {
                    if(result.data.errorCode == 0) {
                        this.roundlist = result.data.data.rows[0].label
                        this.isApiResponseSuccessful = true;
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.roundlist = []
                    }
                }).catch(e => {
                        this.displayError(e)
                });
                },
        getcompanylist() {
            let url = 'api/hrms/getcompanylist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.companynamelist = result.data.data.rows
                    this.companynamelist.sort((a, b) => {
                     return a.label.localeCompare(b.label);
                    });
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.companynamelist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getJobportal() {
            let url = 'api/hrms/getjobportallist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.jobportalnamelist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.jobportalnamelist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getDepartments() {
            let url = 'api/coursemaster/getDepartments'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.departmentlist = result.data.data.rows
                    const decryptedDepartmentList = this.departmentlist.map(item => {
                    item.label = this.decryptText(item.label);
                    return item;
                    });
                    this.departmentlist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.departmentlist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getDesignationdata(deptid){
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                deptid: deptid
            }
                axios({
                'method': 'POST',
                'url': 'api/designationmaster/getdesigniationlist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.designationlist = result.data.data.rows;
                    // const decryptedDepartmentList = this.designationlist.map(item => {
                    // item.label = this.decryptText(item.label);
                    // return item;
                    // });
                    // this.designationlist.sort((a, b) => {
                    //     return a.label.localeCompare(b.label);
                    // });
                } else {
                    this.designationlist = [];
                }
                }).catch(e => {
                    this.displayError(e)
                })
            },
        getlocationlist() {
            let url = 'api/countrymaster/getcountrylist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.locationlist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.locationlist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getnotactiveemp() {
            let url = 'api/hrms/getnotactiveemp'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.replacementforlist = result.data.data.emprows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.replacementforlist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getAllManagerList() {
            axios({
                'method': 'POST',
                'url': 'api/searching/getmanagerlist',
                'headers':{'authorization':this.tokendata},
                'data': {
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.allreportingmnglist = result.data.data;
                    let decryptdepartmenttext = this.allreportingmnglist.map(items =>{
                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.allreportingmnglist = [];
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getManagerList(empid) {
            axios({
                'method': 'POST',
                'url': 'api/hrms/getemployeemng',
                'headers':{'authorization':this.tokendata},
                'data': {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    empid: parseInt(empid)
                }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.reportingmnglist = result.data.data
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.reportingmnglist = []
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getemptypelist() {
            let url = 'api/employeetypemaster/getemployeetypelist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.emptypelist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else {
                    this.emptypelist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        selreplacementfor(state){
            this.getManagerList(state)
        },
        submitdata(objThis,index){
            let valid = this.validateForm(objThis);
            if (valid) {
                this.isLoading = true;
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    jdid: this.jdid,
                }
                this.input.status = this.status
                this.input.businessunit = this.businessunit
                this.input.companyname = this.companyname
                this.input.department = this.department
                this.input.designation = this.designation
                this.input.experiencerangefrom = this.experiencerangefrom
                this.input.experiencerangeto = this.experiencerangeto
                this.input.salarycurrency = this.salarycurrency
                this.input.minsalary = this.minsalary
                this.input.maxsalary = this.maxsalary
                this.input.salaryfimeframe = this.salaryfimeframe
                // this.input.recruitmentstartdate = this.recruitmentstartdate
                this.input.rolesresponsibilites = this.rolesresponsibilites
                this.input.additionalskills = this.additionalskills
                this.input.instructions = this.instructions
                this.input.assetrequirements = this.assetrequirements
                this.input.reportingmng = this.reportingmng
                this.input.isactive = 1
                this.input.noofreplacementArray = this.noofreplacementArray
                this.input.noofnewpositions = parseInt(this.noofnewpositions);
                this.input.noofreplacement = parseInt(this.noofreplacement);
                this.input.noofpositions = parseInt(this.noofpositions);
                this.input.hiringlead = this.hiring.lead
                this.input.shortlistteam = this.hiring.shortlisting
                this.input.screenteam = this.hiring.screening
                this.input.schedulingteam = this.hiring.scheduling
                this.input.hiringteam = this.hiring.team
                this.input.interviewteam = this.hiring.interviewteam
                // let hasNullValue = this.hiring.interviewteam.map(member => member === undefined);
                // if(this.roundlist == this.hiring.interviewteam.length && !hasNullValue.includes(true)){
                // this.activeflag = 0
                // }else{
                //     this.issubmit =true;
                //     Swal.fire({
                //         title: "Failed",
                //         text: 'Please Interview team Members!',
                //         icon: 'info',
                //         customClass: {
                //             confirmButton: "btn btn-primary"
                //         },
                //         buttonsStyling: !1
                //     })
                //     this.activeflag = 1
                // }
                if(this.activeflag !== 1 && this.activeflag !== null){                
                 this.apiURL='api/hrms/saveraiserequisition'
                
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                    this.displayblk='none';
                    this.isshowtable=false;
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.jdid =null;
                        this.resetrecords();
                        this.$router.push({ name: 'requisition', params: { search: true }});
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                })
             }
            this.isLoading = false;
            }
        },
        Exportdata(searchalldata){
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                useremail:this.undt.username,
                empcode: this.undt.userid,
            };
            if(searchalldata) {
                this.input.searchalldata = searchalldata.toLowerCase()
            }
            axios({
                method: "POST",
                url: `api/candidate/getCandidateexport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "Candidate_Details";
                    this.download(this.exportxlsdata,filename)
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
                 this.displayError(e)
                })
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                var ShowLabel = filename
                var JSONData = readExportdata
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
                if (ShowLabel) {
                let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#,Candidate Name,Email,Phone Number,Status,Application Date,Created By, Created Date,Appplication Ageing,Calling,Calling Ageing,Shortlisting,Shortlisting Ageing,Screening,Screening Ageing,Resume Review,Resume Review Ageing,Interview Round 1,Interview Round 1 Ageing,Interview Round 2,Interview Round 2 Ageing,Final Approval,Final Approval Ageing,Offer Stage,Offer Stage Ageing' + '\r\n'
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let candidatename = index.hasOwnProperty('candidatename') ? index.candidatename == null || index.candidatename.toLowerCase() == 'na' ? 'NA' : index.candidatename.trim().replace(/\s+/g, ' ') : 'NA';                
                let email = index.hasOwnProperty('email') ? index.email ==null || index.email.toLowerCase() =='na' ? 'NA' : index.email : 'NA';
                 let contactno = index.hasOwnProperty('contactno') ? index.contactno ==null || index.contactno.toLowerCase() =='na' ? '' : index.contactno : '';
                let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? 'NA' : index.status : 'NA';
                let applicationdate = index.hasOwnProperty('applicationdate') ? index.applicationdate ==null || index.applicationdate.toLowerCase() =='na' ? 'NA' : index.applicationdate : 'NA';
                applicationdate = this.dateMonthFormat(applicationdate);
                let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
                let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
                createddate = this.dateMonthFormat(createddate);
                let ageingdays = index.hasOwnProperty('ageingdays') ? index.ageingdays ==null || index.ageingdays == 'na' ? '':index.ageingdays : '';
                let status_calling = index.hasOwnProperty('status_calling') ? index.status_calling ==null || index.status_calling =='na' ? '' : index.status_calling : '';
                let calling_ageing_days = index.hasOwnProperty('calling_ageing_days') ? index.calling_ageing_days ==null || index.calling_ageing_days == 'na' ? '':index.calling_ageing_days : '';
                let status_shortlisting = index.hasOwnProperty('status_shortlisting') ? index.status_shortlisting ==null || index.status_shortlisting.toLowerCase() =='na' ? 'NA' : index.status_shortlisting : 'NA';
                let shortlisting_ageing_days = index.hasOwnProperty('shortlisting_ageing_days') ? index.shortlisting_ageing_days ==null || index.shortlisting_ageing_days == 'na' ? '':index.shortlisting_ageing_days : '';
                let status_screening = index.hasOwnProperty('status_screening') ? index.status_screening ==null || index.status_screening.toLowerCase() =='na' ? 'NA' : index.status_screening : 'NA';
                let screening_ageing_days = index.hasOwnProperty('screening_ageing_days') ? index.screening_ageing_days ==null || index.screening_ageing_days == 'na' ? '':index.screening_ageing_days : '';
                let status_resumereview = index.hasOwnProperty('status_resumereview') ? index.status_resumereview ==null || index.status_resumereview.toLowerCase() =='na' ? 'NA' : index.status_resumereview : 'NA';
                let hrc_resumereview_ageing_days = index.hasOwnProperty('hrc_resumereview_ageing_days') ? index.hrc_resumereview_ageing_days ==null || index.hrc_resumereview_ageing_days == 'na' ? '':index.hrc_resumereview_ageing_days : '';
                let status_round1 = index.hasOwnProperty('status_round1') ? index.status_round1 ==null || index.status_round1.toLowerCase() =='na' ? 'NA' : index.status_round1 : 'NA';
                let hrc_round1_ageing_days = index.hasOwnProperty('hrc_round1_ageing_days') ? index.hrc_round1_ageing_days ==null || index.hrc_round1_ageing_days == 'na' ? '':index.hrc_round1_ageing_days : '';
                let status_round2 = index.hasOwnProperty('status_round2') ? index.status_round2 ==null || index.status_round2.toLowerCase() =='na' ? 'NA' : index.status_round2 : 'NA';
                let hrc_round2_ageing_days = index.hasOwnProperty('hrc_round2_ageing_days') ? index.hrc_round2_ageing_days ==null || index.hrc_round2_ageing_days == 'na' ? '':index.hrc_round2_ageing_days : '';
                let status_finalapprove = index.hasOwnProperty('status_finalapprove') ? index.status_finalapprove ==null || index.status_finalapprove.toLowerCase() =='na' ? 'NA' : index.status_finalapprove : 'NA';
                let final_approval_ageing = index.hasOwnProperty('final_approval_ageing') ? index.final_approval_ageing ==null || index.final_approval_ageing == 'na' ? '':index.final_approval_ageing : '';
                let status_offerstage = index.hasOwnProperty('status_offerstage') ? index.status_offerstage ==null || index.status_offerstage.toLowerCase() =='na' ? 'NA' : index.status_offerstage : 'NA';
                let offer_stage_ageing = index.hasOwnProperty('offer_stage_ageing') ? index.offer_stage_ageing ==null || index.offer_stage_ageing == 'na' ? '':index.offer_stage_ageing : '';

                
            

                    row += '"' + srno + '",',
                    row += '"' + candidatename + '",',
                    row += '"' + email + '",',
                    row += '"' + contactno + '",',
                    row += '"' + status + '",',
                    row += '"' + applicationdate + '",',
                    row += '"' + createdby + '",',
                    row += '"' + createddate + '",',
                    row += '"' + ageingdays + '",',
                    row += '"' + status_calling + '",',
                    row += '"' + calling_ageing_days + '",',
                    row += '"' + status_shortlisting + '",',
                    row += '"' + shortlisting_ageing_days + '",',
                    row += '"' + status_screening + '",',
                    row += '"' + screening_ageing_days + '",',
                    row += '"' + status_resumereview + '",',
                    row += '"' + hrc_resumereview_ageing_days + '",',
                    row += '"' + status_round1 + '",',
                    row += '"' + hrc_round1_ageing_days + '",',
                    row += '"' + status_round2 + '",',
                    row += '"' + hrc_round2_ageing_days + '",',
                    row += '"' + status_finalapprove + '",',
                    row += '"' + final_approval_ageing + '",',
                    row += '"' + status_offerstage + '",',
                    row += '"' + offer_stage_ageing + '",',
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                return;
                }
                var fileName = filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                    link.href = uri
                    link.style = 'visibility:hidden'
                    link.download = fileName + '.csv'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    Swal.fire({
                        title: "Success!",
                        text: "Export Report Successful",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }
        }, 
        validateuploadresumeForm() {
            let isValid=true;
            if (!this.candidatesource) {
                this.errors.jobportalsource = 'Job Source Required';
                isValid=false;
            }
            if (!this.candidateapplicationdate) {
                this.errors.currentDate = 'Current Date Required';
                isValid=false;
            }
            if (!this.candidateeditjobportalname) {
                this.errors.jobportalname = 'Job Portal Required';
                isValid=false;
            }
            if (!this.candidatefnameedit) {
                this.errors.candidatefname = 'Candidate First Name Required';
                isValid=false;
            }
            if (!this.candidatelnameedit) {
                this.errors.candidatelname = 'candidate Last Name Required';
                isValid=false;
            }
            if (!this.candidateeditcontactno) {
                this.errors.candidatecontact = 'Candidate Contact No Required';
                isValid=false;
            }
            if (!this.candidateeditemail) {
                this.errors.candidateemail = 'Candidate Email Required';
                isValid=false;
            }
            if ((!this.resumeupload.attachmentlink)) {
                this.errors.attachmentlink = 'Attachment Required';
                isValid=false;
            }
            return isValid
        },
        isvalidate(isstep){
            let isValid=true;
            if(isstep =='step2'){
                if (!this.companyname) {
                    this.errors.companyname = 'Company required';
                    isValid=false;
                }
                if (!this.department) {
                    this.errors.department = 'Department required';
                    isValid=false;
                }
                if (!this.designation) {
                    this.errors.designation = 'Designation required';
                    isValid=false;
                }
            }
            if(isstep =='step3'){
                if(this.minsalary && (!this.maxsalary || this.maxsalary ==null || this.maxsalary =='')){
                    this.errors.maxsalary = 'Salary Range (Max) required';
                    isValid=false;
                }
                if(this.maxsalary && (!this.minsalary || this.minsalary ==null || this.minsalary =='')){
                    this.errors.minsalary = 'Salary Range (Min) required';
                    isValid=false;
                }
                if((this.minsalary && this.minsalary!=null) && (this.maxsalary && this.maxsalary!=null)){
                isValid = this.validaterage();
                }
                if(this.experiencerangefrom && !this.experiencerangeto){
                    this.errors.experiencerangeto = 'Experience To required';
                    isValid=false;
                }
                if(!this.experiencerangefrom && this.experiencerangeto){
                    this.errors.experiencerangefrom = 'Experience From required';
                    isValid=false;
                }
                if(this.experiencerangefrom && this.experiencerangeto){
                    isValid = this.validateDate();
                }
            }
            if (isstep =='step4' && this.noofreplacement!=null && this.noofreplacementArray.length>0) {
                if(this.isstape4open){
                    this.displayblk ='none';
                    this.isshowtable =true;
                }else{
                    this.displayblk ='';
                    this.isshowtable =false;
                }
                for(let m=0; m<this.noofreplacementArray.length; m++){
                    if (this.noofreplacementArray[m].location==null || this.noofreplacementArray[m].location==undefined) {
                        isValid=false;
                    }
                    if (this.noofreplacementArray[m].replacementfor==null || this.noofreplacementArray[m].replacementfor==undefined) {
                        isValid=false;
                    }
                    if (this.noofreplacementArray[m].emptype==null || this.noofreplacementArray[m].emptype==undefined) {
                        isValid=false;
                    }
                }
                if(isValid ==false){
                    Swal.fire({
                        title: "Failed",
                        text: 'Fill All Replacement Details',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }
            if(isstep =='step5'){
                if (!this.reportingmng) {
                    this.errors.reportingmng = 'Reporting manager required';
                    isValid=false;
                } else {
                    this.errors.interviewteam = ''
                }
            }
            return isValid
        },
        validateForm(){
            let isValid=true;
            if (!this.companyname) {
                this.errors.companyname = 'Company name required';
                isValid=false;
            }
            if (!this.department) {
                this.errors.department = 'Department name required';
                isValid=false;
            }
            if (!this.designation) {
                this.errors.designation = 'Designation name required';
                isValid=false;
            }
            if (!this.reportingmng) {
                this.errors.reportingmng = 'Reporting manager required';
                isValid=false;
            }
            if (this.noofreplacement!=null && this.noofreplacementArray.length>0) {
                for(let m=0; m<this.noofreplacementArray.length; m++){
                    if (this.noofreplacementArray[m].location==null || this.noofreplacementArray[m].location==undefined) {
                        isValid=false;
                    }
                    if (this.noofreplacementArray[m].replacementfor==null || this.noofreplacementArray[m].replacementfor==undefined) {
                        isValid=false;
                    }
                    if (this.noofreplacementArray[m].emptype==null || this.noofreplacementArray[m].emptype==undefined) {
                        isValid=false;
                    }
                }
            }
            if(this.minsalary && (!this.maxsalary || this.maxsalary ==null || this.maxsalary =='')){
                this.errors.maxsalary = 'Salary Range (Max) required';
                isValid=false;
            }
            if(this.maxsalary && (!this.minsalary || this.minsalary ==null || this.minsalary =='')){
                this.errors.minsalary = 'Salary Range (Min) required';
                isValid=false;
            }
            if((this.minsalary && this.minsalary!=null) && (this.maxsalary && this.maxsalary!=null)){
              isValid = this.validaterage();
            }
            if(this.experiencerangefrom && !this.experiencerangeto){
                this.errors.experiencerangeto = 'Experience to required';
                isValid=false;
            }
            if(!this.experiencerangefrom && this.experiencerangeto){
                this.errors.experiencerangefrom = 'Experience from required';
                isValid=false;
            }
            if(this.experiencerangefrom && this.experiencerangeto){
              isValid = this.validateDate();
            }
            if(!this.hiring.lead){
                this.errors.lead = 'Hiring lead required';
                isValid=false;
            }
            if(!this.hiring.apprvalby){
                this.errors.apprvalby = 'Approval by required';
                isValid=false;
            }
            let hasNullValue = this.hiring.interviewteam.map(member => member === undefined);
            if(this.roundlist && this.roundlist == this.hiring.interviewteam.length && !hasNullValue.includes(true)){
                this.activeflag = 0;
                this.issubmit =false;
            }else if(this.roundlist){
                this.issubmit =true;
                isValid=false;
            }
            return isValid
        },
        resetrecords(){
            this.status=null;;
            this.businessunit=null;
            this.companyname=null;
            this.department=null;
            this.designation=null;
            this.experiencerangefrom=null;
            this.experiencerangeto=null;
            this.salarycurrency=null;
            this.minsalary=null;
            this.maxsalary=null;
            this.salaryfimeframe=null;
            this.recruitmentstartdate=null;
            this.rolesresponsibilites=null;
            this.additionalskills=null;
            this.instructions=null;
            this.assetrequirements=null;
            this.reportingmng=null;
            this.noofreplacementArray={};
        },
        positionsselection:function(dsl,isshowtable,isstape) {
            this.displayblk=dsl;
            this.isshowtable=isshowtable;
            if(isstape =='step1'){
                this.displayblk='none';
                this.isshowtable=false;
                this.slestep1();
            }else if(isstape =='step2'){
                this.displayblk='none';
                this.isshowtable=false;
                this.slestep2();
            }else if(isstape =='step3'){
                this.displayblk='';
                this.isshowtable=true;
                this.slestep3();
            }else if(isstape =='step4'){
                this.displayblk='none';
                this.isshowtable=false;
                this.slestep4();
            }else if(isstape =='step5'){
                this.displayblk='none';
                this.isshowtable=false;
                this.slestep5();
            }
        },
        positionsselection: function(dsl, isshowtable, isstape) {
    this.displayblk = dsl;
    this.isshowtable = isshowtable;
    if (isstape === 'step1') {
        this.displayblk = 'none';
        this.isshowtable = false;
        this.slestep1();
    } else if (isstape === 'step2') {
        this.displayblk = 'none';
        this.isshowtable = false;
        this.slestep2();
    } else if (isstape === 'step3') {
        this.displayblk = '';
        this.isshowtable = true;
        this.slestep3();
    } else if (isstape === 'step4') {
        this.displayblk = 'none';
        this.isshowtable = false;
        this.slestep4();
    } else if (isstape === 'step5') {
        this.displayblk = 'none';
        this.isshowtable = false;
        this.slestep5(); // This function should set isstape5open to true
    }
},

        getallcandidatelist(searchalldata,jdid,reqid){
            this.isLoading = true;
            let search = JSON.parse(localStorage.getItem('searchItems'))
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                limit: apiUrl.LIMIT,
                offset:this.pageoffset
            }
            if (searchalldata) {
                this.input.searchalldata = searchalldata.toLowerCase()
            }
            if(jdid && jdid !=null){
                this.input.jdid =jdid;
            }
            if(reqid && reqid !=null){
                this.input.reqid =reqid;
            }
            axios({
                'method': 'POST',
                'url': 'api/candidate/list',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.candidatedetails.allcandidatelist = result.data.data.rows
                    this.totalcount = parseInt(result.data.data.count);
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.candidatedetails.allcandidatelist= [];
                    this.totalcount = 0;
                    this.pageCount = 0;
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        callbacktable: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getallcandidatelist();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#candidatetable").offset().top -70
                });
            }, 500);
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('YYYY-MM-DD');
        },
        resetcandidatedeatils(){
            this.candidatedetails.searchalldata = null;
            this.isjdcode =null;
            this.isjdid =null;
            this.getallcandidatelist();
        },
        redirectpage:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate=="viewpage"){
                this.$router.push({
                    path: `/hrms/processongoing?candidateid=${parseInt(rowdata.hcid)}`,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                });
            }
        },
        onZoom(e){
        this.getLogtimeDownloadLink(e, 'zoom');
        },
        inZoom(e) {
            this.getDownloadedzoom(e, 'zoom');
        },
        getLogtimeDownloadLink(data, curr=null) {
            if(data) {
                var input =[]
                data=data.trim();
                input.push({link:data,empcode: this.undt.userid,useremail: this.undt.username})
                let payload = {
                documents:input,
                empcode: this.undt.userid,
                useremail: this.undt.username
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    if(result.data.status) {
                        if(result.data.data[0].presignurl){
                            if(curr=='zoom'){
                                $('.imagepreview').attr('src', result.data.data[0].presignurl);
                                $('#imagemodal').modal('show');  
                                return 
                            }
                            window.location.href=result.data.data[0].presignurl
                        }
                    }
                }).catch(e => {
                 this.displayError(e)
                });
            }
        },
        seteditreqid(candidate){
            this.editid = candidate.hcid;
            this.jsid = candidate.source;
            this.jobportalsourceedit= parseInt(candidate.source);
            this.candidateFnamesource = candidate.candidatefname.trim();
            this.candidateLnamesource = candidate.candidatelname.trim();
            this.candidatereqidsource = parseInt(candidate.reqid);
            this.candidatestatussource = candidate.status.trim();
        },
        candidateeditreqid(candidate,clickstage){
            this.candidateeditjobportalname = parseInt(candidate.jpmid.trim());
            this.candidateeditemail = candidate.email.trim();
            this.candidateeditcontactno= candidate.contactno.trim();
            this.candidateeditid = parseInt(candidate.hcid.trim());
            this.candidatefnameedit = candidate.candidatefname.trim();
            this.candidatelnameedit = candidate.candidatelname.trim();
            this.candidatesource = parseInt(candidate.source.trim());
            this.candidateeditereqid = parseInt(candidate.reqid);
            this.candidateeditstatus = candidate.status.trim();
            let cvfile =''
            if(candidate.cv){
                const parsecv = JSON.parse(candidate.cv);
                for(let i=0; i<parsecv.data.length; i++){
                    if(parsecv.data[i+1]){
                        cvfile += parsecv.data[i].link+',';
                    }else{
                        cvfile += parsecv.data[i].link;
                    }
                }
            }
            this.newfile ='';
            this.candidatecv = cvfile;
            this.resumeupload.attachmentlink = candidate.cv;
            if(!this.candidatecv){
                this.resumeupload.disblesavebtn =true;
            }
            this.candidateapplicationdate = candidate.applicationdate.trim();  
            if (clickstage && clickstage == 'View Application Log') {
                this.getcandidateapplicationlogList(candidate.hcid,candidate.reqid);
            }  
        },
        getJobsource() {
            let url = 'api/hrms/getjobjobsourcelist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.jobportalsourcelist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.jobportalsourcelist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getcandidateapplicationlogList(hcid,reqid){
            this.isLoading = true
            this.input={
                useremail  : this.undt.username,
                empcode: parseInt(this.undt.userid),
                candidateid: parseInt(hcid.trim()),
                reqid: parseInt(reqid.trim())
            }
            axios({
                'method': 'POST',
                'url': 'api/candidate/getcandidateapplicationlogList',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.candidatedetails.applicationloglist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.candidatedetails.applicationloglist= []
                }
            }).catch(e => {
                 this.displayError(e)
                })
        },
        extractNameFromEmail(email) {
            const nameRegex = /^([^@]*)@/;
            const matches = email.match(nameRegex);
            if (matches && matches.length > 1) {
                return matches[1];
            }
            return email;
        },
        validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validateDate() {
            let tempvalid = true;
            if (this.experiencerangefrom && (parseInt(this.experiencerangeto) < parseInt(this.experiencerangefrom)) && this.experiencerangefrom !=null) {
                tempvalid = false;
                this.experiencerangeto = null;
                this.errors.experiencerangeto = "Experience To should be greater than Experience From";
            }else{
                this.errors.experiencerangeto =null;
            }
            if (this.experiencerangefrom && (parseInt(this.experiencerangeto) < parseInt(this.experiencerangefrom)) && this.experiencerangeto !=null) {
                tempvalid = false;
                this.experiencerangefrom = null;
                this.errors.experiencerangefrom = "Experience From should be lesser than Experience To";
            }else{
                this.errors.experiencerangefrom = null;
            }
            return tempvalid;
        },
        validaterage() {
            let tempvalid = true;
            if (this.maxsalary && (parseInt(this.maxsalary) < parseInt(this.minsalary))) {
                tempvalid = false;
                this.maxsalary = null;
                this.errors.maxsalary = "Salary Range (Max) should be greater than Salary Range (Min)";
            }else{
                this.errors.maxsalary =null;
            }
            if (this.maxsalary && this.minsalary && (parseInt(this.maxsalary) < parseInt(this.minsalary))) {
                tempvalid = false;
                this.minsalary = null;
                this.errors.minsalary = "Salary Range (Min) should be lesser than Salary Range (Max)";
            }else{
                this.errors.minsalary = null;
            }
            return tempvalid;
        },
        selectcompany(state){
            this.companyname =null;
            this.companyname =state;
            if(state && state !=null){
                this.errors.companyname ='';
            }
        },
        selectdepartment(state){
            this.department =null;
            this.department =state;
            if(state && state !=null){
                this.errors.department ='';
                this.getDesignationdata(state);
            }
        },
        selectdesignation(state){
            this.designation =null;
            this.designation =state;
            if(state && state !=null){
                this.errors.designation ='';
            }
        },
        selectrmanager(state){
            this.reportingmng =null;
            this.reportingmng =state;
            if(state && state !=null){
                this.errors.reportingmng ='';
            }
        },
        selectexpfrom(state){
            this.experiencerangefrom =null;
            this.experiencerangefrom =state;
            if(state && state !=null){
                this.errors.experiencerangefrom ='';
            }
        },
        selectexpto(state){
            this.experiencerangeto =null;
            this.experiencerangeto =state;
            if(state && state !=null){
                this.errors.experiencerangeto ='';
            }
        },
        selectcandidatelist(){
            this.israiserequisition =false;
            this.iscandidatelist =true;
            this.candodatelista = true;
            this.raiserequistiona =false;
            this.requistionlista =false;
            this.getallcandidatelist();
        },
        selectrequisition(){
            this.iscandidatelist =false;
            this.israiserequisition =true;
            this.dspblk ='block';
            this.isstape1open =true;
            this.raiserequistiona =true;
            this.candodatelista = false;
            this.requistionlista =false;
        },
        slestep1(){
            let isvalid =true;
            isvalid = this.isvalidate('step1');
            if(isvalid){
                this.isstape1open =true;
                this.isstape2open =false;
                this.isstape3open =false;
                this.isstape4open =false;
                this.isstape5open =false;
            }
        },
        slestep2(){
            let isvalid =true;
            isvalid = this.isvalidate('step2');
            if(isvalid){
                this.isstape1open =false;
                this.isstape2open =true;
                this.isstape3open =false;
                this.isstape4open =false;
                this.isstape5open =false;
            }
        },
        slestep3(){
            let isvalid =true;
            isvalid = this.isvalidate('step3');
            if(isvalid){
                this.isstape1open =false;
                this.isstape2open =false;
                this.isstape3open =true;
                this.isstape4open =false;
                this.isstape5open =false;
            }
        },
        slestep4(){
            let isvalid =true;
            isvalid = this.isvalidate('step4');
            if(isvalid){
                this.isstape1open =false;
                this.isstape2open =false;
                this.isstape3open =false;
                this.isstape4open =true;
                this.isstape5open =false;
                this.displayblk ='none';
                this.isshowtable = false;
            }
        },
        slestep5(){
            let isvalid =true;
            isvalid = this.isvalidate('step5');
            if(isvalid){
                this.isstape1open =false;
                this.isstape2open =false;
                this.isstape3open =false;
                this.isstape4open =false;
                this.isstape5open =true;
                this.displayblk ='none';
                this.isshowtable = false;
            }
        },

        redirecttourl:function(btnstate){
            if(btnstate=="raiserequisition"){
                this.$router.push({ 
                    path: '/hrms/requisition',
                    params: {
                        backbtnstate:btnstate
                    }
                })
            }
        },
        validNumber: (event) => {
            let keyCode = event.keyCode;
            if (keyCode < 48 || keyCode > 57) {
                event.preventDefault();
            }
        },
        selsource(state){
            if(state){
                this.errors.jobportalsource =null;
            }
        },
        selportal(state){
            if(state){
                this.errors.jobportalname =null;
            }
        },
        selcurrdate(state){
            if(state){
                this.errors.currentDate =null;
            }
        },
        selcandidatefname(state){
            if(state){
                this.errors.candidatefname =null;
            }
        },
        selcandidatelname(state){
            if(state){
                this.errors.candidatelname =null;
            }
        },
        selcontactno(state){
            if(state){
                this.errors.candidatecontact =null;
            }
        },
        selcandidateemail(state){
            if(state){
                this.errors.candidateemail =null;
            }
        },
        selectlead(state){
            if(state){
                this.errors.lead =null;
            }
        },
        selectapprvalby(state){
            if(state){
                this.errors.apprvalby =null;
            }
        },
        notBeforeTodaydate: function (date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        showmappingname(ds){
            let responsestr='';
            let prlist=ds.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'.'+prlist[i]+' '
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        getjddetailsbyid(jdid) {
            if(jdid && jdid !=null){
                this.isLoading = true;
                let url = "api/hrms/getjddetailsbyid";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    jdid: jdid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    headers: { authorization: this.tokendata },
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.reqjddata = result.data.data && result.data.data[0] ? result.data.data[0] :null;
                        if(this.reqjddata){
                            this.companyname =this.reqjddata.companyid;
                            this.department =this.reqjddata.departmentid;
                            this.designation =this.reqjddata.designationid;
                            this.experiencerangefrom =this.reqjddata.expyear;
                            this.experiencerangeto =this.reqjddata.expmonth;
                            this.isdetailsdesabled =true
                        }
                    }
                }).catch((e) => {
                    this.displayError(e);
                });
            }
        },
    }
};    
</script>