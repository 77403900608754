<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1 mt-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left  pad_top_mar_bot-5">
           <div class="container-fluid mb-1">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left pl-0 ">
                 <h3 class="content-header-title float-left mb-0">Business Task Weekly Work Status</h3>
                 <div class="breadcrumb-wrapper">
                   <ol class="breadcrumb">
                     <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/reports/btcweeklyworkstatuscount'>Business Task Weekly Work Status</router-link></li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
           <div class="card border-warning filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center ">
                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                            <div class="avatar-content">
                                <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                                </search-icon>
                            </div>
                        </div>
                         <h4 class="card-title d-inline-flex ml-1">Search Business Task Weekly Work Status</h4>
                    </div>
                    <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                  </div>
                </div>
              </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="form-group col-sm-12 col-md-6 col-lg-3 mb-1">
                        <label class="form-label" for="roles">Business Task Tracker</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Role" :options="managersList" v-model="search.roleId" @input="getBSHlist" v-if="pagefeatures.hodroleproject" :disabled="true"/>
                        <treeselect class="projroleassprojectdd" placeholder="Select Role" :options="managersList" v-model="search.roleId" @input="getBSHlist" v-else/>
                    </div>
                    <div v-if="search.roleId=='Department'" class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Department</label>
                      <treeselect class="projectmdllistdd" placeholder="Select Department" v-model="search.searchDepartment" :options="deptlist" :multiple="true" :clear-on-select="true"/>
                    </div>
                    <div v-else class="form-group col-sm 12 col-md-6 col-lg-3 mb-1">
                        <label class="form-label" for="bsh">Stakeholder Name</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Stakeholder Name " :multiple="true" :clear-on-select="true" :options="bshlist" v-model="search.bsh" />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">BTC Type</label>
                        <treeselect class="projectmdllistdd" placeholder="Select BTC Type" v-model="search.btctype" :options="btctypelist"/>
                    </div>


                    <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getBTCWeeklyWorkStatusCount()"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
         <div class="card border-warning" id="domain-list">
           <div class="card-body p-0 ps ps--active-x">
             <div class="table-responsive" >
               <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc" style="border-collapse:collapse;">
                 <thead>
                    <tr>
                       <th scope="col" class="align-middle text-center first-col-sticky make-bold" style="min-width:140px;">Year/Week/Dept</th>
                       <th scope="col" class="align-middle text-center">PRODUCT PIPELINE</th>
                       <th scope="col" class="align-middle text-center">PRODUCT WIP</th>
                       <th scope="col" class="align-middle text-center">PRODUCT COMPLETE</th>
                       <th scope="col" class="align-middle text-center">TECH AND BUSINESS REVIEW</th>
                       <th scope="col" class="align-middle text-center">TECH PIPELINE</th>
                       <th scope="col" class="align-middle text-center">TECH WIP</th>
                       <th scope="col" class="align-middle text-center">TECH ISSUE RESOLUTION</th>
                       <th scope="col" class="align-middle text-center">QA PIPELINE</th>
                       <th scope="col" class="align-middle text-center">QA WIP</th>  
                       <th scope="col" class="align-middle text-center">UAT</th>
                       <th scope="col" class="align-middle text-center">UAT SIGNOFF</th>
                       <th scope="col" class="align-middle text-center">UAT FEEDBACK</th>
                       <th scope="col" class="align-middle text-center">COMPLETED Live</th>
                       <th scope="col" class="align-middle text-center">DISCARDED</th>
                       <th scope="col" class="align-middle text-center">ON HOLD</th>
                       <th scope="col" class="align-middle text-center">Other Status</th>
                       <th scope="col" class="align-middle text-center">TOTAL COUNT</th>
                     </tr>
                 </thead>
                 <tbody>
                   <template v-for="(data,index) in yearwise"  >
                     <tr v-bind:key="index+data.btyear" data-toggle="collapse" v-bind:data-target="'.demo'+data.btyear" style="font-weight: 600">
                       <td class="align-middle text-center font-weight-normal first-col-sticky sitebluecolor make-bold" style="cursor:pointer" @click="getweekwise(data.btyear)">{{data.btyear}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.productpipeline}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.productwip}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.productcompleted}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.techandbusinessreview}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.devpipeline}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.techwip}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.techissueresolution}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.qapipeline}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.qawip}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.uat}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.uatsignoff}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.uatfeedback}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.completedlive}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.discarded}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.onhold}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.otherstatus}}</td>
                       <td class="align-middle text-center font-weight-normal">{{data.count}}</td>
                     </tr>
                     <!-- {{clickedYear == data.btyear}} -->
                       <template v-for="(data2,index2) in weekwise">
                         <!-- {{data2}} -->
                         <tr v-if="clickedYear == data.btyear" v-bind:key="'.demo'+index2+data.btyear+data2.btweek" data-toggle="collapse" :class="'collapse demo'+data.btyear" v-bind:data-target="'.demo'+data.btyear+data2.btweek">
                           <td class="hiddenRow align-middle text-center first-col-sticky make-bold" >
                               <div :class="'collapse sitebluecolor demo'+data.btyear"  style="cursor:pointer" @click="getdeptwise(data2.btyear, data2.btweek)" >{{data2.btweek}} : {{data2.btdates}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.productpipeline, 'productpipeline')">
                               <div :class="'collapse demo'+data.btyear">{{data2.productpipeline}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.productwip, 'productwip')">
                               <div :class="'collapse demo'+data.btyear">{{data2.productwip}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.productcompleted, 'productcompleted')">
                               <div :class="'collapse demo'+data.btyear">{{data2.productcompleted}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.techandbusinessreview, 'techandbusinessreview')">
                               <div :class="'collapse demo'+data.btyear">{{data2.techandbusinessreview}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.devpipeline, 'devpipeline')">
                               <div :class="'collapse demo'+data.btyear">{{data2.devpipeline}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.techwip, 'techwip')">
                               <div :class="'collapse demo'+data.btyear">{{data2.techwip}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.techissueresolution, 'techissueresolution')">
                               <div :class="'collapse demo'+data.btyear">{{data2.techissueresolution}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.qapipeline, 'qapipeline')">
                               <div :class="'collapse demo'+data.btyear">{{data2.qapipeline}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.qawip, 'qawip')">
                               <div :class="'collapse demo'+data.btyear">{{data2.qawip}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.uat, 'uat')">
                               <div :class="'collapse demo'+data.btyear">{{data2.uat}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.uatsignoff, 'uatsignoff')">
                               <div :class="'collapse demo'+data.btyear">{{data2.uatsignoff}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.uatfeedback, 'uatfeedback')">
                               <div :class="'collapse demo'+data.btyear">{{data2.uatfeedback}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.completedlive, 'completedlive')">
                               <div :class="'collapse demo'+data.btyear">{{data2.completedlive}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.discarded, 'discarded')">
                               <div :class="'collapse demo'+data.btyear">{{data2.discarded}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.onhold, 'onhold')">
                               <div :class="'collapse demo'+data.btyear">{{data2.onhold}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.completedlive, 'completedlive')">
                               <div :class="'collapse demo'+data.btyear">{{data2.otherstatus}}</div>
                           </td>
                           <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.count, 'count')">
                               <div :class="'collapse demo'+data.btyear">{{data2.count}}</div>
                           </td>
                         </tr>
                         <!-- {{clickedWeek == data.btweek}}{{clickedWeek + 'a'}} {{data2.btweek + 'b'}} -->
                           <template v-for="(data3,index3) in deptwise" >
                             <tr v-if="clickedYear == data.btyear && clickedWeek == data2.btweek" v-bind:key="'.demo'+index2+index3" :class="'collapse demo'+data3.btyear+data3.btweek">
                               <td class="hiddenRow align-middle text-center first-col-sticky make-bold">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.department}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.productpipeline}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.productwip}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.productcompleted}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.techandbusinessreview}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.devpipeline}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.techwip}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.techissueresolution}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.qapipeline}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.qawip}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.uat}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.uatsignoff}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.uatfeedback}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.completedlive}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.discarded}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.onhold}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.otherstatus}}</div>
                               </td>
                               <td class="hiddenRow align-middle text-center">
                                   <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.count}}</div>
                               </td>
                             </tr>
                           </template>
                       </template>
                   </template>
                   <template v-if="btcweeklyworkstatusList.length==0">
                     <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                   </template>
                 </tbody>
               </table>
             </div>
           </div>
         </div>
       </section>
     </div>
   </div>
 </template>
 
 <script>
 $('.collapse').on('show.bs.collapse', function () {
     $('.collapse.in').collapse('hide');
 });
 import axios from 'axios'
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Loading from 'vue-loading-overlay';
 import Treeselect from '@riophae/vue-treeselect'
 import DatePicker from "vue2-datepicker";
 import 'vue-loading-overlay/dist/vue-loading.css'
 import "vue2-datepicker/index.css";
 import '@riophae/vue-treeselect/dist/vue-treeselect.css'
 import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon } from "vue-feather-icons";
 import commonMethods from '../../utils/commonMethods';

 
 export default {
   name: 'btcweeklyworkstatuscount',
   components: {
     VueElementLoading,
     Loading,
     Treeselect,
     DatePicker,
     PlusCircleIcon,
     EditIcon,
     CheckCircleIcon,
     XCircleIcon,
     SearchIcon,
     ExternalLinkIcon,
     XIcon,
   },
   mixins: [ commonMethods ],

   data() {
     return {
       btcweeklyworkstatusList: [],
       yearList: ['2024', '2023', '2022', '2021', '2020'],
       yearwise: [],
       weekwise: [],
       deptwise: [],
       bshlist : [],
       pmlist: [],
       emlist:[],
       taskmanager: [],
       taskownerlist: [],
       clickedYear: null,
       clickedWeek: null,
       search: {
        searchDepartment:null,
        bsh:null,
        roleId: 'Department',
        taskownername: null,
        taskmngr: null,
        engman: null,
        proman: null,
        btctype : 'Parent BTC'
       },
       errors: {
       },
       isactive: true,
       isSearchBoxActive: false,
       card_content:'card-content',
       collapse:'collapse',
       isLoading: false,
       undt: null,
       tokendata: null,
       totalcount: 0,
       pageCount: 0,
       page:1,
       pagelimit:apiUrl.LIMIT,
       deptlist:apiUrl.deptlist,
       currPageNum: 0,
       pageoffset:1,
       pagefeatures:[],
       TimeFormat: apiUrl.TimeFormat,
       isSearchRequestActive:false,
       managersList: [{ 
          id: 'hod',
          label: 'HOD',
        },
        {
          id : 477,
          label: 'Project Manager',
        },
        {
          id : 492,
          label: 'Engineering Manager',
        },
        {
          id : 131,
          label: 'Product Stack Holder',
        },
        {
          id : 513,
          label: 'Task Owner',
        },
        {
          id : 'Department',
          label: 'Department',
        }
        ],
        bshlist:[],
        btctypelist:[
        {
            id:'Parent BTC',
            label:'Parent BTC'
        },
        {
            id:'Sub BTC',
            label:'Sub BTC'
        },
      ],

     }
   },
   mounted() {
     let permission = this.$Service.verifyAccess();
     if(permission){
       let userdata = window.localStorage.getItem('userdata');
       if(userdata) {
         userdata = JSON.parse(userdata)
         this.undt = userdata
         this.tokendata = window.localStorage.getItem('token');
         this.getBTCWeeklyWorkStatusCount()
         this.getBSHlist(null);
         this.managersList.sort((a, b) => a.label.localeCompare(b.label));
        //  this.getProjectManagerDetails(477)
        //  this.getProjectManagerDetails(492)
        //  this.getTaskManagerOwnerList(131)
        //  this.getTaskManagerOwnerList(513)
       }
     }
     $(".filter_rotation_onlick").click(function(){
       $(this).toggleClass("common_arrow_rotetion");
     });
   },
   methods: {
     issmaller(year, week, currval, key) {
       let prevweekdata = this.weekwise.filter(i => i.btyear == year && i.btweek == parseInt(week) - 1)
       if(prevweekdata && prevweekdata.length) {
         let prevval = prevweekdata[0][key]
         if(parseInt(prevval) > parseInt(currval)) {
           return 'red'
         } else if(parseInt(prevval) < parseInt(currval)) {
           return 'green'
         }
       } else {
         return 'green'
       }
       return 'orange'
     },
     mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    resetRecords: function () {
      let rowdata={}
      this.search.roleId = null
      this.search.bsh=null
      this.search.engman = null
      this.search.proman = null
      this.search.taskownername = null
      this.search.taskmngr = null
      this.search.searchDepartment = null
      this.search.btctype = null
      this.getBTCWeeklyWorkStatusCount()
    },
    getBSHlist(dept) {
      if(dept==null || dept=='hod')
      {
        this.search.bsh=null
        axios({
            method: "POST",
            url: "api/listing/getHODlist",
            data: {useremail: this.undt.username,empcode: this.undt.userid},
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.bshlist = result.data.data.rows
            } else {
                this.bshlist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
      }
      else{
        this.search.bsh=null
          axios({
            method: "POST",
            url: "api/listing/getStakeHoldersList",
            data: { roleid: dept,useremail: this.undt.username,empcode: this.undt.userid },
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.bshlist = result.data.data;
            } else {
              this.bshlist = [];
            }
          }).catch(e => {
        this.displayError(e)
      });
      }
    },
        getProjectManagerDetails(roleid) {
            axios({
                method: "POST",
                url: "api/listing/getProjectManagerDetails",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,empcode: this.undt.userid,useremail: this.undt.username}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==477){
                        this.pmlist = result.data.data.rows
                    }else if(roleid==492){
                        this.emlist = result.data.data.rows
                    }
                }else{
                    if(roleid==477){
                        this.pmlist = [];
                    }else if(roleid==492){
                        this.emlist = [];
                    }
                }
            }).catch(e => {
          this.displayError(e)
        });
        },
        getTaskManagerOwnerList(roleid){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==131){
                        this.taskmanager=result.data.data.rows;
                    }else if (roleid==513){
                        this.taskownerlist=result.data.data.rows;
                    }
                }else{
                    if(roleid==131){
                        this.taskmanager=[]
                    }else if (roleid==513){
                        this.taskownerlist=[];
                    }
                }
            }).catch(e => {
          this.displayError(e)
        })
        },

     getdeptwise(year, week) {
       this.clickedWeek = week
       let weekdata = this.btcweeklyworkstatusList.filter(i => i.btyear == year && i.btweek == week)
       let deptwise = []
       let distinctDept = []
       for(let data of weekdata) {
         let department = data.department
         let currDeptData = weekdata.filter(i => i.btyear == year && i.btweek == week && i.department == department)
         if(!distinctDept.includes(department)) {
           distinctDept.push(department)
           deptwise.push({
             "btyear": year,
             "btweek": week,
             "department": department ? department : 'NA',
             "productpipeline": currDeptData.reduce((total, currVal) => total + parseInt(currVal.productpipeline), 0),
             "productwip": currDeptData.reduce((total, currVal) => total + parseInt(currVal.productwip), 0),
             "productcompleted": currDeptData.reduce((total, currVal) => total + parseInt(currVal.productcompleted), 0),
             "techandbusinessreview": currDeptData.reduce((total, currVal) => total + parseInt(currVal.techandbusinessreview), 0),
             "onhold": currDeptData.reduce((total, currVal) => total + parseInt(currVal.onhold), 0),
             "discarded": currDeptData.reduce((total, currVal) => total + parseInt(currVal.discarded), 0),
             "devpipeline": currDeptData.reduce((total, currVal) => total + parseInt(currVal.devpipeline), 0),
             "techwip": currDeptData.reduce((total, currVal) => total + parseInt(currVal.techwip), 0),
             "qawip": currDeptData.reduce((total, currVal) => total + parseInt(currVal.qawip), 0),
             "qapipeline": currDeptData.reduce((total, currVal) => total + parseInt(currVal.qapipeline), 0),
             "uat": currDeptData.reduce((total, currVal) => total + parseInt(currVal.uat), 0),
             "uatsignoff": currDeptData.reduce((total, currVal) => total + parseInt(currVal.uatsignoff), 0),
             "uatfeedback": currDeptData.reduce((total, currVal) => total + parseInt(currVal.uatfeedback), 0),
             "completedlive": currDeptData.reduce((total, currVal) => total + parseInt(currVal.completedlive), 0),
             "otherstatus": currDeptData.reduce((total, currVal) => total + parseInt(currVal.otherstatus), 0),
             "techissueresolution": currDeptData.reduce((total, currVal) => total + parseInt(currVal.techissueresolution), 0),
             "count": currDeptData.reduce((total, currVal) => total + parseInt(currVal.count), 0)
           })
         }
       }
       this.deptwise = deptwise
     },
     getweekwise(year) {
       this.clickedYear = year
       this.clickedWeek = null
       let yearData = this.btcweeklyworkstatusList.filter(i => i.btyear == year)
       let weekwise = []
       let distinctWeeks = []
       for(let weekdata of yearData) {
         let btweek = weekdata.btweek
         let currWeekData = yearData.filter(i => i.btyear == year && i.btweek == btweek)
         if(!distinctWeeks.includes(btweek)) {
           distinctWeeks.push(btweek)
           weekwise.push({
             "btyear": year,
             "btweek": btweek,
             "btdates":weekdata.btweeks,
             "productpipeline": currWeekData.reduce((total, currVal) => total + parseInt(currVal.productpipeline), 0),
             "productwip": currWeekData.reduce((total, currVal) => total + parseInt(currVal.productwip), 0),
             "productcompleted": currWeekData.reduce((total, currVal) => total + parseInt(currVal.productcompleted), 0),
             "techandbusinessreview": currWeekData.reduce((total, currVal) => total + parseInt(currVal.techandbusinessreview), 0),
             "onhold": currWeekData.reduce((total, currVal) => total + parseInt(currVal.onhold), 0),
             "discarded": currWeekData.reduce((total, currVal) => total + parseInt(currVal.discarded), 0),
             "devpipeline": currWeekData.reduce((total, currVal) => total + parseInt(currVal.devpipeline), 0),
             "techwip": currWeekData.reduce((total, currVal) => total + parseInt(currVal.techwip), 0),
             "qawip": currWeekData.reduce((total, currVal) => total + parseInt(currVal.qawip), 0),
             "qapipeline": currWeekData.reduce((total, currVal) => total + parseInt(currVal.qapipeline), 0),
             "uat": currWeekData.reduce((total, currVal) => total + parseInt(currVal.uat), 0),
             "uatsignoff": currWeekData.reduce((total, currVal) => total + parseInt(currVal.uatsignoff), 0),
             "uatfeedback": currWeekData.reduce((total, currVal) => total + parseInt(currVal.uatfeedback), 0),
             "completedlive": currWeekData.reduce((total, currVal) => total + parseInt(currVal.completedlive), 0),
             "otherstatus": currWeekData.reduce((total, currVal) => total + parseInt(currVal.otherstatus), 0),
             "techissueresolution": currWeekData.reduce((total, currVal) => total + parseInt(currVal.techissueresolution), 0),
             "count": currWeekData.reduce((total, currVal) => total + parseInt(currVal.count), 0)
           })
         }
       }
       this.weekwise = weekwise
     },
     getBTCWeeklyWorkStatusCount(search) {
      this.clickedYear = null
      this.clickedWeek = null
       this.isLoading = true
       let url = 'api/reports/btcweeklyworkstatuscount'
       let payload = {
         useremail: this.undt.username,
         empcode: this.undt.userid,
         dept: this.search.searchDepartment,
         bsh: this.search.bsh?this.search.bsh:null,
         tracker: this.search.roleId?String(this.search.roleId): 'Department',
         btctype: this.search.btctype
       }
       axios({
         method: 'POST',
         url,
         data: payload,
         headers:{ authorization :this.tokendata }
       }).then(result => {
         this.isLoading = false
         if(result.data.status) {
           let d = result.data.data.rows
           let ys = result.data.data.yearList
           this.btcweeklyworkstatusList = d
           this.yearList = ys.map(i => i.date_trunc)
           let yearwise = []
           for(let year of this.yearList) {
             let yearData = this.btcweeklyworkstatusList.filter(i => i.btyear == year)
             let obj = {
               "btyear": year,
               "productpipeline": yearData.reduce((total, currVal) => total + parseInt(currVal.productpipeline), 0),
               "productwip": yearData.reduce((total, currVal) => total + parseInt(currVal.productwip), 0),
               "productcompleted": yearData.reduce((total, currVal) => total + parseInt(currVal.productcompleted), 0),
               "techandbusinessreview": yearData.reduce((total, currVal) => total + parseInt(currVal.techandbusinessreview), 0),
               "onhold": yearData.reduce((total, currVal) => total + parseInt(currVal.onhold), 0),
               "discarded": yearData.reduce((total, currVal) => total + parseInt(currVal.discarded), 0),
               "devpipeline": yearData.reduce((total, currVal) => total + parseInt(currVal.devpipeline), 0),
               "techwip": yearData.reduce((total, currVal) => total + parseInt(currVal.techwip), 0),
               "qawip": yearData.reduce((total, currVal) => total + parseInt(currVal.qawip), 0),
               "qapipeline": yearData.reduce((total, currVal) => total + parseInt(currVal.qapipeline), 0),
               "uat": yearData.reduce((total, currVal) => total + parseInt(currVal.uat), 0),
               "uatsignoff": yearData.reduce((total, currVal) => total + parseInt(currVal.uatsignoff), 0),
               "uatfeedback": yearData.reduce((total, currVal) => total + parseInt(currVal.uatfeedback), 0),
               "completedlive": yearData.reduce((total, currVal) => total + parseInt(currVal.completedlive), 0),
               "otherstatus": yearData.reduce((total, currVal) => total + parseInt(currVal.otherstatus), 0),
               "techissueresolution": yearData.reduce((total, currVal) => total + parseInt(currVal.techissueresolution), 0),
               "count": yearData.reduce((total, currVal) => total + parseInt(currVal.count), 0),
             }
             yearwise.push(obj)
             this.yearwise = yearwise
           }
         } else {
           this.btcweeklyworkstatusList = []
         }
       }).catch(e => {
         this.isLoading = false
         Swal.fire({
           title: "",
           text: e,
           icon: "info",
           customClass: { confirmButton: "btn btn-primary" },
           buttonsStyling: !1,
         });
       }) 
     },

     searchBoxCollapse() {
       this.isSearchBoxActive = !this.isSearchBoxActive
     },
   }
 }
 </script>
 <style scoped>
   .box-inc {
     min-width: 200px;
   }
   .mark-red {
     color: red;
   }
   .mark-green {
     color: green;
   }
   .mark-orange {
     color: orangered;
   }
   .make-bold {
     font-weight: 600
   }
 </style>