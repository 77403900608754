<template>
  <div class="app-content content">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard" class="pb-1">
        <div class="content-header-left mb-1  pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left px-0">
                <h3 class="content-header-title float-left mb-0">Incident Management Details</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/incident/list'>Incident Management List</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="card border-warning filter_card_searchbgst">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="w-auto px-1 mobile-padding-0-2">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                          <search-icon
                          size="1.5x"
                          class="custom-class avatar-icon font-medium-3"
                          ></search-icon>
                      </div>
                      </div>
                  <h4 class="card-title d-inline-flex ml-1">Search Incident Details</h4>
              </div>
              <div class="ml-auto d-flex pr-1">
              <div class=" px-1 mobile-padding-0-2">
                <div class="input-group-append float-right">
                  <router-link to="/incident/create" class="btn-sm btn-relief-success"><span id="commonaddmodule" class=""   data-backdrop="static" data-keyboard="false"> <span class="mobile_hide_bt"> Create Incident</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></span></router-link>
                </div>
              </div>
              <span class="rotetions_view_arrow"> 
                <em class="las la-angle-down"></em>
              </span>
              </div>
            </div>
            </div>
          </div>
          <div
            v-bind:class="[
              { show: isSearchRequestActive },
              card_content,
              collapse,
            ]"
          >
            <div class="card-body px-sm-mobile-10">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                       <label class="form-label lable-left">Incident Code</label>
                      <input type="text" class="form-control" placeholder="Enter Incident code" v-model="search.incidentcode"  v-on:search-change="incidentCodeChangeSearch" @input="clearCodeList" :maxlength="maxLength"/>
                 </div>
                 <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <label for="project">Project Name</label>
                    <treeselect
                      class="projectmdllistdd capitalisetext"
                      v-model="search.projectnames"
                      placeholder="Enter min 4 chars to search project name"
                      :multiple="true" :clear-on-select="true"
                      :options="projectnamelist"
                      v-on:search-change="projectOnSearch"
                    />
                  </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Project Module Name</label>
                  <treeselect
                    class="projectmdllistdd"
                    placeholder="Enter min 4 chars to search Project module Name"
                    v-on:search-change="projectChange"
                    v-model="search.searchprojectname"
                     :multiple="true" :clear-on-select="true"
                    :options="search.projectnameopts"
                    @input="selectproject"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Functionality</label>
                  <treeselect
                    class="projectmdllistdd"
                    placeholder="Select Functionality"
                    v-model="search.searchFunctionality"
                    :options="search.functionalityopts"
                    @input="selectfunctionality"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Task</label>
                  <treeselect
                    class="projectmdllistdd"
                    placeholder="Select Task"
                    v-model="search.searchTask"
                    :options="search.taskopts"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Incident Name</label>
                  <treeselect
                    class="projectmdllistdd capitalisetext"
                    placeholder="Enter min 4 chars to search incident name"
                    v-on:search-change="incidentnmChange"
                    v-model="search.searchIncidentname"
                    :options="search.incidentopts"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Severity</label>
                  <treeselect
                    class="projectmdllistdd"
                    placeholder="Select Severity"
                    v-model="search.searchSeverity"
                    :options="search.severityopts"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Priority</label>
                  <treeselect
                    class="projectmdllistdd"
                    placeholder="Select Priority"
                    v-model="search.searchPriority"
                    :options="search.priorityopts"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Incident Type</label>
                  <treeselect
                    class="projectmdllistdd"
                    placeholder="Select Incident Type"
                    v-model="search.searchincidenttype"
                    :options="incidenttypelist"
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Created-By</label>
                  <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search Created-By" v-model="search.createdby" :multiple="true" :clear-on-select="true" :options="search.projectcreatedbyopts" 
                  v-on:search-change="createduser" v-if="pagefeatures.createdassigned"/>
                  <treeselect class="projectmdllistdd" v-on:search-change="createduser" placeholder="Select Created-By" v-model="search.createdby" :multiple="true" :clear-on-select="true" :options="search.projectcreatedbyopts" disabled v-else/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Assigned To</label>
                  <treeselect
                    placeholder="Enter min 4 chars to search Employee Name"
                    class="projectmdllistdd capitalisetext"
                    v-on:search-change="getEmpListByProjectAssigned"
                    v-model="search.searchAllocated"
                    :multiple="true" :clear-on-select="true"
                    :options="search.alloactedopts" v-if="pagefeatures.createdassigned"
                  />
                  <treeselect
                    placeholder="Enter min 4 chars to search Employee Name"
                    class="projectmdllistdd capitalisetext"
                    v-on:search-change="getEmpListByProjectAssigned"
                    v-model="search.searchAllocated"
                    :clear-on-select="true"
                    :options="search.alloactedopts" disabled v-else
                  />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label" for="createddate">Status</label>
                  <treeselect class="capitalisetext" for="status" v-model="search.searchStatus"  :multiple="true" :options="search.statusopts"/>
                </div>
                <div class="form-group datePicker col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <label for="">Incident Date From</label>
                    <date-picker placeholder="Select Incident Date From" v-model="search.createddatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                </div>
                <div class="form-group datePicker col-xs-12 col-sm-6 col-md-6 col-lg-3">
                  <label for="">Incident Date To</label>
                    <date-picker placeholder="Select Incident Date To" v-model="search.createddateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-1 text-right">
                  <button
                    type="submit"
                    class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected"
                    @click="masterlist(search,'',1)"
                  >
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                  </button>
                  <button
                    type="reset"
                    class="btn btn-outline-secondary mt-2 mr-1"
                    @click="resetRecords()"
                  >
                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                  </button>
                   <button type="move" class="btn btn-relief-primary mt-2" :disabled="isBothDateSelected" @click="ExportXL() "><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="dashboard-ecommerce">
          <div class="card card-statistics p-1" >
            <div class="">
              <div class="dark_theme_style_table">
                <div class="table-responsive bordertable">
                  <table class="table dataTableListing" aria-describedby="mydesc">
                    <thead class="header-primary">
                      <th scope="col" class="text-center"></th>
                      <th scope="col" class="text-center">Critical</th>
                      <th scope="col" class="text-center">Major</th>
                      <th scope="col" class="text-center">Moderate</th>
                      <th scope="col" class="text-center">Minor</th>
                      <th scope="col" class="text-center">Cosmetic</th>
                      <th scope="col" class="text-center">Total</th>
                    </thead>
                    <tbody>
                    <tr class="row1 table-danger" >
                    <td class="">Open Incident</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Critical', 'open',severitystatus.severityCriticalOpen ? severitystatus.severityCriticalOpen:0)">{{this.severitystatus.severityCriticalOpen?this.severitystatus.severityCriticalOpen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Major', 'open', severitystatus.severityMajorOpen ? severitystatus.severityMajorOpen:0)">{{this.severitystatus.severityMajorOpen?this.severitystatus.severityMajorOpen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Moderate', 'open', severitystatus.severityModerateOpen ? severitystatus.severityModerateOpen:0)">{{this.severitystatus.severityModerateOpen?this.severitystatus.severityModerateOpen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Minor', 'open', severitystatus.severityMinorOpen ? severitystatus.severityMinorOpen:0)">{{this.severitystatus.severityMinorOpen?this.severitystatus.severityMinorOpen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Cosmetic', 'open', severitystatus.severityCosmeticOpen ? severitystatus.severityCosmeticOpen:0)">{{this.severitystatus.severityCosmeticOpen?this.severitystatus.severityCosmeticOpen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable(null,'open', (severitystatus.severityCriticalOpen ? parseInt(severitystatus.severityCriticalOpen):0)+
                          (severitystatus.severityMajorOpen ? parseInt(severitystatus.severityMajorOpen):0) +
                          (severitystatus.severityModerateOpen ? parseInt(severitystatus.severityModerateOpen):0) +
                          (severitystatus.severityMinorOpen ? parseInt(severitystatus.severityMinorOpen):0) +
                          (severitystatus.severityCosmeticOpen ? parseInt(severitystatus.severityCosmeticOpen):0))">
                          {{
                          (this.severitystatus.severityCriticalOpen?parseInt(this.severitystatus.severityCriticalOpen):0)+
                          (this.severitystatus.severityMajorOpen?parseInt(this.severitystatus.severityMajorOpen):0) +
                          (this.severitystatus.severityModerateOpen?parseInt(this.severitystatus.severityModerateOpen):0) +
                          (this.severitystatus.severityMinorOpen?parseInt(this.severitystatus.severityMinorOpen):0) +
                          (this.severitystatus.severityCosmeticOpen?parseInt(this.severitystatus.severityCosmeticOpen):0)}}</td>
                    </tr>
                    <tr class="row1 table-warning" >
                    <td class="">Re-Open Incident</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Critical', 'reopen', severitystatus.severityCriticalReopen?severitystatus.severityCriticalReopen:0)">{{this.severitystatus.severityCriticalReopen?this.severitystatus.severityCriticalReopen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Major', 'reopen', severitystatus.severityMajorReopen ? severitystatus.severityMajorReopen:0)">{{this.severitystatus.severityMajorReopen?this.severitystatus.severityMajorReopen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Moderate', 'reopen', severitystatus.severityModerateReopen ? severitystatus.severityModerateReopen:0)">{{this.severitystatus.severityModerateReopen?this.severitystatus.severityModerateReopen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Minor', 'reopen', severitystatus.severityMinorReopen ? severitystatus.severityMinorReopen:0)">{{this.severitystatus.severityMinorReopen?this.severitystatus.severityMinorReopen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable('Cosmetic', 'reopen', severitystatus.severityCosmeticReopen ? severitystatus.severityCosmeticReopen:0)">{{this.severitystatus.severityCosmeticReopen?this.severitystatus.severityCosmeticReopen:0}}</td>
                    <td class="text-center" style="cursor:pointer;" @click="countClickable(null,'reopen', (severitystatus.severityCriticalReopen ? parseInt(severitystatus.severityCriticalReopen):0) +
                          (severitystatus.severityMajorReopen ? parseInt(severitystatus.severityMajorReopen):0) +
                          (severitystatus.severityModerateReopen ? parseInt(severitystatus.severityModerateReopen):0) +
                          (severitystatus.severityMinorReopen ? parseInt(severitystatus.severityMinorReopen):0) +
                          (severitystatus.severityCosmeticReopen ? parseInt(severitystatus.severityCosmeticReopen):0))">
                          {{
                          (this.severitystatus.severityCriticalReopen?parseInt(this.severitystatus.severityCriticalReopen):0) +
                          (this.severitystatus.severityMajorReopen?parseInt(this.severitystatus.severityMajorReopen):0) +
                          (this.severitystatus.severityModerateReopen?parseInt(this.severitystatus.severityModerateReopen):0) +
                          (this.severitystatus.severityMinorReopen?parseInt(this.severitystatus.severityMinorReopen):0) +
                          (this.severitystatus.severityCosmeticReopen?parseInt(this.severitystatus.severityCosmeticReopen):0)}}</td>
                    </tr>
                    <tr class="row1 table-primary text-center" >
                    <td class="text-left">Resolved Incident</td>
                    <td style="cursor:pointer;" @click="countClickable('Critical', 'resolved', severitystatus.severityCriticalResolved ? severitystatus.severityCriticalResolved:0)">{{this.severitystatus.severityCriticalResolved?this.severitystatus.severityCriticalResolved:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Major', 'resolved', severitystatus.severityMajorResolved ? severitystatus.severityMajorResolved:0)">{{this.severitystatus.severityMajorResolved?this.severitystatus.severityMajorResolved:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Moderate', 'resolved', severitystatus.severityModerateResolved ? severitystatus.severityModerateResolved:0)">{{this.severitystatus.severityModerateResolved?this.severitystatus.severityModerateResolved:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Minor', 'resolved', severitystatus.severityMinorResolved ? severitystatus.severityMinorResolved:0)">{{this.severitystatus.severityMinorResolved?this.severitystatus.severityMinorResolved:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Cosmetic', 'resolved', severitystatus.severityCosmeticResolved ? severitystatus.severityCosmeticResolved:0)">{{this.severitystatus.severityCosmeticResolved?this.severitystatus.severityCosmeticResolved:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable(null,'resolved', (severitystatus.severityCriticalResolved ? parseInt(severitystatus.severityCriticalResolved):0 )+
                          (severitystatus.severityMajorResolved ? parseInt(severitystatus.severityMajorResolved):0 )+
                          (severitystatus.severityModerateResolved ? parseInt(severitystatus.severityModerateResolved):0) +
                          (severitystatus.severityMinorResolved ? parseInt(severitystatus.severityMinorResolved):0) +
                          (severitystatus.severityCosmeticResolved ? parseInt(severitystatus.severityCosmeticResolved):0))">
                          {{
                          (this.severitystatus.severityCriticalResolved?parseInt(this.severitystatus.severityCriticalResolved):0 )+
                          (this.severitystatus.severityMajorResolved?parseInt(this.severitystatus.severityMajorResolved):0 )+
                          (this.severitystatus.severityModerateResolved?parseInt(this.severitystatus.severityModerateResolved):0) +
                          (this.severitystatus.severityMinorResolved?parseInt(this.severitystatus.severityMinorResolved):0) +
                          (this.severitystatus.severityCosmeticResolved?parseInt(this.severitystatus.severityCosmeticResolved):0)}}</td>
                    </tr>
                    <tr class="row1 table-success text-center" >
                    <td class="text-left">Closed Incident</td>
                    <td class="" style="cursor:pointer;" @click="countClickable('Critical', 'closed', severitystatus.severityCriticalClosed ? severitystatus.severityCriticalClosed:0)">{{this.severitystatus.severityCriticalClosed?this.severitystatus.severityCriticalClosed:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Major', 'closed', severitystatus.severityMajorClosed ? severitystatus.severityMajorClosed:0)">{{this.severitystatus.severityMajorClosed?this.severitystatus.severityMajorClosed:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Moderate', 'closed', severitystatus.severityModerateClosed ? severitystatus.severityModerateClosed:0)">{{this.severitystatus.severityModerateClosed?this.severitystatus.severityModerateClosed:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Minor', 'closed', severitystatus.severityMinorClosed ? severitystatus.severityMinorClosed:0)">{{this.severitystatus.severityMinorClosed?this.severitystatus.severityMinorClosed:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable('Cosmetic', 'closed', severitystatus.severityCosmeticClosed ? severitystatus.severityCosmeticClosed:0)">{{this.severitystatus.severityCosmeticClosed?this.severitystatus.severityCosmeticClosed:0}}</td>
                    <td style="cursor:pointer;" @click="countClickable(null,'closed', (severitystatus.severityCriticalClosed ? parseInt(severitystatus.severityCriticalClosed):0) +
                          (severitystatus.severityMajorClosed ? parseInt(severitystatus.severityMajorClosed):0 )+
                          (severitystatus.severityModerateClosed ? parseInt(severitystatus.severityModerateClosed):0) +
                          (severitystatus.severityMinorClosed ? parseInt(severitystatus.severityMinorClosed):0) +
                          (severitystatus.severityCosmeticClosed?parseInt(severitystatus.severityCosmeticClosed):0))">
                          {{
                          (this.severitystatus.severityCriticalClosed?parseInt(this.severitystatus.severityCriticalClosed):0) +
                          (this.severitystatus.severityMajorClosed?parseInt(this.severitystatus.severityMajorClosed):0 )+
                          (this.severitystatus.severityModerateClosed?parseInt(this.severitystatus.severityModerateClosed):0) +
                          (this.severitystatus.severityMinorClosed?parseInt(this.severitystatus.severityMinorClosed):0) +
                          (this.severitystatus.severityCosmeticClosed?parseInt(this.severitystatus.severityCosmeticClosed):0)}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      <div id="scrollToListGrid"></div>
      <div class="row">
   <div class="col-12">
     <ul class="listGrid float-right d-inline-flex">
        <li class="cursorpointercls"  @click="myListView()" >
          <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
            <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
            <span>
              List View
            </span>
          </button>
        </li>
        <li class=" cursorpointercls" @click="myGridView()" >
          <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
            <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
            <span>
              Grid View
            </span>
          </button>
        </li>
      </ul>
   </div>
 </div>
        <div  v-bind:class="[blog_list_wrapper,{displayblk:isgridView}]">
          <div class="dw mx-n1">
            <div class="dw-panel p-1"  v-for="(data,index) in incidentmasterlist" v-bind:key="index">
              <div class="card border-warning card_style_box_shadow1">
              <div class="card-header p-1">
                  <h4 class="card-title">{{data.projectname}} : {{data.incidentcode}} </h4>
              </div>
              <div class="card" style="margin-bottom:0 !important;">
                  <div class="card-body p-1">
                      <h4 class="card-title mb-1">
                          <span v-if="data.incidentname.length > 50"  class="blog-title-truncate text-body-heading ">
                            {{(data.incidentname.substring(0,51)+ ' ...').toLowerCase()}}
                          </span>
                          <span v-else  class="blog-title-truncate text-body-heading ">
                            {{(data.incidentname).toLowerCase()}}
                          </span>
                      </h4>
                      <div class="media">
                          <div class="avatar mr-50">
                             <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                             <img alt=""  v-if="!data.imagename" :src="defaultimagepath"  width="24" height="24" />
                          </div>
                          <div class="media-body">
                            <div>
                              <small class="text-muted mr-25">by</small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                              <span class="text-muted ml-50 mr-25">|</span>
                              <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                              
                            </div>
                          </div>
                      </div>
                      <div class="my-1">
                            <div v-if="data.priority.toLowerCase()=='low'" class="capitalisetext badge badge-pill badge-light-info mr-50 mb-25">{{data.priority}}</div>
                            <div v-if="data.priority.toLowerCase()=='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50 mb-25">{{data.priority}}</div>
                            <div v-if="data.priority.toLowerCase()=='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50 mb-25">{{data.priority}}</div>

                            <div v-if="data.severity.toLowerCase()=='critical'" class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">{{data.severity}}</div>
                            <div  v-if="data.severity.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 mb-25 capitalisetext">{{data.severity}}</div>
                            <div v-if="data.severity.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 mb-25 capitalisetext">{{data.severity}}</div>
                            <div v-if="data.severity.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.severity}}</div>
                            <div v-if="data.severity.toLowerCase()=='moderate'" class="badge badge-pill badge-light-info mr-50 mb-25 capitalisetext">{{data.severity}}</div>

                            <div v-if="data.status.toLowerCase()=='open'" class="capitalisetext badge badge-pill badge-light-warning mr-50 mb-25">{{data.status}}</div>
                            <div v-if="data.status.toLowerCase()=='reopen'" class="capitalisetext badge badge-pill badge-light-danger mr-50 mb-25">{{data.status}}</div>
                            <div v-if="data.status.toLowerCase()=='resolved'" class=" capitalisetext badge badge-pill badge-light-primary mr-50 mb-25">{{data.status}}</div>
                            <div v-if="data.status.toLowerCase()=='closed'" class=" capitalisetext badge badge-pill badge-light-success mr-50 mb-25">{{data.status}}</div>
                            
                            <div  class="badge badge-pill badge-light-primary capitalisetext">{{data.incidenttype}}</div>
                      </div>
                      <div class="card-text blog-content-truncate" style="overflow:hidden">
                          <span v-if="data.description.length>150" v-html="data.description.substring(0, 151) + ' ...'"></span>
                          <span v-else v-html="data.description"></span>
                      </div>
                      <div>
                              <small class="text-muted mr-25">Assigned To </small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.assignedid)" class="capitalisetext">{{data.empname}}</a></small>
                            </div>
                      <hr class="mx-n1" />
                      <div class="d-flex justify-content-between align-items-center">
                          <a v-if="loginusername == data.createdby " @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                              <div class="d-flex align-items-center ">
                                    <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                  <span class="text-body font-weight-bold " >Edit</span>
                              </div>
                          </a>
                          <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold">View Details</span>
                            </div>
                          </a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
            <div class="pageCenter text-center pt-1">
              <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
              <div v-if="incidentmasterlist.length == 0 && isresponce" colspan="14"  style="margin-bottom: 2rem; text-align: center;">No record found</div>
            </div>
        </div>
        <div
          v-bind:class="[{ show: isListActive}, card]" 
          style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top: 1rem;
            margin-bottom: 1rem !important;"
        >
          <div
            v-bind:class="[{ show: isListDetActive }, card_content, collapse]"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div class="table-responsive datatableview">
                                <table class="table table-sm dataTableListing table-hover table-striped" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno align-middle py-1">Sr#</th>
                                      <th scope="col" class="align-middle">Incident Code</th>
                                      <th scope="col" class="align-middle" style="width:160px;">Incident Name</th>
                                      <th scope="col" class="align-middle">Project Name</th>
                                      <th scope="col" class="align-middle">Incident Date</th>
                                      <th scope="col" class="align-middle">Downtime</th>
                                      <th scope="col" class="align-middle">Assigned to</th>
                                      <th scope="col" class="align-middle">Created Details</th>
                                      <th scope="col" class="align-middle">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(
                                        data, index
                                      ) in incidentmasterlist"
                                    >
                                      <tr v-bind:key="data.incidentid">
                                        <td data-column="Sr#" class="srno">
                                          {{
                                            index + 1 + currPageNum * pagelimit
                                          }}
                                        </td>
                                        <td data-column="Incident Code" class="text-nowrap">{{ data.incidentcode }}</td>
                                        <td data-column="Incident Name" class="rolelistdd"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer">
                                          <span
                                            class="
                                              font-weight-bold
                                              sitebluecolor
                                            "
                                            v-if="
                                              data.incidentname.length >
                                              50
                                            "
                                            >{{
                                              data.incidentname.substring(
                                                0,
                                                50 + 1
                                              ) + "..."
                                            }}</span
                                          >
                                          <span class="   font-weight-bold   sitebluecolor " v-if="   data.incidentname.length <=   50 " >{{data.incidentname}}</span>
                                        </td>
                                        <td data-column="Project Name">
                                          <span class="font-weight capitalisetext cursorpointercls sitebluecolor"  @click="redirecttourl('projectID',data)">{{data.projectname}}</span>
                                        </td>
                                        <td data-column="Incident Date" class="text-nowrap">{{ dateMonthFormat(data.datetime) }}</td>
                                        <td data-column="Downtime">{{ data.totaldowntime.slice(0,-3) }}</td>
                                        <td data-column="Assigned to">{{ data.empname }}</td>
                                        <td data-column="Created Details">
                                          <span class="release_hr_tag_span"> Created By :</span>
                                          {{ data.createdby.split("@")[0] }}
                                          <hr class="release_hr_tag_tab">
                                            <span class="release_hr_tag_span"> Created Date :</span>
                                            {{ dateMonthFormat(data.createddate) }}
                                          </td>
                                       

                                        <td data-column="Action" class="clsaction">
                                              <a v-if="loginusername == data.createdby " class="devEditEle"  @click="redirecttourl('edit',data)" style="cursor: pointer;" href="javascript:void(0);">
                                                <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                              </a>
                                            </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="incidentmasterlist.length == 0"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">
                                          No records found
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pageCenter text-center">
                      <pagination
                      v-if="showtabledata"
                      v-model="page"
                      :records="totalcount"
                      :per-page="pagelimit"
                      @paginate="clickCallback"
                    /></div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

        <script>
import axios from "axios";
import moment from "moment";
import {
  EditIcon,DownloadIcon,SearchIcon,GridIcon,ListIcon,ExternalLinkIcon,
  Edit2Icon,
  PlusIcon,
  MinusSquareIcon,
  ClipboardIcon,
  PlusSquareIcon,
  MessageSquareIcon,
  BellIcon,
  BookIcon,
  InfoIcon,
  EyeIcon,PlusCircleIcon,XIcon,UserIcon
} from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import apiUrl from "../../constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "vue-pagination-2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import profilepic from '../profilepic.vue';
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Incident",
  components: {
    Loading,GridIcon,ListIcon,EyeIcon,
    SearchIcon,
    EditIcon,
    Edit2Icon,
    Treeselect,
    DatePicker,
    ExternalLinkIcon,
    Pagination,
    PlusIcon,
    MinusSquareIcon,
    ClipboardIcon,
    PlusSquareIcon,
    ckeditor: CKEditor.component,
    DownloadIcon,
    MessageSquareIcon,
    BellIcon,
    BookIcon,
    InfoIcon,
    profilepic,PlusCircleIcon,XIcon,UserIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      maxLength:14,
      componentKey: 0,
      severitystatus:[],
      defaultimagepath:apiUrl.defaultimagepath,
      incidenttypelist:apiUrl.incidenttypelist.sort((a, b) => a.label.localeCompare(b.label)),
      search: {
        searchIncidentname: null,
        incidentcodelist:null,
        incidentopts: null,
        searchprojectname: null,
        projectnameopts: [],
        searchFunctionality: null,
        functionalityopts: null,
        searchTask: null,
        taskopts: null,
        searchSeverity: null,
        createdby: null,
        projectcreatedbyopts: null,
        createddatefrom:null,
        createddateto:null,
        searchincidenttype:null,
        incidentcode: null,
        projectnames:null,
        severityopts: [
         {
            id: "Cosmetic",
            label: "Cosmetic",
          },
          {
            id: "Critical",
            label: "Critical",
          },
          {
            id: "Major",
            label: "Major",
          },
          {
            id: "Minor",
            label: "Minor",
          },  
          {
            id: "Moderate",
            label: "Moderate",
          },  
        ],
        searchPriority: null,
        priorityopts: [
          {
            id: "High",
            label: "High",
          },
          {
            id: "Low",
            label: "Low",
          },
          {
            id: "Medium",
            label: "Medium",
          },
        ],

        searchAllocated: null,
        alloactedopts: [],
        searchStatus: null,
        statusopts: [{
          id:'open',
          label:'Open'
        },{
          id:'resolved',
          label:'Resolved'
        },{
          id:'closed',
          label:'Closed'
        },{
          id:'reopen',
          label:'Re-open'
        }],
      },
      view: {
        projectname: null,
        functionlityname: null,
        taskname: null,
        empname: null,
      },
      projectnamelist:[],
      isSearchRequestActive: false,
      pageoffset: 1,
      TimeFormat: apiUrl.TimeFormat,
      isLoading: false,
      fullPage: true,
      islistLoading:false,
      listfullPage:true,
      valid: false,
      submitted: false,
      name: "",
      datetime: "",
      totaldowntime: "",
      impact: "",
      losses: "",
      scenario: "",
      incidenttype: "",
      description: "",
      reason: "",
      shorttermsolution: "",
      longtermsolution: "",
      project: null,
      functionality: null,
      projectlist: null,
      functionalitylist: null,
      severity: "",
      priority: "",
      attachment: null,
      attachmentview: null,
      allocatedto: null,
      status: null,
      createdby: "",
      glbMdlLbl: "Incident",
      glbMdl: "",
      incidentmasterlist: [],
      editRowData: [],
      nameBlured: false,
      impactBlured: false,
      lossesBlured: false,
      incidenttypeBlured: false,
      descriptionBlured: false,
      reasonBlured: false,
      isresponce:false,
      employeeListOpts: [],
      errors: {
        project: "",
        functionality: "",
        allocatedto: "",
        status: "",
        severity: "",
        priority: "",
        datetime: "",
        totaldowntime: "",
        imgm: {
          task: null,
          tasklist: [],
        },
      },
      imgm: {
        task: null,
      },
      isAddState: false,
      disblesavebtn: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pagefeatures: [],
      showtabledata: true,
      isDetActive: false,
      isListDetActive:false,
      isRequestActive: false,
      card_content: "card-content",
      collapse: "collapse",
      isedit: false,
      card: "card",
      sitebluecolor:"sitebluecolor",
      activegridlink:true,
      activelistlink:false,
      border_warning: "border-warning",
      editor: ClassicEditor,
      loginusername:'',
      isListActive:true,
      displayblk:'displayblk',
      isgridView:false,
      tokendata:null,
      blog_list_wrapper :'blog-list-wrapper',
      countSeverity:null,
      countStatus:null,
      countClick:false,
      isBothDateSelected:false,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
            "|",
          ],
        },
      },
      rdashboard:null,
      userrole:null,
    };
  },

  created() {
    this.pagefeatures = {};
    let pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/incident/list") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },

  mounted() {
    window.addEventListener('storage', function(event){
        if (event.key == 'logout-mee') { 
            window.location.reload();
        }
    });
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.isLoading=true
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.tokendata = window.localStorage.getItem('token');
        this.userrole = window.localStorage.getItem("userrole")
        this.createdby = userdata.username;
        this.loginusername=userdata.username
        this.undt = userdata;
        this.search.createdby = this.undt.username;
        this.search.searchAllocated = [this.undt.userid];
        this.getEmpListByProjectAssignededit(this.search.searchAllocated);
        //this.getcreatedby(this.search.createdby)
        if(this.$route.params.clickeddata !=undefined){
          this.rdashboard = this.$route.params.clickeddata['rdashboard']
        }
        if(this.$route.params.backbtnstate == "open") {
          this.search.searchStatus=['open'];
          this.masterlist(null, ['open'])
        } else if(this.$route.params.backbtnstate == "ProjectSummaryIncident") {
          let dataSplit = this.$route.params.clickeddata.split('_');
          let projectid = this.$route.params.pid;
          let search = { 
            searchSeverity: dataSplit[0], 
            searchStatus: [dataSplit[1]],
            searchprojectname: projectid,
         }
            this.masterlist(search, '', 1);          
        } else if(this.$route.params.search) {
          let search = JSON.parse(localStorage.getItem('incidentsearchItems'));
          if(search) {
            this.getAssignedProjectData(search.searchprojectname);
            this.getSearchItems(search);
          }else{
            localStorage.removeItem('incidentsearchItems');
            this.search.searchStatus = ['open'];
            this.search.createdby = [this.undt.username];
            this.search.searchAllocated = [this.undt.userid];
            this.getEmpListByProjectAssignededit(this.search.searchAllocated);
            this.getcreatedby(this.search.createdby);
            this.masterlist({ searchStatus: ['open'] });
          }
        } else {
          localStorage.removeItem('incidentsearchItems')
          this.search.searchStatus = ['open'];
          this.search.createdby = [this.undt.username];
          this.search.searchAllocated = [this.undt.userid];
          this.getEmpListByProjectAssignededit(this.search.searchAllocated);
          this.getcreatedby(this.search.createdby);
          this.masterlist({ searchStatus: ['open'] });
        }
        // this.getEmpListByProjectAssigned(parseInt(this.undt.userid))
      }
      this.getincidentist();
    }
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      });
    },

  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    redirecttoprofile(empid){
      let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
      window.open(route.href);
    },
    redirecttourl:function(btnstate,rowdata){
      rowdata.backbtnstate=btnstate
      
      if(btnstate=="view"){
          let routeData = this.$router.resolve({ 
              path: '/incident/view?incidentid='+rowdata.id,
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata
              }
          })
          window.open(routeData.href, '_blank');
      }else if(btnstate=="edit"){
          this.$router.push({ 
              path: '/incident/update?incidentid='+rowdata.id,
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata
              }
          })
      }
         else if (btnstate == 'projectID') {
                   this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid,
                })
            }
        
    },
    clearCodeList(state) {
      if(!state) {
        this.search.incidentcodelist = null
      }
    },
    projectOnSearch: function (node, instanceId){
        if(node && node.length>1){
            this.isLoading = true;
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                projectname:node.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage:'listingpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnamelist = result.data.data;
                } else {
                    this.projectnamelist = [];
                }
            }).catch(e => {
            this.displayError(e)
        });
        }
    },
    incidentCodeChangeSearch(node) {
      if(node && node.length>1) {
        axios({
          'method': 'POST',
          'url': 'api/incident/getincidentcode',
          'data': {
            incidentcode: node.toUpperCase(),
            useremail: this.undt.username,
            empcode: this.undt.userid
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.incidentcodelist = result.data.data
            localStorage.setItem('incidentcodelist', JSON.stringify(result.data.data))
          } else {
            this.search.incidentcodelist = null
            localStorage.removeItem('incidentcodelist')
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
      getcreatedby(usermail) {
        if(usermail && usermail.length>0){
        axios({
          'method': 'POST',
          'url': 'api/incident/getcreatedby',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            created: usermail
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data
          } else {
            this.search.projectcreatedbyopts = null
          }
        }).catch(e => {
        this.displayError(e)
      });
       }
      },
      createduser: function (node) {
          if(node && node.length > 3){
            this.isLoading = true;
          axios({
            'method': 'POST',
            'url': 'api/incident/getcreatedby',
            'data': {
              empid: parseInt(this.undt.userid),
              useremail: this.undt.username,
              empcode: this.undt.userid,
              createdby:node.toLowerCase(),
            },
            'headers':{'authorization':this.tokendata}
          }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
              this.search.projectcreatedbyopts = result.data.data
            } else {
              this.search.projectcreatedbyopts = null
            }
          })
        }
        },
    getseverity(request){
        this.isLoading = true;
        let url = "api/incident/getseverity";
        this.dashboardinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          projectid:request.projectname,
          projectids:this.search.projectnames,
          functionalityid:request.functionality,
          allotedto:request.allocatedto?request.allocatedto:null,
          taskid:request.taskname,
          status:request.status,
          datefrom: request.datefrom,
          dateto: request.dateto,
          incidentcode: request.incidentcode,
          incidentname: request.incidentname,
          createdby: request.createdby,
          empcode: this.undt.userid,
          incidenttype: request.incidenttype,
          userrole:this.userrole,
          priority: request.priority,
          ispagefeatures: request.ispagefeatures
        };
        axios({
          method: "POST",
          url: url,
          data: this.dashboardinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.severitystatus = result.data.data[0];
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            this.severitystatus=[]
          }
        }).catch(e => {
        this.displayError(e)
      });
      },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    myListView: function () {
      this.isListDetActive = true;
      this.isgridView=true;
      this.activegridlink=false
      this.activelistlink=true
      $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    myGridView(){
      this.isListDetActive = false;
      this.isgridView=false;
      this.activegridlink=true
      this.activelistlink=false
      $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    projectChangebyid: function (projectid) {
      if (projectid && projectid.length > 0) {
        this.isLoading = true;
        let url = "api/searching/getAssignedProjectListWithMOdule";
        this.input = {
          projectidlist: projectid,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectnameopts = result.data.data;
          } else {
            this.search.projectnameopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    projectChange: function (node) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/searching/getAssignedProjectListWithMOdule";
        this.input = {
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid

        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectnameopts = result.data.data;
            this.projectlist = result.data.data;
            localStorage.setItem('incidentprojectnameopts', JSON.stringify(result.data.data))
          } else {
            this.search.projectnameopts = [];
            this.projectlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getAssignedProjectData(projectid) {
      this.isLoading = true;
      let url = "api/searching/getAssignedProjectListWithMOdule";
      this.input = {
        projectname: "",
        useremail: this.undt.username,
        empid: this.undt.userid,
        projectid: projectid,
        empcode: this.undt.userid
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.search.projectnameopts = result.data.data;
        } else {
          this.search.projectnameopts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    incidentnmChange: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/master/commonmaster/getModuleDropdownOption";
        this.input = {
          tbl: "t46",
          incidentname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid

        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.incidentopts = result.data.data;
            localStorage.setItem('incidentopts', JSON.stringify(result.data.data))
          } else {
            this.search.incidentopts = [];
            localStorage.removeItem('incidentopts')
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getAllocatedEMPByProjectModuel(projectid) {
      axios({
        method: "POST",
        url: "api/listing/getAllocatedEMPByProjectModuel",
        data: { projectidModuleID: projectid,useremail: this.undt.username,empcode: this.undt.userid},
         'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.employeeListOpts = result.data.data;
        } else {
          this.employeeListOpts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getEmpallocatedtoProject(projectid) {
      axios({
        method: "POST",
        url: "api/listing/getEmpallocatedtoProject",
        data: { projectid: projectid,useremail: this.undt.username,empcode: this.undt.userid },
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.search.alloactedopts = result.data.data;
          let decryptdepartmenttext = this.search.alloactedopts.map(items =>{

            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        } else {
          this.search.alloactedopts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    
    getEmpListByProjectAssigned(node) {
      if(node && node.length>3){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,empcode: this.undt.userid,empname:node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.alloactedopts = result.data.data;
            let decryptdepartmenttext = this.search.alloactedopts.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.alloactedopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getEmpListByProjectAssignededit(assignedto) {
      if(assignedto && assignedto.length>0){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,empcode: this.undt.userid,assignedto:assignedto
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.alloactedopts = result.data.data;
            let decryptdepartmenttext = this.search.alloactedopts.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.alloactedopts = []
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    selectproject: function (state, value) {
      this.search.searchProjectname = null;
      this.search.searchProjectname = state;
      if (!state) {
        localStorage.removeItem('incidentprojectnameopts');
        this.search.searchFunctionality = null;
        this.search.searchTask = null;
        this.search.functionalityopts = null;
        this.search.taskopts = null;
      } else {
        this.getDropdownApplicationdata("t37");
      }
    },
    selectfunctionality: function (state, value) {
      this.search.searchFunctionality = null;
      this.search.searchFunctionality = state;
      if (!state) {
        this.search.searchTask = null;
        this.search.taskopts = null;
      } else {
        this.getDropdownApplicationdata("t18");
      }
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption";
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
      };
      if (mdl === "t37") {
        this.input = {};
        apiUrl = "api/listing/getfunctionalitybyprojectmoduleid";
        this.input.projectmoduleid = this.search.searchProjectname;
      }
      if (mdl === "t18") {
        this.input.functionalityid = this.search.searchFunctionality;
      }
      if (mdl === "t46") {
        this.input.functionalityid = this.search.searchFunctionality;
      }
      
      this.input.useremail= this.undt.username,
      this.input.empcode= this.undt.userid
      axios({
        'method': 'POST',
        'url': apiUrl,
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then((result) => {
        if (result.data.errorCode == 0) {
          if (mdl === "t37") {
            this.search.functionalityopts = result.data.data;
            this.search.functionalityopts.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
          } else if (mdl === "t18") {
            this.search.taskopts = result.data.data;
          } else if (mdl === "t46") {
            this.search.incidentopts = result.data.data;
          }
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.search.functionalityopts = [];
          this.search.taskopts = [];
          this.search.incidentopts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
      this.search.incidentcode=null;
      this.search.searchprojectname = null;
      this.search.searchFunctionality = null;
      this.search.searchTasks = null;
      this.search.searchIncidentname = null;
      this.search.searchSeverity = null;
      this.search.searchincidenttype = null;
      this.search.searchPriority = null;
      this.search.searchStatus = null;
      this.search.searchAllocated = null;
      this.search.createdby = null;
      this.search.createddatefrom = null;
      this.search.createddateto = null;
      this.isBothDateSelected = false
      this.countSeverity = null;
      this.countStatus = null;
      this.countClick = false;
      localStorage.removeItem('incidentsearchItems');
      localStorage.removeItem('incidentprojectnameopts');
      localStorage.removeItem('incidentopts');
      localStorage.removeItem('incidentcodelist');
      this.search.createdby = [this.undt.username];
      this.search.searchAllocated = [this.undt.userid];
      this.search.searchStatus = ['open'];
      this.getEmpListByProjectAssignededit(this.search.searchAllocated);
      this.getcreatedby(this.search.createdby);
      this.masterlist({ searchStatus: ['open'] });
    },
    mydetailscollapse() {
      this.isDetActive = !this.isDetActive;
    },
    myrequestcollapse() {
      this.isRequestActive = !this.isRequestActive;
      this.datetime = null;
      this.name = null;
      this.datetime = null;
      this.totaldowntime = null;
      this.impact = null;
      this.losses = null;
      this.description = "";
      this.reason = null;
      this.incidenttype = null;
      this.shorttermsolution = null;
      this.longtermsolution = null;
      this.severity = null;
      this.priority = null;
      this.allocatedto = null;
      this.status = null;
      this.project = null;
    },
    resetForm() {
      this.name = "";
      this.imgm.task = null;
      this.datetime = "";
      this.totaldowntime = "";
      this.impact = "";
      this.losses = "";
      this.incidenttype = "";
      this.description = "";
      this.reason = "";
      this.shorttermsolution = "";
      this.longtermsolution = "";
      this.project = null;
      this.functionality = null;
      this.severity = "";
      this.priority = "";
      this.attachment = null;
      this.attachmentview = null;
      this.allocatedto = null;
      this.status = "";
      this.submitted = false;
      this.valid = false;
      this.nameBlured = false;
      this.impactBlured = false;
      this.lossesBlured = false;
      this.incidenttypeBlured = false;
      this.descriptionBlured = false;
      this.reasonBlured = false;
      this.errors = {
        project: "",
        functionality: "",
        allocatedto: "",
        status: "",
        severity: "",
        priority: "",
        datetime: "",
        totaldowntime: "",
        desc: "",
      };
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.masterlist();
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#scrollToListGrid").offset().top -70
            });
        }, 500);
    },

    validText: function (inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },

    validate: function () {
      this.valid = true;
      this.nameBlured = true;
      this.impactBlured = true;
      this.lossesBlured = true;
      this.incidenttypeBlured = true;
      this.descriptionBlured = true;
      this.reasonBlured = true;
      if (!this.project) {
        this.errors.project = "Project name required";
        this.valid = false;
      } else {
        this.errors.project = "";
      }
      if (!this.description) {
        this.errors.desc = "Description required";
        this.valid = false;
      } else {
        this.errors.desc = "";
      }
      if (!this.allocatedto) {
        this.errors.allocatedto = "assigned to required";
        this.valid = false;
      } else {
        this.errors.allocatedto = "";
      }
      if (!this.status) {
        this.errors.status = "Incident status required";
        this.valid = false;
      } else {
        this.errors.status = "";
      }
      if (!this.severity) {
        this.errors.severity = "Incident severity required";
        this.valid = false;
      } else {
        this.errors.severity = "";
      }
      if (!this.priority) {
        this.errors.priority = "Incident priority required";
        this.valid = false;
      } else {
        this.errors.priority = "";
      }
      if (!this.datetime) {
        this.errors.datetime = "Incident Date required";
        this.valid = false;
      } else {
        this.errors.datetime = "";
      }
      if (!this.totaldowntime) {
        this.errors.totaldowntime = "Incident down-time required";
        this.valid = false;
      } else {
        this.errors.totaldowntime = "";
      }
      if (!this.validText(this.name)) {
        this.valid = false;
      }
      if (!this.validText(this.impact)) {
        this.valid = false;
      }
      if (!this.validText(this.losses)) {
        this.valid = false;
      }
      if (!this.validText(this.incidenttype)) {
        this.valid = false;
      }
      if (!this.validText(this.description)) {
        this.valid = false;
      }
      if (!this.validText(this.reason)) {
        this.valid = false;
      }
      return this.valid;
    },
    notBeforeToday: function (date) {
      this.search.createddateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    selectedfromdate(){
      if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
        this.isBothDateSelected = false
      }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
        this.isBothDateSelected = true
      }
    },
    disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const selectedFromDate = new Date(this.search.createddatefrom);
      return date < selectedFromDate || date > today;
    },
    selectedtodate(){
       if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
        this.isBothDateSelected = false
      }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
        this.isBothDateSelected = true
      }
    },
    getSearchItems(search) {
      this.isSearchRequestActive = true
      this.search.incidentopts = JSON.parse(localStorage.getItem('incidentopts'))
      this.search.incidentcodelist = JSON.parse(localStorage.getItem('incidentcodelist'))
      this.search.incidentcode = search.incidentcode
      this.search.searchIncidentname = search.searchIncidentname
      this.search.searchTask = search.searchTask
      this.search.projectnameopts = JSON.parse(localStorage.getItem('incidentprojectnameopts'))
      this.search.searchprojectname = search.searchprojectname
      this.search.searchFunctionality = search.searchFunctionality
      this.search.searchPriority = search.searchPriority
      this.search.searchStatus = search.searchStatus
      this.search.searchAllocated = search.searchAllocated;
      this.search.searchSeverity = search.searchSeverity,
      this.search.searchincidenttype = search.searchincidenttype
      this.search.createdby = search.createdby;
      this.search.createddatefrom = search.createddatefrom;
      this.search.createddateto = search.createddateto;
      if(this.search.createdby && this.search.createdby ==null && !this.pagefeatures.createdassigned){
        this.search.createdby = this.undt.username;
      }
      if(this.search.searchAllocated && this.search.searchAllocated ==null && !this.pagefeatures.createdassigned){
        this.search.searchAllocated = [this.undt.userid];
      }
      this.getEmpListByProjectAssignededit(this.search.searchAllocated);
      this.getcreatedby(this.search.createdby);
      this.projectChangebyid(this.search.searchprojectname);
      this.masterlist(this.search);
    },
    masterlist(objThis,status,flag) {
      if(objThis) {
        localStorage.setItem('incidentsearchItems', JSON.stringify(objThis))
      }
      if(flag && flag==1){
        this.pageoffset=1
        this.page=1
      }
      this.islistLoading=true
      let apiURL = "/api/incidents/list";
      let userdata = window.localStorage.getItem("userdata");
      userdata = JSON.parse(userdata);
      this.input = {
        limit: apiUrl.LIMIT,
        offset: this.pageoffset,
        empid: this.undt.userid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        userrole:this.userrole,
        ispagefeatures: this.pagefeatures.createdassigned ? true : false
      };
      let search = JSON.parse(localStorage.getItem('incidentsearchItems'))
      if(search) {
        this.input.incidentname = search.searchIncidentname
        this.input.taskname = search.searchTask
        this.input.projectname = search.searchprojectname
        this.input.functionality = search.searchFunctionality
        this.input.priority = search.searchPriority
        this.input.status = search.searchStatus
        this.input.allocatedto = search.searchAllocated
        this.input.severity = search.searchSeverity?search.searchSeverity:this.search.searchSeverity
        this.input.incidenttype = search.searchincidenttype?search.searchincidenttype:this.search.searchincidenttype
        this.input.incidentcode = search.incidentcode
        this.input.createdby = search.createdby
        this.input.projectid = search.projectnames
        if(this.search.createddateto){
          var assignedate = new Date(this.search.createddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
        this.input.dateto = this.search.createddateto?assignedate:null
      }
      if (status){
        this.input.status = status
        this.input.dashboardflag=1
      }
      if(this.rdashboard && !flag){
        this.input.allocatedto = [this.undt.userid]
      }
      if(this.search.searchAllocated){
        this.input.allocatedto=this.search.searchAllocated
        this.input.status = search.searchStatus
      }
      if(this.search.createdby){
        this.input.createdby=this.search.createdby
      }
      this.getseverity(this.input);
      axios({
                'method': 'POST',
                'url': apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
      .then((result) => {
        this.islistLoading=false;
        this.isresponce=true
        if (result.data.errorCode == 0) {
          this.forceRerender()
          this.isDetActive = true;
          this.incidentmasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.isDetActive = true;
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.isDetActive = true;
          this.incidentmasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

    severitycount: function(severity){
      this.isLoading=true;
      this.incidentmasterlist=[];
      let apiURL = "/api/incidents/list";
        this.input = {
          empid:this.undt.userid,
          useremail: this.undt.username,
          offset:this.pageoffset,
          limit:apiUrl.LIMIT,
          severity: severity,
          status:['open'],
          empcode: this.undt.userid,
          userrole:this.userrole,
          ispagefeatures: this.pagefeatures.createdassigned ? true : false
        }
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}

        })
        .then(result => { 
          this.isLoading= false;         
          this.showproject=true;   
          if(result.data.errorCode == 0){
            this.incidentmasterlist=result.data.data.rows;
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }else{
            this.incidentmasterlist=[];
            this.totalcount = 0
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
        }).catch(e => {
        this.displayError(e)
      });
      },

    addModule(objThis) {
      $(objThis.currentTarget.dataset.target)
        .find("#toggleSubmission")
        .attr("data-isedit", false);
      this.disblesavebtn = true;
      this.attachmentview = null;
    },
    countClickable: function(severity, status, value){
      if(value == 0){
        return
      }
        this.countClick= true;
        this.countSeverity = severity
        this.countStatus = [status]
        this.incidentmasterlist = [];
        this.islistLoading= true;
        this.search.searchSeverity = severity
        var assignedate = new Date(this.search.createddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
        var apiURL = 'api/incidents/list';
        this.input = {
          empid:this.undt.userid,
          offset:1,
          limit:apiUrl.LIMIT,
          severity: severity,
          status: [status],
          datefrom: this.search.createddatefrom,
          dateto: assignedate,
          projectname : this.search.searchprojectname,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          incidenttype: this.search.searchincidenttype,
          userrole:this.userrole,
          createdby: this.search.createdby,
          allocatedto: this.search.searchAllocated,
          taskname: this. search.searchTask,
          functionality: this.search.searchFunctionality,
          incidentcode: this.search.incidentcode,
          incidentname: this.search.searchIncidentname,
          priority: this.search.searchPriority,
          ispagefeatures: this.pagefeatures.createdassigned ? true : false
        }
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
          this.islistLoading= false;         
          this.showproject=true;   
          if(result.data.errorCode == 0){
            this.incidentmasterlist=result.data.data.rows;
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }else{
            this.incidentmasterlist=[];
            this.totalcount = 0
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
        }).catch(e => {
        this.displayError(e)
      });
      },

    getincidentist() {
      this.getApplicationdata("t11", "imgm");
      return;
      
    },
    getProjectname: function () {
      this.apiURL = "api/projectapplication/getprojectnamelist";
      this.input = {
        projectid: 0,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      axios({
        method: "POST",
        url: this.apiURL,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.projectlist = result.data.data;
        } else {
          this.projectlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getApplicationdata(mdl, curr) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
      };
      let apiURL = "api/master/commonmaster/list";
      if (mdl == "t11") {
        apiURL = "api/listing/getprojectlist";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
      } else if (mdl == "t37") {
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        apiURL = "api/listing/getfunctionalitybyprojectmoduleid";
        this.inputappl.projectmoduleid = this.project;
      } else if (mdl == "t18") {
        apiURL = "api/master/commonmaster/getModuleDropdownOption";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          functionalityid: this.functionality,
        };
      }
      this.input.empcode = this.undt.userid,
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (mdl == "t11") {
            
            return true;
          } else if (mdl == "t37") {
            this.functionalitylist = result.data.data;
            this.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            return true;
          } else if (mdl == "t18" && curr == "imgm") {
            this.imgm.tasklist = result.data.data;
            return true;
          }
        } else if(result.data.errorCode == 3){
          Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
          })
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

    seltestcsproject(state, value) {
      this.project = null;
      this.project = state;
      if (!state) {
        this.functionalitylist = null;
        this.functionality = null;
        this.imgm.tasklist = null;
        this.imgm.task = null;
        this.allocatedto = null;
        this.employeeListOpts = null;
        this.project = null;
        this.errors.project = "Project required";
      } else {
        this.getApplicationdata("t37", "testcase");
        this.getAllocatedEMPByProjectModuel(state);
      }
    },

    seltestcsfunctionality(state, value) {
      this.functionality = null;
      this.functionality = state;
      if (!state && this.isAddState == false) {
        
        this.imgm.task = null;
        this.imgm.tasklist = null;
      } else {
       
        this.getApplicationdata("t18", "imgm");
      }
    },

    openEditModule(currRowData, flag) {
      this.resetForm();
      this.editRowData = currRowData;
      this.view.projectname = currRowData.projectname;
      this.view.projectid=currRowData.projectid
      this.view.functionlityname = currRowData.functionlityname;
      this.view.taskname = currRowData.taskname;
      this.view.empname = currRowData.empname;
      let btnEle = "incident";
      $("#" + btnEle)
        .find("#toggleSubmission")
        .addClass(btnEle);
      $("#" + btnEle)
        .find("#toggleSubmission")
        .attr("data-isedit", true);
      this.name = currRowData.incidentname;
      this.datetime = moment(currRowData.datetime).format("YYYY-MM-DD");
      this.totaldowntime = currRowData.totaldowntime;
      this.impact = currRowData.impact;
      this.losses = currRowData.losses;
      this.incidenttype = currRowData.incidenttype;
      this.description = currRowData.description;
      this.reason = currRowData.reason;
      this.shorttermsolution = currRowData.shorttermsolution;
      this.longtermsolution = currRowData.longtermsolution;
      this.project = currRowData.projectmoduleid;
      this.getApplicationdata("t37", "testcase");
      // this.getAssignedProjectData(currRowData.projectmoduleid);
      this.getAllocatedEMPByProjectModuel(currRowData.projectmoduleid);
      this.functionality = currRowData.functionality;
      this.imgm.task = currRowData.taskid;
      this.severity = currRowData.severity;
      this.priority = currRowData.priority;
      this.attachment = currRowData.attachment;
      this.attachmentview = currRowData.attachment;
      this.allocatedto = currRowData.allocatedto;
      this.status = currRowData.status;
      this.isAddState = true;
      if (flag == 0) {
        this.isedit = true;
        this.isRequestActive = true;
        this.mydetailscollapse();
      }
    },
    onSelect(e) {
      this.file = e.target.files[0];
    },
    async onUpload() {
      this.isLoading = true;
      if (this.file) {
        if (this.file.name !== "") {
          this.apiURL = "api/master/fileupload/uploadIncidentFileToS3";
          let formData = new FormData();
          formData.append("imagefile", this.file);
          formData.append("empcode", this.undt.userid);
          formData.append("moduletype", "incident");
          formData.imagefile = this.file;
          formData.empcode = this.undt.userid;
          formData.useremail = this.undt.username;
          formData.moduletype = "incident";
          await axios({
            "content-type": "multipart/form-data",
            method: "POST",
            url: this.apiURL,
            data: formData,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.status) {
              this.disblesavebtn = false;
              this.attachment = result.data.data;
              this.attachmentview = result.data.data;
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          });
        } else {
          Swal.fire({
            title: "Oops...!",
            text: "Select image/docs/videos",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.attachment = null;
        }
      } else {
        Swal.fire({
          title: "Oops...!",
          text: "Select image/docs/videos",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        this.attachment = null;
      }
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD-MM-YYYY");
    },
    dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm');
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },

   ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        incidentcode : this.search.incidentcode,
        projectname :  this.search.searchprojectname,
        functionality : this.search.searchFunctionality,
        taskname :  this.search.searchTask,
        incidentname :  this.search.searchIncidentname,
        severity :  this.search.searchSeverity,
        priority :  this.search.searchPriority,
        createdby : this.search.createdby,
        allocatedto : this.search.searchAllocated,
        status :this.search.searchStatus,
        empid: this.undt.userid,
        useremail: this.undt.username,
        incidenttype: this.search.searchincidenttype,
        userrole:this.userrole,
        ispagefeatures: this.pagefeatures.createdassigned ? true : false
      };  
      if(this.search.createddateto){
          var assignedate = new Date(this.search.createddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
      this.input.dateto = this.search.createddateto?assignedate:null 
      if(this.countSeverity){
        this.input.severity = this.countSeverity
      }
      if(this.countStatus){
        this.input.status = this.countStatus
      } 
      this.input.empcode = this.undt.userid 
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: `/api/incidents/list`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          let filename = "";
          this.download(result.data.data.rows,filename)
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.incidentmasterlist = [];
          this.totalcount = 0;
        }
        if (result.data.status) {
          this.isDetActive = true;
        } 
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
        var ShowLabel = 'incidentsReport_'+filename
        var JSONData = readExportdata
        var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        var CSV = ''
        if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
            row += index + ','
          }
          row = row.slice(0, -1)
          CSV = 'Sr#,Incident Code,Incident Name,Project Name,Project Module Name,Incident Date,Functionality,Severity,Priority,Downtime,Incident Status,Assigned to,Created by,Engineeringmaanager,Projectmaanager,Programmanager,Created date,Last Modified Date,Lastmodifiedby,Incidentaging,Ageing Bucket' + '\r\n'
        }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let incidentcode = index.hasOwnProperty('incidentcode') ? index.incidentcode == null||index.incidentcode.toLowerCase() == 'na'?'':index.incidentcode : '';
          let incidentname = index.hasOwnProperty('incidentname') ? index.incidentname.replace(/"/g, '""') : '';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename : '';
          let datetime = index.hasOwnProperty('datetime') ? index.datetime: '';
          let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null||index.functionlityname.toLowerCase() == 'na'?'':index.functionlityname : '';
          let severity = index.hasOwnProperty('severity') ? index.severity == null || index.severity.toLowerCase() == 'na' ?'':index.severity : '';
          let priority = index.hasOwnProperty('priority') ? index.priority == null||index.priority.toLowerCase() == 'na'?'':index.priority : '';
          let totaldowntime = index.hasOwnProperty('totaldowntime') ? index.totaldowntime.slice(0,-3) : '';
          let status = index.hasOwnProperty('status') ? index.status : '';
          let empname = index.hasOwnProperty('empname') ? index.empname : '';
          let createdby = index.hasOwnProperty('createdby') ? index.createdby.split("@")[0] : '';
          let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager : '';
          let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager : '';
          let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager : '';
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate : '';
          let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby : '';
          let incidentaging = index.hasOwnProperty('incidentaging') ? index.incidentaging : '';
          let ageingbucket = ''

            if(incidentaging >= 0 && incidentaging <=5){
              ageingbucket = '0-5d'
            }
            else if(incidentaging > 5 && incidentaging <=10){
              ageingbucket = '6-10d'
            }
            else if(incidentaging > 10 && incidentaging <= 20){
              ageingbucket = '11-20d'
            }
            else if(incidentaging > 20 && incidentaging <=30){
              ageingbucket = '21-30d'
            }
            else if(incidentaging > 30){
              ageingbucket = '30d+'
            }
          createddate = this.dateMONFormat(createddate);
          datetime = this.dateMONFormat(datetime);
          lastmodifieddate = this.dateMONFormat(lastmodifieddate);
            row += '"' + srno + '",',
            row += '"' + incidentcode + '",',
            row += '"' + incidentname + '",',
            row += '"' + projectname + '",',
            row += '"' + projectmodulename + '",',
            row += '"' + datetime + '",',
            row += '"' + functionlityname + '",',
            row += '"' + severity + '",',
            row += '"' + priority + '",',
            row += '"' + totaldowntime + '",',
            row += '"' + status + '",',
            row += '"' + empname + '",',
            row += '"' + createdby + '",',
            row += '"' + engineeringmaanager + '",',
            row += '"' + projectmaanager + '",',
            row += '"' + programmanager + '",',
            row += '"' + createddate + '",',
            row += '"' + lastmodifieddate + '",'
            row += '"' + lastmodifiedby + '",',
            row += '"' + incidentaging + '",',
            row += '"' + ageingbucket + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'IncidentListReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
        confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
  },
};
</script>

<style scoped>
.displayblk{
    display:none !important;
}
/* .bordertable td,.bordertable th{
  text-align: center;
  border: 1px solid black;

} */
.bordertable .row1{
  color:black;
}
/* .bordertable thead {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black 
} */
</style>
