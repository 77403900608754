<template>
       
    <div id="account-details-modern" class="content">

   <div class=" mx-0 common-hrms-min-heigh">
       <section class="card-body pt-1 pb-0 mobile_margin-px-0_00">
        <ul class="nav nav-tabs " role="tablist"> 
            
            <li class="nav-item">
                <a class="nav-link active px-sm-mobile-10" id="profile-tab" data-toggle="tab" href="#profile" aria-controls="profile" role="tab" aria-selected="true">Add & Manage Positions</a>
            </li>
            <li class="nav-item">
                <a class="nav-link  px-sm-mobile-10" id="home-tab" data-toggle="tab" href="#home" aria-controls="home" role="tab" aria-selected="flase">Job Details</a>
            </li>
            
            
        </ul>
        <div class="tab-content">
            <div class="tab-pane " id="home" aria-labelledby="home-tab" role="tabpanel">
                <div class="row mb-2">
                <div class="content-header col-6">
                    <h6 class="mb-0">Basic Job Details</h6>
                </div>
                <div class="w-auto ml-auto text-center mt-0 mb-0 px-1 pl-0">
                    <span class="required-field text-warning"> All fields marked with asterisk are required.</span>
                </div>
              </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="basicSelect">Select Company</label><span class="required-field"></span>
                            <treeselect placeholder="Select Company" v-model="jobdetail.companyname" class="companydata" :clear-on-select="true" :options="companydata" @select="selectcompany"/>
                            <div class="errorinputmsg" v-if="this.errors.jobdetail.companyname">{{ this.errors.jobdetail.companyname }}</div>
                        </div>
                    </div>            
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="basicSelect">Select Department</label><span class="required-field"></span>
                            <treeselect placeholder="Select Department" v-model="jobdetail.departmentname" class="department" :clear-on-select="true" :options="departmentdata" @select="selectdepartment" :disabled="disdepartment"/>
                            <div class="errorinputmsg" v-if="this.errors.jobdetail.departmentname">{{ this.errors.jobdetail.departmentname }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="basicSelect2">Select Designations</label><span class="required-field"></span>
                            <treeselect placeholder="Select Designations" v-model="jobdetail.designationname" class="designation" :clear-on-select="true" :options="designationdata" @select="selectdesignation"/>
                            <div class="errorinputmsg" v-if="this.errors.jobdetail.designationname">{{ this.errors.jobdetail.designationname }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="basicSelect3">Employee Type</label><span class="required-field"></span>
                            <treeselect placeholder="Select Employee Type" v-model="jobdetail.employeetypename" class="employeetype" :clear-on-select="true" :options="employeetypedata" @select="selectemptype"/>
                            <div class="errorinputmsg" v-if="this.errors.jobdetail.employeetypename">{{ this.errors.jobdetail.employeetypename }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label" for="vertical-email">View Grade</label>
                            <input type="grade" id="" v-model="jobdetail.grade" class="form-control" placeholder="A" aria-label="john.doe" @input="restrictSpecialChars(jobdetail.grade)" />
                            <div class="errorinputmsg" v-if="this.invalidInput">Invalide Input!</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label" for="vertical-email">Display Name On Careers/IJP/Refer </label>
                            <input type="grade" v-model="jobdetail.displaynamecareers" id="" class="form-control" placeholder="Will be the same as Job Title if left blank" aria-label="john.doe" @input="validSpecialChars(jobdetail.displaynamecareers)" />
                            <div class="errorinputmsg" v-if="this.invalidInput">Invalide Input!</div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-1 basic-select2">
                        <label> Job Location </label><span class="required-field"></span>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Job Location" :multiple="true" v-model="jobdetail.joblocation" :options="joblocationlist" :clear-on-select="true" @select="selectjoblocation"/>
                        <div class="errorinputmsg" v-if="this.errors.jobdetail.joblocation">{{ this.errors.jobdetail.joblocation }}</div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="form-label" for="vertical-email">Internal Job Code</label>
                            <input type="grade" v-model="jobdetail.internaljob" id="" class="form-control" placeholder="Internal Job Code" aria-label="john.doe" @input="validSpecialsChars(jobdetail.internaljob)" />
                            <div class="errorinputmsg" v-if="this.invalidInput">Invalide Input!</div>
                        </div>
                    </div>
                    <div class="col-md-4">                 
                        <div class="form-group">
                            <label class="w-100 d-block px-1"> &nbsp; </label>
                            <div class="custom-control custom-checkbox">
                            
                                <input type="checkbox" v-model="jobdetail.tagas_remote" class="custom-control-input" id="tagas_remote" />
                                <label class="custom-control-label" for="tagas_remote">Tag as Remote Job</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                    <div class="row">
                    <label class="w-100 d-block px-1" for="basicSelect3">Experience Range </label>
                    <div class="col-md-6">
                        <div class="form-group">
                        <treeselect placeholder="Exp From" class="projectlistdd" v-model="jobdetail.expfrom" :options="experiencerangefromlist" @select="selectexpfrom"/>
                        <div class="errorinputmsg" v-if="errors.jobdetail.expfrom">{{ errors.jobdetail.expfrom }}</div>
                       </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                        <treeselect placeholder="Exp To" class="projectlistdd" v-model="jobdetail.expto" :options="experiencerangetolist" @select="selectexpto"/>
                        <div class="errorinputmsg" v-if="errors.jobdetail.expto">{{ errors.jobdetail.expto }}</div>
                       </div>
                      </div>
                    </div>
                    </div>
                </div>
                <div class="container pt-2 px-0">
                    <div class="row">
                        <div class="col-md-8 px-0">
                            <div class="container text-editar_discription_jobs">
                            <div class="d-flex justify-content-between align-items-center">
                            <ul class="nav nav-pills nav-pill-warning " role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="job-discri-tab" data-toggle="tab" href="#job-discri" aria-controls="job-discri" role="tab" aria-selected="true">Job Description</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="filled_ssuggest" data-toggle="tab" href="#filled_ssuggest-tab" aria-controls="filled_ssuggest-tab" role="tab" aria-selected="false">Filled Suggested JD</a>
                                </li>

                            </ul>

                        </div>
                            <div class="tab-content">
                                <div class="tab-pane active" id="job-discri" aria-labelledby="job-discri-tab" role="tabpanel">
                                    <div class="container px-0">
                                        <div class="form-group">
                                        <label class="form-label">Job Description </label>
                                        <ckeditor :editor="editor" cols="80" rows="15" placeholder="Please Enter Job Description" v-model="jobdetail.description" :config="editorConfig" tag-name="textarea"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="filled_ssuggest-tab" aria-labelledby="filled_ssuggest" role="tabpanel">
                                    <div class="form-group">
                                    <label class="form-label">Comment  </label>
                                    <ckeditor :editor="editor" placeholder="Comment" v-model="jobdetail.comment" :config="editorConfig" tag-name="textarea"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="col-md-4"> 
                        <div class="container description_bg_detail_job">
                            <h5 class="card-text"> Write a Compelling job description</h5>
                            <p> Include general job resposibilitys. required job skills, and required soft skills.
                            </p>
                            <h5> Use builleted lists</h5>
                            <p> Allow job seekers to quickly read informaion in builled descriptions.</p>
                            <h5> Insert text carefully</h5>
                            <p> Use a flat text editor (Such as text edit or word pan)to copy and past text avoid using mirosoft work (since it contens hidden charactes) </p>
                            <h5> Avoid liks</h5>
                            <p> Links will get your job posting caught in spam filters on all job Board</p>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="buttons_hrms_view text-right px-0 mt-1">
                    <button type="reset" class="btn btn-relief-secondary ml-1" @click="resetRecords()">
                        <span>Cancel</span>
                    </button>
                   
                    <button class="btn btn-relief-success btn-next15 ml-1" @click="savewizard('t56',$event,'0')" id="submitbtnt42">
                        <span class="align-middle d-sm-inline-block d-none">Save as Draft</span>
                        <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                    </button>
                    <button class="btn btn-relief-primary  btn-next15 ml-1" @click="savewizard('t56',$event,'1')">
                        <span class="align-middle d-sm-inline-block d-none">Save & Continue</span>
                        <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0 btn12"></em>
                    </button>
                </div>
            </div>
            <div class="tab-pane active"  id="profile" aria-labelledby="profile-tab" role="tabpanel">
                <!-- <div class="card-body statistics-body  mb-0 radius-1 jobDetails_padding-5 ">
                    <div class="row mx-0">
                        <div class="col-6  col-sm-4 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                            <div class="p-1 border rounded mobile_card_job_details_5">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-1">
                                    <div class="avatar-content">
                                    <em data-feather='briefcase' class="avatar-icon"></em>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0"> 12</h4>
                                    <p class="card-text font-small-3 mb-0"> Total Positions</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-6  col-sm-4 col-md-4 col-lg-2  mb-1 card_job_details_card_10">
                            <div class="p-1 border rounded mobile_card_job_details_5">
                            <div class="media">
                                <div class="avatar bg-light-info mr-1">
                                    <div class="avatar-content">
                                    <em data-feather='airplay' class="avatar-icon"></em>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0"> 0</h4>
                                    <p class="card-text font-small-3 mb-0"> Selected/Filled</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-6  col-sm-4 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                            <div class="p-1 border rounded mobile_card_job_details_5">
                            <div class="media">
                                <div class="avatar bg-light-danger mr-1">
                                    <div class="avatar-content">
                                        <em data-feather='file-text' class="avatar-icon"></em>

                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">0</h4>
                                    <p class="card-text font-small-3 mb-0"> Open Positions</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-6  col-sm-4 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                            <div class="p-1 border rounded mobile_card_job_details_5">
                            <div class="media">
                                <div class="avatar bg-light-success mr-1">
                                    <div class="avatar-content">
                                        <em data-feather='pause-circle' class="avatar-icon"></em>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">06</h4>
                                    <p class="card-text font-small-3 mb-0"> Hold Positions</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-6  col-sm-4 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                        <div class="p-1 border rounded mobile_card_job_details_5">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-1">
                                    <div class="avatar-content">
                                        <em data-feather='file-text' class="avatar-icon"></em>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0"> 0</h4>
                                    <p class="card-text font-small-3 mb-0"> Draft Positions</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-6  col-sm-4 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                            <div class="p-1 border rounded mobile_card_job_details_5">
                            <div class="media">
                                <div class="avatar bg-light-warning mr-1">
                                    <div class="avatar-content">
                                        <em data-feather='folder' class="avatar-icon"></em>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">10</h4>
                                    <p class="card-text font-small-3 mb-0">Auto Archived</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    </div>
                </div> -->
            <div class="container px-0 mobile_tabbing_bottom-border">
                <div class="d-flex justify-content-between align-items-center hrms_tabbing_design_view mb-1 mt-1">
                <ul class="nav nav-pills nav-pill-warning pt-1 mb-0 mobile_tabbing_change_order-2" role="tablist">

                    <li class="nav-item">
                        <a class="nav-link active" id="jobdetails-tab-list" data-toggle="tab" href="#joblist" aria-controls="joblist" role="tab" aria-selected="true">Job Details List</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" id="home-tab-job" data-toggle="tab" href="#homeJob" aria-controls="homeJob" role="tab" aria-selected="false">Positions Details</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="profile-tab-job" data-toggle="tab" href="#profileJob" aria-controls="profileJob" role="tab" aria-selected="false">Positions Status</a>
                    </li> -->
                   
                </ul>
                <!-- <div class="addManagePositis align-items-center ml-auto mobile_tabbing_change_order-1"> 
                   <div class="buttons_hrms_view demo-inline-spacing">
                        <button type="button" class="btn btn-flat-dark waves-effect hrms-bt-mr-5 mt-0 mobile_margin-px-0">Bulk Action </button>
                        <button type="button" class="btn btn-flat-primary waves-effect hrms-bt-mr-5 mt-0 mobile_margin-px-0"> <em data-feather='plus'></em> Raise Requisition</button>
                        <button type="button" class="btn btn-flat-primary waves-effect hrms-bt-mr-5 mt-0 mr-0 mobile_margin-px-0">Link Requisiton</button>
                    </div>
                </div> -->
            </div>
                <div class="tab-content">
                    
                    <div class="tab-pane active" id="joblist" aria-labelledby="jobdetails-tab-list" role="tab">
                        <div class="table-responsive">
                            <table class="table table-striped all_table_stickys" aria-describedby="mydesc" style="width: 100%;">
                                <thead>
                                    <tr>
                                        <th class="text-center align-middle first-col-sticky">Sr.No</th>
                                        <th scope="col" class="text-center align-middle "><em data-feather='settings'></em></th>
                                        <th scope="col" class="text-center align-middle">Job Detail Code </th>
                                        <th scope="col" class="text-left align-middle" style="min-width: 180px;">Company</th>
                                        <th scope="col" class="text-left align-middle">Department</th>
                                        <th scope="col" class="text-left align-middle" style="min-width: 180px;">Designation</th>
                                        <th scope="col" class="text-left align-middle">Employee Type</th>
                                        <th scope="col" class="text-center align-middle" style="min-width: 140px;">Job Location</th>
                                        <th scope="col" class="text-left align-middle">Created By </th>
                                        <th scope="col" class="text-left align-middle" style="min-width: 180px;">Created Date </th>
                                        <th scope="col" class="text-center align-middle">Additional info</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data,index) in jddatalist" v-bind:key="index">
                                        <td class="text-center font-weight-normal first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                        <td class="font-weight-normal ">
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                </button>
                                                <div class="dropdown-menu" v-if="data.isactive">
                                                    <a class="dropdown-item devEditEle" @click="redirecttourl('raisrequisition',data)" style="cursor: pointer;" href="javascript:void(0);">
                                                        <div class="d-flex align-items-center">
                                                            <plus-circle-icon size="1.5x" class="custom-class mr-50"></plus-circle-icon>
                                                            <span>Raise Requisition</span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center font-weight-normal sitebluecolor" style="cursor: pointer" @click="redirecttourl('jdrequisition',data)">{{ data.jdcode?data.jdcode:'NA' }}</td>
                                        <td class="text-left capitalisetext font-weight-normal"> {{ data.companyname }}</td>
                                        <td class="text-left capitalisetext font-weight-normal"> {{ data.departmentname }}</td>
                                        <td class="text-left capitalisetext font-weight-normal"> {{ data.designiation }}</td>
                                        <td class="text-left capitalisetext font-weight-normal"> {{ data.employeetypename }}</td>
                                        <td class="text-left capitalisetext font-weight-normal"> 
                                            <span class="font-weight-bold" v-html="showmappingname(data.joblocation)"></span>
                                        </td>
                                        <td class="text-left capitalisetext font-weight-normal"> {{ data.createdby }}</td>
                                        <td class="text-left font-weight-normal"> {{ dateMonthFormat(data.createddate) }}</td>
                                        <td class="text-center font-weight-normal"> NA </td>
                                    </tr>
                                    <template v-if="jddatalist.length ==0">
                                        <tr><td colspan="14" class="font-weight-normal" style="text-align: center;">No records found</td></tr>
                                    </template>
                               </tbody>
                            </table>
                        </div>
                        <div class="pageCenter text-center mt-2">
                            <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                        </div>
                    </div>

                    <div class="tab-pane" id="homeJob" aria-labelledby="home-tab-job" role="tab">
                        <div class="table-responsive">
                                <table class="table table-striped all_table_stickys" aria-describedby="mydesc">
                                    <thead>
                                        <tr>
                                            <th scope="col"  class="text-center first-col-sticky"><em data-feather='settings'></em></th>
                                            <th scope="col"  class="text-left align-middle second-col-sticky">Requisition ID </th>
                                            <th scope="col"  class="text-left align-middle ">Position No</th>
                                            <th scope="col"  class="text-left align-middle ">Position ID</th>
                                            <th scope="col"  class="text-left align-middle ">Status</th>
                                            <th scope="col"  class="text-left align-middle ">Funcational Area </th>
                                            <th scope="col"  class="text-center align-middle ">Recruiter</th>
                                            <th scope="col"  class="text-center align-middle ">Additional info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td  class="font-weight-normal first-col-sticky">
                                            
                                            <span class="font-weight-bold">Angular Project</span>
                                        </td>
                                        <td class="font-weight-normal second-col-sticky">Peter Charls</td>
                                        <td class="font-weight-normal">Peter Charls</td>
                                        <td class="font-weight-normal">Peter Charls</td>
                                        <td class="font-weight-normal">Peter Charls</td>
                                        <td class="font-weight-normal">
                                           Peter Charls
                                        </td>
                                        <td class="font-weight-normal"><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                                        <td class="font-weight-normal">
                                            <div class="dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown">
                                                    <em data-feather="more-vertical"></em>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <em data-feather="edit-2" class="mr-50"></em>
                                                        <span>Edit</span>
                                                    </a>
                                                    <a class="dropdown-item" href="javascript:void(0);">
                                                        <em data-feather="trash" class="mr-50"></em>
                                                        <span>Delete</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                    </div>

                    <div class="tab-pane" id="profileJob" aria-labelledby="profile-tab-job" role="tab">
                        <p>
                            Dragée jujubes caramels tootsie roll gummies gummies icing bonbon. Candy jujubes cake cotton candy.
                            Jelly-o lollipop oat cake marshmallow fruitcake candy canes toffee. Jelly oat cake pudding jelly beans
                            brownie lemon drops ice cream halvah muffin. Brownie candy tiramisu macaroon tootsie roll danish.
                        </p>
                        <p>
                            Croissant pie cheesecake sweet roll. Gummi bears cotton candy tart jelly-o caramels apple pie jelly
                            danish marshmallow. Icing caramels lollipop topping. Bear claw powder sesame snaps.
                        </p>
                    </div>

                </div>
            </div>
            </div>
            <div class="tab-pane" id="disabled" aria-labelledby="disabled-tab" role="tabpanel">
                <p>
                    Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit
                    tart croissant. Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.
                </p>
            </div>
           
        </div>
    </section>  
       
   </div>
</div>
</template>

<script>
import axios from 'axios'
  import apiUrl from '../../../constants';
  import moment from 'moment'
 import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
import {  MoreVerticalIcon,XIcon, PlusCircleIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../../utils/commonMethods';
   export default {
     name:'JobDetails',
     components: {
        DatePicker,
        ckeditor: CKEditor.component,
        Treeselect,VueElementLoading,Loading,Pagination,MoreVerticalIcon,XIcon, PlusCircleIcon
     },
     mixins: [ commonMethods ],
     data() {
     return {
        defaultValue: new Date(),
        isLoading: false,
        departmentlist: [],
        companymasterlist: [],
        designationlist: [],
        departmentdata:[],
        companydata:[],
        designationdata:[],
        employeetypedata:[],
        citydata:[],
        fullPage: true,
        dateofJoingDate: true,
        offerExpDate: true,
        generate_discription:true,
        discription:{
           comment:null,
        },
        job_discription:{
           comment:null,
        },
        invalidInput: false,
        editor: ClassicEditor,
        editorConfig: {
            toolbar: {
                items: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "insertTable",
                    "|",
                    "undo",
                    "redo",
                    "|",
                ],
            },
        },
        jobdetail: {
            companyname: null,
            departmentname: null,
            designationname: null,
            employeetypename: null,
            joblocation: null,
            grade: null,
            displaynamecareers: null,
            internaljob: null,
            tagas_remote: null,
            description: null,
            comment: null,
            expfrom: null,
            expto: null
        },
        experiencerangefromlist: [
            {id:'0',label:'0'},
            {id:'1',label:'1'},
            {id:'2',label:'2'},
            {id:'3',label:'3'},
            {id:'4',label:'4'},
            {id:'5',label:'5'},
            {id:'6',label:'6'},
            {id:'7',label:'7'},
            {id:'8',label:'8'},
            {id:'9',label:'9'},
            {id:'10',label:'10'},
            {id:'11',label:'11'},
            {id:'12',label:'12'},
            {id:'13',label:'13'},
            {id:'14',label:'14'},
            {id:'15',label:'15'},
            {id:'16',label:'16'},
            {id:'17',label:'17'},
            {id:'18',label:'18'},
            {id:'19',label:'19'},
            {id:'20',label:'20'}
        ],
        experiencerangetolist: [
            {id:'0',label:'0'},
            {id:'1',label:'1'},
            {id:'2',label:'2'},
            {id:'3',label:'3'},
            {id:'4',label:'4'},
            {id:'5',label:'5'},
            {id:'6',label:'6'},
            {id:'7',label:'7'},
            {id:'8',label:'8'},
            {id:'9',label:'9'},
            {id:'10',label:'10'},
            {id:'11',label:'11'},
            {id:'12',label:'12'},
            {id:'13',label:'13'},
            {id:'14',label:'14'},
            {id:'15',label:'15'},
            {id:'16',label:'16'},
            {id:'17',label:'17'},
            {id:'18',label:'18'},
            {id:'19',label:'19'},
            {id:'20',label:'20'}
        ],
        jddatalist: [],
        fullPage: true,
        card_content: "card-content",
        collapse: "collapse",
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        joblocationlist: [],
        errors:{
            jobdetail:{
                companyname: null,
                departmentname: null,
                designationname: null,
                employeetypename: null,
                joblocation: null,
                expfrom: null,
                expto: null
            }
        },
        disdepartment: false
     }
   },
   emits:['setGlobaljdid'],
   props:['globaljdid'],
   mounted(){

    $(function () {
            'use strict';
            var bsStepper = document.querySelectorAll('.bs-stepper'),
                select = $('.select2'),
                modernWizard = document.querySelector('.modern-wizard-example')
            // select2
            select.each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                placeholder: 'Select value',
                dropdownParent: $this.parent()
                });
            });
            let step1 = -1;
            if (typeof modernWizard !== undefined && modernWizard !== null) {
                var modernStepper = new Stepper(modernWizard, {
                linear: false
                });
                step1 = modernStepper._currentIndex;
                $(modernWizard)
                .find('.btn-next15')
                .each(function () {
                   $(this).on('click', function (e) {
                    if(step1==0)
                    {
                        modernStepper._currentIndex=1
                    }
                    if(step1==1)
                    {
                        modernStepper._currentIndex=2
                    }
                    if(step1==2)
                    {
                        modernStepper._currentIndex=3
                    }
                    if(step1==3)
                    {
                        modernStepper._currentIndex=4
                    }
                    if(step1==4)
                    {
                        modernStepper._currentIndex=0
                    }
                    modernStepper._currentIndex=0
                    modernStepper.next();
                   })
                });
            }
        });


     let userdata = window.localStorage.getItem('userdata');
     if(userdata){
        userdata = JSON.parse(userdata)
        this.undt=userdata
        this.loginusername=userdata.username
        this.loginuscondition = userdata.userid
        this.tokendata = window.localStorage.getItem('token');
        this.userrole = window.localStorage.getItem("userrole")
        this.roleid = this.undt.roleid
        if(this.roleid && parseInt(this.roleid) === 520) {
            this.isThisBusinessRole = true
        }
           this.getlocationlist();
           this.getDepartmentdata();
           this.getEmployeeTypedata();
           this.getCitydata();
           this.getCompanydata();
           this.getjobdetalislist();
        }
   },
  methods:{
    mysetGlobaljdid(jdid){
        this.$emit('setGlobaljdid',jdid);
    },
    selectcompany(state){
        this.jobdetail.companyname = null;
        this.jobdetail.companyname = state.id;
        if(!state){
            this.errors.jobdetail.companyname = 'Company name is required';
        }else{
            this.errors.jobdetail.companyname = '';
        }
    },
    selectdepartment(state){
        this.jobdetail.departmentname = null;
        this.jobdetail.departmentname = state.id;
        if(!state){
            this.errors.jobdetail.departmentname = 'Department name is required';
            this.jobdetail.designationname = null;
            this.designationdata = [];
        }else{
            this.errors.jobdetail.departmentname = '';
            this.getDesignationdata(state.id);
        }
    },
    selectdesignation(state){
        this.jobdetail.designationname = null;
        this.jobdetail.designationname = state.id;
        if(!state){
            this.errors.jobdetail.designationname = 'Designation name is required';
        }else{
            this.errors.jobdetail.designationname = '';
        }
    },
    selectemptype(state){
        this.jobdetail.employeetypename = null;
        this.jobdetail.employeetypename = state.id;
        if(!state){
            this.errors.jobdetail.employeetypename = 'Employee type is required';
        }else{
            this.errors.jobdetail.employeetypename = '';
        }
    },
    selectjoblocation(state){
        this.jobdetail.joblocation = null;
        this.jobdetail.joblocation = state.id;
        if(!state){
            this.errors.jobdetail.joblocation = 'Job location is required';
        }else{
            this.errors.jobdetail.joblocation = '';
        }
    },
    getDepartmentdata(){
       this.input = {
            useremail  : this.undt.username,
            empcode: this.undt.userid
       }
        axios({
        'method': 'POST',
        'url': 'api/hrms/gethrdepartmentlist',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.departmentdata = result.data.data.rows;
            // let decryptedDepartmentList = this.departmentdata.map(item => {
            // item.label = this.decryptText(item.label);
            // return item;
            // });
            // this.departmentdata.sort((a, b) => {
            //     return a.label.localeCompare(b.label);
            // });
            if(this.departmentdata && this.departmentdata.length >1){
                this.disdepartment =false;
            }else if(this.departmentdata) {
                this.jobdetail.departmentname = parseInt(this.departmentdata[0].id);
                this.getDesignationdata(this.jobdetail.departmentname)
                this.disdepartment =true;
            }
          } else {
            this.departmentdata = [];
          }
        }).catch(e => {
            this.displayError(e)
        });
    },
     getDesignationdata(deptid){
       this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          deptid: deptid
       }
        axios({
        'method': 'POST',
        'url': 'api/designationmaster/getdesigniationlist',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
         .then(result => {
            this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.designationdata = result.data.data.rows;
            // const decryptedDepartmentList = this.designationdata.map(item => {
            // item.label = this.decryptText(item.label);
            // return item;
            // });
            // this.designationdata.sort((a, b) => {
            //     return a.label.localeCompare(b.label);
            // });
          } else {
            this.designationdata = [];
          }
        }).catch(e => {
            this.displayError(e)
        })
    },
     getEmployeeTypedata(){
       this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid
       }
        axios({
        'method': 'POST',
        'url': 'api/employeetypemaster/getemployeetypelist',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
         .then(result => {
            this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.employeetypedata = result.data.data.rows;
          } else {
            this.employeetypedata = [];
          }
        }).catch(e => {
                this.displayError(e)
        });
    },
     getCitydata(){
       this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid
       }
        axios({
        'method': 'POST',
        'url': 'api/citymaster/getcitylist',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
         .then(result => {
            this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.citydata = result.data.data.rows;
          } else {
            this.citydata = [];
          }
        }).catch(e => {
            this.displayError(e)
        });
    },
    getCompanydata(){
         this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid
       }
        axios({
        'method': 'POST',
        'url': 'api/companymaster/getcompanylist',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
         .then(result => {
            this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.companydata = result.data.data.rows;
          } else {
            this.companydata = [];
          }
        }).catch(e => {
                    this.displayError(e)
        });
  },
  restrictSpecialChars(inputval) {
        if (/[^A-Za-z0-9\s]/.test(this.inputval)) {
        this.invalidInput = true;
        } else {
        this.invalidInput = false;
        }
        this.jobdetail.grade = this.jobdetail.grade.replace(/[^A-Za-z0-9\s]/g, "");
    },
    validSpecialChars(inputval) {
        if (/[^A-Za-z0-9\s]/.test(this.inputval)) {
        this.invalidInput = true;
        } else {
        this.invalidInput = false;
        }
        this.jobdetail.displaynamecareers = this.jobdetail.displaynamecareers.replace(/[^A-Za-z0-9\s]/g, "");
    },
     validSpecialsChars(inputval) {
        if (/[^A-Za-z0-9\s]/.test(this.inputval)) {
        this.invalidInput = true;
        } else {
        this.invalidInput = false;
        }
        this.jobdetail.internaljob = this.jobdetail.internaljob.replace(/[^A-Za-z0-9\s]/g, "");
    },    
  savewizard: function(mdl,objThis,isactive){
        let valid = this.validateForm(objThis);
        if (valid) {
            this.isLoading = true;
            let tblNm='t56'
            let currentstate=1;
            let apiURL = '';
            if(mdl) tblNm=mdl
            this.input = {
                tbl: tblNm,
                section: 'hrms',
                createdby: this.undt.username,
                empid: this.undt.userid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                userrole:this.userrole,
                currentstate:currentstate,
                isactive:isactive
            }
            if (this.globaljdid && this.globaljdid !=null) {
                this.input.jdid = this.globaljdid
                apiURL='api/hrms/updatedJobDetail'
            }else{
                apiURL='api/hrms/creategobdetail'
            }
            this.input.companyname=this.jobdetail.companyname
            this.input.departmentname=this.jobdetail.departmentname            
            this.input.designationname=this.jobdetail.designationname
            this.input.employeetypename = this.jobdetail.employeetypename
            this.input.grade=this.jobdetail.grade
            this.input.displaynamecareers=this.jobdetail.displaynamecareers
            this.input.joblocation=this.jobdetail.joblocation
            this.input.internaljob=this.jobdetail.internaljob
            this.input.tagas_remote= '1' //this.jobdetail.tagas_remote
            this.input.expfrom=this.jobdetail.expfrom
            this.input.expto=this.jobdetail.expto
            this.input.description=this.jobdetail.description
            this.input.comment = this.jobdetail.comment
            this.input.moduletype='jobdetail'
            this.isAddState=false
            this.input.useremail= this.undt.username
            this.input.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;     
                if(result.data.errorCode == 0){     
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    
                    this.mysetGlobaljdid(result.data.data.jdid);
                    this.getjobdetalislist();
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
                 this.displayError(e)
                })
        }
    },
    validateForm: function(objThis){
        this.isAddState=false
        let isValid=true;
        if (!this.jobdetail.companyname) {
            this.errors.jobdetail.companyname = 'Company name is required';
            isValid=false;
        }
        if (!this.jobdetail.departmentname) {
            this.errors.jobdetail.departmentname = 'Department name is required';
            isValid=false;
        }
        if (!this.jobdetail.designationname) {
            this.errors.jobdetail.designationname = 'Designation name is required';
            isValid=false;
        }
        if (!this.jobdetail.employeetypename) {
            this.errors.jobdetail.employeetypename = 'Employee Type is required';
            isValid=false;
        }
        if (!this.jobdetail.joblocation || (this.jobdetail.joblocation && this.jobdetail.joblocation.length==0)) {
            this.errors.jobdetail.joblocation = 'Job location is required';
            isValid=false;
        }
        if(this.jobdetail.expfrom && !this.jobdetail.expto){
            this.errors.jobdetail.expto = 'Exp To required';
            isValid=false;
        }else if(this.jobdetail.expto && !this.jobdetail.expfrom){
            this.errors.jobdetail.expfrom = 'Exp From required';
            isValid=false;
        }
        if((this.jobdetail.expfrom && this.jobdetail.expfrom!=null) && (this.jobdetail.expto && this.jobdetail.expfrom !=null)){
            isValid = this.validateDate();
        }
        return isValid
    },
    getjobdetalislist(objThis){
        if(objThis) {
            localStorage.setItem('searchItems', JSON.stringify(objThis))
        }
        this.isLoading = true
        let search = JSON.parse(localStorage.getItem('searchItems'))
        this.input={
            useremail  : this.undt.username,
            empcode: this.undt.userid,
            limit: apiUrl.LIMIT,
            offset:this.pageoffset,
        }
        axios({
            'method': 'POST',
            'url': 'api/hrms/getjobdetalislist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
            this.isLoading = false             
            if(result.data.errorCode == 0){
                this.jddatalist = result.data.data.rows
                this.totalcount = result.data.data.count;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            }else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            } else {
                this.jddatalist= []
                this.totalcount = 0;
                this.pageCount = 0;
            }
        }).catch(e => {
            this.displayError(e)
        });
    },
    clickCallback: function (pageNum) {
        this.pageoffset = pageNum;
        this.page = pageNum;
        this.currPageNum = pageNum - 1;
        this.getjobdetalislist();
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#joblist").offset().top -70
            });
        }, 500);
    },
    dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    showmappingname(mappingname){
        let responsestr='';
        let prlist=mappingname.split(',')
        if(prlist.length > 1){
            for(var i=0;i<prlist.length;i++){
                responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
            }
        }else{
            for(var i=0;i<prlist.length;i++){
                responsestr +=prlist[i]
            }
        }
        return responsestr
    },
    getlocationlist() {
        let url = 'api/countrymaster/getcountrylist'
        axios({
            method: 'POST',
            url,
            data:{useremail: this.undt.username,empcode: this.undt.userid},
            headers: { authorization: this.tokendata }
        }).then(result => {
            if(result.data.errorCode == 0) {
                this.joblocationlist = result.data.data.rows
            }else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            } else {
                this.joblocationlist = []
            }
        }).catch(e => {
                    this.displayError(e)
        });
    },
    selectexpfrom(state){
        this.jobdetail.expfrom =null;
        this.jobdetail.expfrom =state.id;
        if(state && state !=null){
            this.errors.jobdetail.expfrom ='';
        }
    },
    selectexpto(state){
        this.jobdetail.expto =null;
        this.jobdetail.expto =state.id;
        if(state && state !=null){
            this.errors.jobdetail.expto ='';
        }
    },
    validateDate() {
        let tempvalid = true;
        if ((parseInt(this.jobdetail.expto) < parseInt(this.jobdetail.expfrom)) && this.jobdetail.expfrom !=null) {
            tempvalid = false;
            this.jobdetail.expto = null;
            this.errors.jobdetail.expfrom ='';
            this.errors.jobdetail.expto = "Exp To should be greater than Exp From";
        }
        if ((parseInt(this.jobdetail.expto) < parseInt(this.jobdetail.expfrom)) && this.jobdetail.expto !=null) {
            tempvalid = false;
            this.jobdetail.expfrom = null;
            this.errors.jobdetail.expto = '';
            this.errors.jobdetail.expfrom = "Exp From should be lesser than Exp To";
        }
        return tempvalid;
    },
    resetRecords() {
        this.jobdetail.companyname =null;
        this.jobdetail.departmentname =null;
        this.jobdetail.designationname =null;
        this.jobdetail.employeetypename =null;
        this.jobdetail.joblocation = null;
        this.jobdetail.grade =null;
        this.jobdetail.displaynamecareers =null;
        this.jobdetail.internaljob =null;
        this.jobdetail.tagas_remote =null;
        this.jobdetail.comment ='';
        this.jobdetail.expfrom =null;
        this.jobdetail.expto =null;
        this.jobdetail.description ='';
        this.errors.jobdetail.companyname = '';
        this.errors.jobdetail.departmentname = null;
        this.errors.jobdetail.designationname = null;
        this.errors.jobdetail.employeetypename = null;
        this.errors.jobdetail.joblocation = null;
        this.errors.jobdetail.expfrom = null;
        this.errors.jobdetail.expto = null;
    },
    redirecttourl:function(btnstate,rowdata){
        rowdata.backbtnstate = btnstate;
        if(btnstate=="raisrequisition"){
            this.$router.push({ 
                name:`RaiseRequisition`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        }else if(btnstate=="jdrequisition"){
            this.$router.push({ 
                name: 'requisition',
                params: {
                    backbtnstate:btnstate,
                    clickeddata: rowdata
                }
            })
        }
    }
  }
};
</script>
