<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h3 class="content-header-title float-left mb-1">MOM</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Create MOM</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
            <div style="margin-top:10px;">
              <div class="card-body">
                <form class="form form-vertical">
                  <div class="row">
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Project Name</label>
                          <treeselect  v-if="mom.projectid == null" placeholder="Enter min 4chars to search project name" v-on:search-change="projectChange" :options="mom.projectlist" @input="selprojdata" v-model="mom.projectid" @close="validateForm($event)" />
                          <treeselect type="text" v-else :options="mom.projectlist" v-model="mom.projectid"/>
                          <div class="errorinputmsg" v-if="this.errors.mom.projectid">{{ this.errors.mom.projectid }}</div>
                          </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Project Module</label>
                          <treeselect  placeholder="Select project module" :options="mom.projectmodulelist" @input="selprojmoduledata" v-model="mom.projectmoduleid" @close="validateForm($event)" />
                          <div class="errorinputmsg" v-if="this.errors.mom.projectmoduleid">{{ this.errors.mom.projectmoduleid }}</div>
                          </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group ">
                              <label for="location">Location</label>
                              <input type="text" id="Enter location" class="form-control" name="location" placeholder="Enter location" v-model="mom.location"  v-bind:class="{'form-control': true,'is-invalid': !validText(mom.location) && locationBlured, }"
                              v-on:blur="locationBlured = true"/>
                              <div class="invalid-feedback">Location required</div>
                          </div>
                      </div>
                      <div class="col-sm-12">
                      <div class="form-group">
                          <div class="form-group">
                              <label class="form-label" for="project-name">Meeting Objective</label>
                              <ckeditor :editor="editor" placeholder="Enter Meeting Objective" v-model="mom.objective" :config="editorConfig" tag-name="textarea" v-bind:class="{  'form-control': true,
                              'is-invalid': !validText(mom.objective) && objectiveBlured, }"
                              v-on:blur="objectiveBlured = true" />
                              <div class="invalid-feedback">Meeting Objective required</div>                                           
                          </div>
                      </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Meeting Facilitator</label>
                          <treeselect  class="facilitator capitalisetext" placeholder="Select Meeting Facilitator" v-model="mom.facilitator" :options="mom.facilitatorlist" @input="selfacilitatorto" @close="validateForm($event)" />
                          <div class="errorinputmsg" v-if="this.errors.mom.facilitator">{{ this.errors.mom.facilitator }}</div>
  
                          </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group">
                          <label for="projectmasterdd">Business Analyst</label>
                          <treeselect  placeholder="Select Business Analyst" v-model="mom.businesanayst" :options="mom.businesanalystlist" @input="selbusinesanayst" @close="validateForm($event)"/>
                          <div class="errorinputmsg" v-if="this.errors.mom.businesanayst">{{ this.errors.mom.businesanayst }}</div>
                          </div>
                      </div>
                      <div class="col-md-4 mb-1">
                          <div class="form-group datePicker">
                          <label for="" class="d-block">Date of Meeting</label>
                              <date-picker placeholder="Select Date of Meeting" format="YYYY-MM-DD" v-model="mom.meetingdate" valueType="format" class="startdate" id="StartDate" @close="validateForm($event)"></date-picker>
                              <div class="errorinputmsg" v-if="this.errors.mom.meetingdate">{{ this.errors.mom.meetingdate }}</div>
                          </div>
                      </div>
                      <div class="form-group col-md-3">
                          <div class="form-group mg-bot0">
                          <label class="form-label" for="spendtime">Start Time</label>
                          <div id="spendtime">
                              <vue-timepicker v-model="mom.starttime" type="time"  format="HH:mm" @close="validateForm($event)"></vue-timepicker>
                              <div class="errorinputmsg" v-if="this.errors.mom.starttime">{{ this.errors.mom.starttime }}</div>
                          </div>
                          </div>
                      </div>
                      <div class="form-group col-md-3">
                          <div class="form-group mg-bot0">
                          <label class="form-label" for="spendtime">End Time</label>
                          <div id="spendtime">
                              <vue-timepicker type="time" format="HH:mm" v-model="mom.endtime"  @close="validateForm($event)"></vue-timepicker>
                              <div class="errorinputmsg" v-if="this.errors.mom.endtime">{{ this.errors.mom.endtime }}</div>
                          </div>
                          </div>
                      </div>  
                  </div>

                  <fieldset class="fieldset_border_vi mb-1 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Meeting Agenda</span> 
                    </legend>
                      <div class="card mb-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m1, index) in mom.formdata">
                                  <div v-bind:key="index" class="card border-warning mb-1">
                                    <div v-bind:class="[{show: m1.showform},card_content,collapse]">
                                      <div class="card-body p-1">
                                        <form>
                                          <div class="row">
                                            <div class="form-group col-md-3">
                                              <label for="topic">Topic</label>
                                              <input type="text" id="topic" class="form-control" placeholder="Enter topic" v-model="mom.formdata[index].topic" />
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Owner</label>
                                              <treeselect placeholder="Select owner" class="projectlistdd" v-model="mom.formdata[index].owner" :options="mom.createdbylist" @input="selOwner" @close="validateForm($event)"/>
                                              
                                            </div>
                                            <div class="form-group col-md-3">
                                              <div class="form-group mg-bot0">
                                                <label class="form-label" for="spendtime">Time</label>
                                                <div id="spendtime">
                                                    <vue-timepicker type="time" format="HH:mm" v-model="mom.formdata[index].time"></vue-timepicker>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                              <div class="avatar bg-success rounded ml-1">
                                                  <div class="avatar-content " @click="addAgendaForm()" style="cursor: pointer;">
                                                      <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                  </div>
                                              </div>
                                              <div v-if="index !== 0" class="avatar bg-danger rounded ml-1">
                                                  <div class="avatar-content" @click="deleteField(index)" style="cursor: pointer;">
                                                      <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
                  <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Attendees</span> 
                    </legend>
                      <div class="card mb-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m2, index) in mom.formdata1">
                                  <div v-bind:key="index" class="card border-warning mb-1">
                                    <div v-bind:class="[{show: m2.showform},card_content,collapse]">
                                      <div class="card-body p-1">
                                        <form>
                                          <div class="row">
                                            <div class="form-group col-md-3">
                                              <label for="owner">User</label>
                                              <treeselect placeholder="Select user" class="projectlistdd" v-model="mom.formdata1[index].user" :options="mom.createdbylist" @input="selectuser"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Department</label>
                                              <treeselect placeholder="Select department" class="projectlistdd" v-model="mom.formdata1[index].dept" :options="mom.deptlist"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Email</label>
                                              <treeselect placeholder="Select email" class="projectlistdd" v-model="mom.formdata1[index].email" :options="mom.emailids"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                              <div class="avatar bg-success rounded ml-1">
                                                  <div class="avatar-content " @click="addAttendeesForm(index)" style="cursor: pointer;">
                                                      <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                  </div>
                                              </div>
                                              <div v-if="index !== 0" class="avatar bg-danger rounded ml-1">
                                                  <div class="avatar-content" @click="deleteField1(index)" style="cursor: pointer;">
                                                      <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
                  <fieldset class="fieldset_border_vi mb-1 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Absentees</span> 
                    </legend>
                      <div class="card mb-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m3, index) in mom.formdata2">
                                  <div v-bind:key="index" class="card border-warning mb-1">
                                    <div v-bind:class="[{show: m3.showform},card_content,collapse]">
                                      <div class="card-body p-1">
                                        <form>
                                          <div class="row">
                                            <div class="form-group col-md-3">
                                              <label for="owner">User</label>
                                              <treeselect placeholder="Select user" class="projectlistdd" v-model="mom.formdata2[index].user" :options="mom.createdbylist"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Department</label>
                                              <treeselect placeholder="Select department" class="projectlistdd" v-model="mom.formdata2[index].dept" :options="mom.deptlist"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Email</label>
                                              <treeselect placeholder="Select email" class="projectlistdd" v-model="mom.formdata2[index].email" :options="mom.emailids"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                              <div class="avatar bg-success rounded ml-1">
                                                  <div class="avatar-content " @click="addAbsendeesForm()" style="cursor: pointer;">
                                                      <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                  </div>
                                              </div>
                                              <div v-if="index !== 0" class="avatar bg-danger rounded ml-1">
                                                  <div class="avatar-content" @click="deleteField2(index)" style="cursor: pointer;">
                                                      <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
                  <fieldset class="fieldset_border_vi mb-1 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Pre-work Preparation</span> 
                    </legend>
                      <div class="card mb-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m4, index) in mom.formdata3">
                                  <div v-bind:key="index" class="card border-warning">
                                    <div v-bind:class="[{show: m4.showform},card_content,collapse]">
                                      <div class="card-body p-1">
                                        <form>
                                          <div class="row">
                                            <div class="col-sm-6">
                                              <div class="form-group">
                                                  <div class="form-group">
                                                      <label class="form-label" for="project-name">Description</label>
                                                      <ckeditor :editor="editor" placeholder="Enter description" v-model="mom.formdata3[index].workdescription"  :config="editorConfig" tag-name="textarea" v-bind:class="{  'form-control': true }"
                                                      />
                                                      <div class="invalid-feedback">description required</div>                                           
                                                  </div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Prepared by</label>
                                              <treeselect placeholder="Select prepared by" class="projectlistdd" v-model="mom.formdata3[index].preparedby" :options="mom.createdbylist"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                              <div class="avatar bg-success rounded ml-1">
                                                  <div class="avatar-content " @click="addPreworkForm()" style="cursor: pointer;">
                                                      <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                  </div>
                                              </div>
                                              <div v-if="index !== 0" class="avatar bg-danger rounded ml-1">
                                                  <div class="avatar-content" @click="deleteField3(index)" style="cursor: pointer;">
                                                      <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
                  <fieldset class="fieldset_border_vi mb-1 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Solutions, Notes, Decisions, Issues, Change Requests</span> 
                    </legend>
                      <div class="card mb-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m5, index) in mom.formdata4">
                                  <div v-bind:key="index" class="card border-warning mb-1">
                                    <div v-bind:class="[{show: m5.showform},card_content,collapse]">
                                      <div class="card-body p-1">
                                        <form>
                                          <div class="row">
                                            <div class="form-group col-md-3">
                                              <label for="topic">Topic</label>
                                              <input type="text" class="form-control" name="topic" placeholder="Enter topic" v-model="mom.formdata4[index].topic" />
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Owner</label>
                                              <treeselect placeholder="Select owner" class="projectlistdd" v-model="mom.formdata4[index].owner" :options="mom.createdbylist"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <div class="form-group mg-bot0">
                                                <label class="form-label" for="spendtime">Time</label>
                                                <div id="spendtime">
                                                    <vue-timepicker type="time" format="HH:mm" v-model="mom.formdata4[index].time"></vue-timepicker>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label class="form-label w-100" for="spendtime">&nbsp;</label>
                                              <div class="avatar bg-success rounded ml-1">
                                                  <div class="avatar-content " @click="addSolutionForm()" style="cursor: pointer;">
                                                      <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                  </div>
                                              </div>
                                              <div v-if="index !== 0" class="avatar bg-danger rounded ml-1">
                                                  <div class="avatar-content" @click="deleteField4(index)" style="cursor: pointer;">
                                                      <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
  
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
  

                  <fieldset class="fieldset_border_vi mb-1 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Action Items</span> 
                    </legend>
                      <div class="card mb-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m6, index) in mom.formdata5">
                                  <div v-bind:key="index" class="card border-warning">
                                    <div v-bind:class="[{show: m6.showform},card_content,collapse]">
                                      <div class="card-body p-1">
                                        <form>
                                          <div class="row">
                                            <div class="form-group col-md-3">
                                              <label for="topic">Action</label>
                                              <input type="text" class="form-control" name="action" placeholder="Enter action" v-model="mom.formdata5[index].action" v-bind:class="{'form-control': true,'is-invalid': !validText(mom.formdata6.action) && actionBlured, }"
                                              v-on:blur="actionBlured = true" />
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Owner</label>
                                              <treeselect placeholder="Select owner" class="projectlistdd" v-model="mom.formdata5[index].owner" :options="mom.createdbylist"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                              <label for="owner">Status</label>
                                              <treeselect placeholder="Select status" class="projectlistdd" v-model="mom.formdata5[index].status" :options="mom.statuslist"/>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <div class="form-group datePicker">
                                                <label for="" class="d-block">Due Date</label>
                                                    <date-picker placeholder="Select Due date" v-model="mom.formdata5[index].duedate" type="datetime"  format="YYYY-MM-DD HH:mm" valueType="format" class="startdate" id="StartDate"></date-picker>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-12 text-right">
                                           
                                              <div class="avatar bg-success rounded ml-1">
                                                  <div class="avatar-content " @click="addActionForm()" style="cursor: pointer;">
                                                      <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                  </div>
                                              </div>
                                              <div v-if="index !== 0" class="avatar bg-danger rounded ml-1">
                                                  <div class="avatar-content" @click="deleteField5(index)" style="cursor: pointer;">
                                                      <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
  
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
  

                  <fieldset class="fieldset_border_vi mb-1 mx-0 business_fieldset_bg">
                    <legend class="test_casebhg_fieldset_bg"> 
                        <span class="leg_mobile">Next Meeting</span> 
                    </legend>
                      <div class="card mb-0 border-0">
                        <div class="card-body p-0">
                          <form class="validate-form">
                            <div class="row mx-0">
                              <div class="col-12 mobile-px-0 px-0">
                                <template v-for="(m7, index) in mom.formdata6">
                                  <div v-bind:key="index" class="card border-0">
                                    <div v-bind:class="[{show: m7.showform},card_content,collapse]">
                                      <div class="card-body p-0">
                                        <form>
                                          <div class="row">
                                            <div class="col-md-4 mb-1">
                                                <div class="form-group datePicker">
                                                <label for="" class="d-block">Date</label>
                                                    <date-picker placeholder="Select date" v-model="mom.formdata6[index].date" type="datetime"  format="YYYY-MM-DD" valueType="format" class="startdate" id="StartDate"></date-picker>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <div class="form-group mg-bot0">
                                                <label class="form-label" for="spendtime">Time</label>
                                                <div id="spendtime">
                                                    <vue-timepicker type="time" format="HH:mm" v-model="mom.formdata6[index].time"></vue-timepicker>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-1">
                                                <div class="form-group ">
                                                    <label for="location">Location</label>
                                                    <input type="text" id="Enter location" class="form-control" name="location" v-model="mom.formdata6[index].location" placeholder="Enter location" />
                                                </div>
                                            </div>
                                            <div class="col-sm-12">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <label class="form-label" for="project-name">Meeting Objective</label>
                                                    <ckeditor :editor="editor" placeholder="Enter Meeting Objective" v-model="mom.formdata6[index].objective" :config="editorConfig" tag-name="textarea" v-bind:class="{  'form-control': true }"
                                                    />
                                                    <div class="invalid-feedback">Meeting Objective required</div>                                           
                                                </div>
                                            </div>
                                            </div>
                                          </div>
  
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </form>
                        </div>  
                      </div>
                  </fieldset>
  
                    <div class="row">
                      <div class="col-md-12 mb-1 text-right">
                          <button type="button" class="btn btn-relief-primary mr-1" @click="savefcmom()"   id="submitbtnt13">Submit</button>
                          <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                      </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import VueElementLoading from 'vue-element-loading';
  import Loading from 'vue-loading-overlay';
  import Pagination from 'vue-pagination-2'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { PlusCircleIcon, EditIcon, PlusSquareIcon,MinusIcon,PlusIcon,MinusSquareIcon } from "vue-feather-icons";
  import Treeselect from '@riophae/vue-treeselect'
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import VueTimepicker from 'vue2-timepicker'
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import index from 'watch-size';
  import commonMethods from '../../utils/commonMethods';

import { stringify } from 'querystring';
  
  export default {
      name:'momcreate',
      mixins: [ commonMethods ],
      components: {
          VueElementLoading,
          Loading,
          PlusCircleIcon,
          EditIcon,
          PlusSquareIcon,
          Pagination,
          Treeselect,
          DatePicker,
          MinusIcon,
          PlusIcon,
          MinusSquareIcon,
          ckeditor: CKEditor.component,VueTimepicker
      },
      data(){
          return{
              isLoading: false,
              pagefeatures:[],
              topic:[],
              undt:null,
              tokendata:null,
              fullPage: true,
              editor: ClassicEditor,
              editorConfig: {
                  toolbar: {
                      items: [
                          'heading',
                          '|',
                          'bold',
                          'italic',
                          '|',
                          'bulletedList',
                          'numberedList',
                          '|',
                          'insertTable',
                          '|',
                          'undo',
                          'redo', '|',
                      ],
                  },
              },
              mom:{
                  projectid: null,
                  projectlist:[],
                  projectmoduleid: null,
                  projectmodulelist: [],
                  facilitator:null,
                  facilitatorlist: [],
                  businesanayst: null,
                  businesanalystlist: [],
                  createdby: null,
                  createdbylist: [],
                  emailids:[],
                  deptlist:apiUrl.deptlist,
                  statuslist:apiUrl.status,
                  status: null,
                  meetingdate: null,
                  starttime: "",
                  endtime: "",
                  time:"",
                  formdata: [
                    {
                      topic: null,
                      owner: null,
                      time: "",
                      showform: true,
                    }
                  ],
                  formdata1: [
                    {
                      user: null,
                      dept: null,
                      deptlist:apiUrl.deptlist,
                      email: null,
                      showform: true,
                    }
                  ],
                  formdata2: [
                    {
                      user: null,
                      dept: null,
                      deptlist:apiUrl.deptlist,
                      email: null,
                      showform: true,
                    }
                  ],
                  formdata3: [
                    {
                      workdescription: '',
                      preparedby: null,
                      showform: true,
                    }
                  ],
                  formdata4: [
                    {
                      topic: null,
                      owner: null,
                      time: "",
                      showform: true,
                    }
                  ],
                  formdata5: [
                    {
                      action: '',
                      owner: null,
                      status: null,
                      statuslist:apiUrl.status,
                      duedate: null,
                      showform: true,
                    }
                  ],
                  formdata6: [
                    {
                      date: '',
                      time: "",
                      location: null,
                      objective: null,
                      showform: true,
                    }
                  ],
              },
              errors: {
                  mom:{
                    projectid: null,
                    projectmoduleid: null,
                    facilitator:null,
                    businesanayst: null,
                    createdby: null,
                    meetingdate: null,
                    starttime: "",
                    endtime: "",
                    time:"",
                    owner:null,
                    formdata: [
                      {
                        topic: null,
                        owner: null,
                        time: "",
                      }
                    ],
                  },
              },
              isAddState:false,
              objectiveBlured: null,
              locationBlured: null,
              topicBlured: null,
              actionBlured: null,
              card_content:'card-content',
              collapse:'collapse',
              plannermasterid: null,
              routeplannermasterid: null,
          }
      },
      created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
          if (element.page === '/fc_mom/momcreate') {
            this.pagefeatures[element.featurename] = element.featureaccess
          }
        })
      },
      mounted() {
          // let permission = this.$Service.verifyAccess();
          // if(permission){
              let userdata = window.localStorage.getItem('userdata');
              if(userdata) {
                  userdata = JSON.parse(userdata)
                  this.undt = userdata
                  this.loginuscondition = userdata.userid;
                  this.loginusername = userdata.username;
                  this.tokendata = window.localStorage.getItem('token');
                  // this.getUsersEmailData()
                  this.routeplannermasterid = this.$route.query.plannermasterid
              }
          // }
          // if (this.$route.params.backbtnstate === 'createMOM') {
          //   this.plannermasterid = parseInt(this.$route.params.clickeddata)
          //   console.log(this.plannermasterid,'this.plannermasterid=====================')
          // }
      },
  methods: {
      getProjectAllocatedToEmp() {
          this.isLoading = true
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode : this.undt.userid
          };
          console.log("this.loginuscondition",this.loginuscondition)
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.mom.projectlist = result.data.data;
            } else {
                this.mom.projectlist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
      },
      projectChange: function (node, instanceId) {
        if (node && node.length > 3) {
          this.isLoading = true;
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            empid: parseInt(this.undt.userid),
            projectname: node.toLowerCase(),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage: 'createpage'
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.mom.projectlist = result.data.data;
            } else {
              this.mom.projectlist = [];
            }
          }).catch(e => {
            this.displayError(e)
          });
        }
      },
      getProjectmodulebyProject(projectid) {
        axios({
            method: "POST",
            url: "api/master/fcmom/getprojectmodulebyprojectid",
            data: { projectid:projectid,empcode: this.undt.userid,
            useremail: this.undt.username, isfrompage: 'createpage'},
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
              this.mom.projectmodulelist = result.data.data;
              } else {
              this.mom.projectmodulelist = [];
              }
          }).catch(e => {
          this.displayError(e)
        });
      },
      selprojdata(state, projvalue) {
        this.mom.projectid = null;
        this.mom.projectid = state;
        if (!state && !this.isAddState) {
          this.errors.mom.projectid='Project name is required'
          this.mom.projectlist = null;
        } else {
          this.mom.projectmodulelist = [];
          this.getEmpallocatedtoProject(state)
          this.getProjectmodulebyProject(state)
          this.getUsersEmailData(state)
          this.getEmpbyProjectforMOM(state)
        }
      },
      selprojmoduledata(state) {
        this.mom.projectmoduleid = null,
        this.mom.projectmoduleid = state
        if(!state && !this.isAddState) {
            this.errors.mom.projectmoduleid = 'Project Module is required'
        } else {
            this.errors.mom.projectmoduleid = ''
        }
      },
      getEmpallocatedtoProject(projectid) {
        axios({
          method: "POST",
          url: "api/listing/getEmpallocatedtoProject",
          data: { projectid: projectid,useremail: this.undt.username,empcode: this.undt.userid, },
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            let decryptdepartmenttext = result.data.data.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
            this.mom.facilitatorlist = result.data.data;
            this.mom.businesanalystlist = result.data.data;
          } else {
            this.mom.facilitatorlist = [];
            this.mom.businesanalystlist = [];
          }
        });
      },
      getEmpbyProjectforMOM(projectid) {
        axios({
          method: "POST",
          url: "api/master/fcmom/getEmpbyProjectMOM",
          data: { projectid: projectid,useremail: this.undt.username,empcode: this.undt.userid, },
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.mom.createdbylist = result.data.data 
          } else {
            this.mom.createdbylist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getEmpbyProjectforMOM(projectid) {
        console.log(projectid)
        axios({
          method: "POST",
          url: "api/master/fcmom/getEmpbyProjectMOM",
          data: { projectid: projectid,useremail: this.undt.username,empcode: this.undt.userid, },
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.mom.createdbylist = result.data.data 
          } else {
            this.mom.createdbylist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      },
      selfacilitatorto(state, value) {
        this.mom.facilitator = null,
        this.mom.facilitator = state
        if(!state &&!this.isAddState) {
          this.errors.mom.facilitator = 'Meeting facilitator is required'
        } else {
          this.errors.mom.facilitator = ''
        }
      },
      selbusinesanayst(state, value) {
        this.mom.businesanayst = null,
        this.mom.businesanayst = state
        if(!state && !this.isAddState) {
          this.errors.mom.businesanayst = 'Business Analyst is required'
        } else {
          this.errors.mom.businesanayst = ''
        }
      },
      selOwner(state, value) {
        this.mom.formdata[index].owner = null,
        this.mom.formdata[index].owner = state
        if(!state) {
          this.errors.mom.formdata[index].owner = 'Owner is required'
        } else {
          this.errors.mom.formdata[index].owner = ''
        }
      },
      addAgendaForm() {
        this.mom.formdata.push({
          topic: null,
          owner: null,
          time: "",
          showform: true,
        })
      },
      addAttendeesForm() {
        this.mom.formdata1.push({
          user: null,
          dept: null,
          email: null,
          phone: null,
          showform: true,
        })
      },
      addAbsendeesForm() {
        this.mom.formdata2.push({
          user: null,
          dept: null,
          email: null,
          phone: null,
          showform: true,
        })
      },
      addPreworkForm() {
        this.mom.formdata3.push({
          workdescription: '',
          preparedby: null,
          showform: true,
        })
      },
      addSolutionForm() {
        this.mom.formdata4.push({
          topic: null,
          owner: null,
          time: null,
          showform: true,
        })
      },
      addActionForm() {
        this.mom.formdata5.push({
          action: '',
          owner: null,
          status: null,
          duedate: null,
          showform: true,
        })
      },
      deleteField(idx) {
        this.mom.formdata.splice(idx, 1)
      },
      deleteField1(idx) {
        this.mom.formdata1.splice(idx, 1)
      },
      deleteField2(idx) {
        this.mom.formdata2.splice(idx, 1)
      },
      deleteField3(idx) {
        this.mom.formdata3.splice(idx, 1)
      },
      deleteField4(idx) {
        this.mom.formdata4.splice(idx, 1)
      },
      deleteField5(idx) {
        this.mom.formdata5.splice(idx, 1)
      },
  
      handleCollapse(idx) {
        this.mom.formdata[idx].showform = !this.mom.formdata[idx].showform
      },
  
      // getUsersEmailData(projectid) {
      //   axios({
      //     method: "POST",
      //     url: "api/master/fcmom/getEmpEmailbyProject",
      //     data: {projectid: projectid,
      //       useremail: this.undt.username,
      //       empcode: this.undt.userid, },
      //     'headers':{'authorization':this.tokendata}
      //   }).then((result) => {
      //     this.isLoading = false;
      //     if (result.data.errorCode == 0) {
      //       this.mom.emailids = result.data.data;
      //     } else {
      //       this.mom.emailids = [];
      //     }
      //   });
      // },
  
      savefcmom: function(mdl,objThis){
        console.log(mdl)
        console.log(objThis)
          this.valid = this.validateForm(mdl,objThis);
          if (this.valid) {
              this.isLoading = true;
              let agenda = []
              let attendees = []
              let absendees = []
              let prework = []
              let solution = []
              let actionitems = []
              let nextmeeting = []
              this.input = {
                  createdby: this.undt.username,
                  empcode: parseInt(this.undt.userid),
                  useremail: this.undt.username,
              }
              let apiURL='api/master/fcmom/createfcmom'
              this.input.plannermasterid = parseInt(this.routeplannermasterid)
              this.input.projectid = this.mom.projectid
              this.input.projectmoduleid = this.mom.projectmoduleid
              this.input.location = this.mom.location
              this.input.objective = this.mom.objective
              this.input.facilitator = this.mom.facilitator
              this.input.businesanayst = this.mom.businesanayst
              this.input.meetingdate = this.mom.meetingdate
              this.input.starttime = this.mom.starttime
              this.input.endtime = this.mom.endtime
              if(this.mom.formdata && this.mom.formdata.length >=1){
                for(let index in this.mom.formdata){
                  agenda.push({
                    "topic": this.mom.formdata[index].topic,
                    "owner": this.mom.formdata[index].owner,
                    "time": this.mom.formdata[index].time,
                  })
                }
              }
              this.input.agendarray = {data:agenda }
  
              if(this.mom.formdata1 && this.mom.formdata1.length >=1){
                for(let index in this.mom.formdata1){
                  attendees.push({
                    "user": this.mom.formdata1[index].user,
                    "dept": this.mom.formdata1[index].dept,
                    "email": this.mom.formdata1[index].email,           
                  })
                }
              }
              this.input.attendeesarray = {data:attendees }
  
              if(this.mom.formdata2 && this.mom.formdata2.length >=1){
                for(let index in this.mom.formdata2){
                  absendees.push({
                    "user": this.mom.formdata2[index].user,
                    "dept": this.mom.formdata2[index].dept,
                    "email": this.mom.formdata2[index].email,
                  })
                }
              }
              this.input.absendeesarray = {data:absendees }
  
              if(this.mom.formdata3 && this.mom.formdata3.length >=1){
                for(let index in this.mom.formdata3){
                  prework.push({
                    "workdescription": this.mom.formdata3[index].workdescription,
                    "preparedby": this.mom.formdata3[index].preparedby,
                  })
                }
              }
              this.input.preworkarray = {data:prework }
  
              if(this.mom.formdata4 && this.mom.formdata4.length >=1){
                for(let index in this.mom.formdata4){
                  solution.push({
                    "topic": this.mom.formdata4[index].topic,
                    "owner": this.mom.formdata4[index].owner,
                    "time": this.mom.formdata4[index].time,
                  })
                }
              }
              this.input.solutionarray = {data:solution }
  
              if(this.mom.formdata5 && this.mom.formdata5.length >=1){
                for(let index in this.mom.formdata5){
                  actionitems.push({
                    "action": this.mom.formdata5[index].action,
                    "owner": this.mom.formdata5[index].owner,
                    "status": this.mom.formdata5[index].status,
                    "duedate": this.mom.formdata5[index].duedate,
                  })
                }
              }
              this.input.actionitemsarray = {data:actionitems }
  
              if(this.mom.formdata6 && this.mom.formdata6.length >=1){
                for(let index in this.mom.formdata6){
                  nextmeeting.push({
                    "date": this.mom.formdata6[index].date,
                    "time": this.mom.formdata6[index].time,
                    "location": this.mom.formdata6[index].location,
                    "objective": this.mom.formdata6[index].objective,
                  })
                }
              }
              this.input.nextmeetingarray = {data:nextmeeting }
              console.log(this.input,'this.input==============================')
              axios({
              'method': 'POST',
              'data': this.input,
              'url': apiURL,
              'headers':{'authorization':this.tokendata}
              })
              .then(result => {
                  this.isLoading = false;             
                  if(result.data.errorCode == 0){
                      Swal.fire({
                          title: "Success!",
                          text: result.data.msg,
                          icon: 'success',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                      })
                      // this.cleardata();
                      this.$router.push(`/planner/viewplan?plannermasterid=${parseInt(this.routeplannermasterid)}`)
                  }else if(result.data.errorCode == 3){
                      Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                          window.location.href = "/#/login";
                      })
                    } else {
                      Swal.fire({
                          title: "Failed",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                      })
                  }
              }).catch(e => {
              this.displayError(e)
            })
          }
      },

      cleardata(){
          this.mom.projectid = null;
          this.mom.projectmoduleid = null;
          this.mom.location = '';
          this.mom.objective = '';
          this.mom.facilitator = null;
          this.mom.businesanayst = null;
          this.mom.meetingdate = null;
          this.mom.formdata = {};
          this.mom.formdata1 = {};
          this.mom.formdata2 = {};
          this.mom.formdata3 = {};
          this.mom.formdata4 = {};
          this.mom.formdata5 = {};
      },
      handleCancel() {
        this.$router.push(`/planner/viewplan?plannermasterid=${parseInt(this.routeplannermasterid)}`)
      },
  
      validateForm(){
          this.clearerrorForm();
          this.isAddState=false
          var isValid=true;
          this.objectiveBlured = true;
          this.locationBlured = true;
          this.topicBlured = true;
            if(!this.mom.projectid){
              this.errors.mom.projectid="Project name is required";
              isValid= false;
            }
            else {
              this.errors.mom.projectid=''
            }
            if(!this.mom.projectmoduleid){
              this.errors.mom.projectmoduleid="Project module name is required";
              isValid= false;
            }
            else {
              this.errors.mom.projectmoduleid=''
            }
            if(!this.mom.facilitator){
              this.errors.mom.facilitator="Meeting facilitator is required";
              isValid= false;
            }
            else {
              this.errors.mom.facilitator=''
            }
            if(!this.mom.businesanayst){
              this.errors.mom.businesanayst="Business Analyst is required";
              isValid= false;
            }
            else {
              this.errors.mom.businesanayst=''
            }
            if(this.mom.starttime==''){
              this.errors.mom.starttime="Start time is required";
              isValid= false;
            }else{
              this.errors.mom.starttime='';
            }
            if(this.mom.endtime==''){
              this.errors.mom.endtime="End time is required";
              isValid= false;
            }else{
              this.errors.mom.endtime='';
            }
            // if(!this.mom.formdata.owner){
            //   this.errors.mom.formdata.owner="owner name is required";
            //   isValid= false;
            // }
            if(!this.mom.meetingdate){
              this.errors.mom.meetingdate="Meeting date is required";
              isValid= false;
            }else{
              this.errors.mom.meetingdate='';
            }
            return isValid
      },
      clearerrorForm(){
        this.errors.mom.projectid = null
        this.errors.mom.projectmoduleid = null
        this.errors.mom.facilitator = null
        this.errors.mom.businesanayst = null
      },
      validText : function(inputval) {
          var re = /[A-Za-z0-9].{2,}/;
          if(inputval){
              return re.test(inputval.toLowerCase());
          }
      },
      selmeetingdate(state){
        this.mom.meetingdate = null
        this.mom.meetingdate = state
        if(!state) {
          this.errors.mom.meetingdate = 'Metting date is required'
          console.log(this.errors.mom.meetingdate)
        } else {
          this.errors.mom.meetingdate = ''
        }
      },
      selectuser(state){
        axios({
          method: "POST",
          url: "api/master/fcmom/getEmpEmailbyProject",
          data: {
            empid: parseInt(state) ,
            empidabse:  parseInt(state),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            name: state },
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.mom.emailids = result.data.data;
          } else {
            this.mom.emailids = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
        if (this.mom.formdata1.length >= 1) {
          for (let i=0 ; i < this.mom.formdata1.length; i++) {  
              if (this.mom.formdata1[i].user == state) {
                this.mom.formdata1[i].email = null
              }
            }
          }
          if (this.mom.formdata2.length >= 1) {
          for (let i=0 ; i < this.mom.formdata2.length; i++) {  
              if (this.mom.formdata2[i].user == state) {
                this.mom.formdata2[i].email = null
              }
            }
          }
        },
      },
  }
  </script>