<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-1 pad_top_mar_bot-5 px-0">
                    <div class="row breadcrumbs-top mx-0">
                        <div class="col-12 px-0">
                            <h3 class="content-header-title float-left mb-0">Planner</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to=''>Planner</router-link></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapselist">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Search Planner Details</h4>
                            </div>
                            <div class="ml-auto d-flex">
                            <button  class="btn btn-sm btn-relief-success mr-1"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" :data-target="currentmodule" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false">
                                    <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon> <span class="mobile_hide_bt">Create Plan</span></button>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActivelist},card_content,collapse]">
                    <div class="card-body pt-1">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                <label class="form-label lable-left">Business Task Code</label>
                                <input type="text" class="form-control" placeholder="Enter BusinessTask Code"  v-model="search.btccode"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Business Task Name</label>
                            <treeselect class="projectmdllistdd" placeholder="Enter min 3 chars to search Business Task Name" v-model="search.btcname" :options="search.businesstasknameopts" v-on:search-change="businessNameChangeSearch" @input="clearBusinessNameList"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                <label class="form-label lable-left">Planner Code</label>
                                <input type="text" class="form-control" placeholder="Enter Planner Code"  v-model="search.plannercode" :maxlength="maxLength"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Planner Title</label>
                            <treeselect class="projectmdllistdd" placeholder="Enter Min 3Chars To Search Planner Title" v-model="search.plannertitle" :options="search.plannertitleotps" v-on:search-change="plannertitleChangeSearch" @input="clearplannerList"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1 ">
                                <div class="date_picker_foundeds">
                                    <label for="">Date From</label>
                                    <div class="input-group input-group-merge">
                                    
                                    <date-picker placeholder="Select Created Date From" v-model="search.datefrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                                    <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search planner.</div>                        
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                <div class="date_picker_foundeds">
                                    <label for="">Date To</label>
                                    <div class="input-group input-group-merge"> 
                                    <date-picker placeholder="Select Created Date To" v-model="search.dateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disableDate" @input="selectedenddate"></date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-0  mobile-px-0 text-right px-1">
                                <button type="submit" class="btn btn-relief-primary mt-2 ml-1" :disabled="isBothDateSelected" @click="getplannerlist('','',search,1)"> 
                                <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                                <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="resetRecords()">
                                <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="scrollToListGrid"></div>
                <div class="row">
                <div class="col-12">
           
                </div>
                </div>

       
            <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">                   
            <div class="row mx-0 ">
                <div class="card py-1 px-0 col-12">
                <div class="container-fluid border-bottom panner_viewmain_div-05">
                    <div class="card-header_recent_bg">
                    <div class="row fc_my-05 mx-0" >
                        <div class=" w-auto  planerview_design">
                          <ul> 
                            <li class="sitebluecolor cursorpointercls" @click="getplannerlist('limits','',search,1)"> Recent</li>
                            <li class="sitebluecolor cursorpointercls" @click="getplannerlist('','',search,1)"> All </li>
                          </ul>
                        </div>
                        <div class="ml-auto d-flex">
                            <div  class=" mx-1  text-right mobile-padding-0-2">
                            <div class="input-group-append displayblk">
                                <ul class="listGrid float-right d-inline-flex">
                        <li class="cursorpointercls"  @click="myListView()" >
                        <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                            <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                            <span>
                            List View
                            </span>
                        </button>
                        </li>
                        <li class=" cursorpointercls" @click="myGridView()" >
                        <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
                            <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
                            <span>
                            Grid View
                            </span>
                        </button>
                        </li>
                               </ul>
                            </div>
                        </div>
                    </div>                                
                  </div>
             <div v-bind:class="[{ show: isgridView}, card]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;margin-top: 1rem;margin-bottom: 1rem !important; ">
                <div v-bind:class="[{ show: isgridView }, card_content, collapse]">
                        <div class="row container mx-0 mt-1 px-0" id="plannertable">
                            <div class=" col-xs-12 col-sm-6 col-lg-4 col-md-6  px-1" v-for="(data,index) in companymasterlist" v-bind:key="index">
                                    <div class="card card-transaction planer_view_card_st " :style="{'background-color': colorlist[Math.floor(Math.random() * colorlist.length)]}" >
                                        <div class="plan_dotted_droplists">
                                            <div class="dropdown chart-dropdown">
                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right">
                                                    <a class="dropdown-item" style="padding: 5px 10px;" href="javascript:void(0);" data-toggle="modal" data-backdrop="static" data-keyboard="false" :data-target="currentmodule" @click="openEditModule(data)"> <edit-2-icon size="1.5x" class="custom-class mr-50 feather feather-edit-2" ></edit-2-icon>
                                                        <span> Edit</span></a>
                                                    <a class="dropdown-item" style="padding: 5px 10px;" href="javascript:void(0);" data-toggle="modal" data-target="#addMember"  @click="openEditModule(data)"><plus-icon size="4x" class="custom-class mr-50 feather feather-edit-2" ></plus-icon>
                                                        <span>Add Member</span></a>
                                                    <a class="dropdown-item" style="padding: 5px 10px;" href="javascript:void(0);" @click="redirectToViewPlan('planner',data)"> <em class="las la-copy custom-class mr-50 feather feather-edit-2" size="4x"></em>
                                                        <span>View Plan Details</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    <div class=" plan_backgrounds_droplists "><span class="transaction-title capitalisetext"> <span class="font-weight-bolder capitalisetext"> Planner Code: </span> {{data.plannercode}} </span></div>
                                    <div class="card-body pt-1 px-1">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded  mt-1">
                                                    <div class="avatar-content">
                                                        <BoxIcon size="1.5x" class="avatar-icon font-medium-3 " ></BoxIcon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <span class="transaction-title" style="text-transform: capitalize;"> <span class="font-weight-bolder" >Planner Title: </span> {{(data.plannertitle).toLowerCase()}} </span><br>
                                                                                                                                            
                                                    <span class="transaction-title capitalisetext" style="text-transform: capitalize;"> <span class="font-weight-bolder capitalisetext">Created By: </span> {{(data.createdby.split('@')[0]).toLowerCase()}} </span><br>
                                                    <span class="transaction-title"> <span class="font-weight-bolder">Created Date: </span> {{data.createddate?dateMonthFormat(data.createddate):'NA'}} </span><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pageCenter text-center mt-2" v-if="limits!='limits'">
                                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                <div v-if="companymasterlist.length == 0" colspan="14" style="text-align: center;">No record found</div>
                            </div>
                            <div class="container" v-else-if="limits=='limits'">
                                <div class="text-center">Showing last 10 records</div>
                            </div>
                </div>
             </div>
            <div v-bind:class="[{ show: isListActive}, card]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;margin-top: 1rem;margin-bottom: 1rem !important; ">
            <div v-bind:class="[{ show: isListDetActive }, card_content, collapse]">
                <div class="row">
        <div class="col-md-12">
          <div class="card text-center mb-3">
            <div class="">
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="row" id="table-small">
                    <div class="col-12">
                      <div class="card">
                        <div class="" >
                        </div>
                        <div class="table-responsive datatableview">
                          <table class="table table-sm dataTableListing table-striped all_table_stickys" aria-describedby="mydesc">
                            <thead>
                              <tr> 
                                <th scope="col" class="srno align-middle first-col-sticky py-1" style="min-width: 100px;">Sr#</th>
                                <th scope="col" class="align-middle second-col-sticky text-left" style="min-width: 160px;">Planner Code</th>
                                <th scope="col" class="align-middle text-left">Planner Title</th>
                                <th scope="col" class="align-middle text-left" style="min-width: 180px;">Created By</th>
                                <th scope="col" class="align-middle">Created Date</th>
                                <th scope="col" class="clsaction align-middle">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(data,index) in companymasterlist">        
                                <tr>
                                    <td class="first-col-sticky centeralign">{{ index + 1 + currPageNum * pagelimit }}</td>                                                                                     
                                  <td data-column="Planer Code" class="details_column_table second-col-sticky text-left" style="max-width: 230px;">{{data.plannercode}}</td>
                                  <td data-column="Planer Title" class="details_column_table text-left" @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer; min-width: 230px;"><span v-if="data.plannertitle && data.plannertitle.length > 50" class="font-weight-normal sitebluecolor">{{ data.plannertitle.substring(0, 51) + ' ...' }}</span>
                                  <span v-if="data.plannertitle && data.plannertitle.length <= 50" class="font-weight-normal sitebluecolor">{{ data.plannertitle }}</span>
                                  </td> 
                                  <td data-column="Created By" class="font-weight-normal text-left">{{(data.createdby.split('@')[0]).toLowerCase()}}</td>
                                  <td data-column="Created Date" class="text-nowrap font-weight-normal">{{data.createddate?dateMonthFormat(data.createddate):'NA'}} </td>
                                  
                                  
                                  <td data-column="Actions" class="clsaction" >
                                            <div class="dropdown">
                                              <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                              </button>
                                              <div class="dropdown-menu">
                                                <a class="dropdown-item" style="padding: 5px 10px;" href="javascript:void(0);" data-toggle="modal" data-backdrop="static" data-keyboard="false" :data-target="currentmodule" @click="openEditModule(data)"> <edit-2-icon size="1.5x" class="custom-class mr-50 feather feather-edit-2" ></edit-2-icon>
                                      <span> Edit</span></a>
                                  <a class="dropdown-item" style="padding: 5px 10px;" href="javascript:void(0);" data-toggle="modal" data-target="#addMember"  @click="openEditModule(data)"><plus-icon size="4x" class="custom-class mr-50 feather feather-edit-2" ></plus-icon>
                                      <span>Add Member</span></a>
                                  <a class="dropdown-item" style="padding: 5px 10px;" href="javascript:void(0);" @click="redirectToViewPlan('planner',data)"> <em class="las la-copy custom-class mr-50 feather feather-edit-2" size="4x"></em>
                                      <span>View Plan Details</span></a>
                                              </div>
                                            </div>
                                </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pageCenter text-center mt-2" v-if="limits!='limits'">
              <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
              <div v-if="companymasterlist.length == 0" colspan="14" style="text-align: center;">No record found</div>
          </div>
          <div class="container" v-else-if="limits=='limits'">
              <div class="text-center">Showing last 10 records</div>
          </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            </div>
            </div>
                  </div>
                </div>
    
                    <div class="modal-size-lg d-inline-block">
                        <div class="modal fade text-left" id="addMember" tabindex="-1" role="dialog" aria-labelledby="myModalLabel17" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel17">Add/Update Members</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetForm()">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-12 mb-1">
                                                <div class="form-group">
                                                    <label for="basicInput">Enter EmailId</label>
                                                    <treeselect class="projectmdllistdd" placeholder="Enter minimum 3 chars search emailid" v-model="planner.membersname" :options="search.plannermemname" v-on:search-change="searchnewmembers" @input="selectmember"/>
                                                </div>
                                            </div>
                                        <div class="container-fluid">
                                            <div class="col-sm-12 px-0">
                                                <div class="" v-if="planner.membersname">
                                                    <table class="table  border-0 table-sm dataTableListing table-hover table-striped all_table_stickys" id="table_businessceo" aria-describedby="mydesc">
                                                        <thead class="d-none">
                                                            <th scope="col"> </th>
                                                        </thead>
                                                        <tbody>
                                                            <td style="width:70%"> 
                                                              <div class="card-transaction">
                                                                <div class="transaction-item">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-primary rounded">
                                                                        <div class="avatar-content">
                                                                            <div class="avatar mr-50" style="margin-top:10px;margin-bottom:10px;margin-left:10px;">
                                                                                <profilepic v-if="planner.member.imagename && planner.member.id" v-bind:get-url="'empprofile/'+planner.member.id+'/'+planner.member.imagename" v-bind:key="planner.member.id" width="40" height="40"></profilepic>
                                                                                <img alt="" v-if="!planner.member.imagename" :src="defaultimagepath"  width="40" height="40" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h6 class="transaction-title capitalisetext">{{planner.member.label.split('@')[0]}}</h6>
                                                                        <small>{{planner.member.label}}</small>
                                                                    </div>
                                                                </div>
                                                               </div>
                                                              </div>
                                                            </td>
                                                            <td> 
                                                               <div class="form-group plan_select_plsborder">
                                                                    <label for="appprojectdd">Select Role</label>
                                                                    <treeselect class="allotedto capitalisetext" placeholder="Select role" :options="planner.memberslist" v-model="planner.role" @blur="validateMemForm($event)" />
                                                                </div>
                                                                <div class="errorinputmsg" v-if="this.errors.planner.role"> {{this.errors.planner.role}}</div>
                                                            </td>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="container" v-else>
                                                    <div class="text-center">No record found</div>
                                                </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" @click="savemember(planner)">Submit</button>
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="resetForm()">Close</button>
                                    </div>
                                    <div class="card border-warning mx-2 filter_card_searchbgst" >
                                    <div class="card-header filter_rotation_onlick px-1" style="cursor: pointer" v-on:click="mysearchrequestollapse">
                                        <div class="container-fluid" >
                                        <div class="row fc_my-05">
                                            <div class="w-auto mobile-padding-0-2" >
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                    <div class="avatar-content">
                                                        <em class="las la-user-friends font-medium-3"></em>
                                                    </div>
                                                    </div>
                                                    <h4 class="card-title d-inline-flex ml-1 ">View All Members</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="container-fluid">
                                                        <div class="col-sm-12 px-0">
                                                            <div class="" v-for="(datas,indexx) in memberslist" v-bind:key="indexx">
                                                                <table class="table  border-0 table-sm dataTableListing table-hover table-striped all_table_stickys" id="table_businessceo" aria-describedby="mydesc">
                                                                    <thead class="d-none">
                                                                        <th scope="col"> </th>
                                                                    </thead>
                                                                    <tbody>
                                                                        <td style="width:70%"> 
                                                                        <div class="card-transaction">
                                                                            <div class="transaction-item">
                                                                            <div class="media">
                                                                                <div class="avatar bg-light-primary rounded">
                                                                                    <div class="avatar-content">
                                                                                        <div class="avatar mr-50" style="margin-top:10px;margin-bottom:10px;margin-left:10px;">
                                                                                            <profilepic v-if="datas.imagename && datas.empcode" v-bind:get-url="'empprofile/'+datas.empcode+'/'+datas.imagename" v-bind:key="datas.empcode" width="40" height="40"></profilepic>
                                                                                            <img alt="" v-if="!datas.imagename" :src="defaultimagepath"  width="40" height="40" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="media-body">
                                                                                <h6 class="transaction-title capitalisetext">{{datas.firstname+' '+datas.lastname}}</h6>
                                                                                <span>{{ datas.emailid }}</span>
                                                                            </div>
                                                                            <span class="capitalisetext">{{ datas.rolename }}</span>
                                                                        </div>
                                                                        </div>
                                                                        </td>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="container" v-if="memberslist.length==0">
                                                                <div class="text-center">Members record not found</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
            </section>
        </div>
        <div class="modal modal-slide-in fade" id="projectmodulemaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#ffff !important" @click="resetForm()">×</button>
                    <div class="modal-header mb-1" style="background-color:#1f77b4 !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle ml-50">Add/Update Planner Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm mobile-padding-0-2 px-0">
                        <form>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Enter Planner Title</label>
                                    <input id="modulenamet36" name="" class="form-control" type="text" placeholder="Enter planner title " v-model="planner.title" @blur="validateForm($event)"
                                    v-bind:class="{ 
                                    'form-control': true,
                                    '': !validText(planner.title) && titleBlured, }"
                                    v-on:blur="titleBlured = true"/>
                                    <div class="errorinputmsg" v-if="!validText(planner.title) && titleBlured"> Planner title is required</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex flex-wrap mb-0" >
                                    <button   type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal" v-on:click.stop.prevent="savewizard('t36',$event)">Submit</button>
                                    <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { ListIcon,ExternalLinkIcon,PlusSquareIcon,Trash2Icon,XIcon,PlusCircleIcon,Edit2Icon,PlusIcon,MoreVerticalIcon,PackageIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,UserIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import profilepic from '../profilepic.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';
export default {
    name:'PlanHub',
    components:{
        Loading,ListIcon,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        Trash2Icon,
        PlusCircleIcon,
        XIcon,
        ExternalLinkIcon,profilepic,DatePicker,UserIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            defaultimagepath:apiUrl.defaultimagepath,
            currPageNum: 0,
            pageoffset:1,
            showtabledata:true,
            
            showAddForm:false,
            showfilterboxsection:false,
            currentmodule:'#projectmodulemaster',
            showaddmodulebtn:false,
            isSearchRequestActive:false,
            isSearchRequestActivelist:false,
            card: "card",
            collapse:'collapse',
            card_content:'card-content',
            planner:{
                title:null,
                groups:null,
                privacy:null,
                id: null,
                isactive: null,
                role:null,
                memberslist:[{
                    id:'member',
                    label:'member',
                },{
                    id:'owner',
                    label:'owner'
                }],
                membersname:null,
                member: null
            },
            search:{
                plannermemname:[],
                plannercode:null,
                plannertitle:null,
                btcname:null,
                btccode:null,
                datefrom:null,
                dateto:null,
                businesstasknameopts:null,
                plannertitleotps:[]
            },
            errors:{
                planner:{
                    title:null,
                    groups:null,
                    role:null
                }
            },
            isAddState:false,
            iseditstate:false,
            companymasterlist:[],
            titleBlured:false,
            tokendata:null,
            memberslist: [],
            limits: null,
            isBothDateSelected:false,
            maxLength:17,
            colorlist:[],
            isgridView:true,
            card: "card",
            activelistlink:false,
            activegridlink:true,
            blog_list_wrapper :'blog-list-wrapper',
            isListActive:true,
            isListDetActive:false,
            displayblk:'displayblk',
            isDetActive: false,
           
            
            
            isRequestActive: false,
            border_warning: "border-warning",
            
        }
    },
   
    mounted(){
        let permission = this.$Service.verifyAccess();
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        }); 
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            if(this.$route.params && this.$route.params.search){
                let search = JSON.parse(localStorage.getItem('plannersearchItems'))
                if(search) {
                    this.getSearchItems(search);
                }else{
                    localStorage.removeItem('plannersearchItems');
                    this.getplannerlist();
                }
            }else{
                localStorage.removeItem('plannersearchItems');
                this.getplannerlist();
            }
            // this.getplannerlist();
            this.planner.role='member';
            this.getprofiledata();
            this.colorlist=['#ffcebe','#ffe3c0','#ffe9f0','#F9D8D6','#CDF5F6','#CBE4F9']
        }
    },
    methods: {
        redirecttourl:function(btnstate,rowdata){
        rowdata.backbtnstate=btnstate
        if(btnstate=="view"){
          let routeData = this.$router.resolve({ 
            path: '/planner/viewplan?plannermasterid='+rowdata.id,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
          window.open(routeData.href, '_blank');
        } 
      },
        myListView: function () {
        this.isListDetActive = true;
        this.isgridView=false;
        this.activegridlink=false
        this.activelistlink=true
        $('html, body').animate({
            scrollTop: $("#scrollToListGrid").offset().top -70
        });
        },
        myGridView(){
            this.isListDetActive = false;
            this.isgridView=true;
            this.activegridlink=true
            this.activelistlink=false
            $('html, body').animate({
            scrollTop: $("#scrollToListGrid").offset().top -70
            });
        },
        clearBusinessNameList(state) {
            if(!state) {
                this.search.businesstasknameopts = null
            }
        },
        addModule: function () {
            this.planner.title = null;
            this.planner.groups = null;
            this.planner.privacy = null;
            this.titleBlured = false;
            this.isAddState=true;
            this.iseditstate=false;
        },
        selprivacy(state) {
            this.planner.privacy = null;
            this.planner.privacy = state;
            if (!state && !this.isAddState) {
                this.errors.planner.privacy = "Privacy and Sensitivity is required";
            } else {
                this.errors.planner.privacy = null;
            }
        },
        getplannerlist(limits,objThis,search,isflag){
            if(search) {
                localStorage.setItem('plannersearchItems', JSON.stringify(search))
            }
            this.limits=limits;
            this.isLoading = true;
            this.input={
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            if(isflag==1){
                this.input.offset = 1;
                this.currPageNum = 0;
            }else{
                this.input.offset= this.pageoffset;
            }
            if (search) {
                this.page=1
                this.input.plannercode=this.search.plannercode;
                this.input.btccode=this.search.btccode;
                this.input.btcname=this.search.btcname;
                this.input.plannertitle=parseInt(this.search.plannertitle);
                this.input.datefrom=this.search.datefrom;
                let datetos = ""
                if(this.search.dateto){
                    datetos = new Date(this.search.dateto)
                    datetos.setDate(datetos.getDate() + 1)
                    moment(String(datetos)).format('YYYY-MM-DD');
                }
                this.input.dateto=this.search.dateto?datetos:null
            }
            if (this.limits=='limits') {
                this.input.limit=10;
            }else{
               this.input.limit= apiUrl.LIMIT;
            }
            axios({
                    'method': 'POST',
                    'url': 'api/master/planner/list',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false             
                    if(result.data.errorCode == 0){
                        this.companymasterlist = result.data.data.rows;
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                        setTimeout(function(){
                            $('html, body').animate({
                                scrollTop: $("#plannertable").offset().top -70
                            });
                        }, 500);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.companymasterlist=[];
                        this.totalcount = 0;
                        this.pageCount =0;
                    }
                }).catch(e => {
                    this.displayError(e)
                });

        },
        businessNameChangeSearch(node) {
            if(node && node.length>2) {
                axios({
                    'method': 'POST',
                    'url': 'api/searching/getBusinessTaskname',
                    'data': {
                        empid: parseInt(this.undt.userid),
                        taskname: node.toLowerCase(),
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        this.search.businesstasknameopts=result.data.data
                    } else {
                        this.search.businesstasknameopts= null
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        savewizard(objThis){
            this.valid = this.validateForm();
            if (this.valid) {
                this.isLoading = true;  
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/master/planner/save'
                if (this.iseditstate==true) {
                    apiURL='api/master/planner/update'
                    this.input.id=parseInt(this.planner.id)
                    this.input.isactive=this.planner.isactive
                }
                this.input.plannertitle=this.planner.title
                this.input.groups=this.planner.groups
                this.input.plannertype=this.planner.privacy
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        $(".close").click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resetForm();
                        this.getplannerlist('','',this.search);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        validateForm(){
            this.clearerrorForm();
            this.isAddState=false;
            this.titleBlured=true;
            let isValid=true;
               if(!this.planner.title){
                    isValid= false;
                }
                // if(!this.planner.privacy){
                //     this.errors.planner.privacy="Privacy and Sensitivity is required";
                //     isValid= false;
                // }
            return isValid
        },
        validateMemForm(){
            this.clearerrorForm();
            let isValid1=true;
                if(!this.planner.role){
                    this.errors.planner.role="Role is required";
                    isValid1= false;
                }
            return isValid1
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        clearerrorForm(){
            this.errors={
                planner:{
                    title: null,
                }
            }
        },
        resetForm(){
            this.planner.title=null;
            this.planner.groups=null;
            this.planner.privacy=null;
            this.titleBlured=false;
            this.errors.planner.privacy=null;
            this.planner.membersname=null;
            this.planner.role='member';
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getplannerlist();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#plannertable").offset().top -70
                });
            }, 500);
        },
        openEditModule: function (currRowData) {
        this.editRowData = currRowData;
        this.planner.title = currRowData.plannertitle;
        this.planner.privacy = currRowData.plannertype;
        this.planner.id = currRowData.id;
        this.planner.isactive = currRowData.isactive;
        this.iseditstate = true;
        this.isAddState = true;
        this.getPlannerMemberDetails(currRowData.id);
        },
        searchnewmembers: function (node){
        if(node && node.length>1){
            this.isLoading = true;
            let url = "api/master/planner/getAllEmpEmailIdsdata";
            this.input = {
            emailid:node.toLowerCase(),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            };
            axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.search.plannermemname = result.data.data;
            } else {
                this.search.plannermemname = [];
            }
            });
        }
        },
        selectmember(state){
            this.planner.membersname=null;
            this.planner.membersname=state;
            this.planner.member =this.search.plannermemname.find((obj) => obj.id === state);
        },
        savemember(planner){
            this.valid1 = this.validateMemForm();
            if (this.valid1) {
            if (planner.membersname) {
                this.isLoading = true;  
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/master/planner/savememberdetais'
                this.input.plannermasterid=parseInt(planner.id);
                this.input.memberempcode=planner.member.id;
                this.input.rolename=planner.role;
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;
                    if(result.data.errorCode == 0){
                        this.getPlannerMemberDetails(parseInt(planner.id));
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resetForm()
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                })                
            }
        }
        },
        deletmember(planner){
            this.isLoading = true
            let search = JSON.parse(localStorage.getItem('plannersearchItems'))
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                plannermasterid: parseInt(planner.id),
                memberempcode: parseInt(planner.member.id),
                isactive: 0
            }
            if (search) {
                this.input.searchmember = this.membersname 
            }
            axios({
                'method': 'POST',
                'url': 'api/master/planner/deactivatePlannerMemberDetails',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.getPlannerMemberDetails(parseInt(planner.id));
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getPlannerMemberDetails(datas){
            this.isLoading = true
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                plannermasterid: datas
            }
            axios({
                'method': 'POST',
                'url': 'api/master/planner/getplannermemberdetails',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.memberslist = result.data.data
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.memberslist=[]
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getprofiledata(){
             this.isLoading = true;
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.empid = userdata.userid
                this.apiURL="api/master/imageupload/getPreSignedUrl"
                this.input={
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {         
                    this.isLoading = false;    
                    if(result.data.status){
                        let response = result.data.data;
                        if(response.profilepic){
                            this.profileurl=response.profilepic
                        }
                        this.joiningdate=response.joiningdate
                        this.employeeid = response.empid
                        this.birthday = response.birthday
                        this.reportingto = response.reportto
                        this.year = response.year?(response.year+' yr ') : ''
                        this.month = response.month?response.month+' mos':''
                        this.totalexp =this.year+ ''+this.month
                        this.dept = response.designation+' ['+response.department+']'
                        this.email = response.emailid
                        this.firstname=response.firstname
                        this.lastname=response.lastname
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        plannertitleChangeSearch(node) {
            if(node && node.length>2) {
                axios({
                    'method': 'POST',
                    'url': 'api/master/planner/getplannertitle',
                    'data': {
                        plannertitle: node.toLowerCase(),
                        useremail: this.undt.username,
                        empcode: this.undt.userid
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        this.search.plannertitleotps=result.data.data;
                        localStorage.setItem('plannertitleotps', JSON.stringify(result.data.data))
                    } else {
                        this.search.plannertitleotps= [];
                        localStorage.removeItem('plannertitleotps')
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        clearplannerList(state) {
            if(!state) {
                this.search.plannertitleotps = [];
            }
        },
        resetRecords: function () {
            this.search.plannercode=null;
            this.search.plannertitle=null;
            this.search.btccode=null;
            this.search.btcname=null;
            this.search.businesstasknameopts = null
            this.search.datefrom=null;
            this.search.dateto=null;
            this.search.plannertitleotps = null;
            this.isBothDateSelected=false;
            localStorage.removeItem('plannersearchItems');
            localStorage.removeItem('plannertitleotps');
            this.getplannerlist();
        },
        mysearchrequestollapselist: function () {
            this.isSearchRequestActivelist = !this.isSearchRequestActivelist;
        },
        mysearchrequestollapse: function () {
            this.isSearchRequestActive = !this.isSearchRequestActive;
        },
        notBeforeToday: function (date) {
         this.search.dateto = "";
         return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        disabledBefore(date) {
            let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; 
        },
        selectedstartdate(){
            if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
                this.isBothDateSelected = false
            }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
                this.isBothDateSelected = true
            }
        },
        disableDate(date) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const selectedFromDate = new Date(this.search.datefrom);
            selectedFromDate.setDate(selectedFromDate.getDate()-1);
            return date < selectedFromDate;
        },
        selectedenddate(){
            if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
                this.isBothDateSelected = false
            }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
                this.isBothDateSelected = true
            }
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        if(permission){
            $(".filter_rotation_onlick").click(function(){
                $(this).toggleClass("common_arrow_rotetion");
            }); 
        },
        redirectToViewPlan:function(btnstate,data){
            data.backbtnstate=btnstate
            let plannerid = data.id
            if (btnstate=='planner') {
                this.$router.push({
                    // name: 'ViewPlans',
                    path: `/planner/viewplan?plannermasterid=${plannerid}`,
                    params: {
                        backbtnstate:btnstate,
                        plannerid:plannerid
                    }
                })
            }
        },
        getSearchItems(search) {
            this.isSearchRequestActivelist = true;
            this.search.plannermemname = search.plannermemname,
            this.search.plannercode = search.plannercode,
            this.search.plannertitleotps = JSON.parse(localStorage.getItem('plannertitleotps'));
            this.search.plannertitle = search.plannertitle,
            this.search.datefrom = search.datefrom,
            this.search.dateto = search.dateto,
            this.getplannerlist('','',this.search);
        },
    },
   
}
</script>
