<template>
<div>
    
    <div class="app-content content devcontent ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <section id="dashboard-ecommerce">
                    <div class="row match-height">
                        <div class="col-lg-12 col-12">
                            <div class="row match-height">
                                <div class="col-lg-12 col-12">
                                    <div class="card welcome_pageloginafter">
                                        <h2 style=""> Welcome to <img alt="" src="../../public/images/logo/logonew-1.png"  height="50px"></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default({
    name:'Welcome',
    data() {
        return {
            un:'',
            pagefeatures:[]
        }  
    } ,
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            // location.reload();
            userdata = JSON.parse(userdata)
            this.un=userdata.firstname+' '+userdata.lastname
            this.createdby=userdata.emailaddress
        }
        if (!localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        if (feather) {
            feather.replace({
                width: 14,
                height: 14
            });
        }
    },
})
</script>
<style scoped>
.alignment{
    margin-left: 20px;
    margin-bottom: 20px;
}
</style>