<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12 pb-1">
            <h3 class="content-header-title float-left mb-0">Dynamic Ticket Request Management</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link :to="{ name: 'TCList', params: { search: true }}">
                 Dynamic Ticket Request Management List</router-link>
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Dynamic Ticket</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning">
          <div v-bind:class="[{ show: isRequestActive }, card_content, collapse]">
            <div class="card-header py-1 px-2" style="background-color: #f1f2f3">
              <h3 class="card-title">Create Ticket</h3>
            </div>
            <div class="card-body pt-1">
              <form class="form form-vertical">
                <div class="row">
                  <div class="row mx-0">
                    <div class="col-md-6">
                      <div class="form-group mb-1">
                        <label for="projectlistdd">Ticket Name</label>
                          <input type="text" id="issuename" class="form-control" name="" placeholder="Ticket Name" v-model="ticket.issuename" :disabled="readonlyflds.tcnmfld" @blur="validateForm('t42', $event)"/>
                          <div class="errorinputmsg" @close="validateForm('t42', $event)" v-if="this.errors.ticket.issuename"> {{ this.errors.ticket.issuename }}</div>
                          
                      </div>
                    </div>
                    <div class="col-md-6" v-if="!this.isRedirectedFromFunc">
                      <div class="form-group mb-1">
                        <label for="projectmdllistdd">Project Name</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="ticket.project" :options="ticket.projectlist" @input="selissuecsproject" :disabled="this.isThisMerchandiseProject" @close="validateForm('t39', $event)"/>
                          <div class="errorinputmsg" v-if="this.errors.ticket.project">{{ this.errors.ticket.project }}</div>
                          </div>
                    </div>
                  </div>
                    
               <div class="row mx-0" v-if="!this.isRedirectedFromFunc && this.ticket.project == this.merchandisingProId">
                  <div class="col-md-6">
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Brand</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Brand" v-model="ticket.projectmodulename" :options="ticket.projectmodulelist" @input="selProjectModule" @close="validateForm('t39', $event)" :disabled="readonlyflds.brandfld"/>
                      <div class="errorinputmsg" v-if="this.errors.ticket.projectmodulename">{{ this.errors.ticket.projectmodulename }}</div>
                    </div>
                  </div>
                  <div class="col-md-6" >
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Category</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Category" v-model="ticket.applicationname" :options="ticket.applicationlist" @input="selMerApplication" @close="validateForm('t39', $event)" :disabled="readonlyflds.categoryfld"/>
                      <div class="errorinputmsg" v-if="this.errors.ticket.applicationname">{{ this.errors.ticket.applicationname }}</div>
                    </div>
                  </div>
                  <div class="col-md-6"  >
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Sub Category</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Sub Category" v-model="ticket.applicationmodulename" :options="ticket.applicationmodulelist" @input="selApplicationModule" @close="validateForm('t39', $event)" :disabled="readonlyflds.subcategoryfld"/>
                    <div class="errorinputmsg" v-if="this.errors.ticket.applicationmodulename">{{ this.errors.ticket.applicationmodulename }}</div>
                    </div>
                  </div>
                  <div class="col-md-6"  >
                      <div class="form-group  ">
                      <label for="projectmdllistdd">Product ID/ Product Group ID</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Product ID/ Product Group ID" v-model="ticket.sectionmodulename" :options="ticket.sectionmodulelist" @input="selSectionModule" @close="validateForm('t39', $event)"/>
                      <div class="errorinputmsg" v-if="this.errors.ticket.sectionmodulename">{{ this.errors.ticket.sectionmodulename }}</div>
                    </div>
                  </div>
              </div>
       <div class="col-md-6" v-if="this.ticket.project != this.merchandisingProId">
          <div class="form-group mb-1">
          <label for="projectmdllistdd">Ticket Category</label>
          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Ticket Category" v-model="ticket.projectmodulename" :options="ticket.projectmodulelist" @input="selProType" @close="validateForm('t39', $event)" :disabled="readonlyflds.categoryfld"/>
          <div class="errorinputmsg" v-if="this.errors.ticket.projectmodulename">{{ this.errors.ticket.projectmodulename }}</div>
          </div>
          </div>
          <div class="col-md-6" v-if="this.ticket.project != this.merchandisingProId">
            <div class="form-group mb-1">
              <label for="projectmdllistdd">Ticket Sub Category</label>
              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Ticket Sub Category" v-model="ticket.applicationmodulename" :options="ticket.applicationmodulelist" @input="selSubType" @close="validateForm('t39', $event)" :disabled="readonlyflds.tcsubcategoryfld"/>
              <div class="errorinputmsg" v-if="this.errors.ticket.applicationmodulename">{{ this.errors.ticket.applicationmodulename }}</div>
            </div>
          </div>
          <div class="col-md-6" v-if="this.ticket.project == this.merchandisingProId  && ticket.sectionmodulename">                    
            <div class="form-group mb-1">
                <label for="projectmdllistdd">
                    <small>{{productlabelname}}</small>
                </label>
                <input type="text" id="issuenameadd" class="form-control" name=""  :placeholder="productlabelname" v-model="ticket.issuenameaddnew" />
                <div class="errorinputmsg" v-if="this.errors.ticket.issuenameaddnew">{{ this.productlabelname }} is required.</div>
            </div>
            </div>
            <div class="col-md-6" id="disabledfield">                    
                <div class="form-group mb-1">
                  <label for="projectmdllistdd">
                    <small v-if="this.ticket.project == this.merchandisingProId && ticket.sectionmodulename==this.sectionmoduleId1">Product ID</small>
                    <small v-else-if="this.ticket.project == this.merchandisingProId && this.ticket.sectionmodulename==this.sectionmoduleId2">Product Group ID</small>
                    <small v-else> Functionality </small></label>
                  <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select functionality name" v-model="ticket.functionality" :options="ticket.functionalitylist" @input="selissuecsfunctionality" :disabled="readonlyflds.functionalityfld" @close="validateForm('t42', $event)"/>
                </div>
          </div>

          <div class="col-md-6" v-if="!this.isThisBusinessRole">
            <div class="form-group mb-1">
              <label for="projectmdllistdd">Task</label>
              <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search task" v-model="ticket.task" :options="ticket.tasklist" @close="validateForm('t42', $event)" v-on:search-change="taskChange"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-1"><label for="projectmdllistdd">Ticket Type</label>
              <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket type" v-model="ticket.type" :options="ticket.mrtypelist" @input="selissuecstype" @close="validateForm('t42', $event)" :disabled="readonlyflds.typefld"/>
              <div class="errorinputmsg" v-if="this.errors.ticket.type"> {{ this.errors.ticket.type }}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-1">
              <label for="projectmdllistdd">Ticket Severity</label>
              <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket severity" v-model="ticket.sevairty" :options="ticket.severitylist" @input="selissuecssevairty" @close="validateForm('t42', $event)" :disabled="readonlyflds.sevairtyfld"/>
              <div class="errorinputmsg" v-if="this.errors.ticket.sevairty">{{ this.errors.ticket.sevairty }}</div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-1">
              <label for="projectmdllistdd">Priority 
                <span class="popover-block-container-custom">
                  <span class="popover-block-container-custom">
                    <button type="button" class="popover_top_note" data-toggle="modal" data-target="#ticketcreatepopover">
                      <em class="las la-info-circle text-warning font-medium-5"></em>
                    </button>
                      <div class="modal fade" id="ticketcreatepopover" tabindex="-1" role="dialog" aria-labelledby="ticketcreatepopovertext" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header ticketcreatepopoverbg">
                                        <h5 class="modal-title" id="ticketcreatepopovertext">Note</h5>
                                        </div>
                                    <div class="modal-body">
                                      <ul>
                                        <li v-for="priority in priorityDefination" :key="priority.id">
                                          {{ priority.label }}
                                        </li>
                                      </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </span>
                </span>
              </label>
              <input disabled type="text" id="issuename" class="form-control" name="" v-model="ticket.priority" v-if="this.slapriority == true && ticket.priority"/>

              <treeselect v-else class="projectmdllistdd capitalisetext"  placeholder="Select ticket priority" v-model="ticket.priority" :options="ticket.prioritylist" @input="selissuecspriority" @close="validateForm('t42', $event)" :disabled="readonlyflds.priorityfld"/>
              <div class="errorinputmsg" v-if="this.errors.ticket.priority"> {{ this.errors.ticket.priority }}</div>
                <div v-for="priority in priorityDefination" :key="priority.id" class="informative-msg" v-if="ticket.priority === priority.id">
                  {{ priority.label }}
                </div>
              </div>
          </div>
          <div class="col-md-3">
            <div class="form-group mb-1">
              <label for="">Subscribers</label>
              <treeselect class="projroleassprojectdd" :multiple="true" :clear-on-select="true" placeholder="Enter min 4char to search subscribe" v-model="ticket.subscribers" :options="ticket.subscriberslist" @input="selSubscribers" @close="validateForm('t42',$event)" v-on:search-change="getEmpallocatedtoProjectForSubscriber" :disabled="readonlyflds.subscribersfld"/>
            <div class="errorinputmsg" v-if="this.errors.ticket.subscribers">{{ this.errors.ticket.subscribers }}</div>
          </div>
          </div>
          <div class="col-md-3" v-if="(this.ticket.projectmodulename && this.ticket.project == this.merchandisingProId  && this.assigntoflag)">
            <div>
              <div class="form-group mb-1">
                <label for="allotedto">Assigned To</label>
                <treeselect class="allotedto capitalisetext"  placeholder="Select assigned to" v-model="ticket.allotedto" :options="brandAllocateEmp" @input="selallotedto" @close="validateForm('t42', $event)"/>
                <div class="errorinputmsg" v-if="this.errors.ticket.allotedto"> {{ this.errors.ticket.allotedto }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-3" v-if="this.companycode =='fc' && this.indItHelpDesk!=this.ticket.project && this.ticket.project != this.merchandisingProId && this.assigntoflag">
              <div class="form-group mb-1">
                <label for="allotedto">Assigned To</label>
                <treeselect class="allotedto capitalisetext"  placeholder="Enter min 4char to search assigne" v-model="ticket.allotedto" :options="ticket.allotedtolist" @input="selallotedto" @close="validateForm('t42', $event)" v-on:search-change="getEmpallocatedtoProject" :disabled="readonlyflds.allotedtofld"/>
                <div class="errorinputmsg" v-if="this.errors.ticket.allotedto"> {{ this.errors.ticket.allotedto }}</div>
            </div>
            </div>
            <!-- <div class="row mx-0 w-100">
                    <div class="card-body p-0">
                      <form class="form form-vertical"> -->
                          <div class="col-md-12 px-0">
                            <div class="row row mx-0 w-100" v-if="checkProjectInclusion()">
                                <div class="col-md-6" v-for="(fieldname, i) in this.fieldnamelist" :key="i">
                                    <div class="form-group mb-1" id="disabledfield">
                                      <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                        <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                        <span v-if="fieldname.fieldtype=='text'">
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          @blur="validateConfigForm($event)"
                                          @input="dynamicroster"
                                          v-bind:class="{ 
                                          'form-control': true,
                                          '': !validText(fieldsnamevalues[fieldname.id])}"
                                          @close="validateConfigForm($event)"
                                          v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          @input="dynamicroster"
                                          @blur="validateConfigForm($event)"
                                          v-bind:class="{ 
                                          'form-control': true,
                                          '': !validText(fieldsnamevalues[fieldname.id])}"
                                          @close="validateConfigForm($event)"
                                          v-else />
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                          </p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='number'">
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                          pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                          :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          @input="dynamicroster"
                                          v-bind:class="{
                                          'form-control': true,
                                          '': !validText2(fieldsnamevalues[fieldname.id])}"
                                          />
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='drop-down'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" @input="dynamicroster"
                                            v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')"  disabled/>
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" @input="dynamicroster" v-else/>

                                            
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true" @input="dynamicroster"
                                            v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true" @input="dynamicroster" v-else />
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='checkbox'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" :multiple="true" :clear-on-select="true" @input="dynamicroster"/>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='radio'">
                                            <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                <div class=" form-check form-check-inline mt-0 mb-0">
                                                    <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id" @input="dynamicroster" > <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                </div>
                                            </span>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='date'">
                                            <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                            class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeToday" @input="dynamicroster"></date-picker>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                            <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                            <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='toggle'">
                                            <div class="custom-control custom-switch custom-switch-secondary custom-switch-width-01">
                                                <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]" @input="dynamicroster"/>
                                                <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                    <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                    <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                                </label>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 w-100" v-if="!checkProjectInclusion()">
                              <div class="col-md-6" v-for="(fieldname, i) in getFieldsWithProjectFlag1()" :key="i">
                                  <div class="form-group mb-1">
                                    <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                      <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                      <span v-if="fieldname.fieldtype=='text'">
                                        <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                        @blur="validateConfigForm($event)" @input="dynamicroster"
                                        v-bind:class="{ 
                                        'form-control': true,
                                        '': !validText(fieldsnamevalues[fieldname.id])}"
                                        @close="validateConfigForm($event)"
                                        v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                        
                                        <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                        @blur="validateConfigForm($event)" @input="dynamicroster"
                                        v-bind:class="{ 
                                        'form-control': true,
                                        '': !validText(fieldsnamevalues[fieldname.id])}"
                                        @close="validateConfigForm($event)"
                                        v-else />
                                        <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                          <!-- <span class="errorinputmsg capitalisetext" v-if="this.validationTypes=='required'">{{this.errors.ticket.fieldNames}}====</span> -->
                                        </p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='number'">
                                        <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                        pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                        :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]" @input="dynamicroster"
                                        
                                        v-bind:class="{
                                        'form-control': true,
                                        '': !validText2(fieldsnamevalues[fieldname.id])}"
                                        />
                                        <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='drop-down'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" @input="dynamicroster"
                                          v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" @input="dynamicroster" v-else/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true" @input="dynamicroster"
                                          v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='checkbox'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" :multiple="true" :clear-on-select="true" @input="dynamicroster"/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='radio'">
                                          <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                              <div class=" form-check form-check-inline mt-0 mb-0">
                                                  <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id" @input="dynamicroster" > <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                              </div>
                                          </span>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='date'">
                                          <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                          class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeToday" @input="dynamicroster"></date-picker>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                          <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                          <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='toggle'">
                                          <div class="custom-control custom-switch custom-switch-secondary custom-switch-width-01">
                                              <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]" @input="dynamicroster"/>
                                              <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                  <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                  <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                              </label>
                                          </div>
                                      </span>
                                  </div>
                              </div>
                            </div>
                             <div class="row mx-0 w-100" >
                                <div class="col-md-6" v-for="(fieldname, i) in projectflagfieldnamelist" :key="i">
                                    <div class="form-group mb-1">
                                      <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                        <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                        <span v-if="fieldname.fieldtype=='text'">
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          @blur="validateConfigForm($event)"
                                          v-bind:class="{ 
                                          'form-control': true,
                                          '': !validText(fieldsnamevalues[fieldname.id])}"
                                          @close="validateConfigForm($event)"
                                          @input="dynamicroster"
                                          v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                          
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          @blur="validateConfigForm($event)"
                                          @input="dynamicroster"
                                          v-bind:class="{ 
                                          'form-control': true,
                                          '': !validText(fieldsnamevalues[fieldname.id])}"
                                          @close="validateConfigForm($event)"
                                          v-else />
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                          </p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='number'">
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                          pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                          :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          @input="dynamicroster"
                                          v-bind:class="{
                                          'form-control': true,
                                          '': !validText2(fieldsnamevalues[fieldname.id])}"
                                          />
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='drop-down'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" @input="dynamicroster"
                                            v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled />
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true" @input="dynamicroster"
                                            v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='checkbox'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" :multiple="true" :clear-on-select="true" @input="dynamicroster"/>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='radio'">
                                            <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                <div class=" form-check form-check-inline mt-0 mb-0">
                                                    <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id" @input="dynamicroster" > <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                </div>
                                            </span>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='date'">
                                            <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format" @input="dynamicroster"
                                            class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeToday"></date-picker>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                            <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                            <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='toggle'">
                                            <div class="custom-control custom-switch custom-switch-secondary custom-switch-width-01">
                                                <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]" @input="dynamicroster"/>
                                                <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                    <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                    <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                                </label>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                          </div>
                      <!-- </form>
                    </div>
                  </div> -->
          <div class="form-group col-12">
            <label class="form-label" for="projectmdllistdd">Ticket Scenario</label>
            <ckeditor :editor="editor" placeholder="Ticket Scenario" v-model="ticket.scenario" :config="editorConfig" tag-name="textarea" v-bind:class="{ 'form-control': true, 'is-invalid': !valid2Text(ticket.scenario) && scenarioBlured, }" v-on:blur="scenarioBlured = true"/>
            <div class="invalid-feedback">Enter ticket scenario</div>
          </div>

          <div class="col-md-6">
            <div class="form-group mb-1">
              <label for="reasonofissue">Reason of ticket</label>
              <ckeditor :editor="editor" placeholder="Reason of ticket" v-model="ticket.reasonofissue" :config="editorConfig" tag-name="textarea" v-bind:class="{   'form-control': true,   'is-invalid':     !valid2Text(ticket.reasonofissue) && reasonBlured, }" v-on:blur="reasonBlured = true" :disabled="readonlyflds.reasonofissuefld" />
              <!-- <div v-if="this.companycode != 'xb'" class="invalid-feedback"> Reason of ticket required***</div> -->
              <div class="errorinputmsg" @close="validateForm('t42', $event)" v-if="this.errors.ticket.reasonofissue"> {{ this.errors.ticket.reasonofissue }}</div>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-1">
              <label for="impactofissue">Impact of ticket</label>
              <ckeditor :editor="editor" placeholder="Impact of ticket" v-model="ticket.impactofissue" :config="editorConfig" tag-name="textarea" v-bind:class="{   'form-control': true,   'is-invalid':     !valid2Text(ticket.impactofissue) && impactBlured, }" v-on:blur="impactBlured = true" :disabled="readonlyflds.impactofissuefld"/>
              <!-- <div v-if="this.companycode != 'xb'" class="invalid-feedback"> Impact of ticket required</div> -->
              <div class="errorinputmsg" @close="validateForm('t42', $event)" v-if="this.errors.ticket.impactofissue"> {{ this.errors.ticket.impactofissue }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-1">
              <label for="projectmdllistdd">Status</label>
              <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket status" v-model="ticket.status" :options="ticket.statuslist" :disabled="true" @input="selissuecsstatus" @close="validateForm('t42', $event)"/>
              <div class="errorinputmsg" v-if="this.errors.ticket.status"> {{ this.errors.ticket.status }}</div>
            </div>
          </div>
          <div class="col-md-6" style="margin-top: 24px">
            <div class="form-group">
              <div class="form-group"> 
                <label for="attachment" class="btn btn-sm btn-relief-secondary mb-0 mr-75">Select image/docs/videos</label >
                <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                <div class="table-responsive my-75" v-if="ticket.attachmentview" >
                  <table class="table table-sm table-bordered" aria-describedby="mydesc">
                    <thead>
                      <tr>
                        <th scope="col">SR#</th>
                        <th scope="col">Document Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, k) in ticket.attachmentview.data" :key="k">
                        <td>{{ k + 1 }}</td>
                        <td><a :href="data.presignurl">{{data.link | basename}}</a></td>
                        <td>{{ data.info }}</td>
                        <td>
                          <button type="button" class="btn btn-sm danger" @click="removes3file(k, ticket.attachmentview.data)">
                            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="errorinputmsg" v-if="this.errors.ticket.attachment">{{ this.errors.ticket.attachment }}</div>
                <div class="table-responsive my-75" v-if="file.length > 0">
                  <table class="table table-sm table-bordered" aria-describedby="mydesc">
                    <thead>
                      <tr>
                        <th scope="col">SR#</th>
                        <th scope="col">Document Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(image, key) in file" :key="key">
                        <td>{{ key + 1 }}</td>
                        <td>{{ image.name }}</td>
                        <td>
                          <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="ticket.filedescp[key]"/>
                        </td>
                        <td class="text-center">
                          <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button class="btn btn-sm btn-relief-secondary mr-75" v-on:click.stop.prevent="onUpload()">Upload</button>
              </div>
            </div>
          </div>


      

                  <!-- <div class="col-md-6">
                      <div class="form-group mb-1">
                          <label for="projectlistdd">Sub Ticket Name:</label>
                          <input type="text" id="subtcname" class="form-control" name="" placeholder="Sub Ticket Name" v-model="ticket.subtcname" :disabled="readonlyflds.subtcfld">
                          <div class="errorinputmsg" @close="validateForm('t42', $event)" v-if="this.errors.ticket.subtcname"> {{ this.errors.ticket.subtcname }}</div>
                      </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Sub Ticket Priority</label>
                      <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket Priority" v-model="ticket.subpriority" :options="ticket.subPriorityList" :disabled="readonlyflds.subpriorityfld"  @close="validateForm('t42', $event)"/>
                      <div class="errorinputmsg" v-if="this.errors.ticket.subpriority"> {{ this.errors.ticket.subpriority }}</div>

                    </div>
                  </div> -->
                 
                </div>
                  <div class="row">
                      <div class="col-12 mg-top1 text-right">
                          <button type="button" class="btn btn-relief-primary mr-1" @click="savewizard('t42', $event)" id="submitbtnt42" v-bind:disabled="ticket.disblesavebtn">Submit</button>
                          <button type="reset" class="btn btn-outline-secondary mr-1" @click="resetForm()">Reset</button>
                          <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                      </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.createbtnparentdiv {
  padding-right: 0% !important;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import commonMethods from '../../utils/commonMethods';

import {
  EditIcon,
  EyeIcon,
  Trash2Icon,
  SearchIcon,
  GridIcon,
  CodesandboxIcon,
  CloudIcon,
  ChromeIcon,
  BoxIcon,
  Link2Icon,
  PackageIcon,
  MoreVerticalIcon,
  Edit2Icon,
  PlusIcon,
  DownloadIcon,
  PlusSquareIcon,
  ClipboardIcon,
} from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueTimepicker from 'vue2-timepicker'

export default {
  name: "IssueCreate",
  mixins: [ commonMethods ],

  components: {
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    DownloadIcon,
    Edit2Icon,
    PlusSquareIcon,
    ClipboardIcon,
    ckeditor: CKEditor.component,
    Trash2Icon,
    DatePicker,
    VueTimepicker,
  },
  data() {
    return {
      indItHelpDesk: apiUrl.indItHelpDesk,
      ifItHelpDeskNotFound: apiUrl.ifItHelpDeskNotFound,
      isThisBusinessRole: false,
      roleid: 0,
      productlabelname: null,
      loginuser: "",
      isLoading: false,
      fullPage: true,
      stylevariation: apiUrl.stylevariation,
      companymasterlist: [],
      editRowData: [],
      glbMdl: "",
      glbMdlLbl: "ticket",
      TimeFormat: apiUrl.TimeFormat,
      ticket: {
        issuename: null,
        subtcname: null,
        subpriority: null,
        project: null,
        projectlist: null,
        subscriberslist: [],
        subscribers: null,
        functionality: null,
        functionalitylist: null,
        issuenameaddnew: null,
        type: null,
        typelist: apiUrl.ticketTypeList,
        mrtypelist:[],//apiUrl.mrticketTypelist,
        scenario: null,
        scenariolist: null,
        projectmodulename: null,
        applicationname: null,
        applicationmodulename: null,
        sectionmodulename: null,
        projectmodulelist: null,
        applicationlist: null,
        applicationmodulelist: null,
        sectionmodulelist: null,
        attachment: null,
        attachmentview: null,
        attachmentnew: null,
        sevairty: null,
        severitylist: null,
        priority: null,
        protype: null,
        subType: null,
        prioritylist:apiUrl.issueclassification,
        allotedto: null,
        allotedtolist: [],
        reasonofissue: null,
        impactofissue: null,
        status: 'open',
        statuslist: apiUrl.issueStatuslist,
        subPriorityList: [{
            id:'critical',
            label:'critical'
        },{
            id:'high',
            label:'high'
        },{
            id:'medium',
            label:'medium'
        },{
            id:'minor',
            label:'minor'
        }],
        iseditstate: false,
        disblesavebtn: false,
        task: null,
        tasklist: [],
        filedescp: [],
        ticketresolutiontime: null,
        roasterid:null
      },
      file: [],
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      showproject: false,
      modulelist: [],
      isSearchRequestActive: false,
      card_content: "card-content",
      collapse: "collapse",
      errors: {
        ticket: {
          issuename: null,
          project: null,
          functionality: null,
          type: null,
          scenario: null,
          attachment: null,
          sevairty: null,
          priority: null,
          issuenameaddnew: null,
          allotedto: null,
          reasonofissue: null,
          impactofissue: null,
          status: null,
          task: null,
          projectmodulename: null,
          applicationname: null,
          applicationmodulename: null,
          sectionmodulename: null,
          subscribers: null,
          protype: null,
          subType: null,
          subtcname:null,
          subpriority: null,
          fieldNames:null
        },
      },
      isDetActive: true,
      isRequestActive: true,
      isedit: false,
      card: "card",
      border_warning: "border-warning",
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
            "|",
          ],
        },
      },
      search: {
        searchprojectname: null,
        functionality: null,
        task: null,
        issuename: null,
        type: null,
        sevairty: null,
        priority: null,
        status: null,
        allotedto: null,
        projectnameList: [],
        functionalitylist: [],
        allotedtolist: [],
        tasklist: [],
      },
      projectnameList: [],
      issuenameList: [],
      view: {
        projectname: null,
        functionlityname: null,
        typename: null,
        sevairtyname: null,
      },
      s3bucket: apiUrl.s3url,
      issuenameBlured: false,
      scenarioBlured: false,
      reasonBlured: false,
      impactBlured: false,
      tokendata: null,
      isRedirectedFromFunc: false,
      accepttype:apiUrl.uploadfiletype,
      userrole:null,
      merchandisingProId: apiUrl.merchandisingProId,
      isThisMerchandiseProject: false,
      sectionmoduleId1: apiUrl.sectionmoduleId1,
      sectionmoduleId2: apiUrl.sectionmoduleId2,
      validFileExtensions:apiUrl.validFileExtensions,

      brandAllocateEmp: [],
      itHelpDesk: apiUrl.indItHelpDesk,
      slapriority:false,
      companycode: apiUrl.companycode,
      moduletrackerid: apiUrl.moduletrackerid,
      fieldnamelist: [],
      fieldnamelistTemp:[],
      projectflagfieldnamelist:[],
      fieldsnamevalues:{},
      configurationForm: false,
      slapriority:false,
      xbcreatorList:[],
      multidimensionalArray: [],
      finalvalidationArray: [],
      finalvalidationProjectIDArray: [],
      readonlyflds: {
        subtcfld: false,
        subpriorityfld: false,
        tcnmfld:false,
        functionalityfld:false,
        typefld:false,
        sevairtyfld:false,
        priorityfld:false,
        allotedtofld:false,
        subscribersfld:false,
        brandfld:false,
        categoryfld:false,
        subcategoryfld:false,
        tccategoryfld:false,
        tcsubcategoryfld:false,
        reasonofissuefld:false,
        impactofissuefld:false
      },
      fieldNames:null,
      validationTypes:null,
      displayFields: true,
      priorityDefination: apiUrl.priorityDefination,
      assigntoflag:true,
      autoallotedto:null,
      fieldInfo:[],
      selectedStatus:'',
      BRoleid: apiUrl.BRoleid,
      isInProjectAsBR : false,
      TMroleid: apiUrl.TMroleid,
      POroleid: apiUrl.POroleid,
      SupportEnquiry: apiUrl.SupportEnquiry,
      BRFeatureProList: apiUrl.BRFeatureProList,
      commonFields: [],
    };
  },
  created() {
    this.pagefeatures = {};
    let pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/assignments") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    let userdata = window.localStorage.getItem("userdata");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.undt = userdata;
      this.loginusername = userdata.username;
      this.loginuscondition = userdata.userid;
      this.tokendata = window.localStorage.getItem("token");
      this.userrole = window.localStorage.getItem("userrole")
      this.roleid = this.undt.roleid;
      this.isThisBusinessRole = true;
      // this.ticket.status = "open";
      this.getApplicationdata("t44", "ticket");
      this.getProjectAllocatedToEmp();
      this.getorientationfeedbackformlabels();
      this.getticketcreatormanager();
      // this.getAllfieldnamelist()
      if (this.$route.params.backbtnstate != undefined) {
        if (this.$route.params.backbtnstate == "addissue") {
          let cd = this.$route.params.clickeddata;
          this.isRedirectedFromFunc = true;
          this.ticket.project = cd.projectid;
          this.ticket.functionality = cd.functionlitymasterid;
          this.view.projectname = cd.projectname;
          this.view.functionlityname = cd.functionlitynamewithsection;
        }
      }
    }

  },
  methods: {
    checkIsBusinessRole: function(pid) {
      this.isLoading = true
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
				projectid: pid,
				roleid: this.BRoleid
      }
      let apiUrl = 'api/ticket/getsupportengg'
      axios({
        method: 'POST',
        url: apiUrl,
        data: this.input,
        headers: { authorization: this.tokendata }
      }).then(result => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isInProjectAsBR = true;
          if(this.isInProjectAsBR == true){
            this.ticket.type = this.SupportEnquiry;
            this.getrolenamewithid(pid);
          }
        } else {
          this.isInProjectAsBR = false;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getrolenamewithid : function(projectid) {
        this.isLoading = true
        let url = "api/ticket/getProjWiseSpecificRolewithCount";
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          projectid:projectid,
          roleidarr: [this.TMroleid, this.POroleid],
        };
        axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                if(this.isInProjectAsBR == true && this.ticket.type == this.SupportEnquiry){
                    let tmroless = result.data.data.rows.filter(p => (p.roleid == this.TMroleid));
                    let prroless = result.data.data.rows.filter(p => (p.roleid == this.POroleid));
                    this.ticket.subscribers = [];
                    //task manager
                    if(tmroless && tmroless.length==1)
                    {
                      tmroless.forEach(element => {
                        let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                        //for subscribers
                        const found = this.ticket.subscriberslist.some(el => el.id == element.empid);
                        if(!found) {
                          this.ticket.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                        }
                        const founds = this.ticket.subscribers.some(el => el == element.empid);
                        if(!founds) {
                          this.ticket.subscribers.push(element.empid);
                        }

                        //for alloted to
                        this.ticket.allotedtolist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                        this.ticket.allotedto = element.empid;
                      });
                    }

                    //product owner
                    if(prroless && prroless.length>0)
                    {
                      prroless.forEach(element => {
                        let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                        const found = this.ticket.subscriberslist.some(el => el.id == element.empid);
                        if(!found) {
                          this.ticket.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                        }
                        const founds = this.ticket.subscribers.some(el => el == element.empid);
                        if(!founds) {
                          this.ticket.subscribers.push(element.empid);
                        }
                      });
                    }
                    this.getemployeedetails(this.undt.userid);
                }
              } 
          }).catch(e => {
          this.displayError(e)
      });
    },
    getemployeedetails: function(empid){
        this.isLoading = true;
        this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empid: empid,
            moduletype: 'Appraisal Cycle'
        }
        axios({
            'method': 'POST',
            'url': 'api/feedbackform/getemployeedetails',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
              if(result.data.data.rows.length>0) {
                result.data.data.rows.forEach(element => {
                  let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                  const found = this.ticket.subscriberslist.some(el => el.id == element.empcode);
                    if(!found) {
                      this.ticket.subscriberslist.push({"id":element.empcode,"label": element.empname +'('+tempemplabel2 +')' });
                    }
                    const founds = this.ticket.subscribers.some(el => el == element.empcode);
                    if(!founds) {
                        this.ticket.subscribers.push(element.empcode);
                    }
                });
              }
            }else{
              this.employeedetails=[]
            }
        }).catch(e => {
                this.displayError(e)
        });
    },
    checkProjectInclusion() {
      if (!this.ticket.project) {
        return false;
      }
      const selectedProjectId = parseInt(this.ticket.project);
      // Check if selectedProjectId is included in fieldnamelist
      return this.fieldnamelist.some(field => field.projectid === selectedProjectId && field.projectflag !== 1);
      // return this.fieldnamelist.some(field => field.projectid === selectedProjectId && field.projectflag === 1);
    },  
    getFieldsWithProjectFlag1() {
      return this.fieldnamelist.filter(field => {
        return field.projectflag === 1;
      });
    },
    hasFieldsWithProjectFlag1() {
      return this.fieldnamelist.some(field => field.projectflag === 1);
    },

    getprojectflagfields() {
        this.isLoading = true
        let url = 'api/dynamictickets/getprojectflagfields'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: this.moduletrackerid,
            // projectid: project
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
              this.projectflagfieldnamelist = result.data.data
                for (let i = 0; i < this.projectflagfieldnamelist.length; i++) {
                  if (this.projectflagfieldnamelist[i].fieldtype=="checkbox") {
                      let tempdata = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata[j] = {}
                              tempdata[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalue']=tempdata
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="radio") {
                      let tempdata2 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata2[j] = {}
                              tempdata2[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata2[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['radiooptionalvalue']=tempdata2
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="toggle") {
                      let tempdata5 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata5[j] = {}
                              tempdata5[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata5[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalue3']=tempdata5
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="drop-down") {
                      let tempdata3 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata3[j] = {}
                              tempdata3[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata3[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalu1']=tempdata3
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="multiselect-dropdown") {
                      let tempdata4 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata4[j] = {}
                              tempdata4[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata4[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalue2']=tempdata4
                      }
                  }
              }
          } else if(result.data.errorCode == 3){
              Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
          } else {
              this.projectflagfieldnamelist = [];
          }
      })
  },
  getorientationfeedbackformlabels(project) {
      this.isLoading = true
      this.fieldnamelist = []
      let url = 'api/dynamictickets/getorientationfeedbackformlabels'
      let payload = new Object({
          empcode: this.undt.userid,
          useremail  : this.undt.username,
          moduletracker: this.moduletrackerid,
          projectid: project
      })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.fieldnamelist = result.data.data
                    console.log(this.fieldnamelist,"===========**********")
                     for (let i = 0; i < this.fieldnamelist.length; i++) {
                        if (this.fieldnamelist[i].fieldtype=="checkbox") {
                            let tempdata = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata[j] = {}
                                    tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue']=tempdata
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="radio") {
                            let tempdata2 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata2[j] = {}
                                    tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="toggle") {
                            let tempdata5 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata5[j] = {}
                                    tempdata5[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata5[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue3']=tempdata5
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="drop-down") {
                            let tempdata3 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata3[j] = {}
                                    tempdata3[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata3[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalu1']=tempdata3
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="multiselect-dropdown") {
                            let tempdata4 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata4[j] = {}
                                    tempdata4[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata4[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue2']=tempdata4
                            }
                        }
                    }
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.fieldnamelist = [];
                }
            })
        },

    getAllfieldnamelist(project) {
      this.isLoading = true
      this.fieldnamelist = []
      let url = 'api/dynamictickets/getorientationfeedbackformlabels'
      let payload = new Object({
          empcode: this.undt.userid,
          useremail  : this.undt.username,
          moduletracker: this.moduletrackerid,
          projectid : project
      })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.fieldnamelistTemp = result.data.data
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.fieldnamelistTemp = [];
                }
            })
        },

    getProjectwiseAllfieldnamelist(project) {
      this.isLoading = true
      this.fieldnamelist = []
      let url = 'api/dynamictickets/getorientationfeedbackformlabels'
      let payload = new Object({
          empcode: this.undt.userid,
          useremail  : this.undt.username,
          moduletracker: this.moduletrackerid,
          project : project
      })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.projectfieldnamelistTemp = result.data.data
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.projectfieldnamelistTemp = [];
                }
            })
        },


    getprojectmaprolide(empcode, trackername, valchk1)
      { 
        if(valchk1.length>0)
        {
          let url = "api/fieldtracker/getfieldconfigdataroleid";
          this.input = {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          projectid: [valchk1]
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {    
              if (result.data.errorCode == 0) {
                  this.newroleid = result.data.data[0]['roleid'];
                  this.getfieldconfigdatabyid(empcode, trackername, valchk1);
                  this.getDynamicvalidations(this.newroleid,valchk1)
              }
          });
        }
      },
      async getfieldconfigdatabyid(empcode, trackername, valchk1) {
        try {
          // let projectidarray = []
          // if(valchk1)
          // {
          //   for (var j = 0; j < valchk1.length; j++) {
          //       projectidarray[j]= valchk1[j];
          //   }    
            if(this.newroleid)
            {
              this.isLoading = true
              let url = "api/fieldtracker/getmultiplefieldconfigdatabyid";
              this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                roleid: this.newroleid,
                projectidarray: [valchk1],
                trackername: trackername
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers': { 'authorization': this.tokendata }
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.setSelectedParameter(result.data.data)
                      this.readOnlyFieldsValidation();
                  } else {
                      this.finalvalidationArray = [];
                      this.multidimensionalArray = [];
                  }
              });
            }
          //}
        } catch (error) { 
          console.log('err');
      }

    },
    getDynamicvalidations(newroleid,project) {
        this.isLoading = true
        let url = 'api/dynamictickets/getDynamicvalidation'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: this.moduletrackerid,
            projectid: project,
            roleid: newroleid
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
              this.dynamicfieldsvalidation = result.data.data
                this.getFieldInfo();
              } else if(result.data.errorCode == 3){
              Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
          } else {
              this.dynamicfieldsvalidation = [];
          }
      })
    },
    getFieldInfo() {
        const fieldInfo = [];
        for (const fieldConfig of this.dynamicfieldsvalidation[0].fieldconfig) {
          for (const field of fieldConfig) {
            if (typeof field === 'object') {
              const fieldName = Object.keys(field)[0];
              for (const statusData of field[fieldName]) {
                for (const status in statusData) {
                  const validationType = statusData[status][0].validation[0];
                  fieldInfo.push({
                    fieldName: fieldName,
                    status: status,
                    validation: validationType,
                  });
                }
              }
            }
          }
        }
        this.fieldInfo = fieldInfo;
    },
    setSelectedParameter(variable) {
      const masterkey=[];
      let mprojectid = []
      this.multidimensionalArray = [];
      for (var k = 0; k < variable.length; k++) {
          var fieldconfigstring = JSON.stringify(variable[k].fieldconfig);
          mprojectid.push(variable[k].projectid)
          var fieldconfigstringarray = JSON.parse(fieldconfigstring);
          var firstindex = "";
          var secondindex = "";
          for (var i = 0; i < fieldconfigstringarray.length; i++) {
          var data = "";
          firstindex = Object.keys(fieldconfigstringarray[i][0])[0];
          const textarray = [];
          masterkey.push(firstindex);
              for (var j = 0; j < fieldconfigstringarray[i][0][firstindex].length; j++) {
                  secondindex = Object.keys(fieldconfigstringarray[i][0][firstindex][j])[0];
                  var validationText='';
                  validationText = fieldconfigstringarray[i][0][firstindex][j][secondindex][0]['validation'][0];
                  textarray[secondindex]=validationText;
                  data = textarray;
                  if(this.ticket.status == secondindex )
                  {
                    const fixedKey = firstindex;
                    const newData = validationText;
                    if (!this.multidimensionalArray[fixedKey]){
                        if(this.ticket.status == secondindex )
                        this.$set(this.multidimensionalArray, fixedKey, []);
                    }
                    else
                    {
                        if(this.ticket.status == secondindex )
                        this.$set(this.multidimensionalArray, fixedKey, []);
                    }    
                    this.multidimensionalArray[fixedKey].push(newData);
                  }
              }
          }
      }
      const uniqueArray = [...new Set(masterkey)];
      this.finalvalidationArray = [];
      this.finalvalidationProjectIDArray = [];
      for (var i = 0 ; i < uniqueArray.length; i++)
      {
          if(this.multidimensionalArray.hasOwnProperty(uniqueArray[i])){
            this.finalvalidationArray[uniqueArray[i]]=this.multidimensionalArray[uniqueArray[i]][0];
            this.finalvalidationProjectIDArray[uniqueArray[i]] = mprojectid[i]

          }
         
      }        
    },
    readOnlyFieldsValidation(isValid) {
      if(this.finalvalidationArray['Ticket Name'])
      {
        this.errors.ticket.issuename='';
        if(this.finalvalidationArray['Ticket Name'] == 'Required')
        {
            if(!this.ticket.issuename){
                this.errors.ticket.issuename="Ticket Name is required";
                isValid= false;
            }
            this.readonlyflds.tcnmfld = false;
        } 
        else if (this.finalvalidationArray['Ticket Name'] == 'Read-Only') {
            this.readonlyflds.tcnmfld = true;
        } 
      } 
      if(this.finalvalidationArray['Functionality'])
      {
        this.errors.ticket.functionality='';
        if(this.finalvalidationArray['Functionality'] == 'Required')
        {
            if(!this.ticket.functionality){
                this.errors.ticket.functionality="Functionality is required!";
                isValid= false;
            }
            this.readonlyflds.functionalityfld = false;
        } else if (this.finalvalidationArray['Functionality'] == 'Read-Only') {
            this.readonlyflds.functionalityfld = true;
        } 
      }
      if(this.finalvalidationArray['Ticket Type'])
      {
        this.errors.ticket.type='';
        if(this.finalvalidationArray['Ticket Type'] == 'Required')
        {
            if(!this.ticket.type){
                this.errors.ticket.type="Ticket Type is required!";
                isValid= false;
            }
            this.readonlyflds.typefld = false;
        } 
        else if (this.finalvalidationArray['Ticket Type'] == 'Read-Only') {
            this.readonlyflds.typefld = true;
        } 
      }
      if(this.finalvalidationArray['Ticket Severity'])
        {
          this.errors.ticket.sevairty='';
          if(this.finalvalidationArray['Ticket Severity'] == 'Required')
          {
              if(!this.ticket.sevairty){
              this.errors.ticket.sevairty="Ticket Severity is required!";
              isValid= false;
              }
              this.readonlyflds.sevairtyfld = false;
          }
           else if (this.finalvalidationArray['Ticket Severity'] == 'Read-Only') {
              this.readonlyflds.sevairtyfld = true;
          }
        } 
        if(this.finalvalidationArray['Priority'])
        {
          this.errors.ticket.priority='';
          if(this.finalvalidationArray['Priority'] == 'Required')
          {
              if(!this.ticket.priority){
              this.errors.ticket.priority="Priority is required!";
              isValid= false;
              }
              this.readonlyflds.priorityfld = false;
          }
           else if (this.finalvalidationArray['Priority'] == 'Read-Only') {
              this.readonlyflds.priorityfld = true;
          }
        }
        if(this.finalvalidationArray['Assigned To'])
        {
          this.errors.ticket.allotedto='';
          if(this.finalvalidationArray['Assigned To'] == 'Required')
          {
              if(!this.ticket.allotedto){
              this.errors.ticket.allotedto="Assigned To is required";
              isValid= false;
              }
              this.readonlyflds.allotedtofld = false;
          }
           else if (this.finalvalidationArray['Assigned To'] == 'Read-Only') {
              this.readonlyflds.allotedtofld = true;
          }
        }
        if(this.finalvalidationArray['Subscribers'])
        {
          this.errors.ticket.subscribers='';
          if(this.finalvalidationArray['Subscribers'] == 'Required')
          {
              if(!this.ticket.subscribers){
              this.errors.ticket.subscribers="Subscribers is required!";
              isValid= false;
              }
              this.readonlyflds.subscribersfld = false;
          } 
          else if (this.finalvalidationArray['Subscribers'] == 'Read-Only') {
              this.readonlyflds.subscribersfld = true;
          }
        }
        if(this.finalvalidationArray['Brand'])
        {
          this.errors.ticket.projectmodulename='';
          if(this.finalvalidationArray['Brand'] == 'Required')
          {
              if(!this.ticket.projectmodulename){
                  this.errors.ticket.projectmodulename="Brand is required";
                  isValid= false;
              }
              this.readonlyflds.brandfld = false;
          }
           else if (this.finalvalidationArray['Brand'] == 'Read-Only') {
              this.readonlyflds.brandfld = true;
          } 
        }
        if(this.finalvalidationArray['Category'])
        {
          this.errors.ticket.applicationname='';
          if(this.finalvalidationArray['Category'] == 'Required')
          {
              if(!this.ticket.applicationname){
                  this.errors.ticket.applicationname="Category is required";
                  isValid= false;
              }
              this.readonlyflds.categoryfld = false;
          }
           else if (this.finalvalidationArray['Category'] == 'Read-Only') {
              this.readonlyflds.categoryfld = true;
          } 
        }

        if(this.finalvalidationArray['Sub Category'])
        {
          this.errors.ticket.applicationmodulename='';
          if(this.finalvalidationArray['Sub Category'] == 'Required')
          {
              if(!this.ticket.applicationmodulename){
                  this.errors.ticket.applicationmodulename="Sub Category is required";
                  isValid= false;
              }
              this.readonlyflds.subcategoryfld = false;
          }
           else if (this.finalvalidationArray['Sub Category'] == 'Read-Only') {
              this.readonlyflds.subcategoryfld = true;
          } 
        }
        if(this.finalvalidationArray['Ticket Category'])
        {
          this.errors.ticket.projectmodulename='';
          if(this.finalvalidationArray['Ticket Category'] == 'Required')
          {
              if(!this.ticket.projectmodulename){
                  this.errors.ticket.projectmodulename="Ticket Category is required";
                  isValid= false;
              }
              this.readonlyflds.tccategoryfld = false;
          } 
          else if (this.finalvalidationArray['Ticket Category'] == 'Read-Only') {
              this.readonlyflds.tccategoryfld = true;
          } 
        }
        if(this.finalvalidationArray['Ticket Sub Category'])
        {
          this.errors.ticket.applicationmodulename='';
          if(this.finalvalidationArray['Ticket Sub Category'] == 'Required')
          {
              if(!this.ticket.applicationmodulename){
                  this.errors.ticket.applicationmodulename="Ticket Sub Category is required";
                  isValid= false;
              }
              this.readonlyflds.tcsubcategoryfld = false;
          } 
          else if (this.finalvalidationArray['Ticket Sub Category'] == 'Read-Only') {
              this.readonlyflds.tcsubcategoryfld = true;
          } 
        
        }
        // if(this.finalvalidationArray['Ticket Scenario'])
        // {

        //   this.errors.ticket.scenario='';
        //   if(this.finalvalidationArray['Ticket Scenario'] == 'Required')
        //   {
        //       if(!this.ticket.scenario){
        //           this.errors.ticket.scenario="Ticket Scenario is required!";
        //           isValid= false;
        //       }
        //       this.readonlyflds.scenariofld = false;
        //   } 
        //   else if (this.finalvalidationArray['Ticket Scenario'] == 'Read-Only') {
        //       this.readonlyflds.scenariofld = true;
        //   } 
        // }
        if(this.finalvalidationArray['Reason of ticket'])
        {
          this.errors.ticket.reasonofissue='';
          if(this.finalvalidationArray['Reason of ticket'] == 'Required')
          {
              if(!this.ticket.reasonofissue){
                  this.errors.ticket.reasonofissue="reason of ticket is required!";
                  isValid= false;
              }
              this.readonlyflds.reasonofissuefld = false;
          } 
          else if (this.finalvalidationArray['Reason of ticket'] == 'Read-Only') {
              this.readonlyflds.reasonofissuefld = true;
          } 
        }
        if(this.finalvalidationArray['Impact of ticket'])
        {

          this.errors.ticket.impactofissue='';
          if(this.finalvalidationArray['Impact of ticket'] == 'Required')
          {
              if(!this.ticket.impactofissue){
                  this.errors.ticket.impactofissue=" Impact of ticket is required!";
                  isValid= false;
              }
              this.readonlyflds.impactofissuefld = false;
          } 
          else if (this.finalvalidationArray['Impact of ticket'] == 'Read-Only') {
              this.readonlyflds.impactofissuefld = true;
          } 
        }
      return isValid;
    },

    getEmpallocatedtoProject: function (node) {
      if (this.ticket.project && (node && node.length > 3)) {
        this.isLoading = true;
        let currtab = 'ticket'
        this.input = { projectid: this.ticket.project,useremail: this.undt.username,empcode: this.undt.userid , asigneename:node.toLowerCase()};
      if (this.isThisBusinessRole) {
        this.input.roleid = 484;
      }
      if (this.ticket.project == this.itHelpDesk) {
        this.input.roleid = null;
      }
      axios({
        method: "POST",
        url: "api/listing/getEmpallocatedtoProject",
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (currtab == "ticket") {
            let temparray = [];
            temparray = result.data.data;
            let decryptdepartmenttext = temparray.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
            if(temparray && temparray.length > 0){
              this.ticket.allotedtolist = temparray;
              this.ticket.subscriberslist = temparray;
            }else{
              this.ticket.allotedtolist = result.data.data;
              this.ticket.subscriberslist = result.data.data;
            }
            if (this.ticket.iseditstate) {
              this.ticket.allotedto = this.editRowData.allotedto;
            } else {
              // this.ticket.allotedto = null;
            }
          } else if (currtab == "issueSearch") {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          }
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          if (currtab == "ticket") {
            this.ticket.allotedtolist = [];
            this.ticket.subscriberslist = [];
          } else if (currtab == "issueSearch") {
            this.search.allotedtolist = [];
          }
        }
      });
      }
    },
    getEmpallocatedtoProjectForSubscriber: function (node) {
      if (this.ticket.project && (node && node.length > 3)) {
      this.isLoading = true;
      let currtab = 'ticket'
      this.input = { projectid: this.ticket.project,useremail: this.undt.username,empcode: this.undt.userid , asigneename:node.toLowerCase()};
      if (this.ticket.project == this.itHelpDesk) {
        this.input.roleid = null;
      }
      axios({
        method: "POST",
        url: "api/listing/getEmpallocatedtoProject",
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (currtab == "ticket") {
            let temparray = [];
            temparray = result.data.data;
            let decryptdepartmenttext = temparray.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
            if(temparray && temparray.length > 0){
              this.ticket.allotedtolist = temparray;
              this.ticket.subscriberslist = temparray;
            }else{
              this.ticket.allotedtolist = result.data.data;
              this.ticket.subscriberslist = result.data.data;
            }
            if (this.ticket.iseditstate) {
              this.ticket.allotedto = this.editRowData.allotedto;
            } else {
              // this.ticket.allotedto = null;
            }
          } else if (currtab == "issueSearch") {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          }
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          if (currtab == "ticket") {
            this.ticket.allotedtolist = [];
            this.ticket.subscriberslist = [];
          } else if (currtab == "issueSearch") {
            this.search.allotedtolist = [];
          }
        }
      });
      }
    },
    myrequestcollapse() {
      this.isedit = false;
      this.ticket.iseditstate = false;
      this.ticket.issuename = null;
      this.ticket.project = null;
      this.ticket.functionality = null;
      this.ticket.type = null;
      this.ticket.scenario = "";
      this.ticket.sevairty = null;
      this.ticket.priority = null;
      this.ticket.allotedto = null;
      this.ticket.reasonofissue = null;
      this.ticket.impactofissue = null;
      this.ticket.status = null;
      this.attachment = null;
      this.ticket.attachmentview = null;
      this.clearerrorForm();
    },
    resetForm() {
      this.errors.ticket.allotedto = null;
      this.issuenameBlured = null;
      this.scenarioBlured = null;
      this.reasonBlured = null;
      this.impactBlured = null;
      this.ticket.issuename = null;
      this.ticket.functionality = null;
      this.ticket.type = null;
      this.ticket.scenario = "";
      this.ticket.sevairty = null;
      this.ticket.priority = null;
      this.ticket.allotedto = null;
      this.ticket.reasonofissue = "";
      this.ticket.impactofissue = "";
      this.reasonBlured = null;
      this.impactBlured = null;
      this.ticket.attachment = null;
      this.ticket.attachmentview = null;
      this.ticket.attachmentnew = null;
      this.file = [];
      this.ticket.filedescp = [];
      this.ticket.subscribers = null
      this.ticket.disblesavebtn = false;
      if(!this.isThisMerchandiseProject){
        this.ticket.project = null;
      }
      this.clearerrorForm();
    },
    handleCancel() {
      this.ticket.disblesavebtn = false;
      this.$router.push("/ticket/list");
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      this.$router.push({
        name: "SectionModule",
        path: "/sectionmodule",
        params: {
          backbtnstate: btnstate,
          clickeddata: rowdata,
        },
      });
    },
    savewizard: function (mdl, objThis) {
      this.valid = this.validateForm(mdl, objThis);
      if(this.valid) {
        this.valid = this.validateConfigForm(this.valid);
      }
      if (this.valid) {
        this.isLoading = true;
        let tblNm = "t42";
        if (mdl) tblNm = mdl;
        this.input = {
          tbl: tblNm,
          section: "ticket",
          createdby: this.undt.username,
          empid: this.undt.userid,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          userrole:this.userrole,
        };
        let apiURL = "api/dynamictickets/createDynamicTickets";
        this.input.issuename = this.ticket.issuename;
        this.input.project = this.ticket.project;
        this.input.functionality = this.ticket.functionality;
        this.input.task = this.ticket.task;
        this.input.type = this.ticket.type;
        this.input.scenario = this.ticket.scenario;
        this.input.attachment = this.ticket.attachmentnew;
        this.input.sevairty = this.ticket.sevairty;
        this.input.priority = this.ticket.priority;
        this.input.reasonofissue = this.ticket.reasonofissue;
        this.input.impactofissue = this.ticket.impactofissue;
        this.input.subscribers = this.ticket.subscribers
        this.input.status = this.ticket.status;
        this.input.projectmodulename = this.ticket.projectmodulename;
        this.input.applicationname = this.ticket.applicationname;
        this.input.applicationmodulename = this.ticket.applicationmodulename;
        this.input.sectionmodulename = this.ticket.sectionmodulename;
        this.input.issuenameaddnew = this.ticket.issuenameaddnew;
        this.input.moduletype='ticket'
        this.input.fieldvalues= this.fieldsnamevalues
        this.input.trackerid = this.moduletrackerid
        this.input.roasterid = this.ticket.roasterid ? this.ticket.roasterid : null
        if(this.ticket.ticketresolutiontime){
        let reshour = this.ticket.ticketresolutiontime.hours ? this.ticket.ticketresolutiontime.hours.toString().split('') : '00'.toString().split('')
        let resmin = this.ticket.ticketresolutiontime.minutes ? this.ticket.ticketresolutiontime.minutes.toString().split('') : '00'.toString().split('')
        let resolution_hours = this.ticket.ticketresolutiontime.hours ?  reshour.length < 2 ? '0'+this.ticket.ticketresolutiontime.hours: this.ticket.ticketresolutiontime.hours : '00'
        let resolution_min = this.ticket.ticketresolutiontime.minutes ?  resmin.length < 2 ? '0'+this.ticket.ticketresolutiontime.minutes : this.ticket.ticketresolutiontime.minutes : '00'
        let ticketresolutiontime =  resolution_hours+':'+resolution_min 
        this.input.ticketresolutiontime = this.ticket.ticketresolutiontime? ticketresolutiontime: null
      }
       //ticket responsetime
       if(this.ticket.ticketresponsetime){
        let resphour = this.ticket.ticketresponsetime.hours ? this.ticket.ticketresponsetime.hours.toString().split('') : '00'.toString().split('')
        let respmin = this.ticket.ticketresponsetime.minutes ? this.ticket.ticketresponsetime.minutes.toString().split('') : '00'.toString().split('')
        let response_hours = this.ticket.ticketresponsetime.hours ?  resphour.length < 2 ? '0'+this.ticket.ticketresponsetime.hours: this.ticket.ticketresponsetime.hours : '00'
        let response_min = this.ticket.ticketresponsetime.minutes ?  respmin.length < 2 ? '0'+this.ticket.ticketresponsetime.minutes : this.ticket.ticketresponsetime.minutes : '00'
        let ticketresponsetime =  response_hours+':'+response_min 
        this.input.ticketresponsetime = this.ticket.ticketresponsetime? ticketresponsetime: null
      }
        if(this.ticket.project != null && this.companycode =='xb' ){
            if(this.autoallotedto){
              this.input.allotedto = this.autoallotedto
            }else{
              this.input.allotedto = this.xbcreatorList[0].id
            }
        }
        else{
          if(this.autoallotedto){
            this.input.allotedto = this.autoallotedto
          }else{
            this.input.allotedto = this.ticket.allotedto;
          }
        }
        if (this.ticket.iseditstate == true) {
          apiURL = "api/master/commonmaster/update";
          if (tblNm == "t42") {
            this.input.issueid = this.editRowData.issueid;

            apiURL = "api/issue/update";
          }
          if (this.ticket.attachmentnew && this.ticket.attachment !== null) {
            let jsonobj1 = JSON.parse(this.ticket.attachmentnew);
            let jsonobj2 = this.ticket.attachment;
            let arraymerge = [...jsonobj1.data, ...jsonobj2.data];
            this.input.attachment = JSON.stringify({ data: arraymerge });
          }
          this.input.lastmodifiedby = this.undt.username;
          delete this.input.createdby;
        }
        if (this.isedit) {
          this.isedit = false;
          apiURL = "api/master/commonmaster/update";
          if (tblNm == "t42") {
            this.input.issueid = this.editRowData.issueid;
            apiURL = "api/issue/update";
          }
          this.input.lastmodifiedby = this.undt.username;
          delete this.input.createdby;
        }
        this.input.useremail= this.undt.username
        this.input.empcode= this.undt.userid
        this.isAddState = false;
        
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.file = [];
            this.ticket.filedescp = [];
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            this.myrequestcollapse();
            this.$router.push("/dynamic_tickets/tclist");
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        }).catch(e => {
      this.displayError(e)
    });
      }
    },
    getProjectAllocatedToEmp() {
      this.isLoading = true;
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage: 'createpage'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.ticket.projectlist = result.data.data;
          if(this.ticket.projectlist.length ==1){
              this.ticket.project = this.ticket.projectlist[0].id
              this.isThisMerchandiseProject = true
          }else{
            this.isThisMerchandiseProject = false
          }
        } else {
          this.ticket.projectlist = [];
        }
      });
    },
    projectChange: function (node) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.projectlist = result.data.data;
          } else {
            this.ticket.projectlist = [];
          }
        });
      }
    },
    validText: function (inputval) {
      var re = /[A-Za-z0-9].{4,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{1,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
    notBeforeToday: function (date) {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    valid2Text: function (inputval) {
      var re = /[A-Za-z0-9].{0,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validateForm: function (mdl, objThis) {
      this.isAddState = false;
      var isValid = true;
      this.issuenameBlured = true;
      this.scenarioBlured = true;
      this.reasonBlured = true;
      this.impactBlured = true;
      if (mdl == "t42") {
        if (!this.ticket.issuename) {
          this.errors.ticket.issuename = "Ticket name is required and must be at least 5 characters long";
          isValid = false;
        } else {
          this.errors.ticket.issuename = "";
        }
        if(this.ticket.project == this.merchandisingProId && this.ticket.sectionmodulename != null && this.ticket.sectionmodulename !=''){
            if(!this.ticket.issuenameaddnew){
                this.errors.ticket.issuenameaddnew= this.productlabelname + " is required";
                isValid= false;
            }else{
                this.errors.ticket.issuenameaddnew="";
            }
        }
        if (!this.ticket.project) {
          this.errors.ticket.project = "Project is required";
          isValid = false;
        } else {
          this.errors.ticket.project = null;
        }
        if (!this.ticket.type) {
          this.errors.ticket.type = "Ticket type required";
          isValid = false;
        }
        if (!this.ticket.scenario) {
          this.errors.ticket.scenario = "Ticket scenario required";
          isValid = false;
        } else {
          this.errors.ticket.scenario = "";
        }
        if (!this.ticket.reasonofissue) {
          this.errors.ticket.reasonofissue = "Reason of ticket required";
          isValid = false;
        } else {
          this.errors.ticket.reasonofissue = "";
        }
        if (this.companycode=='xb') {
          this.errors.ticket.reasonofissue = " ";
          isValid = true;
        }
        if (!this.ticket.allotedto  && !this.autoallotedto) {
          this.errors.ticket.allotedto = "Assigned to required";
          isValid = false;
        } else {
          this.errors.ticket.allotedto = "";
        }
        if (this.companycode=='xb') {
          this.errors.ticket.allotedto = " ";
          isValid = true;
        }
        if (!this.ticket.sevairty) {
          this.errors.ticket.sevairty = "Ticket severity required";
          isValid = false;
        }
        if (this.companycode=='xb') {
          this.errors.ticket.sevairty = " ";
          isValid = true;
        }
        if (!this.ticket.priority) {
          this.errors.ticket.priority = "Ticket priority required";
          isValid = false;
        }
        if (this.companycode=='xb') {
          this.errors.ticket.priority = " ";
          isValid = true;
        }
        if (!this.ticket.status) {
          this.errors.ticket.status = "Ticket status required";
          isValid = false;
        }
        if (!this.ticket.impactofissue) {
          this.errors.ticket.impactofissue = "Impact of ticket required";
          isValid = false;
        } else {
          this.errors.ticket.impactofissue = "";
        }
        if (this.companycode=='xb') {
          this.errors.ticket.impactofissue = " ";
          isValid = true;
        }
        if(this.companycode=='xb'){
          this.errors.ticket.subscribers = " ";
          isValid = true;
        }
       else if((!this.ticket.subscribers) || (this.ticket.subscribers && this.ticket.subscribers.length < 1)) {
            this.errors.ticket.subscribers = 'Subscribers are required'
            isValid = false
        } else {
            this.errors.ticket.subscribers = ''
        } 
      }      
      return isValid;
    },
    validateConfigForm(){
      this.isAddState = false;
      var isValid = true;
   
      let ofieldnamelistTemp = this.fieldnamelistTemp.filter(function (el) { 
        return el.projectid == null
      });
      // Field project-wise role config validation
      if(this.fieldnamelistTemp.length > 0 && (this.ticket.project != null && this.ticket.project != '')){
        for (let i = 0; i < this.fieldnamelistTemp.length; i++) {
          if(this.finalvalidationArray[this.fieldnamelistTemp[i].label]) {
              if(this.finalvalidationArray[this.fieldnamelistTemp[i].label] == 'Required')
              {
                if(!this.fieldsnamevalues[this.fieldnamelistTemp[i].id] && this.fieldsnamevalues[this.fieldnamelistTemp[i].id] == '' || this.fieldsnamevalues[this.fieldnamelistTemp[i].id] == null){
                  Swal.fire({
                      title: 'Failed',
                      text: `${this.fieldnamelistTemp[i].label} is required. role config`,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
                  }
              } 
          }  else {
            this.fieldnamelist.forEach(element => {
              if(element.label == this.fieldnamelistTemp[i].label) {
                if(element.projectvalidationtype == "required") {
                  if(!this.fieldsnamevalues[element.id] && this.fieldsnamevalues[element.id] == '' || this.fieldsnamevalues[element.id] == null){
                  Swal.fire({
                      title: 'Failed',
                      text: `${this.fieldnamelistTemp[i].label} is required. field config`,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
                  }
                } else if(element.projectvalidationtype != "required" && element.validationtype == "required") {
                  if( Number(element.projectflag) == 1 && !this.fieldsnamevalues[element.id] && this.fieldsnamevalues[element.id] == '' || this.fieldsnamevalues[element.id] == null){
                  Swal.fire({
                      title: 'Failed',
                      text: `${this.fieldnamelistTemp[i].label} is required. global `,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
                  }
                } 
              }
            });
          }

          //for common field 
          if(ofieldnamelistTemp && ofieldnamelistTemp.length>0) {
            ofieldnamelistTemp.forEach(e => {
              if(e.label == this.fieldnamelistTemp[i].label) {
                if(e.projectvalidationtype != "required" && e.validationtype == "required") {
                  if(!this.fieldsnamevalues[e.id] && this.fieldsnamevalues[e.id] == '' || this.fieldsnamevalues[e.id] == null){
                  Swal.fire({
                      title: 'Failed',
                      text: `${this.fieldnamelistTemp[i].label} is required. common `,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
                  }
                }
              }
            });
          }
        }
      }

      if(this.projectflagfieldnamelist.length > 0 && (this.ticket.project != null && this.ticket.project != '')){
        for (let i = 0; i < this.projectflagfieldnamelist.length; i++) {
          if(this.finalvalidationArray[this.projectflagfieldnamelist[i].label]) {
              if(this.finalvalidationArray[this.projectflagfieldnamelist[i].label] == 'Required' && this.finalvalidationProjectIDArray[this.projectflagfieldnamelist[i].label] == this.ticket.project)
              {
                if(!this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] && this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == '' || this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == null){
                  Swal.fire({
                      title: 'Failed',
                      text: `${this.projectflagfieldnamelist[i].label} is required. role config`,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
                  }
              } 
          }else{
            if(!this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] && this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == '' || this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == null){

            if(this.projectflagfieldnamelist[i].validationtype == 'required'){
              Swal.fire({
                      title: 'Failed',
                      text: `${this.projectflagfieldnamelist[i].label} is required. role config`,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
            }
          }
            // console.log('this.projectflagfieldnamelist[i]================>>>>>>>>',this.projectflagfieldnamelist[i])
          }
        }

      } 
      return isValid;
    },

    updateValidationRulesForFields() {
      for (let i = 0; i < this.fieldnamelist.length; i++) {
        const fieldName = this.fieldnamelist[i].label;
        const validationType = this.getValidationTypeForField(fieldName, this.ticket.status);
        const isReadOnly = validationType === 'Read-Only';
        this.$set(this.fieldnamelist[i], 'isReadOnly', isReadOnly);
      }
    },
  isFieldDisabled(field) {
    if(this.ticket.project && this.ticket.project != null) {
      if(this.finalvalidationArray[field.label]) {  
        if(this.finalvalidationArray[field.label] == 'Read-Only')
        {
          const validationType = this.getValidationTypeForField(field.label, this.ticket.status);
          let result =
          validationType === 'Read-Only' ||
          field.validationtype === 'readonly' ||
          field.validationtype === 'hidden';
          return result;
  
        }
        field.projectvalidationtype = null
        field.validationtype = null
        let result = false;
        return result;
  
      } else {
        const validationType = this.getValidationTypeForField(field.label, this.ticket.status);
        let result =
          validationType === 'Read-Only' ||
          field.validationtype === 'readonly' ||
          field.validationtype === 'hidden';
          return result;
      }
    }
  },

  getValidationTypeForField(fieldName, selectedStatus) {
    const fieldData = this.fieldInfo.find(info => {
        const fieldNameLower = (fieldName || '').toLowerCase();
        const selectedStatusLower = (selectedStatus || '').toLowerCase();
        return info.fieldName.toLowerCase() === fieldNameLower && info.status.toLowerCase() === selectedStatusLower;
    });
    return fieldData ? fieldData.validation : null;
  },

    subvalidateForm: function (mdl, objThis) {
      // this.clearerrorForm();
      this.isAddState = false;
      var isValid = true;
      isValid = this.readOnlyFieldsValidation(isValid);
      if (mdl == "t42") {
        // if (!this.ticket.issuename) {
        //   this.errors.ticket.issuename = "Ticket name must be at least 5 characters long";
        //   isValid = false;
        // } else {
        //   this.errors.ticket.issuename = "";
        // }
        if(this.finalvalidationArray['Sub Ticket Name'])
        {
            this.errors.ticket.subtcname='';
            if(this.finalvalidationArray['Sub Ticket Name'] == 'Required')
            {
                if(!this.ticket.subtcname){
                    this.errors.ticket.subtcname="Sub Ticket Name is required!!!----";
                    isValid= false;
                }
            }
        } 
        // else {
        //     if(!this.ticket.subtcname){
        //         this.errors.ticket.subtcname="Sub Ticket Name is required";
        //         isValid= false;
        //     }else{
        //         this.errors.ticket.subtcname='';
        //     }
        // }
        if(this.finalvalidationArray['Sub Ticket Priority'])
        {
            this.errors.ticket.subpriority='';
            if(this.finalvalidationArray['Sub Ticket Priority'] == 'Required')
            {
                if(!this.ticket.subpriority){
                    this.errors.ticket.subpriority="Sub Ticket Priority is required!";
                    isValid= false;
                }
            }
        } else {
            if(!this.ticket.subpriority){
                this.errors.ticket.subpriority="Sub Ticket Priority is required";
                isValid= false;
            }else{
                this.errors.ticket.subpriority='';
            }
        }
        if(this.ticket.project == this.merchandisingProId && this.ticket.sectionmodulename != null && this.ticket.sectionmodulename !=''){
            if(!this.ticket.issuenameaddnew){
                this.errors.ticket.issuenameaddnew= this.productlabelname + " is required";
                isValid= false;
            }else{
                this.errors.ticket.issuenameaddnew="";
            }
        }
        if (!this.ticket.project) {
          this.errors.ticket.project = "Project is required";
          isValid = false;
        } else {
          this.errors.ticket.project = null;
        }
        if (!this.ticket.type) {
          this.errors.ticket.type = "Ticket type required";
          isValid = false;
        }
        if (this.companycode=='xb') {
          this.errors.ticket.allotedto = " ";
          isValid = true;
        }
        if (!this.ticket.sevairty) {
          this.errors.ticket.sevairty = "Ticket severity required";
          isValid = false;
        }
        if (this.companycode=='xb') {
          this.errors.ticket.sevairty = " ";
          isValid = true;
        }
        if (!this.ticket.priority) {
          this.errors.ticket.priority = "Ticket priority required";
          isValid = false;
        }
        if (this.companycode=='xb') {
          this.errors.ticket.priority = " ";
          isValid = true;
        }
        if (!this.ticket.status) {
          this.errors.ticket.status = "Ticket status required";
          isValid = false;
        }
        if (!this.ticket.impactofissue) {
          this.errors.ticket.impactofissue = "Impact of ticket required";
          isValid = false;
        } else {
          this.errors.ticket.impactofissue = "";
        }
        if (this.companycode=='xb') {
          this.errors.ticket.impactofissue = " ";
          isValid = true;
        }
        if((!this.ticket.subscribers) || (this.ticket.subscribers && this.ticket.subscribers.length < 1)) {
            this.errors.ticket.subscribers = 'Subscribers are required'
            isValid = false
        } else {
            this.errors.ticket.subscribers = ''
        } 
      }      
      return isValid;
    },

    clearerrorForm: function () {
      // avoid mutation
      this.errors.ticket.issuename = null;
      this.errors.ticket.project = null;
      this.errors.ticket.functionality = null;
      this.errors.ticket.type = null;
      this.errors.ticket.scenario = null;
      this.errors.ticket.attachment = null;
      this.errors.ticket.sevairty = null;
      this.errors.ticket.priority = null;
      this.errors.ticket.status = null;
      this.errors.ticket.impactofissue = null;
    },
    openEditModule: function (currRowData, flag) {
      this.editRowData = currRowData;
      this.view.projectname = currRowData.projectname;
      this.view.functionlityname = currRowData.functionlityname;
      this.view.typename = currRowData.typename;
      this.view.sevairtyname = currRowData.sevairtyname;
      this.ticket.issuename = currRowData.issuename;
      this.ticket.project = currRowData.project;
      this.ticket.functionality = currRowData.functionality;
      this.ticket.task = currRowData.taskid;
      this.ticket.type = currRowData.issuetype;
      this.ticket.scenario = currRowData.scenario;
      this.ticket.attachment = currRowData.attachment;
      //this.ticket.attachmentview = currRowData.attachment
      if (
        currRowData.attachment &&
        currRowData.attachment.hasOwnProperty("data")
      ) {
        this.ticket.attachmentview = [];
        this.getDownloadLink(currRowData.attachment);
      }
      this.ticket.sevairty = currRowData.sevairty;
      this.ticket.priority = currRowData.priority;
      this.ticket.allotedto = currRowData.allotedto;
      this.ticket.reasonofissue = currRowData.reasonofissue;
      this.ticket.impactofissue = currRowData.impactofissue;
      // this.ticket.status = currRowData.status;
      this.ticket.iseditstate = true;
      this.isAddState = true;
      if (flag == 0) {
        this.isedit = true;
        this.isRequestActive = true;
        this.mydetailscollapse();
      }
    },
    getDownloadLink(data) {
      if (data.data.length > 0) {
        let payload = {
          documents: data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
          .then((result) => {
            if (result.data.status) {
              this.ticket.attachmentview = result.data;
            }
          });
      }
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD-MM-YYYY");
    },
    addmodule: function () {
      this.ticket.attachmentnew = null;
      this.ticket.issuename = null;
      this.ticket.project = null;
      this.ticket.functionality = null;
      this.ticket.type = null;
      this.ticket.scenario = "";
      this.ticket.attachment = null;
      this.ticket.attachmentview = null;
      this.ticket.sevairty = null;
      this.ticket.priority = null;
      this.ticket.allotedto = null;
      this.ticket.reasonofissue = null;
      this.ticket.impactofissue = null;
      this.ticket.status = null;
      this.ticket.iseditstate = false;
      this.ticket.disblesavebtn = true;
    },
    selSubscribers(state) {
      this.ticket.subscribers = null
      this.ticket.subscribers = state
      if(!state || (state && state.length < 1)) {
        // this.errors.ticket.subscribers = 'Subscribers are required'
      } else {
        this.errors.ticket.subscribers = ''
      }
    },
    selissuecsproject: function (state, value) {
      this.ticket.projectmodulename = null;
      this.ticket.applicationmodulename = null;
      this.ticket.project = null;
      this.ticket.project = state;
      this.ticket.applicationmodulelist = [];
      this.ticket.applicationmodulename = null
      this.ticket.priority = null
      this.slapriority = false
      this.ticket.sectionmodulename = null
      this.ticket.allotedto = null;
      this.ticket.subscribers = null
      this.ticket.allotedtolist = [];
      this.ticket.subscriberslist = [];
       if (!state) {
        this.ticket.functionality = null;
        this.ticket.task = null;
        this.ticket.tasklist = null;
        this.ticket.subscribers = null
        this.ticket.allotedto = null;
        this.ticket.allotedtolist = [];
        this.errors.ticket.project = "Project name is required";
        this.ticket.functionalitylist = null;
        this.ticket.applicationmodulelist = [];
        this.ticket.applicationmodulename = null
        this.ticket.priority = null
        this.slapriority = false
        this.ticket.sectionmodulename = null

       } else {
        this.getprojectmaprolide(this.loginuscondition,this.moduletrackerid,this.ticket.project);

        this.getRosterDetails()
        this.getSLAPriorityDetails()
        this.errors.ticket.project = null;
        // this.getEmpallocatedtoProject(state, "ticket");
        this.getApplicationdata("t37", "ticket");
        this.getprojectmodules(state, 'pmodules');
        this.getprojectmodules(state, 'app');
      //  this.getAppModulesbyprojectid(state);
        if(state == this.merchandisingProId){
          this.getAppModulesbyprojectid(state);
        }
        this.getSectionModules(state);
        if(state == this.merchandisingProId) {
            $('#disabledfield').hide();
        } else {
            $('#disabledfield').show();
        }
        this.tickettypeformrp(state)
        if(state == this.indItHelpDesk) {
            this.autoAssignItHelpDeskUser(state);
        }
        if(this.companycode=='xb'){
          this.getXBProjectCreators(state)
        }
        this.getticketcreatormanager()
        this.ticket.type = null
        this.updateFormFieldsByProject(state);
        this.checkProjectInclusion()
        this.getorientationfeedbackformlabels(state)
        this.getprojectflagfields()
        this.getAllfieldnamelist(state)
        if (this.checkProjectInclusion()) {
          this.displayFields = true; // Set a variable to indicate that fields should be displayed
        }
        if(this.companycode =='fc') {
          if(this.BRFeatureProList.length>0) {
            if(this.BRFeatureProList.includes(parseInt(state))) {
              this.checkIsBusinessRole(state);
            }
          } else {
            this.checkIsBusinessRole(state);
          }
        }
        // this.getDynamicvalidations(state)
      }
    },
    updateFormFieldsByProject(projectId) {
      const selectedProject = this.fieldnamelist.find(item => item.projectid === parseInt(projectId));      
      if (selectedProject) {
        if (selectedProject.fieldtype === 'text') {
          this.fieldsnamevalues[selectedProject.id] = ''; 
        } else if (selectedProject.fieldtype === 'number') {
          this.fieldsnamevalues[selectedProject.id] = 0; 
        } else if (selectedProject.fieldtype === 'drop-down') {
          this.fieldsnamevalues[selectedProject.id] = null; 
        } else if (selectedProject.fieldtype === 'multiselect-dropdown') {
          this.fieldsnamevalues[selectedProject.id] = []; 
        } 
      }
    },
    getXBProjectCreators(projectid) {
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          projectid: projectid,
        };
        axios({
          method: "POST",
          url: 'api/listing/getXBProjectCreator',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.xbcreatorList = result.data.data;
          } else {
            this.xbcreatorList = [];
          }
        });
    },

    autoAssignItHelpDeskUser(id) {
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          projectid: id,
          roleid: 484,
          flag: 0
        };
        axios({
          method: "POST",
          url: 'api/ticket/autoAssignItHelpDeskUser',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.allotedto = result.data.data[0].empid;
          } else {
            this.ticket.allotedto = this.ifItHelpDeskNotFound;
          }
        });
    },
    getTasktypeConfigLists(projectid) {
      let payload = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          projectid: parseInt(projectid.toString()),
          trackername: 'Ticket Request Type',
      };
      axios({
          method: "POST",
          url: '/api/businesstask/getTasktypeConfigList',
          data: payload,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          if(result.data.errorCode == 0){
              let slist = [];
              result.data.data.rows.forEach((rec) => {
                  if(Object.values(rec.statusconfig).length>0){
                      Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                          if(statusnames.length>0) {
                              statusnames.forEach(val => {
                                  if(!slist.some(sdata => sdata.id === val)){
                                      slist.push({ id: val, label: val });
                                  }
                              });
                          }
                      });
                  }
              })
              if(this.ticket.project != null && this.ticket.project == apiUrl.merchandisingProId){
                  this.sortTypeList(slist, 'merchandising')
              } else{
                  this.sortTypeList(slist, 'otherprojects')
              }
          } else {
              this.ticket.mrtypelist = [];
          }
      });
    },
    sortTypeList(typelist, type){
      let numArrFromTypelist = [];
      typelist.forEach(element => {
        if(element.id != null) {
          numArrFromTypelist.push(element.id);
        }
      });
      if(numArrFromTypelist.length>0) {
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'ticket',
          typearr: numArrFromTypelist,
          projecttype: type
        };
        axios({
          method: "POST",
          url: 'api/ticket/sortTypeList',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.mrtypelist = result.data.data.rows;
            this.ticket.mrtypelist.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                  });
          } else {
            this.ticket.mrtypelist = [];
          }
        });
      }
    },
    tickettypeformrp(state){
      this.getTasktypeConfigLists(state);
    },
    selSectionModule(state, value) {
      this.ticket.sectionmodulename = null;
      this.ticket.sectionmodulename = state;
      if (!state) {
        this.errors.ticket.sectionmodulename = "Product ID/ Product Group ID is required";
      } else {
        if(state == this.sectionmoduleId1) {
          this.productlabelname = 'Product ID';
        } else if(state == this.sectionmoduleId2) {
          this.productlabelname = 'Product Group ID';
        }
        this.errors.ticket.sectionmodulename = null;
      }
    },
    selApplication(state, value) {
      this.ticket.applicationname = null;
      this.ticket.applicationname = state;
      this.ticket.applicationmodulelist = [];
      this.ticket.applicationmodulename = null
       if (!state) {
        this.ticket.applicationmodulelist = [];
        this.ticket.applicationmodulename = null
        this.errors.ticket.applicationname = "Category is required";
      } else {
        this.getAppModules(state)
        this.errors.ticket.applicationname = null;
      }
    },
    selMerApplication(state, value) {
      this.ticket.applicationname = null;
      this.ticket.applicationname = state;
       if (!state) {
        this.errors.ticket.applicationname = "Category is required";
      } else {
        this.errors.ticket.applicationname = null;
      }
    },

    selProjectModule(state, value) {
      this.ticket.projectmodulename = null;
      this.ticket.projectmodulename = state;
      
      if (state && this.ticket.project) {
        this.getBrandWiseUserDetails(state, this.ticket.project)
      }

      if (!state) {
        this.errors.ticket.projectmodulename = "Brand is required";

      } else {
        this.errors.ticket.projectmodulename = null;
        // this.getAppModules(state);
      }
    },
    selApplicationModule(state, value) {
      this.ticket.applicationmodulename = null;
      this.ticket.applicationmodulename = state;
      if (!state) {
        this.errors.ticket.applicationmodulename = "Sub category is required";
      } else {
        this.errors.ticket.applicationmodulename = null;
        // this.getSectionModules(state);
      }
    },
    selProType(state) {
      this.ticket.projectmodulename = null;
      this.ticket.projectmodulename = state
      this.ticket.applicationmodulelist = [];
      this.ticket.applicationmodulename = null
      if (!state) {
        this.ticket.applicationmodulelist = [];
      this.ticket.applicationmodulename = null
        this.errors.ticket.projectmodulename = 'Ticket Category is required'
      } else {
        this.getRosterDetails()
        this.getAppModules(state)
        this.errors.ticket.projectmodulename = null
      }
    },
    selSubType(state) {
      this.ticket.applicationmodulename = null;
      this.ticket.applicationmodulename = state
      this.getSLAPriorityDetails()
      if (!state) {
        this.errors.ticket.applicationmodulename = 'Ticket Sub Category is required'
      } else {
        this.getRosterDetails()
        this.errors.ticket.applicationmodulename = null
      }
    },
    getSectionModules(projectid) {
      this.input = {
        projectid: projectid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: 'api/listing/getsectionbyprojectid',
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.ticket.sectionmodulelist = result.data.data;
        } else {
          this.ticket.sectionmodulelist = [];
        }
      });
    },
    getAppModules(moduleid) {
      this.input = {
        projectmoduleid: moduleid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: 'api/listing/getappmodulenamebypromoduleid',
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.ticket.applicationmodulelist = result.data.data;
        } else {
          this.ticket.applicationmodulelist = [];
        }
      });
    },
    // getAppModuleNamesList(applicationid){
    //   this.input = {
    //     applicationid: applicationid,
    //     useremail: this.undt.username,
    //     empcode: this.undt.userid,
    //   };
    //   axios({
    //     method: "POST",
    //     url: 'api/listing/getappmodulenamebyappid',
    //     data: this.input,
    //     'headers':{'authorization':this.tokendata}
    //   }).then((result) => {
    //     this.isLoading = false;
    //     if (result.data.errorCode == 0) {
    //       this.ticket.applicationmodulelist = result.data.data;
    //     } else {
    //       this.ticket.applicationmodulelist = [];
    //     }
    //   });
    // },
    getprojectmodules(projecteid, type) {
      let url = '';
      if(type=='pmodules') {
        url = 'api/listing/getpromodulebyapplicationid';
      } else if(type=='app') {
        url = "api/listing/getappnamebyprojecteid";
      }
      this.input = {
        projecteid: projecteid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      if(type=='pmodules'){
        this.input.isfrompage = 'createpage'
      }
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if(type=='pmodules') {
            this.ticket.projectmodulelist = result.data.data;
            this.ticket.projectmodulelist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
          } 
          if(type=='app') {
            this.ticket.applicationlist = result.data.data;
            this.ticket.applicationlist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
          }
        } else {
          this.ticket.projectmodulelist =[]
          this.ticket.applicationmodulelist = [];
          this.ticket.applicationlist = [];
        }
      });
    },
    getAppModulesbyprojectid(projectid) {
      this.input = {
        projectid: projectid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage:'createpage'
      };
      axios({
        method: "POST",
        url: 'api/listing/getappmodulenamebyprojectid',
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.ticket.applicationmodulelist = result.data.data;
          this.ticket.applicationmodulelist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        } else {
          this.ticket.applicationmodulelist = [];
        }
      });
    },
    selissuecsfunctionality: function (state, value) {
      this.ticket.functionality = null;
      this.ticket.task = null;
      this.ticket.tasklist = null;
      this.ticket.functionality = state;
      if (!state) {
        this.ticket.funisinputdeasabled = false
        this.ticket.task = null;
        this.ticket.tasklist = null;
      } else {
        this.ticket.funisinputdeasabled = true
      }
    },
    taskChange(node) {
      if (node && node.length > 1 && this.ticket.functionality) {
        this.isLoading = true;
        let url = "api/master/commonmaster/getModuleDropdownOption";
        this.input = {
          tbl: "t18",
          taskname: node.toLowerCase(),
          functionalityid: this.ticket.functionality,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.tasklist = result.data.data;
          } else {
            this.ticket.tasklist = [];
          }
        });
      }
    },
    selissuecstype: function (state, value) {
      this.ticket.type = null;
      this.ticket.type = state;
      if (!state && this.isAddState == false) {
        this.errors.ticket.type = "Type required";
      } else {
        this.getRosterDetails()
        this.errors.ticket.type = null;
      }
    },
    selissuecsscenario: function (state, value) {
      this.ticket.scenario = "";
      this.ticket.scenario = state;
      if (!state && this.isAddState == false) {
        this.errors.ticket.scenario = "Scenario required";
      } else {
        this.errors.ticket.scenario = "";
      }
    },
    selissuecssevairty: function (state, value) {
      this.ticket.sevairty = null;
      this.ticket.sevairty = state;
      if (!state && this.isAddState == false && this.companycode != 'xb') {
        this.errors.ticket.sevairty = "Severity required";
      } else {
        this.errors.ticket.sevairty = null;
      }
    },
    selissuecspriority: function (state, value) {
      this.ticket.priority = null;
      this.ticket.priority = state;
      if (!state && this.isAddState == false && this.companycode != 'xb') {
        this.errors.ticket.priority = "Priority required";
      } else {
        this.errors.ticket.priority = null;
      }
    },
    selissuecsstatus: function (state, value) {
      this.ticket.status = null;
      this.ticket.status = state;
      if (!state && this.isAddState == false) {
        this.errors.ticket.status = "Status required";
      } else {
        this.errors.ticket.status = null;
      }
      if(this.ticket.project && this.ticket.project.length>0) {
        this.getprojectmaprolide(this.loginuscondition,this.moduletrackerid,this.ticket.project);
        this.updateValidationRulesForFields();

      }

    },
    selallotedto: function (state, value) {
      this.ticket.allotedto = null;
      this.ticket.allotedto = state;
      if (!state && this.isAddState == false) {
        this.errors.ticket.allotedto = "Assigned To required";
      } else {
        this.errors.ticket.allotedto = null;
      }
    },
    getApplicationdata: function (mdl, curracttab) {
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      let apiURL = "api/master/commonmaster/list";
      if (mdl == "t11") {
        apiURL = "api/listing/getprojectlist";
        this.inputappl = {
          tbl: mdl,
        };
      } else if (mdl == "t37" && curracttab == "ticket") {
        this.ticket.functionality = null;
        this.ticket.functionalitylist = [];
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        apiURL = "api/listing/getfunctionalitybyprojectid";
        this.inputappl.projectid = this.ticket.project;
      } else if (mdl == "t37" && curracttab == "issueSearch") {
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        apiURL = "api/listing/getfunctionalitybyprojectid";
        this.inputappl.projectid = this.search.searchprojectname;
      } else if (mdl == "t43") {
        apiURL = "api/master/commonmaster/customselect";
        /* this.inputappl.projectmoduleid=this.ticket.project */
        this.inputappl.isSearch = false;
        this.inputappl.section = "issue";
        this.inputappl.isFilterSelect = 1;
        this.inputappl.isFilterPayload = {};
      } else if (mdl == "t44") {
        apiURL = "api/master/commonmaster/customselect";
        /* this.inputappl.projectmoduleid=this.ticket.project */
        this.inputappl.isSearch = false;
        this.inputappl.section = "issue";
        this.inputappl.isFilterSelect = 1;
        this.inputappl.isFilterPayload = {};
      } else if (mdl == "t26") {
        apiURL = "api/master/commonmaster/getModuleDropdownOption";
        this.inputappl = {
          tbl: "t26",
        };
      } else if (mdl == "t18" && curracttab == "ticket") {
        this.ticket.task = null;
        this.ticket.tasklist = [];
        apiURL = "api/master/commonmaster/getModuleDropdownOption";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          functionalityid: this.ticket.functionality,
        };
      } else if (mdl == "t18" && curracttab == "issueSearch") {
        apiURL = "api/master/commonmaster/getModuleDropdownOption";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          functionalityid: this.search.functionality,
        };
      }
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (mdl == "t11") {
            if (curracttab == "ticket") {
              // this.ticket.projectlist=result.data.data
              return true;
            }
          } else if (mdl == "t37" && curracttab == "ticket") {
            this.ticket.functionalitylist = result.data.data;
            this.ticket.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            if (this.ticket.iseditstate) {
              this.ticket.functionality = this.editRowData.functionality;
            } else {
              this.ticket.functionality = null;
              this.ticket.task = null;
            }
            return true;
          } else if (mdl == "t37" && curracttab == "issueSearch") {
            this.search.functionalitylist = result.data.data;
            this.search.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            return true;   
          } else if (mdl == "t43") {
            if (curracttab == "ticket") {
              this.ticket.typelist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t44") {
            if (curracttab == "ticket") {
              this.ticket.severitylist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t26") {
            if (curracttab == "ticket") {
              // this.ticket.allotedtolist=result.data.data
              return true;
            }
          } else if (mdl == "t18" && curracttab == "ticket") {
            this.ticket.tasklist = result.data.data;
            if (this.ticket.iseditstate) {
              this.ticket.task = this.editRowData.taskid;
            } else {
              this.ticket.task = null;
            }
            return true;
          } else if (mdl == "t18" && curracttab == "issueSearch") {
            this.search.tasklist = result.data.data;
            return true;
          }
        } else {
          if (mdl == "t37" && curracttab == "ticket") {
            this.ticket.functionalitylist = [];
            return true;
          } else if (mdl == "t18" && curracttab == "ticket") {
            this.ticket.tasklist = [];
            return true;
          }
        }
      });
    },
    onSelect: function (e) {
      this.file = e.target.files[0];
    },
    onUpload() {
      if (this.file) {
        this.isLoading = true;
        if (this.file.length !== 0) {
          this.apiURL = "api/master/fileupload/uploadBulkFileToS3";
          let formData = new FormData();
          let maxsize = 0;
          for (let index = 0; index < this.file.length; index++) {
            formData.append("imagefile", this.file[index]);
            maxsize += this.file[index].size;
          }

          formData.append("empcode", this.undt.userid);
          formData.append("useremail",this.undt.username);
          formData.append("moduletype", "issuemgt");
          formData.append("fcount", parseInt(this.file.length));

          if (maxsize > 1024 * 1024 * 25) {
            this.isLoading = false;
            Swal.fire({
              title: "Failed",
              text: "Your document uploaded above 25mb not allow",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            this.ticket.disblesavebtn = true;
            this.isLoading = false;
            return false;
          }
          axios({
            "content-type": "multipart/form-data",
            method: "POST",
            url: this.apiURL,
            data: formData,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.status) {
              this.ticket.disblesavebtn = false;
              const bindinfo = result.data.data.map((file, index) => {
                return { link: file, info: this.ticket.filedescp[index] };
              });
              let jsonobj = { data: bindinfo };
              this.ticket.attachmentnew = JSON.stringify(jsonobj);
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            } else {
              Swal.fire({
                title: "",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.ticket.attachment = null;
            }
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            title: "",
            text: "Select image/docs/videos",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.ticket.attachment = null;
        }
      } else {
        this.isLoading = false;
        Swal.fire({
          title: "",
          text: "Select image/docs/videos",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        this.ticket.attachment = null;
      }
    },
    onFileChange(e) {
      var validFileExtensions = apiUrl.validFileExtensions
      let selectedFiles = e.target.files;
        for (let i=0; i < selectedFiles.length; i++)
        {
          var blnValid = false;
          for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
              var sCurExtension = validFileExtensions[j];
              if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                  blnValid = true;
                  break;
              }
          }
            
            if (!blnValid) {
                Swal.fire({
                    title: "ERROR",
                    text: "Sorry, Invalid Type of Extension File..!!",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                return false;
            }
            this.file.push(selectedFiles[i]);
        }
        if(this.file.length>0) {
            this.ticket.disblesavebtn=true
        }
        document.getElementById('attachment').value = ""
    },
    removeImage(index) {
      if (this.file.length > 0) {
        this.file.splice(index, 1);
        this.ticket.filedescp.splice(index, 1);
        var removeimg = JSON.parse(this.ticket.attachmentnew);
        if(removeimg){
        var remove = removeimg.data.splice(index, 1);
        if (remove.length > 0)
          this.ticket.attachmentnew = JSON.stringify({ data: removeimg.data });
        else {
          this.ticket.attachmentnew = null;
        }
      }
      }
      if(this.file.length < 1) {
            this.ticket.disblesavebtn = false
           }
    },
    removes3file(key, data) {
      if (data.length > 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.editRowData.issueid !== undefined) {
              let deletefile = data[key];
              data.splice(key, 1);
              const result = data.map(({ presignurl, fileinfo, ...rest }) => ({
                ...rest,
              }));
              let payload =
                result.length > 0 ? JSON.stringify({ data: result }) : null;
              axios({
                method: "POST",
                url: "api/issue/s3deleteissue",
                data: {
                  issueid: this.editRowData.issueid,
                  attachment: payload,
                  deletefile: JSON.stringify(deletefile),
                },
                headers: { authorization: this.tokendata },
              }).then((result) => {
                if (result.data.status) {
                  Swal.fire("Deleted!", result.data.msg, "success");
                } else {
                  Swal.fire("Deleted!", result.data.msg, "error");
                }
              });
            } else {
              Swal.fire("IssueId!", "Issueid should not blank", "info");
            }
          }
        });
      }
    },

    getBrandWiseUserDetails(brandid, projectid) {
      this.isLoading = true;
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        brandid: brandid,
        projectid: parseInt(projectid),
      }
      let apiURL = 'api/thirdparty/getBrandWiseUserDetails'
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },   
      }).then((result) => {
        this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.brandAllocateEmp = result.data.data
          } else {
            this.brandAllocateEmp = [];
          }
      })
    },
    getSLAPriorityDetails() {
    this.isLoading = true;
    this.input = {
      useremail: this.undt.username,
      empcode: this.undt.userid,
      maincategoryid: this.ticket.projectmodulename? this.ticket.projectmodulename : null,
      subcategoryid: this.ticket.applicationmodulename ? this.ticket.applicationmodulename : null,
      project: this.ticket.project ? this.ticket.project : null,
    }
    let apiURL = 'api/slaconfigure/geSLAPriority'
    axios({
      method: "POST",
      url: apiURL,
      data: this.input,
      headers: { authorization: this.tokendata },   
    }).then((result) => {
      this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.slapriority = true
          let d= result.data.data.rows
          
          this.ticket.priority = d[0].issueclassification
          this.ticket.ticketresolutiontime = d[0].resolutiontime
          this.ticket.ticketresponsetime = d[0].responsetime
        } else {
          this.slapriority = false
          this.ticket.priority = null
          this.ticket.ticketresolutiontime = null
          this.ticket.ticketresponsetime = null
         // this.brandAllocateEmp = [];
        }
    }).catch(e => {
      this.displayError(e)
    });
  },
    getticketcreatormanager() {
      this.isLoading = true
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid
      }
      let apiUrl = 'api/ticket/getticketcreatormanager'
      axios({
        method: 'POST',
        url: apiUrl,
        data: this.input,
        headers: { authorization: this.tokendata }
      }).then(result => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if(result.data.data && result.data.data.rows){
            let decryptdepartmenttext = result.data.data.rows.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          }
          this.ticket.subscriberslist = result.data.data.rows;
          this.ticket.subscribers = result.data.data.rows.map(m => m.id);
        } else {
          this.ticket.subscribers = null
        }
      })
    },
    getRosterDetails() {
    this.isLoading = true;
    this.input = {
      useremail: this.undt.username,
      empcode: this.undt.userid,
      category: this.ticket.projectmodulename? this.ticket.projectmodulename : null,
      subcategory: this.ticket.applicationmodulename ? this.ticket.applicationmodulename : null,
      projectid: this.ticket.project ? this.ticket.project : null,
      fieldvalues:this.fieldsnamevalues ? this.fieldsnamevalues : null,
      tickettype: this.ticket.type ? this.ticket.type : null 
    }
    let apiURL = 'api/roaster/getrosterdetails'
    axios({
      method: "POST",
      url: apiURL,
      data: this.input,
      headers: { authorization: this.tokendata },   
    }).then((result) => {
      this.isLoading = false;
         if (result.data.errorCode == 0) {
           let d= result.data.data
          this.assigntoflag = false
          this.autoallotedto = d.length > 0 ? d[0].allocatedto : null
          this.ticket.roasterid =  d[0].roasterid ? Number(d[0].roasterid) : null
          } else {
          this.assigntoflag = true
          this.autoallotedto = null
          this.ticket.roasterid  = null
         // this.brandAllocateEmp = [];
        }
    }).catch(e => {
      this.displayError(e)
    });
  },
  dynamicroster(state){
    this.getRosterDetails()
  }
  },
  filters: {
    basename: function (value) {
      if (!value) return "";
      return value.substr(value.lastIndexOf("/") + 1);
    },
  },
};
</script>