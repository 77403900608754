<template>
    <div class="app-content content">
        <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots"
            :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">



            <section class="modern-horizontal-wizard card_views_hrms" >
                <div class="bs-stepper wizard-modern modern-wizard-example card">
                    <div class="bs-stepper-content pt-0 px-0" >
                        <div class="bs-stepper-header px-0 hrms_header_backgroung ">
                            <div class="step  " >
                                <button type="button" class="step-trigger px-2" data-toggle="tab"
                                    @click="recruitmenttab($event, 'recruitmentteam')">
                                    <span class="bs-stepper-box">
                                        <em data-feather='users' class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"><a class="nav-link " id="recruitment-tab"
                                                @click="recruitmenttab($event, 'recruitmentteam')" role="tab"
                                                aria-controls="recruit" aria-selected="true">Recruitment
                                                Team</a></span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em data-feather="chevron-right" class="font-medium-2"></em>
                            </div>
                            <div class="step">
                                <button type="button" class="step-trigger px-2" data-toggle="tab"
                                    @click="payrolltab($event, 'payrollteam')">
                                    <span class="bs-stepper-box">

                                        <em data-feather='users' class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"><a class="nav-link " id="payroll-tab"
                                                role="tab" aria-controls="payroll" aria-selected="true">Payroll
                                                Team</a></span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em data-feather="chevron-right" class="font-medium-2"></em>
                            </div>
                            <div class="step">
                                <button type="button" class="step-trigger" data-toggle="tab"
                                    @click="SystemInfratab($event, 'systemInfrateam')">
                                    <span class="bs-stepper-box">
                                        <em data-feather='users' class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"><a class="nav-link active" id="systeminfra-tab"
                                                role="tab" aria-controls="systeminfra" aria-selected="true">System
                                                Infrastructure Team</a></span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em data-feather="chevron-right" class="font-medium-2"></em>
                            </div>
                            <div class="step">
                                <button type="button" class="step-trigger" data-toggle="tab"
                                    @click="ldtab($event, 'l&dteam')">
                                    <span class="bs-stepper-box">
                                        <em data-feather="users" class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"><a class="nav-link active" id="ld-tab" role="tab"
                                                aria-controls="ld" aria-selected="true">Learning & Development
                                                Team</a></span>
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div class=" " id="recruit" v-if="ispage && ispage == 'recruitmentteam'">
                            <div class=" mx-0 common-hrms-min-heigh "></div>
                            <section class="card-body pt-1 pb-0 mobile_margin-px-0_00">
                              
                                <div class="tab-content">
                                    <div class="tab-pane active" id="home" aria-labelledby="home-tab" role="tabpanel">
                                        <div class="row mb-2">
                                            <div class="content-header col-6">
                                                <h6 class="mb-0">Recruiter's Team Details</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label" for="vertical-email">Recruiter
                                                        Name</label><span class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Recruiter Name" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Serial No.</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Serail No." aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Candidate's Name</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Candidate's Name" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Candidate's Designation</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Candidate's Designation" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect3">Company Payroll</label><span
                                                        class="required-field"></span>
                                                    <treeselect class="allotedto capitalisetext"
                                                        placeholder="Select Company Payroll" v-model="payroll"
                                                        clear-on-select="true" :options="payrolllist" />

                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect3">Type of Employee</label><span
                                                        class="required-field"></span>
                                                    <treeselect class="allotedto capitalisetext"
                                                        placeholder="Select Type of Employee" v-model="type"
                                                        clear-on-select="true" :options="typelist" />

                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-1 basic-select2">
                                                <label for="basicSelect">Reporting Manager</label><span
                                                    class="required-field"></span>
                                                <input type="text" id="" class="form-control"
                                                    placeholder="Enter Reporting Manager" aria-label="john.doe" />
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Reporting Manager EmailID</label><span
                                                        class="required-field"></span>
                                                    <input typbuttone="text" id="" class="form-control"
                                                        placeholder="Enter Reporting Manager EmailID"
                                                        aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Store ID(Only for stores) for other
                                                        Department</label><span class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Reporting Manager EmailID"
                                                        aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-1 basic-select2">
                                                <label for="basicSelect">State</label><span class="required-field"></span>
                                                <input type="text" id="" class="form-control" placeholder="Enter State Name"
                                                    aria-label="john.doe" />
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Country Name</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Country Name" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Date Of Joining</label><span
                                                        class="required-field"></span>
                                                    <input type="date" id="" class="form-control"
                                                        placeholder="Enter Reporting Manager EmailID"
                                                        aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4 mb-1 basic-select2">
                                                <label for="basicSelect">IT Assets</label><span
                                                    class="required-field"></span>
                                                <input type="text" id="" class="form-control" placeholder="Select IT Assets"
                                                    aria-label="john.doe" />
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">New Joinier's Personal EmailID</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter New Joinier's EmailID" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">New Joinier's Mobile No.</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter New Joinier's Mobile No."
                                                        aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Recruiter's Name</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Select Recruiter's Name" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Recruiter's EmailID</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Select Recruiter's EmailId" aria-label="john.doe" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="buttons_hrms_view text-right px-1">
                                            <button class="btn btn-relief-secondary  hrms-bt-mr-5">
                                                <span class="align-middle d-sm-inline-block d-none">Cancel</span>
                                            </button>
                                            <button class="btn btn-relief-success btn-next4 hrms-bt-mr-5">
                                                <span class="align-middle d-sm-inline-block">Save as Draft</span>
                                            </button>
                                            <button class="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </div>

                        <div id="payroll" v-if="ispage && ispage == 'payrollteam'">
                            <div class=" mx-0 common-hrms-min-heigh"></div>
                            <section class="card-body pt-1 pb-0 mobile_margin-px-0_00">
                                <div class="tab-content">
                                    <div class="tab-pane active " id="ldteam" aria-labelledby="ld-tab" role="tabpanel">
                                        <div class="row mb-2">
                                            <div class="content-header col-6">
                                                <h6 class="mb-0">Payroll Team Details</h6>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label" for="vertical-email">Offer Status</label><span
                                                        class="required-field"></span>
                                                    <treeselect class="allotedto capitalisetext" placeholder="Select Status"
                                                        v-model="status" :clear-on-select="true" :options="statuslist" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Documents Recived</label><span
                                                        class="required-field"></span>
                                                    <treeselect class="allotedto capitalisetext"
                                                        placeholder="Select Documents" v-model="documentlist"
                                                        :clear-on-select="true" :options="documentNamelist" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Genrate Employee ID</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Genrated Employee ID" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Genrate Punch ID</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Genrated Punch ID" aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect3">Proposed Email ID</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Proposed Email ID" aria-label="john.doe" />

                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect3">Zing HRAccess</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Zing HR Access" aria-label="john.doe" />

                                                </div>
                                            </div>

                                        <div class="buttons_hrms_view text-right px-1">
                                            <button class="btn btn-relief-secondary btn-prev4 hrms-bt-mr-5">
                                                <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                            </button>
                                            <button class="btn btn-relief-success btn-next4 hrms-bt-mr-5">
                                                <span class="align-middle d-sm-inline-block">Save as Draft</span>
                                            </button>
                                            <button class="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div id="systeminfra" v-if="ispage && ispage == 'systemInfrateam'">
                            <div class=" mx-0 common-hrms-min-heigh"></div>
                            <section class="card-body pt-1 pb-0 mobile_margin-px-0_00">
                                <div class="tab-content">
                                    <div class="tab-pane active " id="system" aria-labelledby="system-tab" role="tabpanel">
                                        <div class="row mb-2">
                                            <div class="content-header col-6">
                                                <h6 class="mb-0">System Infra Details</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label" for="vertical-email">Email ID Creation &
                                                        Password Creation</label><span class="required-field"></span>
                                                    <treeselect class="allotedto capitalisetext" placeholder="Select Course"
                                                        v-model="status" :clear-on-select="true" :options="statuslist" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Wifi Access</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control" placeholder="Wifi Access"
                                                        aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">VPN Access</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control" placeholder="VPN Access"
                                                        aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Assest Issued</label><span
                                                        class="required-field"></span>
                                                    <treeselect class="allotedto capitalisetext" placeholder="Select Course"
                                                        v-model="Assetstatus" :clear-on-select="true"
                                                        :options="Assetstatuslist" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect3">Corrier Details</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Corrier Details" aria-label="john.doe" />

                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect3">Shipping Hardware Details</label><span
                                                        class="required-field"></span>
                                                    <input type="text" id="" class="form-control"
                                                        placeholder="Enter Shipping Hardware Details"
                                                        aria-label="john.doe" />

                                                </div>
                                            </div>
                                        </div>
                                        <div class="buttons_hrms_view text-right px-1">
                                            <button class="btn btn-relief-secondary btn-prev4 hrms-bt-mr-5">
                                                <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                            </button>
                                            <button class="btn btn-relief-success btn-next4 hrms-bt-mr-5">
                                                <span class="align-middle d-sm-inline-block">Save as Draft</span>
                                            </button>
                                            <button class="btn btn-primary">Save</button>
                                        </div>
                                    </div>
                                </div>

                            </section>
                        </div>

                        <div id="ld" v-if="ispage && ispage == 'l&dteam'">
                            <div class=" mx-0 common-hrms-min-heigh"></div>
                            <section class="card-body pt-1 pb-0 mobile_margin-px-0_00">
                                <div class="tab-content">
                                    <div class="tab-pane active " id="ldteam" aria-labelledby="ld-tab" role="tabpanel">
                                        <div class="row mb-2">
                                            <div class="content-header col-6">
                                                <h6 class="mb-0">L&D Details</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label" for="vertical-email">Orientation
                                                        Completed</label><span class="required-field"></span>
                                                    <input type="date" id="" class="form-control" placeholder=" "
                                                        aria-label="john.doe" />
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="basicSelect">Mandatory Training</label><span
                                                        class="required-field"></span>
                                                    <treeselect class="allotedto capitalisetext" placeholder="Select Course"
                                                        v-model="course" :multiple="true" :clear-on-select="true"
                                                        :options="courselist" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="buttons_hrms_view text-right px-1">
                                            <button class="btn btn-relief-secondary btn-prev4 hrms-bt-mr-5">
                                                <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                            </button>
                                            <button class="btn btn-relief-success btn-next4 hrms-bt-mr-5">
                                                <span class="align-middle d-sm-inline-block">Save as Draft</span>
                                            </button>
                                            <button class="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";


import apiUrl from '../../constants'
import DatePicker from "vue2-datepicker";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
    name: "Joining Process",
    components: {
        Treeselect,
        DatePicker,


        Loading
    },
    data() {
        return {
            defaultValue: new Date(),
            isLoading: false,
            fullPage: true,
            ispage: 'recruitmentteam',
            totalcount: 0,
            pageCount: 0,
            page: 1,
            currPageNum: 0,
            pageoffset: 1,
            isDetailsActive: false,
            isDetailsActive2: false,
            globaljdid: 0,
            type: null,
            typelist: [
                { id: 1, label: 'Onroll' },
                { id: 2, label: 'Contract' },
                { id: 3, label: 'Traniee' },
                { id: 4, label: 'Intern' }


            ],
            payroll: null,
            payrolllist: [
                { id: 1, label: 'BB' },
                { id: 2, label: 'DR' },
                { id: 3, label: 'ED' },
            ],
            status: null,
            statuslist: [
                { id: 1, label: 'Created' },
                { id: 2, label: 'In Process' },
                { id: 3, label: 'Shared' }
            ],
            documentlist: null,
            documentNamelist: [
                { id: 1, label: 'Adhar Card' },
                { id: 2, label: 'Pan Card' },
                { id: 3, label: 'Resume' },
                { id: 4, label: 'Educational Docs' }
            ],
            selectedEducationalDoc: null,
            educationalDocsList: [
                { id: 1, label: ' SSC' },
                { id: 2, label: 'HSC ' },
                { id: 3, label: ' Gradution' },
                { id: 4, label: 'PG' }
            ],
            status: null,
            statuslist: [
                { id: 1, label: 'Created' },
                { id: 2, label: 'In Process' },
                { id: 3, label: 'Shared' }
            ],
            Assetstatus: null,
            Assetstatuslist: [
                { id: 1, label: 'Issued date' },
                { id: 2, label: 'Laptop' },
                { id: 3, label: 'Destop' },
                { id: 4, label: 'None' }
            ],
            course: null,
            courselist: [
                { id: 1, label: 'Code of Conduct' },
                { id: 2, label: 'POSH' },
                { id: 3, label: 'Cyber Security' }
            ]
        }
    },
    methods: {
        setGlobaljdid: function (jdid) {
            this.globaljdid = jdid;
        },



        recruitmenttab(event, setpage) {
            this.ispage = setpage;
            this.totalcount = 0;
            this.pageCount = 0;
            this.page = 1;
            this.currPageNum = 0;
            this.pageoffset = 1;
            this.isDetailsActive = false;
            this.isDetailsActive2 = false;
        },
        payrolltab(event, setpage) {
            this.ispage = setpage;
            this.totalcount = 0;
            this.pageCount = 0;
            this.page = 1;
            this.currPageNum = 0;
            this.pageoffset = 1;
            this.isDetailsActive = false;
            this.isDetailsActive2 = false;
        },
        SystemInfratab(event, setpage) {
            this.ispage = setpage;
            this.totalcount = 0;
            this.pageCount = 0;
            this.page = 1;
            this.currPageNum = 0;
            this.pageoffset = 1;
            this.isDetailsActive = false;
            this.isDetailsActive2 = false;
        },
        ldtab(event, setpage) {
            this.ispage = setpage;
            this.totalcount = 0;
            this.pageCount = 0;
            this.page = 1;
            this.currPageNum = 0;
            this.pageoffset = 1;
            this.isDetailsActive = false;
            this.isDetailsActive2 = false;
        },
     
    },


};

</script>
 