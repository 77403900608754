<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard" class="pb-1">
        <div class="content-header-left mb-1">
        <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left px-0">
                <h3 class="content-header-title float-left">Manage Employee</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/assessment/examsetting">Exam Setting</router-link>
                    </li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls">
                      Manage Employee
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="card border-warning filter_card_searchbgst" >
            <div class="card-header filter_rotation_onlick" style="cursor: pointer" v-on:click="mysearchrequestollapse">
              <div class="container-fluid" >
              <div class="row fc_my-05">
                <div class="w-auto px-1 mobile-px-0">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                      <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                    </div>
                  </div>
                  <h4 class="card-title d-inline-flex ml-1">Search Employee Details</h4>
                </div>
                <span class="rotetions_view_arrow ml-auto mx-1"> 
                <em class="las la-angle-down"></em>
              </span>
              </div>
              </div>
            </div>
            <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <label class="form-label lable-left">Employee Name</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter Min 4Chars To Search Employee Name" v-model="search.empname" :options="emplist" v-on:search-change="getEmplist"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-0">
                    <label class="form-label lable-left">Course code</label>
                    <input type="text" class="form-control" placeholder="Enter Course code" v-model="search.coursecode"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <label class="form-label lable-left">Course Name</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter Min 4 Chars To Search Course Name"
                    v-model="search.coursename"  :multiple="true" :clear-on-select="true" v-on:search-change="courseChange" :options="coursenamelist"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <label class="form-label lable-left">Course Status</label>
                    <treeselect class="projectmdllistdd" placeholder="Select Course Status"
                    v-model="search.coursestatus" :options="courseStatus" />
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <label class="form-label lable-left">Created-By</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search createdby" v-model="search.createdby" :options="search.projectcreatedbyopts" v-on:search-change="createdByChangeSearch"/>
                   </div>
                   <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3" >
                   <label for=""> Created From Date</label>
                    <date-picker placeholder="Select Created Date From" v-model="search.startdatefrom" :disabled-date="notBeforeToday"  valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search.</div> 
                  </div>
                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3">
                    <label for="">Created To Date</label>
                      <date-picker placeholder="Select Created Date To" v-model="search.startdateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-12 mb-0 text-right">
                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getManageempList(search)" ><search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetSearchrecords()" ><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                    <button type="move" class="btn btn-relief-primary mt-2" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div id="scrollToListGrid"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row" id="table-small">
                  <div class="col-12">
                    <div class="card">
                      <div class="table-responsive" v-if="showtabledata">
                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                          
                          <thead>
                            <tr>
                              <th scope="col" class="align-middle first-col-sticky" style="min-width:45px">Sr#</th>
                              <th scope="col" class="align-middle text-left second-col-sticky" style="min-width:120px;">Employee ID</th>
                              <th scope="col" class="align-middle text-left" style="width:160px;">Employee Name</th>
                              <th scope="col" class="align-middle text-center">Department</th>
                              <th scope="col" class="align-middle text-left">Designation</th>
                              <th scope="col" class="align-middle text-left">Reporting Manager</th>
                              <th scope="col" class="align-middle text-left" >Course Code</th>
                              <th scope="col" class="align-middle text-left" >Course Name</th>
                              <th scope="col" class="align-middle text-left" >Periodic Name</th>
                              <th scope="col" class="align-middle text-left" >Assessment Title</th>
                              <th scope="col" class="align-middle text-center">Course Status</th>
                              <th scope="col" class="align-middle text-center">Exam Attempted</th>
                              <th scope="col" class="align-middle text-center">Result </th>
                              <th scope="col" class="align-middle text-center">Marks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(data, index) in manageempList">
                              <tr v-bind:key="data.moduleid">
                                <td class="centeralign first-col-sticky">{{ index + 1 + currPageNum * pagelimit }}</td>
                                <td class="text-nowrap second-col-sticky text-left">{{data.empid}}</td>
                                <td class="text-nowrap text-left capitalisetext">{{data.empname}}</td>
                                <td class="text-nowrap text-center capitalisetext">{{decryptText(data.department)}}</td>
                                <td class="text-nowrap text-left capitalisetext">{{decryptText(data.designation)}}</td>
                                <td class="text-nowrap text-left capitalisetext">{{data.mngrid.split("@")[0]}}</td>
                                <td class=" text-left capitalisetext" style="min-width:200px;">{{data.coursecode}}</td>
                                <td class=" text-left capitalisetext" style="min-width:200px;">{{data.coursename}}</td>
                                <td class=" text-left capitalisetext" style="min-width:200px;">{{data.periodicname}}</td>
                                <td class=" text-left capitalisetext" style="min-width:200px;">{{data.assessmenttitle}}</td>
                                <td class="text-center">
                                    <div v-if="data.iscompleted==true" class="capitalisetext  badge badge-pill badge-light-success mr-50"> Completed</div>
                                    <div v-if="data.iscompleted==false" class="capitalisetext  badge badge-pill badge-light-warning mr-50"> Incomplete</div>
                                </td>
                                <td class="text-nowrap text-center">
                                  <div v-if="data.results" class="capitalisetext  badge badge-pill badge-light-success mr-50">Yes</div>
                                  <div v-if="!data.results" class="capitalisetext  badge badge-pill badge-light-warning mr-50">No</div>
                                </td>
                                <td class="text-center">
                                    <div v-if="data.results=='pass'" class="capitalisetext  badge badge-pill badge-light-success mr-50"> Pass</div>
                                    <div v-if="data.results=='fail'" class="capitalisetext  badge badge-pill badge-light-warning mr-50"> Failed</div>
                                    <div v-if="data.results=='not attempted'" class="capitalisetext  badge badge-pill badge-light-danger mr-50"> Not Attempted</div>
                                </td>
                                <td class="text-nowrap text-center">{{data.totalcorrectans}}</td>
                             </tr>
                            </template>
                            <template 
                            v-if="manageempList.length == 0 && isresponce" >
                              <tr>
                                <td colspan="14" style="text-align: center;">No record found</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pageCenter text-center">
              <pagination
              v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import { SearchIcon,PlusIcon,XIcon,ExternalLinkIcon,MoreVerticalIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import commonMethods from '../../utils/commonMethods';
export default {
  name: "ManaeEmpList",
  components: {
    Loading,
    Treeselect,
    SearchIcon,
    Pagination,
    PlusIcon,
    DatePicker,
    XIcon,
    ExternalLinkIcon,
    MoreVerticalIcon
  },
  mixins: [ commonMethods ],
  data() {
    return {
      isSearchRequestActive: false,
      isBothDateSelected:false,
      isLoading: false,
      fullPage: true,
      glbMdl: "",
      maxLength:2,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      loginuscondition: "",
      isDetActive: false,
      displayblk:'displayblk',
      card: "card",
      card_content: "card-content",
      collapse: "collapse",
      showtabledata:true,
      loginusername:'',
      tokendata:null,
      isresponce:false,
      manageempList: [],
      search:{
        emplist:null,
        empname: null,
        reportingmanager: null,
        coursename: null,
        coursestatus: null,
        coursecode: null,
        createdby: null,
        projectcreatedbyopts: null,
        startdatefrom:null,
        startdateto:null,
      },
      emplist:[],
      reportingmanagerList:[],
      coursenamelist:null,
      courseStatus:apiUrl.courseStatus,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.tokendata = window.localStorage.getItem('token');
        this.loginuscondition = userdata.userid;
        this.loginusername=userdata.username
        this.empid = userdata.userid
        this.getManageempList()
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });  
  },
  methods: {
    getManageempList(search) {
      this.isLoading = true;
      this.manageempList = [];
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
      };
      let url = "api/manageemp/getManageempList";
      if(search) {
        this.page=1  
      }   
      this.input.empid = this.search.empname,
      this.input.coursecode= this.search.coursecode
      this.input.createdby  = this.search.createdby 
      this.input.subscribedcourse = this.search.coursename ? this.search.coursename:null
      this.input.iscompleted = this.search.coursestatus
      this.input.empcode = this.undt.userid
      this.input.useremail= this.undt.username   
      this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
      let assignedate = ''
        if(this.search.startdateto){
          assignedate = new Date(this.search.startdateto)
          assignedate.setDate(assignedate.getDate() + 1)
          assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
        }
      this.input.dateto = this.search.startdateto?assignedate:null
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce = true;
          this.manageempList = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
          this.manageempList = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
          this.displayError(e)
      });
    },
    resetSearchrecords: function(){
        this.search.empname = null;
        this.search.coursename = null;
        this.search.coursestatus = null;
        this.search.coursecode = null;
        this.search.createdby = null;
        this.search.startdatefrom = null;
        this.search.startdateto = null;
        this.isBothDateSelected = false
        this.emplist = [];
        this.getManageempList();
    },
    courseChange: function (node){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/coursemaster/getallcoursenamelist";
          this.input = {
          coursename:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.coursenamelist = result.data.data;
              this.coursenamelist.sort((a, b) => {
                 return a.label.localeCompare(b.label);
              });
            } else {
              this.coursenamelist = [];
            }
          });
        } 
    },
    createdByChangeSearch: function (node){
    if(node && node.length>1){
      this.isLoading = true;
      let url = "api/listing/getAllEmpEmailIds";
      this.input = {
        emailid:node.toLowerCase(),
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.search.projectcreatedbyopts = result.data.data;
        } else {
          this.search.projectcreatedbyopts = [];
        }
      }).catch(e => {
      this.displayError(e)
    });
    }
     },
    selectedfromdate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      let selectedFromDate = new Date(this.search.startdatefrom);
      selectedFromDate.setDate(selectedFromDate.getDate()-1)
      return date < selectedFromDate;
    },
    notBeforeToday: function (date) {
        this.search.startdateto = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
    selectedtodate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    getEmplist(node) {
      if(node && node.length > 3){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.emplist = result.data.data;

            let decryptdepartmenttext = this.emplist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.emplist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    mydetailsollapse() {
      this.isDetActive = true
    },
    parseValue(val) {
        if(val) {
          return parseInt(val)
        }
        return 0
    },

    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.page = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if(inputEle == 't26'){
          this.getManageempList()
        } else{
        this.getManageempList();
      }
    },

    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
      };
        this.input.empid = this.search.empname,
        this.input.subscribedcourse = this.search.coursename,
        this.input.iscompleted = this.search.coursestatus,
        this.input.empcode = this.undt.userid
        this.input.useremail= this.undt.username
        this.input.coursecode= this.search.coursecode
        this.input.createdby  = this.search.createdby 
        this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
        let assignedate = ''
        if(this.search.startdateto){
          assignedate = new Date(this.search.startdateto)
          assignedate.setDate(assignedate.getDate() + 1)
          assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.dateto = this.search.startdateto?assignedate:null
      axios({
        method: "POST",
        url: `api/manageemp/getManageempList`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download(result.data.data.rows,filename)
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
      }).catch(e => {
          this.displayError(e)
        });
    },

    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
        var ShowLabel = 'ManageEmp'+filename
        var JSONData = readExportdata
        var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        var CSV = ''
        if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
              row += index + ','
          }
          row = row.slice(0, -1)
          CSV = 'Sr#,Employee ID, Employee Name, Department, Designation, Reporting Manager, Course Name, Course Completed, Assessment Title, Test Given, Result, Marks' + '\r\n'
        }

        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let empid = index.hasOwnProperty('empid') ? index.empid : '';
          let empname = index.hasOwnProperty('empname') ? index.empname : '';
          // let department = index.hasOwnProperty('department') ? index.department : '';
          let department = index.department ? this.decryptText(index.department) : ''

          // let designation = index.hasOwnProperty('designation') ? index.designation : '';
          let designation = index.designation ? this.decryptText(index.designation) : ''

          let mngrid = index.hasOwnProperty('mngrid') ? index.mngrid : '';
          let coursename = index.hasOwnProperty('coursename') ? index.coursename : '';
          let iscompleted = index.hasOwnProperty('iscompleted') ? index.iscompleted : '';
          let assessmenttitle = index.hasOwnProperty('assessmenttitle') ? index.assessmenttitle : '';
          let result = index.hasOwnProperty('results') ? index.result : '';
          let results = index.hasOwnProperty('results') ? index.results : '';
          let totalcorrectans = index.hasOwnProperty('totalcorrectans') ? index.totalcorrectans : '';
            row += '"' + srno + '",',
            row += '"' + empid + '",',
            row += '"' + empname + '",',
            row += '"' + department + '",',
            row += '"' + designation + '",',
            row += '"' + mngrid + '",',
            row += '"' + coursename + '",',
            row += '"' + `${(iscompleted==true) ? 'Completed':'Incomplete'}` + '",',
            row += '"' + assessmenttitle + '",',
            row += '"' + `${(!result) ? 'Yes':'NO'}` + '",',
            row += '"' + `${(results=='pass') ? 'Pass':'Failed'}` + '",',
            row += '"' + totalcorrectans + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'ManageempList'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });      
    }
  },
  export (data, filename, mime) {
    var data
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    download(blob, filename, mime)
  },
  },
};
</script>
<style scoped>
.displayblk{
    display:none !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
.bordertable .row1{
  color:black;
}
</style>