<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Section Module</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Wizard',path:'project',btnstate:'view',rowdata:backpagedata})">Projects</li>
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application',path:'application',btnstate:'view',rowdata:backpagedata})">Applications</li>
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Module',path:'projectmodule',btnstate:'view',rowdata:backpagedata})">Modules</li>
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application Module',path:'applicationmodule',btnstate:'view',rowdata:backpagedata})">Application Modules</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">
                    <div class="row">
                        <div class="col-12 ">
                            <div  v-if="pagefeatures.searchapplicationmodule" class="card card-body" style="margin-top: 10px">
                                <div class="row breadcrumbs-top">
                                    <div class="col-12 title">
                                        <h4 class="card-title">SEARCH SECTION DETAILS</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Project Module</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.projectModuleid"
                                            :options="search.projectModuleOpts"
                                            @input="selprojectModuleid"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Application</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationMasterid"
                                            :options="search.applicationMasterOpts"
                                            @input="selApplicationid"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Application Module</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationModuleid"
                                            :options="search.applicationModuleOpts"
                                        />
                                    </div>
                                </div>
                                <div class="custom-btns">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-2 mr-1"
                                            @click="getFilteredSectionData()"
                                        >
                                            Search
                                        </button>
                                        <button
                                            type="reset"
                                            class="btn btn-outline-secondary mt-2"
                                            @click="resetRecords()"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                            </div>
                            <div  v-if="pagefeatures.applicationmoduledetails" class="card border-warning filter_card_searchbgst">
                                <div class="card-header" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                                    <div class="avatar bg-light-info rounded" style="margin-left:29%">
                                        <div class="avatar-content">
                                            <grid-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></grid-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title" style="margin-left:-429px;">APPLICATION MODULE DETAILS</h4>
                                    <div class="heading-elements" style="color:blue;">
                                        <ul class="list-inline mb-0">
                                            <li>
                                                <a data-action="collapse"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:10px">
                                    <div class="card-body">
                                        <div class="row">
                                        <div  v-for="(data,index1) in modulelist" v-bind:key="data.moduleid" class="col-lg-3 col-md-6 col-12">
                                            <div v-bind:key="data.moduleid" class="card border cursor-pointer" @click="getsectionlist(data)" :class="[index1 < 9 ? stylevariation[index1].label : 'bg-secondary']">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between text-white">
                                                        <box-icon size="40x" class="custom-class"></box-icon>
                                                    </div>
                                                    <div class="my-1">
                                                        <h4 class="capitalisetext text-white">{{data.applicationmodulename}}</h4>
                                                        <h5 class="capitalisetext text-white">{{dateFormat(data.createddate)}}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card border-warning filter_card_searchbgst" >
                        <div class="card-header filter_rotation_onlick px-lg-1" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                            <div class="container-fluid">
                                <div class="row fc_my-05 " >
                                    <div class="w-auto mobile-padding-0-2">
                                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                                            <div class="avatar-content">
                                                <search-icon
                                                size="1.5x"
                                                class="custom-class avatar-icon font-medium-3"
                                                ></search-icon>
                                            </div>
                                            </div>
                                        <h4 class="card-title d-inline-flex ml-1">Search Section Details</h4>
                                    </div>
                                    <div class="ml-auto d-flex">
                                    <div class=" px-1 mobile-padding-0-2" v-if="pagefeatures.createsectionmodule || showbutton">
                                                                    <div class="input-group-append displayblk float-right">
                                                                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#projectappmaster" type="button" data-backdrop="static" data-keyboard="false" @click="addmodule">
                                                                         <span class="mobile_hide_bt"> Create Section</span> <plus-circle-icon  size="1.5x" class="custom-class"></plus-circle-icon></button>
                                                    </div>
                                                </div>
                                                <div v-else id="commonaddmodule" data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#projectappmaster"></div>
                                                <span class="rotetions_view_arrow"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                </div>
                            </div>
                        </div>
                      <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                        <div class="card-body pt-2 px-sm-mobile-10">
                          <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Project Name</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @select="selprojectid"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Application</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select application name" v-model="search.applicationMasterid" :options="search.applicationMasterOpts" @input="selApplicationid"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Project Module</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project module" v-model="search.projectModuleid" :options="search.projectModuleOpts" @select="selprojectModuleid2"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Application Module Name</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select application module name" v-model="search.applicationModuleid" :options="search.applicationModuleOpts" @select="selApplicationModuleid"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Section Name</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select section name" v-model="search.sectionMasterid" :options="search.sectionMasterOpts"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1 text-right">
                              <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getsectionlist(search)"> <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                              <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span> </button>
                              <button type="move" class="btn btn-relief-primary mt-2" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="showproject">
                        <div class="col-md-12">
                            <div class="card mb-3">
                                    <div v-if="pagefeatures.createsectionmodule">
                                <div class="card-header p-2">
                                        <div class="input-group-append">
                                            <h4 class="card-title">Project Application Module: <strong class="capitalisetext">{{sectionmodule.applicationmodulename}}</strong></h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="sectionModuleList">
                                                <div class="col-12">
                                                    <div class="">
                                                        <div class="table-responsive datatableview" >
                                                            <table class="table table-sm dataTableListing table-hover table-striped" aria-describedby="mydesc">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="srno align-middle py-1">Sr#</th>
                                                                        <th scope="col" class="align-middle" style="min-width:180px;">Section Name</th>
                                                                        <th scope="col" class="align-middle">Project</th>
                                                                        <th scope="col" class="align-middle">Project Module</th>
                                                                        <th scope="col" class="align-middle">Application module</th>
                                                                        <th scope="col" class="align-middle text-center">Total Functionality</th>
                                                                        <th scope="col" class="align-middle" style="min-width:100px;">Created Date</th>
                                                                        <th scope="col" class="clsaction align-middle">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-for="(data,index) in companymasterlist">        
                                                                        <tr v-bind:key="data.moduleid">
                                                                            <td data-column="Sr#" class="srno">{{ index+1+currPageNum*pagelimit }}</td>
                                                                            <td data-column="Section Name">
                                                                                <span class="font-weight-normal cursorpointercls sitebluecolor" @click="redirecttourl('view',data)">{{data.sectionname}}</span>
                                                                            </td>
                                                                            <td data-column="Project">
                                                                                <span class="font-weight-normal  capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectId',data)">{{data.projectname}}</span>
                                                                            </td>
                                                                            <td data-column="Project Module">
                                                                                <span class="font-weight-normal  capitalisetext">{{data.projectmodulename}}</span>
                                                                            </td>
                                                                            <td data-column="Application module">
                                                                                <span class="font-weight-normal  capitalisetext">{{data.applicationmodulename}}</span>
                                                                            </td>
                                                                            <td data-column="Total Functionality" class="centeralign">
                                                                                 <span
                                                                                  class="font-weight-normal  cursorpointercls sitebluecolor capitalisetext" style="cursor:pointer;" @click="redirecttourl('view', data)">
                                                                                   {{data.totalfunctionality}}
                                                                                   </span></td>
                                                                            <td data-column="Created Date" class="">{{dateFormat(data.createddate)}}</td>
                                                                            <td data-column="Actions" class="clsaction">
                                                                                <div class="dropdown">
                                                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                        <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                    </button>
                                                                                    <div class="dropdown-menu">
                                                                                        <div v-if="data.projectlevel == 5">
                                                                                            <a class="dropdown-item devEditEle" href="javascript:void(0);" @click="redirecttowsurl('view',data)">
                                                                                                <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                <span>View Workstep</span>
                                                                                            </a>
                                                                                            <a class="dropdown-item" @click="redirecttowsurl('edit',data)" href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                                <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                                <span>Add Workstep</span>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div v-else>
                                                                                            <a class="dropdown-item" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                                                <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                <span>View Functionality</span>
                                                                                            </a>
                                                                                            <a class="dropdown-item" @click="redirecttourl('edit',data)" href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                                <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                                <span>Add Functionality</span>
                                                                                            </a>
                                                                                        </div>
                                                                                        <a class="dropdown-item devEditEle" href="javascript:void(0);"   data-toggle="modal" data-target="#projectappmaster" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                            <span>Edit</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="companymasterlist.length==0 && !isresponce">
                                                                        <tr><td colspan="14" style="text-align:center;">No record found</td></tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="pageCenter pt-2 text-center">
                                    <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
        <div class="modal modal-slide-in fade" id="projectappmaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"  style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:#9467bd !important;">
                        <h5 class="modal-title"  style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle ml-50" >Add/Update Section Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                        <div class="row">
                            <div class="col-12 mb-1">
                                <div class="table-responsive">
                                  <table class="table table-sm table-hover dataTableListing" aria-describedby="mydesc">
                                    <thead>
                                      <tr>
                                        <th scope="col" class="align-middle">Project</th>
                                        <th scope="col" class="align-middle">Application</th>
                                        <th scope="col" class="align-middle">Project Module</th>
                                        <th scope="col" class="align-middle">Application Module</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <td class="font-weight-normal ">
                                            {{this.sectionmodule.project ? this.sectionmodule.project:'NA'}}
                                            </td>
                                        <td class="font-weight-normal ">
                                         <span class="text-nowrap" v-html="showAppNames(this.sectionmodule.applicationname)"></span>
                                        </td>
                                        <td style="min-width:170px" class="font-weight-normal ">
                                            {{this.sectionmodule.projectmodule ? this.sectionmodule.projectmodule:'NA'}}
                                            </td>
                                        <td style="min-width:170px" class="font-weight-normal ">
                                            {{this.sectionmodule.applicationmodulename ? this.sectionmodule.applicationmodulename:'NA'}}
                                            </td>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="modulenamet12">Section Name</label>
                                       <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><package-icon size="1.5x" class="custom-class"></package-icon></span>
                                        </div>
                                        <input type="text" class="form-control" name="" placeholder="Section Name"  v-model="sectionmodule.sectionname" v-bind:class="{'form-control':true, '' : !validText(sectionmodule.sectionname)}" />
                                    </div>
                                    <div class="errorinputmsg" v-if="!validText(sectionmodule.sectionname)">Section name must be  greater than 5 char, and you must enter a letters</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="prmoduledescription">Section Description</label>
                                    <textarea class="form-control"  name="" rows="3" placeholder="Application Description" v-model="sectionmodule.sectiondescription" v-bind:class="{'form-control':true, '' : !validText(sectionmodule.sectiondescription)}"></textarea>
                                    <div class="errorinputmsg" v-if="!validText(sectionmodule.sectiondescription)">Section description must be  greater than 5 char</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-relief-primary mr-1"  v-on:click.stop.prevent="saveData('t35',$event)" id="submitbtnt12">Submit</button>
                                <button style="margin-right:2px;" type="button" class="btn btn-outline-secondary" @click="reset()">Clear</button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>       
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}

.title {
    text-align: center;
}
.custom-btns {
    display: flex;
    justify-content: center;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { ExternalLinkIcon,PlusSquareIcon,PlusCircleIcon,XIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';

export default {
    name:'SectionModule',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,PlusCircleIcon,XIcon,ExternalLinkIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            showbutton:false,
            isLoading: false,
            fullPage: true,
            islistLoading:false,
            listfullPage:true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            sectionmodule:{
                project:null,
                projectmodule:null,
                appprojectdd:null,
                projectmoduleid:null,
                label:null,
                moduleid:null,
                applicationmoduleid:null,
                applicationmodulelist:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                applicationid:null,
                applicationname:null,
                applicationlist:null,
                sectionname:null,
                sectiondescription:null,
                applicationmodulename:null,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                iseditstate:false
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            pageoffset:1,
            currPageNum: 0,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            search: {
                applicationModuleid: null,
                applicationModuleOpts: [],
                applicationMasterid: null,
                applicationMasterOpts: [],
                projectMasterid: null,
                projectMasterOpts: [],
                projectModuleid: null,
                projectModuleOpts: [],
                searchProjectname:null,
                projectmodulelistopts: [],
                sectionMasterid: null,
                sectionMasterOpts: [],
            },
            projectnameList:[],
            backpagedata:null,
            tokendata:null,
            isresponce:false,
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/sectionmodule') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
            }
            this.getmodulelist();
            let rowdata={};
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view') {
                    let search = JSON.parse(localStorage.getItem('sectionsearchItems'));
                    if(this.$route.params.backbtnstate != 'edit' && search && (search.hasOwnProperty("searchProjectname") || search.hasOwnProperty('projectid') || search.hasOwnProperty('projectname'))) {
                        if(!this.projectnamelist){
                            this.projectChangebyid([this.$route.params.clickeddata.projectid]);
                        }
                        this.backpagedata = this.$route.params.clickeddata;
                        if(this.$route.params && this.$route.params.clickeddata && this.$route.params.clickeddata.projectid){
                            this.search.searchProjectname = this.$route.params.clickeddata.projectid;
                            rowdata.searchProjectname = this.search.searchProjectname;

                            this.search.applicationMasterid = this.$route.params.clickeddata.applicationid ? this.$route.params.clickeddata.applicationid :null;

                            this.search.projectModuleid = this.$route.params.clickeddata.projectmoduleid ? this.$route.params.clickeddata.projectmoduleid :null;

                            this.search.applicationModuleid = this.$route.params.clickeddata.applicationmoduleid ? this.$route.params.clickeddata.applicationmoduleid :null;
                        }
                        // this.getsectionlist(search);
                        this.getSearchItems(search)
                    } else if(this.$route.params.backbtnstate != 'edit'){
                        localStorage.removeItem('sectionsearchItems');
                        this.showbutton=true
                        this.backpagedata=this.$route.params.clickeddata
                        this.getsectionlist(this.$route.params.clickeddata)
                        if(this.$route.params.clickeddata.projectmoduleid){
                            this.getprojectmoduleapplications(this.$route.params.clickeddata)
                        }
                    }
                    if(this.$route.params.backbtnstate == 'edit'){
                        this.backpagedata=this.$route.params.clickeddata;
                        this.getprojectmoduleapplications(this.$route.params.clickeddata);
                        this.sectionmodule.project = this.backpagedata.projectname;
                        this.sectionmodule.appprojectdd = this.backpagedata.projectid;
                        this.sectionmodule.projectmodule = this.backpagedata.projectmodulename;
                        this.sectionmodule.projectmoduleid = this.backpagedata.projectmoduleid;
                        this.sectionmodule.applicationmodulename = this.backpagedata.applicationmodulename;
                        this.sectionmodule.applicationmoduleid = this.backpagedata.applicationmoduleid;
                        this.search.searchProjectname = this.backpagedata.projectid ? this.backpagedata.projectid :null;
                        this.search.applicationMasterid = this.backpagedata.applicationid ? this.backpagedata.applicationid :null;
                        this.search.projectModuleid = this.backpagedata.projectmoduleid ? this.backpagedata.projectmoduleid :null;
                        this.search.applicationModuleid = this.backpagedata.applicationmoduleid ? this.backpagedata.applicationmoduleid :null;
                        this.projectChangebyid([this.backpagedata.projectid]);
                        this.getsectionlist(this.$route.params.clickeddata)
                        if(this.search.searchProjectname && this.search.searchProjectname !=null){
                            this.getDropdownApplicationdata("t12");
                        }
                        if(this.search.applicationModuleid){
                            this.getDropdownApplicationdata("t34");
                        }
                        if(this.search.projectModuleid){
                            this.getprojectmoduleapplications(this.$route.params.clickeddata)
                        }
                        this.showbutton=true;
                        setTimeout(function(){
                            $('#commonaddmodule').click();
                        }, 1000);
                    }
                 }
            }else{
                localStorage.removeItem('sectionsearchItems');
                this.getsectionlist(this.search)
            }
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        showAppNames(prlist){
            let responsetr='';
         if(!prlist) return 'NA'
         if(typeof(prlist) != 'object') return prlist
         if(prlist.length > 1){
          for(var i=0;i<prlist.length;i++){
              responsetr +=(i+1)+'. '+prlist[i]+' <br/>'
        }
      }else{
        for(var i=0;i<prlist.length;i++){
            responsetr +=prlist[i]
        }
      }
      return responsetr
    },
         getProjectDataByProjectID(projectmasterid){
            this.isLoading=true
            let url = 'api/listing/getprojectlistbycompanyid'
            this.input = {
                "companyid":null,
                "projectmasterid":projectmasterid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.search.projectnameList=result.data.data;
                }else{
                    this.search.projectnameList=[]
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        redirecttobreadkumb:function(objThis){
            
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(rowdata.projectlevel == 5) {
                this.$router.push({ 
                    name: 'Work Step',
                    path: '/workstep',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                return
            }
            else if (btnstate == 'view' || btnstate == 'edit') {
            this.$router.push({ 
                name: 'FuntionalityModule',
                path: '/functionalitymodule',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
            } else if (btnstate == 'projectId') {
				this.$router.push({
					path: '/project/summary?pid='+rowdata.projectid,
				})
			}
        },
       
        redirecttowsurl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'Work Step',
                path: '/workstep',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        selappapltype(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
         redirecttourl1:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'flist',
                path: '/functionaly/flist',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        getDropdownData(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            
            axios({
            'method': 'POST',
            'url': apiUrl,
            tbl: "t11",
            'data': {useremail: this.undt.username,empcode: this.undt.userid},
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data
                        localStorage.setItem('projectMasterOpts', JSON.stringify(result.data.data))
                    } else {
                        this.search.projectMasterOpts = null
                        localStorage.removeItem('projectMasterOpts')
                    }
                }).catch(e => {
                this.displayError(e)
            })    
        },
        getFilteredSectionData: function() {
            this.isLoading=true
            let uri = "api/projectapplication/getAplicationModulelist"
            this.input = {
                tbl: "t34",
                offset: 1,
                limit: 100,
                isSearch: false,
                section: "sectionmodule",
                isFilterSelect: 1,
                isFilterPayload: {},
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            axios.post(uri, this.input)
                .then(result => {
                    this.isLoading=false
                    if (result.data.errorCode == 0) {
                        this.isSearchRequestActive=true;
                        this.showproject = false;
                        this.modulelist = result.data.data;
                    } else {
                        this.modulelist = [];
                        Swal.fire({
                            title: "",
                            text: "No Data found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                this.displayError(e)
            })
        },
        resetRecords: function () {
            this.projectnameList = null;
            this.search.applicationModuleid = null;
            this.search.applicationMasterid = null;
            this.search.projectMasterid = null;
            this.search.projectModuleid = null;
            this.isSearchRequestActive=true;
            this.search.searchProjectname = null;
            localStorage.removeItem("sectionsearchItems");
            localStorage.removeItem("sectionprojectnameList");
            localStorage.removeItem("sectionapplicationMasterOpts");
            localStorage.removeItem("sectionprojectModuleOpts");
            localStorage.removeItem("sectionapplicationModuleOpts");
            localStorage.removeItem("sectionMasterOpts");
            let rowdata ={}
            this.getsectionlist(rowdata);
        },
        projectChange: function (node, instanceId){
            if(node && node.length>3){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                 axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                    localStorage.setItem('sectionprojectnameList', JSON.stringify(result.data.data))
                    } else {
                        this.projectnameList = [];
                        localStorage.removeItem('sectionprojectnameList')
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        projectChangebyid: function (projectidarr){
            if(projectidarr && projectidarr.length>0){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectidarr: projectidarr,
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                 axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.projectnameList = result.data.data;
                    } else {
                        this.projectnameList = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
      
        },
        reset: function () {
            this.sectionmodule.sectionname = null
            this.sectionmodule.sectiondescription = null
        },
        selprojectid(state, value) {
            this.search.projectMasterid = state.id;
            this.search.searchProjectname = state.id;
            if (!state && this.isAddState == false) {
                this.search.projectModuleOpts = null;
                this.search.applicationMasterid = null
                this.search.projectModuleid = null
                this.search.applicationModuleid = null;
            } else {
                this.search.applicationMasterid = null;
                this.search.projectModuleid = null;
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
            }
        },
        selprojectModuleid(state, value) {
            this.search.projectModuleid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
                this.search.applicationModuleid = null;
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selprojectModuleid2(state, value) {
            this.search.projectModuleid = state.id;
            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
                this.search.applicationModuleid = null;
            } else {
                this.search.applicationModuleid = null;
                this.getDropdownApplicationdata("t34");
            }
        },
        selApplicationid(state, value) {
            this.search.applicationMasterid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationModuleOpts = null;
                this.search.applicationModuleid = null;
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },
        selApplicationModuleid(state,value) {
            this.search.applicationModuleid = state.id;
            if (!state && this.isAddState == false) {
                this.search.sectionMasterOpts = null;
                this.search.sectionMasterid = null;
            } else {
                this.search.sectionMasterid = null;
                this.getDropdownApplicationdata("t35");
            }
        },
        getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            if(mdl === "t36") {
                
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t12") {
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t34") {
                this.input.projectModuleid = this.search.projectModuleid
            } else if(mdl === "t35") {
                this.input.applicationModuleid = this.search.applicationModuleid
            }
            this.input.useremail= this.undt.username
            this.input.empcode= this.undt.userid
            
            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                            localStorage.setItem('sectionprojectModuleOpts', JSON.stringify(result.data.data))
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                            localStorage.setItem('sectionapplicationMasterOpts', JSON.stringify(result.data.data))
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                            localStorage.setItem('sectionapplicationModuleOpts', JSON.stringify(result.data.data))
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = result.data.data;
                            localStorage.setItem('sectionMasterOpts', JSON.stringify(result.data.data))
                        }
                    } else {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = []
                            localStorage.removeItem('sectionprojectModuleOpts')
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = []
                            localStorage.removeItem('sectionapplicationMasterOpts')
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = [];
                            localStorage.removeItem('sectionapplicationModuleOpts')
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = [];
                            localStorage.removeItem('sectionMasterOpts')
                        }
                    }
                }).catch(e => {
                this.displayError(e)
            })

        },
        getmodulelist:function(){
            this.isLoading=true
            let url = 'api/projectapplication/getAplicationModulelist'
            this.input = {
                "applicationMasterid":0,
                "useremail": this.undt.username,
                empcode: this.undt.userid,
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){
                    this.modulelist=result.data.data;
                    localStorage.setItem('modulelist', JSON.stringify(result.data.data))
                }else{
                    this.modulelist=[]
                    localStorage.removeItem('modulelist')
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getSearchItems(search) {
            this.isSearchRequestActive = true
            this.projectnameList = JSON.parse(localStorage.getItem('sectionprojectnameList'))
            this.search.applicationMasterOpts = JSON.parse(localStorage.getItem('sectionapplicationMasterOpts'))
            this.search.projectModuleOpts = JSON.parse(localStorage.getItem('sectionprojectModuleOpts'))
            this.search.applicationModuleOpts = JSON.parse(localStorage.getItem('sectionapplicationModuleOpts'))
            this.search.sectionMasterOpts = JSON.parse(localStorage.getItem('sectionMasterOpts'))
            this.search.searchProjectname  = this.search.searchProjectname ? this.search.searchProjectname : search.searchProjectname ? search.searchProjectname: search.projectid ? search.projectid : null; 
            this.search.applicationMasterid  = this.search.applicationMasterid ? this.search.applicationMasterid : search.applicationid ? search.applicationid : search.applicationMasterid ? search.applicationMasterid : null
            this.search.projectModuleid  = this.search.projectModuleid ? this.search.projectModuleid : search.projectmoduleid ? search.projectmoduleid : search.projectModuleid ? search.projectModuleid : null
            this.search.applicationModuleid  = this.search.applicationModuleid ? this.search.applicationModuleid : search.applicationmoduleid ? search.applicationmoduleid : search.applicationModuleid ? search.applicationModuleid : null
            this.search.sectionMasterid  = this.search.sectionMasterid ? this.search.sectionMasterid : search.sectionmasterid ? search.sectionmasterid : search.sectionMasterid ? search.sectionMasterid : null;
            if(this.search.searchProjectname && this.search.searchProjectname !=null){
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
            }
            if(this.search.applicationModuleid){
                this.getDropdownApplicationdata("t34");
            }
            if(this.search.sectionMasterid){
                this.getDropdownApplicationdata("t35");
            }
            if(this.search.searchProjectname && this.search.searchProjectname !=null){
                this.projectChangebyid([this.search.searchProjectname]);
            }
            this.getsectionlist(this.search);
        },
        getsectionlist(rowdata){
            this.islistLoading=true
            if(rowdata) {
                localStorage.setItem('sectionsearchItems', JSON.stringify(rowdata))
            }
            this.clickedprojdata=rowdata
            this.input = {
               offset: this.pageoffset,
               limit: apiUrl.LIMIT,
               projectid:rowdata.projectMasterid?rowdata.projectMasterid:rowdata.projectid?rowdata.projectMasterid:rowdata.searchProjectname,
               projectmoduleid:rowdata.projectModuleid?rowdata.projectModuleid:rowdata.projectmoduleid,
               applicationid:rowdata.applicationMasterid?rowdata.applicationMasterid:rowdata.applicationid,
               applicationmoduleid:rowdata.applicationModuleid?rowdata.applicationModuleid:rowdata.applicationmoduleid,
               sectionMasterid: rowdata.sectionMasterid,
               uniqueid: this.undt.userid,
               useremail: this.undt.username,
               empcode: this.undt.userid,
            }
            let url = 'api/section/list'
            localStorage.setItem('sectionmodule',window.btoa(JSON.stringify(this.input)));
            this.sectionmodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.sectionmodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.sectionmodule.companyid=this.clickedprojdata.companyid
            this.sectionmodule.project=this.clickedprojdata.projectname
            this.sectionmodule.projectmodule=this.clickedprojdata.projectmodulename
            this.sectionmodule.appprojectdd = this.clickedprojdata.projectid
            this.sectionmodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.sectionmodule.applicationmoduleid = this.clickedprojdata.applicationmoduleid
            this.sectionmodule.applicationid = this.clickedprojdata.applicationid
            this.sectionmodule.applicationname = this.clickedprojdata.applicationname
            this.sectionmodule.label = this.clickedprojdata.label
            this.sectionmodule.moduleid = this.clickedprojdata.moduleid
            this.sectionmodule.applicationmodulename = this.clickedprojdata.applicationmodulename
            this.getApplicationdata('t3','sectionmodule')
            this.getApplicationdata('t2','sectionmodule')
            this.getApplicationdata('t1','sectionmodule')
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.islistLoading=false;
                
                if(result.data.errorCode == 0){
                    this.isresponce=true
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }  else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else{
                    this.companymasterlist=[]
                    this.showproject=true;
                    this.totalcount = 0
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);
            }).catch(e => {
                this.displayError(e)
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getsectionlist(this.clickedprojdata)
            }
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#sectionModuleList").offset().top -70
                });
        }, 500);
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            if(tblNm=="t35" && (this.validText(this.sectionmodule.sectionname))  && (this.validText(this.sectionmodule.sectiondescription))){
                this.valid = true;
            }
        },
        setSelected(value)
        {
            this.selected=value
        },
        clearerrorForm(){
            this.errors= {
                proejctmodule:{
                    name:null,
                    project:null,
                    desc:null,
                    attachment:null,
                    attachmentview:null,
                    disblesavebtn:false
                },
            };
        },
        openEditModule(currRowData){
            this.editRowData = currRowData
            this.sectionmodule.sectiondescription =null
            this.sectionmodule.sectionname = currRowData.sectionname
            this.sectionmodule.project = currRowData.projectname
            this.sectionmodule.projectmodule = currRowData.projectmodulename
            this.sectionmodule.projectmoduleid = currRowData.projectmoduleid
            
            this.getprojectmoduleapplications(currRowData)
            this.sectionmodule.applicationmodulename= currRowData.applicationmodulename
            this.sectionmodule.projecttypeid= currRowData.projecttypeid
            this.sectionmodule.businessverticleid= currRowData.businessverticleid
            this.sectionmodule.companyid= currRowData.companyid
            this.sectionmodule.applicationmoduleid = currRowData.applicationmoduleid
            this.sectionmodule.sectiondescription = currRowData.description

            this.sectionmodule.appprojectdd = currRowData.projectid
            this.sectionmodule.applicationid=currRowData.applicationid
            this.sectionmodule.iseditstate = true
            this.isAddState=true
        },
        getprojectmoduleapplications: function(rowdata){
            this.inputprojappl = {
                id:rowdata.projectmoduleid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let apiURL='api/projectmodule/getapplications'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.sectionmodule.applicationname=[]
                    
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                            this.sectionmodule.applicationname.push(element.applicationname)
                        }
                    });

                }else{
                    this.applicationmodule.applicationid=null
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
        onSelect(){
            this.file = this.$refs.file.files[0];
        },
        saveData(tblNm,objThis){
            if( tblNm=="t35"){
                this.validate(tblNm)
            }else{
                this.valid=true;
            }
            if (this.valid) {
                let nameobj=document.getElementById('modulename'+tblNm)
                this.input = {
                    tbl: tblNm,
                    section: 'assignment',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                let apiURL='api/section/create'
                if(this.sectionmodule.iseditstate == true){
                    apiURL='api/section/update'
                    this.input.lastmodifiedby=this.undt.username
                    this.input.id=this.editRowData.moduleid
                    delete this.input.createdby;
                }
                 if(tblNm == 't35'){
                    if(this.sectionmodule.sectionname){
                        this.input.sectionname=this.sectionmodule.sectionname
                    }
                    if(this.sectionmodule.project){
                        this.input.projectid=this.sectionmodule.appprojectdd
                    }
                    if(this.sectionmodule.projectmoduleid){
                        this.input.projectmoduleid=this.sectionmodule.projectmoduleid
                    }
                    if(this.sectionmodule.applicationmoduleid){
                        this.input.applicationmoduleid=this.sectionmodule.applicationmoduleid
                    }
                    if(this.sectionmodule.applicationid){
                        this.input.applicationid=this.sectionmodule.applicationid
                    }else{
                        this.input.applicationid=null
                    }
                    if(this.sectionmodule.sectiondescription){
                        this.input.sectiondescription=this.sectionmodule.sectiondescription
                    }
                }
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid,
                this.isAddState=false
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        $('.close').click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.rowdata={
                            label : this.sectionmodule.label,
                            applicationid : this.sectionmodule.applicationid,
                            projecttypeid :this.sectionmodule.projecttypeid,
                            businessverticleid:this.sectionmodule.businessverticleid,
                            companyid:this.sectionmodule.companyid,
                            projectname:this.sectionmodule.project,
                            projectmodulename:this.sectionmodule.projectmodule,
                            projectmoduleid:this.sectionmodule.projectmoduleid,
                            projectid:this.sectionmodule.appprojectdd,
                            applicationmoduleid:this.sectionmodule.applicationmoduleid,
                            applicationname:this.sectionmodule.applicationname,
                            moduleid:this.sectionmodule.moduleid,
                            applicationmodulename:this.sectionmodule.applicationmodulename
                        }
                        this.getsectionlist(this.search);
                        this.isLoading = false
                    }  else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
                this.displayError(e)
            })
            }
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'sectionmodule'){
                this.inputappl = {
                    tbl:mdl,
                    useremail: this.undt.username,empcode: this.undt.userid,
                }
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {        
                this.isLoading=false;     
                if(result.data.errorCode == 0){
                    if(mdl == 't2'){
                        this.sectionmodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'sectionmodule'){
                            this.sectionmodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.sectionmodule.companylist=result.data.data.rows
                        return true;
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
            
        },
        addmodule(){         
            this.sectionmodule.sectionname = null
            this.sectionmodule.sectiondescription = null
            this.sectionmodule.iseditstate = false
            this.isSearchRequestActive = true
        },
        showapplname(applname){
            if(!applname) return ''
            let responsestr='';
            let appllist=applname.split(',')
            if(appllist.length > 1){
                for(var i=0;i<appllist.length;i++){
                    responsestr +=(i+1)+'. '+appllist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<appllist.length;i++){
                    responsestr +=appllist[i]
                }
            }
            
            return responsestr
        },
        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit:null,
                offset:null,
                projectid:this.search.searchProjectname,
                applicationid:this.search.applicationMasterid,
                projectmoduleid:this.search.projectModuleid,
                applicationmoduleid:this.search.applicationModuleid,
                sectionMasterid:this.search.sectionMasterid,
                sectionname:this.search.sectionname,
                useremail: this.undt.username,
                empcode: this.undt.userid,  
            };    
            axios({
                method: "POST",
                url: `api/section/list`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.islistLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                }  else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'sectionmoduleReport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Section Name,Project,Project Module,Application module,Total Functionality,Created Date' + '\r\n'
          }
        var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
           let sectionname = index.hasOwnProperty('sectionname') ? index.sectionname == null||index.sectionname.toLowerCase() == 'na'?'':index.sectionname : '';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename : '';
          let applicationmodulename = index.hasOwnProperty('applicationmodulename') ? index.applicationmodulename : '';
          let totalfunctionality = index.hasOwnProperty('totalfunctionality') ? index.totalfunctionality == null||index.totalfunctionality.toLowerCase() == 'na'?'':index.totalfunctionality : '';
           let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          createddate = this.dateMONFormat(createddate);
            row += '"' + srno + '",',
            row += '"' + sectionname + '",',
            row += '"' + projectname + '",',
            row += '"' + projectmodulename + '",',
            row += '"' + applicationmodulename + '",',
            row += '"' + totalfunctionality + '",',
            row += '"' + createddate + '",'
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
           Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
          return;
        }
        var fileName = 'sectionmoduleReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
        Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
        });
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    }
}
</script>
