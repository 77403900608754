<template>
  <div class="app-content content ">
   <loading
     :active.sync="isLoading"
     :can-cancel="false"
     :is-full-page="true"
     color="orange"
     loader="dots"
     :width="100"
     :height="100"
   ></loading>
   <div class="content-overlay"></div>
   <div class="header-navbar-shadow"></div>
   <div class="content-wrapper pb-1">
     <section id="card-navigation modern-horizontal-wizard">
       <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
         <div class="container-fluid">
           <div class="row breadcrumbs-top">
             <div class="col-md-9 content-header-left pl-0 ">
               <h3 class="content-header-title float-left mb-0">Forms Configuration</h3>
               <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/formsconfig/list'>Forms Configuration</router-link></li>
                 </ol>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card border-warning filter_card_searchbgst" >
         <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
           <div class="container-fluid" >
             <div class="row" >
               <div class="w-auto px-1 mobile-padding-0-2">
                 <div class="avatar bg-light-secondary rounded d-inline-flex">
                   <div class="avatar-content">
                     <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                   </div>
                 </div>
               <h4 class="card-title d-inline-flex ml-1">Search Forms Configuration Details</h4>
               </div>
               <div class="ml-auto d-flex">
               <div class="px-1 text-right mobile-padding-0-2" >
                 <div class="input-group-append float-right">
                   <router-link to="/formsconfig/create"><span class="btn btn-sm btn-relief-success" > <span class="mobile_hide_bt">Create Forms Configuration </span><plus-circle-icon size="1.5x"></plus-circle-icon> </span></router-link>
                 </div>
               </div>
               <span class="rotetions_view_arrow ml-auto mx-1"> 
               <em class="las la-angle-down"></em>
             </span>
             </div>
             </div>
           </div>
         </div>
         <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
           <div class="card-body pt-1 px-sm-mobile-10">
             <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-3">
                <label for="ownername">Department</label>
               <treeselect  @input="selDepartment" placeholder="Select Department" class="projectlistdd capitalisetext" :options = "deparmentlist" v-model="search.departmentid" v-if="deparmentlist.length > 1"/>
                <treeselect  @input="selDepartment" placeholder="Select Department" class="projectlistdd capitalisetext" :options = "deparmentlist" v-model="search.departmentid" disabled v-else/>
                 </div>
              <div class="col-sm-6 col-md-6 col-lg-3">
                 <label class="form-label lable-left">Tracker</label>
                 <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Tracker" v-model="search.tracker" :options="trackerlist" :clear-on-select="true" :multiple="true" @input="selTracker"/>
               </div>
              <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Title</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Title" v-model="search.title" :options="fieldnamelist" :clear-on-select="true" :multiple="true"/>
                </div>
               <div class="col-sm-6 col-md-6 col-lg-3">
                 <label class="form-label lable-left">Publish</label>
                 <treeselect class="projectmdllistdd capitalisetext" placeholder="Select publish type" v-model="search.ispublish" :options="publishlist" :clear-on-select="true" :multiple="true"/>
               </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                  <label for="ownername">Project Name</label>
                  <treeselect   placeholder="Enter Min 4Chars To Search Project" class="projectlistdd" v-model="search.project" :multiple="true" :clear-on-select="true" v-on:search-change="getallprojectlist" :options="projectlist"/>
                </div>
               <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                 <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getAllFormConfigData(search, 1)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                 <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                 <button type="move" class="btn btn-relief-primary mt-2 ml-1" @click="ExportXL(search, true)"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card border-warning" id="domain-list">
         <div class="card-body p-0 ps ps--active-x">
           <div class="table-responsive" >
             <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
               <thead>
                 <tr>
                   <th scope="col" class="text-center first-col-sticky align-middle" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>

                   <th scope="col" class="text-center align-middle second-col-sticky">Department</th>
                   <th scope="col" class="text-center align-middle" style="min-width:200px;">Tracker</th>
                   <th scope="col" class="text-center align-middle">Project Name</th>
                   <th scope="col" class="text-center align-middle">Header</th>
                   <th scope="col" class="text-center align-middle">Title</th>
                   <th scope="col" class="text-center align-middle">Input Type</th>
                   <th scope="col" class="text-center align-middle">Validation Type</th>
                   <th scope="col" class="text-center align-middle">Option Value</th>
                   <th scope="col" class="text-center align-middle">Placeholder</th>
                   <th scope="col" class="text-center align-middle">FieldIndex</th>
                   <th scope="col" class="text-center align-middle">Error Message</th>
                   <th scope="col" class="text-left align-middle" style="min-width:170px;">Flag Details</th>
                   <th scope="col" class="text-center align-middle">Action</th>
                 </tr>
               </thead>
               <tbody>
                   <template v-for="(data,index) in formconfig.formconfiglist"  >
                    <tr v-if="!data.edit" v-bind:key="index" >
                     <td class="text-center first-col-sticky m-0">{{ index+1+currPageNum*pagelimit }}</td>
                     <td class="text-center text-uppercase second-col-sticky m-0">{{data.department}}</td>
                      <td class="text-left text-capitalize">                    
                     <span v-if="data.trackerdetails.length>1">
                        <span v-for="(prv,idx) in data.trackerdetails" v-bind:key="idx">
                          <span class="sitebluecolor" data-toggle="modal" data-target="#previewform_source" style="cursor:pointer" @click="openpreviewform(prv.trackername,prv.trackerid)">{{idx+1+'.'+ prv.trackername}}</span><br/>
                        </span>
                      </span> 
                      <span class="sitebluecolor" data-toggle="modal" data-target="#previewform_source" style="cursor:pointer" @click="openpreviewform(data.trackerdetails[0].trackername,data.trackerdetails[0].trackerid)" v-else>{{data.trackerdetails[0].trackername}}</span>
                    </td>
                    <td class="text-center">{{data.projectname}}</td>
                    <td class="text-center">{{data.fieldheader ? data.fieldheader : 'NA' }}</td>
                     <td class="text-center ">{{data.fieldname}}</td>
                     <td class="text-center text-capitalize">{{data.fieldtype}}</td>
                     <td class="text-center">{{data.validationtype}}</td>
                     <td class="text-center text-capitalize">
                      <ul style="list-style: none;padding: 0px;">
                      <tr v-if="data.field_dbvalue==0 && (data.fieldtype=='radio' || data.fieldtype=='checkbox'  || data.fieldtype == 'drop-down' || data.fieldtype == 'multiselect-dropdown' || data.fieldtype == 'toggle' )" v-for="(zdata,inex) in data.fieldvalues" >
                      
                        <li v-if="zdata.optionvalue"> {{ zdata.optionvalue }}</li>
                      </tr>
                    </ul>
                       </td>
                   <td class="text-center">{{data.fieldplaceholder ? data.fieldplaceholder : 'NA' }}</td>
                   <td class="text-center">{{data.fieldindex ? data.fieldindex : 'NA' }}</td>
                    <td class="text-center">{{data.fielderrormsg ? data.fielderrormsg : 'NA' }}</td>


                     <td class="text-left text-capitalize">
                      <em
                      :class="{'las la-check-circle custom-class font-weight-normal': data.isactive === 1, 'las la-times-circle custom-class': data.isactive !== 1}"
                      :style="data.isactive === 1 ? 'color: #28c76f; font-size: 15px;' : 'color: #b82323; font-size: 15px;'">
                      IsActive
                    </em>

                     <em
                      :class="{'las la-check-circle custom-class font-weight-normal': data.ispublish === 1, 'las la-times-circle custom-class': data.ispublish !== 1}"
                      :style="data.ispublish === 1 ? 'color: #28c76f; font-size: 15px;' : 'color: #b82323; font-size: 15px;'">
                      IsPublish
                    </em>

                    <em
                      :class="{'las la-check-circle custom-class font-weight-normal': data.israting === 1, 'las la-times-circle custom-class': data.israting !== 1}"
                      :style="data.israting === 1 ? 'color: #28c76f; font-size: 15px;' : 'color: #b82323; font-size: 15px;'">
                      IsRating
                    </em>
                    <em
                      :class="{'las la-check-circle custom-class font-weight-normal': data.isstandard === 1, 'las la-times-circle custom-class': data.isstandard !== 1}"
                      :style="data.isstandard === 1 ? 'color: #28c76f; font-size: 15px;' : 'color: #b82323; font-size: 15px;'">
                      IsStandard
                    </em>
                    <em
                      :class="{'las la-check-circle custom-class font-weight-normal': data.isexport === 1, 'las la-times-circle custom-class': data.isexport !== 1}"
                      :style="data.isexport === 1 ? 'color: #28c76f; font-size: 15px;' : 'color: #b82323; font-size: 15px;'">
                      IsExport
                    </em>
                    <em
                      :class="{'las la-check-circle custom-class font-weight-normal': data.islisting === 1, 'las la-times-circle custom-class': data.islisting !== 1}"
                      :style="data.islisting === 1 ? 'color: #28c76f; font-size: 15px;' : 'color: #b82323; font-size: 15px;'">
                      Is Visiable for the Listing
                    </em>
                    <em
                      :class="{'las la-check-circle custom-class font-weight-normal': data.iseditlisting === 1, 'las la-times-circle custom-class': data.iseditlisting !== 1}"
                      :style="data.iseditlisting === 1 ? 'color: #28c76f; font-size: 15px;' : 'color: #b82323; font-size: 15px;'">
                      Is Editable from the Listing
                    </em>
                    </td>                    
                     <td class="centeralign">
                       <span class=" devEditEle edit_open_box_exam"  @click="activateEditRow(data, index)" style="cursor:pointer">
                         <em class="las la-pen" style="font-size:22px;"></em>
                       </span>
                     </td>
                   </tr>
                  <tr v-else v-bind:key="index">
                     <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                     <td class="box-inc second-col-sticky"  >
                      
                    <treeselect placeholder="Select Department" class="projectlistdd" v-model="data.departmentid" :options="deparmentlist" @input="oselDepartment($event,index)"   /> 
                   <div class="errorinputmsg" v-if="!data.departmentid">Department is required</div> 
                 </td>
                 <td class="box-inc"  >
                         <treeselect placeholder="Select Tracker" class="projectlistdd" v-model="data.trackeroption" :options="trackerlist" :multiple="true" :clear-on-select="true"  @input="getKRAHeaderList($event,index,data.fieldheaderid)" /> 
                      <div class="errorinputmsg" v-if="!data.trackeroption.length > 0">Tracker is required</div>
                    </td>
                    <td class="box-inc">
                       <treeselect  placeholder="Select KRA Header" class="projectlistdd" v-model="data.fieldheader" :options="data.fieldheaderlist" @input="selectHeader($event, index)" v-if="data.fieldheaderid || (data.fieldheaderlist && data.fieldheaderlist.length > 0 )" />
                                <input type="text" class="form-control" placeholder="Header" v-model="data.fieldheader" v-else/>  
                               </td>
                     <td class="box-inc">
                       <input type="text" class="form-control" placeholder="Title" v-model="data.fieldname"/>
                       <div class="errorinputmsg" v-if="!data.fieldname">Title is required</div>
                      </td>
                     <td class="box-inc">
                           <treeselect placeholder="Select Input Type" class="projectlistdd" v-model="data.fieldtype" :options="titletypelist"/>
                       <div class="errorinputmsg" v-if="!data.fieldtype">Input type is required</div>
                     </td>
                      <td class="box-inc">
                        <treeselect placeholder="Select Validation Type" class="projectlistdd" v-model="data.validationtype" :options="titlevalidationlist"/>
                       <div class="errorinputmsg" v-if="!data.validationtype">Validation type is required</div>
                     </td>
                     
                     <td class="box-inc">
                      <div style="width: 300px;">
                        <div class="row" style="align-items: center;margin: 8px 0px;">
                      <div class="form-group col-md-8 mb-0 px-0" v-if ="(data.fieldtype=='radio' || data.fieldtype=='checkbox'  || data.fieldtype == 'drop-down' || data.fieldtype == 'multiselect-dropdown' || data.fieldtype == 'toggle' )" >   
                            <div class="form-check">
                            <input class="form-check-input"  v-model="data.field_dbvalue" type="checkbox"   id="myCheckbox">
                            <label class="form-check-label  " for="myCheckbox">
                            Get Value From API
                            </label>
                            </div> 
                            </div> 

                      <div class="form-group col-md-4 mb-0 px-0 text-right"  v-if="data.field_dbvalue==0 && (data.fieldtype=='radio' || data.fieldtype=='checkbox'  || data.fieldtype == 'drop-down' || data.fieldtype == 'multiselect-dropdown' || data.fieldtype == 'toggle' )">  
                      <div class="btn btn-relief-primary ml-4 "  @click="addDocumentFields(index)"><span><plus-square-icon size="1.5x"></plus-square-icon>       
                      </span>
                      </div>
                      </div>      
                    </div>
                      <div  v-if="data.field_dbvalue==0 && (data.fieldtype=='radio' || data.fieldtype=='checkbox'  || data.fieldtype == 'drop-down' || data.fieldtype == 'multiselect-dropdown' || data.fieldtype == 'toggle')" class="row" v-for="(singleDocField, idx) in data.fieldvalues" :key="`phoneInput-${idx}`" style="border-bottom: 0.5px solid rgba(221, 215, 215, 0.51);  "  >


                      <div class="form-group d-flex" style="align-items: center;margin: 8px 0px;">
                     <label class="form-label col-sm-4">Option Values </label>
                     <div class="col-sm-6">
                     <input class="form-control" v-model="singleDocField.optionvalue" type="text" placeholder="Enter Option values"  />
                     <div class="errorinputmsg" v-if="!singleDocField.optionvalue">Option value is required</div>
                      </div>
                     <div class="form-group col-md-2 mb-0" style="padding:0px 6px">
                        <div class="avatar btn-relief-danger rounded ">
                          <div class="avatar-content" @click="removeDocumentField(index,idx)">
                             <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                          </div>
                        </div>
                     </div>
                     </div>
                 
                     

                     </div>
                    </div>
                     </td>
                     
                     <td class="box-inc">
                      <input type="text" class="form-control" placeholder="Placeholder" v-model="data.fieldplaceholder"/>
                      </td>
                  
                     <td class="box-inc">
                       <input type="number"  @keypress="validNumber"  class="form-control" placeholder="FieldIndex" v-model="data.fieldindex"/>
                      </td>       
                  
                     <td :class="{'fieldcomment': data.fieldcomment}" class="toggle__button2">
                         <label :for="'fieldcomment-'+index+'-'+data.id" :class="{'active': data.fieldcomment}" class="toggle__button2">
                           <input type="checkbox" :id="'fieldcomment-'+index+'-'+data.id" v-model="data.fieldcomment">
                           <span class="toggle__switch"></span>
                           <span :class="{'off-lable-color': !data.fieldcomment, 'on-label-color': data.fieldcomment }"></span>
                         </label>
                     </td> 
                     <td class="box-inc">
                       <input type="text" class="form-control" placeholder="Enter Error Message" v-model="data.fielderrormsg"/>
                      </td> 
                     <td :class="{'active': data.isactive}" class="toggle__button2">
                         <label :for="'isactive-'+index+'-'+data.id" :class="{'active': data.isactive}" class="toggle__button2">
                           <input type="checkbox" :id="'isactive-'+index+'-'+data.id" v-model="data.isactive">
                           <span class="toggle__switch"></span>
                           <span :class="{'off-lable-color': !data.isactive, 'on-label-color': data.isactive }"></span>
                         </label>
                     </td>
                     <td :class="{'ispublish': data.ispublish}" class="toggle__button2">
                         <label :for="'ispublish-'+index+'-'+data.id" :class="{'active': data.ispublish}" class="toggle__button2">
                           <input type="checkbox" :id="'ispublish-'+index+'-'+data.id" v-model="data.ispublish">
                           <span class="toggle__switch"></span>
                           <span :class="{'off-lable-color': !data.ispublish, 'on-label-color': data.ispublish }"></span>
                         </label>
                     </td>
                     <td :class="{'israting': data.israting}" class="toggle__button2">
                         <label :for="'israting-'+index+'-'+data.id" :class="{'active': data.israting}" class="toggle__button2">
                           <input type="checkbox" :id="'israting-'+index+'-'+data.id" v-model="data.israting">
                           <span class="toggle__switch"></span>
                           <span :class="{'off-lable-color': !data.israting, 'on-label-color': data.israting }"></span>
                         </label>
                     </td>
                     <td class="centeralign flex--items">
                        <div class=" flex--items">
                       <button type="submit" class="btn btn-relief-primary mr-1" style="padding:6px;" v-on:click.stop.prevent="saveData(data)"><em class="las la-check-circle custom-class" style="font-size:22px;"></em></button>
                       <button type="submit" class="btn btn-relief-info" style="padding:6px;" v-on:click.stop.prevent="cancelUpdate(index)"><em class="las la-times-circle custom-class" style="font-size:22px;"></em></button>
                       </div>
                     </td>
                   </tr>  
                 </template>
                 <template v-if="formconfig.formconfiglist.length==0">
                   <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                 </template>
               </tbody>
             </table>
           </div>
           <div class="pageCenter text-center mt-2">
             <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
           </div>
         </div>
       </div>

       <!-- Preview form start -->
       <div class="scrolling-inside-modal">
          <div class="modal fade" id="previewform_source" tabindex="-1" role="dialog" aria-labelledby="previewform_source_scroll" aria-hidden="true">
              <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title" id="previewform_source_scroll"> <div class="avatar bg-light-secondary rounded d-inline-flex mr-1"> <div class="avatar-content"><eye-icon size="2.5x" class="custom-class avatar-icon font-medium-3 feather feather-search"></eye-icon></div></div> {{ preview.legendname }} </h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearpreviewdata()">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div class="modal_header_overflow_page">
                      <div class="text-left ml-1 mt-1" v-if="preview.fieldnamelist.length>0">
                          <span class="required-field"> All fields marked with asterisk are required.</span>
                      </div>
                      <div class="col-md-12 mb-2 mt-0">
                          <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi  business_fieldset_bg" v-if="preview.tempcheck.length>0">
                          <legend> {{ preview.legendname }} </legend>
                          <div class="row">
                              <div class="col-md-4" v-for="(fieldname,i) in this.preview.fieldnamelist" v-bind:key="i" v-if="fieldname.fieldtype !='hidden'">
                                  <div class="form-group mb-1" v-if="fieldname.fieldheader==null">
                                      <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required' && fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                      <label for="projectlistdd" v-else-if="fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                      <span v-if="fieldname.fieldtype=='text' && fieldname.fieldtype !='hidden'">
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          v-bind:class="{ 
                                          'form-control': true,
                                          '': !validText(fieldsnamevalues[fieldname.id])}"
                                          v-if="(fieldname.validationtype=='readonly')" disabled/>

                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          v-bind:class="{ 
                                          'form-control': true,
                                          '': !validText(fieldsnamevalues[fieldname.id])}"
                                          v-else/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                          </p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='number' && fieldname.fieldtype !='hidden'">
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                          pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                          :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          v-bind:class="{
                                          'form-control': true,
                                          '': !validText2(fieldsnamevalues[fieldname.id])}"
                                          v-if="(fieldname.validationtype=='readonly')" disabled/>
                                          
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                          pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                          :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          v-bind:class="{
                                          'form-control': true,
                                          '': !validText2(fieldsnamevalues[fieldname.id])}"
                                          v-else/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='drop-down' && fieldname.fieldtype !='hidden'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1"/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='multiselect-dropdown' && fieldname.fieldtype !='hidden'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='checkbox' && fieldname.fieldtype !='hidden'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='radio' && fieldname.fieldtype !='hidden'">
                                          <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                              <div class=" form-check form-check-inline mt-0 mb-0">
                                                  <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="Object.values(radiofieldvalues)[0]" @change="changevalue(fieldname.id,Object.values(radiofieldvalues)[0])"> <span class="" style="margin-left:5px; font-size: 14px;"> {{Object.values(radiofieldvalues)[0]}} </span>
                                              </div>
                                          </span>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='date' && fieldname.fieldtype !='hidden'">
                                          <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                          class="startdate capitalisetext" id="EndDate" :minute-step="5" v-if="(fieldname.validationtype=='readonly')" disabled></date-picker>

                                          <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                          class="startdate capitalisetext" :id="'EndDate'+i" :minute-step="5" @change="changevalue(fieldname.id,fieldsnamevalues[fieldname.id])" v-else></date-picker>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='datetime-local' && fieldname.fieldtype !='hidden'">
                                          <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly')" disabled></vue-timepicker>
                                          <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='toggle' && fieldname.fieldtype !='hidden'">
                                          <div class="custom-control custom-switch custom-switch-secondary">
                                              <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]"/>
                                              <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                  <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                  <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                              </label>
                                          </div>
                                      </span>
                                      <span v-if="fieldname.fieldtype && fieldname.fieldtype =='hidden'" style="display:none;">
                                        <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" v-model="fieldsnamevalues[fieldname.id]"/>
                                      </span>
                                  </div>
                              </div>
                          </div>
                          </fieldset>
                          <!-- Header format code start -->
                          <span v-if="Object.keys(preview.tempheader).length>0" class=" px-1">
                              <div class="mt-0" v-for="(headers,ind) in preview.tempheader" v-bind:key="ind">
                                  <div class="tab-content mb-1 d-inline-block w-100" >
                                      <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                                          <div class="collapse-margin collapse-icon mt-0" :id="'faq-payment-qna'+headers[0].id">
                                              <div class="card1  resolved rounded" style="border:1px solid #eee;">
                                                  <div class="card-header p-1 rounded-0"  :id="'paymentOne'+headers[0].id"  role="button" :data-target="'#faq-payment-one'+headers[0].id" aria-expanded="false" aria-controls="faq-payment-one">
                                                      <span class="lead collapse-title">{{ ind }}</span>
                                                  </div>
                                                  <div :id="'faq-payment-one'+headers[0].id"  :aria-labelledby="'#paymentOne'+headers[0].id" :data-parent="'#faq-payment-qna'+headers[0].id">
                                                      <div class="card-body">
                                                          <div class="mb-1 px-0" >
                                                              <div class="table-responsive" style="overflow-x: inherit !important;">
                                                                  <div class="col-md-12 mt-2">
                                                                    <div class="row">
                                                                          <div class="col-md-4" v-for="(head,indx) in headers" v-bind:key="indx" v-if="head.fieldtype !='hidden'">
                                                                              <div class="form-group mb-1">
                                                                                  <label for="projectlistdd" class="required-field" v-if="head.validationtype=='required' && head.fieldtype !='hidden'">{{head.label}}:</label>
                                                                                  <label for="projectlistdd" v-else-if="head.fieldtype !='hidden'">{{ head.label }}:</label>
                                                                                  <span v-if="head.fieldtype=='text' && head.fieldtype !='hidden'">
                                                                                      <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                      v-bind:class="{
                                                                                      'form-control': true,
                                                                                      '': !validText(fieldsnamevalues[head.id])}"
                                                                                      v-if="(head.validationtype=='readonly')" disabled/>

                                                                                      <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                      v-bind:class="{
                                                                                      'form-control': true,
                                                                                      '': !validText(fieldsnamevalues[head.id])}"
                                                                                      v-else/>
                                                                                      <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && !validText(fieldsnamevalues[head.id])" style="min-width:100%;">{{head.fielderrormsg}}</span>
                                                                                      </p>
                                                                                  </span>
                                                                                  <span v-if="head.fieldtype=='number' && head.fieldtype !='hidden'">
                                                                                      <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                                                      pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                                                      :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                      v-bind:class="{
                                                                                      'form-control': true,
                                                                                      '': !validText2(fieldsnamevalues[head.id])}"
                                                                                      v-if="(head.validationtype=='readonly')" disabled/>

                                                                                      <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                                                      pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                                                      :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                      v-bind:class="{
                                                                                      'form-control': true,
                                                                                      '': !validText2(fieldsnamevalues[head.id])}"
                                                                                      v-else/>
                                                                                      <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && !validText2(fieldsnamevalues[head.id])">{{head.fielderrormsg}}</span></p>
                                                                                  </span>

                                                                                  <span v-if="head.fieldtype=='drop-down' && head.fieldtype !='hidden'">
                                                                                      <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[head.id]" :options="head.optionalvalu1"/>
                                                                                      <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                  </span>

                                                                                  <span v-if="head.fieldtype=='multiselect-dropdown' && head.fieldtype !='hidden'">
                                                                                      <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[head.id]" :options="head.optionalvalue2" @input="selectinput($event)" :multiple="true" :clear-on-select="true"/>
                                                                                      <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && (!fieldsnamevalues[head.id] || fieldsnamevalues[head.id].length==0)">{{head.fielderrormsg}}</span></p>
                                                                                  </span>

                                                                                  <span v-if="head.fieldtype=='checkbox' && head.fieldtype !='hidden'">
                                                                                      <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[head.id]" :options="head.optionalvalue" @input="selectinput($event)" :multiple="true" :clear-on-select="true"/>
                                                                                      <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && (!fieldsnamevalues[head.id] || fieldsnamevalues[head.id].length==0)">{{head.fielderrormsg}}</span></p>
                                                                                  </span>

                                                                                  <span v-if="head.fieldtype=='radio' && head.fieldtype !='hidden'">
                                                                                      <span v-for="(radiofieldvalues,k) in head.radiooptionalvalue" v-bind:key="k"><br />
                                                                                          <div class=" form-check form-check-inline mt-0 mb-0">
                                                                                              <input class="form-check-input" type="radio" v-model="fieldsnamevalues[head.id]" :value="Object.values(radiofieldvalues)[0]" @change="changevalue(head.id,Object.values(radiofieldvalues)[0])"> <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                                                          </div>
                                                                                      </span>
                                                                                      <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                  </span>

                                                                                  <p><span v-if="head.fieldtype=='date' && head.fieldtype !='hidden'" >
                                                                                      <date-picker :placeholder="head.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[head.id]" valueType="format"
                                                                                      class="startdate capitalisetext" :id="'EndDate'+indx" :minute-step="5" v-if="(head.validationtype=='readonly')" disabled></date-picker>

                                                                                      <date-picker :placeholder="head.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[head.id]" valueType="format"
                                                                                      class="startdate capitalisetext" :id="'EndDate'+indx" :minute-step="5" @change="changevalue(head.id,fieldsnamevalues[head.id])" v-else></date-picker>
                                                                                      <p><span class="errorinputmsg capitalisetext"  style="min-width:100%;" v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                  </span></p>

                                                                                  <span v-if="head.fieldtype=='datetime-local' && head.fieldtype !='hidden'">
                                                                                      <vue-timepicker class="startdate" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[head.id]" v-if="(head.validationtype=='readonly')" disabled></vue-timepicker>
                                                                                      <vue-timepicker class="startdate" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[head.id]" v-else></vue-timepicker>
                                                                                      <p><span class="errorinputmsg capitalisetext" v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                  </span>

                                                                                  <span v-if="head.fieldtype=='toggle' && head.fieldtype !='hidden'">
                                                                                      <div class="custom-control custom-switch custom-switch-secondary">
                                                                                          <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+head.id" v-model="fieldsnamevalues[head.id]"/>
                                                                                          <label class="custom-control-label" :for="'customSwitch11'+head.id">
                                                                                              <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{head.optionalvalue3[0].label}}</label></span>
                                                                                              <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{head.optionalvalue3[1].label}}</label></span>
                                                                                          </label>
                                                                                      </div>
                                                                                  </span>

                                                                                  <span v-if="head.fieldtype && head.fieldtype =='hidden'" style="display:none;">
                                                                                      <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" v-model="fieldsnamevalues[head.id]"/>
                                                                                  </span>
                                                                                  
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </span>
                          <!-- Header format code end -->
                      </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <!-- Preview form end -->

    </section>
   </div>
 </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2'
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon,MinusSquareIcon,PlusSquareIcon,EyeIcon } from "vue-feather-icons";
import "vue2-datepicker/index.css";
import VueTimepicker from 'vue2-timepicker'
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'FormConfigureList',
  mixins: [ commonMethods ],

 components: {
   VueElementLoading,
   Loading,
   Treeselect,
   DatePicker,
   PlusCircleIcon,
   EditIcon,
   CheckCircleIcon,
   XCircleIcon,
   Pagination,
   SearchIcon,
   ExternalLinkIcon,
   XIcon,
   MinusSquareIcon,
   PlusSquareIcon,EyeIcon,VueTimepicker
 },
 data() {
   return {
     formconfig: {
      formconfiglist: [],
       prevformconfiglist: [],
     },
     fieldnamelist : [],
     trackerlist:[],
     staticValue: ["Businesstask"],
     search: {
        tracker:null,
        title:null,
        isactive:null,
        ispublish:null,
        islisting:null,
        iseditlisting:null,
        department:null,
        departmentid:null,
        project: null
     },
     isactive: true,
     isSearchBoxActive: false,
     card_content:'card-content',
     collapse:'collapse',
     isLoading: false,
     undt: null,
     tokendata: null,
     totalcount: 0,
     pageCount: 0,
     page:1,
     pagelimit:apiUrl.LIMIT,
     currPageNum: 0,
     pageoffset:1,
     pagefeatures:[],
     TimeFormat: apiUrl.TimeFormat,
     purchase_date:null,
     expiry_date:null,
     statuslist: [
        { label: 'Active', id: '1' },
        { label: 'Inactive', id: '0' },
      ],
      publishlist: [
        { label: 'Yes', id: '1' },
        { label: 'No', id: '0' },
      ],
      titletypelist: [
         { label: 'Text', id: 'text'  },
         { label: 'Number', id: 'number'  },
         { label: 'Radio-button', id: 'radio'   },
         { label: 'Checkbox', id: 'checkbox'  },
         { label: 'Date', id: 'date'   },
         { label: 'Timestamp', id: 'datetime-local'  },
         { label: 'Hidden', id: 'hidden'   },
         { label: 'Drop-down', id: 'drop-down'  },
         { label: 'Multiselect-Dropdown', id: 'multiselect-dropdown'  },
         { label: 'Toggle', id: 'toggle'  },
         
     ],
     titlevalidationlist: [
         { label: 'Read-only', id: 'readonly' },
         { label: 'Hidden', id: 'hidden' },
         { label: 'optional', id: 'optional' },
         { label: 'required', id: 'required' },    
     ],
     arrayValue: [],
     deparmentlist:[],
     preview:{
      fieldnamelist: [],
      tempheader: {},
      tempcheck: [],
      legendname: null
     },
     fieldsnamevalues:{},
     projectid:[],
     projectlist:[]
   }
 },
 mounted() {
  let permission = this.$Service.verifyAccess();
  if(permission){
     let userdata = window.localStorage.getItem('userdata');
    if(userdata) {
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.tokendata = window.localStorage.getItem('token');
      this.getEmpDepartList();
      this.getFieldNameList();
      this.titletypelist.sort((a, b) => a.label.localeCompare(b.label));
      this.titlevalidationlist.sort((a, b) => a.label.localeCompare(b.label));
    }
   }
   $(".filter_rotation_onlick").click(function(){
     $(this).toggleClass("common_arrow_rotetion");
   });
 },
 methods: {
  addDocumentFields(index) {
     if(!this.formconfig.formconfiglist[index].fieldvalues){
       this.formconfig.formconfiglist[index].fieldvalues = [{
            optionvalue: '',
             }]
    }else{

      this.formconfig.formconfiglist[index].fieldvalues.push({
       optionvalue: null,
     })
    }

    },
    removeDocumentField(index, idx) {
   if(this.formconfig.formconfiglist[index].fieldvalues && this.formconfig.formconfiglist[index].fieldvalues.length>1) {
    this.formconfig.formconfiglist[index].fieldvalues.splice(idx,1);
      }
    },
    getallprojectlist(node) {
      if (node && node.length>3) {
        this.isLoading = true;
        this.inputfield= {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          projectname: node.toLowerCase(),
          isfrompage:'listingpage'
        }
        axios({
          'method': 'POST',
          'url': 'api/project/getallprojectlist',
          'data': this.inputfield,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.status == true){
            this.projectlist = result.data.data;
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.projectlist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
  getTrackerList(department) {
      this.isLoading = true
      let url = 'api/formconfigure/geTrackerList'
      let payload = new Object({
        moduletype: null,
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        department: department
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.trackerlist = result.data.data
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.trackerlist=[]
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
   validNumber : event => {
     let keyCode = event.keyCode;
       if (keyCode < 48 || keyCode > 57) {
         event.preventDefault();
       }
    }, 
    updateArray() {
      this.arrayValue = this.inputValue.split(',');
    },
   notBeforeToday: function (date) {
     this.expiry_date = "";
     return date > new Date(new Date().setHours(0, 0, 0, 0));     
   },
   disabledBefore(date) {
     let dayBefore = moment(this.purchase_date).format(this.TimeFormat);
     const beforeToday = new Date(dayBefore);
           beforeToday.setHours(0, 0, 0, 0);
     return date < beforeToday; 
   },
   showCommaSepName(code){
        let responsestrs='';
        let prlists=code.split(',')
        if(prlists.length > 1){
            for(let i=0;i<prlists.length;i++){
                responsestrs +='<li>'+prlists[i]+'</li>'
            }
        }else{
            for(let i=0;i<prlists.length;i++){
                responsestrs +=prlists[i]
            }
        }
        return '<ul>'+responsestrs+'</ul>'
        }, 
   getAllFormConfigData(search, flag) {
    if(search) {
      localStorage.setItem('formsearchItems', JSON.stringify(search));
    }
    this.isLoading = true
    if(flag==1){
      this.pageoffset=1
      this.page=1
    }
    let url = 'api/formconfigure/getFormConfigure'
    let payload = new Object({
      limit: apiUrl.LIMIT,
      offset: this.pageoffset,
      useremail: this.undt.username,
      empcode: this.undt.userid,
      empid: parseInt(this.undt.userid),
      department:this.search.departmentid   
    })
    if(search) {
      payload.tracker = search.tracker ? search.tracker : null
      payload.fieldname = search.title ? search.title : null
      payload.ispublish = search.ispublish ? search.ispublish : null
      payload.isactive = search.isactive ? search.isactive : null
      payload.project = search.project ? search.project : null
    }
    axios({
      method: 'POST',
      url,
      data: payload,
      headers:{ authorization :this.tokendata }
    }).then(result => {
      this.isLoading = false
      if(result.data.errorCode == 0) {
        let d = result.data.data.rows

        this.formconfig.formconfiglist = d.map(i => {
        let trackerArr = [];
          if(i.trackerid.includes(',')){
          trackerArr = i.trackerid.split(',');
        }else{
          trackerArr.push(i.trackerid);
        }
        let numberArray = trackerArr.map(str => parseInt(str.trim()));

          return {...i, edit: false,trackeroption:numberArray}}
        )

        for (let x = 0; x < this.formconfig.formconfiglist.length; x++) {
        this.formconfig.formconfiglist[x]['trackerdetails'] =[];
        let tempsplit ='';
        let tempsplit2 ='';
        tempsplit = this.formconfig.formconfiglist[x].tracker.split(',');
        tempsplit2 = this.formconfig.formconfiglist[x].trackerid.split(',');
        if(tempsplit.length>1){
          for (let y = 0; y < tempsplit.length; y++) {
            let trackerobj = {};
            trackerobj['trackername'] = tempsplit[y];
            trackerobj['trackerid'] = tempsplit2[y];
            this.formconfig.formconfiglist[x].trackerdetails.push(trackerobj);
          }
        }else{
          let trackerobj = {};
          trackerobj['trackername'] = this.formconfig.formconfiglist[x].tracker;
          trackerobj['trackerid'] = this.formconfig.formconfiglist[x].trackerid;
          this.formconfig.formconfiglist[x].trackerdetails.push(trackerobj);
        }
        this.formconfig.formconfiglist[x].trackerdetails = this.formconfig.formconfiglist[x].trackerdetails.filter(item => item.trackerid !== undefined)
        }
      this.formconfig.prevformconfiglist = d 

        this.totalcount = result.data.data.count
        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
      } else {
        this.formconfig.formconfiglist = []
        this.totalcount = 0
        this.pageCount = 0
        // if(isExportedFlag) {
        //   Swal.fire({
        //     title: "",
        //     text: "Data not found",
        //     icon: "info",
        //     customClass: { confirmButton: "btn btn-primary" },
        //     buttonsStyling: !1,
        //   });
        // }
      }
    }).catch(e => {
      this.displayError(e)
    })
   },
   getFieldNameList(department) {
      this.isLoading = true
      let url = 'api/formconfigure/geFieldNameList'
      let payload = new Object({
        fieldname:this.search.fieldname,
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        department: department
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.fieldnamelist = result.data.data
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.fieldnamelist=[]
        }
      }).catch(e => {
          this.displayError(e)
        })
    },
   ExportXL(search,isExportedFlag) {
     this.islistLoading = true;
     this.exportxlsdata = [];
     this.exporterr = "";
     this.input = {
      limit:null,
      offset:null,
      useremail: this.undt.username,
      empcode: this.undt.userid,
      empid: parseInt(this.undt.userid),
      tracker :search.tracker ? search.tracker : null,
      fieldname : search.title ? search.title : null,
      ispublish : search.ispublish ? search.ispublish : null,
      islisting : search.islisting ? search.islisting : null,
      iseditlisting : search.iseditlisting ? search.iseditlisting : null,
      isactive : search.isactive ? search.isactive : null,
      department:this.search.departmentid,
      project: this.search.project

     };
     if(isExportedFlag) {
      /// this.input.isExported = true
     }
     axios({
       method: "POST",
       url: `api/formconfigure/getFormConfigure`,
       data: this.input,
       'headers':{'authorization':this.tokendata}
     }).then((result) => {
       this.islistLoading = false;
       if (result.data.errorCode == 0) {
         this.exportxlsdata = result.data.data.rows;
         var filename = "";
         this.download(result.data.data.rows,filename)
       }else if(result.data.errorCode == 3){
           Swal.fire({
                 title: "Session Expired...!!",
                 text: result.data.msg,
                 icon: 'info',
                 customClass: {
                     confirmButton: "btn btn-primary",
                 },
                 buttonsStyling: !1,
             }).then(function() {
               window.location.href = "/#/login";
           })
         } else {
         Swal.fire({
           title: "",
           text: "Data not found",
           icon: "info",
           customClass: {
           confirmButton: "btn btn-primary",
           },
           buttonsStyling: !1,
         });
       }        
     }).catch(e => {
          this.displayError(e)
        });
   },
   download(readExportdata) {
      if(readExportdata.length > 0) {
       let JSONData = readExportdata
       let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
       let row = ''
       let CSV = 'Sr#,Department,Title,Project Name,Input_Type,Validation_Type,Tracker,Placeholder,Fieldindex,Header,Error_Message,Comment_Status,IsPublish,Is Visiable for the Listing,Is Editable from the Listing,IsRating,Created_Date,Created_By'+ '\r\n'
       for (let i=0; i<arrData.length; i++) {
         let index = arrData[i]
         let srno = i+1
        //  let department = index.hasOwnProperty('department') ? index.department   : ''
         let department = index.department ? this.decryptText(index.department) : ''

         let fieldname = index.hasOwnProperty('fieldname') ? index.fieldname   : ''
         let fieldtype = index.hasOwnProperty('fieldtype') ? index.fieldtype : ''
         let validationtype = index.hasOwnProperty('validationtype') ? index.validationtype : ''
        let tracker = index.hasOwnProperty('tracker') ? index.tracker : ''
        let projectname = index.hasOwnProperty('projectname') ? index.projectname : ''

        let fieldplaceholder = index.hasOwnProperty('fieldplaceholder') ? index.fieldplaceholder : ''

        let fieldindex = index.hasOwnProperty('fieldindex') ? index.fieldindex : ''
        let fieldheader = index.hasOwnProperty('fieldheader') ? index.fieldheader : ''
        let fielderrormsg = index.hasOwnProperty('fielderrormsg') ? index.fielderrormsg : ''
        let isactive = index.hasOwnProperty('isactive') ?  index.isactive : ''
         let ispublish = index.hasOwnProperty('ispublish') ? index.ispublish : ''
         let islisting = index.hasOwnProperty('islisting') ? index.islisting : ''
         let iseditlisting = index.hasOwnProperty('iseditlisting') ? index.iseditlisting : ''
         let israting = index.hasOwnProperty('israting') ? index.israting   : ''              
          let createddate = index.hasOwnProperty('createddate') ? this.dateMONFormat(index.createddate) : ''
           let createdby = index.hasOwnProperty('createdby') ? index.createdby : ''
         row += '"' + srno + '",'
         row += '"' + department.toUpperCase() + '",'
         row += '"' + fieldname + '",'
         row += '"' + projectname + '",'
         row += '"' + fieldtype + '",'
          row += '"' + validationtype + '",'
          row += '"' + tracker + '",'
          row += '"' + fieldplaceholder + '",'
          row += '"' + fieldindex + '",'
          row += '"' + fieldheader + '",'
          row += '"' + fielderrormsg + '",'
         row += '"' + isactive + '",'           
         row += '"' + ispublish + '",'
         row += '"' + islisting + '",'
         row += '"' + iseditlisting + '",'
         row += '"' + israting + '",'
         row += '"' + createddate + '",'
         row += '"' + createdby + '",'
         row += '\r\n'
       }
       CSV += row
       if (CSV === '') {
         Swal.fire({
           title: "",
           text: "Data not found",
           icon: "info",
           customClass: {
             confirmButton: "btn btn-primary",
           },
           buttonsStyling: !1,
         });
         return;
       }
       let fileName = 'formconfig_details_' + moment().format('DD_MM_YY_HH_mm_ss')
       let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
       let link = document.createElement('a')
       link.href = uri
       link.style = 'visibility:hidden'
       link.download = fileName + '.csv'
       document.body.appendChild(link)
       link.click()
       document.body.removeChild(link)
       Swal.fire({
         title: "Success!",
         text: "Forms Configuration Exported successfully",
         icon: "success",
         customClass: {
           confirmButton: "btn btn-primary",
         },
         buttonsStyling: !1,
       });
     } else {
       Swal.fire({
         title: "",
         text: "Data not found",
         icon: "info",
         customClass: {
           confirmButton: "btn btn-primary",
         },
         buttonsStyling: !1,
       });    
     }
   },
   dateMONFormat(value){
     if (!value) return "";
     return moment(String(value)).format('DD-MM-YYYY');
   },
   dateMonthFormat(value) {
     if (!value) return "NA";
     return moment(String(value)).format('DD MMM YYYY');
   },
   activateEditRow:function(data,idx){
    // this.projectid.push(data.projectid)
    // console.log('---=',this.projectid)
    // this.projectid = this.projectid[0].split(',').map(item => item.trim());
    // data.projectid = this.projectid
    let dataid = parseInt(data.id)
    let btnstate = 'update';
    this.$router.push({
      path: `/formsconfig/update?id=${dataid}`,
      params: {
            backbtnstate: btnstate,
            clickeddata: data,
        },
    });
   },
   searchBoxCollapse() {
     this.isSearchBoxActive = !this.isSearchBoxActive
   },
   clearSearchFields() {
    this.search.tracker = null;
    this.search.title = null;
    this.search.isactive = null;
    this.search.ispublish = null;
    this.search.project = null;
    localStorage.removeItem('formsearchItems');
    this.getEmpDepartList();
    this.getAllFormConfigData();
   },
   clickCallback: function(pageNum) {
     this.pageoffset=pageNum
     this.currPageNum = pageNum - 1
     this.getAllFormConfigData();
     setTimeout(function(){
       $('html, body').animate({
         scrollTop: $("#domain-list").offset().top -70
       });
     }, 500);
   },
   validateForm(data) {
     let valid = true
     if(!data.id || !data.fieldname) {
       valid = false
     }
     if(!data.trackeroption.length > 0) {
       valid = false
     }
     if (data.fieldtype == null || data.fieldtype == '' || data.fieldtype == undefined) {
       valid = false
     }
     if (data.validationtype == null || data.validationtype == '' || data.validationtype == undefined) {
      valid = false
     }
     if(data.field_dbvalue==0 && (data.fieldtype=='radio' || data.fieldtype == 'checkbox' || data.fieldtype == 'drop-down' || data.fieldtype == 'multiselect-dropdown' )) {
          for(let i=0;i<data.fieldvalues.length;i++){
          if(!data.fieldvalues[i].optionvalue){
            valid = false
          }
        }
        }  
     return valid
   },
   cancelUpdate(idx) {
  
           this.formconfig.prevformconfiglist[idx]['trackerdetails'] =[];
          let tempsplit ='';
          let tempsplit2 ='';
          tempsplit = this.formconfig.prevformconfiglist[idx].tracker.split(',');
          tempsplit2 = this.formconfig.prevformconfiglist[idx].trackerid.split(',');
          if(tempsplit.length>1){
            for (let y = 0; y < tempsplit.length; y++) {
              let trackerobj = {};
              trackerobj['trackername'] = tempsplit[y];
              trackerobj['trackerid'] = tempsplit2[y];
              this.formconfig.prevformconfiglist[idx].trackerdetails.push(trackerobj);
            }
          }else{
            let trackerobj = {};
            trackerobj['trackername'] = this.formconfig.prevformconfiglist[idx].tracker;
            trackerobj['trackerid'] = this.formconfig.prevformconfiglist[idx].trackerid;
            this.formconfig.prevformconfiglist[idx].trackerdetails.push(trackerobj);
          }


    this.formconfig.formconfiglist = this.formconfig.formconfiglist.map((data, i) => {
       if(i === idx) {
          return { ...this.formconfig.prevformconfiglist[idx], edit: false }
       }
        return data
     })
    },
   saveData(data) {
     let valid = this.validateForm(data)
     if(valid) {
       data.edit = false
       let apiurl = `api/formconfigure/update`
       this.isLoading = true
       axios({
         method: 'POST',
         url: apiurl,
         data: {
           id: data.id,
           fieldname: data.fieldname,
           fieldtype: data.fieldtype,
           validationtype: data.validationtype,
           fieldplaceholder: data.fieldplaceholder ? data.fieldplaceholder : null,
           fieldindex: data.fieldindex ? data.fieldindex : null ,
           fieldheader: data.fieldheader ? data.fieldheader : null,
           fieldcomment: data.fieldcomment ? 1  : 0 ,
           fielderrormsg: data.fielderrormsg,
           ispublish: data.ispublish ? 1 : 0,
           islisting: data.islisting ? 1 : 0,
           iseditlisting: data.iseditlisting ? 1 : 0,
           isactive: data.isactive ? 1 : 0,
           tracker:data.trackeroption,
           fieldvalues: data.fieldvalues ? data.fieldvalues : null,
           field_dbvalue: data.field_dbvalue ? 1 : 0,
           lastmodifiedby: this.undt.username,
           useremail  : this.undt.username,
           empcode: this.undt.userid,
           kraheader:data.kraheader ? 1 : 0, 
           israting:data.israting ? 1 : 0,
           krafieldheader:data.krafieldheader ? data.krafieldheader : null,
           fieldheaderid : data.fieldheaderid ? data.fieldheaderid : null,
           department: data.department ? data.department : null,
           departmentid: data.departmentid ? data.departmentid : null

          },
         headers: { authorization :this.tokendata }
       }).then(result => {
         this.isLoading = false
         if(result.data.errorCode == 0) {
           Swal.fire({
             title: "Success!",
             text: result.data.msg,
             icon: 'success',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
           this.getAllFormConfigData(this.search);
         } else {
           Swal.fire({
             title: "Failed",
             text: result.data.msg,
             icon: 'info',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
         }
       }).catch(e => {
          this.displayError(e)
        })
     }
   },
  openpreviewform(tname,tid){
    this.clearpreviewdata();
    this.preview.fieldnamelist = [];
    if (tid && tid !=null) {
      this.preview.legendname = tname;
      this.getorientationfeedbackformlabels(tid);
    }else{
      Swal.fire({
        title: "Failed",
        text: 'Tracker Not Found',
        icon: 'info',
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: !1
      })
    }
  },
  getorientationfeedbackformlabels(trackerid) {
    if(trackerid && trackerid !=null){
      this.isLoading = true
      let url = 'api/feedbackform/getorientationfeedbackformlabels'
      let payload = new Object({
        empcode: this.undt.userid,
        useremail  : this.undt.username,
        moduletracker: trackerid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.preview.fieldnamelist = result.data.data;

          let temArray = {};
          for (let i = 0; i < this.preview.fieldnamelist.length; i++) {
            if ((this.preview.fieldnamelist[i].fieldheader ==null || this.preview.fieldnamelist[i].fieldheader =='') && this.preview.fieldnamelist[i].fieldtype !='hidden') {
              this.preview.tempcheck.push(i);
            }

            // header logic start
            if (this.preview.fieldnamelist[i].fieldheader!=null) {
              if(!temArray.hasOwnProperty(this.preview.fieldnamelist[i].fieldheader)){
                temArray[this.preview.fieldnamelist[i].fieldheader] = [];
              }
              var pushObj = this.preview.fieldnamelist[i];
              temArray[this.preview.fieldnamelist[i].fieldheader].push(pushObj);
            }
            // header logic end                  

            if (this.preview.fieldnamelist[i].fieldtype=="checkbox") {
              let tempdata = [];
              for (let j = 0; j < this.preview.fieldnamelist[i].fieldvalues.length; j++) {
                if (this.preview.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                  tempdata[j] = {}
                  tempdata[j].id=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                  tempdata[j].label=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                }
                this.preview.fieldnamelist[i]['optionalvalue']=tempdata
              }
            }
            if (this.preview.fieldnamelist[i].fieldtype=="radio") {
              let tempdata2 = [];
              for (let j = 0; j < this.preview.fieldnamelist[i].fieldvalues.length; j++) {
                if (this.preview.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                  tempdata2[j] = {}
                  tempdata2[j].id=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                  tempdata2[j].label=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                }
                this.preview.fieldnamelist[i]['radiooptionalvalue']=tempdata2
              }
            }
            if (this.preview.fieldnamelist[i].fieldtype=="drop-down") {
              let tempdata3 = [];
              for (let j = 0; j < this.preview.fieldnamelist[i].fieldvalues.length; j++) {
                if (this.preview.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                  tempdata3[j] = {}
                  tempdata3[j].id=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                  tempdata3[j].label=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                }
                this.preview.fieldnamelist[i]['optionalvalu1']=tempdata3
              }
            }
            if (this.preview.fieldnamelist[i].fieldtype=="multiselect-dropdown") {
              let tempdata4 = [];
              for (let j = 0; j < this.preview.fieldnamelist[i].fieldvalues.length; j++) {
                if (this.preview.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata4[j] = {}
                    tempdata4[j].id=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                    tempdata4[j].label=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                }
                this.preview.fieldnamelist[i]['optionalvalue2']=tempdata4
              }
            }
            if (this.preview.fieldnamelist[i].fieldtype=="toggle") {
              let tempdata5 = [];
              for (let j = 0; j < this.preview.fieldnamelist[i].fieldvalues.length; j++) {
                if (this.preview.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                  tempdata5[j] = {}
                  tempdata5[j].id=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                  tempdata5[j].label=this.preview.fieldnamelist[i].fieldvalues[j].optionvalue
                }
                this.preview.fieldnamelist[i]['optionalvalue3']=tempdata5
              }
            }
          }
          this.preview.tempheader = temArray;
        }else if(result.data.errorCode == -1){
          Swal.fire({
            text: result.data.msg,
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          })
          this.$router.push({ name: 'FormsConfigurationList', params: { search: true }})
        }else if(result.data.errorCode == 3){
          Swal.fire({
            title: "Session Expired...!!",
            text: result.data.msg,
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          }).then(function() {
            window.location.href = "/#/login";
          })
        } else {
          this.preview.fieldnamelist = [];
        }
      }).catch(e => {
          this.displayError(e)
        })
    }else{
      this.isLoading = false;
      $(".close").click();
      Swal.fire({
        title: "Failed",
        text: 'Tracker Not Found',
        icon: 'info',
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: !1
      })
    }
  },
  validText : function(inputval) {
    var re = /[A-Za-z0-9].{0,}/;
    if(inputval){
      return re.test(inputval.toLowerCase());
    }
  },
  validText2 : function(inputval) {
    var re = /[A-Za-z0-9].{0,}/;
    if(inputval){
      return re.test(inputval.toLowerCase());
    }
  },
  clearpreviewdata(){
    this.preview.fieldnamelist =[];
    this.preview.legendname =null;
    this.preview.tempcheck = [];
    this.preview.tempheader = [];
    this.fieldsnamevalues = {}
  },
  selDepartment(state) {
    this.search.departmentid = null;
    this.search.departmentid =state;
    if (!state) {
      this.trackerlist = [];
      this.search.tracker = null;
      this.search.isactive = null;
      this.search.ispublish = null;
      this.search.islisting = null;
      this.search.iseditlisting = null;
    } else {
      this.getTrackerList(state);
      this.getFieldNameList(state);
    }
  },
  selTracker(state) {
    this.search.tracker = null;
    this.search.tracker =state;
    if (!state) {
      this.search.isactive = null;
      this.search.ispublish = null;
      this.search.islisting = null;
      this.search.iseditlisting = null;
    } else {
      // this.getFieldNameList(state);
    }
  },
    getEmpDepartList() {
      this.isLoading = true
      let url = 'api/formconfigure/getEmpDepartList'
      let payload = new Object({
        empid: this.undt.userid,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.deparmentlist = result.data.data.rows;
          const decryptedDepartmentList = this.deparmentlist.map(item => {
          item.label = this.decryptText(item.label);
          return item;
            });
          if(this.$route.params && this.$route.params.search){
            let search = JSON.parse(localStorage.getItem('formsearchItems'));
            if(search){
              this.getSearchItems(search);
              this.getTrackerList(this.search.departmentid);
            }else{
              localStorage.removeItem('formsearchItems');
              if(result.data && result.data.data){
                if(result.data.data.orows && result.data.data.orows.length >0){
                  this.search.departmentid = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
                }
              }
              this.getTrackerList(this.search.departmentid);
              this.getAllFormConfigData();
            }
          }else{
            localStorage.removeItem('formsearchItems');
            if(result.data && result.data.data){
              if(result.data.data.orows && result.data.data.orows.length >0){
                this.search.departmentid = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
              }
            }
            this.getTrackerList(this.search.departmentid);
            this.getAllFormConfigData();
          }
        let dp = this.deparmentlist.filter((v) => {
        if(v.id==this.search.departmentid)
        return v;
        });
        this.search.department = dp ? dp[0].label : null
        this.deparmentlist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.deparmentlist=[]
          this.search.department = null
        }
      }).catch(e => {
          this.displayError(e)
        })
    },
    oselDepartment(state,index){


      let dp = this.deparmentlist.filter((v) => {
      if(v.id==state)
      return v;
      });
      this.search.department = dp ? dp[0].label : null

      this.trackerlist = []
      this.formconfig.formconfiglist[index].fieldheaderid = null
    this.formconfig.formconfiglist[index].fieldheader = null

      this.formconfig.formconfiglist[index].trackeroption = []
      if(!state){
        this.trackerlist = []
      this.formconfig.formconfiglist[index].fieldheaderid = null
    this.formconfig.formconfiglist[index].fieldheader = null

      this.formconfig.formconfiglist[index].trackeroption = []
      }else{
        this.getTrackerList(state)

      }

      

    },
    getKRAHeaderList(tracker,index,kraheader) {
    
        let convertedtracker = tracker.map(element => String(element));
 
      if(convertedtracker.length <= 0){

     this.formconfig.formconfiglist[index].fieldheaderlist = []
     this.formconfig.formconfiglist[index].fieldheaderid = null
     this.formconfig.formconfiglist[index].fieldheader = null
      }

      if(convertedtracker.length > 0){
      this.isLoading = true
      let url = 'api/formconfigure/getKRAdetailList'
      let payload = new Object({
         useremail  : this.undt.username,
        empcode: this.undt.userid,
        tracker : convertedtracker
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.formconfig.formconfiglist[index].fieldheaderlist = result.data.data
          if(!kraheader){
            this.formconfig.formconfiglist[index].fieldheader = null
          }

          } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            this.formconfig.formconfiglist[index].fieldheaderlist = []
            this.formconfig.formconfiglist[index].fieldheaderid = null
             
         }
      }).catch(e => {
          this.displayError(e)
        })
    }
    },
    selectHeader(state,index){
      let krname = null
      this.formconfig.formconfiglist[index].fieldheaderlist.forEach(function(obj) {
        if(obj.id==state){
          krname = obj.label
        } 
      });
      this.formconfig.formconfiglist[index].krafieldheader = krname
      this.formconfig.formconfiglist[index].kraheader = true
    },
    getSearchItems(search) {
      this.isSearchBoxActive = true;
      this.search.tracker = search.tracker;
      this.search.title = search.title;
      this.search.ispublish = search.ispublish;
      this.search.departmentid = search.departmentid;
      this.getAllFormConfigData(search);
    },
 }
}
</script>
<style scoped>
 .box-inc {
   min-width: 200px;
 }
.box-inc .projectlistdd.vue-treeselect.vue-treeselect--multi{
  padding:0px;
}
.modal_header_overflow_page{
  overflow-y: scroll;
}
.modal_header_overflow_page .card1.resolved{
  border:1px solid #eee;
}
.modal_header_overflow_page .card1.resolved{
       border-left: 5px solid #28C76F!important;
}
</style>