<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Functionality Module</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li v-if="pagefeatures.breadcrum_project" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Wizard',path:'project',btnstate:'view',rowdata:backpagedata})">Projects</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Projects</li>
                                    <li v-if="pagefeatures.breadcrum_application" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application',path:'application',btnstate:'view',rowdata:backpagedata})">Applications</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Applications</li>
                                    <li v-if="pagefeatures.breadcrum_module" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Module',path:'projectmodule',btnstate:'view',rowdata:backpagedata})">Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Modules</li>
                                    <li v-if="pagefeatures.breadcrum_appmodule" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application Module',path:'applicationmodule',btnstate:'view',rowdata:backpagedata})">Application Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Application Modules</li>
                                    <li v-if="pagefeatures.breadcrum_section" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'SectionModule',path:'sectionmodule',btnstate:'view',rowdata:backpagedata})">Sections</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Sections</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">
                    <div class="row" style="max-height:310px; overflow-y:auto;">
                        <div class="col-12">  
                            <div  v-if="pagefeatures.searchsection" class="card card-body" style="margin-top:10px">
                                <h4 class="content-header-title float-center mb-0" style="text-align:center; margin-top:0.2px;margin-bottom:80px">SEARCH PROJECT SECTION DETAILS</h4>
                                <div class="row" >
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Project Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" v-model="search.projectMasterid" :options="search.projectMasterOpts" @input="selprojectid"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Project Modules</label>
                                        <treeselect class="projectmdllistdd capitalisetext" v-model="search.projectmoduleid" :options="search.projectModuleOpts" @input="selprojectModuleid"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                         <label class="form-label lable-left">Application</label>
                                         <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationMasterid"
                                            :options="search.applicationMasterOpts"
                                            @input="selApplicationid"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Application Module</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationModuleid"
                                            :options="search.applicationModuleOpts" @input="selApplicationModuleid"/>
                                    </div>
                                    
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Section</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.sectionMasterid"
                                            :options="search.sectionMasterOpts"/>
                                     </div>

                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <button
                                            type="submit" class="btn btn-primary mt-2 mr-1" style="text-align:center" @click="getFilter()">Search</button>
                                        <button type="reset" class="btn btn-outline-secondary mt-2" @click="resetFilter()">Cancel</button>
                                    </div>
                                </div>
                            </div>                          
                            <div v-if="pagefeatures.sectiondetails" class="card border-tabs" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                                <div class="card-header" style="cursor:pointer;" v-on:click="mysearchrequestollapse">
                                    <div class="avatar bg-light-info rounded" style="margin-left:27%">
                                        <div class="avatar-content">
                                            <grid-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></grid-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title" style="margin-left:-449px;">PROJECT SECTION DETAILS</h4>
                                    <div class="heading-elements" style="color:blue;margin-right:50px;">
                                        <ul class="list-inline mb-0">
                                            <li>
                                                <a data-action="collapse"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:10px">
                                    <div class="card-body">
                                        <div class="row">
                                        <div  v-for="(data,index1) in modulelist" v-bind:key="'sectionlisthdr'+data.moduleid+Math.floor(Math.random() * 1000000000)" class="col-lg-3 col-md-6 col-12">
                                            <div v-bind:key="'sectionlist'+data.moduleid+Math.floor(Math.random() * 1000000000)" class="card border cursor-pointer" @click="getfunctionalitylist(data)" :class="[index1 < 9 ? stylevariation[index1].label : 'bg-secondary']">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between text-white">
                                                        <box-icon size="40x" class="custom-class"></box-icon>
                                                    </div>
                                                    <div class="my-1">
                                                        <h4 class="capitalisetext text-white">{{data.sectionname}}</h4>
                                                         <h5 class="capitalisetext text-white">{{dateFormat(data.createddate)}}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card border-warning filter_card_searchbgst filter_card_searchbgst" >
                        <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                            <div class="container-fluid">
                                <div class="row fc_my-05" >
                                    <div class="w-auto px-1 mobile-padding-0-2">
                                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                                            <div class="avatar-content">
                                                <search-icon
                                                size="1.5x"
                                                class="custom-class avatar-icon font-medium-3"
                                                ></search-icon>
                                            </div>
                                            </div>
                                        <h4 class="card-title d-inline-flex ml-1">Search Functionality Details</h4>
                                    </div>
                                    <div class="ml-auto d-flex pr-lg-1">
                                    <div  v-if="pagefeatures.cratefunctionalitymodule || showbutton" class=" px-1 mobile-padding-0-2">
                                                                    <div class="input-group-append displayblk float-right">
                                                                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#functionalitymodulemaster"  type="button" data-backdrop="static" data-keyboard="false" @click="addmodule">
                                                                   
                                              <span class="mobile_hide_bt mr-1"> Create Functionality</span>  
                                              <plus-circle-icon size="1.5x"  class="custom-class"></plus-circle-icon> </button>
                                                                    </div>
                                                                </div>
                                                                
                                            <div v-else id="commonaddmodule" data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#functionalitymodulemaster"></div>
                                            <span class="rotetions_view_arrow"> 
                                                <em class="las la-angle-down"></em>
                                            </span>
                                        </div>
                                           
                                </div>

                            </div>
                        </div>
                        <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                            <div class="card-body pt-2 px-sm-mobile-10">
                                <div class="row" >
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Project Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.projectMasterid" :options="search.projectMasterOpts" @select="selprojectid2"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Application</label>
                                        <treeselect placeholder="Select application name"
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationMasterid"
                                            :options="search.applicationMasterOpts"
                                            @input="selApplicationid"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Project Modules</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project module" v-model="search.projectModuleid" :options="search.projectModuleOpts" @input="selprojectModuleid"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Application Module</label>
                                        <treeselect placeholder="Select application module name"
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationModuleid"
                                            :options="search.applicationModuleOpts" @input="selApplicationModuleid"/>
                                    </div>                     
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Section</label>
                                        <treeselect placeholder="Select section name"
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.sectionMasterid"
                                            :options="search.sectionMasterOpts"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-0">
                                        <label class="form-label lable-left">Functionality Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search functionality name" v-on:search-change="functionalityChange" v-model="search.functionalityMasterid" :options="search.functionalityMasterOpts" />
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6 mb-0 text-right">
                                        <button type="submit" class="btn btn-relief-primary mt-2 mr-1" style="text-align:center" @click="getfunctionalitylist(search)"><search-icon
                                                size="1.5x"
                                                class="custom-class"
                                              ></search-icon> <span>Search</span></button>
                                        <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetFilter()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span>Clear</span></button>
                                       <button type="move" class="btn btn-relief-primary mt-2" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="showproject">
                        <div class="col-md-12">
                            <div class="card mb-3">
                                   <div v-if="pagefeatures.cratefunctionalitymodule" >
                                    <div class="card-header p-2">
                                    <div class="input-group-append">
                                        <h4 class="card-title">Project Section Name: <strong class="capitalisetext">{{functionalitymodule.sectionname}}</strong></h4>
                                    </div>
                                    </div> 
                                </div>
                                <div class="">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="funModuleList">
                                                <div class="col-12">
                                                    <div class="">
                                                        <div class="table-responsive datatableview" >
                                                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="srno py-1 align-middle first-col-sticky" style="min-width:45px;">Sr#</th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Functionality Name</th>
                                                                        <th scope="col" class="align-middle">Description</th>
                                                                        <th scope="col" class="align-middle">Project Name</th>
                                                                        <th scope="col" class="align-middle">Module Name</th>
                                                                        <th scope="col" class="align-middle">Section Name</th>
                                                                        <th scope="col" class="align-middle">Created Date</th>
                                                                        <th scope="col" class="clsaction align-middle">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-for="(data,index) in companymasterlist">        
                                                                        <tr v-bind:key="'functionlist'+data.moduleid+Math.floor(Math.random() * 1000000000)">
                                                                            <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                            <td data-column="Functionality Name" class="second-col-sticky">
                                                                                <span class="font-weight-normal cursorpointercls sitebluecolor" style="word-break: break-word;" @click="redirecttourl('view',data)">{{data.functionlityname}}</span>
                                                                            </td>
                                                                            <td data-column="Description">
                                                                                <span class="font-weight-normal" v-if="data.description && data.description.length > 10">{{data.description.substring(0,10+1)+'...'}}</span>
                                                                                <span class="font-weight-normal" v-if="data.description && data.description.length <= 10">{{data.description}}</span>
                                                                            </td>
                                                                            <td data-column="Project Name">
                                                                                <span class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('projectId',data)">{{data.projectname}}</span>
                                                                            </td>
                                                                            <td data-column="Module Name">
                                                                                <span class="font-weight-normal capitalisetext">{{data.projectmodulename}}</span>
                                                                            </td>
                                                                            <td data-column="Section Name">
                                                                                <span class="font-weight-normal capitalisetext">{{data.sectionname}}</span>
                                                                            </td>
                                                                            <td data-column="Created Date" class="text-nowrap">{{dateFormat(data.createddate)}}</td>
                                                                            
                                                                            <td data-column="Actions" class="clsaction">
                                                                                
                                                                                <div class="dropdown">
                                                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                        <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                    </button>
                                                                                    <div class="dropdown-menu" style="left:-76px !important;">
                                                                                        <div v-if="data.projectlevel == 6">
                                                                                            <a class="dropdown-item devEditEle" href="javascript:void(0);" @click="redirecttowsurl('view',data)">
                                                                                                <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                <span>View Workstep</span>
                                                                                            </a>
                                                                                            <a class="dropdown-item" @click="redirecttowsurl('edit',data)" href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                                <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                                <span>Add Workstep</span>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div v-else>
                                                                                            <a class="dropdown-item" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                                                <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                <span>View Work Step</span>
                                                                                            </a>
                                                                                            <a class="dropdown-item" @click="redirecttourl('edit',data)" href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                                <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                                <span>Add Work Step</span>
                                                                                            </a>
                                                                                        </div>
                                                                                        <a class="dropdown-item" @click="redirectToCreateForms('addissue',data)" href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                            <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                            <span>Add Issue</span>
                                                                                        </a>
                                                                                        <a class="dropdown-item devEditEle" href="javascript:void(0);" @click="redirectToCreateForms('viewtestcase',data)">
                                                                                            <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                            <span>View Testcase</span>
                                                                                        </a>
                                                                                        <a class="dropdown-item" @click="redirectToCreateForms('addtestcase',data)" href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)">
                                                                                            <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                            <span>Add Testcase</span>
                                                                                        </a>
                                                                                        <a class="dropdown-item devEditEle" href="javascript:void(0);"   data-toggle="modal" data-target="#functionalitymodulemaster" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                            <span>Edit</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="companymasterlist.length==0 && !isresponce">
                                                                        <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="pageCenter text-center pt-2">
                                            <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="functionalitymodulemaster">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1"  style="background-color:#8c564b !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle"  style="margin-left: 5px;">Add/Update Functionality Module Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row"> 
                            <div class="card shadow-none px-1">
                                <div class="table-responsive">
                                  <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                                    <thead style="text-align: center;">
                                      <tr>
                                        <th scope="col">Project</th>
                                        <th scope="col">Application</th>
                                        <th scope="col">Project Module</th>
                                        <th scope="col">Application Module</th>
                                        <th scope="col">Section Module</th>
                                      </tr>
                                    </thead>
                                    <tbody style="text-align: center;">
                                        <td class="font-weight-normal">
                                            {{this.functionalitymodule.project ? this.functionalitymodule.project:'NA'}}
                                            </td>
                                        <td>
                                         <span class="font-weight-normal" v-html="showAppNames(this.functionalitymodule.applicationname)"></span>
                                        </td>
                                        <td>
                                            {{this.functionalitymodule.projectmodule ? this.functionalitymodule.projectmodule:'NA'}}
                                        </td>
                                        <td class="font-weight-normal">
                                            {{this.functionalitymodule.applicationmodulename ? this.functionalitymodule.applicationmodulename:'NA'}}
                                            </td>
                                        <td class="font-weight-normal">
                                            {{this.functionalitymodule.sectionname ? this.functionalitymodule.sectionname:'NA'}}
                                            </td>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="appmdlnm">Functionality Name</label>
                                   <input type="text" id="modulenamet22" class="form-control" name="" placeholder="Functionality Name" v-model="functionalitymodule.functionlityname"
                                   v-bind:class="{ 
                                            'form-control': true,
                                            '': !valid2Text(functionalitymodule.functionlityname) && functionlitynameBlured, }"
                                            v-on:blur="functionlitynameBlured = true"/>
                                    <div class="errorinputmsg" v-if="!valid2Text(functionalitymodule.functionlityname) && functionlitynameBlured">Functionality name is required</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="appmdldesc">Functionality Description</label>
                                    <textarea class="form-control appmdldesc" v-model="functionalitymodule.functionlitydescription" id="appmddescription" rows="5" placeholder="Functionality Description "                                   v-bind:class="{ 
                                            'form-control': true,
                                            '': !valid2Text(functionalitymodule.functionlitydescription) && functionlitydescBlured, }"
                                            v-on:blur="functionlitydescBlured = true"/>
                                    <div class="errorinputmsg" v-if="!valid2Text(functionalitymodule.functionlitydescription) && functionlitydescBlured">Functionality description is required</div>
                                </div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-primary mr-1"  @click="saveData('t37',$event)" id="submitbtnt37">Submit</button>
                                <button style="margin-right:2px;" type="button" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { ExternalLinkIcon,XIcon,PlusCircleIcon,PlusSquareIcon,FolderIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon } from 'vue-feather-icons'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';

export default {
    name:'SectionModule',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        FolderIcon,
        PlusSquareIcon,
        PlusCircleIcon,
        XIcon,
        ExternalLinkIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            showbutton:false,
            isLoading: false,
            fullPage: true,
            islistLoading:false,
            listfullPage:true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            functionalitymodule:{
                sectionmasterid:null,
                sectionmasterlist:null,
                applicationmoduleid:null,
                applicationmodulelist:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                applicationid:null,
                applicationlist:null,
                functionlityname:null,
                functionlitydescription:null,
                appprojectdd:null,
                projectmoduleid:null,
                label:null,
                project:null,
                projectmodule:null,
                applicationname:null,
                applicationmodulename:null,
                sectionname:null,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                iseditstate:false
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            pageoffset:1,
            currPageNum: 0,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                functionalitymodule:{
                    functionlityname:null,
                    functionlitydescription:null
                },
            },
            valid:false,
            projectmoduleid:null,
            projectname:null,
            projectmodulename:null,
            projectnameList:[],
            projectmodulenameList:[],
            search: {
                applicationModuleid: null,
                applicationModuleOpts: [],
                applicationMasterid: null,
                applicationMasterOpts: [],
                projectMasterid: null,
                projectMasterOpts: [],
                projectModuleid: null,
                projectModuleOpts: [],
                sectionMasterid: null,
                sectionMasterOpts: [],
                functionalityMasterid: null,
                functionalityMasterOpts: [],
            },
            isSearch:false,
            backpagedata:null,
            functionlitynameBlured:false,
            functionlitydescBlured:false,
            tokendata: null,
            isresponce:false,
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/functionalitymodule') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            this.tokendata = window.localStorage.getItem('token');
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
            }
            if(window.localStorage.getItem('functionalitymodule')){
                let searchData =  window.atob(window.localStorage.getItem('functionalitymodule'));
                searchData = JSON.parse(searchData)
                this.search.projectMasterid=searchData.projectMasterid
                if(this.search.projectMasterid && this.search.projectMasterid !=null){
                    this.getProjectDataByProjectID(this.search.projectMasterid);
                }
                this.search.applicationMasterid = searchData.applicationMasterid
                this.search.projectModuleid = searchData.projectModuleid
                this.search.applicationModuleid = searchData.applicationModuleid
                this.search.sectionMasterid = searchData.sectionMasterid
                this.search.functionalityMasterid = searchData.functionalityMasterid
            }
                 
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view' || this.$route.params.backbtnstate == 'viewfun') {
                    let search = JSON.parse(localStorage.getItem('functionalitysearchItems'))
                    if(search && (search.hasOwnProperty("projectMasterid") || search.hasOwnProperty("projectid"))) {
                        if(!this.projectMasterOpts){
                            this.projectChange(search.projectname)
                        }
                        if(this.$route.params && this.$route.params.clickeddata){
                            this.search.projectMasterid = this.$route.params.clickeddata.projectid;
                            this.search.applicationMasterid = this.$route.params.clickeddata.applicationid;
                            this.search.projectModuleid = this.$route.params.clickeddata.projectmoduleid;
                            this.search.applicationModuleid = this.$route.params.clickeddata.applicationmoduleid;
                            this.search.sectionMasterid = this.$route.params.clickeddata.sectionmasterid;
                        }
                        this.getSearchItems(search);
                    }else{
                        localStorage.removeItem('functionalitysearchItems');
                        this.getfunctionalitylist(this.$route.params.clickeddata);
                    }
                    this.showbutton=true
                    this.backpagedata=this.$route.params.clickeddata
                    // this.getfunctionalitylist(this.$route.params.clickeddata)
                    if(this.$route.params.clickeddata.projectmoduleid){
                        this.getprojectmoduleapplications(this.$route.params.clickeddata);
                    }
                    this.getmodulelist(this.$route.params.clickeddata.applicationmoduleid);
                    if(this.$route.params.backbtnstate == 'edit'){
                        this.functionalitymodule.project = this.backpagedata.projectname;
                        this.functionalitymodule.projectid = this.backpagedata.projectid;
                        this.functionalitymodule.projectmodule = this.backpagedata.projectmodulename;
                        this.functionalitymodule.projectmoduleid = this.backpagedata.projectmoduleid;
                        this.functionalitymodule.applicationmodulename = this.backpagedata.applicationmodulename;
                        this.functionalitymodule.applicationmoduleid = this.backpagedata.applicationmoduleid;
                        this.functionalitymodule.sectionname = this.backpagedata.sectionname
                        this.functionalitymodule.sectionmasterid = this.backpagedata.sectionid ? this.backpagedata.sectionid : this.backpagedata.sectionmasterid;
                        setTimeout(function(){
                            $('#commonaddmodule').click();
                        }, 1000);
                    }                    
                }
            }else{
                localStorage.removeItem('functionalitymodule');
                localStorage.removeItem('functionalitysearchItems');
                this.getfunctionalitylist(this.search)
                this.getmodulelist();
            }
         }
         $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        showAppNames(prlist){
            let responsetr='';
            if(!prlist) return 'NA'
            if(typeof(prlist) != 'object') return prlist
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                responsetr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsetr +=prlist[i]
                }
            }
            return responsetr
        },
        getProjectDataByProjectID(projectmasterid){
            this.isLoading=true
            let url = 'api/listing/getprojectlistbycompanyid'
            this.input = {
                "companyid":null,
                "projectmasterid":projectmasterid,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.search.projectMasterOpts=result.data.data;
                }else{
                    this.search.projectMasterOpts=[]
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        redirecttobreadkumb:function(objThis){
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if (btnstate == 'view' || btnstate == 'edit') {
                this.$router.push({ 
                    name: 'Work Step',
                    path: '/workstep',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if (btnstate == 'projectId') {
                this.$router.push({
                    path: '/project/summary?pid='+rowdata.projectid,
                })
            }
        },
        redirectToCreateForms:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate === 'addissue') {
                this.$router.push({ 
                    name: 'IssueCreate',
                    path: '/issues/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if(btnstate === 'addtestcase') {
                this.$router.push({ 
                    name: 'tcreate',
                    path: '/testcasetask/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if(btnstate === 'viewtestcase') {
                this.$router.push({ 
                    name: 'tlist',
                    path: '/testcasetask/list',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
       
        redirecttowsurl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'Work Step',
                path: '/workstep',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        selappapltype(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
        getmodulelist:function(applicationmoduleid){
            let vm = this
            this.isLoading=true
            let url = 'api/projectapplication/getSectionList'
            this.input = {
                "offset":1,
                "limit":apiUrl.LIMIT,
                "applicationmoduleid" :applicationmoduleid,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.showproject=true;
                    this.modulelist=result.data.data.rows;
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else{
                    this.modulelist=[]
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        
        getFilter: function() {
            this.islistLoading=true
            let url = 'api/projectapplication/getfunctionalitylist'
            this.input = {
                "offset":1,
                "tbl":"t34",
                "limit":1000,
                "isSearch":true,
                "section":"functionalitymodule",
                "isFilterSelect":1,
                "isFilterPayload":{},
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            if(this.search.applicationModuleid && this.search.applicationModuleid != null) {
                this.input.isFilterPayload.applicationModuleid = this.search.applicationModuleid
            }
            if(this.search.projectMasterid && this.search.projectMasterid != null) {
                this.input.isFilterPayload.projectMasterid = this.search.projectMasterid
            }
            if(this.search.applicationMasterid && this.search.applicationMasterid != null) {
                this.input.isFilterPayload.applicationMasterid = this.search.applicationMasterid
            }
            if(this.search.projectModuleid && this.search.projectModuleid != null) {
                this.input.isFilterPayload.projectModuleid = this.search.projectModuleid
            }
            if(this.search.sectionMasterid && this.search.sectionMasterid != null) {
                this.input.isFilterPayload.sectionMasterid = this.search.sectionMasterid
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
                .then(result => {  
                    this.islistLoading=false;           
                    if(result.data.errorCode == 0) {            
                        this.companymasterlist=result.data.data;
                        this.totalcount = result.data.data.length
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                        this.companymasterlist=[]
                        this.totalcount = 0
                        this.isSearch= true
                    }
                }).catch(e => {
                this.displayError(e)
            })
        },
        projectChange: function (node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                        localStorage.setItem('functionalityprojectMasterOpts', JSON.stringify(result.data.data))
                    } else {
                        this.search.projectMasterOpts = [];
                        localStorage.removeItem('functionalityprojectMasterOpts')
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        projectChangebyid: function (projectidarr) {
            if(projectidarr && projectidarr.length>0) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectidarr: projectidarr,
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                    } else {
                        this.search.projectMasterOpts = [];
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        functionalityChange: function(node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/master/commonmaster/getModuleDropdownOption";
                this.input = {
                    tbl: "t37",
                    functionalityname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.functionalityMasterOpts = result.data.data;
                        localStorage.setItem('functionalityMasterOpts', JSON.stringify(result.data.data))
                    } else {
                        this.search.functionalityMasterOpts = [];
                        localStorage.removeItem('functionalityMasterOpts')
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        selprojectid(state, value) {
            this.search.projectMasterid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
                this.search.functionalityMasterOpts = null;
                this.search.projectModuleid = null;
                this.search.applicationMasterid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
            }
        },
        selprojectid2(state, value) {
            this.search.projectMasterid = state.id;
            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
                this.search.functionalityMasterOpts = null;
                this.search.projectModuleid = null;
                this.search.applicationMasterid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
                this.search.functionalityMasterid = null;
            } else {
                this.search.projectModuleid = null;
                this.search.applicationMasterid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
                this.search.functionalityMasterid = null;
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
            }
        },
        selprojectModuleid(state, value) {
            
            this.search.projectModuleid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationModuleOpts = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selApplicationid(state, value) {
            this.search.applicationMasterid = state;
            if (!state && this.isAddState == false) {
                this.search.projectModuleOpts = null;
                this.search.projectModuleid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },

        selApplicationModuleid(state,value) {
            this.search.applicationModuleid = state;
            if (!state && this.isAddState == false) {
                this.search.sectionMasterOpts = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t35");
            }
        },
        resetFilter: function (){
            this.search.projectMasterOpts = null,
            this.search.functionalityMasterOpts = null,
            this.search.projectModuleOpts = null,
            this.search.applicationModuleid = null;
            this.search.applicationMasterid = null;
            this.search.projectmoduleid= null;
            this.search.projectMasterid = null;
            this.search.projectModuleid = null;
            this.search.functionalityMasterid=null;
            this.projectnameList = null;
            this.search.functionalityMasterOpts=null;
            localStorage.removeItem("functionalitysearchItems");
            localStorage.removeItem("functionalityprojectMasterOpts");
            localStorage.removeItem("functionalityapplicationMasterOpts");
            localStorage.removeItem("functionalityprojectModuleOpts");
            localStorage.removeItem("functionalityapplicationModuleOpts");
            localStorage.removeItem("functionalitysectionMasterOpts");
            localStorage.removeItem("functionalityMasterOpts");
            let rowdata = {}
            this.getfunctionalitylist(rowdata)
        },
        resetForm: function () {
            this.functionalitymodule.functionlityname = null       
            this.functionalitymodule.functionlitydescription = null
        },

        getProjectname(){
            this.apiURL="api/projectapplication/getprojectnamelist"
            this.input={
                projectid:0,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.errorCode == 0){
                    this.search.projectMasterOpts = result.data.data;
                }
                else{
                    this.search.projectMasterOpts =[]
                }

            }).catch(e => {
                this.displayError(e)
            })
        },

        getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            if(mdl === "t36") {
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t12") {
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t34") {
                this.input.projectModuleid = this.search.projectModuleid
            }
            else if(mdl === "t35") {
                this.input.applicationModuleid = this.search.applicationModuleid
            } 
                else if(mdl === "t37") {
                this.input.sectionMasterid = this.search.sectionMasterid
            } 
            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                            localStorage.setItem('functionalityprojectModuleOpts', JSON.stringify(result.data.data))
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                            localStorage.setItem('functionalityapplicationMasterOpts', JSON.stringify(result.data.data))
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                            localStorage.setItem('functionalityapplicationModuleOpts', JSON.stringify(result.data.data))
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = result.data.data;
                            localStorage.setItem('functionalitysectionMasterOpts', JSON.stringify(result.data.data))
                        } 
                    this.loadFunctionalityNames("t37")
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = []
                            localStorage.removeItem('functionalityprojectModuleOpts')
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = []
                            localStorage.removeItem('functionalityapplicationMasterOpts')
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = [];
                            localStorage.removeItem('functionalityapplicationModuleOpts')
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = [];
                            localStorage.removeItem('functionalitysectionMasterOpts')
                        }
                    }
                }).catch(e => {
                this.displayError(e)
            })

        },
        loadFunctionalityNames(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
                projectMasterid: this.search.projectMasterid,
                applicationMasterid: this.search.applicationMasterid,
                projectModuleid: this.search.projectModuleid,
                applicationModuleid: this.search.applicationModuleid,
                sectionMasterid: this.search.sectionMasterid,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': apiUrl,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
             })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.search.functionalityMasterOpts = result.data.data;
                        localStorage.setItem('functionalityMasterOpts', JSON.stringify(result.data.data))
                    } else {
                        this.search.functionalityMasterOpts = null;
                        localStorage.removeItem('functionalityMasterOpts')
                    }
                })
        },
        getSearchItems(search) {
            this.isSearchRequestActive = true
            this.search.projectMasterOpts = JSON.parse(localStorage.getItem('functionalityprojectMasterOpts'))
            this.search.applicationMasterOpts = JSON.parse(localStorage.getItem('functionalityapplicationMasterOpts'))
            this.search.projectModuleOpts = JSON.parse(localStorage.getItem('functionalityprojectModuleOpts'))
            
            this.search.sectionMasterOpts = JSON.parse(localStorage.getItem('functionalitysectionMasterOpts'))
            this.search.functionalityMasterOpts = JSON.parse(localStorage.getItem('functionalityMasterOpts'))
            this.search.projectMasterid  = this.search.projectMasterid ? this.search.projectMasterid : search.projectid ? search.projectid : search.projectMasterid ? search.projectMasterid: search.searchProjectname ? search.searchProjectname :null; 
            this.search.applicationMasterid  = this.search.applicationMasterid ? this.search.applicationMasterid : search.applicationid ? search.applicationid :search.applicationMasterid ? search.applicationMasterid :null;
            this.search.projectModuleid  = this.search.projectModuleid ? this.search.projectModuleid : search.projectModuleid ? search.projectModuleid :null; 
            this.search.applicationModuleid = this.search.applicationModuleid ? this.search.applicationModuleid : search.applicationModuleid ? search.applicationModuleid :null;
            this.search.sectionMasterid  = this.search.sectionMasterid ? this.search.sectionMasterid : search.sectionMasterid ? search.sectionMasterid :null;
            this.search.functionalityMasterid  = search.functionalityMasterid ? search.functionalityMasterid :null;
            if(this.search.applicationMasterid){
                this.getDropdownApplicationdata("t12");
            }
            if(this.search.projectModuleid){
                this.getDropdownApplicationdata("t36");
            }
            if(this.search.applicationModuleid){
                this.getDropdownApplicationdata("t34");
            }
            if(this.search.sectionMasterid){
                this.getDropdownApplicationdata("t35");
            }
            this.projectChangebyid([this.search.projectMasterid]);
            this.getfunctionalitylist(this.search);
        },
        getfunctionalitylist(rowdata){
            this.islistLoading=true
            if(rowdata) {
                localStorage.setItem('functionalitysearchItems', JSON.stringify(rowdata))
            }
            this.clickedprojdata=rowdata
            this.input = {
               offset: this.pageoffset,
               limit: apiUrl.LIMIT,
               sectionmasterid:rowdata.sectionmasterid,
               projectMasterid: rowdata.projectMasterid || rowdata.searchProjectname,
               projectModuleid: rowdata.projectModuleid,
               applicationMasterid: rowdata.applicationMasterid,
               applicationModuleid: rowdata.applicationmoduleid,
               sectionMasterid: rowdata.sectionMasterid,
               functionalityMasterid: rowdata.functionalityMasterid,
               uniqueid: this.undt.userid,
               empcode: this.undt.userid,
               useremail: this.undt.username
            }
            localStorage.setItem('functionalitymodule',window.btoa(JSON.stringify(this.input)));
            let url = 'api/functionality/list'
            this.functionalitymodule.project=this.clickedprojdata.projectname
            this.functionalitymodule.projectmodule=this.clickedprojdata.projectmodulename
            this.functionalitymodule.appprojectdd = this.clickedprojdata.projectid
            this.functionalitymodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.functionalitymodule.applicationid = this.clickedprojdata.applicationMasterid
            this.functionalitymodule.applicationname = this.clickedprojdata.applicationname
            this.functionalitymodule.applicationmodulename = this.clickedprojdata.applicationmodulename
            this.functionalitymodule.sectionname = this.clickedprojdata.sectionname
            this.functionalitymodule.sectionmasterid = this.clickedprojdata.sectionmasterid
            this.functionalitymodule.applicationmoduleid = this.clickedprojdata.applicationmoduleid
            this.functionalitymodule.projectid = this.clickedprojdata.projectid
            this.functionalitymodule.label = this.clickedprojdata.label
            this.functionalitymodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.functionalitymodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.functionalitymodule.companyid=this.clickedprojdata.companyid
            this.getApplicationdata('t3','functionalitymodule')
            this.getApplicationdata('t2','functionalitymodule')
            this.getApplicationdata('t1','functionalitymodule')
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.islistLoading=false; 
                if(result.data.errorCode == 0){
                    this.isresponce=true
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else{
                    this.companymasterlist=[]
                    this.showproject=true;
                    this.totalcount = 0
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);                
            }).catch(e => {
                this.displayError(e)
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getfunctionalitylist(this.clickedprojdata)
            }
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#funModuleList").offset().top -70
                });
            }, 500);
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        setSelected(value)
        {
            this.selected=value
        },
        validateForm(mdl,objThis){
            this.isAddState=false
            var isValid=true;
            this.functionlitydescBlured=true;
            this.functionlitynameBlured=true;
            if(mdl=="t37"){
                if(!this.functionalitymodule.sectionmasterid){
                    this.errors.functionalitymodule.sectionmasterid="Section required";
                    isValid= false;
                }
                if(!this.functionalitymodule.applicationmoduleid){
                    this.errors.functionalitymodule.applicationmoduleid="Application Module required";
                    isValid= false;
                }
                if(!this.functionalitymodule.projectid){
                    this.errors.functionalitymodule.projectid="Project required";
                    isValid= false;
                }
               
                if(!this.functionalitymodule.functionlityname){
                    this.errors.functionalitymodule.functionlityname="Functionality name required";
                    isValid= false;
                }
                if(!this.functionalitymodule.functionlitydescription){
                    this.errors.functionalitymodule.functionlitydescription="Functionality description required";
                    isValid= false;
                }
            }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                functionalitymodule:{
                    functionlityname:null,
                    functionlitydescription:null
                },
            };
        },
        openEditModule(currRowData){
            this.editRowData = currRowData
            this.functionalitymodule.sectionmasterid = currRowData.sectionmasterid
            this.functionalitymodule.projectid = currRowData.projectid
            this.functionalitymodule.projectmoduleid = currRowData.projectmoduleid
            this.functionalitymodule.applicationmoduleid = currRowData.applicationmoduleid
            this.functionalitymodule.applicationid = currRowData.applicationid
            this.functionalitymodule.functionlityname = currRowData.functionlityname
            this.functionalitymodule.functionlitydescription = currRowData.functionlitydescription
            this.functionalitymodule.project = currRowData.projectname
            this.functionalitymodule.projectmodule= currRowData.projectmodulename
            
            this.getprojectmoduleapplications(currRowData)
            this.functionalitymodule.applicationmodulename= currRowData.applicationmodulename
            this.functionalitymodule.sectionname= currRowData.sectionname
            this.functionalitymodule.projecttypeid= currRowData.projecttypeid
            this.functionalitymodule.businessverticleid= currRowData.businessverticleid
            this.functionalitymodule.companyid= currRowData.companyid

            this.functionalitymodule.iseditstate = true
            this.isAddState=true
        },
        getprojectmoduleapplications: function(rowdata){
            this.inputprojappl = {
                id:rowdata.projectmoduleid,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            let apiURL='api/projectmodule/getapplications'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.functionalitymodule.applicationname=[]
                    
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                            this.functionalitymodule.applicationname.push(element.applicationname)
                        }
                    });

                }else{
                    this.applicationmodule.applicationid=null
                }
            })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
       },
        saveData(tblNm,objThis){
            if( tblNm=="t37"){
                this.valid=this.validateForm(tblNm)
            }else{
                this.valid=false;
            }
            if (this.valid) {
                let nameobj=document.getElementById('modulename'+tblNm)
                this.input = {
                    tbl: tblNm,
                    section: 'assignment',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                let apiURL='api/functionality/create'
                if(tblNm == 't37'){
                    this.input.sectionid=this.functionalitymodule.sectionmasterid
                    this.input.projectid=this.functionalitymodule.projectid
                    this.input.projectmoduleid=this.functionalitymodule.projectmoduleid
                    this.input.applicationmoduleid=this.functionalitymodule.applicationmoduleid
                    if(this.functionalitymodule.applicationid){
                        this.input.applicationid=this.functionalitymodule.applicationid
                    }else{
                        this.input.applicationid=null
                    }
                    this.input.functionlityname=this.functionalitymodule.functionlityname
                    this.input.functionlitydescription=this.functionalitymodule.functionlitydescription
                    this.input.section='assignment'
                    delete this.input.name;
                } 
                if(this.functionalitymodule.iseditstate == true){
                    apiURL='api/functionality/update'
                    this.input.lastmodifiedby=this.undt.username
                    this.input.id=this.editRowData.functionlitymasterid
                    delete this.input.createdby;
                }               
                this.isAddState=false
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        $('.close').click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.rowdata={
                            label : this.functionalitymodule.label,
                            projectname : this.functionalitymodule.project,
                            projectmodulename : this.functionalitymodule.projectmodule,
                            appprojectdd : this.functionalitymodule.projectid,
                            projectmoduleid : this.functionalitymodule.projectmoduleid,
                            sectionmasterid : this.functionalitymodule.sectionmasterid,
                            applicationid : this.functionalitymodule.applicationid,
                            applicationname : this.functionalitymodule.applicationname,
                            applicationmodulename : this.functionalitymodule.applicationmodulename,
                            sectionname : this.functionalitymodule.sectionname,
                            applicationmoduleid : this.functionalitymodule.applicationmoduleid,
                            projectid : this.functionalitymodule.projectid,
                            projecttypeid : this.functionalitymodule.projecttypeid,
                            businessverticleid : this.functionalitymodule.businessverticleid,
                            companyid : this.functionalitymodule.companyid
                        }
                        this.getfunctionalitylist(this.search);
                        this.isLoading = false
                        this.clearerrorForm();
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
                this.displayError(e)
            })
            }
        },
        selfmsectionmdldata(state,value){
            this.functionalitymodule.sectionmasterid=null
            this.functionalitymodule.sectionmasterid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.sectionmasterid="Section Required"
            }else{                
                this.errors.functionalitymodule.sectionmasterid=null
                this.getfiltereddata('functionalitymodule');
            }
        },
        selfmprojectdata(state,value){
            this.functionalitymodule.projectid=null
            this.functionalitymodule.projectid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.projectid="Project Required"
            }else{                
                this.errors.functionalitymodule.projectid=null
            }
        },
        selfmapplmdldata(state,value){
            this.functionalitymodule.applicationmoduleid=null
            this.functionalitymodule.applicationmoduleid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.applicationmoduleid='Application Module required'
            }else{                
                this.errors.functionalitymodule.applicationmoduleid=null
            }
        },
        selfmappmdldata(state,value){
            this.functionalitymodule.applicationid=null
            this.functionalitymodule.applicationid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.applicationid='Application required'
            }else{                
                this.errors.functionalitymodule.applicationid=null
            }
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'functionalitymodule'){
                this.inputappl = {
                    tbl:mdl,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't2'){
                        this.functionalitymodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'functionalitymodule'){
                            this.functionalitymodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.functionalitymodule.companylist=result.data.data.rows
                        return true;
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
            
        },
        addmodule(){         
            this.functionalitymodule.functionlityname = null
            this.functionalitymodule.functionlitydescription = null
            this.functionalitymodule.iseditstate = false
            this.isSearchRequestActive = true
        },
        ExportXL() {
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit:null,
                offset:null,
                projectid:this.search.searchProjectname,
                projectMasterid:this.search.projectMasterid,
                applicationMasterid:this.search.applicationMasterid,
                projectModuleid:this.search.projectModuleid,
                applicationModuleid:this.search.applicationModuleid,
                sectionMasterid:this.search.sectionMasterid,
                functionalityMasterid:this.search.functionalityMasterid,
                functionalityname:this.search.functionalityname,
                empcode: this.undt.userid,
                useremail: this.undt.username
            };   
            axios({
                method: "POST",
                url: `api/functionality/list`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
        }).catch(e => {
            this.displayError(e)
        });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'functionatilymoduleReport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Functionality Name,Description,Project Name,Module Name,Section Name,Created Date' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null||index.functionlityname.toLowerCase() == 'na'?'':index.functionlityname : '';
          let description = index.hasOwnProperty('description') ? index.description : '';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename : '';
          let sectionname = index.hasOwnProperty('sectionname') ? index.sectionname == null||index.sectionname.toLowerCase() == 'na'?'':index.sectionname : '';
           let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          createddate = this.dateMONFormat(createddate);
            row += '"' + srno + '",',
            row += '"' + functionlityname + '",',
            row += '"' + description + '",',
            row += '"' + projectname + '",',
            row += '"' + projectmodulename + '",',
            row += '"' + sectionname + '",',
            row += '"' + createddate + '",'
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
          return;
        }
        var fileName = 'functionatilymoduleReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
        Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
        });
      
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    }
}
</script>
