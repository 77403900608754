<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1 mt-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left pl-0 ">
                 <h3 class="content-header-title float-left mb-0">Email Configuration</h3>
                 <div class="breadcrumb-wrapper">
                   <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " >Email Configuration List</li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="card border-warning filter_card_searchbgst">
           <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
             <div class="container-fluid" >
               <div class="row" >
                 <div class="w-auto px-1 mobile-padding-0-2">
                   <div class="avatar bg-light-secondary rounded d-inline-flex">
                     <div class="avatar-content">
                       <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                     </div>
                   </div>
                 <h4 class="card-title d-inline-flex ml-1">Email Configuration Details</h4>
                 </div>
                 <div class="ml-auto d-flex">
                 <div class="px-1 text-right mobile-padding-0-2" >
                   <div class="input-group-append float-right">
                     <router-link to="/email_sanity/emailsanitycreate"><span class="btn btn-sm btn-relief-success" > <span class="mobile_hide_bt">Create Email Configuration </span> <plus-circle-icon size="1.5x"></plus-circle-icon> </span></router-link>
                   </div>
                 </div>
                 <span class="rotetions_view_arrow ml-auto mx-1"> 
                 <em class="las la-angle-down"></em>
               </span>
               </div>
               </div>
             </div>
           </div>
           
           <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
             <div class="card-body pt-1 px-sm-mobile-10">
               <div class="row">
                <div class="col-12 col-md-3">
                    <div class="form-group mb-1">
                    <label for="projectmdllistdd">Modules</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select module name" v-model="modules" :options="modulelists"/>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div class="form-group mb-1">
                    <label for="allotedto">Active/Inactive</label>
                    <treeselect class="allotedto capitalisetext" placeholder="Select activation" v-model="searchisactive" :options="isactivelist" :multiple="true" :clear-on-select="true"/>
                </div>
                </div>
                 <div class="col-sm-12 col-md-12 col-lg-4 text-right  text-lg-left">
                  <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getEmailSanityConfigbyEmpid()"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                   <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="card border-warning" id="domain-list">
           <div class="card-body p-0 ps ps--active-x">
             <div class="table-responsive" >
               <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                 <thead>
                   <tr>
                     <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                     <th scope="col" class="text-center second-col-sticky">Modules</th>
                     <th scope="col" class="text-center ">Notification Types</th>
                     <th scope="col" class="text-center">IsActive</th>
                     <th scope="col" class="text-center">Action</th>
                   </tr>
                 </thead>
                 <tbody>
                     <template v-for="(data,index) in emailsanitylist"  >
                     <tr >
                      <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                      <td class="text-center capitalisetext second-col-sticky">{{ getModuleNames(data.modulename) }}</td>
                      <td class="text-center capitalisetext ">{{ getNotificationTypes(data.notificationtypes) }}</td>

                       <!-- <td v-if="data.projectid && data.projectid.length > 0" class="text-nowrap capitalisetext">
                            <ul>
                                <li v-for="(designation, index) in data.projectid.split(',')" :key="index">
                                    {{ designation.trim() }}
                                </li>
                            </ul>
                        </td> -->
                        <!-- <td v-else class="text-center capitalisetext">
                            NA
                        </td> -->

                      <td class="text-center text-capitalize"  v-if="data.isactive === 1">
                      <em class="las la-check-circle custom-class"  style="color: #28c76f;font-size: 24px;"></em>
                        
                      </td>
                      <td class="text-center text-capitalize" v-else>
                        <em class="las la-times-circle custom-class" style="color: #b82323;font-size: 24px;"></em>
                      </td>
                      <td class="centeralign">
                         <span class=" devEditEle edit_open_box_exam" @click="redirecttourl('update_emailsanity', data)">
                           <em class="las la-pen custom-class" style="color: #4F46E5;font-size: 24px;cursor: pointer;"> </em>
                         </span>
                       </td>
                      </tr>
                   </template>
                   <!-- <template v-if="emailsanitylist.length==0">
                     <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                   </template> -->
                 </tbody>
               </table>
             </div>
             <div class="pageCenter text-center mt-2">
               <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
             </div>
           </div>
         </div>
       </section>
     </div>
   </div>
  </template>
  
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import VueElementLoading from 'vue-element-loading';
  import Loading from 'vue-loading-overlay';
  import Pagination from 'vue-pagination-2'
  import Treeselect from '@riophae/vue-treeselect'
  import DatePicker from "vue2-datepicker";
  import commonMethods from '../../utils/commonMethods';
  import moment from "moment";
  
  import 'vue-loading-overlay/dist/vue-loading.css'
  import "vue2-datepicker/index.css";
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon,MinusSquareIcon,PlusSquareIcon } from "vue-feather-icons";
  import index from 'watch-size';
  
  export default {
    name: 'emailsanitylist',
    components: {
     VueElementLoading,
     Loading,
     Treeselect,
     DatePicker,
     PlusCircleIcon,
     EditIcon,
     CheckCircleIcon,
     XCircleIcon,
     Pagination,
     SearchIcon,
     ExternalLinkIcon,
     XIcon,
     MinusSquareIcon,
     PlusSquareIcon, 
   },
   mixins: [ commonMethods ],
   data() {
     return {

       emailsanitylist:[],
       isactive: true,
       isSearchBoxActive: false,
       isUpdateBoxActive: false,
       card_content:'card-content',
       collapse:'collapse',
       isLoading: false,
       undt: null,
       tokendata: null,
       totalcount: 0,
       pageCount: 0,
       page:1,
       pagelimit:apiUrl.LIMIT,
       currPageNum: 0,
       pageoffset:1,
       pagefeatures:[],
        isactivelist: [
          {id: 1, label:'Active'},
          {id: 0, label:'Inactive'},
        ],
        searchisactive:null,
        modulelists : apiUrl.modulelists,
        modules:null,
     }
   },
   mounted() {
    // let permission = this.$Service.verifyAccess();
    // if(permission){
        var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.loginusername=userdata.username
                this.loginuscondition = userdata.userid 
                this.tokendata = window.localStorage.getItem('token');
                this.getEmailSanityConfigbyEmpid()
                this.searchisactive =[1];
            }
            this.getEmailSanityConfigbyEmpid()
            this.searchisactive =[1];
          },
   methods: {
    getSearchItems(search) {
      this.isSearchBoxActive = true;
      this.getEmailSanityConfigbyEmpid(search);
    },
    getNotificationTypes(notificationtypes) {
      if (notificationtypes === 'allproject') {
        return 'For any event on all my projects';
      } else if (notificationtypes === 'selectedprojects') {
        return 'For any event on the selected projects only'; 
      } else if (notificationtypes === 'subscribed') {
        return 'Only for things i watch or im involved in';
      }
      else if (notificationtypes === 'asssigned') {
        return 'Only for things i am assigned to';
      } 
      else if (notificationtypes === 'createdby') {
        return 'Only for things i am the owner of';
      }
      else if (notificationtypes === 'noevents') {
        return 'No Events';
      }else {
        return 'NA';
      }
    },
    getModuleNames(modulename) {
      if (modulename === 'ticket') {
        return 'Ticket Request';
      } else if (modulename === 'issue') {
        return 'Issue Management'; 
      } else if (modulename === 'testcase') {
        return 'Testcase Management';
      } else if (modulename === 'task') {
        return 'Micro Task Master';
      } else if (modulename === 'businesstask') {
        return 'Business Task';
      } else if (modulename === 'Testcycle Set') {
        return 'Testcycle Set';
      } else {
        return 'NA';
      }
    },
    searchBoxCollapse() {
       this.isSearchBoxActive = !this.isSearchBoxActive
     },
     clickCallback: function(pageNum) {
       this.pageoffset=pageNum
       this.currPageNum = pageNum - 1
       this.getEmailSanityConfigbyEmpid();
       setTimeout(function(){
         $('html, body').animate({
           scrollTop: $("#domain-list").offset().top -70
         });
       }, 500);
     },
      getEmailSanityConfigbyEmpid() {
        this.isLoading = true
        let url = 'api/emailsanity/getEmailSanityConfigbyEmp'
        let payload = new Object({
            // limit: apiUrl.LIMIT,
            useremail  : this.undt.username,
            empcode: this.undt.userid,
            // offset: this.pageoffset,
            module : this.modules,
            isactive : this.searchisactive
        })
        // if(search){
        //     payload.module = this.modules
        //     payload.isactive = this.searchisactive
        // }
        axios({
          method: 'POST',
          url,
          data: payload,
          headers:{ authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.emailsanitylist = result.data.data
            // this.totalcount = result.data.data.count
            // this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            this.emailsanitylist=[]
            this.totalcount = 0
           this.pageCount = 0   
          }
        }).catch(e => {
                  this.displayError(e)
          }); 
      },
      activateEditRow:function(data,idx){
        this.isLoading = true
        data.edit = true
        this.isLoading = false
     },
     redirecttourl(btnstate,rowdata) {
      rowdata.backbtnstate=btnstate
      if(btnstate === "update_emailsanity") {
        this.$router.push({
          path: `/email_sanity/emailsanityupdate?id=${rowdata.id}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      }
    },
     cancelUpdate(idx) {
       this.emailsanitylist = this.emailsanitylist.map((data, i) => {
         if(i === idx) {
           return { ...this.emailsanitylist[idx], edit: false }
         }
         return data
       })
       this.getEmailSanityConfigbyEmpid(this.search);
     },
      clearSearchFields() {
        this.modules = null;
        this.searchisactive =[1];
        this.getEmailSanityConfigbyEmpid();
     },
   }
  }
  
  </script>