<template>
<div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
         <div class="card p-1">
           <div class="card-body statistics-body mb-0 radius-1 jobDetails_padding-5 ">
                <div class="row mx-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                        <div class="p-1 border rounded mobile_card_job_details_5">
                        <div class="media">
                            <div class="avatar bg-light-primary mr-1">
                                <div class="avatar-content">
                                <em data-feather='briefcase' class="avatar-icon"></em>
                                </div>
                            </div>
                            <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0"> {{ countdata.totalposition?countdata.totalposition:0 }}</h4>
                                <p class="card-text font-small-3 mb-0"> Total Positions</p>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                        <div class="p-1 border rounded mobile_card_job_details_5">
                        <div class="media">
                            <div class="avatar bg-light-info mr-1">
                                <div class="avatar-content">
                                   <em data-feather='airplay' class="avatar-icon"></em>
                                </div>
                            </div>
                            <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0"> {{ countdata.totalevalution?countdata.totalevalution:0 }}</h4>
                                <p class="card-text font-small-3 mb-0"> Active/Evalution</p>
                            </div>
                        </div>
                     </div>
                    </div>
                    
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2 mb-1 card_job_details_card_10">
                        <div class="p-1 border rounded mobile_card_job_details_5">
                        <div class="media">
                            <div class="avatar bg-light-success mr-1">
                                <div class="avatar-content">
                                    <em data-feather='pause-circle' class="avatar-icon"></em>
                                </div>
                            </div>
                            <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0">{{ countdata.totaldraftposition?countdata.totaldraftposition:0 }}</h4>
                                <p class="card-text font-small-3 mb-0"> Draft Positions</p>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1 card_job_details_card_10">
                        <div class="p-1 border rounded mobile_card_job_details_5">
                        <div class="media">
                            <div class="avatar bg-light-danger mr-1">
                                <div class="avatar-content">
                                    <em data-feather='file-text' class="avatar-icon"></em>

                                </div>
                            </div>
                            <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0">{{ countdata.totalofferposition?countdata.totalofferposition:0 }}</h4>
                                <p class="card-text font-small-3 mb-0"> Active offer Positons</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1 card_job_details_card_10">
                       <div class="p-1 border rounded mobile_card_job_details_5">
                        <div class="media">
                            <div class="avatar bg-light-primary mr-1">
                                <div class="avatar-content">
                                    <em data-feather='file-text' class="avatar-icon"></em>
                                </div>
                            </div>
                            <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0"> {{ countdata.totalclosedposition?countdata.totalclosedposition:0 }}</h4>
                                <p class="card-text font-small-3 mb-0"> Closed Positions</p>
                            </div>
                        </div>
                     </div>
                    </div>
                    
                </div>
            </div>
             <div class="container px-0">
                <div class="nav d-flex justify-content-between align-items-center px-1 hrms_raise_border">
                    <div class="container_hrms_raise">
                        <div class="card-header">
                            <div class="d-flex align-items-center" >
                                <em data-feather='file-text' class="side-bar_feather mr-1"></em>
                                <h2 class="card-title"> {{ 'Requisition List'}} </h2>
                            </div>

                        </div>
                    </div>
                    <div class="addManagePositis align-items-center ml-auto py-1"> 
                        <div class="buttons_hrms_view demo-inline-spacing">
                            <!-- <button type="button" class="btn btn-flat-dark waves-effect hrms-bt-mr-5 mt-0">Bulk Action </button> -->
                            <button class="btn btn-outline-primary" type="button" id="dropdownMenuButton3" @click="redirecttourl('raisrequisition')"> <em class="las la-plus raiserequistion_size"></em> Raise Requisition</a>
                            </button>
                            <button type="move" class="btn btn-outline-primary mt-2" @click="redirecttourl('reqcandidatelisting')">
                                <em class="las la-list"></em> <span>Candidate Listing</span>
                            </button>
                            <button type="move" class="btn btn-outline-primary mt-2"  @click="Exportdata()">
                                <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                            </button>
                        </div>
                    </div>
                </div>
               <div class="tab-content mt-2" id="myTabContent">
                  <div class="table-responsive requisiton_th-text px-1">
                    <table class="table table-striped" aria-describedby="mydesc">
                        <thead>
                            <tr>
                                <th class="text-center">Sr.No</th>
                                <th scope="col" class="text-center align-middle"><em data-feather='settings'></em></th>
                                <!-- <th scope="col">
                                   <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                     <input type="checkbox" class="custom-control-input mr-1 mr-0" id="business-checkbox-1" />
                                     <label class="custom-control-label mr-0" for="business-checkbox-1"></label>
                                    
                                  </div>
                                </th> -->
                                <th scope="col" style="min-width: 180px;" class="text-center">Requisition Code</th>
                                <th scope="col" style="min-width: 310px;" class="text-center"> Designations, Department & Location</th>
                                <th scope="col" class="text-center">Status</th>
                                <th scope="col" class="text-center">Total Positions</th>
                                <!-- <th scope="col" class="text-center"> Active Evaluations </th> -->
                                <!-- <th scope="col" class="text-center"> Active Offer </th> -->
                                <!-- <th scope="col" class="text-center"> Draft </th> -->
                                <!-- <th scope="col" class="text-center"> Closed Positions </th> -->
                                <th scope="col" style="min-width: 180px;" class="text-center"> Last Updated On </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data,index) in requisitionlist" v-bind:key="index">
                                <td class="text-center">{{ index+1+currPageNum*pagelimit }}</td>
                                <td class="clsaction text-center" >
                                    <div class="dropdown">
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                            <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                        </button>
                                        <div class="dropdown-menu" v-if="data.status && data.status !='reject'">
                                            <!-- <a class="dropdown-item devEditEle" @click="savestatus('activate', data.reqid)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <span>Activate</span>
                                                </div>
                                            </a> -->
                                            <a class="dropdown-item devEditEle" v-if="data.status =='activate'" @click="selectactivate('dactivate', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <i class="custom-class mr-50 las las la-ban"></i>
                                                    <span>Dactivate</span>
                                                </div>
                                            </a>

                                          <!-- hodapproval -->
                                          <a class="dropdown-item devEditEle" v-if="undt.userid == data.hod_empcode && data.status =='pending'" @click="requisitionpopup('hodapproval', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <i class="custom-class mr-50 las la-check"></i>
                                                    <span>Approve/Reverse/Reject</span>
                                                </div>
                                            </a>
                                            <!-- hrapproval -->
                                            <a class="dropdown-item devEditEle" v-if="data.hr_empcode && undt.userid == data.hr_empcode && data.status =='pending_with_hr'" @click="requisitionpopup('hrapproval', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <i class="custom-class mr-50 las la-check"></i>
                                                    <span>Approve/Reverse/Reject</span>
                                                </div>
                                            </a>

                                            <!-- recruiterapproval -->
                                            <a class="dropdown-item devEditEle" v-if="data.recruiterhead_empcode && undt.userid == data.recruiterhead_empcode && data.status =='pending_with_recruiterhead'" @click="requisitionpopup('recruiterapproval', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <i class="custom-class mr-50 las la-check"></i>
                                                    <span>Approve/Reverse/Reject</span>
                                                </div>
                                            </a>

                                            <a class="dropdown-item devEditEle" v-if="data.hod_approvaldate && data.hr_approvaldate && data.recruiterhead_approvaldate && data.status !='activate'"  @click="selectactivate('activate', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <i class="custom-class mr-50 las la-check"></i>
                                                    <span>Activate</span>
                                                </div>
                                            </a> 

                                            <!-- <a class="dropdown-item devEditEle" v-else @click="selectactivate('activate', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <i class="custom-class mr-50 las la-check"></i>
                                                    <span>Activate</span>
                                                </div>
                                            </a> -->
                                            <a class="dropdown-item devEditEle" @click="savestatus('archive', data.reqid)" style="cursor: pointer;" href="javascript:void(0);">
                                                <div class="d-flex align-items-center">
                                                    <em class="custom-class mr-50 las la-archive"></em>
                                                    <span>Archive</span>
                                                </div>
                                            </a>
                                            <!-- <a @click="savestatus('duplicate',data.reqid)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                            <div class="d-flex align-items-center"> 
                                                <span>Duplicate</span>
                                            </div>
                                            </a> -->
                                            <a class="dropdown-item devEditEle" data-toggle="modal" data-target="#add_candidates_from" v-if="data.status =='activate'" @click="setreqid(data.reqid)" > 
                                                <div class="d-flex align-items-center"> 
                                                    <plus-circle-icon size="1.5x" class="custom-class mr-50"></plus-circle-icon>
                                                    <span> Add Candidates </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <!-- <td>

                                </td> -->
                                <td class="text-center sitebluecolor" style="cursor: pointer" @click="redirecttourl('reqcandidatelisting',data)">{{ data.reqcode }}</td>
                                <td class="text-center">
                                    <div class="requistion_text-overflow">
                                        <span class="card-text">Designations: <span class="capitalisetext">{{ data.designationname ? data.designationname :'NA' }}</span> </span><br/>
                                        <span class="card-text ml-0">Department: <span class="capitalisetext">{{ data.departmentname ? data.departmentname :'NA' }}</span> </span><br/>
                                        <!-- <span class="card-text ml-0"> Location: <span class="capitalisetext">{{ data.location ? data.location :'NA' }}</span> </span> -->
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span class="badge badge-pill badge-light-warning mr-1 capitalisetext" v-if="data.status=='pending'">{{ data.status }}</span>
                                    <span class="badge badge-pill badge-light-success mr-1 capitalisetext" v-if="data.status=='activate'">{{ data.status }}</span>
                                    <span class="badge badge-pill badge-light-danger mr-1 capitalisetext" v-if="data.status=='dactivate'">{{ data.status }}</span>
                                    <span class="badge badge-pill badge-light-primary mr-1 capitalisetext" v-if="data.status=='archive'">{{ data.status }}</span>
                                    <span class="badge badge-pill badge-light-warning mr-1 capitalisetext" v-if="data.status=='duplicate'">{{ data.status }}</span>

                                    <span class="badge badge-pill badge-light-primary mr-1 capitalisetext" v-if="data.status=='pending_with_hr'">{{ data.status }}</span>
                                    
                                    <span class="badge badge-pill badge-light-primary mr-1 capitalisetext" v-if="data.status=='pending_with_recruiterhead'">{{ data.status }}</span>

                                    <span class="badge badge-pill badge-light-primary mr-1 capitalisetext" v-if="data.status=='approved'">{{ data.status }}</span>

                                    <span class="badge badge-pill badge-light-danger mr-1 capitalisetext" v-if="data.status=='reject'">{{ data.status }}</span>

                                </td>
                                <td class="text-center">
                                    {{ data.columnnoofpositions ? data.columnnoofpositions : 0 }}
                                </td>
                                <!-- <td class="text-center">
                                    {{ data.evaluationsposition ? data.evaluationsposition:'-' }}
                                </td>
                                <td class="text-center">
                                    {{ data.activeofferposition?data.activeofferposition:'-' }}
                                </td>
                                <td class="text-center">
                                    {{ data.draftposition?data.draftposition:'-' }}
                                </td>
                                <td class="text-center">
                                    {{ data.closedposition?data.closedposition:'-' }}
                                </td> -->
                                <td class="text-center">
                                    {{ dateMonthFormat(data.columnlastmodifieddate) }}
                                </td>
                            </tr>
                            <template v-if="requisitionlist.length==0">
                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="pageCenter text-center">
                    <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                </div>
              <div class="scrolling-inside-modal">
                  <div class="modal fade" id="requistion_from" tabindex="-1" role="dialog" aria-labelledby="requisition_from_scroll" aria-hidden="true">
                              <div class="modal-dialog modal-dialog-scrollable modal-md" role="document">
                                  <div class="modal-content">
                                      <div class="modal-header">
                                          <h6 class="modal-title" id="requisition_from_scroll"> Are you sure you want to active this?</h6>
                                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                              <span aria-hidden="true">&times;</span>
                                          </button>
                                      </div>
                                      <div class="modal-body">
                                          <div class="row raise_requistionbg_upload">
                                             <div class="form-group col-md-12">
                                               <div class="form-group">
                                                   <label for="basicSelect">Select Job </label>
                                                   <select class="form-control" id="basicSelect">
                                                       <option>Executive - Human Resources</option>
                                                       <option>Firstcry</option>
                                                       <option>Firstcry.com</option>
                                                   </select>
                                                </div>
                                             </div>
                                                <div class="col-sm-12 ">
                                                   <p class="px-0">Could not find a Job? <a href="#"> Create here</a></p>
                                                </div>
                                                <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                        <label class="custom-control-label" for="customCheck1">Make 
                                                        all postions under this requistion as open</label>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="form-group col-md-12">
                                               <div class="form-group">
                                                   <label class="d-flex" for="basicSelect"> Add Highring lead <span class="star_icon_t">* </span> </label>
                                                   <select class="form-control" id="basicSelect">
                                                       <option>Select </option>
                                                       <option>Firstcry</option>
                                                       <option>Firstcry.com</option>
                                                   </select>
                                                </div>
                                             </div>
                                             <div class="form-group col-md-12">
                                               <div class="form-group">
                                                   <label class="d-flex" for="basicSelect"> Assign Recruter <span class="star_icon_t">* </span> </label>
                                                   <select class="form-control" id="basicSelect">
                                                       <option>Select </option>
                                                       <option>Firstcry</option>
                                                       <option>Firstcry.com</option>
                                                   </select>
                                                </div>
                                             </div>

                                             <div class="col-md-12 mb-1">
                                               <label class="d-flex">Add Shortlisting Team <span class="star_icon_t">* </span></label>
                                               <select class="select2 form-control" multiple>
                                                   <optgroup label="Alaskan/Hawaiian Time Zone">
                                                       <option value="AK">Alaska</option>
                                                       <option value="HI">Hawaii</option>
                                                       <option value="CA">California</option>
                                                       <option value="NV">Nevada</option>
                                                   </optgroup>
                                               </select>
                                           </div>
                                           <div class="col-md-12 mb-1">
                                               <label class="d-flex">Add Screening team <span class="star_icon_t">* </span></label>
                                               <select class="select2 form-control" multiple>
                                                   <optgroup label="Alaskan/Hawaiian Time Zone">
                                                       <option value="AK">Alaska</option>
                                                       <option value="HI">Hawaii</option>
                                                       <option value="CA">California</option>
                                                       <option value="NV">Nevada</option>
                                                   </optgroup>
                                               </select>
                                           </div>
                                       
                                             <div class="form-group col-md-12">
                                               <label class="form-label" for="">Add Scheduling Team</label>
                                               <input type="text" id="" class="form-control" placeholder="Select scheduling team"/>
                                             </div>
                                             <div class="form-group col-md-12">
                                               <label class="form-label" for="">Add Hiring Group</label>
                                               <input type="text" id="" class="form-control" placeholder="Select hiring group"/>
                                             </div>
                                           
                                        </div>
                                      </div>
                                      <div class="modal-footer">
                                          <button type="button" class="btn btn-relief-secondary" data-dismiss="modal">Cancel</button>
                                          <button type="button" class="btn btn-relief-success" data-dismiss="modal">Save</button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

            <div class="scrolling-inside-modal">
                <div class="modal fade" id="add_candidates_from" tabindex="-1" role="dialog" aria-labelledby="add_candidates_from_scroll" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="add_candidates_from_scroll"> Add Candidates</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body px-0">
                                <form action="#"
                                class="dropzones dropzone-areas"
                                id="dpz-single-filess"
                                >
                                    <div class="modal-body">
                                        <div
                                        class="row raise_requistionbg_upload card_views_hrms"
                                        id="raise_requistionbg_upload">
                                            <div class="form-group col-md-4 mb-0">
                                                <div class="form-group">
                                                    <label for="basicSelect">Source
                                                    </label>
                                                    <treeselect
                                                    class="projectmdllistdd capitalisetext" v-model="jobportalsource" :options="jobportalsourcelist" @input="selsource" placeholder="Select Source"/>
                                                    <div class="errorinputmsg" v-if="errors.jobportalsource">{{ errors.jobportalsource }}</div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-4 mb-0">
                                                <div class="form-group">
                                                    <label for="basicSelect">
                                                    Select Job Portal
                                                    </label>
                                                    <treeselect
                                                    class="projectmdllistdd capitalisetext"
                                                    v-model="jobportalname"
                                                    :options="jobportalnamelist"
                                                    @input="selportal"
                                                    placeholder="Select Job Portal"
                                                    />
                                                    <div class="errorinputmsg" v-if="errors.jobportalname">{{ errors.jobportalname }}</div>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-4 mb-0">
                                                <div class="form-group">
                                                    <label class="form-label" for=""
                                                    >Application Date</label
                                                    >
                                                    <input class="mx-input"
                                                    type="text"
                                                    id="dateInput"
                                                    disabled
                                                    v-model="currentDate"
                                                    @input="selcurrdate"
                                                    placeholder="Enter Application Date"
                                                    />
                                                    <div class="errorinputmsg" v-if="errors.currentDate">{{ errors.currentDate }}</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-4 mb-0">
                                                <div class="form-group">
                                                    <label for="basicSelect">Candidate First Name
                                                    </label>
                                                    <input class="form-control" type="text" v-model="candidatefname" @input="selcandidatefname" placeholder="Enter Candidate First Name"/>
                                                    <div class="errorinputmsg" v-if="errors.candidatefname">{{ errors.candidatefname }}</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-4 mb-0">
                                                <div class="form-group">
                                                    <label for="basicSelect">Candidate Last Name
                                                    </label>
                                                    <input class="form-control" type="text" v-model="candidatelname" @input="selcandidatelname" placeholder="Enter Candidate Last Name"/>
                                                    <div class="errorinputmsg" v-if="errors.candidatelname">{{ errors.candidatelname }}</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-4 mb-0">
                                                <div class="form-group">
                                                    <label for="basicSelect">Candidate Contact No
                                                    </label>
                                                    <input class="form-control" type="text" @keypress="validNumber" v-model="candidatecontact" @input="selcontactno" placeholder="Enter Candidate Contact No" maxlength="10"/>
                                                    <div class="errorinputmsg" v-if="errors.candidatecontact">{{ errors.candidatecontact }}</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-md-4 mb-0">
                                                <div class="form-group">
                                                    <label for="basicSelect">Candidate Email
                                                    </label>
                                                    <input class="form-control" type="text" v-model="candidateemail" @input="selcandidateemail" placeholder="Enter Candidate Email"/>
                                                    <div class="errorinputmsg" v-if="errors.candidateemail">{{ errors.candidateemail }}</div>
                                                </div>
                                            </div>

                                            <div
                                            class="form-group col-md-8 mb-0"
                                            >
                                                <div class="form-group">
                                                <label class="w-100 d-block "> &nbsp;</label>
                                                    <div id="dropzone-examples">
                                                        <label
                                                        for="attachment"
                                                        class="btn btn-sm btn-primary mb-75 mr-75"
                                                        >Select Pdf</label>
                                                        <button
                                                        class="btn btn-sm btn-primary mb-75 mr-75"
                                                        v-on:click.stop.prevent="onUpload()"
                                                        >
                                                            Upload
                                                        </button>
                                                        {{ newfile ? newfile :'' }}
                                                        <input
                                                        type="file"
                                                        id="attachment"
                                                        hidden
                                                        accept="application/pdf"
                                                        ref="file"
                                                        @change="onSelect"
                                                        />
                                                        <!-- <div class="invalid-feedback">
                                                            project desc must be greater than 5
                                                        char
                                                        </div> -->
                                                        <div class="errorinputmsg" v-if="errors.attachmentlink">{{ errors.attachmentlink }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer px-0">
                                            <button type="button" class="btn btn-relief-secondary" data-dismiss="modal" @click="cancelForm()">Cancel</button>
                                            <button type="button" class="btn btn-relief-success" data-dismiss="modal" id="submitbtnt90" v-bind:disabled="resumeupload.disblesavebtn" v-on:click.stop.prevent="saveuploadresume($event)" > Submit </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="tablepoup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title">Edit Requisition</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="table-small">
                                                    <div class="form-group col-md-4 mb-1">
                                                        <div class="form-group">
                                                            <label for="basicSelect">
                                                                Requisition Code
                                                            </label>
                                                            <input type="text" class="form-control" placeholder="Enter Requisition Code"
                                                            v-model="this.formreqcode" disabled/>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-4 mb-1">
                                                        <div class="form-group">
                                                            <label for="basicSelect">
                                                                Job Details Code
                                                            </label>
                                                            <treeselect
                                                            class="projectmdllistdd capitalisetext"
                                                            Placeholder="Select JD Code"
                                                            v-model="jdid"
                                                            :options="jdcodelist"
                                                            @input="selectjdcode"
                                                            disabled v-if="jdid"
                                                            />
                                                            <treeselect
                                                            class="projectmdllistdd capitalisetext"
                                                            Placeholder="Select JD Code"
                                                            v-model="jdid"
                                                            :options="jdcodelist"
                                                            @input="selectjdcode" v-else
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-4 mb-1">
                                                        <div class="form-group">
                                                            <label>
                                                                Name Of Recruiter
                                                            </label>
                                                            <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search Assigned to"  :options="allottedtolist" v-model="assignedto" v-on:search-change="getEmpListByProjectAssigned"/>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-4 mb-1">
                                                        <label class="form-label" for="vertical-modern-first-name">Recruitment Start Date</label>
                                                        <date-picker placeholder="Select Recruitment Start Date" type="datetime" format="YYYY-MM-DD HH:mm" v-model="recruitmentstartdate" valueType="format"
                                                        class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeTodaydate"></date-picker>
                                                    </div>
                                                    <div class="form-group col-md-4 mb-1">
                                                        <lable class="w-100 d-block"> &nbsp; </lable>
                                                        <button type="button" class="btn btn-relief-primary" @click="selectsactivate(formactionname, formreqid, jdid)" v-if="formactionname && formactionname =='dactivate'"> Submit </button>
                                                        <button type="button" class="btn btn-relief-primary" @click="savestatus(formactionname, formreqid, jdid)" v-else> Submit </button>
                                                    </div>
                                                    <div class="col-sm-12 ">
                                                        <div class="container description_bg_detail_job description_bg_detail_jobpopups" v-if="jddetails">
                                                            <h5 class="text-left py-1">Job Details</h5>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="text-card">
                                                                        <strong>Company Name: </strong>
                                                                        <span class="capitalisetext">{{jddetails.companyname ? jddetails.companyname :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="text-card">
                                                                        <strong>Department Name: </strong>
                                                                        <span class="capitalisetext">{{jddetails.departmentname ? jddetails.departmentname :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Designation Name: </strong>
                                                                        <span class="capitalisetext">{{jddetails.designationname ? jddetails.designationname :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Employee Type: </strong>
                                                                        <span class="capitalisetext">{{jddetails.employeetypename ? jddetails.employeetypename :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Experience Range: </strong>
                                                                        <span>{{jddetails.expyear ? jddetails.expyear :'0'}} to {{ jddetails.expmonth ? jddetails.expmonth :'0' }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-12 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Job Description: </strong>
                                                                        <span class="capitalisetext" v-html="jddetails.description"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="container description_bg_detail_job text-center" v-else>
                                                            Job Details Not Found
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>

            <div class="modal fade" id="tablepoupapproval" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title">Requisition Approval</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="table-small">
                                                    <div class="form-group col-md-4 mb-0">
                                                        <div class="form-group">
                                                            <label for="basicSelect">
                                                                Requisition Code
                                                            </label>
                                                            <input type="text" class="form-control" placeholder="Enter Requisition Code"
                                                            v-model="this.formreqcode" disabled/>
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="form-group col-md-8 mb-0">
                                                        <lable class="w-100 d-block"> &nbsp; </lable>
                                                        <button type="button" class="btn btn-relief-success" @click="requisitionstatusupdate(approvalstatus,formreqid)"> Approve </button>
                                                        <button type="button" class="btn btn-relief-primary mx-1" @click="requisitionstatusupdate(approvalstatus,formreqid,'reverse')"> Reverse </button>
                                                        <button type="button" class="btn btn-relief-danger" @click="requisitionstatusupdate(approvalstatus,formreqid,'reject')"> Reject </button>
                                                    </div>
                                                    <div class="col-sm-12 ">
                                                        <div class="container description_bg_detail_job" v-if="jddetails">
                                                            <h5 class="text-center py-1">Job Details</h5>
                                                            <div class="row">
                                                                <div class="col-sm-6">
                                                                    <div class="text-card">
                                                                        <strong>Company Name: </strong>
                                                                        <span class="capitalisetext">{{jddetails.companyname ? jddetails.companyname :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <div class="text-card">
                                                                        <strong>Department Name: </strong>
                                                                        <span class="capitalisetext">{{jddetails.departmentname ? jddetails.departmentname :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Designation Name: </strong>
                                                                        <span class="capitalisetext">{{jddetails.designationname ? jddetails.designationname :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Employee Type: </strong>
                                                                        <span class="capitalisetext">{{jddetails.employeetypename ? jddetails.employeetypename :'NA'}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Experience Range: </strong>
                                                                        <span>{{jddetails.expyear ? jddetails.expyear :'0'}} to {{ jddetails.expmonth ? jddetails.expmonth :'0' }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-sm-12 mt-1">
                                                                    <div class="text-card">
                                                                        <strong>Job Description: </strong>
                                                                        <span class="capitalisetext" v-html="jddetails.description"></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="container description_bg_detail_job text-center" v-else>
                                                            Job Details Not Found
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>

            </div>
            </div>
         </div>
         </div>
      </div>
      
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from 'axios'
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon,PlusCircleIcon, ExternalLinkIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMethods from '../../utils/commonMethods';
import VueTimepicker from 'vue2-timepicker'
import apiUrl from '../../constants'
   
export default {
    name: "requisition",
    components: {
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,DatePicker,VueTimepicker,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,PlusCircleIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component, ExternalLinkIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            defaultValue: new Date(),
            isLoading: false,
            fullPage: true,
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            addcandidatereqid : '',
            fullPage: true,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            jobportalname: null,
            candidatefname: null,
            candidatelname: null,
            candidatecontact: null,
            candidateemail: null,
            applicationdate: null,
            applicationdateval:null,
            jobportalsource: null,
            recruitmentstartdate: null,
            requisitionlist:[],
            isSearchRequestActive:false,
            pagefeatures:[],
            resumeupload: {
                disblesavebtn:false,
                filedescp:[],
                attachmentlink: "",
            },
            jobportalnamelist: [
              {
                id: "",
                label: "",
              },
            ],
            jobportalsourcelist: [
              {
                id: "",
                label: "",
              },
            ],
            currentDate: '',
            countdata: [],
            allottedtolist:[],
            formreqid: null,
            formreqcode: null,
            formactionname: null,
            jdcodelist: [],
            jdid: null,
            assignedto:null,
            jddetails: null,
            approvalstatus:null,
            file: [],
            newfile: null,
            errors:{
                jobportalsource: null,
                jobportalname: null,
                currentDate: null,
                candidatefname: null,
                candidatelname: null,
                candidatecontact: null,
                candidateemail: null,
                attachmentlink: null
            }
        }
    },
    mounted() {
        $(function () {
            'use strict';

            var bsStepper = document.querySelectorAll('.bs-stepper'),
                select = $('.select2'),
                modernWizard = document.querySelector('.modern-wizard-example')

            // select2
            select.each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                placeholder: 'Select value',
                dropdownParent: $this.parent()
                });
            });

            if (typeof modernWizard !== undefined && modernWizard !== null) {
                var modernStepper = new Stepper(modernWizard, {
                linear: false
                });
                $(modernWizard)
                .find('.btn-next')
                .on('click', function () {
                    modernStepper.next();
                });
                $(modernWizard)
                .find('.btn-prev')
                .on('click', function () {
                    modernStepper.previous();
                });

                $(modernWizard)
                .find('.btn-submit')
                .on('click', function () {
                    alert('Submitted..!!');
                });
            }
            
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            // this.getpositioncounts();
            this.getJobportal();
            this.getJobsource();
            this.setCurrentDate();
            if(this.$route && this.$route.params.clickeddata && this.$route.params.clickeddata.backbtnstate && this.$route.params.clickeddata.backbtnstate =='jdrequisition'){
                let tempjdid = parseInt(this.$route.params.clickeddata.jdid);
                this.getraiserequisitionlist(tempjdid);
            }else{
                this.getraiserequisitionlist();
            }
        }
    },
    methods:{
        getraiserequisitionlist(jdid){
            this.isLoading = true
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                limit: apiUrl.LIMIT,
                offset:this.pageoffset,
            }
            if(jdid){
                this.input.jdid =jdid;
            }
            axios({
                'method': 'POST',
                'url': 'api/hrms/getraiserequisitionlist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.requisitionlist = result.data.data.rows
                    this.totalcount = result.data.data.count;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    this.getpositioncounts(jdid);
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.requisitionlist= []
                    this.totalcount = 0;
                    this.pageCount = 0;
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getraiserequisitionlist();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#myTabContent").offset().top -70
                });
            }, 500);
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        getEmpListByProjectAssigned(node) {
      if(node && node.length>3){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.allottedtolist = result.data.data;
            let decryptdepartmenttext = this.allottedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.allottedtolist = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
        getpositioncounts(jdid) {
            this.isLoading = true;
            this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
            }
            if(jdid){
                this.input.jdid =jdid;
            }
            axios({
                'method': 'POST',
                'url': 'api/hrms/getpositioncounts',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                    this.countdata = result.data.data.countdata[0]
                }else{
                    this.countdata = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        setreqid(reqid){
            this.addcandidatereqid = reqid;
        },
        notBeforeTodaydate: function (date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        savestatus(status,reqid,jdid){
            if (status && status == 'activate' && (jdid ==null || jdid =='' || jdid ==' ')) {
                Swal.fire({
                    title: "Failed",
                    text: 'Job Details Code Are Required',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }else if(status && status == 'activate' && (this.recruitmentstartdate ==null || this.recruitmentstartdate =='' || this.recruitmentstartdate ==' ')){
                Swal.fire({
                    title: "Failed",
                    text: 'Recruitment Startdate Are Required',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }else if(status && status == 'activate' && (this.assignedto ==null || this.assignedto =='' || this.assignedto ==' ')){
                Swal.fire({
                    title: "Failed",
                    text: 'Recruiter Name Are Required',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            } else{
                this.isLoading = true;
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    reqid: parseInt(reqid),
                    status: status,
                }
                this.input.recruitmentstartdate = this.recruitmentstartdate
                this.input.recruitername = this.assignedto
                if(jdid && (jdid !=null || jdid !='')){
                    this.input.jdid = jdid;
                }
                let apiURL='api/hrms/savestatus'
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.formreqcode =null;
                        this.formreqid =null;
                        this.jdid =null;
                        this.jdcodelist =[];
                        $('#tablepoup').modal('hide');
                        this.getraiserequisitionlist();
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        Exportdata(){
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                useremail:this.undt.username,
                empcode: this.undt.userid,
                limit: null,
                offset: null,
            };
            let search = JSON.parse(localStorage.getItem('searchItems'))
            // if (search) {
            // }
            axios({
                method: "POST",
                url: `api/hrms/getraiserequisitionlist`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "Raise_Requisition";
                    this.download(this.exportxlsdata,filename)
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
                 this.displayError(e)
                })
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                var ShowLabel = filename
                var JSONData = readExportdata
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
                if (ShowLabel) {
                let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#,Raise Requisition Code,Business Unit,Department,Designations,Location,Status,Experience From,Experience To,Salary Currency,Min Salary,Max Salary,Timeframe,Rec Startdate,Roles & Responsibilites,Additional Skills,No Of NewPositions,No Of ReplacementPostion,No of Postion,Comments/Instructions,Asset Requirements,   Created By, Created Date' + '\r\n'
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let reqcode = index.hasOwnProperty('reqcode') ? index.reqcode == null || index.reqcode.toLowerCase() == 'na' ? 'NA' : index.reqcode : 'NA';
                let businessunit = index.hasOwnProperty('businessunit') ? index.businessunit ==null || index.businessunit.toLowerCase() =='na' ? 'NA' : index.businessunit : 'NA';
                // let departmentname = index.hasOwnProperty('departmentname') ? index.departmentname ==null || index.departmentname.toLowerCase() =='na' ? '' : index.departmentname : '';
                let departmentname = index.departmentname ? index.departmentname : 'NA'

                // let designationname = index.hasOwnProperty('designationname') ? index.designationname ==null || index.designationname.toLowerCase() =='na' ? '' : index.designationname : '';
                let designationname = index.designationname ? index.designationname : 'NA'

                let location = index.hasOwnProperty('location') ? index.location ==null || index.location.toLowerCase() =='na' ? 'NA' : index.location : 'NA';
                let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? 'NA' : index.status : 'NA';
                let expfrom = index.hasOwnProperty('expfrom') ? index.expfrom == null || index.expfrom.toLowerCase() =='na' ? '0' : index.expfrom : '0';
                let expto = index.hasOwnProperty('expto') ? index.expto == null || index.expto.toLowerCase() =='na' ? '0' : index.expto : '0';
                let salaycurrenncy = index.hasOwnProperty('salaycurrenncy') ? index.salaycurrenncy == null || index.salaycurrenncy == '' ? 'NA' : index.salaycurrenncy : 'NA';
                let minsalary = index.hasOwnProperty('minsalary') ? index.minsalary == null || index.minsalary.toLowerCase() =='na' ? '0' : index.minsalary : '0';
                let maxsalary = index.hasOwnProperty('maxsalary') ? index.maxsalary == null || index.maxsalary.toLowerCase() =='na' ? '0' : index.maxsalary : '0';
                let timeframe = index.hasOwnProperty('timeframe') ? index.timeframe == null ? 'NA' : index.timeframe : 'NA';
                let recstartdate = index.hasOwnProperty('recstartdate') ? index.recstartdate == null ||index.recstartdate == '' ? 'NA' : this.dateMonthFormat(index.recstartdate) : 'NA';
                let addroles = index.hasOwnProperty('addroles') ? index.addroles==null || index.addroles=='' ? 'NA' :index.addroles : 'NA';
                let columnaddskills = index.hasOwnProperty('columnaddskills') ? index.columnaddskills==null || index.columnaddskills == '' ? 'NA' :index.columnaddskills : 'NA';
                let columnnoofnewpositions = index.hasOwnProperty('columnnoofnewpositions') ? index.columnnoofnewpositions == null || index.columnnoofnewpositions == '' ? '0' : index.columnnoofnewpositions : '0';
                let columnnoofreplacement = index.hasOwnProperty('columnnoofreplacement') ? index.columnnoofreplacement == null || index.columnnoofreplacement == '' ? '0' : index.columnnoofreplacement : '0';
                let columnnoofpositions = index.hasOwnProperty('columnnoofpositions') ? index.columnnoofpositions == null || index.columnnoofpositions == '' ? '0' : index.columnnoofpositions : '0';
                let columninstructions = index.hasOwnProperty('columninstructions') ? index.columninstructions==null || index.columninstructions=='' ? 'NA' :index.columninstructions : 'NA';
                let columnassetrequirement = index.hasOwnProperty('columnassetrequirement') ? index.columnassetrequirement==null || index.columnassetrequirement=='' ? 'NA' :index.columnassetrequirement : 'NA';

                let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
                let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
                createddate = this.dateMonthFormat(createddate);
            

                    row += '"' + srno + '",',
                    row += '"' + reqcode + '",',
                    row += '"' + businessunit + '",',
                    row += '"' + departmentname + '",',
                    row += '"' + designationname + '",',
                    row += '"' + location + '",',
                    row += '"' + status + '",',
                    row += '"' + expfrom + '",',
                    row += '"' + expto + '",',
                    row += '"' + salaycurrenncy + '",',
                    row += '"' + minsalary + '",',
                    row += '"' + maxsalary + '",',
                    row += '"' + timeframe + '",',
                    row += '"' + recstartdate + '",',
                    row += '"' + addroles + '",',
                    row += '"' + columnaddskills + '",',
                    row += '"' + columnnoofnewpositions + '",',
                    row += '"' + columnnoofreplacement + '",',
                    row += '"' + columnnoofpositions + '",',
                    row += '"' + columninstructions + '",',
                    row += '"' + columnassetrequirement + '",',

                    row += '"' + createdby + '",',
                    row += '"' + createddate + '",',
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                return;
                }
                var fileName = filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                    link.href = uri
                    link.style = 'visibility:hidden'
                    link.download = fileName + '.csv'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    Swal.fire({
                        title: "Success!",
                        text: "Export Report Successful",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }
        }, 
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        },
        setCurrentDate() {
          const now = new Date();
          const year = now.getFullYear();
          let month = now.getMonth() + 1;
          if (month < 10) {
            month = `0${month}`;
          }
          let day = now.getDate();
          if (day < 10) {
            day = `0${day}`;
          }
          this.currentDate = `${day}-${month}-${year}`;
        },
        cancelForm() {
            // this.$router.push('/hrms/raiserequisition')
            this.jobportalsource =null;
            this.jobportalname =null;
            this.currentDate =null;
            this.candidatefname =null;
            this.candidatelname =null;
            this.candidatecontact =null;
            this.candidateemail =null;
            this.newfile =null;
            this.file =[];
            this.errors.jobportalsource= null;
            this.errors.jobportalname= null;
            this.errors.jobportalsource= null;
            this.errors.currentDate= null;
            this.errors.candidatefname= null;
            this.errors.candidatelname= null;
            this.errors.candidatecontact= null;
            this.errors.candidateemail= null;
            this.errors.attachmentlink= null;
            $('#add_candidates_from').modal('hide');
        },
        saveuploadresume(objThis){
            let valid =true;
                valid = this.validateuploadresumeForm(objThis);
                if (valid) {
                    this.isLoading = true;
                    this.input = {
                        //lastmodifieddate,lastmodifiedby,createddate
                        createdby: this.undt.username,
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                        jobportalname: this.jobportalname,
                        candidatecontact: this.candidatecontact,
                        candidateemail: this.candidateemail,
                        attachmentlink: this.resumeupload.attachmentlink,
                        candidatelname: this.candidatelname,
                        candidatefname: this.candidatefname,
                        jobportalsource: this.jobportalsource,
                        applicationdate:this.currentDate,
                        reqid:this.addcandidatereqid
                    }
                    let apiURL= 'api/candidate/save'         
                    axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {
                        this.isLoading = false;
                        if(result.data.errorCode == 0){
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                          $(".close").click();                            
                        } else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                                }).then(function() {
                                    window.location.href = "/#/login";
                                })
                                $(".close").click();
                        }else if(result.data.errorCode == -1){
                            Swal.fire({
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        } else {
                            Swal.fire({
                                title: "",
                                text: result.data.msg,
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            $(".close").click();
                        }
                    })
                    .catch(e => {
                        this.displayError(e)
                        this.isLoading = false;
                        Swal.fire({
                            title: "",
                            text: e,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    })
                }
            },
            onUpload(){
            if(this.file){
                this.isLoading=true
                let formData = new FormData();

                formData.append("empcode", this.undt.userid);
                formData.append("useremail", this.undt.username);
                formData.append("moduletype", 'resumeupload');
                formData.append("fcount", '1');

                let maxsize = 0;
                for (let index = 0; index < this.file.length; index++) {
                    formData.append("imagefile", this.file[index]);
                    maxsize+= this.file[index].size
                }

                formData.empcode=this.undt.userid;
                formData.useremail=this.undt.username;
                formData.moduletype='resumeupload';
                formData.fcount= '1';
                formData.imagefile = this.file;
                formData.empcode = this.undt.userid;
                formData.moduletype= 'resumeupload';
                formData.append("imagefile", this.file);
                this.apiURL="api/master/fileupload/uploadResuleFileToS3"
                if(this.file && this.file.length !== 0){
                   let arrGlbMdl = 1;
                    if(arrGlbMdl > 0){
                        let maxsize = 0;
                        for (let index = 0; index < this.file.length; index++) {
                            formData.append("imagefile", this.file[index]);
                            maxsize+= this.file[index].size
                        }
                        if (maxsize > 1000 * 1000 * 25) {
                            this.isLoading = false;
                            Swal.fire({
                                title: "Failed",
                                text: "Your document uploaded above 25mb not allow",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            this.isLoading=false
                            this.resumeupload.disblesavebtn = true
                            return false;
                        }
                        axios({
                            'content-type':'multipart/form-data',
                            'method': 'POST',
                            'url': this.apiURL,
                            'data':formData,
                            'headers':{'authorization':this.tokendata}
                        })
                        .then(result => {
                            this.isLoading=false;
                            if(result.data.status){
                                this.resumeupload.disblesavebtn=false
                                // this.resumeupload.attachmentlink= result.data.data[0];
                                const bindinfo = result.data.data.map((file, index) => {
                                    return {"link":file,"info":this.resumeupload.filedescp[index]}
                                });
                                let jsonobj = {data:bindinfo}
                                this.resumeupload.attachmentlink = JSON.stringify(jsonobj);
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                        }).catch(e => {
                            this.displayError(e)
                        })
                    }else{
                        this.isLoading=false;
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 25mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false;
                    }
                    // axios({
                    //     'content-type':'multipart/form-data',
                    //     'method': 'POST',
                    //     'url': this.apiURL,
                    //     'data':formData,
                    //     'headers':{'authorization':this.tokendata}
                    // })
                    // .then(result => {
                    //     this.isLoading=false;
                    //     if(result.data.status){
                    //         this.resumeupload.disblesavebtn=false
                    //         // this.resumeupload.attachmentlink= result.data.data[0];
                    //         const bindinfo = result.data.data.map((file, index) => {
                    //                 return {"link":file,"info":this.resumeupload.filedescp[index]}
                    //             });
                    //             let jsonobj = {data:bindinfo}
                    //             this.resumeupload.attachmentlink = JSON.stringify(jsonobj);
                    //         Swal.fire({
                    //             title: "Success!",
                    //             text: result.data.msg,
                    //             icon: 'success',
                    //             customClass: {
                    //                 confirmButton: "btn btn-primary"
                    //             },
                    //             buttonsStyling: !1
                    //         })
                    //     } else {
                    //         Swal.fire({
                    //             title: "",
                    //             text: result.data.msg,
                    //             icon: 'info',
                    //             customClass: {
                    //                 confirmButton: "btn btn-primary"
                    //             },
                    //             buttonsStyling: !1
                    //         })
                    //         this.resumeupload.attachment=null
                    //     }
                    // }).catch(e => {
                    //     this.displayError(e)
                    // })
                }else{
                    this.isLoading=false;
                    Swal.fire({
                        title: "",
                        text: 'Select Pdf',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.resumeupload.attachment=null
                }
            } else {
                this.isLoading=false;
                Swal.fire({
                    title: "",
                    text: 'Select Pdf',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.resumeupload.attachment=null
            }
        },
            onSelect() {
                this.file = this.$refs.file.files[0];
                let allotype = ['application/pdf'];
                if (!allotype.includes(this.file.type)) {
                    Swal.fire({
                        title: "Failed",
                        text: 'Please Select only pdf file',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.file =[];
                    return
                }
                this.file = this.$refs.file.files[0];
                this.newfile = this.file.name;
                this.errors.attachmentlink =null;
                this.resumeupload.disblesavebtn =true;
            },
                getJobportal() {
            let url = 'api/hrms/getjobportallist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.jobportalnamelist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.jobportalnamelist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
         getJobsource() {
            let url = 'api/hrms/getjobjobsourcelist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.jobportalsourcelist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.jobportalsourcelist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        validateuploadresumeForm() {
            let isValid=true;
            if (!this.jobportalsource) {
                this.errors.jobportalsource = 'Job Source Required';
                isValid=false;
            }
            if (!this.currentDate) {
                this.errors.currentDate = 'Current Date Required';
                isValid=false;
            }
            if (!this.jobportalname) {
                this.errors.jobportalname = 'Job Portal Required';
                isValid=false;
            }
            if (!this.candidatefname) {
                this.errors.candidatefname = 'Candidate First Name Required';
                isValid=false;
            }
            if (!this.candidatelname) {
                this.errors.candidatelname = 'candidate Last Name Required';
                isValid=false;
            }
            if (!this.candidatecontact) {
                this.errors.candidatecontact = 'Candidate Contact No Required';
                isValid=false;
            }
            if (!this.candidateemail) {
                this.errors.candidateemail = 'Candidate Email Required';
                isValid=false;
            }
            if (!this.resumeupload.attachmentlink) {
                this.errors.attachmentlink = 'Attachment Required';
                isValid=false;
            }
            return isValid
        },
        selectactivate(actionname,jddata){
            this.formactionname = actionname
            this.formreqid = jddata.reqid;
            this.formreqcode = jddata.reqcode;
            this.jdid = jddata.jdid
            this.getbtccodelist();
            $('#tablepoup').modal('show');
        },
        selectsactivate(formactionname, formreqid, jdid){
            Swal.fire({
            title: 'Are You Sure To Dactivate This Requisition?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Dactivate it!'
          }).then((result) =>{
            if(result && result.isConfirmed){
                this.savestatus(formactionname, formreqid, jdid);
            }
          })
        },
        getbtccodelist(){
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': 'api/candidate/getsearchbtccodelist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.jdcodelist = result.data.data.rows;
                } else {
                    this.jdcodelist = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        selectjdcode(state){
            if(state && state !=null){
                this.getjddetailsbyjdid(state);
            }else{
                this.jddetails =null;
            }
        },
        getjddetailsbyjdid(id){
         this.isLoading = true;
            let url = "api/candidate/getjddetailsbyjdid";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                jdid: id
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data){
                        this.jddetails = result.data.data[0];
                    }
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.jddetails = null;
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        redirecttourl:function(btnstate,rowdata){
            if(rowdata){
                rowdata.backbtnstate =btnstate;
            }
            if(btnstate == "reqcandidatelisting"){
                this.$router.push({ 
                    name: 'RaiseRequisition',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata: rowdata ? rowdata :null
                    }
                })
            }else if(btnstate == "raisrequisition"){
                rowdata ={};
                rowdata.backbtnstate =btnstate;
                this.$router.push({ 
                    name: 'RaiseRequisition',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata: rowdata
                    }
                })
            }
        },
        validNumber: (event) => {
            let keyCode = event.keyCode;
            if (keyCode < 48 || keyCode > 57) {
                event.preventDefault();
            }
        },
        selsource(state){
            if(state){
                this.errors.jobportalsource =null;
            }
        },
        selportal(state){
            if(state){
                this.errors.jobportalname =null;
            }
        },
        selcurrdate(state){
            if(state){
                this.errors.currentDate =null;
            }
        },
        selcandidatefname(state){
            if(state){
                this.errors.candidatefname =null;
            }
        },
        selcandidatelname(state){
            if(state){
                this.errors.candidatelname =null;
            }
        },
        selcontactno(state){
            if(state){
                this.errors.candidatecontact =null;
            }
        },
        selcandidateemail(state){
            if(state){
                this.errors.candidateemail =null;
            }
        },
        requisitionstatusupdate(status,reqid,fstatus){
            this.isLoading = true;
            
            let type = ''
            let ostatus = ''
            if(status && status=='hodapproval'){
                type = 'hod';
                if(fstatus && fstatus =='reverse'){
                    ostatus ='pending';
                }else if(fstatus && fstatus =='reject'){
                    ostatus =fstatus;
                }else{
                    ostatus = 'pending_with_hr';
                }

            }else if(status && status=='hrapproval'){
                type = 'hr';
                if(fstatus && fstatus =='reverse'){
                    ostatus ='pending';
                }else if(fstatus && fstatus =='reject'){
                    ostatus =fstatus;
                }else{
                    ostatus = 'pending_with_recruiterhead';
                }

            }else if(status && status=='recruiterapproval'){
                type = 'recruiter';
                if(fstatus && fstatus =='reverse'){
                    ostatus ='pending';
                }else if(fstatus && fstatus =='reject'){
                    ostatus =fstatus;
                }else{
                    ostatus = 'approved';
                }
            }else{
                Swal.fire({
                    title: "Failed",
                    text: 'invalid status',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                reqid: parseInt(reqid),
                status: ostatus,
                type:type
            }                                                                                                                                                                                                                                             
            let apiURL='api/hrms/requisitionstatusupdate'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {    
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.formreqcode =null;
                    this.formreqid =null;
                    this.jdid =null;
                    this.jdcodelist =[];
                    $('#tablepoupapproval').modal('hide');
                    this.getraiserequisitionlist();
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
                else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        requisitionpopup(status,data){

            
            // this.formactionname = actionname
            // this.formreqid = jddata.reqid;
            // this.formreqcode = jddata.reqcode;
            // this.jdid = jddata.jdid
            // this.getbtccodelist();
            this.formreqid = data.reqid;
            this.formreqcode = data.reqcode;
            this.jdid = data.jdid
            this.approvalstatus = status

                if(data.jdid){
                 this.getjddetailsbyjdid(data.jdid)
                }
           
            //this.requisitionstatusupdate('hrapproval', data)
              $('#tablepoupapproval').modal('show');
        },
        showmappingname(ds){
            let responsestr='';
            let prlist=ds.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'.'+prlist[i]+' '
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
    }
};
   
</script>