<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header-left col-md-9 col-12 mb-1">
        <div class="row breadcrumbs-top">
          <div class="col-12 px-0">
            <h2 class="content-header-title float-left mb-0">Exam Setting</h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">
                  Exam Setting
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <!-- drives area starts-->
          <div class="drives">
            <div
              class="card" style=" box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
                margin-bottom: 1rem !important;">
              <div class="card-header" style="cursor: pointer">
                <div class="container-fluid">
                  <div class="row">
                    <h4 class="card-title d-inline-flex ml-1 exam-header-padding-y6">
                        Exam Setting
                      </h4>
                    <div
                      class="ml-auto text-right">
                      <div class="ml-auto px-1 text-right mobile-padding-0-2">
                        <div class="input-group-append displayblk">
                        <button
                          id="commonaddmodule"
                          class="btn btn-sm btn-relief-success"
                          data-toggle="modal"
                          aria-haspopup="true"
                          aria-expanded="false"
                          :data-target="currentmodule"
                          @click="addModule()"
                          type="button"
                          data-backdrop="static"
                          data-keyboard="false"
                        >
                          <plus-circle-icon
                            size="1.5x"
                            class="custom-class"
                          ></plus-circle-icon>
                          <span style="font-weight: 600;"> Create {{ glbMdlLbl }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div class="">
                                <div class="row">
                                  <div class="col-md-10">
                                    <div class="input-group-append"></div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="table-responsive"
                                v-if="showtabledata"
                              >
                        <table class="table table-sm dataTableListing table-hover table-striped" aria-describedby="mydesc">       
                          <thead>
                                    <tr>
                                      <th scope="col" class="srno align-middle" style="max-width:90px;">Sr#</th>
                                      <th scope="col" class="align-middle text-left" style="width:180px;">Course Name</th>
                                      <th scope="col" class="align-middle text-left" style="width:220px;">Assessment Title</th>
                                      <th scope="col" class="align-middle">
                                        No of Questions
                                      </th>
                                      <th scope="col" class="align-middle">
                                        Exam Duration
                                      </th>
                                      <th scope="col" class="align-middle">
                                        Passing Criteria
                                      </th>
                                      <th scope="col" class="clsaction align-middle" >
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                     <template  v-for="(data, index) in examsettinglist">
                                      <tr v-bind:key="data.moduleid">
                                        <td class="srno py-1">{{ index+1+currPageNum*pagelimit }}</td>
                                        <td class="text-left">
                                          <span class="font-weight-bold capitalisetext">{{data.coursenm}}</span>
                                        </td>
                                        <td class="text-left" style="width: 330px;">
                                          <span class="font-weight-bold capitalisetext">{{data.assessmenttitle}}</span>
                                        </td>
                                        <td>
                                          <span class="font-weight-bold capitalisetext">{{data.totalquestions}}</span>
                                        </td>
                                        <td>
                                          <span class="font-weight-bold capitalisetext">{{ data.examduration ?data.examduration.slice(0,5):'NA'}}</span>
                                        </td> 
                                        <td>
                                          <span class="font-weight-bold capitalisetext">{{data.passingcriteria}}</span>
                                        </td> 
                                        <td class="centeralign" style="width: 220px; display: block;">
                                          <span  v-if="data.ispublish== 0">
                                          <span class=" d-inline-block m-1 devEditEle edit_open_box_exam" href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#updatebusiness"
                                                
                                                @click="openEditModule(data)"
                                                data-backdrop="static" data-keyboard="false">
                                                <em class="las la-pen custom-class" style="font-size:22px;"></em>
                                          </span>
                                          </span>
                                          <span v-else><button type="button" class="btn btn-sm btn-relief-primary waves-effect waves-effect mr-2" v-if="loginusername === data.publishedby" @click="sendAssessmentReminder(data.courseid, data.id)" v-bind:disabled="data.assessmentid"><mail-icon size="1.5x" class="custom-class avatar-icon mr-50"></mail-icon><span>Reminder</span></button></span>
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="
                                        examsettinglist.length == 0 &&
                                        isresponce
                                      "
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">
                                          No record found
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-1 pageCenter">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </div>

    <!-- Add Project Details -->
    <div
      class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show"
      id="projectmaster"
    >
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            id="closebtnt11"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #ffff !important"
          >
            ×
          </button>
          <div
            class="modal-header mb-1"
            style="background-color: #1f77b4 !important"
          >
            <h5 class="modal-title" style="color: #ffff !important">
              <plus-square-icon
                size="1.5x"
                class="custom-class"
              ></plus-square-icon>
              <span class="align-middle ml-50">Add/Update Exam Setting</span>
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <form class="form form-vertical">
              <div class="row">
                <div id="addproject1st">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="message-text" class="col-form-label">Course Name</label>
                      <treeselect
                        class="projectmdllistdd"
                        placeholder="Select course name" :options="assessment.coursenamelist"
                        v-model="assessment.coursename" @input='selectCoursename' @blur="validateForm('t52',$event)"
                      />
                      <div class="errorinputmsg" v-if="this.errors.coursename">{{ this.errors.coursename }}</div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="message-text" class="col-form-label"
                        >Assessment Title</label
                      >
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Enter assessment title" v-model="assessment.assessmenttitle"
                        v-bind:class="{ 'form-control': true, '': !valid2Text(assessment.assessmenttitle) && titleBlured, }"
                        v-on:blur="titleBlured = true"
                      />
                      <div class="errorinputmsg" v-if="!valid2Text(assessment.assessmenttitle) && titleBlured">Assessment title is required</div>
                    </div>
                  </div>
                   <div class="col-12">
                    <div class="form-group">
                      <label for="message-text" class="col-form-label"
                        >Number Of Questions</label
                      >
                      <input
                        class="form-control"
                        type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }"
                        placeholder="Enter numbers only" v-model="assessment.totalquestions" @blur="validateForm('t52',$event)"                      
                      />
                      <div class="errorinputmsg" v-if="this.errors.totalquestions">{{ this.errors.totalquestions }}</div>

                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="form-group mg-bot0">
                        <label class="col-form-label" for="downtime"
                          >Exam Duration</label
                        >
                        <div id="downtime">
                          <vue-timepicker
                            hide-clear-button
                            type="time"
                            format="HH:mm" v-model="assessment.examduration" @close="validateForm('t52',$event)"
                          ></vue-timepicker>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.examduration">Exam duration in hours and minutes required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="message-text" class="col-form-label"
                        >Passing Criteria</label
                      >
                      <input
                        class="form-control"
                        type="number"
                        placeholder="Enter passing criteria in numbers" v-model="assessment.passingcriteria" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" @blur="validateForm('t52',$event)"
                      />
                      <div class="errorinputmsg" v-if="this.errors.passingcriteria">{{ this.errors.passingcriteria }}</div>
                    </div>
                  </div>
                  <div class="form-group col-12 mb-2 mt-2" style="margin-left:-1rem">
                    <label for="ispublish" :class="{'active': assessment.ispublish}" class="toggle__button">
                      <input type="checkbox" id="ispublish" v-model="assessment.ispublish"  v-on:change="onActiveselect">
                      <span class="toggle__switch"></span>
                      <span :class="{'off-lable-color': !assessment.ispublish, 'on-label-color': assessment.ispublish, 'toggle__label': true}">Publish Exam</span>
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-relief-primary mr-1"
                    @click="savewizard('t52',$event)">Submit
                  </button>
                  <button
                    type="button"
                    class="btn btn-relief-primary mr-1"
                    @click="resetForm()">Clear
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.createbtnparentdiv {
  padding-right: 0%;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}

.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import { EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,PackageIcon,MoreVerticalIcon,Edit2Icon,PlusIcon,PlusCircleIcon,XIcon,ExternalLinkIcon,PlusSquareIcon,CheckIcon,MailIcon } from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import commonMethods from '../../utils/commonMethods';
export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    PlusSquareIcon,
    PlusCircleIcon,
    CheckIcon,
    VueTimepicker,
    MailIcon,
  },
  mixins: [ commonMethods ],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      showAddForm: false,
      selected: null,
      editRowData: [],
      glbMdl: "",
      isRoleAlloc: "",
      glbst: "",
      glbMdlLbl: "Exam",
        currttbl: "t11",
        prevtbl: null,
        iseditstate: false,
        savedraft: "draft",
        nextbtn: "next",
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      showtabledata: true,
      currentmodule: "projectmaster",
      isresponce: false,
      assessment:{
            coursenamelist:null,
            coursename: null,
            totalquestions: null,
            examduration: '',
            passingcriteria: null,
            assessmenttitle: null,
            ispublish: 0,
            iseditstate: false,
      },
      errors: {
        coursename: "",
        totalquestions: "",
        examduration: "",
        passingcriteria: "",
      },
      examsettinglist: [],
      criteriaBlured: false,
      noofqueBlured:false,
      titleBlured:false,
      valid: false,
      loginusername: null,
      isassessmentmailremindersenttoday: [],
    };
  },
  created() {
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      this.tokendata = window.localStorage.getItem("token");
      let userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.loginusername=userdata.username
        this.empid = userdata.userid
      }
      this.getExamsettinglist();
      this.getCoursename();
    }
  },
  methods: {
    sendAssessmentReminder(courseid, courseassessementid) {
        this.isLoading = true;
        let url = 'api/coursemaster/mailer/sendAssessmentCompletionReminders';
        this.input = {
            courseid,
            sender: this.undt.username,
            empid: parseInt(this.undt.userid),
            courseassessementid,
            empcode: this.undt.userid,
            useremail: this.undt.username,
        };
        axios({
            method: 'POST',
            url,
            data: this.input,
            'headers':{ 'authorization' :this.tokendata }
        }).then(result => {
            this.isLoading = false;
            console.log(result)
            this.getExamsettinglist()
            if(result.data.errorCode == 0) {
                Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            } else if(result.data.errorCode == 3){
                Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
                })
              }else{
                Swal.fire({
                    title: "",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        }).catch(e => {
          this.displayError(e)
        })
    },
    isAssessmentMailReminderSentToday(assessmentid=null) {
        if(!assessmentid || (assessmentid && !assessmentid.length)) {
            return
        }
        let url = 'api/coursemaster/mailer/isAssessmentMailReminderSentToday';
        this.input = {
            courseid: this.course.courseid,
            empid: parseInt(this.undt.userid),
            assessmentid,
            empcode: this.undt.userid,
            useremail: this.undt.username,
        };
        axios({
            method: 'POST',
            url,
            data: this.input,
            'headers':{ 'authorization' :this.tokendata }
        }).then(result => {
            console.log(result, 'coursereminder')
            if(result.data.errorCode == 0) {
                this.isassessmentmailremindersenttoday = result.data.data
            }else{
                this.isassessmentmailremindersenttoday = []
            }
        }).catch(e => {
          this.displayError(e)
        })
    },
    getExamsettinglist() {
      this.isLoading = true;
      let setmoduleform = "projectmaster";
      let glbMdlLblval = "Exam";
      this.examsettinglist = [];
      let tblNm = "t52";
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      let url = "api/examsetting/getExamsettingList";
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce = true;
          this.examsettinglist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }
        else {
          this.examsettinglist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        }
      }).catch(e => {
            this.displayError(e)
      });
    },

    onActiveselect(event){
        this.assessment.ispublish = !this.assessment.ispublish
        if(event.target.checked == true){
            this.assessment.ispublish = '1'
        }else{
            this.assessment.ispublish = 0
        }
    },
    getCoursename() {
        axios({
            method: "post",
            url: "api/examsetting/getCoursename",
            data: {empcode: this.undt.userid,useremail: this.undt.username},
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.assessment.coursenamelist = result.data.data.rows
                this.assessment.coursenamelist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
            } else {
                this.assessment.coursenamelist = []
            }
        }).catch(e => {
            this.displayError(e)
        });
    },
    selectCoursename(state) {
        if(!state){
            this.errors.coursename = 'Course name is required'
        } else {
            this.errors.coursename = ''         
        }
    },
    savewizard(mdl){    
      let valid = this.validateForm(mdl)
      if(valid) {
        this.isLoading = true; 
        let tblNm = "t52"; 
        this.input = {
            tbl: tblNm,
            createdby: this.undt.username,
        }
        let apiURL='api/examsetting/examcreate'
        if (this.assessment.iseditstate == true) {
          apiURL = "api/examsetting/examupdate";
          this.input.lastmodifiedby = this.undt.username;
          this.input.courseid = this.assessment.coursename;
          this.input.id = this.editRowData.id;
          delete this.input.createdby;
        }
        this.input.courseid = this.assessment.coursename;
        this.input.totalquestions=this.assessment.totalquestions
        this.input.passingcriteria = this.assessment.passingcriteria
        this.input.assessmenttitle = this.assessment.assessmenttitle
        if (this.assessment.ispublish == 1) {
          this.input.ispublish = 1;
        } else {
          this.input.ispublish = this.assessment.ispublish;
        }
        if(typeof(this.assessment.examduration)=='string' && this.assessment.examduration) {
            this.input.examduration = this.assessment.examduration
        }else if(typeof(this.assessment.examduration)!='string' && this.assessment.examduration.HH !='' &&  this.assessment.examduration.mm!='') {
            this.input.examduration = this.assessment.examduration;
        }
        this.input.empcode = this.undt.userid
        this.input.useremail= this.undt.username
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        }).then(result => {    
          this.isLoading = false;         
          if(result.data.errorCode == 0){
              $(".close").click();
              this.isRequestActive = false
              this.isDetActive = true
              Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: 'success',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
              })
              // this.getExamsettinglist()
          } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
                Swal.fire({
                    title: "Failed",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
          this.getExamsettinglist()
          this.resetForm()
        }).catch(e => {
          this.displayError(e)
        })
      }           
    },
    openEditModule: function (currRowData) {
      this.editRowData = currRowData;
      this.assessment.coursename = currRowData.courseid;
      this.assessment.assessmenttitle = currRowData.assessmenttitle;
      this.assessment.totalquestions = currRowData.totalquestions;
      this.assessment.examduration = currRowData.examduration;
      this.assessment.passingcriteria = currRowData.passingcriteria;
      this.assessment.ispublish = 0;
      this.assessment.iseditstate = true;
      this.isAddState = true;
    },
    resetForm() {
        this.assessment.coursename=null
        this.assessment.assessmenttitle = null
        this.assessment.totalquestions= null
        this.assessment.examduration = {'HH':'', 'mm':''}
        this.assessment.passingcriteria = null
        this.assessment.ispublish = 0
    },
    addModule: function () {
      this.assessment.coursename=null
      this.assessment.assessmenttitle = null
      this.assessment.totalquestions= null
      // this.assessment.examduration = {'HH':null, 'mm':null}
      this.assessment.passingcriteria = null
      this.assessment.iseditstate = false;
      this.assessment.ispublish = 0
            // this.clearErrormsg()
    },
    clearErrormsg: function (){
      this.errors.coursename = ''
      this.errors.totalquestions = ''
      this.errors.passingcriteria = ''
      this.errors.examduration = ''
    },
    validateForm() {
      this.isAddState=false
      this.valid = true;
      this.criteriaBlured = true;
      this.noofqueBlured = true;
      this.titleBlured = true;
      let duration = this.assessment.examduration
      let durationArr=[]
      if(typeof duration==='string'){
        durationArr = duration.split(':')
      }
      let isValidHours = Boolean(duration) && Boolean(duration.HH) && Boolean(duration.mm)
      if(typeof duration === 'string' && duration.length === 5 && (durationArr[0]!='HH' &&  durationArr[1]!='mm')) isValidHours = true
      if(duration == '00:00') isValidHours = false;
      if(isValidHours && duration !== null && typeof duration === 'object') {
          this.assessment.examduration = `${duration.HH}:${duration.mm}`
      } else {
          this.errors.examduration = true
      }
      if(!isValidHours) {
          this.errors.examduration = true
          this.valid = false
      } else {
          this.errors.examduration = false
          this.valid=true;
      }

      if (!this.assessment.examduration || !isValidHours) {
        this.errors.examduration = "Exam duration hours & minutes required";
        this.valid = false;
      } else {
        this.errors.examduration = "";
        this.valid=true;
      }
      if (!this.assessment.coursename) {
        this.errors.coursename = "Course name required";
        this.valid = false;
      } else {
        this.errors.coursename = "";
        this.valid=true;
      }
      if (!this.assessment.totalquestions) {
        this.errors.totalquestions = "Total questions required";
        this.valid = false;
      } else {
        this.errors.totalquestions = "";
        this.valid=true;
      }
      if (!this.assessment.passingcriteria) {
        this.errors.passingcriteria = "Passing criteria required";
        this.valid = false;
      } else {
        this.errors.passingcriteria = "";
        this.valid=true;
      }
      if(this.assessment.passingcriteria){
        if(parseInt(this.assessment.passingcriteria) <=0 ||   parseInt(this.assessment.passingcriteria) > parseInt(this.assessment.totalquestions)){
          this.errors.passingcriteria = "passing criteria is required and should be greater than 0 and  less than total questions";
          this.valid = false;
        }else{
          this.errors.passingcriteria = "";
          this.valid= true;
        }
      }else{
        this.valid = false;
      }
      if (!isValidHours || !this.assessment.examduration || !this.assessment.assessmenttitle || !this.assessment.passingcriteria || !this.assessment.totalquestions || !this.assessment.coursename){
        this.valid = false;
      }
      return this.valid;
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.page = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if(inputEle == 't52'){
          this.getExamsettinglist()
        } else{
        this.getExamsettinglist();
      }
    },
    valid2Text: function (inputval) {
      let re = /[A-Za-z0-9].{0,}/;
      if(inputval){
        return re.test(inputval)
      }
    },
  },
};
</script>
