<template>
  <div class="app-content content ">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section class="pb-1" id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left px-0">
                <h3 class="content-header-title float-left mb-0">Issue Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/issues/list'>Issue Management List</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst" >
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
            <div class="row row fc_my-05">
              <div class="w-auto px-1 mobile-padding-0-2">
                <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                        <search-icon
                        size="1.5x"
                        class="custom-class avatar-icon font-medium-3"
                        ></search-icon>
                    </div>
                    </div>
                <h4 class="card-title d-inline-flex ml-1">Search Issue Management Details</h4>
            </div>
            <div class="ml-auto d-flex pr-1">
              <div class=" px-1 mobile-padding-0-2" >
                <div class="input-group-append float-right">
                  <router-link to="/issues/create"><button id="commonaddmodule" class="btn btn-sm btn-relief-success" data-backdrop="static" data-keyboard="false"> <span class="mobile_hide_bt"> Create Issue</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></button></router-link>
                </div>
              </div>
              <span class="rotetions_view_arrow"> 
                <em class="las la-angle-down"></em>
              </span>
              </div>
            </div>
          </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
            <div class="card-body px-sm-mobile-10">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Businesstask Code</label>
                    <treeselect type="text" class="projectmdllistdd" placeholder="Enter min 8 char of business task code" v-model="search.businesstaskcode" v-on:search-change="btaskCodeChangeSearch" :options='search.businesstaskcodelist' @input="clearbusinessCodeList" :maxlength="businessmaxLength" :multiple="true" :clear-on-select="true"/>
                 </div>
                 <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                   <label class="form-label lable-left">Issue Code</label>
                    <input type="text" class="form-control" placeholder="Enter Issue Code"  v-model="search.issuecode" v-on:search-change="issueCodeChangeSearch" @input="clearCodeList" :maxlength="maxLength"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectname" :multiple="true" :clear-on-select="true" :options="search.projectnameList" @input="selissuecsprojectSearch"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label for="projectmdllistdd">Functionality</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Functionality" v-model="search.functionality" :options="search.functionalitylist" @input="selissuecsfunctionalitySearch"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="">
                    <label for="projectmdllistdd">Micro Task</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Micro Task" v-model="search.task" :options="search.tasklist"/>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Issue Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 4 chars to Issue name" v-on:search-change="issueChange" v-model="search.issuename" :options="issuenameList"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="">
                    <label for="projectmdllistdd">Type</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Issue type" v-model="search.type" :options="issue.typelist"/>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="">
                    <label for="projectmdllistdd">Severity</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Severity" v-model="search.sevairty" :options="issue.sevairtylist"/>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="">
                    <label for="projectmdllistdd">Priority</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Priority" v-model="search.priority" :options="issue.prioritylist"/>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Created-By</label>
                  <treeselect class="projectmdllistdd" :disabled="this.isThisBusinessRole" placeholder="Enter Min 4 Chars To Search Created By" :multiple="true" :clear-on-select="true" v-model="search.createdby" :options="search.projectcreatedbyopts" v-on:search-change="getcreatedby"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                  <div class="">
                    <label for="allotedto">Assigned To</label>
                    <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search Assigned To" v-model="search.allotedto" :multiple="true" :clear-on-select="true" :options="search.allotedtolist" v-on:search-change="getEmpListByProjectAssigned"/>
                  </div>
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label for="">Issue Date From</label>
                    <date-picker placeholder="Select Issues Date From" v-model="search.createddatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-0">
                  <label for="">Issue Date To</label>
                    <date-picker placeholder="Select Issues Date To" v-model="search.createddateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="">
                    <label for="projectmdllistdd">Status</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="search.status" :multiple="true" :options="issue.statuslist"/>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="">
                    <label class="form-label lable-left">QC Cycle</label>
                    <input class="form-control" placeholder="QC Cycle" type="text" @keypress="validNumber" v-model="search.qacycle"/>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mb-0 text-right">
                  <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getissuelist(search,'',1)"> 
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                  <button type="move" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="dashboard-ecommerce">
          <div class="card card-statistics p-1" >
            <div class="">
              <div class="dark_theme_style_table">
                <div class="table-responsive ">
                  <table class="table dataTableListing" aria-describedby="mydesc" style="border:none;border-radius: 10px;overflow: hidden;">
                    <thead class="header-primary">
                      <th scope="col">&nbsp;</th>
                      <th scope="col" class="text-center">High</th>
                      <th scope="col" class="text-center">Medium</th>
                      <th scope="col" class="text-center">Low</th>
                      <th scope="col" class="text-center">Total</th>
                    </thead>
                    <tbody >
                    <tr class="row1 table-danger" >
                    <td style="text-align: left;">Open Issues</td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'open',1,parseValue(statuspriority.openhigh))">{{parseValue(this.statuspriority.openhigh)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'open',1,parseValue(statuspriority.openmedium))">{{parseValue(this.statuspriority.openmedium)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'open',1,parseValue(statuspriority.openlow))">{{parseValue(this.statuspriority.openlow)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'open',1,parseValue(statuspriority.openhigh)+parseValue(statuspriority.openmedium)+parseValue(statuspriority.openlow))">{{parseValue(statuspriority.openhigh)+parseValue(statuspriority.openmedium)+parseValue(statuspriority.openlow)}}</span></td>
                    </tr>
                    <tr class="row1 table-warning" >
                    <td style="text-align: left;">Re-Open Issues</td>
                    <td class="text-center"><span style="cursor:pointer;" @click="countClickable('high', 'reopen',1,parseValue(statuspriority.reopenhigh))">{{parseValue(this.statuspriority.reopenhigh)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'reopen',1,parseValue(statuspriority.reopenclosed))">{{parseValue(this.statuspriority.reopenclosed)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'reopen',1,parseValue(statuspriority.reopenlow))">{{parseValue(this.statuspriority.reopenlow)}}</span></td>                    
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'reopen',1,parseValue(statuspriority.reopenhigh)+parseValue(statuspriority.reopenclosed)+parseValue(statuspriority.reopenlow))">{{parseValue(statuspriority.reopenhigh)+parseValue(statuspriority.reopenclosed)+parseValue(statuspriority.reopenlow)}}</span></td>
                    </tr>
                    <tr class="row1 table-primary" >
                    <td style="text-align: left;">Resolved Issues</td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'resolved',1,parseValue(statuspriority.resolvedhigh))">{{parseValue(this.statuspriority.resolvedhigh)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'resolved',1,parseValue(statuspriority.resolvedmedium))">{{parseValue(this.statuspriority.resolvedmedium)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'resolved',1,parseValue(statuspriority.resolvedlow))">{{parseValue(this.statuspriority.resolvedlow)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'resolved',1,parseValue(statuspriority.resolvedhigh)+parseValue(statuspriority.resolvedmedium)+parseValue(statuspriority.resolvedlow))">{{parseValue(this.statuspriority.resolvedhigh)+parseValue(this.statuspriority.resolvedmedium)+parseValue(this.statuspriority.resolvedlow)}}</span></td>
                    </tr>
                    <tr class="row1 table-success" >
                    <td style="text-align: left;">Closed Issues</td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'closed',1,parseValue(statuspriority.closedhigh))">{{parseValue(this.statuspriority.closedhigh)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'closed',1,parseValue(statuspriority.closedmedium))">{{parseValue(this.statuspriority.closedmedium)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'closed',1,parseValue(statuspriority.closedlow))">{{parseValue(this.statuspriority.closedlow)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'closed',1,parseValue(statuspriority.closedhigh) + parseValue(statuspriority.closedmedium) + parseValue(statuspriority.closedlow))">{{ parseValue(this.statuspriority.closedhigh) + parseValue(this.statuspriority.closedmedium) + parseValue(this.statuspriority.closedlow) }}</span></td>
                    </tr>
                    <tr class="row1 table" >
                    <td style="text-align: left;">Feedback Issues</td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'feedback',1,parseValue(statuspriority.feedbackhigh))">{{parseValue(this.statuspriority.feedbackhigh)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'feedback',1,parseValue(statuspriority.feedbackmedium))">{{parseValue(this.statuspriority.feedbackmedium)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'feedback',1,parseValue(statuspriority.feedbacklow))">{{parseValue(this.statuspriority.feedbacklow)}}</span></td>
                    <td class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'feedback',1,parseValue(statuspriority.feedbackhigh) + parseValue(statuspriority.feedbackmedium) + parseValue(statuspriority.feedbacklow))">{{ parseValue(this.statuspriority.feedbackhigh) + parseValue(this.statuspriority.feedbackmedium) + parseValue(this.statuspriority.feedbacklow) }}</span></td>
                    </tr>
                    </tbody>
                  </table>       
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="false" id="dashboard-ecommerce">
            <div class="row match-height">
                <div class="col-xl-6 col-md-6 col-12">
                    <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                        <div class="card-header" style="background-color: rgb(255, 127, 14) !important;">
                            <h4 class="card-title" style="color: white;">Open Issues</h4>
                            <div class="d-flex align-items-center">
                            </div>
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row">
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'open',1)">{{statuspriority.openhigh?statuspriority.openhigh:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">High</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'open',1)" >{{statuspriority.openmedium?statuspriority.openmedium:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Medium</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'open',1)">{{statuspriority.openlow?statuspriority.openlow:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Low</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12">
                    <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                        <div class="card-header" style="background-color: #fb1132 !important;">
                            <h4 class="card-title" style="color: white;"> Re-Open Issues</h4>
                            <div class="d-flex align-items-center">
                            </div>
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row">
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'reopen',1)">{{statuspriority.reopenhigh?statuspriority.reopenhigh:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">High</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'reopen',1)">{{statuspriority.reopenclosed?statuspriority.reopenclosed:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Medium</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'reopen',1)">{{statuspriority.reopenlow?statuspriority.reopenlow:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Low</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12">
                    <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                        <div class="card-header" style="background-color: rgb(148, 103, 189) !important;">
                            <h4 class="card-title" style="color: white;">Resolved Issues</h4>
                            <div class="d-flex align-items-center">
                            </div>
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row">
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'resolved',1)">{{statuspriority.resolvedhigh?statuspriority.resolvedhigh:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">High</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'resolved',1)">{{statuspriority.resolvedmedium?statuspriority.resolvedmedium:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Medium</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'resolved',1)">{{statuspriority.resolvedlow?statuspriority.resolvedlow:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Low</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-12">
                    <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                        <div class="card-header" style="background-color: #4cb720 !important;">
                            <h4 class="card-title" style="color: white;">Closed Issues</h4>
                            <div class="d-flex align-items-center">
                            </div>
                        </div>
                        <div class="card-body statistics-body">
                            <div class="row">
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-danger mr-2">
                                            <div class="avatar-content">
                                                <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'closed',1)">{{statuspriority.closedhigh?statuspriority.closedhigh:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">High</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-success mr-2">
                                            <div class="avatar-content">
                                                <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'closed',1)">{{statuspriority.closedmedium?statuspriority.closedmedium:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Medium</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                                    <div class="media">
                                        <div class="avatar bg-light-primary mr-2">
                                            <div class="avatar-content">
                                                <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                                            </div>
                                        </div>
                                        <div class="media-body my-auto">
                                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'closed',1)">{{statuspriority.closedlow?statuspriority.closedlow:0}}</h4>
                                            <p class="card-text font-small-3 mb-0">Low</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div id="scrollToListGrid"></div>
        <div class="row">
   <div class="col-12">
     <ul class="listGrid float-right d-inline-flex">
        <li class="cursorpointercls" v-if="pagefeatures.bulkasigneeupdate"> 
          <button type="submit" class="btn btn-relief-primary " style="padding: 0.5rem 0.75rem;" @click="alert(isChecked)" :disabled="companymasterlist.length && !isChecked.some(bool => bool === true)">
            <edit-icon size="1.5x" class="custom-class"></edit-icon><span> Bulk Asignee Update</span>
          </button>
        </li>
        <li class="cursorpointercls"  @click="myListView()" >
          <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
            <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
            <span>
              List View
            </span>
          </button>
        </li>
        <li class=" cursorpointercls" @click="myGridView()" >
          <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
            <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
            <span>
              Grid View
            </span>
          </button>
        </li>
      </ul>
   </div>
 </div>
        <div v-bind:class="[blog_list_wrapper,{displayblk:isgridView}]">
          <div class="dw mx-n1" >
            <div class="dw-panel p-1 "  v-for="(data,index) in companymasterlist" v-bind:key="index">
              <div class="card border-warning card_style_box_shadow1" style="margin-bottom: 0rem !important;">
              <div class="card-header p-1">
                  <h4 class="card-title">{{(data.projectname).toLowerCase()}} : {{data.issuecode}} </h4>
              </div>
              <div class="card " style="margin-bottom:0 !important;">
                  <div class="card-body p-1">
                      <h4 class="card-title mb-1">
                          <span v-if="data.issuename && data.issuename.length > 50"  class="blog-title-truncate  ">
                            {{data.issuename.substring(0,51)+ ' ...'}}
                          </span>
                          <span v-else  class="blog-title-truncate  ">
                            {{data.issuename}}
                          </span>
                      </h4>
                      <div class="media">
                          <div class="avatar mr-50">
                            <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                              <img alt=""  v-if="!data.imagename" :src="defaultimagepath"  width="24" height="24" />
                          </div>
                          <div class="media-body mb-0">
                            <div>
                              <small class="text-muted mr-25">by</small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                              <span class="text-muted ml-50 mr-25">|</span>
                              <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                            </div>
                          </div>
                      </div>
                      <div class="my-1 ">
                            <div v-if="data.priority =='low'" class="capitalisetext badge badge-pill badge-light-info mr-50 mb-25">{{data.priority}}</div>
                            <div v-else-if="data.priority =='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50 mb-25">{{data.priority}}</div>
                            <div v-else-if="data.priority =='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50 mb-25">{{data.priority}}</div>
                            <div v-else-if="data.priority == null || data.priority == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                            <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">{{data.priority ? data.priority:'NA'}}</div>

                            <div v-if="data.sevairtyname =='critical'" class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div  v-if="data.sevairtyname =='major'" class="badge badge-pill badge-light-warning mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-if="data.sevairtyname =='minor'" class="badge badge-pill badge-light-primary mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-if="data.sevairtyname =='cosmetic'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-if="data.sevairtyname =='moderate'" class="badge badge-pill badge-light-info mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-if="data.sevairtyname == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>

                            <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-danger capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-warning capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-success capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='feedback'" class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                            <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status ? data.status:'NA'}}</div>

                            <div  class="badge badge-pill badge-light-primary capitalisetext mr-50 mb-25">{{data.typename  ? data.typename:'NA'}}</div>

                            <div v-if="data.qacycle>=3" class="badge badge-pill badge-light-danger capitalisetext">{{data.qacycle}}</div>
                            <div v-if="data.qacycle<3" class="badge badge-pill badge-light-primary capitalisetext">{{data.qacycle ? data.qacycle:'NA'}}</div>

                      </div>
                      <div class="card-text blog-content-truncate" >
                          <span v-if="data.scenario && data.scenario.length>150" v-html="data.scenario.substring(0, 151) + ' ...'"></span>
                          <span v-else v-html="data.scenario"></span>
                      </div>
                      
                      <div class="media ">
                          <div class="media-body mb-0">
                              <small class="text-muted mr-25">Assigned To</small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.assignedid)" class="capitalisetext">{{data.empfullname ? data.empfullname:'NA'}}</a></small>                              
                          </div>
                      </div>
                      <hr class="mx-n1" />
                      <div class="d-flex justify-content-between align-items-center">
                          <a v-if="loginusername == data.createdby || pagefeatures.edit" @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                              <div class="d-flex align-items-center sitebluecolor">
                                    <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                  <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                              </div>
                          </a>
                          <a v-on:click="getIssueVersion(data.issueid, data.issuename, data.creator, data.createddate)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <alert-circle-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></alert-circle-icon>
                              <span class="text-body font-weight-bold">Issue History</span>
                            </div>
                          </a>
                          <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold">View Details</span>
                            </div>
                          </a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
            </div>
          <div class="pageCenter text-center mt-2">
            <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            <div v-if="companymasterlist.length == 0 && isresponce" colspan="14" style="text-align: center;">No record found</div>
          </div>
        </div>
        <div v-bind:class="[{ show: isListActive}, card, border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top:1rem; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: isListDetActive},card_content,collapse]">
                <div class="row">
                  <div class="col-md-12 1">
                    <div class="card ">
                      <div class="">
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row" id="table-small">
                              <div class="col-12">
                                <div class="card">
                                  <div class="" >
                                  </div>
                                  <div class="table-responsive datatableview" >
                                    <table class="table table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                      <thead>
                                        <tr>
                                          <th scope="col" class="align-middle first-col-sticky" style="min-width: 100px;">
                                            
                                            <span class="px-1"> Sr#</span>
                                          </th>
                                          <th scope="col" class="align-middle ">Issue Code</th>
                                          <th scope="col" class="align-middle" style="min-width:130px">Issue Name</th>
                                          <th scope="col" class="align-middle" style="min-width:160px;">Project</th>
                                          <th scope="col" class="align-middle">Functionality</th>
                                          <th scope="col" class="align-middle">Type</th>
                                          <th scope="col" class="align-middle">Severity</th>
                                          <th scope="col" class="align-middle">Priority</th>
                                          <th scope="col" class="align-middle" style="min-width:160px;">Assigned to</th>
                                          <th scope="col" class="align-middle">Issue Status</th>
                                          <th scope="col" class="align-middle">QC Cycle</th>
                                          <th scope="col" class="align-middle" style="min-width:160px;">Created By</th>
                                          <th scope="col" class="align-middle">Created Date</th>
                                          <th scope="col" class="clsaction align-middle">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <template v-for="(data,index) in companymasterlist">        
                                          <tr v-bind:key="index">
                                            <td data-column="Sr#" class="first-col-sticky" >
                                              <span class="">
                                                <label :for="index+'-'+data.issueid"></label>
                                                <input type="checkbox" :id="index+'-'+data.issueid"  class="delete_checkbox" v-model="isChecked[index]" v-on:click="alterSelectAllCheck(index)">
                                                <span class="ml-1"> {{ index+1+currPageNum*pagelimit }} </span>
                                              </span>
                                            </td>
                                            <td data-column="Issue Code" class="text-nowrap ">{{data.issuecode ? data.issuecode:'NA'}}</td>
                                            <td data-column="Issue Name" class="rolelistdd"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">
                                              <span v-if="data.issuename && data.issuename.length > 50" class="font-weight-bold sitebluecolor">{{ data.issuename.substring(0, 51) + ' ...' }}</span>
                                              <span v-if="data.issuename && data.issuename.length <= 50" class="font-weight-bold sitebluecolor">{{ data.issuename }}</span>
                                            </td> 
                                            <td data-column="Project">
                                              <span class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectID',data)">{{data.projectname ? data.projectname:'NA'}}</span>
                                            </td>
                                            <td data-column="Functionality">
                                              <span class="font-weight-bold capitalisetext">{{data.functionlityname ? data.functionlityname:'NA'}}</span>
                                            </td>
                                            <td data-column="Type">
                                              <span class="font-weight-bold capitalisetext">{{data.typename ? data.typename:'NA'}}</span>
                                            </td>
                                            <td data-column="Severity">
                                                <div v-if="data.sevairtyname =='critical'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div  v-if="data.sevairtyname =='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-if="data.sevairtyname =='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-if="data.sevairtyname =='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-if="data.sevairtyname =='moderate'" class="badge badge-pill badge-light-info mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-else-if="data.sevairtyname == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.sevairtyname ? data.priority:'NA'}}</div>

                                            </td>
                                            <td data-column="Priority">
                                              <div v-if="data.priority =='low'" class="capitalisetext badge badge-pill badge-light-info mr-50">{{data.priority}}</div>
                                              <div v-else-if="data.priority =='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50">{{data.priority}}</div>
                                             <div v-else-if="data.priority =='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50">{{data.priority}}</div>
                                             <div v-else-if="data.priority == null || data.priority == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                             <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.priority ? data.priority:'NA'}}</div>

                                            </td>
                                            <td data-column="Assigned to">
                                              <span class="font-weight-bold capitalisetext">{{data.empfullname ? data.empfullname:'NA'}}</span>
                                            </td>
                                            <td data-column="Issue Status">
                                              <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50">{{data.status}}</div> 
                                              <div v-else-if="data.status.toLowerCase()=='feedback'" class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status ? data.status:'NA'}}</div>
                                            </td>
                                            <td data-column="QC Cycle">
                                              <span class="font-weight-bold capitalisetext">{{data.qacycle}}</span>
                                            </td>
                                            <td data-column="Created By"> {{ data.creator ? data.creator :'NA'}} </td>
                                            <td data-column="Created Date" class="centeralign text-nowrap">{{dateMonthFormat(data.createddate)}}</td>
                                            <td data-column="Actions" class="clsaction" >
                                            <div class="dropdown">
                                              <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                              </button>
                                              <div class="dropdown-menu">
                                                <a v-if="loginusername == data.createdby" class="dropdown-item devEditEle"  @click="redirecttourl('edit',data)" style="cursor: pointer;" href="javascript:void(0);">
                                                  <div class="d-flex align-items-center">
                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                    <span>Edit</span>
                                                  </div>
                                                </a>
                                                <a v-on:click="getIssueVersion(data.issueid, data.issuename, data.creator, data.createddate)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                  <div class="d-flex align-items-center"> 
                                                    <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                    <span>Issue History</span>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </td>
                                          </tr>
                                        </template>
                                        <template v-if="companymasterlist.length==0">
                                          <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                        </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="pageCenter text-center">
                          <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Task History Cycle</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <ul class="timeline">
                    <li class="timeline-item"  v-for="(data) in issueversionlist.slice(0,1)" v-bind:key="data.experienceid">
                      <span class="timeline-point"><edit-icon size="1.5x" class="custom-class"></edit-icon></span>
                      <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{data.author}} created <strong>{{data.issuename}}</strong></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.createdat)}}</span>
                      </div>
                    </li>
                    <li class="timeline-item"  v-for="(data,i) in issueversionlist.slice(1)" v-bind:key="data.experienceid">
                      <span v-if="i==1" class="timeline-point timeline-point-indicator"></span>
                      <span v-if="i%2==0" class="timeline-point  timeline-point-warning timeline-point-indicator"></span>
                      <span v-else class="timeline-point  timeline-point-info timeline-point-indicator"></span>
                      <div class="timeline-event">
                        <div v-if="data.field !== 'scenario'" class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{formatName(data.modifiedby)}} updated <strong>{{data.field}}</strong> from <strong style="display:inline-block" v-html="data.oldval"></strong> to <strong style="display:inline-block" v-html="data.newval"></strong></p>
                        </div>
                        <div v-else>
                          <p>{{formatName(data.modifiedby)}} updated issue scenario <a style="color: blue" @click="openissuescenario(data)">view details</a></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.lastmodifieddate)}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>    
          </div>
        </div>
      </div>
      <div class="modal fade taskdesctabs" id="taskdesc" tabindex="1" role="dialog" aria-labelledby="taskDescmodal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Task description</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <section id="card-navigation">
                <div class="row match-height">
                  <div class="col-md-12">
                    <div class="card mb-3">
                        <ul class="nav nav-tabs" id="nav-tabs" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" id="new-tab" data-toggle="tab" href="#new" role="tab" aria-controls="new" aria-selected="true">New</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="old-tab" data-toggle="tab" href="#old" role="tab" aria-controls="old" aria-selected="false">Old</a>
                          </li>
                        </ul>
                      <div class="card-body">
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
                            <p class="card-text" v-html="this.currentIssueScenario.newval"></p>
                          </div>
                          <div class="tab-pane fade" id="old" role="tabpanel" aria-labelledby="old-tab">
                            <p class="card-text" v-html="this.currentIssueScenario.oldval"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>    
          </div>
        </div>
      </div>



          <div class="modal fade" id="technologypopup1" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
              <div class="modal-content">
                <div class="modal-header" v-if="pagefeatures.bulkasigneeupdate">
                  <h4 class="modal-title ">Bulk Assignment</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetBulkRecords()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body mb-2">
                  <h5  class="text-warning text-right">{{isChecked.reduce(this.countChecked, 0)}} Row selected</h5>
                  <div class="row flex--items">
                    <div class="row mx-0">
                      <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                        <label class="form-label lable-left">Assigned To</label>
                        <treeselect class="projectmdllistdd" placeholder="Enter Min 4 Chars To Search Assigned To" v-model="assignToBulkUpdate" :clear-on-select="true" :options="search.allotedtolist" v-on:search-change="getEmpListByProjectAssigned"/>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <label for="projectmdllistdd">Status</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="statusBulkUpdate"  :options="bulkUpdatestatuslist"/>
                      </div>
                      <div class="row col-lg-12 mx-0">
                          <label for="message-label">Comments</label>
                          <textarea class="form-control"  rows="3" placeholder="Enter comment" v-model="commentBulkUpdate" v-bind:class="{'form-control':true, 'is-invalid' : !validText(commentBulkUpdate)}"></textarea>
                          <div class="invalid-feedback">Comment is required</div>
                      </div>
                    </div>
                    <div class="col-sm-12 mt-1 text-center">
                      <button type="submit" class="btn btn-relief-primary mt-0" @click="bulkUpdate()" :disabled="!commentBulkUpdate"> <check-square-icon size="1.5x" class="custom-class"></check-square-icon><span> Update</span></button>
                    </div>

                  
                  </div>
                </div>    
              </div>
            </div>
          </div>

          <div class="modal fade" id="errorPopup" tabindex="-1" role="dialog" aria-labelledby="errorPopupLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header bg-warning text-white">
                <h5 class="modal-title" id="errorPopupLabel">Error..!!</h5>
              </div>
              <div class="modal-body">
                <div class="d-flex align-items-center">
                  <span class="fas fa-exclamation-circle text-danger mr-2"></span>
                  <span>The selected issues must have the same status for bulk update.</span>
                </div>
                <div class="col-sm-12 mt-1 text-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Ok</button>
              </div>
              </div>
              
            </div>
          </div>
        </div>


      </section>
    </div>
  </div>
</template>
<style scoped>
.displayblk{
    display:none !important;
}
.bordertable td,.bordertable th{
  text-align: center;
  border: 1px solid black;
}
.bordertable .row1{
  color:black;
}
.bordertable thead {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black 
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
/* td,th{
  text-align: center;
  border: 1px solid black;
} */
.row1{
  color:black;
}
/* thead {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black 
} */
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import {ExternalLinkIcon,CheckSquareIcon,BellIcon,InfoIcon,BookIcon,LinkIcon, MessageSquareIcon,EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import profilepic from '../profilepic.vue';
import DatePicker from "vue2-datepicker";
import commonMethods from '../../utils/commonMethods';
export default {
    name:'IssueList',
    mixins: [ commonMethods ],
    components:{
      Loading,MessageSquareIcon,BellIcon,profilepic,
      VueElementLoading,InfoIcon,BookIcon,LinkIcon,
      ListIcon,
      vSelect,
      EditIcon,
      EyeIcon,
      Treeselect,
      SearchIcon,
      ExternalLinkIcon,
      GridIcon,
      CodesandboxIcon,
      CloudIcon,
      ChromeIcon,
      BoxIcon,
      Link2Icon,
      PackageIcon,
      Pagination,
      MoreVerticalIcon,
      PlusIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ClipboardIcon,
      AlertCircleIcon,
      PlusCircleIcon,XIcon,
      DatePicker,
      UserIcon,
      ckeditor: CKEditor.component,CheckSquareIcon
    },
    data() {
      return {
        maxLength:16,
        componentKey: 0,
        roleid: 0,
        currentIssueScenario: {},
        issueversionlist: [],
        issuecodelist:null,
        loginuser:'',
        isLoading: false,
        islistLoading:false,
        fullPage: true,
        listfullPage:true,
        stylevariation:apiUrl.stylevariation,
        companymasterlist: [],
        editRowData:[],
        glbMdl:'',
        glbMdlLbl:'issue',
        TimeFormat: apiUrl.TimeFormat,
        defaultimagepath:apiUrl.defaultimagepath,
        issue:{
          issuename:null,
          project:null,
          projectlist:null,
          functionality:null,
          functionalitylist:null,
          type:null,
          typelist:null,
          scenario:null,
          scenariolist:null,
          attachment:null,
          attachmentview:null,
          sevairty:null,
          sevairtylist:null,
          priority:null,
          prioritylist:[{
              id:'low',
              label:'Low'
          },{
              id:'medium',
              label:'Medium'
          },{
              id:'high',
              label:'High'
          }],
          allotedto:null,
          allotedtolist:[],
          reasonofissue:null,
          impactofissue:null,
          status:null,
          statuslist: apiUrl.issueStatuslist,
          iseditstate:false,
          disblesavebtn:false,
          task: null,
          tasklist: [],
        },
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        pagefeatures:[],
        showtabledata:true,
        showproject:false,
        modulelist:[],
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        errors:{
          issue:{
            issuename:null,
            project:null,
            functionality:null,
            type:null,
            scenario:null,
            attachment:null,
            sevairty:null,
            priority:null,
            allotedto:null,
            reasonofissue:null,
            impactofissue:null,
            status:null,
            task: null,
          }
        },
        isDetActive: false,
        isListDetActive:false,
        isListActive:true,
        displayblk:'displayblk',
        isgridView:false,
        blog_list_wrapper :'blog-list-wrapper',
        isRequestActive:false,
        isedit: false,
        card:"card",
        border_warning:'border-warning',
        editor: ClassicEditor,
        editorConfig: {
          toolbar: {
            items: [
              'heading', '|', 'bold',
              'italic', '|', 'bulletedList',
              'numberedList', '|', 'insertTable', '|',
              'undo', 'redo', '|',
            ],
          },
        },
        search:{
          searchprojectname :null,
          issuecodelist:null,
          functionality: null,
          task:null,
          issuename:null,
          type:null,
          sevairty:null,
          priority:null,
          status:null,
          allotedto:null,
          projectnameList: [],
          functionalitylist: [],
          allotedtolist: [],
          tasklist: [],
          createdby: null,
          projectcreatedbyopts: null,
          createddatefrom:null,
          createddateto:null,
          qacycle: null,
          businesstaskcode: null,
          businesstaskcodelist: []
        },
        isBothDateSelected:false,
        projectnameList:[],
        issuenameList:[],
        view:{
          projectname: null,
          functionlityname: null,
          typename: null,
          sevairtyname: null
        },
        statuspriority:[],
        countClick: false,
        countPriority: null,
        countStatus: null,
        sitebluecolor:"sitebluecolor",
        activegridlink:true,
        activelistlink:false,
        createdby: "",
        tokendata:null,
        isresponce:false,
        isThisBusinessRole: false,
        rdashboard:null,
        userrole:null,
        isChecked: [],
        isSelectAllChecked:false,
        isSelectAll:false,
        assignToBulkUpdate: null,
        businessmaxLength:19,
        statusBulkUpdate: null,
        commentBulkUpdate:'',
        selectedProjectIds:[],
        bulkUpdatestatuslist:[]
      }
    },
    created() {
      this.pagefeatures = {}
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/issues/list') {
          this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    },
    computed: {
      isSameStatus() {
        console.log("isChecked:", this.isChecked);
        if (this.isChecked.length === 0) return true; 
        const selectedStatus = new Set();
        for (let i = 0; i < this.isChecked.length; i++) {
          if (this.isChecked[i]) {
            const index = this.companymasterlist.findIndex((item, index) => index === i);
            const status = this.companymasterlist[index].status;
            selectedStatus.add(status);
          }
        }
        console.log("Selected statuses:", selectedStatus);
        return selectedStatus.size === 1;
      }
    },

    mounted(){
      window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              window.location.reload();
          }
          
      });
      let permission = this.$Service.verifyAccess();
      if(permission){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.loginusername=userdata.username
            this.loginuscondition = userdata.userid
            this.roleid = this.undt.roleid
            if(this.roleid && parseInt(this.roleid) === 413) {
              this.isThisBusinessRole = true
              this.search.createdby = this.undt.username
            }
            if(this.$route.params.clickeddata !=undefined){
              this.rdashboard = this.$route.params.clickeddata['rdashboard']
            }
            if(this.$route.params.backbtnstate == "open") {
              localStorage.removeItem('issuesearchItems')
              this.search.status=['open']
              this.getissuelist({ status: ['open'] });
            } else if(this.$route.params.backbtnstate == "reopen") {
              localStorage.removeItem('issuesearchItems')
              this.search.status=['reopen']
              this.getissuelist({ status: ['reopen'] });
            } else if(this.$route.params.search) {
              let search = JSON.parse(localStorage.getItem('issuesearchItems'))
              if(search) {
                this.allbtaskCodeChangeSearch(search.businesstaskcode);
                this.getSearchItems(search);
              }
              // this.getissuelist(search);
            } else if(this.$route.params.backbtnstate == "ProjectSummaryIssue") {
              let dataSplit = this.$route.params.clickeddata.split('_');
              let search = {
                status: [dataSplit[2]],
                type: parseInt(dataSplit[0]),
                searchprojectname: [this.$route.params.pid]
              }
              this.getissuelist(search, '', 1);
            } else {
              localStorage.removeItem('issuesearchItems')
              this.search.status=['open']
              this.getissuelist({ status: ['open'] });
            }          
            this.getProjectAllocatedToEmp()
            // this.getEmpListByProjectAssigned(parseInt(this.undt.userid))
            // this.getcreatedby(parseInt(this.undt.userid))
        }
      }
      $(".filter_rotation_onlick").click(function(){
            $(this).toggleClass("common_arrow_rotetion");
          });
    },
    methods:{
      validNumber : event => {
      let keyCode = event.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      },
      validText : function(inputval) {
        let re = /[A-Za-z0-9].{0,}/;
          if(inputval){
              return re.test(inputval.toLowerCase());
          }
      },
      forceRerender() {
        this.componentKey += 1;
      },
      redirecttoprofile(empid){
        let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
        window.open(route.href);
      },
      alert(isChecked) {
        if (!this.isSameStatus) {
          // $('#errorPopup').modal('show');
          this.errorPopup()
        } else {
          $('#technologypopup1').modal('show');
            console.log(this.isChecked, "isChecked");
            let selectedProjects = this.companymasterlist
            .filter((item, index) => this.isChecked[index])
            .map(item => {
                return {
                    projectid: parseInt(item.projectid),
                    status: item.status
                };
            });
            this.selectedProjectIds = selectedProjects.map(project => project.projectid);
            this.selectedProjectStatuses = selectedProjects.map(project => project.status);
            this.statusBulkUpdate = this.selectedProjectStatuses[0];
            this.getStatusConfigList(this.selectedProjectIds,this.selectedProjectStatuses)
        }
      },
      errorPopup(){
        Swal.fire({
            title: "Failed...!!",
            text: "The Selected issue's must have the same status for bulk update",
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          })
      },
      getStatusConfigList(projectids,status) {
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: projectids,//this.selectedProjectIds,//this.pid,
            // roleid: roleid,
            trackername: 'Issue',
            currStatus: status[0],
        };
        axios({
            method: "POST",
            url: 'api/issue/getStatusforBulkUpdate',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            if(result.data.errorCode == 0){
                this.bulkUpdatestatuslist = result.data.data.rows;
            } else {
                this.bulkUpdatestatuslist = [];
            }
        }).catch(e => {
          this.displayError(e)
        })
    },
      selectAll() {
      if(!this.isSelectAll) {
        this.isChecked = Array(this.totalcount).fill(true)
        
      } else {
        this.isChecked = Array(this.totalcount).fill(false)
      }
    },
      alterSelectAllCheck(index) {
      if(this.isChecked[index]) {
        this.isSelectAllChecked = false
      } else {
        this.isSelectAllChecked = this.isChecked.every((i, idx) => {
          if(idx !== index) {
            return i === true
          } else {
            return true
          }
        })
      }
    },
    countChecked(previousValue, currentValue, index) {
      var returns = previousValue
      if(this.isChecked[index]) {
        returns = previousValue + 1
      }
      return returns
    },
      getstatuspriority(request){
        this.isLoading = true;
        let url = "api/issue/getstatuspriority";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          projectid:request.projectname,
          functionalityid:request.functionality,
          allotedto:request.allotedto,
          taskid:request.taskid,
          status:request.status,
          createdby: request.createdby,
          datefrom: request.datefrom,
          dateto: request.dateto,
          issuecode: request.issuecode,
          issuename: request.issuename,
          empcode: this.undt.userid,
          userrole:this.userrole,
          qacycle:request.qacycle,
          businesstaskcode: request.businesstaskcode,
          typename: request.typename,
          moduletype: 'issue'
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.statuspriority = result.data.data[0];
          }
          else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            this.statuspriority=[]
          }
        });
      },
      formatName(authorstring) {
        let author=authorstring.replace('@firstcry.com','')
        if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
        author = author.replace('.',' ')
        author = author.charAt(0).toUpperCase() + author.slice(1);
        return author
      },
      openissuescenario(event) {
        this.currentIssueScenario = event
        $('#taskdesc').modal('show');
      },
      getIssueVersion(issueid, ...args) {
        let issueInfo = { issuename: args[0], author:args[1], createdat: args[2] }
        this.isLoading = true
        let apiUrl = 'api/issue/getIssueHistory'
        this.input = { issueid,useremail: this.undt.username,empcode: this.undt.userid, }
        
        axios({
                'method': 'POST',
                'url': apiUrl,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
        .then(result => {
          this.isLoading = false
          $('#technologypopup').modal('show');
          if(result.data.errorCode == 0) {
            this.issueversionlist = result.data.data.rows
            this.issueversionlist.unshift(issueInfo)
          } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            this.issueversionlist = []
            this.issueversionlist.unshift(issueInfo)
          }
        }) 
      },
      getcreatedby(node) {
        if(node && node.length > 3){
        axios({
          'method': 'POST',
          'url': 'api/issue/getcreatedby',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            userrole:this.userrole,
            createdby:node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data
          } else {
            this.search.projectcreatedbyopts = null
          }
        })
      }
      },

      parseValue(val) {
        if(val) {
          return parseInt(val)
        }
        return 0
      },
      getEmpallocatedtoProject(projectid, currtab){
          if(currtab == 'issue') {
              this.issue.allotedto = null
              this.issue.allotedtolist = []
          }
          axios({
          'method': 'POST',
          'url': 'api/listing/getEmpallocatedtoProject',
          'data': {'projectid':projectid,useremail: this.undt.username,empcode: this.undt.userid},
          'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                  if(currtab == 'issue') {
                      this.issue.allotedtolist=result.data.data;
                      let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                          items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                      })
                      if(this.issue.iseditstate) {
                          this.issue.allotedto = this.editRowData.allotedto    
                      } else {
                          this.issue.allotedto = null
                      }
                  } else if(currtab == 'issueSearch') {
                    this.search.allotedtolist=result.data.data;
                    let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

                      let tempemplabel1 = items.label;
                      let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                      if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                      }
                      return items;
                    })
                  }
              }else{
                  if(currtab == 'issue') {
                      this.issue.allotedtolist = [];
                  } else if(currtab == 'issueSearch') {
                      this.search.allotedtolist = [];
                  }
              }
          })
      },
      getEmployeeDetailsbyID(empid){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmployeeDetailsbyID',
          'data': { 'empid':empid,useremail: this.undt.username,empcode: this.undt.userid},
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
              this.issue.allotedtolist=result.data.data;
              let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            }else{
              this.issue.allotedtolist=[];
            }
        })
      },
      mydetailscollapse() {
        this.isDetActive = true
      },
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },
      myListView: function () {
        this.isListDetActive = true;
        this.isgridView=true;
        this.activegridlink=false
        this.activelistlink=true
        $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
      },
      myGridView(){
        this.isListDetActive = false;
        this.isgridView=false;
        this.activegridlink=true
        this.activelistlink=false
        $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
      },
      resetForm() {
        this.issue.issuename = null
        this.issue.project = null
        this.issue.functionality = null
        this.issue.type = null
        this.issue.scenario = ''
        this.issue.sevairty = null
        this.issue.priority = null
        this.issue.allotedto = null
        this.issue.reasonofissue = null
        this.issue.impactofissue = null
        this.issue.status = null
        this.attachment = null
        this.issue.attachmentview = null
      },
      redirecttourl:function(btnstate,rowdata){
        rowdata.backbtnstate=btnstate
        if(btnstate=="view"){
          let routeData = this.$router.resolve({ 
            path: '/issues/view?issueid='+rowdata.issueid,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          });
          window.open(routeData.href, '_blank');
        } else if(btnstate=="edit"){
          this.$router.push({ 
            path: '/issues/update?issueid='+rowdata.issueid,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        }
        else if (btnstate == 'projectID') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid
                })
            }
      },
      clickCallback: function(pageNum) {
        this.pageoffset=pageNum
        this.currPageNum = pageNum - 1
        let arrGlbMdl=this.glbMdl.split('##')
        let inputEle='';
        let btnEle='';
        if(arrGlbMdl.length > 0){
          if(typeof arrGlbMdl[0] != 'undefined'){
            inputEle=arrGlbMdl[0];
          }
          if(typeof arrGlbMdl[1] != 'undefined'){
            btnEle=arrGlbMdl[1];
          }
        }
        if(this.countStatus){
        this.getissuelist({ status: this.countStatus, priority:this.countPriority } );
        }else{
          this.getissuelist();
        }
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#scrollToListGrid").offset().top -70
            });
        }, 500);  

      },
      getProjectAllocatedToEmp() {
        this.isLoading = true
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'issue',
          isfrompage:'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers': { 'authorization': this.tokendata }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectnameList = result.data.data;
            localStorage.setItem('issueprojectnameList', JSON.stringify(result.data.data))
          } else {
            this.search.projectnameList = [];
            localStorage.removeItem('issueprojectnameList')
          }
        });
      },
      projectChangeSearch: function (node, instanceId){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            projectname:node.toLowerCase(),
            useremail: this.undt.username,
            empid: this.undt.userid,
            empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.search.projectnameList = result.data.data;
              localStorage.setItem('issueprojectnameList', JSON.stringify(result.data.data))
            } else {
              this.search.projectnameList = [];
              localStorage.removeItem('issueprojectnameList')
            }
          });
        } 
      },
      issueChange: function (node, instanceId){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/searching/getissuesnamelist";
          this.input = {
          issuename:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.issuenameList = result.data.data;
              localStorage.setItem('issuenameList', JSON.stringify(result.data.data))
            } else {
              this.issuenameList = [];
            }
          });
        } 
      },
      resetRecords: function() {
        this.search.issuecode=null
        this.search.searchprojectname = null;
        this.search.functionality = null;
        this.search.task= null;
        this.search.issuename= null;
        this.search.type = null;
        this.search.sevairty = null;
        this.search.priority = null;
        this.search.status = null;
        this.search.allotedto = null;
        this.search.issuecodelist = null
        this.search.functionalitylist = null
        this.search.tasklist = []
        this.issuenameList = null
        this.search.createddatefrom = null;
        this.search.createddateto = null;
        this.isBothDateSelected = false
        this.isChecked = []
        this.assignToBulkUpdate = null
        this.statusBulkUpdate = null,
        this.commentBulkUpdate = '',
        this.search.qacycle=null
        this.search.businesstaskcode=null
        localStorage.removeItem('issuesearchItems')
        localStorage.removeItem('issueprojectnameList')
        localStorage.removeItem('issuenameList')
        this.getissuelist();
        if(!this.isThisBusinessRole) this.search.createdby = null;
      },
      notBeforeToday: function (date) {
        this.search.createddateto = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      disabledBefore(date) {
        let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
        const beforeToday = new Date(dayBefore);
                beforeToday.setHours(0, 0, 0, 0);
        return date < beforeToday;
      },
      selectedfromdate(){
        if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
            this.isBothDateSelected = false
        }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
            this.isBothDateSelected = true
        }
      },
      disableDate(date) {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        let selectedFromDate = new Date(this.search.createddatefrom);
        selectedFromDate.setDate(selectedFromDate.getDate()-1);
        return date < selectedFromDate || date > today;
      },
      selectedtodate(){
        if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
            this.isBothDateSelected = false
        }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
            this.isBothDateSelected = true
        }
      },
      getSearchItems(search) {
        this.isSearchRequestActive = true
        this.search.projectnameList = JSON.parse(localStorage.getItem('issueprojectnameList'))
        this.search.searchprojectname  = search.searchprojectname
        this.getApplicationdata('t37','issueSearch')
        this.issuenameList = JSON.parse(localStorage.getItem('issuenameList'))
        this.search.functionality = search.functionality
        this.search.task = search.task
        this.search.issuename = search.issuename
        this.search.type = search.type
        this.search.sevairty = search.sevairty
        this.search.priority = search.priority
        this.search.status = search.status
        this.search.allotedto = search.allotedto
        this.search.createdby = search.createdby
        this.search.businesstaskcode = search.businesstaskcode
        this.search.issuecode = search.issuecode;
        this.search.createddatefrom = search.createddatefrom;
        this.search.createddateto = search.createddateto;
        this.search.qacycle = search.qacycle;
        this.getEmpListByProjectAssignededit(search.allotedto);
         this.getcreatedbyedit(search.createdby)
        this.getissuelist(this.search);
      },
      getissuelist: function(objThis, status,flag){
        this.isSelectAllChecked = false
        this.isSelectAll = false
        if(objThis) {
          localStorage.setItem('issuesearchItems', JSON.stringify(objThis))
        }
        this.islistLoading= true;
        let setmoduleform='issuemaster'
        let glbMdlLblval='Issue Master'
        let tblNm='t42'
        this.glbMdl=tblNm+'##'+setmoduleform
        this.glbst=tblNm
        if(setmoduleform){
            this.glbMdlLbl = glbMdlLblval;
        }
        if(flag==1){
          this.pageoffset=1
          this.page=1
        }
        var apiURL = 'api/issue/getissuelist';
        this.input = {
          empid:this.undt.userid,
          useremail: this.undt.username,
          offset:this.pageoffset,
          limit:apiUrl.LIMIT,
          empcode: this.undt.userid,
          userrole:this.userrole,
          moduletype :'issue'
        }
        let search = JSON.parse(localStorage.getItem('issuesearchItems'))
        if(search) {
          this.input.issuecode  = search.issuecode
          this.input.projectname = search.searchprojectname
          this.input.functionality = search.functionality
          this.input.taskid = search.task
          this.input.issuename = search.issuename
          this.input.typename = search.type
          this.input.sevairty = search.sevairty
          this.input.priority = search.priority
          this.input.status = search.status
          this.input.allotedto = search.allotedto
          this.input.createdby = search.createdby
          this.input.qacycle = search.qacycle
          this.input.businesstaskcode = search.businesstaskcode
          let assignedate =''
          if(this.search.createddateto){
            assignedate = new Date(this.search.createddateto)
            assignedate.setDate(assignedate.getDate() + 1)
            moment(String(assignedate)).format('YYYY-MM-DD');
          }
          this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
          this.input.dateto = this.search.createddateto?assignedate:null
        }
        if (status){
          this.input.status = status
          this.input.dashboardflag=1
        }
        if(this.rdashboard && !flag){
          this.input.allotedto = this.undt.userid?[this.undt.userid]:null
        }
        if(this.search.createdby){
          this.input.createdby=this.search.createdby
        }
        if(this.search.allotedto){
          this.input.allotedto = this.search.allotedto
          this.input.status = this.search.status
        }
        this.getstatuspriority(this.input)
        this.getApplicationdata('t11','issue')
        if(!this.isThisBusinessRole) {
          this.getApplicationdata('t43','issue')
        } else {
          //this.issue.typelist = apiUrl.businessRoleType
        }
        this.getApplicationdata('t44','issue')
        this.getApplicationdata('t26','issue')
        this.input.useremail= this.undt.username
        this.input.empcode= this.undt.userid
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
          this.islistLoading= false;         
          this.showproject=true;   
          if(result.data.errorCode == 0){
            this.isresponce=true
            this.forceRerender()
            this.companymasterlist=result.data.data.rows;
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
          else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.companymasterlist=[];
            this.totalcount = 0
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
          }).catch(e => {
          this.displayError(e)
          })
      },
      clearCodeList(state) {
        if(!state) {
          this.search.issuecodelist = null
        }
      },
      issueCodeChangeSearch(node) {
        if(node && node.length>1){
          axios({
            'method': 'POST',
            'url': 'api/issue/getissuecode',
            'data': {
              issuecode: node.toUpperCase(),
              empid: this.undt.userid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
            },
            'headers':{'authorization':this.tokendata}
          }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
              this.search.issuecodelist=result.data.data
              localStorage.setItem('issuecodelist', JSON.stringify(result.data.data))
            } else {
              this.serch.issuecodelist= null
              localStorage.removeItem('issuecodelist')
            }
          })
        }
      },
        countClickable: function(priority, status, clikpaged, value){
        if(value == 0){
          return 
        }
        this.companymasterlist = []
        this.countClick= true;
        this.countPriority = priority
        this.countStatus = [status]
        this.islistLoading= true;
        this.page = clikpaged
        let search = JSON.parse(localStorage.getItem('issuesearchItems'))
        let apiURL = 'api/issue/getissuelist';
        let assignedate = new Date(this.search.createddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
        this.input = {
          empid:this.undt.userid,
          useremail: this.undt.username,
          offset:clikpaged,
          limit:apiUrl.LIMIT,
          priority: priority,
          status: [status],
          allotedto: this.search.allotedto,
          createdby: this.search.createdby,
          datefrom: this.search.createddatefrom,
          dateto: assignedate,
          userrole:this.userrole,
        }
        if(search){
          this.input.issuecode  = search.issuecode?search.issuecode:null,
          this.input.projectname = search.searchprojectname?search.searchprojectname:null,
          this.input.functionality = search.functionality? search.functionality:null,
          this.input.taskid = search.task?search.task:null,
          this.input.businesstaskcode = search.businesstaskcode?search.businesstaskcode:null
        }
        this.input.useremail= this.undt.username
        this.input.empcode= this.undt.userid
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
          this.islistLoading= false;         
          this.showproject=true; 
          if(result.data.errorCode == 0){
            this.companymasterlist=result.data.data.rows;
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
          else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.companymasterlist=[];
            this.totalcount = 0
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
        })
      },
      dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
      },
      dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
      },
      dateMONFormat(value){
        if(value){
           return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        }else{
          return "";
        }
      },
      yearMonthDateFromat(value) {
        if (!value) return "";
          return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
      },
      addmodule: function(){
        this.issue.issuename = null
        this.issue.project = null
        this.issue.functionality = null
        this.issue.type = null
        this.issue.scenario = ''
        this.issue.attachment = null
        this.issue.attachmentview = null
        this.issue.sevairty = null
        this.issue.priority = null
        this.issue.allotedto = null
        this.issue.reasonofissue = null
        this.issue.impactofissue = null
        this.issue.status = null
        this.issue.iseditstate = false
        this.issue.disblesavebtn=true
      },
      getEmpListByProjectAssigned(node) {
        if(node && node.length > 3){
          axios({
            'method': 'POST',
            'url': 'api/listing/getEmpListByProjectAssigned',
            'data': {
              empid: parseInt(this.undt.userid),
              useremail: this.undt.username,
              empcode: this.undt.userid,
              empname: node.toLowerCase()
            },
            'headers':{'authorization':this.tokendata}
          }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
              this.search.allotedtolist = result.data.data;
              let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            } else {
              this.search.allotedtolist = [];
            }
          })
        }
      },
      getEmpListByProjectAssignededit(assignedto) {
        if(assignedto && assignedto.length > 0){
          axios({
            'method': 'POST',
            'url': 'api/listing/getEmpListByProjectAssigned',
            'data': {
              empid: parseInt(this.undt.userid),
              useremail: this.undt.username,
              empcode: this.undt.userid,
              assignedto: assignedto
            },
            'headers':{'authorization':this.tokendata}
          }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
              this.search.allotedtolist = result.data.data;
              let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            } else {
              this.search.allotedtolist = [];
            }
          })
        }
      },
      getcreatedbyedit(createdby) {
        if(createdby && createdby.length > 0){
        axios({
          'method': 'POST',
          'url': 'api/issue/getcreatedby',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            userrole:this.userrole,
            created : createdby
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data
          } else {
            this.search.projectcreatedbyopts = null
          }
        })
      }
      },
      selissuecsprojectSearch: function(state,value){
        this.search.searchprojectname=null
        this.search.searchprojectname=state
        if(!state){
          this.search.functionality = null 
          this.search.functionalitylist = null
          this.search.tasklist = []
          this.search.task = null
          localStorage.removeItem('issueprojectnameList')
        }else{
          
          this.getApplicationdata('t37','issueSearch')
        }
      },
      selissuecsfunctionalitySearch: function(state,value){
        this.search.functionality=null
        this.search.functionality = state
        if(!state){
          this.search.task = null
          this.search.tasklist = []
          
        }else{                
          
          this.getApplicationdata('t18', 'issueSearch')
        }
      },
      sortTypeList(typelist, type){
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            moduletype: 'issue',
            typearr: [],
            projecttype: type
        };
        axios({
            method: "POST",
            url: 'api/ticket/sortTypeList',
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.issue.typelist = result.data.data.rows;
                this.issue.typelist.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                  });
            } else {
                this.issue.typelist = [];
            }
        });
      },
      getApplicationdata: function(mdl,curracttab){            
        this.pageoffset=1
        this.inputappl = {
          tbl:mdl,
          offset:this.pageoffset,
          limit:apiUrl.LIMIT,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        let apiURL='api/master/commonmaster/list'
        if(mdl == 't11'){
          apiURL='api/listing/getprojectlist'
          this.inputappl = {
              tbl:mdl,
              useremail: this.undt.username,empcode: this.undt.userid,
          }
        }else if(mdl == 't37' && curracttab == 'issue'){
          this.issue.functionality = null
          this.issue.functionalitylist = []
          this.inputappl = {
            useremail: this.undt.username,empcode: this.undt.userid,
          };
          apiURL = "api/listing/getfunctionalitybyprojectid";
          this.inputappl.projectid = this.issue.project
        }else if(mdl == 't37' && curracttab == 'issueSearch'){
          this.inputappl = {
            useremail: this.undt.username,empcode: this.undt.userid,
          };
          apiURL = "api/listing/getfunctionalitybyprojectid";
          this.inputappl.projectidarr = this.search.searchprojectname
        }else if(mdl == 't43'){
          apiURL='api/master/commonmaster/customselect'
          /* this.inputappl.projectmoduleid=this.issue.project */
          this.inputappl.isSearch= false
          this.inputappl.section= "issue"
          this.inputappl.isFilterSelect= 1
          this.inputappl.isFilterPayload= {}
        }else if(mdl == 't44'){
          apiURL='api/master/commonmaster/customselect'
          this.inputappl.isSearch= false
          this.inputappl.section= "issue"
          this.inputappl.isFilterSelect= 1
          this.inputappl.isFilterPayload= {}
        }else if(mdl == 't26'){
          apiURL='api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: "t26",
            useremail: this.undt.username,empcode: this.undt.userid,
          }
        } else if (mdl == 't18' && curracttab == 'issue') {
          this.issue.task = null
          this.issue.tasklist = []
          apiURL = 'api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: mdl,
            useremail: this.undt.username,
            functionalityid: this.issue.functionality
          }
        } else if (mdl == 't18' && curracttab == 'issueSearch') {
          apiURL = 'api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: mdl,
            useremail: this.undt.username,
            functionalityid: this.search.functionality
          }
        }
        this.inputappl.useremail= this.undt.username
        this.inputappl.empcode= this.undt.userid
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {             
          if(result.data.errorCode == 0){
            if(mdl == 't11'){
              if(curracttab == 'issue'){
               
                return true
              }
            }else if(mdl == 't37' && curracttab == 'issue'){
              this.issue.functionalitylist=result.data.data
              this.issue.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
               });
              if(this.issue.iseditstate) {
                this.issue.functionality = this.editRowData.functionality    
              } else {
                this.issue.functionality = null
                this.issue.task = null
              }
              return true
            }else if(mdl == 't37' && curracttab == 'issueSearch'){
              this.search.functionalitylist=result.data.data
              this.search.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
               });
              return true
            }else if(mdl == 't43'){
              if(curracttab == 'issue'){
                //this.issue.typelist=result.data.data.rows
                this.sortTypeList([], 'issuetypelist');
                return true
              }
            }else if(mdl == 't44'){
              if(curracttab == 'issue'){
                this.issue.sevairtylist=result.data.data.rows
                return true
              }
            }else if(mdl == 't26'){
              if(curracttab == 'issue'){
                return true
              }
            } else if(mdl == 't18' && curracttab == 'issue') {
              this.issue.tasklist = result.data.data
              if(this.issue.iseditstate) {
                this.issue.task = this.editRowData.taskid
              } else {
                this.issue.task = null
              }
              return true
            } else if(mdl == 't18' && curracttab == 'issueSearch') {
              this.search.tasklist = result.data.data
              return true
            }
          } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            if(mdl == 't37' && curracttab == 'issue'){
              this.issue.functionalitylist=[]
              return true
            } else if(mdl == 't18' && curracttab == 'issue'){
              this.issue.tasklist = []
              return true
            } else if(mdl == 't37' && curracttab == 'issueSearch') {
              this.search.functionalitylist = []
              localStorage.removeItem('functionalitylist')
              return true
            }
          }
        })
        
      },
      bulkUpdate() {
        let prevIds = []
        this.companymasterlist.map((btm, idx) => {
          if(this.isChecked[idx]) {
            prevIds.push(parseInt(btm.issueid))
          }
        })
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          prevIds: prevIds,
          assignBulk: this.assignToBulkUpdate ? this.assignToBulkUpdate : null,
          statusBulk : this.statusBulkUpdate ? this.statusBulkUpdate : '',
          commentBulk : this.commentBulkUpdate,
          empid: this.undt.userid,
        }

        let apiURL = 'api/issue/updateIssueAssessment'
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.resetBulkRecords()
          $('#technologypopup1').modal('hide');
          if(result.data.errorCode === 0) {
            this.getissuelist();
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              text: result.data.msg,
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          }
          this.isChecked = []
        })
      },
      resetBulkRecords: function() {
        this.isChecked = []
        this.assignToBulkUpdate = null
        this.statusBulkUpdate = null,
        this.commentBulkUpdate = '',
        this.getissuelist();
      },
      clearbusinessCodeList(state) {
        if(!state) {
          this.search.btaskcodelist = null
        }
      },
      btaskCodeChangeSearch(node) {
      if(node && node.length>7) {
          axios({
              'method': 'POST',
              'url': 'api/issue/getsearchbusinesscode',
              'data': {
                  businesscode: node.toUpperCase(),
                  empcode: this.undt.userid,
                  useremail: this.undt.username
              },
              'headers':{'authorization':this.tokendata}
          }).then(result => {
              this.isLoading = false
              if(result.data.errorCode == 0) {
                this.search.businesstaskcodelist=result.data.data.rows
                  localStorage.setItem('businesstaskcodelist', JSON.stringify(result.data.data))
              } else {
                this.search.businesstaskcodelist = null
                localStorage.removeItem('businesstaskcodelist')
              }
          })
      }
    },
    allbtaskCodeChangeSearch(btcids) {
      if(btcids && btcids.length>0) {
        this.isLoading = true;
        axios({
          'method': 'POST',
          'url': 'api/issue/getsearchbusinesscode',
          'data': {
            businesscodeid: btcids,
            empcode: this.undt.userid,
            useremail: this.undt.username
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
            this.isLoading = false;
            if(result.data.errorCode == 0) {
              this.search.businesstaskcodelist=result.data.data.rows;
            } else {
              this.search.businesstaskcodelist = null;
            }
        })
      }
    },
    ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit: null,
        offset: null,
        issuecode : this.search.issuecode,
        projectname :  this.search.searchprojectname,
        functionality : this.search.functionality,
        taskid :  this.search.task,
        issuename :  this.search.issuename,
        typename :  this.search.type,
        sevairty :  this.search.sevairty,
        priority :  this.search.priority,
        createdby : this.search.createdby,
        allotedto : this.search.allotedto,
        status : this.search.status,
        useremail:this.undt.username,
        empid:this.undt.userid,
        userrole:this.userrole,
        qacycle: this.search.qacycle,
        businesstaskcode:this.search.businesstaskcode,
        moduletype:'issue'
      }; 
      let assignedate =''
      if(this.search.createddateto){
        assignedate = new Date(this.search.createddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
      this.input.dateto = this.search.createddateto?assignedate:null 
      if(this.countPriority){
        this.input.priority = this.countPriority
      }
      if(this.countStatus){
        this.input.status = this.countStatus
      }    
      this.input.useremail= this.undt.username
      this.input.empcode= this.undt.userid
      axios({
        method: "POST",
        url: `api/issue/getissuelistExport`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          let filename = "";
          this.download(result.data.data.rows,filename)
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          let ShowLabel = 'BusinessTaskReportDetails_'+filename
          let JSONData = readExportdata
          let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          let CSV = ''
          if (ShowLabel) {
            CSV = 'Sr#,Issue Code,Business Task Code,Issue Name,Project,Functionality,Type,Severity,Priority,Assigned to,Issue Status,Created By,Created Date,Last Modified Date,QA Cycle,Issue Creator Department,Issue Asignee Department,Engineering Manager,Project Manager,BT Created Date,BT Lastmodified Date,Program Manager,Task Manager,Task Owner,QA Manager,QA Lead,Issue Ageing,Ageing Bucket' + '\r\n'
          }
        let row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          console.log(index.assigndept,'---',this.decryptText(index.assigndept))
          let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode == null || index.businesstaskcode.toLowerCase() == 'na' ? '' : index.businesstaskcode : '';
          let issuecode = index.hasOwnProperty('issuecode') ? index.issuecode == null || index.issuecode.toLowerCase() == 'na' ? '' : index.issuecode : '';
          let issuename = index.hasOwnProperty('issuename') ? index.issuename == null || index.issuename.toLowerCase() == 'na' ? '': index.issuename.replace(/"/g, '""') : '';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
          let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null || index.functionlityname.toLowerCase() == 'na' ? '': index.functionlityname : '';
          let typename = index.hasOwnProperty('typename') ? index.typename == null || index.typename.toLowerCase() == 'na' ? '': index.typename : '';              
          let sevairtyname = index.hasOwnProperty('sevairtyname') ? index.sevairtyname == null || index.sevairtyname.toLowerCase() == 'na' ?'':index.sevairtyname : '';
          let priority = index.hasOwnProperty('priority') ? index.priority ==null || index.priority.toLowerCase() == 'na' ? '':index.priority : '';
          let empfullname = index.hasOwnProperty('empfullname') ? index.empfullname ==null || index.empfullname.toLowerCase() =='na'?'':index.empfullname : '';
          let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? '':index.status : '';
          let createdby = index.hasOwnProperty('creator') ? index.creator ==null || index.creator.toLowerCase() =='na'?'':index.creator : '';
          let createddate = index.hasOwnProperty('createddate') ? index.createddate ==null || index.createddate.toLowerCase() =='na'?'':index.createddate : '';
          let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate : '';
          let qacycle = index.hasOwnProperty('qacycle') ? index.qacycle : '';
          createddate = this.dateMONFormat(createddate)
          lastmodifieddate = this.dateMONFormat(lastmodifieddate);
          let creatordept = index.creatordept ? this.decryptText(index.creatordept) : ''
          let assigndept = index.assigndept ? this.decryptText(index.assigndept) : ''
          let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager ==null || index.projectmaanager.toLowerCase() =='na'?'':index.projectmaanager : '';
          let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager ==null || index.engineeringmaanager.toLowerCase() =='na'?'':index.engineeringmaanager : '';
          let btcreateddate = index.hasOwnProperty('btcreateddate') ? index.btcreateddate ==null || index.btcreateddate.toLowerCase() =='na'?'':index.btcreateddate : '';
          btcreateddate = this.dateMONFormat(btcreateddate)
          let btlastmodifieddate = index.hasOwnProperty('btlastmodifieddate') ? index.btlastmodifieddate ==null || index.btlastmodifieddate.toLowerCase() =='na'?'':index.btlastmodifieddate : '';
          btlastmodifieddate = this.dateMONFormat(btlastmodifieddate)
          let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager ==null || index.programmanager.toLowerCase() =='na'?'':index.programmanager : '';
          let productstackholder = index.hasOwnProperty('productstackholder') ? index.productstackholder ==null || index.productstackholder.toLowerCase() =='na'?'':index.productstackholder : '';
          let taskowner = index.hasOwnProperty('taskowner') ? index.taskowner ==null || index.taskowner.toLowerCase() =='na'?'':index.taskowner : '';
          let qamanager = index.hasOwnProperty('qamanager') ? index.qamanager ==null || index.qamanager.toLowerCase() =='na'?'':index.qamanager : '';
          let qalead = index.hasOwnProperty('qalead') ? index.qalead ==null || index.qalead.toLowerCase() =='na'?'':index.qalead : '';
          let issueaging = index.hasOwnProperty('issueaging') ? index.issueaging : '';

          let ageingbucket = ''
          
          if (issueaging >= 0 && issueaging <= 5) {
            ageingbucket = '0-5d'
          } else if (issueaging > 5 && issueaging <= 10) {
            ageingbucket = '6-10d'
          } else if (issueaging > 10 && issueaging <= 20) {
            ageingbucket = '11-20d'
          } else if (issueaging > 20 && issueaging <= 30) {
            ageingbucket = '21-30d'
          } else if (issueaging > 30) {
            ageingbucket = '31d+'
          }
        
            row += '"' + srno + '",',
            row += '"' + issuecode + '",',
            row += '"' + businesstaskcode + '",',
            row += '"' + issuename + '",',
            row += '"' + projectname + '",',
            row += '"' + functionlityname + '",',
            row += '"' + typename + '",',
            row += '"' + sevairtyname + '",',
            row += '"' + priority + '",',
            row += '"' + empfullname + '",',
            row += '"' + status + '",',
            row += '"' + createdby + '",',
            row += '"' + createddate + '",',
            row += '"' + lastmodifieddate + '",'
            row += '"' + qacycle + '",'
            row += '"' + creatordept + '",'
            row += '"' + assigndept + '",'
            row += '"' + engineeringmaanager + '",'
            row += '"' + projectmaanager + '",'
            row += '"' + btcreateddate +'",'
            row += '"' + btlastmodifieddate +'",'
            row += '"' + programmanager +'",'
            row += '"' + productstackholder +'",'
            row += '"' + taskowner +'",'
            row += '"' + qamanager +'",'
            row += '"' + qalead +'",'
            row += '"' + issueaging +'",'
            row += '"' + ageingbucket +'",'

            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        let fileName = 'issuelist_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
          confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });     
    }
},
export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    }
    }
}
</script>
<style scoped>
.displayblk{
    display:none !important;
}
.bordertable td,.bordertable th{
  text-align: center;
  border: 1px solid black;
	/* padding-top:0; */
	/* padding-bottom:0; */
}
.bordertable .row1{
  color:black;
}
.bordertable thead {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black 
}
</style>
