<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-2">
              Update Incident
            </h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls">
                  <router-link :to="{ name: 'Incident List', params: { search: true }}"
                    >Incident Management LIst</router-link
                  >
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">
                  Update Incident
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div
          class="card border-warning"
          id="incidentupdate"
          style="
            box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
            margin-bottom: 1rem !important;
          "
        >
          <div class="card-body">
            <form>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label capitalisetext" for="name"
                    >Incident Name</label
                  >
                  <input
                    id="name"
                    class="form-control"
                    type="text"
                    v-model="name"
                    placeholder="Incident Name"
                    v-bind:class="{
                      'form-control': true,
                      'is-invalid': !validText(name) && nameBlured,
                    }"
                    v-on:blur="nameBlured = true"
                  />
                  <div class="invalid-feedback">
                   Incident name must be length of 5 char at least and only letters are
                    allowed
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label for="project">Project Name</label>
                  <treeselect
                    class="projectmdllistdd capitalisetext"
                    v-model="projectnames"
                    :options="projectlist"
                    @input="selectproject"
                  />
                  <div class="errorinputmsg" v-if="this.errors.projectnames">
                    {{ this.errors.projectnames }}
                  </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="project">Project Module Name</label>
                    <treeselect
                      class="projectmdllistdd capitalisetext"
                      v-model="project"
                      placeholder="select project module name"
                      :options="projectmodulelist"
                      @input="seltestcsproject"
                    />
                    <div class="errorinputmsg" v-if="this.errors.project">
                      {{ this.errors.project }}
                    </div>
                  </div>
              <!-- </div>
              <div class="row"> -->
               <div class="form-group col-md-6">
                <label for="functionality">Functionality</label>
                <treeselect
                  class="projectmdllistdd capitalisetext"
                  v-model="functionality"
                  :options="functionalitylist"
                  @input="seltestcsfunctionality"
                />
                <div class="errorinputmsg" v-if="this.errors.functionality">
                  {{ this.errors.functionality }}
                </div>
               </div>
               <div class="col-md-6">
                    <div class="form-group mg-bot0">
                      <label for="projectmdllistdd">Task Name</label>
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        v-model="imgm.task"
                        :options="imgm.tasklist"
                      />
                    </div>
                  </div>
              <!-- </div> -->
              <!-- <div class="row"> -->
                <div class="form-group col-md-6">
                <label class="form-label capitalisetext" for="datetime"
                  >Incident Date</label
                >
                <input
                  type="date"
                  id="datetime"
                  class="form-control"
                  v-model="datetime"
                  placeholder="Incident Date and Time" 
                  v-on:change="validateDate('incidentdate', $event)"
                />
                <div class="errorinputmsg" v-if="this.errors.datetime">{{ this.errors.datetime }}</div>
              </div>
              <div class="form-group col-md-6">
                  <div class="form-group mg-bot0">
                      <label class="form-label" for="downtime">Total Downtime</label>
                      <div id="downtime">
                      <vue-timepicker hide-clear-button type="time" id="downtime" format="HH:mm"  v-model="totaldowntime" @close="validate"></vue-timepicker>
                      </div>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.totaldowntime">{{ this.errors.totaldowntime }}</div>
              </div>
              <!-- </div>
              <div class="row"> -->
                <div class="form-group col-md-6">
                <label class="form-label" for="impact">Incident Impact</label>
                <input
                  id="impact"
                  class="form-control capitalisetext"
                  type="text"
                  v-model="impact"
                  placeholder="Incident impact"
                  v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validText(impact) && impactBlured,
                  }"
                  v-on:blur="impactBlured = true"
                />
                <div class="invalid-feedback">
                  Incident impact must be length of 5 char at least and only letters are
                  allowed.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label" for="losses">Incident Losses</label>
                <input
                  id="losses"
                  class="form-control capitalisetext"
                  type="text"
                  v-model="losses"
                  placeholder="Incident losses"
                  v-bind:class="{
                    'form-control': true,
                    'is-invalid': !validText(losses) && lossesBlured,
                  }"
                  v-on:blur="lossesBlured = true"
                />
                <div class="invalid-feedback">
                  Incident loss must be length of 5 char at least and only letters are allowed
                </div>
              </div>
              <!-- </div>
              <div class="row"> -->
                <div class="form-group col-md-6">
                    <label class="form-label" for="incidenttype">Incident Type</label>
                    <div class="input-group input-group-merge">
                      <treeselect class="projroleassprojectdd capitalisetext" placeholder="Select Incident Type"  :options="search.incTypesopts" v-model="incidenttype" @input="selincident"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.incidenttype">{{ this.errors.incidenttype }}</div>
                </div>
                <div class="form-group col-md-6" v-if="(incidenttype && incidenttype.toLowerCase() == 'policy violation incident') || (incidenttype && incidenttype.toLowerCase() == 'work bench')">
                  <label class="form-label" for="reason">Corrective Action </label>
                  <treeselect  class="capitalisetext" for="status" v-model="correctaction" placeholder="Select Corrective Action" :options="search.correctiveaction"  @input="secaction" v-if="incidenttype.toLowerCase() == 'policy violation incident'" />

                  <treeselect  class="capitalisetext" for="status" v-model="correctaction" placeholder="Select Corrective Action" :options="search.correctiveaction2" @input="secaction" v-else-if="incidenttype.toLowerCase() == 'work bench'"/>
                  <div class="errorinputmsg" v-if="this.errors.correctaction">
                    {{ this.errors.correctaction }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12">
                    <label class="form-label" for="description"
                      >Incident Description</label
                    >
                    <ckeditor
                      :editor="editor"
                      placeholder="Incident Description"
                      v-model="description"
                      :config="editorConfig"
                      tag-name="textarea"
                    >
                    </ckeditor>
                    <div class="errorinputmsg" v-if="this.errors.desc">
                      {{ this.errors.desc }}
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label class="form-label" for="reason">Incident Reason</label>
                  <input
                    id="reason"
                    class="form-control"
                    type="text"
                    v-model="reason"
                    placeholder="Incident Reason"
                    v-bind:class="{
                      'form-control': true,
                      'is-invalid': !validText(reason) && reasonBlured,
                    }"
                    v-on:blur="reasonBlured = true"
                  />
                  <div class="invalid-feedback">
                    Incident reason must be length of 5 char at least and only letters are
                    allowed
                  </div>
                </div>
                <div class="form-group col-md-6">
                    <label class="form-label" for="createddate">Incident Status</label>
                    <input class="form-control capitalisetext" v-model="status" :options="search.statusopts" @input="selissuecsstatus" v-if="search.statusopts.length<=1" disabled/>
                    <treeselect
                        class="capitalisetext"
                        for="status"
                        v-model="status"
                        :options="search.statusopts"
                        @input="selissuecsstatus" v-else
                      />
                  <div class="errorinputmsg" v-if="this.errors.status">
                    {{ this.errors.status }}
                  </div>
              </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                <label class="form-label" for="shorttermsolution"
                  >Short term solution</label
                >
                <textarea
                  id="shorttermsolution"
                  class="form-control"
                  rows="5"
                  v-model="shorttermsolution"
                  placeholder="Incident short term solution"
                ></textarea>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label" for="longtermsolution"
                  >Long term solution</label
                >
                <textarea
                  id="longtermsolution"
                  class="form-control"
                  rows="5"
                  v-model="longtermsolution"
                  placeholder="Incident long term solution"
                ></textarea>
              </div>
              </div>
              <div class="row">
              <div class="form-group col-md-6">
                <label class="form-label" for="severity">Incident Severity</label>
                <treeselect class="projectmdllistdd capitalisetext" v-model="severity" :options="search.severityopts" @input="selectseverity"/>
                <div class="errorinputmsg" v-if="this.errors.severity">
                  {{ this.errors.severity }}
                </div>
              </div>
              <div class="form-group col-md-6">
                <label class="form-label" for="priority">Incident Priority </label>
               <treeselect class="projectmdllistdd capitalisetext" v-model="priority" :options="search.priorityopts" @input="selectpriority"/>
                <div class="errorinputmsg" v-if="this.errors.priority">
                  {{ this.errors.priority }}
                </div>
              </div>
              </div>
              <div class="row">
              <div class="form-group col-md-6">
                <label class="form-label" for="allocatedto">Assigned to</label>
                <treeselect
                  class="capitalisetext"
                  placeholder="Enter Min 4 Chars To Search Assigned To"
                  for="allocatedto"
                  v-model="allocatedto"
                  :options="employeeListOpts"
                  @input="selectallotedto"
                  v-on:search-change="getAllocatedEMPByProjectModuel"
                />
                <div class="errorinputmsg" v-if="this.errors.allocatedto">
                  {{ this.errors.allocatedto }}
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="">Subscribers</label>
                  <div class="input-group input-group-merge">
                    <treeselect class="projroleassprojectdd capitalisetext" placeholder="Enter Min 4 Chars To Search Subscribers" :multiple="true" :clear-on-select="true" :options="subscriberslist" v-model="subscribers" @input="selSubscribers" v-on:search-change="getAllocatedEMPByProjectModuel"/>
                  </div>
                <div class="errorinputmsg" v-if="this.errors.subscribers">{{ this.errors.subscribers }}</div>
                </div>
              </div>
              
              </div>
            </form>
            <div class="row">
              <div class="form-group col-12">
                <label for="lblattachment">Attachment</label>
                <div class="form-group">
                    <label for="attachment" class="btn btn-sm btn-relief-secondary mb-0 mr-75" >Select image/docs/videos</label>
                  <input type="file" id="attachment"  hidden  multiple :accept="this.accepttype" ref="file" @change="onFileChange" />
                  <div class="table-responsive my-75" v-if="attachmentview">
                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                      <thead>
                        <tr>
                          <th scope="col">SR#</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Description</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, k) in attachmentview.data" :key="k">
                          <td>{{ k + 1 }}</td>
                           <td>
                             <label>
                                    <div class="click-zoom">
                                      <input type="checkbox" />
                             <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                             <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                            &nbsp;
                             <a :href="data.presignurl" target="_blank">{{data.link | basename}}</a>
                                    </div>
                             </label>
                          </td>
                          <td>{{ data.info }}</td>
                          <td>
                            <button type="button" class="btn btn-sm danger" @click="removes3file(k, attachmentview.data)">
                              <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.attachment">
                    {{ this.errors.attachment }}
                  </div>
                  <div class="table-responsive my-75" v-if="file.length > 0">
                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                      <thead>
                        <tr>
                          <th scope="col">SR#</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Description</th>
                          <th scope="col"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(image, key) in file" :key="key">
                          <td>{{ key + 1 }}</td>
                          <td>{{ image.name }}</td>
                          <td>
                            <input class="form-control form-control-sm" type="text" placeholder="Optional description" v-model="filedescp[key]"/>
                          </td>
                          <td>
                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                              <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button class="btn btn-sm btn-relief-secondary mr-75" v-on:click.stop.prevent="onUpload()"> Upload </button>
                </div>
              </div>
              </div>
              <div class="form-group col-sm-12 flex-wrap mb-0 text-right">
                <button
                  type="button"
                  class="btn btn-relief-primary mr-1"
                  id="toggleSubmission"
                  data-dismiss="modal"
                  v-on:click.stop.prevent="saveData($event)"
                  v-bind:disabled="disblesavebtn"
                >
                  Submit
                </button>
                <router-link to='/incident/list'>
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  data-dismiss="modal"
                  @click="resetForm()"
                >
                  Cancel
                </button>
                </router-link>
              </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

                <script>
import axios from "axios";
import moment from "moment";
import { DownloadIcon,Trash2Icon,EditIcon,PlusIcon,MinusSquareIcon,ClipboardIcon,PlusSquareIcon,SearchIcon } from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import apiUrl from "../../constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "vue-pagination-2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import commonMethods from '../../utils/commonMethods';

export default {
  name: "IncidentUpdate",
  components: {
    VueTimepicker,
    Loading,Trash2Icon,
    SearchIcon,
    EditIcon,
    Treeselect,
    DatePicker,
    Pagination,
    PlusIcon,
    MinusSquareIcon,
    ClipboardIcon,
    PlusSquareIcon,
    ckeditor: CKEditor.component,
    DownloadIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      statusconfig:apiUrl.statusconfig,
      accepttype:apiUrl.uploadfiletype,
      imgExt:['jpeg','jpg','png'],
      search: {
        correctiveaction:apiUrl.correctiveaction,
        correctiveaction2:apiUrl.correctiveaction2,
        incidenttype:apiUrl.incidenttypelist,
        searchIncidentname: null,
        incidentopts: null,
        searchprojectname: null,
        projectnameopts: null,
        searchFunctionality: null,
        functionalityopts: null,
        searchTask: null,
        taskopts: null,
        searchSeverity: null,
        severityopts: [
         {
            id: "Cosmetic",
            label: "Cosmetic",
          },
          {
            id: "Critical",
            label: "Critical",
          },
          {
            id: "Major",
            label: "Major",
          },
          {
            id: "Minor",
            label: "Minor",
          },  
          {
            id: "Moderate",
            label: "Moderate",
          },  
        ],
        searchPriority: null,
        priorityopts: [
          {
            id: "High",
            label: "High",
          },
          {
            id: "Low",
            label: "Low",
          },
          {
            id: "Medium",
            label: "Medium",
          },
        ],

        searchAllocated: null,
        alloactedopts: [],
        subscribers: null,
        searchStatus: null,
        incTypesopts:[],
        statusopts: [{
            id:'open',
            label:'Open'
          },{
              id:'resolved',
              label:'Resolved'
          },{
              id:'closed',
              label:'Closed'
          },{
              id:'reopen',
            label:'Re-open'
          }]
      },
      projectmodulelist:[],
      projectnames:null,
      subscriberslist: [],
      view: {
        projectname: null,
        functionlityname: null,
        taskname: null,
        empname: null,
      },
      isSearchRequestActive: false,
      pageoffset: 1,
      TimeFormat: apiUrl.TimeFormat,
      isLoading: false,
      fullPage: true,
      valid: false,
      submitted: false,
      name: "",
      datetime: "",
      totaldowntime: "",
      impact: "",
      losses: "",
      scenario: "",
      incidenttype: "",
      description: "",
      reason: "",
      shorttermsolution: "",
      longtermsolution: "",
      project: null,
      functionality: null,
      projectlist: null,
      functionalitylist: null,
      severity: "",
      priority: "",
      attachment: null,
      attachmentview: null,
      attachmentnew: null,
      allocatedto: null,
      subscribers: null,
      status: "",
      createdby: "",
      glbMdlLbl: "Incident",
      glbMdl: "",
      incidentmasterlist: [],
      editRowData: [],
      nameBlured: false,
      impactBlured: false,
      lossesBlured: false,
      incidenttypeBlured: false,
      descriptionBlured: false,
      reasonBlured: false,
      employeeListOpts: [],
     
      errors: {
        project: "",
        projectnames: "",
        functionality: "",
        allocatedto: "",
        //subscribers: "",
        status: "",
        severity: "",
        priority: "",
        datetime: "",
        totaldowntime: "",
        incidenttype:null,
        correctaction:null,
        imgm: {
          task: null,
          tasklist: [],
        },
      },
      imgm: {
        task: null,
      },
      projectids:null,
      isAddState: false,
      disblesavebtn: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pagefeatures: [],
      showtabledata: true,
      isDetActive: true,
      isRequestActive: false,
      card_content: "card-content",
      collapse: "collapse",
      isedit: false,
      tokendata:null,
      subscriberslist: [],
      card: "card",
      border_warning: "border-warning",
      editor: ClassicEditor,
      file:[],
      filedescp: [],
      correctaction:null,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
            "|",
          ],
        },
      },
      validFileExtensions:apiUrl.validFileExtensions
    };
  },

  mounted() {
    let userdata = window.localStorage.getItem("userdata");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.createdby = userdata.username;
      this.undt = userdata;
      this.tokendata = window.localStorage.getItem('token');
    }
    this.masterlist();
    this.getincidentist();
    this.getProjectname()
    this.getincidentdetailsbyid(this.$route.query.incidentid)
    this.getSubscribers(this.$route.query.incidentid, 'incident')
    
  },
  methods: {
    validateDate(mdl, objThis) {
      var startDate = this.datetime
      if(startDate){
        this.errors.datetime=''
      }else{
        this.errors.datetime='Incident date required'
      }
    },
    removeImage (index) {
      if(this.file.length>0) {
          this.file.splice(index, 1);
          this.filedescp.splice(index, 1);
          var removeimg = JSON.parse(this.attachmentnew)
          if(removeimg){
              var remove = removeimg.data.splice(index,1)
              if(remove.length > 0)
              this.attachmentnew = JSON.stringify({data:removeimg.data})
              else {
              this.attachmentnew = null
            }
          }
      }   
      if(this.file.length < 1) {
          this.disblesavebtn = false
      }      
    },
    selectseverity(state,value){
      if (!state){
        this.errors.severity = "Incident severity required"
      }else{
        this.errors.severity = ""
      }
    },
     selectpriority(state,value){
      if (!state){
        this.errors.priority = "Incident priority required"
      }else{
        this.errors.priority = ""
      }
    },
    selectallotedto(state,value){
      if (!state){
        this.errors.allocatedto = "Assigned to required"
      }else{
        this.errors.allocatedto = ""
      }
    },
    selSubscribers(state) {
      this.subscribers = null
      this.subscribers = state
      if(!state || (state && state.length < 1)) {
        this.errors.subscribers = 'Subscribers are required'
      } else {
        this.errors.subscribers = ''
      }
    },
     selissuecsstatus: function(state,value){
        this.status=null
        this.status=state
        if(!state && this.isAddState == false){
            this.errors.status='Incident status required'
        }else{                
            this.errors.status=null
        }
    },
    selincident: function(state,value){
      this.incidenttype=null
      this.incidenttype=state
      if(!state && this.isAddState == false){
        this.errors.incidenttype='Incident type required'
      }else{                
        this.errors.incidenttype=null
      }
      if (this.incidenttype && this.incidenttype.toLowerCase() == "work bench" && this.editRowData.incidenttype != this.incidenttype) {
        this.correctaction=null;
      }
      if (this.incidenttype && this.incidenttype.toLowerCase() == "policy violation incident" && this.editRowData.incidenttype != this.incidenttype) {
        this.correctaction=null;
      }
    },
    secaction: function(state,value){
        this.correctaction=null
        this.correctaction=state
        if(!state && this.isAddState == false){
          this.errors.correctaction='Corrective action required'
        }else{                
          this.errors.correctaction=null
        }
    },
    removes3file(key,data) {
      if(data.length>0) {                
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to undo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                if(this.editRowData.id !== undefined) {
                    let deletefile = data[key];
                    data.splice(key, 1);
                    let att = this.attachment
                    if(att && att.data.length) {
                        att.data.splice(key, 1)
                        this.attachment = att
                    }
                    const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                    let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                    axios({
                    method: "POST",
                    url: 'api/incidents/s3deleteincident',
                    data: {
                        id:this.editRowData.id,
                        attachment:payload,
                        deletefile: JSON.stringify(deletefile),
                        empcode : this.undt.userid,
                        useremail: this.undt.username 
                    },
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    if (result.data.status) {
                        Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'success'
                        )
                    } else {
                    Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'error'
                        )                        
                    }
                });
                } else {
                    Swal.fire(
                    'Incident!',
                    'Incident should not blank',
                    'info'
                    )
                }    
            }
        })
      }
    },
   
    onFileChange(e) {
      var validFileExtensions = apiUrl.validFileExtensions
      let selectedFiles = e.target.files;
      for (let i=0; i < selectedFiles.length; i++)
      {
        var blnValid = false;
        for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
          var sCurExtension = validFileExtensions[j];
          if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
          }
        } 
        if (!blnValid) {
          Swal.fire({
                title: "ERROR",
                text: "Sorry, Invalid Type of Extension File..!!",
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
          })
          return false;
        }
        this.file.push(selectedFiles[i]);
      }
      if(this.file.length>0) {
          this.disblesavebtn=true
      }
    },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    getincidentdetailsbyid(id){
        this.isLoading = true;
        let url = "api/incidents/getincidentdetailsbyid";
        this.input = {
            incidentid:id,
            useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
            empcode : this.undt.userid ,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.editRowData = result.data.data;
              this.editRowData=this.editRowData[0]
              this.openEditModule(this.editRowData,0)
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
                this.$router.push({ name: 'Incident List', params: { search: true }});
              Swal.fire({
                  title: "Failed!",
                  text: "Your are not authorised user to update this incident",
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
            }
          }).catch(e => {
            this.displayError(e)
          });
        },

    getProjectname: function () {
      this.apiURL = "api/projectapplication/getprojectnamelist";
      this.input = {
        projectid: 0,
        useremail: this.undt.username,
        empcode : this.undt.userid,
        isfrompage : 'updatepage'
      };
      axios({
        method: "POST",
        url: this.apiURL,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.projectlist = result.data.data;
          if(this.projectlist && this.projectlist.length > 0 && (this.projectnames && this.projectnames !=null)){
              const checkexistproject = this.projectlist.some(item =>item.id === this.projectnames);
              if(!checkexistproject){
                  this.getProjectnamebyid(this.projectnames);
              }
          }else if(this.projectlist.length ==0 && (this.projectnames && this.projectnames !=null)){
              this.getProjectnamebyid(this.projectnames);
          }
        } else {
          this.projectlist = [];
        }
      });
    },
    getProjectnamebyid(pid){
        this.isLoading = true;
        this.apiURL="api/searching/getAssignedProjectList"
        this.input={
            useremail: this.undt.username,
            empid :this.undt.userid,
            empcode: this.undt.userid,
            projectid: pid,
            isfrompage: 'isprojectid'
        }
        axios({
            'method': 'POST',
            'url': this.apiURL,
            'data':this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {  
            this.isLoading = false;         
            if(result.data.errorCode == 0){
                if(result.data.data && result.data.data.length > 0){
                    this.projectlist.push(result.data.data[0]);
                }
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    getProjecttoProjectmodule(projectid) {
      axios({
        method: "POST",
        url: "api/master/businesstask/getprojectmodulebyprojectid",
        data: { projectid:projectid,empcode: this.undt.userid,
        useremail: this.undt.username, isfrompage: 'updatepage' },
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectmodulelist = result.data.data;

          if(this.projectmodulelist && this.projectmodulelist.length > 0 && (this.project && this.project !=null)){
            const checkexistproject = this.projectmodulelist.some(item =>item.id === this.project);
            if(!checkexistproject){
              this.getprojectmodulesbyid(projectid,this.project);
            }
          }else if(this.projectmodulelist.length ==0 && (this.project && this.project !=null)){
            this.getprojectmodulesbyid(projectid,this.project);
          }

        } else {
          this.projectmodulelist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getprojectmodulesbyid(projectid,moduleid) {
      axios({
        method: "POST",
        url: "api/master/businesstask/getprojectmodulebyprojectid",
        data: { projectid:projectid, empcode: this.undt.userid,
        useremail: this.undt.username, moduleid: moduleid },
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if(result.data.data && result.data.data.length > 0){
            if(this.projectmodulelist && this.projectmodulelist.length > 0){
              const checkexistprojectmodule = this.projectmodulelist.some(item =>item.id === moduleid);
              if(!checkexistprojectmodule){
                this.projectmodulelist.push(result.data.data[0]);
              }
            }else if(this.projectmodulelist.length ==0){
              this.projectmodulelist.push(result.data.data[0]);
            }
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    // getAssignedProjectData(projectid) {
    //   this.isLoading = true;
    //   let url = "api/searching/getAssignedProjectListWithMOdule";
    //   this.input = {
    //     projectname: "",
    //     useremail: this.undt.username,
    //     empid: this.undt.userid,
    //     projectid: projectid,
    //     empcode : this.undt.userid ,

    //   };
    //   axios({
    //     method: "POST",
    //     url: url,
    //     data: this.input,
    //     'headers':{'authorization':this.tokendata}
    //   }).then((result) => {
    //     this.isLoading = false;
    //     if (result.data.errorCode == 0) {
    //       this.projectlist = result.data.data;
    //     } else {
    //       this.projectlist = [];
    //     }
    //   }).catch(e => {
    //         this.displayError(e)
    //       });
    // },
    incidentnmChange: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/master/commonmaster/getModuleDropdownOption";
        this.input = {
          tbl: "t46",
          incidentname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode : this.empid
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.incidentopts = result.data.data;
          } else {
            this.search.incidentopts = [];
          }
        }).catch(e => {
            this.displayError(e)
          });
      }
    },
    getSubscribers(moduleid, moduletype){
      this.isLoading = true;
      let url = "api/subscribers/getSubscribersByModuleid";
      this.input = { moduleid, moduletype,useremail: this.undt.username,empcode : this.undt.userid };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          let decryptdepartmenttext = result.data.data.rows.map(items =>{
          let tempemplabel1 = items.label;
          let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
          if(tempemplabel2 && tempemplabel2 != null){
            items.label = tempemplabel1 +'('+tempemplabel2 +')';
          }
          return items;
          })
          this.subscriberslist = result.data.data.rows;
          this.subscribers = result.data.data.rows.map(d => d.id)
        } else {
          this.subscribers = null;
          this.subscriberslist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getAllocatedEMPByProjectModuel(node) {
      if(this.project && (node && node.length>3)){
      axios({
        method: "POST",
        url: "api/listing/getAllocatedEMPByProjectModuel",
        data: { projectidModuleID: this.project,useremail: this.undt.username,empcode : this.undt.userid,assignedto:node.toLowerCase() },
         'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.employeeListOpts = result.data.data;
          this.subscriberslist = result.data.data
          let templistarray =[]
              templistarray = result.data.data;
              let decryptdepartmenttext = templistarray.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
                this.employeeListOpts = templistarray;
                this.subscriberslist=templistarray;
        } else {
          this.employeeListOpts = [];
          this.subscriberslist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    }
    },
    getAllocatedEMPByProjectModueledit(assignedto) {
      if(assignedto){
      axios({
        method: "POST",
        url: "api/listing/getAllocatedEMPByProjectModuel",
        data: { projectidModuleID: this.project,useremail: this.undt.username,empcode : this.undt.userid,assignedtoid:assignedto},
         'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.employeeListOpts = result.data.data;
          
        } else {
          this.employeeListOpts = [];
          
        }
      }).catch(e => {
        this.displayError(e)
      });
    }
    },
    selectproject: function (state, value) {
      this.search.projectnameopts = null;
      this.search.searchFunctionality = null;
      this.search.searchAllocated = null;
       this.search.searchTask = null;
      this.search.taskopts = null;
      this.search.alloactedopts = [];
      this.search.searchAllocated = null;
      this.search.searchProjectname = state;
      if (!state && this.isSearch == false) {
        this.search.functionalityopts = null;
        this.search.alloactedopts = [];
      } else {
        // this.getDropdownApplicationdata("t37");
        this.getProjecttoProjectmodule(state)
        //this.getAllocatedEMPByProjectModuel(state)
      }
    },
    selectfunctionality: function (state, value) {
      this.search.searchTask = null;
      this.search.taskopts = null;
      this.search.searchFunctionality = state;
      if (!state && this.isSearch == false) {
        this.search.taskopts = null;
      } else {
        this.getDropdownApplicationdata("t18");
      }
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption";
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      if (mdl === "t37") {
        this.input = {};
        apiUrl = "api/listing/getfunctionalitybyprojectmoduleid";
        this.input.projectmoduleid = [this.search.searchProjectname];
      }
      if (mdl === "t18") {
        this.input.functionalityid = this.search.searchFunctionality;
      }
      if (mdl === "t46") {
        this.input.functionalityid = this.search.searchFunctionality;
      }
      
      this.input.useremail= this.undt.username,
      this.input.empcode = this.undt.userid
      axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
      .then((result) => {
        if (result.data.errorCode == 0) {
          if (mdl === "t37") {
            this.search.functionalityopts = result.data.data;
            this.search.functionalityopts.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
          } else if (mdl === "t18") {
            this.search.taskopts = result.data.data;
          } else if (mdl === "t46") {
            this.search.incidentopts = result.data.data;
          }
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.search.functionalityopts = [];
          this.search.taskopts = [];
          this.search.incidentopts = [];
          this.search.alloactedopts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    
    mydetailscollapse() {
      this.isDetActive = !this.isDetActive;
    },
    myrequestcollapse() {
      this.isRequestActive = !this.isRequestActive;
      this.datetime = null;
      this.name = null;
      this.datetime = null;
      this.totaldowntime = null;
      this.impact = null;
      this.losses = null;
      this.description = "";
      this.reason = null;
      this.incidenttype = null;
      this.shorttermsolution = null;
      this.longtermsolution = null;
      this.severity = null;
      this.priority = null;
      this.allocatedto = null;
      this.status = null;
      this.project = null;
      this.correctaction=null;
    },
    resetForm() {
      this.name = "";
      this.imgm.task = null;
      this.datetime = "";
      this.totaldowntime = "";
      this.impact = "";
      this.losses = "";
      this.incidenttype = "";
      this.correctaction=null;
      this.description = "";
      this.reason = "";
      this.shorttermsolution = "";
      this.longtermsolution = "";
      this.project = null;
      this.projectnames = null;
      this.functionality = null;
      this.severity = "";
      this.priority = "";
      this.attachment = null;
      this.attachmentview = null;
      this.allocatedto = null;
      this.status = "";
      this.submitted = false;
      this.valid = false;
      this.nameBlured = false;
      this.impactBlured = false;
      this.lossesBlured = false;
      this.incidenttypeBlured = false;
      this.descriptionBlured = false;
      this.reasonBlured = false;
      this.disblesavebtn = false;
      this.errors = {
        project: "",
        projectnames: "",
        functionality: "",
        allocatedto: "",
        status: "",
        severity: "",
        priority: "",
        datetime: "",
        totaldowntime: "",
        desc: "",
        correctaction:"",
        incidenttype:""
      };
    },

    validText: function (inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },

    validate: function () {
      this.valid = true;
      this.nameBlured = true;
      this.impactBlured = true;
      this.lossesBlured = true;
      this.incidenttypeBlured = true;
      this.descriptionBlured = true;
      this.reasonBlured = true;
      if (!this.project) {
        this.errors.project = "Project module name required";
        this.valid = false;
      } else {
        this.errors.project = "";
      }
      if (!this.projectnames) {
        this.errors.projectnames = "Project name required";
        this.valid = false;
      } else {
        this.errors.projectnames = "";
      }

      if (!this.description) {
        this.errors.desc = "Incident description required";
        this.valid = false;
      } else {
        this.errors.desc = "";
      }
      if (!this.allocatedto) {
        this.errors.allocatedto = "Assigned to required";
        this.valid = false;
      } else {
        this.errors.allocatedto = "";
      }
      if((!this.subscribers) || (this.subscribers && this.subscribers.length < 1)) {
          this.errors.subscribers = 'Subscribers are required'
          this.valid = false
      } else {
          this.errors.subscribers = ''
      }
      if (!this.status) {
        this.errors.status = "Incident status required";
        this.valid = false;
      } else {
        this.errors.status = "";
      }
      if (!this.incidenttype) {
        this.errors.incidenttype = "Incident type required";
        this.valid = false;
      } else {
        this.errors.incidenttype = "";
      }
      if(this.incidenttype && (this.incidenttype.toLowerCase() =="policy violation incident" || this.incidenttype.toLowerCase() =="work bench") && !this.correctaction){
        this.errors.correctaction = "Corrective action required";
        this.valid = false;
      }else {
        this.errors.correctaction = "";
      }
      if (!this.severity) {
        this.errors.severity = "Incident severity required";
        this.valid = false;
      } else {
        this.errors.severity = "";
      }
      if (!this.priority) {
        this.errors.priority = "Incident priority required";
        this.valid = false;
      } else {
        this.errors.priority = "";
      }
      if (!this.datetime) {
        this.errors.datetime = "Incident date required";
        this.valid = false;
      } else {
        this.errors.datetime = "";
      }
      if (!this.totaldowntime) {
        this.errors.totaldowntime = "Incident down-time required";
        this.valid = false;
      } else {
        this.errors.totaldowntime = "";
      }
      if (!this.validText(this.name)) {
        this.valid = false;
      }
      if (!this.validText(this.impact)) {
        this.valid = false;
      }
      if (!this.validText(this.losses)) {
        this.valid = false;
      }
      if (!this.validText(this.incidenttype)) {
        this.valid = false;
      }
      if (!this.validText(this.description)) {
        this.valid = false;
      }
      if (!this.validText(this.reason)) {
        this.valid = false;
      }
      return this.valid;
    },

    masterlist(objThis) {
      let apiURL = "/api/incidents/list";
      let userdata = window.localStorage.getItem("userdata");
      userdata = JSON.parse(userdata);

      this.input = {
        limit: apiUrl.LIMIT,
        offset: this.pageoffset,
        incidentname: this.search.searchIncidentname,
        taskname: this.search.searchTask,
        projectname: this.search.searchprojectname,
        functionality: this.search.searchFunctionality,
        priority: this.search.searchPriority,
        status: this.search.searchStatus,
        allocatedto: this.search.searchAllocated,
        severity: this.search.searchSeverity,
        empid: this.undt.userid,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      if (typeof objThis != "undefined" && objThis != "") {
        if (
          objThis.currentTarget.dataset.clkstate == "search" &&
          $("#glbsearchinpele").val() != ""
        ) {
          this.input.name = $("#glbsearchinpele").val().trim();
          this.input.isSearch = true;
        }
      }

      
      axios({
            'method': 'POST',
            'url': apiURL,
            'data':this.input,
            'headers':{'authorization':this.tokendata}
      })
      .then((result) => {
        if (result.data.errorCode == 0) {
          this.isDetActive = true;
          this.incidentmasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          
          this.isDetActive = true;
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.isDetActive = true;
          this.incidentmasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

    addModule(objThis) {
      $(objThis.currentTarget.dataset.target)
        .find("#toggleSubmission")
        .attr("data-isedit", false);
      this.disblesavebtn = true;
      this.attachmentview = null;
    },

    getincidentist() {
      this.getApplicationdata("t11", "imgm");
    },
    getApplicationdata(mdl, curr) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      let apiURL = "api/master/commonmaster/list";
      if (mdl == "t11") {
        apiURL = "api/listing/getprojectlist";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
      } else if (mdl == "t37") {
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        apiURL = "api/listing/getfunctionalitybyprojectmoduleid";
        this.inputappl.projectmoduleid = [this.project];
      } else if (mdl == "t18") {
        apiURL = "api/master/commonmaster/getModuleDropdownOption";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          functionalityid: this.functionality,
          empcode: this.undt.userid,
        };
      }
      this.input.useremail= this.undt.username,
      this.input.empcode = this.undt.userid
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (mdl == "t11") {
            
            return true;
          } else if (mdl == "t37") {
            this.functionalitylist = result.data.data;
            this.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            return true;
          } else if (mdl == "t18" && curr == "imgm") {
            this.imgm.tasklist = result.data.data;
            return true;
          }
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          }
      }).catch(e => {
        this.displayError(e)
      });
    },

    seltestcsproject(state, value) {
      this.project = null;
      this.project = state;
      if (!state) {
        this.functionalitylist = null;
        this.functionality = null;
        this.imgm.tasklist = null;
        this.imgm.task = null;
        this.allocatedto = null;
        this.employeeListOpts = null;
        this.project = null;
        this.errors.project = "Project required";
        this.getStatusConfigList(null,this.status);
        this.projectids,
        this.status = this.status ? this.status : null;
        this.incidenttype = null;
      } else {
        this.getIncidentTypeConfigLists(state,this.status)
        // this.getIncidentTypeConfigList(state,this.status)
        this.getApplicationdata("t37", "testcase");
       // this.getAllocatedEMPByProjectModuel(state);
       if (state != this.editRowData.projectmoduleid) {
          this.incidenttype = null
       }
      }
    },

    seltestcsfunctionality(state, value) {
      this.functionality = null;
      this.functionality = state;
      if (!state && this.isAddState == false) {
        
        this.imgm.task = null;
        this.imgm.tasklist = null;
      } else {
        
        this.getApplicationdata("t18", "imgm");
      }
    },

    saveData(objThis) {
      if (this.validate()) {
        this.submitted = true;
      }
      if (this.submitted) {
        this.isLoading = true;
        this.input = {
          name: this.name,
          task: this.imgm.task,
          datetime: this.datetime,
          totaldowntime: this.totaldowntime,
          impact: this.impact,
          losses: this.losses,
          // incidenttype: this.incidenttype,
          incidenttype: this.incidenttype ? this.incidenttype.toLowerCase() : null,
          description: this.description,
          reason: this.reason,
          shorttermsolution: this.shorttermsolution,
          longtermsolution: this.longtermsolution,
          projectmoduleid: this.project,
          projectid: this.projectnames,
          severity: this.severity,
          priority: this.priority,
          allocatedto: this.allocatedto,
          subscribers: this.subscribers,
          empid: this.undt.userid,
          createdby: this.createdby,
          isactive: "1",
          status: this.status,
          lastmodifiedby: this.undt.username,
          module_code: this.editRowData.incidentcode,
          correctiveaction:this.correctaction
        };
        if (!this.functionality) {
          this.input.functionality = null;
        } else {
          this.input.functionality = this.functionality;
        }
        if (!this.imgm.task) {
          this.input.task = null;
        } else {
          this.input.task = this.imgm.task;
        }
        if(this.incidenttype == 'IT Infra Incident'){
            this.input.incidenttype = 'infra incident'
        } else{
          this.input.incidenttype = this.incidenttype
        }
        this.input.attachment=this.attachmentnew
        this.input.useremail= this.undt.username,
        this.input.empcode = this.undt.userid
        let apiURL = "/api/incidents/save";
        if (this.isedit) {
          this.isedit = false;
          apiURL = "/api/incidents/update";
          this.input.id = this.editRowData.id;
          if(this.attachmentnew && this.attachment !== null) {
            this.input.attachmentnew = this.attachmentnew
            let jsonobj1 = JSON.parse(this.attachmentnew);
            let jsonobj2 = this.attachment;
            let arraymerge = [...jsonobj1.data,...jsonobj2.data]
            this.input.attachment= JSON.stringify({data: arraymerge});
          }else if(this.attachment && !this.attachmentnew){
            this.input.attachment = JSON.stringify(this.attachment)
          }
          /*if(!this.input.attachment && this.attachmentview){
            this.input.attachment = JSON.stringify(this.attachmentview)
          }*/
        }
        axios({
                'method': 'POST',
                'url': apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.resetForm();
              this.$router.push({ name: 'Incident List', params: { search: true }});
            }
            else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            }
          }).catch(e => {
        this.displayError(e)
      });
      }
    },
    openEditModule(currRowData, flag) {
      this.resetForm();
      this.editRowData = currRowData;
      this.view.projectname = currRowData.projectname;
      this.view.functionlityname = currRowData.functionlityname;
      this.view.taskname = currRowData.taskname;
      this.view.empname = currRowData.empname;
      let btnEle = "incident";
      $("#" + btnEle)
        .find("#toggleSubmission")
        .addClass(btnEle);
      $("#" + btnEle)
        .find("#toggleSubmission")
        .attr("data-isedit", true);
      this.name = currRowData.incidentname;
      this.datetime = moment(currRowData.datetime).format("YYYY-MM-DD");
      this.totaldowntime = currRowData.totaldowntime;
      this.impact = currRowData.impact;
      this.losses = currRowData.losses;
      if(currRowData.incidenttype =='it infra incident'){
        this.incidenttype = 'IT Infra Incident'
      }else{
        this.incidenttype = this.capitalizeFirstLetter(currRowData.incidenttype);
      }
      this.description = currRowData.description;
      this.reason = currRowData.reason;
      this.shorttermsolution = currRowData.shorttermsolution;
      this.longtermsolution = currRowData.longtermsolution;
      this.project = currRowData.projectmoduleid;
      this.projectnames = currRowData.projectid;
      this.getApplicationdata("t37", "testcase");
      this.getProjecttoProjectmodule(currRowData.projectid);
      // this.getProjecttoProjectmodule(currRowData.projectmoduleid);
      //this.getAllocatedEMPByProjectModuel(currRowData.projectmoduleid);
      this.functionality = currRowData.functionality;
      this.imgm.task = currRowData.taskid;
      this.severity = currRowData.severity;
      this.priority = currRowData.priority;
      this.attachment = currRowData.attachment;
      this.correctaction = currRowData.correctiveaction;
      this.attachmentview = currRowData.attachment;
      if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
        this.attachmentview =[];
        this.getDownloadLink(currRowData.attachment);
      }
      this.allocatedto = currRowData.allocatedto;
      this.getAllocatedEMPByProjectModueledit(currRowData.allocatedto)
      this.status = currRowData.status;
      this.projectids = currRowData.projectid
      this.getStatusConfigList(this.projectids,this.status);
      this.getIncidentTypeConfigLists(this.project,this.incidenttype.toLowerCase())
      this.isAddState = true;
      if (flag == 0) {
        this.isedit = true;
        this.isRequestActive = true;
        this.mydetailscollapse();
      }
      if(this.projectlist && this.projectlist.length > 0 && (this.projectnames && this.projectnames !=null)){
          const checkexist = this.projectlist.some(item =>item.id === this.projectnames);
          if(!checkexist){
              this.getProjectnamebyid(this.projectnames);
          }
      }else if(this.projectlist.length ==0 && (this.projectnames && this.projectnames !=null)){
          this.getProjectnamebyid(this.projectnames);
      }
    },
    capitalizeFirstLetter(sentence) {
      let words = sentence.split(" ");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    onSelect(e) {
      this.file = e.target.files[0];
    },
    getDownloadLink(data) {
      if(data.data.length>0) {
        let payload = {
          documents:data.data,
          empcode : this.undt.userid,
          useremail: this.undt.username,
        };
        axios({
          method: "POST",
          url: '/api/imageupload/getdownloadlink',
          data: payload,
          'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            this.attachmentview = result.data;
          }
        });
      }
    },
    onUpload(){
      if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();  
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
                formData.append("imagefile", this.file[index]);
                maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail", this.undt.username);
            formData.append("moduletype", 'task');
            formData.append("fcount", parseInt(this.file.length));
            if (maxsize > 1000 * 1000 * 25) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 25mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.disblesavebtn=true
                return false; 
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading=false; 
                this.disblesavebtn=false
                if(result.data.status){
                      const bindinfo = result.data.data.map((file, index) => {
                        return {"link":file,"info":this.filedescp[index]}                
                    });
                    let jsonobj = {data:bindinfo}
                    this.attachmentnew = JSON.stringify(jsonobj);
                    if(this.taskmodule == 1) {
                      this.saveBusinessDocuments(bindinfo)
                    }
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.attachment=null
                }
            }).catch(e => {
            this.displayError(e)
          })
        }else{
              this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Select image/docs/videos',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.attachment=null
        }
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.attachment=null
      }
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD-MM-YYYY");
    },
    getStatusConfigList(pid, status) {
      if(this.statusconfig){
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: pid,
            trackername: 'Incident',
            currStatus: status,
        };
        axios({
            method: "POST",
            url: '/api/testcase/getStatusConfigList',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            if(result.data.errorCode == 0){
                this.search.statusopts = result.data.data.rows;
            } else {
                this.search.statusopts = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
  getIncidentTypeConfigLists(pid, incTypes) {
    let payload = {
        empcode: this.undt.userid,
        useremail: this.undt.username,
        projectmoduleid: pid,
        trackername: 'Incident Type',
        currStatus: incTypes,
    };
    axios({
        method: "POST",
        url: '/api/testcase/getINCTypesConfigList',
        data: payload,
        'headers':{'authorization':this.tokendata}
    }).then((result) => {
        if(result.data.errorCode == 0){
          let slist = [];
            result.data.data.rows.forEach((rec) => {
                if(Object.values(rec.statusconfig).length>0){
                    Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                        if(statusnames.length>0) {
                            statusnames.forEach(val => {
                                if(!slist.some(sdata => sdata.id === val)){
                                    slist.push({ id: val, label: val });
                                }
                            });
                        }
                    });
                }
            })
            slist.sort((a, b) => a.label.localeCompare(b.label));
            this.search.incTypesopts = slist;
        } else {
            this.search.incTypesopts = [];
        }
    }).catch(e => {
        this.displayError(e)
    });
  },
  },
  filters: {
    basename: function (value) {
      if (!value) return "";
      return value.substr(value.lastIndexOf("/") + 1);
    },
  },
};
</script>

<style scoped>

.click-zoom input[type=checkbox] {
  display: none;
}

.click-zoom img {
  /* margin: 100px; */
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.click-zoom input[type=checkbox]:checked~img {
  transform: scale(10);
  cursor: zoom-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}</style>
