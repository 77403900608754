<template>
  <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left col-md-9 col-12 mb-1">
          <div class="row breadcrumbs-top">
            <div class="col-12 px-0">
              <h3 class="content-header-title float-left mb-0">Test Details</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link :to="{ name: 'Exam Result', params: { search: true }}">Test Result List</router-link>
                  </li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">View Test</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" >
          <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
            <div class="card-header py-1 mb-2" style="cursor:pointer; border-bottom:1px solid #eeee">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-8">
                    <div class="avatar bg-light-primary rounded d-inline-flex">
                      <div class="avatar-content">
                        <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                      </div>
                    </div>
                    <h3 class="card-title ml-2 d-inline-flex text-primary">Test Id: {{this.editRowData.id}}</h3>
                  </div>
                </div>
              </div>
          </div>
            <div class="card-body">
              <div class="row match-height">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                            <pen-tool-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pen-tool-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">User Name</h6>
                          <small>{{editRowData.empname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Exam Date</h6>
                          <small>{{dateMonthFormat(editRowData.examdate ? dateMonthFormat(editRowData.examdate):'NA')}}</small>
                        </div>
                      </div>
                    </div>
                  </div>                                                     
                </div>
              </div>              
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">User Emailid</h6>
                          <small>{{editRowData.emailid}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item" style="display:none;">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                            <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Start Time</h6>
                          <small>{{editRowData.starttime ? editRowData.starttime:'--'}}</small>
                        </div>
                      </div>
                    </div>
                  </div>                                                                
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                            <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Assessment Title</h6>
                          <small>{{editRowData.assessmenttitle}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item" style="display:none;">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">End Time</h6>
                          <small>{{editRowData.endtime ? editRowData.endtime:'--'}}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Add Question Answer Start -->
      <section class="pb-1" id="card-navigation modern-horizontal-wizard">
        <div class="card border-warning" >
          <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
              <div class="card-header py-0 px-0" style="cursor:pointer">
                <div class="container-fluid  table-responsive px-0">
                    <table class="table table-sm table-bordered all_table_stickys" aria-describedby="mydesc">
                      <thead>
                          <tr>
                              <th scope="col" class="first-col-sticky py-1 align-middle">SR#</th>
                              <th scope="col" class="second-col-sticky align-middle" style="width:250px">Question Title</th>
                              <th scope="col" class="align-middle">Option A</th>
                              <th scope="col" class="align-middle">Option B</th>
                              <th scope="col" class="align-middle">Option C</th>
                              <th scope="col" class="align-middle">Option D</th>
                              <th scope="col" class="align-middle">Given Answer</th>
                              <th scope="col" class="align-middle">Correct Answer</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="(editRowDatas, key) in companymasterlist" :key="key">
                              <td class="first-col-sticky py-1">{{ key+1 }}</td>
                              <td class="second-col-sticky">{{ editRowDatas.questiontitle }}</td>
                              <td>{{ editRowDatas.optiona }}</td>
                              <td>{{ editRowDatas.optionb }}</td>
                              <td>{{ editRowDatas.optionc }}</td>
                              <td>{{ editRowDatas.optiond }}</td>
                              <td>{{ editRowDatas.answergiven ? editRowDatas.answergiven: '-'}}</td>
                              <td>{{ editRowDatas.answer }}</td>                            
                          </tr>
                      </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>

.issue-view-title {
  background-color: #f8f9fa;
  padding: 6px 24px;
  font-weight: bold;
  margin-right: 20px;
}
.card .card-header{
  padding: 0.8rem;
}
.createbtnparentdiv{
  padding-right: 0% !important;
}
.displayblk{
  display:block !important;
}
.createbtn{
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
.tbl {
  margin-left: 0 !important;
}

.disc p:last-child{
  margin-bottom: 0;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import { PaperclipIcon,WatchIcon,EditIcon, MinusSquareIcon, Trash2Icon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon, AlertCircleIcon,UserPlusIcon,ZoomInIcon,XIcon} from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import presignurl from '../getPresignurl.vue';
import profilepic from '../profilepic.vue';
import commonMethods from '../../utils/commonMethods';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
  name:'IssueList',
  components:{
    VueTimepicker,
    Loading,WatchIcon,PaperclipIcon,profilepic,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Trash2Icon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    DownloadIcon,
    Edit2Icon,
    PlusSquareIcon,
    ClipboardIcon,
    UploadCloudIcon, MinusSquareIcon, CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon,
    AlertCircleIcon,
    UserPlusIcon,
    ZoomInIcon,
    XIcon,
    ckeditor: CKEditor.component,
    presignurl
  },    
  mixins: [ commonMethods ],
  data() {
    return {
      isRequestActiveView: true,
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      editRowData:[],
      TimeFormat: apiUrl.TimeFormat,
      isAddState:false,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      card_content:'card-content',
      collapse:'collapse',
      card:'card',
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|', 'bold',
            'italic', '|', 'bulletedList',
            'numberedList', '|', 'insertTable', '|',
            'undo', 'redo', '|',
          ],
        },
      },
      tokendata:null,
      loginusername:'',
      id: null
    }
  },
  mounted(){
    window.addEventListener('storage', function(event){
        if (event.key == 'logout-mee') { 
            window.location.reload();
        }
    });
    let userdata = window.localStorage.getItem('userdata');
    if(userdata){
      userdata = JSON.parse(userdata)
      this.undt=userdata
      this.loginusername=userdata.username
      this.loginuscondition = userdata.userid
      this.tokendata = window.localStorage.getItem('token');
      this.getUserById(this.$route.query.id)
    }
  },
  methods:{
    getUserById(id) {
      this.isLoading = true
      let apiUrl = "api/assessment/getUserById"
      this.input = {
      id : id,
      useremail : this.undt.username,
      empid : parseInt(this.undt.userid),
      empcode: this.undt.userid
      }
      axios({
        method: "POST",
        url: apiUrl,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.editRowData = result.data.data;
          this.gettestById()
          let tempdata = this.editRowData[0]
          this.editRowData = tempdata
        }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }
          else {
          this.editRowData = []
        }
      }).catch(e => {
            this.displayError(e)
      });
    },
    gettestById() {
      this.isLoading = true
      let apiUrl = "api/assessment/getTestById"
      this.input = {
      id :this.editRowData[0].id,
      useremail : this.undt.username,
      empid :this.editRowData[0].empcode,
      empcode: this.undt.userid
      // empid : parseInt(this.undt.userid),
      }
      axios({
        method: "POST",
        url: apiUrl,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.companymasterlist = result.data.data;
        } else {
          this.companymasterlist = []
        }
      }) .catch(e => {
          this.displayError(e)
        })
    },	
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
  },
}
</script>
