<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-md-9 mobile_margin-px-0_00">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1  mobile_width-86" >Search User Wise Performance Report </h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Employee Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Employee Name" :multiple="true" :clear-on-select="true" v-model="search.empname" v-on:search-change="getallemployeelistnewapi" :options="employeenamelist"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Reporting Manager Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="mngrlist" v-if="pagefeatures.reportingmanager" :disabled="true"/>

                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" v-on:search-change="getallmanagerlistnewapi" :options="mngrlist" v-else/>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-0 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1 mobile_margin-px-2" @click="getprojectlist(search)"> <i class="custom-class las la-at" style="font-size: 15px;"></i> <span>Mail Me</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1 mobile_margin-px-2" @click="resetRecords()"><x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                      <!-- <button type="move" class="btn btn-relief-primary mt-2 mobile_margin-px-2" @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span></button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll" style="display:none;">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header border-bottom p-2">
                      <h4 class="card-title">
                        User Wise Performance Report 
                      </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky" v-if="showtabledata" >
                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="align-middle first-col-sticky" style="min-width:100px">Emp id</th>
                                      <th scope="col" class="align-middle text-left second-col-sticky-emp" style="min-width: 160px;">Emp Name</th>
                                      <th scope="col" class="align-middle" style="min-width: 160px;">Designation</th>
                                      <th scope="col" class="align-middle" style="min-width: 160px;">Reporting Manager</th>
                                      <th scope="col" class="align-middle">Total Exp</th>
                                      <th scope="col" class="align-middle">FC Exp</th>
                                      <th scope="col" class="align-middle">Total Projects</th>
                                      <th scope="col" class="align-middle">Total Tasks</th>
                                      <th scope="col" class="align-middle">Red Tasks</th>
                                      <th scope="col" class="align-middle">Green Tasks</th>
                                      <th scope="col" class="align-middle">Blue Tasks</th>
                                      <th scope="col" class="align-middle">Total Issues</th>
                                      <th scope="col" class="align-middle">Total Closed Issues</th>
                                      <th scope="col" class="align-middle">Total Open Issues</th>
                                      <th scope="col" class="align-middle">Total Incidents</th>
                                      <th scope="col" class="align-middle">Total Closed Incident</th>
                                      <th scope="col" class="align-middle">Total Open Incident</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(data, index) in companymasterlist"
                                    >
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)">                                        
                                        <td :class="'uppercasetext font-weight-normal first-col-sticky unique'+index+1+currPageNum*pagelimit">
                                          {{data.empid}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal second-col-sticky-emp">{{data.empname}}</td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{decryptText(data.designation)}}   
                                        </td>
                                         <td class="capitalisetext text-left font-weight-normal">
                                          {{data.reportingmanager.trim() !=''? data.reportingmanager:'NA' }}   
                                        </td>
                                        <td class="text-nowrap font-weight-normal" v-if="data.totalexpyear && data.totalexpmonth">
                                          {{data.totalexpyear}}.{{data.totalexpmonth}} yrs
                                        </td>
                                        <td v-if="!data.totalexpyear && !data.totalexpmonth">
                                          -
                                        </td>
                                        <td class="text-nowrap font-weight-normal" v-if="data.totalexpyear && !data.totalexpmonth">
                                            {{data.totalexpyear}}yrs
                                        </td>
                                        <td v-if="!data.totalexpyear && data.totalexpmonth">
                                            {{data.totalexpyear}}months
                                        </td>
                                        <td class="text-nowrap font-weight-normal" v-if="data.fcexpyear && data.fcexpmonth">
                                            {{data.fcexpyear}}.{{data.fcexpmonth}} yrs
                                        </td>
                                        <td v-if="!data.fcexpyear && !data.fcexpmonth">
                                            -
                                        </td>
                                        <td class="text-nowrap font-weight-normal" v-if="data.fcexpyear && !data.fcexpmonth">
                                            {{data.fcexpyear}}yrs
                                        </td>
                                        <td v-if="!data.fcexpyear && data.fcexpmonth">
                                            {{data.fcexpmonth}}months
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.totalproject}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.opentaskcount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.redtaskcount}}
                                        </td>
                                        <td class="title font-weight-normal" >
                                          {{data.greentaskcount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.bluetaskcount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.totalissuecount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.closedissuecount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.openissuecount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.totalincidentcount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.closedincidentcount}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.openincidentcount}}
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>      
    </div>
    <div class="modal fade" id="technologypopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
       <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h4 class="modal-title" id="myModalLabel16">Technologies</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <div class="table-responsive">
                      <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                          <thead>
                              <tr>
                                  <th scope="col" class="srno">Sr#</th>
                                  <th scope="col">Skills</th>
                                  <th scope="col">Years Of Experience</th>
                              </tr>
                          </thead>
                          <tbody>
                              <template v-for="(data,index) in technologies">        
                                  <tr v-bind:key="index">
                                      <td class="srno">{{index+1}}</td>  
                                      <td>
                                          <span class="font-weight-bold">{{data.skills}}</span>
                                      </td>
                                      <td><span class="font-weight-bold">{{data.yearofexp}}</span></td>
                                  </tr>
                              </template>
                              <template v-if="technologies.length==0 && isresponce">
                                  <tr><td colspan="3" style="text-align: center;">No record found</td></tr>
                              </template>
                          </tbody>
                      </table>
                  </div>
              </div>    
          </div>
      </div>
    </div>
  </div>
  
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import { EyeIcon,SearchIcon,XIcon,ExternalLinkIcon,LinkIcon } from "vue-feather-icons";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    LinkIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      search: {
        searchProjectname:null,
        empname:null,
        mngrname:null
      },
      mailme:'',
      rowdata:null,
      emplist:[],
      mngrlist:[],
      projectnameList:[],
      isLoading: false,
      fullPage: true,
      showprojectdata: [],
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      projectcurrentstate: "local",
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:true,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      technologies:[],
      companymasterlist:[],
      tokendata:null,
      searchOffset: 1,
      searchSession: false,
      isresponce:false,
      employeenamelist: [],
    };
  },
  created() {
    this.pagefeatures = {}
    if(window.localStorage.getItem('pagefeatures')){
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/reports/emplprojtaskreport') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        if(this.pagefeatures.reportingmanager){
          this.getManagerList();
          this.search.mngrname =[parseInt(this.undt.userid)]
          rowdata.mngrname = [parseInt(this.undt.userid)]
        }
        // this.getprojectlist(rowdata);
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getManagerList() {
      this.pageoffset=1
      axios({
        'method': 'POST',
        'url': 'api/searching/getmanagerlist',
        'headers':{'authorization':this.tokendata},
        'data': {
          mngrid:null,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.mngrlist = result.data.data;
          let decryptdepartmenttext = this.mngrlist.map(items =>{

            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        } else {
          this.mngrlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    getTechnology: function(data) {
      if(data.empcode) {
       this.isLoading = true;
       this.technologies = [];
        let url = "api/reports/getemptechnology";
        this.input = {
          empcodee: data.empcode,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.status == true) {
            this.technologies = result.data.data;
            $('#technologypopup').modal('show');
          } else {
            $('#technologypopup').modal('hide');
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          })
          }
        }).catch(e => {
          this.displayError(e)
        });
        }else { 
          $('#technologypopup').modal('hide');         
         Swal.fire({
              title: "Failed",
              text: "Employee code required",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          })
        }
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    // selectproject(state, value) {
    //   this.pageoffset=1
    //   this.search.searchProjectname = state;
    // }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    resetRecords: function () {
      let rowdata={}
      this.search.empname=null
      if(!this.pagefeatures.reportingmanager){
        this.search.mngrname=null;
        this.mngrlist = [];
      }else{
        rowdata.mngrname = [parseInt(this.undt.userid)]
      }
      this.searchSession = false
      this.searchOffset = 1
      this.employeenamelist = [];
      this.getprojectlist(rowdata);
    },
    getprojectlist(rowdata) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        isFilterSelect: 0,
        isFilterPayload:{empname:this.search.empname,mangerid:this.search.mngrname},
		    empid: this.undt.userid,
        empcode: this.undt.userid,
      };
       if(rowdata && rowdata.hasOwnProperty('mngrname') && rowdata.mngrname) {
        this.searchSession = true
        this.input.offset = this.searchOffset
      } else {
        this.searchSession = false
        this.searchOffset = 1
      }
      let url = "api/reports/emplprojtaskreport";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
          Swal.fire({
            title: "Success!",
            text: "Export file link generated on your email",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
        }
        
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      if(this.searchSession) {
        this.searchOffset = pageNum;
      }
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    setSelected(value) {
      this.selected = value;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        isFilterPayload:{empname:this.search.empname,mangerid:this.search.mngrname},
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: `api/Reports/empprojecttaskreport`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'userwiseperformance'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Empid, Emp Name,Designation,Reporting Manager,Total Exp,FC Exp,Total Projects,Total Tasks,Red Tasks,Green Tasks,Blue Tasks,Total Issues,Total Closed Issues,Total Open Issues,Total Incidents,Total Closed Incident,Total Open Incident' + '\r\n'
          }
        var row = ''
       for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let empid = index.hasOwnProperty('empid') ? index.empid : '';
          let empname = index.hasOwnProperty('empname') ? index.empname : '';
          let designation = index.hasOwnProperty('designation') ? index.designation : '';
          let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager : '';
          let totalexp = index.hasOwnProperty('totalexp') ? index.totalexp : '';
          let fcexp = index.hasOwnProperty('fcexp') ? index.fcexp : '';
          let totalproject = index.hasOwnProperty('totalproject') ? index.totalproject : '';
          let opentaskcount = index.hasOwnProperty('opentaskcount') ? index.opentaskcount : '';              
          let redtaskcount = index.hasOwnProperty('redtaskcount') ? index.redtaskcount : '';
          let greentaskcount = index.hasOwnProperty('greentaskcount') ? index.greentaskcount : '';
          let bluetaskcount = index.hasOwnProperty('bluetaskcount') ? index.bluetaskcount : '';
          let totalissuecount = index.hasOwnProperty('totalissuecount') ? index.totalissuecount : '';
          let closedissuecount = index.hasOwnProperty('closedissuecount') ? index.closedissuecount : '';
          let openissuecount = index.hasOwnProperty('openissuecount') ? index.openissuecount : '';
          let totalincidentcount = index.hasOwnProperty('totalincidentcount') ? index.totalincidentcount : '';
          let closedincidentcount = index.hasOwnProperty('closedincidentcount') ? index.closedincidentcount : '';
          let openincidentcount = index.hasOwnProperty('openincidentcount') ? index.openincidentcount : '';
            row += '"' + srno + '",',
            row += '"' + empid + '",',
            row += '"' + empname + '",',
            row += '"' + this.decryptText(designation) + '",',
            row += '"' + reportingmanager + '",',
            row += '"' + totalexp + '",',
            row += '"' + fcexp + '",',
            row += '"' + totalproject + '",',
            row += '"' + opentaskcount + '",',
            row += '"' + redtaskcount + '",',
            row += '"' + greentaskcount + '",',
            row += '"' + bluetaskcount + '",',
            row += '"' + totalissuecount + '",',
            row += '"' + closedissuecount + '",',
            row += '"' + openissuecount + '",',
            row += '"' + totalincidentcount + '",',
            row += '"' + closedincidentcount + '",',
            row += '"' + openincidentcount + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'userwiseperformance'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });      
    }
},
getallemployeelistnewapi(node) {
  if (node && node.length>=3) {
    this.pageoffset=1
    axios({
      'method': 'POST',
      'url': 'api/searching/getallemployeelistnewapi',
      'headers':{'authorization':this.tokendata},
      'data': {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        empname: node.toLowerCase(),
      }
    }).then(result => {
      this.isLoading = false
      if(result.data.errorCode == 0) {
        this.employeenamelist = result.data.data;
        let decryptdepartmenttext = this.employeenamelist.map(items =>{

          let tempemplabel1 = items.label;
          let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

          if(tempemplabel2 && tempemplabel2 != null){
            items.label = tempemplabel1 +'('+tempemplabel2 +')';
          }
          return items;
        })
      } else {
        this.employeenamelist = [];
      }
    }).catch(e => {
      this.displayError(e)
    })
  }
},
getallmanagerlistnewapi(node) {
  if (node && node.length>=3) {
    this.pageoffset=1
    axios({
      'method': 'POST',
      'url': 'api/searching/getallmanagerlistnewapi',
      'headers':{'authorization':this.tokendata},
      'data': {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        mngname: node.toLowerCase(),
      }
    }).then(result => {
      this.isLoading = false
      if(result.data.errorCode == 0) {
        this.mngrlist = result.data.data;
        let decryptdepartmenttext = this.mngrlist.map(items =>{

          let tempemplabel1 = items.label;
          let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

          if(tempemplabel2 && tempemplabel2 != null){
            items.label = tempemplabel1 +'('+tempemplabel2 +')';
          }
          return items;
        })
      } else {
        this.mngrlist = [];
      }
    }).catch(e => {
      this.displayError(e)
    })
  }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },

  },
};
</script>
