<template>
       
   <div id="personal-info-modern" class="content">
        <div class="container">
                <div class="d-flex justify-content-between align-items-center">
                <ul class="nav nav-tabs pt-1" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="posting-tab-job" data-toggle="tab" href="#postingLogic" aria-controls="postingLogic" role="tab" aria-selected="true">Posting Logic</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="sources-tab-job" data-toggle="tab" href="#sourcesJob" aria-controls="sourcesJob" role="tab" aria-selected="false">Sources</a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-pane active" id="postingLogic" aria-labelledby="posting-tab-job" role="tabpanel">
                    <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th class="align-middle" scope="col" style="min-width:200px;"> Select Post Type</th>
                                        <th class="align-middle" scope="col" style="min-width:200px;"> Display From<span class="required-field"></span> </th>
                                        <th class="align-middle" scope="col" style="min-width:200px;"> Display To<span class="required-field"></span></th>
                                        <th class="align-middle" scope="col"> Job Listing Status </th>
                                        <th class="align-middle" scope="col">Action</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data,index) in hrmspostingtype" v-bind:key="index">
                                        <td>
                                          <div class="form-group my-1">
                                            <div class="form-group mb-0">
                                                <input type="grade" id="grade" class="form-control" placeholder="A" aria-label="john.doe" v-model="hrmspostingtype[index].label" disabled/>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                        <div class="form-group mg-bot0 col-md-12  px-0 datePicker my-1">
                                            <div class="form-group form-groupjobposting">
                                            <date-picker v-model="hrmspostingtype[index].postingstartdate" placeholder="Select Date"   class="startdate" type="datetime" format="YYYY-MM-DD HH:mm" valueType="format" :disabled-date="BeforeTodaydate"></date-picker>
                                            
                                            <div class="errorinputmsg capitalisetext text-center" v-if="hrmspostingtype[index].postingstartdate==null">Display From Date Is Required</div>
                                           </div>
                                           </div>
                                        </td>
                                        <td>
                                        <div class="form-group mg-bot0 col-md-12 datePicker my-1 px-0">
                                          <div class="form-group form-groupjobposting">
                                            <date-picker  v-model="hrmspostingtype[index].postingenddate" placeholder="Select Date"   class="startdate" type="datetime" format="YYYY-MM-DD HH:mm" valueType="format" :disabled-date="notBeforeTodaydate"></date-picker>
                                           
                                            <div class="errorinputmsg capitalisetext text-center" v-if="hrmspostingtype[index].postingenddate==null">Display To Date Is Required</div>
                                          </div>
                                          </div>
                                        </td>
                                        <td class="text-center my-1">
                                            
                                            <span class="badge badge-pill badge-light-primary mr-1" style="cursor:pointer" v-model="hrmspostingtype[index].postingstatus.trim()" @click="changestatus(index,hrmspostingtype[index].postingstatus.trim())" v-if="hrmspostingtype[index].postingstatus.trim()=='INACTIVE'">{{hrmspostingtype[index].postingstatus.trim()}}</span>
                                            <span class="badge badge-pill badge-light-success mr-1" style="cursor:pointer" v-model="hrmspostingtype[index].postingstatus.trim()" @click="changestatus(index,hrmspostingtype[index].postingstatus.trim())" v-else>{{hrmspostingtype[index].postingstatus.trim()}}</span>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-icon btn-icon my-1 rounded-circle btn-flat-success waves-effect" @click="restrows(hrmspostingtype[index])">
                                            <em data-feather='refresh-cw'></em>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="w-auto ml-auto text-right mt-0 mb-0 p-1">
                                <span class="required-field"> All fields marked with asterisk are required.</span>
                            </div>
                        </div>
                        <div class="buttons_hrms_view text-right px-0 mt-0">
                            <button class="btn btn-relief-secondary btn-prev10 ml-1" @click="prev11">
                                <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                <span class="align-middle d-sm-inline-block d-none">Previous</span>
                            </button>
                            <button class="btn btn-relief-success btn-next11 btn-next16 ml-1" @click="savedata(hrmspostingtype,0)">
                                <span class="align-middle d-sm-inline-block d-none">Save as Draft</span>
                                <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                            </button>
                            <button class="btn btn-relief-primary ml-1" @click="savedata(hrmspostingtype,1)">
                                <span class="align-middle d-sm-inline-block d-none">Save & Continue</span>
                                <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                            </button>
                        </div>
                </div>
                <div class="tab-pane" id="sourcesJob" aria-labelledby="sources-tab-job" role="tabpanel">
                    <div class="col-sm-12">
                    <p>
                        Dragée jujubes caramels tootsie roll gummies gummies icing bonbon. Candy jujubes cake cotton candy.
                        Jelly-o lollipop oat cake marshmallow fruitcake candy canes toffee. Jelly oat cake pudding jelly beans
                        brownie lemon drops ice cream halvah muffin. Brownie candy tiramisu macaroon tootsie roll danish.
                    </p>
                    <p>
                        Croissant pie cheesecake sweet roll. Gummi bears cotton candy tart jelly-o caramels apple pie jelly
                        danish marshmallow. Icing caramels lollipop topping. Bear claw powder sesame snaps.
                    </p>
                    </div>
                    <div class="buttons_hrms_view text-right px-0 mt-0">
                        <button class="btn btn-relief-secondary ml-1 ">
                            <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                            <span class="align-middle d-sm-inline-block d-none">Previous</span>
                        </button>
                        <button class="btn btn-relief-success ml-1">
                            <span class="align-middle d-sm-inline-block d-none">Save as Drafts</span>
                            <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                        </button>
                        <button class="btn btn-relief-primary ml-1">
                            <span class="align-middle d-sm-inline-block d-none">Save & Continue</span>
                            <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                        </button>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
    import axios from 'axios'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import apiUrl from '../../../constants'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import VueElementLoading from 'vue-element-loading';
    import commonMethods from '../../../utils/commonMethods';
    import moment from 'moment'

export default({
    name:'JobPostings',
     components:{
        DatePicker,
        Loading,
        VueElementLoading
     },
     props:['globaljdid'],
     mixins: [ commonMethods ],
     data() {
        return {
            masterhrmspostingtype: apiUrl.hrmspostingtype,
            hrmspostingtype: apiUrl.hrmspostingtype,
            saveddata: [],
            tokendata:null,
        }
    },
    mounted(){
        $(function () {
            'use strict';
            var bsStepper = document.querySelectorAll('.bs-stepper'),
                select = $('.select2'),
                modernWizard = document.querySelector('.modern-wizard-example')
            // select2
            select.each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                placeholder: 'Select value',
                dropdownParent: $this.parent()
                });
            });
            if (typeof modernWizard !== undefined && modernWizard !== null) {
                var modernStepper = new Stepper(modernWizard, {
                linear: false
                });
                $(modernWizard)
                .find('.btn-next16')
                .on('click', function () {
                    modernStepper._currentIndex=1
                    modernStepper.next();
                });
                $(modernWizard)
                .find('.btn-prev11')
                .on('click', function () {
                    modernStepper._currentIndex=0
                    modernStepper.previous();
                });
            }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            if (this.globaljdid && this.globaljdid!=0 || this.globaljdid!=null) {
                this.getsavedpostingdatabyposttype(this.globaljdid);
            }
        }
    },
    methods:{
        restrows(data) {
            data.postingenddate=null,
            data.postingstartdate=null,
            data.postingstatus='INACTIVE'
        },
        savedata(objThis,isactive){
            if(this.globaljdid && this.globaljdid !=0){
                let valid = this.validateForm(objThis);
                if (valid) {
                    this.isLoading = true;
                    this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    trackername: 'jobposting',
                    jdid: this.globaljdid,
                    fieldvalues: this.hrmspostingtype,
                    isactive: isactive
                    }
                    let apiURL='api/hrms/savejobpostingdata'
                    axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                         this.getsavedpostingdatabyposttype(this.globaljdid)
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                          window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                        })
                    }
                    }).catch(e => {
                 this.displayError(e)
                })
                }
            }else{
                Swal.fire({
                    title: "Failed",
                    text: 'Job Details Id Is Required',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        },
        validateForm(){
            let isValid=true;
            for (let i = 0; i < this.hrmspostingtype.length; i++) {
                if ((this.hrmspostingtype[i].postingenddate==null || this.hrmspostingtype[i].postingenddate=='' || this.hrmspostingtype[i].postingenddate==undefined)) {
                    isValid=false
                }
                if ((this.hrmspostingtype[i].postingstartdate==null || this.hrmspostingtype[i].postingstartdate=='' || this.hrmspostingtype[i].postingstartdate==undefined)) {
                    isValid=false
                }
            }
            return isValid
        },
        getsavedpostingdatabyposttype(){
            this.isLoading = true;
            this.inputfield= {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                trackername: 'jobposting',
                jdid: this.globaljdid,
            }
            axios({
                'method': 'POST',
                'url': 'api/hrms/getsavedpostingdatabyposttype',
                'data': this.inputfield,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                this.saveddata = result.data.data.rows
                if (this.saveddata && this.saveddata.length>0) {
                    let hrmspostingtype =[]
                    hrmspostingtype=this.hrmspostingtype

                    for (let j = 0; j < hrmspostingtype.length; j++) {

                        for (let k = 0; k < this.saveddata.length; k++) {
                            if (this.saveddata[k].id.includes(hrmspostingtype[j].id)) {
                                hrmspostingtype[j].postingstartdate=this.dateMonthFormat(this.saveddata[k].postingstartdate)
                                hrmspostingtype[j].postingenddate=this.dateMonthFormat(this.saveddata[k].postingenddate)
                                hrmspostingtype[j].postingstatus=this.saveddata[k].postingstatus
                            }
                        }
                    }
                    this.hrmspostingtype=[]
                    this.hrmspostingtype=hrmspostingtype
                }
            }else{
                this.saveddata=[]
            }
        }).catch(e => {
            this.displayError(e)
        });
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('YYYY-MM-DD HH:mm');
        },
        BeforeTodaydate: function (date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        notBeforeTodaydate: function (date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        changestatus(idx,statusx){
            if (statusx=='INACTIVE') {
                this.hrmspostingtype[idx].postingstatus = 'ACTIVE'
            }else{
                this.hrmspostingtype[idx].postingstatus = 'INACTIVE'
            }
        },
        prev11(){
           $('.btn-prev11').click();
        }
    }

})
</script>


