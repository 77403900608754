<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper mobile_view_contentss">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-2">Micro Task Master</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                  <router-link :to="{ name: 'TaskList', params: { search: true }}"
                  >Micro Task List</router-link
                  >
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Micro Task</li>
              </ol>
            </div>
          </div>
        </div>
        <div v-if="pagefeatures.createtask" class="card border-warning" >
          <div v-bind:class="[{ show: isRequestActive }, card_content, collapse]" >
            <div class="card-header py-1 px-2" style="background-color: #f1f2f3;">
                            <h3 class="card-title">
                                Create Micro Task
                            </h3>
                        </div>
                        <div class="card-body pt-1">
              <div v-if="!pagefeatures.createadmintask && !this.isRedirectedFromBtmView" class="table-responsive" style="overflow-x: auto!important;">
                <table class="table table-sm dataTableListing mb-1" aria-describedby="Task Master" v-if="!isredirectedfromPMO && !enabledtasklogfields">
                  <thead>
                    <tr>
                      <th scope="col">Project Name</th>
                      <th scope="col">Module Name</th>
                      <th scope="col">Application Module Name</th>
                      <th scope="col">Section</th>
                      <th scope="col">Functionality</th>
                      <th scope="col">Workstep</th>
                      <th scope="col">Business Task</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{this.taskdefine.view.projectname}}</td>
                      <td>{{this.taskdefine.view.projectmodulename}}</td>
                      <td>{{this.taskdefine.view.applicationmodulename}}</td>
                      <td>{{this.taskdefine.view.sectionname}}</td>
                      <td>{{this.taskdefine.view.functionlityname}}</td>
                      <td>{{this.taskdefine.view.workstepname}}</td>
                      <td>{{this.taskdefine.view.taskname}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-sm dataTableListing mb-1" aria-describedby="Task Master" v-if="isredirectedfromPMO=='pmocreate'">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center">Project Name</th>
                      <th scope="col" class="text-center">Business Task</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">{{this.taskdefine.view.projectname?this.taskdefine.view.projectname:'NA'}}</td>
                      <td class="text-center">{{this.taskdefine.view.taskname?this.taskdefine.view.taskname:'NA'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <div v-if="(this.tocheckParentModuleOfTAsk && this.isRedirectedFromBtmView ==true)" class="mb-1" >
                        <div class="table-responsive" style="overflow-x: inherit !important;">
                            <table class="table table-sm dataTableListing table-hover table-striped table-bordered" aria-describedby="Task Master">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center">Business Task Name</th>
                                        <th scope="col" class="text-center">Project Name</th>
                                        <th scope="col" class="text-center">Project Module</th>
                                        <th scope="col" class="text-center">Task Level</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">{{this.tasktype.businesstaskname}}</td>
                                        <td class="text-center">{{this.tasktype.projectnameist}}</td>
                                        <td class="text-center">{{this.tasktype.projectmodulenamenameist}}</td>
                                        <td class="text-center">{{this.getmodulename(2)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group ">
                      <label for="taskdefinename">Micro Task Name</label>
                      <input type="text" id="taskdefinename" class="form-control" name="taskdefinename" placeholder="Micro Task Name" v-model="taskdefine.taskdefinename" v-bind:class="{'form-control': true, '': !validText(taskdefine.taskdefinename) && nameBlured }" v-on:blur="nameBlured = true"/>
                      <div class="errorinputmsg" v-if="!validText(taskdefine.taskdefinename) && nameBlured">Micro Task Name Required must be length of 5 char at least</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group ">
                      <label for="tasktype">Micro Task Type</label>
                      <div class="input-group input-group-merge" >
                        <treeselect placeholder="Select Micro Task Type" :options="tasktypelist" @select="seldefinetypedata" v-model="taskdefine.type" v-on:close="validateForm('t18', $event)" v-if="isredirectedfromPMO=='pmocreate'" :disabled="true"/>
                        <treeselect placeholder="Select Micro Task Type" :options="tasktypelist" @select="seldefinetypedata" v-model="taskdefine.type" v-on:close="validateForm('t18', $event)" v-else/>
                      </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.type">{{ this.errors.taskdefine.type }}</div>
                    </div>
                  </div>
                  <div v-if="this.enabledtasklogfields==true" class="col-md-6">
                    <div class="form-group mg-bot0">
                        <label for="appmdldesc">Technology Name</label>
                        <input class="form-control appmdldesc" v-model="taskdefine.technology" id="Technology" placeholder="Technology Description "
                        @close="validateForm('t18',$event)" />
                        <div class="errorinputmsg" v-if="this.errors.taskdefine.technology">{{this.errors.taskdefine.technology}}</div>
                    </div>
                </div>
                  <div v-if="this.enabledtasklogfields==true" class="col-md-6" >
                    <div class="form-group ">
                      <label for="appmdldesc">Activity Name</label>
                      <div class="input-group input-group-merge">
                        <treeselect class="capitalisetext" placeholder="Select activity name" v-model="taskdefine.activity" :options="activityhoursopts" @select="selActivityName"  @close="validateForm('t18',$event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.activity" >{{this.errors.taskdefine.activity}}</div>
                    </div>
                  </div>
                 
                  <div  class="col-md-6">
                    <div class="form-group ">
                      <label for="projectmdllistdd">Micro Task Estimates</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Micro Task Estimates" v-model="taskdefine.estimateshours" :options="estimateshours" @select="selestimateshours" @close="validateForm('t18',$event)"/>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.estimateshours">{{ this.errors.taskdefine.estimateshours }}</div>
                    </div>
                  </div>

                  <div v-if="this.enabledtasklogfields==true && taskdefine.estimateshours != null" class="col-md-2" >
                    <div class="form-group ">
                      <label for="tasktype">Estimated Hours</label>
                      <div class="input-group input-group-merge" >
                        <treeselect placeholder="Estimated Hours" :options="ahourslist" @input="selahour" v-model="taskdefine.ahour" v-on:close="validateForm('t18', $event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.ahour" >{{this.errors.taskdefine.ahour}}</div>
                    </div>
                  </div>
                  <div v-if="this.enabledtasklogfields==true && taskdefine.estimateshours != null" class="col-md-2" >
                    <div class="form-group ">
                      <label for="tasktype"> </label>
                      <div class="input-group input-group-merge" >
                        <treeselect placeholder="Estimated Minutes" :options="aminutelist" @input="selamin" v-model="taskdefine.amin" v-on:close="validateForm('t18', $event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.amin" >{{this.errors.taskdefine.amin}}</div>
                    </div>
                  </div>
                                        
                  <div class="col-md-6" v-if="this.isRedirectedFromBtmView == false">
                    <div class="form-group ">
                      <label for="taskdefinetaskleveldd">Micro Task Level</label>
                      <treeselect :disabled="isProjectLevelSelected" placeholder="Select Micro Task Level" :options="tasklevel" @select="seltaskdftypedata" v-model="taskdefine.level" v-on:close="validateForm('t18', $event)"/>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.level">{{ this.errors.taskdefine.level }}</div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group ">
                      <label for="projectmdllistdd">Micro Task Status</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Micro Task Status" v-model="taskdefine.status" :options="taskdefine.statuslist" @input="seltaskstatus" @close="validateForm('t18',$event)" :disabled="openstatue"/>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.status">{{ this.errors.taskdefine.status }}</div>
                    </div>
                  </div>
                  
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Assigned To </label>
                      <div class="input-group input-group-merge">
                        <treeselect placeholder="Select Assigned To" class="applicationdd capitalisetext" v-model="taskdefine.allottedto" :options="taskdefine.allottedtolist" @input="selallottedto" v-on:close="validateForm('t18', $event)" v-if="isredirectedfromPMO=='pmocreate'" :disabled="true"/>
                        <treeselect :placeholder="assignedtoplaceholder" class="applicationdd capitalisetext" v-model="taskdefine.allottedto" :options="taskdefine.allottedtolist" @input="selallottedto" v-on:close="validateForm('t18', $event)" v-else-if="taskdefine.type == 28"/>
                        <treeselect :placeholder="assignedtoplaceholder" class="applicationdd capitalisetext" v-model="taskdefine.allottedto" :options="taskdefine.allottedtolist" @input="selallottedto" v-on:close="validateForm('t18', $event)" v-on:search-change ="getAssignEmptoProject" v-else/>
                      </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.allottedto">{{ this.errors.taskdefine.allottedto }}</div>
                    </div>
                  </div>
                  
                  <!--Prshant sir REquiremt -->
                  <div class="col-md-6" v-if="this.isRedirectedFromBtmView == false">
                   <div v-if="pagefeatures.createadmintask || this.enabledtasklogfields==true" >
                    <div class="form-group ">
                      <label for="">Project Name</label>
                      <div class="input-group input-group-merge">
                        <treeselect v-if="!this.enabledtasklogfields && isredirectedfromPMO!='pmocreate' && (taskdefine.projectid == null || !taskdefine.isredirected)" class="projroleassprojectdd" placeholder="Enter min 3chars to search project name" v-on:search-change="projectChange" :options="taskdefine.projectlist" @input="selprojdata" v-model="taskdefine.projectid" v-on:close="validateForm('t18', $event)"/>
                        <treeselect v-else-if="isredirectedfromPMO=='pmocreate'" placeholder="Select project name"  disabled  :options="taskdefine.projectlist" v-model="taskdefine.projectid"/>
                        <treeselect type="text" placeholder="Select project name" disabled v-else :options="taskdefine.projectlist" v-model="taskdefine.projectid"/>
                      </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.projectid">{{ this.errors.taskdefine.projectid }}</div>
                    </div>
                  </div>
                  </div>
                  <div class="col-md-6" v-if="this.isRedirectedFromBtmView == false">
                  <div v-if="pagefeatures.createadmintask || this.enabledtasklogfields==true">
                    <div class="form-group ">
                      <label for="businessstaskmaster">Business Task Name</label>
                      <div class="input-group input-group-merge">
                        <treeselect v-if="!this.enabledtasklogfields && isredirectedfromPMO!='pmocreate' && (taskdefine.businesstype == null || !taskdefine.isredirected) " placeholder="Select Business Task Name" :options="taskdefine.businesstasklist" @input="seldefinebusinessdata" v-model="taskdefine.businesstype" v-on:close="validateForm('t18', $event)"/>
                        <treeselect v-else-if="isredirectedfromPMO=='pmocreate'" :options="taskdefine.businesstasklist" v-model="taskdefine.businesstype"  :disabled="true"/>
                        <treeselect v-else disabled :options="taskdefine.businesstasklist" v-model="taskdefine.businesstype" />
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.businesstype">{{ this.errors.taskdefine.businesstype }}</div>
                  </div>
                  </div>
                  <div class="col-md-6" v-if="this.isRedirectedFromBtmView == false">
                  <div v-if="pagefeatures.createadmintask || this.enabledtasklogfields==true">
                    <div class="form-group ">
                      <label for="applicationtxt">Application Name</label>
                      <div class="input-group input-group-merge">
                        <treeselect v-if="(this.selectedLevel >= 3 || this.selectedLevel == 2) && (taskdefine.application == null || !taskdefine.isredirected) " id="appinp" placeholder="Select Application Name" class="applicationdd" :options="taskdefine.applicoptions" v-model="taskdefine.application" @input="selappldata" v-on:close="validateForm('t18', $event)"/>
                        <treeselect v-else disabled :options="taskdefine.applicoptions" v-model="taskdefine.application"/>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.application">{{ this.errors.taskdefine.application }}</div>
                  </div>
                  </div>
                  <div class="col-md-6" v-if="this.isRedirectedFromBtmView == false">
                  <div v-if="pagefeatures.createadmintask || this.enabledtasklogfields==true" >
                    <div class="form-group ">
                      <label for="taskdefineprojectmdldd">Project Module</label>
                      <div class="input-group input-group-merge">
                        <treeselect v-if="this.selectedLevel >= 2 && (taskdefine.module == null || !taskdefine.isredirected) " id="modinp" placeholder="Select Project Module" class="taskdefineprojectmdldd" :options="taskdefine.projectmdllist" @input="selprojmdldata" v-model="taskdefine.module" v-on:close="validateForm('t18', $event)"/>
                        <treeselect v-else disabled :options="taskdefine.projectmdllist" v-model="taskdefine.module"/>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.module">{{ this.errors.taskdefine.module }}
                    </div>
                  </div>
                  </div>
                  <div class="col-md-6">
                  <div v-if="pagefeatures.createadmintask || this.enabledtasklogfields==true">
                    <div class="form-group ">
                      <label for="applicationmodule">Application Module</label>
                      <div class="input-group input-group-merge">
                        <treeselect v-if="this.selectedLevel >= 4 && (taskdefine.appmodule == null || !taskdefine.isredirected) " id="appmodinp" placeholder="Select Application Module Name" class="applicationdd" v-model="taskdefine.appmodule" :options="taskdefine.appmodulelist" @input="selapplmdldata" v-on:close="validateForm('t18', $event)"/>
                        <treeselect v-else disabled v-model="taskdefine.appmodule" :options="taskdefine.appmodulelist"/>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.application">{{ this.errors.taskdefine.application }}</div>
                  </div>
                  </div>
                  <div class="col-md-6" v-if="this.isRedirectedFromBtmView == false">
                  <div v-if="pagefeatures.createadmintask || this.enabledtasklogfields==true">
                    <div class="form-group ">
                      <label for="hostingplaturl">Section Module</label>
                      <div class="input-group input-group-merge">
                        <treeselect v-if="this.selectedLevel >= 5" id="secinp" placeholder="Select Section Module Name" class="applicationdd" v-model="taskdefine.devsection" :options="taskdefine.sectionlist" @input="tskdfnsectiondata" v-on:close="validateForm('t18', $event)"/>
                        <treeselect v-else disabled v-model="taskdefine.devsection" :options="taskdefine.sectionlist"/>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.devsection">{{ this.errors.taskdefine.devsection }}</div>
                  </div>
                  </div>
                  <div class="col-md-6" v-if="this.isRedirectedFromBtmView == false">
                  <div v-if="pagefeatures.createadmintask || this.enabledtasklogfields==true">
                    <div class="form-group ">
                      <label for="">Functionality </label>
                      <div class="input-group input-group-merge">
                        <treeselect v-if="this.selectedLevel >= 6 && (taskdefine.feature == null || !taskdefine.isredirected)" id="funcinp" placeholder="Select Functionality Name" class="applicationdd" v-model="taskdefine.feature" :options="taskdefine.featurelist" @input="tskdfnfnlitydata" v-on:close="validateForm('t18', $event)"/>
                        <treeselect v-else disabled v-model="taskdefine.feature" :options="taskdefine.featurelist"/>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.feature">{{ this.errors.taskdefine.feature }}</div>
                  </div>
                  </div>
                 
                </div>
                <div class="form-group">
                  <div class="form-group">
                      <label class="form-label" for="project-name">Micro Task Description </label>
                      <ckeditor :editor="editor" placeholder="Micro Task Description" v-model="taskdefine.desc" :config="editorConfig" tag-name="textarea"
                      v-bind:class="{ 
                              'form-control': true,
                              'is-invalid': !validText(taskdefine.desc) && descBlured, }"
                              v-on:blur="descBlured = true"/>
                      <div class="invalid-feedback">Micro Task description required</div>                                           
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group datePicker">
                      <label for="" class="d-block">Micro Task Start Date</label>
                        <date-picker placeholder="Select Micro Task Start Date"
                          type="datetime" format="YYYY-MM-DD HH:mm" v-model="taskdefine.taskstartdate" valueType="format" class="startdate" id="StartDate" v-on:change="validateDate('taskstartdate', $event)" :minute-step="5"></date-picker>
                      
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.taskstartdate">{{ this.errors.taskdefine.taskstartdate }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group datePicker">
                      <label for="" class="d-block">Micro Task End Date</label>
                      
                      <date-picker placeholder="Select Micro Task End Date" type="datetime" format="YYYY-MM-DD HH:mm" v-model="taskdefine.taskenddate" valueType="format" class="startdate" id="EndDate" v-on:change="validateDate('taskenddate', $event)" :minute-step="5"></date-picker>
                     
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.taskenddate">{{ this.errors.taskdefine.taskenddate }}</div>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="taskdefine.type !=28">
                    <div class="form-group datePicker">
                      <label for="" class="d-block">MIcro Task Assign Date Time</label>
                      <date-picker placeholder="Select Assign Date Time" v-bind:disabled="taskdefine.isassigneddateSelected" type="datetime" format="YYYY-MM-DD HH:mm" v-model="taskdefine.taskassigneddate" valueType="format" class="startdate"></date-picker>
                      <div class="errorinputmsg" v-if="this.errors.taskdefine.taskassigneddate">{{ this.errors.taskdefine.taskassigneddate }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Subscribers</label>
                      <div class="input-group input-group-merge">
                        <treeselect class="projroleassprojectdd capitalisetext" placeholder="Enter Min 4 Chars To Search Subscribers" :multiple="true" :clear-on-select="true" :options="taskdefine.subscriberslist" v-model="taskdefine.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)" v-on:search-change ="getEmpallocatedtoProject"/>
                      </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.subscribers">{{ this.errors.taskdefine.subscribers }}</div>
                    </div>
                  </div>
                                     
                </div>
              </form>
              <div class="col-md-12 px-0">
                <div class="form-group">                                    
                    <label for="lblattachment">Attachment</label>
                <div class="form-group">
                    <label for="attachment" class="btn btn-sm btn-primary mb-75 mr-75">Select image/docs/videos</label>
                    <button class="btn btn-sm btn-primary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                    <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                    <div class="table-responsive" v-if="taskdefine.attachmentview">
                        <table class="table table-sm table-bordered" aria-describedby="Task Master">
                            <thead>
                                <tr>
                                    <th scope="col">SR#</th>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, k) in taskdefine.attachmentview.data" :key="k">
                                    <td>{{k+1}}</td>
                                    <td>
                                        <label>
                                    <div class="click-zoom">
                                      <input type="checkbox" />
                                        <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                        <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                        <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                                        <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                                        &nbsp;
                                        <a :href="data.presignurl" target="_blank">{{data.link | basename}}</a>
                                        </div>
                                </label>
                                    </td>
                                    <td>{{data.info}}</td>
                                    <td>
                                        <button type="button" class="btn btn-sm danger" @click="removes3file(k,taskdefine.attachmentview.data)">
                                          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.taskdefine.attachment">{{ this.errors.taskdefine.attachment }}</div>
                        <div class="table-responsive" v-if="file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="Task Master">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="taskdefine.filedescp[key]"/>
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                 </tbody>
                            </table>
                        </div>
                      </div>
                </div>
              </div>
                <div class="col-12 mg-top1 text-right">
                  <button type="button" class="btn btn-relief-primary btn-1 mr-1" @click="saveData('t18', $event)" id="submitbtnt18" v-bind:disabled="taskdefine.disblesavebtn">Submit</button>
                  <button type="reset" class="btn btn-outline-secondary mr-1" @click="resetForm()" v-if="(this.isredirectedfromPMO!='pmocreate') && (this.isredirectedfromWORKSTEP!='worksteppage')">Reset</button>
                  <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                </div>             
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {Trash2Icon } from "vue-feather-icons";
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import commonMethods from '../../utils/commonMethods';

export default {
  name: "TaskCreate",
  components: {
    Loading,
    VueElementLoading,Trash2Icon,
    vSelect,
    Treeselect,
    DatePicker,
    ckeditor: CKEditor.component,VueTimepicker,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      tocheckParentModuleOfTAsk: this.$route.params.clickeddatafrombtmview,
      defaultValue: new Date(),
      isProjectLevelSelected: false,
      selectedLevel: 10,
      level: 1,
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      tasktypelist: [],
      ahourslist: [],
      aminutelist: [],
      typenamelist: [],
      projectlist: [],
      taskassignmentList: [],
      showAddForm: false,
      selected: null,
      countryName: [
        { label: "select_value", id: "Select Value" },
        { label: "Russia", id: "Russia" },
        { label: "Canada", id: "Canada" },
        { label: "China", id: "China" },
        { label: "United States", id: "United States" },
        { label: "Brazil", id: "Brazil" },
        { label: "Australia", id: "Australia" },
        { label: "India", id: "India" },
      ],
      editRowDatas: [],
      editRowData: [],
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      projectType: [],
      projectdescription: "",
      tasklevel: apiUrl.tasklevel,
      tasktypekeylist: apiUrl.tasktypekeylist,
      estimateshours: apiUrl.estimateshours,
      tasktype: {
        businesstaskmoduleassignmentid: null,
        moduletype: 0,
        name: null,
        projectid: null,
        projectlist: [
          {
            id: "",
            label: "",
          },
        ],
        desc: null,
        level: null,
        descerror: null,
        levelerror: null,
        type: null,
        key: null,
        redmineid: null,
        btmpriority: null,
        typelist: [
          { id: "Business Task", label: "Business Task" },
          { id: "Tech Task", label: "Tech Task" },
        ],
        keylist: [
          { id: "Key", label: "Key" },
          { id: "Normal", label: "Normal" },
        ],
        referenceslist: [
          { id: "Customer Care", label: "Customer Care" },
          { id: "End Customer", label: "End Customer" },
          { id: "Finance", label: "Finance" },
          { id: "Logistics", label: "Logistics" },
          { id: "Product", label: "Product" },
          { id: "Quality Check", label: "Quality Check" },
          { id: "Technology", label: "Technology" },
          { id: "Warehouse", label: "Warehouse" },
        ],
        btmprioritylist: [
          { id: 0, label: "0" },
          { id: 1, label: "1" },
          { id: 2, label: "2" },
          { id: 3, label: "3" },
          { id: 4, label: "4" },
          { id: 5, label: "5" },
          { id: 6, label: "6" },
          { id: 7, label: "7" },
          { id: 8, label: "8" },
          { id: 9, label: "9" },
          { id: 10, label: "10" },
        ],
        references: null,
        bsh: null,
        psh: null,
        priorityerr: null,
        dept: null,
        depterr: null,
        deptlist: [
          { id: "Sourcing", label: "Sourcing" },
          { id: "CC", label: "CC" },
          { id: "Marketing", label: "Marketing" },
          { id: "Finance", label: "Finance" },
          { id: "Purchase", label: "Purchase" },
        ],
        startdate: null,
        enddate: null,
        tstatus: null,
        businesstaskid: null,
        businesstaskname: null,
        projectnameist: null,
        projectist: null,
        projectmodulenamenameist: null,
        projectmoduleist: null
      },
      taskmodule: 0,
      tasknamemaster: apiUrl.tasknamemaster,
      taskdefine: {
        technology: null,
        activity: null,
        activityhours: null,
        businesstaskcode: null,   
        estimateshours: null,
        type: null,
        ahour: null,
        amin: null,
        name: null,
        level: null,
        projectid: null,
        projectlist: [
          {
            id: "",
            label: "",
          },
        ],
        subscriberslist: [],
        subscribers: null,
        projectname: null,
        module: null,
        businesstaskid: null,
        projectmdllist: [
          {
            id: "",
            label: "",
          },
        ],
        application: null,
        applicoptions: [
          {
            id: "",
            label: "",
          },
        ],
        applicationname: null,
        appmodule: null,
        appmodulelist: [],
        applicationmodulename: "",
        devsection: null,
        sectionlist: [
          {
            id: "",
            label: "",
          },
        ],
        feature: null,
        featurelist: [
          {
            id: "",
            label: "",
          },
        ],
        workstepid: null,
        taskstartdate: this.dateFormat(new Date()),
        taskenddate: null,
        taskassignby: "",
        taskassigneddate: this.dateFormat(new Date()),
        taskdefinename: "",
        businesstype: null,
        businesstasklist: [
          {
            id: "",
            label: "",
          },
        ],
        projectmodulename: null,
        isedit: false,
        view: {
          projectname: null,
          applicationname: null,
          projectmodulename: null,
          applicationmodulename: null,
          sectionname: null,
          functionlityname: null,
          workstepname: null,
        },
        allottedto: null,
        allottedtolist: [],
        status: null,
        statuslist:apiUrl.status,
        desc:null,
        isassigneddateSelected:true,
        attachment:null,
        attachmentview:null,
        attachmentnew:null,
        filedescp:[],
        disblesavebtn:false,
      },
      taskBlured: false,
      valid: false,
      projvalue: null,
      applvalue: null,
      applicoptions: [
        {
          id: "",
          label: "",
        },
      ],
      startdate: null,
      errors: {
        taskdefine: {
          type: null,
          name: null,
          level: null,
          projectid: null,
          module: "",
          application: null,
          appmodule: "",
          devsection: "",
          feature: "",
          taskstartdate: null,
          taskenddate: null,
          taskassignby: "",
          taskassigneddate: "",
          taskdefinename: "",
          businesstype: null,
          allottedto: null,
          status: null,
          desc:null,
          attachment:null,
          subscribers: null,
          estimateshours: null,
          technology: null,
          activity: null,
          activityhours: null, 
          ahour: null,
          amin: null,    
        },
      },
      view: {
        tasktype: null,
        projectname: null,
        applicationname: null,
      },
      tasklevellist: [
        {
          id: "",
          label: "",
        },
      ],
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      projectnameist: [],
      loginuscondition: "",
      attachment: [],
      attachmentview: null,
      isDetActive: false,
      isRequestActive: true,
      card_content: "card-content",
      collapse: "collapse",
      nameBlured: false,
      editor: ClassicEditor,
      editorConfig: {
          toolbar: {
              items: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  '|',
                  'bulletedList',
                  'numberedList',
                  '|',
                  'insertTable',
                  '|',
                  'undo',
                  'redo', '|',
              ],
          },
      },
      descBlured:false,
      tokendata: null,
      file:[],
      isRedirectedFromBtmView: false,
      openstatue: true,
      accepttype:apiUrl.uploadfiletype,
      isredirectedfromPMO: null,
      newpid:null,
      isredirectedfromWORKSTEP: null,
      enabledtasklogfields: false,
      activityhoursopts: apiUrl.activityhoursopts,
      taskTypedesignCreative: apiUrl.taskTypedesignCreative,
      designTeamRole: apiUrl.designTeamRole,
      assignedtoplaceholder: 'Enter Min 4 Chars To Search Assigned To'
    };
  },
  created() {
    this.pagefeatures = {};
    let pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/tasks/list") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    let userdata = window.localStorage.getItem("userdata");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.undt = userdata;
      this.tokendata = window.localStorage.getItem('token');
      this.loginuscondition = userdata.userid;
      this.taskdefine.status = 'open'
      this.taskmodule = window.localStorage.getItem("taskmodule")
      this.isredirectedfromWORKSTEP=this.$route.params.ispage
      if (this.$route.params.backbtnstate == 'create') {
        this.taskdefine.taskdefinename = this.$route.params.clickeddata?this.$route.params.clickeddata.workstepname:''
        if(this.$route.params.clickeddata && this.$route.params.clickeddata.projectlevel) {
          this.isProjectLevelSelected = true
          this.taskdefine.level = this.$route.params.clickeddata.projectlevel
        }
        this.masterlist("t18",this.$route.params.clickeddata);
        if(this.$route.params.clickeddatafrombtmview){
          this.taskmodule = window.localStorage.getItem("taskmodule")
          let cd = this.$route.params.clickeddatafrombtmview
            this.isRedirectedFromBtmView = true
            this.tasktype.businesstaskid = cd.moduleid 
            this.tasktype.businesstaskname = cd.modulename
            this.tasktype.projectist = cd.projectist
            this.tasktype.projectnameist = cd.projectnameist
            this.tasktype.projectmoduleist = cd.projectmoduleist
            this.tasktype.projectmodulenamenameist =cd.projectmodulenamenameist
            this.tasktype.businesstaskmoduleassignmentid = cd.businesstaskmoduleassignmentid
            this.getbusinesstasklistbyid(cd.moduleid)
            this.getTasktypeConfigLists(cd.projectist)
            // this.getEmpallocatedtoProject(cd.projectist)
        }
        this.getTasktypeConfigLists(this.$route.params.clickeddata.projectid)
      } else {
        this.masterlist("t18", "");
      }
      if(this.$route.params.isPMOCreate){
        this.isProjectLevelSelected = true
        this.isAskforPMO = true
        this.taskdefine.level = 1
        this.taskdefine.type = 25        
      }
      this.isredirectedfromPMO = this.$route.params.isPMOCreate
      if (this.$route.params.length>0 && !this.isredirectedfromPMO) {
        this.taskdefine.businesstype = this.$route.params.clickeddata.moduleid
        this.taskdefine.projectid = this.$route.params.clickeddata.projectid
      }
      if (this.isredirectedfromPMO == 'pmocreate') {
        this.taskdefine.businesstype = this.$route.params.rowdata.moduleid
        this.newpid = this.$route.params.rowdata.projectist
        this.masterlist("t18",this.$route.params.rowdata);
        this.getTasktypeConfigLists(this.$route.params.rowdata.projectist)
      }
      if (this.$route.params.backbtnstate == 'createTlog') {
        this.enabledtasklogfields = true;
        this.masterlist("t18",this.$route.params.taskcreationdata);
        this.activityHoursList();
        this.getTasktypeConfigLists(this.$route.params.taskcreationdata.projectist)
      }
    }
  },
  methods: {
    activityHoursList() {
      for(let i=0; i<=23; i++) {
        if(i<=9) {
          let withZeroNo = '0'+i;
          this.ahourslist.push({ id: withZeroNo, label: withZeroNo});
        } else {
          this.ahourslist.push({ id: i.toString(), label: i.toString() });
        }
      }
      for(let m=1; m<=59; m++) {
        if(m<=9) {
          let withZeroNo = '0'+m;
          this.aminutelist.push({ id: withZeroNo, label: withZeroNo});
        } else {
          this.aminutelist.push({ id: m.toString(), label: m.toString() });
        }
      }
    },
    selActivityName(state) {
        if(!state) {
          if(this.taskdefine.type && this.taskdefine.type ==28){
            this.errors.taskdefine.activity ='';
          }else{
            this.errors.taskdefine.activity = 'Activity name is required'
          }
        } else {
          this.errors.taskdefine.activity = '';
        }
    },
    getmodulename(id){
      let arr = apiUrl.tasklevel;
      let obj = arr.find(o => o.id === id); 
      return obj.text;
    },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    mydetailsollapse() {
      this.isDetActive = !this.isDetActive;
    },
    handleCancel() {
      this.$router.push({ name: 'TaskList', params: { search: true }});
    },
    resetForm() {
      this.taskdefine.type = null
      this.taskdefine.name = null
      this.taskdefine.level = null
      this.taskdefine.allottedto = null
      this.taskdefine.projectid = null
      this.taskdefine.businesstype = null
      this.taskdefine.application = null 
      this.taskdefine.desc = ""
      this.taskdefine.taskstartdate = null
      this.taskdefine.taskenddate = null
      this.taskdefine.attachmentnew= null
      this.taskdefine.subscribers = null
      this.taskdefine.estimateshours = null
      this.taskdefine.subscriberslist = [];
      this.taskdefine.allottedtolist = [];
    },
    dateFormatterSales: function (dt) {
      let dateoptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return dt.toLocaleDateString("en-GB", dateoptions);
    },
    masterlist(mdl, objThis) {
      if(objThis) {
        if (this.$route.params.backbtnstate == 'create') {
          // this.getEmpallocatedtoProject(parseInt(objThis.projectid))
          // this.getAssignEmptoProject(parseInt(objThis.projectid))
          this.taskdefine.view.projectname = objThis.projectname
          this.taskdefine.view.applicationname = objThis.applicationname
          this.taskdefine.view.projectmodulename = objThis.projectmodulename
          if(!objThis.projectmodulename) {
            this.taskdefine.view.projectmodulename = objThis.projmodname
          }
          this.taskdefine.view.applicationmodulename = objThis.applicationmodulename
          this.taskdefine.view.sectionname = objThis.sectionname
          this.taskdefine.view.functionlityname = objThis.functionlityname
          this.taskdefine.view.taskname = objThis.taskname
          this.taskdefine.view.workstepname = objThis.workstepname
          this.taskdefine.businesstaskid=objThis.businesstaskid
          this.taskdefine.workstepid = objThis.workstepid

          this.isDetActive = true;
          this.taskdefine.projectid = objThis.projectid;
          this.taskdefine.module = objThis.projectmoduleid;
          if(!objThis.projectmoduleid) {
            this.taskdefine.module = objThis.projmodid
          }
          this.taskdefine.application = objThis.applicationid;
          this.taskdefine.appmodule = objThis.applicationmoduleid;
          this.taskdefine.devsection = objThis.sectionmasterid;
          this.taskdefine.feature = objThis.functionlitymasterid;
          this.taskdefine.businesstype = objThis.businesstaskid
          this.disablefields(this.selectedLevel)  
        }
        if (this.enabledtasklogfields) {
          this.taskdefine.projectid = objThis.projectist
          this.taskdefine.businesstype = objThis.moduleid
          this.taskdefine.businesstaskid = objThis.moduleid
          this.taskdefine.businesstaskcode=objThis.businesstaskcode
          this.getBusinessTaskbyProject();
          this.getfiltereddata("tdapp");
          this.getfiltereddata("tdprojmodule");
          // this.getEmpallocatedtoProject(parseInt(objThis.projectist))
          // this.getAssignEmptoProject(parseInt(objThis.projectist))
          //this.getAssignEmptobusinesstask(parseInt(objThis.moduleid))
        }
        if (this.$route.params.isPMOCreate) {
          this.taskdefine.projectid=parseInt(objThis.projectist)
          this.taskdefine.businesstype=parseInt(this.taskdefine.businesstype)
          // this.getEmpallocatedtoProject(parseInt(objThis.projectist))
          this.getAssignEmptobusinesstask(parseInt(objThis.moduleid))
          this.taskdefine.view.projectname = objThis.projectnameist
          this.taskdefine.view.taskname = objThis.modulename
          this.taskdefine.businesstaskid=objThis.moduleid
        }
        if(this.taskdefine.projectid && this.taskdefine.projectid !=null){
          this.getProjectDataBYProjectid(this.taskdefine.projectid);
        }

        this.isLoading = true;
        this.selectedLevel = 10;
        this.getApplicationdata("t24", "taskdefine-tab");
        this.getApplicationdata("t25", "taskdefine-tab");
        if (this.taskdefine.isredirected) {
          this.isDetActive = false;
        }
        this.isLoading = false;
      }
    },
    getProjectName(projectid) {
      let proj = this.taskdefine.projectlist.filter(proj => proj.id == projectid)
      if(proj && proj.length) {
        return proj[0].label
      }
      return null
    },
    saveData(mdl, objThis) {
      let isValid = true;
      isValid = this.validateForm(mdl, objThis);
      if (isValid) {
        this.isLoading = true;
        this.clearerrorForm();
        this.input = {
          createdby: this.undt.username,
        };
        let apiURL = "api/master/taskmaster/saveTaskMaster";
        if(this.tasktype.businesstaskid != null && this.tasktype.businesstaskid != 'undefined'){
          this.input.businesstype=this.tasktype.businesstaskid;
        }else if(this.isredirectedfromPMO=='pmocreate'){
          this.input.businesstype=this.taskdefine.businesstaskid;
        }else{
          this.input.businesstype = this.taskdefine.businesstype;
        }
        this.input.taskdefinename = this.taskdefine.taskdefinename;
        this.input.type = this.taskdefine.type;
        this.input.nameid = 1;
        if(this.isRedirectedFromBtmView ==true){
          this.input.level = 2;
        }else{
        this.input.level = this.taskdefine.level;
        }
        if(this.tasktype.projectist != null && this.tasktype.projectist != 'undefined'){
          this.input.projectid=this.tasktype.projectist;
        }else if(this.isredirectedfromPMO=='pmocreate'){
          this.input.projectid=this.newpid
        }else{
          this.input.projectid = this.taskdefine.projectid
          this.input.projectname = this.getProjectName(this.taskdefine.projectid)
        }

        this.input.desc = this.taskdefine.desc
        if(this.tasktype.projectmoduleist != null && this.tasktype.projectmoduleist != 'undefined'){
          this.input.module=this.tasktype.projectmoduleist;
          this.input.moduletype = 1
        }else{
          this.input.module = this.taskdefine.module;
          this.input.moduletype = 0
        }
        this.input.moduletype =this.taskmodule ;
        if (this.taskdefine.application) {
          this.input.application = this.taskdefine.application;
        } else {
          this.input.application = null;
        }
        this.input.appmodule = this.taskdefine.appmodule;
        this.input.devsection = this.taskdefine.devsection;
        this.input.feature = this.taskdefine.feature;
        this.input.taskstartdate = this.taskdefine.taskstartdate ? this.taskdefine.taskstartdate :null;
        this.input.taskenddate = this.taskdefine.taskenddate ? this.taskdefine.taskenddate :null;
        if(this.taskdefine.type ==28){
          this.input.taskassigneddate = null;
        }else{
          this.input.taskassigneddate = this.taskdefine.taskassigneddate;
        }
        this.input.allottedto = this.taskdefine.allottedto
        this.input.status = this.taskdefine.status
        this.input.businesstaskmasterid = this.taskdefine.businesstaskid
        this.input.workstepid = this.taskdefine.workstepid
        this.input.attachment=this.taskdefine.attachmentnew
        this.input.subscribers = this.taskdefine.subscribers
        this.input.estimateshours = this.taskdefine.estimateshours
        this.input.empid = this.undt.userid

        if(this.enabledtasklogfields == true) {
          this.input.technology=this.taskdefine.technology
          let elemHours = this.activityhoursopts.filter(activity => activity.id === this.taskdefine.activity)
          let elemWithoutHours = this.activityhoursopts.filter(activity => activity.id !== this.taskdefine.activity)
          if(elemHours.length) {
              this.input[elemHours[0].id] = this.taskdefine.activityhours
          }
          if(elemWithoutHours.length) {
              elemWithoutHours.forEach((activity) => this.input[activity.id] = null)
          }
          this.input.businesstaskcode =  this.taskdefine.businesstaskcode
        }
        if (!this.taskdefine.attachmentnew) {
          this.input.attachment = null;
        } else {
          this.input.attachment = this.taskdefine.attachmentnew;
        }
        this.input.useremail= this.undt.username
        this.input.empcode= this.undt.userid
        this.input.modulemaster ='taskmaster'
        this.isAddState = false;
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          this.file =[]
          this.taskdefine.filedescp =[]
          if (result.data.errorCode == 0) {
            let d = result.data.data.metadata
            let str = ''
            let icon = "success"
            if(d) {
              icon = "info"
              str = `<div>
                <p>User <strong>${d.emp}</strong> has already been assigned to task within selected date time.</p>
                <p>Existing task details</p>
                <section>
                  <p><strong>Code</strong> : ${d.taskcode}</p>
                  <p><strong>Name</strong> : ${d.taskname}</p>
                  <p><strong>Start date</strong>: ${d.taskstartdate ? this.dateMonthFormat(d.taskstartdate) : 'NA'}</p>
                  <p><strong>End date</strong>: ${d.taskenddate ? this.dateMonthFormat(d.taskenddate) : 'NA'}</p>
                </section>
              </div>`
            }           
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: icon,
              html: str,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            if(this.isRedirectedFromBtmView || this.isredirectedfromPMO=='pmocreate' || this.enabledtasklogfields == true) {
              this.$router.push(`/businesstask/view?businessid=${result.data.data.businesstaskmasterid}`)
            } else {
              this.$router.push({ name: 'TaskList', params: { search: true }});
            }
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "",
              text: result.data.msg,
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    validateDate(mdl, objThis) {
      var startDate = this.taskdefine.taskstartdate
      var endDate = this.taskdefine.taskenddate
      if(mdl === 'taskenddate') {
        if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
          this.errors.taskdefine.taskenddate = "End date should be greater than Start date"
          this.taskdefine.taskenddate = ''
        } else {
          this.errors.taskdefine.taskenddate = ''
        }
      } 
      else if(mdl === 'taskstartdate') {
        if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
          this.errors.taskdefine.taskstartdate = "Start date should be lesser than End date"
          this.taskdefine.taskstartdate = ''
        } else {
          this.errors.taskdefine.taskstartdate = ''
        }
       
      } 
     
    },
    validateForm(mdl, objThis) {
      this.clearerrorForm();
      this.isAddState = false;
      var isValid = true;
      this.nameBlured = true;
      this.descBlured = true;
      if (mdl == "t18") {
        if (!this.taskdefine.taskdefinename && this.isredirectedfromPMO!='pmocreate') {
          this.errors.taskdefine.taskdefinename = "Task name required";
          isValid = false;
        }
        if (!this.taskdefine.type) {
          this.errors.taskdefine.type = "Micro Task type required";
          isValid = false;
        }
        if (!this.taskdefine.level) {
          if(this.taskdefine.type && this.taskdefine.type ==28){
            this.errors.taskdefine.level = "";
          }else{
            this.errors.taskdefine.level = "Micro Task level required";
            isValid = false;
          }
        }
        if(this.isRedirectedFromBtmView ==false && this.isredirectedfromPMO!='pmocreate'){
          if (!this.taskdefine.projectid) {
            this.errors.taskdefine.projectid = "Project name is required";
            isValid = false;
          }
        }else{
          this.errors.taskdefine.projectid = "";
            isValid = true;
        }
        if (!this.taskdefine.desc) {
          this.errors.taskdefine.desc = "Micro Task description required";
          isValid = false;
        }
        if(!this.taskdefine.allottedto) {
          this.errors.taskdefine.allottedto = "Assigned to required";
          isValid = false;
        }
        if (!this.taskdefine.taskstartdate) {
          this.errors.taskdefine.taskstartdate = "Start date required";
          isValid = false;
        }
        if (!this.taskdefine.taskenddate) {
          if(this.taskdefine.type && this.taskdefine.type ==28){
            this.errors.taskdefine.taskenddate ='';
          }else{
            this.errors.taskdefine.taskenddate = "End date required";
            isValid = false;
          }
        }
        if (!this.taskdefine.taskassigneddate) {
          if(this.taskdefine.type ==28){
            this.errors.taskdefine.taskassigneddate ='';
            this.taskdefine.taskassigneddate =null;
          }else{
            this.errors.taskdefine.taskassigneddate = "Assign date required";
            isValid = false;
          }
        }
        if(!this.taskdefine.status) {
          this.errors.taskdefine.status = "Micro Task status is required"
          isValid = false
        }
        if((!this.taskdefine.subscribers) || (this.taskdefine.subscribers && this.taskdefine.subscribers.length < 1)) {
          this.errors.taskdefine.subscribers = 'Subscribers are required'
          isValid = false
        } else {
          this.errors.taskdefine.subscribers = ''
        }
        if(!this.taskdefine.estimateshours) {
          if(this.taskdefine.type && this.taskdefine.type ==28){
            this.errors.taskdefine.estimateshours = "";
          }else{
            this.errors.taskdefine.estimateshours = "Micro Task estimates is required"
            isValid = false;
          }
        }
        if(this.enabledtasklogfields == true) {
          this.taskdefine.activityhours = '00:00';
          if(this.taskdefine.ahour != null && this.taskdefine.ahour != '') {
            this.taskdefine.activityhours = this.taskdefine.ahour+':'+this.taskdefine.amin;
          }
          let hours = this.taskdefine.activityhours
          let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
          if(typeof hours === 'string' && hours.length === 5) isValidHours = true
          if(isValidHours && hours !== null && typeof hours === 'object') {
              this.taskdefine.activityhours = `${hours.HH}:${hours.mm}`
          } else {
              this.errors.taskdefine.activityhours = ''
          }
          
          // if(!isValidHours) {
          //     this.errors.taskdefine.activityhours = 'Activity hours and minute are required'
          //     isValid = false
          // } else {
          //     this.errors.taskdefine.activityhours = ''
          // }

          if(this.taskdefine.ahour == null || this.taskdefine.ahour == '') {
            if(this.taskdefine.type && this.taskdefine.type ==28){
              this.errors.taskdefine.ahour ='';
            }else{
              this.errors.taskdefine.ahour = 'Estimated hours are required'
              isValid = false
            }
          } else {
            this.errors.taskdefine.ahour = ''
          }
          if(this.taskdefine.amin == null || this.taskdefine.amin == '') {
            if(this.taskdefine.type && this.taskdefine.type ==28){
              this.errors.taskdefine.amin ='';
            }else{
              this.errors.taskdefine.amin = 'Estimated minutes are required'
              isValid = false
            }
          } else {
            this.errors.taskdefine.amin = ''
          }
          if(!this.taskdefine.activity) {
            if(this.taskdefine.type && this.taskdefine.type ==28){
              this.errors.taskdefine.activity = '';
            }else{
              this.errors.taskdefine.activity = 'Activity name is required';
              isValid = false;
            }
          } else {
            this.errors.taskdefine.activity = '';
          }
          if(!this.taskdefine.technology){
            if(this.taskdefine.type && this.taskdefine.type ==28){
              this.errors.taskdefine.technology ='';
            }else{
              this.errors.taskdefine.technology = "Technology is required";
              isValid= false;
            }
          } else {
            this.errors.taskdefine.technology = null;
          }
        }
      }
      return isValid;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clearerrorForm() {
      this.errors.taskdefine.type = null
      this.errors.taskdefine.name = null
      this.errors.taskdefine.level = null
      this.errors.taskdefine.projectid = null
      this.errors.taskdefine.desc = null
      this.errors.taskdefine.module = ""
      this.errors.taskdefine.application = null
      this.errors.taskdefine.appmodule = ""
      this.errors.taskdefine.devsection = ""
      this.errors.taskdefine.feature = ""
      this.errors.taskdefine.taskstartdate = null
      this.errors.taskdefine.taskenddate = null
      this.errors.taskdefine.taskassignby = ""
      this.errors.taskdefine.taskassigneddate = ""
      this.errors.taskdefine.taskdefinename = ""
      this.errors.taskdefine.businesstype = null
      this.errors.taskdefine.attachment = ""
    },
    validText: function (inputval) {
      var re = /[A-Za-z0-9].{4,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validRedmineID: function (inputval) {
      var re = /[A-Za-z0-9].{2,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },

    dateFormat2(value) {
      if (!value) return "";
      return moment(value).format(this.TimeFormat);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("YYYY-MM-DD HH:mm:ss");
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    projectChange: function (node, instanceId) {
      if (node && node.length > 3) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage: 'createpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.taskdefine.projectlist = result.data.data;
          } else {
            this.taskdefine.projectlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getProjectDataBYProjectid(projectid) {
      this.isLoading = true;
      let url = "api/searching/getAssignedProjectList";
      this.input = {
        useremail: this.undt.username,
        empid: this.loginuscondition,
        projectid: projectid,
        empcode: this.undt.userid,
        isfrompage: 'isprojectid'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.taskdefine.projectlist = result.data.data;
        } else {
          this.taskdefine.projectlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getEmpallocatedtoProject(node) {
      if(this.taskdefine.projectid && node && node.length>3){
        axios({
          method: "POST",
          url: "api/listing/getEmpallocatedtoProject",
          data: { projectid: this.taskdefine.projectid,useremail: this.undt.username,empcode: this.undt.userid,assignedto:node.toLowerCase()},
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.taskdefine.subscriberslist = result.data.data;
            let decryptdepartmenttext = this.taskdefine.subscriberslist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.taskdefine.subscriberslist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getAssignEmptoProjectselect(id) {
      this.isLoading = true;
      let url = "api/listing/getEmpallocatedtoProject";
      this.input = {
        useremail: this.undt.username,
        projectid: this.taskdefine.projectid,
        empcode: this.undt.userid,
      };
      this.input.roleid = id;
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.taskdefine.allottedtolist = result.data.data;
          let decryptdepartmenttext = this.taskdefine.allottedtolist.map(items =>{

            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        } else {
          this.taskdefine.allottedtolist = [];
        }
      });
    },
    getAssignEmptoProject(node) {
      if(this.taskdefine.projectid && node && node.length>3){
        this.isLoading = true;
        let url = "api/listing/getEmpallocatedtoProject";
        this.input = {
          useremail: this.undt.username,
          projectid: this.taskdefine.projectid,
          empcode: this.undt.userid,
          assignedto: node.toLowerCase()
        };
        if(this.$route.params.isPMOCreate){
          this.input.roleid=477
        }
        if(this.taskTypedesignCreative.includes(this.taskdefine.type)){
          this.input.roleid= this.designTeamRole
          this.input.projectid = this.prId
        }
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.taskdefine.allottedtolist = result.data.data;
            let decryptdepartmenttext = this.taskdefine.allottedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.taskdefine.allottedtolist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getAssignEmptobusinesstask(btid) {
      this.isLoading = true;
      let url = "api/listing/getAssignEmptobusinesstask";
      this.input = {
        useremail: this.undt.username,
        businesstaskmasterid: btid,
        empcode: this.undt.userid,
        roleid:477
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.taskdefine.allottedtolist = result.data.data;
          let decryptdepartmenttext = this.taskdefine.allottedtolist.map(items =>{

            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
          this.taskdefine.allottedto = this.taskdefine.allottedtolist[0].id
          this.isAskforPMO = true
        } else {
          this.taskdefine.allottedtolist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    selallottedto(state, value) {
      if(!state) {
        this.errors.taskdefine.allottedto = 'Assigned To required'
      } else {
        this.errors.taskdefine.allottedto = ''
      }
    },
    selSubscribers(state) {
      this.taskdefine.subscribers = null
      this.taskdefine.subscribers = state
      if(!state || (state && state.length < 1)) {
        this.errors.taskdefine.subscribers = 'Subscribers are required'
      } else {
        this.errors.taskdefine.subscribers = ''
      }
    },
    seltaskstatus(state, value) {
      if(!state) {
        this.errors.taskdefine.status = 'Micro Task status is required'
      } else {
        this.errors.taskdefine.status = ''
      }
    },
    selestimateshours(state, value) {
      this.ahourslist = [];
      if(!state) {
        if(this.taskdefine.type && this.taskdefine.type !=28){
          this.errors.taskdefine.estimateshours = '';
        }else{
          this.errors.taskdefine.estimateshours = 'Micro Task estimates is required'
        }
      } else {
        this.errors.taskdefine.estimateshours = ''
        let hourslimit = 23;
        if (state.id == 'smalltask') {
          hourslimit = 23;
        } else if (state.id == 'minortask') {
          hourslimit = 48;
        } else if (state.id == 'majortask') {
          hourslimit = 144;
        } else if (state.id == 'complextask') {
          hourslimit = 360;
        } else if (state.id == 'highlycomplextask') {
          hourslimit = 750;
        }
        for(let i=0; i<=hourslimit; i++) {
          if(i<=9) {
            let withZeroNo = '0'+i;
            this.ahourslist.push({ id: withZeroNo, label: withZeroNo});
          } else {
            this.ahourslist.push({ id: i.toString(), label: i.toString() });
          }          
        }
      }
    },
    btmChange(node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/master/commonmaster/getModuleDropdownOption";
        if (!this.taskdefine.projectid) {
          this.isLoading = false;
          return;
        }
        this.input = {
          tbl: "t38",
          projectMasterid: this.taskdefine.projectid,
          businesstaskname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.taskdefine.businesstasklist = result.data.data;
          } else {
            this.taskdefine.businesstasklist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getBusinessTaskbyProject() {
      this.isLoading = true;
      let url = "api/searching/getBusinessTaskbyProject";
      if (!this.taskdefine.projectid) {
        this.isLoading = false;
        return;
      }
      this.input = {
        projectid: this.taskdefine.projectid,
        empid: this.undt.userid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.taskdefine.businesstasklist = result.data.data;
        } else {
          this.taskdefine.businesstasklist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getApplicationdata(mdl, curracttab) {
      this.isLoading = true;
      this.pageoffset = 1;

      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      if (mdl == "t25") {
        this.inputappl.limit = 500;
      }
      this.inputappl.useremail= this.undt.username,
      this.inputappl.empcode= this.undt.userid
      let apiURL = "api/master/commonmaster/list";
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (mdl == "t25") {
            if (curracttab == "taskdefine-tab") {
              //this.tasktypelist = result.data.data.rows;
              return true;
            } 
          }  else if (mdl == "t24") {
            if (curracttab == "taskdefine-tab") {
              this.tasklevel = result.data.data.rows;
              return true;
            } 
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getTasktypeConfigLists(projectid) {
      this.isLoading =true;
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: parseInt(projectid.toString()),
            trackername: 'Task Type',
        };
        axios({
            method: "POST",
            url: '/api/businesstask/getTasktypeConfigList',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading =false;
            if(result.data.errorCode == 0){
                let slist = [];
                result.data.data.rows.forEach((rec) => {
                    if(Object.values(rec.statusconfig).length>0){
                        Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                            if(statusnames.length>0) {
                                statusnames.forEach(val => {
                                    if(!slist.some(sdata => sdata.id === val)){
                                        slist.push({ id: val, label: val });
                                    }
                                });
                            }
                        });
                    }
                })
                this.sortTypeList(slist, 'alltype')
            } else {
                this.tasktypelist = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
    },
    sortTypeList(typelist, type){
      this.isLoading =true;
      let numArrFromTypelist = [];
      typelist.forEach(element => {
          if(element.id != null) {
              numArrFromTypelist.push(element.id);
          }
      });
      if(numArrFromTypelist && numArrFromTypelist.length>0) {
          this.input = {
              useremail: this.undt.username,
              empcode: this.undt.userid,
              moduletype: 'task',
              typearr: numArrFromTypelist,
              projecttype: type
          };
          axios({
              method: "POST",
              url: 'api/ticket/sortTypeList',
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.tasktypelist = result.data.data.rows;
                  this.tasktypelist.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                  });
              } else {
                  this.tasktypelist = [];
              }
          }).catch(e => {
        this.displayError(e)
      });
      }else{
        this.isLoading =false;
      }
    },
    selprojdata(state, projvalue) {
      this.taskdefine.projectid = null;
      this.taskdefine.projectid = state;

      if (!state) {
        this.taskdefine.businesstasklist = null;
        this.taskdefine.businesstype = null;
        this.taskdefine.application = null;
        this.taskdefine.applicoptions = null;
        this.taskdefine.module = null;
        this.taskdefine.projectmdllist = [];
        this.taskdefine.appmodule = null;
        this.taskdefine.appmodulelist = [];
        this.taskdefine.devsection = null;
        this.taskdefine.sectionlist = null;
        this.taskdefine.feature = null;
        this.taskdefine.featurelist = null;
        this.taskdefine.allottedto = null
        this.taskdefine.subscribers = null
        this.errors.taskdefine.projectid = "Project name is required";
        
        this.taskdefine.projectlist = [];
      } else {
        this.errors.taskdefine.projectid = null;
        this.getBusinessTaskbyProject();
        this.getfiltereddata("tdapp");
        this.getfiltereddata("tdprojmodule");
        // this.getEmpallocatedtoProject(state)
        if (!this.$route.params.isPMOCreate) {
          // this.getAssignEmptoProject(state)
        }
        this.taskdefine.type = null
      }
    },
    selprojmdldata(state, projvalue) {
      this.taskdefine.module = null;
      this.taskdefine.module = state;
      if (!state) {
        this.taskdefine.appmodule = null;
        this.taskdefine.appmodulelist = [];
        this.taskdefine.devsection = null;
        this.taskdefine.sectionlist = null;
        this.taskdefine.feature = null;
        this.taskdefine.featurelist = null;
        this.taskdefine.appmodule = null;
        this.taskdefine.devsection = null;
        this.taskdefine.feature = null;
      } else {
        this.errors.taskdefine.module = null;
        this.taskdefine.appmodulelist = []
        this.getfiltereddata("tdappmodule");
      }
    },
    selappldata(state, value) {
      this.taskdefine.application = null;
      this.taskdefine.application = state;

      if (!state) {
        this.taskdefine.module = null;
        this.taskdefine.projectmdllist = [];
        this.taskdefine.appmodule = null;
        this.taskdefine.appmodulelist = [];
        this.taskdefine.devsection = null;
        this.taskdefine.sectionlist = null;
        this.taskdefine.feature = null;
        this.taskdefine.featurelist = null;
        this.errors.taskdefine.application = null;
      } else {
        this.errors.taskdefine.application = null;
        this.getfiltereddata("tdprojmodule");
      }
    },
    selapplmdldata(state, value) {
      this.taskdefine.appmodule = null;
      this.taskdefine.appmodule = state;
      if (!state) {
        this.taskdefine.devsection = null;
        this.taskdefine.sectionlist = null;
        this.taskdefine.feature = null;
        this.taskdefine.featurelist = null;
        this.taskdefine.appmodule = state;
        this.errors.taskdefine.appmodule = "Application Module required";
        this.taskdefine.devsection = null;
        this.taskdefine.feature = null;
      } else {
        this.errors.taskdefine.appmodule = null;
        this.getfiltereddata("tdappmdl");
      }
    },
    seltasktypedata(state, value) {
      this.task.tasklevel = null;
      this.task.tasklevel = state;
      if (!state && this.isAddState == false) {
        this.errors.task.tasklevel = "Task Type level required";
      } else {
        this.errors.task.tasklevel = null;
      }
    },
    seldefinetypedata(state, value) {
      this.taskdefine.type = null;
      this.taskdefine.type = state.id;
      this.prId = this.taskdefine.projectid
      if (!state && this.isAddState == false) {
        this.errors.taskdefine.type = "Task type required";
      } else {
        this.errors.taskdefine.type = null;
        // this.getAssignEmptoProject(this.prId)
      }
      if(state && state.id==28){
        this.assignedtoplaceholder = 'Select Assigned To'
        this.taskdefine.allottedto = null;
        this.taskdefine.allottedtolist = [];
        this.getAssignEmptoProjectselect(this.designTeamRole);
      }else{
        this.taskdefine.allottedto = null;
        this.taskdefine.allottedtolist = [];
        this.assignedtoplaceholder = 'Enter Min 4 Chars To Search Assigned To'
      }
    },
    selahour(state, value) {
      this.taskdefine.ahour = null;
      this.taskdefine.ahour = state;
      if (!state && this.isAddState == false) {
        this.errors.taskdefine.ahour = "Estimated hour required";
      } else {
        this.errors.taskdefine.ahour = null;
      }
    },
    selamin(state, value) {
      this.taskdefine.amin = null;
      this.taskdefine.amin = state;
      if (!state && this.isAddState == false) {
        this.errors.taskdefine.amin = "Estimated minute required";
      } else {
        this.errors.taskdefine.amin = null;
      }
    },
    seltasknmtypedata(state, value) {
      this.task.tasknames = null;
      this.task.tasknames = state;
      if (!state && this.isAddState == false) {
        this.errors.taskName.tasklevel = "Task name level required";
      } else {
        this.errors.taskName.tasklevel = null;
      }
    },
    seltaskdftypedata(state, value) {
      this.taskdefine.level = null;
      this.taskdefine.level = state.id;
      if (!state && this.isAddState == false) {
        this.selectedLevel = 10;
        if(this.taskdefine.type && this.taskdefine.type !=28){
          this.errors.taskdefine.level = '';
        }else{
          this.errors.taskdefine.level = "Level required";
        }
      } else {
        this.errors.taskdefine.level = null;
        if(this.pagefeatures.createadmintask || this.enabledtasklogfields==true) {
          this.disablefields(state.id);
        }
      }
    },
    disablefields(level) {
      this.selectedLevel = 10;
      this.selectedLevel = level;
      if (level == 1) {
        this.taskdefine.application = null;
        this.taskdefine.module = null;
        this.taskdefine.appmodule = null;
        this.taskdefine.devsection = null;
        this.taskdefine.feature = null;
      } else if (level == 2) {
        this.taskdefine.appmodule = null;
        this.taskdefine.devsection = null;
        this.taskdefine.feature = null;
      } else if (level == 3) {
        this.taskdefine.appmodule = null;
        this.taskdefine.devsection = null;
        this.taskdefine.feature = null;
      } else if (level == 4) {
        this.taskdefine.devsection = null;
        this.taskdefine.feature = null;
      } else if (level == 5) {
        this.taskdefine.feature = null;
      }
    },
    seltaskdfnamedata(state, value) {
      this.taskdefine.name = null;
      this.taskdefine.name = state;
      if (!state && this.isAddState == false) {
      } else {
        this.errors.taskdefine.name = null;
      }
    },
    selastaskldata(state, value) {
      this.assignment.taskid = null;
      this.assignment.taskid = state;
      if (!state && this.isAddState == false) {
        this.errors.assignment.taskid = "Employee required";
      } else {
        this.errors.assignment.taskid = null;
        this.getfiltereddata("taemployees");
      }
    },
    selasempldata(state, value) {
      this.assignment.empid = null;
      this.assignment.empid = state;
      this.errors.assignment.empid = null;
      if (!state && this.isAddState == false) {
        this.errors.assignment.empid = "Employee required";
      } else {
        this.errors.assignment.empid = null;
      }
    },
    selastempldata(state, value) {
      this.statusassignment.empid = null;
      this.statusassignment.empid = state;
      this.errors.statusassignment.empid = null;
      if (!state && this.isAddState == false) {
        this.errors.statusassignment.empid = "Employee required";
      } else {
        this.errors.statusassignment.empid = null;
      }
    },
    seltaskasdata(state, value) {
      this.statusassignment.taskassignmentid = null;
      this.statusassignment.taskassignmentid = state;
      if (!state && this.isAddState == false) {
        this.errors.statusassignment.taskassignmentid =
          "Task assignment required";
      } else {
        this.errors.statusassignment.taskassignmentid = null;
      }
    },
    seltaskdfndata(state, value) {
      this.statusassignment.taskdefineid = null;
      this.statusassignment.taskdefineid = state;
      if (!state && this.isAddState == false) {
      } else {
        this.errors.statusassignment.taskdefineid = null;
        this.getfiltereddata("tasemployees");
      }
    },
    seldefinebusinessdata(state, value) {
      this.taskdefine.businesstype = null;
      this.taskdefine.businesstype = state;
      if (!state && this.isAddState == false) {
      } else {
        this.errors.taskdefine.businesstype = null;
      }
    },
    tskdfnsectiondata(state, value) {
      this.taskdefine.devsection = null;
      this.taskdefine.devsection = state;
      if (!state && this.isAddState == false) {
        this.taskdefine.feature = null;
        this.taskdefine.featurelist = null;
        this.taskdefine.feature = null;
      } else {
        this.errors.taskdefine.devsection = null;
        this.getfiltereddata("tdsection");
      }
    },
    tskdfnfnlitydata(state, value) {
      this.taskdefine.feature = null;
      this.taskdefine.feature = state;
      if (!state) {
        this.taskdefine.feature = null;
      } else {
      }
    },
    getfiltereddata(tblNm) {
      this.isLoading = true;
      this.pageoffset = 1;
      var url = "api/master/commonmaster/customselect";
      if (tblNm == "tdprojmodule") {
        url = "api/listing/getpromodulebyapplicationid";
        this.input = {
          projecteid: this.taskdefine.projectid,
          isfrompage: 'createpage'
        };
      } else if (tblNm == "tdappmodule") {
        url = "api/listing/getappmodulenamebypromoduleid";
        this.input = {
          projectmoduleid: this.taskdefine.module,
          isfrompage: 'createpage'
        };
      } else if (tblNm == "tdapp") {
        url = "api/listing/getappnamebyprojecteid";
        this.input = {
          projecteid: this.taskdefine.projectid,
        };
      } else if (tblNm == "tdappmdl") {
        url = "api/listing/getsectionamebyappmoduleid";
        this.input = {
          applicationmoduleid: this.taskdefine.appmodule,
        };
      } else if (tblNm == "tdsection") {
        url = "api/listing/getfunctionalityamebysectionid";
        this.input = {
          sectionmasterid: this.taskdefine.devsection,
        };
      } else if (tblNm == "taemployees") {
        url = "api/listing/getemployeesbytaskid";
        this.input = {
          taskid: this.assignment.taskid,
          taskfilter: "developer",
        };
      } else if (tblNm == "tasemployees") {
        url = "api/listing/getemployeesbytaskid";
        this.input = {
          taskid: this.statusassignment.taskdefineid,
          taskfilter: "developer",
        };
      } else if (tblNm == "btprojassign") {
        url = "api/master/commonmaster/getModuleDropdownOption";
        this.input = {
          tbl: "t38",
          projectMasterid: this.taskdefine.projectid,
        };
      }
      this.input.useremail= this.undt.username
      this.input.empcode= this.undt.userid
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (tblNm == "tdprojmodule") {
            this.taskdefine.projectmdllist = result.data.data;
          } else if (tblNm == "tdappmodule") {
            this.taskdefine.appmodulelist = result.data.data;
          } else if (tblNm == "tdapp") {
            this.taskdefine.applicoptions = result.data.data;
          } else if (tblNm == "tdappmdl") {
            this.taskdefine.sectionlist = result.data.data;
          } else if (tblNm == "tdsection") {
            this.taskdefine.featurelist = result.data.data;
          } else if (tblNm == "taemployees") {
            this.assignment.employeeoptions = result.data.data;
          } else if (tblNm == "tasemployees") {
            this.statusassignment.employeeoptions = result.data.data;
          } else if (tblNm == "btprojassign") {
            this.taskdefine.businesstasklist = result.data.data;
          }
        } else {
          if (tblNm == "taemployees") {
            this.assignment.employeeoptions = null;
            this.errors.assignment.empid = "employees not found";
          } else if (tblNm == "tasemployees") {
            this.statusassignment.employeeoptions = null;
            this.errors.statusassignment.empid = "employees not found";
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    selectBtmPriority(state, value) {
      this.tasktype.btmpriority = null;
      this.tasktype.btmpriority = state;
      if (!state) {
        this.tasktype.priorityerr = true;
      } else {
        this.tasktype.priorityerr = false;
      }
    },
    selectserPriority(state, value) {
      this.tasktype.key = null;
      this.tasktype.key = state;
    },
    selecttypePriority(state, value) {
      this.tasktype.type = null;
      this.tasktype.type = state;
    },
    selectrefPriority(state, value) {
      this.tasktype.references = null;
      this.tasktype.references = state;
    },
    selecttaskstatus(state, value) {
      this.tasktype.tstatus = null;
      this.tasktype.tstatus = state;
    },
    selectbtmDept(state, value) {
      this.tasktype.dept = null;
      this.tasktype.dept = state;
      if (!state) {
        this.tasktype.depterr = true;
      } else {
        this.tasktype.depterr = false;
      }
    },
    seltaskleveldata(state, value) {
      this.tasktype.level = null;
      this.tasktype.level = state;
      if (!state && this.isAddState == false) {
        this.tasktype.levelerror = true;
      } else {
        this.tasktype.levelerror = false;
      }
    },
    seltpprojectdata(state, value) {
      this.tasktype.projectid = null;
      this.tasktype.projectid = state;
      if (!state && this.isAddState == false) {
        this.errors.tasktype.projectid = "Project name is required";
      } else {
        this.errors.tasktype.projectid = null;
      }
    },
    showprojectname(prname) {
      let responsestr = "";
      let prlist = prname.split(",");
      if (prlist.length > 1) {
        for (var i = 0; i < prlist.length; i++) {
          responsestr += i + 1 + ". " + prlist[i] + " <br/>";
        }
      } else {
        for (var i = 0; i < prlist.length; i++) {
          responsestr += prlist[i];
        }
      }
      return responsestr;
    },
     getbusinesstasklistbyid(moduleid){
            this.isLoading = true;
            let url = "api/master/taskmaster/getBusinessTaskByID";
            this.input = {
                businesstaskmasterid:moduleid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowDatas = result.data.data;
                    this.isRedirectedFromBtmView= true
                } else {
                    this.editRowDatas = [];
                    this.$router.push('/businesstask/list')
                    Swal.fire({
                        title: "Failed!",
                        text: "UnAuthorised User!!",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
    onSelect() {
      this.file = e.target.files[0];
    },
    onFileChange(e) {
      let selectedFiles = e.target.files;
      for (let i=0; i < selectedFiles.length; i++)
      {
          var _validFileExtensions = ['png','jpg','jpeg','pdf', 'txt','docx', 'excel', 'csv', 'xps', 'ods', 'dbf', 'prn', 'dif', 'slk', 'xls', 'xlsx','zip','mp3', 'mp4', 'vlc', 'wav', 'mpeg', 'mpg'];    
          var blnValid = false;
          for (var j = 0; j < _validFileExtensions.length; j++) {
            var sCurExtension = _validFileExtensions[j];
            if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                blnValid = true;
                break;
            }
          }
          
          if (!blnValid) {
            Swal.fire({
                  title: "ERROR",
                  text: "Sorry, Invalid Type of Extension File..!!",
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
            })
            return false;
          }
          this.file.push(selectedFiles[i]);
      }
      if(this.file.length>0) {
          this.taskdefine.disblesavebtn=true
      }
      document.getElementById('attachment').value = ""
    },
        removeImage (index) {
            if(this.file.length>0) {
                this.file.splice(index, 1);
                this.taskdefine.filedescp.splice(index, 1);
                var removeimg = JSON.parse(this.taskdefine.attachmentnew)
                if(removeimg){
                var remove = removeimg.data.splice(index,1)
                if(remove.length > 0)
                this.taskdefine.attachmentnew = JSON.stringify({data:removeimg.data})
                else{
                  this.taskdefine.attachmentnew = null
                }
              }
            }
            if(this.file.length < 1) {
            this.taskdefine.disblesavebtn = false
           }            
        },
      removes3file(key,data) {
            if(data.length>0) {                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to undo!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        if(this.editRowData.id !== undefined) {
                            let deletefile = data[key];
                            data.splice(key, 1);
                            const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                            let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                            axios({
                            method: "POST",
                            url: 'api/master/taskmaster/s3Deletetask',
                            data: {
                                id:this.editRowData.id,
                                attachment:payload,
                                deletefile: JSON.stringify(deletefile),
                                useremail: this.undt.username,
                                empcode: this.undt.userid, 
                            },
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            if (result.data.status) {
                                Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'success'
                                )
                            } else {
                            Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'error'
                                )                        
                            }
                        });
                        } else {
                            Swal.fire(
                                'Taskid!',
                                'Taskid should not blank',
                                'info'
                                )
                        }    
                    }
                })
            }
      },
    saveBusinessDocuments(btmtaskfiles){
      this.input=[]
      if(btmtaskfiles && btmtaskfiles.length) {
        let temparr = []
        for(let i=0; i<btmtaskfiles.length; i++) {
          let attachinfo = btmtaskfiles[i]
          let obj = {
            documentid:null,
            documentname:attachinfo.link,
            title:attachinfo.title ? attachinfo.info: `task doc desc ${i+1}`,
            desc: attachinfo.info ? attachinfo.info: `task doc desc ${i+1}`,
            comment: `task doc comment ${i+1}`,
            version:'v1',
            createdby: this.undt.username,
            taskid: this.tasktype.businesstaskid ,
            projectname: this.tasktype.projectnameist,
            projectid: parseInt(this.tasktype.projectist),
            projectmodulename: this.tasktype.projectmodulenamenameist,
            projectmoduleid: parseInt(this.tasktype.projectmoduleist),
            businesstaskmoduleassignmentid:this.tasktype.businesstaskmoduleassignmentid,
            businesstaskmasterid:this.tasktype.businesstaskid,
            useremail: this.undt.username,empcode: this.undt.userid,
          }
          temparr.push(obj)
        }
        this.input = temparr
        if(this.input.length>0){
          let url = "api/workstep/bulkinsert";
          axios({
            method: "POST",
            url: url,
            data: {document:this.input},
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
          });
        } else {
          this.isLoading = false;
        }
      }
    },
    onUpload(){
      if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();  
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
              formData.append("imagefile", this.file[index]);
              maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail",this.undt.username);
            formData.append("moduletype", 'task');
            formData.append("fcount", parseInt(this.file.length));
            
            if (maxsize > 1000 * 1000 * 10) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 10mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.taskdefine.disblesavebtn=true
                return false; 
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading=false; 
                this.taskdefine.disblesavebtn=false
                if(result.data.status){
                      const bindinfo = result.data.data.map((file, index) => {
                        return {"link":file,"info":this.taskdefine.filedescp[index]}                
                    });
                    let jsonobj = {data:bindinfo}
                    this.taskdefine.attachmentnew = JSON.stringify(jsonobj);
                    if(this.isRedirectedFromBtmView && this.taskmodule == 1) {
                      this.saveBusinessDocuments(bindinfo)
                    }
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.taskdefine.attachment=null
                }
            }).catch(e => {
            this.displayError(e)
          })
        }else{
              this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Select image/docs/videos',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.taskdefine.attachment=null
        }
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.taskdefine.attachment=null
      }
    }
  },
};
</script>
<style>
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
