<template>
    <div class=" content p-1 m-1">
        <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper mt-0">
            <h2>Reset Password</h2>
            <section>
            <div class="card p-1 py-2">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="container px-0">
                                    <div class="form-group">
                                        <label class="form-label" for="login-email">Email Address : </label>
                                        <input class="form-control" id="login-email" type="text"  v-model="email" placeholder="Enter Email ID" aria-describedby="login-email" autofocus="" tabindex="1"  v-bind:class="{'form-control':true, 'error' : !validEmail(email) && emailBlured}" v-on:blur="emailBlured = true" />
                                        
                                    </div>
                                </div>
                                <div class="form-group container_capcha">
                                  <vue-recaptcha
                                    sitekey="6Len0uMoAAAAAIICg4sfx3ZURVXvtBHHCT5IjiSy"
                                    @verify="onCaptchaVerified"
                                    size="invisible"
                                    ref="recaptcha"
                                  ></vue-recaptcha>
                                </div>
                                <div class="container text-right px-0">
                                <button class="btn btn-relief-primary  mb-2" @click="sendOTP()" :disabled="!email">
                                      Sent OTP
                                  </button>
                                </div> 
                            </div>
                            <div class="col-sm-6" v-if="isotpsent">
                                <div class="container px-0">
                                    <div class="form-group">
                                        <label class="form-label" for="login-email">OTP : </label>
                                        <input class="form-control" id="" type="number" v-model="otp"  placeholder="Enter OTP" tabindex="1"/>
                                        <div class="error-message small-text red-text">{{ otpError }}</div>

                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="login-email">New Password</label>
                                        <input class="form-control" id="" type="text" v-model="newpassword"  placeholder="Enter Password" tabindex="1"/>
                                        <div class="error-message small-text red-text">{{ newPasswordError }}</div>

                                        
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="login-email">Confirm Password</label>
                                        <input class="form-control" id="" type="text" v-model="confirmpassword"  placeholder="Enter Confirm Password" tabindex="1"/>
                                        <div class="error-message small-text red-text">{{ confirmPasswordError }}</div>

                                        
                                    </div>
                                    <div class="form-group container_capcha">
                                        <vue-recaptcha
                                            sitekey="6Len0uMoAAAAAIICg4sfx3ZURVXvtBHHCT5IjiSy"
                                            @verify="onCaptchaVerified"
                                            size="invisible"
                                            ref="recaptcha"
                                        ></vue-recaptcha>
                                        </div>
                                    <div class="container text-right px-0">
                                <button class="btn btn-relief-primary  mb-2 " @click="validateOtpAndSavePassword()">
                                      Proceed
                                  </button>
                                </div> 
                                </div>
                            </div>
                       </div>
                    </div>
                   
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" v-if="isotpsent">
                        <div class="policy is-fullwidth restpassword_instruction_bg">
                      <h5 class="font-weight-bolder">Password Policy</h5>
                      <ul class="restpassword_instruction">
                        <li>Password must be atleast 8 characters.</li>
                        <li>Password must contain 2 Lowercase.</li>
                        <li>Password must contain 2 Uppercase.</li>
                        <li>Password must contain 2 number.</li>
                        <li>Password must contain 2 special char.</li>
                      </ul>
                    </div>
                    </div>
                </div>

            </div>
            </section>

        </div>
    </div>





</template>
<script>
import axios from 'axios'
import apiUrl from '../constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { VueRecaptcha } from 'vue-recaptcha';
import commonMethods from '../utils/commonMethods';


export default({
    name:'ForgetPassword',
    mixins: [ commonMethods ],

    components: {
        Loading,
        VueRecaptcha,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            tokendata:null,
            email:'',
            emailBlured:false,
            isotpsent: false,
            captcha_res: '',
            btndis: true,
            sendOTPBtn: true,
            otp:'',
            confirmpassword:'',
            newpassword:'',
            newPasswordError: '',
            confirmPasswordError: '',
            otpError:'',

        }  
    } ,
    mounted(){
        sessionStorage.removeItem('pathToLoadAfterLogin');
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt = userdata;
            this.un=userdata.firstname+' '+userdata.lastname
            this.udata=userdata
            this.tokendata = window.localStorage.getItem('token');
        }
    },
    methods:{
        validEmail : function(email) {
            let re = /(.+)@(.+){2,}\.(.+){2,}/;
            return re.test(email.toLowerCase());
        },
        executeRecaptcha(response){
          return new Promise((resolve, reject) => {
       
            this.$refs.recaptcha.execute();
            const timeout = setTimeout(() => {
            reject(new Error('reCAPTCHA verification timeout'));
            }, 50000); 

            this.captchaPromiseResolve = (responseToken) => {
            clearTimeout(timeout); 
            resolve(responseToken);
            };
            this.captchaPromiseReject = (error) => {
            clearTimeout(timeout); 
            reject(error);
            };
        });
        },
        onCaptchaVerified(responseToken) {
            if (responseToken) {
                this.captchaPromiseResolve(responseToken);
            } else {
                this.captchaPromiseReject(new Error('reCAPTCHA verification failed'));
            }
        },

    recaptchaVerified(response) {
        this.captcha_res = response
        this.btndis = false
        this.sendOTPBtn = true

    },
    recaptchaExpired() {
        this.captcha_res = ''
        this.btndis = true
        this.sendOTPBtn = true

    },
   async sendOTP(){
    // this.isotpsent = true
    let responseToken = false
        try{
            responseToken = await this.executeRecaptcha();
        }
        catch(e) {
            this.isLoggingIn = false;
            let message = "Failed to verify CAPTCHA, Please Refresh the page";
            if (e?.response?.data.message) {
                message = e.response.data.message;
            }
            this.sweetAlertSnackbar(message, "error", "top-right");
        }
          this.isLoading = true;
          const response = await  axios.post('api/forgetpassword/sendotp',{
            email:this.email,
            captcha_res: responseToken ? responseToken:'a$nkwejhfrflbrjkjuiwhhwiufhuh343532734878BJJWJH@KJHKJ@HEKHKHE#H'
        });
        if(response){
        this.isLoading = false
            this.isotpsent = true
            this.sweetAlertSnackbar("OTP Send on Mail", "success", "top-right");
        }
      },

      checkPassword: function () {
        let password = this.newpassword.toLowerCase()
        return password.match(/firstcry|fc|goodlife/g)
      },
    
      validateOtpAndSavePassword: async function () {
        let responseToken = false
        this.newPasswordError = '';
        this.confirmPasswordError = '';
        this.otpError=''
        try {
            responseToken = await this.executeRecaptcha();

          if (!this.otp) {
            this.otpError = 'Please enter OTP';
            return;
          }
          if (!this.newpassword) {
            this.newPasswordError = 'Please enter new password.';
            return;
          }

            if (!this.confirmpassword) {
                this.confirmPasswordError = 'Please enter confirm password.';
                return;
            }
          if (this.newpassword.length < 8) {
            this.newPasswordError = 'Password length must be at least 8 characters.';
            return
            }
          let pattern =
            /(?=(.*\d){2})(?=(.*[a-z]){2})(?=(.*[A-Z]){2})(?=(.*[!@#$%]){2})/
          if (!pattern.test(this.newpassword)) {
                this.newPasswordError= 'Password does not meet criteria.';
            return
          }

          if (this.newpassword !== this.confirmpassword) {
                this.confirmpassword= 'Password missmatch';
            return
          }

        } catch (e) {
            let message = "Failed to verify CAPTCHA, Please Refresh the page";
            if (e?.response?.data.message) {
                message = e.response.data.message;
            }
        }
        this.isLoading = true;
        const response = await  axios.post('api/forgetpassword/verifyOTPandResetPassword',{
            email:this.email,
            otp: this.otp,
            newPassword: this.newpassword,
            // cams_captcha_res: this.cams_captcha_res,
            captcha_res: responseToken ? responseToken:'a$nkwejhfrflbrjkjuiwhhwiufhuh343532734878BJJWJH@KJHKJ@HEKHKHE#H'
        });
        if(response){
        this.isLoading = false
            Swal.fire({
                    title: "Success",
                    text: "Password Reset Successfully.",
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-success"
                    },
                    buttonsStyling: !1
                
            }).then(function() {
            window.location.href = "/#/login";
            })
        }

      },

    }
})
</script>
<style scoped>
.small-text {
  font-size: 12px; /* Adjust the font size as needed */
}

.red-text {
  color: red;
}
</style>
