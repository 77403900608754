<template>
 <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1">
        <section id="card-navigation modern-horizontal-wizard">
            <div class="content-header-left mb-2 pt-1 pad_top_mar_bot-5">
                <div class="container-fluid">
                    <div class="row breadcrumbs-top">
                        <div class="col-md-9 content-header-left pl-0 ">
                            <h3 class="content-header-title float-left mb-0">Daywise Testcases Pass/Fail report - June 2023</h3>
                            <div class="breadcrumb-wrapper"> 
                                <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/reports/DaywisepassfailReport'>Day Wise Pass Fail Report</router-link></li>
                                 </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                        <div class="container-fluid">
                            <div class="row row fc_my-05">
                                <div class="w-auto px-1 mobile-padding-0-2">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon
                                                  size="1.5x"
                                                  class="custom-class avatar-icon font-medium-3"
                                             ></search-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search Day Wise Testcases Pass/Fail Report</h4>
                                </div>
                                <div class="ml-auto d-flex pr-1">
                                    <span class="rotetions_view_arrow"> 
                                     <em class="las la-angle-down"></em>
                                     </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                        <div class="card-body px-sm-mobile-10">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker"> 
                                    <label for="" class="d-block">From Date</label>
                                    <date-picker placeholder="Select Action Start Date" v-model="search.actionstartdate" valueType="format" class="startdate" id="StartDate" :disabled-date="notBeforeToday"></date-picker>
                                    <div class="errorinputmsg" v-if="this.errors.actionstartdate">{{ this.errors.actionstartdate }}</div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker"> 
                                    <label for="" class="d-block">To Date</label>
                                    <date-picker placeholder="Select Action End Date" v-model="search.actionenddate" valueType="format" class="startdate" id="EndDate" :disabled-date="disabledBefore"></date-picker>
                                    <div class="errorinputmsg" v-if="this.errors.actionenddate">{{ this.errors.actionenddate }}</div>
                                </div>
                              <div class="col-sm-6 col-md-6 col-lg-3">
                               <label class="form-label lable-left">Project Name</label>
                               <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject" :multiple="true" :clear-on-select="true"/>   
                              </div>
                              <div class="col-sm-6 col-md-6 col-lg-3">
                               <label class="form-label lable-left">Business Task Name</label>
                               <treeselect class="projectmdllistdd" placeholder="Enter min 3 chars to search Business Task Name" v-model="search.searchBusinesstaskname" :options="search.businesstasknameopts" v-on:search-change="businessNameChangeSearch" @input="clearBusinessNameList"/>                      
                             </div>
                                <div class="col-sm-6 col-md-6 col-lg-3">
                               <label class="form-label lable-left">QA Manager</label>
                               <treeselect class="projroleassprojectdd" placeholder="Select QA Manager" :options="tasktype.qamnglist" v-model="search.qamanager" :multiple="true" :clear-on-select="true"/> 
                               
                               </div>
                               <div class="col-sm-6 col-md-6 col-lg-3">
                               <label class="form-label lable-left">QA Lead</label>
                               <treeselect class="projroleassprojectdd" placeholder="Select QA Lead" :options="tasktype.qaleadlist" v-model="search.qalead" :multiple="true" :clear-on-select="true"/> 
                               </div>
                               
                                <div class="col-sm-6 col-md-6 col-lg-3" style="padding-left: 0px;padding-right: 0px;">
                                  <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getprojectlist(search)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                                  <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" id="domain-list">
                    <div class="card-body p-0 ps ps--active-x">
                        <div class="table-responsive" >
                            <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc"> 
                                <thead>
                                    <tr> 
                                        <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                                        <th scope="col" class="text-center second-col-sticky">Finish Date</th>
                                        <th scope="col" class="text-center second-col-sticky">Values</th>
                                        <th scope="col" class="text-center second-col-sticky">Value 1</th>
                                        <th scope="col" class="text-center second-col-sticky">Value2</th>
                                        <th scope="col" class="text-center second-col-sticky">Grand Total</th>
                                        

                                    </tr>
                                </thead>
                            
                  
                 
                            </table>
                        </div>
                         </div>    
                </div>
            

        </section>
     </div>
 </div> 
</template>
<script>

import axios from "axios";
import VueElementLoading from 'vue-element-loading';
import apiUrl from "../../constants";
import Loading from 'vue-loading-overlay';
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
    name: 'DaywisepassfailReport',
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    DatePicker,
    PlusCircleIcon,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    SearchIcon,
    ExternalLinkIcon,
    XIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return{
      
        projectnameList:[],
         search:{
          searchprojectname :null,
         },
        domainnamelist:[],
      search: {
        actionstartdate:null,
        actionenddate: null
      },
      errors: {
        actionstartdate: null,
        actionenddate: null
      },
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        currPageNum: 0,
        pageoffset:1,
        isLoading: false,
        pagefeatures:[],
        showtabledata:true,
        showproject:false,
        modulelist:[],
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',

        tasktype:{
                
                
                qamanager:null,
                qamnglist:[],
               
                
            },
    }
    
    
    
  },

  mounted(){
 
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.getprojectlist(rowdata);
        
      }
    }
    
    $(".filter_rotation_onlick").click(function(){
            $(this).toggleClass("common_arrow_rotetion");
          });
  },
  methods:{
    notBeforeToday: function (date) {
     this.search.actionenddate = null;
      return date > new Date(new Date().setHours(0, 0, 0, 0));     
   },
   disabledBefore(date) {
      let dayBefore = moment(this.search.actionstartdate).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
      beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday;
    },
   
    mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },
      
      
      selectproject(state, value) {
      
      this.pageoffset=1
      if(!state){
        console.log('no project selected')
      }else{
        this.search.searchProjectname = state;
        this.getProjectQAdata(478,state);
        this.getProjectQAdata(509,state);
        this.searchactiveflag = false;
       
      }
    },
      projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    getprojectlist(rowdata) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        //empid: this.undt.userid,
        empcode: this.undt.userid,
        
        isFilterSelect: 0,
        isFilterPayload: rowdata,
        
      };
      let url = "api/reports/projectlistreportFunction";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
         
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getProjectQAdata(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==509){
                        this.tasktype.qaleadlist=result.data.data.rows;
                    }else if (roleid==478){
                        this.tasktype.qamnglist=result.data.data.rows;
                    }
                }else{
                    if(roleid==509){
                        this.tasktype.qaleadlist=[]
                    }else if (roleid==478){
                        this.tasktype.qamnglist=[];
                    }
                }
            }).catch(e => {
            this.displayError(e)
          })
        },
        clearBusinessNameList(state) {
            if(!state) {
                this.search.businesstasknameopts = null
            }
        },
        businessNameChangeSearch(node) {
            if(node && node.length>2) {
                axios({
                    'method': 'POST',
                    'url': 'api/searching/getBusinessTaskname',
                    'data': {
                        empid: parseInt(this.undt.userid),
                        taskname: node.toLowerCase(),
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                        tabflag: (this.isListDetailsDataActives == true) ? 'true' : 'false',
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        this.search.businesstasknameopts=result.data.data
                        localStorage.setItem('businesstasknameopts', JSON.stringify(result.data.data))
                    } else {
                        this.search.businesstasknameopts= null
                        localStorage.removeItem('businesstasknameopts')
                    }
                }).catch(e => {
                this.displayError(e)
              })
            }
        },
        
    
  },
  dateMONFormat(value){
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
   
}

  
</script>

