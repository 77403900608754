<template>
<label>
<span class="click-zoom" >
    <input type="checkbox" />
    <img alt="" :src="presignurldata" style="width:80px;height:60px"  />&nbsp;&nbsp;&nbsp;&nbsp;
    <a :href="presignurldata" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor"></download-icon></a>
    </span>
    </label>
</template>
<script>
import axios from 'axios'
import { DownloadIcon} from 'vue-feather-icons'
export default {
    name: 'presignurl',
    props: ['getUrl'],
    components:{
        DownloadIcon
    },
    data() {
        return {
            presignurldata: ''
        }
    },
    beforeMount() {
        this.getLogtimeDownloadLink()
    },
    methods:{
        getLogtimeDownloadLink() {
            var userdata = window.localStorage.getItem('userdata');
            userdata = JSON.parse(userdata)
            this.undt = userdata
            var input =[]
            input.push({link:this.getUrl,useremail: this.undt.username,empcode: this.undt.userid})
            let payload = {
            documents:input,
            useremail: this.undt.username,empcode: this.undt.userid
            };
            axios.post("/api/imageupload/getdownloadlink", payload)
            .then((result) => {
            if(result.data.status) {
                if(result.data.data.length>0) {
                    this.presignurldata =result.data.data[0].presignurl
                }
            }
            });
        }
    }
}
</script>
