<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard" >
          <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
          <div class="container-fluid">
              <div class="row breadcrumbs-top">
                <div class="col-md-12 content-header-left px-0">
                  <h3 class="content-header-title float-left mb-0">Ticket Roaster Configuration Details </h3>
                  <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/configuration/roasterlist'>Ticket Roaster Configuration  List</router-link></li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="card border-warning filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor: pointer" v-on:click="mysearchrequestollapse">
                <div class="container-fluid" >
                <div class="row fc_my-05 ">
                  <div class="w-auto px-1 mobile-px-0 ">
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                      </div>
                    </div>
                    <h4 class="card-title d-inline-flex ml-1 ">Search Ticket Roaster Configuration Details </h4>
                  </div>
                  <div class="ml-auto d-flex pr-md-1">
                  <div  class="ml-auto px-sm-1 px-sm-0 text-right mobile-px-0">
                    <div class="input-group-append float-right">
                      <button id="commonaddmodule" class="btn btn-sm btn-relief-success" @click="redirecttourl('create', null)"> 
                        <span class="mobile_hide_bt">Create Configuration</span> <plus-circle-icon size="1.5x" class="custom-class "></plus-circle-icon>
                      </button>
                    </div>
                  </div>
                  <span class="rotetions_view_arrow"> 
                    <em class="las la-angle-down"></em>
                  </span>
                  </div>
                </div>
                </div>
              </div>
              <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]">
                <div class="card-body px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectname" @input="selSearchProject" 
                      :multiple="true" :clear-on-select="true" :options="search.projectnameopts"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Field Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Field name" v-model="search.searchfieldname" :options="search.fieldlist" @input="selissuecsfield"/>
                  </div>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Values</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Field name" v-model="search.searchvalue" :options="search.valueslist"/>
                  </div> -->
                  <div class="col-md-4" v-if="this.inputtypeselection">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Values</label>
                        
                            <treeselect v-if="this.inputtypeselection == 'drop-down'"
                            class="projectmdllistdd capitalisetext"
                            placeholder="Select Values"
                            v-model="search.searchvalue"
                            :options="search.valueslist"
                          />
                          <input v-if="this.inputtypeselection == 'text'" class="form-control"  v-model="search.searchvalue" type="text" placeholder="Enter Text">
                          <input v-if="this.inputtypeselection == 'number'"  class="form-control"  v-model="search.searchvalue" type="number" placeholder="Enter Number">
                          <date-picker v-if="this.inputtypeselection == 'date'" placeholder="Select date" v-model="search.searchvalue" valueType="format" class="enddate w-100" :default-value="new Date()"></date-picker>

                        </div>
                      </div>
                       <!-- for fail condition -->
                    <div class="col-md-4" v-else>
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Values</label>
                          <treeselect 
                            class="projectmdllistdd capitalisetext"
                            placeholder="Select Values"
                            v-model="search.searchvalue"
                            :options="search.valueslist"
                          />
                        </div>
                      </div>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Shift From Days</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Days" v-model="search.days" :options="search.dayslist"/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Shift To Days </label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Days" v-model="search.day" :options="search.dayslist"/>
                    </div>
                  </div> -->

                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label for="">Shift Date From</label>
                    <date-picker placeholder="Select Shift Date From" v-model="search.fromdate" valueType="format" class="startdate" @input="selectedfromdate" v-on:change="validateDate('fromdate', $event)" ></date-picker>
                    <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>
                    <div class="errorinputmsg" v-if="errors.fromdate">{{ errors.fromdate }}</div>

                  </div>
                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label for="">Shift Date To</label>
                    <date-picker placeholder="Select Shift Date To" v-model="search.todate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate" v-on:change="validateDate('todate', $event)"></date-picker>
                  </div>

                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Agent Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="select agent name" :multiple="true" :clear-on-select="true" :options="search.allotedtolist" v-model="search.allotedto"/>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <!-- <label class="form-label lable-left">Active/Inactive</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select activation" :multiple="true" :clear-on-select="true" :options="isactivelist" v-model="search.isactive"/> -->
                      <div class="form-group mb-1">
                        <label for="allotedto">Active/Inactive</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select activation" v-model="search.isactive" :options="isactivelist" :multiple="true" :clear-on-select="true"/>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-8 mb-1">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" v-if="isBothDateSelected" disabled><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="roasterlist(search,1)" v-else><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2"  @click="ExportXL()" v-if="isBothDateSelected" disabled><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                      <button type="move" class="btn btn-relief-primary mt-2"  @click="ExportXL()" v-else><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                      <button type="move" class="btn btn-relief-success mt-2 ml-1"  @click="downloaddata()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Download Sample</span></button>
                      <label for="attachment" class="btn-sm btn btn-relief-secondary mt-2 ml-1">Select CSV DOCS</label>
                      <button class="btn btn-relief-secondary  mt-2 ml-1"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                      <input type="file" id="attachment" hidden :accept="this.accepttype" ref="file" @change="onFileChange"/>

                      <div class="col-md-12 px-0" style="margin-top:24px">
                        <div class="form-group">
                          <div class="form-group">
                            <div class="table-responsive" v-if="file.length>0">
                              <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                  <tr>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(image, key) in file" :key="key">
                                    <td>{{ image.name }}</td>
                                    <td>
                                      <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div id="scrollToListGrid"></div>
          <div class="row">
            <div class="col-md-12">
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="row" id="table-small">
                    <div class="col-12">
                      <div class="card">
                        <div class="table-responsive">
                          <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="Task Time Log Details">
                            <thead>
                              <tr>
                                <th scope="col" class="align-middle first-col-sticky py-1" style="min-width: 45px;">Sr#</th>
                                <th scope="col" class="align-middle" style="min-width: 150px;">Project Name</th>
                                <th scope="col" class="align-middle" style="min-width: 170px;"> Field Name</th>
                                <th scope="col" class="align-middle" style="min-width:170px" >Values</th>
                                <!-- <th scope="col" class="align-middle" style="min-width:90px">Shift From Days</th>
                                <th scope="col" class="align-middle" style="min-width:170px">Shift To Days</th> -->
                                <th scope="col" class="align-middle" style="min-width:170px">Shift From Date</th>
                                <th scope="col" class="align-middle" style="min-width:170px">Shift To Date</th>
                                <th scope="col" class="align-middle" > Shift From Time</th>
                                <th scope="col" class="align-middle" > Shift To Time</th>
                                <th scope="col" class="align-middle" style="min-width:150px">Agent Name</th>
                                <th scope="col" class="align-middle" style="min-width:150px">Roaster Type</th>
                                <th scope="col" class="align-middle">Created date</th>
                                <th scope="col" class="align-middle ">IsActive</th>
                                <th scope="col" class="align-middle">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(data, index) in roastrlist">
                                <tr v-bind:key="data.moduleid">
                                  <td class="centeralign first-col-sticky">{{ index + 1 + currPageNum * pagelimit }}</td>
                                  <td>
                                    <span class="font-weight capitalisetext">{{data.projectname}}</span>
                                  </td>
                                  <td class="rolelistdd">{{ data.fieldname ? data.fieldname :'NA' }}</td>
                                  <td> 
                                    <div v-if="data.fieldname && data.fieldname=='Ticket Category'" class="rolelistdd" >{{ data.category ? data.category :'NA'}}</div>
                                    <div v-else-if="data.fieldname && data.fieldname=='Ticket Sub Category'" class="rolelistdd" >{{ data.subcategory ? data.subcategory :'NA'}}</div>
                                    <div v-else-if="data.fieldname && data.fieldname=='Ticket Type'" class="rolelistdd" >{{ data.typename ? data.typename :'NA'}}</div>
                                    <div v-else class="rolelistdd">{{ data.fieldvalue ? data.fieldvalue :'NA' }}</div>
                                  </td>
                                  <!-- <td class="rolelistdd" >{{ data.fromshiftday}}</td>
                                  <td class="rolelistdd" >{{ data.toshiftday}}</td> -->
                                  <td class="rolelistdd" >{{ data.shiftfromdate ? dateMonthFormatwithouttime(data.shiftfromdate) :'NA'}}</td>
                                  <td class="rolelistdd" >{{ data.shifttodate ? dateMonthFormatwithouttime(data.shifttodate) :'NA'}}</td>
                                  <td class="rolelistdd" >{{ data.fromshifttime}}</td>
                                  <td class="rolelistdd" >{{ data.toshifttime}}</td>  
                                  <td class="font-weight-normal">{{ data.empname }}</td>
                                  <td class="font-weight-normal">{{ data.roastertype ? data.roastertype : 'NA' }}</td>
                                  <td class="text-nowrap font-weight-normal">{{ dateMonthFormat(data.createddate) }}</td>
                                  <td class="text-center text-capitalize"  v-if="data.isactive === 1">
                                    <em class="las la-check-circle custom-class"  style="color: #28c76f;font-size: 24px;"></em>
                                  </td>
                                  <td class="text-center text-capitalize" v-else>
                                    <em class="las la-times-circle custom-class" style="color: #b82323;font-size: 24px;"></em>
                                  </td>
                                  <td class="clsaction" >
                                    <div class="dropdown">
                                      <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                        <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                      </button>
                                      <div class="dropdown-menu" v-if="loginusername == data.createdby || pagefeatures.edittask">
                                        <a class="dropdown-item devEditEle" @click="redirecttourl('edit', data)" href="javascript:void(0);">
                                          <div class="d-flex align-items-center">
                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                            <span>Edit</span>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </template>
                              <template v-if="roastrlist.length == 0">
                                <tr>
                                  <td colspan="14" style="text-align: center;">No record found</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pageCenter text-center">
                <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="tablepoup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                  <h4 class="modal-title">Document Invalid Details:</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                          <div class="row" id="table-small">
                            <div class="col-12">
                                <template v-for="(datax, indexx) in invaliddetails">
                                  <p v-bind:key="indexx">
                                    Sr.{{ datax.sr+':' }} {{ datax.roastercode ? datax.roastercode +',':''}} {{ datax.projectname ? datax.projectname+',' :'' }} {{ datax.projectid ? datax.projectid+',' :'' }} {{  datax.fieldname ? datax.fieldname+',' :'' }} {{ datax.fieldid ? datax.fieldid+',' :'' }} {{  datax.shiftfromdate ? datax.shiftfromdate+',' :'' }} {{ datax.shifttodate ? datax.shifttodate+',' :''}} {{  datax.shiftfromtime ? datax.shiftfromtime+',' :'' }} {{ datax.shifttotime ? datax.shifttotime+',' :''}} {{  datax.agentemailid ? datax.agentemailid+',' :'' }} {{ datax.roastertype ? datax.roastertype+',' :''}} {{ datax.isactive ? datax.isactive :''}}
                                  </p>
                                </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>    
              </div>
            </div>
        </div>
        </section>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import vSelect from "vue-select";
  import { EditIcon,ListIcon,GridIcon,MessageSquareIcon,PlusCircleIcon,XIcon,MoreVerticalIcon,Edit2Icon,FileTextIcon,MonitorIcon,UserIcon,SearchIcon,ExternalLinkIcon,PlusSquareIcon,EyeIcon,PlusIcon,MinusSquareIcon,ClipboardIcon , Trash2Icon} from "vue-feather-icons";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import Pagination from "vue-pagination-2";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import profilepic from '../profilepic.vue';
  import commonMethods from '../../utils/commonMethods';
  
  export default {
    name: "TaskLogList",
    mixins: [ commonMethods ],
  
    components: {
      Loading,ListIcon,GridIcon,
      EyeIcon,
      PlusSquareIcon,
      VueElementLoading,
      vSelect,
      ExternalLinkIcon,
      EditIcon,
      Treeselect,
      XIcon,
      Pagination,
      PlusIcon,
      SearchIcon,
      MinusSquareIcon,
      ClipboardIcon,
      MessageSquareIcon,
      FileTextIcon,
      MonitorIcon,
      profilepic,PlusCircleIcon,MoreVerticalIcon,Edit2Icon,DatePicker,UserIcon,Trash2Icon
    },
    data() {
      return {
        componentKey: 0,
        currentTaskDescription: {},
        taskcodelist:null,
        showcreatetaskbtn: false,
        clickedTaskData: null,
        isBothDateSelected: false,
        systemEngieerRole: apiUrl.systemEngieerRole,
        search: {
          searchfieldname:null,
          searchprojectname: null,
          searchvalue:null,
          allotedto: null,
          allotedtolist:null,
          fieldlist:null,
          projectnameopts:null,
          valueslist:[],
          searchvalue:null,
          days:null,
          day:null,
          dayslist: apiUrl.dayslist,
          fromdate: null,
          todate: null,
          isactive: null,
        },
        isSearchRequestActive: false,
        selectedLevel: 10,
        level: 1,
        isLoading: false,
        fullPage: true,
        roastrlist: [],
        showAddForm: false,
        selected: null,
        editRowData: [],
        glbMdl: "",
        glbst: "",
        TimeFormat: apiUrl.TimeFormat,
        taskversionlist: [],
        taskBlured: false,
        valid: false,
        projvalue: null,
        applvalue: null,
        startdate: null,
        isAddState: false,
        totalcount: 0,
        pageCount: 0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures: [],
        projectnameist: [],
        loginuscondition: "",
        isDetActive: false,
        isListDetActive:false,
        isListActive:false,
        displayblk:'displayblk',
        isgridView:false,
        inputtypeselection:null,
        blog_list_wrapper :'blog-list-wrapper',
        card: "card",
        isRequestActive: false,
        card_content: "card-content",
        border_warning: "border-warning",
        collapse: "collapse",
        showtabledata:true,
        allottedto: null,
        loginusername:'',
        sitebluecolor:"sitebluecolor",
        activegridlink:true,
        activelistlink:false,
        countStatus: null,
        taskcountClick:false,
        tokendata:null,
        isresponce:false,
        isAdminRole: false,
        isThisBusinessRole: false,
        roleid: 0,
        TKRosterFields:apiUrl.TKRosterFields.sort((a, b) => a.label.localeCompare(b.label)),
        errors:{
          fromdate: null
        },
        isactivelist: [
          {id: 1, label:'Active'},
          {id: 0, label:'Inactive'},
        ],
        accepttype:apiUrl.uploadfiletypecsv,
        file:[],
        filedescp: [],
        attachmentnew: null,
        attachment: null,
        csvfiledata: [],
        jsonData: [],
        invaliddetails: []
      };
    },
    mounted() {
      let permission = this.$Service.verifyAccess();
      if(permission){
        let userdata = window.localStorage.getItem("userdata");
        if (userdata) {
          userdata = JSON.parse(userdata);
          this.undt = userdata;
          this.tokendata = window.localStorage.getItem('token');
          this.loginuscondition = userdata.userid;
          this.loginusername=userdata.username
          this.roleid = this.undt.roleid;
          this.isThisBusinessRole = true;
          this.search.isactive =[1];
          this.getProjectAllocatedToEmp();
          this.roasterlist()
        }
      }
      $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
    },
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
          if (element.page === '/configuration/roasterlist') {
            this.pagefeatures[element.featurename] = element.featureaccess
          }
        })
      },
    methods: {
      mysearchrequestollapse: function () {
        this.isSearchRequestActive = !this.isSearchRequestActive;
      },
      myListView: function () {
        this.isListDetActive = true;
        this.isgridView=true;
        this.activegridlink=false
        this.activelistlink=true
        $('html, body').animate({
            scrollTop: $("#scrollToListGrid").offset().top -70
          });
      },
      myGridView(){
          this.isListDetActive = false;
          this.isgridView=false;
          this.activegridlink=true
          this.activelistlink=false
          $('html, body').animate({
            scrollTop: $("#scrollToListGrid").offset().top -70
          });
      },
      redirecttourl:function(btnstate,rowdata){
        if(rowdata){
          rowdata.backbtnstate=btnstate
         if(btnstate=="edit"){
            this.$router.push({ 
              path: `/configuration/roasterupdate/?rid=${rowdata.id}`,
              params: {
                backbtnstate:btnstate,
                clickeddata:rowdata
              }
            })
          } 
        }else{
        if(btnstate=="create"){
          this.$router.push({
            name: 'Roaster',
            path: `/configuration/roaster`,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        }
      }
        
      },
      resetRecords: function () {
        this.search.searchprojectname = null;
        this.search.fieldname = null;
        this.search.allotedto = null;
        this.search.fromdate =null;
        this.search.todate =null;
        this.search.searchvalue = null;
        this.search.searchfieldname = null;
        this.search.isactive =[1];
        this.page=1;
        this.roasterlist();
      },
      clickCallback: function (pageNum) {
        this.pageoffset = pageNum;
        this.page = pageNum;
        this.currPageNum = pageNum - 1;
        this.roasterlist(this.search);
        setTimeout(function(){
          $('html, body').animate({
              scrollTop: $("#scrollToListGrid").offset().top -70
          });
        }, 500);
      },
      getfieldame(field) {
        if(this.search.fieldlist && this.search.fieldlist !=null && this.search.fieldlist.length>0){        
          let fiel = this.search.fieldlist.filter(fiel => fiel.id == field)
          if(fiel && fiel.length) {
            return fiel[0].label
          }
          return null
        }
      },
      roasterlist(search,flag) {
        this.isLoading = true;
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isactive: this.search.isactive,
          limit: apiUrl.LIMIT,
        }
        if(flag == 1){
          this.input.offset = 1;
          this.currPageNum = 0;
        }else{
          this.input.offset = this.pageoffset;
        }
        if(search) {
          this.input.projectid = search.searchprojectname;
          this.input.fieldname = this.getfieldame(search.searchfieldname);
          this.input.fieldvalue = search.searchvalue != null ? search.searchvalue.toString() : '';
          // this.input.fromshiftday = search.days;
          // this.input.toshiftday = search.day;
          this.input.allotedto = search.allotedto;
          this.input.fromdate = search.fromdate ? search.fromdate :null;
          let todate = '';
          if(search.todate){
            todate = new Date(search.todate)
            todate.setDate(todate.getDate() + 1)
            todate = moment(String(todate)).format('YYYY-MM-DD');
          }
          this.input.todate = search.todate ? todate :null
        }
        this.input.isSearch = false;
        let url = "api/roaster/getroasterlist";
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          this.isresponce=true
          if (result.data.errorCode == 0) {
            this.roastrlist = result.data.data.rows;
            this.totalcount = result.data.data.count;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else {
            this.isDetActive = true;
            this.roastrlist = [];
            this.totalcount = 0;
          }
        }).catch(e => {
          this.displayError(e)
        });
        this.getProjectAllocatedToEmp()
      },
      dateMONFormat(value){
        if(value){
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        }else{
          return "";
        }
      },
      dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
      },
      dateMonthFormatwithouttime(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY');
      },
      getProjectAllocatedToEmp() {
          this.isLoading = true
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            roleid : this.undt.roleid,
            isfrompage:'listingpage'
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.search.projectnameopts = result.data.data;
            } else {
                this.search.projectnameopts = [];
            }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getEmpallocatedtoProject() {
        this.isLoading = true;
        //let currtab = 'ticket'
        this.input = { projectarr: this.search.searchprojectname,useremail: this.undt.username,empcode: this.undt.userid};
      if (this.isThisBusinessRole) {
        this.input.roleid = 484;
      }
      if (this.search.searchprojectname == this.itHelpDesk) {
        this.input.roleid = null;
      }
      axios({
        method: "POST",
        url: "api/listing/getEmpallocatedtoProject",
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
        }
      });
    },
    getticketfield(searchprojectname) {
      this.isLoading = true;
      let url = "api/roaster/getticketfield";
      this.input = {
        empcode: this.undt.userid,
        useremail  : this.undt.username,
        projectid: searchprojectname
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
        
          this.search.fieldlist = result.data.data.concat(this.TKRosterFields);
          this.search.fieldlist.sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        } else {
          this.search.fieldlist = [];
        }
      });
    },
    getticketfieldvalues() {
      this.isLoading = true;
      let url = "api/roaster/getticketfieldvalues";
      this.input = {
        empcode: this.undt.userid,
        useremail: this.undt.username,
        projectidarr: this.search.searchprojectname,
        field: this.search.searchfieldname
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      })
        .then((result) => {
          this.isLoading = false;
          if (result.data.errorCode === 0) {
            this.search.valueslist = result.data.data[0].label.map((item) => {
              return {
                label: item.optionvalue,
                id: item.optionvalue,
              };
            });
          } else {
            console.error("API error:", result.data.msg);
            this.search.valueslist = [];
          }
        })
        .catch((error) => {
          console.error("Error fetching ticket values:", error);
          this.search.valueslist = [];
        });
    },
      selSearchProject(state) {
        this.search.searchprojectname = null
        this.search.searchprojectname = state
        this.search.allotedto = null;
        this.search.allotedtolist = [];
        if(!state) {
          this.search.fieldlist = null;
          this.search.valueslist =null;
          this.search.allotedto = null;
          this.search.allotedtolist = [];
        }
        else {
        this.getEmpallocatedtoProject(state)
        this.getticketfield(state)
      }
      },
      selissuecsfield(state, value) {
      if(!this.TKRosterFields.some(i=>i.id === state)){
      this.search.field = state;
      if (!state) {
      this.search.searchvalue = null;
      this.search.valueslist = [];

      } else {
      let dp = this.search.fieldlist.filter((v) => {
      if(v.id==state) 
      return v;
      });
      this.inputtypeselection = dp.length > 0 ? dp[0].fieldtype : null
      this.search.searchvalue = null;
      this.getticketfieldvalues(state,this.search.searchprojectname)
      }
      }
      if(this.TKRosterFields.some(i=>i.id === state)){
      if(state=='Ticket Category'){
        this.search.searchvalue = null;
      this.getprojectmodules(this.search.searchprojectname)
      }
      if(state=='Ticket Sub Category'){
        this.search.searchvalue = null;
      this.getAppModulesbyprojectid(this.search.searchprojectname)
      }
      if(state=='Ticket Type'){
        this.search.searchvalue = null;
      this.getTasktypeConfigLists(this.search.searchprojectname)
      }
      }

      },
      getprojectmodules(projecteid) {
      let url = '';
        url = 'api/listing/getpromodulebyapplicationid';
    
      this.input = {
        projectidarr: projecteid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.inputtypeselection = 'drop-down'
          this.search.valueslist = result.data.data;
        }else {
          this.search.valueslist = [];
        }  
      }).catch(e => {
        this.displayError(e)
      });
  },
  getAppModulesbyprojectid(projectid) {
    this.input = {
      projectidarr: projectid,
      useremail: this.undt.username,
      empcode: this.undt.userid,
    };
    axios({
      method: "POST",
      url: 'api/listing/getappmodulenamebyprojectid',
      data: this.input,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
        this.inputtypeselection = 'drop-down'
        this.search.valueslist = result.data.data;
      } else {
        this.search.valueslist = [];
      }
    }).catch(e => {
      this.displayError(e)
    });
  },
  getTasktypeConfigLists(projectid) {
      let payload = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          projectidarr: projectid,
          trackername: 'Ticket Request Type',
      };
      axios({
          method: "POST",
          url: '/api/businesstask/getTasktypeConfigList',
          data: payload,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          if(result.data.errorCode == 0){
              let slist = [];
              result.data.data.rows.forEach((rec) => {
                  if(Object.values(rec.statusconfig).length>0){
                      Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                          if(statusnames.length>0) {
                              statusnames.forEach(val => {
                                  if(!slist.some(sdata => sdata.id === val)){
                                      slist.push({ id: val, label: val });
                                  }
                              });
                          }
                      });
                  }
              })
              if(this.search.searchprojectname != null && this.search.searchprojectname == apiUrl.merchandisingProId){
                  this.sortTypeList(slist, 'merchandising')
              } else{
                  this.sortTypeList(slist, 'otherprojects')
              }
          } else {
              this.search.valueslist = [];
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    sortTypeList(typelist, type){
      let numArrFromTypelist = [];
      typelist.forEach(element => {
        if(element.id != null) {
          numArrFromTypelist.push(element.id);
        }
      });
      if(numArrFromTypelist.length>0) {
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'ticket',
          typearr: numArrFromTypelist,
          projecttype: type
        };
        axios({
          method: "POST",
          url: 'api/ticket/sortTypeList',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.inputtypeselection = 'drop-down'
            this.search.valueslist = result.data.data.rows;
          } else {
            this.search.valueslist = [];
          }
        });
      }
    },
  selectedfromdate(){
    if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
      this.isBothDateSelected = false;
      this.errors.fromdate ='';
    }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
      this.isBothDateSelected = true;
    }
  },
  selectedtodate(){
    if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
      this.isBothDateSelected = false;
      this.errors.fromdate ='';
    }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
      this.isBothDateSelected = true;
    }
  },
  validateDate(mdl) {
    let fromdate = this.search.fromdate;
    let todate = this.search.todate;
    if(mdl === 'todate') {
      if ((Date.parse(todate) < Date.parse(fromdate))) {
        this.errors.fromdate = "Shift to date should be greater than shift from date";
        this.isBothDateSelected = true;
        this.search.todate = '';
      } else {
        this.errors.fromdate = '';
      }
    } 
    // else if(mdl === 'fromdate') {
    //   if ((Date.parse(todate) < Date.parse(fromdate))) {
    //     this.errors.fromdate = "Shift from date should be lesser than shift to date";
    //     this.search.fromdate = '';
    //     this.isBothDateSelected = true;
    //   } else {
    //     this.errors.fromdate = '';
    //   }
    // }
  },
  onFileChange(e) {
    this.attachmentnew =null;
    this.file =[];
    let selectedFiles = e.target.files;
    let blnValid = false;
    let CurExtension = 'csv';
    let curtype =selectedFiles[0].type.split('/')[1];
    if (curtype == CurExtension) {
      blnValid = true;
    }
    if (!blnValid) {
      Swal.fire({
        title: "Failed",
        text: "Sorry, Invalid Type of Extension File!",
        icon: 'info',
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: !1
      })
      return false;
    }else{
      this.file.push(selectedFiles[0]);
    }
    document.getElementById('attachment').value = ""
  },
  onUpload(){
    if(this.file){
      this.isLoading=true
      if(this.file.length !== 0){
        this.apiURL="api/master/fileupload/csvfileuploadwithgetdata"
        let formData = new FormData();
        let maxsize = 0;
        for (let index = 0; index < this.file.length; index++) {
          formData.append("imagefile", this.file[index]);
          maxsize+= this.file[index].size
        }
        formData.append("empcode", this.undt.userid);
        formData.append("useremail", this.undt.username);
        formData.append("moduletype", 'roster');
        formData.append("fcount", parseInt(this.file.length));
        formData.append("filefor", 'readcsvfile');
        
        if (maxsize > 1000 * 1000 * 25) {
          this.isLoading = false;
          Swal.fire({
            title: "Failed",
            text: "Your document uploaded above 25mb not allow",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          })
          this.isLoading=false
          return false; 
        }
        axios({
          'content-type':'multipart/form-data',
          'method': 'POST',
          'url': this.apiURL,
          'data':formData,
          'headers':{'authorization':this.tokendata,'content-type':'multipart/form-data'}
        })
        .then(result => { 
          this.isLoading=false;            
          if(result.data.status){
            const bindinfo = result.data.data.map((file, index) => {
              return {"link":file,"info":this.filedescp[index]}                
            });
            let jsonobj = {data:bindinfo}
            this.attachmentnew = JSON.stringify(jsonobj);
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
            this.attachment =null;
            this.attachmentnew =null;
            this.file =[];
            this.roasterlist(this.search);
          }else if(result.data.errorCode ==1 && result.data.data.length >0){
            this.invaliddetails =result.data.data;
            $('#tablepoup').modal('show');
          } else {
            Swal.fire({
              title: "",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
            this.attachment=null
          }
        }).catch(e => {
          this.displayError(e)
        })
      }else{
        this.isLoading=false; 
        Swal.fire({
          title: "",
          text: 'Select docs',
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
        })
        this.attachment=null
      }
    } else {
      this.isLoading=false; 
      Swal.fire({
        title: "",
        text: 'Select docs',
        icon: 'info',
        customClass: {
          confirmButton: "btn btn-primary"
        },
        buttonsStyling: !1
      })
      this.attachment=null
    }
  },
  removeImage (index) {
    if(this.file.length>0) {
      this.file.splice(index, 1);
      this.filedescp.splice(index, 1);
      let removeimg = JSON.parse(this.attachmentnew);
      if(removeimg){
        let remove = removeimg.data.splice(index,1);
        if(remove.length > 0){
          this.attachmentnew = JSON.stringify({data:removeimg.data})}
        else {
          this.attachmentnew = null;
          this.file = [];
        }
      }
    }     
  },

  ExportXL() {
    if(this.search.searchprojectname && this.search.searchprojectname !=null && this.search.searchprojectname.length >0){
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        projectid : this.search.searchprojectname,
        fieldname: this.search.searchfieldname,
        // fromshiftday:this.search.days,
        //fromshiftday:this.search.day,
        allotedto : this.search.allotedto,
        useremail: this.undt.username,
        empid : this.undt.userid,
        empcode: this.undt.userid,
        fromdate: this.search.fromdate ? this.search.fromdate :null,
        isactive: this.search.isactive,
        limit: null,
        offset: null
      };
      let todate = '';
      if(this.search.todate){
        todate = new Date(this.search.todate)
        todate.setDate(todate.getDate() + 1)
        todate = moment(String(todate)).format('YYYY-MM-DD');
      }
      this.input.todate = this.search.todate ? todate :null;
      axios({
        method: "POST",
        url: `api/roaster/getroasterlist`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          let filename = "";
          this.download(result.data.data.rows,filename)
        }else if(result.data.errorCode == 3){
          Swal.fire({
            title: "Session Expired...!!",
            text: result.data.msg,
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          }).then(function() {
            window.location.href = "/#/login";
          })
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
        } 
      }).catch(e => {
        this.displayError(e)
      });
    }else{
      Swal.fire({
        title: "",
        text: "Project Is Required",
        icon: "info",
        customClass: {
        confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });
    }
  },

  download(readExportdata,filename) {
      if (readExportdata.length > 0) {
        var ShowLabel = 'RoasterListReport_'+filename
        var JSONData = readExportdata
        var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        var CSV = ''
        if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
              row += index + ','
          }
          row = row.slice(0, -1)
          CSV = 'Sr,Roastercode, Project Name, Field Name, Values, Shiftfromdate, Shifttodate,Shiftfromtime,Shifttotime, Agent Name, Created Date, IsActive' + '\r\n'
        }

        let row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let id = index.hasOwnProperty('id') ? index.id : 'NA';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : 'NA';
          let fieldname = index.hasOwnProperty('fieldname') ? index.fieldname : 'NA';
          let fieldvalue = null;
          if(fieldname && fieldname =='Ticket Category'){
            fieldvalue = index.hasOwnProperty('category') ? index.category ==null || index.category =='' || index.category ==' ' ? 'NA' : index.category : 'NA';
          }else if(fieldname && fieldname =='Ticket Sub Category'){
            fieldvalue = index.hasOwnProperty('subcategory') ? index.subcategory ==null || index.subcategory =='' || index.subcategory ==' ' ? 'NA' : index.subcategory : 'NA';
          }else{
            fieldvalue = index.hasOwnProperty('fieldvalue') ? index.fieldvalue ==null || index.fieldvalue =='' || index.fieldvalue ==' ' ? 'NA' : index.fieldvalue : 'NA';
          }

          // let fromshiftday = index.hasOwnProperty('fromshiftday') ? index.fromshiftday : '';
          // let toshiftday = index.hasOwnProperty('toshiftday') ? index.toshiftday : '';
          let shiftfromdate = index.hasOwnProperty('shiftfromdate') ? index.shiftfromdate ==null || index.shiftfromdate =='' || index.shiftfromdate ==' ' ? 'NA' : index.shiftfromdate: 'NA';
          shiftfromdate = shiftfromdate ==null || shiftfromdate =='' || shiftfromdate ==' ' || shiftfromdate =='NA' ? 'NA' :this.dateMonthFormatwithouttime(shiftfromdate);

          let shifttodate = index.hasOwnProperty('shifttodate') ? index.shifttodate ==null || index.shifttodate =='' || index.shifttodate ==' ' ? 'NA' : index.shifttodate : 'NA';
          shifttodate = shifttodate ==null || shifttodate =='' || shifttodate ==' ' || shifttodate =='NA' ? 'NA' : this.dateMonthFormatwithouttime(shifttodate);

          let fromshifttime = index.hasOwnProperty('fromshifttime') ? index.fromshifttime : '';
          let toshifttime = index.hasOwnProperty('toshifttime') ? index.toshifttime : '';
          let empname = index.hasOwnProperty('empname') ? index.empname ==null || index.empname.toLowerCase() =='na'?'':index.empname : '';         
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          let isactive = index.hasOwnProperty('isactive') ? index.isactive ==1 ? 'Yes' :'No' : 'No';

          createddate = this.dateMONFormat(createddate);
            row += '"' + srno + '",',
            row += '"' + id + '",',
            row += '"' + projectname + '",',
            row += '"' + fieldname + '",',
            row += '"' + fieldvalue + '",',
            // row += '"' + fromshiftday + '",',
            // row += '"' + toshiftday + '",',
            row += '"' + shiftfromdate + '",',
            row += '"' + shifttodate + '",',
            row += '"' + fromshifttime + '",',
            row += '"' + toshifttime + '",',
            row += '"' + empname + '",',           
            row += '"' + createddate + '",',
            row += '"' + isactive + '",'
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'RoasterListReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });      
    }
  },

  downloaddata() {
    if(this.search.searchprojectname && this.search.searchprojectname !=null && this.search.searchprojectname.length >0){
      this.isLoading = true;
      this.exportxlsdata2 = [];
      this.input = {
        projectid : this.search.searchprojectname,
        fieldname: this.search.searchfieldname,
        allotedto : this.search.allotedto,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        fromdate: this.search.fromdate ? this.search.fromdate :null,
        isactive: this.search.isactive
      };
      let todate = '';
      if(this.search.todate){
        todate = new Date(this.search.todate)
        todate.setDate(todate.getDate() + 1)
        todate = moment(String(todate)).format('YYYY-MM-DD');
      }
      this.input.todate = this.search.todate ? todate :null;
      axios({
        method: "POST",
        url: `api/roaster/downloadsampleroasterlist`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata2 = result.data.data.rows;
          let filename = "";
          this.download2(result.data.data.rows,filename);
        }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        if (result.data.status) {
          this.isDetActive = true;
        } 
      }).catch(e => {
        this.displayError(e)
      });
    }else{
      Swal.fire({
        title: "",
        text: "Project Is Required",
        icon: "info",
        customClass: {
        confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });
    }
  },
  
  download2(readExportdata) {
    if (readExportdata.length > 0) {
      let ShowLabel = 'SampleDownloadRoasterListReport';
      let JSONData = readExportdata;
      let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
      let CSV = ''
      if (ShowLabel) {
        let row = ''
        for (let index in arrData[0]) {
          row += index + ','
        }
        row = row.slice(0, -1)
        CSV = 'Sr#, Roastercode, Projectname, Projectid, Fieldname, Fieldid, Values, Shiftfromdate, Shifttodate, Shiftfromtime, Shifttotime, Agentemailid, Roastertype, Isactive' + '\r\n'
      }

      let row = ''
      for (let i=0; i<arrData.length; i++) {
        let index = arrData[i]
        let srno = i+1
        let id = index.hasOwnProperty('id') ? index.id ==null || index.id =='' || index.id ==' ' ? '' : index.id : 'NA';
        let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
        let projectid = index.hasOwnProperty('projectid') ? index.projectid : '';
        let fieldname = index.hasOwnProperty('fieldname') ? index.fieldname ==null || index.fieldname =='' || index.fieldname ==' ' ? '' : index.fieldname : '';
        let fieldid = index.hasOwnProperty('fieldid') ? index.fieldid ==null || index.fieldid =='' || index.fieldid ==' ' ? '' : index.fieldid : '';

        let fieldvalue = index.hasOwnProperty('fieldvalue') ? index.fieldvalue ==null || index.fieldvalue =='' || index.fieldvalue ==' ' ? '' : index.fieldvalue : '';
        
        let shiftfromdate = index.hasOwnProperty('shiftfromdate') ? index.shiftfromdate ==null || index.shiftfromdate =='' || index.shiftfromdate ==' ' ? '' : index.shiftfromdate: '';
        shiftfromdate = shiftfromdate ==null || shiftfromdate =='' || shiftfromdate ==' ' || shiftfromdate =='' ? '' :shiftfromdate;

        let shifttodate = index.hasOwnProperty('shifttodate') ? index.shifttodate ==null || index.shifttodate =='' || index.shifttodate ==' ' ? '' : index.shifttodate : '';
        shifttodate = shifttodate ==null || shifttodate =='' || shifttodate ==' ' || shifttodate =='NA' ? '' : shifttodate;

        let fromshifttime = index.hasOwnProperty('fromshifttime') ? index.fromshifttime ==null || index.fromshifttime =='' || index.fromshifttime ==' ' ? '' : index.fromshifttime : '';
        let toshifttime = index.hasOwnProperty('toshifttime') ? index.toshifttime ==null || index.toshifttime =='' || index.toshifttime ==' ' ? '' : index.toshifttime : '';
        let empmailid = index.hasOwnProperty('empmailid') ? index.empmailid ==null || index.empmailid =='' || index.empmailid ==' ' || index.empmailid.toLowerCase() =='na' ? '':index.empmailid : ''; 
        let roastertype = index.hasOwnProperty('roastertype') ? index.roastertype : '';
        let isactive = index.hasOwnProperty('isactive') ? index.isactive ==1 ? 'Yes' :'No' : 'No';

        row += '"' + srno + '",',
        row += '"' + id + '",',
        row += '"' + projectname + '",',
        row += '"' + projectid + '",',
        row += '"' + fieldname + '",',
        row += '"' + fieldid + '",',
        row += '"' + fieldvalue + '",',
        row += '"' + shiftfromdate + '",',
        row += '"' + shifttodate + '",',
        row += '"' + fromshifttime + '",',
        row += '"' + toshifttime + '",',
        row += '"' + empmailid + '",',
        row += '"' + roastertype + '",',          
        row += '"' + isactive + '",'
        row += '\r\n'
      }
      CSV += row
      if (CSV === '') {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
          confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        return;
      }
      let fileName = 'SampleDownloadRoasterListReport'+'_'+moment().format('DD_MM_YY_HH_mm_ss')
      let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
      let link = document.createElement('a')
      link.href = uri
      link.style = 'visibility:hidden'
      link.download = fileName + '.csv'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      Swal.fire({
        title: "Success!",
        text: "Export Report successful",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });      
    }
  },

  export (data, filename, mime) {
    var data
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    download(blob, filename, mime)
  },
  },
};
</script>
  <style scoped>
  .displayblk{
      display:none !important;
  }
  .createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  .bordertable .row1{
    color:black;
  }
  </style>
  