<template>
    <div class="app-content content ">
        <loading  :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5 px-0">
                <div class="row breadcrumbs-top">
                    <div class="col-9">
                        <h4 class="content-header-title float-left mb-0">My Feedback</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb" style="padding-top:2px;">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Feedback Form List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-warning filter_card_searchbgst" >
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Search Feedback Form</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                    <div class="card-body pt-1">
                        <div class="row">

                            <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                            <label class="form-label lable-left">Form Name</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Form" :multiple="true" v-model="search.formname" :options="trackerlist"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                            <label class="form-label lable-left">Status</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" :multiple="true" v-model="search.status" :options="statuslist"/>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                                <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getformlist(search,'',1)"> 
                                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                                </button>
                                <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="card-navigation">
                <div class="file-manager-content-body">
                    <div class="drives">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="proModuleList">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="table-responsive">
                                                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="align-middle py-1 srno first-col-sticky">Sr#</th>
                                                                    <th scope="col" class="align-middle second-col-sticky" style="width: 220px;">Form Name </th>
                                                                    <th scope="col" class="align-middle">Status </th>
                                                                    <th scope="col" class="align-middle">Created By </th>
                                                                    <th scope="col" class="align-middle">Created Date </th>
                                                                    <th scope="col" class="align-middle clsaction">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-for="(data,index) in formlist" >        
                                                                    <tr v-bind:key="index">
                                                                        <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                        <td class="second-col-sticky capitalisetext">
                                                                            {{ data.moduletype }}
                                                                        </td>
                                                                        <td class="capitalisetext">
                                                                            {{  data.status }}
                                                                        </td>
                                                                        <td class="capitalisetext">
                                                                            {{ data.createdby.split('@')[0] }}
                                                                        </td>
                                                                        <td>
                                                                            {{dateMonthFormat(data.createddate)}}
                                                                        </td>
                                                                        <td class="clsaction" >
                                                                            <div class="dropdown">
                                                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <a @click="redirecttourl('create',data)" href="javascript:void(0);" class="dropdown-item devEditEle" v-if="data.status=='pending'">
                                                                                    <div class="d-flex align-items-center"> 
                                                                                        <edit-2-icon size="1.5x" class="custom-class mr-50" ></edit-2-icon>
                                                                                        <span>Fill Form</span>
                                                                                    </div>
                                                                                    </a>
                                                                                    <a @click="redirecttourl('view',data)" href="javascript:void(0);" class="dropdown-item devEditEle" v-if="data.status!='pending'">
                                                                                    <div class="d-flex align-items-center"> 
                                                                                        <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                                                        <span>View Form</span>
                                                                                    </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="formlist.length==0">
                                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                    <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,MoreVerticalIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import { SearchIcon } from "vue-feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'FeedbackFormListing',
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,MoreVerticalIcon,EyeIcon,
        SearchIcon,XIcon,ExternalLinkIcon,
        Treeselect
    },
    mixins: [ commonMethods ],
    data(){
        return{
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            formlist:[],
            isSearchRequestActive:false,
            search:{
                formname: null,
                status: null
            },
            trackerlist:[],
            statuslist:[{
                id:'completed',
                label:'Completed'
            },{
                id:'pending',
                label:'Pending'
            }],
            loginuseremail: '',
            pagefeatures:[]
        }
    },
    mounted(){
        // let permission = this.$Service.verifyAccess();
        // if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.loginuseremail = userdata.emailaddress;
                this.undt=userdata
                this.gettrackerlist();
                if(this.$route.params && this.$route.params.search){
                    let search = JSON.parse(localStorage.getItem('feedbacksearchItems'));
                    if(search){
                        this.getSearchItems(search);
                    }else{
                        localStorage.removeItem('feedbacksearchItems');
                        this.getformlist();
                    }
                }else{
                    localStorage.removeItem('feedbacksearchItems');
                    this.getformlist();
                }
            }
        // }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        getSearchItems(search) {
            this.search.formname = search.formname;
            this.search.status = search.status;
            this.isSearchRequestActive = true;
            this.getformlist(this.search);
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive = !this.isSearchRequestActive
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getformlist();
            setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#myTabContent").offset().top -70
            });
        }, 500);
            
        },
        resetRecords: function() {
            this.search.formname = null;
            this.search.status = null;
            localStorage.removeItem('feedbacksearchItems')
            this.page=1
            this.getformlist();
        },
        getformlist(objThis){
            if(objThis) {
                localStorage.setItem('feedbacksearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            let search = JSON.parse(localStorage.getItem('feedbacksearchItems'))
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                limit: apiUrl.LIMIT,
                type: 'orientation',
            }
            this.input.offset= 1
            this.currPageNum=0
            if (search) {
                this.input.formname = this.search.formname
                this.input.status = this.search.status
            }else{
                this.input.offset= this.pageoffset
            }
            axios({
                    'method': 'POST',
                    'url': 'api/feedbackform/getfeedbackformlist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false             
                    if(result.data.errorCode == 0){
                        this.formlist = result.data.data.rows
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.formlist=[]
                        this.totalcount = 0;
                        this.pageCount =0;
                    }
                }).catch(e => {
                    this.displayError(e)
                });

        },
        gettrackerlist() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'orientation',
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getallforms',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.trackerlist = result.data.data.rows
                } else {
                    this.trackerlist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate == "create"){
                this.$router.push({ 
                name: 'FeedbackForm',
                path: `/form/feedbackform?trackerid=${parseInt(rowdata.trackerid)}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            } else if(btnstate == "view"){
                this.$router.push({ 
                    path: '/form/formview?masterid='+parseInt(rowdata.id),
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        }
    }
}
</script>