<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation">
            <div class="row" >
                <div class="col-md-12">
                <div class="card text-center mb-3 ">
                <div class="card-header text-editar_discription_jobs masters_detailstabing_pills px-2">
                <ul class="nav nav-pills nav-pill-warning tabs_masters_detailstabing_pills pt-1 " id="nav-tabs" role="tablist">
                <li class="nav-item" v-if="pagefeatures.company">
                <a class="nav-link active" id="company-tab" data-toggle="tab" href="javascript:void();" @click="masterlist('t1',$event)" role="tab" aria-controls="company" aria-selected="true">Company</a>
                </li>
                <li class="nav-item"  v-if="pagefeatures.business">
                <a class="nav-link" id="business-tab" data-toggle="tab" href="javascript:void();" @click="masterlist('t2',$event)" role="tab" aria-controls="business" aria-selected="false">Business</a>
                </li>
                <li class="nav-item" v-if="pagefeatures.projecttype">
                <a class="nav-link" id="projecttype" data-toggle="tab" href="javascript:void();" @click="masterlist('t3',$event)" role="tab" aria-controls="projecttype" aria-selected="false">Project Type</a>
                </li>
                <li class="nav-item" v-if="pagefeatures.applicationtype">
                <a class="nav-link" id="application" data-toggle="tab" href="javascript:void();" @click="masterlist('t4',$event)" role="tab" aria-controls="application" aria-selected="false">Application Type</a>
                </li>
                <li class="nav-item" v-if="pagefeatures.accessapplication">
                <a class="nav-link" id="access" data-toggle="tab" href="javascript:void();" @click="masterlist('t8',$event)" role="tab" aria-controls="accessapp" aria-selected="false">Application Access</a>
                </li>
                <li class="nav-item" v-if="pagefeatures.employeetype">
                <a class="nav-link" id="employeet" data-toggle="tab" href="javascript:void();" @click="masterlist('t53',$event)" role="tab" aria-controls="employeetype" aria-selected="false">Employee Type</a>
                </li>

                <li class="nav-item" v-if="pagefeatures.country">
                <a class="nav-link" id="country" data-toggle="tab" href="javascript:void();" @click="masterlist('t54',$event)" role="tab" aria-controls="countrymaster" aria-selected="false">Country</a>
                </li>
                <li class="nav-item" v-if="pagefeatures.city">
                <a class="nav-link" id="city" data-toggle="tab" href="javascript:void();" @click="masterlist('t55',$event)" role="tab" aria-controls="citymaster" aria-selected="false">City</a>
                </li>
                <li class="nav-item" v-if="pagefeatures.jobportal">
                <a class="nav-link" id="jobportal" data-toggle="tab" href="javascript:void();" @click="masterlist('t57',$event)" role="tab" aria-controls="jobportalmaster" aria-selected="false">Job Portal</a>
                </li>
                <li class="nav-item" v-if="pagefeatures.jobportal">
                <a class="nav-link" id="joblocation" data-toggle="tab" href="javascript:void();" @click="masterlist('t58',$event)" role="tab" aria-controls="joblocationmaster" aria-selected="false">Job Location Master</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="whats_new_tab" data-toggle="tab" href="javascript:void();" @click="masterlist('wn',$event)" role="tab" aria-controls="whats_new" aria-selected="false">Create Whats New</a>
                </li>
                </ul>
                </div>
                <div class="card-body" id="mLIst">
                <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row" id="table-small">
                <div class="col-12 mobile-px-0">
                <div class="card">
                        <div class="card-body mobile-px-0" style="padding-left:0px !important;padding-top:0px !important;">
                            <div class="row" v-if="glbst !== 'wn'">                            
                                <div class="col-md-12 col-lg-6 mb-1">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-outline-primary" type="button">
                                                <search-icon size="1.5x" class="custom-class"></search-icon>
                                            </button>
                                        </div>
                                        <input id="glbsearchinpele" type="text" class="form-control" placeholder="Search" aria-label="Amount" />
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-primary" type="button" data-clkstate="search" @click="masterlist('',$event)">Search !</button>
                                        </div>
                                    </div>
                                </div>                         
                                <div class="col-md-12 col-lg-6 mb-1 text-right">
                                    <div class="input-group-append small_view_device_right">
                                        <button id="commonaddmodule" class="btn btn-primary waves-effect waves-float waves-light"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#company" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false">Add {{glbMdlLbl}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                <div class="table-responsive" v-if="glbst !== 'wn'">
                <table class="table table-sm dataTableListing" aria-describedby="mydesc" >
                <thead>
                <tr>
                <th scope="col" class="srno">Sr#</th>
                <th scope="col" v-if="glbst =='t14'">Project Name</th>
                <th scope="col" v-if="glbst =='t14'">Role Name</th>
                <th scope="col" class="text-left" v-if="glbst =='t1'">Company Name</th>
                <th scope="col" class="text-left" v-if="glbst =='t2'">Business Name</th>
                <th scope="col" class="text-left" v-if="glbst =='t3'">Project Type</th>
                <th scope="col" class="text-left" v-if="glbst =='t4'">Application Type</th>
                <th scope="col" v-if="glbst =='t5'">Tech Application</th>
                <th scope="col" v-if="glbst =='t6'">Host Application</th>
                <th scope="col" v-if="glbst =='t7'">Client Application</th>
                <th scope="col" class="text-left" v-if="glbst =='t8'">Access Application</th>
                <th scope="col" class="text-left" v-if="glbst =='t53'">Employee Type</th>
                <th scope="col" class="text-left" v-if="glbst =='t54'">Country Master</th>
                <th scope="col" class="text-left" v-if="glbst =='t55'">City Master</th>
                <th scope="col" class="text-left" v-if="glbst =='t57'">Job Portal Name</th>                
                <th scope="col" class="text-left" v-if="glbst =='t57'">Job Portal Desc</th>
                <th scope="col" class="text-left" v-if="glbst =='t58'">Location Name</th>
                <th scope="col" class="text-center" v-if="glbst =='t58'">Country</th>
                 <th scope="col" v-if="glbst =='t58'">City</th>
                <th scope="col" v-if="glbst =='t10'">Role Name</th>
                <th scope="col" v-if="glbst =='t9'">Task Name</th>
                <th scope="col" v-if="glbst =='t9'">Task Level</th>
                <th scope="col" v-if="glbst =='t26'">Project Module Name</th>
                <th scope="col" v-if="glbst =='t26'">Project Name</th>
                <th scope="col" class="centeralign" v-if="glbst !='t53' && glbst !='t54' && glbst !='t55' && glbst !='t57' && glbst !='t58'">Project Count</th>
                <th scope="col" class="centeralign text-left">Created by</th>
                <th scope="col" class="centeralign text-left" style="min-width:100px;">Created date</th>
                <th scope="col" class="clsaction">Actions</th>
                </tr>
                </thead>
                <tbody>
                    <template v-for="(data,index) in companymasterlist">        
                        <tr v-bind:key="data.moduleid">
                            <td class="srno">{{ index+1+currPageNum*pagelimit }}</td>    
                            <td class="text-left" v-if="glbst !='t9' && glbst !='t26' && glbst !='t14'">
                                <span class="font-weight-bold">{{data.modulename}}</span>
                            </td>
                            <td v-if="glbst == 't1' || glbst =='t2'" class="centeralign cursorpointercls sitebluecolor " @click="redirecttourl('viewCompanyProjects',data)">{{data.count}}</td>
                            <td v-if="glbst != 't1' && glbst !='t2' && glbst !='t53' && glbst !='t54' && glbst !='t55' && glbst !='t57' && glbst !='t58'" class="centeralign">{{data.count}}</td>
                            <td class="capitalisetext" v-if="glbst =='t14'">{{data.projectname}}</td>
                            <td class="capitalisetext text-left" v-if="glbst =='t14'">{{data.designationname}}</td>
                            <td class="text-left" v-if="glbst =='t57'">{{data.description}}</td>
                            <td v-if="glbst =='t58'">{{data.countryid}}</td>
                            <td v-if="glbst =='t58'">{{data.cityid}}</td>
                            <td v-if="glbst =='t9'">{{data.modulename}}</td>
                            <td v-if="glbst =='t9'">{{data.tasklevelval}}</td>
                            <td v-if="glbst =='t26'">{{data.modulename}}</td>
                            <td v-if="glbst =='t26'">{{data.projectname}}</td>
                            <td v-if="glbst !='t14'" class="centeralign text-left">{{data.createdby}}</td>
                            <td class="capitalisetext" v-if="glbst =='t14'">{{data.authorname}}</td>
                            <td class="centeralign text-left">{{dateFormat(data.createddate)}}</td>
                            <td class="clsaction">
                                <a class="devEditEle"   data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#updatebusiness" data-currState="reguler" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                </a>
                            </td>
                        </tr>
                    </template>
                    <template v-if="companymasterlist.length==0">
                        <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                    </template>
                </tbody>
                </table>             
                </div>
                </div>
                </div>
                </div>
                </div>
                <div class="pageCenter text-center" v-if="glbst !== 'wn'">
                <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                </div>
                <div class="tab-pane fade" id="business" role="tabpanel" aria-labelledby="profile-tab">
                <div class="row" id="table-small">
                <div class="col-12">
                <div class="card">
                <div class="table-responsive">
                <table class="table table-sm" aria-describedby="mydesc">
                <thead>
                <tr>
                <th scope="col">Business Name</th>
                <th scope="col">Created By</th>
                <th scope="col">Created Date</th>
                <th scope="col">Status</th>
                <th scope="col">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                <td>
                    <img  src="../../../public/images/icons/firstcry.png" class="mr-75" alt="Angular" width="18" height="18" />
                    <span class="font-weight-bold">firstcry.com</span>
                </td>
                <td>Peter Charls</td>
                <td>
                    2021-11-05
                </td>
                <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                <td>
                    <div class="dropdown">
                        <edit-icon size="1.5x" class="custom-class"></edit-icon>
                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                            <em data-feather="more-vertical"></em>
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#updatebusiness">
                                <em data-feather="edit-2" class="mr-50"></em>
                                <span>Edit</span>
                            </a>
                            <a class="dropdown-item" id="confirm-text">
                                <em data-feather="trash" class="mr-50"></em>
                                <span>Delete</span>
                            </a>
                        </div>
                    </div>
                </td>
                </tr>
                </tbody>
                </table>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                <div class="card-body" v-if="glbst == 'wn'">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="whats_new" role="tabpanel" aria-labelledby="whats_new">
                            <div class="row">
                                <div  class="col-md-12 mb-2" v-if="isUploading" >{{this.uploadPercentage}} %</div>
                                <div class="col-md-12 mb-2" v-if="isUploading"><progress  max="100" style="min-width: 294px" :value.prop="uploadPercentage"></progress></div>
                                <div class="col-md-12">    <label for="attachment" class="btn btn-sm btn-relief-secondary mb-0 mr-75">Select pdf type document</label>
                                    <input type="file" id="attachment" hidden accept="application/pdf" ref="file" @change="uploadFile"/>
                                    <span class="mr-1">{{this.whats_new_document ? this.whats_new_document.name: null}}</span>
                                    <button :disabled="!this.whats_new_document" type="button" class="btn btn-relief-primary" @click="submitFile">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            </section>
        </div>
        <div class="modal modal-slide-in fade" id="add-payment-sidebar" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Update Company Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1">
                        <form>
                            <div class="form-group">
                                <input id="business" class="form-control" type="text" value="firstcry.com" disabled />
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Project Name </label>
                                <input id="pname" class="form-control" type="text" placeholder="Project Name " />
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="payment-date">Project Kick off Date</label>
                                <input id="project-date" class="form-control date-picker" type="text" />
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="payment-method">Country</label>
                                <select class="form-control" id="pcountry">
                                    <option value="" selected disabled>Select Country</option>
                                    <option value="INDIA">INDIA</option>
                                    <option value="UAE">UAE</option>
                                    <option value="CHINA">CHINA</option>
                                    <option value="GLOBAL">Global</option>
                                    <option value="Paypal">OMAN</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="payment-note">Project Success Defination </label>
                                <textarea class="form-control" id="payment-note" rows="5" placeholder="Project Success Defination "></textarea>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1" id="type-success" data-dismiss="modal">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="company" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Company Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Company Name </label>
                                <input id="modulenamet1" name="modulenamet1" class="form-control" type="text" placeholder="Company Name " v-model="company.name" v-bind:class="{'form-control':true, '' : !validText1(company.name) && nameBlured}" v-on:blur="nameBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText1(company.name) && nameBlured">Company name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-primary mr-1" id="type-success" data-dismiss="modal"  v-on:click.stop.prevent="saveData('t1',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="businessdetails" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Business Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Business Name </label>
                                <input id="modulenamet2" name="" class="form-control" type="text" placeholder="Business Name" v-model="business.name" v-bind:class="{'form-control':true, '' : !validText1(business.name) && businessBlured}" v-on:blur="businessBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText1(business.name) && businessBlured">Business name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1" id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t2',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="projecttypedetails" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Project Type Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Project Type Name </label>
                                <input id="modulenamet3" name="" class="form-control" type="text" placeholder="Project Type Name " v-model="project.type" v-bind:class="{'form-control':true, '' : !validText1(project.type) && projectBlured}" v-on:blur="projectBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText1(project.type) && projectBlured">Project type name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t3',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="applicationtype" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Application Type Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Application Type Name </label>
                                <input id="modulenamet4" name="" class="form-control" type="text" placeholder="Application Type Name "  v-model="apptype.type" v-bind:class="{'form-control':true, '' : !validText1(apptype.type) && apptypeBlured}" v-on:blur="apptypeBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText1(apptype.type) && apptypeBlured">Application type name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Is Master type </label>
                                <div class="form-check form-check-inline mx-2">
                                    <input class="form-check-input ismastertypeOptions" type="radio" name="ismastertypeOptions" id="ismastertype1" value="yes" @click="setClickedRadioVal($event)" v-model="selectedRadio" checked />
                                    <label class="form-check-label" for="ismastertype1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input ismastertypeOptions" type="radio" name="ismastertypeOptions" id="ismastertype2" value="no" @click="setClickedRadioVal($event)" v-model="selectedRadio" />
                                    <label class="form-check-label" for="ismastertype2">No</label>
                                </div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1" id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t4',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="techtype" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Tech Type Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Tech Type Name </label>
                                <input id="modulenamet5" name="" class="form-control" type="text" placeholder="Tech Type Name " v-model="techtype.type" v-bind:class="{'form-control':true, 'is-invalid' : !validText(techtype.type) && techtypeBlured}" v-on:blur="techtypeBlured = true"/>
                                 <div class="invalid-feedback">tech type name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group">
                                <label for="devApplicationTypeData">Application Type</label>
                                
                                <treeselect :options="ddapplicationlist" @input="seltechapptypedata" v-model="selectedApp" @close="validate('t5')" />
                                
                                <div class="errorinputmsg" v-if="techtype.apptypeerror">Application Type required</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t5',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="hostapplication" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Host Application Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Host Application Name </label>
                                <input id="modulenamet6" name="" class="form-control" type="text" placeholder="Host Application Name " v-model="hostapp.name" v-bind:class="{'form-control':true, 'is-invalid' : !validText(hostapp.name) && hostappBlured}" v-on:blur="hostappBlured = true"/>
                                 <div class="invalid-feedback">host application name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1" id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t6',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="clientdetails" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Client Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Client Name </label>
                                <input id="modulenamet7" name="" class="form-control" type="text" placeholder="Client Name " v-model="clientapp.name" v-bind:class="{'form-control':true, 'is-invalid' : !validText(clientapp.name) && clientppBlured}" v-on:blur="clientppBlured = true"/>
                                 <div class="invalid-feedback">client name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t7',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="accessofapplication" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Access Of Application Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Access Of Application Name </label>
                                <input id="modulenamet8" name="" class="form-control" type="text" placeholder="Access Of Application Name " v-model="accessapp.name" v-bind:class="{'form-control':true, '' : !validText1(accessapp.name) && accessappBlured}" v-on:blur="accessappBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText1(accessapp.name) && accessappBlured">Access of application name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t8',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="employeetype" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Employee Type</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Employee Type</label>
                                <input id="modulenamet53" name="" class="form-control" type="text" placeholder="Employee Type Name " v-model="employeetype.name" v-bind:class="{'form-control':true, '' : !validText1(employeetype.name) && emptypeBlured}" v-on:blur="emptypeBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText1(employeetype.name) && emptypeBlured">Employee Type Name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t53',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-slide-in fade" id="countrymaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Country Master</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Country</label>
                                <input id="modulenamet54" name="" class="form-control" type="text" placeholder="Country Name " v-model="countrymaster.name" v-bind:class="{'form-control':true, '' : !validText2(countrymaster.name) && countryBlured}" v-on:blur="countryBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText2(countrymaster.name) && countryBlured">countrymaster Name must be  greater than 3 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t54',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-slide-in fade" id="citymaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">City Master</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">City</label>
                                <input id="modulenamet55" name="" class="form-control" type="text" placeholder="City Name " v-model="citymaster.name" v-bind:class="{'form-control':true, '' : !validText2(citymaster.name) && cityBlured}" v-on:blur="cityBlured = true"/>
                                 <div class="errorinputmsg" v-if="!validText2(citymaster.name) && cityBlured">citymaster Name must be  greater than 3 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t55',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-slide-in fade" id="jobportalmaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Job Portal Master</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Job Portal Name</label>
                                <input id="modulenamet57" name="" class="form-control" type="text" placeholder="Job Portal Name " v-model="jobportalmaster.name" v-bind:class="{'form-control':true, '' : !validText2(jobportalmaster.name) && jobportalBlured}" v-on:blur="jobportalBlured = true"/>
                                <label class="form-label" for="project-desc">Job Portal Desc</label>
                                <input id="moduledesct57" name="" class="form-control" type="text" placeholder="Job Portal Desc " v-model="jobportalmaster.desc" v-bind:class="{'form-control':true, '' : !validText2(jobportalmaster.desc) && jobportaldescBlured}" v-on:blur="jobportaldescBlured = true"/>

                                 <div class="errorinputmsg" v-if="!validText2(jobportalmaster.name) && jobportalBlured">Job Portal Master Name must be  greater than 3 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t57',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-slide-in fade" id="joblocationmaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Job Location Master</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Job Location Name</label>
                                <input id="modulenamet58" name="" class="form-control" type="text" placeholder="Job Portal Name " v-model="joblocationmaster.name" v-bind:class="{'form-control':true, '' : !validText2(joblocationmaster.name) && joblocationBlured}" v-on:blur="joblocationBlured = true"/>
                                <label class="form-label" for="project-desc">Job Country</label>
                                <input id="modulecountryidt58" name="" class="form-control" type="text" placeholder="Job Country " v-model="joblocationmaster.countryid" v-bind:class="{'form-control':true, '' : !validText2(joblocationmaster.country) && joblocationcountryBlured}" v-on:blur="joblocationcountryBlured = true"/>
                                <label class="form-label" for="project-desc">Job City</label>
                                <input id="modulecityidt58" name="" class="form-control" type="text" placeholder="Job City " v-model="joblocationmaster.cityid" v-bind:class="{'form-control':true, '' : !validText2(joblocationmaster.city) && joblocationcityBlured}" v-on:blur="joblocationcityBlured = true"/>

                                 <div class="errorinputmsg" v-if="!validText2(joblocationmaster.name) && joblocationBlured">Job Location Name must be  greater than 3 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" v-on:click.stop.prevent="saveData('t58',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-slide-in fade" id="rolemaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Role Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Role Name </label>
                                <input id="modulenamet10" name="" class="form-control" type="text" placeholder="Role Name " v-model="rolemaster.name" v-bind:class="{'form-control':true, 'is-invalid' : !validText(rolemaster.name) && rolemasterBlured}" v-on:blur="rolemasterBlured = true"/>
                                 <div class="invalid-feedback">role name must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal"  v-on:click.stop.prevent="saveData('t10',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="updatebusiness" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Update Business Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Business Name </label>
                                <input id="bname" class="form-control" type="text" placeholder="Business Name " />
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal" @click="saveData()">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="projectroleseleform">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Project Role Select Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="projroleprojectdd">Projects</label>
                                    <treeselect class="capitalisetext" v-model="roleselection.project" :options="roleselection.projectlist" @input="selroleasiproj" @close="validateForm('t14',$event)" />
                                    <div class="errorinputmsg" v-if="this.errors.roleselection.project">{{ this.errors.roleselection.project }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="projectroleiddd">Role</label>
                                    <treeselect class="rolelistdd capitalisetext" v-model="roleselection.role" :multiple="true" :clear-on-select="true" :options="roleselection.rolelist" @input="selroleasirole" @close="validateForm('t14',$event)" />
                                    <div class="errorinputmsg" v-if="this.errors.roleselection.role">{{ this.errors.roleselection.role }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-primary mr-1"  @click="saveData('t14',$event)" id="submitbtnt14">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary">Reset</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { SearchIcon,EditIcon,EyeIcon } from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'Masters',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        Pagination,
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isUploading: false,
            uploadPercentage: 0,
            whats_new_document: null,
            isLoading: false,
            fullPage: true,
            companymasterlist: [],
            showAddForm:false,
            selected: null,
            countryName: [
                { label: 'select_value', id: 'Select Value' },
                { label: 'Russia', id: 'Russia' },
                { label: 'Canada', id: 'Canada' },
                { label: 'China', id: 'China' },
                { label: 'United States', id: 'United States' },
                { label: 'Brazil', id: 'Brazil' },
                { label: 'Australia', id: 'Australia' },
                { label: 'India', id: 'India' },
            ],
            editRowData:[],
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            ddapplicationlist:[],
            selectedApp:null,
            selectedRadio:'yes',
            undt:[],
            taskdesc:'',
            tasklevel:'',
            company:{
                name:''
            },
            business:{
                name:''
            },
            project:{
                type:''
            },
            apptype:{
                type:''
            },
            techtype:{
                type:'',
                apptype:null,
                apptypeerror:null
            },
            hostapp:{
                name:''
            },
            clientapp:{
                name:''
            },
            accessapp:{
                name:''
            },
            employeetype:{
                name:''
            },
            countrymaster:{
                name:''
            },
            citymaster:{
                name:''
            },
            jobportalmaster:{
                name:'',
                desc:''
            },
            joblocationmaster:{
                name:'',
                country:'',
                city:''
            },

            rolemaster:{
                name:''
            },
            nameBlured:false,
            businessBlured:false,
            projectBlured:false,
            apptypeBlured:false,
            techtypeBlured:false,
            techapptypeBlured:false,
            hostappBlured:false,
            clientppBlured:false,
            accessappBlured:false,
            emptypeBlured:false,
            countryBlured:false,
            cityBlured:false,
            jobportalBlured:false,
            jobportaldescBlured:false,
            joblocationBlured:false,
            joblocationcityBlured:false,
            joblocationcountryBlured:false,
            rolemasterBlured:false,
            valid : false, 
            submitted : false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:{},
            roleselection:{
                project:null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                role:null,
                rolelist:[{
                    id:'',
                    label:''
                }]
            },
            errors: {
                roleselection:{
                    project:null,
                    role:null
                }
            },
            isAddState:false,
            iseditstate:false,
            projectCountlist: [],
            tokendata: null,
        }
    },    
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/masters') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            this.tokendata = window.localStorage.getItem('token');
            $('.devApplicationTypeData').each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                dropdownAutoWidth: true,
                width: '100%',
                dropdownParent: $this.parent()
                });
            });
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.tokendata = window.localStorage.getItem('token');
            }
            this.masterlist();
        }
    },
    methods:{
        uploadFile(e) {
            this.whats_new_document = this.$refs.file.files[0];
            if(this.whats_new_document && this.whats_new_document.name.substring(this.whats_new_document.name.lastIndexOf('.')+1) !== 'pdf') {
                this.whats_new_document = null
                Swal.fire({
                    title: "",
                    text: 'Only pdf file is allowed to upload',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        },
        async submitFile() {
            if(this.whats_new_document && this.whats_new_document.name) {
                this.isLoading = true
                this.isUploading = true
                this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                const formData = new FormData();
                formData.append('imagefile', this.whats_new_document);
                formData.append("empcode", this.undt.userid);
                formData.append("useremail",this.undt.username);
                formData.append("moduletype", 'whats_new');
                formData.append("fcount", 1);
                let result = await axios({
                    'content-type':'multipart/form-data',
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':formData,
                    'headers':{'authorization':this.tokendata},
                    onUploadProgress: function(progressEvent) {
                        this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                    }.bind(this)
                })
                if(result) {
                    if(result.data.status) {
                        let saveRes = await axios({
                            method: "POST",
                            url: 'api/whats-new/upload',
                            data: {
                                createdby: this.undt.username,
                                docname: result.data.data[0],
                                filename: this.whats_new_document.name,
                                useremail: this.undt.username,
                                empcode: this.undt.userid,
                            },
                            'headers':{'authorization':this.tokendata}
                        })
                        this.whats_new_document = null
                        if(saveRes.data.errorCode === 0) {
                            Swal.fire({
                                title: "Success!",
                                text: saveRes.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        } else {
                            Swal.fire({
                                title: "Info!",
                                text: saveRes.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                } else {
                    Swal.fire({
                        title: "",
                        text: 'Failed to submit form data',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
                this.isLoading = false
                this.isUploading = false
            }
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            this.masterlist(inputEle,'');
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#mLIst").offset().top -70
                });
            }, 500);
        },
        validText1 : function(inputval) {
            var re = /^[A-Za-z-\s]{5,}$/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText2 : function(inputval) {
            var re = /^[A-Za-z-\s]{3,}$/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText : function(inputval) {
            var re = /^[A-Za-z0-9\s]{5,}$/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            this.nameBlured=true
            this.businessBlured=true
            this.projectBlured=true
            this.apptypeBlured=true
            this.techtypeBlured=true
            this.techapptypeBlured=true
            this.hostappBlured=true
            this.clientppBlured=true
            this.accessappBlured=true
            this.emptypeBlured=true
            this.countryBlured=true
            this.jobportalBlured=true
            this.jobportaldescBlured=true            
            this.cityBlured=true
            this.rolemasterBlured=true    
            this.valid = false        
            if(tblNm=="t1"){
                this.inputval = this.company.name
            }
            if(tblNm=="t2"){
                this.inputval = this.business.name
            }
            if(tblNm=="t3"){
                this.inputval = this.project.type
            }
            if(tblNm=="t4"){
                if(this.selectedRadio){
                    this.inputval = this.selectedRadio
                    this.valid = true;
                }
                this.valid=false;
                if(this.apptype.type){
                    this.inputval = this.apptype.type
                    this.valid = true;
                }
                return this.valid;
            }
            if(tblNm=="t5"){
                if(this.techtype.type){
                    this.inputval = this.techtype.type
                    this.valid = true;
                }
                this.valid=false;
                if(this.selectedApp){
                    this.inputval = this.selectedApp
                    this.valid = true
                }else{
                    this.techtype.apptypeerror = true
                }
                return this.valid;
            }
            if(tblNm=="t6"){
                this.inputval = this.hostapp.name
            }
            if(tblNm=="t7"){
                this.inputval = this.clientapp.name
            }
            if(tblNm=="t8"){
                this.inputval = this.accessapp.name
            }
            if(tblNm=="t53"){
                this.inputval = this.employeetype.name
            }
            if(tblNm=="t54"){
                this.inputval = this.countrymaster.name
            }  
            if(tblNm=="t55"){
                this.inputval = this.citymaster.name
            }
            if(tblNm=="t57"){
                this.inputval = this.jobportalmaster.name
                this.desc = this.jobportalmaster.desc                 
            }
            if(tblNm=="t58"){
                this.inputval = this.joblocationmaster.name
                this.country = this.joblocationmaster.countryid
                this.city = this.joblocationmaster.cityid
            }
            
            if(tblNm=="t26"){
                if(this.proejctmodule.name){
                    this.inputval = this.techtype.type
                    this.valid = true;
                }
                return this.valid;
            }
            if(tblNm=="t10"){
                this.inputval = this.rolemaster.name
            }
            if( this.inputval && this.validText(this.inputval)){
                this.valid = true;
            }  
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                name: 'Project Wizard',
                path: '/projects',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        setSelected(value)
        {
            this.selected=value
        },
        whats_new() {
            $('#glbsearchinpele').val('')
            $('#commonaddmodule').attr('data-target','#whats_new')
            this.glbMdlLbl = 'whats_new_tab';
        },
        masterlist(mdl,objThis){
            this.isLoading=true
            let setmoduleform='company'
            let glbMdlLblval='Company'
            if(mdl != ''){
                $('#glbsearchinpele').val('')
            }
            if(this.glbst && mdl == ''){
               mdl = this.glbst;
            }
            if(mdl == 't2'){
                setmoduleform='businessdetails'
                glbMdlLblval="Business"
            }else if(mdl == 't3'){
                setmoduleform='projecttypedetails'
                glbMdlLblval="Project"
            }else if(mdl == 't4'){
                setmoduleform='applicationtype'
                glbMdlLblval="Application"
            }else if(mdl == 't5'){
                setmoduleform='techtype'
                glbMdlLblval="Tech"
            }else if(mdl == 't6'){
                setmoduleform='hostapplication'
                glbMdlLblval="Host"
            }else if(mdl == 't7'){
                setmoduleform='clientdetails'
                glbMdlLblval="Client"
            }else if(mdl == 't8'){
                setmoduleform='accessofapplication'
                glbMdlLblval="Access"
            }else if(mdl == 't53'){
                setmoduleform='employeetype'
                glbMdlLblval="Employee Type"
            }else if(mdl == 't54'){
                setmoduleform='countrymaster'
                glbMdlLblval="Country"
            }else if(mdl == 't55'){
                setmoduleform='citymaster'
                glbMdlLblval="City"
            }else if(mdl == 't57'){
                setmoduleform='jobportalmaster'
                glbMdlLblval="Job Portal"                
            }else if(mdl == 't58'){
                setmoduleform='joblocationmaster'
                glbMdlLblval="Job Location"                
            }else if(mdl == 't10'){
                setmoduleform='rolemaster'
                glbMdlLblval="Role"
            }else if(mdl == 't14'){
                setmoduleform='projectroleseleform'
                glbMdlLblval='Project Role Selection'
            }else if(mdl == 'wn') {
                setmoduleform='whats_new_tab'
                glbMdlLblval='whats_new'
            }
            
            $('#commonaddmodule').attr('data-target','#'+setmoduleform)
            this.companymasterlist = []
            let tblNm='t1'
            if(mdl)
            tblNm=mdl
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                this.glbMdlLbl = glbMdlLblval;
            }
            if(objThis=='' && this.page==1){
                this.pageoffset=1
                
            }
             if(objThis){
                this.page=1
            }
            if(mdl == 'wn') {
                this.isLoading = false
                return
            }
            var url = 'api/getMastList/list';
            this.input = {
                tbl:tblNm,
                offset:this.pageoffset,
                limit:this.pagelimit,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            if(tblNm == 't5'){
                this.getApplicationdata('t4','tech');
            }else if(tblNm == 't9'){
                this.getApplicationdata('t24','task');
                this.getApplicationdata('t11','task');
                url = 'api/master/commonmaster/customselect';
                this.input.section='masters'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
            }else if(tblNm == 't14'){
                this.getThirdPartyAPIcall('t10','projectrolesele-tab')
                this.getApplicationdata('t11','projectrolesele-tab')
                url = 'api/projectonlyroleassignment/list'
                this.isProjectSel= 1
                this.viewprojectlist=1
                this.input.section='assignment'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
            }
            this.input.isSearch=false
            if(typeof objThis != 'undefined' && objThis != ''){
                if(objThis.currentTarget.dataset.clkstate == 'search' && $('#glbsearchinpele').val() != ''){
                    this.input.name=($('#glbsearchinpele').val()).trim()
                    this.input.isSearch=true
                }
            }
            this.input.useremail= this.undt.username
            this.input.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false;          
                if(result.data.errorCode == 0){
                    this.totalcount = result.data.data.count
                    this.companymasterlist=result.data.data.rows;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    setTimeout(function(){ $('.devEditEle').attr('data-target','#'+setmoduleform) }, 1000);
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
                 else {
                    this.totalcount = 0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        addModule(objThis){
            $('.devUniqueForm').find('input[type=text]').val('')
            $(objThis.currentTarget.dataset.target).find('#type-success').attr('data-isedit',false)
            this.iseditstate=false
            $('#glbsearchinpele').val('')
            this.company.name=null
            this.business.name=null
            this.project.type=null
            this.apptype.type=null
            this.techtype.type=null
            this.hostapp.name=null
            this.clientapp.name=null
            this.accessapp.name=null
            this.employeetype.name=null
            this.countrymaster.name=null
            this.citymaster.name=null
            this.jobportalmaster.name=null
            this.jobportalmaster.desc=null
            this.joblocationmaster.name=null
            this.joblocationmaster.countryid=null
            this.joblocationmaster.cityid=null
            this.selectedRadio='yes';
            this.apptype.type=null;
            this.techtype.type=null
            this.selectedApp=null
            this.techtype.apptypeerror=false
            this.rolemaster.name=null
            this.roleselection.role = null
            this.roleselection.project=null
            this.isAddState=true
        },
        saveData(mdl,objThis){
            if(mdl=="t14"){
                this.valid=this.validateForm(mdl,objThis);
            }else{
                this.validate(mdl);
            }
            if (this.valid) {
                this.isLoading=true
                this.submitted=true
                let nameobj=document.getElementById('modulename'+mdl)
                let typeobj=document.getElementById('devApplicationTypeData')
                let tblNm='t1'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'masters',
                    createdby: this.undt.username,
                    useremail: this.undt.username,empcode: this.undt.userid,
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                if(typeobj != null && typeobj != 'undefined')
                {
                    if(typeobj.value != ''){
                        this.input.type=typeobj.value
                    }
                }
                let apiURL='api/master/commonmaster/save'
                if(mdl == 't1' || mdl == 't2' || mdl == 't3' || mdl == 't5' || mdl == 't6' || mdl == 't7' || mdl == 't8' || mdl == 't9' || mdl == 't10' || mdl == 't53' || mdl == 't54' || mdl == 't55' ){
                    this.selectedRadio='';
                }
                if(mdl == 't57')
                {
                    this.selectedRadio='';
                    let descobj=document.getElementById('moduledesc'+mdl)
                    this.input.desc=descobj.value
                }
                if(mdl == 't58')
                {
                    this.selectedRadio='';
                    let modulecountryidobj=document.getElementById('modulecountryid'+mdl);
                    this.input.country=modulecountryidobj.value;
                    let modulecityidobj=document.getElementById('modulecityid'+mdl);
                    this.input.city=modulecityidobj.value;
                    this.input.lastmodifiedby=this.undt.username
                }
                if(this.selectedRadio){
                    this.input.type=this.selectedRadio
                }
                if(this.selectedApp){
                    this.input.type=this.selectedApp
                }
                if(mdl == 't10'){
                    apiURL='api/master/commonmaster/save'
                }else if(tblNm == 't14'){
                    if(this.roleselection.project){
                        this.input.projroleprojectdd=this.roleselection.project
                    }
                    if(this.roleselection.role.length){
                        this.input.projectroleiddd=JSON.stringify(this.roleselection.role)
                        if(this.roleselection.role){
                        var tmprolearr=[]
                        for(var i=0;i < this.roleselection.rolelist.length;i++){
                            if(this.roleselection.role.includes(this.roleselection.rolelist[i].id)){
                                tmprolearr.push({'rolename':this.roleselection.rolelist[i].label,'roleid':this.roleselection.rolelist[i].id})
                            }
                        }
                        this.input.projectroledata=JSON.stringify(tmprolearr)
                    }
                    }
                    this.input.name='1';
                    apiURL='api/projectonlyroleassignment/create'
                }

                if(objThis.currentTarget.dataset.isedit == "true"){
                    apiURL='api/master/commonmaster/update'
                    this.input.id=this.editRowData.moduleid
                    this.input.lastmodifiedby=this.undt.username
                    if(tblNm == 't10'){
                        apiURL='api/master/commonmaster/update'
                    }
                    delete this.input.createdby;
                }else if(this.iseditstate==true){
                    if(tblNm == 't14'){
                        this.input.id=this.editRowData.moduleid
                        this.input.lastmodifiedby=this.undt.username
                        this.input.id=this.editRowData.batchid
                        apiURL='api/projectonlyroleassignment/update'
                    }
                    delete this.input.createdby;
                }
                this.isAddState=false
                this.iseditstate=false
                this.input.useremail= this.undt.username
                this.input.empcode= this.undt.userid
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading=false            
                    if(result.data.errorCode == 0){
                        $('.close').click()
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.masterlist(mdl,'');
                    } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        openEditModule(currRowData){
            this.editRowData = currRowData
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle){
                $('#modulename'+inputEle).val(currRowData.modulename)
            }
            if(inputEle=="t1"){
                this.company.name = currRowData.modulename
            }else if(inputEle=="t2"){
                this.business.name = currRowData.modulename
            }else if(inputEle=="t3"){
                this.project.type = currRowData.modulename
            }else if(inputEle=="t4"){
                this.apptype.type = currRowData.modulename
                this.selectedRadio= currRowData.moduletype
            }else if(inputEle=="t5"){
                this.techtype.type = currRowData.modulename
                this.selectedApp= parseInt(currRowData.applicationtypeid)
            }else if(inputEle=="t6"){
                this.hostapp.name = currRowData.modulename
            }else if(inputEle=="t7"){
                this.clientapp.name = currRowData.modulename
            }else if(inputEle=="t8"){
                this.accessapp.name = currRowData.modulename
            }else if(inputEle=="t53"){
                this.employeetype.name = currRowData.modulename
            }else if(inputEle=="t54"){
                this.countrymaster.name = currRowData.modulename
            }else if(inputEle=="t55"){
                this.citymaster.name = currRowData.modulename
            }else if(inputEle=="t57"){
                this.jobportalmaster.name = currRowData.modulename
                this.jobportalmaster.desc = currRowData.description
            }else if(inputEle=="t58"){
                this.joblocationmaster.name = currRowData.modulename
                this.joblocationmaster.countryid = currRowData.country
                this.joblocationmaster.cityid = currRowData.city
            }else if(inputEle=="t10"){
                this.rolemaster.name = currRowData.modulename
            }else if(inputEle == 't14'){
                var arrroleidlist=currRowData.roleidlist.split(",")
                this.roleselection.role = arrroleidlist
                this.roleselection.project=currRowData.projectid
                $('#modulename'+inputEle).val(currRowData.rolecount)
                this.iseditstate=true
            }
            if(btnEle){
                $('#'+btnEle).find('#type-success').addClass(btnEle)
                $('#'+btnEle).find('#type-success').attr('data-isedit',true)
            }
            $('#glbsearchinpele').val('')
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1;
                this.inputdata = {
                    tbl:mdl,
                    offset:this.pageoffset,
                    limit:this.pagelimit,
                    useremail: this.undt.username,empcode: this.undt.userid,
                }
                let apiURL='api/master/commonmaster/list'
                if(mdl == 't11'){
                    apiURL='api/master/commonmaster/dropdownlist'
                }
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputdata,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(mdl == 't11'){
                        this.roleselection.projectlist=result.data.data.rows
                        return true;
                    }else{
                        var tmpClassNm='default';    
                        if(mdl == 't4'){
                            if(curracttab == 'tech'){
                                tmpClassNm='devApplicationTypeData'
                            }
                        }else if(mdl == 't21'){
                            if(curracttab == 'role'){
                                tmpClassNm='employeemasterdd'
                            }
                        }
                        if(result.data.errorCode == 0){
                            this.ddapplicationlist=result.data.data.rows
                            $('.'+tmpClassNm).wrap('<div class="position-relative"></div>').select2({
                                dropdownAutoWidth: true,
                                dropdownParent: $('.'+tmpClassNm).parent(),
                                width: '100%',
                                data: this.ddapplicationlist
                            });
                        }
                    }
                }).catch(e => {
                this.displayError(e)
            });
        },
        setClickedRadioVal(objThis){
            this.selectedRadio = objThis.currentTarget.value
        },
        seltechapptypedata(state,value){
            this.selectedApp=null
            this.selectedApp=state
            if(!state && this.isAddState == false){
                this.techtype.apptypeerror=true
            }else{                
                this.techtype.apptypeerror=false
            }
        },

        validateForm(mdl,objThis){
            this.clearerrorForm();
            this.isAddState=false
            var isValid=true;
            if(mdl=="t14"){
                if(!this.roleselection.project){
                    this.errors.roleselection.project="Project required";
                    isValid= false;
                }
                if(this.roleselection.role == null || this.roleselection.role == '' || this.roleselection.role.length == 0){
                    this.errors.roleselection.role="Role required";
                    isValid= false;
                }
            }
            return isValid
        },
        clearerrorForm(){
            this.errors= {
                roleselection:{
                    project:null,
                    role:null
                },
            };
        },
        selroleasiproj(state,value){
            this.roleselection.project=null
            this.roleselection.project=state
            if(!state && this.isAddState == false){
                this.errors.roleselection.project='Project required'
            }else{                
                this.errors.roleselection.project=null
            }
        },
        selroleasirole(state,value){
            this.roleselection.role=null
            this.roleselection.role=state
            if(state.length == 0 && this.isAddState == false){
                this.errors.roleselection.role='Role required'
            }else{                
                this.errors.roleselection.role=null
            }
        },
        getThirdPartyAPIcall(mdl,curracttab){
            var calltype=''
            
            calltype=this.currcalltype
            this.pageoffset=1
            this.inputthrid = {
                tbl:mdl
            }
            var apiURL='api/master/commonmaster/thirdpartyapicall' 
            if(mdl == 't10'){
                apiURL='api/projectonlyroleassignment/getdesignations'
                this.inputthrid.currentState=calltype
                this.inputthrid.empcode= this.undt.userid
                this.inputthrid.useremail= this.undt.username
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputthrid,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status == true){
                    if(mdl == 't10'){
                        if(curracttab == 'projectrolesele-tab'){
                            if(this.inputthrid.currentState == 'fcsmart'){
                                this.roleselection.rolelist = result.data.data.rows
                            }
                            return true;
                        }
                    }
                }else if(result.data.status == false){
                    if(mdl == 't10'){
                        if(curracttab == 'projectrolesele-tab'){
                            this.roleselection.rolelist=[{
                                id:'',
                                label:'Issue in CAM Integration API'
                            }]
                            return true;
                        }
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
    }
}
</script>
