<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Functionality Module</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li v-if="pagefeatures.breadcrum_project" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Wizard',path:'project',btnstate:'view',rowdata:backpagedata})">Projects</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Projects</li>
                                    <li v-if="pagefeatures.breadcrum_application" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application',path:'application',btnstate:'view',rowdata:backpagedata})">Applications</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Applications</li>
                                    <li v-if="pagefeatures.breadcrum_module" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Module',path:'projectmodule',btnstate:'view',rowdata:backpagedata})">Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Modules</li>
                                    <li v-if="pagefeatures.breadcrum_appmodule" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application Module',path:'applicationmodule',btnstate:'view',rowdata:backpagedata})">Application Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Application Modules</li>
                                    <li v-if="pagefeatures.breadcrum_section" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'SectionModule',path:'sectionmodule',btnstate:'view',rowdata:backpagedata})">Sections</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Sections</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="card-navigation">
                <div class="file-manager-content-body">
                    <div class="drives">
                        <div class="card border-warning filter_card_searchbgst" >
                            <div class="card-header" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                                <div class="row" style="width:100%">
                                    <div class="col-md-2">
                                        <div class="avatar bg-light-secondary rounded">
                                            <div class="avatar-content">
                                                <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-9" style="margin-left:-130px;margin-top:5px;">
                                        <h4 class="card-title">Search Functionality Details</h4>
                                    </div>
                                    <div v-if="pagefeatures.cratefunctionalitymodule || showbutton" class="col" style="margin-top:2px">
                                        <div class="input-group-append float-right">
                                        <button id="commonaddmodule" class="btn waves-effect waves-float waves-light createbtn" @click="redirecttourl('create', clickedTaskData)">+ Create Functionality</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:10px">
                                <div class="card-body">
                                    <div class="row" >
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <label class="form-label lable-left">Project Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.projectMasterid" :options="search.projectMasterOpts" @input="selprojectid"/>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                            <label class="form-label lable-left">Application</label>
                                            <treeselect placeholder="Select application name"
                                                class="projectmdllistdd capitalisetext"
                                                v-model="search.applicationMasterid"
                                                :options="search.applicationMasterOpts"
                                                @input="selApplicationid"/>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <label class="form-label lable-left">Project Modules</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project module" v-model="search.projectModuleid" :options="search.projectModuleOpts" @input="selprojectModuleid"/>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                            <label class="form-label lable-left">Application Module</label>
                                            <treeselect placeholder="Select application module name"
                                                class="projectmdllistdd capitalisetext"
                                                v-model="search.applicationModuleid"
                                                :options="search.applicationModuleOpts" @input="selApplicationModuleid"/>
                                        </div>                     
                                        <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                            <label class="form-label lable-left">Section</label>
                                            <treeselect placeholder="Select section name"
                                                class="projectmdllistdd capitalisetext"
                                                v-model="search.sectionMasterid"
                                                :options="search.sectionMasterOpts"/>
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <label class="form-label lable-left">Functionality Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search functionality name" v-on:search-change="functionalityChange" v-model="search.functionalityMasterid" :options="search.functionalityMasterOpts" />
                                        </div>
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <button type="submit" class="btn btn-primary mt-2 mr-1" style="text-align:center" @click="getfunctionalitylist(search)">Search</button>
                                            <button type="reset" class="btn btn-outline-secondary mt-2" @click="resetFilter()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="blog-list-wrapper">
                            <div class="row">
                                <div class="col-md-4 col-12"  v-for="(data,index) in companymasterlist" v-bind:key="index">
                                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                                <div class="card-header">
                                    <div class="col-6">
                                    <h4 class="card-title">{{data.projectname}} </h4>
                                    </div>
                                    <div class="col-6;margin-left=0">
                                <a @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                                                <div class="d-flex align-items-center sitebluecolor">
                                                        <message-square-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></message-square-icon>
                                                    <span class="text-body font-weight-bold sitebluecolor"  >Edit</span>
                                                </div>
                                            </a>
                                </div>
                                </div>
                                
                                <div class="card" style="margin-bottom:0 !important;">
                                    <div class="card-body">
                                        <h4 class="card-title">
                                            <span v-if="data.functionlityname.length > 50"  class="blog-title-truncate text-body-heading ">
                                                {{data.functionlityname.substring(0,51)+ ' ...'}}
                                            </span>
                                            <span v-else  class="blog-title-truncate text-body-heading ">
                                                {{data.functionlityname}}
                                            </span>
                                        </h4>
                                        <div class="media">
                                            <div class="avatar mr-50">
                                                <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename"></profilepic>
                                                <img alt=""  v-if="!data.imagename" :src="defaultimagepath"  width="24" height="24" />
                                            </div>
                                            <div class="media-body">
                                                <small class="text-muted mr-25">by</small>
                                                <small><a href="javascript:void(0);" class="text-body">{{data.createdby?data.createdby.split('@')[0]:''}}</a></small>
                                                <span class="text-muted ml-50 mr-25">|</span>
                                                <small class="text-muted">{{dateMONFormat(data.createddate)}}</small>
                                                <small><a href="javascript:void(0);" class="text-body">{{data.empfullname}}</a></small>
                                            </div>
                                        </div>
                                        <div class="my-1 py-25">
                                                <div  class="capitalisetext badge badge-pill badge-light-info mr-50">{{data.projectmodulename}}</div>
                                                
                                                <div  class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.applicationmodulename}}</div>
                                                <div  class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.sectionname}}</div>
                                            
                                                <div  class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.typename}}</div>

                                                <div v-if="data.qacycle>=3" class="badge badge-pill badge-light-danger capitalisetext">{{data.qacycle}}</div>
                                                <div v-if="data.qacycle<3" class="badge badge-pill badge-light-primary capitalisetext">{{data.qacycle}}</div> 

                                        </div>
                                        <p class="card-text blog-content-truncate" style="overflow:hidden">
                                            <span v-if="data.functionlitydescription.length>150" v-html="data.functionlitydescription.substring(0, 151) + ' ...'"></span>
                                            <span v-else v-html="data.functionlitydescription"></span>
                                        </p>
                                        <hr />
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a @click="redirecttourl('view1',data)" href="javascript:void(0);" >
                                                <div class="d-flex align-items-center sitebluecolor">
                                                        <eye-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></eye-icon>
                                                    <span class="text-body font-weight-bold sitebluecolor">View Workstep</span>
                                                </div>
                                            </a>
                                            <span>|</span>
                                            <a @click="redirecttourl('add',data)" href="javascript:void(0);" >
                                                <div class="d-flex align-items-center sitebluecolor">
                                                        <plus-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></plus-icon>
                                                    <span class="text-body font-weight-bold sitebluecolor">Add Workstep</span>
                                                </div>
                                            </a>
                                            <span>|</span>
                                            <a @click="redirecttourl('view',data)" href="javascript:void(0);" >
                                                <div class="d-flex align-items-center sitebluecolor">
                                                        <eye-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></eye-icon>
                                                    <span class="text-body font-weight-bold sitebluecolor">View</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                            <div v-if="companymasterlist.length==0">
                                <tr><td colspan="14" style="text-align: center;">No Records Found</td></tr>
                            </div>
                            <div class="row">
                                <div class="col-12 my-2">
                                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
            </section>
        </div>        
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>

import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect'
import { MessageSquareIcon,EditIcon,EyeIcon,PlusSquareIcon,FolderIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,BoxIcon,ChromeIcon,CloudIcon,CodesandboxIcon,GridIcon,SearchIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name:'flist',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
         MessageSquareIcon,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        FolderIcon,
        PlusSquareIcon
    },
    
    data() {
        return {
            clickedTaskData: null,
            showbutton:false,
            isLoading: false,
            fullPage: true,
            defaultimagepath:apiUrl.defaultimagepath,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            loginusername:null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            functionalitymodule:{
                sectionmasterid:null,
                sectionmasterlist:null,
                applicationmoduleid:null,
                applicationmodulelist:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                applicationid:null,
                applicationlist:null,
                functionlityname:null,
                functionlitydescription:null,
                appprojectdd:null,
                projectmoduleid:null,
                label:null,
                project:null,
                projectmodule:null,
                applicationname:null,
                applicationmodulename:null,
                sectionname:null,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                iseditstate:false
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            pageoffset:1,
            currPageNum: 0,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                functionalitymodule:{
                    functionlityname:null,
                    functionlitydescription:null
                },
            },
            valid:false,
            projectmoduleid:null,
            projectname:null,
            projectmodulename:null,
            projectnameList:[],
            projectmodulenameList:[],
            search: {
                applicationModuleid: null,
                applicationModuleOpts: [],
                applicationMasterid: null,
                applicationMasterOpts: [],
                projectMasterid: null,
                projectMasterOpts: [],
                projectModuleid: null,
                projectModuleOpts: [],
                sectionMasterid: null,
                sectionMasterOpts: [],
                functionalityMasterid: null,
                functionalityMasterOpts: [],
            },
            isSearch:false,
            backpagedata:null,
            functionlitynameBlured:false,
            functionlitydescBlured:false,
            tokendata: null,
        }
    },
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/functionalitymodule') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.empid = userdata.userid
                this.tokendata = window.localStorage.getItem('token');
            }
            if(window.localStorage.getItem('functionalitymodule')){
                let searchData =  window.atob(window.localStorage.getItem('functionalitymodule'));
                searchData = JSON.parse(searchData)
                this.search.projectMasterid=searchData.projectMasterid
                if(this.search.projectMasterid && this.search.projectMasterid !=null){
                    this.getProjectDataByProjectID(this.search.projectMasterid)
                }
                this.search.applicationMasterid = searchData.applicationMasterid
                this.search.projectModuleid = searchData.projectModuleid
                this.search.applicationModuleid = searchData.applicationModuleid
                this.search.sectionMasterid = searchData.sectionMasterid
                this.search.functionalityMasterid = searchData.functionalityMasterid
            }
            this.getmodulelist();     

            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view') {
                    this.showbutton=true
                    this.backpagedata=this.$route.params.clickeddata
                    this.clickedTaskData = this.$route.params.clickeddata
                    this.getfunctionalitylist(this.$route.params.clickeddata)
                    this.getprojectmoduleapplications(this.$route.params.clickeddata)
                }
            }else{
                this.getfunctionalitylist(this.search)
            }
        }
    },
    methods:{
        getProjectDataByProjectID(projectmasterid){
            this.isLoading=true
            let url = 'api/listing/getprojectlistbycompanyid'
            this.input = {
                "companyid":null,
                "projectmasterid":projectmasterid,
            }
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid

            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.search.projectMasterOpts=result.data.data;
                }else{
                    this.search.projectMasterOpts=[]
                }
            })
        },
        redirecttobreadkumb:function(objThis){
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            console.log(rowdata, '----')
            rowdata.backbtnstate=btnstate
            if(btnstate=="view") {
                this.$router.push({ 
                    path: '/functionality/view?functionalityid=' + rowdata.functionlitymasterid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if (btnstate=="edit") {
                this.$router.push({ 
                    path: '/functionality/update?functionalityid=' + rowdata.functionlitymasterid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if (btnstate=="create") {
                this.$router.push({ 
                    name: 'fcreate',
                    path: '/functionality/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
            else if (btnstate=="add") {
                this.$router.push({ 
                    name: 'wcreate',
                    path: '/workstep/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
            else if (btnstate=="view1") {
                this.$router.push({ 
                    name: 'Workstep List',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        selappapltype(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
        getmodulelist:function(){
            this.isLoading=true
            let url = 'api/projectapplication/getSectionList'
            this.input = {
                "applicationmoduleid":0,
                "usermail":this.undt.username,
                "offset":1,
                "limit":null
            }
            this.input.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){                    
                    this.modulelist=result.data.data;
                }else{
                    this.modulelist=[]
                }
            })
        },
        
        getFilter: function() {
            this.isLoading=true
            let url = 'api/projectapplication/getfunctionalitylist'
            this.input = {
                "offset":1,
                "tbl":"t34",
                "limit":1000,
                "isSearch":true,
                "section":"functionalitymodule",
                "isFilterSelect":1,
                "isFilterPayload":{}
            }
            if(this.search.applicationModuleid && this.search.applicationModuleid != null) {
                this.input.isFilterPayload.applicationModuleid = this.search.applicationModuleid
            }
            if(this.search.projectMasterid && this.search.projectMasterid != null) {
                this.input.isFilterPayload.projectMasterid = this.search.projectMasterid
            }
            if(this.search.applicationMasterid && this.search.applicationMasterid != null) {
                this.input.isFilterPayload.applicationMasterid = this.search.applicationMasterid
            }
            if(this.search.projectModuleid && this.search.projectModuleid != null) {
                this.input.isFilterPayload.projectModuleid = this.search.projectModuleid
            }
            if(this.search.sectionMasterid && this.search.sectionMasterid != null) {
                this.input.isFilterPayload.sectionMasterid = this.search.sectionMasterid
            }
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
                .then(result => {  
                    this.isLoading=false;           
                    if(result.data.errorCode == 0) {            
                        this.companymasterlist=result.data.data;
                        this.totalcount = result.data.data.length
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    } else {
                        this.companymasterlist=[]
                        this.totalcount = 0
                        this.isSearch= true
                    }
                })
        },
        projectChange: function (node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.empid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                    } else {
                        this.search.projectMasterOpts = [];
                    }
                });
            }
        },
        functionalityChange: function(node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/master/commonmaster/getModuleDropdownOption";
                this.input = {
                    tbl: "t37",
                    functionalityname:node.toLowerCase(),
                    useremail: this.undt.username,
                };
                this.input.empcode= this.undt.userid
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.functionalityMasterOpts = result.data.data;
                    } else {
                        this.search.functionalityMasterOpts = [];
                    }
                });
            }
        },
        selprojectid(state, value) {
            this.search.projectMasterid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
                this.search.functionalityMasterOpts = null;
                this.search.projectModuleid = null;
                this.search.applicationMasterid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
                
            }
        },
        selprojectModuleid(state, value) {
            
            this.search.projectModuleid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationModuleOpts = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selApplicationid(state, value) {
            this.search.applicationMasterid = state;
            if (!state && this.isAddState == false) {
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },

        selApplicationModuleid(state,value) {
            this.search.applicationModuleid = state;
            if (!state && this.isAddState == false) {
                this.search.sectionMasterOpts = null;
                this.search.sectionMasterid = null;
            } else {
                this.getDropdownApplicationdata("t35");
            }
        },
        resetFilter: function (){
            this.search.applicationModuleid = null;
            this.search.applicationMasterid = null;
            this.search.projectmoduleid= null;
            this.search.projectMasterid = null;
            this.search.projectModuleid = null;
            this.search.functionalityMasterid=null;
            this.projectnameList = null;
            this.search.functionalityMasterOpts=null;
            let rowdata = {}
            this.getfunctionalitylist(rowdata)
        },
        resetForm: function () {
            this.functionalitymodule.functionlityname = null       
            this.functionalitymodule.functionlitydescription = null
        },

        getProjectname(){
            this.apiURL="api/projectapplication/getprojectnamelist"
            this.input={
                projectid:0,
            }
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.errorCode == 0){
                    this.search.projectMasterOpts = result.data.data;
                }
                else{
                    this.search.projectMasterOpts =[]
                }

            })
        },

        getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
            }
            if(mdl === "t36") {
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t12") {
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t34") {
                this.input.projectModuleid = this.search.projectModuleid
            }
            else if(mdl === "t35") {
                this.input.applicationModuleid = this.search.applicationModuleid
            } 
                else if(mdl === "t37") {
                this.input.sectionMasterid = this.search.sectionMasterid
            } 
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = result.data.data;
                        } 
                    this.loadFunctionalityNames("t37")
                    } else {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = []
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = []
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = [];
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = [];
                        }
                    }
                })

        },
        loadFunctionalityNames(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
                projectMasterid: this.search.projectMasterid,
                applicationMasterid: this.search.applicationMasterid,
                projectModuleid: this.search.projectModuleid,
                applicationModuleid: this.search.applicationModuleid,
                sectionMasterid: this.search.sectionMasterid,
            }
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid

            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.search.functionalityMasterOpts = result.data.data;
                    } else {
                        this.search.functionalityMasterOpts = null;
                    }
                })
        },
        getfunctionalitylist(rowdata){
            this.isLoading=true
            this.clickedprojdata=rowdata
            this.input = {
               offset: this.pageoffset,
               limit: apiUrl.LIMIT,
               sectionmasterid:rowdata.sectionmasterid,
               usermail:this.undt.username,
               projectMasterid: rowdata.projectMasterid,
               projectModuleid: rowdata.projectModuleid,
               applicationMasterid: rowdata.applicationMasterid,
               applicationModuleid: rowdata.applicationModuleid,
               sectionMasterid: rowdata.sectionMasterid,
               functionalityMasterid: rowdata.functionalityMasterid,
               uniqueid: this.undt.userid,
               empcode: this.empid,
               useremail: this.undt.username
            }
            localStorage.setItem('functionalitymodule',window.btoa(JSON.stringify(this.input)));
            let url = 'api/functionality/list'
            this.functionalitymodule.project=this.clickedprojdata.projectname
            this.functionalitymodule.projectmodule=this.clickedprojdata.projectmodulename
            this.functionalitymodule.appprojectdd = this.clickedprojdata.projectid
            this.functionalitymodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.functionalitymodule.applicationid = this.clickedprojdata.applicationid
            this.functionalitymodule.applicationname = this.clickedprojdata.applicationname
            this.functionalitymodule.applicationmodulename = this.clickedprojdata.applicationmodulename
            this.functionalitymodule.sectionname = this.clickedprojdata.sectionname
            this.functionalitymodule.sectionmasterid = this.clickedprojdata.sectionmasterid
            this.functionalitymodule.applicationmoduleid = this.clickedprojdata.applicationmoduleid
            this.functionalitymodule.projectid = this.clickedprojdata.projectid
            this.functionalitymodule.label = this.clickedprojdata.label
            this.functionalitymodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.functionalitymodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.functionalitymodule.companyid=this.clickedprojdata.companyid
            this.getApplicationdata('t3','functionalitymodule')
            this.getApplicationdata('t2','functionalitymodule')
            this.getApplicationdata('t1','functionalitymodule')
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false; 
                if(result.data.errorCode == 0){
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    
                }else{
                    this.companymasterlist=[]
                    this.showproject=true;
                    this.totalcount = 0
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);                
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getfunctionalitylist(this.clickedprojdata)
            }
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        setSelected(value)
        {
            this.selected=value
        },
        validateForm(mdl,objThis){
            this.isAddState=false
            var isValid=true;
            this.functionlitydescBlured=true;
            this.functionlitynameBlured=true;
            if(mdl=="t37"){
                if(!this.functionalitymodule.sectionmasterid){
                    this.errors.functionalitymodule.sectionmasterid="Section required";
                    isValid= false;
                }
                if(!this.functionalitymodule.applicationmoduleid){
                    this.errors.functionalitymodule.applicationmoduleid="Application Module required";
                    isValid= false;
                }
                if(!this.functionalitymodule.projectid){
                    this.errors.functionalitymodule.projectid="Project required";
                    isValid= false;
                }
                if(!this.functionalitymodule.functionlityname){
                    this.errors.functionalitymodule.functionlityname="Functionality name required";
                    isValid= false;
                }
                if(!this.functionalitymodule.functionlitydescription){
                    this.errors.functionalitymodule.functionlitydescription="Functionality description required";
                    isValid= false;
                }
            }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                functionalitymodule:{
                    functionlityname:null,
                    functionlitydescription:null
                },
            };
        },
        openEditModule(currRowData){
            this.editRowData = currRowData
            this.functionalitymodule.sectionmasterid = currRowData.sectionmasterid
            this.functionalitymodule.projectid = currRowData.projectid
            this.functionalitymodule.projectmoduleid = currRowData.projectmoduleid
            this.functionalitymodule.applicationmoduleid = currRowData.applicationmoduleid
            this.functionalitymodule.applicationid = currRowData.applicationid
            this.functionalitymodule.functionlityname = currRowData.functionlityname
            this.functionalitymodule.functionlitydescription = currRowData.functionlitydescription
   this.functionalitymodule.applicationname = currRowData.applicationname
            this.functionalitymodule.project = currRowData.projectname
            this.functionalitymodule.projectmodule= currRowData.projectmodulename
            this.getprojectmoduleapplications(currRowData)
            this.functionalitymodule.applicationmodulename= currRowData.applicationmodulename
            this.functionalitymodule.sectionname= currRowData.sectionname
            this.functionalitymodule.projecttypeid= currRowData.projecttypeid
            this.functionalitymodule.businessverticleid= currRowData.businessverticleid
            this.functionalitymodule.companyid= currRowData.companyid

            this.functionalitymodule.iseditstate = true
            this.isAddState=true
        },
        getprojectmoduleapplications: function(rowdata){
            this.inputprojappl = {
                id:rowdata.projectmoduleid
            }
            let apiURL='api/projectmodule/getapplications'
            this.inputprojappl.useremail= this.undt.username
            this.inputprojappl.empcode= this.undt.userid

            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.functionalitymodule.applicationname=[]
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                            this.functionalitymodule.applicationname.push(element.applicationname)
                        }
                    });

                }else{
                    this.applicationmodule.applicationid=null
                }
            })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMONFormat(value){
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        saveData(tblNm,objThis){
            if( tblNm=="t37"){
                this.valid=this.validateForm(tblNm)
            }else{
                this.valid=false;
            }
            if (this.valid) {
                let nameobj=document.getElementById('modulename'+tblNm)
                this.input = {
                    tbl: tblNm,
                    section: 'assignment',
                    createdby: this.undt.username
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                let apiURL='api/functionality/create'
                if(tblNm == 't37'){
                    this.input.sectionid=this.functionalitymodule.sectionmasterid
                    this.input.projectid=this.functionalitymodule.projectid
                    this.input.projectmoduleid=this.functionalitymodule.projectmoduleid
                    this.input.applicationmoduleid=this.functionalitymodule.applicationmoduleid
                    if(this.functionalitymodule.applicationid){
                        this.input.applicationid=this.functionalitymodule.applicationid
                    }else{
                        this.input.applicationid=null
                    }
                    this.input.functionlityname=this.functionalitymodule.functionlityname
                    this.input.functionlitydescription=this.functionalitymodule.functionlitydescription
                    this.input.section='assignment'
                    delete this.input.name;
                } 
                if(this.functionalitymodule.iseditstate == true){
                    apiURL='api/functionality/update'
                    this.input.lastmodifiedby=this.undt.username
                    this.input.id=this.editRowData.functionlitymasterid
                    delete this.input.createdby;
                }               
                this.isAddState=false
                this.input.empcode=this.undt.userid
                this.input.useremail=this.undt.username
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        $('.close').click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.rowdata={
                            label : this.functionalitymodule.label,
                            projectname : this.functionalitymodule.project,
                            projectmodulename : this.functionalitymodule.projectmodule,
                            appprojectdd : this.functionalitymodule.projectid,
                            projectmoduleid : this.functionalitymodule.projectmoduleid,
                            sectionmasterid : this.functionalitymodule.sectionmasterid,
                            applicationid : this.functionalitymodule.applicationid,
                            applicationname : this.functionalitymodule.applicationname,
                            applicationmodulename : this.functionalitymodule.applicationmodulename,
                            sectionname : this.functionalitymodule.sectionname,
                            applicationmoduleid : this.functionalitymodule.applicationmoduleid,
                            projectid : this.functionalitymodule.projectid,
                            projecttypeid : this.functionalitymodule.projecttypeid,
                            businessverticleid : this.functionalitymodule.businessverticleid,
                            companyid : this.functionalitymodule.companyid
                        }
                        this.getfunctionalitylist(this.rowdata)
                        this.isLoading = false
                        this.clearerrorForm();
                    } else {
                        Swal.fire({
                            title: "Oops...!",
                            text: result.data.msg,
                            icon: "error",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                })
            }
        },
        selfmsectionmdldata(state,value){
            this.functionalitymodule.sectionmasterid=null
            this.functionalitymodule.sectionmasterid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.sectionmasterid="Section Required"
            }else{                
                this.errors.functionalitymodule.sectionmasterid=null
                this.getfiltereddata('functionalitymodule');
            }
        },
        selfmprojectdata(state,value){
            this.functionalitymodule.projectid=null
            this.functionalitymodule.projectid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.projectid="Project Required"
            }else{                
                this.errors.functionalitymodule.projectid=null
            }
        },
        selfmapplmdldata(state,value){
            this.functionalitymodule.applicationmoduleid=null
            this.functionalitymodule.applicationmoduleid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.applicationmoduleid='Application Module required'
            }else{                
                this.errors.functionalitymodule.applicationmoduleid=null
            }
        },
        selfmappmdldata(state,value){
            this.functionalitymodule.applicationid=null
            this.functionalitymodule.applicationid=state
            if(!state && this.isAddState == false){
                this.errors.functionalitymodule.applicationid='Application required'
            }else{                
                this.errors.functionalitymodule.applicationid=null
            }
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'functionalitymodule'){
                this.inputappl = {
                    tbl:mdl
                }
            }
            this.inputappl.empcode = this.empid
            this.inputappl.useremail= this.undt.username
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't2'){
                        this.functionalitymodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'functionalitymodule'){
                            this.functionalitymodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.functionalitymodule.companylist=result.data.data.rows
                        return true;
                    }
                }
            })
        },
        addmodule(){         
            this.functionalitymodule.functionlityname = null
            this.functionalitymodule.functionlitydescription = null
            this.functionalitymodule.iseditstate = false
        }
    }
}
</script>