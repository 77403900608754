<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0 mobile-padding-0-10">
           <div class="container-fluid px-0 ">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left">
                 <h3 class="content-header-title float-left mb-0">Forms Configuration</h3>
                 <div class="breadcrumb-wrapper">
                   <ol class="breadcrumb">
                     <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                      <router-link :to="{ name: 'FormsConfigurationList', params: { search: true }}">Forms Configuration List</router-link>
                    </li>
                       <li class="breadcrumb-item"><router-link to='/formsconfig/create'>Update Forms Configuration</router-link></li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="card">
           <div class="card-body p-1">
             <form class="validate-form">
               <div class="row">
                <div class="col-12 px-0">
                  <div class="form-group col-md-4 mb-2">
                    <label for="ownername">Department</label>
                    <treeselect  @input="selDepartment" placeholder="Select Department" class="projectlistdd" :options = "deparmentlist" v-model="formconfigure.formdata[0].departmentid " v-if="deparmentlist.length > 1"/>
                    <treeselect  @input="selDepartment" placeholder="Select Department" class="projectlistdd" :options = "deparmentlist" v-model="formconfigure.formdata[0].departmentid " disabled v-else/>
                  </div>
                </div>
                <div class="col-12">
  
                   <template v-for="(formconfigure, index) in formconfigure.formdata">
					
                     <div v-bind:key="index" class="card border-warning" style=" margin-bottom: 1rem !important; overflow: hidden;">
                       <div class="card-header p-1 cursor-pointer" style="background:#EDF4FF">
                         <h4 class="card-title" style="color: #3E4757!important;">Update Form Configuration	</h4>
                         <div class="heading-elements text-primary" >
                           <ul class="list-inline mb-0">
                             
                           </ul>
                         </div>
                       </div>
                       <div v-bind:class="[{show: formconfigure.showform},card_content,collapse]">
                         <div class="card-body">
                           <form>
                             <div class="row">
                              <div class="form-group col-md-4">
                                 <label for="ownername">Tracker</label>
                                 <treeselect placeholder="Select Tracker" class="projectlistdd" v-model="formconfigure.tracker" :options="trackerlist" :multiple="true" :clear-on-select="true"  @close="validateFormConfig(index, $event)" @input="getKRAHeaderList($event,formconfigure.tracker, formconfigure.fieldheaderid)" />
								<div class="errorinputmsg" v-if="errors.formconfigure.formdata[0].tracker">{{ errors.formconfigure.formdata[0].tracker }}</div>
                               </div>
                               <div class="form-group col-md-4">
                                <div class="demo-inline-spacing custom_radio_creaternote">
                                  <label for="companyname" class="font-weight-bold m-0  w-100">Project Selection</label>
                                  <div class="mb-0 py-1 m-0 w-100 " style="padding-top: 6px!important;">
                                    <div class=" form-check form-check-inline mt-0 mb-0">
                                      <input class="form-check-input" type="radio" v-model="formconfigure.projectflag" value="1"> <span class="pl-1" > All Project</span>
                                    </div>
                                    <div class=" form-check form-check-inline mt-0">
                                      <input class="form-check-input" type="radio" v-model="formconfigure.projectflag" value="0" > <span class="pl-1"> Selected Project</span>
                                    </div>
                                  </div>
                                </div>
                               </div>
  
                               <div class="form-group col-md-4" v-if="formconfigure.projectflag == 0">
									<label for="ownername">Project Name</label>
									<treeselect   placeholder="Enter Min 4Chars To Search Project" class="projectlistdd" v-model="formconfigure.projectid" :multiple="true" :clear-on-select="true" v-on:search-change="getallprojectlist" :options="projectlist" @input="selectproject($event,formconfigure.projectid)"/>
									<div class="errorinputmsg" v-if="errors.formconfigure.formdata[0].project">{{errors.formconfigure.formdata[0].project }}</div>
								</div>
  
                              	<div class="form-group col-md-4">
                                 	<label for="ownername">Header</label>
                                 		<treeselect  placeholder="Select KRA Header" class="projectlistdd" v-model="formconfigure.fieldheader" :options="formconfigure.fieldheaderlist" @input="selectHeader($event, index)" v-if="formconfigure.fieldheaderid || (formconfigure.fieldheaderlist && formconfigure.fieldheaderlist.length > 0 )" />
                                		<input type="text" class="form-control" placeholder="Header" v-model="formconfigure.fieldheader" v-else/>
																	</div>
                               <div class="form-group col-md-4">
                                 <label for="title">Title</label>
                                 <input type="text" class="form-control" name="title" placeholder="Title" v-model="formconfigure.title"
                                 v-bind:class="{ 
                                  'form-control': true,
                                  '': !validText(formconfigure.title)
                                  }"
                                  @close="validateFormConfig(index, $event)"/>
                                 <div class="errorinputmsg" v-if="errors.formconfigure.formdata[0].title">{{errors.formconfigure.formdata[0].title }}</div>
                               </div>
                               
                               <div class="form-group col-md-4">
                                 <label for="registrar">Input Type	</label>
                                 <treeselect placeholder="Select Type" class="projectlistdd" v-model="formconfigure.titletype" :options="titletypelist" @input="selectInputs($event, index)" @close="validateFormConfig(index, $event)"/>
                                 <div class="errorinputmsg" v-if="errors.formconfigure.formdata[0].titletype">{{ errors.formconfigure.formdata[0].titletype }}</div>
                               </div>
                             
                              <div class="form-group col-md-4">
                                 <label for="country">Validation Type	</label>
                                 <treeselect placeholder="Select Validation Type" class="projectlistdd" v-model="formconfigure.validationtype" :options="titlevalidationlist" @input="selectval($event, index)" @close="validateFormConfig(index, $event)"/>
                                 <div class="errorinputmsg" v-if="errors.formconfigure.formdata[0].validationtype">{{ errors.formconfigure.formdata[0].validationtype }}</div>
                               </div>
  
                               <div class="form-group col-md-4">
                                 <label for="ownername">Placeholder</label>
                                 <input type="text" class="form-control" name="fieldplaceholder" placeholder="Placeholder" v-model="formconfigure.fieldplaceholder" @blur="validateFormConfig(index, $event)"/>
                                </div>
                              
                              <div class="form-group col-md-4">
                                 <label for="ownername">Index</label>
                                 <input type="number" class="form-control" name="fieldindex" placeholder="Index" v-model="formconfigure.fieldindex" @blur="validateFormConfig(index, $event)"/>
                                  
                               </div>
                               <div class="form-group col-md-4">
                                 <label for="Comment">Error Message</label>
                                 <input type="text" class="form-control" name="fielderrormsg" placeholder="Error Message" v-model="formconfigure.fielderrormsg" @blur="validateFormConfig(index, $event)"/>
                                </div>

							<div class="form-group col-md-4">
                               <label for="Comment">Form Field Key</label>
                               <treeselect class="projectlistdd" name="fielderrormsg" placeholder="Form Field Key" v-model="formconfigure.fieldnamekey" :options="getbtcstatuslisting"/>
                              </div>

                                <div class="form-group  col-md-2">
                              <div class="form-group">
                                <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.ispublish" type="checkbox"   id="myCheckbox1" >
                              <label class="form-check-label  " for="myCheckbox1">
                                Is Publish 
                              </label>
                              </div>
                                </div>
                          <div class="form-group " v-if ="(formconfigure.titletype=='radio' || formconfigure.titletype == 'checkbox'  || formconfigure.titletype == 'drop-down' || formconfigure.titletype == 'multiselect-dropdown' || formconfigure.titletype == 'toggle') " >
                              
                              <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.db_value" type="checkbox"   id="myCheckbox" checked>
                              <label class="form-check-label  " for="myCheckbox">
                              Get Value From API
                              </label>
                              </div> 
                          </div>
                          <div class="form-group">
                                <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.fieldcomment" type="checkbox" id="myCheckbox2" >
                              <label class="form-check-label  " for="myCheckbox2">
                            Allow For Comment
                              </label>
                              </div>
                                </div>
                                <div class="form-group">
                                <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.israting" type="checkbox" id="myCheckbox3" >
                              <label class="form-check-label  " for="myCheckbox3">
                            Is Rating
                              </label>
                              </div>
                                </div>
							<div class="form-group">
								<div class="form-check">
								<input class="form-check-input"  v-model="formconfigure.isstandard" type="checkbox" id="myCheckbox4" >
								<label class="form-check-label  " for="myCheckbox4">
								Is Standard Field</label>
								</div>
							</div>
							<div class="form-group">
								<div class="form-check">
								<input class="form-check-input"  v-model="formconfigure.isexport" type="checkbox" id="myCheckbox5" >
								<label class="form-check-label  " for="myCheckbox5">
								Is Export</label>
								</div>
							</div>
							<div v-if="formconfigure.tracker && formconfigure.tracker.length>0 && formconfigure.tracker.includes(BTCListing)" class="form-group">
								<div class="form-check">
								<input class="form-check-input"  v-model="formconfigure.islisting" type="checkbox" id="myCheckbox6" >
								<label class="form-check-label  " for="myCheckbox6">
								Is Visiable For The Listing</label>
								</div>
							</div>
							<div v-if="formconfigure.tracker && formconfigure.tracker.length>0 && formconfigure.tracker.includes(BTCListing)" class="form-group">
								<div class="form-check">
								<input class="form-check-input"  v-model="formconfigure.iseditlisting" type="checkbox" id="myCheckbox7" >
								<label class="form-check-label  " for="myCheckbox7">
								Is Editable From The Listing</label>
								</div>
							</div>
							<div v-if="formconfigure.tracker && formconfigure.tracker.length>0 && formconfigure.tracker.includes(BTCListing)" class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="formconfigure.islistingorderby" type="checkbox" id="myCheckbox9" >
                              <label class="form-check-label" for="myCheckbox9">
                              Is Order By Column  </label>
                            </div>
                          </div>
						</div>
                               
  
  
                            
								<div class="form-group  col-sm-6" v-if="formconfigure.projectflag == 0">
                                <div class="row mx-0">
                                  <div class="col-md-12 px-0">
                                    <div class="tab-content" id="projectroleconfigtable">
                                      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="proModuleList">
                                          <div class="col-12">
                                            <div class="mb-4">
                                              <div class="table-responsive " style="overflow: unset;">
                                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" class="text-center first-col-sticky align-middle" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                                                      <th scope="col" class="align-middle" style="width: 180px;">Project Name</th>
                                                      <th scope="col" class="align-middle">Field Validation</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <template v-for="(pro, index) in projectselectionlist">
                                                      <tr v-bind:key="index">
                                                        <td class="text-center first-col-sticky m-0">{{ index + 1 }}</td>
                                                        <td>
                                                          <span class="text-center text-uppercase">{{ pro.label }}</span>
                                                        </td>
                                                        <td>
                                                          <div class="form-group col-md-12">
                                                            <treeselect
                                                              placeholder="Select Validation Type"
                                                              class="projectlistdd"
                                                              v-bind:id="'checkallusers' + index"
                                                              v-model="pro.validationtype"
                                                              :options="titlevalidationlist"
                                                              @input="onfilterchange(pro, index)"
                                                            />
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </template>
                                                    <template v-if="projectselectionlist.length === 0">
                                                      <tr><td colspan="3" style="text-align: center;">Please Select Projects</td></tr>
                                                    </template>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="pageCenter text-center">
                                      </div>
                                    </div>
                                </div>
                              </div>
                            
                            </div>

							<div class="form-group col-md-8" >
								<div class="" style="align-items: center;margin: 8px 0px;">
									<div class="form-group col-md-8 mb-0 px-0" v-if ="(formconfigure.titletype=='radio' || formconfigure.titletype=='checkbox'  || formconfigure.titletype == 'drop-down' || formconfigure.titletype == 'multiselect-dropdown' || formconfigure.titletype == 'toggle' )&& formconfigure.db_value==false " >    
										<div class="form-group col-md-4 mb-0 px-0 text-left"  v-if="formconfigure.field_dbvalue==0 && (formconfigure.titletype=='radio' || formconfigure.titletype=='checkbox'  || formconfigure.titletype == 'drop-down' || formconfigure.titletype == 'multiselect-dropdown' || formconfigure.titletype == 'toggle' )">  
											<div class="btn btn-relief-primary ml-0 "  @click="addDocumentFields(index)"><span>Add Option Value<plus-square-icon size="1.5x"></plus-square-icon>       
												</span>
											</div>
										</div>
									</div> 
												
								</div>
								
								<div  v-if="formconfigure.field_dbvalue==0 && (formconfigure.titletype=='radio' || formconfigure.titletype=='checkbox'  || formconfigure.titletype == 'drop-down' || formconfigure.titletype == 'multiselect-dropdown' || formconfigure.titletype == 'toggle')  && formconfigure.db_value==false  " class="row" v-for="(singleDocField, idx) in formconfigure.fieldvalues" :key="`phoneInput-${idx}`" style="border-bottom: 0.5px solid rgba(221, 215, 215, 0.51);  "  >
									
									<div class="form-group d-flex" style="align-items: center;margin: 8px 0px;">
										<label class="form-label col-sm-4">Option Values </label>
										<div class="col-sm-6">
											<input class="form-control" v-model="singleDocField.optionvalue" type="text" placeholder="Enter Option values"  />
											<div class="errorinputmsg" v-if="!singleDocField.optionvalue">Option value is required</div>
										</div>
										<div class="form-group col-md-2 mb-0" style="padding:0px 6px">
											<div class="avatar btn-relief-danger rounded ">
												<div class="avatar-content" @click="removeDocumentField(index,idx)">
													<minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
                            </div>
                             <div class=" mb-0 mt-0 text-right">
                               <div class="btn btn-outline-info mr-1" style="padding-left: 1.4rem; padding-right: 1.4rem" v-on:mousedown="clearField(index)">
                                 <span>Clear</span>
                               </div>
                               <div v-if="index !== 0" class="btn btn-outline-danger"  style="padding-left: 1rem; padding-right: 1rem" @click="deleteField(index)">
                                 <span>Delete</span>
                               </div>
                             </div>
                           </form>
                         </div>
                       </div>
                     </div>
                   </template>
                 </div>
               </div>
             </form>
           </div>
           <div class="flex--items mb-2 px-1 text-right">
             
             <button type="submit" class="btn btn-relief-primary" style="padding-left: 2rem; padding-right:2rem" v-on:click.stop.prevent="saveData(formconfigure.formdata)">Submit</button>
             
           </div>
         </div>
       </section>
     </div>
   </div>
  </template>

<script>
	import axios from 'axios'
	import VueElementLoading from 'vue-element-loading';
	import Treeselect from '@riophae/vue-treeselect'
	import Loading from 'vue-loading-overlay';
	import DatePicker from "vue2-datepicker";
	import 'vue-loading-overlay/dist/vue-loading.css'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import "vue2-datepicker/index.css";
	import { PlusIcon, StarIcon, MinusIcon,MinusSquareIcon,PlusSquareIcon } from 'vue-feather-icons'
	import moment from "moment";
	import apiUrl from "../../constants";
	import commonMethods from '../../utils/commonMethods';


	export default{
		name: 'UpdateFormConfiguration',
		mixins: [ commonMethods ],

		components: {
			VueElementLoading,
			Treeselect,
			DatePicker,
			Loading,
			PlusIcon,
			MinusIcon,
			StarIcon,
			MinusSquareIcon,
			PlusSquareIcon,  
		},
		data() {
			return {
				document: {
						fields: [{
							optionvalue: ''       
						}]
					},
					formconfig: {
						formconfiglist: [],
						prevformconfiglist: [],
					},
					trackerlist:[],
					formconfigure: {
					formdata: [
						{
							title: null,
							titletypelist: null,
							titlevalidation: null,
							tracker:null,
							optionvalue: [{
								optionvalue: '',
							
								}],
							fieldplaceholder:null,
							fieldcomment:false,
							fieldindex:null, 
							fieldheader:null,
							fielderrormsg:null,
							db_value:false,
							ispublish:false,
							iseditlisting:false,
							islistingorderby: false,
							islisting:false,
							isstandard:false,
							isexport:false,
							showform:true,
							fieldheaderlist:[],
							kraheader:false,
							krafieldheader:null,
							israting:false,
							fieldheaderid: null,
							fieldheader:[],
							department: null,
							departmentid: null,
							projectid: null,
							projectflag: null,
							fieldnamekey: null
						}
					]
				},
				projectlist:[],
				titletypelist: [
					{ label: 'Checkbox', id: 'checkbox'  },
					{ label: 'Date', id: 'date'   },
					{ label: 'Drop-down', id: 'drop-down'  },
					{ label: 'Hidden', id: 'hidden'   },
					{ label: 'Multiselect-Dropdown', id: 'multiselect-dropdown'  },
					{ label: 'Number', id: 'number'  },
					{ label: 'Radio-button', id: 'radio'   },
					{ label: 'Text', id: 'text'  },
					{ label: 'Timestamp', id: 'datetime-local'  },	
					{ label: 'Toggle', id: 'toggle'  },								
				],
				titlevalidationlist: [
				        { label: 'Hidden', id: 'hidden' },
						{ label: 'Read-only', id: 'readonly' },
						{ label: 'optional', id: 'optional' },
						{ label: 'required', id: 'required' },    
				],
					errors: {
					formconfigure: {
						formdata: [
							{
								title: null,
								titletype: null,
								titlevalidation: null,
								tracker: null,
							optionvalue: [],
							project: null
							}
						]
					}
				},
				isLoading: false,
				card_content:'card-content',
				collapse:'collapse',
				tab_pane:'tab-pane', 
				fade:'fade',
				TimeFormat: apiUrl.TimeFormat,
				deparmentlist:[],
				userdeparment:null,
				headerlist:[],
				userdeparmentname:null,
				projectValidation:null,

				projectflag: null,
				projectValidation:[],
				projectselectionlist: [],
				selpro :[],

				selectedproject:[],
				projectselectionValidation:[],
				storearr:[],
				finalarrayofprojectvalidation:[],
				search: {
					tracker:null,
					title:null,
					isactive:null,
					ispublish:null,
					department:null,
					departmentid:null,
					isstandard:null,
					isexport:null,
     		},
			editData : null,
			getbtcstatuslisting: [],
     		BTCListing: apiUrl.BTCListing,
			}
		},
		mounted() {
			let userdata = window.localStorage.getItem('userdata');
			if(userdata) {
				userdata = JSON.parse(userdata)
				this.undt = userdata
				this.tokendata = window.localStorage.getItem('token');
				
				this.getEmpDepartList()
				this.getFormConfigurebyid(this.$route.query.id)	
			}
 		},
		methods: {
			getTrackerList(department) {
				this.isLoading = true
				let url = 'api/formconfigure/geTrackerList'
				let payload = new Object({
					moduletype: null,
					useremail  : this.undt.username,
					empcode: this.undt.userid,
					department: department
				})
				axios({
					method: 'POST',
					url,
					data: payload,
					headers:{ authorization :this.tokendata }
				}).then(result => {
					this.isLoading = false
					if(result.data.errorCode == 0) {
						this.trackerlist = result.data.data
					} else if(result.data.errorCode == 3){
						Swal.fire({
							title: "Session Expired...!!",
							text: result.data.msg,
							icon: 'info',
							customClass: {
									confirmButton: "btn btn-primary",
							},
							buttonsStyling: !1,
							}).then(function() {
								window.location.href = "/#/login";
						})
					} else {
						this.trackerlist=[]
					}
				}).catch(e => {
          this.displayError(e)
        })
			},
			getEmpDepartList() {
				this.isLoading = true
				let url = 'api/formconfigure/getEmpDepartList'
				let payload = new Object({
					empid: this.undt.userid,
					useremail  : this.undt.username,
					empcode: this.undt.userid
				})
				axios({
					method: 'POST',
					url,
					data: payload,
					headers:{ authorization :this.tokendata }
				}).then(result => {
					this.isLoading = false
					if(result.data.errorCode == 0) {
						this.deparmentlist = result.data.data.rows;
						let decryptdepartmenttext = this.deparmentlist.map(items =>{
							items.label = this.decryptText(items.label);
							return items;
						})
						if(this.$route.params && this.$route.params.search){
							let search = JSON.parse(localStorage.getItem('formsearchItems'));
							if(search){
								this.getSearchItems(search);
								this.getTrackerList(this.search.departmentid);
							}else{
								localStorage.removeItem('formsearchItems');
								if(result.data && result.data.data){
									if(result.data.data.orows && result.data.data.orows.length >0){
									this.search.departmentid = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
									}
								}
								this.getTrackerList(this.search.departmentid);
								// this.getAllFormConfigData();
							}
						}else{
							localStorage.removeItem('formsearchItems');
							if(result.data && result.data.data){
								if(result.data.data.orows && result.data.data.orows.length >0){
									this.search.departmentid = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
								}
							}
							this.getTrackerList(this.search.departmentid);
							// this.getAllFormConfigData();
						}
					let dp = this.deparmentlist.filter((v) => {
					if(v.id==this.search.departmentid)
					return v;
					});
					this.search.department = dp ? dp[0].label : null
					this.deparmentlist.sort((a, b) => {
						return a.label.localeCompare(b.label);
					});
					} else if(result.data.errorCode == 3){
							Swal.fire({
										title: "Session Expired...!!",
										text: result.data.msg,
										icon: 'info',
										customClass: {
												confirmButton: "btn btn-primary",
										},
										buttonsStyling: !1,
								}).then(function() {
									window.location.href = "/#/login";
							})
						} else {
						this.deparmentlist=[]
						this.search.department = null
					}
				}).catch(e => {
				this.displayError(e)
				})
			},
			getBTCSchemaDetails() {
				let url = 'api/listingconfig/getBTCSchemaDetails'
				let payload = new Object({
					useremail  : this.undt.username,
					empcode: this.undt.userid,
				})
				axios({
					method: 'POST',
					url,
					data: payload,
					headers:{ authorization :this.tokendata }
				}).then(result => {
					if(result.data.errorCode == 0) {
					this.getbtcstatuslisting = result.data.data.rowsData;
					} else if(result.data.errorCode == 3){
						Swal.fire({
							title: "Session Expired...!!",
							text: result.data.msg,
							icon: 'info',
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: !1,
						}).then(function() {
							window.location.href = "/#/login";
						})
					} else {
					this.getbtcstatuslisting=[]
					}
				}).catch(e => {
					this.displayError(e)
					});
			},
			getKRAHeaderList(index,tracker,kraheader) {
				console.log(index)
				if(index.includes(this.BTCListing)) {
					console.log(index)
					this.getBTCSchemaDetails();
				}
    
				let convertedtracker = tracker.map(element => String(element));

			if(convertedtracker.length <= 0){

				this.formconfigure.formdata.fieldheaderlist = []
				this.formconfig.formconfiglist.fieldheaderid = null
				this.formconfig.formconfiglist.fieldheader = null
			}

				if(convertedtracker.length > 0){
				this.isLoading = true
				let url = 'api/formconfigure/getKRAdetailList'
				let payload = new Object({
					useremail  : this.undt.username,
					empcode: this.undt.userid,
					tracker : convertedtracker
				})
				axios({
					method: 'POST',
					url,
					data: payload,
					headers:{ authorization :this.tokendata }
				}).then(result => {
					this.isLoading = false
					if(result.data.errorCode == 0) {
						this.formconfig.formconfiglist.fieldheaderlist = result.data.data
						if(!kraheader){
							this.formconfig.formconfiglist.fieldheader = null
						}

						} else if(result.data.errorCode == 3){
							Swal.fire({
										title: "Session Expired...!!",
										text: result.data.msg,
										icon: 'info',
										customClass: {
												confirmButton: "btn btn-primary",
										},
										buttonsStyling: !1,
								}).then(function() {
									window.location.href = "/#/login";
							})
						} else {
							this.formconfig.formconfiglist.fieldheaderlist = []
							this.formconfig.formconfiglist.fieldheaderid = null
						}
					}).catch(e => {
					this.displayError(e)
					})
				}
			},
			openEditModule: function(currentData) {

				this.getallprojectarr(currentData.projectid)
				this.getprojectlist(currentData.id)
				let trackerarr = []
				this.formconfigure.formdata[0].title = currentData.fieldname
				this.formconfigure.formdata[0].titletype = currentData.fieldtype,
				this.formconfigure.formdata[0].validationtype = currentData.validationtype,
				this.formconfigure.formdata[0].fieldplaceholder = currentData.fieldplaceholder ? currentData.fieldplaceholder : null,
				this.formconfigure.formdata[0].fieldindex = currentData.fieldindex ? currentData.fieldindex : null ,
				this.formconfigure.formdata[0].fielderrormsg = currentData.fielderrormsg,
				this.formconfigure.formdata[0].ispublish = currentData.ispublish ? true : false,
				this.formconfigure.formdata[0].isstandard = currentData.isstandard ? true : false,
				this.formconfigure.formdata[0].isactive = currentData.isactive ? true : false,
				this.formconfigure.formdata[0].fieldcomment = currentData.fieldcomment ? true : false,
				this.formconfigure.formdata[0].israting = currentData.israting? true : false ,
				this.formconfigure.formdata[0].isexport = currentData.isexport ? true : false,
				this.formconfigure.formdata[0].islisting = currentData.islisting ? true : false,
				this.formconfigure.formdata[0].iseditlisting = currentData.iseditlisting ? true : false,
				this.formconfigure.formdata[0].islistingorderby = currentData.islistingorderby ? true : false,
				this.formconfigure.formdata[0].department = currentData.department ? currentData.department : null,
				this.formconfigure.formdata[0].departmentid = currentData.departmentid ? currentData.departmentid : null
				this.formconfigure.formdata[0].fieldheader = currentData.fieldheader ? currentData.fieldheader : null,
				this.formconfigure.formdata[0].fieldheaderid  = currentData.fieldheaderid ? currentData.fieldheaderid : null
				this.formconfigure.formdata[0].fieldvalues = currentData.fieldvalues ? currentData.fieldvalues : null
				this.formconfigure.formdata[0].field_dbvalue = currentData.field_dbvalue
				this.formconfigure.formdata[0].projectid = currentData.projectid ? currentData.projectid : []
				this.formconfigure.formdata[0].tracker = currentData.trackerid ? [currentData.trackerid] : []
				this.formconfigure.formdata[0].fieldnamekey = currentData.fieldnamekey
				if(currentData.projectflag == null){
					this.formconfigure.formdata[0].projectflag = currentData.projectflag =1
				}else{
					this.formconfigure.formdata[0].projectflag = currentData.projectflag
				}
				if(this.formconfigure.formdata[0].projectid && this.formconfigure.formdata[0].projectid.length>0) {
					this.formconfigure.formdata[0].projectid =  [...new Set(this.formconfigure.formdata[0].projectid.split(',').map(item => item.trim()))];
				}
				if(currentData.trackeroption){
					let numberArray = currentData.trackeroption.map(str => parseInt(str));
					this.getKRAHeaderList(currentData.id,numberArray)
				}
			},
			saveData(data) {
				data[0].id = parseInt( this.$route.query.id)
				let valid = this.validateFormConfig(data)
				if(valid) {
					data.edit = false
					let apiurl = `api/formconfigure/update`
					this.isLoading = true
					axios({
						method: 'POST',
						url: apiurl,
						data: {
							id: data[0].id,
							fieldname: data[0].title,
							fieldtype: data[0].titletype,
							validationtype: data[0].validationtype,
							fieldplaceholder: data[0].fieldplaceholder ? data[0].fieldplaceholder : null,
							fieldindex: data[0].fieldindex ? data[0].fieldindex : null ,
							fieldheader: data[0].fieldheader ? data[0].fieldheader : null,
							fieldcomment: data[0].fieldcomment ? 1  : 0 ,
							fielderrormsg: data[0].fielderrormsg,
							ispublish: data[0].ispublish ? 1 : 0,
							isstandard: data[0].isstandard ? 1: 0,
							isexport: data[0].isexport ? 1: 0,
							islisting: data[0].islisting ? 1: 0,
							iseditlisting: data[0].iseditlisting ? 1: 0,
							islistingorderby: data[0].islistingorderby ? 1: 0,
							isactive: data[0].isactive ? 1 : 0,
							tracker:data[0].tracker,
							fieldvalues: data[0].fieldvalues ? data[0].fieldvalues : null,
							field_dbvalue: data[0].field_dbvalue ? 1 : 0,
							lastmodifiedby: this.undt.username,
							useremail  : this.undt.username,
							empcode: this.undt.userid,
							kraheader:data[0].kraheader ? 1 : 0, 
							israting:data[0].israting ? 1 : 0,
							krafieldheader:data[0].krafieldheader ? data[0].krafieldheader : null,
							fieldheaderid : data[0].fieldheaderid ? data[0].fieldheaderid : null,
							department: data[0].department ? data[0].department : null,
							departmentid: data[0].departmentid ? data[0].departmentid : null,
							projectidarray : this.projectselectionlist,
							projectflag: data[0].projectflag,
							fieldnamekey: data[0].fieldnamekey
							},
						headers: { authorization :this.tokendata }
						
					}).then(result => {
						this.isLoading = false
						if(result.data.errorCode == 0) {
							Swal.fire({
								title: "Success!",
								text: result.data.msg,
								icon: 'success',
								customClass: {
									confirmButton: "btn btn-primary"
								},
								buttonsStyling: !1
							})
							// this.getAllFormConfigData(this.search);
							this.$router.push({ name: 'FormsConfigurationList', path:'/formsconfig/list' })
						} else {
							Swal.fire({
								title: "Failed",
								text: result.data.msg,
								icon: 'info',
								customClass: {
									confirmButton: "btn btn-primary"
								},
								buttonsStyling: !1
							})
						}
					}).catch(e => {
					this.displayError(e)
					})
				}
			},
			validateFormConfig(data) {
			let valid = true
			if(!this.formconfigure.formdata[0].title) {
				this.errors.formconfigure.formdata[0].title = `Title is required`
				valid = false
			} else {
				this.errors.formconfigure.formdata[0].title = ``
			}

			if(!this.formconfigure.formdata[0].titletype) {
				this.errors.formconfigure.formdata[0].titletype = `Input Type is required`
				valid = false
			} else {
				this.errors.formconfigure.formdata[0].titletype = ``
			}

			if(this.formconfigure.formdata[0].field_dbvalue==0 && (this.formconfigure.formdata[0].titletype=='radio' || this.formconfigure.formdata[0].titletype == 'checkbox' || this.formconfigure.formdata[0].titletype == 'drop-down' || this.formconfigure.formdata[0].titletype == 'multiselect-dropdown' )) {
				for(let i=0;i<this.formconfigure.formdata[0].fieldvalues.length;i++){
					if(!this.formconfigure.formdata[0].fieldvalues[i].optionvalue){
						valid = false
					}
				}
			}

			if (data[0] && data[0].projectflag == 0) {
          this.errors.formconfigure.formdata[0].validationtype = ``

					if (this.formconfigure.formdata[0].projectid == undefined || this.formconfigure.formdata[0].projectid.length == 0) {
            this.errors.formconfigure.formdata[0].project = 'Please Select project'
            valid = false
          } else {
            this.errors.formconfigure.formdata[0].project = ''
          }
       } else {
				if(!this.formconfigure.formdata[0].validationtype) {
					this.errors.formconfigure.formdata[0].validationtype = `Validation is required`
					valid = false
				} else {
					this.errors.formconfigure.formdata[0].validationtype = ``
				}
			 }
			if(this.formconfigure.formdata[0].tracker.length == 0) {
				this.errors.formconfigure.formdata[0].tracker = `Tracker is required`
				valid = false
			} else {
				this.errors.formconfigure.formdata[0].tracker = ``
			}
			return valid
		},

			selectval(state, idx) {
				this.formconfigure.formdata[idx].validationtype = null
				this.formconfigure.formdata[idx].validationtype = state
				if(!state) {
					this.errors.formconfigure.formdata[idx].validationtype = `Validation is required`
				} else {
					this.errors.formconfigure.formdata[idx].validationtype = ``
				}
			},
			selectInputs(state,idx){
				this.isLoading = true
				this.formconfigure.formdata[0].titletype = null
				this.formconfigure.formdata[0].titletype = state
				if(!state) {
					this.errors.formconfigure.formdata[0].titletype = `Input Type is required`
				} else {
					this.errors.formconfigure.formdata[0].titletype = ``
				}
				this.isLoading = false
			},
			selDepartment(state) {
			this.search.departmentid = null;
			this.search.departmentid =state;
				if (!state) {
					this.trackerlist = [];
					this.search.tracker = null;
					this.search.isactive = null;
					this.search.ispublish = null;
					this.search.isstandard =null;
					this.search.isexport =null;
				} else {
					this.getTrackerList(state);
				}
			},
			validText : function(inputval) {
				var re = /[A-Za-z0-9].{0,}/;
				if(inputval){
					return re.test(inputval.toLowerCase());
				}
			},
			validText2 : function(inputval) {
				var re = /[A-Za-z0-9].{0,}/;
				if(inputval){
					return re.test(inputval.toLowerCase());
				}
			},
			clearField(idx) {
				this.formconfigure.formdata[idx].projectid=null
				this.formconfigure.formdata[idx].validationtype=null
				this.formconfigure.formdata[idx].title = null
				this.formconfigure.formdata[idx].titletype = null
				this.formconfigure.formdata[idx].titlevalidation = null
				this.formconfigure.formdata[idx].tracker = null
				this.formconfigure.formdata[idx].optionvalue = [{
					optionvalue: '',
				}],
				this.formconfigure.formdata[idx].db_value = false,
				this.formconfigure.formdata[idx].ispublish = false,
				this.formconfigure.formdata[idx].isstandard = false,
				this.formconfigure.formdata[idx].isexport = false,
				this.formconfigure.formdata[idx].islisting = false,
				this.formconfigure.formdata[idx].iseditlisting = false,
				this.formconfigure.formdata[idx].islistingorderby = false,
				this.formconfigure.formdata[idx].fieldindex = null,
				this.formconfigure.formdata[idx].fieldheader = null,
				this.formconfigure.formdata[idx].fielderrormsg = null,
				this.formconfigure.formdata[idx].fieldplaceholder = null,
				this.formconfigure.formdata[idx].fieldcomment = false,
				this.formconfigure.formdata[idx].kraheader = false,
				this.formconfigure.formdata[idx].fieldheaderlist = [],
				this.formconfigure.formdata[idx].krafieldheader = null,
				this.formconfigure.formdata[idx].israting = false,

				
				this.errors.formconfigure.formdata[idx].title = ``
				this.errors.formconfigure.formdata[idx].titletype = ``
				this.errors.formconfigure.formdata[idx].titlevalidation = ``
				this.errors.formconfigure.formdata[idx].tracker = ``

			},

			getallprojectlist(node) {
				if (node && node.length>3) {
					this.isLoading = true;
					this.inputfield= {
						empcode: parseInt(this.undt.userid),
						useremail: this.undt.username,
						projectname: node.toLowerCase(),
						isfrompage:'updatepage'
					}
					axios({
						'method': 'POST',
						'url': 'api/project/getallprojectlist',
						'data': this.inputfield,
						'headers':{'authorization':this.tokendata}
					})
					.then(result => {
						this.isLoading = false;
						if(result.data.status == true){
							this.projectlist = result.data.data;
						}else if(result.data.errorCode == 3){
							Swal.fire({
								title: "Session Expired...!!",
								text: result.data.msg,
								icon: 'info',
								customClass: {
									confirmButton: "btn btn-primary",
								},
								buttonsStyling: !1,
							}).then(function() {
								window.location.href = "/#/login";
							})
						}else{
							this.projectlist = [];
						}
					}).catch(e => {
					this.displayError(e)
					});
				}
			},

			getallprojectarr(projectidarr) {
					this.isLoading = true;
					this.inputfield= {
						empcode: parseInt(this.undt.userid),
						useremail: this.undt.username,
						projectid:  projectidarr.split(',').map(item => item.trim()),
						isfrompage: 'isprojectid'
					}
					axios({
						'method': 'POST',
						'url': 'api/project/getallprojectlist',
						'data': this.inputfield,
						'headers':{'authorization':this.tokendata}
					})
					.then(result => {
						this.isLoading = false;
						if(result.data.status == true){
							this.projectlist = result.data.data;
						}else if(result.data.errorCode == 3){
							Swal.fire({
								title: "Session Expired...!!",
								text: result.data.msg,
								icon: 'info',
								customClass: {
									confirmButton: "btn btn-primary",
								},
								buttonsStyling: !1,
							}).then(function() {
								window.location.href = "/#/login";
							})
						}else{
							this.projectlist = [];
						}
					}).catch(e => {
					this.displayError(e)
					});
			},
			
			getprojectlist(id) {
				this.inputfield= {
					empcode: parseInt(this.undt.userid),
					useremail: this.undt.username,
					fieldid: parseInt(id)
				}
				axios({
					'method': 'POST',
					'url': 'api/formconfigure/getprojectlist',
					'data': this.inputfield,
					'headers':{'authorization':this.tokendata}
				})
				.then(result => {
					this.isLoading = false;
					if(result.data.status == true){
						this.projectselectionlist = result.data.data;
					}else if(result.data.errorCode == 3){
						Swal.fire({
							title: "Session Expired...!!",
							text: result.data.msg,
							icon: 'info',
							customClass: {
								confirmButton: "btn btn-primary",
							},
							buttonsStyling: !1,
						}).then(function() {
							window.location.href = "/#/login";
						})
					}else{
						this.projectlist = [];
					}
				}).catch(e => {
				this.displayError(e)
				});				
			},
			


			selectproject(selected, deselected) {
				this.getproject(selected);
				
			},
			getproject(projectid) {
				this.inputfield = {
					empcode: parseInt(this.undt.userid),
					useremail: this.undt.username,
					projectid: projectid,
				};

				axios({
					method: 'POST',
					url: 'api/project/getallprojectlist',
					data: this.inputfield,
					headers: { authorization: this.tokendata },
				})
				.then(result => {
					this.isLoading = false;

					if (result.data.status == true) {
						this.selpro = result.data.data;

						if (this.selpro) {
							
							if (projectid.length < this.projectselectionlist.length ) {
								// Check if id is not present in projectselectionlist then Remove that object 
								this.projectselectionlist = this.projectselectionlist.filter(item =>
										projectid.includes(item.id.toString())
								);
							} else {
								// Iterate through projectid array
								for (const id of projectid) {
										// Check if id is not present in projectselectionlist
										if (!this.projectselectionlist.some(item => item.id.toString() === id)) {
												// Add id as a new object in projectselectionlist
												for ( let i = 0; i < this.selpro.length; i++) {
													if (this.selpro[i].id == id) {
															this.projectselectionlist.push({
															"fieldid": 551, 
															"id": parseInt(id), 
															"validationtype": "required", 
															"label": this.selpro[i].label 
														});
													}
												}
										}
								}	
							}								
						}
					} else if (result.data.errorCode == 3) {
						Swal.fire({
							title: 'Session Expired...!!',
							text: result.data.msg,
							icon: 'info',
							customClass: {
								confirmButton: 'btn btn-primary',
							},
							buttonsStyling: !1,
						}).then(function() {
							window.location.href = '/#/login';
						});
					} else {
						this.projectselectionlist = [];
					}
				}).catch(e => {
				this.displayError(e)
				});
			},
			onfilterchange(project, index) {

				const existingIndex = this.projectselectionlist.findIndex(obj => obj.id === project.id);

				if (existingIndex !== -1) {
					this.projectselectionlist[existingIndex].validationtype = project.validationtype;
				} else {
					this.projectselectionlist.push(provlaid);
				}

				// if validation is undefined then assign value required
				for (const item of this.projectselectionlist) {
					if (item.validationtype === undefined) {
							item.validationtype = "required";
					}
				}

			},
			addDocumentFields(index) {
				this.isLoading = true
				if(!this.formconfigure.formdata[0].fieldvalues){
					this.formconfigure.formdata[0].fieldvalues = 
						[{
							optionvalue: '',
						}]
				} else {
					this.formconfigure.formdata[0].fieldvalues.push({
					optionvalue: null,
				})
				this.isLoading = false
			}

			},
			removeDocumentField(index, idx) {
				this.isLoading = true
				if(this.formconfigure.formdata[0].fieldvalues && this.formconfigure.formdata[0].fieldvalues.length>1) {
					this.formconfigure.formdata[0].fieldvalues.splice(idx,1);
      	}
				this.isLoading = false
    	},
			getFormConfigurebyid(id) {
				this.isLoading = true
				this.inputfield = {
					empcode: parseInt(this.undt.userid),
					useremail: this.undt.username,
					id: parseInt(id),
				};

				axios({
					method: 'POST',
					url: 'api/formconfigure/getFormConfigurebyid',
					data: this.inputfield,
					headers: { authorization: this.tokendata },
				}).then(result => {
					this.isLoading = false
					if(result.data.errorCode == 0) {
        			let getdata = result.data.data.rows[0]
						this.openEditModule(getdata)
					}
				}).catch(e => {
				this.displayError(e)
				})
			}
		}
	}

</script>