<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
       <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-2">TestCase</h3>
                            <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'tlist', params: { search: true }}">TestCase List</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Update TestCase</li>
                            </ol>
                            </div>
                        </div>
                    </div>
                <div v-if="pagefeatures.createtestcase" class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div>
                        <div class="card-body mobile-padding-0-10">
                            <form class="form form-vertical">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group mb-1">
                                            <label for="projectlistdd">Test Case Name</label>
                                            <input type="text" id="testcasename" class="form-control" name="" placeholder="Test Case Name" v-model="testcase.testcasename" @blur="validateForm('t39',$event)"
                                            v-bind:class="{ 
                                            'form-control': true,
                                            '': !validText(testcase.testcasename) && nameBlured, }"
                                            v-on:blur="nameBlured = true"/>
                                            <div class="errorinputmsg" v-if="!validText(testcase.testcasename) && nameBlured"> Test case name must be length of 5 char at least and only letters are allowed</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label for="projectmdllistdd">Project Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.project" :options="testcase.projectlist" @input="seltestcsproject" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.project">{{ this.errors.testcase.project }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label for="projectmdllistdd">Functionality</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.functionality" :options="testcase.functionalitylist" @input="seltestcsfunctionality" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.functionality">{{ this.errors.testcase.functionality }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label for="projectmdllistdd">Task Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.task" :options="testcase.tasklist"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label for="projectmdllistdd">Test Case Type</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.type" :options="testcase.typelist" @input="seltestcstype" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.type">{{ this.errors.testcase.type }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label for="projectmdllistdd">Test Case Severity</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.sevairty" :options="testcase.sevairtylist" @input="seltestcssevairty" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.sevairty">{{ this.errors.testcase.sevairty }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group ">
                                            <label for="projectmdllistdd">Test Case Priority</label>
                                            <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.priority" :options="testcase.prioritylist" @input="seltestcspriority" @close="validateForm('t39',$event)"/>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.testcase.priority">{{ this.errors.testcase.priority }}</div>
                                    </div>
                                    <div class="col-md-12" v-if="groupnamelist.length>0">
                                        <fieldset class="fieldset_border_vi mb-2">
                                            <legend>Script Presented Group</legend>
                                            <div class="card-body pt-1 px-0">
                                                <div class="mb-1 px-0">
                                                    <div class="table-responsive" style="overflow-x: inherit !important;">
                                                        <small v-for="(datas, index) in testcase.groupmappingcode" v-bind:key="index">
                                                            <small class="sitebluecolor" style="cursor: pointer;"
                                                            href="javascript:void(0);" v-html="(index+1)+'.'+datas +`&nbsp;`+`&nbsp;`" @click="redirecttourl('groupview',index)"></small>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </form>
                            <div class="col-md-12 px-0">
                            <div class="form-group">
                                <label for="lblattachment" class="d-block">Attachment</label>
                                <label for="attachment" class="btn btn-sm btn-relief-secondary mb-0 mr-75">Select image/docs/videos</label>
                                
                                <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                                <div class="table-responsive my-75" v-if="testcase.attachmentview">
                                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col">SR#</th>
                                                <th scope="col">Document Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, k) in testcase.attachmentview.data" :key="k">
                                                <td>{{k+1}}</td>
                                                <td>
                                                    <label>
                                                        <div class="click-zoom">
                                                            <input type="checkbox" />
                                                                <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                                                <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                                                <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                                                                <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                                                            &nbsp;
                                                            <a :href="data.presignurl">{{
                                                            data.link | basename
                                                            }}</a>
                                                        </div>
                                                    </label>
                                                </td>
                                                <td>{{data.info}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-sm danger" @click="removes3file(k,testcase.attachmentview.data)">
                                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.attachment">{{ this.errors.testcase.attachment }}</div>
                                    <div class="table-responsive my-75" v-if="file.length>0">
                                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(image, key) in file" :key="key">
                                                    <td>{{ key+1 }}</td>
                                                    <td>{{ image.name }}</td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="testcase.filedescp[key]"/>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button class="btn btn-sm btn-relief-secondary  mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                                </div>
                        </div>                                  
                        <div class="col-md-12 px-0">
                            <fieldset class="fieldset_border_vi mb-2 mx-0 fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                            <div class="container px-0"> 
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Country</label>
                                        <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Country" v-model="testcase.tcformgroups.country" :options="testcase.tcformgroups.countrylist" @input="getCountry" @close="validateForm($event)" v-if="tempclickeddate.backbtnstate=='countdata'" disabled/>
                                        <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Country" v-model="testcase.tcformgroups.country" :options="testcase.tcformgroups.countrylist" @input="getCountry" @close="validateForm($event)" v-else/>
                                        <div class="errorinputmsg" v-if="this.testcase.tcformgroups.country.length<=0">{{ this.testcase.tcformgroups.errors.country }}</div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Environment</label>
                                        <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Environment" v-model="testcase.tcformgroups.environment" :options="testcase.tcformgroups.environmentlist" @input="getEnv" @close="validateForm($event)" v-if="tempclickeddate.backbtnstate=='countdata'" disabled/>
                                        <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Environment" v-model="testcase.tcformgroups.environment" :options="testcase.tcformgroups.environmentlist" @input="getEnv" @close="validateForm($event)" v-else/>
                                        <div class="errorinputmsg" v-if="this.testcase.tcformgroups.environment.length<=0">{{ this.testcase.tcformgroups.errors.environment }}</div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Environment Language</label>
                                        <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Environment Language" v-model="testcase.tcformgroups.language" :options="testcase.tcformgroups.languagelist" @input="getLang" @close="validateForm($event)" v-if="tempclickeddate.backbtnstate=='countdata'" disabled/>
                                        <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Environment Language" v-model="testcase.tcformgroups.language" :options="testcase.tcformgroups.languagelist" @input="getLang" @close="validateForm($event)" v-else/>
                                        <div class="errorinputmsg" v-if="this.testcase.tcformgroups.language.length<=0">{{this.testcase.tcformgroups.errors.language}}</div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Script Type</label>
                                        <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Script Type" v-model="testcase.tcformgroups.scripttype" :options="testcase.tcformgroups.scripttypelist" @input="getScriptType" @close="validateForm($event)" v-if="tempclickeddate.backbtnstate=='countdata'" disabled/>
                                        <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Script Type" v-model="testcase.tcformgroups.scripttype" :options="testcase.tcformgroups.scripttypelist" @input="getScriptType" @close="validateForm($event)" v-else/>
                                        <div class="errorinputmsg" v-if="this.testcase.tcformgroups.scripttype.length<=0">{{ this.testcase.tcformgroups.errors.scripttype }}</div>
                                    </div>
                                </div>
                                
                                <div v-if="testcase.tcformgroups.scriptgrps.length>0" v-for="(rows, index) in testcase.tcformgroups.scriptgrps" class="col-md-12">
                                    <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                    <legend> 
                                        <span class="leg_mobile"> {{ rows.country }}</span> -<span class="leg_mobile"> {{ rows.environment }}</span> - <span class="leg_language"> {{ rows.language }} </span> - <span class="leg_automation"> {{ rows.scripttype }}</span>
                                    </legend>
                                    <div class="row">
                                        <div class="form-group col-sm-12">
                                            <label v-if="rows.scripttype != 'Manual'" class="form-label" for="projectmdllistdd">Test Case Script :</label>
                                            <label v-else class="form-label" for="projectmdllistdd">Test Case Steps : </label>
                                            <ckeditor :editor="editor" v-model="testcase.tcformgroups.scriptgrps[index].scriptsteps" tag-name="textarea" />
                                            <div class="errorinputmsg" v-if="(!testcase.tcformgroups.scriptgrps[index].scriptsteps)">
                                            <small v-if="rows.scripttype != 'Manual'">Test Case Script is required!</small>
                                            <small  v-else>Test Case Steps are required!</small>
                                        </div>
                                        </div>
                                    </div>
                                    </fieldset>
                                </div>

                                
                                <div v-if="testcase.tcformgroups.editscriptgrps.length>0" v-for="(editrows, index) in testcase.tcformgroups.editscriptgrps" class="col-md-12">
                                    <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                    <legend> 
                                        <span class="leg_mobile"> {{ editrows.country }}</span> - <span class="leg_mobile"> {{ editrows.environment }}</span> - <span class="leg_language"> {{ editrows.language }} </span> - <span class="leg_automation"> {{ editrows.scripttype }}</span>
                                    </legend>
                                    <div class="row">
                                        <div class="form-group col-sm-4">
                                            <div class="form-group mb-1">
                                                <label for="projectmdllistdd">Status</label>

                                                <input placeholder="Select Status" class="form-control capitalisetext" v-model="testcase.tcformgroups.editscriptgrps[index].status" :options="testcase.tcformgroups.editscriptgrps[index].tcscriptstatuslist" v-if="(testcase.tcformgroups.editscriptgrps[index].tcscriptstatuslist.length<=1) || (tempclickeddate.backbtnstate=='countdata' && tempclickeddate.countstatus!=testcase.tcformgroups.editscriptgrps[index].status) " disabled/>

                                                <treeselect placeholder="Select Status" :disabled="testcase.tcformgroups.editscriptgrps[index].statusdisable" class="projectmdllistdd capitalisetext" v-model="testcase.tcformgroups.editscriptgrps[index].status" :options="testcase.tcformgroups.editscriptgrps[index].tcscriptstatuslist" v-else/>
                                            <div class="errorinputmsg" v-if="!testcase.tcformgroups.editscriptgrps[index].status">Status is required!</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">               
                                        <div class="form-group col-sm-12">
                                            <label v-if="editrows.scripttype != 'Manual'" class="form-label" for="projectmdllistdd">Test Case Script :</label>
                                            <label v-else class="form-label" for="projectmdllistdd">Test Case Steps : </label>

                                            <ckeditor :editor="editor" v-model="testcase.tcformgroups.editscriptgrps[index].scriptsteps" tag-name="textarea" v-if="(testcase.tcformgroups.editscriptgrps[index].tcscriptstatuslist.length<=1) || (tempclickeddate.backbtnstate=='countdata' && tempclickeddate.countstatus!=testcase.tcformgroups.editscriptgrps[index].status)" disabled/>
                                            
                                            <ckeditor :editor="editor" v-model="testcase.tcformgroups.editscriptgrps[index].scriptsteps" tag-name="textarea" v-else/>

                                            <div class="errorinputmsg" v-if="(!testcase.tcformgroups.editscriptgrps[index].scriptsteps)">
                                            <small v-if="editrows.scripttype != 'Manual'">Test Case Script is required!</small>
                                            <small  v-else>Test Case Steps are required!</small>
                                        </div>                                        </div>
                                    </div>
                                    </fieldset>
                                </div>

                                </div>
                            </div>
                        </fieldset>
                        </div>                              
                        <div class="col-12 mg-top1 text-right">
                            <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t39',$event)" id="submitbtnt39" v-bind:disabled="testcase.disblesavebtn">Submit</button>
                            <router-link to='/testcasetask/list'>  <button type="cancel" class="btn btn-outline-secondary" @click="cancelForm()">Cancel</button> </router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>       
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}

</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import { EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,PackageIcon,MoreVerticalIcon,Edit2Icon,PlusIcon,PlusSquareIcon,Trash2Icon, MinusSquareIcon, ClipboardIcon } from 'vue-feather-icons'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMethods from '../../utils/commonMethods';


export default {
    name:'tupdate',
    mixins: [ commonMethods ],

    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
        Trash2Icon
    },    
    data() {
        return {
            imgExt:['jpeg','jpg','png'],
            s3bucket:apiUrl.s3url,
            file:[],
            isLoading: false,
            fullPage: true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            editRowData:[],
            glbMdl:'',
            glbMdlLbl:'testcase',
            TimeFormat: apiUrl.TimeFormat,
            getTCScriptList: [],
            testcase:{
                tcgroup: null,
                filedescp:[],
                testcasename:null,
                project:null,
                projectlist:null,
                functionality:null,
                functionalitylist:null,
                type:null,
                typelist:null,
                scenario:null,
                scenariolist:null,
                attachment:null,
                attachmentview:null,
                attachmentnew:null,
                sevairty:null,
                languagelist: apiUrl.languagelist.sort((a, b) => a.label.localeCompare(b.label)),
                environmentlist: apiUrl.environmentlist.sort((a, b) => a.label.localeCompare(b.label)),
                tcformgroups: {
                    language: [],
                    environment: [],
                    scripttype : [],
                    scriptsteps: [],
                    country: [],
                    status: null,
                    languagelist: apiUrl.languagelist.sort((a, b) => a.label.localeCompare(b.label)),
                    environmentlist: apiUrl.environmentlist.sort((a, b) => a.label.localeCompare(b.label)),
                    scripttypelist: apiUrl.scripttypelist.sort((a, b) => a.label.localeCompare(b.label)),
                    countrylist: apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
                    scriptgrps: [],
                    editscriptgrps: [],
                    errors: {
                        language: null,
                        environment: null,
                        scripttype : null,
                        status:null,
                        country: null,
                    }
                },
                sevairtylist:null,
                priority:null,
                prioritylist:[{
                    id:'high',
                    label:'High'
                },{
                    id:'low',
                    label:'Low'
                },{
                    id:'medium',
                    label:'Medium'
                }],
                status:null,
                statuslist: apiUrl.testcaseStatusList,
                iseditstate:false,
                disblesavebtn:false,
                task: null,
                tasklist: [],
                testcasenameopts: null,
                groupmappingcode: [],
                groupmappingid: [],
            },
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            modulelist:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                testcase:{
                    tcgroup:null,
                    testcasename:null,
                    testcasenameopts:null,
                    project:null,
                    functionality:null,
                    type:null,
                    scenario:null,
                    attachment:null,
                    sevairty:null,
                    priority:null,
                    status:null
                }
            },
            isDetActive:true,
            isRequestActive:false,
            isedit: false,
            card:'card',
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|', 'bold',
                        'italic', '|', 'bulletedList',
                        'numberedList', '|', 'insertTable', '|',
                        'undo', 'redo', '|',
                    ],
                },
            },
            search:{
                searchprojectname :null,
                projectnameopts :null,
                searchfunctionalityname:null,
                functionalitylist:null,
                searchtestcasename:null,
                searchtaskname:null,
                tasklist:null,
                searchtypename:null,
                typenameopts:null,
                searchseverityname:null,
                severitynameopts:null,
                searchpriorityname:null,
                prioritynameopts:null,
                searchstatus:null,
                statusopts:null
            },
            projectnameList:[],
            testcasenameList:[],
            tokendata:null,
            view:{
                projectname: null,
                functionlityname: null
            },
            nameBlured: false,
            accepttype:apiUrl.uploadfiletype,
            prevEnvArr: [],
            prevLangArr: [],
            prevSTypeArr: [],
            prevSCountryArr: [],
            statusConfigList: [],
            validFileExtensions:apiUrl.validFileExtensions,
            groupnamelist: [],
            getids: [],
            tempclickeddate: []
        }
    },
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/testcase') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
        }
        if (this.$route.params.backbtnstate=='countdata') {
            this.gettastcasebyid(this.$route.params.clickeddata.testcaseid)
            this.tempclickeddate = this.$route.params.clickeddata
        }else if(Object.values(this.$route.query).length==0){
            this.$router.push({ name: 'tlist', params: { search: true }})
        }else{
           this.gettastcasebyid(this.$route.query.testcaseid)
        }
        this.getProjectname();
        // this.getApplicationdata('t11','testcase')
        this.getApplicationdata('t40','testcase')
        this.getApplicationdata('t41','testcase')
    },
    methods:{
        getScriptType(state) {
            this.testcase.tcformgroups.scripttype = [];
            this.testcase.tcformgroups.scripttype = state;
            if(this.testcase.tcformgroups.scripttype.length < this.prevSTypeArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.scripttype.includes(ele.scripttype)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].scripttype === ele.scripttype) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.scripttype = 'Script Type is required'
            }else{
                this.addItems();
            }
        },
        getCountry(state) {
            this.testcase.tcformgroups.country = [];
            this.testcase.tcformgroups.country = state;
            if(this.testcase.tcformgroups.country.length < this.prevSCountryArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.country.includes(ele.country)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].country === ele.country) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.country = 'Country is required'
            }else{
                this.addItems();
            }
        },
        getEnv(state) {
            this.testcase.tcformgroups.environment = [];
            this.testcase.tcformgroups.environment = state;

            if(this.testcase.tcformgroups.environment.length < this.prevEnvArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.environment.includes(ele.environment)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].environment === ele.environment) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.environment = 'Environment is required'
            }else{
                this.addItems();
            }
        },
        getLang(state) {
            this.testcase.tcformgroups.language = [];
            this.testcase.tcformgroups.language = state;
            if(this.testcase.tcformgroups.language.length < this.prevLangArr.length){
                this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                    if(!this.testcase.tcformgroups.language.includes(ele.language)){
                        for(let j= this.testcase.tcformgroups.editscriptgrps.length - 1; j >= 0; --j) {
                            if (this.testcase.tcformgroups.editscriptgrps[j].language === ele.language) {
                                this.testcase.tcformgroups.editscriptgrps.splice(j,1);
                            }
                        }
                    }
                });
            }
            if(!state) {
                this.testcase.tcformgroups.errors.language = 'Language is required'
            }else{
                this.addItems();
            }
        },
        detectCombinations(input, output, position, path) {
            if (position == null) {
                position = 0;
            }
            if (path == null) {
                path = [];
            }
            if (position < input.length) {
                let item = input[position];
                for (let i = 0; i < item.length; ++i) {
                    let value = item[i];
                    path.push(value);
                    this.detectCombinations(input, output, position + 1, path);
                    path.pop();
                }
            } else {
                output.push(path.slice());
            }
            return output;
        },
        addItems() {
            let output = [];
            let input = [];
            this.testcase.tcformgroups.scriptgrps = [];
            input.push(this.testcase.tcformgroups.environment)
            input.push(this.testcase.tcformgroups.language)
            input.push(this.testcase.tcformgroups.scripttype)
            input.push(this.testcase.tcformgroups.country)
            let x = this.detectCombinations(input, output);
            x.forEach(element => {
                this.testcase.tcformgroups.scriptgrps.push({
                    environment: element[0],
                    language: element[1],
                    scripttype: element[2],
                    country: element[3],
                    scriptsteps: null,
                    status: 'Created',
                    labeltext: element[3]+"-"+element[0]+"-"+element[1]+"-"+element[2],
                });
            });

            
            this.testcase.tcformgroups.editscriptgrps.forEach((ele, ii) => {
                const objWithIdIndex = this.testcase.tcformgroups.scriptgrps.findIndex((obj) => obj.labeltext === ele.labeltext);
                if (objWithIdIndex > -1) {
                    this.testcase.tcformgroups.scriptgrps.splice(objWithIdIndex, 1);
                }                
            });
        },
        mydetailscollapse() {
            this.isDetActive = !this.isDetActive
        },
        scenario() {
             this.scenario.replace(new RegExp('<[^>]*>', 'g'), '')
          
        },
        onFileChange(e) {
            var validFileExtensions = apiUrl.validFileExtensions
            let selectedFiles = e.target.files;
            for (let i=0; i < selectedFiles.length; i++)
            {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];
                if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
                
                if (!blnValid) {
                    Swal.fire({
                        title: "ERROR",
                        text: "Sorry, Invalid Type of Extension File..!!",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return false;
                }
                this.file.push(selectedFiles[i]);
            }
            if(this.file.length>0) {
                this.testcase.disblesavebtn=true
            }
        },
         gettastcasebyid(moduleid){
         this.isLoading = true;
            let url = "api/testcase/getTestcasebyid";
            this.input = {
                testcaseid:moduleid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0]
                    this.openEditModule(this.editRowData, 0)
                } else {
                    this.editRowData = [];
                    this.$router.push({ name: 'tlist', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to update this test case",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            });
        },
        myrequestcollapse() {
            this.isRequestActive = !this.isRequestActive
            this.testcase.testcasename=null
            this.testcase.project=null
            this.testcase.functionality=null
            this.testcase.type=null
            this.testcase.scenario=''
            this.testcase.sevairty=null
            this.testcase.priority=null
            this.testcase.status=null
        },
        mysearchrequestollapse:function(){
            this.myrequestcollapse()
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        removes3file(key,data) {
            if(data.length>0) {                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to delete this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        if(this.editRowData.testcaseid !== undefined) {
                            let deletefile = data[key];
                            data.splice(key, 1);
                            const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                            let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                            axios({
                            method: "POST",
                            url: 'api/testcase/s3deletetestcase',
                            data: {
                                testcaseid:this.editRowData.testcaseid,
                                attachment:payload,
                                deletefile: JSON.stringify(deletefile),
                                useremail: this.undt.username,
                                empcode: this.undt.userid,
                            },
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            if (result.data.status) {
                                Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'success'
                                )
                            } else {
                            Swal.fire(
                                'Deleted!',
                                result.data.msg,
                                'error'
                                )                        
                            }
                        });
                        } else {
                            Swal.fire(
                                'TestCaseId!',
                                'TestCaseId should not blank',
                                'info'
                                )
                        }    
                    }
                })
            }
        },
        resetForm() {
            this.testcase.testcasename = null 
            this.testcase.project = null
            this.testcase.functionality = null
            this.testcase.type = null
            this.testcase.scenario = ''
            this.testcase.sevairty = null
            this.testcase.priority = null
            this.testcase.status = null
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            this.testcase.tcformgroups.environment = [];
            this.testcase.tcformgroups.language = [];
            this.testcase.tcformgroups.scripttype = [];
            this.testcase.tcformgroups.country = [];
            this.testcase.tcformgroups.errors.scripttype = null;
            this.testcase.tcformgroups.errors.language = null;
            this.testcase.tcformgroups.errors.environment = null;
            this.testcase.tcformgroups.errors.country = null;
            this.clearerrorForm()
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                
            }
            this.gettestcaselist()
        },
        savewizard(mdl,objThis){   
            this.valid = this.validateForm(mdl,objThis);
            if (this.valid) {
                this.isLoading = true;
                let tblNm='t39'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'testcase',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/testcase/update'
                this.input.testcasename=this.testcase.testcasename
                this.input.project=this.testcase.project
                this.input.functionality=this.testcase.functionality
                this.input.task = this.testcase.task
                this.input.type=this.testcase.type
                this.input.attachment=this.testcase.attachmentnew
                this.input.sevairty=this.testcase.sevairty
                this.input.priority=this.testcase.priority
                this.input.scriptgrps = this.testcase.tcformgroups.scriptgrps;
                this.input.editscriptgrps = this.testcase.tcformgroups.editscriptgrps;
                this.input.scriptsstatus = this.testcase.tcformgroups.status;
                this.input.module_code = this.editRowData.testcasecode
                if(this.testcase.iseditstate == true){
                    this.input.testcaseid=this.editRowData.testcaseid
                    if(this.testcase.attachmentnew && this.testcase.attachment !== null) {
                        let jsonobj1 = JSON.parse(this.testcase.attachmentnew);
                        let jsonobj2 = this.testcase.attachment;
                        let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                        this.input.attachment= JSON.stringify({data: arraymerge});
                    }
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {     
                    this.isLoading = false;        
                    if(result.data.errorCode == 0){
                        this.isRequestActive = false
                        this.isDetActive = true
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.editRowData=this.issue
                        this.$router.push({ name: 'tlist', params: { search: true }});
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        gettestcaselist(){
            this.isLoading=true
            let setmoduleform='testcasemaster'
            let glbMdlLblval='Test Case Master'
            this.companymasterlist = []
            let tblNm='t39'
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                this.glbMdlLbl = glbMdlLblval;
            }
            var apiURL = 'api/listing/gettestcaselist';
            this.input = {
                tbl:'t39',
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                projectname: this.search.searchprojectname,
                functionlityname: this.search.searchfunctionalityname,
                testcasename: this.search.searchtestcasename,
                testcasetypename: this.search.searchtypename,
                sevairty: this.search.searchseverityname,
                priority:this.search.searchpriorityname,
                status:this.search.searchstatus,
                empid: this.undt.userid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            // this.getApplicationdata('t11','testcase')
            this.getApplicationdata('t40','testcase')
            this.getApplicationdata('t41','testcase')
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false;          
                this.showproject=true;   
                if(result.data.errorCode == 0){
                    this.isDetActive=true;   
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }else{
                    this.isDetActive=true;   
                    this.companymasterlist=[];
                    this.totalcount = 0
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'updatepage'
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                } else {
                    this.projectnameList = [];
                }
                }).catch(e => {
                    this.displayError(e)
                });
            } 
        },
        getProjectname:function(){
            this.apiURL="api/searching/getAssignedProjectList"
            this.input={
                projectname:'',
                useremail: this.undt.username,
                empid :this.undt.userid,
                empcode: this.undt.userid,
                isfrompage: 'updatepage'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.errorCode == 0){
                   this.testcase.projectlist = result.data.data;
                   if(this.testcase.projectlist && this.testcase.projectlist.length > 0 && (this.testcase.project && this.testcase.project !=null)){
                        const checkexistproject = this.testcase.projectlist.some(item =>item.id === this.testcase.project);
                        if(!checkexistproject){
                            this.getProjectnamebyid(this.testcase.project);
                        }
                    }else if(this.testcase.projectlist.length ==0 && (this.testcase.project && this.testcase.project !=null)){
                        this.getProjectnamebyid(this.testcase.project);
                    }
                }
                else{
                    this.testcase.projectlist=[]
                }

            }).catch(e => {
                this.displayError(e)
            })
        },
        getProjectnamebyid(pid){
            this.isLoading = true;
            this.apiURL="api/searching/getAssignedProjectList"
            this.input={
                useremail: this.undt.username,
                empid :this.undt.userid,
                empcode: this.undt.userid,
                projectid: pid,
                isfrompage: 'isprojectid'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    if(result.data.data && result.data.data.length > 0){
                        this.testcase.projectlist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        testcaseChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/searching/getTestcaselist";
                this.input = {
                testcasename:node.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.testcasenameList = result.data.data;
                } else {
                    this.testcasenameList = [];
                }
                }).catch(e => {
                this.displayError(e)
            });
            } 
        },
        resetRecords: function() {
            this.search.searchprojectname = null;
            this.search.searchfunctionalityname = null;
            this.search.searchtestcasename= null;
            this.search.searchtaskname = null;
            this.search.searchtypename = null;
            this.search.searchseverityname = null;
            this.search.searchpriorityname = null;
            this.search.searchstatus = null;
            this.gettestcaselist();
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validateForm(mdl,objThis){
            this.clearerrorForm();
            this.isAddState=false
            var isValid=true;
            this.nameBlured = true;
            if(mdl=="t39"){
                if(!this.testcase.testcasename){
                    this.errors.testcase.testcasename="Test case name required";
                    isValid= false;
                }
                if(!this.testcase.project){
                    this.errors.testcase.project="Project required";
                    isValid= false;
                }
                if(!this.testcase.type){
                    this.errors.testcase.type="Test case type required";
                    isValid= false;
                }
                if(!this.testcase.sevairty){
                    this.errors.testcase.sevairty="Test case severity required";
                    isValid= false;
                }
                if(!this.testcase.priority){
                    this.errors.testcase.priority="Test case priority required";
                    isValid= false;
                }
               
                if(this.testcase.tcformgroups.environment.length<=0) {
                    this.testcase.tcformgroups.errors.environment = "Environment is required."
                    isValid = false;
                }
                
                if(this.testcase.tcformgroups.country.length<=0) {
                    this.testcase.tcformgroups.errors.country = "Country is required."
                    isValid = false;
                }

                if(this.testcase.tcformgroups.language.length<=0) {
                    this.testcase.tcformgroups.errors.language = "Language is required."
                    isValid = false;
                }

                if(this.testcase.tcformgroups.scripttype.length<=0) {
                    this.testcase.tcformgroups.errors.scripttype = "Script Type is required."
                    isValid = false;
                }
                
                
                this.testcase.tcformgroups.scriptgrps.forEach(element => {
                    if(element.scriptsteps == null || element.scriptsteps == '') {
                        isValid= false;
                    }
                });
               
                if(this.testcase.tcformgroups.editscriptgrps.length>0) {
                    this.testcase.tcformgroups.editscriptgrps.forEach(element => {
                        if(element.scriptsteps == null || element.scriptsteps == '') {
                            isValid= false;
                        }
                    });
                }
            }
            return isValid
        },
        clearerrorForm(){
            this.errors={
                testcase:{
                    testcasename:null,
                    project:null,
                    functionality:null,
                    type:null,
                    scenario:'',
                    attachment:null,
                    sevairty:null,
                    priority:null,
                    language:null,
                    environment:null,
                    status:null
                }
            }
        },
        openEditModule(currRowData, flag){
            this.view.projectname=currRowData.projectname
            this.view.functionlityname=currRowData.functionlityname
            this.editRowData = currRowData
            this.testcase.testcasename = currRowData.testcasename
            this.testcase.project = currRowData.project
            this.testcase.functionality = currRowData.functionality
            this.testcase.task = currRowData.taskid
            this.testcase.type = currRowData.issuetype
            this.testcase.scenario = currRowData.scenario
            this.testcase.attachment = currRowData.attachment
            
            if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
                this.testcase.attachmentview =[];
                this.getDownloadLink(currRowData.attachment);
            }
            this.getStatusConfigList(this.testcase.project);
            this.getTCScriptDetails(currRowData.testcaseid);
            this.testcase.sevairty = currRowData.sevairty
            this.testcase.priority = currRowData.priority
            this.testcase.status = currRowData.status
            this.testcase.iseditstate = true
            this.isAddState=true
            if(flag==0){
                this.isedit=true
                this.isRequestActive=true
                this.mydetailscollapse()
            }
            if(this.testcase.projectlist && this.testcase.projectlist.length > 0 && (this.testcase.project && this.testcase.project !=null)){
                const checkexist = this.testcase.projectlist.some(item =>item.id === this.testcase.project);
                if(!checkexist){
                    this.getProjectnamebyid(this.testcase.project);
                }
            }else if(this.testcase.projectlist.length ==0 && (this.testcase.project && this.testcase.project !=null)){
                this.getProjectnamebyid(this.testcase.project);
            }
        },
        getStatusConfigList(pid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: pid,
                trackername: 'Testcase',
            };
            axios({
                method: "POST",
                url: '/api/testcase/getStatusConfigListFORTC',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    this.statusConfigList = result.data.data.rows;
                    this.statusConfigList.sort((a, b) => {
                return a.rolename.localeCompare(b.rolename);
            });
                } else {
                    this.statusConfigList = [];
                }
            }).catch(e => {
        this.displayError(e)
      });
        },
        getTCScriptDetails(tcid) {
            this.input = {
                empid: this.undt.userid,
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                tcid: tcid,
            }
            axios({
                'method': 'POST',
                'url': 'api/testcase/getTCScriptDetails',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.getTCScriptList = result.data.data.rows;
                    this.getTCScriptList.forEach((element) => {
                        this.getids.push(element.id)
                        this.testcase.tcformgroups.status  = element.status;
                        if(!this.prevEnvArr.includes(element.enviornment)){
                            this.prevEnvArr.push(element.enviornment)
                        }
                        if(!this.prevLangArr.includes(element.language)){
                            this.prevLangArr.push(element.language)
                        }
                        if(!this.prevSTypeArr.includes(element.scripttype)){
                            this.prevSTypeArr.push(element.scripttype)
                        }
                        if(!this.prevSCountryArr.includes(element.country)){
                            this.prevSCountryArr.push(element.country)
                        }

                        let slist = [];
                        let sflag = false;
                        if(!slist.some(sdata => sdata.id === element.status)){
                            slist.push({ id: element.status, label: element.status });
                        }

                        if(this.statusConfigList.length>0){
                            this.statusConfigList.forEach((rec) => {
                                if(Object.values(rec.statusconfig).length>0){
                                    Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                        if(skey===element.status){
                                            if(statusnames.length>0) {
                                                statusnames.forEach(val => {
                                                    if(!slist.some(sdata => sdata.id === val)){
                                                        slist.push({ id: val, label: val });
                                                    }
                                                });
                                            }
                                        } 
                                    });
                                }
                            });
                        }

                        this.testcase.tcformgroups.editscriptgrps.push({
                            environment: element.enviornment,
                            language: element.language,
                            scripttype: element.scripttype,
                            scriptsteps: element.scriptsteps,
                            country: element.country,
                            status: element.status,
                            labeltext: element.country+"-"+element.enviornment+"-"+element.language+"-"+element.scripttype,
                            id: element.id,
                            tcscriptcode: element.tcscriptcode,
                            tcscriptstatuslist: slist,
                            statusdisable: sflag,
                        });
                    });
                    if (this.getids.length>0) {
                        this.getgroupsfortestcase(this.getids); 
                    }
                    
                    this.testcase.tcformgroups.language = this.prevLangArr;
                    this.testcase.tcformgroups.environment = this.prevEnvArr;
                    this.testcase.tcformgroups.scripttype  = this.prevSTypeArr;
                    this.testcase.tcformgroups.country  = this.prevSCountryArr;
                } else {
                    this.getTCScriptList = []
                }
            }).catch(e => {
        this.displayError(e)
      })
        },
        getDownloadLink(data) {
            if(data.data.length>0) {
                let payload = {
                documents:data.data,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                };
            
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                if(result.data.status) {
                    this.testcase.attachmentview = result.data;
                }
                });
            }
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        addmodule(){
            this.testcase.testcasename = null
            this.testcase.project = null
            this.testcase.functionality = null
            this.testcase.task = null
            this.testcase.type = null
            this.testcase.scenario = ''
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            this.testcase.sevairty = null
            this.testcase.priority = null
            this.testcase.status = null
            this.testcase.iseditstate = false
            this.testcase.disblesavebtn=true
        },
        seltestcsproject(state,value){
            this.testcase.project=null
            this.testcase.project=state
            if(!state){
                this.testcase.functionality=null
                this.testcase.task = null
                this.testcase.functionalitylist = null
                this.testcase.tasklist = null
                
            }else{
                this.getApplicationdata('t37','testcase')
								this.getTestcasetypeConfigLists(state)
                if (state != this.editRowData.projectid) {
                    this.testcase.type = null	
                }
            }
						
        },
        seltestcsfunctionality(state,value){
            this.testcase.functionality=null
            this.testcase.functionality=state
            if(!state){
                this.testcase.task = null
                this.testcase.tasklist = null
                
            }else{                
                this.getApplicationdata('t18', 'testcase')
            }
        },
        seltestcsprojectsearch(state,value){
            this.search.searchprojectname = null
            this.search.searchprojectname = state
            if(!state){
                this.search.searchfunctionalityname=null
                this.search.searchtaskname=null
                this.search.searchtaskname = null
                this.search.tasklist = null
                this.errors.testcase.project='Project required'
                
            }else{
                this.getApplicationdata('t37','testcaseSearch')
            }
        },     
        seltestcsfunctionalitysearch(state,value){
            this.search.searchfunctionalityname=null
            this.search.searchfunctionalityname=state
            if(!state){
                this.search.searchtaskname = null
                this.search.tasklist = null
            }else{                
                this.getApplicationdata('t18', 'testcaseSearch')
            }
        },
        seltestcstype(state,value){
            this.testcase.type=null
            this.testcase.type=state
            if(!state && this.isAddState == false){
                this.errors.testcase.type='Type required'
            }else{                
                this.errors.testcase.type=null
            }
        },
        seltestcsscenario(state,value){
            this.testcase.scenario=null
            this.testcase.scenario=state
            if(!state && this.isAddState == false){
                this.errors.testcase.scenario='Scenario required'
            }else{                
                this.errors.testcase.scenario=''
            }
        },
        seltestcssevairty(state,value){
            this.testcase.sevairty=null
            this.testcase.sevairty=state
            if(!state && this.isAddState == false){
                this.errors.testcase.sevairty='Severity required'
            }else{                
                this.errors.testcase.sevairty=null
            }
        },
        seltestcspriority(state,value){
            this.testcase.priority=null
            this.testcase.priority=state
            if(!state && this.isAddState == false){
                this.errors.testcase.priority='Priority required'
            }else{                
                this.errors.testcase.priority=null
            }
        },
        seltestcsstatus(state,value){
            this.testcase.status=null
            this.testcase.status=state
            if(!state && this.isAddState == false){
                this.errors.testcase.status='Status required'
            }else{                
                this.errors.testcase.status=null
            }
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,empcode: this.undt.userid,
            }
            let apiURL='api/master/commonmaster/list'
            if(mdl == 't11'){
                apiURL='api/listing/getProjectAllocatedToEmp'
                this.inputappl = {
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    isfrompage: 'updatepage'
                }
            }else if(mdl == 't37' && curracttab == 'testcase'){                
                this.inputappl = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.testcase.project
            }else if(mdl == 't37' && curracttab == 'testcaseSearch'){                
                this.inputappl = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.search.searchprojectname
            }else if(mdl == 't40'){
                apiURL='api/master/commonmaster/customselect'
                
                this.inputappl.isSearch= false
                this.inputappl.section= "testcase"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            }else if(mdl == 't41'){
                apiURL='api/master/commonmaster/customselect'
                
                this.inputappl.isSearch= false
                this.inputappl.section= "testcase"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            } else if (mdl == 't18' && curracttab == 'testcase') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.testcase.functionality
                }
            } else if (mdl == 't18' && curracttab == 'testcaseSearch') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.search.searchfunctionalityname
                }
            } 
            this.inputappl.useremail= this.undt.username
            this.inputappl.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                if(result.data.errorCode == 0){
                    if(mdl == 't11'){
                        if(curracttab == 'testcase'){
                            this.testcase.projectlist=result.data.data;
                            if(this.testcase.projectlist && this.testcase.projectlist.length > 0 && (this.testcase.project && this.testcase.project !=null)){
                                const checkexistp = this.testcase.projectlist.some(item =>item.id === this.testcase.project);
                                if(!checkexistp){
                                    this.getProjectnamebyid(this.testcase.project);
                                }
                            }else if(this.testcase.projectlist.length ==0 && (this.testcase.project && this.testcase.project !=null)){
                                this.getProjectnamebyid(this.testcase.project);
                            }
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcase'){
                        if(curracttab == 'testcase'){
                            this.testcase.functionalitylist=result.data.data
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcaseSearch'){
                        this.search.functionalitylist=result.data.data
                        return true
                    } 
										// else if(mdl == 't40'){
                    //     if(curracttab == 'testcase'){
                    //         this.testcase.typelist=result.data.data.rows
                    //         return true
                    //     }
                    // }
										else if(mdl == 't41'){
                        if(curracttab == 'testcase'){
                            this.testcase.sevairtylist=result.data.data.rows
                            return true
                        }
                    } else if(mdl == 't18' && curracttab == 'testcase') {
                            this.testcase.tasklist = result.data.data
                            return true
                    } else if(mdl == 't18' && curracttab == 'testcaseSearch') {
                        this.search.tasklist = result.data.data
                        return true
                    } 
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
            this.displayError(e)
        })
            
        },
        getgroupsfortestcase(ids){ 
            this.isLoading=true 
            this.input={ 
                useremail: this.undt.username, 
                empcode: this.undt.userid, 
                getids: ids 
            } 
            axios({ 
                'method': 'POST', 
                'url': 'api/testcase/getgroupsfortestcase', 
                'data': this.input, 
                'headers':{'authorization':this.tokendata} })
            .then(result => { 
                this.isLoading=false 
                if(result.data.errorCode == 0){ 
                    this.groupnamelist = result.data.data.rows; 
                    for (let j = 0; j < this.groupnamelist.length; j++) { 
                        this.testcase.groupmappingcode.push(this.groupnamelist[j].groupmappingcode)
                        this.testcase.groupmappingid.push(this.groupnamelist[j].groupmappingid)
                    } 
                }else { 
                    this.groupnamelist = [] 
                } 
            }) 
        },
        redirecttourl:function(btnstate,idx){
            let rowdata = []
            rowdata.id = this.testcase.groupmappingid[idx]
            rowdata.backbtnstate = btnstate
            if(btnstate=="groupview"){
                let routeData = this.$router.resolve({ 
                    path: '/testcase/groupbulkView/?groupmappingid='+rowdata.id,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }
        },
        onSelect(e){
            this.file = e.target.files[0];
        },
        removeImage (index) {
            if(this.file.length>0) {
                this.file.splice(index, 1);
                this.testcase.filedescp.splice(index, 1);
                var removeimg = JSON.parse(this.testcase.attachmentnew)
                if(removeimg){
                    var remove = removeimg.data.splice(index,1)
                    if(remove.length > 0)
                    this.testcase.attachmentnew = JSON.stringify({data:removeimg.data})
                    else {
                    this.testcase.attachmentnew = null
                  }
                }
            }   
            if(this.file.length < 1) {
                this.testcase.disblesavebtn = false
            }      
        },
        onUpload(){
            this.isLoading=true
            if(this.file){
                if(this.file.length!=0){
                    let arrGlbMdl=this.glbMdl.split('##')
                    let inputEle='';
                    let btnEle='';
                    if(arrGlbMdl.length > 0){
                        if(typeof arrGlbMdl[0] != 'undefined'){
                            inputEle=arrGlbMdl[0];
                        }
                        if(typeof arrGlbMdl[1] != 'undefined'){
                            btnEle=arrGlbMdl[1];
                        }
                    }
                    if(inputEle == 't39'){
                        btnEle='testcase'
                    }
                    this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                    let formData = new FormData();
                    let maxsize = 0;
                    for (let index = 0; index < this.file.length; index++) {
                        formData.append("imagefile", this.file[index]);
                        maxsize+= this.file[index].size
                    }
                    formData.append("empcode", this.undt.userid);
                    formData.append("useremail", this.undt.username);
                    formData.append("moduletype", 'testcase');
                    formData.append("fcount", parseInt(this.file.length));
                    if (maxsize > 1000 * 1000 * 25) {
                        this.isLoading = false;
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 25mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        this.testcase.disblesavebtn = true
                        return false; 
                    }
                    axios({
                        'content-type':'multipart/form-data',
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':formData,
                        'headers':{'authorization':this.tokendata}
                    })
                    .then(result => { 
                        this.isLoading=false;            
                        if(result.data.status){
                            this.testcase.disblesavebtn=false
                            
                            const bindinfo = result.data.data.map((file, index) => {
                                return {"link":file,"info":this.testcase.filedescp[index]}                
                            });
                            let jsonobj = {data:bindinfo}
                            this.testcase.attachmentnew = JSON.stringify(jsonobj);
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    }).catch(e => {
                        this.displayError(e)
                    })
                }else{
                     this.isLoading=false; 
                    Swal.fire({
                        title: "",
                        text: 'Select image/docs/videos',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.testcase.attachment=null
                }
            }else{
                    this.isLoading=false; 
                    Swal.fire({
                        title: "",
                        text: 'Select image/docs/videos',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.testcase.attachment=null
                }
            
        
        },
				getTestcasetypeConfigLists(projectid) {
				let payload = {
					empcode: this.undt.userid,
					useremail: this.undt.username,
					projectid: parseInt(projectid.toString()),
					trackername: 'Testcase Type',
				};
				axios({
						method: "POST",
						url: '/api/businesstask/getTasktypeConfigList',
						data: payload,
						'headers':{'authorization':this.tokendata}
				}).then((result) => {
						if(result.data.errorCode == 0){
								let slist = [];
								result.data.data.rows.forEach((rec) => {
										if(Object.values(rec.statusconfig).length>0){
												Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
														if(statusnames.length>0) {
																statusnames.forEach(val => {
																		if(!slist.some(sdata => sdata.id === val)){
																				slist.push({ id: val, label: val });
																		}
																});
														}
												});
										}
								})
								this.sortTypeList(slist, 'alltype')
						} else {
								this.testcase.typelist = [];
						}
				}).catch(e => {
                this.displayError(e)
            });
      },
			sortTypeList(typelist, type){
        let numArrFromTypelist = [];
        typelist.forEach(element => {
					if(element.id != null) {
						numArrFromTypelist.push(element.id);
					}
        });
        if(numArrFromTypelist.length>0) {
					this.input = {
						useremail: this.undt.username,
						empcode: this.undt.userid,
						moduletype: 'testcase',
						typearr: numArrFromTypelist,
						projecttype: type
					};
					axios({
						method: "POST",
						url: 'api/ticket/sortTypeList',
						data: this.input,
						'headers':{'authorization':this.tokendata}
					}).then((result) => {
						this.isLoading = false;
						if (result.data.errorCode == 0) {
							this.testcase.typelist = result.data.data.rows;
                            this.testcase.typelist.sort((a, b) => {
                              return a.label.localeCompare(b.label);
                            });
						} else {
							this.testcase.typelist = [];
						}
					}).catch(e => {
                    this.displayError(e)
                });
        }
      },
    },
    filters: {
        basename: function (value) {
            if (!value) return ''
            return value.substr(value.lastIndexOf("/") + 1)
        }
    }
}
</script>
