<template>
  <div class="app-content content ">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="orange" loader="dots" :width="100"
      :height="100"></loading>
      <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>

    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0">
                <h3 class="float-left mb-0"><span class="text-capitalize">Bulk Business Task Assignment</span></h3>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst"
          style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="col-auto mobile_margin-px-0_00">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                      <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                    </div>
                  </div>
                  <h4 class="card-title d-inline-flex ml-1">Search Business Details</h4>
                </div>
                <span class="ml-auto rotetions_view_arrow ml-auto mx-1">
                  <em class="las la-angle-down"></em>
                </span>
              </div>
            </div>
          </div>
          <div v-bind:class="[{ show: isSearchBoxActive }, card_content, collapse]">
            <div class="card-body pt-1 px-sm-mobile-10">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" :multiple="true"
                    v-model="search.project" :options="search.projectList" @input="nullifyListing" :clear-on-select="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Project Manager</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project Manager" :multiple="true"
                    v-model="search.projectmanager" :options="reqData.projectmanagerlist" @input="nullifyListing" :clear-on-select="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Program Manager</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Program Manager" :multiple="true"
                    v-model="search.progman" :options="reqData.proglist" @input="nullifyListing" :clear-on-select="true" />
                </div>
            
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Engineering Manager</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Engineering Manager" :multiple="true"
                    v-model="search.engineeringmaanager" :options="reqData.emlist" @input="nullifyListing" :clear-on-select="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Task Owner</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Task Owner" :multiple="true"
                    v-model="search.taskownername" :options="reqData.taskownerlist" @input="nullifyListing" :clear-on-select="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Task Manager</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Task Manager" :multiple="true"
                    v-model="search.taskmngr" :options="reqData.taskmanager" @input="nullifyListing" :clear-on-select="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">QA Manager</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select QA Manager" :multiple="true"
                    v-model="search.qamanager" :options="reqData.qamnglist" @input="nullifyListing" :clear-on-select="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">QA Lead</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select QA Lead" :multiple="true"
                    v-model="search.qalead" :options="reqData.qaleadlist" @input="nullifyListing" :clear-on-select="true" />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 text-right">
                  <button type="submit" :disabled="isSubmitDisabled" class="btn btn-relief-primary mt-2 btn-margin-three" @click="getBTMList()"><search-icon
                      size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                      
                  <button type="reset" class="btn btn-outline-secondary mt-2 btn-margin-three" @click="resetRecords()"><x-icon
                      size="1.5x" class="custom-class"></x-icon><span> Clear</span></button>
                  <button type="submit" class="btn btn-relief-primary  mt-2  mr-0 btn-margin-three" @click="alert(isChecked)"
                    :disabled="btmList.length && !isChecked.some(bool => bool === true)">
                    <edit-icon size="1.5x" class="custom-class"></edit-icon><span> Bulk Update</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" id="domain-list">
          <div class="card-body p-0 ps ps--active-x">
            <div class="table-responsive">
              <table class="table dataTableListing table-hover" id="example" aria-describedby="mydesc">
                <thead>
                  <tr>
                    <th scope="col" class="">
                      <span class="">
                        <label for="select_all_checkboxes"></label>
                        <input type="checkbox" id="select_all_checkboxes" class="visuallyhidden" @click="selectAll"
                          v-model="isSelectAllChecked" :disabled="!btmList.length">
                      </span>
                    </th>
                    <th scope="col" class="text-center">Sr#</th>
                    <th scope="col" class="text-center">Business Task Code/Task Name</th>
                    <th scope="col" class="align-middle" style="min-width:140px">Project Name/Project manager</th>
                    <th scope="col" class="text-center">Program/Engineering Manager</th>
                    <th scope="col" class="text-center">Task Owner/Task Manager</th>
                    <th scope="col" class="text-center">QA Manager/QA Lead</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(data, index) in btmList">
                    <tr v-bind:key="index">
                      <td class="">
                        <span class="">
                          <label :for="index + '-' + data.moduleid"></label>
                          <input type="checkbox" :id="index + '-' + data.moduleid" v-model="isChecked[index]"
                            class="delete_checkbox" @click="alterSelectAllCheck(index)">
                        </span>
                      </td>
                      <td class="text-center">{{ index + 1 + currPageNum * pagelimit }}</td>
                      <td class="details_column_table rolelistdd font-weight-bold sitebluecolor" @click="redirecttourl('view', data)"
                        href="javascript:void(0);" style="cursor: pointer;">
                        {{ data.businesstaskcode ? data.businesstaskcode : 'NA' }}
                        <hr class="hr_border-bottom" />
                        {{ data.modulename }}
                      </td>
                      <td class="details_column_table capitalisetext">
                        <span class="font-weight-bold" v-html="showprojectname(data.projectnameist)"></span>
                        <hr class="hr_border-bottom" />
                        {{ data.projectmaanager ? data.projectmaanager : 'NA' }}
                      </td>
                      <td class="text-center capitalisetext details_column_table">    
                        {{data.programmanager ? data.programmanager: 'NA'}}
                        <hr class="hr_border-bottom" />
                        {{ data.engineeringmaanager ? data.engineeringmaanager : 'NA' }}
                      </td>
                      <td class="text-center capitalisetext details_column_table">    
                        {{ data.taskowner ? data.taskowner : 'NA' }}
                        <hr class="hr_border-bottom" />
                        {{data.productstackholder ? data.productstackholder: 'NA'}}
                      </td>
                      <td class="text-center capitalisetext details_column_table">    
                        {{ data.qamanager ? data.qamanager : 'NA' }}
                        <hr class="hr_border-bottom" />
                        {{data.qalead ? data.qalead: 'NA'}}
                      </td>
                    </tr>
                  </template>
                  <template v-if="btmList.length == 0">
                    <tr>
                      <td colspan="14" style="text-align: center;">No record found</td>
                    </tr>
                  </template>
                  <template v-if="!this.search.project">
                    <tr>
                      <td colspan="14" style="text-align: center;">Please select project to filter business tasks</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="pageCenter text-center mt-1 mb-1" v-if="btmList.length">
          <div>{{ btmList.length }} records found</div>
        </div>
        <div class="modal fade" id="technologypopup" tabindex="2" role="dialog"
          aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                <h4 class="modal-title">Bulk Business Task Assignment </h4>
                <h5 style="margin-left: auto; margin-top: 4px">{{ isChecked.reduce(this.countChecked, 0) }} Row selected
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body mb-2">
                <div class="row  flex--items">
                  <div class="col-md-6">
                    <label class="form-label lable-left">Project Manager</label>
                    <treeselect class="projectmdllistdd" placeholder="Select project manager"
                      v-model="reqData.projectmanager" :options="reqData.projectmanagerlist" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label lable-left">Engineering Manager</label>
                    <treeselect class="projectmdllistdd" placeholder="Select Engineering manager"
                      v-model="reqData.engineeringmaanager" :options="reqData.engineeringmaanagerlist" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label lable-left">Program Manager</label>
                    <treeselect class="projectmdllistdd" placeholder="Select Program manager"
                      v-model="reqData.progman" :options="reqData.proglist" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label lable-left">Task Owner</label>
                    <treeselect class="projectmdllistdd" placeholder="Select Task Owner"
                      v-model="reqData.taskownername" :options="reqData.taskownerlist" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label lable-left">Task Manager</label>
                    <treeselect class="projectmdllistdd" placeholder="Select Task manager"
                      v-model="reqData.taskmngr" :options="reqData.taskmanager" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label lable-left">QA Manager</label>
                    <treeselect class="projectmdllistdd" placeholder="Select QA manager"
                      v-model="reqData.qamanager" :options="reqData.qamnglist" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label lable-left">QA Lead</label>
                    <treeselect class="projectmdllistdd" placeholder="Select QA Lead"
                      v-model="reqData.qalead" :options="reqData.qaleadlist" />
                  </div>
                  <div class="col-md-6">
                  </div>
                  <div>
                    
                    <button type="submit" class="btn btn-relief-primary mt-2"
                    :disabled="isButtonDisabled" @click="updateManagers()">
                      <check-square-icon size="1.5x" class="custom-class"></check-square-icon><span>
                        Update</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import { EditIcon, XCircleIcon, SearchIcon, XIcon, PlusCircleIcon, CheckSquareIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'BTMBulkUpdate',
  mixins: [ commonMethods ],
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    XCircleIcon, SearchIcon, XIcon, PlusCircleIcon, EditIcon, CheckSquareIcon
  },
  data() {
    return {
      isLoading: false,
      listfullPage:true,
      islistLoading:false,
      undt: null,
      tokendata: null,
      pagefeatures: [],
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      btmList: [],
      isChecked: [],
      isSelectAllChecked: false,
      search: {
        project: null,
        engineeringmaanager: null,
        projectmanager: null,
        projectList: [],
        proman: null,
        progman: null,
        taskownername: null,
        taskmngr: null,
        qamanager: null,
        qalead: null
      },
      reqData: {
        projectmanager: null,
        projectmanagerlist: [],
        engineeringmaanager: null,
        engineeringmaanagerlist: [],
        pmlist: [],
        emlist: [],
        proglist: [],
        taskownerlist: [],
        taskmanager: [],
        qaleadlist: [],
        qamnglist: [],
        progman : null,
        taskownername: null,
        taskmngr: null,
        qamanager: null,
        qalead:null
      },
      isSearchBoxActive: false,
      card_content: 'card-content',
      collapse: 'collapse',
      userrole: null,

    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if (permission) {
      let userdata = window.localStorage.getItem('userdata');
      if (userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.userrole = window.localStorage.getItem("userrole")
        this.getEngMan()
        this.getProMan()
        this.getProjectAllocatedToEmp();
        this.getProjectManagerDetails(477, null)
        this.getProjectManagerDetails(492, null)
        this.getProjectManagerDetails(503, null)
        this.getTaskManagerOwnerList(131, null)
        this.getTaskManagerOwnerList(513, null)
        this.getProjectQAdata(509, null);
        this.getProjectQAdata(508, null);
      }
    }
    $(".filter_rotation_onlick").click(function () {
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  computed: {
    isButtonDisabled() {
        if(this.reqData.qalead || this.reqData.qamanager || this.reqData.progman || this.reqData.taskmngr || this.reqData.taskownername || this.reqData.engineeringmaanager || this.reqData.projectmanager ){
        return false;
      }else{
        return true;
      }
    },  
    isSubmitDisabled() {

      let sproject = this.search.project ? (JSON.parse(JSON.stringify(this.search.project))).length > 0 ?  this.search.project : null  : null;

      let sqamanager = this.search.qamanager ? (JSON.parse(JSON.stringify(this.search.qamanager))).length > 0 ?  this.search.qamanager : null  : null;

      let sengineeringmaanager = this.search.engineeringmaanager ? (JSON.parse(JSON.stringify(this.search.engineeringmaanager))).length > 0 ?  this.search.engineeringmaanager : null  : null;

      let sprojectmanager= this.search.projectmanager ? (JSON.parse(JSON.stringify(this.search.projectmanager))).length > 0 ?  this.search.projectmanager : null  : null;

      let sqalead= this.search.qalead ? (JSON.parse(JSON.stringify(this.search.qalead))).length > 0 ?  this.search.qalead : null  : null;

      let staskownername= this.search.taskownername ? (JSON.parse(JSON.stringify(this.search.taskownername))).length > 0 ?  this.search.taskownername : null  : null;

      let staskmngr= this.search.taskmngr ? (JSON.parse(JSON.stringify(this.search.taskmngr))).length > 0 ?  this.search.taskmngr : null  : null;

      let sprogman= this.search.progman ? (JSON.parse(JSON.stringify(this.search.progman))).length > 0 ?  this.search.progman : null  : null;
      
          if(sqamanager || sproject || sengineeringmaanager || sprojectmanager || sqalead || staskownername || staskmngr|| sprogman){
        return false;
      }else{
        return true;
      }
        
     }
  },
  methods: {
    updateManagers() {
      let businessIds = []
      this.btmList.map((btm, idx) => {
        if (this.isChecked[idx]) {
          businessIds.push(parseInt(btm.moduleid))
        }
      })
      let payload = new Object({
        lastmodifiedby: this.undt.username,
        businessids: businessIds,
        empcode: this.undt.userid,
        useremail: this.undt.username,
      })
      if (this.reqData.engineeringmaanager) {
        payload.engineeringmanager = (this.reqData.engineeringmaanager).toLowerCase();
      }
      if (this.reqData.projectmanager) {
        payload.projectmanager = (this.reqData.projectmanager).toLowerCase();
      }
      if (this.reqData.progman) {
        payload.programmanager = (this.reqData.progman).toLowerCase();
      }
      if (this.reqData.taskownername) {
        payload.taskowner = (this.reqData.taskownername).toLowerCase();
      }
      if (this.reqData.taskmngr) {
        payload.productstackholder = (this.reqData.taskmngr).toLowerCase();
      }
      if (this.reqData.qamanager) {
        payload.qamanager = (this.reqData.qamanager).toLowerCase();
      }
      if (this.reqData.qalead) {
        payload.qalead = (this.reqData.qalead).toLowerCase();
      }

      if (businessIds.length) {
        let url = 'api/master/businesstask/bulkUpdateManagers'
        axios({
          method: "POST",
          url,
          data: payload,
          headers: { authorization: this.tokendata }
        }).then(result => {
          this.reqData.projectmanager = null
          this.reqData.engineeringmaanager = null
          this.reqData.progman = null
          this.reqData.taskownername = null
          this.reqData.taskmngr = null
          this.reqData.qamanager = null
          this.reqData.qalead = null

          $('#technologypopup').modal('hide');
          if (result.data.errorCode === 0) {
            this.getBTMList()
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          } else if (result.data.errorCode == 3) {
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function () {
              window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              text: result.data.msg,
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          }
        }).catch(e => {
          this.displayError(e)
        })
      }
    },
    getBTMList() {
      this.islistLoading = true;


      this.isSelectAllChecked = false
      let payload = new Object({
        useremail: this.undt.username,
        empcode: this.undt.userid,
        projectname: this.search.project,
        engineeringmaanager: this.search.engineeringmaanager,
        projectmanager: this.search.projectmanager,
        userrole: this.userrole,
        qamanager: this.search.qamanager?this.search.qamanager:null,
        qalead: this.search.qalead?this.search.qalead:null,
        taskownername: this.search.taskownername?this.search.taskownername: null,
        taskmngr : this.search.taskmngr?this.search.taskmngr: null,
        progman: this.search.progman?this.search.progman: null
      })
      let url = `api/businesstask/getBusinessBulkAssignmentList`
      axios({
        method: 'POST',
        url: url,
        data: payload,
        headers: { authorization: this.tokendata },
      })
        .then(result => {
          this.islistLoading = false;
          if (result.data.errorCode == 0) {
            this.btmList = result.data.data.rows;
            this.totalcount = result.data.data.count
            this.isChecked = Array(this.totalcount).fill(false)
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          } else if (result.data.errorCode == 3) {
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function () {
              window.location.href = "/#/login";
            })
          } else {
            this.btmList = []
            this.totalcount = 0
          }
        }).catch(e => {
          this.displayError(e)
        })
    },
    alert(d) {
      $('#technologypopup').modal('show');
    },
    selectAll() {
      if (!this.isSelectAllChecked) {
        this.isChecked = Array(this.totalcount).fill(true)
      } else {
        this.isChecked = Array(this.totalcount).fill(false)
      }
    },
    alterSelectAllCheck(index) {
      if (this.isChecked[index]) {
        this.isSelectAllChecked = false
      } else {
        this.isSelectAllChecked = this.isChecked.every((i, idx) => {
          if (idx !== index) {
            return i === true
          } else {
            return true
          }
        })
      }
    },
    searchBoxCollapse() {
      this.isSearchBoxActive = !this.isSearchBoxActive
    },
    resetRecords: function () {
      this.search.project = null,
      this.search.engineeringmaanager = null,
      this.search.projectmanager = null,
      this.search.proman= null,
      this.search.progman= null,
      this.search.taskownername= null,
      this.search.taskmngr= null,
      this.search.qamanager= null,
      this.search.qalead = null,
      this.btmList = [],
      this.totalcount = 0
       // this.getBTMList()
    },
    showprojectname(prname) {
      let responsestr = '';
      let prlist = prname.split(',')
      if (prlist.length > 1) {
        for (let i = 0; i < prlist.length; i++) {
          responsestr += (i + 1) + '. ' + prlist[i] + ' <br/>'
        }
      } else {
        for (let i = 0; i < prlist.length; i++) {
          responsestr += prlist[i]
        }
      }
      return responsestr
    },
    nullifyListing() {
      this.btmList = []
    },
    redirecttourl(btnstate, rowdata) {
      rowdata.backbtnstate = btnstate
      if (btnstate == "view") {
        let routeData = this.$router.resolve({
          path: '/businesstask/view?businessid=' + rowdata.moduleid,
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata
          }
        })
        window.open(routeData.href, '_blank');
      }
    },
    getProjectAllocatedToEmp() {
      this.isLoading = true
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage: 'listingpage'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers': { 'authorization': this.tokendata }
      }).then((result) => {
        this.isLoading = false;
        this.btmList = []
        if (result.data.errorCode == 0) {
          this.search.projectList = result.data.data;
        } else {
          this.search.projectList = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    projectChangeSearch(node) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata }
        }).then((result) => {
          this.isLoading = false;
          this.btmList = []
          if (result.data.errorCode == 0) {
            this.search.projectList = result.data.data;
          } else {
            this.search.projectList = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    countChecked(previousValue, currentValue, index) {
      let returns = previousValue
      if (this.isChecked[index]) {
        returns = previousValue + 1
      }
      return returns
    },
    getEngMan() {
      axios({
        method: "POST",
        url: "api/listing/getStakeHoldersList",
        data: { roleid: '492', empcode: this.undt.userid, useremail: this.undt.username },
        headers: { authorization: this.tokendata }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.reqData.engineeringmaanagerlist = result.data.data
        } else {
          this.reqData.engineeringmaanagerlist = []
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    getProMan() {
      axios({
        method: "POST",
        url: "api/listing/getStakeHoldersList",
        headers: { authorization: this.tokendata },
        data: { roleid: '477', empcode: this.undt.userid, useremail: this.undt.username }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.reqData.projectmanagerlist = result.data.data
        } else {
          this.reqData.projectmanagerlist = []
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum
      this.currPageNum = pageNum - 1
      let payload = null
      if (this.search.project) {
        payload = new Object({
          project: this.search.project
        })
      }
      this.getBTMList();
      setTimeout(function () {
        $('html, body').animate({
          scrollTop: $("#domain-list").offset().top - 70
        });
      }, 500);
    },
    getTaskManagerOwnerList(roleid, state) {
      this.inputappl = {
        projectid: this.projectid,
        empcode: this.undt.userid,
        useremail: this.undt.username
      }
      axios({
        'method': 'POST',
        'url': 'api/listing/getProjectManagerDetails',
        'data': {
          roleid: roleid, projectidarr: state, empcode: this.undt.userid,
          useremail: this.undt.username
        },
        'headers': { 'authorization': this.tokendata }
      })
        .then(result => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            if (roleid == 131) {
              this.reqData.taskmanager = result.data.data.rows;
            } else if (roleid == 513) {
              this.reqData.taskownerlist = result.data.data.rows;
            }
          } else {
            if (roleid == 131) {
              this.reqData.taskmanager = []
            } else if (roleid == 513) {
              this.reqData.taskownerlist = [];
            }
          }
        })
    },
    getProjectQAdata(roleid, state) {
      this.inputappl = {
        projectid: this.projectid,
        empcode: this.undt.userid,
        useremail: this.undt.username
      }
      axios({
        'method': 'POST',
        'url': 'api/listing/getProjectManagerDetails',
        'data': {
          roleid: roleid, projectidarr: state, empcode: this.undt.userid,
          useremail: this.undt.username
        },
        'headers': { 'authorization': this.tokendata }
      })
        .then(result => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            if (roleid == 509) {
              this.reqData.qaleadlist = result.data.data.rows;
            } else if (roleid == 508) {
              this.reqData.qamnglist = result.data.data.rows;
            }
          } else {
            if (roleid == 509) {
              this.reqData.qaleadlist = []
            } else if (roleid == 508) {
              this.reqData.qamnglist = [];
            }
          }
        }).catch(e => {
          this.displayError(e)
        })
    },
    getProjectManagerDetails(roleid, projectid) {
      axios({
        method: "POST",
        url: "api/listing/getProjectManagerDetails",
        headers: { authorization: this.tokendata },
        data: { roleid: roleid, projectidarr: projectid, empcode: this.undt.userid, useremail: this.undt.username }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (roleid == 477) {
            this.reqData.pmlist = result.data.data.rows
          } else if (roleid == 492) {
            this.reqData.emlist = result.data.data.rows
          } else if (roleid == 503) {
            this.reqData.proglist = result.data.data.rows
          }
        } else {
          if (roleid == 477) {
            this.reqData.pmlist = [];
          } else if (roleid == 492) {
            this.reqData.emlist = [];
          } else if (roleid == 503) {
            this.reqData.proglist = [];
          }
        }
      }).catch(e => {
          this.displayError(e)
        });
    }
  }
}
</script>