<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="card-navigation">
          <div class="file-manager-content-body">
            <div class="drives">
              <div class="card border-warning filter_card_searchbgst">
                  <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                            <div class="avatar bg-light-secondary rounded d-inline-flex">
                                <div class="avatar-content">
                                    <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                </div>
                            </div>
                             <h4 class="card-title d-inline-flex ml-1">Search QA Wise Daily Reports</h4>
                        </div>
                        <span class="rotetions_view_arrow ml-auto mx-1"> 
                          <em class="las la-angle-down"></em>
                        </span>
                      </div>
                    </div>
                  </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                  <div class="card-body pt-2 px-sm-mobile-10">
                    <div class="row">
                        <div class="col-sm-6 col-md-5 col-lg-3 mb-0">
                            <label class="form-label lable-left">QA Name</label>
                            <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of QA Name" v-model="search.empname" v-on:search-change="getallemployeelistnewapi" :multiple="true" :clear-on-select="true" :options="employeenamelist"/>
                        </div>
                      <div class="col-sm-6 col-md-5 col-lg-3 mb-0">
                        <label class="form-label lable-left">Reporting Manager Name</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="mngrlist" v-if="pagefeatures.activityreportmanager" :disabled="true"/>
                        <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="mngrlist" v-on:search-change="getallmanagerlistnewapi" v-else/>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-3 mb-1">
                        <div class="date_picker_foundeds">
                          <label for="">From Date</label>
                          <div class="input-group input-group-merge">
                            
                            <date-picker placeholder="Select Date From" v-model="search.fromdate" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                            <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                            </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-3 mb-0">
                        <div class="date_picker_foundeds">
                          <label for="">To Date</label>
                          <div class="input-group input-group-merge">
                            
                            <date-picker placeholder="Select Date To" v-model="search.todate" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right ">
                        <button type="submit" class="btn btn-relief-primary mt-2 mr-1 mobile_margin-px-2"  :disabled="isBothDateSelected" @click="getQAwiseReports(search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                        <button type="reset" class="btn btn-outline-secondary mt-2 mr-1 mobile_margin-px-2" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                        <button type="move" class="btn btn-relief-primary mt-2 mr-1 mobile_margin-px-2" :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" id="reportTopScroll">
                <div class="col-md-12">
                  <div class="card text-center mb-3">
                    <div class="card-header p-2">
                      <h4 class="card-title">
                        QA Wise Daily Report
                      </h4>
                    </div>
                    <div class="card-datatable">
                      <div class="" id="myTabContent">
                        <div class="" id="home"  role="tabpanel"  aria-labelledby="home-tab">
                          <div class="" id="table-small">
                            <div class="">
                              <div class="">
                                <div class="table-responsive_sitcky datatableview px-1"  v-if="showtabledata">
                                  <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Employee Activity Report">
                                    <thead>
                                      <tr>
                                        <th scope="col" class="srno align-middle first-col-sticky py-1" style="min-width:45px">Sr#</th>
                                        <th scope="col" class="align-middle py-1">Action Date</th>
                                        <th scope="col" class="align-middle py-1">QA Name</th>
                                        <th scope="col" class="align-middle py-1">Reporting Manager</th>
                                        <th scope="col" class="align-middle py-1">Written TestCases Count</th>
                                        <th scope="col" class="align-middle py-1">Executed TestCases Count</th>
                                        <th scope="col" class="align-middle py-1">Logged Issue Count</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template v-for="(data, index) in qadailyreportList">
                                        <tr> 
                                          <td data-column="Sr#" class="srno font-weight-normal first-col-sticky py-1">{{ index+1+currPageNum*pagelimit }}</td>  
                                          <td data-column="Action Date" class="capitalisetext font-weight-normal">
                                            {{data.actiondate}}
                                          </td>               
                                           <td data-column="QA Name" class="capitalisetext font-weight-normal">
                                            {{data.actionby ? extractNameFromEmail(data.actionby):'NA'}} </td>
                                            <td data-column="Reporting Manager" class="capitalisetext font-weight-normal">
                                            {{data.mngrid ? extractNameFromEmail(data.mngrid):'NA'}}
                                          </td>
                                           <td data-column="Written TestCases Count" class="capitalisetext font-weight-normal">
                                              {{data.tccreated}}
                                          </td>
                                          <td data-column="Executed TestCases Count" class="capitalisetext font-weight-normal">
                                            {{data.totalexecution ? data.totalexecution:0}}
                                          </td>
                                          <td data-column="Logged Issue Count" class="capitalisetext font-weight-normal">
                                            {{data.issuecount}}
                                          </td>
                                        </tr>
                                      </template>
                                      <template v-if="qadailyreportList.length == 0 && isresponce">
                                        <tr>
                                          <td colspan="14" style="text-align: center;">No record found</td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pageCenter pt-2 text-center">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <style>
  .card .card-header {
    padding: 0.8rem;
  }
  .title {
    text-align: center;
  }
  input.mx-input::-webkit-input-placeholder {
  color: #b9b9c3;
  font-size: 14px;
  }
  input:-moz-placeholder {
  color: #b9b9c3;
  font-size: 14px;
  }
  input.mx-input{
    color: #000 !important
  }
  </style>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import Treeselect from "@riophae/vue-treeselect";
  import { EyeIcon,SearchIcon,XIcon,ExternalLinkIcon,UserIcon } from "vue-feather-icons";
  import Pagination from "vue-pagination-2";
  import commonMethods from '../../utils/commonMethods';
  export default {
    name: "ActivityReport",
    components: {
      Loading,
      VueElementLoading,
      EyeIcon,
      Treeselect,
      SearchIcon,
      XIcon,
      ExternalLinkIcon,
      Pagination,
      DatePicker,
      UserIcon
    },
    mixins: [ commonMethods ],
  
    data() {
      return {
        isBothDateSelected:false,
        projectnameList:[],
        isLoading: false,
        fullPage: true,
        qadailyreportList: [],
        showroledata: [],
        showempdata: [],      
        totalcount: 0,
        pageCount: 0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures: [],
        showtabledata: true,
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        search:{
          allotedto:null,
          empname:null,
          allotedtolist:[],
          todate:null,
          fromdate:null,
          mngrname:null,            
        },
        employeenamelist:[],
        tokendata:null,
        mngrlist:[],
        isresponce:false,
      };
    },
    created() {
    //   this.pagefeatures = {}
    //   if(window.localStorage.getItem('pagefeatures')){
    //       var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
    //       pagefeatures1.forEach(element => {
    //           if (element.page === '/reports/activityreport') {
    //             this.pagefeatures[element.featurename] = element.featureaccess
    //           }
    //       })
    //   }
    },
    mounted() {
    //   let permission = this.$Service.verifyAccess();
    //   if(permission){
        var userdata = window.localStorage.getItem("userdata");
        this.tokendata = window.localStorage.getItem("token");
        if (userdata) {
          userdata = JSON.parse(userdata);
          this.undt = userdata;
          if(this.pagefeatures.activityreportmanager){
            this.getManagerList();
            this.search.mngrname = [parseInt(this.undt.userid)]
          }
          this.getQAwiseReports()
        }
      //}
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      });
    },

    methods: {
      extractNameFromEmail(email) {
        const nameRegex = /^([^@]*)@/;
        const matches = email.match(nameRegex);
        if (matches && matches.length > 1) {
          const nameWithoutDots = matches[1].replace(/\./g, ' ');
          return nameWithoutDots;
        }
        return email;
    },
      notBeforeToday: function (date) {
        this.search.todate = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      disabledBefore(date) {
        let dayBefore = moment(this.search.fromdate).format(this.TimeFormat);
        const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
        return date < beforeToday; // Assume < 25 May Not Selected
      },
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },
      selassignedto(state, value) {
        if(!state) {
            this.search.allotedto=null
        } else {
            this.search.allotedto=state
        }
    },
    getallemployeelistnewapi(node) {
        if (node && node.length>=3) {
            this.pageoffset=1
            axios({
            'method': 'POST',
            'url': 'api/reports/getallemployeelistnewapi',
            'headers':{'authorization':this.tokendata},
            'data': {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                empname: node.toLowerCase(),
            }
            }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
                this.employeenamelist = result.data.data;
                let decryptdepartmenttext = this.employeenamelist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
                this.employeenamelist.sort((a, b) => {
                return a.label.localeCompare(b.label);
                });  
            } else {
                this.employeenamelist = [];
            }
            }).catch(e => {
            this.displayError(e)
            })
        }
    },

      getManagerList() {
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/searching/getmanagerlist',
          'headers':{'authorization':this.tokendata},
          'data': {
            mngrid:null,
            useremail: this.undt.username,
            empcode: this.undt.userid
          }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.mngrlist = result.data.data
            this.mngrlist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
          } else {
            this.mngrlist = null
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getallmanagerlistnewapi(node) {
        if (node && node.length>=3) {
          this.pageoffset=1
          axios({
            'method': 'POST',
            'url': 'api/reports/getallmanagerlistnewapi',
            'headers':{'authorization':this.tokendata},
            'data': {
              useremail: this.undt.username,
              empcode: this.undt.userid,
              mngname: node.toLowerCase(),
            }
          }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
              this.mngrlist = result.data.data;
            } else {
              this.mngrlist = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
        }
      },
       selectedstartdate(){
        if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
          this.isBothDateSelected = false
        }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
          this.isBothDateSelected = true
        }
      },
      selectedenddate(){
         if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
          this.isBothDateSelected = false
        }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
          this.isBothDateSelected = true
        }
      },
      getQAwiseReports(search){
          this.isLoading = true;
          let url = "api/reports/getQAwiseReports";
          this.input = {
              offset: this.pageoffset,
              limit: apiUrl.LIMIT,
              useremail: this.undt.username,
              mangerid:this.search.mngrname,
              empcode: this.undt.userid
  
          };
        let fromdate = null
        let todate = null
        if(!this.search.todate && !this.search.fromdate){
                fromdate = new Date()
                todate = new Date()
                fromdate.setDate(fromdate.getDate() - 30)
                fromdate = moment(String(fromdate)).format('YYYY-MM-DD');
                this.search.fromdate = fromdate

                todate.setDate(todate.getDate())
                todate = moment(String(todate)).format('YYYY-MM-DD');
                this.search.todate = todate
          }

          this.input.empid = this.search.empname?this.search.empname:null
          this.input.datefrom = this.search.fromdate
          this.input.dateto = this.search.todate
  
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.isresponce=true
            this.qadailyreportList = result.data.data.rows;
            this.totalcount = result.data.data.count;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          }  else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            this.qadailyreportList = [];
            this.totalcount = 0;
            this.pageCount = 0;
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      resetRecords: function () {
        this.search.empname = null;
        this.search.mngrname =[];
        this.search.todate = null;
        this.search.fromdate = null;
        this.isBothDateSelected = false
        let rowdata={}
        this.getQAwiseReports(rowdata);
        localStorage.removeItem('searchItems')
      },
      clickCallback: function (pageNum) {
        this.pageoffset = pageNum;
        this.currPageNum = pageNum - 1
          this.getQAwiseReports(false,this.parentmoduledata);
        setTimeout(function(){
              $('html, body').animate({
                  scrollTop: $("#reportTopScroll").offset().top -70
              });
          }, 500);
        },
        ExportXL() {
        this.isLoading = true;
        this.exportxlsdata = [];
        this.exporterr = "";
        this.input = {
          limit:null,
          offset:null,
          useremail: this.undt.username,
          mangerid:this.search.mngrname,
          empcode: this.undt.userid,
        };
        let fromdate = null
        let todate = null
        if(!this.search.todate && !this.search.fromdate){
            fromdate = new Date()
            todate = new Date()
            fromdate.setDate(fromdate.getDate() - 30)
            fromdate = moment(String(fromdate)).format('YYYY-MM-DD');
            this.search.fromdate = fromdate

            todate.setDate(todate.getDate())
            todate = moment(String(todate)).format('YYYY-MM-DD');
            this.search.todate = todate
          }
          this.input.empid = this.search.empname?this.search.empname:null
          this.input.datefrom = this.search.fromdate
          this.input.dateto = this.search.todate
  
        axios({
          method: "POST",
          url: `api/reports/getQAwiseReports`,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.exportxlsdata = result.data.data.rows;
              var filename = "";
              this.download(result.data.data.rows,filename)
          } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
          if (result.data.status) {
            this.isDetActive = true;
          }
        }).catch(e => {
          this.displayError(e)
        });;
      },
      download(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = 'QAWiseDailyReport'+filename
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
              if (ShowLabel) {
                  let row = ''
                  for (let index in arrData[0]) {
                      row += index + ','
                  }
                  row = row.slice(0, -1)
                  CSV = 'Sr#,Action Date,QA Name,Reporting Manager,Written TestCases Count,Executed TestCases Count,Logged Issues Count' + '\r\n'
              }
              var row = ''
              for (let i=0; i<arrData.length; i++) {
              let index = arrData[i]
              let srno = i+1
              let actionby = index.hasOwnProperty('actionby') ? index.actionby == null || index.actionby == 'NA' ? '' : this.extractNameFromEmail(index.actionby.replace(/"/g, '""')) : '';

              let mngrid = index.hasOwnProperty('mngrid') ? index.mngrid == null || index.mngrid == 'NA' ? '' : this.extractNameFromEmail(index.mngrid) : '';

              let tccreated = index.hasOwnProperty('tccreated') ? index.tccreated == null || index.tccreated == 'NA' ? '' : index.tccreated : '';
              let totalexecution = index.hasOwnProperty('totalexecution') ? index.totalexecution == null?'' :index.totalexecution : '';
              let issuecount = index.hasOwnProperty('issuecount') ? index.issuecount == null || index.issuecount == 'NA' ? '' : index.issuecount : '';
              let actiondate = index.hasOwnProperty('actiondate') ? index.actiondate == null || index.actiondate == 'NA' ? '' : index.actiondate : '';

                  row += '"' + srno + '",',
                  row += '"' + actiondate + '",',
                  row += '"' + actionby + '",',
                  row += '"' + mngrid + '",',
                  row += '"' + tccreated + '",',
                  row += '"' + totalexecution + '",',
                  row += '"' + issuecount + '",',
                  row += '\r\n'
              }
              CSV += row
              if (CSV === '') {
                Swal.fire({
                  title: "",
                  text: "Data not found",
                  icon: "info",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                });
              return;
              }
              var fileName = 'QAWiseDailyReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
              var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
              var link = document.createElement('a')
              link.href = uri
              link.style = 'visibility:hidden'
              link.download = fileName + '.csv'
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              Swal.fire({
                  title: "Success!",
                  text: "Export Report successful",
                  icon: "success",
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              });
          } else {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });   
          }
      },
      export (data, filename, mime) {
        var data
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
        download(blob, filename, mime)
      },
      },


    //},
  };
  </script>