<template>

    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-1 ">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-left mb-0">Account Settings</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a>
                                    </li>
                                    <li class="breadcrumb-item"><a href="#">Pages</a>
                                    </li>
                                    <li class="breadcrumb-item active"> Account Settings</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <section id="page-account-settings">
                    <div class="row">
                        <div class="col-md-4 col-lg-3 mb-2 mb-md-0">
                            <ul class="card nav nav-pills flex-column nav-left  p-1">
                                <li class="nav-item">
                                    <a class="nav-link active" id="account-pill-general" data-toggle="pill" href="#account-vertical-general" aria-expanded="true" @click="getprofiledata">
                                        <user-icon size="1.5x" class="font-medium-3 mr-1"></user-icon>    
                                        <span class="font-weight-bold">My Profile</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-info-edu" data-toggle="pill" href="#account-vertical-info-edu" aria-expanded="false" @click="getmasterList('edu')">
                                        <book-open-icon size="1.5x" class="font-medium-3 mr-1"></book-open-icon>
                                        <span class="font-weight-bold">Education</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-info" data-toggle="pill" href="#account-vertical-info" aria-expanded="false" @click="getmasterList('exp')">
                                        <star-icon size="1.5x" class="font-medium-3 mr-1"></star-icon>
                                        <span class="font-weight-bold">Experience</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-recomment" data-toggle="pill" href="#account-vertical-recomment" aria-expanded="false" @click="getRecommendationdata(1)">
                                        <thumbs-up-icon size="1.5x" class="font-medium-3 mr-1"></thumbs-up-icon>
                                        <span class="font-weight-bold">Recommendation</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-social" data-toggle="pill" href="#account-vertical-social" aria-expanded="false" @click="getmasterList('skills')">
                                        <link-icon size="1.5x" class="font-medium-3 mr-1"></link-icon>
                                        <span class="font-weight-bold">Skills</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-social-awards" data-toggle="pill" href="#account-vertical-social-awards" aria-expanded="false" @click="getmasterList('awards')">
                                        <award-icon size="1.5x" class="font-medium-3 mr-1"></award-icon>
                                        <span class="font-weight-bold">Honors and Awards</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-social-courses" data-toggle="pill" href="#account-vertical-social-courses" aria-expanded="false" @click="getmasterList('course')">
                                        <pen-tool-icon size="1.5x" class="font-medium-3 mr-1"></pen-tool-icon>
                                        <span class="font-weight-bold">Courses</span>
                                    </a>
                                </li>
                                 <li class="nav-item">
                                    <a class="nav-link" id="account-pill-social-certificate" data-toggle="pill" href="#account-vertical-social-certificate" aria-expanded="false" @click="getmasterList('certificate')">
                                        <check-circle-icon size="1.5x" class="font-medium-3 mr-1"></check-circle-icon>
                                        <span class="font-weight-bold">Certification</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-krakpi" data-toggle="pill" href="#account-vertical-krakpi" aria-expanded="false">
                                        <trending-up-icon size="1.5x" class="font-medium-3 mr-1"></trending-up-icon>
                                        <span class="font-weight-bold">My KRA-KPI</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="account-pill-exam" data-toggle="pill" href="#account-vertical-exam" aria-expanded="false">
                                          <clipboard-icon size="1.5x" class="font-medium-3 mr-1"></clipboard-icon>
                                        <span class="font-weight-bold">Assessment</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a @click="checkclick" class="nav-link" id="btc_listing_view" data-toggle="pill" href="#btclisting_Tab" aria-expanded="false">
                                          <clipboard-icon size="1.5x" class="font-medium-3 mr-1"></clipboard-icon>
                                        <span class="font-weight-bold">BTC Listing Mapping</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-8 col-lg-9">
                            <div class="card user_profile_card_rightside">
                                <div class="card-body">
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane active" id="account-vertical-general" aria-labelledby="account-pill-general" aria-expanded="true">
                                            <section id="profile-info">
                                                <div class="row">
                                                    <div class="col-lg-12 col-12 order-lg-1">
                                                        <div class="card mb-0 border-0 " style="box-shadow: none;">
                                                            <div class="card-body p-0">
                                                                <div class="media">
                                                                    <a href="javascript:void(0);" class="mr-25">
                                                                        <img  :src="profileurl" id="account-upload-img" class="rounded mr-50" alt="profile image" height="120" width="120" />
                                                                    </a>
                                                                    <form @submit.prevent="onUpload" enctype="multipart/form-data">
                                                                        <div class="media-body mt-75 ml-1">
                                                                           
                                                                            <input type="file" id="account-upload" hidden accept="image/*" ref="file" @change="onSelect"/>
                                                                            <p class="userprofile_title_name">{{firstname.toUpperCase()}} {{lastname.toUpperCase()}} </p>
                                                                            <!-- <span class="userprofile_title_name_degi pr-1">{{decryptText(dept)}}</span> -->
                                                                             <span class="userprofile_title_name_degi pr-1"> {{ decryptText(desg) }}-[{{ decryptText(dept) }}]</span>
                                                                            <span v-if="totalexp" style="font-weight:bold;font-size:medium;text-transform: capitalize;">:  {{totalexp}}</span>
                                                                            <div style="margin-top:15px;">
                                                                                <label for="account-upload" class="btn btn-sm btn-relief-secondary mb-75 mr-75">Browse Photo</label>
                                                                                <button class="btn btn-sm btn-relief-secondary mb-75 mr-75">Upload</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                            <div class="row match-height">
                                                                <div class=" col-12 col-sm-6 col-md-12 col-lg-6">
                                                                    <div class="card card-transaction mb-1">
                                                                        <div class="card-body px-0">
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-primary rounded">
                                                                                        <div class="avatar-content">
                                                                                            <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">Emp ID</h6>
                                                                                        <small>{{employeeid}}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-danger rounded">
                                                                                        <div class="avatar-content">
                                                                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">Joined</h6>
                                                                                        <small>{{joiningdate}}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <mail-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></mail-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">Email</h6>
                                                                                        <small>{{email}}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-danger rounded">
                                                                                        <div class="avatar-content">
                                                                                            <em class="las la-sync"></em>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">No. Of Time Placed on Workbench</h6>
                                                                                        <small>{{workbenchcount}}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>                                                          
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                                                    <div class="card card-transaction">
                                                                        <div class="card-body px-0">
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-primary rounded">
                                                                                        <div class="avatar-content">
                                                                                            <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">Total Year Of Exp</h6>
                                                                                        <small>{{totalexp}}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-danger rounded">
                                                                                        <div class="avatar-content">
                                                                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body">
                                                                                        <h6 class="transaction-title">Birthday</h6>
                                                                                        <small>{{birthday}}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            <div class="transaction-item">
                                                                                <div class="media">
                                                                                    <div class="avatar bg-light-warning rounded">
                                                                                        <div class="avatar-content">
                                                                                            <at-sign-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></at-sign-icon>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="media-body" v-on:click="gotomyprofile">
                                                                                       <a> <h6 class="transaction-title">Report To</h6>
                                                                                        <small style="font-size:large !important;color: #7367f0;text-decoration: none;background-color: transparent;">{{reportingto}}</small></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>                                                          
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-password" role="tabpanel" aria-labelledby="account-pill-password" aria-expanded="false">
                                            
                                            <form class="validate-form" @submit.prevent="resetpassword">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="account-old-password">Old Password</label>
                                                            <div class="input-group form-password-toggle input-group-merge">
                                                                <input type="text" class="form-control" id="account-old-password" name="" placeholder="Old Password"  v-if="showPassword" v-model="auth.oldpass" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(auth.oldpass) && oldpassBlured}" v-on:blur="oldpassBlured = true"/>
                                                                <input type="password" class="form-control" id="account-old-password" name="" placeholder="Old Password"  v-else v-model="auth.oldpass" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(auth.oldpass) && oldpassBlured}" v-on:blur="oldpassBlured = true"/>
                                                                <div class="input-group-append">
                                                                    <div class="input-group-text cursor-pointer" @click="toggleShow">
                                                                        <eye-icon v-if="showPassword" size="1.5x" class="custom-class"></eye-icon>
                                                                        <eye-off-icon v-else size="1.5x" class="custom-class"></eye-off-icon>
                                                                    </div>
                                                                </div>
                                                             <div class="invalid-feedback">you need to enter a straight password contain capital & lower letters and numbers and special characters, and password length must be greater than 8 char</div>                                                             
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="account-new-password">New Password</label>
                                                            <div class="input-group form-password-toggle input-group-merge">
                                                                <input type="text" id="account-new-password" name="" class="form-control" placeholder="New Password" v-model="auth.newpass" v-if="shownewPassword" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(auth.newpass) && newpassBlured}" v-on:blur="newpassBlured = true"/>

                                                                <input type="password" id="account-new-password" name="" class="form-control" placeholder="New Password" v-model="auth.newpass" v-else v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(auth.newpass) && newpassBlured}" v-on:blur="newpassBlured = true"/>
                                                                <div class="input-group-append">
                                                                    <div class="input-group-text cursor-pointer" @click="toggleShownewpass">
                                                                        <eye-icon size="1.5x" v-if="shownewPassword" class="custom-class"></eye-icon>
                                                                        <eye-off-icon v-else size="1.5x" class="custom-class"></eye-off-icon>
                                                                    </div>
                                                                </div>
                                                                <div class="invalid-feedback">you need to enter a straight password contain capital & lower letters and numbers and special characters, and password length must be greater than 8 char</div>
                                                            </div>
                                                             
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="account-retype-new-password">Retype New Password</label>
                                                            <div class="input-group form-password-toggle input-group-merge">
                                                                <input type="text" class="form-control" id="account-retype-new-password" name="" placeholder="New Password" v-if="showoldPassword" v-model="auth.confpass" v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(auth.confpass) && confpassBlured}" v-on:blur="confpassBlured = true"/>
                                                                <input type="password" class="form-control" id="account-retype-new-password" name="" placeholder="New Password" v-model="auth.confpass" v-else v-bind:class="{'form-control':true, 'is-invalid' : !validPassword(auth.confpass) && confpassBlured}" v-on:blur="confpassBlured = true"/>
                                                                <div class="input-group-append">
                                                                    <div class="input-group-text cursor-pointer" @click="toggleold"><eye-icon v-if="showoldPassword" size="1.5x" class="custom-class"></eye-icon><eye-off-icon  v-else size="1.5x" class="custom-class"></eye-off-icon></div>
                                                                </div>
                                                                <div class="invalid-feedback">you need to enter a straight password contain capital & lower letters and numbers and special characters, and password length must be greater than 8 char</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <button type="submit" class="btn btn-relief-primary mr-1 mt-2" >Save changes</button>
                                                        <button type="reset" class="btn btn-outline-secondary mt-2">Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-info" role="tabpanel" aria-labelledby="account-pill-info" aria-expanded="false">
                                            <div class="border-bottom mb-2" style="margin:0 -1.5rem; padding: 0 0.5rem;">
                                                <h3 class="px-2  user_profile_views_titles_mb_8">
                                                    <star-icon size="1.5x" class="font-medium-3 mr-1"></star-icon>
                                                    <span class="align-middle">Experience</span>
                                                </h3>
                                            </div>
                                            <form class="validate-form">
                                                <div class="row profile_site_bar_scroll">
                                                    <div class="col-12">
                                                        <div class="card border-warning" style="margin-bottom: 1rem !important;">
                                                            <div class="card-header p-1 cursor-pointer" v-on:click="myExpCollapse" style="border-radius: 9px!important;">
                                                                <h4 class="card-title text-primary profile_text-headss_01">Add Experience</h4>
                                                                <div class="heading-elements text-primary" >
                                                                    <ul class="list-inline mb-0">
                                                                        <li>
                                                                            <a data-action="collapse"><em class="las la-angle-down"></em></a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div v-bind:class="[{show: isExpActive},card_content,collapse]">
                                                                    <div class="card-body">
                                                                <form>
                                                                        <div class="row">
                                                                        <div class="col-md-6 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Designation</label>
                                                                                <treeselect v-model="designation"  :options="designationList" @input="seldesignationdata" no-results-text="No results found... Press click on +Add" />
                                                                                <div class="cursorpointercls" @click="adddesignation">
                                                                                     <button type="button" class="btn btn-relief-success mt-1">
                                                                                    <plus-circle-icon size="1.5x" class="custom-class mr-50" ></plus-circle-icon>
                                                                                    <span>Add Manual</span>
                                                                                </button>
                                                                                </div>
                                                                                <div v-if="this.experience.showothertxt" class="pt-50">
                                                                                    <label>Other Designation</label>
                                                                                    <input type="text" class="form-control" v-model="experience.designation" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Employment Type</label>
                                                                                <select class="form-control" v-model="experience.emptype" v-bind:class="{'':true, '' : !validText(experience.emptype) && emptypeBlured}" v-on:blur="emptypeBlured = true">
                                                                                    <option value="" selected disabled >Employment Type</option>
                                                                                    <option v-bind:key="data.id" v-for="data in employemnttype" :value="data.text" > {{ `${data.text}` }}</option>       
                                                                                </select>
                                                                                <div class="errorinputmsg" v-if="!validText(experience.emptype) && emptypeBlured">Employment type is required</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Company Name</label>
                                                                                <input type="text" class="form-control"  placeholder="Company Name" v-model="experience.company" v-bind:class="{'form-control':true, '' : !validText(experience.company) && companyBlured}" v-on:blur="companyBlured = true"/>
                                                                                <div class="errorinputmsg" v-if="!validTextExp(experience.company) && companyBlured">Company name is required</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Industry Type</label>
                                                                                <input type="text" class="form-control"  placeholder="Industry Type" v-model="experience.industry" v-bind:class="{'form-control':true, '' : !validText(experience.industry) && industryBlured}" v-on:blur="industryBlured = true"/>
                                                                                <div class="errorinputmsg" v-if="!validTextExp(experience.industry) && industryBlured">Industry type is required</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group mg-bot0 col-md-6 datePicker">
                                                                            <label for="" class="d-block">Start Date</label>
                                                                            <date-picker placeholder="Select Start Date" v-model="experience.startdate" valueType="format" class="startdate" v-on:pick="validateDate('expstartdate')"></date-picker>
                                                                            <div class="errorinputmsg" v-if="(this.errors.experience.startdate)">{{ this.errors.experience.startdate }}</div>
                                                                        </div>
                                                                        <div class="form-group mg-bot0 col-md-6 datePicker">
                                                                            <label for="" class="d-block">End Date</label>
                                                                                <date-picker placeholder="Select End Date" v-model="experience.enddate" :disabled="isActiveEndDate" :disabled-date="disabledBefore" valueType="format" class="startdate" v-on:pick="validateDate('expenddate')"></date-picker>
                                                                            <div class="errorinputmsg" v-if="(this.errors.experience.enddate) && !isActiveEndDate">{{ this.errors.experience.enddate }}</div>
                                                                        </div>
                                                                        <div class="col-md-6 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Headline</label>
                                                                                <input type="text" class="form-control"  placeholder="Headline"   v-model="experience.headline"/>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-sm-12">
                                                                            <div class="form-group">
                                                                                <label>Description</label>
                                                                                <input type="text" class="form-control"  placeholder="Description"   v-model="experience.description" />
                                                                               
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6 col-sm-12">
                                                                            <div class="form-group mt-75">
                                                                                <div class="custom-control custom-control-success custom-checkbox">
                                                                                    <input type="checkbox" class="custom-control-input" name="ismastertypeOptions" id="ismastertype1" v-model="experience.isactive" checked v-on:click="onActiveselect">
                                                                                    <label class="custom-control-label" for="ismastertype1">I am currently working in this role</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 text-right">
                                                                            <button type="submit" class="btn btn-relief-primary mt-1 mr-1" v-on:click.stop.prevent="saveData('exp')" :data-id="experience.attr">Save</button>
                                                                            <button type="reset" class="btn btn-outline-secondary mt-1" v-on:click="myExpCollapse">Cancel</button>
                                                                        </div>
                                                                        </div>
                                                                </form>
                                                            </div>
                                                                    </div>
                                                        </div>
                                                        <div class="row match-height">
                                                        <div class="col-12 col-sm-6 col-md-12  col-lg-6" v-for="(data,index) in experienceList" v-bind:key="index" style="margin-top: 10px !important;">
                                                            <div class=" card-developer-meetup border-warning card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);">
                                                                <div class="rightaction text-white" @click="openEditModel(data,'exp')">
                                                                    <em class="las la-pencil-alt custom-class" style="font-size:20px;"></em>  
                                                                </div>
                                                                    <div class="card-header p-1 bg-gradient-primary rounded-bottom-0">
                                                                        <div>
                                                                            <h4 class="card-title text-white mb-0">{{data.company}}</h4>
                                                                            <small class="card-text text-white mb-0">{{data.designiation}}</small>
                                                                        </div>
                                                                    </div>
                                                                <div class="card-body pt-2">
                                                                    <div class="media">
                                                                        <div class="avatar bg-light-primary rounded mr-1">
                                                                            <div class="avatar-content">
                                                                                <briefcase-icon size="1.5x" class="custom-class"></briefcase-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div class="media-body">
                                                                             <h6 class="mb-0">{{data.industry}}</h6>
                                                                            <small>{{data.employement_type}}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-1">
                                                                        <div class="col-md-6 mobile_sm-bottom001" v-if="data.startdate">
                                                                             <div class="media">
                                                                                <div class="avatar bg-light-primary rounded mr-1">
                                                                                    <div class="avatar-content">
                                                                                        <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="media-body mt-n25">
                                                                                    <small>From</small>
                                                                                    <h6 class="mb-0">
                                                                                        {{data.startdate}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6" v-if="data.enddate">
                                                                             <div class="media">
                                                                                <div class="avatar bg-light-primary rounded mr-1">
                                                                                    <div class="avatar-content">
                                                                                        <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="media-body mt-n25">
                                                                                    <small>To</small>
                                                                                    <h6 class="mb-0">
                                                                                        {{data.enddate}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6" v-else>
                                                                             <div class="media">
                                                                                <div class="avatar bg-light-primary rounded mr-1">
                                                                                    <div class="avatar-content">
                                                                                        <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="media-body mt-25">
                                                                                    <small>Present</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="tab-pane fade " id="account-vertical-recomment" role="tabpanel" aria-labelledby="account-pill-recomment" aria-expanded="false">
                                            <div class="border-bottom mb-2" style="margin:0 -1.5rem; padding: 0 0.5rem;">
                                                <h3 class="px-2 user_profile_views_titles_mb_8">
                                                    <thumbs-up-icon size="1.5x" class="font-medium-3 mr-1"></thumbs-up-icon>
                                                    <span class="align-middle">Recommendation</span>
                                                </h3>
                                            </div>
                                            <div class="profile_site_bar_scroll">
                                              <div class="row mx-0">
                                            <form class="validate-form w-100">
                                                <div class="card border-warning mb-1"> 
                                                    <div class="card-header p-1 cursor-pointer" v-on:click="myRecCollapse" style="border-radius: 9px!important;">
                                                        <h4 class="card-title text-primary profile_text-headss_01">Add Recommendation</h4>
                                                        <div class="heading-elements text-primary" >
                                                            <ul class="list-inline mb-0">
                                                                <li>
                                                                    <a data-action="collapse"><em class="las la-angle-down"></em></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div v-bind:class="[{show: isRecActive},card_content,collapse]"  >
                                                        <div class="card-body">
                                                            <section id="card-navigation">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="card">
                                                                            <div class="pb-1">
                                                                                <ul class="nav nav-pills card-header-pills ml-0" id="pills-tab" role="tablist">
                                                                                    <li class="nav-item">
                                                                                        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="false">Ask for a recommendation</a>
                                                                                    </li>
                                                                                    <li class="nav-item">
                                                                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Give recommendation</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div class="">
                                                                                <div class="tab-content" id="pills-tabContent">
                                                                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                                                        <div class="row">
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="label-left">Search for people</label>
                                                                                                    <treeselect v-model="rec.empid"  :options="peoplelist" @input="selpeopledata"  @click="testcall" v-bind:class="{'':true, '' : !validRequest(rec.empid) && empidBlured}" v-on:blur="empidBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(rec.empid) && empidBlured">Search for people is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="label-left" >Relationship</label>
                                                                                                    <treeselect v-model="rec.relation"  :options="relationlist" @input="selrelldata"  @click="testcall" v-bind:class="{'':true, '' : !validRequest(rec.relation) && relationBlured}" v-on:blur="relationBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(rec.relation) && relationBlured">Relationship is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="label-left">Position at the time</label>
                                                                                                    <treeselect v-model="rec.position"  :options="positionlist" @input="selpositiondata"  @click="testcall" v-bind:class="{'':true, '' : !validRequest(rec.position) && positionBlured}" v-on:blur="positionBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(rec.position) && positionBlured">Position at the time is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label  class="label-left" >Include a personalized message</label>
                                                                                                    <input type="text" class="form-control" placeholder="Hi, would you write me a recommendation please?"  v-model="rec.msg" v-bind:class="{'':true, '' : !validRequest(rec.msg) && msgBlured}" v-on:blur="msgBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(rec.msg) && msgBlured">Include a personalized message is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 text-right">
                                                                                                <button type="submit" class="btn btn-relief-primary mr-1 mt-2" v-on:click.stop.prevent="saveData('rec')" :data-id="rec.attr">Save</button>
                                                                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="myRecCollapse">Cancel</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                                        <div class="row">
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="label-left">Search for people</label>
                                                                                                    <treeselect v-model="grec.empid"  :options="peoplelist" @input="selgpeopledata"  @click="testcall" v-bind:class="{'':true, '' : !validRequest(grec.empid) && grecempidBlured}" v-on:blur="grecempidBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(grec.empid) && grecempidBlured">Search for people is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="label-left" >Relationship</label>
                                                                                                    <treeselect v-model="grec.relation"  :options="relationlist" @input="selgrelldata"  @click="testcall" v-bind:class="{'':true, '' : !validRequest(grec.relation) && grecrelationBlured}" v-on:blur="grecrelationBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(grec.relation) && grecrelationBlured">Relationship is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label class="label-left" >Position at the time</label>
                                                                                                    <treeselect v-model="grec.position"  :options="positionlist" @input="selgpositiondata"  @click="testcall" v-bind:class="{'':true, '' : !validRequest(grec.position) && grecpositionBlured}" v-on:blur="grecpositionBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(grec.position) && grecpositionBlured">Position at the time is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 col-sm-6">
                                                                                                <div class="form-group">
                                                                                                    <label  class="label-left" >Add recommendation</label>
                                                                                                    <input type="text" class="form-control" placeholder="Write your recommendaion here.."  v-model="grec.msg" v-bind:class="{'':true, '' : !validRequest(grec.msg) && grecmsgBlured}" v-on:blur="grecmsgBlured = true"/>
                                                                                                    <div class="errorinputmsg" v-if="!validRequest(grec.msg) && grecmsgBlured">Include a personalized message is a required field</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 text-right">
                                                                                                <button type="submit" class="btn btn-relief-primary mr-1 mt-2" v-on:click.stop.prevent="saveData('grec')" :data-id="grec.attr">Save</button>
                                                                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="myRecCollapse">Cancel</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </form>
                                            <section id="card-navigation">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="card px-0">
                                                            <div class="card-header py-2">
                                                                <ul class="nav nav-pills card-header-pills ml-0" id="pills-tab" role="tablist">
                                                                    <li class="nav-item">
                                                                        <a  v-bind:class="[{active:rectabActive},nav_link]" id="pills-home-tab" data-toggle="pill" href="#pills-home1" role="tab" aria-controls="pills-home" aria-selected="true" @click="getRecommendationdata(1)">Received</a>
                                                                    </li>
                                                                    <li class="nav-item">
                                                                        <a v-bind:class="[{active:grectabActive},nav_link]" id="pills-profile-tab" data-toggle="pill" href="#pills-profile1" role="tab" aria-controls="pills-profile" aria-selected="false" @click="getRecommendationdata(2)">Given</a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="card-body px-0">
                                                                <div class="tab-content" id="pills-tabContent">
                                                                    <div v-bind:class="[{fade:recfade,show:recommendActive,active:recActive},tab_pane]" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab">
                                                                        <div class="employee-task border-warning-0 p-1 rounded b_bg-dis_row5"  v-for="data in recdata" v-bind:key="data.id" style="margin-bottom:10px;margin-top:10px">
                                                                            <div class="media">
                                                                                <div class="avatar mr-75" style="margin-top:10px;margin-bottom:10px;margin-left:10px;">
                                                                                    <img alt="" v-if="data.imagename" :src="data.imagename"  width="100" height="100"/>
                                                                                    <img  alt="" v-else :src="defaultimagepath" width="100" height="100"/>
                                                                                </div>
                                                                                <div class="media-body my-auto" style="text-align:left !important;">
                                                                                    <h6 class="mb-0" >{{data.empname.toUpperCase()}}</h6>
                                                                                    <small>{{decryptText(data.designation)}}</small>
                                                                                    <p>{{data.createddate}} {{data.firstname}} {{data.relation}}</p>
                                                                                    <p>{{data.message}}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div  v-if="recdata.length==0" class="employee-task d-flex justify-content-between align-items-center">
                                                                            <div class="media">
                                                                                <div class="media-body my-auto">
                                                                                    <h6 class="mb-0">You haven't received a recommendation yet</h6>
                                                                                    <small>Try asking one of your connections to recommend you</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div v-bind:class="[{fade:grecfade,show:grecommendActive,active:grecActive},tab_pane]" id="pills-profile1" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                                        <div class="employee-task border-warning"  v-for="data in recdata" v-bind:key="data.id" style="margin-bottom:10px;margin-top:10px">
                                                                            <div class="media pt-1 px-1">
                                                                                <div class="avatar mr-75" style="margin-top:10px;margin-bottom:10px;margin-left:10px;">
                                                                                    <img alt="" v-if="data.imagename" :src="data.imagename"  width="100" height="100"/>
                                                                                    <img alt="" v-else :src="defaultimagepath"  width="100" height="100"/>
                                                                                </div>
                                                                                <div class="media-body my-auto" style="text-align:left !important;">
                                                                                    <h6 class="mb-0" >{{data.empname.toUpperCase()}}</h6>
                                                                                    <small>{{data.designation.toUpperCase()}}</small>
                                                                                    <p>{{data.createddate}} {{data.firstname}} {{data.relation}}</p>
                                                                                    <p>{{data.message}}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="recdata.length==0"  class="employee-task d-flex justify-content-between align-items-center">
                                                                            <div class="media">
                                                                                <div class="media-body my-auto">
                                                                                    <h6 class="mb-0">You haven't given a recommendation yet</h6>
                                                                                    <small>Try one of your connection to recommend</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-info-edu" role="tabpanel" aria-labelledby="account-pill-info-edu" aria-expanded="false">
                                            <div class="border-bottom mb-2" style="margin:0 -1.5rem; padding: 0 0.5rem;">
                                                <h3 class="px-2 user_profile_views_titles_mb_8">
                                                    <book-open-icon size="1.5x" class="font-medium-3 mr-1"></book-open-icon>
                                                    <span class="align-middle">Education</span>
                                                </h3>
                                            </div>
                                                <div class="row profile_site_bar_scroll" >
                                                    <div class="col-12">
                                                        <form class="validate-form">
                                                        <div class="card border-warning" style=" margin-bottom: 1rem !important;">
                                                            <div class="card-header p-1 cursor-pointer" v-on:click="myEduCollapse" style="border-radius: 9px!important;">
                                                                <h4 class="card-title text-primary profile_text-headss_01">Add Education</h4>
                                                                <div class="heading-elements text-primary" >
                                                                    <ul class="list-inline mb-0">
                                                                        <li>
                                                                            <span data-action="collapse"><em class="las la-angle-down"></em></span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div v-bind:class="[{show: isEduActive},card_content,collapse]"  >
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                    <div class="col-md-6 col-sm-12">
                                                                        <div class="form-group">
                                                                            <label>College / University Name</label>
                                                                            <treeselect  placeholder="Enter Min 3 Char to search your college/university" v-on:search-change="uniChange" v-model="education.univercity" :options="univercityList" @input="selunidata" @search-change="handleSearch" no-results-text="No results found... Press click on +Add" v-bind:class="{'':true, '' : !validText(education.univercity) && univercityBlured}" v-on:blur="univercityBlured = true"/>
                                                                            <div class="cursorpointercls" @click="appendNewItem">
                                                                                <button type="button" class="btn btn-relief-success mt-1">
                                                                                    <plus-circle-icon size="1.5x" class="custom-class mr-50" ></plus-circle-icon>
                                                                                    <span>Add Manual</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="pt-50" v-if="this.education.showothertxt">
                                                                                <label>Other College / University Name</label>
                                                                                <input type="text" class="form-control" v-model="education.otheruniversity" />
                                                                            </div>                                                                            
                                                                            <div class="errorinputmsg" v-if="!validText(education.univercity) && univercityBlured">University required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12">
                                                                        <div class="form-group">
                                                                            <label>Degree</label>
                                                                            <treeselect placeholder="Select your degree" v-model="education.degree"  :options="degreeList" @input="seldegreedata"  @click="testcall" />
                                                                            <div class="cursorpointercls" @click="appendDegree">
                                                                                <button type="button" class="btn btn-relief-success mt-1">
                                                                                    <plus-circle-icon size="1.5x" class="custom-class mr-50" ></plus-circle-icon>
                                                                                    <span>Add Manual</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="row pt-50"  v-if="this.education.showotherdegree">
                                                                                <div class="col-4 col-sm-12">
                                                                                    <label>Other Degree</label>
                                                                                    <div class="input-group">
                                                                                    <input type="text" class="form-control" v-model="education.otherdegree" v-bind:class="{'':true, '' : !validText(education.otherdegree) && otherdegreeBlured}" v-on:blur="otherdegreeBlured = true"/>
                                                                                        <div class="input-group-append" >
                                                                                        <button class="btn btn-outline-primary waves-effect" type="button" @click="createDegree"><save-icon size="1.5x" class="mr-50"></save-icon><span>Save</span></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="!validText(education.otherdegree) && otherdegreeBlured">Degree required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12">
                                                                        <div class="form-group">
                                                                            <label>Field of Study</label>
                                                                            <treeselect placeholder="Select your field of study" v-model="education.filedofstudy"  :options="filedofstudyList" @input="selfileddata"  @click="testcall" />
                                                                            <div class="cursorpointercls" @click="appendfieldofstudy">
                                                                                 <button type="button" class="btn btn-relief-success mt-1">
                                                                                    <plus-circle-icon size="1.5x" class="custom-class mr-50" ></plus-circle-icon>
                                                                                    <span>Add Manual</span>
                                                                                </button>
                                                                            </div>
                                                                            <div class="row pt-50"  v-if="this.education.showotherfield">
                                                                                <div class="col-4 col-sm-12">
                                                                                    <label>Other Field of Study</label>
                                                                                    <div class="input-group">
                                                                                        <input type="text" class="form-control" v-model="education.otherfieldofstudy" aria-describedby="button-saveaddon2" v-bind:class="{'':true, '' : !validText(education.otherfieldofstudy) && otherfieldofstudyBlured}" v-on:blur="otherfieldofstudyBlured = true">
                                                                                        <div class="input-group-append" id="button-saveaddon2">
                                                                                        <button class="btn btn-outline-primary waves-effect" type="button" @click="createFieldofStudty"><save-icon size="1.5x" class="mr-50"></save-icon><span>Save</span></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="!validText(education.otherfieldofstudy) && otherfieldofstudyBlured">Field of study required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-12">
                                                                        <div class="form-group">
                                                                            <label>Grade</label>
                                                                            <input type="text" class="form-control"  placeholder="Entet your grade"  v-model="education.grade" v-bind:class="{'':true, '' : !validText(education.grade) && gradeBlured}" v-on:blur="gradeBlured = true"/>
                                                                            <div class="errorinputmsg" v-if="!validText1(education.grade) && gradeBlured">Grade is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group mg-bot0 col-md-6 datePicker">
                                                                        <label for="" class="d-block">Start Date</label>
                                                                        <date-picker placeholder="Select Start Date" v-model="education.startdate" valueType="format" class="startdate" v-on:pick="validateDate('educationstartdate')"></date-picker>
                                                                        <div class="errorinputmsg" v-if="(this.errors.education.startdate)">{{ this.errors.education.startdate }}</div>
                                                                    </div>
                                                                    <div class="form-group mg-bot0 col-md-6 datePicker">
                                                                        <label for="" class="d-block">End Date</label>
                                                                        <date-picker placeholder="Select End Date" v-model="education.enddate" valueType="format" class="startdate" v-on:pick="validateDate('educationenddate')"></date-picker>
                                                                        <div class="errorinputmsg" v-if="(this.errors.education.enddate)">{{ this.errors.education.enddate }}</div>
                                                                    </div>
                                                                     <div class="col-12 text-right">
                                                                        <button type="submit" class="btn btn-relief-primary mt-1 mr-1" v-on:click.stop.prevent="saveData('edu')" :data-id="education.attr">Save</button>
                                                                        <button type="reset" class="btn btn-outline-secondary mt-1" v-on:click="myEduCollapse">Cancel</button>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </form>
                                                        <div class="row match-height">
                                                        <div class="col-sm-12 col-md-12  col-lg-6" v-for="data in educationList" v-bind:key="data.educationid" style="margin-top:15px !important;">
                                                            <div  class=" card-developer-meetup border-warning card mb-2" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);overflow:hidden;">
                                                            <div class="rightaction text-white" @click="openEditModel(data,'edu')">
                                                                    <em class="las la-pencil-alt custom-class" style="font-size:20px;"></em>
                                                                </div>
                                                                    <div class="card-header p-1 bg-gradient-primary rounded-bottom-0">
                                                                        <div class="my-auto">
                                                                            <h4 class="card-title mb-0 text-white">{{data.school}}</h4>
                                                                        </div>
                                                                    </div>

                                                                <div class="card-body pt-2">
                                                                     <div class="media">
                                                                        <div class="avatar bg-light-secondary rounded mr-1">
                                                                            <div class="avatar-content">
                                                                                <book-open-icon size="1.5x" class="custom-class"></book-open-icon>
                                                                            </div>
                                                                        </div>
                                                                        <div class="media-body">
                                                                             <h6 class="mb-0">{{data.degree}}</h6>
                                                                            <small>{{data.field_of_study}}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-1 mb-1">
                                                                        <div class="col-md-6" v-if="data.startdate">
                                                                             <div class="media">
                                                                                <div class="avatar bg-light-primary rounded mr-1">
                                                                                    <div class="avatar-content">
                                                                                        <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="media-body mt-n25">
                                                                                    <small>From</small>
                                                                                    <h6 class="mb-0">
                                                                                        {{data.startdate}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6" v-if="data.enddate">
                                                                             <div class="media">
                                                                                <div class="avatar bg-light-primary rounded mr-1">
                                                                                    <div class="avatar-content">
                                                                                        <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="media-body mt-n25">
                                                                                    <small>To</small>
                                                                                    <h6 class="mb-0">
                                                                                        {{data.enddate}}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-social" role="tabpanel" aria-labelledby="account-pill-social" aria-expanded="false" >
                                            <div class="border-bottom mb-2" style="margin:0 -1.5rem; padding: 0 0.5rem;">
                                                <h3 class="px-2 user_profile_views_titles_mb_8">
                                                    <link-icon size="1.5x" class="font-medium-3 mr-1"></link-icon>
                                                    <span class="align-middle">Skills</span>
                                                </h3>
                                            </div>
                                            <div class="profile_site_bar_scroll">
                                            <div class="row mx-0">
                                            <form class="validate-form w-100">
                                                <div class="card border-warning" style="margin-bottom: 0rem !important;"> 
                                                    <div class="card-header p-1 cursor-pointer" v-on:click="mySkillCollapse" style="border-radius: 9px!important;">
                                                        <h4 class="card-title text-primary profile_text-headss_01">Add Skills</h4>
                                                        <div class="heading-elements text-primary" >
                                                            <ul class="list-inline mb-0">
                                                                <li>
                                                                    <a data-action="collapse"><em class="las la-angle-down"></em></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div v-bind:class="[{show: isSkillActive},card_content,collapse]"  >
                                                        <div class="card-body">
                                                        <div class="row ">
                                                            <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                                                <div class="form-group">
                                                                    <label for="">Skills</label>
                                                                    <treeselect v-model="skilllist" placeholder="Select your skills" :options="skilllistoptions" @input="selskilldata" no-results-text="No results found... Press click on +Add" />
                                                                    <div class="cursorpointercls" @click="addnewskill">
                                                                        <button type="button" class="btn btn-relief-success mt-1">
                                                                            <plus-circle-icon size="1.5x" class="custom-class mr-50" ></plus-circle-icon>
                                                                            <span>Add Manual</span>
                                                                        </button>
                                                                    </div>
                                                                    <div v-if="this.skills.showothertxt">
                                                                        <label>Other Skills</label>
                                                                        <input type="text" class="form-control" v-model="skills.otherskilllist" />
                                                                    </div>
                                                                    <div class="errorinputmsg" v-if="this.errors.skills.otherskilllist">{{ this.errors.skills.otherskilllist }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6 col-md-12 col-lg-6">
                                                                <div class="form-group numricInput inputyearofexp">
                                                                    <label>Year Of Experience</label>
                                                                    <vue-numeric-input v-model="skills.year"  :min="0.0" :max="40" color="#ad3af9" center=true  size="large" empty-value="0.00" :step="0.1" v-bind:precision="2"  inline controls rounded style="text-align: center !important;" ></vue-numeric-input>
                                                                     <div class="errorinputmsg" v-if="this.errors.skills.year">{{ this.errors.skills.year }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 text-right">
                                                                <button type="submit" class="btn btn-relief-primary mr-1 mt-2" v-on:click.stop.prevent="saveData('skills')" :data-id="skills.attr">Save changes</button>
                                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="mySkillCollapse">Cancel</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        
                                                    </div>  
                                                </div>
                                            </form>
                                            <section class="w-100 mt-2">
                                                <div class="row mx-0 profile_skills_main-div">
                                                    <div class="col-12 col-sm-6 col-md-12 col-lg-4 "  v-if="topskills">
                                                        <div class="card border-warning p-1">
                                                        <h6 class="mb-2 skills_list_view01_bottom">Top Skills</h6>
                                                        <div class="card  mb-0">
                                                            <ul class="list-group list-group-flush skills_list_view01">
                                                                <div  v-for="data in topskills" v-bind:key="data.id">
                                                                    <li class="list-group-item">{{data.skills}} - {{data.yearofexp}} Yrs
                                                                       
                                                                        <em class="las la-times custom-class delicon mt-25 text-danger rounded" @click="removeskills(data.id)"></em>
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-12 col-lg-4" v-if="indkills">
                                                    <div class="card border-warning p-1">
                                                        <h6 class="mb-2 skills_list_view01_bottom">Industry Knowldge</h6>
                                                        <div class="card mb-0" >
                                                            <ul class="list-group list-group-flush skills_list_view01">
                                                                <div  v-for="data in indkills" v-bind:key="data.id">
                                                                <li class="list-group-item">{{data.skills}} - {{data.yearofexp}} Yrs
                                                                    <em size="4x" class="las la-times  custom-class delicon mt-25 text-danger rounded" @click="removeskills(data.id)"></em>
                                                                </li>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                     </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-12 col-lg-4" v-if="techkills">
                                                    <div class="card border-warning p-1">
                                                        <h6 class="mb-2 skills_list_view01_bottom">Tools & Technologies</h6>
                                                        <div class="card mb-0">
                                                            <ul class="list-group list-group-flush skills_list_view01">
                                                                <div  v-for="data in techkills" v-bind:key="data.id">
                                                                    <li class="list-group-item">{{data.skills}} - {{data.yearofexp}} Yrs
                                                                        <em size="4x" class="las la-times  custom-class delicon mt-25 text-danger rounded" @click="removeskills(data.id)"></em>
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                </div>
                                            </section>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-social-awards" role="tabpanel" aria-labelledby="account-pill-social-awards" aria-expanded="false">
                                             <div class="border-bottom mb-2" style="margin:0 -1.5rem; padding: 0 0.5rem;">
                                                <h3 class="px-2 user_profile_views_titles_mb_8">
                                                    <award-icon size="1.5x" class="font-medium-3 mr-1"></award-icon>
                                                    <span class="align-middle">Honors and Awards</span>
                                                </h3>
                                            </div>
                                            <div class="profile_site_bar_scroll">
                                            <div class="row mx-0">
                                            <form class="validate-form w-100">
                                                <div class="card border-warning" style=" margin-bottom: 1rem !important;">
                                                    <div class="card-header p-1 cursor-pointer" v-on:click="myAwardCollapse" style="border-radius: 9px!important;">
                                                        <h4 class="card-title text-primary profile_text-headss_01">Add Honors and Awards</h4>
                                                        <div class="heading-elements text-primary" >
                                                            <ul class="list-inline mb-0">
                                                                <li>
                                                                    <a data-action="collapse"><em class="las la-angle-down"></em></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div v-bind:class="[{show: isAwardActive},card_content,collapse]"  >
                                                        <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-twitter">Title</label>
                                                                    <input type="text" class="form-control" placeholder="Title" value="" v-model="awards.title" v-bind:class="{'form-control':true, '' : !validText(awards.title) && awardtitleBlured}" v-on:blur="awardtitleBlured = true"/>
                                                                    <div class="errorinputmsg" v-if="!validText(awards.title) && awardtitleBlured">Title must be  greater than 5 char</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-facebook">Associated with</label>
                                                                    <input type="text" class="form-control" placeholder="Associated with" v-model="awards.association"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-google">Issuer</label>
                                                                    <input type="text" class="form-control" placeholder="Issuer" v-model="awards.issuer" v-bind:class="{'':true, '' : !validRequest(awards.issuer) && issuerBlured}" v-on:blur="issuerBlured = true"/>
                                                                    <div class="errorinputmsg" v-if="!validRequest(awards.issuer) && issuerBlured">Issuer required</div>
                                                                </div>
                                                            </div>
                                                             <div class="col-6 col-sm-6">
                                                                <div class="form-group datePicker">
                                                                    <label>Issue Date</label>
                                                                        <date-picker placeholder="Select issue date" valueType="format" class="startdate" v-model="awards.issuedate" v-bind:class="{'':true, '' : !validRequest(awards.issuedate) && awardsBlured}" v-on:blur="awardsBlured = true"></date-picker>
                                                                        <div class="errorinputmsg" v-if="!validRequest(awards.issuedate) && awardsBlured">Issue date required</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 col-sm-6">
                                                                <div class="form-group">
                                                                    <label>Description</label>
                                                                    <input type="text" class="form-control"  placeholder="Description"   v-model="awards.description"/>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <button type="submit" class="btn btn-primary mr-1 mt-2" v-on:click.stop.prevent="saveData('awards')" :data-id="awards.attr">Save changes</button>
                                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="myAwardCollapse">Cancel</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </form>
                                            <section id="card-style-variation">
                                                <div class="row">
                                                <div class="col-md-12 col-xl-12" v-for="data in awardList" v-bind:key="data.awardsid">
                                                    <div class="card shadow-none bg-transparent border-warning">
                                                        <div class="rightaction text-white" @click="openEditModel(data,'awards')">
                                                                    <em class="las la-pencil-alt custom-class" style="font-size:20px;"></em>
                                                            </div>
                                                            <div class="card-header p-1 rounded-bottom-0 bg-gradient-primary">
                                                                <h4 class="card-title text-white">{{data.title}}</h4>
                                                            </div>
                                                        <div class="card-body pt-2">
                                                            <p class="card-text"> Issued by {{data.issuer}}</p>
                                                            <p v-if="data.associated" class="card-text"> Associated with {{data.associated}}</p>
                                                            <p v-if="data.description" class="card-text">{{data.description}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </section>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-social-courses" role="tabpanel" aria-labelledby="account-pill-social-courses" aria-expanded="false">
                                            <div class="border-bottom mb-2" style="margin:0 -1.5rem; padding: 0 0.5rem;">
                                                <h3 class="px-2 user_profile_views_titles_mb_8">
                                                    <pen-tool-icon size="1.5x" class="font-medium-3 mr-1"></pen-tool-icon>
                                                    <span class="align-middle">Courses</span>
                                                </h3>
                                            </div>
                                            <div class="profile_site_bar_scroll">
                                            <div class="" >
                                            <form class="validate-form">
                                                <div class="card border-warning shadow-none" style=" margin-bottom: 1rem !important;">
                                                    <div class="card-header p-1 cursor-pointer " v-on:click="myCourseCollapse" style="border-radius: 9px!important;">
                                                        <h4 class="card-title text-primary profile_text-headss_01">Add Courses</h4>
                                                        <div class="heading-elements text-primary" >
                                                            <ul class="list-inline mb-0">
                                                                <li>
                                                                    <a data-action="collapse"><em class="las la-angle-down"></em></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div v-bind:class="[{show: isCourseActive},card_content,collapse]"  >
                                                        <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-twitter">Course Name</label>
                                                                    <input type="text" class="form-control" placeholder="Course Name"  v-model="course.name" v-bind:class="{'form-control':true, '' : !validText(course.name) && coursesnameBlured}" v-on:blur="coursesnameBlured = true"/>
                                                                    <div class="errorinputmsg" v-if="!validText(course.name) && coursesnameBlured">Course name must be  greater than 5 char</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-facebook">Course Number</label>
                                                                    <input type="text" class="form-control" placeholder="Course Number" v-model="course.number"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-google">Associated With</label>
                                                                    <input type="text" class="form-control" placeholder="Associated With" v-model="course.association"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <button type="submit" class="btn btn-relief-primary mr-1 mt-2" v-on:click.stop.prevent="saveData('course')" :data-id="course.attr">Save changes</button>
                                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="myCourseCollapse">Cancel</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </form>
                                            <section id="card-style-variation">
                                                <div class="row match-height">
                                                <div class="col-md-6 col-xl-6" v-for="data in courseList" v-bind:key="data.courseid">
                                                    <div class="card shadow-none bg-transparent border-warning">
                                                        <div class="rightaction text-white" @click="openEditModel(data,'course')">
                                                            <em class="las la-pencil-alt custom-class" style="font-size:20px;"></em>
                                                        </div>
                                                        <div class="card-header p-1 bg-gradient-primary rounded-bottom-0">
                                                            <h4 class="card-title text-white">{{data.course_name}}</h4>
                                                        </div>
                                                        <div class="card-body pt-2">
                                                            <div class="media mb-1" v-if="data.associated_with">
                                                                <div class="avatar bg-light-primary rounded mr-1">
                                                                    <div class="avatar-content">
                                                                        <pen-tool-icon size="1.5x" class="custom-class"></pen-tool-icon>
                                                                    </div>
                                                                </div>
                                                                <div class="media-body mt-n25">
                                                                    <small>Associated with</small>
                                                                    <h6 class="mb-0">
                                                                        {{data.associated_with}}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div class="media" v-if="data.coursenum">
                                                                <div class="avatar bg-light-primary rounded mr-1">
                                                                    <div class="avatar-content">
                                                                        <globe-icon size="1.5x" class="custom-class"></globe-icon>
                                                                    </div>
                                                                </div>
                                                                <div class="media-body mt-n25">
                                                                    <small>Course Number</small>
                                                                    <h6 class="mb-0">
                                                                        {{data.coursenum}}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </section>
                                          </div>
                                         </div>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-social-certificate" role="tabpanel" aria-labelledby="account-pill-social-certificate" aria-expanded="false">
                                            <div class="border-bottom mb-2" style="margin:0 -1.5rem; padding: 0 0.5rem;">
                                                <h3 class="px-2 user_profile_views_titles_mb_8">
                                                    <check-circle-icon size="1.5x" class="font-medium-3 mr-1"></check-circle-icon>
                                                    <span class="align-middle">Certification</span>
                                                </h3>
                                            </div>
                                            <div class="profile_site_bar_scroll">
                                            <div class=" mr-1">
                                            <form class="validate-form">
                                                <div class="card border-warning" style="margin-bottom: 1rem !important;">
                                                    <div class="card-header p-1 cursor-pointer"  v-on:click="myCollapse" style="border-radius: 9px!important;">
                                                        <h4 class="card-title text-primary profile_text-headss_01">Add Certification</h4>
                                                        <div class="heading-elements text-primary">
                                                            <ul class="list-inline mb-0">
                                                                <li>
                                                                    <a data-action="collapse"><em class="las la-angle-down"></em></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div v-bind:class="[{show: isActive},card_content,collapse]"  >
                                                        <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-twitter">Certificate Name</label>
                                                                    <input type="text" class="form-control" placeholder="Certificate Name" value="" v-model="certificate.name" v-bind:class="{'form-control':true, '' : !validRequest(certificate.name) && certificatenameBlured}" v-on:blur="certificatenameBlured = true"/>
                                                                    <div class="errorinputmsg" v-if="!validRequest(certificate.name) && certificatenameBlured">Certificate name required</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-facebook">Issuing Organization</label>
                                                                    <input type="text" class="form-control" placeholder="Certificate Number" v-model="certificate.issuer" v-bind:class="{'form-control':true, '' : !validRequest(certificate.issuer) && certificateissuerBlured}" v-on:blur="certificateissuerBlured = true"/>
                                                                    <div class="errorinputmsg" v-if="!validRequest(certificate.issuer) && certificateissuerBlured">Issuing organization required</div>
                                                                </div>
                                                            </div>
                                                             <div class="col-12 col-sm-6 col-md-6">
                                                                <div class="form-group datePicker">
                                                                    <label>Issue Date</label>
                                                                    <date-picker placeholder="Select issue Date" v-model="certificate.issuedate" valueType="format" class="startdate" v-on:pick="validateDate('issuerdate')"></date-picker>
                                                                    <div class="errorinputmsg" v-if="(this.errors.certificate.issuedate)">{{ this.errors.certificate.issuedate }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6 col-md-6">
                                                                <div class="form-group datePicker">
                                                                    <label>Expiration Date</label>
                                                                        <date-picker placeholder="Select expiration Date" v-model="certificate.expirydate" valueType="format" class="startdate" v-on:pick="validateDate('expirydate')"></date-picker>
                                                                        <div class="errorinputmsg" v-if="(this.errors.certificate.expirydate)">{{ this.errors.certificate.expirydate }}</div>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-google">Credential ID</label>
                                                                    <input type="text" class="form-control" placeholder="Credential ID" v-model="certificate.credentialid"/>
                                                                </div>
                                                            </div>
                                                             <div class="col-12 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="account-google">Credential URL</label>
                                                                    <input type="text" class="form-control" placeholder="Credential URL" v-model="certificate.credentialurl"/>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-sm-12 text-right">
                                                                <button type="submit" class="btn btn-relief-primary mr-1 mt-2" v-on:click.stop.prevent="saveData('certificate')" :data-id="certificate.attr">Save changes</button>
                                                                <button type="reset" class="btn btn-outline-secondary mt-2"  v-on:click="myCollapse">Cancel</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>  
                                                </div>
                                            </form>
                                            <section id="card-style-variation">
                                                <div class="row match-height">
                                                <div class="col-12 col-sm-6 col-md-12 col-lg-6" v-for="data in certificateList" v-bind:key="data.certificationid">
                                                    <div class="card shadow-none bg-transparent border-warning">
                                                        <div class="rightaction text-white" @click="openEditModel(data,'certificate')">
                                                            <em class="las la-pencil-alt custom-class" style="font-size:20px;"></em>
                                                        </div>
                                                        <div class="card-header p-1 bg-gradient-primary rounded-bottom-0">
                                                            <h4 class="card-title text-white">{{data.title}} &nbsp;</h4>
                                                        </div>
                                                        <div class="card-body pt-2">
                                                            <div class="media mb-1" v-if="data.issuer">
                                                            <div class="avatar bg-light-primary rounded mr-1">
                                                                <div class="avatar-content">
                                                                    <book-open-icon size="1.5x" class="custom-class"></book-open-icon>
                                                                </div>
                                                            </div>
                                                            <div class="media-body">
                                                                <small>Issued by</small>
                                                                    <h6 class="mb-0">{{data.issuer}}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="media mb-1" v-if="data.credentialid">
                                                            <div class="avatar bg-light-primary rounded mr-1">
                                                                <div class="avatar-content">
                                                                    #
                                                                </div>
                                                            </div>
                                                            <div class="media-body">
                                                                <small>Credential ID</small>
                                                                    <h6 class="mb-0">{{data.credentialid}}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="media mb-1" v-if="data.credentialurl">
                                                            <div class="avatar bg-light-primary rounded mr-1">
                                                                <div class="avatar-content">
                                                                    <link-icon size="1.5x" class="custom-class"></link-icon>
                                                                </div>
                                                            </div>
                                                            <div class="media-body">
                                                                <small>Credential URL</small>
                                                                    <h6 class="mb-0">{{data.credentialurl}}</h6>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6 mobile_sm-bottom001" v-if="data.issuedate">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-primary rounded mr-1">
                                                                        <div class="avatar-content">
                                                                            <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body mt-n25">
                                                                        <small>From</small>
                                                                        <h6 class="mb-0">
                                                                            {{data.issuedate}}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6" v-if="data.expiredate">
                                                                    <div class="media">
                                                                    <div class="avatar bg-light-primary rounded mr-1">
                                                                        <div class="avatar-content">
                                                                            <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body mt-n25">
                                                                        <small>To</small>
                                                                        <h6 class="mb-0">
                                                                            {{data.expiredate}}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </section>
                                          </div>
                                        </div>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-notifications" role="tabpanel" aria-labelledby="account-pill-notifications" aria-expanded="false">
                                            <div class="row">
                                                <h6 class="section-label mx-1 mb-2">Activity</h6>
                                                <div class="col-12 mb-2">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" checked id="accountSwitch1" />
                                                        <label class="custom-control-label" for="accountSwitch1">
                                                            Email me when someone comments onmy article
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-2">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" checked id="accountSwitch2" />
                                                        <label class="custom-control-label" for="accountSwitch2">
                                                            Email me when someone answers on my form
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-2">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" id="accountSwitch3" />
                                                        <label class="custom-control-label" for="accountSwitch3">Email me hen someone follows me</label>
                                                    </div>
                                                </div>
                                                <h6 class="section-label mx-1 mt-2">Application</h6>
                                                <div class="col-12 mt-1 mb-2">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" checked id="accountSwitch4" />
                                                        <label class="custom-control-label" for="accountSwitch4">News and announcements</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-2">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" checked id="accountSwitch6" />
                                                        <label class="custom-control-label" for="accountSwitch6">Weekly product updates</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-75">
                                                    <div class="custom-control custom-switch">
                                                        <input type="checkbox" class="custom-control-input" id="accountSwitch5" />
                                                        <label class="custom-control-label" for="accountSwitch5">Weekly blog digest</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button type="submit" class="btn btn-primary mt-2 mr-1">Save changes</button>
                                                    <button type="reset" class="btn btn-outline-secondary mt-2">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="account-vertical-krakpi" role="tabpanel" aria-labelledby="account-pill-krakpi" aria-expanded="false">
                                            <section id="card-style-variation">
                                                <div class="" style="margin: 0 -1.5rem 0rem; min-height:50vh;">
                                                    <h3 class="px-2 pb-2 mb-2 border-bottom">
                                                        <trending-up-icon size="1.5x" class="font-medium-3 mr-1"></trending-up-icon>
                                                        <span class="align-middle">My KRA-KPI</span>
                                                    </h3>
                                                    <div class="profile_site_bar_scroll">
                                                     <div class=" mx-1">
                                                    <div class="d-block ">
                                                        <div class="col-sm-12">
                                                        <ul class="p-0 m-0" style="list-style-type:none">
                                                            <li class="border rounded mb-1 text-white"  v-for="(data,index) in performance.performancelist" v-bind:key="index" v-bind:class="getColours(index)">
                                                                <div class="d-flex">
                                                                    <div class="p-1 pageNumb border-right" >
                                                                        <span class="align-middle">
                                                                            {{ index+1+currPageNum*pagelimit }}
                                                                        </span>
                                                                    </div>
                                                                    <div style="width: calc(100% - 50px)">
                                                                        <div class="d-flex border-bottom">
                                                                            <div class="py-50 pl-1 pr-50">
                                                                                <strong>KRA</strong>                                                                                
                                                                            </div>
                                                                            <div class="p-50">
                                                                                {{data.kra}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="d-inline-flex">
                                                                            <div class="py-50 pl-1 pr-50">
                                                                                <strong>KPI</strong>                                                                                
                                                                            </div>
                                                                            <div class="p-50">
                                                                                {{data.kpi}}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>
                                                        </ul>

                                                        </div>



                                                        <div class="not_found m-1" v-if="performance.performancelist.length==0">No record found</div>
                                                    </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </section>
                                        </div>

                                        <div class="tab-pane fade" id="account-vertical-exam" role="tabpanel" aria-labelledby="account-pill-exam" aria-expanded="false">
                                            <section id="card-style-variation">
                                                <div class="" style="margin: 0 -1.5rem 0rem; min-height:50vh;">
                                                    <h3 class="px-2 pb-2 mb-2 border-bottom">
                                                    <clipboard-icon size="1.5x" class="font-medium-3 mr-1"></clipboard-icon>

                                                        <span class="align-middle">Assessment</span>
                                                    </h3>                                                    
                                                    <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="card text-center mb-3">
                                                        <div class="">
                                                            <div class="tab-content" id="myTabContent">
                                                            <div
                                                                class="tab-pane fade show active"
                                                                id="home"
                                                                role="tabpanel"
                                                                aria-labelledby="home-tab"
                                                            >
                                                                <div class="row" id="table-small">
                                                                <div class="col-12">
                                                                    <div class="card">
                                                                    <div class="">
                                                                        <div class="row">
                                                                        <div class="col-md-10">
                                                                            <div class="input-group-append"></div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="table-responsive"
                                                                    >
                                                                        <table
                                                                        class="
                                                                            table table-sm
                                                                            dataTableListing
                                                                            table-hover table-striped
                                                                        " aria-describedby="mydesc"
                                                                        >
                                                                        <thead>
                                                                            <tr>
                                                                            <th scope="col" class="srno align-middle">Sr#</th>
                                                                            <th scope="col" class="align-middle text-left">Course Name</th>
                                                                            <th scope="col" class="align-middle text-left">Periodic Name</th>
                                                                            <th scope="col" class="align-middle text-left">Assignment Title</th>
                                                                            <th scope="col" class="align-middle">Course Status</th>
                                                                            <!-- <th scope="col" class="align-middle">Exam Attempted</th> -->
                                                                            <th scope="col" class="align-middle">Result</th>
                                                                            <th scope="col" class="align-middle">Marks</th>
                                                                            <th scope="col" class="align-middle">View Submitted Answers</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <template  v-for="(data, index) in examlist">
                                                                            <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)">
                                                                                <td class="srno">{{ index+1+currPageNum*pagelimit }}</td>
                                                                                <td class="text-left">
                                                                                <span class="font-weight-normal capitalisetext">{{data.coursename}}</span>
                                                                                </td>
                                                                                <td class="text-left">
                                                                                <span v-if="data.periodicname" class="font-weight-normal capitalisetext">{{data.periodicname}}</span>
                                                                                <span v-else class="font-weight-normal capitalisetext">NA</span>
                                                                                </td>
                                                                                <td class="text-left">
                                                                                <span class="font-weight-normal capitalisetext">{{data.assessmenttitle}}</span>
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <div v-if="data.iscompleted==true" class="badge badge-pill badge-light-success mr-50"> Completed</div>
                                                                                    <div v-if="data.iscompleted==false" class="badge badge-pill badge-light-warning mr-50"> Incomplete</div>
                                                                                </td>
                                                                                <!-- <td class="text-nowrap text-center">
                                                                                    <div v-if="data.results" class="badge badge-pill badge-light-success mr-50">Yes</div>
                                                                                    <div v-if="!data.results" class="badge badge-pill badge-light-warning mr-50">No</div>
                                                                                </td> -->
                                                                                <td class="text-center">
                                                                                    <div v-if="data.results=='pass'" class="capitalisetext  badge badge-pill badge-light-success mr-50"> Pass</div>
                                                                                    <div v-if="data.results=='fail'" class="capitalisetext  badge badge-pill badge-light-warning mr-50"> Failed</div>
                                                                                    <div v-if="data.results=='not attempted'" class="capitalisetext  badge badge-pill badge-light-danger mr-50"> Not Attempted</div>
                                                                                </td>
                                                                                <td class="text-nowrap text-center">{{data.totalcorrectans}}</td>
                                                                                <td>
                                                                                    <div v-if="data.results!='not attempted'" class="d-flex align-items-center">
                                                                                        <button type="button" clsss="btn btn-primary mr-1 mt-2" style="background: transparent;;border-color: #4839EB;color: #7367f0 !important;box-shadow: none;border: none;margin:0px auto;">
                                                                                            <eye-icon size="1.5x" class="custom-class" v-on:click="getSubmittedAnsList(data)" ></eye-icon>
                                                                                        </button>                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            </template>
                                                                        </tbody>
                                                                        </table>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                        <!-- new list -->
                                        <div class="tab-pane fade" id="btclisting_Tab" role="tabpanel" aria-labelledby="btc_listing_view" aria-expanded="false">
                                            <ListingConfig v-if="listingmapping" />
                                        </div>

                                        <div class="modal fade px-2" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered modal-sm-min-width" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                                                <check-circle-icon size="2.5x" class="font-medium-3 mr-1"></check-circle-icon>
                                                <h4 class="modal-title">View Submitted Answers Details </h4>

                                                
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                </div>
                                                <div class="modal-body flex-grow-1 devUniqueForm">
                                                
                                                <section id="card-navigation modern-horizontal-wizard">
                                                    <div class="card border-warning-0 shadow-none" >
                                                    <div>
                                                        <div class="card-body p-0">
                                                        <div class="row match-height">
                                                        <div class="col-lg-4 col-md-6 col-12 mb-1">
                                                            <div class="transaction-item p-1 rounded business_dis_rows b_bg-dis_row1">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-danger rounded">
                                                                    <div class="avatar-content">
                                                                        <airplay-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></airplay-icon>

                                                                    </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                    <h6 class="transaction-title" style="margin-left: 7px">Course Name</h6>
                                                                    <small class ="font-weight-bold" style="margin-left: 9px; color:green;">{{view.coursename}}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-6 col-12 mb-1">
                                                            <div class="transaction-item p-1 rounded business_dis_rows b_bg-dis_row2">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-primary rounded">
                                                                    <div class="avatar-content">
                                                                        <paperclip-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></paperclip-icon>

                                                                    </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                    <h6 class="transaction-title" style="margin-left: 7px">Assessment Title</h6>
                                                                    <small class ="font-weight-bold" style="margin-left: 9px;color:green;">{{view.assessmenttitle}}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-6 col-12 mb-1">
                                                            <div class="transaction-item p-1 rounded business_dis_rows b_bg-dis_row3">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-info rounded">
                                                                    <div class="avatar-content">
                                                                        <award-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></award-icon>
                                                                    </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                    <h6 class="transaction-title" style="margin-left: 7px">Obtained Marks</h6>
                                                                    <small class ="font-weight-bold" style="margin-left: 9px;color:green;">{{view.totalcorrectans}}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>              
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </section>
                                                <form class="form form-vertical">
                                                    <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="card text-center mb-3">
                                                        <div class="">
                                                            <div class="tab-content" id="myTabContent">
                                                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">                                
                                                                <div class="row" id="table-small">
                                                                <div class="col-12">
                                                                    <div class="card">
                                                                    <div class="">
                                                                        <div class="row">
                                                                        <div class="col-md-10">
                                                                            <div class="input-group-append"></div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sm dataTableListing table-hover table-bordered" aria-describedby="mydesc">
                                                                            
                                                                            <thead>                     
                                                                            <tr>
                                                                            <th scope="col" class="srno align-middle" style="width: 40px;">Sr#</th>
                                                                            <th scope="col" class="align-middle" style="width: 200px;">Question Title</th>
                                                                            <th scope="col" class="align-middle">Option A</th>
                                                                            <th scope="col" class="align-middle">Option B</th>
                                                                            <th scope="col" class="align-middle">Option C</th>
                                                                            <th scope="col" class="align-middle">Option D</th>
                                                                            <th scope="col" class="align-middle" style="width: 90px;">Given Answer</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <template  v-for="(data, index) in ansList">
                                                                            <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)">
                                                                                <td class="srno">{{ index+1+currPageNum*pagelimit }}</td>
                                                                                <td>
                                                                                <span class="font-weight-bold capitalisetext" >{{data.questiontitle}}</span>
                                                                                </td>
                                                                                <td>{{ data.optiona }}</td>
                                                                                <td>{{ data.optionb }}</td>
                                                                                <td>{{ data.optionc }}</td>
                                                                                <td>{{ data.optiond }}</td>                                                                               
                                                                                <td class="text-nowrap text-center ">{{data.answergiven ? data.answergiven: '-'}}</td>
                                                                                
                                                                            </tr>
                                                                            </template>
                                                                        </tbody>
                                                                        </table>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </form>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Pagination from 'vue-pagination-2'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { CheckCircleIcon,PenToolIcon,AwardIcon,ThumbsUpIcon,BookIcon,BookOpenIcon,MapPinIcon,CalendarIcon,EditIcon,EyeIcon,UserIcon,LockIcon,InfoIcon,LinkIcon,BellIcon,EyeOffIcon,PlusIcon,CodepenIcon, BarChartIcon,TrendingUpIcon,BriefcaseIcon,GlobeIcon,ClipboardIcon,MoreVerticalIcon,PocketIcon,AtSignIcon,MailIcon,PhoneIcon,Edit2Icon,Trash2Icon ,PlusCircleIcon,SaveIcon,StarIcon,PaperclipIcon,AirplayIcon } from 'vue-feather-icons'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'



import VueNumericInput from 'vue-numeric-input'

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';
import ListingConfig from './ListingConfig.vue';

export default {
    name:'Masters',
    components:{
        Loading,Trash2Icon,PlusCircleIcon,SaveIcon,
        ClipboardIcon,
        VueElementLoading,
        MoreVerticalIcon,
        PaperclipIcon,
        AirplayIcon,
        vSelect,
        EditIcon,
        EyeIcon,
        UserIcon,
        LockIcon,
        InfoIcon,
        LinkIcon,
        BellIcon,
        EyeOffIcon,
        PlusIcon,
        CalendarIcon,
        MapPinIcon,
        DatePicker,
        BookOpenIcon,
        BookIcon,
        Treeselect,
        ThumbsUpIcon,
        AwardIcon,
        PenToolIcon,
        CheckCircleIcon,
        Edit2Icon,
        PhoneIcon,
        MailIcon,
        AtSignIcon,
        VueNumericInput,
        PocketIcon,
        StarIcon,
        CodepenIcon,
        Pagination,
        BarChartIcon,
        TrendingUpIcon,
        BriefcaseIcon,
        GlobeIcon,
        ListingConfig,
    },
    mixins: [ commonMethods ],
    data() {
        return {
            colours: ["bg-gradient-warning" , "bg-gradient-secondary" , "bg-gradient-primary", "bg-gradient-danger", "bg-gradient-success" , "bg-gradient-info"],
            colourCount:0,
            isLoading: false,
            fullPage: true,
            isActiveEndDate:true,
            defaultimagepath:apiUrl.defaultimagepath,
            file:null,
            firstname:'',
            lastname:'',
            email:'',
            mobilenumber:'',
            country:'',
            dept:'',
            address:'',
            city:'',
            desg:'',
            state:'',
            joiningdate:'',
            empid:'',
            employeeid:'',
            birthday:'',
            reportingto:'',
            auth:{
                oldpass:'',
                newpass:'',
                confpass:''
            },
            skills:{
                softskills:'',
                attr:'add',
                year:0.00,
                showothertxt:false,
                otherskilllist:null
            },
            oldpassBlured:false,
            newpassBlured:false,
            confpassBlured:false,
            showPassword: false,
            shownewPassword:false,
            showoldPassword:false,
            softskillsBlured:false,
            employemnttype:apiUrl.employemnttype,
            edudegree:apiUrl.edudegree,
            isActive:false,
            isEduActive:false,
            isExpActive:false,
            isAwardActive:false,
            isCourseActive:false,
            isSkillActive:false,
            isRecActive:false,
            card_content:'card-content',
            collapse:'collapse',
            tab_pane:'tab-pane', 
            fade:'fade',
            recfade:true,
            grecfade:false,
            recommendActive:true,
            grecommendActive:false,
            grecActive:false,
            recActive:false,
            rectabActive:true,
            grectabActive:false,
            nav_link:'nav-link',
            education:{
                univercity:'',
                degree:'',
                filedofstudy:'',
                grade:'',
                startdate:'',
                enddate:'',
                activities:'',
                description:'',
                attr:'add',
                searchedtxt:'',
                showothertxt:'',
                otheruniversity:null,
                otherdegree:null,
                showotherdegree:false,
                showotherfield:false,
                otherfieldofstudy:null
            },
            experience:{
                desg:'',
                emptype:'',
                company:'',
                industry:'',
                startdate:'',
                enddate:'',
                headline:'',
                description:'',
                attr:'add',
                isactive:true,
                enddateerror:null,
                startdateerror:null,
                showothertxt:null,
                designation:null,
                otherdesignation:null
            },
            course:{
                name:'',
                number:'',
                association:'',
                attr:'add'
            },
            certificate:{
                name:'',
                issuer:'',
                issuedate:'',
                expirydate:'',
                credentailid:'',
                credentailurl:'',
                attr:'add'
            },
            grecmsgBlured:false,
            grecpositionBlured:false,
            grecrelationBlured:false,
            grecempidBlured:false,
            otherdegreeBlured:false,
            otherfieldofstudyBlured:false,
            msgBlured:false,
            positionBlured:false,
            relationBlured:false,
            empidBlured:false,
            educationstartdateBlured:false,
            expstartdateBlured:false,
            expenddateBlured:false,
            issuerBlured:false,
            awardsBlured:false,
            enddateBlured:false,
            certificateissuedateBlured:false,
            certificateissuerBlured:false,
            issuedateBlured:false,
            certificatenameBlured:false,
            coursesnameBlured:false,
            desgBlured:false,
            emptypeBlured:false,
            companyBlured:false,
            industryBlured:false,
            startdateBlured:false,
            valid:false,
            univercityBlured:false,
            degreeBlured:false,
            filedofstudyBlured:false,
            gradeBlured:false,
            skilllistoptions:[ {
                id: '',
                label: ''
            }],
            designationList:[{
                id: '',
                label: ''
            }],
            univercityList:[],
            filedofstudyList:[],
            degreeList:[],
            skilllist:[],
            reclist:null,
            designation:null,
            awards:{
                title:'',
                association:'',
                issuer:'',
                issuedate:'',
                description:'',
                attr:'add'
            },
            rec:{
                attr:'add',
                comment:'',
                empid:'',
                relation:null,
                position:null,
                msg:null,
            },
            grec:{
                attr:'add',
                comment:'',
                empid:'',
                relation:null,
                position:null,
                msg:null,
            },
            awardtitleBlured:false,
            responseList:null,
            certificateList:null,
            courseList:null,
            awardList:null,
            skilsList:null,
            educationList:null,
            experienceList:null,
            editRowData:[],
            topskills :null,
            indkills :null,
            techkills:null,
            profileurl :'https://smartbees.xbees.in/images/portrait/small/icon.png',
            errors: {
                education:{
                    univercity:'',
                    otheruniversity:null,
                    degree:'',
                    filedofstudy:'',
                    grade:'',
                    startdate: null,
                    enddate: null,
                },
                experience:{
                    startdate: null,
                    enddate: null,
                },
                rec:{
                    empid:'',
                    relation:'',
                    position:'',
                    msg:'',
                },
                grec:{
                    empid:'',
                    relation:'',
                    position:'',
                    msg:'',
                },
                skills:{
                    softskills:'',
                    year:0.00,
                    otherskilllist:null
                },
                certificate:{
                    expirydate:'',
                    issuedate:''
                }
            },
            relationlist:[],
            peoplelist:[],
            positionlist:[],
            recdata:[],
            totalexp:'',
            loadertemplate:'',
            tokendata:null,
            performance: {
                performancelist: [],
                designationid: null,
                kraid: null,
                kpiid: null,
                kralist: [],
                kpilist: [],
            },
            examlist:[],
            ansList:[],
            view:[],
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            TimeFormat: apiUrl.TimeFormat,
            workbenchcount: null,
            listingmapping: false,
        }
    },    
     mounted(){
        
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)  
            this.tokendata = window.localStorage.getItem('token');
            this.country = userdata.country
            this.email = userdata.username
            this.empid = userdata.userid
            this.undt = userdata
            this.getApplicationdata('t21','')
            this.getDesignationdata()
            this.getprofiledata()
            this.getworkbenchcount()
            this.getDegreeData()
            this.getFieldofstudyData()
            this.getPeoplelist()
            this.getpositiondata()
            this.getRelationshipmaster()
            this.getUnivercityListByEmp()
            this.myKraKpiByDesignationId()
            this.getExamsettingList()
            // this.getCourseInfo(this.$route.query.courseassessmentmasterid)
            // this.getSubmittedAnsList(this.$route.query.id)
        }
        $(".profile_site_bar_scroll .card-header.cursor-pointer").click(function(){
            $(this).toggleClass("profile_arrow_rotetion");
        });
        
    },
    methods:{
        getColours(index){
            if(index < this.colours.length){
                return this.colours[index]
            }
            return this.colours[index%this.colours.length]

        },
        disabledBefore(date) {
            let dayBefore = moment(this.experience.startdate).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; // Assume < 25 May Not Selected
        },
        onActiveselect(){
            this.isActiveEndDate = !this.isActiveEndDate
            if(this.isActiveEndDate){
                this.experience.enddate = null
            }
            // $("#expenddate input").val('')
            // console.log(this.experience.enddate,'this.experience.enddate');
        },
        getUnivercityData(){
            this.apiURL="api/listing/getUnivercityList"
            this.input={
                univercityid:0,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.status){
                   this.univercityList = result.data.data;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getDegreeData(){
            this.apiURL="api/listing/getDegreeList"
            this.input={
                univercityid:0,
                degreeid:0,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                 'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.status){
                   this.degreeList = result.data.data;
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getFieldofstudyData(){
            this.apiURL="api/listing/getfieldofstudyList"
            this.input={
                studyid:0,
                univercityid:0,
                degreeid:0,
                useremail: this.undt.username,empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                 'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.status){
                   this.filedofstudyList = result.data.data;
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        gotomyprofile(){
            if(this.reportingto){
                this.apiURL="api/master/imageupload/getmanagerprofile"
                this.input={
                    emailid:this.reportingto,
                    useremail: this.undt.username,empcode: this.undt.userid
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {           
                    if(result.data.status){
                        let response = result.data.data;
                        let route = this.$router.resolve({ path: "/userprofile?empid="+response[0].empid});
                        window.open(route.href);
                    }
                }).catch(e => {
                this.displayError(e)
            })
            }
            
        },
        getpositiondata(){
            this.positionlist=[]
            let apiURL='api/listing/getDesignationByEmployee'  
            this.input={
                empid : this.empid,
                useremail: this.undt.username,empcode: this.undt.userid
            }            
            axios({
                'method': 'POST',
                'url': apiURL,
                'data':this.input,
                 'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    this.positionlist=result.data.data
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getRelationshipmaster(){
            let apiURL='api/listing/getRelationshipmaster'  
            this.input={
                id : 0,
                useremail: this.undt.username,empcode: this.undt.userid
            }            
            axios({
                'method': 'POST',
                'url': apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    this.relationlist=result.data.data
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getPeoplelist(){
            this.apiURL="api/listing/getAllEmployeeList"
            this.peoplelist=[]
            this.input={
                empid:this.empid,
                useremail: this.undt.username,empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.status){
                    this.peoplelist= result.data.data;
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        myKraKpiByDesignationId() {
            this.isLoading = true
            let url = 'api/master/performance/myKraKpiByDesignationId'
            axios({
                method: 'POST',
                url,
                data: { empid: parseInt(this.empid),useremail: this.undt.username,empcode: this.undt.userid },
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    let d = result.data.data.rows
                    this.performance.performancelist = d
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    this.performance.performancelist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getExamsettingList() {
            this.isLoading = true
            let url = 'api/profile/getUserdashboardassessment'
            axios({
                method: 'POST',
                url,
                data: { 
                limit: apiUrl.LIMIT,
                offset: this.pageoffset,
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,empcode: this.undt.userid
                },
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                let d = result.data.data.rows
                this.examlist = d
                this.totalcount = result.data.data.count
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                this.examlist = []
                this.totalcount = 0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getSubmittedAnsList(data) {
            this.isLoading = true
            let url = "api/profile/getSubmittedAnsById"
            this.input = {
                empid : parseInt(this.undt.userid),
                courseassessmentmasterid:data.courseassessmentmasterid,
                resid:data.id,
                useremail: this.undt.username,
                empcode: this.undt.userid
            };
            axios({
                method: 'POST',
                url,
                'headers':{ 'authorization' :this.tokendata },
                data:this.input
            }).then(result => {
                this.isLoading = false
                $('#technologypopup').modal('show');
                if(result.data.errorCode == 0) {
                    this.ansList = result.data.data
                    this.getCourseInfo(data)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
                else {
                    this.ansList = result.data.data
                    let tempdata = this.ansList[0]
                    this.ansList= tempdata
                    this.ansList = []
                }
            })
        },

        getCourseInfo(data) {
            this.isLoading = true
            let apiUrl = "api/profile/getCourseInfo"
            this.input = {
                // id : id,
                empid : parseInt(this.undt.userid),
                courseassessmentmasterid:data.courseassessmentmasterid,
                useremail: this.undt.username,empcode: this.undt.userid
            }
            axios({
                method: "POST",
                url: apiUrl,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                this.view = result.data.data;
                let tempdata = this.view[0]
                this.view = tempdata
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
                else {
                this.view = []
                }
            }) 
        },
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        checkclick() {
            this.listingmapping = true;
        },
        toggleShownewpass() {
            this.shownewPassword = !this.shownewPassword;
        },
        toggleold() {
            this.showoldPassword = !this.showoldPassword;
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText1 : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validTextExp : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validRequest : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validPassword : function(pasword) {
            var re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
            return re.test(pasword);
        },
        validate : function(){
            this.oldpassBlured = true;
            this.newpassBlured=true;
            this.confpassBlured=true;
            if( this.validPassword(this.auth.confpass) && this.validPassword(this.auth.oldpass) && this.validPassword(this.auth.newpass)){
                this.valid = true;
            }
        },
        validateDate(mdl) {
            var startDate = this.education.startdate
            var endDate = this.education.enddate
            var expstartDate = this.experience.startdate
            var expendDate = this.experience.enddate
            
            var expiredenddate = this.certificate.expirydate
            var unexpiredenddate = this.certificate.issuedate
            if(mdl === 'educationenddate') {
                if ((Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.education.enddate = "End date should be greater than start date"
                    this.education.enddate = null
                } else {
                    this.errors.education.enddate = ''
                }
            } else if(mdl === 'educationstartdate') {
                if ((Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.education.startdate = "Start date should be lesser than end date"
                    this.education.startdate = null
                } else {
                    this.errors.education.startdate = ''
                }
            }
            if(mdl === 'expenddate') {
                if ((Date.parse(expendDate) < Date.parse(expstartDate))) {
                    this.errors.experience.enddate = "End date should be greater than start date"
                    this.experience.enddate = null
                } else {
                    this.errors.experience.enddate = ''
                }
            } else if(mdl === 'expstartdate') {
                if ((Date.parse(expendDate) < Date.parse(expstartDate))) {
                    this.errors.experience.startdate = "Start date should be lesser than end date"
                    this.experience.startdate = null
                } else {
                    this.errors.experience.startdate = ''
                }
            }
            if(mdl === 'expirydate') {
                if ((Date.parse(expiredenddate) < Date.parse(unexpiredenddate))) {
                    this.errors.certificate.expirydate = "Expiration date should be greater than issue date"
                    this.certificate.expirydate = null
                } else {
                    this.errors.certificate.expirydate = ''
                }
            } else if(mdl === 'issuerdate') {
                if ((Date.parse(expiredenddate) < Date.parse(unexpiredenddate))) {
                    this.errors.certificate.issuedate = "Issue date should be lesser than expiration date"
                    this.certificate.issuedate = null
                } else {
                    this.errors.certificate.issuedate = ''
                }
            }
        },
        async resetpassword(){
            this.validate()
            if(this.valid){
                const response = await  axios.post('api/user/changepassword',{
                    username:this.email,
                    oldpassword:this.auth.oldpass,
                    newpassword:this.auth.newpass
                });            
                if(response){
                    if(response.data.msg=="Password changed successfully"){
                        localStorage.removeItem('reloaded');
                        localStorage.removeItem('userdata');
                        localStorage.removeItem('token');
                        this.$router.push('/login')
                    }else{
                        Swal.fire({
                            title: "",
                            text: response.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }
            }
        },
        saveskills(){
            this.validateskills()
        },
        onSelect(){
            this.file = this.$refs.file.files[0];
        },
        getprofiledata(){
             this.isLoading = true;
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.empid = userdata.userid
                this.apiURL="api/master/imageupload/getPreSignedUrl"
                this.input={
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {         
                    this.isLoading = false;    
                    if(result.data.status){
                        let response = result.data.data;
                        if(response.profilepic){
                            this.profileurl=response.profilepic
                        }
                        this.joiningdate=response.joiningdate
                        this.employeeid = response.empid
                        this.birthday = response.birthday
                        this.reportingto = response.reportto
                        this.year= response.year?(response.year+' yr ') : ''
                        this.month = response.month?response.month+' mos':''
                        this.totalexp =this.year+ ''+this.month
                        // this.dept = response.designation+' ['+response.department+']'

                        this.dept = response.department
                        this.desg = response.designation
                        this.email = response.emailid
                        this.address = response.address
                        this.country = response.country
                        this.city = response.city
                        this.state = response.state
                        this.mobilenumber = response.mobileno
                        this.firstname=response.firstname
                        this.lastname=response.lastname

                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getworkbenchcount(){
             this.isLoading = true;
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.empid = userdata.userid
                this.apiURL="api/profile/getworkbenchcount"
                this.input={
                    empcode:parseInt(this.empid),
                    useremail: this.undt.username,
                    empid: this.undt.userid
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {         
                    this.isLoading = false;    
                    if(result.data.status){
                        this.workbenchcount = result.data.data[0].counted;
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        async onUpload(){
            if(this.file){
                if(this.file.name!==""){
                    this.isLoading = true;
                    var userdata = window.localStorage.getItem('userdata');
                    if(userdata){
                        userdata = JSON.parse(userdata)
                        this.empid = userdata.userid
                    }
                    this.apiURL="api/master/imageupload/uploadImagetoS3"
                    let formData = new FormData();
                    formData.append("imagefile", this.file);
                    formData.append("empcode", this.empid);
                    formData.append("useremail",this.username);
                    formData.imagefile = this.file;
                    formData.empcode=this.empid
                    axios({
                        'content-type':'multipart/form-data',
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':formData,
                        'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {  
                        this.isLoading = false;               
                        if(result.data.status){
                            console.log(result)
                            this.profileurl=result.data.data;
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    })
                }else{
                    Swal.fire({
                        title: "",
                        text: 'Select profile photo',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }else{
                this.isLoading = false;
                Swal.fire({
                    title: "",
                    text: 'Select profile photo',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
            
        
        },
        myCollapse:function(){
            this.clearerrorForm();
            this.isActive=!this.isActive
            this.certificate.name=null
            this.certificate.issuer=null
            this.certificate.issuedate=null
            this.certificate.expirydate=null
            this.certificate.credentailid=null
            this.certificate.credentailurl=null
            this.certificate.attr='add';
        },
        mySkillCollapse:function(){
            this.clearerrorForm();
            this.isSkillActive=!this.isSkillActive
            this.skilllist=null
            this.skills.showothertxt=false
            this.skills.otherskilllist=null
            this.skills.attr='add';
            this.skills.year=0
        },
        myRecCollapse:function(){
            this.clearerrorForm();
            this.isRecActive=!this.isRecActive
            this.rec.attr='add';
            this.rec.position=null
            this.rec.empid=null
            this.rec.relation=null
            this.rec.msg=null
            this.grec.position=null
            this.grec.empid=null
            this.grec.relation=null
            this.grec.msg=null
        },
        myEduCollapse:function(){
            this.clearerrorForm();
            this.isEduActive=!this.isEduActive
            this.education.univercity=null,
            this.education.otheruniversity=null,
            this.education.degree=null,
            this.education.filedofstudy=null,
            this.education.startdate=null,
            this.education.enddate=null,
            this.education.grade=null,
            this.education.activities=null,
            this.education.description=null
            this.education.attr='add'
        },
        myExpCollapse:function(){
            this.clearerrorForm();
            this.isExpActive=!this.isExpActive
            this.designation=null,
            this.experience.emptype=null,
            this.experience.company=null,
            this.experience.startdate=null,
            this.experience.enddate=null,
            this.experience.headline=null,
            this.experience.industry=null,
            this.experience.description=null
            this.experience.attr='add'
            this.experience.otherdesignation=null
        },
        myAwardCollapse:function(){
            this.clearerrorForm();
            this.isAwardActive=!this.isAwardActive
            this.awards.title=null
            this.awards.association=null
            this.awards.issuer=null
            this.awards.issuedate=null
            this.awards.description=null
            this.awards.attr='add'
            
        },
        myCourseCollapse:function(){
            this.clearerrorForm();
            this.isCourseActive=!this.isCourseActive
            this.course.name=null,
            this.course.number=null,
            this.course.association=null
            this.course.attr='add'
        },
        saveData(tblNm){
            this.validatedata(tblNm)
            if(this.valid){
                this.isLoading=true
                if(tblNm=="edu"){
                    this.apiURL="api/master/education/save";
                    this.input = {
                        degree:this.education.degree,
                        field_of_study:this.education.filedofstudy,
                        startdate:this.education.startdate,
                        enddate:this.education.enddate?this.education.enddate:'',
                        grade:this.education.grade,
                        activites:this.education.activities?this.education.activities:'',
                        description:this.education.description?this.education.description:'',
                        useremail: this.undt.username,
                        empcode: this.undt.userid
                    }
                    if(this.education.univercity && this.education.showothertxt == false){
                        this.input.school=this.education.univercity
                    }
                    if(this.education.otheruniversity && this.education.showothertxt == true){
                        this.input.otherschool=this.education.otheruniversity
                    }
                    if(this.education.attr=="edit"){
                        this.apiURL="api/master/education/edit"
                        this.input.educationid=this.editRowData.educationid
                        this.input.lastmodifiedby=this.email
                    }else{
                        this.input.createdby=this.email
                    }
                }else if(tblNm=="exp"){
                    this.apiURL="api/master/experience/save"
                    this.input={
                        empcode:this.empid,
                        employement_type:this.experience.emptype,
                        company:this.experience.company,
                        startdate:this.experience.startdate,
                        enddate:this.experience.enddate?this.experience.enddate:'',
                        headline:this.experience.headline?this.experience.headline:'',
                        industry:this.experience.industry,
                        description:this.experience.description?this.experience.description:'',
                        isactive:'1',
                        roleflag:'1',
                        // createdby:this.email
                    }

                    if(this.designation && this.experience.showothertxt == false){
                        this.input.designationid=this.designation
                    }
                    if(this.experience.designation && this.experience.showothertxt == true){
                        this.input.otherdesignation=this.experience.designation
                    }
                    if(this.experience.isactive){
                        this.input.roleflag=1
                    }else{
                        this.input.roleflag=0
                    }
                    if(this.experience.attr=="edit"){
                        this.apiURL="api/master/experience/edit"
                        this.input.experienceid=this.editRowData.experienceid
                        this.input.lastmodifiedby=this.email
                    }else{
                        this.input.createdby=this.email
                    }
                }else if(tblNm=="skills"){
                    this.apiURL='api/master/skill/save'
                    this.skillArray =[this.skilllist]
                    this.input={
                        empcode:this.empid,
                        createdby:this.email,
                        /* skills:this.skillArray, */
                        other_skills:'',
                        yearofexp:this.skills.year
                    }
                    if(this.skillArray && this.skills.showothertxt == false){
                        this.input.skills=this.skillArray
                    }else if(this.skills.otherskilllist && this.skills.showothertxt == true){
                        this.input.otherskilllist=this.skills.otherskilllist
                    }
                    
                }else if(tblNm=="awards"){
                    this.apiURL="api/master/award/save"
                    this.input={
                        empcode:this.empid,
                        title:this.awards.title,
                        association:this.awards.association?this.awards.association:'',
                        issuer:this.awards.issuer,
                        issuedate:this.awards.issuedate?this.awards.issuedate:'',
                        description:this.awards.description?this.awards.description:''
                    }
                    if(this.awards.attr=="edit"){
                        this.apiURL="api/master/award/edit"
                        this.input.awardsid=this.editRowData.awardsid
                        this.input.lastmodifiedby=this.email
                    }else{
                        this.input.createdby=this.email
                    }
                }else if(tblNm=="course"){
                    this.apiURL="api/master/course/save"
                    
                    this.input={
                        empcode:this.empid,
                        course_name:this.course.name,
                        course_number:this.course.number,
                        associated_with:this.course.association?this.course.association:'',
                        createdby:this.email
                    }
                    if(this.course.attr=="edit"){
                        this.apiURL="api/master/course/edit"
                        this.input.courseid=this.editRowData.courseid
                    }
                }else if(tblNm=="certificate"){
                    this.apiURL="api/master/certificate/save"
                    this.input={
                        empcode:this.empid,
                        name:this.certificate.name,
                        issuer:this.certificate.issuer,
                        issuedate:this.certificate.issuedate?this.certificate.issuedate:'',
                        expirydate:this.certificate.expirydate?this.certificate.expirydate:'',
                        credentialid:this.certificate.credentialid?this.certificate.credentialid:'',
                        credentialurl:this.certificate.credentialurl?this.certificate.credentialurl:'',
                        isactive:'1'
                    }
                    if(this.certificate.attr=="edit"){
                        this.apiURL="api/master/certificate/edit"
                        this.input.certificationid=this.editRowData.certificationid
                        this.input.lastmodifiedby=this.email
                    }else{
                        this.input.createdby=this.email
                    }
                }
                else if(tblNm=="rec"){
                    this.apiURL="api/recommendation/save"
                    this.input={
                        empcode:this.rec.empid,
                        realationid:this.rec.relation,
                        designation:this.rec.position,
                        message:this.rec.msg,
                        flag:1,
                        createdby:this.email,
                        userid:this.empid
                    }
                }else if(tblNm=="grec"){
                    this.apiURL="api/recommendation/save"
                    this.input={
                        empcode:this.grec.empid,
                        realationid:this.grec.relation,
                        designation:this.grec.position,
                        message:this.grec.msg,
                        flag:2,
                        createdby:this.email,
                        userid:this.empid
                    }
                }
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid
                if(this.apiURL){
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data': this.input,
                         'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {      
                        this.isLoading = false;
                        this.clearerrorForm();           
                        if(result.data.errorCode == 0){
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            if(tblNm=="exp"){
                                this.myExpCollapse()
                                this.getDesignationdata()
                            }else if(tblNm=="edu"){
                                this.myEduCollapse();
                                this.education.otheruniversity=null
                                this.education.showothertxt=false
                                this.getUnivercityData();
                            }else if (tblNm=="course"){
                                this.myCourseCollapse();
                            }else if (tblNm=="skills"){
                                this.mySkillCollapse();
                                this.getApplicationdata('t21','');
                            }else if(tblNm=="awards"){
                                this.myAwardCollapse()
                            }else if(tblNm=="certificate"){
                                this.myCollapse()
                            }else if(tblNm=="rec"){
                                this.myRecCollapse()
                                this.getRecommendationdata(1)
                            }else if(tblNm=="grec"){
                                this.myRecCollapse()
                                this.getRecommendationdata(2)                                
                            }
                            
                            this.getmasterList(tblNm);
                            
                        } else if(result.data.errorCode == 3){
                                Swal.fire({
                                    title: "Session Expired...!!",
                                    text: result.data.msg,
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                }).then(function() {
                                    window.location.href = "/#/login";
                                })
                            }else{
                            Swal.fire({
                                title: "Failed!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    }).catch(e => {
                        this.displayError(e)
                    })
                }
            }
            // console.log(this.input)
        },
        validatedata : function(tblNm){
            this.desgBlured=true
            this.emptypeBlured=true
            this.companyBlured=true
            this.industryBlured=true
            this.startdateBlured=true
            this.univercityBlured=true
            this.degreeBlured=true
            this.filedofstudyBlured=true
            this.gradeBlured=true
            this.startdateBlured=true
            this.enddateBlured=true 
            this.coursesnameBlured=true 
            this.softskillsBlured=true     
            this.awardtitleBlured=true   
            this.certificateissuerBlured=true
            this.certificateissuedateBlured=true
            this.awardsBlured=true
            this.issuerBlured=true
            this.expstartdateBlured=true
            this.expenddateBlured=true
            this.educationstartdateBlured=true
            this.empidBlured=true
            this.grecempidBlured=true
            this.grecrelationBlured=true
            this.grecpositionBlured=true
            this.grecmsgBlured=true
            this.msgBlured=true
            this.positionBlured=true
            this.relationBlured=true
            this.otherfieldofstudyBlured=true
            this.otherdegreeBlured=true
            this.certificatenameBlured = true
            this.issuedateBlured=true  
            this.relationBlured=true
            if(tblNm=="exp"){
                if( this.validText(this.experience.emptype) && this.validText(this.experience.company) && this.validText(this.experience.industry)){
                    this.valid=true
                }
                if(!this.experience.isactive && !this.experience.enddate){
                    this.errors.experience.enddate="End date is required";
                    this.valid=false
                }
                if(!this.experience.startdate){
                    this.errors.experience.startdate="Start date is required";
                    this.valid=false
                }
            }
            else if(tblNm=="edu"){
                this.valid=true
                // this.clearerrorForm()
                if(!this.education.univercity && this.education.showothertxt == false){
                    this.errors.education.univercity="University required";
                    this.valid=false                    
                }
                if(!this.education.otheruniversity && this.education.showothertxt == true){
                    this.errors.education.univercity="University required";
                    this.valid=false
                }                
                if(!this.education.degree){
                    this.errors.education.degree="Degree required";
                    this.valid=false
                }
                if(!this.education.filedofstudy){
                    this.errors.education.filedofstudy="Field of study required";
                    this.valid=false
                }
                if(!this.education.grade){
                    this.errors.education.grade="Grade required";
                    this.valid=false
                }
                if(!this.education.startdate){
                    this.errors.education.startdate="Start date required";
                    this.valid=false
                }
                if(!this.education.enddate){
                    this.errors.education.enddate="End date required";
                    this.valid=false
                }
            }
            else if(tblNm=="rec"){
                this.valid=true
                // this.clearerrorForm()
                if(!this.rec.empid){
                    this.errors.rec.empid="Search for people is a required field";
                    this.valid=false
                }
                if(!this.rec.relation){
                    this.errors.rec.relation="Relationship is a required field";
                    this.valid=false
                }
                if(!this.rec.position){
                    this.errors.rec.position="Position at the time is a required field";
                    this.valid=false
                }
                if(!this.rec.msg){
                    this.errors.rec.msg="Include a personalized message is a required field";
                    this.valid=false
                }
            }
            else if(tblNm=="grec"){
                this.valid=true
                // this.clearerrorForm()
                if(!this.grec.empid){
                    this.errors.grec.empid="Search for people is a required field";
                    this.valid=false
                }
               if(!this.grec.relation){
                    this.errors.grec.relation="Relationship is a required field";
                    this.valid=false
                }
                if(!this.grec.position){
                    this.errors.grec.position="Position at the time is a required field";
                    this.valid=false
                }
                if(!this.grec.msg){
                    this.errors.grec.msg="Include a personalized message is a required field";
                    this.valid=false
                }
            }
            else if(tblNm=="course"){
                if(this.validText(this.course.name)){
                    this.valid=true
                }
                else{
                    this.valid=false
                }
            }
            else if(tblNm=="skills"){
                this.valid=true
                this.clearerrorForm()
                if(!this.skilllist && this.skills.showothertxt == false){
                    this.errors.skills.otherskilllist="Skill is required";
                    this.valid=false                    
                }
                if(!this.skills.otherskilllist && this.skills.showothertxt == true){
                    this.errors.skills.otherskilllist="Skill is required";
                    this.valid=false
                } 
                if(!this.skills.year){
                    this.errors.skills.year="Year of experience required";
                    this.valid=false
                } 
            }
            else if(tblNm=="awards"){
                if(this.validText(this.awards.title)){
                    this.valid=true
                }
            }
            else if(tblNm=="certificate"){
                if(this.validText(this.certificate.name) && this.validText(this.certificate.issuer)){
                    this.valid=true
                    this.clearerrorForm()
                }
                if(!this.certificate.issuedate){
                    this.errors.certificate.issuedate="Issue date required";
                    this.valid=false
                }
                if(!this.certificate.expirydate){
                    this.errors.certificate.expirydate="Expiration date required";
                    this.valid=false
                }
            }

        },
        // selUniversity(state){
        //     if(!state){
        //         this.errors.education.univercity = 'University is required'
        //     } else {
        //         this.errors.education.univercity = ''         
        //     }
        // },
        clearerrorForm(){
            this.startdateBlured=false
            this.enddateBlured=false
            this.certificatenameBlured=false
            this.certificateissuerBlured=false
            this.certificateissuedateBlured=false
            this.awardsBlured=false
            this.issuerBlured=false
            this.expstartdateBlured=false
            this.expenddateBlured=false
            this.educationstartdateBlured=false
            this.gradeBlured=false
            this.otherfieldofstudyBlured=false
            this.otherdegreeBlured=false
            this.univercityBlured=false
            this.empidBlured=false
            this.grecempidBlured=false
            this.grecrelationBlured=false
            this.grecpositionBlured=false
            this.grecmsgBlured=false
            this.relationBlured=false
            this.msgBlured=false
            this.positionBlured=false
            this.coursesnameBlured=false
            this.awardtitleBlured=false
            this.emptypeBlured=false
            this.companyBlured=false
            this.industryBlured=false
            this.errors= {
                education:{
                    univercity:null,
                    otheruniversity:null,
                    degree:null,
                    filedofstudy:null,
                    grade:null,
                    startdate: null,
                    enddate: null,
                },
                experience:{
                    startdate: null,
                    enddate: null,
                },
                rec:{
                    empid:null,
                    relation:null,
                    position:null,
                    msg:null,
                },
                grec:{
                    empid:null,
                    relation:null,
                    position:null,
                    msg:null,
                },
                skills:{
                    softskills:'',
                    year:0.00,
                    otherskilllist:''
                },
                certificate:{
                    issuedate:null,
                    expirydate:null
                }
            }
        },
        selskilldata(state,value){
            this.skills.softskills=null
            this.skills.softskills=state
            if(state){
                this.skills.showothertxt=false
                this.skills.otherskilllist=null
            }
        },
        selempldata(state,value){
            this.skills.softskills=null
            this.skills.softskills=state
            if(state){
                this.skills.showothertxt=false
            }
        },
        seldesignationdata(state,value){
            this.designation=null
            this.designation=state
            if(state){
                this.experience.showothertxt=false
            }
        },
        selrelldata(state,value){
            this.rec.relation=null
            this.rec.relation=state
        },
        selpeopledata(state,value){
            this.rec.empid=null
            this.rec.empid=state
        },
        selpositiondata(state,value){
            this.rec.position=null
            this.rec.position=state
        },
        selgrelldata(state,value){
            this.grec.relation=null
            this.grec.relation=state
        },
        selgpeopledata(state,value){
            this.grec.empid=null
            this.grec.empid=state
        },
        selgpositiondata(state,value){
            this.grec.position=null
            this.grec.position=state
        },
        selunidata(state,value){
            this.education.univercity=null
            this.education.univercity=state
            if(state){
                this.education.showothertxt=false
                this.education.otheruniversity=null
            }
            this.univercityBlured=false
        },
        seldegreedata(state,value){
            this.education.degree=null
            this.education.degree=state
            if(state){
                this.education.otherdegree=null
                this.education.showotherdegree=false
            }
            this.otherdegreeBlured=false
        },
        selfileddata(state,value){
            this.education.filedofstudy=null
            this.education.filedofstudy=state
            if(state){
                this.education.otherfieldofstudy=null
                this.education.showotherfield=false
            }
            this.otherfieldofstudyBlured = false
        },
        testcall(state,emplvalue){
            // console.log(state)
        },
        getApplicationdata(mdl,curracttab){
            let apiURL='api/profile/getskilllist'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data':{useremail: this.undt.username,empcode: this.undt.userid},
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    this.skilllistoptions=result.data.data
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        removeskills(requestid){
            this.isLoading = true;  
            let apiURL='api/profile/removeskills'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data':{id:requestid,createdby:this.email,useremail: this.undt.username,empcode: this.undt.userid},
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;             
                if(result.data.status){
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.getmasterList("skills")
                }else{
                    Swal.fire({
                        title: "Failed!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getDesignationdata(){
            let apiURL='api/profile/getdesigniationlist'                
            axios({
                'method': 'POST',
                'url': apiURL,
                'data':{useremail: this.undt.username,empcode: this.undt.userid},
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    this.designationList=result.data.data
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getRecommendationdata(tblNm){
            this.isLoading=true
            this.recdata=[]
            let apiURL='api/recommendation/list'   
            this.input ={
                empid:this.empid,
                flag:tblNm,
                useremail: this.undt.username,empcode: this.undt.userid
            }       
            axios({
                'method': 'POST',
                'url': apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.status){
                    this.recdata=result.data.data
                    if(tblNm==1){
                        this.recommendActive=true
                        this.grecommendActive=false
                        this.recfade=true
                        this.grecfade=false
                        this.recActive=true
                        this.rectabActive=true
                        this.grectabActive=false
                    }else{
                        this.recommendActive=false
                        this.grecommendActive=true
                        this.grecActive=true
                        this.recfade=false
                        this.grecfade=true
                        this.grectabActive=true
                        this.rectabActive=false
                    }
                    
                }else{
                    this.recdata=[]
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        async getmasterList(tblNm){
            this.isLoading = true;    
            if(tblNm=="edu"){
                this.apiUrl="api/profile/geteducationdetails";   
            }else if(tblNm=="exp"){
                this.apiUrl="api/profile/getexperiencedetails";   
            }else if(tblNm=="skills"){
                this.apiUrl="api/profile/getskilldetails";    
            }else if(tblNm=="awards"){
                this.apiUrl="api/profile/getawarddetails";   
            }else if(tblNm=="course"){
                this.apiUrl="api/profile/getcoursedetails";   
            }else if(tblNm=="certificate"){
                this.apiUrl="api/profile/getcertificationdetails";   
            }
            // else if(tblNm=="exam"){
            //     this.apiUrl="api/profile/getExamdetailslist";   
            // }
            axios({
                'method': 'POST',
                'url': this.apiUrl,
                'data':{ empid:this.empid,useremail: this.undt.username,empcode: this.undt.userid},
                'headers':{'authorization':this.tokendata}
            }) .then(response => {      
                this.isLoading = false;    
                if(response.data.status){
                    this.educationList=null;
                    this.experienceList=null;
                    this.skilsList=null;
                    this.awardList= null;
                    this.courseList=null;
                    this.certificateList=null;
                    if(tblNm=="edu"){
                        this.educationList=response.data.data;
                    }else if(tblNm=="exp"){
                        this.getpositiondata()
                        this.experienceList= response.data.data;   
                    }else if(tblNm=="skills"){
                        this.skilsList= response.data.data;
                        if(this.skilsList.length >0){
                            this.topskills = this.skilsList[0][1]?this.skilsList[0][1]:null
                            this.indkills = this.skilsList[0][2]?this.skilsList[0][2]:null
                            this.techkills = this.skilsList[0][3]?this.skilsList[0][3]:null
                            console.log(this.topskills);
                        }
                        
                    }else if(tblNm=="awards"){
                        this.awardList= response.data.data;
                    }else if(tblNm=="course"){
                        this.courseList= response.data.data;
                    }else if(tblNm=="certificate"){
                        this.certificateList= response.data.data;  
                    }
                }else{
                    this.educationList=null;
                    this.experienceList=null;
                    this.skilsList=null;
                    this.awardList= null;
                    this.courseList=null;
                    this.certificateList=null;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        openEditModel(currentRowdata,tblNm){
            this.editRowData = currentRowdata
            if(tblNm=="edu"){
                this.myEduCollapse();
               
                this.education.univercity=currentRowdata.schoolid
                this.education.degree=currentRowdata.degreeid
                this.education.filedofstudy=currentRowdata.fieldid
                this.education.startdate=currentRowdata.startdate?this.formatDate(currentRowdata.startdate):''
                this.education.enddate=currentRowdata.enddate?this.formatDate(currentRowdata.enddate):''
                this.education.grade=currentRowdata.grade
                this.education.activities=currentRowdata.activites
                this.education.description=currentRowdata.description
                this.education.attr='edit'
                 this.getUnivercityListByEmp()
            }else if (tblNm=="exp"){
                this.myExpCollapse();
                this.designation=currentRowdata.designationid
                this.experience.emptype=currentRowdata.employement_type
                this.experience.company=currentRowdata.company
                this.experience.startdate=currentRowdata.startdate?this.formatDate(currentRowdata.startdate):''
                this.experience.enddate=currentRowdata.enddate?this.formatDate(currentRowdata.enddate):''
                this.experience.headline=currentRowdata.headline
                this.experience.industry=currentRowdata.industry
                this.experience.description=currentRowdata.description
                if(this.experience.enddate){
                    this.experience.isactive=false
                    this.isActiveEndDate=false
                }else{
                    this.experience.isactive=true
                    this.isActiveEndDate=true
                }
                this.experience.attr='edit'
            }
            else if(tblNm=="awards"){
                this.myAwardCollapse()
                this.awards.title=currentRowdata.title
                this.awards.association=currentRowdata.associated
                this.awards.issuer=currentRowdata.issuer
                this.awards.issuedate=currentRowdata.issuedate?this.formatDate(currentRowdata.issuedate):''
                this.awards.description=currentRowdata.description
                this.awards.attr='edit'
            }else if (tblNm=="course"){
                this.myCourseCollapse();
                this.course.name=currentRowdata.course_name;
                this.course.number=currentRowdata.coursenum;
                this.course.association=currentRowdata.associated_with;
                this.course.attr='edit'
            }else if (tblNm=="certificate"){
                this.myCollapse();
                this.certificate.name=currentRowdata.title
                this.certificate.issuer=currentRowdata.issuer
                this.certificate.issuedate=currentRowdata.issuedate?this.formatDate(currentRowdata.issuedate):''
                this.certificate.expirydate=currentRowdata.expiredate?this.formatDate(currentRowdata.expiredate):''
                this.certificate.credentialid=currentRowdata.credentialid
                this.certificate.credentialurl=currentRowdata.credentialurl
                this.certificate.attr='edit'
            }
            window.scrollTo(0,0);
        },
        formatDate(date) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        handleSearch(ev){
            if (ev.keyCode === 13) {
                this.education.searchedtxt=ev
            }
        },
        validateString(inputval){
            var re = /^[a-zA-Z\. ]*$/
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        createDegree(){
            let degree = this.education.otherdegree
            let valid = this.validateString(degree)
            if(valid && degree.length>2){
                this.isLoading=true
                this.errors.education.degree=null;
                this.input={
                    degree:degree,
                    createdby:this.email,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
                axios({
                    'method': 'POST',
                    'url': 'api/profile/createdegree',
                    'data':this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {  
                    this.isLoading=false         
                    if(result.data.status){
                        this.getDegreeData()
                        this.education.otherdegree=null
                        this.education.degree=result.data.data.insertedid
                        this.education.showotherdegree=false
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }else{
                        Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })

            }else{
                this.errors.education.degree="Degree must be string having greater than 2 character"
            }
        },
        createFieldofStudty(){
            let fieldofstudy = this.education.otherfieldofstudy
            let valid = this.validateString(fieldofstudy)
            if(valid && fieldofstudy.length>2){
                this.isLoading=true
                this.errors.education.fieldofstudy=null;
                this.input={
                    fieldofstudy:fieldofstudy,
                    createdby:this.email,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
                axios({
                    'method': 'POST',
                    'url': 'api/profile/createFieldOfStudy',
                    'data':this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {  
                    this.isLoading=false         
                    if(result.data.status){
                        this.getFieldofstudyData()
                        this.education.otherfieldofstudy=null
                        this.education.filedofstudy=result.data.data.insertedid
                        this.education.showotherfield=false
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }else{
                        Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                this.displayError(e)
            })

            }else{
                this.errors.education.filedofstudy="Field of study must be string having greater than 2 character"
            }
        },
        uniChange: function (node, instanceId) {
            let searchStr = node.toLowerCase()
            if(searchStr.length>1){
                this.apiURL="api/profile/getUnivercityName"
                this.input={
                    univercityname:searchStr,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    'headers':{'authorization':this.tokendata}

                })
                .then(result => {           
                    if(result.data.status){
                        this.univercityList = result.data.data;
                    }else{
                        this.univercityList =[]
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
            
        },
        getUnivercityListByEmp(){
            this.univercityList=[]
            this.apiURL="api/profile/getUnivercityListByEmp"
            this.input={
                empcode:this.empid,
                useremail: this.undt.username,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.status){
                    this.univercityList = result.data.data;
                }else{
                    this.univercityList =[]
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        appendNewItem(ev) {
            this.education.showothertxt=true
            this.education.univercity=null
        },
        appendDegree(ev) {
            this.education.showotherdegree=true
            this.education.degree=null
        },
        appendfieldofstudy(ev) {
            this.education.showotherfield=true
            this.otherfieldofstudyBlured= false
        },
        addnewskill(ev) {
            this.skills.showothertxt=true
            this.skilllist=null
        },
        adddesignation(){
            this.experience.showothertxt=true
            this.experience.designation=null
            this.designation=null
        }
    }
    
}


</script>
<style scoped>
.rightaction{
    position: absolute;
    top: 0.8rem;
    right:1.5rem;
    cursor: pointer;
    z-index: 1;

}
.delicon{
    position: absolute;
    cursor: pointer;
    float: right;
}
.font-size-larger{
    font-size: larger;
}
.label-left{
    float: left !important;
}
.rounded-bottom-0{
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.modal-sm-min-width{
    min-width:200px;
    max-width: 100%;
}
.capitalisetext{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
}
</style>