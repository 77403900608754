<template>
<div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
          <div class="content-wrapper card bg-white px-1 content_wrp_top_b_10"  >
            <div class="content-body">
                <section>
                    <h4 class="my-1"> Performance Box</h4>
                    <div class="card pt-0 pb-0  card_bottom_line_animation bottom_bg_bor5">
                      <div class="row mx-0 new_dashboard_bg_card pt-2 p-sm-1">
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <div class="container px-0">
                                <div class="media">
                                <div class="box_image_new_dash mr-25">
                                  <img alt="" src="../../../public/images/logo/avatar-s-11.jpg">
                            </div>
                                <div class="media-body ml-1">
                                    <div class="my_dash2_name">
                                        <span class="dash2_text card-text"> {{this.loginusername}} </span>
                                    </div>
                                    <div class="my_dash2_name">
                                        <span class="dash2_text card-text"> {{this.useremail}} </span>
                                    </div>
                                    <div class="my_dash2_name">
                                        <span class="dash2_text card-text">{{this.designation}}</span>
                                    </div>
                                    <div class="my_dash2_name">
                                        <span class="dash2_text card-text">{{this.employeeid}}</span>
                                    </div>
                                    <div class="my_dash2_name">
                                        <span class="dash2_text card-text">  2 Year </span>
                                    </div>
                                </div>
                            </div>

                            <div class="new_helth_bar_dash mt-2">
                                <p class="mb-50">Health: 90%</p>
                                <div class="progress progress-bar-danger" style="height: 10px">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="70" aria-valuemax="100" style="width: 70%"></div>
                                </div>
                            </div>
                                
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 new_dash_mar_top10"> 
                          <div class="container  new_dash_add_top00 card-transaction"> 
                            <div class="card-body pt-0 pb-1 new_dash_add_top00">
                                <div class="transaction-item mb-1">
                                <div class="media">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <em class="las la-clipboard-list font-medium-3"></em>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">My Rating Performance</h6>
                                        <small>(4)</small>
                                    </div>
                                </div>
                                <div class="font-weight-bolder my_rating_performance">
                                    <div class="rating">
                                    <form class='stars'>
                                      <input type="radio" name='stars' value='5' id='five' class='star' >
                                      <label for="five"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='4' id='four' class='star' checked>
                                      <label for="four"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='3' id='three' class='star' >
                                      <label for="three"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='2' id='two' class='star'>
                                      <label for="two"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='1' id='one' class='star'>
                                      <label for="one"><em class='las la-star '></em></label>
                                    </form>
                                  </div>
                                    
                                </div>
                            </div>
                            <div class="transaction-item mb-1">
                                <div class="media">
                                    <div class="avatar bg-light-success rounded">
                                        <div class="avatar-content">
                                           <em class="las la-cog font-medium-3"></em>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">My Technical Skills Performance</h6>
                                        <small>(3)</small>
                                    </div>
                                </div>
                                <div class="font-weight-bolder my_rating_performance">
                                    <div class="rating">
                                    <form class='stars'>
                                      <input type="radio" name='stars' value='5' id='five1' class='star' >
                                      <label for="five1"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='4' id='four2' class='star'>
                                      <label for="four2"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='3' id='three3' class='star' checked>
                                      <label for="three3"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='2' id='two2' class='star' >
                                      <label for="two2"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='1' id='one1' class='star' >
                                      <label for="one1"><em class='las la-star '></em></label>
                                    </form>
                                  </div>
                                    
                                </div>
                            </div>
                            <div class="transaction-item mb-1">
                                <div class="media">
                                    <div class="avatar bg-light-warning rounded">
                                        <div class="avatar-content">
                                          <em class="i_icon_st la-network-wired las lab font-medium-4"></em>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">My Communication Skills</h6>
                                        <small>(2)</small>
                                    </div>
                                </div>
                                <div class="font-weight-bolder my_rating_performance">
                                    <div class="rating">
                                    <form class='stars'>
                                      <input type="radio" name='stars' value='5' id='five' class='star' >
                                      <label for="five"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='4' id='four' class='star' >
                                      <label for="four"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='3' id='three' class='star'>
                                      <label for="three"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='2' id='two' class='star' checked>
                                      <label for="two"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='1' id='one' class='star' >
                                      <label for="one"><em class='las la-star '></em></label>
                                    </form>
                                  </div>
                                    
                                </div>
                            </div>
                            <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <em data-feather="pocket" class="avatar-icon font-medium-3"></em>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">My </h6>
                                        <small>(5)</small>
                                    </div>
                                </div>
                                <div class="font-weight-bolder my_rating_performance">
                                    <div class="rating">
                                    <form class='stars'>
                                      <input type="radio" name='stars' value='5' id='five' class='star' checked>
                                      <label for="five"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='4' id='four' class='star'>
                                      <label for="four"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='3' id='three' class='star'>
                                      <label for="three"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='2' id='two' class='star'>
                                      <label for="two"><em class='las la-star'></em></label>
                                      <input type="radio" name='stars' value='1' id='one' class='star' >
                                      <label for="one"><em class='las la-star '></em></label>
                                    </form>
                                  </div>
                                    
                                </div>
                            </div>
                            </div>
                          </div> 
                        </div>
                         
                      </div>
                   </div>
                </section> 
                <section>
                  <div class="pb-1">
                       <div class="row">
                         <div class="col-xl-6 col-md-6 col-sm-12">
                             <h4 class="my-1"> This Weeks Delivery Task </h4>
                            <div class="card text-center">
                                 <div class="row match-height">
                    <div class="col-lg-12 col-12">
                        <div class="card card-company-table mb-0">
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                    <table class="table new_dashbaord_table_head" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="min-width: 200px;"> Business Task Code </th>
                                                <th scope="col" style="min-width: 200px;"> Business Task Name </th>
                                                <th scope="col" style="min-width: 150px;">Project</th>
                                                <th scope="col" style="min-width: 150px;"> Task Status</th>
                                                <th scope="col">Task Type</th>
                                                <th scope="col" style="min-width: 180px;">Start date</th>
                                                <th scope="col" style="min-width: 190px;">Estimated End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="font-weight"> BTC-1669727314508 </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span  class="font-weight mb-25">Document upload on the basis of module</span>
                                                    </div>
                                                </td>
                                                <td class="text-nowrap">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-weight mb-25">India-FC Smart Work </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex flex-column">
                                                        <span class="font-weight mb-25">Product Completed</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">Business Task</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">29-11-2022 18:38</span>
                                                    </div>
                                                </td><td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">29-11-2022 18:38</span>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                            </div>
                         </div>
                         <div class="col-xl-6 col-md-6 col-sm-12">
                            <h4 class="my-1"> Next Weeks Delivery Task </h4>
                            <div class="card text-center">
                                <div class="row match-height">
                    <div class="col-lg-12 col-12">
                        <div class="card card-company-table mb-0">
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                    <table class="table new_dashbaord_table_head" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="min-width: 200px;"> Business Task Code </th>
                                                <th scope="col" style="min-width: 200px;"> Business Task Name </th>
                                                <th scope="col" style="min-width: 150px;">Project</th>
                                                <th scope="col" style="min-width: 150px;"> Task Status</th>
                                                <th scope="col">Task Type</th>
                                                <th scope="col" style="min-width: 180px;">Start date</th>
                                                <th scope="col" style="min-width: 190px;">Estimated End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="font-weight"> BTC-1669727314508 </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span  class="font-weight mb-25">Document upload on the basis of module</span>
                                                    </div>
                                                </td>
                                                <td class="text-nowrap">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-weight mb-25">India-FC Smart Work </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex flex-column">
                                                        <span class="font-weight mb-25">Product Completed</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">Business Task</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">29-11-2022 18:38</span>
                                                    </div>
                                                </td><td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">29-11-2022 18:38</span>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                </div>
             </div>
           </div>
          </div>
    </section>
     <section>
                  <div class="pb-1">
                    <div class="row">
                        <div class="col-md-12">
                            <fieldset class="fieldset_border_vi mb-2 mx-0 px-1   dashbarod_fieldset_bg">
                        <legend>Delayed  Task</legend>
                            <div class="card">
                                <div class="row match-height">
                        <div class="col-lg-12 col-12">
                            <div class="card card-company-table mb-0">
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-striped new_dashbaord_table_head" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col" style="max-width: 120px;">TASK CODE</th>
                                                    <th scope="col" >TASK NAME</th>
                                                    <th scope="col">TASK STATUS</th>
                                                    <th scope="col">TASK TYPE</th>
                                                    <th scope="col" >PROJECT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="font-weight"> 01 </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span  class="font-weight mb-25">TK-22111800778</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-nowrap">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Task 444 </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Open</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Audit Controls</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">BALLPARK PROJECT</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="font-weight"> 01 </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span  class="font-weight mb-25">TK-22111800778</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-nowrap">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Task 444 </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Open</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Audit Controls</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">BALLPARK PROJECT</span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                 </fieldset>
                 </div>
               </div>
              </div>
        </section>

                
                <section class="cards_box_new_dashboard">
                    <fieldset class="fieldset_border_vi mb-2 mx-0 px-1   dashbarod_fieldset_bg">
                        <legend>My Critical issues</legend>
                    <div class=" pb-1">
                       <div class="row">
                         <div class="col-sm-6 col-md-3 col-xl-3">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1 ">
                                <div class="card-body issuesbottom_bg_bor1">
                                <div class="avatar bg-light-danger p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st la-bug las las lab font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Open Issues</p>
                                </div>
                            </div>
                         </div>
                         <div class="col-sm-6 col-md-3 col-xl-3">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1">
                                <div class="card-body issuesbottom_bg_bor2">
                                <div class="avatar bg-light-warning p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st la-info-circle las lab font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Reopen issues</p>
                                </div>
                            </div>
                         </div>
                         <div class="col-sm-6 col-md-3 col-xl-3">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1">
                                <div class="card-body issuesbottom_bg_bor3">
                                <div class="avatar bg-light-success p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st la-users-cog  las font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Resolved Issues</p>
                                </div>
                            </div>
                         </div>
                         <div class="col-sm-6 col-md-3 col-xl-3">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1">
                                <div class="card-body issuesbottom_bg_bor4">
                                <div class="avatar bg-light-primary p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st la-comment-dots las lab font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Feedback Issues</p>
                                </div>
                            </div>
                         </div>
                       </div>
                       <div class="row match-height">
                        <div class="col-lg-12 col-12">
                            <div class="card card-company-table mb-0">
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table new_dashbaord_table_head table-hover table-striped" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col" style="min-width: 150px;">ISSUE CODE</th>
                                                    <th scope="col" style="min-width: 150px;">ISSUE NAME</th>
                                                    <th scope="col">PROJECT</th>
                                                    <th scope="col">FUNCTIONALITY</th>
                                                    <th scope="col">TYPE</th>
                                                    <th scope="col">CREATED BY</th>
                                                     <th scope="col" style="min-width: 120px;">Assign to</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="font-weight"> 01 </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span  class="font-weight mb-25">IS-22120500491</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-nowrap">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Ts11234 </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">India-Content Vendor System</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">DASHBOARD PROJECT FUNCTIONLITY</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Functional</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Hanumant Galgate</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">umaji</span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                      </div>
                    </fieldset>
                </section>
            
                <section class="cards_box_new_dashboard">
                    <fieldset class="fieldset_border_vi mb-2 mx-0 px-1   dashbarod_fieldset_bg">
                        <legend>My Critical Incidents</legend>
                    <div class=" pb-1">
                       <div class="row">
                         <div class="col-xl-3 col-md-4 col-sm-6">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1">
                                <div class="card-body issuesbottom_bg_bor1">
                                <div class="avatar bg-light-danger p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st la-bug las lab font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Open Incidents</p>
                                </div>
                            </div>
                         </div>
                         <div class="col-xl-3 col-md-4 col-sm-6">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1">
                                <div class="card-body issuesbottom_bg_bor2">
                                <div class="avatar bg-light-warning p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st la-info-circle las lab font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Reopen Incidents</p>
                                </div>
                            </div>
                         </div>
                         <div class="col-xl-3 col-md-4 col-sm-6">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1">
                                <div class="card-body issuesbottom_bg_bor3">
                                <div class="avatar bg-light-success p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st  la-users-cog las lab font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Resolved Incidents</p>
                                </div>
                            </div>
                         </div>
                         <div class="col-xl-3 col-md-4 col-sm-6">
                            <div class="card text-center  card_bottom_line_animation gr_bottom_bg_bor1">
                                <div class="card-body issuesbottom_bg_bor4">
                                <div class="avatar bg-light-primary p-50 mb-1">
                                    <div class="avatar-content">
                                    <em class="i_icon_st la-comment-dots las las lab font-medium-4"></em>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">33</h2>
                                <p class="card-text">Feedback Incidents</p>
                                </div>
                            </div>
                         </div>
                       </div>
                       <div class="row match-height">
                        <div class="col-lg-12 col-12">
                            <div class="card card-company-table mb-0">
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table new_dashbaord_table_head table-hover table-striped" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col" style="min-width: 150px;">Incident Code</th>
                                                    <th scope="col" style="min-width: 150px;">Incident Name</th>
                                                    <th scope="col">PROJECT</th>
                                                    <th scope="col">FUNCTIONALITY</th>
                                                    <th scope="col">TYPE</th>
                                                    <th scope="col">CREATED BY</th>
                                                    <th scope="col" style="min-width: 120px;">Assign to</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="font-weight"> 01 </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span  class="font-weight mb-25">IS-22120500491</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-nowrap">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Ts11234 </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">India-Content Vendor System</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">DASHBOARD PROJECT FUNCTIONLITY</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Functional</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Hanumant Galgate</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Umaji M</span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
                </section>
                <section>
                    <fieldset class="fieldset_border_vi mb-2 mx-0 px-1   dashbarod_fieldset_bg">
                        <legend>My Important Action Task</legend>
                    
                    <div class=" py-0 px-0">
                       <div class="row match-height">
                        <div class="col-lg-12 col-12">
                            <div class="card card-company-table mb-0">
                                <div class="card-body p-0">
                                    <div class="table-responsive">
                                        <table class="table new_dashbaord_table_head table-hover table-striped" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col" style="min-width: 150px;">Task Code</th>
                                                    <th scope="col" style="min-width: 150px;">Task Name</th>
                                                    <th scope="col">PROJECT</th>
                                                    <th scope="col">FUNCTIONALITY</th>
                                                    <th scope="col">TYPE</th>
                                                    <th scope="col">CREATED BY</th>
                                                    <th scope="col" style="min-width: 120px;">Assign to</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="font-weight"> 01 </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span  class="font-weight mb-25">IS-22120500491</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-nowrap">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Ts11234 </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">India-Content Vendor System</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">DASHBOARD PROJECT FUNCTIONLITY</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Functional</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Hanumant Galgate</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Umaji M</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="font-weight"> 01 </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span  class="font-weight mb-25">IS-22120500491</span>
                                                        </div>
                                                    </td>
                                                    <td class="text-nowrap">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">Ts11234 </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight mb-25">India-Content Vendor System</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">DASHBOARD PROJECT FUNCTIONLITY</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Functional</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Hanumant Galgate</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <span class="font-weight mr-1">Umaji M</span>
                                                        </div>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      </div>
                    </fieldset>
                </section>
                
               
          <section>
              <div class="pb-1">
                <div class="row">
                    <div class="col-md-12">
                         <fieldset class="fieldset_border_vi mb-2 mx-0 px-1   dashbarod_fieldset_bg">
                        <legend>Team Activity</legend>
                    <div class="card mb-0">
                <div class="card mb-0">
                <div class="card-header px-0" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid px-0">
                    <div class="row">
                      <div class="col-md-9 mobile_margin-px-0_00">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Team Activity Details</h4>
                      </div>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" class="firsttime_none" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row ">
                    
                    <div class="col-md-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                        <label for=""> From Date </label>
                            <div class="input-group input-group-merge w-100">
                              <date-picker  v-model="fromStartDate" placeholder="Select Date"   class="startdate_dash w-100" ></date-picker>                      
                            </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                        <label for=""> To Date </label>
                            <div class="input-group input-group-merge w-100">
                              <date-picker  v-model="toStartDate" placeholder="Select Date"   class="startdate_dash w-100" ></date-picker>                      
                            </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-md-6 col-lg-4 mb-1">
                        <div class="form-group exam-header-padding-y6 user_dashboarad_search_ex_mobile">
                          <button type="submit" class="btn btn-relief-primary mt-2 mr-1"  :disabled="isBothDateSelected" > <span>Search</span></button>
                          <button type="move" class="btn btn-relief-info mt-2 mr-1">
                          <span>Export</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                <div class="row match-height">
                    <div class="col-lg-12 col-12">
                        <div class="card card-company-table">
                            <div class="card-body p-0 px-0">
                                <div class="table-responsive">
                                    <table class="table new_dashbaord_table_head" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col">SR#</th>
                                                <th scope="col">Team Member</th>
                                                <th scope="col">PROJECT NAME</th>
                                                <th scope="col"> Total Task Assigned </th>
                                                <th scope="col"> Total Close</th>
                                                <th scope="col"> Total Spent Hours </th>
                                                <th scope="col"> Total Estimated Hours </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="font-weight"> 01 </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span  class="font-weight mb-25">50</span>
                                                    </div>
                                                </td>
                                                <td class="text-nowrap">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-weight mb-25">SmartWork </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex flex-column">
                                                        <span class="font-weight mb-25">20</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">10</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">80</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <span class="font-weight mr-1">110</span>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        </div>
                </fieldset>
                     </div>
                   </div>
                  </div>
            </section>
        </div>
      </div>
   </div>
</template>
<script>
    import axios from "axios";
    import apiUrl from "../../constants";
    import moment from "moment";
    import VueElementLoading from "vue-element-loading";
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { SearchIcon } from "vue-feather-icons";
    import "vue-loading-overlay/dist/vue-loading.css";
    import DatePicker from "vue2-datepicker";
    import "vue2-datepicker/index.css";
   
    export default {
     name: "Ratingdashboard",
     components: {
        SearchIcon,
        DatePicker,Loading
    },
    data() {
        return {
            isBothDateSelected:null,
            defaultValue: new Date(),
            isLoading: false,
            fullPage: true,
            fromStartDate:'',
            toStartDate:'',
            isSearchRequestActive:true,
            card_content:'card-content',
            collapse:'collapse',
            loginusername:null,
            undt:null,
            empid:null,
            useremail:null,
            designation:null,
            employeeid:null,
            firstday:null,
            lastday:null
        }
    },
    mounted() {
        // let permission = this.$Service.verifyAccess();
        // if(permission){
            this.tokendata = window.localStorage.getItem("token");
            var userdata = window.localStorage.getItem("userdata");
            if (userdata) {
                userdata = JSON.parse(userdata);
                this.undt = userdata;
                this.useremail=userdata.username
                this.empid = userdata.userid
                this.loginusername = (userdata.firstname +' '+ userdata.lastname).toUpperCase()
                this.designation = (userdata.designation).toUpperCase()
                this.employeeid = (userdata.employeeid).toUpperCase()

                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6

                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                this.firstday = moment(String(firstday)).format('YYYY-MM-DD');
                this.lastday = moment(String(lastday)).format('YYYY-MM-DD');
                console.log(this.lastday)
            }
        // }
    },
    methods: {
        mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
        } 
    }
   }
</script>
<style type="text/css">
   
    .firsttime_none.card-contentcollapse{
     display: block!important;
    }
    
    .card-company-table .new_dashbaord_table_head thead th {
    border: 0;
    background: #696f7f!important;
    color: #fff;
}
</style>