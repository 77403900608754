<template>
  <div class="app-content content ">
   <loading
     :active.sync="isLoading"
     :can-cancel="false"
     :is-full-page="true"
     color="orange"
     loader="dots"
     :width="100"
     :height="100"
   ></loading>
   <div class="content-overlay"></div>
   <div class="header-navbar-shadow"></div>
   <div class="content-wrapper pb-1 mt-1">
     <section id="card-navigation modern-horizontal-wizard">
       <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
         <div class="container-fluid">
           <div class="row breadcrumbs-top">
             <div class="col-md-9 content-header-left pl-0 ">
               <h3 class="content-header-title float-left mb-0">Tracker Master</h3>
               <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls " >Tracker List</li>
                 </ol>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card border-warning filter_card_searchbgst">
         <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
           <div class="container-fluid" >
             <div class="row" >
               <div class="w-auto px-1 mobile-padding-0-2">
                 <div class="avatar bg-light-secondary rounded d-inline-flex">
                   <div class="avatar-content">
                     <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                   </div>
                 </div>
               <h4 class="card-title d-inline-flex ml-1">Tracker Details</h4>
               </div>
               <div class="ml-auto d-flex">
               <div v-if="pagefeatures.createtracker" class="px-1 text-right mobile-padding-0-2" >
                 <div class="input-group-append float-right">
                   <router-link to="/trackermaster"><span class="btn btn-sm btn-relief-success" > <span class="mobile_hide_bt">Create Tracker </span> <plus-circle-icon size="1.5x"></plus-circle-icon> </span></router-link>
                 </div>
               </div>
               <span class="rotetions_view_arrow ml-auto mx-1"> 
               <em class="las la-angle-down"></em>
             </span>
             </div>
             </div>
           </div>
         </div>
         
         <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
           <div class="card-body pt-1 px-sm-mobile-10">
             <div class="row">
               <div class="col-sm-6 col-md-6 col-lg-4">
                 <label class="form-label lable-left">Tracker</label>
                 <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Tracker" v-model="search.tracker" :options="trackerlisting" :clear-on-select="true" :multiple="true"/>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-4">
                 <label class="form-label lable-left"> Tracker Type</label>
                 <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Tracker" v-model="search.trackerType" :options="type" :clear-on-select="true"/>
               </div>
               <div class="col-sm-12 col-md-12 col-lg-4 text-right  text-lg-left">
                <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getTrackerList(search,1)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                 <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card border-warning" id="domain-list">
         <div class="card-body p-0 ps ps--active-x">
           <div class="table-responsive" >
             <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
               <thead>
                 <tr>
                   <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                   <th scope="col" class="text-left second-col-sticky">Tracker ID</th>
                   <th scope="col" class="text-left ">Department</th>
                   <th scope="col" class="text-left">Tracker</th>
                   <th scope="col" class="text-left"> Type</th>
                   <th scope="col" class="text-center">IsActive</th>
                   <th scope="col" class="text-center">Action</th>
                 </tr>
               </thead>
               <tbody>
                   <template v-for="(data,index) in trackerlist"  >
                      
                   <tr v-if="!data.edit" v-bind:key="index" >
                    <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                    <td class="text-middle capitalisetext second-col-sticky">{{ data.id }}</td>
                    <td class="text-left capitalisetext ">{{ data.department }}</td>
                     <td class="text-left capitalisetext">{{ data.label }}</td>
                    <td class="text-left capitalisetext">{{ data.type }}</td>
                    <td class="text-center text-capitalize"  v-if="data.isactive === 1">
                    <em class="las la-check-circle custom-class"  style="color: #28c76f;font-size: 24px;"></em>
                      
                    </td>
                    <td class="text-center text-capitalize" v-else>
                      <em class="las la-times-circle custom-class" style="color: #b82323;font-size: 24px;"></em>
                    </td>
                    <td class="centeralign">
                       <span  v-if="pagefeatures.createtracker" class=" devEditEle edit_open_box_exam" @click="activateEditRow(data,index)">
                         <em class="las la-pen custom-class" style="color: #4F46E5;font-size: 24px;cursor: pointer;"> </em>
                       </span>
                     </td>
                    </tr>
                    <tr v-else v-bind:key="index">
                       <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>

                       <td class="text-center first-col-sticky">{{ data.id }}</td>
                       <td class="text-center first-col-sticky">{{ decryptText(data.department) }}</td>
                       <td class="box-inc">
                        <input type="text" class="form-control" placeholder="Enter Tracker" v-model="data.label" @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="!data.label">Tracker required</div>
                      </td>
                      <td class="box-inc ">
                        <input type="text" class="form-control" placeholder="Enter Type" v-model="data.type" @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="!data.type">Type required</div>
                      </td>
                       <td :class="{'isactive==': data.isactive}" class="toggle__button2">
                         <label :for="'isactive-'+index+'-'+data.id" :class="{'active': data.isactive}" class="toggle__button2">
                           <input type="checkbox" :id="'isactive-'+index+'-'+data.id" v-model="data.isactive">
                           <span class="toggle__switch"></span>
                           <span :class="{'off-lable-color': !data.isactive, 'on-label-color': data.isactive }"></span>
                         </label>
                       </td>
                       <td class="centeralign flex--items">
                         <button type="submit" class="btn btn-relief-primary mr-1" style="padding-left: 1rem; padding-right:1rem" v-on:click.stop.prevent="saveData(data)"><check-circle-icon size="1.5x" class="custom-class"></check-circle-icon></button>
                         <button type="submit" class="btn btn-relief-info" style="padding-left: 1rem; padding-right:1rem;" v-on:click.stop.prevent="cancelUpdate(index)"><x-circle-icon size="1.5x" class="custom-class"></x-circle-icon></button>
                       </td>
                    </tr>                      
            
                 </template>
                 <template v-if="trackerlist.length==0">
                   <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                 </template>
               </tbody>
             </table>
           </div>
           <div class="pageCenter text-center mt-2">
             <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
           </div>
         </div>
       </div>
     </section>
   </div>
 </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2'
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import commonMethods from '../../utils/commonMethods';
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon,MinusSquareIcon,PlusSquareIcon } from "vue-feather-icons";
import index from 'watch-size';

export default {
  name: 'TrackerListing',
  components: {
   VueElementLoading,
   Loading,
   Treeselect,
   DatePicker,
   PlusCircleIcon,
   EditIcon,
   CheckCircleIcon,
   XCircleIcon,
   Pagination,
   SearchIcon,
   ExternalLinkIcon,
   XIcon,
   MinusSquareIcon,
   PlusSquareIcon, 
 },
 mixins: [ commonMethods ],
 data() {
   return {
     formconfig: {
      formconfiglist: [],
       prevformconfiglist: [],
     },
     fieldnamelist : [],
     trackerlist:[],
     staticValue: ["Businesstask"],
     search: {
        tracker:null,
        trackerType:null,
     },
     isactive: true,
     isSearchBoxActive: false,
     isUpdateBoxActive: false,
     card_content:'card-content',
     collapse:'collapse',
     isLoading: false,
     undt: null,
     tokendata: null,
     totalcount: 0,
     pageCount: 0,
     page:1,
     pagelimit:apiUrl.LIMIT,
     currPageNum: 0,
     pageoffset:1,
     pagefeatures:[],
     TimeFormat: apiUrl.TimeFormat,
     purchase_date:null,
     expiry_date:null,
    type: apiUrl.trackerlisttype.sort((a, b) => a.label.localeCompare(b.label)),
     arrayValue: [],
     statusConfigList: [],
     statusConfigUpdateList: [],
     updatelist: false,
     newedit: false,
     statuses:[],
     error: {
      tracker:'',
      type:''
    },
    trackerlisting: []
   }
 },
 mounted() {
  let permission = this.$Service.verifyAccess();
  if(permission){
     let userdata = window.localStorage.getItem('userdata');
     if(userdata) {
       userdata = JSON.parse(userdata)
       this.undt = userdata
       this.tokendata = window.localStorage.getItem('token');
      //  this.getTrackerList()
      this.getTrackerListing()
        if(this.$route.params && this.$route.params.search){
          let search = JSON.parse(localStorage.getItem('trackersearchItems'));
          if(search){
            this.getSearchItems(search);
          }else{
            localStorage.removeItem('trackersearchItems');
            this.getTrackerList();
          }
        }else{
            localStorage.removeItem('trackersearchItems');
            this.getTrackerList();
        }
      }
   $(".filter_rotation_onlick").click(function(){
     $(this).toggleClass("common_arrow_rotetion");
   });
  }
 },
 created() {
    this.pagefeatures = {};
    let pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/trackerlisting") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
 methods: {
  getSearchItems(search) {
    this.search.tracker = search.tracker;
    this.search.trackerType = search.trackerType;
    this.isSearchBoxActive = true;
    this.getTrackerList(search);
  },
  searchBoxCollapse() {
     this.isSearchBoxActive = !this.isSearchBoxActive
   },
   clickCallback: function(pageNum) {
     this.pageoffset=pageNum
     this.currPageNum = pageNum - 1
     this.getTrackerList();
     setTimeout(function(){
       $('html, body').animate({
         scrollTop: $("#domain-list").offset().top -70
       });
     }, 500);
   },
  getTrackerList(search,isflag) {
      if(search) {
        localStorage.setItem('trackersearchItems', JSON.stringify(search));
      }
      this.isLoading = true
      let url = 'api/formconfigure/geTrackerListing'
      let payload = new Object({
          limit: apiUrl.LIMIT,
          useremail  : this.undt.username,
          empcode: this.undt.userid
      })
      if(isflag == 1){
        payload.offset = 1;
        this.currPageNum = 0;
      }else{  
        payload.offset = this.pageoffset;
      }
      let searchs = JSON.parse(localStorage.getItem('trackersearchItems'));
      if(search) {
        payload.tracker = search.tracker ? search.tracker : null
        payload.trackerType = search.trackerType ? search.trackerType : null
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.trackerlist = result.data.data.rows
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)


        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.trackerlist=[]
          this.totalcount = 0
         this.pageCount = 0   
        }
      }).catch(e => {
                this.displayError(e)
        }); 
    },
    activateEditRow:function(data,idx){
      this.isLoading = true
      data.edit = true
      this.isLoading = false
   },
   cancelUpdate(idx) {
     this.trackerlist = this.trackerlist.map((data, i) => {
       if(i === idx) {
         return { ...this.trackerlist[idx], edit: false }
       }
       return data
     })
     this.getTrackerList(this.search);
   },
   saveData(data){
    this.isValid = this.validateForm(data) 
    if (this.isValid) {
      
    this.input = {
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
        moduletype : data.label,
        department : data.departmentid,
        type : data.type,
        isactive: data.isactive ? 1 : 0,
        id : parseInt(data.id),
      }
        let apiURL='api/formconfigure/updateTracker'
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result =>{
            this.isLoading = false;
            if(result.data.errorCode == 0){
            Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: 'success',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.getTrackerList(this.search,1);
            }else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                        buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            } else {
                Swal.fire({
                    title: "Failed",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        }).catch(e => {
          this.displayError(e)
        })
      }  
    },
    clearSearchFields() {
      this.search.tracker = null;
      this.search.trackerType = null;
      localStorage.removeItem('trackersearchItems');
      this.getTrackerList();
   },
   validateForm(data){
    var isValid = true 
      if (!data.label) {
        this.error.tracker = 'Enter Tracker Name'
        isValid = false
      } else {
        this.error.tracker = ''
      }
      if (!data.type) {
        this.error.type = 'Enter Tracker Type'
        isValid = false
      } else {
        this.error.type = ''
      }
      return isValid
  },
  getTrackerListing() {
      this.isLoading = true
      let url = 'api/formconfigure/geTracker'
      let payload = new Object({
          useremail  : this.undt.username,
          empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.trackerlisting = result.data.data.rows
        } else {
          this.trackerlisting=[] 
        }
      }).catch(e => {
                this.displayError(e)
        }); 
    },   
 }
}

</script>