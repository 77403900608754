<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-1 pad_top_mar_bot-5">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Workstep</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li v-if="pagefeatures.breadcrum_project" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Wizard',path:'project',btnstate:'view',rowdata:backpagedata})">Projects</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Projects</li>
                                    <li v-if="pagefeatures.breadcrum_application" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application',path:'application',btnstate:'view',rowdata:backpagedata})">Applications</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Applications</li>
                                    <li v-if="pagefeatures.breadcrum_module" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Module',path:'projectmodule',btnstate:'view',rowdata:backpagedata})">Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Modules</li>
                                    <li v-if="pagefeatures.breadcrum_appmodule" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application Module',path:'applicationmodule',btnstate:'view',rowdata:backpagedata})">Application Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Application Modules</li>
                                    <li v-if="pagefeatures.breadcrum_section" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'SectionModule',path:'sectionmodule',btnstate:'view',rowdata:backpagedata})">Sections</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Sections</li>
                                    <li v-if="pagefeatures.breadcrum_functionality" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'FuntionalityModule',path:'functionalitymodule',btnstate:'view',rowdata:backpagedata})">Functionality</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Functionality</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">
                    <div class="row" style="max-height:510px; overflow-y:auto;">
                        <div class="col-12">
                            <div v-if="pagefeatures.searchfunctionality" class="card card-body" >
                                <div class="row breadcrumbs-top">
                                    <div class="col-12 title">
                                        <h4 class="card-title">SEARCH FUNCTIONALITY DETAILS</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Project</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.projectMasterid"
                                            :options="search.projectMasterOpts"
                                            @input="selprojectid"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Project Module</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.projectModuleid"
                                            :options="search.projectModuleOpts"
                                            @input="selprojectModuleid"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Application</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationMasterid"
                                            :options="search.applicationMasterOpts"
                                            @input="selApplicationid"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Application Module</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.applicationModuleid"
                                            :options="search.applicationModuleOpts"
                                            @input="selApplicationModuleid"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Section</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.sectionMasterid"
                                            :options="search.sectionMasterOpts"
                                            @input="selSectionid"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <label class="form-label lable-left">Functionality</label>
                                        <treeselect
                                            class="projectmdllistdd capitalisetext"
                                            v-model="search.functionalityMasterid"
                                            :options="search.functionalityMasterOpts"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                                        <button
                                            type="submit"
                                            class="btn btn-primary mt-2 mr-1"
                                            @click="getFilteredSectionData()"
                                        >
                                            <search-icon size="1.5x" class="custom-class"></search-icon><span>Search</span>
                                        </button>
                                        <button
                                            type="reset"
                                            class="btn btn-outline-secondary mt-2"
                                            @click="resetRecords()"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="pagefeatures.functionalitydetails" class="card border-tabs" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                                <div class="card-header" style="cursor:pointer;" v-on:click="mysearchrequestollapse">
                                    <div class="avatar bg-light-info rounded" style="margin-left:33%">
                                        <div class="avatar-content">
                                            <grid-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></grid-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title" style="margin-left:-449px;">FUNCTIONALITY DETAILS</h4>
                                    <div class="heading-elements" style="color:blue;">
                                        <ul class="list-inline mb-0">
                                            <li>
                                                <chevron-down-icon v-if="showdownicon" size="3x" class="custom-class"></chevron-down-icon>
                                                <chevron-up-icon v-if="showupicon" size="3x" class="custom-class"></chevron-up-icon>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-if="showfunctionalities" style="margin-top:10px">
                                    <div class="card-body">
                                        <div class="row">
                                            <div  v-for="(data1,index1) in functionalitylist" class="col-lg-3 col-md-6 col-12" v-bind:key="index1">
                                                <div v-bind:key="data1.functionlitymasterid" class="card border cursor-pointer " :class="[index1 < 9 ? stylevariation[index1].label : 'bg-secondary']"  @click="getworksteplist(data1)">
                                                    <div class="card-body">
                                                        <div class="d-flex justify-content-between  text-white">
                                                            <box-icon size="40x" class="custom-class"></box-icon>
                                                        </div>
                                                        <div class="my-1">
                                                            <h3 class="text-white">{{data1.functionlityname}}</h3>
                                                            <h5 class="text-white">{{dateFormat(data1.createddate)}}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card border-warning filter_card_searchbgst" >
                        <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="myfilterequestollapse">
                            <div class="container-fluid">
                            <div class="row fc_my-05">
                                <div class="px-1 w-auto mobile-padding-0-2">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                            ></search-icon>
                                        </div>
                                        </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search Workstep Details</h4>
                                </div>
                                <div class="ml-auto d-flex pr-1">
                                <div v-if="pagefeatures.createworkstep || showbutton" class=" px-1 text-right mobile-padding-0-2" >
                                    <div class="input-group-append displayblk float-right">
                                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#workstepmodulemaster"  type="button" data-backdrop="static" data-keyboard="false" @click="addmodule">  <span class="mobile_hide_bt"> Create Work Step</span> <plus-circle-icon size="1.5x" class="custom-class" ></plus-circle-icon></button>
                                    </div>
                                </div>
                                <span class="rotetions_view_arrow"> 
                                    <em class="las la-angle-down"></em>
                                </span>
                               </div>
                            </div>
                            </div>
                        </div>
                        <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                            <div class="card-body px-sm-mobile-10">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-1">
                                        <label class="form-label lable-left">Project Name</label>
                                        <treeselect
                                            class="capitalisetext"
                                            placeholder="Enter min 3 chars to search project name"
                                            v-model="search.projectMasterid"
                                            v-on:search-change="projectChange"
                                            :options="search.projectMasterOpts"
                                            @input="selprojectid2"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-1">
                                        <label class="form-label lable-left">Application</label>
                                        <treeselect
                                            class="capitalisetext"
                                            placeholder="Select Application Name"
                                            v-model="search.applicationMasterid"
                                            :options="search.applicationMasterOpts"
                                            @input="selApplicationid2"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-1">
                                        <label class="form-label lable-left">Project Module</label>
                                        <treeselect
                                            class="capitalisetext"
                                            placeholder="Select Project Module"
                                            v-model="search.projectModuleid"
                                            :options="search.projectModuleOpts"
                                            @input="selprojectModuleid2"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-1">
                                        <label class="form-label lable-left">Application Module</label>
                                        <treeselect
                                            class="capitalisetext"
                                            placeholder="Select Application Module"
                                            v-model="search.applicationModuleid"
                                            :options="search.applicationModuleOpts"
                                            @input="selApplicationModuleid2"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-1">
                                        <label class="form-label lable-left">Section</label>
                                        <treeselect
                                            class="capitalisetext"
                                            placeholder="Select Section Name"
                                            v-model="search.sectionMasterid"
                                            :options="search.sectionMasterOpts"
                                            @input="selSectionid2"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-1">
                                        <label class="form-label lable-left">Functionality</label>
                                        <treeselect
                                            class="capitalisetext"
                                            placeholder="Select Functionality"
                                            v-model="search.functionalityMasterid"
                                            :options="search.functionalityMasterOpts"
                                        />
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-0">
                                        <label class="form-label lable-left">Assigned/Unassigned</label>
                                        <treeselect class="capitalisetext" placeholder="Select workstep state" v-model="search.state" :options="search.stateopts"/>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 mb-0 text-right">
                                        <button
                                            type="submit"
                                            class="btn btn-relief-primary mt-2 mr-1"
                                            @click="getworksteplist(search)"
                                        >
                                            <search-icon
                                                size="1.5x"
                                                class="custom-class"
                                              ></search-icon> <span>Search</span>
                                        </button>
                                        <button
                                            type="reset"
                                            class="btn btn-outline-secondary mt-2 mr-1"
                                            @click="resetRecords()"
                                        >
                                        <x-icon  size="1.5x"                                                class="custom-class"                                              ></x-icon> <span>Clear</span>
                                        </button>
                                         <button 
                                            type="move" 
                                             class="btn btn-relief-primary mt-2" 
                                             @click="ExportXL()"
                                         > <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="showproject">
                        <div class="col-md-12">
                            <div class="card mb-3">
                              <div class=""  v-if="pagefeatures.createworkstep">
                                <div class="card-header p-2">
                                    <h4 class="card-title">
                                            <div class="input-group-append">
                                                <h4 class="card-title">Functionality Name: <strong class="capitalisetext">{{workstepmodule.functionalityname}}</strong></h4>
                                            </div>
                                    </h4>
                                </div>
                            </div>
                                <div class="">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="workstepList">
                                                <div class="col-12">
                                                    <div class="">
                                                       
                                                        <div class="table-responsive datatableview">
                                                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="srno align-middle first-col-sticky py-1" style="min-width:45px;">Sr#</th>
                                                                        <th scope="col" class="align-middle second-col-sticky" style="width: 225px;">Workstep Name</th>
                                                                        <th scope="col" class="align-middle">Technology</th>
                                                                        <th scope="col" class="align-middle">Project Name</th>
                                                                        <th scope="col" class="align-middle">Module Name</th>
                                                                        <th scope="col" class="align-middle">Section Name</th>
                                                                        <th  scope="col" class="align-middle text-nowrap">Created Date</th>
                                                                        <th scope="col" class="clsaction align-middle">Actions</th>
                                                                    </tr>
                                                                </thead>                                                                <tbody>
                                                                    <template v-for="(data,index) in companymasterlist">        
                                                                        <tr v-bind:key="'functionlist'+data.moduleid+Math.floor(Math.random() * 1000000000)">
                                                                            <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                            <td data-column="Workstep Name" class="second-col-sticky"><span style="word-break: break-word;">{{data.workstepname}}</span></td>
                                                                            <td data-column="Technology">
                                                                                <span class="font-weight-normal capitalisetext" style="word-break: break-word;">{{data.technology}}</span>
                                                                            </td>
                                                                            <td data-column="Project Name">
                                                                                <span class="font-weight-normal capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectId',data)">{{data.projectname}}</span>
                                                                            </td>
                                                                            <td data-column="Module Name">
                                                                                <span class="font-weight-normal capitalisetext">{{data.projectmodulename ? data.projectmodulename : data.projmodname}}</span>
                                                                            </td>
                                                                            <td data-column="Section Name">
                                                                                <span class="font-weight-normal capitalisetext">{{data.sectionname}}</span>
                                                                            </td>
                                                                            <td data-column="Created Date" class=" text-nowrap">{{dateFormat(data.createddate)}}</td>
                                                                            
                                                                            <td data-column="Actions" class="clsaction">
                                                                               
                                                                                <div class="dropdown">
                                                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                        <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                    </button>
                                                                                    <div class="dropdown-menu">
                                                                                        <a class="dropdown-item" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                                            <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                            <span>View Micro Task</span>
                                                                                        </a>
                                                                                        <a class="dropdown-item"
                                                                                         v-if="parseInt(data.counttask) < 1 && (data.projectisactive ==1 && data.projectisapproved ==1)"  @click="redirecttourl('create',data)" href="javascript:void(0);">
                                                                                            <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                                                            <span>Add Micro Task</span>
                                                                                        </a>
                                                                                        <a class="dropdown-item devEditEle" href="javascript:void(0);"   data-toggle="modal" data-target="#workstepmodulemaster" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                            <span>Edit</span>
                                                                                        </a>
                                                                                    </div>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="companymasterlist.length==0 && isresponce">
                                                                        <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="pageCenter pt-2 text-center">
                                    <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="workstepmodulemaster">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1"  style="background-color:#8c564b !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <span class=" ml-50">Add/Update Workstep Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row"> 
                            <div class="col-12 mb-1">
                                <div class="table-responsive">
                                  <table class="table table-sm dataTableListing mb-0" aria-describedby="mydesc">
                                    <thead>
                                      <tr>
                                        <th scope="col" class="align-middle">Project</th>
                                        <th scope="col" class="align-middle" >Application</th>
                                        <th scope="col" class="align-middle"  style="min-width:130px">Project Module</th>
                                        <th scope="col" class="align-middle"  style="min-width:150px">Application Module</th>
                                        <th scope="col" class="align-middle"  style="min-width:110px">Section Name</th>
                                        
                                        <th scope="col" class="align-middle" >Functionality</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <td class="font-weight-normal">{{this.workstepmodule.project ? this.workstepmodule.project:'NA'}}</td>
                                        <td class="font-weight-normal">
                                         <span class="font-weight-normal" v-html="showAppNames(this.workstepmodule.applicationname)"></span>
                                        </td>
                                        <td class="font-weight-normal">{{this.workstepmodule.projectmodule ? this.workstepmodule.projectmodule:'NA'}}</td>
                                        <td class="font-weight-normal">{{this.workstepmodule.applicationmodulename ? this.workstepmodule.applicationmodulename:'NA'}}</td>
                                        <td class="font-weight-normal">{{this.workstepmodule.sectionname ? this.workstepmodule.sectionname:'NA'}}</td>
                                        <td class="font-weight-normal">{{this.workstepmodule.functionalityname ? this.workstepmodule.functionalityname:'NA'}}</td>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="devBusinessVerticleData">Business Task Name</label>
                                    <treeselect class="capitalisetext" placeholder="Enter Business Task Name" :disabled="isBtmFieldDisabled" v-model="workstepmodule.businesstaskid" :options="workstepmodule.tasklist" @input="selbtmid" @close="validateForm('t47',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.workstepmodule.businesstaskid">{{ this.errors.workstepmodule.businesstaskid}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="devBusinessVerticleData">Business Task Code</label>
                                    <treeselect class="capitalisetext" placeholder="Enter Business Task Code" 
                                    :disabled="isBTMnameselected"
                                    v-model="workstepmodule.businesstaskcode" :options="workstepmodule.taskcode" @input="selbtmcode" @close="validateForm('t47',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.workstepmodule.businesstaskcode">{{ this.errors.workstepmodule.businesstaskcode}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label">Workstep Name</label>
                                    <input type="text" class="form-control" v-model="workstepmodule.workstepname" placeholder="Enter Workstep Name" @blur="validateForm('t47',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.workstepmodule.workstepname">{{ this.errors.workstepmodule.workstepname }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="appmdldesc">Technology</label>
                                    <input class="form-control appmdldesc" v-model="workstepmodule.technology" id="Technology" placeholder="Technology Description "
                                    v-bind:class="{ 
                                        'form-control': true,
                                        '': !valid2Text(workstepmodule.technology) && technologyBlured, }"
                                        v-on:blur="technologyBlured = true" />
                                    <div class="errorinputmsg" v-if="!valid2Text(workstepmodule.technology) && technologyBlured">Technology is required</div>
                                </div>
                            </div>
                            <div class="col-12 row mobile_margin-px-0">
                                <div class="form-group mg-bot0 col-sm-12 col-lg-8 mobile-px-0" >
                                    <label for="appmdldesc">Activity Name</label>
                                    <treeselect class="capitalisetext" placeholder="Select activity name" v-model="workstepmodule.activity" :options="workstepmodule.activityhoursopts" @input="selActivityName"/>
                                </div>
                                <div class="form-group mg-bot0 col-sm-12 col-lg-4 mobile-px-0 mr-lg-0">
                                    <label for="appmdldesc">Activity Hours</label>
                                    <vue-timepicker type="time" id="qahours" format="HH:mm"  v-model="workstepmodule.activityhours" @close="validateForm('t47',$event)"></vue-timepicker>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.workstepmodule.activity || this.errors.workstepmodule.activityhours" style="padding-left: 14px">Activity name and hours required</div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-relief-primary mr-1"  @click="saveData('t47',$event)" id="submitbtnt47">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary" @click="resetform()">Clear</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.timepickersec .controls{
    right: 44%;
}
.timepickersec .display-time{
    display: block;
    width: 48% !important;
    height: 2.114rem !important;
    padding: 0.138rem 0.438rem !important;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-left: 10%;
}
input#qahours {
    border-radius: 5px;
    height: 2.5em;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import { EyeIcon,EditIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,PackageIcon,MoreVerticalIcon,Edit2Icon,ChevronUpIcon,ChevronDownIcon,PlusIcon,PlusCircleIcon,XIcon,FolderIcon,PlusSquareIcon,ExternalLinkIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import commonMethods from '../../utils/commonMethods';
export default {
    name:'SectionModule',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        FolderIcon,
        PlusSquareIcon,
        ChevronUpIcon,
        ChevronDownIcon,
        ExternalLinkIcon,
        VueTimepicker,PlusCircleIcon,XIcon
    },    
    mixins: [ commonMethods ],
    data() {
        return {
            isBTMnameselected: false,
            isBtmFieldDisabled: false,
            showbutton:false,
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            workstepmodule:{
                sectionmasterid:null,
                sectionmasterlist:null,
                applicationmoduleid:null,
                applicationmodulelist:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                applicationid:null,
                applicationlist:null,
                functionalityname:null,
                functionalityid:null,
                appprojectdd:null,
                projectmoduleid:null,
                label:null,
                project:null,
                projectmodule:null,
                applicationname:null,
                applicationmodulename:null,
                sectionname:null,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                businesstaskid:null,
                businesstaskcode:null,
                tasklist:null,
                taskcode:null,
                workstepname: null,
                technology:null,
                developmenthours: null,
                codereviewhours:null,
                infrasetupanddeploymenthours:null,
                meetingfeaturesdiscussionhrs: null,
                supporthrs: null,
                qahours:null,
                iseditstate:false,
                activity: null,
                activityhours: null,
                activityhoursopts: apiUrl.activityhoursopts.sort((a, b) => a.label.localeCompare(b.label)),
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            showfunctionalities:true,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                workstepmodule:{
                    sectionmasterid:null,
                    applicationmoduleid:null,
                    projectid:null,
                    applicationid:null,
                    functionalityid:null,
                    businesstaskid:null,
                    businesstaskcode:null,
                    workstepname: null,
                    technology:null,
                    developmenthours:null,
                    codereviewhours:null,
                    infrasetupanddeploymenthours:null,
                    qahours:null,
                    activity: null,
                    activityhours: null,
                },
            },
            valid:false,
            showdownicon:true,
            showupicon:false,
            functionalitylist:null,
            search: {
                projectMasterid: null,
                projectMasterOpts: [],
                projectModuleid: null,
                projectModuleOpts: [],
                applicationMasterid: null,
                applicationMasterOpts: [],
                applicationModuleid: null,
                applicationModuleOpts: [],
                sectionMasterid: null,
                sectionMasterOpts: [],
                functionalityMasterid : null,
                functionalityMasterOpts: [],
                state: null,
                stateopts: [{
                    id: 'Assigned',
                    label: 'Assigned'
                }, {
                    id: 'Unassigned',
                    label: 'Unassigned'
                }],
                projectmoduleid: null,
                applicationModuleid: null,
                sectionMasterid: null,
                sectionmasterid: null,
                functionalityMasterid: null,
                functionalitymasterid: null
            },
            isSearchRequestActive : false,
            backpagedata:null,
            technologyBlured:false,
            tokendata: null,
            isresponce:false,
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/workstep') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){    
        let permission = this.$Service.verifyAccess();
        if(permission){   
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
            }
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view' || this.$route.params.backbtnstate == 'createWS' || this.$route.params.backbtnstate == 'pathApp') {
                    this.isSearchRequestActive = true;
                    this.showbutton=true;
                    this.backpagedata=this.$route.params.clickeddata      
                    this.getprojectmoduleapplications(this.$route.params.clickeddata)
                    this.getworksteplist(this.$route.params.clickeddata);
                    this.search.projectMasterid = this.$route.params.clickeddata.projectid ? this.$route.params.clickeddata.projectid :null;
                    if(this.search.projectMasterid){
                        this.projectChangebyid([this.search.projectMasterid]);
                    }
                    this.search.applicationMasterid = this.$route.params.clickeddata.applicationid;
                    if(this.search.applicationMasterid){
                        this.getDropdownApplicationdata('t12');
                    }
                    this.search.projectModuleid = this.$route.params.clickeddata.projectmoduleid;
                    if(this.search.projectModuleid){
                        this.getDropdownApplicationdata('t36');
                    }
                    this.search.applicationModuleid = this.$route.params.clickeddata.applicationmoduleid;
                    if(this.search.applicationModuleid){
                        this.getDropdownApplicationdata('t34');
                    }
                    this.search.sectionMasterid = this.$route.params.clickeddata.sectionmasterid;
                    if(this.search.sectionMasterid){
                        this.getDropdownApplicationdata('t35');
                    }
                    this.search.functionalityMasterid = this.$route.params.clickeddata.functionlitymasterid;
                    if(this.search.functionalityMasterid){
                        this.getDropdownApplicationdata('t37');
                    }
                }
                if(this.$route.params.backbtnstate == 'createWS') {
                    this.workstepmodule.businesstaskid = this.backpagedata.businesstaskid
                    this.workstepmodule.businesstaskcode = this.backpagedata.businesstaskcode
                    this.isBtmFieldDisabled = true
                    setTimeout(function(){
                        $('#commonaddmodule').click();
                    }, 1000);
                }
            }else if(this.$route.params && this.$route.params.search){
                let search = JSON.parse(localStorage.getItem('workstepsearchItems'));
                if(search){
                    this.getSearchItems(search);
                }else{
                    localStorage.removeItem('workstepsearchItems');
                    this.getworksteplist([]);
                }
            }else{
                localStorage.removeItem('workstepsearchItems');
                this.getworksteplist([]);
            }
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        getSearchItems(search){
            this.isSearchRequestActive = true;
            this.search.projectMasterOpts = search.projectMasterOpts;
            this.search.projectMasterid = search.projectMasterid;
            this.search.applicationMasterid = search.applicationMasterid;
            this.search.projectModuleid = search.projectModuleid;
            this.search.applicationModuleid = search.applicationModuleid;
            this.search.sectionMasterid = search.sectionMasterid;
            this.search.functionalityMasterid = search.functionalityMasterid;
            this.search.state = search.state;
            this.getworksteplist(this.search);
        },
        showAppNames(prlist){
            let responsetr='';
            if(!prlist) return 'NA'
            if(typeof(prlist) != 'object') return prlist
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsetr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsetr +=prlist[i]
                }
            }
            return responsetr
        },
        redirecttobreadkumb:function(objThis){
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
                if(objThis.rowdata.projectlevel) {
                    if(objThis.path === 'functionalitymodule' && objThis.rowdata.projectlevel < 6) return
                    if(objThis.path === 'sectionmodule' && objThis.rowdata.projectlevel < 5) return
                    if(objThis.path === 'applicationmodule' && objThis.rowdata.projectlevel < 4) return
                    if(objThis.path === 'application' && objThis.rowdata.projectlevel < 3) return
                    if(objThis.path === 'projectmodule' && objThis.rowdata.projectlevel < 2) return
                }
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate == 'create') {
                this.$router.push({
                    name: 'TaskCreate',
                    path: '/tasks/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata,
                        ispage:'worksteppage'
                    }
                })
            } else if(btnstate == 'view') {
                this.$router.push({
                    name: 'TaskList',
                    path: '/tasks/list',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if (btnstate == 'projectId') {
				this.$router.push({
					path: '/project/summary?pid='+rowdata.projectid,
				})
			}
        },
        mysearchrequestollapse:function(){
            if(this.showfunctionalities == true){
                this.showupicon=true
                this.showdownicon=false
            }else if(this.showfunctionalities == false){
                this.showdownicon=true
                this.showupicon=false
            }
            this.showfunctionalities=!this.showfunctionalities
        },
        myfilterequestollapse: function() {
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        projectChange: function (node) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                        localStorage.setItem('projectMasterOpts', JSON.stringify(result.data.data));
                    } else {
                        this.search.projectMasterOpts = [];
                        localStorage.removeItem('projectMasterOpts')
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        projectChangebyid: function (projectidarr) {
            if(projectidarr && projectidarr.length>0) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectidarr: projectidarr,
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    isfrompage: 'listingpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                    } else {
                        this.search.projectMasterOpts = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        functionalitylistdata:function(mdl,objThis){            
            this.isLoading=true
            this.inputappl = {
               limit: apiUrl.LIMIT,
               offset: this.pageoffset,
               useremail: this.undt.username,
               empcode: this.undt.userid,
            }
            //let apiURL='api/listing/getallfunctionality'
            let apiURL = 'api/projectapplication/getfunctionalitylist'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.functionalitylist=result.data.data
                }
            }).catch(e => {
                    this.displayError(e)
                });
        },
        getDropdownData(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            
            axios({
            'method': 'POST',
            'url': apiUrl,
            tbl: "t11",
            'data': {useremail: this.undt.username,empcode: this.undt.userid},
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data
                    } else {
                        this.search.projectMasterOpts = null
                    }
                }).catch(e => {
                    this.displayError(e)
                })     
        },
        selActivityName(state) {
            if(!state) {
                this.errors.workstepmodule.activity = true
            } else {
                this.errors.workstepmodule.activity = false
            }
        },
        selprojectid(state, value) {
            this.search.projectModuleid = null
            this.search.applicationMasterid = null
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.projectMasterid = state;

            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = [];
            } else {
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
            }
        },
        selprojectid2(state, value) {
            this.search.projectMasterid = state;
            if (!state) {
                this.search.projectModuleid = null
                this.search.applicationMasterid = null
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
                this.search.functionalityMasterid = null;
                this.search.applicationMasterOpts = [];
            } else {
                this.getDropdownApplicationdata("t12");
                this.getDropdownApplicationdata("t36");
            }
        },
        selprojectModuleid(state, value) {
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.projectModuleid = state;

            if (!state && this.isAddState == false) {
                this.search.applicationModuleOpts = [];
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selprojectModuleid2(state) {
            this.search.projectModuleid = state;
            if (!state) {
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
                this.search.functionalityMasterid = null;
                this.search.applicationModuleOpts = [];
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selApplicationid(state, value) {
            this.search.projectModuleid = null;
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.applicationMasterid = state;

            if (!state && this.isAddState == false) {
                this.search.projectModuleOpts = [];
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },
        selApplicationid2(state) {
            this.search.applicationMasterid = state;
            if (!state) {
                this.search.projectModuleid = null;
                this.search.applicationModuleid = null;
                this.search.sectionMasterid = null;
                this.search.functionalityMasterid = null;
                this.search.projectModuleOpts = [];
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },
        selApplicationModuleid(state,value) {
            this.search.applicationModuleid = state;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            if (!state && this.isAddState == false) {
                this.search.sectionMasterOpts = [];
            } else {
                this.getDropdownApplicationdata("t35");
            }
        },
        selApplicationModuleid2(state) {
            this.search.applicationModuleid = state;
            if (!state) {
                this.search.sectionMasterid = null;
                this.search.functionalityMasterid = null;
                this.search.sectionMasterOpts = [];
            } else {
                this.getDropdownApplicationdata("t35");
            }
        },
        selSectionid(state, value) {
            this.search.sectionMasterid = state;
            this.search.functionalityMasterid = null;
            if (!state && this.isAddState == false) {
                this.search.functionalityMasterOpts = [];
            } else {
                this.getDropdownApplicationdata("t37");
            }
        },
        selSectionid2(state) {
            this.search.sectionMasterid = state;
            if (!state) {
                this.search.functionalityMasterid = null;
                this.search.functionalityMasterOpts = [];
            } else {
                this.getDropdownApplicationdata("t37");
            }
        },
        getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            if(mdl === "t36") { 
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t12") { 
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t34") { 
                this.input.projectModuleid = this.search.projectModuleid
            } else if(mdl === "t35") { 
                this.input.applicationModuleid = this.search.applicationModuleid
            } else if(mdl === "t37") { 
                this.input.sectionMasterid = this.search.sectionMasterid
            }
            
            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                        }
                        else if(mdl === "t35") {
                            this.search.sectionMasterOpts = result.data.data;
                        } else if(mdl === "t37") {
                            this.search.functionalityMasterOpts = result.data.data;
                        }
                    }  else if(result.data.errorCode == 3){
                            Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                        } else {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = []
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = []
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = [];
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = [];
                        } else if(mdl === "t37") {
                            this.search.functionalityMasterOpts = [];
                        }
                    }
                }).catch(e => {
                    this.displayError(e)
                })

        },
        getFilteredSectionData: function() {
            this.isLoading=true
            let uri = "api/projectapplication/getfunctionalitylist"
            this.input = {
                tbl: "t37",
                isSearch: false,
                section: "workstep",
                isFilterSelect: 1,
                isFilterPayload: {},
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }

            if(this.search.projectMasterid && this.search.projectMasterid != null) {
                this.input.isFilterPayload.projectMasterid = this.search.projectMasterid
            }
            if(this.search.applicationModuleid && this.search.applicationModuleid != null) {
                this.input.isFilterPayload.applicationModuleid = this.search.applicationModuleid
            }
            if(this.search.applicationMasterid && this.search.applicationMasterid != null) {
                this.input.isFilterPayload.applicationMasterid = this.search.applicationMasterid
            }
            if(this.search.projectModuleid && this.search.projectModuleid != null) {
                this.input.isFilterPayload.projectModuleid = this.search.projectModuleid
            }
            if(this.search.sectionMasterid && this.search.sectionMasterid != null) {
                this.input.isFilterPayload.sectionMasterid = this.search.sectionMasterid
            }
            if(this.search.functionalityMasterid && this.search.functionalityMasterid != null) {
                this.input.isFilterPayload.functionalityMasterid = this.search.functionalityMasterid
            }

            axios.post(uri, this.input)
                .then(result => {
                    this.isLoading = false
                    this.showfunctionalities = true
                    this.showupicon = false
                    this.showdownicon = true
                    this.showproject = false
                    if (result.data.errorCode == 0) {
                        this.functionalitylist = result.data.data;
                    } else {
                        this.functionalitylist = [];
                        Swal.fire({
                            title: "",
                            text: "No Data found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                })
        },
        resetRecords: function () {
            this.search.applicationModuleid = null;
            this.search.applicationMasterid = null;
            this.search.projectMasterid = null;
            this.search.projectModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.businessTaskMasterid = null;
            this.projectnameList = null;
            this.search.projectMasterOpts = null;
            this.search.businessTaskMasterOpts = null;
            this.search.state = null;
            localStorage.removeItem("workstepsearchItems");
            this.getworksteplist({})
        },
        getworksteplist:function(rowdata){
            if(rowdata){
                localStorage.setItem('workstepsearchItems', JSON.stringify(rowdata));
            }
            this.islistLoading=true
            this.clickedprojdata=rowdata
            this.input = {
                tbl:'t47',
                
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
                functionlitymasterid:rowdata.functionlitymasterid,
                projectMasterid: rowdata.projectMasterid?rowdata.projectMasterid:rowdata.projectid,
                projectModuleid: rowdata.projectModuleid?rowdata.projectModuleid:rowdata.projectmoduleid,
                applicationMasterid: rowdata.applicationMasterid?rowdata.applicationMasterid: rowdata.applicationid,
                applicationModuleid: rowdata.applicationModuleid?rowdata.applicationModuleid:rowdata.applicationmoduleid,
                sectionMasterid: rowdata.sectionMasterid? rowdata.sectionMasterid: rowdata.sectionmasterid,
                functionalityMasterid: rowdata.functionalityMasterid?rowdata.functionalityMasterid:rowdata.functionlitymasterid,
                businessTaskMasterid: rowdata.businessTaskMasterid,
                state: rowdata.state,
                uniqueid: this.undt.userid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let url = 'api/workstep/list'
            this.workstepmodule.project=this.clickedprojdata.projectname
            this.workstepmodule.projectmodule=this.clickedprojdata.projectmodulename
            this.workstepmodule.appprojectdd = this.clickedprojdata.projectid
            this.workstepmodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.workstepmodule.applicationid = this.clickedprojdata.applicationid
            this.workstepmodule.applicationname = this.clickedprojdata.applicationname
            this.workstepmodule.applicationmodulename = this.clickedprojdata.applicationmodulename
            this.workstepmodule.sectionname = this.clickedprojdata.sectionname
            this.workstepmodule.sectionmasterid = this.clickedprojdata.sectionmasterid
            this.workstepmodule.functionalityname = this.clickedprojdata.functionlityname
            this.workstepmodule.functionalityid = this.clickedprojdata.functionlitymasterid
            this.workstepmodule.applicationmoduleid = this.clickedprojdata.applicationmoduleid
            this.workstepmodule.projectid = this.clickedprojdata.projectid
            this.workstepmodule.label = this.clickedprojdata.label
            this.workstepmodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.workstepmodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.workstepmodule.companyid=this.clickedprojdata.companyid
            this.getApplicationdata('t3','workstepmodule')
            this.getApplicationdata('t2','workstepmodule')
            this.getApplicationdata('t1','workstepmodule')
            this.getApplicationdata('t9','workstepmodule')
            this.getBusinessTaskCodeforWorkstep('t9','workstepmodule')
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isresponce=true
                this.islistLoading=false; 
                this.showfunctionalities=false
                if(result.data.errorCode == 0){
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else{
                    this.companymasterlist=[]
                    this.showproject=true;
                    this.totalcount = 0
                    
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);                
            }).catch(e => {
                    this.displayError(e)
                })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getworksteplist(this.clickedprojdata)
            }

            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#workstepList").offset().top -70
                });
            }, 500);
        },
        validText:function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text:function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        setSelected:function(value)
        {
            this.selected=value
        },
        selbtmid(state, value) {
            if(!state) {
                this.errors.workstepmodule.businesstaskid = "Business task name required"
                this.workstepmodule.businesstaskcode = null
                this.isBTMnameselected = false
            } else {
                this.errors.workstepmodule.businesstaskid = ""
                if(this.workstepmodule.businesstaskid){
                    let code = this.workstepmodule.taskcode.filter(i => i.businesstaskmasterid == this.workstepmodule.businesstaskid)
                    this.workstepmodule.businesstaskcode = code[0].id
                    this.isBtmFieldDisabled = true
                    this.isBTMnameselected = false
                }
            }
        },
        selbtmcode(state, value) {
            if(!state) {
                this.errors.workstepmodule.businesstaskcode = "Business task code required"
                this.workstepmodule.businesstaskid = null
                this.isBtmFieldDisabled = false
            } else {
                this.errors.workstepmodule.businesstaskcode = ""
                if(this.workstepmodule.businesstaskcode){
                    let code = this.workstepmodule.tasklist.filter(i => i.businesstaskcode == this.workstepmodule.businesstaskcode)
                    this.workstepmodule.businesstaskid = code[0].id
                    this.isBtmFieldDisabled = false
                    this.isBTMnameselected = true
                }
            }
        },
        validateForm:function(mdl,objThis){
            this.isAddState=false
            var isValid=true;
            this.clearerrorForm();
            this.technologyBlured = true;
            if(mdl=="t47"){
                let hours = this.workstepmodule.activityhours
                let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                if(typeof hours === 'string' && hours.length === 5) isValidHours = true
                if(isValidHours && hours !== null && typeof hours === 'object') {
                    this.workstepmodule.activityhours = `${hours.HH}:${hours.mm}`
                } else {
                    this.errors.workstepmodule.activityhours = true
                }
                
                if(!isValidHours) {
                    this.errors.workstepmodule.activityhours = true
                    isValid = false
                } else {
                    this.errors.workstepmodule.activityhours = false
                }
                if(!this.workstepmodule.activity) {
                    this.errors.workstepmodule.activity = true
                    isValid = false
                } else {
                    this.errors.workstepmodule.activity = false
                }
                if(!this.workstepmodule.projectid){
                    this.errors.workstepmodule.projectid="Project required";
                    isValid= false;
                }
                if(!this.workstepmodule.businesstaskcode && !this.workstepmodule.businesstaskid){
                    this.errors.workstepmodule.businesstaskcode="Business task name or code is required";
                    isValid= false;
                }   
                if(!this.workstepmodule.workstepname) {
                    this.errors.workstepmodule.workstepname = "Workstep name is required"
                    isValid = false;
                }
                if(!this.workstepmodule.technology){
                    this.errors.workstepmodule.technology="Technology required";
                    isValid= false;
                }
            }
            return isValid
        },
        validateHHMM:function(str){
            //return /^([0-9]|0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9]|10[0-9]):[0-5][0-9]$/.test(str);
            return /^[0-9][0-9]:[0-5][0-9]$/.test(str);
        },
        validEmail:function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm:function(){
            this.errors= {
                workstepmodule:{
                    sectionmasterid:null,
                    applicationmoduleid:null,
                    projectid:null,
                    applicationid:null,
                    functionalityid:null,
                    businesstaskid:null,
                    businesstaskcode:null,
                    technology:null,
                    developmenthours:null,
                    codereviewhours:null,
                    infrasetupanddeploymenthours:null,
                    qahours:null,
                },
            };
        },
        resetform:function(){
            this.validateForm();
            this.workstepmodule.workstepname=null
            this.workstepmodule.businesstaskid=null
            this.workstepmodule.businesstaskcode=null
            this.workstepmodule.technology=null
            this.technologyBlured = null
            this.workstepmodule.developmenthours=null
            this.workstepmodule.codereviewhours=null
            this.workstepmodule.infrasetupanddeploymenthours=null
            this.workstepmodule.qahours=null
            this.isBtmFieldDisabled = false
            this.workstepmodule.activity = null
            this.workstepmodule.activityhours = {'HH':'', 'mm':''}
        },
        openEditModule:function(currRowData){
            this.editRowData = currRowData
            this.workstepmodule.businesstaskid = currRowData.businesstaskid
            if(this.workstepmodule.businesstaskid){
                this.isBtmFieldDisabled = false
                if(this.workstepmodule.businesstaskcode){
                    this.isBtmFieldDisabled = true
                }
            }
            this.workstepmodule.businesstaskcode = currRowData.businesstaskcode
            if(this.workstepmodule.businesstaskcode){
                this.isBTMnameselected = false
                if(this.workstepmodule.businesstaskid){
                    this.isBTMnameselected = true
                }
                this.errors.workstepmodule.businesstaskcode = ""
            }
            if(currRowData.developmenthours) {
                this.workstepmodule.activity = 'developmenthours'
                this.workstepmodule.activityhours = currRowData.developmenthours
            }
            if(currRowData.codereviewhours) {
                this.workstepmodule.activity = 'codereviewhours'
                this.workstepmodule.activityhours = currRowData.codereviewhours
            }
            if(currRowData.infrasetupanddeploymenthours) {
                this.workstepmodule.activity = 'infrasetupanddeploymenthours'
                this.workstepmodule.activityhours = currRowData.infrasetupanddeploymenthours
            }
            if(currRowData.qahours) {
                this.workstepmodule.activity = 'qahours'
                this.workstepmodule.activityhours = currRowData.qahours
            }
            if(currRowData.meetingfeaturesdiscussionhrs) {
                this.workstepmodule.activity = 'meetingfeaturesdiscussionhrs'
                this.workstepmodule.activityhours = currRowData.meetingfeaturesdiscussionhrs
            }
            if(currRowData.supporthrs) {
                this.workstepmodule.activity = 'supporthrs'
                this.workstepmodule.activityhours = currRowData.supporthrs
            }
            this.workstepmodule.technology = currRowData.technology
           

            this.workstepmodule.workstepname = currRowData.workstepname

            this.workstepmodule.project = currRowData.projectname
            this.workstepmodule.projectmodule= currRowData.projectmodulename ? currRowData.projectmodulename : currRowData.projmodname 
            this.workstepmodule.applicationname= currRowData.applicationname 
            this.getprojectmoduleapplications(currRowData)
            this.workstepmodule.applicationmodulename= currRowData.applicationmodulename
            this.workstepmodule.sectionname= currRowData.sectionname
            this.workstepmodule.functionalityname= currRowData.functionlityname
            this.workstepmodule.projecttypeid= currRowData.projecttypeid
            this.workstepmodule.companyid= currRowData.companyid
            this.workstepmodule.businessverticleid =currRowData.businessverticleid
            this.workstepmodule.sectionmasterid = currRowData.sectionmasterid
            this.workstepmodule.projectid = currRowData.projectid
            this.getApplicationdata('t9', 'workstepmodule')
            this.getBusinessTaskCodeforWorkstep('t9','workstepmodule')
            this.workstepmodule.applicationmoduleid = currRowData.applicationmoduleid
            this.workstepmodule.applicationid = currRowData.applicationid
            this.workstepmodule.functionalityid = currRowData.functionlitymasterid
            this.workstepmodule.iseditstate = true
            this.isAddState=true
            
        },
        getprojectmoduleapplications: function(rowdata){
            let id = rowdata.projectmoduleid ? rowdata.projectmoduleid : rowdata.projmodid
            if(!id) return; 
            this.inputprojappl = { id,useremail: this.undt.username,empcode: this.undt.userid }
            let apiURL='api/projectmodule/getapplications'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.workstepmodule.applicationname=[]
                    
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                            this.workstepmodule.applicationname.push(element.applicationname)
                        }
                    });
                this.workstepmodule.applicationname = this.workstepmodule.applicationname.toString()
                }else{
                    this.applicationmodule.applicationid=null
                }
            }).catch(e => {
                    this.displayError(e)
             })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
        saveData:function(tblNm,objThis){
            if( tblNm=="t47"){
                this.valid=this.validateForm(tblNm)
            }else{
                this.valid=false;
            }
            if (this.valid) {
                this.input = {}
                let apiURL='api/workstep/saveupdate'
                if(tblNm == 't47'){
                    this.input.sectionmasterid=this.workstepmodule.sectionmasterid
                    this.input.projectid=this.workstepmodule.projectid
                    this.input.projectmoduleid=this.workstepmodule.projectmoduleid
                    this.input.applicationmoduleid=this.workstepmodule.applicationmoduleid
                    this.input.applicationid=this.workstepmodule.applicationid
                    this.input.functionalityid=this.workstepmodule.functionalityid
                    
                    this.input.workstepname = this.workstepmodule.workstepname
                    this.input.technology=this.workstepmodule.technology
                    let elemHours = this.workstepmodule.activityhoursopts.filter(activity => activity.id === this.workstepmodule.activity)
                    let elemWithoutHours = this.workstepmodule.activityhoursopts.filter(activity => activity.id !== this.workstepmodule.activity)
                    if(elemHours.length) {
                        this.input[elemHours[0].id] = this.workstepmodule.activityhours
                    }
                    if(elemWithoutHours.length) {
                        elemWithoutHours.forEach((activity) => this.input[activity.id] = null)
                    }
                    this.input.section='save'
                    this.input.actionby=this.undt.username
                } 
                if(this.workstepmodule.iseditstate == true){
                    this.input.section='update'
                    this.input.id=this.editRowData.workstepid
                }  
                if(this.workstepmodule.businesstaskid){
                    let code = this.workstepmodule.taskcode.filter(i => i.businesstaskmasterid == this.workstepmodule.businesstaskid)
                    this.input.businesstaskcode = code[0].label
                    this.input.businesstaskid=this.workstepmodule.businesstaskid
                }
                if(this.workstepmodule.businesstaskcode){
                    let code = this.workstepmodule.tasklist.filter(i => i.businesstaskcode == this.workstepmodule.businesstaskcode)
                    this.input.businesstaskid = code[0].id
                    this.input.businesstaskcode=this.workstepmodule.businesstaskcode
                }
                this.isAddState=false
                // this.isSearchRequestActive=false
                this.input.useremail= this.undt.username
                this.input.empcode= this.undt.userid
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        $('.close').click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                         this.rowdata={
                            label : this.workstepmodule.label,
                            projectname : this.workstepmodule.project,
                            projectmodulename : this.workstepmodule.projectmodule,
                            appprojectdd : this.workstepmodule.projectid,
                            projectmoduleid : this.workstepmodule.projectmoduleid,
                            moduleid : this.workstepmodule.sectionmasterid,
                            sectionmasterid: this.workstepmodule.sectionmasterid,
                            applicationid : this.workstepmodule.applicationid,
                            applicationname : this.workstepmodule.applicationname,
                            applicationmodulename : this.workstepmodule.applicationmodulename,
                            sectionname : this.workstepmodule.sectionname,
                            applicationmoduleid : this.workstepmodule.applicationmoduleid,
                            projectid : this.workstepmodule.projectid,
                            projecttypeid : this.workstepmodule.projecttypeid,
                            businessverticleid : this.workstepmodule.businessverticleid,
                            companyid : this.workstepmodule.companyid,
                            functionlitymasterid: this.workstepmodule.functionalityid,
                            functionlityname: this.workstepmodule.functionlityname
                        }
                        this.getworksteplist(this.search);
                        this.isLoading = false
                       
                        this.clearerrorForm();
                        this.resetform();
                    }
                    else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        selfmsectionmdldata:function(state,value){
            this.workstepmodule.sectionmasterid=null
            this.workstepmodule.sectionmasterid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.sectionmasterid="Section Required"
            }else{                
                this.errors.workstepmodule.sectionmasterid=null
                this.getfiltereddata('workstepmodule');
            }
        },
        selfmprojectdata:function(state,value){
            this.workstepmodule.projectid=null
            this.workstepmodule.projectid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.projectid="Project Required"
            }else{                
                this.errors.workstepmodule.projectid=null
            }
        },
        selfmapplmdldata:function(state,value){
            this.workstepmodule.applicationmoduleid=null
            this.workstepmodule.applicationmoduleid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.applicationmoduleid='Application Module required'
            }else{                
                this.errors.workstepmodule.applicationmoduleid=null
            }
        },
        selfmappmdldata:function(state,value){
            this.workstepmodule.applicationid=null
            this.workstepmodule.applicationid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.applicationid='Application required'
            }else{                
                this.errors.workstepmodule.applicationid=null
            }
        },
        selappapltype:function(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess:function(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
        getApplicationdata:function(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,empcode: this.undt.userid,
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'workstepmodule'){
                this.inputappl = {
                    tbl:mdl,
                    useremail: this.undt.username,empcode: this.undt.userid,
                }
                if(mdl=='t9'){
                    this.inputappl = {
                        projectid: this.workstepmodule.projectid,
                        empid:this.undt.userid,
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                    }
                    apiURL='api/searching/getBusinessTaskforWorkstep'
                }
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't2'){
                        this.workstepmodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'workstepmodule'){
                            this.workstepmodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.workstepmodule.companylist=result.data.data.rows
                        return true;
                    }else if(mdl == 't9'){
                        this.workstepmodule.tasklist=result.data.data
                        return true;
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
            
        },

        getBusinessTaskCodeforWorkstep:function(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            if(curracttab == 'workstepmodule'){
                this.inputappl = {
                    tbl:mdl
                }
                if(mdl=='t9'){
                    this.inputappl = {
                        projectid: this.workstepmodule.projectid,
                        empid:this.undt.userid,
                        useremail: this.undt.username,
                    }
                    var apiURL='api/searching/getBusinessTaskCodeforWorkstep'
                }
            }
            this.inputappl.useremail= this.undt.username
            this.inputappl.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't9'){
                        this.workstepmodule.taskcode=result.data.data
                        return true;                            
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        addmodule:function(){
            this.workstepmodule.iseditstate = false   
            this.isSearchRequestActive = true    
            
        },
        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit:null,
                offset:null,
                state:this.search.state,
                projectid:this.search.projectname,
                projectMasterid:this.search.projectMasterid,
                applicationMasterid:this.search.applicationid ? this.search.applicationid : this.search.applicationMasterid ? this.search.applicationMasterid : null,
                projectModuleid:this.search.projectmoduleid ? this.search.projectmoduleid : this.search.projectModuleid ? this.search.projectModuleid : null,
                applicationModuleid:this.search.applicationmoduleid ? this.search.applicationmoduleid : this.search.applicationModuleid ? this.search.applicationModuleid : null,
                sectionMasterid:this.search.sectionmasterid ? this.search.sectionmasterid : this.search.sectionMasterid ? this.search.sectionMasterid : null,
                functionalityMasterid:this.search.functionalitymasterid ? this.search.functionalitymasterid : this.search.functionalityMasterid ? this.search.functionalityMasterid : null,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }; 
            axios({
                method: "POST",
                url: `api/workstep/list`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if (result.data.errorCode == 0) {
                this.exportxlsdata = result.data.data.rows;
                var filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
                this.islistLoading = false;
            }).catch(e => {
                    this.displayError(e)
                });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                var ShowLabel = 'workstepReportDetails_'+filename
                var JSONData = readExportdata
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#,Workstep Name,Technology,Project Name,Module Name,Section Name, Created Date' + '\r\n'
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let workstepname = index.hasOwnProperty('workstepname') ? index.workstepname : '';
                let technology = index.hasOwnProperty('technology') ? index.technology : '';
                let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
                let projmodname = index.hasOwnProperty('projmodname') ? index.projmodname == null||index.projmodname.toLowerCase() == 'na'?'':index.projmodname : '';
                let sectionname = index.hasOwnProperty('sectionname') ? index.sectionname == null||index.sectionname.toLowerCase() == 'na'?'':index.sectionname : '';
                let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
                createddate = this.dateMONFormat(createddate);
                    row += '"' + srno + '",',
                    row += '"' + workstepname + '",',
                    row += '"' + technology + '",',
                    row += '"' + projectname + '",',
                    row += '"' + projmodname + '",',
                    row += '"' + sectionname + '",',
                    row += '"' + createddate + '",'
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                return;
                }
                var fileName = 'workstepReportDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });  
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
            },
            }
        }
</script>
