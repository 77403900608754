import { useAttrs } from "vue";
import CryptoJS from 'crypto-js';

export default {
api_url: 'https://smartbeesapi.xbees.in/',
s3url: 'https://pimstage.s3.ap-south-1.amazonaws.com/',
LIMIT: 21,
userID: [3,5,815,6,7,860],
TimeFormat: 'YYYY-MM-DD',
encpChangepsstoken: '3a1773f06826a2d4f882e9334089e3f4',
encpCurStatoken : 'e4786ca440cba85191a40494c15e7e62',
languagelist: [{
  id: "English",
  label: "English"
}, {
  id: "Arabic",
  label: "Arabic"
}],
dayslist:[{
    id: "Sunday",
    label: "Sunday"
  }, {
    id: "Monday",
    label: "Monday"
  },{
    id: "Tuesday",
    label: "Tuesday"
  }, 
  {
    id: "Wednesday",
    label: "Wednesday"
  }, 
  {
    id: "Thursday",
    label: "Thursday"
  }, 
  {
    id: "Friday",
    label: "Friday"
  }, 
  {
    id: "Saturday",
    label: "Saturday"
  }, 
],
scripttypelist: [{
  id: "Manual",
  label: "Manual"
}, {
  id: "Automation",
  label: "Automation"
}],
tcscriptstatuslist: [
  {
  id: "Created",
  label: "Created"
  }, {
    id: "Review In Progress",
    label: "Review In Progress"
  }, {
    id: "Reviewed",
    label: "Reviewed"
  }, {
    id: "Approved",
    label: "Approved"
  }, {
    id: "Freeze",
    label: "Freeze"
  }
],
tcsbulkmappingstatuslist: [
  {
  id: "Created",
  label: "Created"
  }, {
    id: "Review In Progress",
    label: "Review In Progress"
  }, {
    id: "Reviewed",
    label: "Reviewed"
  }, {
    id: "Approved",
    label: "Approved"
  }, {
    id: "Freeze",
    label: "Freeze"
  }
],
environmentlist:  [{
  id: "Android",
  label: "Android"
}, {
  id: "IOS",
  label: "IOS"
},{
  id: "Mobile",
  label: "Mobile"
},{
  id: "Desktop",
  label: "Desktop"
}],
countrylist: [{
  id: "IND",
  label: "IND"
},{
  id: "KSA",
  label: "KSA"
},{
  id: "UAE",
  label: "UAE"
}],
tasklevel:[
    { id: 1, text: 'Project' },
    { id: 2, text: 'Project Module' },
    { id: 3, text: 'Application' },
    { id: 4, text: 'Application Module' },
    { id: 5, text: 'Development Section/Part' },
    { id: 6, text: 'Functionality/Feature' }
  ],
tasktype:[
  { id: 1, text: 'New' },
  { id: 2, text: 'CR' },
  { id: 3, text: 'Issues' },
],
tasknamemaster:[
  { id: 1, text: 'Tech Stack Setup' },
  { id: 2, text: 'Infra Setup' },
  { id: 3, text: 'Issue Tracking Setup' },
  { id: 4, text: 'Incident Management' },
  { id: 5, text: 'Audit Controls' },
  { id: 6, text: 'Application Control' },
  { id: 7, text: 'Review Process Setup' },
  { id: 8, text: 'Security Setup' },
  { id: 9, text: 'Code Review& Quality Process Setup' },
  { id: 10, text: 'CICD Setup' },
  { id: 11, text: 'Monitring' },
  { id: 12, text: 'InfraUpgrade' },
  { id: 13, text: 'CostOptimization' },
  { id: 14, text: 'Performance' },
  { id: 15, text: 'Development' },
  { id: 16, text: 'QA and Testing' },
  { id: 17, text: 'Security Testing' },
  { id: 18, text: 'Live Issues' },
],
employemnttype:[
  { id: 1, text: 'Full-Time' },
  { id: 2, text: 'Part-Time' },
  { id: 3, text: 'Self-Employed' },
  { id: 4, text: 'Freelance' },
  { id: 5, text: 'Internship' },
  { id: 6, text: 'Trainee' }
],
edudegree:[
  { id: 1, text: 'SSC' },
  { id: 2, text: 'HSC' },
  { id: 3, text: 'BE' },
  { id: 4, text: 'Diploma' },
  { id: 5, text: 'MCA' },
  { id: 6, text: 'MCS' }
],
relationlist:[
  { id: 1, label: 'managed you directly' },
  { id: 2, label: 'reported directly to you' },
  { id: 3, label: 'was senior to you but didnt manage directly' },
  { id: 4, label: 'was client of you' },
  { id: 5, label: 'taught you' },
  { id: 6, label: 'mentored you' }
],
stylevariation:[
  { label: 'bg-primary' },
  { label: 'bg-warning' },
  { label: 'bg-primary' },
  { label: 'bg-info' },
  { label: 'bg-success' },
  { label: 'bg-info' },
  { label: 'bg-danger' },
  { label: 'bg-secondary' },
  { label: 'bg-warning' },
  { label: 'bg-primary' },
  { label: 'bg-warning' },
  { label: 'bg-primary' },
  { label: 'bg-info' },
  { label: 'bg-success' },
  { label: 'bg-info' },
  { label: 'bg-danger' },
  { label: 'bg-secondary' },
  { label: 'bg-warning' },
],
taskstatus:[
  {id:'Product Pipeline',label:'Product Pipeline'},
  {id:'Product WIP',label:'Product WIP'},
  {id:'Product Completed',label:'Product Completed'},
  {id:'Product WIP 2',label:'Product WIP 2'},
  {id:'Tech and Business Review',label:'Tech and Business Review'},
  {id:'Tech Pipeline',label:'Tech Pipeline'},
  {id:'Tech WIP',label:'Tech WIP'},
  {id:'QA Pipeline',label:'QA Pipeline'},
  {id:'QA WIP',label:'QA WIP'},
  {id:'Tech Issue Resolution',label:'Tech Issue Resolution'}, 
  {id:'Design Review',label:'Design Review'}, 
  {id:'UAT',label:'UAT'},
  {id:'UAT Signoff', label: 'UAT Signoff'},
  {id:'UAT Feedback', label: 'UAT Feedback'},
  {id:'Live',label:'Live'},
  {id:'Completed',label:'Completed'},
  {id:'OnHold',label:'OnHold'},
  {id:'Discarded',label:'Discarded'},
],
formstaskstatus:[
  {id:'Product Pipeline',label:'Product Pipeline', type: 'creation' , sno: 1},
  {id:'Product WIP',label:'Product WIP', type: 'creation', parentkey : ['Product Pipeline', 'QA WIP'], sno: 2},
  {id:'Product Completed',label:'Product Completed', type: 'creation', parentkey : ['Product WIP'], sno: 3},
  {id:'Product WIP 2',label:'Product WIP 2', type: 'updation', parentkey: ['Product Completed', 'Tech and Business Review', 'Tech WIP', 'QA Pipeline', 'UAT', 'UAT Feedback'], sno: 4},
  {id:'Tech and Business Review',label:'Tech and Business Review', type: 'updation', parentkey: [ 'Product Completed', 'Product WIP 2', 'Tech WIP'], sno: 5},
  {id:'Tech Pipeline',label:'Tech Pipeline', type: 'updation', parentkey : ['Tech and Business Review', 'Tech WIP'], sno: 6},
  {id:'Tech WIP',label:'Tech WIP', type: 'updation', parentkey: [ 'Tech Pipeline', 'QA Pipeline',  ], sno: 7},
  {id:'QA Pipeline',label:'QA Pipeline', type: 'updation', parentkey: [ 'Tech WIP', 'QA WIP' ], sno: 8},
  {id:'QA WIP',label:'QA WIP', type: 'updation', parentkey: [ 'QA Pipeline' ], sno: 9},
  {id:'Tech Issue Resolution',label:'Tech Issue Resolution', type: 'updation', parentkey: [ 'QA WIP' ], sno: 10},
  {id:'Design Review',label:'Design Review',type: 'updation', parentkey: [ 'QA WIP' ], sno: 11},
  {id:'UAT',label:'UAT', type: 'updation', parentkey: [ 'QA WIP', 'UAT Feedback' ], sno: 12},
  {id:'UAT Signoff',label:'UAT Signoff', type: 'updation', parentkey: [ 'UAT' ], sno: 14},
  {id:'UAT Feedback',label:'UAT Feedback', type: 'updation', parentkey: [ 'UAT' ], sno: 13},
  {id:'Live',label:'Live', type: 'updation' , parentkey: [ 'UAT Signoff' ], sno: 15},
  {id:'Completed',label:'Completed', type: 'updation', parentkey: [ 'Live' ], sno: 16},
  {id:'OnHold',label:'OnHold', type: 'updation', parentkey : [ 'Product Pipeline', 'Product WIP', 'Product Completed', 'Tech and Business Review', 'Product WIP 2', 'Tech Pipeline', 'Tech WIP', 'QA Pipeline', 'QA WIP', 'UAT', 'UAT Feedback', 'UAT Signoff'], sno: 17},
  {id:'Discarded',label:'Discarded', type: 'updation', parentkey : [ 'Product Pipeline', 'Product WIP', 'Product Completed', 'Tech and Business Review', 'Product WIP 2', 'Tech Pipeline', 'Tech WIP', 'QA Pipeline', 'QA WIP', 'UAT', 'UAT Feedback', 'UAT Signoff'], sno: 18},
],
status:[
  {id:'open',label:'Open'},
  {id:'resolved',label:'Resolved'},
  {id:'closed',label:'Closed'},
  {id:'reopen',label:'Reopen'},
  {id:'review',label:'Review'},
  {id:'approved',label:'Approved'},
  {id:'assigned',label:'Assigned'},
],
estimateshours:[
  {id:'smalltask',label:'Small task (1 day)', sno: 1 },
  {id:'minortask',label:'Minor task (1 to 2 days)', sno: 2 },
  {id:'majortask',label:'Major task (3 to 6 days)', sno: 3 },
  {id:'complextask',label:'Complex task (6 to 15 days)', sno: 4 },
  {id:'highlycomplextask',label:'Highly Complex task (15 to 30+ days)', sno: 5 },
],
tasktypekeylist:[
  {id:'Normal',label:'Normal'},
  {id:'Key',label:'Key'},
  {id:'Urgent',label:'Urgent'},
],
deptlist: [
  { id: 'Accounts', label: 'Accounts' },
  { id: 'B2B', label: 'B2B' },
  { id: 'B2C', label: 'B2C' },
  { id: 'B2D', label: 'B2D' },
  { id: 'CC', label: 'CC' },
  { id: 'Design', label: 'Design' },
  { id: 'Edutainment', label: 'Edutainment' },
  { id: 'Finance', label: 'Finance' },
  { id: 'HR', label: 'HR' },
  { id: 'Logistics', label: 'Logistics' },
  { id: 'Marketing', label: 'Marketing' },
  { id: 'Product', label: 'Product' },
  { id: 'Purchase', label: 'Purchase' },
  { id: 'Stockist', label: 'Stockist' },
  { id: 'Sourcing', label: 'Sourcing' },
  { id: 'Tech', label: 'Tech' },
  { id: 'Warehouse', label: 'Warehouse' },
],
referenceslist:[
  {id:'Customer Care',label:'Customer Care'},
  {id:'End Customer',label:'End Customer'},
  {id:'Finance',label:'Finance'},
  {id:'Logistics',label:'Logistics'},
  {id:'Product',label:'Product'},
  {id:'Quality Check',label:'Quality Check'},
  {id:'Technology',label:'Technology'},
  {id:'Warehouse',label:'Warehouse'},
],
typelist:[
  {id:'Business Task',label:'Business Task - New Feature'},
  {id:'CR',label:'CR'},
  {id:'Enhancement',label:'Business Task - Enhancement'},
  {id:'Escalation',label:'Escalation'},
  {id:'Process',label:'Process'},
  {id:'Program',label:'Program'},
  {id:'Support',label:'Support'},
],
admintypelist:[
  {id:'Business Task',label:'Business Task - New Feature'},
  {id:'CR',label:'CR'},
  {id:'Enhancement',label:'Business Task - Enhancement'},
  {id:'Escalation',label:'Escalation'},
  {id:'Process',label:'Process'},
  {id:'Program',label:'Program'},
  {id:'Support',label:'Support'},
  {id:'Tech Task',label:'Tech Task'},
],
keylist:[
  {id:'Normal',label:'Normal'},
  {id:'Key',label:'Key'},
  {id:'Urgent',label:'Urgent'},
],
acivitylist:[
  {id:'Code Review',label:'Code Review'},
  {id:'Cloud Support',label:'Cloud Support'},
  {id:'Cost Optimization',label:'Cost Optimization'},
  {id:'Cloud R&D',label:'Cloud R&D'},
  {id:'Cloud Billing Support',label:'Cloud Billing Support'},
  {id:'Daily Standup',label:'Daily Standup'},
  {id:'Daily Tech Review meeting',label:'Daily Tech Review meeting'},
  {id:'Deployment',label:'Deployment'},
  {id:'Design Review Issue fixing',label:'Design Review Issue fixing'},
  {id:'Development/Coding',label:'Development/Coding'},
  {id:'Document Creation',label:'Document Creation'},
  {id:'Escalation',label:'Escalation'},
  {id:'Feature Discussion',label:'Feature Discussion'},
  {id:'Issue fixing',label:'Issue fixing'},
  {id:'Issue Management',label:'Issue Management'},
  {id:'Interviews',label:'Interviews'},
  {id:'Infra Down-Gradation Support',label:'Infra Down-Gradation Support'},
  {id:'Infra Up-Gradation Support',label:'Infra Up-Gradation Support'},
  {id:'Meeting',label:'Meeting'},
  {id:'New Infra Creation Support',label:'New Infra Creation Support'},
  {id:'Peer Help',label:'Peer Help'},
  {id:'Planning',label:'Planning'},
  {id:'QA - Testcase/scenario prep',label:'QA - Testcase/scenario prep'},
  {id:'QA - Regression Testing',label:'QA - Regression Testing'},
  {id:'Requirement Review Estimation',label:'Requirement Review Estimation'},
  {id:'R&D',label:'R&D'},
  {id:'Reports',label:'Reports'},
  {id:'Security Testing',label:'Security Testing'},
  {id:'Security Audit',label:'Security Audit'},
  {id:'Support',label:'Support'},
  {id:'Study',label:'Study'},
  {id:'Sprint Planning',label:'Sprint Planning'},
  {id:'Solution Designing',label:'Solution Designing'},
  {id:'Task Monitering',label:'Task Monitering'},
  {id:'Team Councelling',label:'Team Councelling'},
  {id:'UAT Issue fixing',label:'UAT Issue fixing'},
  {id:'UAT Testing',label:'UAT Testing'},
  {id:'VAPT',label:'VAPT'},
  {id:'WBS Creations',label:'WBS Creations'},
],
logacivitylist:[
  {id:'Code Review',label:'Code Review'},
  {id:'Cloud Support',label:'Cloud Support'},
  {id:'Cost Optimization',label:'Cost Optimization'},
  {id:'Cloud R&D',label:'Cloud R&D'},
  {id:'Cloud Billing Support',label:'Cloud Billing Support'},
  {id:'Deployment',label:'Deployment'},
  {id:'Daily Standup',label:'Daily Standup'},
  {id:'Daily Tech Review meeting',label:'Daily Tech Review meeting'},
  {id:'Design Review Issue fixing',label:'Design Review Issue fixing'},
  {id:'Development/Coding',label:'Development/Coding'},
  {id:'Document Creation',label:'Document Creation'},
  {id:'Escalation',label:'Escalation'},
  {id:'Feature Discussion',label:'Feature Discussion'},
  {id:'Full Day Leave',label:'Full Day Leave'},
  {id:'Half Day Leave',label:'Half Day Leave'},
  {id:'Issue fixing',label:'Issue fixing'},
  {id:'Issue Management',label:'Issue Management'},
  {id:'Interviews',label:'Interviews'},
  {id:'Infra Down-Gradation Support',label:'Infra Down-Gradation Support'},
  {id:'Infra Up-Gradation Support',label:'Infra Up-Gradation Support'},
  {id:'Meeting',label:'Meeting'},
  {id:'New Infra Creation Support',label:'New Infra Creation Support'},
  {id:'Peer Help',label:'Peer Help'},
  {id:'Planning',label:'Planning'},
  {id:'QA - Testcase/scenario prep',label:'QA - Testcase/scenario prep'},
  {id:'QA - Regression Testing',label:'QA - Regression Testing'},
  {id:'Requirement Review Estimation',label:'Requirement Review Estimation'},
  {id:'Reports',label:'Reports'},
  {id:'R&D',label:'R&D'},
  {id:'Sprint Planning',label:'Sprint Planning'},
  {id:'Security Testing',label:'Security Testing'},
  {id:'Security Audit',label:'Security Audit'},
  {id:'Support',label:'Support'},
  {id:'Study',label:'Study'},
  {id:'Solution Designing',label:'Solution Designing'},
  {id:'Task Monitering',label:'Task Monitering'},
  {id:'Team Councelling',label:'Team Councelling'},
  {id:'UAT Issue fixing',label:'UAT Issue fixing'},
  {id:'UAT Testing',label:'UAT Testing'},
  {id:'VAPT',label:'VAPT'}, 
  {id:'WBS Creations',label:'WBS Creations'},
],
businessRoleType : [
  { id: 1, label: 'Bug' },
  { id: 2, label: 'Improvement' },
  { id: 3, label: 'New Feature' },
  { id: 5, label: 'Functional' },
  { id: 6, label: 'Performance' },
  { id: 7, label: 'Usability' },
],
testcaseStatusList:[
  { id: 'created', label: 'Created' },
  { id: 'fail', label: 'Fail' },
  { id: 'pass', label: 'Pass' },
],
ticketTypeList: [
  { id: 16, label: 'Issue' },
  { id: 17, label: 'Support/Enquiry' },
  { id: 3, label: 'New Feature' }
],
mrticketTypelist: [
  { id: 16, label: 'Issue' },
  { id: 17, label: 'Support/Enquiry' },
  { id: 3, label: 'New Feature' },
  { id: 21, label: 'Content Correction/Enhancement'},
  { id: 22, label: 'Power Content' },
  { id: 23, label: 'Image Reshoot Request' },
  { id: 24, label: 'Incorrect Images Uploaded' },
  { id: 25, label: 'Grouping Request' },
  { id: 26, label: 'Multi Tagging Request' }
],
courseStatus:[
  {id:true,label:'Completed'},
  {id:false,label:'Incomplete'},
],
businessStakeHolderList: [
  { id: 'Sanket Hattimathur', label: 'Sanket Hattimathur', dept: 'Sourcing' },
  { id: 'Prashant Chauhan', label: 'Prashant Chauhan', dept: 'Sourcing' },
  { id: 'Vicky Zhang', label: 'Vicky Zhang', dept: 'Sourcing' },
  { id: 'Prashant Jadhav', label: 'Prashant Jadhav', dept: 'Tech' },
  { id: 'Peeyush Munjhu', label: 'Peeyush Munjhu', dept: 'Logistics' },
  { id: 'Vivek Goel', label: 'Vivek Goel', dept: 'Marketing' },
  { id: 'Ajoy Kar', label: 'Ajoy Kar', dept: 'Warehouse' },
],
statuslist:[
  { id: 'open', label: 'Open' },
  { id: 'reopen', label: 'Re-open' },
  { id: 'resolved', label: 'Resolved' },
  { id: 'closed', label: 'Closed' },
  { id: 'feedback', label: 'Feedback' },
],
incidenttypelist:[
  {id:'escalation',label:'Escalation'},
  {id:'infra incident',label:'IT Infra Incident'},
  {id:'live application incident',label:'Live Application Incident'},
  {id:'operational incident',label:'Operational Incident'},
  {id:'policy violation incident',label:'Policy Violation Incident'},
  {id:'security incident',label:'Security Incident'},
  {id:'work bench',label:'Work Bench'},
],
correctiveaction:[
  {id:'suspension with pay',label:'Suspension With Pay'},
  {id:'suspension with out pay',label:'Suspension With Out Pay'},
  {id:'termination',label:'Termination'},
  {id:'other',label:'other'},
],
correctiveaction2:[
  {id:'lack of projects or tasks ',label:'Lack Of Projects Or Tasks '},
  {id:'skill set ',label:'Skill Set '},
  {id:'performance',label:'Performance'},
],
testcasereuslt:[
  { id: 'fail', label: 'Fail' },
  { id: 'pass', label: 'Pass' },
  { id: 'NA', label: 'NA' },
],
testscriptreuslt:[
  { id: 1, label: 'Product Approved' },
  { id: 0, label: 'Product Declined' },
],
issueStatuslist:[
  { id: 'open', label: 'Open' },
  { id: 'reopen', label: 'Re-open' },
  { id: 'resolved', label: 'Resolved' },
  { id: 'closed', label: 'Closed' },
  { id: 'feedback', label: 'Feedback' },
  { id: 'assigned', label: 'Assigned' },
  { id: 'deferred', label: 'Deferred' },
  { id: 'not a bug', label: 'Not a bug' },
  { id: 'rejected', label: 'Rejected' },
  { id: 'duplicate', label: 'Duplicate' },
  { id: 'verified', label: 'Verified' },
  { id: 'retest', label: 'Retest' },
  { id: 'pending retest', label: 'Pending retest' },
  { id: 'fixed', label: 'Fixed' },
],
testexecutiontype:[
  { id: 'Feature Test', label: 'Feature Test' }, 
  { id: 'Integration Test', label: 'Integration Test' },
  { id: 'Regression Test', label: 'Regression Test' },
],
testcyclestatuslist:[
  { id: "Created", label: "Created" }, 
  { id: "Review In Progress", label: "Review In Progress" }, 
  { id: "Reviewed", label: "Reviewed" }, 
  {id: "Freeze", label: "Freeze" },
  {id: "Product Pending for Review", label: "Product Pending for Review" },
  {id: "In Product Review", label: "In Product Review" },
  {id: "Product Approved", label: "Product Approved" },
  {id: "Product Declined", label: "Product Declined" }
],
releasenotestatus:[
  { id: 'Pending', label: 'Pending' },
  { id: 'Approved', label: 'Approved' },
  { id: 'Pending for Deployement', label: 'Pending for Deployement' },
  { id: 'Deployed', label: 'Deployed' }
],
blockertypes:[
  { id: 'Blocks', label: 'Blocks' }, 
  { id: 'Is Blocked By', label: 'Is Blocked By' },
  { id: 'Clones', label: 'Clones' },
  { id: 'Is Cloned By', label: 'Is Cloned By' },
  { id: 'Duplicates', label: 'Duplicates' },
  { id: 'Is Duplicated By', label: 'Is Duplicated By' },
  { id: 'Relates To', label: 'Relates To' }
],
zingHrIntimeSources:[
  { id: 'Biometric', label: 'Biometric' },
  { id: 'Leaves', label: 'Leaves' },
  { id: 'Regularization', label: 'Regularization' },
  { id: 'OutDoor', label: 'OutDoor' },
  { id: 'ZingHR App', label: 'ZingHR App' },
],
hrmspostingtype:[
  {id: 'Carriers', label:'Carriers',postingstartdate:'',postingenddate:'',postingstatus:'INACTIVE'},
  {id: 'Refer', label:'Refer',postingstartdate:'',postingenddate:'',postingstatus:'INACTIVE'},
  {id: 'IJP', label:'IJP',postingstartdate:'',postingenddate:'',postingstatus:'INACTIVE'},
],
applicationlist:[
  {id: 1, label:'Resume'},
  {id: 2, label:'Biographical'},
  {id: 3, label:'Contact'},
  {id: 4, label:'Address'},
  {id: 5, label:'Work Experience'},
  {id: 6, label:'Education'},
  {id: 7, label:'Job'},
  {id: 8, label:'Personal Identity'},
  {id: 9, label:'Personal Documents'},
  {id: 10, label:'Social Security'},
  {id: 11, label:'Last Salary'},
  {id: 12, label:'Misc'}
],
resumejobapp:[
  {id: 1, label:'Resume'},
],
biographicaljobapp:[
  {id: 1, label:'Solution'},
  {id: 2, label:'First Name'},
  {id: 3, label:'Middle Name'},
  {id: 4, label:'Last Name'},
  {id: 5, label:'Gender'},
  {id: 6, label:'Marital Status'},
],
contactjobapp:[
  {id: 1, label:'Personal Email Id'},
  {id: 2, label:'Country Code Personal'},
  {id: 3, label:'Personal Mobile No'},
  {id: 4, label:'Facebook Id'},
  {id: 5, label:'Linkedin Id'},
  {id: 6, label:'Extension Mobile No'},
],
addressjobapp:[
  {id: 1, label:'Current Flat/House/Wing Number'},
  {id: 2, label:'Current Street/Locality/Area'},
  {id: 3, label:'Current Landmark'},
  {id: 4, label:'Current Pincode'},
  {id: 5, label:'Current Country'},
  {id: 6, label:'Current State'},
  {id: 7, label:'Current City'},
],
workexperiencejobapp:[
  {id: 1, label:'Experience Latter from the 2nd last company'},
  {id: 2, label:'Relieving Latter from the 2nd last company'},
  {id: 3, label:'Relieving Latter from the previous company'},
  {id: 4, label:'Experience Latter from the previous company'},
  {id: 5, label:'Payslip from last 3months (consolidated in 1 pdf)'},
],
educationjobapp:[
  {id: 1, label:'Educational Category'},
  {id: 2, label:'10th Mark Sheet'},
  {id: 3, label:'12th Mark Sheet'},
  {id: 4, label:'Post Graduation Certificate'},
  {id: 5, label:'Graduation Certificate'},
],
jobjobapp:[
  {id: 1, label:'Languages Spoken'},
  {id: 2, label:'Desired Salary'},
  {id: 3, label:'Referance (Current Employee)'},
  {id: 4, label:'What makes you unique ?'},
  {id: 5, label:'Why do you want to work for our company ?'},
  {id: 6, label:'Why are you interested in this particular profile ?'},
  {id: 7, label:'Recruiter Name'},
],
personalidentityjobapp:[
  {id: 1, label:'Adhaar'},
  {id: 2, label:'PAN'},
  {id: 3, label:'IQAMA Number'},
],
personaldocumentsjobapp:[
  {id: 1, label:'Profile Picture'},
  {id: 2, label:'Signature'},
  {id: 3, label:'Adhaar Front'},
  {id: 4, label:'PAN Card'},
  {id: 5, label:'Driving Licence'},
  {id: 6, label:'Adhaar proof (Adhaar Card/Passport/Driving Licence/Electricity Bill)'},
  {id: 7, label:`ID Proof (PAN Card/Voter's Id/Driving Licence)`},
],
socialsecurityjobapp:[
  {id: 1, label:'UAN Number'},
  {id: 2, label:'PRAN Number'},
],
lastsalaryjobapp:[
  {id: 1, label:'Currency'},
  {id: 2, label:'Fixed Pay'},
  {id: 3, label:'Variable Pay'},
  {id: 4, label:'Incentives/Bonuses'},
  {id: 5, label:'Total Cash Components'},
  {id: 6, label:'Stock Options'},
  {id: 7, label:'Benifits'},
  {id: 8, label:'Total CTC'},
],
miscjobapp:[
  {id: 1, label:'Cover Latter Text'},
  {id: 2, label:'College or University'},
  {id: 3, label:'When are you available'},
  {id: 4, label:'Comments (if any)'},
  {id: 5, label:'How do you know this person ?'},
  {id: 6, label:'Recommendation or Comment'},
  {id: 7, label:'Message'},
],
rejectionactivity:[
  {id: 1, label:'Reuirement not full fill'},
  {id: 2, label:'Reject'},
],
issueclassification:[
  {id: 'p0', label:'P0'},
  {id: 'p1', label:'P1'},
  {id: 'p2', label:'P2'},
  {id: 'p3', label:'P3'},
  {id: 'p4', label:'P4'} 
],
payscale:[
  {id: 1, label:'Net Salary'},
  {id: 2, label:'Gross Salary'},
  {id: 3, label:'CTC'},
],
salarystructure:[
  {id: 1, label:'Net Salary'},
  {id: 2, label:'Gross Salary'},
  {id: 3, label:'CTC'},
],
currency:[
  {id: 1, label:'Rs'},
  {id: 2, label:'Dollar'},
  {id: 3, label:'AED'},
],
probationday:[
  {id: 1, label:'1 Month'},
  {id: 2, label:'2 Month'},
  {id: 3, label:'3 Month'},
],
template:[
  {id: 1, label:'1 Template'},
  {id: 2, label:'2 Template'},
  {id: 3, label:'3 Template'},
],
differentlyabled:[
  {id: 0, label:'None'},
  {id: 1, label:'Visual'},
  {id: 2, label:'Communication'},
],
trackerlisttype:[
  {id: 'Appraisal Cycle', label: 'Appraisal Cycle'},
  {id: 'Orientation', label: 'Orientation'},
  {id: 'Business Task', label: 'Business Task'},
  {id: 'Masterfeedback', label: 'Masterfeedback'},
  {id: 'Ticket Request', label: 'Ticket Request'},
  {id: 'jobapplication', label: 'Job Application'},
  {id: 'dynamic_tickets', label: 'dynamic_tickets'},

],
priorityDefination:[
  {id: 'p0', label: 'P0 - Critical: Issues that have a severe impact on the business, causing a complete halt or critical functionality degradation. Immediate attention and availability are required.'},
  {id: 'p1', label: 'P1 - High: Significant issues that affect core functionality, but the system remains operational.'},
  {id: 'p2', label: 'P2 - Medium: Issues that impact specific features or functionalities but do not cause a system-wide disruption.'},
  {id: 'p3', label: 'P3 - Low: Minor issues or feature requests that do not significantly affect operations.'},
  {id: 'p4', label: 'P4 - Lowest: Non-urgent inquiries, general questions, or cosmetic issues.'},
],
enddateapprovelstatus:[
  {id: 'pending', label: 'Pending'},
  {id: 'approved', label: 'Approved'}
],
activityhoursopts: [
  {
    id: 'developmenthours',
    label: 'Development Hours'
  }, {
    id: 'codereviewhours',
    label: 'Code Review Hours'
  }, {
    id: 'infrasetupanddeploymenthours',
    label: 'Infra Setup and Deployment Hours'
  },
  {
    id: 'meetingfeaturesdiscussionhrs',
    label: 'Meeting - Features Discussion'
  },
  {
    id: 'qahours',
    label: 'QA Hours'
  }, 
  {
    id: 'supporthrs',
    label: 'Support'
  }
],
categorizationlist: [
  {
    id: 'training',
    label: 'Training'
  },
  {
    id: 'company news',
    label: 'Company News'
  },
  {
    id: 'product updates',
    label: 'Product Updates'
  },
  {
    id: 'industry insights',
    label: 'Industry Insights'
  }
],
businesscontextList:[
  {
    id: 'Revenue Generation (Top Line)',
    label: 'Revenue Generation (Top Line)'
  },
  {
    id: 'Cost Saving (Bottom Line)',
    label: 'Cost Saving (Bottom Line)'
  },
  {
    id: 'Productivity Improvement (Operational)',
    label: 'Productivity Improvement (Operational)'
  }
],
modulelists: [
  {
    id: 'ticket',
    label: 'Ticket Request'
  }, {
    id: 'issue',
    label: 'Issue Management'
  },
  {
    id: 'testcase',
    label: 'Testcase Management'
  }, {
    id: 'task',
    label: 'Micro Task Master'
  },
  {
    id: 'businesstask',
    label: 'Business Task'
  },
  {
    id: 'Testcycle Set',
    label: 'Testcycle Set'
  }
],
emailnotificationtypes:[
  { id: 'allproject', label: 'For any event on all my projects' },
  { id: 'selectedprojects', label: 'For any event on the selected projects only' },
  { id: 'subscribed', label: 'Only for things i watch or im involved in' },
  { id: 'asssigned', label: 'Only for things i am assigned to' },
  { id: 'createdby', label: 'Only for things i am the owner of' },
  { id: 'noevents', label: 'No Events' }
],
leaveTypes:[
  {id: 'maternity', label: 'Maternity Leave'},
  {id: 'privilege', label: 'Privilege Leave'},
  {id: 'special', label: 'Special Leave'},
  {id: 'unpaid', label: 'Unpaid Leave'}
],

merchandisingProId : 0,
sectionmoduleId1: 0,
sectionmoduleId2: 0,
indItHelpDesk: 0,
ifItHelpDeskNotFound: 0,
statusconfig:true,
oldBTCFlagDate: '2023-04-20',
encpaccessapitoken : '0d0b48bb2c901ed8883e4f071589d5ca',
liveapiurl:'http://internal-ALB-INT-LIVE-FC-SMART-API-1663537770.ap-south-1.elb.amazonaws.com:80/',
uploadfiletype:"image/*,audio/*,video/*,.pdf,.doc, .docx,.xlsx,.csv,.xls,.zip,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
validFileExtensions : ['png','jpg','docx','jpeg','pdf', 'txt', 'excel', 'csv', 'xps', 'ods', 'dbf', 'prn', 'dif', 'slk', 'xls', 'xlsx','zip','mp3', 'mp4', 'vlc', 'wav', 'mpeg', 'mpg','webm'],
taskTypedesignCreative:[],
designTeamRole: 0,
depOfBAReviewTasks: [],
companycode: 'xb',
uploadfiletypecsv:".csv",
key: CryptoJS.enc.Base64.parse('9Le0DgMTKAAZXNokdEEial'),
iv: CryptoJS.enc.Base64.parse('kHGFxENnZLbienXyANoi.p'),
defaultimagepath:'https://smartbees.xbees.in/images/portrait/small/icon.png',
systemEngieerRole: 483,
FcRoleWiseAPI:true, //active only for rolewise ticket api ,
EMroleid: 492,
PMroleid: 477,
BAroleid: 468,
QAroleid: 509,
TMroleid: 131,
POroleid: 129,
BRoleid: 476,
TOroleid: 513,
SupportEnquiry: 17,
BRFeatureProList: [],
TKRosterFields:[{id:'Ticket Category',label:'Ticket Category'},{id:'Ticket Sub Category',label:'Ticket Sub Category'},{id:'Ticket Type',label:'Ticket Type'}],
CustomerFeedbackRP: 146,
moduletrackerid:2,
FDeadlineDate: 7,
productownerid: 129,
campsprojectid: 56,
BTCListing : '152',
recruitmentprojectid: 367, //recruitment projectid
interviewtype: 32, //interviewtype id
interviewtrackerid: 153, //interview trackerid
TaskTypeRestrictforClose:["Project Planning","WBS"],
SBSystemID: '82',
candidatestatuslist: [{id:'On Hold',label:'On Hold'},{id:'Completed',label:'Selected'},{id:'Rejected',label:'Rejected in the Process'}],
resumereviewtype: 34
}
