<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation">
            <div class="card border-warning filter_card_searchbgst" >
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid" >
            <div class="row">
              <div
                    class="w-auto px-1"
                    
                  ><div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                        <search-icon
                          size="1.5x"
                          class="custom-class avatar-icon font-medium-3"
                        ></search-icon>
                      </div>
                    </div>
                    <h4 class="card-title d-inline-flex ml-1">Search Project Role Allotment Details</h4>
                  </div>
                <div class="ml-auto px-0 d-flex">
              <div class=" text-right" >
                <div class="input-group-append float-right">
                    <button id="commonaddmodule" class="btn btn-sm btn-relief-success" data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#projectroleseleform" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false">
                         <span class="mobile_hide_bt">Add {{glbMdlLbl}}</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></button>
                </div>
              </div>
              <span class="rotetions_view_arrow mr-1"> 
                  <em class="las la-angle-down"></em>
                </span>
            </div>
            </div>
            </div>
                
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectList" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="masterlist('t14')"> <search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2" @click="resetForm()"> <x-icon  size="1.5x" class="custom-class" ></x-icon><span>Clear</span> </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                <div class="card text-center mb-3">
                <div class="">
                <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row" id="table-small">
                <div class="col-12">
                <div class="card">
                   
                    
                <div class="table-responsive">
                <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                <thead>
                <tr>
                <th scope="col" class="srno align-middle py-1">Sr#</th>
                <th scope="col" class="align-middle text-left" v-if="glbst =='t14'">Project Name</th>
                <th scope="col" class="align-middle text-left" v-if="glbst =='t14'">Role Name</th>
                <th scope="col" class="centeralign align-middle" style="min-width:100px">Created by</th>
                <th scope="col" class="centeralign align-middle" style="min-width:100px">Created date</th>
                <th scope="col" class="clsaction align-middle">Actions</th>
                </tr>
                </thead>
                <tbody>
                    <template v-for="(data,index) in companymasterlist">        
                        <tr v-bind:key="data.moduleid">
                            <td class="srno font-weight-normal">{{ index+1+currPageNum*pagelimit }}</td>
                            <td class="capitalisetext font-weight-normal text-left cursorpointercls sitebluecolor" @click="redirecttourl('projectId',data)" v-if="glbst =='t14'">{{data.projectname}}</td>
                            <td class="capitalisetext font-weight-normal text-left" v-if="glbst =='t14'">{{data.designationname}}</td>
                            <td class="capitalisetext font-weight-normal" v-if="glbst =='t14'">{{data.authorname}}</td>
                            <td class="centeralign font-weight-normal">{{dateFormat(data.createddate)}}</td>
                            <td class="clsaction font-weight-normal">
                                <a class="devEditEle"   data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#projectroleseleform" data-currState="reguler" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                </a>
                            </td>
                        </tr>
                    </template>
                    <template v-if="companymasterlist.length==0">
                        <tr><td colspan="14" style="text-align:center;">No record found</td></tr>
                    </template>
                </tbody>
                </table>             
                </div>
                </div>
                </div>
                </div>
                </div>
                <div class="pageCenter">
                <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                </div>
                </div>
                </div>
                </div>
                </div>
            </div>
            </section>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="projectroleseleform">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Project Role Select Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="projroleprojectdd">Project Name</label>
                                    
                                    <treeselect placeholder="Enter min 3 chars to search project name" class="capitalisetext" :clear-on-select="true" v-model="roleselection.project" :options="roleselection.projectlist" v-on:search-change="projectChange" @input="selroleasiproj" @close="validateForm('t14',$event)"/>    
                                    <div class="errorinputmsg" v-if="this.errors.roleselection.project">{{ this.errors.roleselection.project }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="projectroleiddd">Role</label>
                                    <treeselect class="rolelistdd capitalisetext" v-model="roleselection.role" :multiple="true" :clear-on-select="true" :options="roleselection.rolelist" @input="selroleasirole" @close="validateForm('t14',$event)" />
                                    <div class="errorinputmsg" v-if="this.errors.roleselection.role">{{ this.errors.roleselection.role }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="button" class="btn btn-primary mr-1"  @click="saveData('t14',$event)" id="submitbtnt14">Submit</button>
                                 <button type="reset" class="btn btn-primary mr-1" @click="resetRecords()"> Clear </button>
 
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.createbtnparentdiv {
  padding-right: 0%;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}

.title {
  text-align: center;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import { EditIcon,EyeIcon,SearchIcon,PlusCircleIcon,XIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'Masters',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        Pagination,
        PlusCircleIcon,
        XIcon,
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            companymasterlist: [],
            showAddForm:false,
            selected: null,
            countryName: [
                { label: 'select_value', id: 'Select Value' },
                { label: 'Russia', id: 'Russia' },
                { label: 'Canada', id: 'Canada' },
                { label: 'China', id: 'China' },
                { label: 'United States', id: 'United States' },
                { label: 'Brazil', id: 'Brazil' },
                { label: 'Australia', id: 'Australia' },
                { label: 'India', id: 'India' },
            ],
            editRowData:[],
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'Role Allotment',
            ddapplicationlist:[],
            selectedApp:null,
            selectedRadio:'yes',
            undt:[],
            taskdesc:'',
            tasklevel:'',
            valid : false, 
            submitted : false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:{},
            roleselection:{
                project:null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                role:null,
                rolelist:[{
                    id:'',
                    label:''
                }]
            },
            errors: {
                roleselection:{
                    project:null,
                    role:null
                }
            },
            search: {
                searchProjectname:null,
            },
            projectnameList:[],
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            isAddState:false,
            iseditstate:false,
            tokendata: null,
        }
    },    
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/masters') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    
    mounted(){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'addrole') {
                    
                    setTimeout(function(){
                        $('#commonaddmodule').click();
                    }, 1000);
                    this.masterlist();
                }
            }else{
                this.masterlist();
            }
            $('.devApplicationTypeData').each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                
                dropdownAutoWidth: true,
                width: '100%',
                dropdownParent: $this.parent()
                });
            });
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        }); 
    },
    methods:{
        resetForm() {
            this.search.searchProjectname = null
            this.masterlist('t14');
            this.roleselection.project = null
            this.roleselection.projectlist = null
            this.roleselection.role = null
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            this.masterlist(inputEle,'')
        },
        setSelected(value)
        {
            this.selected=value
        },
        masterlist(mdl='t14',objThis){
            this.isLoading=true            
            let setmoduleform='projectroleseleform'
            let glbMdlLblval='Project Role Selection'
            if(this.glbst && mdl == ''){
               mdl = this.glbst;
            }            
            $('#commonaddmodule').attr('data-target','#'+setmoduleform)
            
            this.companymasterlist = []
            let tblNm='t14'
            if(mdl)
            tblNm=mdl
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                
                this.glbMdlLbl = glbMdlLblval;
            }
            var url = 'api/master/commonmaster/list';
            this.input = {
                tbl: "t14",
                offset:this.pageoffset,
                limit:this.pagelimit,
                useremail: this.undt.username,
                projectname: this.search.searchProjectname,
                empcode: this.undt.userid,
            }
            if(tblNm == 't14'){
                this.getThirdPartyAPIcall('t10','projectrolesele-tab')
                
                url = 'api/projectonlyroleassignment/list'
                this.isProjectSel= 1
                this.viewprojectlist=1
                this.input.section='assignment'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
                this.input.createdby= this.undt.username,
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid
            }
            this.input.isSearch=false
            if(typeof objThis != 'undefined' && objThis != ''){
                if(objThis.currentTarget.dataset.clkstate == 'search' && $('#glbsearchinpele').val() != ''){
                    this.input.name=($('#glbsearchinpele').val()).trim()
                    this.input.isSearch=true
                }
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false;          
                if(result.data.errorCode == 0){
                    this.totalcount = result.data.data.count
                    this.companymasterlist=result.data.data.rows;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    projectList: function (node, instanceId){
        if(node && node.length>1){
            this.isLoading = true;
            let url = "api/searching/getProjectList";
            this.input = {
                projectname:node.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'listingpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.projectnameList = result.data.data;
            } else {
                this.projectnameList = [];
            }
            });
        }       
    },
    selectproject(state, value) {
      this.search.searchProjectname = state;
    },
        addModule(objThis){
            $('.devUniqueForm').find('input[type=text]').val('')
            $(objThis.currentTarget.dataset.target).find('#type-success').attr('data-isedit',false)
            this.iseditstate=false
            $('#glbsearchinpele').val('')
            this.roleselection.role = null
            this.roleselection.project=null
            this.isAddState=true
            this.iseditstate=false
        },
        saveData(mdl,objThis){
            
            this.valid=this.validateForm(mdl,objThis);
            if (this.valid) {
                 this.isLoading=true
                this.submitted=true
                let nameobj=document.getElementById('modulename'+mdl)
                let typeobj=document.getElementById('devApplicationTypeData')
                let tblNm='t1'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'masters',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                if(this.roleselection.project){
                    this.input.projroleprojectdd=this.roleselection.project
                }
                if(this.roleselection.role.length){
                    this.input.projectroleiddd=JSON.stringify(this.roleselection.role)
                    if(this.roleselection.role){
                    var tmprolearr=[]
                    for(var i=0;i < this.roleselection.rolelist.length;i++){
                        if(this.roleselection.role.includes(this.roleselection.rolelist[i].id)){
                            tmprolearr.push({'rolename':this.roleselection.rolelist[i].label,'roleid':this.roleselection.rolelist[i].id})
                        }
                    }
                    this.input.projectroledata=JSON.stringify(tmprolearr)
                }
                }
                this.input.name='1';
                let apiURL='api/projectonlyroleassignment/create'
                if(this.iseditstate==true){
                    if(tblNm == 't14'){
                        this.input.id=this.editRowData.moduleid
                        this.input.lastmodifiedby=this.undt.username
                        this.input.id=this.editRowData.batchid
                        apiURL='api/projectonlyroleassignment/update'
                    }
                    delete this.input.createdby;
                }
                this.isAddState=false
                this.iseditstate=false
                this.input.useremail= this.undt.username
                this.input.empcode= this.undt.userid
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading=false            
                    if(result.data.errorCode == 0){
                        
                        $('.close').click()
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.masterlist(mdl,'');
                    }
                    else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                    })
                }
                    
                })
            }
        },
        openEditModule(currRowData){
            console.log(currRowData,'==========currRowData');
            this.editRowData = currRowData
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            // this.getApplicationdata({projname:''});
            this.getApplicationdatabyid(currRowData.projectid);
            var arrroleidlist=currRowData.roleidlist.split(",")
            arrroleidlist = arrroleidlist.map(function (x) { 
                return parseInt(x, 10); 
            });
            this.roleselection.role = arrroleidlist
            this.roleselection.project=currRowData.projectid
            $('#modulename'+inputEle).val(currRowData.rolecount)
            this.iseditstate=true
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        getApplicationdata(data){
            this.isLoading = true;
            let input = {
                projectname:data.projname.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'createpage'
            };
            let apiURL='api/listing/getProjectofCreatedBy'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.roleselection.projectlist=result.data.data.rows
                    return true;
                }else{
                    this.roleselection.projectlist=null
                    return true;
                }
            })
        },
        getApplicationdatabyid(pid){
            this.isLoading = true;
            let input = {
                projectid: pid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'isprojectid'
            };
            let apiURL='api/listing/getProjectofCreatedBy'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.roleselection.projectlist=result.data.data.rows
                    return true;
                }else{
                    this.roleselection.projectlist=null
                    return true;
                }
            })
        },
        setClickedRadioVal(objThis){
            this.selectedRadio = objThis.currentTarget.value
        },
        seltechapptypedata(state,value){
            this.selectedApp=null
            this.selectedApp=state
            if(!state && this.isAddState == false){
                this.techtype.apptypeerror=true
            }else{                
                this.techtype.apptypeerror=false
            }
        },

       
          validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
         validateForm(mdl,objThis){
            this.clearerrorForm();
            this.isAddState=false
            var isValid=true;
            if(mdl=="t14"){
                if(!this.roleselection.project){
                    this.errors.roleselection.project="Project name required";
                    isValid= false;
                }
                if(this.roleselection.role == null || this.roleselection.role == '' || this.roleselection.role.length == 0){
                    this.errors.roleselection.role="Role required";
                    isValid= false;
                }
            }
            return isValid
        },
        clearerrorForm(){
            this.errors= {
                roleselection:{
                    project:null,
                    role:null
                },
            };
        },
         resetRecords: function () {
      this.validateForm();
      this.roleselection.project = null;
         this.roleselection.role = null;
      let rowdata={}
      this.getprojectlist(rowdata);
    },
        selroleasiproj(state,value){
            this.roleselection.project=null
            this.roleselection.project=state
            if(!state && this.isAddState == false){
                this.errors.roleselection.project='Project required'
            }else{                
                this.errors.roleselection.project=null
            }
        },
        selroleasirole(state,value){
            this.roleselection.role=null
            this.roleselection.role=state
            if(state.length == 0 && this.isAddState == false){
                this.errors.roleselection.role='Role required'
            }else{                
                this.errors.roleselection.role=null
            }
        },
        getThirdPartyAPIcall(mdl,curracttab){
            var calltype=''
            
            calltype=this.currcalltype
            this.pageoffset=1
            this.inputthrid = {
                tbl:mdl
            }
            var apiURL='api/master/commonmaster/thirdpartyapicall' 
            if(mdl == 't10'){
                apiURL='api/projectonlyroleassignment/getdesignations'
                this.inputthrid.currentState=calltype,
                this.inputthrid.empcode= this.undt.userid,
                this.inputthrid.useremail= this.undt.username
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputthrid,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status == true){
                    if(mdl == 't10'){
                        if(curracttab == 'projectrolesele-tab'){
                            if(this.inputthrid.currentState == 'fcsmart'){
                                this.roleselection.rolelist = result.data.data.rows
                            }else{
                                this.roleselection.rolelist = result.data.data.rows
                            }
                            
                            return true;
                        }
                    }
                }else if(result.data.status == false){
                    if(mdl == 't10'){
                        if(curracttab == 'projectrolesele-tab'){
                            this.roleselection.rolelist=[{
                                id:'',
                                label:'Issue in CAM Integration API'
                            }]
                            return true;
                        }
                    }
                }
            });
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.getApplicationdata({projname:node})
               
            }
        },
        redirecttourl (btnstate, rowdata) {
            if (btnstate == 'projectId') {
			    this.$router.push({
				    path: '/project/summary?pid='+rowdata.projectid,
			    })
            }
        }
    }
}
</script>