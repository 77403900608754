<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>

    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Project Productivity Based On Task Report</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1" id="projectdropdown">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Project Name" v-model="search.searchProjectname" :multiple="true" :options="projectnameList" @input="selectproject" :clear-on-select="true"  />
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="date_picker_foundeds">
                        <label for="">Business Task Date From </label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select business task date from " v-model="search.datefrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                          <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <div class="date_picker_foundeds">
                        <label for="">Business Task Date To</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select business task date to" v-model="search.dateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-12 text-right mb-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 ml-1 mobile_margin-px-2"  :disabled="isBothDateSelected && this.search.datefrom"  @click="getProjectProductivityDetails(true,search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 ml-1 mobile_margin-px-2" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2 ml-1 mobile_margin-px-2"  :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Project Productivity based on Task Report Details 
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home"  role="tabpanel"  aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              
                                <div id="live-pe-h" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <span class="text-card">  Number Of Live Business Task / Estimated Hours Spent</span> 
                                      </div>
                                  </div>
                                </div>
                                <div id="act-tsk-li" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <span class="text-card"> Number Of Live Business Task / Actual Hours Spent</span> 
                                      </div>
                                  </div>
                                </div>
                                <div id="e-hours-team-live" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <span class="text-card"> No. Of Pending Tasks / Estimated No. Of Tasks Live Per Hour</span> 
                                      </div>
                                  </div>
                                </div>
                                <div id="a-team-to-live" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <span class="text-card"> No. Of Pending Tasks / Actual No. Of Tasks Live Per Hour</span> 
                                      </div>
                                  </div>
                                </div>
                                <div id="e-mem-to-live" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <span class="text-card"> Estimated Hours required by Team to Live / Actual Hours required by Team to Live</span> 
                                      </div>
                                  </div>
                                </div>
                                <div id="a-mem-to-live" style="display:none;">
                                  <div class="popover-body">
                                      <div class="popover_top_heading"> 
                                       <span class="text-card"> Actual Hours required by Team to Live / No of Tech Team</span> 
                                      </div>
                                  </div>
                                </div>
                                
                                  <div class="table-responsive_sitcky"  v-if="showtabledata">
                                  <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Program Manager Project Details ">
                                  <thead>
                                  <tr>
                                  <th scope="col" class="align-middle text-left first-col-sticky " style="min-width:250px;">ProjectName</th>
                                  <th scope="col" class="align-middle" style="min-width:100px;">No of Tech Team
                                  </th>
                                  <th scope="col" class="align-middle" style="min-width:100px;">No of QA Team</th>
                                  <th scope="col" class="align-middle">Type of Task</th>
                                  <th scope="col" class="align-middle">No of Live Business Task</th>
                                  <th scope="col" class="align-middle">
                                  Estimated Hours Spent
                                  </th>
                                  <th scope="col" class="align-middle">
                                  Actual Hours Spent
                                  </th>
                                  <th scope="col" class="align-middle">Estimated No. Of Tasks Live Per Hour
                                  <span class="popover-block-container-custom">
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#live-pe-h">
                                  <em class="las la-info-circle text-warning font-medium-5"></em>
                                  </button>
                                  </span>
                                  </th>
 
                                  <th scope="col" class="align-middle">Actual No. Of Tasks Live Per Hour
                                  <span class="popover-block-container-custom">
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#act-tsk-li">
                                  <em class="las la-info-circle text-warning font-medium-5"></em>
                                  </button>
                                  </span>
                                  </th>
                                  <th scope="col" class="align-middle">
                                  <span class="popover-block-container-custom">
                                  No. Of Pending Tasks
                                  </span>
                                  </th>

                                  <th scope="col" class="align-middle">
                                  <span class="popover-block-container-custom">
                                  Estimated Hours required by Team to Live
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#e-hours-team-live">
                                  <em class="las la-info-circle text-warning font-medium-5"></em>
                                  </button>
                                  </span>
                                  </th>
                                  <th scope="col" class="align-middle">
                                  <span class="popover-block-container-custom">
                                  Actual Hours required by Team to Live
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#a-team-to-live">
                                  <em class="las la-info-circle text-warning font-medium-5"></em>
                                  </button>
                                  </span>
                                  </th>
                                  <th scope="col" class="align-middle">
                                  <span class="popover-block-container-custom">
                                  Estimated Hours required by Team Member to Live
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#e-mem-to-live">
                                  <em class="las la-info-circle text-warning font-medium-5"></em>
                                  </button>
                                  </span>
                                  </th>

                                  <th scope="col" class="align-middle">
                                  <span class="popover-block-container-custom">
                                  Actual Hours required by Team Member to Live
                                  <button class="popover_top_note" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#a-mem-to-live">
                                  <em class="las la-info-circle text-warning font-medium-5"></em>
                                  </button>
                                  </span>
                                  </th>


                                  </tr>
 
                                  </thead>
                                  <tbody>
                                    <template v-for="(pgmdata, index) in companymasterlist">
                                        <tr>
                                          <td  class="first-col-sticky font-weight-normal" style="text-align:left; background-color:#F3F2F7;"><strong class="hod_table_pl-1">{{index}}</strong></td>
                                          <td><strong  >{{ pgmdata[0].totaltechteam }} </strong></td>
                                          <td ><strong  >{{ pgmdata[0].totalqateam }} </strong></td>
                                          <td colspan="12"></td>
                                        </tr>
                                        <tr  v-for="(data,i) in pgmdata"  v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                          <td class="first-col-sticky font-weight-normal" ></td>
                                          <td class="capitalisetext font-weight-normal"></td>
                                          <td class="capitalisetext font-weight-normal"></td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.taskestimates ? data.taskestimates : 'NA' }}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.nooflivetask==0 ? 0 : data.nooflivetask }}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.estimatedhrsspent==0 ? 0 : data.estimatedhrsspent }}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.actualspenthrs == 0 ? 0 : data.actualspenthrs}}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.estlivetaskperhrs == 0 ? 0 : data.estlivetaskperhrs}}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.actuallivetaskperhrs==0 ? 0 : data.actuallivetaskperhrs}}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.pendingtask}}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal" v-if="parseFloat(data.actualrequiredtolivehrs)<=parseFloat(data.esttomakelivehrs)" style="background-color: green;color: white;">
                                            <span>{{data.esttomakelivehrs==0 ? 0 :data.esttomakelivehrs	}}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal" v-else style="background-color: red;color: white;">
                                            <span>{{data.esttomakelivehrs==0 ? 0 :data.esttomakelivehrs	}}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal" v-if="parseFloat(data.actualrequiredtolivehrs)<=parseFloat(data.esttomakelivehrs)" style="background-color: green;color: white;">
                                            <span>{{data.actualrequiredtolivehrs ==0 ? 0 : data.actualrequiredtolivehrs }}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal" v-else style="background-color: red;color: white;">
                                            <span>{{data.actualrequiredtolivehrs ==0 ? 0 : data.actualrequiredtolivehrs }}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.estrequiredtomakelivehrs ==0 ? 0 :data.estrequiredtomakelivehrs }}</span>
                                          </td>
                                          <td class="capitalisetext font-weight-normal">
                                            <span>{{data.actualrequiredtomakelivehrs == 0 ? 0 : data.actualrequiredtomakelivehrs}}</span>
                                          </td>
                                        </tr>
                                        
                                      <tr><td :class="'capitalisetext font-weight-normal first-col-sticky unique'+index+1+currPageNum*pagelimit" style="text-align: center;"><strong>Sum</strong></td>
                                        <td colspan="3" class="font-weight-normal"></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'nooflivetask') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'estimatedhrsspent') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'actualspenthrs') }}</strong></td>
                                        <td colspan="2" class="font-weight-normal"></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'pendingtask') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'esttomakelivehrs') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'actualrequiredtolivehrs') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'estrequiredtomakelivehrs') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetTotalSum(pgmdata,'actualrequiredtomakelivehrs') }}</strong></td>
                                       </tr>
                                       <tr><td :class="'capitalisetext font-weight-normal first-col-sticky unique'+index+1+currPageNum*pagelimit" style="text-align: center;"><strong>Average</strong></td>
                                        <td class="font-weight-normal"></td>
                                        <td class="font-weight-normal"></td>
                                        <td class="font-weight-normal"></td>
                                    <td class="font-weight-normal"><strong >{{ GetAverage(pgmdata,'nooflivetask') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetAverage(pgmdata,'estimatedhrsspent') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetAverage(pgmdata,'actualspenthrs') }}</strong></td>
                                    <td class="font-weight-normal" colspan="2"></td>
                                    <td class="font-weight-normal"><strong  >{{ GetAverage(pgmdata,'pendingtask') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetAverage(pgmdata,'esttomakelivehrs') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetAverage(pgmdata,'actualrequiredtolivehrs') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetAverage(pgmdata,'estrequiredtomakelivehrs') }}</strong></td>
                                    <td class="font-weight-normal"><strong  >{{ GetAverage(pgmdata,'actualrequiredtomakelivehrs') }}</strong></td>
                                       </tr>
                                    </template>
                                     <template v-if="companymasterlist.length == 0">
                                      <tr>
                                        <td class="first-col-sticky font-weight-normal" ></td>
                                        <td colspan="13" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                         
                      <div class="pageCenter pt-2 text-center">
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import { EyeIcon,SearchIcon,XIcon,ExternalLinkIcon,UserIcon } from "vue-feather-icons";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "ProjectProductivityReport",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker,
    UserIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      isBothDateSelected:false,
      projectnameList:[],
      isLoading: false,
      islistLoading:false,
      listfullPage:true,
      fullPage: true,
      companymasterlist: [],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      search:{
      searchProjectname :null,
      dateto:null,
      datefrom:null,
     
      },
      bshlist : [],
      tokendata:null,
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
         this.getProjectAllocatedToEmp();
      }
    }
    $("[data-toggle=popover]").popover({
    html: true,
    trigger: "focus",
    content: function () {
      let content = $(this).attr("data-popover-content");
      return $(content).children(".popover-body").html();
    }
    });
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    notBeforeToday: function (date) {
      this.search.dateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.datefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.pageoffset=1
      if(!state){
        console.log('no project selected')
      }else{
        this.search.searchProjectname = state;
      }
    }, 
    getProjectAllocatedToEmp() {
      this.isLoading = true
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage: "listingpage"
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers': { 'authorization': this.tokendata }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectnameList = result.data.data;
          localStorage.setItem('projectnameList', JSON.stringify(result.data.data))
        } else {
          this.projectnameList = [];
          localStorage.removeItem('projectnameList')
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    dateMonth(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM');
    },
   selectedstartdate(){
      if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
        this.isBothDateSelected = false
      }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
        this.isBothDateSelected = true
      }
    },
    selectedenddate(){
       if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
        this.isBothDateSelected = false
      }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
        this.isBothDateSelected = true
      }
    },
    getProjectProductivityDetails(flag = false,...search){
      if(flag) {
        this.pageoffset = 1
        this.companymasterlist = []
        this.totalcount = 0;
        this.pageCount = 0;
        this.page= 1;
      } 
        
        this.islistLoading = true;
        let url = "api/reports/getProjectProductivity";
        this.input = {
            offset: this.pageoffset,
            limit: apiUrl.LIMIT,
            useremail: this.undt.username,
            empid: this.undt.userid,
             empcode: this.undt.userid,
        };
        let assignedate =""
      if(this.search.dateto){
        assignedate = new Date(this.search.dateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.project = this.search.searchProjectname ? this.search.searchProjectname : null
      this.input.datefrom = this.search.datefrom?this.search.datefrom:null
      this.input.dateto = this.search.dateto?assignedate:null

      if(this.search.datefrom && this.search.dateto){
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true

          const groupByCategory = result.data.data.rows.reduce((group, product) => {
            const { projectname } = product;
            group[projectname] = group[projectname] ?? [];
            group[projectname].push(product);
            return group;
          }, {});

          this.companymasterlist = groupByCategory;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            this.isresponce=true
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    }else{
      this.islistLoading = false;
      this.isresponce=true
      this.companymasterlist = [];
      this.totalcount = 0;
      this.pageCount = 0;
      Swal.fire({
              title: "",
              text: "Please select Date range",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
 
  }
    },
    GetTotalSum(countdata,parameter){
      let total = [];
      let mtotal = 0;
      let getroudoffs = 0;

      Object.entries(countdata).forEach(([key, val]) => {
      if(parameter=='nooflivetask'){
        total.push(parseFloat(val.nooflivetask))
      }  
      if(parameter=='estimatedhrsspent'){
        total.push(parseFloat(val.estimatedhrsspent))
      }  
      if(parameter=='actualspenthrs'){
        total.push(parseFloat(val.actualspenthrs))
      }  
      if(parameter=='pendingtask'){
        total.push(parseFloat(val.pendingtask))
      }  
      if(parameter=='estrequiredtomakelivehrs'){
        total.push(parseFloat(val.estrequiredtomakelivehrs))
      }  
      if(parameter=='actualrequiredtomakelivehrs'){
        total.push(parseFloat(val.actualrequiredtomakelivehrs))
      } 
      if(parameter=='esttomakelivehrs'){
        total.push(parseFloat(val.esttomakelivehrs))
      }  
      if(parameter=='actualrequiredtolivehrs'){
        total.push(parseFloat(val.actualrequiredtolivehrs))
      }          
      });

       mtotal = total.reduce(function(total, num){ return total + num }, 0);
       mtotal = mtotal.toFixed(3);
       getroudoffs = mtotal == 0 ? 0 : mtotal;
       return getroudoffs;  
    },
    GetAverage(countdata,parameter){
      let total = [];
      let count = 0;
      let totalsum = 0;
      let fintotal = 0;
      let finaver = 0;
      Object.entries(countdata).forEach(([key, val]) => {
      if(parameter=='nooflivetask'){
        total.push(parseFloat(val.nooflivetask))
       
      }  
      if(parameter=='estimatedhrsspent'){
        total.push(parseFloat(val.estimatedhrsspent))
       }  
      if(parameter=='actualspenthrs'){
        total.push(parseFloat(val.actualspenthrs))
       }
      if(parameter=='pendingtask'){
        total.push(parseFloat(val.pendingtask))
       }
      if(parameter=='estrequiredtomakelivehrs'){
        total.push(parseFloat(val.estrequiredtomakelivehrs))
       }  
      if(parameter=='actualrequiredtomakelivehrs'){
        total.push(parseFloat(val.actualrequiredtomakelivehrs))
      }
      if(parameter=='esttomakelivehrs'){
        total.push(parseFloat(val.esttomakelivehrs))
      }  
      if(parameter=='actualrequiredtolivehrs'){
        total.push(parseFloat(val.actualrequiredtolivehrs))
      }    
      count++;
     
      });
      totalsum = total.reduce(function(total, num){ return total + num }, 0);
      fintotal = (totalsum/count);
      fintotal  = fintotal.toFixed(3)
      finaver = fintotal==0 ? 0 : fintotal;

      return finaver;
    },
    resetRecords: function () {
        this.search.searchProjectname = null;
         this.search.dateto = null;
        this.search.datefrom = null;
           this.isBothDateSelected = false;
           this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.parentmoduledata={}
        this.parentmoduledata.isPaginationreq=true
        this.getProjectProductivityDetails(false,this.parentmoduledata);

      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMONFormat(value){
      if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    },
    timeToDecimal(t) {
      let arr = t.split(':');
      let dec = parseInt((arr[1]/6)*10, 10);
        return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
    },
    dateFormat1(value) {
      if (!value) return "";
      return moment(String(value)).format('YYYY-MM-DD');
    },
    selassignedto(state, value) {
      if(!state) {
        this.search.allotedto=null
      } else {
        this.search.allotedto=state
      }
    },
    ExportXL() {
      this.exportxlsdata = [];
      this.exporterr = "";
      this.isLoading = true;
      let url = "api/reports/getProjectProductivity";
      this.input = {
          offset: null,
          limit: null,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          
      };
      let assignedate =""
      if(this.search.dateto){
        assignedate = new Date(this.search.dateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      if(this.search.datefrom && this.search.dateto ){
      this.input.project = this.search.searchProjectname ? this.search.searchProjectname : null
      this.input.datefrom = this.search.datefrom?this.search.datefrom:null
      this.input.dateto = this.search.dateto?assignedate:null
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            let filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
          const groupByCategory = result.data.data.rows.reduce((group, product) => {
            const { projectname } = product;
            group[projectname] = group[projectname] ?? [];
            group[projectname].push(product);
            return group;
          }, {});
        }
      }).catch(e => {
        this.displayError(e)
      });

    }else{
      this.isLoading = false;
      Swal.fire({
              title: "",
              text: "Please select Date range",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
    }
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          let ShowLabel = 'ProjectProductivity'+filename
          let JSONData = readExportdata
          let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          let CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr# ,';
               
                 CSV += 'ProjectName,No_of_Tech_Team,No_of_QA_Team,Type_Of_Task,No_Of_Live_Business_Task,Estimated_Hours_Spent,Actual_Hours_Spent,Estimated_No_Of_Tasks_Live_Per_Hour,Actual_No_Of_Tasks_Live_Per_Hour_,No_Of_Pending_Tasks,Estimated_Hours_required_by_Team_to_Live,Actual_Hours_required_by_Team_to_Live,Estimated_Hours_required_by_Team_Member_to_Live,Actual_Hours_required_by_Team_Member_to_Live' + '\r\n'

            }
            let row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
 

            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname == 'NA' ? '' : index.projectname: '';
 
            let totaltechteam = index.hasOwnProperty('totaltechteam') ? index.totaltechteam == null || index.totaltechteam == 'NA' ? '' : index.totaltechteam : '';
 
            let totalqateam = index.hasOwnProperty('totalqateam') ? index.totalqateam == null?'' :index.totalqateam : '';
 
            let taskestimates = index.hasOwnProperty('taskestimates') ? index.taskestimates ==null || index.taskestimates == 'NA' ? '' : index.
            taskestimates: '';
            
            let nooflivetask = index.hasOwnProperty('nooflivetask') ? index.nooflivetask == null || index.nooflivetask == 'NA' ? '' : index.nooflivetask : '';
            
            let estimatedhrsspent = index.hasOwnProperty('estimatedhrsspent') ? index.estimatedhrsspent == null || index.estimatedhrsspent == 'NA' ? '' : index.estimatedhrsspent : '';
            
            let actualspenthrs = index.hasOwnProperty('actualspenthrs') ? index.actualspenthrs == null || index.actualspenthrs == 'NA' ? '' : index.actualspenthrs : '';
          
             let estlivetaskperhrs = index.hasOwnProperty('estlivetaskperhrs') ? index.estlivetaskperhrs == null || index.estlivetaskperhrs == 'NA' ? '' : index.estlivetaskperhrs : '';
            
             let actuallivetaskperhrs = index.hasOwnProperty('actuallivetaskperhrs') ? index.actuallivetaskperhrs == null || index.actuallivetaskperhrs == 'NA' ? '' : index.actuallivetaskperhrs : '';

             let pendingtask = index.hasOwnProperty('pendingtask') ? index.pendingtask == null || index.pendingtask == 'NA' ? '' : index.pendingtask : '';
            
             let esttomakelivehrs = index.hasOwnProperty('esttomakelivehrs') ? index.esttomakelivehrs == null || index.esttomakelivehrs == 'NA' ? '' : index.esttomakelivehrs : '';
            
             let actualrequiredtolivehrs = index.hasOwnProperty('actualrequiredtolivehrs') ? index.actualrequiredtolivehrs == null || index.actualrequiredtolivehrs == 'NA' ? '' : index.actualrequiredtolivehrs : '';
            
             let estrequiredtomakelivehrs = index.hasOwnProperty('estrequiredtomakelivehrs') ? index.estrequiredtomakelivehrs == null || index.estrequiredtomakelivehrs == 'NA' ? '' : index.estrequiredtomakelivehrs : '';
            
             let actualrequiredtomakelivehrs = index.hasOwnProperty('actualrequiredtomakelivehrs') ? index.actualrequiredtomakelivehrs == null || index.actualrequiredtomakelivehrs == 'NA' ? '' : index.actualrequiredtomakelivehrs : '';

            row += '"' + srno + '",',
            row += '"' + projectname + '",',
            row += '"' + totaltechteam + '",',
            row += '"' + totalqateam + '",',
            row += '"' + taskestimates + '",',
            row += '"' + nooflivetask + '",',
            row += '"' + estimatedhrsspent + '",',
            row += '"' + actualspenthrs + '",',
            row += '"' + estlivetaskperhrs + '",',
            row += '"' + actuallivetaskperhrs + '",',
            row += '"' + pendingtask + '",',
            row += '"' + esttomakelivehrs + '",',
            row += '"' + actualrequiredtolivehrs + '",',
            row += '"' + estrequiredtomakelivehrs + '",',
            row += '"' + actualrequiredtomakelivehrs + '",',
            row += '\r\n'
            }

            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            return;
            }
            let fileName = 'ProjectPrdouctivityReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            let link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });   
        }
    },
    export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
  },
};
</script>
