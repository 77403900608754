<template>
    <div class="app-content content ">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="card-navigation modern-horizontal-wizard">
          <div class="content-header-left col-md-9 col-12 mb-1 mt-1">
            <div class="row breadcrumbs-top">
              <div class="col-12 px-0">
                <h3 class="content-header-title float-left mb-0">Dynamic Ticket Details</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TCList', params: { search: true }}">Dynamic Ticket Request Management List</router-link></li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls">View Dynamic Ticket</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-warning" >
            <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
              <div class="card-header py-1" style="cursor:pointer">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-auto d-inline-flex align-items-center">
                      <div class="avatar bg-light-primary rounded d-inline-flex">
                        <div class="avatar-content">
                          <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                        </div>
                      </div>
                      <h3 class="card-title ml-2 d-inline-flex text-primary">TICKET CODE: {{this.view.issuecode}}</h3>
                    </div>
                    <div class="ml-auto text-right">
                      <div v-if="(btcissuebtnaccessarry.includes(parseInt(loginuscondition)) && editRowData.childid == null)" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="redirectToIssueCreate('createTicketBTC')">
                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                        <span>Create BTC</span>
                      </div>
                      <div v-if="(btcissuebtnaccessarry.includes(parseInt(loginuscondition)) && editRowData.childid == null)" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="redirectToIssueCreate('createTicketIssue')">
                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                        <span>Create Issue</span>
                      </div>

                      <div v-if="this.companycode !='xb' && loginusername == view.createdby || pagefeatures.edit" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="redirecttourl('edit',view.issueid)">
                        <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                        <span>Edit</span>
                      </div>
                      <div class="btn btn-sm btn-outline-primary waves-effect waves-effect"  @click="openLogModule()">
                        <watch-icon size="1.5x" class="custom-class avatar-icon mr-50"></watch-icon>
                        <span>Log Time</span>
                    </div>
  
                    </div>
                  </div>
                </div>
            </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                  <div class=" card-transaction">
                      <div class="mb-2" >
                          <div class="transaction-item">
                          <div class="media">
                              <div class="avatar bg-light-success rounded">
                                  <div class="avatar-content">
                                      <pen-tool-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pen-tool-icon>
                                  </div>
                              </div>
                              <div class="media-body">
                                  <h6 class="transaction-title">{{this.issue.issuename}}</h6>
                                  <small>Created by {{this.view.createdby}}</small>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
                </div>
                </div>
  
                <div class="row match-height">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="card card-transaction">
                    <div class="">
                      <div class="transaction-item">
                        <div class="media">
   
                          <div v-if="this.issue.priority=='high'" class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                                <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                            </div>
                          </div>
                          <div v-else-if="this.issue.priority=='low'" class="avatar bg-light-info rounded">
                            <div class="avatar-content">
                                <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                            </div>
                          </div>
                          <div v-else-if="this.issue.priority=='medium'" class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                                <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                            </div>
                          </div>
                          <div v-else class="avatar bg-light-dark rounded">
                            <div class="avatar-content">
                                <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Priority</h6>
                            <small v-if="this.issue.priority=='high'" class="capitalisetext text-danger" style="font-weight:500">{{this.issue.priority}}</small>
                            <small v-else-if="this.issue.priority=='low'" class="capitalisetext text-info" style="font-weight:500">{{this.issue.priority}}</small>
                            <small v-else-if="this.issue.priority=='medium'" class="capitalisetext text-warning" style="font-weight:500">{{this.issue.priority}}</small>
                            <small v-else class="capitalisetext text-dark" style="font-weight:500">
                            {{this.issue.priority ? this.issue.priority: 'NA'}}</small> 
                             
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div v-if="this.view.sevairtyname=='Critical'" class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                                <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                            </div>
                          </div>
                          <div v-else-if="this.view.sevairtyname=='Major'" class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                                <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                            </div>
                          </div>
                          <div v-else-if="this.view.sevairtyname=='Minor'" class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                                <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                            </div>
                          </div>
                          <div v-else-if="this.view.sevairtyname=='Cosmetic'" class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                                <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                            </div>
                          </div>
                          <div v-else-if="this.view.sevairtyname=='Moderate'" class="avatar bg-light-info rounded">
                            <div class="avatar-content">
                                <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                            </div>
                          </div>
                          <div v-else class="avatar bg-light-dark rounded">
                            <div class="avatar-content">
                                <em class="i_icon_st la-bug las font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Severity</h6>
                            <small v-if="this.view.sevairtyname=='Critical'" class="text-danger" style="font-weight:500">{{this.view.sevairtyname}}</small>
                            <small v-else-if="this.view.sevairtyname=='Major'" class="text-warning" style="font-weight:500">{{this.view.sevairtyname}}</small>
                            <small v-else-if="this.view.sevairtyname=='Minor'" class="text-primary" style="font-weight:500">{{this.view.sevairtyname}}</small>
                            <small v-else-if="this.view.sevairtyname=='Cosmetic'" class="text-success" style="font-weight:500">{{this.view.sevairtyname}}</small>
                            <small v-else-if="this.view.sevairtyname=='Moderate'" class="text-info" style="font-weight:500">{{this.view.sevairtyname}}</small>
                            <small v-else class="capitalisetext text-dark" style="font-weight:500">{{ this.view.sevairtyname ? this.view.sevairtyname: 'NA'}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div v-if="this.issue.status=='open'" class="avatar bg-light-warning danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>
                           <div v-else-if="this.issue.status=='resolved'" class="avatar bg-light-primary danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>
                           <div v-else-if="this.issue.status=='closed'" class="avatar bg-light-success danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>
                           <div v-else-if="this.issue.status=='reopen'" class="avatar bg-light-danger danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>
                          <div v-else-if="this.issue.status=='feedback'" class="avatar bg-light-secondary danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>
                          <div v-else class="avatar bg-light-secondary danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Status</h6>
                            <small v-if="this.issue.status=='open'" class="capitalisetext text-warning" style="font-weight:500">{{this.issue.status}}</small>
                            <small v-else-if="this.issue.status=='resolved'" class="capitalisetext text-primary" style="font-weight:500">{{this.issue.status}}</small>
                            <small v-else-if="this.issue.status=='closed'" class="capitalisetext text-success" style="font-weight:500">{{this.issue.status}}</small>
                            <small v-else-if="this.issue.status=='reopen'" class="capitalisetext text-danger" style="font-weight:500">{{this.issue.status}}</small>
                            <small v-else-if="this.issue.status=='feedback'" class="capitalisetext text-dark" style="font-weight:500">{{this.issue.status}}</small>
                            <small v-else class="capitalisetext text-dark" style="font-weight:500">{{this.issue.status}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-info rounded">
                            <div class="avatar-content">
                              <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Type</h6>
                            <small>{{this.view.typename}}</small>
                          </div>
                        </div>
                      </div>                   
                    </div>                                                     
                  </div>
                </div>              
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="card card-transaction">
                    <div class="">
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="las la-clipboard-list font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Micro Task</h6>
                            <small>{{this.view.taskname}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-danger rounded">
                              <div class="avatar-content">
                                <em class="las la-boxes font-medium-5"></em>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 v-if="this.editRowData.projectid == merchandisingProId && this.issue.sectionmodulename == sectionmoduleId1" class="transaction-title">Product ID</h6>
                              <h6 v-else-if="this.editRowData.projectid == merchandisingProId && this.issue.sectionmodulename == sectionmoduleId2" class="transaction-title">Product Group ID</h6>
                              <h6 v-else class="transaction-title">Functionality</h6>
                              <small>{{this.view.functionlityname}}</small>
                            </div>
                          </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Project</h6>
                            <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttoPNM('view', {moduleid:view.projectid})">{{this.view.projectname}}</small>
                          </div>
                        </div>
                      </div>
                      <div v-if="editRowData.projectid == merchandisingProId" class="transaction-item">
                      <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                        <div class="media">
                          <div class="avatar bg-light-success rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Brand</h6>
                            <small class="capitalisetext">{{data.projectmodulename?data.projectmodulename:'NA'}}</small>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                        <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <em class="i_icon_st la-hdd las lab font-medium-5"></em>
                              </div>
                            </div>
                          <div class="media-body">
                              <h6 class="transaction-title capitalisetext">Category</h6>
                              <small class="capitalisetext">{{data.applicationname?data.applicationname:'NA'}}</small>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                        <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                          <div class="media">
                            <div class="avatar bg-light-info rounded">
                              <div class="avatar-content">
                                <em class="i_icon_st la-buffer lab font-medium-5" style="font-weight: 500;"></em>
                              </div>
                            </div>
                          <div class="media-body">
                              <h6 class="transaction-title">Sub Category</h6>
                              <small class="capitalisetext">{{data.applicationmodulename?data.applicationmodulename:'NA'}}</small>
                          </div>
                        </div>
                      </div>
                      </div>
                      <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                        <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <em class="las la-box font-medium-5"></em>
                              </div>
                            </div>
                          <div class="media-body">
                              <h6 class="transaction-title">Product ID/ Product Group ID</h6>
                              <small>{{data.sectionname?data.sectionname:'NA'}}</small>
                          </div>
                          </div>
                          </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="las la-sync-alt font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">QA Cycle</h6>
                            <small>{{this.view.qacycle}}</small>
                          </div>
                        </div>
                      </div>
                      <div  class="transaction-item" v-if="this.issue.project != merchandisingProId">
                        <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                          <div class="media">
                            <div class="avatar bg-light-primary rounded">
                              <div class="avatar-content">
                                <em class="las la-box font-medium-5"></em>
                              </div>
                            </div>
                          <div class="media-body">
                              <h6 class="transaction-title">Ticket Category</h6>
                              <small>{{data.projectmodulename?data.projectmodulename:'NA'}}</small>
                          </div>
                          </div>
                          </div>
                      </div>
                      <div  class="transaction-item" v-if="this.issue.project != merchandisingProId">
                        <div classs = "transaction-item" v-for="(data,index) in getMRPDetails" v-bind:key="index">
                          <div class="media">
                            <div class="avatar bg-light-info rounded">
                              <div class="avatar-content">
                                <em class="i_icon_st la-buffer lab font-medium-5" style="font-weight: 500;"></em>
                              </div>
                            </div>
                          <div class="media-body">
                              <h6 class="transaction-title">Ticket Sub Category</h6>
                              <small class="capitalisetext">{{data.applicationmodulename?data.applicationmodulename:'NA'}}</small>
                          </div>
                        </div>
                      </div>
                      </div>
                    </div>                                                                
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="card card-transaction">
                    <div class="">
                      <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Assigned To</h6>
                            <small class="capitalisetext">{{this.view.empname}}[{{decryptText(this.view.department)}}]</small>                          </div>
                        </div>
                      </div>
                      <div v-else class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Assigned To</h6>
                            <small class="capitalisetext">{{this.view.empfullname}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Created By</h6>
                            <small>{{this.view.createdby}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Created Date</h6>
                            <small>{{dateMonthFormat(this.view.createddate)}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Last Modified By</h6>
                            <small>{{this.view.lastmodifiedby}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Last Modified Date</h6>
                            <small>{{dateMonthFormat(this.view.lastmodifieddate)}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item" v-if="interviewrating">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              <em class="las la-star font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Interview Feedback rating</h6>
                            <small>{{ interviewrating ? interviewrating : 'NA' }}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div class="col-12 px-0">
                    <div v-if="this.queanslist.length > 0">
                    <div class="card pb-0 mb-0 pt-0">
                      <div class="container px-0">
                              
                                  <div class="card-body pb-0">
                                      <form>
                                          <div class="row">
                                              <div class="col-md-12 px-0">
                                                  <div class="mb-0">
                                                      
                                                          <div aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="card-transaction">
                                                              
                                                              <div class="row  ">
                                                                  <div class="media col-sm-4 " v-for="(v, i) in  queanslist" style="margin-bottom: 1.5rem;">
                                                                    <div class="transaction-item">
                                                                      <div class="avatar bg-light-warning rounded" >
                                                                        <div class="avatar-content">
                                                                          <em class="las la-clipboard-list font-medium-5"></em>
                                                                        </div>
                                                                          
                                                                      </div>
                                                                      <div class="avatar-content ticket_dynamic_title03">
                                                                        <h6 class="transaction-title">  {{v.fieldname}} </h6>
                                                                          <div class="media-body">
                                                                              <div class="custom-container case_bg_17">
                                                                                  <div class="outer" :id="'cycle_readmore'+i">
                                                                                      <span class="fc_m16_btcode_text" v-html="v.fieldvalue ? formatData(v.fieldvalue) :'NA'"></span>
                                                                                  </div>
                                                                                  <div class="cycle_viewmoretext" v-if="v.fieldvalue && v.fieldvalue.length > 150">
                                                                                      <a class="btn">
                                                                                      <strong :id="i" @click="idclick(i)">
                                                                                          Read More
                                                                                      </strong>
                                                                                      </a>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </form>
                                  </div>
                      </div>
                    </div>
                </div>
                </div>
                <div class="col-12 px-0">
                  <div v-if="queanslist2 && queanslist2.length > 0">
                    <div class="card pb-0 mb-0 pt-0">
                      <div class="container px-0">
                        <div class="card-body pb-0">
                          <form>
                            <div class="row">
                              <div class="col-md-12 px-0">
                                <div class="mb-0">
                                  <div aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="card-transaction">
                                    <div class="row  ">
                                      <div class="media col-sm-4 " v-for="(v2, i2) in  queanslist2" style="margin-bottom: 1.5rem;">
                                        <div class="transaction-item">
                                          <div class="avatar bg-light-warning rounded" >
                                            <div class="avatar-content">
                                              <em class="las la-clipboard-list font-medium-5"></em>
                                            </div>
                                          </div>
                                          <div class="avatar-content ticket_dynamic_title03">
                                            <h6 class="transaction-title">  {{v2.fieldname}} </h6>
                                              <div class="media-body">
                                                  <div class="custom-container case_bg_17">
                                                      <div class="outer" :id="'cycle_readmore'+i2">
                                                        <span class="fc_m16_btcode_text" v-html="v2.fieldvalue ? formatData(v2.fieldvalue) :'NA'"></span><br/>
                                                        Comment: <span class="fc_m16_btcode_text" v-html="v2.comment ? formatData(v2.comment) :'NA'"></span>
                                                      </div>
                                                      <div class="cycle_viewmoretext" v-if="v2.fieldvalue && v2.fieldvalue.length > 150">
                                                          <a class="btn">
                                                          <strong :id="i2" @click="idclick(i2)">
                                                            Read More
                                                          </strong>
                                                          </a>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
                <div class="container mb-1">
                  <div class="row media-list mx-0">
                          <div class="col-sm-6">
                            <div class="media business_dis_rows business_dis_rowsbg bg_bt2 mb-1">
                            <div class="media-left ">
                              <div class="avatar bg-light-primary rounded avatar-lg ">
                              <div class="avatar-content">
                                <package-icon size="1.5x" class="custom-class  avatar-icon font-medium-3 text-white" ></package-icon>
                              </div>
                              </div>
                            </div>
  
                            <div class="media-body data_dynamic_discription_ids">
                              <h6 class="transaction-title text-white">Reason of ticket	</h6>
                              <span class="disc text-white" v-html="this.issue.reasonofissue"></span>
                            </div>
                            </div>
                          </div>
  
                          <div class="col-sm-6">
                          <div class="media business_dis_rows mb-1 business_dis_rowsbg bg_bt4">
                            <div class="media-left">
                              <div class="avatar bg-light-primary rounded avatar-lg">
                                <div class="avatar-content">
                                  <package-icon size="1.5x" class="custom-class  avatar-icon font-medium-3 text-white"></package-icon>
                                </div>
                              </div>
                            </div>
                            <div class="media-body data_dynamic_discription_ids">
                              <h6 class="transaction-title text-white">Impact of ticket	</h6>
                              <span class="disc text-white" v-html="this.issue.impactofissue"></span>
                            </div>
                            </div>
                        </div>
  
                        <div class="col-sm-12">
                        <div class="card-transaction business_dis_rows mb-1 business_dis_rows_disc border">
                          <div class="transaction-item px-1 pb-1" style="overflow: auto;align-items: start;">
                          <div class="media-left">
                            <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                              </div>
                            </div>
                          </div>
                          <div class="media-body data_dynamic_discription_ids">
                            <h6 class="transaction-title">Ticket Scenario</h6>
                            <div class=" tast_case_groupingview_readmore mx-0">
                                <div class="col-md-12 px-0 micro_case_groupingview_readmore">
                                    <div class="custom-container case_bg_17">
                                        <div v-if="this.issue.scenario" v-bind:class="[readmore]" :id="'cycle_readmore8'">
                                            <span class="fc_m16_btcode_text" v-html="formatData(this.issue.scenario)"></span>
                                            
                                        </div>
                                        <div class="cycle_viewmoretext" v-if="this.issue.scenario && this.issue.scenario.length > 50">
                                            <a class="btn">
                                            <strong :id="'id8'" @click="idclick8()">
                                              Read More
                                          </strong>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          </div>
                        </div>
                        </div>
  
  
  
                        </div>
  
                </div>
                
                <div v-if="issue.history" class="col-lg-12 col-md-6 col-12" style="display:none;">
                    <div class="card-body">
                      <h5 class="mb-1">History</h5>
                      <div class="row tbl">
                        <div class="table-responsive mb-1">
                          <table class="table table-sm table-bordered" aria-describedby="mydesc">
                            <thead>
                              <tr>
                                <th scope="col">SR#</th>
                                <th scope="col">Status</th>
                                <th scope="col">Assigned To</th>
                                <th scope="col">Action Date</th>
                                <th scope="col">Assigned BY</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data,index) in (issue.history ? issue.history:[])" v-bind:key="index">
                                <td>{{index+1}}</td>
                                <td>{{data.status}}</td>
                                <td>{{data.assignedto}}</td>
                                <td>{{dateMonthFormat(data.lastmodifieddate)}}</td>
                                <td>{{data.lastmodifiedby?data.lastmodifiedby.split("@")[0]:''}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                </div>
  
               
            </div>
          </div>
          
  
              
                    <div v-if="issue.attachmentview">
                      <div class="card">
                          <div class="card-header px-2 py-1 border-bottom">
                              <h4 class="card-title">Document List</h4>
                          </div>
                          <div class="card-body pb-0">
                              <div class="row">
                          <div class="col-md-12">
                              <div class="row documentGrid match-height">
                                <div class="col-xl-2 col-md-4 col-sm-6 my-1" v-for="(data,index) in (this.issue.attachmentview ? this.issue.attachmentview.data:[])" v-bind:key="index">
                                  <div class="card card-bordered mb-50">
                                    <div class="text-center py-2" style="background:#F8F8F8;">
                                      <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                      <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                      <a :href="data.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                      <a v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(data.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                      <a v-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(data.presignurl)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>  
                                    </div>
                                      <a :href="data.presignurl" class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">{{data.link | basename}}</a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
  
                    <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">              
                          <div class="modal-body p-0">
                            <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                            <img alt="" src="" class="imagepreview"  >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="customTimeline">
                      <div class="card " v-if="logtimehistory.length > 0">
                        <div class="card-body mb-2">
                          <ul class="timeline">
                            <li class="timeline-item" v-for="(data,index) in logtimehistory" v-bind:key="index">
                              <span v-bind:class="{'timeline-point timeline-point-warning timeline-point-indicator':data.status=='reopen', 'timeline-point timeline-point-secondary timeline-point-indicator':data.status=='closed', 'timeline-point timeline-point-danger timeline-point-indicator':data.status=='open', 'timeline-point timeline-point-success timeline-point-indicator':data.status=='resolved'}"></span>
                              
                              <div class="timeline-event">
                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                  <p class="text-muted" style="margin-top:-3px">Updated on: {{dateMonthFormat(data.lastmodifieddate)}} 
                                    <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.status}}</span>
                                    <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.activity}}</span>
                                    <span v-if="data.isapproved && data.isapproved == 'yes'" class="capitalisetext badge badge-pill badge-light-success mx-150"><i class="las la-check-circle"></i>Ticket Approved</span>
                                    <span v-if="data.approver && data.isapproved == 'no'" class="capitalisetext badge badge-pill badge-light-danger mx-150"><i class="las la-exclamation-circle"></i>Ticket Rejected</span>
                                    <span v-if="data.approver && (data.isapproved == null || data.isapproved == '')" class="capitalisetext badge badge-pill badge-light-danger mx-150"><i class="las la-exclamation-circle"></i>Ticket Pending for Approval</span>
                                    <span v-if="data.approver && data.approver !== null" class="capitalisetext badge badge-pill badge-light-primary mx-150"><i class="las la-user-tie"></i>{{ getFormattedName(data.approver) }}</span>
                                  </p>
                                </div>
                                <div class="d-flex">
                                  <div class="media align-items-center my-50">
                                    <span class="pr-1 text-muted  d-inline-flex">
                                    
                                          <user-plus-icon size="1.5x" class="custom-class avatar-icon font-medium-3 mr-50"></user-plus-icon> 
                                    To</span>
                                    <div class="avatar">
                                      <profilepic v-if="data.assignedtoimagename" v-bind:get-url="'empprofile/'+data.assigntoempcode+'/'+data.assignedtoimagename" :key="`${componentKey + index}`"></profilepic>
                                      <img alt="" v-if="!data.assignedtoimagename" :src="defaultimagepath" width="40" height="40" />
                                    </div>
                                    <div class="media-body ml-50 text-trasform_capitalise">
                                      <h6 class="mb-0 text-nowrap">{{data.assigend}}</h6>
                                      <span class="text-muted text-nowrap">{{data.assignedtodesignatio}}</span>
                                    </div>
                                  </div>
                                </div>

                                <p v-if="data.coments"><span v-html="data.coments"></span></p>
                                <div class="row">
                                <div class="col-xl-2 col-md-4 col-sm-6 my-1" v-for="(res,index) in (data.attachment ? data.attachment.data:[])" v-bind:key="index">
                                  <div class="card card-bordered mb-0">
                                      <div class="text-center py-2" style="background:#F8F8F8;">
                                        <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                        <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                        <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                        <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                        <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                        <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                        <a v-on:click="getLogtimeDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                        <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                      </div>
                                        <a :href="res.link"  class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">
                                          <span v-if="res.info">
                                            {{res.info}} -123
                                          </span>
                                          <span v-else-if="!res.info">
                                            {{res.link | basename}}
                                          </span>
                                          </a>
                                        </div>
                                  </div>
                                  </div>
                              <div class="d-flex flex-sm-row flex-column mt-50 mb-2 pt-50 arrow_box">
                                  <div class="media align-items-center">
                                    <span class="pr-1 text-muted ">
                                      
                                        <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-25"></edit-2-icon>
                                        By</span>
                                      <div class="avatar ">
                                        <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                                          <img alt="" v-if="!data.imagename" :src="defaultimagepath"  width="40" height="40" />
                                      </div>
                                      <div class="media-body ml-50 text-trasform_capitalise">
                                        <h6 class="mb-0 text-nowrap"> {{(data.createdby)}}</h6>
                                        <span class="text-muted text-nowrap">{{data.designation}}</span>
                                        </div>
                                  </div>
                              </div>

                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div>&nbsp;</div>
                    </div>
       
                <div v-bind:class="[{display:dislogActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                  <div class="card-header" style="cursor:pointer">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-md-9">
                          <div class="avatar bg-light-primary rounded d-inline-flex mr-2" >
                            <div class="avatar-content">
                                <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                            </div>
                          </div>
                          <h4 class="card-title text-primary d-inline-flex">Update Log Time</h4>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-bind:class="[{show: isLogActive},card_content,collapse]" >
                    <div class="card-body">
                      <form class="form form-vertical">
                        <div class="col-md-4">
                             <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input"  v-model="logtime.approvalrequired" type="checkbox" id="myCheckbox" >
                              <label class="form-check-label" for="myCheckbox">
                              Approval Required</label>
                            </div>
                            </div>
                          </div>
                        <div class="row">
                          <div class="form-group col-md-3">
                              <div class="form-group mg-bot0">
                              <label class="form-label" for="spendtime">Spent Time</label>
                              <div id="spendtime">
                              <vue-timepicker type="time" v-model="logtime.spenttime" format="HH:mm"  @close="validatelog()"></vue-timepicker>
                              </div>
                              <div class="errorinputmsg" v-if="this.errors.spenttime">{{ this.errors.spenttime }}</div>
                              </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group mg-bot0">
                              <label class="form-label">Activity</label>
                              <treeselect placeholder="Select Activity"  class="projectmdllistdd capitalisetext" v-model="logtime.activity" :options="logtime.acivitylist" @input="selissuelogactivity" @close="validatelog()"/>
                              <div class="errorinputmsg" v-if="this.errors.activity">{{ this.errors.activity }}</div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group mg-bot0">
                                <label class="form-label">Assigned To</label>
                                <treeselect placeholder="Select Enter min 4 Chars to search Assigned To" class="allotedto capitalisetext" v-model="logtime.allotedto" :options="issue.allotedtolist" @input="selallotedto" @close="validatelog()" @search-change="getEmpallocatedtoProject" :disabled="logtime.approvalrequired"/>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.allotedto">{{ this.errors.allotedto }}</div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group mg-bot0">
                                <label class="form-label">Status</label>
                                <treeselect placeholder="Select Status" class="projectmdllistdd capitalisetext" v-model="logtime.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validatelog()"/>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                          </div>
                          
                          <div class="col-md-3" v-if="(editRowData.status && editRowData.status !='closed') && (logtime.status == 'closed') && (issue.type == interviewtype || issue.type == resumereviewtype) && issue.project == recruitmentprojectid">
                            <div class="form-group mg-bot0">
                              <label for="projectmdllistdd">Candidate Status</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select candidate status" v-model="issue.candidatestatus" :options="candidatestatuslist" @input="selcandidatestatus" @close="validateForm('t42',$event)"/>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.issue.candidatestatus">{{ this.errors.issue.candidatestatus }}</div>
                          </div>

                          <div v-if="this.logtime.approvalrequired == true" class="col-md-3">
                            <div class="form-group mg-bot0">
                              <label for="projectlistdd">Approver Email</label>
                              <input type="text" id="issuename" class="form-control" name="" placeholder="Enter Approver Email" v-model="logtime.approver"  @input="validateEmail" @close="validateLog()" @keydown.enter.prevent/>
                              <div v-if="!isEmailValid" class="error-msg">Please enter valid email address</div>
                            </div>
                            <div class="errorinputmsg" v-if="this.errors.approver">{{ this.errors.approver }}</div>

                          </div>
<!-- ----Dynamic log Start--- -->

                          <div class="col-md-12 px-0">
                            <div class="row row mx-0 w-100" v-if="checkProjectInclusion()">
                                <div class="col-md-4" v-for="(fieldname, i) in this.fieldnamelist" :key="i">
                                    <div class="form-group mb-1" id="disabledfield">
                                      <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                        <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                        <span v-if="fieldname.fieldtype=='text' && fieldname.fieldtype !='hidden'">
                                        <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                            @blur="validateConfigForm($event)"
                                            v-bind:class="{ 
                                            'form-control': true,
                                            '': !validText(fieldsnamevalues[fieldname.id])}"
                                            @close="validateConfigForm($event)"
                                            v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                            <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                            @blur="validateConfigForm($event)"
                                            v-bind:class="{ 
                                            'form-control': true,
                                            '': !validText(fieldsnamevalues[fieldname.id])}"
                                            @close="validateConfigForm($event)"
                                            v-else/>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                            </p>
                                        </span>
                                         <span v-if="fieldname.fieldtype=='number'">
                                          <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                          pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                          :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                          @input="dynamicroster"
                                          v-bind:class="{
                                          'form-control': true,
                                          '': !validText2(fieldsnamevalues[fieldname.id])}"
                                          />
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='drop-down'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1" 
                                            v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')"  disabled/>
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1"  v-else/>

                                            
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true" 
                                            v-if="(isFieldDisabled(fieldname) && fieldname.validationtype != 'readonly' && fieldname.projectvalidationtype != 'readonly') || (fieldname.validationtype=='readonly' || fieldname.projectvalidationtype=='readonly')" disabled/>
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true"  v-else />
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='checkbox'">
                                            <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" :multiple="true" :clear-on-select="true" />
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='radio'">
                                            <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                <div class=" form-check form-check-inline mt-0 mb-0">
                                                    <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id"  > <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                </div>
                                            </span>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='date'">
                                            <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                            class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeToday" ></date-picker>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                            <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                            <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                            <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                        </span>
                                        <span v-if="fieldname.fieldtype=='toggle'">
                                            <div class="custom-control custom-switch custom-switch-secondary custom-switch-width-01">
                                                <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]" />
                                                <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                    <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                    <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                                </label>
                                            </div>
                                        </span> 
                                    </div>
                                </div>


                            </div>
                              <div class="col-md-4" v-for="(fieldname, i) in projectflagfieldnamelist" :key="i">
                                  <div class="form-group mb-1">
                                  <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                      <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                      <span v-if="fieldname.fieldtype=='text'">
                                      <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                      @blur="validateConfigForm($event)"
                                    
                                      v-bind:class="{ 
                                      'form-control': true,
                                      '': !validText(fieldsnamevalues[fieldname.id])}"
                                      @close="validateConfigForm($event)"
                                      v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden') || isFieldDisabled(fieldname) || fieldname.isReadOnly" disabled/>                          
                                      <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                      @blur="validateConfigForm($event)"
                                    
                                      v-bind:class="{ 
                                      'form-control': true,
                                      '': !validText(fieldsnamevalues[fieldname.id])}"
                                      @close="validateConfigForm($event)"
                                      v-else />
                                      <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                      </p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='number'">
                                      <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                      pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                      :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                      
                                      v-bind:class="{
                                      'form-control': true,
                                      '': !validText2(fieldsnamevalues[fieldname.id])}"
                                      />
                                      <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='drop-down'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1"/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" :multiple="true" :clear-on-select="true"/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='checkbox'">
                                          <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" :multiple="true" :clear-on-select="true"/>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='radio'">
                                          <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                              <div class=" form-check form-check-inline mt-0 mb-0">
                                                  <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id" > <span class="pl-1"> {{Object.values(radiofieldvalues)[0]}} </span>
                                              </div>
                                          </span>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='date'">
                                          <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                          class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeToday"></date-picker>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                          <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                          <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                          <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                      </span>
                                      <span v-if="fieldname.fieldtype=='toggle'">
                                          <div class="custom-control custom-switch custom-switch-secondary custom-switch-width-01">
                                              <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]"/>
                                              <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                  <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                  <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                              </label>
                                          </div>
                                      </span>
                                  </div>
                              </div>

                          </div>



<!-- ----Dynamic END---- -->

                          <div class="col-12">
                            <div class="form-group mg-bot0">
                              <label for="reasonofissue">Comment</label>
                              <ckeditor :editor="editor" :config="editorConfig" id="reasonofissue" class="form-control" name="" placeholder="Enter Comments" v-model="logtime.comment" 
                                v-bind:class="{ 
                                  'form-control': true,
                                  'is-invalid': !validText(logtime.comment) && commentBlured, 
                                }" v-on:blur="commentBlured = true"/>
                                <div class="invalid-feedback">Comment required</div>
                            </div>
                          </div>
                          <div class="col-md-12" style="margin-top:24px">
                            <div class="form-group">
                              <div class="form-group">
                                <label for="attachment" class="btn-sm btn btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                                <div class="table-responsive" v-if="file.length>0">
                                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col">SR#</th>
                                                <th scope="col">Document Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(image, key) in file" :key="key">
                                                <td>{{ key+1 }}</td>
                                                <td>{{ image.name }}</td>
                                                <td>
                                                    <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="issue.filedescp[key]"/>
                                                </td>
                                                <td class="text-center">
                                                    <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button class="btn btn-relief-secondary btn-sm mb-75 mr-75 "  v-on:click.stop.prevent="onUpload()" >Upload</button>
                                <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                              </div>
                            </div>
                          </div>

                          <div v-if="(editRowData.status && editRowData.status !='closed') && (logtime.status == 'closed' || logtime.status == 'Closed') && editRowData.issuetype == interviewtype && issue.project == recruitmentprojectid && interviewconfigurationForm">
                            <div class="col-md-12 mt-2">
                                <fieldset class="card fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi  business_fieldset_bg" v-if="tempcheck2.length>0">
                                    <legend> Interview Evaluation Form </legend>
                                    <div class="row">
                                        <div class="col-md-4" v-for="(fieldname,i) in this.fieldnamelist2" v-bind:key="i" v-if="fieldname.fieldtype !='hidden'">
                                            <div class="form-group mb-1" v-if="fieldname.fieldheader==null">
                                                <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required' && fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                                <label for="projectlistdd" v-else-if="fieldname.fieldtype !='hidden'">{{fieldname.label}}:</label>
                                                <span v-if="fieldname.fieldtype=='text' && fieldname.fieldtype !='hidden'">
                                                    <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues2[fieldname.id]"
                                                    v-bind:class="{ 
                                                    'form-control': true,
                                                    '': !validText(fieldsnamevalues2[fieldname.id])}"
                                                    v-if="(fieldname.validationtype=='readonly')" disabled/>

                                                    <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues2[fieldname.id]"
                                                    v-bind:class="{ 
                                                    'form-control': true,
                                                    '': !validText(fieldsnamevalues2[fieldname.id])}"
                                                    v-else/>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues2[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                                    </p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-if="fieldname.fieldtype=='number' && fieldname.fieldtype !='hidden'">
                                                    <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                    pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                    :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues2[fieldname.id]"
                                                    v-bind:class="{
                                                    'form-control': true,
                                                    '': !validText2(fieldsnamevalues2[fieldname.id])}"
                                                    v-if="(fieldname.validationtype=='readonly')" disabled/>
                                                    
                                                    <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                    pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                    :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues2[fieldname.id]"
                                                    v-bind:class="{
                                                    'form-control': true,
                                                    '': !validText2(fieldsnamevalues2[fieldname.id])}"
                                                    v-else/>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues2[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-if="fieldname.fieldtype=='drop-down' && fieldname.fieldtype !='hidden'">
                                                    <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues2[fieldname.id]" :options="fieldname.optionalvalu1"/>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues2[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-if="fieldname.fieldtype=='multiselect-dropdown' && fieldname.fieldtype !='hidden'">
                                                    <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues2[fieldname.id]" :options="fieldname.optionalvalue2" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues2[fieldname.id] || fieldsnamevalues2[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-if="fieldname.fieldtype=='checkbox' && fieldname.fieldtype !='hidden'">
                                                    <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues2[fieldname.id]" :options="fieldname.optionalvalue" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues2[fieldname.id] || fieldsnamevalues2[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-else-if="fieldname.fieldtype=='radio' && fieldname.fieldtype !='hidden'">
                                                    <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                        <div class=" form-check form-check-inline mt-0 mb-0">
                                                            <input class="form-check-input" type="radio" v-model="fieldsnamevalues2[fieldname.id]" :value="Object.values(radiofieldvalues)[0]" @change="changevalue2(fieldname.id,Object.values(radiofieldvalues)[0])"> <span class="" style="margin-left:5px; font-size: 14px;"> {{Object.values(radiofieldvalues)[0]}} </span>
                                                        </div>
                                                    </span>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues2[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-else-if="fieldname.fieldtype=='date' && fieldname.fieldtype !='hidden'">
                                                    <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues2[fieldname.id]" valueType="format"
                                                    class="startdate capitalisetext" id="EndDate" :minute-step="5" v-if="(fieldname.validationtype=='readonly')" disabled></date-picker>

                                                    <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues2[fieldname.id]" valueType="format"
                                                    class="startdate capitalisetext" :id="'EndDate'+i" :minute-step="5" @change="changevalue2(fieldname.id,fieldsnamevalues2[fieldname.id])" v-else></date-picker>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues2[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-else-if="fieldname.fieldtype=='datetime-local' && fieldname.fieldtype !='hidden'">
                                                    <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues2[fieldname.id]" v-if="(fieldname.validationtype=='readonly')" disabled></vue-timepicker>
                                                    <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues2[fieldname.id]" v-else></vue-timepicker>
                                                    <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues2[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-if="fieldname.fieldtype=='toggle' && fieldname.fieldtype !='hidden'">
                                                    <div class="custom-control custom-switch custom-switch-secondary">
                                                        <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues2[fieldname.id]"/>
                                                        <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                            <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                            <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                                        </label>
                                                    </div>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                                <span v-if="fieldname.fieldtype && fieldname.fieldtype =='hidden'" style="display:none;">
                                                    <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" v-model="fieldsnamevalues2[fieldname.id]"/>
                                                    <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[fieldname.id]"
                                                    v-if="fieldname.fieldcomment"/>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </fieldset> -->
                                    
                                    <span v-if="Object.keys(tempheader).length>0">
                                        <div class="mt-0" v-for="(headers,ind) in tempheader" v-bind:key="ind">
                                            <div class="tab-content mb-1 d-inline-block w-100 modal_header_overflow_page" >
                                                <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                                                    <div class="collapse-margin collapse-icon mt-1" :id="'faq-payment-qna'+headers[0].id">
                                                        <div class="card1 resolved rounded">
                                                            <div class="card-header p-1 rounded-0" :id="'paymentOne'+headers[0].id"  role="button" :data-target="'#faq-payment-one'+headers[0].id" aria-expanded="false" aria-controls="faq-payment-one">
                                                                <span class="lead collapse-title">{{ ind }}</span>
                                                            </div>
                                                            <div :id="'faq-payment-one'+headers[0].id"  :aria-labelledby="'#paymentOne'+headers[0].id" :data-parent="'#faq-payment-qna'+headers[0].id">
                                                                <div class="card-body">
                                                                    <div class="mb-1 px-0" >
                                                                        <div class="table-responsive" style="overflow-x: inherit !important;">
                                                                            <div class="col-md-12 mt-2">
                                                                                <div class="row">
                                                                                    <div class="col-md-4" v-for="(head,indx) in headers" v-bind:key="indx" v-if="head.fieldtype !='hidden'">
                                                                                        <div class="form-group mb-1">
                                                                                            <label for="projectlistdd" class="required-field" v-if="head.validationtype=='required' && head.fieldtype !='hidden'">{{head.label}}:</label>
                                                                                            <label for="projectlistdd" v-else-if="head.fieldtype !='hidden'">{{ head.label }}:</label>
                                                                                            <span v-if="head.fieldtype=='text' && head.fieldtype !='hidden'">
                                                                                                <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues2[head.id]"
                                                                                                v-bind:class="{
                                                                                                'form-control': true,
                                                                                                '': !validText(fieldsnamevalues2[head.id])}"
                                                                                                v-if="(head.validationtype=='readonly')" disabled/>

                                                                                                <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues2[head.id]"
                                                                                                v-bind:class="{
                                                                                                'form-control': true,
                                                                                                '': !validText(fieldsnamevalues2[head.id])}"
                                                                                                v-else/>
                                                                                                <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && !validText(fieldsnamevalues2[head.id])" style="min-width:100%;">{{head.fielderrormsg}}</span>
                                                                                                </p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>
                                                                                            <span v-if="head.fieldtype=='number' && head.fieldtype !='hidden'">
                                                                                                <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                                                                :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues2[head.id]"
                                                                                                v-bind:class="{
                                                                                                'form-control': true,
                                                                                                '': !validText2(fieldsnamevalues2[head.id])}"
                                                                                                v-if="(head.validationtype=='readonly')" disabled/>

                                                                                                <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                                                                :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues2[head.id]"
                                                                                                v-bind:class="{
                                                                                                'form-control': true,
                                                                                                '': !validText2(fieldsnamevalues2[head.id])}"
                                                                                                v-else/>
                                                                                                <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && !validText2(fieldsnamevalues2[head.id])">{{head.fielderrormsg}}</span></p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>

                                                                                            <span v-if="head.fieldtype=='drop-down' && head.fieldtype !='hidden'">
                                                                                                <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues2[head.id]" :options="head.optionalvalu1"/>
                                                                                                <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && fieldsnamevalues2[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>

                                                                                            <span v-if="head.fieldtype=='multiselect-dropdown' && head.fieldtype !='hidden'">
                                                                                                <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues2[head.id]" :options="head.optionalvalue2" @input="selectinput($event)" :multiple="true" :clear-on-select="true"/>
                                                                                                <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && (!fieldsnamevalues2[head.id] || fieldsnamevalues2[head.id].length==0)">{{head.fielderrormsg}}</span></p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>

                                                                                            <span v-if="head.fieldtype=='checkbox' && head.fieldtype !='hidden'">
                                                                                                <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues2[head.id]" :options="head.optionalvalue" @input="selectinput($event)" :multiple="true" :clear-on-select="true"/>
                                                                                                <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && (!fieldsnamevalues2[head.id] || fieldsnamevalues2[head.id].length==0)">{{head.fielderrormsg}}</span></p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>

                                                                                            <span v-if="head.fieldtype=='radio' && head.fieldtype !='hidden'">
                                                                                                <span v-for="(radiofieldvalues,k) in head.radiooptionalvalue" v-bind:key="k"><br />
                                                                                                    <div class=" form-check form-check-inline mt-0 mb-0">
                                                                                                        <input class="form-check-input" type="radio" v-model="fieldsnamevalues2[head.id]" :value="Object.values(radiofieldvalues)[0]" @change="changevalue2(head.id,Object.values(radiofieldvalues)[0])"> <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                                                                    </div>
                                                                                                </span>
                                                                                                <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && fieldsnamevalues2[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>

                                                                                            <p><span v-if="head.fieldtype=='date' && head.fieldtype !='hidden'" >
                                                                                                <date-picker :placeholder="head.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues2[head.id]" valueType="format"
                                                                                                class="startdate capitalisetext" :id="'EndDate'+indx" :minute-step="5" v-if="(head.validationtype=='readonly')" disabled></date-picker>

                                                                                                <date-picker :placeholder="head.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues2[head.id]" valueType="format"
                                                                                                class="startdate capitalisetext" :id="'EndDate'+indx" :minute-step="5" @change="changevalue2(head.id,fieldsnamevalues2[head.id])" v-else></date-picker>
                                                                                                <p><span class="errorinputmsg capitalisetext"  style="min-width:100%;" v-if="head.validationtype=='required' && fieldsnamevalues2[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span></p>

                                                                                            <span v-if="head.fieldtype=='datetime-local' && head.fieldtype !='hidden'">
                                                                                                <vue-timepicker class="startdate" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues2[head.id]" v-if="(head.validationtype=='readonly')" disabled></vue-timepicker>
                                                                                                <vue-timepicker class="startdate" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues2[head.id]" v-else></vue-timepicker>
                                                                                                <p><span class="errorinputmsg capitalisetext" v-if="head.validationtype=='required' && fieldsnamevalues2[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>

                                                                                            <span v-if="head.fieldtype=='toggle' && head.fieldtype !='hidden'">
                                                                                                <div class="custom-control custom-switch custom-switch-secondary">
                                                                                                    <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+head.id" v-model="fieldsnamevalues2[head.id]"/>
                                                                                                    <label class="custom-control-label" :for="'customSwitch11'+head.id">
                                                                                                        <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{head.optionalvalue3[0].label}}</label></span>
                                                                                                        <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{head.optionalvalue3[1].label}}</label></span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>

                                                                                            <span v-if="head.fieldtype && head.fieldtype =='hidden'" style="display:none;">
                                                                                                <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" v-model="fieldsnamevalues2[head.id]"/>
                                                                                                <input type="text" id="comments" class="form-control capitalisetext" name="" placeholder='Enter Comment' v-model="fieldsnamevalues3[head.id]"
                                                                                                v-if="head.fieldcomment"/>
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </fieldset>
                            </div>
                          </div>

                          <div class="col-12 mg-top1 text-right">
                            <button type="button" class="btn btn-relief-primary mr-1" @click="savelogData()" v-bind:disabled="issue.disblesavebtn">Submit</button>
                            <button type="reset" class="btn btn-outline-secondary" @click="handleCancel2()">Cancel</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
        </section>
      </div>
    </div>
  </template>
  <style>
  
  .issue-view-title {
    background-color: #f8f9fa;
    padding: 6px 24px;
    font-weight: bold;
    margin-right: 20px;
  }
  .card .card-header{
    padding: 0.8rem;
  }
  .createbtnparentdiv{
    padding-right: 0% !important;
  }
  .displayblk{
    display:block !important;
  }
  .createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  .tbl {
    margin-left: 0 !important;
  }
  
  .disc p:last-child{
    margin-bottom: 0;
  }
  .informative-msg {
      color: #17a2b8;
      padding: 10px 0px;
      border-radius: 5px;
      font-size: 12px;
  }
  .error-msg {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
  }
  </style>
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
  import { PaperclipIcon,WatchIcon,EditIcon, MinusSquareIcon, Trash2Icon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon, AlertCircleIcon,UserPlusIcon,ZoomInIcon,XIcon,PieChartIcon} from 'vue-feather-icons'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Pagination from 'vue-pagination-2'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import presignurl from '../getPresignurl.vue';
  import profilepic from '../profilepic.vue';
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import commonMethods from '../../utils/commonMethods';
  
  export default {
    name:'IssueList',
    mixins: [ commonMethods ],
    components:{
      VueTimepicker,
      Loading,WatchIcon,PaperclipIcon,profilepic,
      VueElementLoading,
      vSelect,
      EditIcon,
      EyeIcon,
      Trash2Icon,
      Treeselect,
      SearchIcon,
      GridIcon,
      CodesandboxIcon,
      CloudIcon,
      ChromeIcon,
      BoxIcon,
      Link2Icon,
      PackageIcon,
      Pagination,
      MoreVerticalIcon,
      PlusIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ClipboardIcon,
      UploadCloudIcon, MinusSquareIcon, CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon,
      AlertCircleIcon,
      UserPlusIcon,
      ZoomInIcon,
      XIcon,
      PieChartIcon,
      ckeditor: CKEditor.component,
      presignurl,
      DatePicker,
      VueTimepicker,
    },    
    data() {
      return {
        isThisBusinessRole: false,
        roleid: 0,
        productlabelname: null,
        BRoleid: apiUrl.BRoleid,
        isInProjectAsBR : false,
        TMroleid: apiUrl.TMroleid,
        POroleid: apiUrl.POroleid,
        TOroleid: apiUrl.TOroleid,
        SupportEnquiry: apiUrl.SupportEnquiry,
        BRFeatureProList: apiUrl.BRFeatureProList,
        componentKey: 0,
        imgExt:['jpeg','jpg','png'],
        defaultimagepath:apiUrl.defaultimagepath,
        isRequestActiveView: true,
        dislogActive:true,
        isLogActive:false,
        formActionLabel: 'Create Ticket',
        disRequestActive: true,
        isDetActive:false,
        isRequestActive:true,
        isdocsRequestActive:false,
        isEditDocsRequestActive: false,
        addocument:true,
        editdocument: true,
        isdisabled: true,
        isActive:true,
        files:[],
        doclist:[],
        editdoclist:[],
        alldocs:[],
        attachment: [],
        docs:[{
          title:null,
          docs:null,
          comment:null,
          attachment:null
        }],
        backpagedata: [],
        loginuser:'',
        isLoading: false,
        fullPage: true,
        s3url: apiUrl.s3url,
        stylevariation:apiUrl.stylevariation,
        priorityDefination: apiUrl.priorityDefination,
        priority:'',
        companymasterlist: [],
        editRowData:[],
        glbMdl:'',
        glbMdlLbl:'issue',
        TimeFormat: apiUrl.TimeFormat,
        issue:{
          history:[],
          issuename:null,
          project:null,
          projectlist:null,
          subscriberslist: [],
                  supportengg: [],
          subscribers: null,
          issuenameaddnew: null,
          functionality:null,
          functionalitylist:null,
          type:null,
          typelist:apiUrl.ticketTypeList,
          mrtypelist: [], //apiUrl.mrticketTypelist,
          scenario:null,
          scenariolist:null,
          projectmodulename: null,
          applicationname: null,
          applicationmodulename: null,
          sectionmodulename: null,
          projectmodulelist: [],
          applicationlist: null,
          applicationmodulelist: [],
          sectionmodulelist: null,
          attachment:null,
          attachmentview:null,
          attachmentnew:null,
          sevairty:null,
          sevairtylist:null,
          priority:null,
          protype: null,
          subType: null,
          prioritylist:apiUrl.issueclassification.concat([{
              id:'low',
              label:'Low'
          },{
              id:'medium',
              label:'Medium'
          },{
              id:'high',
              label:'High'
          }]),
          allotedto:null,
          allotedtolist:[],
          reasonofissue:null,
          impactofissue:null,
          status:null,
          statuslist:apiUrl.statuslist,
          iseditstate:false,
          disblesavebtn:false,
          task: null,
          tasklist: [],
          filedescp:[],
          lastproject:null,
          lastprojectmodulename:null,
          lastapplicationmodulename:null ,
          candidatestatus: null,
          candidateid: null,
          interviewroundno: null,
        },
        file:[],
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        pagefeatures:[],
        showtabledata:true,
        showproject:false,
        modulelist:[],
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        errors:{
          spenttime:"",
          activity:null,
          status:null,
          allotedto:null,
          comment: null,
          approver:'',
          issue:{
            issuename:null,
            project:null,
            issuenameaddnew: null,
            functionality:null,
            type:null,
            scenario:null,
            attachment:null,
            sevairty:null,
            priority:null,
            allotedto:null,
            reasonofissue:null,
            impactofissue:null,
            status:null,
            task: null,
            subscribers: null,
            projectmodulename: null,
            applicationname: null,
            applicationmodulename: null,
            sectionmodulename: null,
            protype: null,
            subType: null,
            ticketresolutiontime: null,
            ticketresponsetime: null   
          },
          docs:{
            title:null,
            docs:null,
            comment:null,
            attachment:null
          } 
        },
        isedit: false,
        card:'card',
        border_warning:'border-warning',
        search:{
          searchprojectname :null,
          functionality: null,
          task:null,
          issuename:null,
          type:null,
          sevairty:null,
          priority:null,
          status:null,
          allotedto:null,
          projectnameList: [],
          functionalitylist: [],
          allotedtolist: [],
          tasklist: [],
        },
        projectnameList:[],
        issuenameList:[],
        view:{
          issueid: null,
          projectname: null,
          functionlityname: null,
          typename: null,
          sevairtyname: null,
          taskname: null,
          createdby: null,
          empfullname: null,
          empnamedept: null,
          issuecode: null,
          documentlist: [],
          createddate: null,
          lastmodifiedby: null,
          lastmodifieddate: null,
        },
        editor: ClassicEditor,
        editorConfig: {
          toolbar: {
            items: [
              'heading', '|', 'bold',
              'italic', '|', 'bulletedList',
              'numberedList', '|', 'insertTable', '|',
              'undo', 'redo', '|',
            ],
          },
        },
        s3bucket: apiUrl.s3url ,
        issuenameBlured:false,
        scenarioBlured:false,
        commentBlured:false,
        approverBlured:false,
        tokendata:null,
        logtime:{
          acivitylist:apiUrl.acivitylist,
          spenttime:"",
          activity:null,
          allotedto:null,
          status:null,
          comment:null,
          attachmentview:[],
          isTimeLogged: false,
          approvalrequired:false,
          approver:""
        },
        loginusername:'',
        logtimehistory:[],
        imagext: ['jpg', 'jpeg', 'png'],
        accepttype:apiUrl.uploadfiletype,
        userrole:null,
        getMRPDetails: [],
        merchandisingProId: apiUrl.merchandisingProId,
        sectionmoduleId1: apiUrl.sectionmoduleId1,
        sectionmoduleId2: apiUrl.sectionmoduleId2,
        validFileExtensions:apiUrl.validFileExtensions,
        brandAllocateEmp: [],
        indItHelpDesk: apiUrl.indItHelpDesk,
        ifItHelpDeskNotFound: apiUrl.ifItHelpDeskNotFound,
        itHelpDesk: apiUrl.indItHelpDesk,
        companycode: apiUrl.companycode,
        queanslist: [],
        tempcheck: [],
        fieldsnamevalues:{},
        fieldnamelist: [],
        moduletrackerid: apiUrl.moduletrackerid,
        isSupportEngg : false,
        FcRoleWiseAPI:apiUrl.FcRoleWiseAPI,
        readmore:'outer',
        priorityMessages: {
          P0: "- Critical: Issues that have a severe impact on the business, causing a complete halt or critical functionality degradation. Immediate attention and availability are required.",
          P1: "- High: Significant issues that affect core functionality, but the system remains operational.",
          P2: "- Medium: Issues that impact specific features or functionalities but do not cause a system-wide disruption.",
          P3: "- Low: Minor issues or feature requests that do not significantly affect operations.",
          P4: "- Lowest: Non-urgent inquiries, general questions, or cosmetic issues."
        },
        autoassignto: null,
        isEmailValid:false,
        btcissuebtnaccessarry: [],
        issuecreateSub: [],
        issuecreateSubArry: [],
        loginuscondition:null,
        interviewtrackerid: apiUrl.interviewtrackerid,
        interviewtype: apiUrl.interviewtype,
        fieldnamelist2: [],
        interviewconfigurationForm: false,
        tempcheck2: [],
        fieldsnamevalues2:{},
        fieldsnamevalues3: {},
        saveddata2: [],
        interviewrating: null,
        recruitmentprojectid: apiUrl.recruitmentprojectid,

        projectflagfieldnamelist:[],
        finalvalidationArray: [],
        finalvalidationProjectIDArray: [],
        fieldInfo:[],
        fieldnamelistTemp:[],
        candidatestatuslist: apiUrl.candidatestatuslist,
        resumereviewtype:apiUrl.resumereviewtype,
        queanslist2: [],
      }
    },
    created() {
      this.pagefeatures = {}
      if(window.localStorage.getItem('pagefeatures')){
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
          if (element.page === '/dynamic_tickets/tclist') {
            this.pagefeatures[element.featurename] = element.featureaccess
          }
        })
      }
    },
    mounted(){
      window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              //this.$router.push('/login');
              window.location.reload();
          }
      });
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
        userdata = JSON.parse(userdata)
        this.undt=userdata
        this.loginusername=userdata.username
        this.loginuscondition = userdata.userid
        this.tokendata = window.localStorage.getItem('token');
        this.userrole = window.localStorage.getItem("userrole");
        this.roleid = this.undt.roleid
        this.getProjectAllocatedToEmp()
        this.getIssueById(this.$route.query.ticketid)
        // if(this.roleid && parseInt(this.roleid) === 413) {
          this.isThisBusinessRole = true
        // }
        this.getApplicationdata('t44','issue')
        this.getSubscribers(this.$route.query.ticketid, 'ticket')
        //this.getAllfieldnamelist()
          this.getprojectflagfields()
        // this.getConfigurationFormDetails(this.$route.query.ticketid,null)
        // this.getorientationfeedbackformlabels(this.moduletrackerid)
      }
      $("[data-toggle=popover]").popover({
        html: true,
        trigger: "focus",
        content: function () {
          let content = $(this).attr("data-popover-content");
          return $(content).children(".popover-body").html();
        }
      });     
      
    },
  
    methods:{
      // validateEmail() {
      //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      //   this.isEmailValid = emailRegex.test(this.logtime.approver);
      // },
      getprojectflagfields() {
        this.isLoading = true
        let url = 'api/dynamictickets/getprojectflagfields'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: this.moduletrackerid,
            // projectid: project
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
              this.projectflagfieldnamelist = result.data.data
              const idArray = this.projectflagfieldnamelist.map(item => item.id);
                this.idFieldArray = idArray
                for (let i = 0; i < this.projectflagfieldnamelist.length; i++) {
                  if (this.projectflagfieldnamelist[i].fieldtype=="checkbox") {
                      let tempdata = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata[j] = {}
                              tempdata[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalue']=tempdata
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="radio") {
                      let tempdata2 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata2[j] = {}
                              tempdata2[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata2[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['radiooptionalvalue']=tempdata2
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="toggle") {
                      let tempdata5 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata5[j] = {}
                              tempdata5[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata5[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalue3']=tempdata5
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="drop-down") {
                      let tempdata3 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata3[j] = {}
                              tempdata3[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata3[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalu1']=tempdata3
                      }
                  }
                  if (this.projectflagfieldnamelist[i].fieldtype=="multiselect-dropdown") {
                      let tempdata4 = [];
                      for (let j = 0; j < this.projectflagfieldnamelist[i].fieldvalues.length; j++) {
                          if (this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                              tempdata4[j] = {}
                              tempdata4[j].id=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                              tempdata4[j].label=this.projectflagfieldnamelist[i].fieldvalues[j].optionvalue
                          }
                          this.projectflagfieldnamelist[i]['optionalvalue2']=tempdata4
                      }
                  }
              }
          } else if(result.data.errorCode == 3){
              Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
          } else {
              this.projectflagfieldnamelist = [];
          }
      })
  },
      getFormattedName(email) {
        // Extract the name part before the '@' symbol and capitalize each word
        const name = email.split('@')[0].split('.').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return name;
      },
      validateEmail() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.isEmailValid = emailRegex.test(this.logtime.approver);

        if (this.isEmailValid) {
          const atIndex = this.logtime.approver.indexOf('@');
          const dotIndex = this.logtime.approver.indexOf('.', atIndex + 1);

          this.isEmailValid = atIndex !== -1 && dotIndex !== -1 && dotIndex > atIndex + 1;

          if (this.isEmailValid) {
            const domainPart = this.logtime.approver.substring(atIndex + 1);
            const allowedDomains = ['.in', '.com', '.uae'];

            this.isEmailValid = allowedDomains.some(domain => domainPart.toLowerCase().endsWith(domain));
          }
        }
      },


      redirecttourl:function(btnstate,issueid){
          // rowdata.backbtnstate=btnstate
          if(btnstate=="edit"){
            this.$router.push({ 
              path: '/dynamic_tickets/tcupdate?ticketid='+issueid,
              params: {
                backbtnstate:btnstate,
                clickeddata:issueid
              }
            })
          }
        },
      getPriorityMessages() {
          let messages = Object.keys(this.priorityMessages).map(priority => {
              return `${priority}: ${this.priorityMessages[priority]}`;
          });
          return messages.join('<br>');
      },
      getSubscribers(moduleid, moduletype){
          this.isLoading = true;
          let url = "api/subscribers/getSubscribersByModuleid";
          this.input = { moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) { 
                 let decryptdepartmenttext = result.data.data.rows.map(items =>{
                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                  if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                  })
                  this.issue.subscriberslist = result.data.data.rows;
                  this.issue.subscribers = result.data.data.rows.map(d => d.id)
              } else {
                this.issue.subscriberslist = [];
                this.issue.subscribers = null;
              }
          }).catch(e => {
          this.displayError(e)
        });
      },
      selSubscribers(state) {
          this.issue.subscribers = null
          this.issue.subscribers = state
          if(!state || (state && state.length < 1)) {
              this.errors.issue.subscribers = 'Subscribers are required'
          } else {
              this.errors.issue.subscribers = ''
          }
      },
      
      idclick8(){
        if ($("#cycle_readmore8").hasClass("readmore")) {
            $("#id8").html("Read More");
            $("#cycle_readmore8").removeClass("readmore");
            this.readmore = 'outer'
        } else {
            $("#id8").html("Read Less");
            $("#cycle_readmore8").addClass("readmore");
            this.readmore = 'outer readmore'
        }
      },
  
      forceRerender() {
        this.componentKey += 1;
      },
      getIssueById(issueid){
        this.isLoading = true;
        let url = "";
    if(!this.FcRoleWiseAPI){
       url = "api/issue/getIssueById";
        this.input = {
          issueid:issueid,
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
          userrole:this.userrole,
        };
      }
  
      if(this.FcRoleWiseAPI){
        url = "api/issue/getissuelistfc";
        this.input = {
          issuename:issueid,
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
          userrole:this.userrole,
          createdby:[this.undt.username],       
          userroleid:this.roleid,
          ispagetype:"view",
          moduletype:'ticket',
          ispagefeatures:this.pagefeatures.multiviewuser ? this.pagefeatures.multiviewuser : false 
        };
        if(!this.input.ispagefeatures){
          this.input.allotedto = [this.undt.userid]
         } 
      }
  
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            if(this.FcRoleWiseAPI){
            this.editRowData = result.data.data.rows;
            }else{
            this.editRowData = result.data.data;
            }
            this.editRowData=this.editRowData[0]
            this.openEditModule(this.editRowData,4)
            if (this.editRowData.projectid == this.itHelpDesk && this.editRowData.typename == 'Vulnerability') {
              this.verifyLoggedInUserFortc(this.editRowData.projectid,this.editRowData.issuetype);
            }
          } else {
            this.editRowData = [];
            this.$router.push({ name: 'TCList', params: { search: true }})
            Swal.fire({
                title: "Failed!",
                text: "You are not authorised user to view this issue",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            })
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getMRPDetailss(issueid, pid){
        this.isLoading = true;
        let url = "api/issue/getMRPDetails";
        this.input = {
          issueid:issueid,
          useremail: this.undt.username,
          empcode: parseInt(this.undt.userid),
          userrole:this.userrole,
          pid: pid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.getMRPDetails = result.data.data;
          } else {
            this.getMRPDetails = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      openLogModule(){
        this.logtime.spenttime=null
        this.logtime.spenttime = { "HH": "", "mm": "" }
        this.logtime.activity=null
        this.logtime.comment=''
        this.logtime.approver=''
        this.errors.activity=null
        this.errors.coments=null
        this.errors.approver =null
        this.logtime.allotedto=this.editRowData.allotedto
        this.logtime.status= this.editRowData.status
        this.dislogActive=false
        this.isLogActive=true
        this.isRequestActive=false
        this.disRequestActive=true
        this.issue.disblesavebtn = false;
        this.getDynamicLogfields(this.view.projectid)
        // this.getprojectflagfields()
        this.getprojectmaprolide(this.loginuscondition,this.moduletrackerid,this.view.projectid);
        this.getticketconfigdata()

        $('html, body').animate({ scrollTop: $(document).height() }, 800);
      },
      savelogData(){
        this.valid = this.validatelog();
        if(this.valid) {
          this.valid = this.validateConfigForm(this.valid);
        }
        if((this.editRowData.status && this.editRowData.status !='closed') && this.issue.type ==this.interviewtype && (this.logtime.status =='closed') && this.interviewconfigurationForm && this.editRowData.projectid == this.recruitmentprojectid) {
          this.valid = this.interviewvalidateForm(this.valid);
        }
        if(!this.issue.candidatestatus && (this.editRowData.status && this.editRowData.status !='closed') && this.logtime.status =='closed' && this.issue.type == this.resumereviewtype && this.editRowData.projectid == this.recruitmentprojectid){
          this.errors.ticket.candidatestatus="Candidate status required";
          this.valid= false;
        }
        if(this.valid){
          this.logtime.isTimeLogged = true
          this.input={
            moduletype:'ticket',
            modulename:this.editRowData.issuename,
            moduleid:this.editRowData.issueid,
            projectname:this.editRowData.projectname,
            projectid:this.editRowData.projectid,
            activity:this.logtime.activity,
            assignedto:this.logtime.allotedto,
            status:this.logtime.status,
            module_code: this.editRowData.issuecode,
            createdby:this.undt.username,
            attachment:JSON.stringify(this.issue.attachmentnew),
            subscribers: this.issue.subscribers,
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
          }
          this.input.trackerid = this.moduletrackerid

          // if(this.logtime.spenttime) {
          //   this.input.spenttime = this.logtime.spenttime
          // }
          if(typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
              this.input.spenttime = this.logtime.spenttime
          }else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
              this.input.spenttime = this.logtime.spenttime
          }
          if(this.logtime.comment) {
            this.input.coments = this.logtime.comment
          }
          if(this.logtime.approver) {
            this.input.approver = this.logtime.approver
          }
          if(this.issue.attachmentnew) {
            let jsonobj1 = JSON.parse(this.issue.attachmentnew);
            let arraymerge = [...jsonobj1.data]
            this.input.attachment= JSON.stringify({data: arraymerge});
          }
          this.input.fieldvalues = this.fieldsnamevalues
          this.isLoading=true
          axios({
            method: "POST",
            url: 'api/issue/saveLogTime',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.issue.iseditstate =true
              this.issue.status=this.logtime.status
              this.issue.allotedto = this.logtime.allotedto
              this.editRowData.logtimeFlag=1
              this.savewizard('t42',this.editRowData)
              this.dislogActive=true
              this.isLogActive=false
            }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "Failed!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
          }).catch(e => {
          this.displayError(e)
        });
        }
        
  
      },
  
      validatelog: function(){
        var isValid=true;
        let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm
        
        if(!spenttime && typeof(this.logtime.spenttime)=='string')
        {
          let logstime = (this.logtime.spenttime).split(':')
          if(logstime){
              if(logstime[0] && logstime[1]){
                  spenttime = `${logstime[0]}:${logstime[1]}`
                    this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
              }
          }
        }
        if(spenttime && typeof(this.logtime.spenttime)!='string'){
               this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
        }
  
        if(this.logtime.activity && spenttime==''){
          this.errors.spenttime="Spent-time Hours & minutes is required";
          isValid= false;
        }else{
          this.errors.spenttime='';
        }
        if(!this.logtime.activity && spenttime !=''){
          this.errors.activity="Activity is required";
          isValid= false;
        }else{
          this.errors.activity=null;
        }
        if(!this.logtime.allotedto){
          this.errors.allotedto="Assigned to is required";
          isValid= false;
        }else{
          this.errors.allotedto=null;
        }
        if(!this.logtime.status){
          this.errors.status="Status is required";
          isValid= false;
        }else{
          this.errors.status=null;
        }
        if(this.logtime.approvalrequired == true && this.logtime.approver == ''){
          this.errors.approver="approver is required";
          isValid= false;
        }else{
          this.errors.approver=null;
        }
        
        return isValid
      },
      openEditModule(currRowData, flag){
        if(currRowData && currRowData.length==0){
          currRowData = this.editRowData
        }
        this.logtime.isTimeLogged = false
        this.view.issueid = currRowData.issueid
        this.view.projectid=currRowData.projectid
        this.view.projectname = currRowData.projectname
        this.view.functionlityname = currRowData.functionlityname
        this.view.taskname = currRowData.taskname
        this.view.typename = currRowData.typename
        this.view.sevairtyname = currRowData.sevairtyname
        this.view.createdby = currRowData.createdby
        this.view.empfullname = currRowData.empfullname
        this.view.empname = currRowData.empname
        this.view.department = currRowData.department
        this.view.empnamedept = currRowData.empnamedept
        this.view.documentlist = currRowData.attachment ? currRowData.attachment.data:[]
        this.view.issuecode = currRowData.issuecode
        this.issue.issuenameaddnew = currRowData.functionlityname
        this.view.createddate = currRowData.createddate
        this.view.lastmodifiedby = currRowData.lastmodifiedby      
        this.view.lastmodifieddate = currRowData.lastmodifieddate
        this.view.projectmodulename = currRowData.projectmodulename
        this.view.applicationname = currRowData.applicationname
        this.view.applicationmodulename = currRowData.applicationmodulename
        this.view.sectionmodulename = currRowData.sectionname
        this.issue.issuename = currRowData.issuename;
        this.issue.projectmodulename = currRowData.projectmoduleid;
        this.issue.applicationmodulename = currRowData.applicationmoduleid
        this.issue.project = currRowData.project;
        if(currRowData.project && currRowData.project !=null){
          this.getSLAPriorityDetails();
          this.getEmpallocatedtoProject(currRowData.project, 'issue')
          this.getprojectmodules(currRowData.project, 'pmodules');
          this.getprojectmodules(currRowData.project, 'app');
          this.tickettypeformrp(currRowData.project);
          this.getorientationfeedbackformlabels(this.moduletrackerid,currRowData.project);
          this.getAllfieldnamelist(currRowData.project)
          // this.getticketconfigdata(currRowData.project)
          this.getConfigurationFormDetails(currRowData.issueid,currRowData.project)
        }
        if(currRowData.project ==this.recruitmentprojectid && currRowData.status =='closed'){
          this.getConfigurationFormDetails2(currRowData.issueid,this.recruitmentprojectid)
        }
        this.issue.functionality = currRowData.functionality
        this.issue.task = currRowData.taskid
        this.issue.type = currRowData.issuetype
        this.issue.scenario = currRowData.scenario
        this.issue.attachment = currRowData.attachment
        this.view.qacycle = currRowData.qacycle
        this.issue.applicationname = currRowData.applicationid
        this.issue.lastproject = currRowData.project
        this.issue.lastprojectmodulename = currRowData.projectmoduleid
        this.issue.lastapplicationmodulename = currRowData.applicationmoduleid;
        this.issue.candidateid = currRowData.candidateid;
        this.issue.interviewroundno = currRowData.interviewroundno;
        // if (this.issue.project == this.itHelpDesk) {
        //   this.issue.protype = currRowData.projectmoduleid
        //   this.issue.subType = currRowData.applicationmoduleid
        // }
        this.issue.sectionmodulename = currRowData.sectionmoduleid
        if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
          this.issue.attachmentview =[];
          this.getDownloadLink(currRowData.attachment);
        }
        if(this.issue.sectionmodulename == this.sectionmoduleId1) {
            this.productlabelname = 'Product ID';
        } else if(this.issue.sectionmodulename == this.sectionmoduleId2) {
            this.productlabelname = 'Product Group ID';
        }
        this.issue.sevairty = currRowData.sevairty
        this.issue.priority = (currRowData.priority == '' || currRowData.priority == undefined) ? null : currRowData.priority
        this.issue.allotedto = currRowData.allotedto
        this.getAllocateTo(currRowData.allotedto)
        this.issue.reasonofissue = currRowData.reasonofissue
        this.issue.impactofissue = currRowData.impactofissue
        this.issue.status = currRowData.status
        let currStatus = currRowData.status ? currRowData.status : 'open';
        this.getBrandWiseUserDetails(this.issue.projectmodulename, this.issue.project);
        this.getStatusConfigList(this.issue.project, currStatus);
        this.getissueHistory(currRowData.issueid)
        this.getissueLogTimeHistory(currRowData.issueid)
        this.getAppModulesbyprojectid(currRowData.projectid);
        this.getSectionModules(currRowData.projectid);
        this.getApplicationdata('t37','issue')
        if (currRowData.projectid != this.itHelpDesk && currRowData.projectid != this.merchandisingProId) {
            this.getsupportengg(currRowData.projectid);
        }
        this.getMRPDetailss(currRowData.issueid, this.issue.project);
        if(this.companycode =='fc') {
          if(this.BRFeatureProList.length>0) {
            if(this.BRFeatureProList.includes(parseInt(currRowData.projectid))) {
              this.btnVisibilityTR(currRowData.projectid);
            }
          } else {
            this.btnVisibilityTR(currRowData.projectid);
          }
        }        // if(this.issue.project == this.merchandisingProId) {
        //   this.getMRPDetailss(currRowData.issueid, this.issue.project);
        // } else if (this.issue.project == this.itHelpDesk || this.companycode=='xb') {
        //   this.getMRPDetailss(currRowData.issueid, this.issue.project);
        // }
        if(this.docs){
          for(let i in this.docs){
            this.docs[i].comment=null
            this.docs[i].desc=null
            this.docs[i].title=null
            if(i>0){
              this.docs.splice(i, 1);
            }
          }
        }
        if(flag==0){
          this.issue.iseditstate=true
          this.isedit = true
          this.isRequestActive=true
          this.disRequestActive=false
          this.dislogActive=true
          this.isLogActive = false
          this.formActionLabel = `Edit Ticket: ${currRowData.issuename}`
          $('html, body').animate({ scrollTop: $(document).height() }, 800);
        }
        if(flag==2){
          this.isdocsRequestActive=true
          this.addocument=false
          this.editdocument=true
          $('html, body').animate({ scrollTop: $(document).height() }, 1200);
        }
  
        if(flag==3){
          this.isdocsRequestActive=true
          this.editdocument=false
          this.isEditDocsRequestActive = true
          $('html, body').animate({ scrollTop: $(document).height() }, 1200);
        }
        this.dynamicProject = currRowData.projectid
      },
      redirectToIssueCreate(btnstate) {
      if(btnstate == 'createTicketBTC') {
        let rowdata = {
          backbtnstate:btnstate,
          issuecreateSub: this.issuecreateSub,
          issuecreateSubArry: this.issuecreateSubArry,
          rowTData: this.editRowData,
          empid: this.undt.userid,
        }
        this.$router.push({ 
            name: 'Create Business Task ',
            path: '/businesstask/create',
            params: {
                clickeddata: rowdata,
                backbtnstate:btnstate,
            }
        })
      } else if(btnstate == 'createTicketIssue') { 
        let rowdata = {
          backbtnstate:btnstate,
          issuecreateSub: this.issuecreateSub,
          issuecreateSubArry: this.issuecreateSubArry,
          rowTData: this.editRowData,
        }
        this.$router.push({ 
            name: 'IssueCreate',
            path: '/issues/create',
            params: {
                clickeddata: rowdata,
                backbtnstate:btnstate,
            }
        })
      }
    },
      getissueHistory(issueid){
        this.isLoading = true;
        let url = "api/issue/getIssueLogById";
        this.input = {
          issueid:issueid,
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.issue.history = result.data.data;
          } else {
            this.issue.history=[]
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getissueLogTimeHistory(issueid){
        this.isLoading = true;
        let url = "api/issue/getIssueLogTimeById";
        this.input = {
          issueid:issueid,
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          this.forceRerender()
          if (result.data.errorCode == 0) {
            this.logtimehistory = result.data.data;
            const decryptedDepartmentList = this.logtimehistory.map(item => {
            item.assignedtodesignatio = this.decryptText(item.assignedtodesignatio);
            item.designation = this.decryptText(item.designation);
            return item;
            });
          } else {
            this.logtimehistory=[]
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getDownloadLink(data) {
        if(data.data.length>0) {
          let payload = {
            documents:data.data,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
        axios({
              method: "POST",
              url: '/api/imageupload/getdownloadlink',
              data: payload,
              'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            if(result.data.status) {
              this.issue.attachmentview = result.data;
            }
          }).catch(e => {
          this.displayError(e)
        });
        }
      },
      getLogtimeDownloadLink(data, curr=null) {      
        if(data) {
          var input =[]
          input.push({link:data,useremail: this.undt.username,empcode: this.undt.userid })
          let payload = {
            documents:input,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          axios({
                  method: "POST",
                  url: '/api/imageupload/getdownloadlink',
                  data: payload,
                  'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            if(result.data.status) {
              if(result.data.data[0].presignurl){
                if(curr=='zoom'){
                  $('.imagepreview').attr('src', result.data.data[0].presignurl);
                  $('#imagemodal').modal('show');  
                  return 
                }
                window.location.href=result.data.data[0].presignurl;
              }
            }
          }).catch(e => {
          this.displayError(e)
        });
        }
      },
      docsuploadcollapse:function(){
        if(this.docs){
          for(let i in this.docs){
            this.docs[i].comment=null
            this.docs[i].desc=null
            this.docs[i].title=null
            if(i>0){
              this.docs.splice(i, 1);
            }
          }
        }
        this.isDetActive=true
        this.addocument=true
        this.editdocument = true
        this.isdocsRequestActive=!this.isdocsRequestActive
      },
      myeditdocrequestollapse: function() {
        this.isEditDocsRequestActive =!this.isEditDocsRequestActive
      },
      myadddocrequestollapse: function() {
        this.isdocsRequestActive =!this.isdocsRequestActive
      },
      myrequestollapse:function(){
        // this.isRequestActive=!this.isRequestActive
        this.disRequestActive=!this.disRequestActive
      },
      addField(value, fieldType,opt='add') {
        if(opt=='edit') {
          let row = fieldType[fieldType.length-1];
          if(row.businessdocmasterid == undefined) return false
        }            
        this.isActive=false
        fieldType.push({ value: "" });
      },
      removeField(index, fieldType) {
        if(fieldType && fieldType.length>1){
          this.docs.splice(index,1);
          this.attachment.splice(index,1);
        }
      },
      removeEditField(index, fieldType,data) {            
        Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            if(data.businessdocmasterid !== undefined) {
              axios({
                method: "POST",
                url: 'api/workstep/deletebusinesstaskdoc',
                data: {id:data.businessdocmasterid,deleteby:this.undt.username,useremail: this.undt.username,empcode: this.undt.userid},
                'headers':{'authorization':this.tokendata}
              }).then((result) => {
                this.isLoading = false;
                if (result.data.status) {
                  fieldType.splice(index, 1);
                  Swal.fire(
                    'Deleted!',
                    result.data.msg,
                    'success'
                    )
                } else {
                  Swal.fire(
                    'Deleted!',
                    result.data.msg,
                    'error'
                  )
                }
              });
            } else {
              fieldType.splice(index, 1);
            }    
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getEmpallocatedtoProject(node){
        if (this.issue.project &&  (node && node.length > 3)) {
          this.input = { 
            projectid: this.issue.project, 
            useremail: this.undt.username,
            empcode: this.undt.userid, 
            asigneename:node.toLowerCase()          
          }
          if(this.isThisBusinessRole && this.editRowData.typename != 'Vulnerability') {
            this.input.roleid = 484
          }
          if (this.isSupportEngg) {
            this.input.roleid = null
          } 
        if (this.issue.project == this.itHelpDesk && this.editRowData.typename != 'Vulnerability') {
            this.input.roleid= 484
            this.input.flag= 1
            this.input.issueid = this.editRowData.issueid
            axios({
              method: "POST",
              url: 'api/ticket/autoAssignItHelpDeskUser',
              data: this.input,
              'headers':{'authorization':this.tokendata}
            }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                // this.issue.subscriberslist = result.data.data;
                // this.issue.allotedtolist=result.data.data;
                let templistarray =[]
                templistarray = result.data.data;
                let decryptdepartmenttext = templistarray.map(items =>{
                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                  if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                  })
                  this.issue.subscriberslist = templistarray;
                  this.issue.allotedtolist=templistarray;
              } else {
                this.issue.allotedto = this.ifItHelpDeskNotFound;
              }
            });
          }else if (this.issue.project == this.itHelpDesk && this.editRowData.typename == 'Vulnerability'){
            this.input.projectid = this.issue.project,
            this.input.useremail = this.undt.username,
            this.input.empcode = this.undt.userid
            this.input.rolearr =['484','481'];
            axios({
            method: "POST",
            url: "api/listing/getEmpallocatedtoProject",
            data: this.input,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.issue.allotedtolist = result.data.data;
                let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{

                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                  if(tempemplabel2 && tempemplabel2 != null){
                      items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                })
            }
          });
          }
          else{
              axios({
              'method': 'POST',
              'url': 'api/listing/getEmpallocatedtoProject',
              'data': this.input,
              'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                if(result.data.data){
                  let decryptdepartmenttext = result.data.data.map(items =>{
  
                    let tempemplabel1 = items.label;
                    let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
  
                    if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                    }
                    return items;
                  })
                }
                this.issue.allotedtolist=result.data.data;
                this.issue.subscriberslist=result.data.data;
              } else if (node) {
                this.getticketcreatormanager()
              }else{
                  this.issue.allotedtolist = [];
                  this.issue.subscriberslist = [];
              }
            }).catch(e => {
          this.displayError(e)
        });
          }
        }
      },
      getAllocateTo(allotedtoID) {
        this.input = { 
          projectid:this.issue.project ,
          useremail: this.undt.username,
          empcode: this.undt.userid ,
          assignedtoid: allotedtoID,
        }
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpallocatedtoProject',
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        }).then (result => {
          if(result.data.errorCode == 0){
            this.issue.allotedtolist=result.data.data;
            let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{
              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.issue.allotedtolist =[];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      verifyLoggedInUserFortc (pid){
        this.isLoading = true;
        let url = "api/dynamictickets/verifyLoggedInUserFortc";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
          projectid: pid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false; 
          if(Array.isArray(result.data.data.oempdata) && result.data.data.oempdata.length > 0){
            let uniqueArray = Array.from(new Set(result.data.data.oempdata.map(item => item.agentcode)));
            if(uniqueArray.length > 0){
              if(!uniqueArray.includes(Number(this.undt.userid))){
                   this.$router.push({ name: 'TCList', params: { search: true }})
                Swal.fire({
                title: "Failed!",
                text: "You are not authorised user to view this Ticket",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            })
              }
            }
          }   
        }).catch(e => {
          this.displayError(e)
        });
          },
      onFileChange(e) {
        var validFileExtensions = apiUrl.validFileExtensions
        let selectedFiles = e.target.files;
          for (let i=0; i < selectedFiles.length; i++)
          {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];
                if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
              
              if (!blnValid) {
                  Swal.fire({
                      title: "ERROR",
                      text: "Sorry, Invalid Type of Extension File..!!",
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
                  return false;
              }
              this.file.push(selectedFiles[i]);
          }
          if(this.file.length>0) {
              this.issue.disblesavebtn=true
          }
          document.getElementById('attachment').value = ""
      },
      removeImage (index) {
        if(this.file.length>0) {
            this.file.splice(index, 1);
            this.issue.filedescp.splice(index, 1);
            var removeimg = JSON.parse(this.issue.attachmentnew)
            if(removeimg){
            var remove = removeimg.data.splice(index,1)
            if(remove.length > 0)
            this.issue.attachmentnew = JSON.stringify({data:removeimg.data})
            else{
              this.issue.attachmentnew = null
            }
          }         
        }
        if(this.file.length < 1) {
                  this.issue.disblesavebtn = false
              }              
      },
      removes3file(key,data) {
        if(data.length>0) {
          Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to delete this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
            if (result.isConfirmed) {
                if(this.editRowData.issueid !== undefined) {
                    let deletefile = data[key];
                    data.splice(key, 1)
                    const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                    let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                    axios({
                    method: "POST",
                    url: 'api/issue/s3deleteissue',
                    data: {
                        issueid:this.editRowData.issueid,
                        attachment:payload,
                        deletefile: JSON.stringify(deletefile),
                        useremail: this.undt.username,
                        empcode: this.undt.userid, 
                    },
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    if (result.data.status) {
                        //this.issue.attachmentview.data = data.splice(key, 1);
                        //fieldType.splice(index, 1);
                        Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'success'
                        )
                    } else {
                    Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'error'
                        )                        
                    }
                });
                } else {
                    Swal.fire(
                        'IssueId!',
                        'Issueid should not blank',
                        'info'
                        )
                }    
            }
          }).catch(e => {
          this.displayError(e)
        });
        }
      },
      mydetailscollapse() {
        this.isDetActive = true
      },
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },
      savewizard: function(mdl,objThis){           
        this.valid = this.validateForm(mdl,objThis);
        if(this.valid) {
          this.valid = this.validateConfigForm(this.valid);
        }
        if(objThis.logtimeFlag && objThis.logtimeFlag==1){
          this.valid =true;
        }
        if (this.valid) {
            let tblNm='t42'
            if(mdl) tblNm=mdl
            this.input = {
                tbl: tblNm,
                section: 'issue',
                createdby: this.undt.username,
                empid: this.undt.userid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let apiURL='api/issue/create'
            this.input.issuename=this.issue.issuename
            this.input.project=this.issue.project
            this.input.functionality=this.issue.functionality
            this.input.task = this.issue.task
            this.input.type=this.issue.type
            this.input.scenario=this.issue.scenario
            this.input.attachment= this.issue.attachmentnew          
            this.input.sevairty=this.issue.sevairty
            this.input.priority=this.issue.priority
            this.input.subscribers = this.issue.subscribers
            this.input.reasonofissue=this.issue.reasonofissue
            this.input.impactofissue=this.issue.impactofissue
            this.input.status=this.issue.status
            this.input.moduletype='ticket'
            this.input.module_code = this.editRowData.issuecode
            this.input.projectmodulename = this.issue.projectmodulename;
            this.input.applicationname = this.issue.applicationname;
            this.input.applicationmodulename = this.issue.applicationmodulename;
            this.input.sectionmodulename = this.issue.sectionmodulename;
            this.input.issuenameaddnew = this.issue.issuenameaddnew;
            this.input.allotedto = this.issue.allotedto;
            this.input.fieldvalues2= this.fieldsnamevalues2;
            this.input.fieldvalues3 = this.fieldsnamevalues3;
            // if (this.issue.project == this.itHelpDesk) {
            //   this.input.projectmodulename = this.issue.protype
            //   this.input.applicationmodulename = this.issue.subType
            // }
  
            if(this.issue.iseditstate == true){
                if(tblNm == 't42'){
                  this.input.issueid= this.editRowData.issueid
                  this.input.projectname = this.editRowData.projectname
                  this.input.isTimeLogged = this.logtime.isTimeLogged
                  this.input.fieldvalues= this.fieldsnamevalues
                  this.input.trackerid = this.moduletrackerid
                  apiURL='api/issue/update'
                }
                if(this.issue.attachmentnew && this.issue.attachment !== null) {
                  this.input.attachmentnew = this.issue.attachmentnew
                  let jsonobj1 = JSON.parse(this.issue.attachmentnew);
                  let jsonobj2 = this.issue.attachment;
                  let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                  this.input.attachment= JSON.stringify({data: arraymerge});
                  this.view.documentlist = arraymerge
                }
                this.input.lastmodifiedby=this.undt.username
                delete this.input.createdby;
            }
            this.input.useremail= this.undt.username,
            this.input.empcode= this.undt.userid,
            this.isAddState=false
            if(this.issue.ticketresolutiontime){
  
                    let reshour = this.issue.ticketresolutiontime.hours ? this.issue.ticketresolutiontime.hours.toString().split('') : '00'.toString().split('')
                    let resmin = this.issue.ticketresolutiontime.minutes ? this.issue.ticketresolutiontime.minutes.toString().split('') : '00'.toString().split('')
                    let resolution_hours = this.issue.ticketresolutiontime.hours ?  reshour.length < 2 ? '0'+this.issue.ticketresolutiontime.hours: this.issue.ticketresolutiontime.hours : '00'
  
                    let resolution_min = this.issue.ticketresolutiontime.minutes ?  resmin.length < 2 ? '0'+this.issue.ticketresolutiontime.minutes : this.issue.ticketresolutiontime.minutes : '00'
  
                    let ticketresolutiontime =  resolution_hours+':'+resolution_min 
                    this.input.ticketresolutiontime = this.issue.ticketresolutiontime? ticketresolutiontime: null              
              
                  }
                   //ticket responsetime
                   if(this.issue.ticketresponsetime){
                      let resphour = this.issue.ticketresponsetime.hours ? this.issue.ticketresponsetime.hours.toString().split('') : '00'.toString().split('')
                      let respmin = this.issue.ticketresponsetime.minutes ? this.issue.ticketresponsetime.minutes.toString().split('') : '00'.toString().split('')
                      let response_hours = this.issue.ticketresponsetime.hours ?  resphour.length < 2 ? '0'+this.issue.ticketresponsetime.hours: this.issue.ticketresponsetime.hours : '00'
                      let response_min = this.issue.ticketresponsetime.minutes ?  respmin.length < 2 ? '0'+this.issue.ticketresponsetime.minutes : this.issue.ticketresponsetime.minutes : '00'
                      let ticketresponsetime =  response_hours+':'+response_min 
                      this.input.ticketresponsetime = this.issue.ticketresponsetime? ticketresponsetime: null
                  }
              if(this.issue.candidatestatus && this.issue.candidatestatus !=null){
                this.input.candidatestatus = this.issue.candidatestatus;
                this.input.candidateid = this.editRowData.candidateid;
                this.input.interviewroundno = this.editRowData.interviewroundno;
              }
            axios({
              'method': 'POST',
              'url': apiURL,
              'data': this.input,
              'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
              if(result.data.errorCode == 0){
                this.isLoading=false;
                $('html, body').animate({ scrollTop: 0 }, 1200);
                this.file =[]
                this.issue.filedescp =[]
                this.logtime.isTimeLogged = false
                Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.handleCancel()
                this.disRequestActive=true
                this.getIssueById(this.editRowData.issueid)
              }else if(result.data.errorCode == 3){
                  Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                      window.location.href = "/#/login";
                  })
                } else {
                  Swal.fire({
                      title: "Failed",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
              }
            }).catch(e => {
          this.displayError(e)
        });
        }
      },
      handleCancel() {
        this.issue.attachmentnew = null
        this.issue.attachment = null
        this.issue.attachmentview = null
        this.logtime.approver = ''
        this.logtime.approvalrequired = false
        this.myrequestollapse()
        $('html, body').animate({ scrollTop: 0 }, 1200); 
      },
      handleCancel2() {
        this.dislogActive=true
        this.isLogActive=false
        $('html, body').animate({ scrollTop: 0 }, 1200);
      },
      handleLogCancel() {
        this.dislogActive=true
        this.isLogActive=false
        this.logtime.approvalrequired =false
        this.logtime.comment = ''
        this.logtime.approver = ''
        $('html, body').animate({ scrollTop: 0 }, 1200);
      },
      dateFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
      dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
      },
      getApplicationdata: function(mdl,curracttab){            
        this.pageoffset=1
        this.inputappl = {
          tbl:mdl,
          offset:this.pageoffset,
          limit:apiUrl.LIMIT,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        let apiURL='api/master/commonmaster/list'
        if(mdl == 't11'){
          apiURL='api/listing/getprojectlist'
          this.inputappl = {
              tbl:mdl
          }
        }else if(mdl == 't37' && curracttab == 'issue'){
          this.inputappl = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          apiURL = "api/listing/getfunctionalitybyprojectid";
          this.inputappl.projectid = this.issue.project
        }else if(mdl == 't37' && curracttab == 'issueSearch'){
          this.inputappl = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          apiURL = "api/listing/getfunctionalitybyprojectid";
          this.inputappl.projectid = this.search.searchprojectname
        }else if(mdl == 't43'){
          apiURL='api/master/commonmaster/customselect'
          /* this.inputappl.projectmoduleid=this.issue.project */
          this.inputappl.isSearch= false
          this.inputappl.section= "issue"
          this.inputappl.isFilterSelect= 1
          this.inputappl.isFilterPayload= {}
        }else if(mdl == 't44'){
          apiURL='api/master/commonmaster/customselect';
          this.inputappl.isSearch= false
          this.inputappl.section= "issue"
          this.inputappl.isFilterSelect= 1
          this.inputappl.isFilterPayload= {}
        }else if(mdl == 't26'){
          apiURL='api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: "t26"
          }
        } else if (mdl == 't18' && curracttab == 'issue') {
          apiURL = 'api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: mdl,
            useremail: this.undt.username,
            functionalityid: this.issue.functionality
          }
        } else if (mdl == 't18' && curracttab == 'issueSearch') {
          apiURL = 'api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: mdl,
            useremail: this.undt.username,
            functionalityid: this.search.functionality
          }
        }
        this.inputappl.empcode = this.undt.userid,
        this.inputappl.useremail= this.undt.username
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {             
          if(result.data.errorCode == 0){
            if(mdl == 't11'){
              if(curracttab == 'issue'){
                return true
              }
            }else if(mdl == 't37' && curracttab == 'issue'){
              this.issue.functionalitylist=result.data.data
              this.issue.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
              return true
            }else if(mdl == 't37' && curracttab == 'issueSearch'){
              this.search.functionalitylist=result.data.data
              this.search.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
              return true
            }else if(mdl == 't43'){
              if(curracttab == 'issue'){
                this.issue.typelist=result.data.data.rows
                return true
              }
            }else if(mdl == 't44'){
              if(curracttab == 'issue'){
                this.issue.sevairtylist=result.data.data.rows
                return true
              }
            }else if(mdl == 't26'){
              if(curracttab == 'issue'){
                return true
              }
            } else if(mdl == 't18' && curracttab == 'issue') {
              this.issue.tasklist = result.data.data
              return true
            } else if(mdl == 't18' && curracttab == 'issueSearch') {
              this.search.tasklist = result.data.data
              return true
            }
          } else {
            if(mdl == 't37' && curracttab == 'issue'){
              this.issue.functionalitylist=[]
              return true
            } else if(mdl == 't18' && curracttab == 'issue'){
              this.issue.tasklist = []
              return true
            }
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      validText : function(inputval) {
        var re = /[A-Za-z0-9].{5,}/;
        if(inputval){
          return re.test(inputval.toLowerCase());
        }
      },
      validText2 : function(inputval) {
              var re = /[A-Za-z0-9].{1,}/;
              if(inputval){
                  return re.test(inputval.toLowerCase());
              }
          },
      validateForm: function(mdl,objThis){
        this.isAddState=false
        this.commentBlured=true;
        this.approverBlured=true;
        var isValid=true;
        if(mdl=="t42"){
          if(!this.issue.issuename){
            this.errors.issue.issuename="Ticket name must be at least 5 characters long";
            isValid= false;
          }
  
          if(this.issue.project == this.merchandisingProId && this.issue.sectionmodulename != null && this.issue.sectionmodulename !=''){
            if(!this.issue.issuenameaddnew){
              this.errors.issue.issuenameaddnew= this.productlabelname + " is required";
              isValid= false;
            }else{
              this.errors.issue.issuenameaddnew="";
            }
          }
          
          if(!this.issue.project){
            this.errors.issue.project="Project required";
            isValid= false;
          } else {
            this.errors.issue.project = null;
          }
          if(!this.issue.type){
              this.errors.issue.type="Ticket type required";
              isValid= false;
          }
          if(!this.issue.scenario){
            this.errors.issue.scenario="Ticket scenario required";
            isValid= false;
          } else {
            this.errors.issue.scenario="";
          }
          if(!this.issue.allotedto){
            this.errors.issue.allotedto="Assigned to required";
            isValid= false;
          } else {
            this.errors.issue.allotedto = "";
          }
          if (this.companycode=='xb') {
            this.errors.issue.allotedto = " ";
            isValid = true;
          }
          if(!this.issue.sevairty){
            this.errors.issue.sevairty="Ticket severity required";
            isValid= false;
          }
          if (this.companycode=='xb') {
            this.errors.issue.sevairty = " ";
            isValid = true;
          }
          if(!this.issue.priority){
            this.errors.issue.priority="Ticket priority required";
            isValid= false;
          }
          if (this.companycode=='xb') {
            this.errors.issue.priority = " ";
            isValid = true;
          }
          if(!this.issue.status){
            this.errors.issue.status="Ticket status required";
            isValid= false;
          }
          if((!this.issue.subscribers) || (this.issue.subscribers && this.issue.subscribers.length < 1)) {
              this.errors.issue.subscribers = 'Subscribers are required'
              isValid = false
          } else {
              this.errors.issue.subscribers = ''
          }
          if (this.companycode=='xb') {
            this.errors.issue.subscribers = '';
            isValid = true;
          }
        }else if(mdl=="doc"){
          for(let index in this.docs){
            if(!this.validText(this.docs[index].title) || !this.validText(this.docs[index].comment) || !this.validText(this.docs[index].desc)){
              isValid=false
            }
          }
        }
        return isValid
      },
      checkIsBusinessRole: function(pid) {
            this.isLoading = true
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid: pid,
                roleid: this.BRoleid
            }
            let apiUrl = 'api/ticket/getsupportengg'
            axios({
                method: 'POST',
                url: apiUrl,
                data: this.input,
                headers: { authorization: this.tokendata }
            }).then(result => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.isInProjectAsBR = true;
                    if(this.isInProjectAsBR == true){
                        this.issue.type = this.SupportEnquiry;
                        this.getrolenamewithid(pid);
                    }
                } else {
                this.isInProjectAsBR = false;
                }
            }).catch(e => {
                this.displayError(e)
            });
      },
      btnVisibilityTR: function(projectid) {
      let url = "api/ticket/getProjWiseSpecificRolewithCount";
          this.input = {
              useremail: this.undt.username,
              empcode: this.undt.userid,
              projectid:projectid,
              roleidarr: [this.TMroleid, this.TOroleid],
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  let tmroless = result.data.data.rows.filter(p => (p.roleid == this.TMroleid));
                  let toroless = result.data.data.rows.filter(p => (p.roleid == this.TOroleid));
                  this.btcissuebtnaccessarry = [];

                  //task manager
                  if(tmroless && tmroless.length>0)
                  {
                      tmroless.forEach(element => {
                          this.btcissuebtnaccessarry.push(element.empid)
                      });
                  }
                  
                  //Task owner
                  if(toroless && toroless.length>0)
                  {
                      toroless.forEach(element => {
                        this.btcissuebtnaccessarry.push(element.empid)
                      });
                  }
              } else {
                this.btcissuebtnaccessarry = [];
          }
      }).catch(e => {
          this.displayError(e)
      });
      },
      getrolenamewithid : function(projectid) {
          this.isLoading = true
          let url = "api/ticket/getProjWiseSpecificRolewithCount";
          this.input = {
              useremail: this.undt.username,
              empcode: this.undt.userid,
              projectid:projectid,
              roleidarr: [this.TMroleid, this.POroleid],
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  if(this.isInProjectAsBR == true && this.issue.type == this.SupportEnquiry){
                      let tmroless = result.data.data.rows.filter(p => (p.roleid == this.TMroleid));
                      let prroless = result.data.data.rows.filter(p => (p.roleid == this.POroleid));
                      this.issue.subscribers = [];
                      this.issue.allotedtolist = [];
                      this.issuecreateSub = [];
                      this.issuecreateSubArry = [];

                      //task manager
                      if(tmroless && tmroless.length==1)
                      {
                          tmroless.forEach(element => {
                              let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                              //for subscribers
                              const found = this.issue.subscriberslist.some(el => el.id == element.empid);
                              if(!found) {
                                  this.issue.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                                }
                                this.issuecreateSubArry.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                              const founds = this.issue.subscribers.some(el => el == element.empid);
                              if(!founds) {
                                  this.issue.subscribers.push(element.empid);
                              }
                              //for alloted to
                              this.issue.allotedtolist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                              this.issue.allotedto = element.empid;
                              this.issuecreateSub.push(element.empid)
                          });
                      }
                      //product owner
                      if(prroless && prroless.length>0)
                      {
                          prroless.forEach(element => {
                              let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                              const found = this.issue.subscriberslist.some(el => el.id == element.empid);
                              if(!found) {
                                  this.issue.subscriberslist.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                                }
                                this.issuecreateSubArry.push({"id":element.empid,"label": element.empname +'('+tempemplabel2 +')' });
                              const founds = this.issue.subscribers.some(el => el == element.empid);
                              if(!founds) {
                                  this.issue.subscribers.push(element.empid);
                              }
                              this.issuecreateSub.push(element.empid)
                          });
                      }

                      //creator
                      this.getemployeedetails(this.undt.userid);
                  }
              } else {
          }
      }).catch(e => {
          this.displayError(e)
      });
      },
      getemployeedetails: function(empid){
          this.isLoading = true;
          this.inputfield= {
              empcode: parseInt(this.undt.userid),
              useremail: this.undt.username,
              empid: empid,
              moduletype: 'Appraisal Cycle'
          }
          axios({
              'method': 'POST',
              'url': 'api/feedbackform/getemployeedetails',
              'data': this.inputfield,
              'headers':{'authorization':this.tokendata}
          })
          .then(result => {
              this.isLoading = false;
              if(result.data.status == true){
              if(result.data.data.rows.length>0) {
                  result.data.data.rows.forEach(element => {
                  let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                  const found = this.issue.subscriberslist.some(el => el.id == element.empcode);
                      if(!found) {
                          this.issue.subscriberslist.push({"id":element.empcode,"label": element.empname +'('+tempemplabel2 +')' });
                      }
                      const founds = this.issue.subscribers.some(el => el == element.empcode);
                      if(!founds) {
                          this.issue.subscribers.push(element.empcode);
                      }
                  });
              }
              }else{
              this.employeedetails=[]
              }
              }).catch(e => {
                      this.displayError(e)
              });
      },
      getTasktypeConfigLists(projectid) {
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: parseInt(projectid.toString()),
            trackername: 'Ticket Request Type',
        };
        axios({
            method: "POST",
            url: '/api/businesstask/getTasktypeConfigList',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            if(result.data.errorCode == 0){
                let slist = [];
                result.data.data.rows.forEach((rec) => {
                    if(Object.values(rec.statusconfig).length>0){
                        Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                            if(statusnames.length>0) {
                                statusnames.forEach(val => {
                                    if(!slist.some(sdata => sdata.id === val)){
                                        slist.push({ id: val, label: val });
                                    }
                                });
                            }
                        });
                    }
                })
                if(this.issue.project != null && this.issue.project == apiUrl.merchandisingProId){
                    this.sortTypeList(slist, 'merchandising')
                } else{
                    this.sortTypeList(slist, 'otherprojects')
                }
            } else {
                this.issue.mrtypelist = [];
            }
        }).catch(e => {
          this.displayError(e)
        });
      },
      sortTypeList(typelist, type){
        let numArrFromTypelist = [];
        typelist.forEach(element => {
          if(element.id != null) {
            numArrFromTypelist.push(element.id);
          }
        });
        if(numArrFromTypelist.length>0) {
          this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            moduletype: 'ticket',
            typearr: numArrFromTypelist,
            projecttype: type
          };
          axios({
            method: "POST",
            url: 'api/ticket/sortTypeList',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.issue.mrtypelist = result.data.data.rows;
            } else {
              this.issue.mrtypelist = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
        }
      },
      tickettypeformrp(state){
        this.getTasktypeConfigLists(state);
      },
      selSectionModule(state, value) {
          this.issue.sectionmodulename = null;
          this.issue.sectionmodulename = state;
          if (!state) {
              this.errors.issue.sectionmodulename = "Product ID/ Product Group ID is required";
          } else {
              if(state == this.sectionmoduleId1) {
                  this.productlabelname = 'Product ID';
              } else if(state == this.sectionmoduleId2) {
                  this.productlabelname = 'Product Group ID';
              }
              this.errors.issue.sectionmodulename = null;
          }
          },
          getSectionModules(projectid) {
          this.input = {
              projectid: projectid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
          };
          axios({
              method: "POST",
              url: 'api/listing/getsectionbyprojectid',
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
              this.issue.sectionmodulelist = result.data.data;
              } else {
              this.issue.sectionmodulelist = [];
              }
          }).catch(e => {
          this.displayError(e)
        });
          },
          getAppModulesbyprojectid(projectid) {
            this.input = {
              projectid: projectid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
              isfrompage: 'updatepage'
            };
            axios({
              method: "POST",
              url: 'api/listing/getappmodulenamebyprojectid',
              data: this.input,
              'headers':{'authorization':this.tokendata}
            }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                this.issue.applicationmodulelist = result.data.data;
                if(this.issue.applicationmodulelist && this.issue.applicationmodulelist.length > 0 && (this.issue.applicationmodulename && this.issue.applicationmodulename !=null)){
                  const checkexistproject = this.issue.applicationmodulelist.some(item =>item.id === this.issue.applicationmodulename);
                  if(!checkexistproject){
                    this.getAppModulesbyappmoduleid(this.issue.applicationmodulename);
                  }
                }else if(this.issue.applicationmodulelist.length ==0 && (this.issue.applicationmodulename && this.issue.applicationmodulename !=null)){
                    this.getAppModulesbyappmoduleid(this.issue.applicationmodulename);
                }
                this.issue.applicationmodulelist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
              } else {
                this.issue.applicationmodulelist = [];
              }
            }).catch(e => {
              this.displayError(e)
            });
          },
          getAppModulesbyappmoduleid(appmid) {
            this.input = {
              appmoduleid: appmid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
            };
            axios({
              method: "POST",
              url: 'api/listing/getappmodulenamebyprojectid',
              data: this.input,
              'headers':{'authorization':this.tokendata}
            }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                if(result.data.data && result.data.data.length > 0){
                  this.issue.applicationmodulelist.push(result.data.data[0]);
                }
              }
            }).catch(e => {
              this.displayError(e)
            });
          },
          getprojectmodules(projecteid, type) {
              let url = '';
              if(type=='pmodules') {
                url = 'api/listing/getpromodulebyapplicationid';
              } else if(type=='app') {
                url = "api/listing/getappnamebyprojecteid";
              }
              this.input = {
                projecteid: projecteid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
              };
              if(type=='pmodules'){
                this.input.isfrompage = 'updatepage'
              }
              axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
              }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                  if(type=='pmodules') {
                    this.issue.projectmodulelist = result.data.data;
                    if(this.issue.projectmodulelist && this.issue.projectmodulelist.length > 0 && (this.issue.projectmodulename && this.issue.projectmodulename !=null)){
                      const checkexistproject = this.issue.projectmodulelist.some(item =>item.id === this.issue.projectmodulename);
                      if(!checkexistproject){
                        this.getprojectmodulesbyid(this.issue.projectmodulename);
                      }
                    }else if(this.issue.projectmodulelist.length ==0 && (this.issue.projectmodulename && this.issue.projectmodulename !=null)){
                      this.getprojectmodulesbyid(this.issue.projectmodulename);
                    }
                    this.issue.projectmodulelist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                  } 
                  if(type=='app') {
                    this.issue.applicationlist = result.data.data;
                    this.issue.applicationlist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                  }
                } else {
                  this.issue.applicationmodulelist = [];
                  this.issue.applicationlist = [];
                }
            }).catch(e => {
              this.displayError(e)
            });
          },
          getprojectmodulesbyid(moduleid) {
            let url = 'api/listing/getpromodulebyapplicationid';
            this.input = {
              projectmoduleid: moduleid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
            }
            axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
            }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                if(this.issue.projectmodulelist && this.issue.projectmodulelist.length > 0){
                  const checkexistprojectmodule = this.issue.projectmodulelist.some(item =>item.id === moduleid);
                  if(!checkexistprojectmodule){
                    this.issue.projectmodulelist.push(result.data.data[0]);
                  }
                }else if(this.issue.projectmodulelist.length ==0){
                  this.issue.projectmodulelist.push(result.data.data[0]);
                }
              }
            }).catch(e => {
              this.displayError(e)
            });
          },
      getStatusConfigList(pid, status) {
          let payload = {
              empcode: this.undt.userid,
              useremail: this.undt.username,
              projectid: pid,
              trackername: 'dynamic_tickets',
              currStatus: status,
          };
          axios({
              method: "POST",
              url: '/api/testcase/getStatusConfigList',
              data: payload,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
              if(result.data.errorCode == 0){
                  this.issue.statuslist = result.data.data.rows;
              } else {
                  this.issue.statuslist = [];
              }
          }).catch(e => {
          this.displayError(e)
        });
      },
      selissuecsfunctionality: function(state,value){
          this.issue.functionality=null
          this.issue.functionality=state
  
          if(!state){
              this.issue.task = null
              this.issue.tasklist = null
          }else{                
              this.getApplicationdata('t18', 'issue')
          }
      },
      selissuecstype: function(state,value){
          this.issue.type=null
          this.issue.type=state
          if(!state && this.isAddState == false){
              this.errors.issue.type='Ticket type required'
          }else{                
              this.errors.issue.type=null
          }
      },
      selissuecssevairty: function(state,value){
          this.issue.sevairty=null
          this.issue.sevairty=state
          if(!state && this.isAddState == false && this.companycode != 'xb'){
              this.errors.issue.sevairty='Ticket severity required'
          }else{                
              this.errors.issue.sevairty=null
          }
      },
      selissuecspriority: function(state,value){
          this.issue.priority=null
          this.issue.priority=state
          if(!state && this.isAddState == false && this.companycode != 'xb'){
              this.errors.issue.priority='Ticket priority required'
          }else{                
              this.errors.issue.priority=null
          }
      },
      selissuecsstatus: function(state,value){
          this.issue.status=null
          this.issue.status=state
          if(!state && this.isAddState == false){
              this.errors.issue.status='Ticket status required'
          }else{     
              this.errors.status = null               
              this.errors.issue.status=null
          }
          if((this.editRowData.status && this.editRowData.status !='closed') && this.editRowData.issuetype ==this.interviewtype && (state =='closed' || state =='Closed')){
            this.interviewconfigurationForm =true;
            this.getorientationfeedbackformlabels2(this.interviewtrackerid);
          }
          if(this.dynamicProject && this.dynamicProject.length>0) {
              this.getprojectmaprolide(this.loginuscondition,this.moduletrackerid,this.dynamicProject);
              this.updateValidationRulesForFields();
          }
      },
      selallotedto: function(state,value){
          this.issue.allotedto=null
          this.issue.allotedto=state
          if(!state && this.isAddState == false && this.companycode != 'xb'){
              this.errors.issue.allotedto='Assigned To required'
          }else{ 
              this.errors.allotedto= null              
              this.errors.issue.allotedto=null
          }
      },
      selProType(state) {
        this.issue.projectmodulename = null;
        this.issue.projectmodulename = state
        if (!state) {
          this.errors.issue.projectmodulename = 'Ticket Category is required'
        } else {
          this.getSLAPriorityDetails()
          this.errors.issue.projectmodulename = null
        }
      },
      selSubType(state) {
        this.issue.applicationmodulename = null;
        this.issue.applicationmodulename = state
        
        if (!state) {
          this.errors.issue.applicationmodulename = 'Ticket Sub Category is required'
        } else {
          this.getSLAPriorityDetails()
          this.errors.issue.applicationmodulename = null
        }
      },
      selissuelogactivity(state, value) {
        this.logtime.activity = null
        this.logtime.activity = state
        if(!state) {
            // this.errors.activity = "Task Activity is required"
        } else {
            this.errors.activity = null
        }
      },
      redirecttoPNM:function(btnstate,rowdata){
              rowdata.backbtnstate=btnstate
              this.$router.push({ 
  
                  path: '/project/summary?pid='+rowdata.moduleid,
                  params: {
                      backbtnstate:btnstate,
                      clickeddata:rowdata
                  }
              })
          },
          
      getProjectAllocatedToEmp() {
        this.isLoading = true
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers': { 'authorization': this.tokendata }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.issue.projectlist = result.data.data;
          } else {
            this.issue.projectlist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      onSelect(event){
        this.file = e.target.files[0];          
      },
      onUpload(){
        if(this.file){
          this.isLoading=true
          if(this.file.length !== 0){
              let arrGlbMdl=this.glbMdl.split('##')
              let inputEle='';
              let btnEle='';
              if(arrGlbMdl.length > 0){
                  if(typeof arrGlbMdl[0] != 'undefined'){
                      inputEle=arrGlbMdl[0];
                  }
                  if(typeof arrGlbMdl[1] != 'undefined'){
                      btnEle=arrGlbMdl[1];
                  }
              }
              if(inputEle == 't42'){
                  btnEle='issuemgt'
              }
              this.apiURL="api/master/fileupload/uploadBulkFileToS3"
              let formData = new FormData();
              let maxsize = 0;
              for (let index = 0; index < this.file.length; index++) {
                  formData.append("imagefile", this.file[index]);
                  maxsize+= this.file[index].size
              }
              formData.append("empcode", this.undt.userid);
              formData.append("useremail", this.undt.username);
              formData.append("moduletype", 'issuemgt');
              formData.append("fcount", parseInt(this.file.length));
              
              if (maxsize > 1024 * 1024 * 25) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 25mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.issue.disblesavebtn=true 
                return false; 
              }
              axios({
                  'content-type':'multipart/form-data',
                  'method': 'POST',
                  'url': this.apiURL,
                  'data':formData,
                  'headers':{'authorization':this.tokendata}
              })
              .then(result => { 
                  this.isLoading=false;            
                  if(result.data.status){
                    this.issue.disblesavebtn=false
                    // const bindinfo = [{"link":result.data.data.filename,"info":result.data.data.filename}]
                    const bindinfo = result.data.data.map((file, index) => {
                      return {"link":file,"info":this.issue.filedescp[index]}                
                    });
                    let jsonobj = {data:bindinfo}
                    this.issue.attachmentnew = JSON.stringify(jsonobj);
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.issue.attachment=null
                }
            }).catch(e => {
          this.displayError(e)
        });
          }else{
            this.isLoading=false; 
            Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
            this.issue.attachment=null
          }
        } else {
            this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Select image/docs/videos',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.issue.attachment=null
        }
      },
      onZoom(e){
        this.getLogtimeDownloadLink(e, 'zoom');
      },
          getBrandWiseUserDetails(brandid, projectid) {
        this.isLoading = true;
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          brandid: parseInt(brandid),
          projectid: parseInt(projectid),
        }
        let apiURL = 'api/thirdparty/getBrandWiseUserDetails'
  
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          headers: { authorization: this.tokendata },   
        }).then((result) => {
          this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.brandAllocateEmp = result.data.data
            } else {
              this.brandAllocateEmp = [];
            }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getSLAPriorityDetails() {
        this.isLoading = true;
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          maincategoryid: this.issue.projectmodulename? this.issue.projectmodulename : null,
          subcategoryid: this.issue.applicationmodulename ? this.issue.applicationmodulename : null,
          project: this.issue.project ? this.issue.project : null,
        }
        let apiURL = 'api/slaconfigure/geSLAPriority'
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          headers: { authorization: this.tokendata },   
        }).then((result) => {
          this.isLoading = false;
          if(this.issue.projectmodulename !=this.issue.lastprojectmodulename || this.issue.applicationmodulename != this.issue.lastapplicationmodulename || this.issue.project !=this.issue.lastproject){
            if (result.data.errorCode == 0) {
               let d= result.data.data.rows
               if(this.issue.iseditstate == true){
              this.issue.priority = d[0].issueclassification
              this.issue.ticketresolutiontime = d[0].resolutiontime
              this.issue.ticketresponsetime = d[0].responsetime
  
               }
            } else {
              if(this.issue.iseditstate == true){
               this.issue.priority = null
               this.issue.ticketresolutiontime = null
               this.issue.ticketresponsetime = null
  
              }
             // this.brandAllocateEmp = [];
            }
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getConfigurationFormDetails(ticketid,projectid) {
          this.isLoading = true;
          let url = "api/dynamictickets/getConfigurationFormDetails"
          this.input = {
              useremail: this.undt.username,
              empcode: this.undt.userid,
              ticketid: ticketid,
              projectid : projectid,
              trackerid: this.moduletrackerid
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.queanslist = result.data.data[0]
              }else{
                  this.queanslist = [];
              }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getConfigurationFormDetails2(ticketid,pid) {
        this.isLoading = true;
        let url = "api/dynamictickets/getConfigurationFormDetails"
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          ticketid: ticketid,
          projectid : pid,
          trackerid: this.interviewtrackerid
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.queanslist2 = result.data.data[0];
          }else{
            this.queanslist2 = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
  
      getorientationfeedbackformlabels(trackerid) {
          this.isLoading = true
          let url = 'api/feedbackform/getorientationfeedbackformlabels'
          let payload = new Object({
              empcode: this.undt.userid,
              useremail  : this.undt.username,
              moduletracker: trackerid
          })
          axios({
              method: 'POST',
              url,
              data: payload,
              headers:{ authorization :this.tokendata }
          }).then(result => {
                  this.isLoading = false
                  if(result.data.errorCode == 0) {
                      this.fieldnamelist = result.data.data
                      var temArray = {};
                      for (let i = 0; i < this.fieldnamelist.length; i++) {
                          if ((this.fieldnamelist[i].fieldheader ==null || this.fieldnamelist[i].fieldheader =='') && this.fieldnamelist[i].fieldtype !='hidden') {
                              this.tempcheck.push(i)
                          }
  
                          // header logic start
                          if (this.fieldnamelist[i].fieldheader!=null) {
                              if(!temArray.hasOwnProperty(this.fieldnamelist[i].fieldheader)){
                                  temArray[this.fieldnamelist[i].fieldheader] = [];
                              }
                              var pushObj = this.fieldnamelist[i];
                                  temArray[this.fieldnamelist[i].fieldheader].push(pushObj);
                          }
                          // header logic end                  
  
  
                          if (this.fieldnamelist[i].fieldtype=="text") {
                              let tempdata = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata[j] = {}
                                      tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue']=tempdata
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="checkbox") {
                              let tempdata = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata[j] = {}
                                      tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue']=tempdata
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="radio") {
                              let tempdata2 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata2[j] = {}
                                      tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="drop-down") {
                              let tempdata3 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata3[j] = {}
                                      tempdata3[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata3[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalu1']=tempdata3
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="multiselect-dropdown") {
                              let tempdata4 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata4[j] = {}
                                      tempdata4[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata4[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue2']=tempdata4
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="toggle") {
                              let tempdata5 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata5[j] = {}
                                      tempdata5[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata5[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue3']=tempdata5
                              }
                          }
                          
                      }
                      this.tempheader=temArray;
                  } else {
                      this.fieldnamelist = [];
                  }
              }).catch(e => {
          this.displayError(e)
        });
      },
      getorientationfeedbackformlabels2(trackerid) {
        this.isLoading = true;
        let url = 'api/feedbackform/getorientationfeedbackformlabels'
        let payload = new Object({
          empcode: this.undt.userid,
          useremail  : this.undt.username,
          moduletracker: trackerid
        })
        axios({
          method: 'POST',
          url,
          data: payload,
          headers:{ authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false;
          if(result.data.errorCode == 0) {
            this.fieldnamelist2 = result.data.data
            let temArray = {};
            for (let i = 0; i < this.fieldnamelist2.length; i++) {
              if ((this.fieldnamelist2[i].fieldheader ==null || this.fieldnamelist2[i].fieldheader =='') && this.fieldnamelist2[i].fieldtype !='hidden') {
                  this.tempcheck2.push(i)
              }

              // header logic start
              if (this.fieldnamelist2[i].fieldheader!=null) {
                if(!temArray.hasOwnProperty(this.fieldnamelist2[i].fieldheader)){
                  temArray[this.fieldnamelist2[i].fieldheader] = [];
                }
                var pushObj = this.fieldnamelist2[i];
                temArray[this.fieldnamelist2[i].fieldheader].push(pushObj);
              }
              // header logic end                  

              if (this.fieldnamelist2[i].fieldtype=="text") {
                  let tempdata = [];
                  for (let j = 0; j < this.fieldnamelist2[i].fieldvalues.length; j++) {
                    if (this.fieldnamelist2[i].fieldvalues[j].optionvalue.length>0) {
                      tempdata[j] = {}
                      tempdata[j].id=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                      tempdata[j].label=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                    }
                    this.fieldnamelist2[i]['optionalvalue']=tempdata
                  }
              }
              if (this.fieldnamelist2[i].fieldtype=="checkbox") {
                  let tempdata = [];
                  for (let j = 0; j < this.fieldnamelist2[i].fieldvalues.length; j++) {
                    if (this.fieldnamelist2[i].fieldvalues[j].optionvalue.length>0) {
                      tempdata[j] = {}
                      tempdata[j].id=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                      tempdata[j].label=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                    }
                    this.fieldnamelist2[i]['optionalvalue']=tempdata
                  }
              }
              if (this.fieldnamelist2[i].fieldtype=="radio") {
                  let tempdata2 = [];
                  for (let j = 0; j < this.fieldnamelist2[i].fieldvalues.length; j++) {
                    if (this.fieldnamelist2[i].fieldvalues[j].optionvalue.length>0) {
                      tempdata2[j] = {}
                      tempdata2[j].id=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                      tempdata2[j].label=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                    }
                    this.fieldnamelist2[i]['radiooptionalvalue']=tempdata2
                  }
              }
              if (this.fieldnamelist2[i].fieldtype=="drop-down") {
                  let tempdata3 = [];
                  for (let j = 0; j < this.fieldnamelist2[i].fieldvalues.length; j++) {
                  if (this.fieldnamelist2[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata3[j] = {}
                    tempdata3[j].id=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                    tempdata3[j].label=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                  }
                  this.fieldnamelist2[i]['optionalvalu1']=tempdata3
                }
              }
              if (this.fieldnamelist2[i].fieldtype=="multiselect-dropdown") {
                  let tempdata4 = [];
                  for (let j = 0; j < this.fieldnamelist2[i].fieldvalues.length; j++) {
                    if (this.fieldnamelist2[i].fieldvalues[j].optionvalue.length>0) {
                      tempdata4[j] = {}
                      tempdata4[j].id=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                      tempdata4[j].label=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                    }
                    this.fieldnamelist2[i]['optionalvalue2']=tempdata4
                  }
              }
              if (this.fieldnamelist2[i].fieldtype=="toggle") {
                let tempdata5 = [];
                for (let j = 0; j < this.fieldnamelist2[i].fieldvalues.length; j++) {
                  if (this.fieldnamelist2[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata5[j] = {}
                    tempdata5[j].id=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                    tempdata5[j].label=this.fieldnamelist2[i].fieldvalues[j].optionvalue
                  }
                  this.fieldnamelist2[i]['optionalvalue3']=tempdata5
                }
              }
            }
            this.tempheader=temArray;
            this.getticketconfigdata2(this.view.projectid);
          } else {
            this.fieldnamelist2 = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      changevalue2(id,state){
        this.isLoading =true;
        this.fieldsnamevalues2[id]= null;
        this.fieldsnamevalues2[id] = state;
        this.isLoading =false;
      },
      interviewvalidateForm(){
        this.isAddState=false
        let isValid=true;
        if(!this.issue.candidatestatus && ((this.issue.status == 'closed' || this.issue.status == 'Closed') && this.issue.type == this.interviewtype && this.issue.project == this.recruitmentprojectid && this.interviewconfigurationForm)){
            this.errors.issue.candidatestatus="Candidate status required";
            isValid= false;
        }
        for (let i=0; i<this.fieldnamelist2.length; i++) {

            //Timepicker HH:mm value assignment start
            if (this.fieldnamelist2[i].fieldtype=='datetime-local') {

                if (typeof this.fieldsnamevalues2[this.fieldnamelist2[i].id] == undefined || typeof (this.fieldsnamevalues2[this.fieldnamelist2[i].id]) == 'undefined') {
                    isValid=false;
                }else{
                    let hours = this.fieldsnamevalues2[this.fieldnamelist2[i].id]
                    let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                    if (this.fieldsnamevalues2[this.fieldnamelist2[i].id][1]=='H' || this.fieldsnamevalues2[this.fieldnamelist2[i].id][4]=='m') {
                        isValid=false;
                        Swal.fire({
                            title: "Failed",
                            text: this.fieldnamelist2[i].label+' '+'required in HH:mm format value',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                    if(typeof hours === 'string' && hours.length === 5){
                        isValidHours = true
                    }
                    if(isValidHours && hours !== null && typeof hours === 'object') {
                        this.fieldsnamevalues2[this.fieldnamelist2[i].id] = `${hours.HH}:${hours.mm}`
                    }
                }

            }
            //Timepicker HH:mm value assignment end

            if (this.fieldnamelist2[i].fieldtype != 'toggle') {
                
                if (this.fieldnamelist2[i].validationtype=='required') {

                    if (this.fieldsnamevalues2[this.fieldnamelist2[i].id] == '' || this.fieldsnamevalues2[this.fieldnamelist2[i].id] == null || this.fieldsnamevalues2[this.fieldnamelist2[i].id] == []) {
                        isValid=false;
                        if(isValid==false){
                            Swal.fire({
                                title: "Failed",
                                text: this.fieldnamelist2[i].label+' '+'is required',
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            return
                        }
                    }
                    if (this.fieldnamelist2[i].fieldtype=='datetime-local' && (this.fieldsnamevalues2[this.fieldnamelist2[i].id] == '' || this.fieldsnamevalues2[this.fieldnamelist2[i].id] == null || this.fieldsnamevalues2[this.fieldnamelist2[i].id] == '[object Object]')) {
                        isValid=false;
                        Swal.fire({
                            title: "Failed",
                            text: this.fieldnamelist2[i].label+' '+'is required',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                    if (this.fieldnamelist2[i].fieldtype=='datetime-local' && (this.fieldsnamevalues2[this.fieldnamelist2[i].id][1]=='H' || this.fieldsnamevalues2[this.fieldnamelist2[i].id][4]=='m')) {
                        isValid=false;
                        Swal.fire({
                            title: "Failed",
                            text: this.fieldnamelist2[i].label+' '+'required in HH:mm format value',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                    if (typeof (this.fieldsnamevalues2[this.fieldnamelist2[i].id]) == undefined || typeof (this.fieldsnamevalues2[this.fieldnamelist2[i].id]) == 'undefined') {
                        isValid=false;
                    } 
                    else{
                        if(this.fieldsnamevalues2[this.fieldnamelist2[i].id].length < 0) {
                            isValid=false;
                        }
                    }
                }
            }
        }
        return isValid
      },
  
      changevalue(id,state){
              this.isLoading =true;
              this.fieldsnamevalues[id]= null;
              this.fieldsnamevalues[id] =state;
              this.isLoading =false;
            },
      formatData(data) {
        return data
      },
  

      getAllfieldnamelist(project) {
        this.isLoading = true
        this.fieldnamelist = []
        let url = 'api/dynamictickets/getorientationfeedbackformlabels'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: this.moduletrackerid,
            projectid : project
        })
            axios({
                method: 'POST',
                url,
                data: payload,
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.fieldnamelistTemp = result.data.data
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.fieldnamelistTemp = [];
                }
            })
        },

      validateConfigForm(){
        this.isAddState = false;
        let isValid = true;
   
        let ofieldnamelistTemp = this.fieldnamelistTemp.filter(function (el) { 
            return el.projectid == null
        });

        // Field project-wise role config validation
        if(this.fieldnamelistTemp.length > 0 && (this.dynamicProject != null && this.dynamicProject != '')){
          for (let i = 0; i < this.fieldnamelistTemp.length; i++) {
            if(this.finalvalidationArray[this.fieldnamelistTemp[i].label]) {
                if(this.finalvalidationArray[this.fieldnamelistTemp[i].label] == 'Required')
                {
                  if(!this.fieldsnamevalues[this.fieldnamelistTemp[i].id] && this.fieldsnamevalues[this.fieldnamelistTemp[i].id] == '' || this.fieldsnamevalues[this.fieldnamelistTemp[i].id] == null){
                    Swal.fire({
                        title: 'Failed',
                        text: `${this.fieldnamelistTemp[i].label} is required. role config`,
                        icon: 'info',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: !1,
                      });
                      isValid= false;
                    }
                } 
            }  else {
              this.fieldnamelist.forEach(element => {
                if(element.label == this.fieldnamelistTemp[i].label) {
                  if(element.projectvalidationtype == "required") {
                    if(!this.fieldsnamevalues[element.id] && this.fieldsnamevalues[element.id] == '' || this.fieldsnamevalues[element.id] == null){
                    Swal.fire({
                        title: 'Failed',
                        text: `${this.fieldnamelistTemp[i].label} is required. field config`,
                        icon: 'info',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: !1,
                      });
                      isValid= false;
                    }
                  } else if(element.projectvalidationtype != "required" && element.validationtype == "required") {
                    if(!this.fieldsnamevalues[element.id] && this.fieldsnamevalues[element.id] == '' || this.fieldsnamevalues[element.id] == null){
                    Swal.fire({
                        title: 'Failed',
                        text: `${this.fieldnamelistTemp[i].label} is required. global `,
                        icon: 'info',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: !1,
                      });
                      isValid= false;
                    }
                  } 
                }
              });
            }

            //for common field 
            if(ofieldnamelistTemp && ofieldnamelistTemp.length>0) {
              ofieldnamelistTemp.forEach(e => {
                if(e.label == this.fieldnamelistTemp[i].label) {
                  if(e.projectvalidationtype != "required" && e.validationtype == "required") {
                    if(!this.fieldsnamevalues[e.id] && this.fieldsnamevalues[e.id] == '' || this.fieldsnamevalues[e.id] == null){
                    Swal.fire({
                        title: 'Failed',
                        text: `${this.fieldnamelistTemp[i].label} is required. common `,
                        icon: 'info',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: !1,
                      });
                      isValid= false;
                    }
                  }
                }
              });
            }
          }
        }

        if(this.projectflagfieldnamelist.length > 0 && (this.dynamicProject != null && this.dynamicProject != '')){
        for (let i = 0; i < this.projectflagfieldnamelist.length; i++) {
          if(this.finalvalidationArray[this.projectflagfieldnamelist[i].label]) {
              if(this.finalvalidationArray[this.projectflagfieldnamelist[i].label] == 'Required' && this.finalvalidationProjectIDArray[this.projectflagfieldnamelist[i].label] == this.dynamicProject)
              {
                if(!this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] && this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == '' || this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == null){
                  Swal.fire({
                      title: 'Failed',
                      text: `${this.projectflagfieldnamelist[i].label} is required. role config`,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
                  }
              } 
          }else{
            if(!this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] && this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == '' || this.fieldsnamevalues[this.projectflagfieldnamelist[i].id] == null){

            if(this.projectflagfieldnamelist[i].validationtype == 'required'){
              Swal.fire({
                      title: 'Failed',
                      text: `${this.projectflagfieldnamelist[i].label} is required. role config`,
                      icon: 'info',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: !1,
                    });
                    isValid= false;
            }
          }
            // console.log('this.projectflagfieldnamelist[i]================>>>>>>>>',this.projectflagfieldnamelist[i])
          }
        }

      } 

      return isValid;
    },
      getticketcreatormanager() {
        this.isLoading = true
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
        let apiUrl = 'api/ticket/getticketcreatormanager'
        axios({
          method: 'POST',
          url: apiUrl,
          data: this.input,
          headers: { authorization: this.tokendata }
        }).then(result => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.issue.subscriberslist = result.data.data.rows;
            let decryptdepartmenttext = this.issue.subscriberslist.map(items =>{
  
              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
  
              if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.issue.subscriberslist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
          getsupportengg() {
              this.isLoading = true
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
                  projectid: this.issue.project,
                  roleid: 484
        }
        let apiUrl = 'api/ticket/getsupportengg'
        axios({
          method: 'POST',
          url: apiUrl,
          data: this.input,
          headers: { authorization: this.tokendata }
        }).then(result => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.issue.supportengg = result.data.data.rows;
            if (this.issue.supportengg.length == 1) {
              this.isSupportEngg = true
            }
          } else {
            this.issue.supportengg = []
          }
        }).catch(e => {
          this.displayError(e)
        });
          },
      getEmpallocatedtoProjectForSubscriber(node){
        if (this.issue.project &&  (node && node.length > 3)) {
          this.input = { 
            projectid: this.issue.project, 
            useremail: this.undt.username,
            empcode: this.undt.userid, 
            asigneename:node.toLowerCase()          
          }
          // if(this.isThisBusinessRole) {
          //   this.input.roleid = 484
          // }
          if (this.isSupportEngg) {
            this.input.roleid = null
          } 
          if (this.issue.project == this.itHelpDesk ) {
            this.input.roleid= 484
            this.input.flag= 1
            this.input.issueid = this.editRowData.issueid
            axios({
              method: "POST",
              url: 'api/ticket/autoAssignItHelpDeskUser',
              data: this.input,
              'headers':{'authorization':this.tokendata}
            }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                this.issue.subscriberslist = result.data.data;
                this.issue.allotedtolist=result.data.data;
                let templistarray =[]
                templistarray = result.data.data;
                let decryptdepartmenttext = templistarray.map(items =>{
                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                  if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                  })
                  this.issue.subscriberslist = templistarray;
                  this.issue.allotedtolist=templistarray;
  
              } else {
                this.issue.allotedto = this.ifItHelpDeskNotFound;
              }
            }).catch(e => {
          this.displayError(e)
        });
          }else{
              axios({
              'method': 'POST',
              'url': 'api/listing/getEmpallocatedtoProject',
              'data': this.input,
              'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                if(result.data.data){
                  let decryptdepartmenttext = result.data.data.map(items =>{
  
                    let tempemplabel1 = items.label;
                    let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
  
                    if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                    }
                    return items;
                  })
                }
                this.issue.allotedtolist=result.data.data;
                this.issue.subscriberslist=result.data.data;
              } else if (node) {
                this.getticketcreatormanager()
              }else{
                this.issue.allotedtolist = [];
                this.issue.subscriberslist = [];
              }
            }).catch(e => {
          this.displayError(e)
        });
          }
        }
      },
      previewpdf(geturl)
      {
        window.open(geturl, "_blank");
      },
      getticketconfigdata2(projectid){
        this.isLoading = true;
        this.inputfield= {
          useremail: this.undt.username,
          empcode: parseInt(this.undt.userid),
          trackerid: this.interviewtrackerid,
          formid: parseInt(this.$route.query.ticketid),
          projectid: projectid
        }
        if(this.idFieldArray){
          this.inputfield.allprofieldid = this.idFieldArray;
        }
        axios({
          'method': 'POST',
          'url': 'api/dynamictickets/getticketconfigdata',
          'data': this.inputfield,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.status == true){
            this.isLoading = true;
            this.saveddata2 = result.data.data;
            this.isedit =true;
            for (let p = 0; p < this.saveddata2.length; p++) {

              if (this.saveddata2[p].fieldvalue && (this.saveddata2[p].fieldtype == 'checkbox' || this.saveddata2[p].fieldtype == 'multiselect-dropdown')) {
                this.fieldsnamevalues2[this.saveddata2[p].fieldid] = this.saveddata2[p].fieldvalue.split(',');
                this.fieldsnamevalues3[this.saveddata2[p].fieldid] = this.saveddata2[p].comment;
              }else{
                this.fieldsnamevalues2[this.saveddata2[p].fieldid] = this.saveddata2[p].fieldvalue;
                this.fieldsnamevalues3[this.saveddata2[p].fieldid] = this.saveddata2[p].comment;
              }
            }
            this.isLoading = false;
          }else{
            this.saveddata2=[];
          }
        });
      },
      getinterviewfeedbackrating(ticketid){
        this.isLoading = true;
        let url = "api/issue/getinterviewfeedbackrating";
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          ticketid: ticketid,
          interviewtrackerid: this.interviewtrackerid
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.interviewrating = result.data.data.rating;
          }else if (result.data.errorCode == 3) {
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function () {
              window.location.href = "/#/login";
            });
          } else {
            this.interviewrating = null;
          }
        }).catch(e => {
          this.displayError(e)
        })
      },

//dynamic_logtime

      checkProjectInclusion() {
        const selectedProjectId = parseInt(this.dynamicProject);
        // Check if selectedProjectId is included in fieldnamelist
        return this.fieldnamelist.some(field => field.projectid === selectedProjectId && field.projectflag !== 1 );

        // return this.fieldnamelist.some(field => field.projectid === selectedProjectId && field.projectflag === 1);
      },
      getFieldsWithProjectFlag1() {
        return this.fieldnamelist.filter(field => {
          return field.projectflag === 1;
        });
      },
      hasFieldsWithProjectFlag1() {
        return this.fieldnamelist.some(field => field.projectflag === 1);
      },

      getDynamicLogfields(project) {
        this.isLoading = true
        this.fieldnamelist = []
        let url = 'api/dynamictickets/getorientationfeedbackformlabels'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: this.moduletrackerid,
            projectid: project
        })
          axios({
              method: 'POST',
              url,
              data: payload,
              headers:{ authorization :this.tokendata }
          }).then(result => {
                  this.isLoading = false
                  if(result.data.errorCode == 0) {
                      this.fieldnamelist = result.data.data
                      for (let i = 0; i < this.fieldnamelist.length; i++) {
                          if (this.fieldnamelist[i].fieldtype=="checkbox") {
                              let tempdata = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata[j] = {}
                                      tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue']=tempdata
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="radio") {
                              let tempdata2 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata2[j] = {}
                                      tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="toggle") {
                              let tempdata5 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata5[j] = {}
                                      tempdata5[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata5[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue3']=tempdata5
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="drop-down") {
                              let tempdata3 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata3[j] = {}
                                      tempdata3[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata3[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalu1']=tempdata3
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="multiselect-dropdown") {
                              let tempdata4 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata4[j] = {}
                                      tempdata4[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata4[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue2']=tempdata4
                              }
                          }
                      }
                  } else if(result.data.errorCode == 3){
                      Swal.fire({
                          title: "Session Expired...!!",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      }).then(function() {
                          window.location.href = "/#/login";
                      })
                  } else {
                      this.fieldnamelist = [];
                  }
              })
      },






    getprojectmaprolide(empcode, trackername, valchk1)
      { 
        if(valchk1.length>0)
        {
          let url = "api/dynamictickets/getfieldconfigdataroleid";
          this.input = {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          projectid: [valchk1]
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {    
              if (result.data.errorCode == 0) {
                  this.newroleid = result.data.data[0]['roleid'];
                  this.getfieldconfigdatabyid(empcode, trackername, valchk1);
                  this.getDynamicvalidations(this.newroleid,valchk1)
              }
          });
        }
      },
      async getfieldconfigdatabyid(empcode, trackername, valchk1) {
        try {
            if(this.newroleid)
            {
              this.isLoading = true
              let url = "api/fieldtracker/getmultiplefieldconfigdatabyid";
              this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                roleid: this.newroleid,
                projectidarray: [valchk1],
                trackername: trackername
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers': { 'authorization': this.tokendata }
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.setSelectedParameter(result.data.data)
                  } else {
                      this.finalvalidationArray = [];
                      this.multidimensionalArray = [];
                  }
              });
            }
          //}
        } catch (error) { 
          console.log('err');
      }

    },
    getDynamicvalidations(newroleid,project) {
        this.isLoading = true
        let url = 'api/dynamictickets/getDynamicvalidation'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: this.moduletrackerid,
            projectid: project,
            roleid: newroleid
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
              this.dynamicfieldsvalidation = result.data.data
                this.getFieldInfo();
              } else if(result.data.errorCode == 3){
              Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
          } else {
              this.dynamicfieldsvalidation = [];
          }
      })
    },
    getFieldInfo() {
        const fieldInfo = [];
        for (const fieldConfig of this.dynamicfieldsvalidation[0].fieldconfig) {
          for (const field of fieldConfig) {
            if (typeof field === 'object') {
              const fieldName = Object.keys(field)[0];
              for (const statusData of field[fieldName]) {
                for (const status in statusData) {
                  const validationType = statusData[status][0].validation[0];
                  fieldInfo.push({
                    fieldName: fieldName,
                    status: status,
                    validation: validationType,
                  });
                }
              }
            }
          }
        }
        this.fieldInfo = fieldInfo;
    },
    setSelectedParameter(variable) {
      const masterkey=[];
      let mprojectid = []
      this.multidimensionalArray = [];
      for (var k = 0; k < variable.length; k++) {
          var fieldconfigstring = JSON.stringify(variable[k].fieldconfig);
          var fieldconfigstringarray = JSON.parse(fieldconfigstring);
          mprojectid.push(variable[k].projectid)
          var firstindex = "";
          var secondindex = "";
          for (var i = 0; i < fieldconfigstringarray.length; i++) {
          var data = "";
          firstindex = Object.keys(fieldconfigstringarray[i][0])[0];
          const textarray = [];
          masterkey.push(firstindex);
              for (var j = 0; j < fieldconfigstringarray[i][0][firstindex].length; j++) {
                  secondindex = Object.keys(fieldconfigstringarray[i][0][firstindex][j])[0];
                  var validationText='';
                  validationText = fieldconfigstringarray[i][0][firstindex][j][secondindex][0]['validation'][0];
                  textarray[secondindex]=validationText;
                  data = textarray;
                  if(this.logtime.status == secondindex )
                  {
                    const fixedKey = firstindex;
                    const newData = validationText;
                    if (!this.multidimensionalArray[fixedKey]){
                        if(this.logtime.status == secondindex )
                        this.$set(this.multidimensionalArray, fixedKey, []);
                    }
                    else
                    {
                        if(this.logtime.status == secondindex )
                        this.$set(this.multidimensionalArray, fixedKey, []);
                    }    
                    this.multidimensionalArray[fixedKey].push(newData);
                  }
              }
          }
      }
      const uniqueArray = [...new Set(masterkey)];
      this.finalvalidationArray = [];
      this.finalvalidationProjectIDArray = [];
      for (var i = 0 ; i < uniqueArray.length; i++)
      {
          if(this.multidimensionalArray.hasOwnProperty(uniqueArray[i])){
          this.finalvalidationArray[uniqueArray[i]]=this.multidimensionalArray[uniqueArray[i]][0];
          this.finalvalidationProjectIDArray[uniqueArray[i]] = mprojectid[i]
          }
      }        
    },
    updateValidationRulesForFields() {
      for (let i = 0; i < this.fieldnamelist.length; i++) {
        const fieldName = this.fieldnamelist[i].label;
        const validationType = this.getValidationTypeForField(fieldName, this.logtime.status);
        const isReadOnly = validationType === 'Read-Only';
        this.$set(this.fieldnamelist[i], 'isReadOnly', isReadOnly);
      }
    },
  isFieldDisabled(field) {
    if(this.dynamicProject && this.dynamicProject != null) {
      if(this.finalvalidationArray[field.label]) {  
        if(this.finalvalidationArray[field.label] == 'Read-Only')
        {
          const validationType = this.getValidationTypeForField(field.label, this.logtime.status);
          let result =
          validationType === 'Read-Only' ||
          field.validationtype === 'readonly' ||
          field.validationtype === 'hidden';
          return result;
  
        }
        field.projectvalidationtype = null
        field.validationtype = null
        let result = false;
        return result;
  
      } else {
        const validationType = this.getValidationTypeForField(field.label, this.logtime.status);
        let result =
          validationType === 'Read-Only' ||
          field.validationtype === 'readonly' ||
          field.validationtype === 'hidden';
          return result;
      }
    }
  },

  getValidationTypeForField(fieldName, selectedStatus) {
    const fieldData = this.fieldInfo.find(info => {
        const fieldNameLower = (fieldName || '').toLowerCase();
        const selectedStatusLower = (selectedStatus || '').toLowerCase();
        return info.fieldName.toLowerCase() === fieldNameLower && info.status.toLowerCase() === selectedStatusLower;
    });
    return fieldData ? fieldData.validation : null;
  },

    getticketconfigdata(){
          this.isLoading = true;
          this.inputfield= {
            useremail: this.undt.username,
            empcode: parseInt(this.undt.userid),
            trackerid: this.moduletrackerid,
            formid: parseInt(this.$route.query.ticketid),
            projectid: this.dynamicProject
          }
          if(this.idFieldArray){
            this.inputfield.allprofieldid = this.idFieldArray;
          }
          axios({
              'method': 'POST',
              'url': 'api/dynamictickets/getticketconfigdata',
              'data': this.inputfield,
              'headers':{'authorization':this.tokendata}
          })
          .then(result => {
              this.isLoading = false;
              if(result.data.status == true){
                  this.isLoading = true;
                  this.saveddata = result.data.data;
                  this.isedit =true;
                  for (let p = 0; p < this.saveddata.length; p++) {
                    if (this.saveddata[p].fieldvalue && (this.saveddata[p].fieldtype == 'checkbox' || this.saveddata[p].fieldtype == 'multiselect-dropdown')) {
                        this.fieldsnamevalues[this.saveddata[p].fieldid] = this.saveddata[p].fieldvalue.split(',');
                    }else{
                        this.fieldsnamevalues[this.saveddata[p].fieldid] = this.saveddata[p].fieldvalue;
                    }
                  }
                  this.isLoading = false;
              }else{
                  this.saveddata=[];
              }
          });
      },
      selcandidatestatus(state){
        this.issue.candidatestatus=null
        this.issue.candidatestatus=state
        if(!state && this.editRowData.status && this.editRowData.status !='closed' && (this.logtime.status == 'closed' && ((this.issue.type == this.interviewtype && this.interviewconfigurationForm) || this.issue.type == this.resumereviewtype) && this.issue.project == this.recruitmentprojectid)){
          this.errors.issue.candidatestatus='Candidate Status required';
        }else{
          this.errors.issue.candidatestatus=null;
        }
      },




    },
    filters: {
      basename: function (value) {
        if (!value) return ''
        //value = value.toString()
        return value.substr(value.lastIndexOf("/") + 1)
      }
    }
  }
  </script>
  