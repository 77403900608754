<template>
  <div class="app-content content">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                            <div class="avatar-content">
                                <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                                </search-icon>
                            </div>
                        </div>
                         <h4 class="card-title d-inline-flex ml-1">Search HOD Business Task Summary</h4>
                    </div>
                    <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                  </div>
                </div>
              </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                  <div class="form-group col-md-6 mb-1">
                      <label>
                        <div class="radio-cntrl mr-4">
                          <input type="radio" @change="onChangeSheetType($event)" value="realtime" v-model="search.sheettype"><span>Real Time</span>
                        </div>
                      </label>
                      <label>
                        <div class="radio-cntrl">
                          <input type="radio" @change="onChangeSheetType($event)" value="speedchart" v-model="search.sheettype"><span>Speed Chart</span>
                        </div>
                      </label> 
                    </div>                    
                  </div>
                  <div class="row">
                    <div class="form-group col-sm-12 col-md-6 col-lg-3 mb-1">
                        <label class="form-label" for="roles">Business Task Tracker</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Role" :options="managersList" v-model="search.roleId" @input="getBSHlist" v-if="pagefeatures.hodroleproject" :disabled="true"/>
                        <treeselect class="projroleassprojectdd" placeholder="Select Role" :options="managersList" v-model="search.roleId" @input="getBSHlist" v-else/>
                    </div>

                    <div  v-if="search.roleId=='project'" class="form-group col-sm 12 col-md-6 col-lg-3 mb-1">
                        <label class="form-label" for="bsh">Project Name</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Project Name " :multiple="true" :clear-on-select="true" :options="bshlist" v-model="search.bsh"/>
                    </div>
                    <div v-else class="form-group col-sm 12 col-md-6 col-lg-3 mb-1">
                        <label class="form-label" for="bsh">Stakeholder Name</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Stakeholder Name " :multiple="true" :clear-on-select="true" :options="bshlist" v-model="search.bsh" />
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Task Severity</label>
                        <treeselect class="projectmdllistdd" placeholder="Select Task Severity" v-model="search.searchTaskseverity" :options="keylist"/>
                    </div>

                    <div class="col-sm-12 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Task Type</label>
                        <treeselect class="projectmdllistdd" placeholder="Select Task Type" v-model="search.searchTasktype" :options="typelist" :multiple="true" :clear-on-select="true"/>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right">
                      <button v-if="this.sheettype == 'realtime'" type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getprojectlist(search,1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button v-else type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="hodSpeedChart(search,1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                      <button  v-if="this.sheettype == 'realtime'" type="move" class="btn btn-relief-primary mt-2 mr-1" @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span></button>
                      <button  v-else type="move" class="btn btn-relief-primary mt-2 mr-1" @click="speedChartExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div v-if="search.roleId=='project' && this.buttonflag" class="card-header p-2">
                    <h4 class="card-title">
                      ProjectWise Business Task Summary Report
                    </h4>
                  </div>
                  <div v-else class="card-header p-2">
                    <h4 class="card-title">
                      HOD Business Task Summary Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="table-small">
                      <div class="">
                        <div class="first_table_header_hod">
                          <div class="table-responsive_sitcky">
                          <table class="table table-hover table-striped  table-bordered table-sm all_table_stickys" aria-describedby="mydesc">
                              <thead>
                                <tr>
                                  <th scope="col" class="align-middle first-col-sticky">PRODUCT PIPELINE</th>
                                  <th scope="col" class="align-middle">PRODUCT WIP</th>
                                  <th scope="col" class="align-middle">PRODUCT COMPLETE</th>
                                  <th scope="col" class="align-middle">PRODUCT WIP 2</th>
                                  <th scope="col" class="align-middle">TECH AND BUSINESS REVIEW</th>
                                  <th scope="col" class="align-middle">ESTIMATION REVIEW</th>
                                  <th scope="col" class="align-middle">TECH PIPELINE</th>
                                  <th scope="col" class="align-middle">TECH WIP</th>
                                  <th scope="col" class="align-middle">QA PIPELINE</th>                                  
                                  <th scope="col" class="align-middle">QA WIP</th>
                                  <th scope="col" class="align-middle">Tech Issue Resolution</th>
                                  <th scope="col" class="align-middle">Design Review</th>
                                  <th scope="col" class="align-middle">UAT</th>
                                  <th scope="col" class="align-middle">UAT SIGNOFF</th>
                                  <th scope="col" class="align-middle">UAT Feedback</th>
                                  <th scope="col" class="align-middle">LIVE</th>
                                  <th scope="col" class="align-middle">COMPLETED</th>
                                  <th scope="col" class="align-middle">ONHOLD</th>
                                  <th scope="col" class="align-middle">DISCARDED</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(data, index) in totalcountList">
                                  <tr>                    
                                    <td class="first-col-sticky title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Product Pipeline')" style="cursor:pointer"> {{data.productpipeline ? data.productpipeline: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Product WIP')" style="cursor:pointer"> {{data.productwip ? data.productwip: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Product Completed')" style="cursor:pointer"> {{data.productcomplete ? data.productcomplete: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Product WIP 2')" style="cursor:pointer"> {{data.productwip2 ? data.productwip2: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Tech and Business Review')" style="cursor:pointer"> {{data.techreview ? data.techreview: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Estimation Review')" style="cursor:pointer"> {{data.estimationreview ? data.estimationreview: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Tech Pipeline')" style="cursor:pointer"> {{data.techpipeline ? data.techpipeline: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Tech WIP')" style="cursor:pointer"> {{data.techwip ? data.techwip: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'QA Pipeline')" style="cursor:pointer"> {{data.qapipeline ? data.qapipeline: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'QA WIP')" style="cursor:pointer"> {{data.qawip ? data.qawip: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Tech Issue Resolution')" style="cursor:pointer"> {{data.techissueresolution ? data.techissueresolution: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Design Review')" style="cursor:pointer"> {{data.designreview ? data.designreview: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'UAT')" style="cursor:pointer"> {{data.uat ? data.uat: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'UAT Signoff')" style="cursor:pointer"> {{data.uatsignoff ? data.uatsignoff: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'UAT Feedback')" style="cursor:pointer"> {{data.uatfeedback ? data.uatfeedback: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Live')" style="cursor:pointer"> {{data.live ? data.live: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Completed')" style="cursor:pointer"> {{data.completed ? data.completed: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'OnHold')" style="cursor:pointer"> {{data.onhold ? data.onhold: '0'}}</td>
                                    <td class="title font-weight-normal sitebluecolor" @click="HODSummaryExport(data,'Discarded')" style="cursor:pointer"> {{data.discarded ? data.discarded: '0'}}</td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="search.roleId=='project' && this.buttonflag" class="card-header p-2">
                      <h4 class="card-title">
                        Projectwise Business Task Report
                      </h4>
                    </div>
                    <div v-else class="card-header p-2">
                      <h4 class="card-title">
                        HOD Business Task Report
                      </h4>
                    </div>
                    <div class="" id="myTabContent">
                      <div class="" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky"  v-if="showtabledata">
                              <table class="table table-hover table-striped table-bordered table-sm all_table_stickys" aria-describedby="HOD Business Task Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" v-if="search.roleId=='project' && this.buttonflag"  class="align-middle first-col-sticky" style="min-width:200px;">PROJECT/Status</th>
                                      <th scope="col" v-else class="align-middle text-left first-col-sticky" style="min-width:200px;">HOD/Status</th>
                                      <th scope="col" v-for="(val, key) in taskstatus" class="align-middle">{{val.label}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)">                                        
                                        <td :class="'uppercasetext text-left first-col-sticky  unique'+index+1+currPageNum*pagelimit">
                                          {{data.businessstackholder}}
                                        </td>
                                        
                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Product Pipeline',parseInt(data.productpipeline))" style="cursor:pointer"> {{data.productpipeline}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Product WIP',parseInt(data.productwip))" style="cursor:pointer"> {{data.productwip}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Product Completed',parseInt(data.productcomplete))" style="cursor:pointer"> {{data.productcomplete}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Product WIP 2',parseInt(data.productwip2))" style="cursor:pointer"> {{data.productwip2}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Tech and Business Review',parseInt(data.techreview))" style="cursor:pointer"> {{data.techreview}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Estimation Review',parseInt(data.estimationreview))" style="cursor:pointer"> {{data.estimationreview}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Tech Pipeline',parseInt(data.techpipeline))" style="cursor:pointer"> {{data.techpipeline}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Tech WIP',parseInt(data.techwip))" style="cursor:pointer"> {{data.techwip}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'QA Pipeline',parseInt(data.qapipeline))" style="cursor:pointer"> {{data.qapipeline}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'QA WIP',parseInt(data.qawip))" style="cursor:pointer"> {{data.qawip}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Tech Issue Resolution',parseInt(data.techissueresolution))" style="cursor:pointer"> {{data.techissueresolution}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Design Review',parseInt(data.designreview))" style="cursor:pointer"> {{data.designreview}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'UAT',parseInt(data.uat))" style="cursor:pointer"> {{data.uat}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'UAT Signoff',parseInt(data.uatsignoff))" style="cursor:pointer"> {{data.uatsignoff}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'UAT Feedback',parseInt(data.uatfeedback))" style="cursor:pointer"> {{data.uatfeedback}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Live',parseInt(data.live))" style="cursor:pointer"> {{data.live}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Completed',parseInt(data.completed))" style="cursor:pointer"> {{data.completed}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'OnHold',parseInt(data.onhold))" style="cursor:pointer"> {{data.onhold}} </td>

                                        <td class="font-weight-normal sitebluecolor" @click="openHODModalView(data, 'Discarded',parseInt(data.discarded))" style="cursor:pointer"> {{data.discarded}} </td>
                                     </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0" >
                                      <tr>
                                        <td colspan="17" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="speedcharDiv" style="display:none;">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div v-if="search.roleId=='project' && this.buttonflag" class="card-header p-2">
                    <h4 class="card-title">
                      Projectwise Business Task Summary Report 
                    </h4>
                  </div>
                  <div v-else class="card-header p-2">
                    <h4 class="card-title">
                      HOD Business Task Summary Report 
                    </h4>
                  </div>
                  <div class="" id="table-small">
                    <div class="">
                      <div class="first_table_header_hod">
                        <div class="table-responsive_sitcky">
                          <table class="table table-hover table-striped  table-bordered table-sm all_table_stickys" aria-describedby="HOD Business Task Summary Report">
                            <thead>
                              <tr>
                                <th scope="col" class="align-middle first-col-sticky" style="min-width:140px;">PERIOD</th>
                                <th scope="col" class="align-middle">PRODUCT PIPELINE</th>
                                <th scope="col" class="align-middle">PRODUCT WIP</th>
                                <th scope="col" class="align-middle">PRODUCT COMPLETE</th>
                                <th scope="col" class="align-middle">PRODUCT WIP 2</th>
                                <th scope="col" class="align-middle">TECH AND BUSINESS REVIEW</th>
                                <th scope="col" class="align-middle">ESTIMATION REVIEW</th>
                                <th scope="col" class="align-middle">TECH PIPELINE</th>
                                <th scope="col" class="align-middle">TECH WIP</th>
                                <th scope="col" class="align-middle">QA PIPELINE</th>                                  
                                <th scope="col" class="align-middle">QA WIP</th>
                                <th scope="col" class="align-middle">TECH ISSUE RESOLUTION</th>
                                <th scope="col" class="align-middle">Design Review</th>
                                <th scope="col" class="align-middle">UAT</th>
                                <th scope="col" class="align-middle">UAT SIGNOFF</th>
                                <th scope="col" class="align-middle">UAT Feedback</th>
                                <th scope="col" class="align-middle">LIVE</th>
                                <th scope="col" class="align-middle">COMPLETED</th>
                                <th scope="col" class="align-middle">ONHOLD</th>
                                <th scope="col" class="align-middle">DISCARDED</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(data, index) in speedtotalcountList">
                                <tr>     
                                  <td class="first-col-sticky">
                                      {{dateMonth(data.periodfrom)}} - {{dateMonth(data.periodto)}}
                                  </td>                                   
                                  <td>{{data.productpipeline}}</td>
                                  <td>{{data.productwip}}</td>
                                  <td>{{data.productcomplete}}</td>
                                  <td>{{data.productwip2}}</td>
                                  <td>{{data.techreview}}</td>
                                  <td>{{data.estimationreview}}</td>
                                  <td>{{data.techpipeline}}</td>
                                  <td>{{data.techwip}}</td>
                                  <td>{{data.qapipeline}}</td>
                                  <td>{{data.qawip}}</td>
                                  <td>{{data.techissueresolution}}</td>
                                  <td>{{data.designreview}}</td>
                                  <td>{{data.uat}}</td>
                                  <td>{{data.uatsignoff}}</td>
                                  <td>{{data.uatfeedback?data.uatfeedback:0}}</td>
                                  <td>{{data.live}}</td>
                                  <td>{{data.completed}}</td>
                                  <td>{{data.onhold}}</td>
                                  <td>{{data.discarded}}</td>
                                </tr>
                              </template>
                              <template v-if="speedtotalcountList.length == 0" >
                                    <tr>
                                      <td colspan="17" style="text-align: center;">No record found</td>
                                    </tr>
                                  </template>
                                </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div  v-if="search.roleId=='project' && this.buttonflag" class="card-header p-2">
                    <h4 class="card-title">
                      Projectwise Business Task Report - Speed Chart
                    </h4>
                  </div>
                  <div  v-else class="card-header p-2">
                    <h4 class="card-title">
                      HOD Business Task Report - Speed Chart
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky"  v-if="sheettype != 'realtime'">
                              <table class="table table-hover table-striped table-bordered table-sm all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="align-middle first-col-sticky" style="min-width:150px;">HOD/Status</th>
                                      <th scope="col" v-for="(val, key) in taskstatus" class="align-middle">{{val.label}}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(spcdata, index) in speedChartList">
                                        <tr v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">
                                            <td colspan="20" style="text-align:left; background-color:#F3F2F7;" class="first-col-sticky"><strong class="hod_table_pl-1">{{index}}</strong></td>
                                        </tr>
                                         
                                         <tr v-for="(v,i) in spcdata" v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">  
                                            <td :class="'uppercasetext first-col-sticky unique'+index+1+currPageNum*pagelimit">
                                                {{dateMonth(v.periodfrom)}} - {{dateMonth(v.periodto)}}
                                            </td>
                                            
                                            <td>
                                                <span>{{v.productpipeline}}</span>
                                            </td>

                                            <td class="text-nowrap">
                                                <span>{{v.productwip}}</span>
                                            </td>

                                            <td>
                                                <span>{{v.productcomplete}} </span>
                                            </td>

                                            <td class="text-nowrap">
                                                <span>{{v.productwip2}}</span>
                                            </td>
                                           
                                            <td class="title">
                                                <span>{{v.techreview}}</span>
                                            </td>

                                            <td class="title">
                                                <span v-if="v.estimationreview">{{v.estimationreview}}</span>
                                                <span v-else>0</span>
                                            </td>

                                            <td>
                                                <span>{{v.techpipeline}}</span>
                                            </td>

                                            <td class="title">
                                                <span>{{v.techwip}}</span>
                                            </td>
                                            <td class="title">
                                                <span>{{v.qapipeline}}</span>
                                            </td> 

                                             <td class="title">
                                                <span>{{v.qawip}}</span>
                                            </td>
                                            <td class="title">
                                                <span>{{v.techissueresolution}}</span>
                                            </td>

                                            <td class="title">
                                                <span v-if="v.designreview">{{v.designreview}}</span>
                                                <span v-else>0</span>
                                            </td>

                                             <td class="title">
                                                <span>{{v.uat}}</span>
                                            </td>

                                            <td class="title">
                                                <span>{{v.uatsignoff}}</span>
                                            </td>

                                            <td class="title">
                                                <span v-if="v.uatfeedback">{{v.uatfeedback}}</span>
                                                <span>0</span>
                                            </td>

                                             <td class="title">
                                                <span>{{v.live}}</span>
                                            </td>

                                             <td class="title">
                                                <span>{{v.completed}}</span>
                                            </td>

                                            <td class="title">
                                                <span>{{v.onhold}}</span>
                                            </td>

                                             <td class="title">
                                                <span >{{v.discarded}}</span>
                                            </td>
                                         </tr>
                                    
                                    </template>
                                    <template v-if="speedChartList.length == 0" >
                                      <tr>
                                        <td colspan="17"  style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination v-if="sheettype != 'realtime'" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallbackSP"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="modal fade " id="imagemodal" tabindex="2" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg modal-dialog-centered " style="max-width: 800rem">
                <div class="modal-content">              
                    <div class="modal-body p-0 ">
                      <button type="button" class="modal-video-close-btn modal-video-close-btn2" data-dismiss="modal"><x-icon size="1.5x" class="custom-class rounded avatar-icon font-medium-3 bg-danger text-white"></x-icon></button>
                    </div>
                    <div class="modal-body p-2 mt-0">
                        <div class=" row mt-1">
                          <div class="col-sm-8 col-md-8 col-lg-8"> 
                            <span v-if="tasklist.length==0">No record found</span>
                            <p  style="display: inline-block;float: left;font-size: 18px;font-weight: 600;padding-top: 8px;" v-else> {{tasklist.length}} {{" "}} Records Of {{(search.roleId=='hod'?'HOD':search.roleId==477?'Project Manager':search.roleId==503?'Program Manager':search.roleId==492?'Engineering Manager':search.roleId=='project'?'Project':'HOD')}} Reports</p>
                            <div class="col-sm-2 text-left">
                          </div>
                          </div>
                          <div class="ml-auto mx-1">
                           <button class="btn avatar bg-primary rounded business_mar_view-margin-2 text-white mb-1 ml-2" @click="HODExportXL(tasklist)"> <external-link-icon size="1.5x" class="custom-class font-medium-3 text-white"></external-link-icon> <span> Export</span></button>                          
                          </div>
                        </div>
                      <div class="card border-warning" id="document-list">
                        <div class="card-body p-0 ps ps--active-x">
                          <div class="table-responsive" >
                            <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                                <tr>
                                  <th scope="col" class="text-center align-middle first-col-sticky" style="min-width:30px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                                  <th scope="col" class="text-left align-middle second-col-sticky" style="min-width: 180px;">BUSINESS TASK DETAILS</th>
                                  <th scope="col" class="text-left align-middle">Owner Details</th>
                                  <th scope="col" class="text-left align-middle">Task Manager Details</th>
                                  <th scope="col" class="text-left align-middle">Project Manager Details</th>
                                  <th scope="col" class="text-left align-middle">QA Details</th>
                                  <th scope="col" class="text-left align-middle" style="min-width: 160px;">Date Details</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(data,index) in tasklist"  >
                                  <tr v-bind:key="index" >
                                    <td class="text-center text-capitalize first-col-sticky">{{ index+1 }}</td>
                                    <td class="text-normal details_column_table second-col-sticky" style="max-width:20%;">
                                        <span> <strong>Code : </strong> </span>
                                        <span> {{data.businesstaskcode}}</span>
                                        <hr class="hr_border-bottom" />
                                        <div v-if="data.taskkey.toLowerCase()=='key' || data.taskkey.toLowerCase()=='urgent'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="data.taskkey.toLowerCase()=='normal'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                        <div v-if="(data.isapproved_edd && data.isapproved_edd == 'Pending')" class="badge badge-pill badge-light-warning mr-50 capitalisetext">Pending</div>
                                        <div class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.taskstatus}}</div>
                                        <hr class="hr_border-bottom" />
                                        <span class="capitalisetext">
                                        <span> <strong>Name : </strong> </span>
                                        <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;" v-html="data.taskname.substring(0,17)+'..'"></span>
                                        <hr class="hr_border-bottom" />
                                        <span> <strong>Project Name : </strong>{{ data.projectnames }} </span>

                                        
                                      </span>
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> HOD Name	: </span>
                                      {{ data.businessstackholder?data.businessstackholder:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> Task Owner(Lead) : </span>
                                      {{ data.taskowner?data.taskowner:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> Task Manager : </span>
                                      {{ data.productstackholder?data.productstackholder:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> Program Manager : </span>
                                      {{ data.programmanager?data.programmanager:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> Project Manager : </span>
                                      {{ data.projectmaanager?data.projectmaanager:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> Engineering Manager : </span>
                                      {{ data.engineeringmaanager?data.engineeringmaanager:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> QA Manager	: </span>
                                      {{ data.qamanager?data.qamanager:'NA' }}
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text text-capitalize"> QA Lead : </span>
                                      {{ data.qalead?data.qalead:'NA' }}
                                    </td>
                                    <td class="details_column_table">
                                      <span class="heading_column_text text-capitalize"> CREATED BY : </span>
                                      {{data.createdby.split('@')[0].split('.').join(' ')}}
                                      
                                      <hr class="hr_border-bottom" />
                                      <span class="heading_column_text full_100"> CREATED DATE : </span>
                                      {{dateMonthFormat2(data.createddate)}}
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import { EyeIcon,XIcon,SearchIcon,ExternalLinkIcon } from "vue-feather-icons";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "hodbusinesstaskreport",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      buttonflag:null,
      search:{
          Empname:null,
          searchTaskseverity :null,
          taskseverityopts :null,
          priorityopts :null,
          searchTasktype : ['Business Task'],
          tasktypeopts :null,
          bsh: null,
          roleId: 'hod',
          sheettype : 'realtime'
      },
      managersList: [  {
          id : 492,
          label: 'Engineering Manager',
        },
        { 
          id: 'hod',
          label: 'HOD',
        },
        {
          id : 'project',
          label: 'Project',
        },
        {
          id : 477,
          label: 'Project Manager',
        },
        {
          id : 503,
          label: 'Program Manager',
        },
        {
          id : 'project',
          label: 'Project',
        }
        ],
      projectnameList:[],
      createdbyOpts:[],
      typelist:apiUrl.typelist.sort((a, b) => a.label.localeCompare(b.label)),
      keylist:apiUrl.keylist.sort((a, b) => a.label.localeCompare(b.label)),
      isLoading: false,
      fullPage: true,
      islistLoading:false,
      listfullPage:true,
      companymasterlist: [],
      totalcountList:[],
      stylevariation: apiUrl.stylevariation,
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      taskstatus: [],
      bshlist : [],
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      emplist:[],
      tokendata:null,
      speedChartList: [],
      sheettype : 'realtime',
      speedtotalcountList:[],
      userrole:null,
      tasklist: [],
    };
  },
  created() {
    this.pagefeatures = {}
    if(window.localStorage.getItem('pagefeatures')){
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/reports/businesstaskhodmaster') {
              this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.userrole = window.localStorage.getItem("userrole")
        if(this.pagefeatures.hodroleproject){
          this.search.roleId='project'
          rowdata.roleId='project'
        }
        this.getBSHlist(null);
        this.getprojectlist(rowdata,0);
        this.getBtcStatusList();
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getBtcStatusList() {
        let url = "api/btc/getBtcStatusList";
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.taskstatus = result.data.data.rows;
            } else {
                this.taskstatus = [];
            }
        }).catch(e => {
        this.displayError(e)
      });     
    },
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.pageoffset=1
      this.search.searchProjectname = state;
    }, 
    resetRecords: function () {
      let rowdata={}
      this.search.bsh=null
      this.search.searchTaskseverity=null
      this.search.searchTasktype=['Business Task']
      this.search.roleId='hod'
      if(this.pagefeatures.hodroleproject){
        this.search.roleId='project'
        rowdata.roleId='project'
      }
      this.search.type=null
      this.getprojectlist(rowdata,0);
      this.hodSpeedChart(rowdata,0);
      this.search.empname = null;
    },
    onChangeSheetType(event) {
      this.sheettype = event.target.value
      this.pageoffset=1
      this.page = 1

      let rowdata={}
      if(this.pagefeatures.hodroleproject){
        this.search.roleId='project'
        rowdata.roleId='project'
      }else{
        this.search.roleId="hod"
      }
      if (this.sheettype == 'realtime'){
          this.showtabledata=true
          $('#speedcharDiv').hide();
          $('#reportTopScroll').show();
          this.getprojectlist(rowdata,0);
      } else {
          this.showtabledata=false
          $('#speedcharDiv').show();
          $('#reportTopScroll').hide();
          this.hodSpeedChart(rowdata,0);
      }
      this.totalcount = 0;
      this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
      this.search.bsh=null
      this.search.searchTaskseverity=null
     
    },
    openHODModalView(data, statustype, value) {
      if (value==0) {
        return
      }
      this.isLoading = true
      this.statustype=statustype
      let businessstackholders=null
      if (data.businessstackholder == data.businessstackholder.toUpperCase()){
        businessstackholders=data.businessstackholder.toLowerCase()
      }else{
        businessstackholders=data.businessstackholder
      }
      let url = 'api/reports/getHODModalViewList'
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        businessstackholder: businessstackholders,
        statustype:statustype,
        bsh: this.search.bsh?this.search.bsh:null,
        tracker:this.search.roleId?String(this.search.roleId): 'hod',
        tasktype: this.search.searchTasktype?this.search.searchTasktype: null,
        severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.status) {
          this.tasklist = result.data.data.rows
          // this.tasklist.typename=this.typename
          // this.tasklist.daysfor=daysfor
          // this.tasklist.parr=data.project
          this.tasklist.businessstackholdername=businessstackholders
        }else if(result.data.errorCode == 3){
          Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
        }else {
          this.tasklist = []
        }
      }).catch(e => {
        this.tasklist = []
        Swal.fire({
          title: "",
          text: e,
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
      })
      $('#imagemodal').modal('toggle');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
    dateMonthFormat2(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    getprojectlist(rowdata,flag) {
      this.islistLoading = true; 
      this.parentmoduledata = rowdata;   
      if(flag==1){
        this.page=1
      }
      if(rowdata.roleId && rowdata.roleId=='project'){
        this.buttonflag = 1
      }else{
        this.buttonflag = null
      }
      
      let url = "api/reports/hodbusinesstaskreports";
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        type: rowdata.searchTasktype?rowdata.searchTasktype: this.search.searchTasktype? this.search.searchTasktype : null,
        severity:rowdata.searchTaskseverity?rowdata.searchTaskseverity:null,
        bsh: rowdata.bsh?rowdata.bsh:null,
        roleId: rowdata.roleId?String(rowdata.roleId): 'hod',
        empcode: this.undt.userid,
        userrole:this.userrole,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.status == true) {
          this.showcompanies = false;
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.gettotalcountTbl(rowdata);
        } else {
          Swal.fire({
            title: "",
            text: "HOD wise project status data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    gettotalcountTbl(rowdata) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      let url = "api/master/businesstask/getHODToalCount";
      this.input = {
        offset: null,
        limit: null,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        type: rowdata.searchTasktype?rowdata.searchTasktype: this.search.searchTasktype,
        severity:rowdata.searchTaskseverity?rowdata.searchTaskseverity:null,
        bsh: rowdata.bsh?rowdata.bsh:null,
        roleId: rowdata.roleId?String(rowdata.roleId): 'hod',
        empcode: this.undt.userid,
        userrole:this.userrole,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.status == true) {
          this.totalcountList = result.data.data;
        } else {
          Swal.fire({
            title: "",
            text: "HOD wise project status summary data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.totalcountList = [];
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    hodSpeedChart(rowdata,flag) {
        this.islistLoading = true; 
        this.parentmoduledata = rowdata;   
        if(flag==1){
          this.page=1
        }
        if(rowdata.roleId && rowdata.roleId=='project'){
          this.buttonflag = 1
        }else{
          this.buttonflag = null
        }
        this.input = {
          offset: this.pageoffset,
          limit: apiUrl.LIMIT,
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          type: rowdata.searchTasktype?rowdata.searchTasktype: this.search.searchTasktype,
          severity:rowdata.searchTaskseverity?rowdata.searchTaskseverity:null,
          bsh: rowdata.bsh?rowdata.bsh:null,
          roleId: rowdata.roleId?String(rowdata.roleId): 'hod',
          empcode: this.undt.userid,
          userrole:this.userrole,
        };

        if(typeof rowdata.searchTasktype !=='undefined'){
          this.input.type = rowdata.searchTasktype
        }else if (typeof this.search.searchTasktype !=='undefined'){
          this.input.type = this.search.searchTasktype
        }else{
          this.input.type =null
        }
        axios({
            method: "POST",
            url: "api/master/businesstask/hodSpeedChart",
            data: this.input,
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.islistLoading = false;
            if (result.data.errorCode == 0) {
                const groupByCategory = result.data.data.rows.reduce((group, product) => {
                  const { businessstackholder } = product;
                  group[businessstackholder] = group[businessstackholder] ?? [];
                  group[businessstackholder].push(product);
                  return group;
                }, {});

                this.speedChartList = groupByCategory;
                this.totalcount = result.data.data.count
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                this.getspeedcharttotalcount(rowdata)
            } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                this.speedChartList = [];
                this.totalcount = 0;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                this.speedtotalcountList=[]
            }
        }).catch(e => {
        this.displayError(e)
      });
    },
    getspeedcharttotalcount(rowdata) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      let url = "api/reports/getSpeedChartToalCount";
      this.input = {
        type: rowdata.searchTasktype?rowdata.searchTasktype: this.search.searchTasktype,
        severity:rowdata.searchTaskseverity?rowdata.searchTaskseverity:null,
        stakeholder: rowdata.bsh?rowdata.bsh:null,
        tracker: rowdata.roleId?String(rowdata.roleId): 'hod',
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
        userrole:this.userrole,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.status == true) {
          this.speedtotalcountList = result.data.data;
        } else {
          Swal.fire({
            title: "",
            text: "HOD  project summary data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.speedtotalcountList = [];
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    getBSHlist(dept) {
      if(dept==null || dept=='hod')
      {
          this.search.bsh=null
          axios({
              method: "POST",
              url: "api/listing/getHODlist",
              data: {dept,useremail: this.undt.username,empcode: this.undt.userid},
              headers: { authorization : this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.bshlist = result.data.data.rows
              } else {
                  this.bshlist = [];
              }
          }).catch(e => {
          this.displayError(e)
        });
      }
      else if(dept=='project'){
        this.search.bsh=null
        axios({
            method: "POST",
            url: "api/listing/getProjectAllocatedToEmp",
            data: {empid: parseInt(this.undt.userid),useremail: this.undt.username,empcode: this.undt.userid},
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.bshlist = result.data.data
            } else {
                this.bshlist = [];
            }
        }).catch(e => {
          this.displayError(e)
        });
      }
      else{
        this.search.bsh=null
          axios({
            method: "POST",
            url: "api/listing/getStakeHoldersList",
            data: { roleid: dept,useremail: this.undt.username,empcode: this.undt.userid },
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.bshlist = result.data.data;
            } else {
              this.bshlist = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      }
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);

    },
     clickCallbackSP: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.hodSpeedChart(this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#speedcharDiv").offset().top -70
            });
        }, 500);

    },
    redirecttourl(btnstate,rowdata) {
      rowdata.backbtnstate=btnstate
        let routeData = this.$router.resolve({ 
        path: '/businesstask/view?businessid='+rowdata.businesstaskmasterid,
        params: {
          backbtnstate:btnstate,
          clickeddata:rowdata
        }
      })
      window.open(routeData.href, '_blank');
    },
    setSelected(value) {
      this.selected = value;
    },
    dateMonth(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM');
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    speedChartExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        offset: null,
        limit: null,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        type: this.search.searchTasktype? this.search.searchTasktype : null ,
        severity: this.search.searchTaskseverity?this.search.searchTaskseverity:null,
        bsh: this.search.bsh?this.search.bsh:null,
        roleId: this.search.roleId?String(this.search.roleId): 'hod',
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: "api/master/businesstask/hodSpeedChart",
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          let filename = ""
          this.downloadSP(result.data.data.rows,filename)
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        if (result.data.status) {
          this.isDetActive = true;
          const groupByCategory = result.data.data.rows.reduce((group, product) => {
            const { businessstackholder } = product;
            group[businessstackholder] = group[businessstackholder] ?? [];
            group[businessstackholder].push(product);
            return group;
          }, {});
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        type: this.search.searchTasktype?this.search.searchTasktype: null,
        severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
        bsh: this.search.bsh?this.search.bsh:null,
        roleId: this.search.roleId?String(this.search.roleId): 'hod',
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: `api/reports/hodbusinesstaskreports`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          let filename = ""
          this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        if (result.data.status) {
          this.isDetActive = true;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    downloadSP(readExportdata,filename) {
      if (readExportdata.length > 0) {
        let ShowLabel = 'exportreport_'+filename
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            let statustypes = this.taskstatus;
            if(this.search.roleId && this.search.roleId=='project'){
              CSV = 'PROJECT,';
            }else{
              CSV = 'HOD,';
            }
            CSV += 'Period,';
            for (let task=0; task<statustypes.length; task++) {
                CSV += `${statustypes[task].label},`;
            }
            CSV += '\r\n';
          }
            var row = ''
              for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder : '';
                let productpipeline = index.hasOwnProperty('productpipeline') ? index.productpipeline : '';
                let productwip = index.hasOwnProperty('productwip') ? index.productwip : '';
                let productcomplete = index.hasOwnProperty('productcomplete') ? index.productcomplete : '';
                let productwip2 = index.hasOwnProperty('productwip2') ? index.productwip2 : '';
                let techpipeline = index.hasOwnProperty('techpipeline') ? index.techpipeline : '';
                let techreview = index.hasOwnProperty('techreview') ? index.techreview : '';
                let estimationreview = index.hasOwnProperty('estimationreview') ? index.estimationreview : '';
                let techwip = index.hasOwnProperty('techwip') ? index.techwip : '';              
                let designreview = index.hasOwnProperty('designreview') ? index.designreview : '';
                let qapipeline = index.hasOwnProperty('qapipeline') ? index.qapipeline : '';
                let qawip = index.hasOwnProperty('qawip') ? index.qawip : '';
                let techissueresolution = index.hasOwnProperty('techissueresolution') ? index.techissueresolution : '';
                let uat = index.hasOwnProperty('uat') ? index.uat : '';
                let uatsignoff = index.hasOwnProperty('uatsignoff') ? index.uatsignoff : '';
                let uatfeedback = index.hasOwnProperty('uatfeedback') ? index.uatfeedback : '';
                uatfeedback = (uatfeedback!=null) ? uatfeedback : 0;
                let live = index.hasOwnProperty('live') ? index.live : '';              
                let completed = index.hasOwnProperty('completed') ? index.completed : '';
                let onhold = index.hasOwnProperty('onhold') ? index.onhold : '';
                let discarded = index.hasOwnProperty('discarded') ? index.discarded : '';
                let periodfrom = index.hasOwnProperty('periodfrom') ? index.periodfrom : '';
                let periodto = index.hasOwnProperty('periodto') ? index.periodto : '';

                let pfrom = this.dateMonth(periodfrom)
                let pto = this.dateMonth(periodto)

              
                row += '"' + businessstackholder + '",',
                row += '"' + pfrom + '-' + pto +'",',
                row += '"' + productpipeline + '",',
                row += '"' + productwip + '",',
                row += '"' + productcomplete + '",',
                row += '"' + productwip2 + '",',
                row += '"' + techreview + '",',
                row += '"' + estimationreview + '",',
                row += '"' + techpipeline + '",',
                row += '"' + techwip + '",',
                row += '"' + qapipeline + '",',
                row += '"' + qawip + '",',
                row += '"' + techissueresolution + '",',
                row += '"' + designreview + '",',
                row += '"' + uat + '",',
                row += '"' + uatsignoff + '",',
                row += '"' + uatfeedback + '",',
                row += '"' + live + '",',
                row += '"' + completed + '",',
                row += '"' + onhold + '",',
                row += '"' + discarded + '",',
                row += '\r\n'
                
              }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            let fileName = 'HOD_wise_Project_Status'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            let link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });  
        } 
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          let ShowLabel = 'exportreport_'+filename
          let JSONData = readExportdata
          let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          let CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            let statustypes = this.taskstatus;
            if(this.search.roleId && this.search.roleId=='project'){
              CSV = 'PROJECT/Status,';
            }else{
              CSV = 'HOD/Status,';
            }

            for (let task=0; task<statustypes.length; task++) {
                CSV += `${statustypes[task].label},`;
            }
            CSV += '\r\n';
          }
            var row = ''
              for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder : '';
                let productpipeline = index.hasOwnProperty('productpipeline') ? index.productpipeline : '';
                let productwip = index.hasOwnProperty('productwip') ? index.productwip : '';
                let productcomplete = index.hasOwnProperty('productcomplete') ? index.productcomplete : '';
                let productwip2 = index.hasOwnProperty('productwip2') ? index.productwip2 : '';
                let techpipeline = index.hasOwnProperty('techpipeline') ? index.techpipeline : '';
                let techreview = index.hasOwnProperty('techreview') ? index.techreview : '';
                let estimationreview = index.hasOwnProperty('estimationreview') ? index.estimationreview : '';
                let techwip = index.hasOwnProperty('techwip') ? index.techwip : '';              
                let designreview = index.hasOwnProperty('designreview') ? index.designreview : '';
                let qapipeline = index.hasOwnProperty('qapipeline') ? index.qapipeline : '';
                let qawip = index.hasOwnProperty('qawip') ? index.qawip : '';
                let techissueresolution = index.hasOwnProperty('techissueresolution') ? index.techissueresolution : '';
                let uat = index.hasOwnProperty('uat') ? index.uat : '';
                let uatsignoff = index.hasOwnProperty('uatsignoff') ? index.uatsignoff : '';
                let uatfeedback = index.hasOwnProperty('uatfeedback') ? index.uatfeedback : '';
                let live = index.hasOwnProperty('live') ? index.live : '';              
                let completed = index.hasOwnProperty('completed') ? index.completed : '';
                let onhold = index.hasOwnProperty('onhold') ? index.onhold : '';
                let discarded = index.hasOwnProperty('discarded') ? index.discarded : '';
                
                row += '"' + businessstackholder + '",',
                row += '"' + productpipeline + '",',
                row += '"' + productwip + '",',
                row += '"' + productcomplete + '",',
                row += '"' + productwip2 + '",',
                row += '"' + techreview + '",',
                row += '"' + estimationreview + '",',
                row += '"' + techpipeline + '",',
                row += '"' + techwip + '",',
                row += '"' + qapipeline + '",',
                row += '"' + qawip + '",',
                row += '"' + techissueresolution + '",',
                row += '"' + designreview + '",',
                row += '"' + uat + '",',
                row += '"' + uatsignoff + '",',
                row += '"' + uatfeedback + '",',
                row += '"' + live + '",',
                row += '"' + completed + '",',
                row += '"' + onhold + '",',
                row += '"' + discarded + '",',
                row += '\r\n'
                
              }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            let fileName = 'HOD_wise_Project_Status'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            let link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });  
        } 
    },
    export (data, filename, mime) {
          let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
          download(blob, filename, mime)
        },

        HODExportXL(taskdatas){
            this.isLoading = true;
            this.exportxlsdata2 = [];
            this.exporterr2 = "";
            this.input = {
                useremail:this.undt.username,
                empcode: this.undt.userid,
                businessstackholder: taskdatas.businessstackholdername,
                typename:taskdatas.typename,
                statustype:taskdatas[0].taskstatus,
                tracker:this.search.roleId?String(this.search.roleId): 'hod',
                tasktype: this.search.searchTasktype?this.search.searchTasktype: null,
                severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
                bsh: this.search.bsh?this.search.bsh:null,
            };
            axios({
                method: "POST",
                url: `api/reports/getHODModalViewList`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata2 = result.data.data.rows;
                    var filename = taskdatas.businessstackholdername+'report';
                    this.download2(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                        window.location.href = "/#/login";
                        })
                } else {
                    Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    });
                }
            }).catch(e => {
            this.displayError(e)
          })
        },
        download2(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = filename
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
            if (ShowLabel) {
            let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
              row = row.slice(0, -1)
              CSV = 'Sr#,Project Name,Business Task Code,Business Task Name,HOD Name,Engineering Manager,Project Manager,Program Manager,Task Manager,Task Owner (Lead),QA Manager,QA Lead,Status,Created By,Created Date,Product Completed Date,Tech Pipeline Date,QA Pipeline Date,UAT Date' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode ==null || index.businesstaskcode.toLowerCase() =='na' ? '' : index.businesstaskcode : '';
            let taskname = index.hasOwnProperty('taskname') ? index.taskname ==null || index.taskname.toLowerCase() =='na' ? '' : index.taskname : '';
            let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder ==null || index.businessstackholder.toLowerCase() =='na' ? '' : index.businessstackholder : 'NA';
            let productstackholder = index.hasOwnProperty('productstackholder') ? index.productstackholder ==null || index.productstackholder.toLowerCase() =='na' ? 'NA' : index.productstackholder : 'NA';
            let taskowner = index.hasOwnProperty('taskowner') ? index.taskowner ==null || index.taskowner.toLowerCase() =='na' ? 'NA' : index.taskowner : 'NA';
            let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager ==null || index.projectmaanager.toLowerCase() =='na' ? 'NA' : index.projectmaanager : 'NA';
            let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager ==null || index.programmanager.toLowerCase() =='na' ? 'NA' : index.programmanager : 'NA';
            let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager ==null || index.engineeringmaanager.toLowerCase() =='na' ? 'NA' : index.engineeringmaanager : 'NA';
            let qamanager = index.hasOwnProperty('qamanager') ? index.qamanager ==null || index.qamanager.toLowerCase() =='na' ? 'NA' : index.qamanager : 'NA';
            let qalead = index.hasOwnProperty('qalead') ? index.qalead ==null || index.qalead.toLowerCase() =='na' ? 'NA' : index.qalead : 'NA';
            let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus ==null || index.taskstatus.toLowerCase() =='na' ? '' : index.taskstatus : '';
            let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
            createddate = this.dateMonthFormat(createddate);
            let productcompleteddt = index.hasOwnProperty('productcompleteddt') ? index.productcompleteddt : '';
            productcompleteddt = productcompleteddt?this.dateMonthFormat(productcompleteddt):'NA';
            let techpipelinedate = index.hasOwnProperty('techpipelinedate') ? index.techpipelinedate : '';
            techpipelinedate = techpipelinedate?this.dateMonthFormat(techpipelinedate):'NA';
            let qapipelinedt = index.hasOwnProperty('qapipelinedt') ? index.qapipelinedt : '';
            qapipelinedt = qapipelinedt?this.dateMonthFormat(qapipelinedt):'NA';
            let uatdate = index.hasOwnProperty('uatdate') ? index.uatdate : '';
            uatdate = uatdate?this.dateMonthFormat(uatdate):'NA';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
              row += '"' + srno + '",',
              row += '"' + projectname + '",',
              row += '"' + businesstaskcode + '",',
              row += '"' + taskname + '",',
              row += '"' + businessstackholder + '",',
              row += '"' + engineeringmaanager + '",',
              row += '"' + projectmaanager + '",',
              row += '"' + programmanager + '",',
              row += '"' + productstackholder + '",',
              row += '"' + taskowner + '",',
              row += '"' + qamanager + '",',
              row += '"' + qalead + '",',
              row += '"' + taskstatus + '",',
              row += '"' + createdby + '",',
              row += '"' + createddate + '",',
              row += '"' + productcompleteddt + '",',
              row += '"' + techpipelinedate + '",',
              row += '"' + qapipelinedt + '",',
              row += '"' + uatdate + '",',
              row += '\r\n'
          }
          CSV += row
          if (CSV === '') {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            return;
          }
          var fileName = filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
          var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
          var link = document.createElement('a')
          link.href = uri
          link.style = 'visibility:hidden'
          link.download = fileName + '.csv'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          Swal.fire({
            title: "Success!",
            text: "Export Report Successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          } else {
              Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        },

        HODSummaryExport(data, statustype){
            this.isLoading = true;
            this.exportxlsdata2 = [];
            this.exporterr2 = "";
            this.input = {
                useremail:this.undt.username,
                empcode: this.undt.userid,
                businessstackholder: this.search.bsh,
                statustype:statustype,
                tracker:this.search.roleId?String(this.search.roleId): 'hod',
                tasktype: this.search.searchTasktype?this.search.searchTasktype: null,
                severity:this.search.searchTaskseverity?this.search.searchTaskseverity:null,
            };
            axios({
                method: "POST",
                url: `api/reports/getHODSummaryExport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata2 = result.data.data.rows;
                    var filename = statustype+'-HOD Summary report';
                    this.downloadHODSummary(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                        window.location.href = "/#/login";
                        })
                } else {
                    Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    });
                }
            }).catch(e => {
            this.displayError(e)
          })
        },
        downloadHODSummary(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = filename
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
            if (ShowLabel) {
            let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
              row = row.slice(0, -1)
              CSV = 'Sr#,Project Name,Business Task Code,Business Task Name,HOD Name,Engineering Manager,Project Manager,Program Manager,Task Manager,Task Owner (Lead),QA Manager,QA Lead,Status,Created By,Created Date,Product Completed Date,Tech Pipeline Date,QA Pipeline Date,UAT Date' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode ==null || index.businesstaskcode.toLowerCase() =='na' ? '' : index.businesstaskcode : '';
            let taskname = index.hasOwnProperty('taskname') ? index.taskname ==null || index.taskname.toLowerCase() =='na' ? '' : index.taskname : '';
            let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder ==null || index.businessstackholder.toLowerCase() =='na' ? '' : index.businessstackholder : 'NA';
            let productstackholder = index.hasOwnProperty('productstackholder') ? index.productstackholder ==null || index.productstackholder.toLowerCase() =='na' ? 'NA' : index.productstackholder : 'NA';
            let taskowner = index.hasOwnProperty('taskowner') ? index.taskowner ==null || index.taskowner.toLowerCase() =='na' ? 'NA' : index.taskowner : 'NA';
            let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager ==null || index.projectmaanager.toLowerCase() =='na' ? 'NA' : index.projectmaanager : 'NA';
            let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager ==null || index.programmanager.toLowerCase() =='na' ? 'NA' : index.programmanager : 'NA';
            let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager ==null || index.engineeringmaanager.toLowerCase() =='na' ? 'NA' : index.engineeringmaanager : 'NA';
            let qamanager = index.hasOwnProperty('qamanager') ? index.qamanager ==null || index.qamanager.toLowerCase() =='na' ? 'NA' : index.qamanager : 'NA';
            let qalead = index.hasOwnProperty('qalead') ? index.qalead ==null || index.qalead.toLowerCase() =='na' ? 'NA' : index.qalead : 'NA';
            let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus ==null || index.taskstatus.toLowerCase() =='na' ? '' : index.taskstatus : '';
            let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
            createddate = this.dateMonthFormat(createddate);
            let productcompleteddt = index.hasOwnProperty('productcompleteddt') ? index.productcompleteddt : '';
            productcompleteddt = productcompleteddt?this.dateMonthFormat(productcompleteddt):'NA';
            let techpipelinedate = index.hasOwnProperty('techpipelinedate') ? index.techpipelinedate : '';
            techpipelinedate = techpipelinedate?this.dateMonthFormat(techpipelinedate):'NA';
            let qapipelinedt = index.hasOwnProperty('qapipelinedt') ? index.qapipelinedt : '';
            qapipelinedt = qapipelinedt?this.dateMonthFormat(qapipelinedt):'NA';
            let uatdate = index.hasOwnProperty('uatdate') ? index.uatdate : '';
            uatdate = uatdate?this.dateMonthFormat(uatdate):'NA';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
              row += '"' + srno + '",',
              row += '"' + projectname + '",',
              row += '"' + businesstaskcode + '",',
              row += '"' + taskname + '",',
              row += '"' + businessstackholder + '",',
              row += '"' + engineeringmaanager + '",',
              row += '"' + projectmaanager + '",',
              row += '"' + programmanager + '",',
              row += '"' + productstackholder + '",',
              row += '"' + taskowner + '",',
              row += '"' + qamanager + '",',
              row += '"' + qalead + '",',
              row += '"' + taskstatus + '",',
              row += '"' + createdby + '",',
              row += '"' + createddate + '",',
              row += '"' + productcompleteddt + '",',
              row += '"' + techpipelinedate + '",',
              row += '"' + qapipelinedt + '",',
              row += '"' + uatdate + '",',
              row += '\r\n'
          }
          CSV += row
          if (CSV === '') {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            return;
          }
          var fileName = filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
          var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
          var link = document.createElement('a')
          link.href = uri
          link.style = 'visibility:hidden'
          link.download = fileName + '.csv'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          Swal.fire({
            title: "Success!",
            text: "Export Report Successful",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          } else {
              Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        },

      },
    };
</script>
