<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0">
          <div class="container-fluid px-0">
            <div class="row breadcrumbs-top">
              <div class="col-md-9">
                <h3 class="content-header-title float-left mb-0">Domain Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                      <router-link :to="{ name: 'DomainList', params: { search: true }}"
                      >Domain Management</router-link
                      >
                    </li>
                    <li class="breadcrumb-item"><router-link to='/domain/create'>Create</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-1">
            <form class="validate-form">
              <div class="row">
                <div class="col-12">
                  <template v-for="(domain, index) in domain.formdata">
                    <div v-bind:key="index" class="card border-warning" style=" margin-bottom: 1rem !important;">
                      <div class="card-header p-1 cursor-pointer" v-on:click="handleCollapse(index)" style="border-radius: 10px;">
                        <h4 class="card-title text-primary domain_header_bg_03" style="" >Add Domain {{index + 1}}</h4>
                        <div class="heading-elements text-primary" >
                          <ul class="list-inline mb-0">
                            <li>
                              <a data-action="collapse" v-if="!domain.showform"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                              <a data-action="collapse" v-else><minus-icon size="4x" class="custom-class"></minus-icon></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div v-bind:class="[{show: domain.showform},card_content,collapse]">
                        <div class="card-body">
                          <form>
                            <div class="row">
                              <div class="form-group col-md-4">
                                <label for="domainname">Domain Name</label>
                                <input type="text" class="form-control" name="domainname" placeholder="Domain Name" v-model="domain.domainname" @blur="validateDomain(index, $event)"/>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].domainname">{{ errors.domain.formdata[index].domainname }}</div>
                              </div>
                              <div class="form-group col-md-4">
                                <label for="registrar">Domain Registrar</label>
                                <input type="text" class="form-control" name="registrar" placeholder="Domain Registrar Name" v-model="domain.registrar" @blur="validateDomain(index, $event)"/>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].registrar">{{ errors.domain.formdata[index].registrar }}</div>
                              </div>
                              <div class="form-group col-md-4">
                                <label for="website">Website</label>
                                <input type="text" class="form-control" name="website" placeholder="Website" v-model="domain.website" @blur="validateDomain(index, $event)"/>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].website">{{ errors.domain.formdata[index].website }}</div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-4">
                                <label for="country">Country</label>
                                <treeselect placeholder="Select Country" class="projectlistdd" v-model="domain.country" :options="countrylist" @input="selectCountry($event, index)" @close="validateDomain(index, $event)"/>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].country">{{ errors.domain.formdata[index].country }}</div>
                              </div>
                              <div class="form-group col-md-4">
                                <label for="ownername">Domain Owner Name</label>
                                <input type="text" class="form-control" name="ownername" placeholder="Domain Owner Name" v-model="domain.ownername" @blur="validateDomain(index, $event)"/>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].ownername">{{ errors.domain.formdata[index].ownername }}</div>
                              </div>
                              <div class="form-group mg-bot0 col-md-4 datePicker">
                                <label for="purchasedate" class="d-block">Domain Purchase Date</label>
                                <date-picker placeholder="Select Domain Purchase Date" v-model="domain.purchasedate" valueType="format" class="startdate" 
                                :disabled-date="notBeforeToday" @input="selectPurchaseDate($event, index)" @close="validateDomain(index, $event)"></date-picker>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].purchasedate">{{ errors.domain.formdata[index].purchasedate }}</div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group mg-bot0 col-md-4 datePicker">
                                <label for="expirydate" class="d-block">Domain Expiry Date</label>
                                <date-picker placeholder="Select Domain expiry Date" v-model="domain.expirydate" valueType="format" class="startdate" 
                                :default-value="new Date()" :disabled-date="disabledBefore" @input="selectExpiryDate($event, index)" @close="validateDomain(index, $event)"></date-picker>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].expirydate">{{ errors.domain.formdata[index].expirydate }}</div>
                              </div>
                              <div class="form-group col-md-4">
                                <label for="purchaseapprovalname">Domain Purchase Approval Name</label>
                                <input type="text" class="form-control" name="purchaseapprovalname" placeholder="Domain Purchase Approval Name" v-model="domain.purchaseapprovalname" @blur="validateDomain(index, $event)"/>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].purchaseapprovalname">{{ errors.domain.formdata[index].purchaseapprovalname }}</div>
                              </div>
                              <div class="form-group col-md-4">
                                <label for="renewalapprovalname">Domain Renewal Approval Name</label>
                                <input type="text" class="form-control" name="renewalapprovalname" placeholder="Domain Renewal Approval Name" v-model="domain.renewalapprovalname" @blur="validateDomain(index, $event)"/>
                                <div class="errorinputmsg" v-if="errors.domain.formdata[index].renewalapprovalname">{{ errors.domain.formdata[index].renewalapprovalname }}</div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-4">
                                <label for="purchasequantity">Purchase Quantity</label>
                                <input type="number" @keypress="validNumber" class="form-control" name="purchasequantity" placeholder="Purchase Quantity" v-model="domain.purchasequantity" />
                              </div>
                              <div class="form-group col-md-4">
                                <label for="usedquantity">Used Quantity</label>
                                <input type="number" @keypress="validNumber" class="form-control" name="usedquantity" placeholder="Used Quantity" v-model="domain.usedquantity"/>
                              </div>
                            </div>
                            <div class=" container text-right px-0 mt-1">
                              <div class="btn btn-outline-primary waves-effect waves-effect ml-1 mt-1" @click="addDomainForm">
                                <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                <span>Add another Domain</span>
                              </div>
                              <button type="submit" class="btn btn-relief-primary ml-1 mt-1" style="padding-left: 2rem; padding-right:2rem" v-on:click.stop.prevent="saveData()">Submit</button>
                              
                            
                            
                              <div class="btn btn-outline-info ml-1 mt-1" style="padding-left: 1.4rem; padding-right: 1.4rem" v-on:mousedown="clearField(index)">
                                <span>Clear</span>
                              </div>
                              <div v-if="index !== 0" class="btn btn-outline-danger ml-1 mt-1"  style="padding-left: 1rem; padding-right: 1rem" @click="deleteField(index)">
                                <span>Delete</span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </form>
          </div>
          
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import DatePicker from "vue2-datepicker";
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import "vue2-datepicker/index.css";
import { PlusIcon, StarIcon, MinusIcon } from 'vue-feather-icons'
import moment from "moment";
import apiUrl from "../../constants";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'DomainCreate',
  components: {
    VueElementLoading,
    Treeselect,
    DatePicker,
    Loading,
    PlusIcon,
    MinusIcon,
    StarIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      domain: {
        formdata: [
          {
            domainname: null,
            registrar: null,
            website: null,
            country: null,
            ownername: null,
            purchasedate: null,
            expirydate: null,
            purchaseapprovalname: null,
            renewalapprovalname: null,
            showform: true,
            purchasequantity: null,
            usedquantity: null
          }
        ]
      },
      countrylist: [
         { label: 'China', id: 'China' },
         { label: 'GCC', id: 'GCC' },
         { label: 'Global', id: 'Global' },
          { label: 'India', id: 'India' },
          { label: 'Kuwait', id: 'Kuwait' },
          { label: 'KSA', id: 'KSA' },
          { label: 'Oman', id: 'Oman' },
          { label: 'UAE', id: 'UAE' },
      ],
      errors: {
        domain: {
          formdata: [
            {
              domainname: null,
              registrar: null,
              website: null,
              country: null,
              ownername: null,
              purchasedate: null,
              expirydate: null,
              purchaseapprovalname: null,
              renewalapprovalname: null,
              showform: true,
              purchasequantity: null,
              usedquantity: null
            }
          ]
        }
      },
      isLoading: false,
      card_content:'card-content',
      collapse:'collapse',
      tab_pane:'tab-pane', 
      fade:'fade',
      TimeFormat: apiUrl.TimeFormat,
    }
  },
  mounted() {
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
      }
  },
  methods: {
      validNumber : event => {
      let keyCode = event.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      },
    notBeforeToday: function (date) {
      this.domain.expirydate = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));     
    },
    disabledBefore(date,idx) {
      if(!this.domain.formdata[0].purchasedate) return false;
      let dayBefore = moment(this.domain.formdata[0].purchasedate).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    handleCollapse(idx) {
      this.domain.formdata[idx].showform = !this.domain.formdata[idx].showform
    },
    selectCountry(state, idx) {
      if(!state) {
        this.errors.domain.formdata[idx].country = `Country is required`
      } else {
        this.errors.domain.formdata[idx].country = ``
      }
    },
    selectPurchaseDate(state, idx) {
      if(!state) {
        this.errors.domain.formdata[idx].purchasedate = `Purchasedate is required`
      } else {
        this.errors.domain.formdata[idx].purchasedate = ``
      }
    },
    selectExpiryDate(state, idx) {
      if(!state) {
        this.errors.domain.formdata[idx].expirydate = `Expirydate is required`
      } else {
        this.errors.domain.formdata[idx].expirydate = ``
      }
    },
    clearField(idx) {
      this.domain.formdata[idx].domainname = null
      this.domain.formdata[idx].registrar = null
      this.domain.formdata[idx].website = null
      this.domain.formdata[idx].country = null
      this.domain.formdata[idx].ownername = null
      this.domain.formdata[idx].purchasedate = null
      this.domain.formdata[idx].expirydate = null
      this.domain.formdata[idx].purchaseapprovalname = null
      this.domain.formdata[idx].renewalapprovalname = null
      this.domain.formdata[idx].purchasequantity = null
      this.domain.formdata[idx].usedquantity = null
      this.errors.domain.formdata[idx].domainname = ``
      this.errors.domain.formdata[idx].registrar = ``
      this.errors.domain.formdata[idx].ownername = ``
      this.errors.domain.formdata[idx].website = ``
      this.errors.domain.formdata[idx].country = ``
      this.errors.domain.formdata[idx].purchasedate = ``
      this.errors.domain.formdata[idx].expirydate = ``
      this.errors.domain.formdata[idx].purchaseapprovalname = ``
      this.errors.domain.formdata[idx].renewalapprovalname = ``
      this.errors.domain.formdata[idx].purchasequantity = ``
      this.errors.domain.formdata[idx].usedquantity = ``
    },
    deleteField(idx) {
      this.domain.formdata.splice(idx, 1)
      this.errors.domain.formdata.splice(idx, 1)
    },
    validateAllFormData() {
      let valid = true
      for(let idx=0; idx<this.domain.formdata.length; idx++) {
        let singleDomainForm = this.domain.formdata[idx]
        if(!singleDomainForm.domainname) {
          this.errors.domain.formdata[idx].domainname = `Domain name is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].domainname = ``
        }
        if(!singleDomainForm.registrar) {
          this.errors.domain.formdata[idx].registrar = `Registrar name is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].registrar = ``
        }
        if(!singleDomainForm.website) {
          this.errors.domain.formdata[idx].website = `Website is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].website = ``
        }
        if(!singleDomainForm.country) {
          this.errors.domain.formdata[idx].country = `Country is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].country = ``
        }
        if(!singleDomainForm.ownername) {
          this.errors.domain.formdata[idx].ownername = `Domain owner name is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].ownername = ``
        }
        if(!singleDomainForm.purchasedate) {
          this.errors.domain.formdata[idx].purchasedate = `Purchase date is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].purchasedate = ``
        }
        if(!singleDomainForm.expirydate) {
          this.errors.domain.formdata[idx].expirydate = `Expiry date is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].expirydate = ``
        }
        if(!singleDomainForm.purchaseapprovalname) {
          this.errors.domain.formdata[idx].purchaseapprovalname = `Purchase approval name is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].purchaseapprovalname = ``
        }
        if(!singleDomainForm.renewalapprovalname) {
          this.errors.domain.formdata[idx].renewalapprovalname = `Renewal approval name is required`
          valid = false
        } else {
          this.errors.domain.formdata[idx].renewalapprovalname = ``
        }
      }
      return valid
    },
    validateDomain(idx) {
      let valid = true
      if(!this.domain.formdata[idx].domainname) {
        this.errors.domain.formdata[idx].domainname = `Domain name is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].domainname = ``
      }
      if(!this.domain.formdata[idx].registrar) {
        this.errors.domain.formdata[idx].registrar = `Domain registrar name is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].registrar = ``
      }
      if(!this.domain.formdata[idx].website) {
        this.errors.domain.formdata[idx].website = `Website is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].website = ``
      }
      if(!this.domain.formdata[idx].country) {
        this.errors.domain.formdata[idx].country = `Country is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].country = ``
      }
      if(!this.domain.formdata[idx].ownername) {
        this.errors.domain.formdata[idx].ownername = `Domain ownername name is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].ownername = ``
      }
      if(!this.domain.formdata[idx].purchasedate) {
        this.errors.domain.formdata[idx].purchasedate = `Purchasedate is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].purchasedate = ``
      }
      if(!this.domain.formdata[idx].expirydate) {
        this.errors.domain.formdata[idx].expirydate = `Expirydate is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].expirydate = ``
      }
      if(!this.domain.formdata[idx].purchaseapprovalname) {
        this.errors.domain.formdata[idx].purchaseapprovalname = `Purchase approvalname name is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].purchaseapprovalname = ``
      }
      if(!this.domain.formdata[idx].renewalapprovalname) {
        this.errors.domain.formdata[idx].renewalapprovalname = `Renewal approval name is required`
        valid = false
      } else {
        this.errors.domain.formdata[idx].renewalapprovalname = ``
      }
      return valid
    },
    addDomainForm() {
      this.domain.formdata.push({
        domainname: null,
        registrar: null,
        website: null,
        country: null,
        ownername: null,
        purchasedate: null,
        expirydate: null,
        renewalapprovalname: null,
        showform: true,
      })
      this.errors.domain.formdata.push({
        domainname: null,
        registrar: null,
        website: null,
        country: null,
        ownername: null,
        purchasedate: null,
        expirydate: null,
        renewalapprovalname: null,
        showform: true,
      })
    },
    saveData() {
      let isValid = this.validateAllFormData()
      if(isValid) {
        this.isLoading = true
        let url = 'api/domain/create'
        let payload = {
          createdby: this.undt.username,
          domainData: this.domain.formdata,
          useremail  : this.undt.username,
          empcode: this.undt.userid
        }
        axios({
          method: 'POST',
          url,
          data: payload,
          headers: { authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
            this.$router.push({ name: 'DomainList', params: { search: true }});
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
  }
}
</script>