<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12 mobile_view_padd-05">
                                <h3 class="content-header-title float-left mb-0">Payment Invoice Report</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/mydashboard'>My Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/paymentinvoicereport'>Payment Invoice Report</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                    <div class="avatar-content">
                                                    <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                </div>
                                            </div>
                                            <h4 class="card-title d-inline-flex ml-1">Search Payment Invoice Report</h4>
                                            </div>
                                           
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="form-group col-xs-12 col-sm-6 col-md-4 mb-1">
                                              <label class="form-label" for="vertical-modern-landmark"
                                                >From Date</label
                                              >
                                              <div class="input-group input-group-merge">
                                                <date-picker
                                                  placeholder="Select From Date"
                                                  v-model="search.fromdate"
                                                  :disabled-date="notBeforeToday"
                                                  valueType="format"
                                                  class="Startdate"
                                                  @input="selectedfromdate"
                                                ></date-picker>
                                                <div class="errorinputmsg" v-if="isBothDateSelected">
                                                  Please select both dates to search or export
                                                  reports.
                                                </div>
                                              </div>
                                            </div>
                                            <div class="form-group col-xs-12 col-sm-6 col-md-4 mb-1">
                                              <label class="form-label" for="vertical-modern-landmark"
                                                >To Date</label
                                              >
                                              <div class="input-group input-group-merge">
                                                <date-picker
                                                  placeholder="Select To Date"
                                                  v-model="search.todate"
                                                  valueType="format"
                                                  :default-value="new Date()"
                                                  :disabled-date="disabledBefore"
                                                  @input="selectedtodate"
                                                ></date-picker>
                                              </div>
                                            </div>
                                            <div class="col-xs-12 col-sm-6 col-md-4  mb-1">
                                                <label class="form-label">Request ID	 </label>
                                                <input class="form-control" type="text" placeholder="Enter Request ID" v-model="search.requestid"/>
                                            </div>
                                            <div class="col-xs-12 col-sm-6 col-md-4 mb-1">
                                                <label class="form-label">Status</label>
                                                <treeselect placeholder="Select Ticket Status" v-model="search.status"  :options="statuslist" />
                                            </div>
                                            <div class=" col-xs-12 col-sm-12 col-md-6 common_trs_buttons_res">
                                                <button type="submit" class="btn btn-primary mt-2 mr-1" @click="getPaymentInvoicelist(search)" >Search</button>
                                                <button type="button" class="btn btn-primary mt-2 mr-1" @click="paymentInvoiceExport(search)" >Export</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="mysearchrequestollapse" @click="resetRecords()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                    <div class="avatar-content">
                                                    <clipboard-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></clipboard-icon>
                                                   </div>
                                                </div>
                                                <h4 class="card-title d-inline-flex ml-1">Payment Invoice Report Details</h4>
                                            </div>
                                           
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body px-0 pt-0 full_main_containers_mobile">
                                        <div class="row ">
                                            <b-container fluid class="table-responsive_view">
                                                <!-- Main table element -->
                                                <div class="full_main_containers table_width_col paymentinvoices">
                                                <b-table class="table table-sm dataTableListing table-striped theade_padding_y10"
                                                :items="items"
                                                :fields="fields"
                                                :current-page="currentPage"
                                                :per-page="perPage"
                                                :filter="filter"
                                                :filter-included-fields="filterOn"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :sort-direction="sortDirection"
                                                stacked="md  new_admin_panel_ticket"
                                                show-empty
                                                small
                                                @filtered="onFiltered"
                                                v-model="currentItems"
                                                
                                                >
                                                <template v-slot:cell(actions)="{ detailsShowing, item }">
                                                    <b-btn class="show_details_btn01" size="sm" @click="toggleDetails(item);onRowSelected(item);">{{ detailsShowing ? 'Hide' : 'Show'}} Details</b-btn>
                                                </template>
                                                
                                                
                                                <template #row-details="row" class="w-100">
                                                    <b-card class="p-0 card_travels_mobile_veiw mb-0">
                                                        <div class="table-responsive" style="max-width: 100vh; width: 100%; min-width: 100%;">
                                                            <div id="app" class="table_responce44  card_travels_full_border">    
                                                                <span v-if="traveldetails.length ==0 && hoteldetails.length==0"> 
                                                                No Records Found
                                                            </span>
    
                                                            <b-row  class="mx-0 inner_table_inlie_view mb-1 mt-0 w-100 " v-if="traveldetails.length >0">
                                                                <b-col class="travel_request_detailshead_bg ">
                                                                    <span class="details_travels_fc_svgs"> 
                                                                        <b-icon icon="bank" scale="2" class="inner_icons_table_request_table" variant="warning"></b-icon> 
                                                                    </span>
                                                                    
                                                                    <span class="details_travels_fc_text"> Travel Details  </span> 
                                                                </b-col>
                                                            </b-row>
                                                            <b-table-simple responsive v-if="traveldetails.length >0">
                                                            <b-thead>
                                                                <b-tr>
                                                                    <b-th>Index</b-th>
                                                                    <b-th class="text-left" style="min-width:180px;">From City - To city</b-th>
                                                                    <b-th>Mode of Transport</b-th>
                                                                    <b-th class="text-left" style="min-width: 250px;">Date Details</b-th>
                                                                    <!-- <b-th style="min-width:170px;text-align: left;">Booking Date</b-th>
                                                                    <b-th style="min-width:170px!important;text-align: left;">Travel Date</b-th> -->
                                                                    <b-th class="text-left" style="min-width: 230px!important;">Bill Details</b-th>
                                                                    <!-- <b-th style="text-align: left;">Bill Number</b-th>
                                                                    <b-th style="min-width:170px;text-align: left;">Bill Date</b-th>                                                                 -->
                                                                    <b-th>Company Name</b-th>
                                                                    <b-th>PNR</b-th>
                                                                    <b-th>Fare Amount</b-th>
                                                                    <b-th>GST</b-th>
                                                                    <b-th>Total Payable</b-th>
                                                                    <b-th>Ticket Docs</b-th>
                                                                </b-tr>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr v-for="(data,index) in traveldetails" :key="index">
                                                                    <b-td>{{index+1}}</b-td>
                                                                    <b-td class="text-left">
                                                                        <span v-if="data.fromcity != null && data.tocity != null">{{data.fromcity}} To {{data.tocity}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="data.modeoftravel != null">                                                    
                                                                            {{data.modeoftravel}}
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    
                                                                    <b-td class="text-left details_column_table">
                                                                        <span class="heading_column_text"> Booking Date : </span>
                                                                        <span v-if="data.bookingdate != null">                                                    
                                                                            {{data.bookingdate}}
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                        <br/>
                                                                        <span class="heading_column_text"> Travel Date: </span>
                                                                        <span v-if="data.traveldate != null">{{data.traveldate}}</span>
                                                                        <span v-else>N/A</span>
    
                                                                    </b-td>
                                                                   
                                                                    <b-td class="text-left details_column_table">
                                                                        <span class="heading_column_text"> Bill Number : </span>
                                                                        <span v-if="data.billnumber != null">{{data.billnumber}}</span>
                                                                        <span v-else>N/A</span>
                                                                        <br/>
                                                                        <span class="heading_column_text"> Bill Date : </span>
                                                                        <span v-if="data.billdate">{{data.billdate}}</span>
                                                                        <span v-else>N/A</span>
    
                                                                    </b-td>
                                                                    
                                                                    <b-td>
                                                                        <span v-if="data.companyname">{{data.companyname}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>                                                                
                                                                    <b-td>                                                                    
                                                                        <span v-if="data.pnr">{{data.pnr}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>                                                                    
                                                                        <span v-if="data.fareamt">{{data.fareamt}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>                                                                    
                                                                        <span v-if="data.gst">{{data.gst}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>                                                                    
                                                                        <span v-if="data.totalpayable">{{data.totalpayable}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <div class="card" v-if="data.invoice.length>0">
                                                                            <div class="card-body">
                                                                               
                                                                                <div class="row">
                                                                                    <div class="table-responsive">
                                                                                        <table aria-describedby="invoiceDocList" class="table table-sm table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th scope="col">SR#</th>
                                                                                                    <th scope="col">Filename</th>
                                                                                                    <th scope="col">Action</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr v-for="(doc,i) in data.invoice" :key="i" >
                                                                                                    <td> {{i+1}}</td>
                                                                                                    <td>{{doc.filename}}</td>
                                                                                                    <td style="text-align: center!important;">
                                                                                                        <span v-if="doc.path!=''">
                                                                                                            <div class="avatar bg-info rounded m-1">
                                                                                                                <div class="avatar-content">
                                                                                                                    <a @click="getDownloadedLink(doc)" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white"></download-icon></a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>                                                                                                
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-td>                                                                                                                               
                                                                </b-tr>
                                                            </b-tbody> 
                                                            </b-table-simple>
                                                            <b-row  class="mx-0 inner_table_inlie_view mb-2 w-100" v-if="hoteldetails.length >0">
                                                                <b-col class="travel_request_detailshead_bg">
                                                                    <span class="details_travels_fc_svgs"> 
                                                                    <b-icon icon="building" scale="2" class="inner_icons_table_request_table" variant="warning"></b-icon>
                                                                    </span>
                                                                    <span class="details_travels_fc_text"> Hotel Details </span>
                                                                </b-col>
                                                            </b-row>
                                                            <b-table-simple responsive v-if="hoteldetails.length>0">                                                            
                                                            <b-thead>
                                                                <b-tr>
                                                                    <b-th>Index</b-th>
                                                                    <b-th>Hotel Name</b-th>
                                                                    <b-th style="min-width: 180px;">Location</b-th>
                                                                    <b-th class="text-left" style="min-width: 250px;">Date Details</b-th>
                                                                   
                                                                    <b-th>No Of Nights</b-th>
                                                                    <b-th>Rate Per Day</b-th>                                                                
                                                                    <b-th>Total <br/> Amount</b-th>
                                                                    <b-th>GST</b-th>
                                                                    <b-th>Total Payable</b-th>
                                                                    <b-th>Ticket Docs</b-th>
                                                                </b-tr>
                                                            </b-thead>
                                                            <b-tbody>
                                                                <b-tr v-for="(data,index) in hoteldetails" :key="index">
    
                                                                    <b-td>{{index+1}}</b-td>
                                                                    <b-td>
                                                                        <span v-if="data.hotelname != null">{{data.hotelname}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="data.location != null">                                                    
                                                                            {{data.location}}
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    
                                                                    <b-td class="text-left details_column_table">
                                                                        <span class="heading_column_text"> Checkin Date : </span>
                                                                        <span v-if="data.checkindate != null">                                                    
                                                                            {{data.checkindate}}
                                                                        </span>
                                                                        <span v-else>N/A</span>
                                                                        <br/>
                                                                        <span class="heading_column_text"> Checkout Date : </span>
                                                                        <span v-if="data.checkoutdate != null">{{data.checkoutdate}}</span>
                                                                        <span v-else>N/A</span>
    
                                                                    </b-td>
                                                                    
                                                                    <b-td>
                                                                        <span v-if="data.noofnights != null">{{data.noofnights}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="data.rateperday">{{data.rateperday}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <span v-if="data.totalamount">{{data.totalamount}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>                                                                    
                                                                        <span v-if="data.gst">{{data.gst}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>                                                                    
                                                                        <span v-if="data.totalpayable">{{data.totalpayable}}</span>
                                                                        <span v-else>N/A</span>
                                                                    </b-td>
                                                                    <b-td>
                                                                        <div class="card" v-if="data.invoice.length>0">
                                                                            <div class="card-body">
                                                                                <h5 class="mb-1">Document List</h5>
                                                                                <div class="row">
                                                                                    <div class="table-responsive">
                                                                                        <table aria-describedby="invDocList" class="table table-sm table-bordered">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th scope="col">SR#</th>
                                                                                                    <th scope="col">Filename</th>
                                                                                                    <th scope="col">Action</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr v-for="(doc,i) in data.invoice" :key="i" >
                                                                                                    <td> {{i+1}}</td>
                                                                                                    <td>{{doc.filename}}</td>
                                                                                                    <td style="text-align: center!important;">
                                                                                                        <span v-if="doc.path!=''">
                                                                                                            <div class="avatar bg-info rounded m-1">
                                                                                                                <div class="avatar-content">
                                                                                                                    <a @click="getDownloadedLink(doc)" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white"></download-icon></a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>                                                                                                
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-td>                                                                                                                               
                                                                </b-tr>
                                                            </b-tbody> 
                                                            </b-table-simple>
                                                            </div>
                                                        </div>
                                                    </b-card> 
                                                </template>
                                                
                                                </b-table>
                                                </div>
                                                <!-- User Interface controls -->
                                                <b-row class="pagination_view_con mt-2 m-0">
                                                <b-col class="my-1 pull-right pagination_view_co">
                                                    <b-pagination
                                                    v-model="currentPage"
                                                    :total-rows="totalRows"
                                                    :per-page="perPage"
                                                    align="fill"
                                                    size="sm"
                                                    class="my-0"
                                                    ></b-pagination>
                                                </b-col>
                                                </b-row>
                                            </b-container>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import { PlusIcon,AlertCircleIcon } from 'vue-feather-icons'
    import { PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,CheckSquareIcon,
    MessageCircleIcon,XSquareIcon,
    SearchIcon,PlusCircleIcon,ClipboardIcon,UserIcon,MapPinIcon,InfoIcon,MoreVerticalIcon,EditIcon,EyeIcon,UploadIcon,PowerIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon } from 'vue-feather-icons'
    // Import component
    import Loading from 'vue-loading-overlay';
     // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Pagination from "vue-pagination-2";
    import DatePicker from "vue2-datepicker";
    import apiUrl from "../../../constants";
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh } from 'bootstrap-vue'
    export default({
        name:'PaymentInvoiceReport',
        
        data() {
            return {
                isBothDateSelected: false,
                tokendata: null,
                totalcount: 0,
                pageCount: 0,
                page: 1,
                pagelimit: apiUrl.LIMIT,
                currPageNum: 0,
                pageoffset: 1,
                hodmastercountClick:false,
                showtabledata: true,
                ticketdetails:[],
                isLoading: false,
                fullPage: true,
                search:{
                    requestid:'',
                    status:null,
                    fromdate: "",
                    todate: "",
                },
                statuslist:[
                    {
                    id:'0',
                    label:'Pending'
                    },
                    {
                        id:'2',
                        label:'Approved'
                    },
                    {
                        id:'4',
                        label:'Rejected'
                    },
                    {
                        id:'5',
                        label:'File Uploaded'
                    },
                    {
                        id:'6',
                        label:'Closed'
                    },
                    {
                        id:'7',
                        label:'payment'
                    },
                    {
                        id:'8',
                        label:'Cancellation'
                    }

                ],
                traveldocument:[{filename:'',description:'',attachemnt:''}],
                emptypeBlured:false,
                pagefeatures:[],
                requestList:[],
                isDetActive:true,
                isRequestActive:false,
                isSearchRequestActive:false,
                card_content:'card-content',
                collapse:'collapse',
                isActiveEndDate:false,
                showmanager:true,
                transportList:[],
                managerid:'',
                visitname:'',
                visitlocation:'',
                purpose:'',
                startdate:'',
                enddate:'',
                file: [],
                doc: [],
                traveldetails:[],
                hoteldetails:[],
                traveldocumentsupload: [],
                editdata:{},
                attachment:[],
                ticketreq: {
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:''
                },
                ticketcreatereq: {
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:''
                },
                istravel: false,
                ishotel: false,
                loguser:'',
                items:[],
                fields: [
                    { key: 'ticketid', label: 'Request ID' },
                    { key: 'employname', label: 'Travel Name', thClass: 'travelName_ht130', tdClass: 'travelName_td130'},
                    { key: 'department', label: 'Department'},
                    { key: 'companyname', label: 'Company Name'},
                    { key: 'visitname', label: 'Visit Name', class: 'visit_th_width_tral180'},
                    { key: 'purpose', label: 'Purpose'},
                    { key: 'startdate', label: 'Start Date'},
                    { key: 'enddate', label: 'End Date'},
                    {
                    key: 'status',
                    label: 'status',
                    formatter: (value, key, item) => {
                        if(value == 0) {
                            return 'Pending'
                        } else if(value == 1) {
                            return 'Draft'
                        } else if(value == 2) {
                            return 'Approved'
                        } else if(value == 3) {
                            return 'Feedback'
                        } else if(value == 4) {
                            return 'Rejected'
                        } else if(value == 5) {
                            return 'File Uploaded'
                        } else if(value == 6) {
                            return 'Closed'
                        } else if(value == 7) {
                            return 'Revoked'
                        } else if(value == 8) {
                            return 'Cancellation'
                        }
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true
                },
                    { key: 'actions', label: 'Actions' }             
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 21,
               
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                currentItems: [],
                groputravelinfo:[]
            }  
        } ,
        components: {
            PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
            EditIcon,CheckSquareIcon,MessageCircleIcon,XSquareIcon,
            EyeIcon,
            MoreVerticalIcon,
            Loading,
            PlusIcon,
            SearchIcon,
            PlusCircleIcon,
            ClipboardIcon,
            UserIcon,
            MapPinIcon,
            InfoIcon,
            UploadIcon,
            PowerIcon,
            PlusSquareIcon,
            MinusSquareIcon,
            DownloadIcon,
            Pagination,
            Treeselect,
            BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh,
            AlertCircleIcon,
            DatePicker
        },
         computed: {
          sortOptions() {
            // Create an options list from our fields
            return this.fields
              .filter(f => f.sortable)
              .map(f => {
                return { text: f.label, value: f.key }
              })
          }
        },
        created() {
            this.pagefeatures = {}
            var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/paymentinvoicereport') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        },
        mounted(){
            
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.empid = userdata.userid
                this.loguser = userdata.firstname+' '+userdata.lastname
                this.getprofiledata()
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
            this.getPaymentInvoicelist(null,0)       
        },
        
        methods:{
            notBeforeToday: function (date) {
              this.search.todate = "";
              return date > new Date(new Date().setHours(0, 0, 0, 0));
            },
            disabledBefore(date) {
              return date <  new Date(new Date(this.search.fromdate).setHours(0, 0, 0, 0));
            },
            selectedfromdate() {
              if (
                (this.search.fromdate && this.search.todate) ||
                (!this.search.fromdate && !this.search.todate)
              ) {
                this.isBothDateSelected = false;
              } else if (
                (this.search.fromdate && !this.search.todate) ||
                (!this.search.fromdate && this.search.todate)
              ) {
                this.isBothDateSelected = true;
              }
            },
            selectedtodate(state) {
              if (
                (this.search.fromdate && this.search.todate) ||
                (!this.search.fromdate && !this.search.todate)
              ) {
                this.isBothDateSelected = false;
              } else if (
                (this.search.fromdate && !this.search.todate) ||
                (!this.search.fromdate && this.search.todate)
              ) {
                this.isBothDateSelected = true;
              }
            },
            paymentInvoiceExport(searchObj) {
                if((searchObj.status == null && searchObj.fromdate == '' && searchObj.todate == '' && searchObj.requestid == '') || (searchObj.status == null && searchObj.fromdate == null && searchObj.todate == null && searchObj.requestid == null)) {
                    Swal.fire({
                        title: "",
                        text: "Please select at least one filter",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                }
                if(this.isBothDateSelected ) {
                     Swal.fire({
                        title: "",
                        text: "Both dates should be selected",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                }
    
                if(searchObj.fromdate && searchObj.todate) {
                    let days = moment(searchObj.todate,"YYYY-MM-DD").diff(moment(searchObj.fromdate,"YYYY-MM-DD"), 'days');
                    if(days>30) {
                        Swal.fire({
                        title: "",
                        text: "Date should be select within 30 days",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return false;
                    }
                    
                }            
                
              this.isLoading = true;
              let payload = {
                empcode: this.empid,
                useremail: this.username,
              };
              if (searchObj){
                payload.ticketid=searchObj.requestid ==null ?'':searchObj.requestid,
                payload.status=searchObj.status == null ? '':searchObj.status
                payload.fromdate=searchObj.fromdate == null ? '':searchObj.fromdate
                payload.todate=searchObj.todate == null ? '':searchObj.todate
              }
              
              axios({
                method: "POST",
                url: `api/reports/exportPaymentInvoiceReport`,
                data: payload,
                headers: { authorization: this.tokendata },
              }).then((result) => {
                this.isLoading = false;
                this.isBothDateSelected = false
                if (result.data.errorCode == 0) {
                  let datalength = result.data.data.responsedata;
                  let filename = "paymentinvoicereport";
                  this.download(datalength, filename);
                } else {
                  Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                  });
                }
              });
            },
    
            download(readExportdata, filename) {
              if (readExportdata.length > 0) {
                let ShowLabel = filename;
                let JSONData = readExportdata;
                let arrData =
                  typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
                var CSV = "";
                if (ShowLabel) {
                  let row = "";
                  for (let index in arrData[0]) {
                    row += index + ",";
                  }
                  row = row.slice(0, -1);
                  CSV =
                    ",Emp. Details,,,,,Travel Tickets,,,,,,,ACCOMODATION,,,,,,Basic details,\r\nName,Emp Id,Deparment,TicketID,Request Date,Travel Date,Travel Mode,From,To,PNR,Fare,Total Amount,Hotel Name,Location,Check in,Check Out,Total Nights,Rate per Day,Total Amount,L1 Approval,L2 Approval,Booked By(Travel Desk person),Company(BB/DARP)\r\n";
                }
                var row = "";
                for (const index of arrData) {
                  let employname = index.employname ? index.employname : "";
                  let empid = index.empid ? index.empid : "";
                  let department = index.department ? index.department : "";
                  let ticketid = index.ticketid ? index.ticketid : "";
                  let requestdate = index.requestdate ? index.requestdate : "";
                  let traveldate = index.traveldate ? index.traveldate : "";
                  let modeoftransport = index.modeoftransport ? index.modeoftransport : "";
                  let fromcityname = index.fromcityname ? index.fromcityname : "";
                  let cityname = index.cityname ? index.cityname : "";
    
                  let pnr = index.pnr ? index.pnr : "";
                  let fareamt = index.fareamt ? index.fareamt : "";
                  let totalpayable = index.totalpayable ? index.totalpayable : "";              
                  let hotelname = index.hotelname ? index.hotelname : "";
                  let location = index.location ? index.location : "";
                  let checkindate = index.checkindate ? index.checkindate : "";
                  let checkoutdate = index.checkoutdate ? index.checkoutdate : "";
                  let noofnights = index.noofnights ? index.noofnights : "";
                  let rateperday = index.rateperday ? index.rateperday : "";
                  let totalamount = index.totalamount ? index.totalamount : "";
    
                  let l1 = index.l1 ? index.l1 : "";
                  let l2 ='N/A';
                  if(index.new_entry !='1') {
                    l2 = index.l2 ? index.l2 : "";  
                  } 
                  let bookedby = index.bookedby ? index.bookedby : "";
                  let companyname = index.companyname ? index.companyname : "";
                  
                  (row += '"' + employname + '",'),
                  (row += '"' + empid + '",'),
                  (row += '"' + department + '",'),
                  (row += '"' + ticketid + '",'),              
                  (row += '"' + requestdate + '",');
                  (row += '"' + traveldate + '",'),
                  (row += '"' + modeoftransport + '",'),
                  (row += '"' + fromcityname + '",'),
                  (row += '"' + cityname + '",'),
                  (row += '"' + pnr + '",'),
                  (row += '"' + fareamt + '",'),
                  (row += '"' + totalpayable + '",'),
                  (row += '"' + hotelname + '",'),
                  (row += '"' + location + '",'),
                  (row += '"' + checkindate + '",'),
                  (row += '"' + checkoutdate + '",'),
                  (row += '"' + noofnights + '",'),
                  (row += '"' + rateperday + '",'),
                  (row += '"' + totalamount + '",'),
                  (row += '"' + l1 + '",'),
                  (row += '"' + l2 + '",'),
                  (row += '"' + bookedby + '",'),
                  (row += '"' + companyname + '"'),
                  (row += "\r\n");
                }
                CSV += row;
                if (CSV === "") {
                  Swal.fire({
                    title: "Error!",
                    text: "Invalid data",
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                  });
                  return;
                }
                let fileName =
                  "paymentinvoicereport_" + moment().format("DD_MM_YY_HH_mm_ss");
                let uri = "data:text/csv;charset=utf-8," + escape(CSV);
                let link = document.createElement("a");
                link.href = uri;
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                Swal.fire({
                  title: "Success!",
                  text: "Export Report successful",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                });
              } else {
                Swal.fire({
                  title: "Error!",
                  text: "No Records Found",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                });
              }
            },
            getDownloadedLink(data) {
                if(data) {
                    let payload = {
                        documents:[data],
                        empcode: this.empid,
                        useremail: this.username
                    };
                    this.apiURL="/api/reimbursement/getdownloadlink"
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':payload,
                        headers: { authorization: this.tokendata },
                    })
                    .then((result) => {
                        this.isLoading = false
                        if(result.data.status) {
                            
                            if(result.data.data[0].presignurl){
                                window.open(result.data.data[0].presignurl, '_blank')     
                            }
                        }
                    });
                }
            },
            getGroupTravelList(groupemp) {
                this.apiURL="api/listing/getemployeedetails"
                this.input={
                    empid: groupemp,
                    empcode: this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {
                    this.groputravelinfo=[]        
                    if(result.data.status){
                        this.groputravelinfo = result.data.data;
                        $("#grouptravelist").modal('show')
                        
                    }else{
                        this.groputravelinfo=[]
                        Swal.fire({
                            title: "Info!",
                            text: "Data Not Found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            },
            toggleTravelDetails(row) {
                if(row._showDetails){
                    this.$set(row, '_showDetails', false)
                }else{
                    this.currentItems.forEach(item => {
                    this.$set(item, '_showDetails', false)
                    })
    
                    this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                    })
                }
            },
            toggleDetails(row) {
                if(row._showDetails){
                    this.$set(row, '_showDetails', false)
                }else{
                    this.currentItems.forEach(item => {
                    this.$set(item, '_showDetails', false)
                    })
    
                    this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                    })
                }
            },
            onRowSelected(item) {
                this.getTravelTicketDetails(item.ticketid)
                this.getHotelTicketDetails(item.ticketid)
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            isDisabled: function(){
                return !this.terms;
            },
     
            resetRecords: function () {
                this.search.requestid = null;
                this.search.status = null;
                this.search.todate = null;
                this.search.fromdate = null;
                localStorage.removeItem('searchItems')
                this.getPaymentInvoicelist(null,0);
                this.validateForm()
            },
            validateForm(mdl){
                var isValid=true;
                if(mdl=="doc"){
                    for(const index in this.traveldocument){
                        if(!this.validText(this.traveldocument[index].filename) || !this.validText(this.traveldocument[index].description)){
                            isValid=false
                        }
                    }                
                }
                return isValid
            },
            validText : function(inputval) {
                var re = /[A-Za-z0-9].{5,}/;
                if(inputval){
                    return re.test(inputval.toLowerCase());
                }
            },
            getTravelTicketDetails(ticketid){
                this.istravel =true
                this.ishotel =false
                var userdata = window.localStorage.getItem('userdata');
                this.ticketdetails=[]
                this.ticketcreatereq={
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:''
                }
                if(userdata){
                    this.apiURL="api/reports/getTravelPaymentInvoiceTicketDetails"
                    this.input={
                        ticketid:ticketid,
                        empcode: this.empid,
                        useremail: this.username
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {             
                        if(result.data.status){
                            let response = result.data.data;
                            this.traveldetails = response                        
                        } else {
                            this.traveldetails =[]
                        }
                    })
                }
            },
            getHotelTicketDetails(ticketid){
                this.istravel =false
                this.ishotel =true
                var userdata = window.localStorage.getItem('userdata');
                this.ticketdetails=[]
                if(userdata){
                    this.apiURL="api/reports/getHotelPaymentInvoiceTicketDetails"
                    this.input={
                        ticketid:ticketid,
                        empcode: this.empid,
                        useremail: this.username
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {             
                        if(result.data.status){
                            let response = result.data.data;
                            this.hoteldetails = response
                            
                        } else {
                            this.hoteldetails = [];
                        }
                    })
                }
            },
            
            getprofiledata(){
                var userdata = window.localStorage.getItem('userdata');
                if(userdata){
                    userdata = JSON.parse(userdata)
                    this.empid = userdata.userid
                    this.apiURL="api/profile/getProfileDetails"
                    this.input={
                        empcode:this.empid,
                        useremail: this.email
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {           
                        if(result.data.status){
                            let response = result.data.data;
                            if(response.profilepic){
                                this.profileurl=response.profilepic
                            }
                            this.joiningdate=response.joiningdate
                            this.employeeid = response.empid
                            this.birthday = response.birthday
                            this.reportingto = response.reportto
                            this.year = response.year?(response.year+' yr ') : ''
                            this.month = response.month?response.month+' mos':''
                            this.totalexp =this.year+ ''+this.month
    
                            this.dept = response.designation+' ['+response.department+']'
                            this.email = response.emailid
                            this.address = response.city+','+response.state+','+response.country
                            this.mobilenumber = response.mobileno
                            this.firstname=response.firstname
                            this.lastname=response.lastname
                            this.l1 = response.l1
                            this.l2 = response.l2
                            this.ticketreq.empid=response.empid
                            this.ticketreq.empname = (this.firstname +' '+ this.lastname).toUpperCase()
                            this.ticketreq.designation = (response.designation).toUpperCase()
                            this.ticketreq.dept = (response.department).toUpperCase()
                        }
                    })
                }
            },
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },        
            getPaymentInvoicelist(searchObj,statusVal){
                this.requestList=[]
                var payload = {
                    empcode:this.empid,
                    useremail: this.username
                }
                this.isLoading = true;
                let apiURL='api/reports/getPaymentInvoiceReportDetails'  
                if (searchObj){
                    payload.ticketid=searchObj.requestid ==null ?'':searchObj.requestid,
                    payload.status=searchObj.status == null ? 0:searchObj.status
                    payload.fromdate=searchObj.fromdate == null ? '':searchObj.fromdate
                    payload.todate=searchObj.todate == null ? '':searchObj.todate
                }             
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data':payload,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {   
                    this.isLoading = false;          
                    if(result.data.status){
                        this.isDetActive=true;
                        this.items = result.data.data.responsedata;
                        this.totalRows = result.data.data.count
                    }else{
                        this.items = [];
                        this.isLoading= false;
                        this.totalRows = 0;
                    }
                })
    
            }
        }
    })
    </script>
    <style scoped>
    .display{
        display: none;
    }
    .mx-datepicker {
      width: 100% !important;
    }
    </style>