<template>
<div class="app-content content ">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-header-left col-md-12 col-12 mb-2 pad_top_mar_bot-5">
            <div class="row breadcrumbs-top">
                <div class="col-8">
                    <h3 class="content-header-title float-left mb-0">Business Task Status Configuration</h3>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to='/businesstask/list'>Business Task</router-link></li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">Status Configuration</li>
                        </ol>
                    </div>
                </div>
                <div v-if="pagefeatures.creategroupmaster" class="col-4 ml-auto px-0 text-right mobile_margin-px-0_00" >
                    <div class="input-group-append float-right">
                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success " data-toggle="modal" aria-haspopup="true" aria-expanded="false" :data-target="currentmodule" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false"> 
                        <plus-circle-icon size="1.5x" class="custom-class mr-1"></plus-circle-icon>
                         <span class="mobile_hide_create_text">Create Status Configuration</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border-warning" style="border-radius: 5px; border: none!important;">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-9 mobile-padding-0-2">
                      <div class="avatar bg-light-secondary rounded d-inline-flex">
                          <div class="avatar-content">
                              <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                              </search-icon>
                          </div>
                      </div>
                       <h4 class="card-title d-inline-flex ml-1">Search Status Configuration</h4>
                  </div>
                  <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
              </div>
            </div>
            <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                      <label class="form-label lable-left">Task Owner</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Task Owner" v-model="search.taskowner" :clear-on-select="true" :options="search.taskownerlist"/>
                  </div>

                  <div class="col-sm-4 col-md-4 col-lg-4 mb-1">
                      <label class="form-label lable-left">Business Task Status</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Business Task Status" v-model="search.btcstatus" :clear-on-select="true" :options="search.btcstatuslist"/>
                  </div>
                  
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="searchSummaryData(search)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-3">
                                <div class="">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="proModuleList">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="table-responsive">
                                                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="srno first-col-sticky">Sr#</th>
                                                                        <th scope="col" class="second-col-sticky">Task Owner</th>
                                                                        <th scope="col">Business Task Status</th>
                                                                        <th scope="col">Task Status </th>
                                                                        <th scope="col">Created Date</th>
                                                                        <th scope="col">Created By</th>
                                                                        <th scope="col">LastModified Date</th>
                                                                        <th scope="col">LastModified By</th>
                                                                        <th scope="col" v-if="pagefeatures.editgroupmaster" class="clsaction">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-for="(data,index) in companymasterlist" v-if="companymasterlist.length>0">        
                                                                        <tr>
                                                                            <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                            <td class="second-col-sticky">
                                                                                <span  class="font-weight-bold capitalisetext">{{data.taskowner}}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span class="font-weight-bold capitalisetext">{{data.btcstatus}}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span v-if="data.taskstatus" class="font-weight-bold capitalisetext">
                                                                                    <ol><li v-for="(gnames) in data.taskstatus.split(',')">{{gnames}}</li></ol>
                                                                                </span>
                                                                            </td>
                                                                            <td>{{dateMonthFormat(data.createddate)}}</td>
                                                                            <td>
                                                                                <span class="font-weight-bold capitalisetext">{{data.createdby.split('@')[0]}}</span>
                                                                            </td>
                                                                            <td>{{dateMonthFormat(data.lastmodifieddate)}}</td>
                                                                            <td>
                                                                                <span class="font-weight-bold capitalisetext">{{data.lastmodifiedby.split('@')[0]}}</span>
                                                                            </td>
                                                                            <td class="clsaction"  v-if="pagefeatures.editgroupmaster">                                                                  
                                                                                <div class="d-flex align-items-center sitebluecolor" href="javascript:void(0);" data-toggle="modal" :data-target="currentmodule" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false" style="cursor: pointer" >
                                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                                                                    <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="companymasterlist.length==0">
                                                                        <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pageCenter text-center">
                                        <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="modal modal-slide-in fade" id="groupmaster" aria-hidden="true">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#ffff !important" @click="resetForm()">×</button>
                <div class="modal-header mb-1" style="background-color:#ff7f0e !important;">
                    <h5 class="modal-title" style="color:#ffff !important">
                        <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                        <span class="align-middle ml-50">Add/Update Business Task Status Configuration</span>
                    </h5>
                </div>
                <div class="modal-body flex-grow-1 devUniqueForm mobile-padding-0-2">
                    <form>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Task Owner</label>
                                <div class="input-group input-group-merge">
                                    <treeselect placeholder="Select Task Owner" :options="taskownerlist" @input="selecttaskowner" v-model="taskowner" v-on:close="validateForm($event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskowner">{{ this.errors.taskowner }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Business Task Status</label>
                                <div class="input-group input-group-merge">
                                    <treeselect placeholder="Select Business Task Status" :options="btcstatuslist" @input="selectbtaskstatus" v-model="btcstatus" v-on:close="validateForm($event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.btcstatus">{{ this.errors.btcstatus }}</div>
                            </div>
                        </div>
                         <div class="col-12">
                            <div class="form-group">
                                <label class="form-label">Task Status</label>
                                <div class="input-group input-group-merge">
                                    <treeselect placeholder="Select Task Status" :multiple="true" :options="taskstatuslist" @input="selecttaskstatus" v-model="taskstatus" v-on:close="validateForm($event)"/>
                                  </div>
                                <div class="errorinputmsg" v-if="this.errors.taskstatus">{{ this.errors.taskstatus }}</div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button   type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal" v-on:click.stop.prevent="savewizard($event)">Submit</button>
                                <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,SearchIcon } from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import commonMethods from '../../utils/commonMethods';

export default {
    name:'StatusMapping',
    mixins: [ commonMethods ],

    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,
        Treeselect,
        SearchIcon,
    },
    data(){
        return{
            search: {
                btcstatuslist: [],
                taskownerlist: [
                  { id: "Product", label: "Product" },
                  { id: "Tech", label: "Tech" },
                  { id: "QA", label: "QA" },
                  { id: "Security", label: "Security" },
                  { id: "Infra", label: "Infra" },
                ],
            },
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            companymasterlist:[],
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            currentmodule:"#groupmaster",
            btcstatus: null,
            taskowner: null,
            taskstatus: null,
            isEdit: false,
            editRowData: [],
            pagefeatures:[],
            btcstatuslist: [],
            taskstatuslist: apiUrl.status,
            taskownerlist: [
              { id: "Product", label: "Product" },
              { id: "Tech", label: "Tech" },
              { id: "QA", label: "QA" },
              { id: "Security", label: "Security" },
              { id: "Infra", label: "Infra" },
            ],
            errors: {
                btcstatus: null,
                taskowner: null,
                taskstatus: null,
            }
        }
    },
    created() {
        this.pagefeatures = {};
        var pagefeatures1 = JSON.parse(
          window.atob(window.localStorage.getItem("pagefeatures"))
        );
        pagefeatures1.forEach((element) => {
          if (element.page === "/testcasetask/groupmaster") {
            this.pagefeatures[element.featurename] = element.featureaccess;
          }
        });
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.getlist()
                this.getBtcStatusList();
            }
        }
        $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
    },
    methods:{
        getBtcStatusList() {
            let url = "api/btc/getBtcStatusList";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.search.btcstatuslist = result.data.data.rows;
                    this.btcstatuslist = result.data.data.rows;
                } else {
                    this.search.btcstatuslist = [];
                    this.btcstatuslist = [];
                }
            }).catch(e => {
              this.displayError(e)
            });     
        },
        resetRecords: function() {
            this.search.btcstatus = null
            this.search.taskowner = null
            this.taskowner = null
            this.getlist([]);
        },
        searchSummaryData:function(data){
            this.getlist(data);
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        selecttaskowner(state, value) {
            this.errors.taskowner = null
            this.taskowner = null
            this.taskowner = state
             if(!state){
               this.errors.taskowner = 'Task Owner is required'
            }else{                
               this.errors.taskowner = ''
            }
        },
        selectbtaskstatus(state, value) {
            this.errors.btcstatus = null
            this.btcstatus = null
            this.btcstatus = state
             if(!state){
               this.errors.btcstatus = 'Business Task status is required'
            }else{                
               this.errors.btcstatus = ''
            }
        },
        selecttaskstatus(state, value) {
            this.errors.taskstatus = null
            this.taskstatus = null
            this.taskstatus = state
             if(!state){
               this.errors.taskstatus = 'Task status is required'
            }else{                
               this.errors.taskstatus = ''
            }
        },
        openEditModule(currRowData){
            this.editRowData = currRowData;
            this.btcstatus = currRowData.btcstatus;
            var statuslisst = currRowData.taskstatus.split(",")
            this.taskstatus = statuslisst.map(d => d)
            this.taskowner = currRowData.taskowner;
            this.isEdit = true;
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getlist()
        },
        addModule(){
            this.btcstatus = null
            this.taskowner = null
            this.taskstatus = null
        },
        getlist(search){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
            }
            if(search){
                this.input.btcstatus = search.btcstatus?search.btcstatus: null;
                this.input.taskowner = search.taskowner?search.taskowner: null;
            }
            this.input.empcode= this.undt.userid,
            this.input.useremail= this.undt.username
            axios({
                    'method': 'POST',
                    'url': 'api/tasks/statusmappinglist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.companymasterlist = result.data.data.rows
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.companymasterlist=[]
                        this.totalcount = 0;
                        this.pageCount =0;
                    }
                }).catch(e => {
              this.displayError(e)
            })
        },
        savewizard(){
            this.valid = this.validateForm();
            if(this.valid){
                this.input={
                    btcstatus : this.btcstatus,
                    taskstatus :this.taskstatus.toString(),
                    taskowner :this.taskowner,
                    useremail  : this.undt.username,
                    isactive:1,
                    empcode: this.undt.userid
                }
                let url = 'api/tasks/savestatusmapping';
                if(this.isEdit){
                    url = 'api/tasks/updatestatusmapping';
                    this.input.id = this.editRowData.id
                }
                this.input.useremail= this.undt.username,
                this.input.empcode=this.undt.userid

                axios({
                    'method': 'POST',
                    'url': url,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.btcstatus = null
                        this.taskowner = null
                        this.taskstatus = null
                        this.isEdit = false;
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.getlist()
                        $('.close').click();
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
              this.displayError(e)
            })
            }
        },
        resetForm(){
            this.btcstatus = null
            this.taskowner = null
            this.taskstatus = null
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validateForm(){
            var isValid=true;
            if(!this.taskstatus){
                this.errors.taskstatus="Task status is required";
                isValid= false;
            }else{
                this.errors.taskstatus='';
            }
            if(!this.btcstatus){
                this.errors.btcstatus="Business Task status is required";
                isValid= false;
            }else{
                this.errors.btcstatus='';
            }
            if(!this.taskowner){
                this.errors.taskowner="Task Owner is required";
                isValid= false;
            }else{
                this.errors.taskowner='';
            }
            return isValid;
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
    }
}
</script>