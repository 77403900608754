<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12 d-flex">
              <h3 class="content-header-title float-left mb-2">Add Appraisal Cycle</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link :to="{ name: 'ListingAppraisalCycle', params: { search: true }}">Appraisal Cycle Listing</router-link>
                  </li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Add Appraisal Cycle</li>
                </ol>
              </div>
            </div>
          </div>
          <div  class="card border-warning add_apprisalcycle_view" >
            <div>
              <div class="card-body p-1">
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group ">
                        <div class="card border mb-0">
                          <div class="card-header py-1 px-1" style="background-color: #f1f2f3;">
                              <h3 class="card-title">
                                Appraisal Cycle Info
                              </h3>
                          </div>
                          <div class="row mt-2  mx-0">
                          <div class="col-md-4">
                            <label for="">Name of the Appraisal</label>
                            <div class="form-group ">
                              <input class="form-control"  placeholder="Name " v-model="appraisalName" @input="restrictSpecialCharsName(appraisalName)"  @close="validateForm($event)">
                              <div class="errorinputmsg" v-if="this.errors.appraisalName">{{ this.errors.appraisalName }}</div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="">
                            <label for="">Appraisal Cycle Date From</label>
                            <div class="input-group input-group-merge">
                            <div class="input-group-prepend">
                            </div>
                            <date-picker placeholder="Select Date From" v-model="appraisalCyclePeriodFrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate" @input="selectedstartdate"></date-picker>
                            <div class="errorinputmsg" v-if="this.errors.appraisalCyclePeriodFrom">{{ this.errors.appraisalCyclePeriodFrom }}</div>  
                          </div>
                        </div>
                          </div>
                          <div class="col-md-4">
                            <div class="">
                              <label for="">Appraisal Cycle Date To</label>
                              <div class="input-group input-group-merge">
                              <div class="input-group-prepend">
                              </div>
                              <date-picker placeholder="Select Date To" v-model="appraisalCyclePeriodTo" valueType="format" class="enddate" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                              <div class="errorinputmsg" v-if="this.errors.appraisalCyclePeriodTo ">{{ this.errors.appraisalCyclePeriodTo   }}</div>  
                            </div>
                          </div>
                          </div>
                          
                          <div class="col-md-12 form-group">
                            <label class="form-label" for="project-name">Description </label>
                              <ckeditor :editor="editor" placeholder="Description" v-model="description" :config="editorConfig" tag-name="textarea" v-on:blur="descBlured = true"/>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                  <div class="card-body p-1 col-12 mg-top1 text-right">
                    <button v-if="pagefeatures.createcycle" type="button" class="btn btn-relief-primary btn-1 mr-1" @click="saveData()" id="submitbtnt18" >Submit</button>
                    <button type="reset" class="btn btn-outline-secondary mr-1" @click="resetForm()">Reset</button>
                    <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                  </div>        
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>
  <script>
  import axios from "axios";
  import VueElementLoading from "vue-element-loading";
  import vSelect from "vue-select";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import {Trash2Icon,PlusSquareIcon, MinusSquareIcon, EditIcon, CheckCircleIcon, XCircleIcon, UserIcon} from "vue-feather-icons";
  import apiUrl from '../../constants';
  import Pagination from 'vue-pagination-2'
  import moment from 'moment'
  import commonMethods from '../../utils/commonMethods';

  export default {
    name: "AddAppraisalCycle",
    mixins: [ commonMethods ],

    components: {
      Loading,
      VueElementLoading,Trash2Icon,PlusSquareIcon, MinusSquareIcon,
      vSelect,
      Treeselect,
      DatePicker,
      ckeditor: CKEditor.component,
      Pagination,
      EditIcon,
      CheckCircleIcon,
      XCircleIcon,
      UserIcon
    },
    data() {
      return {
        isLoading: false,
        fullPage: true,
        appraisalName: null,
        appraisalCyclePeriodFrom: null,
        appraisalCyclePeriodTo: null,
        description: null,
        editor: ClassicEditor,
        editorConfig: {
            toolbar: {
              items: [
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'insertTable',
                '|',
                'undo',
                'redo', '|',
              ],
            },
          },
        
        masterList:[],
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,

        errors: {
          appraisalName: '',
          appraisalCyclePeriodFrom: '',
          appraisalCyclePeriodTo: ''
        }
      };
    },
    created() {
      this.pagefeatures = {};
      let pagefeatures1 = JSON.parse(
        window.atob(window.localStorage.getItem("pagefeatures"))
      );
      pagefeatures1.forEach((element) => {
        if (element.page === "/appraisalcyclemaster") {
          this.pagefeatures[element.featurename] = element.featureaccess;
        }
      });
    },
    mounted() {
      var userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.tokendata = window.localStorage.getItem('token');
        this.loginuscondition = userdata.userid;
        
      }
    },
    methods: {
      restrictSpecialCharsName(inputval) {
        if (/[^A-Za-z0-9\s]/.test(this.inputval)) {
          this.invalidInput = true;
        } else {
          this.invalidInput = false;
        }
        this.appraisalName = this.appraisalName.replace(/[^A-Za-z0-9\s]/g, "");
      },
      saveData(objThis) {
        this.isValid = this.validateForm(objThis);
        if (this.isValid) {
          this.input = {
            useremail: this.undt.username,
            empcode: parseInt(this.undt.userid),
            appraisalName: this.appraisalName,
            appraisalCyclePeriodFrom : this.appraisalCyclePeriodFrom,
            appraisalCyclePeriodTo : this.appraisalCyclePeriodTo,
            description : this.description,
            }
            
            let apiURL = 'api/master/saveApraisalCycle'
            axios({
              'method': 'POST',
              'url': apiURL,
              'data': this.input,
              'headers':{'authorization':this.tokendata}
            })
            .then(result =>{
                  this.isLoading = false;
                  if(result.data.errorCode == 0){
                  Swal.fire({
                      title: "Success!",
                      text: result.data.msg,
                      icon: 'success',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
                  this.resetForm();
                  this.$router.push({ name: 'ListingAppraisalCycle', params: { search: true }});
                  }
                  else if(result.data.errorCode == 3){
                      Swal.fire({
                          title: "Session Expired...!!",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary",
                          },
                              buttonsStyling: !1,
                      }).then(function() {
                          window.location.href = "/#/login";
                      })
                  } else {
                      Swal.fire({
                          title: "Failed",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary"
                          },
                          buttonsStyling: !1
                      })
                    }
                }).catch(e => {
          this.displayError(e)
        })
        }
      },

      handleCancel() {
        this.$router.push({ name: 'ListingAppraisalCycle', params: { search: true }});
      },

      resetForm() {
        this.appraisalName = null
        this.appraisalCyclePeriodFrom = null
        this.appraisalCyclePeriodTo = null
        this.description = null
      },

      clearErrorForm(){
        this.errors.appraisalName = null
        this.errors.appraisalCyclePeriodFrom = null
        this.errors.appraisalCyclePeriodTo = null
      },

      validateDate(mdl, objThis) {
        var startDate = this.taskdefine.taskstartdate
        var endDate = this.taskdefine.taskenddate
        if(mdl === 'taskenddate') {
          if ( endDate &&(Date.parse(endDate) < Date.parse(startDate))) {
            this.errors.taskdefine.taskenddate = "End date should be greater than Start date"
            this.taskdefine.taskenddate = ''
          } else {
            this.errors.taskdefine.taskenddate = ''
          }
        } 
        else if(mdl === 'taskstartdate') {
          if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
            this.errors.taskdefine.taskstartdate = "Start date should be lesser than End date"
            this.taskdefine.taskstartdate = ''
          } else {
            this.errors.taskdefine.taskstartdate = ''
          }
        } 
      },
      
      clickCallback: function(pageNum) {
       this.pageoffset=pageNum
       this.currPageNum = pageNum - 1
       this.getTrackerList();
       setTimeout(function(){
       }, 500);
     },

     validateForm(){
          var isValid=true;
            if(!this.appraisalName) {
              this.errors.appraisalName = "Appraisal Name Required";
              isValid = false;
            } else {
              this.errors.appraisalName = ''
            }

            if(!this.appraisalCyclePeriodFrom) {
              this.errors.appraisalCyclePeriodFrom = "Cycle Period From Required"
              isValid = false;
            }  else {
              this.errors.appraisalCyclePeriodFrom = ''
            }

            if (!this.appraisalCyclePeriodTo) {
              this.errors.appraisalCyclePeriodTo = 'Cycle Period To Required'
              isValid = false;
            } else {
              this.errors.appraisalCyclePeriodTo = ''
            }

            return isValid
      },

      selectedenddate(){
      if((this.appraisalCyclePeriodFrom && this.appraisalCyclePeriodTo) || (!this.appraisalCyclePeriodFrom && !this.appraisalCyclePeriodTo)){
          this.isBothDateSelected = false
      }else if((this.appraisalCyclePeriodFrom && !this.appraisalCyclePeriodTo) ||( !this.appraisalCyclePeriodFrom && this.appraisalCyclePeriodTo)){
          this.isBothDateSelected = true
      } 
    },
    selectedstartdate(){
      if((this.appraisalCyclePeriodFrom && this.appraisalCyclePeriodTo) || (!this.appraisalCyclePeriodFrom && !this.appraisalCyclePeriodTo)){
          this.isBothDateSelected = false
      }else if((this.appraisalCyclePeriodFrom && !this.appraisalCyclePeriodTo) ||( !this.appraisalCyclePeriodFrom && this.appraisalCyclePeriodTo)){
          this.isBothDateSelected = true
      }
    },
    disabledBefore(date) {
      let dayBefore = moment(this.appraisalCyclePeriodFrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date <= beforeToday; 
    },
    notBeforeToday(date) {
      this.appraisalCyclePeriodTo = "";
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    },
  };
  </script>
  <style>
  .displayblk {
    display: block !important;
  }
  .createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  input.mx-input::-webkit-input-placeholder {
  color: #b9b9c3;
  font-size: 14px;
  }
  input:-moz-placeholder {
  color: #b9b9c3;
  font-size: 14px;
  }
  input.mx-input{
    color: #000 !important
  }
  </style>
  