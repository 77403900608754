<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0 ">
                <h3 class="content-header-title float-left mb-0">Document Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/document/list'>Document Management</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst" >
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
            <div class="container-fluid" > 
              <div class="row" >
                <div class="w-auto px-1 mobile-padding-0-2">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                      <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                    </div>
                  </div>
                <h4 class="card-title d-inline-flex ml-1">Search Document Details</h4>
                </div>
                <div class="ml-auto d-flex">
                <div class="px-1 text-right mobile-padding-0-2" >
                  <div class="input-group-append float-right">
                    <router-link to="/document/create"><span class="btn btn-sm btn-relief-success" > <span class="mobile_hide_bt">Create Document </span> <plus-circle-icon size="1.5x"></plus-circle-icon></span></router-link>
                  </div>
                </div>
                <span class="rotetions_view_arrow ml-auto mx-1"> 
                <em class="las la-angle-down"></em>
              </span>
              </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
            <div class="card-body pt-1 px-sm-mobile-10">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="from-group"> 
                  <label class="form-label lable-left">Document Group Name</label>
                  <input type="text" class="form-control" placeholder="Enter Document Group Name"  v-model="search.documentgroupname" />
                </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="from-group"> 
                  <label class="form-label lable-left">Document Title</label>
                  <input type="text" class="form-control" placeholder="Enter Document Title"  v-model="search.documenttitle" />
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="from-group"> 
                  <label class="form-label">Keywords</label>
                    <div class='tag-input'>
                      <div v-for='(tag, index) in search.keywords' :key='tag' class='tag-input__tag'>
                        {{ tag }}
                        <span @click='removeTag(index)'>x</span>
                      </div>
                      <input
                        type='text'
                        placeholder="Enter a Keyword"
                        class='tag-input__text'
                        @keydown.enter='addTag'
                        @keydown.188='addTag'
                        @keydown.delete='removeLastTag'
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <div class="from-group"> 
                  <label class="form-label lable-left">Document Description</label>
                  <input type="text" class="form-control" placeholder="Enter Document Description"  v-model="search.description" />
                 </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                  <div class="from-group"> 
                  <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getAllDocumetList(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                  <button type="move" class="btn btn-relief-primary mt-2 mr-1" @click="getAllDocumetList(search, true)"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="blog-list-wrapper" id="document-list">
          <div class="dw mx-n1">
            <div class="dw-panel p-1 customAnimation-1"  v-for="(data,index) in document.documentlist" v-bind:key="index">
              <div class="card border-warning" style="margin-bottom: 0rem !important; border-radius: 15px; overflow: hidden;border: none!important;">
              <div class="card-header p-1" style="min-height: 55px;">
                <h4 class="card-title"><span style="font-weight:bold">{{data.documentgroupname}}</span> - {{data.doctitle}} </h4>
              </div>
              <div class="card" style="margin-bottom:0 !important;">
                <div class="card-body p-1">
                    <div class="media">
                       <div class="avatar mr-50">
                        <img data-v-72078b9d="" alt="" src="../../../public/images/icons/avatar-s-11.jpg" width="24" height="24">

                       </div>
                      <div class="media-body">
                        <div>
                          <small class="text-muted mr-25">By</small>
                          <small class="capitalisetext sitebluecolor">{{ formatName(data.createdby) }}</small>
                          <span class="text-muted ml-50 mr-25">|</span>
                          <small class="text-muted">{{dateMonthFormat2(data.updatedatetime)}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="my-1">
                      <template v-for="(d,i) in data.dockeywords" >
                        <div v-bind:key="i" :class="'badge badge-pill capitalisetext mr-50 mb-25 ' + getColorClass(i)">{{d}}</div>
                      </template>
                    </div>
                    <div class="media">
                        <img style="margin:auto; width:50px; margin-top: 5px" v-if="getFileExtension(data.docfilepath) === 'image' && !getPresignedUrl(data.presignedurl)" src="../../../public/images/icons/image.png"  alt="image thumbnail" height="100%"/>
                        <img style="margin-top: 5px" v-else-if="getFileExtension(data.docfilepath) === 'image' && getPresignedUrl(data.presignedurl)" width="100%" height="100%" :src="data.presignedurl"  alt="image thumbnail"/>
                        <img style="margin:auto; width:50px; margin-top: 5px" v-else-if="getFileExtension(data.docfilepath) === 'pdf'" alt="pdf-thumbnail" src="../../../public/images/icons/ic-pdf.png" />
                        <img style="margin:auto; width:50px; margin-top: 5px" v-else-if="getFileExtension(data.docfilepath) === 'docx'" alt="docx-thumbnail" src="../../../public/images/icons/ic-doc.png" />
                        <img style="margin:auto; width:50px; margin-top: 5px" v-else-if="getFileExtension(data.docfilepath) === 'csv'" alt="csv-thumbnail" src="../../../public/images/icons/ic-csv.png" />
                        <img style="margin:auto; width:50px; margin-top: 5px" v-else-if="getFileExtension(data.docfilepath) === 'xls'" alt="xls-thumbnail" src="../../../public/images/icons/ic-xls.png" />
                        <img style="margin:auto; width:50px; margin-top: 5px" v-else-if="getFileExtension(data.docfilepath) === 'video'" alt="video-thumbnail" src="../../../public/images/icons/ic-video.png" />
                        <img style="margin:auto; width:50px; margin-top: 5px" v-else alt="doc-thumbnail" src="../../../public/images/icons/doc.png" height="100%" />
                    </div>
                    <div class="card-text blog-content-truncate my-2" style="overflow:hidden">
                      <span v-html="displayStrings(data.docdescription)"></span>
                    </div>
                    <hr class="mx-n1" />
                    <div class="d-flex justify-content-between align-items-center cards_bottoms_over">
                      <a @click="redirecttourl('view',data)" href="javascript:void(0);" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                        <div class="d-flex align-items-center sitebluecolor"> 
                          <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                          <span class="text-body font-weight-bold sitebluecolor">View Details</span>
                        </div>
                      </a>
                      <a @click="getDownloadedLink(data.filename, 'download-doc')" target="_blank" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                        <div class="d-flex align-items-center sitebluecolor"> 
                          <download-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></download-icon>
                          <span class="text-body font-weight-bold sitebluecolor">Download</span>
                        </div>
                      </a>
                      <a v-if="getFileExtension(data.docfilepath) === 'image'" @click="onZoom(data.filename, 'zoom')" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                        <div class="d-flex align-items-center sitebluecolor"> 
                          <zoom-in-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></zoom-in-icon>
                          <span class="text-body font-weight-bold sitebluecolor">Zoom</span>
                        </div>
                      </a>
                      <a v-else-if="getFileExtension(data.docfilepath) === 'video'" @click="vlc(data.filename, 'vidzoom')" class="btn btn-icon btn-icon btn-flat-secondary waves-effect">
                        <div class="d-flex align-items-center sitebluecolor"> 
                          <play-circle-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></play-circle-icon>
                          <span class="text-body font-weight-bold sitebluecolor">Play</span>
                        </div>
                      </a>
                    </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="pageCenter text-center pt-1">
            <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            <div v-if="document.documentlist.length == 0" colspan="14" style="text-align: center;">No record found</div>
          </div>
        </div>
        <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">              
              <div class="modal-body p-0">
                <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                <img alt="" src="" class="imagepreview"  >
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="videomodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content video__container_mobile_view_centers">              
                  <div class="modal-body p-0 ">
                      <div class="video__container" v-if="videoIsOpen">
                          <button type="button" class="imageModalClose modal-video-close-btn" data-dismiss="modal" @click="stopVideoPlay"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                          <video controls controlsList="nodownload" id="myVideoPlayer" :src="videoURL" width="100%" preload="metadata" >
                              <source :src="videoURL+'#t=0.0'" type="video/mp4" >
                          </video>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2'
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";
import commonMethods from '../../utils/commonMethods';
import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon, EyeIcon, ZoomInIcon, DownloadIcon, PlayCircleIcon } from "vue-feather-icons";

export default {
  name: 'DocumentList',
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    DatePicker,
    PlusCircleIcon,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    Pagination,
    SearchIcon,
    ExternalLinkIcon,
    XIcon,
    EyeIcon,
    ZoomInIcon,
    DownloadIcon,
    PlayCircleIcon,
  },
  mixins: [ commonMethods ],
  data() {
    return {
      videoURL: '',
      videoIsOpen: false,
      document: {
        documentlist: [],
        presingnurllist: [],
      },
      pills: ['danger', 'primary', 'info', 'warning', 'success' ],
      imgExt:['jpeg','jpg','png'],
      vidExt: ['mov', 'movie', 'mp4', 'wmv', 'avi', 'mkv'],
      domainnamelist:[],
      search: {
        documentgroupname: null,
        document:null,
        documenttitle: null,
        keywords: [],
        description: null,
      },
      searchedQuery: null,
      isactive: true,
      isSearchBoxActive: false,
      card_content:'card-content',
      collapse:'collapse',
      isLoading: false,
      undt: null,
      loggedinusermailid: null,
      tokendata: null,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT-12,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      TimeFormat: apiUrl.TimeFormat,
      purchase_date:null,
      expiry_date:null,
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.loggedinusermailid = this.undt.username
        this.tokendata = window.localStorage.getItem('token');
        if(this.$route.params && this.$route.params.search) {
          let search = JSON.parse(localStorage.getItem('documentsearchItems'))
          if(search) {
            this.getSearchItems(search);
          }else{
            localStorage.removeItem('documentsearchItems');
            this.getAllDocumetList();
          }
        } else {
          localStorage.removeItem('documentsearchItems');
          this.getAllDocumetList();
        }
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getSearchItems(search){
      this.isSearchBoxActive = true;
      this.search.documentgroupname = search.documentgroupname;
      this.search.description = search.description;
      this.search.documenttitle = search.documenttitle;
      this.search.keywords = search.keywords;
      this.getAllDocumetList(this.search);
    },
    addTag(event) {
      event.preventDefault()
      let val = event.target.value.trim()
      if (val.length > 0) {
        if (this.search.keywords.length >= 1) {
          for (let i = 0; i < this.search.keywords.length; i++) {
            if (this.search.keywords[i] === val) {
              return false
            }
          }
        }
        this.search.keywords.push(val)
        event.target.value = ''
      }
    },
    removeTag(index) {
      this.search.keywords.splice(index, 1)
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.search.keywords.length - 1)
      }
    },
    getPresignedUrl(name) {
      if(!name || name == 'https://s3.ap-south-1.amazonaws.com/') {
        return false
      }
      return true
    },
    getFileExtension(name) {
      if(!name) {
        return 'NA'
      }
      if(this.vidExt.includes(name.substring(name.lastIndexOf('.')+1).toLowerCase())) {
        return 'video'
      } else if(this.imgExt.includes(name.substring(name.lastIndexOf('.')+1).toLowerCase())) {
        return 'image'
      } else if(name.substring(name.lastIndexOf('.')+1).toLowerCase() === 'pdf') {
        return 'pdf'
      } else if(name.substring(name.lastIndexOf('.')+1).toLowerCase() === 'docx') {
        return 'docx'
      } else if(name.substring(name.lastIndexOf('.')+1).toLowerCase() === 'csv') {
        return 'csv'
      } else if(['xls', 'xlsx'].includes(name.substring(name.lastIndexOf('.')+1).toLowerCase())) {
        return 'xls'
      }
      return 'document'
    },
    stopVideoPlay() {
        var video = document.getElementById("myVideoPlayer");
        video.pause();
        video.currentTime = 0;
    },
    getDownloadedLink(data, curr=null) {
      this.isLoading = true
      if(data) {
        let payload = {
        documents:[{link:data,empid: this.undt.userid,useremail: this.undt.username}],
        empcode: this.undt.userid,
        useremail: this.undt.username
        };
        axios({
          method: "POST",
          url: '/api/imageupload/getdownloadlink',
          data: payload,
          'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          this.isLoading = false
          if(result.data.status) {
              if(result.data.data[0].presignurl){
                if(curr=='zoom'){
                  $('.imagepreview').attr('src', result.data.data[0].presignurl);
                  $('#imagemodal').modal('show');  
                  return 
                } else if(curr == 'vidzoom') {
                  this.videoURL = result.data.data[0].presignurl
                  this.videoIsOpen = true
                  $('#videomodal').modal('show');
                  return
                } else if(curr == 'pdfpreview') {
                  window.open(result.data.data[0].presignurl, '', 'left=0,top=0,width=900,height=1200,toolbar=0,scrollbars=0,status=0')
                  return 
                }
                window.open(result.data.data[0].presignurl, '_blank')     
              }
          }
        }).catch(e => {
        this.displayError(e)
      });;
      }
    },
    getColorClass(index) {
      if(index >= 5) {
        let lastdigit = index % 10
        if(lastdigit >= 5) {
          if(lastdigit % 2) {
            lastdigit = 0
          } else {
            lastdigit = 1
          }
        }
        return `badge-light-${this.pills[lastdigit]}`
      }
      return `badge-light-${this.pills[index]}`
    },
    getAllDownloadedLink(data, curr=null) {
      this.isLoading = true
      if(data && data.length) {
        let payload = {
        documents: data.map(i => {
          return {
            link: i.filename,
            empid: this.undt.userid,
            useremail: this.undt.username
          }
        }),
        empcode: this.undt.userid,
        useremail: this.undt.username
        };
        axios({
          method: "POST",
          url: '/api/imageupload/getdownloadlink',
          data: payload,
          'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          this.isLoading = false
          if(result.data.status) {
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    onZoom(e,  action){
      this.getDownloadedLink(e, action);
    },
    vlc(e, action) {
      this.getDownloadedLink(e, action);
    },
    displayStringsByGroup(givenstring){
      if(!givenstring) {
        return 'NA'
      }
      let responsestr = '';
      let prlist = givenstring.split(',')
      if(prlist.length > 1){
        let i=0
        for(let str of prlist) {
          if(str.length > 30) {
            responsestr += str.substring(0,31) + '...'
          } else {
            responsestr += str
          }
          if(i !== prlist.length -1 ) {
            responsestr += ' <br/><hr/>'
          }
          i += 1
        }
      } else {
        for(let str of prlist) {
          responsestr += str
        }
      }
      return responsestr
    },
    displayStrings(givenstring) {
      if(!givenstring) {
        return 'NA'
      }
      if(givenstring.length > 130) {
        return givenstring.substring(0,130) + '...'
      }
      return givenstring
    },
    redirecttourl:function(btnstate,rowdata){
      rowdata.backbtnstate=btnstate
      if(btnstate=="view"){
        let routeData = this.$router.resolve({ 
          path: '/document/view?documentid='+rowdata.id,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        });
        window.open(routeData.href, '_blank');
      }
    },
    formatName(authorstring) {
      if(!authorstring) return 'NA'
      let author=authorstring.replace('@firstcry.com','')
      if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
      author = author.replace('.',' ')
      author = author.charAt(0).toUpperCase() + author.slice(1);
      return author
    },
    getAllDocumetList(search, isExportedFlag) {
      if(search){
        localStorage.setItem('documentsearchItems',JSON.stringify(search));
      }
      this.isLoading = true
      let url = 'api/document/getAllDocumetList2'
      let payload = new Object({
        empid: this.undt.employeeid.toLowerCase(),
        limit: this.pagelimit,
        offset: this.pageoffset,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      if(search) {
        this.searchedQuery = search
        payload.documentgroupname = search.documentgroupname
        payload.description = search.description
        payload.documenttitle = search.documenttitle
        payload.keywords = search.keywords
      } else {
        this.searchedQuery = null
      }
      if(isExportedFlag) {
        payload.isExported = true
        payload.limit = null
        payload.offset = null
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      })
      .then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          let d = result.data.data.rows
          
          // this.getAllDownloadedLink(d)
          

          this.document.documentlist = d.map(i => {
            let keywords = []
            if(i.dockeywords.indexOf('[') == -1) {
              // not array
              let keys = i.dockeywords.split(',')
              keywords = keys
            } else {
              keywords = JSON.parse(i.dockeywords)
            }
            return { ...i, dockeywords: keywords, dockeywordsexport: keywords.join(' | ') }
          })
          if(isExportedFlag) {
            this.download(this.document.documentlist)
          }
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else {
          this.document.documentlist = []
          this.totalcount = 0
          this.pageCount = 0
          if(isExportedFlag) {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            });
          }
        }
      }).catch(e => {
               this.displayError(e)
      });
    },
    download(readExportdata) {
      if(readExportdata.length > 0) {
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let row = ''
        let CSV = 'Sr#, Document Group Name, Document Title, URL, Keywords, Description, Created By, Created Date' + '\r\n'
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let documentgroupname = index.hasOwnProperty('documentgroupname') ? (index.documentgroupname?index.documentgroupname.replace(/"/g, '""'):'')  : ''
          let doctitle = index.hasOwnProperty('doctitle') ? (index.doctitle?index.doctitle.replace(/"/g, '""'):'') : ''
          let docurl = index.hasOwnProperty('docurl') ? (index.docurl?index.docurl.replace(/"/g, '""'):'') : ''
          let dockeywords = index.hasOwnProperty('dockeywordsexport') ? index.dockeywordsexport : ''
          let docdescription = index.hasOwnProperty('docdescription') ? (index.docdescription?index.docdescription.replace(/"/g, '""'):'') : ''
          let createdby = index.hasOwnProperty('createdby') ? (index.createdby?index.createdby.replace(/"/g, '""'):'') : ''
          let createddatetime = index.hasOwnProperty('createddatetime') ? this.dateMONFormat(index.createddatetime) : ''
         
          row += '"' + srno + '",'
          row += '"' + documentgroupname + '",'
          row += '"' + doctitle + '",'
          row += '"' + docurl + '",'
          row += '"' + dockeywords + '",'
          row += '"' + docdescription + '",'
          row += '"' + createdby + '",'
          row += '"' + createddatetime + '",'
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        let fileName = 'documets_' + moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Data Exported successfully",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });    
      }
    },
    dateMONFormat(value){
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
    dateMonthFormat2(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    searchBoxCollapse() {
      this.isSearchBoxActive = !this.isSearchBoxActive
    },
    clearSearchFields() {
      this.search.documentgroupname = null;
      this.search.description = null;
      this.search.keywords = [];
      this.search.documenttitle = null;
      localStorage.removeItem('documentsearchItems');
      this.getAllDocumetList();
    },
    clickCallback: function(pageNum) {
      this.pageoffset=pageNum
      this.currPageNum = pageNum - 1
      this.getAllDocumetList(this.searchedQuery);
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $("#document-list").offset().top -70
        });
      }, 500);
    },
  }
}
</script>
<style scoped>
  .box-inc {
    min-width: 200px;
  }
</style>