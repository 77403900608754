import Vue from "vue";
import Router from "vue-router";
import Home from "./components/Home.vue"
import Charts from "./components/Charts.vue"

import trsRouter from './trsrouter';

import Login from "./components/Login.vue"
import Dashboard from "./components/Dashboard.vue"
import ProjectMaster from "./components/ProjectMaster.vue"
import ProjectSummary from "./components/project/ProjectSummary.vue"
import UserDashboard from "./components/UserDashboard.vue"
import Masters from "./components/masters/Masters.vue"
import GanttChart from "./components/project/GanttChart.vue"
import Profile from "./components/profile/Profile.vue"
import Worklogs from "./components/Worklogs.vue"
import UserProfile from "./components/UserProfile.vue"
import Project from "./components/project/project.vue"
import Projectmodule from "./components/project/projectmodule.vue"
import ProjectApplication from "./components/project/projectapplication.vue"
import ProjectApplicationModule from "./components/project/projectapplicationmodule.vue"
import SectionModule from "./components/project/sectionmodule.vue"
import FuntionalityModule from "./components/project/functionalitymodule.vue"
import Welcome from "./components/welcome.vue"
import Workstep from "./components/project/workstep.vue"
import Projectrolealloc from "./components/project/projectrolealloc.vue"
import EmplProjTaskReport from "./components/reports/emplprojecttaskreport.vue"
import Emplprojecttaskdetailedreport from "./components/reports/emplprojecttaskdetailedreport.vue"
import Projectlistreport from "./components/reports/projectlistreport.vue"
import Redprojectlistreport from "./components/reports/redprojectlistreport.vue"
import EmployeeTotalOverview from "./components/reports/employeetotaloverview.vue"
import BusinessTaskReport from "./components/reports/businesstaskreport.vue"
import hodbusinesstaskreport from "./components/reports/hodbusinesstaskreport.vue"
import businesstaskdeptmaster from "./components/reports/businesstaskdeptmaster.vue"
import businesstaskdeptmaster2 from "./components/reports/businesstaskdeptmaster2.vue"
import Employeeleavetransactions from "./components/reports/Employeeleavetransactions.vue"
import BTMList from "./components/businesstask/BTMList.vue"
import BTMCreate from "./components/businesstask/BTMCreate.vue"
import BTMUpdate from "./components/businesstask/BTMUpdate.vue"
import BTMView from "./components/businesstask/BTMView.vue"
import btcDelayDetails from "./components/businesstask/btcDelayDetails.vue"
import IssueList from "./components/issues/IssueList.vue"
import IssueCreate from "./components/issues/IssueCreate.vue"
import IssueUpdate from "./components/issues/IssueUpdate.vue"
import IssueView from "./components/issues/IssueView.vue"
import IncidentList from "./components/incident/IncidentList.vue"
import IncidentCreate from "./components/incident/IncidentCreate.vue"
import IncidentUpdate from "./components/incident/IncidentUpdate.vue"
import IncidentView from "./components/incident/IncidentView.vue"
import tupdate from "./components/testcasetask/tupdate.vue"
import tlist from "./components/testcasetask/tlist.vue"
import tcreate from "./components/testcasetask/tcreate.vue"
import tview from "./components/testcasetask/tview.vue"
import TaskList from "./components/tasks/TaskList.vue"
import TaskCreate from "./components/tasks/TaskCreate.vue"
import TaskUpdate from "./components/tasks/TaskUpdate.vue"
import TaskView from "./components/tasks/TaskView.vue"
import ProjectRoleAllotment from "./components/project/projectroleallotment.vue"
import AppEnvironmentList from "./components/app_environment/AppEnvList.vue"
import AppEnvironmentCreate from "./components/app_environment/AppEnvCreate.vue"
import AppEnvironmentUpdate from "./components/app_environment/AppEnvUpdate.vue"
import AppEnvironmentView from "./components/app_environment/AppEnvView.vue"
import fcreate from "./components/functionality/fcreate.vue"
import flist from "./components/functionality/flist.vue"
import fupdate from "./components/functionality/fupdate.vue"
import fview from "./components/functionality/fview.vue"
import WorkstepUpdate from "./components/workstep/WorkstepUpdate.vue"
import WorkstepList from "./components/workstep/WorkstepList.vue"
import wcreate from "./components/workstep/wcreate.vue"
import WorkstepView from "./components/workstep/WorkstepView.vue"
import Userperformance2report from "./components/reports/userperformance2report.vue"
import Employeeskillreport from "./components/reports/employeeskillreport.vue"
import ActivityReport from "./components/reports/activityreport.vue"
import UserActivityReport from "./components/reports/useractivityreport.vue"
import TicketList from "./components/tickets/TicketList.vue"
import TicketCreate from "./components/tickets/TicketCreate.vue"
import TicketUpdate from "./components/tickets/TicketUpdate.vue"
import TicketView from "./components/tickets/TicketView.vue"
import PerformanceList from "./components/performance/PerformanceList.vue"
import PerformanceCreate from "./components/performance/PerformanceCreate.vue"
import PerformanceUpdate from "./components/performance/PerformanceUpdate.vue"
import PerformanceView from "./components/performance/PerformanceView.vue"
import MyActivityReport from "./components/myactivity.vue"
import TrainingList from "./components/trainingmodule/TrainingList.vue"
import EmpTrainingList from "./components/trainingmodule/EmpTrainingList.vue"
import TrainingCreate from "./components/trainingmodule/TrainingCreate.vue"
import TrainingArticles from "./components/trainingmodule/TrainingArticles.vue"

import TrainingUpdate from "./components/trainingmodule/TrainingUpdate.vue"
import TrainingView from "./components/trainingmodule/TrainingView.vue"
import TaskLogCreate from "./components/tasktimelog/TaskLogCreate.vue"
import TaskLogList from "./components/tasktimelog/TaskLogList.vue"
import Empmasterreport from "./components/reports/empmasterreport.vue"
import DomainList from "./components/domain/DomainList.vue"
import DomainCreate from "./components/domain/DomainCreate.vue"
import SearchDetails from "./components/SearchDetails.vue"
import ManageEmp from "./components/assessment/ManageEmp.vue"
import ExamSetting from "./components/assessment/ExamSetting.vue"
import Assessmentstart from "./components/assessment/Assessmentstart.vue"
import BTMBulkUpdate from "./components/businesstask/BTMBulkUpdate.vue"
import AgingBTReport from "./components/reports/agingbtreport.vue"
import ZingHrReport from "./components/reports/zinghrreport.vue"
import TaskTimeLogReport from "./components/reports/tasktimelogreport.vue"
import PGMReport from "./components/reports/pgmreport.vue"
import ManageQuestions from "./components/assessment/ManageQuestions.vue"
import ExamResult from "./components/assessment/ExamResult.vue"
import ExamResultView from "./components/assessment/ExamResultView.vue"
import JobOpenings from "./components/hrms/JobOpenings.vue"
import RaiseRequisition from "./components/hrms/RaiseRequisition.vue"
import CreateReleaseNote from "./components/releasenote/CreateReleaseNote.vue"
import UpdateReleaseNote from "./components/releasenote/UpdateReleaseNote.vue"
import ListReleaseNote from "./components/releasenote/ListReleaseNote.vue"
import ViewReleaseNote from "./components/releasenote/ViewReleaseNote.vue"
import requisition from "./components/hrms/Requisition.vue"
import HrDashboard from "./components/hrms/HrDashboard.vue"

import Processongoing from "./components/hrms/CandidateProcessOnGoing.vue"
import CandidatesDetail from "./components/hrms/CandidateDetails.vue"
import candidatescheduler from "./components/hrms/CandidateScheduler.vue"
import generateoffer from "./components/hrms/GenerateOffer.vue"
import LOCReport from "./components/reports/locreport.vue"
import Ratingdashboard from "./components/dashboards/Ratingdashboard.vue"
import mycalendars from "./components/dashboards/MyCalendar.vue"
import GroupMaster from "./components/testcasegroupmaster/GroupMaster.vue"
import StatusMapping from "./components/businesstask/StatusMapping.vue"
import RoleTraMapping from "./components/statusconfiguration/roleTrackerCreate.vue"
import TCBulkMapping from "./components/tcbulkmapping/TCBulkMapping.vue"
import TCBulkView from "./components/tcbulkmapping/TCBulkView.vue"
import TestCycleSetList from "./components/testcycleset/TestCycleSetList.vue"
import BulkmappingCreate from "./components/tcbulkmapping/BulkmappingCreate.vue"
import BulkmappingUpdate from "./components/tcbulkmapping/BulkmappingUpdate.vue"
import TestCycleSetCreate from "./components/testcycleset/TestCycleSetCreate.vue"
import TestCycleVeiw from "./components/testcycleset/TestCycleVeiw.vue"
import TestCaseManagement_listView from "./components/testcycleset/TestCaseManagement_list.vue"
import TestCycleSetUpdate from "./components/testcycleset/TestCycleSetUpdate.vue"
import TestCycleRunVeiw from "./components/testcycleset/TestCycleRunVeiw.vue"
import TestCycleSetAssignee from "./components/testcycleset/TestCycleSetAssignee.vue"
import TestCycleSetBulkList from "./components/testcycleset/TestCycleSetBulkList.vue"
import TestCycleSetRunkList from "./components/testcycleset/TestCycleSetRunLists.vue"
import TestCycleSetProStatusList from "./components/testcycleset/TestCycleSetProStatusList.vue"
import PlanHub from "./components/planner/planhub.vue"
import ViewPlans from "./components/planner/viewplan.vue"
import DocumentMaster from "./components/documents/DocumentMaster.vue"
import DocumentList from "./components/documents/DocumentList.vue"
import DocumentCreate from "./components/documents/DocumentCreate.vue"
import DocumentView from "./components/documents/DocumentView.vue"
import momcreate from "./components/fc_mom/momcreate.vue"
import momupdate from "./components/fc_mom/momupdate.vue"
import viewmomplanner from "./components/planner/viewmomplanner.vue"
import DelayedBusinessTaskSummary from "./components/reports/DelayedBusinessTaskSummary.vue"
import ReleasenoteEMReport from "./components/reports/releasenoteemreport.vue"
import BTCReleasenoteReport from "./components/reports/BTCReleasenoteReport.vue"
import FieldTrackerMapping from "./components/fieldconfiguration/fieldTrackerCreate"
import ProjectProductivityReport from "./components/reports/ProjectProductivityReport.vue"
import FormsConfigurationCreate from "./components/formsconfiguration/CreateFormConfiguration.vue"
import FormsConfigurationList from "./components/formsconfiguration/FormConfigList.vue"
import FeedbackForm from "./components/feedbackform/orientationfeedbackformc.vue"
import FeedbackFormListing from "./components/feedbackform/feedbackformlisting.vue"
import FeedbackViewForm from './components/feedbackform/FeedbackViewForm.vue';
import FeedbackFormReport from './components/feedbackform/FeedbackFormReport.vue';
import statusConfigCreate from './components/statusDynamicConfig/statusConfigCreate.vue';
import statusConfigList from './components/statusDynamicConfig/statusConfigList.vue';
import TeamActivityReport from "./components/teamactivity/teamactivity.vue"
import LogtimeActivity from "./components/logtimeactivity/logtimeactivity.vue"
import CreateAppraisalCycle from "./components/appraisalcycle/CreateAppraisalCycle.vue";
import ListAppraisalCycle from "./components/appraisalcycle/ListAppraisalCycle.vue";
import AppraisalReport from "./components/appraisalcycle/AppraisalFormReport.vue";
import RPFeedbackReport from "./components/reports/RPFeedbackReport.vue";
import AppraisalRaitingReport from "./components/appraisalcycle/AppraisalIsratingReport.vue";
import btcweeklyworkstatuscount from "./components/reports/btcweeklyworkstatuscount.vue";
import EmpProjectRoleConfiguration from "./components/fieldconfiguration/EmployeeProjectRoleConfiguration.vue";
import SlaConfiguration from "./components/slaconfiguration/SlaConfiguration.vue";
import TrackerMaster from "./components/trackerMaster/trackerMaster.vue"
import TrackerListing from "./components/trackerMaster/trackerListing.vue"
import AddAppraisalCycle from "./components/appraisalCycleInfo/addApprisalCycle.vue"
import listingAppraisalCycle from "./components/appraisalCycleInfo/listingAppraisalCycle.vue"
import dailygroupfreezreport from "./components/reports/dailygroupfreezreport.vue"
import ApplicableAppraisalEmp from "./components/appraisalEmpInfo/appraisalApplicableEmp.vue"
import createAppraisalApplicableEmployees from "./components/appraisalCycleInfo/createAppraisalApplicableEmployees.vue"
import createAppraisalTrackers from "./components/appraisalCycleInfo/createAppraisalTrackers.vue"
import Daywisepassfailreport from "./components/reports/DaywisepassfailReport.vue"
import TestCycleReport from "./components/reports/TestCycleReport.vue"
import testcasetimeanalysis from "./components/reports/testcasetimeanalysis.vue"
import QAREPORTS from "./components/reports/qareports.vue"
import TestcycleDetailsLevelReport from "./components/reports/testcycledetailslevelreport.vue"
import testcasedetaillevelreport from "./components/reports/testcasedetaillevelreport.vue"
import typeModuleListing from "./components/moduleconfiguration/typeMasterListing.vue"
import TCCreate from "./components/dynamic_tickets/TCCreate.vue"
import TCUpdate from "./components/dynamic_tickets/TCUpdate.vue"
import BtcTaskCreationConfig from "./components/statusconfiguration/btctaskcreationconfig.vue"
import UpdateFormConfiguration from "./components/formsconfiguration/UpdateFormConfiguration.vue"
import TCList from "./components/dynamic_tickets/TCList.vue"
import ProjectApiConfigurationCreate from "./components/projectapiconfiguration/ProjectApiConfigurationCreate.vue"
import ProjectApiConfigList from "./components/projectapiconfiguration/ProjectApiConfigList.vue"
import RoleProjectApiMapping from "./components/projectapiconfiguration/roleProjectApiMapping.vue"
import TCView from "./components/dynamic_tickets/TCView.vue"
import Roaster from "./components/configuration/roaster.vue"
import RoasterList from "./components/configuration/roasterlist.vue"
import RoasterUpdate from "./components/configuration/roasterupdate.vue"
import RNFeedbackForm from "./components/releasenote/RNFeedbackForm.vue"
import dlemailcreate from "./components/configuration/dlemailcreate.vue"
import dlemailist from "./components/configuration/dlemailist.vue"
import dlemailupdate from "./components/configuration/dlemailupdate.vue"
import escalationcreate from "./components/escalation_matrix/escalationcreate.vue"
import forgetpassword from "./components/forgetpassword.vue"
import escalationlist from "./components/escalation_matrix/escalationlist.vue"
import escalationupdate from "./components/escalation_matrix/escalationupdate.vue"
import qawisedailyreport from "./components/reports/qawisedailyreport.vue"
import BlockerModuleReport from "./components/reports/BlockerModuleReport.vue"
import emailsanitycreate from "./components/email_sanity/emailsanitycreate.vue"
import Documenthistory from "./components/configuration/documenthistorylist.vue"
import emailsanitylist from "./components/email_sanity/emailsanitylist.vue"
import emailsanityupdate from "./components/email_sanity/emailsanityupdate.vue"
import Sonarcube from "./components/Sonarcube.vue" 
import sonarqubeDetails from "./components/sonarqubeDetails.vue" 
import EMLeaveApplication from "./components/fcclock/EMLeaveApplication.vue" 
import Attendancereport from "./components/fcclock/Attendancereport.vue" 
import Activeuserdetails from "./components/fcclock/Activeuserdetails.vue"
import MyApproval from "./components/fcclock/MyApproval.vue" 
import PMODailyReport from "./components/reports/PMODailyReport.vue";
import BulkAttendance from "./components/fcclock/BulkAttendance.vue" 
import SprintDetails from "./components/reports/SprintDetails.vue"
import JoiningProcess from "./components/hrms/JoiningProcess"

Vue.use(Router)

const router= new Router({
    mode:'hash',    
    routes:[
        {
            path:'/',
            component:Login,
            name:'Login'
        },
        {
            path:'/login',
            component:Login,
            name:'Login'
        },        
        {
            path: '/forgetpassword',
            name: 'forgetpassword',
            component: forgetpassword
        },
        {
            path: '/home',
            component: Home,
            children: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                },
                {
                    path:'/projects',
                    name:'Project',
                    component:ProjectMaster
                }, 
                {
                    path:'/project/summary',
                    name:'ProjectSummary',
                    component:ProjectSummary
                }, 
                {
                    path:'/userdashboard',
                    name:'User Dashboard',
                    component:UserDashboard
                }, 
                {
                    path:'/sonarqube',
                    name:'Sonarcube',
                    component:Sonarcube
                },
                {
                    path:'/sonarqube-details',
                    name:'sonarqubeDetails',
                    component:sonarqubeDetails
                },
                {
                    path:'/Charts',
                    name:'Charts',
                    component:Charts
                }, 
                {
                    path:'/masters',
                    name:'Masters',
                    component:Masters
                }, 
                {
                    path:'/worklogs',
                    name:'Work Logs',
                    component:Worklogs
                },
                {
                    path:'/projectgantt',
                    name:'Project Gantt Chart',
                    component:GanttChart
                },
                {
                    path:'/profile',
                    name:'Profile',
                    component:Profile
                },
                {
                    path:'/project',
                    name:'Project Wizard',
                    component:Project
                },
                {
                    path:'/projectmodule',
                    name:'Project Module',
                    component:Projectmodule
                },
                {
                    path:'/application',
                    name:'Project Application',
                    component:ProjectApplication
                },
                {
                    path:'/applicationmodule',
                    name:'Project Application Module',
                    component:ProjectApplicationModule
                },
                {
                    path:'/sectionmodule',
                    name:'SectionModule',
                    component:SectionModule
                },
                {
                    path:'/functionalitymodule',
                    name:'FuntionalityModule',
                    component:FuntionalityModule
                },
                {
                    path:'/welcome',
                    name:'Welcome Page',
                    component:Welcome
                },
                {
                    path: '/functionality/create',
                    name: 'fcreate',
                    component: fcreate
                },
                {
                    path: '/functionality/list',
                    name: 'flist',
                    component: flist
                },
                {
                    path: '/functionality/update',
                    name: 'fupdate',
                    component: fupdate
                },
                {
                    path: '/functionality/view',
                    name: 'fview',
                    component: fview
                },
                {
                    path:'/workstep',
                    name:'Work Step',
                    component:Workstep
                },
                {
                    path:'/reports/businesstaskhodmaster',
                    name:'Business Task Hod Report',
                    component:hodbusinesstaskreport
                },
                {
                    path:'/rolealloc',
                    name:'project role alloc',
                    component:Projectrolealloc
                },
                {
                    path:'/reports/emplprojtaskreport',
                    name:'User wise performance report',
                    component:EmplProjTaskReport
                },
                {
                    path:'/reports',
                    name:'Reports',
                    component:EmplProjTaskReport
                },
                {
                    path:'/reports/emplprojecttaskdetailedreport',
                    name:'Employee Wise - Project Wise',
                    component:Emplprojecttaskdetailedreport
                },
                {
                    path:'/reports/projectlistreport',
                    name:'Project list Report',
                    component:Projectlistreport
                },
                {
                    path:'/reports/redprojectlistreport',
                    name:'Red Project list Report',
                    component:Redprojectlistreport
                },
                {
                    path:'/reports/employeetotaloverview',
                    name:'Employee Total Overview',
                    component:EmployeeTotalOverview
                },
                {
                    path:'/reports/businesstaskreport',
                    name:'Business Task Report',
                    component:BusinessTaskReport
                },
                {
                    path:'/reports/userperformance2report',
                    name:'Userperformance2 Report',
                    component:Userperformance2report
                },
                {
                    path:'/reports/employeeskillreport',
                    name:'Employee Skill Report',
                    component:Employeeskillreport
                },
                {
                    path:'/reports/activityreport',
                    name:'Employee Activity Report',
                    component:ActivityReport
                },
                {
                    path:'/reports/useractivityreport',
                    name:'User Activity Report',
                    component:UserActivityReport
                },
                {
                    path:'/reports/Employeeleavetransactions',
                    name:'Employee Leave Transactions',
                    component: Employeeleavetransactions
                },
                {
                    path:'/reports/Daywisepassfailreport',
                    name: 'Day Wise Pass Fail Report',
                    component: Daywisepassfailreport
                },
                {
                    path:'/businesstask/list',
                    name:'Business Task List',
                    component:BTMList
                },
                {
                    path:'/tasks/statusmapping',
                    name:'StatusMapping',
                    component:StatusMapping
                },
                {
                    path:'/businesstask/create',
                    name:'Create Business Task ',
                    component:BTMCreate
                },
                {
                    path:'/businesstask/update',
                    name:'BTMUpdate',
                    component:BTMUpdate
                },
                {
                    path:'/businesstask/view',
                    name:'BTMView',
                    component:BTMView
                },
                {
                    path:'/businesstask/delay-dashboard',
                    name:'btcDelayDetails',
                    component:btcDelayDetails
                },
                {
                    path: '/issues/list',
                    name: 'IssueList',
                    component: IssueList
                },
                {
                    path: '/issues/create',
                    name: 'IssueCreate',
                    component: IssueCreate
                },
                {
                    path: '/issues/update',
                    name: 'IssueUpdate',
                    component: IssueUpdate
                },
                {
                    path: '/issues/view',
                    name: 'IssueView',
                    component: IssueView
                },
                {
                    path:'/incident/list',
                    name:'Incident List',
                    component:IncidentList
                },
                {
                    path:'/incident/create',
                    name:'Incident Create',
                    component:IncidentCreate  
                },
                {
                    path:'/incident/update',
                    name:'Incident Update',
                    component:IncidentUpdate
                },
                {
                    path:'/incident/view',
                    name:'Incident View',
                    component:IncidentView 
                },
                 {
                    path:'/testcasetask/update',
                    name:'tupdate',
                    component:tupdate
                },
                {
                    path:'/testcasetask/list',
                    name:'tlist',
                    component:tlist
                },
                {
                    path:'/testcasetask/create',
                    name:'tcreate',
                    component:tcreate
                },
                {
                    path:'/testcasetask/view',
                    name:'tview',
                    component:tview
                },
                {
                    path: '/tasks/list',
                    name: 'TaskList',
                    component: TaskList
                },
                {
                    path: '/tasks/create',
                    name: 'TaskCreate',
                    component: TaskCreate
                },
                {
                    path: '/tasks/update',
                    name: 'TaskUpdate',
                    component: TaskUpdate
                },
                {
                    path: '/tasks/view',
                    name: 'TaskView',
                    component: TaskView
                },
                {
                    path: '/project/roleallotment',
                    name: 'ProjectRoleAllotment',
                    component: ProjectRoleAllotment
                },
                {
                    path: '/appenvironment/list',
                    name: 'appenvironmentList',
                    component: AppEnvironmentList
                },
                {
                    path: '/appenvironment/create',
                    name: 'appenvironmentCreate',
                    component: AppEnvironmentCreate
                },
                {
                    path: '/appenvironment/update',
                    name: 'appenvironmentUpdate',
                    component: AppEnvironmentUpdate
                },
                {
                    path: '/appenvironment/view',
                    name: 'appenvironmentView',
                    component: AppEnvironmentView
                },
                {
                    path: '/workstep/update',
                    name: 'Workstep Update',
                    component: WorkstepUpdate
                },
                {
                    path: '/workstep/list',
                    name: 'Workstep List',
                    component: WorkstepList
                },
                {
                    path:'/workstep/create',
                    name:'wcreate',
                    component:wcreate
                },
                {
                    path: '/workstep/view',
                    name: 'Workstep View',
                    component: WorkstepView
                },
                {
                    path: '/ticket/list',
                    name: 'TicketList',
                    component: TicketList
                },
                {
                    path: '/ticket/create',
                    name: 'TicketCreate',
                    component: TicketCreate
                },
                {
                    path: '/ticket/update',
                    name: 'TicketUpdate',
                    component: TicketUpdate
                },
                {
                    path: '/ticket/view',
                    name: 'TicketView',
                    component: TicketView
                },
                {
                    path: '/releasenote/feedback',
                    name: 'RNFeedbackForm',
                    component: RNFeedbackForm
                },
                {
                    path: '/performance/list',
                    name: 'PerformanceList',
                    component: PerformanceList
                }, 
                {
                    path: '/performance/create',
                    name: 'PerformanceCreate',
                    component: PerformanceCreate
                },
                {
                    path: '/performance/update',
                    name: 'PerformanceUpdate',
                    component: PerformanceUpdate
                },
                {
                    path: '/performance/view',
                    name: 'PerformanceView',
                    component: PerformanceView
                },
                {
                    path: '/myactivity',
                    name: 'MyActivityReport',
                    component: MyActivityReport
                },
                {
                    path: '/training/list',
                    name: 'TrainingList',
                    component: TrainingList
                },
                {
                    path: '/user/training/list',
                    name: 'EmpTrainingList',
                    component: EmpTrainingList
                },
                {
                    path: '/training/articles',
                    name: 'TrainingArticles',
                    component: TrainingArticles
                },
                {
                    path: '/training/create',
                    name: 'TrainingCreate',
                    component: TrainingCreate
                },
                {
                    path: '/training/update',
                    name: 'TrainingUpdate',
                    component: TrainingUpdate
                },
                {
                    path: '/training/view',
                    name: 'TrainingView',
                    component: TrainingView
                },
                {
                    path: '/tasklog/create',
                    name: 'TaskLogCreate',
                    component: TaskLogCreate
                },
                {
                    path: '/tasklog/list',
                    name: 'TaskLogList',
                    component: TaskLogList
                },
                {
                    path:'/reports/empmasterreport',
                    name:'Employee Report',
                    component:Empmasterreport
                },
                {
                    path: '/domain/list',
                    name: 'DomainList',
                    component: DomainList
                },
                {
                    path: '/domain/create',
                    name: 'DomainCreate',
                    component: DomainCreate
                },
                {
                    path: '/searchdetails',
                    name: 'SearchDetails',
                    component: SearchDetails
                },
                {
                    path: '/business/bulkbtcupdate',
                    name: 'BTMBulkUpdate',
                    component: BTMBulkUpdate,
                },
                {
                    path: '/reports/agingreport',
                    name: 'AgingBTReport',
                    component: AgingBTReport,
                },
                {
                    path:'/reports/zinghrreport',
                    name:'Employee Zing Hr Report',
                    component:ZingHrReport
                },
                {
                    path:'/reports/tasktimelogreport',
                    name:'Task Time Log Report',
                    component:TaskTimeLogReport
                },
                {
                    path:'/reports/pgmreport',
                    name:'PGM Report',
                    component:PGMReport
                },
                {
                    path:'/reports/businesstaskdeptmaster',
                    name:'businesstaskdeptmaster',
                    component: businesstaskdeptmaster
                },
                {
                    path:'/reports/businesstaskdeptmaster2',
                    name:'businesstaskdeptmaster2',
                    component: businesstaskdeptmaster2
                },
                {
                    path: '/assessment/examsetting',
                    name: 'ExamSetting',
                    component: ExamSetting
                },
                {
                    path: '/assessment/manageemp',
                    name: 'ManageEmp',
                    component: ManageEmp
                },
                {
                    path:'/assessment/managequestions',
                    name:'Manage Questions',
                    component:ManageQuestions
                },
                {
                    path:'/assessment/assessmentstart',
                    name:'Assessment Start',
                    component:Assessmentstart,
                },
                {    
                    path:'/assessment/testresultlist',
                    name:'Exam Result',
                    component:ExamResult
                },
                {
                    path:'/assessment/testresultview',
                    name:'Exam Result View',
                    component:ExamResultView
                },
                {
                    path:'/hrms/jobopenings',
                    name:'Job Openings',
                    component:JobOpenings
                },
                {
                    path:'/hrms/raiserequisition',
                    name:'RaiseRequisition',
                    component:RaiseRequisition
                },
                {
                    path:'/createreleasenote',
                    name:'CreateReleaseNote',
                    component:CreateReleaseNote
                },
                {
                    path:'/releasenote/update',
                    name:'UpdateReleaseNote',
                    component:UpdateReleaseNote
                },
                {
                    path:'/releasenote',
                    name:'ListReleaseNote',
                    component:ListReleaseNote
                },
                {
                    path:'/releasenote/view',
                    name:'ViewReleaseNote',
                    component:ViewReleaseNote
                },
                {
                    path:'/hrms/requisition',
                    name:'requisition',
                    component:requisition
                },
                {
                    path:'/hrms/hr-dashboard',
                    name:'HrDashboard',
                    component:HrDashboard
                },
                {
                    path:'/hrms/processongoing',
                    name:'Processongoing',
                    component:Processongoing
                },
                {
                    path:'/hrms/candidatesdetail',
                    name:'CandidatesDetail',
                    component:CandidatesDetail
                },
                {
                    path:'/hrms/candidatescheduler',
                    name:'candidatescheduler',
                    component:candidatescheduler
                },
                {
                    path:'/hrms/generateoffer',
                    name:'generateoffer',
                    component:generateoffer
                },
                {
                    path:'/reports/locreport',
                    name:'LOCReport',
                    component:LOCReport
                    },
                {
                    path:'/ratingdashboard',
                    name:'Ratingdashboard',
                    component:Ratingdashboard
                },
                {
                    path:'/mycalendars',
                    name:'mycalendars',
                    component:mycalendars
                },
                {
                    path:'/testcasetask/groupmaster',
                    name:'GroupMaster',
                    component:GroupMaster
                },
                {
                    path:'/roletracker/create',
                    name:'RoleTracker',
                    component:RoleTraMapping
                },
                {
                    path:'/testcase/groupbulkmapping',
                    name:'TCBulkMapping',
                    component:TCBulkMapping
                },
                {
                    path:'/testcase/BulkmappingCreate',
                    name:'BulkmappingCreate',
                    component:BulkmappingCreate
                },
                {
                    path:'/testcase/BulkmappingUpdate',
                    name:'BulkmappingUpdate',
                    component:BulkmappingUpdate
                },
                {
                    path:'/testexecution/testcycleset',
                    name:'TestCycleSetList',
                    component:TestCycleSetList
                },
                {
                    path:'/testexecution/testcyclesetadd',
                    name:'TestCycleSetCreate',
                    component:TestCycleSetCreate
                },
                {
                    path:'/testexecution/testcycleview',
                    name:'TestCycleVeiw',
                    component:TestCycleVeiw
                }, 
                {
                    path:'/testexecution/TestCaseManagement_listView',
                    name:'TestCaseManagement_listView',
                    component:TestCaseManagement_listView
                },
                {
                    path:'/testexecution/testcyclerunview',
                    name:'TestCycleRunVeiw',
                    component:TestCycleRunVeiw
                },
                {
                    path:'/testexecution/testcyclebulkallocation',
                    name:'TestCycleSetAssignee',
                    component:TestCycleSetAssignee
                },
                {
                    path:'/testexecution/testcyclebulklist',
                    name:'TestCycleSetBulkList',
                    component:TestCycleSetBulkList
                },
                {
                    path:'/testexecution/testcyclerunlist',
                    name:'TestCycleSetRunkList',
                    component:TestCycleSetRunkList
                },
                {
                    path:'/testexecution/testcycleproductlist',
                    name:'TestCycleSetProStatusList',
                    component:TestCycleSetProStatusList
                },
                {
                    path:'/testcase/groupbulkView',
                    name:'TCBulkView',
                    component:TCBulkView
                },
                {
                    path:'/testexecution/testcyclesetedit',
                    name:'TestCycleSetUpdate',
                    component:TestCycleSetUpdate
                }, 
                {
                    path:'/planner/planhub',
                    name:'PlanHub',
                    component:PlanHub
                },
                {
                    path:'/planner/viewplan',
                    name:'ViewPlans',
                    component:ViewPlans
                },
                {
                    path:'/document/folders',
                    name:'DocumentMaster',
                    component: DocumentMaster
                },
                {
                    path:'/document/list',
                    name:'DocumentList',
                    component: DocumentList
                },
                {
                    path:'/document/create',
                    name:'DocumentCreate',
                    component: DocumentCreate
                },
                {
                    path:'/document/view',
                    name:'DocumentView',
                    component: DocumentView
                },
                {
                    path:'/fc_mom/momcreate',
                    name:'momcreate',
                    component:momcreate
                },
                {
                    path:'/fc_mom/momupdate',
                    name:'momupdate',
                    component:momupdate
                },
                {
                    path:'/viewmomplanner/view',
                    name:'viewmomplanner',
                    component:viewmomplanner
                },
                {
                    path:'/reports/delayedbtcsummary',
                    name: 'DelayedBusinessTaskSummary',
                    component: DelayedBusinessTaskSummary
                },
                {
                    path:'/reports/releasenoteemreport',
                    name: 'ReleasenoteEMReport',
                    component: ReleasenoteEMReport
                },
                {
                    path:'/reports/btcreleasenotereport',
                    name: 'BTCReleasenoteReport',
                    component: BTCReleasenoteReport
                },
                {
                    path:'/fieldtrackermapping',
                    name:'FieldTrackerMapping',
                    component: FieldTrackerMapping
                },
                {
                    path:'/reports/projectproductivityreport',
                    name:'ProjectProductivityReport',
                    component:ProjectProductivityReport
                },
                {
                    path:'/formsconfig/create',
                    name:'FormsConfigurationCreate',
                    component:FormsConfigurationCreate
                },
                {
                    path:'/formsconfig/list',
                    name:'FormsConfigurationList',
                    component:FormsConfigurationList
                },
                {
                    path:'/feedbackform',
                    name:'FeedbackForm',
                    component:FeedbackForm
                },
                {
                    path:'/myfeedback',
                    name:'FeedbackFormListing',
                    component:FeedbackFormListing
                },
                {
                    path:'/form/formview',
                    name:'FeedbackViewForm',
                    component:FeedbackViewForm
                },
                {
                    path:'/reports/feedbackformreport',
                    name:'FeedbackFormReport',
                    component:FeedbackFormReport
                },
                {
                    path:'/statusconfig/create',
                    name:'statusConfigCreate',
                    component:statusConfigCreate
                },
                {
                    path:'/statusconfig/list',
                    name:'statusConfigList',
                    component:statusConfigList
                },
                {
                    path: '/teamactivity',
                    name: 'TeamActivityReport',
                    component: TeamActivityReport
                },
                {
                    path: '/logtimeactivity',
                    name: 'LogtimeActivity',
                    component: LogtimeActivity
                },
                {
                    path: '/appraisal/create',
                    name: 'CreateAppraisalCycle',
                    component: CreateAppraisalCycle
                },
                {
                    path: '/performance/appraisal',
                    name: 'ListAppraisalCycle',
                    component: ListAppraisalCycle
                },
                {
                    path: '/appraisal/report',
                    name: 'AppraisalReport',
                    component: AppraisalReport
                },
                {
                    path: '/rnfeedback/report',
                    name: 'RPFeedbackReport',
                    component: RPFeedbackReport
                },
                {
                    path: '/reports/blockerbtc',
                    name: 'BlockerModuleReport',
                    component: BlockerModuleReport
                },
                {
                    path: '/appraisal/ratingreport',
                    name: 'AppraisalRaitingReport',
                    component: AppraisalRaitingReport
                },
                {
                    path:'/reports/btcweeklyworkstatuscount',
                    name:'btcweeklyworkstatuscount',
                    component: btcweeklyworkstatuscount
                },
                {
                    path:'/employeeroleconfiguration',
                    name:'EmpProjectRoleConfiguration',
                    component: EmpProjectRoleConfiguration
                },
                {
                    path:'/slaconfiguration',
                    name:'SlaConfiguration',
                    component:SlaConfiguration
                },
                {
                    path:'/trackermaster',
                    name:'TrackerMaster',
                    component: TrackerMaster
                },
                {
                    path:'/trackerlisting',
                    name:'TrackerListing',
                    component: TrackerListing
                },
                {
                    path:'/appraisalcyclemaster/create',
                    name:'Add AppraisalCycle',
                    component:AddAppraisalCycle
                },
                {
                    path:'/appraisalcyclemaster',
                    name:'ListingAppraisalCycle',
                    component: listingAppraisalCycle
                },
                {
                    path:'/reports/dailygroupfreezreport',
                    name:'dailygroupfreezreport',
                    component: dailygroupfreezreport
                },
                {

                    path:'/appraisalcyclemaster/addApplicableEmployees',
                    name:'createAppraisalApplicableEmployees',
                    component: createAppraisalApplicableEmployees
                },
                {
                    path:'/appraisalcyclemaster/addAppraisalTrackers',
                    name: 'createAppraisalTrackers',
                    component: createAppraisalTrackers
                },
                {
                    path: '/appraisalcyclemaster/appraisalApplicableEmployees',
                    name:'ApplicableAppraisalEmp',
                    component: ApplicableAppraisalEmp
                },
                {
                    path: '/reports/testcyclereport',
                    name:'TestCycleReport',
                    component: TestCycleReport
                },
                {
                    path:'/reports/testcasetimeanalysis',
                    name:'testcasetimeanalysis',
                    component: testcasetimeanalysis
                },
                {
                    path: '/reports/qareports',
                    name:'QAREPORTS',
                    component: QAREPORTS,
                },
                {
                    path: '/report/testcycledetailslevelreport',
                    name: 'TestcycleDetailsLevelReport',
                    component: TestcycleDetailsLevelReport
                },
                {
                    path:'/reports/testcasedetaillevelreport',
                    name:'testcasedetaillevelreport',
                    component: testcasedetaillevelreport
                },
                {
                    path: '/typemodule/listing',
                    name: 'TypeModuleListiing',
                    component: typeModuleListing
                },
                {
                    path:'/dynamic_tickets/tccreate',
                    name:'TCCreate',
                    component:TCCreate
                },
                {
                    path:'/dynamic_tickets/tcupdate',
                    name:'TCUpdate',
                    component:TCUpdate
                },
                {
                    path:'/config/btctaskcreationconfig',
                    name: 'BtcTaskCreationConfig',
                    component: BtcTaskCreationConfig
                },
                {
                    path: '/formsconfig/update',
                    name: 'UpdateFormConfiguration',
                    component: UpdateFormConfiguration
                },
                {
                    path:'/dynamic_tickets/tclist',
                    name:'TCList',
                    component:TCList
                },
                {
                    path:'/dynamic_tickets/tcview',
                    name:'TCView',
                    component:TCView
                },
                {
                    path:'/projectapiconfig/create',
                    name:'ProjectApiConfigurationCreate',
                    component:ProjectApiConfigurationCreate
                },
                {
                    path:'/projectapiconfig/list',
                    name:'ProjectApiConfigList',
                    component:ProjectApiConfigList
                },
                {
                    path:'/projectapiconfig/roleprojectapimap',
                    name:'RoleProjectApiMapping',
                    component:RoleProjectApiMapping
                },
                {
                    path:'/configuration/roaster',
                    name:'Roaster',
                    component:Roaster
                },
                {
                    path:'/configuration/roasterlist',
                    name:'RoasterList',
                    component:RoasterList
                },
                {
                    path:'/configuration/roasterupdate',
                    name:'RoasterUpdate',
                    component:RoasterUpdate
                },
                {
                    path:'/configuration/dlemailcreate',
                    name:'dlemailcreate',
                    component:dlemailcreate
                },
                {
                    path:'/configuration/dlemailist',
                    name:'dlemailist',
                    component:dlemailist
                },
                {
                    path:'/configuration/dlemailupdate',
                    name:'dlemailupdate',
                    component:dlemailupdate
                },
                {
                    path:'/escalation_matrix/escalationcreate',
                    name:'escalationcreate',
                    component:escalationcreate
                },
                {
                    path:'/escalation_matrix/escalationlist',
                    name:'escalationlist',
                    component:escalationlist
                },
                {
                    path:'/escalation_matrix/escalationupdate',
                    name:'escalationupdate',
                    component:escalationupdate
                },
                {
                    path:'/reports/qawisedailyreport',
                    name:'qawisedailyreport',
                    component:qawisedailyreport
                },
                {
                    path:'/email_sanity/emailsanitycreate',
                    name:'emailsanitycreate',
                    component:emailsanitycreate
                },
                {
                    path:'/configuration/documenthistory',
                    name:'Documenthistory',
                    component:Documenthistory
                },
                {
                    path:'/email_sanity/emailsanitylist',
                    name:'emailsanitylist',
                    component:emailsanitylist
                },
                {
                    path:'/email_sanity/emailsanityupdate',
                    name:'emailsanityupdate',
                    component:emailsanityupdate
                },{
                    path: '/fcclock/bulkAttendance',
                    name: 'BulkAttendance',
                    component: BulkAttendance
                },
                {
                    path:'/fcclock/attendancereport',
                    name:'Attendancereport',
                    component:Attendancereport
                },
                {
                    path:'/fcclock/activeuserdetails',
                    name:'Activeuserdetails',
                    component:Activeuserdetails
                },
                {
                    path:'/fcclock/myapproval',
                    component:MyApproval,
                    name:'MyApproval'
                },
                {
                    path: '/fcclock/emergencyleave',
                    name: 'EMLeaveApplication',
                    component: EMLeaveApplication
                },
                {
                    path: '/reports/sprint-details',
                    name: 'SprintDetails',
                    component: SprintDetails
                },{
                    path:'/reports/pmodailyreport',
                    name:'PMODailyReports',
                    component:PMODailyReport
                },
                {
                    path:'/hrms/joiningprocess',
                    name:'Joining Process',
                    component:JoiningProcess
                },
                
            ]
        }, 
        {
            path:'/userprofile',
            component:UserProfile
        }, 
        


        // Merge in the routes from trsrouter.js
        ...trsRouter,
    ]
});

router.beforeEach((to, from, next) => {
    let isLoggedIn = localStorage.getItem('userdata')
    if(from.name && from.name == 'forgetpassword' ){
        sessionStorage.removeItem('pathToLoadAfterLogin');
    }else{
        if(!isLoggedIn && from.name) {
            sessionStorage.setItem('pathToLoadAfterLogin', from.fullPath)
        } else if (!isLoggedIn && from.name && !to.name) {
            sessionStorage.removeItem('pathToLoadAfterLogin');
        }
        else if (isLoggedIn && to.name=='Login') {
            // If the user is logged in, redirect to that path
            return next({ path: '/welcome' })
        }
    }
    next()
})

export default router