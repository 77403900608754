<template>
    <div class="app-content content">
        <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard" class="pb-1 d-none">
                <div class="content-header-left mb-0 pt-1">
                    <div class="container-fluid">
                        <div class="row breadcrumbs-top">
                            <div class="col-md-9 content-header-left">
                                <h3 class="content-header-title float-left">Job Openings</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item">HRMS</li>
                                        <li class="breadcrumb-item sitebluecolor cursorpointercls">Job Openings</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            
            <section class="modern-horizontal-wizard card_views_hrms">
                    <div class="bs-stepper wizard-modern modern-wizard-example card ">
                        
                        <div class="bs-stepper-content pt-0 px-0">
                            <div class="bs-stepper-header px-0 hrms_header_backgroung">
                            <div class="step" data-target="#account-details-modern">
                                <button type="button" class="step-trigger px-2">
                                    <span class="bs-stepper-box">
                                        <em data-feather='users' class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Job Details</span>
                                        <span class="bs-stepper-subtitle">Information</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em data-feather="chevron-right" class="font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#personal-info-modern">
                                <button type="button" class="step-trigger">
                                    <span class="bs-stepper-box">
                                        
                                        <em data-feather='layers' class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Job Posting</span>
                                        <span class="bs-stepper-subtitle">  Info</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em data-feather="chevron-right" class="font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#address-step-modern">
                                <button type="button" class="step-trigger">
                                    <span class="bs-stepper-box">
                                        <em data-feather='layers' class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Job Application</span>
                                        <span class="bs-stepper-subtitle">Info</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em data-feather="chevron-right" class="font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#social-links-modern">
                                <button type="button" class="step-trigger">
                                    <span class="bs-stepper-box">
                                        <em data-feather="link" class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"> Hiring Workflow </span>
                                        <span class="bs-stepper-subtitle">Process</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em data-feather="chevron-right" class="font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#hiring_team">
                                <button type="button" class="step-trigger px-1">
                                    <span class="bs-stepper-box">
                                        <em data-feather='users' class="font-medium-3"></em>
                                    </span>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title"> Hiring Team </span>
                                        <span class="bs-stepper-subtitle">Process</span>
                                    </span>
                                </button>
                            </div>
                        </div>

                        <hr class="my-0">
                             
                              <JobDetails  @setGlobaljdid = "setGlobaljdid" :globaljdid = "globaljdid"/>
                              <JobPostings :globaljdid = "globaljdid"/>
                              <JobApplicationDynamic :globaljdid = "globaljdid"/>
                              <HiringWorkflow :globaljdid = "globaljdid"/>
                              <HiringTeam :globaljdid = "globaljdid" @setGlobaljdid = "setGlobaljdid"/>
                        </div>
                    </div>
                </section>
        </div>
    </div>
  </template>
  <script>
  import Loading from 'vue-loading-overlay';
  import "vue-loading-overlay/dist/vue-loading.css";
  import JobDetails from "./components/JobDetails";
  import JobPostings from "./components/JobPostings";
  import HiringWorkflow from "./components/HiringWorkflow";
  import HiringTeam from "./components/HiringTeam";
  import JobApplicationDynamic from "./components/JobApplicationDynamic";
  import apiUrl from '../../constants' 
  import DatePicker from "vue2-datepicker";

  export default {
    name: "Job Openings",
    components: {
        DatePicker,
        JobDetails,
        JobPostings,
        HiringWorkflow,
        HiringTeam,
        JobApplicationDynamic,
        Loading
    },
    data() {
    return {
        defaultValue: new Date(),
        isLoading: false,
        fullPage: true,
        globaljdid:0
    }
},
methods:{
    setGlobaljdid:function(jdid){
        this.globaljdid = jdid;
    }
},
mounted() {
//     $(function () {
//   'use strict';

//   var bsStepper = document.querySelectorAll('.bs-stepper'),
//     select = $('.select2'),
//     modernWizard = document.querySelector('.modern-wizard-example')

//   // select2
//   select.each(function () {
//     var $this = $(this);
//     $this.wrap('<div class="position-relative"></div>');
//     $this.select2({
//       placeholder: 'Select value',
//       dropdownParent: $this.parent()
//     });
//   });

//   if (typeof modernWizard !== undefined && modernWizard !== null) {
//     var modernStepper = new Stepper(modernWizard, {
//       linear: false
//     });
//     $(modernWizard)
//       .find('.btn-next')
//       .on('click', function () {
//         modernStepper.next();
//       });
//     $(modernWizard)
//       .find('.btn-prev')
//       .on('click', function () {
//         modernStepper.previous();
//       });

//     $(modernWizard)
//       .find('.btn-submit')
//       .on('click', function () {
//         alert('Submitted..!!');
//       });
//   }
  
// if (feather) {
//     feather.replace({
//         width: 14,
//         height: 14
//     });
// }
// });
  }

  };

  </script>
 