<template>
  <div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"  color="orange"  loader="dots" :width="100" :height="100"></loading>
      <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
          <section id="modern-horizontal-wizard">
              <div class="row breadcrumbs-top">
                  <div class="col-12">
                      <h3 class="content-header-title float-left mb-1">Team Activity</h3>
                      <div class="breadcrumb-wrapper">
                      <ol class="breadcrumb">
                          <li class="breadcrumb-item sitebluecolor cursorpointercls">Team Activity</li>
                      </ol>
                      </div>
                  </div>
              </div>
              <div class="card border-warning filter_card_searchbgst">
                  <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                      <div class="container-fluid">
                          <div class="row row fc_my-05">
                              <div class="w-auto px-1 mobile-padding-0-2">
                                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                                      <div class="avatar-content">
                                          <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                      </div>
                                  </div>
                                  <h4 class="card-title d-inline-flex ml-1">Search Team Activity Details</h4>
                              </div>
                              <div class="ml-auto d-flex mr-md-2">
                          
                              <span class="rotetions_view_arrow"> 
                                  <em class="las la-angle-down"></em>
                              </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div v-bind:class="[{show: isRequestActive},card_content,collapse]">
                      <div class="card-body px-sm-mobile-10">
                          <div class="row">
                             
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                                <label class="form-label lable-left">EMP Name</label>
                                <treeselect class="allotedto capitalisetext" 
                                 placeholder="Enter min 4 chars to search emp name"
                                 v-on:search-change="getEmpListByProjectAssigned" 
                                 :multiple="true" :clear-on-select="true" v-model="search.userempid"
                                 :options="emplist" @input="selassignedto(emplist)"/>
                            </div>
                             
                              <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                                  <div class="">
                                      <label for="projectmdllistdd">Status</label>
                                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="search.activitystatus" :multiple="true" :options="statuslist"/>
                                  </div>
                              </div>
                               
                              <div class="col-sm-12 col-md-12 col-lg-12 text-right mb-0 px-0">
                                  <button type="submit" class="btn btn-relief-primary mt-2 mr-1"  @click="getteamactivitylist(search,'',1,'flagValue')"> 
                                  <i class="custom-class las la-at" style="font-size: 15px;"></i> <span>Mail Me</span></button>
                                  <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                  <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                                  <!-- <button 
                                type="move" 
                                class="btn btn-relief-primary  mt-2 mr-1" 
                                @click="ExportXL()"
                                > <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                                </button> -->
                                <button  type="submit" class="btn btn-relief-primary  mt-2 mr-1" @click="updateLogtime('approved')"
                                :disabled="teamactivitylist.length && !isChecked.some(bool => bool === true)">
                                <edit-icon size="1.5x" class="custom-class"></edit-icon><span> Approve</span>
                                </button>
                                <button type="submit" class="btn btn-relief-primary  mt-2 mr-1" @click="updateLogtime('rejected')"
                                :disabled="teamactivitylist.length && !isChecked.some(bool => bool === true)" >
                                <edit-icon size="1.5x" class="custom-class"></edit-icon><span> Reject</span>
                                </button>
                              </div>
                  

                          </div>
                      </div>
                  </div>
              </div>
             
              <div class=" card border-warning"  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top:1rem; margin-bottom: 1rem !important;">
                  <div class="show card-content collapse">
                      <div class="row">
                          <div class="col-md-12 1">
                              <div class="card ">
                                  <div class="">
                                      <div class="tab-content" id="myTabContent">
                                      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                          <div class="row" id="table-small">
                                          <div class="col-12">
                                              <div class="card">
                                              <div class="table-responsive " >
                                                <table  class="table table-sm dataTableListing table-striped all_table_stickys" aria-describedby="Release Note List">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col" class="align-middle  first-col-sticky" style="min-width:45px;">
                                                        <span class="">
                                                        <label for="select_all_checkboxes"></label>
                                                        <input type="checkbox" id="select_all_checkboxes" class="visuallyhidden" @click="selectAll"
                                                        v-model="isSelectAllChecked" :disabled="!teamactivitylist.length">
                                                        </span>
                                                        </th>
                                                        <th scope="col" class="align-middle  text-left second-col-sticky" >#</th>
                                                        <th scope="col" class=" align-middle  text-left second-col-sticky" style="min-width:160px;">Project Name</th>
                                                        <th scope="col" class="align-middle  text-left" style="min-width:160px;">Log Time Date</th>
                                                        <th scope="col" class="align-middle  text-left">User</th>
                                                        <th scope="col" class="align-middle  text-center">Reporting Manager</th>
                                                        <th scope="col" class="align-middle  text-left">Activity</th>
                                                        <th scope="col" class="align-middle  text-center">Hours</th>
                                                        <th scope="col" class="align-middle  text-center" style="min-width:150px;">Module Type ID </th>
                                                        <th scope="col" class="align-middle  text-left" style="min-width:190px;">Module Name </th>
                                                        <th scope="col" class="align-middle  text-center">Module Type </th>
                                                        <th scope="col" class="align-middle  text-center" style="min-width:150px;">Business Task Code</th>
                                                        <th scope="col" class="align-middle  text-center" style="min-width:180px;">Business Task Name </th>
                                                        <th scope="col" class="align-middle  text-left">Comment </th>
                                                        <th scope="col" class="align-middle  text-center">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <template v-for="(data,index) in teamactivitylist"> 
                                                            <tr class="release_notemerge_font"  v-bind:key="index">
                                                        <td class="first-col-sticky"  v-if = "data.activitystatus =='pending'" >
                                                        <span class="first-col-sticky">
                                                        <label :for="index + '-' + data.projectid"></label>
                                                        <input type="checkbox" :id="index + '-' + data.projectid" v-model="isChecked[index]"
                                                        class="delete_checkbox" @click="alterSelectAllCheck(index)">
                                                        </span>
                                                        </td>
                                                        <td v-else></td>
                                                                <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                        <td :class="'text-left capitalisetext second-col-sticky unique' +index +1 +currPageNum * pagelimit" >
                                        {{ data.projectname }}
                                        </td>
                                        <td class="capitalisetext align-middle ">
                                          {{
                                            data.logdate ? dateFormat( data.logdate): ""
                                          }}
                                        </td>
                                        <td class="text-left capitalisetext">
                                          {{ data.username }}
                                        </td>
                                        <td class="text-left capitalisetext">
                                          {{ data.reportingmanager }}
                                        </td>
                                        <td class="text-left capitalisetext">
                                          {{ data.activity }}
                                        </td>
                                        <td>
                                          {{
                                            data.spenttime ? data.spenttime.slice(0, 5): "NA"
                                          }}
                                        </td>
                                        
                                        <td class="text-left capitalisetext">
                                          {{ data.code }}
                                        </td>
                                       
                                        <td v-if = "data.modulename">
                                          <span v-if="data.modulename.length>=150"> {{ data.modulename.substring(0,150)+".." }}</span>
                                          <span v-else> {{ data.modulename }}</span>
                                        </td>
                                        <td v-else>NA</td>
                                        <td class="text-left capitalisetext">
                                          {{ data.moduletype }}
                                        </td>
                                        <td class="text-nowrap capitalisetext">
                                          {{ data.business_code }}
                                        </td>
                                        <td class="text-wrap capitalisetext">
                                          <span v-if="data.taskname && data.taskname.length>=50" v-html="data.taskname.substring(0,50)+'..'" ></span>
                                          <span v-else v-html="data.taskname"></span>
                                        </td>
                                        <!-- <td class="text-wrap">
                                          <span v-if="data.coments &&data.coments.length >= 150" v-html="data.coments.substring(0, 150) +'..'"></span>
                                          <span v-else v-html="data.coments"></span>
                                        </td> -->
                                        <td class="text-left tast_case_groupingview_readmore my_activity_readmorelist">
                                           
                                           <div class="col-md-12 mt-0 px-0 tast_case_groupingview_readmore my_activity_readmorelist">
                                               <div class="custom-container case_bg_17">
                                                   <div class="outer" :id="'cycle_readmore2'+index">
                                                       <span class="fc_m16_btcode_text" v-if="data.coments" v-html="formatData(data.coments)"></span>
                                                       
                                                       <span class="fc_m16_btcode_text" v-else v-html="data.coments"></span>
                                                       
                                                   </div>
                                                   <div class="cycle_viewmoretext" v-if="data.coments && data.coments.length > 150">
                                                       <a class="btn">
                                                       <strong :id="'id2'+index" @click="teamactivitycomment(index)">
                                               Read More
                                           </strong>
                                                       </a>
                                                   </div>
                                               </div>
                                           </div>
                                       </td>
                                        <td class="text-nowrap capitalisetext">
                                          {{ data.activitystatus }}
                                        </td>
                                                            </tr>  
                                                        </template>
                                                        <template v-if="teamactivitylist.length==0">
                                                            <tr><td colspan="10" style="text-align: center;">No record found</td></tr>
                                                        </template>
                                                    </tbody>
                                                    </table>
                                              </div>
                                              </div>
                                          </div>
                                          </div>
                                      </div>
                                      <div class="pageCenter text-center mt-4">
                                          <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                      </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- popup -->
          </section>
      </div>
  </div>
</template>
<script>
import axios from "axios";
import apiUrl from '../../constants';
import moment from 'moment'
import Loading from "vue-loading-overlay";
import Pagination from 'vue-pagination-2';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {BellIcon,InfoIcon,BookIcon,LinkIcon, ExternalLinkIcon,MessageSquareIcon,EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon } from 'vue-feather-icons'
import DatePicker from "vue2-datepicker";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "ListReleaseNote",
  mixins: [ commonMethods ],
  components: {
      Loading,Treeselect,DatePicker,ExternalLinkIcon,
      BellIcon,InfoIcon,BookIcon,LinkIcon, MessageSquareIcon,EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon,
      Pagination
  },
  data() {
      return {
          isLoading: false,
          fullPage: true,
          islistLoading:false,
          listfullPage:true,
          isRequestActive:false,
          card_content:'card-content',
          collapse:'collapse',
          tokendata:null,
          undt:[],
          isBothDateSelected:false,
          statuslist:[
              {
                  'id':'pending',
                  'label':'Pending For Approval'
              },
              {
                  'id':'approved',
                  'label':'Approved'
              },
              {
                  'id':'rejected',
                  'label':'Rejected'
              }
          ],
          search:{
              activitystatus:['pending'],
              userempid:null
              
          },
           totalcount: 0,
          pageCount: 0,
          page:1,
          pagelimit:apiUrl.LIMIT,
          currPageNum: 0,
          pageoffset:1,
          teamactivitylist: [],
          maxLength:15,
          loginusername:'',
          loginuseremail:'',
          loginuserid: null,
          approavalData : {
              rnotecode : null,
              rnversion: null,
              createdby: null,
              createddate: null,
              rid: null,
              comment: null,
              commentErr: null,
          },
          emplist:[],
          isChecked: [],
          isSelectAllChecked: false,
          parentmoduledata: null,
          searchSession: false,
          searchOffset: 1,
        }
  },
  mounted() {
      var userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt=userdata
        this.loginuserid = userdata.userid
        this.tokendata = window.localStorage.getItem('token');  
        this.loginusername= userdata.firstname+' '+userdata.lastname
        this.loginuseremail = userdata.emailaddress;
        this.getProjectAllocatedToEmp()
        this.getteamactivitylist()
        
        // if(this.$route.params.search){
        // let search = JSON.parse(localStorage.getItem('teamactivitysearchItems'))
        // if(search) {
        //     this.getSearchItems(search)
        // }
        // this.getteamactivitylist(search, "", 1,"");     
        // }else{
        // let rowdata={}
        // this.getteamactivitylist(rowdata);
        // }
      }
      $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
  });
  },
  methods:{
    updateLogtime(status){
        let businessIds = []
      this.teamactivitylist.map((btm, idx) => {
        if (this.isChecked[idx]) {
            if(btm.activitystatus=='pending'){
          businessIds.push(parseInt(btm.id))
            }
        }
      })
      let payload = new Object({
        lastmodifiedby: this.undt.username,
        activitystatus:status,
        businessids: businessIds,
        empcode: this.undt.userid,
        useremail: this.undt.username,
      })
     
      if (businessIds.length) {
        let url = 'api/teamactivity/bulkupdateemptimelog'
        axios({
          method: "POST",
          url,
          data: payload,
          headers: { authorization: this.tokendata }
        }).then(result => {
          this.search.activitystatus=null;
          this.search.userempid=null;
           if (result.data.errorCode === 0) {
            this.getteamactivitylist()
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          } else if (result.data.errorCode == 3) {
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function () {
              window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              text: result.data.msg,
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          }
        }).catch(e => {
        this.displayError(e)
      })
      }else{

          Swal.fire({
              text: 'Please select',
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
      }
     },
    countChecked(previousValue, currentValue, index) {
      let returns = previousValue
      if (this.isChecked[index]) {
        returns = previousValue + 1
      }
      return returns
    },
    viewtrans(d) {
      $('#technologypopup').modal('show');
    },
    selectAll() {
      if (!this.isSelectAllChecked) {
        this.isChecked = Array(this.totalcount).fill(true)
      } else {
        this.isChecked = Array(this.totalcount).fill(false)
      }
    },

    alterSelectAllCheck(index) {
      if (this.isChecked[index]) {
        this.isSelectAllChecked = false
      } else {
        this.isSelectAllChecked = this.isChecked.every((i, idx) => {
          if (idx !== index) {
            return i === true
          } else {
            return true
          }
        })
      }
    },
    teamactivitycomment(index){
            
            if ($("#cycle_readmore2"+index).hasClass("readmore")) {
                $("#id2"+index).html("Read More");
                $("#cycle_readmore2"+index).removeClass("readmore");
            } else {
                $("#id2"+index).html("Read Less");
                $("#cycle_readmore2"+index).addClass("readmore");
            }
        },
      notBeforeToday: function (date) {
          this.search.createddateto = "";
          return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      selectedfromdate(){
          if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
              this.isBothDateSelected = false
          }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
              this.isBothDateSelected = true
          }
      },
      selectedtodate(){
          if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
              this.isBothDateSelected = false
          }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
              this.isBothDateSelected = true
          }
      },
      disabledBefore(date) {
          let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
          const beforeToday = new Date(dayBefore);
                  beforeToday.setHours(0, 0, 0, 0);
          return date < beforeToday; 
      },
      mysearchrequestollapse:function(){
          this.isRequestActive=!this.isRequestActive
      },
      selissuecsproject: function(state,value){
          this.search.project=null
          this.search.project=state
          if (!state) {
              this.search.approvedby = null
              this.approvalmanager = []
          }else{
              this.getEngManByPro(this.search.project, [492]);
          }
      },
      getProjectAllocatedToEmp() {
          this.isLoading = true
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.search.projectlist = result.data.data;
                  localStorage.setItem('projectlist', JSON.stringify(result.data.data))

              } else {
                  this.search.projectlist = [];
                  localStorage.removeItem('projectlist')

              }
          }).catch(e => {
            this.displayError(e)
        });
      },
      
      getEngManByPro(proid, roleid) {
          axios({
              method: "POST",
              url: "api/releasenote/getEMforreleasenote",
              headers: { authorization : this.tokendata },
              data:{roleid:roleid,projectidarr:[proid],useremail: this.undt.username,empcode: this.undt.userid}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.approvalmanager = result.data.data.rows
              }else{
                  this.approvalmanager = [];
              }
          }).catch(e => {
            this.displayError(e)
        });
      },
      clearCodeList(state) {
          if(!state) {
          this.search.releasecodelist = null
          }
      },
      getteamactivitylist(flagValue,objThis,uvar,flag){
        console.log(flagValue,'falgvalue--')
        console.log(flagValue,'objThis------')
        console.log(flag,'falg----------')
        
          if(objThis) {
              localStorage.setItem('teamactivitysearchItems', JSON.stringify(objThis))
              this.parentmoduledata = objThis;
          }
          this.islistLoading = true
          this.input = {  
          useremail: this.undt.username,
          empcode: this.undt.userid,
          //mangerid:[this.undt.userid],
          };
          let search = JSON.parse(localStorage.getItem('teamactivitysearchItems'))
          if(flag==1){
          this.pageoffset=1
          this.page=1
          }
          if(search && flag == 1) {
       
          this.searchSession = true
          this.input.offset = this.searchOffset
          }else{
          this.searchSession = false
          this.searchOffset = 1
          }
          
          if(flagValue){
            this.input.mailme = 1
            this.input.offset = null,
            this.input.limit =null
        }
          this.input.activitystatus = this.search.activitystatus ? this.search.activitystatus : null
          this.input.empid = this.search.userempid ? this.search.userempid : null
          this.input.offset = this.pageoffset 
          this.input.limit =apiUrl.LIMIT

          let url = "api/teamactivity/empactivitylist";
        
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              this.islistLoading = false;
              if (result.data.errorCode == 0) {
                  this.teamactivitylist = result.data.data.rows;
                  this.totalcount = result.data.data.count
                  this.isChecked = Array(this.totalcount).fill(false)
                  this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                  if(flagValue && !this.totalcount == 0 ){
                    Swal.fire({
                    title: "Success!",
                    text: "Export file link generated on your email",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    });
                }
              } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
                  this.teamactivitylist = [];
                  this.totalcount = 0
                  this.pageCount = 0
              }
          }).catch(e => {
        this.displayError(e)
      });
      },
      resetRecords: function(){
          this.search.releasecode = null;
          this.search.project=null;
          this.search.approvedby=null;
          this.search.createddateto=null;
          this.search.createddatefrom=null;
          this.search.status=null;
          this.search.activitystatus=['pending'];
          this.search.userempid=null;
          this.pageoffset=1
          this.page=1
          this.searchSession = false
          this.searchOffset = 1
          this.emplist = []
          this.getteamactivitylist();
      },
      clickCallback: function(pageNum) {

        this.pageoffset = pageNum;
    if(this.searchSession) {
      this.searchOffset = pageNum;
    }
 
    this.currPageNum = pageNum - 1;
  
    this.getteamactivitylist(this.parentmoduledata);

      //     this.pageoffset=pageNum
      //     this.currPageNum = pageNum - 1
      //     this.getteamactivitylist();
      // setTimeout(function(){
      //     $('html, body').animate({
      //         scrollTop: $("#myTabContent").offset().top -70
      //     });
      // }, 500);
      },
      dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
      },
      dateMONFormat(value){
      if (!value) return "";
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    },
      getSearchItems(search) {
      this.isSearchRequestActive = true
      this.search.userempid = search.userempid
      this.search.activitystatus = search.activitystatus

      },
      getEmpListByProjectAssigned(node) {
          if(node != null && node.length > 3) {
              axios({
                  'method': 'POST',
                  'url': 'api/listing/getEmpListWithFourChar',
                  'data': {
                      empid : parseInt(this.undt.userid),
                      useremail : this.undt.username,
                      empcode :  this.undt.userid,
                      node : node ? node.toLowerCase() : null,
                  },
                  'headers':{'authorization':this.tokendata}
              }).then(result => {
                  // this.isLoading = false
                  if(result.data.errorCode == 0) {
                  this.emplist = result.data.data
                  } else {
                  this.emplist = null
                  }
              }).catch(e => {
                this.displayError(e)
            })
          }
      },
      selassignedto(state) {
          if(!state) {
              this.emplist=[]
          } else {
              this.emplist=state
          }
      },
      ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            let assignedate=''
            if(this.search.createddateto){
            assignedate = new Date(this.search.createddateto)
            assignedate.setDate(assignedate.getDate() + 1)
            moment(String(assignedate)).format('YYYY-MM-DD');
            }
            this.input = {
                limit:null,
                offset:null,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                activitystatus : this.search.activitystatus ? this.search.activitystatus : null,
                empid : this.search.userempid ? this.search.userempid : null,
                mangerid:[this.undt.userid]
            }; 
            axios({
                method: "POST",
                url: `api/teamactivity/empactivitylist`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if (result.data.errorCode == 0) {
                this.exportxlsdata = result.data.data.rows;
                var filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
                this.islistLoading = false;
            }).catch(e => {
                this.displayError(e)
            });
        },
        formatData(data) {
            return data
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                var ShowLabel = 'TeamActivityDetails_'+filename
                var JSONData = readExportdata
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    
                    CSV = 'Sr#,ProjectName,LogTimeDate,User,ReportingManager,Activity,Hours,ModuleTypeID,ModuleName,ModuleType,BusinessTaskCode,BusinessTaskName,Status\r\n'

                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1

                 let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
                let logdate = this.dateMONFormat(index.logdate);

                let username = index.hasOwnProperty('username') ? index.username : '';
                let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager : '';
                let activity = index.hasOwnProperty('activity') ? index.activity : '';
                let spenttime = index.hasOwnProperty('spenttime') ? index.spenttime.slice(0, 5) : '';
               let code = index.hasOwnProperty('code') ? index.code : '';
 
               let modulename = index.hasOwnProperty('modulename') ? index.modulename : '';
               let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype : '';

               let business_code = index.hasOwnProperty('business_code') ? index.business_code : '';
               let taskname = index.hasOwnProperty('taskname') ? index.taskname : '';
                let activitystatus = index.hasOwnProperty('activitystatus') ? index.activitystatus : '';


                    row += '"' + srno + '",',
                    row += '"' + projectname + '",',
                    row += '"' + logdate + '",',
                    row += '"' + username + '",',
                    row += '"' + reportingmanager + '",',
                    row += '"' + activity + '",',
                    row += '"' + spenttime + '",',
                    row += '"' + code + '",',
                    row += '"' + modulename + '",',
                    row += '"' + moduletype + '",',
                    row += '"' + business_code + '",',
                    row += '"' + taskname + '",',
                    row += '"' + activitystatus + '",'
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                return;
                }
                var fileName = 'TeamActivityDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });  
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
            },
  }
}
</script>