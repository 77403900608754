<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div v-if="pagefeatures.projectsummary" class="content-wrapper pb-1">
      <section class="project_summarysviews001" id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left content-header-left mb-1 pt-0">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-12 content-header-left pl-0">
                <h3 class="content-header-title float-left mb-0">Project Summary</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls"
                    @click="redirecttobreadkumb({
                      name: 'Project Wizard',
                      path: 'project',
                      btnstate: 'view',
                      rowdata: urlId,
                    })">Project
                  </li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls">
                    <router-link to='/project/summary'>Summary</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="card border-warning filter_card_searchbgst" style="border-radius: 5px; border: none!important;">
        <div class="card-header filter_rotation_onlick filter_card_searchbggss" style="cursor:pointer" v-on:click="mysearchrequestollapse">
          <div class="container-fluid">
            <div class="row">
              <div class="w-auto px-1 mobile-padding-0-2 mobile_w_calc_38 d-inline-flex align-items-center">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                          <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                          </search-icon>
                      </div>
                  </div>
                   <h4 class="card-title d-inline-flex ml-1">Search Project Summary Details</h4>
              </div>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
          </div>
        </div>
        <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
          <div class="card-body pt-2">
            <div class="row">
              <div class="col-12 col-sm-4 col-md-4 col-lg-3 mb-1">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectid" :clear-on-select="true" :options="search.projectnameList" @input="this.selProj"/>
              </div>
              <div class="form-group datePicker col-12 col-sm-4 col-md-4 col-lg-3">
                  <label for="">From Date</label>
                      <date-picker placeholder="Select From Date " v-model="search.startdatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search.</div> 
              </div>
              <div class="form-group datePicker col-12 col-sm-4 col-md-4 col-lg-3">
                  <label for="">To Date</label>
                      <date-picker placeholder="Select To Date" class="startdate" v-model="search.startdateto" :disabled-date="disabledBefore" valueType="format" @input="selectedtodate"></date-picker>
              </div>
              
              <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-0 text-lg-left text-right">
                <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="searchSummaryData(search)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                <button type="reset" class="btn btn-outline-secondary mt-2 mr-0" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card open_oranges shadow-none rounded mb-1" style="border-radius: 5px;overflow: hidden; border: none!important;">
        <div class="card-header" style="cursor:pointer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 px-0">
                <h4 class="card-title d-inline-flex  project_name_Sum01">Project Name - <u>{{this.projectname}}</u></h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card card approved rounded mb-1 shadow-none ">
          <div class="card-body p-0">
             <div  class="card-header px-1 py-1 ">
                <h2  class="card-title">Members </h2>
                 
              </div>
            <div class="table-responsive" >
              <table class="table dataTableListing table-hover project_name_member_text_01 proj_member_text_013 " aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr v-for="(prole, index) in projectRoleDetails">
                        <td class="capitalisetext font-weight-normal" v-if="prole.rolename"><strong>{{prole.rolename}}: </strong></td>
                        <td class="capitalisetext font-weight-normal"  v-if="prole.empname">{{prole.empname}}</td>
                        <td v-else class="text-center font-weight-normal" colspan="3">Data Not Found!</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center mt-0">
            </div>
          </div>
        </div>
        <div  v-if="this.teamdl" class="card card open_oranges shadow-none rounded mb-1" style="border-radius: 5px;overflow: hidden; border: none!important;">
        <div class="card-header" style="cursor:pointer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 px-0">
                <h1 class="card-title d-inline-flex">Team DL : {{this.teamdl}}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div v-if="this.companycode=='fc'" class="card card open rounded mb-1 shadow-none " id="issueDiv">
          <div class="card-body p-0">
             <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="IssuesummanryCollapse">
              <h2  class="card-title heading_text_pro_summarys" >Issue Dashboard</h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive" v-bind:class="[{show: isIssueActive},card_content,collapse]" >      
              <table class="table dataTableListing table-hover project_name_member_text_01" aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr>
                      <td class="text-center"> <strong>Status/Types </strong> </td>
                      <td class="text-center" v-for="(values, key) in issueDetails[5]">
                        <strong>{{values.typename}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Open</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in issueDetails[0]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIssue',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Closed</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in issueDetails[1]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIssue',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Resolved</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in issueDetails[2]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIssue',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Re-Open</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in issueDetails[3]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIssue',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Feedback</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in issueDetails[4]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIssue',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center">
            </div>
          </div>
        </div>
        <div class="card resolved rounded mb-1 shadow-none " id="ticketDiv">
          <div class="card-body p-0">
             <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="TicketsummanryCollapse">
              <h2  class="card-title heading_text_pro_summarys" >Ticket Dashboard</h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive" v-bind:class="[{show: isTicketActive},card_content,collapse]" >      
              <table class="table dataTableListing table-hover project_name_member_text_01" aria-describedby="mydesc">
                <thead 
                class="header-primary">
                      <th scope="col" class="text-center">Status/Types</th>
                      <th scope="col" class="text-center">Issue</th>
                      <th scope="col" class="text-center">Support/Enquiry</th>
                      <th scope="col" class="text-center">New Feature</th>
                      <th scope="col" class="text-center">Content Correction/Enhancement</th>
                      <th scope="col" class="text-center">Power Content</th>
                      <th scope="col" class="text-center">Image Reshoot Request</th>
                      <th scope="col" class="text-center">Incorrect Images Uploaded</th>
                      <th scope="col" class="text-center">Grouping Request</th>
                      <th scope="col" class="text-center">Multi Tagging Request</th>
                  </thead>
              <tbody>
                <tr class="row1 text-center "></tr>         
                <tr class="row1 text-center ">
                  <td class="text-center"><strong>Open</strong></td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',16)">{{this.issuetypestatus.open16?this.issuetypestatus.open16:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',17)">{{this.issuetypestatus.open17?this.issuetypestatus.open17:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',3)">{{this.issuetypestatus.open3?this.issuetypestatus.open3:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',21)">{{this.issuetypestatus.open21?this.issuetypestatus.open21:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',22)">{{this.issuetypestatus.open22?this.issuetypestatus.open22:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',23)">{{this.issuetypestatus.open23?this.issuetypestatus.open23:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',24)">{{this.issuetypestatus.open24?this.issuetypestatus.open24:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',25)">{{this.issuetypestatus.open25?this.issuetypestatus.open25:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Open',26)">{{this.issuetypestatus.open26?this.issuetypestatus.open26:0}} </td>
                </tr>
                <tr class="row1 text-center ">
                  <td class="text-center"><strong>Re-Open</strong></td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',16)">{{this.issuetypestatus.reopen16?this.issuetypestatus.reopen16:0}}</td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',17)">{{this.issuetypestatus.reopen17?this.issuetypestatus.reopen17:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',3)">{{this.issuetypestatus.reopen3?this.issuetypestatus.reopen3:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',21)">{{this.issuetypestatus.reopen21?this.issuetypestatus.reopen21:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',22)">{{this.issuetypestatus.reopen22?this.issuetypestatus.reopen22:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',23)">{{this.issuetypestatus.reopen23?this.issuetypestatus.reopen23:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',24)">{{this.issuetypestatus.reopen24?this.issuetypestatus.reopen24:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',25)">{{this.issuetypestatus.reopen25?this.issuetypestatus.reopen25:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Re-Open',26)">{{this.issuetypestatus.reopen26?this.issuetypestatus.reopen26:0}} </td>
                </tr>
                <tr class="row1 text-center ">
                  <td class="text-center"><strong>Closed</strong></td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',16)">{{this.issuetypestatus.closed16?this.issuetypestatus.closed16:0}}</td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',17)">{{this.issuetypestatus.closed17?this.issuetypestatus.closed17:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',3)">{{this.issuetypestatus.closed3?this.issuetypestatus.closed3:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',21)">{{this.issuetypestatus.closed21?this.issuetypestatus.closed21:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',22)">{{this.issuetypestatus.closed22?this.issuetypestatus.closed22:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',23)">{{this.issuetypestatus.closed23?this.issuetypestatus.closed23:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',24)">{{this.issuetypestatus.closed24?this.issuetypestatus.closed24:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',25)">{{this.issuetypestatus.closed25?this.issuetypestatus.closed25:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Closed',26)">{{this.issuetypestatus.closed26?this.issuetypestatus.closed26:0}} </td>
                </tr>
                <tr class="row1 text-center ">
                  <td class="text-center"><strong>Resolved</strong></td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',16)">{{this.issuetypestatus.resolved16?this.issuetypestatus.resolved16:0}}</td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',17)">{{this.issuetypestatus.resolved17?this.issuetypestatus.resolved17:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',3)">{{this.issuetypestatus.resolved3?this.issuetypestatus.resolved3:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',21)">{{this.issuetypestatus.resolved21?this.issuetypestatus.resolved21:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',22)">{{this.issuetypestatus.resolved22?this.issuetypestatus.resolved22:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',23)">{{this.issuetypestatus.resolved23?this.issuetypestatus.resolved23:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',24)">{{this.issuetypestatus.resolved24?this.issuetypestatus.resolved24:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',25)">{{this.issuetypestatus.resolved25?this.issuetypestatus.resolved25:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Resolved',26)">{{this.issuetypestatus.resolved26?this.issuetypestatus.resolved26:0}} </td>
                </tr>
                <tr class="row1 text-center ">
                  <td class="text-center"><strong>Feedback</strong></td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',16)">{{this.issuetypestatus.feedback16?this.issuetypestatus.feedback16:0}}</td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',17)">{{this.issuetypestatus.feedback17?this.issuetypestatus.feedback17:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',3)">{{this.issuetypestatus.feedback3?this.issuetypestatus.feedback3:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',21)">{{this.issuetypestatus.feedback21?this.issuetypestatus.feedback21:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',22)">{{this.issuetypestatus.feedback22?this.issuetypestatus.feedback22:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',23)">{{this.issuetypestatus.feedback23?this.issuetypestatus.feedback23:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',24)">{{this.issuetypestatus.feedback24?this.issuetypestatus.feedback24:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',25)">{{this.issuetypestatus.feedback25?this.issuetypestatus.feedback25:0}} </td>
                  <td class="text-center sitebluecolor" style="cursor:pointer;" @click="redirecttourlticket('Ticketrequest','Feedback',26)">{{this.issuetypestatus.feedback26?this.issuetypestatus.feedback26:0}} </td>
                </tr>
                
                
                   
                    
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center">
            </div>
          </div>
        </div>

        <div v-if="this.companycode=='fc'" class="card card review rounded mb-1 shadow-none " id="btDiv">
          <div class="card-body p-0 ">
            <div  class="card-header px-1 py-1 filter_rotation_onlick border-bottom" style="cursor:pointer;" v-on:click="BTCsummanryCollapse">
              <h2  class="card-title heading_text_pro_summarys">Business Task </h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive mt-1" v-bind:class="[{show: isBTCActive},card_content,collapse]" >
              
              <div class="card-header px-2">
                <div class="row">
                  <div class="form-group col-md-12 mb-1">
                      <label>
                        <div class="radio-cntrl mr-4">
                          <input type="radio" @change="onBTCreport($event)"  value="Parent BTC" v-model="btcsearchtype"><span>Parent BTC</span>
                        </div>
                      </label>
                      <label>
                        <div class="radio-cntrl mr-4">
                          <input type="radio" @change="onBTCreport($event)"  value="Sub BTC" v-model="btcsearchtype"><span>Sub BTC</span>
                        </div>
                      </label> 
                  </div>                    
                </div>
              </div>

              <table class="table dataTableListing table-hover project_name_member_text_01" aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr>
                      <td class="text-center"><strong>Types/Status</strong></td>
                      <td class="text-center" v-for="(values, key) in busitaskstatus">
                        <strong>{{values.label}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>New Feature</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in businessTaskDetails[0]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryBTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>CR</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in businessTaskDetails[1]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryBTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Enhancement</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in businessTaskDetails[2]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryBTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Escalation</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in businessTaskDetails[3]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryBTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Process</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in businessTaskDetails[4]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryBTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Program</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in businessTaskDetails[5]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryBTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Support</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in businessTaskDetails[6]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryBTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center">
            </div>
          </div>
        </div>

        <div v-if="this.companycode=='fc'" class="card card open_primary rounded mb-1 shadow-none" id="taskDiv">
          <div class="card-body p-0">
            <div  class="card-header px-1  py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="TasksummanryCollapse">
              <h2  class="card-title heading_text_pro_summarys">Task</h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive" v-bind:class="[{show: isTaskActive},card_content,collapse]" >
              <table class="table dataTableListing table-hover project_name_member_text_01" aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr>
                      <td class="text-center"><strong>Status/Types</strong></td>
                      <td class="text-center" v-for="(values, key) in taskDetails[3]">
                        <strong>{{values.title}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Open</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in taskDetails[0]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTask',key)">{{values}}</span>
                          <span v-else>0</span>

                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Resolved</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in taskDetails[1]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Closed</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in taskDetails[2]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTask',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center">
            </div>
          </div>
        </div>

        <div v-if="this.companycode=='fc'" class="card card open_oranges rounded mb-1 shadow-none" id="tcDiv">
          <div class="card-body p-0">
            <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="TCsummanryCollapse">
              <h2  class="card-title heading_text_pro_summarys">Test Case</h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive" v-bind:class="[{show: isTCActive},card_content,collapse]" >
              <table class="table dataTableListing table-hover project_name_member_text_01" aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr>
                      <td class="text-center"><strong>Status/Types</strong></td>
                      <td class="text-center" v-for="(values, key) in testCaseDetails[3]">
                        <strong>{{values.testcasetypename}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Created</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in testCaseDetails[0]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTestCase',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Review In Progress</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in testCaseDetails[1]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTestCase',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Reviewed</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in testCaseDetails[2]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTestCase',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Approved</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in testCaseDetails[3]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTestCase',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Freez</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in testCaseDetails[4]">
                          <span v-if='parseInt(values)' style="cursor:pointer;"   @click="redirecttourl('ProjectSummaryTestCase',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center mt-0">
            </div>
          </div>
        </div>

        <div v-if="this.companycode=='fc'" class="card resolved rounded mb-1 shadow-none" id="incidentDiv">
          <div class="card-body p-0">
            <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="INCsummanryCollapse">
              <h2  class="card-title heading_text_pro_summarys">Incident</h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive" v-bind:class="[{show: isINCActive},card_content,collapse]" >
              <table class="table dataTableListing table-hover project_name_member_text_01" aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr>
                      <td class="text-center"><strong>Status/Severity</strong></td>
                      <td class="text-center" v-for="(values, key) in incidentDetails[4]">
                        <strong>{{values}}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Open</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in incidentDetails[0]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIncident',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Resolved</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in incidentDetails[1]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIncident',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Closed</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in incidentDetails[2]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIncident',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>Re Open</strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in incidentDetails[3]">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ProjectSummaryIncident',key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center">
            </div>
          </div>
        </div>

        <div v-if="this.companycode=='fc'" class="card card open_primary rounded mb-1 shadow-none" id="releasenotediv">
          <div class="card-body p-0">
            <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="RNsummanryCollapse">
              <h2  class="card-title heading_text_pro_summarys">Release Note</h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive" v-bind:class="[{show: isRNActive},card_content,collapse]"  >
              <table class="table dataTableListing table-hover project_name_member_text_01" aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr>
                      <td class="text-center"><strong>Status</strong></td>
                      <td class="text-center">
                        <strong>Pending</strong>
                      </td>
                      <td class="text-center">
                        <strong>Approved</strong>
                      </td>
                      <td class="text-center">
                        <strong>Rejected</strong>
                      </td>
                      <td class="text-center">
                        <strong>Pending for Deployement</strong>
                      </td>
                      <td class="text-center">
                        <strong>Deployed</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong></strong></td>
                      <td class="text-center sitebluecolor" v-for="(values, key) in releasenoteDetails">
                          <span v-if='parseInt(values)' style="cursor:pointer;" @click="redirecttourl('ReleaseNote', key)">{{values}}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center">
            </div>
          </div>
        </div>

        <div v-if="this.companycode=='fc'" class="card closed rounded mb-1 shadow-none filter_rotation_onlick" id="locDiv">
          <div class="card-body p-0">
            <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="locsummanryCollapse">
              <h2 class="card-title heading_text_pro_summarys">Line Of Code</h2>
              <div class="ml-auto mr-lg-1 d-flex ">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div v-bind:class="[{show: isLOCActive},card_content,collapse]" >
            <div class="table-responsive" >
              <div class="card-header px-2">
              <div class="row">
                  <div class="form-group col-md-12 mb-1">
                      <label>
                        <div class="radio-cntrl mr-4">
                          <input type="radio" @change="onLOCType($event)"  value="appwise" v-model="search.sheettype"><span>Application Wise LOC</span>
                        </div>
                      </label>
                      <label>
                        <div class="radio-cntrl mr-4">
                          <input type="radio" @change="onLOCType($event)"  value="userwise" v-model="search.sheettype"><span>User Wise LOC </span>
                        </div>
                      </label> 
                    </div>                    
                  </div>
                </div>
                
              </div>
              
                
              <div class="row mx-0" id="appwiseDiv" style="display:none; border-top: 1px solid #eee;">
                <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2" style="">
                    <h4 class="card-title">Application Wise LOC </h4>
                  </div>
                  <div class="table-responsive_sitcky"  v-if="sheettype != 'userwise'">
                    <table class="table table-hover table-striped table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                      <thead>
                        <tr>       
                        <th scope="col" class="align-middle">Application Name</th>
                        <th scope="col" class="align-middle">Repo Name</th>
                        <th scope="col" style="min-width: 200px;" class="align-middle">Added LOC</th>
                        <th scope="col" style="min-width: 200px;"  class="align-middle">Deleted LOC</th>
                        </tr>
                      </thead>
                      <tbody>      
                        <template v-for="(data,index) in LOCDetails">
                          <tr class="release_notemerge_font">
                            <td class=" font-weight-normal align-middle">{{data.applicationname}}</td>
                            <td class=" font-weight-normal align-middle">{{data.name}}</td>
                            <td class=" font-weight-normal align-middle">{{data.totallocadded}}</td>
                            <td class=" font-weight-normal align-middle">{{data.totallocdeleted}}</td>
                          </tr>
                          </template>
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>

              <div class="row mx-0" id="userwiseDiv" style="display:none;border-top: 1px solid #eee;">
                <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">User Wise LOC </h4>
                  </div>
                  <div class="table-responsive_sitcky"  v-if="sheettype != 'appwise'">
                    <table class="table table-hover table-striped table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                      <thead>
                        <tr>       
                        <th scope="col" class="align-middle">User Name</th>
                        <th scope="col" class="align-middle">Repo Name</th>
                        <th scope="col" style="min-width: 200px;" class="align-middle">Added LOC</th>
                        <th scope="col" style="min-width: 200px;"  class="align-middle">Deleted LOC</th>
                        </tr>
                      </thead>
                      <tbody>      
                        <template v-for="(data,index) in userwiseLOCDetails">
                          <tr class="release_notemerge_font">
                            <td class=" font-weight-normal align-middle">{{data.authorName}}</td>
                            <td class=" font-weight-normal align-middle">{{data.name}}</td>
                            <td class=" font-weight-normal align-middle">{{data.locadded}}</td>
                            <td class=" font-weight-normal align-middle">{{data.locdeleted}}</td>
                          </tr>
                          </template>
                      </tbody>
                    </table>
                  </div>
                  </div>
                </div>
              </div>
            
            <div class="pageCenter text-center mt-1">
            </div>
          </div>
        </div>

        </div>

        <div v-if="this.companycode=='fc'" class="card card reopen rounded mb-1 shadow-none " id="releasenotediv">
          <div class="card-body p-0">
            <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="Cyclesetsummarycollapse">
              <h2  class="card-title heading_text_pro_summarys">Test Cycle Set</h2>
              <div class="ml-auto mr-lg-1 d-flex">
                    <span class="rotetions_view_arrow bg-white"> 
                      <em class="las la-angle-down"></em>
                    </span>
                </div>
            </div>
            <div class="table-responsive" v-bind:class="[{show: isCyclesetActive},card_content,collapse]"  >
              <table aria-describedby="Summary" class="table dataTableListing table-hover project_name_member_text_01">

                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                    <tr>
                      <td class="text-center"><strong>Status</strong></td>
                      <td class="text-center" v-for="(cyclestatus , cindx) in testcycledetailslist" v-bind:key="cindx">
                        <strong class="capitalisetext">{{ cindx }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center"><strong></strong></td>
                      <td class="text-center sitebluecolor" v-for="(statuscount, countkey) in testcycledetailslist" v-bind:key="countkey">
                          <span v-if='parseInt(statuscount)' style="cursor:pointer;" @click="redirecttourl('Testcycleset', countkey)">{{ statuscount }}</span>
                          <span v-else>0</span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center">
            </div>
          </div>
        </div>

       </section>
    </div>
    <div v-else class="w-100 text-center">
        <h2 class="mb-1"> You are not authorized! 🔐 </h2>
        <p class="mb-2"> You don’t have permission to access this page!! </p>
        <img  src="../../../public/images/pages/not-authorized.e9c47c33.svg" alt="Not authorized page" class="img-fluid" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Treeselect from '@riophae/vue-treeselect'
import moment from 'moment'
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'ProjectSummary',
  components: {
    VueElementLoading,
    Loading,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Treeselect,
    DatePicker,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      isBothDateSelected: false,
      projectname: null,
      issuetypestatus:[],
      search: {
        projectnameList: [],
        searchprojectid: this.$route.query.pid,
        startdatefrom:null,
        startdateto:null,
        sheettype : 'appwise'
      },
      projectRoleDetails: null,
      teamdl:null,
      isLoading: false,
      undt: null,
      tokendata: null,
      issueDetails:[],
      ticketDetails:[],
      taskDetails:[],
      incidentDetails:[],
      busitypelist:apiUrl.typelist,
      busitaskstatus:[],
      businessTaskDetails:[],
      testCaseDetails:[],
      projectmdllist: [],
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      projectIdd: null,
      urlId:this.$route.query.pid,
      backpagedata: null,
      releasenoteDetails: [],
      LOCDetails:[],
      userwiseLOCDetails:[],
      isBothDateSelected:false,
      sheettype : 'appwise',
      isLOCActive:false,
      LOCDetails: [],
      isIssueActive:false,
      isTicketActive:false,
      isBTCActive:false,
      isTaskActive:false,
      isTCActive:false,
      isINCActive:false,
      isRNActive:false,
      isCyclesetActive: false,
      testcyclestatuslist: apiUrl.testcyclestatuslist,
      testcycledetailslist: [],
      companycode : apiUrl.companycode,
      userrole:null,
      btcsearchtype: 'Parent BTC',
      pagefeatures: [],
    }
  },
  created() {
    this.pagefeatures = {};
    if(window.localStorage.getItem("pagefeatures")){
      var pagefeatures1 = JSON.parse(
        window.atob(window.localStorage.getItem("pagefeatures"))
      );
      pagefeatures1.forEach((element) => {
        if (element.page === "/project") {
          this.pagefeatures[element.featurename] = element.featureaccess;
        }
      });
    }
  },
  mounted() {
    let userdata = window.localStorage.getItem('userdata');
    if(userdata){
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.tokendata = window.localStorage.getItem('token');
      this.userrole = window.localStorage.getItem("userrole");
      this.verifyLoggedInUserForPS(this.urlId)
      this.getProjList()
      this.getProjectDetailsById(this.urlId)
      this.getProjectModuleIds(this.urlId)
      this.getBtcStatusList();
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
    $('#appwiseDiv').show();
 
  },
  methods: {     
      getBtcStatusList() {
          let url = "api/btc/getBtcStatusList";
          this.input = {
              useremail: this.undt.username,
              empcode: this.undt.userid,
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.busitaskstatus = result.data.data.rows;
              } else {
                  this.busitaskstatus = [];
              }
          }).catch(e => {
          this.displayError(e)
        });     
      },
      selectedfromdate(){
          if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
              this.isBothDateSelected = false
          }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
              this.isBothDateSelected = true
          }
      }, 
      selectedtodate(){
          if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
              this.isBothDateSelected = false
          }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
              this.isBothDateSelected = true
          }
      },
      selProj(state) {
        if(state==undefined || state == null) { 
          this.isBothDateSelected = true 
        } else {
          this.isBothDateSelected = false 
        }
      },
      disabledBefore(date) {
          let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
          const beforeToday = new Date(dayBefore);
                  beforeToday.setHours(0, 0, 0, 0);
          return date < beforeToday; 
      },
      notBeforeToday: function (date) {
          this.search.startdateto = "";
          return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      resetRecords: function() {
        this.search.searchprojectid = null
        this.search.startdatefrom = null
        this.search.startdateto = null
      },
      searchSummaryData:function(data){
        if(data) {
          this.urlId = data.searchprojectid?data.searchprojectid:null;
          window.history.pushState('', '', '#/project/summary?pid='+this.urlId);
          this.getProjectDetailsById(this.urlId)
          this.getProjectModuleIds(this.urlId)
          if(this.isIssueActive) {
            this.getIssueDetails(this.urlId, data)
          }
          if(this.isTicketActive) {
            this.getticketDetails(this.urlId, data)
          }
          if(this.isBTCActive) {
            this.getBusinessTaskDetails(this.urlId, this.busitaskstatus, this. busitypelist, data)
          }
          if(this.isTaskActive) {
            this.getTaskDetails(this.urlId, data)
          }
          if(this.isTCActive){
            this.getTestCaseDetails(this.urlId, data)
          }
          if(this.isINCActive) {
            this.getIncidentDetails(this.urlId, data)
          }
          if(this.isRNActive) {
            this.getReleaseNoteDetails(this.urlId, data)
          }
          if(this.isCyclesetActive){
            this.getcyclesetdetails(this.urlId,data);
          }
          if (this.isLOCActive) {
            this.getUserwiseLOCDetails(this.urlId,data);
            this.getLOCDetails(this.urlId,data);
          }
        }
      },
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },
      getProjList: function(){            
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage: 'listingpage'
          };
          axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {             
            if (result.data.errorCode == 0) {
              this.search.projectnameList = result.data.data;
            } else {
              this.search.projectnameList = [];
            }                  
          }).catch(e => {
          this.displayError(e)
        })
      },
      verifyLoggedInUserForPS :function(pid) {
        this.isLoading = true;
        let url = "api/projectmodule/verifyLoggedInUserForPS";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
          projectid: parseInt(pid),
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.data.rows[0].empid==null) {
            this.$router.push('/login')
            Swal.fire({
                title: "Failed!",
                text: "Your are not authorised user to view this page",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            })
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getProjectDetailsById: function(pid) {
        this.isLoading = true;
        let url = "api/projectmodule/getProjectDetailsById";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          projectid: parseInt(pid),
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            let pname = result.data.data.rows[0].projectname.toUpperCase();
            this.projectname = pname;
            let dlname = result.data.data.rows[0].teamdl;
            this.teamdl = dlname;
            this.projectRoleDetails = result.data.data.rows;
          }  else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            this.projectRoleDetails = null;
            this.projectname = null;
            this.$router.push({ name: 'Project Wizard', params: { search: true }})
            Swal.fire({
                title: "Failed!",
                text: "Your are not authorised user to view this page",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            })
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      sliceIntoChunks: function(arr, chunkSize) {
        var values = Object.values(arr);
        var final = [];
        var counter = 0;
        var portion = {};

        for (var key in arr) {
          if (counter !== 0 && counter % chunkSize === 0) {
            final.push(portion);
            portion = {};
          }
          portion[key] = values[counter];
          counter++
        }
        final.push(portion);
        return final;
      },
      getIssueDetails: function(pid, search){
          this.isLoading = true;
          let url = "api/issue/getIssueTypeDetails";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
            userrole:this.userrole,
            };
          if(search) {
            this.statusinput.sdate = search.startdatefrom
            this.statusinput.edate = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              let myData = result.data.data[0];
              let typelen = result.data.data[1].length;
              myData = this.sliceIntoChunks(myData, typelen);
              myData[5] = result.data.data[1];
              this.issueDetails = myData;
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.issueDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getTaskDetails: function(pid, search){
          this.isLoading = true;
          let url = "api/task/getTaskTypeDetails";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
          };
          if(search) {
            this.statusinput.sdate = search.startdatefrom
            this.statusinput.edate = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              let myData = result.data.data[0];
              let typelen = result.data.data[1].length;
              myData = this.sliceIntoChunks(myData, typelen);
              myData[3] = result.data.data[1];
              this.taskDetails = myData;
            }  else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.taskDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getBusinessTaskDetails: function(pid, statuslist, typelist, search){
          this.isLoading = true;
          let url = "api/business/getBusinessTaskDetails";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            statuslist: statuslist,
            typelist:typelist,
            empcode: this.undt.userid,
            btcsearchtype: this.btcsearchtype,
          };
          if(search) {
            this.statusinput.sdate = search.startdatefrom
            this.statusinput.edate = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              let myData = result.data.data[0];
              let typelen = statuslist.length;
              myData = this.sliceIntoChunks(myData, typelen);
              this.businessTaskDetails = myData;
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.businessTaskDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getTestCaseDetails: function(pid, search){
          this.isLoading = true;
          let url = "api/testcase/getTestCaseDetails";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
          };
          if(search) {
            this.statusinput.sdate = search.startdatefrom
            this.statusinput.edate = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              let myData = result.data.data[0];
              let severitylen = result.data.data[1].length;
              myData = this.sliceIntoChunks(myData, severitylen);
              myData[3] = result.data.data[1];
              this.testCaseDetails = myData;
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.testCaseDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getIncidentDetails: function(pid, search){
          this.isLoading = true;
          let url = "api/incidents/getIncidentTypeDetails";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
          };
          if(search) {
            this.statusinput.sdate = search.startdatefrom
            this.statusinput.edate = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              let myData = result.data.data[0];
              let severitylen = result.data.data[1].length;
              myData = this.sliceIntoChunks(myData, severitylen);
              myData[4] = result.data.data[1];
              this.incidentDetails = myData;
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.incidentDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getReleaseNoteDetails: function(pid, search){
          this.isLoading = true;
          let url = "api/releasenote/getReleaseNoteDetails";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
          };
          if(search) {
            this.statusinput.sdate = search.startdatefrom
            this.statusinput.edate = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              let myData = result.data.data[0];
              this.releasenoteDetails = myData;
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.releasenoteDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getTicketDetails: function(pid, search){
          this.isLoading = true;
          let url = "api/issue/getticketTypeDetails";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
            moduletype:'ticket'
          };
          if(search) {
            this.statusinput.sdate = search.startdatefrom
            this.statusinput.edate = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
            this.issuetypestatus = result.data.data[0];
             } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
                this.issuetypestatus = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      redirecttourl:function(btnstate,rowdata){
        if(btnstate == 'ProjectSummaryIncident') {
            this.$router.push({
              path:'/incident/list',
              name:'Incident List',
              
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata,
                  pid: this.projectmdllist,
              }
          })
        } else if(btnstate == 'ProjectSummaryIssue') {
          this.$router.push({
              path:'/issues/list',
              name:'IssueList',
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata,
                  pid:this.urlId,

              }
          })
        } else if(btnstate == 'ProjectSummaryTicket') {
          this.$router.push({
              path:'/ticket/list',
              name:'TicketList',
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata,
                  pid:this.urlId,

              }
          })
        } 
        else if(btnstate == 'ProjectSummaryBTask') {
          this.$router.push({
              path:'/businesstask/list',
              name:'Business Task List',
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata,
                  pid:this.urlId,
                  btctype:this.btcsearchtype,
              }
          })
        } else if(btnstate == 'ProjectSummaryTask') {
          this.$router.push({
              path: '/tasks/list',
              name: 'TaskList',
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata,
                  pid:this.urlId,
              }
          })
        } else if(btnstate == 'ProjectSummaryTestCase') {
          this.$router.push({
              path:'/testcasetask/list',
              name:'tlist',
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata,
                  pid:this.urlId,
              }
          })
        } else if(btnstate == 'ReleaseNote') {
          if(rowdata) {
            rowdata = rowdata.replaceAll('_', ' ');
          }
          this.$router.push({
              path:'/releasenote',
              name:'ListReleaseNote',
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata,
                  pid:this.urlId,
              }
          })
        }else if(btnstate == 'Testcycleset') {
          if(rowdata) {
            rowdata = rowdata.replaceAll('_', ' ');
          }
          this.$router.push({
              name:'TestCycleSetList',
              params: {
                backbtnstate:btnstate,
                clickeddata:rowdata,
                pid:this.urlId,
                fromrediret: 'projectsummary',
                startdatefrom: this.search.startdatefrom ? this.search.startdatefrom : null,
                startdateto: this.search.startdateto ? this.search.startdateto : null
              }
          })
        }
      },
      redirecttourlticket(btnstate,status,type){
        btnstate = btnstate;
        let rowdata ={};
        rowdata.fromrediret = 'projectsummary';
        rowdata.status = status;
        rowdata.type = type;
        this.$router.push({
            name:'TicketList',
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata,
              pid:this.urlId,
              startdatefrom: this.search.startdatefrom ? this.search.startdatefrom : null,
              startdateto: this.search.startdateto ? this.search.startdateto : null
            }
        })
      },
      getProjectModuleIds:function(id){
            this.isLoading=true
            this.pageoffset=1
            var url='api/listing/getpromodulebyprojectid'
            this.input = {
                projectid:id,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false;          
                if(result.data.errorCode == 0){
                  this.projectmdllist = result.data.data.map(x => x.id)
                }
            }).catch(e => {
          this.displayError(e)
        })
      },
      redirecttobreadkumb: function (objThis) {
        let backbtnstate = objThis.btnstate;
        let rowdata ={};
        rowdata.projectid = objThis.rowdata
        this.$router.push({
          name: objThis.name,
          path: "/" + objThis.path,
          params: {
            backbtnstate: backbtnstate,
            clickeddata: rowdata,
            search: true
          },
        });
      },
      onLOCType(event) {
        this.sheettype = event.target.value
        if (this.sheettype == 'appwise'){
          this.showLoadingOverlay()
            this.showtabledata=true
            $('#appwiseDiv').show();
            $('#userwiseDiv').hide();
            $('#reportTopScroll').show();
            this.getLOCDetails(this.urlId);
        }else if(this.sheettype == 'userwise'){
          this.showLoadingOverlay()
          this.showtabledata=false
          $('#userwiseDiv').show();
            $('#appwiseDiv').hide();
            $('#reportTopScroll').hide();
            this.getUserwiseLOCDetails(this.urlId, this.search)
        }
      },
      showLoadingOverlay() {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      },
      onBTCreport(event) {
        this.btcsearchtype = event.target.value;
        if(this.isBTCActive) {
          this.getBusinessTaskDetails(this.urlId, this.busitaskstatus, this. busitypelist)
        }
      },
      locsummanryCollapse:function(){
        this.isLOCActive=!this.isLOCActive
        this.getLOCDetails(this.urlId)
      },
      getLOCDetails: function(pid, $event, search){
        this.project= $event
          this.isLoading = true;
          let url = "api/reports/getLOCDashboardinProSummary";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
          };
          if(search) {
            this.statusinput.datefrom = search.startdatefrom
            this.statusinput.dateto = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.LOCDetails = result.data.data;
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.LOCDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      getUserwiseLOCDetails: function(pid, search){
          this.isLoading = true;
          let url = "api/reports/getLOCUserWiseProSummary";
          this.statusinput = {
            useremail: this.undt.username,
            projectid: parseInt(pid),
            empcode: this.undt.userid,
          };
          if(search) {
            this.statusinput.datefrom = search.startdatefrom
            this.statusinput.dateto = search.startdateto
          }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.userwiseLOCDetails = result.data.data;
            } else if(result.data.errorCode == 3){
                Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                  }).then(function() {
                    window.location.href = "/#/login";
                })
              } else {
              this.userwiseLOCDetails = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
      },
      IssuesummanryCollapse:function(){
        this.isIssueActive=!this.isIssueActive
        if(this.isIssueActive) {
          this.getIssueDetails(this.urlId)
        }
      },
      TicketsummanryCollapse:function(){
        this.isTicketActive=!this.isTicketActive
        if(this.isTicketActive) {
          this.getTicketDetails(this.urlId)
        }
      },
      BTCsummanryCollapse:function(){
        this.isBTCActive=!this.isBTCActive
        if(this.isBTCActive) {
          this.getBusinessTaskDetails(this.urlId, this.busitaskstatus, this. busitypelist)
        }
      },
      TasksummanryCollapse:function(){
        this.isTaskActive=!this.isTaskActive
        if(this.isTaskActive) {
          this.getTaskDetails(this.urlId)
        }
      },
      TCsummanryCollapse:function(){
        this.isTCActive=!this.isTCActive
        if(this.isTCActive){
          this.getTestCaseDetails(this.urlId)
        }
      },
      INCsummanryCollapse:function(){
        this.isINCActive=!this.isINCActive
        if(this.isINCActive) {
          this.getIncidentDetails(this.urlId)
        }
      },
      RNsummanryCollapse:function(){
        this.isRNActive=!this.isRNActive
        if(this.isRNActive) {
          this.getReleaseNoteDetails(this.urlId)
        }
      },
    Cyclesetsummarycollapse:function(){
      this.isCyclesetActive=!this.isCyclesetActive
      if(this.isCyclesetActive) {
        this.getcyclesetdetails(this.urlId)
      }
    },
    getcyclesetdetails: function(pid, search){
      this.isLoading = true;
      let url = "api/testcycle/getcyclesetdetails";
      this.inputs = {
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
        projectid: parseInt(pid),
      };
      if(this.search.startdatefrom && this.search.startdateto){
        this.inputs.sdate = this.search.startdatefrom;
        this.inputs.edate = this.search.startdateto;
      }
      axios({
        method: "POST",
        url: url,
        data: this.inputs,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.testcycledetailslist = result.data.data[0];
        } else if(result.data.errorCode == 3){
          Swal.fire({
            title: "Session Expired...!!",
            text: result.data.msg,
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          }).then(function() {
          window.location.href = "/#/login";
          })
        } else {
          this.testcycledetailslist = [];
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
  }
}
</script>
<style> 
.view_bg-gradient-primary{
  background: #7DA3F9!important;
}
.project_summarysviews001 .card .card-header.filter_rotation_onlick{
	background:#fff!important;
}
.project_summarysviews001 .card .card-header.filter_rotation_onlick.filter_card_searchbggss{
  background: linear-gradient(135.23deg, #2E85BE -1.94%, #95DAF4 102.64%)!important;
}
.dark-layout .project_summarysviews001 .card .card-header.filter_rotation_onlick{
	background:#2f374c!important;
  border-radius: 0px;
}
</style>