import router from '../router.js'
export const Service = {
    verifyAccess(){
        if (window.localStorage.getItem('sidebar')) {
            var menu = window.atob(window.localStorage.getItem('sidebar'))
            var finalmenu = JSON.parse(menu)
            if (typeof finalmenu === 'object') { 
              var currentUrl = window.location.hash
              var url = currentUrl.split('#') 
              if (menu.includes(url[1])) {
              }else{
                Swal.fire({
                    title: "Failed!!",
                    text: "You don’t have permission to access this page.",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                 router.push('/login')
                 return false;
                }
            } else {
                Swal.fire({
                    title: "Failed!!",
                    text: "You don’t have permission to access this page.",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                router.push('/login')
                return false;
            }
            return true;
        }
    },
}