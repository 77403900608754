<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Red Project Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" :multiple="true" :clear-on-select="true" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Reporting Manager Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" v-on:search-change="getallmanagerlistnewapi" :options="managersnamelist"/>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-0 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1 mobile_margin-px-2" @click="getprojectlist(search,1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1 mobile_margin-px-2" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                      <button type="move" class="btn btn-relief-primary mt-2 mobile_margin-px-2" @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span> </button>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Red Project List Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              
                              <div
                                class="table-responsive_sitcky"
                                v-if="showtabledata"
                              >
                                <table class="table table-sm table-hover table-striped dataTableListing all_table_stickys" aria-describedby="Red Project List Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="align-middle text-left first-col-sticky">Project Name</th>
                                      <th scope="col" class="align-middle">Program Manager</th>
                                      <th scope="col" class="align-middle">Reporting Manager</th>
                                      <th scope="col" class="align-middle">APM/PM</th>
                                      <th scope="col" class="align-middle">Engg. Manager</th>
                                      <th scope="col" class="align-middle">Total Task Count</th>
                                      <th scope="col" class="align-middle">Total Red Task Count</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(data, index) in companymasterlist"
                                    >
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)">                                        
                                        <td :class="'capitalisetext text-left font-weight-normal first-col-sticky cursorpointercls sitebluecolor unique'+index+1+currPageNum*pagelimit" @click="redirecttourl('projectId',data)">
                                          {{data.projectname}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          {{data.programmanager ? data.programmanager:'NA'}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{data.reportingmanager ? data.reportingmanager:'NA'}}
                                        </td>

                                         <td class="capitalisetext font-weight-normal">
                                          {{data.apm ? data.apm:'NA'}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          {{data.enggmanger ? data.enggmanger:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.totaltaskcount}}
                                        </td>
                                         <td class="font-weight-normal text-center">
                                          {{data.redtaskcount}}
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14"  style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Projects",
  mixins: [ commonMethods ],
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
  },
  data() {
    return {
      searchOffset: 1,
      searchSession: false,
      project: {
        name: null,
        desc: null,
        projecttype: null,
        projecttypelist: [
          {
            id: "",
            label: "",
          },
        ],
        businesslist: [
          {
            id: "",
            label: "",
          },
        ],
        companylist: [
          {
            id: "",
            label: "",
          },
        ],
      },
      search: {
        searchProjectname:null,
        mngrname:null
      },
      projectnameList:[],
      mngrlist:[],
      isLoading: false,
      fullPage: true,
      showprojectdata: [],
      TimeFormat: apiUrl.TimeFormat,
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      allapicallauthkey: "",
      showtabledata: true,
      glbMdl: "",
      glbst: "",
      showfilterboxsection:false,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      companymasterlist:[],
      tokendata:null,
      isresponce:false,
      managersnamelist: []
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.getprojectlist(rowdata,0);
        this.getManagerList();
      }
     }
     $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.search.searchProjectname = state;
    }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    resetRecords: function () {
      this.search.mngrname=null
      let rowdata={}
      this.getprojectlist(rowdata,1);
      this.search.searchProjectname = null;
      this.search.createdby = null;
      this.search.companyname = null;
      this.search.businessname = null;
      this.searchSession = false
      this.searchOffset = 1
      this.managersnamelist = [];
    },
    getcomapnylist: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "project",
        isFilterSelect: 0,
        isFilterPayload: {},
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getManagerList() {
      this.pageoffset=1
      axios({
        'method': 'POST',
        'url': 'api/searching/getmanagerlist',
        'headers':{'authorization':this.tokendata},
        'data': {
          mngrid:null,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.mngrlist = result.data.data;
          let decryptdepartmenttext = this.mngrlist.map(items =>{

            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        } else {
          this.mngrlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    getprojectlist(rowdata,flag) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      if(flag==1){
        this.pageoffset=1
        this.page=1
      }
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        uniqueid: this.undt.userid,
        empcode: this.undt.userid,
        isFilterSelect: 0,
        isFilterPayload: rowdata,
        
      };
      if(rowdata.hasOwnProperty('searchProjectname') && rowdata.searchProjectname) {
        this.searchSession = true
        this.input.offset = this.searchOffset
      } else {
        this.searchSession = false
        this.searchOffset = 1
      }
      if(rowdata.hasOwnProperty('mngrname') && rowdata.mngrname) {
        this.searchSession = true
        this.input.offset = this.searchOffset
      } else {
        this.searchSession = false
        this.searchOffset = 1
      }
      let url = "api/reports/redprojectlistreport";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      if(this.searchSession) {
        this.searchOffset = pageNum;
      }
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata,0);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    setSelected(value) {
      this.selected = value;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
 ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
     this.input = {
        limit:null,
        offset:null,
        isFilterPayload:{searchProjectname:this.search.searchProjectname,mngrname:this.search.mngrname},    
          
         useremail: this.undt.username,
         empcode: this.undt.userid,    
      };
      axios({
        method: "POST",
        url: `api/Reports/EXredprojectlistreport`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'redprojectlistreport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Project Name,Program Manager,Reporting Manager,APM/PM,Engg.Manger,Total Task Count,Total Red Task Count' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager == null?'':index.programmanager : '';
          let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager == null?'' :index.reportingmanager : '';
          let apm = index.hasOwnProperty('apm') ? index.apm == null?'' :index.apm : '';
          let enggmanger = index.hasOwnProperty('enggmanger') ? index.enggmanger == null?'':index.enggmanger : '';
          let totaltaskcount = index.hasOwnProperty('totaltaskcount') ? index.totaltaskcount : '';
          let redtaskcount = index.hasOwnProperty('redtaskcount') ? index.redtaskcount : '';
            row += '"' + srno + '",',
            row += '"' + projectname + '",',
            row += '"' + programmanager + '",',
            row += '"' + reportingmanager + '",',
            row += '"' + apm + '",',
            row += '"' + enggmanger + '",',
            row += '"' + totaltaskcount + '",',
            row += '"' + redtaskcount + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'redprojectlistreport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });     
    }
},
getallmanagerlistnewapi(node) {
  if (node && node.length>=3) {
    this.pageoffset=1
    axios({
      'method': 'POST',
      'url': 'api/searching/getallmanagerlistnewapi',
      'headers':{'authorization':this.tokendata},
      'data': {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        mngname: node.toLowerCase(),
      }
    }).then(result => {
      this.isLoading = false
      if(result.data.errorCode == 0) {
        this.managersnamelist = result.data.data;
        let decryptdepartmenttext = this.managersnamelist.map(items =>{

          let tempemplabel1 = items.label;
          let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

          if(tempemplabel2 && tempemplabel2 != null){
            items.label = tempemplabel1 +'('+tempemplabel2 +')';
          }
          return items;
        })
      } else {
        this.managersnamelist = [];
      }
    }).catch(e => {
      this.displayError(e)
    })
  }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    redirecttourl(state, rowdata) {
      if (state == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid,
        })
      }
    }
  },
};
</script>
