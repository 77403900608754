<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-0">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-0 pt-0">
          <div class="container-fluid px-0">
            <div class="row breadcrumbs-top mx-0">
              <div class="col-md-9 col-sm-10 content-header-left pl-0">
                <h3 class="content-header-title float-left mb-0">Performance Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/performance/list'>Performance Management</router-link></li>
                  </ol>
                </div>
              </div>
              <div class="col-md-3 col-sm-2 text-right pr-0" >
                <div class="input-group-append float-right ml-1" v-if="glbst == 't11'">
                  <router-link to="/performance/create"><span class="btn btn-sm btn-relief-success" ><plus-circle-icon size="1.5x"></plus-circle-icon> <span class="mobile_hide_bt"> Tag KRA </span> </span></router-link>
                </div>
                <div class="ml-auto px-1 text-right mobile-padding-0-2" v-else-if="glbst == 't12'">
                    <button
                      id="commonaddmodule"
                      class="btn btn-sm btn-relief-success"
                      data-toggle="modal"
                      aria-haspopup="true"
                      aria-expanded="false"
                      :data-target="currentmodule"
                      @click="addModule($event)"
                      type="button"
                      data-backdrop="static"
                      data-keyboard="false"
                    >
                    <plus-circle-icon  size="1.5x" class="custom-class"></plus-circle-icon> <span> Add {{ glbMdlLblval }} </span>
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" text-editar_discription_jobs masters_detailstabing_pills px-0">
          <ul class="nav nav-pills nav-pill-warning pt-1" id="nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="business-tab" data-toggle="tab" href="javascript:void();" @click="getPerformanceData2('t2',$event)" role="tab" aria-controls="business" aria-selected="false">KRA Listing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="company-tab" data-toggle="tab" href="javascript:void();" @click="getPerformanceData('t1',$event)" role="tab" aria-controls="company" aria-selected="true">Performance Management</a>
          </li>
          
          </ul>
        </div>
      </section>
      <!-- New two in one table start -->
      <div class="card border-warning" id="performanceList">
        <div class="card-body p-0">
          <div class="table-responsive" v-if="glbst !== 'wn'">
              <table class="table table-sm dataTableListing" aria-describedby="mydesc">
              <thead>
              <tr>
              <th scope="col" class="text-center py-1" style="width:90px">Sr#</th>
              <th scope="col" class="text-left py-1"   v-if="glbst == 't11'">Tracker</th>
              <th scope="col" class="align-middle" v-if="glbst == 't11'">KRA</th>
              <th scope="col" class="text-center py-1" v-if="glbst == 't11'">Weightages (%)</th>
              <th scope="col" class="text-left py-1" v-if="glbst == 't12'">KRA</th>
              <th scope="col" class="text-left py-1" v-if="glbst == 't12'">Description</th>
              <th scope="col" class="text-left py-1" v-if="glbst == 't12'">Created By</th>
              <th scope="col" class="text-center py-1" v-if="glbst == 't12'">Created Date</th>
              <th scope="col" class="clsaction py-1">Actions</th>
              </tr>
              </thead>
              <tbody>
                  <template v-for="(data,index) in performance.performancelist"> 
                    <tr v-bind:key="index" >
                      <td class="text-center">{{ index+1+currPageNum*pagelimit }}</td>
                      <td class="text-left text-capitalize" v-if="glbst == 't11'">{{data.trackername}}</td>
                      <td class="font-weight-bold" v-if="glbst == 't11'"><span v-html="showprojectname(data.kra)"></span></td>
                      <td class="text-center text-capitalize" v-if="glbst == 't11'">
                        <span v-html="showprojectname(data.weightags ? data.weightags:'0')"></span>
                      </td>
                      <td class="text-left text-capitalize" v-if="glbst == 't12'">{{data.kra}}</td>
                      <td class="text-left text-capitalize" v-if="glbst == 't12'">{{data.description}}</td>
                      <td class="text-left text-capitalize" v-if="glbst == 't12'">{{data.createdby}}</td>
                      <td class="text-center text-capitalize" v-if="glbst == 't12'">{{dateFormat(data.createddate)}}</td>
                      <td class="centeralign" v-if="glbst == 't11'">
                        <span class="btn btn-icon btn-outline-primary rounded-circle devEditEle"  @click="redirecttourl('edit',data)">
                          <edit-icon size="1.5x" class="custom-class"></edit-icon>
                        </span>
                      </td>
                      <td class="centeralign" v-else-if="glbst == 't12'">
                          <span class="btn btn-icon btn-outline-primary rounded-circle devEditEle"
                          href="javascript:void(0);"
                          data-toggle="modal"
                          :data-target="currentmodule"
                          @click="openEditModule(data)"
                          data-backdrop="static" data-keyboard="false">
                          <edit-icon size="1.5x" class="custom-class"></edit-icon>
                        </span>
                      </td>
                    </tr>  
                  </template>
                  <template v-if="performance.performancelist.length==0">
                      <tr><td colspan="14" style="text-align: ;center">No record found</td></tr>
                  </template>
              </tbody>
              </table>             
          </div>
          <div class="pageCenter text-center mt-2">
            <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
          </div>
        </div>
      </div>
      <!-- New two in one table end -->
    </div>
     <!-- Add KRA-->
    <div
      class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show"
      id="projectmaster" 
    >
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            id="closebtnt11"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #ffff !important"
          >
            ×
          </button>
          <div
            class="modal-header mb-1"
            style="background-color: #1f77b4 !important"
          >
            <h5 class="modal-title" style="color: #ffff !important">
              <plus-square-icon
                size="1.5x"
                class="custom-class"
              ></plus-square-icon>
              <span class="align-middle ml-50" v-if="iseditstate == true"
                >Update KRA</span
              >
              <span class="align-middle ml-50" v-else
                >Add KRA</span
              >
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <form class="form form-vertical">
              <div class="row">
                  <div class="col-12">
                    <div class="form-group " style="margin-bottom:5px;">
                      <label for="modulenamet11">KRA Title :</label>
                      <div class="input-group input-group-merge">
                        <input
                          style="border: 1px solid #d8d6de !important"
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Enter KRA title"
                          v-model="addkra.kraname"
                          v-bind:class="{'form-control': true,'': !validText(addkra.kraname), }"
                        />
                        <div class="errorinputmsg" v-if="!validText(addkra.kraname) && kranameBlured">
                          KRA title required
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                      <label for="projectdescription"
                        >KRA Description</label
                      >
                      <textarea
                        class="form-control"
                        id="projectdescription"
                        name=""
                        rows="3"
                        placeholder="Enter KRA Description"
                        v-model="addkra.kradesc"
                        v-bind:class="{
                          'form-control': true,
                          '': !validText(addkra.kradesc),
                        }"
                      ></textarea>
                      <div class="errorinputmsg" v-if="!validText(addkra.kradesc) && kradescBlured" >
                        KRA description required
                      </div>
                    </div>
                  </div>
                <div class="col-12">
                  <button type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt9" data-dismiss="modal" v-on:click.stop.prevent="savewizard('t9',$event)">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Add KRA End-->
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2'
import moment from 'moment'

import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, PlusSquareIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'PerformanceList',
  components: {
    VueElementLoading,
    Loading,
    PlusCircleIcon,
    EditIcon,
    Pagination,PlusSquareIcon
  },
  mixins: [ commonMethods ],
  data() {
    return {
      performance: {
        performancelist: [],
        trackerid: null,
        kraid: null,
        kpiid: null,
        kralist: [],
        kpilist: [],
      },
      addkra: {
        kraname: null,
        kradesc: null,
        kraid: null,
      },
      errors: {
        performance: {
          trackerid: null,
          kraid: null,
          kpiid: null,
        },
        addkra: {
          kraname: null,
          kradesc: null
        }
      },
      isLoading: false,
      undt: null,
      tokendata: null,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      isAddState: false,
      iseditstate: false,
      currentmodule:'projectmaster',
      glbMdlLblval : "KRA",
      glbMdl: "",
      glbst:'',
      kranameBlured: false,
      kradescBlured: false
    }
  },
  created() {},
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        // AJAX REQUESTS
        this.getPerformanceData2()
        if (this.glbst == 't12') {
          this.getPerformanceData2()
        }else if(this.glbst == 't11'){
          this.getPerformanceData()
        }else{
          this.getPerformanceData()
        }
      }
    }
  },
  methods: {
    getPerformanceData(event) {
      if (event == 't1') {
        this.pageoffset =1;
        this.currPageNum =0;
        this.page = 1;
      }
      let setmoduleform = "projectmaster";
      this.currentmodule=`#`+setmoduleform
      let glbMdlLblval = "KRA";
      let tblNm = "t11";
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.isLoading = true
      let url = 'api/master/performance/getPerformanceData'
       axios({
        method: 'POST',
        url,
        data: { 
          empid: parseInt(this.undt.userid),
          limit: apiUrl.LIMIT,
          offset: this.pageoffset,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        },
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          let d = result.data.data.rows
          this.performance.performancelist = d
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.performance.performancelist = []
          this.totalcount = 0
          this.pageCount = 0
        }
      }).catch(e => {
         this.displayError(e)
      });
    },
    getPerformanceData2(event) {
      if (event == 't2') {
        this.pageoffset =1;
        this.currPageNum =0;
        this.page = 1;
      }
      let setmoduleform = "projectmaster";
      this.currentmodule=`#`+setmoduleform
      let glbMdlLblval = "KRA";
      let tblNm = "t12";
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.isLoading = true
      let url = 'api/master/performance/getallkradatalist'
       axios({
        method: 'POST',
        url,
        data: { 
          useremail: this.undt.username,
          limit: apiUrl.LIMIT,
          offset: this.pageoffset ,
          empcode: this.undt.userid
        },
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          let d = result.data.data.rows
          this.performance.performancelist = d
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.performance.performancelist = []
          this.totalcount = 0
          this.pageCount = 0
        }
      }).catch(e => {
         this.displayError(e)
      });
    },
    redirecttourl:function(btnstate,rowdata){
      rowdata.backbtnstate=btnstate
      if(btnstate=="edit"){
        this.$router.push({ 
          path: `/performance/update?trackerid=${rowdata.trackerid}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      }
    },
    showKRAnames(prname){
      if(prname && !prname.includes(',')) return prname
      let responsestr='';
      if (this.glbst == '') {
        let prlist=prname.split(',')
      if(prlist.length > 1){
        for(var i=0; i<prlist.length; i++){
          responsestr += (i+1)+'. '+prlist[i]+'<hr class="my-50"/>'
        }
        responsestr = responsestr.slice(0,-5)
      }else{
        for(var i=0; i<prlist.length; i++){
          responsestr += prlist[i]
        }
      }
      return responsestr
      }
    },
    showprojectname(kra){
        let responsestrs='';
        let prlists=kra.split(',')
        if(prlists.length > 1){
            for(var i=0;i<prlists.length;i++){
                responsestrs +=(i+1)+'. '+prlists[i]+' <br/>'
            }
        }else{
            for(var i=0;i<prlists.length;i++){
                responsestrs +=prlists[i]
            }
        }
        return responsestrs
    },
    clickCallback: function(pageNum) {
      this.pageoffset=pageNum
      this.currPageNum = pageNum - 1
      if (this.glbst == 't12') {
        this.getPerformanceData2();
      }else{
        this.getPerformanceData();
      }
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#performanceList").offset().top -70
        });
      }, 500);
    },
    dateFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD-MM-YYYY');
    },
    addModule(objThis) {
      this.isAddState = true;
      this.iseditstate = false;
      this.addkra.kraname = null;
      this.addkra.kradesc = null;
      this.kranameBlured = null;
      this.kradescBlured = null;
    },
    validText: function (inputval) {
      var re = /[!-A-Za-z0-9-_-|-~-].{0,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validateForm(mdl, objThis) {
      this.isAddState = false;
      var isValid = true;
      this.kranameBlured = true;
      this.kradescBlured = true
      return isValid;
    },
    savewizard: function(mdl,objThis){ 
      this.valid = this.validateForm(mdl,objThis);
        if (this.valid) {
          this.isLoading = true;
            let tblNm='t12'
            if(mdl) tblNm=mdl
            this.input = {
              kraname: this.addkra.kraname,
              kradesc: this.addkra.kradesc,
              empcode: this.undt.userid,
            }
            if (this.iseditstate == false) {
              this.input.createdby = this.undt.username
            }
              let apiURL='api/master/performance/savekra'
              if (this.iseditstate == true) {
                this.input.kraid = this.editRowData.kraid;
                this.input.lastmodifiedby = this.undt.username;
                apiURL = "api/master/performance/updatekra";
              }
              this.isAddState=false
              this.input.useremail= this.undt.username,
              this.input.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;     
                if(result.data.errorCode == 0){
                  let rowdata = {}
                  this.getPerformanceData2(rowdata);
                    $(".close").click();
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
            this.displayError(e)
          })
        }
    },
    openEditModule(currRowData) {
      this.editRowData = currRowData;
      this.addkra.kraid = currRowData.kraid;
      this.addkra.kraname = currRowData.kra;
      this.addkra.kradesc = currRowData.description;
      this.iseditstate = true;
    },
  }

}
</script>