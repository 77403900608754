<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header row">
              <div class="content-header-left col-md-9 col-12 mb-1 pad_top_mar_bot-5">
                  <div class="row breadcrumbs-top">
                      <div class="col-12">
                          <h3 class="content-header-title float-left mb-0"> BTC Consumption Report</h3>
                          <div class="breadcrumb-wrapper">
                              <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/reports/btcreleasenotereport'>BTC Consumption Report</router-link></li>
                              </ol>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-9 col-md-9 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1 mobile_headercommon_width">Search Release Note Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                  <div class="form-group col-md-12 mb-1">
                      <label>
                        <div class="radio-cntrl mr-4">
                          <input type="radio" @change="onChangeSheetType($event)"  value="detailwise" v-model="search.sheettype"><span>Detail Summary</span>
                        </div>
                      </label>
                      <label>
                        <div class="radio-cntrl mr-4">
                          <input type="radio" @change="onChangeSheetType($event)"  value="btcswisechart" v-model="search.sheettype"><span>BTC Wise Summary </span>
                        </div>
                      </label> 
                      <label>
                        <div class="radio-cntrl">
                          <input type="radio" @change="onChangeSheetType($event)"  value="taskwisechart" v-model="search.sheettype"><span>Task Wise Summary </span>
                        </div>
                      </label>
                    </div>                    
                  </div>
                  <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Release Code</label>
                  <input type="text" class="form-control" placeholder="Enter Release Code"  v-model="search.releasecode"  :maxlength="maxLength"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Business Task Code	</label>
                  <input type="text" class="form-control" placeholder="Enter Business Task Code"  v-model="search.businesstaskcode"   >
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Task Code	</label>
                  <input type="text" class="form-control" placeholder="Enter Task Code"  v-model="search.taskcode"   >
                  </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" :multiple="true" :clear-on-select="true" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left"> Approval Manager</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Approval Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="bshlist" v-if="pagefeatures.empreportingmanager" :disabled="true"/>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Approval Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="bshlist" v-else/>
                    </div>
                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label for="">Release Date From</label>
                  <date-picker placeholder="Select Release Date From" v-model="search.createddatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                  </div>
                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label for="">Release Date To</label>
                  <date-picker placeholder="Select Release Date To" v-model="search.createddateto" :disabled-date="disabledBefore" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                  </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0 mt-2 text-right">
                      <button v-if="this.sheettype == 'detailwise'" type="submit" class="btn btn-relief-primary mt-0 mr-1 sheetswitch" @click="getreleasenotelist(search,1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button v-else-if="this.sheettype == 'btcswisechart'" type="submit" class="btn btn-relief-primary mt-0 mr-1 sheetswitch" @click="btcWiseChart(search,1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button v-else type="submit" class="btn btn-relief-primary mt-0 mr-1 sheetswitch" @click="taskWiseChart(search,1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-0 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>

                      <button v-if="this.sheettype == 'detailwise'"  type="move" class="btn btn-relief-primary mt-0 mr-0" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                      <button v-else-if="this.sheettype == 'btcswisechart'" type="move" class="btn btn-relief-primary mt-0 mr-0" @click="BTExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                      <button v-else type="move" class="btn btn-relief-primary mt-0 mr-0" @click="TKExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                     <h4 class="card-title text-left">
                      Detail Wise Consumption - Release Note List
                      </h4>
                  </div>
                  <div class="">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="card-datatable">
                              <div
                                class="table-responsive_sitcky"
                                v-if="showtabledata"
                              >
                                <table class="table table-hover table-striped table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>       
                                      <th scope="col" class="text-center align-middle first-col-sticky" style="min-width:45px;">#</th>
                                      <th scope="col" class="align-middle second-col-sticky"  style="min-width:160px;">Release Note code</th>
                                    <th scope="col"   class="align-middle">Project Name</th>
                                    <th scope="col" style="min-width: 200px;" class="align-middle">Business Task Code</th>
                                    <th scope="col" style="min-width: 200px;"  class="align-middle">Task Code(s)</th>
                                    <th scope="col" style="min-width: 200px;"  class="align-middle">Issue Code(s)</th>
                                    <th scope="col" style="min-width: 200px;"  class="align-middle">Cycleset Code(s)</th>
                                    <th scope="col"  style="min-width: 200px;"  class="align-middle">Approval Email</th>
                                    <th scope="col"  style="min-width: 200px;"  class="align-middle">Deployment Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
       
                                    <template v-for="(data,index) in companymasterlist"> 

                                      <tr class="release_notemerge_font">
                                        <td class="text-center font-weight-normal first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                        <td   class=" font-weight-normal second-col-sticky">{{data.releasenotecode}}</td>
                                        <td class="title font-weight-normal" style="text-align:left;">
                                          <span class="cursorpointercls sitebluecolor" @click="redirecttourl('projectId',data)" v-html="showprojectname(data.projectname)"></span> 
                                        </td>
                                        <td class="title font-weight-normal" style="  min-width: 100px;">
                                          <span v-html="showprojectname(data.businesstaskcode)"></span>
                                        </td>
                                        <td class="title font-weight-normal" >
                                          <span v-html="showprojectname(data.taskcode)"></span>
                                        </td>
                                        <td class="title font-weight-normal" v-if="data.issuecode">
                                          <span v-html="showprojectname(data.issuecode)"></span>
                                        </td>
                                        <td class="title font-weight-normal" v-else>NA</td>
                                        <td class="title font-weight-normal" v-if="data.cyclesetcode">
                                          <span v-html="showprojectname(data.cyclesetcode)"></span>
                                        </td>
                                        <td class="title font-weight-normal" v-else>NA</td>
                                        <td class="title font-weight-normal" v-if="data.apprvedbyemail">
                                          <span v-html="showprojectname(data.apprvedbyemail)"></span>
                                        </td>
                                        <td class="title font-weight-normal" v-else>NA</td>
                                        <td class="title font-weight-normal" v-if="data.releasedate">
                                          <span v-html="showprojectname(data.releasedate)"></span>
                                        </td>
                                        <td class="title font-weight-normal" v-else>NA</td>
                                      </tr>
                                     </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="7" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" id="btcwisecharDiv" style="display:none;">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">

                  <h4 class="card-title">
                    BTC Wise Release Note Consumption Summary Report 
                    </h4>
                    </div>
                    <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="table-responsive_sitcky"  v-if="sheettype != 'detailwise' && sheettype != 'taskswisechart' ">
                            <table class="table table-hover table-striped table-bordered table-sm all_table_stickys" aria-describedby="mydesc">
                              <thead>
                                    <tr>
                                      <th scope="col" class="align-middle first-col-sticky" style="min-width:100px;">Business Task Code</th>
                                      <th scope="col" class="align-middle "  >APPROVAL EMAIL</th>

                                      <th scope="col" class="align-middle "  >ReleaseCount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(spcdata, index) in btcwiseChartList">
                                        <tr v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">
                                            <td style="text-align:left; background-color:#F3F2F7;" class="first-col-sticky cursorpointercls sitebluecolor" @click="redirecttourl('BTCprojectId',spcdata)"><strong class="hod_table_pl-1">{{index}}</strong></td>
                                        </tr>
                                         <tr v-for="(v,i) in spcdata" v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">  
                                            <td class="title" style="text-align:left;">
                                              {{ v.businesstaskcode }}
                                            </td>
                                            <td  >
                                              <span v-html="showprojectname(v.apprvedbyemail)"></span>

                                             </td>
                                            <td :class="'uppercasetext  '+index+1+currPageNum*pagelimit">
                                              {{ v.releasecount }}
                                            </td>
                                         </tr>
                                    </template>
                                    <template v-if="btcwiseChartList.length == 0" >
                                      <tr>
                                        <td colspan="3"  style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                              </table>
                          </div>
                          </div>
                          </div>
                      </div>
                      </div>
                      <div class="pageCenter pt-2">
        <pagination v-if="sheettype != 'detailwise' && sheettype != 'taskswisechart'" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallbackBT"/>
      </div>
                  </div>
                  </div>
                  </div>
            <div class="row" id="taskwisecharDiv" style="display:none;">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">

                  <h4 class="card-title">
                    Task Wise Release Note Consumption Summary Report 
                    </h4>
                    </div>
                    <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="table-responsive_sitcky"  v-if="sheettype != 'detailwise'  && sheettype != 'btcwisechart'">
                            <table class="table table-hover table-striped table-bordered table-sm all_table_stickys" aria-describedby="mydesc">
                              <thead>
                                    <tr>
                                      <th scope="col" class="align-middle first-col-sticky" style="min-width:100px;">Task Code</th>
                                      <th scope="col" class="align-middle">APPROVAL EMAIL</th>
                                      <th scope="col" class="align-middle">ReleaseCount</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(spcdata, index) in taskwiseChartList">
                                        <tr v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">
                                            <td style="text-align:left; background-color:#F3F2F7;" class="first-col-sticky cursorpointercls sitebluecolor" @click="redirecttourl('TaskprojectId',spcdata)"><strong class="hod_table_pl-1">{{index}}</strong></td>
                                        </tr>
                                         <tr v-for="(v,i) in spcdata" v-bind:key="spcdata.empid+Math.floor(Math.random() * 1000000000)">  
                                            <td class="title font-weight-normal" style="text-align:left;">
                                              {{ v.taskcode }}
                                            </td>
                                            <td class="font-weight-normal">
                                              <span v-html="showprojectname(v.apprvedbyemail)"></span>

                                             </td>
                                            <td :class="'uppercasetext font-weight-normal '+index+1+currPageNum*pagelimit">
                                              {{ v.releasecount }}
                                            </td>
                                         </tr>
                                    </template>
                                    <template v-if="taskwiseChartList.length == 0" >
                                      <tr>
                                        <td colspan="3"  style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                              </table>
                          </div>

                          </div>
                          </div>
                      </div>
                      </div>
                      <div class="pageCenter pt-2">
        <pagination v-if="sheettype != 'detailwise' && sheettype != 'btcswisechart'" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallbackTK"/>
      </div>

                  </div>
                  </div>
                  </div>
            
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import DatePicker from "vue2-datepicker";
import commonMethods from '../../utils/commonMethods';


export default {
  name: "BTCReleasenoteReport",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker
  },
  mixins: [ commonMethods ],

  data() {
    return {
      maxLength:16,
      search: {
        searchProjectname:null,
        status:null,
        empname:null,
        mngrname:null,
        releasecode:null,
        createddateto:null,
        createddatefrom:null,
        businesstaskcode:null,
        taskcode:null,
        sheettype : 'detailwise'
      },
      emplist:[],
      mngrlist:[],
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      listfullPage:true,
      islistLoading:false,
      fullPage: true,
      showprojectdata: [],
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      projectcurrentstate: "local",
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      technologies:[],
      companymasterlist:[],
      tokendata:null,
      searchOffset: 1,
      searchSession: false,
      isresponce:false,
      bshlist : [],
      releasenotetatus:apiUrl.releasenotestatus,
      isBothDateSelected:false,
      islistLoading:false,
      sheettype : 'detailwise',
      btcwiseChartList: [],
      taskwiseChartList: [],
    };
  },
  mounted() {
   let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        if(this.pagefeatures.empreportingmanager){
          this.search.mngrname = [parseInt(this.undt.userid)]
          rowdata.mngrname = [parseInt(this.undt.userid)]
        }
        this.getreleasenotelist(rowdata);
        this.getAllocatedProjectList()
        // this.getEManagerList(rowdata);
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getEManagerList(proid) {
      this.pageoffset=1
       axios({
      method: "POST",
      url: "api/releasenote/getEMforreleasenote",
      data: { roleid: ["492"],projectidarr:[proid],useremail: this.undt.username,empcode: this.undt.userid },
      'headers':{'authorization':this.tokendata}
      }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
       this.bshlist = result.data.data.rows;
      } else {
      this.bshlist = [];
      }
      }).catch(e => {
        this.displayError(e)
      });
    },
      mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.pageoffset=1
      this.search.searchProjectname = state;
      if (!state) {
          this.search.approvedby = null
          this.approvalmanager = []
      }else{
          this.getEManagerList(this.search.searchProjectname);
      }
    }, 
    getAllocatedProjectList: function (){
      this.pageoffset=1
      this.isLoading = true;
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        projectname:null,
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectnameList = result.data.data;
        } else {
          this.projectnameList = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    showprojectname(code){
        let responsestrs='';
        let prlists=code.split(',')
        if(prlists.length > 1){
          for(let i=0;i<prlists.length;i++){
            // responsestrs +=prlists[i]+' <br/><hr>'
            responsestrs +=(i+1)+'. '+prlists[i]+' <br/>'
          }
        }else{
            for(let i=0;i<prlists.length;i++){
                responsestrs +=prlists[i]
            }
        }
        return responsestrs
        },
    resetRecords: function () {
      let rowdata={}
      this.search.searchProjectname = null;
      this.bshlist = [];
      this.search.createdby = null;
      this.search.companyname = null;
      this.search.businessname = null;
      this.search.empname=null
      this.search.releasecode=null;
      this.search.createddateto=null;
      this.search.createddatefrom=null;
      this.search.businesstaskcode=null;
      this.search.taskcode=null;

      if(!this.pagefeatures.empreportingmanager){
        this.search.mngrname=null
      }else{
        rowdata.mngrname = [parseInt(this.undt.userid)]
      }
      this.searchSession = false
      this.searchOffset = 1
 
      this.getreleasenotelist(rowdata);
      this.btcWiseChart(rowdata);
      this.taskWiseChart(rowdata);

    },
    getreleasenotelist(rowdata,flag) {
      this.islistLoading = true; 
      this.parentmoduledata = rowdata;
      if(flag==1){
      this.pageoffset=1
      this.page=1
    }

      this.input = {
          offset: this.pageoffset,
          limit: apiUrl.LIMIT,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
      };

        this.input.project = this.search.searchProjectname ? this.search.searchProjectname : null 
        this.input.approvedby = (this.search.mngrname) ? (this.search.mngrname) : null 
        this.input.releasecode = this.search.releasecode ? this.search.releasecode : null  
        this.input.businesstaskcode = this.search.businesstaskcode ? this.search.businesstaskcode : null  
        this.input.taskcode = this.search.taskcode ? this.search.taskcode : null  

        let assignedate=''
        if(this.search.createddateto){
        assignedate = new Date(this.search.createddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom+' 00:00:00':null
        this.input.dateto = this.search.createddateto?assignedate:null
      if(rowdata.hasOwnProperty('mngrname') && rowdata.mngrname) {
        this.searchSession = true
        this.input.offset = this.searchOffset
      } else {
        this.searchSession = false
        this.searchOffset = 1
      }
      let url = "api/reports/getBTCReleaseNoteList";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true; 
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    btcWiseChart(rowdata,flag) {
        this.islistLoading = true; 
        this.parentmoduledata = rowdata;   
        if(flag==1){
          this.page=1
        }
    
        this.input = {
          offset: this.pageoffset,
          limit: apiUrl.LIMIT,
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
         };

      this.input.project = this.search.searchProjectname ? this.search.searchProjectname : null 
      this.input.approvedby = (this.search.mngrname) ? (this.search.mngrname) : null 
      this.input.releasecode = this.search.releasecode ? this.search.releasecode : null  
      this.input.businesstaskcode = this.search.businesstaskcode ? this.search.businesstaskcode : null  
      this.input.taskcode = this.search.taskcode ? this.search.taskcode : null  
      let assignedate=''
        if(this.search.createddateto){
        assignedate = new Date(this.search.createddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom+' 00:00:00':null
        this.input.dateto = this.search.createddateto?assignedate:null

        axios({
            method: "POST",
            url: "api/reports/getConsumptionBTCReleaseNoteList",
            data: this.input,
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.islistLoading = false;
            if (result.data.errorCode == 0) {
                const groupByCategory = result.data.data.rows.reduce((group, product) => {
                  const { projectname } = product;
                  group[projectname] = group[projectname] ?? [];
                  group[projectname].push(product);
                  return group;
                }, {});

                this.btcwiseChartList = groupByCategory;
                this.totalcount = result.data.data.count
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                this.btcwiseChartList = [];
                this.totalcount = 0;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            }
        }).catch(e => {
        this.displayError(e)
      });
    },
    taskWiseChart(rowdata,flag) {
        this.islistLoading = true; 
        this.parentmoduledata = rowdata;   
        if(flag==1){
          this.page=1
        }
    
        this.input = {
          offset: this.pageoffset,
          limit: apiUrl.LIMIT,
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
         };

      this.input.project = this.search.searchProjectname ? this.search.searchProjectname : null 
      this.input.approvedby = (this.search.mngrname) ? (this.search.mngrname) : null 
      this.input.releasecode = this.search.releasecode ? this.search.releasecode : null  
      this.input.businesstaskcode = this.search.businesstaskcode ? this.search.businesstaskcode : null  
      this.input.taskcode = this.search.taskcode ? this.search.taskcode : null  
        let assignedate=''
        if(this.search.createddateto){
        assignedate = new Date(this.search.createddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom+' 00:00:00':null
        this.input.dateto = this.search.createddateto?assignedate:null
        
        axios({
            method: "POST",
            url: "api/reports/getConsumptionTaskReleaseNoteList",
            data: this.input,
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.islistLoading = false;
            if (result.data.errorCode == 0) {
                const groupByCategory = result.data.data.rows.reduce((group, product) => {
                  const { projectname } = product;
                  group[projectname] = group[projectname] ?? [];
                  group[projectname].push(product);
                  return group;
                }, {});

                this.taskwiseChartList = groupByCategory;
                this.totalcount = result.data.data.count
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                this.taskwiseChartList = [];
                this.totalcount = 0;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            }
        }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      if(this.searchSession) {
        this.searchOffset = pageNum;
      }
      this.currPageNum = pageNum - 1;
      this.getreleasenotelist(this.parentmoduledata);
    },
    notBeforeToday: function (date) {
      this.search.createddateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    selectedfromdate(){
      if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
          this.isBothDateSelected = false
      }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
          this.isBothDateSelected = true
      }
    },
    selectedtodate(){
      if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
          this.isBothDateSelected = false
      }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
          this.isBothDateSelected = true
      }
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    onChangeSheetType(event) {
      this.sheettype = event.target.value
      this.pageoffset=1
      this.page = 1
      let rowdata={}
      this.search.searchProjectname = null;
      this.search.createdby = null;
      this.search.companyname = null;
      this.search.businessname = null;
      this.search.empname=null
      this.search.releasecode=null;
      this.search.createddateto=null;
      this.search.createddatefrom=null;
      this.search.businesstaskcode=null;
      this.search.taskcode=null;
      if (this.sheettype == 'detailwise'){
        
          this.showtabledata=true
          $('#btcwisecharDiv').hide();
          $('#taskwisecharDiv').hide();
          $('#reportTopScroll').show();
            this.getreleasenotelist(rowdata,0);
      
      }else if(this.sheettype == 'btcswisechart'){
        this.showtabledata=false
        $('#btcwisecharDiv').show();
          $('#taskwisecharDiv').hide();
          $('#reportTopScroll').hide();
            this.btcWiseChart(rowdata,0);
           
      }else {
          this.showtabledata=false
          $('#btcwisecharDiv').hide();
          $('#taskwisecharDiv').show();
          $('#reportTopScroll').hide();
            this.taskWiseChart(rowdata,0);
         
      }
      this.totalcount = 0;
      this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
       
    },
    clickCallbackBT: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
        this.btcWiseChart(this.parentmoduledata);
       setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#btcwisecharDiv").offset().top -70
            });
        }, 500);

    },
    clickCallbackTK: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
        this.taskWiseChart(this.parentmoduledata);
       setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#taskwisecharDiv").offset().top -70
            });
        }, 500);

    },
    ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      let assignedate=''
      if(this.search.createddateto){
        assignedate = new Date(this.search.createddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input = {
          limit:null,
          offset:null,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          empid: this.undt.userid,
          releasecode: this.search.releasecode ? this.search.releasecode : null,
          project:  this.search.searchProjectname ? this.search.searchProjectname : null,
          approvedby: this.search.mngrname ? this.search.mngrname : null,
          datefrom: this.search.createddatefrom?this.search.createddatefrom+' 00:00:00' :null,
          dateto: this.search.createddateto?assignedate:null,
          businesstaskcode: this.search.businesstaskcode ? this.search.businesstaskcode : null,
      }; 
      axios({
        method: "POST",
        url: `api/reports/getBTCReleaseNoteList`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
        this.exportxlsdata = result.data.data.rows;
        var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        }
          this.islistLoading = false;
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
        let ShowLabel = 'Detail_Consumptionreport_releasenote_'+filename
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let CSV = ''
        if (ShowLabel) {
            let row = ''
            for (let index in arrData[0]) {
                row += index + ','
            }
            row = row.slice(0, -1)
            
            CSV = 'Sr#,Release Code,Project Name,BTC Code,Task Code, Issue Code,Cycleset Code, Approval Email,Deployment Date' + '\r\n'

        }
          var row = ''
          for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let releasenotecode = index.hasOwnProperty('releasenotecode') ? index.releasenotecode : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
            let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode : '';
            let taskcode = index.hasOwnProperty('taskcode') ? index.taskcode : 'NA';
            let issuecode = index.hasOwnProperty('issuecode') ? index.issuecode ==null || index.issuecode =='' || index.issuecode ==' ' ? 'NA' : index.issuecode : 'NA';
            let cyclesetcode = index.hasOwnProperty('cyclesetcode') ? index.cyclesetcode ==null || index.cyclesetcode =='' || index.cyclesetcode ==' ' ? 'NA' : index.cyclesetcode : 'NA';
            let apprvedbyemail = index.hasOwnProperty('apprvedbyemail') ? index.apprvedbyemail : 'NA';
            let releasedate = index.hasOwnProperty('releasedate') ? index.releasedate : 'NA';
            row += '"' + srno + '",',
            row += '"' + releasenotecode + '",',
            row += '"' + projectname + '",',
            row += '"' + businesstaskcode + '",',
            row += '"' + taskcode + '",',
            row += '"' + issuecode + '",',
            row += '"' + cyclesetcode + '",',
            row += '"' + apprvedbyemail + '",',
            row += '"' + releasedate + '",',
            row += '\r\n'
          }
          CSV += row
          if (CSV === '') {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          return;
          }
          let fileName = 'Detail_Consumptionreport_releasenote_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
          let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
          let link = document.createElement('a')
          link.href = uri
          link.style = 'visibility:hidden'
          link.download = fileName + '.csv'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
              confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
          });
      } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });  
      }
    },
    BTExportXL() {
    this.islistLoading = true;
    this.exportxlsdata = [];
    this.exporterr = "";
    let assignedate=''
    if(this.search.createddateto){
      assignedate = new Date(this.search.createddateto)
      assignedate.setDate(assignedate.getDate() + 1)
      moment(String(assignedate)).format('YYYY-MM-DD');
    }
    this.input = {
        limit:null,
        offset:null,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        empid: this.undt.userid,
        releasecode: this.search.releasecode ? this.search.releasecode : null,
        project:  this.search.searchProjectname ? this.search.searchProjectname : null,
        approvedby: this.search.mngrname ? this.search.mngrname : null,
        datefrom: this.search.createddatefrom?this.search.createddatefrom+' 00:00:00' :null,
        dateto: this.search.createddateto?assignedate:null,
        businesstaskcode: this.search.businesstaskcode ? this.search.businesstaskcode : null,
        taskcode: this.search.taskcode ? this.search.taskcode : null

    }; 
    axios({
      method: "POST",
      url: `api/reports/getConsumptionBTCReleaseNoteList`,
      data: this.input,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      if (result.data.errorCode == 0) {
      this.exportxlsdata = result.data.data.rows;
      var filename = "";
          this.BTdownload(result.data.data.rows,filename)
      } else {
          Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          });
      }
        this.islistLoading = false;
    }).catch(e => {
        this.displayError(e)
      });
    },
    BTdownload(readExportdata,filename) {
    if (readExportdata.length > 0) {
      let ShowLabel = 'BTC_Consumptionreport_releasenoteCount_'+filename
      let JSONData = readExportdata
      let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
      let CSV = ''
      if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
              row += index + ','
          }
          row = row.slice(0, -1)
          
          CSV = 'Sr#,Project Name,BTC Code,Release Count, Approval Email' + '\r\n'

      }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode : '';
          let releasecount = index.hasOwnProperty('releasecount') ? index.releasecount : '';
          let apprvedbyemail = index.hasOwnProperty('apprvedbyemail') ? index.apprvedbyemail : '';
          row += '"' + srno + '",',
          row += '"' + projectname + '",',
          row += '"' + businesstaskcode + '",',
          row += '"' + releasecount + '",',
          row += '"' + apprvedbyemail + '",',
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        return;
        }
        let fileName = 'BTC_Consumptionreport_releasenoteCount_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
        title: "Success!",
        text: "Export Report successful",
        icon: "success",
        customClass: {
            confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
        });
    } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
          confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });  
    }
    },
    TKExportXL() {
    this.islistLoading = true;
    this.exportxlsdata = [];
    this.exporterr = "";
    let assignedate=''
    if(this.search.createddateto){
      assignedate = new Date(this.search.createddateto)
      assignedate.setDate(assignedate.getDate() + 1)
      moment(String(assignedate)).format('YYYY-MM-DD');
    }
    this.input = {
        limit:null,
        offset:null,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        empid: this.undt.userid,
        releasecode: this.search.releasecode ? this.search.releasecode : null,
        project:  this.search.searchProjectname ? this.search.searchProjectname : null,
        approvedby: this.search.mngrname ? this.search.mngrname : null,
        datefrom: this.search.createddatefrom?this.search.createddatefrom+' 00:00:00' :null,
        dateto: this.search.createddateto?assignedate:null,
        businesstaskcode: this.search.businesstaskcode ? this.search.businesstaskcode : null,
        taskcode: this.search.taskcode ? this.search.taskcode : null

    }; 
    axios({
      method: "POST",
      url: `api/reports/getConsumptionTaskReleaseNoteList`,
      data: this.input,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      if (result.data.errorCode == 0) {
      this.exportxlsdata = result.data.data.rows;
      var filename = "";
          this.TKdownload(result.data.data.rows,filename)
      } else {
          Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          });
      }
        this.islistLoading = false;
    }).catch(e => {
        this.displayError(e)
      });
    },
    TKdownload(readExportdata,filename) {
    if (readExportdata.length > 0) {
      let ShowLabel = 'BTC_Consumptionreport_releasenoteCount_'+filename
      let JSONData = readExportdata
      let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
      let CSV = ''
      if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
              row += index + ','
          }
          row = row.slice(0, -1)
          
          CSV = 'Sr#,Project Name,Task Code,Release Count, Approval Email' + '\r\n'

      }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let taskcode = index.hasOwnProperty('taskcode') ? index.taskcode : '';
          let releasecount = index.hasOwnProperty('releasecount') ? index.releasecount : '';
          let apprvedbyemail = index.hasOwnProperty('apprvedbyemail') ? index.apprvedbyemail : '';
          row += '"' + srno + '",',
          row += '"' + projectname + '",',
          row += '"' + taskcode + '",',
          row += '"' + releasecount + '",',
          row += '"' + apprvedbyemail + '",',
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        return;
        }
        let fileName = 'TASK_Consumptionreport_releasenoteCount_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
        title: "Success!",
        text: "Export Report successful",
        icon: "success",
        customClass: {
            confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
        });
    } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
          confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });  
    }
    },
    export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (btnstate == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid
        })
      } else if (btnstate == 'BTCprojectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata[0].projectid
        })
      } else if (btnstate == 'TaskprojectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata[0].projectid
        })
      }
    },
  }
};
</script>
