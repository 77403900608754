<template>
  <div id="address-step-modern" class="content">
    <div class="row mx-0">
      <section id="basic-tabs-components" class="w-100">
        <div class="match-height">
          <div class="col-xl-12 col-lg-12">
            <div class="card">
              <div class="card-body pt-1 px-0">
                <div class="d-flex justify-content-between align-items-center jobapp_tabbing_design_view">
                  <ul class="nav nav-tabs mb-0 mobile_tabbing_change_order-2" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active px-sm-mobile-10"
                        id="job-tab1"
                        data-toggle="tab"
                        href="#job-dis"
                        aria-controls="home"
                        role="tab"
                        aria-selected="true"
                        >Job Application
                      </a>
                    </li>
                    <!-- <li class="nav-item">
                      <a
                        class="nav-link px-sm-mobile-10"
                        id="profile-tab1"
                        data-toggle="tab"
                        href="#profile1"
                        aria-controls="profile"
                        role="tab"
                        aria-selected="false"
                        >Pre screening settings
                      </a>
                    </li> -->
                  </ul>
                  <!-- <div class="addManagePositis align-items-center ml-auto mobile_tabbing_change_order-1">
                    <div class="buttons_hrms_view demo-inline-spacing">
                      <button
                        type="button"
                        class="btn btn-flat-primary waves-effect hrms-bt-mr-5 mt-0 mr-0 mobile_margin-px-0"
                      >
                        VIEW JD
                      </button>
                      <button
                        type="button"
                        class="btn btn-flat-primary waves-effect hrms-bt-mr-5 mt-0 mobile_margin-px-0"
                      >
                        PREVIEW APPLICATION
                      </button>
                      <button
                        type="button"
                        class="btn btn-flat-primary waves-effect hrms-bt-mr-5 mt-0 mr-0 mobile_margin-px-0"
                      >
                        CANCEL
                      </button>
                    </div>
                  </div> -->
                </div>
                <div class="tab-content">
                  <div
                    class="tab-pane active"
                    id="job-dis"
                    aria-labelledby="job-tab1"
                    role="tabpanel"
                  >
                    <section id="vertical-tabs">
                      <div class="row match-height">
                        <div class="col-xl-12 col-lg-12">
                          <div class="card">
                            <div class="card-body nav-vertical mr-0 px-0">
                              <div class="row nav-vertical_margin_x5 pl-1">
                                <ul class="col-md-2 nav nav-tabs nav-left flex-column hrms_ul_job_detals mr-0 px-0" role="tablist">
                                  <li class="nav-item" v-for="(app ,apindx) in applicationlist" v-bind:key="apindx">
                                    <a class="nav-link capitalisetext " id="jobResume-tab" data-toggle="tab" aria-controls="jobAppResume" href="#jobAppResume" role="tab" aria-selected="true" @click="selecteapplication(app.id,app.label)">{{app.label}}</a>
                                  </li>
                                  <li class="text-center" v-if="applicationlist.length==0">
                                    Applications Not Exist
                                  </li>
                                </ul>
                                <div class="tab-content col-md-10 hrms_new_designtabs">
                                  <div class="tab-pane active" id="jobAppResume" role="tabpanel" aria-labelledby="jobResume-tab" style="overflow: unset;">
                                    <div class="" id="basic-table">
                                      <div class="card_views_hrms">
                                        <div class="table-responsive pb-2" v-if="fieldinputlist.length>0">
                                          <table class="table table-sm dataTableListing table-striped all_table_stickys table_input_width_150 " aria-describedby="mydesc">
                                          <thead>
                                            <tr>
                                              <th scope="col" class="text-center py-1 align-middle first-col-sticky">Field Name</th>
                                             
                                              <th scope="col" class="text-center align-middle" v-for="(data1,index1) in tempheader[0].heads" v-bind:key="index1">
                                                {{data1.fieldname}}
                                              </th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            <template>

                                              <tr class="custom_radio_but-text" v-for="(data2,index2) in tempheader" v-bind:key="index2">
                                                <td class="text-center font-weight-normal text-capitalize first-col-sticky">
                                                    {{ data2.label }}
                                                </td>

                                                <td class="text-center font-weight-normal text-capitalize" v-for="(data3,index3) in data2.heads" v-bind:key="index3">

                                                  <span v-if="data3.fieldtype=='toggle'">
                                                      <div class="custom-control custom-switch custom-switch-secondary">
                                                          <input type="checkbox"  class="custom-control-input" :id="myconversion(data2.label+data3.modulefieldid)"  v-model="data2.heads[index3].answervalue"/>
                                                          <label class="custom-control-label" :for="myconversion(data2.label+data3.modulefieldid)">
                                                              <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{data3.optionalvalue3[0].label}}</label></span>
                                                              <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{data3.optionalvalue3[1].label}}</label></span>
                                                          </label>
                                                      </div>
                                                  </span>
                                                  
                                                  <span v-if="data3.fieldtype=='text'">
                                                    <input :type="data3.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="data3.fieldplaceholder" v-model="data2.heads[index3].answervalue" @blur="validateForm($event)"
                                                    v-bind:class="{ 
                                                    'form-control': true,
                                                    '': !validText(data2.heads[index3].answervalue)}"
                                                    @close="validateForm($event)" v-if="(data3.validationtype=='readonly' || data3.validationtype=='hidden')" disabled/>

                                                    <input :type="data3.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="data3.fieldplaceholder" v-model="data2.heads[index3].answervalue" @blur="validateForm($event)"
                                                    v-bind:class="{ 
                                                    'form-control': true,
                                                    '': !validText(data2.heads[index3].answervalue)}"
                                                    @close="validateForm($event)" v-else/>
                                                    
                                                  </span>

                                                  <span v-if="data3.fieldtype=='number'">
                                                    <input :type="data3.fieldtype" id="fieldnumber" class="form-control capitalisetext" name=""
                                                    pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                    :placeholder="data3.fieldplaceholder" v-model="data2.heads[index3].answervalue" @blur="validateForm($event)"
                                                    v-bind:class="{
                                                    'form-control': true,
                                                    '': !validText2(data2.heads[index3].answervalue)}"
                                                    @close="validateForm($event)" v-if="(data3.validationtype=='readonly' || data3.validationtype=='hidden')" disabled/>

                                                    <input :type="data3.fieldtype" id="fieldnumber" class="form-control capitalisetext" name=""
                                                    pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                    :placeholder="data3.fieldplaceholder" v-model="data2.heads[index3].answervalue" @blur="validateForm($event)"
                                                    v-bind:class="{
                                                    'form-control': true,
                                                    '': !validText2(data2.heads[index3].answervalue)}"
                                                    @close="validateForm($event)" v-else/>
                                                    
                                                  </span>

                                                  <span class="radiobutn_jobapplications" v-if="data3.fieldtype=='radio'">
                                                    <span v-for="(radiofieldvalues,k) in data3.radiooptionalvalue" v-bind:key="k">
                                                      <div class=" form-check form-check-inline mt-0 mb-0">
                                                        <input class="form-check-input" type="radio" v-model="data2.heads[index3].answervalue" :value="Object.values(radiofieldvalues)[0]" > <span class="pl-0" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                      </div>
                                                    </span>
                                                    
                                                  </span>

                                                  <span v-if="data3.fieldtype=='checkbox'">
                                                      <treeselect :placeholder="data3.fieldplaceholder" class="projectlistdd" v-model="data2.heads[index3].answervalue" :options="data3.optionalvalue" :multiple="true" :clear-on-select="true"/>
                                                      
                                                  </span>

                                                  <span v-if="data3.fieldtype=='drop-down'">
                                                      <treeselect :placeholder="data3.fieldplaceholder" class="projectlistdd" v-model="data2.heads[index3].answervalue" :id="myconversion(data2.label+data3.modulefieldid)" :options="data3.optionalvalue1"/>
                                                      
                                                    </span>

                                                  <span v-if="data3.fieldtype=='multiselect-dropdown'">
                                                      <treeselect :placeholder="data3.fieldplaceholder" class="projectlistdd" v-model="data2.heads[index3].answervalue" :options="data3.optionalvalue2" :multiple="true" :clear-on-select="true"/>
                                                      
                                                  </span>

                                                  <span v-if="data3.fieldtype=='date'">
                                                      <date-picker :placeholder="data3.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="data2.heads[index3].answervalue" valueType="format"
                                                      class="startdate capitalisetext" id="EndDate" :minute-step="5" v-if="(data3.validationtype=='readonly' || data3.validationtype=='hidden')" disabled></date-picker>

                                                      <date-picker :placeholder="data3.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="data2.heads[index3].answervalue" valueType="format"
                                                      class="startdate capitalisetext" id="EndDate" :minute-step="5" v-else></date-picker>
                                                      
                                                  </span>

                                                  <span v-if="data3.fieldtype=='datetime-local'">
                                                      <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="data2.heads[index3].answervalue" v-if="(data3.validationtype=='readonly' || data3.validationtype=='hidden')" disabled></vue-timepicker>

                                                      <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="data2.heads[index3].answervalue" v-else></vue-timepicker>
                                                      
                                                  </span>

                                                </td>
                                              </tr>
                                            </template>
                                          </tbody>
                                          </table>
                                          
                                         
                                        </div>
                                        <!-- <div class="col-md-4 mt-2" v-if="application == 'Resume' && application1 == 1">
                                          <label class="capitalisetext">Enable resume parsing for</label>
                                          <treeselect placeholder="Select" class="projectlistdd" v-model="resumeparsing" id="resumeparsing" :options="resumeparsinglist"/>
                                        </div> -->
                                        <div v-if="fieldinputlist.length==0" style="text-align: center;">Fields Not Exists</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div class="buttons_hrms_view text-right px-0">
                      <button class="btn btn-relief-secondary btn-prev2 hrms-bt-mr-5" @click="prev10">
                        <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                      </button>
                      <button class="btn btn-relief-success btn-next2 btn-next17   hrms-bt-mr-5" @click="savewizard($event,0,'indraft')">
                        <span class="align-middle d-sm-inline-block ">Save as Draft</span>
                      </button>
                      <button class="btn btn-primary hrms-bt-mr-5" @click="savewizard($event,1,'insave')">
                        <span class="align-middle d-sm-inline-block "
                          >Save & Continue</span
                        >
                        <em data-feather="arrow-right" class="align-middle mr-sm-25 mr-0"></em>
                      </button>
                    </div>
                  </div>
                  <div
                    class="tab-pane"
                    id="profile1"
                    aria-labelledby="profile-tab1"
                    role="tabpanel"
                  >
                  <div class="px-1">
                    <p>
                      Dragée jujubes caramels tootsie roll gummies gummies icing
                      bonbon. Candy jujubes cake cotton candy. Jelly-o lollipop
                      oat cake marshmallow fruitcake candy canes toffee. Jelly
                      oat cake pudding jelly beans brownie lemon drops ice cream
                      halvah muffin. Brownie candy tiramisu macaroon tootsie
                      roll danish.
                    </p>
                    <p>
                      Croissant pie cheesecake sweet roll. Gummi bears cotton
                      candy tart jelly-o caramels apple pie jelly danish
                      marshmallow. Icing caramels lollipop topping. Bear claw
                      powder sesame snaps.
                    </p>
                  </div>
                  
                    <div class="buttons_hrms_view text-right px-0">
                      <button class="btn btn-relief-secondary hrms-bt-mr-5">
                        <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                      </button>
                      <button class="btn btn-relief-success hrms-bt-mr-5">
                        <span class="align-middle d-sm-inline-block">Save as Draft</span>
                      </button>
                      <button class="btn btn-primary hrms-bt-mr-5">
                        <span class="align-middle d-sm-inline-block "
                          >Save & Continue</span
                        >
                      </button>
                      <button class="btn btn-relief-secondary hrms-bt-mr-5">
                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                        <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.table_input_width_150 tbody td{
      min-width: 150px;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.required-field::after {
  content: "*";
  color: red;
}
</style>

<script>
import axios from 'axios'
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../../utils/commonMethods';
import VueTimepicker from 'vue2-timepicker'
import apiUrl from '../../../constants'

export default {
  name: "JobApplicationDynamic",
  components:{
    Loading,Trash2Icon,DownloadIcon,
    VueElementLoading,DatePicker,VueTimepicker,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    Edit2Icon,
    PlusSquareIcon,
    MinusSquareIcon,
    ClipboardIcon,
    ckeditor: CKEditor.component,
  },
  props:['globaljdid'],
  mixins: [ commonMethods ],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      // TimeFormat: apiUrl.TimeFormat,
      isAddState:false,
      pagefeatures:[],
      tokendata:null,
      moduletrackerid: 20,
      typename: 'jobapplication',
      application1: null,
      application: null,
      applicationlist: apiUrl.applicationlist,
      fieldlist: [],
      fieldinputlist: [],
      fieldsnamevalues: [],
      tempheader: [],
      saveddata: [],
      fieldsnamevalues2: [],
      resumeparsing: null,
      resumeparsinglist: []
    }
  },
  mounted(){

    $(function () {
        'use strict';
        var bsStepper = document.querySelectorAll('.bs-stepper'),
            select = $('.select2'),
            modernWizard = document.querySelector('.modern-wizard-example')
        // select2
        select.each(function () {
            var $this = $(this);
            $this.wrap('<div class="position-relative"></div>');
            $this.select2({
            placeholder: 'Select value',
            dropdownParent: $this.parent()
            });
        });
        let step1 = -1;
        if (typeof modernWizard !== undefined && modernWizard !== null) {
          var modernStepper = new Stepper(modernWizard, {
          linear: false
          });
        
          step1=modernStepper._currentIndex;
          
          // $(modernWizard)
          // .find('.btn-next3')
          // .each(function () {
          //   $(this).on('click', function (e) {
          //   // modernStepper._currentIndex=3
          //   modernStepper.next();
          //   })
          // });
          $(modernWizard)
            .find('.btn-next17')
            .on('click', function () {
              
             if(step1==0)
                    {
                        modernStepper._currentIndex=1
                    }
                    if(step1==1)
                    {
                        modernStepper._currentIndex=2
                    }
                    if(step1==2)
                    {
                        modernStepper._currentIndex=3
                    }
                    if(step1==3)
                    {
                        modernStepper._currentIndex=4
                    }
                    if(step1==4)
                    {
                        modernStepper._currentIndex=0
                    }
              modernStepper._currentIndex=2
              modernStepper.next();
          });
          $(modernWizard)
          .find('.btn-prev10')
          .on('click', function () {
            // modernStepper._currentIndex=3
            modernStepper.previous();
          });
        }
    });

    let userdata = window.localStorage.getItem('userdata');
    if(userdata){
      userdata = JSON.parse(userdata)
      this.undt=userdata
      this.tokendata = window.localStorage.getItem('token');
      this.tempheader = apiUrl.resumejobapp
      if (this.application == null) {
        this.application = 'Resume'
        this.application1 = 1
        this.tempheader = apiUrl.resumejobapp
        this.getfieldinputlist(this.application);
        if (this.globaljdid !=0 && this.globaljdid!=null) {
          this.getsavedapplicationdetailsbytracker(this.application1,this.application);
        }
      }
    }
  },
  methods:{
    selecteapplication(state,state1){
      this.savewizard('',0,null);
      this.application=null;
      this.application=state1;
      this.application1=state;
      if (state == 1) {
        this.tempheader = apiUrl.resumejobapp
      }else if(state == 2){
        this.tempheader = apiUrl.biographicaljobapp
      }else if (state == 3) {
        this.tempheader = apiUrl.contactjobapp
      }else if (state == 4) {
        this.tempheader = apiUrl.addressjobapp
      }else if (state == 5) {
        this.tempheader = apiUrl.workexperiencejobapp
      }else if (state == 6) {
        this.tempheader = apiUrl.educationjobapp
      }else if (state == 7) {
        this.tempheader = apiUrl.jobjobapp
      }else if (state == 8) {
        this.tempheader = apiUrl.personalidentityjobapp
      }else if (state == 9) {
        this.tempheader = apiUrl.personaldocumentsjobapp
      }else if (state == 10) {
        this.tempheader = apiUrl.socialsecurityjobapp
      }else if (state == 11) {
        this.tempheader = apiUrl.lastsalaryjobapp
      }else if (state == 12) {
        this.tempheader = apiUrl.miscjobapp
      }

      if (state1 && state1 !=null) {
        this.getfieldinputlist(state1);
        if (this.globaljdid !=0 && this.globaljdid!=null) {
          this.getsavedapplicationdetailsbytracker(state,state1);
        }
      }else{
        this.fieldinputlist = [];
        this.saveddata = [];
      }
    },
    getfieldinputlist(appid){
        this.isLoading = true;
        this.inputfield= {
          useremail: this.undt.username,
          empcode: parseInt(this.undt.userid),
          typename: this.typename,
          appid: appid,
        }
        axios({
            'method': 'POST',
            'url': 'api/hrms/getfieldinputlist',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.status == true){
          this.fieldinputlist = result.data.data.rows
          var temArray = {};
            for (let i = 0; i < this.fieldinputlist.length; i++) {

              // header logic start
              // if (this.fieldinputlist[i].fieldheader!=null) {
              //   if(!temArray.hasOwnProperty(this.fieldinputlist[i].fieldheader)){
              //     temArray[this.fieldinputlist[i].fieldheader] = [];
              //   }
              //   var pushObj = this.fieldinputlist[i];
              //     temArray[this.fieldinputlist[i].fieldheader].push(pushObj);
              // }
              // header logic end

              // value optional logic start
              if (this.fieldinputlist[i].fieldtype=="checkbox") {
                let tempdata = [];
                for (let j = 0; j < this.fieldinputlist[i].fieldvalues.length; j++) {
                  if (this.fieldinputlist[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata[j] = {}
                    tempdata[j].id=this.fieldinputlist[i].fieldvalues[j].optionvalue
                    tempdata[j].label=this.fieldinputlist[i].fieldvalues[j].optionvalue
                  }
                  this.fieldinputlist[i]['optionalvalue']=tempdata
                }
              }
              if (this.fieldinputlist[i].fieldtype=="radio") {
                let tempdata2 = [];
                for (let j = 0; j < this.fieldinputlist[i].fieldvalues.length; j++) {
                  if (this.fieldinputlist[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata2[j] = {}
                    tempdata2[j].id=this.fieldinputlist[i].fieldvalues[j].optionvalue
                    tempdata2[j].label=this.fieldinputlist[i].fieldvalues[j].optionvalue
                  }
                  this.fieldinputlist[i]['radiooptionalvalue']=tempdata2
                }
              }
              if (this.fieldinputlist[i].fieldtype=="drop-down") {
                let tempdata3 = [];
                for (let j = 0; j < this.fieldinputlist[i].fieldvalues.length; j++) {
                  if (this.fieldinputlist[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata3[j] = {}
                    tempdata3[j].id=this.fieldinputlist[i].fieldvalues[j].optionvalue
                    tempdata3[j].label=this.fieldinputlist[i].fieldvalues[j].optionvalue
                  }
                  this.fieldinputlist[i]['optionalvalue1']=tempdata3
                }
              }
              if (this.fieldinputlist[i].fieldtype=="multiselect-dropdown") {
                let tempdata4 = [];
                for (let j = 0; j < this.fieldinputlist[i].fieldvalues.length; j++) {
                  if (this.fieldinputlist[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata4[j] = {}
                    tempdata4[j].id=this.fieldinputlist[i].fieldvalues[j].optionvalue
                    tempdata4[j].label=this.fieldinputlist[i].fieldvalues[j].optionvalue
                  }
                  this.fieldinputlist[i]['optionalvalue2']=tempdata4
                }
              }
              if (this.fieldinputlist[i].fieldtype=="toggle") {
                let tempdata5 = [];
                for (let j = 0; j < this.fieldinputlist[i].fieldvalues.length; j++) {
                  if (this.fieldinputlist[i].fieldvalues[j].optionvalue.length>0) {
                    tempdata5[j] = {}
                    tempdata5[j].id=this.fieldinputlist[i].fieldvalues[j].optionvalue
                    tempdata5[j].label=this.fieldinputlist[i].fieldvalues[j].optionvalue
                  }
                  this.fieldinputlist[i]['optionalvalue3']=tempdata5
                }
              }
              // value optional logic end
            }
            // this.tempheader=temArray

            // header wise assignment start
            for (let l = 0; l < this.tempheader.length; l++) {
              let newobje=[]
              for (let m = 0; m < this.fieldinputlist.length; m++) {
                newobje.push(this.fieldinputlist[m])
                this.tempheader[l]['heads']= JSON.parse(JSON.stringify(newobje));
                for (let n = 0; n < this.tempheader[l]['heads'].length; n++) {
                  this.tempheader[l]['heads'][n]['answervalue']=null;
                }
              }
            }
            // header wise assignment end
          }else{
            this.fieldinputlist=[]
          }
      }).catch(e => {
                    this.displayError(e)
      });
    },
    validText : function(inputval) {
      var re = /[A-Za-z0-9].{4,}/;
      if(inputval){
        return re.test(inputval.toLowerCase());
      }
    },
    validText2 : function(inputval) {
      var re = /[A-Za-z0-9].{1,}/;
      if(inputval){
        return re.test(inputval.toLowerCase());
      }
    },
    savewizard(objThis,isactive,finalsubmission){
      if (this.globaljdid && this.globaljdid!=0) {
        let valid = this.validateForm(objThis);
        if (valid) {
          this.isLoading = true;
          this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            jdid: this.globaljdid,
            fieldname: this.application,
            moduletype: this.application1,
            fieldvalues: this.fieldsnamevalues,
            isactive: isactive,
            finalsubmission: finalsubmission
          }
          if (this.application == 'Resume' && this.application1 == 1) {
            this.input.resumeparsing = this.resumeparsing
          }
          let apiURL='api/hrms/savejobapplicationdata'
          this.isAddState=false
          axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
          })
          .then(result => {    
            this.isLoading = false;         
            if(result.data.errorCode == 0){
              if (finalsubmission == 'insave' || finalsubmission=='indraft') {
                Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: 'success',
                  customClass: {
                    confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
                })
              }
              this.fieldsnamevalues = [];
              // this.getsavedapplicationdetailsbytracker(this.application1,this.application);
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }
            else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
          }).catch(e => {
              this.displayError(e)
            })
        }
      }else if(finalsubmission != null){
        Swal.fire({
          title: "Failed",
          text: 'Job Details Id Is Required',
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
        })
      }
    },
    validateForm(objThis){
      this.isAddState=false
      let isValid=true;
      for (let i=0; i<this.tempheader.length; i++) {
        let temparr = {}

        for (let j = 0; j < this.tempheader[i].heads.length; j++) {

          //Toggle default value assignment start
          if (this.tempheader[i].heads[j].fieldtype == 'toggle') {
            if (this.tempheader[i].heads[j].answervalue == null || this.tempheader[i].heads[j].answervalue == '') {
              this.tempheader[i].heads[j].answervalue = false
            }else if(this.tempheader[i].heads[j].answervalue == 'Yes' || (this.tempheader[i].heads[j].answervalue == true || this.tempheader[i].heads[j].answervalue == 'true')){
              this.tempheader[i].heads[j].answervalue = true
            }else if(this.tempheader[i].heads[j].answervalue == 'No' || (this.tempheader[i].heads[j].answervalue == false || this.tempheader[i].heads[j].answervalue == 'false') ){
              this.tempheader[i].heads[j].answervalue = false
            }
          }
          //Toggle default value assignment end

          //Timepicker HH:mm value assignment start
          if (this.tempheader[i].heads[j].fieldtype == 'datetime-local') {
            
            if (this.tempheader[i].heads[j].validationtype == 'required' && (this.tempheader[i].heads[j].answervalue == null || this.tempheader[i].heads[j].answervalue == '')) {
              isValid=false;
              Swal.fire({
                title: "Failed",
                text: this.tempheader[i].heads[j].fieldname+' '+'is required !',
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }else{
              let hours = this.tempheader[i].heads[j].answervalue
              let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
              if(this.tempheader[i].heads[j].answervalue !=null){
                if (this.tempheader[i].heads[j].answervalue.HH=='' || this.tempheader[i].heads[j].answervalue.mm=='') {
                  isValid=false;
                  Swal.fire({
                    title: "Failed",
                    text: this.tempheader[i].heads[j].fieldname+' '+'required in HH:mm format value',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                  })
                }
                if (this.tempheader[i].heads[j].answervalue[1]=='H' || this.tempheader[i].heads[j].answervalue[4]=='m') {
                  isValid=false;
                  Swal.fire({
                    title: "Failed",
                    text: this.tempheader[i].heads[j].fieldname+' '+'required in HH:mm format value',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                  })
                }
                if(typeof hours === 'string' && hours.length === 5){
                  isValidHours = true
                }
                if(isValidHours && hours !== null && typeof hours === 'object') {
                  this.tempheader[i].heads[j].answervalue = `${hours.HH}:${hours.mm}`
                }
              }
            }
          }
          //Timepicker HH:mm value assignment end
          
          //all fields required validation check start
          if (this.tempheader[i].heads[j].validationtype == 'required') {
            if (this.tempheader[i].heads[j].answervalue == null || this.tempheader[i].heads[j].answervalue == '') {
              isValid=false;
              Swal.fire({
                title: "Failed",
                text: this.tempheader[i].heads[j].fieldname+' '+'is required !',
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
          }
          //all fields required validation check end

          //this.fieldsnamevalues db assignment start
          this.fieldsnamevalues[i] = {}
          this.fieldsnamevalues[i]['id'] = this.tempheader[i].id
          this.fieldsnamevalues[i]['label'] = this.tempheader[i].label
          this.fieldsnamevalues[i]['values'] = []
          temparr[this.tempheader[i].heads[j].modulefieldid]=this.tempheader[i].heads[j].answervalue
          this.fieldsnamevalues[i].values.push(temparr)
          //this.fieldsnamevalues db assignment end
        }
      }
      return isValid
    },
    getsavedapplicationdetailsbytracker(applicationid,application){
      this.isLoading = true;
      this.inputfield= {
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
        jdid: this.globaljdid,
        moduletype: applicationid,
        fieldname: application,
      }
      axios({
        'method': 'POST',
        'url': 'api/hrms/getsavedapplicationdetailsbytracker',
        'data': this.inputfield,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false;
        if(result.data.status == true){
          this.saveddata = result.data.data.rows[0]
          this.resumeparsing = this.saveddata.resumeparsing
          this.fieldsnamevalues2=this.saveddata.configdetails

          for (let x = 0; x < this.fieldsnamevalues2.length; x++) {
            const findIndex = this.tempheader.find(ele =>{
              return ele.id == this.fieldsnamevalues2[x].id
            })
            if (findIndex) {
              findIndex.heads.forEach((head) => {
                head.answervalue = this.fieldsnamevalues2[x].values[0][head.modulefieldid]
              })
            }
          }

        }else{
          this.saveddata=[]
        }
      }).catch(e => {
                    this.displayError(e)
      });
    },
    myconversion(x) {
      x = x.split(" ").join("")
      return x
    },
    nextpage() {
      $('.btn-next3').click();
    },
    prev10(){
      $('.btn-prev10').click();
    }
  }
};
</script>
<style scoped>
.radiobutn_jobapplications span{
  display: block;
    text-align: left;
    margin-right: 0px;
}
.hrms_new_designtabs .all_table_stickys tr:nth-of-type(odd) td.first-col-sticky, .hrms_new_designtabs .all_table_stickys th.first-col-sticky{
  z-index: 9;
}
.hrms_new_designtabs .all_table_stickys td.first-col-sticky{
 z-index: 9;
}
.nav-tabs.hrms_ul_job_detals .nav-link.active {
    color: #4F46E5 !important;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.3px;
}

</style>



