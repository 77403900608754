<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-sm-9 col-md-9 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Task Time Log Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <div class="">
                        <label for="allotedto">Employee Name</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4Chars To Search Employee Name" v-model="search.allotedto" :multiple="true" :clear-on-select="true" :options="search.allotedtolist" v-on:search-change="getEmpListByProjectAssigned"/>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Project Name" v-model="search.searchProjectname" :multiple="true" :clear-on-select="true" :options="search.projectnameList"/>
                    </div>
                    <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                      <div class="date_picker_foundeds">
                        <label for="">Date From</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select Date From" v-model="search.datefrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                          <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                          </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-md-6 col-lg-3 mb-0">
                      <div class="date_picker_foundeds">
                        <label for="">Date To</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select Date To" v-model="search.dateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 text-right mb-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 ml-1"  :disabled="isBothDateSelected" @click="getTaskLogReport(true,search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2 ml-1"  :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                        Task Time Log Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home"  role="tabpanel"  aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky">
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Task Time Log Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno first-col-sticky align-middle" style="min-width:45px;">Sr#</th>
                                      <th scope="col" class="align-middle text-left second-col-sticky" style="min-width:180px;">Employee ID</th>
                                      <th scope="col" class="align-middle">Employee Name</th>
                                      <th scope="col" class="align-middle">Team</th>
                                      <th scope="col" class="align-middle">Project</th>
                                      <th scope="col" class="align-middle">Region</th>
                                      <th scope="col" class="align-middle">Engineering Manager</th>
                                      <th scope="col" class="align-middle">Project Manager</th>
                                      <th scope="col" class="align-middle">Total Hours</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in reportList">
                                      <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                        <td :class="'capitalisetext font-weight-normal text-left second-col-sticky unique'+index+1+currPageNum*pagelimit">
                                          {{data.empid}}
                                        </td>
                                        <td :class="'capitalisetext font-weight-normal unique'+index+1+currPageNum*pagelimit">
                                          {{data.name}}
                                        </td>
                                        <td :class="'capitalisetext font-weight-normal unique'+index+1+currPageNum*pagelimit">
                                            -
                                        </td>
                                        <td :class="'capitalisetext font-weight-normal cursorpointercls sitebluecolor unique'+index+1+currPageNum*pagelimit" @click="redirecttourl('projectId',data)">
                                          {{data.projectname}}
                                        </td>
                                        <td :class="'capitalisetext font-weight-normal unique'+index+1+currPageNum*pagelimit">
                                          <span v-if="data.region">{{data.region[0]}}</span>
                                          <span v-else>-</span>
                                        </td>
                                        <td style="text-align:left;" :class="'capitalisetext font-weight-normal unique'+index+1+currPageNum*pagelimit">
                                          <ol>
                                              <li v-for="(emdata, emindex) in data.em">{{emdata}}</li>
                                          </ol>
                                        </td>
                                        <td style="text-align:left;" :class="'capitalisetext font-weight-normal unique'+index+1+currPageNum*pagelimit">
                                          <ol>
                                              <li v-for="(pmdata, pmindex) in data.pm">{{pmdata}}</li>
                                          </ol>                                    
                                        </td>
                                        <td :class="'capitalisetext font-weight-normal unique'+index+1+currPageNum*pagelimit">
                                          <span v-if="data.spenttime">
                                          {{data.spenttime.hours ? data.spenttime.hours:'00'}}.{{data.spenttime.minutes ? data.spenttime.minutes:'00'}} hrs
                                          </span>
                                          <span v-else>-</span>
                                        </td>
                                          
                                      </tr>
                                    </template>
                                    <template v-if="reportList.length == 0 && isresponce">
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2 text-center">
                      <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon,UserIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "TaskTimeLogReport",
  mixins: [ commonMethods ],

  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker,
    UserIcon
  },
  data() {
    return {
      isBothDateSelected:false,
      projectnameList:[],
      isLoading: false,
      fullPage: true,
      reportList: [],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
     
      companylist: [],
      showproject: false,
      showcompanies: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      search:{
          projectnameList: [],
          searchProjectname :null,
          searchTaskStatus:null,
          allotedto:null,
          allotedtolist:[],
          dateto:null,
          datefrom:null,
          mngrname:null
          
      },
      tokendata:null,
      mngrlist:[],
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.getTaskLogReport()
        this.getProjList()
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    notBeforeToday: function (date) {
      this.search.dateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.datefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectedstartdate(){
      if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
        this.isBothDateSelected = false
      }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
        this.isBothDateSelected = true
      }
    },
    selectedenddate(){
       if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
        this.isBothDateSelected = false
      }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
        this.isBothDateSelected = true
      }
    },
    getProjList: function(){            
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          'method': 'POST',
          'url': url,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {             
          if (result.data.errorCode == 0) {
            this.search.projectnameList = result.data.data;
          } else {
            this.search.projectnameList = [];
          }                  
        }).catch(e => {
        this.displayError(e)
      })
    },
    getEmpListByProjectAssigned(node) {
      if (node && node.length >=3) {
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.allotedtolist = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    getTaskLogReport(flag = false,...search){
      if(flag) {
        this.pageoffset = 1
        this.reportList = []
        this.totalcount = 0;
        this.pageCount = 0;
        this.page= 1;
      } 
      this.isLoading = true;
      let url = "api/btcreport/btcusertimelogdetails";
      this.input = {
          offset: this.pageoffset,
          limit: apiUrl.LIMIT,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
      };
      let assignedate = ""
      if(this.search.dateto){
        assignedate = new Date(this.search.dateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.allotedto = this.search.allotedto ? this.search.allotedto : null
      this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
      this.input.datefrom = this.search.datefrom?this.search.datefrom:null
      this.input.dateto = this.search.dateto?assignedate:null
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          let list = result.data.data.rows;
          list.forEach(function(element, index) {
            if(element.em){
              list[index].em =  element.em.split(',');
            }
            if(element.pm){
              list[index].pm =  element.pm.split(',');
            }
            if(element.projectname){
              if(element.projectname.indexOf("-") > 0){
                list[index].region =  element.projectname.split('-');
              }
            }       
          });
          this.reportList = list;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.reportList = [];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
    this.search.searchProjectname = null;
    this.search.searchTaskStatus = null;
    this.search.allotedto = null;
    this.search.dateto = null;
    this.search.datefrom = null;
    this.search.mngrname=null;
    this.isBothDateSelected = false;
    this.search.allotedtolist =[];
    let rowdata={}
    this.getTaskLogReport(rowdata);
    localStorage.removeItem('searchItems')
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getTaskLogReport(false,this.parentmoduledata);

      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
          offset: null,
          limit: null,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
      };
      let assignedate = ""
      if(this.search.dateto){
        assignedate = new Date(this.search.dateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.allotedto = this.search.allotedto ? this.search.allotedto : null
      this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
      this.input.datefrom = this.search.datefrom?this.search.datefrom:null
      this.input.dateto = this.search.dateto?assignedate:null
      axios({
        method: "POST",
        url: "api/btcreport/btcusertimelogdetails",
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          let list = result.data.data.rows;
          list.forEach(function(element, index) {
            if(element.em){
              list[index].em =  element.em.split(',');
            }
            if(element.pm){
              list[index].pm =  element.pm.split(',');
            }
            if(element.projectname){
              if(element.projectname.indexOf("-") > 0){
                list[index].region =  element.projectname.split('-');
              }
            }       
          });
          this.exportxlsdata = list;
          var filename = ""
          this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        if (result.data.status) {
          this.isDetActive = true;
          let list = result.data.data.rows;
          list.forEach(function(element, index) {
            if(element.em){
              list[index].em =  element.em.split(',');
            }
            if(element.pm){
              list[index].pm =  element.pm.split(',');
            }
            if(element.projectname){
              if(element.projectname.indexOf("-") > 0){
                list[index].region =  element.projectname.split('-');
              }
            }       
          });
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'EmpTaskLogTimeReport'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#,EMPLOYEE ID,EMPLOYEE NAME,TEAM,PROJECT,REGION,ENGINEERING MANAGER,PROJECT MANAGER,TOTAL HOURS' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let em = index.hasOwnProperty('em') ? index.em == null || index.em == 'NA' ? '' : index.em : '';
            let empid = index.hasOwnProperty('empid') ? index.empid == null || index.empid == 'NA' ? '' : index.empid : '';
            let name = index.hasOwnProperty('name') ? index.name == null || index.name == 'NA' ? '' : index.name : '';
            let pm = index.hasOwnProperty('pm') ? index.pm == null || index.pm == 'NA' ? '' : index.pm : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname == null || index.projectname == 'NA' ? '' : index.projectname : '';
            let spenttime = index.hasOwnProperty('spenttime') ? index.spenttime == null || index.spenttime == 'NA' ? '' : index.spenttime : '';
            let region = index.hasOwnProperty('region') ? index.region == null || index.region == 'NA' ? '' : index.region : '';
            
            row += '"' + srno + '",',
            row += '"' + empid + '",',
            row += '"' + name + '",',
            row += '" NA ",',
            row += '"' + projectname + '",',
            row += '"'+ `${region ? region[0] :'NA'}` +'",',
            row += '"' + `${em ? em :'NA'}` + '",',
            row += '"' + `${pm ? pm :'NA'}` + '",',
            row += '"' + `${spenttime.hours ? spenttime.hours :'00'}.${spenttime.minutes ? spenttime.minutes:'00'}`   + '",',
            row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            return;
            }
            var fileName = 'EmpTaskLogTimeReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });   
        }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (btnstate == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid
        })
      }
    }
  },
};
</script>
