<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5 px-0">
                <div class="row breadcrumbs-top">
                    <div class="col-sm-8">
                        <h3 class="content-header-title float-left mb-0">Log Activity</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to='/profile'>Profile</router-link></li>
                            </ol>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        <section id="card-navigation">
            
            
                        <!-- Calendar Start -->
             <div  style="margin-top: 1rem; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: iscalendarDetailsActives},card_content,collapse]">
                        <div class="container px-0 ">
                           <div class="card col-sm-12 px-0">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                        <div class="card-body app_calendar_view app_calendar_view_time_log pb-0 mb-2">
                                            <div id="calendar"></div>
                                        </div>
                                    </div>                                    
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            <!-- Calendar End -->
<!-- New Side POP UP Start -->
                <div class="modal modal-slide-in fade" id="calendarModal" aria-hidden="true">
                    <div class="modal-dialog sidebar-lg">
                        <div class="modal-content p-0">
                            <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#ffff !important" @click="clearData()">×</button>
                            <div class="modal-header mb-1" style="background-color:#1f77b4 !important;">
                                <h5 class="modal-title" style="color:#ffff !important">
                                    <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                                    <span class="align-middle ml-50">Add Leaves/Logtime</span>
                                </h5>
                            </div>
                        <div style="overflow:auto;">
                            <div class="px-1">
                                <div class="demo-inline-spacing custom_radio_creaternote">
                                    <div class="mb-0 py-1 m-0 w-100" style="padding-top: 6px!important;">
                                        <div  v-if="this.timelogRadio == true " class="form-check form-check-inline mt-0 mb-0">
                                            <input class="form-check-input" type="radio" id="timeLogRadio" name="activityRadio"  value="Time Log" @click="timelogActive()" checked >
                                            <label class="form-check-label time_logo-radio-5" for="timeLogRadio">Time Log</label>
                                        </div>
                                        <div v-if="this.timelogRadio == true " class="form-check form-check-inline mt-0">
                                            <input class="form-check-input" type="radio" id="halfDayLeaveRadio" name="activityRadio"  value="halfdayleave" @click="halfLeaveActive()" >
                                            <label class="form-check-label time_logo-radio-5" for="fullDayLeaveRadio">Half Day Leave</label>
                                        </div>
                                        <div v-else-if="this.timelogRadio == false " class="form-check form-check-inline mt-0">
                                            <input class="form-check-input" type="radio" id="halfDayLeaveRadio" name="activityRadio"  value="halfdayleave" @click="halfLeaveActive()" checked>
                                            <label class="form-check-label time_logo-radio-5" for="fullDayLeaveRadio">Half Day Leave</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-0">
                                            <input class="form-check-input" type="radio" id="fullDayLeaveRadio" name="activityRadio"  value="fulldayaleave" @click="fullLeaveActive()">
                                            <label class="form-check-label time_logo-radio-5" for="halfDayLeaveRadio">Full Day Leave</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="devUniqueForm mobile-padding-0-2 px-0" style="overflow:hidden;">
                                <form class="form ">
                                    <div v-if="this.halfDleaves ==false && this.fullDleaves == false" class="form-group mg-bot0 col-lg-12">
                                        <label class="form-label lable-left">Search</label>
                                        <treeselect class="projectmdllistdd" placeholder="Enter min 3 chars to search" v-model="logtime.searchterm" :options="searchTermList" v-on:search-change="searchAll" @input="clearSearchTermList" @blur="clearSearchTermList" @close="validatelog()"/>
                                        <div class="errorinputmsg px-1" v-if="this.logtimeErrors.searchterm">{{ this.logtimeErrors.searchterm }}</div>
                                    </div>
                                    <div class="row mx-0">
                                        <div  class="form-group mg-bot0 col-sm-6">
                                            <label for="appmdldesc">Spent Time</label>
                                            <vue-timepicker class="appmdldesc_input-100" type="time" id="qahours" format="HH:mm"  v-model="logtime.spenttime" :disabled="this.isFieldDisabled" @close="validatelog()"></vue-timepicker>
                                            <div class="errorinputmsg px-1" v-if="this.logtimeErrors.spenttime">{{ this.logtimeErrors.spenttime }}</div>

                                        </div>
                                        

                                        <div  class="form-group mg-bot0 col-sm-6 " >
                                            <label for="appmdldesc">Activity</label>
                                           
                                           
                                           
                                            <treeselect  v-if="this.halfDleaves ==false && this.fullDleaves == false" class="capitalisetext" placeholder="Select activity" v-model="logtime.activity" :options="logtime.timelogacivitylist" :disabled="this.isFieldDisabled" @input="seltasklogactivity"/>

                                            <treeselect  v-else class="capitalisetext" placeholder="Select activity" v-model="logtime.activity" :options="logtime.logacivitylist" :disabled="this.isFieldDisabled" @input="seltasklogactivity"/>
                                            
                                            
                                            <div class="errorinputmsg px-1" v-if="this.logtimeErrors.activity">{{ this.logtimeErrors.activity }}</div>

                                        </div>
                                    </div>

                                    
                                    <div class="col-12">
                                        <label class="form-label">Comment</label>
                                        <ckeditor :editor="editor"  placeholder="Comment" v-model="logtime.comment" @input="seltasklogcomments" :config="editorConfig" tag-name="textarea" ></ckeditor>
                                        <div class="errorinputmsg" v-if="this.logtimeErrors.comment">{{ this.logtimeErrors.comment }}</div>
                                    </div>
                                    <div class="col-md-12" style="margin-top:15px">
                                        <div class="form-group">
                                        <div class="form-group">
                                            <label for="attachment" class="btn btn-sm btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                                            <div class="table-responsive mb-1" v-if="file.length>0">
                                                <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">SR#</th>
                                                            <th scope="col">Document Name</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(image, key) in file" :key="key">
                                                            <td>{{ key+1 }}</td>
                                                            <td>{{ image.name }}</td>
                                                            <td>
                                                                <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                                    <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <button class="btn btn-sm btn-relief-secondary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                                            <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <div class="form-group d-flex flex-wrap mb-0" >
                                            <button   type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal"  v-on:click.stop.prevent="savelogData()">Submit</button>
                                            <button style="margin-right:2px;" type="button" class="btn btn-outline-secondary" @click="clearData()">Clear</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>   
<!-- New Side POP UP END -->
        </section>
      </div>
  </template>
  <style>
  .card .card-header {
    padding: 0.8rem;
  }
  .fc-toolbar-chunk{
    display: none;
  }
  #qahours{
    width: 100%;
  }
  .card-body.cropside{
    position: relative;
  }
  .canvasjs_bgviews{
    background: #f8f8f8;
  }
  .title {
    text-align: center;
  }
  input.mx-input::-webkit-input-placeholder {
    color: #b9b9c3;
    font-size: 14px;
  }
  input:-moz-placeholder {
    color: #b9b9c3;
    font-size: 14px;
  }
  input.mx-input {
    color: #000 !important;
  }
  .cropside{
    overflow: hidden;
  }
  
  .canvasoverflowscrool{
    width: 100%;
    display: block;
    /*overflow-x: auto;*/
  }
  .chart_heading_my_activity{
    font-weight: 600;
  }
  .chart_input_my_activity{
    display: none!important;
    visibility: visible;
  }
  .custom_radio_creaternote .form-check-label.time_logo-radio-5{
    margin-left: 1px!important;
    margin-right: 10px!important;
  }
  </style>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import Treeselect from "@riophae/vue-treeselect";
  import { EyeIcon,SearchIcon,ExternalLinkIcon,TrendingUpIcon,UserPlusIcon,Trash2Icon,PlusSquareIcon } from "vue-feather-icons";
  import Pagination from "vue-pagination-2";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import commonMethods from '../../utils/commonMethods';
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  

  
  export default {
    name: "MyActivityReport",
    components: {
      Loading,
      VueElementLoading,
      EyeIcon,
      Treeselect,
      SearchIcon,
      UserPlusIcon,
      ckeditor: CKEditor.component,
      ExternalLinkIcon,PlusSquareIcon,
      Pagination,ClassicEditor,Trash2Icon,
      DatePicker,VueTimepicker,TrendingUpIcon,CKEditor
    },
    mixins: [ commonMethods ],
    data() {
      return {
         
        logtime:{
            logacivitylist:apiUrl.logacivitylist,
            timelogacivitylist: apiUrl.logacivitylist.filter(obj => obj.id !== 'Half Day Leave' && obj.id !== 'Full Day Leave'),
            spenttime:"",
            activity:null,
            allotedto:null,
            status:null,
            comment:null,
            attachmentview:[],
            searchterm:null
        },
        logtimeErrors: {
            allotedto: null,
            spenttime: "",
            activity: null,
            comment:null,
            searchterm:''
        },
        statusopts: [{
            id:'open',
            label:'Open'
        },{
            id:'resolved',
            label:'Resolved'
        },{
            id:'closed',
            label:'Closed'
        },{
            id:'reopen',
            label:'Re-open'
        }],
        employeeListOpts:[],
        isLoading: false,
        fullPage: true,
        glbMdl: "",
        glbst: "",
        TimeFormat: apiUrl.TimeFormat,
        totalcount: 0,
        pageCount: 0,
        totalspenttime:0,
        todayspenttime:0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures: [],
        showtabledata: true,
        tokendata: null,
        errors: {
            allocatedto: "",
            subscribers: "",
            status: "",
            datetime: "",
            totaldowntime: "",
            imgm: {
            task: null,
            tasklist: [],
            },
        },
        editor: ClassicEditor,
        editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
            "|",
          ],
        },
      },
      file: [],
      accepttype:apiUrl.uploadfiletype,
      disblesavebtn: false,
      calendarEvents : [],
      iscalendarDetailsActives:false,
      card_content:'card-content',
       collapse:'collapse',
        searchTermList:[],
       caldate:null,
       fd:'',
       selectedlogdate : null,
       halfDleaves: '',
       fullDleaves: '',
       timelogactive:'',
       selDate:null,
       todaysdate:null,
       timelogRadio:true,
       isFieldDisabled:false
      };
    },
    mounted() {
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;     
        this.calendar = this.initializeCalendar();
        this.calendarShowView()
        this.timelogActive()
        }
    },
    watch: {
        calendarEvents: {
        handler() {
            this.calendar.removeAllEvents();
            this.calendar.addEventSource(this.calendarEvents);
        },
        deep: true
        }
    },
    methods: {
        clearSearchTermList(state) {
            if(!state) {
                this.searchTermList = []
            }
        },
        timelogActive(){
            this.timelogactive = true
            this.logtime.spenttime = ''
            this.logtime.spenttime= {'HH':'', 'mm':''},
            this.logtime.activity = null
            this.halfDleaves =false
            this.fullDleaves =false
            this.isFieldDisabled= false
        },
        halfLeaveActive(){
            this.halfDleaves =true
            this.logtime.spenttime= {'HH':'04', 'mm':'00'},
            this.logtime.activity ='Half Day Leave',
            this.isFieldDisabled= true
        },
        fullLeaveActive(){
            this.fullDleaves =true
            this.logtime.spenttime= {'HH':'08', 'mm':'00'},
            this.logtime.activity ='Full Day Leave',
            this.isFieldDisabled= true
        },
        searchAll(node) {
            if(node && node.length>2) {
                axios({
                    'method': 'POST',
                    'url': 'api/teamactivity/gettaskbtcissueincidentmasterlist',
                    'data': {
                        searchcode: node,
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                        empid: parseInt(this.undt.userid),
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        this.searchTermList=result.data.data.rows
                    } else {
                        this.searchTermList= []
                    }
                })
            }
    
        },
        dateMONFormat(value){
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },

        calendarShowView(){
            this.iscalendarDetailsActives=true;
            localStorage.setItem('activebtctab', 'chartview');
            this.getLogCountOnCalendar()
            this.initializeCalendar();
        },
        clearData(){
            if(this.halfDleaves ==true){
                this.logtime.spenttime= {'HH':'04', 'mm':'00'}
                this.logtime.comment = ''
                this.file = []
            }
            else if(this.fullDleaves ==true){
                this.logtime.spenttime= {'HH':'08', 'mm':'00'}
                this.logtime.comment = ''
                this.file = []
            }
            else if(this.timelogactive == true){
                this.logtime.searchterm = null,
                this.logtime.spenttime = { "HH": "", "mm": "" }
                this.logtime.activity = null,
                this.logtime.allotedto = null,
                this.logtime.status = null,
                this.logtime.comment = '',
                this.file = [],
                this.halfDleaves = false,
                this.fullDleaves = false

            }
        },

    getLogCountOnCalendar(startdate,enddate){
        this.input = {
            empcode: this.undt.userid,
        }
        if(startdate != undefined && enddate != undefined){
            this.input.startdate =  startdate
            this.input.enddate =  enddate
        }else{
            let gdate = new Date();
            let firstDay = new Date(gdate.getFullYear(), gdate.getMonth(), 1);
            let lastDay = new Date(gdate.getFullYear(), gdate.getMonth() + 1, 0);        
            this.input.startdate =  moment(String(firstDay)).format('YYYY-MM-DD');
            this.input.enddate =  moment(String(lastDay)).format('YYYY-MM-DD');
        }
        let url = 'api/teamactivity/getLogCountonCalendar'
        this.input.useremail=this.undt.username
        this.input.empid=this.undt.userid
        axios({
        'method': 'POST',
        'url': url,
        'data': this.input,
        'headers':{'authorization':this.tokendata},
        })
        .then(result => {  
            this.iscalendlistLoading=false;    
            if(result.data.errorCode == 0){   
                    this.isresponce=true
                    this.calendarEvents=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    this.calendarEvents.push(result.data.data.rows);
            } else if(result.data.errorCode == 3){
                Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            }else{
                this.calendarEvents = [];
                this.totalcount = 0;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);

            }
        })

    }, 

        initializeCalendar() {
            const calendar = new FullCalendar.Calendar(document.getElementById('calendar'), {
                events: this.calendarEvents,
                headerToolbar: {
                    start: 'sidebarToggle, prev,next, title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                },
                datesSet: this.handleDatesSet,
                dateClick:this.Savecalendarlog,
            });
            //console.log()
            calendar.render();
            let td = new Date()
            this.todaysdate=moment(String(td)).format('YYYY-MM-DD HH:mm:ss')
            return calendar;
        },
        getWeekNumber(date) {
            let firstDayOfWeek = new Date(date.getFullYear(), 0, 1);
            let dayOffset = (firstDayOfWeek.getDay() + 6) % 7; // Offset to Monday (0 - Sunday, 1 - Monday, ..., 6 - Saturday)
            firstDayOfWeek.setDate(firstDayOfWeek.getDate() - dayOffset);
             let millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
             let millisecondsSinceFirstWeekStart = date - firstDayOfWeek;
             let fullWeeks = Math.floor(millisecondsSinceFirstWeekStart / millisecondsPerWeek);
             let remainingDays = (date.getDay() + 6) % 7; 
                if (remainingDays >= 0) {
                    fullWeeks++;
                }
            return fullWeeks;
        },
        handleDatesSet(info) {
        let calendarApi = info.view.calendar;
        let vstart = calendarApi.view.activeStart;
        let end = calendarApi.view.activeEnd;
        let startdate = moment(String(vstart)).format('YYYY-MM-DD');
        let enddate = moment(String(end)).format('YYYY-MM-DD');
        this.updateCalendar(startdate, enddate);
    },
    updateCalendar(startdate, enddate) {
     try{
        this.getLogCountOnCalendar(startdate,enddate);
     }catch(error){
     }
    },
        Savecalendarlog(info){
        let dt= moment(String(info.date)).format('YYYY-MM-DD HH:mm:ss');
         this.selDate=dt
        let currentDate = info.date;
        let calendardt = new Date(info.date);
        let currentWeek = this.getWeekNumber(new Date());
        let ocurrentDate = new Date();
        let previousWeekDate = new Date(ocurrentDate.getTime() - (7 * 24 * 60 * 60 * 1000)); 
        let previousWeekNumber = this.getWeekNumber(previousWeekDate); 
  
             if(this.yearmonthcheck(calendardt)){

                    if(previousWeekNumber == this.getWeekNumber(currentDate)){

                    let datelog = moment(String(info.date)).format('YYYY-MM-DD HH:mm:ss');
                    $('#calendarModal').modal()
                    this.selectedlogdate = calendardt
                    this.timelogRadio=false
                    this.halfDleaves =true
                    this.halfLeaveActive()
                    }

                    else if (currentWeek == this.getWeekNumber(currentDate) && calendardt.getTime() <= ocurrentDate.getTime()) {

                    let datelog = moment(String(info.date)).format('YYYY-MM-DD HH:mm:ss');
                    $('#calendarModal').modal()
                    this.selectedlogdate = calendardt
                    this.timelogRadio=true
                    this.timelogActive()
                    }
                    else if (this.getWeekNumber(currentDate) > currentWeek && this.getWeekNumber(currentDate) > previousWeekNumber) {
                    let datelog = moment(String(info.date)).format('YYYY-MM-DD HH:mm:ss');
                    $('#calendarModal').modal()
                    this.selectedlogdate = calendardt
                    this.timelogRadio=false
                    this.halfDleaves =true
                    this.halfLeaveActive()
                    }
                    else if (currentWeek == this.getWeekNumber(currentDate) && calendardt.getTime() > ocurrentDate.getTime()) {

                    $('#calendarModal').modal()
                    this.selectedlogdate = calendardt
                    this.timelogRadio=false
                    this.halfDleaves =true
                    this.halfLeaveActive()

                    }
            
                }else{
                    if(previousWeekNumber == this.getWeekNumber(currentDate)){
                        $('#calendarModal').modal()
                        this.selectedlogdate = calendardt
                        this.timelogRadio=false
                        this.halfDleaves =true
                        this.halfLeaveActive()

                    }
                    if(this.getWeekNumber(ocurrentDate) == this.getWeekNumber(currentDate)){
                    let datelog = moment(String(info.date)).format('YYYY-MM-DD HH:mm:ss');
                    $('#calendarModal').modal()
                    this.selectedlogdate = calendardt
                    this.timelogRadio=true
                    this.timelogActive()
                    }
                } 

        },
        yearmonthcheck(givenDate){
            let currentDate = new Date();
        if(  givenDate.getFullYear() > currentDate.getFullYear() ||
        (givenDate.getFullYear() === currentDate.getFullYear() && givenDate.getMonth() >= currentDate.getMonth())){
        return true
        }       
        },

        eventMouseEnter(info) {
            const eventElement = info.el;
            let name = 'Name: '+info.event.extendedProps.customParam2+'\n'
            name +=  info.event.title+'\n'
            name += 'Due Date:' + info.event.start
            eventElement.title = name;
            eventElement.classList.add('fc-tooltip');
        },
        eventMouseLeave(info) {
            const eventElement = info.el;
            eventElement.title = '';
            eventElement.classList.remove('fc-tooltip');
        },
        validatelog: function(){
            var isValid=true;
            if(this.logtime.searchterm == null && this.logtime.activity != 'Half Day Leave' && this.logtime.activity != 'Full Day Leave' ){
                this.logtimeErrors.searchterm ="Please select any Task/BTC/Issue/Testcase";
                isValid= false;
            }
            else{
                this.logtimeErrors.searchterm='';
            }

            if(!this.logtime.spenttime["HH"] && typeof(this.logtime.spenttime)!='string'){
            this.logtimeErrors.spenttime="Spent-time required";
            isValid= false;
            }
            if(!this.logtime.spenttime["mm"] && typeof(this.logtime.spenttime)!='string'){
            this.logtimeErrors.spenttime="Spent-time required";
            isValid= false;
            }

            if(this.logtime.spenttime["HH"] && this.logtime.spenttime["mm"] && typeof(this.logtime.spenttime)!='string'){
                this.logtimeErrors.spenttime = ''
            }

            //let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm
            // if(!spenttime && typeof(this.logtime.spenttime)=='string')
            // {
            // let logstime = (this.logtime.spenttime).split(':')
            // if(logstime){
            // if(logstime[0] && logstime[1]){
            // spenttime = `${logstime[0]}:${logstime[1]}`
            // this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
            // }
            // }
            // }
            // if(spenttime && typeof(this.logtime.spenttime)!='string'){
            // this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
            // }

            // if(this.logtime.activity && spenttime==''){
            // this.logtimeErrors.spenttime="Spent-time Hours & minutes is required";
            // isValid= false;
            // }else{
            // this.logtimeErrors.spenttime= '';
            // }
            if(!this.logtime.activity){
            this.logtimeErrors.activity="Activity is required";
            isValid= false;
            }else{
            this.logtimeErrors.activity=null;
            }
            if(!this.logtime.comment){
                this.logtimeErrors.comment="Comment is required";
                isValid= false;
            }else{
                this.logtimeErrors.comment=null;
            }
            return isValid
        },
        sellogstatus: function(state,value){
            this.logtime.status=null
            this.logtime.status=state
            if(!state){
                this.errors.status='logtime status required'
            }else{                
                this.errors.status=null
            }
        },
        seltasklogactivity(state, value) {
            this.logtime.activity = null
            this.logtime.activity = state
            if(!state) {
            } else {
                this.logtimeErrors.activity = null
            }
        },
        selallotedto: function(state,value){
            this.allocatedto=null
            this.allocatedto=state
            if(!state){
                this.errors.allocatedto='Alloted To required'
            }else{ 
                this.errors.allocatedto=null
            }
        },
        seltasklogcomments(state, value) {
            this.logtime.comment = null
            this.logtime.comment = state
            if(!state) {
                this.logtimeErrors.comment = "Comment is required"
            } else {
                this.logtimeErrors.comment = null
            }
        },

        savelogData(){
        let valid = this.validatelog();
        if(valid){
 
            if(this.logtime.spenttime["HH"] && this.logtime.spenttime["mm"]){
                this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
            }

            let searchdetails = this.searchTermList.filter(osearch => osearch.id ==this.logtime.searchterm)
            this.input={
                projectname: searchdetails.length > 0 ? searchdetails[0].projectname : null ,
                projectid:searchdetails.length > 0 ? searchdetails[0].projectid :  null,
                modulename: searchdetails.length > 0 ?searchdetails[0].modulename : null ,
                module_code: searchdetails.length > 0 ? searchdetails[0].modulecode : null,
                business_code: searchdetails.length > 0 ? searchdetails[0].business_code : null,
                moduletype:searchdetails.length > 0 ? searchdetails[0].moduletype : 'leave',
                moduleid: searchdetails.length > 0 ? searchdetails[0].moduleid :1,
                moduleidstr: searchdetails.length > 0 ? searchdetails[0].projectid :  null,
                assignedto: searchdetails.length > 0 ? searchdetails[0].allotedemp :  null,
                status: searchdetails.length > 0 ? searchdetails[0].modulestatus :  null,
                createdby:this.undt.username,
                attachment:JSON.stringify(this.attachmentnew),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                logdate: this.selectedlogdate
            }
            this.input.activity = this.logtime.activity
            if(this.logtime.activity=='Half Day Leave' || this.logtime.activity == 'Full Day Leave'){
                this.input.activity = 'leave'
            }
            if(typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
                this.input.spenttime = this.logtime.spenttime
            }else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
                this.input.spenttime = this.logtime.spenttime
            }
            if(this.logtime.comment) {
                this.input.coments = this.logtime.comment
            }
            if(this.attachmentnew) {
                let jsonobj1 = JSON.parse(this.attachmentnew);
                let arraymerge = [...jsonobj1.data]
                this.input.attachment= JSON.stringify({data: arraymerge});
            }
            let oapiURL =  'api/issue/saveLogTime'
            if(searchdetails.length > 0 && searchdetails[0].moduletype == 'businesstask'){
                oapiURL = 'api/business/saveBTMLogTime'
            }
            this.isLoading=true
            axios({
                'method': 'POST',
                'url': oapiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                        this.clearData()
                     $('#calendarModal').modal('hide');
                    Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                    confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1

                    }) 
             } else if(result.data.errorCode == 3){
                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                    window.location.href = "/#/login";
                })
                } else {
                Swal.fire({
                title: "Failed!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
                })
            }
            });
        }
        },
        onFileChange(e) {
            var validFileExtensions = apiUrl.validFileExtensions
            let selectedFiles = e.target.files;
            for (let i=0; i < selectedFiles.length; i++)
            {
                var blnValid = false;
                for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];
                if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
                }
                if (!blnValid) {
                Swal.fire({
                    title: "ERROR",
                    text: "Sorry, Invalid Type of Extension File..!!",
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                return false;
                }
                this.file.push(selectedFiles[i]);
            }
            if(this.file.length>0) {
                this.disblesavebtn=true
            }
        },
        onSelect(e) {
      this.file = e.target.files[0];
    },
    onUpload(){
      if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();  
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
                formData.append("imagefile", this.file[index]);
                maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail",this.undt.username);
            formData.append("moduletype", 'task');
            formData.append("fcount", parseInt(this.file.length));
            
            if (maxsize > 1000 * 1000 * 25) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 25mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.disblesavebtn=true
                return false; 
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading=false; 
                this.disblesavebtn=false
             //   console.log(result.data.status);           
                if(result.data.status){
                      const bindinfo = result.data.data.map((file, index) => {
                        return {"link":file}                
                    });
                    let jsonobj = {data:bindinfo}
                    this.attachmentnew = JSON.stringify(jsonobj);
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.attachment=null
                }
            })
        }else{
              this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Select image/docs/videos',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.attachment=null
        }
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.attachment=null
      }
    },
    removeImage (index) {
      if(this.file.length>0) {
          this.file.splice(index, 1);
          var removeimg = JSON.parse(this.attachmentnew)
          if(removeimg){
              var remove = removeimg.data.splice(index,1)
              if(remove.length > 0)
              this.attachmentnew = JSON.stringify({data:removeimg.data})
              else {
              this.attachmentnew = null
            }
          }
      }   
      if(this.file.length < 1) {
          this.disblesavebtn = false
      }      

    },

    },
  };
  </script> 

  <style>
 .fc-day[data-has-events="false"] {
  background-color: red; /* Set the desired color here */
  color: white; /* Set the desired text color here */
}

</style>