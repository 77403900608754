<template>
    <div class="app-content content ">
        <loading  :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-9 px-0">
                        <h4 class="content-header-title float-left mb-0">Performance Appraisal</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Appraisal List</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Search Appraisal Form</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                    <div class="card-body pt-1">
                        <div class="row">

                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Appraisal Cycle</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Appraisal Cycle" :clear-on-select="true" :multiple="true" v-model="search.appraisalcyclename" :options="search.appraisalcyclenamelist"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label for="form-label lable-left">Department Name</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Department Name" :options = "search.deparmentlist" v-model="search.department" v-if="search.deparmentlist.length > 1"/>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Department Name" :options = "search.deparmentlist" v-model="search.department" disabled v-else/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Form Name</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Form Name" :clear-on-select="true" :multiple="true" v-model="search.formname" :options="search.trackerlist"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Employee Name/ID</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 4char Of Employee Name/ID" :clear-on-select="true" :multiple="true" v-model="search.empname" :options="search.employeenamelist" v-on:search-change="getallemplist($event,0)"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Reviewers Name/ID</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 4char Of Reviewers Name/ID" :clear-on-select="true" :multiple="true" v-model="search.reviewersname" :options="search.reviewersnamelist" v-on:search-change="getallemplist($event,1)"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                            <label class="form-label lable-left">Status</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" :clear-on-select="true" :multiple="true" v-model="search.status" :options="statuslist"/>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 text-right mb-1">
                                <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getappraisalformlist(search,1)"> 
                                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                                </button>
                                <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                                </button>
                                <button type="move" class="btn btn-relief-primary mt-2 " @click="ExportXL()">
                                    <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="card-navigation">
                <div class="file-manager-content-body">
                    <div class="drives">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab-content" id="apprisalformtable">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="proModuleList">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="table-responsive">
                                                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="align-middle srno py-1 first-col-sticky" style="width:45px">Sr#</th>
                                                                    <th scope="col" class="align-middle second-col-sticky" style="width: 180px;">Appraisal Cycle</th>
                                                                    <th scope="col" class="align-middle  text-center" style="width: 120px;">Department Name</th>
                                                                    <th scope="col" class="align-middle " style="width: 120px;">Employee ID</th>
                                                                    <th scope="col" class="align-middle " style="min-width: 180px;">Form Name </th>
                                                                    <th scope="col" class="align-middle " style="width: 160px;">Reviewers Name</th>
                                                                    <th scope="col" class="align-middle ">Status </th>
                                                                    <th scope="col" class="align-middle">Assigned Date </th>
                                                                    <th scope="col" class="align-middle clsaction">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-for="(data,index) in appraisalformlist" >        
                                                                    <tr v-bind:key="index">
                                                                        <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                        <td class="capitalisetext second-col-sticky">
                                                                            {{ data.appraisalcyclename ? data.appraisalcyclename : 'NA' }}
                                                                        </td>
                                                                        <td class="capitalisetext  text-center">
                                                                            {{  decryptText(data.department) }}
                                                                        </td>
                                                                        <td class="capitalisetext ">
                                                                            {{  data.assigneecode }}
                                                                        </td>
                                                                        <td class=" capitalisetext">
                                                                            <span v-if="data.empid == undt.userid ">
                                                                                <strong>Self-</strong>{{ data.moduletype }}
                                                                            </span>
                                                                            <span v-else>
                                                                                <strong>{{ data.empname }}-</strong>{{ data.moduletype }}
                                                                            </span>
                                                                        </td>
                                                                        <td class="capitalisetext">
                                                                            {{ data.reviewersname }}
                                                                        </td>
                                                                        <td class="capitalisetext">
                                                                            {{ data.status }}
                                                                        </td>
                                                                        <td>
                                                                            {{ dateMonthFormat(data.createddate) }}
                                                                        </td>
                                                                        <td class="clsaction" >
                                                                            <div class="dropdown">
                                                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <a @click="redirecttourl('create',data)" href="javascript:void(0);" class="dropdown-item devEditEle" v-if="data.status=='pending'">
                                                                                    <div class="d-flex align-items-center"> 
                                                                                        <edit-2-icon size="1.5x" class="custom-class mr-50" ></edit-2-icon>
                                                                                        <span>Fill/Update Form</span>
                                                                                    </div>
                                                                                    </a>
                                                                                    <a @click="redirecttourl('view',data)" href="javascript:void(0);" class="dropdown-item devEditEle" v-if="data.status!='pending'">
                                                                                    <div class="d-flex align-items-center"> 
                                                                                        <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                                                        <span>View Form</span>
                                                                                    </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-if="appraisalformlist.length==0">
                                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                    <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,MoreVerticalIcon,EyeIcon,XIcon,ExternalLinkIcon,MonitorIcon } from "vue-feather-icons";
import { SearchIcon } from "vue-feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'ListAppraisalCycle',
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,MoreVerticalIcon,EyeIcon,
        SearchIcon,XIcon,ExternalLinkIcon,MonitorIcon,
        Treeselect
    },
    mixins: [ commonMethods ],
    data(){
        return{
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            appraisalformlist:[],
            isSearchRequestActive:false,
            search:{
                formname: null,
                trackerlist:[],
                empname: null,
                employeenamelist: [],
                status: null,
                reviewersname: null,
                reviewersnamelist: [],
                department: null,
                deparmentlist: [],
                appraisalcyclename: null,
                appraisalcyclenamelist: []
            },
            statuslist:[{
                id:'completed',
                label:'Completed'
            },{
                id:'pending',
                label:'Pending'
            }],
            loginuseremail: '',
            pagefeatures:[],
            trackerdetails: [],
            colors: ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'primary', 'secondary', 'success', 'danger', 'warning', 'info',],
        }
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.loginuseremail = userdata.emailaddress;
                this.undt=userdata
                // localStorage.removeItem('appraisalsearchItems')
                // this.getEmpDepartList();
                // this.gettrackerlist();
                // this.getallappraisalcycle();
                if(this.$route.params.search){
                    let search = JSON.parse(localStorage.getItem('appraisalsearchItems'));
                    if(search){
                        this.getSearchItems(search);
                        this.getallemployeelist(search.empname,0);
                        this.getallemployeelist(search.reviewersname,1);
                    }else{
                        localStorage.removeItem('appraisalsearchItems');
                        this.getEmpDepartList();
                        this.gettrackerlist();
                        this.getallappraisalcycle();
                    }
                }else{
                    localStorage.removeItem('appraisalsearchItems');
                    this.getEmpDepartList();
                    this.gettrackerlist();
                    this.getallappraisalcycle();
                }
            }
        }
        $(".filter_rotation_onlick").click(function(){
           $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        getSearchItems(search) {
            this.search.appraisalcyclenamelist = JSON.parse(localStorage.getItem('appraisalcyclenamelist'));
            this.search.appraisalcyclename = search.appraisalcyclename;
            this.search.deparmentlist = JSON.parse(localStorage.getItem('appraisaldeparmentlist'));
            this.search.department = search.department;
            this.search.trackerlist = JSON.parse(localStorage.getItem('appraisaltrackerlist'));
            this.search.formname = search.formname;
            this.search.employeenamelist = JSON.parse(localStorage.getItem('appraisalemployeenamelist'));
            this.search.empname = search.empname;
            this.search.reviewersnamelist = JSON.parse(localStorage.getItem('appraisalreviewersnamelist'));
            this.search.reviewersname = search.reviewersname;
            this.search.status = search.status;
            this.isSearchRequestActive = true;
            this.getappraisalformlist(this.search);
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive = !this.isSearchRequestActive
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getappraisalformlist();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#apprisalformtable").offset().top -70
                });
            }, 500);
        },
        resetRecords: function() {
            this.search.department = null;
            this.search.formname = null;
            this.search.empname = null;
            this.search.employeenamelist = [];
            this.search.status = null;
            this.search.reviewersname = null;
            this.search.reviewersnamelist = [];
            this.search.appraisalcyclename = null;
            localStorage.removeItem('appraisalsearchItems');
            localStorage.removeItem('appraisaldeparmentlist');
            localStorage.removeItem('appraisaltrackerlist');
            localStorage.removeItem('appraisalemployeenamelist');
            localStorage.removeItem('appraisalreviewersnamelist');
            this.page=1;
            this.getEmpDepartList();
        },
        getappraisalformlist(objThis,flag){
            if(objThis) {
                localStorage.setItem('appraisalsearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            let search = JSON.parse(localStorage.getItem('appraisalsearchItems'))
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                limit: apiUrl.LIMIT,
                type: 'Appraisal Cycle',
            }
            if(flag == 1){
                this.input.offset = 1;
                this.currPageNum = 0;
            }else{
                this.input.offset = this.pageoffset;
            }
            if (search && ((this.search.formname && this.search.formname.length>0) || (this.search.empname && this.search.empname.length>0) || (this.search.status && this.search.status.length>0) || (this.search.reviewersname && this.search.reviewersname.length>0) || (this.search.appraisalcyclename && this.search.appraisalcyclename.length>0))) {
                this.input.formname = this.search.formname;
                this.input.empname = this.search.empname;
                this.input.status = this.search.status;
                this.input.reviewersname = this.search.reviewersname;
                this.input.appraisalcyclename = this.search.appraisalcyclename;
            }
            this.input.department = this.search.department;
            axios({
                'method': 'POST',
                'url': 'api/master/getappraisalformlist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.appraisalformlist = result.data.data.rows
                    this.totalcount = result.data.data.count;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.appraisalformlist=[]
                    this.totalcount = 0;
                    this.pageCount =0;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        gettrackerlist() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'Appraisal Cycle',
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getallforms',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.search.trackerlist = result.data.data.rows;
                    localStorage.setItem('appraisaltrackerlist', JSON.stringify(this.search.trackerlist));
                    this.search.trackerlist .sort((a, b) => {
                     return a.label.localeCompare(b.label);
                    });
                } else {
                    this.search.trackerlist = [];
                    localStorage.removeItem('appraisaltrackerlist');
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate;
            rowdata.moduletypes='Appraisal Cycle'
            if(btnstate == "create"){
                this.$router.push({ 
                name: 'CreateAppraisalCycle',
                path: `/appraisal/create?trackerid=${parseInt(rowdata.trackerid)}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            } else if(btnstate == "view"){
                this.$router.push({
                    name: 'FeedbackViewForm',
                    path: '/form/formview?masterid='+parseInt(rowdata.id),
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        getallemplist(node,isflag){
            if (node && node.length >=3 ) {
                this.isLoading = true;
                this.inputfield= {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empname: node.toLowerCase()
                }
                axios({
                    'method': 'POST',
                    'url': 'api/master/getemployeebyname',
                    'data': this.inputfield,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                    if(result.data.status == true){
                        if (isflag ==0) {
                            this.search.employeenamelist = result.data.data.rows;
                            let decryptdesignationtext = this.search.employeenamelist.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                            localStorage.setItem('appraisalemployeenamelist', JSON.stringify(this.search.employeenamelist));
                        }else if(isflag ==1){
                            this.search.reviewersnamelist = result.data.data.rows;
                            let decryptdesignationtext = this.search.reviewersnamelist.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                            localStorage.setItem('appraisalreviewersnamelist', JSON.stringify(this.search.reviewersnamelist));
                        }
                    }else{
                      this.search.employeenamelist=[];
                      localStorage.removeItem('appraisalemployeenamelist');
                      this.search.reviewersnamelist=[];
                      localStorage.removeItem('appraisalreviewersnamelist');
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        getallemployeelist(empids,isflag){
                this.isLoading = true;
                this.inputfield= {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empids: empids
                }
                axios({
                    'method': 'POST',
                    'url': 'api/master/getemployeebyname',
                    'data': this.inputfield,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                    if(result.data.status == true){
                        if (isflag ==0) {
                            this.search.employeenamelist = result.data.data.rows;
                            let decryptdesignationtext = this.search.employeenamelist.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                        }else if(isflag ==1){
                            this.search.reviewersnamelist = result.data.data.rows;
                            let decryptdesignationtext = this.search.reviewersnamelist.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                        }
                    }else{
                      this.search.employeenamelist=[];
                      this.search.reviewersnamelist=[];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
        },
        getEmpDepartList() {
            this.isLoading = true
            let url = 'api/formconfigure/getEmpDepartList'
            let payload = new Object({
                empid: this.undt.userid,
                useremail  : this.undt.username,
                empcode: this.undt.userid
            })
            axios({
                method: 'POST',
                url,
                data: payload,
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.search.deparmentlist = result.data.data.rows;
                    let decryptdepartmenttext = this.search.deparmentlist.map(items =>{
                        items.label = this.decryptText(items.label);
                        return items;
                    })
                    this.search.deparmentlist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                    localStorage.setItem('appraisaldeparmentlist', JSON.stringify(this.search.deparmentlist));
                    if(result.data && result.data.data){
                        if(result.data.data.orows && result.data.data.orows.length >0){
                            this.search.department = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
                        }
                    }
                    this.getappraisalformlist();
                    console.log(this.search.deparmentlist,'=============this.search.deparmentlist');
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.search.deparmentlist=[];
                    localStorage.removeItem('appraisaldeparmentlist');
                    this.search.department = null;
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getallappraisalcycle() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
            }
            axios({
                'method': 'POST',
                'url': 'api/master/getallappraisalcycle',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.search.appraisalcyclenamelist = result.data.data.rows;
                    localStorage.setItem('appraisalcyclenamelist', JSON.stringify(this.search.appraisalcyclenamelist));
                    this.search.appraisalcyclenamelist .sort((a, b) => {
                    return a.label.localeCompare(b.label);
                    });
                } else {
                    this.search.appraisalcyclenamelist = [];
                    localStorage.removeItem('appraisalcyclenamelist');
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        ExportXL() {
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'Appraisal Cycle',
                limit: null,
                offset: null
            }
            let search = JSON.parse(localStorage.getItem('appraisalsearchItems'))
            if (search && ((this.search.formname && this.search.formname.length>0) || (this.search.empname && this.search.empname.length>0) || (this.search.status && this.search.status.length>0) || (this.search.reviewersname && this.search.reviewersname.length>0) || (this.search.appraisalcyclename && this.search.appraisalcyclename.length>0))) {
                this.input.formname = this.search.formname;
                this.input.empname = this.search.empname;
                this.input.status = this.search.status;
                this.input.reviewersname = this.search.reviewersname;
                this.input.appraisalcyclename = this.search.appraisalcyclename;
            }
            this.input.department = this.search.department;
            axios({
                method: "POST",
                url: `api/master/getappraisalformlist`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                       confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
            var ShowLabel = 'PerformanceAppraisalReport'
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#, EMP NAME,EMP ID,EMP MAILID,DATE OF JOINING,DESIGNATION, DEPARTMENT NAME,REPORTING MANAEER MAIL ID,APPRAISAL CYCLE,FORM NAME, FORM FILLED BY MAIL ID, FORM FILLED BY EMP ID,STATUS,ASSIGNED DATE,REVIEWERS MAIL ID,REVIEW DATE';
                    
                    // if(arrData[0].fieldname != "") {
                    //     CSV += `',${arrData[0].fieldname}'`
                    // }
                    CSV += '\r\n';
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                    let index = arrData[i]
                    let srno = i+1
                    let empname = index.hasOwnProperty('empname') ? index.empname == null || index.empname == 'NA' ? '' : index.empname : '';
                    let assigneecode = index.hasOwnProperty('assigneecode') ? index.assigneecode == null || index.assigneecode == 'NA' ? '' : index.assigneecode : '';
                    let empmailid = index.hasOwnProperty('empmailid') ? index.empmailid == null || index.empmailid == 'NA' ? '' : index.empmailid : '';
                    let empdoj = index.hasOwnProperty('empdoj') ? index.empdoj == null || index.empdoj == 'NA' ? '' : index.empdoj : '';
                    // let designation = index.hasOwnProperty('designation') ? index.designation == null || index.designation == 'NA' ? '' : index.designation : '';
                    let designation = index.designation ? this.decryptText(index.designation) : ''
                    // let department = index.hasOwnProperty('department') ? index.department == null || index.department == 'NA' ? 'NA' : index.department : 'NA';
                    let department = index.department ? this.decryptText(index.department) : ''
                    let supervisormailid = index.hasOwnProperty('supervisormailid') ? index.supervisormailid == null || index.supervisormailid == 'NA' ? '' : index.supervisormailid : '';
                    let appraisalcyclename = index.hasOwnProperty('appraisalcyclename') ? index.appraisalcyclename == null || index.appraisalcyclename == 'NA' ? 'NA' : index.appraisalcyclename : 'NA';
                    let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype == null || index.moduletype == 'NA' ? '' : index.moduletype : '';
                    let reviewersmailid = index.hasOwnProperty('reviewersmailid') ? index.reviewersmailid == null || index.reviewersmailid == 'NA' ? '' : index.reviewersmailid : '';
                    let status = index.hasOwnProperty('status') ? index.status == null || index.status == 'NA' ? '' : index.status : '';
                    let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ? '' : index.createddate : '';
                    let lastmodifiedby = 'NA';
                    let formfilledbyid = 'NA';
                    let lastmodifieddate = 'NA';
                    if(status=='completed') {
                        formfilledbyid = index.hasOwnProperty('formfilledbyid') ? index.formfilledbyid == null || index.formfilledbyid == 'NA' ? '' : index.formfilledbyid : '';
                        lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby == null || index.lastmodifiedby == 'NA' ? '' : index.lastmodifiedby : '';
                        lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate == null || index.lastmodifieddate == 'NA' ? '' : this.dateMonthFormat(index.lastmodifieddate) : '';
                    }

                    row += '"' + srno + '",',
                    row += '"' + empname + '",',
                    row += '"' + assigneecode + '",',
                    row += '"' + empmailid + '",',
                    row += '"' + this.dateMonthFormat(empdoj) + '",',
                    row += '"' + designation + '",',
                    row += '"' + department.toUpperCase() + '",',
                    row += '"' + supervisormailid + '",',
                    row += '"' + appraisalcyclename + '",',
                    row += '"' + moduletype + '",',
                    row += '"' + lastmodifiedby + '",',
                    row += '"' + formfilledbyid + '",',
                    row += '"' + status + '",',
                    row += '"' + this.dateMonthFormat(createddate) + '",',   
                    row += '"' + reviewersmailid + '",'      
                    row += '"' + lastmodifieddate + '",'            
                    // if(index.fieldvalue != "") {
                    //     row += index.fieldvalue
                    // }
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                return;
                }
                var fileName = 'PerformanceAppraisalReport'+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export Report successful",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });   
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        }
    }
}
</script>