<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard" >
        <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
        <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-12 content-header-left px-0">
                <h3 class="content-header-title float-left mb-0">Micro Task Log Details </h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/workstep'>Workstep</router-link></li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/tasks/list'>Micro Task List</router-link></li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/tasklog/list'>Micro Task Log List</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="card border-warning filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor: pointer" v-on:click="mysearchrequestollapse">
              <div class="container-fluid" >
              <div class="row fc_my-05 ">
                <div class="w-auto px-1 mobile-px-0 ">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                      <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                    </div>
                  </div>
                  <h4 class="card-title d-inline-flex ml-1 ">Search Micro Task Time Log Details </h4>
                </div>
                <div class="ml-auto d-flex pr-md-1">
                <div  class="ml-auto px-sm-1 px-sm-0 text-right mobile-px-0">
                  <div class="input-group-append float-right">
                    <button id="commonaddmodule" class="btn btn-sm btn-relief-success" @click="redirecttourl('create', clickedTaskData)"> 
                      
                      <span class="mobile_hide_bt">Create Micro Task Time Log </span> <plus-circle-icon size="1.5x" class="custom-class "></plus-circle-icon>
                    </button>
                  </div>
                </div>
                <span class="rotetions_view_arrow"> 
                  <em class="las la-angle-down"></em>
                </span>
                </div>
              </div>
              </div>
            </div>
            <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]">
              <div class="card-body px-sm-mobile-10">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Micro Task Code</label>
                    <input type="text" class="form-control" placeholder="Enter Micro Task Code" v-model="search.taskcode"  @input="clearCodeList"/>
                  </div> 
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Micro Task Type</label>
                    <treeselect class="projectmdllistdd" placeholder="Select Micro Task Type" v-model="search.searchTasktype" :options="search.tasktypeopts"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Business Task Name</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter min 3 chars to search Business Task Name" v-model="search.searchBusinesstaskname" :options="search.businesstasknameopts" v-on:search-change="businessNameChangeSearch" @input="clearBusinessNameList"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Project Name</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectname" @input="selSearchProject" :multiple="true" :clear-on-select="true" :options="search.projectnameopts"/>
                </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Status</label>
                    <treeselect class="projectmdllistdd" :multiple="true" placeholder="Select Status Name" :options="status" v-model="search.status" />
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Created-By</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter min 3 chars to search createdby" v-model="search.searchProjectcreatedby" :multiple="true" :clear-on-select="true" :options="search.projectcreatedbyopts" v-on:search-change="createdByChangeSearch"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Assigned To</label>
                    <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4Chars To Search Assigned to" :multiple="true" :clear-on-select="true"  v-if ="pagefeatures.tasklogadminuser" :options="search.allottedtoopts" v-model="search.allottedto" v-on:search-change="getEmpListByProjectAssigned"/>
                    <treeselect class="allotedto capitalisetext" placeholder="Select Assigned to" :multiple="true" :clear-on-select="true"  v-else  disabled :options="search.allottedtoopts" v-model="search.allottedto"   />
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <div class="date_picker_foundeds">
                    <label for="">Micro Task Start Date From </label>
                    <div class="input-group input-group-merge">
                      
                      <date-picker placeholder="Select Start Date From" v-model="search.taskassigneddatefrom" valueType="format" class="Startdate w-100" :disabled-date="notBeforeToday" @input="selectedstartdate"></date-picker>
                      <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export tasks.</div>
                    </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <div class="date_picker_foundeds">
                    <label for="">Micro Task Start Date To</label>
                    <div class="input-group input-group-merge">
                     
                      <date-picker placeholder="Select Start Date To" v-model="search.taskassigneddateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                    </div>
                   </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-12 mb-0 text-right">
                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="masterlist('t18', '', search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                    <button type="move" class="btn btn-relief-primary mt-2" :disabled="isBothDateSelected"  @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div id="scrollToListGrid"></div>
        <div class="row">
          <div class="col-md-12">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row" id="table-small">
                  <div class="col-12">
                    <div class="card data_table_padding_ziro_mobile">
                      <div class="table-responsive datatableview">
                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="Task Time Log Details">
                          <thead>
                            <tr>
                              <th scope="col" class="align-middle first-col-sticky py-1" style="min-width: 45px;">Sr#</th>
                              <th scope="col" class="align-middle second-col-sticky" style="min-width: 150px;">Micro Task Code</th>
                              <th scope="col" class="align-middle" style="min-width: 170px;"> Micro Task Name</th>
                              <th scope="col" class="align-middle" style="min-width:170px" >Business Task Name</th>
                              <th scope="col" class="align-middle" style="min-width:90px">Project</th>
                              <th scope="col" class="align-middle" style="min-width:170px">Micro Task  Details</th>
                              <th scope="col" class="align-middle" > Task status</th>
                              <th scope="col" class="align-middle" >Assigned To</th>
                              <th scope="col" class="align-middle">Created date</th>
                              <th scope="col" class="align-middle">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(data, index) in companymasterlist">
                              <tr v-bind:key="data.moduleid">
                                <td data-column="Sr#" class="centeralign first-col-sticky">{{ index + 1 + currPageNum * pagelimit }}</td>
                                <td data-column="Micro Task Code" class="text-nowrap second-col-sticky">{{ data.taskcode }}</td>
                                <td data-column="Micro Task Name" class="rolelistdd" @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer">
                                  <span v-if="data.taskname.length > 50" class="font-weight-normal sitebluecolor" >{{ data.taskname.substring(0, 51) + '...' }}</span>
                                  <span v-if="data.taskname.length <= 50" class="font-weight-normal sitebluecolor" >{{ data.taskname }}</span>
                                </td>
                                <td data-column="Business Task Name" class="rolelistdd"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">
                                    <span class="font-weight-normal sitebluecolor">{{data.btname}}</span>
                                </td>
                                <td data-column="Project">
                                  <span class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectID',data)">{{data.projectname}}</span>
                                </td>
                                <td data-column="Micro Task  Details" class="details_column_table">
                                  <span class="heading_column_text"> Task Type: </span>
                                  <span class="font-weight-normal">{{data.tasktype}}</span>
                                  <hr  class="hr_border-bottom">
                                  <span class="heading_column_text"> Task Level: </span>
                                  <span class="font-weight-normal">{{data.modulename}}</span>
                                </td>
                               
                                <td data-column="Task status">
                                    <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.status}}</div>
                                    <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{data.status}}</div>
                                    <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.status}}</div>
                                    <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50">{{data.status}}</div> 
                                    <div v-else-if="data.status.toLowerCase()=='review'" class="badge badge-pill badge-light-info capitalisetext mr-50">{{data.status}}</div>
                                    <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div> 
                                  </td>
                                
                                <td data-column="Assigned To" class="font-weight-normal">{{ data.empname == ' ' ? 'NA': data.empname }}</td>
                                <td data-column="Created date" class="text-nowrap font-weight-normal">{{ dateMonthFormat(data.createddate) }}</td>
                                <td data-column="Actions" class="clsaction" >
                                  <div class="dropdown">
                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                      <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                    </button>
                                    <div class="dropdown-menu">
                                      <a v-if="loginusername == data.createdby || pagefeatures.edittask" class="dropdown-item devEditEle" @click="redirecttourl('edit', data)" href="javascript:void(0);">
                                        <div class="d-flex align-items-center">
                                          <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                          <span>Edit</span>
                                        </div>
                                      </a>
                                      <a v-on:click="getTaskVersion(data.id, data.taskname, data.createdby, data.createddate)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                        <div class="d-flex align-items-center"> 
                                          <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                          <span>Micro Task History</span>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </template>
                            <template v-if="companymasterlist.length == 0">
                              <tr>
                                <td colspan="14" style="text-align: center;">No record found</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pageCenter text-center">
              <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Micro Task History Cycle</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <ul class="timeline">
                    <li class="timeline-item"  v-for="(data) in taskversionlist.slice(0,1)" v-bind:key="data.experienceid">
                      <span class="timeline-point"><edit-icon size="1.5x" class="custom-class"></edit-icon></span>
                      <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{formatName(data.author)}} created <strong>{{data.taskname}}</strong></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.createdat)}}</span>
                      </div>
                    </li>
                    <li class="timeline-item"  v-for="(data,i) in taskversionlist.slice(1)" v-bind:key="data.experienceid">
                      <span v-if="i==1" class="timeline-point timeline-point-indicator"></span>
                      <span v-if="i%2==0" class="timeline-point  timeline-point-warning timeline-point-indicator"></span>
                      <span v-else class="timeline-point  timeline-point-info timeline-point-indicator"></span>
                      <div class="timeline-event">
                        <div v-if="data.field !== 'taskdescription'" class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{formatName(data.modifiedby)}} updated <strong>{{data.field}}</strong> from <strong style="display:inline-block" v-html="data.oldval"></strong> to <strong style="display:inline-block" v-html="data.newval"></strong></p>
                        </div>
                        <div v-else>
                          <p>{{formatName(data.modifiedby)}} updated task description <a style="color: blue" @click="opentaskdesc(data)">view details</a></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.lastmodifieddate)}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>    
          </div>
        </div>
      </div>
      <div class="modal fade taskdesctabs" id="taskdesc" tabindex="1" role="dialog" aria-labelledby="taskDescmodal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Task description</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <section id="card-navigation">
                <div class="row match-height">
                  <div class="col-md-12">
                    <div class="card mb-3">
                        <ul class="nav nav-tabs" id="nav-tabs" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" id="new-tab" data-toggle="tab" href="#new" role="tab" aria-controls="new" aria-selected="true">New</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="old-tab" data-toggle="tab" href="#old" role="tab" aria-controls="old" aria-selected="false">Old</a>
                          </li>
                        </ul>
                      <div class="card-body">
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
                            <p class="card-text" v-html="this.currentTaskDescription.newval"></p>
                          </div>
                          <div class="tab-pane fade" id="old" role="tabpanel" aria-labelledby="old-tab">
                            <p class="card-text" v-html="this.currentTaskDescription.oldval"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>    
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import { EditIcon,ListIcon,GridIcon,MessageSquareIcon,PlusCircleIcon,XIcon,MoreVerticalIcon,Edit2Icon,FileTextIcon,MonitorIcon,UserIcon,SearchIcon,ExternalLinkIcon,PlusSquareIcon,EyeIcon,PlusIcon,MinusSquareIcon,ClipboardIcon } from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import profilepic from '../profilepic.vue';
import commonMethods from '../../utils/commonMethods';

export default {
  name: "TaskLogList",
  mixins: [ commonMethods ],

  components: {
    Loading,ListIcon,GridIcon,
    EyeIcon,
    PlusSquareIcon,
    VueElementLoading,
    vSelect,
    ExternalLinkIcon,
    EditIcon,
    Treeselect,
    XIcon,
    Pagination,
    PlusIcon,
    SearchIcon,
    MinusSquareIcon,
    ClipboardIcon,
    MessageSquareIcon,
    FileTextIcon,
    MonitorIcon,
    profilepic,PlusCircleIcon,MoreVerticalIcon,Edit2Icon,DatePicker,UserIcon,
  },
  data() {
    return {
      componentKey: 0,
      currentTaskDescription: {},
      taskcodelist:null,
      showcreatetaskbtn: false,
      clickedTaskData: null,
      isBothDateSelected: false,
      systemEngieerRole: apiUrl.systemEngieerRole,
      search: {
        searchTaskname: null,
        Tasknameopts: null,
        searchprojectname: null,
        businesstasknameopts: null,
        searchBusinesstaskname: null,
        projectnameopts: [],
        searchTasktype: null,
        tasktypeopts: null,
        searchProjectcreatedby: null,
        projectcreatedbyopts: null,
        allottedto: [] ,
        allottedtoopts: [],
        status: null,
        taskcodelist:null,
        taskassigneddatefrom:null,
        taskassigneddateto: null,
        statusopts: [{
            id:'open',
            label:'Open'
        },{
            id:'resolved',
            label:'Resolved'
        },{
            id:'closed',
            label:'Closed'
        }]
      },
      isSearchRequestActive: false,
      selectedLevel: 10,
      level: 1,
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      tasktypelist: [],
      typenamelist: [],
      projectlist: [],
      taskassignmentList: [],
      showAddForm: false,
      selected: null,
      editRowData: [],
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      projectType: [],
      projectdescription: "",
      tasklevel: apiUrl.tasklevel,
      tasktypekeylist: apiUrl.tasktypekeylist,
      tasknamemaster: apiUrl.tasknamemaster,
      status:apiUrl.status,
      taskversionlist: [],
      taskdefine: {
        type: null,
        name: null,
        level: null,
        projectid: null,
        projectlist: [
          {
            id: "",
            label: "",
          },
        ],
        projectname: null,
        module: null,
        taskstartdate: null,
        taskenddate: null,
        taskassignby: "",
        taskassigneddate: "",
        taskdefinename: "",
        businesstype: null,
        businesstasklist: [
          {
            id: "",
            label: "",
          },
        ],
        projectmodulename: null,
        isedit: false,
      },
      taskBlured: false,
      valid: false,
      projvalue: null,
      applvalue: null,
      startdate: null,
      tasklevellist: [
        {
          id: "",
          label: "",
        },
      ],
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      projectnameist: [],
      loginuscondition: "",
      attachment: [],
      attachmentview: null,
      isDetActive: false,
      isListDetActive:false,
      isListActive:false,
      displayblk:'displayblk',
      isgridView:false,
      blog_list_wrapper :'blog-list-wrapper',
      card: "card",
      isRequestActive: false,
      card_content: "card-content",
      border_warning: "border-warning",
      collapse: "collapse",
      showtabledata:true,
      allottedto: null,
      loginusername:'',
      sitebluecolor:"sitebluecolor",
      activegridlink:true,
      activelistlink:false,
      countStatus: null,
      taskcountClick:false,
      tokendata:null,
      isresponce:false,
      isAdminRole: false,
    };
  },
  created() {
    this.pagefeatures = {};
    var pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/tasklog/list") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.tokendata = window.localStorage.getItem('token');
        this.loginuscondition = userdata.userid;
        this.loginusername=userdata.username
        this.getEmpListByProjectAssigned([],[parseInt(this.undt.userid)]);
        this.getApplicationdata("t25", "taskdefine-tab");
        // this.search.status=['open']
        // this.search.allottedto = [parseInt(this.undt.userid)]
        // this.masterlist("t18", "", { status: ['open'],allottedto:[parseInt(this.undt.userid)] });
        this.getProjectAllocatedToEmp();
        if(this.$route.params && this.$route.params.search) {
          let search = JSON.parse(localStorage.getItem('timelogsearchItems'))
          if(search) {
            this.getSearchItems(search);
          }else{
            localStorage.removeItem('timelogsearchItems');
            this.search.status=['open'];
            this.search.allottedto = [parseInt(this.undt.userid)];
            this.masterlist("t18", "", { status: ['open'],allottedto:[parseInt(this.undt.userid)] });
          }
        } else {
          localStorage.removeItem('timelogsearchItems');
          this.search.status=['open'];
          this.search.allottedto = [parseInt(this.undt.userid)];
          this.masterlist("t18", "", { status: ['open'],allottedto:[parseInt(this.undt.userid)] });
        }
      }
    }
    $(".filter_rotation_onlick").click(function(){
  $(this).toggleClass("common_arrow_rotetion");
});
    
  },
  methods: {
    getSearchItems(search){
      this.isSearchRequestActive = true;
      this.search.taskcode = search.taskcode;
      this.search.searchTasktype = search.searchTasktype;
      this.search.searchBusinesstaskname = search.searchBusinesstaskname;
      this.search.searchprojectname = search.searchprojectname;
      this.search.status = search.status;
      this.search.searchProjectcreatedby = search.searchProjectcreatedby;
      this.search.allottedto = search.allottedto;
      this.search.taskassigneddatefrom = search.taskassigneddatefrom;
      this.search.taskassigneddateto = search.taskassigneddateto;
      this.masterlist("t18", "", search);
    },
    forceRerender() {
      this.componentKey += 1;
    },
    opentaskdesc(event) {
      this.currentTaskDescription = event
      $('#taskdesc').modal('show');
    },
    redirecttoprofile(empid){
      let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
      window.open(route.href);
    },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    mydetailsollapse() {
      this.isDetActive = true
    },
    parseValue(val) {
        if(val) {
          return parseInt(val)
        }
        return 0
    },
    myListView: function () {
      this.isListDetActive = true;
      this.isgridView=true;
      this.activegridlink=false
      this.activelistlink=true
      $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    myGridView(){
        this.isListDetActive = false;
        this.isgridView=false;
        this.activegridlink=true
        this.activelistlink=false
        $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    formatName(authorstring) {
      let author=authorstring.replace('@firstcry.com','')
      if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
      author = author.replace('.',' ')
      author = author.charAt(0).toUpperCase() + author.slice(1);
      return author
    },
    notBeforeToday: function (date) {
      this.search.taskassigneddateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.taskassigneddatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
      beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    selectedstartdate(){
      if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    selectedenddate(){
       if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    getTaskVersion(taskid, ...args) {
      let taskInfo = { taskname: args[0], author:args[1], createdat: args[2] }
      this.isLoading = true
      let apiUrl = 'api/master/taskmaster/getTaskversion'
      this.input = { taskid,useremail: this.undt.username,empcode: this.undt.userid }
      
      axios({
        method: "POST",
        url: apiUrl,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false
        $('#technologypopup').modal('show');
        if(result.data.errorCode == 0) {
          this.taskversionlist = result.data.data.rows
          this.taskversionlist.unshift(taskInfo)
        } else {
          this.taskversionlist = []
          this.taskversionlist.unshift(taskInfo)
        }
      }).catch(e => {
        this.displayError(e)
      }) 
    },
    redirecttourl:function(btnstate,rowdata){
      if(rowdata){
        rowdata.backbtnstate=btnstate
        if(btnstate=="view"){
          this.$router.push({ 
            path: `/tasks/view/?taskid=${rowdata.id}`,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        }  else if (btnstate == 'projectID') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid
                })
            }else if(btnstate=="create"){
          this.$router.push({
            name: 'TaskLogCreate',
            path: `/tasklog/create`,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        } else if(btnstate=="edit"){
          this.$router.push({ 
            path: `/tasks/update/?taskid=${rowdata.id}`,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        } 
      }else{
        if(btnstate=="create"){
          this.$router.push({
            name: 'TaskLogCreate',
            path: `/tasklog/create`,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        }
      }
      
    },
    resetRecords: function () {
      this.search.businesstasknameopts = null;
      this.search.searchBusinesstaskname = null;
      this.search.searchprojectname = null;
      this.search.searchTaskname = null;
      this.search.searchTasktype = null;
      this.search.searchProjectcreatedby = null;
      this.search.allottedto = null
      this.search.taskassigneddateto = null;
      this.search.taskassigneddatefrom = null;
      this.isBothDateSelected = false
      this.search.status = null
      this.search.taskcode = null
      this.search.taskcodelist = null
      this.search.projectnameopts = []
      this.search.Tasknameopts = null;
      this.search.projectcreatedbyopts = null;
      localStorage.removeItem('timelogsearchItems');
      this.search.status=['open'];
      this.search.allottedto = [parseInt(this.undt.userid)];
      this.masterlist("t18");
      this.getProjectAllocatedToEmp();
    },
    clearCodeList(state) {
      if(!state) {
        this.search.taskcodelist = null
      }
    },
    createdByChangeSearch: function (node){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getAllEmpEmailIds";
        this.input = {
          emailid:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data;
          } else {
            this.search.projectcreatedbyopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.page = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if(this.taskcountClick){
          this.masterlist('t18', '', null,null,this.taskcountClick)
        } else{
        this.masterlist(inputEle, "");
      }
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#scrollToListGrid").offset().top -70
        });
      }, 500);
    },
    masterlist(mdl, objThis, search, status, countclick, value) {
      if(search) {
        localStorage.setItem('timelogsearchItems', JSON.stringify(search))
      }
      if(value == 0){
        return
      }
      this.isLoading = true;
      let setmoduleform = "projectmaster";
      let glbMdlLblval = "Project";
      this.selectedLevel = 10;
      this.formActionLabel = "Create Task";
      if (this.glbst && mdl == "") {
        mdl = this.glbst;
      }
      if (mdl == "t18") {
        setmoduleform = "taskdefinemaster";
        glbMdlLblval = "Task Master";
      }

      $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
      let tblNm = "t18";
      if (mdl) tblNm = mdl;
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        allottedto:this.search.allottedto,
        tasktimelog:1,
        empcode: this.undt.userid,
      };
      let searchThis = JSON.parse(localStorage.getItem('timelogsearchItems'));
      if(this.search.status && this.search.status!=null){
        this.input.status = this.search.status;
      }
      if(searchThis) {
        this.input.taskname = searchThis.searchTaskname
        this.input.businesstaskname =  parseInt(searchThis.searchBusinesstaskname)
        this.input.tasktype = searchThis.searchTasktype
        this.input.projectname = searchThis.searchprojectname
        this.input.allottedto = searchThis.allottedto
        this.input.status = searchThis.status
        this.input.createdby = searchThis.searchProjectcreatedby
        this.input.taskcode = searchThis.taskcode
        let assignedate = ''
        if(this.search.taskassigneddateto){
          assignedate = new Date(this.search.taskassigneddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.datefrom = this.search.taskassigneddatefrom
        this.input.dateto = this.search.taskassigneddateto?assignedate:null
        
      }
      if(countclick){
        this.taskcountClick = countclick
        this.input.status = [countclick.status]
        this.page = this.pageoffset;
      }else{
        this.taskcountClick = false
      }
      this.input.isSearch = false;
      var url = "api/master/taskmaster/getTaskMasterlist";
      this.input.useremail = this.undt.username;
      this.input.empid = this.undt.userid;
      this.input.isFilterSelect = 0;
      this.input.isFilterPayload = {};
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        this.isresponce=true
        if (result.data.errorCode == 0) {
          this.forceRerender()
          this.isDetActive = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          }
         else {
          this.isDetActive = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
        if (this.taskdefine.isredirected) {
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getApplicationdata(mdl, curracttab) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      let apiURL = "api/master/commonmaster/list";
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          let tmpClassNm = "";
          if (mdl == "t25") {
            if (curracttab == "taskdefine-tab") {
              this.tasktypelist = result.data.data.rows;
              this.search.tasktypeopts = result.data.data.rows;
              return true;
            }
          }
          let payload = {
            classnm: tmpClassNm,
          };
          this.fillDropdown(payload);
      }
    }).catch(e => {
        this.displayError(e)
      });
    },
    fillDropdown(data){
      return ;
    },
    dateMONFormat(value){
      if(value){
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      }else{
        return "";
      }
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    clearBusinessNameList(state) {
      if(!state) {
        this.search.businesstasknameopts = null;
      }
    },
    businessNameChangeSearch: function (node) {
        if(node && node.length>1) {
            axios({
                'method': 'POST',
                'url': 'api/master/commonmaster/getModuleDropdownOption',
                'data': {
                    tbl: 't9',
                    taskname: node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                },
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.search.businesstasknameopts=result.data.data;
                } else {
                    this.search.businesstasknameopts= null;
                }
            }).catch(e => {
            this.displayError(e)
          })
        }
    },
    getProjectAllocatedToEmp() {
        this.isLoading = true
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          roleid : this.undt.roleid,
          isfrompage: 'listingpage'
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers': { 'authorization': this.tokendata }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.search.projectnameopts = result.data.data;
          } else {
              this.search.projectnameopts = [];
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    projectChangesearch: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          roleid : this.undt.roleid
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectnameopts = result.data.data;
          } else {
            this.search.projectnameopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getEmpListByProjectAssigned(node,empid) {
      if((node && node.length > 3) || empid){

        this.input ={
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        if(node && node.length > 3){
          this.input.empname = node.toLowerCase();
        }else if(empid && empid !=null && empid.length > 0){
          this.input.assignedto = empid;
        }else{
          return;
        }
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false;
          if(result.data.errorCode == 0) {
            this.search.allottedtoopts = result.data.data;
            let decryptdepartmenttext = this.search.allottedtoopts.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.allottedtoopts = [];
          }
        }).catch(e => {
          this.displayError(e)
        })
      }
    },
    selSearchProject(state, value) {
      this.search.searchprojectname = null
      this.search.searchprojectname = state
    },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      let assignedate=''
      if(this.search.taskassigneddateto){
        assignedate = new Date(this.search.taskassigneddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input = {
        limit:null,
        offset:null,
        businesstaskname : this.search.searchBusinesstaskname,
        projectname : this.search.searchprojectname,
        taskcode : this.search.taskcode,
        taskname : this.search.searchTaskname,
        tasktype : this.search.searchTasktype,
        allottedto : this.search.allottedto,
        status : this.search.status,
        createdby : this.search.searchProjectcreatedby,
        useremail: this.undt.username,
        empid : this.undt.userid,
        tasktimelog: 1,
        datefrom : this.search.taskassigneddatefrom,
        dateto : assignedate,
        empcode: this.undt.userid,
        tbl:'t18'
      };
      if(this.taskcountClick && this.taskcountClick.status){
        this.input.status = this.taskcountClick.status
      }
      axios({
        method: "POST",
        url: `api/master/taskmaster/getTaskMasterlist`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download(result.data.data.rows,filename)
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        if (result.data.status) {
          this.isDetActive = true;
        } 
      }).catch(e => {
        this.displayError(e)
      });
    },

    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
        var ShowLabel = 'TaskListReport_'+filename
        var JSONData = readExportdata
        var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        var CSV = ''
        if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
              row += index + ','
          }
          row = row.slice(0, -1)
          CSV = 'Sr#,Micro Task code, Micro Task Name, Business Task Name, Project, Micro Task Type, Micro Task Level, Status, Created by, Assigned To, Created Date' + '\r\n'
        }

        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let taskcode = index.hasOwnProperty('taskcode') ? index.taskcode : '';
          let tasktype = index.hasOwnProperty('tasktype') ? index.tasktype : '';
          let btname = index.hasOwnProperty('btname') ? index.btname : '';
          let taskname = index.hasOwnProperty('taskname') ? index.taskname : '';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let status = index.hasOwnProperty('status') ? index.status : '';
          let createdby = index.hasOwnProperty('createdby') ? index.createdby.split("@")[0] : '';
          let empname = index.hasOwnProperty('empname') ? index.empname ==null || index.empname.toLowerCase() =='na'?'':index.empname : '';         
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          let modulename = index.hasOwnProperty('modulename') ? index.modulename : '';
          createddate = this.dateMONFormat(createddate);
            row += '"' + srno + '",',
            row += '"' + taskcode + '",',
            row += '"' + taskname + '",',
            row += '"' + btname + '",',
            row += '"' + projectname + '",',
            row += '"' + tasktype + '",',
            row += '"' + modulename + '",',
            row += '"' + status + '",',
            row += '"' + createdby + '",',
            row += '"' + empname + '",',           
            row += '"' + createddate + '",'
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'TaskListReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });      
    }
  },
  export (data, filename, mime) {
    var data
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    download(blob, filename, mime)
  },
  },
};
</script>
<style scoped>
.displayblk{
    display:none !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
.bordertable .row1{
  color:black;
}
</style>
