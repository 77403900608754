<template>
    <section>
        <Nav/>
        <VMenu/>
        <div class="mainSection">
            <router-view></router-view>     
        </div> 
    </section>
</template>
<script>
import Nav from './Nav'
import VMenu from './VMenu'
import Dashboard from './Dashboard.vue'
// import axios from 'axios' 
export default({
    name:'Home',
    components:{
        Nav,
        VMenu,
        Dashboard
    },
    // async created(){
    //     const response = await axios.get('master/country/list');
    //     console.log(response);
    // }
})
</script>
<style>
 .mainSection {
   position: relative;
   /* top: 155px;   */
   /* left:10px; */
 }
 
</style>
