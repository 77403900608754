<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div v-if="pagefeatures.btcconfiglist" class="content-wrapper pb-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left content-header-left mb-1 pt-0">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-12 content-header-left pl-0">
                 <h3 class="content-header-title float-left mb-0">BTC Status Task Creation Configuration</h3>
                 <div class="breadcrumb-wrapper">
                   <ol class="breadcrumb">
                     <li class="breadcrumb-item sitebluecolor cursorpointercls">
                     <router-link to='/config/btctaskcreationconfig'>BTC Status Task Creation</router-link></li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
         </div>
 
       <div class="card border-warning filter_card_searchbgst" style="border-radius: 5px; border: none!important;">
         <div class="card-header filter_rotation_onlick " style="cursor:pointer" v-on:click="mysearchrequestollapse">
           <div class="container-fluid">
             <div class="row">
               <div class="w-auto px-1 mobile-padding-0-2 mobile_w_calc_38 d-inline-flex align-items-center">
                   <div class="avatar bg-light-secondary rounded d-inline-flex">
                       <div class="avatar-content">
                           <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                           </search-icon>
                       </div>
                   </div>
                    <h4 class="card-title d-inline-flex ml-1">Business Task Creation Configuration Details Search</h4>
               </div>
               <div class="ml-auto mr-lg-1 d-flex">

                <div v-if="pagefeatures.createbtcconfig" class=" mx-1  text-right mobile-padding-0-2">
                  <div class="input-group-append displayblk">
                    <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" :data-target="currentmodule" @click="createConfigform($event)" type="button" data-backdrop="static" data-keyboard="false">
                    <span class="mobile_hide_bt">Create Configuration</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></button>
                  </div>
                </div>

                <span class="rotetions_view_arrow"> 
                  <em class="las la-angle-down"></em>
                </span>
                </div>
             </div>
           </div>
         </div>
         <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
           <div class="card-body pt-2">
             
             <div class="row">
               <div class="col-12 col-sm-4 col-md-4 col-lg-3 mb-1">
                   <label class="form-label lable-left">Project Name</label>
                   <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.projectid" :clear-on-select="true" :options="projectlist" v-on:search-change="getallprojectlist" :multiple="true"/>
               </div>

               <div class="form-group datePicker col-12 col-sm-4 col-md-4 col-lg-3">
                  <label for="appprojectdd">Role</label>
                  <treeselect placeholder="Select Role " v-model="search.roleid" :options="rolelist" :multiple="true"/>
               </div>
               <div class="form-group datePicker col-12 col-sm-4 col-md-4 col-lg-3">
                <label for="appprojectdd">New Status</label>
                  <treeselect placeholder="New Status" v-model="search.currentStatus" :options="statuslist" :multiple="true"/>
               </div>
               <div class="form-group datePicker col-12 col-sm-4 col-md-4 col-lg-3">
                  <label for="appprojectdd">Task Creation Status</label>
                  <treeselect placeholder="Task Creation Status" v-model="search.nextStatus" :options="statuslist" :multiple="true"/>
               </div>
               <div class="form-group datePicker col-12 col-sm-4 col-md-4 col-lg-3">
                  <label for="appprojectdd">Active/Inactive</label>
                  <treeselect placeholder="search Active/Inactive" v-model="search.active" :options="active"/>
               </div>
               
               <div class="col-12 col-sm-4 col-md-4 col-lg-3 mb-0">
                 <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getStatusTaskConfiguration(search, 1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                 <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetSearchRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
               </div>
             </div>
           </div>
         </div>

         
         <div class="modal modal-slide-in fade" id="projectmodulemaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#3E4757 !important" @click="resetForm()">×</button>
                    <div class="modal-header mb-1 leftsidepopupstyle px-1" style="background-color:#ECEEFB !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <span v-if="this.editflag != null" class="align-middle mx-0">Update Configuration Details</span>
                            <span v-else class="align-middle mx-0">Add Configuration Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm mobile-padding-0-2 px-0">
                        
                        <form>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Module Name</label>
                                    <treeselect placeholder="Module Status" v-model="createConfig.moduleName" :options="modulename" @input="selmodule"/>
                                    <div class="errorinputmsg" v-if="error.createConfig.moduleName">{{ error.createConfig.moduleName }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group">
                                    <label for="appprojectdd">Creation Module Name</label>
                                    <treeselect placeholder="Creation Module Name" v-model="createConfig.creationModuleName" :options="creationModuleName" @input="selmodulename"/>
                                    <div class="errorinputmsg" v-if="error.createConfig.creationModuleName">{{ error.createConfig.creationModuleName }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                              <div class="form-group">
                                <label for="appprojectdd">Task Creation Type</label>
                                <treeselect placeholder="Select Type" v-model="createConfig.type" :options="type" />
                                </div>
                            </div>
                            <div v-if="createConfig.type!='milestone'" class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Condition Status</label>
                                    <treeselect placeholder="Creation Module Name" v-model="createConfig.conditonStatus" :options="conditonstatuslist" @input="selconstatus"/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Project</label>
                                    <treeselect v-if="this.editflag != null" placeholder="Enter min 3 chars to search project " v-model="createConfig.project" :options="projectlist1"  v-on:search-change="getallprojectlist1" :clear-on-select="true" @input="selproect" @close="validateform($event)"/>
                                    <treeselect v-else placeholder="Enter min 3 chars to search project " v-model="createConfig.project" :options="projectlist1"  v-on:search-change="getallprojectlist1"  :multiple="true" :clear-on-select="true" @input="selproect" @close="validateform($event)"/>
                                    <div class="errorinputmsg" v-if="error.createConfig.project">{{ error.createConfig.project }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Role</label>
                                    <treeselect  v-if="this.editflag != null"  class="projectlistdd" placeholder="Select Role " v-model="createConfig.role" :options="rolelist" @input="selrole"/>
                                    <treeselect v-else class="projectlistdd" placeholder="Select Role " v-model="createConfig.role" :options="rolelist" @input="selrole" :multiple="true"/>
                                    <div class="errorinputmsg" v-if="error.createConfig.role">{{ error.createConfig.role }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Micro Task Type</label>
                                    <treeselect placeholder="Select Microtasktype" v-model="createConfig.microtasktype" :options="tasktypelist" @input="selmicrotask"/>
                                    <div class="errorinputmsg" v-if="error.createConfig.microtasktype">{{ error.createConfig.microtasktype }}</div>
                                </div>
                            </div>

                            <div class="col-12 row mobile_margin-px-0">
                              <div class="form-group mg-bot0 col-sm-12 col-lg-6 mobile-px-0">
                                    <label for="appprojectdd">New Status</label>
                                    <treeselect placeholder="New Status" v-model="createConfig.currentStatus" :options="statuslist" @input="selcurrentstatus"/>
                                    <div class="errorinputmsg" v-if="error.createConfig.currentStatus">{{ error.createConfig.currentStatus }}</div>
                                </div>
                                <div class="form-group mg-bot0 col-sm-12 col-lg-6 mobile-px-0">
                                    <label for="appprojectdd">Task Creation Status</label>
                                    <treeselect  v-if="this.editflag != null" placeholder="Task Creation Status" v-model="createConfig.nextStatus" :options="statuslist" @input="selnextstatus"/>
                                    <treeselect v-else placeholder="Task Creation Status" v-model="createConfig.nextStatus" :options="statuslist" @input="selnextstatus" :multiple="true"/>
                                </div>
                            </div>
                            
                            <div v-if="this.editData == true" class="col-12">
                              <div class="form-group">
                                <label for="ispublish" :class="{'active': createConfig.isactive}" class="toggle__button">
                                  <input type="checkbox" id="ispublish" v-model="createConfig.isactive" >
                                  <span class="toggle__switch"></span>
                                  <span :class="{'off-lable-color': !createConfig.isactive, 'on-label-color': createConfig.isactive, 'toggle__label': true}">Is Active</span>
                                </label>
                              </div>
                            </div>
                          
                            <div class="col-12">
                                <div class="form-group d-flex flex-wrap mb-0" >
                                    <button   type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal" v-on:click.stop.prevent="savewizard(createConfig,)">Submit</button>
                                    <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div>

       </div>

       <div class="row">
          <div class="col-md-12">
              <div class="card mb-3">
                  <div class="">
                      <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                              <div class="row" id="proModuleList">
                                  <div class="col-12">
                                      <div class="card">
                                          <div class="" style="padding-left:0px !important;padding-top:0px !important;">
                                              <div class="row">                       
                                                 
                                                  
                                              </div>
                                          </div>
                                          <div class="table-responsive">
                                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                              <thead>
                                                  <tr>
                                                      <th scope="col" class="srno first-col-sticky py-1 align-middle">Sr#</th>
                                                      <th scope="col" class="second-col-sticky align-middle" style="min-width:180px;">Project Name</th>
                                                      <th scope="col" class="align-middle">Tracker Name</th>
                                                      <th scope="col" class="align-middle">Module Name</th>
                                                      <th scope="col" class="align-middle">Role</th>
                                                      <th scope="col" class="align-middle">New Status</th>
                                                      <th scope="col" class="align-middle">Task Creation Status</th>
                                                      <!-- <th scope="col" class="align-middle">Creation Module Name</th>
                                                      <th scope="col" class="align-middle">BTC Role Field</th> -->
                                                      <th scope="col" class="align-middle">Task Type</th>
                                                      <th scope="col" class="align-middle">Micro Task Type</th>
                                                      <th scope="col" class="align-middle">Active</th>
                                                      <th scope="col" class="clsaction align-middle">Actions</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                <template v-for="(data,index) in statusTaskCreationConfiglist">
                                                  <tr v-if="!data.edit" v-bind:key="data.id">
                                                    <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                    <td class="srno first-col-sticky"> 
                                                      <span class="font-weight-normal capitalisetext">{{data.projectname}}</span>
                                                    </td>
                                                    <td class="text-left"> 
                                                      <span v-if="data.moduletype != null && data.moduletype != ''" class="font-weight-normal capitalisetext">{{data.moduletype}}</span>
                                                      <span v-else class="font-weight-normal capitalisetext">NA</span>
                                                    </td>
                                                    <td class="text-left"> 
                                                      <span class="font-weight-normal capitalisetext">{{data.modulename}}</span>
                                                    </td>
                                                    <td class="text-left"> 
                                                      <span class="font-weight-normal capitalisetext">{{data.designationname}}</span>
                                                    </td>
                                                    <td class="text-left"> 
                                                      <span class="font-weight-normal capitalisetext">{{data.currentstatus}}</span>
                                                    </td>
                                                    <td class="text-left"> 
                                                      <span class="font-weight-normal capitalisetext">{{data.nextstatus}}</span>
                                                    </td>
                                                    <td class="text-left"> 
                                                      <span class="font-weight-normal capitalisetext">{{data.tasktype}}</span>
                                                    </td>
                                                    <td class="text-left"> 
                                                      <span class="font-weight-normal capitalisetext">{{data.tasktypename}}</span>
                                                    </td>
                                                    <td class="text-center text-capitalize"  v-if="data.active === 1">
                                                      <em class="las la-check-circle custom-class"  style="color: #28c76f;font-size: 24px;"></em>
                                                    </td>
                                                    <td class="text-center text-capitalize" v-else>
                                                      <em class="las la-times-circle custom-class" style="color: #b82323;font-size: 24px;"></em>
                                                    </td>
                                                    <td class="centeralign">
                                                      <span  class=" devEditEle edit_open_box_exam" data-toggle="modal" :data-target="currentmodule" @click="openEditModule(data,index)">
                                                        <em class="las la-pen custom-class" style="color: #4F46E5;font-size: 24px;cursor: pointer;"> </em>
                                                      </span>
                                                    </td>
                                                  </tr>

                                                </template>
                                                <template v-if="statusTaskCreationConfiglist.length==0">
                                                  <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </template>
                                              </tbody>
                                            </table>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="pageCenter">
                            <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        </section>
     </div>
     <div v-else class="w-100 text-center">
         <h2 class="mb-1"> You are not authorized! 🔐 </h2>
         <p class="mb-2"> You don’t have permission to access this page!! </p>
         <img  src="../../../public/images/pages/not-authorized.e9c47c33.svg" alt="Not authorized page" class="img-fluid" />
     </div>
   </div>
 </template>
 
 <script>
 import axios from 'axios';
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css';
 import '@riophae/vue-treeselect/dist/vue-treeselect.css';
 import { SearchIcon,XIcon,ExternalLinkIcon,PlusCircleIcon } from "vue-feather-icons";
 import DatePicker from 'vue2-datepicker';
 import 'vue2-datepicker/index.css';
 import commonMethods from '../../utils/commonMethods';
 import Treeselect from '@riophae/vue-treeselect'
 import Pagination from 'vue-pagination-2'
 
 export default {
   name: 'BtcTaskCreationConfig',
   components: {
     VueElementLoading,
     Loading,
     SearchIcon,
     XIcon,
     ExternalLinkIcon,
     Treeselect,
     DatePicker,
     PlusCircleIcon,
     Pagination,
   },
   mixins: [ commonMethods ],
   data() {
     return {
      editflag: null,
      currPageNum: 0,
      pagelimit:apiUrl.LIMIT,
      totalcount: 0,
      page:1,
      pageoffset:1,
      pageCount: 0,

       isBothDateSelected: false,
       search: {
         projectnameList: [],
         searchprojectid: this.$route.query.pid,
         startdatefrom:null,
         startdateto:null,
         sheettype : 'appwise'
       },
       isLoading: false,
       undt: null,
       tokendata: null,
       isSearchRequestActive:false,
       card_content:'card-content',
       collapse:'collapse',
       addformbtntxt:'Next',
       urlId:this.$route.query.pid,
       backpagedata: null,
       sheettype : 'appwise',
       pagefeatures: [],
       currentmodule:'#projectmodulemaster',
       projectlist:[],
       projectlist1:[],
       rolelist: [],
       statuslist:[],
       tasktypelist:[],
       type : [
       { id : 'milestone', label : 'milestone' },
        { id : 'review', label: 'review' },
        { id : 'wbs', label : 'wbs' },
       ],
       statusTaskCreationConfiglist:[],
       createConfig: {
          project: null,
          role: null,
          currentStatus: null,
          nextStatus: null,
          type: null,
          microtasktype: null,
          moduleName: null,
          conditonStatus: null,
          isactive: null
       },
       error: {
        createConfig: {
          project: '',
          role: '',
          currentStatus: '',
          nextStatus: '',
          type: '',
          microtasktype: '',
          moduleName: '',
          conditonStatus: ''
        },
       },
      modulename :[
        {id: 'Business Task', label: 'Business Task'}
      ],
      creationModuleName: [
        {id: 'task', label: 'Task'},
      ],
      conditonstatuslist:[
        {id: 'closed previous task', label: 'Closed Previous Task'}
      ],
      active : [
        {id : 1, label: 'Active'},
        {id : 0, label : 'IN Active'}
      ],
      editData: false,
      search: {
        projectid: null,
        roleid: null,
        currentStatus: null,
        nextStatus: null,
        active: null
      },
      roledetails: null,
      payload:{}
     }
   },
   created() {
     this.pagefeatures = {};
     if(window.localStorage.getItem("pagefeatures")){
       let pagefeatures1 = JSON.parse(
         window.atob(window.localStorage.getItem("pagefeatures"))
       );
       pagefeatures1.forEach((element) => {
        if (element.page === "/config/btctaskcreationconfig") {
          this.pagefeatures[element.featurename] = element.featureaccess;
        }
      });
     }
   },
   mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.userrole = window.localStorage.getItem("userrole");
        this.getRole();
        this.getStatusList();
        this.getModuleTypesList();
        this.getStatusTaskConfiguration();
      }
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      });
    }
   },
   methods: {     
       selProj(state) {
            if(state==undefined || state == null) { 
            this.isBothDateSelected = true 
            } else {
            this.isBothDateSelected = false 
            }
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        resetRecords: function() {
          this.editflag = null;
          this.search.searchprojectid = null
          this.search.startdatefrom = null
          this.search.startdateto = null
        },
        createConfigform(state) {
          //console.log(state);
        },
        getallprojectlist(node) {
        if (node && node.length>3) {
          this.isLoading = true;
          this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            projectname: node.toLowerCase(),
            isfrompage:'listingpage'
          }
          axios({
            'method': 'POST',
            'url': 'api/project/getallprojectlist',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
              this.projectlist = result.data.data;
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }else{
              this.projectlist = [];
            }
        });
      }
    },
    getallprojectlist1(node) {
        if (node && node.length>3) {
          this.isLoading = true;
          this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            projectname: node.toLowerCase(),
          }
          if(this.createConfig.project !=null){
                this.inputfield.isfrompage = 'updatepage'
            }else{
                this.inputfield.isfrompage = 'createpage'
            }
          axios({
            'method': 'POST',
            'url': 'api/project/getallprojectlist',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
              this.projectlist1 = result.data.data;
              if(this.projectlist1 && this.projectlist1.length > 0 && (this.createConfig.project&& this.createConfig.project!=null)){
                    const checkexistproject = this.projectlist1.some(item =>item.id === this.createConfig.project);
                    if(checkexistproject){
                        this.getProjectnamebyid(this.createConfig.project);
                    }
                }else if(this.projectlist1.length ==0 && (this.createConfig.project&& this.createConfig.project!=null)){
                    this.getProjectnamebyid(this.createConfig.project);
                }
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }else{
              this.projectlist1 = [];
            }
        });
      }
    },
    getProjectnamebyid(pid){
            this.isLoading = true;
            this.apiURL="api/project/getallprojectlist"
            this.input={
                useremail: this.undt.username,
                //empid :this.undt.userid,
                empcode: this.undt.userid,
                projectid: [pid],
                isfrompage: 'isprojectid'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    if(result.data.data && result.data.data.length > 0){
                        this.projectlist1.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
    getRole(){
      this.isLoading = true
      this.pageoffset=1
      this.inputthrid = {
          tbl:'t10',
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
      }
      axios({
        'method': 'POST',
        'url': 'api/projectonlyroleassignment/getBTCRolesDesignations',
        'data': this.inputthrid,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false;
        if(result.data.status == true){
          this.rolelist = result.data.data;
        }else{
          this.rolelist=[]
        }
      }).catch(e => {
                this.displayError(e)
      });
    },
    getStatusList() {
      let url = "api/btc/getBtcStatusList";
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
      method: "POST",
      url: url,
      data: this.input,
      'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.statuslist = result.data.data.rows;
        } else {
            this.statuslist = [];
        }
      }).catch(e => {
                this.displayError(e)
      });
    },
    getModuleTypesList() {
      this.input = {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        tracker: "Task Type",
        modulename: "task",
      }
      axios({
        'method': 'POST',
        'url': 'api/typelist/getModuleTypesList',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        if(result.data.status == true){
            this.tasktypelist = result.data.data.rows

            // Modify the data by removing "id" and renaming "taskid" to "id"
            this.tasktypelist.forEach(item => {
              delete item.id;
              item.id = item.taskid;
              delete item.taskid;
            })
        }else{
            this.tasktypelist = []  
        }
      }).catch(e => {
                this.displayError(e)
         });
    },

    savewizard(state) {
      let valid = this.validateform() 
      if (valid) {
        this.isLoading = true
        let url
        
        let payload = {
          useremail  : this.undt.username,
          empcode: this.undt.userid,
          microtasktype: state.microtasktype,
          project: state.project,
          role: state.role,
          currentStatus: state.currentStatus,
          nextStatus: state.nextStatus,
          tasktype: state.type,
          creationModuleName: state.creationModuleName,
          moduleName: state.moduleName,
          conditonStatus: state.conditonStatus
        }

        if (this.editData == true) {
          payload.id = this.id
          payload.active = state.isactive == true ? 1 : 0 ;
           url = '/api/statustaskupdation/update' 
        } else {
           url = '/api/statustaskcreation/create' 
        }
        axios({
         method: 'POST',
         url,
         data: payload,
         headers: { authorization :this.tokendata }
       }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
           Swal.fire({
             title: "Success!",
             text: result.data.msg,
             icon: 'success',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
          // this.$router.push({ name: 'FormsConfigurationList', params: { search: true }});
          this.resetForm()
          $('.close').click();
          this.getStatusTaskConfiguration();
         } else if(result.data.errorCode == 3){
             Swal.fire({
                   title: "Session Expired...!!",
                   text: result.data.msg,
                   icon: 'info',
                   customClass: {
                       confirmButton: "btn btn-primary",
                   },
                   buttonsStyling: !1,
               }).then(function() {
                 window.location.href = "/#/login";
             })
           } else {
           Swal.fire({
             title: "Failed",
             text: result.data.msg,
             icon: 'info',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
         }
       }).catch(e => {
                this.displayError(e)
      });
      }
    },
    validateform() {
      let valid = true 
      if (!this.createConfig.project || this.createConfig.project.length == 0) {
        this.error.createConfig.project = 'Project Is Required'
        valid = false
      } else {
        this.error.createConfig.project = ''
      }

      if (!this.createConfig.role) {
        this.error.createConfig.role = 'Role Is Required'
        valid = false
      } else {
        this.error.createConfig.role = ''
      }
      
      if (!this.createConfig.currentStatus) {
        this.error.createConfig.currentStatus = 'Current Status is required'
        valid = false
      } else {
        this.error.createConfig.currentStatus = ''
      }
      if (!this.createConfig.nextStatus) {
        this.error.createConfig.nextStatus = 'Next Status is required'
        valid = false
      } else {
        this.error.createConfig.nextStatus = ''
      }

      if (!this.createConfig.microtasktype) {
        this.error.createConfig.microtasktype = 'Micro Task Type Is Required'
        valid = false
      } else {
        this.error.createConfig.microtasktype = ''
      }
      if (!this.createConfig.moduleName) {
        this.error.createConfig.moduleName = 'Module Name Type Is Required'
        valid = false
      } else {
        this.error.createConfig.moduleName = ''
      }

      if (!this.createConfig.creationModuleName) {
        this.error.createConfig.creationModuleName = 'Creation Module Name Type Is Required'
        valid = false
      } else {
        this.error.createConfig.creationModuleName = ''
      }
  
      return valid
    },
    selproect(state) {
      this.createConfig.project = null,
      this.createConfig.project = state
      if (!state) {
        this.error.createConfig.project = 'Project Is Required'
      } else {
        this.error.createConfig.project = ''
      }
    },
    selrole(state){
      this.createConfig.role = null
      this.createConfig.role = state
      if (!state) {
        this.error.createConfig.role = 'Role Is Required'
      } else {
        this.error.createConfig.role = ''
      }
    },
    selcurrentstatus(state) {
      this.createConfig.currentStatus = null
      this.createConfig.currentStatus = state
      if (!state) {
        this.error.createConfig.currentStatus = 'Current Status Is Required'
      } else {
        this.error.createConfig.currentStatus = ''
      }
    },
    selnextstatus(state) {
      this.createConfig.nextStatus = null
      this.createConfig.nextStatus = state
      if (!state) {
        this.error.createConfig.nextStatus = 'Next Status Is Required'
      } else {
        this.error.createConfig.nextStatus = ''
      }
    },

    selmicrotask(state) {
      this.createConfig.microtasktype = null
      this.createConfig.microtasktype = state
      if (!state) {
        this.error.createConfig.microtasktype = 'Micro Task Type Is Required'  
      } else {
        this.error.createConfig.microtasktype = ''
      }
    },
    selmodulename(state) {
      this.createConfig.creationModuleName = null
      this.createConfig.creationModuleName = state
      if (!state) {
        this.error.createConfig.creationModuleName = 'Creation Module Name Is Required'  
      } else {
        this.error.createConfig.creationModuleName = ''
      }
    }, 
    selmodule(state) {
      this.createConfig.moduleName = null
      this.createConfig.moduleName = state
      if (!state) {
        this.error.createConfig.moduleName = 'Module Name is required'
      } else {
        this.error.createConfig.moduleName = null
      }
    },
    selconstatus(state) {
      this.createConfig.conditonStatus = null,
      this.createConfig.conditonStatus = state
      // if (!state) {
      //   this.error.createConfig.conditonStatus = 'Condition Status is Required' 
      // } else {
      //   this.error.createConfig.conditonStatus = null
      // }
    },
    resetForm() {
      this.editflag = null;
      this.editData = false
      this.createConfig.project= null,
      this.createConfig.role= null,
      this.createConfig.currentStatus= null,
      this.createConfig.nextStatus= null
      this.createConfig.type= null,
      this.createConfig.microtasktype= null,
      this.createConfig.moduleName= null,
      this.createConfig.creationModuleName = null,
      this.createConfig.conditonStatus = null

      this.projectlist = []
    },

    getStatusTaskConfiguration(search, flag) {
      this.islistLoading=true
      if(flag==1){
        this.pageoffset=1
        this.page=1
      }
      
      let url = 'api/getstatusTaskCreationConfig'
      this.input = {
          offset:this.pageoffset,
          limit: apiUrl.LIMIT,
          useremail: this.undt.username,
          empcode: this.undt.userid
      }
      
      if (search) {
        this.input.projectid = this.search.projectid
        this.input.roleid = this.search.roleid
        this.input.currentStatus = this.search.currentStatus
        this.input.nextStatus = this.search.nextStatus
        this.input.active = this.search.active
      }
      axios({
        'method': 'POST',
        'url': url,
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      }).then(result => {
          this.isresponce=true
          this.islistLoading=false;
          if(result.data.errorCode == 0){
              this.showprojects=false
              // this.showprojectmodule=true;
              this.statusTaskCreationConfiglist=result.data.data.rows;
              this.totalcount = result.data.data.count
              this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        
          } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          }else{
              // this.showprojectmodule=true;
              this.statusTaskCreationConfiglist=[];
             
              this.totalcount = 0
              this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
              
          }
      }).catch(e => {
                this.displayError(e)
      });
    },
    getallprojectarr(projectidarr) {
        this.isLoading = true;
        let projectarr = []
        projectarr.push(projectidarr)
        this.inputfield= {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          projectid:  projectarr,
          isfrompage: 'updatepage'
        }
        axios({
          'method': 'POST',
          'url': 'api/project/getallprojectlist',
          'data': this.inputfield,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.status == true){
            this.projectlist = result.data.data;
            if(this.projectlist1 && this.projectlist1.length > 0 && (this.createConfig.project&& this.createConfig.project!=null)){
                    const checkexistproject = this.projectlist1.some(item =>item.id === this.createConfig.project);
                    if(checkexistproject){
                        this.getProjectnamebyid(this.createConfig.project);
                    }
                }else if(this.projectlist1.length ==0 && (this.createConfig.project&& this.createConfig.project!=null)){
                    this.getProjectnamebyid(this.createConfig.project);
                }
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.projectlist = [];
          }
        }).catch(e => {
                this.displayError(e)
      });
			},
      clickCallback: function(pageNum) {
        this.pageoffset=pageNum
        this.currPageNum = pageNum - 1
        this.getStatusTaskConfiguration(this.search);
      },
      openEditModule(data) {
        this.editData = true 
        this.getallprojectarr(data.projectid)
        this.id = data.id
        this.editflag =  data.id
        this.createConfig.moduleName = data.modulename
        this.createConfig.creationModuleName = data.creationmodulename,
        this.createConfig.project = data.projectid,
        this.createConfig.role = data.roleid,
        this.createConfig.currentStatus = data.currentstatus,
        this.createConfig.nextStatus = data.nextstatus,
        this.createConfig.type = data.tasktype ,
        this.createConfig.microtasktype = data.microtasktype,
        this.createConfig.conditonStatus = data.conditionstatus
        this.createConfig.isactive = data.active == 1 ? true : false
        if(this.projectlist1 && this.projectlist1.length > 0 && (this.createConfig.project && this.createConfig.project !=null)){
            const checkexist = this.projectlist1.some(item =>item.id === this.createConfig.project);
            if(!checkexist){
                this.getProjectnamebyid(this.createConfig.project);
            }
        }else if(this.projectlist1.length ==0 && (this.createConfig.project && this.createConfig.project !=null)){
            this.getProjectnamebyid(this.createConfig.project);
        }
      },
      resetSearchRecords() {
        this.search.projectid = null
        this.search.roleid = null
        this.search.currentStatus = null
        this.search.nextStatus = null
        this.search.active = null
        this.getStatusTaskConfiguration() 
      },
   }
 }
 </script>
 <style> 
 .view_bg-gradient-primary{
   background: #7DA3F9!important;
 }
 </style>