<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Work Step</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li v-if="pagefeatures.breadcrum_project" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Wizard',path:'project',btnstate:'view',rowdata:backpagedata})">Projects</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Projects</li>
                                    <li v-if="pagefeatures.breadcrum_application" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application',path:'application',btnstate:'view',rowdata:backpagedata})">Applications</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Applications</li>
                                    <li v-if="pagefeatures.breadcrum_module" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Module',path:'projectmodule',btnstate:'view',rowdata:backpagedata})">Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Modules</li>
                                    <li v-if="pagefeatures.breadcrum_appmodule" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'Project Application Module',path:'applicationmodule',btnstate:'view',rowdata:backpagedata})">Application Modules</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Application Modules</li>
                                    <li v-if="pagefeatures.breadcrum_section" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'SectionModule',path:'sectionmodule',btnstate:'view',rowdata:backpagedata})">Sections</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Sections</li>
                                    <li v-if="pagefeatures.breadcrum_functionality" class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirecttobreadkumb({name:'FuntionalityModule',path:'functionalitymodule',btnstate:'view',rowdata:backpagedata})">Functionality</li>
                                    <li v-else class="breadcrumb-item sitebluecolor cursorpointercls">Functionality</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-list-wrapper">
            <div class="row">
                <div class="col-md-4 col-12"  v-for="(data,index) in companymasterlist" v-bind:key="index">
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header">
                    <div class="col-6">
                    <h4 class="card-title">{{data.projectname}}  </h4>
                    </div>
                    <div class="col-6;margin-left=0">
                    <a @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                        <div class="d-flex align-items-center sitebluecolor">
                                <message-square-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></message-square-icon>
                            <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                        </div>
                    </a>
                </div>
                </div>
                <div class="card" style="margin-bottom:0 !important;">
                    <div class="card-body">
                        <h4 class="card-title">
                            <span v-if="data.workstepname && data.workstepname.length > 50"  class="blog-title-truncate text-body-heading ">
                                {{data.workstepname.substring(0,51)+ ' ...'}}
                            </span>
                            <span v-else  class="blog-title-truncate text-body-heading ">
                                {{data.workstepname}}
                            </span>
                        </h4>
                        <div class="media">
                            <div class="avatar mr-50">
                                <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename"></profilepic>
                                <img alt="" v-if="!data.imagename" :src="defaultimagepath" width="40" height="40" />
                            </div>
                            <div class="media-body">
                                <small class="text-muted mr-25">by</small>
                                <small><a href="javascript:void(0);" class="text-body">{{data.createdby?data.createdby.split('@')[0]:''}}</a></small>
                                <span class="text-muted ml-50 mr-25">|</span>
                                <small class="text-muted">{{dateFormat(data.createddate)}}</small>
                            </div>
                        </div>
                        <div class="my-1 py-25" style="overflow:hidden">
                                <div v-if="data.technology" class="capitalisetext badge badge-pill badge-light-info mr-50 mb-50">{{data.technology}}</div>
                                <div v-if="data.projectmodulename" class="badge badge-pill badge-light-danger mr-50 mb-50 capitalisetext mt-20">{{data.projectmodulename}}</div>
                                <div class="badge badge-pill badge-light-warning capitalisetext mr-50 mb-50">{{data.status}}</div>
                                <div  class="badge badge-pill badge-light-primary capitalisetext mb-50">{{data.sectionname}}</div>
                                <div  class="badge badge-pill badge-light-success capitalisetext mb-50">{{data.taskname}}</div>
                        </div>
                        <hr />  
                        <div class="d-flex justify-content-between align-items-center">
                            <a @click="redirecttourl('view',data)" href="javascript:void(0);" >
                                <div class="d-flex align-items-center sitebluecolor">
                                        <eye-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></eye-icon>
                                    <span class="text-body font-weight-bold sitebluecolor" >View Task</span>
                                </div>
                            </a>
                            <span>|</span>
                            <a @click="redirecttourl('create',data)" href="javascript:void(0);" >
                                <div class="d-flex align-items-center sitebluecolor">
                                        <plus-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></plus-icon>
                                    <span class="text-body font-weight-bold sitebluecolor" >Add Task</span>
                                </div>
                            </a>
                            <span>|</span>
                            <a 
                            @click="redirecttourl('view1',data)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor">
                                        <eye-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></eye-icon>
                                    <span class="text-body font-weight-bold sitebluecolor" >View Details</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
            <div v-if="companymasterlist.length == 0">
                <tr><td colspan="14" style="text-align: center;">No Records Found</td></tr>
            </div>
            <div class="row">
                <div class="col-12 my-2">
                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                </div>
            </div>
            </div>
        </div>
    </div>                        
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'

import Treeselect from '@riophae/vue-treeselect'
import { PlusSquareIcon,MessageSquareIcon,FolderIcon,PlusIcon,ChevronDownIcon,ChevronUpIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon } from 'vue-feather-icons'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import profilepic from '../profilepic.vue';
export default {
    name:'SectionModule',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        FolderIcon,
        PlusSquareIcon,
        ChevronUpIcon,
        ChevronDownIcon,
        VueTimepicker,MessageSquareIcon,
        profilepic
    },    
    data() {
        return {
            clickedTaskData: null,
            showbutton:false,
            isLoading: false,
            fullPage: true,
            defaultimagepath:apiUrl.defaultimagepath,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            workstepmodule:{
                sectionmasterid:null,
                sectionmasterlist:null,
                applicationmoduleid:null,
                applicationmodulelist:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                applicationid:null,
                applicationlist:null,
                functionalityname:null,
                functionalityid:null,
                appprojectdd:null,
                projectmoduleid:null,
                label:null,
                project:null,
                projectmodule:null,
                applicationname:null,
                applicationmodulename:null,
                sectionname:null,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                businesstaskid:null,
                tasklist:null,
                workstepname: null,
                technology:null,
                developmenthours: null,
                codereviewhours:null,
                infrasetupanddeploymenthours:null,
                qahours:null,
                iseditstate:false,
                taskname:null
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            showfunctionalities:true,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                workstepmodule:{
                    sectionmasterid:null,
                    applicationmoduleid:null,
                    projectid:null,
                    applicationid:null,
                    functionalityid:null,
                    businesstaskid:null,
                    workstepname: null,
                    technology:null,
                    developmenthours:null,
                    codereviewhours:null,
                    infrasetupanddeploymenthours:null,
                    qahours:null,
                    taskname:null
                },
            },
            valid:false,
            showdownicon:true,
            showupicon:false,
            showfunctionalities:true,
            functionalitylist:null,
            search: {
                projectMasterid: null,
                projectMasterOpts: [],
                projectModuleid: null,
                projectModuleOpts: [],
                applicationMasterid: null,
                applicationMasterOpts: [],
                applicationModuleid: null,
                applicationModuleOpts: [],
                sectionMasterid: null,
                sectionMasterOpts: [],
                functionalityMasterid : null,
                functionalityMasterOpts: [],
            },
            isSearchRequestActive : false,
            backpagedata:null,
            technologyBlured:false,
            tokendata:null,
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/workstep') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){       
        let permission = this.$Service.verifyAccess();
        if(permission){ 
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.tokendata = window.localStorage.getItem('token');
            }
            // this.functionalitylistdata('t47','');
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view1'|| this.$route.params.backbtnstate == 'view'|| this.$route.params.backbtnstate == 'add') {
                    this.showbutton=true;
                    this.backpagedata=this.$route.params.clickeddata
                    this.getworksteplist(this.$route.params.clickeddata)
                    this.getprojectmoduleapplications(this.$route.params.clickeddata)
                    this.clickedTaskData = this.$route.params.clickeddata
                }
            }else{
                this.getworksteplist([])
            }
        }
    },
    methods:{
        redirecttobreadkumb:function(objThis){
            // objThis.rowdata.backbtnstate=objThis.btnstate
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        redirecttourl:function(btnstate,rowdata){
            console.log(rowdata, '----rowdata')
            rowdata.backbtnstate=btnstate
            if(btnstate == 'edit') {
                this.$router.push({
                    path: '/workstep/update?workstepid='+rowdata.workstepid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if(btnstate == 'view1') {
                this.$router.push({
                    path: '/workstep/view?workstepid='+rowdata.workstepid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
            else if(btnstate == 'create') {
                this.$router.push({
                    name: 'TaskCreate',
                    path: '/tasks/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
            else if(btnstate == 'view') {
                this.$router.push({
                    name: 'TaskList',
                    path: '/tasks/list',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
            else if(btnstate == 'add') {
                this.$router.push({
                    name: 'wcreate',
                    // path: '/workstep/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        mysearchrequestollapse:function(){
            if(this.showfunctionalities == true){
                this.showupicon=true
                this.showdownicon=false
            }else if(this.showfunctionalities == false){
                this.showdownicon=true
                this.showupicon=false
            }
            this.showfunctionalities=!this.showfunctionalities
        },
        myfilterequestollapse: function() {
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        projectChange: function (node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                    } else {
                        this.search.projectMasterOpts = [];
                    }
                });
            }
        },
        functionalitylistdata:function(mdl,objThis){            
            this.isLoading=true
            this.inputappl = {
               useremail:this.undt.username,
               limit: apiUrl.LIMIT,
               offset: this.pageoffset,
            }
            //let apiURL='api/listing/getallfunctionality'
            let apiURL = 'api/projectapplication/getfunctionalitylist'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.functionalitylist=result.data.data
                }
            });
        },
        getDropdownData(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            // axios.post(apiUrl, { tbl: "t11" })
            axios({
            'method': 'POST',
            'url': apiUrl,
            tbl:"t11",
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data
                    } else {
                        this.search.projectMasterOpts = null
                    }
                })       
        },
        selprojectid(state, value) {
            this.search.projectModuleid = null
            this.search.applicationMasterid = null
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.projectMasterid = state;

            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
            } else {
                this.getDropdownApplicationdata("t12");
            }
        },
        selprojectModuleid(state, value) {
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.projectModuleid = state;

            if (!state && this.isAddState == false) {
                this.search.applicationModuleOpts = null;
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selApplicationid(state, value) {
            this.search.projectModuleid = null;
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.applicationMasterid = state;

            if (!state && this.isAddState == false) {
                this.search.projectModuleOpts = null;
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },
        selApplicationModuleid(state,value) {
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;

            if (!state && this.isAddState == false) {
                this.search.sectionMasterOpts = null;
            } else {
                this.getDropdownApplicationdata("t35");
            }
        },
        selSectionid(state, value) {
            this.search.functionalityMasterid = null;
            if (!state && this.isAddState == false) {
                this.search.functionalityMasterOpts = null;
            } else {
                this.getDropdownApplicationdata("t37");
            }
        },
        getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
            }
            if(mdl === "t36") { // projectmodulemaster
                this.input.applicationMasterid = this.search.applicationMasterid
            } else if(mdl === "t12") { // applicationmaster
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t34") { // applicationmodulemaster
                this.input.projectModuleid = this.search.projectModuleid
            } else if(mdl === "t35") { // sectionmaster
                this.input.applicationModuleid = this.search.applicationModuleid
            } else if(mdl === "t37") { // functionlitymaster
                this.input.sectionMasterid = this.search.sectionMasterid
            }
            // axios.post(apiUrl, this.input)
            axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = result.data.data;
                        } else if(mdl === "t37") {
                            this.search.functionalityMasterOpts = result.data.data;
                        }
                    } else {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = []
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = []
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = [];
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = [];
                        } else if(mdl === "t37") {
                            this.search.functionalityMasterOpts = [];
                        }
                    }
                })

        },
        getFilteredSectionData: function() {
            this.isLoading=true
            let uri = "api/projectapplication/getfunctionalitylist"
            this.input = {
                tbl: "t37",
                isSearch: false,
                section: "workstep",
                isFilterSelect: 1,
                isFilterPayload: {},
            }

            if(this.search.projectMasterid && this.search.projectMasterid != null) {
                this.input.isFilterPayload.projectMasterid = this.search.projectMasterid
            }
            if(this.search.applicationModuleid && this.search.applicationModuleid != null) {
                this.input.isFilterPayload.applicationModuleid = this.search.applicationModuleid
            }
            if(this.search.applicationMasterid && this.search.applicationMasterid != null) {
                this.input.isFilterPayload.applicationMasterid = this.search.applicationMasterid
            }
            if(this.search.projectModuleid && this.search.projectModuleid != null) {
                this.input.isFilterPayload.projectModuleid = this.search.projectModuleid
            }
            if(this.search.sectionMasterid && this.search.sectionMasterid != null) {
                this.input.isFilterPayload.sectionMasterid = this.search.sectionMasterid
            }
            if(this.search.functionalityMasterid && this.search.functionalityMasterid != null) {
                this.input.isFilterPayload.functionalityMasterid = this.search.functionalityMasterid
            }

            axios.post(uri, this.input)
                .then(result => {
                    this.isLoading = false
                    this.showfunctionalities = true
                    this.showupicon = false
                    this.showdownicon = true
                    this.showproject = false
                    if (result.data.errorCode == 0) {
                        this.functionalitylist = result.data.data;
                    } else {
                        this.functionalitylist = [];
                        Swal.fire({
                            title: "",
                            text: "No Data found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
        },
        resetRecords: function () {
            this.search.applicationModuleid = null;
            this.search.applicationMasterid = null;
            this.search.projectMasterid = null;
            this.search.projectModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.businessTaskMasterid = null;
            this.projectnameList = null;
            this.search.projectMasterOpts = null;
            this.search.businessTaskMasterOpts = null;
            this.getworksteplist({})
        },
        getworksteplist:function(rowdata){
            this.isLoading=true
            this.companymasterlist=[]
            this.clickedprojdata=rowdata
            this.input = {
                tbl:'t47',
                // functionalityobj:rowdata,
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
                useremail:this.undt.username,
                functionlitymasterid:rowdata.functionlitymasterid,
                projectMasterid: rowdata.projectMasterid,
                projectModuleid: rowdata.projectModuleid?rowdata.projectModuleid:rowdata.projectmoduleid,
                applicationMasterid: rowdata.applicationMasterid,
                applicationModuleid: rowdata.applicationModuleid,
                sectionMasterid: rowdata.sectionMasterid,
                functionalityMasterid: rowdata.functionalityMasterid?rowdata.functionalityMasterid:rowdata.functionlitymasterid,
                businessTaskMasterid: rowdata.businessTaskMasterid,
                uniqueid: this.undt.userid,
            }
            let url = 'api/workstep/list'
            this.workstepmodule.project=this.clickedprojdata.projectname
            this.workstepmodule.projectmodule=this.clickedprojdata.projectmodulename
            this.workstepmodule.appprojectdd = this.clickedprojdata.projectid
            this.workstepmodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.workstepmodule.applicationid = this.clickedprojdata.applicationid
            this.workstepmodule.applicationname = this.clickedprojdata.applicationname
            this.workstepmodule.applicationmodulename = this.clickedprojdata.applicationmodulename
            this.workstepmodule.sectionname = this.clickedprojdata.sectionname
            this.workstepmodule.sectionmasterid = this.clickedprojdata.sectionmasterid
            this.workstepmodule.functionalityname = this.clickedprojdata.functionlityname
            this.workstepmodule.functionalityid = this.clickedprojdata.functionlitymasterid
            this.workstepmodule.applicationmoduleid = this.clickedprojdata.applicationmoduleid
            this.workstepmodule.projectid = this.clickedprojdata.projectid
            this.workstepmodule.label = this.clickedprojdata.label
            this.workstepmodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.workstepmodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.workstepmodule.companyid=this.clickedprojdata.companyid
            this.getApplicationdata('t3','workstepmodule')
            this.getApplicationdata('t2','workstepmodule')
            this.getApplicationdata('t1','workstepmodule')
            this.getApplicationdata('t9','workstepmodule')
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false; 
                this.showfunctionalities=false
                
                if(result.data.errorCode == 0){
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    // this.mysearchrequestollapse()
                }else{
                    this.showproject=true;
                    this.totalcount = 0
                    // Swal.fire({
                    //     title: "",
                    //     text: "Project Application  data not found for : "+rowdata.sectionname,
                    //     icon: 'info',
                    //     customClass: {
                    //         confirmButton: "btn btn-primary"
                    //     },
                    //     buttonsStyling: !1
                    // })
                    // this.mysearchrequestollapse()
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);                
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getworksteplist(this.clickedprojdata)
            }
        },
        validText:function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text:function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        setSelected:function(value)
        {
            this.selected=value
        },
        selbtmid(state, value) {
            if(!state) {
                this.errors.workstepmodule.businesstaskid = "Business task name required"
            } else {
                this.errors.workstepmodule.businesstaskid = ""
            }
        },
        validateForm:function(mdl,objThis){
            this.isAddState=false
            var isValid=true;
            this.clearerrorForm();
            this.technologyBlured = true;
            if(mdl=="t47"){
                let hoursArr = [Boolean(this.workstepmodule.developmenthours), Boolean(this.workstepmodule.codereviewhours), Boolean(this.workstepmodule.infrasetupanddeploymenthours), Boolean(this.workstepmodule.qahours) ]
                isValid = hoursArr.some(time => time != false)
                if(!isValid) {
                    this.errors.workstepmodule.developmenthours = "Atleast one field is required";
                    this.errors.workstepmodule.codereviewhours = "Atleast one field is required";
                    this.errors.workstepmodule.codereviewhours = "Atleast one field is required";
                    this.errors.workstepmodule.infrasetupanddeploymenthours = "Atleast one field is required";
                    this.errors.workstepmodule.qahours = "Atleast one field is required";
                } else {
                    this.errors.workstepmodule.developmenthours = "";
                    this.errors.workstepmodule.codereviewhours = "";
                    this.errors.workstepmodule.codereviewhours = "";
                    this.errors.workstepmodule.infrasetupanddeploymenthours = "";
                    this.errors.workstepmodule.qahours = "";
                }
                if(!this.workstepmodule.sectionmasterid){
                    this.errors.workstepmodule.sectionmasterid="Section required";
                    isValid= false;
                }
                if(!this.workstepmodule.applicationmoduleid){
                    this.errors.workstepmodule.applicationmoduleid="Application Module required";
                    isValid= false;
                }
                if(!this.workstepmodule.projectid){
                    this.errors.workstepmodule.projectid="Project required";
                    isValid= false;
                }
                // if(!this.workstepmodule.applicationid){
                //     this.errors.workstepmodule.applicationid="Application required";
                //     isValid= false;
                // }
                if(!this.workstepmodule.functionalityid){
                    this.errors.workstepmodule.functionalityid="Functionality name required";
                    isValid= false;
                }
                if(!this.workstepmodule.businesstaskid){
                    this.errors.workstepmodule.businesstaskid="Business task name required";
                    isValid= false;
                }
                if(!this.workstepmodule.workstepname) {
                    this.errors.workstepmodule.workstepname = "Workstep name required"
                    isValid = false;
                }
                if(!this.workstepmodule.technology){
                    this.errors.workstepmodule.technology="Technology required";
                    isValid= false;
                }
            }
            return isValid
        },
        validateHHMM:function(str){
            //return /^([0-9]|0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9]|10[0-9]):[0-5][0-9]$/.test(str);
            return /^[0-9][0-9]:[0-5][0-9]$/.test(str);
        },
        validEmail:function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm:function(){
            this.errors= {
                workstepmodule:{
                    sectionmasterid:null,
                    applicationmoduleid:null,
                    projectid:null,
                    applicationid:null,
                    functionalityid:null,
                    businesstaskid:null,
                    technology:null,
                    developmenthours:null,
                    codereviewhours:null,
                    infrasetupanddeploymenthours:null,
                    qahours:null,
                },
            };
        },
        resetform:function(){
            this.workstepmodule.businesstaskid=null
            this.workstepmodule.technology=null
            this.workstepmodule.developmenthours=null
            this.workstepmodule.codereviewhours=null
            this.workstepmodule.infrasetupanddeploymenthours=null
            this.workstepmodule.qahours=null
        },
        openEditModule:function(currRowData){
            this.getApplicationdata('t9', 'workstepmodule')
            this.editRowData = currRowData
            this.workstepmodule.businesstaskid = currRowData.businesstaskid
            this.workstepmodule.technology = currRowData.technology
            this.workstepmodule.developmenthours = currRowData.developmenthours
            this.workstepmodule.codereviewhours = currRowData.codereviewhours
            this.workstepmodule.infrasetupanddeploymenthours = currRowData.infrasetupanddeploymenthours
            this.workstepmodule.qahours = currRowData.qahours
            this.workstepmodule.workstepname = currRowData.workstepname

            this.workstepmodule.project = currRowData.projectname
            this.workstepmodule.projectmodule= currRowData.projectmodulename
            /* this.workstepmodule.applicationname= currRowData.applicationname */
            this.getprojectmoduleapplications(currRowData)
            this.workstepmodule.applicationmodulename= currRowData.applicationmodulename
            this.workstepmodule.sectionname= currRowData.sectionname
            this.workstepmodule.functionalityname= currRowData.functionlityname
            this.workstepmodule.projecttypeid= currRowData.projecttypeid
            this.workstepmodule.companyid= currRowData.companyid
            this.workstepmodule.businessverticleid =currRowData.businessverticleid
            this.workstepmodule.sectionmasterid = currRowData.sectionmasterid
            this.workstepmodule.projectid = currRowData.projectid
            this.workstepmodule.applicationmoduleid = currRowData.applicationmoduleid
            this.workstepmodule.applicationid = currRowData.applicationid
            this.workstepmodule.functionalityid = currRowData.functionlitymasterid
            this.workstepmodule.taskname = currRowData.taskname
            this.workstepmodule.iseditstate = true 
            this.isAddState=true
        },
        getprojectmoduleapplications: function(rowdata){
            this.inputprojappl = {
                id:rowdata.projectmoduleid
            }
            let apiURL='api/projectmodule/getapplications'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.workstepmodule.applicationname=[]
                    // this.applicationmodule.applicationlist=result.data.data.data
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                            this.workstepmodule.applicationname.push(element.applicationname)
                        }
                    });
                this.workstepmodule.applicationname = this.workstepmodule.applicationname.toString()
                }else{
                    this.applicationmodule.applicationid=null
                }
            })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        saveData:function(tblNm,objThis){
            if( tblNm=="t47"){
                this.valid=this.validateForm(tblNm)
            }else{
                this.valid=false;
            }
            if (this.valid) {
                this.input = {}
                let apiURL='api/workstep/saveupdate'
                if(tblNm == 't47'){
                    this.input.sectionmasterid=this.workstepmodule.sectionmasterid
                    this.input.projectid=this.workstepmodule.projectid
                    this.input.projectmoduleid = this.workstepmodule.projectmoduleid
                    this.input.applicationmoduleid=this.workstepmodule.applicationmoduleid
                    this.input.applicationid=this.workstepmodule.applicationid
                    this.input.functionalityid=this.workstepmodule.functionalityid
                    this.input.businesstaskid=this.workstepmodule.businesstaskid
                    this.input.workstepname = this.workstepmodule.workstepname
                    this.input.technology=this.workstepmodule.technology
                    this.input.developmenthours=this.workstepmodule.developmenthours
                    this.input.codereviewhours=this.workstepmodule.codereviewhours
                    this.input.infrasetupanddeploymenthours=this.workstepmodule.infrasetupanddeploymenthours
                    this.input.qahours=this.workstepmodule.qahours
                    this.input.section='save'
                    this.input.actionby=this.undt.username
                } 
                if(this.workstepmodule.iseditstate == true){
                    this.input.section='update'
                    this.input.id=this.editRowData.workstepid
                }               
                this.isAddState=false
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        $('.close').click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                         this.rowdata={
                            label : this.workstepmodule.label,
                            projectname : this.workstepmodule.project,
                            projectmodulename : this.workstepmodule.projectmodule,
                            appprojectdd : this.workstepmodule.projectid,
                            projectmoduleid : this.workstepmodule.projectmoduleid,
                            projmodid : this.workstepmodule.projecmodid,
                            projmodname: this.workstepmodule.projmodname,
                            moduleid : this.workstepmodule.sectionmasterid,
                            applicationid : this.workstepmodule.applicationid,
                            applicationname : this.workstepmodule.applicationname,
                            applicationmodulename : this.workstepmodule.applicationmodulename,
                            sectionname : this.workstepmodule.sectionname,
                            applicationmoduleid : this.workstepmodule.applicationmoduleid,
                            projectid : this.workstepmodule.projectid,
                            label : this.workstepmodule.label,
                            projecttypeid : this.workstepmodule.projecttypeid,
                            businessverticleid : this.workstepmodule.businessverticleid,
                            companyid : this.workstepmodule.companyid
                        }
                        this.getworksteplist(this.rowdata)
                         this.isLoading = false
                        // this.getworksteplist({})
                        this.clearerrorForm();
                        // this.resetform();
                    }
                })
            }
        },
        selfmsectionmdldata:function(state,value){
            this.workstepmodule.sectionmasterid=null
            this.workstepmodule.sectionmasterid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.sectionmasterid="Section Required"
            }else{                
                this.errors.workstepmodule.sectionmasterid=null
                this.getfiltereddata('workstepmodule');
            }
        },
        selfmprojectdata:function(state,value){
            this.workstepmodule.projectid=null
            this.workstepmodule.projectid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.projectid="Project Required"
            }else{                
                this.errors.workstepmodule.projectid=null
            }
        },
        selfmapplmdldata:function(state,value){
            this.workstepmodule.applicationmoduleid=null
            this.workstepmodule.applicationmoduleid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.applicationmoduleid='Application Module required'
            }else{                
                this.errors.workstepmodule.applicationmoduleid=null
            }
        },
        selfmappmdldata:function(state,value){
            this.workstepmodule.applicationid=null
            this.workstepmodule.applicationid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.applicationid='Application required'
            }else{                
                this.errors.workstepmodule.applicationid=null
            }
        },
        selappapltype:function(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess:function(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
        getApplicationdata:function(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'workstepmodule'){
                this.inputappl = {
                    tbl:mdl
                }
                if(mdl=='t9'){
                    this.inputappl = {
                        projectid: this.workstepmodule.projectid,
                        empid:this.undt.userid,
                        useremail: this.undt.username,
                    }
                    apiURL='api/searching/getBusinessTaskbyProject'
                }
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't2'){
                        this.workstepmodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'workstepmodule'){
                            this.workstepmodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.workstepmodule.companylist=result.data.data.rows
                        return true;
                    }else if(mdl == 't9'){
                        this.workstepmodule.tasklist=result.data.data
                        return true;
                    }
                }
            })
            // return retResponse;
        },
        addmodule:function(){
            this.workstepmodule.iseditstate = false       
            this.resetform()
        }
    }
}
</script>