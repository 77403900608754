<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst" >
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Business Task Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" :multiple="true" :clear-on-select="true" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Status</label>
                      <treeselect class="projectmdllistdd capitalisetext" v-model="search.searchTaskStatus" :options="taskdefine.statuslist" @input="seltaskstatus"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                        <label for="allotedto">Assigned To</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4Char To Search Assigned To" :multiple="true" :clear-on-select="true" v-model="search.allotedto" :options="search.allotedtolist" @input="selassignedto" v-on:search-change="getEmpListByProjectAssigned"/>
                      </div>
                    </div>
                  
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="date_picker_foundeds">
                        <label for="">Task Assign Date From</label>
                        <div class="input-group input-group-merge">
                          
                           <date-picker  class="w-100" placeholder="Select Assign Date From" v-model="search.taskassigneddatefrom" valueType="format" @input="selectedstartdate" :disabled-date="notBeforeToday"></date-picker>
                           <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="date_picker_foundeds">
                        <label for="">Task Assign Date To</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select Assign Date To" v-model="search.taskassigneddateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore"  @input="selectedenddate"></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Reporting Manager Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Reporting Manager Name" v-model="search.mngrname" :options="mngrlist" v-on:search-change="getallmanagerlistnewapi"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Department</label>
                      <treeselect class="projectmdllistdd" placeholder="Select Department" v-model="search.searchDepartment" :options="deptlist"/>
                      </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getBusinessTaskReport(search)"> <i class="custom-class las la-at" style="font-size: 15px;"></i> <span>Mail Me</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                      <!-- <button type="move" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span></button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll" style="display:none;">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                   <div class="card-header p-2">
                    <h4 class="card-title">
                      Business Task Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div
                                class="table-responsive_sitcky"
                                v-if="showtabledata"
                              >
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno align-middle first-col-sticky" style="min-width:45px;">Sr#</th>
                                      <th scope="col" class="align-middle text-left second-col-sticky" style="min-width:180px;">Project Name</th>
                                      <th scope="col" class="align-middle text-left" style="min-width:200px;">Business Task Name</th>
                                      <th scope="col" class="align-middle">Department</th>
                                      <th scope="col" class="align-middle">Reporting Manager</th>
                                      <th scope="col" class="align-middle">Task Severity</th>
                                      <th scope="col" class="align-middle">Priority</th>
                                      <th scope="col" class="align-middle">Sub Priority</th>
                                      <th scope="col" class="align-middle">Business Stakeholder</th>
                                      <th scope="col" class="align-middle">Task Name</th>
                                      <th scope="col" class="align-middle">Task Status</th>
                                      <th scope="col" class="align-middle">Business Task Status</th>
                                      <th scope="col" class="align-middle">Start Date</th>
                                      <th scope="col" class="align-middle">Assign To</th>
                                      <th scope="col" class="align-middle">Assign Date</th>
                                      <th scope="col" class="align-middle">Close Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(data, index) in companymasterlist"
                                    >
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                        <td :class="'capitalisetext font-weight-normal text-left second-col-sticky cursorpointercls sitebluecolor unique'+index+1+currPageNum*pagelimit" @click="redirecttourl('projectId',data)">
                                          {{data.projectname}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal text-left">
                                          {{data.businesstaskname}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.department}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.reportingmanager ? data.reportingmanager:'NA'}}
                                        </td>
                                         <td class="font-weight-normal">
                                            <span v-if="data.taskkey.toLowerCase()=='key'" class="badge badge-pill badge-light-danger capitalisetext mr-50 my-50" style="display:block !important">{{data.taskkey}}</span>
                                            <span v-if="data.taskkey.toLowerCase()=='normal'" class="badge badge-pill badge-light-primary capitalisetext mr-50 my-50" style="display:block !important">{{data.taskkey}}</span>
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.priority}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.subpriority ? data.subpriority : 'NA'}}
                                        </td>
                                        <td class="font-weight-normal">
                                          {{data.businessstackholder}}
                                        </td>
                                         <td class="font-weight-normal">
                                          {{data.taskname}}
                                        </td>
                                        <td>
                                          <span v-if="data.taskstatus && data.taskstatus.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50 my-50" style=" display:block !important">{{data.taskstatus}}</span>
                                          <span v-if="data.taskstatus && data.taskstatus.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50 my-50" style=" display:block !important">{{data.taskstatus}}</span>
                                          <span v-if="data.taskstatus && data.taskstatus.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50 my-50" style=" display:block !important">{{data.taskstatus}}</span>
                                          <span v-if="data.taskstatus && data.taskstatus.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50 my-50" style=" display:block !important">{{data.taskstatus}}</span>
                                        </td>
                                        <td class="font-weight-normal">
                                          {{data.businesstaskstatus}}
                                        </td>
                                        <td class="text-nowrap font-weight-normal">
                                          {{dateFormat(data.taskstartdate)}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          {{data.allotedtoname}}
                                        </td>
                                        <td class="text-nowrap font-weight-normal" >
                                          {{dateFormat(data.taskassigndatetime)}}
                                        </td>
                                        <td class="font-weight-normal" v-if="data.taskstatus && data.taskstatus.toLowerCase() == 'closed'" style="text-align: center;">
                                          {{dateFormat(data.closeddate)}}
                                        </td>
                                        <td class="font-weight-normal" v-else style="text-align: center;">N/A</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
.mx-datepicker{
  width: calc(100% - 43px);
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { EyeIcon,XIcon,UserIcon,SearchIcon,ExternalLinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker,
    UserIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      project: {
        name: null,
        desc: null,
        projecttype: null,
        projecttypelist: [
          {
            id: "",
            label: "",
          },
        ],
        businesslist: [
          {
            id: "",
            label: "",
          },
        ],
        companylist: [
          {
            id: "",
            label: "",
          },
        ],
      },
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      fullPage: true,
      islistLoading:false,
      listfullPage:true,
      companymasterlist: [],
      stylevariation: apiUrl.stylevariation,
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      projectcurrentstate: "local",
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:true,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      tokendata:null,
      isBothDateSelected: false,
      search:{
          searchProjectname :null,
          searchTaskStatus:null,
          allotedto:null,
          allotedtolist:[],
          taskassigneddateto:null,
          taskassigneddatefrom:null,
          mngrname:null,
          searchDepartment :null,
      },
      mngrlist:[],
      deptlist:apiUrl.deptlist.sort((a, b) => a.label.localeCompare(b.label)),
      taskdefine: {
        statuslist: [{
          id:'open',
          label:'Open'
        },{
          id:'resolved',
          label:'Resolved'
        },{
          id:'closed',
          label:'Closed'
        }],
      },
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
     notBeforeToday: function (date) {
      this.search.taskassigneddateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.taskassigneddatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    getCompanyCreatedList: function () {
      axios
        .post("api/listing/getCreatedByList", { tbl: "t1",useremail: this.undt.username,empcode: this.undt.userid })
        .then((result) => {
          if (result.data.errorCode == 0) {
            this.search.createdbyOpts = result.data.data;
          }
        });
    },
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
        companymasterid: this.search.companyname,
        businessmasterid: this.search.businessname,
        empcode: this.undt.userid,
      }
      
      axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          if(result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = null
          }
        }).catch(e => {
        this.displayError(e)
      })
    },
    selectcompany(state, value) {
      this.search.companyname = state

      if(!state) {
        this.projectnameList = null
        if(this.search.businessname) {
          this.getDropdownApplicationdata('t11')
        }
      } else {
        this.getDropdownApplicationdata('t11')
      }

    },
    selectbusiness(state, value) {
      this.search.businessname = state
      if(!state) {
        this.projectnameList = null
        if(this.search.companyname) {
          this.getDropdownApplicationdata('t11')
        }
      } else {
        this.getDropdownApplicationdata('t11')
      }
    },
    selectproject(state, value) {
      this.pageoffset=1
      if(!state){
        console.log('no project selected')
      }else{
        this.search.searchProjectname = state;
        
      }
    }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    getSearchItems(search) {
        this.isSearchRequestActive = true
        this.search.searchProjectname = search.searchprojectname;
    },
    getallmanagerlistnewapi(node) {
      if (node && node.length>=3) {
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/searching/getallmanagerlistnewapi',
          'headers':{'authorization':this.tokendata},
          'data': {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            mngname: node.toLowerCase(),
          }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.mngrlist = result.data.data;
            let decryptdepartmenttext = this.mngrlist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.mngrlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    selectedstartdate(){
      if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    selectedenddate(){
       if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    getBusinessTaskReport(...search){
      let projectid=''
      let searchTaskStatus=''
      let allotedto=''
      let taskassigneddateto=''
      let taskassigneddatefrom=''
      let searchDepartment=''
      let setPage = ''
      if(search){        
        search.forEach(function(data){
          setPage = (!data.isPaginationreq ? 1 : '')
          
          projectid = (data.searchProjectname ? data.searchProjectname : '')
          
          searchTaskStatus = (data.searchTaskStatus ? data.searchTaskStatus : '')
          
          allotedto = (data.allotedto ? data.allotedto : '')
         
          taskassigneddateto = (data.taskassigneddateto?data.taskassigneddateto:'')
          taskassigneddatefrom = (data.taskassigneddatefrom?data.taskassigneddatefrom:'')
        })
      }
      if(this.search.searchProjectname || this.search.searchTaskStatus || this.search.allotedto || this.search.taskassigneddateto || this.search.taskassigneddatefrom || this.search.searchDepartment){
        projectid = (this.search.searchProjectname ? this.search.searchProjectname : '')
        
        searchTaskStatus = (this.search.searchTaskStatus ? this.search.searchTaskStatus : '')
       
        searchDepartment = (this.search.searchDepartment ? this.search.searchDepartment : '')
        allotedto = (this.search.allotedto ? this.search.allotedto : '')
       
        if(this.search.taskassigneddateto){
          let dateto = new Date(this.search.taskassigneddateto)
          dateto.setDate(dateto.getDate() +1)
          taskassigneddateto = moment(String(dateto)).format('YYYY-MM-DD');
        }
        taskassigneddatefrom = (this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:'')
      }
      if(setPage != ''){
        this.page=setPage
      }
      
      this.islistLoading = true;
      let url = "api/searching/getBusinessTaskReport";
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        mangerid:this.search.mngrname,
        empcode: this.undt.userid,
      };
      if(search.length > 0) {
          this.parentmoduledata = search
          localStorage.setItem('searchItems', JSON.stringify(search))
      }
      this.input.projectid = (projectid ? projectid : '')
      this.input.searchTaskStatus = (searchTaskStatus ? searchTaskStatus : '')
      this.input.allotedto = (parseInt(allotedto) ? allotedto : '')
      this.input.taskassigneddateto = (taskassigneddateto?taskassigneddateto:'')
      this.input.taskassigneddatefrom = (taskassigneddatefrom?taskassigneddatefrom:'')
      this.input.searchDepartment =  (searchDepartment ? searchDepartment : '')
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          Swal.fire({
            title: "Success!",
            text: "Export file link generated on your email",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
         else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (btnstate == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid
        })
      }
      // let redirectmdlnm='Project Module'
      // let redirectmdlurl='/projectmodule'
      // if(btnstate == 'addapplication'){
      //   redirectmdlnm='Project Application'
      //   redirectmdlurl='/application'
      // }
      // this.$router.push({
      //   name: redirectmdlnm,
      //   path: redirectmdlurl,
      //   params: {
      //     backbtnstate: btnstate,
      //     clickeddata: rowdata,
      //   },
      // });
    },
    getFilteredData: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "company",
        isFilterSelect: 1,
        isFilterPayload: {},
        useremail: this.undt.username,empcode: this.undt.userid,
      };

      if (this.search.searchCompany != null) {
        this.input.isFilterPayload.companyid = this.search.searchCompany;
      } 
      if (this.search.createdby != null) {
        this.input.isFilterPayload.createdby = this.search.createdby;
      }

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.showcompanies = true
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
    this.search.searchProjectname = null;
    this.search.searchTaskStatus = null;
    this.search.allotedto = null;
    this.search.taskassigneddateto = null;
    this.search.taskassigneddatefrom = null;
    this.search.mngrname=null
    this.search.searchDepartment = null;
    this.search.allotedtolist = [];
    this.mngrlist = []
    this.isBothDateSelected = false;
    let rowdata={}
    this.getBusinessTaskReport(rowdata);
        

    localStorage.removeItem('searchItems')
    },
    getcomapnylist: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "project",
        isFilterSelect: 0,
        isFilterPayload: {},
        useremail: this.undt.username,empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.parentmoduledata={}
        this.parentmoduledata.isPaginationreq=true
        this.getBusinessTaskReport(this.parentmoduledata);

      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    setSelected(value) {
      this.selected = value;
    },
    masterlist(mdl, objThis) {
      let setmoduleform = "projectmaster";
      let glbMdlLblval = "Project";
      if (this.glbst && mdl == "") {
        mdl = this.glbst;
      }
      $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
      this.companymasterlist = [];
      this.isProjectSel = 0;
      this.isProjectMsr = 0;
      this.viewprojectlist = 0;
      this.isProjectRoleSel = 0;
      this.isRoleAlloc = 0;
      let tblNm = "t11";
      if (mdl) tblNm = mdl;
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      this.input.isSearch = false;

      if (typeof objThis != "undefined" && objThis != "") {
        if (
          objThis.currentTarget.dataset.clkstate == "search" &&
          $("#glbsearchinpele").val() != ""
        ) {
          this.input.name = $("#glbsearchinpele").val().trim();
          this.input.isSearch = true;
        }
      }
      let url = "api/master/commonmaster/list";
      if (tblNm == "t11") {
        this.getApplicationdata("t3", "project-tab");
        this.getApplicationdata("t2", "project-tab");
        this.getApplicationdata("t1", "project-tab");
        url = "api/master/commonmaster/customselect";
        this.isProjectMsr = 1;
        this.input.section = "assignment";
        this.input.isFilterSelect = 0;
        this.input.isFilterPayload = {};
      }
      this.input.empcode = this.undt.userid,
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (
            this.isProjectSel == 1 ||
            this.isProjectMsr == 1 ||
            this.isProjectRoleSel == 1
          ) {
            
            this.companymasterlist = result.data.data.rows;
            this.totalcount = result.data.data.count;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          } else {
            this.companymasterlist = [];
            this.totalcount = 0;
          }
          
          setTimeout(function () {
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else {
          this.totalcount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
    getApplicationdata(mdl, curracttab) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      let apiURL = "api/master/commonmaster/dropdownlist";
      if (mdl == "t35") {
        apiURL = "api/master/commonmaster/customselect";
        this.inputappl.isFilterPayload = {};
        this.inputappl.isFilterSelect = 0;
        this.inputappl.isSearch = false;
        this.inputappl.section = "assignment";
      }
      if (
        curracttab == "project-tab" ||
        curracttab == "projectroleassign-tab"
      ) {
        this.inputappl = {
          tbl: mdl,
        };
      }
      this.inputappl.empcode = this.undt.userid,
      this.inputappl.useremail= this.undt.username
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectType = [];
          this.projectType = result.data.data.rows;
          let tmpClassNm = "";
          if (mdl == "t2") {
            tmpClassNm = "devBusinessVerticleData";
            this.project.businesslist = result.data.data.rows;
            this.search.businessnameList = result.data.data.rows;
            return true;
          }else if (mdl == "t1") {
            tmpClassNm = "companyname";
            this.project.companylist = result.data.data.rows;
            this.search.companynameList = result.data.data.rows
            return true;
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
      
    },
    seltaskstatus(state, value) {
      if(!state) {
        console.log('Task status required')
      } else {
        console.log('selected')
      }
    },  
    getEmpListByProjectAssigned(node) {
      if(node && node.length >=3){
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.allotedtolist = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    selassignedto(state, value) {
      if(!state) {
        this.search.allotedto=null
      } else {
        this.search.allotedto=state
      }
    },

ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      let assignedate =""
      if(this.search.taskassigneddateto){
          assignedate = new Date(this.search.taskassigneddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input = {
        limit:null,
        offset:null,
        searchTaskStatus:this.search.searchTaskStatus?this.search.searchTaskStatus:'',
        allotedto:this.search.allotedto?this.search.allotedto:'',
        taskassigneddatefrom:this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:'',
        
        taskassigneddateto:assignedate,
        searchDepartment:this.search.searchDepartment?this.search.searchDepartment:'',
        projectid:this.search.searchProjectname?this.search.searchProjectname:'',
        mangerid:this.search.mngrname?this.search.mngrname:null,
        useremail: this.undt.username?this.undt.username:'',
        empcode : this.undt.userid,
      };
      axios({
        method: "POST",
        url: `api/searching/getBusinessTaskReport`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'BusinessTaskReportDetails_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Project Name,Business Task Name, Department, Reference, Reporting Manager,Task Severity,Priority, Sub Priority, Businesss Stakeholder, Task Name, Task Status, Business Task Status, Start Date, Assign To, Assign Date, Close Date, Tech WIP Date, Estimate Start Date, Estimate End Date, Efforts Hours' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let projectname = index.hasOwnProperty('projectname') ? index.projectname.replace(/"/g, '""') : '';
          let businesstaskname = index.hasOwnProperty('businesstaskname') ? index.businesstaskname.replace(/"/g, '""') : '';
          let department = index.hasOwnProperty('department') ? index.department : '';
          // let department = index.department ? (index.department) : ''

          let reference = index.hasOwnProperty('reference') ? index.reference.replace(/"/g, '""') : '';
          let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager == null?'' :index.reportingmanager : '';
          let taskkey = index.hasOwnProperty('taskkey') ? index.taskkey : '';
          let priority = index.hasOwnProperty('priority') ? index.priority : '';
          let subpriority = index.hasOwnProperty('subpriority') ? index.subpriority : '';
          let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder : '';
          let taskname = index.hasOwnProperty('taskname') ? index.taskname : '';
          let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus : '';              
          let businesstaskstatus = index.hasOwnProperty('businesstaskstatus') ? index.businesstaskstatus : '';              
          let taskstartdate = index.hasOwnProperty('taskstartdate') ? index.taskstartdate : '';
          let allotedtoname = index.hasOwnProperty('allotedtoname') ? index.allotedtoname : '';
          let taskassigndatetime = index.hasOwnProperty('taskassigndatetime') ? index.taskassigndatetime : '';
          let closeddate = index.hasOwnProperty('closeddate') ? index.closeddate : '';
          let lmdate = index.hasOwnProperty('lmdate') ? index.lmdate : '';
          taskassigndatetime = this.dateMONFormat(taskassigndatetime);
          taskstartdate = this.dateMONFormat(taskstartdate);
          closeddate = this.dateMONFormat(closeddate);
          lmdate = this.dateMONFormat(lmdate);

          let startdate = index.hasOwnProperty('startdate') ? index.startdate : '';
          let estimatedddate = index.hasOwnProperty('estimatedddate') ? index.estimatedddate : '';
          let estimatedhours = index.hasOwnProperty('estimatedhours') ? index.estimatedhours : '';

          estimatedhours = (!estimatedhours) ? 'NA' : estimatedhours;
          estimatedddate = this.dateMONFormat(estimatedddate)
          startdate = this.dateMONFormat(startdate)
          row += '"' + srno + '",',
            row += '"' + projectname + '",',
            row += '"' + businesstaskname + '",',
            row += '"' + department + '",',
            row += '"' + reference + '",',
            row += '"' + reportingmanager + '",',
            row += '"' + taskkey + '",',
            row += '"' + priority + '",',
            row += '"' + subpriority + '",',
            row += '"' + businessstackholder + '",',
            row += '"' + taskname + '",',
            row += '"' + taskstatus + '",',
            row += '"' + businesstaskstatus + '",',
            row += '"' + taskstartdate + '",',
            row += '"' + allotedtoname + '",',
            row += '"' + taskassigndatetime + '",',
            row += '"' + closeddate + '",'
            row += '"' + lmdate + '",'
            row += '"' + startdate + '",' 
            row += '"' + estimatedddate + '",'
            row += '"' + estimatedhours + '",'
              row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'BusinessTaskReportDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });  
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
  },
};
</script>
