<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-sm-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Program Manager Project Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                        <label class="form-label" for="roles">Business Task Tracker</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Role" :options="managersList" v-model="search.roleId" @input="getBSHlist" />
                      <div class="errorinputmsg" v-if="!search.roleId">Please select tracker.</div>                        
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1" id="projectdropdown">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Project Name" v-model="search.searchProjectname" :multiple="true" :options="projectnameList" @input="selectproject"/>
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0" id="hoddropdown" style="display:none;">
                        <label class="form-label" for="bsh">Stakeholder Name</label>
                        <treeselect class="projroleassprojectdd" placeholder="Select Stakeholder Name " :options="bshlist" v-model="search.bsh" />
                    </div>

                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="date_picker_foundeds">
                        <label for="">Date From</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select Logtime Date From" v-model="search.datefrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                          <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <div class="date_picker_foundeds">
                        <label for="">Date To</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select Logtime Date To" v-model="search.dateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 text-right mb-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1 mobile_margin-px-2"  :disabled="isBothDateSelected" @click="getPGMReportDetails(true,search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1 mobile_margin-px-2" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2 mr-1 mobile_margin-px-2"  :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Program Manager Project Details 
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home"  role="tabpanel"  aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky"  v-if="showtabledata">
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Program Manager Project Details ">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="align-middle first-col-sticky" style="min-width:140px;">Duration</th>
                                      <th scope="col" class="align-middle">
                                       <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#cumullative-id">
                                              Product Pipeline
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">
                                      <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#devpipeline-id">
                                              Dev Pipeline
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">
                                      <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#wip-id">
                                              WIP
                                            </button>
                                        </span>
                                      </th>
                                     
                                      <th scope="col" class="align-middle">
                                      <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#uat_live_id">
                                              UAT + Live
                                            </button>
                                        </span> </th>
                                        
                                      <th scope="col" class="align-middle">
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#estimated_time_id">
                                              Estimated Time
                                            </button>
                                        </span>
                                      </th>
                                      <th scope="col" class="align-middle">
                                      <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#spent_time">
                                              Spent Time
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                      <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#adjestment_time">
                                              Number Of Adjustments Made To The Schedule
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                       <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#ontimetask_time">
                                             Ontime Task Ratio
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                      <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#number_of_errors">
                                             Number Of Errors
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                      <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#effort_variance">
                                             Effort Variance
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                       <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#shedule_variance">
                                             Schedule Variance
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#delayed_number_task">
                                             Delayed Number of Tasks
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                        <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#number_cancelled_task">
                                             Number Of Cancelled Tasks
                                            </button>
                                        </span>
                                       </th>
                                       
                                      <th scope="col" class="align-middle">
                                         <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#number_of_change_requests">
                                             Number Of Change Requests
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                         <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#utilization_Percentage">
                                             Utilization Percentage
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                       <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#productions_issues">
                                             Production issues
                                            </button>
                                        </span>
                                      </th>
                                      
                                      <th scope="col" class="align-middle">
                                         <span class="popover-block-container-custom">
                                         <button class="popover_top_note pgmreport_header_text" data-toggle="popover" data-placement="top" data-container="body" data-original-title="Note" data-popover-content="#escalations">
                                            Escalations
                                            </button>
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(pgmdata, index) in companymasterlist">
                                      <tr>
                                          <td colspan="20" style="text-align:left; background-color:#F3F2F7;" class="cursorpointercls sitebluecolor" @click="redirecttourl('projectId',pgmdata)"><strong class="hod_table_pl-1">{{index}} </strong></td>
                                      </tr>
                                      <tr v-for="(data,i) in pgmdata" v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td :class="'capitalisetext font-weight-normal first-col-sticky unique'+index+1+currPageNum*pagelimit">
                                            {{dateMonth(data.periodfrom)}} - {{dateMonth(data.periodto)}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                            <span>{{data.productpipeline}}</span>
                                          </td>
                                         <td class="capitalisetext font-weight-normal">
                                          <span>{{data.techpipeline}}</span>
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                            <span>{{data.techwip}}</span>
                                          </td>
                                         <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.livecount)" style="cursor:pointer;" @click="getCSV(data, index, 'uatlive')">{{data.livecount}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.estimatedhrs)">{{data.estimatedhrs, 'estimatedhours'}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.spenthrs)" style="cursor:pointer;" @click="getCSV(data, index, 'spenttime')">{{data.spenthrs}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          <span>{{data.noofadjustment}}</span>  
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.onetimetaskratio)" style="cursor:pointer;" @click="getCSV(data, index, 'ontimetask')">{{data.onetimetaskratio}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span>{{data.qcerrors}}</span>  
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.effortvarience)" style="cursor:pointer;" @click="getCSV(data, index, 'effortvarience')">{{data.effortvarience}}</span> 
                                          <span v-else>0</span> 
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.schedulevarience)" style="cursor:pointer;" @click="getCSV(data, index, 'schedulevarience')">{{data.schedulevarience}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.delayedtask)" style="cursor:pointer;" @click="getCSV(data, index, 'delayedtask')">{{data.delayedtask}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span>{{data.cancelledtask}}</span>  
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span>{{data.changerequest}}</span>  
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.utilizationperc)" style="cursor:pointer;" @click="getCSV(data, index, 'totalspenthrs')">{{data.utilizationperc}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span>{{data.prodissues}}</span>  
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          <span v-if="parseInt(data.escalation)" style="cursor:pointer;" @click="getCSV(data, index, 'totalweektask')">{{data.escalation}}</span>  
                                          <span v-else>0</span>
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0 && isresponce">
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                           <div id="cumullative-id" style="display:none;">
                              <div class="popover-body">
                                  
                                   <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Cumullative count of BTC where taskstatus is Product pipeline </span> 
                                  </div>

                              </div>
                            </div>
                            
                            <div id="devpipeline-id" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Cumullative count of BTC where taskstatus is Dev Pipeline </span> 
                                  </div>
                              </div>
                            </div>
                            
                            <div id="wip-id" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Cumullative count of BTC where taskstatus is WIP </span> 
                                  </div>
                              </div>
                            </div>
                            
                            <div id="uat_live_id" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Timebound count  of BTC where status is UAT or Live </span> <hr class="hr_border_bottom_note" />
                                  <span class="text-card"> Where UAT date or Live date is within given week</span> 
                                  </div>
                              </div>
                            </div>
                            
                            <div id="estimated_time_id" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Sum of efforts estimates for all BTC that moved to Live or UAT </span>  
                                  </div>
                              </div>
                            </div>
                            
                            <div id="spent_time" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card">  2 seprate coulmns for each Dev and QA 
                                  </span> <hr class="hr_border_bottom_note" />
                                  <span class="text-card"> 
                                  Sum of time spent on all the (BTC, BTC.tasks, BTC.Issues) that vent live Or UAT
                                </span>  
                                  </div>
                              </div>
                            </div>
                            
                            <div id="adjestment_time" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> If status is WIP on create or edit page then make start date and end date compulsory. baseline date store it permanentry and don't overwrite. where as store new date as actual end date if revision made to planed end date 
                                  </span> <hr class="hr_border_bottom_note" />
                                  <span class="text-card"> 
                                  For a BTC Check in log enddate and count the distinct end dates
                                </span>  
                                  </div>
                              </div>
                            </div>
                            
                            <div id="ontimetask_time" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> total task moved to UAT = count tasktatus = UAT or task task status = Live where last UAT date or Live date   is within 'Given week' 
                                  </span> <hr class="hr_border_bottom_note" />
                                  <span class="text-card"> 
                                  On time = if UAT date or live date is less than or equal to Enddate..
                                  <hr class="hr_border_bottom_note" />
                                  Count such BTC as On time
                                </span>  
                                  </div>
                              </div>
                            </div>
                            
                            <div id="number_of_errors" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Count of issues all the BTC given in F4 cell (UAT + Live 
                                  
                                </span>  
                                  </div>
                              </div>
                            </div>
                            
                            <div id="effort_variance" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Actual efforts= timespent of BTC, BTC.tasks, BTC.issues
                                    <hr class="hr_border_bottom_note" />
                                  Planned efforts = Efforts estimates entered by PM
                                  
                                </span>  
                                  </div>
                              </div>
                            </div>
                            
                            <div id="shedule_variance" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> (+) varriance :If task flag is delayed, number of days from actual end date to UAT date or Live date (-) varriance: if task flag is Ontime then number of days from actual date to UAT date or Live date
                                    <hr class="hr_border_bottom_note" />
                                  Sum of (+) and (-)
                                  
                                </span>  
                                  </div>
                              </div>
                            </div>
                            
                            <div id="delayed_number_task" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> flag BTC as delayed if actual enddate is > UAT date or Live date, count of BTC where flag = delayed.
                                  
                                </span>  
                                  </div>
                              </div>
                            </div>
                            <div id="number_cancelled_task" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Count(BTC) Where taskstatus = discarded
                                  
                                </span>  
                                  </div>
                              </div>
                            </div>
                            <div id="number_of_change_requests" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Count tasktype = CR
                                  
                                </span>  
                                  </div>
                              </div>
                            </div>
                            <div id="utilization_Percentage" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> *** tentative column 
                                    <hr class="hr_border_bottom_note" />
                                    Number of resouce = all resources allocated in roleallocation  Deno= Time spent in hours by all resources Numerator= number of resources * 8*5 
                                    <hr class="hr_border_bottom_note" />
                                    <strong> Total time spent on BTC or Subtask or Issue / total number of assingee * 8*5. </strong>

                                  
                                </span>  
                                  </div>
                              </div>
                            </div>
                            <div id="productions_issues" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Count of issues if issue type is 'live issue' or Incident || created within given week range.
                                 </span>  
                                </div>
                              </div>
                            </div>
                            <div id="escalations" style="display:none;">
                              <div class="popover-body">
                                <div class="popover_top_heading"> 
                                   <h2> Definations :</h2>
                                  <span class="text-card"> Count of escalltions created within Given week.
                                 </span>  
                                </div>
                              </div>
                            </div>
                      <div class="pageCenter pt-2 text-center">
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import { EyeIcon,SearchIcon,XIcon,ExternalLinkIcon,UserIcon } from "vue-feather-icons";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "PGMReport",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker,
    UserIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      isBothDateSelected:false,
      projectnameList:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      search:{
          searchProjectname :null,
          searchTaskStatus:null,
          allotedto:null,
          allotedtolist:[],
          dateto:null,
          datefrom:null,
          mngrname:null,
          bsh: null,
          roleId: 'Project',
      },
      managersList: [{
          id : 492,
          label: 'Engineering Manager',
        },
        { 
          id: 'Project',
          label: 'Project',
        },
        {
          id : 477,
          label: 'Project Manager',
        },
        /*{
          id : 503,
          label: 'Program Manager',
        }*/
      ],
      headingLabel: 'Project Name',
      bshlist : [],
      tokendata:null,
      mngrlist:[],
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.getBSHlist('Project');
        this.getPGMReportDetails()
        this.getProjectAllocatedToEmp();
      }
    }
    $("[data-toggle=popover]").popover({
    html: true,
    trigger: "focus",
    content: function () {
      let content = $(this).attr("data-popover-content");
      return $(content).children(".popover-body").html();
    }
    });
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getBSHlist(dept) {
      if(dept==undefined){
        this.isBothDateSelected = true;
        $('#projectdropdown').hide();
        $('#hoddropdown').hide();
      } else {
        this.isBothDateSelected = false;
      }
      if(dept=='Project'){
        this.headingLabel = 'Project Name';
        $('#projectdropdown').show();
        $('#hoddropdown').hide();
      }else if(dept == 477 || dept == 492 || dept == 503){
        $('#projectdropdown').hide();
        $('#hoddropdown').show();

        if(dept == 477){
          this.headingLabel = 'Project Manager';
        }
        if(dept == 492){
          this.headingLabel = 'Engineering Manager';
        }
        if(dept == 503){
          this.headingLabel = 'Program Manager';
        }
     
        this.search.bsh=null
        axios({
          method: "POST",
          url: "api/listing/getStakeHoldersList",
          data: { roleid: dept,useremail: this.undt.username,empcode: this.undt.userid },
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.bshlist = result.data.data;
          } else {
            this.bshlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    notBeforeToday: function (date) {
      this.search.dateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.datefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.pageoffset=1
      if(!state){
        console.log('no project selected')
      }else{
        this.search.searchProjectname = state;
      }
    }, 
    getProjectAllocatedToEmp() {
      this.isLoading = true
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage: "listingpage"
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers': { 'authorization': this.tokendata }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectnameList = result.data.data;
          localStorage.setItem('projectnameList', JSON.stringify(result.data.data))
        } else {
          this.projectnameList = [];
          localStorage.removeItem('projectnameList')
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    dateMonth(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM');
    },
   selectedstartdate(){
      if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
        this.isBothDateSelected = false
      }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
        this.isBothDateSelected = true
      }
    },
    selectedenddate(){
       if((this.search.datefrom && this.search.dateto) || (!this.search.datefrom && !this.search.dateto)){
        this.isBothDateSelected = false
      }else if((this.search.datefrom && !this.search.dateto) ||( !this.search.datefrom && this.search.dateto)){
        this.isBothDateSelected = true
      }
    },
    getPGMReportDetails(flag = false,...search){
      if(flag) {
        this.pageoffset = 1
        this.companymasterlist = []
        this.totalcount = 0;
        this.pageCount = 0;
        this.page= 1;
      } 
        
        this.isLoading = true;
        let url = "api/btcreport/pgmreport";
        this.input = {
            offset: this.pageoffset,
            limit: apiUrl.LIMIT,
            useremail: this.undt.username,
            empid: this.undt.userid,
            trackername: this.search.bsh?String(this.search.bsh.toLowerCase()):null,
            roleId: this.search.roleId?String(this.search.roleId): 'Project',
            empcode: this.undt.userid,
        };
        let assignedate =""
      if(this.search.dateto){
        assignedate = new Date(this.search.dateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
      this.input.datefrom = this.search.datefrom?this.search.datefrom:null
      this.input.dateto = this.search.dateto?assignedate:null

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true

          const groupByCategory = result.data.data.rows.reduce((group, product) => {
            const { projectname } = product;
            group[projectname] = group[projectname] ?? [];
            group[projectname].push(product);
            return group;
          }, {});

          this.companymasterlist = groupByCategory;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
        this.search.searchProjectname = null;
        this.search.allotedto = null;
        this.search.dateto = null;
        this.search.datefrom = null;
        this.search.mngrname=null;
        this.search.bsh=null;
        this.search.roleId='Project';
        this.isBothDateSelected = false;
        let rowdata={}
        this.getPGMReportDetails(false,rowdata);
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.parentmoduledata={}
        this.parentmoduledata.isPaginationreq=true
        this.getPGMReportDetails(false,this.parentmoduledata);

      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMONFormat(value){
      if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    },
    timeToDecimal(t) {
      let arr = t.split(':');
      let dec = parseInt((arr[1]/6)*10, 10);
        return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
    },
    dateFormat1(value) {
      if (!value) return "";
      return moment(String(value)).format('YYYY-MM-DD');
    },
    getCSV(data, stakeholdername, reportname){
        this.isLoading = true;
        this.exportxlsdata = [];
        this.exporterr = "";
        this.input = {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            projectid: data.projectid,
            periodfrom: this.dateFormat1(data.periodfrom),
            periodto: this.dateFormat1(data.periodto),
            stakeholdername: stakeholdername,
            reportname: reportname,
            trackername: this.search.roleId?String(this.search.roleId): 'Project',
        };
        axios({
          method: "POST",
          url: `api/btcreport/pgmreportexport`,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            let filename = stakeholdername;
            this.downloadexport(result.data.data.rows,filename)
          } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        }).catch(e => {
        this.displayError(e)
      });
      },
      downloadexport(readExportdata, filename) {
          if (readExportdata.length > 0) {
            let ShowLabel = 'pgmreport'+filename
            let JSONData = readExportdata
            let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            let CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#, BusinessTask Code, Business Task Name, HOD Name, Engineering Manager, Project Manager, Task Manager, Task Owner, Project, Department, Reference, Task Severity, Priority, Sub Priority, Redmine ID, Task Status, Task Type, Created by, Created date, Last Modified Date, Last Modified By, Due date, Estimate Start Date, Estimated End Date, Efforts Hours, Total Estimated Hours, Total Spent Hours, Ballpark Estimate in days, Product Pipeline Date, Product WIP Date, Product Completed Date, Product WIP 2 Date, Tech Business Review Date, Tech Pipeline Date, Tech WIP Date, QA Pipeline Date, QA WIP Date, UAT Date, UAT Signoff Date, UAT Feedback Date, Live Date, Completed Date, On Hold Date, Discarded Date, Period From, Period To' +'\r\n';
            }
            let row = ''
            for (let i=0; i<arrData.length; i++) {
              let index = arrData[i]
              let srno = i+1
              let totalspenthrs = index.hasOwnProperty('totalspenthrs') ? index.totalspenthrs : '';
              let totalestimatedhours = index.hasOwnProperty('totalestimatedhours') ? index.totalestimatedhours : '';
              let periodfrom = index.hasOwnProperty('periodfrom') ? index.periodfrom : '';
              let periodto = index.hasOwnProperty('periodto') ? index.periodto : '';
              let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode : '';
              let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder : '';
              let engmanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager : '';
              let projectmanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager : '';
              let taskname = index.hasOwnProperty('taskname') ? index.taskname.replace(/"/g, '""') : '';
              let productstackholder = index.hasOwnProperty('productstackholder') ? index.productstackholder : '';
              let taskowner = index.hasOwnProperty('taskowner') ? index.taskowner : '';
              let projectnameist = index.hasOwnProperty('projectnameist') ? index.projectnameist : '';
              // let department = index.hasOwnProperty('department') ? index.department : '';
              let department = index.department ? this.decryptText(index.department) : ''

              let taskkey = index.hasOwnProperty('taskkey') ? index.taskkey : '';
              let priority = index.hasOwnProperty('priority') ? index.priority : '';
              let subpriority = index.hasOwnProperty('subpriority') ? index.subpriority : '';
              let redmineid = index.hasOwnProperty('redmineid') ? index.redmineid == null ? '' : index.redmineid : ''; 
              let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus : '';              
              let tasktype = index.hasOwnProperty('tasktype') ? index.tasktype : '';
              let createdby = index.hasOwnProperty('createdby') ? index.createdby.split('@')[0] : '';
              let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
              let reference = index.hasOwnProperty('reference') ? index.reference : '';
              let startdate = index.hasOwnProperty('startdate') ? index.startdate : '';
              let due_date = index.hasOwnProperty('due_date') ? index.due_date : '';
              let estimatedddate = index.hasOwnProperty('estimatedddate') ? index.estimatedddate : '';
              let estimatedhours = index.hasOwnProperty('estimatedhours') ? index.estimatedhours : '';
              let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate : '';
              let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby.split('@')[0] : '';
              let taskestimates = index.hasOwnProperty('taskestimates') ? index.taskestimates : '';
              
              let techpipelinedate = index.hasOwnProperty('techpipelinedate') ? index.techpipelinedate : '';
              let productpipelinedt = index.hasOwnProperty('productpipelinedt') ? index.productpipelinedt : '';
              let productwipdt = index.hasOwnProperty('productwipdt') ? index.productwipdt : '';
              let productcompleteddt = index.hasOwnProperty('productcompleteddt') ? index.productcompleteddt : '';
              let techbusinessreviewdt = index.hasOwnProperty('techbusinessreviewdt') ? index.techbusinessreviewdt : '';
              let uatdate = index.hasOwnProperty('uatdate') ? index.uatdate : '';
              let livedate = index.hasOwnProperty('livedate') ? index.livedate : '';
              let completeddate = index.hasOwnProperty('completeddate') ? index.completeddate : '';
              let productwip2dt = index.hasOwnProperty('productwip2dt') ? index.productwip2dt : '';
              let qapipelinedt = index.hasOwnProperty('qapipelinedt') ? index.qapipelinedt : '';
              let qawipdt = index.hasOwnProperty('qawipdt') ? index.qawipdt : '';
              let uatsignoffdt = index.hasOwnProperty('uatsignoffdt') ? index.uatsignoffdt : '';
              let uatfeedbackdt = index.hasOwnProperty('uatfeedbackdt') ? index.uatfeedbackdt : '';
              let onholddt = index.hasOwnProperty('onholddt') ? index.onholddt : '';
              let discardeddt = index.hasOwnProperty('discardeddt') ? index.discardeddt : '';
              let techwipdate = index.hasOwnProperty('techwipdt') ? index.techwipdt : '';

              estimatedhours = estimatedhours?this.timeToDecimal(estimatedhours):null;
              estimatedhours = (!estimatedhours) ? 'NA' : estimatedhours;
              createddate = this.dateMONFormat(createddate)
              estimatedddate = this.dateMONFormat(estimatedddate)
              startdate = this.dateMONFormat(startdate)
              due_date = this.dateMONFormat(due_date)
              lastmodifieddate = this.dateMONFormat(lastmodifieddate);
              
              techpipelinedate = this.dateMONFormat(techpipelinedate);
              techwipdate = this.dateMONFormat(techwipdate);
              productpipelinedt = this.dateMONFormat(productpipelinedt);
              productwipdt = this.dateMONFormat(productwipdt);
              productcompleteddt = this.dateMONFormat(productcompleteddt);
              techbusinessreviewdt = this.dateMONFormat(techbusinessreviewdt);
              uatdate = this.dateMONFormat(uatdate);
              livedate = this.dateMONFormat(livedate);
              completeddate = this.dateMONFormat(completeddate);
              productwip2dt = this.dateMONFormat(productwip2dt);
              qapipelinedt = this.dateMONFormat(qapipelinedt);
              qawipdt = this.dateMONFormat(qawipdt);
              uatsignoffdt = this.dateMONFormat(uatsignoffdt);
              uatfeedbackdt = this.dateMONFormat(uatfeedbackdt);
              onholddt = this.dateMONFormat(onholddt);
              discardeddt = this.dateMONFormat(discardeddt);
              periodfrom = this.dateMONFormat(periodfrom);
              periodto = this.dateMONFormat(periodto);

              row += '"' + srno + '",',
              row += '"' + businesstaskcode + '",', 
              row += '"' + taskname + '",', 
              row += '"' + businessstackholder + '",'
              row += (engmanager==null) ? "NA," : '"' + engmanager + '",'
              row += (projectmanager==null) ? "NA," : '"' + projectmanager + '",'
              row += (productstackholder==null) ? "NA," : '"' + productstackholder + '",'
              row += (taskowner==null) ? "NA," : '"' + taskowner + '",'
              row += '"' + projectnameist + '",',
              row += '"' + department + '",',
              row += '"' + reference + '",'
              row += '"' + taskkey + '",',
              row += (priority==null) ? "NA," : '"' + priority + '",' 
              row += (subpriority==null) ? "NA," : '"' + subpriority + '",'
              row += '"' + redmineid + '",',         
              row += '"' + taskstatus + '",',
              row += '"' + tasktype + '",',
              row += '"' + createdby + '",',
              row += '"' + createddate + '",' 
              row += '"' + lastmodifieddate + '",'
              row += '"' + lastmodifiedby + '",'
              row += '"' + due_date + '",' 
              row += '"' + startdate + '",' 
              row += '"' + estimatedddate + '",'
              row += '"' + estimatedhours + '",'
              row += (totalestimatedhours=='null') ? "NA," : '"' + totalestimatedhours + '",'
              row += (totalspenthrs=='null') ? "NA," : '"' + totalspenthrs + '",'
              row += (taskestimates == null) ? "NA," : '"'+ taskestimates + '",'
              row += '"' + productpipelinedt + '",'
              row += '"' + productwipdt + '",'
              row += '"' + productcompleteddt + '",'
              row += '"' + productwip2dt + '",'
              row += '"' + techbusinessreviewdt + '",'
              row += '"' + techpipelinedate + '",'
              row += '"' + techwipdate + '",'
              row += '"' + qapipelinedt + '",'
              row += '"' + qawipdt + '",'
              row += '"' + uatdate + '",'
              row += '"' + uatsignoffdt + '",'
              row += '"' + uatfeedbackdt + '",'
              row += '"' + livedate + '",'
              row += '"' + completeddate + '",'
              row += '"'+ onholddt +'",'
              row += '"' + discardeddt + '",'
              row += '"' + periodfrom + '",', 
              row += '"' + periodto + '",', 
              row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            let fileName = 'PGMReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            let link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report Successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });      
        }
      },
    selassignedto(state, value) {
      if(!state) {
        this.search.allotedto=null
      } else {
        this.search.allotedto=state
      }
    },
    ExportXL() {
      this.exportxlsdata = [];
      this.exporterr = "";
      this.isLoading = true;
      let url = "api/btcreport/pgmreport";
      this.input = {
          offset: null,
          limit: null,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          trackername: this.search.bsh?String(this.search.bsh.toLowerCase()):null,
          roleId: this.search.roleId?String(this.search.roleId): 'Project',
      };
      let assignedate =""
      if(this.search.dateto){
        assignedate = new Date(this.search.dateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
      this.input.datefrom = this.search.datefrom?this.search.datefrom:null
      this.input.dateto = this.search.dateto?assignedate:null
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            let filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
          const groupByCategory = result.data.data.rows.reduce((group, product) => {
            const { projectname } = product;
            group[projectname] = group[projectname] ?? [];
            group[projectname].push(product);
            return group;
          }, {});
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          let ShowLabel = 'PGMReport'+filename
          let JSONData = readExportdata
          let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          let CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#';
                CSV += ',' +this.headingLabel+ ','
                CSV += 'Duration,Product Pipeline,Dev Pipeline,WIP,UAT + Live,Estimated Time,Spent Time, Number Of Adjustments Made To The Schedule,Ontime Task Ratio,Number Of Errors, Effort Variance, Schedule Variance,Delayed Number of Tasks,Number Of Cancelled Tasks,Number Of Change Requests,Utilization Percentage,Production issues,Escalations' + '\r\n'
            }
            let row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname == 'NA' ? '' : index.projectname: '';
            let periodfrom = index.hasOwnProperty('periodfrom') ? index.periodfrom ==null || index.periodfrom == 'NA' ? '' : index.periodfrom: '';
            let periodto = index.hasOwnProperty('periodto') ? index.periodto == null || index.periodto == 'NA' ? '' : index.periodto : '';
            let productpipeline = index.hasOwnProperty('productpipeline') ? index.productpipeline == null || index.productpipeline == 'NA' ? '' : index.productpipeline : '';
            let techpipeline = index.hasOwnProperty('techpipeline') ? index.techpipeline == null?'' :index.techpipeline : '';
            let techwip = index.hasOwnProperty('techwip') ? index.techwip == null || index.techwip == 'NA' ? '' : index.techwip : '';
            let livecount = index.hasOwnProperty('livecount') ? index.livecount == null || index.livecount == 'NA' ? '' : index.livecount : '';
            let estimatedhrs = index.hasOwnProperty('estimatedhrs') ? index.estimatedhrs == null || index.estimatedhrs == 'NA' ? '' : index.estimatedhrs : '';
            let spenthrs = index.hasOwnProperty('spenthrs') ? index.spenthrs == null || index.spenthrs == 'NA' ? '' : index.spenthrs : '';
            let noofadjustment = index.hasOwnProperty('noofadjustment') ? index.noofadjustment == null || index.noofadjustment == 'NA' ? '' : index.noofadjustment : '';
            let onetimetaskratio = index.hasOwnProperty('onetimetaskratio') ? index.onetimetaskratio == null || index.onetimetaskratio == 'NA' ? '' : index.onetimetaskratio : '';
            let qcerrors = index.hasOwnProperty('qcerrors') ? index.qcerrors == null || index.qcerrors == 'NA' ? '' : index.qcerrors : '';
            let effortvarience = index.hasOwnProperty('effortvarience') ? index.effortvarience == null || index.effortvarience == 'NA' ? '' : index.effortvarience : '';
            let schedulevarience = index.hasOwnProperty('schedulevarience') ? index.schedulevarience == null || index.schedulevarience == 'NA' ? '' : index.schedulevarience : '';
            let delayedtask = index.hasOwnProperty('delayedtask') ? index.delayedtask == null || index.delayedtask == 'NA' ? '' : index.delayedtask : '';
            let cancelledtask = index.hasOwnProperty('cancelledtask') ? index.cancelledtask == null || index.cancelledtask == 'NA' ? '' : index.cancelledtask : '';
            let changerequest = index.hasOwnProperty('changerequest') ? index.changerequest == null || index.changerequest == 'NA' ? '' : index.changerequest : '';
            let utilizationperc = index.hasOwnProperty('utilizationperc') ? index.utilizationperc == null || index.utilizationperc == 'NA' ? '' : index.utilizationperc : '';
            let prodissues = index.hasOwnProperty('prodissues') ? index.prodissues == null || index.prodissues == 'NA' ? '' : index.prodissues : '';
            let escalation = index.hasOwnProperty('escalation') ? index.escalation == null || index.escalation == 'NA' ? '' : index.escalation : '';

            let pfrom = this.dateMonth(periodfrom)
            let pto = this.dateMonth(periodto)

            row += '"' + srno + '",',
            row += '"' + projectname + '",',
            row += '"' + pfrom + '-' + pto +'",',
            row += '"' + productpipeline + '",',
            row += '"' + techpipeline + '",',
            row += '"' + techwip + '",',
            row += '"' + livecount + '",',
            row += '"' + estimatedhrs + '",',
            row += '"' + spenthrs + '",',
            row += '"' + noofadjustment + '",',
            row += '"' + onetimetaskratio + '",',
            row += '"' + qcerrors + '",',
            row += '"' + effortvarience + '",',
            row += '"' + schedulevarience + '",',
            row += '"' + delayedtask + '",',
            row += '"' + cancelledtask + '",',
            row += '"' + changerequest + '",',
            row += '"' + utilizationperc + '",',
            row += '"' + prodissues + '",',
            row += '"' + escalation + '",',
            row += '\r\n'
            }

            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            return;
            }
            let fileName = 'PGMReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            let link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });   
        }
    },
    export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (btnstate == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata[0].projectid
        })
      }
    },
  },
};
</script>
