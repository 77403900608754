<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-2 pt-1">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left">
                <h3 class="content-header-title float-left mb-0">Performance Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/erformance'>Performance Management</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning">
          <div class="card-header py-1">
            <div class="container-fluid">
              <div class="row">
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form form-vertical">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label for="projectlistdd">Designation</label>
                      <treeselect placeholder="Select designation" class="projectlistdd" :multiple="true"  :clear-on-select="true" v-model="performance.designationid" :options="performance.designationlist"/>
                      <div class="errorinputmsg" v-if="this.errors.performance.designationid">{{ this.errors.performance.designationid }}</div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="projectlistdd">KRA</label>
                      <treeselect placeholder="Select KRA" class="projectlistdd" :multiple="true"  :clear-on-select="true" v-model="performance.kraid" :options="performance.kralist"/>
                      <div class="errorinputmsg" v-if="this.errors.performance.kraid">{{ this.errors.performance.kraid }}</div>
                    </div>
                    <div class="form-group col-md-4">
                      <label for="projectlistdd">KPI</label>
                      <treeselect placeholder="Select KPI" class="projectlistdd" :multiple="true"  :clear-on-select="true" v-model="performance.kpiid" :options="performance.kpilist"/>
                      <div class="errorinputmsg" v-if="this.errors.performance.kpiid">{{ this.errors.performance.kpiid }}</div>
                    </div>
                  </div>
                  <div class="flex--items mt-1">
                    <button type="button" class="btn btn-relief-primary" style="padding: 10px 32px" v-on:click.stop.prevent="saveData($event)">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import commonMethods from '../../utils/commonMethods';
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


export default {
  name: 'PerformanceView',
  components: {
    VueElementLoading,
    Treeselect,
    Loading,
  },
  mixins: [ commonMethods ],
  data() {
    return {
      performance: {
        designationid: null,
        kraid: null,
        kpiid: null,
        performancelist: [],
        designationlist: [],
        kralist: [],
        kpilist: [],
      },
      errors: {
        performance: {
          designationid: null,
          kraid: null,
          kpiid: null,
        }
      },
      isLoading: false,
    }
  },
  created() {},
  mounted() {
    let userdata = window.localStorage.getItem('userdata');
    if(userdata) {
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.tokendata = window.localStorage.getItem('token');
      this.getDesignations()
    }
  },
  methods: {
    getDesignations() {
      let url = 'api/master/performance/getDesignations'
       axios({
        method: 'POST',
        url,
        data:{useremail: this.undt.username,empcode: this.undt.userid},
        headers:{ authorization :this.tokendata }
      }).then(result => {
        if(result.data.errorCode == 0) {
          this.performance.designationlist = result.data.data.rows;
          let decryptdepartmenttext = this.performance.designationlist.map(items =>{

            let tempemplabel1 = items.label ? this.decryptText(items.label) : null;
            items.label = tempemplabel1;
            return items;
          })
        } else {
          this.performance.designationlist = [];
        }
      }).catch(e => {
         this.displayError(e)
      });
    },
    clearFields() {
      this.performance.designationid = null
      this.performance.kraid = null
      this.performance.kpiid = null
    },
    validateForm() {
      let isValid = true
      if(!this.performance.designationid) {
        this.errors.performance.designationid = 'Designation is required'
        isValid = false
      } else {
        this.errors.performance.designationid = ''
      }
      if(!this.performance.kraid) {
        this.errors.performance.kraid = 'KRA is required'
        isValid = false
      } else {
        this.errors.performance.kraid = ''
      }
      if(!this.performance.kpiid) {
        this.errors.performance.kpiid = 'KPI is required'
        isValid = false
      } else {
        this.errors.performance.kpiid = ''
      }
      if(this.performance.kpiid && this.performance.kraid && this.performance.kpiid.length !== this.performance.kraid.length) {
        this.errors.performance.kpiid = 'Select same number of KPI as KRA'
        isValid = false
      } else {
        if(this.performance.kpiid) {
          this.errors.performance.kpiid = ''
        }
      }
      console.log(this.performance.designationid, this.performance.kraid, this.performance.kpiid)
      return isValid
    },
    saveData() {
      let isValid = this.validateForm()
      if(isValid) {
        this.isLoading = true
        let url = 'api/master/performance/create'
        let payload = {
          designationid: this.performance.designationid.map(i => parseInt(i)),
          kraid: this.performance.kraid.map(i => parseInt(i)),
          kpiid: this.performance.kpiid.map(i => parseInt(i)),
          createdby: this.undt.username,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
        axios({
          method: 'POST',
          url,
          data: payload,
          headers: { authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.clearFields()
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
          this.displayError(e)
        })
      }
    }
  }

}
</script>