<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left pl-0">
                 <h3 class="content-header-title float-left mb-0">Test Case Detail Level Report</h3>
                 <div class="breadcrumb-wrapper">
                  
                 </div>
               </div>
             </div>
           </div>
         </div>
 
        <div class="card border-warning filter_card_searchbgst" style="border-radius: 5px; border: none!important;">
          <div class="card-header filter_rotation_onlick " style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="w-auto px-1 mobile-padding-0-2 mobile_w_calc_38 d-inline-flex align-items-center">
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                            <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                            </search-icon>
                        </div>
                    </div>
                      <h4 class="card-title d-inline-flex ml-1">Search Test Case Detail Level Report</h4>
                </div>
                <div class="ml-auto mr-lg-1 d-flex">
                      <span class="rotetions_view_arrow"> 
                        <em class="las la-angle-down"></em>
                      </span>
                  </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
            <div class="card-body pt-2">
              <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker mb-1"> 
                      <label for="" class="d-block">From Date</label>
                      <date-picker placeholder="Select Action Start Date" v-model="search.actionstartdate" valueType="format" class="startdate" id="StartDate" :disabled-date="notBeforeToday" @input="selectedstartdate"></date-picker>
                      <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker mb-1"> 
                      <label for="" class="d-block">To Date</label>
                      <date-picker placeholder="Select Action End Date" v-model="search.actionenddate" valueType="format" class="startdate" id="EndDate" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                  </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject" :multiple="true" :clear-on-select="true"/>   
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                <label class="form-label lable-left">QA Lead</label>
                <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Lead"  :options="qaleadlist" v-model="search.qalead" :multiple="true" v-on:search-change="getProjectQAdata(509,null,$event)" :clear-on-select="true"/> 
                </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">QA Engineer</label>
                  <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Engineer"  :options="qaenglist" v-model="search.qaeng" :multiple="true" v-on:search-change="getProjectQAdata(478,null,$event)" :clear-on-select="true"/> 
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Business Task Name</label>
                  <treeselect class="projectmdllistdd" placeholder="Enter min 5 chars to search Business Task Name" v-model="search.searchBusinesstaskname" :options="businesstasknameopts" v-on:search-change="businessNameChangeSearch" :clear-on-select="true"/>                      
                </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mobile-padding-0-10">
                    <button type="submit" :disabled="isBothDateSelected" class="btn btn-relief-primary mt-2 btn-margin-three" @click="getTCDetaillevelReports()"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 btn-margin-three" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                    <button :disabled="isBothDateSelected" type="move" class="btn btn-relief-primary mt-2 btn-margin-three mr-0" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div id="scrollToListGrid"></div>
        <div class="card border-warning" id="btDiv">
          <div class="card-body p-0 ">
            <div  class="card-header px-1 py-1 view_bg-gradient-primary">
              <h2  class="card-title heading_text_pro_summarys">Test Case Detail Level Report </h2>
            </div>
            <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home"  role="tabpanel"  aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky"  v-if="this.tcdetaillevelreports.length>0">
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Employee Activity Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno align-middle first-col-sticky" style="min-width:45px">Sr#</th>
                                      <th scope="col" class="align-middle text-nowrap second-col-sticky">Test Case Name</th>
                                      <th scope="col" class="align-middle text-nowrap">Project Name</th>
                                      <th scope="col" class="align-middle text-nowrap">QA Lead</th>
                                      <th scope="col" class="align-middle text-nowrap">QA Engineer</th>
                                      <th scope="col" class="align-middle text-nowrap">Test Case Types</th>
                                      <th scope="col" class="align-middle text-nowrap">Created Date</th>
                                      <th scope="col" class="text-nowrap">Present In Test Group</th>
                                      <th scope="col" class="text-nowrap">Present In Test Cycles</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in tcdetaillevelreports">
                                      <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>     
                                  
                                        <td class="capitalisetext align-middle font-weight-normal">
                                            {{data.testcasename}}
                                        </td>
                                        <td class="capitalisetext align-middle font-weight-normal">
                                            {{data.projectname}}
                                        </td>
                                        <td class="capitalisetext" style="min-width: 210px;">{{ data.qalead }}</td>
                                        <td class="capitalisetext" style="min-width: 210px;">{{ data.qaeng }}</td>

                                        <td class="capitalisetext text-nowrap align-middle font-weight-normal">
                                          {{data.testcasetypename ? data.testcasetypename:'NA'}}
                                        </td>
                                         <td class="capitalisetext align-middle text-nowrap font-weight-normal">
                                          {{data.createddate?dateFormat(data.createddate):''}}
                                        </td>
                                        <td class="align-middle font-weight-normal" style="text-align: center;color:blue;cursor:pointer;" @click="openTotalgroups(data)">
                                          {{data.totalgroups}}
                                        </td>
                                        <td class="align-middle font-weight-normal" style="text-align: center;color:blue;cursor:pointer;" @click="openTotalcycleset(data)">
                                          {{data.totalcyclset}}
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="tcdetaillevelreports.length == 0">
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                                <div class="pageCenter pt-2 text-center">
                                <pagination
                                    v-if="showtabledata"
                                    v-model="page"
                                    :records="totalcount"
                                    :per-page="pagelimit"
                                    @paginate="clickCallback"
                                />
                                </div>
                              </div>
                              <div v-else class="pageCenter text-center mt-1">
                                <span v-if="search.actionstartdate == null && search.actionenddate == null">Please apply dates filter to found the data !</span><br/>
                                Data not found..!!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
          </div>
        </div>
        </section>
        <div class="modal fade" id="groupspopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel16">Test Group's Names</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col" class="srno">Sr#</th>
                                    <th scope="col">Group Names</th>                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(data,index) in groupsList">        
                                    <tr v-bind:key="index">
                                        <td class="srno">{{index+1}}</td>  
                                        <td>
                                            <span class="font-weight-bold">{{data.modulename}}</span>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="groupsList.length==0">
                                    <tr><td colspan="3">No record found</td></tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>    
            </div>
        </div>
        </div>
        <div class="modal fade" id="cyclesetpopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel16">Cycle Set Names</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="table-responsive">
                        <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                            <thead>
                                <tr>
                                    <th scope="col" class="srno">Sr#</th>
                                    <th scope="col">Cycle Set Names</th>                                
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(data,index) in cyclesetList">        
                                    <tr v-bind:key="index">
                                        <td class="srno">{{index+1}}</td>  
                                        <td>
                                            <span class="font-weight-bold">{{data.cyclesetname}}</span>
                                        </td>
                                    </tr>
                                </template>
                                <template v-if="cyclesetList.length==0">
                                    <tr><td colspan="3">No record found</td></tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>    
            </div>
        </div>
        </div>
     </div>
   </div>
 </template>
 
 <script>
 import axios from 'axios';
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Loading from 'vue-loading-overlay';
 import DatePicker from "vue2-datepicker";
 import 'vue-loading-overlay/dist/vue-loading.css';
 import '@riophae/vue-treeselect/dist/vue-treeselect.css';
 import { SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
 import Treeselect from '@riophae/vue-treeselect'
 import moment from "moment";
 import Pagination from 'vue-pagination-2';
 import commonMethods from '../../utils/commonMethods';

 export default {
   name: 'testcasedetaillevelreport',
   mixins: [ commonMethods ],
   components: {
     VueElementLoading,
     Loading,
     SearchIcon,
     XIcon,
     ExternalLinkIcon,
     Treeselect,DatePicker,Pagination,
   },
   data() {
     return {
        isBothDateSelected:false,
        projectnameList:[],
        businesstasknameopts: null,
        search: {
          actionstartdate:null,
          actionenddate: null,
          qalead: null,
          qaeng: null,
          searchBusinesstaskname:null,
          searchProjectname: null,
        },
        showtabledata: true,
        qaleadlist:[],
        qaenglist: [],
        isLoading: false,
        undt: null,
        tokendata: null,
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        addformbtntxt:'Next',
        tcdetaillevelreports: [],
        dateArr: [],
        totalcount: 0,
        pageCount: 0,
        current_page: 1,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures:[],
        startDate: null,
        endDate:null,
        pagelimit:apiUrl.LIMIT,
        page:1,
        glbMdl:'',
        glbst:'',
        glbMdlLbl:'company',
        rowsSum: [],
        rowsReopenSum: [],
        groupsList:[],
        cyclesetList:[]
     }
   },
   created() {},
   mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        // this.getTCDetaillevelReports()
      }
    }
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      }); 
   },
   methods: {
      selectproject(state, value) {
        this.pageoffset=1
        if(!state){
          console.log('no project selected')
          this.qaleadlist=[];
            this.qaenglist= [];
        }else{
          this.search.searchProjectname = state;
          this.getProjectQAdata(478,state, null);
          this.getProjectQAdata(509,state, null);
          this.searchactiveflag = false;
        }
      },
      getProjectQAdata(roleid, state, node){
          let inputappl = {
              empcode: this.undt.userid,
              useremail: this.undt.username,
              node: (node != null && node != '' && node.length > 2)? node : null,
              roleid: roleid,
              projectidarr:((state != null && state.length > 0))? state : null,
          }
          if((node != null && node.length>=2) || (state != null && state.length > 0)) {
            axios({
            'method': 'POST',
            'url': 'api/reports/getGroupManagerDetails',
            'data': inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==509){
                        this.qaleadlist=result.data.data.rows;
                    }else if (roleid==478){
                        this.qaenglist=result.data.data.rows;
                    }
                }else{
                    if(roleid==509){
                        this.qaleadlist=[]
                    }else if (roleid==478){
                        this.qaenglist=[];
                    }
                }
            }).catch(e => {
    this.displayError(e)
    })
          } else {
            this.qaleadlist=[];
            this.qaenglist= [];
          }
      },
      selectedenddate() {
          if (this.search.actionstartdate && this.search.actionenddate) {
            this.isBothDateSelected = false;
            this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
          } else {
            this.isBothDateSelected = true;
          }
      },
      selectedstartdate() {
          if (this.search.actionstartdate && this.search.actionenddate) {
            this.isBothDateSelected = false;
            this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
          } else {
            this.isBothDateSelected = true;
          }
      },
      getDatesInRange(fromDate, toDate) {
          let dates = [];
          const current = new Date(fromDate);
          const end = new Date(toDate);
          const oneDay = 24 * 60 * 60 * 1000; 
          while (current <= end) {
              dates.push(current.toISOString().split('T')[0]);
              current.setTime(current.getTime() + oneDay);
          }
          return dates;
      },
      businessNameChangeSearch(node) {
        if(node != null && node != '' && node.length >= 5) {
            this.isLoading = true;
            let url = "api/reports/getBTCbyProject";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                node: node ? node.toLowerCase() : null,
                projectid: this.search.searchProjectname? this.search.searchProjectname : null,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.businesstasknameopts = result.data.data.rows;
                } else {
                    this.businesstasknameopts = [];
                }
            }).catch(e => {
    this.displayError(e)
    })
        } else {
          this.businesstasknameopts = [];
        }
      },
      clickCallback: function(pageNum) {
          this.pageoffset=pageNum
          this.currPageNum = pageNum - 1
          this.getTCDetaillevelReports();
          setTimeout(function(){
              $('html, body').animate({
                  scrollTop: $("#scrollToListGrid").offset().top -70
              });
          }, 500);
      },
      getTCDetaillevelReports() {
        this.isLoading=true
          this.input = {
              offset: this.pageoffset,
              limit: apiUrl.LIMIT,
              empcode: this.undt.userid,
              useremail: this.undt.username,
              startDate: (this.search.actionstartdate!=null)?this.search.actionstartdate:this.startDate,
              endDate: (this.search.actionenddate!=null)?this.search.actionenddate:this.endDate,
              projectid: this.search.searchProjectname,
              qaeng: this.search.qaeng,
              qalead: this.search.qalead,
              btcid: this.search.searchBusinesstaskname,
          }
          axios({
              method: "POST",
              url: "api/reports/getTCDetaillevelReport",
              data: this.input,
              headers: { authorization : this.tokendata },
          })
          .then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                this.tcdetaillevelreports = result.data.data.rows;
                this.totalcount = result.data.data.count;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
              } else {
                this.tcdetaillevelreports = []
                this.totalcount = 0;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
              }
          }).catch(e => {
    this.displayError(e)
    })
      },
      openTotalgroups(data) {
      this.isLoading = true
      let url = 'api/reports/getTCGroupDetails'
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        testcaseid: data.testcaseid
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.status) {
          this.groupsList = result.data.data.rows
          $('#groupspopup').modal('show');

        }else if(result.data.errorCode == 3){
          Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
        }else {
            $('#groupspopup').modal('hide');

          this.groupsList = []
        }
      }).catch(e => {
        this.tasklist = []
        Swal.fire({
          title: "",
          text: e,
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
      })
      $('#groupspopup').modal('hide');         
    },

    openTotalcycleset(data) {
      this.isLoading = true
      let url = 'api/reports/getCycleSetDetails'
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        testcaseid: data.testcaseid
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.status) {
          this.cyclesetList = result.data.data.rows
          $('#cyclesetpopup').modal('show');

        }else if(result.data.errorCode == 3){
          Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
              }).then(function() {
                  window.location.href = "/#/login";
              })
        }else {
            $('#cyclesetpopup').modal('hide');

          this.cyclesetList = []
        }
      }).catch(e => {
        this.cyclesetList = []
        Swal.fire({
          title: "",
          text: e,
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
      })
      $('#cyclesetpopup').modal('hide');         
    },

      dateFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD-MM-YYYY');
     },
      projectChange: function (node, instanceId){
        if(node && node.length>1){
          this.pageoffset=1
          this.isLoading = true;
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            projectname:node.toLowerCase(),
            useremail: this.undt.username,
            empid: this.undt.userid,
            empcode: this.undt.userid,
            isfrompage: 'listingpage'
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.projectnameList = result.data.data;
            } else {
              this.projectnameList = [];
            }
          }).catch(e => {
    this.displayError(e)
    })
        }
      },  
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },   
      resetRecords: function() {
        this.search.searchBusinesstaskname = null
        this.search.actionstartdate=null
        this.search.actionenddate= null
        this.search.searchProjectname = null
        this.search.qalead = null
        this.search.qaeng = null
        this.qaleadlist=[];
        this.qaenglist= [];
        this.startDate = null;
        this.endDate = null;
        this.tcdetaillevelreports = [];
      },
      notBeforeToday: function (date) {
        this.search.actionenddate = null;
        return date > new Date(new Date().setHours(0, 0, 0, 0));     
      },
      disabledBefore(date) {
          const oneMonthFromFromDate = moment(this.search.actionstartdate).add(1, 'month');
          const startOfOneMonth = oneMonthFromFromDate.startOf('day').toDate();
          const startOfFromDate = moment(this.search.actionstartdate).startOf('day').toDate();
          return date > startOfOneMonth || date < startOfFromDate;
      },
      dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
      ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
          this.input = {
              offset: null,
              limit: null,
              empcode: this.undt.userid,
              useremail: this.undt.username,
              startDate: (this.search.actionstartdate!=null)?this.search.actionstartdate:this.startDate,
              endDate: (this.search.actionenddate!=null)?this.search.actionenddate:this.endDate,
              projectid: this.search.searchProjectname,
              qaeng: this.search.qaeng,
              qalead: this.search.qalead,
              btcid: this.search.searchBusinesstaskname,
          }
          axios({
              method: "POST",
              url: "api/reports/getTCDetaillevelReport",
              data: this.input,
              headers: { authorization : this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.exportxlsdata = result.data.data.rows;
              var filename = "";
              this.download(this.exportxlsdata, filename)
            }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }      
          }).catch(e => {
    this.displayError(e)
    })
        
      },
      download(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = 'testcase_detail_level_report'+filename;
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
            if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
              row = row.slice(0, -1)
              CSV = 'Sr#,Test Case,Project Name,QA Lead,QA Engineer,Test Case Type,Created Date,Present in Test Group,Present in Test Cycle'+'\r\n';
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
              let index = arrData[i]
              let srno = i+1
              let testcasename = index.hasOwnProperty('testcasename') ? index.testcasename ==null || index.testcasename == 'NA' ? '' : index.testcasename.replace(/"/g, '""'): '';
              let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname == 'NA' ? '' : index.projectname.replace(/"/g, '""'): '';
              let qalead = index.hasOwnProperty('qalead') ? index.qalead : 'NA';
              let qaeng = index.hasOwnProperty('qaeng') ? index.qaeng : 'NA';
              let testcasetypename = index.hasOwnProperty('testcasetypename') ? index.testcasetypename == null || index.testcasetypename == 'NA' ? '' : index.testcasetypename : '';
              let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ? '' : index.createddate : '';
              let totalgroups = index.hasOwnProperty('totalgroups') ? index.totalgroups  == null || index.totalgroups == 'NA' ?'':index.totalgroups : '';
              let totalcyclset = index.hasOwnProperty('totalcyclset') ? index.totalcyclset  == null || index.totalcyclset == 'NA' ?'':index.totalcyclset : ''
              createddate = this.dateMONFormat(createddate)

              row += '"' + srno + '",',
              row += '"' + testcasename + '",',
              row += '"' + projectname + '",',
              row += '"' + qalead + '",',
              row += '"' + qaeng + '",',
              row += '"' + testcasetypename + '",',
              row += '"' + createddate + '",',
              row += '"' + totalgroups + '",',
              row += '"' + totalcyclset + '",',
              row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            var fileName = 'TaskListReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report Successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });      
        }
      },
    
      export (data, filename, mime) {
        var data
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
        download(blob, filename, mime)
      },
   }
 }
 </script>
 <style> 
 .view_bg-gradient-primary{
   background: #7DA3F9!important;
 }
 </style>