<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="container-fluid px-0">
                    <div class="row breadcrumbs-top my-1 mx-0">
                    <div class="col-md-9 px-0 content-header-left ">
                        <h3 class="content-header-title float-left mb-0">Test Case</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/testcasetask/list'>Test Case List</router-link></li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="card border-warning filter_card_searchbgst" >
                    <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                        <div class="container-fluid">
                            <div class="row fc_my-05">
                                <div class="w-auto px-1 mobile-padding-0-2">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                            ></search-icon>
                                        </div>
                                        </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search Test Case Details</h4>
                                </div>
                                <div class="ml-auto d-flex">
                                <div class="px-1 text-right mobile-padding-0-2" v-if="pagefeatures.createtestcase">
                                    <div class="">
                                    <router-link to="/testcasetask/create" >
                                    <button id="commonaddmodule" class="btn btn-sm btn-relief-success"   data-backdrop="static" data-keyboard="false"> <span class="mobile_hide_bt"> Create Testcase</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></button>
                                    </router-link>
                                    </div>
                                </div>
                                <span class="rotetions_view_arrow"> 
                                    <em class="las la-angle-down"></em>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                    <div class="card-body px-sm-mobile-10">
                        <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Test Case Code</label>
                            <input type="text" class="form-control" placeholder="Enter Test Case Code" v-model="search.testcasecode" v-on:search-change="testcaseCodeChangeSearch" @input="clearCodeList" :maxlength="maxLength"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Test Case Name</label>
                            <treeselect  class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search test case name" v-on:search-change="testcaseChange" v-model="search.searchtestcasename" :options="testcasenameList"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Project Name</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchprojectname" :options="projectnameList" @input="seltestcsprojectsearch" :multiple="true" :clear-on-select="true"/>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Functionality</label>
                            <treeselect class="projectmdllistdd" placeholder="Select Functionality" v-model="search.searchfunctionalityname" :options="functionalitylist" @input="seltestcsfunctionalitysearch" :multiple="true" :clear-on-select="true"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Task Name</label>
                            <treeselect class="projectmdllistdd" placeholder="Select Task Name" v-model="search.searchtaskname" :options="tasklist" :multiple="true" :clear-on-select="true"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Type</label>
                            <treeselect class="projectmdllistdd" placeholder="Select Types" v-model="search.searchtypename" :options="testcase.typelist" :multiple="true" :clear-on-select="true"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Severity</label>
                            <treeselect class="projectmdllistdd" placeholder="Select Severity" v-model="search.searchseverityname" :options="testcase.sevairtylist" :multiple="true" :clear-on-select="true"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Priority</label>
                            <treeselect class="projectmdllistdd" placeholder="Select Priority" v-model="search.searchpriorityname" :options="testcase.prioritylist" :multiple="true" :clear-on-select="true"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <label class="form-label lable-left">Created-By</label>
                            <treeselect class="projectmdllistdd" placeholder="Enter Min 3 Chars to seatch Created By" v-model="search.createdby" :options="search.createdbyopts" v-on:search-change="getcreatedby" :multiple="true" :clear-on-select="true"/>
                        </div>
                        <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3">
                        <label for="">Testcase Date From</label>
                            <date-picker placeholder="Select Testcase Date From" v-model="search.createddatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                        <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                        </div>
                    <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3">
                    <label for="">Testcase Date To</label>
                        <date-picker placeholder="Select Testcase Date To" v-model="search.createddateto" :disabled-date="disabledBefore" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                        <label class="form-label lable-left">Script Status</label>
                        <treeselect class="projectmdllistdd" placeholder="Select Script Status" :multiple="true" :clear-on-select="true" v-model="search.scriptstatus" :options="testcase.tcscriptstatuslist"/>
                    </div>
                    <div class="ml-auto col-sm-6 col-md-6 col-lg-4 mb-1 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="gettestcaselist(search,1)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                        <button type="move" class="btn btn-relief-primary mt-2" :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="scrollToListGrid"></div>
            <div class="row">
                <div class="col-12">
                    <ul class="listGrid float-right d-inline-flex px-0">
                        <li class="cursorpointercls"  @click="myListView()" >
                        <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                            <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                            <span>
                            List View
                            </span>
                        </button>
                        </li>
                        <li class=" cursorpointercls" @click="myGridView()" >
                        <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
                            <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
                            <span>
                            Grid View
                            </span>
                        </button>
                        </li>
                    </ul>
                </div>
                </div>
        <div v-bind:class="[blog_list_wrapper,{displayblk:isgridView}]">
          <div class="dw mx-n1">
            <div class="dw-panel p-1"  v-for="(data,index) in companymasterlist" v-bind:key="index">
              <div class="card border-warning card_style_box_shadow1" style="margin-bottom: 0rem !important;">
                <div class="card-header p-1">
                  <h4 class="card-title">{{(data.projectname).toLowerCase()}} : {{data.testcasecode}} </h4>
                </div>
              <div class="card" style="margin-bottom:0 !important;">
                  <div class="card-body p-1">
                      <h4 class="card-title mb-1">
                          <span v-if="data.testcasename.length > 50"  class="blog-title-truncate text-body-heading ">
                            {{data.testcasename.substring(0,51)+ ' ...'}}
                          </span>
                          <span v-else  class="blog-title-truncate text-body-heading ">
                            {{data.testcasename}}
                          </span>
                      </h4>
                      <div class="media">
                          <div class="avatar mr-50">
                             <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic> 
                            <img alt="" v-if="!data.imagename"  :src="defaultimagepath"  width="40" height="40" />
                          </div>
                          <div class="media-body">
                              <small class="text-muted mr-25">by</small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                              <span class="text-muted ml-50 mr-25">|</span>
                              <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                          </div>
                      </div>
                      <div class="my-1">
                            <div v-if="data.priority.toLowerCase()=='low'" class="capitalisetext badge badge-pill badge-light-info mr-50 mb-25">{{data.priority}}</div>
                            <div v-if="data.priority.toLowerCase()=='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50 mb-25">{{data.priority}}</div>
                            <div v-if="data.priority.toLowerCase()=='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50 mb-25">{{data.priority}}</div>

                            <div v-if="data.testcasesevairtyname.toLowerCase()=='critical'" class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">{{data.testcasesevairtyname}}</div>
                            <div  v-if="data.testcasesevairtyname.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 mb-25 capitalisetext">{{data.testcasesevairtyname}}</div>
                            <div v-if="data.testcasesevairtyname.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 mb-25 capitalisetext">{{data.testcasesevairtyname}}</div>
                            <div v-if="data.testcasesevairtyname.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.testcasesevairtyname}}</div>
                            <div v-if="data.testcasesevairtyname.toLowerCase()=='moderate'" class="badge badge-pill badge-light-info mr-50 mb-25 capitalisetext">{{data.testcasesevairtyname}}</div>           
                      </div>
                      <div class="my-1 table-responsive  datatableview column_adjectdatatable">
                        <table class="table  dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">                                
                            <thead class="text_case_heading_text12">
                                <tr>
                                    <th scope="col" class="srno align-middle first-col-sticky">Created</th>
                                    <th scope="col" class="srno align-middle first-col-sticky">Review In Progress</th>
                                    <th scope="col" class="srno align-middle first-col-sticky">Reviewed</th>
                                    <th scope="col" class="srno align-middle first-col-sticky">Approved</th>
                                    <th scope="col" class="srno align-middle first-col-sticky">Freeze</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td data-column="Created" class="text-center sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Created')" href="javascript:void(0);">
                                    {{ data.createdcount }}
                                </td>
                                <td data-column="Review In Progress" class="text-center sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Review In Progress')" href="javascript:void(0);">
                                    {{ data.reviewinprgressocount }}
                                </td>
                                <td data-column="Reviewed" class="text-center sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Reviewed')" href="javascript:void(0);">
                                    {{ data.reviewedcount }}
                                </td>
                                <td data-column="Approved" class="text-center sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Approved')" href="javascript:void(0);">
                                    {{ data.approvedcount }}
                                </td>
                                <td data-column="Freeze" class="text-center sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Freeze')" href="javascript:void(0);">
                                    {{ data.freezecount }}
                                </td>
                            </tbody>
                        </table>
                      </div>
                     
                      <hr class="mx-n1 mt-0" />
                      <div class="d-flex justify-content-between align-items-center">
                          <a  @click="redirecttourl('edit',data)" href="javascript:void(0);" v-if="loginusername == data.createdby || pagefeatures.edittestcase">
                              <div class="d-flex align-items-center sitebluecolor">
                                    <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                  <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                              </div>
                          </a>
                          <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold">View Details</span>
                            </div>
                          </a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
            <div class="pageCenter text-center pt-1">
                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                    <div v-if="companymasterlist.length == 0 && isresponce" colspan="14" style="text-align: center;">No record found</div>
                </div>
            </div>
            
                <div v-bind:class="[{ show: isListActive}, card]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top: 1rem; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: isListDetActive},card_content,collapse]">
                        <div class="file-manager-content-body">
                            <div class="drives">
                                <div class="row" v-if="showproject">
                                    <div class="col-md-12">
                                        <div class="card ">
                                            <div class="">
                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                        <div class="row" id="table-small">
                                                            <div class="col-12">
                                                                <div class="card">
                                                                    <div class="table-responsive datatableview">
                                                                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                           
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" class="srno align-middle first-col-sticky">Sr#</th>
                                                                                    <th scope="col" class="align-middle second-col-sticky">Test Case Code</th>
                                                                                    <th scope="col" class="align-middle" style="min-width: 150px;">Test Case Name</th>
                                                                                    <th scope="col" class="align-middle text-left" style="min-width: 150px;">Country</th>
                                                                                    <th scope="col" class="align-middle text-left" style="min-width: 150px;">Environment</th>
                                                                                    <th scope="col" class="align-middle">Language</th>
                                                                                    <th scope="col" class="align-middle">Script Type</th>
                                                                                    <th scope="col" class="align-middle" style="min-width: 150px;">Project</th>
                                                                                    <th scope="col" class="align-middle" style="min-width: 180px;">Functionality</th>
                                                                                    <th scope="col" class="align-middle" style="min-width: 150px;">Type</th>
                                                                                    <th scope="col" class="align-middle">Severity</th>
                                                                                    <th scope="col" class="align-middle">Priority</th>
                                                                                    <th scope="col" class="align-middle" style="min-width: 170px;">Script Details</th>
                                                                                    <th scope="col" class="align-middle">Created Date</th>
                                                                                    <th scope="col" class="clsaction align-middle">Actions</th> 
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <template v-for="(data,index) in companymasterlist">        
                                                                                    <tr v-bind:key="data.moduleid">
                                                                                        <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                                        <td data-column="Test Case Code" class="text-nowrap second-col-sticky"><span class="font-weight-bold capitalisetext">{{data.testcasecode}}</span> </td>
                                                                                        <td data-column="Test Case Name"  class="rolelistdd"  @click="redirecttourl('view',data)" style="cursor: pointer;" href="javascript:void(0);">
                                                                                            <span  class="font-weight-bold sitebluecolor" v-if="data.testcasename.length > 50">{{data.testcasename.substring(0,50+1)+'...'}}</span>
                                                                                            <span class="font-weight-bold sitebluecolor" v-if="data.testcasename.length <= 50" >{{data.testcasename}}</span>
                                                                                        </td> 
                                                                                        
                                                                                        <td data-column="Country" class="text-nowrap test_list_caseulli">
                                                                                            <span v-if="data.countrynamelist" class="font-weight-bold capitalisetext">
                                                                                                <ol><li v-for="(countrynames) in data.countrynamelist.split(',')">{{countrynames}}</li></ol>
                                                                                            </span>
                                                                                            <span v-else><ul><li>NA</li></ul></span>
                                                                                        </td>
                                                                                        <td data-column="Environment" class="text-nowrap test_list_caseulli">
                                                                                            <span v-if="data.enviornmentnamelist" class="font-weight-bold capitalisetext">
                                                                                                <ol><li v-for="(envnames) in data.enviornmentnamelist.split(',')">{{envnames}}</li></ol>
                                                                                            </span>
                                                                                            <span v-else><ul><li>NA</li></ul></span>
                                                                                        </td>
                                                                                        <td data-column="Language" class="text-nowrap test_list_caseulli">
                                                                                            <span v-if="data.languagenamelist" class="font-weight-bold capitalisetext">
                                                                                                <ol><li v-for="(langnames) in data.languagenamelist.split(',')">{{langnames}}</li></ol>
                                                                                            </span>
                                                                                            <span v-else><ul><li>NA</li></ul></span>
                                                                                        </td>
                                                                                        <td data-column="Script Type" class="text-nowrap test_list_caseulli">
                                                                                            <span v-if="data.scripttypenamelist" class="font-weight-bold capitalisetext">
                                                                                                <ol><li v-for="(gnames) in data.scripttypenamelist.split(',')">{{gnames}}</li></ol>
                                                                                            </span>
                                                                                            <span v-else><ul><li>NA</li></ul></span>
                                                                                        </td>
                                                                                        <td data-column="Project" >
                                                                                            <span class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectID',data)">{{data.projectname}}</span>
                                                                                            
                                                                                        </td>
                                                                                        <td data-column="Functionality">
                                                                                            <span class="font-weight-bold capitalisetext">{{data.functionlityname}}</span>
                                                                                        </td>
                                                                                        <td data-column="Type">
                                                                                            <span class="font-weight-bold capitalisetext">{{data.testcasetypename}}</span>
                                                                                        </td>
                                                                                        <td data-column="Severity">
                                                                                            <div v-if="data.testcasesevairtyname.toLowerCase()=='critical'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.testcasesevairtyname}}</div>
                                                                                            <div  v-if="data.testcasesevairtyname.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.testcasesevairtyname}}</div>
                                                                                            <div v-if="data.testcasesevairtyname.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.testcasesevairtyname}}</div>
                                                                                            <div v-if="data.testcasesevairtyname.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.testcasesevairtyname}}</div>
                                                                                            <div v-if="data.testcasesevairtyname.toLowerCase()=='moderate'" class="badge badge-pill badge-light-info mr-50 capitalisetext">{{data.testcasesevairtyname}}</div>
                                                                                        </td>
                                                                                        <td data-column="Priority">
                                                                                            <div v-if="data.priority.toLowerCase()=='low'" class="capitalisetext badge badge-pill badge-light-info mr-50">{{data.priority}}</div>
                                                                                            <div v-if="data.priority.toLowerCase()=='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50">{{data.priority}}</div>
                                                                                            <div v-if="data.priority.toLowerCase()=='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50">{{data.priority}}</div>
                                                                                        </td>
                                                                                        <td data-column="Script Details">
                                                                                            <span class="heading_column_text text-capitalize"> Created	: </span>
                                                                                            <span class="sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Created')" href="javascript:void(0);">
                                                                                                {{ data.createdcount }}
                                                                                            </span>
                                                                                            <hr class="hr_border-bottom" />
                                                                                            <span class="heading_column_text text-capitalize"> Review In Progress	: </span>
                                                                                            <span class="sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Review In Progress')" href="javascript:void(0);">
                                                                                                {{ data.reviewinprgressocount }}
                                                                                            </span>
                                                                                            <hr class="hr_border-bottom" />
                                                                                            <span class="heading_column_text text-capitalize"> Reviewed	: </span>
                                                                                            <span class="sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Reviewed')" href="javascript:void(0);">
                                                                                                {{ data.reviewedcount }}
                                                                                            </span>
                                                                                            <hr class="hr_border-bottom" />
                                                                                            <span class="heading_column_text text-capitalize"> Approved	: </span>
                                                                                            <span class="sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Approved')" href="javascript:void(0);">
                                                                                                {{ data.approvedcount }}
                                                                                            </span>
                                                                                            <hr class="hr_border-bottom" />
                                                                                            <span class="heading_column_text text-capitalize"> Freeze	: </span>
                                                                                            <span class="sitebluecolor" style="cursor: pointer;" @click="redirecttourl('countdata',data,'Freeze')" href="javascript:void(0);">
                                                                                                {{ data.freezecount }}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td data-column="Created Date" class="centeralign text-nowrap">{{dateMonthFormat(data.createddate)}}</td>
                                                                                        <td data-column="Actions" class="clsaction">
                                                                                            <a class="devEditEle" v-if="loginusername == data.createdby || pagefeatures.edittestcase"  @click="redirecttourl('edit',data)" style="cursor: pointer;" href="javascript:void(0);">
                                                                                                <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-if="companymasterlist.length==0">
                                                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="pageCenter text-center">
                                                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div >
                        <div class="">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="errorinputmsg" v-if="this.errors.testcase.testcasename">{{ this.errors.testcase.testcasename }}</div>
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                        <div class="errorinputmsg" v-if="this.errors.testcase.functionality">{{ this.errors.testcase.functionality }}</div>
                                    </div>
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                        <div class="errorinputmsg" v-if="this.errors.testcase.type">{{ this.errors.testcase.type }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="errorinputmsg" v-if="this.errors.testcase.sevairty">{{ this.errors.testcase.sevairty }}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="errorinputmsg" v-if="this.errors.testcase.priority">{{ this.errors.testcase.priority }}</div>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
            </section>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="testcasemaster">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:#2ca02c !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle" style="margin-left: 5px;">Add/Update Test Case Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projectlistdd">Test Case Name</label>
                                    <input type="text" id="testcasename" class="form-control" name="" placeholder="Testcase Name" v-model="testcase.testcasename" @blur="validateForm('t39',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.testcasename">{{ this.errors.testcase.testcasename }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projectmdllistdd">Project</label>
                                    <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.project" :options="testcase.projectlist" @input="seltestcsproject" @close="validateForm('t39',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.project">{{ this.errors.testcase.project }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projectmdllistdd">Functionality</label>
                                    <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.functionality" :options="testcase.functionalitylist" @input="seltestcsfunctionality" @close="validateForm('t39',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.functionality">{{ this.errors.testcase.functionality }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projectmdllistdd">Type</label>
                                    <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.type" :options="testcase.typelist" @input="seltestcstype" @close="validateForm('t39',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.type">{{ this.errors.testcase.type }}</div>
                            </div>
                           
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projectmdllistdd">Sevairty</label>
                                    <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.sevairty" :options="testcase.sevairtylist" @input="seltestcssevairty" @close="validateForm('t39',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.sevairty">{{ this.errors.testcase.sevairty }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projectmdllistdd">Priority</label>
                                    <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.priority" :options="testcase.prioritylist" @input="seltestcspriority" @close="validateForm('t39',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.priority">{{ this.errors.testcase.priority }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projectmdllistdd">Status</label>
                                    <treeselect class="projectmdllistdd capitalisetext" v-model="testcase.status" :options="testcase.statuslist" @input="seltestcsstatus" @close="validateForm('t39',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.status">{{ this.errors.testcase.status }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="attachment" class="btn btn-sm btn-primary mb-75 mr-75">Select image/docs/videos</label>
                                    <button class="btn btn-sm btn-primary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                                    <input type="file" id="attachment" hidden accept="image/*" ref="file" @change="onSelect"/>
                                    <label v-if="testcase.attachmentview" for="attachmentview"><a :href=testcase.attachmentview>{{testcase.attachmentview}}</a></label>
                                    <div class="invalid-feedback">project desc must be  greater than 5 char</div>
                                </div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-primary mr-1"  @click="savewizard('t39',$event)" id="submitbtnt39" v-bind:disabled="testcase.disblesavebtn">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary">Reset</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="Viewtestcasemaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg pop-up">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:rgb(31, 119, 180)  !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle"> Test Case List</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                            <thead class="d-none"> <th scope="col"> </th></thead>
                            <tr>
                                <td>Test case name</td>
                                <td><strong>{{this.testcase.testcasename}}</strong></td>
                            </tr>
                            <tr>
                                <td>Project</td>
                                <td><strong>{{this.view.projectname}}</strong></td>
                            </tr>
                             <tr>
                                <td>Functionality</td>
                                <td><strong>{{this.view.functionlityname}}</strong></td>
                            </tr>
                            <tr>
                                <td>Task</td>
                                <td><strong>{{this.testcase.task}}</strong></td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td><strong>{{this.testcase.type}}</strong></td>
                            </tr>
                              <tr>
                                <td>Sevairty</td>
                                <td><strong>{{this.testcase.sevairty}}</strong></td>
                            </tr>
                             <tr>
                                <td>Priority</td>
                                <td><strong>{{this.testcase.priority}}</strong></td>
                            </tr>
                             <tr>
                                <td>Attachment</td>
                                <td><strong>{{this.testcase.attachmentview}}</strong></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>         
    </div>
</template>
<style scoped>
.displayblk{
    display:none !important;
}
.count_tabletd tbody td {
    padding: 0.3rem;
    border: 1px solid #eee;
}

.createbtnparentdiv{
    padding-right: 0% !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import { ExternalLinkIcon,MoreVerticalIcon,Edit2Icon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,PackageIcon,PlusIcon,PlusSquareIcon, MinusSquareIcon, MessageSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,UserIcon } from 'vue-feather-icons'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import profilepic from '../profilepic.vue';
import DatePicker from "vue2-datepicker";
import commonMethods from '../../utils/commonMethods';
export default {
    name:'tlist',
    mixins: [ commonMethods ],
    components:{
        Loading,ListIcon,
        VueElementLoading,
        DatePicker,
        UserIcon,
        vSelect,
        EditIcon,
        EyeIcon,
        ExternalLinkIcon,
        Treeselect,
        SearchIcon,
        XIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
        MessageSquareIcon, 
        profilepic,PlusCircleIcon
    },    
    data() {
        return {
            maxLength:16,
            componentKey: 0,
            testcasecodelist:null,
            defaultimagepath:apiUrl.defaultimagepath,
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            editRowData:[],
            
            
            glbMdl:'',
            glbMdlLbl:'testcase',
            TimeFormat: apiUrl.TimeFormat,
            testcase:{
                testcasename:null,
                project:null,
                projectlist:null,
                functionality:null,
                functionalitylist:null,
                type:null,
                typelist:null,
                attachment:null,
                attachmentview:null,
                sevairty:null,
                sevairtylist:null,
                priority:null,
                prioritylist:[{
                    id:'high',
                    label:'High'
                },{
                    id:'low',
                    label:'Low'
                },{
                    id:'medium',
                    label:'Medium'
                }],
                status:null,
                statuslist: apiUrl.testcaseStatusList,
                iseditstate:false,
                disblesavebtn:false,
                task: null,
                tasklist: [],
                testcasenameopts: null,
                tcscriptstatuslist:apiUrl.tcscriptstatuslist.sort((a, b) => a.label.localeCompare(b.label)),
            },
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
             isresponce:false,
            modulelist:[],
            isSearchRequestActive:false,
            errors:{
                testcase:{
                    testcasename:null,
                    testcasenameopts:null,
                    project:null,
                    functionality:null,
                    type:null,
                    attachment:null,
                    sevairty:null,
                    priority:null,
                }
            },
            isDetActive: false,
            isListDetActive:false,
            isListActive:true,
            displayblk:'displayblk',
            isgridView:false,
            blog_list_wrapper :'blog-list-wrapper',
            isRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            isedit: false,
            card:"card",
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|', 'bold',
                        'italic', '|', 'bulletedList',
                        'numberedList', '|', 'insertTable', '|',
                        'undo', 'redo', '|',
                    ],
                },
            },
            search:{
                testcasecodelist:null,
                searchprojectname :null,
                projectnameopts :null,
                searchfunctionalityname:null,
                functionalitylist:null,
                searchtestcasename:null,
                searchtaskname:null,
                tasklist:null,
                searchtypename:null,
                typenameopts:null,
                searchseverityname:null,
                severitynameopts:null,
                searchpriorityname:null,
                prioritynameopts:null,
                searchstatus:null,
                statusopts:null,
                createddatefrom:null,
                createddateto:null,
                createdbyopts: [],
                createdby: null,
                tcgroup: null,
                tcgrouplist: [],
                scriptstatus: null
            },
            projectnameList:[],
            testcasenameList:[],
            view:{
                projectname: null,
                functionlityname: null
            },
            loginusername:'',
            sitebluecolor:"sitebluecolor",
            activegridlink:true,
            activelistlink:false,
            tokendata: null,
            isBothDateSelected:false,
            functionalitylist: [],
            tasklist: [],
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/testcasetask/list') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        window.addEventListener('storage', function(event){
              if (event.key == 'logout-mee') { 
                  window.location.reload();
              }
        });
        let permission = this.$Service.verifyAccess();
        if(permission){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt = userdata
                this.loginusername = userdata.username
                this.tokendata = window.localStorage.getItem('token');
                if(this.$route.params.backbtnstate == "ProjectSummaryTestCase") {
                    let dataSplit = this.$route.params.clickeddata.split('_');
                    this.isSearchRequestActive = true
                    this.projectChangebyid();
                  this.search.searchprojectname = [this.$route.params.pid]
                  this.search.searchtypename = [dataSplit[2]]
                  this.search.scriptstatus = [dataSplit[1]]
                  this.gettestcaselist(this.search, 1);

                } else if(this.$route.params.search) {
                        let search = JSON.parse(localStorage.getItem('testcasesearchItems'))
                    if(search) {
                        this.getSearchItems(search)
                    }
                } else if (this.$route.params.backbtnstate && this.$route.params.backbtnstate === 'viewtestcase') {
                    this.getProjectname()
                    this.search.searchprojectname = this.$route.params.clickeddata.projectid
                    this.search.searchfunctionalityname = this.$route.params.clickeddata.functionlitymasterid
                    this.isSearchRequestActive = true
                    this.gettestcaselist({
                        searchprojectname: this.search.searchprojectname,
                        searchfunctionalityname: this.search.searchfunctionalityname
                    });
                } else {
                    localStorage.removeItem('testcasesearchItems')
                    this.search.searchstatus = 'created'
                    this.gettestcaselist({ searchstatus: 'created' });
                }
            }
            this.getTCGlist();
        }
        $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
        
    },
    methods:{
        getTCGlist(){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                
            }
            axios({
                'method': 'POST',
                'url': 'api/testcase/getTestCaseGroupMasterList',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.search.tcgrouplist = result.data.data.rows;
                } else {
                    this.search.tcgrouplist = []
                }
            }).catch(e => {
            this.displayError(e)
            })
        },
        forceRerender() {
            this.componentKey += 1;
        },
        redirecttoprofile(empid){
            let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
            window.open(route.href);
        },
        mydetailscollapse() {
            this.isDetActive = !this.isDetActive
        },
        myrequestcollapse() {
            this.isRequestActive = !this.isRequestActive
            this.testcase.testcasename=null
            this.testcase.project=null
            this.testcase.functionality=null
            this.testcase.type=null
            this.testcase.sevairty=null
            this.testcase.priority=null
        },
        mysearchrequestollapse:function(){
            this.myrequestcollapse()
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        myListView: function () {
            this.isListDetActive = true;
            this.isgridView=true;
            this.activegridlink=false
            this.activelistlink=true
        },
        myGridView(){
            this.isListDetActive = false;
            this.isgridView=false;
            this.activegridlink=true
            this.activelistlink=false
        },
        resetForm() {
            this.testcase.testcasename = null 
            this.testcase.project = null
            this.testcase.functionality = null
            this.testcase.type = null
            this.testcase.sevairty = null
            this.testcase.priority = null
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            localStorage.removeItem('testcasesearchItems')
            this.clearerrorForm()
        },
      
        redirecttourl:function(btnstate,rowdata,selectedstatus){
            rowdata.backbtnstate=btnstate
            if(btnstate=="view"){
                let routeData = this.$router.resolve({ 
                    path: '/testcasetask/view?testcaseid='+rowdata.testcaseid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            } else if(btnstate=="edit"){
                this.$router.push({ 
                    path: '/testcasetask/update?testcaseid='+rowdata.testcaseid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }else if(btnstate=='countdata'){
                rowdata.countstatus=selectedstatus
                if (rowdata.countstatus=='Created' && rowdata.createdcount==0) {
                    return
                }else if(rowdata.countstatus=='Review In Progress' && rowdata.reviewinprgressocount==0){
                    return
                }else if(rowdata.countstatus=='Reviewed' && rowdata.reviewedcount==0){
                    return
                }else if(rowdata.countstatus=='Approved' && rowdata.approvedcount==0){
                    return
                }else if(rowdata.countstatus=='Freeze' && rowdata.freezecount==0){
                    return
                }
                this.$router.push({
                    name: 'tupdate',
                    path: '/testcasetask/update?testcaseid='+rowdata.testcaseid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if (btnstate == 'projectID') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid
                })
            }
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }
            this.gettestcaselist();
            setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#scrollToListGrid").offset().top -70
            });
        }, 500);
        },
        savewizard(mdl,objThis){      
            this.valid = this.validateForm(mdl,objThis);
            if (this.valid) {
                let tblNm='t39'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'testcase',
                    createdby: this.undt.username,
                    useremail  : this.undt.username,
                    empcode: this.undt.userid,

                }
                let apiURL='api/testcase/create'
                this.input.testcasename=this.testcase.testcasename
                this.input.project=this.testcase.project
                this.input.functionality=this.testcase.functionality
                this.input.task = this.testcase.task
                this.input.type=this.testcase.type
                this.input.attachment=this.testcase.attachment
                this.input.sevairty=this.testcase.sevairty
                this.input.priority=this.testcase.priority
                
                if(this.testcase.iseditstate){
                    this.testcase.iseditstate=false
                    apiURL='api/master/commonmaster/update'
                    if(tblNm == 't39'){
                        this.input.testcaseid=this.editRowData.testcaseid
                        apiURL='api/testcase/update'
                    }
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                if(this.isedit) {
                    this.isedit = false
                    apiURL='api/master/commonmaster/update'
                    if(tblNm == 't39'){
                        this.input.testcaseid=this.editRowData.testcaseid
                        apiURL='api/testcase/update'
                    }
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                }
                this.isAddState=false
                this.input.useremail=this.undt.username
                this.input.empcode= this.undt.userid

                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.isRequestActive = false
                        this.isDetActive = true
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.editRowData=this.issue
                        this.gettestcaselist()
                        this.resetForm()
                        $('.close').click();
                    }
                })
            }
        },
        notBeforeToday: function (date) {
            this.search.createddateto = "";
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        disabledBefore(date) {
            let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; 
        },
        selectedfromdate(){
            if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
                this.isBothDateSelected = false
            }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
                this.isBothDateSelected = true
            }
        },
        selectedtodate(){
            if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
                this.isBothDateSelected = false
            }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
                this.isBothDateSelected = true
            }
        },
        getSearchItems(search) {
            this.getcreatedbylist(search.createdby);
            this.projectnameList = JSON.parse(localStorage.getItem('testcaseprojectnameList'))
            this.search.testcasecodelist = JSON.parse(localStorage.getItem('testcasecodelist'))
            this.search.testcasecode = search.testcasecode
            this.search.searchprojectname = search.searchprojectname
            this.search.searchfunctionalityname = search.searchfunctionalityname
            this.search.searchtaskname = search.searchtaskname
            this.testcasenameList = JSON.parse(localStorage.getItem('testcasenameList'))
            this.search.searchtestcasename = search.searchtestcasename
            this.search.searchtypename = search.searchtypename
            this.search.searchseverityname = search.searchseverityname
            this.search.searchpriorityname = search.searchpriorityname
            this.search.searchstatus = search.searchtypename;
            this.search.createdby = search.createdby;
            this.search.createddatefrom = search.createddatefrom;
            this.search.createddateto = search.createddateto;
            this.search.scriptstatus = search.scriptstatus;
            this.isSearchRequestActive = true;
            this.projectChangebyid(this.search.searchprojectname);
            this.gettestcaselist(this.search);
        },
        getfunctionalitywithproject(pid){
            this.isLoading = true
            axios({
            'method': 'POST',
            'url': 'api/testcase/getfunctionalitywithproject',
            'data': {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid: pid,
            },
            'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.functionalitylist = result.data.data;
                    this.functionalitylist.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                    });
                } else {
                    this.functionalitylist = [];
                }
            }).catch(e => {
            this.displayError(e)
            })
        },
        gettaskwithprojectfunctionality(fid){
            this.isLoading = true
            axios({
            'method': 'POST',
            'url': 'api/testcase/gettaskwithprojectfunctionality',
            'data': {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                functionalityid: fid,
            },
            'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.tasklist = result.data.data;
                } else {
                    this.tasklist = [];
                }
            }).catch(e => {
            this.displayError(e)
            })
        },
        getcreatedby: function (node){
            if (node && node.length>2) {
                this.isLoading = true
                axios({
                'method': 'POST',
                'url': 'api/testcase/getemployeeemaildata',
                'data': {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    createdby: node.toLowerCase(),
                },
                'headers':{'authorization':this.tokendata}
                }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.search.createdbyopts = result.data.data
                } else {
                    this.search.createdbyopts = []
                }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        getcreatedbylist(createdbylist){
            if (createdbylist && createdbylist.length>0) {
                this.isLoading = true
                axios({
                'method': 'POST',
                'url': 'api/testcase/getemployeeemaildata',
                'data': {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    createdbylist: createdbylist,
                },
                'headers':{'authorization':this.tokendata}
                }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.search.createdbyopts = result.data.data;
                } else {
                    this.search.createdbyopts = [];
                }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        gettestcaselist(objThis,flag){
            if(objThis) {
                localStorage.setItem('testcasesearchItems', JSON.stringify(objThis))
            }
            if(flag && flag==1){
                this.page=1
                this.pageoffset=1
            }
            this.islistLoading=true
            let setmoduleform='testcasemaster'
            let glbMdlLblval='Test Case Master'
            let tblNm='t39'
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                this.glbMdlLbl = glbMdlLblval;
            }
            var apiURL = 'api/listing/gettestcaselist';
            this.input = {
                tbl:'t39',
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                // empid: this.undt.userid,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            let search = JSON.parse(localStorage.getItem('testcasesearchItems'))
            if(search) {
                this.input.projectname = search.searchprojectname
                this.input.functionlityname = search.searchfunctionalityname
                this.input.taskname = search.searchtaskname
                this.input.tcgroup = search.tcgroup
                this.input.testcasename = search.searchtestcasename
                this.input.testcasetypename = search.searchtypename
                this.input.sevairty = search.searchseverityname
                this.input.priority = search.searchpriorityname
                this.input.status = search.searchstatus
                this.input.testcasecode = search.testcasecode
                this.input.createdby = search.createdby
                this.input.scriptstatus = search.scriptstatus
                if(this.search.createddateto){
                var assignedate = new Date(this.search.createddateto)
                assignedate.setDate(assignedate.getDate() + 1)
                moment(String(assignedate)).format('YYYY-MM-DD');
                }
                this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
                this.input.dateto = this.search.createddateto?assignedate:null
            }
            this.getApplicationdata('t40','testcase')
            this.getApplicationdata('t41','testcase')
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.islistLoading=false; 
                this.showproject=true;   
                this.isresponce=true         
                this.forceRerender()
                if(result.data.errorCode == 0){
                    this.isDetActive=true;   
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }else{
                    this.isDetActive=true;   
                    this.companymasterlist=[];
                    this.totalcount = 0
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                }
            }).catch(e => {
            this.displayError(e)
            })
        },
        clearCodeList(state) {
            if(!state) {
                this.search.testcasecodelist = null
            }
        },
        testcaseCodeChangeSearch(node) {
            if(node && node.length > 1) {
                axios({
                    'method': 'POST',
                    'url': 'api/testcase/gettestcasecode',
                    'data': {
                        testcasecode: node.toUpperCase(),
                        useremail: this.undt.username,
                        empcode: this.undt.userid,

                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        this.search.testcasecodelist = result.data.data
                        localStorage.setItem('testcasecodelist', JSON.stringify(result.data.data))
                    } else {
                        this.search.testcasecodelist = null
                        localStorage.removeItem('testcasecodelist')
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
          projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                projectname:node.toLowerCase(),
                useremail: this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                isfrompage: 'listingpage'
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                    localStorage.setItem('testcaseprojectnameList', JSON.stringify(result.data.data));
                } else {
                    this.projectnameList = [];
                    localStorage.removeItem('testcaseprojectnameList');
                }
                }).catch(e => {
                    this.displayError(e)
                });
            } 
        },
        projectChangebyid: function (projectid){
           // if(projectid && projectid.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                projectidarr: projectid,
                useremail: this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                isfrompage: 'listingpage'
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                    localStorage.setItem('testcaseprojectnameList', JSON.stringify(result.data.data))
                } else {
                    this.projectnameList = [];
                    localStorage.removeItem('testcaseprojectnameList')
                }
                }).catch(e => {
                this.displayError(e)
                })
           // } 
        },
        getProjectname:function(){
            this.apiURL="api/listing/getProjectAllocatedToEmp"
            this.input={
                useremail: this.undt.username,
                empid :this.undt.userid,
                empcode: this.undt.userid,

            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {           
                if(result.data.errorCode == 0){
                    this.projectnameList = result.data.data;
                    localStorage.setItem('testcaseprojectnameList', JSON.stringify(result.data.data))
                }
                else{
                    this.projectnameList = [];
                    localStorage.removeItem('testcaseprojectnameList')
                }

            }).catch(e => {
                    this.displayError(e)
                })
        },
        testcaseChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/searching/getTestcaselist";
                this.input = {
                testcasename:node.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.testcasenameList = result.data.data;
                    localStorage.setItem('testcasenameList', JSON.stringify(result.data.data))
                } else {
                    this.testcasenameList = [];
                    localStorage.removeItem('testcasenameList')
                }
                }).catch(e => {
                    this.displayError(e)
                });
            } 
        },
        resetRecords: function() {
            this.search.testcasecode=null;
            this.search.searchprojectname = null;
            this.search.searchfunctionalityname = null;
            this.search.searchtestcasename= null;
            this.search.searchtaskname = null;
            this.search.searchtypename = null;
            this.search.searchseverityname = null;
            this.search.searchpriorityname = null;
            this.search.searchstatus = null;
            this.search.createddatefrom = null;
            this.search.createddateto = null;
            this.search.createdby = null;
            this.search.tcgroup = null;
            this.search.scriptstatus = null;
            this.isBothDateSelected = false;
            this.projectnameList = [];
            localStorage.removeItem('testcasesearchItems');
            localStorage.removeItem('testcaseprojectnameList');
            localStorage.removeItem('testcasenameList');
            localStorage.removeItem('testcasecodelist');
            this.page=1;
            this.gettestcaselist();
        },
        validateForm(mdl,objThis){
            this.clearerrorForm();
            this.isAddState=false
            var isValid=true;
            if(mdl=="t39"){
                if(!this.testcase.testcasename){
                    this.errors.testcase.testcasename="Test Case Name required";
                    isValid= false;
                }
                if(!this.testcase.project){
                    this.errors.testcase.project="Project required";
                    isValid= false;
                }
                if(!this.testcase.type){
                    this.errors.testcase.type="Type required";
                    isValid= false;
                }
                if(!this.testcase.sevairty){
                    this.errors.testcase.sevairty="Sevairty required";
                    isValid= false;
                }
                if(!this.testcase.priority){
                    this.errors.testcase.priority="Priority required";
                    isValid= false;
                }
               
            }
            return isValid
        },
        dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMONFormat(value){
            if(value){
                return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
            }else{
                return "";
            }
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        clearerrorForm(){
            this.errors={
                testcase:{
                    testcasename:null,
                    project:null,
                    functionality:null,
                    type:null,
                    attachment:null,
                    sevairty:null,
                    priority:null,
                }
            }
        },
        openEditModule(currRowData, flag){
            this.view.projectname=currRowData.projectname
            this.view.functionlityname=currRowData.functionlityname
            this.editRowData = currRowData
            this.testcase.testcasename = currRowData.testcasename
            this.view.projectid=currRowData.projectid
            this.testcase.project = currRowData.project
            this.testcase.functionality = currRowData.functionality
            this.testcase.task = currRowData.taskid
            this.testcase.type = currRowData.issuetype
            this.testcase.attachment = currRowData.attachment
            this.testcase.attachmentview = currRowData.attachment
            this.testcase.sevairty = currRowData.sevairty
            this.testcase.priority = currRowData.priority
            this.testcase.iseditstate = true
            this.isAddState=true
            if(flag==0){
                this.isedit=true
                this.isRequestActive=true
                this.mydetailscollapse()
            }
        },
        dateFormat2(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:MM:SS');
        },
        addmodule(){
            this.testcase.testcasename = null
            this.testcase.project = null
            this.testcase.functionality = null
            this.testcase.task = null
            this.testcase.type = null
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            this.testcase.sevairty = null
            this.testcase.priority = null
            this.testcase.iseditstate = false
            this.testcase.disblesavebtn=true
        },
        seltestcsproject(state,value){
            this.testcase.project=null
            this.testcase.project=state
            if(!state){
                this.testcase.functionality=null
                this.testcase.task = null
                this.testcase.functionalitylist = null
                this.testcase.tasklist = null
            }else{
                // this.getApplicationdata('t37','testcase')
            }
        },     
        seltestcsfunctionality(state,value){
            this.testcase.functionality=null
            this.testcase.functionality=state
            if(!state){
                this.testcase.task = null
                this.testcase.tasklist = null
            }else{                
                // this.getApplicationdata('t18', 'testcase')
            }
        },
        seltestcsprojectsearch(state,value){
            this.search.searchprojectname = null
            this.search.searchprojectname = state
            if(!state){
                this.search.searchfunctionalityname=null
                this.search.functionalitylist = null
                this.search.tasklist = null
                this.search.searchtaskname=null
                this.search.searchtaskname = null
                this.search.tasklist = null
                this.errors.testcase.project='Project required'
                this.functionalitylist=[]
                this.tasklist=[]
            }else{
                // this.getApplicationdata('t37','testcaseSearch')
                if (state && state.length>0) {
                    this.getfunctionalitywithproject(state)
                }else{
                    this.functionalitylist=[]
                    this.tasklist=[]
                    this.search.searchfunctionalityname=null
                    this.search.searchtaskname=null
                }
            }
        },     
        seltestcsfunctionalitysearch(state,value){
            this.search.searchfunctionalityname=null
            this.search.searchfunctionalityname=state
            if(!state){
                this.search.searchtaskname = null
                this.search.tasklist = null
                this.tasklist=[]
            }else{                
                // this.getApplicationdata('t18', 'testcaseSearch')
                if (state && state.length>0) {
                    this.gettaskwithprojectfunctionality(state)
                }else{
                    this.tasklist=[]
                    this.search.searchtaskname=null
                }
            }
        },
        seltestcstype(state,value){
            this.testcase.type=null
            this.testcase.type=state
            if(!state && !this.isAddState){
                this.errors.testcase.type='Type required'
            }else{                
                this.errors.testcase.type=null
            }
        },
        seltestcssevairty(state,value){
            this.testcase.sevairty=null
            this.testcase.sevairty=state
            if(!state && !this.isAddState){
                this.errors.testcase.sevairty='Sevairty required'
            }else{                
                this.errors.testcase.sevairty=null
            }
        },
        seltestcspriority(state,value){
            this.testcase.priority=null
            this.testcase.priority=state
            if(!state && !this.isAddState){
                this.errors.testcase.priority='Priority required'
            }else{                
                this.errors.testcase.priority=null
            }
        },
        seltestcsstatus(state,value){
            this.testcase.status=null
            this.testcase.status=state
            if(!state && !this.isAddState){
                this.errors.testcase.status='Status required'
            }else{                
                this.errors.testcase.status=null
            }
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/list'
            if(mdl == 't11'){
                apiURL='api/listing/getProjectAllocatedToEmp'
                this.inputappl = {
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                }
            }else if(mdl == 't37' && curracttab == 'testcase'){                
                this.inputappl = {};
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.testcase.project
            }else if(mdl == 't37' && curracttab == 'testcaseSearch'){                
                this.inputappl = {};
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.search.searchprojectname
            }else if(mdl == 't40'){
                apiURL='api/master/commonmaster/customselect'
                /* this.inputappl.projectmoduleid=this.testcase.project */
                this.inputappl.isSearch= false
                this.inputappl.section= "testcase"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            }else if(mdl == 't41'){
                apiURL='api/master/commonmaster/customselect'
                this.inputappl.isSearch= false
                this.inputappl.section= "testcase"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            } else if (mdl == 't18' && curracttab == 'testcase') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.testcase.functionality
                }
            } else if (mdl == 't18' && curracttab == 'testcaseSearch') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.search.searchfunctionalityname
                }
            } else if (mdl == 't39') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    testcasename: this.testcase.testcasename
                }
            }
            this.inputappl.useremail=this.undt.username
            this.inputappl.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                if(result.data.errorCode == 0){
                    if(mdl == 't11'){
                        if(curracttab == 'testcase'){
                            this.testcase.projectlist=result.data.data
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcase'){
                        if(curracttab == 'testcase'){
                            this.testcase.functionalitylist=result.data.data
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcaseSearch'){
                        this.search.functionalitylist=result.data.data
                        return true
                    } 
										else if(mdl == 't40'){
                        if(curracttab == 'testcase'){
                            // this.testcase.typelist=result.data.data.rows
														this.sortTypeList([],'testcasetypelidt')
                            return true
                        }
                    }
										else if(mdl == 't41'){
                        if(curracttab == 'testcase'){
                            this.testcase.sevairtylist=result.data.data.rows
                            return true
                        }
                    } else if(mdl == 't18' && curracttab == 'testcase') {
                            this.testcase.tasklist = result.data.data
                            return true
                    } else if(mdl == 't18' && curracttab == 'testcaseSearch') {
                        this.search.tasklist = result.data.data
                        return true
                    } else if(mdl == 't39') {
                        if(curracttab == 'testcase') {
                            this.testcase.testcasenameopts = result.data.data
                            return true
                        }
                    }
                }
                else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                    this.displayError(e)
                })
            
        },
        onSelect(e){
            this.file = e.target.files[0];
            if(this.file.length>0)
            this.testcase.disblesavebtn = true
        },
        async onUpload(){
            this.isLoading=true
            if(this.file){
                if(this.file.name!==""){
                    let arrGlbMdl=this.glbMdl.split('##')
                    let inputEle='';
                    let btnEle='';
                    if(arrGlbMdl.length > 0){
                        if(typeof arrGlbMdl[0] != 'undefined'){
                            inputEle=arrGlbMdl[0];
                        }
                        if(typeof arrGlbMdl[1] != 'undefined'){
                            btnEle=arrGlbMdl[1];
                        }
                    }
                    if(inputEle == 't39'){
                        btnEle='testcase'
                    }
                    this.apiURL="api/master/fileupload/uploadTestCaseFileToS3"
                    let formData = new FormData();
                    formData.append("imagefile", this.file);
                    formData.append("empcode", this.undt.userid);
                    formData.append("useemail",this.undt.username);
                    formData.append("moduletype", btnEle);
                    formData.imagefile = this.file;
                    formData.empcode=this.undt.userid
                    formData.moduletype=btnEle
                    await axios({
                        'content-type':'multipart/form-data',
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':formData,
                        'headers':{'authorization':this.tokendata}
                    })
                    .then(result => { 
                        this.isLoading=false;            
                        if(result.data.status){
                            this.testcase.disblesavebtn=false
                            this.testcase.attachment=result.data.data;
                            this.testcase.attachmentview=result.data.data;
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    })
                }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: 'Select image/docs/videos',
                        icon: 'error',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.testcase.attachment=null
                }
            }else{
                    Swal.fire({
                        title: "Oops...!",
                        text: 'Select image/docs/videos',
                        icon: 'error',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.testcase.attachment=null
                }
            
        
        },

      ExportXL() {
        this.islistLoading = true;
        this.exportxlsdata = [];
        this.exporterr = "";
        this.input = {
            limit:null,
            offset:null,
            projectname : this.search.searchprojectname,
            functionlityname : this.search.searchfunctionalityname,
            taskname : this.search.searchtaskname,
            testcasename : this.search.searchtestcasename,
            testcasetypename : this.search.searchtypename,
            sevairty : this.search.searchseverityname,
            priority : this.search.searchpriorityname,
            tcgroup : this.search.tcgroup,
            status : this.search.searchstatus,
            testcasecode : this.search.testcasecode,
            createdby : this.search.createdby,
            useremail: this.undt.username,
            tbl:'t39',
            empcode: this.undt.userid
        };
        if(this.search.createddateto){
          var assignedate = new Date(this.search.createddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
        this.input.dateto = this.search.createddateto?assignedate:null
        this.input.scriptstatus = this.search.scriptstatus
        axios({
            method: "POST",
            url: `api/listing/gettestcaselist`,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.islistLoading = false;
            if (result.data.errorCode == 0) {
                this.exportxlsdata = result.data.data.rows;
                var filename = "";
                this.download(result.data.data.rows,filename)
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }
        }).catch(e => {
            this.displayError(e)
        });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'BusinessTaskReportDetails_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Test Case No,Test Case Name,Countries,Environment,Language,Script Type,Project Name,Functionality,Type,Severity,Priority,Created By,Created Date,Created,Review In Progress,Reviewed,Approved,Freeze' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let testcasecode = index.hasOwnProperty('testcasecode') ? index.testcasecode == null||index.testcasecode.toLowerCase() == 'na'?'':index.testcasecode : '';
          let countrynamelist = index.hasOwnProperty('countrynamelist') ? index.countrynamelist == null||index.countrynamelist.toLowerCase() == 'na'?'':index.countrynamelist : '';
          let enviornmentnamelist = index.hasOwnProperty('enviornmentnamelist') ? index.enviornmentnamelist == null||index.enviornmentnamelist.toLowerCase() == 'na'?'':index.enviornmentnamelist : '';
          let languagenamelist = index.hasOwnProperty('languagenamelist') ? index.languagenamelist == null||index.languagenamelist.toLowerCase() == 'na'?'':index.languagenamelist : '';
          let scripttypenamelist = index.hasOwnProperty('scripttypenamelist') ? index.scripttypenamelist == null||index.scripttypenamelist.toLowerCase() == 'na'?'':index.scripttypenamelist : '';
          let testcasename = index.hasOwnProperty('testcasename') ? index.testcasename.replace(/"/g, '""') : '';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname.replace(/"/g, '""') : 'NA';
          let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null||index.functionlityname.toLowerCase() == 'na'?'':index.functionlityname : '';
          let testcasetypename = index.hasOwnProperty('testcasetypename') ? index.testcasetypename : '';              
          let testcasesevairtyname = index.hasOwnProperty('testcasesevairtyname') ? index.testcasesevairtyname : '';
          let priority = index.hasOwnProperty('priority') ? index.priority : '';
          let createdby = index.hasOwnProperty('createdby') ? index.createdby : '';
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          createddate = this.dateMONFormat(createddate)
          let createdcount = index.hasOwnProperty('createdcount') ? index.createdcount : '';
          let reviewinprgressocount = index.hasOwnProperty('reviewinprgressocount') ? index.reviewinprgressocount : '';
          let reviewedcount = index.hasOwnProperty('reviewedcount') ? index.reviewedcount : '';
          let approvedcount = index.hasOwnProperty('approvedcount') ? index.approvedcount : '';
          let freezecount = index.hasOwnProperty('freezecount') ? index.freezecount : '';
            row += '"' + srno + '",',
            row += '"' + testcasecode + '",',
            row += '"' + testcasename + '",',
            row += '"' + countrynamelist + '",',
            row += '"' + enviornmentnamelist + '",',
            row += '"' + languagenamelist + '",',
            row += '"' + scripttypenamelist + '",',
            row += '"' + projectname + '",',
            row += '"' + functionlityname + '",',
            row += '"' + testcasetypename + '",',
            row += '"' + testcasesevairtyname + '",',
            row += '"' + priority + '",',
            row += '"' + createdby + '",',
            row += '"' + createddate + '",'
            row += '"' + createdcount + '",'
            row += '"' + reviewinprgressocount + '",'
            row += '"' + reviewedcount + '",'
            row += '"' + approvedcount + '",'
            row += '"' + freezecount + '",'
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
          return;
        }
        let fileName = 'Test Case Details_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        } else {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        }
    },
    export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
		sortTypeList(typelist, type){
      this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'testcase',
          typearr: [],
          projecttype: type
      };
      axios({
          method: "POST",
          url: 'api/ticket/sortTypeList',
          data: this.input,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.testcase.typelist = result.data.data.rows;
              this.testcase.typelist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
          } else {
              this.testcase.typelist = [];
          }
      });
    }
  }
}
</script>
