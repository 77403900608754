<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header-left mb-0 pt-0 ">
        <div class="container-fluid">
            <div class="row breadcrumbs-top">
                <div class="col-md-9 content-header-left px-0 ">
                    <h3 class="content-header-title float-left mb-0">Employee</h3>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link
                                    to=''>Attendance Summary Report</router-link></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <section id="modern-horizontal-wizard">
        
        <div class=" text-editar_discription_jobs masters_detailstabing_pills px-0">
          <ul class="nav nav-pills nav-pill-warning pt-1" id="nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="attend-tab" data-toggle="tab" href="javascript:void();" @click="attendtab($event,'attendance')" role="tab" aria-controls="attend" aria-selected="false">Attendance Report</a>
            </li>
            <li  class="nav-item">
              <a class="nav-link" id="summary-tab" data-toggle="tab" href="javascript:void();" @click="summarytab($event,'summary')" role="tab" aria-controls="summary" aria-selected="true">Summary Report </a>
            </li>
          </ul>
        </div>

        <div class="" id="projecttabmaster" v-if="ispage && ispage == 'attendance'">
          <div class="card filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="myequestcollapse">
              <div class="container-fluid">
                  <div class="row row fc_my-05">
                      <div class="w-auto px-1 mobile-padding-0-2">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                          <h4 class="card-title d-inline-flex ml-1">Search Attendance Report</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                          <em class="las la-angle-down"></em>
                      </span>
                  </div>
              </div>
            </div>
            <div class="card-body pt-1" v-bind:class="[{show: isRequestActive},card_content,collapse]">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <div class="form-group mb-1">
                      <label for="allotedto">Stockist</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Stockist"  v-model="search.stockist" :options="search.stockistlist" @input="selstokist"/>
                      <!-- <div class="errorinputmsg" v-if="this.errors.search.stockist">{{ this.errors.search.stockist }}</div> -->
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <div class="form-group mb-1">
                      <label for="allotedto">Employee Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Employee" :multiple="true" :clear-on-select="true" v-model="search.empid" :options="search.emplist"/>
                      <!-- <div class="errorinputmsg" v-if="this.errors.search.empid">{{ this.errors.search.empid }}</div> -->
                    </div>
                  </div>
                  <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                 <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Leave Type</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Leave Type" v-model="search.type" :options="leaveTypes"/>
                </div>
                 </div>
                 <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                 <div class="form-group mg-bot0">
                  <label for="projectmdllistdd">Attendance Status</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Attendance Status" v-model="search.status" :options="statuslist"/>
                </div>
                 </div>
               <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-1">
                <label for=""> Punchin Date From</label>
                  <date-picker placeholder="Select Punchin Date From" v-model="search.logindatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate" @close="validateForm()"></date-picker>
                  <div class="errorinputmsg" v-if="this.errors.search.logindatefrom">{{ this.errors.search.logindatefrom }}</div>
                  <!-- <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to export reports.</div>  -->
              </div>
              <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-1">
                <label for="">Punchout Date To</label>
                  <date-picker placeholder="Select Punchout Date To" v-model="search.logindateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate" @close="validateForm()"></date-picker>
                  <div class="errorinputmsg" v-if="this.errors.search.logindateto">{{ this.errors.search.logindateto }}</div>
              </div>
              <div class="col-12 col-lg-6 text-right">
                <lable class="w-100 d-block"> &nbsp;</lable>
                  <button type="submit" class="btn btn-relief-primary" :disabled="isBothDateSelected" v-on:click.stop.prevent="getstockistreport(search,1)"> 
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                  <button type="button" class="btn btn-relief-primary ml-1" :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                  <button style="margin-left:8px;" type="button" class="btn btn-outline-secondary ml-1" @click="cleardata">Cancel</button>
                </div>
                </div>
               
              </form>
            </div>
          </div>
          <div  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top:1rem; margin-bottom: 1rem !important;">
                    <div>
                            <div class="row">
                            <div class="col-md-12 1">
                                <div class="card ">
                                <div class="">
                                    <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="table-small">
                                        <div class="col-12">
                                            <div class="card">
                                            <div class="" >
                                            </div>
                                            <div class="table-responsive datatableview" >
                                                <table class="table table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                                <thead>
                                                    <tr>
                                                    <th scope="col" class="align-middle py-1" style="min-width: 100px;">
                                                        
                                                        <span class="px-1"> Sr#</span>
                                                    </th>
                                                    <th scope="col" class="align-middle ">Stockist</th>
                                                    <th scope="col" class="align-middle" style="min-width:130px">Employee Name</th>
                                                    <th scope="col" class="align-middle ">Leave Type</th>
                                                    <th scope="col" class="align-middle ">Attendance Status</th>
                                                    <th scope="col" class="align-middle ">Punch In</th>
                                                    <th scope="col" class="align-middle ">Punch Out</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(data,index) in attendanceslist">   
                                                        <tr v-bind:key="index">
                                                            <td data-column="Sr#" >
                                                                <span class="">
                                                                    <span class="ml-1"> {{ index+1+currPageNum*pagelimit }} </span>
                                                                </span>
                                                            </td>
                                                            <td class="font-weight-normal capitalisetext text-left">{{data.department ? data.department:'-'}}</td>
                                                            <td  class="align-middle text-left">{{data.empID ? data.empID:'-'}}</td>
                                                            <td  class="font-weight-normal capitalisetext text-left">{{data.leaveDetail && data.leaveDetail.leaveType ? data.leaveDetail.leaveType : '-'}}</td>
                                                            <td  class="font-weight-normal capitalisetext text-left">{{data.status ? data.status:'-'}}</td>
                                                            <td class="align-middle text-left">{{data.punchedInAt ? dateMonthFormat(data.punchedInAt) : '-'}}</td> 
                                                            <td  class="align-middle text-left">{{data.punchedOutAt ? dateMonthFormat(data.punchedOutAt) : '-'}}</td>                                                        
                                                          </tr>
                                                        
                                                    </template>

                                                    <template v-if="attendanceslist.length==0">
                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                    </template>
                                                </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <!-- <div class="pageCenter text-center">
                                    <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div> -->
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
          </div>
        </div>

        <div class="" id="managertabmaster" v-if="ispage && ispage == 'summary'">
          <div class="card filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="managerequestcollapse">
              <div class="container-fluid">
                  <div class="row row fc_my-05">
                      <div class="w-auto px-1 mobile-padding-0-2">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                          <h4 class="card-title d-inline-flex ml-1">Search Summary Report</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                          <em class="las la-angle-down"></em>
                      </span>
                  </div>
              </div>
            </div>
            <div class="card-body pt-1" v-bind:class="[{show: isRequestActive2},card_content,collapse]">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <div class="form-group mb-1">
                      <label for="allotedto">Stockist</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Stockist"  v-model="search.stockist" :options="search.stockistlist" @input="selstokist"/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <div class="form-group mb-1">
                      <label for="allotedto">Employee Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Employee" :multiple="true" :clear-on-select="true" v-model="search.empid" :options="search.emplist"/>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                    <div class="form-group mb-1">
                      <label for="allotedto">Month</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Month" :multiple="true" :clear-on-select="true" v-model="search.month" :options="monthlist"/>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6  col-lg-3 text-right">
                  <lable class="w-100 d-block"> &nbsp;</lable>
                  <button type="button" class="btn btn-relief-primary ml-1" @click="ExportXL1()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                  <button style="margin-left:8px;" type="button" class="btn btn-outline-secondary" @click="cleardata">Cancel</button>
                </div>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
  .table_input_width_150 tbody td{
    min-width: 150px;
  }
  .createbtnparentdiv{
    padding-right: 0% !important;
  }
  .displayblk{
    display:block !important;
  }
  .createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  .btn_deletedata .btn_deletedata_icons{
    width: 24px;
    height: 24px;
    margin: 5px 10px;
  }
</style>
<script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
  import { EyeIcon, SearchIcon, MoreVerticalIcon, Edit2Icon, DownloadIcon,ExternalLinkIcon,PlusSquareIcon, PlusCircleIcon, MonitorIcon, Trash2Icon } from 'vue-feather-icons'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import DatePicker from "vue2-datepicker";
  import Pagination from 'vue-pagination-2'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { json } from 'body-parser';
  import commonMethods from '../../utils/commonMethods';

  
  export default {
    name:'Attendancereport',
    mixins: [ commonMethods ],
    components:{
      Loading,
      VueElementLoading,PlusCircleIcon,
      vSelect,
      EyeIcon,
      Treeselect,
       DatePicker,
      SearchIcon,
      Pagination,
      MoreVerticalIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ExternalLinkIcon,
      MonitorIcon,Trash2Icon
    },    
    data() {
      return {
        isThisBusinessRole: false,
        loginuser:'',
        isLoading: false,
        fullPage: true,
        companymasterlist: [],
        editRowData:[],
        chkselectfieldlist: [],
        TimeFormat: apiUrl.TimeFormat,
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        isBothDateSelected:false,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        pagefeatures:[],
        isRequestActive: true,
        isDetailsActive: false,
        showtabledata:true,
        card_content:'card-content',
        collapse:'collapse',
        leaveTypes:apiUrl.leaveTypes,
        statuslist: [{ 
          id: 'present',
          label: 'Present',
      },
      {
          id : 'absent',
          label: 'Absent',
      },{
          id : 'singlepunch',
          label: 'Single punch',
      },{
          id : 'missedSwipe',
          label: 'Missed Swipe',
        },],
        monthlist: [{ 
          id: '01',
          label: 'January',
          },{
          id : '02',
          label: 'February',
          },{
          id : '03',
          label: 'March',
          },{
          id : '04',
          label: 'April',
          },{ 
          id: '05',
          label: 'May',
          },{
          id : '06',
          label: 'June',
          },{
          id : '07',
          label: 'July',
          },{
          id : '08',
          label: 'August',
          },{ 
          id: '09',
          label: 'September',
          },{
          id : '10',
          label: 'October',
          },{
          id : '11',
          label: 'November',
          },{
          id : '12',
          label: 'December',
          },],
        search:{
          empid: null,
          logindatefrom: null,
          status: null,
          type: null,
          stockist: null,
          month:null,
          logindateto: null,
          emplist: [],
          stockistlist: [],
        },
        errors:{
          search:{
            // empid:null,
            logindatefrom: null,
            logindateto: null,
            stockist: null,
          }
        },
        emplist: [],
        tokendata: null,
        ispage: 'attendance',
        isRequestActive2: true,
        isDetailsActive2: false,
        attendanceslist: []
      }
    },
    mounted(){
      // let permission = this.$Service.verifyAccess();
      // if(permission){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');
          this.getstockistlist();
          // this.getStokistEmpList()
        }
      // }
      $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
    },
    methods:{ 
      notBeforeToday: function (date) {
      this.search.logindateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      disabledBefore(date) {
      let dayBefore = moment(this.search.logindatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
      },
      selectedfromdate(){
      if((this.search.logindatefrom && this.search.logindateto) || (!this.search.logindatefrom && !this.search.logindateto)){
        this.isBothDateSelected = false;
      }else if((this.search.logindatefrom && !this.search.logindateto) ||( !this.search.logindatefrom && this.search.logindateto)){
        this.isBothDateSelected = true;
        this.errors.search.logindatefrom ='';
      }
      },
      disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const selectedFromDate = new Date(this.search.logindatefrom);
      return date < selectedFromDate || date > today;
      },
      selectedtodate(){
      if((this.search.logindatefrom && this.search.logindateto) || (!this.search.logindatefrom && !this.search.logindateto)){
        this.isBothDateSelected = false;
        this.errors.search.logindateto ='';
      }else if((this.search.logindatefrom && !this.search.logindateto) ||( !this.search.logindatefrom && this.search.logindateto)){
        this.isBothDateSelected = true;
      }
      },
      myequestcollapse:function(){
        this.isRequestActive = !this.isRequestActive
      },
      managerequestcollapse:function(){
        this.isRequestActive2 = !this.isRequestActive2
      },
      cleardata(){
        this.search.empid = null;
        this.search.stockist = null;
        this.search.month = null;
        this.search.emplist = [];
        this.search.status = null;
        this.search.type = null;
        this.search.logindatefrom = null;
        this.search.logindateto = null;
        this.isDetailsActive = false;
        this.totalcount = 0;
      },
      attendtab(event,setpage){
        this.ispage = setpage;
        this.cleardata();
        this.totalcount = 0;
        this.pageCount = 0;
        this.page = 1;
        this.currPageNum = 0;
        this.pageoffset = 1;
        this.isDetailsActive = false;
        this.isDetailsActive2 = false;
      },
      summarytab(event,setpage){
        this.ispage = setpage;
        this.cleardata();
        this.totalcount = 0;
        this.pageCount = 0;
        this.page = 1;
        this.currPageNum = 0;
        this.pageoffset = 1;
        this.isDetailsActive = false;
        this.isDetailsActive2 = false;
      },
      dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
      },
      dateMONFormat(value){
          if (value){
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
          } else{
            return ""
          }
        },
      ExportXL() {     
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
        employeeIDs: this.search.empid,
        department: this.search.stockist,
        leavetype:this.search.type,
        status:this.search.status
      };     
      if(this.search.logindateto){
        var assignedate = new Date(this.search.logindateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.punchinfromDate = this.search.logindatefrom?this.search.logindatefrom:null
      this.input.punchouttoDate = this.search.logindateto?assignedate:null 
      axios({
        method: "POST",
        url: `api/thirdpartyfcclock/getBulkAttendanceList`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.responsedata[0];
          var filename = "";
          this.download(result.data.data.responsedata[0],filename)
        }else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }      
        this.exportxlsdata = [];  
      }).catch(e => {
        this.displayError(e)
      });

       },
      download(readExportdata) {
          if(readExportdata.length > 0) {
          let JSONData = readExportdata
          let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          let row = ''
          let CSV = 'Sr#,ReferenceID,EmployeeID,User Name,Department,Leave Type, Login date,PunchedInAt,PunchedOutAt,WorkingHours,AttendanceStatus,PunchInPremise,PunchOutPremise,Remark' + '\r\n'
          for (let i=0; i<arrData.length; i++) {
              let index = arrData[i]
              let srno = i+1
              let referenceID = index.hasOwnProperty('referenceID') ? (index.referenceID?index.referenceID:'NA') : ''
              let empID = index.hasOwnProperty('empID') ? (index.empID?index.empID:'NA') : ''
              let userName = index.hasOwnProperty('userName') ? (index.userName?index.userName:'NA') : ''
              let department = index.hasOwnProperty('department') ? (index.department?index.department:'NA') : ''
              // let leavetype = index.leaveDetail && index.leaveDetail.leaveType == null ? (index.leaveDetail.leaveType.toLowerCase() === 'na' ? '' : index.leaveDetail.leaveType) : 'NA';
              let logindate = index.hasOwnProperty('date') ? index.date ==null || index.date.toLowerCase() =='na'?'':index.date : 'NA';
              let leavetype =index.leaveDetail && index.leaveDetail.leaveType ? index.leaveDetail.leaveType : 'NA'
              let punchin = index.hasOwnProperty('punchedInAt') ? index.punchedInAt ==null || index.punchedInAt.toLowerCase() =='na' ? '':index.punchedInAt : 'NA';
              let punchout = index.hasOwnProperty('punchedOutAt') ? index.punchedOutAt ==null || index.punchedOutAt.toLowerCase() =='na' ? '':index.punchedOutAt : 'NA';
              // let workinghours = index.hasOwnProperty('workinghours') ? index.workinghours ==null || index.workinghours.toLowerCase() =='na' ? '':index.workinghours : 'NA';
              let attendancestatus = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? '':index.status : '';
              let punchinpremise = index.punchInPremise && index.punchInPremise.name ? index.punchInPremise.name : 'NA'
              let punchOutPremise = index.punchOutPremise && index.punchOutPremise.name ? index.punchOutPremise.name : 'NA'
              let remark = index.attendanceUser && index.attendanceUser.remark ? index.punchInPremise.remark : 'NA'

              logindate = this.dateMonthFormat(logindate)
              punchin = this.dateMonthFormat(punchin)
              punchout = this.dateMonthFormat(punchout)
              let punchinTime = new Date(punchin);
              let punchoutTime = new Date(punchout);
              // let timeDifferenceMs = punchoutTime - punchinTime;
              let workinghours = punchin && punchout ? (punchoutTime - punchinTime) / (1000 * 60 * 60) : null;
              if (workinghours !== null) {
                  workinghours = workinghours.toFixed(2);
              } else {
                  workinghours = 'NA';
              }
              row += '"' + srno + '",'
              row += '"' + referenceID + '",'
              row += '"' + empID + '",'
              row += '"' + userName + '",'
              row += '"' + department + '",'
              row += '"' + leavetype + '",'
              row += '"' + logindate + '",'
              row += '"' + punchin + '",'
              row += '"' + punchout + '",'
              row += '"' + workinghours + '",'
              row += '"' + attendancestatus + '",'
              row += '"' + punchinpremise + '",'
              row += '"' + punchOutPremise + '",'
              row += '"' + remark + '",'
              row += '\r\n'
          }
          CSV += row
          if (CSV === '') {
              Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
              });
              return;
          }
          let fileName = ' Employee-Attendance-details' + moment().format('DD_MM_YY_HH_mm_ss')
          let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
          let link = document.createElement('a')
          link.href = uri
          link.style = 'visibility:hidden'
          link.download = fileName + '.csv'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          Swal.fire({
              title: "Success!",
              text: " Employee_Attendance_Details Exported successfully",
              icon: "success",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          });
          } else {
          Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          });    
          }
      },
      ExportXL1() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
      };
      axios({
          method: "POST",
          url: `api/thirdpartyfcclock/getBulkAttendanceList`,
          data: this.input,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download1(result.data.data.rows,filename)
          }else {
          Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          });
          }      
          this.exportxlsdata = [];  
      }).catch(e => {
          this.displayError(e)
      });
   
  },
  download1(readExportdata) {
    if(readExportdata.length > 0) {
      let JSONData = readExportdata
      let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
      let row = ''
      let CSV = 'Sr#,Month,Shift Name,Extra Hours,Deficit,Total Present Days, Total Absent Days,Total Leaves Taken' + '\r\n'
      for (let i=0; i<arrData.length; i++) {
        let index = arrData[i]
        let srno = i+1
        let month = index.hasOwnProperty('month') ? index.month ==null || index.month.toLowerCase() =='na' ? '':index.month : '';
        let shiftname = index.hasOwnProperty('shiftname') ? (index.shiftname?index.shiftname:'NA') : ''
        let extrahours = index.hasOwnProperty('extrahours') ? (index.extrahours?index.extrahours:'NA') : ''
        let deficit = index.hasOwnProperty('deficit') ? (index.deficit?index.deficit:'NA') : ''
        let presentdays = index.hasOwnProperty('presentdays') ? index.presentdays ==null || index.presentdays.toLowerCase() =='na'?'':index.presentdays : '';
        let absentdays = index.hasOwnProperty('absentdays') ? index.absentdays ==null || index.absentdays.toLowerCase() =='na'?'':index.absentdays : '';
        let leavestaken = index.hasOwnProperty('leavestaken') ? index.leavestaken ==null || index.leavestaken.toLowerCase() =='na'?'':index.leavestaken : '';
        row += '"' + srno + '",'
        row += '"' + month + '",'
        row += '"' + shiftname + '",'
        row += '"' + extrahours + '",'
        row += '"' + deficit + '",'
        row += '"' + presentdays + '",'
        row += '"' + absentdays + '",'
        row += '"' + leavestaken + '",'
        row += '\r\n'
      }
      CSV += row
      if (CSV === '') {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        return;
      }
      let fileName = ' Employee-Summary-details-' + moment().format('DD_MM_YY_HH_mm_ss')
      let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
      let link = document.createElement('a')
      link.href = uri
      link.style = 'visibility:hidden'
      link.download = fileName + '.csv'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      Swal.fire({
        title: "Success!",
        text: " Employee_Summary_Details  Exported successfully",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });    
    }
  },
  selstokist(state){
    if(state){
      this.getStokisWisUser(state.toString());
    }
  },
  selstokist1(state){
    if(state){
      this.getStokisWisUser(state.toString());
    }
  },
  getStokisWisUser(stokist){
        this.isLoading = true;
        this.input ={
          useremail: this.undt.username,
          empcode: this.undt.userid,
          department: stokist
        }
        axios({
          'method': 'POST',
          'url': 'api/thirdpartyfcclock/getUserList',
          'data': this.input,
          'headers': {'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.errorCode == 0){
            this.search.emplist = result.data.data.rows;
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.search.emplist = [];
          }
        }).catch(e => {
          this.displayError(e);
        });
      },

  getstockistlist() {
    this.isLoading =true;
    this.input ={
      useremail : this.undt.username,
      empcode: this.undt.userid
    }
    axios({
      'method': 'POST',
      'url': 'api/thirdpartyfcclock/getStokistList',
      'data': this.input,
      'headers': {'authorization':this.tokendata}
    })
    .then(result => {
      this.isLoading =false;
      if (result.data.errorCode == 0) {
        this.search.stockistlist = result.data.data.rows.data;
      }else if(result.data.errorCode == 3){
        Swal.fire({
          title: "Session Expired...!!",
          text: result.data.msg,
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        }).then(function() {
          window.location.href = "/#/login";
        })
      }else {
        this.search.stockistlist = [];
      }
    }).catch(e => {
      this.displayError(e);
    })
  },
  getStokistEmpList(){ 
  this.isLoading = true;
  this.input ={
      useremail: this.undt.username,
      empcode: this.undt.userid,
  }
  axios({
  'method': 'POST',
  'url': 'api/thirdpartyfcclock/getAllStokistEmp',
  'data': this.input,
  'headers': {'authorization':this.tokendata}
  })
  .then(result => {
  this.isLoading = false;
  if(result.data.errorCode == 0){
      this.search.emplist = result.data.data.rows;
      console.log(this.search.emplist,"emlist===>")
      this.totalcount = this.search.emplist.length;
      this.pageCount = Math.ceil(this.totalcount / this.pagelimit)

  }else if(result.data.errorCode == 3){
      Swal.fire({
      title: "Session Expired...!!",
      text: result.data.msg,
      icon: 'info',
      customClass: {
          confirmButton: "btn btn-primary",
      },
      buttonsStyling: !1,
      }).then(function() {
      window.location.href = "/#/login";
      })
  }else{
      this.totalcount = 0
      this.pageCount =0
      this.empList = [];
  }
  }).catch(e => {
  this.displayError(e);
  });
},
  getstockistreport(search){
    let isvalid = true;
    isvalid = this.validateForm();
    if(isvalid){
      this.isLoading = true;
      this.input ={
        useremail: this.undt.username,
        empcode: this.undt.userid,
        department: search.stockist,
        employeeIDs: search.empid,
        leavetype:search.type,
        status:search.status
      }
      if(this.search.logindateto){
        var assignedate = new Date(this.search.logindateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.punchinfromDate = this.search.logindatefrom?this.search.logindatefrom:null
      this.input.punchouttoDate = this.search.logindateto?assignedate:null 
      axios({
        'method': 'POST',
        'url': 'api/thirdpartyfcclock/getBulkAttendanceList',
        'data': this.input,
        'headers': {'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false;
        if(result.data.errorCode == 0){
          this.attendanceslist = result.data.data.responsedata[0];
          console.log(this.attendanceslist,'attendanceslist--------------------');
        }else if(result.data.errorCode == 3){
          Swal.fire({
            title: "Session Expired...!!",
            text: result.data.msg,
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          }).then(function() {
            window.location.href = "/#/login";
          })
        }else{
          this.attendanceslist = [];
        }
      }).catch(e => {
        this.displayError(e);
      });
    }
  },
  validateForm(){
    let isvalid =true;
    // if(!this.search.stockist){
    //   this.errors.search.stockist = 'Stockist Is Required';
    //   isvalid =false;
    // }
    // if(!this.search.empid){
    //   this.errors.search.empid = 'Employee Name Is Required';
    //   isvalid =false;
    // }
    if(!this.search.logindatefrom){
      this.errors.search.logindatefrom = 'Punchin Date From Is Required';
      isvalid =false;
    }
    if(!this.search.logindateto){
      this.errors.search.logindateto = 'Punchout Date To Is Required';
      isvalid =false;
    }
    return isvalid;
  }
},
filters: {
  basename: function (value) {
      if (!value) return ''
      return value.substr(value.lastIndexOf("/") + 1)
  }
}
}
</script>