<template>
  <div class="app-content content">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard" class="pb-1">
        <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
        <div class="container-fluid px-0 ">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left px-sm-mobile-10">
                <h3 class="content-header-title float-left mb-0">Micro Task Master</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                      <router-link :to="{ name: 'Work Step', params: { search: true }}">Workstep</router-link>
                    </li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/tasks/list'>Micro Task List</router-link></li>
                  </ol>
                </div>
              </div>
              <div class="col-md-3 text-right ">
              </div> 
            </div>
          </div>
          </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;margin-bottom: 1rem !important;border-radius: 15px 15px 0px 0px;">
          <div class="card-header filter_rotation_onlick px-1" style="cursor: pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid px-0" >
            <div class="row fc_my-05 mx-0">
              <div class="w-auto mobile-padding-0-2" >
                <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                        <search-icon
                          size="1.5x"
                          class="custom-class avatar-icon font-medium-3"
                        ></search-icon>
                      </div>
                    </div>
                    <h4 class="card-title d-inline-flex ml-1 ">Search Micro Task Details</h4>
                  </div>
              <div class="ml-auto d-flex">
              <div v-if="showcreatetaskbtn || pagefeatures.createadmintask" class="ml-auto px-1 mobile-padding-0-2 text-right" >
                <div class="input-group-append float-right">
                  <button id="commonaddmodule" class="btn btn-sm btn-relief-success" @click="redirecttourl('create', clickedTaskData)">
                   <plus-circle-icon size="1.5x" class="custom-class" style="margin-right:5px;">
                     
                   </plus-circle-icon> 
                   <span class="mobile_hide_bt">Create Micro Task</span></button>
                </div>
              </div>
              <span class="rotetions_view_arrow"> 
                  <em class="las la-angle-down"></em>
              </span>
            </div>
            </div>
            </div>
          </div>
          <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Businesstask Code</label>
                    <input type="text" class="form-control" placeholder="Enter Businesstask Code" v-model="search.btaskcode" v-on:search-change="btaskCodeChangeSearch" @input="clearbCodeList" :maxlength="bmaxLength"/>
                 </div>
                 <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Micro Task Code</label>
                    <input type="text" class="form-control" placeholder="Enter Micro Task Code" v-model="search.taskcode" v-on:search-change="taskCodeChangeSearch" @input="clearCodeList" :maxlength="maxLength"/>
                 </div>   
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Micro Task Name</label>
                  <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search micro task name" v-model="search.searchTaskname" :options="search.Tasknameopts" v-on:search-change="taskNameChangeSearch" @input="clearTaskNameList"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Micro Task Type</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Micro Task Type" v-model="search.searchTasktype" :options="search.tasktypeopts"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Project Name</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectname" @input="selSearchProject" :multiple="true" :clear-on-select="true" :options="search.projectnameopts"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Assigned To</label>
                  <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search Assigned to" :multiple="true" :clear-on-select="true" :options="search.allottedtoopts" v-model="search.allottedto" v-on:search-change="getEmpListByProjectAssigned"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Status</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Status Name" :options="status" v-model="search.status" :multiple="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                  <label class="form-label lable-left">Created-By</label>
                  <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search createdby" v-model="search.searchProjectcreatedby" :multiple="true" :clear-on-select="true" :options="search.projectcreatedbyopts" v-on:search-change="createdByChangeSearch"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">Task Reviewer</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Task Reviewer" :options="reviewersopts" v-model="search.reviewers" :multiple="true" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                  <label class="form-label lable-left">End Date Approvel Status</label>
                  <treeselect class="projectmdllistdd" placeholder="Select End Date Approvel Status" :options="estatuslist" v-model="search.estatus" :multiple="true" />
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4" >
                  <label for="">Micro Task Start Date From</label>
                    <date-picker placeholder="Select Micro Task Start Date From" v-model="search.startdatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4">
                  <label for="">Micro Task Start Date To</label>
                    <date-picker placeholder="Select Micro Task Start Date To" v-model="search.startdateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right">
                  <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="masterlist('t18', '', search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                  <button type="move" class="btn btn-relief-primary mt-2" :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="dashboard-ecommerce">
          <div class="row match-height dshboard_margin_x match-height dashTiles px-sm-0">
            <div class="col-xl-2 col-md-4 col-sm-6 col-6">
              <div class="card text-center" style="cursor:pointer;" @click="masterlist('t18', '', null,null,{status:'open'},parseValue(taskstatus.StatusOpen))">
                <div class="card-body">
                  <div class="avatar bg-light-danger p-50 mb-1">
                    <div class="avatar-content">
                      <em class="las la-clipboard-list font-medium-5"></em>
                    </div>
                  </div>
                  <h2 class="font-weight-bolder">{{parseValue(this.taskstatus.StatusOpen)}}</h2>
                  <p class="card-text">Open Micro Task</p>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 col-sm-6 col-6">
              <div class="card text-center" style="cursor:pointer;" @click="masterlist('t18', '', null,null,{status:'assigned'},parseValue(taskstatus.StatusAssigned))">
                <div class="card-body">
                  <div class="avatar bg-light-primary p-50 mb-1">
                    <div class="avatar-content">
                      <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                    </div>
                  </div>
                  <h2 class="font-weight-bolder">{{parseValue(this.taskstatus.StatusAssigned)}}</h2>
                  <p class="card-text">Assigned Micro Task</p>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 col-sm-6 col-6" >
              <div class="card text-center" style="cursor:pointer;" @click="masterlist('t18', '', null,null,{status:'reopen'},parseValue(taskstatus.StatusReopen))">
                <div class="card-body">
                  <div class="avatar bg-light-warning p-50 mb-1">
                    <div class="avatar-content">
                      
                      <em class="las la-retweet font-medium-5"></em>
                      
                    </div>
                  </div>
                  <h2 class="font-weight-bolder">{{parseValue(this.taskstatus.StatusReopen)}}</h2>
                  <p class="card-text">Reopen Micro Task</p>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 col-sm-6 col-6">
              <div class="card text-center" style="cursor:pointer;" @click="masterlist('t18', '', null,null,{status:'resolved'},parseValue(taskstatus.StatusResolved))">
                <div class="card-body">
                  <div class="avatar bg-light-success p-50 mb-1">
                    <div class="avatar-content">
                      
                      <em class="las la-check-circle font-medium-5"></em>
                    </div>
                  </div>
                  <h2 class="font-weight-bolder">{{parseValue(this.taskstatus.StatusResolved)}}</h2>
                  <p class="card-text">Resolved Micro Task</p>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 col-sm-6 col-6">
              <div class="card text-center" style="cursor:pointer;" @click="masterlist('t18', '', null,null,{status:'closed'},parseValue(taskstatus.StatusClosed))">
                <div class="card-body">
                  <div class="avatar bg-light-secondary p-50 mb-1">
                    <div class="avatar-content">
                      <em class="las la-file-invoice font-medium-5"></em>
                    </div>
                  </div>
                  <h2 class="font-weight-bolder">{{parseValue(this.taskstatus.StatusClosed)}}</h2>
                  <p class="card-text">Closed Micro Task</p>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 col-sm-6 col-6">
              <div class="card text-center" style="cursor:pointer;" @click="masterlist('t18', '', null,null,{status:'review'},parseValue(taskstatus.StatusReview))">
                <div class="card-body">
                  <div class="avatar bg-light-info p-50 mb-1">
                    <div class="avatar-content">
                      
                      <em class="las la-notes-medical font-medium-5"></em>
                      
                    </div>
                  </div>
                  <h2 class="font-weight-bolder">{{parseValue(this.taskstatus.StatusReview)}}</h2>
                  <p class="card-text">Review Micro Task</p>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-md-4 col-sm-6 col-6" style="display:none;">
              <div class="card text-center" style="cursor:pointer;" @click="masterlist('t18', '', null,null,{status:'approved'},parseValue(taskstatus.StatusApproved))">
                <div class="card-body">
                  <div class="avatar bg-light-primary p-50 mb-1">
                    <div class="avatar-content">
                      <em class="las la-clipboard font-medium-5"></em>

                    </div>
                  </div>
                  <h2 class="font-weight-bolder">{{parseValue(this.taskstatus.StatusApproved)}}</h2>
                  <p class="card-text">Approved Micro Task</p>
                </div>
              </div>
            </div>
          </div>
        </section>
    <div id="scrollToListGrid"></div>
    <div class="row">
      <div class="col-12">
        <ul class="listGrid float-right d-inline-flex">
            <li class="cursorpointercls"  @click="myListView()" >
              <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                <span>
                  List View
                </span>
              </button>
            </li>
            <li class=" cursorpointercls" @click="myGridView()" >
              <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
                <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
                <span>
                  Grid View
                </span>
              </button>
            </li>
          </ul>
      </div>
    </div>
          <div v-bind:class="[blog_list_wrapper,{displayblk:isgridView}]">
          <div class="dw mx-n1">
            <div class="dw-panel p-1 customAnimation"  v-for="(data,index) in companymasterlist" v-bind:key="index">
              <div class="card border-warning card_style_box_shadow1" style="margin-bottom: 0rem !important;border-radius: 15px;overflow: hidden;">
              <div class="card-header p-1">
                  <h4 class="card-title">{{(data.projectname).toLowerCase()}} : {{data.taskcode}} </h4>
              </div>
              <div class="card" style="margin-bottom:0 !important;">
                  <div class="card-body p-1">
                      <h4 class="card-title mb-1">
                          <span v-if="data.taskname.length > 50"  class="blog-title-truncate text-body-heading ">
                            {{data.taskname.substring(0,51)+ ' ...'}}
                          </span>
                          <span v-else  class="blog-title-truncate text-body-heading ">
                            {{data.taskname}}
                          </span>
                      </h4>
                      <div class="media">
                        
                          <div class="avatar mr-50">
                            <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                              <img alt="" v-if="!data.imagename" :src="defaultimagepath"  width="24" height="24" />
                          </div>
                          <div class="media-body">
                              <div>
                                <small class="text-muted mr-25">by</small>
                                <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                                <span class="text-muted ml-50 mr-25">|</span>
                                <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                                
                              </div>
                          </div>
                      </div>
                      <div class="my-1">
                        <div v-if="data.status=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50 mb-25">{{data.status}}</div>
                        <div v-if="data.status=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50 mb-25">{{data.status}}</div>
                        <div v-if="data.status=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50 mb-25">{{data.status}}</div>
                        <div v-if="data.status=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50 mb-25">{{data.status}}</div>
                        <div v-if="data.status=='review'" class="badge badge-pill badge-light-info capitalisetext mr-50 mb-25">{{data.status}}</div>
                        <div v-if="data.status=='approved'" class="badge badge-pill badge-light-dark capitalisetext mr-50 mb-25">{{data.status}}</div>
                        <div v-if="data.status=='assigned'" class="badge badge-pill badge-light-primary capitalisetext mr-50 mb-25">{{data.status}}</div>

                        <div  class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">{{data.tasktype}}</div>
                        <div  class="badge badge-pill badge-light-primary capitalisetext mb-25">{{data.modulename}}</div>
                        <div v-if="data.isapproved_ed && data.isapproved_ed == 'Pending'" class="badge badge-pill badge-light-danger mr-50 capitalisetext" style="margin-top:5px;">Pending</div>
                      </div>
                      <div class="card-text blog-content-truncate" v-if="data.moduledesc" style="overflow:hidden">
                          <span v-if="data.moduledesc.length>150" v-html="data.moduledesc.substring(0, 151) + ' ...'"></span>
                          <span v-else v-html="data.moduledesc"></span>
                      </div>
                      <div>
                        <small class="text-muted mr-25">Assigned To </small>
                        <small><a href="javascript:void(0);" @click="redirecttoprofile(data.assignedid)" class="capitalisetext">{{data.empname}}</a></small>
                       </div>
                      <hr class="mx-n1" />
                      <div class="d-flex justify-content-between align-items-center">
                          <a v-if="loginusername == data.createdby || pagefeatures.edittask || loginpersonname === data.empname || (loginuscondition == data.allottedto && data.tasktypemasterid == 28)" @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                              <div class="d-flex align-items-center sitebluecolor">
                                    <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                  <span class="text-body font-weight-bold " >Edit</span>
                              </div>
                          </a>
                          <a v-on:click="getTaskVersion(data.id, data.taskname, data.createdby, data.createddate)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor ml-1"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold"> Micro Task History</span>
                            </div>
                          </a>
                          <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold">View Details</span>
                            </div>
                          </a>
                          <a v-if="(data.isapproved_ed == 'Pending' && pagefeatures.approveedd)" v-on:click="reqToApprovedEstiDate(data.updated_ed, parseInt(data.moduleid), data.ed_reason, data.taskenddate)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <check-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></check-icon>
                              <span class="text-body font-weight-bold">Approve Task End Date</span>
                            </div>
                          </a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
          <div class="pageCenter text-center pt-1">
            <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            <div v-if="companymasterlist.length == 0 && isresponce" colspan="14" style="text-align: center;">No record found</div>
          </div>
        </div>
        <div v-bind:class="[{ show: isListActive}, card]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;margin-top: 1rem;margin-bottom: 1rem !important; ">
          <div v-bind:class="[{ show: isListDetActive }, card_content, collapse]">
            <div>
                <div class="row">
                <div class="col-md-12">
                  <div class="">
                    <div class=" ">
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                          <div class="row" id="table-small">
                            <div class="col-12">
                              <div class="card">
                                <div class="table-responsive">
                                  <table class="table table-sm dataTableListing table-hover table-striped 
                                  all_table_stickys" aria-describedby="mydesc">
                                    <thead>
                                      <tr>
                                        <th scope="col" class="align-middle first-col-sticky" style="min-width:45px;">Sr#</th>
                                        <th scope="col" class="align-middle second-col-sticky">Micro Task Code</th>
                                        <th scope="col" class="align-middle" style="min-width: 150px;">Micro Task Name</th>
                                        <th scope="col" class="align-middle">Micro Task status</th>
                                        <th scope="col" style="min-width: 130px;" class="align-middle">Parent Micro Task</th>
                                        <th scope="col" class="align-middle " style="min-width: 150px;">Micro Task Details</th>
                                        <th scope="col" class="align-middle">Project</th>
                                        <th scope="col" class="align-middle" style="min-width: 180px;">Assigned Details</th>
                                        <th scope="col" class="align-middle" style="min-width: 220px;">Created by</th>
                                        <th scope="col" class="align-middle">Status Details</th>
                                        <th scope="col" class="align-middle">Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template v-for="(data, index) in companymasterlist">
                                        <tr v-bind:key="data.moduleid">
                                          <td class="first-col-sticky centeralign">{{ index + 1 + currPageNum * pagelimit }}</td>
                                          <td class="text-nowrap second-col-sticky task_details_text_font12">{{ data.taskcode }}
                                            <hr class="release_hr_tag_tab"/>
                                            
                                            <div v-if="data.isapproved_ed && data.isapproved_ed == 'Pending'" class="badge badge-pill badge-light-danger capitalisetext mr-50">Pending</div>
                                              <span v-else>NA</span>
                                          </td>
                                          <td class="rolelistdd" @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer">
                                            <span v-if="data.taskname.length > 50" class="font-weight-normal sitebluecolor" >{{ data.taskname.substring(0, 51) + '...' }}</span>
                                            <span v-if="data.taskname.length <= 50" class="font-weight-normal sitebluecolor" >{{ data.taskname }}</span>
                                          </td>
                                          <td>
                                              <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50">{{data.status}}</div> 
                                              <div v-else-if="data.status.toLowerCase()=='review'" class="badge badge-pill badge-light-info capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='assigned'" class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div> 
                                          </td>
                                          <td>
                                              <div style="cursor: pointer" class="font-weight-normal sitebluecolor" v-if="data.parenttaskcode" @click="redirecttourl('pview',data)" href="javascript:void(0);">{{ data.parenttaskcode }}</div>
                                              <div v-else class="text-center"> - </div>
                                          </td>
                                         
                                          <td class="task_details_text_font12">
                                            <span class="release_hr_tag_span"> Type :</span>
                                            <span class="font-weight-normal">{{data.tasktype}}</span>
                                            <hr class="release_hr_tag_tab"/>
                                            <span class="release_hr_tag_span"> Level :</span>
                                            <span class="font-weight-normal">{{data.modulename}}</span>
                                          </td>
                                          <td>
                                            <span class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectID',data)">{{data.projectname}}</span>
                                          </td>
                                          <td class="task_details_text_font12">
                                            <span class="release_hr_tag_span">Assign to :</span> 
                                            {{ data.empname == ' ' ? 'NA': data.empname }}
                                            <hr class="release_hr_tag_tab"/>
                                            <span class="release_hr_tag_span">Date :</span>
                                            {{dateMonthFormat(data.taskassigndatetime) ? dateMonthFormat(data.taskassigndatetime):'NA'}}
                                          </td>
                                          <td> <span class="release_hr_tag_span">Created :</span>
                                            {{ data.createdby.split("@")[0] }}
                                            <hr class="release_hr_tag_tab"/>
                                            <span class="release_hr_tag_span">Date :</span>
                                            {{ dateMonthFormat(data.createddate) }}
                                          </td>
                                          <td class="text-nowrap task_details_text_font12" >
                                            
                                            <span class="release_hr_tag_span">Resolved Date :</span>
                                            {{dateMonthFormat(data.resolveddate) ? dateMonthFormat(data.resolveddate):'NA'}}
                                            <hr class="release_hr_tag_tab"/>
                                            <span class="release_hr_tag_span">Close Date :</span>
                                            {{dateMonthFormat(data.closeddate) ? dateMonthFormat(data.closeddate):'NA'}}
                                          </td>
                                          <td class="clsaction" >
                                            <div class="dropdown">
                                              <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                              </button>
                                              <div class="dropdown-menu">
                                                <a v-if="loginusername == data.createdby || pagefeatures.edittask || (loginuscondition == data.allottedto && data.tasktypemasterid == 28)" class="dropdown-item devEditEle" @click="redirecttourl('edit', data)" href="javascript:void(0);">
                                                  <div class="d-flex align-items-center">
                                                    <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                                    <span>Edit</span>
                                                  </div>
                                                </a>
                                                <a v-on:click="getTaskVersion(data.id, data.taskname, data.createdby, data.createddate)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                  <div class="d-flex align-items-center"> 
                                                    <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue"></eye-icon>
                                                    <span>Task History</span>
                                                  </div>
                                                </a>
                                                <a v-if="(data.isapproved_ed == 'Pending' && pagefeatures.approveedd)" v-on:click="reqToApprovedEstiDate(data.updated_ed, parseInt(data.moduleid), data.ed_reason, data.taskenddate)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                  <div class="d-flex align-items-center"> 
                                                      <check-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></check-icon>
                                                      <span >Approve Task End Date</span>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </template>
                                      <template v-if="companymasterlist.length == 0">
                                        <tr>
                                          <td colspan="14" style="text-align: center;">No record found</td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pageCenter text-center">
                        <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Micro Task History Cycle</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <ul class="timeline">
                    <li class="timeline-item"  v-for="(data) in taskversionlist.slice(0,1)" v-bind:key="data.experienceid">
                      <span class="timeline-point"><edit-icon size="1.5x" class="custom-class"></edit-icon></span>
                      <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{formatName(data.author)}} created <strong>{{data.taskname}}</strong></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.createdat)}}</span>
                      </div>
                    </li>
                    <li class="timeline-item"  v-for="(data,i) in taskversionlist.slice(1)" v-bind:key="data.experienceid">
                      <span v-if="i==1" class="timeline-point timeline-point-indicator"></span>
                      <span v-if="i%2==0" class="timeline-point  timeline-point-warning timeline-point-indicator"></span>
                      <span v-else class="timeline-point  timeline-point-info timeline-point-indicator"></span>
                      <div class="timeline-event">
                        <div v-if="data.field !== 'taskdescription'" class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{formatName(data.modifiedby)}} updated <strong>{{data.field}}</strong> from <strong style="display:inline-block" v-html="data.oldval"></strong> to <strong style="display:inline-block" v-html="data.newval"></strong></p>
                        </div>
                        <div v-else>
                          <p>{{formatName(data.modifiedby)}} updated task description <a style="color: blue" @click="opentaskdesc(data)">view details</a></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.lastmodifieddate)}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>    
          </div>
        </div>
      </div>
      <div class="modal fade" id="estimatedatepop" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                  <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                  <h4 class="modal-title">Approve Task End Date</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closed()">
                      <span aria-hidden="true">&times;</span>
                  </button>
                  </div>
                  <div class="modal-body sm-overflow_scroll_div">
                  <div class="row">
                      <div class="form-group col-md-4">
                          <label class="form-label">Current Task End Date</label>
                          <input class="form-control" type="text" v-bind:value="dateFormat(muatdate)" disabled/>
                      </div>
                      <div class="form-group col-md-4">
                          <label class="form-label">Updated Task End Date</label>
                          <input class="form-control" type="text" v-bind:value="dateFormat(estimatedate)" disabled/>
                      </div>
                      <div class="form-group col-md-8">
                          <label class="form-label">Task End Date Update Reason</label>
                          <textarea class="form-control" v-bind:value="medd_reason" disabled></textarea>                                        
                      </div>
                      <div class="col-md-12">
                          <button type="button" id="estimateapprovebtn" class="btn btn-primary" v-on:click="approvedEstiDate(estimatedate, businesstaskidED)">Approved</button>
                      </div>
                  </div>
                  </div>    
              </div>
          </div>
      </div>
      <div class="modal fade taskdesctabs" id="taskdesc" tabindex="1" role="dialog" aria-labelledby="taskDescmodal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Task description</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <section id="card-navigation">
                <div class="row match-height">
                  <div class="col-md-12">
                    <div class="card mb-3">
                        <ul class="nav nav-tabs" id="nav-tabs" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" id="new-tab" data-toggle="tab" href="#new" role="tab" aria-controls="new" aria-selected="true">New</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="old-tab" data-toggle="tab" href="#old" role="tab" aria-controls="old" aria-selected="false">Old</a>
                          </li>
                        </ul>
                      <div class="card-body">
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
                            <p class="card-text" v-html="this.currentTaskDescription.newval"></p>
                          </div>
                          <div class="tab-pane fade" id="old" role="tabpanel" aria-labelledby="old-tab">
                            <p class="card-text" v-html="this.currentTaskDescription.oldval"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>    
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  PlusSquareIcon,SearchIcon,EditIcon,ListIcon,GridIcon,
  EyeIcon,CheckIcon,
  PlusIcon,
  MinusSquareIcon,
  ClipboardIcon,
  MessageSquareIcon,ExternalLinkIcon,PlusCircleIcon,XIcon,MoreVerticalIcon,Edit2Icon,FileTextIcon,MonitorIcon,UserIcon
} from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TaskCreateVue from './TaskCreate.vue';
import profilepic from '../profilepic.vue';
import commonMethods from '../../utils/commonMethods';
export default {
  name: "TaskList",
  components: {
    Loading,ListIcon,GridIcon,
    EyeIcon,CheckIcon,
    PlusSquareIcon,
    VueElementLoading,
    vSelect,
    ExternalLinkIcon,
    EditIcon,
    Treeselect,
    DatePicker,
    SearchIcon,
    XIcon,
    Pagination,
    PlusIcon,
    MinusSquareIcon,
    ClipboardIcon,
    MessageSquareIcon,
    FileTextIcon,
    MonitorIcon,
    profilepic,PlusCircleIcon,MoreVerticalIcon,Edit2Icon,UserIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      maxLength:16,
      bmaxLength:19,
      componentKey: 0,
      defaultimagepath:apiUrl.defaultimagepath,
      currentTaskDescription: {},
      taskcodelist:null,
      showcreatetaskbtn: false,
      clickedTaskData: null,
      search: {
        searchTaskname: null,
        Tasknameopts: [],
        searchprojectname: null,
        projectnameopts: null,
        searchTasktype: null,
        tasktypeopts: null,
        searchProjectcreatedby: null,
        projectcreatedbyopts: null,
        allottedto: null,
        allottedtoopts: [],
        status: null,
        taskcodelist:null,
        btaskcodelist:null,
        btaskcode:null,
        startdatefrom:null,
        startdateto:null,
        statusopts: [{
            id:'open',
            label:'Open'
        },{
            id:'resolved',
            label:'Resolved'
        },{
            id:'closed',
            label:'Closed'
        }],
        estatus: null
      },
      reviewersopts: [{
        id:'engineeringmanager',
        label:'Engineering Manager'
      },{
        id:'projectmanager',
        label:'Project Manager'
      },{
        id:'qalead',
        label:'QA Lead'
      }],
      isBothDateSelected:false,
      isSearchRequestActive: false,
      selectedLevel: 10,
      level: 1,
      isLoading: false,
      fullPage: true,
      islistLoading:false,
      listfullPage:true,
      companymasterlist: [],
      tasktypelist: [],
      typenamelist: [],
      projectlist: [],
      taskassignmentList: [],
      showAddForm: false,
      selected: null,
      editRowData: [],
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      projectType: [],
      projectdescription: "",
      tasklevel: apiUrl.tasklevel,
      tasktypekeylist: apiUrl.tasktypekeylist,
      tasknamemaster: apiUrl.tasknamemaster,
      taskstatus: [],
      status:apiUrl.status,
      estatuslist: apiUrl.enddateapprovelstatus.sort((a, b) => a.label.localeCompare(b.label)),
      taskversionlist: [],
      taskdefine: {
        type: null,
        name: null,
        level: null,
        projectid: null,
        projectlist: [
          {
            id: "",
            label: "",
          },
        ],
        projectname: null,
        module: null,
        projectmdllist: [
          {
            id: "",
            label: "",
          },
        ],
        application: null,
        applicoptions: [
          {
            id: "",
            label: "",
          },
        ],
        applicationname: null,
        appmodule: null,
        appmodulelist: [
          {
            id: "",
            label: "",
          },
        ],
        applicationmodulename: "",
        devsection: null,
        sectionlist: [
          {
            id: "",
            label: "",
          },
        ],
        feature: null,
        featurelist: [
          {
            id: "",
            label: "",
          },
        ],
        taskstartdate: null,
        taskenddate: null,
        taskassignby: "",
        taskassigneddate: "",
        taskdefinename: "",
        businesstype: null,
        businesstasklist: [
          {
            id: "",
            label: "",
          },
        ],
        projectmodulename: null,
        isedit: false,
      },
      taskBlured: false,
      valid: false,
      projvalue: null,
      applvalue: null,
      applicoptions: [
        {
          id: "",
          label: "",
        },
      ],
      startdate: null,
      tasklevellist: [
        {
          id: "",
          label: "",
        },
      ],
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      projectnameist: [],
      loginuscondition: "",
      attachment: [],
      attachmentview: null,
      isDetActive: false,
      isListDetActive:false,
      isListActive:true,
      displayblk:'displayblk',
      isgridView:false,
      blog_list_wrapper :'blog-list-wrapper',
      card: "card",
      isRequestActive: false,
      card_content: "card-content",
      border_warning: "border-warning",
      collapse: "collapse",
      showtabledata:true,
      allottedto: null,
      loginusername:'',
      sitebluecolor:"sitebluecolor",
      activegridlink:true,
      activelistlink:false,
      countStatus: null,
      taskcountClick:false,
      tokendata:null,
      isresponce:false,
      rdashboard:null,
      taskmodule:0,
      loginpersonname:null,
      estimatedate: null,
      businesstaskidED: null,
      muatdate:null,
      medd_reason:null,
      designteam: false
    };
  },
  created() {
    this.pagefeatures = {};
    var pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/tasks/list") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    window.addEventListener('storage', function(event){
        if (event.key == 'logout-mee') { 
            window.location.reload();
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    });
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.tokendata = window.localStorage.getItem('token');
        this.loginuscondition = userdata.userid;
        this.loginusername=userdata.username
        this.loginpersonname=userdata.firstname+' '+userdata.lastname;
        this.taskmodule = window.localStorage.getItem("taskmodule")
        // this.getEmpListByProjectAssigned(parseInt(this.undt.userid))
        this.getProjectAllocatedToEmp();
        this.designteam =this.pagefeatures.approveedd; //remove false and add design team page feature 
      }
      if(this.$route.params.clickeddata !=undefined){
        this.rdashboard = this.$route.params.clickeddata['rdashboard']
      }
      if (this.$route.params && this.$route.params.backbtnstate != undefined) {
        if (
          this.$route.params.backbtnstate == "edit" ||
          this.$route.params.backbtnstate == "view"
        ) {
          this.masterlist("t18", this.$route.params.clickeddata);
          $("#taskdefine-tab").click();
          $("#commonaddmodule").attr("data-target", "#taskdefinemaster");
          this.clickedTaskData = this.$route.params.clickeddata
          if (parseInt(this.clickedTaskData.counttask) < 1 ){
            this.showcreatetaskbtn = true
          }
          if (this.$route.params.backbtnstate == "edit") {
            $("#commonaddmodule").click();
            this.isRequestActive = true;
            this.selectedLevel = 10;
            this.taskdefine.isredirected = true;
          }
        }
        if(this.$route.params.backbtnstate == "open") {
          this.search.status=['open']
          this.masterlist("t18", null, null, ['open'])
        } 
        if(this.$route.params.backbtnstate == "reopen"){
            this.search.status=['reopen']
          this.masterlist("t18", null, null, ['reopen'])
        }
        if(this.$route.params.backbtnstate == "approved"){
            this.search.status=['approved']
          this.masterlist("t18", null, null, ['approved'])
        }
        if (this.$route.params.backbtnstate == "ProjectSummaryTask") {
        let dataSplit = this.$route.params.clickeddata.split('_');
        let search = {
          status: [dataSplit[1]],
          searchTasktype: dataSplit[2],
          searchprojectname: [this.$route.params.pid]
        }
        this.masterlist('t18', '', search);
        } 
      } else if (this.$route.params && this.$route.params.search) {
        let search = JSON.parse(localStorage.getItem('taskmastersearchItems'))
        if(search) {
          this.getSearchItems(search);
        }else{
          localStorage.removeItem('taskmastersearchItems');
          this.search.status=['open'];
          this.masterlist("t18", "", { status: ['open'] });
        }
      } else {
        localStorage.removeItem('taskmastersearchItems');
        this.search.status=['open'];
        this.masterlist("t18", "", { status: ['open'] });
      }
    }
  },
  methods: {
    closed(){
        $('#estimatedatepop').modal('hide');
        this.estimatedate = null;
        this.businesstaskidED = null;
        this.medd_reason=null
        this.muatdate=null
    },
    reqToApprovedEstiDate(edate, businesstaskid, edd_reason, uatdate){
        this.estimatedate = edate
        this.businesstaskidED = businesstaskid
        this.medd_reason = edd_reason
        this.muatdate=uatdate
        $('#estimatedatepop').modal('show');
    },
    approvedEstiDate(edate, businesstaskid){
      axios({
        method: "POST",
        url: "api/master/taskmaster/approvedEstimateDate",
        data: { taskid: parseInt(businesstaskid), empid: this.undt.userid, updatedby: this.undt.username, edate: edate,useremail: this.undt.username,empcode: this.undt.userid, },
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          $('#estimatedatepop').modal('hide');
          this.estimatedate = null;
          this.businesstaskidED = null;
          Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
            this.masterlist('t18')
        } 
      }).catch(e => {
        this.displayError(e)
      });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    opentaskdesc(event) {
      this.currentTaskDescription = event
      $('#taskdesc').modal('show');
    },
    redirecttoprofile(empid){
      let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
      window.open(route.href);
    },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    mydetailsollapse() {
      this.isDetActive = true
    },
    parseValue(val) {
        if(val) {
          return parseInt(val)
        }
        return 0
    },
    myListView: function () {
      this.isListDetActive = true;
      this.isgridView=true;
      this.activegridlink=false
      this.activelistlink=true
      $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    myGridView(){
        this.isListDetActive = false;
        this.isgridView=false;
        this.activegridlink=true
        this.activelistlink=false
        $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    formatName(authorstring) {
      let author=authorstring.replace('@firstcry.com','')
      if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
      author = author.replace('.',' ')
      author = author.charAt(0).toUpperCase() + author.slice(1);
      return author
    },
    redirecttourl:function(btnstate,rowdata){
      if(rowdata){
      rowdata.backbtnstate=btnstate
      if(btnstate=="view"){
        let routeData = this.$router.resolve({ 
          path: `/tasks/view/?taskid=${rowdata.id}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
        window.open(routeData.href, '_blank');
      } else if(btnstate=="pview"){
        let routeData = this.$router.resolve({ 
          path: `/tasks/view/?taskid=${rowdata.parenttaskid}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
        window.open(routeData.href, '_blank');
      } else if(btnstate=="edit"){
        this.$router.push({ 
          path: `/tasks/update/?taskid=${rowdata.id}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      } else if(btnstate=="create"){
        this.$router.push({
          name: 'TaskCreate',
          path: `/task/create`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      }else if (btnstate == 'projectID') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid
                })
            }
      }else{
        if(btnstate=="create"){
          this.$router.push({
            name: 'TaskCreate',
            path: `/task/create`,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        }
      }
    },
    getTaskVersion(taskid, ...args) {
      let taskInfo = { taskname: args[0], author:args[1], createdat: args[2] }
      this.isLoading = true
      let apiUrl = 'api/master/taskmaster/getTaskversion'
      this.input = { taskid,useremail: this.undt.username,empcode: this.undt.userid }
      
      axios({
        method: "POST",
        url: apiUrl,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false
        $('#technologypopup').modal('show');
        if(result.data.errorCode == 0) {
          this.taskversionlist = result.data.data.rows
          this.taskversionlist.unshift(taskInfo)
        } else {
          this.taskversionlist = []
          this.taskversionlist.unshift(taskInfo)
        }
      }).catch(e => {
        this.displayError(e)
      }) 
    },
    dispalayTechnologies(data) {
      if(!data) {
        return 'NA'
      }
      let responsestr='';
      let techList=data.split(',')
      if(techList.length > 1){
        for(let i=0;i<techList.length;i++){
          responsestr += (i+1) + '. ' + techList[i] + ' <br/>'
        }
      }else{
        for(var i=0;i<techList.length;i++){
          responsestr +=techList[i]
        }
      }
      return responsestr
    },
    resetRecords: function () {
      this.search.searchprojectname = null;
      this.search.searchTaskname = null;
      this.search.searchTasktype = null;
      this.search.searchProjectcreatedby = null;
      this.search.allottedto = null;
      this.search.allottedtoopts = [];
      this.search.status = null
      this.search.taskcode = null
      this.search.taskcodelist = null
      this.search.btaskcode = null
      this.search.btaskcodelist = null
      this.search.projectnameopts = []
      this.search.Tasknameopts = []
      this.search.projectcreatedbyopts = null
      this.search.startdatefrom = null;
      this.search.startdateto = null;
      this.search.estatus = null;
      this.isBothDateSelected = false
      localStorage.removeItem('taskmastersearchItems')
      localStorage.removeItem('taskcodelist')
      localStorage.removeItem('btaskcodelist')
      localStorage.removeItem('tasknameopts')
      localStorage.removeItem('projectcreatedbyopts')
      this.masterlist("t18");
      this.getProjectAllocatedToEmp();
    },
    clearCodeList(state) {
      if(!state) {
        this.search.taskcodelist = null
      }
    },
    clearbCodeList(state) {
      if(!state) {
        this.search.btaskcodelist = null
      }
    },
    // btaskCodeChangeSearch(node) {
    //   if(node && node.length>1) {
    //     axios({
    //       'method': 'POST',
    //       'url': 'api/master/taskmaster/gettaskcode',
    //       'data': {
    //         taskcode: node.toUpperCase(),
    //         useremail: this.undt.username,
    //         empcode: this.undt.userid,
    //       },
    //       'headers':{'authorization':this.tokendata}
    //     }).then(result => {
    //       this.isLoading = false
    //       if(result.data.errorCode == 0) {
    //         this.search.btaskcodelist = result.data.data
    //         localStorage.setItem('btaskcodelist', JSON.stringify(result.data.data))
    //       } else {
    //         this.search.btaskcodelist = null
    //         localStorage.removeItem('btaskcodelist')
    //       }
    //     })
    //   }
    // },
    btaskCodeChangeSearch(node) {
      if(node && node.length>1) {
          axios({
              'method': 'POST',
              'url': 'api/business/getBusinessCode',
              'data': {
                  businesscode: node.toUpperCase(),
                  empcode: this.undt.userid,
                  useremail: this.undt.username
              },
              'headers':{'authorization':this.tokendata}
          }).then(result => {
              this.isLoading = false
              if(result.data.errorCode == 0) {
                this.search.btaskcodelist=result.data.data
                  localStorage.setItem('btaskcodelist', JSON.stringify(result.data.data))
              } else {
                this.search.btaskcodelist = null
                localStorage.removeItem('btaskcodelist')
              }
          }).catch(e => {
        this.displayError(e)
      })
      }
    },
    taskCodeChangeSearch(node) {
      if(node && node.length>1) {
        axios({
          'method': 'POST',
          'url': 'api/master/taskmaster/gettaskcode',
          'data': {
            taskcode: node.toUpperCase(),
            useremail: this.undt.username,
            empcode: this.undt.userid,
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.taskcodelist = result.data.data
            localStorage.setItem('taskcodelist', JSON.stringify(result.data.data))
          } else {
            this.search.taskcodelist = null
            localStorage.removeItem('taskcodelist')
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    clearTaskNameList(state) {
      if(!state) {
        this.search.Tasknameopts = null
      }
    },
    taskNameChangeSearch(node) {
      if(node && node.length>3) {
        axios({
          'method': 'POST',
          'url': 'api/searching/getTaskList',
          'data': {
            empid: parseInt(this.undt.userid),
            taskname: node.toLowerCase(),
            useremail: this.undt.username,
            empcode: this.undt.userid,
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.Tasknameopts = result.data.data;
            localStorage.setItem('tasknameopts', JSON.stringify(result.data.data));
          } else {
            this.search.Tasknameopts = [];
            localStorage.removeItem('tasknameopts');
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.page = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
     
      if(this.taskcountClick){
        if(this.taskcountClick.status){
          this.masterlist('t18', '', {status: [this.taskcountClick.status]},null,this.countclick)
          }else{
            this.masterlist('t18', '', null,null,this.countclick)
          }
        } else{
          this.masterlist(inputEle, "");
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#scrollToListGrid").offset().top -70
            });
        }, 500);
    },
    dateFormatterSales: function (dt) {
      let dateoptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      return dt.toLocaleDateString("en-GB", dateoptions);
    },
    notBeforeToday: function (date) {
      this.search.startdateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    selectedfromdate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      let selectedFromDate = new Date(this.search.startdatefrom);
      selectedFromDate.setDate(selectedFromDate.getDate()-1)
      return date < selectedFromDate || date > today;
    },
    selectedtodate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    getSearchItems(search) {
      this.isSearchRequestActive = true
      this.search.taskcode = search.taskcode
      this.search.btaskcode = search.btaskcode
      this.search.searchTaskname = search.searchTaskname
      this.search.searchTasktype = search.searchTasktype
      this.search.projectnameopts = JSON.parse(localStorage.getItem('projectnameopts'))
      this.search.Tasknameopts = JSON.parse(localStorage.getItem('tasknameopts'))
      this.search.taskcodelist = JSON.parse(localStorage.getItem('taskcodelist'))
      this.search.btaskcodelist = JSON.parse(localStorage.getItem('btaskcodelist'))
      this.search.projectcreatedbyopts = JSON.parse(localStorage.getItem('projectcreatedbyopts'))
      this.search.searchprojectname = search.searchprojectname
      this.search.allottedto = search.allottedto
      this.getEmpListByProjectAssignededit(search.allottedto)
      this.getAllEmpEmailIds(search.searchProjectcreatedby)
      this.search.status = search.status
      this.search.reviewers = search.reviewers
      this.search.searchProjectcreatedby = search.searchProjectcreatedby;
      this.search.startdatefrom = search.startdatefrom;
      this.search.startdateto = search.startdateto;
      this.search.estatus = search.estatus;
      this.masterlist("t18", "", this.search);
    },
    masterlist(mdl, objThis, searchThis, status, countclick, value) {
      if(value == 0){
        return
      }
      this.islistLoading = true;
      let setmoduleform = "projectmaster";
      let glbMdlLblval = "Project";
      this.selectedLevel = 10;
      this.formActionLabel = "Create Task";
      if (this.glbst && mdl == "") {
        mdl = this.glbst;
      }
      if (mdl == "t18") {
        setmoduleform = "taskdefinemaster";
        glbMdlLblval = "Task Master";
      }

      $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
      
      let tblNm = "t18";
      if (mdl) tblNm = mdl;
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        moduletype:this.taskmodule
      };
      if(searchThis) {
        this.page=1
        localStorage.setItem('taskmastersearchItems', JSON.stringify(searchThis));
      }
      let search = JSON.parse(localStorage.getItem('taskmastersearchItems'));
      if(search) {
        this.input.taskname = search.searchTaskname
        this.input.tasktype = search.searchTasktype
        this.input.projectname = search.searchprojectname
        this.input.allottedto = search.allottedto?search.allottedto:null
        this.input.status = search.status
        this.input.createdby = search.searchProjectcreatedby
        this.input.taskcode = search.taskcode
        this.input.btaskcode = search.btaskcode
        this.input.reviewers = search.reviewers
        this.input.estatus = search.estatus
        
        let assignedate = ''
        if(this.search.startdateto){
          assignedate = new Date(this.search.startdateto)
          assignedate.setDate(assignedate.getDate() + 1)
          assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
        this.input.dateto = this.search.startdateto?assignedate:null
      }
      if (status){
        this.input.status = status
        this.input.dashboardflag=1
      }
      if(this.rdashboard && !searchThis){
        this.input.allottedto = this.undt.userid?[this.undt.userid]:null
      }
      if(this.search.allottedto){
        this.input.allottedto = this.search.allottedto
        this.input.status = this.search.status
      }
      if(countclick){
        this.taskcountClick = countclick
        this.input.status = [countclick.status]
        this.page = this.pageoffset;
      }else{
        this.taskcountClick = false
        this.gettaskstatus(this.input)
      }
      this.input.isSearch = false;
      var url = "api/master/commonmaster/list";
      if (mdl == "t18") {
        this.getApplicationdata("t4", "taskdefine-tab");
        this.getApplicationdata("t24", "taskdefine-tab");
        this.getApplicationdata("t25", "taskdefine-tab");
        url = "api/master/taskmaster/getTaskMasterlist";
        this.input.useremail = this.undt.username;
        this.input.empid = this.undt.userid;
        this.input.isFilterSelect = 0;
        this.input.isFilterPayload = {};
        if(objThis) {
          this.input.functionlitymasterid = objThis.functionlitymasterid
          this.input.workstepid = objThis.workstepid
        }
      }
      this.input.useremail= this.undt.username
      this.input.empcode= this.undt.userid
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        this.isresponce=true

        if (objThis) {
          this.isDetActive = true;
          this.taskdefine.projectid = objThis.projectid;
          this.taskdefine.module = objThis.projectmoduleid;
          this.taskdefine.application = objThis.applicationid;
          this.taskdefine.appmodule = objThis.applicationmoduleid;
          this.taskdefine.devsection = objThis.sectionmasterid;
          this.taskdefine.feature = objThis.functionlitymasterid;
        }
        if (result.data.errorCode == 0) {
          this.forceRerender()
          this.isDetActive = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.isDetActive = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    gettaskstatus(request){
        this.isLoading = true;
        let url = "api/master/taskmaster/gettaskstatus";
        this.dashboardinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          projectid:request.projectname,
          functionalityid:request.functionality,
          allotedto:request.allottedto,
          taskid:request.taskname,
          status:request.status,
          datefrom: request.datefrom,
          dateto: request.dateto,
          moduletype:this.taskmodule,
          createdby: request.createdby,
          taskcode: request.taskcode,
          taskname: request.taskid,
          empcode: this.undt.userid,
          btaskcode: request.btaskcode,
          reviewers: request.reviewers,
          type: this.search.searchTasktype,
          estatus: request.estatus
        };
        axios({
          method: "POST",
          url: url,
          data: this.dashboardinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.taskstatus = result.data.data[0];
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            this.taskstatus=[]
          }
        }).catch(e => {
        this.displayError(e)
      });
      },
    sortTypeList(typelist, type){
      this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'task',
          typearr: [],
          projecttype: type
      };
      axios({
          method: "POST",
          url: 'api/ticket/sortTypeList',
          data: this.input,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.search.tasktypeopts = result.data.data.rows;
              this.search.tasktypeopts.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                  });
          } else {
              this.search.tasktypeopts = [];
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getApplicationdata(mdl, curracttab) {
    this.isLoading = true;
    this.pageoffset = 1;

    this.inputappl = {
      tbl: mdl,
      offset: this.pageoffset,
      limit: apiUrl.LIMIT,
      useremail: this.undt.username,empcode: this.undt.userid,
    };
    if (mdl == "t11") {
      this.inputappl.limit = 500;
      this.inputappl.useremail= this.undt.username,
      this.inputappl.empcode= this.undt.userid
    }
    let apiURL = "api/master/commonmaster/list";
    axios({
      method: "POST",
      url: apiURL,
      data: this.inputappl,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
        this.projectType = [];
        this.projectType = result.data.data.rows;
        let tmpClassNm = "";
        if (mdl == "t25") {
          if (curracttab == "taskdefine-tab") {
            this.tasktypelist = result.data.data.rows;
            this.sortTypeList([], 'tasklistfetch');
            //this.search.tasktypeopts = result.data.data.rows;
            return true;
          }  else if (mdl == "t25") {
          if (curracttab == "tasktype-tab") {
            this.task.tasknamelist = result.data.data.rows;
            return true;
          } else if (curracttab == "taskname-tab") {
            this.taskName.tasknamemaster = result.data.data.rows;
            return true;
          } else if (curracttab == "taskdefine-tab") {
            this.typenamelist = result.data.data.rows;
            return true;
          }
          }
        } else if (mdl == "t17") {
          if (curracttab == "taskname-tab") {
            tmpClassNm = "tasknametaskleveldd";
          } else if (curracttab == "taskdefine-tab") {
            this.typenamelist = result.data.data.rows;
            return true;
          }
        } else if (mdl == "t2") {
          tmpClassNm = "devBusinessVerticleData";
        } else if (mdl == "t23") {
          if (curracttab == "tasktype-tab") {
            this.tasknamelist = result.data.data.rows;
            return true;
          } else if (curracttab == "taskdefine-tab") {
            this.tasktypelist = result.data.data.rows;
            return true;
          }
        } else if (mdl == "t24") {
          if (curracttab == "tasktype-tab") {
            this.task.tasklevellist = result.data.data.rows;
            return true;
          } else if (curracttab == "taskname-tab") {
            this.taskName.tasklevellist = result.data.data.rows;
            return true;
          } else if (curracttab == "taskdefine-tab") {
            this.tasklevel = result.data.data.rows;
            return true;
          } else if (curracttab == "task") {
            this.tasklevellist = result.data.data.rows;
            return true;
          }
        } else if (mdl == "t11") {
          if (curracttab == "task") {
            this.tasktype.projectlist = result.data.data.rows;
            return true;
          } else if (curracttab == "taskdefine-tab") {
            return true;
          }
        } else if (mdl == "t21") {
          if (curracttab == "taskassignment-tab") {
            this.assignment.employeeoptions = result.data.data.rows;
            return true;
          } else if (curracttab == "taskfassignmentstatus-tab") {
            this.statusassignment.employeeoptions = result.data.data.rows;
            return true;
          }
        } else if (mdl == "t18") {
          if (curracttab == "taskfassignmentstatus-tab") {
            this.statusassignment.taskdefineList = result.data.data.rows;
            return true;
          } else if (curracttab == "taskassignment-tab") {
            this.assignment.tasklist = result.data.data.rows;
            return true;
          }
        } else if (mdl == "t19") {
          if (curracttab == "taskfassignmentstatus-tab") {
            this.statusassignment.taskassignmentList = result.data.data.rows;
            return true;
          }
        } else if (mdl == "t9") {
          if (curracttab == "taskdefine-tab") {
            this.taskdefine.businesstasklist = result.data.data.rows;
            return true;
          }
        }
        let payload = {
          classnm: tmpClassNm,
        };
        this.fillDropdown(payload);
    }
  }).catch(e => {
        this.displayError(e)
      });
    },
    fillDropdown(data){
      return ;
    },
    dateFormat2(value) {
      if (!value) return "";
      return moment(value).format(this.TimeFormat);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD-MM-YYYY");
    },
    dateMONFormat(value){
      if(value){
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      }else{
        return "";
      }
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    getProjectAllocatedToEmp() {
        this.isLoading = true
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage: 'listingpage'
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers': { 'authorization': this.tokendata }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.search.projectnameopts = result.data.data;
              localStorage.setItem('projectnameopts', JSON.stringify(result.data.data))
          } else {
              this.search.projectnameopts = [];
              localStorage.removeItem('projectnameopts')
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    projectChangesearch: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectnameopts = result.data.data;
            localStorage.setItem('projectnameopts', JSON.stringify(result.data.data))
          } else {
            this.search.projectnameopts = [];
            localStorage.removeItem('projectnameopts')
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    createdByChangeSearch: function (node){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getAllEmpEmailIds";
        this.input = {
          emailid:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data;
            localStorage.setItem('projectcreatedbyopts', JSON.stringify(result.data.data))
          } else {
            this.search.projectcreatedbyopts = [];
            localStorage.removeItem('projectcreatedbyopts')
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getEmpListByProjectAssigned(node) {
      if(node && node.length>3){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.allottedtoopts = result.data.data;
            let decryptdepartmenttext = this.search.allottedtoopts.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.allottedtoopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    getEmpListByProjectAssignededit(assignedto) {
      if(assignedto && assignedto!=null){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: this.undt.userid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            assignedto: assignedto
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.allottedtoopts = result.data.data;
            let decryptdepartmenttext = this.search.allottedtoopts.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.allottedtoopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    getAllEmpEmailIds(createdby){
      if(createdby && createdby.length > 0){
        this.isLoading = true;
        let url = "api/listing/getAllEmpEmailIds";
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          created: createdby
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data;
            localStorage.setItem('projectcreatedbyopts', JSON.stringify(result.data.data))
          } else {
            this.search.projectcreatedbyopts = [];
            localStorage.removeItem('projectcreatedbyopts')
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    selSearchProject(state, value) {
      this.search.searchprojectname = null
      this.search.searchprojectname = state
      if(!state) {
        localStorage.removeItem('projectnameopts')
      } else {
      }
    },
    ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        projectname : this.search.searchprojectname,
        taskcode : this.search.taskcode,
        btaskcode : this.search.btaskcode,
        taskname : this.search.searchTaskname,
        tasktype : this.search.searchTasktype,
        allottedto : this.search.allottedto,
        status : this.search.status,
        createdby : this.search.searchProjectcreatedby,
        useremail: this.undt.username,
        empid : this.undt.userid,
        moduletype:this.taskmodule,
        tbl:'t18',
        empcode: this.undt.userid,
        reviewers: this.search.reviewers,
        estatus : this.search.estatus,
      };
      let assignedate = ''
      if(this.search.startdateto){
        assignedate = new Date(this.search.startdateto)
        assignedate.setDate(assignedate.getDate() + 1)
        assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
      this.input.dateto = this.search.startdateto?assignedate:null
      if(this.taskcountClick && this.taskcountClick.status){
        this.input.status = [this.taskcountClick.status]
      }
      axios({
        method: "POST",
        url: `api/master/taskmaster/getTaskMasterlist`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download(result.data.data.rows,filename)
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        
      }).catch(e => {
        this.displayError(e)
      });
    },

    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
        var ShowLabel = 'TaskListReport_'+filename
        var JSONData = readExportdata
        var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        var CSV = ''
        if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
              row += index + ','
          }
          row = row.slice(0, -1)
          if(!this.search.reviewers == '' || !this.search.reviewers == null ) {
            CSV = 'Sr#,Businesstask Code,Micro Task code,Micro Task Name,Micro Task Status, Parent Micro Task ,Micro Task Type,Micro Task Level,Project,Assigned To,Assigned Date,Created By,Created Date,Micro Task Start Date,Micro Task End Date,Updated End Date,Updated End Date Reason,End Date Approvel Status,Resolved Date,Closed Date,Micro Task Reviewers' + '\r\n'
          }else{
          CSV = 'Sr#,Businesstask Code,Micro Task code,Micro Task Name,Micro Task Status, Parent Micro Task ,Micro Task Type,Micro Task Level,Project,Assigned To,Assigned Date,Created By,Created Date,Micro Task Start Date,Micro Task End Date,Updated End Date,Updated End Date Reason,End Date Approvel Status,Resolved Date,Closed Date' + '\r\n'
          }
        }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode : 'NA';
          let taskcode = index.hasOwnProperty('taskcode') ? index.taskcode : '';
          let parenttaskcode = index.hasOwnProperty('parenttaskcode') ? index.parenttaskcode : '';
          parenttaskcode = (parenttaskcode!=null)?parenttaskcode: 'NA';
          let taskname = index.hasOwnProperty('taskname') ? index.taskname.replace(/"/g, '""') : '';
          let status = index.hasOwnProperty('status') ? index.status.toUpperCase() : '';
          let tasktype = index.hasOwnProperty('tasktype') ? index.tasktype : '';
          let modulename = index.hasOwnProperty('modulename') ? index.modulename ==null || index.modulename =='' || index.modulename ==' ' ? 'NA' : index.modulename : 'NA';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let empname = index.hasOwnProperty('empname') ? index.empname ==null || index.empname.toLowerCase() =='na'?'':index.empname : '';   
          let taskassigndatetime = index.hasOwnProperty('taskassigndatetime') ? index.taskassigndatetime : '';
          taskassigndatetime = this.dateMONFormat(taskassigndatetime);      
          let createdby = index.hasOwnProperty('createdby') ? index.createdby.split("@")[0] : '';
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          createddate = this.dateMONFormat(createddate);
          let taskstartdate = index.hasOwnProperty('taskstartdate') ? index.taskstartdate : '';
          taskstartdate = taskstartdate ? this.dateMONFormat(taskstartdate) : 'NA';
          let taskenddate = index.hasOwnProperty('taskenddate') ? index.taskenddate : 'NA';
          taskenddate = taskenddate ? this.dateMONFormat(taskenddate) :'NA';
          let updated_ed = index.hasOwnProperty('updated_ed') ? index.updated_ed : 'NA';
          updated_ed = updated_ed ? this.dateMONFormat(updated_ed) : 'NA';
          let ed_reason = index.hasOwnProperty('ed_reason') ? index.ed_reason ==null || index.ed_reason =='' || index.ed_reason ==' ' ? 'NA' : index.ed_reason : 'NA';
          let isapproved_ed = index.hasOwnProperty('isapproved_ed') ? index.isapproved_ed ==null || index.isapproved_ed =='' || index.isapproved_ed ==' ' ? 'NA' : index.isapproved_ed : 'NA';
          
          let resolveddate = index.hasOwnProperty('resolveddate') ? index.resolveddate : '';
          resolveddate = this.dateMONFormat(resolveddate) ? this.dateMONFormat(resolveddate) : 'NA';
          let closeddate = index.hasOwnProperty('closeddate') ? index.closeddate : '';
          closeddate = this.dateMONFormat(closeddate) ? this.dateMONFormat(closeddate) : 'NA';
          if(!this.search.reviewers == '' || !this.search.reviewers == null){
            let reviewers = index.hasOwnProperty('reviewers') ? index.reviewers ==null || index.reviewers.toLowerCase() =='NA'?'':index.reviewers : '';   

            row += '"' + srno + '",',
            row += '"' + businesstaskcode + '",',
            row += '"' + taskcode + '",',
            row += '"' + taskname + '",',
            row += '"' + status + '",',
            row += '"' + parenttaskcode + '",',
            row += '"' + tasktype + '",',
            row += '"' + modulename + '",',
            row += '"' + projectname + '",',
            row += '"' + empname + '",',    
            row += '"' + taskassigndatetime + '",',       
            row += '"' + createdby + '",',
            row += '"' + createddate + '",',
            row += '"' + taskstartdate + '",',
            row += '"' + taskenddate + '",',
            row += '"' + updated_ed + '",',
            row += '"' + ed_reason + '",',
            row += '"' + isapproved_ed + '",',
            row += '"' + resolveddate + '",',
            row += '"' + closeddate + '",',
            row += '"' + reviewers + '",',
            row += '\r\n'
          }else{
            row += '"' + srno + '",',
            row += '"' + businesstaskcode + '",',
            row += '"' + taskcode + '",',
            row += '"' + taskname + '",',
            row += '"' + status + '",',
            row += '"' + parenttaskcode + '",',
            row += '"' + tasktype + '",',
            row += '"' + modulename + '",',
            row += '"' + projectname + '",',
            row += '"' + empname + '",',    
            row += '"' + taskassigndatetime + '",',       
            row += '"' + createdby + '",',
            row += '"' + createddate + '",',
            row += '"' + taskstartdate + '",',
            row += '"' + taskenddate + '",',
            row += '"' + updated_ed + '",',
            row += '"' + ed_reason + '",',
            row += '"' + isapproved_ed + '",',
            row += '"' + resolveddate + '",',
            row += '"' + closeddate + '",',
            row += '\r\n'
          }
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'TaskListReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });      
    }
  },
  export (data, filename, mime) {
    var data
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    download(blob, filename, mime)
  },
  },
};
</script>
<style scoped>
.displayblk{
    display:none !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
/* .bordertable td,.bordertable th{
  text-align: center;
  border: 1px solid black;
} */
.bordertable .row1{
  color:black;
}
/* .bordertable thead {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black 
} */
@media(max-width:767px){
  #dashboard-ecommerce .row.match-height.dashTiles{
  padding:0px 0px;
}
#dashboard-ecommerce .match-height.dashTiles .col-6 {
    padding: 3px 3px;
}
}
</style>
