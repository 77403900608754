<template>
    <section id="dashboard-ecommerce">
        
        <section id="dashboard-ecommerce">
            <div class="row mx-0" v-if="!pagefeatures.compnaydetails">
                <div class="form-group col-md-12 bg-white px-2  py-1 rounded user_dashbarod_assi_tome_radious ">
                    <label>
                        <div class="radio-cntrl mr-4">
                            <input type="radio" @change="onChangeAssigneeType($event)" value="1" v-model="search.assigneefilter"><span>Assigned To Me</span>
                        </div>
                    </label>
                    <label>
                        <div class="radio-cntrl mr-4">
                            <input type="radio" @change="onChangeAssigneeType($event)"  value="2" v-model="search.assigneefilter"><span>My Projects</span>
                        </div>
                    </label> 
                    <label>
                        <div class="radio-cntrl">
                            <input type="radio" @change="onChangeAssigneeType($event)"  value="3" v-model="search.assigneefilter"><span>My Reportees (As per CAMS)</span>
                        </div>
                    </label> 
                    
                </div>                    
            </div>
            <div class="row match-height">
                <div class="col-xl-12 col-md-12 col-12">
                    <fieldset class="fieldset_border_vi  dashboard_fieldset_bg ">
                        <legend>My Total Projects</legend>
                        <div class="row match-height customAnimation">
                        <div class="col-xl-2 col-md-4 col-sm-6">
                            <div class="card text-center my_projects-bgs_co my_projects-bgs-1" >
                                <div class="card-body ">
                                <div class="avatar bg-light-info p-50 mb-1">
                                    <div class="avatar-content">
                                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="21" height="21">
                                            <g id="Layer">
                                                <g id="Layer">
                                                    <g id="Layer">
                                                        <g id="Layer">
                                                            <circle fill="currentColor" id="Layer" class="s0" cx="10.5" cy="10.5" r="1.8"/>
                                                        </g>
                                                    </g>
                                                    <g id="Layer">
                                                        <path id="Layer" fill="currentColor" fill-rule="evenodd" class="s0" d="m17 14.1q0.1 0.4 0.1 0.8c0.5 2.2 0.1 3.8-0.9 4.4q-0.6 0.4-1.3 0.4c-1.2 0-2.8-0.8-4.4-2.3-2.3 2-4.4 2.8-5.7 1.9-1-0.6-1.4-2.2-0.9-4.4q0-0.5 0.2-1-0.5-0.2-0.9-0.3c-2.1-0.9-3.2-2.1-3.2-3.3 0-1.4 1.5-2.7 4-3.4q-0.1-0.4-0.1-0.7c-0.4-0.2-0.6-0.5-0.6-0.9 0-0.3 0.1-0.6 0.4-0.8 0-1.4 0.4-2.4 1.1-2.8 1.3-0.9 3.4-0.1 5.7 1.9 2.3-2 4.4-2.8 5.7-1.9 1.2 0.7 1.5 2.7 0.7 5.4q0.5 0.1 0.9 0.3 0.6 0.3 1.1 0.6 0.2-0.1 0.3-0.1c0.5 0 1 0.5 1 1q0 0 0 0 0.8 0.9 0.8 1.8c0 1.4-1.5 2.7-4 3.4zm-7.2 2.6q-0.9-0.9-1.7-1.9-1.6-0.2-3.1-0.6-0.1 0.5-0.2 0.8c-0.3 1.8-0.1 3.1 0.6 3.5 0.8 0.6 2.6-0.2 4.4-1.8zm-5.1-12.3c0.3 0.1 0.6 0.5 0.6 0.9 0 0.3-0.2 0.6-0.4 0.8q0 0.2 0.1 0.5 1.5-0.3 3.2-0.5 0.8-1 1.6-1.8c-1.8-1.6-3.6-2.4-4.4-1.8-0.6 0.3-0.7 1.2-0.7 1.9zm2.6 9.3q-0.3-0.4-0.5-0.8-0.4-0.7-0.8-1.3-0.4 0.8-0.7 1.7 0.9 0.2 2 0.4zm0.1-6.5q-1.1 0.1-2.1 0.4 0.3 0.9 0.7 1.8 0.4-0.7 0.8-1.3 0.3-0.4 0.6-0.9zm0.2 5.1q0.5 0.8 1 1.5 0.9 0.1 1.8 0.1 1 0 1.9 0 0.6-0.8 1.1-1.6 0.6-0.8 1.1-1.8-0.5-1-1.1-1.9-0.5-0.7-1-1.4-0.9-0.1-1.8-0.1-1-0.1-1.9 0-0.6 0.8-1.1 1.5-0.6 0.9-1.1 1.9 0.5 1 1.1 1.8zm8.1 1.1q-0.3-0.9-0.7-1.8-0.4 0.6-0.8 1.3-0.3 0.4-0.6 0.9 1.1-0.1 2.1-0.4zm-0.7-4q0.4-0.8 0.7-1.7-1-0.2-2-0.4 0.3 0.4 0.5 0.8 0.4 0.6 0.8 1.3zm0.6-6.9c-0.8-0.6-2.6 0.2-4.4 1.8q0.9 0.9 1.7 1.9 1.6 0.2 3.1 0.6c0.6-2.2 0.4-3.8-0.4-4.3zm-4.1 3.6q-0.5-0.6-1-1.1-0.5 0.5-1 1.1 0.3 0 0.5 0 0.3 0 0.6 0 0.4 0 0.9 0zm-10.5 4.2c0 0.8 0.9 1.7 2.6 2.4q0.3 0.1 0.8 0.3 0.4-1.2 1-2.5-0.7-1.4-1.1-2.7c-2.1 0.6-3.3 1.6-3.3 2.5zm8.5 4.6q0.5 0.6 1 1.1 0.5-0.5 1-1.1-0.5 0-1.1 0-0.4 0-0.9 0zm1.7 1.8c1.8 1.6 3.6 2.4 4.4 1.8 0.7-0.4 0.9-1.7 0.6-3.5q-0.1-0.2-0.2-0.6-1.5 0.3-3.2 0.5-0.8 1-1.6 1.8zm8.8-6q0-0.4-0.4-0.9-0.2 0.1-0.4 0.1c-0.5 0-1-0.5-1-1q0-0.1 0-0.2-0.3-0.2-0.8-0.4-0.3-0.1-0.8-0.3-0.4 1.2-1 2.5 0.7 1.4 1.1 2.7c2.1-0.7 3.3-1.6 3.3-2.5z"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">{{totalproject}}
                                    
                                </h2>
                                <p class="card-text">Total Projects</p>
                            </div>
                            </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6">
                            <div class="card text-center my_projects-bgs_co my_projects-bgs-2">
                                <div class="card-body">
                                <div class="avatar bg-light-primary p-50 mb-1">
                                    <div class="avatar-content">
                                        <codesandbox-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></codesandbox-icon>
                                    </div>
                                </div>
                                <h2 class="font-weight-bolder">{{totalmodule}}</h2>
                                <p class="card-text">Total Module or Functionality</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6">
                        <div class="card text-center my_projects-bgs_co my_projects-bgs-3">
                            <div class="card-body">
                            <div class="avatar bg-light-secondary p-50 mb-1">
                                <div class="avatar-content">
                                <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                </div>
                            </div>
                            <h2 class="font-weight-bolder">{{totaltask}}</h2>
                            <p class="card-text">Total Open Micro Task</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6">
                        <div class="card text-center my_projects-bgs_co my_projects-bgs-4">
                            <div class="card-body">
                            <div class="avatar bg-light-success p-50 mb-1">
                                <div class="avatar-content">
                                <check-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></check-square-icon>
                                </div>
                            </div>
                            <h2 class="font-weight-bolder">{{completedtask}}</h2>
                            <p class="card-text">Micro Task Completed</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6">
                        <div class="card text-center my_projects-bgs_co my_projects-bgs-5">
                            <div class="card-body">
                            <div class="avatar bg-light-warning p-50 mb-1">
                                <div class="avatar-content">
                                <alert-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-circle-icon>
                                </div>
                            </div>
                            <h2 class="font-weight-bolder">{{delayedtask}}</h2>
                            <p class="card-text">Micro Task Delayed</p>
                            </div>
                        </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6">
                        <div class="card text-center my_projects-bgs_co my_projects-bgs-6">
                            <div class="card-body">
                            <div class="avatar bg-light-danger p-50 mb-1">
                                <div class="avatar-content">
                                <maximize-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></maximize-2-icon>
                                </div>
                            </div>
                            <h2 v-if="this.companycode=='fc'" class="font-weight-bolder">{{totalissues}}</h2>
                            <h2 v-else-if="this.companycode=='xb'" class="font-weight-bolder">{{totaltickets}}</h2>
                            <p v-if="this.companycode=='fc'" class="card-text">Total Issues Count</p>
                            <p v-else-if="this.companycode=='xb'" class="card-text">Total Ticket Count</p>
                            </div>
                        </div>
                        </div>
                    </div> 
                   </fieldset>                   
                </div>
            </div>
        </section>

        <div class="row match-height" style="display:none">
            <div class="col-lg-12 col-12">
                <div data-v-aae30ed8="" class="card-header" style="background-color: azure !important;"><h4 data-v-aae30ed8="" class="card-title">My Developer View</h4><div data-v-aae30ed8="" class="d-flex align-items-center"></div></div>
                <div class="card card-company-table">
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr.No</th>
                                        <th scope="col">Developer Name</th>
                                        <th scope="col">Project Name</th>
                                        <th scope="col">Total Closed Task</th>
                                        <th scope="col">Total Open Task</th>
                                        <th scope="col">Total Open Issues</th>
                                        <th scope="col">Total Time</th>
                                        <th scope="col">Project Condition</th>
                                        <th scope="col">Total Developers</th>
                                        <th scope="col">Total QA</th>
                                        <th scope="col">Total Estiamted Time</th>
                                        <th scope="col">Total Development Time</th>
                                        <th scope="col">Total QA time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="font-weight-bolder">{{educount}}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <span  class="font-weight-bolder mb-25">{{expcount}}</span>
                                            </div>
                                        </td>
                                        <td class="text-nowrap">
                                            <div class="d-flex flex-column">
                                                <span class="font-weight-bolder mb-25">{{skillscount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex flex-column">
                                                <span class="font-weight-bolder mb-25">{{coursecount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bolder mr-1">{{certcount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bolder mr-1">{{awardscount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="font-weight-bolder">{{educount}}</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <span  class="font-weight-bolder mb-25">{{expcount}}</span>
                                            </div>
                                        </td>
                                        <td class="text-nowrap">
                                            <div class="d-flex flex-column">
                                                <span class="font-weight-bolder mb-25">{{skillscount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex flex-column">
                                                <span class="font-weight-bolder mb-25">{{coursecount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bolder mr-1">{{certcount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bolder mr-1">{{awardscount}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <span class="font-weight-bolder mr-1">{{awardscount}}</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="dashboard-ecommerce" v-if="this.companycode=='fc'">
        <h4 class="customTitle py-1 mb-0">My Daily Open Micro Task</h4>
        <div class="row dashTiles">
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation " @click="redirecttotask('open',{})">
                <div class="card-header open_task_bg_read01">
                <div>
                    <h2 class="font-weight-bolder mb-0" >{{redtask}}</h2>
                    <p class="card-text">Total Red Micro Task</p>
                </div>
                <div class="illustretaravatar-50  m-0">
                    <div class="avatar-content">
                    <img class="img-fluid" src="../../../public/images/icons/task_icon1.png" alt=" V2" />
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation " @click="redirecttotask('open',{})">
                <div class="card-header open_task_bg_read02">
                <div>
                    <h2 class="font-weight-bolder mb-0" >{{greentask}}</h2>
                    <p class="card-text">Total Green Micro Task</p>
                </div>
                <div class="illustretaravatar-50 m-0">
                    <div class="avatar-content">
                    <img class="img-fluid" src="../../../public/images/icons/task_icon1.png" alt=" V2" />
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation " @click="redirecttotask('open',{})">
                <div class="card-header open_task_bg_read03">
                <div>
                    <h2 class="font-weight-bolder mb-0" >{{bluetask}}</h2>
                    <p class="card-text">Total Blue Micro Task</p>
                </div>
                <div class="illustretaravatar-50 m-0">
                    <div class="avatar-content">
                    <img class="img-fluid" src="../../../public/images/icons/task_icon1.png" alt=" V2" />
                    </div>
                </div>
                </div>
            </div>
            </div>

        </div>
        </section>

                <!-- start-->
    <div>
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>         
        <section id="dashboard-ecommerce" v-if="this.companycode=='fc'">    
         <div v-if="this.pagefeatures.delayedbtc && this.companycode=='fc'">
            <div class=" text-editar_discription_jobs masters_detailstabing_pills px-0">
                <ul class="nav nav-pills nav-pill-warning pt-1">
                    <li class="nav-item">
                        <a class="nav-link active"  data-toggle="pill"
                            aria-expanded="false" @click="buttonBtc()">Delayed BTC</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"  data-toggle="pill"
                            aria-expanded="false" @click="buttonTask()">Task list </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"  data-toggle="pill"
                            aria-expanded="false" @click="buttonIssue()">Issue list </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="pill"
                            aria-expanded="false" @click="buttonTicket()">Ticket list </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="pill"
                            aria-expanded="false" @click="buttonIncident()">Incident list </a>
                    </li>
                </ul>

                <div v-if="btcListActive == true">
                   <div class="row match-height" >
                    <div class="col-lg-12 col-12">
                        <div class="card card-company-table">
                             <div class="card-body p-0">
                                <div class="table-responsive datatableview">
                                    <table class="table table-hover table_stickys table-col-td-white" aria-describedby="mydesc" >
                                        <thead>
                                            <tr>
                                                <th scope="col"  class="align-middle first-col-sticky" style="min-width:50px;">Sr.No</th>
                                                <th scope="col" class="align-middle second-col-sticky" style="max-width:200px">BTC CODE</th>
                                                <th scope="col" class="align-middle" style="max-width:140px">BTC Name</th>
                                                <th scope="col" class="align-middle">BTC Status</th>
                                                <th scope="col" class="align-middle">Micro Task Type / Severity</th>
                                                <th scope="col" class="align-middle">Project Details</th>
                                                <th scope="col" class="align-middle">QA Lead</th>
                                                <th scope="col" class="align-middle">Estimated  date Details</th>
                                                <th scope="col" class="align-middle">Estimate / Delayed</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr v-for="(data,i) in mydelaytasklist" v-bind:key="i" v-bind:class="{'table-danger':'red'}">
                                                <td data-column="Sr.No" class="first-col-sticky text-center align-middle">
                                                    <div class="d-flex align-items-center ">
                                                        <div class="font-weight-normal">{{i+1+d_currPageNum*d_pagelimit}}</div>
                                                    </div>
                                                </td>
                                                <td data-column="BTC CODE" class="second-col-sticky">
                                                    <div class="d-flex align-items-center">
                                                        <span style="cursor:pointer; " @click="redirecttoBusinessTaskDetail('view', {businesstaskmasterid:data.businesstaskmasterid})" class="font-weight-normal mb-25">{{data.businesstaskcode}}</span>
                                                    </div>
                                                </td>
                                                <td data-column="BTC Name" style="max-width: 160px;">
                                                   
                                                        <span style="cursor:pointer;" @click="redirecttoBusinessTaskDetail('view', {businesstaskmasterid:data.businesstaskmasterid})" class="font-weight-normal text-dotted mb-25">{{data.taskname}}</span>
                                                    
                                                </td>
                                                <td data-column="BTC Status" class="">
                                                        <span class="text-dotted font-weight-normal mb-25">{{data.taskstatus}}</span>
                                                    
                                                </td>
                                                <td data-column="Micro Task Type / Severity" class="details_column_table">
                                                    <span class="heading_column_text"> Task Type : </span>
                                                    <span class="text-dotted font-weight-normal mb-25">{{data.tasktype}}</span>
                                                    <hr class="hr_border-bottoms-0">
                                                    <span class="heading_column_text"> Task Severity : </span>
                                                    <span class="text-dotted font-weight-normal mb-25">{{data.taskkey}}</span>
                                                    
                                                </td>
                                                
                                                <td data-column="Project Details" class=" details_column_table">
                                                    <span class="heading_column_text"> Project Name : </span>
                                                      <span class="text-dotted font-weight-normal mb-25">{{data.projectname}}</span>
                                                      <hr class="hr_border-bottoms-0">
                                                      <span class="heading_column_text"> Project Manager : </span>
                                                      <span class="text-dotted font-weight-normal mb-25 capitalisetext">{{data.projectmaanager}}</span>
                                                      <hr class="hr_border-bottoms-0">
                                                      <span class="heading_column_text"> Eng. Manager : </span>
                                                      <span class="text-dotted font-weight-normal mb-25 capitalisetext">{{data.engineeringmaanager}}</span>
                                                </td>
                                                
                                                
                                                <td data-column="QA Lead" class="text-nowrap">
                                                    <div class="d-flex flex-column">
                                                        <span class="text-dotted font-weight-normal mb-25 capitalisetext">{{data.qalead}}</span>
                                                    </div>
                                                </td>
                                                <td data-column="Estimated  date Details" class="details_column_table">
                                                    <span class="heading_column_text"> Start Date : </span>
                                                        <span class="text-dotted font-weight-normal mb-25">{{dateFormat(data.startdate)}}</span>
                                                        <hr class="hr_border-bottoms-0">
                                                        <span class="heading_column_text"> End Date : </span>
                                                        <span class="text-dotted font-weight-normal mb-25">{{dateFormat(data.estimatedddate)}}</span>
                                                        <hr class="hr_border-bottoms-0">
                                                        <span class="heading_column_text"> Est. Hours : </span>
                                                        <span class="text-dotted font-weight-normal mb-25">{{data.estimatedhours}}</span>
                                                </td>
                                               
                                                
                                                <td data-column="Estimate / Delayed" class="text-nowrap details_column_table">
                                                    <span class="heading_column_text"> Ballpark Estimate in days : </span>
                                                    <span class="text-dotted font-weight-normal mb-25"> 
                                                        {{data.taskestimates?data.taskestimates:'NA'}}
                                                    </span>
                                                    <hr class="hr_border-bottoms-0">
                                                    <span class="heading_column_text"> Delayed By(Days): </span>
                                                    <span class="text-dotted font-weight-normal mb-25">{{data.ageingdays}}</span>
                                                  
                                                </td>
                                                
                                        
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-1" v-if="mydelaytasklist ? mydelaytasklist.length==0 :false">
                        <tr><td colspan="14" style="text-align: center;">No Records Found</td></tr>
                    </div>
                 </div>
                 <div class="pageCenter text-center mb-1">
                    <pagination v-if="d_showtabledata" v-model="d_page" :records="d_totalcount" :per-page="d_pagelimit" @paginate="clickCallback_delaytask"/>
                </div>
                </div>
                <div v-if="taskListActive == true">
                    <div v-if="this.companycode=='fc'" >
                      <div class="col-lg-12 col-12 px-0">
                       <div class=" card card-company-table">
                        <div class="card-body p-0">
                           <div class="table-responsive datatableview">  
                            <table class="table table-hover table_stickys table-col-td-white" style="background:#fff!important;" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col" class="align-middle first-col-sticky">Sr.No</th>
                                        <th scope="col" class="align-middle second-col-sticky" style="min-width:200px">Micro Task Code</th>
                                        <th scope="col" class="align-middle" style="min-width:200px">Micro Task Name</th>
                                        <th scope="col" class="align-middle">Assigned To</th>
                                        <th scope="col" class="align-middle">Micro Task Type</th>
                                        <th scope="col" class="align-middle">Functionality Name</th>
                                        <th scope="col" class="align-middle">Project Name</th>
                                        <th scope="col" class="align-middle" style="min-width:200px">Module Name</th>
                                        <th scope="col" class="align-middle">Estimated Efforts </th>
                                        <th scope="col" class="align-middle">Start Date</th>
                                        <th scope="col" class="align-middle">Estimated End Date</th>
                                        <th scope="col" class="align-middle" style="min-width:160px">Work Compelted %</th>
                                        <th scope="col" class="align-middle">Current Status</th>
                                        <th scope="col" class="align-middle" style="min-width:180px">Total Time Take To Complete</th>
                                        <th scope="col" class="align-middle" style="min-width:160px">Micro Task Approved By</th>
                                        <th scope="col" class="align-middle" style="min-width:160px">Architect Approved By</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr v-for="(data,i) in myopentasklist" v-bind:key="i"  v-bind:class="{'table-danger':data.color=='red', 'table-success':data.color=='green'}" >
                                        <td data-column="Sr.No" class="first-col-sticky">
                                            <div class="d-flex align-items-center">
                                                <div class="font-weight-normal">{{i+1+currPageNum*pagelimit}}</div>
                                            </div>
                                        </td>
                                        <td data-column="Micro Task Code" class="second-col-sticky">
                                            <div class="d-flex align-items-center">
                                                <span style="cursor:pointer; " @click="redirecttoTaskDetail('view', {taskid:data.taskmasterid})" class="font-weight-normal mb-25">{{data.taskcode}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Micro Task Name">
                                            <div class="d-flex align-items-center">
                                                <span style="cursor:pointer; " @click="redirecttoTaskDetail('view', {taskid:data.taskmasterid})" class="font-weight-normal text-dotted mb-25">{{data.taskname}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Assigned To" class="text-nowrap">
                                            <div class="d-flex flex-column">
                                                <span class="text-dotted font-weight-normal mb-25 capitalisetext">{{data.assignedto}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Micro Task Type" class="text-nowrap">
                                            <div class="d-flex flex-column">
                                                <span class="text-dotted font-weight-normal mb-25">{{data.tasktype}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Functionality Name">
                                            <div class="d-flex flex-column">
                                                <span class=" text-dotted font-weight-normal mb-25">{{data.functionlityname}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Project Name">
                                            <div class="d-flex align-items-center">
                                                <span class="text-dotted font-weight-normal mr-1">{{data.projectname}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Module Name">
                                            <div class="d-flex align-items-center">
                                                <span class="text-dotted font-weight-normal mr-1">{{data.projectmodulename}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Estimated Efforts">
                                            <div class="d-flex align-items-center">
                                                <div class="text-dotted font-weight-normal"></div>
                                            </div>
                                        </td>
                                        <td data-column="Start Date" class="text-nowrap">
                                            <div class="d-flex align-items-center">
                                                <span  class="text-dotted font-weight-normal mb-25">{{dateFormat(data.taskstartdate)}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Estimated End Date" class="text-nowrap">
                                            <div class="d-flex flex-column">
                                                <span class="text-dotted font-weight-normal mb-25">{{dateFormat(data.taskenddate)}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Work Compelted %">
                                            <div class="d-flex flex-column">
                                                <span class="text-dotted font-weight-normal mb-25"></span>
                                            </div>
                                        </td>
                                        <td data-column="Current Status">
                                            <div class="d-flex align-items-center">
                                                <span class="text-dotted font-weight-normal mr-1">{{data.taskstatus}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Total Time Take To Complete">
                                            <div class="d-flex align-items-center">
                                                <span class="text-dotted font-weight-normal mr-1"></span>
                                            </div>
                                        </td>
                                        <td data-column="Micro Task Approved By">
                                            <div class="d-flex align-items-center">
                                                <span class="text-dotted font-weight-normal mr-1">{{data.approvedby}}</span>
                                            </div>
                                        </td>
                                        <td data-column="Architect Approved By">
                                            <div class="d-flex align-items-center">
                                                <span class="font-weighttext-dotted font-weight-normal mr-1">{{data.approvedby}}</span>
                                            </div>
                                        </td>
                                    </tr>                 
                                </tbody>
                            </table> 
                            </div>
                         </div>
                      </div>
                     </div>
                    <div class="px-1" v-if="myopentasklist ? myopentasklist.length==0 :false">
                        <tr><td colspan="14" style="text-align: center;">No Records Found</td></tr>
                     </div>
                   </div>
                    <div class="pageCenter text-center mb-1">
                        <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                    </div>
                </div>
                <div v-if="issueListActive == true">   
                    <div v-if="this.companycode=='fc'">
                    <div class="col-lg-12 col-12 px-0">
                        <div class="card card-company-table">
                        <div class="card-body p-0">
                            <div class="table-responsive datatableview">  
                                <table class="table table-hover table_stickys table-col-td-white" style="background:#fff!important;" aria-describedby="mydesc">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="align-middle first-col-sticky">Sr.No</th>
                                            <th scope="col" class="align-middle second-col-sticky" style="min-width:200px">Issue Code</th>
                                            <th scope="col" class="align-middle" style="min-width:200px">Issue Name</th>
                                            <th scope="col" class="align-middle">Assigned To</th>
                                            <th scope="col" class="align-middle">Type</th>
                                            <th scope="col" class="align-middle">Issue Status</th>
                                            <th scope="col" class="align-middle">Project Name</th>
                                            <th scope="col" class="align-middle" style="min-width:200px">Priority</th>
                                            <th scope="col" class="align-middle">Severity</th>
                                            <th scope="col" class="align-middle">Qc Cycle</th>
                                            <th scope="col" class="align-middle" style="min-width:160px">Created By</th>
                                            <th scope="col" class="align-middle">Created Date</th>

                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr v-for="(data,i) in myissuelist" v-bind:key="i"  v-bind:class="{'table-danger':data.color=='red', 'table-success':data.color=='green'}" >
                                            <td data-column="Sr.No" class="first-col-sticky">
                                                <div class="d-flex align-items-center">
                                                    <div class="font-weight-normal">{{i+1+i_currPageNum*i_pagelimit}}</div>
                                                </div>
                                            </td>
                                            <td data-column="Issue Code" class="second-col-sticky">
                                                <div class="d-flex align-items-center">
                                                    <span style="cursor:pointer; " @click="redirecttoIssueDetail('view', {issueid:data.issueid})" class="font-weight-normal mb-25">{{data.issuecode}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Issue Name">
                                                <div class="d-flex align-items-center">
                                                    <span  style="cursor:pointer; " @click="redirecttoIssueDetail('view', {issueid:data.issueid})" class="font-weight-normal mb-25">{{data.issuename}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Assigned To" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25 capitalisetext">{{data.empfullname}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Type" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25">{{data.typename}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Issue Status">
                                                <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50">{{data.status}}</div> 
                                                <div v-else-if="data.status.toLowerCase()=='feedback'" class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                                            </td>
                                            <td data-column="Project Name">
                                                <div class="d-flex align-items-center">
                                                    <span class="text-dotted font-weight-normal mr-1">{{data.projectname}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Priority">
                                                <div v-if="data.priority =='low'" class="capitalisetext badge badge-pill badge-light-info mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority =='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority =='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority == null || data.priority == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.priority}}</div>

                                            </td>
                                            <td data-column="Severity">
                                                    <div v-if="data.sevairtyname =='Critical'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div  v-else-if="data.sevairtyname =='Major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname =='Minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname =='Cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname =='Moderate'" class="badge badge-pill badge-light-info mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                    <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.sevairtyname}}</div>
                                            </td>
                                            <td data-column="Qc Cycle" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="font-weight-bold capitalisetext">{{data.qacycle}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Created By">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25">{{ data.creator }}</span>
                                                </div>
                                            </td>
                                            <td data-column="Created Date">
                                                <div class="d-flex align-items-center">
                                                    <span class="text-dotted font-weight-normal mr-1">{{dateMonthFormat(data.createddate)}}</span>
                                                </div>
                                            </td>
                                        
                                        </tr>
                                    </tbody>
                                </table>
                               </div>
                        </div>
                    </div>
                </div>
                <div class="px-1" v-if="myissuelist ? myissuelist.length==0 :false">
                <tr><td colspan="14" style="text-align: center;">No Records Found</td></tr>
                </div>
            </div>
            <div class="pageCenter text-center mb-1">
                    <pagination v-if="i_showtabledata" v-model="i_page" :records="i_totalcount" :per-page="i_pagelimit" @paginate="clickCallback_issuelist"/>
            </div>
                </div>
                <div v-if="ticketListActive == true">   
                    <div v-if="this.companycode=='fc'">
                    <div class="col-lg-12 col-12 px-0">
                        <div class="card card-company-table">
                        <div class="card-body p-0">
                            <div class="table-responsive datatableview">  
                                <table class="table table-hover table_stickys table-col-td-white" style="background:#fff!important;" aria-describedby="mydesc">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="align-middle first-col-sticky">Sr.No</th>
                                            <th scope="col" class="align-middle second-col-sticky" style="min-width:200px">Ticket Code</th>
                                            <th scope="col" class="align-middle" style="min-width:200px">Ticket Name</th>
                                            <th scope="col" class="align-middle">Assigned To</th>
                                            <th scope="col" class="align-middle">Type</th>
                                            <th scope="col" class="align-middle">Ticket Status</th>
                                            <th scope="col" class="align-middle">Project Name</th>
                                            <th scope="col" class="align-middle" style="min-width:200px">Priority</th>
                                            <th scope="col" class="align-middle">Severity</th>
                                            <th scope="col" class="align-middle" style="min-width:160px">Created By</th>
                                            <th scope="col" class="align-middle">Created Date</th>

                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr v-for="(data,i) in myissuelist" v-bind:key="i"  v-bind:class="{'table-danger':data.color=='red', 'table-success':data.color=='green'}" >
                                            <td data-column="Sr.No" class="first-col-sticky">
                                                <div class="d-flex align-items-center">
                                                    <div class="font-weight-normal">{{i+1+i_currPageNum*i_pagelimit}}</div>
                                                </div>
                                            </td>
                                            <td data-column="Ticket Code" class="second-col-sticky">
                                                <div class="d-flex align-items-center">
                                                    <span style="cursor:pointer; " @click="redirecttoTicketDetail('view', {issueid:data.issueid})" class="font-weight-normal mb-25">{{data.issuecode}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Ticket Name">
                                                <div class="d-flex align-items-center">
                                                    <span  style="cursor:pointer; " @click="redirecttoTicketDetail('view', {issueid:data.issueid})" class="font-weight-normal mb-25">{{data.issuename}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Assigned To" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25 capitalisetext">{{data.empfullname}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Type" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25">{{data.typename}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Ticket Status">
                                                <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50">{{data.status}}</div> 
                                                <div v-else-if="data.status.toLowerCase()=='feedback'" class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                                            </td>
                                            <td data-column="Project Name">
                                                <div class="d-flex align-items-center">
                                                    <span class="text-dotted font-weight-normal mr-1">{{data.projectname}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Priority">
                                                <div v-if="data.priority =='low'" class="capitalisetext badge badge-pill badge-light-info mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority =='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority =='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority == null || data.priority == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.priority}}</div>

                                            </td>
                                            <td data-column="Severity">
                                                    <div v-if="data.sevairtyname =='Critical'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div  v-else-if="data.sevairtyname =='Major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname =='Minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname =='Cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname =='Moderate'" class="badge badge-pill badge-light-info mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                    <div v-else-if="data.sevairtyname == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                    <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.sevairtyname}}</div>
                                            </td>
                                            <td data-column="Created By">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25">{{ data.creator }}</span>
                                                </div>
                                            </td>
                                            <td data-column="Created Date">
                                                <div class="d-flex align-items-center">
                                                    <span class="text-dotted font-weight-normal mr-1">{{dateMonthFormat(data.createddate)}}</span>
                                                </div>
                                            </td>
                                        
                                        </tr>
                                    </tbody>
                                </table>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div class="px-1" v-if="myissuelist ? myissuelist.length==0 :false">
                <tr><td colspan="14" style="text-align: center;">No Records Found</td></tr>
                </div>
            </div>
            <div class="pageCenter text-center mb-1">
                    <pagination v-if="i_showtabledata" v-model="i_page" :records="i_totalcount" :per-page="i_pagelimit" @paginate="clickCallback_issuelist"/>
            </div>
                </div>
                <div v-if="incidentListActive == true">   
                    <div v-if="this.companycode=='fc'">
                    <div class="col-lg-12 col-12 px-0">
                        <div class="card card-company-table">
                        <div class="card-body p-0">
                            <div class="table-responsive datatableview">  
                                <table class="table table-hover table_stickys table-col-td-white" style="background:#fff!important;" aria-describedby="mydesc">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="align-middle first-col-sticky">Sr.No</th>
                                            <th scope="col" class="align-middle second-col-sticky" style="min-width:200px">Incident Code</th>
                                            <th scope="col" class="align-middle" style="min-width:200px">Incident Name</th>
                                            <th scope="col" class="align-middle">Assigned To</th>
                                            <th scope="col" class="align-middle">Incident Date</th>
                                            <th scope="col" class="align-middle"> Incident Type</th>
                                            <th scope="col" class="align-middle">Incident Status</th>
                                            <th scope="col" class="align-middle">Project Name</th>
                                            <th scope="col" class="align-middle" style="min-width:200px">Priority</th>
                                            <th scope="col" class="align-middle">Severity</th>
                                            <th scope="col" class="align-middle">Downtime</th>
                                            <th scope="col" class="align-middle" style="min-width:160px">Created Details</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr v-for="(data,i) in myincidentlist" v-bind:key="i"  v-bind:class="{'table-danger':data.color=='red', 'table-success':data.color=='green'}" >
                                            <td data-column="Sr.No" class="first-col-sticky">
                                                <div class="d-flex align-items-center">
                                                    <div class="font-weight-normal">{{i+1+im_currPageNum*im_pagelimit}}</div>
                                                </div>
                                            </td>
                                            <td data-column="Incident Code" class="second-col-sticky">
                                                <div class="d-flex align-items-center">
                                                    <span style="cursor:pointer; " @click="redirecttoIncidentDetail('view', {id:data.id})" class="font-weight-normal mb-25">{{data.incidentcode}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Incident Name">
                                                <div class="d-flex align-items-center">
                                                    <span  style="cursor:pointer; " @click="redirecttoIncidentDetail('view', {id:data.id})" class="font-weight-normal mb-25">{{data.incidentname}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Assigned To" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25 capitalisetext">{{data.empname}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Incident Date">
                                                <div class="d-flex align-items-center">
                                                    <span class="text-dotted font-weight-normal mr-1">{{ dateMonthFormat(data.datetime) }}</span>
                                                </div>
                                            </td>
                                            <td data-column="Incident Type" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="text-dotted font-weight-normal mb-25">{{data.incidenttype}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Incident Status">
                                                <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{data.status}}</div>
                                                <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                                            </td>
                                            <td data-column="Project Name">
                                                <div class="d-flex align-items-center">
                                                    <span class="text-dotted font-weight-normal mr-1">{{data.projectname}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Priority">
                                                <div v-if="data.priority =='Low'" class="capitalisetext badge badge-pill badge-light-info mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority =='Medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority =='High'" class=" capitalisetext badge badge-pill badge-light-danger mr-50">{{data.priority}}</div>
                                                <div v-else-if="data.priority == null || data.priority == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.priority}}</div>

                                            </td>
                                            <td data-column="Severity">
                                                    <div v-if="data.severity =='Critical'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.severity}}</div>
                                                    <div v-else-if="data.severity =='Major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.severity}}</div>
                                                    <div v-else-if="data.severity =='Minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.severity}}</div>
                                                    <div v-else-if="data.severity =='Cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.severity}}</div>
                                                    <div v-else-if="data.severity =='Moderate'" class="badge badge-pill badge-light-info mr-50 capitalisetext">{{data.severity}}</div>
                                                    <div v-else-if="data.severity == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                    <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50">{{data.severity}}</div>
                                            </td>
                                            <td data-column="Downtime" class="text-nowrap">
                                                <div class="d-flex flex-column">
                                                    <span class="font-weight-bold capitalisetext">{{data.totaldowntime.slice(0,-3)}}</span>
                                                </div>
                                            </td>
                                            <td data-column="Created Details">
                                                <span class="release_hr_tag_span"> Created By :</span>
                                                 {{ data.createdby.split("@")[0] }}
                                                <hr class="release_hr_tag_tab">
                                                 <span class="release_hr_tag_span"> Created Date :</span>
                                                 {{ dateMonthFormat(data.createddate) }}
                                            </td>
                                           
                                        
                                        </tr>
                                    </tbody>
                                </table>
                             </div>
                        </div>
                    </div>
                </div>
                <div class="px-1" v-if="myincidentlist ? myincidentlist.length==0 :false">
                <tr><td colspan="14" style="text-align: center;">No Records Found</td></tr>
                </div>
            </div>
            <div class="pageCenter text-center mb-1">
                    <pagination v-if="im_showtabledata" v-model="im_page" :records="im_totalcount" :per-page="im_pagelimit" @paginate="clickCallback_incidentlist"/>
            </div>
                </div>
            </div> 

         </div>
        </section>
      </div>  
            <!-- end -->
            <h4 v-if="this.companycode=='fc'" class="customTitle py-1 mb-0">My Daily Re-Open Micro Task</h4>
         <div class="row dashTiles" v-if="this.companycode=='fc'">
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor1" @click="redirecttotask('reopen',{})">
                <div class="card-header">
                <div class="task-count-width_w">
                    <h2 class="font-weight-bolder mb-0" >{{redreopentask}}</h2>
                    <p class="card-text">Total Re-Open Red Micro Task </p>
                </div>
                <div class="avatar bg-light-danger p-50 m-0">
                    <div class="avatar-content">
                   <em data-feather='eye' class="custom-class avatar-icon font-medium-3"></em>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor2" @click="redirecttotask('reopen',{})">
                <div class="card-header">
                <div class="task-count-width_w">
                    <h2 class="font-weight-bolder mb-0" >{{greenreopentask}}</h2>
                    <p class="card-text">Total Re-Open Green Micro Task</p>
                </div>
                <div class="avatar bg-light-success p-50 m-0">
                    <div class="avatar-content">
                    <em data-feather='eye' class="custom-class avatar-icon font-medium-3"></em>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor3" @click="redirecttotask('reopen',{})">
                <div class="card-header">
                <div class="task-count-width_w">
                    <h2 class="font-weight-bolder mb-0" >{{bluereopentask}}</h2>
                    <p class="card-text">Total Re-Open Blue Micro Task</p>
                </div>
                <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                    <em data-feather='eye' class="custom-class avatar-icon font-medium-3"></em>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>   

        <h4 class="customTitle py-1 mb-0" v-if="this.companycode=='fc'">My Daily Approved Micro Task</h4>
        <div class="row dashTiles" v-if="this.companycode=='fc'">
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor1" @click="redirecttotask('approved',{})">
                <div class="card-header">
                <div class="task-count-width_w">
                    <h2 class="font-weight-bolder mb-0" >{{approveredtask}}</h2>
                    <p class="card-text">Total Approved Red Micro Task</p>
                </div>
                <div class="avatar bg-light-danger p-50 m-0">
                    <div class="avatar-content">
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="24" height="24">
                            <g id="Layer">
                                <g id="Layer">
                                    <path id="Layer" fill="currentColor" d="m9.8 5.4l-3.2 3.2q-0.2 0.2-0.5 0.2-0.2 0-0.4-0.2l-1.6-1.5c-0.1-0.1-0.1-0.3 0-0.5l0.5-0.4c0.1-0.1 0.3-0.1 0.4 0l1.1 1.1 2.8-2.8c0.1-0.1 0.3-0.1 0.4 0l0.5 0.5c0.1 0.1 0.1 0.3 0 0.4z"/>
                                </g>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m10.3 6.3h6.3v1.7h-6.3z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m9 10.1h7.6v1.7h-7.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m5.7 10.1h1.6v1.7h-1.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m5.7 13.9h1.6v1.6h-1.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m9 13.9h7.6v1.6h-7.6z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor2" @click="redirecttotask('approved',{})">
                <div class="card-header">
                <div class="task-count-width_w">
                    <h2 class="font-weight-bolder mb-0" >{{approvegreentask}}</h2>
                    <p class="card-text">Total Approved Green Micro Task</p>
                </div>
                <div class="avatar bg-light-success p-50 m-0">
                    <div class="avatar-content">
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="24" height="24">
                            <g id="Layer">
                                <g id="Layer">
                                    <path id="Layer" fill="currentColor" d="m9.8 5.4l-3.2 3.2q-0.2 0.2-0.5 0.2-0.2 0-0.4-0.2l-1.6-1.5c-0.1-0.1-0.1-0.3 0-0.5l0.5-0.4c0.1-0.1 0.3-0.1 0.4 0l1.1 1.1 2.8-2.8c0.1-0.1 0.3-0.1 0.4 0l0.5 0.5c0.1 0.1 0.1 0.3 0 0.4z"/>
                                </g>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m10.3 6.3h6.3v1.7h-6.3z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m9 10.1h7.6v1.7h-7.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m5.7 10.1h1.6v1.7h-1.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m5.7 13.9h1.6v1.6h-1.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m9 13.9h7.6v1.6h-7.6z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
            <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor3" @click="redirecttotask('approved',{})">
                <div class="card-header">
                <div class="task-count-width_w">
                    <h2 class="font-weight-bolder mb-0" >{{approvebluetask}}</h2>
                    <p class="card-text">Total Approved Blue Micro Task</p>
                </div>
                <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="24" height="24">
                            <g id="Layer">
                                <g id="Layer">
                                    <path id="Layer" fill="currentColor" d="m9.8 5.4l-3.2 3.2q-0.2 0.2-0.5 0.2-0.2 0-0.4-0.2l-1.6-1.5c-0.1-0.1-0.1-0.3 0-0.5l0.5-0.4c0.1-0.1 0.3-0.1 0.4 0l1.1 1.1 2.8-2.8c0.1-0.1 0.3-0.1 0.4 0l0.5 0.5c0.1 0.1 0.1 0.3 0 0.4z"/>
                                </g>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m10.3 6.3h6.3v1.7h-6.3z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m9 10.1h7.6v1.7h-7.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m5.7 10.1h1.6v1.7h-1.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m5.7 13.9h1.6v1.6h-1.6z"/>
                            </g>
                            <g id="Layer">
                                <path id="Layer" fill="currentColor" d="m9 13.9h7.6v1.6h-7.6z"/>
                            </g>
                        </svg>
                    </div>
                </div>
                </div>
            </div>
            </div>

        </div>

        <section id="dashboard-ecommerce" v-if="this.companycode=='fc'">
            <h4 class="customTitle py-1 mb-0">My Open Issues</h4>
            <div class="row dashTiles">
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor1" @click="redirecttoissue('open',{})">
                    <div class="card-header">
                    <div>
                        <h2 class="font-weight-bolder mb-0" >{{redissues}}</h2>
                        <p class="card-text">Total Red Issues</p>
                    </div>
                    <div class="avatar bg-light-danger p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor2" @click="redirecttoissue('open',{})">
                    <div class="card-header">
                    <div>
                        <h2 class="font-weight-bolder mb-0">{{greenissues}}</h2>
                        <p class="card-text">Total Green Issues</p>
                    </div>
                    <div class="avatar bg-light-success p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor3" @click="redirecttoissue('open',{})">
                    <div class="card-header">
                    <div>
                    <h4 class="font-weight-bolder mb-0" >{{blueissues}}</h4>
                        <p class="card-text">Total Blue Issues</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <h4 class="customTitle py-1 mb-0">My Re-Open Issues</h4>
            <div class="row dashTiles">
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor1" @click="redirecttoissue('reopen',{})">
                    <div class="card-header">
                    <div class="task-count-width_w">
                        <h2 class="font-weight-bolder mb-0" >{{redreopenissues}}</h2>
                        <p class="card-text">Total Re-Open Red Issues</p>
                    </div>
                    <div class="avatar bg-light-danger p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor2" @click="redirecttoissue('reopen',{})">
                    <div class="card-header">
                    <div class="task-count-width_w">
                        <h2 class="font-weight-bolder mb-0">{{greenreopenissues}}</h2>
                        <p class="card-text">Total Re-open Green Issues</p>
                    </div>
                    <div class="avatar bg-light-success p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor3" @click="redirecttoissue('reopen',{})">
                    <div class="card-header">
                    <div class="task-count-width_w">
                    <h4 class="font-weight-bolder mb-0" >{{bluereopenissues}}</h4>
                        <p class="card-text">Total Re-open Blue Issues</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
        </section>        
        
       

       
        <section id="dashboard-ecommerce">
            <h4 class="customTitle py-1 mb-0">My Open Tickets</h4>
            <div class="row dashTiles">
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor1" @click="redirecttoticket('open',{})">
                    <div class="card-header">
                    <div>
                        <h2 class="font-weight-bolder mb-0" >{{redticket}}</h2>
                        <p class="card-text">Total Red Tickets</p>
                    </div>
                    <div class="avatar bg-light-danger p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor2" @click="redirecttoticket('open',{})">
                    <div class="card-header">
                    <div>
                        <h2 class="font-weight-bolder mb-0">{{greenticket}}</h2>
                        <p class="card-text">Total Green Tickets</p>
                    </div>
                    <div class="avatar bg-light-success p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor3" @click="redirecttoticket('open',{})">
                    <div class="card-header">
                    <div>
                    <h4 class="font-weight-bolder mb-0" >{{blueticket}}</h4>
                        <p class="card-text">Total Blue Tickets</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <h4 class="customTitle py-1 mb-0">My Re-Open Tickets</h4>
            <div class="row dashTiles">
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor1" @click="redirecttoticket('reopen',{})">
                    <div class="card-header">
                    <div class="task-count-width_w">
                        <h2 class="font-weight-bolder mb-0" >{{redreopenticket}}</h2>
                        <p class="card-text">Total Re-Open Red Tickets</p>
                    </div>
                    <div class="avatar bg-light-danger p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor2" @click="redirecttoticket('reopen',{})">
                    <div class="card-header">
                    <div class="task-count-width_w">
                        <h2 class="font-weight-bolder mb-0">{{greenreopenticket}}</h2>
                        <p class="card-text">Total Re-open Green Tickets</p>
                    </div>
                    <div class="avatar bg-light-success p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor3" @click="redirecttoticket('reopen',{})">
                    <div class="card-header">
                    <div class="task-count-width_w">
                    <h4 class="font-weight-bolder mb-0" >{{bluereopenticket}}</h4>
                        <p class="card-text">Total Re-open Blue Tickets</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                        <alert-triangle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-triangle-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
        </section>
        <section id="dashboard-ecommerce" v-if="this.companycode=='fc'">
            <h4 class="customTitle py-1 mb-0">My Open Incident</h4>
            <div class="row dashTiles">
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor1" @click="redirecttoincident('open', {})">
                    <div class="card-header">
                    <div>
                        <h2 class="font-weight-bolder mb-0" >{{redincident}}</h2>
                        <p class="card-text">Total Red Incident</p>
                    </div>
                    <div class="avatar bg-light-danger p-50 m-0">
                        <div class="avatar-content">
                        <alert-octagon-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-octagon-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor2" @click="redirecttoincident('open', {})">
                    <div class="card-header">
                    <div>
                    <h2 class="font-weight-bolder mb-0" >{{greenincident}}</h2>
                        <p class="card-text">Total Green Incident</p>
                    </div>
                    <div class="avatar bg-light-success p-50 m-0">
                        <div class="avatar-content">
                        <alert-octagon-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-octagon-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                <div class="card cursor-pointer card_bottom_line_animation bottom_bg_bor3" @click="redirecttoincident('open', {})">
                    <div class="card-header">
                    <div>
                    <h2 class="font-weight-bolder mb-0" >{{blueincident}}</h2>
                        <p class="card-text">Total Blue Incident</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                        <alert-octagon-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-octagon-icon>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>


        </section>
        <div v-if=" this.companycode=='fc' && (this.roleid==492 || this.roleid==477) " class="login_flashscreen_popup"> 
            <div id="popup">
            <div  class="modal-dialog modal-dialog-centered modal-lg">
               <div class="modal-content login_flashscreen_popupbg">
                    <div class="  headerbodyspop">
                    <div class="modal-header">
                        <h4 class="modal-title" id="flashdashboard">Flash Dashboard</h4>
                    </div>
                    <div class="modal-body">
                        <loading :active.sync="isLoadingdashboard" :can-cancel="true"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
                        <div class="row match-height customAnimation" >
                            <div class="col-lg-12 col-12">
                            <div class="row match-height">
                            <div class="col-xl-12 col-md-12 col-12">
                                
                                <div class="row match-height  pt-1 mb-0">
                                    
                                <div class="col-xl-4 col-md-4 col-sm-6 mb-1 ">
                                <div class="card text-center rainbow" >
                                    
                                    <div class="card-body ">
                                        <div class="avatar bg-light-warning p-50 mb-1">
                                            <div class="avatar-content">
                                            <alert-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-circle-icon>
                                            </div>
                                        </div>
                                    <h2 class="font-weight-bolder">{{delaybtc}}</h2>
                                    <p class="card-text">Delayed BTC</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-6  mb-1">
                                    <div class="card text-center rainbow" >
                                
                                    <div class="card-body ">
                                        <div class="avatar bg-light-info p-50 mb-1">
                                            <div class="avatar-content">
                                            <alert-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-circle-icon>
                                            </div>
                                        </div>
                                    <h2 class="font-weight-bolder">{{delayedmicrotask}}</h2>
                                    <p class="card-text"> Delayed Micro Task</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-6 mb-1 ">
                                <div class="card text-center rainbow " >
                                
                                    <div class="card-body ">
                                        <div class="avatar bg-light-danger p-50 mb-1">
                                        <div class="avatar-content">
                                        <maximize-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></maximize-2-icon>
                                        </div>
                                    </div>
                                        <h2 class="font-weight-bolder">{{liveissue}}</h2>
                                        <p class="card-text">Total Live Issues</p>
                                    </div>
                                    
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-6 mb-1">
                                    <div class="card text-center rainbow " >
                                    
                                    <div class="card-body ">
                                        <div class="avatar bg-light-secondary p-50 mb-1">
                                        <div class="avatar-content">
                                        <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                        </div>
                                    </div>
                                    
                                    <h2 v-if="this.companycode=='fc'" class="font-weight-bolder">{{totalissuescount}}</h2>
                                    <p v-if="this.companycode=='fc'" class="card-text">Total Open Issues</p>
                                    </div>
                                </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-6 mb-1">
                                        <div class="card text-center rainbow " >
                                    
                                            <div class="card-body ">
                                                <div class="avatar bg-light-info p-50 mb-1">
                                                    <div class="avatar-content">
                                                    <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                                    </div>
                                                </div>
                                                <h2 class="font-weight-bolder">{{totalticket}}</h2>
                                                <p class="card-text">Total Open Ticket</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-6 mb-1">
                                        <div class="card text-center rainbow " >
                                    
                                            <div class="card-body ">
                                                    <div class="avatar bg-light-danger p-50 mb-1">
                                                        <div class="avatar-content">
                                                        <alert-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></alert-circle-icon>
                                                        </div>
                                                    </div>
                                                <h2 class="font-weight-bolder">{{totalsecurityissue}}</h2>
                                                <p class="card-text">Total Security Issues</p>
                                            </div>
                                    </div>
                                </div>
                            
                            
                            </div>
                    
                    
                        </div>                   
                    </div>
                </div>
            </div>
            <div class="modal-footer p-0">
                <button type="button" id="close-button" class="btn btn-relief-dark" data-dismiss="modal">ok</button>
            </div>

                      <!-- end -->
                    </div>
    </div>
</div>
            
           
                        
                    </div>
                </div>
            </div>
        </div>
    </section> 
      
</template>

<script>
import axios from 'axios'
import apiURL from "../../constants";
import moment from "moment";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { EyeIcon,InfoIcon,PlusIcon,TrendingUpIcon,BookIcon,AwardIcon,PenToolIcon,CheckCircleIcon,LinkIcon,BookOpenIcon,PocketIcon,CodesandboxIcon,CalendarIcon,AlertCircleIcon,CheckSquareIcon,EditIcon,WatchIcon,UserPlusIcon,Maximize2Icon,AlertTriangleIcon,AlertOctagonIcon } from 'vue-feather-icons'
import CountryFlag from 'vue-country-flag'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueElementLoading from 'vue-element-loading';
import commonMethods from '../../utils/commonMethods';
import Pagination from 'vue-pagination-2'

export default({
    name:'Dashboard',
    components:{
        Loading,TrendingUpIcon,BookIcon,AwardIcon,PenToolIcon,CheckCircleIcon,LinkIcon,InfoIcon,
        Treeselect,BookOpenIcon,PocketIcon,CodesandboxIcon,CalendarIcon,AlertCircleIcon,CheckSquareIcon,
        EditIcon,
        WatchIcon,
        UserPlusIcon,
        DatePicker,
        PlusIcon,
        EyeIcon,
        AlertTriangleIcon,
        AlertOctagonIcon,
        CountryFlag,
        Maximize2Icon,Pagination,VueElementLoading
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            un:'',            
            userDataList:[],
            educount:0,
            expcount:0,
            coursecount:0,
            skillscount:0,
            isLoadingdashboard: false,
            awardscount:0,
            certcount:0,
            employeeid:0,
            loginuseremail: null,
            empcode:0,
            opentask:[],
            redtask:0,
            greentask:0,
            bluetask:0,
            redreopentask:0,
            greenreopentask:0,
            bluereopentask:0,
            approveredtask:0,
            approvegreentask:0,
            approvebluetask:0,
            openissues:[],
            redissues:0,
            greenissues:0,
            blueissues:0,
            redreopenissues:0,
            greenreopenissues:0,
            bluereopenissues:0,
            openincident:[],
            redincident:0,
            greenincident:0,
            blueincident:0,
            projectdetails:[],
            managercount:[],
            totalproject:0,
            totalmodule:0,
            totaltask:0,
            completedtask:0,
            delayedtask:0,
            totalissues:0,
            totalcr:0,
            totaltime:0,
            delaybtc:0,
            delayedmicrotask:0,
            liveissue:0,
            totalissuescount:0,
            totalticket:0,
            totalsecurityissue:0,
            roleid:'',
            extratime:0,
            myopentasklist:[],
            myissuelist:[],
            myincidentlist:[],
            bgcolor:"background-color",
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiURL.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            mydelaytasklist:[],
            i_totalcount: 0,
            i_pageCount: 0,
            i_page:1,
            i_pagelimit:apiURL.LIMIT,
            i_currPageNum: 0,
            i_pageoffset:1,
            i_pagefeatures:[],
            i_showtabledata:true,
            im_totalcount: 0,
            im_pageCount: 0,
            im_page:1,
            im_pagelimit:apiURL.LIMIT,
            im_currPageNum: 0,
            im_pageoffset:1,
            im_pagefeatures:[],
            im_showtabledata:true,
            d_totalcount: 0,
            d_pageCount: 0,
            d_page:1,
            d_pagelimit:apiURL.LIMIT,
            d_currPageNum: 0,
            d_pageoffset:1,
            d_pagefeatures:[],
            d_showtabledata:true,
            isTaskActive:false,
            isIssueActive:false,
            listfullPage:true,
            islistLoading:false,
            collapse:'collapse',
            card_content:'card-content',
            search:{
                assigneefilter:1
            },
            companycode : apiURL.companycode,
            ProjectManager : apiURL.ProjectManager,
            EngineeringManager : apiURL.EngineeringManager,
            adminUser : apiURL.adminUser,
            totaltickets:0,
            redticket:0,
            greenticket:0,
            blueticket:0,
            redreopenticket:0,
            greenreopenticket:0,
            bluereopenticket:0,
            btcListActive : true,
            taskListActive : false,
            issueListActive : false,
            ticketListActive : false,
            incidentListActive : false,
        }  
    } ,
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/dashboard') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.tokendata = window.localStorage.getItem('token');
            this.un=userdata.firstname+' '+userdata.lastname
            this.createdby=userdata.emailaddress
            this.employeeid = userdata.userid
            this.empid = userdata.userid
            this.roleid = userdata.roleid
            this.loginuseremail = userdata.emailaddress

            let assigneefilter = this.search.assigneefilter;
            this.getUserDataDashboard()
            this.getMyProjectDetails(assigneefilter)
            this.getOpenTicketDetails(assigneefilter)
            if(this.companycode =='fc'){
                this.getOpenTaskDetails(assigneefilter)
                this.getOpenIssuesDetails(assigneefilter)
                this.getOpenIncidentDetails(assigneefilter)         
            }
            if(this.pagefeatures.delayedbtc && this.companycode =='fc'){
                this.getMyDelayTaskList(assigneefilter)
            }
            if((this.roleid==492 || this.roleid==477) && this.companycode =='fc'){
                this.getmanagercount()
            }
        }
        if (!localStorage.getItem('reloaded')) {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        if (feather) {
            feather.replace({
                width: 14,
                height: 14
            });
        }
        window.addEventListener('load', function() {
        let popup = document.getElementById('popup');
        popup.style.display = 'block';

        // Add a click event listener to the close button to hide the popup
        let closeButton = document.getElementById('close-button');
        closeButton.addEventListener('click', function() {
            popup.style.display = 'none';
        });
       
    });
    },
    methods:{
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
      },
        onChangeAssigneeType(event) {
            this.search.assigneefilter = event.target.value
            this.getMyProjectDetails(this.search.assigneefilter)
            this.getOpenTicketDetails(this.search.assigneefilter)
            if(this.companycode =='fc'){
                this.getOpenTaskDetails(this.search.assigneefilter)
                this.getOpenIssuesDetails(this.search.assigneefilter)
                this.getOpenIncidentDetails(this.search.assigneefilter)
                this.getMyOpenTaskList(this.search.assigneefilter)
                this.getMyIssueList(this.search.assigneefilter)
                this.getMyincidentList(this.search.assigneefilter)
            }
            if(this.pagefeatures.delayedbtc && this.companycode =='fc'){
                this.getMyDelayTaskList(this.search.assigneefilter)
            }
        },
        getOpenTaskDetails(assigneefilter){
            this.apiURL="api/dashboard/getOpenTaskDetails"
            this.input={
                empid:this.employeeid,
                empcode: this.employeeid,
                useremail:this.createdby,
                assigneefilter:assigneefilter
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                   this.opentask=result.data.data
                   this.redtask = this.opentask[0].count
                   this.greentask = this.opentask[1].count
                   this.bluetask = this.opentask[2].count
                   this.redreopentask = this.opentask[3].count
                   this.greenreopentask = this.opentask[4].count
                   this.bluereopentask = this.opentask[5].count
                   this.approveredtask = this.opentask[6].count
                   this.approvegreentask = this.opentask[7].count
                   this.approvebluetask = this.opentask[8].count
                }else{
                    this.opentask=[]
                    this.redtask =0
                    this.greentask = 0
                    this.bluetask = 0
                    this.redreopentask = 0
                    this.greenreopentask = 0
                    this.bluereopentask = 0
                    this.approveredtask = 0
                    this.approvegreentask = 0
                    this.approvebluetask = 0
                }
            }).catch(e => {
               this.displayError(e)
            });
        },
      
        getOpenIssuesDetails(assigneefilter){
            this.apiURL="api/dashboard/getOpenIssuesDetails"
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                assigneefilter:assigneefilter                
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                   this.openissues=result.data.data
                   this.redissues = this.openissues[0].count
                   this.greenissues = this.openissues[1].count
                   this.blueissues = this.openissues[2].count
                   this.redreopenissues = this.openissues[3].count
                   this.greenreopenissues = this.openissues[4].count
                   this.bluereopenissues = this.openissues[5].count

                }else{
                    this.openissues=[]
                    this.redissues = 0
                    this.greenissues = 0
                    this.blueissues = 0
                    this.redreopenissues = 0
                    this.greenreopenissues = 0
                    this.bluereopenissues = 0
                }
            }).catch(e => {
               this.displayError(e)
            });
        },

        getOpenTicketDetails(assigneefilter){
            this.apiURL="api/dashboard/getOpenTicketDetails"
            let openticket=[]
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                assigneefilter:assigneefilter                
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                   openticket=result.data.data
                   this.redticket = openticket[0].count
                   this.greenticket = openticket[1].count
                   this.blueticket = openticket[2].count
                   this.redreopenticket = openticket[3].count
                   this.greenreopenticket = openticket[4].count
                   this.bluereopenticket = openticket[5].count
                }else{
                    openticket=[]
                    this.redticket = null
                    this.greenticket = null
                    this.blueticket = null
                    this.redreopenticket = null
                    this.greenreopenticket = null
                    this.bluereopenticket = null
                }
            }).catch(e => {
              this.displayError(e)
            });
        },
        getOpenIncidentDetails(assigneefilter){
            this.apiURL="api/dashboard/getOpenIncidentDetails"
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                assigneefilter:assigneefilter
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                   this.openincident=result.data.data
                   this.redincident = this.openincident[0].count
                   this.greenincident = this.openincident[1].count
                   this.blueincident = this.openincident[2].count
                }else{
                    this.openincident=[]
                    this.redincident = 0
                    this.greenincident = 0
                    this.blueincident = 0
                }
            }).catch(e => { 
               this.displayError(e)
            });
        },
        getMyProjectDetails(assigneefilter){
            this.apiURL="api/dashboard/getMyProjectDetails"
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                assigneefilter:assigneefilter
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status){
                   this.projectdetails=result.data.data
                   this.totalproject = this.projectdetails[0].count
                   this.totalmodule = this.projectdetails[1].count
                   this.totaltask = this.projectdetails[2].count
                   this.completedtask = this.projectdetails[3].count
                   this.delayedtask = this.projectdetails[4].count
                   this.totalissues = this.projectdetails[5].count
                   this.totalcr = this.projectdetails[6].count
                   this.totaltickets = this.projectdetails[7].count
                }else{
                    this.projectdetails=[]
                    this.totalproject = 0
                    this.totalmodule = 0
                    this.totaltask = 0
                    this.completedtask = 0
                    this.delayedtask =0
                    this.totalissues = 0
                    this.totalcr = 0
                    this.totaltickets =0
                }
            }).catch(e => {
               this.displayError(e)
            });
        },
        getmanagercount(){
            this.isLoadingdashboard = true
            this.apiURL="api/dashboard/getmanagercount"
            this.input={
                empcode:this.employeeid,
                useremail:this.createdby,
                roleid:this.roleid,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoadingdashboard = false
                if(result.data.status){
                    this.managercount=result.data.data
                    this.delaybtc = this.managercount[0].count
                    this.delayedmicrotask  = this.managercount[1].count
                    this.liveissue = this.managercount[2].count
                    this.totalissuescount = this.managercount[3].count
                    this.totalticket = this.managercount[4].count
                    this.totalsecurityissue = this.managercount[5].count
                   
                }else{
                    this.managercount=[]
                    this.delaybtc = 0
                    this.delayedmicrotask = 0
                    this.liveissue =0
                    this.totalissuescount = 0
                    this.totalticket = 0
                    this.totalsecurityissue = 0
                }
            }).catch(e => {
               this.displayError(e)
            });
        },
        getMyOpenTaskList(state){
            this.isLoading = true
            this.apiURL="api/dashboard/getMyOpenTaskList"
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                limit: apiURL.LIMIT,
                offset: this.pageoffset,
                assigneefilter:this.search.assigneefilter
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.status){
                   this.myopentasklist=result.data.data.rows
                   this.totalcount = result.data.data.count;
                   this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else {
                    this.totalcount = 0
                    this.myopentasklist=[]
                    this.pageCount =0
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        getMyIssueList(node){
            this.isLoading = true
            this.apiURL="api/dashboard/getMyIssueList"
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                limit: apiURL.LIMIT,
                offset: this.i_pageoffset,
                assigneefilter:this.search.assigneefilter,
                moduletype: '',
            }
            if (node == 'ticket') {
                this.input.moduletype = 'ticket';
            } else if (node == 'issue') {
                this.input.moduletype = 'issue';
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.status){
                   this.myissuelist=result.data.data.rows
                   this.i_totalcount = result.data.data.count;
                   this.i_pageCount = Math.ceil(this.i_totalcount / this.i_pagelimit)
                } else {
                    this.i_totalcount = 0
                    this.myissuelist=[]
                    this.i_pageCount =0
                }
            }).catch(e => {
            this.displayError(e)
            })
        },
        getMyincidentList(){
            this.isLoading = true
            this.apiURL="api/dashboard/getMyincidentList"
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                limit: apiURL.LIMIT,
                offset: this.im_pageoffset,
                assigneefilter:this.search.assigneefilter
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.status){
                   this.myincidentlist=result.data.data.rows
                   this.im_totalcount = result.data.data.count;
                   this.im_pageCount = Math.ceil(this.im_totalcount / this.im_pagelimit)
                } else {
                    this.im_totalcount = 0
                    this.myincidentlist=[]
                    this.im_pageCount =0
                }
            }).catch(e => {
          this.displayError(e)
        })
        },
        getMyDelayTaskList(assigneefilter){
            this.isLoading = true
            this.apiURL="api/dashboard/getMyDelayTaskList"
            this.input={
                empid:this.employeeid,
                useremail:this.createdby,
                empcode: this.employeeid,
                limit: apiURL.LIMIT,
                offset: this.d_pageoffset,
                assigneefilter:assigneefilter
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.status){
                   this.mydelaytasklist=result.data.data.rows
                   this.d_totalcount = result.data.data.count;
                   this.d_pageCount = Math.ceil(this.d_totalcount / this.d_pagelimit)
                } else {
                    this.d_totalcount = 0
                    this.mydelaytasklist=[]
                    this.d_pageCount =0
                }
            }).catch(e => {
               this.displayError(e)
            });
        },
        getUserDataDashboard(){
            this.userDataList=[]
            this.apiURL="api/dashboard/getUserDashboard"
            this.input={
                id:0,
                empcode: this.empid,
                useremail: this.createdby,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}

            })
            .then(result => {
                if(result.data.status){
                    this.userDataList = result.data.data;
                    this.educount=this.userDataList[0].count
                    this.expcount=this.userDataList[1].count
                    this.skillscount=this.userDataList[2].count
                    this.coursecount=this.userDataList[3].count
                    this.certcount=this.userDataList[4].count
                    this.awardscount=this.userDataList[5].count
                    
                }
            }).catch(e => {
               this.displayError(e)
            });
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.currPageNum = pageNum - 1;
            this.getMyOpenTaskList(this.search.assigneefilter);
        },
        clickCallback_issuelist: function (pageNum) {
            this.i_pageoffset = pageNum;
            this.i_currPageNum = pageNum - 1;
            this.getMyIssueList('issue');
        },
        clickCallback_incidentlist: function (pageNum) {
            this.im_pageoffset = pageNum;
            this.im_currPageNum = pageNum - 1;
            this.getMyincidentList(this.search.assigneefilter);
        },
        clickCallback_delaytask: function (pageNum) {
            this.d_pageoffset = pageNum;
            this.d_currPageNum = pageNum - 1;
            this.getMyDelayTaskList(this.search.assigneefilter);
        },
        redirecttoTaskDetail:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            let routeData = this.$router.resolve({ 
                path: '/tasks/view?taskid='+rowdata.taskid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
            window.open(routeData.href);
        },
        redirecttoIssueDetail:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            let routeData = this.$router.resolve({ 
                path: '/issues/view?issueid='+rowdata.issueid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
            window.open(routeData.href);
        },
        redirecttoTicketDetail:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            let routeData = this.$router.resolve({ 
                path: '/dynamic_tickets/tcview?ticketid='+rowdata.issueid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
            window.open(routeData.href);
        },
        redirecttoIncidentDetail:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            let routeData = this.$router.resolve({ 
                path: '/incident/view?incidentid='+rowdata.id,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
            window.open(routeData.href);
        },
        redirecttoBusinessTaskDetail:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            let routeData = this.$router.resolve({ 
                path: '/businesstask/view?businessid='+rowdata.businesstaskmasterid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
            window.open(routeData.href);
        },
        redirecttotask:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(this.search.assigneefilter==1){
                rowdata.rdashboard=1
            }else{
                rowdata.rdashboard=null
            }
            
            this.$router.push({ 
                name: 'TaskList',
                path: '/tasks/list',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        redirecttoissue:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(this.search.assigneefilter==1){
                rowdata.rdashboard=1
            }else{
                rowdata.rdashboard=null
            }
            this.$router.push({ 
                name: 'IssueList',
                path: '/issues/list',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        redirecttoticket:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(this.search.assigneefilter==1){
                rowdata.rdashboard=1
            }else{
                rowdata.rdashboard=null
            }
            this.$router.push({ 
                name: 'TCList',
                path: '/dynamic_tickets/tclist',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        redirecttoincident:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(this.search.assigneefilter==1){
                rowdata.rdashboard=1
            }else{
                rowdata.rdashboard=null
            }
            this.$router.push({ 
                name: 'Incident List',
                path: '/incident/list',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
      buttonBtc(){
        this.d_pageoffset = 1
        this.btcListActive = true
        console.log(this.search.assigneefilter)
        this.getMyDelayTaskList(this.search.assigneefilter)
        if (this.btcListActive) {
            this.taskListActive = false
            this.issueListActive = false
            this.ticketListActive = false
            this.incidentListActive = false
        }
      },
      buttonTask() {
        this.pageoffset = 1
        this.taskListActive = true
        this.getMyOpenTaskList(this.taskListActive)
        if (this.taskListActive) {
            this.btcListActive = false
            this.issueListActive = false
            this.ticketListActive = false
            this.incidentListActive = false
        }
      },
      buttonIssue() {
        this.i_pageoffset = 1;
        this.issueListActive = true
        this.getMyIssueList('issue')
        if (this.issueListActive) {
            this.btcListActive = false
            this.taskListActive = false
            this.ticketListActive = false
            this.incidentListActive = false
        }
      },
      buttonTicket() {
        this.i_pageoffset = 1;
        this.ticketListActive = true
        this.getMyIssueList('ticket')
        if (this.ticketListActive) {
            this.btcListActive = false
            this.taskListActive = false
            this.issueListActive = false
            this.incidentListActive =false
        }
      },
      buttonIncident() {
        this.im_pageoffset = 1;
        this.incidentListActive = true
        this.getMyincidentList()

        if (this.incidentListActive) {
            this.btcListActive = false
            this.taskListActive = false
            this.issueListActive = false
            this.ticketListActive = false
        }
      }
    }
})
</script>
<style scoped>
.alignment{
    margin-left: 20px;
    margin-bottom: 20px;
}
.cardcenter{
    text-align: center;
    color: white;
}
.text-editar_discription_jobs .table-responsive table tr th{
    padding: 0.52rem 1.1rem;
}
.login_flashscreen_popup #popup {
display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
            z-index: 9999;
            text-align: center;
        }
        .login_flashscreen_popup #close-button {
            /* position: absolute; */
            top: 13px;
            right: 22px;
            cursor: pointer;
            border-radius: 0.358rem;
            opacity: unset;
            padding: 5px;
        }
        .login_flashscreen_popup #close-button span{
            color: #fcfcfc;
            background: #050505;
            padding: 5px;
            display: inline-block;
            width: 28px;
            height: 26px;
            line-height: 13px;
            border-radius: 5px;
        }
</style>
