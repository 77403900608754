<template>
  <div id="app">
    <router-view/>
    <button class="btn btn-primary btn-icon scroll-top scroll_view" type="button"><em data-feather="arrow-up"></em></button>
  </div>
  
</template>

<script>

export default {
  name: 'App',
}
  $(document).ready(function(){
    $('.scroll_view').click(function(){
      $('html, body').animate({scrollTop:0}, 'slow');
      return true;
  });
});
</script>


