<template>
    <div class="app-content content ">
        <loading  :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper pb-1">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5 px-0">
                <div class="row breadcrumbs-top">
                    <div class="col-9">
                        <h4 class="content-header-title float-left mb-0">Employee Feedback</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Feedback Form Report</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <monitor-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></monitor-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Emp Fedback Form Dashboard</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body pt-1" v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                    <div class="tab-content" id="dashboardtable">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-6 col-xl-4" v-for="(datavalue, datakey) in trackerdetails">
                                <div class="card shadow-none bg-transparent" v-bind:class="'border-'+getcolours(datakey)">
                                    <div class="card-body">
                                        <h4 v-bind:class="'text-'+getcolours(datakey)" class="card-title " style="cursor:pointer;margin-bottom:5px;" @click="openListing(datavalue)">{{datavalue.moduletype}}</h4>
                                        <p class="card-text">Some quick example text to build on the card title and make up.</p>
                                        <div class="row border text-center mx-0 rounded">
                                        <div style="cursor:pointer" @click="openListing(datavalue, 'total')" class="col-4 border-right quiz_footer_view px-0">
                                            <h6 class="card-text mb-0 " style="margin-top:5px;">Total</h6>
                                            <h5 class="font-weight-bolder mb-0 content_wrp_top_b_10">{{parseInt(datavalue.completedtasks)+parseInt(datavalue.pendingtasks)}}</h5>
                                        </div>
                                        <div style="cursor:pointer" @click="openListing(datavalue, 'completed')" class="col-4 quiz_footer_view border-right px-0">
                                            <h6 class="card-text mb-0" style="margin-top:5px;">Completed</h6>
                                            <h5 class="font-weight-bolder mb-0 content_wrp_top_b_10">{{datavalue.completedtasks}}</h5>
                                        </div>
                                        <div style="cursor:pointer" @click="openListing(datavalue, 'pending')" class="col-4 px-0 quiz_footer_view">
                                            <h6 class="card-text mb-0" style="margin-top:5px;">Pending</h6>
                                            <h5 class="font-weight-bolder mb-0 content_wrp_top_b_10">{{datavalue.pendingtasks}}</h5>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center" v-if="trackerdetails.length==0">
                            No record found
                        </div>
                        <div class="pageCenter text-center">
                        <pagination  v-model="page1" :records="totalcount1" :per-page="pagelimit" @paginate="pageclickCallback"/>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="card border-warning  filter_card_searchbgst">
                    <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapseone">
                        <div class="container-fluid">
                            <div class="row row fc_my-05">
                                <div class="w-auto px-1 mobile-padding-0-2">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon
                                                size="1.5x"
                                                class="custom-class avatar-icon font-medium-3"
                                            ></search-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex ml-1">Emp Feedback Form Report</h4>
                                </div>
                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                    <em class="las la-angle-down"></em>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isSearchRequestActiveone},card_content,collapse]">
                        <div class="card-body pt-1">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <div class="">
                                        <label for="allotedto">Employee Name</label>
                                        <treeselect class="allotedto capitalisetext" placeholder="Enter min 4 chars to search Employee Name/ID" v-model="search.allotedto" :multiple="true" :clear-on-select="true" :options="search.allotedtolist" v-on:search-change="getEmpListByProjectAssigned" @input="selassignedto"/>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                                <label class="form-label lable-left">Feedback Form Name</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Feedback Form Name" :multiple="true" v-model="search.formname" :options="trackerlist" @input='selectTname'/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                                <label class="form-label lable-left">Status</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" :multiple="true" v-model="search.status" :options="statuslist"/>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right mobile-px-0">
                                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getformlist(search,'',1)"> 
                                        <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                                    </button>
                                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                        <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                                    </button>
                                    <button type="move" :disabled="disabledexport" class="btn btn-relief-primary mt-2 " @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-1">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="proModuleList">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="table-responsive">
                                                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="align-middle srno first-col-sticky">Sr#</th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Employee Name</th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Designation</th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Department</th>
                                                                        <th scope="col" class="align-middle second-col-sticky" >Feedback Form Name </th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Reporting Manager</th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Status </th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Created Date </th>
                                                                        <th scope="col" class="align-middle second-col-sticky">Submitted Date </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-for="(data,i) in formlist" >        
                                                                        <tr v-bind:key="i">
                                                                            <td class="srno first-col-sticky">{{ i+1+currPageNum*pagelimit }}</td>
                                                                            <td class="second-col-sticky capitalisetext">
                                                                                {{ data.empname }} - ({{ data.empid }})
                                                                            </td>
                                                                            <td class="capitalisetext">
                                                                                {{ decryptText(data.designation) }}
                                                                            </td>
                                                                            <td class="capitalisetext">
                                                                                {{ decryptText(data.department) }}
                                                                            </td>
                                                                            <td class="capitalisetext">
                                                                                {{ data.moduletype }}
                                                                            </td>
                                                                            <td class="capitalisetext">
                                                                                {{  data.managername }}
                                                                            </td>
                                                                            <td class="capitalisetext">
                                                                                {{  data.status }}
                                                                            </td>
                                                                            <td>
                                                                                {{dateMonthFormat(data.createddate)}}
                                                                            </td>
                                                                            <td>
                                                                                <span v-if="data.status == 'completed'">{{dateMonthFormat(data.lastmodifieddate)}}</span>
                                                                                <span v-else></span>
                                                                            </td>
                                                                            
                                                                        </tr>
                                                                    </template>
                                                                    <template v-if="formlist.length==0">
                                                                        <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pageCenter text-center">
                                        <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { MonitorIcon,PlusCircleIcon,PlusSquareIcon,Edit2Icon,MoreVerticalIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import { SearchIcon } from "vue-feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'FeedbackFormReport',
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,MoreVerticalIcon,EyeIcon,
        SearchIcon,XIcon,ExternalLinkIcon,
        Treeselect,MonitorIcon
    },
    mixins: [ commonMethods ],
    data(){
        return{
            dashboardui: true,
            listingui: false,
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            totalcount1: 0,
            pageCount1: 0,
            page1: 1,
            pageoffset1:1,
            formlist:[],
            isSearchRequestActive:true,
            isSearchRequestActiveone:false,
            search:{
                formname: [],
                status: [],
                allotedtolist:[],
                allotedto : [],
            },
            trackerlist:[],
            statuslist:[{
                id:'completed',
                label:'Completed'
            },{
                id:'pending',
                label:'Pending'
            }],
            loginuseremail: '',
            pagefeatures:[],
            trackerdetails: [],
            colors: ['primary', 'secondary', 'success', 'danger', 'warning', 'info'],
            disabledexport: true,
        }
    },
    mounted(){
        // let permission = this.$Service.verifyAccess();
        // if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.loginuseremail = userdata.emailaddress;
                this.undt=userdata
                localStorage.removeItem('feedbackreportsearchItems')
                //this.getformlist();
                this.gettrackerlist();
                this.getTrackerDetails();
            }
        // }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        selectTname(state){
            if(state.length==0){
                this.disabledexport = true;
            }
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        mysearchrequestollapseone:function(){
            this.isSearchRequestActiveone=!this.isSearchRequestActiveone
        },
        openListing(data, status) {
            this.isSearchRequestActiveone = true;
            this.isSearchRequestActive = false;
            this.search.formname = [data.id]
            if(status) {
                this.search.status = [status]
                if(status=='total') {
                    this.search.status = ['completed', 'pending'] 
                }
            }
            this.getformlist(this.search,'',1)
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getformlist();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#myTabContent").offset().top -70
                });
            }, 500);
        },
        pageclickCallback: function(pageNum){
            this.pageoffset1 = pageNum;
            this.page1 = pageNum;
            this.getTrackerDetails();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#dashboardtable").offset().top -70
                });
            }, 500);
        },
        getEmpListByProjectAssigned(node) {
            if(node != null && node != '' && node.length > 3) {
                axios({
                    'method': 'POST',
                    'url': 'api/listing/getEmpListWithFourChar',
                    'data': {
                        empid : parseInt(this.undt.userid),
                        useremail : this.undt.username,
                        empcode :  this.undt.userid,
                        node : node ? node.toLowerCase() : null,
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    // this.isLoading = false
                    if(result.data.errorCode == 0) {
                      this.search.allotedtolist = result.data.data;
                    } else {
                      this.search.allotedtolist = null;
                    }
                }).catch(e => {
              this.displayError(e)
            })
            }
        },
        selassignedto(state, value) {
            if(!state) {
                this.search.allotedto=null
            } else {
                this.search.allotedto=state
            }
        },
        resetRecords: function() {
            this.search.formname = [];
            this.search.status = [];
            this.search.allotedto = [];
            this.search.allotedtolist = [];
            localStorage.removeItem('feedbackreportsearchItems');
            this.page=1;
            this.formlist = [];
            this.totalcount = 0;
            //this.getformlist();
        },
        getformlist(objThis){
            if(objThis) {
                if(this.search.formname.length == 0) {
                    Swal.fire({
                    title: "",
                    text: "Please select feedback form name, to fetch the results.",
                    icon: "info",
                    customClass: { confirmButton: "btn btn-primary" },
                    buttonsStyling: !1,
                    });
                    this.formlist = [];
                    this.disabledexport = true;
                    return;
                } else {
                    this.disabledexport = false;

                }
                localStorage.setItem('feedbackreportsearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            let search = JSON.parse(localStorage.getItem('feedbackreportsearchItems'))
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                userid: this.search.allotedto,
                limit: apiUrl.LIMIT,
            }
            this.input.offset= 1
            this.currPageNum=0
            if (search) {
                this.input.formname = this.search.formname
                this.input.status = this.search.status
            }else{
                this.input.offset= this.pageoffset
            }
            axios({
                    'method': 'POST',
                    'url': 'api/feedbackform/getfeedbackformreport',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false             
                    if(result.data.errorCode == 0){
                        this.formlist = result.data.data.rows
                        this.totalcount = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.formlist=[]
                        this.totalcount = 0;
                        this.pageCount =0;
                    }
                }).catch(e => {
                this.displayError(e)
                })

        },
        getTrackerDetails() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                limit: apiUrl.LIMIT,
                type: 'orientation',
            }
            this.input.offset = this.pageoffset1;
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getTrackerDetails',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.trackerdetails = result.data.data.rows;
                    this.totalcount1 = result.data.data.count;
                    this.pageCount1 = Math.ceil(this.totalcount1 / this.pagelimit);
                } else {
                    this.trackerdetails = [];
                    this.totalcount1 = 0;
                    this.pageCount1 = 0;
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        gettrackerlist() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'orientation',
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getallforms',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.trackerlist = result.data.data.rows
                } else {
                    this.trackerlist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate == "create"){
                this.$router.push({ 
                name: 'FeedbackForm',
                path: `/form/feedbackform?trackerid=${parseInt(rowdata.trackerid)}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            } else if(btnstate == "view"){
                this.$router.push({ 
                    path: '/form/formview?masterid='+parseInt(rowdata.id),
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        getcolours(idx){
            let newcolor = idx % this.colors.length;
            return this.colors[newcolor];
        },
        ExportXL() {
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                userid: this.search.allotedto,
                limit: null,
            }
            this.input.offset = null
            this.currPageNum = 0
            let search = JSON.parse(localStorage.getItem('feedbackreportsearchItems'))
            if (search) {
                this.input.formname = this.search.formname
                this.input.status = this.search.status
            }else{
                this.input.offset= this.pageoffset
            }
            axios({
                method: "POST",
                url: `api/feedbackform/getfeedbackformreport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                }
            }).catch(e => {
              this.displayError(e)
            });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'FeedBackFormsReport'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#, EMPLOYEE NAME, DESIGNATION, DEPARTMENT, FORM NAME, MANAGER NAME, STATUS, CREATED DATE, SUBMITTED DATE';
                if(arrData[0].fieldname != "") {
                    CSV += `',${arrData[0].fieldname}'`
                }
                CSV += '\r\n';
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ? '' : index.createddate : '';
            let empname = index.hasOwnProperty('empname') ? index.empname == null || index.empname == 'NA' ? '' : index.empname : '';
            let empid = index.hasOwnProperty('empid') ? index.empid == null || index.empid == 'NA' ? '' : index.empid : '';
            // let designation = index.hasOwnProperty('designation') ? index.designation == null || index.designation == 'NA' ? '' : index.designation : '';
            let designation = index.designation ? this.decryptText(index.designation) : '';

            // let department = index.hasOwnProperty('department') ? index.department == null || index.department == 'NA' ? '' : index.department : '';
            let department = index.department ? this.decryptText(index.department) : ''

            let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype == null || index.moduletype == 'NA' ? '' : index.moduletype : '';
            let managername = index.hasOwnProperty('managername') ? index.managername == null || index.managername == 'NA' ? '' : index.managername : '';
            let status = index.hasOwnProperty('status') ? index.status == null || index.status == 'NA' ? '' : index.status : '';
            let lastmodifieddate = 'NA';
            if(status=='completed') {
                lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate == null || index.lastmodifieddate == 'NA' ? '' : this.dateMonthFormat(index.lastmodifieddate) : '';
            }

            row += '"' + srno + '",',
            row += '"' + empname + ' - ('+ empid +')",',
            row += '"' + designation + '",',
            row += '"' + department.toUpperCase() + '",',
            row += '"' + moduletype + '",',
            row += '"' + managername + '",',
            row += '"' + status + '",',
            row += '"' + this.dateMonthFormat(createddate) + '",',            
            row += '"' + lastmodifieddate + '",'            
            if(index.fieldvalue != "") {
                row += index.fieldvalue
            }
            row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            return;
            }
            var fileName = 'FeedBackFormsReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });   
        }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    }
}
</script>