<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header-left col-md-9 col-12 mb-1">
        <div class="row breadcrumbs-top">
            <div class="col-12 px-0">
                <h3 class="content-header-title float-left mb-0">Exam Results</h3>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to='/assessment/testresultlist'> Exam Result List</router-link></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-warning filter_card_searchbgst">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
            <div class="row row fc_my-05">
              <div class="w-auto px-1 mobile-padding-0-2">
                <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                        <search-icon
                        size="1.5x"
                        class="custom-class avatar-icon font-medium-3"
                        ></search-icon>
                    </div>
                </div>
                <h4 class="card-title d-inline-flex ml-1">Search Exam Results</h4>
            </div>
              <span class="rotetions_view_arrow ml-auto mx-1"> 
                <em class="las la-angle-down"></em>
              </span>
            </div>
          </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
            <div class="card-body pt-1">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Course code</label>
                    <input type="text" class="form-control" placeholder="Enter Course code" v-model="search.coursecode" />
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Course Name</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter Min 4 Chars To Search Course Name"
                    v-model="search.coursename"  :multiple="true" :clear-on-select="true" v-on:search-change="courseChange" :options="coursenamelist"/>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Created-By</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search createdby" v-model="search.createdby" :options="search.projectcreatedbyopts" v-on:search-change="createdByChangeSearch"/>
                   </div>
                   <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4" >
                   <label for=""> Created From Date</label>
                    <date-picker placeholder="Select Created Date From" v-model="search.startdatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search.</div> 
                  </div>
                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4">
                    <label for="">Created To Date</label>
                      <date-picker placeholder="Select Created Date To" v-model="search.startdateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                  </div>
                    <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">User name</label>
                    <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4Chars To Search Reporting Manager" :multiple="true" :clear-on-select="true" v-model="search.empcode" :options="search.emplist" v-on:search-change="getEmpList"/>
                    </div> -->
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Result</label>
                    <treeselect  class="capitalisetext" placeholder="Select result"  v-model="search.results" :clear-on-select="true"  :options="staturesultsopts"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0  mobile-px-0">
                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="gettestresultlist(search,'',1)"> 
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                    <button type="move" class="btn btn-relief-primary mt-2" :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
            <div class="row">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div class="">
                                <div class="row">
                                  <div class="col-md-10 ">
                                    <div class="input-group-append">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="table-responsive"
                                v-if="showtabledata"
                              >
                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno align-middle first-col-sticky py-1">Sr#</th>
                                      <th scope="col" class="align-middle second-col-sticky text-left" >Assessment Name</th>
                                      <th scope="col" class="align-middle text-left">Course Code</th>
                                      <th scope="col" class="align-middle text-left">Course Name</th>
                                      <th scope="col" class="align-middle text-left">Periodic Name</th>
                                      <th scope="col" class="align-middle text-left">User Name</th>
                                      <th scope="col" class="align-middle text-left" >Email Id</th>
                                      <th scope="col" class="align-middle">Result</th>
                                      <th scope="col" class="align-middle">Date Of Exams</th>
                                      <th scope="col" class="clsaction align-middle">Test</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template  v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)">
                                        <td class="srno first-col-sticky py-1">{{ index+1+currPageNum*pagelimit }}</td>
                                        <td class="second-col-sticky text-left" style="max-width:200px">
                                          <span class="font-weight-bold capitalisetext">{{data.assessmenttitle}}</span>
                                        </td>
                                        <td class="text-left">
                                          <span>{{data.coursecode}}</span>
                                        </td>
                                        <td class="text-left">
                                          <span>{{data.coursename}}</span>
                                        </td>
                                        <td class="text-left">
                                          <span v-if="data.periodicname">{{data.periodicname}}</span>
                                          <span v-else>NA</span>
                                        </td>
                                        <td class="text-left">
                                          <span class="font-weight-bold capitalisetext">{{data.empname}}</span>
                                        </td>
                                        <td class="text-left">
                                          <span>{{data.emailid}}</span>
                                        </td>
                                        <td class="text-center">
                                            <span v-if="data.results=='pass'" class="capitalisetext  badge badge-pill badge-light-success mr-50"> Pass</span>
                                            <span v-else-if="data.results=='fail'" class="capitalisetext  badge badge-pill badge-light-warning mr-50"> Failed</span>
                                            <span v-else-if="data.results=='not attempted'" class="capitalisetext  badge badge-pill badge-light-danger mr-50"> Not Attempted</span>
                                            <span v-else class="capitalisetext  badge badge-pill badge-light-warning mr-50"> NA</span>
                                        </td>
                                        <td class="text-center">
                                          <span class="font-weight-bold capitalisetext">{{dateMonthFormat(data.examdate ? data.examdate:'NA')}}</span>
                                        </td>
                                        <td class="text-left">
                                            <a v-if="data.results!='not attempted'" class="btn-flat-primary" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                            <div class="d-flex align-items-center"> 
                                            <span>View Test</span>
                                            </div>
                                            </a>
                                            <a v-else>NA</a>
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-1 pageCenter" >
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </div>
</template>  
<style>
.card .card-header {
  padding: 0.8rem;
}
.createbtnparentdiv {
  padding-right: 0%;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}

.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import { EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,
  Link2Icon,PackageIcon,MoreVerticalIcon,Edit2Icon,PlusIcon,PlusCircleIcon,XIcon,ExternalLinkIcon,PlusSquareIcon,CheckIcon } from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import DatePicker from "vue2-datepicker";
import "vue-loading-overlay/dist/vue-loading.css";
import commonMethods from '../../utils/commonMethods';
export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    DatePicker,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    PlusSquareIcon,
    PlusCircleIcon,
    CheckIcon
  },
  mixins: [ commonMethods ],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      TimeFormat: apiUrl.TimeFormat,
      staturesultsopts: [{
            id:'1',
            label:'fail'
        },{
            id:'0',
            label:'pass'
        },
      ],
      search:{
        empname: null,
        results: null,
        emplist: [],
        resultlist: [],
        empcode: null,
        coursecode: null,
        coursename: null,
        createdby: null,
        projectcreatedbyopts: null,
        startdatefrom:null,
        startdateto:null,
      },
      isAddState: false,
      coursenamelist:null,
      maxLength:15,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      showtabledata: true,
      showproject: false,
      parentmoduledata: null,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      tokendata: null,
      isresponce:false,
      isBothDateSelected:false,
      holdEmpcode: null
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      this.tokendata = window.localStorage.getItem('token');
      var userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
      }
      let rowdata={}
      if(this.$route.params && this.$route.params.search){
        let search = JSON.parse(localStorage.getItem('eresultsearchItems'));
        if(search){
          this.getSearchItems(search);
        }else{
          localStorage.removeItem('eresultsearchItems');
          this.gettestresultlist(rowdata);
        }
      }else{
          localStorage.removeItem('eresultsearchItems');
          this.gettestresultlist(rowdata);
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getSearchItems(search) {
      this.search.empcode = search.empcode;
      this.search.results = search.results;
      this.isSearchRequestActive = true;
      if(this.search.empcode){
        this.getEmpListbyid(this.search.empcode);
      }
      this.gettestresultlist(this.search);
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    redirecttourl:function(btnstate,rowdata){
        rowdata.backbtnstate=btnstate
        if(btnstate=="view"){
          let routeData = this.$router.resolve({ 
            path: '/assessment/testresultview?id='+rowdata.id,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata,
            }
          });
          window.open(routeData.href, '_blank');
        }
      },
      getEmpList(node) {
        if(node && node.length > 3){
          this.pageoffset=1
            axios({
              'method': 'POST',
              'url': 'api/listing/getEmpListByProjectAssigned',
              'data': {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                empname: node.toLowerCase()
              },
              'headers':{'authorization':this.tokendata}
            }).then(result => {
            this.isLoading = false;
            if(result.data.errorCode == 0) {
              this.search.emplist = result.data.data;
              let decryptdepartmenttext = this.search.emplist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            } else {
              this.search.emplist = null
            }
          }).catch(e => {
            this.displayError(e)
          })
        }
      },
      getEmpListbyid(assignedto) {
        if(assignedto && assignedto.length > 0){
          this.pageoffset=1
            axios({
              'method': 'POST',
              'url': 'api/listing/getEmpListByProjectAssigned',
              'data': {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                assignedto: assignedto
              },
              'headers':{'authorization':this.tokendata}
            }).then(result => {
            this.isLoading = false;
            if(result.data.errorCode == 0) {
              this.search.emplist = result.data.data;
              let decryptdepartmenttext = this.search.emplist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            } else {
              this.search.emplist = null
            }
          }).catch(e => {
            this.displayError(e)
          })
        }
      },
    getUserResultList(node) {
      if(node && node.length > 1) {
          axios({
              'method': 'POST',
              'url': 'api/assessment/getUserResultList',
              'data': {
                  results: node.toUpperCase(),
                  useremail: this.undt.username,
                  empcode: this.undt.userid
              },
              'headers':{'authorization':this.tokendata}
          }).then(result => {
              this.isLoading = false
              if(result.data.errorCode == 0) {
                  this.search.resultlist = result.data.data
                  localStorage.setItem('resultlist', JSON.stringify(result.data.data))
              } else if(result.data.errorCode == 3){
                Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
                })
              } else {
                  this.search.resultlist = null
                  localStorage.removeItem('testcasecodelist')
              }
          }).catch(e => {
          this.displayError(e)
        })
      }
    },
    gettestresultlist(objThis) {
      if(objThis) {
          localStorage.setItem('eresultsearchItems', JSON.stringify(objThis))
      }
      this.isLoading = true;
      let setmoduleform = "tr_empassessmentresult";
      this.input = {
        tbl: 'tr_empassessmentresult',
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
      };
      let search = JSON.parse(localStorage.getItem('eresultsearchItems'))
        if(search) {
           this.input.coursecode= this.search.coursecode
           this.input.coursename = this.search.coursename
           this.input.createdby  = this.search.createdby 
           this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
          let assignedate = ''
            if(this.search.startdateto){
              assignedate = new Date(this.search.startdateto)
              assignedate.setDate(assignedate.getDate() + 1)
              assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
            }
          this.input.dateto = this.search.startdateto?assignedate:null
          if(this.search.empcode)
          {
            this.input.empcodes=this.search.empcode
          }
          if(this.search.results){
            if(this.search.results == 0){
              this.input.results = 'pass'
            }else{
              this.input.results = 'fail'
            }
          }
        }
      this.input.empcode= this.undt.userid
      let url = "api/managequestions/getTestresultListForResultPage";
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    courseChange: function (node){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/coursemaster/getallcoursenamelist";
          this.input = {
          coursename:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.coursenamelist = result.data.data;
              this.coursenamelist.sort((a, b) => {
                 return a.label.localeCompare(b.label);
              });
            } else {
              this.coursenamelist = [];
            }
          });
        } 
    },
    createdByChangeSearch: function (node){
    if(node && node.length>1){
      this.isLoading = true;
      let url = "api/listing/getAllEmpEmailIds";
      this.input = {
        emailid:node.toLowerCase(),
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.search.projectcreatedbyopts = result.data.data;
        } else {
          this.search.projectcreatedbyopts = [];
        }
      }).catch(e => {
      this.displayError(e)
    });
    }
     },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      this.gettestresultlist(this.parentmoduledata);
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#myTabContent").offset().top -70
        });
      }, 500);
    },
    resetRecords: function() {
      this.search.empcode=null;
      this.search.results=null;
      this.isBothDateSelected = false;
      this.search.coursecode = null;
      this.search.coursename = null;
      this.search.createdby = null;
      this.search.startdatefrom = null;
      this.search.startdateto = null;
      this.isBothDateSelected = false
      this.search.emplist = [];
      localStorage.removeItem('eresultsearchItems');
      this.gettestresultlist();
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    selectedfromdate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      let selectedFromDate = new Date(this.search.startdatefrom);
      selectedFromDate.setDate(selectedFromDate.getDate()-1)
      return date < selectedFromDate;
    },
    selectedtodate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    notBeforeToday: function (date) {
      this.search.startdateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY');
    },
    ExportXL() {
    this.isLoading = true;
    this.exportxlsdata = [];
    this.exporterr = "";
    this.input = {
      limit: null,
      offset: null,
      tbl: 'tr_empassessmentresult',
      useremail:this.undt.username,
      empcode: this.undt.userid
    };  
    let search = JSON.parse(localStorage.getItem('eresultsearchItems'))
    if(search) {
      this.input.coursecode= this.search.coursecode
           this.input.coursename = this.search.coursename
           this.input.createdby  = this.search.createdby 
      this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
          let assignedate = ''
            if(this.search.startdateto){
              assignedate = new Date(this.search.startdateto)
              assignedate.setDate(assignedate.getDate() + 1)
              assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
            }
          this.input.dateto = this.search.startdateto?assignedate:null
      if(this.search.empcode){
        this.input.empcodes=this.search.empcode
      }
      if(this.search.results){
        if(this.search.results == 0){
          this.input.results = 'pass'
        }else{
          this.input.results = 'fail'
        }
      }
    }
    axios({
      method: "POST",
      url: `api/managequestions/getTestresultListForResultPage`,
      data: this.input,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
        this.exportxlsdata = result.data.data.rows;
        var filename = "";
        this.download(result.data.data.rows,filename)
      } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
        } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
          confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      }
    }).catch(e => {
      this.displayError(e)
    });
  },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'AssessmentTestDetails'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Assessment Name,User Name,Email Id,Result,Date of Exam,Start Time, End Time' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let assessmenttitle = index.hasOwnProperty('assessmenttitle') ? index.assessmenttitle == null || index.assessmenttitle.toLowerCase() == 'na' ? '' : index.assessmenttitle : '';
          let empname = index.hasOwnProperty('empname') ? index.empname ==null || index.empname.toLowerCase() =='na' ? '' : index.empname : '';
          let emailid = index.hasOwnProperty('emailid') ? index.emailid ==null || index.emailid.toLowerCase() =='na' ? '' : index.emailid : '';
          let results = index.hasOwnProperty('results') ? index.results ==null || index.results.toLowerCase() =='na' ? '' : index.results : '';
          let examdate = index.hasOwnProperty('examdate') ? index.examdate ==null || index.examdate.toLowerCase() =='na' ? '' : index.examdate : '';
          examdate = this.dateMonthFormat(examdate);
          let starttime = index.hasOwnProperty('starttime') ? index.starttime ==null || index.starttime.toLowerCase() =='na' ? '' : index.starttime : 'NA';
          let endtime = index.hasOwnProperty('endtime') ? index.endtime ==null || index.endtime.toLowerCase() =='na' ? '' : index.endtime : 'NA';
            row += '"' + srno + '",',
            row += '"' + assessmenttitle + '",',
            row += '"' + empname + '",',
            row += '"' + emailid + '",',
            row += '"' + results + '",',
            row += '"' + examdate + '",',
            row += '"' + starttime + '",',
            row += '"' + endtime + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'AssessmentTest Details_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
          confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });     
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    }
  },
};
</script>
