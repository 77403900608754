<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-2">
              Incident Management Details
            </h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls">
                  <router-link :to="{ name: 'Incident List', params: { search: true }}"
                    >Incident Management LIst</router-link
                  >
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">
                  View Incident
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header py-2 indent_border_bottom" style="cursor:pointer">
            <div class="container-fluid">
              <div class="row mobile-px-0">
                  <div class="w-auto px-1 mobile-padding-0-2 ">
                    <div class="avatar bg-light-primary rounded d-inline-flex ">
                          <div class="avatar-content">
                              <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                          </div>
                      </div>
                      <h3 class="card-title ml-2 d-inline-flex text-primary">Incident Code: {{this.incidentcode}}</h3>
                  </div>
                  <div class="ml-auto px-1 mobile-padding-0-2 text-right">
                  <div v-if="loginusername == this.view.createdby " class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="openEditModule(backpagedata,0)">
                    <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                    <span>Edit</span>
                  </div>
                  <div class="btn btn-sm btn-outline-primary waves-effect waves-effect"  @click="openLogModule()">
                      <watch-icon size="1.5x" class="custom-class avatar-icon mr-50"></watch-icon>
                      <span>Log Time</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row match-height">
              <div class="col-12 mb-sm-1">
                  <div class=" card-transaction">
                      <div class="card-body mobile-padding-0-10">
                          <div class="transaction-item">
                          <div class="media">
                              <div class="avatar bg-light-success rounded">
                                  <div class="avatar-content">
                                      <pen-tool-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pen-tool-icon>
                                  </div>
                              </div>
                              <div class="media-body">
                                  <h6 class="transaction-title">{{this.name}}</h6>
                                  <small>Created by {{this.view.createdby}}</small>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </div>
            <div class="col-lg-4 col-md-6 col-12 ">
                  <div class="card card-transaction">
                      <div class="card-body mobile-padding-0-10 pt-md-0">
                        <div class="transaction-item">
                            <div class="media">
                                <div v-if="this.priority=='High'" class="avatar bg-light-danger rounded">
                                    <div class="avatar-content">
                                      <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>                                    </div>
                                </div>
                                <div v-if="this.priority=='Low'" class="avatar bg-light-info rounded">
                                    <div class="avatar-content">
                                        <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                                    </div>
                                </div>
                                <div v-if="this.priority=='Medium'" class="avatar bg-light-warning rounded">
                                    <div class="avatar-content">
                                        <pen-tool-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pen-tool-icon>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Incident Priority</h6>
                                    <small v-if="this.priority=='High'" class="text-danger" style="font-weight:500">{{this.priority}}</small>
                                    <small v-if="this.priority=='Low'" class="text-info" style="font-weight:500">{{this.priority}}</small>
                                    <small v-if="this.priority=='Medium'" class="text-warning" style="font-weight:500">{{this.priority}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="transaction-item">
                            <div class="media">
                                <div v-if="this.severity=='Critical'" class="avatar bg-light-danger rounded">
                                <div class="avatar-content">
                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                </div>
                                </div>
                                <div v-if="this.severity=='Major'" class="avatar bg-light-warning rounded">
                                <div class="avatar-content">
                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                </div>
                                </div>
                                <div v-if="this.severity=='Minor'" class="avatar bg-light-primary rounded">
                                <div class="avatar-content">
                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                </div>
                                </div>
                                <div v-if="this.severity=='Cosmetic'" class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                </div>
                                </div>
                                <div v-if="this.severity=='Moderate'" class="avatar bg-light-info rounded">
                                <div class="avatar-content">
                                    <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                                </div>
                                </div>
                                <div class="media-body">
                                <h6 class="transaction-title">Incident Severity</h6>
                                <small v-if="this.severity=='Critical'" class="text-danger" style="font-weight:500">{{this.severity}}</small>
                                <small v-if="this.severity=='Major'" class="text-warning" style="font-weight:500">{{this.severity}}</small>
                                <small v-if="this.severity=='Minor'" class="text-primary" style="font-weight:500">{{this.severity}}</small>
                                <small v-if="this.severity=='Cosmetic'" class="text-success" style="font-weight:500">{{this.severity}}</small>
                                <small v-if="this.severity=='Moderate'" class="text-info" style="font-weight:500">{{this.severity}}</small>
                                </div>
                            </div>
                        </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-danger rounded">
                                      <div class="avatar-content">
                                          <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Incident Status</h6>
                                      <small class="text-danger capitalisetext" style="font-weight:500">{{this.status}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-warning rounded">
                                      <div class="avatar-content">
                                          <settings-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></settings-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Incident Type</h6>
                                      <small class="text-danger capitalisetext" style="font-weight:500">{{this.incidenttype}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-danger rounded">
                                      <div class="avatar-content">
                                          <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Total Downtime</h6>
                                      <small>{{this.totaldowntime}}</small>
                                  </div>
                              </div>
                          </div> 
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-warning rounded">
                                      <div class="avatar-content">
                                          <pen-tool-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pen-tool-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Incident Reason</h6>
                                      <small>{{this.reason}}</small>
                                  </div>
                              </div>
                          </div>                         
                      </div>                                                          
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12 ">
                  <div class="card card-transaction">
                      <div class="card-body mobile-padding-0-10 pt-md-0">
                        <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-success rounded">
                                      <div class="avatar-content">
                                          <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Task Name</h6>
                                      <small>{{this.view.taskname ? this.view.taskname: 'NA'}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-info rounded">
                                      <div class="avatar-content">
                                          <codesandbox-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></codesandbox-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Functionlity</h6>
                                      <small>{{this.view.functionlityname ? this.view.functionlityname: 'NA'}}</small>
                                  </div>
                              </div>
                          </div>
                           <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-primary rounded">
                                      <div class="avatar-content">
                                          <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Project Name</h6>
                                      <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttoPNM('view', {moduleid:view.projectid})">{{this.view.projectname}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-primary rounded">
                                      <div class="avatar-content">
                                          <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Project Module Name</h6>
                                      <small class="text">{{this.view.projectmodulename ? this.view.projectmodulename: 'NA'}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-warning rounded">
                                      <div class="avatar-content">
                                          <pocket-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pocket-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Incident Impact</h6>
                                      <small>{{this.impact}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item" v-if="this.correctaction">
                              <div class="media">
                                  <div class="avatar bg-light-warning rounded">
                                      <div class="avatar-content">
                                          <pocket-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></pocket-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Corrective Action</h6>
                                      <small class="capitalisetext">{{this.correctaction}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-success rounded">
                                      <div class="avatar-content">
                                          <codesandbox-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codesandbox-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Incident Losses</h6>
                                      <small>{{this.losses}}</small>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12 ">
                  <div class="card card-transaction">
                      <div class="card-body mobile-padding-0-10 pt-md-0">
                        <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-info rounded">
                                      <div class="avatar-content">
                                          <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Assigned to</h6>
                                      <small class="capitalisetext">{{this.view.empname}}</small>
                                  </div>
                              </div>
                        </div>
                        <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-success rounded">
                                      <div class="avatar-content">
                                          <calendar-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></calendar-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Incident Date</h6>
                                      <small>{{dateFormat2(this.datetime)}}</small>
                                  </div>
                              </div>
                        </div>
                        <div class="transaction-item">
                            <div class="media">
                              <div class="avatar bg-light-warning rounded">
                                <div class="avatar-content">
                                  <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                </div>
                              </div>
                            <div class="media-body">
                            <h6 class="transaction-title">Created By</h6>
                              <small>{{this.view.createdby}}</small>
                            </div>
                          </div>
                         </div>
                         <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-success rounded">
                                      <div class="avatar-content">
                                          <calendar-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></calendar-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Created Date</h6>
                                      <small>{{dateMonthFormat(this.createddate)}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Last Modified By</h6>
                              <small>{{this.view.lastmodifiedby}}</small>
                            </div>
                          </div>
                        </div>
                        <div class="transaction-item">
                          <div class="media">
                            <div class="avatar bg-light-warning rounded">
                              <div class="avatar-content">
                                <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                              </div>
                            </div>
                            <div class="media-body">
                              <h6 class="transaction-title">Last Modified Date</h6>
                              <small>{{dateMonthFormat(this.view.lastmodifieddate)}}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="col-12">
                <div class="card-transaction">
                    <div class="card-body mobile-padding-0-10">
                        <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-primary rounded">
                                      <div class="avatar-content">
                                          <package-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></package-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Short Term Solution	</h6>
                                      <small>{{this.shorttermsolution ? this.shorttermsolution: 'NA'}}</small>
                                  </div>
                              </div>
                          </div>
                          <div class="transaction-item">
                              <div class="media">
                                  <div class="avatar bg-light-warning rounded">
                                      <div class="avatar-content">
                                          <package-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></package-icon>
                                      </div>
                                  </div>
                                  <div class="media-body">
                                      <h6 class="transaction-title">Long Term Solution</h6>
                                      <small>{{this.longtermsolution ? this.longtermsolution: 'NA'}}</small>
                                  </div>
                              </div>
                          </div>
                    </div>
                </div>
            </div>
            <div class="media-list media-bordered">
                <div class="card-transaction">
                    <div class="card-body mobile-padding-0-10 mobile_margin_left-0" >
                        <div class="transaction-item" style="overflow:auto">
                            <div class="media">
                                <div class="avatar bg-light-primary rounded">
                                    <div class="avatar-content">
                                        <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                    </div>
                                </div>
                                <div class="media-body">
                                    <h6 class="transaction-title">Incident Description</h6>
                                    <!-- <span class="text-wrap_incident_descri" v-html="formatData(this.description)"></span> -->
                                    <div class="text-left tast_case_groupingview_readmore mx-0">
                                        <div class="col-md-12 px-0  micro_case_groupingview_readmore mb-1">
                                            <div class="custom-container case_bg_17">
                                                <div v-if="this.description" class="outer" :id="'cycle_readmore6'+1">
                                                    <span class="fc_m16_btcode_text" v-html="formatDataComment(this.description)"></span>
                                                    
                                                </div>
                                                
                                                <div class="cycle_viewmoretext" v-if="this.description && this.description.length > 150">
                                                    <a class="btn">
                                                    <strong :id="'id6'+1" @click="idclick6(1)">
                                            Read More
                                        </strong>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
               <div v-if="view.history && view.history.length " class="col-lg-12 col-md-6 col-12" style="display:none;">
                  <div class="card-body">
                    <h5 class="mb-1">History</h5>
                    <div class="row tbl">
                      <div class="table-responsive mb-1">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                          <thead>
                            <tr>
                              <th scope="col">SR#</th>
                              <th scope="col">Status</th>
                              <th scope="col">Assigned To</th>
                              <th scope="col" >Action Date</th>
                              <th scope="col">  Assigned BY</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data,index) in (view.history)" v-bind:key="index">
                              <td>{{index+1}}</td>
                              <td>{{data.status}}</td>
                              <td>{{data.assignedto}}</td>
                              <td>{{dateMonthFormat(data.lastmodifieddate)}}</td>
                              <td>{{data.lastmodifiedby.split("@")[0]}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div v-if="attachmentview">
                    <div class="card">
                        <div class="card-header px-2 py-1 border-bottom">
                            <h4 class="card-title">Document List</h4>
                        </div>
                        <div class="card-body pt-1 pb-0">
                            <div class="row">
                        <div class="col-md-12">
                            <div class="row documentGrid match-height">
                                <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(data,index) in (this.attachmentview ? this.attachmentview.data:[])" v-bind:key="index">
                                    <div class="card card-bordered mb-50">
                                        <div class="text-center py-2" style="background:#F8F8F8;">
                                     <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                     
                                    <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                    
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                            <a :href="data.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                            <a v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(data.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon></a>
                            </div>
                            <div class="py-50 px-50 border-top" style="font-size:0.85rem; color:#888;">
                              <span v-if="data.info">
                                {{data.info}}
                              </span>
                              <span v-else-if="!data.info">
                                {{data.link | basename}}
                              </span>
                            </div>
                             </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                  </div>
                  <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">              
                        <div class="modal-body p-0">
                          <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                          <img alt="" src="" class="imagepreview"  >
                        </div>
                      </div>
                    </div>
                  </div>
        <div class="customTimeline" v-for="(data,index) in logtimehistory" v-bind:key="index">
          <div class="card" v-bind:class="{'reopen':data.status=='reopen', 'closed':data.status=='closed', 'open':data.status=='open', 'resolved':data.status=='resolved'}">
            <div class="card-body">
              <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                <p class="text-muted" style="margin-top:-3px">Updated on: {{dateMonthFormat(data.lastmodifieddate)}} 
                  <span v-bind:class="{'capitalisetext badge badge-pill badge-light-warning mx-50':data.status=='reopen', 'capitalisetext badge badge-pill badge-light-secondary mx-50':data.status=='closed', 'capitalisetext badge badge-pill badge-light-danger mx-50':data.status=='open', 'capitalisetext badge badge-pill badge-light-success mx-50':data.status=='resolved'}">{{data.status}}</span>
                  <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.activity}}</span>
                </p>
              </div>

              <p v-if="data.coments"><span v-html="data.coments"></span></p>
                    <div class="row match-height">
                    <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(res,index) in (data.attachment ? data.attachment.data:[])" v-bind:key="index">
                      <div class="card card-bordered mb-0">
                          <div class="text-center py-2" style="background:#F8F8F8;">
                          <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                          
                          <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                    
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                            <a v-on:click="getLogtimeDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                            <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                            </div>
                             <a :href="res.link"  class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">
                              <span v-if="res.info">
                                {{res.info}} -123
                              </span>
                              <span v-else-if="!res.info">
                                {{res.link | basename}}
                              </span>
                              </a>
                             </div>
                        
                      </div>
                      </div>
                      <hr class="mt-0" />

                  <div class="d-flex justify-content-between align-items-center custom_time_line_mobile_vflex">
                    <div class="d-flex custom_time_line_mobile_bottom-10">
                  <div class="media align-items-center">
                    <span class="pr-1 text-muted  d-inline-flex">
                        <user-plus-icon size="1.5x" class="custom-class avatar-icon font-medium-3 mr-50"></user-plus-icon> 
                        Assigned To</span>
                    <div class="avatar">
                      <profilepic v-if="data.assignedtoimagename" v-bind:get-url="'empprofile/'+data.assigntoempcode+'/'+data.assignedtoimagename" :key="`${componentKey + index}`"></profilepic>
                      <img alt="" v-if="!data.assignedtoimagename" :src="search.defaultimagepath" width="36" height="36" />
                    </div>
                    <div class="media-body ml-50">
                      <h6 class="mb-0 text-nowrap">{{data.assigend}}</h6>
                      <span class="text-muted text-nowrap">{{decryptText(data.assignedtodesignatio)}}</span>
                    </div>
                  </div>
                    </div>

                    <div class="d-flex">
                        <div class="media align-items-center">
                          <span class="pr-1 text-muted ">
                              <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-25"></edit-2-icon>
                              Updated By</span>
                            <div class="avatar ">
                              <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                                <img alt="" v-if="!data.imagename" :src="search.defaultimagepath" width="36" height="36" />
                            </div>
                            <div class="media-body ml-50">
                              <h6 class="mb-0 text-nowrap"> {{data.createdby}}</h6>
                              <span class="text-muted text-nowrap">{{decryptText(data.designation)}}</span>
                              </div>
                        </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
          <div>&nbsp;</div>
        <div v-bind:class="[{display:dislogActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor:pointer">
            <div class="container-fluid">
              <div class="row">
                  <div class="col-9">
                    <div class="avatar bg-light-primary rounded d-inline-flex" >
                          <div class="avatar-content">
                              <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                          </div>
                      </div>
                      <h4 class="card-title d-inline-flex text-primary ml-1" >Update Log Time</h4>
                  </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isLogActive},card_content,collapse]" >
            <div class="card-body">
              <form class="form form-vertical">
                <div class="row">
                  <div class="form-group col-md-3">
                      <div class="form-group mg-bot0">
                      <label class="form-label" for="spendtime">Spent Time</label>
                      <div id="spendtime">
                      <vue-timepicker type="time" v-model="logtime.spenttime" format="HH:mm"  @close="validatelog()"></vue-timepicker>
                      </div>
                      <div class="errorinputmsg" v-if="this.logtimeErrors.spenttime">{{ this.logtimeErrors.spenttime }}</div>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                      <label class="form-label">Activity</label>
                      <treeselect placeholder="Select Activity"  class="projectmdllistdd capitalisetext" v-model="logtime.activity" :options="logtime.acivitylist" @input="seltasklogactivity" @close="validatelog()"/>
                      <div class="errorinputmsg" v-if="this.logtimeErrors.activity">{{ this.logtimeErrors.activity }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                        <label class="form-label">Assigned To</label>
                        <treeselect placeholder="Enter Min 4 Chars To Search Assigned To" class="allotedto capitalisetext" v-model="logtime.allotedto" :options="employeeListOpts" @input="selallotedto" @close="validatelog()" v-on:search-change="getAllocatedEMPByProjectModuel"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.logtimeErrors.allotedto">{{ this.logtimeErrors.allotedto }}</div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                        <label class="form-label">Status</label>
                        <input class="form-control capitalisetext" placeholder="Select Status" v-model="logtime.status" :options="search.statusopts" @input="sellogissuecsstatus" @close="validatelog()" v-if="search.statusopts && search.statusopts.length<=1" disabled/>
                        <treeselect placeholder="Select Status" class="projectmdllistdd capitalisetext" v-model="logtime.status" :options="search.statusopts" @input="sellogissuecsstatus" @close="validatelog()" v-else/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                  </div>
                  <div class="form-group col-12">
                    <label class="form-label">Comment</label>
                    <ckeditor :editor="editor"  placeholder="Comment" v-model="logtime.comment" @input="seltasklogcomments" :config="editorConfig" tag-name="textarea" ></ckeditor>
                    <div class="errorinputmsg" v-if="this.logtimeErrors.comment">{{ this.logtimeErrors.comment }}</div>
                  </div>
                  <div class="col-md-6" style="margin-top:24px">
                    <div class="form-group">
                      <div class="form-group">
                        <label for="attachment" class="btn btn-sm btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                        <div class="table-responsive mb-1" v-if="file && file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button class="btn btn-sm btn-relief-secondary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                        <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mg-top1 text-right">
                    <button type="button" class="btn btn-relief-primary mr-1" @click="savelogData()"  v-bind:disabled="disblesavebtn">Submit</button>
                    <button type="reset" class="btn btn-outline-secondary" @click="handleCancel2()">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor: pointer" v-on:click="myrequestcollapse">
            <div class="container-fluid">
            <div class="row">
              <div class="col-9">
                <div class="avatar bg-light-primary rounded d-inline-flex">
                      <div class="avatar-content">
                          <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                      </div>
                  </div>
                  <h4 class="card-title d-inline-flex text-primary ml-1" >Update Incident Details : {{this.name}}</h4>
              </div>
            </div>
          </div>
            
          </div>
          <div v-bind:class="[{ show: isRequestActive }, card_content, collapse]" >
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label class="form-label capitalisetext" for="name"
                      >Incident Name</label
                    >
                    <input
                      id="name"
                      class="form-control"
                      type="text"
                      v-model="name"
                      placeholder="Incident Name"
                      v-bind:class="{
                        'form-control': true,
                        'is-invalid': !validText(name) && nameBlured,
                      }"
                      v-on:blur="nameBlured = true"
                    />
                    <div class="invalid-feedback">
                      Incident name must be length of 5 char at least and only letters are
                      allowed
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="project">Project Name</label>
                    <treeselect
                      class="projectmdllistdd capitalisetext"
                      v-model="projectnames"
                      placeholder="select project"
                      :options="projectlist"
                      @input="selectproject"
                    />
                    <div class="errorinputmsg" v-if="this.errors.projectnames">
                      {{ this.errors.projectnames }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="project">Project Module Name</label>
                    <treeselect
                      class="projectmdllistdd capitalisetext"
                      v-model="project"
                      placeholder="select project module name"
                      :options="projectmodulelist"
                      @input="seltestcsproject"
                    />
                    <div class="errorinputmsg" v-if="this.errors.project">
                      {{ this.errors.project }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="functionality">Functionality</label>
                    <treeselect
                      class="projectmdllistdd capitalisetext"
                      v-model="functionality"
                      :options="functionalitylist"
                      @input="seltestcsfunctionality"
                    />
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mg-bot0">
                      <label for="projectmdllistdd">Task Name</label>
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        v-model="imgm.task"
                        :options="imgm.tasklist"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label capitalisetext" for="datetime"
                      >Incident Date</label
                    >
                    <input
                      type="date"
                      id="datetime"
                      class="form-control"
                      v-model="datetime"
                      placeholder="Incident Date and Time"
                      v-on:change="validateDate('incidentdate', $event)"
                    />
                    <div class="errorinputmsg" v-if="this.errors.datetime">{{ this.errors.datetime }}</div>
                  </div>
                  <div class="form-group col-md-6">
                  <div class="form-group mg-bot0">
                      <label class="form-label" for="totaldowntime">Total Downtime</label>
                      <div id="downtime">
                      <vue-timepicker type="time" id="totaldowntime" format="HH:mm"  v-model="totaldowntime" @close="validate"></vue-timepicker>
                      </div>
                  </div>
                  <div class="errorinputmsg" v-if="this.errors.totaldowntime">{{ this.errors.totaldowntime }}</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="impact">Incident Impact</label>
                    <input
                      id="impact"
                      class="form-control capitalisetext"
                      type="text"
                      v-model="impact"
                      placeholder="Incident impact"
                      v-bind:class="{
                        'form-control': true,
                        'is-invalid': !validText(impact) && impactBlured,
                      }"
                      v-on:blur="impactBlured = true"
                    />
                    <div class="invalid-feedback">
                      Incident impact must be length of 5 char at least and only letters are
                      allowed.
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="losses">Incident Losses</label>
                    <input
                      id="losses"
                      class="form-control capitalisetext"
                      type="text"
                      v-model="losses"
                      placeholder="Incident losses"
                      v-bind:class="{
                        'form-control': true,
                        'is-invalid': !validText(losses) && lossesBlured,
                      }"
                      v-on:blur="lossesBlured = true"
                    />
                    <div class="invalid-feedback">
                      Incident loss must be length of 5 char at least and only letters are
                      allowed
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="incidenttype">Incident Type</label>
                    <div class="input-group input-group-merge">
                      <treeselect class="projroleassprojectdd capitalisetext" placeholder="Select Incident Type"  :options="search.incTypesopts" v-model="incidenttype" @input="selincident"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.incidenttype">{{ this.errors.incidenttype }}</div>
                  </div>
                  <div class="form-group col-md-6"  v-if="(incidenttype && incidenttype.toLowerCase() == 'policy violation incident') || (incidenttype && incidenttype.toLowerCase() == 'work bench')">
                    <label class="form-label" for="reason">Corrective Action</label>
                    <treeselect  class="capitalisetext" for="status" v-model="correctaction" placeholder="Select Corrective Action" :options="search.correctiveaction" @input="secaction" v-if="incidenttype && incidenttype.toLowerCase() == 'policy violation incident'"/>

                    <treeselect  class="capitalisetext" for="status" v-model="correctaction" placeholder="Select Corrective Action" :options="search.correctiveaction2" @input="secaction" v-else-if="incidenttype && incidenttype.toLowerCase() == 'work bench'"/>
                    <div class="errorinputmsg" v-if="this.errors.correctaction">
                      {{ this.errors.correctaction }}
                    </div>
                  </div>
                  <div class="form-group col-12">
                    <label class="form-label" for="description"
                      >Incident Description</label
                    >
                    <ckeditor
                      :editor="editor"
                      placeholder="Incident Description"
                      v-model="description"
                      :config="editorConfig"
                      tag-name="textarea"
                      v-bind:class="{ 
                        'form-control': true,
                        'is-invalid': !validText(description) && descriptionBlured, }"
                      v-on:blur="descriptionBlured = true"
                    >
                    </ckeditor>
                    <div class="invalid-feedback">Incident description Required</div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="reason">Incident Reason</label>
                    <input
                      id="reason"
                      class="form-control"
                      type="text"
                      v-model="reason"
                      placeholder="Incident Reason"
                      v-bind:class="{
                        'form-control': true,
                        'is-invalid': !validText(reason) && reasonBlured,
                      }"
                      v-on:blur="reasonBlured = true"
                    />
                    <div class="invalid-feedback">
                      Incident reason must be length of 5 char at least and only letters are
                      allowed
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="createddate">Incident Status</label>
                    <input class="form-control capitalisetext" placeholder="Select Incident Status" v-model="status" :options="search.statusopts" @input="selissuecsstatus" v-if="search.statusopts && search.statusopts.length<=1" disabled/>
                    <treeselect class="capitalisetext" for="status" v-model="status" :options="search.statusopts" @input="selissuecsstatus" v-else/>
                    <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                  </div> 
                  <div class="form-group col-md-6">
                    <label class="form-label" for="shorttermsolution"
                      >Short term solution</label
                    >
                    <textarea
                      id="shorttermsolution"
                      class="form-control"
                      rows="3"
                      v-model="shorttermsolution"
                      placeholder="Incident short term solution"
                    ></textarea>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="longtermsolution"
                      >Long term solution</label
                    >
                    <textarea
                      id="longtermsolution"
                      class="form-control"
                      rows="3"
                      v-model="longtermsolution"
                      placeholder="Incident long term solution"
                    ></textarea>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="severity">Incident Severity</label>
                   <treeselect class="projectmdllistdd capitalisetext" v-model="severity" :options="search.severityopts" @input="selectseverity"/>
                   <div class="errorinputmsg" v-if="this.errors.severity">
                      {{ this.errors.severity }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="priority">Incident Priority</label>
                    <treeselect class="projectmdllistdd capitalisetext" v-model="priority" :options="search.priorityopts" @input="selectpriority"/>
                    <div class="errorinputmsg" v-if="this.errors.priority">
                      {{ this.errors.priority }}
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label" for="allocatedto"
                      >Assigned to</label
                    >
                    <treeselect
                      class="capitalisetext"
                      placeholder="Enter Min 4 Chars To Search Assigned To"
                      for="allocatedto"
                      v-model="allocatedto"
                      :options="employeeListOpts"
                      @input="selectallotedto"
                      v-on:search-change="getAllocatedEMPByProjectModuel"
                    />
                    <div class="errorinputmsg" v-if="this.errors.allocatedto">
                      {{ this.errors.allocatedto }}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Subscribers</label>
                      <div class="input-group input-group-merge">
                        <treeselect class="projroleassprojectdd capitalisetext" placeholder="Enter Min 4 Chars To Search Subscribers" :multiple="true" :clear-on-select="true" :options="subscriberslist" v-model="subscribers" @input="selSubscribers"  v-on:search-change="getAllocatedEMPByProjectModuel"/>
                      </div>
                    <div class="errorinputmsg" v-if="this.errors.subscribers">{{ this.errors.subscribers }}</div>
                    </div>
                  </div>
                                   
                  <div class="form-group col-md-12">
                    <label for="lblattachment">Attachment</label>
                    <div class="form-group">
                       <label
                        for="attachment"
                        class="btn btn-sm btn-relief-secondary mb-75 mr-75"
                        >Select image/docs/videos</label
                      >
                      <button
                        class="btn btn-sm btn-relief-secondary mb-75 mr-75"
                        v-on:click.stop.prevent="onUpload()"
                      >
                        Upload
                      </button>
                      <input
                        type="file"
                        id="attachment"
                        hidden
                        multiple
                        :accept="this.accepttype"
                        ref="file"
                        @change="onFileChange"
                      />
                      <div class="table-responsive" v-if="attachmentview">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                          <thead>
                            <tr>
                              <th scope="col">SR#</th>
                              <th scope="col">Document Name</th>
                              <th scope="col">Description</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, k) in (attachmentview ? attachmentview.data: [])"
                              :key="k"
                            >
                              <td>{{ k + 1 }}</td>
                              <td>
                                 <label>
                                    <div class="click-zoom">
                                      <input type="checkbox" />
                                <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                            &nbsp;
                                <a :href="data.presignurl">{{
                                  data.link | basename
                                }}</a>
                                </div>
                                </label>
                              </td>
                              <td>{{ data.info }}</td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-sm danger"
                                  @click="removes3file(k, attachmentview.data)"
                                >
                                  <trash-2-icon
                                    size="1.5x"
                                    class="custom-class"
                                  ></trash-2-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.attachment">
                        {{ this.errors.attachment }}
                      </div>
                      <div class="table-responsive" v-if="file && file.length > 0">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                          <thead>
                            <tr>
                              <th scope="col">SR#</th>
                              <th scope="col">Document Name</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(image, key) in file" :key="key">
                              <td>{{ key + 1 }}</td>
                              <td>{{ image.name }}</td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Optional description"
                                  v-model="filedescp[key]"
                                />
                                &nbsp;&nbsp;
                                <button
                                  type="button"
                                  class="btn btn-sm danger"
                                  @click="removeImage(key)"
                                >
                                  <trash-2-icon
                                    size="1.5x"
                                    class="custom-class"
                                  ></trash-2-icon>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mg-top1 text-right">
                    <button
                      type="button"
                      class="btn btn-relief-primary mr-1"
                      id="toggleSubmission"
                      data-dismiss="modal"
                      v-on:click.stop.prevent="saveData($event,0)"
                      v-bind:disabled="disblesavebtn"
                    >
                      Submit
                    </button>
                    <button type="button" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";
import moment from "moment";
import {
  PaperclipIcon, DownloadIcon,Trash2Icon,SearchIcon,
  Edit2Icon,
  PlusIcon,
  MinusSquareIcon,
  ClipboardIcon,
  PlusSquareIcon,
  EyeIcon,
  UploadCloudIcon,SettingsIcon,PackageIcon,UserIcon,
  PenToolIcon,PocketIcon,CodesandboxIcon,MonitorIcon,CalendarIcon,WatchIcon,CodepenIcon,UserPlusIcon,ZoomInIcon,XIcon
} from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import apiUrl from "../../constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "vue-pagination-2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import presignurl from '../getPresignurl.vue';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Incident",
  components: {
    VueTimepicker,
    Loading,
    SearchIcon,
    Edit2Icon,
    Treeselect,
    DatePicker,
    Pagination,
    PlusIcon,
    MinusSquareIcon,
    ClipboardIcon,
    PlusSquareIcon,
    ckeditor: CKEditor.component,
    DownloadIcon,
    EyeIcon,PaperclipIcon,
    UploadCloudIcon,SettingsIcon,PackageIcon,UserIcon,Trash2Icon,
    PenToolIcon,PocketIcon,CodesandboxIcon,MonitorIcon,CalendarIcon,WatchIcon,CodepenIcon,
    presignurl,
    profilepic,
    UserPlusIcon,
    ZoomInIcon,XIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      componentKey:0,
      imgExt:['jpeg','jpg','png'],
      search: {
        correctiveaction:apiUrl.correctiveaction,
        correctiveaction2:apiUrl.correctiveaction2,
        incidenttype:apiUrl.incidenttypelist,
        defaultimagepath:apiUrl.defaultimagepath,
        searchIncidentname: null,
        incidentopts: null,
        searchprojectname: null,
        projectnameopts: null,
        searchFunctionality: null,
        functionalityopts: null,
        searchTask: null,
        taskopts: null,
        searchSeverity: null,
        incTypesopts:[],
        severityopts: [
         {
            id: "Cosmetic",
            label: "Cosmetic",
          },
          {
            id: "Critical",
            label: "Critical",
          },
          {
            id: "Major",
            label: "Major",
          },
          {
            id: "Minor",
            label: "Minor",
          },  
          {
            id: "Moderate",
            label: "Moderate",
          },  
        ],
        searchPriority: null,
        priorityopts: [
          {
            id: "High",
            label: "High",
          },
          {
            id: "Low",
            label: "Low",
          },
          {
            id: "Medium",
            label: "Medium",
          },
        ],
        
        logtime:{
          acivitylist:apiUrl.acivitylist,
          spenttime:"",
          activity:null,
          allotedto:null,
          status:null,
          comment:null,
          attachmentview:[],
          isTimeLogged: false,
        },
        loginusername:'',
        logtimehistory:[],

        searchAllocated: null,
        alloactedopts: [],
        subscriberslist: [],
        subscribers: null,
        searchStatus: null,
        statusopts: [{
            id:'open',
            label:'Open'
        },{
            id:'resolved',
            label:'Resolved'
        },{
            id:'closed',
            label:'Closed'
        },{
            id:'reopen',
            label:'Re-open'
        }]
      },
      view: {
        projectname: null,
        functionlityname: null,
        taskname: null,
        empname: null,
        lastmodifiedby: null,
        lastmodifieddate: null,
        history: []
      },
      logtime:{
        acivitylist:apiUrl.acivitylist,
        spenttime:"",
        activity:null,
        allotedto:null,
        status:null,
        comment:null,
        attachmentview:[]

      },
      dislogActive: true,
      isLogActive:false,
      isSearchRequestActive: false,
      pageoffset: 1,
      TimeFormat: apiUrl.TimeFormat,
      isLoading: false,
      fullPage: true,
      valid: false,
      submitted: false,
      name: "",
      datetime: "",
      totaldowntime: "",
      impact: "",
      losses: "",
      incidentcode:'',
      scenario: "",
      incidenttype: "",
      description: "",
      reason: "",
      createddate:"",
      shorttermsolution: "",
      longtermsolution: "",
      project: null,
      functionality: null,
      projectlist: null,
      projectmodulelist:[],
      projectnames:null,
      functionalitylist: null,
      severity: "",
      priority: "",
      attachment: null,
      attachmentview: null,
      attachmentnew: null,
      allocatedto: null,
      
      subscribers: null,
      status: "",
      createdby: "",
      glbMdlLbl: "Incident",
      glbMdl: "",
      incidentmasterlist: [],
      editRowData: [],
      nameBlured: false,
      impactBlured: false,
      lossesBlured: false,
      incidenttypeBlured: false,
      descriptionBlured: false,
      reasonBlured: false,
      employeeListOpts: [],
      errors: {
        project: "",
        functionality: "",
        allocatedto: "",
        //subscribers: "",
        status: "",
        severity: "",
        priority: "",
        datetime: "",
        totaldowntime: "",
        incidenttype:null,
        correctaction:null,
        imgm: {
          task: null,
          tasklist: [],
        },
      },
      logtimeErrors: {
        allotedto: null,
        spenttime: "",
        activity: null,
        comment:null
      },
      imgm: {
        task: null,
      },
      projectids:null,
      isAddState: false,
      disblesavebtn: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pagefeatures: [],
      showtabledata: true,
      isDetActive: true,
      isRequestActive: false,
      disRequestActive:true,
      subscriberslist: [],
      card_content: "card-content",
      collapse: "collapse",
      isedit: false,
      card: "card",
      border_warning: "border-warning",
      editor: ClassicEditor,
      severityBlured:false,
      priorityBlured:false,
      statusBlured:false,
      correctaction:null,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertTable",
            "|",
            "undo",
            "redo",
            "|",
          ],
        },
      },
      file: [],
      s3bucket: apiUrl.s3url,
      filedescp: [],
      loginusername:'',
      logtimehistory:[],
      imagext: ['jpg', 'jpeg', 'png'],
      tokendata:null,
      accepttype:apiUrl.uploadfiletype,
      userrole:null,
      statusconfig:apiUrl.statusconfig,
      validFileExtensions:apiUrl.validFileExtensions
    };
  },
  mounted() {
    window.addEventListener('storage', function(event){
        if (event.key == 'logout-mee') { 
            window.location.reload();
        }
    });
    let userdata = window.localStorage.getItem("userdata");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.createdby = userdata.username;
      this.loginusername = userdata.username;
      this.undt = userdata;
      this.tokendata = window.localStorage.getItem('token');
      this.userrole = window.localStorage.getItem("userrole")
      this.getincidentdetailsbyid(this.$route.query.incidentid)
      this.getincidentHistory(this.$route.query.incidentid)
      this.getSubscribers(this.$route.query.incidentid, 'incident')
      this.getProjectname()
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    selectseverity(state,value){
      if (!state){
        this.errors.severity = "Incident severity required"
      }else{
        this.errors.severity = ""
      }
    },
     selectpriority(state,value){ 
      if (!state){
        this.errors.priority = "Incident priority required"
      }else{
        this.errors.priority = ""
      }
    },
    selectallotedto(state,value){
      if (!state){
        this.errors.allocatedto = "Assigned to required"
      }else{
        this.errors.allocatedto = ""
      }
    },
    selSubscribers(state) {
      this.subscribers = null
      this.subscribers = state
      if(!state || (state && state.length < 1)) {
        this.errors.subscribers = 'Subscribers are required'
      } else {
        this.errors.subscribers = ''
      }
    },
    seltasklogactivity(state, value) {
        this.logtime.activity = null
        this.logtime.activity = state
        if(!state) {
        } else {
            this.logtimeErrors.activity = null
        }
        },
    seltasklogcomments(state, value) {
        this.logtime.comment = null
        this.logtime.comment = state
        if(!state) {
            this.logtimeErrors.comment = "Comment is required"
        } else {
            this.logtimeErrors.comment = null
        }
    },
    selissuecsstatus: function(state,value){
      this.status=null
      this.status=state
      if(!state){
        this.errors.status='Incident status required'
      }else{                
        this.errors.status=null
      }
    },
    sellogissuecsstatus: function(state,value){
      this.logtime.status=null
      this.logtime.status=state
      if(!state){
        this.errors.status='Incident status required'
      }else{                
        this.errors.status=null
      }
    },
    selallotedto: function(state,value){
      this.allocatedto=null
      this.allocatedto=state
      if(!state && this.isAddState == false){
        this.errors.allocatedto='Alloted To required'
      }else{ 
        this.errors.allocatedto=null
      }
    },
    selincident: function(state,value){
      this.incidenttype=null
      this.incidenttype=state
      if(!state && this.isAddState == false){
        this.errors.incidenttype='Incident type required'
      }else{                
        this.errors.incidenttype=null
      }
      if(this.isedit && state && state.toLowerCase()!=("policy violation incident" || "work bench")){
        this.correctaction=null
      }
    },
    secaction: function(state,value){
        this.correctaction=state
        if(!state && this.isAddState == false){
          this.errors.correctaction='Corrective action required'
        }else{                
          this.errors.correctaction=null
        }
    },
    handleCancel() {
      this.myrequestcollapse()
       $('html, body').animate({ scrollTop: 0 }, 800);
    },
    handleCancel2() {
      this.dislogActive=true
      this.isLogActive=false
      this.disblesavebtn = false
      $('html, body').animate({ scrollTop: 0 }, 1200);
    },
    removeImage (index) {
      if(this.file.length>0) {
          this.file.splice(index, 1);
          this.filedescp.splice(index, 1);
          var removeimg = JSON.parse(this.attachmentnew)
          if(removeimg){
              var remove = removeimg.data.splice(index,1)
              if(remove.length > 0)
              this.attachmentnew = JSON.stringify({data:removeimg.data})
              else {
              this.attachmentnew = null
            }
          }
      }   
      if(this.file.length < 1) {
          this.disblesavebtn = false
      }      
    },
    removes3file(key,data) {
        if(data.length>0) {                
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to undo!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    if(this.editRowData.id !== undefined) {
                        let deletefile = data[key];
                        data.splice(key, 1);
                        let att = this.attachment
                        if(att && att.data.length) {
                            att.data.splice(key, 1)
                            this.attachment = att
                        }
                        const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                        let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                        axios({
                        method: "POST",
                        url: 'api/master/taskmaster/s3Deletetask',
                        data: {
                            id:this.editRowData.id,
                            attachment:payload,
                            deletefile: JSON.stringify(deletefile) ,
                            useremail: this.undt.username,
                            empcode: this.undt.userid,
                        },
                        'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                        if (result.data.status) {
                            Swal.fire(
                            'Deleted!',
                            result.data.msg,
                            'success'
                            )
                        } else {
                        Swal.fire(
                            'Deleted!',
                            result.data.msg,
                            'error'
                            )                        
                        }
                    });
                    } else {
                        Swal.fire(
                          'Taskid!',
                          'Taskid should not blank',
                          'info'
                        )
                    }    
                }
            })
        }
    },
    onFileChange(e) {
      var validFileExtensions = apiUrl.validFileExtensions
      let selectedFiles = e.target.files;
      for (let i=0; i < selectedFiles.length; i++)
      {
        var blnValid = false;
        for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
          var sCurExtension = validFileExtensions[j];
          if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
          }
        }
        if (!blnValid) {
          Swal.fire({
              title: "ERROR",
              text: "Sorry, Invalid Type of Extension File..!!",
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          return false;
        }
        this.file.push(selectedFiles[i]);
      }
      if(this.file.length>0) {
          this.disblesavebtn=true
      }
    },
    formatData(data) {
      return data
    },
    formatDataComment(data) {
            return data
        },
        idclick6(indexx){
            
            if ($("#cycle_readmore6"+indexx).hasClass("readmore")) {
                $("#id6"+indexx).html("Read More");
                $("#cycle_readmore6"+indexx).removeClass("readmore");
            } else {
                $("#id6"+indexx).html("Read Less");
                $("#cycle_readmore6"+indexx).addClass("readmore");
            }
        },
    getincidentdetailsbyid(moduleid){
      this.isLoading = true;
      let url = "api/incidents/getincidentdetailsbyid";
      this.input = {
          incidentid:moduleid,
          useremail: this.undt.username,
          empid:this.undt.userid,
          empcode: this.undt.userid,
          userrole:this.userrole,
      };
      axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.editRowData = result.data.data;
              this.editRowData=this.editRowData[0]
              this.backpagedata=this.editRowData[0]
              this.openEditModule(this.editRowData,4)
          } else {
              this.editRowData = [];
              this.$router.push({ name: 'Incident List', params: { search: true }});
              Swal.fire({
                  title: "Failed!",
                  text: "Your are not authorised user to view this incident",
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    projectChange: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/searching/getAssignedProjectListWithMOdule";
        this.input = {
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectnameopts = result.data.data;
            this.projectlist = result.data.data;
          } else {
            this.search.projectnameopts = [];
            this.projectlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },

    getincidentHistory(incidentid){
      this.isLoading = true;
      let url = "api/incidents/getIncidentLogById";
      this.input = {
        incidentid:incidentid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.view.history = result.data.data;
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.view.history=[]
        }
      }).catch(e => {
        this.displayError(e)
      });
    },


     getissueLogTimeHistory(incidentid){
      this.isLoading = true;
      this.logtimehistory=[]
      let url = "api/incident/getincidentLogTimeById";
      this.input = {
        incidentid:incidentid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.logtimehistory = result.data.data;
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.logtimehistory=[]
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

    getProjectname: function () {
      this.apiURL = "api/projectapplication/getprojectnamelist";
      this.input = {
        projectid: 0,
        useremail: this.undt.username,
        empcode : this.undt.userid,
        isfrompage : 'updatepage'
      };
      axios({
        method: "POST",
        url: this.apiURL,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.projectlist = result.data.data;
          if(this.projectlist && this.projectlist.length > 0 && (this.projectnames && this.projectnames !=null)){
              const checkexistproject = this.projectlist.some(item =>item.id === this.projectnames);
              if(!checkexistproject){
                  this.getProjectnamebyid(this.projectnames);
              }
          }else if(this.projectlist.length ==0 && (this.projectnames && this.projectnames !=null)){
              this.getProjectnamebyid(this.projectnames);
          }
        } else {
          this.projectlist = [];
        }
      });
    },
    getProjectnamebyid(pid){
      this.isLoading = true;
      this.apiURL="api/searching/getAssignedProjectList"
      this.input={
          useremail: this.undt.username,
          empid :this.undt.userid,
          empcode: this.undt.userid,
          projectid: pid,
          isfrompage: 'isprojectid'
      }
      axios({
          'method': 'POST',
          'url': this.apiURL,
          'data':this.input,
          'headers':{'authorization':this.tokendata}
      })
      .then(result => {  
          this.isLoading = false;         
          if(result.data.errorCode == 0){
              if(result.data.data && result.data.data.length > 0){
                  this.projectlist.push(result.data.data[0]);
              }
          }
      }).catch(e => {
          this.displayError(e)
      })
    },
    getProjecttoProjectmodule(projectid) {
      axios({
        method: "POST",
        url: "api/master/businesstask/getprojectmodulebyprojectid",
        data: { projectid:projectid,empcode: this.undt.userid,
        useremail: this.undt.username, isfrompage: 'updatepage' },
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectmodulelist = result.data.data;
          if(this.projectmodulelist && this.projectmodulelist.length > 0 && (this.project && this.project !=null)){
            const checkexistproject = this.projectmodulelist.some(item =>item.id === this.project);
            if(!checkexistproject){
              this.getprojectmodulesbyid(projectid,this.project);
            }
          }else if(this.projectmodulelist.length ==0 && (this.project && this.project !=null)){
            this.getprojectmodulesbyid(projectid,this.project);
          }
        } else {
          this.projectmodulelist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getprojectmodulesbyid(projectid,moduleid) {
      axios({
        method: "POST",
        url: "api/master/businesstask/getprojectmodulebyprojectid",
        data: { projectid:projectid, empcode: this.undt.userid,
        useremail: this.undt.username, moduleid: moduleid },
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if(result.data.data && result.data.data.length > 0){
            if(this.projectmodulelist && this.projectmodulelist.length > 0){
              const checkexistprojectmodule = this.projectmodulelist.some(item =>item.id === moduleid);
              if(!checkexistprojectmodule){
                this.projectmodulelist.push(result.data.data[0]);
              }
            }else if(this.projectmodulelist.length ==0){
              this.projectmodulelist.push(result.data.data[0]);
            }
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    // getAssignedProjectData(projectid) {
    //   this.isLoading = true;
    //   let url = "api/searching/getAssignedProjectListWithMOdule";
    //   this.input = {
    //     projectname: "",
    //     useremail: this.undt.username,
    //     empid: this.undt.userid,
    //     projectid: projectid,
    //     empcode: this.undt.userid,
    //   };
    //   axios({
    //     method: "POST",
    //     url: url,
    //     data: this.input,
    //     'headers':{'authorization':this.tokendata}
    //   }).then((result) => {
    //     this.isLoading = false;
    //     if (result.data.errorCode == 0) {
    //       this.projectlist = result.data.data;
    //     } else {
    //       this.projectlist = [];
    //     }
    //   }).catch(e => {
    //     this.displayError(e)
    //   });
    // },
    incidentnmChange: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/master/commonmaster/getModuleDropdownOption";
        this.input = {
          tbl: "t46",
          incidentname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.incidentopts = result.data.data;
          } else {
            this.search.incidentopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getSubscribers(moduleid, moduletype){
      this.isLoading = true;
      let url = "api/subscribers/getSubscribersByModuleid";
      this.input = { moduleid, moduletype ,useremail: this.undt.username,empcode: this.undt.userid,};
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          let decryptdepartmenttext = result.data.data.rows.map(items =>{
          let tempemplabel1 = items.label;
          let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
          if(tempemplabel2 && tempemplabel2 != null){
            items.label = tempemplabel1 +'('+tempemplabel2 +')';
          }
          return items;
          })
          this.subscriberslist = result.data.data.rows;
          this.subscribers = result.data.data.rows.map(d => d.id)
        } else {
          this.subscribers = null
          this.subscriberslist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getAllocatedEMPByProjectModuel(node) {
      if(this.project && (node && node.length>3)){
      axios({
        method: "POST",
        url: "api/listing/getAllocatedEMPByProjectModuel",
        data: { projectidModuleID: this.project,useremail: this.undt.username,empcode : this.undt.userid,assignedto:node.toLowerCase() },
         'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.employeeListOpts = result.data.data;
          this.subscriberslist = result.data.data
          let templistarray =[]
          templistarray = result.data.data;
           let decryptdepartmenttext = templistarray.map(items =>{
            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
            })
            this.employeeListOpts = templistarray;
            this.subscriberslist=templistarray;
        } else {
          this.employeeListOpts = [];
          this.subscriberslist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    }
    },
    getAllocatedEMPByProjectModueledit(assignedto) {
      if(assignedto){
      axios({
        method: "POST",
        url: "api/listing/getAllocatedEMPByProjectModuel",
        data: { projectidModuleID: this.project,useremail: this.undt.username,empcode : this.undt.userid,assignedtoid:assignedto},
         'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.employeeListOpts = result.data.data;
          
        } else {
          this.employeeListOpts = [];
          
        }
      }).catch(e => {
        this.displayError(e)
      });
    }
    },
    selectproject: function (state, value) {
      this.search.projectnameopts = null;
      this.search.searchFunctionality = null;
      this.search.searchAllocated = null;
      this.search.searchTask = null;
      this.search.taskopts = null;
      this.search.alloactedopts = [];
      this.search.searchAllocated = null;
      this.search.searchProjectname = state;
      
      if (!state && this.isSearch == false) {
        this.search.functionalityopts = null;
        this.search.alloactedopts = [];
      
      } else {
        this.getProjecttoProjectmodule(state)
        // this.getDropdownApplicationdata("t37");
        //this.getAllocatedEMPByProjectModuel(state);

      }
    },
    selectfunctionality: function (state, value) {
      this.search.searchTask = null;
      this.search.taskopts = null;
      this.search.searchFunctionality = state;
      if (!state && this.isSearch == false) {
        this.search.taskopts = null;
      } else {
        this.getDropdownApplicationdata("t18");
      }
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption";
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
      };
      if (mdl === "t37") {
        this.input = {};
        apiUrl = "api/listing/getfunctionalitybyprojectmoduleid";
        this.input.projectmoduleid = [this.search.searchProjectname];
      }
      if (mdl === "t18") {
        this.input.functionalityid = this.search.searchFunctionality;
      }
      if (mdl === "t46") {
        this.input.functionalityid = this.search.searchFunctionality;
      }
      this.input.useremail= this.undt.username
      this.input.empcode= this.undt.userid
      axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
      .then((result) => {
        if (result.data.errorCode == 0) {
          if (mdl === "t37") {
            this.search.functionalityopts = result.data.data;
            this.search.functionalityopts.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
          } else if (mdl === "t18") {
            this.search.taskopts = result.data.data;
          } else if (mdl === "t46") {
            this.search.incidentopts = result.data.data;
          }
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.search.functionalityopts = [];
          this.search.taskopts = [];
          this.search.incidentopts = [];
          this.search.alloactedopts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
      this.search.searchprojectname = null;
      this.search.searchFunctionality = null;
      this.search.searchTasks = null;
      this.search.searchIncidentname = null;
      this.search.searchSeverity = null;
      this.search.searchPriority = null;
      this.search.searchStatus = null;
      this.search.searchAllocated = null;
    },
    myrequestcollapse() {
      this.isRequestActive = !this.isRequestActive;
      this.disRequestActive = !this.disRequestActive
    },
    resetForm() {
      this.name = "";
      this.imgm.task = null;
      this.datetime = "";
      this.totaldowntime = "";
      this.impact = "";
      this.losses = "";
      this.incidenttype = null;
      this.correctaction=null;
      this.description = "";
      this.reason = "";
      this.shorttermsolution = "";
      this.longtermsolution = "";
      this.project = null;
      this.functionality = null;
      this.severity = "";
      this.priority = "";
      this.attachment = null;
      this.attachmentview = null;
      this.allocatedto = null;
      this.status = "";
      this.submitted = false;
      this.valid = false;
      this.nameBlured = false;
      this.impactBlured = false;
      this.lossesBlured = false;
      this.incidenttypeBlured = false;
      this.descriptionBlured = false;
      this.reasonBlured = false;
      this.file=[]
      this.errors = {
        project: "",
        functionality: "",
        allocatedto: "",
        status: "",
        severity: "",
        priority: "",
        datetime: "",
        totaldowntime: "",
        desc: "",
        correctaction:"",
        incidenttype:""
      };
    },
    validText: function (inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validateDate(mdl, objThis) {
      var startDate = this.datetime
      if(startDate){
        this.errors.datetime=''
      }else{
        this.errors.datetime='Incident date required'
      }
    },
    validate: function () {
      this.valid = true;
      this.nameBlured = true;
      this.impactBlured = true;
      this.lossesBlured = true;
      this.incidenttypeBlured = true;
      this.descriptionBlured = true;
      this.reasonBlured = true;
      if (!this.project) {
        this.errors.project = "Project name required";
        this.valid = false;
      } else {
        this.errors.project = "";
      }
      if (!this.description) {
        this.errors.desc = "Description required";
        this.valid = false;
      } else {
        this.errors.desc = "";
      }
      if (!this.allocatedto) {
        this.errors.allocatedto = "Assigned to required";
        this.valid = false;
      } else {
        this.errors.allocatedto = "";
      }
      if((!this.subscribers) || (this.subscribers && this.subscribers.length < 1)) {
          this.errors.subscribers = 'Subscribers are required'
          this.valid = false
      } else {
          this.errors.subscribers = ''
      }
      if (!this.status) {
        this.errors.status = "Incident status required";
        this.valid = false;
      } else {
        this.errors.status = "";
      }

      if (!this.incidenttype) {
        this.errors.incidenttype = "Incident type required";
        this.valid = false;
      } else {
        this.errors.incidenttype = "";
      }
      if(this.incidenttype && (this.incidenttype.toLowerCase() =="policy violation incident" || this.incidenttype.toLowerCase() =="work bench") && !this.correctaction){
        this.errors.correctaction = "Corrective action required";
        this.valid = false;
      }else {
        this.errors.correctaction = "";
      }
      if (!this.severity) {
        this.errors.severity = "Incident severity required";
        this.valid = false;
      } else {
        this.errors.severity = "";
      }
      if (!this.priority) {
        this.errors.priority = "Incident priority required";
        this.valid = false;
      } else {
        this.errors.priority = "";
      }
      if (!this.datetime) {
        this.errors.datetime = "Incident date required";
        this.valid = false;
      } else {
        this.errors.datetime = "";
      }
      if (!this.totaldowntime) {
        this.errors.totaldowntime = "Incident down-time required";
        this.valid = false;
      } else {
        this.errors.totaldowntime = "";
      }
      if (!this.validText(this.name)) {
        this.valid = false;
      }
      if (!this.validText(this.impact)) {
        this.valid = false;
      }
      if (!this.validText(this.losses)) {
        this.valid = false;
      }
      if (!this.validText(this.incidenttype)) {
        this.valid = false;
      }
      if (!this.validText(this.description)) {
        this.valid = false;
      }
      if (!this.validText(this.reason)) {
        this.valid = false;
      }
      return this.valid;
    },

    getProjectname: function () {
      this.apiURL = "api/projectapplication/getprojectnamelist";
      this.input = {
        projectid: 0,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage : 'updatepage'
      };
      axios({
        method: "POST",
        url: this.apiURL,
        data: this.input,
        'headers':{'authorization':this.tokendata}

      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.projectlist = result.data.data;
          if(this.projectlist && this.projectlist.length > 0 && (this.projectnames && this.projectnames !=null)){
              const checkexistproject = this.projectlist.some(item =>item.id === this.projectnames);
              if(!checkexistproject){
                  this.getProjectnamebyid(this.projectnames);
              }
          }else if(this.projectlist.length ==0 && (this.projectnames && this.projectnames !=null)){
              this.getProjectnamebyid(this.projectnames);
          }
        } else {
          this.projectlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    redirecttoPNM:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 

                path: '/project/summary?pid='+rowdata.moduleid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
    getApplicationdata(mdl, curr) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      let apiURL = "api/master/commonmaster/list";
      if (mdl == "t11") {
        apiURL = "api/listing/getprojectlist";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
      } else if (mdl == "t37") {
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        apiURL = "api/listing/getfunctionalitybyprojectmoduleid";
        this.inputappl.projectmoduleid = [this.project];
      } else if (mdl == "t18") {
        apiURL = "api/master/commonmaster/getModuleDropdownOption";
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          functionalityid: this.functionality,
        };
      }
      this.inputappl.useremail= this.undt.username
      this.inputappl.empcode= this.undt.userid
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (mdl == "t11") {
            return true;
          } else if (mdl == "t37") {
            this.functionalitylist = result.data.data;
            this.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            return true;
          } else if (mdl == "t18" && curr == "imgm") {
            this.imgm.tasklist = result.data.data;
            return true;
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

    seltestcsproject(state, value) {
      this.project = null;
      this.project = state;
      if (!state) {
        this.functionalitylist = null;
        this.functionality = null;
        this.imgm.tasklist = null;
        this.imgm.task = null;
        this.allocatedto = null;
        this.employeeListOpts = null;
        this.project = null;
        this.errors.project = "Project required";
        this.incidenttype = null;
        this.getStatusConfigList(null,this.status)
      } else {
        this.getIncidentTypeConfigLists(state,this.status)
        this.getApplicationdata("t37", "testcase");
        //this.getAllocatedEMPByProjectModuel(state);
        if (this.isedit && state != this.editRowData.projectid) {
          this.incidenttype = null 
        }
      }
    },

    seltestcsfunctionality(state, value) {
      this.functionality = null;
      this.functionality = state;
      if (!state && this.isAddState == false) {
        this.imgm.task = null;
        this.imgm.tasklist = null;
      } else {
        this.getApplicationdata("t18", "imgm");
      }
    },
    saveData(objThis,flag) {
      if (this.validate()) {
        this.submitted = true;
      }
      if(flag==1){
        this.submitted = true;
      }
      if(objThis.logtimeFlag && objThis.logtimeFlag==1){
        this.submitted =true;
      }
      if (this.submitted) {
        this.isLoading = true;
        this.input = {
          name: this.name,
          task: this.imgm.task,
          datetime: this.datetime,
          totaldowntime: this.totaldowntime,
          impact: this.impact,
          losses: this.losses,
          incidenttype: this.incidenttype ? this.incidenttype.toLowerCase() : null,
          description: this.description,
          reason: this.reason,
          shorttermsolution: this.shorttermsolution,
          longtermsolution: this.longtermsolution,
          projectmoduleid: this.project,
          severity: this.severity,
          priority: this.priority,
          allocatedto: this.allocatedto,
          subscribers: this.subscribers,
          empid: this.undt.userid,
          createdby: this.createdby,
          isactive: "1",
          status: this.status,
          lastmodifiedby: this.undt.username,
          module_code: this.incidentcode,
          correctiveaction:this.correctaction
        };
        this.input.attachment=this.attachmentnew
        if (!this.functionality) {
          this.input.functionality = null;
        } else {
          this.input.functionality = this.functionality;
        }
        if (!this.imgm.task) {
          this.input.task = null;
        } else {
          this.input.task = this.imgm.task;
        }
        let apiURL = "/api/incidents/save";
        if (this.isedit) {
          this.isedit = false;
          this.input.isTimeLogged = this.logtime.isTimeLogged
          apiURL = "/api/incidents/update";
          if(this.attachmentnew && this.attachment !== null) {
              this.input.attachmentnew = this.attachmentnew
              let jsonobj1 = JSON.parse(this.attachmentnew);
              let jsonobj2 = this.attachment;
              let arraymerge = [...jsonobj1.data,...jsonobj2.data]
              this.input.attachment= JSON.stringify({data: arraymerge});
              this.view.documentlist = arraymerge
          }else if(this.attachment && !this.attachmentnew){
              this.input.attachment = JSON.stringify(this.attachment)
          }
          this.input.id = this.editRowData.id;
        }
        this.input.useremail= this.undt.username
        this.input.empcode= this.undt.userid
          axios({
                'method': 'POST',
                'url': apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.forceRerender()
              this.file = []
              this.filedescp = []
              this.disRequestActive = true
              this.logtime.spenttime = ''
              this.logtime.activity = null
              this.logtime.allotedto = null
              this.logtime.status = null
              this.logtime.comment = ''      
              this.logtime.isTimeLogged = false
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.getincidentdetailsbyid(this.editRowData.id)
              $('html, body').animate({ scrollTop: 0 }, 1200);
            } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            }
          }).catch(e => {
        this.displayError(e)
      });
      }
    },

    openEditModule(currRowData, flag) {
      this.resetForm();
      if(!currRowData){
        currRowData =this.editRowData ;
      }
      this.attachment = null
      this.attachmentview = null
      this.attachmentnew = null
      this.disblesavebtn = false
      this.logtime.isTimeLogged = false
      this.dislogActive=true
      this.view.projectname = currRowData.projectname;
      this.view.projectmodulename = currRowData.projectmodulename;
      this.view.projectid=currRowData.projectid
      this.view.functionlityname = currRowData.functionlityname;
      this.view.taskname = currRowData.taskname;
      this.view.empname = currRowData.empname;
      this.view.lastmodifiedby = currRowData.lastmodifiedby;
      this.view.createdby = currRowData.createdby;
      this.view.lastmodifieddate = currRowData.lastmodifieddate;
      let btnEle = "incident";
      $("#" + btnEle)
        .find("#toggleSubmission")
        .addClass(btnEle);
      $("#" + btnEle)
        .find("#toggleSubmission")
        .attr("data-isedit", true);
      this.name = currRowData.incidentname;
      this.datetime = moment(currRowData.datetime).format("YYYY-MM-DD");
      
      this.createddate = currRowData.createddate;
      this.totaldowntime = currRowData.totaldowntime;
      this.impact = currRowData.impact;
      this.losses = currRowData.losses;
      if(currRowData.incidenttype =='it infra incident'){
        this.incidenttype = 'IT Infra Incident'
      }else{
        this.incidenttype = this.capitalizeFirstLetter(currRowData.incidenttype);
      }
      this.description = currRowData.description;
      this.reason = currRowData.reason;
      this.shorttermsolution = currRowData.shorttermsolution;
      this.longtermsolution = currRowData.longtermsolution;
      this.project = currRowData.projectmoduleid;
      this.projectnames = currRowData.projectid;
      this.correctaction = currRowData.correctiveaction;
      this.view.correctaction = currRowData.correctiveaction;
      this.getApplicationdata("t37", "testcase");
      this.getProjecttoProjectmodule(currRowData.projectid);
      // this.getProjecttoProjectmodule(currRowData.projectmoduleid);
     // this.getAllocatedEMPByProjectModuel(currRowData.projectmoduleid);
      this.functionality = currRowData.functionality;
      this.imgm.task = currRowData.taskid;
      this.severity = currRowData.severity;
      this.priority = currRowData.priority;
      this.attachment = currRowData.attachment;
      if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
        this.attachmentview =[];
        this.getDownloadLink(currRowData.attachment);
      }
      this.allocatedto = currRowData.allocatedto;
      this.getAllocatedEMPByProjectModueledit(currRowData.allocatedto)
      this.status = currRowData.status;
      this.projectids = currRowData.projectid
      this.getStatusConfigList(this.projectids,this.status);
      this.getIncidentTypeConfigLists(this.project,this.incidenttype ? this.incidenttype.toLowerCase(): null)
      this.incidentcode= currRowData.incidentcode
      this.getissueLogTimeHistory(currRowData.id)
      this.isAddState = true;
      if (flag == 0) {
        this.getincidentdetailsbyid(this.$route.query.incidentid)
        this.isedit = true;
        this.isRequestActive = true;
        this.disRequestActive=false
        $('html, body').animate({ scrollTop: $(document).height() }, 1200);
      }
      if(this.projectlist && this.projectlist.length > 0 && (this.projectnames && this.projectnames !=null)){
          const checkexist = this.projectlist.some(item =>item.id === this.projectnames);
          if(!checkexist){
              this.getProjectnamebyid(this.projectnames);
          }
      }else if(this.projectlist && this.projectlist.length ==0 && (this.projectnames && this.projectnames !=null)){
          this.getProjectnamebyid(this.projectnames);
      }
    },
    capitalizeFirstLetter(sentence) {
      let words = sentence.split(" ");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    savelogData(){
      let valid = this.validatelog();
      if(valid){
        this.logtime.isTimeLogged = true
        this.input={
          moduletype:'incident',
          modulename:this.editRowData.incidentname,
          moduleid:this.editRowData.id,
          projectname:this.editRowData.projectname,
          projectid:this.editRowData.projectid,
          activity:this.logtime.activity,
          assignedto:this.logtime.allotedto,
          status:this.logtime.status,
          module_code: this.incidentcode,
          createdby:this.undt.username,
          attachment:JSON.stringify(this.attachmentnew),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        if(typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
            this.input.spenttime = this.logtime.spenttime
        }else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
            this.input.spenttime = this.logtime.spenttime
        }
        if(this.logtime.comment) {
          this.input.coments = this.logtime.comment
        }
        if(this.attachmentnew) {
          let jsonobj1 = JSON.parse(this.attachmentnew);
          let arraymerge = [...jsonobj1.data]
          this.input.attachment= JSON.stringify({data: arraymerge});
        }
        this.isLoading=true
        axios({
          method: "POST",
          url: 'api/issue/saveLogTime',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.isedit =true
            this.status=this.logtime.status
            this.allocatedto = this.logtime.allotedto
            this.editRowData.logtimeFlag=1
            this.saveData(this.editRowData,1)
            this.dislogActive=true
            this.isLogActive=false
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            Swal.fire({
              title: "Failed!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getIncidentTypeConfigLists(pid, incTypes) {
    let payload = {
        empcode: this.undt.userid,
        useremail: this.undt.username,
        projectmoduleid: pid,
        trackername: 'Incident Type',
        currStatus: incTypes,
    };
    axios({
        method: "POST",
        url: '/api/testcase/getINCTypesConfigList',
        data: payload,
        'headers':{'authorization':this.tokendata}
    }).then((result) => {
        if(result.data.errorCode == 0){
          let slist = [];
            result.data.data.rows.forEach((rec) => {
                if(Object.values(rec.statusconfig).length>0){
                    Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                        if(statusnames.length>0) {
                            statusnames.forEach(val => {
                                if(!slist.some(sdata => sdata.id === val)){
                                    slist.push({ id: val, label: val });
                                }
                            });
                        }
                    });
                }
            })
            slist.sort((a, b) => a.label.localeCompare(b.label));
            this.search.incTypesopts = slist;
        } else {
            this.search.incTypesopts = [];
        }
    }).catch(e => {
        this.displayError(e)
      });
  },
    openLogModule(){
      this.logtime.spenttime=null
      this.logtime.spenttime = { "HH": "", "mm": "" }
      this.logtime.activity = null,
      this.logtime.comment = ''
      this.logtime.allotedto=this.editRowData.allocatedto
      this.logtime.status= this.editRowData.status
      this.dislogActive=false
      this.isLogActive=true
      this.disblesavebtn = false
      this.disRequestActive=true
      $('html, body').animate({ scrollTop: $(document).height() }, 800);
    },
    validatelog: function(){
      var isValid=true;
      let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm

      if(!spenttime && typeof(this.logtime.spenttime)=='string')
      {
        let logstime = (this.logtime.spenttime).split(':')
        if(logstime){
            if(logstime[0] && logstime[1]){
                spenttime = `${logstime[0]}:${logstime[1]}`
                  this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
            }
        }
      }
      if(spenttime && typeof(this.logtime.spenttime)!='string'){
             this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
      }

      if(this.logtime.activity && spenttime==''){
        this.logtimeErrors.spenttime="Spent-time Hours & minutes is required";
        isValid= false;
      }else{
        this.logtimeErrors.spenttime='';
      }
      if(!this.logtime.activity && spenttime!=''){
        this.logtimeErrors.activity="Activity is required";
        isValid= false;
      }else{
        this.logtimeErrors.activity=null;
      }
      if(!this.logtime.allotedto){
        this.logtimeErrors.allotedto="Alloted to is required";
        isValid= false;
      }else{
        this.logtimeErrors.allotedto=null;
      }
      if(!this.logtime.comment){
        this.logtimeErrors.comment="Comment is required";
        isValid= false;
      }else{
        this.logtimeErrors.comment=null;
      }
      return isValid
    },
    getDownloadLink(data) {
      if(data.data.length>0) {
        let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
              method: "POST",
              url: '/api/imageupload/getdownloadlink',
              data: payload,
              'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            this.attachmentview = result.data;
          }
        });
      }
    },
    getLogtimeDownloadLink(data, curr=null) {
      if(data) {
        var input =[]
        input.push({link:data,useremail: this.undt.username,empcode: this.undt.userid})
        let payload = {
          documents:input,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            if(result.data.data[0].presignurl){
              if(curr=='zoom'){
                $('.imagepreview').attr('src', result.data.data[0].presignurl);
                $('#imagemodal').modal('show'); 
                return 
              }
              window.location.href=result.data.data[0].presignurl;
              
            }
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
  
    onSelect(e) {
      this.file = e.target.files[0];
    },
    onUpload(){
      if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();  
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
                formData.append("imagefile", this.file[index]);
                maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail",this.undt.username);
            formData.append("moduletype", 'task');
            formData.append("fcount", parseInt(this.file.length));
            
            if (maxsize > 1000 * 1000 * 25) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 25mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.disblesavebtn=true
                return false; 
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading=false; 
                this.disblesavebtn=false
                if(result.data.status){
                      const bindinfo = result.data.data.map((file, index) => {
                        return {"link":file,"info":this.filedescp[index]}                
                    });
                    let jsonobj = {data:bindinfo}
                    this.attachmentnew = JSON.stringify(jsonobj);
                    if(this.taskmodule == 1) {
                      this.saveBusinessDocuments(bindinfo)
                    }
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.attachment=null
                }
            })
        }else{
              this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Select image/docs/videos',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.attachment=null
        }
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.attachment=null
      }
    },
    dateFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    },
    dateFormat2(value) {
        if (!value) return "";
        return moment(String(value)).format('DD-MMM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    onZoom(e){
      this.getLogtimeDownloadLink(e, 'zoom');
    },
    getStatusConfigList(pid, status) {
      if(this.statusconfig){
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: pid,
            trackername: 'Incident',
            currStatus: status,
        };
        axios({
            method: "POST",
            url: '/api/testcase/getStatusConfigList',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            if(result.data.errorCode == 0){
                this.search.statusopts = result.data.data.rows;
            } else {
                this.search.statusopts = [];
            }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
  },
  filters: {
    basename: function (value) {
      if (!value) return "";
      return value.substr(value.lastIndexOf("/") + 1);
    },
  },
};
</script>

<style>

.display{
    display: none !important;
}
.click-zoom input[type=checkbox] {
  display: none;
}

.click-zoom img {
  /* margin: 100px; */
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.click-zoom input[type=checkbox]:checked~img {
  transform: scale(10);
  cursor: zoom-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
}
</style>
