<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0 ">
                <h3 class="content-header-title float-left mb-0">Domain Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/domain/list'>Domain Management</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
            <div class="container-fluid" >
              <div class="row" >
                <div class="w-auto px-1 mobile-padding-0-2">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                      <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                    </div>
                  </div>
                <h4 class="card-title d-inline-flex ml-1">Search Domain Details</h4>
                </div>
                <div class="ml-auto d-flex">
                <div class="px-1 text-right mobile-padding-0-2" >
                  <div class="input-group-append float-right">
                    <router-link to="/domain/create"><span class="btn btn-sm btn-relief-success" ><span class="mobile_hide_bt">Create Domain </span> <plus-circle-icon size="1.5x"></plus-circle-icon> </span></router-link>
                  </div>
                </div>
                <span class="rotetions_view_arrow ml-auto mx-1"> 
                <em class="las la-angle-down"></em>
              </span>
              </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
            <div class="card-body pt-1 px-sm-mobile-10">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Domain Name </label>
                  <treeselect class="projectmdllistdd" placeholder="Select Domain Name" v-model="search.domain" :options="domainnamelist" :clear-on-select="true" :multiple="true"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Country</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Country name" v-model="search.country" :options="countrylist" :clear-on-select="true" :multiple="true"/>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                  <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getAllDomainData(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                  <button type="move" class="btn btn-relief-primary mt-2 mr-1" @click="ExportXL(search, true)"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" id="domain-list">
          <div class="card-body p-0 ps ps--active-x">
            <div class="table-responsive datatableview" >
              <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                <thead>
                  <tr>
                    <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                    <th scope="col" class="text-center second-col-sticky">Domain Name </th>
                    <th scope="col" class="text-center">Domain Registrar</th>
                    <th scope="col" class="text-center">Website</th>
                    <th scope="col" class="text-center">Country</th>
                    <th scope="col" class="text-center" style="min-width:165px">Domain Owner Name</th>
                    <th scope="col" class="text-center" style="min-width:165px">Domain Purchase Date</th>
                    <th scope="col" class="text-center" style="min-width:165px">Domain Expiry Date</th>
                    <th scope="col" class="text-center">Domain Purchase Approval Name</th>
                    <th scope="col" class="text-center">Domain Renewal Approval Name</th>
                    <th scope="col" class="text-center">Purchase Quantity</th>
                    <th scope="col" class="text-center">Used Quantity</th>
                    <th scope="col" class="text-center">isactive</th>
                    <th scope="col" class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(data,index) in domain.domainlist"  >
                    <tr v-if="!data.edit" v-bind:key="index" >
                      <td data-column="Sr. No" class="text-center first-col-sticky ">{{ index+1+currPageNum*pagelimit }}</td>
                      <td data-column="Domain Name" class="text-center second-col-sticky">{{data.domain_name}}</td>
                      <td data-column="Domain Registrar" class="text-center text-capitalize">{{data.registrar}}</td>
                      <td data-column="Website" class="text-center">{{data.website}}</td>
                      <td data-column="Country" class="text-center text-capitalize">{{data.country}}</td>
                      <td data-column="Domain Owner Name" class="text-center text-capitalize">{{data.owner_name}}</td>
                      <td data-column="Domain Purchase Date" class="text-center text-capitalize">{{dateMonthFormat(data.purchase_date)}}</td>
                      <td data-column="Domain Expiry Date" class="text-center text-capitalize">{{dateMonthFormat(data.expiry_date)}}</td>
                      <td data-column="Domain Purchase Approval Name" class="text-center text-capitalize">{{data.purchase_approval_name ? data.purchase_approval_name : '-'}}</td>
                      <td data-column="Domain Purchase Approval Name" class="text-center text-capitalize">{{data.renewal_approval_name ? data.renewal_approval_name : '-'}}</td>
                      <td data-column="Purchase Quantity" class="text-center text-capitalize">{{data.purchasequantity?data.purchasequantity:'NA'}}</td>
                      <td data-column="Used Quantity" class="text-center text-capitalize">{{data.usedquantity?data.usedquantity:'NA'}}</td>
                      <td data-column="isactive" class="text-center text-capitalize"  v-if="data.isactive === true">
                        <check-circle-icon size="1.5x" class="custom-class" style="color: #28c76f"></check-circle-icon>
                      </td>
                      <td data-column="isactive" class="text-center text-capitalize" v-else>
                        <x-circle-icon else size="1.5x" class="custom-class" style="color: black"></x-circle-icon>
                      </td>
                      <td data-column="Action" class="centeralign">
                        <span class="btn btn-icon btn-outline-primary rounded-circle devEditEle edit_open_box_exam"  @click="activateEditRow(data, index)">
                          <edit-icon size="1.5x" class="custom-class"></edit-icon>
                        </span>
                      </td>
                    </tr>
                    <tr v-else v-bind:key="index">
                      <td data-column="Sr. No" class="text-center first-col-sticky ">{{ index+1+currPageNum*pagelimit }}</td>
                      <td data-column="Domain Name" class="box-inc second-col-sticky  mb-1">
                        <input type="text" class="form-control" placeholder="Domain Name" v-model="data.domain_name"/>
                        <div class="errorinputmsg" v-if="!data.domain_name">Domain name is required</div>
                      </td>
                      <td data-column="Domain Registrar" class="box-inc">
                        <input type="text" class="form-control" placeholder="Registrar Name" v-model="data.registrar"/>
                        <div class="errorinputmsg" v-if="!data.registrar">Registrar name is required</div>
                      </td>
                      <td data-column="Website" class="box-inc">
                        <input type="text" class="form-control" placeholder="Website Name" v-model="data.website"/>
                        <div class="errorinputmsg" v-if="!data.website">Website is required</div>
                      </td>
                      <td data-column="Country" class="box-inc">
                        <treeselect placeholder="Select Country" class="projectlistdd" v-model="data.country" :options="countrylist"/>
                        <div class="errorinputmsg" v-if="!data.country">Country is required</div>
                      </td>
                      <td data-column="Domain Owner Name" class="box-inc">
                        <input type="text" class="form-control" placeholder="Owner Name" v-model="data.owner_name"/>
                        <div class="errorinputmsg" v-if="!data.owner_name">Owner name is required</div>
                      </td>
                      <td data-column="Domain Purchase Date" class="box-inc">
                        <date-picker placeholder="Select Domain Purchase Date" v-model="data.purchase_date" valueType="format" class="startdate" :disabled-date="notBeforeToday"></date-picker>
                        <div class="errorinputmsg" v-if="!data.purchase_date">Purchase date is required</div>
                      </td>
                      <td data-column="Domain Expiry Date" class="box-inc">
                        <date-picker placeholder="Select Domain Expiry Date" v-model="data.expiry_date" valueType="format" class="startdate" :default-value="new Date()" :disabled-date="disabledBefore" ></date-picker>
                        <div class="errorinputmsg" v-if="!data.expiry_date">Expiry date is required</div>
                      </td>
                      <td data-column="Domain Purchase Approval Name" class="box-inc">
                        <input type="text" class="form-control" placeholder="Puchase Approval Name" v-model="data.purchase_approval_name"/>
                        <div class="errorinputmsg" v-if="!data.purchase_approval_name">Purchase approval name is required</div>
                      </td>
                      <td data-column="Domain Renewal Approval Name" class="box-inc">
                        <input type="text" class="form-control" placeholder="Renewal Approval Name" v-model="data.renewal_approval_name"/>
                        <div class="errorinputmsg" v-if="!data.renewal_approval_name">Renewal approval name is required</div>
                      </td>
                      <td data-column="Purchase Quantity" class="box-inc">
                        <input type="number" @keypress="validNumber" class="form-control" placeholder="Purchase Quantity" v-model="data.purchasequantity"/>
                      </td>
                      <td data-column="Used Quantity" class="box-inc">
                        <input type="number" @keypress="validNumber" class="form-control" placeholder="Used Quantity" v-model="data.usedquantity"/>
                      </td>
                      <td data-column="isactive" :class="{'active': data.isactive}" class="toggle__button2">
                          <label :for="'isactive-'+index+'-'+data.id" :class="{'active': data.isactive}" class="toggle__button2">
                            <input type="checkbox" :id="'isactive-'+index+'-'+data.id" v-model="data.isactive">
                            <span class="toggle__switch"></span>
                            <span :class="{'off-lable-color': !data.isactive, 'on-label-color': data.isactive }"></span>
                          </label>
                      </td>
                      <td data-column="Action" class="centeralign flex--items">
                        <button type="submit" class="btn btn-relief-primary mr-1" style="padding-left: 1rem; padding-right:1rem" v-on:click.stop.prevent="saveData(data)"><check-circle-icon size="1.5x" class="custom-class"></check-circle-icon></button>
                        <button type="submit" class="btn btn-relief-info" style="padding-left: 1rem; padding-right:1rem;" v-on:click.stop.prevent="cancelUpdate(index)"><x-circle-icon size="1.5x" class="custom-class"></x-circle-icon></button>
                      </td>
                    </tr>
                  </template>
                  <template v-if="domain.domainlist.length==0">
                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center mt-4">
              <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2'
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'DomainList',
  mixins: [ commonMethods ],
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    DatePicker,
    PlusCircleIcon,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    Pagination,
    SearchIcon,
    ExternalLinkIcon,
    XIcon,
  },
  data() {
    return {
      domain: {
        domainlist: [],
        prevdomainlist: [],
      },
      domainnamelist:[],
      countrylist: [
         { label: 'China', id: 'China' },
         { label: 'GCC', id: 'GCC' },
         { label: 'Global', id: 'Global' },
          { label: 'India', id: 'India' },
          { label: 'Kuwait', id: 'Kuwait' },
          { label: 'KSA', id: 'KSA' },
          { label: 'Oman', id: 'Oman' },
          { label: 'UAE', id: 'UAE' },
      ],
      search: {
        country: null,
        domain:null,
      },
      isactive: true,
      isSearchBoxActive: false,
      card_content:'card-content',
      collapse:'collapse',
      isLoading: false,
      undt: null,
      tokendata: null,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      TimeFormat: apiUrl.TimeFormat,
      purchase_date:null,
      expiry_date:null,
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        // this.getAllDomainData()
        this.getDomainNameList();
        if(this.$route.params && this.$route.params.search){
          let search = JSON.parse(localStorage.getItem('domainsearchItems'));
          if(search){
            this.getSearchItems(search);
          }else{
            localStorage.removeItem('domainsearchItems');
            this.getAllDomainData();
          }
        }else{
          localStorage.removeItem('domainsearchItems');
          this.getAllDomainData();
        }
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    validNumber : event => {
    let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    notBeforeToday: function (date) {
      this.expiry_date = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));     
    },
    disabledBefore(date) {
      let dayBefore = moment(this.purchase_date).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
            console.log(beforeToday,'purchase_date');
      return date < beforeToday; 
    },
    getDomainNameList() {
      this.isLoading = true
      let url = 'api/domain/getDomainNameList'
      let payload = new Object({
        domain_name:this.search.domain,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.domainnamelist = result.data.data
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.domainnamelist=[]
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    getAllDomainData(search) {
      if (search) {
        this.pageoffset = 1;
        this.page = 1;
      }
      if(search) {
        localStorage.setItem('domainsearchItems', JSON.stringify(search))
      }
      this.isLoading = true;
      let searchs = JSON.parse(localStorage.getItem('domainsearchItems'));

      let url = 'api/domain/getAllDomainData'
      let payload = new Object({
        limit: apiUrl.LIMIT,
        offset: this.pageoffset,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        empid: parseInt(this.undt.userid),
      })
      if(search) {
        payload.country = search.country
        payload.domain_name = search.domain
      }
      // if(isExportedFlag) {
      //   payload.isExported = true
      // }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          let d = result.data.data.rows
          // if(isExportedFlag) {
          //   this.download(d)
          // }
          this.domain.domainlist = d.map(i => {
            return {...i, edit: false}}
          )
          this.domain.prevdomainlist = d
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else {
          this.domain.domainlist = []
          this.totalcount = 0
          this.pageCount = 0
          if(isExportedFlag) {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            });
          }
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    ExportXL(search,isExportedFlag) {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        useremail: this.undt.username,
        empcode: this.undt.userid,
        empid: parseInt(this.undt.userid),
        country: search.country,
        domain_name: search.domain
      };
      if(isExportedFlag) {
        this.input.isExported = true
      }
      axios({
        method: "POST",
        url: `api/domain/getAllDomainData`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download(result.data.data.rows,filename)
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }        
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata) {
      if(readExportdata.length > 0) {
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let row = ''
        let CSV = 'Sr#, Domain Name, Registrar, Website, Country, Owner Name, Purchase Date, Expiry Date, Purchase Approval Name, Renewal Approval Name, purchasequantity, usedquantity, Created Date, Created By' + '\r\n'
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let domain_name = index.hasOwnProperty('domain_name') ? (index.domain_name?index.domain_name.replace(/"/g, '""'):'')  : ''
          let registrar = index.hasOwnProperty('registrar') ? (index.registrar?index.registrar.replace(/"/g, '""'):'') : ''
          let website = index.hasOwnProperty('website') ? (index.website?index.website.replace(/"/g, '""'):'') : ''
          let country = index.hasOwnProperty('country') ? index.country : ''
          let owner_name = index.hasOwnProperty('owner_name') ? (index.owner_name?index.owner_name.replace(/"/g, '""'):'') : ''
          let purchase_date = index.hasOwnProperty('purchase_date') ? this.dateMONFormat(index.purchase_date) : ''
          let expiry_date = index.hasOwnProperty('expiry_date') ? this.dateMONFormat(index.expiry_date) : ''              
          let purchase_approval_name = index.hasOwnProperty('purchase_approval_name') ? (index.purchase_approval_name?index.purchase_approval_name.replace(/"/g, '""'):'') : ''
          let renewal_approval_name = index.hasOwnProperty('renewal_approval_name') ? (index.renewal_approval_name?index.renewal_approval_name.replace(/"/g, '""'):'') : ''
          let purchasequantity = index.hasOwnProperty('purchasequantity') ? (index.purchasequantity?index.purchasequantity:'NA') : ''
          let usedquantity = index.hasOwnProperty('usedquantity') ? (index.usedquantity?index.usedquantity:'NA') : ''
          let createddate = index.hasOwnProperty('createddate') ? this.dateMONFormat(index.createddate) : ''
          let createdby = index.hasOwnProperty('createdby') ? index.createdby : ''
          row += '"' + srno + '",'
          row += '"' + domain_name + '",'
          row += '"' + registrar + '",'
          row += '"' + website + '",'
          row += '"' + country + '",'
          row += '"' + owner_name + '",'
          row += '"' + purchase_date + '",'           
          row += '"' + expiry_date + '",'
          row += '"' + purchase_approval_name + '",'
          row += '"' + renewal_approval_name + '",'
          row += '"' + purchasequantity + '",'
          row += '"' + usedquantity + '",'
          row += '"' + createddate + '",'
          row += '"' + createdby + '",'
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        let fileName = 'domain_details_' + moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Domains Exported successfully",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });    
      }
    },
    dateMONFormat(value){
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
    activateEditRow:function(data,idx){
      data.edit = true
    },
    searchBoxCollapse() {
      this.isSearchBoxActive = !this.isSearchBoxActive
    },
    clearSearchFields() {
      this.search.country = null;
      this.search.domain=null;
      localStorage.removeItem('domainsearchItems');
      this.getAllDomainData();
    },
    clickCallback: function(pageNum) {
      this.pageoffset=pageNum
      this.currPageNum = pageNum - 1
      this.getAllDomainData();
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $("#domain-list").offset().top -70
        });
      }, 500);
    },
    validateForm(data) {
      let valid = true
      if(!data.id || !data.domain_name || !data.registrar || !data.website || !data.country || !data.domain_name || !data.purchase_date || !data.expiry_date || !data.purchase_approval_name || !data.renewal_approval_name) {
        valid = false
      }
      return valid
    },
    cancelUpdate(idx) {
      this.domain.domainlist = this.domain.domainlist.map((data, i) => {
        if(i === idx) {
          return { ...this.domain.prevdomainlist[idx], edit: false }
        }
        return data
      })
    },
    saveData(data) {
      let valid = this.validateForm(data)
      if(valid) {
        data.edit = false
        let apiurl = `api/domain/update`
        this.isLoading = true
        axios({
          method: 'POST',
          url: apiurl,
          data: {
            id: data.id,
            domain_name: data.domain_name,
            registrar: data.registrar,
            website: data.website,
            country: data.country,
            owner_name: data.owner_name,
            purchase_date: data.purchase_date,
            expiry_date: data.expiry_date,
            purchase_approval_name: data.purchase_approval_name,
            renewal_approval_name: data.renewal_approval_name,
            lastmodifiedby: this.undt.username,
            useremail  : this.undt.username,
            empcode: this.undt.userid,
            isactive: data.isactive,
            purchasequantity: data.purchasequantity,
            usedquantity: data.usedquantity,
          },
          headers: { authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          } else {
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
          this.displayError(e)
        })
      }
    },
    getSearchItems(search) {
        this.search.domain = search.domain;
        this.search.country = search.country;
        this.isSearchBoxActive = true;
        this.getAllDomainData(this.search);
    },
  }
}
</script>
<style scoped>
  .box-inc {
    min-width: 200px;
  }
</style>