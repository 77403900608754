<template>
    <div class="app-content content ">
      <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12">

              <h3 class="content-header-title float-left mb-1" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">View Appraisal Form</h3>
              <h3 class="content-header-title float-left mb-1" v-else>View Onboarding Form</h3>

              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">

                  <li class="breadcrumb-item sitebluecolor cursorpointercls" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'"><router-link :to="{ name: 'ListAppraisalCycle', params: { search: true }}">Appraisal Form List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" v-else><router-link :to="{ name: 'FeedbackFormListing', params: { search: true }}">Onboarding Form List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">{{view.moduletype}}</li>

                </ol>
              </div>
            </div>
          </div>
          <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
            <div>
              <div class="card-header py-2" style="cursor:pointer">
                  <div class="container-fluid">
                <div class="row">
                  <div class="col-md-5 mobile-padding-0-2">
                      <div class="avatar bg-light-primary rounded">
                      <div class="avatar-content">
                        <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                      </div>
                    </div>
                    <h3 class="card-title ml-2 d-inline-flex text-primary">{{view.moduletype}}</h3>
                  </div>
                  <div class="col-md-7 text-right">
    
                  </div>
                </div>
              </div>
            </div>
              <div class="row match-height">
                <div class="col-lg-4 col-md-6 col-12">
                  <div class="card card-transaction">
                    <div class="card-body mobile-padding-15-10">
                      
                      <div class="transaction-item" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <em class="las la-user font-medium-3"></em>
                                </div>
                            </div>
                            <div class="media-body mobile-padding-15-10">
                                <h6 class="transaction-title">Employee Name</h6><small class="capitalisetext" style="font-weight:500">{{view.assignee}}</small>
                            </div>
                        </div>
                      </div>
                      <div class="transaction-item" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">
                        <div class="media">
                            <div class="avatar bg-light-warning rounded">
                                <div class="avatar-content">
                                    <em class="las la-user-edit font-medium-3"></em>
                                </div>
                            </div>
                            <div class="media-body mobile-padding-15-10">
                                <h6 class="transaction-title">Employee Mail Id</h6><small class="" style="font-weight:500">{{ view.assigneemail }}</small>
                            </div>
                        </div>
                      </div>
                      <div class="transaction-item" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">
                        <div class="media">
                            <div class="avatar bg-light-primary rounded">
                                <div class="avatar-content">
                                    <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                                </div>
                            </div>
                            <div class="media-body mobile-padding-15-10">
                                <h6 class="transaction-title">Employee Id</h6><small class="capitalisetext" style="font-weight:500">{{ view.assigneeid }}</small>
                            </div>
                        </div>
                      </div>
                      <div class="transaction-item" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">
                        <div class="media">
                            <div class="avatar bg-light-warning rounded">
                                <div class="avatar-content">
                                    <em class="las la-calendar font-medium-5"></em>
                                </div>
                            </div>
                            <div class="media-body mobile-padding-15-10">
                                <h6 class="transaction-title">Date Of Joining</h6><small class="capitalisetext" style="font-weight:500">{{ dateMonthFormat(view.assigneedoj) }}</small>
                            </div>
                        </div>
                      </div>
                      <div class="transaction-item" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <em class="las la-users font-medium-5"></em> 
                                </div>
                            </div>
                            <div class="media-body mobile-padding-15-10">
                                <h6 class="transaction-title">Reporting Manager Mail ID</h6><small class="capitalisetext" style="font-weight:500">{{ view.supervisormailid ? view.supervisormailid==null || view.supervisormailid=='' || view.supervisormailid==' ' ?'NA' : view.supervisormailid:'NA' }}</small>
                            </div>
                        </div>
                      </div>

                      <div class="transaction-item" v-if="!masterroute">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <em class="las la-clipboard-list font-medium-5"></em>
                                </div>
                            </div>
                            <div class="media-body mobile-padding-15-10">
                                <h6 class="transaction-title">Status</h6>
                                <small class="capitalisetext text-success" style="font-weight:500">{{view.status}}</small>
                            </div>
                        </div>
                      </div>  
                    </div>                                                     
                  </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-12" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">
                  <div class="card card-transaction">
                    <div class="card-body mobile-padding-15-10">
                      <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-warning rounded">
                                <div class="avatar-content">
                                    <em class="las la-user-edit font-medium-3"></em>
                                </div>
                            </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Form Filled By Mail ID</h6>
                            <small>{{view.lastmodifiedby ? view.lastmodifiedby: view.createdby}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                          <div class="media">
                              <div class="avatar bg-light-primary rounded">
                                  <div class="avatar-content">
                                      <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                                  </div>
                              </div>
                              <div class="media-body">
                                <h6 class="transaction-title">Form Filled By Employee ID</h6>
                                <small>{{ view.formfilledbyid }}</small>
                              </div>
                          </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <em class="las la-clipboard-list font-medium-5"></em>
                                </div>
                            </div>
                            <div class="media-body mobile-padding-15-10">
                                <h6 class="transaction-title">Status</h6>
                                <small class="capitalisetext text-success" style="font-weight:500">{{view.status}}</small>
                            </div>
                        </div>
                      </div>
                    </div>                                                                
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12" v-if="masterroute && masterroute.moduletypes =='Appraisal Cycle'">
                  <div class="card card-transaction">
                    <div class="card-body mobile-padding-15-10">

                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              
                              <em class="las la-calendar font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Assigned By</h6>
                            <small>{{view.createdby.split('@')[0]}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                             <em class="las la-calendar font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Assigned Date</h6>
                            <small>{{dateMonthFormat(view.createddate) ? dateMonthFormat(view.createddate):'NA'}}</small>
                          </div>
                        </div>
                      </div>

                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                                  <div class="avatar-content">
                                      <em class="las la-user-edit font-medium-3"></em>
                                  </div>
                              </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Reviewers Mail ID</h6>
                            <small>{{ view.reviewersmailid }}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                          <div class="media">
                              <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                  <em class="las la-calendar font-medium-5"></em>
                              </div>
                              </div>
                              <div class="media-body">
                              <h6 class="transaction-title">Review Date</h6>
                              <small>{{dateMonthFormat(view.lastmodifieddate) ? dateMonthFormat(view.lastmodifieddate): dateMonthFormat(view.createddate)}}</small>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12" v-if="!masterroute">
                  <div class="card card-transaction">
                    <div class="card-body mobile-padding-15-10">

                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                            <div class="avatar-content">
                              
                              <em class="las la-calendar font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Created By</h6>
                            <small>{{view.createdby.split('@')[0]}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                             <em class="las la-calendar font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Created Date</h6>
                            <small>{{dateMonthFormat(view.createddate) ? dateMonthFormat(view.createddate):'NA'}}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12" v-if="!masterroute">
                  <div class="card card-transaction">
                    <div class="card-body mobile-padding-15-10">
                      <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-warning rounded">
                                  <div class="avatar-content">
                                      <em class="las la-user-edit font-medium-3"></em>
                                  </div>
                              </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Last Modified By</h6>
                            <small>{{view.lastmodifiedby ? view.lastmodifiedby.split('@')[0]: view.createdby}}</small>
                          </div>
                        </div>
                      </div>
                      <div class="transaction-item">
                          <div class="media">
                              <div class="avatar bg-light-success rounded">
                              <div class="avatar-content">
                                  <em class="las la-calendar font-medium-5"></em>
                              </div>
                              </div>
                              <div class="media-body">
                              <h6 class="transaction-title">Last Modified Date</h6>
                              <small>{{dateMonthFormat(view.lastmodifieddate) ? dateMonthFormat(view.lastmodifieddate): dateMonthFormat(view.createddate)}}</small>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content mb-2 d-inline-block w-100" >
            <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                    <div class="card card resolved rounded">
                        <div class="card-header" id="paymentOne"  role="button" data-target="#faq-payment-one" aria-expanded="false" aria-controls="faq-payment-one">
                            <span class="lead collapse-title">Form Details</span>
                        </div>
  
                        <div id="faq-payment-one"  aria-labelledby="paymentOne" data-parent="#faq-payment-qna">
                            <div class="card-body">
                                <div class="mb-1 px-0" >
                                    <div class="table-responsive" style="overflow-x: inherit !important;">
                                        <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                            <thead class="d-none"> <th scope="col"></th></thead>
                                            <tbody>
                                            <tr v-for="(v, i) in  view.queanslist" >
                                                <td class="text-left" style="min-width:200px;">
                                                {{v.fieldname}}
                                                </td>
                                                <td  class="text-left tast_case_groupingview_readmore">
                                                  <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                      <div class="custom-container case_bg_17">
                                                          <div class="outer" :id="'cycle_readmore'+i">
                                                              <span class="fc_m16_btcode_text" v-html="formatData(v.fieldvalue)"></span>
                                                          </div>
                                                          <div class="cycle_viewmoretext" v-if="v.fieldvalue.length > 150">
                                                              <a class="btn">
                                                              <strong :id="i" @click="idclick(i)">
                                                                  Read More
                                                              </strong>
                                                              </a>
                                                          </div>
                                                      </div>
                                                  </div>

                                                <!-- {{v.fieldvalue}} -->
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        
        
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  import apiUrl from '../../constants';
  import moment from "moment";
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select';
  import Treeselect from '@riophae/vue-treeselect';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { Trash2Icon,EditIcon, DownloadIcon, ZoomInIcon, MonitorIcon, PlusSquareIcon,EyeIcon, Edit2Icon, PlusIcon, MinusSquareIcon, ClipboardIcon, PenToolIcon, PocketIcon, CodepenIcon, UserIcon, CalendarIcon,WatchIcon, PaperclipIcon, SettingsIcon, UserPlusIcon, XIcon } from 'vue-feather-icons';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import profilepic from '../profilepic.vue';
  import VueTimepicker from 'vue2-timepicker';
  import 'vue2-timepicker/dist/VueTimepicker.css';
  import commonMethods from '../../utils/commonMethods';
  
  export default {
      name:"TaskList",
      components:{
        VueTimepicker,
        Loading,PlusSquareIcon,DownloadIcon,ZoomInIcon,Trash2Icon,XIcon,
        VueElementLoading,
        WatchIcon,
        vSelect,
        EditIcon,
        EyeIcon,
        Edit2Icon,
        Treeselect,
        DatePicker,
        PlusIcon,
        CalendarIcon,
        MinusSquareIcon,
        ClipboardIcon,
        UserPlusIcon,
        PenToolIcon, PocketIcon, CodepenIcon, UserIcon, PaperclipIcon, SettingsIcon,MonitorIcon,
        ckeditor: CKEditor.component,
        profilepic
      },
      mixins: [ commonMethods ],
      data() {
        return {
          isLoading: false,
          fullPage: true,
          isRequestActiveView: true,
          editRowData:[],
          TimeFormat: apiUrl.TimeFormat,
          view:{
            createddate : null,
            createdby : null,
            empid : null,
            trackerid : null, 
            moduletype : null,
            status : null, 
            lastmodifiedby : null, 
            lastmodifieddate : null, 
            queanslist : [],
            assignee: null,
            assigneeid: null ,
            assigneedoj: null,
            assigneemail: null,
            reviewersmailid: null,
            reviewersid: null,
            supervisormailid: null,
            formfilledbyid: null
          },
          isRequestActive:false,
          card_content:'card-content',
          collapse:'collapse',
          card:'card',
          border_warning:'border-warning',
          tokendata: null,
          loginpersonname:null,
          masterroute: null
        }
      },    
      mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata) {
            userdata = JSON.parse(userdata)
            this.undt = userdata
            this.tokendata = window.localStorage.getItem('token');
            if (this.$route.params.clickeddata) {
              this.masterroute =this.$route.params.clickeddata;
              this.getbyidtabledata(this.$route.params.clickeddata.id,this.$route.params.clickeddata.moduletypes,this.$route.params.clickeddata.reviewtaskmasterid);
            }else if(Object.values(this.$route.query).length==0){
                this.$router.push({ name: 'ListAppraisalCycle', params: { search: true }})
            }else{
              this.getbyidtabledata(this.$route.query.masterid);
            }
        }
      },
      
      methods:{
        idclick(indexx){
            if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                $("#"+indexx).html("Read More");
                $("#cycle_readmore"+indexx).removeClass("readmore");
            } else {
                $("#"+indexx).html("Read Less");
                $("#cycle_readmore"+indexx).addClass("readmore");
            }
        },
          redirecttoTask(rdata){
            let routeData = this.$router.resolve({ 
              path: `/tasks/view/?taskid=${rdata.taskmasterid}`,
              params: {
                clickeddata:rdata
              }
            })
            window.open(routeData.href, '_blank');
          },
          openEditModule(currRowData){
              this.view.createddate = currRowData[0].createddate;
              this.view.createdby = currRowData[0].createdby;
              this.view.empid = currRowData[0].empid;
              this.view.trackerid = currRowData[0].trackerid ; 
              this.view.moduletype = currRowData[0].moduletype;
              this.view.status = currRowData[0].status; 
              this.view.lastmodifiedby = currRowData[0].lastmodifiedby; 
              this.view.lastmodifieddate = currRowData[0].lastmodifieddate;
              this.view.assignee = currRowData[0].assignee;
              this.view.assigneeid = currRowData[0].assigneeid;
              this.view.assigneedoj = currRowData[0].assigneedoj;
              this.view.assigneemail = currRowData[0].assigneemail;
              this.view.reviewersmailid = currRowData[0].reviewersmailid;
              this.view.reviewersid = currRowData[0].reviewersid;
              this.view.supervisormailid= currRowData[0].supervisormailid;
              this.view.formfilledbyid = currRowData[0].formfilledbyid;
          },
          getbyidtabledata(id,type,rid) {
            this.isLoading = true;
            this.input = {
                  masterid: id,
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
                  moduletype: type,
                  empid: rid
              };
              axios({
                  method: "POST",
                  url: "api/feedbackform/view/formviewapi",
                  headers: { authorization : this.tokendata },
                  data: this.input
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.view.queanslist = result.data.data[0]
                      this.openEditModule(this.view.queanslist)
                  }else{
                      this.view.queanslist = [];
                  }
              }).catch(e => {
                    this.displayError(e)
              });
          },
          dateMONFormat(value){
              if (!value) return "";
              return moment(String(value)).format('DD-MM-YYYY HH:mm');
          },
          dateMonthFormat(value) {
              if (!value) return "";
              return moment(String(value)).format('DD MMM YYYY HH:mm');
          },
          dateFormat(value) {
              if (!value) return "";
              return moment(String(value)).format('DD-MM-YYYY');
          },
          formatData(data) {
              return data
          },
      },
      filters: {
      basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
      }
    }, 
    
  }
  </script>
  <style>
  
  .displayblk{
      display:block !important;
  }
  .createbtn{
      border-color: #053e52 !important;
      color: #fff !important;
      background-color: #008000 !important;
      padding: 0.386rem 0.5rem !important;
  }
  .tast_case_groupingview_readmore{
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.2px;
    color: #868686;
  }
  </style>
  