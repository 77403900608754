<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-wrapper">
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mysearchrequestollapse">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                    <div class="avatar-content">
                                                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                    </div>
                                                </div>
                                                <h4 class="card-title d-inline-flex ml-1">FinWise Accommodation Details</h4>
                                            </div>
                                           
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body mobile_only_min-height card_padding_10">
                                        <form>
                                            <div class="row">
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-4 mb-1 ">
                                                    <label class="form-label">Request ID </label>
                                                    <treeselect placeholder="Select Request ID" v-on:search-change="getTicketRequestDetails" v-model="request.requestid"  :options="requestmasterList" @input="selectrequestid" @blur="validateform(index, $event)" />
                                                    <div class="errorinputmsg" v-if="errors.request.requestid">{{ errors.request.requestid }}</div>
    
                                                </div>
                                            </div>
                                            
                                            <div v-if="closedata" class="row">
                                                <div class="col-12" style="margin-top:10px">
                                                <hr class="border_hr" />
                                                    <h5>Basic Details</h5>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Employee ID</label>
                                                    <input class="form-control" type="text" v-model.trim="request.empid" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Employee Name</label>
                                                    <input class="form-control" type="text" v-model.trim="request.empname" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Department</label>
                                                    <input class="form-control" type="text" v-model.trim="request.deptname" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Bill To Company (Brainbees / DARP)</label>
                                                    <input class="form-control" type="text" v-model.trim="request.billtocompany" readonly/>
                                                </div>
                                            </div>
                                            <hr  v-if="closedata" class="border_hr mt-2">
                                            <div class="row mx-0" v-for="(data,index) in travel" v-bind:key="'travel-row-'+index" >
                                                <div class="row" v-if="data.istravelrequired">
                                                <div class="col-12">
                                                    <div class="travel_ticket_details_bg02">
                                                    <h5 class="mb-0">Travel Ticket Details {{index+1}} :</h5>
                                                   </div>
                                                </div>
                                                <div class="ccol-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">From City</label>
                                                    <input class="form-control" type="text" v-model.trim="travel[index].fcityname" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">To City</label>
                                                    <input class="form-control" type="text" v-model.trim="travel[index].tcityname" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Mode of Travel</label>
                                                    <input class="form-control" type="text" v-model.trim="travel[index].modeoftravel" readonly/>
                                                </div>
    
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Booking Date</label>
                                                    <div class="input-group input-group-merge">
                                                        
                                                        <date-picker v-model="travel[index].bookingdate"  :disabled-date="notBeforeTodayTravel"  placeholder="Select Booking Date" value-type="format" @input="selbookdate" @blur="validatefields(index, $event)" class="w-100"></date-picker>
                                                        <div class="errorinputmsg" v-if="errors.travel[index].bookingdate">{{ errors.travel[index].bookingdate }}</div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Travel Date</label>
                                                    <input class="form-control" type="text" v-model="travel[index].traveldate" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Bill Number (Invoice Number)</label>
                                                    <input class="form-control" type="text"  placeholder="Enter Bill Number (Invoice Number)" v-model.trim="travel[index].billnumber" @blur="validatefields(index, $event)"/>
                                                    <div class="errorinputmsg" v-if="errors.travel[index].billnumber">{{ errors.travel[index].billnumber }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Booked From Vendor Name</label>
                                                    <treeselect placeholder="Select Booked From Vendor Name"  v-model="travel[index].vendorname"  :options="vendorList" @input="selectvendor" @blur="validatefields(index, $event)"/>
                                                    <div class="errorinputmsg" v-if="errors.travel[index].vendorname">{{ errors.travel[index].vendorname }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Bill Date</label>
                                                    <div class="input-group input-group-merge">
                                                        
                                                        <date-picker v-model="travel[index].billdate"  :disabled-date="notBeforeTodayTravel" placeholder="Select Bill Date" value-type="format" @input="selfromdate" @blur="validatefields(index, $event)" class="w-100"></date-picker>
                                                        <div class="errorinputmsg" v-if="errors.travel[index].billdate">{{ errors.travel[index].billdate }}</div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">PNR</label>
                                                    <input class="form-control" type="text" placeholder="Enter PNR Details" v-model.trim="travel[index].pnr" @blur="validatefields(index, $event)"/>
                                                    <div class="errorinputmsg" v-if="errors.travel[index].pnr">{{ errors.travel[index].pnr }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Fare Amount</label>
                                                    <input class="form-control" type="text" v-model.trim="travel[index].fareamt"  placeholder="Enter Fare Amount" @blur="validatefields(index, $event)" @keypress="restrictScoreValue($event,index,travel[index].fareamt)"/>
                                                    <div class="errorinputmsg" v-if="errors.travel[index].fareamt">{{ errors.travel[index].fareamt }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">GST Amount</label>
                                                    <input class="form-control" type="text" v-model.trim="travel[index].gst" placeholder="Enter GST Amount" @blur="validatefields(index, $event)" @keypress="restrictScoreValue($event,index,travel[index].gst)"/>
                                                    <div class="errorinputmsg" v-if="errors.travel[index].gst">{{ errors.travel[index].gst }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Total Payable Amount</label>
                                                    <input class="form-control" type="text" v-model.trim="travel[index].payable"  placeholder="Enter Total Payable Amount" @blur="validatefields(index, $event)" @keypress="restrictScoreValue($event,index,travel[index].payable)"/>
                                                    <div class="errorinputmsg" v-if="errors.travel[index].payable">{{ errors.travel[index].payable }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label for="lblattachment">Travel Invoice Attachment</label>
                                                    <div class="form-group">
                                                    <a href="#" @click="handledata(index,'travel')">
                                                    <label
                                                        for="attachment"
                                                        class="btn btn-sm btn-primary mb-75 mr-75"
                                                        >Select file docs</label
                                                    >
                                                    </a>
                                                    <button
                                                        class="btn btn-sm btn-primary mb-75 mr-75"
                                                        v-on:click.stop.prevent="onUpload(index,'travel')"
                                                    >
                                                        Upload
                                                    </button>
                                                    <input
                                                        type="file"
                                                        id="attachment"
                                                        hidden
                                                        multiple
                                                        ref="file"
                                                        @change="onFileChange"
                                                    />
                                                    <div class="table-responsive" v-if="travel[index].file.length >0">
                                                        <table aria-describedby="mydesc" class="table table-sm table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col">SR#</th>
                                                            <th scope="col">Document Name</th>
                                                            <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(image, key) in travel[index].file" :key="index+'_'+key">
                                                            <td>{{ key + 1 }}</td>
                                                            <td>{{ image.filename }}</td>
                                                            <td>
                                                                <button
                                                                type="button"
                                                                class="btn btn-sm danger"
                                                                @click="removeImage(key,index,'travel')"
                                                                >
                                                                <trash-2-icon
                                                                    size="1.5x"
                                                                    class="custom-class"
                                                                ></trash-2-icon>
                                                                </button>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <hr  v-if="closedata" class="border_hr mt-2"/>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="row mx-0" v-for="(data,index) in hotel" v-bind:key="'key-hotel-'+index">
                                                <div class="row" v-if="data.accomodation">
                                                <div class="col-12" style="margin-top:10px">
                                                    <h5>Accomodation Details {{index + 1}} :</h5>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Hotel Name</label>
                                                    <input class="form-control" type="text" v-model="hotel[index].hotelname" placeholder="Enter Hotel Name" @blur="validatefields(index, $event)"/>
                                                    <div class="errorinputmsg" v-if="errors.hotel[index].hotelname">{{ errors.hotel[index].hotelname }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Location</label>
                                                    <input class="form-control" type="text" v-model.trim="hotel[index].location"  @blur="validatefields(index, $event)" readonly/>
                                                    <div class="errorinputmsg" v-if="errors.hotel[index].location">{{ errors.hotel[index].location }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">CheckIn Date</label>
                                                    <input class="form-control" type="text" v-model="hotel[index].checkindate" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">CheckOut Date</label>
                                                    <input class="form-control" type="text" v-model.trim="hotel[index].checkoutdate" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Total Number Of Nights</label>
                                                    <input class="form-control" type="text" v-model.trim="hotel[index].noofnight" readonly/>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Booked From Vendor Name</label>
                                                    <treeselect placeholder="Select Booked From Vendor Name"  v-model="hotel[index].vendorname"  :options="vendorList" @input="selectvendor" @blur="validatefields(index, $event)"/>
                                                    <div class="errorinputmsg" v-if="errors.hotel[index].vendorname">{{ errors.hotel[index].vendorname }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Rate Per Date</label>
                                                    <input class="form-control" type="text" v-model.trim="hotel[index].rate" placeholder="Enter Rate Per Date" @blur="validatefields(index, $event)"  @keypress="restrictScoreValue($event,index,hotel[index].rate)"/>
                                                     <div class="errorinputmsg" v-if="errors.hotel[index].rate">{{ errors.hotel[index].rate }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Total Amount</label>
                                                    <input class="form-control" type="text" v-model.trim="hotel[index].totalamount" placeholder="Enter Total Amount" @blur="validatefields(index, $event)"  @keypress="restrictScoreValue($event,index,hotel[index].totalamount)"/>
                                                    <div class="errorinputmsg" v-if="errors.hotel[index].totalamount">{{ errors.hotel[index].totalamount }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3mb-1">
                                                    <label class="form-label">GST Amount</label>
                                                    <input class="form-control" type="text" v-model.trim="hotel[index].gst" placeholder="Enter GST Amount" @blur="validatefields(index, $event)"  @keypress="restrictScoreValue($event,index,hotel[index].gst)"/>
                                                    <div class="errorinputmsg" v-if="errors.hotel[index].gst">{{ errors.hotel[index].gst }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label for="lblattachment">Hotel Invoice Attachment</label>
                                                    <div class="form-group">
                                                    <a href="#" @click="handledata(index,'hotel')">
                                                    <label
                                                        for="attachment"
                                                        class="btn btn-sm btn-primary mb-75 mr-75"
                                                        >Select file docs</label
                                                    >
                                                    </a>
                                                    <button
                                                        class="btn btn-sm btn-primary mb-75 mr-75"
                                                        v-on:click.stop.prevent="onUpload(index,'hotel')"
                                                    >
                                                        Upload
                                                    </button>
                                                    <input
                                                        type="file"
                                                        id="attachment"
                                                        hidden
                                                        multiple
                                                        ref="file"
                                                        @change="onFileChange"
                                                    />
                                                    <div class="table-responsive" v-if="hotel[index].file.length >0">
                                                        <table aria-describedby="myDocsdesc" class="table table-sm table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col">SR#</th>
                                                            <th scope="col">Document Name</th>
                                                            <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(image, key) in hotel[index].file" :key="'key-hotel-doc-'+key">
                                                            <td>{{ key + 1 }}</td>
                                                            <td>{{ image.filename }}</td>
                                                            <td>
                                                                <button
                                                                type="button"
                                                                class="btn btn-sm danger"
                                                                @click="removeImage(key,index,'hotel')"
                                                                >
                                                                <trash-2-icon
                                                                    size="1.5x"
                                                                    class="custom-class"
                                                                ></trash-2-icon>
                                                                </button>
                                                            </td>
                                                            </tr>
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Total Payable Amount</label>
                                                    <input class="form-control" type="text" v-model.trim="hotel[index].totalpayable" placeholder="Enter Total Payable Amount" @blur="validatefields(index, $event)"  @keypress="restrictScoreValue($event,index,hotel[index].totalpayable)"/>
                                                    <div class="errorinputmsg" v-if="errors.hotel[index].totalpayable">{{ errors.hotel[index].totalpayable }}</div>
                                                </div>
    
                                                <div class="col-12">
                                                    <hr  v-if="closedata" class="border_hr mt-2"/>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="closedata">
                                                <div class="col-12" style="margin-top:10px">
                                                    <h5>Transaction Details :</h5>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Approved By</label>
                                                    <input class="form-control" placeholder="Enter Approved By Details" type="text" v-model.trim="request.approvedby" @blur="validatefields(0, $event)"/>
                                                    <div class="errorinputmsg" v-if="errors.approvedby">{{ errors.approvedby }}</div>
                                                </div>
                                                <div class="col-xs-12 col-sm-6  col-md-6 col-lg-3 mb-1">
                                                    <label class="form-label">Booked By</label>
                                                    <input class="form-control" type="text" placeholder="Enter Booked By Details" v-model.trim="request.bookedby" @blur="validatefields(0, $event)"/>
                                                    <div class="errorinputmsg" v-if="errors.bookedby">{{ errors.bookedby }}</div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="row">
                                             <div class="col-12 text-right">
                                               
                                                <button type="button" class="btn btn-primary ml-50 mt-50" v-on:click.stop.prevent="saveAccountDetails">Submit</button>
                                                 <button type="button" class="btn btn-secondary ml-50 mt-50" v-on:click="resetForm">Cancel</button>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import apiUrl from '../../../constants';
    import { SearchIcon,PlusIcon,Trash2Icon } from 'vue-feather-icons'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import DatePicker from 'vue2-datepicker';
    export default({
        name:'FinWise',
        
        data() {
            return{
                tokendata:null,
                isSearchRequestActive:true,
                card_content:'card-content',
                collapse:'collapse',
                isLoading: false,
                fullPage: true,
                username:null,
                requestmasterList:null,
                vendorList:null,
                closedata:null,
                hotellength:0,
                travellength:0,
                rowid:0,
                traveltype:'',
                request:{
                    requestid:null,
                    empid:'',
                    empname:'',
                    deptname:'',
                    approvedby:'',
                    bookedby:'',
                    billtocompany:'',
                    companyid:'',
                    empcode:'',
                    bookingdate:''
                },
                file:[],
                hotel:[],
                travel:[],
                errors:{
                    hotel:[
                        {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,accomodation:null },
                        {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,accomodation:null },
                        {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,accomodation:null },
                        {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,accomodation:null },
                        {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,accomodation:null },
                        {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,accomodation:null }
                    ],
                    travel:[
                        {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,istravelrequired:null},
                        {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,istravelrequired:null},
                        {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,istravelrequired:null},
                        {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,istravelrequired:null},
                        {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,istravelrequired:null},
                        {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,istravelrequired:null},
                    ],
                    request:{
                        requestid:null,
                    }
                },
                billtocompanylist:[{
                    id:'1',
                    label:'Brainbees'
                    },{
                        id:'2',
                        label:'DARP'
                    }
                ],
    
            } 
        } ,
       
    
        components: {
            SearchIcon,
            Loading,
            PlusIcon,
            Treeselect,
            DatePicker,
            Trash2Icon
        },
        mounted(){
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.empid = userdata.userid
                this. getTicketList()
            }
            
        },
        
       methods:{
            fulldateFormat(value) {
                if (!value) return "";
                return moment(String(value)).format('YYYY-MM-DD HH:MM:ss');
            },
            dateFormat(value) {
                if (!value) return "";
                return moment(String(value)).format('YYYY-MM-DD');
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },
            removeImage(index,row,type) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to delete the file?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then(async(result) => {
                   if (result.isConfirmed) {
                    if (this.travel.length > 0 && type =='travel') {
                        if(this.travel[row].file.length >0) {
                            this.travel[row].file.splice(index, 1);
                            this.file.splice(index, 1);
                        }                    
                    }else if (this.hotel.length > 0 && type =='hotel') {
                        if(this.hotel[row].file.length > 0) {
                            this.hotel[row].file.splice(index, 1);
                            this.file.splice(index, 1);
                        }                    
                    }    
                   }
                });
                
            },
            handledata(rowid,type) {
                this.rowid =rowid
                this.traveltype = type
            },
            onFileChange(e) {
                if(this.traveltype==='hotel' && e.target.files.length >0) {
                    let tmpFiles = e.target.files;
                    let tmpstore = [];
                    for (let index = 0; index < tmpFiles.length; index++) {
                        tmpstore.push({path: '',filename: tmpFiles[index].name,description:''});
                    }
                    this.hotel[this.rowid].file= [...tmpstore];
                } else if(this.traveltype==='travel' && e.target.files.length >0) {
                    let tmpFiles = e.target.files;
                    let tmpstore = [];
                    for (let index = 0; index < tmpFiles.length; index++) {
                        tmpstore.push({path: '',filename: tmpFiles[index].name,description:''});
                    }
                    this.travel[this.rowid].file=[...tmpstore];
                }
                var selectedFiles = e.target.files;
                this.file =[]
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.file.push(selectedFiles[i]);
                }
            },
            onUpload(ikey,type) {
                if (this.file) {
                    if (this.file.name !== "") {
                    this.isLoading = true;
                    this.apiURL = "api/bulkfileupload";
                    let formData = new FormData();
                    let maxsize = 0;
                    for (var index = 0; index < this.file.length; index++) {
                        formData.append("imagefile", this.file[index]);
                        maxsize+= this.file[index].size
                    }
                    formData.append("empcode", this.request.empcode);
                    formData.append("moduletype", "finwise");
                    formData.append("fcount", parseInt(this.file.length));
                    if (maxsize > 1024 * 1024 * 10) {
                        this.isLoading = false;
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 10mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false; 
                    }
                    axios({
                        "content-type": "multipart/form-data",
                        method: "POST",
                        url: this.apiURL,
                        data: formData,
                    }).then((result) => {
                        this.isLoading = false;
                        this.file=[];
                        if (result.data.status) {
                        this.disblesavebtn = false;
                        const bindinfo = result.data.data.map((file, index) => {
                            let fn=file.substring(file.lastIndexOf('/')+1);
                            return { path: file,filename: fn,description:''};
                        });
                        if(type==='hotel') {
                            this.hotel[ikey].file= [...bindinfo];
                        } else if(type==='travel') {
                            this.travel[ikey].file=[...bindinfo];
                        }
                        
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: "success",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        });
                        } else {
                        this.isLoading = false;
                            Swal.fire({
                                title: "Oops...!",
                                text: result.data.msg,
                                icon: 'error',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            this.attachment=null
                        }
                    });
                    } else {
                        this.file=[];
                    this.isLoading = false;
                    Swal.fire({
                        title: "Oops...!",
                        text: "Select file docs",
                        icon: "error",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    this.attachment = null;
                    }
                } else {
                    this.file=[];
                    this.isLoading = false;
                    Swal.fire({
                    title: "Oops...!",
                    text: "Select file docs",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    });
                    this.attachment = null;
                }
            },
            selectrequestid(state,value){
                this.request.requestid=null
                this.request.requestid=state
                if(state){
                    this.getTicketRequestDetails()
                }
                else{
                    this.errors.request.requestid=null
                }
            },
            notBeforeTodayTravel: function(date) {
                this.request.billdate = '';
                return date > new Date(new Date().setHours(0, 0, 0, 0));
            },
            selfromdate(state,value){
                this.request.billdate=null
                this.request.billdate=state
            },
            selbookdate(state,value){
                this.request.bookingdate=null
                this.request.bookingdate=state
            },
            selectvendor(state,value){
                this.request.vendorname=null
                this.request.vendorname=state
            },
            getTicketList(){
                this.isLoading= true;
                axios({
                    'method': 'POST',
                    'url': 'api/finwise/getclosedtickets',
                    'data':{empid:null,empcode:this.empid,useremail: this.username},
                    headers: { authorization: this.tokendata },
                })
                .then(result => {  
                    this.isLoading= false;           
                    if(result.data.status){
                        this.requestmasterList = result.data.data;
                        
                    }else{
                        this.requestmasterList = []
                    }
                })
            },
            getVendorList(){
                this.isLoading= true;
                axios({
                    'method': 'POST',
                    'url': 'api/finwise/getvendorList',
                    'data':{vmid:null,companyid:this.request.companyid,empcode:this.empid,useremail: this.username},
                    headers: { authorization: this.tokendata },
                })
                .then(result => {  
                    this.isLoading= false;           
                    if(result.data.status){
                        this.vendorList = result.data.data;
                        
                    }else{
                        this.vendorList = []
                    }
                })
            },
            getTicketRequestDetails(){
                this.isLoading= true;
                
                axios({
                    'method': 'POST',
                    'url': 'api/finwise/getclosedticketsDetails',
                    'data':{ticketid: this.request.requestid, empcode:this.empid,useremail: this.username},
                    headers: { authorization: this.tokendata },
                })
                .then(result => {      
                    this.resetForm();    
                    this.isLoading= false;   
                    if(result.data.status){
                        this.closedata = result.data.data;
                        if(this.closedata && this.closedata[0]){
                            this.request.empid=this.closedata[0].empid
                            this.request.empcode=this.closedata[0].empcode
                            this.request.empname=this.closedata[0].empname
                            this.request.deptname=this.closedata[0].department
                            this.request.requestid = this.closedata[0].ticketid
                            this.request.billtocompany = this.closedata[0].reqcompanyname
                            this.request.companyid = this.closedata[0].companyid
                            this.getVendorList()
                            
                            let len =0
                            let tlen =0
                            this.hotel=[]
                            this.travel=[]
                            for(let i=0;i<this.closedata.length;i++){
                                let hotelArr = this.closedata[i]
                                let travelArr = this.closedata[i]
                                if( hotelArr.accomodation){
                                    let hotedetails ={
                                        'id' : hotelArr.ticketid,
                                        'hotelname':null,
                                        'checkindate' : hotelArr.checkindate?this.fulldateFormat(hotelArr.checkindate):'',
                                        'checkoutdate' : hotelArr.checkoutdate?this.fulldateFormat(hotelArr.checkoutdate):'',
                                        'location' : hotelArr.tocityname,
                                        'noofnight' : hotelArr.noofnight,
                                        'accomodation' : hotelArr.accomodation,
                                        'vendorname':null,
                                        'rate': null,
                                        'totalamount': null,
                                        'gst': null,
                                        'totalpayable': null,
                                        'file':[],
                                        'fk_td': hotelArr.id,
                                    }
                                    
                                    len ++
                                    this.hotel.push(hotedetails)
                                }else{
                                    
                                }
                                
                                if(travelArr.fromcityname){
                                    if(travelArr.istravelrequired){
                                        tlen++
                                        
                                    
                                        let travelDet ={
                                            'id' : travelArr.ticketid,
                                            'fcityname' : travelArr.fromcityname,
                                            'tcityname' : travelArr.tocityname,
                                            'modeoftravel' : travelArr.modeoftransport,
                                            'traveldate' : travelArr.traveldate,
                                            'istravelrequired' : travelArr.istravelrequired,
                                            'bookingdate':null,
                                            'billnumber':null,
                                            'vendorname':null,
                                            'billtocompany':null,
                                            'billdate':null,
                                            'pnr':null,
                                            'fareamt':null,
                                            'gst':null,
                                            'payable':null,
                                            'file': [],
                                            'fk_td': hotelArr.id,
                                        }
                                        this.travel.push(travelDet)
                                    }
                                }
                                
                            }
                            this.hotellength = len
                            this.travellength = tlen
                        }
                    }else{
                        this.closedata = []
                    }
                })
            },
            resetForm(){
                this.closedata=null
                this.request.requestid=null,
                this.request.empid='',
                this.request.empname='',
                this.request.deptname='',
                this.request.approvedby='',
                this.request.bookedby='',
                this.errors.approvedby='',
                this.errors.bookedby='',
                this.hotel=[
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,file:[],fk_td:null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,file:[],fk_td:null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,file:[],fk_td:null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,file:[],fk_td:null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,file:[],fk_td:null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null,file:[],fk_td:null }
                ],
                this.travel=[
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,file:[],fk_td:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,file:[],fk_td:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,file:[],fk_td:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,file:[],fk_td:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,file:[],fk_td:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null,file:[],fk_td:null}
                ]
    
                this.errors.hotel=[
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null },
                    {id:null, hotelname:null, checkindate:null, checkoutdate:null, location:null,noofnight:null, vendorname:null, rate: null, totalamount: null,gst: null, totalpayable: null }
                    ],
                this.errors.travel=[
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null},
                    {fcityname:null,tcityname:null,modeoftravel:null,bookingdate:null,traveldate:null,billnumber:null,vendorname:null,billtocompany:null,billdate:null,pnr:null,fareamt:null,gst:null,payable:null}
                ]
            },
            restrictScoreValue($event,index,input) {
                const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
                // only allow number and one dot
                if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || input.indexOf('.') !== -1)) { // 46 is dot
                    $event.preventDefault()
                }
                // restrict to 1 decimal place
                if (input !== null && input.indexOf('.') > -1 && (input.split('.')[1].length > 1)) {
                    $event.preventDefault()
                }
            },
            validatefields(index){
                let valid=true
                if(!this.request.bookedby) {
                    this.errors.bookedby = `Booked by is required`
                    valid = false
                } else {
                    this.errors.bookedby = ''
                }
                if(!this.request.approvedby) {
                    this.errors.approvedby = `Approved by is required`
                    valid = false
                } else {
                    this.errors.approvedby = ''
                }
                
                if(this.travel.length>0) {
                if(!this.travel[index].bookingdate) {
                    this.errors.travel[index].bookingdate = `Booking date  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].bookingdate = ''
                }
                if(!this.travel[index].billnumber) {
                    this.errors.travel[index].billnumber = `Bill number  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].billnumber = ''
                }
    
                if(!this.travel[index].vendorname) {
                    this.errors.travel[index].vendorname = `Booked from vendor name  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].vendorname = ''
                }
    
                if(!this.travel[index].billdate) {
                    this.errors.travel[index].billdate = `Bill date  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].billdate = ''
                }
    
                if(!this.travel[index].pnr) {
                    this.errors.travel[index].pnr = `PNR details  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].pnr = ''
                }
    
                if(!this.travel[index].gst) {
                    this.errors.travel[index].gst = `GST amount  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].gst = ''
                }
    
                if(!this.travel[index].fareamt) {
                    this.errors.travel[index].fareamt = `Fare amount  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].fareamt = ''
                }
    
                if(!this.travel[index].payable || this.travel[index].payable<=0) {
                    this.errors.travel[index].payable = `Total payable  ${index+1} required`
                    valid = false
                } else {
                    this.errors.travel[index].payable = ''
                }
                if(this.hotel.length>0) {
                if(!this.hotel[index].hotelname) {
                    this.errors.hotel[index].hotelname = `Hotel Name  ${index+1} required`
                    valid = false
                } else {
                    this.errors.hotel[index].hotelname = ''
                }
                
                if(!this.hotel[index].totalpayable || this.hotel[index].totalpayable <=0) {
                    this.errors.hotel[index].totalpayable = `Total payable  ${index+1} required`
                    valid = false
                } else {
                    this.errors.hotel[index].totalpayable = ''
                }
    
                if(!this.hotel[index].gst) {
                    this.errors.hotel[index].gst = `GST amount  ${index+1} required`
                    valid = false
                } else {
                    this.errors.hotel[index].gst = ''
                }
    
                if(!this.hotel[index].totalamount || this.hotel[index].totalamount<=0) {
                    this.errors.hotel[index].totalamount = `Total amount  ${index+1} required`
                    valid = false
                } else {
                    this.errors.hotel[index].totalamount = ''
                }
    
                if(!this.hotel[index].rate) {
                    this.errors.hotel[index].rate = `Rate  ${index+1} required`
                    valid = false
                } else {
                    this.errors.hotel[index].rate = ''
                }
    
                if(!this.hotel[index].vendorname) {
                    this.errors.hotel[index].vendorname = `vendorname  ${index+1} required`
                    valid = false
                } else {
                    this.errors.hotel[index].vendorname = ''
                }
                }
                }
                return valid;
            },
            validateform(){
                let valid=true
                if(!this.request.bookedby) {
                    this.errors.bookedby = `Booked by is required`
                    valid = false
                } else {
                    this.errors.bookedby = ''
                }
                if(!this.request.approvedby) {
                    this.errors.approvedby = `Approved by is required`
                    valid = false
                } else {
                    this.errors.approvedby = ''
                }
                if(!this.request.requestid) {
                    this.errors.request.requestid = `Request ID is required`
                    valid = false
                } else {
                    this.errors.request.requestid = ''
                }
                
                for(let index =0;index<this.travel.length;index++){
                    if(!this.travel[index].bookingdate) {
                        this.errors.travel[index].bookingdate = `Booking date  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].bookingdate = ''
                    }
    
                    if(!this.travel[index].billnumber) {
                        this.errors.travel[index].billnumber = `Bill number  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].billnumber = ''
                    }
    
                    if(!this.travel[index].vendorname) {
                        this.errors.travel[index].vendorname = `Booked from vendor name  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].vendorname = ''
                    }
    
                    if(!this.travel[index].billdate) {
                        this.errors.travel[index].billdate = `Bill date  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].billdate = ''
                    }
    
                    if(!this.travel[index].pnr) {
                        this.errors.travel[index].pnr = `PNR details  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].pnr = ''
                    }
    
                    if(!this.travel[index].gst) {
                        this.errors.travel[index].gst = `GST amount  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].gst = ''
                    }
    
                    if(!this.travel[index].fareamt) {
                        this.errors.travel[index].fareamt = `Fare amount  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].fareamt = ''
                    }
    
                    if(!this.travel[index].payable || this.travel[index].payable<=0) {
                        this.errors.travel[index].payable = `Total payable  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.travel[index].payable = ''
                    }
                }
                for(let index =0;index<this.hotel.length;index++){
                    if(!this.hotel[index].hotelname) {
                        this.errors.hotel[index].hotelname = `Hotel Name  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.hotel[index].hotelname = ''
                    }
                    
                    if(!this.hotel[index].totalpayable || this.hotel[index].totalpayable<=0) {
                        this.errors.hotel[index].totalpayable = `Total payable  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.hotel[index].totalpayable = ''
                    }
    
                    if(!this.hotel[index].gst ) {
                        this.errors.hotel[index].gst = `GST amount  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.hotel[index].gst = ''
                    }
    
                    if(!this.hotel[index].totalamount || this.hotel[index].totalamount<=0) {
                        this.errors.hotel[index].totalamount = `Total amount  ${index+1} should be greater than 0`
                        valid = false
                    } else {
                        this.errors.hotel[index].totalamount = ''
                    }
    
                    if(!this.hotel[index].rate) {
                        this.errors.hotel[index].rate = `Rate  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.hotel[index].rate = ''
                    }
    
                    if(!this.hotel[index].vendorname) {
                        this.errors.hotel[index].vendorname = `Rate  ${index+1} required`
                        valid = false
                    } else {
                        this.errors.hotel[index].vendorname = ''
                    }
                }
                return valid;
            },
            saveAccountDetails(){
                this.isValid = this.validateform()
                if(this.isValid){
                    if((this.travel.length >0 || this.hotel.length >0) && this.request.requestid){
                        this.travelobj=[]
                        this.hotelobj=[]
                        for(let i=0;i<this.hotel.length;i++){
                            if(this.hotel[i].checkindate){
                                this.hotelobj.push({
                                    ticketid:this.request.requestid,
                                    hotelname:this.hotel[i].hotelname,
                                    location:this.hotel[i].location,
                                    checkindate:this.hotel[i].checkindate,
                                    checkoutdate:this.hotel[i].checkoutdate,
                                    noofnights:this.hotel[i].noofnight,
                                    vendorid:this.hotel[i].vendorname,
                                    rateperday:this.hotel[i].rate,
                                    totalamount:this.hotel[i].totalamount,
                                    gst:this.hotel[i].gst,
                                    totalpayable:this.hotel[i].totalpayable,
                                    invoices: this.hotel[i].file.every((p)=> {return p.path!==''}) ? this.hotel[i].file:[],
                                    fk_tdid:this.hotel[i].fk_td
                                })
                            }
                        }
                        for(let i=0;i<this.travel.length;i++){
                            if(this.travel[i].fcityname){
                                this.travelobj.push({
                                    ticketid:this.request.requestid,
                                    fromcity:this.travel[i].fcityname,
                                    tocity:this.travel[i].tcityname,
                                    modeoftravel:this.travel[i].modeoftravel,
                                    bookingdate:this.travel[i].bookingdate,
                                    traveldate:this.travel[i].traveldate,
                                    billnumber:this.travel[i].billnumber,
                                    vendorid:this.travel[i].vendorname,
                                    companyid:this.request.companyid,
                                    billdate:this.travel[i].billdate,
                                    pnr:this.travel[i].pnr,
                                    fareamt:this.travel[i].fareamt,
                                    gst:this.travel[i].gst,
                                    totalpayable:this.travel[i].payable,
                                    invoices:this.travel[i].file.every((p)=> {return p.path!==''}) ? this.travel[i].file:[],
                                    fk_tdid:this.travel[i].fk_td
                                })
                            }
                        }
                        this.input ={
                            ticketid:this.request.requestid,
                            empid:this.request.empid,
                            empname:this.request.empname,
                            dept:this.request.deptname,
                            approvedby:this.request.approvedby,
                            bookedby:this.request.bookedby,
                            useremail:this.username,
                            hotelobj:this.hotelobj,
                            travelreq:this.travelobj,
                            empcode:this.empid
                        }
                        this.isLoading= true;
                        axios({
                            'method': 'POST',
                            'url': 'api/finwise/save',
                            'data':this.input,
                            headers: { authorization: this.tokendata },
                        })
                        .then(result => {  
                            this.isLoading= false;           
                            if(result.data.status){
                                this.resetForm()
                                this.getTicketList()
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }else{
                            Swal.fire({
                                    title: "",
                                    text: result.data.msg,
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                        })
                    }
                }
            }
       }
    })
    </script>
    
    