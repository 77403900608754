<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-2 pt-1 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0 ">
                <h3 class="content-header-title float-left mb-0">Document Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/document/list'>Document Management</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body">
          <section id="dashboard-ecommerce">
            <div class="row match-height dshboard_margin_x">
              <div class="col-lg-12 col-12 dshboard_padd_x">
                <div class="row match-height dashTiles ">
                  <div class="col-lg-3 col-md-3 col-6" style="cursor:pointer" v-on:dblclick="redirecttourl('techpacks')">
                      <div class="card card_bottom_line_animation bottom_bg_bor3">
                        <div class="card-header">
                          <div class="top_header_card_width">
                            <h2 class="font-weight-bolder mb-0">
                              TECHPACKS
                            </h2>
                          </div>
                          <div class="dashboard_card_top">
                            <div class="avatar bg-light-primary p-50 m-0">
                              <div class="avatar-content">
                                <em class="las la-folder line_font_size"></em>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-6" style="cursor:pointer" v-on:dblclick="redirecttourl('artworks')">
                      <div class="card card_bottom_line_animation bottom_bg_bor3">
                        <div class="card-header">
                          <div class="top_header_card_width">
                            <h2 class="font-weight-bolder mb-0">
                              ARTWORKS
                            </h2>
                          </div>
                          <div class="dashboard_card_top">
                            <div class="avatar bg-light-primary p-50 m-0">
                              <div class="avatar-content">
                                <em class="las la-folder line_font_size"></em>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2'
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon, EyeIcon, ZoomInIcon, DownloadIcon } from "vue-feather-icons";

export default {
  name: 'DocumentMaster',
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    DatePicker,
    PlusCircleIcon,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    Pagination,
    SearchIcon,
    ExternalLinkIcon,
    XIcon,
    EyeIcon,
    ZoomInIcon,
    DownloadIcon,
  },
  data() {
    return {
      isLoading: false,
      undt: null
    }
  },
  mounted() {
    // let permission = this.$Service.verifyAccess();
    // if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
      }
    // }
  },
  methods: {
    hell() {
      console.log('sdkflj')
    },
    redirecttourl(folder) {
      this.$router.push({ 
        path: `/document/list?folder=${folder}`,
        params: {
          backbtnstate:folder,
        }
      });
    },
  }
}
</script>
<style scoped>
</style>