<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header" style="cursor:pointer" v-on:click="mysearchrequestollapse">

                  <div class="row" style="width:100%">
                    <div class="col-md-2">
                        <div class="avatar bg-light-secondary rounded">
                            <div class="avatar-content">
                                <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9" style="margin-left:-130px;margin-top:5px;">
                        <h4 class="card-title">Search Project Details</h4>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getprojectlist(search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span> Search</span></button>>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2 mr-1" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-body">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div
                                class="card-body"
                                style="
                                  padding-left: 0px !important;
                                  padding-top: 0px !important;
                                "
                              >
                                <div class="row">
                                  <div class="col-md-10">
                                    <div class="input-group-append">
                                      <h4 class="card-title">
                                        Employee Tatal Overview
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="table-responsive"
                                style="overflow-x: scroll !important;margin-top:-25px;"
                                v-if="showtabledata"
                              >
                                <table class="table table-bordered table-sm dataTableListing" aria-describedby="Employee Tatal Overview">
                                  
                                  <thead>
                                    <tr>
                                      <th scope="col">Empid</th>
                                      <th scope="col">Emp Name</th>
                                      <th scope="col">Designation</th>
                                      <th scope="col">Reporting Manager</th>
                                      <th scope="col">Total Exp</th>
                                      <th scope="col">FC Exp</th>
                                      <th scope="col">Total Project Count</th>
                                      <th scope="col">Total Task Count</th>
                                      <th scope="col">Red Task Count</th>
                                      <th scope="col">Green Task Count</th>
                                      <th scope="col">Blue Task Count</th>
                                      <th scope="col">Total Issues Count</th>
                                      <th scope="col">Total Closed Issues Count</th>
                                      <th scope="col">Total Open Issues Count</th>
                                      <th scope="col">Total Incident Count</th>
                                      <th scope="col">Total Closed Incident Count</th>
                                      <th scope="col">Total Open Incident Count</th>
                                      <th scope="col" >Total Code Line Count</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(data, index) in companymasterlist"
                                    >
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)">                                        
                                        <td :class="'uppercasetext unique'+index+1+currPageNum*pagelimit">
                                          {{data.empid}}
                                        </td>
                                        <td class="capitalisetext">{{data.empname}}</td>
                                        <td class="capitalisetext">
                                          {{data.designation}}
                                        </td>
                                        <td>
                                          {{data.reportingmanager}}
                                        </td>
                                        <td v-if="data.totalexpyear && data.totalexpmonth">
                                          {{data.totalexpyear}}.{{data.totalexpmonth}} yrs
                                        </td>
                                        <td v-if="!data.totalexpyear && !data.totalexpmonth">
                                          -
                                        </td>
                                        <td v-if="data.totalexpyear && !data.totalexpmonth">
                                            {{data.totalexpyear}}yrs
                                        </td>
                                        <td v-if="!data.totalexpyear && data.totalexpmonth">
                                            {{data.totalexpyear}}months
                                        </td>
                                        <td v-if="data.fcexpyear && data.fcexpmonth">
                                            {{data.fcexpyear}}.{{data.fcexpmonth}} yrs
                                        </td>
                                        <td v-if="!data.fcexpyear && !data.fcexpmonth">
                                            -
                                        </td>
                                        <td v-if="data.fcexpyear && !data.fcexpmonth">
                                            {{data.fcexpyear}}yrs
                                        </td>
                                        <td v-if="!data.fcexpyear && data.fcexpmonth">
                                            {{data.fcexpmonth}}months
                                        </td>
                                        <td>{{data.projectcount}}</td>
                                        <td>                                          
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>                                          
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>                                          
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>                                          
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            class="dropdown-item"
                                            @click="
                                              redirecttourl('view', data)
                                            "
                                            href="javascript:void(0);"
                                          >
                                          {{data.fcexp}}
                                          </a>
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,ExternalLinkIcon,XIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    Pagination,
    XIcon,
    ExternalLinkIcon,
  },
  mixins: [ commonMethods ],

  /* directives: {
        Ripple,
    }, */
  data() {
    return {
      project: {
        name: null,
        desc: null,
        projecttype: null,
        projecttypelist: [
          {
            id: "",
            label: "",
          },
        ],
        businesslist: [
          {
            id: "",
            label: "",
          },
        ],
        companylist: [
          {
            id: "",
            label: "",
          },
        ],
      },
      search: {
        searchCompany: null,
        createdbyOpts: [],
        createdby: null,
        searchProjectname:null,
        companyname: null,
        businessname: null,
        companynameList: [],
        businessnameList: [],
      },
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      stylevariation: apiUrl.stylevariation,
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      projectcurrentstate: "local",
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      tokendata:null,
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      this.getApplicationdata("t1", "project-tab");
      this.getProjectCreatebyList()
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.getprojectlist(rowdata);
      }
    }
  },
  methods: {
    getCompanyCreatedList: function () {
      axios
        .post("api/listing/getCreatedByList", { tbl: "t1",useremail: this.undt.username,empcode: this.undt.userid })
        .then((result) => {
          if (result.data.errorCode == 0) {
            this.search.createdbyOpts = result.data.data;
          }
        }).catch(e => {
        this.displayError(e)
      });
    },
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
        companymasterid: this.search.companyname,
        businessmasterid: this.search.businessname,
        empcode: this.undt.userid,
      }
      axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          if(result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = null
          }
        }).catch(e => {
        this.displayError(e)
      })
    },
    selectcompany(state, value) {
      this.search.companyname = state

      if(!state) {
        this.projectnameList = null
        if(this.search.businessname) {
          this.getDropdownApplicationdata('t11')
        }
      } else {
        this.getDropdownApplicationdata('t11')
      }

    },
    selectbusiness(state, value) {
      this.search.businessname = state
      if(!state) {
        this.projectnameList = null
        if(this.search.companyname) {
          this.getDropdownApplicationdata('t11')
        }
      } else {
        this.getDropdownApplicationdata('t11')
      }
    },
    selectproject(state, value) {
      this.pageoffset=1
      this.search.searchProjectname = state;
    }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    getProjectCreatebyList(){
      this.isLoading = true;
      let url = "api/searching/getProjectCreatebyList";
      this.input={projectid:0,useremail: this.undt.username,empcode: this.undt.userid,}
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.createdbyOpts = result.data.data;
        } else {
          this.createdbyOpts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      let redirectmdlnm='Project Module'
      let redirectmdlurl='/projectmodule'
      if(btnstate == 'addapplication'){
        redirectmdlnm='Project Application'
        redirectmdlurl='/application'
      }
      this.$router.push({
        name: redirectmdlnm,
        path: redirectmdlurl,
        params: {
          backbtnstate: btnstate,
          clickeddata: rowdata,
        },
      });
    },
    getFilteredData: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "company",
        isFilterSelect: 1,
        isFilterPayload: {},
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };

      if (this.search.searchCompany != null) {
        this.input.isFilterPayload.companyid = this.search.searchCompany;
      } 
      if (this.search.createdby != null) {
        this.input.isFilterPayload.createdby = this.search.createdby;
      }

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.showcompanies = true
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
      let rowdata={}
      this.getprojectlist(rowdata);
      this.search.searchProjectname = null;
      this.search.createdby = null;
      this.search.companyname = null;
      this.search.businessname = null;
    },
    getcomapnylist: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "project",
        isFilterSelect: 0,
        isFilterPayload: {},
        useremail: this.undt.username,empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getprojectlist(rowdata) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        uniqueid: this.undt.userid,
        isFilterSelect: 0,
        isFilterPayload: rowdata,
        empcode: this.undt.userid,
      };
      let url = "api/reports/employeetotaloverview";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.project.company = this.activecompanyid;          
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.project.company = this.activecompanyid;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    setSelected(value) {
      this.selected = value;
    },
    masterlist(mdl, objThis) {
      let setmoduleform = "projectmaster";
      let glbMdlLblval = "Project";
      if (this.glbst && mdl == "") {
        mdl = this.glbst;
      }
      $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
      this.companymasterlist = [];
      this.isProjectSel = 0;
      this.isProjectMsr = 0;
      this.viewprojectlist = 0;
      this.isProjectRoleSel = 0;
      this.isRoleAlloc = 0;
      let tblNm = "t11";
      if (mdl) tblNm = mdl;
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      this.input.isSearch = false;

      if (typeof objThis != "undefined" && objThis != "") {
        if (
          objThis.currentTarget.dataset.clkstate == "search" &&
          $("#glbsearchinpele").val() != ""
        ) {
          this.input.name = $("#glbsearchinpele").val().trim();
          this.input.isSearch = true;
        }
      }
      let url = "api/master/commonmaster/list";
      if (tblNm == "t11") {
        this.getApplicationdata("t3", "project-tab");
        this.getApplicationdata("t2", "project-tab");
        this.getApplicationdata("t1", "project-tab");
        url = "api/master/commonmaster/customselect";
        this.isProjectMsr = 1;
        this.input.section = "assignment";
        this.input.isFilterSelect = 0;
        this.input.isFilterPayload = {};
      }
      this.input.empcode = this.undt.userid,
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (
            this.isProjectSel == 1 ||
            this.isProjectMsr == 1 ||
            this.isProjectRoleSel == 1
          ) {
            this.companymasterlist = result.data.data.rows;
            this.totalcount = result.data.data.count;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          } else {
            this.companymasterlist = [];
            this.totalcount = 0;
          }
          setTimeout(function () {
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else {
          this.totalcount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    getApplicationdata(mdl, curracttab) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      let apiURL = "api/master/commonmaster/dropdownlist";
      if (mdl == "t35") {
        apiURL = "api/master/commonmaster/customselect";
        this.inputappl.isFilterPayload = {};
        this.inputappl.isFilterSelect = 0;
        this.inputappl.isSearch = false;
        this.inputappl.section = "assignment";
      }
      if (
        curracttab == "project-tab" ||
        curracttab == "projectroleassign-tab"
      ) {
        this.inputappl = {
          tbl: mdl,
        };
      }
      this.input.empcode = this.undt.userid,
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectType = [];
          this.projectType = result.data.data.rows;
          let tmpClassNm = "";
          if (mdl == "t2") {
            tmpClassNm = "devBusinessVerticleData";
            this.project.businesslist = result.data.data.rows;
            this.search.businessnameList = result.data.data.rows;
            return true;
          }else if (mdl == "t1") {
            tmpClassNm = "companyname";
            this.project.companylist = result.data.data.rows;
            this.search.companynameList = result.data.data.rows
            return true;
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },

     ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        projectid:this.search.searchProjectname,
        useremail: this.undt.username,
        tbl:'t11',
      };
      this.input.empcode = this.undt.userid ,
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: `api/Reports/employeetotaloverview`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data;
            var filename = "";
            this.download(result.data.data,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
        } 
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'employeetotaloverviewreport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,empid,empname,designation,reportingmanager,totalexp,fcexp,totalprojectcount,totaltaskcount,redtaskcount,greentaskcount,bluetaskcount,totalissuescount,totalclosedissuescount,totalopenissuescount,totalincidentcount,totalclosedincidentcount,totalopenincidentcount,totalcodelinecount' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let empid = index.hasOwnProperty('empid') ? index.empid : '';
          let empname = index.hasOwnProperty('empname') ? index.empname : '';
          let designation = index.hasOwnProperty('designation') ? index.designation : '';
          let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager : '';
          let totalexp = index.hasOwnProperty('totalexp') ? index.totalexp : '';
          let fcexp = index.hasOwnProperty('fcexp') ? index.fcexp : '';
          let totalprojectcount = index.hasOwnProperty('totalprojectcount') ? index.totalprojectcount : '';              
          let totaltaskcount = index.hasOwnProperty('totaltaskcount') ? index.totaltaskcount : '';
          let redtaskcount = index.hasOwnProperty('redtaskcount') ? index.redtaskcount : '';
          let greentaskcount = index.hasOwnProperty('greentaskcount') ? index.greentaskcount : '';
          let bluetaskcount = index.hasOwnProperty('bluetaskcount') ? index.bluetaskcount : '';
          let totalissuescount = index.hasOwnProperty('totalissuescount') ? index.totalissuescount : '';
          let totalclosedissuescount = index.hasOwnProperty('totalclosedissuescount') ? index.totalclosedissuescount : '';
          let totalopenissuescount = index.hasOwnProperty('totalopenissuescount') ? index.totalopenissuescount : '';
          let totalincidentcount = index.hasOwnProperty('totalincidentcount') ? index.totalincidentcount : '';
          let totalclosedincidentcount = index.hasOwnProperty('totalclosedincidentcount') ? index.totalclosedincidentcount : '';
          let totalcodelinecount = index.hasOwnProperty('totalcodelinecount') ? index.totalcodelinecount : '';
            row += '"' + srno + '",',
            row += '"' + empid + '",',
            row += '"' + empname + '",',
            row += '"' + designation + '",',
            row += '"' + reportingmanager + '",',
            row += '"' + totalexp + '",',
            row += '"' + fcexp + '",',
            row += '"' + totalprojectcount + '",',
            row += '"' + totaltaskcount + '",',
            row += '"' + redtaskcount + '",',
            row += '"' + greentaskcount + '",',
            row += '"' + bluetaskcount + '",',
            row += '"' + totalissuescount + '",',
            row += '"' + totalclosedissuescount + '",',
            row += '"' + totalopenissuescount + '",',
            row += '"' + totalincidentcount + '",',
            row += '"' + totalclosedincidentcount + '",',
            row += '"' + totalcodelinecount + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'employeetotaloverviewreport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      }); 
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },

  },
};
</script>
