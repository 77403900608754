<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top" >
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Run Cycle</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">Test Execution</li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TestCycleSetRunkList', params: { search: true }}">Run Test Cycle Set's</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Run Test Cycle Set</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card" >
                        <div class="card-header py-1" style="cursor:pointer">
                            <div class="container-fluid">
                            <div class="row" >
                                <div class="col-md-8">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                        </div>
                                    </div>
                                    <h3 class="card-title ml-2 d-inline-flex text-primary">Test Cycle Set Code: {{testcycle.cyclesetcode}}</h3>
                                </div>
                            </div>
                            </div>
                            <div class="container">
                                 <section class="modern-horizontal-wizard mb-1">
                    <div class="bs-stepper wizard-modern modern-wizard-example">
                        <div class="bs-stepper-header">
                           <div class="step" data-target="#account-details-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Created Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.createddate)}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#personal-info-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-success rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Start Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.startdate)}}</span>
                                    </span>
                                </button>
                            </div>
                           
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#social-links-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-danger rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Last Modify Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.lastmodifieddate)}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#social-links-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-warning rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Review Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.revieweddate) ? dateMonthFormat(testcycle.revieweddate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#address-step-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-success rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Product Pending for Review</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.productpendingreviewdate) ? dateMonthFormat(testcycle.productpendingreviewdate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                            <div class="row my-1"> 
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-user-edit font-medium-3"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Cycle Set Name</h6>
                                                <small>{{testcycle.cyclename}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-sync-alt font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Task Code</h6>
                                                <small>{{testcycle.businesscode}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-clipboard-list font-medium-5"></em>
                                                        </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Created By</h6>
                                                    <small  class="capitalisetext " style="font-weight:500">{{testcycle.createdby}}</small>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title"> Versions</h6>
                                                <small>{{testcycle.version}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>                                
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-id-card font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Task Name</h6>
                                                <small>{{testcycle.businessname}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <UserIcon size="1.5x" class="custom-class  avatar-icon font-medium-3"></UserIcon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Last Modified By</h6>
                                                    <small  class="capitalisetext " style="font-weight:500">{{testcycle.lastmodifiedby}}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-clipboard-list font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Status</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.status}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-users font-medium-5"></em> 
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Group Name</h6>
                                                <span class="font-weight-bold" v-if="testcycle.mappingname" v-html="showmappingname(testcycle.mappingname)"></span>
                                                <small class="font-weight-bold" v-else>NA</small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-calendar font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Cycle Last Run Date</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{dateMonthFormat(testcycle.rundate) ? dateMonthFormat(testcycle.rundate):'NA'}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Total Scripts</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.nooftestcase}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-danger rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-sync-alt font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">No Of Times Cycle Run</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.cycleruncount?testcycle.cycleruncount:'0'}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-calendar font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Cycle Last Finish Date</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{dateMonthFormat(testcycle.finishdate) ? dateMonthFormat(testcycle.finishdate):'NA'}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-thumbs-up font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Total Last Pass Scripts</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.spasscount ? testcycle.spasscount :0}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-danger rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-thumbs-down font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Total Last Fail Scripts</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.sfailcount ? testcycle.sfailcount :0}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                            </div>
                            <div class="container px-0">
                                <div class="col-md-12 px-0">
                                    <div class=" mb-2 mx-0 business_fieldset_bg">   
                                        <div class="tab-content" id="myTabContent">                            
                                            <div class="row">
                                                <div class="col-sm-12 col-md-12" v-for="(datax,indexx) in editRowDatas" v-bind:key="indexx">
                                                    <div class="tast_case_groupingview_01">
                                                    <div class="common_groupview_cases">
                                                        <div class="cycle_group_bg">
                                                        <span class="leg_cycle_text"> <span class="testCaseName01"> Test Case Name :</span> <span class="test_casename_t-03">  {{datax.testcasename}} </span> </span>
                                                        <span v-if="datax.issuecode" @click="redirecttourl('view',datax)" href="javascript:void(0);" style="cursor:pointer; float:right;" class="font-weight-bold sitebluecolor issue_open0011">
                                                                {{ datax.issuecode }} | {{ datax.status }}
                                                        </span> 
                                                        </div>
                                                        <span class="leg_group_testcase case_bg_11">{{datax.tcscriptcode}}<em class="las la-angle-double-right"></em></span>
                                                        <span class="leg_group_testcase case_bg_12">{{datax.country}}  <em class="las la-angle-double-right"></em></span>
                                                        <span class="leg_group_testcase case_bg_13">{{datax.enviornment}} <em class="las la-angle-double-right"></em></span>
                                                        <span class="leg_group_testcase case_bg_14">{{datax.language}} <em class="las la-angle-double-right"></em></span> 
                                                        <span class="leg_group_testcase case_bg_15">{{datax.scripttype}}<em class="las la-angle-double-right"></em></span>
                                                        <span class="leg_group_testcase case_bg_16">
                                                        <div class="col-md-12 px-0">
                                                            <div class="form-group mb-0 p-0">
                                                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select assignee" v-model="editRowDatas[indexx].assignee" :options="assigneelist" @input="selasgn"/>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <span class="leg_group_testcase case_bg_16">
                                                        <div class="col-md-12 px-0">
                                                            <div class="form-group mb-0 p-0">
                                                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select status" v-model="editRowDatas[indexx].resultstatus" :options="testcasereuslt" @input="selresult" v-if="editRowDatas[indexx].assignee && datax.assignemail===loginpersonmail"/>
                                                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select status" v-model="editRowDatas[indexx].resultstatus" :options="testcasereuslt" @input="selresult" v-else disabled/> <span class="textcycle_textsize"> {{ datax.assignemail }}</span>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <span class="leg_group_testcase py-0 px-0 runcycle_btnsave-2"> 
                                                        <div class="form-group mb-0 p-0">
                                                            <button type="button" class="btn btn-relief-primary" @click="savestatusresult(datax)" v-if="datax.statuses !='pass' && (editRowData.rundate && editRowData.rundate !=null) && (editRowData.finishdate ==null || editRowData.finishdate =='' || editRowData.finishdate ==' ')">Save</button>
                                                            <button type="button" class="btn btn-relief-primary" v-else disabled>Save</button>
                                                        </div>
                                                    </span>
                                                    <div class="clearfix"> </div>
                                                    <div class="col-md-12 px-0 mt-0 tast_case_groupingview_readmore">
                                                        <div class="custom-container case_bg_17">
                                                            <div class="outer mb-1 mt-1" :id="'cycle_readmore1'+indexx" v-if="datax.comment">
                                                                <span class="cycle_readmore_dis">Script Comment : </span>
                                                                <span class="fc_m16_btcode_text" v-html="formatData(datax.comment)"></span>
                                                            </div>
                                                            <div class="cycle_viewmoretext" v-if="datax.comment && datax.comment.length >50">
                                                                <a class="btn">
                                                                <strong :id="'id'+indexx" @click="idclick2(indexx)">
                                                                    Read More
                                                                </strong>
                                                                </a>
                                                            </div>
                                                            <div class="outer" :id="'cycle_readmore2'+indexx">
                                                                <span class="cycle_readmore_dis" v-if="datax.scripttype =='Manual'">Steps : </span>
                                                                <span class="cycle_readmore_dis" v-if="datax.scripttype =='Automation'">Script : </span>
                                                                <span class="fc_m16_btcode_text" v-html="formatData(datax.scriptsteps)"></span>
                                                            </div>
                                                            <div class="cycle_viewmoretext" v-if="datax.scriptsteps.length >50">
                                                                <a class="btn">
                                                                <strong :id=indexx @click="idclick(indexx)">
                                                                    Read More
                                                                </strong>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                </div>
                                                <div class="col-12 mg-top1 text-center mt-0">
                                                <button type="button" class="btn btn-relief-success mr-1 mt-1"  @click="savefinishdate(editRowData)" v-if="testcycle.finishdate!=null" disabled>Finish Cycle</button>
                                                <button type="button" class="btn btn-relief-success mr-1 mt-1"  @click="savefinishdate(editRowData)" v-else-if="(testcycle.rundate !=null && (testcycle.finishdate ==null || testcycle.finishdate ==''))" :disabled="finishenable">Finish Cycle</button>
                                                <!-- <button type="button" class="btn btn-relief-success mr-1 mt-1"  @click="savefinishdate(editRowData)" v-else>Finish Cycle</button> -->
                                                </div>
                                            </div>
                                            <div class="pageCenter text-center">
                                                <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>                
            </section>
        </div>           
    </div>
</template>
<style>
   .fc_m16_btcode_text p{
        margin-bottom:5px;
}
.testCaseName01{
    white-space: nowrap;
    font-weight: 600;
}
.grouptestcase_views .tast_case_groupingview_readmore .cycle_readmoresdis{
    height: 40px;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,CalendarIcon,UserIcon,EyeIcon,WatchIcon,SettingsIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';
export default {
    name:'TestCycleRunVeiw',
    components:{
        Loading,
        vSelect,
        Treeselect,
        VueElementLoading,
        Pagination,Edit2Icon,CalendarIcon,
        PlusCircleIcon,PlusSquareIcon,UserIcon,EyeIcon,WatchIcon,SettingsIcon
    },
    mixins: [ commonMethods ],
    data(){
        return{
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            cyclelist:[],
            testcasereuslt:apiUrl.testcasereuslt,
            testcycle:{
                cyclename: null,
                tcgrouplist: [],
                businesscode: null,
                businessname: null,
                assignto: null,
                startdate: null,
                enddate: null,
                tcgroup: null,
                btccodelist: [],
                btcnamelist: [],
                assigntolist: [],
                status: null,
                version: null,
                cyclesetid: null,
                createdby: null,
                createddate: null,
                lastmodifiedby: null,
                lastmodifieddate: null,
                cyclesetcode: null,
                mappingname: null,
                revieweddate: null,
                productpendingreviewdate: null,
                rundate: null,
                finishdate: null,
                groupname: [],
                spasscount: null,
                sfailcount: null,
                nooftestcase: null,
                cycleruncount: null,
                bulkassignmentcompleteno: null
            },
            resultstatus: null,
            editRowDatas: [],
            finishenable: true,
            assigneelist: [],
            assignee: null,
            loginpersonmail: ''
        }
    },
    mounted(){
        window.addEventListener('storage', function(event){
              if (event.key == 'logout-mee') { 
                  window.location.reload();
              }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.loginpersonmail=this.undt.emailaddress;
            this.tokendata = window.localStorage.getItem('token');
            this.loginusername = userdata.username
            this.getcyclesetviewbyid(this.$route.query.cyclesetid);
        }

    },
    methods:{
        redirecttourl:function(btnstate, rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate=="view"){
                let routeData = this.$router.resolve({ 
                    path: '/issues/view?issueid='+rowdata.issueid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata,
                    }
                })
                window.open(routeData.href, '_blank');
            }
        },
        idclick(indexx){
        if ($("#cycle_readmore2"+indexx).hasClass("readmore")) {
                $("#"+indexx).html("Read More");
                $("#cycle_readmore2"+indexx).removeClass("readmore");
            } else {
                $("#"+indexx).html("Read Less");
                $("#cycle_readmore2"+indexx).addClass("readmore");
            }
        },
        idclick2(indexx){
            if ($("#cycle_readmore1"+indexx).hasClass("readmore")) {
                $("#"+'id'+indexx).html("Read More");
                $("#cycle_readmore1"+indexx).removeClass("readmore");
            } else {
                $("#"+'id'+indexx).html("Read Less");
                $("#cycle_readmore1"+indexx).addClass("readmore");
            }
        },
      
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getcyclesetviewresulttbl(this.$route.query.cyclesetid);
            setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#myTabContent").offset().top -70
            });
            }, 500);
        },
        getcyclesetviewbyid(cyclesetid){
         this.isLoading = true;
            let url = "api/testcycle/getcyclesetviewbyid";
            this.input = {
                cyclesetid:cyclesetid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0];
                    if(this.editRowData && (this.editRowData.rundate && this.editRowData.rundate !=null && this.editRowData.rundate !='') && (this.editRowData.finishdate && this.editRowData.finishdate !=null && this.editRowData.finishdate !='')){
                        this.$router.push({ name: 'TestCycleSetRunkList', params: { search: true }})
                        Swal.fire({
                            title: "Failed!",
                            text: this.editRowData.cyclesetname+', '+"Cycleset Is Not Run Yet",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }else if(this.editRowData && (this.editRowData.rundate ==null || this.editRowData.rundate =='' || this.editRowData.rundate ==' ') && (this.editRowData.finishdate ==null || this.editRowData.finishdate =='' || this.editRowData.finishdate ==' ')){
                        this.$router.push({ name: 'TestCycleSetRunkList', params: { search: true }})
                        Swal.fire({
                            title: "Failed!",
                            text: this.editRowData.cyclesetname+', '+"Cycleset Is Not Run Yet",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }else{
                        this.openEditModule(this.editRowData);
                    }
                } else {
                    this.editRowData = [];
                    this.$router.push({ name: 'TestCycleSetList', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to update this test case",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        getcyclesetviewresulttbl(cyclesetid){
         this.isLoading = true;
            let url = "api/testcycle/getcyclesetviewresulttbl";
            this.input = {
                cyclesetid:cyclesetid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                limit: apiUrl.LIMIT,
                offset: this.pageoffset
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowDatas = result.data.data.rows;
                    this.totalcount = result.data.data.count;
                    let temparr=[];
                    for (let n = 0; n < this.editRowDatas.length; n++) {
                        if(this.editRowDatas[n].resultstatus ==null || this.editRowDatas[n].resultstatus =='' || this.editRowDatas[n].resultstatus ==' '){
                            this.editRowDatas[n].resultstatus ='NA';
                        }

                        let element = this.editRowDatas[n]; 
                        if (element.resultstatus !=null && element.resultstatus !='NA' && element.resultstatus !='') {
                            temparr.push(element.resultstatus);
                        }
                    }
                    if (temparr.length > 0) {
                        this.finishenable=false;
                    }
                } else {
                    this.editRowDatas = [];
                    this.totalcount =0;
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        openEditModule(currRowData){
            this.testcycle.cyclesetid=currRowData.cyclesetid
            this.testcycle.cyclename=currRowData.cyclesetname
            this.testcycle.cyclesetcode = currRowData.cyclesetcode
            this.testcycle.businesscode=currRowData.businesstaskcode
            this.testcycle.businessname=currRowData.taskname
            this.testcycle.status=currRowData.status
            this.testcycle.startdate=currRowData.startdate
            this.testcycle.enddate=currRowData.enddate
            this.testcycle.createdby=currRowData.createdby
            this.testcycle.createddate=currRowData.createddate
            this.testcycle.lastmodifiedby=currRowData.lastmodifiedby
            this.testcycle.lastmodifieddate=currRowData.lastmodifieddate
            this.testcycle.version=currRowData.versionno
            this.testcycle.mappingname=currRowData.mappingname
            this.testcycle.assignto=currRowData.assignedto
            this.testcycle.revieweddate=currRowData.revieweddate
            this.testcycle.productpendingreviewdate=currRowData.productpendingreviewdate
            this.testcycle.rundate=currRowData.rundate
            this.testcycle.finishdate=currRowData.finishdate
            this.testcycle.spasscount=currRowData.spasscount
            this.testcycle.sfailcount=currRowData.sfailcount
            this.testcycle.nooftestcase=currRowData.nooftestcase
            this.testcycle.cycleruncount=currRowData.cycleruncount
            this.testcycle.bulkassignmentcompleteno=currRowData.bulkassignmentcompleteno
            this.getassigneelist()
            this.getcyclesetviewresulttbl(this.$route.query.cyclesetid)
        },
        getassignto(assignto){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                assignto: assignto
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getassignto',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.testcycle.assignto = result.data.data.rows[0].label;
                } else {
                    this.testcycle.assignto = []
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        getcyclegroup(groupnameidlist){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                groupnameid: groupnameidlist
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getcyclegroup',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.testcycle.tcgrouplist = result.data.data.rows;
                    for (let i = 0; i < this.testcycle.tcgrouplist.length; i++) {
                        this.testcycle.groupname.push(this.testcycle.tcgrouplist[i].label);
                    }
                } else {
                    this.testcycle.tcgrouplist = [],
                    this.testcycle.groupname = []
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        formatData(data) {
            return data
        },
        selresult(state, value) {
            this.resultstatus = null;
            this.resultstatus = state;
            if (!state) {
                this.resultstatus = null;
            }
        },
        selasgn(state) {
            this.assignee = null;
            this.assignee = state;
        },
        savestatusresult(datax){
            if (datax.assignee != null) {
                this.assignee = datax.assignee
            }
            if (datax.resultstatus != null) {
                this.resultstatus = datax.resultstatus
            }
                this.isLoading = true;
                this.input = {
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    empid: parseInt(this.undt.userid),
                }
                let apiURL='api/testcycle/savetestcaseresult'
                this.input.resultid=parseInt(datax.resultid),
                this.input.status=this.resultstatus,
                this.input.assignee=this.assignee,
                this.input.cyclesetid= parseInt(this.$route.query.cyclesetid),
                this.input.cyclecode=datax.cyclecode,
                this.input.tcscriptcode=datax.tcscriptcode,
                this.input.testcasemasterid=datax.testcasemasterid,
                this.input.testcasedetailsid=datax.testcasedetailsid,
                this.input.businesstaskcode = this.editRowData.businesstaskcode,
                this.input.businesstaskmasterid = this.editRowData.businesstaskmasterid,
                this.input.cyclesetname = this.editRowData.cyclesetname,
                this.input.projectid = this.editRowData.projectist,
                this.input.version = this.editRowData.versionno,
                this.input.startdate = this.editRowData.startdate,
                this.input.enddate = this.editRowData.enddate
                this.input.qalead = this.editRowData.qalead
                this.input.qamanager = this.editRowData.qamanager
                this.input.cyclecreatedby = this.editRowData.createdby
                this.input.cycleruncount = this.testcycle.cycleruncount
                this.input.assignedate = datax.assignedate
                this.input.bulkassignmentcompleteno=this.testcycle.bulkassignmentcompleteno?this.testcycle.bulkassignmentcompleteno:0;
                this.input.rundate = this.editRowData.rundate;
                this.input.finishdate = this.editRowData.finishdate;
                if(this.input.status == 'fail') {
                    this.input.engineeringmaanager = this.editRowData.engineeringmaanager;
                    this.input.projectmaanager = this.editRowData.projectmaanager;
                }
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resultstatus = null;
                        this.getcyclesetviewbyid(this.$route.query.cyclesetid);
                        this.getcyclesetviewresulttbl(this.$route.query.cyclesetid);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }else if(result.data.errorCode == (-1) && result.data.msg =='Issue is already exist'){
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resultstatus = null;
                        this.getcyclesetviewbyid(this.$route.query.cyclesetid);
                        this.getcyclesetviewresulttbl(this.$route.query.cyclesetid);
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                    this.resultstatus = null;
                }).catch(e => {
                  this.displayError(e)
                })
        },
        savefinishdate(dataex){
            Swal.fire({
            title: 'Are You Sure To Finish This Testcycle?',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, finish it!',
            input: 'text',
            inputPlaceholder: 'Enter finish comment',
            inputValidator: (value) => {
                if (!value) {
                    return 'Please enter a comment';
                }
            }
          }).then((result) => {
              if (result.isConfirmed && (Swal.getInput().value !=null || Swal.getInput().value !='')) {
                    let finishcomment = Swal.getInput().value;
                    this.isLoading = true;
                    this.input = {
                        createdby: this.undt.username,
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                        finishcomment: finishcomment
                    }
                    let apiURL='api/testcycle/savefinishdate'
                    this.input.cyclesetid=dataex.cyclesetid,
                    this.input.dataex=dataex
                    axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {    
                        this.isLoading = false;         
                        if(result.data.errorCode == 0){
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            this.$router.push({ name: 'TestCycleSetRunkList', params: { search: true }})
                        }else if(result.data.errorCode == 3){
                            Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                        }else {
                            Swal.fire({
                                title: "Failed",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    }).catch(e => {
                     this.displayError(e)
                    })
                }
            })
        },
        getassigneelist(){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getassigneelist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    let decryptdepartmenttext = result.data.data.rows.map(items =>{
                    let tempemplabel1 = items.label;
                    let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                    if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                    }
                    return items;
                    })
                    this.assigneelist = result.data.data.rows;
                } else {
                    this.assigneelist = []
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
    },
}
</script>

