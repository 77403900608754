<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Test Case</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'tlist', params: { search: true }}">Test Case List</router-link></li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Test Case</li>
                        </ol>
                        </div>
                    </div>
                </div>
                <div v-if="pagefeatures.createtestcase" class="card border-warning card_style_box_shadow1" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header py-1 px-2" style="border-bottom: 1px solid #d8d8d8;">
                  <h3 class="card-title">
                      Create Test Case
                  </h3>
                </div>
                <div>
                    <div class="card-body">
                        <div v-if="this.isRedirectedFromFunc" class="pb-1  mb-1 mt-0" >
                            <div class="table-responsive" style="overflow-x: inherit !important;">
                                <table class="table table-sm dataTableListing table-hover table-striped table-bordered" aria-describedby="mydesc">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="text-center">Project Name</th>
                                            <th scope="col" class="text-center">Functionality Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">{{this.view.projectname}}</td>
                                            <td class="text-center">{{this.view.functionlityname}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <form class="form form-vertical">
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectlistdd">Test Case Name</label>
                                        <input type="text" id="testcasename" class="form-control" name="" placeholder="Test Case Name" v-model="testcase.testcasename" @blur="validateForm('t39',$event)"
                                        v-bind:class="{ 
                                        'form-control': true,
                                        '': !validText(testcase.testcasename) && nameBlured, }"
                                        v-on:blur="nameBlured = true"/>
                                        <div class="errorinputmsg" v-if="!validText(testcase.testcasename) && nameBlured"> Test case name must be length of 5 char at least and only letters are allowed</div>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="!this.isRedirectedFromFunc">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Project Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="testcase.project" :options="testcase.projectlist" @input="seltestcsproject" @close="validateForm('t39',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcase.project">{{ this.errors.testcase.project }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="!this.isRedirectedFromFunc">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Functionality</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select functionality name" v-model="testcase.functionality" :options="testcase.functionalitylist" @input="seltestcsfunctionality" @close="validateForm('t39',$event)"/>
                                    </div>
                                    <div class="errorinputmsg" v-if="this.errors.testcase.functionality">{{ this.errors.testcase.functionality }}</div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Task Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search task" v-model="testcase.task" :options="testcase.tasklist" v-on:search-change="taskChange"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Test Case Type</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Test Case Type" v-model="testcase.type" :options="testcase.typelist" @input="seltestcstype" @close="validateForm('t39',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcase.type">{{ this.errors.testcase.type }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Test Case Severity</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Test Case Severity" v-model="testcase.sevairty" :options="testcase.sevairtylist" @input="seltestcssevairty" @close="validateForm('t39',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcase.sevairty">{{ this.errors.testcase.sevairty }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Test Case Priority</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Test Case Priority" v-model="testcase.priority" :options="testcase.prioritylist" @input="seltestcspriority" @close="validateForm('t39',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcase.priority">{{ this.errors.testcase.priority }}</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="col-md-6 px-0">
                            <div class="form-group">
                                <label for="lblattachment" class="d-flex">Attachment</label>
                                <label for="attachment" class="btn btn-sm btn-relief-secondary mr-75 mb-0">Select image/docs/videos</label>
                                <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                                <div class="table-responsive my-75" v-if="testcase.attachmentview">
                                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col">SR#</th>
                                                <th scope="col">Document Name</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, k) in testcase.attachmentview.data" :key="k">
                                                <td>{{k+1}}</td>
                                                <td>
                                                    <a :href="data.presignurl">{{
                                                    data.link | basename
                                                    }}</a>
                                                </td>
                                                <td>{{data.info}}</td>
                                                <td>
                                                    <button type="button" class="btn btn-sm danger" @click="removes3file(k,testcase.attachmentview.data)">
                                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.testcase.attachment">{{ this.errors.testcase.attachment }}</div>
                                    <div class="table-responsive my-75" v-if="file.length>0">
                                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(image, key) in file" :key="key">
                                                    <td>{{ key+1 }}</td>
                                                    <td>{{ image.name }}</td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="testcase.filedescp[key]"/>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                <button class="btn btn-sm btn-relief-secondary mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                            </div>
                        </div>
                        <div class="col-md-12 px-0">
                            <fieldset class="fieldset_border_vi mb-2 mx-0 fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                        <div class="container px-0"> 
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                <div class="form-group mb-1">
                                    <label for="projectmdllistdd">Country</label>
                                    <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Country" v-model="testcase.tcformgroups.country" :options="testcase.tcformgroups.countrylist" @input="getCountry" @close="validateForm($event)"/>
                                <div class="errorinputmsg" v-if="this.testcase.tcformgroups.country.length<=0">{{ this.testcase.tcformgroups.errors.country }}</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div class="form-group mb-1">
                                    <label for="projectmdllistdd">Environment</label>
                                    <treeselect class="projectmdllistdd capitalisetext" :multiple="true" placeholder="Select Environment" v-model="testcase.tcformgroups.environment" :options="testcase.tcformgroups.environmentlist" @input="getEnv" @close="validateForm($event)"/>
                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.environment.length<=0">{{ this.testcase.tcformgroups.errors.environment }}</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                <div class="form-group mb-1">
                                    <label for="projectmdllistdd">Environment Language</label>
                                    <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Environment Language" v-model="testcase.tcformgroups.language" :options="testcase.tcformgroups.languagelist" @input="getLang" @close="validateForm($event)"/>
                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.language.length<=0">{{this.testcase.tcformgroups.errors.language}}</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                <div class="form-group mb-1">
                                    <label for="projectmdllistdd">Script Type</label>
                                    <treeselect :multiple="true" class="projectmdllistdd capitalisetext"  placeholder="Select Script Type" v-model="testcase.tcformgroups.scripttype" :options="testcase.tcformgroups.scripttypelist" @input="getScriptType" @close="validateForm($event)"/>
                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.scripttype.length<=0">{{ this.testcase.tcformgroups.errors.scripttype }}</div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-2">
                                <div class="form-group mb-1">
                                    <label for="projectmdllistdd">Status</label>
                                    <treeselect :disabled="true" class="projectmdllistdd capitalisetext" v-model="testcase.tcformgroups.status" :options="testcase.tcformgroups.tcscriptstatuslist" />
                                    <div class="errorinputmsg" v-if="this.testcase.tcformgroups.errors.status">{{ this.testcase.tcformgroups.errors.status }}</div>
                                </div>
                            </div>
                            <div v-if="testcase.tcformgroups.scriptgrps.length>0" v-for="(rows, index) in testcase.tcformgroups.scriptgrps" class="col-xs-12 col-sm-12 col-md-12">
                                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                <legend> 
                                    <span class="leg_mobile"> {{ rows.country }}</span> -
                                    <span class="leg_mobile"> {{ rows.environment }}</span> - <span class="leg_language"> {{ rows.language }} </span> - <span class="leg_automation"> {{ rows.scripttype }}</span>
                                </legend>
                                <div class="row">
                                    <div class="form-group col-sm-12">
                                        <label v-if="rows.scripttype != 'Manual'" class="form-label" for="projectmdllistdd">Test Case Script :</label>
                                        <label v-else class="form-label" for="projectmdllistdd">Test Case Steps : </label>
                                        <ckeditor :editor="editor" v-model="testcase.tcformgroups.scriptgrps[index].scriptsteps"  tag-name="textarea" />
                                        <div class="errorinputmsg" v-if="(!testcase.tcformgroups.scriptgrps[index].scriptsteps)">
                                            {{ rows.scripttype }}
                                            <small v-if="rows.scripttype != 'Manual'">Test Case Script is required!</small>
                                            <small v-else>Test Case Steps are required!</small>
                                        </div>
                                    </div>
                                </div>
                                </fieldset>
                            </div>
                            </div>
                        </div>
                    </fieldset>
                    </div>
                        <div class="col-12 mg-top1 text-right">
                            <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t39',$event)" id="submitbtnt39" v-bind:disabled="testcase.disblesavebtn">Submit</button>
                            <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Reset</button>
                            <router-link :to="{ name: 'tlist', params: { search: true }}">  <button style="margin-left:8px;" type="cancel" class="btn btn-outline-secondary" @click="cancelForm()">Cancel</button></router-link>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </div>        
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'tcreate',
    mixins: [ commonMethods ],
    components:{
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
    },    
    data() {
        return {
            isRedirectedFromFunc: false,
            isLoading: false,
            fullPage: true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            editRowData:[],
            glbMdl:'',
            glbMdlLbl:'testcase',
            TimeFormat: apiUrl.TimeFormat,
            testcase:{
                tcformgroups: {
                    language: [],
                    environment: [],
                    scripttype : [],
                    scriptsteps: [],
                    country: [],
                    status: 'Created',
                    languagelist: apiUrl.languagelist.sort((a, b) => a.label.localeCompare(b.label)),
                    environmentlist: apiUrl.environmentlist.sort((a, b) => a.label.localeCompare(b.label)),
                    scripttypelist: apiUrl.scripttypelist.sort((a, b) => a.label.localeCompare(b.label)),
                    tcscriptstatuslist: apiUrl.tcscriptstatuslist.sort((a, b) => a.label.localeCompare(b.label)),
                    countrylist: apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
                    scriptgrps: [],
                    errors: {
                        language: null,
                        environment: null,
                        scripttype : null,
                        country: null,
                        status:null,
                    }
                },
                testcasename:null,
                project:null,
                projectlist:null,
                functionality:null,
                functionalitylist:null,
                type:null,
                typelist:null,
                attachment:null,
                attachmentview:null,
                attachmentnew: null,
                sevairty:null,
                sevairtylist:null,
                priority:null,
                prioritylist:[{
                    id:'high',
                    label:'High'
                },{
                    id:'low',
                    label:'Low'
                },{
                    id:'medium',
                    label:'Medium'
                }],
                status:null,
                statuslist: apiUrl.testcaseStatusList,
                iseditstate:false,
                disblesavebtn:false,
                task: null,
                tasklist: [],
                testcasenameopts: null,
                filedescp:[]
            },
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            modulelist:[],
            isSearchRequestActive:false,
            errors:{
                testcase:{
                    testcasename:null,
                    testcasenameopts:null,
                    project:null,
                    functionality:null,
                    type:null,
                    attachment:null,
                    sevairty:null,
                    priority:null,
                }
            },
            isDetActive:true,
            isRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            isedit: false,
            card:'card',
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|', 'bold',
                        'italic', '|', 'bulletedList',
                        'numberedList', '|', 'insertTable', '|',
                        'undo', 'redo', '|',
                    ],
                },
            },
            search:{
                searchprojectname :null,
                projectnameopts :null,
                searchfunctionalityname:null,
                functionalitylist:null,
                searchtestcasename:null,
                searchtaskname:null,
                tasklist:null,
                searchtypename:null,
                typenameopts:null,
                searchseverityname:null,
                severitynameopts:null,
                searchpriorityname:null,
                prioritynameopts:null,
                searchstatus:null,
                statusopts:null
            },
            projectnameList:[],
            testcasenameList:[],
            view:{
                projectname: null,
                functionlityname: null
            },
            file:[],
            nameBlured: false,
            tokendata:null,
            accepttype:apiUrl.uploadfiletype,
            validFileExtensions:apiUrl.validFileExtensions
        }
    },
    created() {
        this.pagefeatures = {}
        let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/testcasetask/list') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.getProjectAllocatedToEmp();
            this.getApplicationdata('t40','testcase')
            this.getApplicationdata('t41','testcase')
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'addtestcase') {
                    let cd = this.$route.params.clickeddata
                    this.isRedirectedFromFunc = true
                    this.testcase.project = cd.projectid
                    this.testcase.functionality = cd.functionlitymasterid
                    this.view.projectname = cd.projectname
                    this.view.functionlityname = cd.functionlitynamewithsection
                }
            }
            this.testcase.status = 'created'
        }
    },
    methods:{
        getScriptType(state) {
            this.testcase.tcformgroups.scripttype = [];
            this.testcase.tcformgroups.scripttype = state
            if(!state) {
                this.testcase.tcformgroups.errors.scripttype = 'Script Type is required'
            }else{
                this.addItems('Script Type');
            }
        },
        cancelForm() {
            this.$router.push({ name: 'tlist', params: { search: true }})
        },
        getCountry(state) {
            this.testcase.tcformgroups.country = [];
            this.testcase.tcformgroups.country = state
            if(!state) {
                this.testcase.tcformgroups.errors.country = 'Country is required'
            }else{
                this.addItems('Country');
            }
        },
        getEnv(state) {
            this.testcase.tcformgroups.environment = [];
            this.testcase.tcformgroups.environment = state;
            if(!state) {
                this.testcase.tcformgroups.errors.environment = 'Environment is required'
            }else{
                this.addItems('Environment');
            }
        },
        getLang(state) {
            this.testcase.tcformgroups.language = [];
            this.testcase.tcformgroups.language = state;
            if(!state) {
                this.testcase.tcformgroups.errors.language = 'Language is required'
            }else{
                this.addItems('Language');
            }
        },
        detectCombinations(input, output, position, path) {
            if (position == null) {
                position = 0;
            }
            if (path == null) {
                path = [];
            }
            if (position < input.length) {
                var item = input[position];
                for (var i = 0; i < item.length; ++i) {
                    var value = item[i];
                    path.push(value);
                    this.detectCombinations(input, output, position + 1, path);
                    path.pop();
                }
            } else {
                output.push(path.slice());
            }
            return output;
        },
        addItems(type) {
            let output = [];
            let input = [];
            let prevarr = [];
            prevarr.push(this.testcase.tcformgroups.scriptgrps);

            this.testcase.tcformgroups.scriptgrps = [];
            input.push(this.testcase.tcformgroups.environment)
            input.push(this.testcase.tcformgroups.language)
            input.push(this.testcase.tcformgroups.scripttype)
            input.push(this.testcase.tcformgroups.country)
            
            let x = this.detectCombinations(input, output);
            x.forEach(element => {
                let objWithIdIndex = prevarr[0].filter((obj) => (obj.environment === element[0] 
                && obj.language === element[1] 
                && obj.scripttype === element[2]
                && obj.country === element[3]));

                let scriptstepsval = '';
                if(objWithIdIndex.length == 1) {
                    scriptstepsval = objWithIdIndex[0].scriptsteps;
                }

                this.testcase.tcformgroups.scriptgrps.push({
                    environment: element[0],
                    language: element[1],
                    scripttype: element[2],
                    country: element[3],
                    scriptsteps: scriptstepsval,
                    status: 'Created',
                });
            });
        },
        onFileChange(e) {
            var validFileExtensions = apiUrl.validFileExtensions
            let selectedFiles = e.target.files;
            for (let i=0; i < selectedFiles.length; i++)
            {
            var blnValid = false;
            for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                var sCurExtension = validFileExtensions[j];
                if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
                
                if (!blnValid) {
                    Swal.fire({
                        title: "ERROR",
                        text: "Sorry, Invalid Type of Extension File..!!",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return false;
                }
                this.file.push(selectedFiles[i]);
            }
            if(this.file.length>0) {
                this.testcase.disblesavebtn=true
            }
        },
        removeImage (index) {
            if(this.file.length>0) {
                this.file.splice(index, 1);
                this.testcase.filedescp.splice(index, 1);
                let removeimg = JSON.parse(this.testcase.attachmentnew)
                if(removeimg){
                    let remove = removeimg.data.splice(index,1)
                    if(remove.length > 0)
                    this.testcase.attachmentnew = JSON.stringify({data:removeimg.data})
                    else {
                    this.testcase.attachmentnew = null
                  }
                }
            }   
            if(this.file.length < 1) {
                this.testcase.disblesavebtn = false
            }      
        },
        removes3file(key,data) {
            if(data.length>0) {                
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to delete this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((res) => {
                    if (res.isConfirmed) {
                        if(this.editRowData.testcaseid !== undefined) {
                            let deletefile = data[key];
                            data.splice(key, 1);
                            const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                            let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                            axios({
                                method: "POST",
                                url: 'api/testcase/s3deletetestcase',
                                data: {
                                    testcaseid:this.editRowData.testcaseid,
                                    attachment:payload,
                                    deletefile: JSON.stringify(deletefile),
                                    useremail: this.undt.username,
                                    empcode: this.undt.userid,s 
                                },
                                'headers':{'authorization':this.tokendata}
                            }).then((resul) => {
                                if (resul.data.status) {
                                    Swal.fire(
                                    'Deleted!',
                                    resul.data.msg,
                                    'success'
                                    )
                                } else {
                                Swal.fire(
                                    'Deleted!',
                                    resul.data.msg,
                                    'error'
                                    )                        
                                }
                            });
                        } else {
                            Swal.fire(
                                'TestCaseId!',
                                'TestCaseId should not blank',
                                'info'
                                )
                        }    
                    }
                })
            }
        },
        mydetailscollapse() {
            this.isDetActive = !this.isDetActive
        },
        myrequestcollapse() {
            this.isRequestActive = !this.isRequestActive
            this.testcase.testcasename=null
            this.testcase.project=null
            this.testcase.functionality=null
            this.testcase.type=null
            this.testcase.sevairty=null
            this.testcase.priority=null
        },
        mysearchrequestollapse:function(){
            this.myrequestcollapse()
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        resetForm() {
            this.nameBlured = null
            this.testcase.testcasename = null 
            this.testcase.project = null
            this.testcase.functionality = null
            this.testcase.type = null
            this.testcase.sevairty = null
            this.testcase.priority = null
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            this.testcase.attachmentnew = null
            this.testcase.tcformgroups.environment = [];
            this.testcase.tcformgroups.language = [];
            this.testcase.tcformgroups.scripttype = [];
            this.testcase.tcformgroups.country = [];
            this.testcase.tcformgroups.errors.scripttype = null;
            this.testcase.tcformgroups.errors.language = null;
            this.testcase.tcformgroups.errors.environment = null;
            this.testcase.tcformgroups.errors.country = null;
            this.file = []
            this.testcase.filedescp = []
            this.testcase.disblesavebtn = false
            this.removeImage()
            this.clearerrorForm()
        },
        savewizard(mdl,objThis){
            this.valid = this.validateForm(mdl,objThis);
            if (this.valid) {
                this.isLoading = true;
                let tblNm='t39'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'testcase',
                    createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/testcase/create'
                this.input.testcasename=this.testcase.testcasename
                this.input.project=this.testcase.project
                this.input.functionality=this.testcase.functionality
                this.input.task = this.testcase.task
                this.input.type=this.testcase.type
                this.input.attachment=this.testcase.attachmentnew
                this.input.sevairty=this.testcase.sevairty
                this.input.priority=this.testcase.priority
                this.input.scriptgrps = this.testcase.tcformgroups.scriptgrps;
                
            
            this.input.empcode = this.undt.userid
            this.input.useremail= this.undt.username
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        this.isRequestActive = false
                        this.isDetActive = true
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resetForm()
                        this.$router.push({ name: 'tlist', params: { search: true }})
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
            }).catch(e => {
            this.displayError(e)
            })
            }
        },
        taskChange(node) {
            if(node && node.length>1 && this.testcase.functionality){
                this.isLoading = true;
                let url = "api/master/commonmaster/getModuleDropdownOption";
                this.input = {
                    tbl: 't18',
                    taskname:node.toLowerCase(),
                    functionalityid:this.testcase.functionality,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.testcase.tasklist = result.data.data;
                    } else {
                        this.testcase.tasklist = [];
                    }
                }).catch(e => {
                this.displayError(e)
                })
            }
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                projectname:node.toLowerCase(),
                useremail: this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectnameList = result.data.data;
                } else {
                    this.projectnameList = [];
                }
                }).catch(e => {
                this.displayError(e)
                })
            } 
        },
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'createpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.testcase.projectlist = result.data.data;
                    this.testcase.projectlist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
                } else {
                    this.testcase.projectlist = [];
                }
            }).catch(e => {
            this.displayError(e)
            })
        },
        testcaseChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/searching/getTestcaselist";
                this.input = {
                testcasename:node.toLowerCase(),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.testcasenameList = result.data.data;
                } else {
                    this.testcasenameList = [];
                }
                }).catch(e => {
                this.displayError(e)
                })
            } 
        },
        resetRecords: function() {
            this.search.searchprojectname = null;
            this.search.searchfunctionalityname = null;
            this.search.searchtestcasename= null;
            this.search.searchtaskname = null;
            this.search.searchtypename = null;
            this.search.searchseverityname = null;
            this.search.searchpriorityname = null;
            this.search.searchstatus = null;
        },
        validateForm(mdl, objThis){
            this.clearerrorForm();
            this.isAddState=false
            let isValid=true;
            this.nameBlured = true;
            if(mdl=="t39"){
                if(!this.testcase.testcasename){
                    this.errors.testcase.testcasename="Test Case Name required";
                    isValid= false;
                }else{
                    this.errors.testcase.testcasename='';
                }
                if(!this.testcase.project){
                    this.errors.testcase.project="Project is required";
                    isValid= false;
                }
                if(!this.testcase.type){
                    this.errors.testcase.type="Test case type required";
                    isValid= false;
                }
               
                if(!this.testcase.sevairty){
                    this.errors.testcase.sevairty="Test case severity required";
                    isValid= false;
                }

                if(!this.testcase.priority){
                    this.errors.testcase.priority="Test case priority required";
                    isValid= false;
                }

                if(this.testcase.tcformgroups.environment.length<=0) {
                    this.testcase.tcformgroups.errors.environment = "Environment is required."
                    isValid = false;
                }
                
                if(this.testcase.tcformgroups.country.length<=0) {
                    this.testcase.tcformgroups.errors.country = "Country is required."
                    isValid = false;
                }

                if(this.testcase.tcformgroups.language.length<=0) {
                    this.testcase.tcformgroups.errors.language = "Language is required."
                    isValid = false;
                }

                if(this.testcase.tcformgroups.scripttype.length<=0) {
                    this.testcase.tcformgroups.errors.scripttype = "Script Type is required."
                    isValid = false;
                }

                
                this.testcase.tcformgroups.scriptgrps.forEach(element => {
                    if(element.scriptsteps == null || element.scriptsteps == '') {
                        isValid = false;
                    }
                });
            }
            return isValid
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        clearerrorForm(){
            this.errors={
                testcase:{
                    testcasename:null,
                    project:null,
                    functionality:null,
                    type:null,
                    attachment:null,
                    sevairty:null,
                    priority:null,
                    status:null
                }
            }
        },
        openEditModule(currRowData, flag){
            this.view.projectname=currRowData.projectname
            this.view.functionlityname=currRowData.functionlityname
            this.editRowData = currRowData
            this.testcase.testcasename = currRowData.testcasename
            this.testcase.project = currRowData.project
            this.testcase.functionality = currRowData.functionality
            this.testcase.task = currRowData.taskid
            this.testcase.type = currRowData.issuetype
            this.testcase.attachment = currRowData.attachment
            if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
                this.testcase.attachmentview =[];
                this.getDownloadLink(currRowData.attachment);
            }
            this.testcase.sevairty = currRowData.sevairty
            this.testcase.priority = currRowData.priority
            this.testcase.iseditstate = true
            this.isAddState=true
            if(flag==0){
                this.isedit=true
                this.isRequestActive=true
                this.mydetailscollapse()
            }
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        getDownloadLink(data) {
            if(data.data.length>0) {
                let payload = {
                documents:data.data,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                if(result.data.status) {
                    this.testcase.attachmentview = result.data;
                }
                }).catch(e => {
                this.displayError(e)
                })
            }
        },
        addmodule(){
            this.testcase.attachmentnew = null
            this.testcase.testcasename = null
            this.testcase.project = null
            this.testcase.functionality = null
            this.testcase.task = null
            this.testcase.type = null
            this.testcase.attachment = null
            this.testcase.attachmentview = null
            this.testcase.sevairty = null
            this.testcase.priority = null
            this.testcase.iseditstate = false
            this.testcase.disblesavebtn=true
        },
        seltestcsproject(state,value){
            this.testcase.project=null
            this.testcase.project=state
            if(!state){
                this.testcase.functionality=null
                this.testcase.task = null
                this.testcase.functionalitylist = null
                this.testcase.tasklist = null
                this.errors.testcase.project='Project required'
            }else{
                this.errors.testcase.project = ''
				this.testcase.type = null
                this.getApplicationdata('t37','testcase')
								this.getTestcasetypeConfigLists(state)

            }
        },     
        seltestcsfunctionality(state,value){
            this.testcase.functionality=null
            this.testcase.functionality=state
            if(!state){
                this.testcase.task = null
                this.testcase.tasklist = null
            }else{                
                
            }
        },
        seltestcsprojectsearch(state,value){
            this.search.searchprojectname = null
            this.search.searchprojectname = state
            if(!state){
                this.search.searchfunctionalityname=null
                this.search.searchtaskname=null
                this.search.searchtaskname = null
                this.search.tasklist = null
                this.errors.testcase.project='Project name is required'
                
            }else{
                this.getApplicationdata('t37','testcaseSearch')
            }
        },     
        seltestcsfunctionalitysearch(state,value){
            this.search.searchfunctionalityname=null
            this.search.searchfunctionalityname=state
            if(!state){
                this.search.searchtaskname = null
                this.search.tasklist = null
            }else{                
                this.getApplicationdata('t18', 'testcaseSearch')
            }
        },
        seltestcstype(state,value){
            this.testcase.type=null
            this.testcase.type=state
            if(!state && !this.isAddState){
                this.errors.testcase.type='Type required'
            }else{                
                this.errors.testcase.type=null
            }
        },
        seltestcssevairty(state,value){
            this.testcase.sevairty=null
            this.testcase.sevairty=state
            if(!state && !this.isAddState){
                this.errors.testcase.sevairty='Severity required'
            }else{                
                this.errors.testcase.sevairty=null
            }
        },
        seltestcspriority(state,value){
            this.testcase.priority=null
            this.testcase.priority=state
            if(!state && !this.isAddState){
                this.errors.testcase.priority='Priority required'
            }else{                
                this.errors.testcase.priority=null
            }
        },
        getApplicationdata(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/list'
            if(mdl == 't11'){
                apiURL='api/listing/getProjectAllocatedToEmp'
                this.inputappl = {
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    isfrompage: 'createpage'
                }
            }else if(mdl == 't37' && curracttab == 'testcase'){                
                this.inputappl = {};
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.testcase.project
            }else if(mdl == 't37' && curracttab == 'testcaseSearch'){                
                this.inputappl = {};
                apiURL = "api/listing/getfunctionalitybyprojectid";
                this.inputappl.projectid = this.search.searchprojectname
            }else if(mdl == 't40' || mdl == 't41'){
                apiURL='api/master/commonmaster/customselect'
                this.inputappl.isSearch= false
                this.inputappl.section= "testcase"
                this.inputappl.isFilterSelect= 1
                this.inputappl.isFilterPayload= {}
            } else if (mdl == 't18' && curracttab == 'testcase') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.testcase.functionality
                }
            } else if (mdl == 't18' && curracttab == 'testcaseSearch') {
                apiURL = 'api/master/commonmaster/getModuleDropdownOption'
                this.inputappl = {
                    tbl: mdl,
                    useremail: this.undt.username,
                    functionalityid: this.search.searchfunctionalityname
                }
            }
            this.inputappl.empcode = this.undt.userid 
            this.inputappl.useremail= this.undt.username
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                if(result.data.errorCode == 0){
                    if(mdl == 't11'){
                        if(curracttab == 'testcase'){
                            this.testcase.projectlist=result.data.data
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcase'){
                        if(curracttab == 'testcase'){
                            this.testcase.functionalitylist=result.data.data
                            this.testcase.functionalitylist.sort((a, b) => {
                               return a.label.localeCompare(b.label);
                            });
                            return true
                        }
                    }else if(mdl == 't37' && curracttab == 'testcaseSearch'){
                        this.search.functionalitylist=result.data.data
                        this.search.functionalitylist.sort((a, b) => {
                           return a.label.localeCompare(b.label);
                        });
                        return true
                    } 
										else if(mdl == 't40'){
                        if(curracttab == 'testcase'){
                            //this.testcase.typelist=result.data.data.rows
                            return true
                        }
                    }
										else if(mdl == 't41'){
                        if(curracttab == 'testcase'){
                            this.testcase.sevairtylist=result.data.data.rows
                            return true
                        }
                    } else if(mdl == 't18' && curracttab == 'testcase') {
                            this.testcase.tasklist = result.data.data
                            return true
                    } else if(mdl == 't18' && curracttab == 'testcaseSearch') {
                        this.search.tasklist = result.data.data
                        return true
                    } else if(mdl == 't39') {
                        if(curracttab == 'testcase') {
                            this.testcase.testcasenameopts = result.data.data
                            return true
                        }
                    }
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
            this.displayError(e)
            })
            
        },
        onSelect(e){
            this.file = e.target.files[0];
        },
        onUpload(){
        if(this.file){
            this.isLoading=true
            if(this.file.length !== 0){
                let arrGlbMdl=this.glbMdl.split('##')
                if(arrGlbMdl.length > 0){
                    this.apiURL="api/master/fileupload/uploadBulkFileToS3"
                    let formData = new FormData();
                    let maxsize = 0;
                    for (let index = 0; index < this.file.length; index++) {
                        formData.append("imagefile", this.file[index]);
                        maxsize+= this.file[index].size
                    }
                    formData.append("empcode", this.undt.userid);
                    formData.append("useremail", this.undt.username);
                    formData.append("moduletype", 'testcase');
                    formData.append("fcount", parseInt(this.file.length));
                    if (maxsize > 1000 * 1000 * 25) {
                        this.isLoading = false;
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 25mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        this.testcase.disblesavebtn = true
                        return false; 
                    }
                    axios({
                        'content-type':'multipart/form-data',
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':formData,
                        'headers':{'authorization':this.tokendata}

                    })
                    .then(result => { 
                        this.isLoading=false;            
                        if(result.data.status){
                            this.testcase.disblesavebtn=false
                            const bindinfo = result.data.data.map((file, index) => {
                                return {"link":file,"info":this.testcase.filedescp[index]}                
                            });
                            let jsonobj = {data:bindinfo}
                            this.testcase.attachmentnew = JSON.stringify(jsonobj);
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    }).catch(e => {
                    this.displayError(e)
                    })
                }else{
                     this.isLoading=false; 
                    Swal.fire({
                        title: "Failed",
                        text: "Your document uploaded above 25mb not allow",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading=false
                    return false; 
                }
                axios({
                    'content-type':'multipart/form-data',
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':formData,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading=false;            
                    if(result.data.status){
                        
                        this.issue.disblesavebtn=false
                        const bindinfo = result.data.data.map((file, index) => {
                                return {"link":file,"info":this.issue.filedescp[index]}                
                            });
                            let jsonobj = {data:bindinfo}
                            this.issue.attachmentnew = JSON.stringify(jsonobj);
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.issue.attachment=null
                    }
                }).catch(e => {
                this.displayError(e)
                })
            }else{
                    this.isLoading=false; 
                Swal.fire({
                    title: "",
                    text: 'Select image/docs/videos',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.issue.attachment=null
            }
        } else {
            this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Select image/docs/videos',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.issue.attachment=null
        }
    },
			getTestcasetypeConfigLists(projectid) {
				let payload = {
					empcode: this.undt.userid,
					useremail: this.undt.username,
					projectid: parseInt(projectid.toString()),
					trackername: 'Testcase Type',
				};
				axios({
						method: "POST",
						url: '/api/businesstask/getTasktypeConfigList',
						data: payload,
						'headers':{'authorization':this.tokendata}
				}).then((result) => {
						if(result.data.errorCode == 0){
								let slist = [];
								result.data.data.rows.forEach((rec) => {
										if(Object.values(rec.statusconfig).length>0){
												Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
														if(statusnames.length>0) {
																statusnames.forEach(val => {
																		if(!slist.some(sdata => sdata.id === val)){
																				slist.push({ id: val, label: val });
																		}
																});
														}
												});
										}
								})
								this.sortTypeList(slist, 'alltype')
						} else {
								this.testcase.typelist = [];
						}
				}).catch(e => {
	this.displayError(e)
	})
      },
			sortTypeList(typelist, type){
        let numArrFromTypelist = [];
        typelist.forEach(element => {
					if(element.id != null) {
						numArrFromTypelist.push(element.id);
					}
        });
        if(numArrFromTypelist.length>0) {
					this.input = {
						useremail: this.undt.username,
						empcode: this.undt.userid,
						moduletype: 'testcase',
						typearr: numArrFromTypelist,
						projecttype: type
					};
					axios({
						method: "POST",
						url: 'api/ticket/sortTypeList',
						data: this.input,
						'headers':{'authorization':this.tokendata}
					}).then((result) => {
						this.isLoading = false;
						if (result.data.errorCode == 0) {
							this.testcase.typelist = result.data.data.rows;
                            this.testcase.typelist.sort((a, b) => {
                             return a.label.localeCompare(b.label);
                            });
						} else {
							this.testcase.typelist = [];
						}
            }).catch(e => {
            this.displayError(e)
            })
        }
      },
    },
    filters: {
      basename: function (value) {
          if (!value) return ''
          return value.substr(value.lastIndexOf("/") + 1)
      }
    }
}
</script>