<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div  v-if="pagefeatures.createbusinesstask" class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-2">Create Business Task</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                                    <router-link :to="{ name: 'Business Task List', params: { search: true }}"
                                    >Business Task Master List</router-link
                                    >
                                </li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Business Task Master</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div  class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
                        <div class="card-header py-1 px-2" style="background-color: #f1f2f3;">
                            <h3 class="card-title">
                                Create Business Task
                            </h3>
                        </div>
                        <div class="card-body pt-1">
                            <form>

                                <div class="row">
                                   <div class="form-group col-md-4">
                                        <label class="form-label" for="project-name">Business Task Name </label>
                                        <input class="form-control" type="text" placeholder="Enter Business Task Name " v-model="tasktype.name" @blur="validateForm('t9',$event)"
                                         v-bind:class="{
                                            'form-control': true,
                                            '': !validText1(tasktype.name) && nameBlured, }"
                                            v-on:blur="nameBlured = true"/>
                                            <div class="errorinputmsg" v-if="!validText1(tasktype.name) && nameBlured">{{this.errors.tasktype.name}}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="">Business Project Name</label>
                                        <treeselect class="projroleassprojectdd" placeholder="Select Business Project Name" :options="tasktype.businessnamelist" v-model="tasktype.businessname" @input='selectBusinessname' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.businessname">{{ this.errors.tasktype.businessname }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="redmineid">Redmine/Reference ID </label>
                                        <input :disabled="reminefieldflag" class="form-control" type="text" placeholder="Redmine/Reference ID " v-model="tasktype.redmineid" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.redmineid">{{ this.errors.tasktype.redmineid }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Project Name</label>
                                        <treeselect class="projectlistdd capitalisetext" :multiple="true"  :clear-on-select="true" placeholder="Select project name" v-model="tasktype.projectid" :options="tasktype.projectlist" @input="seltpprojectdata" @close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.projectid">{{ this.errors.tasktype.projectid }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Project Module</label>
                                        <treeselect placeholder="Select Project Module" class="projectmodule" :multiple="true"  :clear-on-select="true" v-model="tasktype.projectmoduleid" :options="tasktype.projectmodulelist" @input="selprojmodid" @close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.projectmoduleid">{{ this.errors.tasktype.projectmoduleid }}</div>
                                    </div>
                                    <div class="form-group mg-bot0 col-md-4">
                                        <label for="" class="d-block">Subscribers</label>
                                        <treeselect class="projroleassprojectdd capitalisetext" placeholder="Select subscribers" :multiple="true" :clear-on-select="true" :options="tasktype.subscriberslist" v-model="tasktype.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.subscribers">{{ this.errors.tasktype.subscribers }}</div>
                                    </div>
                                    <div class="col-md-4 mb-1">
                                        <div class="form-group">
                                        <label for="applicationmasterdd">Task Type
                                            <BTCtypetext/>
                                        </label>
                                        <treeselect v-model="tasktype.type" placeholder="Select Type" :options="tasktype.btmtasktypelist" @input="selecttypePriority" v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.type">{{ this.errors.tasktype.type }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="Department">Task Status</label>
                                        <treeselect placeholder="Select Task Status" :options="taskstatus" @input="selecttaskstatus" v-model="tasktype.tstatus" v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.tstatus">{{ this.errors.tasktype.tstatus }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="key">Task Severity </label>
                                        <treeselect placeholder="Select Task Severity" :options="tasktypekeylist" @input="selectserPriority" v-model="tasktype.key" v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.key">{{ this.errors.tasktype.key }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="project-name">Task Level </label>
                                        <treeselect placeholder="Select Task Level" :options="tasklevellist" @input="seltaskleveldata" v-model="tasktype.level" v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.level">{{ this.errors.tasktype.level }}</div>
                                    </div>

                                    <div class="form-group col-md-4" style="display:none ;">
                                        <label for="priority">Task Priority</label>
                                        <input class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }"  placeholder="Enter the Numbers Only..." v-model="tasktype.btmpriority"
                                        v-bind:class="{ 'form-control': true }"
                                        />
                                    </div>
                                    <div class="form-group col-md-4"  style="display:none ;">
                                        <label class="form-label" for="subpriority">Task Sub Priority</label>
                                        <input class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" placeholder="Enter the Numbers Only..." v-model="tasktype.subpriority" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="Department">Department</label>
                                        <treeselect placeholder="Select Department" :options="tasktype.deptlist" @input="selectbtmDept" @deselect="deSelectbtmDept" v-model="tasktype.dept" @blur="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.dept">{{ this.errors.tasktype.dept }}</div>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="bsh">HOD Name</label>
                                        <treeselect :disabled="readonlyflds.hodnamefld" class="projroleassprojectdd" placeholder="Select HOD Name " :options="tasktype.bshlist" v-model="tasktype.bsh"  @input="selectBSH" @blur="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.bsh">{{ this.errors.tasktype.bsh }}</div>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Requester</label>
                                        <treeselect class="projectlistdd capitalisetext" placeholder="Enter min 4 chars to search requester name" :clear-on-select="true" v-model="tasktype.requester" @input="selRequester" :options="requesterlist" v-on:search-change="getRequesterOnSearch"/>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Requester HOD</label>
                                        <treeselect class="projectlistdd capitalisetext" placeholder="Select Requester HOD Name" :clear-on-select="true" v-model="tasktype.requester_hod" :options="requesterhodlist" />
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="taskmanager">Task Manager</label>
                                        <treeselect :disabled="readonlyflds.taskmanagerfld" class="projroleassprojectdd" placeholder="Select Task Manager" :options="tasktype.taskmanagerlist" v-model="tasktype.taskmanager" @input='selTaskmanager' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskmanager">{{ this.errors.tasktype.taskmanager }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="taskowner">Task Owner (Lead)</label>
                                        <treeselect :disabled="readonlyflds.taskownerfld" class="projroleassprojectdd" placeholder="Select Task Owner" :options="tasktype.taskownerlist" v-model="tasktype.taskowner" @input='selTaskOwner'/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskowner">{{ this.errors.tasktype.taskowner }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="promanager">Project Manager</label>
                                        <treeselect :disabled="readonlyflds.promanagerfld" class="projroleassprojectdd" placeholder="Select Project Manager" :options="tasktype.pmlist" v-model="tasktype.proman" @input='selectProMan' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.proman">{{ this.errors.tasktype.proman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="programmanager">Program Manager</label>
                                        <treeselect :disabled="readonlyflds.progmanfld"  class="projroleassprojectdd" placeholder="Select Program Manager" :options="tasktype.proglist" v-model="tasktype.progman" @input='selectProgMan' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.progman">{{ this.errors.tasktype.progman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="engmanager">Engineering Manager</label>
                                        <treeselect :disabled="readonlyflds.engmanagerfld" class="projroleassprojectdd" placeholder="Select Engineering Manager" :options="tasktype.emlist" v-model="tasktype.engman" @input='selectEngMan' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.engman">{{ this.errors.tasktype.engman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="qamanager">QA Manager</label>
                                        <treeselect :disabled="readonlyflds.qamanagerfld" class="projroleassprojectdd" placeholder="Select QA Manager" :options="tasktype.qamnglist" v-model="tasktype.qamanager" @input='selectQAMan' v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.qamanager">{{ this.errors.tasktype.qamanager }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="qalead">QA Lead</label>
                                        <treeselect :disabled="readonlyflds.qaleadfld" class="projroleassprojectdd" placeholder="Select QA Lead" :options="tasktype.qaleadlist" v-model="tasktype.qalead" @input='selectQALead' v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.qalead">{{ this.errors.tasktype.qalead }}</div>
                                    </div>
                                    <div class="form-group mg-bot0 col-md-4 datePicker">
                                        <label for="" class="d-block">Start Date</label>
                                            <date-picker placeholder="Select Start Date" v-model="tasktype.startdate" valueType="format"  format="YYYY-MM-DD" class="startdate"  :disabled="isdisableds"></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.startdate">{{ this.errors.tasktype.startdate }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="References">Reference (Please mention the origin from where this task came from) </label>
                                        <input class="form-control" type="text" placeholder="Reference (Please mention the origin from where this task came from) " v-model="tasktype.references"
                                        v-bind:class="{
                                            'form-control': true,
                                            '': !valid2Text(tasktype.references) && referencesBlured, }"
                                            v-on:blur="referencesBlured = true"/>
                                        <div class="errorinputmsg" v-if="!valid2Text(tasktype.references) && referencesBlured">{{this.errors.tasktype.references}}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="References">Planner Name </label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 3 Chars To Search  Planner Name" v-model="tasktype.planner" v-on:search-change="getPlannerList" :options="tasktype.plannerlist" :multiple="true" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="bscontext">Business Context
                                            <BTCcontexttext/>
                                        </label>
                                        <treeselect class="mb-2" placeholder="Select Business Context" :options="tasktype.businesscontextList"  v-model="tasktype.businesscontext" @input="selectBusinessContext" @blur="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.businesscontext">{{ this.errors.tasktype.businesscontext }}</div>

                                        
                                    </div>
                                        <div class="form-group col-md-4">
                                            <label class="form-label">Security Sensitive</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Security Sensitive" v-model="tasktype.securitytag"  :options="securitylist" @input='selectsecurity' v-on:close="validateForm('t9',$event)" />
                                            <div class="errorinputmsg" v-if="this.errors.tasktype.securitytag">{{ this.errors.tasktype.securitytag }}</div>
                                        </div>
                                    <div class="form-group col-12 col-md-4">
                                    <div class="form-group" v-if="tasktype.securitytag">
                                        <label class="form-label" for="project-name">Security Justification</label>
                                        <textarea class="form-control" placeholder="Enter security comment or justification" rows="1" v-model="tasktype.securitycomment"></textarea>
                                        <div class="invalid-feedback">Security Justification is required</div>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.securitycomment">{{ this.errors.tasktype.securitycomment }}</div>
                                    </div>
                                    </div>
                                <div class="form-group col-md-12">
                                    <label class="form-label" for="project-name">Task Description </label>
                                    <ckeditor :editor="editor" placeholder="Task Description" @ready="prefill" v-model="tasktype.desc" :config="editorConfig" tag-name="textarea"
                                    v-bind:class="{
                                            'form-control': true,
                                            'is-invalid': !validText(tasktype.desc) && descBlured, }"
                                            v-on:blur="descBlured = true"/>
                                    <div class="invalid-feedback">Business task description required</div>
                                </div>
                                
                           
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_success_criteria">Business Success Criteria </label>
                                        <ckeditor :editor="editor" placeholder="Define clear  expectations from business to make it successful." v-model="tasktype.project_success_criteria" :config="editorConfig" tag-name="textarea"
                                            v-bind:class="{
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_success_criteria) && project_success_criteriaBlured, }"
                                                v-on:blur="project_success_criteriaBlured = true"/>
                                        <div class="invalid-feedback">Business success criteria is required</div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_fail_criteria">Business Failure Criteria </label>
                                        <ckeditor :editor="editor" placeholder="Business Failure Criteria" v-model="tasktype.project_fail_criteria" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_fail_criteria) && project_fail_criteriaBlured, }"
                                                v-on:blur="project_fail_criteriaBlured = true"/>
                                        <div class="invalid-feedback">Business failure criteria is required</div>
                                    </div>
                               
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_assumptions">Business Assumptions </label>
                                        <ckeditor :editor="editor" placeholder="Business Assumptions" v-model="tasktype.project_assumptions" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{'form-control': true,'is-invalid': !validText(tasktype.project_assumptions) && project_assumptionsBlured, }"
                                                v-on:blur="project_assumptionsBlured = true"/>
                                        <div class="invalid-feedback">Business assumptions is required</div>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_roi">Business ROI </label>
                                        <ckeditor :editor="editor" placeholder="Business ROI" v-model="tasktype.project_roi" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_roi) && project_roiBlured, }"
                                                v-on:blur="project_roiBlured = true"/>
                                        <div class="invalid-feedback">Business ROI is required</div>
                                    </div>
                                
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="business_impact">Business Impact </label>
                                        <ckeditor :editor="editor" placeholder="Business Impact" v-model="tasktype.business_impact" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{'form-control': true, 'is-invalid': !validText(tasktype.business_impact) && business_impactBlured, }"
                                                v-on:blur="business_impactBlured = true"/>
                                        <div class="invalid-feedback">Business impact is required</div>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="impact_of_live_feature">Impact on other live features if any </label>
                                        <ckeditor :editor="editor" placeholder="Impact on other live features if any." v-model="tasktype.impact_of_live_feature" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.impact_of_live_feature) && impact_of_live_featureBlured, }"
                                                v-on:blur="impact_of_live_featureBlured = true"/>
                                        <div class="invalid-feedback">Impact on other live features if any is required</div>
                                    </div>
                                </div>

                                <div class="row" style="display:none">
                                <div v-if="moduledocs.length > 0" >
                                <div v-for="(pdata, index) in moduledocs" :key="index" >
                                    <div class="card-body px-1 py-0">
                                        <form>
                                          <fieldset class="fieldset_border_vi mb-2">
                                              <legend>{{moduledocs[index].projectname}} : {{moduledocs[index].projectmodulename}}</legend>
                                            <div class="row">
                                                <div class="form-group col-md-3 business_hidden_input_space">
                                                    <label class="form-label" for="project-name">Project Name </label>
                                                    <input class="form-control" type="text" placeholder="Project Name" :disabled="isprojectSelected" :value="moduledocs[index].projectname"/>
                                                </div>
                                                <div class="form-group col-md-3 business_hidden_input_space">
                                                    <label class="form-label" for="redmineid">Project Module Name </label>
                                                    <input class="form-control" type="text" placeholder="Project Module Name" :disabled="isprojectmoduleSelected" :value="moduledocs[index].projectmodulename" />
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="modulepriority">Project Module Priority </label>
                                                    <input class="form-control" type="number" placeholder="Enter project module priority in numbers" v-model="moduledocs[index].modulepriority" />
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label for="Department">Project Module Status</label>
                                                    <treeselect placeholder="Select Project Module Status" class="projectmdllistdd capitalisetext" :options="taskstatus" v-model="moduledocs[index].modulestatus" />
                                                </div>
                                                 <div class="form-group col-md-4">
                                                    <label for="Department"> &nbsp;</label>
                                                    <div class="text-right bg-white rounded w-100">
                                                <div class="avatar btn-relief-primary rounded ml-1" v-if="moduledocs[index].modulestatus =='Product Pipeline' || moduledocs[index].modulestatus =='Product WIP' || moduledocs[index].modulestatus =='Product Completed' || moduledocs[index].modulestatus =='Product WIP 2'">
                                                    <div class="avatar-content_addbtn btn_padding-update_btn" @click="addModuleFileField(doclist, pdata.modulefiles)">
                                                        <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3 " style="height:3"></plus-square-icon>
                                                        Add Document
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            </div>

                                            <div class="row" v-for="(doclist, idx) in pdata.modulefiles" :key="`phoneInput-${index+idx}`">
                                                <div class="form-group col-md-3" >
                                                    <label class="form-label">Document Title </label>
                                                    <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title"/>
                                                    <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="form-label">Description</label>
                                                    <textarea class="form-control"  rows="1" placeholder="Description" v-model="doclist.desc"></textarea>
                                                    <div class="errorinputmsg" v-if="!doclist.desc">Document description is required</div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="form-label">Comment</label>
                                                    <textarea class="form-control" rows="1" placeholder="Comment" v-model="doclist.comment"/>
                                                    <div class="errorinputmsg" v-if="!doclist.comment">Comment is required</div>
                                                </div>
                                                <div class="form-group col-md-3" style="margin-top:25px">
                                                    <label for="attachment" class="btn btn-relief-success mb-0">Browse</label>
                                                    <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelectDoc($event) "/>

                                                    <button class="btn btn-relief-info  ml-1 uploadbutton" :data-id="idx" v-on:click.stop.prevent="onUploadDoc($event, index, doclist, 'modulefile', idx)" v-if="moduledocs[index].modulestatus =='Product Pipeline' || moduledocs[index].modulestatus =='Product WIP' || moduledocs[index].modulestatus =='Product Completed' || moduledocs[index].modulestatus =='Product WIP 2'"> Upload</button>
                                                    <div v-if="(idx>0)" class="avatar btn-relief-danger rounded ml-1">
                                                        <div class="avatar-content" @click="removeModuleFileField(index, idx, pdata.modulefiles)">
                                                            <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                        </div>
                                                    </div>
                                                    <div class="errorinputmsg" v-if="doclist.attachment == ''">Attachment is required</div>
                                                </div>
                                                <div class="table-responsive my-75" v-if="doclist.tempfilename">
                                                  <table class="table table-sm table-bordered mb-0" aria-describedby="mydesc">
                                                    <thead>
                                                          <tr>
                                                              <th scope="col">Document Title</th>
                                                              <th scope="col">Document Name</th>
                                                              <th scope="col">Action</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr>
                                                              <td>{{ doclist.tempdoctitile }}</td>
                                                              <td>{{ doclist.tempfilename.filename | basename }}</td>
                                                              <td>
                                                                  <button type="button" class="btn btn-sm danger" @click="removeImage(index, idx)">
                                                                      <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                                  </button>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                              </div>
                                            </div>

                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                                </div>
                                </div>

                                <div class="form-group  mt-1 mb-0 text-right" >
                                    <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" v-on:click.stop.prevent="saveData('t9',$event)">Submit</button>
                                    <button type="reset" class="btn btn-outline-secondary mr-1" @click="resetForm()">Reset</button>
                                    <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="model-title" for="tstatus" style="margin-left: 200px;">Enter the Estimation hours in Tech WIP</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <div class="row">
                            <form class="form form-vertical">
                                <div class="form-group col-12">
                                    <label class="form-label" for="tstatus">Please enter the effort estimation required for this task (Development efforts + QA efforts + external dependency if any</label>
                                </div>
                                    <div class="form-group col-md-6">
                                       <input id="swal-input1" class="form-control col-md-8" style="margin-left: 200px;" v-model="estimatehrs" @blur="validateHHHMM(estimatehrs)" placeholder="Please enter efforts in hours" >
                                        <div class="errorinputmsg" v-if="this.errors.validestimatehrs" style="margin-left: 200px;">{{ this.errors.validestimatehrs }}</div>
                                    </div>
                                    <div class="form-group col-md-12 flex--items">
                                        <input type="checkbox" id="accept" v-model="selcheckbox"/>
                                        <label class="form-label col-md-lg-12" style="margin-left: 10px;">Hereby I acknowledge that efforts entered are best of my knowledge and experience of engineering manager and projects leads on story points and efforts estimation</label>
                                    </div>
                                        <div class="errorinputmsg" v-if="this.errors.isChecked" style="margin-left: 10px;">{{ this.errors.isChecked }}</div>

                                <div class="form-group d-flex flex-wrap mt-1 mb-0 flex--items" >
                                    <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" :disabled="!this.validateHHHMM(this.estimatehrs) || !this.isChecked()" v-on:click.stop.prevent="saveestimatedhrs($event)">Submit</button>
                                    <button type="reset" class="btn btn-outline-secondary" @click="closeModal()">Cancel</button>
                                </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                 </div>
            </section>
        </div>
        <div v-else class="w-100 text-center">
            <h2 class="mb-1"> You are not authorized! 🔐 </h2>
            <p class="mb-2"> You don’t have permission to access this page!! </p>
            <img  src="../../../public/images/pages/not-authorized.e9c47c33.svg" alt="Not authorized page" class="img-fluid" />
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { PlusIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,EditIcon,SearchIcon,MoreVerticalIcon,Edit2Icon,
ClipboardIcon,EyeIcon,UploadCloudIcon,UserIcon,Trash2Icon } from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMethods from '../../utils/commonMethods';
import BTCtypetext from "../constanttemplates/BTCtypetext";
import BTCcontexttext from "../constanttemplates/BTCcontexttext";

export default {
    name:'BTMCreate',
    components:{
        Loading,PlusSquareIcon,DownloadIcon,EyeIcon,UploadCloudIcon,
        VueElementLoading,MoreVerticalIcon,Edit2Icon,MinusSquareIcon,ClipboardIcon,
        vSelect,PlusIcon,
        EditIcon,
        Treeselect,
        DatePicker,
        SearchIcon,
        UserIcon,
        Pagination,
        ckeditor: CKEditor.component,
        Trash2Icon,
        BTCtypetext, BTCcontexttext
    },
    mixins: [ commonMethods ],

    data() {
        return {
            selcheckbox:null,
            validestimatehrs:true,
            isinputtechwiphrs:false,
            estimatehrs: null,
            projectLevels: [],
            projectmultiplename: [],
            multidimensionalArray: [],
            finalmultidimensionalArray: [],
            finalvalidationArray: [],
            businesstaskcodelistopts:null,
            undt:'',
            isSearchRequestActive:false,
            istrue:false,
            show:'display',
            isdisabled:true,
            isActive:true,
            isLoading: false,
            fullPage: true,
            companymasterlist: [],
            tasktypelist: [],
            typenamelist: [],
            projectlist: [],
            taskassignmentList:[],
            showAddForm:false,
            selected: null,
            editRowData:[],
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            projectType:[],
            projectdescription:'',
            tasktypekeylist:apiUrl.tasktypekeylist.sort((a, b) => a.label.localeCompare(b.label)),
            requesterlist: [],
            requesterhodlist: [],
            tasktype:{
                securitycomment: null,
                securitytag: null,
                plannerlist: [],
                planner: [],
                requester:null,
                requester_hod:null,
                startdate: null,
                ehrs:null,
                name:null,
                edit:false,
                projectid:null,
                subscriberslist: [],
                taskmanagerlist: [],
                taskownerlist: [],
                subscribers: null,
                projectlist:[],
                desc:null,
                project_success_criteria:null,
                project_fail_criteria:null,
                project_assumptions:null,
                project_roi:null,
                business_impact:null,
                impact_of_live_feature:null,
                level:null,
                descerror:null,
                levelerror:null,
                type: null,
                key: null,
                redmineid: null,
                btmpriority: null,
                typelist:apiUrl.typelist,
                admintypelist:apiUrl.admintypelist,
                keylist:apiUrl.keylist,
                referenceslist:apiUrl.referenceslist,
                references: null,
                bsh: null,
                pmlist: null,
                emlist: null,
                proglist: null,
                proman: null,
                progman: null,
                engman: null,
                businessnamelist: null,
                businessname: null,
                psh: null,
                taskmanager: null,
                taskowner: null,
                priorityerr: null,
                dept: null,
                depterr: null,
                deptlist:apiUrl.deptlist.sort((a, b) => a.label.localeCompare(b.label)),
                enddate: null,
                tstatus:null,
                isedit:false,
                taskid:null,
                subpriority: null,
                bshlist: [],
                projectmoduleid: null,
                projectmodulelist:[],
                qalead: null,
                qaleadlist:[],
                qamanager:null,
                qamnglist:[],
                documentlist:[{
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:''
                }],
                btmtasktypelist:[],
                businesscontext:null,
                businesscontextList:apiUrl.businesscontextList.sort((a, b) => a.label.localeCompare(b.label)),
                // billingcountry:null,
                // billingcountrylist:apiUrl.countrylist,
            },
            tasknamemaster:apiUrl.tasknamemaster,
            taskstatus:[],
            taskBlured:false,
            valid : false,
            projvalue:null,
            applvalue:null,
            applicoptions:[ {
                id: '',
                label: ''
            }],
            errors: {
                isChecked:null,
                validestimatehrs:null,
                tasktype:{
                    name:null,
                    securitycomment: null,
                    projectid:null,
                    desc:null,
                    prjecto_success_criteria:null,
                    project_fail_criteria:null,
                    project_assumptions:null,
                    project_roi:null,
                    business_impact:null,
                    impact_of_live_feature:null,
                    level:null,
                    descerror:null,
                    securitytag: null,
                    levelerror:null,
                    type: null,
                    key: null,
                    redmineid: null,
                    btmpriority: null,
                    references: null,
                    bsh: null,
                    proman: null,
                    progman: null,
                    engman: null,
                    psh: null,
                    taskmanager: null,
                    taskowner: null,
                    priorityerr: null,
                    dept: null,
                    depterr: null,
                    startdate: '',
                    enddate: null,
                    tstatus:null,
                    subscribers: null,
                    businessname:null,
                    projectmoduleid: null,
                    qamanager:null,
                    qalead: null,
                    businesscontext: null
                },
                docs:{
                    title:null,
                    docs:null,
                    comment:null,
                    attachment:null
                }
            },
            tasklevel:null,
            tasklevellist:[ {
                id: '',
                label: ''
            }],
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            projectnameist:[],
            loginuscondition:'',
            attachment: [],
            attachmentview: null,
            securitylist: [
            { id: 1, label: 'Yes'},
            { id: 0,label: 'No'},
            ],
            docs:[
                {
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:''
                }
            ],
            doclist:[],
            editdoclist:[

            ],
            file: '',
            alldocs:[],
            isDetActive:false,
            isRequestActive:true,
            isdocsRequestActive:false,
            isEditDocsRequestActive: false,
            card_content:'card-content',
            collapse:'collapse',
            files:[],
            loginusername:'',
            addocument:true,
            editdocument: true,
            card:'card',
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
               toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertTable',
                        '|',
                        'undo',
                        'redo', '|',
                    ],
                },
            },
            formActionLabel: 'Create Business Task',
            nameBlured: false,
            descBlured:false,
            project_success_criteriaBlured:null,
            project_fail_criteriaBlured:null,
            project_assumptionsBlured:null,
            project_roiBlured:null,
            business_impactBlured:null,
            impact_of_live_featureBlured:null,
            priorityBlured:false,
            referencesBlured:false,
            bshBlured:false,
            tokendata:null,
            holdassessmentid: null,
            isdisableds: false,
            moduledocs:[],
            isprojectSelected: true,
            isprojectmoduleSelected: true,
            isAllModuleDocsAdded: true,
            myfiles: [],
            accepttype:apiUrl.uploadfiletype,
            userrole:null,
            readonlyflds: {
                hodnamefld: false,
                taskmanagerfld: false,
                taskownerfld: false,
                promanagerfld: false,
                engmanagerfld: false,
                progmanfld: false,
                qamanagerfld:false,
                qaleadfld: false,
            },
            userDepName: null,
            trCode: null,
            reminefieldflag: false,
            isRedirectedFromTR: false,
            ticketData: null,
            securitytagchk: null,
            securitycommentBlured: false,
        }
    },
    created() {
        this.pagefeatures = {}
        if(window.localStorage.getItem('pagefeatures')){
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/businesstask/list') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        }
    },
    filters: {
        basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
        }
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.roleid = userdata.roleid
            this.undt=userdata
            this.loginusername=userdata.username
            this.loginuscondition = userdata.userid
            this.tasktype.deptlist = apiUrl.deptlist
            this.tasktype.typelist = apiUrl.typelist
            this.getApplicationdata('t24','task');
            this.getBusinessname();
            this.getProjectAllocatedToEmp();
            this.getUserDepName();
            if(this.userrole=='product'){
                this.tasktype.tstatus = 'Product Pipeline';
                this.tasktype.key = 'Normal';
                this.tasktype.level = 6;
                this.tasktype.dept = 'Product';
            }
            this.$nextTick(() => {
                const contentContainers = document.querySelectorAll(".content-container");
                contentContainers.forEach(function (container) {
                const content = container.querySelector(".content02");
                const readMoreBtn = container.querySelector(".read-more");
                const readLessBtn = container.querySelector(".read-less");

                readMoreBtn.addEventListener("click", function () {
                    readMoreBtn.style.display = "none";
                    readLessBtn.style.display = "block";
                    content.classList.add("read-remove");
                });

                readLessBtn.addEventListener("click", function () {
                    readMoreBtn.style.display = "block";
                    readLessBtn.style.display = "none";
                    content.classList.remove("read-remove");
                });
                });
            });
            
            if (this.$route.params.backbtnstate === 'createTicketBTC') {
                let tddata = this.$route.params.clickeddata;
                console.log(tddata)
                if(tddata) {
                    this.isRedirectedFromTR = true;
                    this.tasktype.projectid = [tddata.rowTData.projectid];
                    this.getemployeedetails(tddata.rowTData.createdby);
                    this.tasktype.redmineid = tddata.rowTData.issuecode;
                    this.reminefieldflag = true;
                    this.trCode = 'Imported from '+ tddata.rowTData.issuecode +' created by Business team.';
                    this.ticketData = tddata.rowTData;
                }
            }
        }
    },
    methods:{
        getemployeedetails: function(empid){
        this.isLoading = true;
        this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empemailid: empid,
            moduletype: 'Appraisal Cycle'
        }
        axios({
            'method': 'POST',
            'url': 'api/feedbackform/getemployeedetails',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
                if(result.data.data.rows.length>0) {
                    result.data.data.rows.forEach(element => {
                        let tempemplabel2 = element.department ? this.decryptText(element.department) : null;
                        this.requesterlist.push({ "id": element.empcode, "label": element.empname +'('+tempemplabel2 +')' });
                        this.tasktype.requester = parseInt(element.empcode);
                    });
                }
            }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        prefill( editor ) {
            this.tasktype.desc = this.trCode;
        },
        getPlannerList(node) {
            if(node && node.length>2){
            axios({
                method: "POST",
                url: "api/businesstask/getPlannerList",
                headers: { authorization : this.tokendata },
                data:{plannertitle: node.toLowerCase(),empcode: this.undt.userid,useremail: this.undt.username}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.plannerlist = result.data.data
                } else {
                    this.tasktype.plannerlist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
            }
        },
        selRequester(state) {
            if(state){
                this.getRequesterHODOnSearch(state);
            }else{
                this.tasktype.requester_hod = null;
            }
        },
        getRequesterOnSearch(node) {
            if(node != null && node != '' && node.length > 4) {
                this.isLoading = true;
                let url = "api/master/getemployeebyname";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    empname: node ? node.toLowerCase() : null,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.requesterlist = result.data.data.rows;
                        let decryptdesignationtext = this.requesterlist.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                    } else {
                        this.requesterlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } else {
                this.requesterlist = [];
            }
        },
        getRequesterHODOnSearch(id) {
            console.log(id)
            this.isLoading = true;
            let url = "api/listing/getRequesterOnSearch";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                requesterid: id,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.requesterhodlist = result.data.data.rows;
                } else {
                    this.requesterhodlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getUserDepName(){
            this.apiURL="api/profile/getUserDepartment"
            this.input={
                empcode: this.undt.userid,
                useremail:this.undt.username,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    let response = result.data.data;
                    this.userDepName = response[0].department
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        removeImage (itemindex, rowindex) {
            if(itemindex!=null && rowindex!=null) {
                this.moduledocs[itemindex]['modulefiles'][rowindex]['tempfilename'] = null;
                this.moduledocs[itemindex]['modulefiles'][rowindex]['tempdoctitile'] = null;
            }
        },
        getProjectByBusinessTask(moduleids){
            this.moduledocs = []
            this.input = { midarr:moduleids, type: 'add', bid: null,empcode: this.undt.userid,useremail: this.undt.username }
            let url = "api/bt/getProModuleDetailsWithProject";
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                if(result.data.errorCode == 0) {
                    for(let i = 0; i < result.data.data.length; i++){
                        let moduleArr = {
                            'projectid':result.data.data[i].pid,
                            'projectname': result.data.data[i].pname,
                            'projectmoduleid': result.data.data[i].moduleid,
                            'projectmodulename': result.data.data[i].projectmodulename,
                            'modulestatus': null,
                            'modulepriority': null,
                             modulefiles: []
                        }
                        this.moduledocs.push(moduleArr)
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        addModuleFileField(pdata, fieldType, opt) {
            if(opt=='edit') {
                let row = fieldType[fieldType.length-1];
                if(row.businessdocmasterid == undefined) return false
            }
            if(opt === 'moduledocs') {
                fieldType.push({
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:'',
                    filename: null,
                    batchid: null,
                    isUploaded: false,
                    taskid:this.tasktype.taskid,
                    projectname: pdata.projectname,
                    projectid: pdata.projectid,
                    projectmodulename: pdata.projectmodulename,
                    projectmoduleid: pdata.moduleid,
                    modulepriority: pdata.modulepriority,
                    modulestatus: pdata.modulestatus,
                    businesstaskmoduleassignmentid:pdata.businesstaskmoduleassignmentid,
                    businesstaskmasterid:pdata.businesstaskmasterid,
                });
            } else {
                fieldType.push({
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:'',
                    filename: null,
                    batchid: null,
                    isUploaded: false,
                    tempfilename: null,
                    tempdoctitile: null,
                })
            }
        },
        removeModuleFileField(index, idx, fieldType) {
            if(fieldType && fieldType.length>1){
                fieldType.splice(idx,1)
            }
        },
        isChecked(){
            let z = document.getElementById('accept')
            if(!z) return false
            if(!z.checked){
                this.errors.isChecked = "Please accept terms and conditions"

            }else {
                this.errors.isChecked = ""
            }
            return z.checked
        },
        closeModal(){
            this.validestimatehrs = false
            this.estimatehrs = null
            $('#technologypopup').modal('hide');
        },
        formatData(data) {
            return data
        },
        docsuploadcollapse:function(){
            if(this.docs){
                for(const i in this.docs){
                    this.docs[i].comment=null
                    this.docs[i].desc=null
                    this.docs[i].title=null
                    if(i>0){
                        this.docs.splice(i, 1);
                    }
                }
            }
            this.isDetActive=true
            this.addocument=true
            this.editdocument = true
            this.isdocsRequestActive=!this.isdocsRequestActive

        },
        myeditdocrequestollapse: function() {
            this.isEditDocsRequestActive =!this.isEditDocsRequestActive
        },
        myadddocrequestollapse: function() {
            this.isdocsRequestActive =!this.isdocsRequestActive
        },
        myrequestollapse:function(){
            this.tasktype.name=null
            this.tasktype.proman=null
            this.tasktype.progman=null
            this.tasktype.engman=null
            this.tasktype.desc= ''
            this.tasktype.securitycomment = ''
            this.tasktype.securitytag = null
            this.tasktype.project_success_criteria=null
            this.tasktype.project_fail_criteria=null
            this.tasktype.project_assumptions=null
            this.tasktype.project_roi=null
            this.tasktype.business_impact=null
            this.tasktype.impact_of_live_feature=null
            this.tasktype.level=null
            this.tasktype.projectid=null
            this.tasktype.type=null
            this.tasktype.key=null
            this.tasktype.redmineid=null
            this.tasktype.btmpriority=null
            this.tasktype.references=null
            this.tasktype.bsh=null
            this.tasktype.psh=null
            this.tasktype.taskmanager=null
            this.tasktype.taskowner=null
            this.tasktype.priorityerr=null
            this.tasktype.dept=null
            this.tasktype.depterr=null
            this.tasktype.startdate = null
            this.tasktype.enddate = null
            this.tasktype.tstatus = null
            this.tasktype.projectmoduleid = null
            this.tasktype.planner = null
            this.tasktype.businesscontext = null
            this.formActionLabel = 'Create Business Task'
        },
        resetForm() {
            this.tasktype.name=null
            this.tasktype.proman=null
            this.tasktype.progman=null
            this.tasktype.engman=null
            this.tasktype.desc= ''
            this.tasktype.securitycomment = ''
            this.tasktype.securitytag = null
            this.tasktype.project_success_criteria=null
            this.tasktype.project_fail_criteria=null
            this.tasktype.project_assumptions=null
            this.tasktype.project_roi=null
            this.tasktype.business_impact=null
            this.tasktype.impact_of_live_feature=null
            this.tasktype.level=null
            this.tasktype.projectid=null
            this.tasktype.type=null
            this.tasktype.key=null
            this.tasktype.subscribers = null
            this.tasktype.redmineid=null
            this.tasktype.btmpriority=null
            this.tasktype.references=null
            this.tasktype.bsh=null
            this.tasktype.psh=null
            this.tasktype.taskmanager=null
            this.tasktype.taskowner=null
            this.tasktype.priorityerr=null
            this.tasktype.dept=null
            this.tasktype.depterr=null
            this.tasktype.startdate = null
            this.tasktype.enddate = null
            this.tasktype.tstatus = null
            this.tasktype.businessname = null
            this.tasktype.projectmoduleid = null
            this.tasktype.planner = null
            this.tasktype.businesscontext = null
            this.getProjectAllocatedToEmp();
        },
        addField(value, fieldType,opt='add') {
            if(opt=='edit') {
                let row = fieldType[fieldType.length-1];
                if(row.businessdocmasterid == undefined) return false
            }
            this.isActive=false
            fieldType.push({ value: "" });
        },
        removeField(index, fieldType) {
            if(fieldType && fieldType.length>1){
                this.docs.splice(index,1);
                this.attachment.splice(index,1);
            }
        },
        removeEditField(index, fieldType,data) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                if(data.businessdocmasterid !== undefined) {
                    axios({
                    method: "POST",
                    url: 'api/workstep/deletebusinesstaskdoc',
                    data: {id:data.businessdocmasterid,deleteby:this.undt.username,empcode: this.undt.userid,useremail: this.undt.username},
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.status) {
                        fieldType.splice(index, 1);
                        Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'success'
                        )
                    } else {
                       Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'error'
                        )
                    }
                });
                } else {
                    fieldType.splice(index, 1);
                }
            }
            })
        },
        dateFormatterSales: function(dt) {
            let dateoptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
            };
            return dt.toLocaleDateString("en-GB", dateoptions);
        },
        UpdateDocument(objdata) {
            if(objdata.title == "" || objdata.title == undefined || objdata.description == "" || objdata.description == undefined || objdata.comment == "" || objdata.comment == undefined) {
                return false;
            } else {
                if(this.files && this.files.length) {
                    this.isLoading=true
                    let url = "api/s3upload/editnewbusinessdocument";
                    this.files.forEach(file => {
                        if (file.size > 1024 * 1024 * 5) {
                            Swal.fire({
                                title: "Failed",
                                text: "Your document uploaded above 5mb not allow",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            this.isLoading=false
                            return false;
                        }
                        if(file.name) {
                            let formData = new FormData();
                            if(objdata.businessdocmasterid) {
                                let newversion =  objdata.docverison !== '' && objdata.docverison !== undefined ? Number(objdata.docverison.substr(1)) +1:'v1';
                                formData.append("version","v"+newversion );
                                formData.append("updatedby",this.undt.username );
                                formData.append("taskid", objdata.taskid );
                                formData.append("businessdocid", objdata.businessdocmasterid);
                                url = "api/s3upload/updatebusinessdocument";
                            } else {
                                url = "api/s3upload/editnewbusinessdocument";
                                formData.append("createdby",this.undt.username );
                                formData.append("taskid", this.tasktype.taskid);
                            }
                            formData.append("imagefile", file);
                            formData.append("moduletype", 'businesstask')
                            formData.append("title", objdata.title);
                            formData.append("desc", objdata.description);
                            formData.append("comment", objdata.comment);
                            formData.append("empcode", this.undt.userid);
                            formData.append("useremail", this.undt.username);

                            axios({
                                "content-type": "multipart/form-data",
                                method: "POST",
                                url: url,
                                data: formData,
                                'headers':{'authorization':this.tokendata}
                            }).then((result) => {
                                if (result.data.status) {
                                    this.files=[];
                                    let currRowData = {
                                        moduleid: this.tasktype.taskid
                                    }
                                    this.getDocumetList(currRowData)
                                    Swal.fire({
                                        title: "Success!",
                                        text: result.data.msg,
                                        icon: "success",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    });
                                    this.isLoading=false
                                } else {
                                    Swal.fire({
                                        title: "Failed",
                                        text: "Select business task image/docs/videos",
                                        icon: "info",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    })
                                    this.isLoading=false
                                }
                            })
                        }
                    })
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: "Select document",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }
        },
        saveestimatedhrs(e){
            let z = document.getElementById('accept').checked
            if(this.estimatehrs && z){
                this.tasktype.ehrs = this.estimatehrs;
                this.isinputtechwiphrs = true;
                this.validestimatehrs = true;
                $('#technologypopup').modal('hide');
                this.saveData('t9')
            }
        },
        saveData(mdl,objThis){
            let isValid = true;
            isValid = this.validateForm(mdl,objThis);
            if(this.tasktype.tstatus == 'Tech WIP' && !this.isinputtechwiphrs && isValid){
                this.validestimatehrs = false;
                 $('#technologypopup').modal('show');
            }else{
                this.validestimatehrs = true;
            }
            if(this.tasktype.tstatus != 'Tech WIP'){
                this.tasktype.ehrs = ''
            }

            if (isValid && this.validestimatehrs) {
                this.isLoading = true;
                this.clearerrorForm();
                let nameobj=document.getElementById('modulename'+mdl)
                this.input = {
                    createdby: this.undt.username,
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    userrole:this.userrole,
                }
                let apiURL= 'api/master/businesstask/saveBusinessProject'
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                if(mdl == 't9'){
                    this.input.name=this.tasktype.name
                    if(this.tasktype.desc){
                        this.input.desc=this.tasktype.desc
                    }
                    
                    if(this.tasktype.securitytag==1){
                        this.input.securitytag = 1;
                        this.input.securitycomment = this.tasktype.securitycomment;
                    }else if(this.tasktype.securitytag==0){
                        this.input.securitytag = 0;
                    }else{
                        this.input.securitytag= null;
                    }

                    if(this.tasktype.project_success_criteria){
                        this.input.project_success_criteria=this.tasktype.project_success_criteria
                    }
                    if(this.tasktype.project_fail_criteria){
                        this.input.project_fail_criteria=this.tasktype.project_fail_criteria
                    }
                    if(this.tasktype.project_roi){
                        this.input.project_roi=this.tasktype.project_roi
                    }
                    if(this.tasktype.business_impact){
                        this.input.business_impact=this.tasktype.business_impact
                    }
                    if(this.tasktype.impact_of_live_feature){
                        this.input.impact_of_live_feature=this.tasktype.impact_of_live_feature
                    }
                    if(this.tasktype.project_assumptions){
                        this.input.project_assumptions=this.tasktype.project_assumptions
                    }
                    if(this.tasktype.level != null && this.tasktype.level != 'undefined'){
                        this.input.level=this.tasktype.level
                    }
                    if(this.tasktype.projectid != null && this.tasktype.projectid != 'undefined'){
                        this.input.projectid=JSON.stringify(this.tasktype.projectid)
                    }
                    if(this.tasktype.redmineid != null && this.tasktype.redmineid != ""){
                        this.input.redmineid = this.tasktype.redmineid
                    }else{
                        this.input.redmineid = null
                    }
                    if(this.tasktype.taskmanager != null && this.tasktype.taskmanager != ""){
                        this.input.productstackholder = this.tasktype.taskmanager
                    }else{
                        this.input.productstackholder = null
                    }
                    if(this.tasktype.taskowner != null && this.tasktype.taskowner != ""){
                        this.input.taskowner = this.tasktype.taskowner
                    }else{
                        this.input.taskowner = null
                    }
                    // if(this.tasktype.projectmoduleid != null && this.tasktype.projectmoduleid != 'undefined'){
                    //     this.input.projectmoduleid=JSON.stringify(this.tasktype.projectmoduleid)
                    // }else{
                    //     this.input.projectmoduleid = null
                    // }
                    if(this.tasktype.type=='Business Task - New Feature'){
                        this.input.tasktype ='Business Task'
                    }
                    else if(this.tasktype.type=='Business Task - Enhancement') {
                        this.input.tasktype ='Enhancement'
                    } else{
                        this.input.tasktype = this.tasktype.type
                    }
                    this.input.taskkey = this.tasktype.key
                    this.input.priority = this.tasktype.btmpriority
                    this.input.reference = this.tasktype.references
                    this.input.businessstackholder = this.tasktype.bsh
                    this.input.requester =  this.tasktype.requester
                    this.input.requester_hod = this.tasktype.requester_hod
                    this.input.projectmaanager = this.tasktype.proman?(this.tasktype.proman).toLowerCase():null;
                    this.input.engineeringmaanager = this.tasktype.engman?(this.tasktype.engman).toLowerCase():null;
                    this.input.department = this.tasktype.dept
                    this.input.startdate = this.tasktype.startdate
                    this.input.estimatedddate = this.tasktype.enddate?this.tasktype.enddate:null
                    this.input.attachment = this.attachment
                    this.input.taskstatus = this.tasktype.tstatus
                    this.input.estimatedhours = this.estimatehrs
                    this.input.subscribers = this.tasktype.subscribers
                    this.input.planner = this.tasktype.planner
                    this.input.estimatedhours = this.tasktype.ehrs
                    this.input.subpriority = this.tasktype.subpriority
                    this.input.businessname = this.tasktype.businessname
                    this.input.projectmoduledata = this.moduledocs
                    this.input.qalead = this.tasktype.qalead
                    this.input.qamanager = this.tasktype.qamanager
                    this.input.programmanager = this.tasktype.progman?(this.tasktype.progman).toLowerCase():null;
                    this.input.user_department = this.userDepName;
                    if(this.isRedirectedFromTR) {
                        this.input.isRedirectedFromTR = this.isRedirectedFromTR;
                        this.input.ticketData = this.ticketData;
                    }
                    if(this.input.programmanager && this.input.programmanager != null){
                        if(this.tasktype.proglist && this.tasktype.proglist != null && this.tasktype.proglist.length>0) {
                            const rowFound = this.tasktype.proglist.filter(el => el.id == this.input.programmanager);
                            this.input.programmanagerid = rowFound?(rowFound[0].employeeid != undefined) ? rowFound[0].employeeid : null :null;
                        }
                    }
                    if(this.input.qalead && this.input.qalead != null){
                        if(this.tasktype.qaleadlist && this.tasktype.qaleadlist != null && this.tasktype.qaleadlist.length>0) {
                            const rowFound1 = this.tasktype.qaleadlist.filter(el => el.id == this.input.qalead);
                            this.input.qaleadid = rowFound1?(rowFound1[0].employeeid!=undefined)?rowFound1[0].employeeid:null :null;
                        }
                    }
                    if(this.input.qamanager && this.input.qamanager != null){
                        if(this.tasktype.qamnglist && this.tasktype.qamnglist != null && this.tasktype.qamnglist.length>0) {
                            const rowFound2 = this.tasktype.qamnglist.filter(el => el.id == this.input.qamanager);
                            this.input.qamanagerid = rowFound2?(rowFound2[0].employeeid!=undefined)?rowFound2[0].employeeid : null :null;
                        }
                    }
                    if(this.input.taskowner && this.input.taskowner != null){
                        if(this.tasktype.taskownerlist && this.tasktype.taskownerlist != null && this.tasktype.taskownerlist.length>0) {
                            const rowFound3 = this.tasktype.taskownerlist.filter(el => el.id == this.input.taskowner);
                            this.input.taskownerid = rowFound3?(rowFound3[0].employeeid!=undefined)?rowFound3[0].employeeid:null:null;
                        }
                    }
                    if(this.input.projectmaanager && this.input.projectmaanager != null){
                        if(this.tasktype.pmlist && this.tasktype.pmlist != null && this.tasktype.pmlist.length>0) {
                            const rowFound4 = this.tasktype.pmlist.filter(el => el.id == this.input.projectmaanager);
                            this.input.projectmanagerid = rowFound4?(rowFound4[0].employeeid!=undefined)?rowFound4[0].employeeid:null:null;
                        }
                    }
                    if(this.input.engineeringmaanager && this.input.engineeringmaanager != null){
                        if(this.tasktype.emlist && this.tasktype.emlist != null && this.tasktype.emlist.length>0) {
                            const rowFound5 = this.tasktype.emlist.filter(el => el.id == this.input.engineeringmaanager);
                            this.input.engineeringmanagerid = rowFound5?(rowFound5[0].employeeid!=undefined)?rowFound5[0].employeeid:null:null;
                        }
                    }
                    this.input.businesscontext = this.tasktype.businesscontext ? this.tasktype.businesscontext : null
                }
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                    if(result.data.errorCode == 0){
                       Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.myrequestollapse();
                        this.$router.push({ name: 'Business Task List', params: { search: true }});
                    } else if(result.data.errorCode == 3){
                            Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                        } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                })
                .catch(e => {
                    this.displayError(e)
                    this.isLoading = false;
                    Swal.fire({
                        title: "",
                        text: e,
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                })
            }
        },
        readOnlyFieldsValidation(isValid) {
            if(this.finalvalidationArray['HOD Name'])
            {
                this.errors.tasktype.bsh='';
                if(this.finalvalidationArray['HOD Name'] == 'Required')
                {
                    if(!this.tasktype.bsh){
                        this.errors.tasktype.bsh="HOD name is required!";
                        isValid= false;
                    }
                    this.readonlyflds.hodnamefld = false;
                } else if (this.finalvalidationArray['HOD Name'] == 'Read-Only') {
                    this.readonlyflds.hodnamefld = true;
                } 
            } else {
                if(!this.tasktype.bsh){
                    this.errors.tasktype.bsh="HOD name is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.bsh='';
                }
                this.readonlyflds.hodnamefld = false;
            }

            if(this.finalvalidationArray['Project Manager'])
            {
                this.errors.tasktype.proman='';
                if(this.finalvalidationArray['Project Manager'] == 'Required')
                {
                    if(!this.tasktype.proman){
                    this.errors.tasktype.proman="Project Manager is required!";
                    isValid= false;
                    }
                    this.readonlyflds.promanagerfld = false;
                } else if (this.finalvalidationArray['Project Manager'] == 'Read-Only') {
                    this.readonlyflds.promanagerfld = true;
                }
            } else {
                if(!this.tasktype.proman){
                    this.errors.tasktype.proman="Project Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.proman='';
                }
                this.readonlyflds.promanagerfld = false;
            }
              
            if(this.finalvalidationArray['Task Manager'])
            {
                this.errors.tasktype.taskmanager='';
                if(this.finalvalidationArray['Task Manager'] == 'Required')
                {
                    if(!this.tasktype.taskmanager){
                        this.errors.tasktype.taskmanager="Task Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.taskmanagerfld = false;
                } else if (this.finalvalidationArray['Task Manager'] == 'Read-Only') {
                    this.readonlyflds.taskmanagerfld = true;
                }
            } else {
                if(!this.tasktype.taskmanager){
                    this.errors.tasktype.taskmanager="Task Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.taskmanager='';
                }
                this.readonlyflds.taskmanagerfld = false;
            }

            if(this.finalvalidationArray['Task Owner'])
            {
                this.errors.tasktype.taskowner='';
                if(this.finalvalidationArray['Task Owner'] == 'Required')
                {
                    if(!this.tasktype.taskowner){
                        this.errors.tasktype.taskowner="Task Owner is required!";
                        isValid= false;
                    }
                    this.readonlyflds.taskownerfld = false;
                } else if (this.finalvalidationArray['Task Owner'] == 'Read-Only') {
                    this.readonlyflds.taskownerfld = true;
                }
            } else {
                if(!this.tasktype.taskowner){
                    this.errors.tasktype.taskowner="Task Owner is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.taskowner='';
                }
                this.readonlyflds.taskownerfld = false;
            }
            
            if(this.finalvalidationArray['Engineering Manager'])
            {
                this.errors.tasktype.engman='';
                if(this.finalvalidationArray['Engineering Manager'] == 'Required')
                {
                    if(!this.tasktype.engman){
                        this.errors.tasktype.engman="Engineering Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.engmanagerfld = false;
                } else if (this.finalvalidationArray['Engineering Manager'] == 'Read-Only') {
                    this.readonlyflds.engmanagerfld = true;
                } 
            } else {
                if(!this.tasktype.engman){
                    this.errors.tasktype.engman="Engineering Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.engman='';
                }
                this.readonlyflds.engmanagerfld = false;
            }

            if(this.finalvalidationArray['QA Manager'])
            {
                this.errors.tasktype.qamanager='';
                if(this.finalvalidationArray['QA Manager'] == 'Required')
                {
                    if(!this.tasktype.qamanager){
                        this.errors.tasktype.qamanager="QA Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.qamanagerfld = false;
                } else if (this.finalvalidationArray['QA Manager'] == 'Read-Only') {
                    this.readonlyflds.qamanagerfld = true;
                } 
            } else {
                if(this.tasktype.tstatus == "Tech and Business Review"){
                    if(!this.tasktype.qamanager && this.tasktype.qamanager==null){
                        this.errors.tasktype.qamanager="QA Manager is required.";
                        isValid= false;
                    }
                }
                else if(!this.tasktype.qamanager){
                    this.errors.tasktype.qamanager="QA Manager is required";
                    isValid= false;
                } else {
                    this.errors.tasktype.qamanager='';
                }
                this.readonlyflds.qamanagerfld = false;
            }

            if(this.finalvalidationArray['QA Lead'])
            {
                this.errors.tasktype.qalead='';
                if(this.finalvalidationArray['QA Lead'] == 'Required')
                {
                    if(!this.tasktype.qalead){
                        this.errors.tasktype.qalead="QA Lead is required!";
                        isValid= false;
                    }
                    this.readonlyflds.qaleadfld = false;
                } else if (this.finalvalidationArray['QA Lead'] == 'Read-Only') {
                    this.readonlyflds.qaleadfld = true;
                } 
            } else {
                if(this.tasktype.tstatus == "Tech and Business Review"){
                    if(!this.tasktype.qalead && this.tasktype.qalead==null){
                        this.errors.tasktype.qalead="QA Lead is required.";
                        isValid= false;
                    }
                }
                else if(!this.tasktype.qalead){
                    this.errors.tasktype.qalead="QA Lead is required";
                    isValid= false;
                } else {
                    this.errors.tasktype.qalead='';
                }
                this.readonlyflds.qaleadfld = false;
            }

            if(this.finalvalidationArray['Program Manager'])
            {
                this.errors.tasktype.progman='';
                if(this.finalvalidationArray['Program Manager'] == 'Required')
                {
                    if(!this.tasktype.progman){
                        this.errors.tasktype.progman="Program Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.progmanfld = false;
                } else if (this.finalvalidationArray['Program Manager'] == 'Read-Only') {
                    this.readonlyflds.progmanfld = true;
                } 
            } else {
                if(!this.tasktype.progman){
                    this.errors.tasktype.progman="Program Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.progman='';
                }
                this.readonlyflds.progmanfld = false;
            }
            return isValid;
        },
        validateForm(mdl,objThis){
            this.isAddState=false
            let isValid=true;
            this.nameBlured = true;
            this.descBlured = true;
            this.securitycommentBlured = true;
            this.project_success_criteriaBlured = true;
            this.project_fail_criteriaBlured = true;
            this.project_assumptionsBlured = true;
            this.project_roiBlured = true;
            this.business_impactBlured = true;
            this.impact_of_live_featureBlured = true;
            this.referencesBlured = true;
            isValid = this.readOnlyFieldsValidation(isValid);
            this.moduledocs.forEach(element => {
                if(element.modulefiles && element.modulefiles.length>0)
                {
                    element.modulefiles.forEach(modulefilesele => {
                        if((!modulefilesele.title && modulefilesele.title == "") ||
                        (!modulefilesele.desc && modulefilesele.desc == "") ||
                        (!modulefilesele.comment && modulefilesele.comment == "") || modulefilesele.attachment == "") {
                            isValid = false;
                        }
                    });
                }
            });

            if(mdl == 't9'){
                let flag=false
                if(flag){
                    if(!this.tasktype.project_success_criteria){
                        this.errors.tasktype.project_success_criteria="Business success criteria is required";
                        isValid= false;
                    }
                    if(!this.tasktype.business_impact){
                        this.errors.tasktype.business_impact="Business impact is required";
                        isValid= false;
                    }
                    if(!this.tasktype.impact_of_live_feature){
                        this.errors.tasktype.impact_of_live_feature="Impact on other live features HOD name isif any is required";
                        isValid= false;
                    }
                    if(!this.tasktype.project_success_criteria){
                        this.errors.tasktype.project_success_criteria="Business success criteria is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.project_success_criteria='';
                    }
                    if(!this.tasktype.project_fail_criteria){
                        this.errors.tasktype.projetc_fail_criteria="Business fail criteria is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.project_fail_criteria='';
                    }
                    if(!this.tasktype.project_assumptions){
                        this.errors.tasktype.project_assumptions="Business assumptions is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.project_assumptions='';
                    }
                    if(!this.tasktype.project_roi){
                        this.errors.tasktype.project_roi="Business ROI is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.project_roi='';
                    }
                    if(!this.tasktype.business_impact){
                        this.errors.tasktype.business_impact="Business impact is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.business_impact='';
                    }
                    if(!this.tasktype.impact_of_live_feature){
                        this.errors.tasktype.impact_of_live_feature="Impact Of Live Feature is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.impact_of_live_feature='';
                    }
                }
                if(this.tasktype.securitytag == null){
                        this.errors.tasktype.securitytag="";
                      //  isValid= false;
                    }else{
                        this.errors.tasktype.securitytag='';
                    }  
                if(!this.tasktype.businesscontext){
                        this.errors.tasktype.businesscontext="Business context is required";
                        isValid= false;
                }else{
                    this.errors.tasktype.businesscontext='';
                }
                if(this.finalvalidationArray['Redmine ID']) {
                    this.errors.tasktype.redmineid='';
                    if(this.finalvalidationArray['Redmine ID'] == 'Required')
                    {
                        if(!this.tasktype.redmineid){
                            this.errors.tasktype.redmineid="Redmine/Reference ID is required!";
                            isValid= false;
                        }else{
                            this.errors.tasktype.redmineid='';
                        }
                    }
                } else {
                    if(this.isRedirectedFromTR) {
                        if(!this.tasktype.redmineid){
                            this.errors.tasktype.redmineid="Redmine/Reference ID is required!";
                            isValid= false;
                        }else{
                            this.errors.tasktype.redmineid='';
                        }
                    }
                }
               
                if(this.finalvalidationArray['Business Project Name'])
                {
                    this.errors.tasktype.businessname='';
                    if(this.finalvalidationArray['Business Project Name'] == 'Required')
                    {
                        if(!this.tasktype.businessname){
                            this.errors.tasktype.businessname="Business Project Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.businessname){
                        this.errors.tasktype.businessname="Business project name is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.businessname='';
                    }
                }
                if(this.finalvalidationArray['Business Task Name']) {
                    this.errors.tasktype.name='';
                    if(this.finalvalidationArray['Business Task Name'] == 'Required')
                    {
                        if(!this.tasktype.name){
                            this.errors.tasktype.name="Business Task Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.name){
                        this.errors.tasktype.name="Task name is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.name='';
                    }
                }
                if(this.finalvalidationArray['Task Description']) {
                    this.errors.tasktype.desc='';
                    if(this.finalvalidationArray['Task Description'] == 'Required')
                    {
                        if(!this.tasktype.desc){
                            this.errors.tasktype.desc="Task Description is required!";
                            isValid= false;
                        }
                    }
                }else{
                    if(!this.tasktype.desc){
                        this.errors.tasktype.desc="Business task description is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.desc='';
                    }
                }
                console.log(this.tasktype.securitytag)
                if(this.tasktype.securitytag) {
                    if(this.finalvalidationArray['Security Justification']) {
                        this.errors.tasktype.securitycomment='';
                        if(this.finalvalidationArray['Security Justification'] == 'Required')
                        {
                            if(!this.tasktype.securitycomment){
                                this.errors.tasktype.securitycomment="security justification is required!";
                                isValid= false;
                            }
                        }
                    }else{
                        if(!this.tasktype.securitycomment){
                            this.errors.tasktype.securitycomment="Business security justification is required";
                            isValid= false;
                        }else{
                            this.errors.tasktype.securitycomment='';
                        }
                    }
                }
                if(this.finalvalidationArray['Project Name']) {
                    this.errors.tasktype.projectid='';
                    if(this.finalvalidationArray['Project Name'] == 'Required')
                    {
                        if(!this.tasktype.projectid){
                            this.errors.tasktype.projectid="Project Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.projectid){
                        this.errors.tasktype.projectid="Project name is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.projectid='';
                    }
                }
                
                if(this.finalvalidationArray['Task Level']) {
                    this.errors.tasktype.level='';
                    if(this.finalvalidationArray['Task Level'] == 'Required')
                    {
                        if(!this.tasktype.level){
                            this.errors.tasktype.level="Task Level is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.level){
                        this.errors.tasktype.level="Task level is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.level='';
                    }
                }
                if(this.finalvalidationArray['Task Type']) {
                    this.errors.tasktype.type='';
                    if(this.finalvalidationArray['Task Type'] == 'Required')
                    {
                        if(!this.tasktype.type){
                            this.errors.tasktype.type="Task Type is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.type){
                        this.errors.tasktype.type="Task type is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.type='';
                    }
                }
                if(this.finalvalidationArray['Task Severity']) {
                    this.errors.tasktype.key='';
                    if(this.finalvalidationArray['Task Severity'] == 'Required')
                    {
                        if(!this.tasktype.key){
                            this.errors.tasktype.key="Task Severity is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.key){
                        this.errors.tasktype.key="Task severity is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.key='';
                    }
                }

                if(this.finalvalidationArray['Reference']) {
                    this.errors.tasktype.references='';
                    if(this.finalvalidationArray['Reference'] == 'Required')
                    {
                        if(!this.tasktype.references){
                            this.errors.tasktype.references="Reference is required!";
                            isValid= false;
                        }
                    }
                }else{
                    if(!this.tasktype.references){
                        this.errors.tasktype.references="Task references is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.references='';
                    }
                }

                if(this.finalvalidationArray['Department'])
                {
                    this.errors.tasktype.dept='';
                    if(this.finalvalidationArray['Department'] == 'Required')
                    {
                        if(!this.tasktype.dept){
                        this.errors.tasktype.dept="Department is required!";
                        isValid= false;
                        }
                    }
                }
                else
                {
                    if(!this.tasktype.dept){
                        this.errors.tasktype.dept="Department is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.dept='';
                    }
                }
                
                if(this.finalvalidationArray['Task Status'])
                {
                    this.errors.tasktype.tstatus='';
                    if(this.finalvalidationArray['Task Status'] == 'Required')
                    {
                        if(!this.tasktype.tstatus){
                        this.errors.tasktype.tstatus="Task Status is required!";
                        isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.tstatus){
                        this.errors.tasktype.tstatus="Task status is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.tstatus='';
                    }         
                }
                if(this.finalvalidationArray['Project Module'])
                {
                    this.errors.tasktype.projectmoduleid='';
                    if(this.finalvalidationArray['Project Module'] == 'Required')
                    {
                        if(this.tasktype.projectmoduleid == null || this.tasktype.projectmoduleid == '' ||this.tasktype.projectmoduleid == []){
                            this.errors.tasktype.projectmoduleid="Project Module is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(this.tasktype.projectmoduleid == null || this.tasktype.projectmoduleid == '' ||this.tasktype.projectmoduleid == []){
                        this.errors.tasktype.projectmoduleid="Project Module is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.projectmoduleid='';
                    }
                }

                if(this.finalvalidationArray['Estimated End Date'])
                {
                    this.errors.tasktype.enddate="";
                    if(this.finalvalidationArray['Estimated End Date'] == 'Required')
                    {
                        if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'Product WIP 2'){
                            if(this.tasktype.startdate && !this.tasktype.enddate){
                                this.errors.tasktype.enddate="Estimated End Date is required!";
                                isValid= false;
                            }
                        }
                    }
                } else {
                    if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'Product WIP 2'){
                        if(this.tasktype.startdate && !this.tasktype.enddate){
                            this.errors.tasktype.enddate="Estimated date is required";
                            isValid= false;
                        }else{
                            this.errors.tasktype.enddate="";
                        }
                    }
                }
                
                if(this.finalvalidationArray['Subscribers'])
                {
                    this.errors.tasktype.subscribers='';
                    if(this.finalvalidationArray['Subscribers'] == 'Required')
                    {
                        if(!this.tasktype.subscribers){
                            this.errors.tasktype.subscribers="Subscribers is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if((!this.tasktype.subscribers) || (this.tasktype.subscribers && this.tasktype.subscribers.length < 1)) {
                        this.errors.tasktype.subscribers = 'Subscribers are required'
                        isValid = false
                    } else {
                        this.errors.tasktype.subscribers = ''
                    }
                }

                let allDataFilled =  this.tasktype.type
                && this.tasktype.key
                && this.tasktype.references && this.tasktype.bsh
                && this.tasktype.dept
                if(!allDataFilled) {
                    isValid = false
                }
            }else if(mdl=="doc"){
                for(const index in this.docs){
                    if(!this.validText(this.docs[index].title) || !this.validText(this.docs[index].comment) || !this.validText(this.docs[index].desc)){
                        isValid=false
                    }
                }
            }
            return isValid
        },
        validateHHHMM:function(str){
            if(!str) return false
            let z = /^([0-9][0-9]|[0-9][0-9][0-9]):[0-5][0-9]$/.test(str);
             if(!z){
                this.errors.validestimatehrs = "Please enter hours in HHH:MM format"

            }else {
                this.errors.validestimatehrs = ""
            }
            return z
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                tasktype:{
                    name:null,
                    projectid:null,
                    desc:null,
                    project_success_criteria:null,
                    securitytag:null,
                    project_fail_criteria:null,
                    project_assumptions:null,
                    project_roi:null,
                    business_impact:null,
                    impact_of_live_feature:null,
                    level:null,
                    startdate: null,
                    enddate: null,
                    projectmoduleid: null,
                    businesscontext:null
                }
            };
        },
        handleCancel() {
            this.$router.push({ name: 'Business Task List', params: { search: true }});
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText1 : function(inputval) {
            var re = /^[A-Za-z0-9_\-#@$%&\s]{4,}$/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validRedmineID : function(inputval) {
            var re = /[A-Za-z0-9].{2,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            if(tblNm=="t9"){
                if(this.tasktype.name){
                    this.inputval = this.tasktype.name
                    this.valid = true;
                }
                this.valid=false;
                if(this.tasktype.desc){
                    this.inputval = this.tasktype.desc
                    this.valid = true
                }else{
                    this.tasktype.descerror = true
                }
                this.valid=false;
                if(this.tasktype.level){
                    this.inputval = this.tasktype.level
                    this.valid = true
                }else{
                    this.tasktype.levelerror = true
                }
                return this.valid;
            }
            if( this.inputval && this.validText(this.inputval)){
                this.valid = true;
            }

        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format("YYYY-MM-DD");
        },
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'createpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.tasktype.projectlist = result.data.data;
                localStorage.setItem('projectlist', JSON.stringify(result.data.data))
            } else {
                this.tasktype.projectlist = [];
                localStorage.removeItem('projectlist')
            }
            }).catch(e => {
                this.displayError(e)
            });
        },
        projectChange: function (node, instanceId){
            this.tasktype.subscribers = null
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    empid: parseInt(this.undt.userid),
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.tasktype.projectlist = result.data.data;
                    } else {
                        this.tasktype.projectlist = [];
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        getApplicationdata(mdl,curracttab){
            this.isLoading=true
            this.pageoffset=1

            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                empcode: this.undt.userid,
                useremail: this.undt.username,
            }
            if(mdl=="t11"){
                this.inputappl.limit=500
            }
            let apiURL='api/master/commonmaster/list'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false
                if(result.data.errorCode == 0){
                    this.projectType=[];
                    this.projectType=result.data.data.rows
                    let tmpClassNm=''
                        if(mdl == 't25'){
                            if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasknamemaster=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't17'){
                            if(curracttab == 'taskname-tab'){
                                tmpClassNm='tasknametaskleveldd'
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't2'){
                            tmpClassNm='devBusinessVerticleData'
                        }else if(mdl == 't23'){
                            if(curracttab == 'tasktype-tab'){
                                this.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't24'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasklevel=result.data.data.rows
                                return true
                            }else if(curracttab == 'task'){
                                this.tasklevellist=result.data.data.rows
                                return true
                            }
                        }
                        else if(mdl == 't11'){
                            if(curracttab == 'task'){
                                this.tasktype.projectlist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.projectlist=result.data.data.rows
                                return true
                            }
                        }
                        else if(mdl == 't21'){
                            if(curracttab == 'taskassignment-tab'){
                                this.assignment.employeeoptions=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.employeeoptions=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't18'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskdefineList=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskassignment-tab'){
                                this.assignment.tasklist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't19'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskassignmentList=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't9'){
                            if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.businesstasklist=result.data.data.rows
                                return true
                            }
                        }
                        let payload={
                            'classnm':tmpClassNm
                        }
                        this.fillDropdown(payload);
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        fillDropdown(data){
            return ;
        },
        selectserPriority(state, value) {
            this.tasktype.key = null
            this.tasktype.key = state
            if(!state && this.isAddState == false){
                this.errors.tasktype.key='task key required'
            }else{
                this.errors.tasktype.key=''
            }
        },
        selecttypePriority(state, value) {
            this.tasktype.type = null
            this.tasktype.type = state
             if(!state){
               this.errors.tasktype.type='task type is required'
            }else{
               this.errors.tasktype.type=''
            }
        },
        selectrefPriority(state, value) {
            this.tasktype.references = null
            this.tasktype.references = state
        },
        selecttaskstatus(state, value) {
            this.errors.tasktype.tstatus = null
            this.tasktype.tstatus = null
            this.tasktype.tstatus = state
            if(!state){
               this.errors.tasktype.tstatus='task status is required'
            }else{
               this.errors.tasktype.tstatus=''
            }
            if(this.tasktype.tstatus=='Product WIP'){
                this.tasktype.startdate = this.dateFormat(new Date())
                this.isdisableds= true
                this.tasktype.enddate = ''
            }else{
                this.isdisableds= false
                this.tasktype.startdate = null
                this.tasktype.enddate = null
            }
            if(this.tasktype.projectid && this.tasktype.projectid.length>0) {
                this.getprojectmaprolide(this.loginuscondition,'1',this.tasktype.projectid);
            }
        },
        selectBSH(state) {
            if(!state){
                this.errors.tasktype.bsh = 'HOD name is required'
            } else {
                this.errors.tasktype.bsh = ''
            }
        },
        selectProMan(state){
            if(!state){
                this.errors.tasktype.proman = 'Project Manager is required'
            } else {
                this.errors.tasktype.proman = ''
            }
        },
        selectProgMan(state){
            if(!state){
                this.errors.tasktype.progman = 'Program Manager is required'
            } else {
                this.errors.tasktype.progman = ''
            }
        },
        selectEngMan(state){
            if(!state){
                this.errors.tasktype.engman = 'Engineering Manager is required'
            } else {
                this.errors.tasktype.engman = ''
            }
        },
        selectQAMan(state){
            if(!state){
                this.errors.tasktype.qamanager = 'QA Manager is required'
            } else {
                this.errors.tasktype.qamanager = ''
            }
        },
        selectQALead(state){
            if(!state){
                this.errors.tasktype.qalead = 'QA Lead is required'
            } else {
                this.errors.tasktype.qalead = ''
            }
        },
        selectBusinessname(state){
            if(!state){
                this.errors.tasktype.businessname = 'Business project name is required'
            } else {
                this.errors.tasktype.businessname = ''
            }
        },
        selectbtmDept(state, value) {
            this.tasktype.bsh = null
            if(!state){
                this.tasktype.depterr = true
                this.tasktype.bsh = null
                this.tasktype.bshlist = []
            } else {
                this.errors.tasktype.dept = null
                this.tasktype.depterr = false
                if(this.tasktype.projectid && this.tasktype.projectid.length>0){
                    this.getBSHlist(state, this.tasktype.projectid)
                }
            }
        },
        selectBusinessContext(state){
            if(!state){
                this.errors.tasktype.businesscontext = 'business context is required'
            } else {
                this.errors.tasktype.businesscontext = ''
            }
        },
        selectsecurity(state){
            if(state==null){
                this.errors.tasktype.securitytag = '';
            } else {
                this.errors.tasktype.securitytag = '';
            }
        },
        deSelectbtmDept() {
            this.tasktype.bsh = null
            this.tasktype.bshlist = []
        },
        isKeyPresent(dataslabel, datassid) {
            if (this.chkselectfieldlist.hasOwnProperty(dataslabel) && this.chkselectfieldlist[dataslabel].hasOwnProperty(datassid)) {
            return true; // Return boolean true if condition is met
            } else {
            return false; // Return boolean false if condition is not met
            }
        },
        getVariable(dataslabel, datassid) {
            if (this.isKeyPresent(dataslabel, datassid)) {
            var parameter = Object.values(this.chkselectfieldlist[dataslabel][datassid]);
            const combinedString = parameter.join("");
            const combinedString1 = combinedString.replaceAll(',', '');
            return combinedString1;
            } else {
            return ''; // Return an empty string if condition is not met
            }
        },
        getProjectManagerDetails(roleid,projectid) {
            axios({
                method: "POST",
                url: "api/listing/getProjectManagerDetails",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,projectidarr:projectid,empcode: this.undt.userid,useremail: this.undt.username}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==477){
                        this.tasktype.pmlist = result.data.data.rows
                        if(this.tasktype.pmlist.length == 1){
                            this.tasktype.proman = this.tasktype.pmlist[0].id
                        }
                    }else if(roleid==492){
                        this.tasktype.emlist = result.data.data.rows
                        if(this.tasktype.emlist.length == 1){
                            this.tasktype.engman = this.tasktype.emlist[0].id
                        }
                    }else if(roleid==503){
                        this.tasktype.proglist = result.data.data.rows
                        if(this.tasktype.proglist.length == 1){
                            this.tasktype.progman = this.tasktype.proglist[0].id
                        }
                    }
                }else{
                    if(roleid==477){
                        this.tasktype.pmlist = [];
                    }else if(roleid==492){
                        this.tasktype.emlist = [];
                    }else if(roleid==503){
                        this.tasktype.proglist = [];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBSHlist(dept, projectid) {
            axios({
                method: "POST",
                url: "api/listing/getBTCHODlist",
                data: { dept:[dept],empcode: this.undt.userid,projectid:projectid,
                useremail: this.undt.username },
                headers: { authorization : this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.bshlist = result.data.data.rows
                    if(this.tasktype.bshlist.length == 1){
                        this.tasktype.bsh = this.tasktype.bshlist[0].id
                    }
                } else {
                    this.tasktype.bshlist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBusinessname() {
            this.input = {
                empcode: this.undt.userid,
                useremail:this.undt.username
            }
            axios({
                method: "POST",
                url: "api/listing/getBusinessname",
                data: this.input,
                headers: { authorization : this.tokendata },
            })
            .then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.businessnamelist = result.data.data.rows
                } else {
                    this.tasktype.businessnamelist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        seltaskleveldata(state,value){
            this.tasktype.level=null
            this.tasktype.level=state
            if(!state && this.isAddState == false){
                this.tasktype.levelerror=true
            }else{
                this.errors.tasktype.level=''
                this.tasktype.levelerror=false
            }
        },
        seltpprojectdata(state,value){
            this.tasktype.projectid=null
            this.tasktype.engman=null
            this.tasktype.proman=null
            this.tasktype.pmlist = []
            this.tasktype.emlist = []
            this.tasktype.proglist = []
            this.tasktype.qaleadlist = []
            this.tasktype.qamnglist = []
            if(this.userrole == 'product') {
                if(state.length>0){
                    this.getBSHlist('Product', state)
                }
            }else{
                this.tasktype.dept = null
                this.tasktype.bsh = null
            }
            this.tasktype.bshlist = []
            this.tasktype.projectmoduleid = null
            this.tasktype.projectmodulelist = []
            this.tasktype.projectid=state
            if(!state || (state && state.length < 1) && this.isAddState == false){
                //this.tasktype.projectlist = null
                this.tasktype.subscribers = null
                this.tasktype.qalead = null
                this.tasktype.qamanager = null
                this.tasktype.projectmodulelist = []
                this.errors.tasktype.projectid="Project name is required"
            }else{
                this.errors.tasktype.projectid=null
                if(state && state.length) this.getProjectLevel(state)
                this.getEmpallocatedtoProject(state)
                this.getProjecttoProjectmodule(state)
                this.getProjectManagerDetails(477,state)
                this.getProjectManagerDetails(492,state)
                this.getProjectManagerDetails(503,state)
                this.getTaskmanager(131,state)
                this.getTaskmanager(513,state)
                this.getProjectQAdata(509,state);
                this.getProjectQAdata(508,state);
                this.getTasktypeConfigLists(state);
                this.getBStatusConfigList(state, 'Product Pipeline');
                this.projectmultiplename = state;
                this.getprojectmaprolide(this.loginuscondition,'1',this.tasktype.projectid);
            }
        },
        getBStatusConfigList(pid, status) {
            this.taskstatus = [];
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: pid,
                trackername: 'Businesstask',
                currStatus: status,
            };
            axios({
                method: "POST",
                url: '/api/testcase/getBStatusConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    this.taskstatus = result.data.data.rows;
                    this.taskstatus.sort((a, b) => a.label.localeCompare(b.label));
                } else {
                    this.taskstatus = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        selprojmodid(state) {
            if(!state) {
                this.errors.tasktype.projectmoduleid = 'Project Module is required'
            } else {
                this.getProjectByBusinessTask(state)
                this.errors.tasktype.projectmoduleid = ''
            }
        },
        getEmpallocatedtoProject(projectid) {
            axios({
                method: "POST",
                url: "api/listing/getEmpallocatedtoProject",
                data: { projectidarr: projectid, moduletype: 'businesstask',empcode: this.undt.userid,
                useremail: this.undt.username },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.subscriberslist = result.data.data;
                    let decryptdepartmenttext = this.tasktype.subscriberslist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.tasktype.subscriberslist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjecttoProjectmodule(projectid) {
            axios({
                method: "POST",
                url: "api/master/businesstask/getprojectmodulebyprojectid",
                data: { projectidarr:projectid, moduletype: 'businesstask',empcode: this.undt.userid,
                useremail: this.undt.username, isfrompage: 'createpage' },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                this.tasktype.projectmodulelist = result.data.data;
                } else {
                this.tasktype.projectmodulelist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getTaskmanager(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading= false;
                if(result.data.errorCode == 0){
                    if(roleid==131){
                        this.tasktype.taskmanagerlist=result.data.data.rows;
                        if(this.tasktype.taskmanagerlist.length ==1){
                            this.tasktype.taskmanager = this.tasktype.taskmanagerlist[0].id  
                        }
                        this.tasktype.subscribers = this.tasktype.taskmanagerlist.map(i => (i.employeeid));
                        let f = this.tasktype.subscribers.find(obj => obj == parseInt(this.undt.userid));
                        if(f == undefined) {
                            this.tasktype.subscribers.push(parseInt(this.undt.userid))
                        }
                    }
                    else if (roleid === 513) {
                        this.tasktype.taskownerlist = result.data.data.rows;
                        if(this.tasktype.taskownerlist.length ==1){
                            this.tasktype.taskowner = this.tasktype.taskownerlist[0].id  
                        }
                        let userId = this.undt.userid; 
                        let isUserIdPresent = this.tasktype.taskownerlist.filter(owner => owner.employeeid == userId);
                        if (this.userrole=='product' && isUserIdPresent.length>0) {
                            this.tasktype.taskowner = isUserIdPresent[0].id;
                        }
                    }
                }else{
                    if(roleid==131){
                        this.tasktype.taskmanagerlist=[]
                    }else if (roleid==513){
                        this.tasktype.taskownerlist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getProjectQAdata(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading= false;
                if(result.data.errorCode == 0){
                    if(roleid==509 && state.length >0){
                        this.tasktype.qaleadlist=result.data.data.rows;
                        if(this.tasktype.qaleadlist.length ==1){
                            this.tasktype.qalead = this.tasktype.qaleadlist[0].id  
                        }
                    }else if (roleid==508 && state.length >0){
                        this.tasktype.qamnglist=result.data.data.rows;
                        if(this.tasktype.qamnglist.length ==1){
                            this.tasktype.qamanager = this.tasktype.qamnglist[0].id  
                        }
                    }
                }else{
                    if(roleid==509){
                        this.tasktype.qaleadlist=[]
                    }else if (roleid==508){
                        this.tasktype.qamnglist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        selSubscribers(state) {
            this.tasktype.subscribers = null
            this.tasktype.subscribers = state
            if(!state || (state && state.length < 1)) {
                this.tasktype.projectid = null
                this.errors.tasktype.subscribers = 'Subscribers are required'
            } else {
                this.errors.tasktype.subscribers = ''
            }
        },
        selTaskmanager(state){
            if(!state){
                this.errors.tasktype.taskmanager = 'Task Manager is required'
            } else {
                this.errors.tasktype.taskmanager = ''
            }
        },
        selTaskOwner(state){
            if(!state){
                this.errors.tasktype.taskowner = 'Task Owner is required'
            } else {
                this.errors.tasktype.taskowner = ''
            }
        },
        getProjectLevel(projectidarr) {
            let url = "api/projectapplication/getprojectnamelist";
            this.input = {
            projectidarr,
            empcode: this.undt.userid,
            useremail: this.undt.username
            }
            axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.projectLevels = result.data.data
                        let levels = this.projectLevels.map(proj => {
                            if(proj.projectlevel) {
                                return proj.projectlevel
                            }
                            return 0
                        })
                        let levels2 = levels.filter(lev => lev != 0)
                        if(levels2.length) this.tasktype.level = Math.max(...levels2)
                    } else {
                        this.projectLevels = result.data.data
                        this.tasktype.level = null
                    }
                }).catch(e => {
                this.displayError(e)
            })
        },
        onSelect(event){
            this.files= event.target.files;
            this.isdisabled=false
        },
        onSelectDoc(event){
            this.myfiles= event.target.files;
            if(this.myfiles.length>0)
            this.isdisabled=true
        },
        saveDocument(){
            this.input=[]
            let isValid = this.validateForm('doc',this.docs)
            if(isValid){
                this.isLoading = true;
                if(this.attachment && this.attachment.length == this.docs.length){
                    for(const index in this.attachment){
                        this.input.push({
                            documentid:this.attachment[index].batchid,
                            documentname:this.attachment[index].filename,
                            title:this.docs[index].title,
                            desc:this.docs[index].desc,
                            comment:this.docs[index].comment,
                            version:'v1',
                            createdby:this.undt.username,
                            taskid:this.tasktype.taskid,
                            empcode: this.undt.userid,
                            useremail: this.undt.username
                        })
                    }
                    if(this.input.length>0){
                        let url = "api/workstep/bulkinsert";
                        axios({
                            method: "POST",
                            url: url,
                            data: {document:this.input},
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading = false;
                            this.attachment=[]
                            if (result.data.errorCode == 0) {
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                                this.docsuploadcollapse()
                                this.mydetailsollapse()
                            } else if(result.data.errorCode == 3){
                                Swal.fire({
                                    title: "Session Expired...!!",
                                    text: result.data.msg,
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                    }).then(function() {
                                        window.location.href = "/#/login";
                                    })
                                } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Failed to upload document",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        }).catch(e => {
                            this.displayError(e)
                        });
                    }else{
                        this.isLoading = false;
                    }
                }else {
                    Swal.fire({
                        title: "Failed",
                        text: "All Fields are compulsory",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading = false;
                }
            }
        },
        async onUploadDoc(event, index, doclist, moduletype=null, rowindex) {
            this.apiURL = "api/s3upload/uploadDocumentToS3";
            if(this.myfiles.length) {
                this.isLoading=true
                this.myfiles.forEach(file => {
                    if (file.size > 1024 * 1024 * 21) {
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 21mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false;
                    }
                    if(file.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file);
                        formData.append("empcode", this.undt.userid);
                        formData.append("useremail", this.undt.username);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            this.myfiles=[];
                            if (result.data.status) {
                                this.isdisabled=false
                                doclist.attachment= result.data.data
                                doclist.isUploaded = true
                                this.moduledocs[index]['modulefiles'][rowindex]['tempfilename'] = result.data.data;
                                this.moduledocs[index]['modulefiles'][rowindex]['tempdoctitile'] = doclist.title;
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        })
                    }
                })
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "Select document",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }
        },
        getTasktypeConfigLists(projectid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: parseInt(projectid),
                trackername: 'BTM Task Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    slist.sort((a, b) => a.label.localeCompare(b.label));
                    this.tasktype.btmtasktypelist = slist;
                } else {
                    this.tasktype.btmtasktypelist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        async onUpload(event,index) {
            this.apiURL = "api/s3upload/uploadDocumentToS3";
            if(this.files.length) {
                this.isLoading=true
                this.files.forEach(file => {
                    if (file.size > 1024 * 1024 * 21) {
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 21mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false;
                    }
                    if(file.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file);
                        formData.append("empcode", this.undt.userid);
                        formData.append("useremail", this.undt.username);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            this.files=[];
                            if (result.data.status) {
                                if(this.attachment){
                                    this.attachment[index] = result.data.data;
                                }else{
                                    this.attachment = []
                                    this.attachment[index]= result.data.data;
                                }
                                this.attachmentview = result.data.data;
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        })
                    }
                })
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "Select document",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }
        },
        getprojectmaprolide(empcode, trackername, valchk1)
        { 
            if(valchk1.length>0)
            {
                let url = "api/fieldtracker/getfieldconfigdataroleid";
                this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                projectid: valchk1
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers': { 'authorization': this.tokendata }
                }).then((result) => {    
                    if (result.data.errorCode == 0) {
                        this.newroleid = result.data.data[0]['roleid'];
                        this.getfieldconfigdatabyid(empcode, trackername, valchk1);
                    } else {

                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        async getfieldconfigdatabyid(empcode, trackername, valchk1) {
            try {
            let projectidarray = []
            if(valchk1)
            {
                for (var j = 0; j < valchk1.length; j++) {
                    projectidarray[j]= valchk1[j];
                }    
                if(this.newroleid)
                {
                    this.isLoading = true
                    let url = "api/fieldtracker/getmultiplefieldconfigdatabyid";
                    this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    roleid: this.newroleid,
                    projectidarray: projectidarray,
                    trackername: trackername
                    };
                    axios({
                        method: "POST",
                        url: url,
                        data: this.input,
                        'headers': { 'authorization': this.tokendata }
                    }).then((result) => {
                        this.isLoading = false;
                        if (result.data.errorCode == 0) {
                            this.setSelectedParameter(result.data.data)
                            this.readOnlyFieldsValidation();
                        } else {
                            this.finalvalidationArray = [];
                            this.multidimensionalArray = [];
                        }
                    }).catch(e => {
                        this.displayError(e)
                    });
                }
            }
        } catch (error) { 
            console.log('err');
        }

        },
        setSelectedParameter(variable) {
        const masterkey=[];
        this.multidimensionalArray = [];
        for (var k = 0; k < variable.length; k++) {
            var fieldconfigstring = JSON.stringify(variable[k].fieldconfig);
            var fieldconfigstringarray = JSON.parse(fieldconfigstring);
            var firstindex = "";
            var secondindex = "";
            for (var i = 0; i < fieldconfigstringarray.length; i++) {
            var data = "";
            firstindex = Object.keys(fieldconfigstringarray[i][0])[0];
            const textarray = [];
            masterkey.push(firstindex);
                for (var j = 0; j < fieldconfigstringarray[i][0][firstindex].length; j++) {
                    secondindex = Object.keys(fieldconfigstringarray[i][0][firstindex][j])[0];
                    var validationText='';
                    validationText = fieldconfigstringarray[i][0][firstindex][j][secondindex][0]['validation'][0];
                    textarray[secondindex]=validationText;
                    data = textarray;
                    if(this.tasktype.tstatus == secondindex )
                    {
                        const fixedKey = firstindex;
                        const newData = validationText;
                        if (!this.multidimensionalArray[fixedKey]) {
                            if(this.tasktype.tstatus == secondindex )
                            this.$set(this.multidimensionalArray, fixedKey, []);
                        }
                        else
                        {
                            if(this.tasktype.tstatus == secondindex )
                            this.$set(this.multidimensionalArray, fixedKey, []);
                        }    
                        this.multidimensionalArray[fixedKey].push(newData);
                    }
                }
            }
        }
        const uniqueArray = [...new Set(masterkey)];
        this.finalvalidationArray = [];
        for (var i = 0 ; i < uniqueArray.length; i++)
        {
            if(this.multidimensionalArray.hasOwnProperty(uniqueArray[i]))
            this.finalvalidationArray[uniqueArray[i]]=this.multidimensionalArray[uniqueArray[i]][0];
        }        
        },
    }
}
</script>


<style>
.ck-content{
    min-height: 150px !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.display{
    display: none !important;
}

.pop-up {
    width: 70% !important;
}
.swal-wide{
    width:850px !important;
}
.bt-cre_createpopoverbg .content02 {
  
}



</style>
