<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-sm-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Userwise Performance List II</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Employee Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4Chars To Search Employee Name" v-model="search.empname" :options="emplist" v-on:search-change="getEmpListByProjectAssigned"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getprojectlist(search)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                      <button type="move" class="btn btn-relief-primary mt-2 mr-1" @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Userwise Performance List 2
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div
                                class="table-responsive_sitcky"
                                v-if="showtabledata"
                              >
                              <table class="table table-hover table-striped table-bordered table-sm all_table_stickys" aria-describedby="Userwise Performance List 2">
                                  <thead>
                                    <tr style="color:blue;">
                                      <th scope="col" colspan="6" class="title align-middle dubble_table_padding_y5 first-col-sticky">Epm Details</th>
                                      <th scope="col" colspan="6" class="title align-middle second-col-sticky">Task allocated count</th>
                                      <th scope="col" colspan="6" class="title align-middle">Issues allocated count</th>
                                      <th scope="col" colspan="6" class="title align-middle">Incident allocated count</th>
                                    </tr>
                                    <tr>
                                      <th scope="col" class="align-middle first-col-sticky" style="min-width:45px">Empid</th>
                                      <th scope="col" class="align-middle text-left second-col-sticky-emp" style="min-width:200px">Emp Name</th>
                                      <th scope="col" class="align-middle text-left" style="min-width:200px">Designation</th>
                                      <th scope="col" class="align-middle text-left">Reporting manager</th>
                                      <th scope="col" class="align-middle">Total exp</th>
                                      <th scope="col" class="align-middle">fc exp</th>

                                      <th scope="col" class="text-nowrap align-middle">1 Year</th>
                                      <th scope="col" class="text-nowrap align-middle">6 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">3 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">1 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Week</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Day</th>

                                      <th scope="col" class="text-nowrap align-middle">1 Year</th>
                                      <th scope="col" class="text-nowrap align-middle">6 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">3 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">1 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Week</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Day</th>

                                      <th scope="col" class="text-nowrap align-middle">1 Year</th>
                                      <th scope="col" class="text-nowrap align-middle">6 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">3 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">1 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Week</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Day</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)">                                        
                                        <td :class="'uppercasetext font-weight-normal first-col-sticky unique'+index+1+currPageNum*pagelimit" style="min-width:100px;">
                                          {{data.empid}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal second-col-sticky-emp">{{data.empname}}</td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{decryptText(data.designation)}}
                                        </td>
                                        <td class="font-weight-normal text-left">
                                          {{data.reportingmanager.trim() !=''? data.reportingmanager:'NA' }}
                                        </td>
                                        <td class="text-nowrap font-weight-normal" v-if="data.totalexp">
                                          {{data.totalexp}} yrs
                                        </td>
                                        <td class="font-weight-normal" v-if="!data.totalexp">
                                          -
                                        </td>
                                        <td class="text-nowrap font-weight-normal" v-if="data.totalfcexp">
                                            {{data.totalfcexp}} yrs
                                        </td>
                                        <td class="font-weight-normal" v-if="!data.totalfcexp">
                                            -
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.oneyrtask}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.sixmnthtask}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.threemnthtask}}
                                        </td>  
                                         <td class="title font-weight-normal">
                                          {{data.onemnthtask}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.lastweektask}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.lastdaytask}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.oneyrissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.sixmnthissue}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.threemnthissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.onemnthissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.lastweekissue}}
                                        </td>
                                        <td class="title">
                                          {{data.lastdayissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.oneyrincident}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.sixmnthincident}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.threemnthincident}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.onemnthincident}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.lastweekincident}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.lastdayincident}}
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0 && isresponce" >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Userperformance2report",
  mixins: [ commonMethods ],
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
  },
  data() {
    return {
      search: {
        empname:null,
      },
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      stylevariation: apiUrl.stylevariation,
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      emplist:[],
      tokendata:null,
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.getprojectlist(rowdata);
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    getEmpListByProjectAssigned(node) {
      if(node && node.length >=3){
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.emplist = result.data.data;
            let decryptdepartmenttext = this.emplist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.emplist = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.pageoffset=1
      this.search.searchProjectname = state;
    }, 
    resetRecords: function () {
      let rowdata={}
      this.getprojectlist(rowdata);
      this.search.empname = null;
      this.emplist = [];
    },
    getprojectlist(rowdata) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empid: rowdata.empname,
        empcode: this.undt.userid,
      };
      let url = "api/reports/userperformancereportII";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Userwise performance report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);

    },
    setSelected(value) {
      this.selected = value;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
  ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
     this.input = {
        limit:null,
        offset:null,
        empid:this.search.empname,        
        useremail: this.undt.username,
        empcode: this.undt.userid      
      };
      axios({
        method: "POST",
        url: `api/reports/userperformancereportII`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = ""
          this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
      download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'exportreport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Empid,Emp Name,Designation,Reporting Manager,Total Exp,FC Exp,Task allocated 1 Year  ,Task allocated 6 Month ,Task allocated 3 Month  ,Task allocated 1 Month  , Task allocated Last Week, Task allocated Last Day , Issues allocated 1 Year  ,Issues allocated 6 Month ,Issues allocated 3 Month ,Issues allocated 1 Month ,Issues allocated Last Week ,Issues allocated Last Day,Incident allocated 1 Year ,Incident allocated 6 Month ,Incident allocated 3 Month ,Incident allocated 1 Month ,Incident allocated Last Week,Incident allocated Last Day ' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let empid = index.hasOwnProperty('empid') ? index.empid : '';
          let empname = index.hasOwnProperty('empname') ? index.empname : '';
          let designation = index.hasOwnProperty('designation') ? index.designation == null?'':index.designation : '';
          let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager == null?'' :index.reportingmanager : '';
          let totalexp = index.hasOwnProperty('totalexp') ? index.totalexp == null?'':index.totalexp : '';
          let totalfcexp = index.hasOwnProperty('totalfcexp') ? index.totalfcexp == null?'':index.totalfcexp : '';
          let oneyrtask = index.hasOwnProperty('oneyrtask') ? index.oneyrtask : '';
          let sixmnthtask = index.hasOwnProperty('sixmnthtask') ? index.sixmnthtask : '';
          let threemnthtask = index.hasOwnProperty('threemnthtask') ? index.threemnthtask : '';
          let onemnthtask = index.hasOwnProperty('onemnthtask') ? index.onemnthtask : '';              
          let lastweektask = index.hasOwnProperty('lastweektask') ? index.lastweektask : '';
          let lastdaytask = index.hasOwnProperty('lastdaytask') ? index.lastdaytask : '';
          let oneyrissue = index.hasOwnProperty('oneyrissue') ? index.oneyrissue : '';
          let sixmnthissue = index.hasOwnProperty('sixmnthissue') ? index.sixmnthissue : '';
          let threemnthissue = index.hasOwnProperty('threemnthissue') ? index.threemnthissue : '';
          let onemnthissue = index.hasOwnProperty('onemnthissue') ? index.onemnthissue : '';              
          let lastweekissue = index.hasOwnProperty('lastweekissue') ? index.lastweekissue : '';
          let lastdayissue = index.hasOwnProperty('lastdayissue') ? index.lastdayissue : '';
          let oneyrincident = index.hasOwnProperty('oneyrincident') ? index.oneyrincident : '';
          let sixmnthincident = index.hasOwnProperty('sixmnthincident') ? index.sixmnthincident : '';
          let threemnthincident = index.hasOwnProperty('threemnthincident') ? index.threemnthincident : '';
          let onemnthincident = index.hasOwnProperty('onemnthincident') ? index.onemnthincident : '';              
          let lastweekincident = index.hasOwnProperty('lastweekincident') ? index.lastweekincident : '';
          let lastdayincident = index.hasOwnProperty('lastdayincident') ? index.lastdayincident : '';
            row += '"' + srno + '",',
            row += '"' + empid + '",',
            row += '"' + empname + '",',
            row += '"' + this.decryptText(designation) + '",',
            row += '"' + reportingmanager + '",',
            row += '"' + totalexp + '",',
            row += '"' + totalfcexp + '",',
            row += '"' + oneyrtask + '",',
            row += '"' + sixmnthtask + '",',
            row += '"' + threemnthtask + '",',
            row += '"' + onemnthtask + '",',
            row += '"' + lastweektask + '",',
            row += '"' + lastdaytask + '",',
            row += '"' + oneyrissue + '",',
            row += '"' + sixmnthissue + '",',
            row += '"' + threemnthissue + '",',
            row += '"' + onemnthissue + '",',
            row += '"' + lastweekissue + '",',
            row += '"' + lastdayissue + '",',
            row += '"' + oneyrincident + '",',
            row += '"' + sixmnthincident + '",',
            row += '"' + threemnthincident + '",',
            row += '"' + onemnthincident + '",',
            row += '"' + lastweekincident + '",',
            row += '"' + lastdayincident + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'userwiseperformance2_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });  
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },

  },
};
</script>
