<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-2">MOM planner Details</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">MOM Planner Details</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card mb-0">
                        <div class="card-header py-1" style="cursor:pointer">
                            <div class="container">
                        <fieldset class="fieldset_border_vi mb-1 mx-0 business_fieldset_bg">
                                <legend>
                                    <span class="leg_mobile">MOM Details</span>
                                </legend>

                        <div class="row match-height card_transction_veiws">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="card card-transaction">
                            <div class="card-body mobile-padding-15-10 pt-md-1">
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-warning rounded">
                                    <div class="avatar-content">
                                        <em class="lab la-canadian-maple-leaf font-medium-5"></em>
                                    </div>
                                    </div>
                                    <div class="media-body">
                                    <h6 class="transaction-title">Project Name</h6>
                                    <small class="capitalisetext">{{ momDetailsbyPlanner[0].projectname }}</small>
                                    </div>
                                </div>
                                </div>
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-primary rounded">
                                    <div class="avatar-content">
                                        <em class="las la-server font-medium-5"></em>
                                    </div>
                                    </div>
                                    <div class="media-body">
                                    <h6 class="transaction-title">Project Module</h6>
                                    <small>{{ momDetailsbyPlanner[0].projectmodulename }}</small>
                                    </div>
                                </div>
                                </div>
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-success rounded">
                                    <div class="avatar-content">
                                    
                                        <em class="las la-cloud-moon font-medium-3"></em>
                                    </div>
                                    </div>
                                    <div class="media-body">
                                    <h6 class="transaction-title">Location</h6>
                                    <small class="capitalisetext">{{ momDetailsbyPlanner[0].location }}</small>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="card card-transaction ">
                            <div class="card-body mobile-padding-15-10 pt-md-1">
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-primary rounded">
                                    <div class="avatar-content">
                                        <user-icon class="i_icon_st la-codepen lab font-medium-5" style="font-weight: 500;"></user-icon>
                                    </div>
                                    </div>
                                    <div class="media-body">
                                    <h6 class="transaction-title">Meeting Facilitator</h6>
                                    <small class="capitalisetext">{{ momDetailsbyPlanner[0].facilitatornm }}</small>
                                    </div>
                                </div>
                                </div>
                                <div class="transaction-item">
                                    <div class="media">
                                    <div class="avatar bg-light-success rounded">
                                        <div class="avatar-content">
                                        <user-icon class="i_icon_st la-hdd  las font-medium-5" ></user-icon>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">Business Analyst</h6>
                                        <small class="capitalisetext">{{ momDetailsbyPlanner[0].businessanalyst }}</small>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>                                                                
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="card card-transaction">
                            <div class="card-body mobile-padding-15-10 pt-md-1">
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-warning rounded">
                                    <div class="avatar-content">
                                        <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                    </div>
                                    </div>
                                    <div class="media-body">
                                    <h6 class="transaction-title">Date of Meeting</h6>
                                    <small>{{ dateMonthFormat(momDetailsbyPlanner[0].dateofmeeting) }}</small>
                                    </div>
                                </div>
                                </div>
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-primary rounded">
                                    <div class="avatar-content">
                                        <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                                    </div>
                                    </div>
                                    <div class="media-body">
                                    <h6 class="transaction-title">Start Time</h6>
                                    <small>{{ momDetailsbyPlanner[0].starttime }}</small>
                                    </div>
                                </div>
                                </div>
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-warning rounded">
                                    <div class="avatar-content">
                                        <watch-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></watch-icon>
                                    </div>
                                    </div>
                                    <div class="media-body">
                                    <h6 class="transaction-title">End Time</h6>
                                    <small>{{ momDetailsbyPlanner[0].endtime }}</small>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-12" style="margin-top:-45px;">
                            <div class=" card-transaction">
                            <div class="card-body mobile-padding-15-10">
                                <div class="transaction-item">
                                <div class="media">
                                    <div class="avatar bg-light-secondary rounded">
                                    <div class="avatar-content">
                                        <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                    </div>
                                    </div>
                                    <div class="media-body meeting_dic_font_size">
                                    <h6 class="transaction-title">Meeting Objective</h6>
                                    <span class="d-inline-flex" v-html="momDetailsbyPlanner[0].description"></span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        


                        </fieldset>
                        </div>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="tab-content mb-0 d-inline-block w-100" >
                            <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                                <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                                    <div class="card card resolved rounded">
                                        <div class="card-header rounded-0 border-bottom" id="paymentOne" data-toggle="collapse" role="button" data-target="#faq-payment-one" aria-expanded="false" aria-controls="faq-payment-one">
                                            <span class="lead collapse-title">Agenda</span>
                                        </div>

                                        <div id="faq-payment-one" class="collapse " aria-labelledby="paymentOne" data-parent="#faq-payment-qna">
                                            <div class="card-body pt-1">
                                                <div class="mb-1 px-0" >
                                                    <div class="table-responsive">
                                                        <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered" >
                                                            <thead>
                                                                <tr>
                                                                <th scope="col" class="text-center">Owner</th>
                                                                <th scope="col" class="text-center" style="min-width:180px">Topic</th>
                                                                <th scope="col" class="text-center">Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(m1, index) in agenda" v-bind:key="index">
                                                                <td class="text-center capitalisetext ">
                                                                    {{agenda[index].owner}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{agenda[index].topic}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{agenda[index].time}}
                                                                </td>
                                                                </tr>
                                                            </tbody>                          
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content mb-0 d-inline-block w-100" >
                            <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                                <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                                    <div class="card card open_primary">
                                        <div class="card-header rounded-0 border-bottom" id="paymentTwo" data-toggle="collapse" role="button" data-target="#faq-payment-two" aria-expanded="false" aria-controls="faq-payment-two">
                                            <span class="lead collapse-title">Attendees</span>
                                        </div>

                                        <div id="faq-payment-two" class="collapse" aria-labelledby="paymentTwo" data-parent="#faq-payment-qna">
                                            <div class="card-body pt-1">
                                                <div class="mb-1 px-0" >
                                                    <div class="table-responsive">
                                                        <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                            <thead>
                                                                <tr>
                                                                <th scope="col" class="text-center">User</th>
                                                                <th scope="col" class="text-center" style="min-width:180px">User Email</th>
                                                                <th scope="col" class="text-center">User Department</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(m1, index) in attendees" v-bind:key="index">
                                                                <td class="text-center capitalisetext ">
                                                                    {{attendees[index].user}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{attendees[index].email}}
                                                                </td>
                                                                <td class="text-center">
                                                                    {{decryptText(attendees[index].dept)}}
                                                                </td>
                                                                </tr>
                                                            </tbody>                          
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="tab-content mb-0 d-inline-block w-100" >
                        <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                            <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                                <div class="card card open_oranges">
                                    <div class="card-header" id="paymentThree" data-toggle="collapse" role="button" data-target="#faq-payment-three" aria-expanded="false" aria-controls="faq-payment-three">
                                        <span class="lead collapse-title">Absentees</span>
                                    </div>

                                    <div id="faq-payment-three" class="collapse" aria-labelledby="paymentThree" data-parent="#faq-payment-qna">
                                        <div class="card-body">
                                            <div class="mb-1 px-0" >
                                                <div class="table-responsive">
                                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col" class="text-center">User</th>
                                                            <th scope="col" class="text-center" style="min-width:180px">User Email</th>
                                                            <th scope="col" class="text-center">User Department</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(m1, index) in absentees" v-bind:key="index">
                                                            <td class="text-center capitalisetext ">
                                                                {{absentees[index].user}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{absentees[index].email}}
                                                            </td>
                                                            <td class="text-center">
                                                                {{decryptText(absentees[index].dept)}}
                                                            </td>
                                                            </tr>
                                                        </tbody>                          
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    <div class="tab-content mb-0 d-inline-block w-100" >
                        <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                            <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                                <div class="card card resolved rounded">
                                    <div class="card-header" id="paymentFour" data-toggle="collapse" role="button" data-target="#faq-payment-four" aria-expanded="false" aria-controls="faq-payment-four">
                                        <span class="lead collapse-title">Pre-work Preparation</span>
                                    </div>

                                    <div id="faq-payment-four" class="collapse rounded-0 border-bottom" aria-labelledby="paymentFour" data-parent="#faq-payment-qna">
                                        <div class="card-body pt-2">
                                            <div class="mb-1 px-0" >
                                                <div class="table-responsive">
                                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col" class="text-center" style="width:200px;">Preparedby</th>
                                                            <th scope="col" class="text-left" style="min-width:300px;">Work Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(m1, index) in prework" v-bind:key="index">
                                                            <td class="text-center capitalisetext ">
                                                                {{prework[index].preparedby}}
                                                            </td>
                                                            <td class="text-left " v-html="prework[index].workdescription">                                               </td>
                                                            </tr>
                                                        </tbody>                          
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content mb-0 d-inline-block w-100" >
                        <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                            <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                                <div class="card card open_primary">
                                    <div class="card-header rounded-0 border-bottom" id="paymentFive" data-toggle="collapse" role="button" data-target="#faq-payment-five" aria-expanded="false" aria-controls="faq-payment-five">
                                        <span class="lead collapse-title">Solution,Notes,Decision</span>
                                    </div>

                                    <div id="faq-payment-five" class="collapse" aria-labelledby="paymentFive" data-parent="#faq-payment-qna">
                                        <div class="card-body pt-1">
                                            <div class="mb-1 px-0" >
                                                <div class="table-responsive" >
                                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col" class="text-left" >Topic</th>
                                                            <th scope="col" class="text-center" style="width:200px;">Owner</th>
                                                            <th scope="col" class="text-center" style="width:140px;">Time</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(m1, index) in solutions" v-bind:key="index">
                                                            <td class="text-left capitalisetext ">
                                                                {{solutions[index].topic}}
                                                            </td>
                                                            <td class="text-center capitalisetext ">
                                                                {{solutions[index].owner}}
                                                            </td>
                                                            <td class="text-center capitalisetext ">
                                                                {{solutions[index].time}}
                                                            </td>
                                                            </tr>
                                                        </tbody>                          
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-content mb-0 d-inline-block w-100" >
                        <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                            <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                                <div class="card card open_oranges">
                                    <div class="card-header rounded-0 border-bottom" id="paymentSix" data-toggle="collapse" role="button" data-target="#faq-payment-six" aria-expanded="false" aria-controls="faq-payment-six">
                                        <span class="lead collapse-title">Action Items</span>
                                    </div>

                                    <div id="faq-payment-six" class="collapse" aria-labelledby="paymentSix" data-parent="#faq-payment-qna">
                                        <div class="card-body pt-1">
                                            <div class="mb-1 px-0" >
                                                <div class="table-responsive">
                                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col" class="text-center">Owner</th>
                                                            <th scope="col" class="text-left">Action</th>
                                                            <th scope="col" class="text-center">Status</th>
                                                            <th scope="col" class="text-center">Due Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(m1, index) in actionitems" v-bind:key="index">
                                                            <td class="text-center capitalisetext ">
                                                                {{actionitems[index].owner}}
                                                            </td>
                                                            <td class="text-left capitalisetext ">
                                                                {{actionitems[index].action}}
                                                            </td>
                                                            <td class="text-center capitalisetext ">
                                                                {{actionitems[index].status}}
                                                            </td>
                                                            <td class="text-center capitalisetext ">
                                                                {{actionitems[index].duedate}}
                                                            </td>
                                                            </tr>
                                                        </tbody>                          
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content mb-0 d-inline-block w-100" >
                        <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                            <div class="collapse-margin collapse-icon mt-1" id="faq-payment-qna">
                                <div class="card card resolved rounded">
                                    <div class="card-header rounded-0 border-bottom" id="paymentSeven" data-toggle="collapse" role="button" data-target="#faq-payment-seven" aria-expanded="false" aria-controls="faq-payment-seven">
                                        <span class="lead collapse-title">Next Meeting</span>
                                    </div>

                                    <div id="faq-payment-seven" class="collapse" aria-labelledby="paymentSeven" data-parent="#faq-payment-qna">
                                        <div class="card-body pt-1">
                                            <div class="mb-1 px-0" >
                                                <div class="table-responsive">
                                                    <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                        <thead>
                                                            <tr>
                                                            <th scope="col" class="text-center" style="min-width:120px;">Date</th>
                                                            <th scope="col" class="text-left">Objective</th>
                                                            <th scope="col" class="text-center">Time</th>
                                                            <th scope="col" class="text-center">Location</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(m1, index) in nextmeeting" v-bind:key="index">
                                                            <td class="text-center capitalisetext ">
                                                                {{nextmeeting[index].date}}
                                                            </td>
                                                            <td class="text-left capitalisetext" v-html="nextmeeting[index].objective">
                                                                
                                                            </td>
                                                            <td class="text-center capitalisetext ">
                                                                {{nextmeeting[index].time}}
                                                            </td>
                                                            <td class="text-center capitalisetext ">
                                                                {{nextmeeting[index].location}}
                                                            </td>
                                                            </tr>
                                                        </tbody>                          
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>


          <div>&nbsp;</div>
        </div>
        </div>           
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Trash2Icon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,EditIcon,SearchIcon,MoreVerticalIcon,Edit2Icon,PackageIcon,PieChartIcon,
ClipboardIcon,EyeIcon,UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,CodesandboxIcon,PlusIcon,BoxIcon,WatchIcon} from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import presignurl from '../getPresignurl.vue';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'viewmomplanner',
    components:{
        VueTimepicker,
        Loading,PlusSquareIcon,DownloadIcon,EyeIcon,UploadCloudIcon,CodesandboxIcon ,PackageIcon,
        VueElementLoading,MoreVerticalIcon,Edit2Icon,MinusSquareIcon,ClipboardIcon,
        vSelect,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,
        EditIcon,PieChartIcon,
        Treeselect,
        DatePicker,
        SearchIcon,
        Pagination,
        ckeditor: CKEditor.component,presignurl,
        profilepic,
        Trash2Icon,
        PlusIcon,
        BoxIcon,
        WatchIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            tasklistofbusiness:[],
            undt:null,
            isLoading: false,
            fullPage: true,
            editRowData:[],
            editRowData1:[],
            pagefeatures:[],
            loginuscondition:'',
            file: '',
            card_content:'card-content',
            collapse:'collapse',
            // files:[],
            loginusername:'',
            card:'card',
            deptlist:'',
            backpagedata:[],
            listActive:true,
            loginpersonname:'',
            tokendata:null,
            userrole:null,
            momDetails:[],
            document: {
                selectedFiles: [],
                fields: [{
                docname: '',
                comment: '',
                attachment: '',
                }],
            },
            errors: {
                document: {
             }
            },
            taskmodule: 0,
            attachmentview: null,
            accepttype:apiUrl.uploadfiletype,
            isRequestActive: true,
            imgExt:['jpeg','jpg','png'],
            vidExt: ['mov', 'movie', 'mp4', 'wmv', 'avi', 'mkv'],
            documentname:'',
            plannerdoc:{
                docname:'',
                comment:''
            },
            disRequestActive: true,
            border_warning:'border-warning',
            isdocsRequestActive:false,
            newdocument: {
                selectedFiles: [],
                fields: [{
                docname: '',
                comment: '',
                attachment: '',
                }],
            },
            momDetailsbyPlanner:[],
            colorlist:[],
            empname:''
        }
    },  
    mounted(){
        window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              window.location.reload();
          }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.loginusername=userdata.username
            this.loginpersonname=userdata.firstname+' '+userdata.lastname;
            this.loginuscondition = userdata.userid
            this.pagefeatures = {}
            this.taskmodule = window.localStorage.getItem("taskmodule")
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
                if (element.page === '/businesstask/list') {
                    this.pagefeatures[element.featurename] = element.featureaccess
                }
            })
            this.colorlist=['#ffcebe','#ffe3c0','#ffe9f0','#F9D8D6','#CDF5F6','#CBE4F9']
            this.tokendata = window.localStorage.getItem('token');
            
            
        }
        this.getPlannerMomDetails(this.$route.params.plannermasterid);
        // this.getPlannerDocById(this.$route.params.plannermasterid);
            this.getfcmomdetailsbyplannerid(this.$route.params.plannermasterid,this.$route.params.id);
    },
    methods:{
        getfcmomdetailsbyplannerid(plannermasterid,id) {
            this.isLoading = true
            let url = 'api/getfcmomdetailsbyplannerid'
            this.input = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                plannermasterid: parseInt(plannermasterid),
                id: parseInt(id)
            };
            axios({
                method: 'post',
                url: url,
                data: this.input,
                'headers': {'authorization': this.tokendata}
            }).then((result) => {
                this.isLoading = false
                if (result.data.errorCode == 0) {
                    this.momDetailsbyPlanner = result.data.data
                    this.openEditModule(result.data.data[0],0)
                } else {
                    this.momDetailsbyPlanner = []
                } 
            }).catch(e => {
                this.displayError(e)
            });
        },
        openEditModule: function (currRowData, currRowData1,index) {
          this.agenda = [];
          if(currRowData.agenda.data.length > 0){
            for(let i=0; i < currRowData.agenda.data.length; i++) {
              let pushObj = {
                topic : currRowData.agenda.data[i].topic,
                owner : currRowData.agenda.data[i].owner,
                time : currRowData.agenda.data[i].time,
                index:i,
              }
              this.getEmployeeByID(pushObj.owner)
              this.agenda.push(pushObj);
              
            }
          }
          this.attendees = [];
          if(currRowData.attendees.data.length > 0){
            for(let i=0; i < currRowData.attendees.data.length; i++) {
              let pushObj1 = {
                dept : currRowData.attendees.data[i].dept,
                user : currRowData.attendees.data[i].user,
                email : currRowData.attendees.data[i].email,
                index:i
              }
              this.attendees.push(pushObj1);
            }
          }

          this.absentees = [];
          if(currRowData.absentees.data.length > 0){
            for(let i=0; i < currRowData.absentees.data.length; i++) {
              let pushObj1 = {
                dept : currRowData.absentees.data[i].dept,
                user : currRowData.absentees.data[i].user,
                email : currRowData.absentees.data[i].email,
                index:i
              }
              this.absentees.push(pushObj1);
            }
          }

          this.prework = [];
          if(currRowData.prework.data.length > 0){
            for(let i=0; i < currRowData.prework.data.length; i++) {
              let pushObj = {
                preparedby : currRowData.prework.data[i].preparedby,
                workdescription : currRowData.prework.data[i].workdescription,
                index:i
              }
              this.prework.push(pushObj);
            }
          }

          this.solutions = [];
          if(currRowData.solutions.data.length > 0){
            for(let i=0; i < currRowData.solutions.data.length; i++) {
              let pushObj = {
                time : currRowData.solutions.data[i].time,
                owner : currRowData.solutions.data[i].owner,
                topic : currRowData.solutions.data[i].topic,
                index:i
              }
              this.solutions.push(pushObj);
            }
          }

          this.actionitems = [];
          if(currRowData.actionitems.data.length > 0){
            for(let i=0; i < currRowData.actionitems.data.length; i++) {
              let pushObj = {
                owner : currRowData.actionitems.data[i].owner,
                action : currRowData.actionitems.data[i].action,
                status : currRowData.actionitems.data[i].status,
                duedate : currRowData.actionitems.data[i].duedate,
                index:i
              }
              this.actionitems.push(pushObj);
            }
          }

          this.nextmeeting = [];
          if(currRowData.nextmeeting.data.length > 0){
            for(let i=0; i < currRowData.nextmeeting.data.length; i++) {
              let pushObj = {
                date : currRowData.nextmeeting.data[i].date,
                time : currRowData.nextmeeting.data[i].time,
                location : currRowData.nextmeeting.data[i].location,
                objective : currRowData.nextmeeting.data[i].objective,
                index:i
              }
              this.nextmeeting.push(pushObj);
            }
          }
        },
        getPlannerMomDetails(plannermasterid) {
            this.isLoading = true;
            let url = "api/getPlannerMomDetails";
            this.input = {
                plannermasterid:plannermasterid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                // userrole : this.userrole
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.momDetails = result.data.data.getdata
                } else {
                    this.momDetails = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY');
        },
        formatData(data) {
            return data
        },
        getColorClass(index) {
        if(index >= 9) {
            let lastdigit = index % 10
            if(!lastdigit) {
            if(index % 2) {
                lastdigit = 5
            } else {
                lastdigit = 3
            }
            }
            return `document_bg_randam_bg${lastdigit}`
        }
        return `document_bg_randam_bg${index+1}`
        },

        getDownloadedLink(data, curr=null) {
        if(data) {
            let payload = {
            documents:[{link:data,empid: this.undt.userid,useremail: this.undt.username}],
            empcode: this.undt.userid,
            useremail: this.undt.username
            };
            axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
            })
            .then((result) => {
            this.isLoading = false
            if(result.data.status) {
                if(result.data.data[0].presignurl){
                    if(curr=='zoom'){
                    $('.imagepreview').attr('src', result.data.data[0].presignurl);
                    $('#imagemodal').modal('show');  
                    return 
                    } else if(curr == 'vidzoom') {
                    this.videoURL = result.data.data[0].presignurl
                    this.videoIsOpen = true
                    $('#videomodal').modal('show');
                    return
                    } else if(curr == 'pdfpreview') {
                    window.open(result.data.data[0].presignurl, '', 'left=0,top=0,width=900,height=1200,toolbar=0,scrollbars=0,status=0')
                    return 
                    }
                    window.open(result.data.data[0].presignurl, '_blank')     
                }
            }
            }).catch(e => {
                this.displayError(e)
            });
        }
    },
    myrequestollapse() {
      this.disRequestActive=!this.disRequestActive
    },
    formatData(data) {
      if(!data) {
        return 'NA'
      }
      if(data.length > 83) {
        return data.substring(0,84) + '...'
      }
      return data
    },
    onZoom(e,  action){
      this.getDownloadedLink(e, action);
    },
    vlc(e, action) {
      this.getDownloadedLink(e, action);
    },

    newSaveDocument() {
      let url = 'api/master/planner/updatePlannerDocument'
      let payload = {
        updatedby: this.undt.username,
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        newdocumentlist: this.document.newfields,
        id: this.documentid,
        updateddocumentlist: this.document.fields.map(i => {
          return {
            id2: i.id2,
            id: i.id,
            docname: i.docname,
            comment: i.comment,
            attachment: i.docfilepath
          }
        }),
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers: { authorization :this.tokendata }
      })
      .then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          Swal.fire({
            title: "Success!",
            text: result.data.msg,
            icon: 'success',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
        //   this.restForm()
        //   this.getPlannerDocById(this.documentid)
          this.docsuploadcollapse()
          // this.$router.push('/document/list')
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          }).then(function() {
            window.location.href = "/#/login";
          })
        } else {
          Swal.fire({
            title: "Failed",
            text: result.data.msg,
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
        }
      }).catch(e => {
            this.displayError(e)
        })
    },
    redirectToMOMUpdate: function(btnstate, rowdata) {
        this.$router.push({
            name: 'momupdate',
            path: '/fc_mom/momupdate',
            params: {
                backbtnstate: btnstate,
                id: rowdata.id,
                plannermasterid: rowdata.plannermasterid
            }
        })
    },
    redirectToMOMCreate(btnstate) {
        let rowdata = this.momDetails[0].plannermasterid
        this.$router.push({
            name: 'momcreate',
            path: '/fc_mom/momcreate',
            params: {
                backbtnstate:btnstate,
                clickeddata:rowdata
            }
        })
    },
    docsuploadcollapse() {
      this.isdocsRequestActive=false
      this.disRequestActive=true
      $('html, body').animate({ scrollTop: $(document).height() }, 1200);
    },

    getEmployeeByID(empid){
    axios({
            'method': 'POST',
            'url': 'api/getEmployeeByID',
            'data': {empid:this.undt.userid, useremail: this.undt.username,empcode: this.undt.userid},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    this.empname=result.data.data.rows[0];
                    this.openEditModule(this.empname,0)
                }else{
                    this.empname=[];
                }
            }).catch(e => {
                this.displayError(e)
            })

        },
    }
    
}
</script>
<style>
.ck-content{
    min-height: 150px !important;
}
.displayblk{
    display:block !important;
}
.disc p:last-child{
  margin-bottom: 0;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.display{
    display: none !important;
}

.pop-up {
    width: 70% !important;
}
.click-zoom input[type=checkbox] {
  display: none;
}

.click-zoom img {
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.click-zoom input[type=checkbox]:checked~img {
  transform: scale(10);
  cursor: zoom-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}
.meeting_dic_font_size{
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.3px;
}

</style>