<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-9 col-12 mb-1 px-0 pad_top_mar_bot-5">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">SLA Configuration</h3>
               
                        </div>
                    </div>
                </div>
            <section id="card-navigation">
            <div class="file-manager-content-body">
                <div class="drives">
                     
                    <div class="card border-warning filter_card_searchbgst" >
                        <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                            <div class="container-fluid">
                            <div class="row fc_my-05" >
                                <div class="px-1 w-auto mobile-padding-0-2">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                            ></search-icon>
                                        </div>
                                        </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search SLA Configuration Details</h4>
                                </div>
                                <div class="ml-auto d-flex">
                                <div  class=" mx-1  text-right mobile-padding-0-2">
                                    <div class="input-group-append displayblk">
                                        <button id="commonaddmodule" class="btn btn-sm btn-relief-success"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" :data-target="currentmodule" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false">
                                             <span class="mobile_hide_bt">Create SLA Configuration</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></button>
                                    </div>
                                </div>
                                <span class="rotetions_view_arrow mr-1"> 
                                <em class="las la-angle-down"></em>
                                </span>
                            </div>                                
                            </div>
                            </div>
                        </div>
                      <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                        <div class="card-body pt-2 px-sm-mobile-10">
                          <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Project</label>
                              <treeselect placeholder="Enter Min 3 Chars To Select Project " v-model="search.project" :options="projectlist" v-on:search-change="getallprojectlistv1" @input="handleCategoryChange"  :multiple="true" :clear-on-select="true"  />
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Main Category</label>
                              
                              <treeselect placeholder="Enter Min 3 Chars To Select Main Category " v-model="search.maincategory" :options="projectmodulelist" @select="selProject" @input="handleInputChangesearch"    />

                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Sub-Category</label>
                              <treeselect class="projectmdllistdd" placeholder="Select Sub-Category" v-model="search.subcategory" :options="applist" :multiple="true" :clear-on-select="true"   />
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                              <label class="form-label lable-left">Issue Classification</label>
                              <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Issue Classification" 
                               v-model="search.issueclassification" :options="issueclassification" :multiple="true" :clear-on-select="true"/>
                            </div>
                                                       
                            <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right">
                              <button type="submit" class="btn btn-relief-primary mt-1 mr-1" @click="getslaconfigurelist(search)"> <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                              <button type="reset" class="btn btn-outline-secondary mt-1 mr-1 " @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span></button>
                              <button type="move" class="btn btn-relief-primary mt-1" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
            <div class="row">
                <div class="col-md-12">
                    <div class="card mb-3">
                        <div class="">
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="row" id="proModuleList">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="" style="padding-left:0px !important;padding-top:0px !important;">
                                                    <div class="row">                       
                                                        <div class="col-md-9">
                                                            <div class="input-group-append" v-if="showfilterboxsection">
                                                            </div>
                                                        </div> 
                                                        
                                                    </div>
                                                </div>
                                                <div class="table-responsive" style="" v-if="showtabledata">
                                                    <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="srno first-col-sticky py-1 align-middle">Sr#</th>
                                                                <th scope="col" class="second-col-sticky align-middle" style="min-width:180px;">Project</th>
                                                                <th scope="col" class="align-middle">Main Category</th>
                                                                <th scope="col" class="align-middle">Sub-category</th>
                                                                <th scope="col" class="align-middle">Issue Classification</th>
                                                                <th scope="col" class="align-middle">SLA / TAT</th>
  
                                                                <th scope="col" class="clsaction align-middle">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(data,index) in companymasterlist">        
                                                              <tr v-bind:key="data.moduleid">
                                                                <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                <td class="srno first-col-sticky">
                                                                  <span v-html="showprojectname(data.projectname)"></span>
  
                                                                 </td>
                                                                <td>
                                                         
                                                                <span class="font-weight-normal capitalisetext">{{data.projectmodulename}}</span>
                                                                </td>
                                                                <td>
                                                                  <span v-html="showprojectname(data.applicationmodulename)"></span>
  
                                                                 </td>
                                                                <td> {{ data.issueclassification.toUpperCase() }}</td>
                                                                <td>Response:
                                                                    <span v-if="parseInt(data.responsetime.hours)>24">
                                                                    {{ parseInt(parseInt(data.responsetime.hours)/24) > 31 ? '31' : parseInt(parseInt(data.responsetime.hours)/24) }} Days
                                                                    
                                                                    <span v-if="(parseInt(data.responsetime.hours) % 24) > 0">
                                                                              
                                                                        {{ parseInt(parseInt(data.responsetime.hours)/24)> 31 ? '24' : parseInt(parseInt(data.responsetime.hours) % 24) }} hours
                                                                    </span>

                                                                        <span v-if="parseInt(parseInt(data.responsetime.hours)/24)> 31">
                                                                            ,
                                                                            24 hours
                                                                        </span>

                                                                    </span>
                                                                    <span v-else>
                                                                        {{ data.responsetime.hours ? data.responsetime.hours : '0' }} hours
                                                                    </span>
                                                                    
                                                                   
                                                              And     
                                                              
                                                              


                                                                  {{ data.responsetime.minutes ? data.responsetime.minutes : '0' }} Minutes
                                                                  <br><hr>
                                                                  Resolution: 
                                                                  
                                                                  
                                                                  <span v-if="parseInt(data.resolutiontime.hours)>24">
                                                                    {{ parseInt(parseInt(data.resolutiontime.hours)/24) > 31 ? '31' : parseInt(parseInt(data.resolutiontime.hours)/24) }} Days
                                                                    
                                                                    <span v-if="(parseInt(data.resolutiontime.hours) % 24) > 0">
                                                                        ,
                                                                        {{ parseInt(parseInt(data.resolutiontime.hours)/24)> 31 ? '24' : parseInt(parseInt(data.resolutiontime.hours) % 24) }} hours
                                                                    </span>

                                                                    <span v-if="parseInt(parseInt(data.resolutiontime.hours)/24)> 31">
                                                                        ,   
                                                                        24 hours
                                                                        </span>

                                                                    </span>
                                                                    <span v-else>
                                                                        {{ data.resolutiontime.hours ? data.resolutiontime.hours : '0' }} hours
                                                                    </span>
                                                                  
                                                                    
                                                              And        {{ data.resolutiontime.minutes ? data.resolutiontime.minutes : '0' }} Minutes
                                                              </td>
                                                            <td class="clsaction">                                                                  
                                                                <div class="dropdown">
                                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                        <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                    </button>
                                                                    <div class="dropdown-menu">
                                                                        <a class="dropdown-item devEditEle" href="javascript:void(0);"   data-toggle="modal" :data-target="currentmodule" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                                                            <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                            <span>Edit</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                                </tr>
                                                            </template>
                                                            <template v-if="companymasterlist.length==0 && isresponce">
                                                                <tr><td colspan="14" style="text-align:center">No record found</td></tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                </div>
                                <div class="pageCenter text-center">
                                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
            </div>
            </section>
        </div>
        <div class="modal modal-slide-in fade" id="projectmodulemaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal"  aria-label="Close" style="color:#3E4757 !important" @click="resetForm()">×</button>
                    <div class="modal-header mb-1 leftsidepopupstyle px-1" style="background-color:#ECEEFB !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <span class="align-middle mx-0">Add/Update SLA Configuration Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm mobile-padding-0-2 px-0">
                        
                        <form>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appprojectdd">Project</label>
                                    <treeselect placeholder="Enter Min 3 Chars To Select Project " v-model="slaconfig.project" :options="projectlist1" v-on:search-change="getallprojectlistv2" @input="handleCategoryChange"  :multiple="true" :clear-on-select="true"  />
                                    <div class="errorinputmsg" v-if="this.errors.slaconfig.project">{{ this.errors.slaconfig.project }}</div>

                                    
                                 </div>
                            </div>
                            <div class="col-12">
                                <div v-if="(this.slaconfig.project && this.slaconfig.project.length==1)"
                                class="form-group">
                                    <label for="appprojectdd">Main Category</label>
                                    <treeselect placeholder="Enter Min 3 Chars To Select Main Category " v-model="slaconfig.maincategory" :options="projectmodulelist" @select="selProject" @input="handleInputChange"    />
                                    <div class="errorinputmsg" v-if="this.errors.slaconfig.maincategory">{{ this.errors.slaconfig.maincategory }}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div v-if="(this.slaconfig.project && this.slaconfig.project.length==1)"
                                class="form-group">
                                    <label for="appprojectdd">Sub-Category</label>
                                    <treeselect placeholder="Select Sub Category" v-model="slaconfig.subcategory" :options="applicationlist" :multiple="true" :clear-on-select="true"   />
                                    <div class="errorinputmsg" v-if="this.errors.slaconfig.subcategory">{{ this.errors.slaconfig.subcategory }}</div>
  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label" for="project-name">Issue Classification </label>
                                    <treeselect placeholder="Select Issue Classification" v-model="slaconfig.issueclassification" :options="issueclassification"   :clear-on-select="true" />
  
                                    <div class="errorinputmsg" v-if="this.errors.slaconfig.issueclassification">{{ this.errors.slaconfig.issueclassification }}</div>
  
                                </div>
   
                            </div>
                             <div class="col-12">
                            
                           
                              <div class="form-group">
                                    <label for="appmdldesc">Response Time</label>
                                  <div class="row">
                                    <div class="col-4">
                                    <treeselect placeholder=" Days" v-model="slaconfig.responsedays" :options="adayslist" @select="seldays"   :clear-on-select="true" />
                                   <div class="errorinputmsg" v-if="this.errors.slaconfig.responsedays">{{ this.errors.slaconfig.responsedays }}</div>  
                                        
                                 </div>   
                                    <div class="col-4">
                                    <treeselect placeholder=" Hours" v-model="slaconfig.responsetime_hour" :options="ahourslist"   :clear-on-select="true" />
                                   <div class="errorinputmsg" v-if="this.errors.slaconfig.responsetime_hour">{{ this.errors.slaconfig.responsetime_hour }}</div>  
  
                                 </div>   
                                 <div class="col-4">
                                   <treeselect placeholder=" Minutes" v-model="slaconfig.responsetime_min" :options="aminutelist"   :clear-on-select="true" />
                                   <div class="errorinputmsg" v-if="this.errors.slaconfig.responsetime_min">{{ this.errors.slaconfig.responsetime_min }}</div>  
  
                                 </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="appmdldesc">Resolution Time</label>
                                    <div class="row">
                                        <div class="col-4">
                                       
                                       <treeselect placeholder=" Days" v-model="slaconfig.resolutiondays" :options="adayslist" @select="seldayss"  :clear-on-select="true" />
                                       <div class="errorinputmsg" v-if="this.errors.slaconfig.resolutiondays">{{ this.errors.slaconfig.resolutiondays }}</div>  
      
                                     </div>
                                      <div class="col-4">
                                       
                                   <treeselect placeholder=" Hours" v-model="slaconfig.resolutiontime_hour" :options="ahourslist"   :clear-on-select="true" />
                                   <div class="errorinputmsg" v-if="this.errors.slaconfig.resolutiontime_hour">{{ this.errors.slaconfig.resolutiontime_hour }}</div>  
  
                                 </div>
                                 <div class="col-4">
                                   <treeselect placeholder=" Minutes" v-model="slaconfig.resolutiontime_min" :options="aminutelist" :clear-on-select="true" />
                                   <div class="errorinputmsg" v-if="this.errors.slaconfig.resolutiontime_min">{{ this.errors.slaconfig.resolutiontime_min }}</div>  
  
                                 </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group d-flex flex-wrap mb-0" >
                                    <button   type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt36" data-dismiss="modal" v-on:click.stop.prevent="savewizard($event)">Submit</button>
                                    <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Clear</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>         
    </div>
  </template>
  <style>
  .card .card-header{
    padding: 0.8rem;
  }
  .createbtnparentdiv{
    padding-right: 0% !important;
  }
  .displayblk{
    display:block !important;
  }
  .createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  </style>
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
  import Treeselect from '@riophae/vue-treeselect'
  import { ExternalLinkIcon,PlusSquareIcon,Trash2Icon,XIcon,PlusCircleIcon,Edit2Icon,PlusIcon,MoreVerticalIcon,PackageIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon } from 'vue-feather-icons'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Pagination from 'vue-pagination-2'
  import jwt from 'jsonwebtoken'
  import Loading from 'vue-loading-overlay';
  import commonMethods from '../../utils/commonMethods';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  export default {
    name:'Masters',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        jwt,
        PlusSquareIcon,
        Trash2Icon,
        PlusCircleIcon,
        XIcon,
        ExternalLinkIcon,
        VueTimepicker,PlusCircleIcon,XIcon
    },
    mixins: [ commonMethods ],
    data() {
        return {
            search: {
              maincategory:null,
              subcategory: null,
              issueclassification: null,
              project:null
            },
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            showprojectdata: [],
            showroledata: [],
            showempdata: [],
            isProjectSel: 0,
            isProjectRoleSel: 0,
            showAddForm:false,
            selected: null,
            editRowData:[],
            glbMdl:'',
            isRoleAlloc:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            projectType:[],
            projectdescription:'',
            isProjectMsr:'',
            viewprojectlist:0,
            projoptions:[ {
                id: '',
                label: ''
            }],
            roleoptions:[ {
                id: '',
                label: ''
            }],
            employeeoptions:[ {
                id: '',
                label: ''
            }],
            projvalue:null,
            emplvalue:null,
            rolevalue:null,
            projectmodule:{
                name:null,
                project:null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                projectlist1:[{
                    id:'',
                    label:''
                }],
                desc:null,
                attachment:null,
                attachmentview:null,
                disblesavebtn:true,
                projectdisbale:false,
                iseditstate:false,
                projectname:false,
                businesslist:null,
                business:null,
                projecttypelist:null,
                projecttype:null,
                companylist:null,
                company:null,
                applicationid:null,
           
                filedescp:[],
                businessname:null,
                projecttypename:null,
                companyname:null,
                
  
            },
              errors: {
              slaconfig:{
                  maincategory:null,
                  subcategory:null,
                  project:null,
                  issueclassification:null,
                  responsetime:null,
                  resolutiontime:null,
                  responsetime_hour:null,
                  responsetime_min:null,
                  resolutiontime_hour:null,
                  resolutiontime_min:null,
                  responsedays:null,
                  resolutiondays:null
              }
            },
   
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            currcalltype:'CAMP',
            allapicallauthkey:'',
            prevtabRowData:[],
            projecttabRowData:[],
            roleassitabRowData:[],
            showtabledata:true,
            projectlist:[],
            projectlist1:[],
            modulelist1:[],
            showprojectmodule:false,
            showprojects:true,
            projectcurrentstate:'local',
            activeprojectid:'',
            clickedprojdata:null,
            showfilterboxsection:false,
            currentmodule:'#projectmodulemaster',
            showaddmodulebtn:false,
            isSearchRequestActive:false,
            projectnameList:[],
            applicationMasterid: [],
          
            collapse:'collapse',
            card_content:'card-content',
            backpagedata:null,
            s3bucket: apiUrl.s3url,
            tokendata: null,
            isresponce:false,
            validFileExtensions:apiUrl.validFileExtensions,
            //new
            projectmodulelist : [],
            projectlist : [],
            projectModuleid: [],
            issueclassification:apiUrl.issueclassification,
            applicationlist:[],
            applist:[],
            slaconfig:{
              maincategory:null,
              subcategory:null,
              project:null,
              issueclassification:null,
              responsetime:null,
              resolutiontime:null,
              findissueclassification:null,
              findprojectmoduleid:null,
              responsetime_hour:null,
              responsetime_min:null,
              resolutiontime_hour:null,
              resolutiontime_min:null,
              responsedays:null,
              resolutiondays:null,
              previousids:null
             },
            iseditstate:false,
            ahourslist:[],
            aminutelist:[],
            adayslist:[]
         }
    },
    
    mounted(){
       let permission = this.$Service.verifyAccess();
       if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.getslaconfigurelist()
                this.activityHoursList()
            }
            $(".filter_rotation_onlick").click(function(){
            $(this).toggleClass("common_arrow_rotetion");
            }); 
        }
    },
    methods:{
      activityHoursList() {
        for(let i=0; i<=24; i++) {
          if(i<=9) {
             
            let withZeroNo = '0'+i;
            this.ahourslist.push({ id: withZeroNo, label: withZeroNo});
          } else {
            this.ahourslist.push({ id: i.toString(), label: i.toString() });
          }
        }
        for(let m=0; m<=59; m++) {
          if(m<=9) {
            let withZeroNo = '0'+m;
            this.aminutelist.push({ id: withZeroNo, label: withZeroNo});
          } else {
            this.aminutelist.push({ id: m.toString(), label: m.toString() });
          }
        }
        for(let d=0; d<=31; d++) {
          if(d<=9) {
            let withZeroNo = '0'+d;
            this.adayslist.push({ id: withZeroNo, label: withZeroNo});
          } else {
            this.adayslist.push({ id: d.toString(), label: d.toString() });
          }
        }
      },
      showprojectname(code){
          let responsestrs='';
          let prlists=code.split(',')
          if(prlists.length > 1){
              for(let i=0;i<prlists.length;i++){
                  responsestrs +=prlists[i]+' <br/><hr>'
              }
          }else{
              for(let i=0;i<prlists.length;i++){
                  responsestrs +=prlists[i]
              }
          }
          return responsestrs
          },
        handleInputChange(state){
        if(state!=null){
        this.getApplicationModuleList(state)
        }else{
        this.applicationlist = null
        this.slaconfig.subcategory= null
        }
        },
        handleInputChangesearch(state){
        if(state!=null){
        this.getApplicationList(state)
        }else{
        this.applicationlist = null
        this.search.subcategory = null
        }
        },
        getallprojectlist(node,projectids) {

        if (node && node.length>3 || projectids) {
            this.isLoading = true;
            this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            projectname: node ? node.toLowerCase() : null,
            projectid:projectids ? projectids : null,
            isfrompage:'listingpage'
            }
            axios({
            'method': 'POST',
            'url': 'api/project/getallprojectlist',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
                this.projectlist = result.data.data;
            }else if(result.data.errorCode == 3){
                Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                window.location.href = "/#/login";
                })
            }else{
                this.projectlist = [];
            }
            }).catch(e => {

    this.displayError(e)

    })
        }
        },
        getallprojectlist1(projectids) {
        // if (node && node.length>3 || projectids) {
        this.isLoading = true;
        this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            isfrompage: 'updatepage',
            projectid:projectids
        }
        if(this.slaconfig.project !=null){
                this.inputfield.isfrompage = 'updatepage'
            }else{
                this.inputfield.isfrompage = 'createpage'
            }
        axios({
            'method': 'POST',
            'url': 'api/project/getallprojectlist',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
            this.projectlist1 = result.data.data;
            if(this.projectlist1 && this.projectlist1.length > 0 && (this.slaconfig.project && this.slaconfig.project !=null)){
                    const checkexistproject = this.projectlist1.some(item =>item.id === this.slaconfig.project);
                    if(!checkexistproject){
                        this.getProjectnamebyid(this.slaconfig.project);
                    }
                }else if(this.projectlist1.length ==0 && (this.slaconfig.project && this.slaconfig.project !=null)){
                    this.getProjectnamebyid(this.slaconfig.project);
                }
            }else if(result.data.errorCode == 3){
            Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            }).then(function() {
                window.location.href = "/#/login";
            })
            }else{
            this.projectlist1 = [];
            }
        }).catch(e => {

        this.displayError(e)

        })
        //}
        },
        getallprojectlistv2(node) {
            if (node && node.length>3) {
            this.isLoading = true;
            this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            projectname:node.toLowerCase(),
            }
            if(this.slaconfig.project !=null){
                this.inputfield.isfrompage = 'updatepage'
            }else{
                this.inputfield.isfrompage = 'createpage'
            }
            axios({
            'method': 'POST',
            'url': 'api/project/getallprojectlist',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
                this.projectlist1 = result.data.data;
                if(this.projectlist1 && this.projectlist1.length > 0 && (this.slaconfig.project && this.slaconfig.project !=null)){
                    const checkexistproject = this.projectlist1.some(item =>item.id === this.slaconfig.project);
                    if(checkexistproject){
                        this.getProjectnamebyid(this.slaconfig.project);
                    }
                }else if(this.projectlist1.length ==0 && (this.slaconfig.project && this.slaconfig.project !=null)){
                    this.getProjectnamebyid(this.slaconfig.project);
                }
            }else if(result.data.errorCode == 3){
                Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                window.location.href = "/#/login";
                })
            }else{
                this.projectlist1 = [];
            }
            }).catch(e => {

            this.displayError(e)

            })
            }
            },
        getProjectnamebyid(pid){
            this.isLoading = true;
            this.apiURL="api/project/getallprojectlist"
            this.input={
                useremail: this.undt.username,
                //empid :this.undt.userid,
                empcode: this.undt.userid,
                projectid: pid,
                isfrompage: 'isprojectid'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    if(result.data.data && result.data.data.length > 0){
                        this.projectlist1.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getallprojectlistv1(node) {
        if (node && node.length>3) {
        this.isLoading = true;
        this.inputfield= {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        projectname:node.toLowerCase(),
        isfrompage:'listingpage'
        }
        axios({
        'method': 'POST',
        'url': 'api/project/getallprojectlist',
        'data': this.inputfield,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => {
        this.isLoading = false;
        if(result.data.status == true){
            this.projectlist = result.data.data;
        }else if(result.data.errorCode == 3){
            Swal.fire({
            title: "Session Expired...!!",
            text: result.data.msg,
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
            }).then(function() {
            window.location.href = "/#/login";
            })
        }else{
            this.projectlist = [];
        }
        }).catch(e => {

        this.displayError(e)

        })
        }
        },
        getProjectModuleList(projectid) {          
                 this.isLoading = true
                let url = "api/slaconfigure/geProjectModuleList";
                this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid:projectid ? projectid :null
                };
                if(this.slaconfig.maincategory !=null){
                this.input.isfrompage = 'updatepage'
                }else{
                    this.input.isfrompage = 'createpage'
                }
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
                }).then((result) => {
                    this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.projectmodulelist = result.data.data;
                    if(this.projectmodulelist && this.projectmodulelist.length > 0 && (this.slaconfig.maincategory && this.slaconfig.maincategory !=null)){
                    const checkexistproject = this.projectmodulelist.some(item =>item.id === this.slaconfig.maincategory);
                    if(!checkexistproject){
                    this.getprojectmodulesbyid(this.slaconfig.maincategory);
                    }
                    }
                    else if(this.projectmodulelist.length ==0 && (this.slaconfig.maincategory && this.slaconfig.maincategory !=null)){
                        this.getprojectmodulesbyid(this.slaconfig.maincategory);
                    }
                    } else {
                      this.projectmodulelist= [];
                    }
                }).catch(e => {
                this.displayError(e)
                })
           
        },
        getprojectmodulesbyid(moduleid) {
        this.input = {
            moduleid: moduleid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: 'api/slaconfigure/geProjectModuleList',
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.length > 0){
                        const checkexistprojectmodule = this.projectmodulelist.some(item =>item.id === moduleid);
                        if(!checkexistprojectmodule){
                            this.projectmodulelist.push(result.data.data[0]);
                        }
                    }else if(this.projectmodulelist.length ==0){
                        this.projectmodulelist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
         getModuleList(projectid) {
                 this.isLoading = true
                let url = "api/slaconfigure/geProjectModuleList";
                this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectid:projectid,
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.modulelist1 = result.data.data;
                    } else {
                      this.modulelist1= [];
                    }
                }).catch(e => {
                this.displayError(e)
                })
           
        },
        getApplicationModuleList(projectid) {
        if(this.slaconfig.maincategory || this.search.maincategory){
        this.isLoading = true
        let url = "api/slaconfigure/geApplicationModuleList";
        this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        projectmoduleid:projectid
        };
        if(this.slaconfig.subcategory !=null){
        this.input.isfrompage = 'updatepage'
        }else{
            this.input.isfrompage = 'createpage'
        }
        axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers': { 'authorization': this.tokendata }
        }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
        this.applicationlist = result.data.data;
        if(this.applicationlist && this.applicationlist.length > 0 && (this.slaconfig.subcategory && this.slaconfig.subcategory !=null)){
            const checkexistproject = this.applicationlist.some(item =>item.id === this.slaconfig.subcategory);
            if(!checkexistproject){
                this.getAppModulesbyappmoduleid(this.slaconfig.subcategory);
            }
        }else if(this.applicationlist.length ==0 && (this.slaconfig.subcategory && this.slaconfig.subcategory !=null)){
            this.getAppModulesbyappmoduleid(this.slaconfig.subcategory);
        }
        } else {
        this.applicationlist= [];
        }
                }).catch(e => {
        this.displayError(e)
        })
        }
        },
        getAppModulesbyappmoduleid(appmid) {
        this.input = {
            appmoduleid: appmid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: 'api/slaconfigure/geApplicationModuleList',
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.length > 0){
                        const checkexistappmodule = this.applicationlist.some(item =>item.id === appmid);
                        if(!checkexistappmodule){
                            this.applicationlist.push(result.data.data[0]);
                        }else if(this.applicationlist.length ==0){
                            this.applicationlist.push(result.data.data[0]);

                    }
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getApplicationList(projectid) {
        if(this.slaconfig.maincategory || this.search.maincategory){
        this.isLoading = true
        let url = "api/slaconfigure/geApplicationModuleList";
        this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        projectmoduleid:projectid,
        };
        axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers': { 'authorization': this.tokendata }
        }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
        this.applist = result.data.data;
        console.log(this.applist,'this.applist-------------------');
        } else {
        this.applist= [];
        }
        console.log(this.applist,'this-------------------');
                }).catch(e => {
        this.displayError(e)
        })
        }
        },
        projectCollapse:function(){
            this.showprojects=!this.showprojects
        },
        redirecttobreadkumb:function(objThis){
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        resetRecords: function () { 
            this.projectmodulelist = []
            this.projectlist =[]
            this.search.maincategory = null
            this.search.subcategory = null
            this.search.issueclassification = null
            this.search.project = null

            let rowdata={}
            this.getslaconfigurelist(rowdata);
        },
        resetForm: function () {
          this.slaconfig.maincategory = null
          this.slaconfig.subcategory = null
          this.slaconfig.issueclassification = null
          this.slaconfig.responsetime = {'HH':'', 'mm':''}
          this.slaconfig.resolutiontime = {'HH':'', 'mm':''}
          this.slaconfig.responsetime_hour = null
          this.slaconfig.responsetime_min = null
          this.slaconfig.resolutiontime_hour =null
          this.slaconfig.resolutiontime_min =null
          this.slaconfig.project =null

          this.clearerrorForm()
        },
        getslaconfigurelist(rowdata){
            this.islistLoading=true
            this.input = {
                 offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            this.input.maincategoryid = this.search.maincategory ? this.search.maincategory : null
            this.input.subcategoryid = this.search.subcategory ? this.search.subcategory : null
            this.input.issueclassification = this.search.issueclassification ? this.search.issueclassification : null
            this.input.project = this.search.project ? this.search.project : null

             let url = 'api/slaconfigure/getslaconfigList' 
   
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isresponce=true
                this.islistLoading=false;
                if(result.data.errorCode == 0){
                    this.showprojects=false
                    this.showprojectmodule=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
             
                } else if(result.data.errorCode == 3){
                  Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                      window.location.href = "/#/login";
                  })
                }else{
                    this.showprojectmodule=true;
                    this.companymasterlist=[];
                    this.totalcount = 0
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        clickCallback: function(pageNum) {
            //console.log(pageNum)
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            this.getprojectmodulelist(this.clickedprojdata)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#proModuleList").offset().top -70
                });
            }, 500);
            
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            this.projectBlured=true
            this.projectdescBlured=true
            this.projectcountryBlured=true
            this.appenvhosturlBlured=true
            this.appenvhostdetailspathBlured=true
            this.appenvhostpathBlured=true
            this.appenvhostBlured=true
            this.appenvnameBlured=true
            this.appenvtypeBlured=true
            this.applinameBlured=true
  
            if(tblNm=="t11" && (this.validText(this.project.name) && this.validText(this.project.desc))){
                this.valid = true;
            }
            if(tblNm=="t12" && (this.validText(this.application.name))){
                this.valid = true;
            }
            if(tblNm=="t13" && (this.validText(this.appenv.name) && this.validText(this.appenv.host) && this.validText(this.appenv.hostpath) && this.validText(this.appenv.hostdetails)  && this.validText(this.appenv.hosturl) && this.valid2Text(this.appenv.type) )){
                this.valid = true;
            }            
        },
        setSelected(value)
        {
            this.selected=value
        },
        addModule(objThis){
          this.applicationlist= [];
          this.projectmodulelist= [];
          this.search.issueclassification = null
          this.search.maincategory = null
          this.search.subcategory = null
          this.slaconfig.maincategory = null
          this.slaconfig.subcategory = null
          this.slaconfig.issueclassification = null
          this.slaconfig.responsetime =  {'HH':'', 'mm':''}
          this.slaconfig.resolutiontime =  {'HH':'', 'mm':''}
          this.slaconfig.responsetime_hour = null
          this.slaconfig.responsetime_min = null
          this.slaconfig.resolutiontime_hour =null
          this.slaconfig.resolutiontime_min =null
           
        },
        savewizard(objThis){     
            this.valid = this.validateForm(objThis);
             if (this.valid) {
                   this.input = {
                      createdby: this.undt.username,
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                }

                let apiURL='api/slaconfigure/create'

                let response_hours = this.slaconfig.responsetime_hour
                let resolution_hours = this.slaconfig.resolutiontime_hour

                if(this.slaconfig.responsedays && parseInt(this.slaconfig.responsedays) > 0){
                   let get_response_hours  = 24 * parseInt(this.slaconfig.responsedays)
                    response_hours = parseInt(response_hours) + get_response_hours 
                    
                    if((response_hours.toString()).length < 2){
                    response_hours = '0'+response_hours
                    }
                }

                if(this.slaconfig.resolutiondays && parseInt(this.slaconfig.resolutiondays) > 0){
                   let get_resolution_hours  = 24 * parseInt(this.slaconfig.resolutiondays)
                   resolution_hours = parseInt(resolution_hours) + get_resolution_hours 
                    
                    if((resolution_hours.toString()).length < 2){
                        resolution_hours = '0'+resolution_hours
                    }
                }
              
               // this.input.responsedays =  this.slaconfig.responsedays ? this.slaconfig.responsedays : null
               // this.input.resolutiondays =  this.slaconfig.resolutiondays ? this.slaconfig.resolutiondays : null

                this.input.responsetime =  response_hours +':'+this.slaconfig.responsetime_min
                this.input.resolutiontime =  resolution_hours +':'+this.slaconfig.resolutiontime_min 
  
                if(this.iseditstate == true){
                  apiURL='api/slaconfigure/update'
                 // this.input.findissueclassification = this.slaconfig.findissueclassification
                 // this.input.findprojectmoduleid = this.slaconfig.findprojectmoduleid
                  //this.input.responsetime= this.slaconfig.responsetime
                //  this.input.resolutiontime= this.slaconfig.resolutiontime
                this.input.previousids = this.slaconfig.previousids
                }
                
                this.input.maincategoryid = this.slaconfig.maincategory ? this.slaconfig.maincategory : null
                this.input.subcategoryid = this.slaconfig.subcategory ? this.slaconfig.subcategory : null;
                 this.input.issueclassification =  this.slaconfig.issueclassification
                 this.input.project =  this.slaconfig.project
                 this.isAddState=false
                 this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid
                 axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                         Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.editRowData={};
                        this.slaconfig.maincategory = null
                        this.slaconfig.project = null
                        this.slaconfig.subcategory = null
                        this.slaconfig.issueclassification = null
                      //   this.slaconfig.responsetime = {'HH':'', 'mm':''}
                      //   this.slaconfig.resolutiontime = {'HH':'', 'mm':''}
                        this.slaconfig.findissueclassification = null
                        this.slaconfig.findprojectmoduleid  = null
                      this.slaconfig.responsetime_hour = null
                      this.slaconfig.responsetime_min = null
                      this.slaconfig.resolutiontime_hour =null
                      this.slaconfig.resolutiontime_min =null
                      this.slaconfig.responsedays = null
                      this.slaconfig.resolutiondays = null
                        

                        this.iseditstate = false
                        this.getslaconfigurelist(this.search);
                        $('.close').click();
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
                this.displayError(e)
                })
            }
        },
        validateForm(objThis){
            this.clearerrorForm();
            this.isAddState=false
            var isValid=true;
  
                // if(this.slaconfig.maincategory == null || this.slaconfig.maincategory == '' || this.slaconfig.maincategory.length == 0){
                //     this.errors.slaconfig.maincategory="Main category required";
                //     isValid= false;
                // }
  
                // if(this.slaconfig.subcategory == null || this.slaconfig.subcategory == '' || this.slaconfig.subcategory.length == 0){
                //     this.errors.slaconfig.subcategory="Sub category required";
                //     isValid= false;
                // }

                if(this.slaconfig.project == null || this.slaconfig.project == '' || this.slaconfig.project.length == 0){
                    this.errors.slaconfig.project="Project required";
                    isValid= false;
                }
  
                if(this.slaconfig.issueclassification == null || this.slaconfig.issueclassification == '' || this.slaconfig.issueclassification.length == 0){
                    this.errors.slaconfig.issueclassification="Issue classification required";
                    isValid= false;
                }
  
                if(this.slaconfig.responsetime_hour == null || this.slaconfig.responsetime_hour == '' || this.slaconfig.responsetime_hour.length == 0){
                    this.errors.slaconfig.responsetime_hour="Respone Time Hour requried ";
                    isValid= false;
                }
  
                if(this.slaconfig.responsetime_min == null || this.slaconfig.responsetime_min == '' || this.slaconfig.responsetime_min.length == 0){
                    this.errors.slaconfig.responsetime_min="Respone Time Minute requried ";
                    isValid= false;
                }
  
                if(this.slaconfig.resolutiontime_hour == null || this.slaconfig.resolutiontime_hour == '' || this.slaconfig.resolutiontime_hour.length == 0){
                    this.errors.slaconfig.resolutiontime_hour="Resolution Time Hour requried ";
                    isValid= false;
                }
  
                if(this.slaconfig.resolutiontime_min == null || this.slaconfig.resolutiontime_min == '' || this.slaconfig.resolutiontime_min.length == 0){
                    this.errors.slaconfig.resolutiontime_min="Resolution Time Minute requried ";
                    isValid= false;
                }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                slaconfig:{
                  maincategory:null,
                  subcategory:null,
                  issueclassification:null,
                  responsetime:null,
                  resolutiontime:null
                },
            };
        },
        openEditModule(currRowData){

            
           this.iseditstate = true
          this.editRowData = currRowData
          this.slaconfig.maincategory =currRowData.projectmoduleid
          this.slaconfig.subcategory = currRowData.applicationmoduleid ?  currRowData.applicationmoduleid.split(',') : null
           let chkproject = currRowData.projectid.split(',') 
           if(currRowData.projectmoduleid && currRowData.applicationmoduleid){
            // this.getProjectModuleList(chkproject[0])
             this.handleCategoryChange(chkproject)
             this.handleInputChange(currRowData.projectmoduleid)
            }
           this.slaconfig.project = chkproject
 
          if(chkproject.length > 0){ 
            this.getallprojectlist1(null,chkproject)
            this.getProjectModuleList(chkproject[0],currRowData.projectmoduleid)
            }
            if(this.projectmodulelist && this.projectmodulelist.length > 0 && (this.slaconfig.maincategory && this.slaconfig.maincategory !=null)){
            const checkexistproject = this.projectmodulelist.some(item =>item.id === this.slaconfig.maincategory);
            if(checkexistproject){
            this.getprojectmodulesbyid(this.slaconfig.maincategory);
            }
            }
            else if((!this.projectmodulelist || this.projectmodulelist.length === 0) &&
            (this.slaconfig.maincategory && this.slaconfig.maincategory != null)){
                this.getprojectmodulesbyid(this.slaconfig.maincategory);
            }
            if(this.applicationlist && this.applicationlist.length > 0 && (this.slaconfig.subcategory && this.slaconfig.subcategory !=null)){
            const checkexistproject = this.applicationlist.some(item =>item.id === this.slaconfig.subcategory);
            // if(!checkexistproject){
            //     this.getAppModulesbyappmoduleid(this.slaconfig.subcategory);
            // }
            }
            // else if(this.applicationlist.length ==0 && (this.slaconfig.subcategory && this.slaconfig.subcategory !=null)){
            //     this.getAppModulesbyappmoduleid(this.slaconfig.subcategory);
            // }   
            if(this.projectlist1 && this.projectlist1.length > 0 && (this.slaconfig.project && this.slaconfig.project !=null)){
                const checkexist = this.projectlist1.some(item =>item.id === this.slaconfig.project);
                // if(!checkexist){
                //     this.getProjectnamebyid(this.slaconfig.project);
                // }
            }
            // else if(this.projectlist1.length ==0 && (this.slaconfig.project && this.slaconfig.project !=null)){
            //     this.getProjectnamebyid(this.slaconfig.project);
            // }

       
          this.slaconfig.issueclassification = currRowData.issueclassification
       //   this.slaconfig.responsetime = currRowData.responsetime
         // this.slaconfig.resolutiontime = currRowData.resolutiontime
         let resphour= currRowData.responsetime.hours ?  currRowData.responsetime.hours.toString().split('') : '00'.toString().split('')
         let respmin = currRowData.responsetime.minutes ? currRowData.responsetime.minutes.toString().split('') : '00'.toString().split('')
         let reshour = currRowData.resolutiontime.hours ? currRowData.resolutiontime.hours.toString().split('') : '00'.toString().split('')
         let resmin = currRowData.resolutiontime.minutes ? currRowData.resolutiontime.minutes.toString().split('') : '00'.toString().split('')
        //  this.slaconfig.findissueclassification = currRowData.issueclassification
         // this.slaconfig.findprojectmoduleid = currRowData.projectmoduleid
            this.slaconfig.previousids = currRowData.slaid
            if(parseInt(currRowData.responsetime.hours) >  24){
                               
              let get_rsp_h = parseInt(currRowData.responsetime.hours)/24
               get_rsp_h = parseInt(get_rsp_h)

             let gen_response_hour = parseInt(currRowData.responsetime.hours) % 24

             if((gen_response_hour.toString()).length < 2){
                gen_response_hour = '0'+gen_response_hour
             }
             if((get_rsp_h.toString()).length < 2){
                get_rsp_h = '0'+get_rsp_h
             }

             this.slaconfig.responsedays = parseInt(get_rsp_h) > 31 ? '31' : get_rsp_h
            this.slaconfig.responsetime_hour =  parseInt(get_rsp_h) > 31 ? '24' : gen_response_hour

            }else{
             this.slaconfig.responsetime_hour = currRowData.responsetime.hours ?  resphour.length < 2 ?   '0'+currRowData.responsetime.hours :  currRowData.responsetime.hours : '00'
             this.slaconfig.responsedays = '00'
            }    
            this.slaconfig.responsetime_min = currRowData.responsetime.minutes ?  respmin.length < 2 ? '0'+currRowData.responsetime.minutes: currRowData.responsetime.minutes : '00'


            if(parseInt(currRowData.resolutiontime.hours) >  24){

                let get_res_h = parseInt(currRowData.resolutiontime.hours)/24
                get_res_h = parseInt(get_res_h)

             let gen_response_hour = parseInt(currRowData.resolutiontime.hours) % 24

             if((gen_response_hour.toString()).length < 2){
                gen_response_hour = '0'+gen_response_hour
             }
             if((get_res_h.toString()).length < 2){
                get_res_h = '0'+get_res_h
             }

            this.slaconfig.resolutiondays = parseInt(get_res_h) > 31 ? '31' : get_res_h
            this.slaconfig.resolutiontime_hour =parseInt(get_res_h) > 31 ? '24' : gen_response_hour

            }else{
             this.slaconfig.resolutiontime_hour = currRowData.resolutiontime.hours ?  reshour.length < 2 ? '0'+currRowData.resolutiontime.hours: currRowData.resolutiontime.hours : '00'
             this.slaconfig.resolutiondays = '00'
            }
            this.slaconfig.resolutiontime_min = currRowData.resolutiontime.minutes ?  resmin.length < 2 ? '0'+currRowData.resolutiontime.minutes : currRowData.resolutiontime.minutes : '00'

        },
        dateFormat(value) {   
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
          dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
        selectcompany(state, value) {
            this.search.searchProject =null;
            this.search.searchCompany = state;
            if (!state) {
                 this.search.projectlistopts = null;
             } else {
                 this.getProjectbycompanyid();
                 }
             },
        selprojmdlproj(state,value){
            this.projectmodule.project=null
            this.projectmodule.project=state
            if(!state && this.isAddState == false){
                this.errors.projectmodule.project='Project required'
            }else{                
                this.errors.projectmodule.project=null
            }
        },
        onSelect(){
            this.file = this.$refs.file.files[0];
        },
        selProject(state,value){
          
            this.getApplicationList(state.id);
  
        },
        seldayss(state,value){
          if(state != null ){
            this.slaconfig.resolutiontime_hour = '00';
            this.slaconfig.resolutiontime_min  = '00';

          }

      },
        seldays(state,value){
          if(state != null ){

            this.slaconfig.responsetime_hour = '00';
            this.slaconfig.responsetime_min  = '00';

          }

      },

        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
            limit:null,
            offset:null,
            useremail:this.undt.username,
            empcode: this.undt.userid
            }; 
            this.input.maincategoryid = this.search.maincategory ? this.search.maincategory : null
            this.input.subcategoryid = this.search.subcategory ? this.search.subcategory : null
            this.input.issueclassification = this.search.issueclassification ? this.search.issueclassification : null
            this.input.project = this.search.project ? this.search.project : null

            axios({
                method: "POST",
                url: `api/slaconfigure/getslaconfigList`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.islistLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3){
                  Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                      window.location.href = "/#/login";
                  })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {

this.displayError(e)

})
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'SLAConfiguration_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#,Project,Main Category,Sub Category,Issue Classification,SLA / TAT' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname == 'NA' ? '' : index.projectname: '';

            let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename ==null || index.projectmodulename == 'NA' ? '' : index.projectmodulename: '';
            let applicationmodulename = index.hasOwnProperty('applicationmodulename') ? index.applicationmodulename == null || index.applicationmodulename == 'NA' ? '' : index.applicationmodulename : '';
            let issueclassification = index.hasOwnProperty('issueclassification') ? index.issueclassification == null || index.issueclassification == 'NA' ? '' : index.issueclassification.toUpperCase() : '';

            let sla_response_days= ''
            let sla_response_hours = ''
            let sla_resolution_days= ''
            let sla_resolution_hours = ''
                if(parseInt(index.responsetime.hours)>24){
                sla_response_days= parseInt(parseInt(index.responsetime.hours)/24) > 31 ? '31 days ' : parseInt(parseInt(index.responsetime.hours)/24) + ' days '
                if((parseInt(index.responsetime.hours) % 24) > 0){
                sla_response_hours= parseInt(parseInt(index.responsetime.hours)/24)> 31 ? '24 hours ' : parseInt(parseInt(index.responsetime.hours) % 24) +' hours '
                }
                if(parseInt(parseInt(index.responsetime.hours)/24)> 31){
                    sla_response_hours  = '24 hours'
                }
                }else{
                sla_response_hours=  index.responsetime.hours ? index.responsetime.hours +' hours ' : '' 
                }

                if(parseInt(index.resolutiontime.hours)>24){
                sla_resolution_days= parseInt(parseInt(index.resolutiontime.hours)/24) > 31 ? '31 days ' : parseInt(parseInt(index.resolutiontime.hours)/24) + ' days '
                if((parseInt(index.resolutiontime.hours) % 24) > 0){
                sla_resolution_hours= parseInt(parseInt(index.resolutiontime.hours)/24)> 31 ? '24 hours ' : parseInt(parseInt(index.resolutiontime.hours) % 24)+' hours '
                }
                if(parseInt(parseInt(index.resolutiontime.hours)/24)> 31){
                    sla_resolution_hours  = '24 hours'
                }
               
                }else{
                sla_resolution_hours=  index.resolutiontime.hours ? index.resolutiontime.hours +' hours ' : '' 
                }
 
            let sla_response_min = index.responsetime.minutes ? index.responsetime.minutes+' minutes' : ''
            let sla_resolution_min = index.resolutiontime.minutes ? index.resolutiontime.minutes+' minutes' : ''

             let sla_tat = 'Response: '+ sla_response_days +sla_response_hours+sla_response_min +' '+'Resolution: '+sla_resolution_days +sla_resolution_hours+sla_resolution_min
                 row += '"' + srno + '",',
                 row += '"' + projectname + '",',
                row += '"' + projectmodulename + '",',
                row += '"' + applicationmodulename + '",',
                row += '"' + issueclassification + '",',
                row += '"' + sla_tat + '",',
                  row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            return;
            }
            var fileName = 'SLAConfiguration'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
            Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });    
        }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    handleCategoryChange(state){
        if(state!=null && state.length==1){        
        this.getProjectModuleList(state[0])
        this.getModuleList(state[0])
        }else{
        this.applicationlist = null
        this.slaconfig.subcategory= null
        this.search.subcategory = null
        this.slaconfig.maincategory = null
        this.projectmodulelist = null
        // this.modulelist1 = null
        }
        },
    },
    filters: {
        basename: function (value) {
            if (!value) return ''
            //value = value.toString()
            return value.substr(value.lastIndexOf("/") + 1)
        },
    },
      
  }
  </script>
  