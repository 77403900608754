<template>
    <img alt="" :src="presignurldata" style="width:24px;height:24px" class="round"  />
</template>
<script>
import axios from 'axios'
import apiUrl from "../constants";

import { DownloadIcon} from 'vue-feather-icons'
import commonMethods from '../utils/commonMethods';

export default {
    name: 'profilepic',
    mixins: [ commonMethods ],
    props: ['getUrl'],
    components:{
        DownloadIcon
    },
    data() {
        return {
          presignurldata: apiUrl.defaultimagepath,
        }
    },
    beforeMount() {
        this.getLogtimeDownloadLink()
    },
    methods:{
        getLogtimeDownloadLink() {
            this.tokendata = window.localStorage.getItem('token');
            let userdata = window.localStorage.getItem('userdata');
            userdata = JSON.parse(userdata)
            this.undt = userdata
            this.email = userdata.username
            this.empid = userdata.userid

            let payload = {
                link:this.getUrl,
                empcode: this.undt.userid,
                useremail: this.undt.username
            };
            axios({
                'method': 'POST',
                'url': "/api/imageupload/getProfilePicDownloadLink",
                'data':payload,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                if(result.data.status) {
                    if(result.data.data.length>0) {
                        this.presignurldata =result.data.data
                        // console.log( this.presignurldata,'============')
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
            // axios.post("/api/imageupload/getProfilePicDownloadLink", payload,)
            // .then((result) => {
            // if(result.data.status) {
            //     if(result.data.data.length>0) {
            //         this.presignurldata =result.data.data
            //     }
            // }
            // });
        }
    }
}
</script>