<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="filter_card_searchbgst">
                    <section id="profile-info">
                        <div class="row">
                            <div class="col-lg-9 col-12">
                                <div class="articles_search_filder_maindiv">
                                    
                                <div class="nav-search  search_filtter_animation sarch_afterchange_design12 d-flex mb-1">
                                    <div class="avatar mr-1 profile_search_icon">
                                        <profilepic v-if="loginpersonimage && loginpersonid" v-bind:get-url="'empprofile/'+loginpersonid+'/'+loginpersonimage" :key="`${loginpersonid}-${loginpersonimage}`" height="50" width="50"></profilepic>
                                        <img alt="" v-if="!loginpersonimage" :src="defaultimagepath" width="40" height="40" class="">
                                    </div>
                                    <div class="search searchbox_view">
                                        <input type="text" class="search-box" placeholder="Enter Min 4 Chars To Search" v-model="searcharticle" style="width:100%;" @input="searchdata">
                                        <search-icon size="1.5x" class="custom-class"></search-icon>
                                    </div>
                                    <div class="searchbox_post_btn ml-1">
                                       <button type="button" class="btn btn-primary " @click="redirecttocreatepage('createarticle')"> Post </button>
                                    </div>
                                </div>
                                </div>

                                <template v-for="(doc, idx) in this.alldocumentlist" v-bind="idx">
                                    <div class="card main_articlas_views mb-2" :id="'article'+doc.courseid" v-if="doc.docnamelist && doc.docnamelist.length == 1">
                                        <div class="card-body">
                                            <div class="innerarticle_view15">
                                            <div class="d-flex align-items-center justify-content-between mb-1">
                                                <div class="d-flex align-items-center person_details_left"> 
                                                    <div class="avatar mr-1">
                                                        <profilepic v-if="doc.imagename && doc.empcode" v-bind:get-url="'empprofile/'+doc.empcode+'/'+doc.imagename" :key="`${idx}`" height="50" width="50"></profilepic>
                                                        <img alt="" v-if="!doc.imagename" :src="defaultimagepath" width="50" height="50" />
                                                    </div>
                                                    <div class="profile-user-info person_designation_main">
                                                        <h6 class="mb-0 capitalisetext">{{ doc.createdbyname }}</h6>
                                                        <div class="person_designation">
                                                            <span class="person_designation_title capitalisetext">  {{ doc.designation ? decryptText(doc.designation) : 'NA' }}  :</span>
                                                            <span class="capitalisetext"> {{ doc.department ? '['+decryptText(doc.department)+']' : 'NA' }} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pull-right min_ago_text post_tags_mainview">
                                                    <div class="timeago_text text-right"> {{ doc.currenthrs}} ago </div>
                                                    <div class="pull-right capitalisetext post_tag_text">
                                                       {{ doc.category}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="postimageviews post_tags_mainview_borderlist">
                                                <div class="postimageviews postvideo" v-if="(doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'mp4') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='mp3') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='webm') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='vlc')">
                                                    
                                                    <articlevideo class="img-fluid rounded mb-75" v-bind:get-url="doc.docnamelist[0]" :key="`${idx}`"></articlevideo>
                                                </div>
                                                <div class="common_post_tags_mainview_borderlist">
                                                <div class="card  mb-50">
                                                    <img alt="" v-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png"/>
                                                    <img alt="" v-else-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png"/>
                                                    <img alt="" v-else-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'xls'" src="../../../public/images/icons/ic-xls.png"/>
                                                    <img alt="" v-else-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) ==  'xlsx'" src="../../../public/images/icons/ic-xls.png"/>
                                                    <a v-on:click="getLogtimeDownloadLink(doc.docnamelist[0])" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn" v-if="(doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'pdf') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='csv') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='xls') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='xlsx')"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                </div>
                                                </div>
                                                <span v-if="(doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'jpeg') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='jpg') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='png')" @click="showimagepreview(doc.docnamelist[0])">
                                                    <articlepic class="img-fluid rounded mb-75" v-bind:get-url="doc.docnamelist[0]" :key="`${idx}`"></articlepic>
                                                </span>
                                            </div>
                                            <div class="postimageviews_text">
                                                <div class="d-flex ">
                                                    <div class="mr-auto"> 
                                                        <strong class="text-left">Article Name:</strong> {{ doc.coursename }}
                                                    </div>
                                                   
                                                </div>
                                                <div class="text-center tast_case_groupingview_readmore mx-0">
                                                    <div class="col-md-12 px-0 mt-1 tast_case_groupingview_readmore">
                                                        <div class="card-text mb-0">
                                                            <div v-if="doc.description" class="outer" :id="'cycle_readmore1'+idx">
                                                                <strong>Description: </strong><span v-html="doc.description"></span>
                                                            </div>
                                                            <div v-else class="outer" :id="'cycle_readmore1'+idx">
                                                                <strong>Description: </strong><span class="fc_m16_btcode_text">NA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cycle_viewmoretext" v-if="doc.description && doc.description.length > 150">
                                                    <a class="btn py-1">
                                                        <strong :id="'id1'+idx" @click="idclick1(idx)">
                                                            Read More
                                                        </strong>
                                                    </a>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="row mx-0 d-flex justify-content-start align-items-center flex-wrap pb-0">
                                                <div class=" w-100 justify-content-sm-start mb-0">
                                                <div class="social_iconsview w-100 d-flex justify-content-between px-1"> 
                                                    <div class="social_iconsvieone">
                                                        <!-- <div class="social_iconsviewdiv ">
                                                        <span class="social_iconsviewlike"> <em class="las la-eye"></em></span>
                                                        <span class="scocial_text_like"> 9k Views </span>
                                                        </div> -->
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> 
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-liked': doc.isliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isliked && doc.isliked == undt.userid"></em>
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isliked ? true : false}" @click="savearticlelike(1,doc.courseid)" v-else></em> 
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.islike }} Likes</span>
                                                            <span class="social_iconsviewlike ml-1">
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-liked': doc.isdisliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isdisliked == undt.userid"></em>
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isdisliked ? true : false}" @click="savearticlelike(0,doc.courseid)" v-else></em>
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.dislike }} Dislikes</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="social_iconsvietwo">
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> <em class="lab la-rocketchat"></em> </span>
                                                            <span class="scocial_text_like"> 12 comments</span>
                                                        </div>
                                                    </div> -->
                                                </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card main_articlas_views mb-2" :id="'article'+doc.courseid" v-if="doc.docnamelist && doc.docnamelist.length == 2">
                                        <div class="card-body">
                                            <div class="innerarticle_view15">
                                            <div class="d-flex align-items-center justify-content-between mb-1">
                                                
                                                <div class="d-flex align-items-center person_details_left"> 
                                                <div class="avatar mr-1">
                                                    <profilepic v-if="doc.imagename && doc.empcode" v-bind:get-url="'empprofile/'+doc.empcode+'/'+doc.imagename" :key="`${idx}`" height="50" width="50"></profilepic>
                                                    <img alt="" v-if="!doc.imagename" :src="defaultimagepath" width="50" height="50" />
                                                </div>
                                                <div class="profile-user-info person_designation_main">
                                                    <h6 class="mb-0 capitalisetext">{{ doc.createdbyname }}</h6>
                                                    <div class="person_designation">
                                                        <span class="person_designation_title capitalisetext">  {{ doc.designation ? decryptText(doc.designation) : 'NA' }}  :</span>
                                                        <span class="capitalisetext"> {{ doc.department ? '['+decryptText(doc.department)+']' : 'NA' }} </span>
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="pull-right min_ago_text post_tags_mainview">
                                                   <div class="timeago_text text-right"> {{ doc.currenthrs}} ago </div>
                                                   <div class="pull-right capitalisetext post_tag_text">
                                                       {{ doc.category}}
                                                   </div>
                                                </div>
                                               
                                            </div>
                                            <div class="row mx-0 postimageviews image-containermultiples">
                                                <div class="col-sm-6 articals_viewmoreimg" v-for="(doc1, idx1) in doc.docnamelist" v-bind:key="idx1">
                                                    <div class="postimageviews postvideo" v-if="(doc1.substring(doc1.lastIndexOf('.')+1) == 'mp4' || doc1.substring(doc1.lastIndexOf('.')+1) =='mp3' || doc1.substring(doc1.lastIndexOf('.')+1) =='webm' || doc1.substring(doc1.lastIndexOf('.')+1) =='vlc')">
                                                        <articlevideo class="img-fluid rounded mb-75" :id="'articlevideo'+idx1+doc.courseid" v-bind:get-url="doc1" :key="`${idx1}`"></articlevideo>
                                                    </div>
                                                    <div class="card  mb-50">
                                                        <img alt="" v-if="doc1.substring(doc1.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png"/>
                                                        <img alt="" v-else-if="doc1.substring(doc1.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png"/>
                                                        <img alt="" v-else-if="doc1.substring(doc1.lastIndexOf('.')+1) == 'xls'" src="../../../public/images/icons/ic-xls.png"/>
                                                        <img alt="" v-else-if="doc1.substring(doc1.lastIndexOf('.')+1) == 'xlsx'" src="../../../public/images/icons/ic-xls.png"/>
                                                        <a v-on:click="getLogtimeDownloadLink(doc1)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn" v-if="(doc1.substring(doc1.lastIndexOf('.')+1) == 'pdf') || (doc1.substring(doc1.lastIndexOf('.')+1) =='csv') || (doc1.substring(doc1.lastIndexOf('.')+1) =='xls') || (doc1.substring(doc1.lastIndexOf('.')+1) =='xlsx')"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                    </div>
                                                    <span v-if="(doc1.substring(doc1.lastIndexOf('.')+1) == 'jpeg') || (doc1.substring(doc1.lastIndexOf('.')+1) == 'jpg') || (doc1.substring(doc1.lastIndexOf('.')+1) =='png')" @click="showimagepreview(doc1)">
                                                        <articlepic class="img-fluid rounded mb-75" v-bind:get-url="doc1" :key="`${idx1}`"></articlepic>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="postimageviews_text">
                                                <div class="d-flex ">
                                                    <div class="mr-auto"> 
                                                        <strong class="text-left">Article Name:</strong> {{ doc.coursename }}
                                                    </div>
                                                    
                                                </div>
                                                <div class="text-center tast_case_groupingview_readmore mx-0">
                                                    <div class="col-md-12 px-0 mt-1 tast_case_groupingview_readmore">
                                                        <div class="card-text mb-0">
                                                            <div v-if="doc.description" class="outer" :id="'cycle_readmore2'+idx">
                                                                <strong>Description: </strong><span v-html="doc.description"></span>
                                                            </div>
                                                            <div v-else class="outer" :id="'cycle_readmore2'+idx">
                                                                <strong>Description: </strong><span class="fc_m16_btcode_text">NA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cycle_viewmoretext" v-if="doc.description && doc.description.length > 150">
                                                    <a class="btn py-1">
                                                        <strong :id="'id2'+idx" @click="idclick2(idx)">
                                                            Read More
                                                        </strong>
                                                    </a>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="row mx-0 d-flex justify-content-start align-items-center flex-wrap pb-0">
                                                <div class=" w-100 justify-content-sm-start mb-0">
                                                <div class="social_iconsview w-100 d-flex justify-content-between px-1"> 
                                                    <div class="social_iconsvieone">
                                                        <!-- <div class="social_iconsviewdiv ">
                                                        <span class="social_iconsviewlike"> <em class="las la-eye"></em></span>
                                                        <span class="scocial_text_like"> 9k Views </span>
                                                        </div> -->
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> 
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-liked': doc.isliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isliked && doc.isliked == undt.userid"></em>
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isliked ? true : false}" @click="savearticlelike(1,doc.courseid)" v-else></em> 
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.islike }} Likes</span>
                                                            <span class="social_iconsviewlike ml-1">
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-liked': doc.isdisliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isdisliked == undt.userid"></em>
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isdisliked ? true : false}" @click="savearticlelike(0,doc.courseid)" v-else></em>
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.dislike }} Dislikes</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="social_iconsvietwo">
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> <em class="lab la-rocketchat"></em> </span>
                                                            <span class="scocial_text_like"> 12 comments</span>
                                                        </div>
                                                    </div> -->
                                                </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card main_articlas_views mb-2" :id="'article'+doc.courseid" v-if="doc.docnamelist && doc.docnamelist.length == 3">
                                        <div class="card-body">
                                            <div class="innerarticle_view15">
                                            <div class="d-flex align-items-center justify-content-between mb-1">
                                                
                                                <div class="d-flex align-items-center person_details_left">
                                                <div class="avatar mr-1">
                                                    <profilepic v-if="doc.imagename && doc.empcode" v-bind:get-url="'empprofile/'+doc.empcode+'/'+doc.imagename" :key="`${idx}`" height="50" width="50"></profilepic>
                                                    <img alt="" v-if="!doc.imagename" :src="defaultimagepath" width="50" height="50" />
                                                </div>
                                                <div class="profile-user-info person_designation_main">
                                                    <h6 class="mb-0">{{ doc.createdbyname }}</h6>
                                                    <div class="person_designation">
                                                        <span class="person_designation_title capitalisetext">  {{ doc.designation ? decryptText(doc.designation) : 'NA' }}  :</span>
                                                        <span class="capitalisetext"> {{ doc.department ? '['+decryptText(doc.department)+']' : 'NA' }} </span>
                                                    </div>
                                                </div>
                                                </div>
                                                <div class="pull-right min_ago_text post_tags_mainview">
                                                    <div class="timeago_text text-right"> {{ doc.currenthrs}} ago </div>
                                                        <div class="pull-right capitalisetext post_tag_text">
                                                            {{ doc.category}}
                                                        </div>
                                                </div>
                                            </div>
                                            <div class="row mx-0 postimageviews image-containermultiples">
                                                <div class="col-sm-6 articals_viewmoreimg extraclass">
                                                    <div class="postimageviews postvideo" v-if="(doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'mp4') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='mp3') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='webm') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='vlc')">
                                                        <articlevideo class="img-fluid rounded mb-75" v-bind:get-url="doc.docnamelist[0]" :key="`${0}`"></articlevideo>
                                                    </div>
                                                    <div class="card card-bordered mb-50">
                                                        <img alt="" v-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png"/>
                                                        <img alt="" v-else-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png"/>
                                                        <img alt="" v-else-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'xls'" src="../../../public/images/icons/ic-xls.png"/>
                                                        <img alt="" v-else-if="doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'xlsx'" src="../../../public/images/icons/ic-xls.png"/>
                                                        <a v-on:click="getLogtimeDownloadLink(doc.docnamelist[0])" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn" v-if="(doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) == 'pdf') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='csv') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='xls') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1) =='xlsx')"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                    </div>
                                                    <span v-if="(doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1)== 'jpeg') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1)== 'jpg') || (doc.docnamelist[0].substring(doc.docnamelist[0].lastIndexOf('.')+1)=='png')" @click="showimagepreview(doc.docnamelist[0])">
                                                        <articlepic class="img-fluid rounded mb-75" v-bind:get-url="doc.docnamelist[0]" v-bind:key="`${0}`"></articlepic>
                                                    </span>
                                                </div>
                                                <div class="col-sm-6 articals_viewmoreimg pt-0">
                                                    <div class="row mx-0" v-for="(doc3, idx3) in doc.docnamelist" v-bind:key="idx3">
                                                        <div class="col-sm-12 articals_viewmoreimg" v-if="idx3 > 0">
                                                            <div class="postimageviews postvideo" v-if="(doc3.substring(doc3.lastIndexOf('.')+1) == 'mp4') || (doc3.substring(doc3.lastIndexOf('.')+1) =='mp3') || (doc3.substring(doc3.lastIndexOf('.')+1) =='webm') || (doc3.substring(doc3.lastIndexOf('.')+1) =='vlc')">
                                                                <articlevideo class="img-fluid rounded mb-75" v-bind:get-url="doc3" :key="`${idx3}`"></articlevideo>
                                                            </div>
                                                            <div class="card card-bordered mb-50">
                                                                <img alt="" v-if="doc3.substring(doc3.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png"/>
                                                                <img alt="" v-else-if="doc3.substring(doc3.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png"/>
                                                                <img alt="" v-else-if="doc3.substring(doc3.lastIndexOf('.')+1) == 'xls'" src="../../../public/images/icons/ic-xls.png"/>
                                                                <img alt="" v-else-if="doc3.substring(doc3.lastIndexOf('.')+1) == 'xlsx'" src="../../../public/images/icons/ic-xls.png"/>
                                                                <a v-on:click="getLogtimeDownloadLink(doc3)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn" v-if="(doc3.substring(doc3.lastIndexOf('.')+1) == 'pdf') || (doc3.substring(doc3.lastIndexOf('.')+1) =='csv') || (doc3.substring(doc3.lastIndexOf('.')+1) =='xls') || (doc3.substring(doc3.lastIndexOf('.')+1) =='xlsx')"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                            </div>
                                                            <span v-if="(doc3.substring(doc3.lastIndexOf('.')+1) == 'jpeg') || (doc3.substring(doc3.lastIndexOf('.')+1)=='jpg') || (doc3.substring(doc3.lastIndexOf('.')+1) =='png')" @click="showimagepreview(doc3)">
                                                                <articlepic class="img-fluid rounded mb-75" v-bind:get-url="doc3" :key="`${idx3}`"></articlepic>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="postimageviews_text">
                                                <div class="d-flex ">
                                                    <div class="mr-auto"> 
                                                        <strong class="text-left">Article Name:</strong> {{ doc.coursename }}
                                                    </div>
                                                    
                                                </div>
                                                <div class="text-center tast_case_groupingview_readmore mx-0">
                                                    <div class="col-md-12 px-0 mt-1 tast_case_groupingview_readmore">
                                                        <div class="card-text mb-0">
                                                            <div v-if="doc.description" class="outer" :id="'cycle_readmore3'+idx">
                                                              <strong>Description: </strong><span v-html="doc.description"></span>
                                                            </div>
                                                            <div v-else class="outer" :id="'cycle_readmore3'+idx">
                                                              <strong>Description: </strong><span class="fc_m16_btcode_text">NA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cycle_viewmoretext" v-if="doc.description && doc.description.length > 150">
                                                    <a class="btn py-1">
                                                        <strong :id="'id3'+idx" @click="idclick3(idx)">
                                                            Read More
                                                        </strong>
                                                    </a>
                                                </div>
                                            </div>
                                          </div>
                                            <div class="row mx-0 d-flex justify-content-start align-items-center flex-wrap pb-0">
                                                <div class=" w-100 justify-content-sm-start mb-0">
                                                <div class="social_iconsview w-100 d-flex justify-content-between px-1"> 
                                                    <div class="social_iconsvieone">
                                                        <!-- <div class="social_iconsviewdiv ">
                                                        <span class="social_iconsviewlike"> <em class="las la-eye"></em></span>
                                                        <span class="scocial_text_like"> 9k Views </span>
                                                        </div> -->
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> 
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-liked': doc.isliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isliked && doc.isliked == undt.userid"></em>
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isliked ? true : false}" @click="savearticlelike(1,doc.courseid)" v-else></em> 
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.islike }} Likes</span>
                                                            <span class="social_iconsviewlike ml-1">
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-liked': doc.isdisliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isdisliked == undt.userid"></em>
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isdisliked ? true : false}" @click="savearticlelike(0,doc.courseid)" v-else></em>
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.dislike }} Dislikes</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="social_iconsvietwo">
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> <em class="lab la-rocketchat"></em> </span>
                                                            <span class="scocial_text_like"> 12 comments</span>
                                                        </div>
                                                    </div> -->
                                                </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card main_articlas_views mb-2" :id="'article'+doc.courseid" v-if="doc.docnamelist && doc.docnamelist.length >= 4">
                                        <div class="card-body">
                                            <div class="innerarticle_view15">
                                            <div class="d-flex align-items-center justify-content-between mb-1">
                                                <div class="d-flex align-items-center person_details_left"> 
                                                    <div class="avatar mr-1">
                                                        <profilepic v-if="doc.imagename && doc.empcode" v-bind:get-url="'empprofile/'+doc.empcode+'/'+doc.imagename" :key="`${idx}`" height="50" width="50"></profilepic>
                                                        <img alt="" v-if="!doc.imagename" :src="defaultimagepath" width="50" height="50" />
                                                    </div>
                                                    <div class="profile-user-info person_designation_main">
                                                        <h6 class="mb-0">{{ doc.createdbyname }}</h6>
                                                        <div class="person_designation">
                                                            <span class="person_designation_title capitalisetext">  {{ doc.designation ? decryptText(doc.designation) : 'NA' }}  :</span>
                                                            <span class="capitalisetext"> {{ doc.department ? '['+decryptText(doc.department)+']' : 'NA' }} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pull-right min_ago_text post_tags_mainview">
                                                    <div class="timeago_text text-right">{{ doc.currenthrs}} ago </div>
                                                    <div class="pull-right capitalisetext post_tag_text">
                                                        {{ doc.category}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mx-0 postimageviews image-containermultiples">
                                                <div class="col-sm-6 articals_viewmoreimg " v-for="(doc4, idx4) in doc.docnamelist" :key="idx4">
                                                    <span  class="card-bordered d-block rounded articals_viewmoreimg_padd_8" v-if="idx4 < 4 && doc.isshowmore ==0">
                                                        <div class="postimageviews postvideo" v-if="(doc4.substring(doc4.lastIndexOf('.')+1) == 'mp4') || (doc4.substring(doc4.lastIndexOf('.')+1) =='mp3') || (doc4.substring(doc4.lastIndexOf('.')+1) =='webm') || (doc4.substring(doc4.lastIndexOf('.')+1) =='vlc')">
                                                            <articlevideo class="img-fluid rounded mb-75" v-bind:get-url="doc4" :key="`${idx4}`"></articlevideo>
                                                        </div>
                                                        <div class="card m-0">
                                                            <img alt="" v-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png"/>
                                                            <img alt="" v-else-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png"/>
                                                            <img alt="" v-else-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'xls'" src="../../../public/images/icons/ic-xls.png"/>
                                                            <img alt="" v-else-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'xlsx'" src="../../../public/images/icons/ic-xls.png"/>
                                                            <a v-on:click="getLogtimeDownloadLink(doc4)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn" v-if="(doc4.substring(doc4.lastIndexOf('.')+1) == 'pdf') || (doc4.substring(doc4.lastIndexOf('.')+1) =='csv') || (doc4.substring(doc4.lastIndexOf('.')+1) =='xls') || (doc4.substring(doc4.lastIndexOf('.')+1) =='xlsx')"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                        </div>
                                                        <span v-if="(doc4.substring(doc4.lastIndexOf('.')+1) == 'jpeg') || (doc4.substring(doc4.lastIndexOf('.')+1) == 'jpg') || (doc4.substring(doc4.lastIndexOf('.')+1) == 'png')" @click="showimagepreview(doc4)">
                                                            <articlepic class="img-fluid rounded mb-75" v-bind:get-url="doc4"></articlepic>
                                                        </span>
                                                    </span>
                                                    <span v-if="doc.isshowmore ==1">
                                                        <div class="postimageviews postvideo" v-if="(doc4.substring(doc4.lastIndexOf('.')+1) == 'mp4') || (doc4.substring(doc4.lastIndexOf('.')+1) =='mp3') || (doc4.substring(doc4.lastIndexOf('.')+1) =='webm') || (doc4.substring(doc4.lastIndexOf('.')+1) =='vlc')">
                                                            <articlevideo class="img-fluid rounded mb-75" v-bind:get-url="doc4" :key="`${idx4}`"></articlevideo>
                                                        </div>
                                                        <div class="card  mb-50">
                                                            <img alt="" v-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png"/>
                                                            <img alt="" v-else-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png"/>
                                                            <img alt="" v-else-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'xls'" src="../../../public/images/icons/ic-xls.png"/>
                                                            <img alt="" v-else-if="doc4.substring(doc4.lastIndexOf('.')+1) == 'xlsx'" src="../../../public/images/icons/ic-xls.png"/>
                                                            <a v-on:click="getLogtimeDownloadLink(doc4)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn" v-if="(doc4.substring(doc4.lastIndexOf('.')+1) == 'pdf') || (doc4.substring(doc4.lastIndexOf('.')+1) =='csv') || (doc4.substring(doc4.lastIndexOf('.')+1) =='xls') || (doc4.substring(doc4.lastIndexOf('.')+1) =='xlsx')"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                        </div>
                                                        <span v-if="(doc4.substring(doc4.lastIndexOf('.')+1) == 'jpeg') || (doc4.substring(doc4.lastIndexOf('.')+1) == 'jpg') || (doc4.substring(doc4.lastIndexOf('.')+1) == 'png')" @click="showimagepreview(doc4)">
                                                            <articlepic class="img-fluid rounded mb-75" v-bind:get-url="doc4" :key="`${idx4}`"></articlepic>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="text-right image-containermultiples_morebtn">
                                            <a class="text-center " @click="showMoreImages('more',doc.courseid)" v-if="doc.docnamelist.length >4 && doc.isshowmore ==0">Show More <span> <i class="las la-arrow-right"></i></span></a>
                                            <a class="text-center" @click="showMoreImages('less',doc.courseid)" v-if="doc.docnamelist.length >4 && doc.isshowmore ==1">Show Less <span> <i class="las la-arrow-right"></i> </span> </a>
                                            </div>
                                            <div class="postimageviews_text mt-1">
                                                <div class="d-flex ">
                                                    <div class="mr-auto"> 
                                                        <strong class="text-left">Article Name:</strong> {{ doc.coursename }}
                                                    </div>
                                                    
                                                </div>
                                                <div class="text-center tast_case_groupingview_readmore mx-0">
                                                    <div class="col-md-12 px-0 mt-1 tast_case_groupingview_readmore">
                                                        <div class="card-text mb-0">
                                                            <div v-if="doc.description" class="outer" :id="'cycle_readmore5'+idx">
                                                                <strong>Description: </strong><span v-html="doc.description"></span>
                                                            </div>
                                                            <div v-else class="outer" :id="'cycle_readmore5'+idx">
                                                                <strong>Description: </strong><span class="fc_m16_btcode_text">NA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cycle_viewmoretext" v-if="doc.description && doc.description.length > 150">
                                                    <a class="btn py-1">
                                                        <strong :id="'id5'+idx" @click="idclick5(idx)">
                                                            Read More
                                                        </strong>
                                                    </a>
                                                </div>
                                            </div>
                                         </div>
                                            <div class="row mx-0 d-flex justify-content-start align-items-center flex-wrap pb-0">
                                                <div class=" w-100 justify-content-sm-start mb-0">
                                                <div class="social_iconsview w-100 d-flex justify-content-between px-1"> 
                                                    <div class="social_iconsvieone">
                                                        <!-- <div class="social_iconsviewdiv ">
                                                        <span class="social_iconsviewlike"> <em class="las la-eye"></em></span>
                                                        <span class="scocial_text_like"> 9k Views </span>
                                                        </div> -->
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> 
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-liked': doc.isliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isliked && doc.isliked == undt.userid"></em>
                                                                <em class="las la-thumbs-up cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isliked ? true : false}" @click="savearticlelike(1,doc.courseid)" v-else></em> 
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.islike }} Likes</span>
                                                            <span class="social_iconsviewlike ml-1">
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-liked': doc.isdisliked  ? true : false}" @click="savearticlelike(null,doc.courseid)" v-if="doc.isdisliked == undt.userid"></em>
                                                                <em class="las la-thumbs-down cursorpointercls" :class="{ 'thumb-icon-unliked': !doc.isdisliked ? true : false}" @click="savearticlelike(0,doc.courseid)" v-else></em>
                                                            </span>
                                                            <span class="scocial_text_like"> {{doc.dislike }} Dislikes</span>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="social_iconsvietwo">
                                                        <div class="social_iconsviewdiv">
                                                            <span class="social_iconsviewlike"> <em class="lab la-rocketchat"></em> </span>
                                                            <span class="scocial_text_like"> 12 comments</span>
                                                        </div>
                                                    </div> -->
                                                </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="text-center mt-1" v-if="alldocumentlist.length==0">
                                    <tr style="width=100%; display:block;"><td colspan="14" style="text-align: center; display:block;">No Post Found</td></tr>
                                </div>
                            </div>
                            <div  class="col-lg-3 col-12 " >
                                <div class="scroll-top-button" :class="{ 'visible': isScrolling }" @click="scrollTop">
                                    <div class="card leftside_latest_posts_bg" >
                                        <div class="card-body leftside_latest_posts_bg1">
                                            <h5 class="">Latest {{createdbylist.length ? createdbylist.length : ''}} Posts By:</h5>
                                            <template v-for="(data, index) in createdbylist">
                                                <tr v-bind:key="index">
                                                    <div class="d-flex justify-content-start align-items-center mt-2">
                                                        <div class="avatar mr-75 colleagueimage">
                                                            <profilepic v-if="data.imagename && data.empcode" v-bind:get-url="'empprofile/'+data.empcode+'/'+data.imagename" :key="`${index}`"></profilepic>
                                                            <img alt="" v-if="!data.imagename" :src="defaultimagepath" width="40" height="40" />
                                                        </div>
                                                        <div class="profile-user-info">
                                                            <h6 class="mb-0 capitalisetext">{{ data.createdbyname }}</h6>
                                                            <small class="text-muted">{{ data.lastmodifieddate ? dateMonthFormat(data.lastmodifieddate) :'NA' }}</small>
                                                            <!-- <small class="text-muted">6 Mutual Friends</small> -->
                                                        </div>
                                                    </div>
                                                </tr>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-md">
                                    <div class="modal-content">              
                                        <div class="modal-body previewpopupclass p-0">
                                            <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                                            <img alt="" src="" class="imagepreview"  >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants'
import moment from "moment";
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import { Trash2Icon, CalendarIcon, DownloadIcon, XIcon, SearchIcon } from 'vue-feather-icons';
import profilepic from '../profilepic.vue';
import articlepic from '../articlepic.vue';
import articlevideo from '../articlevideo.vue'

export default {
  name: 'TrainingArticles',
  components: {
    VueElementLoading,
    Treeselect,
    Loading,
    ckeditor: CKEditor.component,
    Trash2Icon,
    CalendarIcon, DatePicker, profilepic, articlepic, articlevideo, DownloadIcon, XIcon, SearchIcon
  },
  mixins: [ commonMethods ],
  data() {
    return {
        isScrolling: false,
        videoSource: '../videos/Logo.mp4', 
        isLoading: false,
        isPlaying: false,
        undt: null,
        createdbylist: [],
        defaultimagepath:apiUrl.defaultimagepath,
        documentlist: [],
        s3bucket: apiUrl.s3url,
        pageoffset: 1,
        pagelimit: 5,
        itemsPerPage: 5,
        alldocumentlist: [],
        searcharticle:  null,
        loginpersonimage: apiUrl.defaultimagepath,
        loginpersonid: null
    }
  },
  created() {
    this.setupInfiniteScroll();
    window.addEventListener('scroll', this.handleScroll_scroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll_scroll);
  },
  mounted() {
    let userdata = window.localStorage.getItem('userdata');
    if(userdata){
        userdata = JSON.parse(userdata)
        this.undt=userdata;
        this.loginpersonid = parseInt(this.undt.userid)
        this.tokendata = window.localStorage.getItem('token');
        this.loginusername = userdata.username;
        this.getnotificationcreatedbylist();
        if(this.$route.params && this.$route.params.clickeddata && this.$route.params.clickeddata.courseid){
            this.getDocumetList(this.$route.params.clickeddata.courseid);
        }else{
            this.getDocumetList();
        }
    }
  },
  methods: {
    
    setupInfiniteScroll(){
        window.addEventListener('scroll', this.handleScroll);
    },
    async handleScroll() {
      let scrollY = window.scrollY;
      let windowHeight = window.innerHeight;
      let documentHeight = document.documentElement.scrollHeight;

      if (scrollY + windowHeight >= documentHeight - 200 && !this.isLoading) {
        if (this.alldocumentlist.length < this.itemsPerPage) {
            // this.getDocumetList(null,'up');
            return
        }else{
            this.pageoffset =this.pageoffset+1;
            this.getDocumetList(null,'down',this.searcharticle);
        }
      }
    //   if (scrollY === 0 && !this.isLoading ) {
    //     this.pageoffset = this.pageoffset-1;
    //     if(this.pageoffset == 0){
    //         this.pageoffset = 1;
    //     }
    //     this.getDocumetList(null,'up');
    //   }
    },
    handleScroll_scroll() {
      // Check if the user has scrolled down a certain amount (e.g., 100 pixels)
      if (window.scrollY > 100) {
        this.isScrolling = true;
      } else {
        this.isScrolling = false;
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
      this.isScrolling = false;
    },
    playOrPause: function() {
        const video = this.$el.querySelector('video');
        if (this.isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        this.isPlaying = !this.isPlaying;
    },
    dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    getnotificationcreatedbylist() {
        this.isLoading =true;
        let url = 'api/coursemaster/getnotificationcreatedbylist'
        axios({
            method: 'POST',
            url,
            data: { useremail: this.undt.username, empcode: this.undt.userid, coursetype: 'article' },
            headers: { authorization: this.tokendata }
        }).then(result => {
            this.isLoading =false;
            if(result.data.errorCode == 0) {
                this.createdbylist = result.data.data.rows;
            } else {
                this.createdbylist = [];
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    idclick1(idx){
        if ($("#cycle_readmore1"+idx).hasClass("readmore")) {
            $("#id1"+idx).html("Read More");
            $("#cycle_readmore1"+idx).removeClass("readmore");
        } else {
            $("#id1"+idx).html("Read Less");
            $("#cycle_readmore1"+idx).addClass("readmore");
        }
    },
    idclick2(idx){
        if ($("#cycle_readmore2"+idx).hasClass("readmore")) {
            $("#id2"+idx).html("Read More");
            $("#cycle_readmore2"+idx).removeClass("readmore");
        } else {
            $("#id2"+idx).html("Read Less");
            $("#cycle_readmore2"+idx).addClass("readmore");
        }
    },
    idclick3(idx){
        if ($("#cycle_readmore3"+idx).hasClass("readmore")) {
            $("#id3"+idx).html("Read More");
            $("#cycle_readmore3"+idx).removeClass("readmore");
        } else {
            $("#id3"+idx).html("Read Less");
            $("#cycle_readmore3"+idx).addClass("readmore");
        }
    },
    idclick4(idx){
        if ($("#cycle_readmore4"+idx).hasClass("readmore")) {
            $("#id4"+idx).html("Read More");
            $("#cycle_readmore4"+idx).removeClass("readmore");
        } else {
            $("#id4"+idx).html("Read Less");
            $("#cycle_readmore4"+idx).addClass("readmore");
        }
    },
    idclick5(idx){
        if ($("#cycle_readmore5"+idx).hasClass("readmore")) {
            $("#id5"+idx).html("Read More");
            $("#cycle_readmore5"+idx).removeClass("readmore");
        } else {
            $("#id5"+idx).html("Read Less");
            $("#cycle_readmore5"+idx).addClass("readmore");
        }
    },
    showMoreImages(state,courseid) {
        this.isLoading = true;
        if(state =='more'){
            this.alldocumentlist.map(items => {
                if(items.courseid == courseid){
                    items.isshowmore ='1'
                }
            })
        }else if(state =='less'){
            this.alldocumentlist.map(items => {
                if(items.courseid == courseid){
                    items.isshowmore ='0'
                }
            })
        }
        this.isLoading = false;
    },
    getDocumetList(courseid,isscroll,searchitemss){
        if(isscroll && isscroll !='up'){
            this.isLoading = true;
        }
        let url = "api/s3upload/getallDocumentPresignedUrl";
        this.input = { moduletype: 'training-article',useremail: this.undt.username, empcode: this.undt.userid, limit: this.pagelimit};

        if(courseid && isscroll && isscroll =='noscroll'){
            this.input.courseid = courseid;
            this.input.offset = null;
        }else{
            this.input.offset = this.pageoffset;
        }
        if(isscroll && isscroll =='issearch' && searchitemss && searchitemss !=null){
            this.alldocumentlist = [];
            this.input.allsearchitems = searchitemss.toLowerCase();
        }
        if(isscroll && isscroll =='isnosearch'){
            this.alldocumentlist = [];
        }
        if(isscroll =='down' && searchitemss && searchitemss !=null){
            this.alldocumentlist = [];
            this.input.allsearchitems = searchitemss.toLowerCase();
        }
        axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.documentlist = result.data.data.rows;
                this.loginpersonimage = result.data.data.imagename;
                
                for(let i=0; i<this.documentlist.length; i++){
                    this.documentlist[i]['docnamelist'] =this.documentlist[i].documentnamelist.split(',');
                    this.documentlist[i]['isshowmore'] =0;

                    //like number conversion in K,M,B,T start
                    let num = this.documentlist[i].islike;
                    let convertnumber = (num) => {
                        if (num < 1e3) return num;
                        if (num >= 1e3 && num < 1e6)
                            return +(num / 1e3).toFixed(1) + "K";
                        if (num >= 1e6 && num < 1e9)
                            return +(num / 1e6).toFixed(1) + "M";
                        if (num >= 1e9 && num < 1e12)
                            return +(num / 1e9).toFixed(1) + "B";
                        if (num >= 1e12) return +(num / 1e12).toFixed(1) + "T";
                    };
                    this.documentlist[i].islike = convertnumber(num);
                    //like number conversion in K,M,B,T end

                    //hrs,min,week,days,year conversion start
                    let currentdatetime = moment().format('YYYY-MM-DD HH:mm');
                    let startDatetime = this.documentlist[i].lastmodifieddate ? moment(this.documentlist[i].lastmodifieddate) : moment(this.documentlist[i].createddate);
                    let endDatetime = moment(currentdatetime);

                    if (endDatetime.isBefore(startDatetime)) {
                        [startDatetime, endDatetime] = [endDatetime, startDatetime];
                    }
                    
                    let durations = moment.duration(endDatetime.diff(startDatetime));
                    let currenthrs = durations.asMinutes();
                    if (currenthrs >= 525600) {
                        let currentYears = Math.floor(currenthrs / 525600);
                        this.documentlist[i]['currenthrs'] = `${currentYears} years`;
                    }else if (currenthrs >= 10080) {
                        let currentDays = Math.floor(currenthrs / 10080);
                        this.documentlist[i]['currenthrs'] = `${currentDays} week`;
                    }else if (currenthrs >= 1440) {
                        let currentDays = Math.floor(currenthrs / 1440);
                        this.documentlist[i]['currenthrs'] = `${currentDays} days`;
                    } else if (currenthrs >= 60) {
                        let currentHours = Math.floor(currenthrs / 60);
                        let remainingMinutes = Math.floor(currenthrs % 60);
                        this.documentlist[i]['currenthrs'] = `${currentHours} hrs`; //${remainingMinutes} min
                    } else {
                        let remainingMinutes = Math.floor(currenthrs % 60);
                        this.documentlist[i]['currenthrs'] = `${remainingMinutes} min`;
                    }
                    //hrs,min,week,days,year conversion end
                }
                if(isscroll && isscroll =='noscroll' && this.documentlist.length > 0){
                    let findindexs = this.alldocumentlist.findIndex(item => item.courseid === courseid);
                    if (findindexs !== -1) {
                        this.alldocumentlist.splice(findindexs, 1, this.documentlist[0]);
                    }
                }else{
                    this.alldocumentlist = this.alldocumentlist.concat(this.documentlist);
                }

                if(courseid && courseid !=null && !isscroll){
                    setTimeout(function(){
                        $('html, body').animate({
                            scrollTop: $("#article"+courseid).offset().top -70
                        });
                    }, 500);
                }
                // if(isscroll && isscroll =='up'){
                //     if(this.pageoffset !=1){
                //         let isid =this.documentlist[4].courseid
                //         setTimeout(function(){
                //             $('html, body').animate({
                //                 scrollTop: $("#article"+isid).offset().top -70
                //             });
                //         }, 500);
                //     }
                // }
                // if(isscroll && isscroll =='down'){
                //     let isid =this.documentlist[0].courseid
                //     setTimeout(function(){
                //         $('html, body').animate({
                //             scrollTop: $("#article"+isid).offset().top -70
                //         });
                //     }, 500);
                // }
            } else {
                this.documentlist = [];
                this.alldocumentlist = [];
            }
        });
    },
    getLogtimeDownloadLink(data) {      
      if(data) {
        let input =[];
        input.push({link:data, empcode: this.undt.userid, useremail: this.undt.username})
        let payload = {
          documents:input,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            if(result.data.data[0].presignurl){
              window.location.href=result.data.data[0].presignurl; 
            }
          }
        }).catch(e => {
            this.displayError(e)
        })
      }
    },
    savearticlelike(islike,courseid){
        this.isLoading = true;
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            islike: islike,
            courseid: courseid
        }
        let apiURL='api/coursemaster/savearticlelike'
        axios({
        'method': 'POST',
        'url': apiURL,
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => {    
            this.isLoading = false;         
            if(result.data.errorCode == 0){
                this.getDocumetList(courseid,'noscroll',this.searcharticle);
            }else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            }
            else {
                Swal.fire({
                    title: "Failed",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    showimagepreview(imgurl){
        let input = {
            link: imgurl,
            empcode: this.undt.userid,
            useremail: this.undt.username
        };
        this.isLoading =true;
        axios({
            'method': 'POST',
            'url': "/api/imageupload/getProfilePicDownloadLink",
            'data':input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            this.isLoading =false;
            if(result.data.status) {
                if(result.data.data.length>0) {
                    $('.imagepreview').attr('src', result.data.data);
                    $('#imagemodal').modal('show');
                }
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    searchdata(event){
        if(event){
            if(event.target.value && event.target.value !=null && event.target.value !=''){
                if(event.target.value && event.target.value.length > 3){
                    this.getDocumetList(null,'issearch',event.target.value);
                }
            }else{
                this.getDocumetList(null,'isnosearch',null);
            }
        }
    },
    redirecttocreatepage:function(btnstate){
        if(btnstate=="createarticle"){
            this.$router.push({ 
              path: '/training/create',
              params: {
                backbtnstate:btnstate,
            }
          })
        }    
    },
  }
}
</script>
<style scoped>
.colleagueimage img{
    border-radius: 0.375rem;
    width:40px !important;
    height:40px !important;
}
.postimageviews img{
    max-width: 100%!important;
    height: auto!important;
    margin: 0px auto;
    cursor: pointer;
}
.tast_case_groupingview_readmore .cycle_viewmoretext a.btn{
    padding-top: 0px;
}
.search_filtter_animation.sarch_afterchange_design12{
  width: 50%;
  margin:0px auto;
}
.thumb-icon-liked {
  color: blue;
}
.thumb-icon-unliked {
  color: gray;
}
.previewpopupclass .imagepreview{
    width: 100%;
    padding: 0px;
}
.search.searchbox_view{
    width: 100%;
    display: flex;
    position: relative;
}
.search.searchbox_view .custom-class{
    position: absolute;
    right: 0px;
    margin: 10px;
}
.profile_search_icon img{
    width: 34px!important;
    height: 34px!important;
}
</style>