<template>
    <div class="app-content content">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="fullPage"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
           <div class="content-wrapper">
               <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5 px-0">
                       <div class="row breadcrumbs-top">
                           <div class="col-sm-8">
                               <h3 class="content-header-title float-left mb-0">Apply Emergency Leave</h3>
                               <!-- <div class="breadcrumb-wrapper">
                                   <ol class="breadcrumb">
                                       <li class="breadcrumb-item"><router-link to='/profile'>Leave</router-link></li>
                                   </ol>
                               </div> -->
                           </div>
                       </div>
                       </div>
           </div>
           <section class="main_leave_sectiondiv_stocklist" id="card-navigation">
             <div class="card border-warning filter_card_searchbgst">
               <div class="card-header filter_rotation_onlick" style="cursor: pointer" v-on:click="mysearchrequestollapse">
                <div class="container-fluid" >
                   <div class="row fc_my-05 ">
                       <div class="w-auto px-1 mobile-px-0 ">
                       <div class="avatar bg-light-secondary rounded d-inline-flex">
                           <div class="avatar-content">
                           <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                           </div>
                       </div>
                       <h4 class="card-title d-inline-flex ml-1 ">Search Stockist Wise Employees </h4>
                       </div>
                       <div class="ml-auto d-flex pr-md-1 ">
                       <div  class="ml-auto px-sm-1 px-sm-0 text-right mobile-px-0 leavebottomselected_text">
                       <div class="input-group-append float-right">
                           <button id="commonaddmodule"
                         class="btn btn-sm btn-relief-success"
                         data-toggle="modal"
                         aria-haspopup="true"
                         aria-expanded="false"
                         :data-target="currentmodule"
                         type="button"
                         data-backdrop="static"
                         data-keyboard="false" :disabled="empList.length && !isChecked.some(bool => bool === true)"> 
                           
                           <span class="mobile_hide_bt">Apply Leave For Selected Users </span> 
                           </button>
                       </div>
                       </div>
                       </div>
                   </div>
                   </div>
                   </div>
               <div class="container-fluid"  v-bind:class="[{ show: isSearchRequestActive },
                 card_content,
                 collapse,
               ]">
                   <div class="card-body pt-2 px-0">
                        <form>
                           <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Stockist </label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Stockist"
                                    v-model="stockist"  :options="stockistList"/>
                                </div>
                               <div class="col-sm-8 col-md-6 col-lg-6 mb-0 text-left">
                                <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getStokistEmpList()"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                                <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                               </div>
                           </div>
                        </form>
                   </div>
                </div>
               </div>
               <div  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top:1rem; margin-bottom: 1rem !important;">
                    <div>
                            <div class="row">
                            <div class="col-md-12 1">
                                <div class="card ">
                                <div class="">
                                    <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="table-small">
                                        <div class="col-12">
                                            <div class="card">
                                            <div class="" >
                                            </div>
                                            <div class="table-responsive datatableview" >
                                                <table class="table table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                                <thead>
                                                    <tr>
                                                    <th scope="col" class="align-middle py-1" style="min-width: 100px;">
                                                        
                                                        <span class="px-1"> Sr#</span>
                                                    </th>
                                                    <th scope="col" class="align-middle text-left ">Employee ID</th>
                                                    <th scope="col" class="align-middle text-left" style="min-width:130px">Employee Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(data,index) in empList">   
                                                        <tr v-bind:key="index">
                                                            <td data-column="Sr#" >
                                                                <span class="align-middle text-center">
                                                                    <label :for="index+'-'+data.id"></label>
                                                                    <input type="checkbox" :id="index+'-'+data.id" class="delete_checkbox" v-model="isChecked[index]" v-on:click="empSelection(data.id)">
                                                                    <span class="ml-1"> {{ index+1+currPageNum*pagelimit }} </span>
                                                                </span>
                                                            </td>
                                                            <td data-column="Employee ID" class="align-middle text-left">{{data.id ? data.id:'NA'}}</td>
                                                            <td data-column="Employee Name" class="align-middle text-left">{{data.label ? data.label:'NA'}}</td>
                                                        </tr>
                                                        
                                                    </template>

                                                    <template v-if="empList.length==0">
                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                    </template>
                                                </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                    <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
           </section>
           <div class="leavebottomselected">
               <button type="button" class="btn btn-sm btn-relief-success"> Apply Leave For Selectd Users</button>
           </div>
           <div  class="modal modal-slide-in fade" id="projectappmaster" aria-hidden="true">
               <div class="modal-dialog sidebar-lg">
                   <div class="modal-content p-0">
                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                       style="color: #3E4757 !important">
                       ×
                   </button>
                   <div class="modal-header mb-1 leftsidepopupstyle"
                       style="background-color: #ECEEFB !important">
                       <h5 class="modal-title" style="color: #3E4757 !important">
                       
                       <span class="align-middle  mx-0"
                           >Apply Leave for Selected Users: ({{ getSelectedIdsCount() }})</span
                       >
                       </h5>
                   </div>
                   
                   <div class="modal-body flex-grow-1 devUniqueForm">
                       
                       <form>
                         <div class="row">
                             <div class="col-sm-5">
                                 <div class="form-group mg-bot0">
                                 <label for="projectmdllistdd">From :</label>
                                    <date-picker placeholder="Select From Date"  valueType="format" class="startdate"  v-model="fromDate" 
                                     @input="selectedfromdate"></date-picker>
                                 </div>
                             </div>
                             <div class="col-sm-5">
                                 <div class="form-group mg-bot0">
                                 <label for="projectmdllistdd">To :</label>
                                 <date-picker placeholder="Select To Date" v-model="toDate"  valueType="format" class="startdate"  :disabled-date="disabledBefore" @input="selectedtodate"></date-picker>
                                 </div>
                             </div>


                             <div v-if="this.toDate != null" class="col-sm-2 px-0">
                                <div class="form-group mg-bot0">
                                 <label for="projectmdllistdd" class="projectmdllistdd_titlelables">Total Days:</label>
                                 <label for="projectmdllistdd" class="text-center w-100">
                                    <span style="font-size: 18px; color: blue;"> <span class="badge badge-info text-center" style="margin-top: 5px;"> {{ totalDays }}</span></span></label>
                                </div>
                             </div>
                             <div class="errorinputmsg mx-1" v-if="(this.fromDate == null) && (this.toDate == null)">Please select both dates</div>

                         </div>
                         <div class="row">
                             <div class="col-12">
                                 <div class="form-group mg-bot0">
                                 <label for="projectmdllistdd">Leave Type</label>
                                 <treeselect class="projectmdllistdd capitalisetext"
                                     placeholder="Select leave type " v-model="leavetype" :options="leaveTypes"/>
                                 </div>
                                 <div class="errorinputmsg" v-if="(this.leavetype == null) || (this.leavetype == '')">leave type is required</div>

                             </div>
                             <div class="row col-lg-12 mx-0">
                                <label for="message-label">Reason</label>
                                <textarea class="form-control"  rows="3" placeholder="Enter reason here" v-model="reason" v-bind:class="{'form-control':true, 'is-invalid' : !validText(reason)}"></textarea>
                                <div class="invalid-feedback">Reason is required</div>
                            </div>
                             <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right">
                                 <button :disabled="!isValidForm" type="button" class="btn btn-relief-primary mt-0 mr-1" @click="applyLeave()" ><span>Submit</span></button>
                             </div>
                         </div>
                       </form>
                     
                 </div>

                   </div>
               </div>
           </div>
       </div>
       
 </template>
 
 <script>
 import axios from 'axios'
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Treeselect from "@riophae/vue-treeselect";
 import '@riophae/vue-treeselect/dist/vue-treeselect.css'
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css'
 import profilepic from '../profilepic.vue';
 import {SearchIcon,XIcon } from "vue-feather-icons";
 import moment from 'moment';
 import DatePicker from "vue2-datepicker";
 import commonMethods from '../../utils/commonMethods';
 import Pagination from 'vue-pagination-2'


 
 export default {
     name: 'EMLeaveApplication',
     mixins: [ commonMethods ],
 
     components: {
         VueElementLoading,Loading,profilepic,Treeselect,SearchIcon,XIcon,DatePicker,Pagination
     },
     data() {
         return {
           isSearchRequestActive: false,
           card: "card",
           isRequestActive: false,
           card_content: "card-content",
           border_warning: "border-warning",
           collapse: "collapse",
           currentmodule: "#projectappmaster",
           isLoading: false,
           fullPage: true,
           stockist:null,
           stockistList:[],
           empList:[],
           isListActive:true,
           isListDetActive:false,
           isChecked: [],
           fromDate:null,
           toDate:null,
           isBothDateSelected:false,
           leaveTypes:apiUrl.leaveTypes,
           leavetype:null,
           reason:'',
           selectedIds: [],
           totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            showtabledata:true,

            isValidForm: false,



         }
     },
     props: {
         bid: Array,
         tstatus: String,
         flagg: Number,
     },
     mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');
          this.getstockistlist();
          this.getStokistEmpList()
        } 
    },
    computed: {
        totalDays() {
            if (this.fromDate && this.toDate) {
                const from = new Date(this.fromDate);
                const to = new Date(this.toDate);
                const diffTime = Math.abs(to - from) + (24 * 60 * 60 * 1000); 
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                return diffDays;
            } else {
                return '';
            }
        },
    },
    watch: {
        fromDate: {
            handler() {
                this.validateForm();
            },
        immediate: true,
        },
        toDate: {
            handler() {
                this.validateForm();
            },
        immediate: true,
        },
        leavetype: {
            handler() {
                this.validateForm();
            },
        },
        reason: {
            handler() {
                this.validateForm();
            },
        },
    },

    methods: {
        mysearchrequestollapse: function () {
            this.isSearchRequestActive = !this.isSearchRequestActive;
        },
        validateForm() {
            this.isValidForm = this.validText(this.fromDate) && this.validText(this.toDate) && this.validText(this.leavetype) && this.validText(this.reason);
        },
        empSelection(id) {
            const index = this.selectedIds.indexOf(id);
            if (index === -1) {
                this.selectedIds.push(id);
            } else {
                this.selectedIds.splice(index, 1);
            }
            console.log(this.selectedIds,'selectedids===>')
        },
        getSelectedIdsCount() {
            return this.selectedIds.length;
        },

        disabledBefore(date) {
            const oneMonthFromFromDate = moment(this.fromDate).add(1, 'month');
            const startOfOneMonth = oneMonthFromFromDate.startOf('day').toDate();
            const startOfFromDate = moment(this.fromDate).startOf('day').toDate();
            return date > startOfOneMonth || date < startOfFromDate;
        },
        getstockistlist() {
            this.isLoading =true;
            this.input ={
            useremail : this.undt.username,
            empcode: this.undt.userid
            }
            axios({
            'method': 'POST',
            'url': 'api/thirdpartyfcclock/getStokistList',
            'data': this.input,
            'headers': {'authorization':this.tokendata}
            })
            .then(result => {
            this.isLoading =false;
            if (result.data.errorCode == 0) {
                this.stockistList = result.data.data.rows.data;
            }else if(result.data.errorCode == 3){
                Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                window.location.href = "/#/login";
                })
            }else {
                this.stockistList = [];
            }
            }).catch(e => {
            this.displayError(e);
            })
        },
        clickCallback: function (pageNum) {
          this.pageoffset = pageNum;
          this.page = pageNum;
          this.currPageNum = pageNum - 1;
          this.getStokistEmpList()
        },

        getStokistEmpList(){ 
            this.isLoading = true;
            this.input ={
                useremail: this.undt.username,
                empcode: this.undt.userid,
                limit: apiUrl.LIMIT,
                offset: this.pageoffset
            }
            this.input.department = this.stockist ? this.stockist: null
            axios({
            'method': 'POST',
            'url': 'api/thirdpartyfcclock/getAllStokistEmp',
            'data': this.input,
            'headers': {'authorization':this.tokendata}
            })
            .then(result => {
            this.isLoading = false;
            if(result.data.errorCode == 0){
                this.empList = result.data.data.rows;
                this.totalcount = result.data.data.totalCount;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit)

            }else if(result.data.errorCode == 3){
                Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                window.location.href = "/#/login";
                })
            }else{
                this.totalcount = 0
                this.pageCount =0
                this.empList = [];
            }
            }).catch(e => {
            this.displayError(e);
            });
        },
        clearSearchFields(){
            this.stockist= null,
            this.getStokistEmpList()
        },
        validText : function(inputval) {
            let re = /[A-Za-z0-9].{0,}/;
            if(inputval){
              return re.test(inputval.toLowerCase());
            }
        },


        applyLeave() {
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                userids: this.selectedIds,
                startdate: this.fromDate ? this.fromDate : null,
                enddate : this.toDate ? this.toDate : null,
                leavetype : this.leavetype ? this.leavetype: null,
                reason : this.reason,
            }
            let apiURL = 'api/thirdpartyfcclock/applyEMleave'
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
            this.resetRecords()
            if(result.data.errorCode === 0) {
                this.getStokistEmpList();
                Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: { confirmButton: "btn btn-primary" },
                buttonsStyling: !1,
                })
            } else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                buttonsStyling: !1,
                })
            } else {
                    Swal.fire({
                    text: result.data.msg,
                    icon: "info",
                    customClass: { confirmButton: "btn btn-primary" },
                    buttonsStyling: !1,
                })
            }
                this.isChecked = []
            })
        },
        resetRecords(){
            this.selectedIds = [],
            this.fromDate = null,
            this.toDate = null,
            this.leavetype = null,
            this.reason = null
            this.getStokistEmpList()
        },
        selectedfromdate(){
            if((this.fromDate && this.toDate) || (!this.fromDate && !this.toDate)){
                this.isBothDateSelected = false
            }else if((this.fromDate && !this.toDate) ||( !this.fromDate && this.toDate)){
                this.isBothDateSelected = true
            }
        },
        disableDate(date) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            let selectedFromDate = new Date(this.fromDate);
            selectedFromDate.setDate(selectedFromDate.getDate()-1)
            return date < selectedFromDate;
        },
        selectedtodate(){
            if((this.fromDate && this.toDate) || (!this.fromDate && !this.toDate)){
                this.isBothDateSelected = false
            }else if((this.fromDate && !this.toDate) ||( !this.fromDate && this.toDate)){
                this.isBothDateSelected = true
            }
        },


    },
 }
 </script>