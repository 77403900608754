<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 ">
          <div class="container-fluid px-0">
            <div class="row breadcrumbs-top mx-0">
              <div class="col-xs-auto col-sm-9 col-md-9 px-0 content-header-left pl-0 d-lg-flex">
                <h3 class="content-header-title float-left mb-0">Course Module Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/training/list'>Course Module List</router-link></li>
                  </ol>
                </div>
              </div>
            
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
            <div class="row row fc_my-05">
              <div class="w-auto px-1 mobile-padding-0-2">
                <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                        <search-icon
                        size="1.5x"
                        class="custom-class avatar-icon font-medium-3"
                        ></search-icon>
                    </div>
                </div>
                <h4 class="card-title d-inline-flex ml-1">Search Course Module Management</h4>
            </div>
            <div class="ml-auto d-flex pr-1" v-if="pagefeatures.create">
                <div class="input-group-append float-right">
                  <router-link to="/training/create"><span class="btn btn-sm btn-relief-success" ><plus-circle-icon size="1.5x"></plus-circle-icon> <span class="mobile_hide_bt"> Create Course Module </span></span></router-link>
                </div>
             
            <span class="rotetions_view_arrow"> 
              <em class="las la-angle-down"></em>
            </span>
          </div>
            </div>
          </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
            <div class="card-body pt-1">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Course code</label>
                    <input type="text" class="form-control" placeholder="Enter Course code" v-model="search.coursecode"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Course Name</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 4 chars to Course name" :multiple="true" :clear-on-select="true" v-on:search-change="issueChange" v-model="search.coursename" :options="coursenamelist"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Created-By</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search createdby" v-model="search.createdby" :options="search.projectcreatedbyopts" v-on:search-change="createdByChangeSearch"/>
                   </div>
                   <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4" >
                  <label for=""> Created From Date</label>
                    <date-picker placeholder="Select Created Date From" v-model="search.startdatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search.</div> 
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4">
                  <label for="">Created To Date</label>
                    <date-picker placeholder="Select Created Date To" v-model="search.startdateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0  mobile-px-0">
                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getCourseData(search)"> 
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                    </div>
                </div>
            </div>
        </div>
              </div>
        <div class="card border-warning" id="courselist">
          <div class="card-body p-0">
            <div class="table-responsive" >
              <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                <thead>
                  <tr>
                    <th scope="col" class="srno first-col-sticky align-middle py-1">Sr#</th>
                    <th scope="col" class="text-center align-middle second-col-sticky">Course Code</th>
                    <th scope="col" class="text-center align-middle">Course name</th>
                    <th scope="col" class="align-middle">Course description</th>
                    <th scope="col" class="align-middle">No. of Courses</th>
                    <th scope="col" class="align-middle">Subscription Type</th>
                    <th scope="col" class="text-nowrap align-middle">Start Date</th>
                    <th scope="col" class="text-nowrap align-middle">End Date</th>
                    <th scope="col" class="text-nowrap align-middle">Created Date</th>
                    <th scope="col" class="align-middle">Created By</th>
                    <th scope="col" class="text-nowrap align-middle">Published Date</th>
                    <th scope="col">Published By</th>
                    <th scope="col" class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(data,index) in training.courselist"  >
                    <tr v-bind:key="index" >
                      <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                      <td class=" text-capitalize text-nowrap second-col-sticky">{{data.coursecode}}</td>
                      <td class=" text-capitalize sitebluecolor cursor-pointer"  @click="redirecttourl('view',data)">{{data.coursename}}</td>
                      
                      <td class="text-capitalize" style="min-width:200px;">
                        <span v-if="data.description && data.description.length>=150" v-html="data.description.substring(0,150)+'..'" ></span>
                        <span v-else v-html="data.description"></span>
                      </td>
                      <td class="text-center">{{data.nooftraining}}</td>
                      <td class="text-center">{{data.subsriptiontype}}</td>
                      <td class="text-nowrap">{{dateFormat(data.startdate)}}</td>
                      <td class="text-nowrap">{{dateFormat(data.enddate)}}</td>
                      <td class="text-center text-nowrap">{{dateMonthFormat(data.createddate)}}</td>
                      <td class="text-center capitalisetext">{{formatName(data.createdby)}}</td>
                      <td class="text-center text-nowrap">{{dateMonthFormat(data.publisheddate)}}</td>
                      <td class="text-center capitalisetext">{{formatName(data.publishedby)}}</td>
                    <td>
                        <div class="dropdown">
                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item"   @click="redirecttourl('view',data)" href="javascript:void(0);">
                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                    <span>View Details</span>
                                </a>
                                <a v-if="pagefeatures.update && data.coursetype != 'article'" class="dropdown-item devEditEle"  @click="redirecttourl('edit',data)" href="javascript:void(0);">
                                  <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                  <span>Update</span>
                                </a>
                                <a v-if="data.coursetype == 'article' && data.createdby == undt.username" class="dropdown-item devEditEle"  @click="redirecttourl('edit',data)" href="javascript:void(0);">
                                  <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                  <span>Update</span>
                                </a>
                            </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="training.courselist.length==0">
                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center mt-4">
              <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from "moment";
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,XIcon,EditIcon, MoreVerticalIcon,EyeIcon,Edit2Icon,SearchIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'TrainingList',
  components: {
    VueElementLoading,EyeIcon,Edit2Icon,
    Loading,
    PlusCircleIcon,
    EditIcon,
    Pagination,
    MoreVerticalIcon,
    SearchIcon,
    XIcon,
    Treeselect,
    DatePicker,
  },
  mixins: [ commonMethods ],
  data() {
    return {
      maxLength:20,
      training:{
        courselist:[],
        courseid:null,
        
      },
      search:{
        coursecode: null,
        coursename: null,
        createdby: null,
        projectcreatedbyopts: null,
        startdatefrom:null,
        startdateto:null
      },
      isBothDateSelected:false,
      isSearchRequestActive:false,
      isLoading: false,
      undt: null,
      tokendata: null,
      coursenamelist:[],
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      collapse:'collapse',
      card_content:'card-content',
    }
  },
  created() {
    this.pagefeatures = {};
    if(window.localStorage.getItem("pagefeatures")){
      var pagefeatures1 = JSON.parse(
        window.atob(window.localStorage.getItem("pagefeatures"))
      );
      pagefeatures1.forEach((element) => {
        if (element.page === "/training/list") {
          this.pagefeatures[element.featurename] = element.featureaccess;
        }
      });
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        // AJAX REQUESTS
        this.getCourseData()
        // this.getCoursename()
      }
    }
  },
  methods: {
    getCourseData(search) {
      this.isLoading = true
      this.input = {
        limit: apiUrl.LIMIT,
          offset: this.pageoffset,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
       let url = 'api/coursemaster/list' 
      if(search) {
        this.page=1
        }
      this.input.coursename  = this.search.coursename    
      this.input.code  = this.search.coursecode  
      this.input.createdby  = this.search.createdby 
      this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
      let assignedate = ''
        if(this.search.startdateto){
          assignedate = new Date(this.search.startdateto)
          assignedate.setDate(assignedate.getDate() + 1)
          assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
        }
      this.input.dateto = this.search.startdateto?assignedate:null
       axios({
        method: 'POST',
        url:url,
        data: this.input,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          let d = result.data.data.rows
          this.training.courselist = d
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.training.courselist = []
          this.totalcount = 0
        }
      }).catch(e => {
                this.displayError(e)
        })
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    // clearCodeList(state) {
    //   if(!state) {
    //     this.search.coursecodelist = null
    //   }
    // },
    resetRecords: function() {
    this.search.coursename = null;
    this.search.coursecode = null;
    this.search.startdatefrom = null;
    this.search.startdateto = null;
    this.search.createdby = null;
    this.getCourseData();
    this.isBothDateSelected = false
  },
  disabledBefore(date) {
      let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      let selectedFromDate = new Date(this.search.startdatefrom);
      selectedFromDate.setDate(selectedFromDate.getDate()-1);
      return date < selectedFromDate;
    },
    notBeforeToday: function (date) {
        this.search.startdateto = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
    selectedfromdate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    selectedtodate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    issueChange: function (node){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/coursemaster/getcoursenamelist";
          this.input = {
          coursename:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.coursenamelist = result.data.data;
            } else {
              this.coursenamelist = [];
            }
          });
        } 
      },
      createdByChangeSearch: function (node){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getAllEmpEmailIds";
        this.input = {
          emailid:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data;
          } else {
            this.search.projectcreatedbyopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    formatName(authorstring) {
      if(!authorstring) return 'NA'
      let author=authorstring.replace('@firstcry.com','')
      if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
      author = author.replace('.',' ')
      author = author.charAt(0).toUpperCase() + author.slice(1);
      return author
    },
    redirecttourl:function(btnstate,rowdata){
        rowdata.backbtnstate=btnstate
        if(btnstate=="view"){
          this.$router.push({ 
            path: '/training/view?courseid='+rowdata.courseid,
            params: {
                backbtnstate:btnstate,
                clickeddata:rowdata
          }
        })
        }else if(btnstate=="edit"){
          this.$router.push({ 
              path: '/training/update?courseid='+rowdata.courseid,
              params: {
                  backbtnstate:btnstate,
                  clickeddata:rowdata
              }
          })
        }            
    },
    clickCallback: function(pageNum) {
      this.pageoffset=pageNum
      this.currPageNum = pageNum - 1
      this.getCourseData();
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#TrainingList").offset().top -70
        });
      }, 500);
    },
    showCSnames(trname){
      if(trname && !trname.includes(',')) return trname
      let responsestr='';
      let trlist=trname.split(',')
      if(trlist.length > 1){
        for(var i=0; i<trlist.length; i++){
          responsestr += (i+1)+'. '+trlist[i]+'<hr class="my-50"/>'
        }
        responsestr = responsestr.slice(0,-5)
      }else{
        for(var i=0; i<trlist.length; i++){
          responsestr += trlist[i]
        }
      }
      return responsestr
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    dateFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
  }
  
}
</script>
