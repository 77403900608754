//TRS Project route

import Home from "./components/Home.vue"
import MyDashboard from "./components/trs/reimbursement/MyDashboard.vue"
import Travellist from "./components/trs/reimbursement/Travelist.vue"
import TravelRequest from "./components/trs/reimbursement/TravelRequest.vue"
import ViewRequest from "./components/trs/reimbursement/ViewRequest.vue"
import AdminPanalViewTickets from "./components/trs/reimbursement/AdminPanalViewTickets.vue"
import Reports from "./components/trs/reimbursement/Report.vue"
import HODMaster from "./components/trs/reimbursement/Hodmaster.vue"
import HODTravelReqList from "./components/trs/reimbursement/Hodlist.vue";
import TransportModeMaster from "./components/trs/reimbursement/TransportModeMaster.vue"
import DesignationBandMaster from "./components/trs/reimbursement/DesignationBandMaster.vue"
import TicketApprovalMaster from "./components/trs/reimbursement/TicketApprovalMaster.vue"
import FinWise from "./components/trs/reimbursement/FinWise.vue"
import PaymentInvoiceReport from "./components/trs/reimbursement/PaymentInvoiceReport.vue" 

const trsRoutes = [
    {
        path: '/home',
        component: Home,
        children: [
            {
                path: '/trs/mydashboard',
                name: 'MyDashboard',
                component: MyDashboard,
            },
            {
                path: '/trs/travelist',
                name: 'TravelList',
                component: Travellist,
            },
            {
                path:'/trs/travelrequest',
                name:'TravelRequest',
                component:TravelRequest
            },
            {
                path:'/trs/viewrequest',
                name:'ViewRequest',
                component:ViewRequest
            },
            {
                path:'/trs/adminpanalviewtickets',
                name:'AdminPanalViewTickets',
                component:AdminPanalViewTickets
            },
            {
                path:'/trs/reports',
                name:'Reports',
                component:Reports
            },
            {
                path:'/trs/hodmaster',
                name:'HODMaster',
                component:HODMaster
            },
            {
                path:'/trs/transportmodemaster',
                name:'TransportModeMaster',
                component:TransportModeMaster   
            },
            {
                path:'/trs/designationbandmaster',
                name:'DesignationBandMaster',
                component:DesignationBandMaster   
            },
            {
                path:'/trs/ticketapprovalmaster',
                name:'TicketApprovalMaster',
                component:TicketApprovalMaster   
            },
            {
                path:'/trs/finwise',
                name:'FinWise',
                component:FinWise   
            },
            {
                path:'/trs/hodtravelreqlist',
                name:'HODTravelReqList',
                component:HODTravelReqList
            },
            {
                path:'/trs/paymentinvoicereport',
                name:'PaymentInvoiceReport',
                component:PaymentInvoiceReport   
            },
        ]
    }
];
  
export default trsRoutes;