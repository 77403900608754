<template>
  <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left col-md-9 col-12 mb-1 px-0">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h3 class="content-header-title float-left mb-0">Issue Details</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'IssueList', params: { search: true }}">Issue Management List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">View Issue</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" >
          <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
            <div class="card-header py-1" style="cursor:pointer">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-4">
                    <div class="avatar bg-light-primary rounded d-inline-flex">
                      <div class="avatar-content">
                        <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                      </div>
                    </div>
                    <h3 class="card-title ml-2 d-inline-flex text-primary text-primary-dark">ISSUE CODE: {{this.view.issuecode}}</h3>
                  </div>
                  <div class="col-md-8 text-right">
                    <div v-if="loginusername == view.createdby || pagefeatures.edit" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="openEditModule(backpagedata,0)">
                      <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                      <span>Edit</span>
                    </div>
                    <div v-if="pagefeatures.logtime" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="openLogModule()">
                      <watch-icon size="1.5x" class="custom-class avatar-icon mr-50"></watch-icon>
                      <span>Log Time</span>
                    </div>
                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="openBlockerIssueModule()">
                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                        <span>Add Blocker</span>
                    </div>
                    <div v-if="bissuelist.length" class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2" @click="openBlockerIssuesList()">
                        <file-text-icon size="1.5x" class="custom-class avatar-icon mr-50"></file-text-icon>
                        <span>Blocker list</span>
                    </div>

                  </div>
                </div>
              </div>
          </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                <div class=" card-transaction">
                    <div class="mb-2" >
                        <div class="transaction-item">
                        <div class="media">
                            <div class="avatar bg-light-success rounded">
                                <div class="avatar-content">
                                    <em class="las la-user-edit font-medium-3"></em>
                                </div>
                            </div>
                            <div class="media-body">
                                <h6 class="transaction-title">{{this.issue.issuename}}</h6>
                                <small>Created by {{this.view.createdby}}</small>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
              </div>
              </div>

              <div class="row match-height">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                      <div class="media">
                        <div v-if="this.issue.priority=='high'" class="avatar bg-light-danger rounded">
                          <div class="avatar-content">
                             
                              <em class="i_icon_st la-bug las font-medium-5"></em>
                          </div>
                        </div>
                        <div v-else-if="this.issue.priority=='low'" class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                             <em class="i_icon_st la-bug las font-medium-5"></em>
                          </div>
                        </div>
                        <div v-else-if="this.issue.priority=='medium'" class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                              <em class="i_icon_st la-bug las font-medium-5"></em>
                          </div>
                        </div>
                        <div v-else class="avatar bg-light-dark rounded">
                          <div class="avatar-content">
                              <em class="i_icon_st la-bug las font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Issue Priority</h6>
                          <small v-if="this.issue.priority=='high'" class="capitalisetext text-danger" style="font-weight:500">{{this.issue.priority}}</small>
                          <small v-else-if="this.issue.priority=='low'" class="capitalisetext text-info" style="font-weight:500">{{this.issue.priority}}</small>
                          <small v-else-if="this.issue.priority=='medium'" class="capitalisetext text-warning" style="font-weight:500">{{this.issue.priority}}</small>
                          <small v-else class="capitalisetext text-dark" style="font-weight:500">{{this.issue.priority ? this.issue.priority: 'NA'}}</small>

                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div v-if="this.view.sevairtyname=='Critical'" class="avatar bg-light-danger rounded">
                          <div class="avatar-content">
                              <codepen-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></codepen-icon>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Major'" class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                             <em class="i_icon_st la-codepen lab font-medium-5" style="font-weight: 500;"></em>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Minor'" class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                              <em class="i_icon_st la-codepen lab font-medium-5" style="font-weight: 500;"></em>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Cosmetic'" class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                              <em class="i_icon_st la-codepen lab font-medium-5" style="font-weight: 500;"></em>
                          </div>
                        </div>
                        <div v-else-if="this.view.sevairtyname=='Moderate'" class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                              <em class="i_icon_st la-codepen lab font-medium-5" style="font-weight: 500;"></em>
                          </div>
                        </div>
                        <div v-else class="avatar bg-light-dark rounded">
                          <div class="avatar-content">
                              <em class="i_icon_st la-bug las font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Issue Severity</h6>
                          <small v-if="this.view.sevairtyname=='Critical'" class="text-danger" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Major'" class="text-warning" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Minor'" class="text-primary" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Cosmetic'" class="text-success" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else-if="this.view.sevairtyname=='Moderate'" class="text-info" style="font-weight:500">{{this.view.sevairtyname}}</small>
                          <small v-else class="capitalisetext text-dark" style="font-weight:500">{{this.view.sevairtyname ? this.view.sevairtyname: 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div v-if="this.issue.status=='open'" class="avatar bg-light-warning danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                         <div v-else-if="this.issue.status=='resolved'" class="avatar bg-light-primary danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                         <div v-else-if="this.issue.status=='closed'" class="avatar bg-light-success danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>
                         <div v-else-if="this.issue.status=='reopen'" class="avatar bg-light-danger danger rounded">
                          <div class="avatar-content">
                            <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                          </div>
                        </div>

                          <div v-else-if="this.issue.status=='feedback'" class="avatar bg-light-secondary danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>

                          <div v-else class="avatar bg-light-secondary danger rounded">
                            <div class="avatar-content">
                              <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                            </div>
                          </div>                        
                        <div class="media-body">
                          <h6 class="transaction-title">Issue Status</h6>
                          <small v-if="this.issue.status=='open'" class="capitalisetext text-warning" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='resolved'" class="capitalisetext text-primary" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='closed'" class="capitalisetext text-success" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='reopen'" class="capitalisetext text-danger" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else-if="this.issue.status=='feedback'" class="capitalisetext text-dark" style="font-weight:500">{{this.issue.status}}</small>
                          <small v-else class="capitalisetext text-dark" style="font-weight:500">{{this.issue.status}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                             <em class="i_icon_st la-bug las font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Issue Type</h6>
                          <small>{{this.view.typename}}</small>
                        </div>
                      </div>
                    </div>                   
                  </div>                                                     
                </div>
              </div>              
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                             <em class="las la-clipboard-check font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Business Task Name</h6>
                            <small  v-if="this.view.btmname" class="text" style="cursor:pointer; color:Blue;" @click="redirecttoBTM('view', {moduleid:view.businessid})">{{this.view.btmname ? this.view.btmname:'NA'}}</small>
                            <small  v-else class="text">{{this.view.btmname ? this.view.btmname:'NA'}}</small>
                          </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                           <em class="las la-id-card font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Micro Task Name</h6>
                          <small>{{this.view.taskname ? this.view.taskname: 'NA'}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-danger rounded">
                            <div class="avatar-content">
                              <em class="las la-boxes font-medium-5"></em>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 v-if="this.editRowData.projectid == merchandisingProId && this.issue.sectionmodulename == sectionmoduleId1" class="transaction-title">Product ID</h6>
                            <h6 v-else-if="this.editRowData.projectid == merchandisingProId && this.issue.sectionmodulename == sectionmoduleId2" class="transaction-title">Product Group ID</h6>
                            <h6 v-else class="transaction-title">Functionality</h6>
                            <small>{{this.view.functionlityname}}</small>
                          </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-info rounded">
                          <div class="avatar-content">
                           <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Project Name</h6>
                          <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttoPNM('view', {moduleid:view.projectid})">{{this.view.projectname}}</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-success rounded">
                          <div class="avatar-content">
                            <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Brand</h6>
                          <small>{{this.getMRPDetails[0].projectmodulename}}</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-hdd las lab font-medium-5"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Category</h6>
                            <small>{{this.getMRPDetails[0].applicationname}}</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-info rounded">
                            <div class="avatar-content">
                              <em class="i_icon_st la-buffer lab font-medium-5" style="font-weight: 500;"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Sub Category</h6>
                            <small>{{this.getMRPDetails[0].applicationmodulename}}</small>
                        </div>
                      </div>
                    </div>
                    <div v-if="this.editRowData.projectid == merchandisingProId" class="transaction-item">
                        <div class="media">
                          <div class="avatar bg-light-primary rounded">
                            <div class="avatar-content">
                              <em class="las la-box font-medium-5"></em>
                            </div>
                          </div>
                        <div class="media-body">
                            <h6 class="transaction-title">Product ID/ Product Group ID</h6>
                            <small>{{this.getMRPDetails[0].sectionname}}</small>
                        </div>
                        </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <em class="las la-crosshairs font-medium-5"></em>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">QA Cycle</h6>
                          <small>{{this.view.qacycle}}</small>
                        </div>
                      </div>
                    </div>
                  </div>                                                                
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                  <div class="">
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Assigned To</h6>
                          <small class="capitalisetext">{{this.view.empfullname}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created By</h6>
                          <small>{{this.view.createdby}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Created Date</h6>
                          <small>{{dateMonthFormat(this.view.createddate)}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-primary rounded">
                          <div class="avatar-content">
                            <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified By</h6>
                          <small>{{this.view.lastmodifiedby}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Last Modified Date</h6>
                          <small>{{dateMonthFormat(this.view.lastmodifieddate)}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="transaction-item" v-if="editRowData.vendorcode">
                      <div class="media">
                        <div class="avatar bg-light-warning rounded">
                          <div class="avatar-content">
                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Due Date</h6>
                          <small v-if="view.duedate">{{dateMonthFormat(this.view.duedate)}}</small>
                          <small v-else>NA</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <div>
                <div class="media-list media-bordered  issueswarp-listview">
                        <div class="media pl-0">
                          <div class="media-left">
                            <div class="avatar bg-light-info rounded avatar-lg">
                            <div class="avatar-content">
                              <package-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></package-icon>
                            </div>
                            </div>
                          </div>

                          <div class="media-body">
                            <h6 class="transaction-title">Reason of Issue	</h6>
                            <span class="disc" v-html="this.issue.reasonofissue"></span>
                          </div>
                        </div>

                        <div class="media pl-0">
                          <div class="media-left">
                            <div class="avatar bg-light-info rounded avatar-lg">
                              <div class="avatar-content">
                                <package-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></package-icon>
                              </div>
                            </div>
                          </div>
                          <div class="media-body">
                            <h6 class="transaction-title">Impact of Issue	</h6>
                            <span class="disc" v-html="this.issue.impactofissue"></span>
                          </div>
                      </div>

                      <div class="media pl-0">
                        <div class="media-left">
                          <div class="avatar bg-light-warning rounded avatar-lg">
                            <div class="avatar-content">
                              <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                            </div>
                          </div>
                        </div>
                        <div class="media-body">
                          <h6 class="transaction-title">Issue Scenario</h6>
                          
                          <!-- <span class="disc table_view_scenario issue_details_dis_scenario" v-html="this.issue.scenario"></span> -->
                          <div class=" tast_case_groupingview_readmore mx-0">
                              <div class="col-md-12 px-0 micro_case_groupingview_readmore">
                                  <div class="custom-container case_bg_17">
                                      <div v-if="this.issue.scenario" v-bind:class="[readmore]" :id="'cycle_readmore5'">
                                          <span class="fc_m16_btcode_text" v-html="formatData(this.issue.scenario)"></span>
                                          
                                      </div>
                                      
                                      <div class="cycle_viewmoretext" v-if="this.issue.scenario && this.issue.scenario.length > 50">
                                          <a class="btn">
                                          <strong :id="'id5'" @click="idclick5(indexx)">
                                            Read More
                                        </strong>
                                          </a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      </div>

              </div>
              
              <div v-if="issue.history" class="col-lg-12 col-md-6 col-12" style="display:none;">
                  <div class="card-body">
                    <h5 class="mb-1">History</h5>
                    <div class="row tbl">
                      <div class="table-responsive mb-1">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                          <thead>
                            <tr>
                              <th scope="col">SR#</th>
                              <th scope="col">Status</th>
                              <th scope="col">Assigned To</th>
                              <th scope="col">Action Date</th>
                              <th scope="col">Assigned BY</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data,index) in (issue.history ? issue.history:[])" v-bind:key="index">
                              <td>{{index+1}}</td>
                              <td>{{data.status}}</td>
                              <td>{{data.assignedto}}</td>
                              <td>{{dateMonthFormat(data.lastmodifieddate)}}</td>
                              <td>{{data.lastmodifiedby?data.lastmodifiedby.split("@")[0]:''}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="issue.attachmentview != null">
          <div class="card">
              <div class="card-header px-2 py-1 border-bottom">
                  <h4 class="card-title">Document List</h4>
              </div>
              <div class="card-body pt-1 pb-0">
                  <div class="row">
              <div class="col-md-12">
                  <div class="row documentGrid match-height">
                      <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(data,index) in (issue.attachmentview ? issue.attachmentview.data:[])" v-bind:key="index">
                          <div class="card card-bordered mb-50">
                            <div class="text-center py-2" style="background:#F8F8F8;">
                              <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                              <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                              <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                              <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                              <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4' || 'webm'" src="../../../public/images/icons/ic-video.png" height="35"  />
                              <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                              <a :href="data.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                              <a v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(data.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                              <a v-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(data.presignurl)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                            </div>
                              <a :href="data.presignurl" class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">{{data.link | basename}}</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
              </div>
          </div>
        </div>

        <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">              
              <div class="modal-body p-0">
                <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                <img  alt="" src="" class="imagepreview"  >
              </div>
            </div>
          </div>
        </div>
        <div class="customTimeline" v-for="(data,index) in logtimehistory" v-bind:key="index">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                <div class="text-muted mb-1" style="margin-top:-3px">
                  Updated on: {{dateMonthFormat(data.lastmodifieddate)}} <span class="mx-50">|</span> by <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                  <img alt="" v-if="!data.imagename" :src="defaultimagepath"  width="25" height="25" /> <strong class="text-secondary" style="text-transform: capitalize;">{{(data.createdby)}}</strong> <span class="text-muted text-nowrap" style="text-transform: capitalize;">{{data.descdesignation}}</span>
                  <span class="mx-50">|</span>
                  <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.status}}</span>
                  <span class="capitalisetext badge badge-pill badge-light-primary mx-50">{{data.activity}}</span>
                </div>
              </div>

              <div class="d-inline-block" v-if="data.coments">
                <div for="" class="d-inline-flex mr-50 mb-0">Comment: </div>
                <div class="d-inline-flex textlinline_wrap">
                  <div v-html="data.coments"></div>
                </div>
                </div>
                    <div class="row">
                    <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(res,index) in (data.attachment ? data.attachment.data:[])" v-bind:key="index">
                      <div class="card card-bordered mb-0">
                          <div class="text-center py-2" style="background:#F8F8F8;">
                            <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4' || 'webm'" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                            <a v-on:click="getLogtimeDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                            <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                            </div>
                             <a :href="res.link"  class="py-50 px-50 border-top " style="font-size:0.85rem; color:#888;"  target="_blank">
                              <span v-if="res.info">
                                {{res.info}} -123
                              </span>
                              <span v-else-if="!res.info">
                                {{res.link | basename}}
                              </span>
                              </a>
                            </div>
                      </div>
                      </div>
                      <hr class="mt-0" />

                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                  <div class="media align-items-center">
                    <span class="pr-1 text-muted  d-inline-flex">
                        <user-plus-icon size="1.5x" class="custom-class avatar-icon font-medium-3 mr-50"></user-plus-icon> 
                        Assigned To</span>
                    <div class="avatar">
                      <profilepic v-if="data.assignedtoimagename" v-bind:get-url="'empprofile/'+data.assigntoempcode+'/'+data.assignedtoimagename" :key="`${componentKey + index}`"></profilepic>
                      <img alt="" v-if="!data.assignedtoimagename" :src="defaultimagepath" width="36" height="36" />
                    </div>
                    <div class="media-body ml-50">
                      <h6 class="mb-0 text-nowrap" style="text-transform: capitalize;">{{data.assigend}}</h6>
                      <span class="text-muted text-nowrap">{{decryptText(data.decdesignation)}}</span>
                    </div>
                  </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
          <div>&nbsp;</div>
        <div v-bind:class="[{display:dislogActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor:pointer">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-9">
                  <div class="avatar bg-light-primary rounded d-inline-flex mr-2" >
                    <div class="avatar-content">
                        <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                    </div>
                  </div>
                  <h4 class="card-title text-primary d-inline-flex">Update Log Time</h4>
                </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isLogActive},card_content,collapse]" >
            <div class="card-body">
              <form class="form form-vertical">
                <div class="row">
                  <div class="form-group col-md-3">
                      <div class="form-group mg-bot0">
                      <label class="form-label" for="spendtime">Spent Time</label>
                      <div id="spendtime">
                      <vue-timepicker type="time" v-model="logtime.spenttime" format="HH:mm"  @close="validatelog()"></vue-timepicker>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.spenttime">{{ this.errors.spenttime }}</div>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                      <label class="form-label">Activity</label>
                      <treeselect placeholder="Select Activity"  class="projectmdllistdd capitalisetext" v-model="logtime.activity" :options="logtime.acivitylist" @input="selissuelogactivity" @close="validatelog()"/>
                      <div class="errorinputmsg" v-if="this.errors.activity">{{ this.errors.activity }}</div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                        <label class="form-label">Assigned To</label>
                        <treeselect placeholder="Enter Min 4 Chars To Search" class="allotedto capitalisetext" v-model="logtime.allotedto" :options="issue.allotedtolist" @input="selallotedto" @close="validatelog()" v-on:search-change="getEmpallocatedtoProject"/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.allotedto">{{ this.errors.allotedto }}</div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group mg-bot0">
                        <label class="form-label">Status</label>
                        <input placeholder="Select Status" class="form-control capitalisetext" v-model="logtime.status" :options="issue.statuslist" @input="selogissuecsstatus" @close="validatelog()" v-if="issue.statuslist.length<=1" disabled/>
                        <treeselect placeholder="Select Status" class="projectmdllistdd capitalisetext" v-model="logtime.status" :options="issue.statuslist" @input="selogissuecsstatus" @close="validatelog()" v-else/>
                    </div>
                    <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                  </div>
                  <div class="col-md-3" v-if="editRowData.vendorcode">
                      <div class="form-group">
                        <label for="logtimeduedate">Due Date</label><br/>
                        <date-picker placeholder="Select Due Date" v-model="logtime.duedate" valueType="format" class="startdate" :disabled-date="notBeforeToday" v-on:pick="validateForm('t42',$event)"></date-picker>
                      </div>
                        <div class="errorinputmsg" v-if="this.errors.duedate">{{ this.errors.duedate }}</div>
                  </div>
                  <div class="col-12">
                    <div class="form-group mg-bot0">
                      <label for="reasonofissue">Comment</label>
                      <ckeditor :editor="editor" :config="editorConfig" id="reasonofissue" class="form-control" name="" placeholder="Enter Comments" v-model="logtime.comment"
                        v-bind:class="{ 
                          'form-control': true,
                          'is-invalid': !validText(logtime.comment) && commentBlured, 
                        }" v-on:blur="commentBlured = true"/>
                        <div class="invalid-feedback">Comment Required</div>
                    </div>
                  </div>
                  <div class="col-md-6" style="margin-top:24px">
                    <div class="form-group">
                      <div class="form-group">
                        <label for="attachment" class="btn-sm btn btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                        <div class="table-responsive" v-if="file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button class="btn btn-relief-secondary btn-sm mb-75 mr-75 "  v-on:click.stop.prevent="onUpload()" >Upload</button>
                        <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mg-top1 text-right">
                    <button type="button" class="btn btn-relief-primary mr-1" @click="savelogData()" v-bind:disabled="issue.disblesavebtn">Submit</button>
                    <button type="reset" class="btn btn-outline-secondary" @click="handleCancel2()">Cancel</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header" style="cursor:pointer" v-on:click="myrequestollapse">
            <div class="container-fluid">
              <div class="row">
                  <div class="col-9">
                    <div class="avatar bg-light-primary rounded d-inline-flex" >
                          <div class="avatar-content">
                              <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                          </div>
                      </div>
                      <h4 class="card-title d-inline-flex text-primary ml-1 text-primary-dark ">{{this.formActionLabel}}</h4>
                  </div>
              </div>
              </div>
          </div>
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
              <div class="card-body">
                <form class="form form-vertical">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mg-bot0">
                        <label for="projectlistdd">Issue Name</label>
                        <input type="text" id="issuename" class="form-control" name="" placeholder="Issue Name" v-model="issue.issuename" @blur="validateForm('t42',$event)"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.issue.issuename">{{ this.errors.issue.issuename }}</div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mg-bot0">
                        <label for="projectmdllistdd">Project Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" v-model="issue.project" :options="issue.projectlist" @input="selissuecsproject" @close="nullifybusinessfields()"/>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.issue.project">{{ this.errors.issue.project }}</div>
                    </div>
                    <div class="col-md-6" v-if="!this.isThisBusinessRole">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Functionality</label>
                            <treeselect class="projectmdllistdd capitalisetext" v-model="issue.functionality" :options="issue.functionalitylist" @input="selissuecsfunctionality" @close="validateForm('t42',$event)"/>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="!this.isThisBusinessRole">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Micro Task Name</label>
                            <treeselect class="projectmdllistdd capitalisetext" v-model="issue.task" :options="issue.tasklist" @close="validateForm('t42',$event)"/>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="!this.isThisBusinessRole">
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Business Task Code</label>
                      <treeselect class="capitalisetext" placeholder="Enter Business Task Code" :disabled="isBtmFieldDisabled" v-model="issue.businesstaskcode" :options="issue.businesscodelist" @input="selbtmcode" @close="validateForm('t47',$event)"/>
                    <div class="errorinputmsg" v-if="(this.errors.issue.businesstaskcode) && !isBtmFieldDisabled">{{ this.errors.issue.businesstaskcode }}</div>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="!this.isThisBusinessRole">
                    <div class="form-group mb-1">
                      <label for="projectmdllistdd">Business Task Name</label>
                      <treeselect class="capitalisetext" placeholder="Enter Business Task Name" :disabled="isBtmFieldDisable" v-model="issue.businesstaskid" :options="issue.businesstasklist" @input="selbtmid" @close="validateForm('t47',$event)"/>
                      <div class="errorinputmsg" v-if="(this.errors.issue.businesstaskid) && !isBtmFieldDisable">{{ this.errors.issue.businesstaskid }}</div>
                    </div>
                  </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Issue Type</label>
                            <treeselect class="projectmdllistdd capitalisetext" v-model="issue.type" :options="issue.typelist" @input="selissuecstype" @close="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.type">{{ this.errors.issue.type }}</div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Issue Severity</label>
                            <treeselect class="projectmdllistdd capitalisetext" v-model="issue.sevairty" :options="issue.sevairtylist" @input="selissuecssevairty" @close="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.sevairty">{{ this.errors.issue.sevairty }}</div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Issue Priority</label>
                            <input v-if="issue.moduletype=='ticket'" type="text" class="form-control" name="" v-model="issue.priority" disabled/>

                            <treeselect v-else class="projectmdllistdd capitalisetext" v-model="issue.priority" :options="issue.prioritylist" @input="selissuecspriority" @close="validateForm('t42',$event)" />
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.priority">{{ this.errors.issue.priority }}</div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mg-bot0">
                            <label for="allotedto">Assigned To</label>
                            <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search" v-model="issue.allotedto" :options="issue.allotedtolist" @input="selallotedto" @close="validateForm('t42',$event)" v-on:search-change="getEmpallocatedtoProject"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.allotedto">{{ this.errors.issue.allotedto }}</div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group mg-bot0">
                            <label for="">Subscribers</label>
                            <treeselect class="projroleassprojectdd capitalisetext" placeholder="Enter Min 4 Chars To Search" :multiple="true" :clear-on-select="true" :options="issue.subscriberslist" v-model="issue.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)" v-on:search-change="getEmpallocatedtoProject"/>
                        </div>
                           <div class="errorinputmsg" v-if="this.errors.issue.subscribers">{{ this.errors.issue.subscribers }}</div>
                    </div>
                    <div class="form-group col-12">
                        <label class="form-label" for="projectmdllistdd">Issue Scenario</label>
                        <ckeditor :editor="editor"  placeholder="Issue Scenario" v-model="issue.scenario" :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"></ckeditor>
                        <div class="errorinputmsg" v-if="this.errors.issue.scenario">{{ this.errors.issue.scenario }}</div>
                    </div>
    
                      <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="reasonofissue">Reason of Issue</label>
                            <ckeditor :editor="editor" id="reasonofissue" class="form-control" name="" placeholder="Reason of Issue" v-model="issue.reasonofissue" 
                            :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.reasonofissue">{{ this.errors.issue.reasonofissue }}</div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="impactofissue">Impact of Issue</label>
                            <ckeditor :editor="editor" id="impactofissue" class="form-control" name="" placeholder="Impact of Issue" v-model="issue.impactofissue" 
                            :config="editorConfig" tag-name="textarea" @blur="validateForm('t42',$event)"/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.impactofissue">{{ this.errors.issue.impactofissue }}</div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group mg-bot0">
                            <label for="projectmdllistdd">Status</label>
                            <input placeholder="Select Status" class="form-control capitalisetext" v-model="issue.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validateForm('t42',$event)" v-if="issue.statuslist.length<=1" disabled/>
                            <treeselect class="projectmdllistdd capitalisetext" v-model="issue.status" :options="issue.statuslist" @input="selissuecsstatus" @close="validateForm('t42',$event)" v-else/>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.status">{{ this.errors.issue.status }}</div>
                    </div>
                    <div class="col-md-6" v-if="editRowData.vendorcode">
                      <div class="form-group">
                        <label for="duedate">Due Date</label><br/>
                        <date-picker placeholder="Select Due Date" v-model="issue.duedate" valueType="format" class="startdate" :disabled-date="notBeforeToday" v-on:pick="validateForm('t42',$event)"></date-picker>
                      </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.duedate">{{ this.errors.issue.duedate }}</div>
                    </div>
                    <div class="col-md-12">
                        <label for="attachment" class="btn btn-sm btn btn-relief-secondary mb-75 mr-75">Select image/docs/videos</label>
                        <button class="btn btn-sm btn-relief-secondary mb-75 mr-75"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                        <input type="file" id="attachment" hidden multiple :accept="this.accepttype" ref="file" @change="onFileChange"/>
                        <div class="table-responsive" v-if="issue.attachmentview">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, k) in (issue.attachmentview ? issue.attachmentview.data:[])" :key="k">
                                        <td>{{k+1}}</td>
                                        <td>
                                          <label>
                                    <div class="click-zoom">
                                      <input type="checkbox" />
                                          <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  :src="data.presignurl"  style="width:50px;height:50px"  />
                                          <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/pdf.png" style="width:50px;height:50px" />
                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xlsx'||'csv'||'xls'" src="../../../public/images/icons/xls.png" style="width:50px;height:50px" />
                             &nbsp;
                                             <a :href="data.presignurl" target="_blank">{{data.link | basename}}</a>
                                    </div>
                                          </label>
                                        </td>
                                        <td>{{data.info}}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removes3file(k,issue.attachmentview.data)">
                                              <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="errorinputmsg" v-if="this.errors.issue.attachment">{{ this.errors.issue.attachment }}</div>
                        <div class="table-responsive" v-if="file.length>0">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">SR#</th>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(image, key) in file" :key="key">
                                        <td>{{ key+1 }}</td>
                                        <td>{{ image.name }}</td>
                                        <td>
                                            <input type="text" placeholder="Optional description" v-model="issue.filedescp[key]"/>
                                            &nbsp;&nbsp;
                                            <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 mg-top1">
                        <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t42',$event)" id="submitbtnt42" v-bind:disabled="issue.disblesavebtn">Submit</button>
                        <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                    </div>
                  </div>
                </form>
              </div>
          </div>
        </div>
      </section>

      <section id="card-navigation-issue" class="taskList" v-if="bissuelist.length || tasksearch == true">
        <div class="row match-height">
            <div class="col-md-12">
                <div class="card mb-3 ">
                    <div class="container text-editar_discription_jobs px-2">
                        <ul class="nav nav-pills nav-pill-warning mb-0 mt-1  business_carateria_tabbbing_diss" id="nav-tabs" role="tablist">
                            <li class="nav-item" v-if="bissuelist.length">
                                <a class="nav-link" v-bind:class="{'active': bissuelist.length }" id="tasklist-tab" data-toggle="tab" href="#tasklist" role="tab" aria-controls="tasklist" aria-selected="true">Blocker Issue List - {{ t_totalcount }}</a>
                            </li>
                            <li class="nav-item" v-if="bissuelist.length==0 && tasksearch == true">
                                <a class="nav-link" v-bind:class="{'active': bissuelist.length==0 }" id="tasklist-tab" data-toggle="tab" href="#tasklist" role="tab" aria-controls="tasklist" aria-selected="true">Blocker Issue List - {{ t_totalcount }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body" v-if="bissuelist.length || tasksearch == true">
                        <div class="tab-content" id="myTabContent">
                            <div v-if="(tasksearch == true && bissuelist.length == 0) || bissuelist.length" class="tab-pane fade" v-bind:class="{'show':bissuelist.length || tasksearch == true, 'active': bissuelist.length || tasksearch == true}" id="tasklist" role="tabpanel" aria-labelledby="tasklist-tab">
                                
                                <div class="row">
                                   
                                
                                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <div class="nav-item nav-search mr-50 search_filtter_animation">
                                        <div class="search">
                                            <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchTerm" @input="inputsearchtask"/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                                <div class="table-responsive">
                                    <table class="table table-sm dataTableListing table-hover table-striped" aria-describedby="mydesc">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="align-middle py-1">Sr#</th>
                                                <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" style="width:20%" @click="getsorting('Issue Code')">Issue Code</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Issue Name')">Issue Name</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Project Name')">Project Name</th>
                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Related To')">Related To</th>
                                                <th scope="col" class="align-middle">Type</th>
                                                <th scope="col" class="align-middle">Status</th>
                                                <th scope="col" class="align-middle">Priority</th>
                                                <th scope="col" class="align-middle">Created BY/Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-for="(data, index) in bissuelist">
                                                <tr v-bind:key="data.issuecode">
                                                    <td class="srno">{{ index+1+t_currPageNum*t_pagelimit }}</td>
                                                    <td><span class="font-weight-bold capitalisetext">{{data.issuecode}}</span></td>
                                                    <td><span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirectToTask('view', data.id)">{{ data.issuename }}</span></td>
                                                    <td style="text-transform: capitalize;">{{data.projectname}}</td>
                                                    <td style="text-transform: capitalize;">{{data.status}}</td>
                                                    <td style="text-transform: capitalize;">{{data.tasktype}}</td>
                                                    <td style="text-transform: capitalize;">{{data.issuestatus}}</td>
                                                    <td style="text-transform: capitalize;">{{data.priority}}</td>
                                                    <td class="details_column_table">
                                                        {{data.createdby.split('@')[0]}}
                                                        <hr class="hr_border-bottom" />
                                                        <span> {{dateMonthFormat(data.createddate)}} </span>
                                                    </td>                                                </tr>
                                            </template>
                                            <template v-if="bissuelist.length == 0">
                                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="pageCenter text-center mb-1">
                                        <pagination v-if="t_showtabledata" v-model="t_page" :records="t_totalcount" :per-page="t_pagelimit" @paginate="clickCallback_tasklists"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>

      <section id="card-navigation" class="addBlockerTaskDiv" >
          <div v-bind:class="[{show: disBlockerActive},card_content,collapse]" class="row match-height">
                <div class="col-md-12">
                    <div class="card mb-3 ">
                        <div class="card-body">
                            <h4 class="card-title d-inline-flex text-primary text-primary-dark" >Add Blocker</h4>
                            <form class="form form-vertical update_Log_btTime">
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label for="projectlistdd">Select Blocker Issues</label>
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow p-0 ml-1" data-toggle="dropdown" data-boundary="window">
                                                <em class="las la-info-circle text-warning font-medium-2"></em>
                                            </button>
                                            <div class="dropdown-menu col-md-3" style="margin-left: -135px">
                                                <a class="dropdown-item text-wrap">
                                                    <span>Note* All Issue status which are not in (closed, deferred, not a bug, rejected, fixed)</span>
                                                </a>
                                            </div>
                                            <treeselect class="projectlistdd capitalisetext" placeholder="Enter min 4 chars to search Blocker Issue Name/Code" :clear-on-select="true" v-model="blockertaskdetails.blockerissues" :options="tasksblockerlist" :multiple="true" @input="seltBlockerIssuess" @close="validateBlocekrForm('t9',$event)" v-on:search-change="getBTaskListToTagBlocker"/>
                                            <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.blockerissues">{{ this.blockertaskdetails.errors.blockerissues }}</div>
                                    </div>

                                    <div class="form-group col-md-3">
                                        <label for="Department">Related To</label>
                                        <treeselect placeholder="Select Related To" class="projectmdllistdd capitalisetext" :options="blockertaskdetails.blockertypes" @input="seltaskblockerstatus" v-model="blockertaskdetails.status" @close="validateBlocekrForm('t9',$event)" />
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.status">{{ this.blockertaskdetails.errors.status }}</div>
                                    </div>
                                    
                                    <div class="form-group col-12">
                                        <label class="form-label">Comment</label>
                                        <ckeditor :editor="editor" placeholder="Comment" v-model="blockertaskdetails.comment"  @input="seltaskblockercomments" :config="editorConfig" tag-name="textarea" @close="validateBlocekrForm('t9',$event)"></ckeditor>
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.comment">{{ this.blockertaskdetails.errors.comment }}</div>
                                    </div>

                                    <div class="col-12 mg-top1 text-right">
                                        <button type="button" class="btn btn-relief-primary mr-1" @click="saveBlockerTasks()" :disabled="isdisabled">Submit</button>
                                        <button type="reset" class="btn btn-outline-secondary" @click="handleLogCancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
  </div>
</template>
<style>

.issue-view-title {
  background-color: #f8f9fa;
  padding: 6px 24px;
  font-weight: bold;
  margin-right: 20px;
}
.card .card-header{
  padding: 0.8rem;
}
.createbtnparentdiv{
  padding-right: 0% !important;
}
.displayblk{
  display:block !important;
}
.createbtn{
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
.tbl {
  margin-left: 0 !important;
}

.disc p:last-child{
  margin-bottom: 0;
}
.d-table_icon_up.bussiness{
  cursor: pointer;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import { PaperclipIcon,WatchIcon,EditIcon, MinusSquareIcon, Trash2Icon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon, AlertCircleIcon,UserPlusIcon,ZoomInIcon,XIcon,FileTextIcon} from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import presignurl from '../getPresignurl.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import commonMethods from '../../utils/commonMethods';
export default {
  name:'IssueList',
  mixins: [ commonMethods ],
  components:{
    VueTimepicker,
    Loading,WatchIcon,PaperclipIcon,profilepic,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Trash2Icon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    DownloadIcon,
    Edit2Icon,
    PlusSquareIcon,
    ClipboardIcon,
    UploadCloudIcon, MinusSquareIcon, CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,PenToolIcon,
    AlertCircleIcon,
    UserPlusIcon,
    ZoomInIcon,
    DatePicker,
    XIcon,
    ckeditor: CKEditor.component,
    FileTextIcon,
    presignurl
  },    
  data() {
    return {
      isThisBusinessRole: false,
      roleid: 0,
      componentKey: 0,
      defaultimagepath:apiUrl.defaultimagepath,
      imgExt:['jpeg','jpg','png'],
      isRequestActiveView: true,
      dislogActive:true,
      disBlockerDivActive:true,
      isBlockerDvActive:false,
      isLogActive:false,
      formActionLabel: 'Create Issue',
      disRequestActive: true,
      isDetActive:false,
      isRequestActive:true,
      isdocsRequestActive:false,
      isEditDocsRequestActive: false,
      addocument:true,
      editdocument: true,
      isdisabled: false,
      isActive:true,
      files:[],
      doclist:[],
      editdoclist:[],
      alldocs:[],
      attachment: [],
      docs:[{
        title:null,
        docs:null,
        comment:null,
        attachment:null
      }],
      backpagedata: [],
      loginuser:'',
      isLoading: false,
      fullPage: true,
      s3url: apiUrl.s3url,
      stylevariation:apiUrl.stylevariation,
      companymasterlist: [],
      editRowData:[],
      glbMdl:'',
      glbMdlLbl:'issue',
      TimeFormat: apiUrl.TimeFormat,
      issue:{
        history:[],
        issuename:null,
        project:null,
        projectlist:null,
        subscriberslist: [],
        subscribers: null,
        functionality:null,
        functionalitylist:null,
        duedate:null,
        type:null,
        typelist:null,
        scenario:null,
        scenariolist:null,
        attachment:null,
        attachmentview:null,
        attachmentnew:null,
        sevairty:null,
        sevairtylist:null,
        priority:null,
        businesstaskid:null,
        businesstaskcode:null,
        businesstasklist : [],
        businesscodelist : [],
        projectmodulename: null,
        applicationname: null,
        applicationmodulename: null,
        sectionmodulename: null,
        prioritylist:[{
            id:'low',
            label:'Low'
        },{
            id:'medium',
            label:'Medium'
        },{
            id:'high',
            label:'High'
        }],
        allotedto:null,
        allotedtolist:[],
        reasonofissue:null,
        impactofissue:null,
        status:null,
        statuslist: [],
        iseditstate:false,
        disblesavebtn:false,
        task: null,
        tasklist: [],
        filedescp:[],
        moduletype:null
      },
      file:[],
      isAddState:false,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      showtabledata:true,
      showproject:false,
      modulelist:[],
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      readmore:'outer',
      errors:{
        spenttime:"",
        activity:null,
        status:null,
        allotedto:null,
        duedate: null,
        comment: null,
        issue:{
          issuename:null,
          project:null,
          functionality:null,
          type:null,
          scenario:null,
          attachment:null,
          sevairty:null,
          priority:null,
          allotedto:null,
          reasonofissue:null,
          duedate:null,
          impactofissue:null,
          status:null,
          task: null,
          subscribers: null,
          businesstaskid:null,
          businesstaskcode:null
        },
        docs:{
          title:null,
          docs:null,
          comment:null,
          attachment:null
        } 
      },
      isedit: false,
      card:'card',
      border_warning:'border-warning',
      search:{
        searchprojectname :null,
        functionality: null,
        task:null,
        issuename:null,
        type:null,
        sevairty:null,
        priority:null,
        status:null,
        allotedto:null,
        projectnameList: [],
        functionalitylist: [],
        allotedtolist: [],
        tasklist: [],
      },
      projectnameList:[],
      issuenameList:[],
      view:{
        issueid: null,
        projectname: null,
        functionlityname: null,
        typename: null,
        sevairtyname: null,
        taskname: null,
        btmname:null,
        businessid: null,
        createdby: null,
        empfullname: null,
        issuecode: null,
        documentlist: [],
        createddate: null,
        lastmodifiedby: null,
        lastmodifieddate: null,
        duedate:null,
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|', 'bold',
            'italic', '|', 'bulletedList',
            'numberedList', '|', 'insertTable', '|',
            'undo', 'redo', '|',
          ],
        },
      },
      s3bucket: apiUrl.s3url ,
      issuenameBlured:false,
      scenarioBlured:false,
      commentBlured:false,
      tokendata:null,
      logtime:{
        acivitylist:apiUrl.acivitylist,
        spenttime:"",
        activity:null,
        allotedto:null,
        status:null,
        comment:null,
        attachmentview:[],
        isTimeLogged: false,
        duedate:null,
      },
      loginusername:'',
      logtimehistory:[],
      imagext: ['jpg', 'jpeg', 'png'],
      isBtmFieldDisabled: false,
      isBtmFieldDisable: false,
      accepttype:apiUrl.uploadfiletype,
      userrole:null,
      merchandisingProId: apiUrl.merchandisingProId,
      sectionmoduleId1: apiUrl.sectionmoduleId1,
      sectionmoduleId2: apiUrl.sectionmoduleId2,
      getMRPDetails: [],
      validFileExtensions:apiUrl.validFileExtensions,
      blockertaskdetails: {
          projectid: null,
          comment: null,
          btctaaskid: null,
          status: null,
          blockerbtcs: null,
          blockerissues: null,
          moduletype: 'businesstask',
          errors: {
              projectid: null,
              comment: null,
              btctaaskid: null,
              status: null,
              btcItemsErr: null,
              blockerissues: null,
          },
          blockertypes: [
          { id: 'Blocks', label: 'Blocks' },
          { id: 'Is Blocked By', label: 'Is Blocked By' },
          { id: 'Clones', label: 'Clones' },
          { id: 'Is Cloned By', label: 'Is Cloned By' },
          { id: 'Duplicates', label: 'Duplicates' },
          { id: 'Is Duplicated By', label: 'Is Duplicated By' },
          { id: 'Relates To', label: 'Relates To' }
          ],
      },
      disBlockerActive: false,
      tasksblockerlist: [],
      bissuelist: [],
      tasksearch: null,
      t_totalcount: 0,
      t_pageCount: 0,
      t_page:1,
      t_pagelimit:apiUrl.LIMIT,
      t_currPageNum: 0,
      t_pageoffset:1,
      t_pagefeatures:[],
      t_showtabledata:true,
      searchTerm: null,
      tasklistoOrderBy : [
          {id: 'status', label:'status'},
          {id: 'project', label:'project'},
          {id: 'name', label:'name'},
          {id: 'code', label:'code'},
          {id: 'priority', label:'priority'},
      ],
      orderBy: {
          task: null,
          issue: null,
          bloker: null,
      },
    }
  },
  created() {
    this.pagefeatures = {}
    if(window.localStorage.getItem('pagefeatures')){
      let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/issues/list') {
          this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    }
  },
  mounted(){
    window.addEventListener('storage', function(event){
        if (event.key == 'logout-mee') { 
            window.location.reload();
        }
    });
    let userdata = window.localStorage.getItem('userdata');
    if(userdata){
      userdata = JSON.parse(userdata)
      this.undt=userdata
      this.loginusername=userdata.username
      this.loginuscondition = userdata.userid
      this.tokendata = window.localStorage.getItem('token');
      this.userrole = window.localStorage.getItem("userrole")
      this.roleid = this.undt.roleid
      this.getProjectAllocatedToEmp()
      this.getIssueById(this.$route.query.issueid)
      if(this.roleid && parseInt(this.roleid) === 520) {
        this.isThisBusinessRole = true
      }
      if(!this.isThisBusinessRole) {
        this.getApplicationdata('t43','issue')
      } 
      this.getApplicationdata('t44','issue')
      this.getSubscribers(this.$route.query.issueid, 'issue')
      this.getbusinesstaskname(this.issue.project, 'issue')
      this.getbusinesstaskcode(this.issue.project, 'issue')
      this.getBlockerIssueListt(this.$route.query.issueid);
      this.getsorting();
    }
  },
 /* updated(){
      var userdata = window.localStorage.getItem('userdata');
      if(!userdata){
        this.$router.push('/login');
      }
  },*/
  methods:{
    formatName(authorstring) {
        let author=authorstring.replace('@firstcry.com','')
        if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
        author = author.replace('.',' ')
        author = author.charAt(0).toUpperCase() + author.slice(1);
        return author
    },
    inputsearchtask(){
        if (this.searchTerm !== null)
        this.tasksearch = true
        this.getBlockerIssueListt(this.$route.query.issueid)
    },
    diffBetweenDates(d2, d1) {
        let date1 = moment(d1)
        let date2 = moment(d2)
        let years = date1.diff(date2, 'year');
        date2.add(years, 'years');

        let months = date1.diff(date2, 'months');
        date2.add(months, 'months');

        let days = date1.diff(date2, 'days');
        date2.add(days, 'days');

        let hours = date1.diff(date2, 'hours');
        date2.add(hours, 'hours');

        let minutes = date1.diff(date2, 'minutes');
        date2.add(minutes, 'minutes');
        let result = ''
        if(years) {
            if(years > 1) {
                result += `${years} Years `
            } else {
                result += `${months} Year `
            }
        }
        if(months) {
            if(months > 1) {
                result += `${months} Months `
            } else {
                result += `${months} Month `
            }
        }
        if(days) {
            if(days > 1) {
                result += `${days} Days `
            } else {
                result += `${days} Day `
            }
        }
        if(hours) {
            if(hours > 1) {
                result += `${hours} Hours `
            } else {
                result += `${hours} Hour `
            }
        }

        if(minutes) {
            if(minutes > 1) {
                result += `${minutes} Minutes`
            } else {
                result += `${minutes} Minute`
            }
        }
        return result
    },
    clickCallback_tasklists: function (pageNum) {
        this.t_pageoffset = pageNum;
        this.t_currPageNum = pageNum - 1;

        this.getBlockerIssueListt(this.$route.query.issueid)
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#card-navigation").offset().top -70
            });
        }, 500);
    },
    notBeforeToday: function (date) {
        this.issue.duedate = "";
        this.logtime.duedate = "";
        return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    forceRerender() {
      this.componentKey += 1;
    },
    seltaskblockercomments(state) {
        this.blockertaskdetails.comment = null
        this.blockertaskdetails.comment = state
        if(!state) {
            this.blockertaskdetails.errors.comment = "Comment is required"
        } else {
            this.blockertaskdetails.errors.comment = null
        }
    },
    seltaskblockerstatus(state) {
        this.blockertaskdetails.status = null
        this.blockertaskdetails.status = state
        if(!state) {
            this.blockertaskdetails.errors.status = "Related to is required"
        } else {
            this.blockertaskdetails.errors.status = null
        }
    },
    seltBlockerIssuess(state) {
        this.blockertaskdetails.blockerissues = null
        this.blockertaskdetails.blockerissues = state
       
        if(!state) {
            //this.blockertaskdetails.projectid = null
            this.blockertaskdetails.errors.blockerissues = "Blocker Issue is required"
        } else {
            this.blockertaskdetails.errors.blockerissues = null
            let pid = this.tasksblockerlist.filter(itm => itm.id == state)
            if(pid.length>0) {
                this.blockertaskdetails.projectid = pid[0].projectid
            }
        }
    },
    openBlockerIssuesList() {
      $('#card-navigation-issue').show();
      this.isRequestActive=false;
      $('html, body').animate({ scrollTop: $(document).height() }, 1200);
    },
    selectTaskOrderBy(state) {
      this.getBlockerIssueListt(this.$route.query.issueid, state)
    },
    formatData(data) {
            return data
        },
    idclick5(){
            
            if ($("#cycle_readmore5").hasClass("readmore")) {
                $("#id5").html("Read More");
                $("#cycle_readmore5").removeClass("readmore");
                this.readmore = 'outer'
            } else {
                $("#id5").html("Read Less");
                $("#cycle_readmore5").addClass("readmore");
                this.readmore = 'outer readmore'
            }
        },
    getBlockerIssueListt(id, state){
        this.isLoading = true;
        let url = "api/blocker/getBlockerIssueListt";
        this.input = { id: parseInt(id), moduletype:this.taskmodule,empcode: this.undt.userid,useremail: this.undt.username,  orderBy: state};
        if(this.taskmodule == 1) {
            this.input.allottedto = parseInt(this.undt.userid)
            this.input.useremail = this.undt.username
        }
        this.input.limit = apiUrl.LIMIT
        this.input.offset = this.t_pageoffset
        this.input.order = this.order
        this.input.labelname = this.labelname

        this.input.searchTask
        this.input.searchStatus
        if(this.searchTerm) {
            let code = this.searchTerm.split('-')[0]
            if (code == 'TK' || code == 'tk'){
                this.input.searchCode = this.searchTerm.toUpperCase()
            } else {
                this.input.searchCode = null
                this.input.searchTask = this.searchTerm
                this.input.searchTask = this.input.searchTask.toLowerCase()
            }
        }

        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.bissuelist = result.data.data.rows
                this.t_totalcount = result.data.data.count;
                this.t_pageCount = Math.ceil(this.t_totalcount / this.t_pagelimit)
            } else {
                this.bissuelist = []
                this.t_totalcount = 0
                this.t_pageCount =0
            }
        }).catch(e => {
        this.displayError(e)
        })
    },

    getsorting(labelName) {
          
            let globalthis = this
                let index = $(this).index(),
                rows = [],
                thClass = $(this).hasClass('asc') ? 'desc' : 'asc';
                let listinglbl = '';
                if($(this).closest('table').attr('id')  ==  'table_businessceo1') {
                    listinglbl = 'table_businessceo1';
                }
                 else {
                    listinglbl = 'table_businessceo1';
                }
                $('#'+listinglbl+ ' th').removeClass('asc desc');

                $(this).addClass(thClass);
                globalthis.labelname = labelName;
                
                rows.sort(function (a, b) {

                    let aValue = $(a).find('td').eq(index).text(),
                        bValue = $(b).find('td').eq(index).text();
                        
                    if (!isNaN(aValue) && !isNaN(bValue)) {
                        aValue = Number(aValue)
                        bValue= Number(bValue)
                    }

                    return aValue > bValue
                        ? 1
                        : aValue < bValue
                        ? -1
                        : 0;
                });
                this.ordering = !this.ordering;
                if(this.ordering){
                    globalthis.order='DESC'
                }  else {

                    globalthis.order='ASC'
                }
                if(globalthis.labelname){
                    globalthis.getBlockerIssueListt(this.$route.query.issueid,globalthis.order)

                }
                $.each(rows, function (index, row) {
                    $('#'+listinglbl+' tbody').append(row);
                });
        },

    redirectToTask(btnstate,taskid) {
        this.$router.push({ 
            path: '/issues/view?issueid='+taskid,
            params: {
                backbtnstate:btnstate,
            }
        })
    },
    getBTaskListToTagBlocker(node) {
        if(node != null && node != '' && node.length > 4) {
            this.isLoading = true;
            let url = "api/blocker/getBTaskListToTagBlocker";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                node: node ? node.toLowerCase() : null,
                id: parseInt(this.$route.query.issueid),
                moduletype: 'issue',
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasksblockerlist = result.data.data.rows.map(item => ({ id: item.id, label: item.name+' - '+item.code, projectid: item.project }));
                } else {
                    this.tasksblockerlist = [];
                }
            }).catch(e => {
        this.displayError(e)
        })
        } else {
            this.tasksblockerlist = [];
        }
    },
    openBlockerIssueModule(){
        this.clearBlockermodulefields();
        this.disBlockerActive = true;
        this.isLogActive = false;
        this.dislogActive = true;
        this.issue.iseditstate=false;
        this.isedit = false;
        this.isRequestActive=false;
        this.disRequestActive=true;
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".addBlockerTaskDiv").offset().top - 70
        }, 2000);
    },
    validateBlocekrForm () {
        let isValid = true;
        if (this.blockertaskdetails.comment == null || this.blockertaskdetails.comment == "") {
            this.blockertaskdetails.errors.comment = "Comment is required."   
            isValid = false;
        }else{
            this.blockertaskdetails.errors.comment = null;
        }

        if (this.blockertaskdetails.status == null || this.blockertaskdetails.status == "") {
            this.blockertaskdetails.errors.status = "Status is required."   
            isValid = false;
        }else{
            this.blockertaskdetails.errors.status = null;
        }

        if (this.blockertaskdetails.blockerissues == null || this.blockertaskdetails.blockerissues.length == 0) {
            this.blockertaskdetails.errors.blockerissues = "Blocker Issue is required."   
            isValid = false;
        }else{
            this.blockertaskdetails.errors.blockerissues = null;
        }
        return isValid;
    },
    saveBlockerTasks () {
        let valid = this.validateBlocekrForm();
        if(valid){
            this.logtime.isTimeLogged = false;
            this.input = {
                moduletype:'issue',
                id: parseInt(this.$route.query.issueid),
                projectid: parseInt(this.blockertaskdetails.projectid),
                comment: this.blockertaskdetails.comment,
                status: this.blockertaskdetails.status,
                empcode: this.undt.userid,
                useremail: this.undt.username,
                blockertasklist: this.blockertaskdetails.blockerissues,
                bid: this.issue.businesstaskid?this.issue.businesstaskid:null,
            }
            axios({
                method: "POST",
                url: 'api/tasks/saveBlockerTasks',
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.disBlockerActive=false
                    this.isLoading = false
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.clearBlockermodulefields();
                    this.getBlockerIssueListt(this.$route.query.issueid);
                    $('html, body').animate({ scrollTop: 0 }, 1200);
                } else if(result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                        title: "Failed!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
        this.displayError(e)
        })
        }
    },
    getSubscribers(moduleid, moduletype){
        this.isLoading = true;
        let url = "api/subscribers/getSubscribersByModuleid";
        this.input = { moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              if(result.data.data && result.data.data.rows){
                let decryptdepartmenttext = result.data.data.rows.map(items =>{
                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                  if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                  })
              }
              this.issue.subscriberslist = result.data.data.rows;
              this.issue.subscribers = result.data.data.rows.map(d => d.id)
            } else {
              this.issue.subscribers = null;
              this.issue.subscriberslist
            }
        }).catch(e => {
        this.displayError(e)
        })
    },
    selSubscribers(state) {
        this.issue.subscribers = null
        this.issue.subscribers = state
        if(!state || (state && state.length < 1)) {
            this.errors.issue.subscribers = 'Subscribers are required'
        } else {
            this.errors.issue.subscribers = ''
        }
    },
    getIssueById(issueid){
      this.isLoading = true;
      let url = "api/issue/getIssueById";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
        userrole:this.userrole,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.editRowData = result.data.data;
          this.editRowData=this.editRowData[0]
          this.openEditModule(this.editRowData,4)
        } else {
          this.editRowData = [];
          this.$router.push({ name: 'IssueList', params: { search: true }});
          Swal.fire({
              title: "Failed!",
              text: "Your are not authorised user to view this issue",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          })
        }
      }).catch(e => {
        this.displayError(e)
        })
    },
    clearBlockermodulefields() {
        this.blockertaskdetails.projectid = null;
        this.blockertaskdetails.comment = null;
        this.blockertaskdetails.comment = '';
        this.blockertaskdetails.status = null;
        this.blockertaskdetails.moduletype = 'businestask';
        this.blockertaskdetails.errors.projectid = null;
        this.blockertaskdetails.errors.comment = null;
        this.blockertaskdetails.errors.btctaaskid = null;
        this.blockertaskdetails.errors.status = null;
        this.blockertaskdetails.errors.blockerissues = null;
        this.blockertaskdetails.blockerbtcs = null;
        this.blockertaskdetails.blockerissues = null;
        this.tasksblockerlist = [];
    },
    openBlockerModule() {
      this.clearBlockermodulefields();
      this.dislogActive = false
      this.isLogActive = false
      this.isRequestActive=false
      this.disRequestActive=false
      this.issue.disblesavebtn = false
      this.disBlockerDivActive = true
      this.isBlockerDvActive = true
      $('html, body').animate({ scrollTop: $(document).height() }, 800);
    },
    openLogModule(){
      this.logtime.spenttime=null
      this.logtime.spenttime = { "HH": "", "mm": "" }
      this.logtime.activity=null
      this.logtime.comment=''
      this.errors.activity=null
      this.errors.comment=null
      this.logtime.allotedto=this.editRowData.allotedto
      this.logtime.status= this.editRowData.status
      let currStatus = this.editRowData.status ? this.editRowData.status : 'open';
      this.getStatusConfigList(this.issue.project, currStatus);
      this.logtime.duedate = this.editRowData.duedate?this.editRowData.duedate:null
      this.dislogActive=false
      this.isLogActive=true
      this.isRequestActive=false
      this.disRequestActive=true
      this.issue.disblesavebtn = false
      this.disBlockerActive = false;
      $('html, body').animate({ scrollTop: $(document).height() }, 800);
    },
    savelogData(){
      let valid = this.validatelog();
      if(valid){
        this.logtime.isTimeLogged = true
        this.input={
          moduletype:'issue',
          modulename:this.editRowData.issuename,
          moduleid:this.editRowData.issueid,
          projectname:this.editRowData.projectname,
          projectid:this.editRowData.projectid,
          activity:this.logtime.activity,
          assignedto:this.logtime.allotedto,
          status:this.logtime.status,
          module_code: this.editRowData.issuecode,
          business_code: this.issue.businesstaskcode,
          createdby:this.undt.username,
          attachment:JSON.stringify(this.issue.attachmentnew),
          subscribers: this.issue.subscribers,
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        
        if(typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
            this.input.spenttime = this.logtime.spenttime
        }else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
            this.input.spenttime = this.logtime.spenttime
        }
        if(this.logtime.comment) {
          this.input.coments = this.logtime.comment
        }
        if(this.issue.attachmentnew) {
          let jsonobj1 = JSON.parse(this.issue.attachmentnew);
          let arraymerge = [...jsonobj1.data]
          this.input.attachment= JSON.stringify({data: arraymerge});
        }
        this.input.vendorcode = this.editRowData.vendorcode
        this.input.duedate = this.logtime.duedate?this.logtime.duedate:null
        this.isLoading=true
        axios({
          method: "POST",
          url: 'api/issue/saveLogTime',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.issue.iseditstate =true
            this.issue.status=this.logtime.status
            this.issue.duedate=this.logtime.duedate
            this.issue.allotedto = this.logtime.allotedto
            this.editRowData.logtimeFlag= 1
            this.savewizard('t42',this.editRowData)
            this.dislogActive=true
            this.isLogActive=false
          } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "Failed!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
        this.displayError(e)
        })
      }
      

    },
    getMRPDetailss(issueid, pid){
      this.isLoading = true;
      let url = "api/issue/getMRPDetails";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
        userrole:this.userrole,
        pid: pid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.getMRPDetails = result.data.data;
        } else {
          this.getMRPDetails = [];
        }
      }).catch(e => {
        this.displayError(e)
        })
    },

    validatelog: function(){
      var isValid=true;
      let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm
      
      if(!spenttime && typeof(this.logtime.spenttime)=='string')
      {
        let logstime = (this.logtime.spenttime).split(':')
        if(logstime){
            if(logstime[0] && logstime[1]){
                spenttime = `${logstime[0]}:${logstime[1]}`
                  this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
            }
        }
      }
      if(spenttime && typeof(this.logtime.spenttime)!='string'){
             this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
      }

      if(this.logtime.activity && spenttime==''){
        this.errors.spenttime="Spent-time Hours & minutes is required";
        isValid= false;
      }else{
        this.errors.spenttime='';
      }
      if(!this.logtime.activity && spenttime !=''){
        this.errors.activity="Activity is required";
        isValid= false;
      }else{
        this.errors.activity=null;
      }
      if(!this.logtime.allotedto){
        this.errors.allotedto="Assigned to is required";
        isValid= false;
      }else{
        this.errors.allotedto=null;
      }
      if(!this.logtime.status){
        this.errors.status="Status is required";
        isValid= false;
      }else{
        this.errors.status=null;
      }
      if(!this.logtime.duedate && this.editRowData.vendorcode){
        this.errors.duedate="Due Date is required";
        isValid= false;
      }else{
        this.errors.duedate = null;
      }  
      return isValid
    },
    redirecttoBTM:function(btnstate,rowdata){
        rowdata.backbtnstate=btnstate
        this.$router.push({ 

            path: '/businesstask/view?businessid='+rowdata.moduleid,
            params: {
                backbtnstate:btnstate,
                clickeddata:rowdata
            }
        })
    },
    openEditModule(currRowData, flag){
      if(currRowData && currRowData.length==0){
        currRowData = this.editRowData
      }
      this.issue.disblesavebtn = false
      this.logtime.isTimeLogged = false
      this.view.issueid = currRowData.issueid
      this.view.projectid=currRowData.projectid
      this.view.projectname = currRowData.projectname
      this.issue.businesstaskid = currRowData.businesstaskid
      if(this.issue.businesstaskid){
         this.isBtmFieldDisabled = false
            if(this.issue.businesstaskcode){
                this.isBtmFieldDisable = true
              }
      }
      this.issue.businesstaskcode = currRowData.businesstaskcode
         if(this.issue.businesstaskcode){
           this.isBtmFieldDisable = false
              if(this.issue.businesstaskid){
                this.isBtmFieldDisabled = true
              }
      }
      this.view.functionlityname = currRowData.functionlityname
      this.view.taskname = currRowData.taskname
      this.view.businessid = currRowData.businesstaskid
      this.view.btmname = currRowData.btmname
      this.view.typename = currRowData.typename
      this.view.sevairtyname = currRowData.sevairtyname
      this.view.createdby = currRowData.createdby
      this.view.empfullname = currRowData.empfullname
      this.view.documentlist = currRowData.attachment ? currRowData.attachment.data:[]
      this.view.issuecode = currRowData.issuecode
      this.view.createddate = currRowData.createddate
      this.view.lastmodifiedby = currRowData.lastmodifiedby      
      this.view.lastmodifieddate = currRowData.lastmodifieddate
      this.view.duedate = currRowData.duedate?currRowData.duedate:null
      this.issue.issuename = currRowData.issuename
      this.issue.project = currRowData.project
      this.issue.functionality = currRowData.functionality
      this.issue.task = currRowData.taskid
      this.issue.type = currRowData.issuetype
      this.issue.scenario = currRowData.scenario
      this.issue.attachment = currRowData.attachment
      this.view.qacycle = currRowData.qacycle
      this.issue.projectmodulename = currRowData.projectmoduleid
      this.issue.applicationname = currRowData.applicationid
      this.issue.applicationmodulename = currRowData.applicationmoduleid
      this.issue.sectionmodulename = currRowData.sectionmoduleid
      if(currRowData.attachment && currRowData.attachment.hasOwnProperty('data')) {
        this.issue.attachmentview =[];
        this.getDownloadLink(currRowData.attachment);
      }
      this.issue.sevairty = currRowData.sevairty
      this.issue.priority = currRowData.priority
      this.issue.allotedto = currRowData.allotedto;
      this.getEmployeeDetailsbyID(currRowData.allotedto);
      this.issue.reasonofissue = currRowData.reasonofissue
      this.issue.impactofissue = currRowData.impactofissue
      this.issue.status = currRowData.status
      this.issue.duedate = currRowData.duedate
      this.issue.moduletype = currRowData.moduletype

      if(this.issue.project == this.merchandisingProId) {
        this.getMRPDetailss(currRowData.issueid, this.issue.project);
      }
      this.getissueHistory(currRowData.issueid)
      this.getissueLogTimeHistory(currRowData.issueid)
      if(this.docs){
        for(let i in this.docs){
          this.docs[i].comment=null
          this.docs[i].desc=null
          this.docs[i].title=null
          if(i>0){
            this.docs.splice(i, 1);
          }
        }
      }
      if(flag==0){
        this.issue.iseditstate=true
        this.isedit = true
        this.isRequestActive=true
        this.disRequestActive=false
        this.dislogActive=true
        this.isLogActive = false
        this.disBlockerActive = false;
        this.formActionLabel = `Edit Issue: ${currRowData.issuename}`
        $('html, body').animate({ scrollTop: $(document).height() }, 800);
        this.getTasktypeConfigLists(this.issue.project);
      }
      if(flag==2){
        this.isdocsRequestActive=true
        this.addocument=false
        this.editdocument=true
        $('html, body').animate({ scrollTop: $(document).height() }, 1200);
      }

      if(flag==3){
        this.isdocsRequestActive=true
        this.editdocument=false
        this.isEditDocsRequestActive = true
        $('html, body').animate({ scrollTop: $(document).height() }, 1200);
      }
      if(this.issue.projectlist && this.issue.projectlist.length > 0 && (this.issue.project && this.issue.project !=null)){
      const checkexist = this.issue.projectlist.some(item =>item.id === this.issue.project);
      if(!checkexist){
          this.getProjectnamebyid(this.issue.project);
      }
      }else if(this.issue.projectlist && this.issue.projectlist.length ==0 && (this.issue.project && this.issue.project !=null)){
          this.getProjectnamebyid(this.issue.project);
      }
    },
    getissueHistory(issueid){
      this.isLoading = true;
      let url = "api/issue/getIssueLogById";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.issue.history = result.data.data;
        } else {
          this.issue.history=[]
        }
      }).catch(e => {
        this.displayError(e)
        })
    },
    getissueLogTimeHistory(issueid){
      this.isLoading = true;
      let url = "api/issue/getIssueLogTimeById";
      this.input = {
        issueid:issueid,
        useremail: this.undt.username,
        empid: parseInt(this.undt.userid),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        this.forceRerender()
        if (result.data.errorCode == 0) {
          this.logtimehistory = result.data.data;
          for(let i=0;i<(this.logtimehistory).length;i++){
            this.logtimehistory[i]['decdesignation'] = this.decryptText(this.logtimehistory[i]['assignedtodesignatio'])
            this.logtimehistory[i]['descdesignation'] = this.decryptText(this.logtimehistory[i]['designation'])
          }
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }
          else {
          this.logtimehistory=[]
        }
      }).catch(e => {
        this.displayError(e)
        })
    },
    getDownloadLink(data) {
      if(data.data.length>0) {
        let payload = {
          documents:data.data.map(i => {
          return {...i, useremail: this.undt.username, empcode: this.undt.userid}
          }),
          empcode: this.undt.userid,
          useremail: this.undt.username
        };
      axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            this.issue.attachmentview = result.data;
          }
        }).catch(e => {
        this.displayError(e)
        })
      }
    },
    getLogtimeDownloadLink(data, curr=null) {      
      if(data) {
        var input =[]
        input.push({link:data,empcode: this.undt.userid,useremail: this.undt.username})
        let payload = {
          documents:input,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: '/api/imageupload/getdownloadlink',
            data: payload,
            'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          if(result.data.status) {
            if(result.data.data[0].presignurl){
              if(curr=='zoom'){
                $('.imagepreview').attr('src', result.data.data[0].presignurl);
                $('#imagemodal').modal('show');  
                return 
              }
              window.location.href=result.data.data[0].presignurl;
              
            }
          }
        }).catch(e => {
        this.displayError(e)
        })
      }
    },
    docsuploadcollapse:function(){
      if(this.docs){
        for(let i in this.docs){
          this.docs[i].comment=null
          this.docs[i].desc=null
          this.docs[i].title=null
          if(i>0){
            this.docs.splice(i, 1);
          }
        }
      }
      this.isDetActive=true
      this.addocument=true
      this.editdocument = true
      this.isdocsRequestActive=!this.isdocsRequestActive
    },
    myeditdocrequestollapse: function() {
      this.isEditDocsRequestActive =!this.isEditDocsRequestActive
    },
    myadddocrequestollapse: function() {
      this.isdocsRequestActive =!this.isdocsRequestActive
    },
    myrequestollapse:function(){
      this.disRequestActive=!this.disRequestActive
    },
    addField(value, fieldType,opt='add') {
      if(opt=='edit') {
        let row = fieldType[fieldType.length-1];
        if(row.businessdocmasterid == undefined) return false
      }            
      this.isActive=false
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      if(fieldType && fieldType.length>1){
        this.docs.splice(index,1);
        this.attachment.splice(index,1);
      }
    },
    removeEditField(index, fieldType,data) {            
      Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          if(data.businessdocmasterid !== undefined) {
            axios({
              method: "POST",
              url: 'api/workstep/deletebusinesstaskdoc',
              data: {id:data.businessdocmasterid,deleteby:this.undt.username,useremail: this.undt.username,empcode: this.undt.userid},
              'headers':{'authorization':this.tokendata}
            }).then((result) => {
              this.isLoading = false;
              if (result.data.status) {
                fieldType.splice(index, 1);
                Swal.fire(
                  'Deleted!',
                  result.data.msg,
                  'success'
                  )
              } else {
                Swal.fire(
                  'Deleted!',
                  result.data.msg,
                  'error'
                )
              }
            });
          } else {
            fieldType.splice(index, 1);
          }    
        }
      })
    },
    getEmpallocatedtoProject(node){
      if(this.issue.project && (node && node.length > 3)){
        this.input = { projectid: this.issue.project, useremail: this.undt.username, empcode: this.undt.userid, asigneename: node.toLowerCase()}
        if(this.isThisBusinessRole) {
          this.input.roleid = 484
        }
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpallocatedtoProject',
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
          this.isLoading= false;         
          if(result.data.errorCode == 0){
            let decryptdepartmenttext = result.data.data.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
            this.issue.allotedtolist=result.data.data;
            this.issue.subscriberslist=result.data.data;
          }else{
            this.issue.allotedtolist = [];
            this.issue.subscriberslist = [];
          }
        }).catch(e => {
        this.displayError(e)
        })
      }
    },
    getEmployeeDetailsbyID(empid){
      if(this.issue.project && empid){
        axios({
        'method': 'POST',
        'url': 'api/listing/getEmpallocatedtoProject',
        'data': {assignedtoid: empid,projectid: this.issue.project,useremail: this.undt.username,empcode: this.undt.userid,},
        'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
              this.issue.allotedtolist=result.data.data;
              let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            }else{
              this.issue.allotedtolist=[];
            }
        }).catch(e => {
        this.displayError(e)
        })
      }
    },
    getbusinesstaskname(){
        this.inputappl = {
            projectid: this.issue.project,
            empid:this.undt.userid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            moduletype: 'issue'
        }
        axios({
        'method': 'POST',
        'url': 'api/searching/getBusinessTaskbyProject',
        'data': this.inputappl,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
                this.issue.businesstasklist=result.data.data;
            }else{
                this.issue.businesstasklist=[];
            }
        }).catch(e => {
        this.displayError(e)
        })
    },
    getbusinesstaskcode(){
        this.inputappl = {
            projectid: this.issue.project,
            empid:this.undt.userid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            moduletype: 'issue'
        }
        axios({
        'method': 'POST',
        'url': 'api/searching/getBusinessTaskCode',
        'data': this.inputappl,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
                this.issue.businesscodelist=result.data.data
            }else{
                this.issue.businesscodelist=[]
            }
        }).catch(e => {
        this.displayError(e)
        })
    },
    onFileChange(e) {
      console.log("=======",e)
      var validFileExtensions = apiUrl.validFileExtensions
      let selectedFiles = e.target.files;
      for (let i=0; i < selectedFiles.length; i++)
      {
        var blnValid = false;
        for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
          var sCurExtension = validFileExtensions[j];
          if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
          }
        }
          if (!blnValid) {
            Swal.fire({
                  title: "ERROR",
                  text: "Sorry, Invalid Type of Extension File..!!",
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
            })
            return false;
          }
          this.file.push(selectedFiles[i]);
      }
      if(this.file.length>0) {
          this.issue.disblesavebtn=true
      }
      document.getElementById('attachment').value = ""
    },
    removeImage (index) {
      if(this.file.length>0) {
          this.file.splice(index, 1);
          this.issue.filedescp.splice(index, 1);
          var removeimg = JSON.parse(this.issue.attachmentnew)
          if(removeimg){
              var remove = removeimg.data.splice(index,1)
              if(remove.length > 0)
              this.issue.attachmentnew = JSON.stringify({data:removeimg.data})
              else {
              this.issue.attachmentnew = null
            }
          }
      }   
      if(this.file.length < 1) {
          this.issue.disblesavebtn = false
      }      
    },
    removes3file(key,data) {
      if(data.length>0) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
          if (result.isConfirmed) {
              if(this.editRowData.issueid !== undefined) {
                  let deletefile = data[key];
                  data.splice(key, 1)
                  const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                  let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                  axios({
                  method: "POST",
                  url: 'api/issue/s3deleteissue',
                  data: {
                      issueid:this.editRowData.issueid,
                      attachment:payload,
                      deletefile: JSON.stringify(deletefile) ,
                      useremail: this.undt.username,
                      empcode: this.undt.userid,
                  },
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  if (result.data.status) {
                      Swal.fire(
                      'Deleted!',
                      result.data.msg,
                      'success'
                      )
                  } else {
                  Swal.fire(
                      'Deleted!',
                      result.data.msg,
                      'error'
                      )                        
                  }
              }).catch(e => {
        this.displayError(e)
        })
              } else {
                  Swal.fire(
                      'IssueId!',
                      'Issueid should not blank',
                      'info'
                      )
              }    
          }
        })
      }
    },
    mydetailscollapse() {
      this.isDetActive = true
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    savewizard: function(mdl,objThis){      
      this.valid = this.validateForm(mdl,objThis);
      if(objThis.logtimeFlag && objThis.logtimeFlag==1){
        this.valid =true;
      }
      if (this.valid) {
        this.isLoading = true;
          let tblNm='t42'
          if(mdl) tblNm=mdl
          this.input = {
              tbl: tblNm,
              section: 'issue',
              createdby: this.undt.username,
              empid: this.undt.userid,
          }
          let apiURL='api/issue/create'
          this.input.issuename=this.issue.issuename
          this.input.project=this.issue.project
          this.input.functionality=this.issue.functionality
          this.input.task = this.issue.task
          this.input.type=this.issue.type
          this.input.scenario=this.issue.scenario
          this.input.attachment= this.issue.attachmentnew          
          this.input.sevairty=this.issue.sevairty
          this.input.priority=this.issue.priority
          this.input.allotedto=this.issue.allotedto
          this.input.reasonofissue=this.issue.reasonofissue
          this.input.subscribers = this.issue.subscribers
          this.input.impactofissue=this.issue.impactofissue
          this.input.status=this.issue.status
          this.input.duedate = this.issue.duedate
          this.input.businesstaskid=this.issue.businesstaskid
          this.input.businesstaskcode=this.issue.businesstaskcode
          // if(this.issue.businesstaskid){
          //     let code = this.issue.businesscodelist.filter(i => i.businesstaskmasterid == this.issue.businesstaskid)
          //     this.input.businesstaskcode = code?(code[0].id):null
          //     this.input.businesstaskid=this.issue.businesstaskid
          // }
          // if(this.issue.businesstaskcode){
          //     let code = this.issue.businesstasklist.filter(i => i.businesstaskcode == this.issue.businesstaskcode)
          //     this.input.businesstaskid = code?(code[0].id):null
          //     this.input.businesstaskcode=this.issue.businesstaskcode
          // }
          this.input.moduletype=this.editRowData.moduletype?this.editRowData.moduletype:'issue'
          this.input.module_code = this.editRowData.issuecode
          this.input.vendorcode = this.editRowData.vendorcode
          if(this.issue.iseditstate == true){
              if(tblNm == 't42'){
                this.input.issueid=this.editRowData.issueid
                this.input.projectname = this.editRowData.projectname
                this.input.isTimeLogged = this.logtime.isTimeLogged
                apiURL='api/issue/update'
              }
              if(this.issue.attachmentnew && this.issue.attachment !== null) {
                this.input.attachmentnew = this.issue.attachmentnew
                let jsonobj1 = JSON.parse(this.issue.attachmentnew);
                let jsonobj2 = this.issue.attachment;
                let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                this.input.attachment= JSON.stringify({data: arraymerge});
                this.view.documentlist = arraymerge
              }
              this.input.lastmodifiedby=this.undt.username
              delete this.input.createdby;
          }
          
          this.isAddState=false
          this.input.useremail= this.undt.username
          this.input.empcode= this.undt.userid
          axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {           
            this.isLoading = false;  
            if(result.data.errorCode == 0){
              // this.isLoading=false;
              $('html, body').animate({ scrollTop: 0 }, 1200);
              
              this.file =[]
              this.issue.filedescp =[]
              this.logtime.isTimeLogged = false
              Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: 'success',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
              })
              this.handleCancel()
              this.disRequestActive=true
              this.getIssueById(this.editRowData.issueid)
            } 
            else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            } else {
                Swal.fire({
                    title: "Failed",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
          }).catch(e => {
        this.displayError(e)
        })
      }
    },
    handleCancel() {
      this.issue.attachmentnew = null
      this.issue.attachment = null
      this.issue.attachmentview = null
      this.myrequestollapse()
      $('html, body').animate({ scrollTop: 0 }, 1200); 
    },
    handleCancel2() {
      this.dislogActive=true
      this.isLogActive=false
      $('html, body').animate({ scrollTop: 0 }, 1200);
    },
    handleLogCancel() {
      this.clearBlockermodulefields();
      this.dislogActive=true
      this.isLogActive=false
      this.disBlockerActive = false
      this.isdisabled = false
      this.logtime.comment = ''
      this.issue.iseditstate=false
      this.isedit = false
      this.isRequestActive=false
      this.disRequestActive = true
      $('html, body').animate({ scrollTop: 0 }, 1200);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    getApplicationdata: function(mdl,curracttab){            
      this.pageoffset=1
      this.inputappl = {
        tbl:mdl,
        offset:this.pageoffset,
        limit:apiUrl.LIMIT
      }
      let apiURL='api/master/commonmaster/list'
      if(mdl == 't11'){
        apiURL='api/listing/getprojectlist'
        this.inputappl = {
            tbl:mdl
        }
      }else if(mdl == 't37' && curracttab == 'issue'){
        
        this.inputappl = {};
        apiURL = "api/listing/getfunctionalitybyprojectid";
        this.inputappl.projectid = this.issue.project
      }else if(mdl == 't37' && curracttab == 'issueSearch'){
        this.inputappl = {};
        apiURL = "api/listing/getfunctionalitybyprojectid";
        this.inputappl.projectid = this.search.searchprojectname
      }else if(mdl == 't43'){
        apiURL='api/master/commonmaster/customselect'
        /* this.inputappl.projectmoduleid=this.issue.project */
        this.inputappl.isSearch= false
        this.inputappl.section= "issue"
        this.inputappl.isFilterSelect= 1
        this.inputappl.isFilterPayload= {}
      }else if(mdl == 't44'){
        apiURL='api/master/commonmaster/customselect'
        this.inputappl.isSearch= false
        this.inputappl.section= "issue"
        this.inputappl.isFilterSelect= 1
        this.inputappl.isFilterPayload= {}
      }else if(mdl == 't26'){
        apiURL='api/master/commonmaster/getModuleDropdownOption'
        this.inputappl = {
          tbl: "t26"
        }
      } else if (mdl == 't18' && curracttab == 'issue') {
        
        apiURL = 'api/master/commonmaster/getModuleDropdownOption'
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          functionalityid: this.issue.functionality
        }
      } else if (mdl == 't18' && curracttab == 'issueSearch') {
        apiURL = 'api/master/commonmaster/getModuleDropdownOption'
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          functionalityid: this.search.functionality
        }
      }
      this.inputappl.useremail= this.undt.username
      this.inputappl.empcode= this.undt.userid
      axios({
        'method': 'POST',
        'url': apiURL,
        'data': this.inputappl,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {             
        if(result.data.errorCode == 0){
          if(mdl == 't11'){
            if(curracttab == 'issue'){
              return true
            }
          }else if(mdl == 't37' && curracttab == 'issue'){
            this.issue.functionalitylist=result.data.data
            this.issue.functionalitylist.sort((a, b) => {
              return a.label.localeCompare(b.label);
              });
            return true
          }else if(mdl == 't37' && curracttab == 'issueSearch'){
            this.search.functionalitylist=result.data.data
            this.search.functionalitylist.sort((a, b) => {
            return a.label.localeCompare(b.label);
            });
            return true
          }else if(mdl == 't43'){
            if(curracttab == 'issue'){
              //this.issue.typelist=result.data.data.rows
              return true
            }
          }else if(mdl == 't44'){
            if(curracttab == 'issue'){
              this.issue.sevairtylist=result.data.data.rows
              return true
            }
          }else if(mdl == 't26'){
            if(curracttab == 'issue'){
              
              return true
            }
          } else if(mdl == 't18' && curracttab == 'issue') {
            this.issue.tasklist = result.data.data
            
            return true
          } else if(mdl == 't18' && curracttab == 'issueSearch') {
            this.search.tasklist = result.data.data
            return true
          }
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            if(mdl == 't37' && curracttab == 'issue'){
              this.issue.functionalitylist=[]
              return true
            } else if(mdl == 't18' && curracttab == 'issue'){
              this.issue.tasklist = []
              return true
            }
          }
      }).catch(e => {
        this.displayError(e)
        })
      
    },
    validText : function(inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if(inputval){
        return re.test(inputval.toLowerCase());
      }
    },
    selbtmid(state, value) {
        if(!state) {
            this.errors.issue.businesstaskid = "Business task name required"
            this.issue.businesstaskcode = null
            this.isBtmFieldDisabled = false
        } else {
          this.errors.issue.businesstaskid = ""
          
          if(this.issue.businesstaskid){
              let code = this.issue.businesscodelist.filter(i => i.businesstaskmasterid == this.issue.businesstaskid)
              if(code.length>0){
              this.issue.businesstaskcode = code[0].id
              }
              this.isBtmFieldDisable = true
              this.isBtmFieldDisabled = false
          }
        }
    },
    selbtmcode(state, value) {
        if(!state) {
            this.errors.issue.businesstaskcode = "Business task code required"
            this.issue.businesstaskid = null
            this.isBtmFieldDisable = false
        } else {
            this.errors.issue.businesstaskcode = ""
          if(this.issue.businesstaskcode){
              let code = this.issue.businesstasklist.filter(i => i.businesstaskcode == this.issue.businesstaskcode)
              if(code.length>0){
              this.issue.businesstaskid = code[0].id
              }
              this.isBtmFieldDisable = false
              this.isBtmFieldDisabled = true
          }
        }
    },
    validateForm: function(mdl,objThis){
      this.isAddState=false
      this.commentBlured=true;
      var isValid=true;
      if(mdl=="t42"){
        if(!this.issue.issuename){
          this.errors.issue.issuename="Issue name required";
          isValid= false;
        }
        if(!this.issue.project){
          this.errors.issue.project="Project required";
          isValid= false;
        } else {
          this.errors.issue.project = null;
        }
        if(!this.issue.businesstaskid && !this.issue.businesstaskcode && (this.issue.type != 9 && this.issue.type != 18)){
            this.errors.issue.businesstaskid="Business task name is required";
            isValid= false;
        } else {
            this.errors.issue.businesstaskid = null;
        }
        if(!this.issue.businesstaskcode && !this.issue.businesstaskid && (this.issue.type != 9 && this.issue.type != 18)){
            this.errors.issue.businesstaskcode="Business task code is required";
            isValid= false;
        }else {
            this.errors.issue.businesstaskcode = null;
        }
        if(!this.issue.type){
            this.errors.issue.type="Type required";
            isValid= false;
        }
        if(!this.issue.scenario){
          this.errors.issue.scenario="Issue scenario required";
          isValid= false;
        } else {
          this.errors.issue.scenario="";
        }
        if(!this.issue.allotedto){
          this.errors.issue.allotedto="Assigned to required";
          isValid= false;
        } else {
          this.errors.issue.allotedto = "";
        }
        if(!this.issue.sevairty){
          this.errors.issue.sevairty="Sevairty required";
          isValid= false;
        }
        if(!this.issue.priority){
          this.errors.issue.priority="Priority required";
          isValid= false;
        }
        if(!this.issue.status){
          this.errors.issue.status="Status required";
          isValid= false;
        }
        if(!this.issue.duedate && this.editRowData.vendorcode){
            this.errors.issue.duedate = "Due Date required";
            isValid= false;
        }else{
            this.errors.issue.duedate = null
        }
        if((!this.issue.subscribers) || (this.issue.subscribers && this.issue.subscribers.length < 1)) {
            this.errors.issue.subscribers = 'Subscribers are required'
            isValid = false
        } else {
            this.errors.issue.subscribers = ''
        }
      }else if(mdl=="doc"){
        for(let index in this.docs){
          if(!this.validText(this.docs[index].title) || !this.validText(this.docs[index].comment) || !this.validText(this.docs[index].desc)){
            isValid=false
          }
        }
      }
      return isValid
    },
    nullifybusinessfields: function(){
        this.issue.businesstaskid = null
        this.issue.businesstaskcode = null
    },
    selissuecsproject: function(state,value){
            this.issue.project=null
            this.issue.subscribers = null
            this.issue.project=state
            if(!state){
                this.issue.functionality = null
                
                this.issue.task = null
                this.issue.tasklist = null
                this.issue.allotedto = null
                this.issue.subscribers = null
                this.issue.allotedtolist = []
                this.errors.issue.project='Project required'
                this.issue.functionalitylist=null
                this.issue.businesstaskid = null
                this.issue.businesstaskcode = null
                this.issue.statuslist = [];
            }else{
                this.errors.issue.project=null
                // this.getEmpallocatedtoProject(state, 'issue')
                this.getbusinesstaskname(state, 'issue')
                this.getbusinesstaskcode(state, 'issue')
                this.getApplicationdata('t37','issue')
                let currStatus = this.issue.status ? this.issue.status : 'open';
                this.getStatusConfigList(state, currStatus);
                this.getTasktypeConfigLists(state);
                console.log("ada")
                if (state != this.editRowData.projectid) {
                  this.issue.type = null
                }
            }
    },
    getTasktypeConfigLists(projectid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: parseInt(projectid.toString()),
                trackername: 'Issue Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    this.sortTypeList(slist, 'alltype')
                } else {
                    this.issue.typelist = [];
          }
      }).catch(e => {
        this.displayError(e)
        })
    },    
    sortTypeList(typelist, type){
        let numArrFromTypelist = [];
        typelist.forEach(element => {
            if(element.id != null) {
                numArrFromTypelist.push(element.id);
            }
        });
        if(numArrFromTypelist.length>0) {
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                moduletype: 'issue',
                typearr: numArrFromTypelist,
                projecttype: type
            };
            axios({
                method: "POST",
                url: 'api/ticket/sortTypeList',
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.issue.typelist = result.data.data.rows;
                    this.issue.typelist.sort((a, b) => {
                      return a.label.localeCompare(b.label);
                  });
                } else {
                    this.issue.typelist = [];
                }
            });
        }
    },
    getStatusConfigList(pid, status) {
          let payload = {
              empcode: this.undt.userid,
              useremail: this.undt.username,
              projectid: pid,
              trackername: 'Issue',
              currStatus: status,
          };
          axios({
              method: "POST",
              url: '/api/testcase/getStatusConfigList',
              data: payload,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
              if(result.data.errorCode == 0){
                  this.issue.statuslist = result.data.data.rows;
              } else {
                  this.issue.statuslist = [];
              }
          }).catch(e => {
        this.displayError(e)
        })
      },
    selissuecsfunctionality: function(state,value){
        this.issue.functionality=null
        this.issue.functionality=state

        if(!state){
            this.issue.task = null
            this.issue.tasklist = null
        }else{                
            this.getApplicationdata('t18', 'issue')
        }
    },
    selissuecstype: function(state,value){
        this.issue.type=null
        this.issue.type=state
        if(state == 9 || state == 18){
            this.errors.issue.businesstaskcode = null
            this.errors.issue.businesstaskid = null
            this.errors.issue.type=null
        }else{
          if(!state && this.isAddState == false){
              this.errors.issue.type='Issue type required'
          }else{                
              this.errors.issue.type=null
          }
        }
    },
    selissuecssevairty: function(state,value){
        this.issue.sevairty=null
        this.issue.sevairty=state
        if(!state && this.isAddState == false){
            this.errors.issue.sevairty='Issue severity required'
        }else{                
            this.errors.issue.sevairty=null
        }
    },
    selissuecspriority: function(state,value){
        this.issue.priority=null
        this.issue.priority=state
        if(!state && this.isAddState == false){
            this.errors.issue.priority='Issue priority required'
        }else{                
            this.errors.issue.priority=null
        }
    },
    selogissuecsstatus: function(state,value){
        this.logtime.status=null
        this.logtime.status=state
        if(!state && this.isAddState == false){
            this.errors.issue.status='Issue status required'
        }else{     
            this.errors.status = null               
            this.errors.issue.status=null
        }
    },
    selissuecsstatus: function(state,value){
        this.issue.status=null
        this.issue.status=state
        if(!state && this.isAddState == false){
            this.errors.issue.status='Issue status required'
        }else{     
            this.errors.status = null               
            this.errors.issue.status=null
        }
    },
    selallotedto: function(state,value){
        this.issue.allotedto=null
        this.issue.allotedto=state
        if(!state && this.isAddState == false){
            this.errors.issue.allotedto='Assigned To required'
        }else{ 
            this.errors.allotedto= null              
            this.errors.issue.allotedto=null
        }
    },
    selissuelogactivity(state, value) {
      this.logtime.activity = null
      this.logtime.activity = state
      if(!state) {
          
      } else {
          this.errors.activity = null
      }
    },
    redirecttoPNM:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            this.$router.push({ 

                path: '/project/summary?pid='+rowdata.moduleid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
    getProjectAllocatedToEmp() {
      this.isLoading = true
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode:this.undt.userid,
        moduletype: 'issue',
        isfrompage:'updatepage'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers': { 'authorization': this.tokendata }
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.issue.projectlist = result.data.data;
          if(this.issue.projectlist && this.issue.projectlist.length > 0 && (this.issue.project && this.issue.project !=null)){
              const checkexistproject = this.issue.projectlist.some(item =>item.id === this.issue.project);
              if(!checkexistproject){
                  this.getProjectnamebyid(this.issue.project);
              }
          }else if(this.issue.projectlist.length ==0 && (this.issue.project && this.issue.project !=null)){
              this.getProjectnamebyid(this.issue.project);
          }
        } else {
          this.issue.projectlist = [];
        }
      }).catch(e => {
        this.displayError(e)
        })
    },
    getProjectnamebyid(pid){
        this.isLoading = true;
        this.apiURL="api/searching/getAssignedProjectList"
        this.input={
            useremail: this.undt.username,
            empid :this.undt.userid,
            empcode: this.undt.userid,
            projectid: pid,
            isfrompage: 'isprojectid'
        }
        axios({
            'method': 'POST',
            'url': this.apiURL,
            'data':this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {  
            this.isLoading = false;         
            if(result.data.errorCode == 0){
                if(result.data.data && result.data.data.length > 0){
                    this.issue.projectlist.push(result.data.data[0]);
                }
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    onSelect(event){
      this.file = e.target.files[0];          
    },
    onUpload(){
      if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't42'){
                btnEle='issuemgt'
            }
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
                formData.append("imagefile", this.file[index]);
                maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail", this.undt.username);
            formData.append("moduletype", 'issuemgt');
            formData.append("fcount", parseInt(this.file.length));
            
            if (maxsize > 1000 * 1000 * 25) {
              this.isLoading = false;
              Swal.fire({
                  title: "Failed",
                  text: "Your document uploaded above 25mb not allow",
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
              this.issue.disblesavebtn=true 
              this.isLoading=false
              return false; 
            }
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata,'content-type':'multipart/form-data'}
            })
            .then(result => { 
                this.isLoading=false;            
                if(result.data.status){
                  this.issue.disblesavebtn=false
                  
                  const bindinfo = result.data.data.map((file, index) => {
                    return {"link":file,"info":this.issue.filedescp[index]}                
                  });
                  let jsonobj = {data:bindinfo}
                  this.issue.attachmentnew = JSON.stringify(jsonobj);
                  Swal.fire({
                      title: "Success!",
                      text: result.data.msg,
                      icon: 'success',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
              } else {
                  Swal.fire({
                      title: "",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
                  this.issue.attachment=null
              }
          }).catch(e => {
        this.displayError(e)
        })
        }else{
          this.isLoading=false; 
          Swal.fire({
            title: "",
            text: 'Select image/docs/videos',
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
          this.issue.attachment=null
        }
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.issue.attachment=null
      }
    },
    onZoom(e){
      this.getLogtimeDownloadLink(e, 'zoom');
    },

    previewpdf(geturl)
    {
        window.open(geturl, "_blank");
    }
  },
  filters: {
    basename: function (value) {
      if (!value) return ''
      
      return value.substr(value.lastIndexOf("/") + 1)
    }
  }
}
</script>
