<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper content-header-left mb-1 pt-0 mt-1">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-1">Application Environment</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/appenvironment/list'>App Environment List</router-link></li>
              </ol>
            </div>          
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst">
          <div class="card-header filter_rotation_onlick" style="cursor: pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
              <div class="row">  
                <div class="w-auto px-1 mobile-padding-0-2">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                          <search-icon
                          size="1.5x"
                          class="custom-class avatar-icon font-medium-3"
                          ></search-icon>
                      </div>
                      </div>
                  <h4 class="card-title d-inline-flex ml-1">Search App Environment Details</h4>
              </div>
              <div class="ml-auto d-flex pr-1">
              <div v-if="showaddmodulebtn" class="px-1" >
                <div class="input-group-append float-right">
                  <button id="commonaddmodule" class="btn btn-sm btn-relief-success" @click="redirecttourl('create', backpagedata)"><plus-circle-icon
                                                size="1.5x"
                                                class="custom-class"
                                              ></plus-circle-icon> <span>Create App Environment</span></button>
                </div>
              </div>
              <span class="rotetions_view_arrow"> 
                <em class="las la-angle-down"></em>
              </span>
              </div>

            </div>
            </div>

          </div>
          <div v-bind:class="[{ show: isSearchRequestActive },card_content,collapse,]" >
            <div class="card-body pt-2 px-sm-mobile-10">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChangesearch" @input="selSearchProject"  v-model="search.project" :options="appenv.projectlist" />
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                  <label class="form-label lable-left">Application Name</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Application Name"  v-model="search.application" :options="appenv.applicationlist"/>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 mb-0 text-right">
                  <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="masterlist(search)"><search-icon
                                                size="1.5x"
                                                class="custom-class"
                                              ></search-icon> <span>Search</span></button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span></button>
                  <button type="move" class="btn btn-primary mt-2" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>                            
                </div>
              </div>
            </div>
          </div>
        </div>
          <div v-if="false" class="blog-list-wrapper">
          <div class="row">
            <div class="col-md-4 col-12"  v-for="(data,index) in companymasterlist" v-bind:key="index">
              <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
              <div class="card-header">
                <div class="col-12">
                  <h4 class="card-title">{{data.projectname}}</h4>
                </div>
              </div>
              <div class="card" style="margin-bottom:0 !important;">
                  <div class="card-body">
                    <h4 class="card-title">
                          <span class="blog-title-truncate text-body-heading ">
                            {{ `${data.environmenttype} - ${data.applicationname}` }}
                          </span>
                      </h4>
                      <div class="media">
                          <div class="avatar mr-50">
                              <img alt="" :src="data.presignedurl?data.presignedurl:defaultimagepath"  width="24" height="24" />
                          </div>
                          <div class="media-body">
                              <small class="text-muted mr-25">by</small>
                              <small><a href="javascript:void(0);" class="text-body">{{data.createdby?data.createdby.split('@')[0]:''}}</a></small>
                              <span class="text-muted ml-50 mr-25">|</span>
                              <small class="text-muted">{{dateMONFormat(data.createddate)}}</small>
                          </div>
                      </div>
                      <div class="my-1 py-25">
                        <div class="capitalisetext badge badge-pill badge-light-info mr-50 mb-50">{{data.datacentername}}</div>
                        <div class="capitalisetext badge badge-pill badge-light-danger mr-50 mb-50">{{data.hostingplatform}}</div>
                        <div class="capitalisetext badge badge-pill badge-light-primary mr-50 mb-50">{{data.hostingpath}}</div>
                        <div class="capitalisetext badge badge-pill badge-light-success mr-50">{{data.hostingurl}}</div>
                      </div>
                      <p class="card-text blog-content-truncate" v-if="data.hostingdetails">
                          <span v-if="data.hostingdetails.length>150" v-html="data.hostingdetails.substring(0, 151) + ' ...'"></span>
                          <span v-else v-html="data.hostingdetails"></span>
                      </p>
                      <hr />
                      <div class="d-flex justify-content-between align-items-center">
                          <a v-if="loginusername == data.createdby" @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                              <div class="d-flex align-items-center sitebluecolor">
                                    <message-square-icon size="1.5x" class="custom-class font-medium-1 text-body mr-50"></message-square-icon>
                                  <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                              </div>
                          </a>
                          <a @click="redirecttourl('view',data)" href="javascript:void(0);" class="font-weight-bold">View Details</a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-2">
              <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
              <div v-if="companymasterlist.length == 0" colspan="14" style="text-align: center;">No record found</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card mb-3">
              <div class="">
                <div class="" id="appEnvList">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="row" id="table-small">
                      <div class="col-12">
                        <div class="card">
                          <div class="table-responsive datatableview">
                            <table class="table table-sm dataTableListing table-hover table-striped" aria-describedby="mydesc">
                             
                              <thead>
                                <tr>
                                  <th scope="col" class="srno align-middle py-1">Sr#</th>
                                  <th scope="col" class="align-middle">Project Name</th>
                                  <th scope="col" class="align-middle">Application Name</th>
                                  <th scope="col" class="align-middle">Environment Type</th>
                                  <th scope="col" class="align-middle">Hosting Platform</th>
                                  <th scope="col" class="align-middle">Hosting URL</th>
                                  <th scope="col" class="align-middle">Hosting Details</th>
                                  <th scope="col" class="align-middle" style="min-width:100px;">Created Date</th>
                                  <th scope="col" class="clsaction align-middle">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <template v-for="(data,index) in companymasterlist">        
                                  <tr v-bind:key="data.moduleid">
                                      <td data-column="Sr#" class="srno">{{ index+1+currPageNum*pagelimit }}</td>
                                      <td data-column="Project Name">
                                        <span class="font-weight-normal capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectId',data)">{{data.projectname}}</span>
                                      </td>
                                      <td data-column="Application Name">
                                        <span class="font-weight-normal capitalisetext cursorpointercls" >{{data.applicationname}}</span>
                                      </td>
                                      <td data-column="Environment Type">{{data.environmenttype}}</td>
                                      <td data-column="Hosting Platform">
                                        <span class="font-weight-normal capitalisetext" v-if="data.hostingplatform && data.hostingplatform.length > 10">{{data.hostingplatform.substring(0,10+1)+'...'}}</span>
                                        <span class="font-weight-normal capitalisetext" v-if="data.hostingplatform && data.hostingplatform.length <= 10">{{data.hostingplatform}}</span></td>
                                      <td data-column="Hosting URL">
                                        <span class="font-weight-normal capitalisetext" v-if="data.hostingurl && data.hostingurl.length > 10">{{data.hostingurl.substring(0,10+1)+'...'}}</span>
                                        <span class="font-weight-normal capitalisetext" v-if="data.hostingurl && data.hostingurl.length <= 10">{{data.hostingurl}}</span></td>
                                      <td data-column="Hosting Details">
                                        <span class="font-weight-normal capitalisetext" v-if="data.hostingdetails && data.hostingdetails.length > 10">{{data.hostingdetails.substring(0,10+1)+'...'}}</span>
                                        <span class="font-weight-normal capitalisetext" v-if="data.hostingdetails && data.hostingdetails.length <= 10">{{data.hostingdetails}}</span>
                                      </td>
                                      <td data-column="Created Date" class="">{{dateFormat(data.createddate)}}</td>
                                      <td data-column="Actions" class="clsaction">
                                        <div class="dropdown">
                                          <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                            <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                          </button>
                                          <div class="dropdown-menu">
                                            <a class="dropdown-item font-weight-bold" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                              <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                              <span>View Details</span>
                                            </a>
                                            <a class="dropdown-item devEditEle" href="javascript:void(0);" v-if="loginusername == data.createdby" @click="redirecttourl('edit',data)">
                                              <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                              <span>Edit</span>
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                  </tr>
                                </template>
                                <template v-if="companymasterlist.length==0">
                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pageCenter text-center">
                  <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { ExternalLinkIcon,PackageIcon, ClipboardIcon, MessageSquareIcon, EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, MoreVerticalIcon, Edit2Icon, XIcon,PlusCircleIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';

export default {
  name:'appenvironmentList',
  components:{
      Loading,
      MessageSquareIcon,
      ClipboardIcon,
      VueElementLoading,
      vSelect,
      EditIcon,
      EyeIcon,
      Treeselect,
      SearchIcon,
      GridIcon,
      CodesandboxIcon,
      CloudIcon,
      ChromeIcon,
      BoxIcon,
      Link2Icon,
      PackageIcon,
      Pagination,
      MoreVerticalIcon,
      Edit2Icon,
      XIcon,
      PlusCircleIcon,
      ExternalLinkIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      showaddmodulebtn: false,
      backpagedata: null,
      currPageNum: 0,
      defaultimagepath:apiUrl.defaultimagepath,
      isSearchRequestActive: false,
      isDetActive: true,
      card_content: "card-content",
      collapse: "collapse",
      search: {
        project: null,
        projectOpts: [],
        application: null,
        applicationOpts: []
      },
        isLoading: false,
        fullPage: true,
        companymasterlist: [],
        showAddForm:false,
        projectType:[],
        appenv:{
            name:'',
            host:'',
            hostpath:'',
            hostdetails:'',
            hosturl:'',
            type:'',
            application:null,
            applicationlist:[{
                id:'',
                label:''
            }],
            project:null,
            projectlist:[]

        },
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        pageoffset:1,
        pagefeatures:[],
        loginusername: '',
        tokendata:null
    }
  },
  created() {
    this.pagefeatures = {}
    let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
    pagefeatures1.forEach(element => {
      if (element.page === '/assignments') {
        this.pagefeatures[element.featurename] = element.featureaccess
      }
    })
  },
  mounted(){
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
        userdata = JSON.parse(userdata)
        this.undt=userdata
        this.tokendata = window.localStorage.getItem('token');
        this.loginusername=userdata.username
        if(this.$route.params.search) {
          let search = JSON.parse(localStorage.getItem('appenvsearchItems'))
          if(search) {
            this.getSearchItems(search)
          }
          // this.masterlist(search);
        } else if(this.$route.params.backbtnstate == 'view') {
          this.backpagedata = this.$route.params.clickeddata;
          this.showaddmodulebtn = true;
          this.isSearchRequestActive = true;
          if(this.$route.params.clickeddata && this.$route.params.clickeddata.projectid){
            this.search.project = this.$route.params.clickeddata.projectid;
            this.projectChangesearchbyid([this.search.project]);
          }
          if(this.$route.params.clickeddata && this.$route.params.clickeddata.applicationid){
            this.search.application = this.$route.params.clickeddata.applicationid;
          }
          this.masterlist(null, this.$route.params.clickeddata);
        } else {
          localStorage.removeItem('appenvsearchItems');
          this.masterlist();
        }
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods:{
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    mydetailsollapse() {
      this.isDetActive = true
    },
    clickCallback: function(pageNum) {
      this.pageoffset=pageNum
      this.currPageNum = pageNum - 1
      this.masterlist();
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#appEnvList").offset().top -70
        });
      }, 500);
    },
    resetRecords() {
      this.search.project = null;
      this.search.application = null;
      localStorage.removeItem('appenvsearchItems');
      this.masterlist();
    },
    redirecttourl:function(btnstate,rowdata){
      rowdata.backbtnstate=btnstate
      if(btnstate=="view") {
        this.$router.push({ 
          path: `/appenvironment/view/?appenvid=${rowdata.id}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      } else if(btnstate=="edit") {
        this.$router.push({ 
          path: `/appenvironment/update/?appenvid=${rowdata.id}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      } else if(btnstate=="create") {
        this.$router.push({
          name: 'appenvironmentCreate',
          path: `/appenvironment/create`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      } else if (btnstate == 'projectId') {
        this.$router.push({
            path: '/project/summary?pid='+rowdata.projectid,
        })
      }
    },
   
    getSearchItems(search) {
      this.isSearchRequestActive = true;
      this.search.project = search.project;
      this.search.application = search.application;
      this.masterlist(this.search);
    },
    masterlist(searchThis, objThis){
      if(searchThis) {
        localStorage.setItem('appenvsearchItems', JSON.stringify(searchThis))
      }
      this.companymasterlist = []
      this.input = {
        offset:this.pageoffset,
        useremail: this.undt.username,
        empid: this.undt.userid,
        limit:apiUrl.LIMIT,
        empcode: this.undt.userid,
      }
      let search = JSON.parse(localStorage.getItem('appenvsearchItems'))
      if(search) {
        this.input.projectid = search.project
        this.input.applicationid = search.application
      }
      if(objThis) {
        this.input.applicationid = objThis.applicationid
      }
      let url = 'api/appenv/list'
      axios({
        'method': 'POST',
        'url': url,
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {       
        if(result.data.errorCode == 0){                    
          this.companymasterlist=result.data.data.rows;
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
          this.totalcount = 0
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMONFormat(value){
      if(value){
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      }else{
        return "";
      }
    },
    getApplicationdata(mdl) {
      this.isLoading = true
      let url = "api/listing/getappnamebyprojecteid";
      if(mdl == 't12') {
        this.input = {
          projecteid: this.search.project,
          empcode: this.undt.userid,
          useremail: this.undt.username

        };
      }
      axios({
        method: "POST",
        url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then(result => {
        this.isLoading = false
        if (result.data.errorCode == 0) {
          this.appenv.applicationlist = result.data.data;
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    projectChangesearch: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.appenv.projectlist = result.data.data;
          } else {
            this.appenv.projectlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    projectChangesearchbyid: function (projectidarr) {
      if (projectidarr && projectidarr.length > 0) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          empid: parseInt(this.undt.userid),
          projectidarr: projectidarr,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.appenv.projectlist = result.data.data;
          } else {
            this.appenv.projectlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    selSearchProject(state, value) {
      this.search.project = null
      this.search.project = state
      if(!state) {
        this.search.application = null
        this.appenv.applicationlist = null
      } else {
        this.getApplicationdata('t12')
      }
    },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
       this.input = {
        limit:null,
        offset:null,
        projectid:this.search.project,
        applicationid:this.search.application,
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
      }; 
      axios({
        method: "POST",
        url: `api/appenv/list`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            var filename = "";
            this.download(result.data.data.rows,filename)
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        
        if (result.data.status) {
          this.isDetActive = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else {
          this.companymasterlist = [];
          this.totalcount =0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          let ShowLabel = 'appenvlistreport'+filename
          let JSONData = readExportdata
          let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          let CSV = ''
          let row = ''
            if (ShowLabel) {
                CSV = 'Sr#,Project Name,Application Name,Environment Type,Hosting Platform,Hosting URL,Hosting Details,Created Date' + '\r\n'
            }
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let projectname = index.hasOwnProperty('projectname') ? (index.projectname ==null || index.projectname == 'NA' ? '' : index.projectname): '';
            let applicationname = index.hasOwnProperty('applicationname') ? (index.applicationname == null || index.applicationname == 'NA' ? '' : index.applicationname) : '';
            let environmenttype = index.hasOwnProperty('environmenttype') ? (index.environmenttype == null || index.environmenttype == 'NA' ? '' : index.environmenttype) : '';
            let hostingplatform = index.hasOwnProperty('hostingplatform') ? (index.hostingplatform ==null || index.hostingplatform == 'NA'?'' : index.hostingplatform ): '';
            let hostingurl = index.hasOwnProperty('hostingurl') ? (index.hostingurl == null || index.hostingurl == 'NA'?'':index.hostingurl) : '';
            let hostingdetails = index.hasOwnProperty('hostingdetails') ? (index.hostingdetails == null || index.hostingdetails == 'NA'?'':index.hostingdetails) : '';
            let createddate = index.hasOwnProperty('createddate') ? (index.createddate == null || index.createddate == 'NA' ?'':index.createddate) : '';  
            createddate = this.dateMONFormat(createddate);  
                row += '"' + srno + '",'
                row += '"' + projectname + '",'
                row += '"' + applicationname + '",'
                row += '"' + environmenttype + '",'
                row += '"' + hostingplatform + '",'
                row += '"' + hostingurl + '",'
                row += '"' + hostingdetails + '",'
                row += '"' + createddate + '",'
                row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            return;
            }
            var fileName = 'appenvlistreport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });    
        }
    },
    export (data, filename, mime) {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
  }
}
</script>