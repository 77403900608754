<template>
     <section id="card-style-variation">
        <div class="card mb-0">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer;border-radius: 10px 10px 0px 0px;" >
                       <div class="container-fluid">
                <div class="row mobile_co_mainheader_text">
                    <div class="w-auto px-1 mobile_margin-px-0_00 mobile_w_calc_38">
                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                            <div class="avatar-content">
                                <settings-icon size="1x"  class="custom-class avatar-icon font-medium-3"></settings-icon>
                            </div>
                        </div>
                        <h4 class="card-title d-inline-flex ml-1 mobile_width-86">BTC Listing Mapping</h4>
                    </div>
                    
                </div>
                </div>
            </div>
            
            <div class="container p-1" >
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-2">
                        <label class="form-label lable-left">Visible</label>
                        <treeselect type="text" class="projectmdllistdd" placeholder="Select Visibility" v-model="lconfig.visiabletype" :options="lconfig.visiabletypelist" @input="getVisiableDetails"/>
                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.lconfig.errors.visiabletype">{{ this.lconfig.errors.visiabletype }}</div>
                    </div>
                    <div v-if="lconfig.visiabletype == 'roles'" class="col-sm-6 col-md-6 col-lg-4 mb-1">
                        <label class="form-label lable-left">Role</label>
                        <treeselect type="text" class="projectmdllistdd" placeholder="Select Roles" v-model="lconfig.roleids" :options="lconfig.roleslist" @input="getRolesDetails"/>
                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.lconfig.errors.roleids">{{ this.lconfig.errors.roleids }}</div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-2">
                        <label class="form-label lable-left">Module Type </label>
                        <treeselect type="text" class="projectmdllistdd" placeholder="Select Roles" v-model="lconfig.moduletype" :options="lconfig.moduletypelist"  @input="getModuleTypeDetails"/>
                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.lconfig.errors.moduletype">{{ this.lconfig.errors.moduletype }}</div>
                    </div>
                    <div v-if="lconfig.moduletype == 'businesstask'" class="col-sm-6 col-md-6 col-lg-4 mb-2">
                        <label class="form-label lable-left">BTC View Type </label>
                        <treeselect type="text" class="projectmdllistdd" placeholder="Select Roles" v-model="lconfig.submoduletype" :options="lconfig.submoduletypelist" @input="getSubModuleDetails"/>
                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.lconfig.errors.submoduletype">{{ this.lconfig.errors.submoduletype }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 container_mappingview">
                    <div class="list_left_mapping">
                    <ul>
                        <li v-for="item in leftItems" :key="item.id" class="list-item">
                            <input type="checkbox" v-model="item.selected">  {{ item.text }}
                        </li>
                    </ul>
                    </div>
                    <div class="containerMapping_arrows">
                        <div class="containerMappCenter">
                            <div class="icon mapleft" @click="moveSelected(leftItems, rightItems)">
                                <em class="las la-arrow-right font-medium-3"></em>
                            </div>
                            <div class="icon mapright" @click="moveSelected(rightItems, leftItems)">
                                <em class="las la-arrow-left font-medium-3"></em>
                            </div>
                    </div>
                    </div>

                    <div class="list_right_mapping">
                        <ul>
                            <li v-for="item in rightItems" :key="item.id" class="list-item">
                            <input type="checkbox" v-model="item.selected"> {{ item.text }}
                            
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 mg-top1 text-right">
                    <button type="button" class="btn btn-relief-primary mr-1" @click="SaveConfig()">Submit</button>
                    <button type="reset" class="btn btn-outline-secondary mr-0" @click="resetForm()">Reset</button>
                    <!-- <button type="button" class="btn btn-relief-success " data-dismiss="modal" aria-label="Close">
                        Close
                    </button> -->
                </div>
                </div>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import VueElementLoading from 'vue-element-loading';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Loading from 'vue-loading-overlay';
  import { ClipboardIcon,SettingsIcon } from 'vue-feather-icons';
  import 'vue-loading-overlay/dist/vue-loading.css'
  import profilepic from '../profilepic.vue';
  import moment from 'moment';
  import commonMethods from '../../utils/commonMethods';
  import Treeselect from '@riophae/vue-treeselect'

  export default {
      name: 'ListingConfig',
      mixins: [ commonMethods ],
      components: {
          VueElementLoading,Loading,profilepic,ClipboardIcon,Treeselect,SettingsIcon
      },
      data() {
          return {
            isLoading:false,
            tasksrows: [],
            completedTaskCount: [],
            issuesrows: [],
            completedIssuesCount: [],
            dateDetailsREs: [],
            leftItems: [],
            rightItems: [],
            leftSelected: {},
            rightSelected: {},
            tempLeftItems: [],
            lconfig: {
                visiabletypelist: [
                    {id : 'self',
                    label : 'to me only'},
                    {id : 'roles',
                    label : 'to roles only'},
                ],
                visiabletype: null,
                roleslist: [],
                roleids: null,
                moduletypelist: [{id : 'businesstask',
                    label : 'Business Task'},{id : 'task',
                    label : 'Task'}
                ],
                submoduletypelist: [{id : 'parentbtc',
                    label : 'Parent BTC'},
                    {id : 'subbtc',
                    label : 'Sub BTC'},],
                moduletype: null,
                submoduletype: null,
                errors: {
                    visiabletype: null,
                    roleids: null,
                    moduletype: null,
                    submoduletype: null,
                }
            }
          }
      },
      props: {
      },
      mounted(){
          window.addEventListener('storage', function(event){
            if (event.key == 'logout-mee') { 
                window.location.reload();
            }
          });
          let userdata = window.localStorage.getItem('userdata');
          if(userdata){
              userdata = JSON.parse(userdata)
              this.undt=userdata
              this.tokendata = window.localStorage.getItem('token');
              this.userrole = window.localStorage.getItem("userrole")
              this.loginusername=userdata.username
              this.loginpersonname=userdata.firstname+' '+userdata.lastname;
              this.loginuscondition = userdata.userid
              this.pagefeatures = {}
              this.taskmodule = window.localStorage.getItem("taskmodule")
              let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
              pagefeatures1.forEach(element => {
                  if (element.page === '/businesstask/list') {
                      this.pagefeatures[element.featurename] = element.featureaccess
                  }
              })
              this.getConfigTableList();
          }
      },
      methods: {
            resetForm() {
                this.lconfig.errors.visiabletype = null;   
                this.lconfig.errors.moduletype = null;   
                this.lconfig.errors.submoduletype = null;   
                this.lconfig.errors.roleids = null;   
                this.lconfig.visiabletype = null;   
                this.lconfig.moduletype = null;   
                this.lconfig.submoduletype = null;   
                this.lconfig.roleids = null;   
                this.lconfig.roleslist = []; 
                this.getConfigTableList();
                this.rightItems = [];  
            },
            getVisiableDetails (state) {
                if(state){
                    this.lconfig.errors.visiabletype = null;
                    if(state == 'roles'){
                        this.getCopyRoleAPIConfiguredList();
                    }
                    this.openeditmodule()
                } else {
                    this.lconfig.errors.visiabletype = "Visible Type is required.";  
                }
            },
            openeditmodule() {
                if((this.lconfig.visiabletype != null && this.lconfig.visiabletype != '') && 
                (this.lconfig.moduletype != null && this.lconfig.moduletype != '')) {
                    this.inputthrid = {
                        empcode: parseInt(this.undt.userid),
                        useremail: this.undt.username,
                        moduletype: this.lconfig.moduletype,
                        visiabletype: this.lconfig.visiabletype,
                    }

                    if(this.lconfig.visiabletype == 'roles') {
                        this.inputthrid.roleids = this.lconfig.roleids
                    }
                    if(this.lconfig.visiabletype == 'self') {
                        this.inputthrid.userid = parseInt(this.undt.userid)
                    }
                    if(this.lconfig.moduletype == 'businesstask') {
                        this.inputthrid.submoduletype = this.lconfig.submoduletype
                    }

                    axios({
                    'method': 'POST',
                    'url': 'api/listingconfig/getListingConfigDatabyid',
                    'data': this.inputthrid,
                    'headers':{'authorization':this.tokendata}
                    })
                    .then(async (result) => {
                        if(result.data.status == true){
                            this.rightItems = result.data.data.rowdata[0].listingdata;
                            let unmatchedArr1 = this.tempLeftItems.filter(element => {
                                let targetIndex = this.rightItems.findIndex(e => element.id == e.id);
                                return targetIndex >= 0 ? false : true;
                            });
                            
                            this.leftItems = unmatchedArr1;
                        }else{
                            this.rightItems = [];
                            this.getConfigTableList();
                        }
                    }).catch(e => {
                            this.displayError(e)
                    });
                }
            },
            getRolesDetails (state) {
                if(state){
                    this.lconfig.errors.roleids = null;
                    this.openeditmodule()
                } else {
                    this.lconfig.errors.roleids = "Role is required.";  
                }
            },
            getModuleTypeDetails (state) {
                if(state){
                    this.lconfig.errors.moduletype = null;
                    this.openeditmodule()
                } else {
                    this.lconfig.errors.moduletype = "Module Type is required.";  
                }
            },
            getSubModuleDetails (state) {
                if(state){
                    this.lconfig.errors.submoduletype = null;
                    this.openeditmodule()
                } else {
                    this.lconfig.errors.submoduletype = "BTC View Type is required.";  
                }
            },
            getCopyRoleAPIConfiguredList() {
                this.inputthrid = {
                    projectid:apiUrl.campsprojectid,
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                }
                axios({
                    'method': 'POST',
                    'url': 'api/projectapiconfig/getProjectWiseCamsRolesList',
                    'data': this.inputthrid,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(result.data.status == true){
                    this.lconfig.roleslist = result.data.data
                    }else{
                    this.lconfig.roleslist=[]
                    }
                }).catch(e => {
                        this.displayError(e)
                });
          },
          SaveConfig() {
            let valid = this.validateBlocekrForm();
            if(this.rightItems.length==0) {
                Swal.fire({
                    title: "Failed",
                    text: 'Please Select and Add Columns for the Listing.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                valid = false;
            }
			if(valid) {
                let apiurl = `api/listingconfig/config`
                this.isLoading = true
                axios({
                    method: 'POST',
                    url: apiurl,
                    data: {
                        visiabletype: this.lconfig.visiabletype,
                        roleids: (this.lconfig.visiabletype == 'roles') ? parseInt(this.lconfig.roleids) : null,
                        moduletype: this.lconfig.moduletype,
                        submoduletype: this.lconfig.submoduletype,
                        userid: (this.lconfig.visiabletype == 'self') ? parseInt(this.undt.userid) : null,
                        listingdata: this.rightItems,
                        empcode: parseInt(this.undt.userid),
                        useremail: this.undt.username,

                    },
                    headers: { authorization :this.tokendata }
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        });
                        this.resetForm();
                    } else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
          },
          validateBlocekrForm () {
                let isValid = true;

                if (this.lconfig.visiabletype == null || this.lconfig.visiabletype == "") {
                    this.lconfig.errors.visiabletype = "Visible Type is required."   
                    isValid = false;
                }else{
                    this.lconfig.errors.visiabletype = null;
                }

                if (this.lconfig.visiabletype && this.lconfig.visiabletype == 'roles' && (this.lconfig.roleids == null || this.lconfig.roleids == "")) {
                    this.lconfig.errors.roleids = "Role is required."   
                    isValid = false;
                }else{
                    this.lconfig.errors.roleids = null;
                }

                if (this.lconfig.moduletype == null || this.lconfig.moduletype == "") {
                    this.lconfig.errors.moduletype = "Module Type is required."   
                    isValid = false;
                }else{
                    this.lconfig.errors.moduletype = null;
                }

                if (this.lconfig.moduletype && this.lconfig.moduletype == 'businesstask' && (this.lconfig.submoduletype == null || this.lconfig.submoduletype == "")) {
                    this.lconfig.errors.submoduletype = "BTC View Type is required."   
                    isValid = false;
                }else{
                    this.lconfig.errors.submoduletype = null;
                }

                return isValid;
            },
          moveSelected(sourceList, destinationList) {
            const selectedItems = sourceList.filter(item => item.selected);
            selectedItems.forEach(item => {
                item.selected = false;
                destinationList.push(item);
                const index = sourceList.indexOf(item);
                sourceList.splice(index, 1);
            });
          },
          getConfigTableList() {
              let url = "api/listing/getListingConfigData";
              this.input = {
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                    this.leftItems = result.data.data.rowdata.map(item => ({ id: parseInt(item.id), text: item.fieldname.toUpperCase(), selected: false }))
                    this.tempLeftItems = result.data.data.rowdata.map(item => ({ id: parseInt(item.id), text: item.fieldname.toUpperCase(), selected: false }))
                  } else {
                      this.leftItems = [];
                  }
              }).catch(e => {
                this.displayError(e)
              });     
          },
          additionOfTaskAndIssue(n1, n2) {
              if(n1 != null && n2 != null) {
                  return n1+n2;
              } else {
                  return 0;
              }
          },
          getBTCTaskAndIssueCounts(id) {
              this.isLoading = true
              let url = "api/bt/getBTCTaskAndIssueCounts";
              this.input = {
                  id: id,
                  useremail: this.undt.username,
                  empcode: this.undt.userid,
                  issubtask: this.flagg==1?true:false,
              };
              axios({
                  method: "POST",
                  url: url,
                  data: this.input,
                  'headers':{'authorization':this.tokendata}
              }).then((result) => {
                  this.isLoading = false;
                  if (result.data.errorCode == 0) {
                      this.tasksrows = result.data.data.tasksrows;
                      this.completedTaskCount = result.data.data.completedTasksCount;
                      this.issuesrows = result.data.data.issuesrows;
                      this.completedIssuesCount = result.data.data.completedIssuesCount;
                      this.dateDetailsREs = result.data.data.dateDetailsREs;
                  } else {
                      this.tasksrows = [];
                      this.completedTaskCount = [];
                      this.issuesrows = [];
                      this.completedIssuesCount = [];
                      this.dateDetailsREs = [];
                  }
              }).catch(e => {
            this.displayError(e)
          });
          },
          dateFormatnew(value) {
              if (!value) return "";
              return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
          },
      },
  }
  </script>