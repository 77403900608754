<template>
    <div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
         <section class="modern-horizontal-wizard ">
            <div class="wizard-modern modern-wizard-example card ">
               <div class="bs-stepper-content pt-0 px-0">
                  <div  class="">
                     <div class="row mx-0">
                        <section  class="w-100">
                           <div class=" match-height" style="overflow:hidden;">
                              <div class="col-xl-12 col-lg-12 px-0">
                                    <div class="card-body pt-1 px-0 pb-0">
                                       <div class="px-1 hrms_raise_border ">
                                          <div class="container_hrms_raise">
                                             <div class="card-header">
                                                <div class="d-flex align-items-center" > 
                                                   <em data-feather='mail' class="side-bar_feather mr-1"></em>
                                                   <h2 class="card-title">Generate Offer Latter</h2>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                              </div>
                              <div class="row mx-0">
                                 <div class="col-md-4">
                                     <section id="filled-pills">
                    <div class="row match-height">
                            <div class="card w-100">
                                <div class="card-body py-0 px-0">
                                    <ul class="nav nav-pills nav-fill hrms-generate-offerview py-1 px-1 border-bottom">
                                        <li class="nav-item">
                                            <a class="nav-link active px-0" id="jobCandidateDetails-tab" data-toggle="pill" href="#jobCandidateDetails" aria-expanded="true">
                                              <span class="hr_circle1"> 1</span>
                                              <span class="hr_circle_text "> Job/Candidate Details</span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link px-0" id="offerLetter" data-toggle="pill" href="#offerLetter-view" aria-expanded="false">
                                               <span class="hr_circle1"> 2</span>
                                              <span class="hr_circle_text"> Offer Letter</span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link px-0" id="pay_details_tab" data-toggle="pill" href="#pay_details" aria-expanded="false">
                                               <span class="hr_circle1"> 3</span>
                                              <span class="hr_circle_text"> Pay Details</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane active" id="jobCandidateDetails" aria-labelledby="jobCandidateDetails-tab" aria-expanded="true">
                                            <div class="card-body pt-0 card_views_hrms candidates_details-hr">
                                                <div class="card-header px-0">
                                                  <h3 class="card-title"> Job/Candidate Details </h3>
                                                </div>
                                                 <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Company  <span class="star_icon_t">* </span> </label>
                                                     <treeselect placeholder="Select Company" v-model="companyname" class="companydata" :clear-on-select="true" :options="companydata" />
                                                  </div>
                                                   <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Select Department  <span class="star_icon_t">* </span> </label>
                                                    <treeselect placeholder="Select Department" v-model="departmentname" class="department" :clear-on-select="true" :options="departmentdata" />
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Designations  <span class="star_icon_t">* </span> </label>
                                                     <treeselect placeholder="Select Designations" v-model="designationname" class="designation" :clear-on-select="true" :options="designationdata" />
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Location  <span class="star_icon_t">* </span> </label>
                                                     <select class="form-control" id="basicSelect">
                                                         <option>Executive- Human resources(BB)</option>
                                                         <option>Firstcry</option>
                                                         <option>Firstcry.com</option>
                                                     </select>
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Employee Type  <span class="star_icon_t">* </span> </label>
                                                     <treeselect placeholder="Select Employee Type" v-model="employeetypename" class="employeetype" :clear-on-select="true" :options="employeetypedata" />
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Reporting Manager  <span class="star_icon_t">* </span> </label>
                                                     <treeselect placeholder="Select Reporting Manager" class="allotedto capitalisetext" v-model="reportingmanager" :options="allottedtolist" />
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Probation Period  <span class="star_icon_t">* </span> </label>
                                                     <treeselect class="capitalisetext" v-model="probationdayval" placeholder="Select Probation Day" :options="probationday" />
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display-flex" for="first-name-column">Full Name <span class="star_icon_t">* </span></label>
                                                     <input type="text" id="name" class="form-control" placeholder="Enter Full Name" name="fname-column" />
                                                 </div>
                                                 <div class="form-group">
                                                     <div class="form-group mg-bot0 col-md-12 mb-0 px-0 datePicker">
                                                     <label class="display-flex" for="first-name-column"> Date of Joining <span class="star_icon_t">* </span></label>
                                                     <date-picker  v-model="dateofJoingDate" placeholder="Select Date"   class="startdate" ></date-picker>
                                                     </div>
                                                </div>
                                                <div class="form-group">
                                                  <label class="display-flex" for="first-name-column">Contact No<span class="star_icon_t">* </span></label>
                                                  <input type="text" id="name" class="form-control" placeholder="Enter Contact Number" name="fname-column" />
                                                </div>
                                                <div class="form-group">
                                                  <label class="display-flex" for="first-name-column">Email ID<span class="star_icon_t">* </span></label>
                                                  <input type="text" id="name" class="form-control" placeholder="Enter Email id" name="fname-column" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="offerLetter-view" role="tabpanel" aria-labelledby="offerLetter" aria-expanded="false">
                                            <div class="card-body pt-0 card_views_hrms candidates_details-hr">
                                                <div class="card-header px-0">
                                                  <h3 class="card-title"> Offer Letter </h3>
                                                </div>
                                                 <div class="form-group">
                                                     <div class="form-group mg-bot0 col-md-12 mb-0 px-0 datePicker">
                                                     <label class="display-flex" for="first-name-column"> Offer Expiry Date <span class="star_icon_t">* </span></label>
                                                     <date-picker  v-model="offerExpDate" placeholder="Select Date"   class="startdate" ></date-picker>
                                                     </div>
                                                </div>
                                                 <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Template  <span class="star_icon_t">* </span> </label>
                                                     <treeselect class="capitalisetext" v-model="templateval" placeholder="Select Template" :options="template" />
                                                      
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Signing authority 1  <span class="star_icon_t">* </span> </label>
                                                     <treeselect placeholder="Select Signing authority" v-model="singingauthority" class="companydata" :clear-on-select="true" :options="singingauthoritylist" /> 
                                                  </div>
                                                  <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Signature 1 <span class="star_icon_t">* </span> </label>
                                                      <treeselect placeholder="Select Signature" v-model="singture" class="companydata" :clear-on-select="true" :options="singturelist" />
                                                  </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="pay_details" role="tabpanel" aria-labelledby="pay_details_tab" aria-expanded="false">
                                            <div class="card-body pt-0 card_views_hrms candidates_details-hr">
                                                <div class="card-header px-0">
                                                  <h3 class="card-title"> Pay Details </h3>
                                                </div>
                                                <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Pay Scale <span class="star_icon_t">* </span> </label>
                                                     <treeselect class="capitalisetext" v-model="payscaleval" placeholder="Select Pay Scale" :options="payscale" />
                                                </div>
                                                  <h6 class="card-text"> Pay Scale Groups <em data-feather='alert-circle'></em></h6>
                                                <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Salary Structure <span class="star_icon_t">* </span> </label>
                                                     <treeselect class="capitalisetext" v-model="salarystructureval" placeholder="Select Salary Structure" :options="salarystructure" />
                                                </div>
                                                <div class="form-group">
                                                  <label class="display-flex" for="first-name-column"> Annual Gross Salary <em data-feather='alert-circle'></em> </label>
                                                  <input type="text" id="name" class="form-control" placeholder="2500000" name="fname-column" />
                                                </div>
                                                <div class="form-group">
                                                  <label class="display-flex" for="first-name-column"> Annual Variable Pay <em data-feather='alert-circle'></em> </label>
                                                  <input type="text" id="name" class="form-control" placeholder="" name="fname-column" />
                                                </div>
                                                 <div class="form-group">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="customCheck1" />
                                                        <label class="custom-control-label" for="customCheck1">Show Total CTC in Salary Breakup</label>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                <button type="text" class="btn btn-outline-primary"> Salary Breakup </button>
                                                </div>
                                                <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Currency  <span class="star_icon_t">* </span> </label>
                                                     <treeselect class="capitalisetext" v-model="currencyval" placeholder="Select Currency" :options="currency" />
                                                </div>
                                                <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> Differently Abled  <span class="star_icon_t">* </span> </label>
                                                     <treeselect class="capitalisetext" v-model="differentlyabledval" placeholder="Select differently abled" :options="differentlyabled" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>
               </div>
               <div class="col-md-8 border-left">
                 <div class="card-body pt-0 px-0">
                     <section id="filled-pills">
                    <div class="row match-height">
                            <div class="card w-100">
                                <div class="card-body py-0 px-0">
                                    <ul class="nav hrms-generate-offerview-right py-1 px-0 round-0 border-bottom">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="jobprivew_tab" data-toggle="pill" href="#jobprivew" aria-expanded="true">Preview</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="jobFeedback-tab" data-toggle="pill" href="#jobFeedback" aria-expanded="false">Feedback</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="jobApproval-tab" data-toggle="pill" href="#jobApproval" aria-expanded="false">Approval</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane active" id="jobprivew" aria-labelledby="jobprivew_tab" aria-expanded="true">
                                            <div class="card-body px-1">
                                                <p> <strong> Letter Date </strong></p>
                                                <p> <strong> Full Name </strong></p>
                                                <p> Dear First Name</p>
                                                <p> This is with  reference to the various discussions you had us.</p>
                                                <p> Congratulations!!!</p>
                                                <p> We are pleased to offer you the position of <strong>#* Designation *#</strong>  at <strong>#*office City
                                                *# </strong> your annual compensation will be as started below-:</p>
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="jobFeedback" role="tabpanel" aria-labelledby="jobFeedback-tab" aria-expanded="false">
                                            <div class="card-body px-1">
                                                Feedback  design here
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="jobApproval" role="tabpanel" aria-labelledby="jobApproval-tab" aria-expanded="false">
                                            <div class="card-body px-1 approvers_view_div candidates_details-hr">
                                                <h4>Offer Letter email for approval </h4>
                                                <p> This email gets triggered to approver for his/her approval.
                                                No email is triggered if there are no approvals.</p>

                                                <div class="form-group">
                                                     <label class="display:flex;" for="basicSelect"> CC USERS  <span class="star_icon_t">* </span> </label>
                                                     <select class="form-control" id="basicSelect">
                                                         <option>Select</option>
                                                         <option>Retention Bonus</option>
                                                         <option>Firstcry.com</option>
                                                     </select>
                                                </div>
                                                <div class="geneateoffer_checkout demo-inline-spacing">
                                                    <div class="custom-control custom-checkbox mt-0 mb-1">
                                                        <input type="checkbox" class="custom-control-input" id="customCheck2" Checked/>
                                                        <label class="custom-control-label" for="customCheck2">Myself</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox mt-0 mb-1">
                                                        <input type="checkbox" class="custom-control-input" id="customCheck3" />
                                                        <label class="custom-control-label" for="customCheck3">Hiring Lead</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox mt-0 mb-1">
                                                        <input type="checkbox" class="custom-control-input" id="customCheck4" />
                                                        <label class="custom-control-label" for="customCheck4">Admin</label>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                     <label class="display-flex" for="first-name-column">Subject <span class="star_icon_t">* </span></label>
                                                     <input type="text" id="name" class="form-control" placeholder="Enter Subject" name="subject" />
                                                 </div>

                                                 <div class="form-group">
                                                    <label class="form-label">Comment </label>
                                                   <ckeditor :editor="editor" placeholder="Comment" v-model="discription.comment" @input="seltasklogcomments" :config="editorConfig" tag-name="textarea"/>
                                                 </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </section>
            </div>
            </div>
        </div>
    </div>
</section>
</div>

</div>
</div>
</div>
         </section>
      </div>
   </div>
</template>
<script>
import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
 import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
import {  MoreVerticalIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';

   export default {
     name: "generateoffer",
     components: {
        DatePicker,
        ckeditor: CKEditor.component,         
        Treeselect,
        VueElementLoading,
        Loading,Pagination,
        MoreVerticalIcon        
     },
     mixins: [ commonMethods ],
     data() {
     return {
         defaultValue: new Date(),
         isLoading: false,
         fullPage: true,
         dateofJoingDate: true,
         offerExpDate: true,
         generate_discription:true,
         discription:{
            comment:null,
        },
        reportingmanager : null,
        allottedtolist: [], 
        singingauthoritylist : [],
        singturelist : [],
        singingauthority : null,
        singture : null,
        editor: ClassicEditor,
        departmentlist: [],
        companymasterlist: [],
        designationlist: [],
        departmentdata:[],
        companydata:[],
        designationdata:[],
        employeetypedata:[],
        citydata:[],       
        card_content: "card-content",
        collapse: "collapse",
        totalcount: 0,
        pageCount: 0,
        page:1,      
        currPageNum: 0,
        pageoffset:1,
        payscaleval: null,
        salarystructureval: null,
        currencyval: null,
        probationdayval: null,
        templateval: null,
        differentlyabledval: null,
        payscale: apiUrl.payscale,
        salarystructure: apiUrl.salarystructure,
        currency: apiUrl.currency,
        probationday: apiUrl.probationday,
        template: apiUrl.template,
        differentlyabled: apiUrl.differentlyabled,
     }
   },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
        userdata = JSON.parse(userdata)
        this.undt=userdata
        this.loginusername=userdata.username
        this.loginuscondition = userdata.userid
        this.tokendata = window.localStorage.getItem('token');
        this.userrole = window.localStorage.getItem("userrole")
        this.roleid = this.undt.roleid
        if(this.roleid && parseInt(this.roleid) === 520) {
            this.isThisBusinessRole = true
        }
           this.getDepartmentdata();
           this.getDesignationdata();
           this.getEmployeeTypedata();
           this.getCitydata();
           this.getCompanydata();
           this.getEmpallocatedtoProject();
        }
    },
    methods:{
        getDepartmentdata(){
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isactive: 1
        }
            axios({
            'method': 'POST',
            'url': 'api/departmentmaster/getdepartmentlist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.departmentdata = result.data.data.rows;
            } else {
                this.departmentdata = [];
            }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getDesignationdata(){
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid
        }
            axios({
            'method': 'POST',
            'url': 'api/designationmaster/getdesigniationlist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.designationdata = result.data.data.rows;
            } else {
                this.designationdata = [];
            }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getEmployeeTypedata(){
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid
        }
            axios({
            'method': 'POST',
            'url': 'api/employeetypemaster/getemployeetypelist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.employeetypedata = result.data.data.rows;
            } else {
                this.employeetypedata = [];
            }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getCitydata(){
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid
        }
            axios({
            'method': 'POST',
            'url': 'api/citymaster/getcitylist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.citydata = result.data.data.rows;
            } else {
                this.citydata = [];
            }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getCompanydata(){
                this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
            'method': 'POST',
            'url': 'api/companymaster/getcompanylist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                this.companydata = result.data.data.rows;
                } else {
                this.companydata = [];
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getEmpallocatedtoProject() {
            axios({
                method: "POST",
                url: "api/listing/getEmpallocatedtoProject",
                data: { useremail: this.undt.username,empcode: this.undt.userid },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    let decryptdepartmenttext = result.data.data.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                    this.allottedtolist = result.data.data;
                    this.singingauthoritylist = result.data.data;
                    this.singturelist = result.data.data;                   
                } else {
                    this.allottedtolist = [];
                    this.singingauthoritylist = [];
                    this.singturelist = [];                              
                }                
            }).catch(e => {
                    this.displayError(e)
            });
        },
    }
   };
   
</script>