import { render, staticRenderFns } from "./TCBulkMapping.vue?vue&type=template&id=74137269&scoped=true"
import script from "./TCBulkMapping.vue?vue&type=script&lang=js"
export * from "./TCBulkMapping.vue?vue&type=script&lang=js"
import style0 from "./TCBulkMapping.vue?vue&type=style&index=0&id=74137269&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74137269",
  null
  
)

export default component.exports