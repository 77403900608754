<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header-left mb-1 pt-0 ">
            <div class="container-fluid">
                <div class="row breadcrumbs-top">
                    <div class="col-md-9 content-header-left px-0 ">
                        <h3 class="content-header-title float-left mb-0">User Activation</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link
                                        to=''>Details</router-link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section id="modern-horizontal-wizard">
         <div class="" id="projecttabmaster">
            <div class="card filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="myequestcollapse">
                <div class="container-fluid">
                    <div class="row row fc_my-05">
                        <div class="w-auto px-1 mobile-padding-0-2">
                            <div class="avatar bg-light-secondary rounded d-inline-flex">
                                <div class="avatar-content">
                                  <em class="las la-user custom-class font-medium-3"></em>
                                </div>
                            </div>
                            <h4 class="card-title d-inline-flex ml-1">User Activation/Deactivation</h4>
                        </div>
                        <span class="rotetions_view_arrow ml-auto mx-1"> 
                            <em class="las la-angle-down"></em>
                        </span>
                    </div>
                </div>
              </div>
              <div class="card-body pt-1" v-bind:class="[{show: isRequestActive},card_content,collapse]">
                <form class="form form-vertical">
                  <div class="row">
                    <div class="col-8 col-sm-6 col-md-6 col-lg-3">
                      <div class="form-group mb-1">
                        <label for="allotedto">Stockist Name</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select Stockist Name" v-model="user.stockist"  :options="user.stockistlist" @input="selstokist" />
                      </div>
                    </div>
                    <div class="col-8 col-sm-6 col-md-6 col-lg-3">
                      <div class="form-group mb1">
                        <label for="allotedto">User ID</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select User ID" v-model="user.userid" :options="user.userlist"/>
                      </div>
                    </div>
                    <div class="col-8 col-sm-6 col-md-6 col-lg-3">
                        <button type="button" class="btn btn-relief-primary mt-2 mr-1" @click="getActDeactUsersList()" > 
                        <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                        <button type="reset" class="btn btn-outline-secondary mt-2 mr-1"  v-on:click="resetRecords()">
                        <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                        </div>
                  </div>
                  
                </form>
              </div>
            </div>
            <div class="card filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor:pointer">
                  <div class="container-fluid">
                    <div class="row row fc_my-05">
                      <div class="w-auto px-1 mobile-padding-0-2">
                        <div class="avatar bg-light-secondary rounded d-inline-flex">
                          <div class="avatar-content">
                            <monitor-icon
                              size="1.5x"
                              class="custom-class avatar-icon font-medium-3"
                            ></monitor-icon>
                          </div>
                        </div>
                        <h4 class="card-title d-inline-flex ml-1">User Activation/Deactivation Details</h4>
                      </div>
                      <div class="ml-auto d-flex ">
                        <div class="text-right mr-1">
                          <button type="button" class="btn btn-relief-primary ml-1"  id="submitbtnt41" :disabled="isChecked == false" @click="activateUser">Activate User</button>
                        <button type="button" class="btn btn-relief-primary ml-1"   id="submitbtnt42" :disabled="isChecked == false" @click="deactivateUser">Deactivate User</button>
                      </div>
                        <span class="rotetions_view_arrow ml-auto mx-1"> 
                          <em class="las la-angle-down"></em>
                        </span>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="card-body pt-1" >
                <div class="row">
                  <div class="col-md-12">
                    <div class="tab-content" id="projectroleconfigtable">
                      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="row" id="proModuleList">
                          <div class="col-12">
                            <div class="card">
                              <div class="table-responsive">
                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="align-middle text-center srno first-col-sticky py-1">Sr#</th>
                                      <th scope="col" class="align-middle text-center" style="width: 180px;">Stockist Name</th>
                                      <th scope="col" class="align-middle text-center">User ID </th>
                                      <th scope="col" class="align-middle text-center">Account Current Status</th>
                                      <!-- <th scope="col" class="align-middle text-center clsaction">Remark</th> -->
                                      <th scope="col" class="align-middle text-center clsaction">Last Modified Date</th>
                                      <th scope="col" class="align-middle text-center clsaction">Last Modified By</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data,index) in allusersList">   
                                      <tr v-bind:key="index">
                                          <td data-column="Sr#" >
                                              <span class="">
                                                  <label :for="index+'-'+data.userID"></label> 
                                                  <input type="checkbox" :id="index+'-'+data.id" class="delete_checkbox" v-model="isChecked[index]" v-on:click="empSelection(data.userID)">
                                              </span>
                                              <span class="ml-1"> {{ index+1+currPageNum*pagelimit }} </span>

                                          </td>
                                          <td data-column="Employee ID" class="align-middle text-center">{{data.department ? data.department:'NA'}}</td>
                                          <td data-column="Employee Name" class="align-middle text-center">{{data.userID ? data.userID:'NA'}}</td>
                                          <td class="align-middle text-center">
                                          <div v-if="data.isActive=== 1 " class="badge badge-pill badge-light-warning capitalisetext mr-50">{{ data.isActive === 1 ? 'Active' : data.isActive === 0 ? 'Inactive' : 'NA' }}</div>
                                                <div v-else-if="data.isActive === 0" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{ data.isActive === 1 ? 'Active' : data.isActive === 0 ? 'Inactive' : 'NA' }}</div>
                                          </td>
                                          <!-- <td data-column="Employee Name" class="align-middle text-center">{{data.remark ? data.remark:'NA'}}</td> -->
                                          <td data-column="Employee Name" class="align-middle text-center">{{dateMonthFormat(data.updatedAt)}}</td>
                                          <td data-column="Employee Name" class="align-middle text-center">{{data.updatedBy ? data.updatedBy:'NA'}}</td>

                                      </tr>
                                      
                                  </template></tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter text-center">
                      <pagination 
                      v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <style>
    .table_input_width_150 tbody td{
      min-width: 150px;
    }
    .createbtnparentdiv{
      padding-right: 0% !important;
    }
    .displayblk{
      display:block !important;
    }
    .createbtn{
      border-color: #053e52 !important;
      color: #fff !important;
      background-color: #008000 !important;
      padding: 0.386rem 0.5rem !important;
    }
    .btn_deletedata .btn_deletedata_icons{
      width: 24px;
      height: 24px;
      margin: 5px 10px;
    }
  </style>
  <script>
      import axios from 'axios'
      import apiUrl from '../../constants';
      import moment from 'moment'
      import VueElementLoading from 'vue-element-loading';
      import vSelect from 'vue-select'
      import { EyeIcon, SearchIcon, MoreVerticalIcon, Edit2Icon, DownloadIcon,ExternalLinkIcon,PlusSquareIcon, PlusCircleIcon, MonitorIcon, Trash2Icon,XIcon } from 'vue-feather-icons'
      import Treeselect from '@riophae/vue-treeselect'
      import '@riophae/vue-treeselect/dist/vue-treeselect.css'
      import DatePicker from "vue2-datepicker";
      import Pagination from 'vue-pagination-2'
      import Loading from 'vue-loading-overlay';
      import 'vue-loading-overlay/dist/vue-loading.css';
      import { json } from 'body-parser';
      import commonMethods from '../../utils/commonMethods';
  
    
    export default {
      name:'Activeuserdetails',
      mixins: [ commonMethods ],
      components:{
        Loading,
        VueElementLoading,PlusCircleIcon,
        vSelect,
        EyeIcon,
        Treeselect,
        SearchIcon,
        Pagination,
        MoreVerticalIcon,
        DownloadIcon,
        Edit2Icon,
        PlusSquareIcon,
        ExternalLinkIcon,
        MonitorIcon,Trash2Icon,XIcon
      },    
      data() {
        return {
          isLoading: false,
          fullPage: true,
          isAddState:false,
          totalcount: 0,
          pageCount: 0,
          page:1,
          pagelimit:apiUrl.LIMIT,
          currPageNum: 0,
          pageoffset:1,
          isRequestActive: false,
          isDetailsActive: false,
          card_content:'card-content',
          collapse:'collapse',
          user:{
            userid: null,
            userlist: [],
            stockistlist: [],
            stockist: null,
            empid:null
  
          },
          tokendata: null,
          isRequestActive2: false,
          isDetailsActive2: false,
          allusersList:[],
          selectedIds: [],
          isChecked: [],
          showtabledata: true

        }
      },
      mounted(){
      //   let permission = this.$Service.verifyAccess();
      //   if(permission){
          var userdata = window.localStorage.getItem('userdata');
          if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.loginusername=userdata.username
            this.loginuscondition = userdata.userid 
            this.tokendata = window.localStorage.getItem('token');  
            this.camptoken = window.localStorage.getItem('camptoken')
          }
          this.getActDeactUsersList()
          this.getAllStokistList()
          // this.getStokisWisUser()
          $(".filter_rotation_onlick").click(function(){
          $(this).toggleClass("common_arrow_rotetion");
        });
      //   }
      },
      methods:{
        myequestcollapse:function(){
          this.isRequestActive = !this.isRequestActive
        },
        mydetailscollapse:function(){
          this.isDetailsActive = !this.isDetailsActive
        },
        dateMonthFormat(value) {
        if (!value) return "NA";
          return moment(String(value)).format('DD-MM-YYYY HH:mm');
        },
        cleardata(){
          this.user.empid = null;
          this.user.emplist = [];
          this.user.emplist1 = [];
          this.isDetailsActive = false;
          this.totalcount = 0;
        },
        clickCallback: function (pageNum) {
          this.pageoffset = pageNum;
          this.page = pageNum;
          this.currPageNum = pageNum - 1;
          this.getActDeactUsersList()
        },
        empSelection(userID) {
            const index = this.selectedIds.indexOf(userID);
            if (index === -1) {
                this.selectedIds.push(userID);
            } else {
                this.selectedIds.splice(index, 1);
            }
            console.log(this.selectedIds,'selectedids===>')
        },

        getActDeactUsersList() {
            this.isLoading = true;
            this.input = {
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                camptoken: this.camptoken
            }   
            this.input.department = this.user.stockist ? this.user.stockist: null
            this.input.userID = this.user.userid ? this.user.userid: null
            axios({
                method: 'POST',
                url: 'api/thirdpartyfcclock/getActDeactUsersList',
                data: this.input,
                headers: { 'authorization': this.tokendata }
            })
            .then(result => {
                this.isLoading = false;
                if (result.data.errorCode === 0) {
                    this.allusersList = result.data.data.rows.data;
                    this.totalcount =result.data.data.rows.totalCount;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                } else if (result.data.errorCode === 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.totalcount = 0;
                    this.pageCount = 0;
                    this.allusersList = [];
                }
            })
            .catch(e => {
                this.displayError(e);
            });
        },

        getAllStokistList() {
            this.isLoading = true;
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }            
            axios({
                method: 'POST',
                url: 'api/thirdpartyfcclock/getStokistList',
                data: this.input,
                headers: { 'authorization': this.tokendata }
            })
            .then(result => {
                this.isLoading = false;
                if (result.data.errorCode === 0) {
                    this.user.stockistlist = result.data.data.rows.data;
                } else if (result.data.errorCode === 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.totalcount = 0;
                    this.pageCount = 0;
                    this.allusersList = [];
                }
            })
            .catch(e => {
                this.displayError(e);
            });
        },

        selstokist(state){
          if(state){
            this.getStokisWisUser(state.toString());
          }
        },

        getStokisWisUser(stokist){
        this.isLoading = true;
        this.input ={
          useremail: this.undt.username,
          empcode: this.undt.userid,
          department: stokist
        }
        axios({
          'method': 'POST',
          'url': 'api/thirdpartyfcclock/getUserList',
          'data': this.input,
          'headers': {'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.errorCode == 0){
            this.user.userlist = result.data.data.rows;
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.user.userlist = [];
          }
        }).catch(e => {
          this.displayError(e);
        });
      },
      activateUser() {
          this.bulkActDeactivation(1);
      },
      deactivateUser() {
          this.bulkActDeactivation(0);
      },
      bulkActDeactivation(flag) {
          this.input = {
              useremail: this.undt.username,
              empcode: this.undt.userid,
              userid: this.selectedIds,
              isActive: flag
          }
          let apiURL = 'api/thirdpartyfcclock/bulkActDeactivation'
          axios({
              'method': 'POST',
              'url': apiURL,
              'data': this.input,
              'headers':{'authorization':this.tokendata}
          }).then(result => {
          if(result.data.errorCode === 0) {
              this.getActDeactUsersList();
              Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
              })
          } else if(result.data.errorCode == 3){
              Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
              buttonsStyling: !1,
              })
          } else {
                  Swal.fire({
                  text: result.data.msg,
                  icon: "info",
                  customClass: { confirmButton: "btn btn-primary" },
                  buttonsStyling: !1,
              })
              this.getActDeactUsersList();

          }
              this.isChecked = []
          })
      },
      resetRecords(){
        this.user.userid =  null,
        this.user.stockist = null,
        this.bulkActDeactivation()
      }


    },
    filters: {
      basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
      }
    }
  }
  </script>