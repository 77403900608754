<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12 d-flex">
              <h3 class="content-header-title float-left mb-2">Appraisal Applicable Employees</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link to='/appraisalcyclemaster'>Appraisal Cycle</router-link></li>
                  <li class="breadcrumb-item">Send Pending Mails</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card border-warning add_apprisalcycle_view" >
            <div>
              <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchrequest">
                    <div class="container-fluid" >
                        <div class="row" >
                            <div class="w-auto px-1 mobile-padding-0-2">
                              <div class="avatar bg-light-secondary rounded d-inline-flex">
                                  <div class="avatar-content">
                                      <search-icon
                                          size="1.5x"
                                          class="custom-class avatar-icon font-medium-3"
                                      ></search-icon>
                                  </div>
                              </div>
                            <h4 class="card-title d-inline-flex ml-1">Search Employee</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: searchFilter},card_content,collapse]">
                  <div class="card-body pt-1">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                          <label class="form-label lable-left">Department</label>
                          <treeselect class="allotedto capitalisetext" placeholder="Search Name of the Appraisal" :clear-on-select="true" :options="departmentList" v-model="search.department"/>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                          <label class="form-label lable-left">Email ID</label>
                          <treeselect class="allotedto" placeholder="Search Employee Email"  :clear-on-select="true" :options="empemailist" v-model="search.emailid" v-on:search-change="createdByChangeSearch"/>
                      </div>
                      <div class="col-sm-6 col-md-12 col-lg-12 mb-0  mobile-px-0 text-right">
                          <button type="submit" class="btn btn-relief-primary mt-2 mr-1"  @click="getApplicableAppraisalEmp(search)"> 
                          <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                          <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                          <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                  <div class="rows" style="display: flex;justify-content: space-between;align-items: center;">
                    <span class="capitalisetext mb-1 ml-1"><b>Appraisal Cycle Name: </b>{{this.appraisal.name}}</span>
                    <ul class="listGrid d-inline-flex">
                      <li class="cursorpointercls"> 
                        <button type="submit" class="btn btn-relief-primary mb-1 mr-1" @click="scheduleAppraisalMails()"
                          :disabled="appraisal.applicableEmplist.length && !appraisal.isChecked.some(bool => bool === true)">
                          <edit-icon size="1.5x" class="custom-class"></edit-icon><span> Schedule Emails</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="card border-warning" id="domain-list">
                  <div class="card-body p-0 ps ps--active-x">
                    <div class="table-responsive" >
                      <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                        <thead>
                          <tr>
                            <th scope="col" class="text-center" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                            <th scope="col">
                              <span>
                                <label for="select_all_checkboxes"></label>
                                <input type="checkbox" id="select_all_checkboxes" class="visuallyhidden" v-on:change="selectAll" v-model="appraisal.isSelectAllChecked" :disabled="!appraisal.applicableEmplist.length">
                              </span>
                            </th>
                            <th scope="col" class="text-center first-col-sticky">Reviewer</th>
                            <th scope="col" class="text-center">Email</th>
                            <th scope="col" class="text-center">Tracker (id)</th>
                            <th scope="col" class="text-center">Department</th>
                            <th scope="col" class="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data,index) in appraisal.applicableEmplist"  >
                                
                            <tr v-bind:key="index" >
                              <td class="text-center">{{ index+1+currPageNum*pagelimit }}</td>
                              <td>
                                <span>
                                  <label :for="index + '-' + data.appraisalmasterid"></label>
                                  <input type="checkbox" :id="index + '-' + data.id" v-model="appraisal.isChecked[index+currPageNum*pagelimit]" :disabled="data.status"
                                    class="delete_checkbox" v-on:change="alterSelectAllCheck(index+currPageNum*pagelimit, data.id)">
                                </span>
                              </td>
                              <td class="text-center capitalisetext  first-col-sticky">{{ data.reviewer + ` (${data.reviewerempid})`  }}</td>
                              <td class="text-center">{{ decryptText(data.emailid) }}</td>
                              <td class="text-center capitalisetext">{{ data.tracker }}</td>
                              <td class="text-center capitalisetext">{{ decryptText(data.department) }}</td>
                              <td class="text-center capitalisetext">{{ !data.status ? 'Pending' : "completed" }}</td>
                            </tr>
                          </template>
                          <template v-if="appraisal.applicableEmplist.length==0">
                            <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                    <div class="pageCenter text-center mt-4">
                      <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                    </div>
                  </div>
                </div>             
              
            </div>
          </div>
        </section>
      </div>
    </div>
</template>

<script>
  import axios from "axios";
  import VueElementLoading from "vue-element-loading";
  import vSelect from "vue-select";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
  import CKEditor from '@ckeditor/ckeditor5-vue2';
  import {Trash2Icon,PlusSquareIcon, MinusSquareIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, XIcon, UserIcon} from "vue-feather-icons";
  import apiUrl from '../../constants';
  import Pagination from 'vue-pagination-2'
  import commonMethods from '../../utils/commonMethods';
  export default {
    name: 'ApplicableAppraisalEmp',
    mixins: [ commonMethods ],
    components: {
      Loading,
      VueElementLoading,Trash2Icon,PlusSquareIcon, MinusSquareIcon,
      vSelect,
      Treeselect,
      DatePicker,
      ckeditor: CKEditor.component,
      Pagination,
      EditIcon,
      CheckCircleIcon,
      XCircleIcon,
      SearchIcon,
      XIcon,
      UserIcon
    },
    data() {
      return {
        appraisal: {
          name: null,
          id: null,
          applicableEmplist: [],
          isChecked: [],
          checkedIds: [],
          isSelectAllChecked: false
        },
        isLoading: false,
        fullPage: true,
        appraisalName: "",
        appraisalCyclePeriodFrom: null,
        appraisalCyclePeriodTo: null,
        description: null,
        editor: ClassicEditor,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,

        searchFilter: false,
        collapse:'collapse',
        card_content:'card-content',
        search :{
          appraisalName: null,
          department: null,
          emailid: null
        },
        isBothDateSelected:false,
        editdateFrom: null,
        editdateTo: null,
        departmentList:[],
        empemailist: [],
        apprasialCycleName: []
      };
    },
    mounted() {
    // let permission = this.$Service.verifyAccess();
      // if(permission){
        let userdata = window.localStorage.getItem("userdata");
        if (userdata) {
          userdata = JSON.parse(userdata);
          this.undt = userdata;
          this.tokendata = window.localStorage.getItem('token');
          this.loginuscondition = userdata.userid;
          this.appraisal.id = this.$route.query.id
          this.getApplicableAppraisalEmp()
          this.getDepartmentList()
          // this.getApprasialCycleName()
        }
      // }
    },
    methods:{
      searchrequest(){
        this.searchFilter = !this.searchFilter
      },
      clickCallback: function(pageNum) {
        this.pageoffset=pageNum
        this.currPageNum = pageNum - 1
        this.getApplicableAppraisalEmp();
        setTimeout(function(){
        }, 500);
      },
      getApplicableAppraisalEmp(search) {
        if(!this.appraisal.id) {
          this.sweetAlertSnackbar('Could not display data as Appraisal details were not provided!')
          return
        }
        this.isLoading = true
        this.input = {
          appraisalmasterid: this.appraisal.id,
          useremail  : this.undt.username,
          empcode: this.undt.userid,
          limit: apiUrl.LIMIT,
          offset: this.pageoffset,
        }

        if (search){
          this.input.deptid = this.search.department
          this.input.emailid = this.search.emailid
        }

        let url = 'api/master/getApplicableAppraisalEmpWithReviewers'

        axios({
          method: 'POST',
          url,
          data: this.input,
          headers: { authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.appraisal.applicableEmplist = result.data.data.rows
            this.appraisal.name = this.appraisal.applicableEmplist[0].name
            this.totalcount = result.data.data.count
            if(!this.appraisal.isChecked.length) {
              this.appraisal.isChecked = Array(this.totalcount).fill(false)
            }
          } else {
            this.appraisal.applicableEmplist = []
            this.totalcount = []
            this.appraisal.isChecked = []
            this.sweetAlertSnackbar(result.data.msg, 'error')
          }
        })
        .catch(e => {
          this.displayError(e)
        })
      },
      getDepartmentList() {
        this.input = {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid)
        }
        axios({
          'method': 'POST',
          'url': 'api/formconfigure/getEmpDepartList',
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false
          if(result.data.status == true){
              this.departmentList = result.data.data.rows
              let decryptedDepartmentList = this.departmentList.map(item => {
                let temlebel =[];
                temlebel = this.decryptText(item.label);
                item.label = temlebel;
                return item;
              });
              if(result.data && result.data.data){
                if(result.data.data.orows && result.data.data.orows.length >0){
                  this.department = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
                }
              }
              let dp = this.departmentList.filter((v) => {
                  if(v.id==this.departmentid)
                  return v;
                  });
              this.department = dp ? dp.label : null
            }else{
              this.departmentList=[]
          }
        }).catch(e => {
          this.displayError(e)
        })
      },
      createdByChangeSearch: function (node){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/listing/getAllEmpEmailIds";
          this.input = {
            emailid:node.toLowerCase(),
            empcode: this.undt.userid,
            useremail: this.undt.username
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.empemailist = result.data.data;
            } else {
              this.empemailist = [];
            }
          }).catch(e => {
          this.displayError(e)
        });
        } 
      },
      getApprasialCycleName(){
        this.input = {
          empcode: this.undt.userid,
          useremail: this.undt.username
        }
        let url = 'api/master/getApplicableAppraisalCycle'

        axios({
          method: 'POST',
          url: url,
          data: this.input,
          headers: {'authorization':this.tokendata}
        }).then((result) => {
          if (result.data.errorCode == 0){
            this.apprasialCycleName = result.data.data.rows
            console.log(this.apprasialCycleName)
          } else {
            this.apprasialCycleName = []
          }
        }).catch(e => {
          this.displayError(e)
        })
      },
      resetRecords(){
       this.search.appraisalName = null,
       this.search.department= null,
       this.search.emailid= null 
       this.getApplicableAppraisalEmp()
      },
      selectAll() {
        if (!this.appraisal.isSelectAllChecked) {
          this.appraisal.checkedIds = []
          this.appraisal.isChecked = Array(this.totalcount).fill(false)
        } else {
          this.appraisal.isChecked = Array(this.totalcount).fill(true)
          this.appraisal.checkedIds = Array(this.totalcount).fill(0)
        }
      },
      alterSelectAllCheck(index, id) {
        if (this.appraisal.isChecked[index]) {
          if(!this.appraisal.checkedIds.includes(id)) {
            this.appraisal.checkedIds.push(id)
          }          
        } else {
          let index = this.appraisal.checkedIds.indexOf(id)
          if(index > -1) {
            this.appraisal.checkedIds.splice(index, 1)
          }
        }
        this.appraisal.isSelectAllChecked = this.appraisal.isChecked.every(i => i === true)
      },
      scheduleAppraisalMails() {
        console.log(this.appraisal.isSelectAllChecked)
        console.log(this.appraisal.checkedIds)
        this.isLoading = true
        let method = 'POST'
        let url = `api/appraisal/statusUpdationToSchedulerAppraisalMails/${this.appraisal.id}`
        let payload = {
          isSelectAllChecked: this.appraisal.isSelectAllChecked,
          checkedIds: this.appraisal.checkedIds
        }
        axios({
          method,
          url,
          data: payload,
          headers: {
            authorization: this.tokendata,
            useremail: this.undt.username,
            empcode: this.undt.userid
          }
        })
        .then(result => {
          this.isLoading = false
          if(result.data.status) {
            this.sweetAlertSnackbar(result.data.message)
            this.getApplicableAppraisalEmp()
            this.appraisal.isChecked = []
          } else {
            this.sweetAlertSnackbar(result.data.message, 'error')
          }
        })
        .catch(e => {
          this.isLoading = false
          this.displayError(e)
        })
      },
    }
  }
</script>