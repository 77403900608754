<template>
  <div id="hiring_team" class="content">
    <div class="container mobile-px-0">
            <div class="card">
               <div class="container mt-1 px-0">
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/hrms/jobopenings'></router-link></li>
                    </ul>
                    <div class="col-md-12 text-right mt-1 mb-1 text-warning pl-0">
                        <span class="required-field"> All fields marked with asterisk are required.</span>
                    </div>
                    <div class="row mx-0">
                        <div class="col-md-6 mb-1">
                            <label> Select Hiring Lead<span class="required-field"></span></label>
                            <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 3 chars to search Hiring Lead" v-model="hiring.lead" :options="empRoleData" :clear-on-select="true" :multiple="true" @close="validateForm()" v-on:search-change="getemprole"/>
                            <div class="errorinputmsg" v-if="this.errors.lead">{{ this.errors.lead }}</div>
                        </div>
                        <div class="col-md-6 mb-1">
                            <label> Select Shortlisting Team<span class="required-field"></span></label>
                            <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 3 chars to search Shortlisting Team" v-model="hiring.shortlisting" :options="empRoleData" :clear-on-select="true" :multiple="true" @close="validateForm()" v-on:search-change="getemprole"/>
                            <div class="errorinputmsg" v-if="this.errors.shortlisting">{{ this.errors.shortlisting }}</div>
                        </div>
                        <div class="col-md-6 mb-1">
                            <label> Select Screening Team<span class="required-field"></span></label>
                            <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 3 chars to search Screening Team" v-model="hiring.screening" :options="empRoleData" :clear-on-select="true" :multiple="true" @close="validateForm()" v-on:search-change="getemprole"/>
                            <div class="errorinputmsg" v-if="this.errors.screening">{{ this.errors.screening }}</div>
                        </div>
                        <div class="col-md-6 mb-1">
                            <label> Select Scheduling Team<span class="required-field"></span></label>
                            <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 3 chars to search Scheduling Team" v-model="hiring.scheduling" :options="empRoleData" :clear-on-select="true" :multiple="true" @close="validateForm()" v-on:search-change="getemprole"/>
                            <div class="errorinputmsg" v-if="this.errors.scheduling">{{ this.errors.scheduling }}</div>
                        </div>
                         <div class="col-md-6 mb-1">
                            <label> Select Hiring Team<span class="required-field"></span></label>
                            <treeselect class="projectlistdd capitalisetext"  placeholder="Enter min 3 chars to search Hiring Team" v-model="hiring.team" :options="empRoleData" :clear-on-select="true" :multiple="true" @close="validateForm()" v-on:search-change="getemprole"/>
                            <div class="errorinputmsg" v-if="this.errors.team">{{ this.errors.team }}</div>
                        </div>
                   </div>
                </div>
            </div>

        </div>
        <div class="buttons_hrms_view text-right px-1">
            <button class="btn btn-relief-secondary btn-prev4 hrms-bt-mr-5" @click="prev3">
                <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                <span class="align-middle d-sm-inline-block d-none">Previous</span>
            </button>
            <button class="btn btn-relief-success btn-next4 hrms-bt-mr-5" @click="submitData($event,'0')">
                <span class="align-middle d-sm-inline-block">Save as Draft</span>
            </button>
            <button class="btn btn-primary" @click="submitData($event,'1')">Submit</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueTimepicker from 'vue2-timepicker'
import commonMethods from '../../../utils/commonMethods';


export default({
    mixins: [ commonMethods ],
    name:'HiringTeam',
    components:{
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,DatePicker,VueTimepicker,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
    },
    data() {
        return {
            
            isLoading: false,
            empRoleData: [],
            
            hiring: {
                lead: null,
                shortlisting: null,
                screening: null,
                scheduling: null,
                team: null,
            },
            errors: {
                lead: null,
                shortlisting: null,
                screening: null,
                scheduling: null,
                team: null,  
            }
        }
    },
    props:['globaljdid'],
    mounted() {
        $(function () {
            'use strict';
            var bsStepper = document.querySelectorAll('.bs-stepper'),
                select = $('.select2'),
                modernWizard = document.querySelector('.modern-wizard-example')
            // select2
            select.each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                placeholder: 'Select value',
                dropdownParent: $this.parent()
                });
            });
            if (typeof modernWizard !== undefined && modernWizard !== null) {
                var modernStepper = new Stepper(modernWizard, {
                linear: false
                });
                $(modernWizard)
                .find('.btn-next4')
                .on('click', function () {
                    modernStepper.next();
                });
                $(modernWizard)
                .find('.btn-prev3')
                .on('click', function () {
                    modernStepper.previous();
                });
            }
        });
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.tokendata = window.localStorage.getItem('token');
            }
        },
    methods: {
        getemprole(node) {
            if (node && node.length > 1) {
                this.isLoading = true;
                this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empname: node
                }
                axios({
                    'method': 'POST',
                    'url': 'api/hrms/getemprole',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                    this.empRoleData = result.data.data.rows
                }else{
                        this.empRoleData=[]
                    }
                }).catch(e => {
                 this.displayError(e)
                });
            }
        },
        submitData(objThis,isactive){
            if (this.globaljdid && this.globaljdid!=0){
                let valid = this.validateForm(objThis);
                if (valid) {
                this.loading = true
                    this.input = {
                        empcode:  parseInt(this.undt.userid),
                        useremail: this.undt.username,
                        jdid: this.globaljdid,
                        lead: this.hiring.lead,
                        shortlisting: this.hiring.shortlisting,
                        screening: this.hiring.screening,
                        scheduling: this.hiring.scheduling,
                        team: this.hiring.team,
                        isactive: isactive
                    }
                    let apiURL = 'api/hrms/savehiringteamdetails'
                    axios({
                        'method': 'POST',
                        'url': apiURL,
                        'data': this.input,
                        'headers':{'authorization':this.tokendata}
                    }).then(result => {
                        this.isLoading = false;        
                        if(result.data.errorCode == 0){
                            Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                            })
                            if (isactive == 1) {
                                this.hiring.lead=null;
                                this.hiring.scheduling=null;
                                this.hiring.screening=null;
                                this.hiring.shortlisting=null;
                                this.hiring.team=null;
                                this.mysetGlobaljdid(null);
                                window.location.reload();
                            }
                        }else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                                }).then(function() {
                                window.location.href = "/#/login";
                            })
                        }
                        else {
                            Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                            })
                        }
                    }).catch(e => {
                     this.displayError(e)
                    })
                }
            }else{
                Swal.fire({
                    title: "Failed",
                    text: 'Job Details Id Is Required',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        },
        validateForm: function(){
            let isValid=true;
            if (!this.hiring.lead || this.hiring.lead.length==0) {
                this.errors.lead = 'Lead is Required'
                isValid = false;
            } else {
                this.errors.lead = ''
            }
            if (!this.hiring.shortlisting || this.hiring.shortlisting.length==0) {
                this.errors.shortlisting = 'Shortlisting is Required'
                isValid = false;
            } else {
                this.errors.shortlisting = ''
            }
            if (!this.hiring.scheduling || this.hiring.scheduling.length==0) {
                this.errors.scheduling = 'Scheduling is Required'
                isValid = false;
            } else {
                this.errors.scheduling = ''
            }
            if (!this.hiring.screening || this.hiring.screening.length==0) {
                this.errors.screening = 'Screening is required'
                isValid = false;
            } else {
                this.errors.screening = ''
            }
            if (!this.hiring.team || this.hiring.team.length==0) {
                this.errors.team = 'Team is required'
                isValid = false;
            } else {
                this.errors.team = ''
            }

            return isValid
        },
        mysetGlobaljdid(jdid){
            this.$emit('setGlobaljdid',jdid);
        },
        prev3(){
            $('.btn-prev3').click();
        }
    }
})
</script>


