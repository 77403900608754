<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-1">Role And Tracker Wise Field Configuration</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Add Configuration</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning" >
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
              <div class="card-header indent_border_bottom py-1 px-2">
                  <h3 class="card-title">
                      Add Role And Tracker Wise Field Configuration
                  </h3>
                  <div class="ml-auto px-0 text-right mobile_margin-px-0_00" >
                      <div class="input-group-append float-right ml-1">
                          <router-link to="statusconfig/create"><button id="commonaddmodule2" class="btn btn-sm btn-relief-success "   data-backdrop="static" data-keyboard="false">
                          <plus-circle-icon size="1.5x" class="custom-class">
                          </plus-circle-icon> <span class="mobile_hide_create_text">Add Module-Status</span></button></router-link>
                      </div>
                      <div class="input-group-append float-right ml-1">
                        <router-link to="/formsconfig/create" >  
                        <button id="commonaddmodule1" class="btn btn-sm btn-relief-success "   data-backdrop="static" data-keyboard="false">
                          <plus-circle-icon size="1.5x" class="custom-class">
                          </plus-circle-icon> <span class="mobile_hide_create_text">Add Module- Field</span></button></router-link>
                      </div>
                      <div class="input-group-append float-right">
                        <router-link to="/trackermaster" >  
                        <button id="commonaddmodule1" class="btn btn-sm btn-relief-success "   data-backdrop="static" data-keyboard="false">
                          <plus-circle-icon size="1.5x" class="custom-class">
                          </plus-circle-icon> <span class="mobile_hide_create_text">Add Tracker</span></button>
                        </router-link>
                      </div>
                  </div>
              </div>
            <div class="card-body pt-1">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group mb-2">
                      <label for="allotedto">Role</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select role" v-model="roletrack.roleid" :options="rolelist" @select="selrolename" @close="validateForm('t42',$event)"/>
                    <div class="errorinputmsg" v-if="this.errors.roletrack.roleid">{{ this.errors.roletrack.roleid }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb-2">
                        <label for="allotedto">Tracker</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select tracker" v-model="roletrack.trackername" :options="trackerownerlist"  @close="validateForm('t42',$event)" @select="seltrackername"/>
                        <div class="errorinputmsg" v-if="this.errors.roletrack.trackername">{{ this.errors.roletrack.trackername }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb-2">
                        <label for="allotedto">Project</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Enter min 3 chars to search project" v-model="roletrack.project" v-on:search-change="getallprojectlist" :options="projectlist" @select="selproject"/>
                        <div class="errorinputmsg" v-if="this.errors.roletrack.project">{{ this.errors.roletrack.project}}</div>
                    </div>
                  </div>
                </div>
                <div class="card border-warning mb-0" id="performanceList">
                    <div class="card-body p-0">
                        <div class="table-responsive">
                          <div class="mb-1 role_and_tracker_viewinputfiledheight">
                            <table class="table table-sm dataTableListing table-striped all_table_stickys table_input_width_150" v-if="roletrack.trackername == 1" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center first-col-sticky">Current / New Field</th>
                                      <th scope="col" class="text-center" v-for="(data,indexs) in statuslist" v-bind:key="indexs">{{data.label}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 <template>
                                      <tr v-for="(datas,indexx) in fieldlist" v-bind:key="indexx" >
                                        <td class="text-center text-capitalize first-col-sticky">{{datas.label}}</td>
                                        <td class="text-center text-capitalize" v-for="(datass,indexxx) in statuslist" v-bind:key="indexxx">
                                        <span v-if="isKeyPresent(datas.label, datass.id)">
                                            <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                        name="productfilter" :id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" :value="getVariable(datas.label, datass.id)"  @input="onfilterchange(datas.id, datass.id, $event)" />
                                          </span>
                                          <span v-else>
                                           <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                  name="productfilter" v-bind:id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" v-model="[indexxx].checkoption"
                                                  @input="onfilterchange(datas.id,datass.id,$event)" />
                                          </span>
                                        </td>
                                      </tr>
                                  </template>
                              </tbody>
                              </table>
                              <table class="table table-sm dataTableListing table-striped all_table_stickys table_input_width_150" v-if="roletrack.trackername == 2" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center first-col-sticky">Current / New Field</th>
                                      <th scope="col" class="text-center" v-for="(data,indexs) in statuslist" v-bind:key="indexs">{{data.label}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 <template>
                                      <tr v-for="(datas,indexx) in fieldlist" v-bind:key="indexx" >
                                        <td class="text-center text-capitalize first-col-sticky">{{datas.label}}</td>
                                        <td class="text-center text-capitalize" v-for="(datass,indexxx) in statuslist" v-bind:key="indexxx">
                                        <span v-if="isKeyPresent(datas.label, datass.id)">
                                            <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                        name="productfilter" :id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" :value="getVariable(datas.label, datass.id)"  @input="onfilterchange(datas.id, datass.id, $event)" />
                                          </span>
                                          <span v-else>
                                           <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                  name="productfilter" v-bind:id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" v-model="[indexxx].checkoption"
                                                  @input="onfilterchange(datas.id,datass.id,$event)" />
                                          </span>
                                        </td>
                                      </tr>
                                  </template>
                              </tbody>
                              </table>
                              <table class="table table-sm dataTableListing table-striped all_table_stickys table_input_width_150" v-if="roletrack.trackername == 3" aria-describedby="mydesc">
                               <thead>
                                  <tr>
                                      <th scope="col" class="text-center first-col-sticky">Current / New Field</th>
                                      <th scope="col" class="text-center" v-for="(data,indexs) in statuslist" v-bind:key="indexs">{{data.label}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 <template>
                                      <tr v-for="(datas,indexx) in fieldlist" v-bind:key="indexx" >
                                        <td class="text-center text-capitalize first-col-sticky">{{datas.label}}</td>
                                        <td class="text-center text-capitalize" v-for="(datass,indexxx) in statuslist" v-bind:key="indexxx">
                                        <span v-if="isKeyPresent(datas.label, datass.id)">
                                            <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                        name="productfilter" :id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" :value="getVariable(datas.label, datass.id)"  @input="onfilterchange(datas.id, datass.id, $event)" />
                                          </span>
                                          <span v-else>
                                           <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                  name="productfilter" v-bind:id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" v-model="[indexxx].checkoption"
                                                  @input="onfilterchange(datas.id,datass.id,$event)" />
                                          </span>
                                        </td>
                                      </tr>
                                  </template>
                              </tbody>
                              </table>
                              <table class="table table-sm dataTableListing table-striped all_table_stickys table_input_width_150" v-if="roletrack.trackername == 4" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center first-col-sticky">Current / New Field</th>
                                      <th scope="col" class="text-center" v-for="(data,indexs) in statuslist" v-bind:key="indexs">{{data.label}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 <template>
                                      <tr v-for="(datas,indexx) in fieldlist" v-bind:key="indexx" >
                                        <td class="text-center text-capitalize first-col-sticky">{{datas.label}}</td>
                                        <td class="text-center text-capitalize" v-for="(datass,indexxx) in statuslist" v-bind:key="indexxx">
                                        <span v-if="isKeyPresent(datas.label, datass.id)">
                                            <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                        name="productfilter" :id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" :value="getVariable(datas.label, datass.id)"  @input="onfilterchange(datas.id, datass.id, $event)" />
                                          </span>
                                          <span v-else>
                                           <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                                  name="productfilter" v-bind:id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" v-model="[indexxx].checkoption"
                                                  @input="onfilterchange(datas.id,datass.id,$event)" />
                                          </span>
                                        </td>
                                      </tr>
                                  </template>
                              </tbody>
                              </table>
                              <table class="table table-sm dataTableListing table-striped all_table_stickys table_input_width_150" v-if="roletrack.trackername == 35" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center first-col-sticky">Current / New Field</th>
                                      <th scope="col" class="text-center" v-for="(data,indexs) in statuslist" v-bind:key="indexs">{{data.label}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                 <template>
                                      <tr v-for="(datas,indexx) in fieldlist" v-bind:key="indexx">
                                        <td class="text-center text-capitalize first-col-sticky">{{datas.label}}</td>
                                        <td class="text-center text-capitalize" v-for="(datass,indexxx) in statuslist" v-bind:key="indexxx">
                                        <span v-if="isKeyPresent(datas.label, datass.id)">
                                            <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                            name="productfilter" :id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" :value="getVariable(datas.label, datass.id)"  @input="onfilterchange(datas.id, datass.id, $event)"/>
                                          </span>
                                          <span v-else>
                                           <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                          name="productfilter" v-bind:id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" v-model="[indexxx].checkoption"
                                          @input="onfilterchange(datas.id,datass.id,$event)"/>
                                          </span>
                                        </td>
                                      </tr>
                                  </template>
                              </tbody>
                              </table>

                              <table class="table table-sm dataTableListing table-striped all_table_stickys table_input_width_150" v-if="roletrack.trackername == 140" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center first-col-sticky">Current / New Field</th>
                                      <th scope="col" class="text-center" v-for="(data,indexs) in statuslist" v-bind:key="indexs">{{data.label}}</th>
                                  </tr>
                              </thead>
                                <tbody>
                                  <template>
                                        <tr v-for="(datas,indexx) in fieldlist" v-bind:key="indexx">
                                          <td class="text-center text-capitalize first-col-sticky">{{datas.label}}</td>
                                          <td class="text-center text-capitalize" v-for="(datass,indexxx) in statuslist" v-bind:key="indexxx">
                                          <span v-if="isKeyPresent(datas.label, datass.id)">
                                              <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                              name="productfilter" :id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" :value="getVariable(datas.label, datass.id)"  @input="onfilterchange(datas.id, datass.id, $event)" />
                                            </span>
                                            <span v-else>
                                            <treeselect class="projroleassprojectdd" placeholder="Select Option"
                                              name="productfilter" v-bind:id="'checkallusers'+datas.id+datass.id" :options="checkoptionlist" v-model="[indexxx].checkoption"
                                              @input="onfilterchange(datas.id,datass.id,$event)"/>
                                            </span>
                                          </td>
                                        </tr>
                                    </template>
                                </tbody>
                              </table>
                
                          </div>
                            <div v-if="roletrack.trackername == 1">
                                <div class="errorinputmsg" v-if="this.errors.roletrack.checkarray">{{ this.errors.roletrack.checkarray }}</div>
                            </div>
                            <div v-if="roletrack.trackername == 2">
                                <div class="errorinputmsg" v-if="this.errors.roletrack.checkarray">{{ this.errors.roletrack.checkarray }}</div>
                            </div>
                            <div v-if="roletrack.trackername == 3">
                                <div class="errorinputmsg" v-if="this.errors.roletrack.checkarray">{{ this.errors.roletrack.checkarray }}</div>
                            </div>
                             <div v-if="roletrack.trackername == 4">
                                <div class="errorinputmsg" v-if="this.errors.roletrack.checkarray">{{ this.errors.roletrack.checkarray }}</div>
                            </div> 
                            <div v-if="roletrack.trackername == 35">
                                <div class="errorinputmsg" v-if="this.errors.roletrack.checkarray">{{ this.errors.roletrack.checkarray }}</div>
                            </div> 
                            <div v-if="roletrack.trackername == 140">
                                <div class="errorinputmsg" v-if="this.errors.roletrack.checkarray">{{ this.errors.roletrack.checkarray }}</div>
                            </div> 

                            <div class="mb-1" v-if="!roletrack.trackername" style="text-align: center;">Please select role and tracker</div>       
                        </div>
                    </div>
                </div>
                <div class="col-12 mg-top1 text-center">
                    <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t42',$event)" id="submitbtnt42">Submit</button>
                    
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.table_input_width_150 tbody td{
      min-width: 150px;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import { EditIcon, EyeIcon, Trash2Icon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, PlusCircleIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import { json } from 'body-parser';
import commonMethods from '../../utils/commonMethods';


export default {
  name:'FieldTrackerMapping',
  mixins: [ commonMethods ],
  components:{
    Loading,
    VueElementLoading,PlusCircleIcon,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    DownloadIcon,
    Edit2Icon,
    PlusSquareIcon,
    ClipboardIcon,
    ckeditor: CKEditor.component,
    Trash2Icon,
  },    
  data() {
    return {
      isThisBusinessRole: false,
      loginuser:'',
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      editRowData:[],
      chkselectfieldlist: [],
      TimeFormat: apiUrl.TimeFormat,
      taskstatuslist:apiUrl.status,
      issustatuslist:apiUrl.issueStatuslist,
      tcscriptstatuslist:apiUrl.tcscriptstatuslist,
      tcsbulkmappingstatuslist: apiUrl.tcsbulkmappingstatuslist,
      testcyclestatuslist:apiUrl.testcyclestatuslist,
      moduletrackerid: apiUrl.moduletrackerid,
      incidentstatusopts: [{
            id:'open',
            label:'Open'
        },{
            id:'resolved',
            label:'Resolved'
        },{
            id:'closed',
            label:'Closed'
        },{
            id:'reopen',
            label:'Re-open'
        }],
      roletrack:{
        rolename:null,
        roleid: null,
        trackername:null,
        project:null,
        checkarray: {}
      },
      isAddState:false,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      showtabledata:true,
      showproject:false,
      modulelist:[],
      isRequestActive:true,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      errors:{
        roletrack:{
          rolename:null,
          roleid: null,
          trackername:null,
          checkarray: null,
        }
      },
      taskownerlist: [
        { id: "Product", label: "Product" },
        { id: "Tech", label: "Tech" },
        { id: "QA", label: "QA" },
        { id: "Security", label: "Security" },
        { id: "Infra", label: "Infra" },
      ],
      trackerownerlist: [],
      projectlist:[],
      isedit: false,
      card:'card',
      border_warning:'border-warning',
      statusrowid: null,
      statuscolumnid: null,
      checkarray: [],
      mastercheckarray: [],
      rolelist: [],
      newarray: [],
      validation: 'validation',
      admintypelist:apiUrl.admintypelist,
      blockertypes: apiUrl.blockertypes,
      fieldlist: [],
      statuslist: [],
      checkoptionlist: [
      { id: "Required", label: "Required" },
      { id: "Not-Required", label: "Not-Required" },
      { id: "Read-Only", label: "Read-Only" }
      ],
      dynamicfieldlist: [
      { id: "Dynamic Ticket Name", label: "Dynamic Ticket Name" },
      { id: "Ticket Name", label: "Ticket Name" },
      { id: "Ticket Type", label: "Ticket Type" }
      ],
      checkoption: null
    }
  },
  mounted(){
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem('userdata');
      if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');  
          this.getThirdPartyAPIcall();
          this.getTrackerList();
          this.admintypelist =  this.admintypelist.map((file) => {
              return {id:file.label,label:file.label}                
          });
      }
    }
  },
  methods:{
    getallfields(tracks,projectid){
      this.isLoading = true;
      this.inputfield= {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        trackername: tracks
      }
      if(tracks== this.moduletrackerid){
        this.inputfield.projectid = parseInt(projectid)
      }
      axios({
        'method': 'POST',
        'url': 'api/fieldtracker/getallfields',
        'data': this.inputfield,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false;
        if(result.data.status == true){
          this.fieldlist = result.data.data.rows
        }else{
          this.fieldlist=[]
        }
      }).catch(e => {

this.displayError(e)

})
    },
     isKeyPresent(dataslabel, datassid) {
    if (this.chkselectfieldlist.hasOwnProperty(dataslabel) && this.chkselectfieldlist[dataslabel].hasOwnProperty(datassid)) {
      return true; // Return boolean true if condition is met
    } else {
      return false; // Return boolean false if condition is not met
    }
  },
  getVariable(dataslabel, datassid) {
    if (this.isKeyPresent(dataslabel, datassid)) {
      var parameter = Object.values(this.chkselectfieldlist[dataslabel][datassid]);
      const combinedString = parameter.join("");
      const combinedString1 = combinedString.replaceAll(',', '');
      return combinedString1;
    } else {
      return ''; // Return an empty string if condition is not met
    }
  },
    setSelectedParameter(variable) {
      var fieldconfigstring = JSON.stringify(variable[0].fieldconfig);
      var fieldconfigstringarray = JSON.parse(fieldconfigstring);
      var firstindex = "";
      var secondindex = "";
      var chkselectfieldlist1 = [];
      for (var i = 0; i < fieldconfigstringarray.length; i++) {
       var data = "";
        firstindex = Object.keys(fieldconfigstringarray[i][0])[0];
         const textarray = [];
        for (var j = 0; j < fieldconfigstringarray[i][0][firstindex].length; j++) {
          secondindex = Object.keys(fieldconfigstringarray[i][0][firstindex][j])[0];
          var validationText='';
          validationText = fieldconfigstringarray[i][0][firstindex][j][secondindex][0]['validation'][0];
          textarray[secondindex]=validationText;
           data = textarray;
        }
        chkselectfieldlist1[firstindex] = data;
      }
      this.chkselectfieldlist=chkselectfieldlist1;
    },
    getfieldallstatus(tracks){
      this.isLoading = true;
      this.inputfield= {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        trackername:tracks
      }
      axios({
        'method': 'POST',
        'url': 'api/fieldtracker/getfieldallstatus',
        'data': this.inputfield,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false;
        if(result.data.status == true){
          this.statuslist = result.data.data.rows
        }else{
          this.statuslist=[]
        }
      }).catch(e => {

this.displayError(e)

})
    },
    getTrackerList() {
      this.isLoading = true
      let url = 'api/formconfigure/geTrackerList'
      let payload = new Object({
        moduletype: null,
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        activeforrole : 1,
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.trackerownerlist = result.data.data
          this.trackerownerlist.sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.trackerownerlist=[]
        }
      }).catch(e => {

this.displayError(e)

})
    },
    getallprojectlist(node) {
      if (node && node.length>3) {
        this.isLoading = true;
        this.inputfield= {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          projectname: node.toLowerCase()
        }
        axios({
          'method': 'POST',
          'url': 'api/project/getallprojectlist',
          'data': this.inputfield,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.status == true){
            this.projectlist = result.data.data;
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.projectlist = [];
          }
        }).catch(e => {

this.displayError(e)

})
      }
    },
    getThirdPartyAPIcall(){
      this.isLoading = true
      this.pageoffset=1
      this.inputthrid = {
          tbl:'t10',
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
      }
      axios({
        'method': 'POST',
        'url': 'api/projectonlyroleassignment/getdesignations',
        'data': this.inputthrid,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false;
        if(result.data.status == true){
          this.rolelist = result.data.data.rows
          this.rolelist.sort((a, b) => {
              return a.label.localeCompare(b.label);
          });
        }else{
          this.rolelist=[]
        }
      }).catch(e => {

this.displayError(e)

})
    },
    getfieldconfigdatabyid(roleid, trackername,project) {
      this.isLoading = true
      let url = "api/fieldtracker/getfieldconfigdatabyid";
      this.input = {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        roleid: roleid,
        trackername: trackername,
        project:project
      };
  
      axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers': { 'authorization': this.tokendata }
      }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.editRowData = result.data.data;
            this.editRowData = this.editRowData[0]
            this.openEditModule(this.editRowData, 0)
            this.setSelectedParameter(result.data.data)
          } else {
              this.editRowData = [];
              let rows = [];
              this.chkselectfieldlist = [];
              if (this.roletrack.trackername == 1) { 
                rows = this.fieldlist;
              }if (this.roletrack.trackername == 2) { 
                rows = this.fieldlist;
              }if (this.roletrack.trackername == 3) { 
                rows = this.fieldlist;
              }if (this.roletrack.trackername == 4) { 
                 rows = this.fieldlist;
               }if (this.roletrack.trackername == 35) { 
                 rows = this.fieldlist;
               }
               if (this.roletrack.trackername == 140) { 
                 rows = this.fieldlist;
               }
              if(rows != undefined) {
                for(var i=0; i<rows.length;i++) {
                  rows[i]['checked_val'] = [];
                }
              }
          }
      }).catch(e => {

this.displayError(e)

})
    },
    openEditModule: function(currRowData){
      this.editRowData = currRowData;
      let editedData = [];
      let rows = [];
      if (this.roletrack.trackername == 1) {
        this.mastercheckarray = this.editRowData.fieldconfig;
        editedData = this.editRowData.fieldconfig;
        rows = this.fieldlist;
      } if (this.roletrack.trackername == 2) {
        this.mastercheckarray = this.editRowData.fieldconfig;
        editedData = this.editRowData.fieldconfig;
        rows = this.fieldlist;
      }if (this.roletrack.trackername == 3) {
        this.mastercheckarray = this.editRowData.fieldconfig;
        editedData = this.editRowData.fieldconfig;
        rows = this.fieldlist;
      }if (this.roletrack.trackername == 4) {
         this.mastercheckarray = this.editRowData.fieldconfig;
         editedData = this.editRowData.fieldconfig;
         rows = this.fieldlist;
       }if (this.roletrack.trackername == 35) {
         this.mastercheckarray = this.editRowData.fieldconfig;
         editedData = this.editRowData.fieldconfig;
         rows = this.fieldlist;
       }
       if (this.roletrack.trackername == 140) {
         this.mastercheckarray = this.editRowData.fieldconfig;
         editedData = this.editRowData.fieldconfig;
         rows = this.fieldlist;
       }

      if(editedData != undefined) {
       for(var i=0; i<rows.length;i++) {
          rows[i]['checked_val'] = [];
          rows[i]['isshow'] = []
          editedData.forEach(th => {
            let thhh = Object.keys(th[0])
            var result = thhh.includes(rows[i]['id']);
            if (thhh.includes(rows[i]['id'])) {
              rows[i]['isshow'].push('1')             
            }
           if(result){
            for (let p = 0; p < Object.values(Object.values(th[0])[0]).length; p++) {
              rows[i]['checked_val'].push(Object.values(Object.values(th[0])[0])[p])    
            }
            }
          });
        }
      }
    },
    selrolename(state, value) {
      this.roletrack.roleid = null;
      this.roletrack.roleid = state.id;
      if (!state.id) {
        this.errors.roletrack.roleid = "Role is required";
      } else {
        this.errors.roletrack.roleid = '';
      }
      if (state.id && this.roletrack.trackername) {
        this.getfieldconfigdatabyid(state.id,this.roletrack.trackername);
      }
    },
    seltrackername(state, value) {
      this.roletrack.trackername = null;
      this.roletrack.trackername = state.id;
      if (!state.id) {
        this.errors.roletrack.trackername = "Tracker is required";
      } else if(this.roletrack.trackername != this.moduletrackerid){
        this.errors.roletrack.trackername = '';
        this.getallfields(state.id);
        this.getfieldallstatus(state.id);
      }
       if (this.roletrack.trackername != this.moduletrackerid && this.roletrack.roleid && state.id) {
         this.getfieldconfigdatabyid(this.roletrack.roleid,state.id);
       }
    },
    selproject(state, value){
      this.roletrack.project = state.id;
      
      if(!state.id){
        this.errors.roletrack.project = "Project is required";
      }else{
        this.errors.roletrack.project ='';
        this.getfieldconfigdatabyid(this.roletrack.roleid,this.roletrack.trackername,state.id);
      }
      if(this.roletrack.trackername == this.moduletrackerid){
        this.getallfields(this.moduletrackerid,state.id);
        this.getfieldallstatus(this.moduletrackerid);
        this.getfieldconfigdatabyid(this.roletrack.roleid,this.moduletrackerid,state.id);
      }
      // if(this.roletrack.project = null){
      //   this.getfieldconfigdatabyid(this.roletrack.roleid,this.roletrack.trackername);
      // }
      

    },
    onfilterchange(datas, datass, state,event){
        this.statusrowid = datas;
        this.statuscolumnid = datass;
        let isexist= false
      if (this.statusrowid && this.statuscolumnid && state!=undefined) {
        for (let n = 0; n < this.mastercheckarray.length; n++) {
          for (let m = 0; m < this.mastercheckarray[n].length; m++) {
            if (this.mastercheckarray[n][m].hasOwnProperty(this.statusrowid)) {
              isexist=true
            }
          }
        }
        if(isexist==true){
            for (let n = 0; n < this.mastercheckarray.length; n++) {
              for (let m = 0; m < this.mastercheckarray[n].length; m++) {
                if (this.mastercheckarray[n][m].hasOwnProperty(this.statusrowid)) {

                  removeByKey(this.mastercheckarray[n][m][this.statusrowid], datass);
                 removeEmptyObjects(this.mastercheckarray[n][m][this.statusrowid]);
                 
                  let tempdataslength = Object.values(Object.values(this.mastercheckarray[n][m]))[0].length
                  
                  this.mastercheckarray[n][m][this.statusrowid][tempdataslength] = {}
                  this.mastercheckarray[n][m][this.statusrowid][tempdataslength][this.statuscolumnid] = []
                  this.mastercheckarray[n][m][this.statusrowid][tempdataslength][this.statuscolumnid].push(this.statuscolumnid)
                  this.mastercheckarray[n][m][this.statusrowid][tempdataslength][this.statuscolumnid][0] = {}
                  this.mastercheckarray[n][m][this.statusrowid][tempdataslength][this.statuscolumnid][0][this.validation] = []
                  this.mastercheckarray[n][m][this.statusrowid][tempdataslength][this.statuscolumnid][0][this.validation].push(state)
                }
              }
            }
        }else{
          this.checkarray[0] = {}
          this.checkarray[0][this.statusrowid] = []
          this.checkarray[0][this.statusrowid].push(this.statusrowid)

          this.checkarray[0][this.statusrowid][0] = {}
          this.checkarray[0][this.statusrowid][0][this.statuscolumnid] = []
          this.checkarray[0][this.statusrowid][0][this.statuscolumnid].push(this.statuscolumnid)

          this.checkarray[0][this.statusrowid][0][this.statuscolumnid][0] = {}
          this.checkarray[0][this.statusrowid][0][this.statuscolumnid][0][this.validation] = []
          this.checkarray[0][this.statusrowid][0][this.statuscolumnid][0][this.validation].push(state)
          this.mastercheckarray.push(this.checkarray)
          this.checkarray=[]
        }
        this.errors.roletrack.checkarray = null
      }else{
        if (state==undefined) {
            for (let x = 0; x < this.mastercheckarray.length; x++) {
              for (let y = 0; y < this.mastercheckarray[x].length; y++) {
                if (this.mastercheckarray[x][y].hasOwnProperty(this.statusrowid)) {
                  
                  for(let z1 = 0; z1 < Object.values(this.mastercheckarray[x][y])[0].length; z1++){

                    if (Object.keys(Object.values(this.mastercheckarray[x][y])[0][z1])==this.statuscolumnid) {
                      Object.values(this.mastercheckarray[x][y])[0].splice(Object.values(this.mastercheckarray[x][y])[0][z1], 1)
                    }
                  }
                }
              }
            }
        }
      }
    },
savewizard: function(mdl,objThis){
      this.valid = this.validateForm(mdl,objThis);
      if (this.valid) {
        this.isLoading = true;
        if (this.roletrack.trackername == 1) {
          this.newarray = this.mastercheckarray
        }if (this.roletrack.trackername == 2) {
          this.newarray = this.mastercheckarray
        }  if (this.roletrack.trackername == 3) {
          this.newarray = this.mastercheckarray
        }if (this.roletrack.trackername == 4) {
           this.newarray = this.mastercheckarray
         }if (this.roletrack.trackername == 35) {
           this.newarray = this.mastercheckarray
         }
         if (this.roletrack.trackername == 140) {
           this.newarray = this.mastercheckarray
         }
        this.input = {
          empcode: parseInt(this.undt.userid),
          useremail: this.undt.username,
          fieldarray:this.newarray
        }
        let apiURL='api/fieldtracker/saverolestatusfieldconfig'
        this.input.roleid = this.roletrack.roleid
        this.input.trackername = this.roletrack.trackername
        this.input.project=this.roletrack.project
        axios({
        'method': 'POST',
        'data': this.input,
        'url': apiURL,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => {
        this.isLoading = false;             
        if(result.data.errorCode == 0){
          Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          this.cleardata();
        }else if(result.data.errorCode == 3){
          Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {

this.displayError(e)

})
      }
    },
    validateForm: function(mdl,objThis){
        var isValid=true;
        if(this.roletrack.roleid == null || this.roletrack.roleid == '' || this.roletrack.roleid == []){
            this.errors.roletrack.roleid = 'Role is required';
            isValid= false;
        }else{
            this.errors.roletrack.roleid = null;
        }
        if(!this.roletrack.trackername) {
            this.errors.roletrack.trackername = 'Tracker is required';
            isValid= false;
        } else {
            this.errors.roletrack.trackername = null;
        }
        // if(!this.roletrack.project) {
        //     this.errors.roletrack.project = 'project  is required';
        //     isValid= false;
        // } 
        // else {
        //     this.errors.roletrack.project = null;
        // }
        // if(this.roletrack.trackername == 'Businesstask') {
        //     if(Object.keys(this.checkarray).length === 0){
        //         this.errors.roletrack.checkarray = 'Select field';
        //         isValid= false;
        //     }
        // }
        return isValid;
    },
    cleardata(){
      this.roletrack.roleid = null;
      this.roletrack.trackername = null;
      this.roletrack.project=null;
      this.errors.roletrack.roleid = null;
      this.errors.roletrack.trackername = null;
      this.errors.roletrack.project=null;
      this.errors.roletrack.checkarray = null;
      this.checkarray = [];
      this.newarray = [];
    },
  }, 
  filters: {
    basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
    }
}
}
function removeByKey(data, key) {
  if (Array.isArray(data)) {
    // If the current level is an array, iterate over its elements
    for (let i = 0; i < data.length; i++) {
      removeByKey(data[i], key); // Recursively call the function for each element
    }
  } else if (typeof data === 'object') {
    // If the current level is an object, check if it has the specified key
    if (data.hasOwnProperty(key)) {
      // If the key is found, delete the object
      delete data[key];
    } else {
      // If the key is not found, recursively call the function for each value
      for (let prop in data) {
        removeByKey(data[prop], key);
      }
    }
  }
}


function removeEmptyObjects(data) {
  if (Array.isArray(data)) {
    // If the current level is an array, iterate over its elements
    for (let i = data.length - 1; i >= 0; i--) {
      if (Object.keys(data[i]).length === 0) {
        data.splice(i, 1); // Remove the empty object from the array
      } else {
        removeEmptyObjects(data[i]); // Recursively call the function for each element
      }
    }
  } else if (typeof data === 'object') {
    // If the current level is an object, iterate over its properties
    for (let prop in data) {
      if (Object.keys(data[prop]).length === 0) {
        delete data[prop]; // Remove the empty object from the object
      } else {
        removeEmptyObjects(data[prop]); // Recursively call the function for each value
      }
    }
  }
}
</script>