import Joi from "joi"

const validationOptions = {
  abortEarly: false,
  allowUnknown: true,
  stripUnknown: true,
};

const createAppraisalApplicableEmployees = Joi.array().items(
  Joi.object({
    'Email ID': Joi.string().required(),
  })
)

const createAppraisalTrackersByEmployees = Joi.array().items(
  Joi.object({
    'Email ID': Joi.string().required(),
    'Tracker ID': Joi.number().min(0).required(),
    'Reviewer Email ID': Joi.string().required()
  })
)

export default {
  validationOptions,
  createAppraisalApplicableEmployees,
  createAppraisalTrackersByEmployees,
}