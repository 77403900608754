<template>
  <div class="app-content content">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header-left col-md-9 col-12 mb-1 px-0 pad_top_mar_bot-5">
        <div class="row breadcrumbs-top">
            <div class="col-12">
                <h3 class="content-header-title float-left mb-0">Projects</h3>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to='/dashboard'>Dashboard</router-link></li>
                        <li class="breadcrumb-item sitebluecolor cursorpointercls" >Projects</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="row" v-if="showfilterboxsection">
              <div class="col-12">
                <div class="card card-body" style="margin-top: 10px">
                  <div class="row breadcrumbs-top">
                    <div class="col-12 title">
                      <h4 class="card-title">SEARCH COMPANY DETAILS</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Company Name</label>
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        v-model="search.searchCompany"
                        :options="project.companylist"
                      />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Created By</label>
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        v-model="search.createdby"
                        :options="search.createdbyOpts"
                      />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <button
                        type="submit"
                        class="btn btn-relief-primary mt-2 mr-1"
                        @click="getFilteredData()"
                      >
                        Search
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary mt-2 mr-1"
                        @click="resetRecords()"
                      >
                        Cancel
                      </button>
                      <button 
                       type="move" 
                       class="btn btn-relief-primary mt-2" 
                        @click="ExportXL()"
                        > 
                        Export
                      </button>

                    </div>
                  </div>
                </div>
                <div
                  class="card border-tabs"
                  style="
                    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
                    margin-bottom: 1rem !important;
                    cursor: pointer;
                  "
                  v-on:click="companyCollapse"
                >
                  <div class="card-header">
                    <div
                      class="avatar bg-light-info rounded"
                      style="margin-left: 39%"
                    >
                      <div class="avatar-content">
                        <grid-icon
                          size="1.5x"
                          class="custom-class avatar-icon font-medium-3"
                        ></grid-icon>
                      </div>
                    </div>
                    <h4 class="card-title" style="margin-left: -449px">
                      COMPANY DETAILS
                    </h4>
                    <div class="heading-elements" style="color: blue">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"
                            ><plus-icon
                              size="4x"
                              class="custom-class"
                            ></plus-icon
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="showcompanies" style="margin-top: 10px">
                    <div class="card-body">
                      <div class="row">
                        <div
                          v-for="(data1, index1) in companylist"
                          class="col-lg-3 col-md-6 col-12"
                          v-bind:key="index1"
                        >
                          <div
                            v-bind:key="data1.companyid"
                            class="card border cursor-pointer"
                            :class="[
                              stylevariation[data1.companyid].label,
                              activecompanyid == data1.companyid
                                ? 'bxshadow '
                                : 'shadow-none ',
                            ]"
                            @click="getprojectlist(data1)"
                          >
                            <div class="card-body">
                              <div
                                class="d-flex justify-content-between text-white"
                              >
                                <box-icon
                                  size="40x"
                                  class="custom-class"
                                ></box-icon>
                              </div>
                              <div class="my-1">
                                <h3 class="text-white">
                                  {{ data1.companyname }}
                                </h3>
                                <h5 class="text-white">
                                  {{ dateFormat(data1.createddate) }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid" >
                  <div class="row fc_my-05" >
                    <div
                    class="px-1 w-auto mobile-padding-0-2"
                    
                  >
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                          <search-icon
                            size="1.5x"
                            class="custom-class avatar-icon font-medium-3"
                          ></search-icon>
                        </div>
                      </div>
                    <h4 class="card-title d-inline-flex ml-1">Search Project Details </h4>
                  </div>
                      <div class="ml-auto d-flex">
                    <div
                                    v-if="pagefeatures.createproject"
                                    class=" text-right"
                                  >
                                    <div class="input-group-append displayblk">
                                      <button
                                        id="commonaddmodule"
                                        class="btn btn-sm btn-relief-success"
                                        data-toggle="modal"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :data-target="currentmodule"
                                        @click="addModule($event)"
                                        type="button"
                                        data-backdrop="static"
                                        data-keyboard="false"
                                      >
                                         <span class="mobile_hide_bt">Create {{ glbMdlLbl }}</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon>
                                      </button>
                                    </div>
                                  </div>
                                  <span class="rotetions_view_arrow"> 
                                  <em class="las la-angle-down"></em>
                                </span>
                              </div>

                  </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Company Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Company name" v-model="search.companyname" :options="search.companynameList" @input="selectcompany"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Business Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Business name" v-model="search.businessname" :options="search.businessnameList" @input="selectbusiness"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Project Status</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Project Status" v-model="search.project_status" :options="search.projectstatusList" @input="selectprojectstatus"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-12 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getprojectlist(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span>Clear</span></button>
                      <button type="move" class="btn btn-relief-primary mt-2" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span>Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card data_table_padding_ziro_mobile">
                              <div class="">
                                <div class="row">
                                  <div class="col-md-10 ">
                                    <div class="input-group-append">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="table-responsive datatableview "
                                v-if="showtabledata"
                              >
                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno first-col-sticky align-middle" style="min-width: 45px;">Sr#</th>
                                      <th scope="col" class="align-middle second-col-sticky text-left" style="min-width: 160px;" >Project Name</th>
                                      <th scope="col" class="align-middle text-left" style="min-width: 200px;">Project Details</th>
                                      
                                      <th scope="col" class="align-middle">Business <br>Name</th>
                                      <th scope="col" class="align-middle">Total <br>Applications</th>
                                      <th scope="col" class="align-middle">Total Project <br>Modules</th>
                                      <th scope="col" class="align-middle text-left" style="min-width: 200px;">Created Details</th>
                                      <th scope="col" class="align-middle">Security Assessment</th>
                                      <th scope="col" class="clsaction align-middle">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template  v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="data.moduleid">
                                        <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                        <td data-column="Project Name" class="second-col-sticky text-left">
                                          <span v-if="data.isapproved == '1' && pagefeatures.projectlevel" class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('view', data)">{{ data.projectname }}</span>
                                          <span  v-else  class="font-weight-bold cursorpointercls sitebluecolor capitalisetext">{{ data.projectname }}</span>
                                        </td>

                                        <td data-column="Project Details" class="details_column_table text-left">
                                          <span class="heading_column_text"> Project Level : </span>
                                          <span class="font-weight-normal"> {{projectlevelArr[data.projectlevel - 1]}} </span>
                                          <hr class="hr_border-bottom">
                                          <span class="heading_column_text"> Project Type : </span>
                                          <span class="font-weight-normal"> {{data.typename}} </span>
                                          <hr class="hr_border-bottom">
                                          <span class="heading_column_text"> Company : </span>
                                          <span class="font-weight-normal"> {{data.companyname}} </span>
                                          <hr class="hr_border-bottom">
                                          <span class="heading_column_text"> Project Status : </span>
                                          <span v-if="data.isapproved == '1'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">Active</span>
                                          <span v-if="data.isapproved == '0'" class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">Inactive</span>
                                        </td>
                                        
                                        <td data-column="Business Name">
                                          <span class="font-weight-bold capitalisetext">{{ data.businessvertname}}</span>
                                        </td>
                                        <td data-column="Total Applications" class="centeralign"> 
                                            <span v-if="data.isapproved == '1' && pagefeatures.projectlevel" class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" style="cursor:pointer;" @click="redirecttourl('viewapp', data)">{{data.totalapp}}</span>
                                            <span v-else class="font-weight-bold  sitebluecolor capitalisetext" style="cursor:pointer;">{{data.totalapp}}</span>
                                        </td>
                                        <td data-column="Total Project Modules" class="centeralign">
                                          <span v-if="data.isapproved == '1' && pagefeatures.projectlevel" class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" style="cursor:pointer;" @click="redirecttourl('view', data)">{{data.totalmodules}}</span>
                                          <span v-else  class="font-weight-bold  sitebluecolor capitalisetext" style="cursor:pointer;">{{data.totalmodules}}</span>
                                        </td>
                                        <td data-column="Created Details" class="details_column_table text-left">
                                          <span class="heading_column_text"> Created By : </span>
                                          <span class="font-weight-normal"> {{ data.createdby.split("@")[0] }} </span>
                                          <hr class="hr_border-bottom">
                                          <span class="heading_column_text"> Created Date : </span>
                                          <span class="font-weight-normal"> {{ dateFormat(data.createddate) }} </span>
                                          
                                        </td>
                                        <td data-column="Security Assessment" class="centeralign text-nowrap" v-if="data.issecurity == 1">
                                          Required
                                        </td>
                                        <td data-column="Security Assessment" class="centeralign text-nowrap" v-else>
                                          {{ data.issecurity == 0 ? 'Not Required':'NA'}}
                                        </td>
                              
                                        <td data-column="Actions">
                                          <div class="dropdown">
                                            <button
                                              type="button"
                                              class="btn btn-sm dropdown-toggle hide-arrow"
                                              data-toggle="dropdown"
                                              data-boundary="window"
                                            >
                                              <more-vertical-icon
                                                size="1.5x"
                                                class="custom-class"
                                              ></more-vertical-icon>
                                            </button>
                                            <div class="dropdown-menu">
                                              <div v-if="data.projectlevel >= 4 || !data.projectlevel">
                                              </div>
                                              <div v-if="data.isapproved == '1' && pagefeatures.projectlevel">
                                                <div v-if="data.projectlevel >= 3 || !data.projectlevel">
                                                  <a class="dropdown-item" @click="redirecttourl('addapplication', data)" href="javascript:void(0);">
                                                    <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                    <span> Application</span>
                                                  </a>
                                                </div>
                                              </div>
                                              <div v-if="data.isapproved == '1'  && pagefeatures.projectlevel">
                                                <div v-if="data.projectlevel >= 2 || !data.projectlevel">
                                                  <a class="dropdown-item" @click="redirecttourl('edit', data)" href="javascript:void(0);">
                                                    <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                    <span> Project Module</span>
                                                  </a>
                                                </div>
                                              </div>
                                              <div v-if="data.isapproved == '1' && data.projectlevel == 1 && pagefeatures.projectlevel" >
                                                <a class="dropdown-item devEditEle" href="javascript:void(0);" @click="redirecttowsurl('view',data)">
                                                  <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                  <span>View Workstep</span>
                                                </a>
                                                <a class="dropdown-item" @click="redirecttowsurl('edit',data)" href="javascript:void(0);">
                                                  <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                  <span>Add Workstep</span>
                                                </a>
                                              </div>
                                              
                                              <a 
                                                class="dropdown-item"
                                                v-if="data.isapproved == '1' && pagefeatures.projectaddrole "
                                                @click="redirecttourl('addrole', data)"
                                                href="javascript:void(0);"
                                              >
                                                <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                <span>  Add Role</span>
                                              </a>
                                              <a  
                                                class="dropdown-item devEditEle"
                                                v-if="data.isapproved == '1'  && pagefeatures.allocateresource"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#updatebusiness"
                                                @click="allocateresource(data)"
                                                data-backdrop="static" data-keyboard="false"
                                              >
                                                <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                <span> Allocate Resources</span>
                                              </a>
                                              <a v-if="pagefeatures.editproject"                                                class="dropdown-item devEditEle"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                data-target="#updatebusiness"
                                                @click="openEditModule(data)"
                                                data-backdrop="static" data-keyboard="false"
                                              >
                                                <edit-2-icon size="1.5x" class="custom-class mr-50" ></edit-2-icon>
                                                <span> Edit</span>
                                              </a>
                                              <a 
                                                    class="dropdown-item"
                                                    v-if="data.isapproved == '1'"
                                                    href="javascript:void(0);"
                                                    data-toggle="modal"
                                                    data-target="#clonemaster"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                    type="button"
                                                    data-backdrop="static"
                                                    data-keyboard="false"
                                                    @click="cloneproject(data,'open')"
                                                    >
                                                    <plus-icon size="1.5x" class="custom-class mr-50"></plus-icon>
                                                    <span>Clone</span>
                                                    </a>
                                                    
                                              <a v-if="data.isapproved == '1' && pagefeatures.projectsummary" class="dropdown-item" @click="redirecttosummary('view', data)" href="javascript:void(0);">
                                              <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                <span> Project Summary</span>
                                              </a>
                                              <a
                                                class="dropdown-item devEditEle"
                                                v-if="data.isapproved != '1' && pagefeatures.projectapproval "
                                                type="submit"
                                                href="javascript:void(0);"
                                                @click="projectapprove(data)"
                                                id="submitbtnt18" v-bind:disabled="project.isapproved"
                                                data-backdrop="static" data-keyboard="false"
                                              >
                                                <check-icon size="1.5x" class="custom-class mr-50"></check-icon>
                                                <span> Approve Project</span> 
                                              </a>
                                              <a class="dropdown-item devEditEle"
                                                v-if="(data.isapproved == '1') && (data.isactive == '1') && pagefeatures.projectapproval"
                                                type="submit"
                                                href="javascript:void(0);"
                                                @click="projectdeactivemodal(data)"
                                                id="submitbtnt18" v-bind:disabled="project.isapproved"
                                                data-backdrop="static" data-keyboard="false"
                                              >
                                              <i class="custom-class mr-50 las las la-ban"></i>
                                                <span> Deactivate Project</span>
                                              </a>
                                              <a class="dropdown-item devEditEle"
                                                v-if="(data.isapproved == '1') && (data.isactive == '0') && pagefeatures.projectapproval"
                                                type="submit"
                                                href="javascript:void(0);"
                                                @click="projectdeactivemodal(data)"
                                                id="submitbtnt18" v-bind:disabled="project.isapproved"
                                                data-backdrop="static" data-keyboard="false"
                                              >
                                              <i class="custom-class mr-50 las la-check-circle"></i>
                                                <span> Activate Project</span>
                                              </a>
                                              
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-1 pageCenter" >
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal fade" id="statusEditPopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-sm " role="document">
                  <div class="modal-content">
                      <div class="modal-header" style="text-align: center; margin-bottom: 1.2rem">
                      <h4 class="modal-title">Project Deactivate Message</h4>
                      <!-- <h4>Project Deactivate Message</h4> -->
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearAllPopupFields()">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                    <form>
                      <div class="form-group">
                        <label for="message-label">Message</label>
                          <textarea class="form-control"  rows="3" placeholder="Please enter a Message" v-model="editCell.editDesc" v-bind:class="{'form-control':true, 'is-invalid' : !validText(editCell.editDesc)}">
                          </textarea>
                          <div class="invalid-feedback">Message is required</div>
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn  btn-relief-secondary" @click="clearAllPopupFields()" data-dismiss="modal">Close</button>
                      <button type="button" v-bind:disabled="!editCell.editDesc" class="btn btn-relief-primary" @click="projectdeactive('t9',$event)">Submit</button>
                  </div>
                  </div>
              </div>
          </div>
          </div>
        </div>
      </section>
    </div>
    
    <div
      class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show"
      id="projectmaster" 
    >
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            id="closebtnt11"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #ffff !important"
          >
            ×
          </button>
          <div
            class="modal-header mb-1"
            style="background-color: #1f77b4 !important"
          >
            <h5 class="modal-title" style="color: #ffff !important">
              <plus-square-icon
                size="1.5x"
                class="custom-class"
              ></plus-square-icon>
              <span class="align-middle ml-50"
                >Add/Update Project Details</span
              >
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <form class="form form-vertical">
              <div class="row">
                <div id="addproject1st" v-if="project.currttbl == 't11'">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="modulenamet11">Project Name</label>
                      <div class="input-group input-group-merge">
                        <input
                          style="border: 1px solid #d8d6de !important"
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Project Name"
                          v-model="project.name"
                          v-bind:class="{
                            'form-control': true,
                            '':
                              !validText(project.name) && projectBlured,
                          }"
                          v-on:blur="projectBlured = true"
                        />
                        <div class="errorinputmsg" v-if="!validText(project.name) && projectBlured">
                          Project name required must be length greater than 5 char
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projectdescription"
                        >Project Description</label
                      >
                      <textarea
                        class="form-control"
                        id="projectdescription"
                        name=""
                        rows="3"
                        placeholder="Project Description"
                        v-model="project.desc"
                        v-bind:class="{
                          'form-control': true,
                          '':
                            !validText(project.desc) && projectdescBlured,
                        }"
                        v-on:blur="projectdescBlured = true"
                      ></textarea>
                      <div class="errorinputmsg" v-if="!validText(project.desc) && projectdescBlured" >
                        Project description must be greater than 5 char
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="taskdefinetaskleveldd">Project Level</label>
                      <treeselect v-bind:disabled="isProjLevelDis" placeholder="Select Project Level" :options="projectlevel" @input="selProjectLevel" v-model="project.projectlevel" v-on:close="validateForm('t18', $event)"/>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projecttypeid">Project Type</label>
                      <treeselect
                        v-model="project.projecttype"
                        placeholder="Select Project Type"
                        :options="project.projecttypelist"
                        @input="selprojtype"
                        @close="validateForm('t11', $event)"
                      />
                      <div
                        class="errorinputmsg"
                        v-if="this.errors.project.projecttype"
                      >
                        {{ this.errors.project.projecttype }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="devBusinessVerticleData">Business Name</label>
                      <treeselect
                      placeholder="Select Business Name"
                        v-model="project.business"
                        :options="project.businesslist"
                        @input="selbusiness"
                        @close="validateForm('t11', $event)"
                      />
                      <div
                        class="errorinputmsg"
                        v-if="this.errors.project.business"
                      >
                        {{ this.errors.project.business }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="companyname">Company Name</label>
                      <treeselect  
                      placeholder="Select Company Name"                      
                        v-model="project.company"
                        :options="project.companylist"
                        @input="selcompany"
                        @close="validateForm('t11', $event)"
                      />
                      <div
                        class="errorinputmsg"
                        v-if="this.errors.project.company"
                      >
                        {{ this.errors.project.company }}
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                        <label for="modulenamet11">Team DL</label>
                        <div class="input-group input-group-merge">
                          <input
                            style="border: 1px solid #d8d6de !important"
                            type="text"
                            class="form-control"
                            placeholder="Enter Team DL Email"
                            v-model="project.teamdl" @input="validateEmail" @keydown.enter.prevent/>
                        </div>
                        <div v-if="!isEmailValid" class="error-msg">Please enter valid email address</div>
                      </div>
                    </div>

                  <div class="col-12">
                    <div class="demo-inline-spacing custom_radio_creaternote">
                      <label for="companyname" class="font-weight-bold m-0  w-100">Security Assessment</label>
                      <div class="mb-0 py-1 m-0 w-100 " style="padding-top: 6px!important;">
                        <div class=" form-check form-check-inline mt-0 mb-0">
                          <input class="form-check-input" type="radio" v-model="project.security" value="1" @close="validateForm('t11', $event)" @input="selsecurity"> <span class="pl-1" > Required </span>
                        </div>
                        <div class=" form-check form-check-inline mt-0">
                          <input class="form-check-input" type="radio" v-model="project.security" value="0" @close="validateForm('t11', $event)"  @input="selsecurity"> <span class="pl-1"> Not Required </span>
                        </div>
                        <div
                          class="errorinputmsg"
                          v-if="this.errors.project.security"
                        >
                          {{ this.errors.project.security }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="addproject2nd" v-if="project.currttbl == 't15'">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">Projects</label>
                      <treeselect
                        v-bind:disabled="project.disbleprojid"
                        class="projroleassprojectdd"
                        v-model="projvalue"
                        :options="projoptions"
                        @input="selroleassprojdata"
                        @close="validateForm('t14', $event)"
                      />
                      <div
                        class="errorinputmsg"
                        v-if="this.errors.roleassign.proj"
                      >
                        {{ this.errors.roleassign.proj }}
                      </div>
                    </div>
                  </div>
                  <div v-for="(data, index) in projectroleassigneddata" class="col-12"  v-bind:key="data.projectid+data.roleid">
                    <div class="form-group">
                      <label class="capitalisetext" :for="'projroleassprojectdd'+index"
                        >Select {{data.designationname}}</label
                      >
                      <treeselect
                        class="srbusianlydd capitalisetext"
                        placeholder="Enter min 3 chars to search employee"
                        v-model="selectedroleempl[data.roleid]"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                        v-on:search-change="empNameSearchChange"
                      />
                    </div>
                  </div>
                  
                </div>
                <div id="addproject3rd" v-if="project.currttbl == 't36'">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="appprojectdd">Projects</label>
                      <treeselect
                        v-bind:disabled="project.disbleprojid"
                        v-model="projectmodule.project"
                        :options="projectmodule.projectlist"
                        @input="selprojmdlproj"
                        @close="validateForm('t36', $event)"
                      />
                      <div
                        class="errorinputmsg"
                        v-if="this.errors.projectmodule.project"
                      >
                        {{ this.errors.projectmodule.project }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label" for="project-name"
                        >Project Module Name
                      </label>
                      <input
                        id="modulenamet36"
                        name=""
                        class="form-control"
                        type="text"
                        placeholder="Project Module Name "
                        v-model="projectmodule.name"
                        v-bind:class="{
                          'form-control': true,
                          'is-invalid': !validText(projectmodule.name),
                        }"
                      />
                      <div class="invalid-feedback">
                        project module name must be greater than 5 char
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="prmoduledescription">Description</label>
                      <textarea
                        class="form-control"
                        id="prmoduledescription"
                        name=""
                        rows="3"
                        placeholder="Description"
                        v-model="projectmodule.desc"
                        v-bind:class="{
                          'form-control': true,
                          'is-invalid': !validText(projectmodule.desc),
                        }"
                      ></textarea>
                      <div class="invalid-feedback">
                        desc must be greater than 5 char
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="lblattachment">Attachment</label>
                    </div>
                    <div class="form-group">
                      <label
                        for="attachment"
                        class="btn btn-sm btn-primary mb-75 mr-75"
                        >Select image/docs/videos</label
                      >
                      <button
                        class="btn btn-sm btn-primary mb-75 mr-75"
                        v-on:click.stop.prevent="onUpload()"
                      >
                        Upload
                      </button>
                      <input
                        type="file"
                        id="attachment"
                        hidden
                        accept="image/*"
                        ref="file"
                        @change="onSelect"
                      />
                      <label
                        v-if="projectmodule.attachmentview"
                        for="attachmentview"
                        ><a :href="projectmodule.attachmentview">{{
                          projectmodule.attachmentview
                        }}</a></label
                      >
                      <div class="invalid-feedback">
                        project desc must be greater than 5 char
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-relief-primary mr-1"
                    v-on:click.stop.prevent="
                      savewizard(project.currttbl, $event, 'next')
                    "
                    :id="'submitnxtbtn' + project.currttbl"
                    :value="project.nextbtn"
                  >
                    {{addformbtntxt}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show"
      id="clonemaster" 
    >
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            id="closebtnt11"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #ffff !important"
            @click="cloneproject('close')"
          >
            ×
          </button>
          <div
            class="modal-header mb-1"
            style="background-color: #1f77b4 !important"
          >
            <h5 class="modal-title" style="color: #ffff !important">
              <plus-square-icon
                size="1.5x"
                class="custom-class"
              ></plus-square-icon>
              <span class="align-middle ml-50"
                >Clone Details</span
                
              >
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <form class="form form-vertical">
              <projectclone v-if="clonemoduletype == 'open'" > </projectclone>

             
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.createbtnparentdiv {
  padding-right: 0%;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}

.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import { CheckIcon,PlusSquareIcon,ExternalLinkIcon,XIcon,PlusCircleIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon } from "vue-feather-icons";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import commonMethods from '../../utils/commonMethods';
import projectclone from './projectclone.vue';
export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    ExternalLinkIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    PlusSquareIcon,
    PlusCircleIcon,
    XIcon,CheckIcon,
    projectclone,
  },
  mixins: [ commonMethods ],
  data() {
    return {
      search: {
        searchCompany: null,
        createdbyOpts: [],
        createdby: null,
        searchProjectname:null,
        companyname: null,
        businessname: null,
        companynameList: [],
        businessnameList: [],
        project_status: null,
        projectstatusList:[
          { label: "Active", id: 1 },
          { label: "Inactive", id: 0 }
        ] 
      },
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      fullPage: true,
      islistLoading:false,
      listfullPage:true,
      companymasterlist: [],
      stylevariation: apiUrl.stylevariation,
      projectlevel: apiUrl.tasklevel,
      projectlevelArr: ['Project', 'Project Module', 'Application', 'Application Module', 'Development Section/Part', 'Functionality/Feature'],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],
      isProjectSel: 0,
      isProjectRoleSel: 0,
      showAddForm: false,
      selected: null,
      countryName: [
        { label: "select_value", id: "Select Value" },
        { label: "Russia", id: "Russia" },
        { label: "Canada", id: "Canada" },
        { label: "China", id: "China" },
        { label: "United States", id: "United States" },
        { label: "Brazil", id: "Brazil" },
        { label: "Australia", id: "Australia" },
        { label: "India", id: "India" },
      ],

      editRowData: [],
      glbMdl: "",
      isRoleAlloc: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      projectType: [],
      projectdescription: "",
      isProjectMsr: "",
      viewprojectlist: 0,
      projoptions: [
        {
          id: "",
          label: "",
        },
      ],
      roleoptions: [
        {
          id: "",
          label: "",
        },
      ],
      employeeoptions: [
        {
          id: "",
          label: "",
        },
      ],
      projvalue: null,
      emplvalue: null,
      rolevalue: null,
      project: {
        name: null,
        desc: null,
        projectlevel: null,
        projecttype: null,
        teamdl:'',
        projecttypelist: [
          {
            id: "",
            label: "",
          },
        ],
        businesslist: [
          {
            id: "",
            label: "",
          },
        ],
        companylist: [
          {
            id: "",
            label: "",
          },
        ],
        business: null,
        company: null,
        security: null,
        country: "india",
        currttbl: "t11",
        prevtbl: null,
        iseditstate: false,
        disbleprojid: true,
        rolevalue: null,
        roleoptions: [
          {
            id: "",
            label: "",
          },
        ],
        savedraft: "draft",
        nextbtn: "next",
        projectid: null,
        programvalue: null,
        programoptions: null,
        projectmgrvalue: null,
        projectmgroptions: null,
        apmvalue: null,
        apmoptions: null,
        srengmanagervalue: null,
        srengmanager: null,
        engmanagervalue: null,
        engmanager: null,
        proleadvalue: null,
        prolead: null,
        modleadvalue: null,
        modlead: null,
        srsoluarchvalue: null,
        srsoluarch: null,
        soluarchvalue: null,
        soluarch: null,
        qamgrvalue: null,
        qamgr: null,
        srqamgrvalue: null,
        srqamgr: null,
        srsecumgrvalue: null,
        srsecumgr: null,
        srbusianly: null,
        busianly: null,
        QAvalue: null,
        QA: null,
        developervalue: null,
        developer: null,
        programrolevalue: null,
        projectmgrrolevalue: null,
        apmrolevalue: null,
        srengmanagerrolevalue: null,
        engmanagerrolevalue: null,
        proleadrolevalue: null,
        modleadrolevalue: null,
        srsoluarchrolevalue: null,
        soluarchrolevalue: null,
        qamgrrolevalue: null,
        srqamgrrolevalue: null,
        srsecumgrrolevalue: null,
        srbusianlyrolevalue: null,
        busianlyrolevalue: null,
        QArolevalue: null,
        developerrolevalue: null,
        companyname: null,
        projectempllist: [],
        isapproved:null,
        tmpnoempllist: [
          {
            id: "",
            label: "No Role defined yet in CAMP system",
          },
        ],
      },
      roleallocation: {
        role: null,
        employee: null,
        employelist: [
          {
            id: "",
            label: "",
          },
        ],
        rolelist: [
          {
            id: "",
            label: "",
          },
        ],
      },
      applinameBlured: false,
      appenvhosturlBlured: false,
      appenvhostdetailspathBlured: false,
      appenvhostpathBlured: false,
      appenvhostBlured: false,
      appenvnameBlured: false,
      appenvtypeBlured: false,
      projecttypeBlured: false,
      roleallocationBlured: false,
      employeeBlured: false,
      projectBlured: false,
      projectdescBlured: false,
      projectcountryBlured: false,
      projectmodule: {
        name: null,
        project: null,
        projectlist: [
          {
            id: "",
            label: "",
          },
        ],
        desc: null,
        attachment: null,
        attachmentview: null,
        disblesavebtn: false,
      },
      errors: {
        project: {
          name: null,
          desc: null,
          projectlevel: null,
          projecttype: null,
          business: null,
          company: null,
          country: "india",
          security: null
        },
        roleallocation: {
          role: null,
          employee: null,
        },
        roleassign: {
          proj: null,
          role: null,
          empl: null,
        },
        projectmodule: {
          name: null,
          project: null,
          desc: null,
          attachment: null,
          attachmentview: null,
        },
      },
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      clonemoduletype: 'close',
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      projectcurrentstate: "local",
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Submit',
      projectroleassigneddata:null,
      selectedroleempl:[],
      projectEmpIdList: [],
      tokendata: null,
      isProjLevelDis: false,
      isresponce:false,
      editCell: {
        editDesc:null,
      },
      holdcurrRowData: {},
      isEmailValid:false,

    };
  },
  created() {
    this.pagefeatures = {};
    if(window.localStorage.getItem("pagefeatures")){
      var pagefeatures1 = JSON.parse(
        window.atob(window.localStorage.getItem("pagefeatures"))
      );
      pagefeatures1.forEach((element) => {
        if (element.page === "/project") {
          this.pagefeatures[element.featurename] = element.featureaccess;
        }
      });
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      this.tokendata = window.localStorage.getItem('token');
      let userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.empid = userdata.userid;
      }
        this.getApplicationdata("t1", "project-tab");
        this.getProjectCreatebyList()
        this.getProjectLevelList()

      this.projectcurrentstate = "local";
      if (window.location.host === "smartworks.firstcry.tech") {
        this.projectcurrentstate = "live";
      } else if (window.location.host === "65.0.57.28:9000") {
        this.projectcurrentstate = "stage";
      }
      this.project.rolevalue = [
        "169",
        "155",
        "156",
        "158",
        "160",
        "161",
        "157",
        "164",
        "165",
      ];
      if (this.projectcurrentstate == "live") {
        this.project.rolevalue = [
          "138",
          "139",
          "140",
          "142",
          "143",
          "144",
          "141",
          "146",
          "147",
        ];
      }
      let rowdata={}
      if (this.$route.params.backbtnstate != undefined) {
        if (this.$route.params.backbtnstate == 'viewCompanyProjects' || this.$route.params.backbtnstate == 'view') { 
          let search = JSON.parse(localStorage.getItem('projectmastersearchItems'));
          if(search && (search.hasOwnProperty('searchProjectname') || search.hasOwnProperty('projectname') || search.hasOwnProperty('projectid'))) {
            this.backpagedata=this.$route.params.clickeddata;
            if(this.$route.params && this.$route.params.clickeddata && this.$route.params.clickeddata.projectid){
              this.search.searchProjectname = this.$route.params.clickeddata.projectid;
              rowdata.searchProjectname = this.search.searchProjectname;
            }
              this.getSearchItems(search);
              this.isSearchRequestActive = true;
          }else {
            localStorage.removeItem('projectmastersearchItems');
            this.backpagedata = this.$route.params.clickeddata;
            if(this.$route.params && this.$route.params.clickeddata && this.$route.params.clickeddata.projectid){
              this.isSearchRequestActive = true;
              this.projectChangebyid(this.$route.params.clickeddata.projectid);
              this.search.searchProjectname = this.$route.params.clickeddata.projectid;
            }
            this.getprojectlist(this.$route.params.clickeddata);
          }
          if(this.$route.params.backbtnstate == 'viewCompanyProjects'){
            if(this.$route.params.clickeddata.projectid) {
              this.search.searchProjectname = this.$route.params.clickeddata.projectid
              rowdata.searchProjectname = this.search.searchProjectname
              this.isSearchRequestActive = true
            }
            else if(this.$route.params.clickeddata.companyid) {
              this.search.companyname = this.$route.params.clickeddata.companyid
              rowdata.companyname = this.search.companyname
              this.isSearchRequestActive = true
            } else if(this.$route.params.clickeddata.businessverticleid) {
              this.search.businessname = this.$route.params.clickeddata.businessverticleid
              rowdata.businessname = this.search.businessname
              this.isSearchRequestActive = true
            }
          }
        }
      } else {
        localStorage.removeItem('projectmastersearchItems');
        rowdata.project_status=1;        
        this.getprojectlist(rowdata);
      }
    }
    $(".filter_rotation_onlick").click(function(){
  $(this).toggleClass("common_arrow_rotetion");
  });
  },
  methods: {
    validateEmail() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.isEmailValid = emailRegex.test(this.project.teamdl);

        if (this.isEmailValid) {
          const atIndex = this.project.teamdl.indexOf('@');
          const dotIndex = this.project.teamdl.indexOf('.', atIndex + 1);

          this.isEmailValid = atIndex !== -1 && dotIndex !== -1 && dotIndex > atIndex + 1;

          if (this.isEmailValid) {
            const domainPart = this.project.teamdl.substring(atIndex + 1);
            const allowedDomains = ['.in', '.com', '.uae'];

            this.isEmailValid = allowedDomains.some(domain => domainPart.toLowerCase().endsWith(domain));
          }
        }
      },
    redirecttosummary: function(type, data){
      let pid = data.projectid;
      this.$router.push({ 
        path: '/project/summary?pid='+pid,
        params: {
          backbtnstate:type,
        }
      })
    },
    getCompanyCreatedList: function () {
      axios
        .post("api/listing/getCreatedByList", { tbl: "t1",empcode: this.empid,useremail: this.undt.username })
        .then((result) => {
          if (result.data.errorCode == 0) {
            this.search.createdbyOpts = result.data.data;
          }
        });
    },
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
        companymasterid: this.search.companyid,
        businessmasterid: this.search.businessverticleid,
        empcode: this.empid,
      }
      axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          if(result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = null
          }
        }).catch(e => {
            this.displayError(e)
        })
    },
    selectcompany(state, value) {
      this.search.companyname = state

      if(!state) {
        this.projectnameList = null
        if(this.search.businessname) {
          this.getDropdownApplicationdata('t11')
        }
      } else {
        this.getDropdownApplicationdata('t11')
      }

    },
    selectbusiness(state, value) {
      this.search.businessname = state
      if(!state) {
        this.projectnameList = null
        if(this.search.companyname) {
          this.getDropdownApplicationdata('t11')
        }
      } else {
        this.getDropdownApplicationdata('t11')
      }
    },
    selectproject(state, value) {
      this.search.searchProjectname = state;
    },    
    selectprojectstatus(state) {
      this.search.project_status = state;
    }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/project/getProjectListByUserType";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.empid,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    projectChangebyid: function (projectid){
      if(projectid && projectid !=null){
        this.isLoading = true;
        let url = "api/project/getProjectListByUserType";
        this.input = {
          projectid: projectid,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.empid,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getProjectCreatebyList(){
      this.isLoading = true;
      let url = "api/searching/getProjectCreatebyList";
      this.input={projectid:0,empcode: this.empid,useremail: this.undt.username}
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.createdbyOpts = result.data.data;
        } else {
          this.createdbyOpts = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if(rowdata.projectlevel == 1 && btnstate != 'addrole') {
        this.$router.push({ 
          name: 'Work Step',
          path: '/workstep',
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
        return
      }
      let currStateData=rowdata
      let redirectmdlnm='Project Module'
      let redirectmdlurl='/projectmodule'
      if(btnstate == 'addapplication'){
        redirectmdlnm='Project Application'
        redirectmdlurl='/application'
      }else if(btnstate == 'addrole'){
        redirectmdlnm='ProjectRoleAllotment'
        redirectmdlurl='/project/roleallotment'
        currStateData = {projectid:this.projvalue}
      }else if(btnstate == 'viewapp'){
        redirectmdlnm='Project Application'
        redirectmdlurl='/projectapplication/getapplicationtypelist'
      }
      this.$router.push({
        name: redirectmdlnm,
        path: redirectmdlurl,
        params: {
          backbtnstate: btnstate,
          clickeddata: currStateData,
        },
      });
    },
    redirecttowsurl:function(btnstate,rowdata){
      rowdata.backbtnstate=btnstate
      this.$router.push({ 
        name: 'Work Step',
        path: '/workstep',
        params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
        }
      })
    },
    getFilteredData: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "company",
        isFilterSelect: 1,
        isFilterPayload: {},
        empcode: this.empid,useremail: this.undt.username
      };

      if (this.search.searchCompany != null) {
        this.input.isFilterPayload.companyid = this.search.searchCompany;
      } 
      if (this.search.createdby != null) {
        this.input.isFilterPayload.createdby = this.search.createdby;
      }

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.showcompanies = true
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    cloneproject(currRowData,type){
      localStorage.setItem('clone_projectname', currRowData.projectname);
      localStorage.setItem('clone_projectid', currRowData.projectid);
      let clone_pname  = localStorage.getItem('clone_projectname');
      let clone_pid  = localStorage.getItem('clone_projectid');
      this.clonemoduletype = type
    },
    resetRecords: function () {
      this.projectnameList = null;
      this.search.searchProjectname = null;
      this.search.createdby = null;
      this.search.companyname = null;
      this.search.businessname = null;
      this.search.project_status = null;
      localStorage.removeItem('projectmastersearchItems');
      let rowdata={}
      this.getprojectlist(rowdata);
    },
    getcomapnylist: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "project",
        isFilterSelect: 0,
        isFilterPayload: {},
        empcode: this.empid,useremail: this.undt.username
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
            this.displayError(e)
        });
    },
    getSearchItems(search) {
      this.isSearchRequestActive = true;
      this.search.searchProjectname  = this.search.searchProjectname ? this.search.searchProjectname :search.searchProjectname ? search.searchProjectname
      : search.projectid ? search.projectid: search.searchProjectname ? search.searchProjectname:search.searchProjectname;
      this.search.businessname = search.businessverticleid ? search.businessverticleid : search.businessname ? search.businessname : null;
      this.search.companyname = search.companyid ? search.companyid : search.companyname ? search.companyname : null;

      this.projectChangebyid(this.search.searchProjectname);
      this.getprojectlist(this.search)
    },
    getprojectlist(rowdata) {
      this.islistLoading = true;
      if(rowdata) {
        localStorage.setItem('projectmastersearchItems', JSON.stringify(rowdata))
      }
      this.parentmoduledata = rowdata;
      let setmoduleform = "projectmaster";
      this.currentmodule=`#`+setmoduleform
      let glbMdlLblval = "Project";
      this.companymasterlist = [];
      let tblNm = "t11";
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }

      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        projectid: rowdata.projectid || rowdata.searchProjectname,
        createdby:rowdata.createdby,
        companymasterid: rowdata.companyid || rowdata.companyname,
        businessmasterid: rowdata.businessverticleid || rowdata.businessname,
        uniqueid: this.undt.userid,
        empcode: this.empid,
        projectstatus: rowdata.project_status
      };
      this.input.isSearch = false;
      let url = "api/project/list";
      this.getApplicationdata("t3", "project-tab");
      this.getApplicationdata("t2", "project-tab");
      this.getApplicationdata("t1", "project-tab");
      this.input.section = "project";
      this.input.isFilterSelect = 1;
      this.activecompanyid = this.parentmoduledata.companyid;
      this.project.companyname = this.parentmoduledata.companyname;
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.project.company = this.activecompanyid;
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.project.company = this.activecompanyid;
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata);
      }
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#projectList").offset().top -70
        });
      }, 500);
    },
    validText: function (inputval) {
      var re = /[A-Za-z0-9].{4,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    valid2Text: function (inputval) {
      var re = /[A-Za-z0-9].{0,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validate: function (tblNm) {
      this.projectBlured = true;
      this.projectdescBlured = true;
      this.projectcountryBlured = true;
      this.appenvhosturlBlured = true;
      this.appenvhostdetailspathBlured = true;
      this.appenvhostpathBlured = true;
      this.appenvhostBlured = true;
      this.appenvnameBlured = true;
      this.appenvtypeBlured = true;
      this.applinameBlured = true;

      if (
        tblNm == "t11" &&
        this.validText(this.project.name) &&
        this.validText(this.project.desc)
      ) {
        this.valid = true;
      }
      if (tblNm == "t12" && this.validText(this.application.name)) {
        this.valid = true;
      }
      if (
        tblNm == "t13" &&
        this.validText(this.appenv.name) &&
        this.validText(this.appenv.host) &&
        this.validText(this.appenv.hostpath) &&
        this.validText(this.appenv.hostdetails) &&
        this.validText(this.appenv.hosturl) &&
        this.valid2Text(this.appenv.type)
      ) {
        this.valid = true;
      }
    },
    setSelected(value) {
      this.selected = value;
    },
    masterlist(mdl, objThis) {
      let setmoduleform = "projectmaster";
      let glbMdlLblval = "Project";
      if (this.glbst && mdl == "") {
        mdl = this.glbst;
      }
      $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
      this.companymasterlist = [];
      this.isProjectSel = 0;
      this.isProjectMsr = 0;
      this.viewprojectlist = 0;
      this.isProjectRoleSel = 0;
      this.isRoleAlloc = 0;
      let tblNm = "t11";
      if (mdl) tblNm = mdl;
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        empcode: this.empid,
        useremail: this.undt.username
      };
      this.input.isSearch = false;

      if (typeof objThis != "undefined" && objThis != "") {
        if (
          objThis.currentTarget.dataset.clkstate == "search" &&
          $("#glbsearchinpele").val() != ""
        ) {
          this.input.name = $("#glbsearchinpele").val().trim();
          this.input.isSearch = true;
        }
      }
      let url = "api/master/commonmaster/list";
      if (tblNm == "t11") {
        this.getApplicationdata("t3", "project-tab");
        this.getApplicationdata("t2", "project-tab");
        this.getApplicationdata("t1", "project-tab");
        url = "api/project/list";
        this.isProjectMsr = 1;
        this.input.section = "assignment";
        this.input.isFilterSelect = 0;
        this.input.isFilterPayload = {};
      }
      this.input.empcode= this.empid
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (
            this.isProjectSel == 1 ||
            this.isProjectMsr == 1 ||
            this.isProjectRoleSel == 1
          ) {
            this.companymasterlist = result.data.data.rows;
            this.totalcount = result.data.data.count;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          } else {
            this.companymasterlist = [];
            this.totalcount = 0;
          }
          setTimeout(function () {
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.totalcount = 0;
        }
      });
    },
    addModule(objThis) {
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t36") {
        this.projectmodule.disblesavebtn = true;
      } else if (inputEle == "t11") {
        this.project.iseditstate = false;
        this.addformbtntxt='Submit'
      }
      $(".devUniqueForm").find("input[type=text]").val("");
      $(objThis.currentTarget.dataset.target)
        .find("#submitbtn" + inputEle)
        .attr("data-isedit", false);
      $("#glbsearchinpele").val("");
      this.isAddState = true;
      this.projectmodule.name = null;
      this.projectmodule.project = null;
      this.project.projecttype = null;
      this.project.business = null;
      this.project.desc = null;
      this.project.security = null;
      this.project.projectlevel = null;
      this.project.name = null;
      this.rolevalue = null;
      this.emplvalue = null;
      this.projvalue = null;
      this.roleallocation.role = null;
      this.roleallocation.employee = null;
      this.project.currttbl = "t11";
      this.project.prevtbl = "";
      this.isProjLevelDis = false
      this.isSearchRequestActive = true
    },
    showprevform(mdl, objThis) {
      if (mdl) {
        if (mdl == "t11") {
          this.project.currttbl = mdl;
          this.project.projecttype = this.projecttabRowData.projecttypeid;
          this.project.business = this.projecttabRowData.businessverticleid;
          this.project.desc = this.projecttabRowData.projectdescription;
          $("#businessname").val(this.projecttabRowData.businessname);
          this.project.company = this.projecttabRowData.companyid;
          this.project.name = this.projecttabRowData.projectname;
          this.project.security = this.projecttabRowData.issecurity;
          this.project.iseditstate = true;
          this.project.prevtbl = null;
          this.addformbtntxt='Submit'
        } else if (mdl == "t15") {
          this.project.currttbl = mdl;
          this.rolevalue = this.roleassitabRowData.roleid;
          this.getThirdPartyAPIcall("t21", "projectroleassign-tab");
          var arrempllist = this.roleassitabRowData.employeelist.split(",");
          this.emplvalue = arrempllist;
          this.projvalue = this.roleassitabRowData.projectid;
          this.project.iseditstate = true;
          this.project.prevtbl = "t11";
          this.getlastinsertedprojectdata(this.project.prevtbl);
        } else if (mdl == "t36") {
          this.project.currttbl = mdl;
          this.projectmodule.name = this.prevtabRowData.modulename;
          this.projectmodule.project = this.prevtabRowData.projectid;
          this.projectmodule.desc = this.prevtabRowData.description;
          this.projectmodule.attachment = this.prevtabRowData.attachment;
          this.projectmodule.attachmentview = this.prevtabRowData.attachment;
          this.project.iseditstate = true;
          this.project.prevtbl = "t15";
        }
      } else {
        this.project.prevtbl = null;
      }
    },
    savewizard(mdl, objThis, currbtnstate) {
      if (mdl == "t11") {
        this.validate(mdl);
      } else {
        this.valid = true;
      }
      this.valid = this.validateForm(mdl, objThis);
      if (this.valid) {
        let nameobj = document.getElementById("modulename" + mdl);
        let tblNm = "t11";
        if (mdl) tblNm = mdl;
        this.input = {
          tbl: tblNm,
          section: "assignment",
          createdby: this.undt.username ,
          empcode: this.empid,useremail: this.undt.username         
        };
        if (nameobj != null && nameobj != "undefined") {
          this.input.name = nameobj.value;
        }
        let apiURL = "api/master/commonmaster/save";
        if (tblNm == "t11") {
          if (this.project.name) {
            this.input.name =this.project.name;
          }
          if (this.project.desc) {
            this.input.projectdescription = this.project.desc;
          }
          if (this.project.projecttype) {
            this.input.devApplicationTypeData = this.project.projecttype;
          }
          if (this.project.security !=null) {
            this.input.security = this.project.security
          }
          this.input.projectlevel = this.project.projectlevel
          let business = (this.project.businesslist && this.project.businesslist.length) ? this.project.businesslist.filter(item => item.id == this.project.business) : []
          this.input.businessname = business.length ? business[0].label : 'na';
          if (this.project.business) {
            this.input.devBusinessVerticleData = this.project.business;
          }
          if (this.project.company) {
            this.input.companyname = this.project.company;
          }
          if (this.project.country) {
            this.input.ddcountry = this.project.country;
          }
          if (this.project.teamdl) {
            this.input.teamdl = this.project.teamdl;
          }
          apiURL = "api/project/create";
        } else if (tblNm == "t15") {
          this.input.rolename = "test";
          this.emplvalue = [];
          this.project.rolevalue = [];
          var empdata = [];
          var tmpindx = 0;
          var projectresourcedata=[]
          this.selectedroleempl.map((assignedroledata,index) => {
            this.projectroleassigneddata.map((projroleallotdata,innerindex)=>{
              if(projroleallotdata.roleid == index){
                this.emplvalue.push(assignedroledata);
                this.project.rolevalue.push(index);
                projectresourcedata.push({'roleid':index,'rolename':projroleallotdata.designationname,'emplids':assignedroledata})
                empdata.push({
                roleid:index,
                rolename:projroleallotdata.designationname,
                emplvalue: JSON.stringify(assignedroledata),
                rolevalue: index
              });
              tmpindx++;
              }
            })
          })

          this.input.projectassignroleidd = JSON.stringify(
            this.project.rolevalue
          );
          if (this.emplvalue.length == 0) {
            Swal.fire({
              title: "Failed",
              text: "Please select at least 1 employee",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            return false;
          }
          this.input.employeemasterdd = JSON.stringify(this.emplvalue);
          this.input.empdata = JSON.stringify(empdata);
          this.input.projroleassprojectdd = this.projvalue;
          apiURL = "api/roleassignment/saveRoleAssign";
        } else if (mdl == "t36") {
          this.input.name = this.projectmodule.name;
          this.input.projectid = this.projectmodule.project;
          this.input.desc = this.projectmodule.desc;
          this.input.section = "assignment";
          if (!this.projectmodule.attachment) {
            Swal.fire({
              title: "Failed !!!",
              text: "image or file not uploaed yet",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            return;
          }
          this.input.attachment = this.projectmodule.attachment;
          apiURL = "api/master/commonmaster/customsave";
          delete this.input.type;
        }
        //
        if (this.project.iseditstate == true) {
          apiURL = "api/project/update";
          this.input.id = this.projecttabRowData.projectid;
          if (tblNm == "t15") {
            this.input.id = this.roleassitabRowData.batchid;
            this.input.projectid = this.roleassitabRowData.projectid;
            apiURL = "api/roleassignment/updateRoleAssign";
          } else if (tblNm == "t36") {
            this.input.id = this.prevtabRowData.projmdlid;
            apiURL = "api/master/commonmaster/customupdate";
          }
          this.input.lastmodifiedby = this.undt.username;
          delete this.input.createdby;
        }
        this.isAddState = false;
        var currobj = objThis.currentTarget.value;
        this.input.empcode= this.empid;
        this.input.useremail= this.undt.username
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          if (result.data.errorCode == 0) {
            let rowdata = {};
            this.getprojectlist(this.search);
            if (this.project.currttbl == "t11") {
              this.getlastinsertedprojectdata(
                this.project.currttbl,
                currbtnstate
              );
                $(".close").click();
                Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
                });
            } else if (this.project.currttbl == "t15") {
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              $(".close").click();
              this.getlastinsertedprojectdata(this.project.currttbl);
            } else if (this.project.currttbl == "t36") {
              this.project.prevtbl = "t15";
              this.project.currttbl = "t11";
              $(".close").click();
            }
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            }else{
            Swal.fire({
              title: "Failed!",
              text: result.data.msg,
              icon: "Info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        }).catch(e => {
    this.displayError(e)
  });
      }
    },
    getroleassignmentdata(mdl, clickedstate) {
      this.getprojectroleassigneddata()      
      let apiURL = "api/listing/getroleassignmentdatabyprojectid";
      this.isAddState = false;
      this.input = {
        tbl: "t11",
        projectid: this.projvalue,
        empcode: this.empid,
        useremail: this.undt.username
      };
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
         'headers':{'authorization':this.tokendata}
      }).then((result) => {        
        this.project.iseditstate = false;
        if (mdl == "t11" && clickedstate == "draft") {
          this.project.iseditstate = true;
        }
        this.selectedroleempl=[]
        if (result.data.errorCode == 0) {
          for (var i = 0; i < result.data.data.length; i++) {
            if(!this.selectedroleempl[result.data.data[i].roleid]){
              this.selectedroleempl[result.data.data[i].roleid]=[]
            }
            this.selectedroleempl[result.data.data[i].roleid].push(`${result.data.data[i].empid}`)
            this.projectEmpIdList.push(result.data.data[i].empid)
          }
          this.project.iseditstate = true;
          this.roleassitabRowData = result.data.data[0];
          this.getfiltereddata("projectempllist");
        }
        this.addformbtntxt='Submit'
      })
      .catch(e => {
    this.displayError(e)
  });
    },
    getprojectmoduledata(mdl, clickedstate) {
      let apiURL = "api/listing/getprojectmoduledatabyprojectid";
      this.isAddState = false;
      this.input = {
        tbl: "t11",
        projectid: this.projectmodule.project,
        empcode: this.empid,
        useremail: this.undt.username
      };
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.project.iseditstate = false;
        if (result.data.errorCode == 0) {
          this.projectmodule.name = result.data.data[0].projmdlname;
          this.projectmodule.project = result.data.data[0].projectid;
          this.projectmodule.desc = result.data.data[0].projmdldescription;
          this.projectmodule.attachment = result.data.data[0].attachment;
          this.projectmodule.attachmentview = result.data.data[0].attachment;
          this.project.iseditstate = true;
          this.prevtabRowData = result.data.data[0];
        }
      });
    },
    getlastinsertedprojectdata(mdl, clickedstate) {
      let apiURL = "api/listing/getlastinsertedprojid";
      this.isAddState = false;
      this.input = {
        tbl: "t11",
        empcode: this.empid,
        useremail: this.undt.username
      };
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
         'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (mdl == "t11") {
            this.getprojectlist(this.parentmoduledata);
            if(!this.projvalue){
              this.projvalue = result.data.data[0].projectid;
            }            
            this.getroleassignmentdata(mdl, clickedstate);
          }
        }
      }).catch(e => {
    this.displayError(e)
  });
    },
    validateForm(mdl, objThis) {
      this.clearerrorForm();
      this.isAddState = false;
      var isValid = true;
      if (mdl == "t11") {
        if (!this.project.projecttype) {
          this.errors.project.projecttype = "Project type required";
          isValid = false;
        }
        if (!this.project.business) {
          this.errors.project.business = "Business name required";
          isValid = false;
        }
        if (!this.project.company) {
          this.errors.project.company = "Company name required";
          isValid = false;
        }
        if (this.project.security == null) {
          this.errors.project.security = "Security assessment required";
          isValid = false;
        }
      } else if (mdl == "t15") {
        if (!this.projvalue) {
          this.errors.roleassign.proj = "Project required";
          isValid = false;
        }
        if (!this.project.rolevalue) {
          this.errors.roleassign.role = "Role required";
          isValid = false;
        }
      } else if (mdl == "t36") {
        if (
          this.projectmodule.project == null ||
          this.projectmodule.project == "" ||
          this.projectmodule.project.length == 0
        ) {
          this.errors.projectmodule.project = "Project required";
          isValid = false;
        }
      }
      return isValid;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clearerrorForm() {
      this.errors = {
        project: {
          name: null,
          desc: null,
          projecttype: null,
          business: null,
          company: null,
          country: null,
          security: null
        },
        roleallocation: {
          role: null,
          employee: null,
        },
        roleselection: {
          project: null,
          role: null,
        },
        roleassign: {
          proj: null,
          role: null,
          empl: null,
        },
        projectmodule: {
          name: null,
          project: null,
          desc: null,
          attachment: null,
          attachmentview: null,
          disblesavebtn: false,
        },
      };
    },
    projectapprove:function(currRowData) {
      this.isLoading = true;
      this.editRowData = currRowData;
      let today_date = new Date();
      let cal_Date = new Date(currRowData.deactivedate);
      cal_Date.setDate(cal_Date.getDate() + 90);

      if(today_date < cal_Date || (currRowData.deactivedate ==null || currRowData.deactivedate =='' || currRowData.deactivedate ==' ' || currRowData.deactivedate ==undefined || currRowData.deactivedate =='undefined'))
      {
        this.input = {
          tbl:'t11',
          isapproved:'1',
          id:currRowData.projectid,
          name: currRowData.projectname,
          empcode: this.empid,
          useremail: this.undt.username
        };

        axios({
          method: "POST",
          url: `api/project/approveProject`,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          if(result.data.errorCode == 0) {
            this.getprojectlist(this.search);
            this.isLoading=false;
            Swal.fire({
              title: "Success!",
              text: 'Project Apporved',
              icon: 'success',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          } else {
              Swal.fire({
                  title: "",
                  text: result.data.msg,
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
          }
        });
      }
      else
      {
        this.isLoading=false;
        this.editCell.editDesc = null;

          Swal.fire({
              title: "",
              text: "Project cannot be Activated as time period is expired",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          })
      }
    },
    projectdeactivemodal(currRowData){
      this.holdcurrRowData = {}
      $('#statusEditPopup').modal('show');
      this.holdcurrRowData = currRowData;
    },
    projectdeactive:function() {
      $('#statusEditPopup').modal('hide');
      this.isLoading = true;
      let current_pstatus;

        if(this.holdcurrRowData.isapproved == '0')
        {
          current_pstatus = '1';
        }
        else
        {
          current_pstatus = '0';
        }

        this.input = {
          tbl:'t11',
          isapproved: current_pstatus,
          isactive:'1',
          id: this.holdcurrRowData.projectid,
          name: this.holdcurrRowData.projectname,
          empcode: this.empid,
          useremail: this.undt.username,
          deactivatereason: this.editCell.editDesc
        };

        axios({
          method: "POST",
          url: `api/project/deactiveProject`,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          if(result.data.errorCode == 0){
            this.getprojectlist(this.search);
            this.isLoading=false;
            this.editCell.editDesc = null;

            Swal.fire({
              title: "Success!",
              text: "Project Deactivated Successfully",
              icon: 'success',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          } else {
            this.isLoading=false;
              Swal.fire({
                  title: "",
                  text: result.data.msg,
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
          }
        });
    },
    clearAllPopupFields() {
      this.editCell.editDesc = null;
    },
    allocateresource:function(currRowData){
      this.project.currttbl = "t15";
      this.project.prevtbl = "";
      this.addformbtntxt='Next'
      this.editRowData = currRowData;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle) {
        $("#modulename" + inputEle).val(currRowData.modulename);
      }
      if (btnEle) {
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .addClass(btnEle);
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .attr("data-isedit", true);
      }
      $("#glbsearchinpele").val("");
      this.project.name = currRowData.projectname;
      this.projvalue = currRowData.projectid;
      this.project.iseditstate = true;
      this.getlastinsertedprojectdata(
        't11',
        'next'
      );
      this.project.prevtbl = "t11";
      this.project.currttbl = "t15";
      this.getApplicationdata("t11", "projectroleassign-tab");
      // this.getfiltereddata("projectempllist");
      this.isAddState = true;
    },
    openEditModule(currRowData) {
      this.project.currttbl = "t11";
      this.project.prevtbl = "";
      this.addformbtntxt='Submit'
      this.editRowData = currRowData;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle) {
        $("#modulename" + inputEle).val(currRowData.modulename);
      }
      if (btnEle) {
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .addClass(btnEle);
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .attr("data-isedit", true);
      }
      $("#glbsearchinpele").val("");
      if (inputEle == "t11") {
        this.projecttabRowData = currRowData;
        this.project.projectlevel = currRowData.projectlevel
        this.project.projecttype = currRowData.projecttypeid;
        this.project.business = currRowData.businessverticleid;
        this.project.desc = currRowData.projectdescription;
        $("#businessname").val(currRowData.businessname);
        this.project.company = currRowData.companyid;
        this.project.name = currRowData.projectname;
        this.projvalue = currRowData.projectid;
        this.project.security = currRowData.issecurity;
        this.project.teamdl = currRowData.teamdl;
        this.project.iseditstate = true;
        this.isProjLevelDis = true
      }
      this.isAddState = true;
    },
    getProjectLevelList() {
      this.inputappl = {
        tbl: 't24',
        offset: 1,
        limit: apiUrl.LIMIT,
        empcode: this.empid,
        useremail: this.undt.username
      };
      let apiURL = "api/master/commonmaster/list";
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then(result => {
        if(result.data.errorCode == 0) {
          this.projectlevel = result.data.data.rows;
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    dateFormat(value) {
      if (!value){
        return "";
      }else{
        return moment(String(value)).format('DD-MM-YYYY');
      }
    },
    dateMONFormat(value) {
      if (!value){
        return "";
      }else{
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      }
    },
    getApplicationdata(mdl, curracttab) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        empcode: this.undt.userid,

      };
      let apiURL = "api/master/commonmaster/dropdownlist";
      if (mdl == "t35") {
        apiURL = "api/master/commonmaster/customselect";
        this.inputappl.isFilterPayload = {};
        this.inputappl.isFilterSelect = 0;
        this.inputappl.isSearch = false;
        this.inputappl.section = "assignment";
      }
      if (
        curracttab == "project-tab" ||
        curracttab == "projectroleassign-tab"
      ) {
        this.inputappl = {
          tbl: mdl,
        };
      }
      this.inputappl.empcode= this.empid,
      this.inputappl.useremail= this.undt.username
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectType = [];
          this.projectType = result.data.data.rows;
          let tmpClassNm = "";
          if (mdl == "t4") {
            if (curracttab == "project-tab") {
              tmpClassNm = "devApplicationTypeData";
              this.project.projecttypelist = result.data.data.rows;
              return true;
            } else if (curracttab == "application-tab") {
              tmpClassNm = "applicationtype";
              this.application.applicationtypelist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t2") {
            tmpClassNm = "devBusinessVerticleData";
            this.project.businesslist = result.data.data.rows;
            this.search.businessnameList = result.data.data.rows;
            return true;
          } else if (mdl == "t11") {
            if (curracttab == "application-tab") {
              tmpClassNm = "appprojectdd";
              this.application.projectlist = result.data.data.rows;
              return true;
            } else if (curracttab == "appenvi-tab") {
              tmpClassNm = "projectmasterdd";
              this.appenv.projectlist = result.data.data.rows;
              return true;
            } else if (curracttab == "projectrolesele-tab") {
              tmpClassNm = "projroleprojectdd";
              this.roleselection.projectlist = result.data.data.rows;
              return true;
            } else if (curracttab == "projectroleassign-tab") {
              tmpClassNm = "projroleassprojectdd";
              this.projoptions = result.data.data.rows;
              return true;
            } else if (curracttab == "projectmodule") {
              this.projectmodule.projectlist = result.data.data.rows;
              return true;
            } else if (curracttab == "applicationmodule") {
              this.applicationmodule.projectlist = result.data.data.rows;
              return true;
            } else if (curracttab == "sectionmodule") {
              this.sectionmodule.projectlist = result.data.data.rows;
              return true;
            } else if (curracttab == "functionalitymodule") {
              this.functionalitymodule.projectlist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t6") {
            tmpClassNm = "applicationhost";
            this.application.applicationhostlist = result.data.data.rows;
            return true;
          } else if (mdl == "t7") {
            tmpClassNm = "applicationclient";
            this.application.applicationclientlist = result.data.data.rows;
            return true;
          } else if (mdl == "t8") {
            tmpClassNm = "applicationaccess";
            this.application.applicationaccesslist = result.data.data.rows;
            return true;
          } else if (mdl == "t3") {
            if (curracttab == "project-tab") {
              tmpClassNm = "devApplicationTypeData";
              this.project.projecttypelist = result.data.data.rows;
              return true;
            } else {
              tmpClassNm = "techapplicationtype";
              this.application.techapplicationtypelist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t12") {
            if (curracttab == "appenvi-tab") {
              tmpClassNm = "applicationmasterdd";
              this.appenv.applicationlist = result.data.data.rows;
              return true;
            } else if (curracttab == "applicationmodule") {
              this.applicationmodule.applicationlist = result.data.data.rows;
              return true;
            } else if (curracttab == "sectionmodule") {
              this.sectionmodule.applicationlist = result.data.data.rows;
              return true;
            } else if (curracttab == "functionalitymodule") {
              this.functionalitymodule.applicationlist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t10") {
            if (curracttab == "projectrolesele-tab") {
              tmpClassNm = "projectroleiddd";
              this.roleselection.rolelist = result.data.data.rows;
              return true;
            } else if (curracttab == "projectroleassign-tab") {
              tmpClassNm = "projectassignroleidd";
              this.roleoptions = result.data.data.rows;
              return true;
            } else if (curracttab == "roleallocation-tab") {
              tmpClassNm = "allocationroleidd";
              this.roleallocation.rolelist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t21") {
            if (curracttab == "projectroleassign-tab") {
              tmpClassNm = "employeemasterdd";
              this.employeeoptions = result.data.data.rows;
              return true;
            } else if (curracttab == "roleallocation-tab") {
              tmpClassNm = "allocationempldd";
              this.roleallocation.employelist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t1") {
            tmpClassNm = "companyname";
            this.project.companylist = result.data.data.rows;
            this.project.companylist.sort((a, b) => {
              return a.label.localeCompare(b.label);
            });
            this.search.companynameList = result.data.data.rows
            this.search.companynameList.sort((a, b) => {
              return a.label.localeCompare(b.label);
            });
            return true;
          } else if (mdl == "t36") {
            if (curracttab == "application-tab") {
              this.application.projectmdllist = result.data.data.rows;
              return true;
            } else if (curracttab == "applicationmodule") {
              this.applicationmodule.projectmodulelist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t34") {
            if (curracttab == "sectionmodule") {
              this.sectionmodule.applicationmodulelist = result.data.data.rows;
              return true;
            } else if (curracttab == "functionalitymodule") {
              this.functionalitymodule.applicationmodulelist =
                result.data.data.rows;
              return true;
            }
          } else if (mdl == "t35") {
            if (curracttab == "functionalitymodule") {
              this.functionalitymodule.sectionmasterlist =
                result.data.data.rows;
              return true;
            }
          }
          $("." + tmpClassNm)
            .wrap('<div class="position-relative"></div>')
            .select2({
              dropdownAutoWidth: true,
              dropdownParent: $("." + tmpClassNm).parent(),
              width: "100%",
              data: this.projectType,
            });
        }
      });
    },
    getThirdPartyAPIcall(mdl, curracttab) {
      var calltype = "";
      calltype = this.currcalltype;
      this.pageoffset = 1;
      this.inputthrid = {
        tbl: mdl,
        offset: this.pageoffset,
        token: this.undt.tokenorg,
        limit: apiUrl.LIMIT,
      };
      var apiURL = "api/master/commonmaster/thirdpartyapicall";
      if (mdl == "t10") {
        apiURL = "api/thirdpartyapicall/getdesignations";
        this.inputthrid.currentState = calltype;
      } else if (mdl == "t21") {
        apiURL = "api/thirdpartyapicall/getemployees";
        this.inputthrid.currentState = calltype;
        if (this.rolevalue) {
          this.inputthrid.roleid = parseInt(this.rolevalue);
        } else {
          this.inputthrid.roleid = this.roleallocation.role
            ? parseInt(this.roleallocation.role)
            : 143;
        }
      }
      this.inputthrid.currentPlatform = "localhost";
      if (window.location.host === "smartworks.firstcry.tech") {
        this.inputthrid.currentPlatform = "live";
      } else if (window.location.host === "65.0.57.28:9000") {
        this.inputthrid.currentPlatform = "stage";
      }
      this.allapicallauthkey = jwt.sign(
        this.inputthrid,
        apiUrl.encpaccessapitoken,
        {
          expiresIn: "24h", 
        }
      );
      var encpaccessapitoken = this.allapicallauthkey;
      axios({
        method: "POST",
        url: apiURL,
        data: { encpaccessapitoken },
      }).then((result) => {
        if (result.data.status == true) {
          if (mdl == "t10") {
            if (curracttab == "roleallocation-tab") {
              if (this.inputthrid.currentState == "fcsmart") {
                this.roleallocation.rolelist = result.data.data.rows;
              } else {
                this.roleallocation.rolelist = result.data.data;
              }
              return true;
            } else if (curracttab == "projectrolesele-tab") {
              if (this.inputthrid.currentState == "fcsmart") {
                this.roleselection.rolelist = result.data.data.rows;
              } else {
                this.roleselection.rolelist = result.data.data;
              }
              
              return true;
            } else if (curracttab == "projectroleassign-tab") {
              
              if (this.inputthrid.currentState == "fcsmart") {
                this.project.roleoptions = result.data.data.rows;
              } else {
                this.project.roleoptions = result.data.data;
              }
              return true;
            } 
            // else if (curracttab == "projectroleassign-tab") {
            //   tmpClassNm = "projectassignroleidd";
            //   this.project.roleoptions = result.data.data.rows;
            //   return true;
            // }
          } else if (mdl == "t21") {
            if (curracttab == "roleallocation-tab") {
              if (this.inputthrid.currentState == "fcsmart") {
                this.roleallocation.employelist = result.data.data.rows;
              } else {
                this.roleallocation.employelist = result.data.data;
              }
              return true;
            } else if (curracttab == "projectroleassign-tab") {
              this.employeeoptions = result.data.data;
              return true;
            }
          }
        } else if (result.data.status == false) {
          if (mdl == "t10") {
            if (curracttab == "roleallocation-tab") {
              this.roleallocation.rolelist = [
                {
                  id: "",
                  label: "Issue in CAM Integration API",
                },
              ];
              return true;
            } else if (curracttab == "projectrolesele-tab") {
              this.roleselection.rolelist = [
                {
                  id: "",
                  label: "Issue in CAM Integration API",
                },
              ];
              return true;
            } else if (curracttab == "projectroleassign-tab") {
              this.project.roleoptions = [
                {
                  id: "",
                  label: "Issue in CAM Integration API",
                },
              ];
              return true;
            }
          } else if (mdl == "t21") {
            if (curracttab == "roleallocation-tab") {
              this.roleallocation.employelist = [
                {
                  id: "",
                  label: "Issue in CAM Integration API",
                },
              ];
              return true;
            } else if (curracttab == "projectroleassign-tab") {
              this.employeeoptions = [
                {
                  id: "",
                  label: "Issue in CAM Integration API",
                },
              ];
              return true;
            }
          }
        }
      });
    },
    selprojdata(state, projvalue) {
      this.pageoffset = 1;
      var projid = state;
      var mdl = "t14";
      this.inputappl = {
        isSearch: false,
        section: "assignment",
        currenttab: "roleassignment",
        tbl: mdl,
        offset: this.pageoffset,
        projid: projid,
        limit: apiUrl.LIMIT,
        empcode: this.empid,
        useremail: this.undt.username
      };
      let apiURL = "api/master/commonmaster/customselectdata";
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.roleoptions = result.data.data;
        } else {
          this.roleoptions = [];
          this.rolevalue = null;
        }
      });
    },
    selprojroledata(state, rolevalue) {
      this.project.rolevalue = null;
      this.project.rolevalue = state;
      if (!state && !this.isAddState) {
        this.errors.roleallocation.role = "Role required";
        this.employeeoptions = null;
        this.emplvalue = null;
      } else {
        this.errors.roleallocation.role = null;
        this.getfiltereddata("prjempbyrole");
      }
    },
    selroledata(state, rolevalue) {
      this.rolevalue = null;
      this.rolevalue = state;
      if (!state && !this.isAddState) {
        this.errors.roleallocation.role = "Role required";
        this.employeeoptions = null;
        this.emplvalue = null;
      } else {
        this.errors.roleallocation.role = null;
        this.getfiltereddata("prjempbyrole");
      }
    },
    selempldata(state, value) {
      this.emplvalue = null;
      this.emplvalue = state;
    },
    selProjectLevel(state, value) {
      if(!state) {
        this.errors.project.projectlevel = "Project Level required"
      }
    },
    selprojtype(state, value) {
      this.project.projecttype = null;
      this.project.projecttype = state;
      if (!state && !this.isAddState) {
        this.errors.project.projecttype = "Project type required";
      } else {
        this.errors.project.projecttype = null;
      }
    },
    selbusiness(state, value) {
      this.project.business = null;
      this.project.business = state;
      if (!state && !this.isAddState) {
        this.errors.project.business = "Business name required";
      } else {
        this.errors.project.business = null;
      }
    },
    selcompany(state, value) {
      this.project.company = null;
      this.project.company = state;
      if (!state && !this.isAddState) {
        this.errors.project.company = "Company name required";
      } else {
        this.errors.project.company = null;
      }
    },
    selsecurity(state, value) {
      this.project.security = null;
      this.project.security = state;
      if (!state && !this.isAddState) {
        this.errors.project.security = "Security  required";
      } else {
        this.errors.project.security = null;
      }
    },
    selrlroledata(state, value) {
      this.roleallocation.role = null;
      this.roleallocation.role = state;
      if (!state && !this.isAddState) {
        this.errors.roleallocation.role = "Role required";
      } else {
        this.getThirdPartyAPIcall("t21", "roleallocation-tab");
        this.errors.roleallocation.role = null;
      }
    },
    selrlempldata(state, value) {
      this.roleallocation.employee = null;
      this.roleallocation.employee = state;
      if (state.length == 0 && !this.isAddState) {
        this.errors.roleallocation.employee = "Employee required";
      } else {
        this.errors.roleallocation.employee = null;
      }
    },
    selappproj(state, value) {
      this.application.project = null;
      this.application.project = state;
      if (!state && !this.isAddState) {
        this.errors.application.project = "Project required";
      } else {
        this.errors.application.project = null;
      }
    },
    selappprojmdl(state, value) {
      this.application.projectmodule = null;
      this.application.projectmodule = state;
      if (!state && !this.isAddState) {
        this.errors.application.projectmodule = "Project Module required";
      } else {
        this.errors.application.projectmodule = null;
      }
    },
    selappapltype(state, value) {
      this.application.applicationtype = null;
      this.application.applicationtype = state;
      if (!state && !this.isAddState) {
        this.errors.application.applicationtype = "Application type required";
      } else {
        this.errors.application.applicationtype = null;
      }
    },
    selapptechapltype(state, value) {
      this.application.techapplicationtype = null;
      this.application.techapplicationtype = state;
      if (!state && !this.isAddState) {
        this.errors.application.techapplicationtype =
          "Tech application type required";
      } else {
        this.errors.application.techapplicationtype = null;
      }
    },
    selappaplhost(state, value) {
      this.application.applicationhost = null;
      this.application.applicationhost = state;
      if (!state && !this.isAddState) {
        this.errors.application.applicationhost = "Application host required";
      } else {
        this.errors.application.applicationhost = null;
      }
    },
    selappaplclient(state, value) {
      this.application.applicationclient = null;
      this.application.applicationclient = state;
      if (!state && !this.isAddState) {
        this.errors.application.applicationclient =
          "Application client required";
      } else {
        this.errors.application.applicationclient = null;
      }
    },
    selappaplaccess(state, value) {
      this.application.applicationaccess = null;
      this.application.applicationaccess = state;
      if (!state && !this.isAddState) {
        this.errors.application.applicationaccess =
          "Application access required";
      } else {
        this.errors.application.applicationaccess = null;
      }
    },
    selenvappl(state, value) {
      this.appenv.application = null;
      this.appenv.application = state;
      if (!state && !this.isAddState) {
        this.errors.appenv.application = "Application required";
      } else {
        this.errors.appenv.application = null;
      }
    },
    selenvproj(state, value) {
      this.appenv.project = null;
      this.appenv.project = state;
      if (!state && !this.isAddState) {
        this.errors.appenv.project = "Project required";
      } else {
        this.errors.appenv.project = null;
      }
    },
    selroleasiproj(state, value) {
      this.roleselection.project = null;
      this.roleselection.project = state;
      if (!state && !this.isAddState) {
        this.errors.roleselection.project = "Project required";
      } else {
        this.errors.roleselection.project = null;
      }
    },
    selroleasirole(state, value) {
      this.roleselection.role = null;
      this.roleselection.role = state;
      if (state.length == 0 && !this.isAddState) {
        this.errors.roleselection.role = "Role required";
      } else {
        this.errors.roleselection.role = null;
      }
    },
    selroleassprojdata(state, value) {
      this.projvalue = null;
      this.projvalue = state;
      if (!state && !this.isAddState) {
        this.errors.roleassign.proj = "Project required";
        this.roleoptions = null;
        this.rolevalue = null;
      } else {
        this.errors.roleassign.proj = null;
      }
    },
    selroleassroledata(state, value) {
      this.rolevalue = null;
      this.rolevalue = state;
      if (!state && !this.isAddState) {
        this.errors.roleassign.role = "Role required";
      } else {
        this.errors.roleassign.role = null;
      }
    },
    selroleassempldata(state, value) {
      this.emplvalue = null;
      this.emplvalue = state;
      if (state.length == 0 && !this.isAddState) {
        this.errors.roleassign.empl = "Employee required";
      } else {
        this.errors.roleassign.empl = null;
      }
    },
    selprojmdlproj(state, value) {
      this.projectmodule.project = null;
      this.projectmodule.project = state;
      if (!state && !this.isAddState) {
        this.errors.projectmodule.project = "Project required";
      } else {
        this.errors.projectmodule.project = null;
      }
    },
    selamprojectdata(state, value) {
      this.applicationmodule.projectid = null;
      this.applicationmodule.projectid = state;
      if (!state && !this.isAddState) {
        this.errors.applicationmodule.projectid = "Project Required";
      } else {
        this.errors.applicationmodule.projectid = null;
        this.getfiltereddata("t36");
      }
    },
    selamprojectmdldata(state, value) {
      this.applicationmodule.projectmoduleid = null;
      this.applicationmodule.projectmoduleid = state;
      if (!state && !this.isAddState) {
        this.errors.applicationmodule.projectmoduleid =
          "Project Module required";
      } else {
        this.errors.applicationmodule.projectmoduleid = null;
      }
    },
    selamappmdldata(state, value) {
      this.applicationmodule.applicationid = null;
      this.applicationmodule.applicationid = state;
      if (!state && !this.isAddState) {
        this.errors.applicationmodule.applicationid =
          "Application Module required";
      } else {
        this.errors.applicationmodule.applicationid = null;
      }
    },
    selsmprojectdata(state, value) {
      this.sectionmodule.projectid = null;
      this.sectionmodule.projectid = state;
      if (!state && !this.isAddState) {
        this.errors.sectionmodule.projectid = "Project Required";
      } else {
        this.errors.sectionmodule.projectid = null;
      }
    },
    selsmapplmdldata(state, value) {
      this.sectionmodule.applicationmoduleid = null;
      this.sectionmodule.applicationmoduleid = state;
      if (!state && !this.isAddState) {
        this.errors.sectionmodule.applicationmoduleid =
          "Application Module required";
      } else {
        this.errors.sectionmodule.applicationmoduleid = null;
        this.getfiltereddata("sectionmodule");
      }
    },
    selsmappmdldata(state, value) {
      this.sectionmodule.applicationid = null;
      this.sectionmodule.applicationid = state;
      if (!state && !this.isAddState) {
        this.errors.sectionmodule.applicationid = "Application required";
      } else {
        this.errors.sectionmodule.applicationid = null;
      }
    },
    selfmsectionmdldata(state, value) {
      this.functionalitymodule.sectionmasterid = null;
      this.functionalitymodule.sectionmasterid = state;
      if (!state && !this.isAddState) {
        this.errors.functionalitymodule.sectionmasterid = "Section Required";
      } else {
        this.errors.functionalitymodule.sectionmasterid = null;
        this.getfiltereddata("functionalitymodule");
      }
    },
    selfmprojectdata(state, value) {
      this.functionalitymodule.projectid = null;
      this.functionalitymodule.projectid = state;
      if (!state && !this.isAddState) {
        this.errors.functionalitymodule.projectid = "Project Required";
      } else {
        this.errors.functionalitymodule.projectid = null;
      }
    },
    selfmapplmdldata(state, value) {
      this.functionalitymodule.applicationmoduleid = null;
      this.functionalitymodule.applicationmoduleid = state;
      if (!state && !this.isAddState) {
        this.errors.functionalitymodule.applicationmoduleid =
          "Application Module required";
      } else {
        this.errors.functionalitymodule.applicationmoduleid = null;
      }
    },
    selfmappmdldata(state, value) {
      this.functionalitymodule.applicationid = null;
      this.functionalitymodule.applicationid = state;
      if (!state && !this.isAddState) {
        this.errors.functionalitymodule.applicationid = "Application required";
      } else {
        this.errors.functionalitymodule.applicationid = null;
      }
    },
    getfiltereddata(tblNm) {
      this.isLoading = true;
      this.pageoffset = 1;
      var url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        empcode: this.empid,useremail: this.undt.username
      };
      this.input.isSearch = true;
      this.input.section = "assignment";
      this.input.isFilterSelect = 1;
      this.input.isFilterPayload = {};
      if (tblNm == "t26") {
        this.input.roleid = parseInt(this.rolevalue);
      } else if (tblNm == "t14") {
        this.input.projectid = this.projvalue;
        this.input.offset = this.pageoffset;
        this.input.limit = apiUrl.LIMIT;
      } else if (tblNm == "t36") {
        this.input.projectid = this.applicationmodule.projectid;
      } else if (tblNm == "sectionmodule") {
        url = "api/listing/getproappbyappmoduleid";
        this.input = {
          appmoduelid: this.sectionmodule.applicationmoduleid,
        };
      } else if (tblNm == "functionalitymodule") {
        url = "api/listing/getproappbysecmasterid";
        this.input = {
          sectionmasterid: this.functionalitymodule.sectionmasterid,
        };
      } else if (tblNm == "prjempbyrole") {
        url = "api/listing/getemoloyeedetailsbyrole";
        this.input = {
          dept: "it",
          designation: JSON.stringify(this.project.rolevalue),
          roleid: JSON.stringify(this.project.rolevalue),
        };
      } else if (tblNm == "projectempllist") {
        url = "api/listing/getallemoloyeedetails";
        this.project.programrolevalue = "138";
        this.project.projectmgrrolevalue = "139";
        this.project.apmrolevalue = "140";
        this.project.srengmanagerrolevalue = "142";
        this.project.engmanagerrolevalue = "280";
        this.project.proleadrolevalue = "143";
        this.project.modleadrolevalue = "144";
        this.project.srsoluarchrolevalue = "141";
        this.project.soluarchrolevalue = "0";
        this.project.qamgrrolevalue = "0";
        this.project.srqamgrrolevalue = "0";
        this.project.srsecumgrrolevalue = "0";
        this.project.srbusianlyrolevalue = "146";
        this.project.busianlyrolevalue = "147";
        this.project.QArolevalue = "176";
        this.project.developerrolevalue = "177";
        if (this.projectcurrentstate == "live") {
          this.project.programrolevalue = "138";
          this.project.projectmgrrolevalue = "139";
          this.project.apmrolevalue = "140";
          this.project.srengmanagerrolevalue = "142";
          this.project.engmanagerrolevalue = "280";
          this.project.proleadrolevalue = "143";
          this.project.modleadrolevalue = "144";
          this.project.srsoluarchrolevalue = "141";
          this.project.soluarchrolevalue = "0";
          this.project.qamgrrolevalue = "0";
          this.project.srqamgrrolevalue = "0";
          this.project.srsecumgrrolevalue = "0";
          this.project.srbusianlyrolevalue = "146";
          this.project.busianlyrolevalue = "147";
          this.project.QArolevalue = "330";
          this.project.developerrolevalue = "331";
        }
        this.input = {
          dept: "it",
          projectEmpIdList: this.projectEmpIdList
        };
      }
        this.input.empcode= this.empid,
        this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          if (tblNm == "t14") {
            this.roleoptions = result.data.data.rows;
          } else if (tblNm == "t26") {
            this.employeeoptions = result.data.data.rows;
          } else if (tblNm == "t36") {
            this.applicationmodule.projectmodulelist = result.data.data.rows;
          } else if (tblNm == "sectionmodule") {
            this.sectionmodule.projectname = result.data.data[0].projectname;
            this.sectionmodule.projectid = result.data.data[0].projectid;
            this.sectionmodule.applicationname =
              result.data.data[0].applicationname;
            this.sectionmodule.applicationid =
              result.data.data[0].applicationid;
          } else if (tblNm == "functionalitymodule") {
            this.functionalitymodule.projectname =
              result.data.data[0].projectname;
            this.functionalitymodule.projectid = result.data.data[0].projectid;
            this.functionalitymodule.applicationname =
              result.data.data[0].applicationname;
            this.functionalitymodule.applicationid =
              result.data.data[0].applicationid;
            this.functionalitymodule.applicationmodulename =
              result.data.data[0].applicationmodulename;
            this.functionalitymodule.applicationmoduleid =
              result.data.data[0].applicationmoduleid;
          } else if (tblNm == "prjempbyrole") {
            this.employeeoptions = result.data.data;
          } else if (tblNm == "projectempllist") {
            this.project.projectempllist = result.data.data;
            let decryptdesignationtext = this.project.projectempllist.map(items =>{
              let tempemplabel1 = items.label;
              let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;
              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          }
        } else {
          if (tblNm == "t14") {
            this.roleoptions = null;
            this.errors.roleassign.role = "no role found";
          } else if (tblNm == "t22") {
            this.employeeoptions = null;
            this.errors.roleassign.empl = "no employee found";
          } else if (tblNm == "t36") {
            this.errors.applicationmodule.projectmoduleid =
              "no project module found";
          } else if (tblNm == "sectionmodule") {
            this.errors.applicationmodule.projectmoduleid =
              "no project module found";
            this.errors.functionalitymodule.applicationid =
              "no application found";
            this.errors.functionalitymodule.applicationmoduleid =
              "no application module found";
          } else if (tblNm == "functionalitymodule") {
            this.errors.functionalitymodule.projectid = "no project found";
            this.errors.functionalitymodule.applicationid =
              "no application found";
            this.errors.functionalitymodule.applicationmoduleid =
              "no application module found";
          }
        }
      });
    },
    empNameSearchChange(node) {
      if(node && node.length > 1) {
        this.isLoading = true;
        let url = "api/listing/getallemoloyeedetails";
        let payload = {
          dept: "it",
          useremail: this.undt.username,
          empcode: this.undt.userid,
          name: node.toLowerCase(),
        };
        axios({
          method: "POST",
          url: url,
          data: payload,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.project.projectempllist = result.data.data;
            let decryptdesignationtext = this.project.projectempllist.map(items =>{
              let tempemplabel1 = items.label;
              let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;
              if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          }
        });

      }
    },
    onSelect() {
      this.file = this.$refs.file.files[0];
    },
    getAttachment(currRow) {
      this.apiURL = "api/master/fileupload/getImageFileUrl";
      this.inputattachment = {
        empcode: this.undt.userid,
        moduleid: currRow.id,
        useremail: this.undt.username
      };
      axios({
        "content-type": "multipart/form-data",
        method: "POST",
        url: this.apiURL,
        data: this.inputattachment,
      }).then((result) => {
        if (result.data.status) {
          this.projectmodule.disblesavebtn = false;
          this.projectmodule.attachmentview = result.data.data;
        }
      });
    },
    async onUpload() {
      this.isLoading = true;
      if (this.file) {
        if (this.file.name !== "") {
          let arrGlbMdl = this.glbMdl.split("##");
          let inputEle = "";
          let btnEle = "";
          if (arrGlbMdl.length > 0) {
            if (typeof arrGlbMdl[0] != "undefined") {
              inputEle = arrGlbMdl[0];
            }
            if (typeof arrGlbMdl[1] != "undefined") {
              btnEle = arrGlbMdl[1];
            }
          }
          if (inputEle == "t36") {
            btnEle = "prmdl";
          }
          this.apiURL = "api/master/fileupload/uploadFileToS3";
          let formData = new FormData();
          formData.append("imagefile", this.file);
          formData.append("empcode", this.undt.userid);
          formData.append("useremail", this.undt.username);
          formData.append("moduletype", btnEle);
          formData.imagefile = this.file;
          formData.empcode = this.undt.userid;
          formData.moduletype = btnEle;
          await axios({
            "content-type": "multipart/form-data",
            method: "POST",
            url: this.apiURL,
            data: formData,
          }).then((result) => {
            this.isLoading = false;
            if (result.data.status) {
              this.projectmodule.disblesavebtn = false;
              this.projectmodule.attachment = result.data.data;
              this.projectmodule.attachmentview = result.data.data;
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          });
        } else {
          Swal.fire({
            title: "Failed",
            text: "Select image/docs/videos",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.projectmodule.attachment = null;
        }
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Select image/docs/videos",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        this.projectmodule.attachment = null;
      }
    },
    getprojectroleassigneddata:function (){
      this.input=null;
      this.input = {
        tbl:'t14',
        projectid: this.projvalue,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      let url = 'api/roleassignment/getprojassignedroles'
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.projectroleassigneddata=result.data.data.rows
        }else{
          this.projectroleassigneddata=null
        }
      })
    },
    addrolestoproject:function(){
      $(".close").click();
      let rowdata = {projectid:this.projvalue}
      this.$router.push({
          name: 'ProjectRoleAllotment',
          path: '/project/roleallotment',
          params: {
            backbtnstate:'add',
            clickeddata: rowdata,
          },
        });
    },
    ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        companymasterid:this.search.companyname,
        businessmasterid:this.search.businessname,
        projectid:this.search.searchProjectname,
        tbl:'t11',
        useremail: this.undt.username,
        uniqueid: this.undt.userid,
        empcode: this.undt.userid

      };
      axios({
        method: "POST",
        url: `api/project/list`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'ProjectDetails_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Project Name,Project Level,Company, Project Type, Business Name,Total Applications,Total Project Modules,Created By,Created Date,Security Assessment,Team DL' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let projectlevel = index.hasOwnProperty('projectlevel') ? index.projectlevel:'';
          let companyname = index.hasOwnProperty('companyname') ? index.companyname : '';
          let typename = index.hasOwnProperty('typename') ? index.typename : '';
          let businessvertname = index.hasOwnProperty('businessvertname') ? index.businessvertname : '';
          let totalapp = index.hasOwnProperty('totalapp') ? index.totalapp : '';
          let totalmodules = index.hasOwnProperty('totalmodules') ? index.totalmodules : '';
          let createdby = index.hasOwnProperty('createdby') ? index.createdby.split("@")[0] : '';              
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          createddate = this.dateMONFormat(createddate);
          projectlevel = this.projectlevelArr[projectlevel - 1] ? this.projectlevelArr[projectlevel - 1] : '';
          let issecurity = index.hasOwnProperty('issecurity') ? index.issecurity : '';
          let teamdl = index.hasOwnProperty('teamdl') ? index.teamdl ==null || index.teamdl.toLowerCase() =='na'?'':index.teamdl : '';
          if (issecurity == 1) {
            issecurity ='Required'
          }else if(issecurity == 0){
            issecurity ='Not Required'
          }else if(issecurity == null){
            issecurity ='NA'
          }
            row += '"' + srno + '",'
            row += '"' + projectname + '",'
            row += '"' + projectlevel + '",'
            row += '"' + companyname + '",'
            row += '"' + typename + '",'
            row += '"' + businessvertname + '",'
            row += '"' + totalapp + '",'       
            row += '"' + totalmodules + '",'
            row += '"' + createdby + '",'
            row += '"' + createddate + '",'
            row += '"' + issecurity + '",'
            row += '"' + teamdl + '",'
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
           Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'ProjectDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });    
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
  },
};
</script>
