<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top" >
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-2">Bulk Assignment</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">Test Execution</li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TestCycleSetBulkList', params: { search: true }}">Bulk Assignment Test Cycle Set's</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Bulk Assignment Test Cycle Set</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card" >
                        <div class="card-header py-1 px-0" style="cursor:pointer">
                            <div class="container-fluid px-2" style="border-bottom:1px solid #eee;padding-bottom: 15px;">
                            <div class="row" >
                                <div class="col-md-8">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                        </div>
                                    </div>
                                    <h3 class="card-title ml-2 d-inline-flex text-primary">Test Cycle Set Code: {{testcycle.cyclesetcode}}</h3>
                                </div>
                            </div>
                            </div>
                            <div class="container px-2">
                                 <section class="modern-horizontal-wizard mb-1">
                    <div class="bs-stepper wizard-modern modern-wizard-example">
                        <div class="bs-stepper-header">
                           <div class="step" data-target="#account-details-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Created Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.createddate)}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#personal-info-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-success rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Start Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.startdate)}}</span>
                                    </span>
                                </button>
                            </div>
                           
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#social-links-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-danger rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Last Modify Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.lastmodifieddate)}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#social-links-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-warning rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Review Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.revieweddate) ? dateMonthFormat(testcycle.revieweddate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#address-step-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-success rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Product Pending for Review</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(testcycle.productpendingreviewdate) ? dateMonthFormat(testcycle.productpendingreviewdate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                            <div class="row my-1"> 
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-user-edit font-medium-3"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Cycle Set Name</h6>
                                                <small>{{testcycle.cyclename}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-sync-alt font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Task Code</h6>
                                                <small>{{testcycle.businesscode}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-clipboard-list font-medium-5"></em>
                                                        </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Created By</h6>
                                                    <small  class="capitalisetext " style="font-weight:500">{{testcycle.createdby}}</small>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title"> Versions</h6>
                                                <small>{{testcycle.version}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>                                
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-id-card font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Task Name</h6>
                                                <small>{{testcycle.businessname}} </small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <UserIcon size="1.5x" class="custom-class  avatar-icon font-medium-3"></UserIcon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Last Modified By</h6>
                                                    <small  class="capitalisetext " style="font-weight:500">{{testcycle.lastmodifiedby}}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-clipboard-list font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Status</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.status}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-users font-medium-5"></em> 
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Group Name</h6>
                                                <span class="font-weight-bold" v-if="testcycle.mappingname" v-html="showmappingname(testcycle.mappingname)"></span>
                                                <small class="font-weight-bold" v-else>NA</small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-calendar font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Cycle Last Run Date</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{dateMonthFormat(testcycle.rundate) ? dateMonthFormat(testcycle.rundate):'NA'}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Total Scripts</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.nooftestcase}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-danger rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-sync-alt font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">No Of Times Cycle Run</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.cycleruncount?testcycle.cycleruncount:'0'}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-calendar font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Cycle Last Finish Date</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{dateMonthFormat(testcycle.finishdate) ? dateMonthFormat(testcycle.finishdate):'NA'}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-thumbs-up font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Total Last Pass Scripts</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.spasscount ? testcycle.spasscount :0}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-danger rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-thumbs-down font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Total Last Fail Scripts</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{testcycle.sfailcount ? testcycle.sfailcount :0}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-md-4">
                                    <div class=" card-transaction">
                                    <div class="card-body px-0 py-1">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <monitor-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></monitor-icon>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Cycle Last Finish Comment</h6>
                                                <small  class="capitalisetext " style="font-weight:500">{{ testcycle.finishcomment ? testcycle.finishcomment :'NA'}}</small>                                        
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                            </div>
                            <fieldset class="fieldset_border_vi mb-2">
                                <legend>Last Cycle Run Allocated History</legend>
                                <div class="card-body pt-1 px-0">
                                    <div class="mb-1 px-0">
                                        <div class="table-responsive" >
                                            <table aria-describedby="cyclehistorytbl" id="cyclehistorytbl" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                    <th scope="col" class="text-center">Sr#</th>
                                                    <th scope="col" class="text-center">Assign To</th>
                                                    <th scope="col" class="text-center">Script Code</th>
                                                    <th scope="col" class="text-center">Assign Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(hdetails,hindex) in lastrundetailslist" v-bind:key="hindex">
                                                        <td class="text-center">
                                                            {{hindex+1}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ hdetails.assigneename }}
                                                        </td>
                                                        <!-- <td class="text-center">
                                                            <span v-html="showcode(hdetails.testccasecode)"></span>
                                                        </td> -->
                                                        <td class="text-center tast_case_groupingview_readmore">
                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div class="outer" :id="'cycle_readmore'+hindex">
                                                                        <span class="fc_m16_btcode_text" v-html="formatData(hdetails.testccasecode)"></span>
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="hdetails.testccasecode && hdetails.testccasecode.length > 35">
                                                                        <a class="btn">
                                                                        <strong :id="'id1'+hindex" @click="idclick('id1',hindex)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <!-- <td class="text-center">
                                                            <span v-html="showdate((hdetails.assignedate))" v-if="hdetails.assignedate"></span>
                                                            <span v-else>NA</span>
                                                        </td> -->

                                                        <td class="text-center tast_case_groupingview_readmore">
                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div class="outer" :id="'cycle_readmore2'+hindex">
                                                                        <span class="fc_m16_btcode_text" v-html="formatData(hdetails.assignedate)"></span>
                                                                        
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="hdetails.assignedate && hdetails.assignedate.length > 35">
                                                                        <a class="btn">
                                                                        <strong :id="'id2'+hindex" @click="idclick2('id2',hindex)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        
                                                    </tr>
                                                    <tr v-if="lastrundetailslist==''"><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset class="fieldset_border_vi mb-2">
                                <legend>Current Bulk Allocation History</legend>
                                <div class="card-body pt-1 px-0">
                                    <div class="mb-1 px-0">
                                        <div class="table-responsive" style="overflow-x: inherit !important;">
                                            <table aria-describedby="cyclehistorytbl" id="cyclehistorytbl" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                    <th scope="col" class="text-center">Total Script Allocated</th>
                                                    <th scope="col" class="text-center">Total Script Unallocated</th>
                                                    <th scope="col" class="text-center">Total Script</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">
                                                            {{ countassign?countassign:'0' }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ countunassigne?countunassigne:'0' }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{testcycle.nooftestcase}}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="!testcycle.nooftestcase"><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div class="tab-content" id="historytable">
                                <fieldset class="fieldset_border_vi mb-2">
                                    <legend>Current Bulk Allocation History Details</legend>
                                    <div class="card-body pt-1 px-0">
                                        <div class="mb-1 px-0">
                                            <div class="table-responsive" style="overflow-x: inherit !important;">
                                                <table aria-describedby="cyclehistorytbl" id="cyclehistorytbl" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                        <th scope="col" class="text-center">Sr#</th>
                                                        <th scope="col" class="text-center">Assign To</th>
                                                        <th scope="col" class="text-center">Script Code</th>
                                                        <th scope="col" class="text-center">Assign Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(ahlist, idx) in testcycle.allocatedhistory" v-bind:key="idx">
                                                        <td class="text-center">
                                                            {{idx+1+currPageNum*pagelimit}}
                                                        </td>
                                                        <td class="text-center capitalisetext">
                                                            {{ahlist.assigneename}}
                                                        </td>
                                                        <!-- <td class="text-center">
                                                            <span v-html="showcode(ahlist.testccasecode)"></span>
                                                        </td> -->
                                                        <td class="text-center tast_case_groupingview_readmore">
                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div class="outer" :id="'cycle_readmore3'+idx">
                                                                        <span class="fc_m16_btcode_text" v-html="formatData(ahlist.testccasecode)"></span>
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="ahlist.testccasecode && ahlist.testccasecode.length > 35">
                                                                        <a class="btn">
                                                                        <strong :id="'id3'+idx" @click="idclick3('id3',idx)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <!-- <td class="text-center">
                                                            <span v-html="showdate((ahlist.assignedate))" v-if="ahlist.assignedate"></span>
                                                            <span v-else>NA</span>
                                                        </td> -->
                                                        <td class="text-center tast_case_groupingview_readmore">
                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div class="outer" :id="'cycle_readmore4'+idx">
                                                                        <span class="fc_m16_btcode_text" v-html="formatData(ahlist.assignedate)"></span>
                                                                        
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="ahlist.assignedate && ahlist.assignedate.length > 35">
                                                                        <a class="btn">
                                                                        <strong :id="'id4'+idx" @click="idclick4('id4',idx)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        </tr>
                                                        <tr v-if="testcycle.allocatedhistory.length==0"><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter text-center">
                                        <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                    </div>
                                </fieldset>
                            </div>
                            <fieldset class="fieldset_border_vi mb-2" v-if="testcycle.nooftestcase!=countassign">
                                <legend>Bulk Allocation</legend>
                            <div class="row"  v-for="(newfea1, idx1) in newfea" :key="`phoneInput-${idx1}`">
                                <div class="card-body px-0">
                                    <form>
                                        <div class="row ">
                                            <div class="form-group col-md-3 ml-1" >
                                                <label class="form-label">Allocation Number </label>
                                                <input class="form-control" type="number" placeholder="Enter Allocation Number" v-model="newfea1.scriptcount" pattern="^[1-9][0-9]*$" required oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"/>
                                                <div class="errorinputmsg" v-if="!newfea1.scriptcount && testcycle.nooftestcase!=countassign">{{errors.scriptcount}}</div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <label class="form-label">Assign To</label>
                                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select assign to" v-model="newfea1.scriptassignee" :options="assigneelist" @input="selasgn"/>
                                                <div class="errorinputmsg" v-if="!newfea1.scriptassignee && testcycle.nooftestcase!=countassign">{{errors.scriptassignee}}</div>
                                            </div>
                                            <div class="form-group col-md-3 p-md-0" style="margin-top:25px">
                                                <div class="avatar btn-relief-success rounded ml-0 mb-1 btn_over_cursor_pointer">
                                                    <div class="avatar-content_addbtn add_header-padding-y6 business_mar_view-2" @click="addField()">
                                                        <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                        Add
                                                    </div>
                                                </div>
                                                <div class="avatar bg-danger rounded ml-1 mb-1" v-if="newfea.length>1">
                                                    <div class="avatar-content" @click="removeField(idx1)" style="cursor: pointer;">
                                                        <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                    </div>
                                                </div>                            
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-12 mg-top1 text-right">
                                <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard($event)" id="submitbtnt39" v-if="testcycle.nooftestcase==countassign || newfea.scriptcount>countunassigne" disabled>Submit</button>
                                <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard($event)" id="submitbtnt39" v-else>Submit</button>
                                <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Reset</button>
                            </div>
                            </fieldset>
                    </div>
                        </div>
                    </div>
                </div>                
            </section>
        </div>           
    </div>
</template>
<style>
   .fc_m16_btcode_text p{
        margin-bottom:5px;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,CalendarIcon,UserIcon,EyeIcon,WatchIcon,SettingsIcon,MinusSquareIcon,MonitorIcon } from "vue-feather-icons";
export default {
    name:'TestCycleSetAssignee',
    components:{
        Loading,
        vSelect,
        Treeselect,
        VueElementLoading,
        Pagination,Edit2Icon,CalendarIcon,
        PlusCircleIcon,PlusSquareIcon,UserIcon,EyeIcon,WatchIcon,SettingsIcon,MinusSquareIcon,MonitorIcon
    },
    mixins: [ commonMethods ],
    data(){
        return{
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            cyclelist:[],
            testcasereuslt:apiUrl.testcasereuslt,
            testcycle:{
                cyclename: null,
                tcgrouplist: [],
                businesscode: null,
                businessname: null,
                assignto: null,
                startdate: null,
                enddate: null,
                tcgroup: null,
                btccodelist: [],
                btcnamelist: [],
                assigntolist: [],
                status: null,
                version: null,
                cyclesetid: null,
                createdby: null,
                createddate: null,
                lastmodifiedby: null,
                lastmodifieddate: null,
                cyclesetcode: null,
                mappingname: null,
                revieweddate: null,
                productpendingreviewdate: null,
                rundate: null,
                finishdate: null,
                groupname: [],
                spasscount: null,
                sfailcount: null,
                nooftestcase: null,
                cycleruncount: null,
                allocatedhistory: [],
                bulkassignmentcompleteno: null,
                finishcomment: null
            },
            resultstatus: null,
            editRowDatas: [],
            finishenable: true,
            assigneelist: [],
            assignee: null,
            loginpersonmail: '',
            newfea: [{
                scriptcount: null,
                scriptassignee: null
            }],
            countassign: null,
            countunassigne: null,
            errors:{
                scriptcount:null,
                scriptassignee:null
            },
            lastrundetailslist: []
        }
    },
    mounted(){
        window.addEventListener('storage', function(event){
              if (event.key == 'logout-mee') { 
                  window.location.reload();
              }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.loginpersonmail=this.undt.emailaddress;
            this.tokendata = window.localStorage.getItem('token');
            this.loginusername = userdata.username
            this.getcyclesetviewbyid(this.$route.query.cyclesetid);
            this.getassigneunassigncount(this.$route.query.cyclesetid);
        }

    },
    methods:{
        resetForm() {
            this.newfea= [{}],
            this.errors.scriptassignee=null,
            this.errors.scriptcount=null
        },
        savewizard() {                
            this.isLoading = true;
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let apiURL='api/testcycle/savebulkassignee'
            this.input.cyclesetid=this.$route.query.cyclesetid
            this.input.nooftestcase=this.testcycle.nooftestcase
            this.input.bulkassignmentcompleteno=this.testcycle.bulkassignmentcompleteno?this.testcycle.bulkassignmentcompleteno:0
            let scriptcount=[]
            let scriptassignee=[]
            for (let u = 0; u < this.newfea.length; u++) {

                if (this.newfea[u].scriptassignee==null || (this.newfea[u].scriptcount==null ||this.newfea[u].scriptcount=="")) {
                    if (this.newfea[u].scriptcount==null || this.newfea[u].scriptcount=="") {
                        this.errors.scriptcount = 'Allocation count is required'
                    }
                    if (this.newfea[u].scriptassignee==null) {
                        this.errors.scriptassignee='Assign to is required'
                    }
                    this.isLoading = false;
                    Swal.fire({
                        title: "Failed",
                        text: 'Add required field!',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return
                }else if (this.newfea[u].scriptcount[0]==0 && this.newfea[u].scriptcount.length<=1) {
                    this.isLoading = false;
                    Swal.fire({
                        title: "Failed",
                        text: 'Allocation no should be greater than 0!',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return
                }else{
                    scriptcount.push(parseInt(this.newfea[u].scriptcount));
                    scriptassignee.push(parseInt(this.newfea[u].scriptassignee));
                }
            }
            const allcountsum = scriptcount.reduce( (partialSum, a) => partialSum + a, 0);
            this.input.countnumber = scriptcount;
            this.input.assignee = scriptassignee;
            if (allcountsum<=this.countunassigne) {
                
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resetForm();
                        this.getcyclesetviewbyid(this.$route.query.cyclesetid);
                        this.getassigneunassigncount(this.$route.query.cyclesetid);
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                   this.displayError(e)
                })
            }else{
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: 'Added allocation numbers count is greater than unallocated count!',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        },
        idclick(id,indexx){
            
            if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                $("#"+id+indexx).html("Read More");
                $("#cycle_readmore"+indexx).removeClass("readmore");
            } else {
                $("#"+id+indexx).html("Read Less");
                $("#cycle_readmore"+indexx).addClass("readmore");
            }
        },
        idclick2(id,indexx){
            
            if ($("#cycle_readmore2"+indexx).hasClass("readmore")) {
                $("#"+id+indexx).html("Read More");
                $("#cycle_readmore2"+indexx).removeClass("readmore");
            } else {
                $("#"+id+indexx).html("Read Less");
                $("#cycle_readmore2"+indexx).addClass("readmore");
            }
        },
        idclick3(id,indexx){
            if ($("#cycle_readmore3"+indexx).hasClass("readmore")) {
                $("#"+id+indexx).html("Read More");
                $("#cycle_readmore3"+indexx).removeClass("readmore");
            } else {
                $("#"+id+indexx).html("Read Less");
                $("#cycle_readmore3"+indexx).addClass("readmore");
            }
        },
        idclick4(id,indexx){
            if ($("#cycle_readmore4"+indexx).hasClass("readmore")) {
                $("#"+id+indexx).html("Read More");
                $("#cycle_readmore4"+indexx).removeClass("readmore");
            } else {
                $("#"+id+indexx).html("Read Less");
                $("#cycle_readmore4"+indexx).addClass("readmore");
            }
        },
        
        getassigneunassigncount(id){
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                cyclesetid: parseInt(id)
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getassigneunassigncount',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.countassign = result.data.data.countassign;
                    this.countunassigne = result.data.data.countunassigne;
                } else {
                    this.countassign = null;
                    this.countunassigne = null;
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        addField() {
            this.newfea.push({
                scriptcount: null,
                scriptassignee: null,
            })
        },
        removeField(index) {
            this.newfea.splice(index,1)          
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.allocatedhistory(this.$route.query.cyclesetid);
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#historytable").offset().top -70
                });
            }, 500);
        },
        getcyclesetviewbyid(cyclesetid){
         this.isLoading = true;
            let url = "api/testcycle/getcyclesetviewbyid";
            this.input = {
                cyclesetid:cyclesetid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0]
                    this.openEditModule(this.editRowData, 0)
                } else {
                    this.editRowData = [];
                    this.$router.push({ name: 'TestCycleSetList', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to update this test case",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        openEditModule(currRowData){
            this.testcycle.cyclesetid=currRowData.cyclesetid
            this.testcycle.cyclename=currRowData.cyclesetname
            this.testcycle.cyclesetcode = currRowData.cyclesetcode
            this.testcycle.businesscode=currRowData.businesstaskcode
            this.testcycle.businessname=currRowData.taskname
            this.testcycle.status=currRowData.status
            this.testcycle.startdate=currRowData.startdate
            this.testcycle.enddate=currRowData.enddate
            this.testcycle.createdby=currRowData.createdby
            this.testcycle.createddate=currRowData.createddate
            this.testcycle.lastmodifiedby=currRowData.lastmodifiedby
            this.testcycle.lastmodifieddate=currRowData.lastmodifieddate
            this.testcycle.version=currRowData.versionno
            this.testcycle.mappingname=currRowData.mappingname
            this.testcycle.assignto=currRowData.assignedto
            this.testcycle.revieweddate=currRowData.revieweddate
            this.testcycle.productpendingreviewdate=currRowData.productpendingreviewdate
            this.testcycle.rundate=currRowData.rundate
            this.testcycle.finishdate=currRowData.finishdate
            this.testcycle.spasscount=currRowData.spasscount
            this.testcycle.sfailcount=currRowData.sfailcount
            this.testcycle.nooftestcase=currRowData.nooftestcase
            this.testcycle.cycleruncount=currRowData.cycleruncount
            this.testcycle.bulkassignmentcompleteno=currRowData.bulkassignmentcompleteno
            this.testcycle.finishcomment = currRowData.finishcomment
            this.getassigneelist();
            this.getlastcomopletedhistory(currRowData.cyclesetid,currRowData.cycleruncount,currRowData.bulkassignmentcompleteno?currRowData.bulkassignmentcompleteno:0);
            this.allocatedhistory(this.$route.query.cyclesetid);
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        selasgn(state) {
            this.assignee = null;
            this.assignee = state;
        },
        getassigneelist(){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getassigneelist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    let decryptdepartmenttext = result.data.data.rows.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
                    this.assigneelist = result.data.data.rows;
                } else {
                    this.assigneelist = []
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        allocatedhistory(ids){
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                cyclesetid: ids,
                limit: apiUrl.LIMIT,
                offset: this.pageoffset
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/allocatedhistory',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.testcycle.allocatedhistory = result.data.data.rows;
                    this.totalcount = parseInt(result.data.data.count[0].count);
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                } else {
                    this.testcycle.allocatedhistory = [];
                    this.totalcount = 0;
                    this.pageCount =0;
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        getlastcomopletedhistory(idh,runno,bno){
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                cyclesetid: idh,
                cyclerunno: parseInt(runno),
                bulkassignmentcompleteno:bno
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getlastcomopletedhistory',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.lastrundetailslist = result.data.data.rows;
                } else {
                    this.lastrundetailslist = [];
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        formatData(code){
        let responsestrs='';
        let clist=code.split(',')
        if(clist.length > 1){
            for(let i=0;i<clist.length;i++){
                responsestrs +=(i+1)+'. '+clist[i]+' <br/>'
            }
        }else{
            for(let i=0;i<clist.length;i++){
                responsestrs +=clist[i]
            }
        }
        return responsestrs
        },
        showdate(date){
        let responsestrs='';
        let dlist=date.split(',')
        if(dlist.length > 1){
            for(let i=0;i<dlist.length;i++){
                responsestrs +=(i+1)+'. '+dlist[i]+' <br/>'
            }
        }else{
            for(let i=0;i<dlist.length;i++){
                responsestrs +=dlist[i]
            }
        }
        return responsestrs
        },
    },
}
</script>

<style scoped>
.text-center.tast_case_groupingview_readmore{
    width:300px;
}
</style>

