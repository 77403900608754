<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-12 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12 mobile_view_padd-05">
                                <h3 class="content-header-title float-left mb-0">Create Request</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/mydashboard'>My Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/travelrequest'>Travel Request</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card card_box_shadow filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick"  v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                            <div class="avatar bg-light-secondary rounded d-inline-flex" >
                                                <div class="avatar-content">
                                                    <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                </div>
                                            </div>
                                            <h4 class="card-title d-inline-flex ml-1">Search Travel Request</h4>
                                            </div>
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body card_padding_10">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Request ID     </label>
                                                <input class="form-control" type="text" placeholder="Enter Request ID" v-model.trim="search.requestid" :options="requestList"/>
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Status</label>
                                                <treeselect placeholder="Select Ticket Status" v-model.trim="search.status" :options="search.statuslist" />
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 common_trs_buttons_res">
                                                <button type="submit" class="btn btn-primary mt-2 mr-1" @click="getmasterlist(search.status,search.requestid)" >Search</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-2" v-on:click="mysearchrequestollapse" @click="resetRecords()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                            <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                <div class="avatar-content">
                                                    <clipboard-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></clipboard-icon>
                                                </div>
                                            </div>
                                            <h4 class="card-title d-inline-flex ml-1">Travel Request Details</h4>
                                            </div>
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body px-0 pt-0 full_main_containers_mobile">
                                        <div class="row">
    
                                            <!-- test1 start-->
                             <b-container fluid class="table-responsive_view">
                                <!-- Main table element -->
                                <div class="full_main_containers">
                                <b-table class="table table-sm dataTableListing table-striped theade_padding_y10"
                                :items="items"
                                :fields="fields"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                                stacked="md"
                                show-empty
                                small
                                @filtered="onFiltered"
                                v-model="currentItems"
                                >
                               
                                <template v-slot:cell(actions)="{ detailsShowing, item,index }" >
                                    <b-btn class="show_details_btn01" @click="toggleDetails(item);onRowSelected(item,index);">{{ detailsShowing ? 'Hide' : 'Show'}} Details</b-btn>
                                </template>
                                
                                <template #row-details="row" class="w-100">
                                    <b-card class="p-0 card_travels_mobile_veiw">
                                        <div class="table-responsive" style="max-width: 100vh; width: 100%; min-width: 100%;">
                                        <div id="app" class=" table_responce44">
                                            <b-row class="mx-0 inner_table_inlie_view mb-2">
                                                <b-col class="inner_icons_table_request_table_head">
                                                    <b-icon icon="person-fill" scale="2" class="inner_icons_table_request_table" variant="secondary"></b-icon>
                                                    <span class="viewdetais_namestext"> {{request.empname}} </span>
                                                </b-col>
                                                <b-col class="inner_icons_table_request_table_head">
                                                    <b-icon icon="envelope" scale="2" class="inner_icons_table_request_table" variant="warning"></b-icon>
                                                    <span class="viewdetais_namestext">{{request.designation}}</span>
                                                </b-col>
                                                <b-col class="inner_icons_table_request_table_head">
                                                    <b-icon icon="briefcase" scale="2"  class="inner_icons_table_request_table" variant="info"></b-icon>
                                                    <span class="viewdetais_namestext"> {{request.empid}} </span>
                                                </b-col>
                                                <b-col class="inner_icons_table_request_table_head">
                                                    <b-icon icon="grid-fill" scale="2" variant="success" class="inner_icons_table_request_table"></b-icon>
                                                    <span class="viewdetais_namestext">{{request.dept}} </span>
                                                </b-col>
                                            </b-row>
                                            <b-table-simple responsive table_view_travel_requests>
                                            <b-thead>
                                                <b-tr>
                                                    <b-th>Index</b-th>
                                                    <b-th class="text-left" style="min-width: 250px;">City Details</b-th>
                                                    <b-th>Group Travel list</b-th>
                                                    <b-th>Mode of Transport</b-th>
                                                    <b-th>Special Instructions</b-th>
                                                    <b-th class="text-left" style="min-width:270px;">Date Details</b-th>
                                                    <b-th>HOD Status</b-th>
                                                    <b-th>L1 Status</b-th>
                                                    <b-th>L1 Feedback</b-th>
                                                    <!-- <b-th>L2 Status</b-th> -->
                                                    <!-- <b-th>L2 Feedback</b-th> -->
                                                    <b-th>Admin Status</b-th>
                                                    <b-th style="min-width:280px;">Travel Docs</b-th>
                                                </b-tr>
                                            </b-thead>
                                            <b-tbody>
                                                <b-tr v-for="(data,index) in ticketdetails" :key="index">
                                                    <b-td>{{index+1}}</b-td>
                                                    <b-td class="text-left details_column_table">
                                                        <span class="heading_column_text"> City node : </span>
                                                        <span v-if="data.returnbooking ==2" class="font-weight-normal">Return Journey Details</span>
                                                        <span v-else-if="data.returnbooking ==1" class="font-weight-normal">City node - {{data.node}}</span>                                                    
                                                         <br/>
                                                         <span class="heading_column_text"> From City - To City : </span>
                                                         <span v-if="data.fromcityname !=null && data.cityname !=null">{{data.fromcityname}} To {{data.cityname}}</span>
                                                        <span v-else>No Travel</span>
                                                     </b-td>
                                                    <!-- <b-td class="text-left">
                                                        <span v-if="data.fromcityname !=null && data.cityname !=null">{{data.fromcityname}} To {{data.cityname}}</span>
                                                        <span v-else>No Travel</span>
                                                    </b-td> -->
                                                    <b-td>
                                                        <span v-if="data.isgrouptravelreq == 1 && data.nameofgrouptravel !=null">  
                                                            <eye-icon size="1.5x" class="custom-class" @click="getGroupTravelList(data.nameofgrouptravel)"></eye-icon>
                                                        </span>
                                                        <span v-else>N/A</span>
                                                    </b-td>
                                                    <b-td>
                                                        <span v-if="data.transportmode !=null">{{data.transportmode}}</span>
                                                        <span v-else>N/A</span>
                                                    </b-td>
                                                    <b-td class="text-left">
                                                        <!-- <span v-if="data.instruction !=null">{{data.instruction}}</span>
                                                        <span v-else>N/A</span> -->
                                                        <!--  -->
                                                        <span class="readmore_texttrs" v-if="data.instruction != null">                                                                 
                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div class="outer" :id="'cycle_readmore'+'instruction'+index">
                                                                        <span class="fc_m16_btcode_text" v-html="formatData(data.instruction)"></span>
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="data.instruction.length > 10">
                                                                        <a class="btn">
                                                                        <strong :id="'instruction'+index" @click="idclick('instruction'+index)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    <span v-else>N/A</span>
                                                        <!--  -->
                                                    </b-td>
                                                    <b-td class="text-left details_column_table">
                                                        <span class="heading_column_text"> Date Of Travel : </span>
                                                        <span v-if="data.traveldate">{{data.traveldate}}</span>
                                                        <span v-else>N/A</span>
                                                        <br/>
                                                        <span class="heading_column_text"> Appointment Date : </span>
                                                        <span v-if="data.appointmentdate">{{data.appointmentdate}}</span>
                                                        <span v-else>N/A</span>
                                                        <br/>
                                                        <span class="heading_column_text"> CheckIn Date - CheckOut Date : </span>
                                                        <span v-if="data.checkindate && data.checkoutdate">{{data.checkindate}} To {{data.checkoutdate}}</span>
                                                        <span v-else-if="data.returnbooking ==2">N/A</span>
                                                        <span v-else>No Accomodation</span>
                                                    </b-td>
                                                    
                                                    <b-td>
                                                        <small class="badge badge-pill badge-danger mr-1" v-if="data.revokestatus==2">Revoked</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==1">Cancellation pending</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==2">Cancellation Accepted</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==3">Cancellation Rejected</small>
                                                        <small class="badge badge-pill badge-primary mr-1" v-else-if="data.doneedapprovhod==1">Pending</small>
                                                        <small class="badge badge-pill badge-success mr-1" v-else-if="data.doneedapprovhod==2">Approved</small>
                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.doneedapprovhod==3">Reject</small>
                                                        <small v-else-if="data.doneedapprovhod==0 || data.doneedapprovhod==null">N/A</small>
                                                    </b-td>
                                                    <b-td>
                                                        <small class="badge badge-pill badge-danger mr-1" v-if="data.revokestatus==2">Revoked</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==1">Cancellation pending</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==2">Cancellation Accepted</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==3">Cancellation Rejected</small>
                                                        <small class="badge badge-pill badge-danger mr-1" v-else-if="data.doneedapprovhod==3">Reject</small>
                                                        <small class="badge badge-pill badge-primary mr-1" v-else-if="data.l1_status==0">Pending</small>
                                                        <small class="badge badge-pill badge-secondary mr-1"  v-else-if="data.l1_status==1">Draft</small>
                                                        <small class="badge badge-pill badge-success mr-1" v-else-if="data.l1_status==2">Approved</small>
                                                        <small class="badge badge-pill badge-warning mr-1"  v-else-if="data.l1_status==3">Feedback</small>
                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.l1_status==4">Reject</small>
                                                        <span v-if="data.l1_status == 3 && data.revokestatus!=2"><b-button variant="info" @click="feedbackRequest(data,1)">Feedback Request</b-button></span>
                                                        
                                                    </b-td>
                                                    <b-td>
                                                        <span v-if="data.l1_feedback && [2,3,4].includes(data.l1_status)">
                                                            {{data.l1_feedback}}
                                                        </span>
                                                    </b-td>
                                                    <!-- <b-td>
                                                        <span v-if="data.new_entry==1">N/A</span>
                                                        <span v-else> 
                                                        <small class="badge badge-pill badge-danger mr-1" v-if="data.revokestatus==2">Revoked</small>
                                                        <small class="badge badge-pill badge-danger mr-1" v-else-if="data.doneedapprovhod==3">Reject</small>
                                                        <small class="badge badge-pill badge-danger mr-1" v-else-if="data.l1_status==4">Reject</small>
                                                        <small class="badge badge-pill badge-primary mr-1" v-else-if="data.l2_status==0">Pending</small>
                                                        <small class="badge badge-pill badge-secondary mr-1"  v-else-if="data.l2_status==1">Draft</small>
                                                        <small class="badge badge-pill badge-success mr-1" v-else-if="data.l2_status==2">Approved</small>
                                                        <small class="badge badge-pill badge-warning mr-1"  v-else-if="data.l2_status==3">Feedback</small>
                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.l2_status==4">Reject</small>
                                                        <span v-if="data.l2_status == 3 && data.revokestatus!=2"><b-button variant="info" @click="feedbackRequest(data,2)">Feedback Request</b-button></span>
                                                        </span>
                                                    </b-td>
                                                    <b-td>
                                                        <span v-if="data.new_entry==1">N/A</span>
                                                        <span v-else>
                                                        <span v-if="data.l2_feedback && [2,3,4].includes(data.l2_status)">
                                                            {{data.l2_feedback}}
                                                        </span>
                                                        </span>
                                                    </b-td> -->
                                                    <b-td>
                                                        <small class="badge badge-pill badge-danger mr-1" v-if="data.revokestatus==2">Revoked</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==1">Cancellation pending</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==2">Cancellation Accepted</small>
                                                        <small class="badge badge-pill badge-warning mr-1" v-else-if="data.cancellation==3">Cancellation Rejected</small>
                                                        <small class="badge badge-pill badge-danger mr-1" v-else-if="data.l1_status==4 || data.l2_status==4 || data.doneedapprovhod==3">Reject</small>
                                                        <small class="badge badge-pill badge-primary mr-1"  v-else-if="data.l3_status==0">Pending</small>
                                                        <small class="badge badge-pill badge-secondary mr-1" v-else-if="data.l3_status==5">Ticket Uploaded</small>
                                                        <small class="badge badge-pill badge-danger mr-1"  v-else-if="data.l3_status==6">Ticket Closed</small>
                                                    </b-td>
                                                    <b-td class="media_action_text_tra d-flex align-items-center">
                                                        <div v-if="data.l1_status==4 || data.l2_status==4 || data.doneedapprovhod==3">
                                                        </div>
                                                        <div v-else-if="[0,3].includes(data.revokestatus)" class="m-auto  revoke_action_text_btn">
                                                            <b-btn @click="revokereq(data);" v-if="data.l3_status!=6 && ![1,2].includes(data.cancellation) && data.expiredate">Revoke</b-btn>
                                                        </div>
                                                        <div class="transaction-item" v-else-if="[1,2,3].includes(data.revokestatus)">
                                                            <div class="media">
                                                                <div class="avatar bg-light-warning rounded" >
                                                                    <div class="avatar-content">
                                                                        <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                    </div>
                                                                </div>
                                                                <div class="media-body ml-1">
                                                                    <!-- <h6 class="transaction-title">Revoked</h6> -->
                                                                    <small v-if="data.revokestatus==1">Revoked Requested</small>
                                                                    <small v-else-if="data.cancellation==1">Cancellation Requested</small>
                                                                    <small v-else-if="data.cancellation==2">Cancellation Accepted</small>
                                                                    <small v-else-if="data.cancellation==3">Cancellation Rejected</small>
                                                                    <small v-else-if="data.revokestatus==2">Revoked Accepted</small>
                                                                    <small v-else-if="data.revokestatus==3">Revoked Not Accepted</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.l1_status==4 || data.l2_status==4 || data.doneedapprovhod==3">
                                                        </div>
                                                        <div v-else-if="data.cancellation ==0" class="m-auto  revoke_action_text_btn"> 
                                                            <b-btn @click="cancellationreq(data);" v-if="![1,2].includes(data.revokestatus) && data.l3_status!=6 && data.expiredate && [0].includes(data.cancellation)">Cancellation</b-btn>
                                                        </div>
                                                        
                                                        <div class="transaction-item ml-1" v-if="data.remark">
                                                            <div class="media">
                                                                <div class="avatar bg-light-warning rounded" style="margin-top: 8px;">
                                                                    <div class="avatar-content">
                                                                        <pocket-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pocket-icon>
                                                                    </div>
                                                                </div>
                                                                <div class="media-body ml-1">
                                                                    <h6 class="transaction-title">Remark</h6>
                                                                    <small>{{data.remark}}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card" v-if="data.ticketdocs.length>0 && [0,3].includes(data.revokestatus)">
                                                            <div class="card-body">
                                                                <div class="row">
                                                                    <div class="table-responsive">
                                                                            <table aria-describedby="mydesc" class="table table-sm table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">SR#</th>
                                                                                        <th scope="col">Filename</th>
                                                                                        <th scope="col">Description</th>
                                                                                        <th scope="col" class="min-width:100px;">Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(doc,i) in data.ticketdocs" :key="i" >
                                                                                        <td> {{i+1}}</td>
                                                                                        <td>{{doc.filename}}</td>
                                                                                        <td>{{doc.description}}</td>
                                                                                        <td style="text-align: center!important;">
                                                                                            <div class="avatar bg-info rounded m-1">
                                                                                                <div class="avatar-content">
                                                                                                    <a @click="getDownloadedLink(doc)" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white"></download-icon></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </b-td>
                                                </b-tr>
                                            </b-tbody> 
                                            </b-table-simple>
                                            </div>
                                        </div>
                                    </b-card> 
                                </template>
                                </b-table>
                                </div>
                                <!-- User Interface controls -->
                                <b-row class="pagination_view_con mt-2 m-0">
                                    <b-col  class="my-1 pull-right pagination_view_co">
                                        <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                        align="fill"
                                        size="sm"
                                        class="my-0"
                                        ></b-pagination>
                                    </b-col>
                                </b-row>
                                
                            </b-container>                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="myrequestollapse">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                            <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                <div class="avatar-content">
                                                    <plus-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></plus-circle-icon>
                                                </div>
                                            </div>
                                            <h4 class="card-title d-inline-flex ml-1">Raise Travel Request</h4>
                                            </div>
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body padding-px-5">
                                        <div class="row mobile_margin-px-0">
                                            <div class="col-12">
                                                <section class="horizontal-wizard">
                                                    <div class="bs-stepper horizontal-wizard-example shadow-none">
                                                        <div class="bs-stepper-header">
                                                            <div :class="[{active:firstStepActive},step]" data-target="#account-details">
                                                                <button type="button" class="step-trigger" aria-selected="true">
                                                                    <span class="bs-stepper-box" style="margin-left:10px"> <user-icon size="1.5x" class="custom-class font-medium-3"></user-icon></span>
                                                                    <span class="bs-stepper-label">
                                                                        <span class="bs-stepper-title">Basic Details</span>
                                                                        <span class="bs-stepper-subtitle">Verify Basic Details</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div class="line">
                                                                <em data-feather="chevron-right" class="font-medium-2"></em>
                                                            </div>
                                                            <div :class="[{active:secondStepActive},step]" data-target="#personal-info">
                                                                <button type="button" class="step-trigger">
                                                                    <span class="bs-stepper-box"><map-pin-icon size="1.5x" class="custom-class font-medium-3"></map-pin-icon></span>
                                                                    <span class="bs-stepper-label">
                                                                        <span class="bs-stepper-title">Visit Details</span>
                                                                        <span class="bs-stepper-subtitle">Setup Visit Details</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div class="line">
                                                                <em data-feather="chevron-right" class="font-medium-2"></em>
                                                            </div>
                                                            <div :class="[{active:thirdStepActive},step]"  data-target="#address-step-vertical-modern">
                                                                <button type="button" class="step-trigger">
                                                                    <span class="bs-stepper-box">
                                                                        <map-pin-icon size="1.5x" class="custom-class font-medium-3"></map-pin-icon>
                                                                    </span>
                                                                    <span class="bs-stepper-label">
                                                                        <span class="bs-stepper-title">Travel Details</span>
                                                                        <span class="bs-stepper-subtitle">Setup Travel Details</span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="bs-stepper-content card_padding_10">
                                                            <div id="account-details" :class="[{active:FirstActive,dstepper_block:firstBlock},content]">
                                                                <form>
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="username">Employee ID</label>
                                                                            <input type="text"  class="form-control" v-model.trim="request.empid" disabled />
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" >Employee Name</label>
                                                                            <input type="text" class="form-control" v-model.trim="request.empname" disabled />
                                                                        </div>
                                                                        <div class="form-group col-md-4 submition_popups_views_icons">
                                                                            <info-icon size="1.5x" class="custom-class font-medium-3" v-on:click="displaymgr"></info-icon>
                                                                            <div class="form-group form-password-toggle col-md-12 px-0" :class="[{display:showmanager}]">
                                                                           <div class="content-header">
                                                                                <h5 class="mb-0">Post submission of request, your ticket will go for L1 manager Approval.</h5>
                                                                                <p>L1 Manager Name: <strong>{{l1?l1.toUpperCase():'NA'}} </strong></p>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group form-password-toggle col-md-4">
                                                                            <label class="form-label">Employee Designation</label>
                                                                            <input type="text" class="form-control" v-model.trim="request.designation" disabled/>
                                                                        </div>
                                                                        <div class="form-group form-password-toggle col-md-4">
                                                                            <label class="form-label" >Department</label>
                                                                            <input type="text" class="form-control"  v-model.trim="request.dept" disabled />
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </form>
                                                                <div class="d-flex justify-content-between">
                                                                    <button class="btn btn-outline-secondary btn-prev" disabled>
                                                                        <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                    </button>
                                                                    <button class="btn btn-primary btn-next">
                                                                        <span class="align-middle d-sm-inline-block d-none" @click="firstClick">Next</span>
                                                                        <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div id="personal-info"  :class="[{active:secondActive,dstepper_block:secondBlock},content]">
                                                                <form>
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">Visit Name</label>
                                                                            <input type="text" v-model.trim="request.visit" class="form-control" placeholder="Enter name to visit" @input="selvisit" @blur="validateForm"/>
                                                                            <div class="errorinputmsg" v-if="this.errors.visit">{{ this.errors.visit }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">Locations Visiting</label>
                                                                            <input type="text" v-model.trim="request.location" class="form-control" placeholder="Location Details" @input="sellocation" @blur="validateForm"/>
                                                                            <div class="errorinputmsg" v-if="this.errors.location">{{ this.errors.location }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">Purpose</label>
                                                                            <input type="text" v-model.trim="request.purpose" class="form-control" placeholder="Purpose of visit" @input="selpurpose" @blur="validateForm"/>
                                                                            <div class="errorinputmsg" v-if="this.errors.purpose">{{ this.errors.purpose }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" >Start Date</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <date-picker v-model.trim="request.startdate" 
                                                                                    :disabled-date="notBeforeToday"
                                                                                    value-type="format" placeholder="Select Start Date" @blur="validateForm" @input="selstartdate">
                                                                                </date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="this.errors.startdate">{{ this.errors.startdate }}</div>
                                                                        </div>
                                                                        <div class="form-group  col-md-4">
                                                                            <label class="form-label" >End Date</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <date-picker  v-model.trim="request.enddate" 
                                                                                    :disabled-date="notBeforeStartDateToday"
                                                                                    value-type="format" placeholder="Select End Date" @blur="validateForm" @input="selenddate"></date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="this.errors.enddate">{{ this.errors.enddate }}</div>
                                                                        </div>
                                                                    </div>
                                                                 </form>
                                                                <div class="d-flex justify-content-between">
                                                                    <button class="btn btn-primary btn-prev" @click="firstClick">
                                                                        <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                    </button>
                                                                    <button class="btn btn-primary btn-next"  v-on:click.stop.prevent="validateForm(1)">
                                                                        <span class="align-middle d-sm-inline-block d-none">Next</span>
                                                                        <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
                                                                    </button>
                                                                </div>
                                                                
                                                            </div>
                                                            <div id="address-step-vertical-modern" :class="[{active:thirdActive,dstepper_block:thirdBlock},content]  ">
                                                                <form>
                                                                <div class="row">
                                                                    <div class="form-group col-md-12 pull-right mt-1 text-right">
                                                                        <div class="avatar bg-success rounded" v-on:click="showmorevisit">
                                                                            <div class="avatar-content">
                                                                                <plus-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-circle-icon>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    
                                                                <div v-for="(travel, k) in travels" :key="k">   
                                                                    <span v-if="k !=0 "><div style="border-top:1px dotted;"></div> </span>                                                             
                                                                    <div class="content-header" style="margin-top: 10px">
                                                                        <span class="avatar bg-error rounded" style="background-color: red !important;float:right" v-on:click="deleteRow(k)">
                                                                            <div class="avatar-content">
                                                                                <minus-circle-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-circle-icon>                                                                            
                                                                            </div>
                                                                        </span>
                                                                        <h5 class="mb-0" style="color:blue;">City Node {{k+1}}</h5>
                                                                        <small class="text-muted">Enter Your City Node {{k+1}} Details.</small>
                                                                    </div>   
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="vertical-modern-landmark">Is travel required?</label>
                                                                            <div class="form-check form-check-inline mx-2">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="1"  v-model.trim="travels[k].istravel" v-on:click="showtravel(1,k)" :checked="true"/>
                                                                                <label class="form-check-label" for="ismastertype1">Yes</label>
                                                                            </div>
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="0" v-model.trim="travels[k].istravel"  v-on:click="showtravel(0,k)" />
                                                                                <label class="form-check-label" for="ismastertype2">No</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="vertical-modern-landmark">Do you need group travel request?</label>
                                                                            <div class="form-check form-check-inline mx-2">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="1"  v-model.trim="travels[k].grouptravel" v-on:click="showgrouptravel(1,k)"/>
                                                                                <label class="form-check-label" for="ismastertype1">Yes</label>
                                                                            </div>
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="0" v-model.trim="travels[k].grouptravel"  v-on:click="showgrouptravel(0,k)" :checked="true" />
                                                                                <label class="form-check-label" for="ismastertype2">No</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group col-md-4" v-bind:class="[{display:isActiveEndDateGroupTravel[k].status}]">                                                                        
                                                                            <label class="form-label" for="empname1">Employee name</label>
                                                                            <treeselect placeholder="Enter min 3 chars to search employee name" v-on:search-change="empnameChangeSearch" v-model.trim="travels[k].groupempname" :multiple="true" :flat="true" :options="emplist" :clearOnSelect=true />
                                                                            <div class="errorinputmsg" v-if="terrors[k].groupempname">{{ terrors[k].groupempname }}</div>
                                                                        </div>
                                                                    </div>    
    
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="pincode4">From City</label>
                                                                            <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.trim="travels[k].fromcity"  :options="citymasterList" @input="selcitydata"  @click="testcall" />
                                                                            <div class="errorinputmsg" v-if="terrors[k].fromcity">{{ terrors[k].fromcity }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">To City</label>
                                                                            <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.trim="travels[k].tocity"  :options="citymasterList" @input="seltocitydata"  @click="testcall"  />
                                                                            <div class="errorinputmsg" v-if="terrors[k].tocity">{{ terrors[k].tocity }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4" v-bind:class="[{display:isActiveEndDateTravel[k].status}]">
                                                                            <label class="form-label">Prefered Mode of Transport</label>
                                                                            <treeselect v-model.trim="travels[k].transport" placeholder="Select mode of transport" :options="transportList" @input="seltransportdata"  @click="testcall" />
                                                                            <div class="errorinputmsg" v-if="terrors[k].transport">{{ terrors[k].transport }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">Special Instructions</label>
                                                                            <textarea class="form-control" placeholder="Enter special instructions"  v-model.trim="travels[k].instruction"></textarea>
                                                                        </div>
                                                                        <div class="form-group col-md-4" v-bind:class="[{display:isActiveEndDateTravel[k].status}]">
                                                                            <label class="form-label" for="vertical-modern-address">Prefered Date Of Travel</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <date-picker v-model.trim="travels[k].dateoftravel" placeholder="Please select prefered date of travel"
                                                                                    :disabled-date="notBeforeTodayTravel" @input="seldateoftravel"
                                                                                    value-type="format">
                                                                                </date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="terrors[k].dateoftravel">{{ terrors[k].dateoftravel }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4" v-bind:class="[{display:isActiveEndDateTravel[k].status}]">
                                                                            <label class="form-label" for="vertical-modern-landmark">Appointment date/ reporting date</label>
                                                                            <div class="input-group input-group-merge">                                                                            
                                                                                <date-picker v-model.trim="travels[k].appintmentdate" placeholder="Please select appointment date"
                                                                                    :disabled-date="notBeforeStartDate" @input="selappintmentdate"
                                                                                    value-type="format">
                                                                                </date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="terrors[k].appintmentdate">{{ terrors[k].appintmentdate }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="vertical-modern-landmark">Is accomodation required?</label>
                                                                            <div class="form-check form-check-inline mx-2">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="1"  v-model.trim="travels[k].accomadation" v-on:click="showdate(1,k)" :checked="true"/>
                                                                                <label class="form-check-label" for="ismastertype1">Yes</label>
                                                                            </div>
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="0" v-model.trim="travels[k].accomadation"  v-on:click="showdate(0,k)" />
                                                                                <label class="form-check-label" for="ismastertype2">No</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row"  v-bind:class="[{display:isActiveEndDate[k].status}]">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="pincode4">Check in date and time</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <date-picker type="datetime" placeholder="Please select check in date and time" v-model.trim="travels[k].checkin"  :disabled-date="notBeforeTodayCheckIn" value-type="format" :disabled-time="notBeforeTodayTwelveOClock" @open="currentrow(k)" @select="selected" @input="selcheckin"></date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="terrors[k].checkin">{{ terrors[k].checkin }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="city4">Check-out date and time</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <date-picker type="datetime" placeholder="Check-out date and time" v-model.trim="travels[k].checkout"  :disabled-date="notBeforeTodaytime" @open="currentrow(k)" value-type="format" :disabled-time="notBeforeTodayTwelveOClock" @select="selected" @input="selcheckout"></date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="terrors[k].checkout">{{ terrors[k].checkout }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="city4">Number of nights</label>
                                                                            <input type="text" v-model.trim="travels[k].totalnights" class="form-control" placeholder="Number of nights" disabled  @click="selected"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <!--Add return Booking required : Start-->
                                                                <div :class="[{display:returnbooking}]" style="border-top:1px dotted;">
                                                                    <div class="content-header" style="margin-top: 10px">
                                                                        <h5 class="mb-0" style="color:blue;">Return Journey Details</h5>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="pincode4">From City</label>
                                                                            <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.trim="request.fromcity3"  :options="citymasterList" @input="selcitydata3"  @click="testcall" />
                                                                            <div class="errorinputmsg" v-if="this.errors.fromcity3">{{ this.errors.fromcity3 }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">To City</label>
                                                                            <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.trim="request.tocity3"  :options="citymasterList" @input="seltocitydata3"  @click="testcall" />
                                                                            <div class="errorinputmsg" v-if="this.errors.tocity3">{{ this.errors.tocity3 }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">Prefered Mode of Transport</label>
                                                                            <treeselect v-model.trim="request.transport3" placeholder="Select mode of transport" :options="transportList" @input="seltransportdata3"  @click="testcall" />
                                                                            <div class="errorinputmsg" v-if="this.errors.transport3">{{ this.errors.transport3 }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label">Special Instructions</label>
                                                                            <textarea class="form-control" placeholder="Enter special instructions"  v-model.trim="request.instruction3"></textarea>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="vertical-modern-address">Prefered Date Of Travel</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <div class="input-group-prepend">
                                                                                    
                                                                                </div>
                                                                                <date-picker v-model.trim="request.dateoftravel3" placeholder="Please select prefered date of travel"
                                                                                    :disabled-date="notBeforeTodayReturnBook" @input="seldateoftravel3"
                                                                                    value-type="format">
                                                                                </date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="this.errors.dateoftravel3">{{ this.errors.dateoftravel3 }}</div>
                                                                        </div>
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="vertical-modern-landmark">Appointment date/ reporting date</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <div class="input-group-prepend">
                                                                                    
                                                                                </div>
                                                                                <date-picker v-model.trim="request.appintmentdate3" placeholder="Please select appointment date"
                                                                                    :disabled-date="notBeforeStartDateReturn" @input="selappintmentdate3"
                                                                                    value-type="format">
                                                                                </date-picker>
                                                                            </div>
                                                                            <div class="errorinputmsg" v-if="this.errors.appintmentdate3">{{ this.errors.appintmentdate3 }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="form-group col-md-4">
                                                                            <label class="form-label" for="vertical-modern-landmark">Do you need group travel request?</label>
                                                                            <div class="form-check form-check-inline mx-2">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="1"  v-model.trim="request.grouptravel3" v-on:click="rshowgrouptravel(1)"/>
                                                                                <label class="form-check-label" for="ismastertype1">Yes</label>
                                                                            </div>
                                                                            <div class="form-check form-check-inline">
                                                                                <input class="form-check-input ismastertypeOptions" type="radio" :value="0" v-model.trim="request.grouptravel3"  v-on:click="rshowgrouptravel(0)" :checked="true" />
                                                                                <label class="form-check-label" for="ismastertype2">No</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group col-md-3" v-bind:class="[{display:risActiveEndDateGroupTravel}]">                                                                        
                                                                            <label class="form-label" for="empname1">Employee name</label>
                                                                            <treeselect placeholder="Enter min 3 chars to search employee name" v-on:search-change="empnameChangeSearch" v-model.trim="request.groupempname3" :multiple="true" :flat="true" :options="emplist" :clearOnSelect=true />
                                                                            <div class="errorinputmsg" v-if="this.errors.groupempname3">{{ errors.groupempname3 }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--Add Return Booking : End-->
    
                                                                
                                                                <div class="row">
                                                                    
                                                                    <div class="form-group col-md-4">
                                                                       <label class="form-label" for="project-name">Is return booking required? </label>
                                                                        <div class="form-check form-check-inline mx-2">
                                                                            <input class="form-check-input ismastertypeOptions" type="radio" name="booking" id="ismastertype1" :value="1"  v-model.trim="request.booking" v-on:click="showreturnbooking(1)"/>
                                                                            <label class="form-check-label" for="ismastertype1">Yes</label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input ismastertypeOptions" type="radio" name="booking" id="ismastertype2" :value="0" v-model.trim="request.booking" checked v-on:click="showreturnbooking(0)"/>
                                                                            <label class="form-check-label" for="ismastertype2">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </form>
                                                                <div class="d-flex justify-content-between">
                                                                    <button class="btn btn-primary btn-prev" v-on:click.stop.prevent="validateForm(1)">
                                                                        <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                                                                        <span class="align-middle d-sm-inline-block d-none">Previous</span>
                                                                    </button>
                                                                    <button class="btn btn-primary" @click="createRequestDetails(0)">Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--VIew Modal-->
            <!-- Modal -->
        <div class="modal fade" id="exampleModalScrollable" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document" style="max-width:900px !important;">
                <div class="modal-content">
                    <div class="modal-header bg_background_popups_top">
                        <h5 class="modal-title" id="exampleModalScrollableTitle">Travel User List</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body bg_background_popups">
                        <div class="row match-height">
                            <div>
                                <b-table striped hover :items="empdetails"></b-table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="grouptravelist" tabindex="2" role="dialog" aria-labelledby="grouptravelTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog modal-lg" role="document" >
                <div class="modal-content">
                    <div class="modal-header bg_background_popups_top">
                        <h5 class="modal-title" id="EmploadDetails">Group Employee Travel List</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body bg_background_popups">
                        <div class="row match-height">
                            <div class="table-responsive">
                                <b-table striped hover :items="groputravelinfo"></b-table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    
          <!-- Edit feedback Modal -->
        <div class="modal fade bd-example-modal-lg" id="EditFeedbackDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable" role="document" style="max-width:900px !important;">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleEditFeedbackTitle">Feedback Request</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
            <div class="card-body px-0">
                <div class="row">
                    <div class="col-12">
                        <section class="horizontal-wizard mb-1">
                            <div class="bs-stepper horizontal-wizard-example shadow-none">
                                <div class="bs-stepper-content p-0">
                                    <div id="address-step-vertical-modern">
                                        <form>
                                        <div id="personal-info" v-bind:class="[{display:fisActiveEndDate}]">
                                            <div class="content-header" style="margin-top: 10px">
                                                <h5 class="mb-0" style="color:blue;">City Node</h5>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="vertical-modern-landmark">Is travel required?</label>
                                                    <div class="form-check form-check-inline mx-2">
                                                        <input class="form-check-input ismastertypeOptions" type="radio" :value="1" v-model.trim="fbrequest.istravel" v-on:click="fshowtravel(1)"/>
                                                        <label class="form-check-label" for="ismastertype1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input ismastertypeOptions" type="radio" :value="0" v-model.trim="fbrequest.istravel"  v-on:click="fshowtravel(0)" />
                                                        <label class="form-check-label" for="ismastertype2">No</label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="vertical-modern-landmark">Do you need group travel request?</label>
                                                    <div class="form-check form-check-inline mx-2">
                                                        <input class="form-check-input ismastertypeOptions" type="radio" :value="1"  v-model.trim="fbrequest.grouptravel" v-on:click="fshowgrouptravel(1)" />
                                                        <label class="form-check-label" for="ismastertype1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input ismastertypeOptions" type="radio" :value="0" v-model="fbrequest.grouptravel"  v-on:click="fshowgrouptravel(0)" />
                                                        <label class="form-check-label" for="ismastertype2">No</label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-3" v-bind:class="[{display:fisActiveEndDateGroupTravel}]">                                                                        
                                                    <label class="form-label" for="empname1">Employee name</label>
                                                    <treeselect placeholder="Enter min 3 chars to search employee name" v-on:search-change="empnameChangeSearch" :multiple="true" :flat="true" v-model.trim="fbrequest.groupempname" :sort-value-by="sortValueBy" :options="emplist" :clearOnSelect=true />
                                                    <div class="errorinputmsg" v-if="this.fberrors.groupempname">{{ this.fberrors.groupempname }}</div>
                                                </div>
                                            </div> 
    
                                            <div class="row">
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="pincode4">From City</label>
                                                    <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.number="fbrequest.fromcity" :sort-value-by="sortValueBy" :options="citymasterList" @input="selcitydata"  @click="testcall" />
                                                    <div class="errorinputmsg" v-if="this.fberrors.fromcity">{{ this.fberrors.fromcity }}</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label">To City</label>
                                                    <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.number="fbrequest.tocity" :sort-value-by="sortValueBy" :options="citymasterList" @input="seltocitydata"  @click="testcall" />
                                                    <div class="errorinputmsg" v-if="this.fberrors.tocity">{{ this.fberrors.tocity }}</div>
                                                </div>
                                                <div class="form-group col-md-4" v-bind:class="[{display:fisActiveEndDateTravel}]">
                                                    <label class="form-label">Prefered Mode of Transport</label>
                                                    <treeselect v-model.trim="fbrequest.transport"  :options="transportList" disabled @input="seltransportdata"  @click="testcall" />
                                                    <div class="errorinputmsg" v-if="this.fberrors.transport">{{ this.fberrors.transport }}</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-4" v-bind:class="[{display:fisActiveEndDateTravel}]">
                                                    <label class="form-label" for="vertical-modern-address">Prefered Date Of Travel</label>
                                                    <div class="input-group input-group-merge">
                                                        
                                                        <date-picker class="w-100" v-model.trim="fbrequest.dateoftravel" 
                                                            :disabled-date="notBeforeTodayTravel"
                                                            value-type="format">
                                                        </date-picker>
                                                    </div>
                                                    <div class="errorinputmsg" v-if="this.fberrors.dateoftravel">{{ this.fberrors.dateoftravel }}</div>
                                                </div>
                                                <div class="form-group col-md-4" v-bind:class="[{display:fisActiveEndDateTravel}]">
                                                    <label class="form-label" for="vertical-modern-landmark">Appointment date/ reporting date</label>
                                                    <div class="input-group input-group-merge">
                                                        
                                                        <date-picker v-model.trim="fbrequest.appintmentdate"
                                                            :disabled-date="fbnotBeforeStartDate"
                                                            value-type="format" class="w-100">
                                                        </date-picker>
                                                    </div>
                                                    <div class="errorinputmsg" v-if="this.fberrors.appintmentdate">{{ this.fberrors.appintmentdate }}</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label w-100" for="vertical-modern-landmark">Is accomodation required?</label>
                                                    <div class="form-check form-check-inline ml-0"> 
                                                        <input class="form-check-input ismastertypeOptions" type="radio" name="accomodation" :value="1" id="ismastertype1"  v-model.trim="fbrequest.accomadation"  v-on:click="fshowdate(1)"/>
                                                        <label class="form-check-label" for="ismastertype1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input ismastertypeOptions" type="radio" name="accomodation" :value="0" id="ismastertype2"  v-model.trim="fbrequest.accomadation" v-on:click="fshowdate(0)" />
                                                        <label class="form-check-label" for="ismastertype2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-bind:class="[{display:fisActiveaccomodation}]">
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="pincode4">Check in date and time</label>
                                                        <div class="input-group input-group-merge">
                                                        
                                                        <date-picker class="w-100" type="datetime" v-model.trim="fbrequest.checkin"  :disabled-date="fbnotBeforeTodayCheckIn" value-type="format" :disabled-time="fbnotBeforeTodayTwelveOClock" @select="selected" ></date-picker>
                                                    </div>
                                                    <div class="errorinputmsg" v-if="this.fberrors.checkin">{{ this.fberrors.checkin }}</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="city4">Check-out date and time</label>
                                                    <div class="input-group input-group-merge">
                                                        
                                                        <date-picker class="w-100" type="datetime" v-model.trim="fbrequest.checkout"  :disabled-date="fbnotBeforeTodaytime" value-type="format" :disabled-time="fbnotBeforeTodayTwelveOClock" @select="selected" ></date-picker>
                                                    </div>
                                                    <div class="errorinputmsg" v-if="this.fberrors.checkout">{{ this.fberrors.checkout }}</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="city4">Number of nights</label>
                                                    <input type="text" v-model.trim="fbrequest.totalnights" class="form-control" placeholder="Number of nights" disabled  @click="selected"/>
                                                </div>
                                            </div>
                                        </div>    
                                        <!--Add return Booking required : Start-->
                                        <div :class="[{display:freturnbooking}]">
                                            <div class="content-header" style="margin-top: 10px">
                                                <h5 class="mb-0" style="color:blue;">Return Journey Details</h5>
                                                
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="pincode4">From City</label>
                                                    <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.number="fbrequest.fromcity3" :sort-value-by="sortValueBy" :options="citymasterList" @input="selcitydata3"  @click="testcall" />
                                                    <div class="errorinputmsg" v-if="this.fberrors.fromcity3">{{ this.fberrors.fromcity3 }}</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label">To City</label>
                                                    <treeselect placeholder="Enter min 3 chars to search city name" v-on:search-change="citynameChangeSearch" v-model.number="fbrequest.tocity3" :sort-value-by="sortValueBy" :options="citymasterList" @input="seltocitydata3"  @click="testcall" />
                                                    <div class="errorinputmsg" v-if="this.fberrors.tocity3">{{ this.fberrors.tocity3 }}</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label">Prefered Mode of Transport</label>
                                                    <treeselect v-model.trim="fbrequest.transport3"  :options="transportList" disabled @input="seltransportdata3"  @click="testcall" />
                                                    <div class="errorinputmsg" v-if="this.fberrors.transport3">{{ this.fberrors.transport3 }}</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="vertical-modern-address">Prefered Date Of Travel</label>
                                                    <div class="input-group input-group-merge">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><em data-feather="user"></em></span>
                                                        </div>
                                                        <date-picker v-model.trim="fbrequest.dateoftravel3" 
                                                            :disabled-date="fbnotBeforeTodayReturnBook"
                                                            value-type="format">
                                                        </date-picker>
                                                    </div>
                                                    <div class="errorinputmsg" v-if="this.fberrors.dateoftravel3">{{ this.fberrors.dateoftravel3 }}</div>
                                                </div>
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="vertical-modern-landmark">Appointment date/ reporting date</label>
                                                    <div class="input-group input-group-merge">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><em data-feather="user"></em></span>
                                                        </div>
                                                        <date-picker v-model.trim="fbrequest.appintmentdate3"
                                                            :disabled-date="fbnotBeforeStartDateReturn"
                                                            value-type="format">
                                                        </date-picker>
                                                    </div>
                                                    <div class="errorinputmsg" v-if="this.fberrors.appintmentdate3">{{ this.fberrors.appintmentdate3 }}</div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-md-4">
                                                    <label class="form-label" for="vertical-modern-landmark">Do you need group travel request?</label>
                                                    <div class="form-check form-check-inline mx-2">
                                                        <input class="form-check-input ismastertypeOptions" type="radio" :value="1"  v-model.trim="fbrequest.grouptravel3" v-on:click="rshowgrouptravel(1)"/>
                                                        <label class="form-check-label" for="ismastertype1">Yes</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input ismastertypeOptions" type="radio" :value="0" v-model.trim="fbrequest.grouptravel3"  v-on:click="rshowgrouptravel(0)" :checked="true" />
                                                        <label class="form-check-label" for="ismastertype2">No</label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-4" v-bind:class="[{display:risActiveEndDateGroupTravel}]">                                                                        
                                                    <label class="form-label" for="empname1">Employee name</label>
                                                    <treeselect placeholder="Enter min 3 chars to search employee name" v-on:search-change="empnameChangeSearch" v-model.trim="fbrequest.groupempname3" :multiple="true" :flat="true" :sort-value-by="sortValueBy" :options="emplist" :clearOnSelect=true />
                                                    <div class="errorinputmsg" v-if="this.fberrors.groupempname3">{{ fberrors.groupempname3 }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Add Return Booking : End-->                           
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" v-on:click.stop.prevent="updateFeedbackRequestDetails">Submit</button>
            </div>
            </div>
        </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import DatePicker from 'vue2-datepicker';
    import datetime from 'vuejs-datetimepicker';
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import 'vue2-datepicker/index.css';
    import { BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh } from 'bootstrap-vue'
    import {PlusIcon, PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
    SearchIcon,PlusCircleIcon,ClipboardIcon,UserIcon,MapPinIcon,InfoIcon,MoreVerticalIcon,EditIcon,EyeIcon,MinusCircleIcon,DownloadIcon } from 'vue-feather-icons'
    // Import component
    import Loading from 'vue-loading-overlay';
     // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    import Pagination from "vue-pagination-2";
    import apiUrl from "../../../constants";
    export default{
        name:'TravelRequest',
        
        data() {
            return {
                tokendata:null,
                search:{
                    requestid:'',
                    status:null,
                    statuslist:[{
                    id:'0',
                    label:'Pending'
                    },{
                        id:'1',
                        label:'Draft'
                    },{
                        id:'2',
                        label:'Approved'
                    },{
                        id:'4',
                        label:'Rejected'
                    },{
                        id:'5',
                        label:'File Uploaded'
                    },{
                        id:'6',
                        label:'Closed'
                    },
                    {
                        id:'7',
                        label:'Revoked'
                    }
                 ]
                },
                currentDate:moment().format('YYYY-MM-DD'),
                rowindex: 0,
                firstStepActive:true,
                secondStepActive:false,
                thirdStepActive:false,
                FirstActive:true,
                firstBlock:true,
                secondActive:false,
                secondBlock:false,
                thirdActive:false,
                thirdBlock:false,
                content:'content',
                step:'step',
                dstepper_block:'dstepper-block',
                ticketdetails:[],
                isLoading: false,
                fullPage: true,
                un:'',
                username:'',
                empid:'',
                deptid:'',
                companyid:'',
                empcode:'',
                l1:'',
                l2:'',
                hodid:'',
                sortValueBy: 'ORDER_SELECTED',
                request:{
                    empid:'',
                    empname:'',
                    designation:'',
                    dept:'',
                    dateofjourney:'',
                    accomadation:1,
                    accomadation2:1,
                    booking:0,
                    returndate:'',
                    transport:null,
                    noofdays:'',
                    checkin:'',
                    checkout:'',
                    appintmentdate:'',
                    visit:'',
                    location:'',
                    purpose:'',
                    startdate:'',
                    enddate:'',
                    fromcity:null,
                    tocity:null,
                    dateoftravel:'',
                    fromcity2:null,
                    tocity2:null,
                    transport2:null,
                    dateoftravel2:'',
                    appintmentdate2:'',
                    checkin2:'',
                    checkout2:'',
    
                    fromcity3:null,
                    tocity3:null,
                    transport3:null,
                    dateoftravel3:'',
                    appintmentdate3:'',
                    deptid:'',
                    designationid:'',
    
                    totalnights:0,
                    totalnights2:0,
                    grouptravel3:0,
                    groupempname3:null 
                },
                errors:{
                    visit:'',
                    location:'',
                    purpose:'',
                    startdate:'',
                    enddate:'',
    
                    fromcity:'',
                    tocity:'',
                    transport:null,
                    checkin:'',
                    checkout:'',
                    appintmentdate:'',
                    dateoftravel:'',
                    accomadation:1,
                    booking:0,
    
                    accomadation2:1,
                    fromcity2:null,
                    tocity2:null,
                    transport2:null,
                    returndate2:null,
                    appintmentdate2:null,
                    checkin2:null,
                    checkout2:null,
                    dateoftravel2:null,
    
                    fromcity3:null,
                    tocity3:null,
                    transport3:null,
                    returndate3:null,
                    appintmentdate3:null,    
                    dateoftravel3:null,
                    managerid:'',
                    groupempname3:''
                },
                fbrequest:{
                    fromcity:null,
                    tocity:null,
                    transport:null,
                    dateoftravel:'',
                    appintmentdate:'',
                    accomadation: 0,
                    checkin:'',
                    checkout:'',
                    totalnights:0,
                    fromcity3:null,
                    tocity3:null,
                    transport3:null,
                    dateoftravel3:'',
                    appintmentdate3:'',
                    level:'',
                    nodestatus: '',
                    id:'',
                    ticketid:'',
                    node: 0,
                    istravel: 0,
                    grouptravel: 0,
                    groupempname: null,
                    grouptravel3: 0,
                    groupempname3: null
                },
                fberrors:{
                    fromcity:null,
                    tocity:null,
                    transport:null,
                    dateoftravel:'',
                    appintmentdate:'',
                    accomadation:'',
                    checkin:'',
                    checkout:'',
                    totalnights:0,
                    fromcity3:null,
                    tocity3:null,
                    transport3:null,
                    dateoftravel3:'',
                    appintmentdate3:'',
                    level:'',
                    nodestatus: '',
                    groupempname:'',
                    groupempname3:''
                },
                travels: [{
                    fromcity:null,
                    tocity:null,
                    transport:null,
                    dateoftravel:'',
                    appintmentdate:'',
                    checkin:'',
                    checkout:'',
                    totalnights:0,
                    accomadation:1,
                    istravel:1,
                    grouptravel:0,
                    groupempname:null,
                    instruction:null
    
                }],
                terrors: [{
                    fromcity:'',
                    tocity:'',
                    transport:'',
                    dateoftravel:'',
                    appintmentdate:'',
                    checkin:'',
                    checkout:'',
                    groupempname:''
                }],
                emptypeBlured:false,
                pagefeatures:[],
                requestList:[],
                isDetActive:true,
                isRequestActive:false,
                isSearchRequestActive:false,
                card_content:'card-content',
                collapse:'collapse',
                isActiveEndDate:[{status:false}],
                isActiveEndDateTravel:[{status:false}],
                isActiveEndDateGroupTravel:[{status:true}],
                addmorecity:true,
                returnbooking:true,
                showmanager:true,
                citymasterList:[],
                isActiveEndDate2:false,
                transportList:[{
                        id:'Train',
                        label:'Train'
                    },
                    {
                        id:'Bus',
                        label:'Bus'
                    },
                    {
                        id:'Flight',
                        label:'Flight'
                    },
                    {
                        id:'Cab',
                        label:'Cab'
                    }],
                usereligibletransport:[],    
                managerid:'',
                visitname:'',
                visitlocation:'',
                purpose:'',
                startdate:'',
                enddate:'',
                freturnbooking:false,
                fisActiveEndDate:false,
                fisActiveaccomodation: false,
                fisActiveEndDateTravel:false,
                fisActiveEndDateGroupTravel:false,
                risActiveEndDateGroupTravel: true,
                reportingto:'',
                l1mail:'',
                l2mail:'',
                emplist:[],
                empdetails:[],
                totalcount: 0,
                pageCount: 0,
                page: 1,
                pagelimit: apiUrl.LIMIT,
                currPageNum: 0,
                pageoffset: 1,
                groputravelinfo:[],
                showtabledata: true,
                isdraft: false,
                draftButtonShow: true,
                iseditstate:false,
                crow: 0,
                items:[],
                fields: [
              { key: 'ticketid', label: 'Request ID', },
              { key: 'visitname', label: 'Visit Name', thClass: 'travelName_ht_textleft', tdClass: 'travelName_td130'},
              { key: 'visitlocation', label: 'Locations Visit', thClass: 'travelName_ht_textleft', tdClass: 'travelName_td130'},
              { key: 'purpose', label: 'Purpose', thClass: 'travelName_ht_textleft', tdClass: 'travelName_td130'},
              { key: 'startdate', label: 'Start Date'},
              { key: 'enddate', label: 'End Date'},
              { key: 'createddate', label: 'Request Date' },
              {
                key: 'status', thClass: 'short_travelName_ht_textleft',
                label: 'status',
                formatter: (value, key, item) => {
                    if(value == 0) {
                        return 'Pending'
                    } else if(value == 1) {
                        return 'Draft'
                    } else if(value == 2) {
                        return 'Approved'
                    } else if(value == 3) {
                        return 'Feedback'
                    } else if(value == 4) {
                        return 'Rejected'
                    } else if(value == 5) {
                        return 'File Uploaded'
                    } else if(value == 6) {
                        return 'Closed'
                    } else if(value == 7) {
                        return 'Revoked'
                    }  else if(value == 8) {
                        return 'Cancellation'
                    }
                },
                sortable: true,
                sortByFormatted: true,
                filterByFormatted: true
              },
              { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 21,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
           filter: null,
            filterOn: [],
            currentItems: [],
            infoModal: {
              id: 'info-modal',
              title: '',
              content: ''
            }       
            }  
        } ,
        components: {
            PocketIcon,CodepenIcon,AtSignIcon,MailIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
            EditIcon,
            EyeIcon,
            MoreVerticalIcon,
            Loading,
            Treeselect,
            DatePicker,
            PlusIcon,
            SearchIcon,
            PlusCircleIcon,
            ClipboardIcon,
            UserIcon,
            MapPinIcon,
            InfoIcon,
            datetime,
            Pagination,
            MinusCircleIcon,
            DownloadIcon,
          BTable,BTableLite,BTbody,BThead,BTr,BTd,BTh
        },
        created() {
            this.pagefeatures = {}
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/travelrequest') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        },
        computed: {
          sortOptions() {
            // Create an options list from our fields
            return this.fields
              .filter(f => f.sortable)
              .map(f => {
                return { text: f.label, value: f.key }
              })
          }
        },
        mounted(){
            
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.empid = userdata.userid
                this.companyid = userdata.companyid
                // this.deptid = userdata.department.departmentid
                this.getprofiledata()
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
            this.getmasterlist(0,'');
            $(".filter_rotation_onlick").click(function(){
            $(this).toggleClass("common_arrow_rotetion");
            });
            
            
        },    
    
        methods:{
            getDownloadedLink(data) {
                if(data) {
                    let payload = {
                        documents:[data],
                        empcode: this.empid,
                        useremail: this.username
                    };
                    this.apiURL="/api/reimbursement/getdownloadlink"
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':payload,
                        headers: { authorization: this.tokendata },
                    })
                    .then((result) => {
                        this.isLoading = false
                        if(result.data.status) {
                            if(result.data.data[0].presignurl){
                                window.open(result.data.data[0].presignurl, '_blank')     
                            }
                        }
                    });
                }
            },
            formatData(data) {
                console.log("data",data,data.length);
                return data
            },
            idclick(indexx){
                if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                    $("#"+indexx).html("Read More");
                    $("#cycle_readmore"+indexx).removeClass("readmore");
                } else {
                    $("#"+indexx).html("Read Less");
                    $("#cycle_readmore"+indexx).addClass("readmore");
                }
            },
            revokereq(data) {
                //0=> pending, 1=revoke req, 2=> revoke accept, 3=> revoke not accept
                this.payload= {
                    id: data.id,
                    useremail:this.username,
                    ticketid: data.ticketid,
                    empcode: this.empid
                }
                if([0,2,3].includes(data.l1_status) && data.l3_status ==5) {
                    this.payload.revoked = 1
                } else {
                    this.payload.revoked = 2
                }
                
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to revoke the travel request?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then(async(result) => {
                   if (result.isConfirmed) {
                    this.isLoading = true;
                    axios({
                            'method': 'POST',
                            'url': "api/reimbursement/revoketravelticket",
                            'data':this.payload,
                            headers: { authorization: this.tokendata },
                    }).then((response) => {
                        this.isLoading = false;
                        if(response.data.status){
                            this.getTicketDetails(data.ticketid);
                            this.getmasterlist(0,'')
                            Swal.fire({
                                title: "Success!",
                                text: response.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                           // $("#EditFeedbackDetails").modal('hide');
                        }else{
                            Swal.fire({
                                title: "Error!",
                                text: response.data.msg,
                                icon: 'error',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    })
                   }
                });
            },
            cancellationreq(data){
                $(document).off('focusin.modal');
                let input = {
                    //createdname:this.loguser,
                    ticketid : data.ticketid,
                    id : data.id,
                    //createdby : this.username,
                    empcode:this.empid,
                    useremail: this.username
                }
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to cancellation this travel request?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then(async(result) => {
                    if (result.isConfirmed) {
                        const { value: text } = await Swal.fire({
                            input: 'textarea',
                            inputLabel: 'Comment',
                            inputPlaceholder: 'Please comment here...',
                            inputAttributes: {
                                'aria-label': 'Please comment here'
                            },
                            showCancelButton: true,
                            customClass: {
                                validationMessage: 'my-validation-message'
                            },
                            preConfirm: (value) => {
                                if (!value) {
                                Swal.showValidationMessage(
                                    '<em class="fa fa-info-circle"></em> Comment is required'
                                )
                                }
                            }
                        })
                        if (text) {
                            input.cancelreason =text;
                            this.updateCancellationStatus(input)
                        }
                    } 
                });
            },
            updateCancellationStatus(payload) {
                //0=> pending, 1=cancellation request, 2=> cancellation accept, 3=> cancellation not accept
                this.isLoading = true;
                axios({
                    'method': 'POST',
                    'url': "api/reimbursement/cancellationtravelticket",
                    'data':payload,
                    headers: { authorization: this.tokendata },
                }).then((response) => {
                    this.isLoading = false;
                    if(response.data.status){
                        this.getTicketDetails(payload.ticketid);
                        this.getmasterlist(0,'')
                        Swal.fire({
                            title: "Success!",
                            text: response.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        
                    }else{
                        Swal.fire({
                            title: "Error!",
                            text: response.data.msg,
                            icon: 'error',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
        
                // Swal.fire({
                //     title: "Are you sure?",
                //     text: "Are you sure you want to revoke the travel request?",
                //     icon: "warning",
                //     showCancelButton: true,
                //     cancelButtonText:'No, go back',
                //     confirmButtonColor: "#3085d6",
                //     cancelButtonColor: "#d33",
                //     confirmButtonText: "Yes, proceed!",
                //     }).then(async(result) => {
                //    if (result.isConfirmed) {
                //     //this.isLoading = true;
                //     axios({
                //             'method': 'POST',
                //             'url': "api/reimbursement/cancellationtravelticket",
                //             'data':payload,
                //             headers: { authorization: this.tokendata },
                //     }).then((response) => {
                //         this.isLoading = false;
                //         if(response.data.status){
                //             this.getTicketDetails(payload.ticketid);
                //             Swal.fire({
                //                 title: "Success!",
                //                 text: response.data.msg,
                //                 icon: 'success',
                //                 customClass: {
                //                     confirmButton: "btn btn-primary"
                //                 },
                //                 buttonsStyling: !1
                //             })
                            
                //         }else{
                //             Swal.fire({
                //                 title: "Error!",
                //                 text: response.data.msg,
                //                 icon: 'error',
                //                 customClass: {
                //                     confirmButton: "btn btn-primary"
                //                 },
                //                 buttonsStyling: !1
                //             })
                //         }
                //     })
                //    }
                // });
            },
            toggleDetails(row) {
                if(row._showDetails){
                    this.$set(row, '_showDetails', false)
                }else{
                    this.currentItems.forEach(item => {
                    this.$set(item, '_showDetails', false)
                    })
    
                    this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                    })
                }
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            currentrow(key) {
                this.crow = key
            },
            openEditModule(currRowData){
                this.editRowData = currRowData
                this.search.requestid = currRowData.requestid
                this.search.status = currRowData.status
                this.isAddState=true
                this.iseditstate = true
            },
             clickCallback: function(pageNum) {
                this.pageoffset=pageNum
                this.currPageNum = pageNum - 1
                this.getmasterlist()
            },
            resetRecords: function () {
                this.search.requestid = null;
                this.search.status = null;
                localStorage.removeItem('searchItems')
                this.getmasterlist();
                this.validateForm()
            },
            getDraftTicketDetails(data) {
                this.isdraft = true
                this.isRequestActive = true;
                this.isDetActive = false
                this.apiURL="api/reimbursement/getDraftDetails"
                this.input={
                    ticketid:data.ticketid
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status && result.data.data.length>0) {
                        let response = result.data.data;
                        this.ticketdetails = response
                        this.request.visit=response[0].visitname
                        this.request.location=response[0].visitlocation
                        this.request.purpose=response[0].purpose
                        this.request.startdate=response[0].startdate
                        this.request.enddate=response[0].enddate
                        this.getprofiledata()
                        for(const ticketdata of response) {
                            if(ticketdata.node==1) {
                                this.request.fromcity = ticketdata.fromcity
                                this.request.tocity = ticketdata.tocity
                                this.request.transport= ticketdata.modeoftransport
                                this.request.dateoftravel= ticketdata.traveldate
                                this.request.appintmentdate= ticketdata.appointmentdate
                                if(ticketdata.accomodation == 1) {
                                    this.request.checkin= ticketdata.checkindate
                                    this.request.checkout= ticketdata.checkoutdate
                                    this.request.totalnights= ticketdata.noofnight
                                    this.isActiveEndDate = true
                                }
                            } else if(ticketdata.node==2) {
                                this.request.fromcity2 = ticketdata.fromcity
                                this.request.tocity2 = ticketdata.tocity
                                this.request.transport2= ticketdata.modeoftransport
                                this.request.dateoftravel2= ticketdata.traveldate
                                this.request.appintmentdate2= ticketdata.appointmentdate
                                
                                if(ticketdata.accomodation == 1) {
                                    this.request.checkin2= ticketdata.checkindate
                                    this.request.checkout2= ticketdata.checkoutdate
                                    this.request.totalnights2= ticketdata.noofnight
                                    this.isActiveEndDate2 = false
                                }
                            } else if(ticketdata.node==3) {
                                this.request.fromcity3 = ticketdata.fromcity
                                this.request.tocity3 = ticketdata.tocity
                                this.request.transport3= ticketdata.modeoftransport
                                this.request.dateoftravel3= ticketdata.traveldate
                                this.request.appintmentdate3= ticketdata.appointmentdate
                            }
                        }                    
                    }
                })
            },
            nameWithcity ({ name, language }) {
                return `${name} — [${language}]`
            },
            getempid(isgroup,id) {    
                return new Promise((resolve,reject)=>{        
                    if(isgroup ==1) {
                        this.apiURL="api/listing/getemployeedetails"
                        this.input={
                            empid:id,
                            empcode: this.empid,
                            useremail: this.username
                        }
                        axios({
                            'method': 'POST',
                            'url': this.apiURL,
                            'data':this.input,
                            headers: { authorization: this.tokendata },
                        })
                        .then(result => {   
                            if(result.data.status && result.data.data.length >0){
                                resolve(result.data.data)
                            } else {
                                resolve([])
                            }
                        })
                    } else {
                        resolve([])
                    }      
                })      
            },
            updateFeedbackRequestDetails() {
                this.valid = this.validateFeedbackForm(this.fbrequest.nodestatus)
                if(this.valid){
                    Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to feedback the travel request?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then(async(result) => {
                   if (result.isConfirmed) {
                    this.isLoading = true;
                    this.payload= {
                        level:parseInt(this.fbrequest.level),
                        id:parseInt(this.fbrequest.id),
                        createdby:this.username,
                        node:parseInt(this.fbrequest.nodestatus),
                        reportorname: this.fbrequest.level == 1? this.l1:this.l2,
                        reportormail: this.fbrequest.level == 1? this.l1mail:this.l2mail,
                        empcode: this.empid,
                        useremail: this.username
                    }          
                    if(this.fbrequest.nodestatus ==1) {
                        this.payload.fromcity = parseInt(this.fbrequest.fromcity)
                        this.payload.tocity = parseInt(this.fbrequest.tocity)
                        this.payload.dateoftravel=this.fbrequest.istravel == 1?this.fbrequest.dateoftravel:''
                        this.payload.appintmentdate=this.fbrequest.istravel == 1?this.fbrequest.appintmentdate:''
                        this.payload.accomadation=parseInt(this.fbrequest.accomadation)
                        this.payload.checkindate= this.fbrequest.accomadation ==1 ? this.fbrequest.checkin:''
                        this.payload.checkoutdate=this.fbrequest.accomadation ==1 ?this.fbrequest.checkout:''
                        this.payload.noofnight=this.fbrequest.accomadation ==1 ? parseInt(this.fbrequest.totalnights) || 0:0
                        this.payload.istravel= parseInt(this.fbrequest.istravel)
                        this.payload.grouptravel= parseInt(this.fbrequest.grouptravel)
                        this.payload.groupempname = this.fbrequest.grouptravel ==1? this.fbrequest.groupempname.join(',') :''
                    } else if(this.fbrequest.nodestatus==2) {
                        this.payload.fromcity = parseInt(this.fbrequest.fromcity3)
                        this.payload.tocity = parseInt(this.fbrequest.tocity3)
                        this.payload.dateoftravel=this.fbrequest.dateoftravel3
                        this.payload.appintmentdate=this.fbrequest.appintmentdate3
                        this.payload.grouptravel=this.fbrequest.grouptravel3
                        this.payload.groupempname=this.fbrequest.grouptravel3 ==1?this.fbrequest.groupempname3.join(','):''
                    }
                    if(this.payload && Object.keys(this.payload).length > 0) {
                        axios({
                            'method': 'POST',
                            'url': this.apiURL,
                            'data':this.input,
                            headers: { authorization: this.tokendata },
                        })
                        const response = await axios.post('api/reimbursement/feedbackRequestUpdate',this.payload,{headers: { authorization: this.tokendata }});
                        if(response){
                            this.isLoading = false;
                            if(response.data.status){
                                this.getmasterlist(0,'')
                                this.getTicketDetails(this.fbrequest.ticketid);
                                this.$forceUpdate()
                                Swal.fire({
                                    title: "Success!",
                                    text: response.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                                $("#EditFeedbackDetails").modal('hide');
                            }else{
                                Swal.fire({
                                    title: "Error!",
                                    text: response.data.msg,
                                    icon: 'error',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                        }
                    } else {
                        this.isLoading = false;
                        Swal.fire({
                            title: "Error!",
                            text: "Payload can not empty",
                            icon: 'error',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                   }
                });
                }
            },
            getGroupTravelList(groupemp) {
                this.apiURL="api/listing/getemployeedetails"
                this.input={
                    empid: groupemp,
                    empcode: this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {
                    this.groputravelinfo=[]        
                    if(result.data.status){
                        this.groputravelinfo = result.data.data;
                        $("#grouptravelist").modal('show')
                        
                    }else{
                        this.groputravelinfo=[]
                        Swal.fire({
                            title: "Info!",
                            text: "Data Not Found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            },
            feedbackRequest(data,level) {  
                $("#exampleModalScrollable").modal('hide');
                $("#EditFeedbackDetails").modal('show');
                this.fbrequest.ticketid =''
                this.getemplist(0,data.nameofgrouptravel,1)
                this.freturnbooking = data.returnbooking ==2 ? false : true;
                this.fisActiveEndDate = data.returnbooking == 1? false :true; 
                this.fbrequest.level = level
                this.fbrequest.node = data.node
                this.fbrequest.nodestatus = parseInt(data.returnbooking) 
                this.fbrequest.ticketid = data.ticketid
                this.fbrequest.id = data.id
                this.fbrequest.accomadation = data.accomodation
                this.fisActiveEndDateGroupTravel = false
                this.fisActiveEndDateTravel = false
                this.fisActiveaccomodation = false
                this.risActiveEndDateGroupTravel = false
                if(data.fromcity==data.tocity) {
                    this.getcitymasterList(0,`${data.fromcity}`,1)
                } else {
                    this.getcitymasterList(0,`${data.tocity},${data.fromcity}`,1)
                }
                
                if(data.returnbooking ==1) {                
                    this.fbrequest.fromcity= [data.fromcity]
                    this.fbrequest.tocity= [data.tocity]
                    if(data.istravelrequired ==1) {
                        this.fbrequest.transport= data.modeoftransport
                        this.fbrequest.dateoftravel= data.traveldate
                        this.fbrequest.appintmentdate= data.appointmentdate
                        this.fbrequest.istravel =1
                    } else {
                        this.fbrequest.istravel =0
                        this.fisActiveEndDateTravel = true
                    }
                    this.fbrequest.groupempname = null
                    if(data.isgrouptravelreq ==1) {
                        this.fbrequest.grouptravel = 1
                        if(data.nameofgrouptravel.length>0) {
                            this.fbrequest.groupempname = data.nameofgrouptravel.split(",")
                        }
                    } else {
                        this.fbrequest.grouptravel = 0
                        this.fisActiveEndDateGroupTravel = true
                    }
                    if(data.accomodation ==1) {
                        this.fbrequest.checkin= data.checkindatetime
                        this.fbrequest.checkout= data.checkoutdatetime
                        this.fbrequest.totalnights= data.noofnight || 0
                    } else {
                        this.fisActiveaccomodation = true
                    }
                } else if(data.returnbooking ==2) {
                    this.fbrequest.fromcity3=[data.fromcity]
                    this.fbrequest.tocity3=[data.tocity]
                    this.fbrequest.transport3=data.modeoftransport
                    this.fbrequest.dateoftravel3=data.traveldate
                    this.fbrequest.appintmentdate3=data.appointmentdate
                    this.fbrequest.groupempname3 = null
                    if(data.isgrouptravelreq ==1) {
                        this.fbrequest.grouptravel3 = 1
                        if(data.nameofgrouptravel.length>0) {
                            this.fbrequest.groupempname3 = data.nameofgrouptravel.split(",")
                        }
                    } else {
                        this.fbrequest.grouptravel3 = 0
                        this.risActiveEndDateGroupTravel = true
                    }
                }
            },
            fnotBeforeToday : function(date) {
                this.fbrequest.enddate = '';
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            notBeforeTodayTravel: function(date) {
                return date < new Date(new Date(this.request.startdate).setHours(0, 0, 0, 0));
            },
            notBeforeTodaynode2: function(date) {
                return date < new Date(new Date(this.request.startdate).setHours(0, 0, 0, 0));
            },
            notBeforeToday: function(date) {
                this.request.enddate = '';
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            fbnotBeforeTodayReturnBook: function(date) {
                this.fbrequest.appintmentdate3 = '';
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            notBeforeTodayReturnBook: function(date) {
                this.request.appintmentdate3 = '';
                return date < new Date(new Date(this.request.startdate).setHours(0, 0, 0, 0));
            },
            fbnotBeforeTodayCheckIn: function(date) {
                this.fbrequest.checkout = '';
                this.fbrequest.totalnights =0;
                if(this.fbrequest.checkin!='' && this.fbrequest.checkout!='') {
                    let checkin = moment(this.fbrequest.checkin).format("YYYY-MM-DD");
                    let checkout = moment(this.fbrequest.checkout).format("YYYY-MM-DD");
                    if(moment(checkin).isSame(checkout))
                        this.fbrequest.totalnights =1;
                    else 
                        this.fbrequest.totalnights = moment(checkout).diff(moment(checkin), 'days') // 1                
                }
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            notBeforeTodayCheckIn: function(date,data,info) {
                this.travels[this.crow].checkout = '';
                this.travels[this.crow].totalnights = 0
                if(this.travels[this.crow].checkin!='' && this.travels[this.crow].checkout!='') {
                    let checkin = moment(this.travels[this.crow].checkin).format("YYYY-MM-DD");
                    let checkout = moment(this.travels[this.crow].checkout).format("YYYY-MM-DD");
                    if(moment(checkin).isSame(checkout))
                        this.travels[this.crow].totalnights =1;
                    else 
                        this.travels[this.crow].totalnights = moment(checkout).diff(moment(checkin), 'days') // 1                
                }
                return date < new Date(new Date(this.request.startdate).setHours(0, 0, 0, 0));
            },
            fbnotBeforeStartDate: function(date) {
                return date <  new Date(new Date(this.fbrequest.startdate).setHours(0, 0, 0, 0));            
            },
            notBeforeStartDate: function(date) {
                return date <  new Date(new Date(this.request.dateoftravel).setHours(0, 0, 0, 0));            
            },
            notBeforeStartDateToday: function(date) {
                return date <  new Date(new Date(this.request.startdate).setHours(0, 0, 0, 0));            
            },
            fbnotBeforeStartDateReturn: function(date) {
                return date <  new Date(new Date(this.fbrequest.dateoftravel3).setHours(0, 0, 0, 0));
            },
            notBeforeStartDateReturn: function(date) {
                return date <  new Date(new Date(this.request.dateoftravel3).setHours(0, 0, 0, 0));
            },
            fbnotBeforeTodaytime: function(date) {
                this.fbrequest.totalnights =0;
                if(this.fbrequest.checkin!='' && this.fbrequest.checkout!='') {
                    let checkin = moment(this.fbrequest.checkin).format("YYYY-MM-DD");
                    let checkout = moment(this.fbrequest.checkout).format("YYYY-MM-DD");
                    if(moment(checkin).isSame(checkout))
                        this.fbrequest.totalnights =1;
                    else 
                        this.fbrequest.totalnights = moment(checkout).diff(moment(checkin), 'days') // 1                
                    
                }
                return date <  new Date(new Date(this.fbrequest.checkin).setHours(0, 0, 0, 0));
            },        
            notBeforeTodaytime: function(date) {
                this.travels[this.crow].totalnights =0;
                if(this.travels[this.crow].checkin!='' && this.travels[this.crow].checkout!='') {
                    let checkin = moment(this.travels[this.crow].checkin).format("YYYY-MM-DD");
                    let checkout = moment(this.travels[this.crow].checkout).format("YYYY-MM-DD");
                    if(moment(checkin).isSame(checkout))
                        this.travels[this.crow].totalnights =1;
                    else 
                        this.travels[this.crow].totalnights = moment(checkout).diff(moment(checkin), 'days') // 1                
                }
                return date <  new Date(new Date(this.travels[this.crow].checkin).setHours(0, 0, 0, 0));
            },
            fbnotBeforeTodayTwelveOClock(date) {
                this.fbrequest.totalnights =0;
                if(this.fbrequest.checkin!='' && this.fbrequest.checkout!='') {
                    let checkin = moment(this.fbrequest.checkin).format("YYYY-MM-DD");
                    let checkout = moment(this.fbrequest.checkout).format("YYYY-MM-DD");
                    if(moment(checkin).isSame(checkout))
                        this.fbrequest.totalnights =1;
                    else 
                        this.fbrequest.totalnights = moment(checkout).diff(moment(checkin), 'days') // 1                
                }
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            notBeforeTodayTwelveOClock(date) {
                if(this.travels[this.crow].checkin!='' && this.travels[this.crow].checkout!='') {
                    let checkin = moment(this.travels[this.crow].checkin).format("YYYY-MM-DD");
                    let checkout = moment(this.travels[this.crow].checkout).format("YYYY-MM-DD");
                    if(moment(checkin).isSame(checkout))
                        this.travels[this.crow].totalnights =1;
                    else 
                        this.travels[this.crow].totalnights = moment(checkout).diff(moment(checkin), 'days') // 1                
                }
                return date < new Date(new Date().setHours(0, 0, 0, 0));
            },
            selected: function(selectValue){
               
            },
            firstClick(){
                this.FirstActive=!this.FirstActive
                this.firstBlock=!this.firstBlock
                this.secondActive =!this.secondActive
                this.secondBlock =!this.secondBlock
                this.firstStepActive=!this.firstStepActive
                this.secondStepActive = !this.secondStepActive
            },
            dateFormat(date) {
                if (!date) return '';
                return moment(date).format(this.TimeFormat);
            },
            doAjax() {
                  this.isLoading = true;
                  // simulate AJAX
                  setTimeout(() => {
                    this.isLoading = false
                  },5000)
              },
            onCancel() {
                console.log('User cancelled the loader.')
            },
            selcitydata3(state,value){
                this.request.fromcity3=null
                this.request.fromcity3=state
                   if(!state && this.isAddState === false){
                this.errors.fromcity3='From city is required'
            }else{                
                this.errors.fromcity3=null
            }
            },
            seltocitydata3(state,value){
                this.request.tocity3=null
                this.request.tocity3=state
                   if(!state && this.isAddState === false){
                this.errors.tocity3='To city is required'
            }else{                
                this.errors.tocity3=null
            }
            },
            seltransportdata3(state,value){
                this.request.transport3=null
                this.request.transport3=state
                  if(!state && this.isAddState === false){
                this.errors.transport3='To city is required'
            }else{                
                this.errors.transport3=null
            }
            },
            selcitydata2(state,value){
                this.request.fromcity2=null
                this.request.fromcity2=state
                 if(!state && this.isAddState === false){
                this.errors.fromcity2='From city is required'
            }else{                
                this.errors.fromcity2=null
            }
            },
            seltocitydata2(state,value){
                this.request.tocity2=null
                this.request.tocity2=state
                 if(!state && this.isAddState === false){
                this.errors.tocity2='To city is required'
            }else{                
                this.errors.tocity2=null
            }
            },
            seltransportdata2(state,value){
                this.request.transport2=null
                this.request.transport2=state
                 if(!state && this.isAddState === false){
                this.errors.transport2='Mode of transport is required'
            }else{                
                this.errors.transport2=null
            }
            },
            selcitydata(state,value){
                this.request.fromcity=null
                this.request.fromcity=state
                 if(!state && this.isAddState === false){
                this.errors.fromcity='From city is required'
            }else{                
                this.errors.fromcity=null
            }
            },
            seltocitydata(state,value){
                this.request.tocity=null
                this.request.tocity=state
                 if(!state && this.isAddState === false){
                this.errors.tocity='To city is required'
            }else{                
                this.errors.tocity=null
            }
            },
            seltransportdata(state,value){
                this.request.transport=null
                this.request.transport=state
                if(!state && this.isAddState === false){
                this.errors.transport='Mode of transport is required'
            }else{                
                this.errors.transport=null
            }
            },
             seldateoftravel(state,value){
                this.request.dateoftravel=null
                this.request.dateoftravel=state
                 if(!state && this.isAddState === false){
                this.errors.dateoftravel='Prefered date of travel is required'
            }else{                
                this.errors.dateoftravel=null
            }
            },
            seldateoftravel2(state,value){
                this.request.dateoftravel2=null
                this.request.dateoftravel2=state
                 if(!state && this.isAddState === false){
                this.errors.dateoftravel2='Prefered date of travel is required'
            }else{                
                this.errors.dateoftravel2=null
            }
            },
            seldateoftravel3(state,value){
                this.request.dateoftravel3=null
                this.request.dateoftravel3=state
                 if(!state && this.isAddState === false){
                this.errors.dateoftravel3='Prefered date of travel is required'
            }else{                
                this.errors.dateoftravel3=null
            }
            },
             selappintmentdate(state,value){
                this.request.appintmentdate=null
                this.request.appintmentdate=state
                 if(!state && this.isAddState === false){
                this.errors.appintmentdate='Appointment date / Reporting date is required'
            }else{                
                this.errors.appintmentdate=null
            }
            },
             selappintmentdate2(state,value){
                this.request.appintmentdate2=null
                this.request.appintmentdate2=state
                 if(!state && this.isAddState === false){
                this.errors.appintmentdate2='Appointment date / Reporting date is required'
            }else{                
                this.errors.appintmentdate2=null
            }
            },
              selappintmentdate3(state,value){
                this.request.appintmentdate3=null
                this.request.appintmentdate3=state
                 if(!state && this.isAddState === false){
                this.errors.appintmentdate3='Appointment date / Reporting date is required'
            }else{                
                this.errors.appintmentdate3=null
            }
            },
             selcheckin(state,value){
                this.request.checkin=null
                this.request.checkin=state
                 if(!state && this.isAddState === false){
                this.errors.checkin='Checkin date and time is required'
            }else{                
                this.errors.checkin=null
            }
            },
              selcheckin2(state,value){
                this.request.checkin2=null
                this.request.checkin2=state
                 if(!state && this.isAddState === false){
                this.errors.checkin2='Checkin date and time is required'
            }else{                
                this.errors.checkin2=null
            }
            },
           
            selcheckout(state,value){
                this.request.checkout=null
                this.request.checkout=state
                 if(!state && this.isAddState === false){
                this.errors.checkout='Checkout date and time is required'
            }else{                
                this.errors.checkout=null
            }
            },
             selcheckout2(state,value){
                this.request.checkout2=null
                this.request.checkout2=state
                 if(!state && this.isAddState === false){
                this.errors.checkout2='Checkout date and time is required'
            }else{                
                this.errors.checkout2=null
            }
            },
            
            testcall(state,emplvalue){
                
            },
            onRowSelected(item,index) {
                this.rowindex = index
                this.getTicketDetails(item.ticketid)
            },
            getTicketDetails(ticketid){
                let userdata = window.localStorage.getItem('userdata');
                if(userdata){
                    this.apiURL="api/reimbursement/getAllNodesTicketDetails"
                    this.ticketdetails = [];
                    this.input={
                        ticketid:ticketid,
                        managerid:0,
                        empcode: this.empid,
                        useremail: this.username
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {             
                        if(result.data.status){
                            let response = result.data.data;
                            this.ticketdetails = response
                            this.visitname=response[0].visitname
                            this.visitlocation=response[0].visitlocation
                            this.purpose=response[0].purpose
                            this.startdate=response[0].startdate
                            this.enddate=response[0].enddate
                            this.getprofiledata()
                        }
                    })
                }
            },
            getprofiledata(){
                let userdata = window.localStorage.getItem('userdata');
                if(userdata){
                    userdata = JSON.parse(userdata)
                    this.empid = userdata.userid
                    this.apiURL="api/profile/getProfileDetails"
                    this.input={
                        empcode:this.empid,
                        useremail: this.username
                    }
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data':this.input,
                        headers: { authorization: this.tokendata },
                    })
                    .then(result => {           
                        if(result.data.status){
                            let response = result.data.data;
                            if(response.profilepic){
                                this.profileurl=response.profilepic
                            }
                            this.joiningdate=response.joiningdate
                            this.employeeid = response.empid
                            this.birthday = response.birthday
                            this.reportingto = response.reportto
                            this.year = response.year?(response.year+' yr ') : ''
                            this.month = response.month?response.month+' mos':''
                            this.totalexp =this.year+ ''+this.month
                            this.empcode = response.empcode
                            this.dept = response.designation+' ['+response.department+']'
                            this.email = response.emailid
                            this.address = response.city+','+response.state+','+response.country
                            this.mobilenumber = response.mobileno
                            this.firstname=response.firstname
                            this.lastname=response.lastname
                            this.request.deptid = response.deptid
                            this.request.designationid = response.designationid
                            this.request.managerid = response.managerid
                            this.l1 = response.l1
                            this.l2 = response.l2
                            this.request.empid=response.empid
                            this.request.empname = (this.firstname +' '+ this.lastname).toUpperCase()
                            this.request.designation = (response.designation).toUpperCase()
                            this.request.dept = (response.department).toUpperCase()
                            this.l1mail = response.l1mail
                            this.l2mail = response.l2mail
                            this.hodid = response.hodid
                            this.request.l2managerid = response.l2managerid
                            this.getmodeoftransport()
                        }
                    })
                }
            },
            getallempdetails() {
                this.apiURL="api/listing/getemployeedetails"
                this.input={
                    deptid:null,
                    empcode: this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        this.empdetails = result.data.data;
                        $("#exampleModalScrollable").modal('show');                    
                    }else{
                        this.empdetails=[]
                        Swal.fire({
                            title: "Info!",
                            text: "Data Not Found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            },
            getemplist(id,name,searchid){
                this.apiURL="api/listing/getallemployeelist"
                this.input={
                    companyid: this.companyid,
                    empname:name,
                    searchid:searchid,
                    empcode:this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {    
                    if(result.data.status){
                        this.emplist = result.data.data;
                    }else{
                        this.emplist=[]
                    }
                })
            },
            getmodeoftransport(){
                this.usereligibletransport=[]
                this.apiURL="api/listing/getmodeoftransport"
                this.input={
                    deptid:this.request.deptid,
                    designationid:this.request.designationid,
                    empcode:this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {    
                    if(result.data.status){                    
                        this.usereligibletransport= result.data.data.map(f => {return f.label.toLowerCase()});
                    }
                })
            },
            selissuecsprojectSearch: function(state,value){
                this.search.searchprojectname=null
                this.search.searchprojectname=state
                
            },
            citynameChangeSearch(node, instanceId) {
                if(node && node.length>=3){
                    this.getcitymasterList(0,node,0)
                }
            },
            empnameChangeSearch(node, instanceId) {
                if(node && node.length>=3){
                    this.getemplist(0,node,0)
                }
            },
            getcitymasterList(id,cityname,searchid){
                this.apiURL="api/listing/getCityMasterList"
                this.input={
                    cityid:id,
                    cityname:cityname.toLowerCase(),
                    searchid:searchid,
                    empcode:this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {          
                    if(result.data.status){
                        this.citymasterList = result.data.data;
                    } else {
                        this.citymasterList = [];
                    }
                })
            },
            displaymgr(){
                this.showmanager = !this.showmanager
            },
            showreturnbooking(flag){
                if(flag==1){
                    this.returnbooking= false
                }else{
                    this.returnbooking= true
                }
            },
            showmorevisit(){            
                if(this.travels.length<6) {
                    this.travels.push({fromcity:null,tocity:null,transport:null,dateoftravel:'',appintmentdate:'',checkin:'',checkout:'',totalnights:0,accomadation:1,istravel:1,grouptravel:0,groupempname:null})
                    this.terrors.push({fromcity:'',tocity:'',transport:'',dateoftravel:'',appintmentdate:'',checkin:'',checkout:'',groupempname:''})
                    let rowlength = this.travels.length-1
                    this.travels[rowlength].accomadation = 1;
                    this.travels[rowlength].istravel = 1;
                    this.travels[rowlength].grouptravel = 0; 
                    this.isActiveEndDate.push({status:false});
                    this.isActiveEndDateTravel.push({status:false});
                    this.isActiveEndDateGroupTravel.push({status:true});
                } else {
                    Swal.fire({
                        title: "Info!",
                        text: "You Have Selected Maximum number of nodes, You cannot select more.",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            },
            deleteRow(index){  
                if(this.travels.length == 1) {
                    Swal.fire({
                        title: "Info!",
                        text: "At least one node is required.",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }  else {
                    this.travels.splice(index,1); 
                    this.terrors.splice(index,1);  
                    this.validateForm(2)   
                }            
            },  
            showdate(flag,key){
                if(flag==1){
                    this.isActiveEndDate[key].status= false
                    this.travels[key].accomadation = 1
                }else{
                    this.isActiveEndDate[key].status= true
                    this.travels[key].accomadation = 0
                    if(this.travels[key].istravel==0 && this.travels[key].accomadation== 0) {
                        this.isActiveEndDateTravel[key].status= false
                        this.travels[key].istravel = 1
                    }
                }            
            },
            showgrouptravel(flag,key){
                if(flag==1){
                    this.isActiveEndDateGroupTravel[key].status= false
                    this.travels[key].grouptravel = 1
                }else{
                    this.isActiveEndDateGroupTravel[key].status= true
                    this.travels[key].grouptravel = 0
                }            
            },
            showtravel(flag,key){
                if(flag==1){
                    this.isActiveEndDateTravel[key].status= false
                    this.travels[key].istravel = 1
                }else{
                    this.isActiveEndDateTravel[key].status= true
                    this.travels[key].istravel = 0
                    if(this.travels[key].istravel==0 && this.travels[key].accomadation== 0) {
                        this.isActiveEndDate[key].status= false
                        this.travels[key].accomadation = 1
                    }
                }            
            },
            fshowdate(flag){
                if(flag==1){
                    this.fisActiveaccomodation= false
                }else{
                    this.fisActiveaccomodation= true
                    this.fbrequest.accomadation =0
                    if(this.fbrequest.istravel == 0 && this.fbrequest.accomadation == 0) {
                        this.fisActiveEndDateTravel= false
                        this.fbrequest.istravel =1
                    }
                }            
            },
            fshowtravel(flag){
                if(flag==1){
                    this.fisActiveEndDateTravel= false
                }else{
                    this.fisActiveEndDateTravel= true
                    this.fbrequest.istravel =0
                    
                    if(this.fbrequest.istravel == 0 && this.fbrequest.accomadation == 0) {
                        this.fisActiveaccomodation= false
                        this.fbrequest.accomadation =1
                    }
                    
                }            
            },
            fshowgrouptravel(flag){
                if(flag==1){
                    this.fisActiveEndDateGroupTravel= false
                }else{
                    this.fisActiveEndDateGroupTravel= true                
                }            
            },
            rshowgrouptravel(flag){
                if(flag==1){
                    this.risActiveEndDateGroupTravel= false
                }else{
                    this.risActiveEndDateGroupTravel= true                
                }            
            },
            showdate2(flag){
                if(flag==1){
                    this.isActiveEndDate2= false
                }else{
                    this.isActiveEndDate2= true
                }
                
            },
            validText : function(inputval) {
                let re = /[A-Za-z0-9].{5,}/;
                if(inputval){
                    return re.test(inputval.toLowerCase());
                }
            },
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
            myrequestollapse:function(){
                this.isRequestActive=!this.isRequestActive
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },
            validateForm(step){
                let isValid=true;
                if(step==1){
                    if(!this.request.visit){
                        this.errors.visit="Visit name is required";
                        isValid= false;
                    }
                    if(!this.request.location){
                        this.errors.location="Location of visit is required";
                        isValid= false;
                    }
                    if(!this.request.purpose){
                        this.errors.purpose="Purpose of visit is required";
                        isValid= false;
                    }
                    if(!this.request.startdate){
                        this.errors.startdate="Start date is required";
                        isValid= false;
                    }
                    if(!this.request.enddate){
                        this.errors.enddate="End date is required";
                        isValid= false;
                    }
                    if(this.request.enddate && this.request.startdate && this.request.purpose && this.request.location && this.request.visit){
                        this.thirdActive=!this.thirdActive
                        this.thirdBlock=!this.thirdBlock
                        this.secondActive =!this.secondActive
                        this.secondBlock =!this.secondBlock
                        this.secondStepActive = !this.secondStepActive
                        this.thirdStepActive = !this.thirdStepActive
                    }
                }else if (step==2){
                    if(!this.request.visit){
                        this.errors.visit="Visit name is required";
                        isValid= false;
                    }
                    if(!this.request.location){
                        this.errors.location="Location of visit is required";
                        isValid= false;
                    }
                    if(!this.request.purpose){
                        this.errors.purpose="Purpose of visit is required";
                        isValid= false;
                    }
                    if(!this.request.startdate){
                        this.errors.startdate="Start date is required";
                        isValid= false;
                    }
                    if(!this.request.enddate){
                        this.errors.enddate="End date is required";
                        isValid= false;
                    }
                    for (let index = 0; index < this.travels.length; index++) {
                        if(this.travels[index].fromcity){
                           this.terrors[index].fromcity =''
                        } else {                        
                            this.terrors[index].fromcity= "Node "+(index+1)+" from city is required";
                            isValid= false;
                        }
                        if(this.travels[index].tocity) {
                            this.terrors[index].tocity= ''
                        } else {
                            this.terrors[index].tocity="Node "+(index+1)+" to city is required";
                            isValid= false;
                        } 
                        if(this.travels[index].istravel==1) {
                            if(this.travels[index].transport){
                                this.terrors[index].transport= ''
                            } else {
                                this.terrors[index].transport="Node "+(index+1)+" mode of transport is required";
                                isValid= false;
                            }
                            if(this.travels[index].appintmentdate){
                                this.terrors[index].appintmentdate= ''
                            } else {
                                this.terrors[index].appintmentdate="Node "+(index+1)+" appointment date / Reporting date is required";
                                isValid= false;
                            }
                            if(this.travels[index].dateoftravel){
                                this.terrors[index].dateoftravel= ''
                            } else {
                                this.terrors[index].dateoftravel="Node "+(index+1)+" prefered date of travel is required";
                                isValid= false;
                            }
                        }
                        if(this.travels[index].grouptravel==1){
                            if(this.travels[index].groupempname !=null){
                                if(this.travels[index].groupempname.length >0) {
                                    this.terrors[index].groupempname= ''
                                } else {
                                    this.terrors[index].groupempname="Node "+(index+1)+" group travel employee name is required";
                                    isValid= false;    
                                }                            
                            } else {
                                this.terrors[index].groupempname="Node "+(index+1)+" group travel employee name is required";
                                isValid= false;
                            }
                        }
                        if(this.travels[index].accomadation==1){
                            if(this.travels[index].checkin){
                                this.terrors[index].checkin= ''
                            } else {
                                this.terrors[index].checkin="Node "+(index+1)+" checkin date and time is required";
                                isValid= false;
                            }
                            if(this.travels[index].checkout){
                                this.terrors[index].checkout= ''
                            } else {
                                this.terrors[index].checkout="Node "+(index+1)+" checkout date and time is required";
                                isValid= false;
                            }
                        }                    
                    }
                    if(!this.returnbooking){
                        if(!this.request.fromcity3){
                            this.errors.fromcity3="From city is required";
                            isValid= false;
                        }
                        if(!this.request.tocity3){
                            this.errors.tocity3="To city is required";
                            isValid= false;
                        }
                        if(!this.request.transport3){
                            this.errors.transport3="Mode of transport is required";
                            isValid= false;
                        }
                        if(!this.request.appintmentdate3){
                            this.errors.appintmentdate3="Appointment date / Reporting date is required";
                            isValid= false;
                        }
                        if(!this.request.dateoftravel3){
                            this.errors.dateoftravel3="Prefered date of travel is required";
                            isValid= false;
                        }
                         if(this.request.grouptravel3==1){
                            if(this.request.groupempname3 !=null){
                                if(this.request.groupempname3.length>0)
                                    this.errors.groupempname3= ''
                                else {
                                    this.errors.groupempname3="Group travel emp. name is required";
                                    isValid= false;    
                                }
                            } else {
                                this.errors.groupempname3="Group travel emp. name is required";
                                isValid= false;
                            }
                        }
                    }
                    
                }
                
                return isValid
            },
            validateFeedbackForm(step){
                let isValid=true;
                if (step==1){
                    if(!this.fbrequest.fromcity){
                        this.fberrors.fromcity="From city is required";
                        isValid= false;
                    }
                    if(!this.fbrequest.tocity){
                        this.fberrors.tocity="To city is required";
                        isValid= false;
                    }
                    if(this.fbrequest.istravel ==1){
                        if(!this.fbrequest.transport){
                            this.fberrors.transport="Mode of transport is required";
                            isValid= false;
                        }
                        if(!this.fbrequest.appintmentdate){
                            this.fberrors.appintmentdate="Appointment date / Reporting date is required";
                            isValid= false;
                        }
                        if(!this.fbrequest.dateoftravel){
                            this.fberrors.dateoftravel="Prefered date of travel is required";
                            isValid= false;
                        }
                    }
                    if(this.fbrequest.grouptravel ==1){
                        if(!this.fbrequest.groupempname.length>0){
                            this.fberrors.groupempname="Group travel employee name is required";
                            isValid= false;
                        }
                    }
                    if(this.fbrequest.accomadation==1){
                        if(!this.fbrequest.checkin){
                            this.fberrors.checkin="Checkin date  and time is required";
                            isValid= false;
                        }
                        if(!this.fbrequest.checkout){
                            this.fberrors.checkout="Checkout date and time  is required";
                            isValid= false;
                        }
                    }
                } else if(step ==2) {
                    if(!this.fbrequest.fromcity3){
                        this.fberrors.fromcity3="From city is required";
                        isValid= false;
                    }
                    if(!this.fbrequest.tocity3){
                        this.fberrors.tocity3="To city is required";
                        isValid= false;
                    }
                    if(!this.fbrequest.transport3){
                        this.fberrors.transport3="Mode of transport is required";
                        isValid= false;
                    }
                    if(!this.fbrequest.appintmentdate3){
                        this.fberrors.appintmentdate3="Appointment date / Reporting date is required";
                        isValid= false;
                    }
                    if(!this.fbrequest.dateoftravel3){
                        this.fberrors.dateoftravel3="Prefered date of travel is required";
                        isValid= false;
                    }
                    if(this.fbrequest.grouptravel3 ==1){
                        if(!this.fbrequest.groupempname3.length>0){
                            this.fberrors.groupempname3="Group travel employee6 name is required";
                            isValid= false;
                        }
                    }
                }
                return isValid
            },
            clearerrorForm(){
                for (let index = 0; index < this.travels.length; index++) {
                    this.travels[index].fromcity= null
                    this.travels[index].tocity= null
                    this.travels[index].transport=null
                    this.travels[index].dateoftravel=''
                    this.travels[index].appintmentdate=''
                    this.travels[index].checkin=''
                    this.travels[index].checkout=''
                    this.travels[index].totalnights=0
                    this.travels[index].accomadation=1
                    this.travels[index].istravel=1
                    this.travels[index].grouptravel=0
                    this.travels[index].groupempname=[]
                    this.terrors[index].fromcity=''
                    this.terrors[index].tocity=''
                    this.terrors[index].transport=''
                    this.terrors[index].dateoftravel=''
                    this.terrors[index].appintmentdate=''
                    this.terrors[index].checkin=''
                    this.terrors[index].checkout=''
                }
    
                this.errors={
                    visit:null,
                    location:null,
                    purpose:null,
                    startdate:null,
                    enddate:null,
                    fromcity:null,
                    tocity:null,
                    transport:null,
                    checkin:'',
                    checkout:'',
                    appintmentdate:'',
                    dateoftravel:'',
                    accomadation:1,
                    booking:0,
                }
                this.request={
                    visit:null,
                    location:null,
                    purpose:null,
                    startdate:null,
                    enddate:null,
                    fromcity:null,
                    tocity:null,
    
                    transport:null,
                    checkin:null,
                    checkout:null,
                    appintmentdate:null,
                    dateoftravel:null,
                    accomadation:1,
                    booking:0,
    
                    fromcity2:null,
                    tocity2:null,
                    transport2:null,
                    checkin2:null,
                    checkout2:null,
                    appintmentdate2:null,
                    dateoftravel2:null,
                    accomadation2:1,
    
                    fromcity3:null,
                    tocity3:null,
                    transport3:null,
                    appintmentdate3:null,
                    dateoftravel3:null,
                    grouptravel3:0,
                    groupempname3:null,
                    instruction3:null,
                    instruction:null
                }
            },
            async createRequestDetails(status) {  
                this.valid = this.validateForm(2)
                if(this.valid){
                    Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to log the travel request? It will submit data to your reporting manager and approval, Only after manager approval, travel desk team will assist you with booking and reimbursement.",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then(async(result) => {
                    if (result.isConfirmed) {
                        let doneedapprovhodflag = 0
                        this.isLoading = true;
                        this.reqdetails= [];
                        if(this.request.transport3) {
                            if(!this.usereligibletransport.includes(this.request.transport3.toLowerCase())) 
                            doneedapprovhodflag++
                        }                    
    
                        for (let index = 0; index < this.travels.length; index++) {
                            if(this.travels[index].istravel ==1 && this.travels[index].transport) {
                                if(this.travels[index].transport) {
                                    if(!this.usereligibletransport.includes(this.travels[index].transport.toLowerCase()))
                                    doneedapprovhodflag++                            
                                }                            
                            }
                            let transport =0;
                            if(
                                this.travels[index].istravel ==1 &&
                                this.travels[index].transport &&
                                !this.usereligibletransport.includes(this.travels[index].transport.toLowerCase())) {
                                transport =1;
                            }
    
                            this.reqdetails.push({
                            fromcity:this.travels[index].fromcity,
                            tocity:this.travels[index].tocity,
                            instruction:this.travels[index].instruction?this.travels[index].instruction:null,
                            modeoftransport:this.travels[index].istravel ==1? this.travels[index].transport:null,
                            traveldate:this.travels[index].istravel ==1?this.travels[index].dateoftravel:null,
                            appintmentdate:this.travels[index].istravel ==1?this.travels[index].appintmentdate:null,
                            accomadation:this.travels[index].accomadation,
                            istravel:this.travels[index].istravel,
                            grouptravel:this.travels[index].grouptravel,
                            groupempname:this.travels[index].grouptravel ==1? this.travels[index].groupempname.join(','):'',
                            checkindate:this.travels[index].accomadation ? this.travels[index].checkin :null,
                            checkoutdate:this.travels[index].accomadation ? this.travels[index].checkout: null,
                            noofnight:this.travels[index].accomadation ? this.travels[index].totalnights || 0: 0,
                            returnbooking: 1,
                            status:status,
                            node:index+1,
                            doneedapprovhod: transport, 
                            hodid: this.hodid || 0
                        })                    
                    }    
                    let transport3 = 0;
                    if(this.request.transport3 && !this.usereligibletransport.includes(this.request.transport3.toLowerCase())) {
                        transport3 = 1;
                    }
                    this.return={
                        fromcity:this.request.fromcity3,
                        tocity:this.request.tocity3,
                        modeoftransport:this.request.transport3,
                        instruction:this.request.instruction3, // added for return journey
                        traveldate:this.request.dateoftravel3,
                        appintmentdate:this.request.appintmentdate3,
                        istravel:1,
                        grouptravel:this.request.grouptravel3,
                        groupempname:this.request.grouptravel3 ==1? this.request.groupempname3.join(','):'',
                        accomadation:null,
                        checkindate:null,
                        checkoutdate:null,
                        noofnight:0,
                        returnbooking:2,
                        status:status,
                        node:this.travels.length+1,
                        doneedapprovhod: transport3,
                        hodid: this.hodid || 0
                    }
                    
                    if(this.request.fromcity3 && this.request.tocity3 && this.request.transport3){
                        this.reqdetails.push(this.return)
                    }
                    
                    axios({
                        'method': 'POST',
                        'url': 'api/reimbursement/createtravelrequest',
                        'data':{
                            visitname:this.request.visit,
                            visitlocation:this.request.location,
                            purpose:this.request.purpose,
                            startdate:this.request.startdate,
                            enddate:this.request.enddate,
                            managerid:this.request.managerid,
                            l2managerid:this.request.l2managerid,
                            empcode:this.empid,
                            ticketdetails:this.reqdetails,
                            status:status,
                            createdby:this.username,
                            deptid:this.request.deptid,
                            reportingto:this.reportingto,
                            reportingname: this.l1,
                            createdname: this.request.empname,
                            doneedapprovhod: doneedapprovhodflag ==0 ?0:1,
                            useremail: this.username
                        },
                        headers: { authorization: this.tokendata },
                    }).then(response => {  
                        this.isLoading = false;
                       if(response.data.status){
                            this.clearerrorForm()
                            this.getmasterlist(0,'')
                            this.isDetActive=true
                            this.iseditstate=false
                            this.myrequestollapse()
                            Swal.fire({
                                title: 'success!',
                                text: response.data.msg,
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            })
                        }else{
                            Swal.fire({
                                title: "Error!",
                                text: response.data.msg,
                                icon: 'error',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    })
                    }
                });
                }
            },
            cleardata() {
                this.firstStepActive=true
                this.secondStepActive=false
                this.thirdStepActive=false
                this.FirstActive=true
                this.firstBlock=true
                this.secondActive=false
                this.secondBlock=false
                this.thirdActive=false
                this.thirdBlock=false
                this.isRequestActive=false
                this.addmorecity=false
                this.returnbooking=false
               
            },
            async createMasterRequest(){
                this.valid=false;
                this.valid = this.validateForm(1)
                if(this.valid){
                    const response = await  axios.post('api/reimbursement/createtravelrequest',{
                        ticketid:"SS20221234",
                        visitname:this.request.visit,
                        visitlocation:this.request.location,
                        purpose:this.request.purpose,
                        startdate:this.request.startdate,
                        enddate:this.request.startdate,
                        status:0,
                        empcode:this.empid,
                        createdby:this.username,
                        useremail: this.username
                    });
                    if(response){
                        if(response.data.status){
                            this.clearerrorForm()
                            Swal.fire({
                                title: "Success!",
                                text: response.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }else{
                            Swal.fire({
                                title: "Error!",
                                text: response.data.msg,
                                icon: 'error',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    }
                    
                }
            },
              selvisit(state,value){
                this.visit=null
                this.visit=state
              if(!state && this.isAddState === false){
                this.errors.visit='Visit name is required'
            }else{                
                this.errors.visit=null
            }
            },
            sellocation(state,value){
                this.location=null
                this.location=state
              if(!state && this.isAddState === false){
                this.errors.location='Location of visit is required'
            }else{                
                this.errors.location=null
            }
            },
            selpurpose(state,value){
                this.purpose=null
                this.purpose=state
              if(!state && this.isAddState === false){
                this.errors.purpose='Purpose of visit is required'
            }else{                
                this.errors.purpose=null
            }
            },
            selstartdate(state,value){
                this.startdate=null
                this.startdate=state
              if(!state && this.isAddState === false){
                this.errors.startdate='Start date is required'
            }else{                
                this.errors.startdate=null
            }
            },
            selenddate(state,value){
                this.enddate=null
                this.enddate=state
              if(!state && this.isAddState === false){
                this.errors.enddate='End date is required'
            }else{                
                this.errors.enddate=null
            }
            },
            getmasterlist(statusVal,ticketid){
                this.requestList=[]
                this.isLoading = true;
                let apiURL='api/reimbursement/gettravelrequest' 
                this.input={
                    offset:this.pageoffset,
                    limit: apiUrl.LIMIT,
                    ticketid:ticketid,
                    empcode:this.empid,
                    status:statusVal,
                    useremail: this.username
                }           
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {   
                    this.isLoading = false;          
                    if(result.data.status){
                        this.isDetActive=true;
                        this.items = result.data.data.responsedata;
                        this.totalRows = result.data.data.count
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }else{
                        this.items = [];
                        this.isLoading= false;
                        this.totalRows = 1;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                })
    
            }
        }
    }
    </script>
    <style scoped>
    .display{
        display: none;
    }
    
    .v-treeview-node__label {
      overflow: initial;
      text-overflow: initial;
    }
    
    .v-navigation-drawer__content {
      overflow-x: auto;
      min-height:150px;
      max-height:150px;
    }
    .readmore_texttrs .outer span{
      overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .readmore_texttrs .outer.readmore span{
          overflow: unset;
        display: -webkit-box;
        -webkit-line-clamp: unset;
        line-clamp: 2;
        -webkit-box-orient: unset;
    }
    .cycle_viewmoretext a{
      text-align: left;
    }
    .cycle_viewmoretext a strong{
            color: blue;
    }
    </style>