<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1 " >
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 new_dash_add_top00">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-12 content-header-left pl-0">
                <h3 class="content-header-title float-left mb-0">Course Module Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" >My Tutorials</li>
                  </ol>
                </div>
              </div>
              <div class="col-md-3 text-right pr-0" v-if="pagefeatures.create">
                <div class="input-group-append float-right">
                  <router-link to="/training/create"><span class="btn btn-sm btn-relief-success" ><plus-circle-icon size="1.5x"></plus-circle-icon> Create Training Module</span></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div id="scrollToListGrid"></div>
      <div class="row">
        <div class="col-12">
          <ul class="listGrid float-right d-inline-flex">
              <li class="cursorpointercls"  @click="myListView()" >
                <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                  <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                  <span>
                    List View
                  </span>
                </button>
              </li>
              <li class=" cursorpointercls" @click="myGridView()" >
                <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
                  <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
                  <span>
                    Grid View
                  </span>
                </button>
              </li>
            </ul>
        </div>
      </div>

      <div  v-bind:class="[blog_list_wrapper,{displayblk:isgridView}]" >
          <div class="dw mx-n1">
            <div class="dw-panel p-1"  v-for="(data,index) in training.courselist" v-bind:key="index">
              <div class="card border-warning card_style_box_shadow1 mb-0">
              <div class="card-header p-1">
                  <h4 class="card-title">{{(data.coursename).toLowerCase()}} : {{data.coursecode}} </h4>
              </div>
              <div class="card" style="margin-bottom:0 !important;">
                  <div class="card-body p-1">
                      <h4 class="card-title mb-1">
                          <span v-if="data.coursename.length > 50"  class="blog-title-truncate text-body-heading ">
                            {{(data.coursename.substring(0,51)+ ' ...').toLowerCase()}}
                          </span>
                          <span v-else  class="blog-title-truncate text-body-heading ">
                            {{(data.coursename).toLowerCase()}}
                          </span>
                      </h4>
                      <div class="media">
                        <div class="avatar mr-50">
                              <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                              <img alt="" v-if="!data.imagename" :src="defaultimagepath" width="40" height="40" />
                          </div>
                          <div class="media-body">
                            <div>
                              <small class="text-muted mr-25">by</small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                              <span class="text-muted ml-50 mr-25">|</span>
                              <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                              
                            </div>
                          </div>
                      </div>
                      <div class="card-text blog-content-truncate mt-1" style="overflow:hidden">
                          <span v-if="data.description.length>150" v-html="data.description.substring(0, 151) + ' ...'"></span>
                          <span v-else v-html="(data.description).toLowerCase()" style="text-transform:capitalize;"></span>
                      </div>
                      <div>
                        <small class="text-muted mr-25">Published by </small>
                        <small><a href="javascript:void(0);" @click="redirecttoprofile(data.empid)" class="capitalisetext">{{data.empname == '' ? 'NA': data.empname}} </a></small>
                        <small class="text-muted">{{dateMonthFormat(data.publisheddate)}}</small>
                      </div>
                      <hr class="mx-n1" />
                      <div class="d-flex justify-content-between align-items-center">
                          <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold">View Details</span>
                            </div>
                          </a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
            <div class="pageCenter text-center pt-1">
              <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
              <div v-if="training.courselist.length == 0 && isresponce" colspan="14" style="margin-bottom: 2rem; text-align: center;">No record found</div>
            </div>
        </div>
        <div class="container smart_work_courselist" style="display: none;">
          <div class="row"> 
            <div class="col-md-4">
              <div class="card courseview_top">
                  <a href="#" class="card courseview_top_container">
                    <div class="dropdown-items-wrapper">
                        <em data-feather="more-vertical" id="dropdownMenuLink1" role="button" data-toggle="dropdown" aria-expanded="false"></em>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink1">
                            <a class="dropdown-item" href="javascript:void(0)">
                                <em data-feather="refresh-cw" class="mr-25"></em>
                                <span class="align-middle">Refresh</span>
                            </a>
                            <a class="dropdown-item" href="javascript:void(0)">
                                <em data-feather="settings" class="mr-25"></em>
                                <span class="align-middle">Manage</span>
                            </a>
                            <a class="dropdown-item" href="javascript:void(0)">
                                <em data-feather="trash" class="mr-25"></em>
                                <span class="align-middle">Delete</span>
                            </a>
                        </div>
                    </div>
                    <img alt="" src="../../../public/images/pages/vue.jpg">
                    <div class="view_edit_btns"> 
                      <button type="button" class="btn btn-gradient-info">Preview</button>
                      <button type="button" class="btn btn-gradient-dark">Edit</button>
                    </div>
                  </a>
                  <div class="card-body">
                      <h4 class="card-title">
                          <a href="page-blog-detail.html" class="blog-title-truncate text-body-heading">The Best Features Coming to iOS and Web design</a>
                      </h4>
                      <div class="media">
                          <div class="avatar mr-50">
                            <img alt="" src="../../../public/images/icons/avatar-s-11.jpg" class="round" style="width: 24px; height: 24px;"/>
                             
                          </div>
                          <div class="media-body">
                              <small class="text-muted mr-25">by</small>
                              <small><a href="javascript:void(0);" class="text-body">Ghani Pradita</a></small>
                              <span class="text-muted ml-50 mr-25">|</span>
                              <small class="text-muted">Jan 10, 2020</small>
                          </div>
                      </div>
                      <div class="my-1 py-25">
                          <a href="javascript:void(0);">
                              <div class="badge badge-pill badge-light-info mr-50">Quote</div>
                          </a>
                          <a href="javascript:void(0);">
                              <div class="badge badge-pill badge-light-primary">Fashion</div>
                          </a>
                      </div>
                      <p class="card-text blog-content-truncate">
                          Donut fruitcake soufflé apple pie candy canes jujubes croissant chocolate bar ice cream.
                      </p>
                      <hr />
                      <div class="d-flex justify-content-between align-items-center">
                          <a href="page-blog-detail.html#blogComment">
                              <div class="d-flex align-items-center">
                                  <button type="button" class="btn btn-dark waves-effect waves-float waves-light"> Draft </button>
                              </div>
                          </a>
                          <a href="#" class="font-weight-bold p-0-1" data-toggle="tooltip" data-placement="top" title="Lessons"> <em class="las la-list-ul"></em> <span class="count_corses"> 03</span></a>
                      </div>
                  </div>
              </div>
          </div>
            <div class="col-sm-4"> one</div>
            <div class="col-sm-4"> one</div>
          </div> 
        </div>

    <div v-bind:class="[{ show: isListActive}, card]" 
          style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top: 1rem;
            margin-bottom: 1rem !important;"
        >
          <div
            v-bind:class="[{ show: isListDetActive }, card_content, collapse]"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div class="table-responsive">
                                <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                <tr>
                                    <th scope="col" class="text-center first-col-sticky align-middle" style="min-width:45px;">Sr#</th>
                                    <th scope="col" class="text-center second-col-sticky align-middle">Course Code</th>
                                    <th scope="col" class="text-center align-middle" style="max-width:250px;">Course name</th>
                                    <th scope="col" class="align-middle" style="min-width:200px;">Course description</th>
                                    <th scope="col" class="align-middle">No. of Trainings</th>
                                    <th scope="col" class="align-middle">Subscription Type</th>
                                    <th scope="col" class="text-nowrap align-middle">Start Date</th>
                                    <th scope="col" class="text-nowrap align-middle">End Date</th>
                                    <th scope="col" class="text-nowrap align-middle">Created Date</th>
                                    <th scope="col" class="text-center align-middle" style="min-width:200px;">Created By</th>
                                    <th scope="col" class="text-nowrap align-middle">Published Date</th>
                                    <th scope="col" class="align-middle">Published By</th>
                                    <th scope="col" class="align-middle">Action</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(
                                        data, index
                                      ) in training.courselist">
                                      <tr v-bind:key="data.courseid">
                                        <td class="srno first-col-sticky">
                                          {{
                                            index + 1 + currPageNum * pagelimit
                                          }}
                                        </td>
                                        <td class="text-nowrap second-col-sticky">{{ data.coursecode }}</td>
                                        <td class="rolelistdd"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer">
                                          <span
                                            class="
                                              font-weight-bold
                                              sitebluecolor
                                            "
                                            v-if="
                                              data.coursename.length >
                                              50
                                            "
                                            >{{
                                              data.coursename.substring(
                                                0,
                                                50 + 1
                                              ) + "..."
                                            }}</span
                                          >
                                          <span class="   font-weight-bold   sitebluecolor " v-if="   data.coursename.length <=   50 " >{{data.coursename}}</span>
                                        </td>
                                        <td class="text-capitalize" style="max-width: 230px;">
                                          <span v-if="data.description && data.description.length>=150" v-html="data.description.substring(0,150)+'..'" ></span>
                                          <span v-else v-html="data.description"></span>
                                        </td>
                                        <td class="text-center">{{data.nooftraining}}</td>
                                        <td class="text-center">{{data.subsriptiontype}}</td>
                                        <td class="text-nowrap">{{dateFormat(data.startdate)}}</td>
                                        <td class="text-nowrap">{{dateFormat(data.enddate)}}</td>
                                        <td class="text-center text-nowrap">{{dateMonthFormat(data.createddate)}}</td>
                                        <td class="text-center capitalisetext">{{formatName(data.createdby)}}</td>
                                        <td class="text-center text-nowrap">{{dateMonthFormat(data.publisheddate)}}</td>
                                        <td class="text-center capitalisetext">{{formatName(data.publishedby)}}</td>
                                        <td class="centeralign">
                                        <span class="btn btn-icon btn-warning devEditEle"  @click="redirecttourl('view',data)" href="javascript:void(0);">
                                          <eye-icon size="1.5x" class="custom-class"></eye-icon>
                                        </span>
                                      </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="training.courselist == 0"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">
                                          No records found
                                        </td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pageCenter text-center">
                      <pagination
                      v-if="showtabledata"
                      v-model="page"
                      :records="totalcount"
                      :per-page="pagelimit"
                      @paginate="clickCallback"
                    /></div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from "moment";
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2';
import profilepic from '../profilepic.vue';
import { PlusCircleIcon, EditIcon, MoreVerticalIcon,EyeIcon,Edit2Icon,GridIcon,ListIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'EmpTrainingList',
  components: {
    VueElementLoading,EyeIcon,Edit2Icon,
    Loading,
    PlusCircleIcon,
    EditIcon,
    Pagination,
    MoreVerticalIcon,
    GridIcon,ListIcon,
    profilepic
  },
  mixins: [ commonMethods ],
  data() {
    return {
      training:{
        courselist:[],
        courseid:null,
      },
      isLoading: false,
      undt: null,
      tokendata: null,
      totalcount: 0,
      pageCount: 0,
      page:1,
      defaultimagepath:apiUrl.defaultimagepath,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      activelistlink:false,
      activegridlink:false,
      isListDetActive:true,
      isgridView:true,
      blog_list_wrapper :'blog-list-wrapper',
      showtabledata: true,
      isresponce:false,
      displayblk:'displayblk',
      isListActive:true,
      card: "card",
      card_content: "card-content",
      collapse: "collapse",
      componentKey: 0,

    }
  },
  created() {
    this.pagefeatures = {};
    if(window.localStorage.getItem("pagefeatures")){
      var pagefeatures1 = JSON.parse(
        window.atob(window.localStorage.getItem("pagefeatures"))
      );
      pagefeatures1.forEach((element) => {
        if (element.page === "/user/training/list") {
          this.pagefeatures[element.featurename] = element.featureaccess;
        }
      });
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        // AJAX REQUESTS
        this.getCourseData()
      }
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    getCourseData() {
      this.isLoading = true
      let url = 'api/user/coursemaster/list'
       axios({
        method: 'POST',
        url,
        data: { 
          limit: apiUrl.LIMIT,
          offset: this.pageoffset,
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid
        },
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        this.isresponce=true
        if(result.data.errorCode == 0) {
          this.forceRerender()
          let d = result.data.data.rows
          this.training.courselist = d
          this.totalcount = result.data.data.count
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.training.courselist = []
          this.totalcount = 0
        }
      }).catch(e => {
                this.displayError(e)
        })
    },
    formatName(authorstring) {
      if(!authorstring) return 'NA'
      let author=authorstring.replace('@firstcry.com','')
      if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
      author = author.replace('.',' ')
      author = author.charAt(0).toUpperCase() + author.slice(1);
      return author
    },
    redirecttourl:function(btnstate,rowdata){
        rowdata.backbtnstate=btnstate
        if(btnstate=="view"){
            this.$router.push({ 
              path: '/training/view?courseid='+rowdata.courseid,
              params: {
                backbtnstate:btnstate,
                clickeddata:rowdata
            }
          })
        }    
    },
    myListView: function () {
      this.isListDetActive = true;
      this.isgridView=true;
      this.activegridlink=false
      this.activelistlink=true
      $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    myGridView(){
      this.isListDetActive = false;
      this.isgridView=false;
      this.activegridlink=true
      this.activelistlink=false
      $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
    },
    redirecttoprofile(empid){
      let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
      window.open(route.href);
    },
    clickCallback: function(pageNum) {
      this.pageoffset=pageNum
      this.currPageNum = pageNum - 1
      this.getCourseData();
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#TrainingList").offset().top -70
        });
      }, 500);
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    dateFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
  }
}
</script>

<style scoped>
.displayblk{
    display:none !important;
}

.bordertable .row1{
  color:black;
}
</style>
