<template>
    <div class="app-content content">
        <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"  color="orange"  loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="isLoadingBTCLIST" :can-cancel="true" :is-full-page="fullPage"  color="orange"  loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Release Planner</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                                <router-link :to="{ name: 'ListReleaseNote', params: { search: true }}">Release Planner List</router-link>
                            </li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Release Planner</li>
                        </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" >
                    <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
                        <div class="card-header py-1 px-1" style="background-color: #f1f2f3;">
                            <h3 class="card-title">
                                Create Release Planner
                            </h3>
                        </div>
                        <div class="card-body pt-1 update_release_not_radioss">
                            <form class="form form-vertical">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="projectlistdd">Project Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="note.project" :options="note.projectlist" @input="selissuecsproject"/>
                                            <div class="errorinputmsg" v-if="!note.project">Project is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="projectlistdd">Version</label>
                                            <input type="text"  class="form-control capitalisetext"  v-model="note.version" placeholder="Enter version">
                                            <div class="errorinputmsg" v-if="!note.version">Version is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="approvedby">Engineering Manager</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Engineering Manager" v-model="note.approvedby" :options="emlist" />
                                            <div class="errorinputmsg" v-if="!note.approvedby">Engineering Manager is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="pmname">Project Manager</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Project Manager" v-model="note.pmname" :options="pmlist" />
                                            <div class="errorinputmsg" v-if="!note.pmname">Project Manager By is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="qaname">QA Lead</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select QA Lead" v-model="note.qaname" :options="qalist" />
                                            <div class="errorinputmsg" v-if="!note.qaname">QA Lead is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="baname">Business Analyst</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Business Analyst" v-model="note.baname" :options="balist" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="projectlistdd">Gitlab Repo Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select gitlab repo name" v-model="note.reponame" :options="repolist" :multiple="true" />
                                            <div class="errorinputmsg" v-if="!note.reponame">Gitlab Repo is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="projectlistdd">Jenkins Job Name</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select jenkins job name" v-model="note.jenkinsjob" :options="joblist" :multiple="true" />
                                            <div class="errorinputmsg" v-if="!note.jenkinsjob">Jenkins Job is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group mb-1">
                                            <label for="approvedby">Subscribers</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select subscribers" :multiple="true" :clear-on-select="true" v-model="note.subscriber" :options="rolelist" @input="selectrole"/>
                                            <div class="errorinputmsg" v-if="!note.subscriber || note.subscriber.length==0">Subscribers is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group datePicker">
                                        <label for="" class="d-block">Start Date</label>
                                            <date-picker placeholder="Select Start Date"
                                            type="datetime" format="YYYY-MM-DD HH:mm" :disabled-date="notBeforeToday"  v-model="note.startdate" valueType="format" class="startdate" id="StartDate" v-on:change="validateDate('startdate', $event)" :minute-step="5"></date-picker>
                                            <div class="errorinputmsg" v-if="this.errors.note.startdate">{{ this.errors.note.startdate }}</div>
                                            </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group datePicker">
                                        <label for="" class="d-block">End Date</label>
                                        <date-picker placeholder="Select End Date" type="datetime" format="YYYY-MM-DD HH:mm" v-model="note.enddate" valueType="format" class="startdate" id="EndDate" v-on:change="validateDate('enddate', $event)" :minute-step="5" :disabled-date="disabledBefore" ></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.note.enddate">{{ this.errors.note.enddate }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body pt-1 px-0">
                                    <div class="mb-1" >
                                        <div class="table-responsive" style="overflow-x: auto !important;">
                                            <table class="table table-sm dataTableListing table-hover table-striped table-bordered custom_release_note_tb_tbody" aria-describedby="Release Note Details">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" class="text-center" style="max-width:100px;padding: 5px 2px;">Select Features</th>
                                                        <th scope="col" class="text-center">Module Name</th>
                                                        <th scope="col" class="text-center" style="min-width:250px;">Business Task Details</th>
                                                        
                                                        <th scope="col" class="text-center" style="min-width:200px;">Micro Task Code(s)</th>
                                                        <th scope="col" class="text-center" style="min-width:160px;">QA Check SmartWorks ID(s)</th>
                                                        <th scope="col" class="text-center" style="min-width:160px;">CycleSet Code</th>
                                                        <th scope="col" class="text-center" style="min-width:130px;">Review Details</th> 
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody v-if="featureslist.length>0">
                                                    <tr v-for="(bttlist, index) in featureslist">
                                                        <td class="text-center">
                                                            <input class="form-check-input btccheck" type="checkbox" @change="selectBTC($event)" v-model="note.btcItems[index].btc" :value="bttlist.businesstaskmasterid" />
                                                        </td>
                                                        <td class="text-center">
                                                            {{bttlist.modulenamearr}}
                                                        </td>
                                                        <td class="text-left text-capitalize">
                                                            <span class="release_hr_tag_span">  Task Code :</span>
                                                            {{bttlist.businesstaskcode}}
                                                            <hr class="release_hr_tag_tab"/>
                                                            <span class="release_hr_tag_span">  Task Name :</span>
                                                            {{bttlist.taskname}}
                                                            <hr class="release_hr_tag_tab"/>
                                                            <span class="release_hr_tag_span">  Task Type :</span>
                                                            {{bttlist.tasktype}}
                                                        </td>
                                                        
                                                        <td class="text-left">
                                                            <div class="form-group mb-1">
                                                                <label class="w-100" for="">Select Micro Tasks</label>
                                                                <treeselect class="capitalisetext" :multiple="true" :clear-on-select="true" placeholder="Select Micro Tasks" v-model="note.btcItems[index].tasks" :options="bttlist.taskslist" @input="selTasks" @close="validateForm($event)"/>
                                                            </div>
                                                        </td>
                                                        <td class="text-left">
                                                            <div class="form-group mb-1">
                                                                <label class="w-100" for="">Select Issue</label>
                                                                <treeselect class="capitalisetext" :multiple="true" :clear-on-select="true" placeholder="Select Issues" v-model="note.btcItems[index].issues" :options="bttlist.issueslist" @input="selIssues" @close="validateForm($event)"/>
                                                            </div>
                                                        </td>
                                                        <td class="text-left">
                                                            <div class="form-group mb-1">
                                                                <label class="w-100" for="">Select CycleSet Code</label>
                                                                <treeselect class="capitalisetext" :multiple="true" :clear-on-select="true" placeholder="Select CycleSet Code" v-model="note.btcItems[index].cyclesetcode" :options="bttlist.testcasecyclelist" @input="selCyclecode"/>
                                                            </div>
                                                        </td>
                                                        <td class="text-left text-capitalize security_code_w50">
                                                            <span class="release_hr_tag_span"> Security Code Check:</span>
                                                            <input class="form-check-input" type="checkbox" v-model="note.btcItems[index].securitycodereview" value="1" />
                                                            <hr class="release_hr_tag_tab"/>
                                                            <span class="release_hr_tag_span">Sonar Cube SmartWorks ID(s): </span>
                                                            <input class="form-check-input" type="checkbox" v-model="note.btcItems[index].sonarcubereview" value="1" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else><tr><td colspan="12" style="text-align: center;">No Business Tasks Found.</td></tr></tbody>
                                            </table>
                                            <div class="errorinputmsg" v-if="note.btcItemsErr">{{ note.btcItemsErr }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="container ">
                                        <fieldset class="card fieldset_border_vi mb-2 px-0 fieldset_border_rele" >
                                       <div class="card-header py-1 px-2">
                                        <legend style="display: none;"></legend>
                                        <h2 class="card-title"> Impact & Recovery</h2>
                                     </div>
                                     <div class="row impact_recoverybg mx-0 py-2">
                                     
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px"> Has this been tested in a lower environment?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" v-model="note.lower_environment" type="radio" value="1" />
                                                <label class="form-check-label mx-2 ml-1 " for="lower_environment">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" v-model="note.lower_environment" type="radio" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="lower_environment" >No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px"> Has the roll-back been tested & documented?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.tested_documented" id="tested_documented" value="1" />
                                                <label class="form-check-label mx-2 ml-1" for="tested_documented">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.tested_documented" id="tested_documented" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="tested_documented">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Has Backward ?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.backward_compatibility" value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="backward_compatibility">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.backward_compatibility" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="backward_compatibility">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <label for="if_deployment"> <strong style="font-size:13px; letter-spacing: 0.5px; color:#6e6b7b;font-weight: 500;"> If deployment fails, how much time is required to bring it back to steady state and what steps will be taken? </strong> </label>
                                            <div class="form-check form-check-block m-0 pl-0 col-md-12">
                                                <input class="form-control" type="text" v-model="note.if_deployment_fails" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </fieldset>
                            </div>
                            <div class="container ">
                                        <fieldset class="card fieldset_border_vi mb-2 px-0 fieldset_border_rele" >
                                            <legend style="display: none;"></legend>
                                       <div class="card-header py-1 px-2">
                                        
                                        <h2 class="card-title"> Dependency</h2>
                                     </div>
                                    
                                     <div class="row impact_recoverybg mx-0 py-2">
                                   
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1 mb-1">
                                                <div class="options_images_text_view_px">Does this change have any dependency on other systems / services? If yes, which are those?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote ">
                                                <input class="form-check-input" type="radio" v-model="note.systems_services" value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="systems_services">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0  radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.systems_services" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="systems_services">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1 mb-1">
                                                <div class="options_images_text_view_px">Is this change reviewed and signed-off by dependent systems?</div>
                                            </div>
                                            <div class="form-check form-check-block radio-cntrl m-0 mr-1 pl-0 mb-0 custom_radio_creaternote">
                                                <input class="form-check-input mt-0 ml-0 pl-0 ml-0 mr-0" type="radio" v-model="note.dependent_systems" value="1" />
                                                <label class="form-check-label  mr-1" for="dependent_systems">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block radio-cntrl m-0 mr-1 pl-0 mb-0 custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.dependent_systems" value="0" />
                                                <label class="form-check-label mt-0 ml-0 pl-0 mr-0" for="dependent_systems">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Teams involved to execute the change?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0  radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.teams_involved" value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="teams_involved">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0  radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.teams_involved" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="teams_involved">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1 mb-1">
                                                <div class="options_images_text_view_px">Are all stakeholders notified and Smart work Ticket opened to execute the change?</div>
                                            </div>
                                            <div class="form-check form-check-block m-0 pl-0 mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.stakeholders_notified" value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="stakeholders_notified" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block m-0 pl-0 mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.stakeholders_notified" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="stakeholders_notified" style="margin-left:26px">No</label>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </fieldset>
                             </div>
                             <div class="container ">
                              <fieldset class="card fieldset_border_vi mb-2 px-0 fieldset_border_rele" >
                                <legend style="display: none;"></legend>
                                       <div class="card-header py-1 px-2">
                                        <h2 class="card-title"> DB Deployment</h2>
                                     </div>
                                        
                                     <div class="row impact_recoverybg mx-0 py-2">
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Scripts Deployment in </div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0  radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.scripts_deployment" value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="scripts_deployment" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.scripts_deployment" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="scripts_deployment" style="margin-left:26px">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Are you adding a new table?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.adding_new_table" id="adding_new_table" value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_new_table" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.adding_new_table" id="adding_new_table" value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_new_table" style="margin-left:26px">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Adding a new column? If Yes then specify columns:</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  id="adding_new_column" v-model="note.adding_new_column"  value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_new_column" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  id="adding_new_column" v-model="note.adding_new_column"  value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_new_column" style="margin-left:26px">No</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote w-100" v-if="(note.adding_new_column=='1')">
                                                <textarea class="form-control capitalisetext" v-model="note.new_column_specify" placeholder="Specify here..."></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Adding any index?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.adding_any_index"  value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_any_index" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.adding_any_index"  value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_any_index" style="margin-left:26px">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class=" mb-1">
                                            
                                            <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                                <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Adding any new constraint to the running process/flow?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.adding_new_constraint"  value="1" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_new_constraint" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.adding_new_constraint"  value="0" />
                                                <label class="form-check-label mx-2 mr-1" for="adding_new_constraint" style="margin-left:26px">No</label>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Scripts Attached</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.scripts_attached"  value="1" />
                                                <label class="form-check-label m-0 mr-1" for="scripts_attached" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.scripts_attached"  value="0" />
                                                <label class="form-check-label m-0 mr-1" for="scripts_attached" style="margin-left:26px">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Rollback Scripts Attached</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.rollback_scripts_attached"  value="1" />
                                                <label class="form-check-label m-0 mr-1"  for="rollback_scripts_attached" style="margin-left:26px">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.rollback_scripts_attached"  value="0" />
                                                <label class="form-check-label m-0 mr-1" for="rollback_scripts_attached" style="margin-left:26px">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">DB Scripts</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.db_scripts"  value="1" />
                                                <label class="form-check-label m-0 mr-1" for="rollback_scripts_attached" >Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.db_scripts"  value="0" />
                                                <label class="form-check-label m-0 mr-1" for="rollback_scripts_attached" >No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Archival process is required (New table)</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.archival_process"  value="1" />
                                                <label class="form-check-label m-0 mr-1" for="archival_process">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio"  v-model="note.archival_process"  value="0" />
                                                <label class="form-check-label m-0 mr-1" for="archival_process">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="options_images_text_view m-0 mr-1">
                                                <div class="options_images_text_view_px">Downtime Required</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.downtime_required" value="1" />
                                                <label class="form-check-label m-0 mr-1" for="downtime_required">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.downtime_required" value="0" />
                                                <label class="form-check-label m-0 mr-1" for="downtime_required">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <label class="m-0 mr-1 mb-1">
                                                <div class="options_images_text_view_px"> <strong class="font-size:13px; letter-spacing: 0.5px; color:#6e6b7b;font-weight: 500;">Briefly explain the scripts that need to be executed by DBA. Please specify Smartwork Ticket id which are part of the change. </strong></div>
                                            </label>
                                            <div class="form-check form-check-block m-0 pl-0 col-md-12">
                                                <textarea class="form-control" type="text"  v-model="note.briefly_explain"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                   
                                  </div>
                                </fieldset>
                              </div>
                              <div class="container ">
                                    <fieldset class="card fieldset_border_vi mb-2 px-0 fieldset_border_rele" >
                                        <legend style="display: none;"></legend>
                                       <div class="card-header py-1 px-2">
                                        <h2 class="card-title"> QC Dependency</h2>
                                     </div>
                                       
                                     <div class="row impact_recoverybg mx-0 py-2">
                                   
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class="m-0 mr-1 mb-1">
                                                <div class="options_images_text_view_px">Is release unit tested ?</div>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote ">
                                                <input class="form-check-input" type="radio" v-model="note.isunit_tested" value="1" @close="validateForm($event)" @input="selQCQue" />
                                                <label class="form-check-label mx-2 mr-1" for="isunit_tested">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block mt-0 pl-0  radio-cntrl custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.isunit_tested" value="0" @close="validateForm($event)" @input="selQCQue" />
                                                <label class="form-check-label mx-2 mr-1" for="isunit_tested">No</label>
                                            </div>
                                        </div>                
                                    </div>
                                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                        <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                            <div class=" m-0 mr-1 mb-1">
                                                <div class="options_images_text_view_px">Is test data has been created and share with QA team ?</div>
                                            </div>
                                            <div class="form-check form-check-block radio-cntrl m-0 mr-1 pl-0 mb-0 custom_radio_creaternote">
                                                <input class="form-check-input mt-0 ml-0 pl-0 ml-0 mr-0" type="radio" v-model="note.isdata_shared_qateam" value="1"  @close="validateForm($event)" @input="selQCQue2" />
                                                <label class="form-check-label  mr-1" for="isdata_shared_qateam">Yes</label>
                                            </div>
                                            <div class="form-check form-check-block radio-cntrl m-0 mr-1 pl-0 mb-0 custom_radio_creaternote">
                                                <input class="form-check-input" type="radio" v-model="note.isdata_shared_qateam" value="0"  @close="validateForm($event)" @input="selQCQue2"/>
                                                <label class="form-check-label mt-0 ml-0 pl-0 mr-0" for="isdata_shared_qateam">No</label>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>

                              <div class="container ">
                                <fieldset class="card fieldset_border_vi mb-2 px-0 fieldset_border_rele" >
                                    <legend style="display: none;"></legend>
                                    <div class="card-header py-1 px-2">
                                        <h2 class="card-title"> Job Alert</h2>
                                    </div>
                                    <div class="row impact_recoverybg mx-0 py-2">
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                            <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                                <div class="options_images_text_view m-0 mr-1">
                                                    <div class="options_images_text_view_px">Are there Jobs in this release ?</div>
                                                </div>
                                                <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote ">
                                                    <input class="form-check-input" type="radio" v-model="note.isjobrelease" value="1" @close="validateForm($event)" @input="seljobrelease" />
                                                    <label class="form-check-label mx-2 mr-1" for="isjobrelease">Yes</label>
                                                </div>
                                                <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                    <input class="form-check-input" type="radio" v-model="note.isjobrelease" value="0" @close="validateForm($event)" @input="seljobrelease" />
                                                    <label class="form-check-label mx-2 mr-1" for="isjobrelease">No</label>
                                                </div>
                                            </div>                
                                        </div>
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                            <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                                <div class="options_images_text_view m-0 mr-1">
                                                    <div class="options_images_text_view_px">Are job email error alert implemented ?</div>
                                                </div>
                                                <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                    <input class="form-check-input mt-0 ml-0 pl-0 ml-0 mr-0" type="radio" v-model="note.isjobmail" value="1"  @close="validateForm($event)" @input="seljobmail" />
                                                    <label class="form-check-label  mr-1" for="isjobmail">Yes</label>
                                                </div>
                                                <div class="form-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                    <input class="form-check-input" type="radio" v-model="note.isjobmail" value="0"  @close="validateForm($event)" @input="seljobmail"/>
                                                    <label class="form-check-label mt-0 ml-0 pl-0 mr-0" for="isjobmail">No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mb-2" >
                                            <div class="demo-inline-spacing mb-1 inline_spacing_div_width">
                                                <div class="options_images_text_view m-0 mr-1">
                                                    <div class="options_images_text_view_px">Are job call error alert implemented ?</div>
                                                </div>
                                                <div class="orm-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                    <input class="form-check-input mt-0 ml-0 pl-0 ml-0 mr-0" type="radio" v-model="note.jobcallalert" value="1"  @close="validateForm($event)" @input="seljobalert" />
                                                    <label class="form-check-label  mr-1" for="jobcallalert">Yes</label>
                                                </div>
                                                <div class="orm-check form-check-block mt-0 pl-0 radio-cntrl custom_radio_creaternote">
                                                    <input class="form-check-input" type="radio" v-model="note.jobcallalert" value="0"  @close="validateForm($event)" @input="seljobalert"/>
                                                    <label class="form-check-label mt-0 ml-0 pl-0 mr-0" for="jobcallalert">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                              </div>


                              <div class="col-12 mg-top1 text-right">
                                        <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" v-on:click.stop.prevent="saveWizard($event)">Submit</button>
                                        <button type="reset" class="btn btn-outline-secondary mr-1" @click="resetForm()">Reset</button>
                                        <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import Loading from "vue-loading-overlay";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import commonMethods from '../../utils/commonMethods';
import DatePicker from "vue2-datepicker";
import moment from 'moment';

export default {
    name: "CreateReleaseNote",
    components: {
        Loading,Treeselect,DatePicker
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            isLoadingBTCLIST: false,
            fullPage: true,
            isRequestActive:true,
            card_content:'card-content',
            collapse:'collapse',
            tokendata:null,
            undt:[],
            approvalmanager:[],
            emlist:[],
            pmlist:[],
            qalist:[],
            balist:[],
            repolist: [],
            joblist: [],
            featureslist: [],
            disblesavebtn: false,
            btasks: {
                taskid: null,
                issueid: null,
                tcid: null,
                errors: {
                    taskid: null,
                    issueid: null,
                    tcid: null,
                }
            },
            note: {
                startdate: null,
                enddate: null,
                project:null,
                projectlist:[],
                reponame:null,
                jenkinsjob:null,
                approvedby:null,
                qaname:null,
                pmname:null,
                baname:null,
                errors: {},
                version: null,
                lower_environment: null,
                tested_documented: null,
                backward_compatibility: null,		
                if_deployment_fails: null,
                systems_services: null,
                dependent_systems: null,
                teams_involved: null,
                stakeholders_notified: null,
                scripts_deployment: null,
                adding_new_table: null,
                adding_new_column: null,
                new_column_specify: null,
                adding_any_index: null,
                adding_new_constraint: null,
                scripts_attached: null,
                rollback_scripts_attached: null,
                db_scripts: null,
                archival_process: null,
                downtime_required: null,
                briefly_explain: null,
                btcItems: [],
                btcItemsErr: null,
                subscriber: null,
                isunit_tested: null,
                isdata_shared_qateam: null,
                isjobrelease: null,
                isjobmail: null,
                jobcallalert: null
            },
            errors: {
                note: {
                    isunit_tested: null,
                    isdata_shared_qateam: null,
                    startdate:null,
                    enddate:null,
                }
            },
            subscriberslist: [],
            rolelist:[],
            productownerid: apiUrl.productownerid
        }
    },
    mounted() {
        var userdata = window.localStorage.getItem("userdata");
        if (userdata) {
            userdata = JSON.parse(userdata);
            this.undt = userdata
            this.tokendata = window.localStorage.getItem('token');  
            this.getProjectAllocatedToEmp()
            this.getApprovalManagerList(apiUrl.PMroleid)
        }
    },
    methods:{
        selectBTC(event) {
            if(event.target.checked == true) {
                this.note.btcItemsErr = null;
            } else {
                this.note.btcItems.forEach((btcerrlist, i) => {
                    if(event.target.value == btcerrlist.businesstaskmasterid){
                        this.note.btcItems[i].tasks = [];
                        this.note.btcItems[i].issues = [];
                        this.note.btcItems[i].cyclesetcode = [];
                        this.note.btcItems[i].securitycodereview = [];
                        this.note.btcItems[i].sonarcubereview = [];
                    }
                });
            }
        },
        validateDate(mdl, objThis) {
          var startDate = this.note.startdate
          var endDate = this.note.enddate
          if(mdl === 'enddate') {
              if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
              this.errors.note.enddate = "End date should be greater than Start date"
              this.note.enddate = ''
              } else {
              this.errors.note.enddate = ''
              }
          }
           else if(mdl === 'startdate') {
              if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
              this.errors.note.startdate = "Start date should be lesser than End date"
              this.note.startdate = ''
              } else {
              this.errors.note.startdate = ''
              }
          } 
        },
        notBeforeToday: function (date) {
          this.note.enddate = "";
          return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        selectrole(state,value){
            this.note.subscriber = null;
            this.note.subscriber = state;
        },
        disabledBefore(date) {
            let dayBefore = moment(this.note.startdate).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; 
        },
        saveWizard(objThis) {
            var isValid = true;
            isValid = this.validateForm(objThis);
            if (isValid) {
                this.isLoading = true;
                let filteredBTCArr = [];
                this.note.btcItems.forEach(element => {
                    if(element.btc && element.btc.length>0)
                    {
                        filteredBTCArr.push(element)
                    }
                });

                this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    project: this.note.project,
                    startdate: this.note.startdate,
                    enddate: this.note.enddate,
                    reponame: this.note.reponame,
                    jenkinsjob: this.note.jenkinsjob,
                    approvedby: this.note.approvedby,
                    baname: this.note.baname,
                    pmname: this.note.pmname,
                    qaname: this.note.qaname,
                    version: this.note.version,
                    lower_environment: this.note.lower_environment,
                    tested_documented: this.note.tested_documented,
                    backward_compatibility: this.note.backward_compatibility,		
                    if_deployment_fails: this.note.if_deployment_fails,
                    systems_services: this.note.systems_services,
                    dependent_systems: this.note.dependent_systems,
                    teams_involved: this.note.teams_involved,
                    stakeholders_notified: this.note.stakeholders_notified,
                    scripts_deployment: this.note.scripts_deployment,
                    adding_new_table: this.note.adding_new_table,
                    adding_new_column: this.note.adding_new_column,
                    new_column_specify: this.note.new_column_specify,
                    adding_any_index: this.note.adding_any_index,
                    adding_new_constraint: this.note.adding_new_constraint,
                    scripts_attached: this.note.scripts_attached,
                    rollback_scripts_attached: this.note.rollback_scripts_attached,
                    db_scripts: this.note.db_scripts,
                    archival_process: this.note.archival_process,
                    downtime_required: this.note.downtime_required,
                    briefly_explain: this.note.briefly_explain,  
                    btcItems: filteredBTCArr,  
                    subscriber: this.note.subscriber,
                    isunit_tested: this.note.isunit_tested,
                    isdata_shared_qateam: this.note.isdata_shared_qateam,
                    isjobrelease: this.note.isjobrelease,
                    isjobmail: this.note.isjobmail,
                    jobcallalert: this.note.jobcallalert
                };
                axios({
                'method': 'POST',
                'url': 'api/releasenote/saveReleaseNote',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading = false;            
                    if(result.data.errorCode == 0){
                       Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.$router.push({ name: 'ListReleaseNote', params: { search: true }});
                    }  else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                })
                .catch(e => {
                    this.displayError(e)
                    this.isLoading = false;  
                    Swal.fire({
                        title: "",
                        text: e,
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                })
            }
        },
        resetForm() {
            this.note.project = null;
            this.featureslist = [];
            this.note.reponame = null;
            this.note.jenkinsjob = null;
            this.note.approvedby = null;
            this.note.baname = null;
            this.note.pmname = null;
            this.note.qaname = null;
            this.note.errors = {};
            this.note.version = null;
            this.note.lower_environment = null;
            this.note.tested_documented = null;
            this.note.backward_compatibility = null;		
            this.note.if_deployment_fails = null;
            this.note.systems_services = null;
            this.note.dependent_systems = null;
            this.note.teams_involved = null;
            this.note.stakeholders_notified = null;
            this.note.scripts_deployment = null;
            this.note.adding_new_table = null;
            this.note.adding_new_column = null;
            this.note.new_column_specify = null;
            this.note.adding_any_index = null;
            this.note.adding_new_constraint = null;
            this.note.scripts_attached = null;
            this.note.rollback_scripts_attached = null;
            this.note.db_scripts = null;
            this.note.archival_process = null;
            this.note.downtime_required = null;
            this.note.briefly_explain = null;
            this.note.btcItemsErr = null;
            this.note.subscriber = null;
            this.note.isunit_tested = null;
            this.note.isdata_shared_qateam = null;
            this.note.isjobrelease = null;
            this.note.isjobmail = null;
            this.note.jobcallalert = null;
            this.note.startdate = null;
            this.note.enddate = null;
        },
        handleCancel() {
            this.$router.push({ name: 'ListReleaseNote', params: { search: true }});
        },
        validateForm(objThis){
            var isValid=true;

            if (this.note.startdate== "" || this.note.startdate == null) {
              this.errors.note.startdate = "Start date required";
              isValid = false;
            }

            if (this.note.enddate=="" || this.note.enddate == null) {
              this.errors.note.enddate = "End date required";
              isValid = false;
            }

            if(this.note.project == null || this.note.project == ''){
                isValid= false;
            }
            if(this.note.version == null && this.note.version == '') {
                isValid= false;
            }
            if(this.note.reponame == null || this.note.reponame == ''){
                isValid= false;
            }
            if(this.note.jenkinsjob == null && this.note.jenkinsjob == '') {
                isValid= false;
            }
            if(this.note.approvedby == null && this.note.approvedby == '') {
                isValid= false;
            }
            if(this.note.pmname == null || this.note.pmname == '') {
                isValid= false;
            }
            if(this.note.qaname == null || this.note.qaname == '') {
                isValid= false;
            }
            if(this.note.subscriber == null || this.note.subscriber == '' || this.note.subscriber ==[]) {
                isValid= false;
            }else if(this.note.subscriber && this.note.subscriber.length ==0){
                isValid= false;
            }
         
            return isValid;
        }, 
        selQCQue(state, value) {
            this.note.isunit_tested = null;
            this.note.isunit_tested = state;
        },
        selQCQue2(state, value) {
            this.note.isdata_shared_qateam = null;
            this.note.isdata_shared_qateam = state;
        },
        selTasks(state, value) {
            this.btasks.taskid = null;
            this.btasks.taskid = state;
            if(!state) {
                this.btasks.taskid = null;
                this.btasks.errors.taskid = 'Task is required'
            } else {
                this.btasks.errors.taskid = null;
            }
        },
        selIssues(state, value) {
            this.btasks.issueid = null;
            this.btasks.issueid = state;
            if(!state) {
                this.btasks.issueid = null;
                this.btasks.errors.issueid = 'Issue is required'
            } else {
                this.btasks.errors.issueid = null;
            }
        },
        selCyclecode(state, value) {
            this.btasks.tcid = null;
            this.btasks.tcid = state;
        },
        seljobrelease(state) {
            this.note.isjobrelease = null;
            this.note.isjobrelease = state;
        },
        seljobmail(state) {
            this.note.isjobmail = null;
            this.note.isjobmail = state;
        },
        seljobalert(state) {
            this.note.jobcallalert = null;
            this.note.jobcallalert = state;
        },
        getRepoName(type, projectid) {
            axios({
                method: "POST",
                url: "api/releasenote/getAppRepoByProject",
                headers: { authorization : this.tokendata },
                data:{ projectid: projectid, type: type,useremail: this.undt.username,empcode: this.undt.userid }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(type=='repo'){
                        this.repolist = result.data.data.rows
                    } else {
                        this.joblist = result.data.data.rows
                    }
                }else{
                    this.repolist = [];
                    this.joblist = [];
                }
            }).catch(e => {
                this.displayError(e)
        });
        },
        getBusinessTasklistByQAWIP(projectid) {
            this.isLoadingBTCLIST = true;
            axios({
                method: "POST",
                url: "api/releasenote/getBusinessTasklistByQAWIP",
                headers: { authorization : this.tokendata },
                data:{ projectid: projectid,useremail: this.undt.username,empcode: this.undt.userid, }
            }).then((result) => {
                this.isLoadingBTCLIST = false;
                if (result.data.errorCode == 0) {
                    this.featureslist = result.data.data.rows.filter(items => items.taskslist.length>0 || items.issueslist.length>0 || items.testcasecyclelist.length>0);
                    this.note.btcItems = Array.from(this.featureslist, function (item, index) {
                        item = {
                            btc: [],
                            businesstaskmasterid: item.businesstaskmasterid,
                            modulenames: item.modulenamearr,
                            btccodes: item.businesstaskcode,
                            btcname: item.taskname,
                            tasktype: item.tasktype,
                            tasks: [],
                            issues: [],
                            cyclesetcode: [],
                            cyclesetcodeerr: null,
                            securitycodereview: [],
                            sonarcubereview: [],
                        }
                        return item;
                    });
                }  else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                }else{
                    this.featureslist = [];
                    this.btcItems = [];
                }
            }).catch(e => {
          this.displayError(e)
        });
        },
        getEngManByPro(proid, roleid) {
            axios({
                method: "POST",
                url: "api/releasenote/getEMforreleasenote",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,projectidarr:[proid],useremail: this.undt.username,empcode: this.undt.userid}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==apiUrl.EMroleid) {
                        this.emlist = result.data.data.rows;
                    }else if(roleid==apiUrl.PMroleid) {
                        this.pmlist = result.data.data.rows;
                    }else if(roleid==apiUrl.QAroleid) {
                        this.qalist = result.data.data.rows;
                    }else if(roleid==apiUrl.BAroleid) {
                        this.balist = result.data.data.rows;
                    }
                }else{
                    if(roleid==apiUrl.EMroleid) {
                        this.emlist = [];
                    }else if(roleid==apiUrl.PMroleid) {
                        this.pmlist = [];
                    }else if(roleid==apiUrl.QAroleid) {
                        this.qalist = [];
                    }else if(roleid==apiUrl.BAroleid) {
                        this.balist = [];
                    }                   
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        selissuecsproject: function(state,value){
            this.note.project = null
            this.note.project = state
            this.note.reponame =null;
            this.featureslist = [];
            this.note.jenkinsjob =null;
            this.note.approvedby =null;
            this.note.baname =null;
            this.note.qaname =null;
            this.note.pmname =null;
            this.note.errors = {};
            this.note.version = null;
            this.note.lower_environment = null;
            this.note.tested_documented = null;
            this.note.backward_compatibility = null;		
            this.note.if_deployment_fails = null;
            this.note.systems_services = null;
            this.note.dependent_systems = null;
            this.note.teams_involved = null;
            this.note.stakeholders_notified = null;
            this.note.scripts_deployment = null;
            this.note.adding_new_table = null;
            this.note.adding_new_column = null;
            this.note.new_column_specify = null;
            this.note.adding_any_index = null;
            this.note.adding_new_constraint = null;
            this.note.scripts_attached = null;
            this.note.rollback_scripts_attached = null;
            this.note.db_scripts = null;
            this.note.archival_process = null;
            this.note.downtime_required = null;
            this.note.briefly_explain = null;
            this.note.btcItems = [];
            this.note.btcItemsErr = null;
            this.note.subscriber = null;
            this.note.isunit_tested = null;
            this.note.isdata_shared_qateam = null;
            this.note.isjobrelease = null;
            this.note.isjobmail = null;
            this.note.jobcallalert = null;
            this.note.startdate = null;
            this.note.enddate = null;
            if (!state) {
                this.note.subscriber = null;
                this.subscriberslist = [];
            }else{
                this.getEngManByPro(this.note.project, [apiUrl.EMroleid]);
                this.getEngManByPro(this.note.project, [apiUrl.PMroleid]);
                this.getEngManByPro(this.note.project, [apiUrl.BAroleid]);
                this.getEngManByPro(this.note.project, [apiUrl.QAroleid]);
                this.getBusinessTasklistByQAWIP(this.note.project);
                this.getRepoName('repo', this.note.project);
                this.getRepoName('job', this.note.project);
                this.getrolenamewithid(state);
            }
        },
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage: 'createpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.note.projectlist = result.data.data;
                } else {
                    this.note.projectlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getApprovalManagerList(dept) {
            this.note.approvedby=null
            axios({
                method: "POST",
                url: "api/listing/getStakeHoldersList",
                data: { roleid: dept,useremail: this.undt.username,empcode: this.undt.userid, },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.approvalmanager = result.data.data;
                } else {
                    this.approvalmanager = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getrolenamewithid(projectid) {
            this.isLoading = true
            let url = "api/releasenote/getrolenamewithid";
            this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            projectid:projectid
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.rolelist = result.data.data;
                    if(this.rolelist && this.rolelist.length >0){
                        this.rolelist.map(item =>{
                            if(item.id == this.productownerid){
                                this.note.subscriber =[];
                                this.note.subscriber.push(this.productownerid.toString());
                            }
                        })
                    }
                } else {
                    this.rolelist = [];
                }
            }).catch(e => {
          this.displayError(e)
        });
        },
    }
}
</script>