<template>
    <div class="app-content content">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="fullPage"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
           <div class="content-wrapper">
               <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5 px-0">
                       <div class="row breadcrumbs-top">
                           <div class="col-sm-8">
                               <h3 class="content-header-title float-left mb-0">HR Dashboard</h3>
                               <!-- <div class="breadcrumb-wrapper">
                                   <ol class="breadcrumb">
                                       <li class="breadcrumb-item"><router-link to='/profile'>Leave</router-link></li>
                                   </ol>
                               </div> -->
                           </div>
                       </div>
                       </div>
                        <div class="content-body">
                            <!-- approval status -->
                            <section class="match-height" id="dashboard-ecommerce">
                                <!-- Stats Horizontal Card -->
                                <h4 class="customTitle py-1 pt-0 mb-0">HOD of Department</h4>
                                <div class="row dashTiles  ">
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-1">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                    <em class="las la-clipboard-check font-medium-6 text-white"></em>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhodapprovals ? hrdashboardcount.hoddata.totalhodapprovals : 0 }}</h2>
                                                    <p class="card-text text-white">Total Approval</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-2">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-hourglass-half font-medium-6 text-white"></em>
                                                        
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhodpending ? hrdashboardcount.hoddata.totalhodpending : 0 }}</h2>
                                                    <p class="card-text text-white">Total Pending</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-3">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-hand-holding-heart font-medium-6 text-white"></em>
                                                        
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhodapproval ? hrdashboardcount.hoddata.totalhodapproval : 0 }}</h2>
                                                    <p class="card-text text-white">Total Completed</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/ Stats Horizontal Card -->
                                <!-- Stats Horizontal Card -->
                                <h4 class="customTitle py-1 pt-0 mb-0">HOD of HR</h4>
                                <div class="row dashTiles">
                                <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-4">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-clipboard-check font-medium-6 text-white"></em>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhrhodapproval ? hrdashboardcount.hoddata.totalhrhodapproval :0 }}</h2>
                                                    <p class="card-text text-white">Total Approval</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-5">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-hourglass-half font-medium-6 text-white"></em>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhodapproval ? hrdashboardcount.hoddata.totalhodapproval : 0 }}</h2>
                                                    <p class="card-text text-white">Total Pending</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-7">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-hand-holding-heart font-medium-6 text-white"></em>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhrhodapprovals ? hrdashboardcount.hoddata.totalhrhodapprovals : 0 }}</h2>
                                                    <p class="card-text text-white">Total Complated</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/ Stats Horizontal Card -->
                                <!-- Stats Horizontal Card -->
                                <h4 class="customTitle py-1 pt-0 mb-0"> Head of HR </h4>
                                <div class="row dashTiles">
                                <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-7">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-clipboard-check  font-medium-6 text-white"></em>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhrheadapproval ? hrdashboardcount.hoddata.totalhrheadapproval : 0 }}</h2>
                                                    <p class="card-text text-white">Total Approval</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-8">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-hourglass-half font-medium-6 text-white"></em>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhrhodapprovals ? hrdashboardcount.hoddata.totalhrhodapprovals : 0 }}</h2>
                                                    <p class="card-text text-white">Total Pending</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                            <div class="card-header hr_dashboard_new-bgs-9">
                                                <div class="avatar bg-light-primary p-50 mb-1">
                                                    <div class="avatar-content">
                                                        <em class="las la-hand-holding-heart font-medium-6 text-white"></em>
                                                    </div>
                                                </div>
                                                <div class="text-right">
                                                    <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.hoddata && hrdashboardcount.hoddata.totalhrheadapprovals ? hrdashboardcount.hoddata.totalhrheadapprovals : 0 }}</h2>
                                                    <p class="card-text text-white">Total Completed</p>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/ Stats Horizontal Card -->
                            </section>

                            <section class="match-height" id="dashboard-ecommerce">
                            <!-- Stats Horizontal Card -->
                            
                                    <!-- <div class="row dashTiles  ">
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-1">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-users  font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">30</h2>
                                                        <p class="card-text text-white">Total Numbers</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-2">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-users-cog font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">12</h2>
                                                        <p class="card-text text-white">Total Forwarded With Team</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-3">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-headset font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">20</h2>
                                                        <p class="card-text text-white">Total Forwarded With Calls</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!--/ Stats Horizontal Card -->

                                    <!-- Stats Horizontal Card -->
                                    <h4 class="customTitle py-1 pt-0 mb-0">Candidate & Calling</h4>
                                    <div class="row dashTiles">
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-4">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-users font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.totalcandidate ? hrdashboardcount.totalcandidate : 0 }}</h2>
                                                        <p class="card-text text-white">Total Candidate</p>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-5">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-headset font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.callingcount && hrdashboardcount.callingcount.totalcallingcomplete ? hrdashboardcount.callingcount.totalcallingcomplete : 0 }}</h2>
                                                        <p class="card-text text-white">Total Calling Completed</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-6">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-times-circle font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.callingcount && hrdashboardcount.callingcount.totalcallingreject ? hrdashboardcount.callingcount.totalcallingreject : 0 }}</h2>
                                                        <p class="card-text text-white">Total Calling Rejected</p>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--/ Stats Horizontal Card -->
                                    <!-- Stats Horizontal Card -->
                                    <h4 class="customTitle py-1 pt-0 mb-0"> Shortlisting, Screening & Resume Review</h4>
                                    <div class="row dashTiles">
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-8">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-clipboard-check font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.shortlistcount && hrdashboardcount.shortlistcount.totalshortlistcomplete ? hrdashboardcount.shortlistcount.totalshortlistcomplete : 0 }}</h2>
                                                        <p class="card-text text-white">Total Shortlisting</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-7">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-clipboard-list font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.screeningcount && hrdashboardcount.screeningcount.totalscreencomplete ? hrdashboardcount.screeningcount.totalscreencomplete : 0 }}</h2>
                                                        <p class="card-text text-white">Total screening</p>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-9">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-6 text-white"></eye-icon>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.resumereviewcount && hrdashboardcount.resumereviewcount.totalresumereviewcomplete ? hrdashboardcount.resumereviewcount.totalresumereviewcomplete : 0 }}</h2>
                                                        <p class="card-text text-white">Total Resume Review	</p>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--/ Stats Horizontal Card -->
                                    <!-- Stats Horizontal Card -->
                                    <h4 class="customTitle py-1 pt-0 mb-0"> Interview, Approval & Offer</h4>
                                    <div class="row dashTiles">
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-10">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-users  font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.interviewcount && hrdashboardcount.interviewcount.totalinterviewcomplete ? hrdashboardcount.interviewcount.totalinterviewcomplete : 0 }}</h2>
                                                        <p class="card-text text-white">Total Interview Selected</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-11">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-clipboard-check font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.finalapprovalcount && hrdashboardcount.finalapprovalcount.totalfinalappcomplete ? hrdashboardcount.finalapprovalcount.totalfinalappcomplete : 0 }}</h2>
                                                        <p class="card-text text-white">Final Approval</p>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-lg-4">
                                            <div class="card mb-1 cursor-pointer card_bottom_line_animation my_projects-bgs-2">
                                                <div class="card-header hr_dashboard_new-bgs-12">
                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                        <div class="avatar-content">
                                                            <em class="las la-envelope-open-text font-medium-6 text-white"></em>
                                                        </div>
                                                    </div>
                                                    <div class="text-right">
                                                        <h2 class="font-weight-bolder mb-0 text-white">{{ hrdashboardcount.offercount && hrdashboardcount.offercount.totaloffercomplete ? hrdashboardcount.offercount.totaloffercomplete : 0 }}</h2>
                                                        <p class="card-text text-white">Offer</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--/ Stats Horizontal Card -->
                            </section>
                        </div>
           </div>
           
          
       </div>
       
 </template>
 
 <script>
 import axios from 'axios'
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Treeselect from "@riophae/vue-treeselect";
 import '@riophae/vue-treeselect/dist/vue-treeselect.css'
 import Loading from 'vue-loading-overlay';
 import 'vue-loading-overlay/dist/vue-loading.css'
 import profilepic from '../profilepic.vue';
 import {SearchIcon,XIcon,EyeIcon } from "vue-feather-icons";
 import moment from 'moment';
 import DatePicker from "vue2-datepicker";
 import commonMethods from '../../utils/commonMethods';
 
 export default {
    name: 'HrDashboard',
    mixins: [ commonMethods ],

    components: {
        VueElementLoading,Loading,profilepic,Treeselect,SearchIcon,XIcon,DatePicker,EyeIcon
    },
    data() {
        return {
            isSearchRequestActive: false,
            card: "card",
            isRequestActive: false,
            card_content: "card-content",
            border_warning: "border-warning",
            collapse: "collapse",
            currentmodule: "#projectappmaster",
            hrdashboardcount: []
        }
    },
    props: {
        bid: Array,
        tstatus: String,
        flagg: Number,
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.getHRDashboardCount();
        }
    },
    methods: {
        mysearchrequestollapse: function () {
            this.isSearchRequestActive = !this.isSearchRequestActive;
        },
        getHRDashboardCount(){
            this.isLoading = true;
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid
            }
            axios({
                'method': 'POST',
                'url': 'api/hrms/getHRDashboardCount',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.hrdashboardcount = result.data.data;
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.hrdashboardcount =[];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
    },
 }
 </script>