<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12 pb-1">
              <h3 class="content-header-title float-left mb-0">Ticket Roaster Configuration</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'RoasterList', params: { search: true }}">Roaster List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Update Roaster</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card border-warning">
            <div v-bind:class="[{ show: isRequestActive }, card_content, collapse]">
              <div class="card-header py-1 px-2" style="background-color: #f1f2f3">
                <h3 class="card-title">Update Roaster</h3>
              </div>
              <div class="card-body pt-1">
                <form class="form form-vertical">
                  <div class="row">
                    <div class="row mx-0">
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Project Name</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="ticket.project" :options="ticket.projectlist" @select="selissuecsproject" :disabled="this.isThisMerchandiseProject" @close="validateForm($event)"/>
                            <div class="errorinputmsg" v-if="this.errors.ticket.project">{{ this.errors.ticket.project }}</div>
                            </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Field Name </label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Field" v-model="ticket.field" @input="selissuecsfield" :options="ticket.fieldlist" @close="validateForm($event)"/>
                            <div class="errorinputmsg" v-if="this.errors.ticket.field">{{ this.errors.ticket.field }}</div>
                            </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group mb-1">
                            <label for="projectmdllistdd">Values</label>
                            <treeselect
                              class="projectmdllistdd capitalisetext"
                              placeholder="Select Values"
                              v-model="ticket.values"
                              :options="ticket.valueslist"
                              v-if="this.inputtypeselection == 'drop-down'"
                              @select="selvalue"
                            />
                              <input v-else-if="this.inputtypeselection == 'text'" class="form-control"  v-model="ticket.values" type="text" placeholder="Enter text">
                              <input v-else-if="this.inputtypeselection == 'number'"  class="form-control"  v-model="ticket.values" type="number" placeholder="Enter Number">
                              <input v-else class="form-control"  v-model="ticket.values" type="text" placeholder="Enter Values">
                              <date-picker v-if="this.inputtypeselection == 'date'" placeholder="Select date" v-model="ticket.values" valueType="format" class="enddate w-100" :default-value="new Date()" @select="selvalue"></date-picker>
                            <div class="errorinputmsg" v-if="this.errors.ticket.values">{{ this.errors.ticket.values }}</div>
                          </div>
                        </div>

                        <div class="col-md-4">
                          <div class="form-group datePicker">
                            <label for="" class="d-block">Shift From Date</label>
                              <date-picker placeholder="Select Shift From Date" v-model="ticket.fromdate" valueType="format" class="startdate" id="StartDate" v-on:change="validateDate('fromdate', $event)" :disabled-date="notBeforeTodaydate"></date-picker>
                            <div class="errorinputmsg" v-if="this.errors.ticket.fromdate">{{ this.errors.ticket.fromdate }}</div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group datePicker">
                            <label for="" class="d-block">Shift To Date</label>
                            <date-picker placeholder="Select Shift To Date" v-model="ticket.todate" valueType="format" class="startdate" id="EndDate" v-on:change="validateDate('todate', $event)" :disabled-date="notBeforeTodaydate"></date-picker>
                            <div class="errorinputmsg" v-if="this.errors.ticket.todate">{{ this.errors.ticket.todate }}</div>
                          </div>
                        </div>
                      <!-- <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Shift From Days</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Days" v-model="ticket.days" :options="ticket.dayslist" @close="validateForm($event)" @select="selshiftdayfrom"/>
                            <div class="errorinputmsg" v-if="this.errors.ticket.days">{{ this.errors.ticket.days }}</div>
                            </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Shift To Days </label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Days" v-model="ticket.day" :options="ticket.dayslist" @close="validateForm($event)" @select="selshiftdayto"/>
                            <div class="errorinputmsg" v-if="this.errors.ticket.day">{{ this.errors.ticket.day }}</div>
                            </div>
                      </div> -->

                        <div class="col-md-4">
                          <div class="form-group mb-1">
                            <label  for="projectmdllistdd">Shift From Time</label>
                          <div id="spendtime">
                            <vue-timepicker type="time"  v-model="ticket.shiftfrom" format="HH:mm"  :minute-step="5" @close="validateForm($event)" @select="selshiftfromtime"></vue-timepicker>
                            <div class="errorinputmsg" v-if="this.errors.ticket.shiftfrom">{{ this.errors.ticket.shiftfrom }}</div>
                          </div>
                          </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group mb-1">
                            <label  for="projectmdllistdd">Shift To Time</label>
                          <div id="spendtime">
                            <vue-timepicker type="time"  v-model="ticket.shiftto" format="HH:mm" :minute-step="5" @close="validateForm($event)" @select="selshifttotime"></vue-timepicker>
                            <div class="errorinputmsg" v-if="this.errors.ticket.shiftto">{{ this.errors.ticket.shiftto }}</div>
                          </div>
                          </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="allotedto">Agent Name</label>
                          <treeselect class="allotedto capitalisetext"  placeholder="select Agent name" v-model="ticket.allotedto" :options="ticket.allotedtolist" :multiple="true" :clear-on-select="true" @select="selallotedto" @close="validateForm($event)" />
                          <div class="errorinputmsg" v-if="this.errors.ticket.allotedto"> {{ this.errors.ticket.allotedto }}</div>
                        </div>
                      </div>
                      <div class="col-md-4">
                      <div class="form-group mb-1">
                        <label for="allotedto">Type</label>
                        <treeselect class="allotedto capitalisetext"  placeholder="select Roaster Type" v-model="ticket.roastertype" :options="typelist"  :clear-on-select="true"  @close="validateForm($event)" />
                        <div class="errorinputmsg" v-if="this.errors.ticket.roastertype"> {{ this.errors.ticket.roastertype }}</div>
                      </div>
                    </div>
                      <div class="col-md-4 mt-2" style="margin-left:-1rem">
                        <label for="isactive" :class="{'active': ticket.isactive}" class="toggle__button">
                          <input type="checkbox" id="isactive" v-model="ticket.isactive">
                          <span class="toggle__switch"></span>
                          <span :class="{'off-lable-color': !ticket.isactive, 'on-label-color': ticket.isactive, 'toggle__label': true}">Inactive/Active</span>
                        </label>
                      </div>
                    <div class="col-md-4 mg-top1 text-right">
                      <label class="w-100 d-block" for="allotedto">&nbsp;</label>
                            <button type="button" class="btn btn-relief-primary mr-1" @click="savewizard($event)" id="submitbtnt42" v-bind:disabled="ticket.disblesavebtn">Submit</button>
                            <button type="reset" class="btn btn-outline-secondary mr-1" @click="resetForm()">Reset</button>
                            <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                        </div>
                    </div>
                   
                  </div>
                    
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <style>
  .createbtnparentdiv {
    padding-right: 0% !important;
  }
  .displayblk {
    display: block !important;
  }
  .createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  </style>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import vSelect from "vue-select";
  import commonMethods from '../../utils/commonMethods';

  import {
    EditIcon,
    EyeIcon,
    Trash2Icon,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    DownloadIcon,
    PlusSquareIcon,
    ClipboardIcon,
  } from "vue-feather-icons";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import Pagination from "vue-pagination-2";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import VueTimepicker from 'vue2-timepicker'
  
  export default {
    name: "IssueCreate",
    mixins: [ commonMethods ],

    components: {
      Loading,
      VueElementLoading,
      vSelect,
      EditIcon,
      EyeIcon,
      Treeselect,
      SearchIcon,
      GridIcon,
      CodesandboxIcon,
      CloudIcon,
      ChromeIcon,
      BoxIcon,
      Link2Icon,
      PackageIcon,
      Pagination,
      MoreVerticalIcon,
      PlusIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ClipboardIcon,
      ckeditor: CKEditor.component,
      Trash2Icon,
      DatePicker,
      VueTimepicker,
    },
    data() {
      return {
        indItHelpDesk: apiUrl.indItHelpDesk,
        ifItHelpDeskNotFound: apiUrl.ifItHelpDeskNotFound,
        isThisBusinessRole: false,
        roleid: 0,
        productlabelname: null,
        loginuser: "",
        isLoading: false,
        fullPage: true,
        stylevariation: apiUrl.stylevariation,
        companymasterlist: [],
        editRowData: [],
        ticket: {
          project: null,
          projectlist: null,
          fieldlist:null,
          valueslist:null,
          allotedto: null,
          allotedtolist: [],
          values:null,
          dayslist: apiUrl.dayslist,
          days:null,
          day:null,
          shiftfrom:"",
          shiftto:"",
          iseditstate:false,
          fromdate: null,
          todate: null,
          isactive: false,
          roastertype:null
        },
        file: [],
        isAddState: false,
        totalcount: 0,
        pageCount: 0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures: [],
        isSearchRequestActive: false,
        card_content: "card-content",
        collapse: "collapse",
        errors: {
          ticket: {
            project: null,
            field: null,
            values:null,
            allotedto: null,
            shiftfrom:null,
            shiftto:null,
            day: null,
            days: null,
            fromdate: null,
            todate: null,
            roastertype:null
          },
        },
        isDetActive: true,
        isRequestActive: true,
        isedit: false,
        card: "card",
        border_warning: "border-warning",
        editor: ClassicEditor,
        editorConfig: {
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "insertTable",
              "|",
              "undo",
              "redo",
              "|",
            ],
          },
        },
        tokendata: null,
        accepttype:apiUrl.uploadfiletype,
        userrole:null,
        merchandisingProId: apiUrl.merchandisingProId,
        isThisMerchandiseProject: false,
        itHelpDesk: apiUrl.indItHelpDesk,
        companycode: apiUrl.companycode,
        TKRosterFields:apiUrl.TKRosterFields.sort((a, b) => a.label.localeCompare(b.label)),
        inputtypeselection: null,
        projectmodulelist:[],
        typelist:[{id:'roundrobin',label:'ROUND ROBIN'},{id:'regular',label:'REGULAR'}]
      };
    },
    mounted() {
      let userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.loginusername = userdata.username;
        this.loginuscondition = userdata.userid;
        this.tokendata = window.localStorage.getItem("token");
        this.userrole = window.localStorage.getItem("userrole")
        this.roleid = this.undt.roleid;
        this.isThisBusinessRole = true;
        this.getProjectAllocatedToEmp();
        this.getroasterById(this.$route.query.rid);
        this.rid=this.$route.query.rid
      }

    },
    methods: {
      getEmpallocatedtoProject: function (node) {
          this.isLoading = true;
          this.input = { projectid: this.ticket.project,useremail: this.undt.username,empcode: this.undt.userid};
          this.input.roleid = 484;
          axios({
            method: "POST",
            url: "api/listing/getEmpallocatedtoProject",
            data: this.input,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.ticket.allotedtolist = result.data.data;
                let decryptdepartmenttext = this.ticket.allotedtolist.map(items =>{

                  let tempemplabel1 = items.label;
                  let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                  if(tempemplabel2 && tempemplabel2 != null){
                      items.label = tempemplabel1 +'('+tempemplabel2 +')';
                  }
                  return items;
                })
            }
          });
      },
      resetForm() {
        this.errors.ticket.allotedto = null;
        // this.ticket.project = null;
        this.ticket.field = null;
        this.ticket.values = null;
        // this.ticket.days = null;
        // this.ticket.day = null;
        this.ticket.fromdate = null;
        this.ticket.todate = null;
        this.ticket.shiftfrom = null;
        this.ticket.shiftto = null;
        this.ticket.allotedto = null;
        this.ticket.isactive = null;
        this.ticket.roastertype = null
        // if(!this.isThisMerchandiseProject){
        //   this.ticket.project = null;
        // }
        this.clearerrorForm();
      },
      getfieldame(field) {
      let fiel = this.ticket.fieldlist.filter(fiel => fiel.id == field)
      if(fiel && fiel.length) {
        return fiel[0].label
      }
      return null
    },
      savewizard: function (mdl, objThis) {
        this.valid = this.validateForm(mdl, objThis);
        if (this.valid) {
          this.isLoading = true;
          let tblNm = "t42";
          if (mdl) tblNm = mdl;
          this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            rid:this.rid,
          };
          let apiURL = "api/roaster/updateroaster";
          this.input.project = this.ticket.project;
          if(!this.TKRosterFields.some(i=>i.id ==this.ticket.field)){
          this.input.fieldname = this.getfieldame(this.ticket.field)
          this.input.field = this.ticket.field ? this.ticket.field :null;
          }
          if(this.TKRosterFields.some(i=>i.id ==this.ticket.field)){
          this.input.fieldname = this.ticket.field
          }
          this.input.values = this.ticket.values.toString();
          this.input.allotedto = this.ticket.allotedto;
          // this.input.days = this.ticket.days;
          // this.input.day = this.ticket.day;
          this.input.fromdate = this.ticket.fromdate;
          this.input.todate = this.ticket.todate;
          if(typeof(this.ticket.shiftfrom)=='string' && this.ticket.shiftfrom) {
            this.input.shiftfrom = this.ticket.shiftfrom
          }else if(typeof(this.ticket.shiftfrom)!='string' && this.ticket.shiftfrom.HH !='' &&  this.ticket.shiftfrom.mm!='') {
              this.input.shiftfrom = this.ticket.shiftfrom;
          }
          if(typeof(this.ticket.shiftto)=='string' && this.ticket.shiftto) {
            this.input.shiftto = this.ticket.shiftto
          }else if(typeof(this.ticket.shiftto)!='string' && this.ticket.shiftto.HH !='' &&  this.ticket.shiftto.mm!='') {
              this.input.shiftto = this.ticket.shiftto;
          }
          if(this.ticket.isactive == true){
            this.input.isactive = 1;
          }else{
            this.input.isactive = 0;
          }

          this.input.roastertype = this.ticket.roastertype
        //   this.input.useremail= this.undt.username
        //   this.input.empcode= this.undt.userid
          this.isAddState = false;
        //   if(this.ticket.iseditstate == true){
        //     apiURL='api/roaster/updateroaster'
        // }
          
          axios({
            method: "POST",
            url: apiURL,
            data: this.input,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.file = [];
              this.ticket.filedescp = [];
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.myrequestcollapse();
              this.$router.push(`/configuration/roasterlist`)

            }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          }).catch(e => {
        this.displayError(e)
      });
        }
      },
    myrequestcollapse() {
        this.isedit = false;
        this.ticket.iseditstate = false
        // this.ticket.project = null;
        this.ticket.field = null;
        this.ticket.values = null;
        // this.ticket.days = null;
        // this.ticket.day = null;
        this.ticket.shiftfrom = {"HH":"","mm":""};
        this.ticket.shiftto = {"HH":"","mm":""};
        this.ticket.fromdate = null;
        this.ticket.todate = null;
        this.clearerrorForm();
      },
     getticketfield(project,isfield) {
        this.isLoading = true;
        let url = "api/roaster/getticketfield";
        this.input = {
          empcode: this.undt.userid,
          useremail  : this.undt.username,
          projectid: [project],
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.fieldlist = result.data.data.concat(this.TKRosterFields);
            this.ticket.fieldlist.sort((a, b) => {
              return a.label.localeCompare(b.label);
            });
            if(isfield){
              let dp = this.ticket.fieldlist.filter((v) => {
              if(v.id==isfield) 
                return v;
              });
              this.inputtypeselection = dp.length > 0 ? dp[0].fieldtype : null
            }
          } else {
            this.ticket.fieldlist = [].concat(this.TKRosterFields);
          }
        });
      },

      getticketfieldvalues() {
        this.isLoading = true;
        let url = "api/roaster/getticketfieldvalues";
        this.input = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          projectid: this.ticket.project,
          field: this.ticket.field
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode === 0) {
              this.ticket.valueslist = result.data.data[0].label.map((item) => {
                return {
                  label: item.optionvalue,
                  id: item.optionvalue,
                };
              });
            } else {
              this.ticket.valueslist = [];
            }
          })
          .catch((error) => {
            this.ticket.valueslist = [];
          });
      },


      getProjectAllocatedToEmp() {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage:'updatepage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.projectlist = result.data.data;
            if(this.ticket.projectlist && this.ticket.projectlist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
                const checkexistproject = this.ticket.projectlist.some(item =>item.id === this.ticket.project);
                if(!checkexistproject){
                    this.getProjectnamebyid(this.ticket.project);
                }
            }else if(this.ticket.projectlist.length ==0 && (this.ticket.project && this.ticket.project !=null)){
                this.getProjectnamebyid(this.ticket.project);
            }
            if(this.ticket.projectlist.length ==1){
                this.ticket.project = this.ticket.projectlist[0].id
                this.isThisMerchandiseProject = true
            }else{
              this.isThisMerchandiseProject = false
            }
          } else {
            this.ticket.projectlist = [];
          }
        });
      },
      getProjectnamebyid(pid){
            this.isLoading = true;
            this.apiURL="api/listing/getProjectAllocatedToEmp"
            this.input={
                useremail: this.undt.username,
                empid :this.undt.userid,
                empcode: this.undt.userid,
                projectidarr: [pid],
                isfrompage: 'isprojectid'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    if(result.data.data && result.data.data.length > 0){
                        this.ticket.projectlist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
      validateForm: function (mdl, objThis) {
         this.clearerrorForm();
        this.isAddState = false;
        var isValid = true;
          if (!this.ticket.project) {
            this.errors.ticket.project = "Project is required";
            isValid = false;
          } else {
            this.errors.ticket.project = null;
          }     
          if (!this.ticket.field) {
            this.errors.ticket.field = "field is required";
            isValid = false;
          } else {
            this.errors.ticket.field = null;
          }  
          if (!this.ticket.values || this.ticket.values ==null || this.ticket.values =='' || this.ticket.values ==' ') {
            this.errors.ticket.values = "values is required";
            isValid = false;
          } else {
            this.errors.ticket.values = null;
          }
          // if (!this.ticket.days) {
          //   this.errors.ticket.days = "Shift From Days is required";
          //   isValid = false;
          // } else {
          //   this.errors.ticket.days = null;
          // } 
          // if (!this.ticket.day) {
          //   this.errors.ticket.day = "Shift To Days is required";
          //   isValid = false;
          // } else {
          //   this.errors.ticket.day = null;
          // }
          if (!this.ticket.fromdate) {
            this.errors.ticket.fromdate = "Shift from date is required";
            isValid = false;
          } else {
            this.errors.ticket.fromdate = null;
          } 
          if (!this.ticket.todate) {
            this.errors.ticket.todate = "Shift to date is required";
            isValid = false;
          } else {
            this.errors.ticket.todate = null;
          } 
          if (!this.ticket.shiftfrom) {
            this.errors.ticket.shiftfrom = "Shift from time is required";
            isValid = false;
          } else {
            this.errors.ticket.shiftfrom = null;
            let hours = this.ticket.shiftfrom
            let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
            if (this.ticket.shiftfrom[1]=='H' || this.ticket.shiftfrom[4]=='m') {
              isValid=false;
              Swal.fire({
                title: "Failed",
                text: 'Shift From Time required in HH:mm format value',
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
            if(typeof hours === 'string' && hours.length === 5){
              isValidHours = true
            }
            if(isValidHours && hours !== null && typeof hours === 'object') {
              this.ticket.shiftfrom = `${hours.HH}:${hours.mm}`
            }
          } 
          if (!this.ticket.shiftto) {
            this.errors.ticket.shiftto = "Shift to time is required";
            isValid = false;
          } else {
            this.errors.ticket.shiftto= null;
            let hours = this.ticket.shiftto
            let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
            if (this.ticket.shiftto[1]=='H' || this.ticket.shiftto[4]=='m') {
              isValid=false;
              Swal.fire({
                title: "Failed",
                text: 'Shift To Time required in HH:mm format value',
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
            if(typeof hours === 'string' && hours.length === 5){
              isValidHours = true
            }
            if(isValidHours && hours !== null && typeof hours === 'object') {
              this.ticket.shiftto = `${hours.HH}:${hours.mm}`
            }
          }
          if (!this.ticket.allotedto) {
            this.errors.ticket.allotedto = "Assigned to required";
            isValid = false;
          } else {
            if(this.ticket.allotedto && this.ticket.allotedto.length ==0){
              this.errors.ticket.allotedto = "Assigned to required";
              isValid = false;
              return
            }else{
              this.errors.ticket.allotedto = "";
            }
          }
          if (!this.ticket.roastertype) {
          this.errors.ticket.roastertype = "Roaster type is required";
          isValid = false;
        }else{
            this.errors.ticket.roastertype = "";
          }
        return isValid;
      },
  
      clearerrorForm: function () {
        this.errors.ticket.project = null;
        this.errors.ticket.field = null;
        this.errors.ticket.values = null;
        // this.errors.ticket.days = null;
        // this.errors.ticket.day = null;
        this.errors.ticket.shiftfrom = "";
        this.errors.ticket.shiftto = "";
        this.errors.ticket.allotedto = null;
        this.errors.ticket.fromdate = null;
        this.errors.ticket.todate = null;
        this.errors.ticket.roastertype = null;
      },
      dateFormat(value) {
        if (!value) return "";
        return moment(String(value)).format("DD-MM-YYYY");
      },
      selissuecsproject: function (state, value) {
        this.ticket.project = null;
        this.ticket.project = state.id;
        this.ticket.allotedtolist = [];
         if (!state && !state.id) {
          this.ticket.fieldlist = [];
          this.ticket.valueslist =[];
          this.ticket.values = null;
          this.ticket.allotedto = null;
          this.ticket.field =null;
          this.ticket.allotedtolist = [];
          this.errors.ticket.project = "Project name is required";
        
         } else {
          this.errors.ticket.project ='';
          this.ticket.fieldlist =[];
          this.ticket.valueslist =[];
          this.ticket.values = null;
          this.ticket.allotedto = null;
          this.ticket.field =null;
          this.getticketfield(state.id);
          this.getEmpallocatedtoProject(state.id);
          this.getProjectwiseroasterType(state.id)
        }
      },
      selissuecsfield: function (state, value) {
        if (!state) {
          this.errors.ticket.field ='Field is required';
          this.ticket.values = null;
          this.ticket.valueslist = [];
        }else{
          if(state !='Ticket Category' && state !='Ticket Sub Category' && state !='Ticket Type'){
            if(state !=this.editRowData.fieldid){
              this.ticket.values = null;
            }
            this.getticketfieldvalues();
          }
          if(!this.TKRosterFields.some(i=>i.id === state)){
            this.ticket.field = state;
            if (!state) {
              this.errors.ticket.field ='Field is required';
              this.ticket.values = null;
              this.ticket.valueslist = [];
            } else {
              this.errors.ticket.field ='';
              if(this.ticket.fieldlist && this.ticket.fieldlist.length >0){
                let dp = this.ticket.fieldlist.filter((v) => {
                if(v.id==state) 
                  return v;
                });
                this.inputtypeselection = dp.length > 0 ? dp[0].fieldtype : null;
              }
            }
          }
          if(this.TKRosterFields.some(i=>i.id === state)){
            if(state=='Ticket Category'){
              if(state !=this.editRowData.fieldname){
                this.ticket.values = null;
                this.ticket.valueslist = [];
              }
              this.getprojectmodules(this.ticket.project)
            }
            if(state=='Ticket Sub Category'){
              if(state !=this.editRowData.fieldname){
                this.ticket.values = null;
                this.ticket.valueslist = [];
              }
              this.getAppModulesbyprojectid(this.ticket.project)
            }
            if(state=='Ticket Type'){
              if(state !=this.editRowData.fieldname){
                this.ticket.values = null;
                this.ticket.valueslist = [];
              }
              this.getTasktypeConfigLists(this.ticket.project)
            }
          }
        }
        },
        getprojectmodules(projecteid) {
          let url = '';
            url = 'api/listing/getpromodulebyapplicationid';
        
          this.input = {
            projecteid: projecteid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage : 'updatepage'
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.inputtypeselection = 'drop-down'
              this.ticket.valueslist = result.data.data;

              if(this.ticket.valueslist && this.ticket.valueslist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
                const checkexistproject = this.ticket.valueslist.some(item =>item.id === this.ticket.values);
                if(!checkexistproject){
                  this.getprojectmodulesbyid(this.ticket.values);
                }
              }else if(this.ticket.valueslist.length ==0 && (this.ticket.values && this.ticket.values !=null)){
                this.getprojectmodulesbyid(this.ticket.values);
              }

            }else {
              this.ticket.valueslist = [];
            }  
          }).catch(e => {
            this.displayError(e)
          });
        },

        getprojectmodulesbyid(moduleid) {
          axios({
            method: "POST",
            url: "api/master/businesstask/getprojectmodulebyprojectid",
            data: { empcode: this.undt.userid,
            useremail: this.undt.username, moduleid: moduleid },
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              if(result.data.data && result.data.data.length > 0){
                if(this.ticket.valueslist && this.ticket.valueslist.length > 0){
                  const checkexistprojectmodule = this.ticket.valueslist.some(item =>item.id === moduleid);
                  if(!checkexistprojectmodule){
                    this.ticket.valueslist.push(result.data.data[0]);
                  }
                }else if(this.ticket.valueslist.length ==0){
                  this.ticket.valueslist.push(result.data.data[0]);
                }
              }
            }
          }).catch(e => {
            this.displayError(e)
          });
        },
        
        getAppModulesbyprojectid(projectid) {
        this.input = {
          projectid: projectid,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage : 'updatepage'
        };
        axios({
          method: "POST",
          url: 'api/listing/getappmodulenamebyprojectid',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.inputtypeselection = 'drop-down'
            this.ticket.valueslist = result.data.data;
            if(this.ticket.valueslist && this.ticket.valueslist.length > 0 && (this.ticket.values && this.ticket.values !=null)){
              const checkexistproject = this.ticket.valueslist.some(item =>item.id === this.ticket.values);
              if(!checkexistproject){
                  this.getAppModulesbyappmoduleid(this.ticket.values);
              }
          }else if(this.ticket.valueslist.length ==0 && (this.ticket.values && this.ticket.values !=null)){
              this.getAppModulesbyappmoduleid(this.ticket.values);
          }
          } else {
            this.ticket.valueslist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getAppModulesbyappmoduleid(appmid) {
        this.input = {
            appmoduleid: appmid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
        };
        axios({
            method: "POST",
            url: 'api/listing/getappmodulenamebyprojectid',
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.length > 0){
                      this.ticket.valueslist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getTasktypeConfigLists(projectid) {
      let payload = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          projectid: parseInt(projectid),
          trackername: 'Ticket Request Type',
      };
      axios({
          method: "POST",
          url: '/api/businesstask/getTasktypeConfigList',
          data: payload,
          'headers':{'authorization':this.tokendata}
      }).then((result) => {
          if(result.data.errorCode == 0){
              let slist = [];
              result.data.data.rows.forEach((rec) => {
                  if(Object.values(rec.statusconfig).length>0){
                      Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                          if(statusnames.length>0) {
                              statusnames.forEach(val => {
                                  if(!slist.some(sdata => sdata.id === val)){
                                      slist.push({ id: val, label: val });
                                  }
                              });
                          }
                      });
                  }
              })
              if(this.ticket.project != null && this.ticket.project == apiUrl.merchandisingProId){
                  this.sortTypeList(slist, 'merchandising')
              } else{
                  this.sortTypeList(slist, 'otherprojects')
              }
          } else {
              this.ticket.valueslist = [];
          }
      }).catch(e => {
        this.displayError(e)
      });
    },
    sortTypeList(typelist, type){
      let numArrFromTypelist = [];
      typelist.forEach(element => {
        if(element.id != null) {
          numArrFromTypelist.push(element.id);
        }
      });
      if(numArrFromTypelist.length>0) {
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'ticket',
          typearr: numArrFromTypelist,
          projecttype: type
        };
        axios({
          method: "POST",
          url: 'api/ticket/sortTypeList',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.inputtypeselection = 'drop-down'
            this.ticket.valueslist = result.data.data.rows;
          } else {
            this.ticket.valueslist = [];
          }
        });
      }
    },
      autoAssignItHelpDeskUser(id) {
          this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            projectid: id,
            roleid: 484,
            flag: 0
          };
          axios({
            method: "POST",
            url: 'api/ticket/autoAssignItHelpDeskUser',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.ticket.allotedto = result.data.data[0].empid;
            } else {
              this.ticket.allotedto = this.ifItHelpDeskNotFound;
            }
          });
      },
      selallotedto: function (state, value) {
        this.ticket.allotedto = null;
        this.ticket.allotedto = state.id;
        if (!state && this.isAddState == false) {
          this.errors.ticket.allotedto = "Assigned To required";
        } else {
          this.errors.ticket.allotedto = null;
        }
      },
      getroasterById(id){
          this.isLoading = true;
          let url = "api/roaster/getroasterById";
          this.input = {
              rid:id,
              useremail: this.undt.username,
              empid: parseInt(this.undt.userid),
              empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
               (result.data.errorCode == 0) 
                this.editRowData = result.data.data;
                this.editRowData=this.editRowData[0]
                this.openEditModule(this.editRowData,0)
            });
          },
          handleCancel() {
            this.$router.push({ name: 'RoasterList', params: { search: true }})
          },
          openEditModule: function(currRowData, flag){
            this.editRowData = currRowData
            this.ticket.project = currRowData.projectid
            this.ticket.field = currRowData.fieldid
            if(currRowData.fieldid && currRowData.fieldid !=null){
              this.getticketfield(this.ticket.project,this.ticket.field)
            }
            if(currRowData.fieldname && (currRowData.fieldname =='Ticket Category' || currRowData.fieldname =='Ticket Sub Category' || currRowData.fieldname == 'Ticket Type') && (currRowData.fieldid ==null || currRowData.fieldid =='')){
              this.ticket.field = null;
              this.ticket.field = currRowData.fieldname;
              this.getticketfield(this.ticket.project);
            }
            this.ticket.fromdate = currRowData.shiftfromdate;
            this.ticket.todate = currRowData.shifttodate;
            this.ticket.values = currRowData.fieldvalue;
            // this.ticket.days = currRowData.fromshiftday;
            // this.ticket.day = currRowData.toshiftday;
            this.ticket.shiftfrom = currRowData.fromshifttime
            this.ticket.shiftto = currRowData.toshifttime
            this.ticket.allotedto = [currRowData.agentcode]
            this.getEmpallocatedtoProject(currRowData.agentcode);
            this.ticket.isactive = currRowData.isactive;
            this.ticket.iseditstate = true
            this.isAddState=true
            if(currRowData.roastertype){

            if(moment(String(new Date())).format("YYYY-MM-DD") > currRowData.shifttodate  || currRowData.isactive ==0){
            this.ticket.roastertype = null
            this.getProjectwiseroasterType(this.ticket.project)
            }else{
            let otypelist = this.typelist.filter(i=>i.id == currRowData.roastertype)
            this.typelist = otypelist
            this.ticket.roastertype = currRowData.roastertype ? currRowData.roastertype:null

            }
            }else{
              this.getProjectwiseroasterType(this.ticket.project)
            }
            this.ticket.roastertype = currRowData.roastertype ? currRowData.roastertype:null
            if(currRowData.fieldname && (currRowData.fieldid && (currRowData.fieldid !='Ticket Category' || currRowData.fieldid !='Ticket Sub Category'|| currRowData.fieldid !='Ticket Type'))){
              this.getticketfieldvalues();
            }else if(currRowData.fieldname =='Ticket Type' && (currRowData.fieldid ==null || currRowData.fieldid =='') || currRowData.fieldid ==undefined || currRowData.fieldid =='Ticket Type'){
              this.getTasktypeConfigLists();
            }
            else if(currRowData.fieldname =='Ticket Category' && (currRowData.fieldid ==null || currRowData.fieldid =='' || currRowData.fieldid ==undefined || currRowData.fieldid =='Ticket Category')){
              this.getprojectmodules();
            }else if(currRowData.fieldname =='Ticket Sub Category' && (currRowData.fieldid ==null || currRowData.fieldid =='') || currRowData.fieldid ==undefined || currRowData.fieldid =='Ticket Sub Category'){
              this.getAppModulesbyprojectid();
            }

            if(flag==0){
              this.isedit=true
              this.isRequestActive=true
            }
            if(this.ticket.projectlist && this.ticket.projectlist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
                const checkexist = this.ticket.projectlist.some(item =>item.id === this.ticket.project);
                if(!checkexist){
                    this.getProjectnamebyid(this.ticket.project);
                }
            }else if(this.ticket.projectlist.length ==0 && (this.ticket.project && this.ticket.project !=null)){
                this.getProjectnamebyid(this.ticket.project);
            }
            // if(this.ticket.valueslist && this.ticket.valueslist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
            //     const checkexistproject = this.ticket.valueslist.some(item =>item.id === this.ticket.project);
            //     if(!checkexistproject){
            //       this.getprojectmodulesbyid(this.ticket.project,this.ticket.values);
            //     }
            //   }else if(this.ticket.valueslist.length ==0 && (this.ticket.project && this.ticket.project !=null)){
            //     this.getprojectmodulesbyid(this.ticket.project,this.ticket.values);
            //   }
            
          },
          // selshiftdayfrom(state){
          //   this.ticket.days =null;
          //   this.ticket.days =state.id;
          //   if(!state && !state.id){
          //     this.errors.ticket.days ='Shift From Days is required';
          //   }else{
          //     this.errors.ticket.days ='';
          //   }
          // },
          // selshiftdayto(state){
          //   this.ticket.day =null;
          //   this.ticket.day =state.id;
          //   if(!state && !state.id){
          //     this.errors.ticket.day ='Shift To Days is required';
          //   }else{
          //     this.errors.ticket.day ='';
          //   }
          // },
          selshiftfromtime(state){
            this.ticket.shiftfrom =null;
            this.ticket.shiftfrom =state.id;
            if(!state && !state.id){
              this.errors.ticket.shiftfrom ='Shift from time is required';
            }else{
              this.errors.ticket.shiftfrom ='';
            }
          },
          selshifttotime(state){
            this.ticket.shiftto =null;
            this.ticket.shiftto =state.id;
            if(!state && !state.id){
              this.errors.ticket.shiftto ='Shift to time is required';
            }else{
              this.errors.ticket.shiftto ='';
            }
          },
          selvalue(state){
            this.ticket.values =null;
            this.ticket.values =state.id;
            if(!state && !state.id){
              this.errors.ticket.values ='Value is required';
            }else{
              this.errors.ticket.values ='';
            }
          },
          notBeforeTodaydate: function (date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
          },
          validateDate(mdl) {
            let fromdate = this.ticket.fromdate;
            let todate = this.ticket.todate;
            if(mdl === 'todate') {
              if ((Date.parse(todate) < Date.parse(fromdate))) {
              this.errors.ticket.todate = "Shift to date should be greater than shift from date";
              this.ticket.todate = '';
              } else {
                this.errors.ticket.todate = '';
              }
            // } else if(mdl === 'fromdate') {
            //   if ((Date.parse(todate) < Date.parse(fromdate))) {
            //     this.errors.ticket.fromdate = "Shift from date should be lesser than shift to date";
            //     this.ticket.fromdate = '';
            //   } else {
            //     this.errors.ticket.fromdate = '';
            //   }
            }
          },
          getProjectwiseroasterType(projectid) {
    this.input = {
      projectid: projectid,
      useremail: this.undt.username,
      empcode: this.undt.userid,
     };
    axios({
      method: "POST",
      url: 'api/roaster/getProjectwiseroasterType',
      data: this.input,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      this.isLoading = false;
      this.ticket.roastertype = null
      if (result.data.errorCode == 0) {
        this.typelist = result.data.data;
      } else {
        
        this.typelist = [{id:'roundrobin',label:'ROUND ROBIN'},{id:'regular',label:'REGULAR'}];
      }
    }).catch(e => {
      this.displayError(e)
    });
  }
    },
    filters: {
      basename: function (value) {
        if (!value) return "";
        return value.substr(value.lastIndexOf("/") + 1);
      },
    },
  };
  </script>
  