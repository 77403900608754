<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
         <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h3 class="content-header-title float-left mb-0">Update Workstep</h3>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls">
                                     <router-link to="/workstep/list"
                                      >Workstep List</router-link
                                       >
                                     </li>
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls">
                                      Update Workstep
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-body">
              <form>
                        <div class="table-responsive" style="overflow-x: inherit !important;">
                <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                  <thead>
                    <tr>
                      <th scope="col">Project Name</th>
                      <th scope="col">Application</th>
                      <th scope="col">Project Module</th>
                      <th scope="col">Application Module</th>
                      <th scope="col">Section Module </th>
                      <th scope="col">Functionality </th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{this.workstepmodule.project}}</td>
                      <td>{{this.workstepmodule.applicationname}}</td>
                      <td>{{this.workstepmodule.projectmodule}}</td>
                      <td>{{this.workstepmodule.applicationmodulename}}</td>
                      <td>{{this.workstepmodule.sectionname}}</td>
                      <td>{{this.workstepmodule.functionalityname}}</td>
                     
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
                    <div class="row">
                            <div class="form-group col-md-6">
                                <div class="form-group">
                                    <label for="devBusinessVerticleData">Business Task Name</label>
                                    <treeselect class="capitalisetext" v-model="workstepmodule.businesstaskid" :options="workstepmodule.tasklist" @input="selbtmid" @close="validateForm('t47',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.workstepmodule.businesstaskid">{{ this.errors.workstepmodule.businesstaskid }}</div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="form-group">
                                    <label class="form-label">Workstep Name</label>
                                    <input type="text" class="form-control" v-model="workstepmodule.workstepname" placeholder="Enter Workstep Name" @blur="validateForm('t47',$event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.workstepmodule.workstepname">{{ this.errors.workstepmodule.workstepname }}</div>
                                </div>
                            </div>
                    </div>
                    <div class="row">
                            <div class="form-group col-md-6">
                                <div class="form-group">
                                    <label for="appmdldesc">Technology</label>
                                    <input class="form-control appmdldesc" v-model="workstepmodule.technology" id="Technology" placeholder="Technology Description "
                                    v-bind:class="{ 
                                        'form-control': true,
                                        'is-invalid': !valid2Text(workstepmodule.technology) && technologyBlured, }"
                                        v-on:blur="technologyBlured = true" />
                                    <div class="invalid-feedback">Technology required</div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="form-group">
                                    <label for="appmdldesc">Development Hours</label>
                                    <input type="time" class="form-control appmdldesc" v-model="workstepmodule.developmenthours" id="developmenthours" placeholder="24:mm" @blur="validateForm('t47',$event)" />
                                    
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.workstepmodule.developmenthours">{{ this.errors.workstepmodule.developmenthours }}</div>
                            </div>
                    </div>
                    <div class="row">
                            <div class="form-group col-md-6">
                                <div class="form-group">
                                    <label for="appmdldesc">Code Review Hours</label>
                                    <input type="time" class="form-control appmdldesc" v-model="workstepmodule.codereviewhours" id="codereviewhours" placeholder="24:mm" @blur="validateForm('t47',$event)" />
                                    
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.workstepmodule.codereviewhours">{{ this.errors.workstepmodule.codereviewhours }}</div>
                            </div>
                            <div class="form-group col-md-6">
                                <div class="form-group">
                                    <label for="appmdldesc">Infra Setup and Deployment Hours</label>
                                    <input type="time" class="form-control appmdldesc" v-model="workstepmodule.infrasetupanddeploymenthours" id="infrasetupanddeploymenthours" placeholder="24:mm" @blur="validateForm('t47',$event)" />
                                    
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.workstepmodule.infrasetupanddeploymenthours">{{ this.errors.workstepmodule.infrasetupanddeploymenthours }}</div>
                            </div>
                    </div>
                    <div class="row">
                            <div class="form-group col-md-6">
                                <div class="form-group">
                                    <label for="appmdldesc">QA Hours</label>
                                    <input type="time" class="form-control appmdldesc" v-model="workstepmodule.qahours" id="qahours" placeholder="24:mm" @blur="validateForm('t47',$event)" />
                                    
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.workstepmodule.qahours">{{ this.errors.workstepmodule.qahours }}</div>
                            </div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0">
                                <div class="form-group">
                                <button type="button" class="btn btn-primary mr-1"  @click="saveData('t47',$event)" id="submitbtnt47">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary" @click="resetform()">Clear</button>
                            </div>
                        
                    </div>
              </form>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'

import { PlusSquareIcon,FolderIcon,PlusIcon,ChevronDownIcon,ChevronUpIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,BoxIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon } from 'vue-feather-icons'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
    name:'SectionModule',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        FolderIcon,
        PlusSquareIcon,
        ChevronUpIcon,
        ChevronDownIcon,
        VueTimepicker
    },    
    data() {
        return {
            showbutton:false,
            isLoading: false,
            fullPage: true,
            stylevariation:apiUrl.stylevariation,
            companymasterlist: [],
            selected: null,
            editRowData:[],
            glbMdl:'',
            TimeFormat: apiUrl.TimeFormat,
            workstepmodule:{
                sectionmasterid:null,
                sectionmasterlist:null,
                applicationmoduleid:null,
                applicationmodulelist:null,
                projectid:null,
                projectname:null,
                projectlist:null,
                applicationid:null,
                applicationlist:null,
                functionalityname:null,
                functionalityid:null,
                appprojectdd:null,
                projectmoduleid:null,
                label:"",
                project:null,
                projectmodule:null,
                applicationname:null,
                applicationmodulename:null,
                sectionname:null,
                projecttypelist:null,
                projecttypeid:null,
                businesslist:null,
                businessverticleid:null,
                companyid:null,
                companylist:null,
                businesstaskid:null,
                tasklist:null,
                workstepname: null,
                technology:null,
                developmenthours: null,
                codereviewhours:null,
                infrasetupanddeploymenthours:null,
                qahours:null,
                iseditstate:false
            },
            applinameBlured:false,
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            showtabledata:true,
            showproject:false,
            activeprojectid:'',
            modulelist:[],
            showfunctionalities:true,
            card_content:'card-content',
            collapse:'collapse',
            errors:{
                workstepmodule:{
                    sectionmasterid:null,
                    applicationmoduleid:null,
                    projectid:null,
                    applicationid:null,
                    functionalityid:null,
                    businesstaskid:null,
                    workstepname: null,
                    technology:null,
                    developmenthours:null,
                    codereviewhours:null,
                    infrasetupanddeploymenthours:null,
                    qahours:null,
                    label:null
                },
            },
            valid:false,
            showdownicon:true,
            showupicon:false,
            showfunctionalities:true,
            functionalitylist:null,
            search: {
                projectMasterid: null,
                projectMasterOpts: [],
                projectModuleid: null,
                projectModuleOpts: [],
                applicationMasterid: null,
                applicationMasterOpts: [],
                applicationModuleid: null,
                applicationModuleOpts: [],
                sectionMasterid: null,
                sectionMasterOpts: [],
                functionalityMasterid : null,
                functionalityMasterOpts: [],
            },
            isSearchRequestActive : false,
            backpagedata:null,
            technologyBlured:false,
            tokendata: null,
        }
    },
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/workstep') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){        
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
        }
        // this.functionalitylistdata('t47','');
            this.getWorkstepById(this.$route.query.workstepid)
        if (this.$route.params.backbtnstate != undefined) {
            if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view') {
                this.showbutton=true;
                this.backpagedata=this.$route.params.clickeddata
                this.getworksteplist(this.$route.params.clickeddata)
                this.getprojectmoduleapplications(this.$route.params.clickeddata)
                // console.log(this.$route)
            }
        }
        else{
            this.getworksteplist([])
        }
    },
    methods:{
        redirecttobreadkumb:function(objThis){
            // objThis.rowdata.backbtnstate=objThis.btnstate
            if(objThis.rowdata){
                objThis.rowdata.backbtnstate=objThis.btnstate
            }else{
                objThis.rowdata={}
                objThis.rowdata.backbtnstate=objThis.btnstate
            }
            this.$router.push({ 
                name: objThis.name,
                path: '/'+objThis.path,
                params: {
                    backbtnstate:objThis.btnstate,
                    clickeddata:objThis.rowdata
                }
            })
        },
        // redirecttourl:function(btnstate,rowdata){
        //     console.log(rowdata, '----rowdata')
        //     rowdata.backbtnstate=btnstate
        //     if(btnstate == 'create') {
        //         this.$router.push({
        //             name: 'TaskCreate',
        //             path: '/tasks/create',
        //             params: {
        //                 backbtnstate:btnstate,
        //                 clickeddata:rowdata
        //             }
        //         })
        //     } else if(btnstate == 'view') {
        //         this.$router.push({
        //             name: 'TaskList',
        //             path: '/tasks/list',
        //             params: {
        //                 backbtnstate:btnstate,
        //                 clickeddata:rowdata
        //             }
        //         })
        //     }
        // },
        mysearchrequestollapse:function(){
            if(this.showfunctionalities == true){
                this.showupicon=true
                this.showdownicon=false
            }else if(this.showfunctionalities == false){
                this.showdownicon=true
                this.showupicon=false
            }
            this.showfunctionalities=!this.showfunctionalities
        },
        getWorkstepById(id){
        this.isLoading = true;
        let url = "api/workstep/getWorkstepById";
        this.input = {
            workstepid:id,
            useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.editRowData = result.data.data;
              this.editRowData=this.editRowData[0]
              this.openEditModule(this.editRowData,0)
            } else {
              this.$router.push('/workstep/list')
              Swal.fire({
                  title: "Failed!",
                  text: "Your are not authorised user to update this workstep",
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
            }
          });
        },
        myfilterequestollapse: function() {
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        projectChange: function (node, instanceId) {
            if(node && node.length>1) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data;
                    } else {
                        this.search.projectMasterOpts = [];
                    }
                });
            }
        },
        functionalitylistdata:function(mdl,objThis){            
            this.isLoading=true
            this.inputappl = {
               useremail:this.undt.username,
               limit: apiUrl.LIMIT,
               offset: this.pageoffset,
            }
            //let apiURL='api/listing/getallfunctionality'
            let apiURL = 'api/projectapplication/getfunctionalitylist'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.functionalitylist=result.data.data
                }
            });
        },
        getDropdownData(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            // axios.post(apiUrl, { tbl: "t11" })
            axios({
                'method': 'POST',
                'url': apiUrl,
                tbl:"t11",
                'headers':{'authorization':this.tokendata}
               })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        this.search.projectMasterOpts = result.data.data
                    } else {
                        this.search.projectMasterOpts = null
                    }
                })       
        },
        selprojectid(state, value) {
            this.search.projectModuleid = null
            this.search.applicationMasterid = null
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.projectMasterid = state;

            if (!state && this.isAddState == false) {
                this.search.applicationMasterOpts = null;
            } else {
                this.getDropdownApplicationdata("t12");
            }
        },
        selprojectModuleid(state, value) {
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.projectModuleid = state;

            if (!state && this.isAddState == false) {
                this.search.applicationModuleOpts = null;
            } else {
                this.getDropdownApplicationdata("t34");
            }
        },
        selApplicationid(state, value) {
            this.search.projectModuleid = null;
            this.search.applicationModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.applicationMasterid = state;

            if (!state && this.isAddState == false) {
                this.search.projectModuleOpts = null;
            } else {
                this.getDropdownApplicationdata("t36");
            }
        },
        selApplicationModuleid(state,value) {
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;

            if (!state && this.isAddState == false) {
                this.search.sectionMasterOpts = null;
            } else {
                this.getDropdownApplicationdata("t35");
            }
        },
        selSectionid(state, value) {
            this.search.functionalityMasterid = null;
            if (!state && this.isAddState == false) {
                this.search.functionalityMasterOpts = null;
            } else {
                this.getDropdownApplicationdata("t37");
            }
        },
        getDropdownApplicationdata(mdl) {
            let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
            this.input = {
                tbl: mdl,
                useremail: this.undt.username,
            }
            if(mdl === "t36") { // projectmodulemaster
                this.input.applicationMasterid = this.search.applicationMasterid
            } else if(mdl === "t12") { // applicationmaster
                this.input.projectMasterid = this.search.projectMasterid
            } else if(mdl === "t34") { // applicationmodulemaster
                this.input.projectModuleid = this.search.projectModuleid
            } else if(mdl === "t35") { // sectionmaster
                this.input.applicationModuleid = this.search.applicationModuleid
            } else if(mdl === "t37") { // functionlitymaster
                this.input.sectionMasterid = this.search.sectionMasterid
            }
            // axios.post(apiUrl, this.input)
            axios({
                'method': 'POST',
                'url': apiUrl,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
                .then(result => {
                    if(result.data.errorCode == 0) {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = result.data.data
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = result.data.data
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = result.data.data;
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = result.data.data;
                        } else if(mdl === "t37") {
                            this.search.functionalityMasterOpts = result.data.data;
                        }
                    } else {
                        if(mdl === "t36") {
                            this.search.projectModuleOpts = []
                        } else if(mdl === "t12") {
                            this.search.applicationMasterOpts = []
                        } else if(mdl === "t34") {
                            this.search.applicationModuleOpts = [];
                        } else if(mdl === "t35") {
                            this.search.sectionMasterOpts = [];
                        } else if(mdl === "t37") {
                            this.search.functionalityMasterOpts = [];
                        }
                    }
                })

        },
        getFilteredSectionData: function() {
            this.isLoading=true
            let uri = "api/projectapplication/getfunctionalitylist"
            this.input = {
                useremail:this.undt.username,
                tbl: "t37",
                isSearch: false,
                section: "workstep",
                isFilterSelect: 1,
                isFilterPayload: {},
            }

            if(this.search.projectMasterid && this.search.projectMasterid != null) {
                this.input.isFilterPayload.projectMasterid = this.search.projectMasterid
            }
            if(this.search.applicationModuleid && this.search.applicationModuleid != null) {
                this.input.isFilterPayload.applicationModuleid = this.search.applicationModuleid
            }
            if(this.search.applicationMasterid && this.search.applicationMasterid != null) {
                this.input.isFilterPayload.applicationMasterid = this.search.applicationMasterid
            }
            if(this.search.projectModuleid && this.search.projectModuleid != null) {
                this.input.isFilterPayload.projectModuleid = this.search.projectModuleid
            }
            if(this.search.sectionMasterid && this.search.sectionMasterid != null) {
                this.input.isFilterPayload.sectionMasterid = this.search.sectionMasterid
            }
            if(this.search.functionalityMasterid && this.search.functionalityMasterid != null) {
                this.input.isFilterPayload.functionalityMasterid = this.search.functionalityMasterid
            }

            axios.post(uri, this.input)
                .then(result => {
                    this.isLoading = false
                    this.showfunctionalities = true
                    this.showupicon = false
                    this.showdownicon = true
                    this.showproject = false
                    if (result.data.errorCode == 0) {
                        this.functionalitylist = result.data.data;
                    } else {
                        this.functionalitylist = [];
                        Swal.fire({
                            title: "",
                            text: "No Data found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
        },
        resetRecords: function () {
            this.search.applicationModuleid = null;
            this.search.applicationMasterid = null;
            this.search.projectMasterid = null;
            this.search.projectModuleid = null;
            this.search.sectionMasterid = null;
            this.search.functionalityMasterid = null;
            this.search.businessTaskMasterid = null;
            this.projectnameList = null;
            this.search.projectMasterOpts = null;
            this.search.businessTaskMasterOpts = null;
            this.getworksteplist({})
        },
        getworksteplist:function(rowdata){
            this.isLoading=true
            this.clickedprojdata=rowdata
            this.input = {
                tbl:'t47',
                // functionalityobj:rowdata,
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
                useremail:this.undt.username,
                functionlitymasterid:rowdata.functionlitymasterid,
                projectMasterid: rowdata.projectMasterid,
                projectModuleid: rowdata.projectModuleid?rowdata.projectModuleid:rowdata.projectmoduleid,
                applicationMasterid: rowdata.applicationMasterid,
                applicationModuleid: rowdata.applicationModuleid,
                sectionMasterid: rowdata.sectionMasterid,
                functionalityMasterid: rowdata.functionalityMasterid?rowdata.functionalityMasterid:rowdata.functionlitymasterid,
                businessTaskMasterid: rowdata.businessTaskMasterid,
                uniqueid: this.undt.userid,
            }
            let url = 'api/workstep/list'
            this.workstepmodule.project=this.clickedprojdata.projectname
            this.workstepmodule.projectmodule=this.clickedprojdata.projectmodulename
            this.workstepmodule.appprojectdd = this.clickedprojdata.projectid
            this.workstepmodule.projectmoduleid = this.clickedprojdata.projectmoduleid
            this.workstepmodule.applicationid = this.clickedprojdata.applicationid
            this.workstepmodule.applicationname = this.clickedprojdata.applicationname
            this.workstepmodule.applicationmodulename = this.clickedprojdata.applicationmodulename
            this.workstepmodule.sectionname = this.clickedprojdata.sectionname
            this.workstepmodule.sectionmasterid = this.clickedprojdata.sectionmasterid
            this.workstepmodule.functionalityname = this.clickedprojdata.functionlityname
            this.workstepmodule.functionalityid = this.clickedprojdata.functionlitymasterid
            this.workstepmodule.applicationmoduleid = this.clickedprojdata.applicationmoduleid
            this.workstepmodule.projectid = this.clickedprojdata.projectid
            this.workstepmodule.label = this.clickedprojdata.label
            this.workstepmodule.projecttypeid=this.clickedprojdata.projecttypeid
            this.workstepmodule.businessverticleid=this.clickedprojdata.businessverticleid
            this.workstepmodule.companyid=this.clickedprojdata.companyid
            this.getApplicationdata('t3','workstepmodule')
            this.getApplicationdata('t2','workstepmodule')
            this.getApplicationdata('t1','workstepmodule')
            this.getApplicationdata('t9','workstepmodule')
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading=false; 
                this.showfunctionalities=false
                if(result.data.errorCode == 0){
                    this.showproject=true;
                    this.companymasterlist=result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    // this.mysearchrequestollapse()
                }else{
                    this.companymasterlist=[]
                    this.showproject=true;
                    this.totalcount = 0
                    // Swal.fire({
                    //     title: "",
                    //     text: "Project Application  data not found for : "+rowdata.sectionname,
                    //     icon: 'info',
                    //     customClass: {
                    //         confirmButton: "btn btn-primary"
                    //     },
                    //     buttonsStyling: !1
                    // })
                    // this.mysearchrequestollapse()
                }
                setTimeout(function(){
                    if(rowdata.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                }, 1000);                
            })
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't22'){
                this.customlisting(inputEle,'')
            }else{
                this.getworksteplist(this.clickedprojdata)
            }
        },
        validText:function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text:function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        setSelected:function(value)
        {
            this.selected=value
        },
        selbtmid(state, value) {
            if(!state) {
                this.errors.workstepmodule.businesstaskid = "Business task name required"
            } else {
                this.errors.workstepmodule.businesstaskid = ""
            }
        },
        validateForm:function(mdl,objThis){
            this.isAddState=false
            var isValid=true;
            this.clearerrorForm();
            this.technologyBlured = true;
            if(mdl=="t47"){
                let hoursArr = [Boolean(this.workstepmodule.developmenthours), Boolean(this.workstepmodule.codereviewhours), Boolean(this.workstepmodule.infrasetupanddeploymenthours), Boolean(this.workstepmodule.qahours) ]
                isValid = hoursArr.some(time => time != false)
                if(!isValid) {
                    this.errors.workstepmodule.developmenthours = "Atleast one field is required";
                    this.errors.workstepmodule.codereviewhours = "Atleast one field is required";
                    this.errors.workstepmodule.codereviewhours = "Atleast one field is required";
                    this.errors.workstepmodule.infrasetupanddeploymenthours = "Atleast one field is required";
                    this.errors.workstepmodule.qahours = "Atleast one field is required";
                } else {
                    this.errors.workstepmodule.developmenthours = "";
                    this.errors.workstepmodule.codereviewhours = "";
                    this.errors.workstepmodule.codereviewhours = "";
                    this.errors.workstepmodule.infrasetupanddeploymenthours = "";
                    this.errors.workstepmodule.qahours = "";
                }
                if(!this.workstepmodule.sectionmasterid){
                    this.errors.workstepmodule.sectionmasterid="Section required";
                    isValid= false;
                }
                if(!this.workstepmodule.applicationmoduleid){
                    this.errors.workstepmodule.applicationmoduleid="Application Module required";
                    isValid= false;
                }
                if(!this.workstepmodule.projectid){
                    this.errors.workstepmodule.projectid="Project required";
                    isValid= false;
                }
                // if(!this.workstepmodule.applicationid){
                //     this.errors.workstepmodule.applicationid="Application required";
                //     isValid= false;
                // }
                if(!this.workstepmodule.functionalityid){
                    this.errors.workstepmodule.functionalityid="Functionality name required";
                    isValid= false;
                }
                if(!this.workstepmodule.businesstaskid){
                    this.errors.workstepmodule.businesstaskid="Business task name required";
                    isValid= false;
                }
                if(!this.workstepmodule.workstepname) {
                    this.errors.workstepmodule.workstepname = "Workstep name required"
                    isValid = false;
                }
                if(!this.workstepmodule.technology){
                    this.errors.workstepmodule.technology="Technology required";
                    isValid= false;
                }
            }
            return isValid
        },
        validateHHMM:function(str){
            //return /^([0-9]|0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9]|10[0-9]):[0-5][0-9]$/.test(str);
            return /^[0-9][0-9]:[0-5][0-9]$/.test(str);
        },
        validEmail:function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm:function(){
            this.errors= {
                workstepmodule:{
                    sectionmasterid:null,
                    applicationmoduleid:null,
                    projectid:null,
                    applicationid:null,
                    functionalityid:null,
                    businesstaskid:null,
                    technology:null,
                    developmenthours:null,
                    codereviewhours:null,
                    infrasetupanddeploymenthours:null,
                    qahours:null,
                },
            };
        },
        resetform:function(){
            this.workstepmodule.businesstaskid=null
            this.workstepmodule.technology=null
            this.workstepmodule.developmenthours=null
            this.workstepmodule.codereviewhours=null
            this.workstepmodule.infrasetupanddeploymenthours=null
            this.workstepmodule.qahours=null
        },
        openEditModule:function(currRowData,flag){
            this.getApplicationdata('t9', 'workstepmodule')
            this.editRowData = currRowData
            this.workstepmodule.businesstaskid = currRowData.businesstaskid
            this.workstepmodule.technology = currRowData.technology
            this.workstepmodule.developmenthours = currRowData.developmenthours
            this.workstepmodule.codereviewhours = currRowData.codereviewhours
            this.workstepmodule.infrasetupanddeploymenthours = currRowData.infrasetupanddeploymenthours
            this.workstepmodule.qahours = currRowData.qahours
            this.workstepmodule.workstepname = currRowData.workstepname
            this.workstepmodule.label = currRowData.label

            this.workstepmodule.project = currRowData.projectname
            this.workstepmodule.projectmodule= currRowData.projectmodulename
            /* this.workstepmodule.applicationname= currRowData.applicationname */
            this.getprojectmoduleapplications(currRowData)
            this.workstepmodule.applicationmodulename= currRowData.applicationmodulename
            this.workstepmodule.sectionname= currRowData.sectionname
            this.workstepmodule.functionalityname= currRowData.functionlityname
            this.workstepmodule.projecttypeid= currRowData.projecttypeid
            this.workstepmodule.companyid= currRowData.companyid
            this.workstepmodule.businessverticleid =currRowData.businessverticleid
            this.workstepmodule.sectionmasterid = currRowData.sectionmasterid
            this.workstepmodule.projectid = currRowData.projectid
            this.workstepmodule.applicationmoduleid = currRowData.applicationmoduleid
            this.workstepmodule.applicationid = currRowData.applicationid
            this.workstepmodule.functionalityid = currRowData.functionlitymasterid
            this.workstepmodule.iseditstate = true
            this.isAddState=true
            if(flag==0){
                this.isedit=true
                this.formActionLabel = `Edit Work Step : ${currRowData.businesstaskid}`
                this.isRequestActive=true
                this.disRequestActive=false
                $('html, body').animate({ scrollTop: $(document).height() }, 1200);
                
            }
        },
        getprojectmoduleapplications: function(rowdata){
            this.inputprojappl = {
                id:rowdata.projectmoduleid
            }
            let apiURL='api/projectmodule/getapplications'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputprojappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.workstepmodule.applicationname=[]
                    // this.applicationmodule.applicationlist=result.data.data.data
                    result.data.data.data.forEach(element => {
                        if(element.applicationid>0){
                            this.workstepmodule.applicationname.push(element.applicationname)
                        }
                    });
                this.workstepmodule.applicationname = this.workstepmodule.applicationname.toString()
                }else{
                    this.applicationmodule.applicationid=null
                }
            })
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        saveData:function(tblNm,objThis){
            if( tblNm=="t47"){
                this.valid=this.validateForm(tblNm)
            }else{
                this.valid=false;
            }
            if (this.valid) {
                this.input = {}
                let apiURL='api/workstep/saveupdate'
                if(tblNm == 't47'){
                    this.input.sectionmasterid=this.workstepmodule.sectionmasterid
                    this.input.projectid=this.workstepmodule.projectid
                    this.input.applicationmoduleid=this.workstepmodule.applicationmoduleid
                    this.input.applicationid=this.workstepmodule.applicationid
                    this.input.functionalityid=this.workstepmodule.functionalityid
                    this.input.businesstaskid=this.workstepmodule.businesstaskid
                    this.input.workstepname = this.workstepmodule.workstepname
                    this.input.technology=this.workstepmodule.technology
                    this.input.developmenthours=this.workstepmodule.developmenthours
                    this.input.codereviewhours=this.workstepmodule.codereviewhours
                    this.input.infrasetupanddeploymenthours=this.workstepmodule.infrasetupanddeploymenthours
                    this.input.qahours=this.workstepmodule.qahours
                    this.input.section='save'
                    this.input.actionby=this.undt.username
                } 
                if(this.workstepmodule.iseditstate == true){
                    this.input.section='update'
                    this.input.id=this.editRowData.workstepid
                }               
                this.isAddState=false
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        $('.close').click();
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                         this.rowdata={
                            label : this.workstepmodule.label,
                            projectname : this.workstepmodule.project,
                            projectmodulename : this.workstepmodule.projectmodule,
                            appprojectdd : this.workstepmodule.projectid,
                            projectmoduleid : this.workstepmodule.projectmoduleid,
                            moduleid : this.workstepmodule.sectionmasterid,
                            applicationid : this.workstepmodule.applicationid,
                            applicationname : this.workstepmodule.applicationname,
                            applicationmodulename : this.workstepmodule.applicationmodulename,
                            sectionname : this.workstepmodule.sectionname,
                            applicationmoduleid : this.workstepmodule.applicationmoduleid,
                            projectid : this.workstepmodule.projectid,
                            label : this.workstepmodule.label,
                            projecttypeid : this.workstepmodule.projecttypeid,
                            businessverticleid : this.workstepmodule.businessverticleid,
                            companyid : this.workstepmodule.companyid
                        }
                        this.getworksteplist(this.rowdata)
                         this.isLoading = false
                        // this.getworksteplist({})
                        // this.clearerrorForm();
                        // this.resetform();
                        this.$router.push('/workstep/list')
                    }
                })
            }
        },
        selfmsectionmdldata:function(state,value){
            this.workstepmodule.sectionmasterid=null
            this.workstepmodule.sectionmasterid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.sectionmasterid="Section Required"
            }else{                
                this.errors.workstepmodule.sectionmasterid=null
                this.getfiltereddata('workstepmodule');
            }
        },
        selfmprojectdata:function(state,value){
            this.workstepmodule.projectid=null
            this.workstepmodule.projectid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.projectid="Project Required"
            }else{                
                this.errors.workstepmodule.projectid=null
            }
        },
        selfmapplmdldata:function(state,value){
            this.workstepmodule.applicationmoduleid=null
            this.workstepmodule.applicationmoduleid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.applicationmoduleid='Application Module required'
            }else{                
                this.errors.workstepmodule.applicationmoduleid=null
            }
        },
        selfmappmdldata:function(state,value){
            this.workstepmodule.applicationid=null
            this.workstepmodule.applicationid=state
            if(!state && this.isAddState == false){
                this.errors.workstepmodule.applicationid='Application required'
            }else{                
                this.errors.workstepmodule.applicationid=null
            }
        },
        selappapltype:function(state,value){
            this.application.applicationtype=null
            this.application.applicationtype=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationtype='Application type required'
            }else{                
                this.application.errorapplicationtype=null
            }
        },
        selappaplaccess:function(state,value){
            this.application.applicationaccess=null
            this.application.applicationaccess=state
            if(!state && this.isAddState == false){
                this.application.errorapplicationaccess ='Application access required'
            }else{                
                this.application.errorapplicationaccess =null
            }
        },
        getApplicationdata:function(mdl,curracttab){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            let apiURL='api/master/commonmaster/dropdownlist'
            if(curracttab == 'workstepmodule'){
                this.inputappl = {
                    tbl:mdl
                }
                if(mdl=='t9'){
                    this.inputappl = {
                        projectid: this.workstepmodule.projectid,
                        empid:this.undt.userid,
                        useremail: this.undt.username,
                    }
                    apiURL='api/searching/getBusinessTaskbyProject'
                }
            }
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    if(mdl == 't2'){
                        this.workstepmodule.businesslist=result.data.data.rows
                        return true;                            
                    }else if(mdl == 't3'){
                        if(curracttab == 'workstepmodule'){
                            this.workstepmodule.projecttypelist=result.data.data.rows
                            return true;
                        }
                    }else if(mdl == 't1'){
                        this.workstepmodule.companylist=result.data.data.rows
                        return true;
                    }else if(mdl == 't9'){
                        this.workstepmodule.tasklist=result.data.data
                        return true;
                    }
                }
            })
            // return retResponse;
        },
        addmodule:function(){
            this.workstepmodule.iseditstate = false       
            this.resetform()
        }
    }
}
</script>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.timepickersec .controls{
    right: 44%;
}
.timepickersec .display-time{
    display: block;
    width: 48% !important;
    height: 2.114rem !important;
    padding: 0.138rem 0.438rem !important;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-left: 10%;
}
.card .card-header{
    padding: 10rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
</style>