<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left pl-0">
                 <h3 class="content-header-title float-left mb-0">Daywise Testcases Pass Fail Report</h3>
                 <div class="breadcrumb-wrapper">
                   <ol class="breadcrumb">
                     <li class="breadcrumb-item sitebluecolor cursorpointercls " >
                     <router-link to='/reports/qareports'>Daywise Testcases Pass Fail Report</router-link></li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
         </div>
 
        <div class="card border-warning filter_card_searchbgst" style="border-radius: 5px; border: none!important;">
          <div class="card-header filter_rotation_onlick " style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="w-auto px-1 mobile-padding-0-2 mobile_w_calc_38 d-flex align-items-center">
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                            <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                            </search-icon>
                        </div>
                    </div>
                      <h4 class="card-title d-inline-flex ml-1">Search Daywise Testcases Pass Fail Report</h4>
                </div>
                <div class="ml-auto mr-lg-1 d-flex">
                      <span class="rotetions_view_arrow"> 
                        <em class="las la-angle-down"></em>
                      </span>
                  </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
            <div class="card-body pt-2">
              <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker"> 
                      <label for="" class="d-block">From Date</label>
                      <date-picker placeholder="Select Action Start Date" v-model="search.actionstartdate" valueType="format" class="startdate" id="StartDate" :disabled-date="notBeforeToday" @input="selectedstartdate"></date-picker>
                      <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker"> 
                      <label for="" class="d-block">To Date</label>
                      <date-picker placeholder="Select Action End Date" v-model="search.actionenddate" valueType="format" class="startdate" id="EndDate" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                  </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject" :multiple="true" :clear-on-select="true"/>   
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                <label class="form-label lable-left">QA Lead</label>
                <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Lead"  :options="qaleadlist" v-model="search.qalead" :multiple="true" v-on:search-change="getProjectQAdata(509,null,$event)" :clear-on-select="true"/> 
                </div>
                  <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">QA Engineer</label>
                  <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Engineer"  :options="qaenglist" v-model="search.qaeng" :multiple="true" v-on:search-change="getProjectQAdata(478,null,$event)" :clear-on-select="true"/> 
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Business Task Name</label>
                  <treeselect class="projectmdllistdd" placeholder="Enter min 5 chars to search Business Task Name" v-model="search.searchBusinesstaskname" :options="businesstasknameopts" v-on:search-change="businessNameChangeSearch" :clear-on-select="true"/>                      
                </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 ">
                    <button type="submit" :disabled="isBothDateSelected" class="btn btn-relief-primary mt-2 mt-2 btn-margin-three" @click="testCycleIssueReportList(search, 1)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2  btn-margin-three" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                    <button :disabled="isBothDateSelected" type="move" class="btn btn-relief-primary mt-2 btn-margin-three mr-0" @click="ExportXL(search)"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div id="scrollToListGrid"></div>
        <div class="card border-warning" id="btDiv">
          <div class="card-body p-0 ">
            <div  class="card-header px-1 py-1 view_bg-gradient-primary">
              <h2  class="card-title heading_text_pro_summarys">Daywise Testcases Pass Fail Report</h2>
            </div>
            <div v-if="this.reportissuelist.length>0" class="table-responsive" >
              <table class="table table-hover table_stickys table-col-td-white" aria-describedby="mydesc">
                <thead class="d-none"></thead>
                <tbody>
                  <template>
                        <tr>
                          <th>SR No</th>
                          <th scope="col">
                            Finished Date   
                          </th>
                          <th scope="col">
                            QA Lead
                          </th>
                          <th scope="col">
                            QA Engineer
                          </th>
                          <th scope="col">
                            Cycle Set Code
                          </th>
                          <th scope="col">
                            Cycle Number
                          </th>
                          <th scope="col">
                            Sum of Total Pass
                          </th>
                          <th scope="col">
                            Sum of Total Fail
                          </th>
                          <th scope="col">
                            Sum of Time in Hours
                          </th>
                        </tr>
                        <tr v-for="(rdata, i) in this.reportissuelist">
                          <td>{{ i+1+currPageNum*pagelimit }} </td>
                            <td>{{ dateMONFormat(rdata.finishdate) }}
                            </td>
                            <td>{{ rdata.qalead }}
                            </td>
                            <td>{{ rdata.qaeng }}
                            </td>
                            <td>{{ rdata.cyclesetcode }}
                            </td>
                            <td>{{  rdata.cyclerunno }}
                            </td>
                            <td>{{  rdata.totalpass }}
                            </td> 
                            <td>{{  rdata.totalfail }}
                            </td>   
                            <td>{{  hoursFormate(rdata.hours) }}
                            </td>                        
                        </tr>
                        <tr>
                          <td><b>Total</b></td>
                          <td><b></b></td>
                          <td><b></b></td>
                          <td><b></b></td>
                          <td><b></b></td>
                          <td><b></b></td>
                          <td><b>{{ this.totalPass }}</b></td>
                          <td><b>{{ this.totalFail }}</b></td>
                          <td><b>{{ this.totalHours }}</b></td>
                        </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-else class="pageCenter text-center mt-1">
                <span v-if="search.actionstartdate == null && search.actionenddate == null">Please apply dates filter to found the data!</span><br/>
                Data Not Found
            </div>
            <div class="pageCenter text-center pt-1">
                <pagination v-if="this.reportissuelist.length>0" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            </div>
          </div>
        </div>
        </section>
     </div>
   </div>
 </template>
 
 <script>
 import axios from 'axios';
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Loading from 'vue-loading-overlay';
 import DatePicker from "vue2-datepicker";
 import 'vue-loading-overlay/dist/vue-loading.css';
 import '@riophae/vue-treeselect/dist/vue-treeselect.css';
 import { SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
 import Treeselect from '@riophae/vue-treeselect'
 import moment from "moment";
 import Pagination from 'vue-pagination-2';
 import commonMethods from '../../utils/commonMethods';

 export default {
   name: 'TestCycleReport',
   mixins: [ commonMethods ],
   components: {
     VueElementLoading,
     Loading,
     SearchIcon,
     XIcon,
     ExternalLinkIcon,
     Treeselect,DatePicker,Pagination,
   },
   data() {
     return {
        isBothDateSelected:false,
        projectnameList:[],
        businesstasknameopts: null,
        search: {
          actionstartdate:null,
          actionenddate: null,
          qalead: null,
          qaeng: null,
          searchBusinesstaskname:null,
          searchProjectname: null,
        },
        qaleadlist:[],
        qaenglist: [],
        isLoading: false,
        undt: null,
        tokendata: null,
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        addformbtntxt:'Next',
        reportissuelist: [],
        dateArr: [],
        totalcount: 0,
        pageCount: 0,
        current_page: 1,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures:[],
        startDate: null,
        endDate:null,
        pagelimit:apiUrl.LIMIT,
        page:1,
        glbMdl:'',
        glbst:'',
        glbMdlLbl:'company',
        rowsSum: [],
        rowsReopenSum: [],
        totalPass: 0,
        totalFail: 0,
        totalHours: 0,
     }
   },
   created() {},
   mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.testCycleIssueReportList();
      }
    }
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      }); 
   },
   methods: {
      hoursFormate(hours){
        if(hours) {
          const formattedHours = (hours.hours!=undefined)?String(hours.hours).padStart(2, '0'):'00';
          const formattedMinutes = (hours.minutes!=undefined)?String(hours.minutes).padStart(2, '0'):'00';
          const formattedSeconds = (hours.seconds!=undefined)?String(hours.seconds).padStart(2, '0'):'00';
          return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
          return `00:00:00`;
        }
      },
      selectproject(state, value) {
        this.pageoffset=1
        if(!state){
          console.log('no project selected')
          this.qaleadlist=[];
            this.qaenglist= [];
        }else{
          this.search.searchProjectname = state;
          this.getProjectQAdata(478,state, null);
          this.getProjectQAdata(509,state, null);
          this.searchactiveflag = false;
        }
      },
      getProjectQAdata(roleid, state, node){
          let inputappl = {
              empcode: this.undt.userid,
              useremail: this.undt.username,
              node: (node != null && node != '' && node.length > 2)? node : null,
              roleid: roleid,
              projectidarr:((state != null && state.length > 0))? state : null,
          }
          if((node != null && node.length>=2) || (state != null && state.length > 0)) {
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                if(result.data.errorCode == 0){
                    if(roleid==509){
                        this.qaleadlist=result.data.data.rows;
                    }else if (roleid==478){
                        this.qaenglist=result.data.data.rows;
                    }
                }else{
                    if(roleid==509){
                        this.qaleadlist=[]
                    }else if (roleid==478){
                        this.qaenglist=[];
                    }
                }
            }).catch(e => {
    this.displayError(e)
    })
          } else {
            this.qaleadlist=[];
            this.qaenglist= [];
          }
      },
      selectedenddate() {
          if (this.search.actionstartdate && this.search.actionenddate) {
            this.isBothDateSelected = false;
            this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
          } else {
            this.isBothDateSelected = true;
          }
      },
      selectedstartdate() {
          if (this.search.actionstartdate && this.search.actionenddate) {
            this.isBothDateSelected = false;
            this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
          } else {
            this.isBothDateSelected = true;
          }
      },
      getDatesInRange(fromDate, toDate) {
          let dates = [];
          const current = new Date(fromDate);
          const end = new Date(toDate);
          const oneDay = 24 * 60 * 60 * 1000; 
          while (current <= end) {
              dates.push(current.toISOString().split('T')[0]);
              current.setTime(current.getTime() + oneDay);
          }
          return dates;
      },
      businessNameChangeSearch(node) {
        if(node != null && node != '' && node.length >= 5) {
            this.isLoading = true;
            let url = "api/reports/getBTCbyProject";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                node: node ? node.toLowerCase() : null,
                projectid: this.search.searchProjectname? this.search.searchProjectname : null,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.businesstasknameopts = result.data.data.rows;
                } else {
                    this.businesstasknameopts = [];
                }
            }).catch(e => {
    this.displayError(e)
    })
        } else {
          this.businesstasknameopts = [];
        }
      },
      clickCallback: function(pageNum) {
          this.pageoffset=pageNum
          this.currPageNum = pageNum - 1
          this.testCycleIssueReportList(this.search);
          setTimeout(function(){
              $('html, body').animate({
                  scrollTop: $("#scrollToListGrid").offset().top -70
              });
          }, 500);
      },
      testCycleIssueReportList(search, flag) {
        if(flag){
          this.pageoffset=1
          this.page=1
        }
        this.isLoading= true;    
        this.input = {}     
          if(search) {
            this.input = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                startDate: (search.actionstartdate!=null)?search.actionstartdate:this.startDate,
                endDate: (search.actionenddate!=null)?search.actionenddate:this.endDate,
                projectid: search.searchProjectname,
                qaeng: search.qaeng,
                qalead: search.qalead,
                btcid: search.searchBusinesstaskname,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
            }
          }
          axios({
              method: "POST",
              url: "api/btc/cycle/QAReportsDetails",
              data: this.input,
              headers: { authorization : this.tokendata },
          })
          .then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                let totalpass = 0;
                let totalfail = 0;
                let totalhours = [];
                result.data.data.rows.forEach(element => {
                  totalpass += element.totalpass
                  totalfail += element.totalfail
                  if(element.hours!=null){
                    totalhours.push(this.hoursFormate(element.hours))
                  }
                });
                this.totalPass = totalpass;
                this.totalFail = totalfail;
                this.totalofHOurs(totalhours);
                this.reportissuelist = result.data.data.rows;
                this.totalcount = result.data.data.count;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
              } else {
                this.reportissuelist = []
                this.totalcount = 0;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
              }
          }).catch(e => {
    this.displayError(e)
    })
      },
      totalofHOurs(arr) {
        const totalSeconds = arr.reduce((total, time) => {
        const [hours, minutes, seconds] = time.split(":").map(Number);
        return total + hours * 3600 + minutes * 60 + seconds;
        }, 0);

        const formattedHours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
        const formattedMinutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
        const formattedSeconds = String(totalSeconds % 60).padStart(2, '0');
        const formattedSum = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        this.totalHours = formattedSum
      },
      dateMONFormat(value){
        if (!value) return "";
        return moment(String(value)).format('DD-MMM');
      },
      dateMonthFormat(value) {
        if (!value) return "NA";
        return moment(String(value)).format('DD MMM YYYY');
      },
      projectChange: function (node, instanceId){
        if(node && node.length>1){
          this.pageoffset=1
          this.isLoading = true;
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            projectname:node.toLowerCase(),
            useremail: this.undt.username,
            empid: this.undt.userid,
            empcode: this.undt.userid,
            isfrompage: 'listingpage'
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.projectnameList = result.data.data;
            } else {
              this.projectnameList = [];
            }
          }).catch(e => {
    this.displayError(e)
    })
        }
      },  
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },   
      resetRecords: function() {
        this.search.searchBusinesstaskname = null
        this.search.actionstartdate=null
        this.search.actionenddate= null
        this.search.searchProjectname = null
        this.search.qalead = null
        this.search.qaeng = null
        this.qaleadlist=[];
        this.qaenglist= [];
        this.startDate = null;
        this.endDate = null;
        this.reportissuelist = [];
      },
      notBeforeToday: function (date) {
        this.search.actionenddate = null;
        return date > new Date(new Date().setHours(0, 0, 0, 0));     
      },
      disabledBefore(date) {
          const oneMonthFromFromDate = moment(this.search.actionstartdate).add(1, 'month');
          const startOfOneMonth = oneMonthFromFromDate.startOf('day').toDate();
          const startOfFromDate = moment(this.search.actionstartdate).startOf('day').toDate();
          return date > startOfOneMonth || date < startOfFromDate;
      },
      ExportXL(search) {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {}     
      if(search) {
          this.input = {
              empcode: this.undt.userid,
              useremail: this.undt.username,
              startDate: (search.actionstartdate!=null)?search.actionstartdate:this.startDate,
              endDate: (search.actionenddate!=null)?search.actionenddate:this.endDate,
              projectid: search.searchProjectname,
              qaeng: search.qaeng,
              qalead: search.qalead,
              btcid: search.searchBusinesstaskname,
              // offset:this.pageoffset,
              // limit:apiUrl.LIMIT,
          }
        }          
        axios({
            method: "POST",
            url: "api/btc/cycle/QAReportsDetails",
            data: this.input,
            headers: { authorization : this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            var filename = "";
            this.download(this.exportxlsdata, filename)
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }      
        }).catch(e => {
    this.displayError(e)
    })
      },
      download(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = 'QA_Report_'+filename;
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
            if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
              row = row.slice(0, -1)
              CSV = 'Sr#,Finished Date,QA Lead,QA Engineer,Cycle Code,Cycle Run Number,Sum of Total Pass,Sum of Total Fail,Sum of Time in Hours';
              CSV += '\r\n';
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
              let index = arrData[i]
              let srno = i+1
              let qalead = index.hasOwnProperty('qalead') ? index.qalead : 'NA';
              let qaeng = index.hasOwnProperty('qaeng') ? index.qaeng : 'NA';
              let cyclesetcode = index.hasOwnProperty('cyclesetcode') ? index.cyclesetcode : 'NA';
              let finishdate = index.hasOwnProperty('finishdate') ? index.finishdate : 'NA';
              let hours = index.hasOwnProperty('hours') ? index.hours : 'NA';
              let cyclerunno = index.hasOwnProperty('cyclerunno') ? index.cyclerunno : 'NA';
              let totalpass = index.hasOwnProperty('totalpass') ? index.totalpass : 'NA';
              let totalfail = index.hasOwnProperty('totalfail') ? index.totalfail : 'NA';
              
              row += '"' + srno + '",',
              row += '"' + this.dateMONFormat(finishdate) + '",',
              row += '"' + qalead + '",',
              row += '"' + qaeng + '",',
              row += '"' + cyclesetcode + '",',
              row += '"' + cyclerunno + '",',
              row += '"' + totalpass + '",'
              row += '"' + totalfail + '",'
              row += '"' + this.hoursFormate(hours) + '",'
              row += '\r\n'
            }
            row += ',,,,,,';
            row += '"' + this.totalPass + '",',
            row += '"' + this.totalFail + '",'
            row += '"' + this.totalHours + '",'
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            var fileName = 'QA_REPORT_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report Successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });      
        }
      },
      export (data, filename, mime) {
        var data
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
        download(blob, filename, mime)
      },
   }
 }
 </script>
 <style> 
 .view_bg-gradient-primary{
   background: #7DA3F9!important;
 }
 </style>