<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Employee Activity Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name"  v-model="search.searchProjectname" :options="projectnameList" @input="selectproject" :multiple="true" :clear-on-select="true"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                        <label for="allotedto">Employee Name</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Employee Name" v-model="search.allotedto" :multiple="true" :clear-on-select="true" :options="search.allotedtolist" @input="selassignedto" v-on:search-change="getEmpListByProjectAssigned"/>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Reporting Manager Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="mngrlist" v-if="pagefeatures.activityreportmanager" :disabled="true"/>

                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="mngrlist" v-on:search-change="getallmanagerlistnewapi" v-else/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <label class="form-label lable-left">Activity</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Activity"  v-model="search.searchactivity" :options="activityList"   :multiple="true" :clear-on-select="true"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="date_picker_foundeds">
                        <label for="">LogTime Date From</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select Logtime Date From" v-model="search.taskassigneddatefrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                          <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <div class="date_picker_foundeds">
                        <label for="">LogTime Date To</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select Logtime Date To" v-model="search.taskassigneddateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right ">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getBusinessTaskReport(search,'flagValue')"><i class="custom-class las la-at" style="font-size: 15px;"></i><span>Mail Me</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1 mobile_margin-px-2" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll" style="display:none;">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Employee Activity Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div class="" id="home"  role="tabpanel"  aria-labelledby="home-tab">
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky"  v-if="showtabledata">
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Employee Activity Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno align-middle first-col-sticky" style="min-width:45px">Sr#</th>
                                      <th scope="col" class="align-middle second-col-sticky">Project Name</th>
                                      <th scope="col" class="align-middle">LogTimeDate</th>
                                      <th scope="col" class="align-middle">Employee Name</th>
                                      <th scope="col" class="align-middle">Reporting Manager</th>
                                      <th scope="col" class="align-middle">Activity</th>
                                      <th scope="col" class="align-middle">Hours</th>
                                      <th scope="col" class="align-middle">Module Type ID</th>
                                      <th scope="col" class="align-middle">Module Name</th>
                                      <th scope="col" class="align-middle">Module Type</th>
                                      <th scope="col" class="align-middle">Business Task Code</th>
                                      <th scope="col" class="align-middle">Business Task Name</th>
                                      <th scope="col" class="align-middle">Comment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                        <td :class="'capitalisetext  font-weight-normal second-col-sticky cursorpointercls sitebluecolor unique'+index+1+currPageNum*pagelimit"  @click="redirecttourl('projectId',data)">
                                          <span  class="font-weight-normal" >{{data.projectname}}</span>
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          {{data.logdate?dateFormat(data.logdate):''}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                            {{data.username}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.reportingmanager ? data.reportingmanager:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.activity}}
                                        </td>
                                        <td class="font-weight-normal">
                                          {{data.spenttime ?data.spenttime.slice(0,5):'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.code?data.code:'NA'}}
                                        </td>
                                        <td class="font-weight-normal">
                                          <span v-if="!data.modulename == null && data.modulename.length>=150"> {{ data.modulename.substring(0,150)+".." }}</span>
                                          <span v-else> {{ data.modulename ? data.modulename:'NA'  }}</span>
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.moduletype}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.business_code?data.business_code:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.taskname?data.taskname:'NA'}}
                                        </td>
                                         <td class="font-weight-normal">
                                          <span v-if="data.coments && data.coments.length>=150" v-html="data.coments.substring(0,150)+'..'" ></span>
                                           <span v-else v-html="data.coments"></span>
                                        </td>
                                        
                                      </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0 && isresponce">
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2 text-center">
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { EyeIcon,SearchIcon,XIcon,ExternalLinkIcon,UserIcon,LinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';
export default {
  name: "ActivityReport",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker,
    UserIcon,
    LinkIcon
  },
  mixins: [ commonMethods ],

  data() {
    return {
      isBothDateSelected:false,
      projectnameList:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      isSearchRequestActive:true,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      search:{
        searchProjectname :null,
        searchTaskStatus:null,
        allotedto:null,
        allotedtolist:[],
        taskassigneddateto:null,
        taskassigneddatefrom:null,
        mngrname:null,
        searchactivity:null
          
      },
      tokendata:null,
      mngrlist:[],
      isresponce:false,
      activityList:apiUrl.logacivitylist.sort((a, b) => a.label.localeCompare(b.label)),
    };
  },
  created() {
    this.pagefeatures = {}
    if(window.localStorage.getItem('pagefeatures')){
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
            if (element.page === '/reports/activityreport') {
              this.pagefeatures[element.featurename] = element.featureaccess
            }
        })
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        if(this.pagefeatures.activityreportmanager){
          this.getManagerList();
          this.search.mngrname = [parseInt(this.undt.userid)]
        }
        this.projectChange()
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    notBeforeToday: function (date) {
      this.search.taskassigneddateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.taskassigneddatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; // Assume < 25 May Not Selected
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
        companymasterid: this.search.companyname,
        businessmasterid: this.search.businessname,
        empcode: this.undt.userid,
      }
      axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          if(result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = null
          }
        }).catch(e => {
        this.displayError(e)
      });
    },
    selectproject(state, value) {
      this.pageoffset=1
      if(!state){
        console.log('no project selected')
      }else{
        this.search.searchProjectname = state;
      }
    }, 
    projectChange: function (){
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:null,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });;
    },
    getManagerList() {
      this.pageoffset=1
      axios({
        'method': 'POST',
        'url': 'api/searching/getmanagerlist',
        'headers':{'authorization':this.tokendata},
        'data': {
          mngrid:null,
          useremail: this.undt.username,
          empcode: this.undt.userid
        }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.mngrlist = result.data.data;
          let decryptdepartmenttext = this.mngrlist.map(items =>{

            let tempemplabel1 = items.label;
            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

            if(tempemplabel2 && tempemplabel2 != null){
              items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        } else {
          this.mngrlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getallmanagerlistnewapi(node) {
      if (node && node.length>=3) {
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/searching/getallmanagerlistnewapi',
          'headers':{'authorization':this.tokendata},
          'data': {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            mngname: node.toLowerCase(),
          }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.mngrlist = result.data.data;
            let decryptdepartmenttext = this.mngrlist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.mngrlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
     selectedstartdate(){
      if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    selectedenddate(){
       if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    getBusinessTaskReport(flag = false,...search){
      if(flag) {
        this.pageoffset = 1
        this.companymasterlist = []
        this.totalcount = 0;
        this.pageCount = 0;
        this.page= 1;
      } 
        this.isLoading = true;
        let url = "api/reports/emphierarchyreport";
        this.input = {
            offset: null,
            limit: null,
            useremail: this.undt.username,
            mangerid:this.search.mngrname,
            empcode: this.undt.userid
        };
        let assignedate = ""
        if(this.search.taskassigneddateto){
          assignedate = new Date(this.search.taskassigneddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
        this.input.empid = this.search.allotedto?this.search.allotedto:null
        this.input.datefrom = this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:null
        this.input.dateto = this.search.taskassigneddateto?assignedate:null
        this.input.activity = this.search.searchactivity ? this.search.searchactivity:null

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
            Swal.fire({
              title: "Success!",
              text: "Export file link generated on your email",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }  else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
            title: "",
            text: "Activity report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
      this.search.searchTaskStatus = null;
      this.search.allotedto = null;
      this.search.allotedtolist =[];
      this.search.taskassigneddateto = null;
      this.search.taskassigneddatefrom = null;
      this.isBothDateSelected = false
      let rowdata={}
      this.search.searchactivity = null
      if(!this.pagefeatures.activityreportmanager){
        this.search.mngrname=null;
        this.mngrlist = [];
        this.search.searchProjectname = null;
      }
      localStorage.removeItem('searchItems')
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (btnstate == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid
        })
      }
      // let redirectmdlnm='Project Module'
      // let redirectmdlurl='/projectmodule'
      // if(btnstate == 'addapplication'){
      //   redirectmdlnm='Project Application'
      //   redirectmdlurl='/application'
      // }
      // this.$router.push({
      //   name: redirectmdlnm,
      //   path: redirectmdlurl,
      //   params: {
      //     backbtnstate: btnstate,
      //     clickeddata: rowdata,
      //   },
      // });
    },
    getFilteredData: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "company",
        isFilterSelect: 1,
        isFilterPayload: {},
        empcode: this.undt.userid
      };

      if (this.search.searchCompany != null) {
        this.input.isFilterPayload.companyid = this.search.searchCompany;
      } 
      if (this.search.createdby != null) {
        this.input.isFilterPayload.createdby = this.search.createdby;
      }

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.showcompanies = true
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    
    getcomapnylist: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "project",
        isFilterSelect: 0,
        isFilterPayload: {},
        empcode: this.undt.userid
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.parentmoduledata={}
        this.parentmoduledata.isPaginationreq=true
        this.getBusinessTaskReport(false,this.parentmoduledata);

      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    masterlist(mdl, objThis) {
      let setmoduleform = "projectmaster";
      let glbMdlLblval = "Project";
      if (this.glbst && mdl == "") {
        mdl = this.glbst;
      }
      $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
      this.companymasterlist = [];
      this.isProjectSel = 0;
      this.isProjectMsr = 0;
      this.viewprojectlist = 0;
      this.isProjectRoleSel = 0;
      this.isRoleAlloc = 0;
      let tblNm = "t11";
      if (mdl) tblNm = mdl;
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
      };
      this.input.isSearch = false;

      if (typeof objThis != "undefined" && objThis != "") {
        if (
          objThis.currentTarget.dataset.clkstate == "search" &&
          $("#glbsearchinpele").val() != ""
        ) {
          this.input.name = $("#glbsearchinpele").val().trim();
          this.input.isSearch = true;
        }
      }
      let url = "api/master/commonmaster/list";
      if (tblNm == "t11") {
        this.getApplicationdata("t3", "project-tab");
        this.getApplicationdata("t2", "project-tab");
        this.getApplicationdata("t1", "project-tab");
        url = "api/master/commonmaster/customselect";
        this.isProjectMsr = 1;
        this.input.section = "assignment";
        this.input.isFilterSelect = 0;
        this.input.isFilterPayload = {};
      }
      this.input.empcode= this.undt.userid
      this.input.useremail = this.undt.username
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (
            this.isProjectSel == 1 ||
            this.isProjectMsr == 1 ||
            this.isProjectRoleSel == 1
          ) {
            this.companymasterlist = result.data.data.rows;
            this.totalcount = result.data.data.count;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          } else {
            this.companymasterlist = [];
            this.totalcount = 0;
          }
          
          setTimeout(function () {
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.totalcount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
       timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1]/6)*10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
}   ,
    getApplicationdata(mdl, curracttab) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        empcode: this.undt.userid,
        useremail: this.undt.username
      };
      let apiURL = "api/master/commonmaster/dropdownlist";
      if (mdl == "t35") {
        apiURL = "api/master/commonmaster/customselect";
        this.inputappl.isFilterPayload = {};
        this.inputappl.isFilterSelect = 0;
        this.inputappl.isSearch = false;
        this.inputappl.section = "assignment";
      }
      if (
        curracttab == "project-tab" ||
        curracttab == "projectroleassign-tab"
      ) {
        this.inputappl = {
          tbl: mdl,
        };
      }
        this.inputappl.empcode= this.undt.userid,
        this.inputappl.useremail= this.undt.username

      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectType = [];
          this.projectType = result.data.data.rows;
          let tmpClassNm = "";
          if (mdl == "t2") {
            tmpClassNm = "devBusinessVerticleData";
            this.project.businesslist = result.data.data.rows;
            this.search.businessnameList = result.data.data.rows;
            return true;
          }else if (mdl == "t1") {
            tmpClassNm = "companyname";
            this.project.companylist = result.data.data.rows;
            this.search.companynameList = result.data.data.rows
            return true;
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
      
    },
    seltaskstatus(state, value) {
      if(!state) {
        console.log('Task status required')
      } else {
        console.log('selected')
      }
    },  
    getEmpListByProjectAssigned(node) {
      if(node && node.length >= 3){
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/listing/getmanagerwiseuser',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.allotedtolist = []
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    selassignedto(state, value) {
      if(!state) {
        this.search.allotedto=null
      } else {
        this.search.allotedto=state
      }
    },

    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        
        useremail: this.undt.username,
        mangerid:this.search.mngrname,
        empcode: this.undt.userid,
      };
      let assignedate = ""
       if(this.search.taskassigneddateto){
          assignedate = new Date(this.search.taskassigneddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
        this.input.empid = this.search.allotedto?this.search.allotedto:null
        this.input.datefrom = this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:null
        this.input.dateto = this.search.taskassigneddateto?assignedate:null
        this.input.activity = this.search.searchactivity?this.search.searchactivity:null

      axios({
        method: "POST",
        url: `api/reports/emphierarchyreport`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
        }
      }).catch(e => {
        this.displayError(e)
      });;
    },
    download(readExportdata,filename) {
        if (readExportdata.length > 0) {
          var ShowLabel = 'EmployeeActivityReport'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
            if (ShowLabel) {
                let row = ''
                for (let index in arrData[0]) {
                    row += index + ','
                }
                row = row.slice(0, -1)
                CSV = 'Sr#,Project Name,LogTimeDate,Employee Name,Reporting Manager,Activity,Hours,Module Type ID,Module Name, Module Type,Business Task Code,Business Task Name,Comment,Round Of Time' + '\r\n'
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname == 'NA' ? '' : index.projectname.replace(/"/g, '""'): '';
            let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate == null || index.lastmodifieddate == 'NA' ? '' : index.lastmodifieddate : '';
            let username = index.hasOwnProperty('username') ? index.username == null || index.username == 'NA' ? '' : index.username : '';
            let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager == null?'' :index.reportingmanager : '';
            let activity = index.hasOwnProperty('activity') ? index.activity == null || index.activity == 'NA' ? '' : index.activity : '';
            let spenttime = index.hasOwnProperty('spenttime') ? index.spenttime ==null || index.spenttime.slice(0,5) == 'NA'?'' : index.spenttime.slice(0,5) : '';
            let modulename = index.hasOwnProperty('modulename') ? index.modulename == null || index.modulename == 'NA'?'':index.modulename.replace(/"/g, '""') : '';
            let modulecode = index.hasOwnProperty('code') ? index.code == null || index.code == 'NA' ?'':index.code : '';
            let business_code = index.hasOwnProperty('business_code') ? index.business_code  == null || index.business_code == 'NA' ?'':index.business_code : '';
            let taskname = index.hasOwnProperty('taskname') ? index.taskname  == null || index.taskname == 'NA' ?'':index.taskname : '';
            let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype == null || index.moduletype == 'NA' ?'':index.moduletype : '';    
            let coments = index.hasOwnProperty('coments') ? index.coments == null || index.coments == 'NA' ?'':index.coments.replace(/["“”]/g, '""') : '';  
            let newcoments = coments.replace(/(<([^>]+)>)/gi, "")
            newcoments = newcoments.replace(/&nbsp;/g," ")
            newcoments = newcoments.replace(/&amp;/g,"&")
            lastmodifieddate = this.dateMONFormat(lastmodifieddate)
              let newtime = this.timeToDecimal(spenttime)
                row += '"' + srno + '",',
                row += '"' + projectname + '",',
                row += '"' + lastmodifieddate + '",',
                row += '"' + username + '",',
                row += '"' + reportingmanager + '",',
                row += '"' + activity + '",',
                row += '"' + spenttime + '",',
                row += '"' + modulecode + '",',
                row += '"' + modulename + '",',
                row += '"' + moduletype + '",',
                row += '"' + business_code + '",',
                row += '"' + taskname + '",',
                row += '"'+ newcoments + '",',
                 row += '"' + newtime + '"',
                row += '\r\n'
            }
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            return;
            }
            var fileName = 'ActivityReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            });
        } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });   
        }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    showprojectname(prname){
      let responsestr='';
      let prlist=(prname)?prname.split(','):[];
      if(prlist.length > 1){
        for(var i=0;i<prlist.length;i++){
            responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
        }
      }else{
        for(var i=0;i<prlist.length;i++){
            responsestr +=prlist[i]
        }
      }
      return responsestr
    },
  },
};
</script>
