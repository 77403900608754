<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Feedback Form</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">Feedback Form</li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'FeedbackFormListing', params: { search: true }}">Feedback Forms List </router-link></li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">{{formheadname}}</li>
                        </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header py-1 px-2" style="background-color: #f1f2f3;">
                  <h3 class="card-title">
                    {{formheadname}}
                  </h3>
                </div>
                <div>
                    <div class="card-body">
                        <form class="form form-vertical">
                            <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi  business_fieldset_bg">
                                <legend>Employee Details</legend>
                                <div class="card-body pt-1 px-0">
                                    <div class="mb-1 px-0">
                                        <div class="table-responsive" style="overflow-x: inherit !important;">
                                            <table aria-describedby="cyclehistorytbl" id="cyclehistorytbl" class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                    <th scope="col" class="text-center">Employee ID</th>
                                                    <th scope="col" class="text-center">Employee Name</th>
                                                    <th scope="col" class="text-center">Date Of Joining</th>
                                                    <th scope="col" class="text-center">designation</th>
                                                    <th scope="col" class="text-center">Reporting Manager</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">
                                                            {{ this.employeedetails.empid }}
                                                        </td>
                                                        <td class="text-center capitalisetext">
                                                            {{ this.employeedetails.empname }}
                                                        </td>
                                                        <td class="text-center capitalisetext">
                                                            {{ this.employeedetails.joiningdate?dateFormat(this.employeedetails.joiningdate):'NA' }}
                                                        </td>
                                                        <td class="text-center capitalisetext">
                                                            {{ this.employeedetails.designation }}
                                                        </td>
                                                        <td class="text-center capitalisetext">
                                                            {{ this.employeedetails.mngrid }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="!this.employeedetails || this.employeedetails==[]"><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <div class="col-md-12 text-left">
                                <span class="required-field"> All fields marked with asterisk are required.</span>
                            </div>
                            
                            <div class="col-md-12 mt-2 px-0">
                                <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bgfieldset_border_vi  business_fieldset_bg">
                                <legend>Employee Feedback</legend>
                                <div class="row">
                                    <div class="col-md-4" v-for="(fieldname,i) in this.fieldnamelist" v-bind:key="i">
                                        <div class="form-group mb-1" v-if="fieldname.fieldheader==null">
                                            <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                            <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                            <span v-if="fieldname.fieldtype=='text'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateForm($event)"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validateForm($event)"
                                                v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled/>

                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateForm($event)"
                                                v-bind:class="{ 
                                                'form-control': true,
                                                '': !validText(fieldsnamevalues[fieldname.id])}"
                                                @close="validateForm($event)"
                                                v-else/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                                </p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='number'">
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateForm($event)"
                                                v-bind:class="{
                                                'form-control': true,
                                                '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                @close="validateForm($event)"
                                                v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled/>
                                                
                                                <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                @blur="validateForm($event)"
                                                v-bind:class="{
                                                'form-control': true,
                                                '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                @close="validateForm($event)"
                                                v-else/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='drop-down'">
                                               <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalu1"/>
                                               <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue2" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='checkbox'">
                                                <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && (!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='radio'">
                                                <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                    <div class=" form-check form-check-inline mt-0 mb-0">
                                                        <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="Object.values(radiofieldvalues)[0]" > <span class="" style="margin-left:5px; font-size: 14px;"> {{Object.values(radiofieldvalues)[0]}} </span>
                                                    </div>
                                                </span>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='date'">
                                                <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                class="startdate capitalisetext" id="EndDate" :minute-step="5" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></date-picker>
                                                <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                class="startdate capitalisetext" id="EndDate" :minute-step="5" v-else></date-picker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                                <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                                <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                            </span>
                                            <span v-if="fieldname.fieldtype=='toggle'">
                                                <div class="custom-control custom-switch custom-switch-secondary">
                                                    <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+fieldname.id" v-model="fieldsnamevalues[fieldname.id]"/>
                                                    <label class="custom-control-label" :for="'customSwitch11'+fieldname.id">
                                                        <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[0].label}}</label></span>
                                                        <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{fieldname.optionalvalue3[1].label}}</label></span>
                                                    </label>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                </fieldset>
                                <!-- Header format code start -->
                                <span v-if="Object.keys(tempheader).length>0">
                                    <div class="mt-1" v-for="(headers,ind) in tempheader" v-bind:key="ind">
                                        <div class="tab-content mb-2 d-inline-block w-100" >
                                            <div role="tabpanel" class="tab-pane active" id="faq-payment" aria-labelledby="payment" aria-expanded="true">
                                                <div class="collapse-margin collapse-icon mt-1" :id="'faq-payment-qna'+headers[0].id">
                                                    <div class="card resolved rounded" style="border: 1px solid #eee!important;box-shadow: none!important;">
                                                        <div class="card-header" :id="'paymentOne'+headers[0].id"  role="button" :data-target="'#faq-payment-one'+headers[0].id" aria-expanded="false" aria-controls="faq-payment-one">
                                                            <span class="lead collapse-title">{{ ind }}</span>
                                                        </div>
                                                        <div :id="'faq-payment-one'+headers[0].id"  :aria-labelledby="'#paymentOne'+headers[0].id" :data-parent="'#faq-payment-qna'+headers[0].id">
                                                            <div class="card-body">
                                                                <div class="mb-1 px-0" >
                                                                    <div class="table-responsive" style="overflow-x: inherit !important;">
                                                                        <div class="col-md-12 mt-2">
                                                                          <div class="row">
                                                                                <div class="col-md-4" v-for="(head,indx) in headers" v-bind:key="indx">
                                                                                    <div class="form-group mb-1">
                                                                                        <label for="projectlistdd" class="required-field" v-if="head.validationtype=='required'">{{head.label}}:</label>
                                                                                        <label for="projectlistdd" v-else>{{ head.label }}:</label>
                                                                                        <span v-if="head.fieldtype=='text'">
                                                                                            <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                            @blur="validateForm($event)"
                                                                                            v-bind:class="{
                                                                                            'form-control': true,
                                                                                            '': !validText(fieldsnamevalues[head.id])}"
                                                                                            @close="validateForm($event)"
                                                                                            v-if="(head.validationtype=='readonly' || head.validationtype=='hidden')" disabled/>

                                                                                            <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                            @blur="validateForm($event)"
                                                                                            v-bind:class="{
                                                                                            'form-control': true,
                                                                                            '': !validText(fieldsnamevalues[head.id])}"
                                                                                            @close="validateForm($event)"
                                                                                            v-else/>
                                                                                            <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && !validText(fieldsnamevalues[head.id])" style="min-width:100%;">{{head.fielderrormsg}}</span>
                                                                                            </p>
                                                                                        </span>
                                                                                        <span v-if="head.fieldtype=='number'">
                                                                                            <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                                                            pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                                                            :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                            @blur="validateForm($event)"
                                                                                            v-bind:class="{
                                                                                            'form-control': true,
                                                                                            '': !validText2(fieldsnamevalues[head.id])}"
                                                                                            @close="validateForm($event)"
                                                                                            v-if="(head.validationtype=='readonly' || head.validationtype=='hidden')" disabled/>

                                                                                            <input :type="head.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                                                            pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                                                            :placeholder="head.fieldplaceholder" v-model="fieldsnamevalues[head.id]"
                                                                                            @blur="validateForm($event)"
                                                                                            v-bind:class="{
                                                                                            'form-control': true,
                                                                                            '': !validText2(fieldsnamevalues[head.id])}"
                                                                                            @close="validateForm($event)"
                                                                                            v-else/>
                                                                                            <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && !validText2(fieldsnamevalues[head.id])">{{head.fielderrormsg}}</span></p>
                                                                                        </span>

                                                                                        <span v-if="head.fieldtype=='drop-down'">
                                                                                            <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[head.id]" :options="head.optionalvalu1"/>
                                                                                            <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                        </span>

                                                                                        <span v-if="head.fieldtype=='multiselect-dropdown'">
                                                                                            <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[head.id]" :options="head.optionalvalue2" @input="selectinput($event)" :multiple="true" :clear-on-select="true"/>
                                                                                            <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && (!fieldsnamevalues[head.id] || fieldsnamevalues[head.id].length==0)">{{head.fielderrormsg}}</span></p>
                                                                                        </span>

                                                                                        <span v-if="head.fieldtype=='checkbox'">
                                                                                            <treeselect :placeholder="head.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[head.id]" :options="head.optionalvalue" @input="selectinput($event)" :multiple="true" :clear-on-select="true"/>
                                                                                            <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && (!fieldsnamevalues[head.id] || fieldsnamevalues[head.id].length==0)">{{head.fielderrormsg}}</span></p>
                                                                                        </span>

                                                                                        <span v-if="head.fieldtype=='radio'">
                                                                                            <span v-for="(radiofieldvalues,k) in head.radiooptionalvalue" v-bind:key="k"><br />
                                                                                                <div class=" form-check form-check-inline mt-0 mb-0">
                                                                                                    <input class="form-check-input" type="radio" v-model="fieldsnamevalues[head.id]" :value="Object.values(radiofieldvalues)[0]" > <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                                                                </div>
                                                                                            </span>
                                                                                            <p><span class="errorinputmsg capitalisetext"  v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                        </span>

                                                                                        <p><span v-if="head.fieldtype=='date'" >
                                                                                            <date-picker :placeholder="head.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[head.id]" valueType="format"
                                                                                            class="startdate capitalisetext" id="EndDate" :minute-step="5" v-if="(head.validationtype=='readonly' || head.validationtype=='hidden')" disabled></date-picker>
                                                                                            <date-picker :placeholder="head.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[head.id]" valueType="format"
                                                                                            class="startdate capitalisetext" id="EndDate" :minute-step="5" v-else></date-picker>
                                                                                            <p><span class="errorinputmsg capitalisetext"  style="min-width:100%;" v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                        </span></p>

                                                                                        <span v-if="head.fieldtype=='datetime-local'">
                                                                                            <vue-timepicker class="startdate" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[head.id]" v-if="(head.validationtype=='readonly' || head.validationtype=='hidden')" disabled></vue-timepicker>
                                                                                            <vue-timepicker class="startdate" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[head.id]" v-else></vue-timepicker>
                                                                                            <p><span class="errorinputmsg capitalisetext" v-if="head.validationtype=='required' && fieldsnamevalues[head.id]==null">{{head.fielderrormsg}}</span></p>
                                                                                        </span>
                                                                                        <span v-if="head.fieldtype=='toggle'">
                                                                                            <div class="custom-control custom-switch custom-switch-secondary">
                                                                                                <input type="checkbox" class="custom-control-input" :id="'customSwitch11'+head.id" v-model="fieldsnamevalues[head.id]"/>
                                                                                                <label class="custom-control-label" :for="'customSwitch11'+head.id">
                                                                                                    <span class="switch-icon-left"><label for="check" style="font-size: 0.857rem;">{{head.optionalvalue3[0].label}}</label></span>
                                                                                                    <span class="switch-icon-right"><label for="uncheck" style="font-size: 0.857rem;">{{head.optionalvalue3[1].label}}</label></span>
                                                                                                </label>
                                                                                            </div>
                                                                                        </span>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                <!-- Header format code end -->
                            </div>

                        </form>
                        <div class="col-12 mg-top1 text-right">
                            <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard($event)" id="submitbtnt39">Submit</button>
                            <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Reset</button>
                            <router-link :to="{ name: 'FeedbackFormListing', params: { search: true }}">  <button style="margin-left:8px;" type="cancel" class="btn btn-outline-secondary">Cancel</button></router-link>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </div>        
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}

.required-field::before{
    content: '';
} 
.required-field::after {
    content: "*";
    color: red;
    font-size: 16px;
    position: relative;
    top: 4px;
    left: 2px;
}
.form-group .mx-datepicker{
  min-width: 100%!important;
}
.tab-content #paymentOne309{
    background-color: #e8e8e8!important;
    border-radius: 0px;
    text-transform: capitalize;
}

</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMethods from '../../utils/commonMethods';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueTimepicker from 'vue2-timepicker'

export default {
    name:'FeedbackForm',
    components:{
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,DatePicker,VueTimepicker,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
    },    
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            TimeFormat: apiUrl.TimeFormat,
            statuslist: apiUrl.tcscriptstatuslist,
            moduletrackerid: null,
            fieldnamelist: [],
            isAddState:false,
            pagefeatures:[],
            tokendata:null,
            fieldsnamevalues:{},
            formheadname: 'Feedback Form',
            trackerid: this.$route.params.clickeddata.trackerid,
            masterid: this.$route.params.clickeddata.id,
            employeedetails: [],
            tempheader: {}
        }
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.getemployeedetails();
            if (this.$route.params.backbtnstate) {
                this.formheadname = this.$route.params.clickeddata.moduletype
                this.getorientationfeedbackformlabels(this.$route.params.clickeddata.trackerid)
            }else if(Object.values(this.$route.query).length==0){
                this.$router.push({ name: 'FeedbackFormListing', params: { search: true }})
            }
        }
    },
    methods:{
        getemployeedetails(){
            this.isLoading = true;
            this.inputfield= {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getemployeedetails',
                'data': this.inputfield,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                  this.employeedetails = result.data.data.rows
                  this.employeedetails=this.employeedetails[0]
                }else{
                  this.employeedetails=[]
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        selectinput(state,index,datax) {

        },
        getorientationfeedbackformlabels(trackerid) {
        this.isLoading = true
        let url = 'api/feedbackform/getorientationfeedbackformlabels'
        let payload = new Object({
            empcode: this.undt.userid,
            useremail  : this.undt.username,
            moduletracker: trackerid
        })
        axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
        }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.fieldnamelist = result.data.data
                    var temArray = {};
                    for (let i = 0; i < this.fieldnamelist.length; i++) {

                        // header logic start
                        if (this.fieldnamelist[i].fieldheader!=null) {
                            if(!temArray.hasOwnProperty(this.fieldnamelist[i].fieldheader)){
                                temArray[this.fieldnamelist[i].fieldheader] = [];
                            }
                            var pushObj = this.fieldnamelist[i];
                                temArray[this.fieldnamelist[i].fieldheader].push(pushObj);
                        }
                        // header logic end                  

                        if (this.fieldnamelist[i].fieldtype=="checkbox") {
                            let tempdata = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata[j] = {}
                                    tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue']=tempdata
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="radio") {
                            let tempdata2 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata2[j] = {}
                                    tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="drop-down") {
                            let tempdata3 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata3[j] = {}
                                    tempdata3[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata3[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalu1']=tempdata3
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="multiselect-dropdown") {
                            let tempdata4 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata4[j] = {}
                                    tempdata4[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata4[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue2']=tempdata4
                            }
                        }
                        if (this.fieldnamelist[i].fieldtype=="toggle") {
                            let tempdata5 = [];
                            for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                    tempdata5[j] = {}
                                    tempdata5[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                    tempdata5[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                }
                                this.fieldnamelist[i]['optionalvalue3']=tempdata5
                            }
                        }
                    }
                    this.tempheader=temArray
                }else if(result.data.errorCode == -1){
                    Swal.fire({
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.$router.push({ name: 'FeedbackFormListing', params: { search: true }})
                }
                 else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.fieldnamelist = [];
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        myconversion(x) {
            // :id="'faq-payment-qna'+headers[0].id"
            x = x.split(" ").join("")
            return x
        },
        resetForm() {
            this.fieldsnamevalues = {}
        },
        savewizard(objThis){
            let valid = this.validateForm(objThis);
            if (valid) {
                this.isLoading = true;  
                //toggle default value assignment start
                for (let i = 0; i < this.fieldnamelist.length; i++) {
                    if(this.fieldnamelist[i].fieldtype=='toggle'){
                        if (this.fieldsnamevalues[this.fieldnamelist[i].id] == undefined || this.fieldsnamevalues[this.fieldnamelist[i].id] == undefined) {
                            this.fieldsnamevalues[this.fieldnamelist[i].id] = this.fieldnamelist[i].optionalvalue3[0].label
                        }else if (this.fieldsnamevalues[this.fieldnamelist[i].id] ==true) {
                            this.fieldsnamevalues[this.fieldnamelist[i].id] = this.fieldnamelist[i].optionalvalue3[1].label
                        }else if (this.fieldsnamevalues[this.fieldnamelist[i].id] ==false) {
                            this.fieldsnamevalues[this.fieldnamelist[i].id] = this.fieldnamelist[i].optionalvalue3[1].label
                        }
                    }
                }
                //toggle default value assignment end
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    fieldvalues: this.fieldsnamevalues,
                    trackerid: this.trackerid,
                    id: this.masterid,
                }
                let apiURL='api/feedbackform/savefieldwisedata'
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resetForm();
                        this.$router.push({ name: 'FeedbackFormListing', params: { search: true }});
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            }
        },
        validateForm(objThis){
            this.isAddState=false
            let isValid=true;
            for (let i=0; i<this.fieldnamelist.length; i++) {

                //Timepicker HH:mm value assignment start
                if (this.fieldnamelist[i].fieldtype=='datetime-local') {

                    if (typeof this.fieldsnamevalues[this.fieldnamelist[i].id] == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                        isValid=false;
                    }else{
                        let hours = this.fieldsnamevalues[this.fieldnamelist[i].id]
                        let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                            if (this.fieldsnamevalues[this.fieldnamelist[i].id].HH=='' || this.fieldsnamevalues[this.fieldnamelist[i].id].mm=='') {
                                Swal.fire({
                                    title: "Failed",
                                    text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                            if (this.fieldsnamevalues[this.fieldnamelist[i].id][1]=='H' || this.fieldsnamevalues[this.fieldnamelist[i].id][4]=='m') {
                                isValid=false;
                                Swal.fire({
                                    title: "Failed",
                                    text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                        if(typeof hours === 'string' && hours.length === 5){
                            isValidHours = true
                        }
                        if(isValidHours && hours !== null && typeof hours === 'object') {
                            this.fieldsnamevalues[this.fieldnamelist[i].id] = `${hours.HH}:${hours.mm}`
                        }
                    }

                }
                //Timepicker HH:mm value assignment end

                if (this.fieldnamelist[i].fieldtype != 'toggle') {
                    
                    if (this.fieldnamelist[i].validationtype=='required') {
                        if (this.fieldsnamevalues[this.fieldnamelist[i].id] == '' || this.fieldsnamevalues[this.fieldnamelist[i].id] == null || this.fieldsnamevalues[this.fieldnamelist[i].id] == []) {
                            isValid=false;
                            if (this.fieldnamelist[i].fieldtype=='datetime-local' && this.fieldsnamevalues[this.fieldnamelist[i].id] == '') {
                                Swal.fire({
                                    title: "Failed",
                                    text: this.fieldnamelist[i].label+' '+'is required',
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                            if (this.fieldsnamevalues[this.fieldnamelist[i].id][1]=='H' || this.fieldsnamevalues[this.fieldnamelist[i].id][4]=='m') {
                                isValid=false;
                                Swal.fire({
                                    title: "Failed",
                                    text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            }
                        }
                        if (typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                            isValid=false;
                        } 
                        else{
                            if(this.fieldsnamevalues[this.fieldnamelist[i].id].length < 0) {
                            isValid=false;
                        }
                        }
                    }
                }
            }
            return isValid
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                    return re.test(inputval.toLowerCase());
            }
        },
        validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{1,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
    },
    filters: {
      basename: function (value) {
          if (!value) return ''
          return value.substr(value.lastIndexOf("/") + 1)
      }
    }
}
</script>