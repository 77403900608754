<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0 ">
                <h3 class="content-header-title float-left mb-0">Dept Business Task Summary</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/reports/businesstaskdeptmaster'>Dept Business Task Summary</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" id="domain-list">
          <div class="card-body p-0 ps ps--active-x">
            <div class="table-responsive" >
              <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc" style="border-collapse:collapse;">
                <thead>
                   <tr>
                      <th scope="col" class="align-middle text-center first-col-sticky make-bold" style="min-width:140px;">Year/Week/Dept</th>
                      <th scope="col" class="align-middle text-center">PRODUCT PIPELINE</th>
                      <th scope="col" class="align-middle text-center">PRODUCT COMPLETE</th>
                      <th scope="col" class="align-middle text-center">TECH AND BUSINESS REVIEW</th>
                      <th scope="col" class="align-middle text-center">DEV PIPELINE</th>
                      <th scope="col" class="align-middle text-center">QA PIPELINE</th>      
                      <th scope="col" class="align-middle text-center">Design Review</th>                             
                      <th scope="col" class="align-middle text-center">UAT</th>
                      <th scope="col" class="align-middle text-center">UAT SIGNOFF</th>
                      <th scope="col" class="align-middle text-center">COMPLETED</th>
                      <th scope="col" class="align-middle text-center">LIVE</th>
                      <th scope="col" class="align-middle text-center">TOTAL COUNT</th>
                    </tr>
                </thead>
                <tbody>
                  <template v-for="(data,index) in yearwise"  >
                    <tr v-bind:key="index+data.btyear" data-toggle="collapse" v-bind:data-target="'.demo'+data.btyear" style="font-weight: 600">
                      <td class="align-middle text-center first-col-sticky sitebluecolor make-bold" style="cursor:pointer" @click="getweekwise(data.btyear)">{{data.btyear}}</td>
                      <td class="align-middle text-center">{{data.productpipeline}}</td>
                      <td class="align-middle text-center">{{data.productcompleted}}</td>
                      <td class="align-middle text-center">{{data.techandbusinessreview}}</td>
                      <td class="align-middle text-center">{{data.devpipeline}}</td>
                      <td class="align-middle text-center">{{data.qapipeline}}</td>
                      <td class="align-middle text-center">{{data.designreview}}</td>
                      <td class="align-middle text-center">{{data.uat}}</td>
                      <td class="align-middle text-center">{{data.uatsignoff}}</td>
                      <td class="align-middle text-center">{{data.completed}}</td>
                      <td class="align-middle text-center">{{data.live}}</td>
                      <td class="align-middle text-center">{{data.count}}</td>
                    </tr>
                    <!-- {{clickedYear == data.btyear}} -->
                      <template v-for="(data2,index2) in weekwise">
                        <tr v-if="clickedYear == data.btyear" v-bind:key="'.demo'+index2+data.btyear+data2.btweek" data-toggle="collapse" :class="'collapse demo'+data.btyear" v-bind:data-target="'.demo'+data.btyear+data2.btweek">
                          <td class="hiddenRow align-middle text-center first-col-sticky make-bold" >
                              <div :class="'collapse sitebluecolor demo'+data.btyear"  style="cursor:pointer" @click="getdeptwise(data2.btyear, data2.btweek)" >{{data2.btweek}} : {{data2.btdates}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.productpipeline, 'productpipeline')">
                              <div :class="'collapse demo'+data.btyear">{{data2.productpipeline}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.productcompleted, 'productcompleted')">
                              <div :class="'collapse demo'+data.btyear">{{data2.productcompleted}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.techandbusinessreview, 'techandbusinessreview')">
                              <div :class="'collapse demo'+data.btyear">{{data2.techandbusinessreview}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.devpipeline, 'devpipeline')">
                              <div :class="'collapse demo'+data.btyear">{{data2.devpipeline}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.qapipeline, 'qapipeline')">
                              <div :class="'collapse demo'+data.btyear">{{data2.qapipeline}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.designreview, 'designreview')">
                              <div :class="'collapse demo'+data.btyear">{{data2.designreview}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.uat, 'uat')">
                              <div :class="'collapse demo'+data.btyear">{{data2.uat}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.uatsignoff, 'uatsignoff')">
                              <div :class="'collapse demo'+data.btyear">{{data2.uatsignoff}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.completed, 'completed')">
                              <div :class="'collapse demo'+data.btyear">{{data2.completed}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.live, 'live')">
                              <div :class="'collapse demo'+data.btyear">{{data2.live}}</div>
                          </td>
                          <td class="hiddenRow align-middle text-center" :style="'color:'+ issmaller(data2.btyear, data2.btweek, data2.count, 'count')">
                              <div :class="'collapse demo'+data.btyear">{{data2.count}}</div>
                          </td>
                        </tr>
                        <!-- {{clickedWeek == data.btweek}}{{clickedWeek + 'a'}} {{data2.btweek + 'b'}} -->
                          <template v-for="(data3,index3) in deptwise" >
                            <tr v-if="clickedYear == data.btyear && clickedWeek == data2.btweek" v-bind:key="'.demo'+index2+index3" :class="'collapse demo'+data3.btyear+data3.btweek">
                              <td class="hiddenRow align-middle text-center first-col-sticky make-bold">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.department}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.productpipeline}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.productcompleted}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.techandbusinessreview}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.devpipeline}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.qapipeline}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.designreview}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.uat}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.uatsignoff}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.completed}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.live}}</div>
                              </td>
                              <td class="hiddenRow align-middle text-center">
                                  <div :class="'collapse demo'+data3.btyear+data3.btweek">{{data3.count}}</div>
                              </td>
                            </tr>
                          </template>
                      </template>
                  </template>
                  <template v-if="businesstaskdeptmasterlist.length==0">
                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
$('.collapse').on('show.bs.collapse', function () {
    $('.collapse.in').collapse('hide');
});
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';


export default {
  name: 'businesstaskdeptmaster',
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    DatePicker,
    PlusCircleIcon,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    SearchIcon,
    ExternalLinkIcon,
    XIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      businesstaskdeptmasterlist: [],
      yearList: ['2024', '2023', '2022', '2021', '2020'],
      yearwise: [],
      weekwise: [],
      deptwise: [],
      clickedYear: null,
      clickedWeek: null,
      search: {
      },
      errors: {
      },
      isactive: true,
      isSearchBoxActive: false,
      card_content:'card-content',
      collapse:'collapse',
      isLoading: false,
      undt: null,
      tokendata: null,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      TimeFormat: apiUrl.TimeFormat,
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.getbusinesstaskdeptmasterlist()
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    issmaller(year, week, currval, key) {
      let prevweekdata = this.weekwise.filter(i => i.btyear == year && i.btweek == parseInt(week) - 1)
      if(prevweekdata && prevweekdata.length) {
        let prevval = prevweekdata[0][key]
        if(parseInt(prevval) > parseInt(currval)) {
          return 'red'
        } else if(parseInt(prevval) < parseInt(currval)) {
          return 'green'
        }
      } else {
        return 'green'
      }
      return 'orange'
    },
    getdeptwise(year, week) {
      this.clickedWeek = week
      let weekdata = this.businesstaskdeptmasterlist.filter(i => i.btyear == year && i.btweek == week)
      let deptwise = []
      let distinctDept = []
      for(let data of weekdata) {
        let department = data.department
        let currDeptData = weekdata.filter(i => i.btyear == year && i.btweek == week && i.department == department)
        if(!distinctDept.includes(department)) {
          distinctDept.push(department)
          deptwise.push({
            "btyear": year,
            "btweek": week,
            "department": department ? department : 'NA',
            "productpipeline": currDeptData.reduce((total, currVal) => total + parseInt(currVal.productpipeline), 0),
            "productcompleted": currDeptData.reduce((total, currVal) => total + parseInt(currVal.productcompleted), 0),
            "techandbusinessreview": currDeptData.reduce((total, currVal) => total + parseInt(currVal.techandbusinessreview), 0),
            "devpipeline": currDeptData.reduce((total, currVal) => total + parseInt(currVal.devpipeline), 0),
            "qapipeline": currDeptData.reduce((total, currVal) => total + parseInt(currVal.qapipeline), 0),
            "designreview": currDeptData.reduce((total, currVal) => total + parseInt(currVal.designreview), 0),
            "uat": currDeptData.reduce((total, currVal) => total + parseInt(currVal.uat), 0),
            "uatsignoff": currDeptData.reduce((total, currVal) => total + parseInt(currVal.uatsignoff), 0),
            "completed": currDeptData.reduce((total, currVal) => total + parseInt(currVal.completed), 0),
            "live": currDeptData.reduce((total, currVal) => total + parseInt(currVal.live), 0),
            "count": currDeptData.reduce((total, currVal) => total + parseInt(currVal.count), 0)
          })
        }
      }
      this.deptwise = deptwise
    },
    getweekwise(year) {
      this.clickedYear = year
      this.clickedWeek = null
      let yearData = this.businesstaskdeptmasterlist.filter(i => i.btyear == year)
      let weekwise = []
      let distinctWeeks = []
      for(let weekdata of yearData) {
        let btweek = weekdata.btweek
        let currWeekData = yearData.filter(i => i.btyear == year && i.btweek == btweek)
        if(!distinctWeeks.includes(btweek)) {
          distinctWeeks.push(btweek)
          weekwise.push({
            "btyear": year,
            "btweek": btweek,
            "btdates": weekdata.btweeks,
            "productpipeline": currWeekData.reduce((total, currVal) => total + parseInt(currVal.productpipeline), 0),
            "productcompleted": currWeekData.reduce((total, currVal) => total + parseInt(currVal.productcompleted), 0),
            "techandbusinessreview": currWeekData.reduce((total, currVal) => total + parseInt(currVal.techandbusinessreview), 0),
            "devpipeline": currWeekData.reduce((total, currVal) => total + parseInt(currVal.devpipeline), 0),
            "qapipeline": currWeekData.reduce((total, currVal) => total + parseInt(currVal.qapipeline), 0),
            "designreview": currWeekData.reduce((total, currVal) => total + parseInt(currVal.designreview), 0),
            "uat": currWeekData.reduce((total, currVal) => total + parseInt(currVal.uat), 0),
            "uatsignoff": currWeekData.reduce((total, currVal) => total + parseInt(currVal.uatsignoff), 0),
            "completed": currWeekData.reduce((total, currVal) => total + parseInt(currVal.completed), 0),
            "live": currWeekData.reduce((total, currVal) => total + parseInt(currVal.live), 0),
            "count": currWeekData.reduce((total, currVal) => total + parseInt(currVal.count), 0)
          })
        }
      }
      this.weekwise = weekwise
    },
    getbusinesstaskdeptmasterlist(search, isExportedFlag) {
      this.isLoading = true
      let url = 'api/reports/businesstaskdeptmaster'
      let payload = {
        useremail: this.undt.username,
        empcode: this.undt.userid
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.status) {
          let d = result.data.data.rows
          let ys = result.data.data.yearList
          this.businesstaskdeptmasterlist = d
          this.yearList = ys.map(i => i.date_trunc)
          let yearwise = []
          for(let year of this.yearList) {
            let yearData = this.businesstaskdeptmasterlist.filter(i => i.btyear == year)
            let obj = {
              "btyear": year,
              "productpipeline": yearData.reduce((total, currVal) => total + parseInt(currVal.productpipeline), 0),
              "productcompleted": yearData.reduce((total, currVal) => total + parseInt(currVal.productcompleted), 0),
              "techandbusinessreview": yearData.reduce((total, currVal) => total + parseInt(currVal.techandbusinessreview), 0),
              "devpipeline": yearData.reduce((total, currVal) => total + parseInt(currVal.devpipeline), 0),
              "qapipeline": yearData.reduce((total, currVal) => total + parseInt(currVal.qapipeline), 0),
              "designreview": yearData.reduce((total, currVal) => total + parseInt(currVal.designreview), 0),
              "uat": yearData.reduce((total, currVal) => total + parseInt(currVal.uat), 0),
              "uatsignoff": yearData.reduce((total, currVal) => total + parseInt(currVal.uatsignoff), 0),
              "completed": yearData.reduce((total, currVal) => total + parseInt(currVal.completed), 0),
              "live": yearData.reduce((total, currVal) => total + parseInt(currVal.live), 0),
              "count": yearData.reduce((total, currVal) => total + parseInt(currVal.count), 0),
            }
            yearwise.push(obj)
            this.yearwise = yearwise
          }
        } else {
          this.businesstaskdeptmasterlist = []
          if(isExportedFlag) {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            });
          } else {
            Swal.fire({
              title: "",
              text: result.data.data.Message,
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            });
          }
        }
      }).catch(e => {
        this.isLoading = false
        this.displayError(e)
        Swal.fire({
          title: "",
          text: e,
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
      }) 
    },
    dateMONFormat(value){
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
    searchBoxCollapse() {
      this.isSearchBoxActive = !this.isSearchBoxActive
    },
    clearSearchFields() {
      // this.search.EmployeeCode = null
      this.search.actionstartdate = null
      this.search.actionenddate = null
      this.getAllDomainData()
    },
  }
}
</script>
<style scoped>
  .box-inc {
    min-width: 200px;
  }
  .mark-red {
    color: red;
  }
  .mark-green {
    color: green;
  }
  .mark-orange {
    color: orangered;
  }
  .make-bold {
    font-weight: 600
  }
</style>