<template>
  <div class="app-content content ">
    <loading
    :active.sync="isLoading"
    :can-cancel="true"
    :is-full-page="fullPage"
    color="orange"
    loader="dots"
    :width="100"
    :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-2">Document Details</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                  <router-link :to="{ name: 'DocumentList', params:{ search: true }}"> Document Management List</router-link>
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">View Document Details</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;border-radius: 10px;overflow:hidden;">
          <div v-bind:class="[{show: isRequestActiveView}, card_content,collapse]">
            <div class="card-header py-1 indent_border_bottom">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-7 d-flex  align-items-center mobile-padding-0-2">
                      <div class="avatar bg-light-primary rounded">
                      <div class="avatar-content">
                        <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                      </div>
                    </div>
                    <h3 class="card-title ml-1 text-primary document_Group_Name01"><span style="color: black;margin-right:8px">Document Group Name:</span>{{ this.document.docgroupname }}</h3>
                  </div>
                  <div class="col-xs-12 col-sm-5  text-right" v-if="loggedinusermailid === document.createdby">
                      <div class="btn btn-sm btn-outline-primary waves-effect waves-effect mr-2"  @click="openEditModule(editRowData, 'edit')">
                        <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                        <span>Edit</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0 pt-2 document_Group_dark-theme" style="background: #F9FAFB;">
              <div class="customTimeline col-sm-12 col-md-3 col-lg-3" v-for="(data,index) in editRowData" v-bind:key="index">
                <div class="card border " style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)!important;border: none!important;">
                  <div :class="'card-header px-2 py-1 border-bottom ' + getColorClass(index)" >
                    <h4 class="card-title"> <span class="text-white">{{data.doctitle}}</span></h4>
                  </div>
                  <div class="card-body p-0">
                    
                    <div class="documentGrid match-height custom_grid_watch_bt">
                      <div class=" col-12 mb-1 px-0">
                        <div class="card  mb-0 ">
                          <div class="text-center py-2 document_Group_Namebg0222" style="background:#ECEEFB;">
                            <a v-on:click="getDownloadedLink(data.filename, 'download-doc')" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                            <a v-if="imgExt.includes(data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1).toLowerCase())" target="_blank" v-on:click="onZoom(data.filename, 'zoom')" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class"></zoom-in-icon> </a>
                            
                            <a v-if="vidExt.includes(data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1).toLowerCase())" v-on:click="vlc(data.filename, 'vidzoom')" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop">  <play-circle-icon size="1.5x" class="custom-class"></play-circle-icon></a>
                            <div class="traning_image_listing_st m-0 mb-1">
                            <img alt="" v-if="imgExt.includes(data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" style="cursor:pointer;" v-on:click="onZoom(data.filename, 'zoom')"/>                               
                            <img alt="" v-else-if="data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35"/>
                            <img alt="" v-else-if="data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                            <img alt="" v-else-if="data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                            <img alt="" v-else-if="vidExt.includes(data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1).toLowerCase())" src="../../../public/images/icons/ic-video.png" height="35"  />
                            <img alt="" v-else-if="data.docfilepath.substring(data.docfilepath.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="35" />
                          </div>
                          </div>
                          <p class="py-50 px-50 border-top mb-0" style="font-size:0.85rem; color:#888;">
                              <span v-if="data.docfilepath">
                                  {{data.docfilepath}}
                              </span>
                              <span v-else-if="!data.info">
                                  {{data.filename}}
                              </span>
                          </p>
                          <div class="document_discription_texts" style="padding: 0px 8px;">
                            
                            <div style="margin-bottom:2px">
                              <span style="font-weight:600">Keywords: </span>
                              <div class="my-1">
                                <template v-for="(d,i) in data.dockeywords" >
                                  <div v-bind:key="i" :class="'badge badge-pill capitalisetext mr-50 mb-25 ' + getColorClass2(i)">{{d}}</div>
                                </template>
                              </div>
                            </div>
                            <p class="transaction-title charter_limit" style="margin-bottom:2px"><span style="font-weight:600">Description:</span> <p> {{formatData(data.docdescription)}} </p></p>
                            <p style="margin-bottom:2px"><span style="font-weight:600">Link:</span> <a :href="data.docurl" target="_blank">Click here</a></p>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;" id="edit-section">
          <div class="card-header" style="cursor:pointer" v-on:click="myrequestollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12">
                  <div class="avatar bg-light-primary rounded d-inline-flex" >
                    <div class="avatar-content">
                      <edit-2-icon size="1.5x" class="custom-class avatar-icon"></edit-2-icon>
                    </div>
                  </div>
                  <h4 class="card-title d-inline-flex text-primary ml-1 text-primary-dark">Edit Document</h4>
                </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isdocsRequestActive},card_content,collapse]" >
            <div class="card-body">
              <form>
                <div class="row">
                  <div class="form-group col-md-3">
                    <label class="form-label">Document Group Name </label>
                    <input class="form-control" v-model="document.docgroupname" type="text" placeholder="Document Group Name" />
                    <div class="errorinputmsg" v-if="!document.docgroupname">Document group name is required</div>
                  </div>
                </div>
                <div class="row" v-for="(singleDocField, idx) in document.fields" :key="`phoneInput-${idx}`" style="border-bottom: 0.5px solid rgba(221, 215, 215, 0.51); margin-top: 12px">
                  <div class="form-group col-md-4" >
                    <label class="form-label">Document Title </label>
                    <input class="form-control" type="text" placeholder="Document Title" v-model="singleDocField.doctitle"/>
                    <div class="errorinputmsg" v-if="!singleDocField.doctitle">Document title is required</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Link</label>
                    <input class="form-control" type="text" placeholder="link" v-model="singleDocField.docurl"/>
                    <div class="errorinputmsg" v-if="!singleDocField.docurl">Link is required</div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4">
                    <label class="form-label lable-left">Subsribers</label>
                    <treeselect class="projectmdllistdd text-capitalize" placeholder="Enter min 3 chars to search Employee Name" v-model="singleDocField.subscribers" :options="search.emplist" :multiple="true" :clear-on-select="true" v-on:search-change="empNameChange"/>
                    <div class="errorinputmsg" v-if="!singleDocField.subscribers.length">Document subscribers is required</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Keywords</label>
                    <div class='tag-input'>
                      <div v-for='(tag, index) in singleDocField.dockeywords' :key='tag' class='tag-input__tag'>
                        {{ tag }}
                        <span @click='removeTag(singleDocField.dockeywords, index)'>x</span>
                      </div>
                      <input
                        type='text'
                        placeholder="Enter a Keyword"
                        class='tag-input__text'
                        :id="'old,'+idx"
                        @keydown.enter='addTag'
                        @keydown.188='addTag'
                        @keydown.delete='removeLastTag'
                      />
                    </div>
                    <div class="errorinputmsg" v-if="!singleDocField.dockeywords.length">Document keywords is required</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Description</label>
                    <textarea class="form-control"  rows="1" placeholder="Description" v-model="singleDocField.docdescription"></textarea>
                    <div class="errorinputmsg" v-if="!singleDocField.docdescription">Document description is required</div>
                  </div>
                  <div class="form-group col-md-4" style="margin-top:25px">
                    <label :for="'attachment'+idx" class="btn btn-relief-success mb-0">Browse</label>
                    <input  type="file" :id="'attachment'+idx" hidden  ref="file" @change="onSelectDocReplace($event, idx) "/>
                    <button class="btn btn-relief-info  ml-1 uploadbutton" :data-id="idx" v-on:click.stop.prevent="onUpload($event, idx, singleDocField, 'update')" :disabled="!singleDocField.docfilepath"> {{singleDocField.isupdated ? 'Updated' : 'Update'}}</button>
                    <div class="avatar btn-relief-danger rounded ml-1">
                      <div class="avatar-content" @click="removeDoc(idx, document.fields, document.selectedFiles, singleDocField)">
                        <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="!singleDocField.docfilepath">Attachment is required</div>
                  </div>
                  <div style="margin:8px auto; font-weight: 600">{{singleDocField.docfilepath}}</div>
                </div>
                <div class="btn btn-relief-primary mt-2 mr-1" @click="addDocumentFields()"><span><plus-square-icon size="1x"></plus-square-icon> Add New Document</span></div>
                <div class="row" v-for="(singleDocField, idx) in document.newfields" :key="`EditInput-${idx}`" style="border-bottom: 0.5px solid rgba(221, 215, 215, 0.51); margin-top: 12px">
                  <div class="form-group col-md-4" >
                    <label class="form-label">Document Title </label>
                    <input class="form-control" type="text" placeholder="Document Title" v-model="singleDocField.title"/>
                    <div class="errorinputmsg" v-if="!singleDocField.title">Document title is required</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Link</label>
                    <input class="form-control" type="text" placeholder="link" v-model="singleDocField.link"/>
                    <div class="errorinputmsg" v-if="!singleDocField.link">Link is required</div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4">
                    <label class="form-label lable-left">Subsribers</label>
                    <treeselect class="projectmdllistdd text-capitalize" placeholder="Enter min 3 chars to search Employee Name" v-model="singleDocField.subscribers" :options="search.emplist" :multiple="true" :clear-on-select="true" v-on:search-change="empNameChange"/>
                    <div class="errorinputmsg" v-if="!singleDocField.subscribers.length">Document subscribers is required</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Keywords</label>
                    <div class='tag-input'>
                      <div v-for='(tag, index) in singleDocField.keywords' :key='tag' class='tag-input__tag'>
                        {{ tag }}
                        <span @click='removeTag(singleDocField.keywords, index)'>x</span>
                      </div>
                      <input
                        type='text'
                        placeholder="Enter a Keyword"
                        class='tag-input__text'
                        :id="'new,'+idx"
                        @keydown.enter='addTag'
                        @keydown.188='addTag'
                        @keydown.delete='removeLastTag'
                      />
                    </div>
                    <div class="errorinputmsg" v-if="!singleDocField.keywords.length">Document keywords is required</div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Description</label>
                    <textarea class="form-control"  rows="1" placeholder="Description" v-model="singleDocField.desc"></textarea>
                    <div class="errorinputmsg" v-if="!singleDocField.desc">Document description is required</div>
                  </div>
                  <div class="form-group col-md-4" style="margin-top:25px">
                    <label :for="'newattachment'+idx" class="btn btn-relief-success mb-0">Browse</label>
                    <input  type="file" :id="'newattachment'+idx" hidden  ref="file" @change="onSelectDoc($event, idx) "/>
                    <button class="btn btn-relief-info  ml-1 uploadbutton" :data-id="idx" v-on:click.stop.prevent="onNewUpload($event, idx, singleDocField, 'add')" :disabled="!singleDocField.attachment"> {{singleDocField.isuploaded ? 'Uploaded' : 'Upload'}}</button>
                    <div class="avatar btn-relief-danger rounded ml-1">
                      <div class="avatar-content" @click="removeNewDocumentField(idx, document.newfields, document.newselectedfiles)">
                        <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                      </div>
                    </div>
                    <div class="errorinputmsg" v-if="!singleDocField.attachment">Attachment is required</div>
                  </div>
                  <div style="margin:8px auto; font-weight: 600">{{singleDocField.attachment}}</div>
                </div>
                <div class="row">
                  <div class="col-sm-8"> 
                      <div class="form-group mt-1">
                      <div class="note_document_creates"> <strong> Note:</strong> You can upload docx, xls, xlsx, png, jpeg, jpg, pdf, odg, txt, mp4 type files. The maximum individual file size allowed is 25MB.</div>
                      </div>
                    </div>
                   <div class="form-group  mb-0 ml-auto px-1">
                    <div class="form-group  mt-1 mb-0 text-right" >
                      <button  type="button" class="btn btn-relief-primary mr-1"  data-dismiss="modal" v-on:click.stop.prevent="newSaveDocument()" :disabled="checkIfAllDocsUploaded()">Submit</button>
                      <button type="button" class="btn btn-outline-secondary"  @click="docsuploadcollapse">Cancel</button>
                    </div>
                  </div>
              </div>
              </form>
          </div>
          </div>
        </div>
        <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">              
              <div class="modal-body p-0">
                <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                <img alt="" src="" class="imagepreview"  >
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="videomodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
          <div class="modal-dialog modal-lg modal-dialog-centered">
              <div class="modal-content video__container_mobile_view_centers">              
                  <div class="modal-body p-0 ">
                      <div class="video__container" v-if="videoIsOpen">
                          <button type="button" class="imageModalClose modal-video-close-btn" data-dismiss="modal" @click="stopVideoPlay"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                          <video controls controlsList="nodownload" id="myVideoPlayer" :src="videoURL" width="100%" preload="metadata">
                              <source :src="videoURL+'#t=0.0'" type="video/mp4">
                          </video>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Trash2Icon,SearchIcon,EditIcon,DownloadIcon,PlayCircleIcon,ZoomInIcon,MonitorIcon, PlusSquareIcon,EyeIcon, Edit2Icon, PlusIcon, MinusSquareIcon, ClipboardIcon, PenToolIcon, PocketIcon, CodepenIcon, UserIcon, CalendarIcon,WatchIcon, PaperclipIcon, SettingsIcon,UserPlusIcon,XIcon } from 'vue-feather-icons';
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import presignurl from '../getPresignurl.vue';
import commonMethods from '../../utils/commonMethods';

export default {
  name:"DocumentView",
  components:{
    VueTimepicker,
    Loading,PlusSquareIcon,DownloadIcon,ZoomInIcon,Trash2Icon,XIcon,
    VueElementLoading,
    WatchIcon,
    vSelect,
    EditIcon,
    EyeIcon,
    Edit2Icon,
    Treeselect,
    DatePicker,
    SearchIcon,
    Pagination,
    PlusIcon,
    CalendarIcon,
    MinusSquareIcon,
    ClipboardIcon,
    UserPlusIcon,
    PenToolIcon, PocketIcon, CodepenIcon, UserIcon, PaperclipIcon, SettingsIcon,MonitorIcon,
    profilepic,presignurl,
    PlayCircleIcon
  },
  mixins: [ commonMethods ],
  data() {
    return {
      videoURL: '',
      videoIsOpen: false,
      imgExt:['jpeg','jpg','png'],
      vidExt: ['mov', 'movie', 'mp4', 'wmv', 'avi', 'mkv'],
      isLoading: false,
      fullPage: true,
      tokendata: null,
      backpagedata: null,
      card_content:'card-content',
      collapse:'collapse',
      card:'card',
      border_warning:'border-warning',
      disRequestActive: true,
      isdocsRequestActive:false,
      isRequestActiveView: true,
      undt: null,
      loggedinusermailid: null,
      documentdetails: [],
      attachmentview:[],
      editRowData: [],
      documentid: null,
      pills: ['danger', 'primary', 'info', 'warning', 'success' ],
      document: {
        selectedFiles: [],
        newselectedfiles: [],
        fields: [],
        newfields: [],
        docgroupname: null,
        createdby: null,
      },
      search: {
        emplist: []
      },
    }
  },
  created() {
    this.pagefeatures = {}
    let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
    pagefeatures1.forEach(element => {
      if (element.page === '/document/list') {
        this.pagefeatures[element.featurename] = element.featureaccess
      }
    })
  },
  mounted(){
    window.addEventListener('storage', function(event){
      if (event.key == 'logout-mee') { 
        window.location.reload();
      }
    });
    let userdata = window.localStorage.getItem('userdata');
    if(userdata) {
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.loggedinusermailid = this.undt.username
      this.tokendata = window.localStorage.getItem('token');
      this.documentid = this.$route.query.documentid
      this.getDocumentDetailsById(this.$route.query.documentid)
      this.getEmpNameList(this.undt.userid, this.undt.employeeid)
    }
  },
  methods: {
    addTag(event) {
      event.preventDefault()
      let identiy = event.target.id.split(',')
      // let currTags = []
      let val = event.target.value.trim()
      if(identiy[0] === 'old') {
        // currTags = this.document.fields[parseInt(identiy[1])]
        if (val.length > 0) {
          if (this.document.fields[parseInt(identiy[1])].dockeywords.length >= 1) {
            for (let i = 0; i < this.document.fields[parseInt(identiy[1])].dockeywords.length; i++) {
              if (this.document.fields[parseInt(identiy[1])].dockeywords[i] === val) {
                return false
              }
            }
          }
          this.document.fields[parseInt(identiy[1])].dockeywords.push(val)
          event.target.value = ''
          console.log(this.document.fields[parseInt(identiy[1])].dockeywords)
        }
      } else {
        if (val.length > 0) {
          if (this.document.newfields[parseInt(identiy[1])].keywords.length >= 1) {
            for (let i = 0; i < this.document.newfields[parseInt(identiy[1])].keywords.length; i++) {
              if (this.document.newfields[parseInt(identiy[1])].keywords[i] === val) {
                return false
              }
            }
          }
          this.document.newfields[parseInt(identiy[1])].keywords.push(val)
          event.target.value = ''
          // console.log(this.document.newfields[parseInt(identiy[1])].keywords)
        }
      }
    },
    removeTag(tags, index) {
      tags.splice(index, 1)
      console.log(this.document.fields)
      console.log(this.document.newfields)
    },
    removeLastTag(event) {
      let identiy = event.target.id.split(',')
      if(identiy === 'old') {
        let currTags = this.document.fields[parseInt(identiy[1])].dockeywords
        if (event.target.value.length === 0) {
          this.removeTag(currTags, currTags.length - 1)
        }
      } else {
        let currTags = this.document.newfields[parseInt(identiy[1])].keywords
        if (event.target.value.length === 0) {
          this.removeTag(currTags, currTags.length - 1)
        }
      }
      // console.log(this.document.fields)
      // console.log(this.document.newfields)
    },
    getColorClass(index) {
      if(index >= 9) {
        let lastdigit = index % 10
        if(!lastdigit) {
          if(index % 2) {
            lastdigit = 5
          } else {
            lastdigit = 3
          }
        }
        return `document_bg_randam_bg${lastdigit}`
      }
      return `document_bg_randam_bg${index+1}`
    },
    getColorClass2(index) {
      if(index >= 5) {
        let lastdigit = index % 10
        if(lastdigit >= 5) {
          if(lastdigit % 2) {
            lastdigit = 0
          } else {
            lastdigit = 1
          }
        }
        return `badge-light-${this.pills[lastdigit]}`
      }
      return `badge-light-${this.pills[index]}`
    },
    formatData(data) {
      if(!data) {
        return 'NA'
      }
      if(data.length > 83) {
        return data.substring(0,84) + '...'
      }
      return data
    },
    openEditModule(currRowData, flag) {
      if(currRowData && currRowData.length) {
        this.document.docgroupname = currRowData[0].documentgroupname
        this.document.createdby = currRowData[0].createdby
        if(flag == 'edit') {
          this.isdocsRequestActive=true
          this.disRequestActive=false
          $('html, body').animate({ scrollTop: $(document).height() }, 1200);
        }
      }
    },
    stopVideoPlay() {
        var video = document.getElementById("myVideoPlayer");
        video.pause();
        video.currentTime = 0;
    },
    addDocumentFields() {
      this.document.newfields.push({
        title: '',
        desc: '',
        link: '',
        attachment: '',
        filename: '',
        subscribers: [],
        keywords: [],
        isuploaded: false
      })
    },
    onSelectDocReplace(event, idx) {
      let currFile = event.target.files[0]
      if (currFile.size > 1024 * 1024 * 25) {
        Swal.fire({
          title: "Failed",
          text: "Document of size above 25MB is not allowed",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        })
        return
      }
      let currSelectedFilesLength = this.document.selectedFiles.length
      if(currSelectedFilesLength) {
        this.document.selectedFiles[idx] = currFile
      } else {
        let curLen = this.document.fields.length
        let arr = []
        if(curLen) {
          for(let i=0; i<curLen; i++) {
            arr.push('')
          }
          this.document.selectedFiles = arr
          this.document.selectedFiles[idx] = currFile
        } else {
          this.document.selectedFiles.push(currFile);
        }
      }
      this.document.fields[idx].docfilepath = currFile.name
    },
    onSelectDoc(event, idx){
      let currFile = event.target.files[0]
      if (currFile.size > 1024 * 1024 * 25) {
        Swal.fire({
          title: "Failed",
          text: "Document of size above 25MB is not allowed",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        })
        return
      }
      let currSelectedFilesLength = this.document.newselectedfiles.length
      if(currSelectedFilesLength) {
        this.document.newselectedfiles[idx] = currFile
      } else {
        let curLen = this.document.newfields.length
        let arr = []
        if(curLen) {
          for(let i=0; i<curLen; i++) {
            arr.push('')
          }
          this.document.newselectedfiles = arr
          this.document.newselectedfiles[idx] = currFile
        } else {
          this.document.newselectedfiles.push(currFile);
        }
      }
      this.document.newfields[idx].attachment = currFile.name
    },
    async onUpload(event, index, file, action) {
      this.apiURL = "api/s3upload/uploadDocumentToS3";
      let currFile = this.document.selectedFiles[index]
      if(currFile && currFile.name) {
        this.isLoading=true
        let formData = new FormData();
        formData.append("imagefile", currFile);
        formData.append("empcode", this.undt.userid);
        formData.append("useremail", this.undt.username);
        formData.append("moduletype", 'documentcontrol')
        formData.imagefile = file;
        formData.empcode = this.undt.userid;
        formData.moduletype = 'documentcontrol';
        axios({
            "content-type": "multipart/form-data",
            method: "POST",
            url: this.apiURL,
            data: formData,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading=false
            if (result.data.status) {
              this.document.fields[index].isupdated = true
              this.document.fields[index].filename = result.data.data.filename
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            } else {
              Swal.fire({
                  title: "Failed",
                  text: result.data.msg,
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
            }
        }).catch(e => {
          this.isLoading = false
          Swal.fire({
            title: "Failed",
            text: e,
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          })
        })
      } else {
        Swal.fire({
            title: "Failed",
            text: "Select document",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
        })
      }            
    },
    async onNewUpload(event, index, file, action) {
      this.apiURL = "api/s3upload/uploadDocumentToS3";
      let currFile = this.document.newselectedfiles[index]
      if(currFile && currFile.name) {
        this.isLoading=true
        let formData = new FormData();
        formData.append("imagefile", currFile);
        formData.append("empcode", this.undt.userid);
        formData.append("useremail", this.undt.username);
        formData.append("moduletype", 'documentcontrol')
        formData.imagefile = file;
        formData.empcode = this.undt.userid;
        formData.moduletype = 'documentcontrol';
        axios({
            "content-type": "multipart/form-data",
            method: "POST",
            url: this.apiURL,
            data: formData,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading=false
            if (result.data.status) {
              this.document.newfields[index].isuploaded = true
              this.document.newfields[index].filename = result.data.data.filename
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            } else {
              Swal.fire({
                  title: "Failed",
                  text: result.data.msg,
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
            }
        }).catch(e => {
          this.isLoading = false
          Swal.fire({
            title: "Failed",
            text: e,
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          })
        })
      } else {
        Swal.fire({
            title: "Failed",
            text: "Select document",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
        })
      }            
    },
    removeDocumentField(idx, fields, selectedFiles) {
      if(fields && fields.length>1) {
        fields.splice(idx,1);
        selectedFiles.splice(idx, 1)
      }
    },
    removeNewDocumentField(idx, fields, selectedFiles) {
      if(fields && fields.length>=1) {
        fields.splice(idx,1);
        selectedFiles.splice(idx, 1)
      }
    },
    removeDoc(index, fieldType, selectedFiles, data) {
      if(fieldType && fieldType.length <= 1) {
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      })
      .then((result) => {
        if (result.isConfirmed) {
          if(data.id2 !== undefined) {
              axios({
              method: "POST",
              url: 'api/document/delete',
              data: {id:data.id2, deleteby:this.undt.username, empcode: this.undt.userid, useremail: this.undt.username },
              'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            this.isLoading = false;
            if(fieldType && fieldType.length>1) {
              fieldType.splice(index,1);
              selectedFiles.splice(index, 1)
            }
            if (result.data.status) {
                fieldType.splice(index, 1);
                Swal.fire(
                  'Deleted!',
                  result.data.msg,
                  'success'
                )
            } else {
              Swal.fire(
                'Deleted!',
                result.data.msg,
                'error'
              )
            }
          })
          .catch(e => {
            Swal.fire({
              title: "Failed",
              text: e,
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            })
          })
          } else {
            fieldType.splice(index, 1);
          }
        }
      })
    },
    checkIfAllDocsUploaded() {
      let data = this.document.fields
      let newdata = this.document.newfields
      if(!this.document.fields.length) {
        return true
      }
      let f = data.every(i => {
        return i.docfilepath && i.doctitle && i.docdescription && i.docurl && i.subscribers.length && i.dockeywords.length
      })
      let f2 = newdata.every(i => {
        return i.attachment && i.title && i.desc && i.link && i.isuploaded && i.subscribers.length && i.keywords.length
      })
      return !f || !f2
    },
    restForm() {
      this.document.docgroupname = null
      this.document.selectedFiles = []
      this.document.newselectedfiles = []
      this.document.newfields = []
      this.document.fields = []
    },
    docsuploadcollapse() {
      this.isdocsRequestActive=false
      this.disRequestActive=true
      $('html, body').animate({ scrollTop: $(document).height() }, 1200);
    },
    newSaveDocument() {
      let url = 'api/document/update'
      let payload = {
        updatedby: this.undt.username,
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        documentgroupname: this.document.docgroupname,
        newdocumentlist: this.document.newfields,
        id: this.documentid,
        updateddocumentlist: this.document.fields.map(i => {
          return {
            id2: i.id2,
            id: i.id,
            title: i.doctitle,
            desc: i.docdescription,
            link: i.docurl,
            attachment: i.docfilepath,
            subscribers: i.subscribers,
            keywords: i.dockeywords,
            filename: i.filename
          }
        }),
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers: { authorization :this.tokendata }
      })
      .then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          Swal.fire({
            title: "Success!",
            text: result.data.msg,
            icon: 'success',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
          this.restForm()
          this.getDocumentDetailsById(this.documentid)
          this.docsuploadcollapse()
          // this.$router.push('/document/list')
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          }).then(function() {
            window.location.href = "/#/login";
          })
        } else {
          Swal.fire({
            title: "Failed",
            text: result.data.msg,
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getDownloadedLink(data, curr=null) {
      if(data) {
        let payload = {
        documents:[{link:data,empid: this.undt.userid,useremail: this.undt.username}],
        empcode: this.undt.userid,
        useremail: this.undt.username
        };
        axios({
          method: "POST",
          url: '/api/imageupload/getdownloadlink',
          data: payload,
          'headers':{'authorization':this.tokendata}
        })
        .then((result) => {
          this.isLoading = false
          if(result.data.status) {
              if(result.data.data[0].presignurl){
                if(curr=='zoom'){
                  $('.imagepreview').attr('src', result.data.data[0].presignurl);
                  $('#imagemodal').modal('show');  
                  return 
                } else if(curr == 'vidzoom') {
                  this.videoURL = result.data.data[0].presignurl
                  this.videoIsOpen = true
                  $('#videomodal').modal('show');
                  return
                } else if(curr == 'pdfpreview') {
                  window.open(result.data.data[0].presignurl, '', 'left=0,top=0,width=900,height=1200,toolbar=0,scrollbars=0,status=0')
                  return 
                }
                window.open(result.data.data[0].presignurl, '_blank')     
              }
          }
        }).catch(e => {
        this.displayError(e)
      });;
      }
    },
    onZoom(e,  action){
      this.getDownloadedLink(e, action);
    },
    vlc(e, action) {
      this.getDownloadedLink(e, action);
    },
    empNameChange: function (node, instanceId){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/manageemp/getAllEmplist";
        this.input = {
          empname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: parseInt(this.undt.userid),
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            let decryptdepartmenttext = result.data.data.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            this.search.emplist = result.data.data;
          } else {
            this.search.emplist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getEmpNameList(empid) {
      axios({
        'method': 'POST',
        'url': 'api/manageemp/getAllEmplist',
        'data': {
          empid,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          searchedempid: this.search.EmployeeCode ? this.search.EmployeeCode.toLowerCase() : null,
        },
        'headers':{'authorization':this.tokendata}
      }).then(result => {
        // this.isLoading = false
        if(result.data.errorCode == 0) {
          this.search.emplist = result.data.data
          let decryptdepartmenttext = result.data.data.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
            })
        } else {
          this.search.emplist = []
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    myrequestollapse() {
      this.disRequestActive=!this.disRequestActive
    },
    getDocumentDetailsById(moduleid) {
      this.isLoading = true
      let url = "api/document/getDocumentListById";
      this.input = {
        documentid: moduleid,
        useremail: this.undt.username,
        empid:this.undt.employeeid.toLowerCase(),
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          let d = result.data.data.rows

          this.document.fields = d.map(i => {
            let keywords = []
            if(i.dockeywords.indexOf('[') == -1) {
              // not array
              let keys = i.dockeywords.split(',')
              keywords = keys
            } else {
              keywords = JSON.parse(i.dockeywords)
            }
            return { ...i, dockeywords: keywords, isupdated: false }
          })
          this.editRowData = this.document.fields;
          this.openEditModule(this.editRowData, 0)
        } else {
          this.editRowData = [];
          // this.$router.push('/document/list')
          Swal.fire({
            title: "Failed!",
            text: "Your are not authorised user to view this documents",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          })
        }
      })
      .catch(e => {
        this.isLoading = false
        Swal.fire({
          title: "Failed!",
          text: 'Data not found',
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        })
        this.$router.push('/document/list')
      });
    },
  }
}

</script>
<style>

.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
