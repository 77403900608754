<template>
  <div class="app-content content">
    <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header-left col-md-9 col-12 mb-1 pad_top_mar_bot-5 px-0">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-0">Application</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard">Dashboard</router-link>
                </li>
                <li
                  class="breadcrumb-item sitebluecolor cursorpointercls"
                  @click="
                    redirecttobreadkumb({
                      name: 'Project Wizard',
                      path: 'project',
                      btnstate: 'view',
                      rowdata: backpagedata,
                    })
                  "
                >
                  Projects
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="row" v-if="showfilterboxsection">
              <div class="col-12">
                <div class="card card-body" style="margin-top: 10px">
                  <h4
                    class="content-header-title float-center mb-0"
                    style="
                      text-align: center;
                      margin-top: 0.2px;
                      margin-bottom: 80px;
                    "
                  >
                    SEARCH PROJECT MODULE DETAILS
                  </h4>
                  <div class="row">
                    <div
                      class="col-sm-6 col-md-6 col-lg-3 mb-1"
                      style="margin-left: 20px"
                    >
                      <label class="form-label lable-left">Project</label>
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        v-model="search.searchProjectname"
                        :options="application.projectnameList"
                        @select="selectproject"
                      />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left"
                        >Project Modules</label
                      >
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        v-model="search.searchProjectmodulename"
                        :options="projectmodulenameList"
                        @input="selectprojectmodule"
                      />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <button
                        type="submit"
                        class="btn btn-primary mt-2 mr-1"
                        @click="getFilter()"
                      >
                        Search
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary mt-2"
                        @click="resetFilter()"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="card border-warning"
                  style="
                    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
                    margin-bottom: 1rem !important;
                  "
                >
                  <div
                    class="card-header"
                    style="cursor: pointer"
                    v-on:click="mysearchrequestollapse"
                  >
                    <div
                      class="avatar bg-light-info rounded"
                      style="margin-left: 36%"
                    >
                      <div class="avatar-content">
                        <grid-icon
                          size="1.5x"
                          class="custom-class avatar-icon font-medium-3"
                        ></grid-icon>
                      </div>
                    </div>
                    <h4 class="card-title" style="margin-left: -409px">
                      PROJECT MODULE DETAILS
                    </h4>
                    <div class="heading-elements" style="color: blue">
                      <ul class="list-inline mb-0">
                        <li>
                          <a data-action="collapse"
                            ><plus-icon
                              size="4x"
                              class="custom-class"
                            ></plus-icon
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    v-bind:class="[
                      { show: isSearchRequestActive },
                      card_content,
                      collapse,
                    ]"
                    style="margin-top: 10px"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div
                          v-for="(data, index1) in modulelist"
                          v-bind:key="data.moduleid"
                          class="col-lg-3 col-md-6 col-12"
                        >
                          <div
                            v-bind:key="data.moduleid"
                            class="card border cursor-pointer"
                            @click="getprojectapplicationlist(data)"
                            :class="[
                              index1 < 9
                                ? stylevariation[index1].label
                                : 'bg-secondary',
                            ]"
                          >
                            <div class="card-body">
                              <div
                                class="d-flex justify-content-between text-white"
                              >
                                <box-icon
                                  size="40x"
                                  class="custom-class"
                                ></box-icon>
                              </div>
                              <div class="my-1">
                                <h4 class="capitalisetext text-white">
                                  {{ data.modulename }}
                                </h4>
                                <h5 class="capitalisetext text-white">
                                  {{ dateFormat(data.createddate) }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card border-warning filter_card_searchbgst">
              <div class="card-header filter_rotation_onlick" style="cursor: pointer"
                v-on:click="mysearchrequestollapse">
                <div class="container-fluid">
                  <div class="row fc_my-05">
                    <div class="w-auto px-1 mobile-padding-0-2">
                      <div
                        class="avatar bg-light-secondary rounded d-inline-flex"
                      >
                        <div class="avatar-content">
                          <search-icon
                            size="1.5x"
                            class="custom-class avatar-icon font-medium-3"
                          ></search-icon>
                        </div>
                      </div>
                      <h4 class="card-title d-inline-flex ml-1">
                        Search Application Details
                      </h4>
                    </div>
                  <div class="ml-auto d-flex">
                    <div class=" px-1 text-right mobile-padding-0-2">
                      <div class="input-group-append displayblk">
                        <button
                          id="commonaddmodule"
                          class="btn btn-sm btn-relief-success"
                          data-toggle="modal"
                          aria-haspopup="true"
                          aria-expanded="false"
                          :data-target="currentmodule"
                          @click="addModule"
                          type="button"
                          data-backdrop="static"
                          data-keyboard="false"
                        >
                          
                          <span class="mobile_hide_bt mr-1">Create Application</span>
                          <plus-circle-icon
                            size="1.5x"
                            class="custom-class ml-0"
                          ></plus-circle-icon>
                        </button>
                      </div>
                    </div>
                    <span class="rotetions_view_arrow mr-1"> 
                  <em class="las la-angle-down"></em>
                </span>
                </div>
                  </div>
                </div>
              </div>
              <div
                v-bind:class="[
                  { show: isSearchRequestActive },
                  card_content,
                  collapse,
                ]"
              >
                <div class="card-body pt-2">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        placeholder="Enter min 3 chars to search project name"
                        v-on:search-change="projectChange"
                        v-model="search.searchProjectname"
                        :options="projectnameList"
                        @select="selectproject2"
                      />
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Application</label>
                      <treeselect
                        class="projectmdllistdd capitalisetext"
                        placeholder="Enter min 3 chars to search Application Name"
                        v-on:search-change="applicationChange"
                        v-model="search.searchApplication"
                        :options="search.applicationopts"
                      />
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-0 text-right">
                      <button
                        type="submit"
                        class="btn btn-relief-primary mt-2 mr-1"
                        @click="getprojectapplicationlist(search)"
                      >
                        <search-icon
                          size="1.5x"
                          class="custom-class"
                        ></search-icon>
                        <span>Search</span>
                      </button>
                      <button
                        type="reset"
                        class="btn btn-outline-secondary mt-2 mr-1"
                        @click="resetRecords()"
                      >
                        <x-icon size="1.5x" class="custom-class"></x-icon>
                        <span>Clear</span>
                      </button>
                      <button
                        type="move"
                        class="btn btn-relief-primary mt-2"
                        @click="ExportXL()"
                      >
                        <external-link-icon
                          size="1.5x"
                          class="custom-class"
                        ></external-link-icon>
                        <span>Export</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <div class="card">
                    <div >
                      <div
                        id="applicationTable"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div
                                class=""
                                style="
                                  padding-left: 0px !important;
                                  padding-top: 0px !important;
                                "
                              >
                                <div class="row">
                                  <div class="col-md-9">
                                    <div class="input-group-append">
                                      <h4
                                        v-if="showfilterboxsection"
                                        class="card-title"
                                      >
                                        Project Application Name :
                                        <strong>{{ application.projectmodule }}</strong>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="table-responsive datatableview"
                              >
                                <table
                                  class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc" >
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno first-col-sticky align-middle" style="min-width: 45px;">Sr#</th>
                                      <th scope="col" class="align-middle second-col-sticky text-left" style="min-width: 160px;">
                                        Application Name
                                      </th>
                                      <th scope="col" class="align-middle" style="min-width: 200px;">Project Details</th>
                                      <th scope="col" class="align-middle">
                                        Total App Modules
                                      </th>
                                      <th scope="col" class="align-middle">
                                        Total App Env
                                      </th>
                                      <th scope="col" class="align-middle">Repo Name</th>
                                      <th scope="col" class="align-middle">Jenkins Job Name</th>
                                      <th scope="col" class="align-middle">Graylog Dashboard ID</th>
                                      <th scope="col" class="align-middle text-left" style="min-width:115px;">Created Date</th>
                                      <th scope="col" class="clsaction align-middle">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template
                                      v-for="(data, index) in companymasterlist"
                                    >
                                      <tr v-bind:key="data.moduleid">
                                        <td data-column="Sr#" class="srno first-col-sticky">
                                          {{
                                            index + 1 + currPageNum * pagelimit
                                          }}
                                        </td>
                                        <td data-column="Application Name" class="second-col-sticky text-left">
                                          <span
                                            class="font-weight-normal capitalisetext cursorpointercls sitebluecolor"
                                            @click="
                                              redirecttourl('pathApp', data)
                                            "
                                            >{{ data.applicationname }}</span
                                          >
                                        </td>
                                        <td data-column="Project Details" class="details_column_table">
                                           <span class="heading_column_text"> Description : </span>
                                          <span
                                            class="font-weight-normal capitalisetext"
                                            v-if="
                                              data.description &&
                                              data.description.length > 10
                                            "
                                            >{{
                                              data.description.substring(
                                                0,
                                                10 + 1
                                              ) + "..."
                                            }}</span>

                                          <span
                                            class="font-weight-normal capitalisetext"
                                            v-if="
                                              data.description &&
                                              data.description.length <= 10
                                            "
                                            >{{ data.description }}</span>
                                            <hr class="hr_border-bottom">
                                            <span class="heading_column_text">Project Name : </span>
                                            <span class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('projectId',data)">{{ data.projectname }}</span>
                                          </td>
                                       
                                        <td data-column="Total App Modules" class="centeralign">
                                          <span
                                            class="font-weight-normal cursorpointercls sitebluecolor capitalisetext"
                                            style="cursor: pointer"
                                            @click="
                                              redirecttourl('pathApp', data)
                                            "
                                            >{{ data.totalappmodule }}</span
                                          >
                                        </td>
                                        <td data-column="Total App Env" class="centeralign">
                                          <span
                                            class="font-weight-normal cursorpointercls sitebluecolor capitalisetext"
                                            style="cursor: pointer"
                                            @click="
                                              redirecttoEnvurl('view', data)
                                            "
                                            >{{ data.totalappenv }}</span
                                          >
                                        </td>
                                        <td data-column="Repo Name">
                                          <span
                                            class="font-weight-normal capitalisetext">{{ data.gitlabreponame ? data.gitlabreponame:'NA'}}</span>
                                        </td>
                                        <td data-column="Jenkins Job Name" class="font-weight-normal">
                                          <span
                                            class="font-weight-normal capitalisetext">{{ data.jenkinsjobname ? data.jenkinsjobname:'NA'}}</span>
                                        </td>
                                        <td data-column="Graylog Dashboard ID" class="font-weight-normal">
                                          <span
                                            class="font-weight-normal capitalisetext">{{ data.graylogdashboardid ? data.graylogdashboardid:'NA'}}</span>
                                        </td>
                                        <td data-column="Created Date" class="font-weight-normal">
                                          {{ dateFormat(data.createddate) }}
                                        </td>
                                        <td data-column="Actions" class="clsaction">
                                          <div class="dropdown">
                                            <button
                                              type="button"
                                              class="btn btn-sm dropdown-toggle hide-arrow"
                                              data-toggle="dropdown"
                                              data-boundary="window"
                                            >
                                              <more-vertical-icon
                                                size="1.5x"
                                                class="custom-class"
                                              ></more-vertical-icon>
                                            </button>
                                            <div class="dropdown-menu">
                                              <div
                                                v-if="data.projectlevel == 3"
                                              >
                                                <a
                                                  class="dropdown-item devEditEle"
                                                  href="javascript:void(0);"
                                                  @click="
                                                    redirecttowsurl(
                                                      'view',
                                                      data
                                                    )
                                                  "
                                                >
                                                  <eye-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></eye-icon>
                                                  <span>View Workstep</span>
                                                </a>
                                                <a
                                                  class="dropdown-item"
                                                  @click="
                                                    redirecttowsurl(
                                                      'edit',
                                                      data
                                                    )
                                                  "
                                                  href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)"
                                                >
                                                  <plus-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></plus-icon>
                                                  <span>Add Workstep</span>
                                                </a>
                                              </div>
                                              <div
                                                v-if="
                                                  data.projectlevel >= 2 ||
                                                  !data.projectlevel
                                                "
                                              >
                                                <a
                                                  class="dropdown-item"
                                                  @click="
                                                    redirecttoProjModule(
                                                      'view',
                                                      data
                                                    )
                                                  "
                                                  href="javascript:void(0);"
                                                >
                                                  <eye-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></eye-icon>
                                                  <span
                                                    >View Project Module</span
                                                  >
                                                </a>
                                                <a
                                                  class="dropdown-item"
                                                  @click="
                                                    redirecttoProjModule(
                                                      'edit',
                                                      data
                                                    )
                                                  "
                                                  href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)"
                                                >
                                                  <plus-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></plus-icon>
                                                  <span> Project Module</span>
                                                </a>
                                              </div>
                                              <div
                                                v-if="
                                                  data.projectlevel >= 4 ||
                                                  !data.projectlevel
                                                "
                                              >
                                                <a
                                                  class="dropdown-item"
                                                  @click="
                                                    redirecttourl(
                                                      'pathApp',
                                                      data
                                                    )
                                                  "
                                                  href="javascript:void(0);"
                                                >
                                                  <eye-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></eye-icon>
                                                  <span>View App Module</span>
                                                </a>
                                                <a
                                                  class="dropdown-item"
                                                  @click="
                                                    redirecttourl(
                                                      'pathAppAdd',
                                                      data
                                                    )
                                                  "
                                                  href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)"
                                                >
                                                  <plus-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></plus-icon>
                                                  <span>Add App Module</span>
                                                </a>
                                                <a
                                                  class="dropdown-item"
                                                  @click="
                                                    redirecttoEnvurl(
                                                      'view',
                                                      data
                                                    )
                                                  "
                                                  href="javascript:void(0);"
                                                >
                                                  <eye-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></eye-icon>
                                                  <span>View App Env</span>
                                                </a>
                                                <a
                                                  class="dropdown-item"
                                                  @click="
                                                    redirecttoEnvurl(
                                                      'add',
                                                      data
                                                    )
                                                  "
                                                  href="javascript:void(0);" v-if="(data.projectisactive ==1 && data.projectisapproved ==1)"
                                                >
                                                  <plus-icon
                                                    size="1.5x"
                                                    class="custom-class mr-50"
                                                  ></plus-icon>
                                                  <span>Add App Env</span>
                                                </a>
                                              </div>
                                              <a
                                                class="dropdown-item devEditEle"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                :data-target="currentmodule"
                                                @click="openEditModule(data)"
                                                data-backdrop="static"
                                                data-keyboard="false"
                                              >
                                                <edit-2-icon
                                                  size="1.5x"
                                                  class="custom-class mr-50"
                                                ></edit-2-icon>
                                                <span>Edit</span>
                                              </a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0 && isresponce">
                                      <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter text-center">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="modal modal-slide-in fade"
      id="projectappmaster"
      aria-hidden="true"
    >
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #3E4757 !important"
          >
            ×
          </button>
          <div
            class="modal-header mb-1 leftsidepopupstyle"
            style="background-color: #ECEEFB !important"
          >
            <h5 class="modal-title" style="color: #3E4757 !important">
              
              <span class="align-middle  mx-0"
                >Add/Update Application Details</span
              >
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <div class="row">
              <form id="projectsForm" class="">
                <div class="col-12">
                  <div class="form-group">
                    <label for="appprojectdd">Project Name</label>
                    <treeselect
                      class="projectmdllistdd capitalisetext"
                      placeholder="Select Project Name"
                      v-model="application.projectid"
                      :options="application.projectnameList"
                      @select="selProject"
                    />
                    <div class="errorinputmsg" v-if="this.errors.projectid">
                      {{ this.errors.projectid }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="modulenamet12">Application Name</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><package-icon
                            size="1.5x"
                            class="custom-class"
                          ></package-icon
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        placeholder="Application Name"
                        v-model="application.name"
                        v-bind:class="{
                          'form-control': true,
                          '': !validText(application.name) && applinameBlured,
                        }"
                        v-on:blur="applinameBlured = true"
                      />
                    </div>
                    <div
                      class="errorinputmsg"
                      v-if="!validText(application.name) && applinameBlured"
                    >
                      Application name must be greater than 5 char, and you must
                      enter a letters
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="prmoduledescription"
                      >Application Description</label
                    >
                    <textarea
                      class="form-control"
                      name=""
                      rows="3"
                      placeholder="Application Description"
                      v-model="application.desc"
                      v-bind:class="{
                        'form-control': true,
                        '': !validText(application.desc),
                      }"
                    ></textarea>
                    <div
                      class="errorinputmsg"
                      v-if="!validText(application.desc)"
                    >
                      Application description must be greater than 5 char
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="applicationtype">Application Type</label>
                    <treeselect
                      class="projectmdllistdd capitalisetext"
                      placeholder="Select Application Type"
                      v-model="application.applicationtype"
                      :options="application.applicationtypelist"
                      @input="selappapltype"
                    />
                    <div
                      class="errorinputmsg"
                      v-if="this.errors.errorapplicationtype"
                    >
                      {{ this.errors.errorapplicationtype }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Network Accessibility Type</label>
                    <treeselect
                      placeholder="Select Network Accessibility Type"
                      v-model="application.applicationaccess"
                      :options="application.applicationaccesslist"
                      @input="selappaplaccess"
                    />
                    <div
                      class="errorinputmsg"
                      v-if="this.errors.errorapplicationaccess"
                    >
                      {{ this.errors.errorapplicationaccess }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                      <label class="form-label" for="project-name">Repo Name</label>
                      <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><package-icon
                            size="1.5x"
                            class="custom-class"
                          ></package-icon
                        ></span>
                      </div>
                      <input class="form-control" type="text" placeholder="Enter Repo Name" v-model="application.gitlabreponame" v-bind:class="{'form-control':true, '' : !validText(application.gitlabreponame)}"/>
                      <div class="errorinputmsg" v-if="!validText(application.gitlabreponame)">Repo name must be  greater than 5 char</div>
                      </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                      <label class="form-label" for="project-name">Jenkins Job Name</label>
                      <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><package-icon
                            size="1.5x"
                            class="custom-class"
                          ></package-icon
                        ></span>
                      </div>
                      <input class="form-control" type="text" placeholder="Enter Jenkins Job Name" v-model="application.jenkinsjobname" v-bind:class="{'form-control':true, '' : !validText(application.jenkinsjobname)}"/>
                      <div class="errorinputmsg" v-if="!validText(application.jenkinsjobname)">Jenkins Job name must be  greater than 5 char</div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                      <label class="form-label" for="project-name">Graylog Dashboard ID</label>
                      <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><package-icon
                            size="1.5x"
                            class="custom-class"
                          ></package-icon
                        ></span>
                      </div>
                      <input class="form-control" type="text" placeholder="Enter Graylog Dashboard ID" v-model="application.graylogdashboardid" @input="alphanumericInput"/>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-relief-primary mr-1"
                    v-on:click.stop.prevent="saveData('t12', $event)"
                    id="submitbtnt12"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    class="btn btn-outline-secondary"
                    @click="resetData()"
                  >
                    Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.createbtnparentdiv {
  padding-right: 0% !important;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import { ExternalLinkIcon,PlusCircleIcon,PlusSquareIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,EditIcon,EyeIcon,SearchIcon,XIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon } from "vue-feather-icons";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import commonMethods from '../../utils/commonMethods';
export default {
  name: "ProjectApplication",
  components: {
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    Edit2Icon,
    PlusSquareIcon,
    XIcon,
    ExternalLinkIcon,
    PlusCircleIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      isLoading: false,
      fullPage: true,
      islistLoading:false,
      listfullPage:true,
      stylevariation: apiUrl.stylevariation,
      companymasterlist: [],
      selected: null,
      editRowData: [],
      glbMdl: "",
      TimeFormat: apiUrl.TimeFormat,
      application: {
        applicationid: "",
        projectid: null,
        name: "",
        desc: "",
        project: "",
        appprojectdd: null,
        label: "",
        projectmodule: null,
        applicationtype: null,
        applicationtypelist: [
          {
            id: "",
            label: "",
          },
        ],
        projectnameList: [],
        techapplicationtype: null,
        techapplicationtypelist: [
          {
            id: "",
            label: "",
          },
        ],
        applicationaccess: null,
        applicationaccesslist: [
          {
            id: "",
            label: "",
          },
        ],
        gitlabreponame: "",
        jenkinsjobname: "", 
        graylogdashboardid:"",
        errorapplicationaccess: null,
        errorapplicationtype: null,
        businesslist: null,
        businessverticleid: null,
        projecttypelist: null,
        projecttypeid: null,
        companylist: null,
        companyid: null,
        iseditstate: false,
        projectmoduleid: null,
      },
      errors: {
        projectid: null,
        project: null,
        errorapplicationtype: null,
        errorapplicationaccess: null,
      },
      applinameBlured: false,
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      showproject: false,
      activeprojectid: "",
      modulelist: [],
      isSearchRequestActive: false,
      card_content: "card-content",
      collapse: "collapse",
      projectmoduleid: null,
      projectname: null,
      projectmodulename: null,
      projectnameList: [],
      projectmodulenameList: [],
      search: {
        status: false,
        searchProjectname: null,
        projectnameList: null,
        searchApplication: null,
        applicationopts: null,
        searchProjectmodulename: null,
        applicationList: [],
        searchProject: null,
        projectmoduleid: null
      },
      isSearch: false,
      showfilterboxsection: false,
      currentmodule: "#projectappmaster",
      backpagedata: null,
      tokendata: null,
      isresponce:false,
    };
  },
  created() {
    this.pagefeatures = {};
    var pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/assignments") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if (permission) {
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
      }
      this.getaccessofapplicationlist();
      this.getapplicationtypelist();
      this.getProjectname();
      let rowdata={}
      if (this.$route.params.backbtnstate != undefined) {
        if (
          this.$route.params.backbtnstate == "addapplication" ||
          this.$route.params.backbtnstate == "view" ||
          this.$route.params.backbtnstate == "viewapp"
          ) {
            let search = JSON.parse(localStorage.getItem("applicationmastersearchItems"));
            if (search && (search.hasOwnProperty("searchProject") || search.hasOwnProperty('projectid') || search.hasOwnProperty('projectname')) && this.$route.params.backbtnstate != "addapplication") {
            this.backpagedata = this.$route.params.clickeddata;
            if(this.$route.params && this.$route.params.clickeddata && this.$route.params.clickeddata.projectid){
              this.search.searchProject = this.$route.params.clickeddata.projectid;
              rowdata.searchProject = this.search.searchProject;
              this.search.searchProject = this.search.searchProject;
              if(this.$route.params.backbtnstate != "viewapp"){
                this.search.projectmoduleid = this.$route.params.clickeddata.projectmoduleid ? this.$route.params.clickeddata.projectmoduleid :null;
              }
            }
            this.getSearchItems(search);
            this.getapplictionlist(search);
            this.isSearchRequestActive = true;
          } else if(this.$route.params.backbtnstate != "addapplication"){
            localStorage.removeItem('applicationmastersearchItems');
            this.search.searchProject = this.$route.params.clickeddata.projectid
            rowdata.searchProject = this.search.searchProject
            this.backpagedata = this.$route.params.clickeddata;
            this.getprojectapplicationlist(this.$route.params.clickeddata);
            this.getapplictionlist(this.$route.params.clickeddata.projectid);
          }
          if (this.$route.params.backbtnstate == "addapplication") {
            localStorage.removeItem('applicationmastersearchItems');
            this.backpagedata = this.$route.params.clickeddata;
            this.application.projectid = this.backpagedata.projectid;
            this.search.searchProject = this.backpagedata.projectid;
            this.search.searchProjectname = this.search.searchProject;
            rowdata.searchProject = this.search.searchProject;
            this.projectChangebyid(this.search.searchProject ? [this.search.searchProject] :[]);
            this.getprojectapplicationlist(this.backpagedata);
            this.getapplictionlist(this.backpagedata.projectid);
            setTimeout(function () {
              $("#commonaddmodule").click();
            });
          }
        }
      } else {
        localStorage.removeItem('applicationmastersearchItems');
        this.getprojectapplicationlist(rowdata);
        this.getapplictionlist();
      }
    }
    $(".filter_rotation_onlick").click(function(){
  $(this).toggleClass("common_arrow_rotetion");
});
  },
  methods: {
    redirecttobreadkumb: function (objThis) {
      if (objThis.rowdata) {
        objThis.rowdata.backbtnstate = objThis.btnstate;
      } else {
        objThis.rowdata = {};
        objThis.rowdata.backbtnstate = objThis.btnstate;
      }
      this.$router.push({
        name: objThis.name,
        path: "/" + objThis.path,
        params: {
          backbtnstate: objThis.btnstate,
          clickeddata: objThis.rowdata,
        },
      });
    },
    alphanumericInput() {
      this.application.graylogdashboardid = this.application.graylogdashboardid.replace(/[^a-zA-Z0-9]/g, "");
    },
    validateInput(inputField) {
      const inputValue = inputField.value;
      const pattern = /^[a-zA-Z0-9-_]+$/;
      if (!pattern.test(inputValue)) {
        alert("Please enter only letters, numbers, hyphens, and underscores.");
        inputField.value = inputValue.replace(/[^a-zA-Z0-9-_]/g, '');
      }
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (rowdata.projectlevel == 3 && btnstate == null) {
        this.$router.push({
          name: "Work Step",
          path: "/workstep",
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata,
          },
        });
        return;
      } else if (btnstate == "view") {
        this.$router.push({
          name: "Project Module",
          path: "/projectmodule",
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata,
          },
        });
      } else if (btnstate == "viewapp") {
        rowdata.searchProject = rowdata.projectid;
        rowdata.searchApplication = rowdata.applicationid;
        this.$router.push({
          name: "Project Application Module",
          path: "/applicationmodule",
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata,
          },
        });
      } else if (btnstate == "pathApp" || btnstate == "pathAppAdd") {
        delete rowdata.projectmoduleid;
        rowdata.searchProject = rowdata.projectid;
        rowdata.searchApplication = rowdata.applicationid;
        rowdata.backbtnstate = btnstate;
        this.$router.push({
          name: "Project Application Module",
          path: "/applicationmodule",
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata,
          },
        });
      } else if (btnstate == 'projectId') {
        this.$router.push({
            path: '/project/summary?pid='+rowdata.projectid,
        })
      }
    },
    redirecttoEnvurl(btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      if (btnstate == "view") {
        this.$router.push({
          name: "appenvironmentList",
          path: "/appenvironment/list",
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata,
          },
        });
      } else if (btnstate == "add") {
        this.$router.push({
          name: "appenvironmentCreate",
          path: "/appenvironment/create",
          params: {
            backbtnstate: btnstate,
            clickeddata: rowdata,
          },
        });
      }
    },
    redirecttowsurl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      this.$router.push({
        name: "Work Step",
        path: "/workstep",
        params: {
          backbtnstate: btnstate,
          clickeddata: rowdata,
        },
      });
    },
    redirecttoProjModule(btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      this.$router.push({
        name: "Project Module",
        path: "/projectmodule",
        params: {
          backbtnstate: btnstate,
          clickeddata: rowdata,
        },
      });
    },
    mysearchrequestollapse: function () {
      this.isSearchRequestActive = !this.isSearchRequestActive;
    },
    selappapltype: function (state, value) {
      this.application.applicationtype = null;
      this.application.applicationtype = state;
      if (!state && !this.isAddState) {
        this.errors.errorapplicationtype = "Application type required";
      } else {
        this.errors.errorapplicationtype = null;
      }
    },
    selappaplaccess: function (state, value) {
      this.application.applicationaccess = null;
      this.application.applicationaccess = state;
      if (!state && !this.isAddState) {
        this.errors.errorapplicationaccess = "Application access required";
      } else {
        this.errors.errorapplicationaccess = null;
      }
    },
    getaccessofapplicationlist: function () {
      let url = "api/projectapplication/getaccessofapplicationlist";
      this.input = {
        accessofapplicationid: 0,
        empcode: this.undt.userid,
        useremail: this.undt.username
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.application.applicationaccesslist = result.data.data;
        } else {
          this.application.applicationaccesslist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getapplicationtypelist: function () {
      this.isLoading = true;
      let url = "api/projectapplication/getapplicationtypelist";
      this.input = {
        applicationtypeid: 0,
        useremail: this.undt.username,
        empcode: this.undt.userid

      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.application.applicationtypelist = result.data.data;
        } else {
          this.application.applicationtypelist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getapplictionlist: function (projectid) {
      let url = "api/projectapplication/getAplicationlist";
      this.input = {
        projectid: projectid,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        // if (result.data.errorCode == 0) {
        //   result.data.data.rows;
        // } else {
          
        // }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getmodulelist: function () {
      let url = "api/projectapplication/list";
      this.input = {
        projectid: 0,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.modulelist = result.data.data;
        } else {
          this.modulelist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getFilter: function () {
      this.isLoading = true;
      let url = "api/application/list";
      this.input = {
        offset: 1,
        tbl: "t11",
        limit: 1000,
        isSearch: true,
        section: "application",
        isFilterSelect: 1,
        isFilterPayload: {},
      };
      if (this.search.searchProjectname != null) {
        this.input.projectid = this.search.searchProjectname;
      } else if (this.search.searchProjectmodulename) {
        this.input.projectmoduleid = this.search.searchProjectmodulename;
      }
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.modulelist = result.data.data.rows;
        } else {
          this.modulelist = [];
          this.isSearch = true;
        }
      });
    },
    resetData: function () {
      (this.application.name = null),
      (this.application.desc = null),
      (this.application.applicationtype = null),
      (this.application.applicationaccess = null);
      this.application.gitlabreponame= "",
      this.application.jenkinsjobname="",
      this.application.graylogdashboardid=""
    },
    selectproject: function (state, value) {
      this.search.searchProjectname = state;
      if (!state) {
        this.search.applicationopts = null;
      } else {
        this.getDropdownApplicationdata("t12");
      }
    },
    selectproject2: function (state) {
      this.search.searchProjectname = state.id;
      if (!state.id) {
        this.search.searchApplication = null;
        this.search.applicationopts = null;
      } else {
        this.search.searchApplication = null;
        this.getDropdownApplicationdata("t12");
      }
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption";
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      if (mdl === "t12") {
        this.input.projectMasterid = this.search.searchProjectname;
      }
      axios({
        method: "POST",
        url: apiUrl,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (mdl === "t12") {
            this.search.applicationopts = result.data.data;
          }
        } else {
          this.search.applicationopts = [];
        }
        this.loadApplicationName("t12");
      });
    },
    loadApplicationName(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption";
      this.input = {
        tbl: mdl,
        projectMasterid: this.search.searchProjectname,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      axios({
        method: "POST",
        url: apiUrl,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.search.applicationopts = result.data.data;
        } else {
          this.search.applicationopts = [];
        }
      });
    },
    selectprojectmodule: function (state, value) {
      this.search.searchProjectmodulename = state;
    },
    resetRecords: function () {
      this.projectnameList = null;
      this.search.applicationopts = null;
      this.search.searchProjectname = null;
      this.search.searchApplication = null;
      localStorage.removeItem("applicationmastersearchItems");
      let rowdata = {};
      this.getprojectapplicationlist(rowdata);
    },
    getProjectname: function () {
      this.apiURL = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage: 'createpage'
      };
      axios({
        method: "POST",
        url: this.apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.application.projectnameList = result.data.data;
        } else {
          this.application.projectnameList = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getProjectnamebyid: function (projectidarr) {
      if (projectidarr && projectidarr.length > 0) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectidarr: projectidarr,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: 'isprojectid'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            if(result.data.data && result.data.data.length > 0){
              this.application.projectnameList.push(result.data.data[0]);
            }
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    getProjectModulename: function () {
      this.apiURL = "api/listing/getprojectmoduledatabyprojectid";
      this.input = {
        tbl: "t36",
        projectid: this.search.searchProjectname,
        useremail: this.undt.username,
        empcode: this.undt.userid

      };
      axios({
        method: "POST",
        url: this.apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.projectmodulenameList = result.data.data;
        } else {
          this.projectmodulenameList = [];
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    projectChange: function (node) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    projectChangebyid: function (projectidarr) {
      if (projectidarr && projectidarr.length > 0) {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectidarr: projectidarr,
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    applicationChange: function (node, instanceId) {
      if (node && node.length > 1) {
        this.isLoading = true;
        let url = "api/master/commonmaster/getModuleDropdownOption";
        this.input = {
          tbl: "t12",
          applicationname: node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.applicationopts = result.data.data;
          } else {
            this.search.applicationopts = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    getSearchItems(search) {
      this.isSearchRequestActive = true;
      this.search.searchProject = this.$route.params.clickeddata.projectid ? this.$route.params.clickeddata.projectid : this.search.searchProject ? this.search.searchProject : search.searchProject ? search.searchProject : search.projectid;
      this.search.searchProjectname = this.search.searchProjectname ? this.search.searchProjectname : search.projectid ? search.projectid : search.searchProjectname ? search.searchProjectname : '';
      this.search.searchApplication = search.applicationid ? search.applicationid : search.searchApplication ? search.searchApplication : null ;

      this.search.projectmoduleid = this.search.projectmoduleid ? this.search.projectmoduleid : search.projectmoduleid ? search.projectmoduleid :null;
      
      if(this.search.searchProjectname && this.search.searchProjectname!=null){
        this.getDropdownApplicationdata("t12");
      }
      this.projectChangebyid(this.search.searchProject ? [this.search.searchProject] :[]);
      this.getprojectapplicationlist(this.search);
    },
    getprojectapplicationlist: function (rowdata) {
      this.islistLoading = true;
      this.clickedprojdata = rowdata;
      if (rowdata) {
        localStorage.setItem("applicationmastersearchItems", JSON.stringify(rowdata));
      }
      this.input = {
        projectid: rowdata.projectid ? rowdata.projectid : rowdata.searchProjectname ? rowdata.searchProjectname : rowdata.projectid,
        applicationMasterid: rowdata.searchApplication,
        projectmoduleid: rowdata.projectmoduleid,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        uniqueid: this.undt.userid,
        useremail: this.undt.username,
        empcode: this.undt.userid

      };
      let url = "api/application/list";
      this.getApplicationdata("t3", "application");
      this.getApplicationdata("t2", "application");
      this.getApplicationdata("t1", "application");
      this.application.projecttypeid = this.clickedprojdata.projecttypeid;
      this.application.businessverticleid =
        this.clickedprojdata.businessverticleid;
      this.application.companyid = this.clickedprojdata.companyid;
      this.application.project = this.clickedprojdata.projectname;
      this.application.projectid = this.clickedprojdata.projectid;
      this.application.projectmodule = this.clickedprojdata.projectmodulename;
      this.application.projectmoduleid = this.clickedprojdata.projectmoduleid;
      this.application.applicationid = this.clickedprojdata.moduleid;
      this.application.applicationid = this.clickedprojdata.moduleid;
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isresponce=true
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.application.label = rowdata.label;
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.companymasterlist = [];
          this.application.label = rowdata.label;
          this.showproject = true;
          this.totalcount = 0;
        }
        setTimeout(function () {
          if (rowdata.backbtnstate == "edit") {
            $("#commonaddmodule").click();
          }
        }, 1000);
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
         btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectapplicationlist(this.clickedprojdata);
      }
        setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#applicationTable").offset().top -70
                });
            }, 500);
    },
    validText: function (inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    valid2Text: function (inputval) {
      var re = /[A-Za-z0-9].{0,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validate: function (tblNm) {
      this.projectBlured = true;
      this.projectdescBlured = true;
      this.projectcountryBlured = true;
      this.appenvhosturlBlured = true;
      this.appenvhostdetailspathBlured = true;
      this.appenvhostpathBlured = true;
      this.appenvhostBlured = true;
      this.appenvnameBlured = true;
      this.appenvtypeBlured = true;
      this.applinameBlured = true;
      if (tblNm == "t12") {
        if (!this.application.projectid) {
          this.errors.projectid = "Project name is required";
          this.valid = false;
        } else {
          this.errors.projectid = null;
        }
        if (!this.application.applicationtype) {
          this.errors.errorapplicationtype = "Application type is required";
          this.valid = false;
        } else {
          this.errors.errorapplicationtype = null;
        }
        if (!this.application.applicationaccess) {
          this.errors.errorapplicationaccess =
            "Network accessibility type is required";
          this.valid = false;
        } else {
          this.errors.errorapplicationaccess = null;
        }
      }
      if (
        tblNm == "t11" &&
        this.validText(this.project.name) &&
        this.validText(this.project.desc)
      ) {
        this.valid = true;
      }
      if (tblNm == "t12" && this.validText(this.application.name)) {
        this.valid = true;
      }
      if (
        tblNm == "t13" &&
        this.validText(this.appenv.name) &&
        this.validText(this.appenv.host) &&
        this.validText(this.appenv.hostpath) &&
        this.validText(this.appenv.hostdetails) &&
        this.validText(this.appenv.hosturl) &&
        this.valid2Text(this.appenv.type)
      ) {
        this.valid = true;
      }
    },
    validateForm: function (mdl, objThis) {
      this.clearerrorForm();
      this.isAddState = false;
      var isValid = true;
      if (mdl == "t36") {
        if (
          this.proejctmodule.project == null ||
          this.proejctmodule.project == "" ||
          this.proejctmodule.project.length == 0
        ) {
          this.errors.proejctmodule.project = "Project required";
          isValid = false;
        }
      }
      return isValid;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clearerrorForm: function () {
      this.errors = {
        proejctmodule: {
          name: null,
          project: null,
          desc: null,
          attachment: null,
          attachmentview: null,
          disblesavebtn: false,
        },
      };
    },
    openEditModule: function (currRowData) {
      this.editRowData = currRowData;
      this.application.name = currRowData.applicationname;
      this.application.applicationid = currRowData.applicationid;
      this.application.projectid = currRowData.projectid;
      if(this.application.projectnameList && this.application.projectnameList.length > 0 && (this.application.projectid && this.application.projectid !=null)){
          const checkexistproject = this.application.projectnameList.some(item =>item.id === this.application.projectid);
          if(!checkexistproject){
            this.getProjectnamebyid(this.application.projectid ? [this.application.projectid] :[]);
          }
      }
      this.application.projecttypeid = currRowData.projecttypeid;
      this.application.businessverticleid = currRowData.businessverticleid;
      this.application.companyid = currRowData.companyid;
      this.application.desc = currRowData.description;
      this.application.applicationtype = currRowData.applicationtype;
      this.application.applicationaccess = currRowData.applicationaccess;
      this.application.gitlabreponame = currRowData.gitlabreponame;
      this.application.jenkinsjobname = currRowData.jenkinsjobname;
      this.application.graylogdashboardid = currRowData.graylogdashboardid;
      this.application.iseditstate = true;
      this.isAddState = true;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format("DD-MM-YYYY");
    },
    dateMONFormat(value) {
      if (!value){
        return "";
      }else{
        return moment(String(value)).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    onSelect: function () {
      this.file = this.$refs.file.files[0];
    },
    getAttachment: function (currRow) {
      this.apiURL = "api/master/fileupload/getImageFileUrl";
      this.inputattachment = {
        empcode: this.undt.userid,
        moduleid: currRow.id,
        useremail: this.undt.username,

      };
      axios({
        "content-type": "multipart/form-data",
        method: "POST",
        url: this.apiURL,
        data: this.inputattachment,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.status) {
          this.proejctmodule.disblesavebtn = false;
          this.proejctmodule.attachmentview = result.data.data;
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    async onUpload() {
      this.isLoading = true;
      if (this.file) {
        if (this.file.name !== "") {
          let arrGlbMdl = this.glbMdl.split("##");
          let inputEle = "";
          let btnEle = "";
          if (arrGlbMdl.length > 0) {
            if (typeof arrGlbMdl[0] != "undefined") {
              inputEle = arrGlbMdl[0];
            }
            if (typeof arrGlbMdl[1] != "undefined") {
              btnEle = arrGlbMdl[1];
            }
          }
          if (inputEle == "t36") {
            btnEle = "prmdl";
          }
          this.apiURL = "api/master/fileupload/uploadFileToS3";
          let formData = new FormData();
          formData.append("imagefile", this.file);
          formData.append("empcode", this.undt.userid);
          formData.append("useremail",this.undt.username);
          formData.append("moduletype", btnEle);
          formData.imagefile = this.file;
          formData.empcode = this.undt.userid;
          formData.moduletype = btnEle;
          await axios({
            "content-type": "multipart/form-data",
            method: "POST",
            url: this.apiURL,
            data: formData,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.status) {
              this.proejctmodule.disblesavebtn = false;
              this.proejctmodule.attachment = result.data.data;
              this.proejctmodule.attachmentview = result.data.data;
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          });
        } else {
          Swal.fire({
            title: "",
            text: "Select image/docs/videos",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.proejctmodule.attachment = null;
        }
      } else {
        Swal.fire({
          title: "",
          text: "Select image/docs/videos",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
        this.proejctmodule.attachment = null;
      }
    },
    saveData: function (tblNm, objThis) {
      if (tblNm == "t12") {
        this.validate(tblNm);
      } else {
        this.valid = true;
      }
      if (this.valid) {
        let nameobj = document.getElementById("modulename" + tblNm);
        this.input = {
          tbl: tblNm,
          section: "assignment",
          createdby: this.undt.username,
          useremail: this.undt.username,
          empcode: this.undt.userid

        };
        if (nameobj != null && nameobj != "undefined") {
          this.input.name = nameobj.value;
        }
        let apiURL = "api/application/create";
        if (this.application.iseditstate == true) {
          apiURL = "api/application/update";
          this.input.lastmodifiedby = this.undt.username;
          this.input.id = this.application.applicationid;
          delete this.input.createdby;
        }
        if (tblNm == "t12") {
          if (this.application.name) {
            this.input.name = this.application.name;
          }
          if (this.application.projectid) {
            this.input.appprojectdd = this.application.projectid;
          }
          this.input.projectmodule = null;
          if (this.application.applicationtype) {
            this.input.applicationtype = this.application.applicationtype;
          }
          if (this.application.techapplicationtype) {
            this.input.techapplicationtype =
              this.application.techapplicationtype;
          }
          if (this.application.applicationaccess) {
            this.input.applicationaccess = this.application.applicationaccess;
          }
          if (this.application.desc) {
            this.input.description = this.application.desc;
          }
          if (this.application.gitlabreponame) {
            this.input.gitlabreponame = this.application.gitlabreponame;
          }
          if (this.application.jenkinsjobname) {
            this.input.jenkinsjobname = this.application.jenkinsjobname;
          }
          if (this.application.graylogdashboardid) {
            this.input.graylogdashboardid = this.application.graylogdashboardid;
          }
        }

        this.isAddState = false;
        this.input.useremail= this.undt.username
        this.input.empcode= this.undt.userid

        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          if (result.data.errorCode == 0) {
            $(".close").click();
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
            var rowdata = {};
            if (this.clickedprojdata.backbtnstate == "addapplication") {
              rowdata = {
                label: this.application.label,
                projectmoduleid: this.application.projectmoduleid,
                projecttypeid: this.application.projecttypeid,
                businessverticleid: this.application.businessverticleid,
                companyid: this.application.companyid,
                projectname: this.application.project,
                projectid: this.application.projectid,
                projectmodulename: this.application.projectmodule,
                backbtnstate: this.clickedprojdata.backbtnstate,
              };
            }
            this.getprojectapplicationlist(this.search);
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            Swal.fire({
              title: "Oops...!",
              text: result.data.msg,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    getApplicationdata: function (mdl, curracttab) {
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid

      };
      let apiURL = "api/master/commonmaster/dropdownlist";
      if (curracttab == "application") {
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,
          empcode: this.undt.userid
        };
      }
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          if (mdl == "t2") {
            this.application.businesslist = result.data.data.rows;
            return true;
          } else if (mdl == "t3") {
            if (curracttab == "application") {
              this.application.projecttypelist = result.data.data.rows;
              return true;
            }
          } else if (mdl == "t1") {
            this.application.companylist = result.data.data.rows;
            return true;
          }
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    addModule: function () {
      this.application.name = null;
      this.application.desc = null;
      this.application.applicationtype = null;
      this.application.applicationaccess = null;
      this.application.gitlabreponame = null;
      this.application.jenkinsjobname = null;
      this.application.graylogdashboardid = null;
      this.application.iseditstate = false;
      this.isSearchRequestActive = true;
    },
    selProject(state, value) {
      this.application.projectid = null;
      this.application.projectid = state;
      if (!state && !this.isAddState) {
        this.application.projectid = "Project id required";
      } else {
        this.errors.projectid = null;
        this.application.projectid = state;
        this.application.projecttypeid = state.projecttypeid;
        this.application.businessverticleid = state.businessverticleid;
        this.application.companyid = state.companyid;
      }
    },
    getProjectbyProjectID: function (projectid) {
      this.apiURL = "api/projectapplication/getprojectnamelist";
      this.input = {
        projectid: projectid,
        useremail: this.undt.username,
        empcode: this.undt.userid
      };
      axios({
        method: "POST",
        url: this.apiURL,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.application.projecttypeid = result.data.data.projecttypeid;
          this.application.businessverticleid =
            result.data.data.businessverticleid;
          this.application.companyid = result.data.data.companyid;
        } else {
        }
      });
    },
    ExportXL() {
      this.islistLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit: null,
        offset: null,
        projectid: this.search.searchProjectname,
        applicationMasterid: this.search.searchApplication,
        useremail: this.undt.username,
        empcode: this.undt.userid

      };
      axios({
        method: "POST",
        url: `api/application/list`,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.islistLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download(result.data.data.rows, filename);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          }else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    download(readExportdata, filename) {
      if (readExportdata.length > 0) {
        var ShowLabel = "applicationreport" + filename;
        var JSONData = readExportdata;
        var arrData =
          typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
        var CSV = "";
        if (ShowLabel) {
          let row = "";
          for (let index in arrData[0]) {
            row += index + ",";
          }
          row = row.slice(0, -1);
          CSV =
            "Sr#,Application Name,Description,Project Name,Total App Modules,Total App Env,Repo Name,Jenkins Job Name,Created Date,GRAYLOG Dashboard ID" +
            "\r\n";
        }
        var row = "";
        for (let i = 0; i < arrData.length; i++) {
          let index = arrData[i];
          let srno = i + 1;
          let applicationname = index.hasOwnProperty("applicationname") ? index.applicationname : "";
          let description = index.hasOwnProperty("description") ? index.description : "";
          let projectname = index.hasOwnProperty("projectname") ? index.projectname : "";
          let totalappmodule = index.hasOwnProperty("totalappmodule") ? index.totalappmodule : "";
          let totalappenv = index.hasOwnProperty("totalappenv") ? index.totalappenv : "";
          let gitlabreponame = index.hasOwnProperty("gitlabreponame") ? index.gitlabreponame : "";
          if(gitlabreponame==null){
            gitlabreponame=''
          }
          let jenkinsjobname = index.hasOwnProperty("jenkinsjobname") ? index.jenkinsjobname : "";
          if(jenkinsjobname==null){
            jenkinsjobname=''
          }
          let graylogdashboardid = index.hasOwnProperty("graylogdashboardid") ? index.graylogdashboardid : "";
          if(graylogdashboardid==null){
            graylogdashboardid=''
          }
          let createddate = index.hasOwnProperty("createddate") ? index.createddate : "";
          createddate = this.dateMONFormat(createddate);
          row += '"' + srno + '",'
          row += '"' + applicationname + '",'
          row += '"' + description + '",'
          row += '"' + projectname + '",'
          row += '"' + totalappmodule + '",'
          row += '"' + totalappenv + '",'
          row += '"' + gitlabreponame + '",'
          row += '"' + jenkinsjobname + '",'
          row += '"' + createddate + '",'
          row += '"' + graylogdashboardid + '",'
          row += "\r\n";
        }
        CSV += row;
        if (CSV === "") {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName =
          "applicationreport" +
          filename +
          "_" +
          moment().format("DD_MM_YY_HH_mm_ss");
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);
        var link = document.createElement("a");
        link.href = uri;
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      }
    },
    export(data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      download(blob, filename, mime);
    },
  },
};
</script>
