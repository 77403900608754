<template>
    <div class="app-content content">
  <loading
    :active.sync="isLoading"
    :can-cancel="false"
    :is-full-page="fullPage"
    color="orange"
    loader="dots"
    :width="100"
    :height="100"
  ></loading>
  <div class="content-overlay"></div>
  <div class="header-navbar-shadow"></div>

     <div class="content-wrapper">
          <div class="content-header-left mb-1 pt-0 ">
          <div class="container-fluid">
              <div class="row breadcrumbs-top">
                  <div class="col-md-9 content-header-left px-0 ">
                      <h3 class="content-header-title float-left mb-0">Bulk Attendance </h3>
                      <div class="breadcrumb-wrapper">
                          <ol class="breadcrumb">
                              <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link
                                      to=''>Approval</router-link></li>
                          </ol>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <section id="modern-horizontal-wizard">
         <div class="" id="projecttabmaster">
          
          <div class="card filter_card_searchbgst" >
              <div class="card-header filter_rotation_onlick " style="cursor:pointer"  v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                      <div class="row row fc_my-05">
                          <div class="w-auto px-1 mobile-padding-0-2">
                              <div class="avatar bg-light-secondary rounded d-inline-flex">
                                  <div class="avatar-content">
                                      <em class="las la-user custom-class font-medium-3"></em>
                                  </div>
                              </div>
                              <h4 class="card-title d-inline-flex ml-1">Search Bulk Attendance Approval</h4>
                          </div>
                          <span class="rotetions_view_arrow ml-auto mx-1"> 
                              <em class="las la-angle-down"></em>
                          </span>
                      </div>
                  </div>
               </div>
          
              <div class="card-body px-sm-mobile-10 pt-1" v-bind:class="[{show : isSearchRequestActive},card_content,collapse]">
                  <div class="row " >
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <div class="form-group mb-1">
                            <label for="allotedto">Stockist</label>
                            <treeselect class="allotedto capitalisetext" placeholder="Select Stockist" :clear-on-select="true" v-model="search.stockist" :options="search.stockistlist" @input="selstokist" />
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                            <div class="form-group mb-1">
                            <label for="allotedto">User ID</label>
                            <treeselect class="allotedto capitalisetext" placeholder="Select Employee" v-model="search.userid" :options="search.emplist"/>
                            </div>
                        </div>

                        
                          <div class="col-sm-12 col-md-4 col-lg-4 mb-0 text-right">
                          <button type="button" class="btn btn-relief-primary mt-2 mr-1" @click="getBulkAttendanceList()" > 
                          <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                          <button type="reset" class="btn btn-outline-secondary mt-2 mr-1"  v-on:click="resetRecords">
                          <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                          </div>
                  </div>
              </div>
        </div>
          <div class="table-responsive datatableview " style="margin-top: 45px;" >

              <div  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top:1rem; margin-bottom: 1rem !important;">
                    <div>
                        <div class="row">
                        <div class="col-md-12 1">
                            <div class="card ">
                            <div class="">
                                <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="row" id="table-small">
                                    <div class="col-12">
                                        <div class="card">
                                        <div class="" >
                                        </div>
                                        <div class="table-responsive datatableview" >
                                            <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                <th scope="col" class="align-middle first-col-sticky py-1" style="min-width: 100px;">
                                                    
                                                    <span class="px-1"> Sr#</span>
                                                </th>
                                                <th scope="col" class="align-middle text-center ">User ID</th>
                                                <th scope="col" class="align-middle text-center" style="min-width:130px">User Name</th>
                                                <th scope="col" class="align-middle text-center " >Stockist Name</th>
                                                <th scope="col" class="align-middle text-center" >No of Regularisation Days</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(userRecords, userID,index) in groupedByUserID">
                                                    <tr v-bind:key="userID">
                                                        <td data-column="Sr#" >
                                                            <span class="">
                                                                <span class="ml-1"> {{ index +1 }} </span>
                                                            </span>
                                                        </td>
                                                        <td class="align-middle text-center ">{{ userRecords[0].userID }}</td>
                                                        <td class="align-middle text-center capitalisetext">{{ userRecords[0].userName }}</td>
                                                        <td class="align-middle text-center ">{{ userRecords[0].department }}</td>
                                                        <td class="align-middle text-center sitebluecolor cursorpointercls">
                                                            <span @click="openPopup(userRecords[0])">
                                                                {{ userRecords.length }}
                                                            </span>
                                                        </td>

                                                    </tr>

                                                    <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                            <h4 class="modal-title ">Bulk Attendance Regularisation</h4>
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetdatesRecords()" >
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                            </div>
                                                            <div class="modal-body mb-2">
                                                                <div class="card card open_oranges shadow-none rounded mb-1" style="border-radius: 5px; border: none!important;">
                                                                    <div class="card-header" style="cursor:pointer">
                                                                        <div class="container-fluid">
                                                                            <h6 class="text text-left capitalisetext">
                                                                                Employee : 
                                                                                <span style="color: blue;">{{ userRecords[0].userName }}</span> 
                                                                                (<span style="color: blue;">{{ userRecords[0].userID }}</span>)
                                                                            </h6>
                                                                            <div class="row">
                                                                                <div class="col-md-9" >
                                                                                    <label for="projectmdllistdd">Select Dates For Approval</label>
                                                                                    <treeselect class="projectmdllistdd capitalisetext rounded-border" placeholder="Select Dates" v-model="selectedDates" :multiple="true" :options="selectedUserDatesList"/>
                                                                                </div>
                                                                            <div class="col-md-3 px-0">
                                                                                 <label class="w-100 d-block"> &nbsp;</label>
                                                                                <button type="button" class="btn btn-relief-success mt-0" :disabled="selectedDates.length === 0" @click="bulkRegularisation(userRecords[0].userID)"><check-icon size="1.5x" class="custom-class"></check-icon>Approve</button>
                                                                            </div>
                                                                        </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                        </div>
                                                    </div>
                                                    
                                                </template>

                                                <!-- <template v-if="empList.length==0">
                                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </template> -->
                                            </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <!-- <div class="pageCenter text-center">
                                <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                </div> -->
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
          </div>


       </div>
      </section>
      </div>
     </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { SearchIcon, MoreVerticalIcon, EditIcon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon,CheckIcon,CalendarIcon,BoxIcon } from 'vue-feather-icons'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'


export default {
  name:'BulkAttendance',
  components:{
      Treeselect,
      SearchIcon,
      ListIcon,
      XIcon,
      Loading,
      CheckIcon,
      CalendarIcon,
      EditIcon
  },
  data(){
     return{
      Stocknamelist:null,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      stockist:null,
      stockistList:[],
      isLoading: false,
      fullPage: true,
      search:{
          empid: null,
          logindatefrom: null,
          status: null,
          type: null,
          stockist: null,
          month:null,
          logindateto: null,
          emplist: [],
          stockistlist: [],
          userid:null
        },

        errors:{
          search:{
            logindatefrom: null,
            logindateto: null,
          }
        },
        emplist: [],
        tokendata: null,
        allempList:[],
        isChecked: [],
        currPageNum: 0,
        pagelimit:apiUrl.LIMIT,
        selectedIds: [],
        userID: '', 
        userName: '',
        startDate:'',
        endDate:'',
        selectedDates: [],
        dateslist: [], 
        groupedByUserID: {},
        selectedUserDatesList:[],
     } 

     
  },

  mounted(){
      $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
      });
      let userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');
          this.getstockistlist();
          this.getBulkAttendanceList()
        }
  },

  created() {
        this.updateDatesList();
    },

    watch: {
        startDate(newValue, oldValue) {
            this.updateDatesList();
        },
        endDate(newValue, oldValue) {
            this.updateDatesList();
        }
    },
  methods:{

  mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    updateDatesList() {
        this.dateslist = this.getDatesBetween(this.startDate, this.endDate).map(date => ({
            label: date,
            id: date
        }));
    },
    openPopup(userRecord) {
        this.selectedUserRecord = userRecord;
        this.selectedUserDatesList = userRecord.dateslist.map(date => ({ 
            id: date,
            label: date }));
        $('#technologypopup').modal('show');
    },

    getDatesBetween(startDate, endDate) {
        const dates = [];
        const currentDate = new Date(startDate);
        while (currentDate <= new Date(endDate)) {
            dates.push(currentDate.toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    },
    getstockistlist() {
        this.isLoading =true;
        this.input ={
        useremail : this.undt.username,
        empcode: this.undt.userid
        }
        axios({
        'method': 'POST',
        'url': 'api/thirdpartyfcclock/getStokistList',
        'data': this.input,
        'headers': {'authorization':this.tokendata}
        })
        .then(result => {
        this.isLoading =false;
        if (result.data.errorCode == 0) {
            this.search.stockistlist = result.data.data.rows.data;
        }else if(result.data.errorCode == 3){
            Swal.fire({
            title: "Session Expired...!!",
            text: result.data.msg,
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
            }).then(function() {
            window.location.href = "/#/login";
            })
        }else {
            this.stockistlist = [];
        }
        }).catch(e => {
        this.displayError(e);
        })
    },
    selstokist(state){
        if(state){
        this.getstokistemp(state.toString());
        }
    },
    getstokistemp(stokist){
    this.isLoading = true;
    this.input ={
      useremail: this.undt.username,
      empcode: this.undt.userid,
      department: stokist
    }
    axios({
      'method': 'POST',
      'url': 'api/thirdpartyfcclock/getUserList',
      'data': this.input,
      'headers': {'authorization':this.tokendata}
    })
    .then(result => {
      this.isLoading = false;
      if(result.data.errorCode == 0){
        this.search.emplist = result.data.data.rows;
      }else if(result.data.errorCode == 3){
        Swal.fire({
          title: "Session Expired...!!",
          text: result.data.msg,
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        }).then(function() {
          window.location.href = "/#/login";
        })
      }else{
        this.search.emplist = [];
      }
    }).catch(e => {
      this.displayError(e);
    });
  },
  getBulkAttendanceList() {
    this.isLoading = true;
    this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
    }
    this.input.employeeIDs = this.search.userid ? this.search.userid : null;
    
    axios({
        method: 'POST',
        url: 'api/thirdpartyfcclock/getBulkAttendanceList',
        data: this.input,
        headers: { 'authorization': this.tokendata }
    })
    .then(result => {
        this.isLoading = false;
        if (result.data.errorCode === 0) {
            this.allempList = result.data.data.responsedata[0];
            
            const groupedByUserID = {};
            this.allempList.forEach(record => {
                const userID = record.userID;
                if (!groupedByUserID[userID]) {
                    groupedByUserID[userID] = [];
                }
                groupedByUserID[userID].push(record);
            });

            Object.keys(groupedByUserID).forEach(userID => {
                const records = groupedByUserID[userID];
                const noofregularisation = records.length;
                const dateslist = records.map(record => record.date);
                records.forEach(record => {
                    record.noofregularisation = noofregularisation;
                    record.dateslist = dateslist;
                    record.selectedDates = [];

                });
            });
            this.groupedByUserID = groupedByUserID;


        } else if (result.data.errorCode === 3) {
            Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            }).then(function() {
                window.location.href = "/#/login";
            })
        } else {
            this.totalcount = 0;
            this.pageCount = 0;
            this.allempList = [];
        }
    })
    .catch(e => {
        this.displayError(e);
    });
},

    empSelection(id) {
        const index = this.selectedIds.indexOf(id);
        if (index === -1) {
            this.selectedIds.push(id);
        } else {
            this.selectedIds.splice(index, 1);
        }
    },

  resetRecords: function() {
      this.search.stockist=null,
      this.search.userid=null,
      this.getBulkAttendanceList()
    },
    resetdatesRecords: function(){
        this.selectedDates = null
        this.getBulkAttendanceList()

    },
    bulkRegularisation(selectedUserid) {
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                userid: selectedUserid,
                selecteddate: this.selectedDates ? this.selectedDates : [],
                status : 'approved',
            }
            let apiURL = 'api/thirdpartyfcclock/bulkRegularisation'
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
            if(result.data.errorCode === 0) {
                this.getBulkAttendanceList();
                this.resetRecords()
                $('#technologypopup').modal('show');
                Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: { confirmButton: "btn btn-primary" },
                buttonsStyling: !1,
                })
            } else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                buttonsStyling: !1,
                })
            } else {
                    Swal.fire({
                    text: result.data.msg,
                    icon: "info",
                    customClass: { confirmButton: "btn btn-primary" },
                    buttonsStyling: !1,
                })
            }
                this.isChecked = []
            })
        },

  } 



}
</script>

<style>
.below-table td{
background: #fff;
}





</style>