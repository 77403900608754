<template>
  <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section class="pb-1" id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0 ">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left px-0 ">
                <h3 class="content-header-title float-left mb-0">Ticket Request Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/ticket/list'>Ticket Request Management List</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
            <div class="row">
              <div class="col-9 col-xs-12 col-sm-auto col-md-8 m-side_form_head_580">
                <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                        <search-icon
                        size="1.5x"
                        class="custom-class avatar-icon font-medium-3"
                        ></search-icon>
                    </div>
                    </div>
                <h4 class="card-title d-inline-flex ml-1">Search Ticket Request Management Details</h4>
            </div>
              <div class="ml-auto d-flex" >
                <div class="input-group-append float-right mr-1">
                  <router-link to="/ticket/create"><button id="commonaddmodule" class="btn btn-sm btn-relief-success" data-backdrop="static" data-keyboard="false"> <span class="mobile_hide_bt"> Create Ticket</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon></button></router-link>
                </div>
                <span class="rotetions_view_arrow ml-auto mx-1"> 
                  <em class="las la-angle-down"></em>
                </span>
              </div>
              
            </div>
          </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
            <div class="card-body">
              <div class="row">
                 <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                   <label class="form-label lable-left">Ticket Code</label>
                   <input type="text" class="form-control" v-model="search.issuecode" placeholder="Search Ticket Code" aria-label="Amount" :maxlength="maxLength"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectname" :options="search.projectnameList" @input="selissuecsprojectSearch" :disabled="this.isThisMerchandiseProject" :multiple="true" :clear-on-select="true"/>
                </div>
                <div v-if="search.searchprojectname == merchandisingProId" class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Brand</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Brand" v-model="search.projectmodulename" :options="search.projectmodulelist" @input="selProjectModule"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="search.searchprojectname == merchandisingProId">
                  <label class="form-label lable-left">Category</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Category" v-model="search.applicationname" :options="search.applicationlist" @input="selApplication"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="search.searchprojectname == merchandisingProId">
                  <label class="form-label lable-left">Sub Category</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Sub category" v-model="search.applicationmodulename" :options="search.applicationmodulelist" @input="selApplicationModule"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="search.searchprojectname == merchandisingProId">
                  <label class="form-label lable-left">Product ID/ Product Group ID</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Product ID/ Product Group ID" v-model="search.sectionmodulename" :options="search.sectionmodulelist" @input="selSectionModule"/>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="search.searchprojectname != merchandisingProId">
                  <label class="form-label lable-left">Ticket Category</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Type" v-model="search.projectmodulename" :options="search.projectmodulelist"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="search.searchprojectname != merchandisingProId">
                  <label class="form-label lable-left">Ticket Sub Category</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Type" v-model="search.applicationmodulename" :options="search.applicationmodulelist"/>
                </div>

                <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                  <label v-if="search.searchprojectname == merchandisingProId && search.sectionmodulename == sectionmoduleId1" for="projectmdllistdd">Product ID</label>
                  <label v-else-if="search.searchprojectname == merchandisingProId && search.sectionmodulename == sectionmoduleId2" for="projectmdllistdd">Product Group ID</label>
                  <label v-else for="projectmdllistdd">Functionality</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="select functionality" v-model="search.functionality" :options="search.functionalitylist" @input="selissuecsfunctionalitySearch"/>
                </div>
                <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                  <div class="form-group mg-bot0">
                    <label for="projectmdllistdd">Task</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Task" v-model="search.task" :options="search.tasklist"/>
                  </div>
                </div>
                <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Ticket Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to Ticket name" v-on:search-change="issueChange" v-model="search.issuename" :options="issuenameList"/>
                </div>
                <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                  <div class="form-group mg-bot0">
                    <label for="projectmdllistdd">Type</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Ticket type" v-model="search.type" :options="issue.mrtypelist"/>
                  </div>
                </div>
                <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                  <div class="form-group mg-bot0">
                    <label for="projectmdllistdd">Severity</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Severity" v-model="search.sevairty" :options="issue.sevairtylist"/>
                  </div>
                </div>
                <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                  <div class="form-group mg-bot0">
                    <label for="projectmdllistdd">Priority</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Priority" v-model="search.priority" :options="issue.prioritylist"/>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Created-By</label>
                  
                  <treeselect class="projectmdllistdd"  placeholder="Enter Min 4 Chars To Search Created-By" v-model="search.createdby" :multiple="true" :clear-on-select="true"  :options="search.projectcreatedbyopts" v-on:search-change="createduser" v-if="pagefeatures.allowcreatedby" :disabled="!pagefeatures.multiviewuser"  />
                  <treeselect class="projectmdllistdd" :disabled="!pagefeatures.multiviewuser" placeholder="Select Created-By" :multiple="true" :clear-on-select="true"  v-model="search.createdby" :options="search.projectcreatedbyopts" v-on:search-change="createduser" v-else/> 
                  
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-0">
                  <div class="" >
                    <label for="allotedto">Assigned To</label>
                    <treeselect v-if="search.searchprojectname == merchandisingProId || !search.searchprojectname == null" class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search Assigned To" v-model="search.allotedto"
                    v-on:search-change="allotedChange"
                    :options="search.allotedtolist" :disabled = "!pagefeatures.multiviewuser"/>
                    <treeselect v-else class="allotedto capitalisetext" placeholder="Enter Min 4 Chars To Search Assigned To" v-model="search.allotedto" :multiple="true" :clear-on-select="true" 
                    v-on:search-change="allotedChange" :options="search.allotedtolist" :disabled = "!pagefeatures.multiviewuser"/>
                  </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-0">
                  <label class="form-label" for="">Project Manager</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Project Manager" :options="issue.pmlist" v-model="search.proman" :multiple="true" :clear-on-select="true"/> 
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-0">
                  <label class="form-label" for="">Engineering Manager</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Engineering Manager" :options="issue.emlist" v-model="search.engman" :multiple="true" :clear-on-select="true"/> 
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-0">
                  <label class="form-label" for="">Support Manager</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Support Manager" :options="issue.smlist" v-model="search.supman" :multiple="true" :clear-on-select="true"/> 
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-0">
                  <label class="form-label ">Project Country</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Country Name" :options="issue.countrylist" v-model="search.country" :multiple="true" />
              </div>
                <div class="col-md-6 col-md-6 col-lg-3 mb-1">
                  <div class="form-group mg-bot0">
                    <label for="projectmdllistdd">Status</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="search.status" :multiple="true" :options="tickettrackerstatus"/>
                  </div>
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label for="">Ticket Date From</label>
                    <date-picker placeholder="Select Ticket  Date From" v-model="search.createddatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-0">
                  <label for="">Ticket Date To</label>
                    <date-picker placeholder="Select Ticket  Date To" v-model="search.createddateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-0">
                  <label for="">Resolution Date From</label>
                    <date-picker placeholder="Select Resolution Date From" v-model="search.resolutiondate_from" :disabled-date="notBeforeToday_Reso" valueType="format" class="startdate" :default-value="new Date()" @input="selectedfromdateReso"></date-picker>
                    <div class="errorinputmsg" v-if="isBothDateSelected_reso">Please select both dates to search or export reports.</div> 
                </div>

                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label for="">Resolution Date To</label>
                    <date-picker placeholder="Select Resolution Date To" v-model="search.resolutiondate_to" :disabled-date="disabledBefore_Resp" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodateReso"></date-picker>
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label for="">Response Date From</label>
                    <date-picker placeholder="Select Response Date From" v-model="search.responsedate_from" :disabled-date="notBeforeToday_Resp" valueType="format" class="startdate" :default-value="new Date()" @input="selectedfromdateResp"></date-picker>
                    <div class="errorinputmsg" v-if="isBothDateSelected_resp">Please select both dates to search or export reports.</div> 
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-0">
                  <label for="">Response Date To</label>
                    <date-picker placeholder="Select Response Date To" v-model="search.responsedate_to" :disabled-date="disabledBefore" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodateResp"></date-picker>
                </div>
                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-1">
                    <label for=""> Last Modified Date From</label>
                      <date-picker placeholder="Select LastModified   Date From" v-model="search.lastmodifiedfrom" :disabled-date="notBeforeToday_last" valueType="format" class="startdate" @input="selectedfromdate_last"></date-picker>
                    <div class="errorinputmsg" v-if="isBothDateSelected_last">Please select both dates to search.</div> 
                  </div>
                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3 mb-1">
                    <label for="">Last Modified Date To</label>
                      <date-picker placeholder="Select Last Modified  Date To" v-model="search.lastmodifiedto" :disabled-date="disableDate_last" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate_last"></date-picker>
                  </div>
                <div class="col-sm-12 col-md-6 col-lg-9 mb-1 text-right">
                  <button type="submit" class="btn btn-primary mt-2" :disabled="isBothDateSelected || isBothDateSelected_reso || isBothDateSelected_resp || isBothDateSelected_last" @click="getissuelist(search)"> 
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 mx-1" @click="resetRecords()">
                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                  <button type="move" class="btn btn-relief-primary mt-2" :disabled="isBothDateSelected || isBothDateSelected_reso || isBothDateSelected_resp" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="dashboard-ecommerce">
          <div class="card card-statistics p-1 data_table_padding_ziro_mobile" v-if="chartDetails!=false">
            <div class="dark_theme_style_table">
              <div class="mobile-px-0">
                <div class="table-responsive datatableview datatable_opentickets">
                  <table class="table dataTableListing ticket_table_requestview" aria-describedby="mydesc">
                    <thead class="header-primary">
                      <tr>
                      <th scope="col">&nbsp;</th>
                      <th v-if="this.companycode=='fc'" scope="col" class="text-center">High</th>
                      <th v-if="this.companycode=='fc'" scope="col" class="text-center">Medium</th>
                      <th v-if="this.companycode=='fc'" scope="col" class="text-center">Low</th>
                      <th scope="col" class="text-center">P0</th>
                      <th scope="col" class="text-center">P1</th>
                      <th scope="col" class="text-center">P2</th>
                      <th scope="col" class="text-center">P3</th>
                      <th scope="col" class="text-center">P4</th>
                      <th scope="col" class="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr class="row1 table-danger" >
                    <td data-column="" style="text-align: left;">Open Tickets</td>
                    <td data-column="High" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'open')">{{parseValue(this.statuspriority.openhigh)}}</span></td>
                    <td data-column="Medium" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'open')">{{parseValue(this.statuspriority.openmedium)}}</span></td>
                    <td data-column="Low" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'open')">{{parseValue(this.statuspriority.openlow)}}</span></td>
                    <td data-column="P0" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p0', 'open')">{{parseValue(this.statuspriority.openp0)}}</span></td>
                    <td data-column="P1" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p1', 'open')">{{parseValue(this.statuspriority.openp1)}}</span></td>
                    <td data-column="P2" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p2', 'open')">{{parseValue(this.statuspriority.openp2)}}</span></td>
                    <td data-column="P3" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p3', 'open')">{{parseValue(this.statuspriority.openp3)}}</span></td>
                    <td data-column="P4" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p4', 'open')">{{parseValue(this.statuspriority.openp4)}}</span></td>
                    <td data-column="Total" class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'open')">{{parseValue(this.statuspriority.openhigh)+parseValue(this.statuspriority.openmedium)+parseValue(this.statuspriority.openlow)+parseValue(this.statuspriority.openp0)+parseValue(this.statuspriority.openp1)+parseValue(this.statuspriority.openp2)+parseValue(this.statuspriority.openp3)+parseValue(this.statuspriority.openp4)}}</span></td>
                    </tr>
                    <tr class="row1 table-warning" >
                    <td data-column="" style="text-align: left;">Re-Open Tickets</td>
                    <td data-column="High" v-if="this.companycode=='fc'" class="text-center"><span style="cursor:pointer;" @click="countClickable('high', 'reopen')">{{parseValue(this.statuspriority.reopenhigh)}}</span></td>
                    <td data-column="Medium" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'reopen')">{{parseValue(this.statuspriority.reopenclosed)}}</span></td>
                    <td data-column="Low" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'reopen')">{{parseValue(this.statuspriority.reopenlow)}}</span></td>  
                    <td data-column="P0" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p0', 'reopen')">{{parseValue(this.statuspriority.reopenp0)}}</span></td>  
                    <td data-column="P1" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p1', 'reopen')">{{parseValue(this.statuspriority.reopenp1)}}</span></td>  
                    <td data-column="P2" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p2', 'reopen')">{{parseValue(this.statuspriority.reopenp2)}}</span></td>  
                    <td data-column="P3" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p3', 'reopen')">{{parseValue(this.statuspriority.reopenp3)}}</span></td>  
                    <td data-column="P4" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p4', 'reopen')">{{parseValue(this.statuspriority.reopenp4)}}</span></td>                    
                    <td data-column="Total" class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'reopen')">{{parseValue(this.statuspriority.reopenhigh)+parseValue(this.statuspriority.reopenclosed)+parseValue(this.statuspriority.reopenlow)+parseValue(this.statuspriority.reopenp0)+parseValue(this.statuspriority.reopenp1)+parseValue(this.statuspriority.reopenp2)+parseValue(this.statuspriority.reopenp3)+parseValue(this.statuspriority.reopenp4)}}</span></td>
                    </tr>
                    <tr class="row1 table-primary" >
                    <td data-column="" style="text-align: left;">Resolved Tickets</td>
                    <td data-column="High" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'resolved')">{{parseValue(this.statuspriority.resolvedhigh)}}</span></td>
                    <td data-column="Medium" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'resolved')">{{parseValue(this.statuspriority.resolvedmedium)}}</span></td>
                    <td data-column="Low" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'resolved')">{{parseValue(this.statuspriority.resolvedlow)}}</span></td>
                    <td data-column="P0" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p0', 'resolved')">{{parseValue(this.statuspriority.resolvedp0)}}</span></td>
                    <td data-column="P1" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p1', 'resolved')">{{parseValue(this.statuspriority.resolvedp1)}}</span></td>
                    <td data-column="P2" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p2', 'resolved')">{{parseValue(this.statuspriority.resolvedp2)}}</span></td>
                    <td data-column="P3" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p3', 'resolved')">{{parseValue(this.statuspriority.resolvedp3)}}</span></td>
                    <td data-column="P4" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p4', 'resolved')">{{parseValue(this.statuspriority.resolvedp4)}}</span></td>
                    <td data-column="Total" class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'resolved')">{{parseValue(this.statuspriority.resolvedhigh)+parseValue(this.statuspriority.resolvedmedium)+parseValue(this.statuspriority.resolvedlow)+parseValue(this.statuspriority.resolvedp0)+parseValue(this.statuspriority.resolvedp1)+parseValue(this.statuspriority.resolvedp2)+parseValue(this.statuspriority.resolvedp3)+parseValue(this.statuspriority.resolvedp4)}}</span></td>
                    </tr>
                    <tr class="row1 table-success" >
                    <td data-column="" style="text-align: left;">Closed Tickets</td>
                    <td data-column="High" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'closed')">{{parseValue(this.statuspriority.closedhigh)}}</span></td>
                    <td data-column="Medium" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'closed')">{{parseValue(this.statuspriority.closedmedium)}}</span></td>
                    <td data-column="Low" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'closed')">{{parseValue(this.statuspriority.closedlow)}}</span></td>
                    <td data-column="P0" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p0', 'closed')">{{parseValue(this.statuspriority.closedp0)}}</span></td>
                    <td data-column="P1" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p1', 'closed')">{{parseValue(this.statuspriority.closedp1)}}</span></td>
                    <td data-column="P2" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p2', 'closed')">{{parseValue(this.statuspriority.closedp2)}}</span></td>
                    <td data-column="P3" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p3', 'closed')">{{parseValue(this.statuspriority.closedp3)}}</span></td>
                    <td data-column="P4" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p4', 'closed')">{{parseValue(this.statuspriority.closedp4)}}</span></td>
                    <td data-column="Total" class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'closed')">{{ parseValue(this.statuspriority.closedhigh) + parseValue(this.statuspriority.closedmedium) + parseValue(this.statuspriority.closedlow) + parseValue(this.statuspriority.closedp0) + parseValue(this.statuspriority.closedp1) + parseValue(this.statuspriority.closedp2) + parseValue(this.statuspriority.closedp3) + parseValue(this.statuspriority.closedp4) }}</span></td>
                    </tr>
                    <tr class="row1 table" >
                    <td data-column="" style="text-align: left;">Feedback Tickets</td>
                    <td data-column="High" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('high', 'feedback')">{{parseValue(this.statuspriority.feedbackhigh)}}</span></td>
                    <td data-column="Medium" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('medium', 'feedback')">{{parseValue(this.statuspriority.feedbackmedium)}}</span></td>
                    <td data-column="Low" v-if="this.companycode=='fc'" class="text-center" ><span style="cursor:pointer;" @click="countClickable('low', 'feedback')">{{parseValue(this.statuspriority.feedbacklow)}}</span></td>
                    <td data-column="P0" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p0', 'feedback')">{{parseValue(this.statuspriority.feedbackp0)}}</span></td>
                    <td data-column="P1" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p1', 'feedback')">{{parseValue(this.statuspriority.feedbackp1)}}</span></td>
                    <td data-column="P2" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p2', 'feedback')">{{parseValue(this.statuspriority.feedbackp2)}}</span></td>
                    <td data-column="P3" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p3', 'feedback')">{{parseValue(this.statuspriority.feedbackp3)}}</span></td>
                    <td data-column="P4" class="text-center" ><span style="cursor:pointer;" @click="countClickable('p4', 'feedback')">{{parseValue(this.statuspriority.feedbackp4)}}</span></td>
                    <td data-column="Total" class="text-center" ><span style="cursor:pointer;" @click="countClickable(null,'feedback')">{{ parseValue(this.statuspriority.feedbackhigh) + parseValue(this.statuspriority.feedbackmedium) + parseValue(this.statuspriority.feedbacklow) + parseValue(this.statuspriority.feedbackp0)+ parseValue(this.statuspriority.feedbackp1)+ parseValue(this.statuspriority.feedbackp2)+ parseValue(this.statuspriority.feedbackp3)+ parseValue(this.statuspriority.feedbackp4) }}</span></td>
                    </tr>
                    </tbody>
                  </table>       
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="false" id="dashboard-ecommerce">
          <div class="row match-height">
              <div class="col-xl-6 col-md-6 col-12">
                  <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                      <div class="card-header" style="background-color: rgb(255, 127, 14) !important;">
                          <h4 class="card-title" style="color: white;">Open Tickets</h4>
                          <div class="d-flex align-items-center">
                          </div>
                      </div>
                      <div class="card-body statistics-body">
                        <div class="row">
                          <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                              <div class="avatar bg-light-danger mr-2">
                                <div class="avatar-content">
                                  <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                                </div>
                              </div>
                              <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'open')">{{statuspriority.openhigh?statuspriority.openhigh:0}}</h4>
                                <p class="card-text font-small-3 mb-0">High</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                              <div class="avatar bg-light-success mr-2">
                                <div class="avatar-content">
                                  <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                                </div>
                              </div>
                              <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'open')" >{{statuspriority.openmedium?statuspriority.openmedium:0}}</h4>
                                <p class="card-text font-small-3 mb-0">Medium</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                              <div class="avatar bg-light-primary mr-2">
                                <div class="avatar-content">
                                  <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                                </div>
                              </div>
                              <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'open')">{{statuspriority.openlow?statuspriority.openlow:0}}</h4>
                                <p class="card-text font-small-3 mb-0">Low</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12">
                <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                  <div class="card-header" style="background-color: #fb1132 !important;">
                    <h4 class="card-title" style="color: white;"> Re-Open Tickets</h4>
                    <div class="d-flex align-items-center"></div>
                  </div>
                  <div class="card-body statistics-body">
                    <div class="row">
                        <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <div class="avatar bg-light-danger mr-2">
                                    <div class="avatar-content">
                                        <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'reopen')">{{statuspriority.reopenhigh?statuspriority.reopenhigh:0}}</h4>
                                    <p class="card-text font-small-3 mb-0">High</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <div class="avatar bg-light-success mr-2">
                                    <div class="avatar-content">
                                        <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'reopen')">{{statuspriority.reopenclosed?statuspriority.reopenclosed:0}}</h4>
                                    <p class="card-text font-small-3 mb-0">Medium</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-2">
                                    <div class="avatar-content">
                                        <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'reopen')">{{statuspriority.reopenlow?statuspriority.reopenlow:0}}</h4>
                                    <p class="card-text font-small-3 mb-0">Low</p>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12">
                <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                  <div class="card-header" style="background-color: rgb(148, 103, 189) !important;">
                    <h4 class="card-title" style="color: white;">Resolved Tickets</h4>
                    <div class="d-flex align-items-center"></div>
                  </div>
                  <div class="card-body statistics-body">
                    <div class="row">
                      <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                        <div class="media">
                            <div class="avatar bg-light-danger mr-2">
                                <div class="avatar-content">
                                    <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                                </div>
                            </div>
                            <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'resolved')">{{statuspriority.resolvedhigh?statuspriority.resolvedhigh:0}}</h4>
                                <p class="card-text font-small-3 mb-0">High</p>
                            </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                        <div class="media">
                            <div class="avatar bg-light-success mr-2">
                                <div class="avatar-content">
                                    <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                                </div>
                            </div>
                            <div class="media-body my-auto">
                                <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'resolved')">{{statuspriority.resolvedmedium?statuspriority.resolvedmedium:0}}</h4>
                                <p class="card-text font-small-3 mb-0">Medium</p>
                            </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                        <div class="media">
                          <div class="avatar bg-light-primary mr-2">
                            <div class="avatar-content">
                              <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'resolved')">{{statuspriority.resolvedlow?statuspriority.resolvedlow:0}}</h4>
                            <p class="card-text font-small-3 mb-0">Low</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-12">
                <div class="card card-statistics" style="padding: 0.5rem 0.5rem !important;">
                  <div class="card-header" style="background-color: #4cb720 !important;">
                    <h4 class="card-title" style="color: white;">Closed Tickets</h4>
                    <div class="d-flex align-items-center"></div>
                  </div>
                  <div class="card-body statistics-body">
                    <div class="row">
                      <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                        <div class="media">
                          <div class="avatar bg-light-danger mr-2">
                            <div class="avatar-content">
                              <bell-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></bell-icon>
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('high', 'closed')">{{statuspriority.closedhigh?statuspriority.closedhigh:0}}</h4>
                            <p class="card-text font-small-3 mb-0">High</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                        <div class="media">
                          <div class="avatar bg-light-success mr-2">
                            <div class="avatar-content">
                              <info-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></info-icon>
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('medium', 'closed')">{{statuspriority.closedmedium?statuspriority.closedmedium:0}}</h4>
                            <p class="card-text font-small-3 mb-0">Medium</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-sm-6 col-12 mb-2 mb-xl-0">
                        <div class="media">
                          <div class="avatar bg-light-primary mr-2">
                            <div class="avatar-content">
                              <book-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></book-icon>
                            </div>
                          </div>
                          <div class="media-body my-auto">
                            <h4 class="font-weight-bolder mb-0" style="cursor:pointer;" @click="countClickable('low', 'closed')">{{statuspriority.closedlow?statuspriority.closedlow:0}}</h4>
                            <p class="card-text font-small-3 mb-0">Low</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
        <div id="scrollToListGrid"></div>
        <div class="row">
          <div class="col-12">
            <ul class="listGrid mb-1 float-right d-inline-flex">
              <li class="cursorpointercls"  v-if="pagefeatures.bulkasigneeupdate"> 
                <button type="submit" class="btn btn-relief-primary " style="padding: 0.5rem 0.75rem;" @click="alert(isChecked)" :disabled="companymasterlist.length && !isChecked.some(bool => bool === true)">
                  <edit-icon size="1.5x" class="custom-class"></edit-icon><span> Bulk Asignee Update </span>
                </button>
              </li>
                <li class="cursorpointercls"  @click="myListView()" >
                  <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                    <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                    <span>
                      List View
                    </span>
                  </button>
                </li>
                <li class=" cursorpointercls" @click="myGridView()" >
                  <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
                    <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
                    <span>
                      Grid View
                    </span>
                  </button>
                </li>
                <li class="cursorpointercls" @click="chartShowView()" v-if="!pagefeatures.chartview"> 
                  <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" title="Chart View" data-original-title="Chart View">
                      <em class="las la-chart-pie custom-class mr-0 avatar-icon font-medium-1"></em>
                      <span>
                      Chart
                      </span>
                  </button>
                </li>
              </ul>
          </div>
        </div>
        <div v-bind:class="[blog_list_wrapper,{displayblk:isgridView}]">
          <div class="dw mx-n1" >
            <div class="dw-panel p-1"  v-for="(data,index) in companymasterlist" v-bind:key="index">
              <div class="card border-warning card_style_box_shadow1" style=" margin-bottom: 0rem !important;">
              <div class="card-header">
                <div class="col-12">
                  <h4 class="card-title">{{data.projectname}} #{{data.issuecode}} </h4>
                </div>
              </div>
              <div class="card" style="margin-bottom:0 !important;">
                  <div class="card-body">
                      <!-- <h4 class="card-title">
                          <span v-if="data.issuename.length > 50"  class="blog-title-truncate text-body-heading ">
                            {{data.issuename.substring(0,51)+ ' ...'}}
                          </span>
                          <span v-else  class="blog-title-truncate text-body-heading ">
                            {{data.issuename}}
                          </span>
                      </h4> -->
                      <h4 class="card-title">
                          <span v-if="data.issuename && data.issuename.length > 50" class="blog-title-truncate text-body-heading">
                              {{data.issuename.substring(0, 51) + ' ...'}}
                          </span>
                          <span v-else class="blog-title-truncate text-body-heading">
                              {{data.issuename}}
                          </span>
                      </h4>

                      <div class="media">
                          <div class="avatar mr-50">
                            <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                              <img alt="" v-if="!data.imagename" :src="defaultimagepath"  width="40" height="40" />
                          </div>
                          <div class="media-body">
                            <div>
                              <small class="text-muted mr-25">by</small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                              <span class="text-muted ml-50 mr-25">|</span>
                              <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                              <span class="text-muted ml-50 mr-25">|</span>
                            </div>
                            <div>
                              <small class="text-muted mr-25">Assigned To </small>
                              <small><a href="javascript:void(0);" @click="redirecttoprofile(data.assignedid)" class="capitalisetext">{{data.empfullname ? data.empfullname:'NA'}}</a></small>
                            </div>
                          </div>
                      </div>
                      <div class="my-1 py-25">
                            <div v-if="data.priority && data.priority == 'low'" class="capitalisetext badge badge-pill badge-light-info mr-50 mb-25">{{ data.priority }}</div>
                            <div v-else-if="data.priority=='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50 mb-25">{{data.priority}}</div>
                            <div v-else-if="data.priority=='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50 mb-25">{{data.priority}}</div>
                            <div v-else-if="data.priority == null || data.priority == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                            <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">{{data.priority ? data.priority:'NA'}}</div>

                            <div v-if="data.sevairtyname=='critical'" class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div  v-else-if="data.sevairtyname=='major'" class="badge badge-pill badge-light-warning mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-else-if="data.sevairtyname=='minor'" class="badge badge-pill badge-light-primary mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-else-if="data.sevairtyname=='cosmetic'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-else-if="data.sevairtyname=='moderate'" class="badge badge-pill badge-light-info mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>
                            <div v-else-if="data.sevairtyname == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                            <div v-else class="badge badge-pill badge-light-info mr-50 mb-25 capitalisetext">{{data.sevairtyname}}</div>

                            <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50 mb-25">{{data.status}}</div>
                            <div v-else-if="data.status.toLowerCase()=='feedback'" class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div> 
                            <div v-else class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status ? data.status:'NA'}}</div> 

                            <div  class="badge badge-pill badge-light-primary capitalisetext mr-50 mb-25">{{data.typename ? data.typename:'NA'}}</div>

                            <div v-if="data.qacycle>=3" class="badge badge-pill badge-light-danger capitalisetext">{{data.qacycle}}</div>
                            <div v-if="data.qacycle<3" class="badge badge-pill badge-light-primary capitalisetext">{{data.qacycle ? data.qacycle:'NA'}}</div>

                      </div>
                      <p class="card-text blog-content-truncate" style="overflow:hidden">
                          <span v-if="data.scenario && data.scenario.length>150" v-html="data.scenario.substring(0, 151) + ' ...'"></span>
                          <span v-else v-html="data.scenario"></span>
                      </p>
                      <hr />
                      <div class="d-flex justify-content-between align-items-center">
                          <a v-if="loginusername == data.createdby || pagefeatures.edit" @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                              <div class="d-flex align-items-center sitebluecolor">
                                    <message-square-icon size="1.5x" class="custom-class mr-50" style="color:blue"></message-square-icon>
                                  <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                              </div>
                          </a>
                          <a v-on:click="getIssueVersion(data.issueid, data.issuename, data.creator, data.createddate)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold">Ticket History</span>
                            </div>
                          </a>
                          <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                            <div class="d-flex align-items-center sitebluecolor"> 
                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                              <span class="text-body font-weight-bold">View Details</span>
                            </div>
                          </a>
                      </div>
                  </div>
              </div>
              </div>
            </div>
            </div>
          <div class="pageCenter text-center mt-2">
            <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            <div v-if="companymasterlist.length == 0 && isresponce" colspan="14" style="text-align: center;">No record found</div>
          </div>
        </div>
        <div v-bind:class="[{ show: isListActive}, card]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: isListDetActive},card_content,collapse]">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card text-center mb-3">
                      <div class="">
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row" id="table-small">
                              <div class="col-12">
                                <div class="card">
                                  <div class="" >
                                  </div>
                                  <div class="table-responsive datatableview">
                                    <table class="table table-sm dataTableListing table-striped all_table_stickys" aria-describedby="mydesc">
                                      <thead>
                                        <tr>
                                          <th scope="col" class="align-left first-col-sticky py-1" style="min-width: 100px;">
                                            <span class="px-1"> Sr#</span>
                                          </th>
                                          <th scope="col" class="align-middle second-col-sticky text-left py-1" style="min-width: 160px;">Ticket Code</th>
                                          <th scope="col" class="align-middle text-left py-1">Ticket Name</th>
                                          <th scope="col" class="align-middle text-left py-1">Project</th>
                                          <th scope="col" class="align-middle py-1">Functionality</th>
                                          <th scope="col" class="align-middle text-left py-1">Type</th>
                                          <th scope="col" class="align-middle py-1">Severity</th>
                                          <th scope="col" class="align-middle py-1">Priority</th>
                                          <th scope="col" class="align-middle py-1 text-left" style="min-width: 120px;">Assigned to</th>
                                          <th scope="col" class="align-middle py-1" style="min-width: 120px;">Ticket Status</th>
                                          <th scope="col" class="align-middle py-1 text-left" style="min-width: 180px;">Created By</th>
                                          <th scope="col" class="align-middle py-1 text-left">Created Date</th>
                                          <th scope="col" class="clsaction align-middle py-1">Actions</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <template v-for="(data,index) in companymasterlist">        
                                          <tr v-bind:key="data.moduleid">
                                            <td data-column="Sr#" class="first-col-sticky" >
                                              <span class="">
                                                <label :for="index+'-'+data.issueid"></label>
                                                <input type="checkbox" :id="index+'-'+data.issueid"  class="delete_checkbox" v-model="isChecked[index]" v-on:click="alterSelectAllCheck(index)">
                                                <span class=""> {{ index+1+currPageNum*pagelimit }} </span>
                                              </span>
                                            </td>
                                            <td data-column="Ticket Code" class="details_column_table second-col-sticky text-left" style="max-width: 230px;">{{data.issuecode ? data.issuecode:'NA'}}</td>
                                            <td data-column="Ticket Name" class="rolelistdd text-left"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer; min-width: 230px;">
                                              <span v-if="data.issuename && data.issuename.length > 50" class="font-weight-normal sitebluecolor">{{ data.issuename.substring(0, 51) + ' ...' }}</span>
                                              <span v-if="data.issuename && data.issuename.length <= 50" class="font-weight-normal sitebluecolor">{{ data.issuename }}</span>
                                            </td> 
                                            <td data-column="Project" class="text-left">
                                              <span class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectID',data)">{{data.projectname}}</span>
                                            </td>
                                            <td data-column="Functionality" class="text-left">
                                              <span class="font-weight-normal capitalisetext ">{{data.functionlityname ? data.functionlityname:'NA'}}</span>
                                            </td>
                                            <td data-column="Type" class="text-left">
                                              <span class="font-weight-normal capitalisetext text-left">{{data.typename ? data.typename:'NA'}}</span>
                                            </td>
                                            <td data-column="Severity">
                                                <div v-if="data.sevairtyname=='critical'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div  v-else-if="data.sevairtyname=='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-else-if="data.sevairtyname=='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-else-if="data.sevairtyname=='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-else-if="data.sevairtyname=='moderate'" class="badge badge-pill badge-light-info mr-50 capitalisetext">{{data.sevairtyname}}</div>
                                                <div v-else-if="data.sevairtyname == null || data.sevairtyname == ''" class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">NA</div>
                                                <div v-else class="capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">{{data.sevairtyname}}</div>
                                            </td>
                                            <td data-column="Priority">
                                              <div v-if="data.priority=='low'" class="capitalisetext badge badge-pill badge-light-info mr-50">{{data.priority}}</div>
                                              <div v-else-if="data.priority=='medium'" class="capitalisetext badge badge-pill badge-light-warning mr-50">{{data.priority}}</div>
                                             <div v-else-if="data.priority=='high'" class=" capitalisetext badge badge-pill badge-light-danger mr-50">{{data.priority}}</div>
                                             <div v-else-if="data.priority == null || data.priority == ''" class=" capitalisetext badge badge-pill badge-light-dark mr-50">NA</div>
                                             <div v-else class=" capitalisetext badge badge-pill badge-light-dark mr-50 mb-25">{{data.priority}}</div>
                                            </td>
                                            <td data-column="Assigned to" class="text-left">
                                              <span class="font-weight-normal capitalisetext">{{data.empfullname ? data.empfullname:'NA'}}</span>
                                            </td>
                                            <td data-column="Ticket Status">
                                              <div v-if="data.status.toLowerCase()=='open'" class="badge badge-pill badge-light-warning capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='reopen'" class="badge badge-pill badge-light-danger capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='resolved'" class="badge badge-pill badge-light-primary capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else-if="data.status.toLowerCase()=='closed'" class="badge badge-pill badge-light-success capitalisetext mr-50">{{data.status}}</div> 
                                              <div v-else-if="data.status.toLowerCase()=='feedback'" class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div>
                                              <div v-else="data.status.toLowerCase()=='feedback'" class="badge badge-pill badge-light-dark capitalisetext mr-50">{{data.status}}</div> 
                                              
                                            </td>
                                            <td data-column="Created By" class="font-weight-normal text-left">{{ data.creator ? data.creator:'NA' }}</td>
                                            <td data-column="Created Date" class="centeralign text-nowrap font-weight-normal">{{dateMonthFormat(data.createddate)}}</td>
                                            
                                            <td data-column="Actions" class="clsaction" >
                                            <div class="dropdown">
                                              <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                              </button>
                                              <div class="dropdown-menu">
                                                <a v-if="loginusername == data.createdby" class="dropdown-item devEditEle"  @click="redirecttourl('edit',data)" style="cursor: pointer;" href="javascript:void(0);">
                                                  <div class="d-flex align-items-center">
                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                    <span>Edit</span>
                                                  </div>
                                                </a>
                                                <a v-on:click="getIssueVersion(data.issueid, data.issuename, data.creator, data.createddate)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                  <div class="d-flex align-items-center"> 
                                                    <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                    <span>Ticket History</span>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </td>
                                          </tr>
                                        </template>
                                        <template v-if="companymasterlist.length==0">
                                          <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                        </template>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="pageCenter">
                          <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <div v-bind:class="[{ show: chartDetails},]" style="margin-top: 1rem; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: ischartDetailsActives},card_content,collapse]">
            <div class="container px-0">
              <div class="row">
                <div class="col-xl-12 col-18">
                  <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                      <div class="card-header">
                        <div class="w-auto px-0 mobile_margin-px-0_00 mobile_w_calc_38" style="padding:0px 0px;">
                          <h4 class="card-title d-inline-flex ml-0 mobile_width-86" style="padding: 0px 9px;white-space: nowrap;">Ticket Request Statistics</h4>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="chartpiechartviews">
                        <canvas class="containermasterbarchart chartjs" data-height="400"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="mobile-px-0">
                  <div class="table-responsive ">
                    <table class="table dataTableListing ticket_table_requestview" aria-describedby="mydesc">
                      <thead>
                        
                        <tr>
                          <th scope="col" style="min-width:120px">DATE</th>
                          <th class="text-center" style="white-space: nowrap;" v-for="(values, key) in tickettrackerstatus">
                          <span style="text-align: ;">{{values.label}} </span>
                          </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="row1" v-for="(values, key) in ticketstatus">
                        <td style="text-align: left;">{{values.created_date}} </td>
                        <td style="text-align: center;" v-for="(v, k) in tickettrackerstatus">{{values[v.id]}} </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </div>
        <div v-bind:class="[{ show: chartDetails},]" style="margin-top: 1rem; margin-bottom: 1rem !important;">
        </div>
        <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Task History Cycle</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <ul class="timeline">
                    <li class="timeline-item"  v-for="(data) in issueversionlist.slice(0,1)" v-bind:key="data.experienceid">
                      <span class="timeline-point"><edit-icon size="1.5x" class="custom-class"></edit-icon></span>
                      <div class="timeline-event">
                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{data.author}} created <strong>{{data.issuename}}</strong></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.createdat)}}</span>
                      </div>
                    </li>
                    <li class="timeline-item"  v-for="(data,i) in issueversionlist.slice(1)" v-bind:key="data.experienceid">
                      <span v-if="i==1" class="timeline-point timeline-point-indicator"></span>
                      <span v-if="i%2==0" class="timeline-point  timeline-point-warning timeline-point-indicator"></span>
                      <span v-else class="timeline-point  timeline-point-info timeline-point-indicator"></span>
                      <div class="timeline-event">
                        <div v-if="data.field !== 'scenario'" class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                          <p>{{formatName(data.modifiedby)}} updated <strong>{{data.field}}</strong> from <strong style="display:inline-block" v-html="data.oldval"></strong> to <strong style="display:inline-block" v-html="data.newval"></strong></p>
                        </div>
                        <div v-else>
                          <p>{{formatName(data.modifiedby)}} updated ticket scenario <a style="color: blue" @click="openissuescenario(data)">view details</a></p>
                        </div>
                        <span class="timeline-event-time">At: {{dateMonthFormat(data.lastmodifieddate)}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>    
          </div>
        </div>
      </div>
      <div class="modal fade taskdesctabs" id="taskdesc" tabindex="1" role="dialog" aria-labelledby="taskDescmodal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">Task description</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <section id="card-navigation">
                <div class="row match-height">
                  <div class="col-md-12">
                    <div class="card mb-3">
                        <ul class="nav nav-tabs" id="nav-tabs" role="tablist">
                          <li class="nav-item">
                            <a class="nav-link active" id="new-tab" data-toggle="tab" href="#new" role="tab" aria-controls="new" aria-selected="true">New</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" id="old-tab" data-toggle="tab" href="#old" role="tab" aria-controls="old" aria-selected="false">Old</a>
                          </li>
                        </ul>
                      <div class="card-body">
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
                            <p class="card-text" v-html="this.currentIssueScenario.newval"></p>
                          </div>
                          <div class="tab-pane fade" id="old" role="tabpanel" aria-labelledby="old-tab">
                            <p class="card-text" v-html="this.currentIssueScenario.oldval"></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>    
          </div>
        </div>
      </div>

      <div class="modal fade" id="technologypopup1" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Bulk Assignment</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetBulkRecords()">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body mb-2">
                  <h5  class="text-warning text-right">{{isChecked.reduce(this.countChecked, 0)}} Row selected</h5>
                  <div class="row flex--items">
                    <div class="row mx-0">
                      <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                        <label class="form-label lable-left">Assigned To</label>
                        <treeselect class="projectmdllistdd" placeholder="Enter Min 4 Chars To Search Assigned To" v-model="assignToBulkUpdate" :clear-on-select="true" :options="search.allotedtolist" v-on:search-change="allotedChange"/>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6 mb-1">
                          <label for="projectmdllistdd">Status</label>
                          <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="statusBulkUpdate"  :options="bulkUpdatestatuslist"/>
                      </div>
                      <div class="row col-lg-12 mx-0">
                          <label for="message-label">Comments</label>
                          <textarea class="form-control"  rows="3" placeholder="Enter comment" v-model="commentBulkUpdate" v-bind:class="{'form-control':true, 'is-invalid' : !validText(commentBulkUpdate)}"></textarea>
                          <div class="invalid-feedback">Comment is required</div>
                      </div>
                    </div>
                    <div class="col-sm-12 mt-1 text-center">
                      <button type="submit" class="btn btn-relief-primary mt-0" @click="bulkUpdate()" :disabled="!commentBulkUpdate"> <check-square-icon size="1.5x" class="custom-class"></check-square-icon><span> Update</span></button>
                    </div>

                  
                  </div>
                </div>    
              </div>
            </div>
          </div>

          <div class="modal fade" id="errorPopup" tabindex="-1" role="dialog" aria-labelledby="errorPopupLabel" aria-hidden="true"></div>
      </section>
    </div>
  </div>
</template>
<style scoped>
.displayblk{
    display:none !important;
}
.bordertable td,.bordertable th{
  text-align: center;
  border: 1px solid black;
}
.bordertable .row1{
  color:black;
}
.bordertable thead {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black 
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.row1{
  color:black;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import {ExternalLinkIcon,CheckSquareIcon,BellIcon,InfoIcon,BookIcon,LinkIcon, MessageSquareIcon,EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import profilepic from '../profilepic.vue';
import DatePicker from "vue2-datepicker";
import commonMethods from '../../utils/commonMethods';
export default {
    name:'TicketList',
    mixins: [ commonMethods ],
    components:{
      Loading,MessageSquareIcon,BellIcon,profilepic,
      VueElementLoading,InfoIcon,BookIcon,LinkIcon,
      ListIcon,
      vSelect,
      EditIcon,
      EyeIcon,
      Treeselect,
      SearchIcon,
      ExternalLinkIcon,
      GridIcon,
      CodesandboxIcon,
      CloudIcon,
      ChromeIcon,
      BoxIcon,
      Link2Icon,
      PackageIcon,
      Pagination,
      MoreVerticalIcon,
      PlusIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ClipboardIcon,
      AlertCircleIcon,
      PlusCircleIcon,XIcon,
      DatePicker,
      UserIcon,
      ckeditor: CKEditor.component,CheckSquareIcon
    },
    data() {
      return {
        maxLength:17,
        componentKey: 0,
        roleid: 0,
        defaultimagepath:apiUrl.defaultimagepath,
        productlabelname: null,
        currentIssueScenario: {},
        issueversionlist: [],
        issuecodelist:null,
        loginuser:'',
        isLoading: false,
        fullPage: true,
        islistLoading:false,
        listfullPage:true,
        stylevariation:apiUrl.stylevariation,
        companymasterlist: [],   
        editRowData:[],
        glbMdl:'',
        glbMdlLbl:'issue',
        TimeFormat: apiUrl.TimeFormat,
        issue:{
          issuename:null,
          project:null,
          projectlist:null,
          functionality:null,
          functionalitylist:null,
          type:null,
          typelist:apiUrl.ticketTypeList,
          mrtypelist:[],//apiUrl.mrticketTypelist,
          scenario:null,
          scenariolist:null,
          attachment:null,
          attachmentview:null,
          sevairty:null,
          sevairtylist:null,
          priority:null,
          prioritylist:apiUrl.issueclassification,
          allotedto:null,
          allotedtolist:null,
          reasonofissue:null,
          impactofissue:null,
          status:null,
          typelist:apiUrl.ticketTypeList,
          statuslist:apiUrl.issueStatuslist,
          iseditstate:false,
          disblesavebtn:false,
          task: null,
          tasklist: [],
          pmlist: [],
          emlist: [],
          smlist: [],
          countrylist: apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
        },
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        pagefeatures:[],
        showtabledata:true,
        showproject:false,
        modulelist:[],
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        errors:{
          issue:{
            issuename:null,
            project:null,
            functionality:null,
            type:null,
            scenario:null,
            attachment:null,
            sevairty:null,

            priority:null,
            allotedto:null,
            reasonofissue:null,
            impactofissue:null,
            status:null,
            task: null,
          }
        },
        isDetActive: false,
        isListDetActive:true,
        isListActive:true,
        displayblk:'displayblk',
        isgridView:true,
        isListDetailsActive:true,
        ischartDetailsActives:false,  
        chartDetails:true, 
        blog_list_wrapper :'blog-list-wrapper',
        isRequestActive:false,
        isedit: false,
        card:"card",
        border_warning:'border-warning',
        editor: ClassicEditor,
        editorConfig: {
          toolbar: {
            items: [
              'heading', '|', 'bold',
              'italic', '|', 'bulletedList',
              'numberedList', '|', 'insertTable', '|',
              'undo', 'redo', '|',
            ],
          },
        },
        search:{
          responsedate_from: null,
          responsedate_to: null,
          resolutiondate_from: null,
          lastmodifiedfrom:null,
          lastmodifiedto:null,
          resolutiondate_to: null,
          searchprojectname :null,
          issuecodelist:null,
          functionality: null,
          task:null,
          issuename:null,
          type:null,
          sevairty:null,
          priority:null,
          status:null,
          allotedto:null,
          projectnameList: [],
          functionalitylist: [],
          allotedtolist: [],
          tasklist: [],
          proman: null,
          engman: null,
          supman: null,
          country: null,
          createdby: null,
          createddatefrom:null,
          createddateto:null,
          projectcreatedbyopts: null,
          projectmodulelist: [],
          applicationlist: [],
          applicationmodulelist: [],
          sectionmodulelist: [],
          projectmodulename:null,
          applicationname:null,
          applicationmodulename:null,
          sectionmodulename:null,
          protype: null,
          subType: null,
        },
        isBothDateSelected:false,
        isBothDateSelected_resp:false,
        isBothDateSelected_reso:false,
        isBothDateSelected_last:false,
        issuenameList:[],
        view:{
          projectname: null,
          functionlityname: null,
          typename: null,
          sevairtyname: null
        },
        statuspriority:[],
        countClick: false,
        countPriority: null,
        countStatus: null,
        sitebluecolor:"sitebluecolor",
        activegridlink:false,
        activelistlink:false,
        createdby: "",
        tokendata:null,
        isresponce:true,
        isThisBusinessRole: false,
        userrole:null,
        merchandisingProId: apiUrl.merchandisingProId,
        isThisMerchandiseProject: false,
        sectionmoduleId1: apiUrl.sectionmoduleId1,
        sectionmoduleId2: apiUrl.sectionmoduleId2,
        tickettrackerstatus:[],
        companycode: apiUrl.companycode,
        itHelpDesk: apiUrl.indItHelpDesk,
        FcRoleWiseAPI:apiUrl.FcRoleWiseAPI,
        ticketstatus: [],
        barChartExample2: '',
        BRFeatureProList: apiUrl.BRFeatureProList,
        regex: /\[(.*?)\]/,
        match: null,
        encryptedText: null,      
        decryptedText: null,

        isChecked: [],
        isSelectAllChecked:false,
        isSelectAll:false,
        assignToBulkUpdate: null,
        statusBulkUpdate: null,
        commentBulkUpdate:'',
        selectedProjectIds:[],
        bulkUpdatestatuslist:[]
      }
    },
    created() {
      this.pagefeatures = {}
      var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/ticket/list') {
          this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    },
    computed: {
      isSameStatus() {
        console.log("isChecked:", this.isChecked);
        if (this.isChecked.length === 0) return true; 
        const selectedStatus = new Set();
        for (let i = 0; i < this.isChecked.length; i++) {
          if (this.isChecked[i]) {
            const index = this.companymasterlist.findIndex((item, index) => index === i);
            const status = this.companymasterlist[index].status;
            selectedStatus.add(status);
          }
        }
        console.log("Selected statuses:", selectedStatus);
        return selectedStatus.size === 1;
      }
    },
    mounted(){
      window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              window.location.reload();
          }
      });
      $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
      let permission = this.$Service.verifyAccess();
      if(permission){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)       
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole");
            localStorage.setItem('activebtctab', 'listview');

            this.loginusername=userdata.username
            this.loginuscondition = userdata.userid
            this.roleid = this.undt.roleid
            this.isThisBusinessRole = true
            this.search.createdby = [this.undt.username]
            this.search.allotedto =  [this.undt.userid]

            if(this.$route.params.backbtnstate == "open") {
              localStorage.removeItem('ticketsearchItems')
              this.search.status=['open']
              this.search.createdby = null 
              this.getissuelist(null, ['open'])
            }else if (this.$route.params.backbtnstate == "reopen") {
              localStorage.removeItem('ticketsearchItems')
              this.search.status=['re-open']
              this.search.createdby = null 
              this.getissuelist(null, ['re-open'])
            }
            else if(this.$route.params.search) {
              let search = JSON.parse(localStorage.getItem('ticketsearchItems'));
              if(search) {
                this.getSearchItems(search);
              }
              if(this.$route.params.activebtctab == "chartview") {
                this.chartShowView();
              } else if(this.$route.params.activebtctab == "gridview") {
                this.myGridView();
              } else {
                  this.myListView();
              }
            }else if(this.$route.params.backbtnstate =='Ticketrequest'){
              localStorage.removeItem('ticketsearchItems');
              this.search.status=[this.$route.params.clickeddata.status.toLowerCase()];
              this.search.type=this.$route.params.clickeddata.type;
              this.search.searchprojectname=[parseInt(this.$route.params.pid)];
              this.getissuelist({ status:this.search.status });
            }else {
              localStorage.removeItem('ticketsearchItems');
              this.search.status=['open'];
              this.search.createdby = [this.undt.username];
              this.getissuelist({ status: ['open'] });
            }
            this.getProjectAllocatedToEmp()
            //will active only for fcnewapicase  
            if(this.FcRoleWiseAPI){
                this.getEmpListByProjectAssigned(parseInt(this.undt.userid))
                this.getcreatedby(this.undt.username)
            }
            // this.getissuecode(parseInt(this.undt.userid))
         
            this.getTicketStatus()
            this.sortTypeList([], 'alltypes')
            this.getProjectManagerDetailsticket(477,null)
            this.getProjectManagerDetailsticket(492,null)
            this.getProjectManagerDetailsticket(515,null)
        }
      }
    },
    methods:{
      forceRerender() {
        this.componentKey += 1;
      },
      redirecttoprofile(empid){
        let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
        window.open(route.href);
      },
      validText : function(inputval) {
        let re = /[A-Za-z0-9].{0,}/;
          if(inputval){
              return re.test(inputval.toLowerCase());
          }
      },
      selectAll() {
        if(!this.isSelectAll) {
          this.isChecked = Array(this.totalcount).fill(true)
          
        } else {
          this.isChecked = Array(this.totalcount).fill(false)
        }
      },
      alterSelectAllCheck(index) {
        if(this.isChecked[index]) {
          this.isSelectAllChecked = false
        } else {
          this.isSelectAllChecked = this.isChecked.every((i, idx) => {
            if(idx !== index) {
              return i === true
            } else {
              return true
            }
          })
        }
      },
      countChecked(previousValue, currentValue, index) {
        var returns = previousValue
        if(this.isChecked[index]) {
          returns = previousValue + 1
        }
        return returns
      },
      alert(isChecked) {
        if (!this.isSameStatus) {
          // $('#errorPopup').modal('show');
          this.errorPopup()
        } else {
          $('#technologypopup1').modal('show');
            console.log(this.isChecked, "isChecked");
            let selectedProjects = this.companymasterlist
            .filter((item, index) => this.isChecked[index])
            .map(item => {
                return {
                    projectid: parseInt(item.projectid),
                    status: item.status
                };
            });
            this.selectedProjectIds = selectedProjects.map(project => project.projectid);
            this.selectedProjectStatuses = selectedProjects.map(project => project.status);
            this.statusBulkUpdate = this.selectedProjectStatuses[0];
            this.getStatusConfigList(this.selectedProjectIds,this.selectedProjectStatuses)
        }
      },
      getStatusConfigList(projectids,status) {
        let payload = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            projectid: projectids,
            // roleid: roleid,
            trackername: 'Ticket Request',
            currStatus: status[0],
        };
        axios({
            method: "POST",
            url: 'api/issue/getStatusforBulkUpdate',
            data: payload,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            if(result.data.errorCode == 0){
                this.bulkUpdatestatuslist = result.data.data.rows;
            } else {
                this.bulkUpdatestatuslist = [];
            }
        }).catch(e => {
          this.displayError(e)
        })
      },
      bulkUpdate() {
        let prevIds = []
        this.companymasterlist.map((btm, idx) => {
          if(this.isChecked[idx]) {
            prevIds.push(parseInt(btm.issueid))
          }
        })
        this.inputappl = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          prevIds: prevIds,
          assignBulk: this.assignToBulkUpdate ? this.assignToBulkUpdate : null,
          statusBulk : this.statusBulkUpdate ? this.statusBulkUpdate : '',
          commentBulk : this.commentBulkUpdate,
          empid: this.undt.userid,
        }

        let apiURL = 'api/issue/updateIssueAssessment'
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.resetBulkRecords()
          $('#technologypopup1').modal('hide');
          if(result.data.errorCode === 0) {
            this.getissuelist();
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              text: result.data.msg,
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            })
          }
          this.isChecked = []
        })
      },
      errorPopup(){
        Swal.fire({
            title: "Failed...!!",
            text: "The Selected ticket's must have the same status for bulk update",
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          })
      },
      resetBulkRecords: function() {
        this.isChecked = []
        this.assignToBulkUpdate = null
        this.statusBulkUpdate = null,
        this.commentBulkUpdate = '',
        this.getissuelist();
      },
      getTicketStatus(){
          this.pageoffset=1
          this.input = {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            tracker: 'Ticket Request'
          }
          axios({
            'method': 'POST',
            'url': 'api/tasks/getTicketReqStatus',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            if (result.data.errorCode == 0) {
              this.tickettrackerstatus = result.data.data.rows
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
            }else{
              this.tickettrackerstatus=[]
            }
          });
        },
      getstatuspriority(request){
        this.isLoading = true;
        let url = "api/issue/getstatuspriority";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          projectid:request.ticketprojectname,
          functionalityid:request.functionality,
          // allotedto:request.allotedto,
          taskid:request.taskid,
          status:request.status,
          createdby: request.createdby,
          datefrom: request.datefrom,
          dateto: request.dateto,
          lastmodifiedfrom: request.lastmodifiedfrom,
          lastmodifiedto: request.lastmodifiedto,
          empcode: this.undt.userid,
          userrole:this.userrole,
          issuecode: request.issuecode,
          priority: request.priority,
          projectmodule: request.projectmodule,
          application: request.applicationname,
          applicationmodule: request.applicationmodule,
          sectionmodule: request.sectionmodule,
          sevairty: request.sevairty,
          typename: request.typename,
          proman: request.proman,
          engman: request.engman,
          supman: request.supman,
          country: request.country,
          moduletype:'ticket',
          responsedate_from: request.responsedate_from,
          responsedate_to: request.responsedate_to,
          resolutiondate_from: request.resolutiondate_from,
          resolutiondate_to: request.resolutiondate_to,
        };
        let oallotedto =null
        if(this.search.searchprojectname == this.merchandisingProId){
          oallotedto = this.search.allotedto;
          if(Array.isArray(this.search.allotedto)){
             oallotedto = this.search.allotedto[0]
          }

          this.statusinput.allotedemp = oallotedto
        } else{
          this.statusinput.allotedto = this.search.allotedto
        }

        if(this.FcRoleWiseAPI){
          url = "api/issue/getstatuspriorityfc";
         this.statusinput.ispagefeatures=this.pagefeatures.multiviewuser ? this.pagefeatures.multiviewuser : false
          this.statusinput.userroleid=this.roleid
          if(this.statusinput.ispagefeatures){
            if(Array.isArray(this.search.allotedto)){
          this.statusinput.allotedto = this.search.allotedto ?  this.search.allotedto : null
          }else{
            this.statusinput.allotedto = this.search.allotedto ? [this.search.allotedto].map(element => Number(element))  : null
            }
           }else{
          this.statusinput.allotedto = [this.undt.userid]
          }
        }

        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.statuspriority = result.data.data[0];
          }else if(result.data.errorCode == 3){
            Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            this.statuspriority=[]
          }
        }).catch(e => {
	        this.displayError(e)
	      })
      },
      formatName(authorstring) {
        let author=authorstring.replace('@firstcry.com','')
        if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
        author = author.replace('.',' ')
        author = author.charAt(0).toUpperCase() + author.slice(1);
        return author
      },
      openissuescenario(event) {
        this.currentIssueScenario = event
        $('#taskdesc').modal('show');
      },
      getIssueVersion(issueid, ...args) {
        let issueInfo = { issuename: args[0], author:args[1], createdat: args[2] }
        this.isLoading = true
        let apiUrl = 'api/issue/getIssueHistory'
        this.input = { issueid,useremail: this.undt.username,empcode: this.undt.userid }
        // axios.post(apiUrl, this.input)
        axios({
                'method': 'POST',
                'url': apiUrl,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
        .then(result => {
          this.isLoading = false
          $('#technologypopup').modal('show');
          if(result.data.errorCode == 0) {
            this.issueversionlist = result.data.data.rows
            this.issueversionlist.unshift(issueInfo)
          }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
            this.issueversionlist = []
            this.issueversionlist.unshift(issueInfo)
          }
        }) 
      },
      getcreatedby(usermail) {
        let created = []
          if(Array.isArray(usermail)){
            created = usermail
          }else{
            created = [this.undt.username]
          }
        axios({
          'method': 'POST',
          'url': 'api/issue/getcreatedby',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            created : created,
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data;
          }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
            this.search.projectcreatedbyopts = null
          }
        })
      },
      createduser: function (node) {
          if(node && node.length > 3){
            this.isLoading = true;
          axios({
            'method': 'POST',
            'url': 'api/issue/getcreatedby',
            'data': {
              empid: parseInt(this.undt.userid),
              useremail: this.undt.username,
              empcode: this.undt.userid,
              createdby:node.toLowerCase(),
            },
            'headers':{'authorization':this.tokendata}
          }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
              this.search.projectcreatedbyopts = result.data.data
            } else {
              this.search.projectcreatedbyopts = null
            }
          })
        }
        },
      parseValue(val) {
        if(val) {
          return parseInt(val)
        }
        return 0
      },
      getEmpallocatedtoProject(projectid, currtab){
          if(currtab == 'issue') {
              this.issue.allotedto = null
              this.issue.allotedtolist = []
          }
          axios({
          'method': 'POST',
          'url': 'api/listing/getEmpallocatedtoProject',
          'data': {'projectid':projectid,useremail: this.undt.username,empcode: this.undt.userid},
          'headers':{'authorization':this.tokendata}
          })
          .then(result => { 
              this.isLoading= false;         
              if(result.data.errorCode == 0){
                  if(currtab == 'issue') {
                    this.issue.allotedtolist=result.data.data;
                    let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{

                      let tempemplabel1 = items.label;
                      let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                      if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                      }
                      return items;
                    })
                    if(this.issue.iseditstate) {
                        this.issue.allotedto = this.editRowData.allotedto    
                    } else {
                        this.issue.allotedto = null
                    }
                  } else if(currtab == 'issueSearch') {
                    this.search.allotedtolist=result.data.data;
                    let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

                      let tempemplabel1 = items.label;
                      let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                      if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                      }
                      return items;
                    })
                  }
              }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else{
                  if(currtab == 'issue') {
                      this.issue.allotedtolist = [];
                  } else if(currtab == 'issueSearch') {
                      this.search.allotedtolist = [];
                  }
              }
          })
      },
      getEmployeeDetailsbyID(empid){
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmployeeDetailsbyID',
          'data': { 'empid':empid,useremail: this.undt.username,empcode: this.undt.userid },
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
            this.isLoading= false;         
            if(result.data.errorCode == 0){
              this.issue.allotedtolist=result.data.data;
              let decryptdepartmenttext = this.issue.allotedtolist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else{
              this.issue.allotedtolist=[];
            }
        })
      },
      mydetailscollapse() {
        this.isDetActive = true
      },
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },
      myListView: function () {
        this.isListDetActive = true;
        this.isListDetailsActive = true;
        this.chartDetails=true;
        this.ischartDetailsActives=false;
        this.isgridView=true;
        this.activegridlink=false
        this.activelistlink=true
        $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
        localStorage.setItem('activebtctab', 'listview');
      },
      myGridView(){
        this.isListDetActive = false;
        this.isListDetailsActive = true;
        this.chartDetails=true;
        this.ischartDetailsActives=false;
        this.isgridView=false;
        this.activegridlink=true
        this.activelistlink=false
        $('html, body').animate({
          scrollTop: $("#scrollToListGrid").offset().top -70
        });
        localStorage.setItem('activebtctab', 'gridview');
      },

      chartShowView() {
        var chartWrapper = $('.chartjs')

        if (chartWrapper.length) {
        chartWrapper.each(function () {
        $(this).wrap($('<div style="height:' + this.getAttribute('data-height') + 'px"></div>'));
        });
        }
        this.isListDetActive = false;
        this.isgridView=true;
        this.isListDetailsActive=true;
        this.chartDetails=false;
        this.activegridlink=false;
        this.activelistlink=false;
        this.ischartDetailsActives=true;
        localStorage.setItem('activebtctab', 'chartview');
        this.getticketstatuscount();
      },

      getticketstatuscount(){
        this.isLoading = true;
        this.ticketstatus = [];
        let url = "api/issue/getticketstatuscount";
        let fromdate = null
        let todate = null
        let projectid = this.search.searchprojectname?this.search.searchprojectname:null
        if(projectid == null  && !this.search.createddateto && !this.search.createddatefrom){
                fromdate = new Date()
                todate = new Date()
                fromdate.setDate(fromdate.getDate() - 30)
                fromdate = moment(String(fromdate)).format('YYYY-MM-DD');
                this.search.createddatefrom = fromdate

                todate.setDate(todate.getDate())
                todate = moment(String(todate)).format('YYYY-MM-DD');
                this.search.createddateto = todate
          }
        this.countinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          projectid:this.search.searchprojectname,
          functionalityid:this.search.functionality,
          // allotedto:this.search.allotedto,
          taskid:this.search.task,
          status:this.search.status,
          createdby: this.search.createdby,
          datefrom: this.search.createddatefrom,
          dateto: this.search.createddateto,
          empcode: this.undt.userid,
          userrole:this.userrole,
          issuecode: this.search.issuecode,
          priority: this.search.priority,
          projectmodule: this.search.projectmodulename,
          application: this.search.applicationname,
          applicationmodule: this.search.applicationmodulename,
          sectionmodule: this.search.sectionmodulename,
          sevairty: this.search.sevairty,
          typename: this.search.typename,
          proman: this.search.proman,
          engman: this.search.engman,
          supman: this.search.supman,
          country: this.search.country,
          moduletype:'ticket',
          responsedate_from: this.search.responsedate_from,
          responsedate_to: this.search.responsedate_to,
          resolutiondate_from: this.search.resolutiondate_from,
          resolutiondate_to: this.search.resolutiondate_to,
          tracker: 'Ticket Request'
        }
        let assigne1 =''
        if(this.search.lastmodifiedto){
        assigne1 = new Date(this.search.lastmodifiedto)
        assigne1.setDate(assigne1.getDate() + 1)
        moment(String(assigne1)).format('YYYY-MM-DD');
        }
        this.countinput.lastmodifiedfrom = this.search.lastmodifiedfrom?this.search.lastmodifiedfrom:null
        this.countinput.lastmodifiedto = this.search.lastmodifiedto?assigne1:null
        let oallotedto =null
        if(this.search.searchprojectname == this.merchandisingProId){
          oallotedto = this.search.allotedto;
          if(Array.isArray(this.search.allotedto)){
             oallotedto = this.search.allotedto[0]
          }

          this.countinput.allotedemp = oallotedto
        } else{
          this.countinput.allotedto = this.search.allotedto
        }
        axios({
          method: "POST",
          url: url,
          data: this.countinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticketstatus = result.data.data;
            this.getshowbarcharts(this.ticketstatus);
          }else if(result.data.errorCode == 3){
            this.ticketstatus=[];
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            this.ticketstatus=[]
          }
        });
      },
      getshowbarcharts(datac){
        if(this.barChartExample2)
        {
          this.barChartExample2.destroy();
        }
        var tooltipShadow = 'rgba(0, 0, 0, 0.25)',
        labelColor = '#6e6b7b',
        grid_line_color = 'rgba(200, 200, 200, 0.2)',
        newcolor1 = 'Blue',
        newcolor2 = 'Green',
        newcolor3 = 'Red'

        let date_arr = [];
        let totalcnt_arr = [];
        for (let a=0; a<datac.length; a++)
        {
          if(datac[a].created_date != undefined){
          date_arr[a] = datac[a].created_date;
          totalcnt_arr[a] = datac[a].total_count;
          }
        }
        let minnumber = Math.min.apply( null, totalcnt_arr );
        let maxnumber = Math.max.apply( null, totalcnt_arr );
        let maxCount = Math.ceil(maxnumber/50) * 50  
        let CountStep = (maxCount/100)*10;
        let barChartEx1=$('.containermasterbarchart ');
        if (barChartEx1.length) {
             this.barChartExample2 = new Chart(barChartEx1, {
            type: 'bar',
            
            options: {
                elements: {
                rectangle: {
                    borderWidth: 1,
                    borderSkipped: 'bottom'
                }
                },
                responsive: true,
                maintainAspectRatio: false,
                responsiveAnimationDuration: 500,
                legend: {
                display: false
                },
                tooltips: {
                // enable: false,
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: tooltipShadow,
                backgroundColor: window.colors.solid.white,
                titleFontColor: window.colors.solid.black,
                bodyFontColor: window.colors.solid.black
                },
                scales: {
                xAxes: [
                    {
                    barThickness: 15,
                    display: true,
                    gridLines: {
                        display: true,
                        color: grid_line_color,
                        zeroLineColor: grid_line_color
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        fontColor: labelColor
                    }
                    }
                ],
                yAxes: [
                    {
                    display: true,
                    gridLines: {
                        color: grid_line_color,
                        zeroLineColor: grid_line_color
                    },
                    ticks: {
                        stepSize: CountStep,
                        min: 0,
                        max: maxCount,
                        fontColor: labelColor
                    }
                    }
                ]
                }
            },
            data: {
                    labels: date_arr,
                    datasets: [{
                      data: totalcnt_arr,
                      backgroundColor: newcolor2,
                      borderColor: 'transparent'
                    }]
                }
          });
        }
        else{
          date_arr = [];
          totalcnt_arr = [];
        }
      },
      resetForm() {
        this.issue.issuename = null
        this.issue.project = null
        this.issue.functionality = null
        this.issue.type = null
        this.issue.scenario = ''
        this.issue.sevairty = null
        this.issue.priority = null
        this.issue.allotedto = null
        this.issue.reasonofissue = null
        this.issue.impactofissue = null
        this.issue.status = null
        this.attachment = null
        this.issue.attachmentview = null
        this.ticketstatus = []
      },
      redirecttourl:function(btnstate,rowdata){
        rowdata.backbtnstate=btnstate
        if(btnstate=="view"){
          let routeData = this.$router.resolve({ 
            path: '/ticket/view?ticketid='+rowdata.issueid,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
          window.open(routeData.href, '_blank');
        } else if(btnstate=="edit"){
          this.$router.push({ 
            path: '/ticket/update?ticketid='+rowdata.issueid,
            params: {
              backbtnstate:btnstate,
              clickeddata:rowdata
            }
          })
        }else if (btnstate == 'projectID') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid
                })
            }
      },
      clickCallback: function(pageNum) {
        this.pageoffset=pageNum
        this.currPageNum = pageNum - 1
        let arrGlbMdl=this.glbMdl.split('##')
        let inputEle='';
        let btnEle='';
        if(arrGlbMdl.length > 0){
          if(typeof arrGlbMdl[0] != 'undefined'){
            inputEle=arrGlbMdl[0];
          }
          if(typeof arrGlbMdl[1] != 'undefined'){
            btnEle=arrGlbMdl[1];
          }
        }
        if(this.countClick){
          this.countClickable(this.countPriority,this.countStatus)
        } else{
          this.getissuelist()
        }
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#scrollToListGrid").offset().top -70
            });
        }, 500);
      },
      getProjectAllocatedToEmp() {
        this.isLoading = true
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage:'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers': { 'authorization': this.tokendata }
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectnameList = result.data.data;
            if(this.search.projectnameList.length ==1){
                this.search.searchprojectname = [this.search.projectnameList[0].id]
                this.isThisMerchandiseProject = true
            } else {
              this.isThisMerchandiseProject = false
            }
          }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
            this.search.projectnameList = [];
          }
        });
      },
      projectChangeSearch: function (node, instanceId){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            projectname:node.toLowerCase(),
            useremail: this.undt.username,
            empid: this.undt.userid,
            empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.search.projectnameList = result.data.data;
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
              this.search.projectnameList = [];
            }
          });
        } 
      },
      issueChange: function (node, instanceId){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/searching/getissuesnamelist";
          this.input = {
          issuename:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.issuenameList = result.data.data;
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
              this.issuenameList = [];
            }
          });
        } 
      },
      resetRecords: function() {
        this.search.issuecode=null
        this.search.functionality = null;
        this.search.task= null;
        this.search.issuename= null;
        this.search.type = null;
        this.search.sevairty = null;
        this.search.priority = null;
        this.search.status = null;
        this.search.proman = null
        this.search.createdby = null;
        this.search.supman = null
        this.search.engman = null
        this.search.country = null;
        this.search.allotedto = null;
        this.search.createddatefrom = null;
        this.search.createddateto = null;
        this.search.lastmodifiedfrom = null;
        this.search.lastmodifiedto = null;
        this.isBothDateSelected_last = false
        this.isBothDateSelected = false
        this.isBothDateSelected_reso = false
        this.isBothDateSelected_resp = false
        this.search.issuecodelist = null
        this.search.functionalitylist = null
        this.search.tasklist = null
        this.issuenameList = null
        this.search.projectmodulename = null
        this.search.applicationname = null
        this.search.applicationmodulename = null
        this.search.sectionmodulename = null
        localStorage.removeItem('ticketsearchItems');
        this.search.createdby = [this.undt.username];
        this.search.allotedto =  [this.undt.userid]
        this.search.status=['open'];
        this.getissuelist('',this.search.status);
        if(!this.isThisBusinessRole) this.search.createdby = null;
        if(!this.isThisMerchandiseProject){
          this.search.searchprojectname = null;
        }
        this.search.responsedate_from = null
        this.search.responsedate_to = null
        this.search.resolutiondate_from = null
        this.search.resolutiondate_to = null
      },
    disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      let selectedFromDate = new Date(this.search.createddatefrom);
      selectedFromDate.setDate(selectedFromDate.getDate()-1);
      return date < selectedFromDate || date > today;
    },

      notBeforeToday: function (date) {
        this.search.createddateto = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      disabledBefore(date) {
        let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
        const beforeToday = new Date(dayBefore);
                beforeToday.setHours(0, 0, 0, 0);
        return date < beforeToday;
      },
      notBeforeToday_Reso: function (date) {
        this.search.resolutiondate_to = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      disabledBefore_Reso(date) {
        let dayBefore = moment(this.search.resolutiondate_from).format(this.TimeFormat);
        const beforeToday = new Date(dayBefore);
                beforeToday.setHours(0, 0, 0, 0);
        return date < beforeToday;
      },
      notBeforeToday_Resp: function (date) {
        this.search.responsedate_to = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      disabledBefore_Resp(date) {
        let dayBefore = moment(this.search.responsedate_from).format(this.TimeFormat);
        const beforeToday = new Date(dayBefore);
                beforeToday.setHours(0, 0, 0, 0);
        return date < beforeToday;
      },
      selectedfromdateReso(){
        if((this.search.resolutiondate_from && this.search.resolutiondate_to) || (!this.search.resolutiondate_from && !this.search.resolutiondate_to)){
            this.isBothDateSelected_reso= false
        }else if((this.search.resolutiondate_from && !this.search.resolutiondate_to) ||( !this.search.resolutiondate_from && this.search.resolutiondate_to)){
            this.isBothDateSelected_reso = true
        }
      },
      selectedtodateReso(){
        if((this.search.resolutiondate_from && this.search.resolutiondate_to) || (!this.search.resolutiondate_from && !this.search.resolutiondate_to)){
            this.isBothDateSelected_reso = false
        }else if((this.search.resolutiondate_from && !this.search.resolutiondate_to) ||( !this.search.resolutiondate_from && this.search.resolutiondate_to)){
            this.isBothDateSelected_reso = true
        }
      },
      selectedfromdateResp(){
        if((this.search.responsedate_from && this.search.responsedate_to) || (!this.search.responsedate_from && !this.search.responsedate_to)){
            this.isBothDateSelected_resp = false
        }else if((this.search.responsedate_from && !this.search.responsedate_to) ||( !this.search.responsedate_from && this.search.responsedate_to)){
            this.isBothDateSelected_resp = true
        }
      },
      selectedtodateResp(){
        if((this.search.responsedate_from && this.search.responsedate_to) || (!this.search.responsedate_from && !this.search.responsedate_to)){
            this.isBothDateSelected_resp = false
        }else if((this.search.responsedate_from && !this.search.responsedate_to) ||( !this.search.responsedate_from && this.search.responsedate_to)){
            this.isBothDateSelected_resp = true
        }
      },
      selectedfromdate(){
        if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
            this.isBothDateSelected = false
        }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
            this.isBothDateSelected = true
        }
      },
      selectedtodate(){
        if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
            this.isBothDateSelected = false
        }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
            this.isBothDateSelected = true
        }
      },
      selectedfromdate_last(){
          if((this.search.lastmodifiedfrom && this.search.lastmodifiedto) || (!this.search.lastmodifiedfrom && !this.search.lastmodifiedto)){
              this.isBothDateSelected_last = false
          }else if((this.search.lastmodifiedfrom && !this.search.lastmodifiedto) ||( !this.search.lastmodifiedfrom && this.search.lastmodifiedto)){
              this.isBothDateSelected_last = true
          }
        },
        selectedtodate_last(){
          if((this.search.lastmodifiedfrom && this.search.lastmodifiedto) || (!this.search.lastmodifiedfrom && !this.search.lastmodifiedto)){
              this.isBothDateSelected_last = false
          }else if((this.search.lastmodifiedfrom && !this.search.lastmodifiedto) ||( !this.search.lastmodifiedfrom && this.search.lastmodifiedto)){
              this.isBothDateSelected_last = true
          }
        },
        notBeforeToday_last: function (date) {
          this.search.lastmodifiedto = "";
          return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        disableDate_last(date) {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        let selectedFromDate = new Date(this.search.lastmodifiedfrom);
        selectedFromDate.setDate(selectedFromDate.getDate()-1);
        return date < selectedFromDate || date > today;
      },
      getSearchItems(search) {
        this.isSearchRequestActive = true
        this.search.searchprojectname  = search.searchprojectname
        this.search.responsedate_from = search.responsedate_from;
        this.search.responsedate_to = search.responsedate_to;
        this.search.resolutiondate_from = search.resolutiondate_from;
        this.search.resolutiondate_to = search.resolutiondate_to;
        this.getApplicationdata('t37','issueSearch')
        this.search.functionality = search.functionality
        this.search.task = search.task
        this.search.issuename = search.issuename
        this.search.type = search.type
        this.search.sevairty = search.sevairty
        this.search.priority = search.priority
        this.search.proman = search.proman
        this.search.engman = search.engman
        this.search.supman = search.supman
        this.search.status = search.status
        this.search.country = search.country
        this.search.allotedto = search.allotedto
        this.search.createdby = search.createdby;
        this.search.issuecode = search.issuecode;
        this.search.createddatefrom = search.createddatefrom;
        this.search.createddateto = search.createddateto;
        if(!search.createdby){
          this.search.createdby = [this.undt.username];
        }
        this.getEmpListByProjectAssigned(this.search.allotedto);
        this.getcreatedby(this.search.createdby);
        this.getissuelist(this.search);
      },
      getissuelist: function(objThis, status){
        if (objThis) {
          this.pageoffset = 1;
          this.page = 1;
        }
        this.ticketstatus=[];
        if(objThis) {
          localStorage.setItem('ticketsearchItems', JSON.stringify(objThis))
        }
        this.islistLoading= true;
        let setmoduleform='issuemaster'
        let glbMdlLblval='Ticket Master'
        // this.companymasterlist = []
        let tblNm='t42'
        this.glbMdl=tblNm+'##'+setmoduleform
        this.glbst=tblNm
        if(setmoduleform){
            this.glbMdlLbl = glbMdlLblval;
        }
        var apiURL = 'api/issue/getissuelist';
        this.input = {
          empid:this.undt.userid,
          useremail: this.undt.username,
          offset:this.pageoffset,
          limit:apiUrl.LIMIT,
          empcode: this.undt.userid,
          userrole:this.userrole,
          moduletype:'ticket',
        }
        let search = JSON.parse(localStorage.getItem('ticketsearchItems'))
        if(search) {
          this.input.issuecode  = search.issuecode
          this.input.ticketprojectname = search.searchprojectname
          this.input.responsedate_from = search.responsedate_from;
          this.input.responsedate_to = search.responsedate_to;
          this.input.resolutiondate_from = search.resolutiondate_from;
          this.input.resolutiondate_to = search.resolutiondate_to;
          this.input.lastmodifiedfrom = this.search.lastmodifiedfrom ? this.search.lastmodifiedfrom:null
          this.input.lastmodifiedto = this.search.lastmodifiedto ? this.search.lastmodifiedto:null
          this.input.functionality = search.functionality
          this.input.taskid = search.task
          this.input.issuename = search.issuename
          this.input.typename =search.type
          this.input.sevairty = search.sevairty
          this.input.priority = search.priority
          this.input.status = search.status
          this.input.proman = search.proman
          this.input.engman = search.engman
          this.input.supman = search.supman
          this.input.country = search.country
          // this.input.allotedto = search.allotedto
          this.input.createdby = search.createdby
          this.input.projectmodule = search.projectmodulename;
          this.input.application = search.applicationname
          this.input.applicationmodule = search.applicationmodulename;
          this.input.sectionmodule = search.sectionmodulename
          
          let assigne1 =''
          if(this.search.lastmodifiedto){
            assigne1 = new Date(this.search.lastmodifiedto)
            assigne1.setDate(assigne1.getDate() + 1)
            moment(String(assigne1)).format('YYYY-MM-DD');
          }
          this.input.lastmodifiedfrom = this.search.lastmodifiedfrom?this.search.lastmodifiedfrom:null
          this.input.lastmodifiedto = this.search.lastmodifiedto?assigne1:null
        
          let assignedate =''
          if(this.search.createddateto){
            assignedate = new Date(this.search.createddateto)
            assignedate.setDate(assignedate.getDate() + 1)
            moment(String(assignedate)).format('YYYY-MM-DD');
          }
          this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
          this.input.dateto = this.search.createddateto?assignedate:null
        }
        if (status){
          // this.input.allotedto = this.undt.userid
          this.input.status = status
          this.input.dashboardflag=1
        }
        if(this.search.createdby){
        this.input.createdby=this.search.createdby
        // this.input.status = this.search.searchStatus
      }
        if(this.search.searchprojectname == this.merchandisingProId){
            let oallotedto = this.search.allotedto  
          if(Array.isArray(this.search.allotedto)){
            oallotedto = this.search.allotedto[0]
            }

          this.input.allotedemp = oallotedto
        } else{
          this.input.allotedto = this.search.allotedto
        }
        
        if(this.FcRoleWiseAPI){
          apiURL = "api/issue/getissuelistfc";
         this.input.ispagefeatures=this.pagefeatures.multiviewuser ? this.pagefeatures.multiviewuser : false
          this.input.userroleid=this.roleid
          
          //this.search.allotedto = [this.undt.userid]
          if(this.input.ispagefeatures){
            if(Array.isArray(this.search.allotedto)){
          this.input.allotedto = this.search.allotedto ?  this.search.allotedto : null
          }else{
            this.input.allotedto = this.search.allotedto ? [this.search.allotedto].map(element => Number(element))  : null
            }
           }else{
          this.input.allotedto = [this.undt.userid]
          this.search.allotedto = [this.undt.userid]
          }
        }
        this.getstatuspriority(this.input)
        this.getApplicationdata('t11','issue')
        this.getApplicationdata('t44','issue')
        this.getApplicationdata('t26','issue')
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
          this.islistLoading= false;         
          this.showproject=true;   
          if(result.data.errorCode == 0){
            this.isresponce=false
            this.forceRerender()
            this.companymasterlist=result.data.data.rows;
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          }else{
            this.companymasterlist=[];
            this.totalcount = 0
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
              }).catch(e => {
        this.displayError(e)
        })
        if (this.ischartDetailsActives==true) {
            this.getticketstatuscount();
        }
      },
      clearCodeList(state) {
        if(!state) {
          this.search.issuecodelist = null
        }
      },
      issueCodeChangeSearch(node) {
        if(node && node.length>1){
          axios({
            'method': 'POST',
            'url': 'api/issue/getissuecode',
            'data': {
              issuecode: node.toUpperCase(),
              empid: this.undt.userid,
              useremail: this.undt.username,
              empcode: this.undt.userid,
            },
            'headers':{'authorization':this.tokendata}
          }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
              this.search.issuecodelist=result.data.data
              localStorage.setItem('issuecodelist', JSON.stringify(result.data.data))
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
              this.serch.issuecodelist= null
              localStorage.removeItem('issuecodelist')
            }
          })
        }
      },
      countClickable: function(priority, status){
        this.companymasterlist = []
        this.countClick= true;
        this.countPriority = priority
        this.countStatus = status
        this.isLoading= true;
        let search = JSON.parse(localStorage.getItem('ticketsearchItems'))
        var apiURL = 'api/issue/getissuelist';
        // moment(String(assignedate)).format('YYYY-MM-DD');
      

        this.input = {
          empid:this.undt.userid,
          useremail: this.undt.username,
          offset:1,
          limit:apiUrl.LIMIT,
          priority: priority,
          status: [status],
          allotedto: this.search.allotedto,
          createdby: this.search.createdby,
          datefrom: this.search.createddatefrom,
          empcode: this.undt.userid,
          userrole:this.userrole,
          moduletype:'ticket',
        }
        if(search){
          this.input.issuecode  = search.issuecode?search.issuecode:null,
          this.input.projectname = search.searchprojectname?search.searchprojectname:null,
          this.input.functionality = search.functionality? search.functionality:null,
          this.input.taskid = search.task?search.task:null,
          this.input.proman = search.proman?search.proman:null,
          this.input.engman = search.engman?search.engman:null,
          this.input.supman = search.supman?search.supman:null,
          this.input.country = search.country?search.country:null
        }

          if(this.FcRoleWiseAPI){
            apiURL = "api/issue/getissuelistfc";
          this.input.ispagefeatures=this.pagefeatures.multiviewuser ? this.pagefeatures.multiviewuser : false 
          this.input.userroleid=this.roleid
          if(this.input.ispagefeatures){
          if(Array.isArray(this.search.allotedto)){
          this.input.allotedto = this.search.allotedto ?  this.search.allotedto : null
           }else{
          this.input.allotedto = this.search.allotedto ? [this.search.allotedto].map(element => Number(element))  : null
          }
          }else{
          this.input.allotedto = [this.undt.userid]
          }
          }


        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
          this.isLoading= false;         
          this.showproject=true;   
          // this.isresponce=false;
          if(result.data.errorCode == 0){
            this.companymasterlist=result.data.data.rows;
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          }else{
            this.companymasterlist=[];
            this.totalcount = 0
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          }
        })
      },
      srchDate(value) {
        if (!value) return "";
        return moment(String(value)).format('YYYY-MM-DD 00:00:00');
      },
      dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
      },
      dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format('DD MMM YYYY HH:mm');
      },
      dateMONFormat(value){
        if (value){
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        } else{
          return ""
        }
      },
      yearMonthDateFromat(value) {
        if (!value) return "";
          return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
      },
      addmodule: function(){
        this.issue.issuename = null
        this.issue.project = null
        this.issue.functionality = null
        this.issue.type = null
        this.issue.scenario = ''
        this.issue.attachment = null
        this.issue.attachmentview = null
        this.issue.sevairty = null
        this.issue.priority = null
        this.issue.allotedto = null
        this.issue.reasonofissue = null
        this.issue.impactofissue = null
        this.issue.status = null
        this.issue.iseditstate = false
        this.issue.disblesavebtn=true
      },
      getEmpListByProjectAssigned(empid) {

        let oassignedto = []

        if(Array.isArray(empid)){
          oassignedto = empid
        }else{
          oassignedto = [this.undt.userid]
        }
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            assignedto : oassignedto,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
            this.search.allotedtolist = [];
          }
        })
      },
      getProjectManagerDetailsticket(roleid,projectid) {
            axios({
                method: "POST",
                url: "api/listing/getProjectManagerDetailsticket",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,projectidarr:projectid,empcode: this.undt.userid,useremail: this.undt.username}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==477){
                        this.issue.pmlist = result.data.data.rows
                    }else if(roleid==492){
                        this.issue.emlist = result.data.data.rows
                    }else if(roleid==515){
                        this.issue.smlist = result.data.data.rows
                    }
                }else{
                    if(roleid==477){
                        this.issue.pmlist = [];
                    }else if(roleid==492){
                        this.issue.emlist = [];
                    }else if(roleid==515){
                        this.issue.smlist = [];
                    }
                }
            });
        },
      allotedChange: function (node){
        if(node && node.length>3){
          this.isLoading = true;
          let url = "api/listing/getEmpListByProjectAssigned";
          this.input = {
          empid:this.undt.userid,
          empname:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.search.allotedtolist = result.data.data;
              let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
              this.search.allotedtolist = [];
            }
          });
        } 
      },
      selissuecsprojectSearch: function(state,value){
        this.search.searchprojectname=null
        this.search.projectmodulename = null
        this.search.applicationmodulename = null
        this.search.searchprojectname=state
        if(!state){
          this.search.functionality = null 
          this.search.functionalitylist = null
          this.search.tasklist = null
          this.search.task = null
          this.search.projectmodulelist = [];
          this.search.applicationmodulelist = [];
        }else{
          this.getApplicationdata('t37','issueSearch')
          this.getprojectmodules(state, 'pmodules');
          this.getprojectmodules(state, 'app');
          this.tickettypeformrp(state);
          this.getAppModulesbyprojectid(state);
          this.getSectionModules(state);
        }
        if(this.search.searchprojectname[0] == this.merchandisingProId && !this.FcRoleWiseAPI){
          this.search.allotedto = this.undt.userid
          this.search.createdby = null
        }
      },
      sortTypeList(typelist, type){
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            moduletype: 'ticket',
            typearr: [],
            projecttype: type
        };
        axios({
            method: "POST",
            url: 'api/ticket/sortTypeList',
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.issue.mrtypelist = result.data.data.rows;
                this.issue.mrtypelist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
            }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                this.issue.mrtypelist = [];
            }
        });
      },
      tickettypeformrp(state){
        if(state && this.search.searchprojectname == apiUrl.merchandisingProId){
          this.sortTypeList([], 'merchandising')
        } else{
          this.sortTypeList([], 'alltypes')
        }
      },
    selSectionModule(state, value) {
      this.search.sectionmodulename = null;
      this.search.sectionmodulename = state;
      if(state) {
        if(state == this.sectionmoduleId1) {
            this.productlabelname = 'Product ID';
        } else if(state == this.sectionmoduleId2) {
            this.productlabelname = 'Product Group ID';
        }
      }
    },
    selApplication(state, value) {
      this.search.applicationname = null;
      this.search.applicationname = state;
      this.getAppModulesbyAppid(state);
    },
    selProjectModule(state, value) {
      this.search.projectmodulename = null;
      this.search.projectmodulename = state;
      // this.getAppModules(state);
      
    },
    selApplicationModule(state, value) {
      this.search.applicationmodulename = null;
      this.search.applicationmodulename = state;
      // this.getSectionModules(state);
      
    },
    getSectionModules(projectid) {
      this.input = {
        projectidarr: projectid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: 'api/listing/getsectionbyprojectid',
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.search.sectionmodulelist = result.data.data;
        }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
          this.search.sectionmodulelist = [];
        }
      });
    },
    getAppModulesbyprojectid(projectid) {
      this.input = {
        projectidarr: projectid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: 'api/listing/getappmodulenamebyprojectid',
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.search.applicationmodulelist = result.data.data;
          this.search.applicationmodulelist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
          this.search.applicationmodulelist = [];
        }
      });
    },
    getAppModulesbyAppid(applicationid) {
      this.input = {
        applicationid: applicationid,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: 'api/listing/getappmodulenamebyappid',
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.search.applicationmodulelist = result.data.data;
        }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
          this.search.applicationmodulelist = [];
        }
      });
    },

      getprojectmodules(projecteid, type) {
        let url = '';
        if(type=='pmodules') {
          url = 'api/listing/getpromodulebyapplicationid';
        } else if(type=='app') {
          url = "api/listing/getappnamebyprojecteid";
        }
        this.input = {
          projectidarr: projecteid,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            if(type=='pmodules') {
              this.search.projectmodulelist = result.data.data;
              this.search.projectmodulelist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            } 
            if(type=='app') {
              this.search.applicationlist = result.data.data;
              this.search.applicationlist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
            }
          }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
            this.search.applicationmodulelist = [];
            this.search.applicationlist = [];
          }
        });
      },
      selissuecsfunctionalitySearch: function(state,value){
        this.search.functionality=null
        this.search.functionality = state
        if(!state){
          this.search.task = null
          this.search.tasklist = null
        }else{                
          this.getApplicationdata('t18', 'issueSearch')
        }
      },
      getApplicationdata: function(mdl,curracttab){            
        this.pageoffset=1
        this.inputappl = {
          tbl:mdl,
          offset:this.pageoffset,
          limit:apiUrl.LIMIT,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        }
        let apiURL='api/master/commonmaster/list'
        if(mdl == 't11'){
          apiURL='api/listing/getprojectlist'
          this.inputappl = {
              tbl:mdl
          }
        }else if(mdl == 't37' && curracttab == 'issue'){
          this.issue.functionality = null
          this.issue.functionalitylist = []
          this.inputappl = {};
          apiURL = "api/listing/getfunctionalitybyprojectid";
          this.inputappl.projectid = this.issue.project
        }else if(mdl == 't37' && curracttab == 'issueSearch'){
          this.inputappl = {};
          apiURL = "api/listing/getfunctionalitybyprojectid";
          this.inputappl.projectidarr = this.search.searchprojectname
        }else if(mdl == 't43'){
          apiURL='api/master/commonmaster/customselect'
          /* this.inputappl.projectmoduleid=this.issue.project */
          this.inputappl.isSearch= false
          this.inputappl.section= "issue"
          this.inputappl.isFilterSelect= 1
          this.inputappl.isFilterPayload= {}
        }else if(mdl == 't44'){
          apiURL='api/master/commonmaster/customselect'
          /* this.inputappl.projectmoduleid=this.issue.project */
          this.inputappl.isSearch= false
          this.inputappl.section= "issue"
          this.inputappl.isFilterSelect= 1
          this.inputappl.isFilterPayload= {}
        }else if(mdl == 't26'){
          apiURL='api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: "t26"
          }
        } else if (mdl == 't18' && curracttab == 'issue') {
          this.issue.task = null
          this.issue.tasklist = []
          apiURL = 'api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: mdl,
            useremail: this.undt.username,
            functionalityid: this.issue.functionality
          }
        } else if (mdl == 't18' && curracttab == 'issueSearch') {
          apiURL = 'api/master/commonmaster/getModuleDropdownOption'
          this.inputappl = {
            tbl: mdl,
            useremail: this.undt.username,
            functionalityid: this.search.functionality
          }
        }
        this.inputappl.useremail= this.undt.username
        this.inputappl.empcode= this.undt.userid
        axios({
          'method': 'POST',
          'url': apiURL,
          'data': this.inputappl,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {             
          if(result.data.errorCode == 0){
            if(mdl == 't11'){
              if(curracttab == 'issue'){
                // this.issue.projectlist=result.data.data
                return true
              }
            }else if(mdl == 't37' && curracttab == 'issue'){
              this.issue.functionalitylist=result.data.data
              this.issue.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
              if(this.issue.iseditstate) {
                this.issue.functionality = this.editRowData.functionality    
              } else {
                this.issue.functionality = null
                this.issue.task = null
              }
              return true
            }else if(mdl == 't37' && curracttab == 'issueSearch'){
              this.search.functionalitylist=result.data.data
              this.search.functionalitylist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
              // localStorage.setItem('functionalitylist', JSON.stringify(result.data.data))
              return true
            }else if(mdl == 't43'){
              if(curracttab == 'issue'){
                this.issue.typelist=result.data.data.rows
                return true
              }
            }else if(mdl == 't44'){
              if(curracttab == 'issue'){
                this.issue.sevairtylist=result.data.data.rows
                return true
              }
            }else if(mdl == 't26'){
              if(curracttab == 'issue'){
                // this.issue.allotedtolist=result.data.data
                return true
              }
            } else if(mdl == 't18' && curracttab == 'issue') {
              this.issue.tasklist = result.data.data
              if(this.issue.iseditstate) {
                this.issue.task = this.editRowData.taskid
              } else {
                this.issue.task = null
              }
              return true
            } else if(mdl == 't18' && curracttab == 'issueSearch') {
              this.search.tasklist = result.data.data
              return true
            }
          }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
            if(mdl == 't37' && curracttab == 'issue'){
              this.issue.functionalitylist=[]
              return true
            } else if(mdl == 't18' && curracttab == 'issue'){
              this.issue.tasklist = []
              return true
            } else if(mdl == 't37' && curracttab == 'issueSearch') {
              this.search.functionalitylist = []
              localStorage.removeItem('functionalitylist')
              return true
            }
          }
        })
        // return retResponse;
      },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      console.log(this.search)
      this.input = {
        limit: null,
        offset: null,
        issuecode : this.search.issuecode,
        ticketprojectname :  this.search.searchprojectname,
        responsedate_from : this.search.responsedate_from,
        responsedate_to : this.search.responsedate_to,
        resolutiondate_from : this.search.resolutiondate_from,
        resolutiondate_to : this.search.resolutiondate_to,
        functionality : this.search.functionality,
        taskid :  this.search.task,
        issuename :  this.search.issuename,
        typename :  this.search.type,
        sevairty :  this.search.sevairty,
        priority :  this.search.priority,
        createdby : this.search.createdby,
        // allotedto : this.search.allotedto,
        status : this.search.status,
        useremail:this.undt.username,
        empid:this.undt.userid,
        empcode: this.undt.userid,
        userrole:this.userrole,
        proman: this.search.proman,
        engman: this.search.engman,
        supman: this.search.supman,
        country: this.search.country,
        moduletype:'ticket',
      };  
      let assigne1 =''
        if(this.search.lastmodifiedto){
        assigne1 = new Date(this.search.lastmodifiedto)
        assigne1.setDate(assigne1.getDate() + 1)
        moment(String(assigne1)).format('YYYY-MM-DD');
        }
        this.input.lastmodifiedfrom = this.search.lastmodifiedfrom?this.search.lastmodifiedfrom:null
        this.input.lastmodifiedto = this.search.lastmodifiedto?assigne1:null
      let assignedate = ''
      if(this.search.createddateto){
          assignedate = new Date(this.search.createddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
      this.input.dateto = this.search.createddateto?assignedate:null 
      if(this.countStatus){
        this.input.status = [this.countStatus]
      }   
      if(this.search.searchprojectname){
          this.input.projectmodule = this.search.projectmodulename
          this.input.application = this.search.applicationname
          this.input.applicationmodule = this.search.applicationmodulename
          this.input.sectionmodule = this.search.sectionmodulename
      } 
      let oallotedto =null
      if(this.search.searchprojectname == this.merchandisingProId){
        oallotedto = this.search.allotedto
        if(Array.isArray(this.search.allotedto)){
          oallotedto = this.search.allotedto[0]
        }
          this.input.allotedemp = oallotedto
        } else{
          this.input.allotedto = this.search.allotedto
        }
        let url = "api/ticket/getTicketExport"
      if(this.FcRoleWiseAPI){
        url = "api/ticket/getTicketExportfc";
        this.input.ispagefeatures=this.pagefeatures.multiviewuser ? this.pagefeatures.multiviewuser : false
        this.input.userroleid=this.roleid
        if(this.input.ispagefeatures){
          if(Array.isArray(this.search.allotedto)){
        this.input.allotedto = this.search.allotedto ?  this.search.allotedto : null
        }else{
          this.input.allotedto = this.search.allotedto ? [this.search.allotedto].map(element => Number(element))  : null
          }
          }else{
        this.input.allotedto = [this.undt.userid]
        this.search.allotedto = [this.undt.userid]
        }
     }

      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download(result.data.data.rows,filename)
        }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        if (result.data.status) {
          this.isDetActive = true;
        } 
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
        let ShowLabel = 'BusinessTaskReportDetails_'+filename
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let CSV = ''
        if (ShowLabel) {
            let row = ''
            for (let index in arrData[0]) {
                row += index + ','
            }
          row = row.slice(0, -1)
          if(this.search.searchprojectname == this.merchandisingProId){
            CSV = 'Sr#,Ticket Code,Ticket Name,Project,Functionality,Brand,Category,Sub Category,Product ID/ Group Product ID,Type,Severity,Priority,First Assigned to,Assigned to,Creator Location,First Assignee Location,Ticket Creator Department,Ticket Assignee Department,Ticket Ageing,Ticket Status,Created By,Created Date, Lastmodified By,Lastmodified Date,Response Date,Resolution Date,SLA Ageingdays,Ageing By Lastmodifieddate' + '\r\n'
          } else if (this.search.searchprojectname == this.itHelpDesk ) {
            CSV = 'Sr#,Ticket Code,Ticket Name,Project,Functionality,Ticket Category,Ticket Sub Category,Type,Severity,Priority,First Assigned to,Assigned to,Creator Location,First Assignee Location,Ticket Creator Department,Ticket Assignee Department,Ticket Ageing,Ticket Status,Created By,Created Date,Lastmodified By,Lastmodified Date,Response Date,Resolution Date,SLA Ageingdays' + '\r\n'
          } else if(this.companycode=='xb'){
            CSV = 'Sr#,Ticket Code,Ticket Name,Project,Functionality,Type,Severity,Priority,First Assigned to,Assigned to,Creator Location,First Assignee Location,Ticket Creator Department,Ticket Assignee Department,Ticket Ageing,Ticket Status,Created By,Created Date,Lastmodified By,Lastmodified Date,Ageing Bucket,Response Date,Resolution Date,SLA Ageingdays' + '\r\n'
          }
          else{
            CSV = 'Sr#,Ticket Code,Ticket Name,Project,Functionality,Ticket Category,Ticket Sub Category,Type,Severity,Priority,First Assigned to,Assigned to,Creator Location,First Assignee Location,Ticket Creator Department,Ticket Assignee Department,Ticket Ageing,Project Manager,Engineering Manager, Program Manager, Ticket Status,Created By,Created Date,Lastmodified By,Lastmodified Date,Ageing Bucket,Response Date,Resolution Date,SLA Ageingdays,Child ID,Child Status' + '\r\n'
          }

        }
        var row = ''
        if(this.search.searchprojectname == this.merchandisingProId){
          for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let issuecode = index.hasOwnProperty('issuecode') ? index.issuecode == null || index.issuecode.toLowerCase() == 'na' ? '' : index.issuecode : '';
            let issuename = index.hasOwnProperty('issuename') ? index.issuename == null || index.issuename.toLowerCase() == 'na' ? '': index.issuename.replace(/"/g, '""') : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
            let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null || index.functionlityname.toLowerCase() == 'na' ? '': index.functionlityname : '';
            let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename == null || index.projectmodulename.toLowerCase() == 'na' ? '': index.projectmodulename : '';
            let applicationname = index.hasOwnProperty('applicationname') ? index.applicationname == null || index.applicationname.toLowerCase() == 'na' ? '': index.applicationname : '';
            let applicationmodulename = index.hasOwnProperty('applicationmodulename') ? index.applicationmodulename == null || index.applicationmodulename.toLowerCase() == 'na' ? '': index.applicationmodulename : '';
            let sectionname = index.hasOwnProperty('sectionname') ? index.sectionname == null || index.sectionname.toLowerCase() == 'na' ? '': index.sectionname : '';
            let typename = index.hasOwnProperty('typename') ? index.typename == null || index.typename.toLowerCase() == 'na' ? '': index.typename : '';              
            let sevairtyname = index.hasOwnProperty('sevairtyname') ? index.sevairtyname == null || index.sevairtyname.toLowerCase() == 'na' ?'':index.sevairtyname : '';
            let priority = index.hasOwnProperty('priority') ? index.priority ==null || index.priority.toLowerCase() == 'na' ? '':index.priority : '';
            let empnamedept = index.hasOwnProperty('empnamedept') ? index.empnamedept ==null || index.empnamedept.toLowerCase() =='na'?'':index.empnamedept : '';
            
            this.match = empnamedept.match(this.regex);
            if (this.match) {
              this.encryptedText = this.match[1];
              this.decryptedText = this.decryptText(this.encryptedText);
              empnamedept = empnamedept.replace(`[${this.encryptedText}]`, this.decryptedText);
            }
            let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? '':index.status : '';
            let createdby = index.hasOwnProperty('creator') ? index.creator ==null || index.creator.toLowerCase() =='na'?'':index.creator : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate ==null || index.createddate.toLowerCase() =='na'?'':index.createddate : '';
            let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby ==null || index.lastmodifiedby.toLowerCase() =='na'?'':index.lastmodifiedby : '';
            let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate ==null || index.lastmodifieddate.toLowerCase() =='na'?'':index.lastmodifieddate : '';
            let firstassignedto = index.hasOwnProperty('firstassignedto') ? index.firstassignedto ==null || index.firstassignedto.toLowerCase() == 'na' ? '':index.firstassignedto : '';
            let creatordepartment = index.creatordepartment ? this.decryptText(index.creatordepartment) : ''
            let assigneedeparment = index.assigneedeparment ? this.decryptText(index.assigneedeparment) : ''
            let creatorjoblocation = index.creatorjoblocation ? this.decryptText(index.creatorjoblocation) : ''
            let fajoblocation = index.fajoblocation ? this.decryptText(index.fajoblocation) : ''
            let ageingdays = index.hasOwnProperty('ageingdays') ? index.ageingdays ==null || index.ageingdays == 'na' ? '':index.ageingdays : '';
            let sla_responsedate = index.hasOwnProperty('sla_responsedate') ? index.sla_responsedate ==null || index.sla_responsedate == 'na' ? '':index.sla_responsedate : '';
            let sla_resolutiondate = index.hasOwnProperty('sla_resolutiondate') ? index.sla_resolutiondate ==null || index.sla_resolutiondate == 'na' ? '':index.sla_resolutiondate : '';

            let sla_ageingdays = index.hasOwnProperty('sla_ageingdays') ? index.sla_ageingdays ==null || index.sla_ageingdays == 'na' ? '':parseInt(index.sla_ageingdays) > 0 ? index.sla_ageingdays : '' : '';
            let lm_ageingdays = index.hasOwnProperty('lm_ageingdays') ? index.lm_ageingdays ==null || index.lm_ageingdays == 'na' ? '':index.lm_ageingdays : '';

            createddate = this.dateMONFormat(createddate)
            lastmodifieddate = this.dateMONFormat(lastmodifieddate)
            sla_responsedate = this.dateMONFormat(sla_responsedate)
            sla_resolutiondate = this.dateMONFormat(sla_resolutiondate)

              row += '"' + srno + '",',
              row += '"' + issuecode + '",',
              row += '"' + issuename + '",',
              row += '"' + projectname + '",',
              row += '"' + functionlityname + '",',
              row += '"' + projectmodulename + '",',
              row += '"' + applicationname + '",',
              row += '"' + applicationmodulename + '",',
              row += '"' + sectionname + '",',
              row += '"' + typename + '",',
              row += '"' + sevairtyname + '",',
              row += '"' + priority + '",',
              row += '"' + firstassignedto + '",',
              row += '"' + empnamedept + '",',
              row += '"' + creatorjoblocation + '",',
              row += '"' + fajoblocation + '",',
              row += '"' + creatordepartment + '",',
              row += '"' + assigneedeparment + '",',
              row += '"' + ageingdays + '",',
              // row += '"' + projectmaanager + '",',
              // row += '"' + engineeringmaanager + '",',

              row += '"' + status + '",',
              row += '"' + createdby + '",'
              row += '"' + createddate + '",'
              row += '"' + lastmodifiedby + '",'
              row += '"' + lastmodifieddate + '",'
              row += '"' + sla_responsedate + '",'
              row += '"' + sla_resolutiondate + '",'
              row += '"' + sla_ageingdays + '",'
              row += '"' + lm_ageingdays + '",',
              row += '\r\n'
          }
        } else if (this.search.searchprojectname == this.itHelpDesk) {
          for (let i=0 ; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let issuecode = index.hasOwnProperty('issuecode') ? index.issuecode == null || index.issuecode.toLowerCase() == 'na' ? '' : index.issuecode : '';
            let issuename = index.hasOwnProperty('issuename') ? index.issuename == null || index.issuename.toLowerCase() == 'na' ? '': index.issuename.replace(/"/g, '""') : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
            let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null || index.functionlityname.toLowerCase() == 'na' ? '': index.functionlityname : '';
            let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename == null || index.projectmodulename.toLowerCase() == 'na' ? '': index.projectmodulename : '';
            let applicationmodulename = index.hasOwnProperty('applicationmodulename') ? index.applicationmodulename == null || index.applicationmodulename.toLowerCase() == 'na' ? '': index.applicationmodulename : '';
            let typename = index.hasOwnProperty('typename') ? index.typename == null || index.typename.toLowerCase() == 'na' ? '': index.typename : '';              
            let sevairtyname = index.hasOwnProperty('sevairtyname') ? index.sevairtyname == null || index.sevairtyname.toLowerCase() == 'na' ?'':index.sevairtyname : '';
            let priority = index.hasOwnProperty('priority') ? index.priority ==null || index.priority.toLowerCase() == 'na' ? '':index.priority : '';
            let empnamedept = index.hasOwnProperty('empnamedept') ? index.empnamedept ==null || index.empnamedept.toLowerCase() =='na'?'':index.empnamedept : '';

            this.match = empnamedept.match(this.regex);
            if (this.match) {
              this.encryptedText = this.match[1];
              this.decryptedText = this.decryptText(this.encryptedText);
              empnamedept = empnamedept.replace(`[${this.encryptedText}]`, this.decryptedText);
            }
            let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? '':index.status : '';
            let createdby = index.hasOwnProperty('creator') ? index.creator ==null || index.creator.toLowerCase() =='na'?'':index.creator : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate ==null || index.createddate.toLowerCase() =='na'?'':index.createddate : '';
            let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby ==null || index.lastmodifiedby.toLowerCase() =='na'?'':index.lastmodifiedby : '';
            let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate ==null || index.lastmodifieddate.toLowerCase() =='na'?'':index.lastmodifieddate : '';
            let firstassignedto = index.hasOwnProperty('firstassignedto') ? index.firstassignedto ==null || index.firstassignedto.toLowerCase() == 'na' ? '':index.firstassignedto : '';
            let creatordepartment = index.creatordepartment ? this.decryptText(index.creatordepartment) : ''
            let assigneedeparment = index.assigneedeparment ? this.decryptText(index.assigneedeparment) : ''
            let creatorjoblocation = index.creatorjoblocation ? this.decryptText(index.creatorjoblocation) : ''
            let fajoblocation = index.fajoblocation ? this.decryptText(index.fajoblocation) : ''
            let ageingdays = index.hasOwnProperty('ageingdays') ? index.ageingdays ==null || index.ageingdays == 'na' ? '':index.ageingdays : '';
            createddate = this.dateMONFormat(createddate)
            lastmodifieddate = this.dateMONFormat(lastmodifieddate)
            let sla_responsedate = index.hasOwnProperty('sla_responsedate') ? index.sla_responsedate ==null || index.sla_responsedate == 'na' ? '':index.sla_responsedate : '';
            let sla_resolutiondate = index.hasOwnProperty('sla_resolutiondate') ? index.sla_resolutiondate ==null || index.sla_resolutiondate == 'na' ? '':index.sla_resolutiondate : '';

            let sla_ageingdays = index.hasOwnProperty('sla_ageingdays') ? index.sla_ageingdays ==null || index.sla_ageingdays == 'na' ? '':parseInt(index.sla_ageingdays) > 0 ? index.sla_ageingdays : '' : '';

            sla_responsedate = this.dateMONFormat(sla_responsedate)
            sla_resolutiondate = this.dateMONFormat(sla_resolutiondate)

            row += '"' + srno + '",',
              row += '"' + issuecode + '",',
              row += '"' + issuename + '",',
              row += '"' + projectname + '",',
              row += '"' + functionlityname + '",',
              row += '"' + projectmodulename + '",',
              row += '"' + applicationmodulename + '",',
              row += '"' + typename + '",',
              row += '"' + sevairtyname + '",',
              row += '"' + priority + '",',
              row += '"' + firstassignedto + '",',
              row += '"' + empnamedept + '",',
              row += '"' + creatorjoblocation + '",',
              row += '"' + fajoblocation + '",',
              row += '"' + creatordepartment + '",',
              row += '"' + assigneedeparment + '",',
              row += '"' + ageingdays + '",',
              // row += '"' + projectmaanager + '",',
              // row += '"' + engineeringmaanager + '",',
              row += '"' + status + '",',
              row += '"' + createdby + '",'
              row += '"' + createddate + '",'
              row += '"' + lastmodifiedby + '",'
              row += '"' + lastmodifieddate + '",'
              row += '"' + sla_responsedate + '",'
              row += '"' + sla_resolutiondate + '",'
              row += '"' + sla_ageingdays + '",'
              row += '\r\n'
          }
        }
          else if(this.companycode=='xb'){
            for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let issuecode = index.hasOwnProperty('issuecode') ? index.issuecode == null || index.issuecode.toLowerCase() == 'na' ? '' : index.issuecode : '';
            let issuename = index.hasOwnProperty('issuename') ? index.issuename == null || index.issuename.toLowerCase() == 'na' ? '': index.issuename.replace(/"/g, '""') : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
            let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null || index.functionlityname.toLowerCase() == 'na' ? '': index.functionlityname : '';
            let typename = index.hasOwnProperty('typename') ? index.typename == null || index.typename.toLowerCase() == 'na' ? '': index.typename : '';              
            let sevairtyname = index.hasOwnProperty('sevairtyname') ? index.sevairtyname == null || index.sevairtyname.toLowerCase() == 'na' ?'':index.sevairtyname : '';
            let priority = index.hasOwnProperty('priority') ? index.priority ==null || index.priority.toLowerCase() == 'na' ? '':index.priority : '';
            let empfullname = index.hasOwnProperty('empfullname') ? index.empfullname ==null || index.empfullname.toLowerCase() =='na'?'':index.empfullname : '';
            let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? '':index.status : '';
            let createdby = index.hasOwnProperty('creator') ? index.creator ==null || index.creator.toLowerCase() =='na'?'':index.creator : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate ==null || index.createddate.toLowerCase() =='na'?'':index.createddate : '';
            let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby ==null || index.lastmodifiedby.toLowerCase() =='na'?'':index.lastmodifiedby : '';
            let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate ==null || index.lastmodifieddate.toLowerCase() =='na'?'':index.lastmodifieddate : '';
            let firstassignedto = index.hasOwnProperty('firstassignedto') ? index.firstassignedto ==null || index.firstassignedto.toLowerCase() == 'na' ? '':index.firstassignedto : '';
            let creatordepartment = index.creatordepartment ? this.decryptText(index.creatordepartment) : ''
            let assigneedeparment = index.assigneedeparment ? this.decryptText(index.assigneedeparment) : ''
            let creatorjoblocation = index.creatorjoblocation ? this.decryptText(index.creatorjoblocation) : ''
            let fajoblocation = index.fajoblocation ? this.decryptText(index.fajoblocation) : ''
            let ageingdays = index.hasOwnProperty('ageingdays') ? index.ageingdays ==null || index.ageingdays == 'na' ? '':index.ageingdays : '';
            createddate = this.dateMONFormat(createddate)
            lastmodifieddate = this.dateMONFormat(lastmodifieddate)
            let sla_responsedate = index.hasOwnProperty('sla_responsedate') ? index.sla_responsedate ==null || index.sla_responsedate == 'na' ? '':index.sla_responsedate : '';
            let sla_resolutiondate = index.hasOwnProperty('sla_resolutiondate') ? index.sla_resolutiondate ==null || index.sla_resolutiondate == 'na' ? '':index.sla_resolutiondate : '';

            let sla_ageingdays = index.hasOwnProperty('sla_ageingdays') ? index.sla_ageingdays ==null || index.sla_ageingdays == 'na' ? '':parseInt(index.sla_ageingdays) > 0 ? index.sla_ageingdays : '' : '';

            sla_responsedate = this.dateMONFormat(sla_responsedate)
            sla_resolutiondate = this.dateMONFormat(sla_resolutiondate)
            let ageingbucket = ''

            if(ageingdays >= 0 && ageingdays <=5){
              ageingbucket = '0-5d'
            }
            else if(ageingdays > 5 && ageingdays <=10){
              ageingbucket = '6-10d'
            }
            else if(ageingdays > 10 && ageingdays <= 20){
              ageingbucket = '11-20d'
            }
            else if(ageingdays > 20 && ageingdays <=30){
              ageingbucket = '21-30d'
            }
            else if(ageingdays > 30){
              ageingbucket = '30d+'
            }

              row += '"' + srno + '",',
              row += '"' + issuecode + '",',
              row += '"' + issuename + '",',
              row += '"' + projectname + '",',
              row += '"' + functionlityname + '",',
              row += '"' + typename + '",',
              row += '"' + sevairtyname + '",',
              row += '"' + priority + '",',
              row += '"' + firstassignedto + '",',
              row += '"' + empfullname + '",',
              row += '"' + creatorjoblocation + '",',
              row += '"' + fajoblocation + '",',
              row += '"' + creatordepartment + '",',
              row += '"' + assigneedeparment + '",',
              row += '"' + ageingdays + '",',
              row += '"' + status + '",',
              row += '"' + createdby + '",'
              row += '"' + createddate + '",'
              row += '"' + lastmodifiedby + '",'
              row += '"' + lastmodifieddate + '",'
              row += '"' + ageingbucket + '",'
              row += '"' + sla_responsedate + '",'
              row += '"' + sla_resolutiondate + '",'
              row += '"' + sla_ageingdays + '",'
              row += '\r\n'
          }

         // }
        } else {
          for (let i=0; i<arrData.length; i++) {
            let index = arrData[i]
            let srno = i+1
            let issuecode = index.hasOwnProperty('issuecode') ? index.issuecode == null || index.issuecode.toLowerCase() == 'na' ? '' : index.issuecode : '';
            let issuename = index.hasOwnProperty('issuename') ? index.issuename == null || index.issuename.toLowerCase() == 'na' ? '': index.issuename.replace(/"/g, '""') : '';
            let projectname = index.hasOwnProperty('projectname') ? index.projectname ==null || index.projectname.toLowerCase() =='na' ? '' : index.projectname : '';
            let functionlityname = index.hasOwnProperty('functionlityname') ? index.functionlityname == null || index.functionlityname.toLowerCase() == 'na' ? '': index.functionlityname : '';
            let projectmodulename = index.hasOwnProperty('projectmodulename') ? index.projectmodulename == null || index.projectmodulename.toLowerCase() == 'na' ? 'NA': index.projectmodulename : 'NA';
            let applicationmodulename = index.hasOwnProperty('applicationmodulename') ? index.applicationmodulename == null || index.applicationmodulename.toLowerCase() == 'na' ? 'NA': index.applicationmodulename : 'NA';
            let typename = index.hasOwnProperty('typename') ? index.typename == null || index.typename.toLowerCase() == 'na' ? '': index.typename : '';              
            let sevairtyname = index.hasOwnProperty('sevairtyname') ? index.sevairtyname == null || index.sevairtyname.toLowerCase() == 'na' ?'':index.sevairtyname : '';
            let priority = index.hasOwnProperty('priority') ? index.priority ==null || index.priority.toLowerCase() == 'na' ? '':index.priority : '';
            let empfullname = index.hasOwnProperty('empfullname') ? index.empfullname ==null || index.empfullname.toLowerCase() =='na'?'':index.empfullname : '';
            let status = index.hasOwnProperty('status') ? index.status ==null || index.status.toLowerCase() =='na' ? '':index.status : '';
            let createdby = index.hasOwnProperty('creator') ? index.creator ==null || index.creator.toLowerCase() =='na'?'':index.creator : '';
            let createddate = index.hasOwnProperty('createddate') ? index.createddate ==null || index.createddate.toLowerCase() =='na'?'':index.createddate : '';
            let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby ==null || index.lastmodifiedby.toLowerCase() =='na'?'':index.lastmodifiedby : '';
            let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate ==null || index.lastmodifieddate.toLowerCase() =='na'?'':index.lastmodifieddate : '';
            let firstassignedto = index.hasOwnProperty('firstassignedto') ? index.firstassignedto ==null || index.firstassignedto.toLowerCase() == 'na' ? '':index.firstassignedto : '';
            let creatordepartment = index.creatordepartment ? this.decryptText(index.creatordepartment) : ''
            let assigneedeparment = index.assigneedeparment ? this.decryptText(index.assigneedeparment) : ''
            let creatorjoblocation = index.creatorjoblocation ? this.decryptText(index.creatorjoblocation) : ''
            let fajoblocation = index.fajoblocation ? this.decryptText(index.fajoblocation) : ''
            let ageingdays = index.hasOwnProperty('ageingdays') ? index.ageingdays ==null || index.ageingdays == 'na' ? '':index.ageingdays : '';
            let projectmaanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager ==null || index.projectmaanager.toLowerCase() == 'na' ? '':index.projectmaanager : '';
            let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager ==null || index.engineeringmaanager.toLowerCase() == 'na' ? '':index.engineeringmaanager : '';
            let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager ==null || index.programmanager.toLowerCase() == 'na' ? '':index.programmanager : '';
            createddate = this.dateMONFormat(createddate)
            lastmodifieddate = this.dateMONFormat(lastmodifieddate)
            let sla_responsedate = index.hasOwnProperty('sla_responsedate') ? index.sla_responsedate ==null || index.sla_responsedate == 'na' ? '':index.sla_responsedate : '';
            let sla_resolutiondate = index.hasOwnProperty('sla_resolutiondate') ? index.sla_resolutiondate ==null || index.sla_resolutiondate == 'na' ? '':index.sla_resolutiondate : '';

            let sla_ageingdays = index.hasOwnProperty('sla_ageingdays') ? index.sla_ageingdays ==null || index.sla_ageingdays == 'na' ? '':parseInt(index.sla_ageingdays) > 0 ? index.sla_ageingdays : '' : '';
            let childcode = index.hasOwnProperty('childcode') ? index.childcode ==null ? 'NA':index.childcode : 'NA';
            let childstatus = index.hasOwnProperty('childstatus') ? index.childstatus ==null ? 'NA':index.childstatus : 'NA';

            sla_responsedate = this.dateMONFormat(sla_responsedate)
            sla_resolutiondate = this.dateMONFormat(sla_resolutiondate)
            let ageingbucket = ''

            if(ageingdays >= 0 && ageingdays <=5){
              ageingbucket = '0-5d'
            }
            else if(ageingdays > 5 && ageingdays <=10){
              ageingbucket = '6-10d'
            }
            else if(ageingdays > 10 && ageingdays <= 20){
              ageingbucket = '11-20d'
            }
            else if(ageingdays > 20 && ageingdays <=30){
              ageingbucket = '21-30d'
            }
            else if(ageingdays > 30){
              ageingbucket = '30d+'
            }

              row += '"' + srno + '",',
              row += '"' + issuecode + '",',
              row += '"' + issuename + '",',
              row += '"' + projectname + '",',
              row += '"' + functionlityname + '",',
              row += '"' + projectmodulename + '",',
              row += '"' + applicationmodulename + '",',
              row += '"' + typename + '",',
              row += '"' + sevairtyname + '",',
              row += '"' + priority + '",',
              row += '"' + firstassignedto + '",',
              row += '"' + empfullname + '",',
              row += '"' + creatorjoblocation + '",',
              row += '"' + fajoblocation + '",',
              row += '"' + creatordepartment + '",',
              row += '"' + assigneedeparment + '",',
              row += '"' + ageingdays + '",',
              row += '"' + projectmaanager + '",',
              row += '"' + engineeringmaanager + '",',
              row += '"' + programmanager + '",',
              row += '"' + status + '",',
              row += '"' + createdby + '",'
              row += '"' + createddate + '",'
              row += '"' + lastmodifiedby + '",'
              row += '"' + lastmodifieddate + '",'
              row += '"' + ageingbucket + '",'
              row += '"' + sla_responsedate + '",'
              row += '"' + sla_resolutiondate + '",'
              row += '"' + sla_ageingdays + '",'
              row += '"' + childcode + '",'
              row += '"' + childstatus + '",'
              row += '\r\n'
          }

        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        let fileName = 'Ticketlist_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report Successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });     
    }
},
  export (data, filename, mime) {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    download(blob, filename, mime)
  }
}
}
</script>
<style scoped>
.displayblk{
    display:none !important;
}
.bordertable td,.bordertable th{
  text-align: center;
  border: 1px solid black;
	/* padding-top:0; */
	/* padding-bottom:0; */
}
.bordertable .row1{
  color:black;
}
.bordertable thead {
    border-top: 1.5px solid black;
    border-bottom: 1.5px solid black 
}
</style>
