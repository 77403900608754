import { render, staticRenderFns } from "./TicketApprovalMaster.vue?vue&type=template&id=ffbea3ba&scoped=true"
import script from "./TicketApprovalMaster.vue?vue&type=script&lang=js"
export * from "./TicketApprovalMaster.vue?vue&type=script&lang=js"
import style0 from "./TicketApprovalMaster.vue?vue&type=style&index=0&id=ffbea3ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffbea3ba",
  null
  
)

export default component.exports