<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-1">Application Environment</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link :to="{ name: 'appenvironmentList', params: { search: true }}">Application Environment List</router-link>
                </li>
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Application Environment</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" style="margin-top:10px;">
            <div class="card-body">
              <form class="form form-vertical">
                <div class="row">
                    <div class="col-md-6 mb-1">
                        <div class="form-group">
                        <label for="projectmasterdd">Project Name</label>
                        <treeselect :disabled="isRedirected" v-on:search-change="projectChangesearch" v-model="appenv.project" :options="appenv.projectlist" @input="selenvproj" placeholder="Enter min 3 chars to search project name" @close="validateForm('t13',$event)" />
                        <div class="errorinputmsg" v-if="this.errors.appenv.project">{{ this.errors.appenv.project }}</div>
                        </div>
                    </div>
                  <div class="col-md-6 mb-1">
                    <div class="form-group">
                      <label for="applicationmasterdd">Application Name</label>
                      <treeselect :disabled="isRedirected" v-model="appenv.application" :options="appenv.applicationlist" @input="selenvappl" @close="validateForm('t13',$event)" />
                      <div class="errorinputmsg" v-if="this.errors.appenv.application">{{ this.errors.appenv.application }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-1">
                      <div class="form-group">
                          <label for="environmenttypedd">Environment Type</label>
                          <select class="form-control environmenttypedd" id="environmenttypedd" v-model="appenv.type" v-bind:class="{'form-control':true, '' : !valid2Text(appenv.type) && appenvtypeBlured}" v-on:blur="appenvtypeBlured = true">
                              <option value="">Select Environment Type</option>
                              <option value="Local">Local</option>
                              <option value="Stage">Stage</option>
                              <option value="Preproduction">Preproduction</option>
                              <option value="Live/Production">Live/Production</option>
                          </select>
                          <div class="errorinputmsg" v-if="!valid2Text(appenv.type) && appenvtypeBlured">Enviornment type is required</div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-1">
                      <div class="form-group">
                          <label for="">Data Center Name</label>
                              <div class="input-group mb-1">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"><codesandbox-icon size="1.5x" class="custom-class"></codesandbox-icon></span>
                              </div>
                              <input type="text" id="" class="form-control" name="" placeholder="Data Center Name"  v-model="appenv.name" v-bind:class="{'form-control':true, '' : !validText(appenv.name) && appenvnameBlured}" v-on:blur="appenvnameBlured = true"/>
                              <div class="errorinputmsg" v-if="!validText(appenv.name) && appenvnameBlured">Data center name must be  greater than 5 char, and you must enter a letters</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-1">
                      <div class="form-group">
                          <label for="hostingplatform">Hosting Platform</label>
                            <div class="input-group mb-1">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"><cloud-icon size="1.5x" class="custom-class"></cloud-icon></span>
                              </div>
                              <input type="text" id="hostingplatform" class="form-control" name="" placeholder="Hosting Platform"  v-model="appenv.host" v-bind:class="{'form-control':true, '' : !validText(appenv.host) && appenvhostBlured}" v-on:blur="appenvhostBlured = true"/>
                              <div class="errorinputmsg" v-if="!validText(appenv.host) && appenvhostBlured">Hosting platform must be  greater than 5 char, and you must enter a letters</div>
                            </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-1">
                      <div class="form-group">
                          <label for="hostingplatpath">Hosting Path</label>
                              <div class="input-group mb-1">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"> <link-2-icon size="1.5x" class="custom-class"></link-2-icon></span>
                              </div>
                              <input type="text" id="hostingplatpath" class="form-control" name="" placeholder="Hosting Path"  v-model="appenv.hostpath" v-bind:class="{'form-control':true, '' : !validText(appenv.hostpath) && appenvhostpathBlured}" v-on:blur="appenvhostpathBlured = true"/>
                              <div class="errorinputmsg" v-if="!validText(appenv.hostpath) && appenvhostpathBlured">Hosting path must be  greater than 5 char, and you must enter a letters</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-1">
                      <div class="form-group">
                          <label for="hostingplatdetails">Hosting Details</label>
                              <div class="input-group mb-1">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"><box-icon size="1.5x" class="custom-class"></box-icon></span>
                              </div>
                              <input type="text" id="hostingplatdetails" class="form-control" name="" placeholder="Hosting Details" v-model="appenv.hostdetails" v-bind:class="{'form-control':true, '' : !validText(appenv.hostdetails) && appenvhostdetailspathBlured}" v-on:blur="appenvhostdetailspathBlured = true"/>
                              <div class="errorinputmsg" v-if="!validText(appenv.hostdetails) && appenvhostdetailspathBlured">Hosting details must be  greater than 5 char, and you must enter a letters</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6 mb-1">
                      <div class="form-group">
                          <label for="hostingplaturl">Hosting Url</label>
                            <div class="input-group mb-1">
                              <div class="input-group-prepend">
                                  <span class="input-group-text"><chrome-icon size="1.5x" class="custom-class"></chrome-icon></span>
                              </div>
                              <input type="text" id="hostingplaturl" class="form-control" name="" placeholder="Hosting Url" v-model="appenv.hosturl" v-bind:class="{'form-control':true, '' : !validText(appenv.hosturl) && appenvhosturlBlured}" v-on:blur="appenvhosturlBlured = true"/>
                              <div class="errorinputmsg" v-if="!validText(appenv.hosturl) && appenvhosturlBlured">Hosting url must be  greater than 5 char, and you must enter a letters</div>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-12 mb-1 text-right">
                      <button type="button" class="btn btn-relief-primary mr-1"  v-on:click.stop.prevent="saveData()" id="submitbtnt13">Submit</button>
                      <button type="reset" class="btn btn-outline-secondary">Reset</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { PackageIcon, EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import commonMethods from '../../utils/commonMethods';

export default {
    name:'Masters',
    components:{
        Loading,
        VueElementLoading,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
    },
    mixins: [ commonMethods ],

    data() {
        return {
            isRedirected: false,
            card_content: "card-content",
            collapse: "collapse",
            isRequestActive: true,
            isLoading: false,
            fullPage: true,
            showempdata: [],
            isProjectSel: 0,
            isProjectRoleSel: 0,
            showAddForm:false,
            projectType:[],
            appenv:{
                name:'',
                host:'',
                hostpath:'',
                hostdetails:'',
                hosturl:'',
                type:'',
                application:null,
                applicationlist:[{
                    id:'',
                    label:''
                }],
                project:null,
                projectlist:[{
                    id:'',
                    label:''
                }]

            },
            appenvhosturlBlured:false,
            appenvhostdetailspathBlured:false,
            appenvhostpathBlured:false,
            appenvhostBlured:false,
            appenvnameBlured:false,
            appenvtypeBlured:false,
            errors: {
                appenv:{
                    name:null,
                    host:null,
                    hostpath:null,
                    hostdetails:null,
                    hosturl:null,
                    type:null,
                    application:null,
                    project:null
                },
            },
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            pageoffset:1,
            pagefeatures:[],
            prevtabRowData:[],
            roleassitabRowData:[],
            tokendata:null
        }
    },
    created() {
      this.pagefeatures = {}
      var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
      pagefeatures1.forEach(element => {
        if (element.page === '/assignments') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
      })
    },
    mounted(){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            if(this.$route.params.backbtnstate === 'add' || this.$route.params.backbtnstate === 'create') {
                this.isRedirected = true
                this.getProjectList('t11')
                this.appenv.project = this.$route.params.clickeddata.projectid
                this.appenv.application = this.$route.params.clickeddata.applicationid
            }
        }
    },
    methods:{
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        valid2Text : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(){
          this.valid = false
          this.appenvhosturlBlured=true
          this.appenvhostdetailspathBlured=true
          this.appenvhostpathBlured=true
          this.appenvhostBlured=true
          this.appenvnameBlured=true
          this.appenvtypeBlured=true

          if((this.validText(this.appenv.name) && this.validText(this.appenv.host) && this.validText(this.appenv.hostpath) && this.validText(this.appenv.hostdetails)  && this.validText(this.appenv.hosturl) && this.valid2Text(this.appenv.type) )){
            this.valid = true;
          }
          return this.valid
        },
        addModule(objThis){
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle == 't36'){
                this.proejctmodule.disblesavebtn=true
            }else if(inputEle == 't11'){
                this.project.iseditstate = false
            }
            $('.devUniqueForm').find('input[type=text]').val('')
            $(objThis.currentTarget.dataset.target).find('#submitbtn'+inputEle).attr('data-isedit',false)
            $('#glbsearchinpele').val('')
            this.isAddState=true
            this.roleselection.project=null
            this.roleselection.role=null
            this.roleallocation.role=null
            this.roleallocation.employee=null
            this.proejctmodule.name=null
            this.proejctmodule.project=null
            this.project.projecttype=null
            this.project.business=null
            this.project.desc=null
            this.project.company=null;
            this.project.name=null
            this.application.name=null
            this.application.project=null
            this.application.projectmodule=null
            this.application.applicationtype=null
            this.application.techapplicationtype=null
            this.application.applicationhost=null
            this.application.applicationclient=null
            this.application.applicationaccess=null
            this.appenv.application = null
            this.appenv.project = null
            this.roleselection.role = null
            this.roleselection.project=null
            this.rolevalue=null
            this.emplvalue=null
            this.projvalue=null
            this.roleallocation.role =null
            this.roleallocation.employee = null
            this.proejctmodule.name =null
            this.proejctmodule.project =null
            this.applicationmodule.projectid=null
            this.applicationmodule.projectmoduleid=null
            this.applicationmodule.applicationid=null
            this.applicationmodule.applicationmodulename=null
            this.applicationmodule.applicationmoduledescription=null
            this.sectionmodule.projectid=null
            this.sectionmodule.applicationmoduleid=null
            this.sectionmodule.applicationid=null
            this.sectionmodule.sectionname=null
            this.sectionmodule.sectiondescription=null
            this.sectionmodule.applicationname=null
            this.sectionmodule.projectname=null
            this.functionalitymodule.sectionmasterid=null
            this.functionalitymodule.projectid=null
            this.functionalitymodule.applicationmoduleid=null
            this.functionalitymodule.applicationid=null
            this.functionalitymodule.functionlityname=null
            this.functionalitymodule.functionlitydescription=null
            this.functionalitymodule.applicationname=null
            this.functionalitymodule.applicationmodulename=null
            this.functionalitymodule.projectname=null
        },
        saveData(){           
          this.valid = this.validate() && this.validateForm();
          if (this.valid) {
              let tblNm= 't13'
              this.input = {
                tbl: tblNm,
                section: 'assignment',
                createdby: this.undt.username,
                empcode: this.undt.userid,
                useremail: this.undt.username
              }
              let apiURL='api/master/commonmaster/save'
              if(this.appenv.application){
                  this.input.name=this.appenv.name
              }
              if(this.appenv.application){
                  this.input.applicationmasterdd=this.appenv.application
              }
              if(this.appenv.project){
                  this.input.projectmasterdd=this.appenv.project
              }
              if(document.getElementById('environmenttypedd').value){
                  this.input.environmenttypedd=document.getElementById('environmenttypedd').value
              }
              if(document.getElementById('hostingplatform').value){
                  this.input.hostingplatform=document.getElementById('hostingplatform').value
              }
              if(document.getElementById('hostingplatpath').value){
                  this.input.hostingplatpath=document.getElementById('hostingplatpath').value
              }
              if(document.getElementById('hostingplatdetails').value){
                  this.input.hostingplatdetails=document.getElementById('hostingplatdetails').value
              }
              if(document.getElementById('hostingplaturl').value){
                  this.input.hostingplaturl=document.getElementById('hostingplaturl').value
              }
              this.isAddState=false
              axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
              })
              .then(result => {             
                  if(result.data.errorCode == 0){
                    Swal.fire({
                      title: "Success!",
                      text: result.data.msg,
                      icon: 'success',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                    })
                    this.$router.push({ name: 'appenvironmentList', params:{ search: true}})
                  }
                  else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
              }).catch(e => {
                this.displayError(e)
            })
          }
        },
        validateForm(){
          this.clearerrorForm();
          this.isAddState=false
          var isValid=true;
          if(!this.appenv.application){
            this.errors.appenv.application="Application name is required";
            isValid= false;
          }
          if(!this.appenv.project){
            this.errors.appenv.project="Project name is required";
            isValid= false;
          }
          return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
          this.errors.appenv.name = null
          this.errors.appenv.host = null
          this.errors.appenv.hostpath = null
          this.errors.appenv.hostdetails = null
          this.errors.appenv.hosturl = null
          this.errors.appenv.type = null
          this.errors.appenv.application = null
          this.errors.appenv.project = null
        },
        dateFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY');
        },
        getProjectList(mdl){
            this.pageoffset=1
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                //createdby: this.undt.username,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            let apiURL='api/master/commonmaster/list'  
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.inputappl,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                if(result.data.errorCode == 0){
                    let tmpClassNm=''
                    if(mdl == 't11'){  
                        this.appenv.projectlist=result.data.data.rows    
                    } else if(mdl == 't12'){
                        this.appenv.applicationlist=result.data.data.rows
                    }
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getApplicationData(mdl) {
            this.isLoading = true
            let url = "api/listing/getappnamebyprojecteid";
            if(mdl == 't12') {
                this.input = {
                    projecteid: this.appenv.project,
                    //createdby: this.undt.username,
                    empcode: this.undt.userid,
                    useremail: this.undt.username
                };
            }
            axios({
                method: "POST",
                url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false
                if (result.data.errorCode == 0) {
                    this.appenv.applicationlist = result.data.data;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        projectChangesearch: function (node, instanceId) {
            if (node && node.length > 1) {
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    empid: parseInt(this.undt.userid),
                    projectname: node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.appenv.projectlist = result.data.data;
                    } else {
                        this.appenv.projectlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        selenvappl(state,value){
            this.appenv.application=null
            this.appenv.application=state
            if(!state){
                this.errors.appenv.application='Application required'
            }else{                
                this.errors.appenv.application=null
            }
        },
        selenvproj(state,value){
            this.appenv.project=null
            this.appenv.project=state
            if(!state){
                this.errors.appenv.project='Project name required'
                this.appenv.application = null
                this.appenv.applicationlist = null
            }else{                
                this.getApplicationData('t12')
            }
        },
    }
}
</script>