import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import { IdleSessionTimeout } from "idle-session-timeout";
import './axios'
require('@/assets/scss/app.css')
import {Service} from '@/services/Service'
import {BootstrapVue,TablePlugin,BootstrapVueIcons,IconsPlugin} from "bootstrap-vue"

Vue.config.productionTip = false
Vue.prototype.$Service = Service

Vue.use(BootstrapVue)
Vue.use(TablePlugin)
Vue.use(BootstrapVueIcons)
Vue.use(IconsPlugin)

new Vue({
  router:router,
  render: h => h(App),
}).$mount('#app')

export const EventBus = new Vue();

if(window.location.href.search('/login') == -1){
  // var uniqdata=JSON.parse(window.localStorage.getItem('userdata'))
  // if(uniqdata.emailaddress == 'hanumant.galgate@firstcry.com' || uniqdata.emailaddress == 'atul.deokar@firstcry.com'){
    // let session = new IdleSessionTimeout(5 * 60 * 1000);
    let session = new IdleSessionTimeout(60 * 60 * 8000, "click", "mousemove","keypress","DOMMouseScroll","mousewheel");
    session.onTimeOut = () => {
    // here you can call your server to log out the user
        session.reset();
        session.dispose();
        localStorage.removeItem('reloaded');
        localStorage.removeItem('userdata');
        localStorage.removeItem('token');
        localStorage.removeItem('check');
        localStorage.removeItem('pagefeatures');
        localStorage.removeItem('sidebar');
        localStorage.removeItem('functionalitymodule');
        localStorage.removeItem('sectionmodule');
        router.push('/login')
    };    
    //optional
    session.onTimeLeftChange = (timeLeft) => {
        // this will notify you  each second about the time left before the timeout
        // console.log(`${timeLeft} ms left`);
    };
    session.start();
  // }
}









