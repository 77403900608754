<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top" >
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Test Cases Group View</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'tlist', params: { search: true }}">Test Case</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                                    <router-link :to="{ name: 'TCBulkMapping', params: { search: true }}">Test Cases Group and Test Cases Bulk Mapping</router-link>
                                </li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" >Test Cases Bulk Group View</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card" >
                        <div class="card-header py-1" style="cursor:pointer">
                            <div class="container">
                            <fieldset class="fieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                <legend>
                                    <span class="leg_mobile">{{view.groupname}} - {{view.groupmappingcode}}</span>
                                </legend>
                                
                                 <section class="modern-horizontal-wizard mb-1">
                    <div class="bs-stepper wizard-modern modern-wizard-example">
                        <div class="bs-stepper-header">
                           <div class="step" data-target="#account-details-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-primary rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Created Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(view.createddate) ? dateMonthFormat(view.createddate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#personal-info-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-warning rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Review  Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(view.reviewddate) ? dateMonthFormat(view.reviewddate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#address-step-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-success rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Approved Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(view.approveddate) ? dateMonthFormat(view.approveddate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                            <div class="line">
                                <em class="las la-angle-double-right font-medium-2"></em>
                            </div>
                            <div class="step" data-target="#social-links-modern">
                                <button type="button" class="step-trigger">
                                    <div class="avatar bg-light-danger rounded">
                                        <div class="avatar-content">
                                            <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                        </div>
                                    </div>
                                    <span class="bs-stepper-label">
                                        <span class="bs-stepper-title">Last Modified Date</span>
                                        <span class="bs-stepper-subtitle">{{dateMonthFormat(view.lastmodifieddate) ? dateMonthFormat(view.lastmodifieddate):'NA'}}</span>
                                    </span>
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </section>
                            <div class="row my-2"> 
                                <div class="col-lg-4 col-md-6 col-12 ">
                                    <div class="card card-transaction mb-1">
                                        <div class="card-body px-1 py-1 group_map_tast_case_bg1">
                                            <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <UserIcon size="1.5x" class="custom-class  avatar-icon font-medium-3"></UserIcon>
                                                        </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Created By</h6>
                                                    <small  class="capitalisetext " style="font-weight:500">{{view.createdby}}</small>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                               
                                
                                
                                
                                <div class="col-lg-4 col-md-6 col-12 ">
                                    <div class="card card-transaction mb-1">
                                        <div class="card-body px-1 py-1 group_map_tast_case_bg5">
                                            <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-secondary rounded">
                                                    <div class="avatar-content">
                                                        <UserIcon size="1.5x" class="custom-class  avatar-icon font-medium-3"></UserIcon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Last Modified By</h6>
                                                    <small  class="capitalisetext " style="font-weight:500">{{view.lastmodifiedby}}</small>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12 ">
                                    <div class="card card-transaction mb-1">
                                        <div class="card-body px-1 py-1 group_map_tast_case_bg6">
                                            <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-file-invoice custom-class  font-medium-5"></em>
                                                        
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Status</h6>
                                                    <small  class="capitalisetext " style="font-weight:500">{{view.status}}</small>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container px-0" v-for="(viewdata, index) in groupmapviewDetails" v-bind:key="index">
                                <div class="col-md-12 px-0">
                                    <div class="tast_case_groupingview mb-1" style="background: transparent!important;"> 
                                            <div>
                                            <div>
                                                <div class="row" v-for="(d,i) in viewdata" v-bind:key="i">
                                                </div>
                                            </div>
                                                    <div class="row mx-0" v-for="(d,i) in viewdata" v-bind:key="i">
                                                        <div class="col-sm-12 col-md-12 update_groupview_testcase_03">
                                                            <span class="leg_group_testcase"> Script {{i+1}} : {{d.label}}</span>
                                                            
                                                            <div class="clearfix"></div>
                                                            <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div v-bind:class="[readmore]" class=" tast_case_groupingview_readmoreUpdates" :id="'cycle_readmore'+i">
                                                                        <span class="cycle_readmore_dis" v-if="d.scripttype =='Manual'">Steps : </span>
                                                                        <span class="cycle_readmore_dis" v-if="d.scripttype =='Automation'">Script : </span>
                                                                        <span class="fc_m16_btcode_text" v-html="formatData(d.scriptsteps)"></span>
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="d.scriptsteps.length >50">
                                                                        <a class="btn">
                                                                        <strong :id="'ids'+i" @click="idclick(i)">
                                                                            Read More
                                                                        </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>

                                                        

                                                    </div>
                                                </div>
                                </div>
                                </div>
                            </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>   

    </div>
</template>
<style scoped>
  .update_groupview_testcase_03{
    margin: 5px 0px;
    padding: 8px 10px;
    border-radius: 5px;
    border:1px solid #eadadb;
    background-color: #ffefef
  }
  .fc_m16_btcode_text p{
        margin-bottom:5px;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,CalendarIcon,UserIcon,EyeIcon,WatchIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';
export default {
    name:'TCBulkView',
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,CalendarIcon,
        PlusCircleIcon,PlusSquareIcon,UserIcon,EyeIcon,WatchIcon
    },
    mixins: [ commonMethods ],
    data(){
        return{
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            mappinglist:[],
            groupmapviewDetails:[],
            groupnamedetails:[],
            readmore:'outer',
            view:{
                groupname: null,
                testcases: null,
                status: null,
                createddate: null,
                createdby: null,
                lastmodifiedby: null,
                lastmodifieddate: null,
                reviewddate: null,
                approveddate: null
            }
        }
    },
    mounted(){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
        }
        this.getBulkmapdetailsById(this.$route.query.groupmappingid)
        this.getBulkMapViewdetails(this.$route.query.groupmappingid)
    },
    methods:{
        idclick(indexx){
            if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                $("#"+'ids'+indexx).html("Read More");
                $("#cycle_readmore"+indexx).removeClass("readmore");
                // this.readmore = 'outer'
            } else {
                $("#"+'ids'+indexx).html("Read Less");
                $("#cycle_readmore"+indexx).addClass("readmore");
                // this.readmore = 'outer readmore'
            }
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
        },
        getBulkmapdetailsById(id){
            this.isLoading = true;
            let url = "api/groupbulkmapping/getBulkmapdetailsById";
            this.input = {
                id,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                this.editRowData = result.data.data[0];
                this.openEditModule(this.editRowData,0)
                } else if (result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
                this.editRowData =[];
            }).catch(e => {
               this.displayError(e)
            })
        },
        openEditModule(currRowData){
          this.editRowData = currRowData
          this.view.groupname=currRowData.label
          this.view.groupmappingcode=currRowData.groupmappingcode
          
          if(currRowData.tcgroupname){
            var tcgroupnamelist = currRowData.tcgroupname.split(",")
            this.view.testcases = tcgroupnamelist.map(d => d)
          }
          this.view.status = currRowData.status
          this.view.createddate=currRowData.createddate,
          this.view.createdby = currRowData.createdby,
          this.view.lastmodifiedby=currRowData.lastmodifiedby,
          this.view.lastmodifieddate=currRowData.lastmodifieddate,
          this.view.reviewddate=currRowData.reviewddate,
          this.view.approveddate=currRowData.approveddate
        },
        getBulkMapViewdetails(id) {
            this.input = {
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                id,
            }
            axios({
                'method': 'POST',
                'url': 'api/groupbulkmapping/getBulkViewdetailsById',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    const groupByCategory = result.data.data.reduce((group, product) => {
                    const { groupnameid } = product;
                    group[groupnameid] = group[groupnameid] ?? [];
                    group[groupnameid].push(product);
                    return group;
                }, {});
                    this.groupmapviewDetails = groupByCategory;
                }
                else {
                    this.groupmapviewDetails = []
                }
            }).catch(e => {
               this.displayError(e)
            })
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        formatData(data) {
            return data
        },
    }
}
</script>