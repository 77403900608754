<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper mobile_view_contentss">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Business Task Master Details</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'Business Task List', params: { search: true, activebtctab: this.activetabflag }}">Business Task Master List</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">View Business Task Master</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: listActive},card_content,collapse]">
                        <div class="card-header py-1" style="cursor:pointer">
                            <div class="container-fluid btmView">
                            <div class="row">
                                <div class="ml-auto text-right px-1">
                                    <div v-if="this.issubtask == true && pagefeatures.askforpmo && (editRowData.projectisactive ==1 && editRowData.projectisapproved ==1)" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="redirectToPMOTask('pmocreate',editRowData)">
                                        <user-icon size="1.5x" class="custom-class  avatar-icon mr-50"></user-icon>
                                        <span> Ask For PMO Help</span>
                                    </div>
                                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" v-if="tasklistofbusiness.length || issuelistofbusiness.length || blockertaskslistofbusiness.length" @click="openTaskList()">
                                        <file-text-icon size="1.5x" class="custom-class avatar-icon mr-50"></file-text-icon>
                                        <span>Task/Issue list</span>
                                    </div>
                                    <div v-if="pagefeatures.editbusinesstask || loginusername== editRowData.createdby || loginpersonname==editRowData.projectmaanager || 
                                    loginpersonname==editRowData.qalead || 
                                    loginpersonname==editRowData.qamanager || 
                                    loginpersonname==editRowData.productstackholder || loginpersonname==editRowData.taskowner || loginpersonname==editRowData.engineeringmaanager ||
                                    loginpersonname==editRowData.programmanager" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50"  @click="openEditModule(backpagedata,0)">
                                        <edit-2-icon size="1.5x" class="custom-class avatar-icon mr-50"></edit-2-icon>
                                        <span>Edit</span>
                                    </div>
                                    <div v-if="this.workstepOfBusiness.length > 0 && this.taskmodule==0 && (this.tasktype.tstatus !='Live' &&  this.tasktype.tstatus !='Completed' && this.tasktype.tstatus !='OnHold' && this.tasktype.tstatus !='Discarded') && isshow == 1 && (editRowData.projectisactive ==1 && editRowData.projectisapproved ==1)" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="redirectToWsCreate('createWS', workstepOfBusiness[workstepOfBusiness.length - 1])">
                                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                        <span>Workstep</span>
                                    </div>
                                    <div v-if="(this.tasktype.tstatus !='Live' &&  this.tasktype.tstatus !='Completed' && this.tasktype.tstatus !='OnHold' && this.tasktype.tstatus !='Discarded') && isshow == 1 && pagefeatures.createtask && (editRowData.projectisactive ==1 && editRowData.projectisapproved ==1)" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="redirectToTLogCreate('createTlog', editRowData)">
                                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                        <span>Task</span>
                                    </div>
                                    <div v-if="(this.tasktype.tstatus =='QA Pipeline' || this.tasktype.tstatus == 'UAT' || this.tasktype.tstatus =='UAT Feedback' || this.tasktype.tstatus =='QA WIP' || this.tasktype.tstatus =='Live')" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="redirectToIssueCreate('createBTMIssue', editRowData) && (editRowData.projectisactive ==1 && editRowData.projectisapproved ==1)">
                                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                        <span>Issue</span>
                                    </div>
                                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50"  @click="openLogModule()">
                                        <watch-icon size="1.5x" class="custom-class avatar-icon mr-50"></watch-icon>
                                        <span>Log Time</span>
                                    </div>
                                    <div v-if="this.isoldbtc == true && pagefeatures.edituploaddocs && (moduledocs.length>0 ? this.tasktype.documentlist.filter(item => item.projectmoduleid != null).length : this.tasktype.documentlist.length)  && this.isPresentInProductRoles() && (this.tasktype.tstatus=='Product Pipeline' || this.tasktype.tstatus =='Product WIP' || this.tasktype.tstatus =='Product Completed' || this.tasktype.tstatus =='Product WIP 2')" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="openEditModule(backpagedata,3)">
                                        <upload-cloud-icon size="1.5x" class="custom-class light-primary avatar-icon mr-50"></upload-cloud-icon>
                                        <span>Edit Upload Docs</span>
                                    </div>
                                    <div v-if="this.isoldbtc == true && pagefeatures.uploaddocs && !(moduledocs.length>0 ? this.tasktype.documentlist.filter(item => item.projectmoduleid != null).length : this.tasktype.documentlist.length) && this.isPresentInProductRoles() && (this.tasktype.tstatus=='Product Pipeline' || this.tasktype.tstatus =='Product WIP' || this.tasktype.tstatus =='Product Completed' || this.tasktype.tstatus =='Product WIP 2')" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="openEditModule(backpagedata,2)">
                                        <upload-cloud-icon size="1.5x" class="custom-class light-primary avatar-icon mr-50" ></upload-cloud-icon>
                                        <span>Upload Docs</span>
                                    </div>
                                    <div v-if="(this.issubtask == true || this.isshow == 1) && this.isoldbtc == false && (this.tasktype.documentlist.length > 0) && pagefeatures.edituploaddocs && this.isPresentInProductRoles() && (this.tasktype.tstatus=='Product Pipeline' || this.tasktype.tstatus =='Product WIP' || this.tasktype.tstatus =='Product Completed' || this.tasktype.tstatus =='Product WIP 2')" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="openEditModule(backpagedata,3)">
                                        <upload-cloud-icon size="1.5x" class="custom-class light-primary avatar-icon mr-50"></upload-cloud-icon>
                                        <span>Edit Upload Docs</span>
                                    </div>
                                    <div v-if="(this.issubtask == true || this.isshow == 1) && this.isoldbtc == false && (this.tasktype.documentlist.length < 1) && pagefeatures.uploaddocs && this.isPresentInProductRoles() && (this.tasktype.tstatus=='Product Pipeline' || this.tasktype.tstatus =='Product WIP' || this.tasktype.tstatus =='Product Completed' || this.tasktype.tstatus =='Product WIP 2')" class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="openEditModule(backpagedata,2)">
                                        <upload-cloud-icon size="1.5x" class="custom-class light-primary avatar-icon mr-50" ></upload-cloud-icon>
                                        <span>Upload Docs</span>
                                    </div>
                                    <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="redirectToTcycleCreate('createTcycle',editRowData)" v-if="tasktype.tstatus !='Discarded' && tasktype.tstatus !='OnHold' && tasktype.tstatus !='Product WIP' && tasktype.tstatus !='Product Pipeline' && pagefeatures.createtestcycle && (editRowData.projectisactive ==1 && editRowData.projectisapproved ==1)">
                                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                        <span>Create Test Cycle Set</span>
                                    </div>
                                    <div v-if="(isshow != null) && pagefeatures.taskblocker"  class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50" @click="openBlockerTaskModule()">
                                        <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                        <span>Task Blocker</span>
                                    </div>                                        
                                    <div>
                                    </div>
                                   
                                </div>
                            </div>
                            </div>
                        </div>
                       <div class="row match-height">
                           <div class="col-12">
                            <div class=" card-transaction">
                                <div class="card-body" style="padding-top:0px;">
                                    <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded d-inline-flex">
                                                <div class="avatar-content">
                                                    <eye-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></eye-icon>
                                                </div>
                                            </div>
                                            <div v-if="issubtask && parentTaskData.length>0" class="media-body">
                                                <h3 style="margin:15px;" class="card-title ml-1 d-inline-flex  text-primary business-task-codess-01">BUSINESS TASK CODE: <span @click="redirecttourl('view', parentTaskData[0])"> {{parentTaskData[0].businesstaskcode}} >> </span> {{this.tasktype.businesstaskcode}}</h3>
                                           </div>
                                            <div v-else class="media-body">
                                                <h3 style="margin:14px;" class="card-title ml-1 d-inline-flex text-primary business-task-codess-01">BUSINESS TASK CODE: {{this.tasktype.businesstaskcode}}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hr_border-bottom" />
                                <section class="mainbtc_newDesign p-1">
                                     <div class="container px-0">
                                        <div class="card card-transaction">
                                            <div class="transaction-item">
                                                <div class="media">
                                                    <div class="avatar bg-light-info rounded">
                                                        <div class="avatar-content">
                                                            <em class="las la-file-alt font-medium-4"></em>
                                                        </div>
                                                    </div>
                                                    <div class="media-body">
                                                        <h6 class="transaction-title">{{this.tasktype.name}}</h6>
                                                        <small>Created by {{tasktype.createdby}}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                      </div>
                                      <div class="container px-0">
                                          <div class="row match-height customAnimation">
                                              <div class="col-sm-12 col-md-9 mobile_btcview_change_order-2">
                                                <div class="row match-height">
                                                    <div class="col-12 col-sm-6 col-lg-3">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_01">
                                                            <div class=" co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                       <em class="las la-star-of-david font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <div class="btcbottom_new_details">
                                                                    <h5 class="font-weight-bolder mt-1 text-white" v-if="this.tasktype.type=='Business Task'">Business Task - New Feature</h5>
                                                                    <h5 class="font-weight-bolder mt-1 text-white" v-else-if="this.tasktype.type=='Enhancement'">Business Task - Enhancement</h5>
                                                                    <h5 class="font-weight-bolder mt-1 text-white" v-else>{{this.tasktype.type}}</h5>
                                                                    <p class="card-text text-white mb-1">Business Task Type</p>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-lg-3">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_02">
                                                            <div class=" co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                       <em class="las la-key font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <div class="btcbottom_new_details">
                                                                <h5 class="font-weight-bolder mt-1 text-white">{{this.tasktype.key}}</h5>
                                                                <p class="card-text text-white mb-1">Business Task Severity</p>
                                                               </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-lg-3">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_03">
                                                            <div class=" co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                       <em class="las la-business-time font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <!-- / On Track / at risk -->
                                                                <div class="btcbottom_new_details"> 
                                                                <h5 class="font-weight-bolder mt-1 text-white">{{this.btcdelayedstatus}}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-lg-3 ">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_04">
                                                            <div class=" co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                       <em class="las la-calendar font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <div class="btcbottom_new_details">
                                                                <h5 v-if="this.view.taskestimates" class="font-weight-bolder mt-1 text-white">{{this.view.taskestimates}}</h5>
                                                                <h5 v-else class="font-weight-bolder mt-1 text-white">NA</h5>
                                                                <p class="card-text text-white mb-1">Ballpark Estimate in days</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-lg-3 ">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_05">
                                                            <div class=" co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-calendar font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <div class="btcbottom_new_details">
                                                                <h5 v-if="this.tasktype.startdate" class="font-weight-bolder mt-1 text-white">{{dateMonthFormat1(this.tasktype.startdate)}}</h5>
                                                                <h5 v-else class="font-weight-bolder mt-1 text-white">NA</h5>
                                                                <p class="card-text text-white mb-1">Start Date</p>
                                                                <p v-if="this.tasktype.startdateaeging != null" class="card-text text-white mb-1">Ageing - {{this.tasktype.startdateaeging}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-lg-3 ">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_06">
                                                            <div class=" co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                       <em class="las la-clock font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <div class="btcbottom_new_details">
                                                                <h5 class="font-weight-bolder mt-1 text-white">{{this.tasktype.estimatedhours?this.tasktype.estimatedhours:0}} Hrs</h5>
                                                                <p class="card-text text-white mb-1">Total Estimated Hrs</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-lg-3 ">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_07">
                                                            <div class="co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                        <em class="las la-clock font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <div class="btcbottom_new_details d-flex">
                                                                <div class="btcbottom_extras"> 
                                                                    <h5 class="font-weight-bolder mt-1 text-white">{{this.tasktype.totaltime?this.tasktype.totaltime:0}} Hrs</h5>
                                                                <p class="card-text text-white mb-1">Spent Hours</p>
                                                                </div>
                                                                <div class="btcbottom_extras ml-1">
                                                                <h5 class="font-weight-bolder mt-1 text-white">{{ this.extratime  }}</h5>
                                                                <p class="card-text text-white mb-1">Extra Time</p>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-lg-3 ">
                                                        <div class="card co_new_btc_viewDetails newbtcbg_08">
                                                            <div class=" co_new_btc_viewDetailscommonview card-body p-0 d-flex flex-column">
                                                                <div class="avatar bg-light-primary p-50 m-0">
                                                                    <div class="avatar-content">
                                                                       <em class="las la-calendar-check font-medium-4"></em>
                                                                    </div>
                                                                </div>
                                                                <div v-if="this.tasktype.enddate" class="btcbottom_new_details">
                                                                    <h5 class="font-weight-bolder mt-1 text-white">{{dateMonthFormat1(this.tasktype.enddate)}}</h5>
                                                                    <p class="card-text text-white mb-1">Estimated End Date</p>
                                                                    <h5 v-if="this.tasktype.edddeliverable!=null" class="font-weight-bolder mt-1 text-white">{{this.tasktype.edddeliverable}} day to go</h5>
                                                                    <h5 v-else class="font-weight-bolder mt-1 text-white">{{this.tasktype.edddelayed}} - Delayed</h5>
                                                                </div>
                                                                <div v-else class="btcbottom_new_details">
                                                                    <h5 class="font-weight-bolder mt-1 text-white">NA</h5>
                                                                    <p class="card-text text-white mb-1">Estimated End Date</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                   
                                                </div>
                                              </div>
                                              <div class="col-sm-12 col-md-3 mobile_btcview_change_order-1">
                                                <div class="card  card-congratulations_wip">
                                                    <div class="card-body text-center" style="display: contents;">
                                                    
                                                        <div class="avatar avatar-xl bg-primary shadow mb-3">
                                                            <div class="avatar-content">
                                                                <img alt="" src="../../../public/images/icons/proview.png" class="round"/>
                                                            </div>
                                                        </div>
                                                    <div class="text-center">
                                                        <h3 class="mb-1 text-white">{{this.tasktype.tstatus}}</h3>
                                                        <p class="card-text text-white">
                                                            Current Business Task Status
                                                        </p>
                                                        <p v-if="techpipelinedateaeging!=null" class="card-text text-white">
                                                            Total Ageing  - {{techpipelinedateaeging}} days
                                                        </p>
                                                    </div>
                                               </div>
                                        </div>
                                              </div>
                                            </div>
                                         </div>
                                         <div class="container py-2 px-0 columnbg_viewbgchart">
                                              <div class="col-sm-12 px-0"> 
                                                <div class="card-body p-0">
                                                    <div id="columns_chart"></div>
                                                </div>
                                              </div>
                                         </div>
                                </section>
                               <div class=" card-transaction">
                                    <div style="flex: 1 1 auto; min-height: 1px;margin-top: 10px; margin-left: 20px">
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-clipboard-list font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title animation_view_btcview">
                                                    <span class="animate-charcter glow"> {{this.tasktype.name}} </span>
                                                    </h6>
                                                <small>Created by {{this.tasktype.createdby}}</small>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                               </div>
                           </div>

                            <div class="col-lg-4 col-md-6 col-12 ">
                                <div class="card card-transaction">
                                    <div class="card-body">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                       
                                                        <em class="las la-inbox font-medium-4"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Redmine/Reference ID</h6>
                                                    <small>{{this.tasktype.redmineid ? this.tasktype.redmineid:'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-danger rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-tasks font-medium-5"></em>              
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title" >Business Task Priority</h6>
                                                    <small class="capitalisetext text-black" style="font-weight:500">{{this.tasktype.btmpriority ? this.tasktype.btmpriority: "NA"}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-tasks font-medium-5"></em>              
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title" >Business Task Sub Priority</h6>
                                                    <small class="capitalisetext text-black" style="font-weight:500">{{this.tasktype.btmsubpriority ? this.tasktype.btmsubpriority: "NA"}}</small>
                                                </div>
                                            </div>
                                        </div>
                                         <div class="transaction-item">
                                            <div class="media">
                                                <div v-if="this.tasktype.key=='Key' || this.tasktype.key=='Urgent'" class="avatar bg-light-danger rounded">
                                                <div class="avatar-content">
                                                  <em class="las la-clipboard-check font-medium-5"></em>
                                                </div>
                                                </div>
                                                <div v-if="this.tasktype.key=='Normal'" class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-clipboard-check font-medium-5"></em>
                                                </div>
                                                </div>
                                                <div class="media-body">
                                                <h6 class="transaction-title">Business Task Severity</h6>
                                                <small v-if="this.tasktype.key=='Key' || this.tasktype.key=='Urgent'" class="text-danger" style="font-weight:500">{{this.tasktype.key}}</small>
                                                <small v-if="this.tasktype.key=='Normal'" class="text-primary" style="font-weight:500">{{this.tasktype.key}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                        <settings-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></settings-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Business Task Status</h6>
                                                    <small style="font-weight:500">{{this.tasktype.tstatus}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        
                                                    <em class="las la-sync-alt font-medium-5"></em>
                                                        
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Business Task Type	</h6>
                                                    <small v-if="this.tasktype.type=='Business Task'">Business Task - New Feature</small>
                                                    <small v-else-if="this.tasktype.type=='Enhancement'">Business Task - Enhancement</small>
                                                    <small v-else>{{this.tasktype.type}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                        
                                                        <em class="las la-users font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Business Task Level</h6>
                                                    <small>{{this.tasktype.tasklevelname}}</small>
                                                </div>
                                            </div>
                                        </div>   
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-boxes font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Department</h6>
                                                    <small>{{this.tasktype.dept}}</small>
                                                </div>
                                            </div>
                                        </div>  
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        
                                                       <em class="las la-id-card font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Business Project Name</h6>
                                                    <small>{{this.tasktype.businessname ? this.tasktype.businessname:'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>  
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <settings-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></settings-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">References</h6>
                                                    <small>{{this.tasktype.references}}</small>
                                                </div>
                                            </div>
                                        </div> 
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-map-marker font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Billing Country</h6>
                                                    <small class="capitalisetext">{{this.tasktype.billingcountry?this.tasktype.billingcountry:'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-clipboard-check font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Business Context</h6>
                                                    <small class="capitalisetext">{{this.tasktype.businesscontext?this.tasktype.businesscontext:'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                               
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 ">
                                <div class="card card-transaction">
                                    <div class="card-body">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="i_icon_st la-sitemap las lab font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Project Name</h6>
                                                    <div v-if=this.tasktype.projectnamestr>
                                                        <span v-if="this.isshow == 1" class="font-weight-normal capitalisetext">
                                                        <small  style="cursor: pointer;" href="javascript:void(0);" @click="redirecttoprojectsummary('projectsummary', [tasktype.projectist])" class="sitebluecolor">{{ this.tasktype.projectnamestr }}</small>
                                                        </span>
                                                        <span v-else class="font-weight-normal capitalisetext">
                                                        <ol><li v-for="(pro) in this.tasktype.projectnamestr.split(',')"><small>{{ pro }}</small></li></ol>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="this.tasktype.projectmodulename" class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <pie-chart-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></pie-chart-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Project Module Name</h6>
                                                    <small>{{this.tasktype.projectmodulename}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-address-card font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">HOD Name</h6>
                                                    <small>{{this.tasktype.bsh}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item" v-if="this.tasktype.requester != null">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-address-card font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Requester</h6>
                                                    <small>{{this.tasktype.requestername}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item" v-if="this.tasktype.requester_hod != null">
                                            <div class="media">
                                                <div class="avatar bg-light-info rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-address-card font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Requester HOD</h6>
                                                    <small>{{this.tasktype.requester_hodname}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-chart-line font-medium-3"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title ">Task Manager</h6>
                                                    <small class="capitalisetext">{{this.tasktype.taskmanager ? this.tasktype.taskmanager: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-address-card font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Task Owner (Lead)</h6>
                                                    <small class="capitalisetext">{{this.tasktype.taskowner ? this.tasktype.taskowner: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <em class="i_icon_st la-codepen lab font-medium-5" style="font-weight: 500;"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Project Manager</h6>
                                                    <small class="capitalisetext">{{this.tasktype.proman ? this.tasktype.proman: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-user-cog font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Engineering Manager</h6>
                                                    <small class="capitalisetext">{{this.tasktype.engman ? this.tasktype.engman: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-user-tie font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Program Manager</h6>
                                                    <small class="capitalisetext">{{this.tasktype.progman ? this.tasktype.progman: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-user-tie font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">QA Manager</h6>
                                                    <small class="capitalisetext">{{this.tasktype.qamanager ? this.tasktype.qamanager: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-users font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">QA Lead</h6>
                                                    <small class="capitalisetext">{{this.tasktype.qalead ? this.tasktype.qalead: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-primary rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-lock font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Security Sensitive</h6>
                                                    <small v-if="this.tasktype.security == 1" class="capitalisetext">Yes</small>
                                                    <small v-else-if="this.tasktype.security == 0" class="capitalisetext">No</small>
                                                    <small v-else class="capitalisetext">NA</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                                          
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 ">
                                <div class="card card-transaction">
                                    <div class="card-body">
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-edit font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Created By</h6>
                                                    <small>{{this.tasktype.createdby}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <calendar-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></calendar-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Created Date</h6>
                                                    <small>{{dateMonthFormat(this.tasktype.createddate)}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-info rounded">
                                            <div class="avatar-content">
                                                <user-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></user-icon>
                                            </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title">Last Modified By</h6>
                                            <small>{{this.tasktype.lastmodifiedby}}</small>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="transaction-item">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                            <div class="avatar-content">
                                                <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                            </div>
                                            </div>
                                            <div class="media-body">
                                            <h6 class="transaction-title">Last Modified Date</h6>
                                            <small>{{dateMonthFormat(this.tasktype.lastmodifieddate)}}</small>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <calendar-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></calendar-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Start Date</h6>
                                                    <small>{{dateMonthFormat(this.tasktype.startdate) ? this.tasktype.startdate: 'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <calendar-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></calendar-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Estimated End Date</h6>
                                                    <small>{{dateMonthFormat(this.tasktype.enddate) ? this.tasktype.enddate: 'NA'}}</small>
                                                    <br/>
                                                    <small class="capitalisetext text-danger" v-if="this.view.isapproved_edd && this.view.isapproved_edd == 'Pending'"> This Estimated Date is Pending for the approval - <strong> {{dateMonthFormat(this.view.updated_edd) ? dateFormatnew(this.view.updated_edd): 'NA'}} </strong>.</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Estimated Hrs</h6>
                                                    <small>{{this.tasktype.estimatedhours?this.tasktype.estimatedhours:0}} Hrs</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Spent Time</h6>
                                                    <small>{{this.tasktype.totaltime?this.tasktype.totaltime:0}} Hrs</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Ballpark Estimate in days</h6>
                                                    <small class="capitalisetext">{{this.view.taskestimates?this.view.taskestimates:'NA'}}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transaction-item">
                                            <div class="media">
                                                <div class="avatar bg-light-warning rounded">
                                                    <div class="avatar-content">
                                                        <watch-icon size="1.5x" class="custom-class  avatar-icon font-medium-3"></watch-icon>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                <h6 class="transaction-title">Planner Name</h6>
                                                <span v-if="view.plannertitle != null && view.plannertitle != ''" class="font-weight-normal capitalisetext">
                                                    <ol>
                                                        <li v-for="(plannerdata, index) in view.plannertitle.split(',')">
                                                            <small class="text" style="cursor:pointer; color:Blue;" @click="redirecttoplan('view', {moduleid: view.plannerid != null ? view.plannerid.split(',')[index] : null })">
                                                                {{ plannerdata }}
                                                            </small>
                                                        </li>
                                                    </ol>
                                                </span>
                                                <span v-else class="font-weight-normal capitalisetext">
                                                    <small>NA</small>
                                                </span>
                                            </div>

                                            </div>
                                        </div>
                                        
                                    </div>                                                          
                                </div>
                            </div>
                           
                            <BTCProgressBar v-if="pagefeatures.btprogress && parentflagg != null" :flagg="parentflagg" :bid="bid" :tstatus="this.tasktype.tstatus" />

                        <div class="col-md-12">
                            <fieldset class="fieldset_border_vi mb-2 mx-2 business_fieldset_style_bg02 ">
                                <h4 class="py-1">Business Task Details</h4>
                                <legend style="display:none"> </legend>
                            <div class="row">
                            <div class="col-xs-12 col-md-12">
                                <div v-if="this.view.securitytag" class="card-transaction business_dis_rows mb-1 business_dis_rows_disc border">
                                    <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                        <div class="transaction-item" style="overflow:auto">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-file-alt custom-class  font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Security Justification</h6>
                                                    <div class="text-left tast_case_groupingview_readmore mx-0" style="width: 100% !important;">
                                                        <div class="col-md-12 px-0  micro_case_groupingview_readmore mb-1">
                                                            <div class="custom-container case_bg_17">
                                                                <div v-if="this.view.securitycomment" v-bind:class="[ssreadmore]" :id="'ssjudtid'">
                                                                    <span class="fc_m16_btcode_text" v-html="formatData(this.view.securitycomment)"></span>
                                                                    
                                                                </div>
                                                                
                                                                <div class="cycle_viewmoretext" v-if="this.view.securitycomment && this.view.securitycomment.length > 50">
                                                                    <a class="btn">
                                                                    <strong :id="'ssjudtidid5'" @click="securityJustReadmore()">
                                                                        Read More
                                                                    </strong>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-transaction business_dis_rows mb-1 business_dis_rows_disc border">
                                    <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                        <div class="transaction-item" style="overflow:auto">
                                            <div class="media">
                                                <div class="avatar bg-light-success rounded">
                                                    <div class="avatar-content">
                                                        <em class="las la-file-alt custom-class  font-medium-5"></em>
                                                    </div>
                                                </div>
                                                <div class="media-body">
                                                    <h6 class="transaction-title">Business Task Description</h6>
                                                    <div class="text-left tast_case_groupingview_readmore mx-0" style="width: 100% !important;">
                                                        <div class="col-md-12 px-0  micro_case_groupingview_readmore mb-1">
                                                            <div class="custom-container case_bg_17">
                                                                <div v-if="this.tasktype.desc" v-bind:class="[readmore]" :id="'cycle_readmore5'">
                                                                    <span class="fc_m16_btcode_text" v-html="formatData(this.tasktype.desc)"></span>
                                                                    
                                                                </div>
                                                                
                                                                <div class="cycle_viewmoretext" v-if="this.tasktype.desc && this.tasktype.desc.length > 50">
                                                                    <a class="btn">
                                                                    <strong :id="'id5'" @click="idclick5()">
                                                                        Read More
                                                                    </strong>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="col-xs-12 col-md-4">
                            <div class="card-transaction business_dis_rows business_dis_rowsbg bg_bt2  mb-1 ">
                                <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                    <div class="transaction-item" style="overflow:auto">
                                        <div class="media">
                                            <div class="avatar bg-light-primary rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-thumbs-up custom-class  font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Success Criteria</h6>
                                                <p> <span v-html="formatData(this.tasktype.project_success_criteria ? this.tasktype.project_success_criteria: 'NA')"></span> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 mb-xs-1">
                            <div class="card-transaction business_dis_rows mb-1 business_dis_rowsbg bg_bt3 ">
                                <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                    <div class="transaction-item" style="overflow:auto">
                                        <div class="media">
                                            <div class="avatar bg-light-success rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-file-invoice custom-class  font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Failure Criteria</h6>
                                                <p><span v-html="formatData(this.tasktype.project_fail_criteria ? this.tasktype.project_fail_criteria: 'NA')"></span> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 mb-xs-1">
                            <div class="card-transaction business_dis_rows mb-1 business_dis_rowsbg bg_bt4 ">
                                <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                    <div class="transaction-item" style="overflow:auto">
                                        <div class="media">
                                            <div class="avatar bg-light-warning rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-notes-medical custom-class  font-medium-5"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Assumptions</h6>
                                                <p> <span v-html="formatData(this.tasktype.project_assumptions ? this.tasktype.project_assumptions: 'NA')"></span> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 mb-xs-1">
                            <div class="card-transaction business_dis_rows mb-1 business_dis_rowsbg bg_bt5 ">
                                <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                    <div class="transaction-item" style="overflow:auto">
                                        <div class="media">
                                            <div class="avatar bg-light-info rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-dollar-sign custom-class font-medium-3"></em>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business ROI</h6>
                                                <p><span v-html="formatData(this.tasktype.project_roi ? this.tasktype.project_roi: 'NA')"></span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 mb-xs-1">
                            <div class="card-transaction business_dis_rows mb-1 business_dis_rowsbg bg_bt6 ">
                                <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                    <div class="transaction-item" style="overflow:auto">
                                        <div class="media">
                                            <div class="avatar bg-light-secondary rounded">
                                                <div class="avatar-content">
                                                    <em class="lab la-codepen custom-class  font-medium-5 text-white"></em>
                                                    
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Business Impact</h6>
                                                <p> 
                                                    <span v-html="formatData(this.tasktype.business_impact ? this.tasktype.business_impact: 'NA')"></span>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-4 mb-xs-1">
                            <div class="card-transaction business_dis_rows business_dis_rowsbg bg_bt2 ">
                                <div style="flex: 1 1 auto; min-height: 1px; margin-left: 20px">
                                    <div class="transaction-item" style="overflow:auto">
                                        <div class="media">
                                            <div class="avatar bg-light-danger rounded">
                                                <div class="avatar-content">
                                                    <em class="las la-life-ring custom-class font-medium-5"></em>
                                                    
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="transaction-title">Impact on other live features if any</h6>
                                                <p> <span v-html="formatData(this.tasktype.impact_of_live_feature ? this.tasktype.impact_of_live_feature:'NA')"></span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            </fieldset>
                        </div>
                            
                        </div>
                    </div>
                </div>

                <div v-if="(attachmentviewParentBtc.length>0 || attachmentview.length>0)">
                    <div class="card">
                        <div class="card-header px-2 py-1 border-bottom">
                            <h4 class="card-title">Document List</h4>
                        </div>
                        <div class="card-body pb-0">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row documentGrid match-height">
                                        <div v-if="attachmentviewParentBtc.length>0" class="col-xl- col-md-3 col-sm-6 my-1" v-for="(data11,index11) in attachmentviewParentBtc" v-bind:key="index11">
                                            <div class="card document_list_box-shadow21 mb-50">
                                                <div class="text-center py-2" style="background:#cdf5f6;">
                                                <img alt="" v-if="imgExt.includes(data11.link.substring(data11.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                                <img alt="" v-else-if="data11.link.substring(data11.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                                <img alt="" v-else-if="data11.link.substring(data11.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                                <img alt="" v-else-if="data11.link.substring(data11.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                                <img alt="" v-else-if="data11.link.substring(data11.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                                <img alt="" v-else-if="data11.link.substring(data11.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                                <a :href="data11.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                <a v-if="imgExt.includes(data11.link.substring(data11.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="inZoom(data11.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                                <a v-if="data11.link.substring(data11.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(data11.presignurl)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                                </div>
                                                <div class="py-50 px-50 border-top" style="font-size:0.85rem; color:#888;">
                                                <span class="groth01_development_business" v-if="data11.info">
                                                    {{data11.info}}
                                                </span>
                                                <span class="groth01_development_business" v-else-if="!data11.info">
                                                    {{data11.link | basename}}
                                                </span>
                                                <div class="media-body" >
                                                    <ul class="timeline-item px-0" style="list-style: none;">
                                                        <div class="timeline-event business_doc_list-1">
                                                            <div class="media align-items-center">
                                                                <span class="text-muted">
                                                                    -Uploaded On :</span>
                                                                <div class="media-body ml-50" v-if="issubtask == false">
                                                                    <p class="mb-0 text-nowrap">{{dateMonthFormat(data11.uploaddate)}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="media align-items-center">
                                                                <span class="text-muted">
                                                                    -Uploaded By :</span>
                                                                <div class="media-body ml-50" v-if="issubtask == false">
                                                                    <p class="mb-0 text-nowrap capitalisetext">{{(data11.uploadedby).split('@')[0]}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <div v-if="attachmentview.length>0" class="col-xl- col-md-3 col-sm-6 my-1" v-for="(data,index) in attachmentview" :key="'i'+index">
                                            <div class="card document_list_box-shadow21 mb-50">
                                                <div class="text-center py-2" style="background:#cdf5f6;">
                                            <img alt="" v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                            <img alt="" v-else-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                            <a :href="data.presignurl" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                            <a v-if="imgExt.includes(data.link.substring(data.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="inZoom(data.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                            <a v-if="data.link.substring(data.link.lastIndexOf('.')+1) == 'pdf'" target="_blank" v-on:click="previewpdf(data.presignurl)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop" title="Click to Preview"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                            </div>
                                            <div class="py-50 px-50 border-top" style="font-size:0.85rem; color:#888;">
                                            <span class="groth01_development_business" v-if="data.info">
                                                {{data.info}}
                                            </span>
                                            <span class="groth01_development_business" v-else-if="!data.info">
                                                {{data.link | basename}}
                                            </span>
                                            <div class="media-body" >
                                                <ul class="timeline-item px-0" style="list-style: none;">
                                                    <div class="timeline-event business_doc_list-1">
                                                        <div class="media align-items-center">
                                                            <span class="text-muted">
                                                                -Uploaded On :</span>
                                                            <div class="media-body ml-50">
                                                                <p class="mb-0 text-nowrap">{{dateMonthFormat(docdetails[index].uploaddate)}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="media align-items-center">
                                                            <span class="text-muted">
                                                                -Uploaded By :</span>
                                                            <div class="media-body ml-50">
                                                                <p class="mb-0 text-nowrap capitalisetext">{{(docdetails[index].createdby).split('@')[0]}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">              
                    <div class="modal-body p-0">
                        <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                        <img alt="" src="" class="imagepreview"  >
                    </div>
                    </div>
                </div>
                </div>

                <section id="card-navigation" class="taskList" v-if="subtasklist.length || tasklistofbusiness.length || issuelistofbusiness.length || blockertaskslistofbusiness.length || testcyclesetlist.length || tasksearch == true || issuesearch == true || this.blockersearch == true || searchcycleset ==true ">
                    <div class="row match-height">
                        <div class="col-md-12">
                            <div class="card mb-3 ">
                                <div class="container text-editar_discription_jobs masters_detailstabing_pills px-2">
                                <ul class="nav nav-pills nav-pill-warning mb-0 mt-1 " id="nav-tabs" role="tablist">
                                    <li class="nav-item" v-if="subtasklist.length">
                                        <a class="nav-link" v-bind:class="{'active': subtasklist.length }" id="suntask-tab" data-toggle="tab" href="#subtasklist" role="tab" aria-controls="subtasklist" aria-selected="false">Sub BTC List - {{ s_totalcount }}</a>
                                    </li>
                                    <li class="nav-item" v-if="subtasksearch == true && subtasklist.length==0">
                                        <a class="nav-link" v-bind:class="{'active': subtasklist.length==0}" id="suntask-tab" data-toggle="tab" href="#subtasklist" role="tab" aria-controls="subtasklist" aria-selected="false">Sub BTC List - {{ s_totalcount }}</a>
                                    </li>
                                    <li class="nav-item" v-if="tasklistofbusiness.length">
                                        <a class="nav-link" v-bind:class="{'active': tasklistofbusiness.length && !subtasklist.length && !issuelistofbusiness.length }" id="tasklist-tab" data-toggle="tab" href="#tasklist" role="tab" aria-controls="tasklist" aria-selected="true">Micro Task List - {{ t_totalcount }}</a>
                                    </li>
                                    <li class="nav-item" v-if="tasklistofbusiness.length==0 && tasksearch == true">
                                        <a class="nav-link" v-bind:class="{'active': tasklistofbusiness.length==0 }" id="tasklist-tab" data-toggle="tab" href="#tasklist" role="tab" aria-controls="tasklist" aria-selected="true">Micro Task List - {{ t_totalcount }}</a>
                                    </li>        

                                    <li class="nav-item" v-if="blockertaskslistofbusiness.length">
                                        <a class="nav-link" v-bind:class="{'active': blockertaskslistofbusiness.length && !tasklistofbusiness.length && !issuelistofbusiness.length && !subtasklist.length && blockersearch == true}" id="blockerlist-tab" data-toggle="tab" href="#blockerlist" role="tab" aria-controls="blockerlist" aria-selected="false">Blocker Tasks List - {{ b_totalcount }}</a>
                                    </li>
                                    <li class="nav-item" v-if="blockertaskslistofbusiness.length==0 && blockersearch == true">
                                        <a class="nav-link" v-bind:class="{'active': blockertaskslistofbusiness.length == 0 }" id="blockerlist-tab" data-toggle="tab" href="#blockerlist" role="tab" aria-controls="blockerlist" aria-selected="false">Blocker Tasks List - {{ b_totalcount }}</a>
                                    </li>
                                    
                                    <li class="nav-item" v-if="testcyclesetlist.length>0">
                                        <a class="nav-link" v-bind:class="{'active': testcyclesetlist.length && !subtasklist.length && !blockertaskslistofbusiness.length && !issuelistofbusiness.length && !tasklistofbusiness.length}" id="testcyclesetlist-tab" data-toggle="tab" href="#testcyclesetlist" role="tab" aria-controls="testcyclesetlist" aria-selected="true">Testcycle Set List - {{ tcs_totalcount }}</a>
                                    </li>
                                    <li class="nav-item" v-if="testcyclesetlist.length==0 && testcyclesetsearch == true">
                                        <a class="nav-link" v-bind:class="{'active': testcyclesetlist.length==0 }" id="testcyclesetlist-tab" data-toggle="tab" href="#testcyclesetlist" role="tab" aria-controls="testcyclesetlist" aria-selected="true">Testcycle Set List - {{ tcs_totalcount }}</a>
                                    </li>

                                    <li class="nav-item" v-if="issuelistofbusiness.length">
                                        <a class="nav-link" v-bind:class="{'active': issuelistofbusiness.length && !tasklistofbusiness.length && !blockertaskslistofbusiness.length && !subtasklist.length && issuesearch == true}" id="issue-tab" data-toggle="tab" href="#issuelist" role="tab" aria-controls="issuelist" aria-selected="false">Issue List - {{ p_totalcount }}</a>
                                    </li>
                                    <li class="nav-item" v-if="issuelistofbusiness.length==0 &&  issuesearch == true">
                                        <a class="nav-link" v-bind:class="{'active': issuelistofbusiness.length == 0}" id="issue-tab" data-toggle="tab" href="#issuelist" role="tab" aria-controls="issuelist" aria-selected="false">Issue List - {{ p_totalcount }}</a>
                                    </li>
                                </ul>
                              </div>

                              <div class="card-body p-1" v-if="subtasklist.length || tasklistofbusiness.length || issuelistofbusiness.length|| blockertaskslistofbusiness.length || tasksearch == true || issuesearch == true || this.blockersearch == true || this.testcyclesetsearch == true || this.testcyclesetlist.length">
                                <div class="tab-content" id="myTabContent">
                                <div v-if="(this.subtasksearch == true && subtasklist.length==0) || subtasklist.length" class="tab-pane fade" v-bind:class="{'show':subtasklist.length || subtasksearch == true, 'active': subtasklist.length || subtasksearch == true}" id="subtasklist" role="tabpanel" aria-labelledby="suntask-tab">
                                    
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <div class="nav-item nav-search mr-50 search_filtter_animation">
                                                <div class="search">
                                                    <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchSubBtc" @input="inputsearchsub"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="table-responsive" >
                                        <table class="table table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="align-middle first-col-sticky py-1">Sr#</th>                             
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness second-col-sticky" @click="getsorting('Code')">Code</th>
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" @click="getsorting('Name')" style="min-width:150px;">Name</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Project Name')">Project Name</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:140px;" @click="getsorting('Status')">Status</th>
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Task Type')">Task Type</th>
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" @click="getsorting('Sub Priority')">Sub Priority</th>
                                                    <th scope="col" class="text no-wrap align-middle">Created By/Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(data4, index) in subtasklist">
                                                    <tr v-bind:key="data4.id">
                                                        <td class="srno first-col-sticky" style="min-width:45px;">{{ index+1+b_currPageNum*b_pagelimit }}</td>
                                                        <td class="second-col-sticky"><span class="font-weight-normal capitalisetext" v-if="data4.businesstaskcode">{{data4.businesstaskcode}}</span></td>
                                                        <td>
                                                            <span class="font-weight-normal cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('view', data4)">{{ data4.taskname }}</span>
                                                         </td>   
                                                        <td style="text-transform: capitalize;">{{data4.projectname}}</td>
                                                        <td><span class="font-weight-normal capitalisetext">{{data4.taskstatus}}</span></td>
                                                        
                                                        <td><span class="font-weight-normal capitalisetext">{{data4.tasktype}}</span>
                                                        </td>

                                                        <td>
                                                            <span style="text-transform: capitalize;" v-if="data4.subpriority">{{ data4.subpriority }}</span>
                                                            <span style="text-transform: capitalize;" v-else>NA</span>
                                                        </td>
                                                        
                                                        <td class="details_column_table">
                                                            {{data4.createdby.split('@')[0]}}
                                                            <hr class="hr_border-bottom" />
                                                            <span> {{dateMonthFormat(data4.createddate)}} </span>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-if="subtasklist.length == 0">
                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="pageCenter text-center mb-1">
                                            <pagination v-if="s_showtabledata" v-model="s_page" :records="s_totalcount" :per-page="s_pagelimit" @paginate="clickCallback_subtasklist"/>
                                    </div>
                                </div>

                                <div v-if="(this.tasksearch == true && tasklistofbusiness.length == 0) || tasklistofbusiness.length" class="tab-pane fade" v-bind:class="{'show':tasklistofbusiness.length && !subtasklist.length || tasksearch == true , 'active': tasklistofbusiness.length && !subtasklist.length || tasksearch == true }" id="tasklist" role="tabpanel" aria-labelledby="tasklist-tab">
                                    
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                        <div class="nav-item nav-search mr-50 search_filtter_animation">
                                            <div class="search">
                                                <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchTerm" @input="inputsearchtask"/>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    
                                    <div class="table-responsive datatableview">
                                        <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" id="table_task" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="align-middle py-1 first-col-sticky" style="min-width:45px;">Sr#</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness second-col-sticky" @click="getsorting('Task Code')" style="min-width:145px;">Task Code</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('BTC Code')" style="min-width:185px;">BTC Code</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:220px;" @click="getsorting('Task Name')" >Task Name</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:170px;" @click="getsorting('Parent Task Code')">Parent Task Code</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Task Status')" style="min-width:110px;">Status</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:120px;" @click="getsorting('Type') ">Type</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Project Name')">Project Name</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:120px;" @click="getsorting('Assignee')">Assignee</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:120px;" @click="getsorting('Assignor')">Assignor</th>
                                                    <th scope="col" class="align-middle" style="min-width:150px;">Start date</th>
                                                    <th scope="col" class="align-middle" style="min-width:150px;">End date</th>
                                                    <th scope="col" class="align-middle" style="min-width:150px;">Estimated Duration</th>
                                                    <th scope="col" class="align-middle" style="min-width:150px;">Micro Task Blocker</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(data, index) in tasklistofbusiness">
                                                    <tr v-bind:key="data.taskcode">
                                                        <td data-column="Sr#" class="srno first-col-sticky">{{ index+1+t_currPageNum*t_pagelimit }}</td>
                                                        <td data-column="Task Code" class="second-col-sticky"><span class="font-weight-bold capitalisetext">{{data.taskcode}}</span></td>
                                                        <td data-column="BTC Code"><span class="font-weight-bold capitalisetext">{{data.businesstaskcode}}</span></td>
                                                        <td data-column="Task Name"><span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirectToTask('view', data.id)">{{ data.taskname }}</span></td>
                                                        <td data-column="Parent Task Code" class="font-weight-bold capitalisetext" v-if="data.parenttaskcode">{{data.parenttaskcode}}</td>
                                                        <td data-column="Parent Task Code" class="font-weight-bold capitalisetext" v-else>-</td>
                                                        <td data-column="Status" style="text-transform: capitalize;">{{data.status}}</td>
                                                        <td data-column="Type" style="text-transform: capitalize;">{{data.tasktype}}</td>
                                                        <td data-column="Project Name" style="text-transform: capitalize;">{{data.projectname}}</td>
                                                        <td data-column="Assignee" style="text-transform: capitalize;">{{data.assignee}}</td>
                                                        <td data-column="Assignor" style="text-transform: capitalize;">{{formatName(data.assignor)}}</td>
                                                        <td data-column="Start date"><span class="font-weight-bold capitalisetext">{{dateMonthFormat(data.taskstartdate)}}</span></td>
                                                        <td data-column="End date"><span class="font-weight-bold capitalisetext">{{dateMonthFormat(data.taskenddate)}}</span></td>
                                                        <td data-column="Estimated Duration"><span class="font-weight-bold">{{diffBetweenDates(data.taskstartdate, data.taskenddate)}}</span></td>
                                                        <td data-column="Micro Task Blocker">
                                                            <span class="font-weight-bold">
                                                                <div style="cursor:pointer" @click="openBlockerTaskModule(data, 'task')" class="avatar bg-light-danger rounded">
                                                                    <div class="avatar-content">
                                                                        <plus-icon size="1.5x" class="custom-class avatar-icon"></plus-icon>
                                                                    </div>
                                                                </div>                                                      
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-if="tasklistofbusiness.length == 0">
                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="pageCenter text-center mb-1">
                                            <pagination v-if="t_showtabledata" v-model="t_page" :records="t_totalcount" :per-page="t_pagelimit" @paginate="clickCallback_tasklists"/>
                                    </div>
                                </div>

                                <div v-if="(this.blockersearch == true && blockertaskslistofbusiness.length==0) || blockertaskslistofbusiness.length" class="tab-pane fade" v-bind:class="{'show':blockertaskslistofbusiness.length && !tasklistofbusiness.length && !subtasklist.length || blockersearch == true, 'active': blockertaskslistofbusiness.length && !tasklistofbusiness.length && !subtasklist.length || blockersearch == true}" id="blockerlist" role="tabpanel" aria-labelledby="blockertasks-tab">
                                    
                                    <div class="row">
                                        
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <div class="nav-item nav-search mr-50 search_filtter_animation">
                                                <div class="search">
                                                    <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchBlocker" @input="inputsearchbloker"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="table-responsive datatableview" >
                                        <table class="table table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="align-middle first-col-sticky py-1" style="min-width:45px;">Sr#</th>                             
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness second-col-sticky" @click="getsorting('BTC Code')">Code</th>
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" @click="getsorting('BTC Name')">Name</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('BProject Name')">Project Name</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:140px;" @click="getsorting('Related To')">Related To</th>
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Module Type')">Module Type</th>
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" @click="getsorting('BStatus')">Status</th>
                                                    <th scope="col" class="text no-wrap align-middle">Assignee</th>
                                                    <th scope="col" class="text no-wrap align-middle">Assignor</th>
                                                    <th scope="col" class="text no-wrap align-middle">Created By/Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(data3, index) in blockertaskslistofbusiness">
                                                    <tr v-bind:key="data3.id">
                                                        <td data-column="Sr#"  class="srno first-col-sticky">{{ index+1+b_currPageNum*b_pagelimit }}</td>
                                                        <td data-column="Code" ><span class="font-weight-bold capitalisetext second-col-sticky" v-if="data3.blokercode">{{data3.blokercode}}</span></td>
                                                        <td data-column="Name">
                                                            <span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirectToITB('business', data3)" v-if="data3.moduletype == 'businesstask' " >{{ data3.blockername }}</span>
                                                            <span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirectToITB('task', data3)" v-if="data3.moduletype == 'task'">{{ data3.blockername }}</span>
                                                            <span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirectToITB('issue', data3)" v-if="data3.moduletype == 'issue'">{{ data3.blockername }}</span>
                                                         </td>   
                                                        <td data-column="Project Name" style="text-transform: capitalize;">{{data3.projectname}}</td>
                                                        <td data-column="Related To"><span class="font-weight-bold capitalisetext">{{data3.relatedto}}</span></td>
                                                        
                                                        <td data-column="Module Type"><span class="font-weight-bold capitalisetext">{{data3.moduletype}}</span></td>

                                                        <td data-column="Status" >
                                                            <span style="text-transform: capitalize;" v-if="data3.status">{{ data3.status }}</span>
                                                        </td>

                                                        <td data-column="Assignee">
                                                            <span style="text-transform: capitalize;" v-if="data3.asignee">{{ data3.asignee }}</span>
                                                            <span style="text-transform: capitalize;" v-else>NA</span>
                                                        </td>
                                                        <td data-column="Assignor">
                                                            <span style="text-transform: capitalize;" v-if="data3.asignor">{{ data3.asignor.split('@')[0] }}</span>
                                                            <span style="text-transform: capitalize;" v-else>NA</span>
                                                        </td>
                                                        <td data-column="Created By/Date" class="details_column_table">
                                                            {{data3.createdby.split('@')[0]}}
                                                            <hr class="hr_border-bottom" />
                                                            <span> {{dateMonthFormat(data3.createddate)}} </span>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-if="blockertaskslistofbusiness.length == 0">
                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="pageCenter text-center mb-1">
                                            <pagination v-if="b_showtabledata" v-model="b_page" :records="b_totalcount" :per-page="b_pagelimit" @paginate="clickCallback_blockerlist"/>
                                    </div>
                                </div>
                                
                                <div v-if="(testcyclesetsearch == true || testcyclesetlist.length>0)" class="tab-pane fade" v-bind:class="{'show':testcyclesetlist.length && tasksearch == false && !tasklistofbusiness.length || testcyclesetsearch == true, 'active': tasksearch == false && !tasklistofbusiness.length && testcyclesetlist.length || testcyclesetsearch == true}" id="testcyclesetlist" role="tabpanel" aria-labelledby="testcyclesetlist-tab">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <div class="nav-item nav-search mr-50 search_filtter_animation">
                                                <div class="search">
                                                    <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchcycleset" @input="inputsearchcycleset"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-responsive datatableview" >
                                        <table class="table table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="align-middle first-col-sticky py-1" style="min-width:45px;">Sr#</th>                             
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness second-col-sticky" @click="getsorting('Test BTC Code')" style="min-width:190px;">BTC Code</th>
                                                    <th scope="col" class="text no-wrap align-middle d-table_icon_up bussiness" style="min-width:210px;" @click="getsorting('Cycleset Code')">Cycle Set Details</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Cyclseset Status')" style="min-width:120px;">Cycle Set Status</th>
                                                    <th scope="col" class="text no-wrap align-middle" style="min-width:150px;">Scripts Details</th>
                                                    <th scope="col" class="text no-wrap align-middle" style="min-width:260px;">Cycle Set Date Details</th>
                                                    <th scope="col" class="text no-wrap align-middle" style="min-width:260px;">Last Finish Details</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:230px;" @click="getsorting('Group Code')">Group Details</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:150px;" @click="getsorting('Group Status')">Group Status</th>
                                                    <th scope="col" class="text no-wrap align-middle" style="min-width:250px;">Group Date Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(data5, index5) in testcyclesetlist">
                                                    <tr v-bind:key="index5">
                                                        <td data-column="Sr#" class="srno first-col-sticky">{{ index5+1+tcs_currPageNum*tcs_pagelimit }}</td>
                                                        <td data-column="BTC Code" class="second-col-sticky">
                                                            <span class="font-weight-normal capitalisetext ">{{data5.businesstaskcode}}</span>
                                                        </td>
                                                        <td data-column="Cycle Set Details" class="details_column_table">
                                                            <div clsss="font-weight-normal capitalisetext"> <strong> Code: </strong>  {{data5.cyclesetcode}} </div>
                                                            <hr class="hr_border-bottom" />
                                                            <div clsss="font-weight-bold capitalisetext"> <strong> Name: </strong> <span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('cyclesetview', data5)">{{ data5.cyclesetname }}</span> </div>
                                                            <hr class="hr_border-bottom" />
                                                            <div clsss="font-weight-bold capitalisetext"><strong>  Created By: </strong> <span>{{data5.createdby.split('@')[0]}}</span> </div>
                                                        </td>
                                                        <td data-column="Cycle Set Status">
                                                            <span class="font-weight-bold capitalisetext">{{data5.status}}</span>
                                                        </td>
                                                        <td data-column="Scripts Details" class="details_column_table ">
                                                            <strong> Total Scripts: </strong> {{ data5.nooftestcase ? data5.nooftestcase : 0 }}
                                                            <hr class="hr_border-bottom" />
                                                            <strong> Total Last Pass: </strong>  <span> {{ data5.totalpass ? data5.totalpass : 0}} </span>
                                                            <hr class="hr_border-bottom" />
                                                            <strong> Total Last Fail: </strong> <span> {{ data5.totalfail ? data5.totalfail : 0}} </span>
                                                        </td>
                                                        <td data-column="Cycle Set Date Details" class="details_column_table ">
                                                            <strong> Created Date:</strong> <span> {{dateMonthFormat(data5.createddate)}} </span>
                                                            <hr class="hr_border-bottom" />
                                                            <strong> Reviewed Date:</strong> <span> {{data5.revieweddate ? dateMonthFormat(data5.revieweddate) : 'NA'}} </span>
                                                            <hr class="hr_border-bottom" />
                                                            <strong> Product Pending for Review:</strong> <span> {{data5.productpendingreviewdate ? dateMonthFormat(data5.productpendingreviewdate) : 'NA'}} </span>
                                                            <hr class="hr_border-bottom" />
                                                            <strong> Last Run Date: </strong><span> {{data5.rundate ? dateMonthFormat(data5.rundate) : 'NA'}} </span>
                                                        </td>
                                                        <td data-column="Last Finish Details" class="text-left tast_case_groupingview_readmore">
                                                            <strong>Last Finish Date:</strong><span> {{data5.finishdate ? dateMonthFormat(data5.finishdate) : 'NA'}} </span>
                                                            <!-- <strong>Last Finish Comment: </strong><span> {{data5.finishcomment ? data5.finishcomment : 'NA'}} </span> -->
                                                          
                                                            <hr class="hr_border-bottom" />
                                                            <div class="col-md-12 mt-0 px-0 tast_case_groupingview_readmore">
                                                                <div class="custom-container case_bg_17">
                                                                    <div v-bind:class="[readmore]" :id="'cycle_readmore2'+index5">
                                                                        <strong style="color: #3E4757;">Last Finish Comment: </strong>
                                                                        <span class="fc_m16_btcode_text" v-if="data5.finishcomment" v-html="formatData(data5.finishcomment)"></span>
                                                                        <span class="fc_m16_btcode_text" v-else>NA</span>
                                                                        
                                                                    </div>
                                                                    <div class="cycle_viewmoretext" v-if="data5.finishcomment && data5.finishcomment.length > 150">
                                                                        <a class="btn">
                                                                        <strong :id="'id2'+index5" @click="idclick2(index5)">
                                                                Read More
                                                            </strong>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td data-column="Group Details">
                                                            <div clsss="font-weight-normal capitalisetext"> <strong> Code: </strong>  {{ data5.groupmappingcode ? data5.groupmappingcode :'NA'}} </div>
                                                            <hr class="hr_border-bottom" />
                                                            <div clsss="font-weight-normal capitalisetext"><strong>Name: </strong> <span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirecttourl('groupmappingview', data5)">{{ data5.mappingname ? data5.mappingname :'NA' }} </span> </div>
                                                            <hr class="hr_border-bottom" />
                                                            <div clsss="font-weight-normal capitalisetext"> <strong>Created By: </strong>{{ data5.groupcreatedby ? data5.groupcreatedby.split('@')[0] : 'NA' }}</div>
                                                        </td>
                                                        <td data-column="Group Status">
                                                            <span class="font-weight-bold"> {{ data5.groupmappingstatus ? data5.groupmappingstatus :'NA'}} </span>
                                                        </td>
                                                        <td data-column="Group Date Details">
                                                            <strong> Created Date: </strong> <span> {{ data5.groupcreateddate ? dateMonthFormat(data5.groupcreateddate) : 'NA'}} </span>
                                                            <hr class="hr_border-bottom" />
                                                            <strong> Reviewed Date: </strong><span> {{data5.grouprevieweddate ? dateMonthFormat(data5.grouprevieweddate) : 'NA'}} </span>
                                                            <hr class="hr_border-bottom" />
                                                            <strong> Approved Date: </strong> <span> {{data5.groupapproveddate ? dateMonthFormat(data5.groupapproveddate) : 'NA'}} </span>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-if="testcyclesetlist.length == 0">
                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="pageCenter text-center mb-1">
                                        <pagination v-if="tcs_showtabledata" v-model="tcs_page" :records="tcs_totalcount" :per-page="tcs_pagelimit" @paginate="clickCallback_testcyclesetlist"/>
                                    </div>
                                </div>
                                
                                <div v-if="(issuesearch == true && issuelistofbusiness.length == 0) || issuelistofbusiness.length" class="tab-pane fade" v-bind:class="{'show':issuelistofbusiness.length && tasksearch == false && !tasklistofbusiness.length || issuesearch == true, 'active': tasksearch == false && !tasklistofbusiness.length && issuelistofbusiness.length || issuesearch == true}" id="issuelist" role="tabpanel" aria-labelledby="issuelist-tab">
                                    <div class="table-responsive datatableview" >
                                        
                                        <div class="row">
                                        <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                            <div class="nav-item nav-search mr-50 search_filtter_animation">
                                                <div class="search">
                                                    <input type="text" class="search-box" placeholder="Search for the below fields..." v-model="searchIssue" @input="inputsearchissue"/>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <table class="table table-sm dataTableListing all_table_stickys" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="align-middle py-1 first-col-sticky">Sr#</th>                             
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness second-col-sticky" @click="getsorting('Issue Code')" style="min-width:120px;">Issue Code</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Issue BTC Code')" style="min-width:185px;">BTC Code</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Issue Name')" style="min-width:120px;">Issue Name</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Type')">Type</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Status')" style="min-width:110px;">Status</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Priority')" style="min-width:110px;">Priority</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Severity')" style="min-width:110px;">Severity</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('IProject Name')" style="min-width:110px;">Project Name</th>
                                                    <th scope="col">QA Cycle</th>
                                                    <th scope="col" class="align-middle d-table_icon_up bussiness" @click="getsorting('Assignee')" style="min-width:110px;">Assignee</th>
                                                    <th scope="col">Created Date</th>
                                                    <th scope="col">Add Blocker</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(data, index) in issuelistofbusiness">
                                                    <tr v-bind:key="data.issuecode">
                                                        <td data-column="Sr#" class="srno py-1 first-col-sticky" style="min-width:45px;">{{ index+1+p_currPageNum*p_pagelimit }}</td>
                                                        <td data-column="Issue Code" class="second-col-sticky"><span class="font-weight-bold capitalisetext ">{{data.issuecode}}</span></td>
                                                        <td data-column="BTC Code"><span class="font-weight-bold capitalisetext">{{data.businesstaskcode}}</span></td>
                                                        <td data-column="Issue Name"><span class="font-weight-bold cursorpointercls sitebluecolor capitalisetext" @click="redirectToIssue('view', data.issueid)">{{ data.issuename }}</span></td>
                                                        <td data-column="Type" style="text-transform: capitalize;">{{data.typename}}</td>
                                                        <td data-column="Status" style="text-transform: capitalize;">{{data.status}}</td>
                                                        <td data-column="Priority" style="text-transform: capitalize;">{{data.priority}}</td>
                                                        <td data-column="Severity" style="text-transform: capitalize;">{{data.sevairtyname}}</td>
                                                        <td data-column="Project Name" style="text-transform: capitalize;">{{data.projectname}}</td>
                                                        <td data-column="QA Cycle" style="text-transform: capitalize;">{{data.qacycle}}</td>
                                                        <td data-column="Assignee"><span class="font-weight-bold capitalisetext">{{data.emp}}</span></td>
                                                        <td data-column="Created Date"><span class="font-weight-bold capitalisetext">{{dateMonthFormat(data.createddate)}}</span></td>
                                                        <td data-column="Add Blocker">
                                                            <span class="font-weight-bold">
                                                                <div style="cursor:pointer"  @click="openBlockerTaskModule(data, 'issue')" class="avatar bg-light-danger rounded">
                                                                    <div class="avatar-content">
                                                                        <plus-icon size="1.5x" class="custom-class avatar-icon"></plus-icon>
                                                                    </div>
                                                                </div>                                                      
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <template v-if="issuelistofbusiness.length == 0">
                                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="pageCenter text-center mb-1">
                                            <pagination v-if="p_showtabledata" v-model="p_page" :records="p_totalcount" :per-page="p_pagelimit" @paginate="clickCallback_issueslist"/>
                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                </section>

        <section id="card-navigation" class="addBlockerTaskDiv" >
            <div v-bind:class="[{show: disBlockerActive},card_content,collapse]" class="row match-height">
                <div class="col-md-12">
                    <div class="card mb-3 ">
                        <div class="card-body">
                            <h4 class="card-title d-inline-flex text-primary">Add Blocker&nbsp;
                                <span v-if="blockertaskdetails.moduletype == 'task'"> Task </span>
                                <span v-else-if="blockertaskdetails.moduletype == 'issue'">  Issue</span>
                                <span v-else> Business Task </span>

                                <span v-if="blockertaskdetails.tasklabel">&nbsp; - {{ blockertaskdetails.tasklabel }}</span>
                            </h4>
                            <form class="form form-vertical update_Log_btTime">
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Select Blocker</label>
                                        <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                            <em class="las la-info-circle text-warning font-medium-2"></em>
                                        </button>
                                        <div class="dropdown-menu form-group col-md-4" style="margin-left: -80px">
                                            <a v-if="this.blockertaskdetails.moduletype == 'task'" class="dropdown-item text-wrap">
                                                <span>Note* All task status which are not in (Closed, Resolved)</span>
                                            </a>
                                            <a v-else-if="this.blockertaskdetails.moduletype == 'issue'" class="dropdown-item text-wrap">
                                                <span>Note* All Issue status which are not in (Closed, Deferred', Not a bug, Rejected, Fixed)</span>
                                            </a>
                                            <a v-else class="dropdown-item text-wrap">
                                                <span>Note* <br/> - All BTC task status which are not in (Completed, Discarded, Live, Product Pipeline, Product WIP, On Hold).</span>
                                            </a>
                                        </div>
                                        <treeselect class="projectlistdd capitalisetext" placeholder="Enter min 4 chars to search Blocker Task Name/Code" :clear-on-select="true" v-model="blockertaskdetails.blockerbtcs" :options="tasksblockerlist" :multiple="true" @input="seltBlockerBTCs" @close="validateBlocekrForm('t9',$event)" v-on:search-change="getBTaskListToTagBlocker"/>
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.blockerbtcs">{{ this.blockertaskdetails.errors.blockerbtcs }}</div>
                                    </div>

                                    <div v-if="this.blockertaskdetails.moduletype == undefined && this.blockertaskdetails.blockerbtcs != null && blockertaskdetails.btcprojlist.length>0" class="form-group col-3">
                                        <label class="form-label">Select BTC Project</label>
                                        <treeselect placeholder="Select BTC Project" class="projectmdllistdd capitalisetext" :options="blockertaskdetails.btcprojlist" @input="selBTcPro" v-model="blockertaskdetails.projectid" @close="validateBlocekrForm('t9',$event)" />
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.projectid">{{ this.blockertaskdetails.errors.projectid }}</div>
                                    </div>

                                    <div class="form-group col-md-3">
                                        <label for="Department">Related To</label>
                                        <treeselect placeholder="Select Related To" class="projectmdllistdd capitalisetext" :options="blockertaskdetails.blockertypes" @input="seltaskblockerstatus" v-model="blockertaskdetails.status" @close="validateBlocekrForm('t9',$event)" />
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.status">{{ this.blockertaskdetails.errors.status }}</div>
                                    </div>
                                    
                                    <div class="form-group col-12">
                                        <label class="form-label">Comment</label>
                                        <ckeditor :editor="editor" placeholder="Comment" v-model="blockertaskdetails.comment"  @input="seltaskblockercomments" :config="editorConfig" tag-name="textarea" @blur="validatelog" @close="validateBlocekrForm('t9',$event)"></ckeditor>
                                        <div class="errorinputmsg" :clear-on-select="true" v-if="this.blockertaskdetails.errors.comment">{{ this.blockertaskdetails.errors.comment }}</div>
                                    </div>

                                    <div class="col-12 mg-top1 text-right">
                                        <button type="button" class="btn btn-relief-primary mr-1" @click="saveBlockerTasks()" :disabled="isdisabled">Submit</button>
                                        <button type="reset" class="btn btn-outline-secondary" @click="handleLogCancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="customTimeline time_lineBusiness_details">
          <div class="card " v-if="logtimehistory.length > 0">
            <div class="card-body mb-2 mt-1 timeline_card time_line_style_guide">
              <ul class="timeline">
                <li class="timeline-item" v-for="(data,index) in logtimehistory" v-bind:key="index">
                  <span class="timeline-point timeline-point-primary timeline-point-indicator"></span>
                  <div class="timeline-event" style="display:block;">
                  <div class="row time_linegrids ">
                    <div class="col-sm-2 text-muted timeline-event_display"> Updated on : </br>{{dateMonthFormat(data.lastmodifieddate)}} </div>
                     <div class="col-sm-3 d-flex flex-sm-row flex-column mt-0 timeline-event_display mb-0">
                      <div class="media align-items-center">
                         
                          <div class="avatar ">
                             <profilepic v-if="data.imagename" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                             <img alt=""  v-if="!data.imagename" :src="defaultimagepath"  width="24" height="24" />
                          </div>
                          <div class="media-body ml-50">
                            <h6 class="mb-0 text-nowrap"  style="text-transform:capitalize"> {{(data.createdby)}}</h6>
                            <span class="text-muted text-nowrap " style="text-transform:capitalize">{{decryptText(data.designation)}}</span>
                            </div>
                      </div>
                  </div>
                    <div class="col-sm-4  d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1 timeline-event_display">
                    <div class=" time_line_point_primary time_line_point_table_view">
                        <p v-if="data.coments"><span v-html="data.coments"  style="font-weight:600 !important;"></span></p>
                    </div>
                      
                    </div>
                    <div class="col-sm-3 text-muted timeline-event_display" style="margin-top:-3px">
                        
                        <span class="capitalisetext badge badge-pill badge-secondary mx-50 badge-glow mb-1" style="margin-top:5px;">{{data.status}}</span>
                        <span class="capitalisetext badge badge-pill badge-primary mx-50 badge-glow mb-1">{{data.activity}}</span>
                    </div>
                   
                  
                    <div class="col-xl-2 col-md-2 col-sm-2 mb-1 time_line_point_primary1 timeline-event_display mt-1" v-for="(res,idx) in (data.attachment ? data.attachment.data:[])" v-bind:key="idx">
                        <div class="card card-bordered mb-0">
                          <div class="text-center" style="background:#F8F8F8; padding:6px 0px;">
                            <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="25" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="25" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="25" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="25" />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="25"  />
                            <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlxs'" src="../../../public/images/icons/ic-xls.png" height="25" />
                            <a v-on:click="getLogtimeDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.1x" class="custom-class "></download-icon> </a>
                            <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.1 x" class="custom-class "></zoom-in-icon> </a>
                            </div>
                             <a :href="res.link"  class="py-50 px-50 border-top documents_list_images_pic" style="font-size:0.85rem; color:#888;"  target="_blank">
                              <span v-if="res.info">
                                {{res.info}} -123
                              </span>
                              <span v-else-if="!res.info">
                                {{res.link | basename}}
                              </span>
                              </a>
                        </div>
                    </div>
                  
                  
                </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
        <div v-bind:class="[{display:dislogActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header" style="cursor:pointer">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-9">
                                    <div class="avatar bg-light-primary rounded d-inline-flex" >
                                        <div class="avatar-content">
                                            <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex text-primary ml-1" >Update Log Time</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isLogActive},card_content,collapse]">
                        <div class="card-body">
                            <form class="form form-vertical update_Log_btTime">
                                <div class="row">
                                    <div class="form-group col-md-3">
                                     <div class="form-group mg-bot0">
                                        <label class="form-label" for="spendtime">Spent Time</label>
                                        <div id="spendtime">
                                            <vue-timepicker type="time"  v-model="logtime.spenttime" format="HH:mm"  @close="validatelog()"></vue-timepicker>
                                            <div class="errorinputmsg" v-if="this.errors.spenttime">{{ this.errors.spenttime }}</div>
                                        </div>
                                     </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group mg-bot0">
                                            <label class="form-label">Activity</label>
                                            <treeselect placeholder="Select Activity"  class="projectmdllistdd capitalisetext" v-model="logtime.activity" :options="logtime.acivitylist" @input="seltasklogactivity" @close="validatelog()"/>
                                        <div class="errorinputmsg" v-if="this.errors.activity">{{ this.errors.activity }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="Department">Task Status</label>
                                        <input class="form-control capitalisetext" placeholder="Select Task Status" :options="taskstatus" @input="seltasklogstatus" v-model="logtime.status"  @close="validatelog()" v-if="taskstatus.length<=1" disabled/>
                                        <treeselect placeholder="Select Task Status" class="projectmdllistdd capitalisetext" :options="taskstatus" @input="seltasklogstatus" v-model="logtime.status"  @close="validatelog()" v-else/>
                                        <div class="errorinputmsg" v-if="this.errors.status">{{ this.errors.status }}</div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="Department">Status Due Date</label>
                                        <date-picker placeholder="Select Status Due Date" v-model="logtime.duedate" valueType="format" class="startdate" :disabled-date="notBeforeToday" @close="validatelog()"></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.duedate">{{ this.errors.duedate }}</div>
                                    </div>
                                        <div class="form-group col-md-3" v-if="logtime.status != 'Product Pipeline' && logtime.status != 'Product WIP' && logtime.status != 'Product Pipeline' && logtime.status != 'Product Completed' && logtime.status != 'OnHold' && logtime.status != 'Discarded' && logtime.status != 'Tech and Business Review' && logtime.status != 'Product WIP 2' && logtime.status != 'Tech Issue Resolution'">
                                            <label for="Department">Ballpark Estimate in days</label>
                                            <treeselect :disabled="disableballpark" class="projectmdllistdd" placeholder="Select Ballpark Estimate In Day's" v-model="logtime.taskestimates" :options="estimateshours" @input="sellogBallparkestimates" @close="validatelog()"/>
                                            <div class="errorinputmsg" v-if="this.errors.taskestimates">{{ this.errors.taskestimates }}</div> 
                                        </div>

                                        <div class="row mx-0" v-if="('Product WIP' != logtime.status && 'Product Pipeline' != logtime.status && 'Product Completed' != logtime.status && 'Product WIP 2' != logtime.status && 'Tech and Business Review' != logtime.status && 'OnHold' != logtime.status && 'Discarded' != logtime.status) && editRowData.securitytag == null">
                                        <div class="form-group col-md-3" >
                                            <label class="form-label">Security Sensitive</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Security Sensitive" v-model="logtime.securitytag" @input='selectsecurity' :options="securitylist"/>
                                            <div class="errorinputmsg">{{ this.errors.securitytag }}</div>
                                        </div>
                                        
                                        <div class="form-group col-md-4">
                                            <div class="form-group" v-if="logtime.securitytag">
                                            <label class="form-label" for="project-name">Security Justification</label>
                                            <textarea class="form-control" placeholder="Enter security comment or justification" rows="1" v-model="logtime.securitycomment"></textarea>
                                            <div class="invalid-feedback">Security Justification is required</div>
                                            <div class="errorinputmsg" v-if="this.errors.securitycomment">{{ this.errors.securitycomment }}</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-12">
                                        <label class="form-label">Comment</label>
                                        <ckeditor :editor="editor"  placeholder="Comment" v-model="logtime.comment"  @input="seltasklogcomments" :config="editorConfig" tag-name="textarea" @blur="validatelog"></ckeditor>
                                        <div class="errorinputmsg" v-if="this.errors.comment">{{ this.errors.comment }}</div>
                                    </div>
                                        <div class="card-body">
                                                <div class="row" v-for="(doclist, index) in docs" :key="`phoneInput-${index}`">
                                                    <div class="form-group col-md-3" >
                                                        <label class="form-label">Document Title </label>
                                                        <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title"/>
                                                        <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label class="form-label">Description</label>
                                                        <textarea class="form-control"  rows="3" placeholder="Description" v-model="doclist.desc" ></textarea>
                                                        <div class="errorinputmsg" v-if="!doclist.desc">Document description is required</div>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label class="form-label">Comment</label>
                                                        <textarea class="form-control" rows="3" placeholder="Comment" v-model="doclist.comment"/>
                                                        <div class="errorinputmsg" v-if="!doclist.comment">Commentis required</div>
                                                    </div>
                                                    <div class="form-group col-md-3" style="margin-top:25px">
                                                        <label for="attachment" class="btn btn-relief-success mb-0">Browse</label>
                                                        <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelect($event) "/>
                                                        <button class="btn btn-relief-info ml-1 uploadbutton" :data-id="index" v-on:click.stop.prevent="onUpload($event,index)"> Upload</button>
                                                        <div class="avatar bg-success rounded ml-1">
                                                            <div class="avatar-content " @click="addField(doclist, docs)" style="cursor: pointer;">
                                                                <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                            </div>
                                                        </div>
                                                        <div class="avatar bg-danger rounded ml-1" :class="[{display:isActive}]">
                                                            <div class="avatar-content" @click="removeField(index, docs)" style="cursor: pointer;">
                                                                <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                </div>
                                        </div>
                                    <div class="col-12 mg-top1 text-right">
                                        <button type="button" class="btn btn-relief-primary mr-1" @click="savelogData()" :disabled="isdisabled">Submit</button>
                                        <button type="reset" class="btn btn-outline-secondary" @click="handleLogCancel()">Cancel</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{display:disRequestActive},card,border_warning]" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header" style="cursor:pointer" v-on:click="myrequestollapse">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-9">
                                    <div class="avatar bg-light-primary rounded d-inline-flex" >
                                        <div class="avatar-content">
                                            <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex text-primary ml-1" >{{this.formActionLabel}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="project-name">Business Task Name </label>
                                        <input class="form-control" type="text" placeholder="Task Name " v-model="tasktype.name"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.name">{{ this.errors.tasktype.name }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="">Business Project Name</label>
                                        <treeselect class="projroleassprojectdd" placeholder="Select business project name" :options="tasktype.businessnamelist" v-model="tasktype.businessname" @input='selectBusinessname' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.businessname">{{ this.errors.tasktype.businessname }}</div> 
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="redmineid">Redmine/Reference ID </label>
                                        <input class="form-control" type="text" placeholder="Redmine/Reference ID " v-model="tasktype.redmineid" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.redmineid">{{ this.errors.tasktype.redmineid }}</div> 
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Project Name</label>
                                        <treeselect :disabled="issubtask" class="projectlistdd capitalisetext" placeholder="Enter min 3chars to search project name" :multiple="true"  :clear-on-select="true" v-model="tasktype.projectid" :options="tasktype.projectlist" @input="seltpprojectdata" @close="validateForm('t9',$event)" v-on:search-change="getProjectAllocatedToEmp"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.projectid">{{ this.errors.tasktype.projectid }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="">Project Module</label>
                                        <treeselect class="projroleassprojectdd capitalisetext" placeholder="Select Project Module" :multiple="true" :clear-on-select="true" :options="tasktype.projectmodulelist" v-model="tasktype.projectmodules" @input="selProjectMOdules" @close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.projectmodules">{{ this.errors.tasktype.projectmodules }}</div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                          <label for="">Subscribers</label>
                                          <div class="input-group input-group-merge">
                                            <treeselect class="projroleassprojectdd capitalisetext" placeholder="Select Subscribers" :multiple="true" :clear-on-select="true" :options="tasktype.subscriberslist" v-model="tasktype.subscribers" @input="selSubscribers" v-on:close="validateForm('t18', $event)"/>
                                          </div>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.subscribers">{{ this.errors.tasktype.subscribers }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4 mb-1">
                                        <div class="form-group">
                                        <label for="applicationmasterdd">Task Type
                                            <BTCtypetext/>
                                        </label>
                                        <input class="form-control capitalisetext" v-model="tasktype.type" placeholder="Select Type" :options="btmtasktypelist" @input="selecttypePriority" v-if="btmtasktypelist.length<=1" disabled/>
                                        <treeselect v-model="tasktype.type" placeholder="Select Type" :options="btmtasktypelist" @input="selecttypePriority" v-else/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.type">{{ this.errors.tasktype.type }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="Department">Task Status</label>
                                        <input class="form-control capitalisetext" placeholder="Select Task Status" :options="taskstatus" @input="selecttaskstatus" v-model="tasktype.tstatus" v-if="taskstatus.length<=1" disabled/>
                                        <treeselect placeholder="Select Task Status" :options="taskstatus" @input="selecttaskstatus" v-model="tasktype.tstatus" v-else/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.tstatus">{{ this.errors.tasktype.tstatus }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="key">Task Severity </label>
                                        <treeselect placeholder="Select Severity" :options="tasktypekeylist" @input="selectserPriority" v-model="tasktype.key" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.key">{{ this.errors.tasktype.key }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="project-name">Task Level </label>
                                        <treeselect placeholder="Select Task Level" :options="tasklevellist" @input="seltaskleveldata" v-model="tasktype.level" @close="validate('t9')" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.level">{{ this.errors.tasktype.level }}</div>
                                    </div>
                                    <div v-if="issubtask == false" class="form-group col-md-4">
                                        <label for="priority">Task Priority</label>
                                        <input  v-if="pagefeatures.updatepriority || loginpersonname==editRowData.projectmaanager" :disabled="false"  class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" placeholder="Enter the Numbers Only..." v-model="tasktype.btmpriority"/> 
                                        <input v-else :disabled="true" class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" placeholder="Enter the Numbers Only..." v-model="tasktype.btmpriority"/> 
                                    </div>
                                    <div v-if="(this.tasktype.projectid && this.tasktype.projectid.length==1) || issubtask == true || isoldbtc == true" class="form-group col-md-4">
                                        <label class="form-label" for="subpriority">Task Sub Priority</label>
                                        <input v-if="pagefeatures.updatemodulepriority || loginpersonname==editRowData.productstackholder" :disabled="false" class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" placeholder="Enter the Numbers Only..." v-model="tasktype.subpriority" />
                                        <input v-else :disabled="true" class="form-control" type="number" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" placeholder="Enter the Numbers Only..." v-model="tasktype.subpriority" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="Department">Department</label>
                                        <treeselect placeholder="Select Department" :options="tasktype.deptlist" @input="selectbtmDept" @select="deSelectbtmDept" v-model="tasktype.dept" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.dept">{{ this.errors.tasktype.dept }}</div>
                                    </div> 

                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="bsh">HOD name</label>
                                        <treeselect :disabled="readonlyflds.hodnamefld" class="projroleassprojectdd" placeholder="Select HOD Name " :options="tasktype.bshlist" v-model="tasktype.bsh"  @input="selectBSH" @blur="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.bsh">{{ this.errors.tasktype.bsh }}</div> 
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Requester</label>
                                        <treeselect class="projectlistdd capitalisetext" placeholder="Enter min 4 chars to search requester name" :clear-on-select="true" v-model="tasktype.requester" @input="selRequester" :options="requesterlist" v-on:search-change="getRequesterOnSearch"/>
                                    </div>

                                    <div class="form-group col-md-4">
                                        <label for="projectlistdd">Requester HOD</label>
                                        <treeselect class="projectlistdd capitalisetext" placeholder="Select Requester HOD Name" :clear-on-select="true" v-model="tasktype.requester_hod" :options="requesterhodlist" />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="taskmanager">Task Manager</label>
                                        <treeselect :disabled="readonlyflds.taskmanagerfld" class="projroleassprojectdd" placeholder="Select Task Manager" :options="tasktype.taskmanagerlist" v-model="tasktype.taskmanager" @input='selTaskmanager' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskmanager">{{ this.errors.tasktype.taskmanager }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="taskowner">Task Owner (Lead)</label>
                                        <treeselect :disabled="readonlyflds.taskownerfld" class="projroleassprojectdd" placeholder="Select Task Owner" :options="tasktype.taskownerlist" v-model="tasktype.taskowner" @input='selTaskOwner'  />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskowner">{{ this.errors.tasktype.taskowner }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="promanager">Project Manager</label>
                                        <treeselect :disabled="readonlyflds.promanagerfld" class="projroleassprojectdd" placeholder="Select Project Manager" :options="tasktype.pmlist" v-model="tasktype.proman" @input='selectProMan' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.proman">{{ this.errors.tasktype.proman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="progmanager">Program Manager</label>
                                        <treeselect :disabled="readonlyflds.progmanfld" class="projroleassprojectdd" placeholder="Select Program Manager" :options="tasktype.proglist" v-model="tasktype.progman" @input='selectProgMan' />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.progman">{{ this.errors.tasktype.progman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="engmanager">Engineering Manager</label>
                                        <treeselect :disabled="readonlyflds.engmanagerfld" class="projroleassprojectdd" placeholder="Select Engineering Manager" :options="tasktype.emlist" v-model="tasktype.engman" @input='selectEngMan' v-on:close="validateForm('t9',$event)" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.engman">{{ this.errors.tasktype.engman }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="qamanager">QA Manager</label>
                                        <treeselect :disabled="readonlyflds.qamanagerfld" class="projroleassprojectdd" placeholder="Select QA Manager" :options="tasktype.qamnglist" v-model="tasktype.qamanager" @input='selectQAMan' v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.qamanager">{{ this.errors.tasktype.qamanager }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label class="form-label" for="qalead">QA Lead</label>
                                        <treeselect :disabled="readonlyflds.qaleadfld" class="projroleassprojectdd" placeholder="Select QA Lead" :options="tasktype.qaleadlist" v-model="tasktype.qalead" @input='selectQALead' v-on:close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.qalead">{{ this.errors.tasktype.qalead }}</div>
                                    </div>
                                    <div class="form-group mg-bot0 col-md-4">
                                        <label for="">Start Date</label>
                                        <div class="input-group input-group-merge">
                                            <date-picker v-model="tasktype.startdate" valueType="format" class="startdate w-100" placeholder="Select Start Date" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeTodaydate"
                                            :disabled="isdisableds" @change="onchangestartdate()"></date-picker>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.startdate">{{ this.errors.tasktype.startdate }}</div>
                                    </div>
                                    <div class="form-group mg-bot0 col-md-4">
                                        <label for="">Estimated End Date</label>
                                        <div class="input-group input-group-merge">
                                            <date-picker v-model="tasktype.enddate" :disabled="isenableestimatedate" placeholder="Select Estimated End Date" valueType="format" class="startdate w-100" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeTodaydate"></date-picker>
                                        </div>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.enddate && isenableestimatedate == false">{{ this.errors.tasktype.enddate }}</div>
                                        <small class="capitalisetext text-danger" v-if="this.view.isapproved_edd && this.view.isapproved_edd == 'Pending'"> This Estimated Date is Pending for the approval - <strong> {{dateMonthFormat(this.view.updated_edd) ? dateFormatnew(this.view.updated_edd): 'NA'}} </strong>.</small>
                                    </div>
                                    <div class="form-group col-md-4" v-if="(editRowData.estimatedddate && tasktype.enddate != editRowData.estimatedddate && tasktype.tstatus != 'Product Pipeline' && tasktype.tstatus != 'Product WIP' && tasktype.tstatus != 'Product Completed' && tasktype.tstatus != 'Product WIP 2')">
                                        <label class="form-label" for="edd_reason">Estimate Update Reason </label>
                                        <textarea class="form-control" placeholder="Enter the estimate update reason here" v-model="tasktype.edd_reason" v-bind:class="{'form-control':true, 'is-invalid' : !validText(tasktype.edd_reason)}" ></textarea>                                        
                                        <div class="invalid-feedback" v-if="this.errors.tasktype.edd_reason">{{ this.errors.tasktype.edd_reason }}</div>   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="Department">Status Due Date</label>
                                        <date-picker placeholder="Select Status Due Date" v-model="tasktype.duedate" valueType="format" class="startdate w-100" :disabled-date="notBeforeToday"  v-on:pick="validateForm('t9',$event)"></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.duedate">{{ this.errors.tasktype.duedate }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="projectmdllistdd">Ballpark Estimate in days </label>
                                            <treeselect :disabled="disableballpark" class="projectmdllistdd capitalisetext" placeholder="Select Ballpark Estimate in days" v-model="tasktype.taskestimates" :options="estimateshours" @input="selestimateshours" @close="validateForm('t9',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.taskestimates">{{ this.errors.tasktype.taskestimates }}</div>
                                     </div>
                                     <div class="form-group col-md-4">
                                        <label class="form-label" for="References">Reference (Please mention the origin from where this task came from) </label>
                                        <input class="form-control" type="text" placeholder="Reference (Please mention the origin from where this task came from) " v-model="tasktype.references" v-bind:class="{'form-control':true, 'is-invalid' : !validText(tasktype.references)}" />
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.references">{{ this.errors.tasktype.references }}</div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <div class="form-group">
                                            <label for="projectmdllistdd">Billing Country</label>
                                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Billing Country" v-model="tasktype.billingcountry" :options="tasktype.billingcountrylist" />
                                        </div>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="bscontext">Business Context
                                            <BTCcontexttext/>
                                        </label>
                                        <treeselect placeholder="Select Business Context" :options="tasktype.businesscontextList"  v-model="tasktype.businesscontext"/>
                                        <div class="errorinputmsg" v-if="!this.tasktype.businesscontext">{{'Business Context is required'}}</div>
                                    </div>
                                </div>              

                                <div class="row">
                                     <div class="form-group col-md-3">
                                        <label class="form-label">Security Sensitive</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Security Sensitive" v-model="tasktype.securitytag" @input='selectsecurity' :options="securitylist"/>
                                        <div class="errorinputmsg" v-if="this.errors.tasktype.securitytag">{{ this.errors.tasktype.securitytag }}</div>
                                    </div>
                                    <div class="form-group col-md-10">
                                       <div class="form-group" v-if="tasktype.securitytag">
                                           <label class="form-label" for="project-name">Security Justification</label>
                                           <textarea class="form-control" placeholder="Enter security comment or justification" rows="3" v-model="tasktype.securitycomment"></textarea>
                                           <div class="invalid-feedback">Security Justification is required</div>
                                           <div class="errorinputmsg" v-if="this.errors.tasktype.securitycomment">{{ this.errors.tasktype.securitycomment }}</div>
                                       </div>
                                    </div>
                                </div>          

                                <div class="form-group">
                                    <label class="form-label" for="project-name">Task Description </label>
                                    <ckeditor :editor="editor" placeholder="Task Description" v-model="tasktype.desc" :config="editorConfig" tag-name="textarea" ></ckeditor>
                                    <div class="errorinputmsg" v-if="this.errors.tasktype.desc">{{ this.errors.tasktype.desc }}</div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_success_criteria">Business Success Criteria </label>
                                        <ckeditor :editor="editor" placeholder="Define clear  expectations from business to make it successful." v-model="tasktype.project_success_criteria" :config="editorConfig" tag-name="textarea"
                                            v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_success_criteria) && project_success_criteriaBlured, }"
                                                v-on:blur="project_success_criteriaBlured = true"/>
                                        <div class="invalid-feedback">Business success criteria is required</div>                                           
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_fail_criteria">Business Failure Criteria </label>
                                        <ckeditor :editor="editor" placeholder="Business Failure Criteria" v-model="tasktype.project_fail_criteria" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_fail_criteria) && project_fail_criteriaBlured, }"
                                                v-on:blur="project_fail_criteriaBlured = true"/>
                                        <div class="invalid-feedback">Business failure criteria is required</div>                                           
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_assumptions">Business Assumptions </label>
                                        <ckeditor :editor="editor" placeholder="Business Assumptions" v-model="tasktype.project_assumptions" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{'form-control': true,'is-invalid': !validText(tasktype.project_assumptions) && project_assumptionsBlured, }"
                                                v-on:blur="project_assumptionsBlured = true"/>
                                        <div class="invalid-feedback">Business assumptions is required</div>                                           
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="project_roi">Business ROI </label>
                                        <ckeditor :editor="editor" placeholder="Business ROI" v-model="tasktype.project_roi" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.project_roi) && project_roiBlured, }"
                                                v-on:blur="project_roiBlured = true"/>
                                        <div class="invalid-feedback">Business ROI is required</div>                                           
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="business_impact">Business Impact </label>
                                        <ckeditor :editor="editor" placeholder="Business Impact" v-model="tasktype.business_impact" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{'form-control': true, 'is-invalid': !validText(tasktype.business_impact) && business_impactBlured, }"
                                                v-on:blur="business_impactBlured = true"/>
                                        <div class="invalid-feedback">Business impact is required</div>                                           
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label class="form-label" for="impact_of_live_feature">Impact on other live features if any </label>
                                        <ckeditor :editor="editor" placeholder="Impact on other live features if any." v-model="tasktype.impact_of_live_feature" :config="editorConfig" tag-name="textarea"
                                        v-bind:class="{ 
                                                'form-control': true,
                                                'is-invalid': !validText(tasktype.impact_of_live_feature) && impact_of_live_featureBlured, }"
                                                v-on:blur="impact_of_live_featureBlured = true"/>
                                        <div class="invalid-feedback">Impact on other live features if any is required</div>                                           
                                    </div>
                                </div>

                                <div v-if="isoldbtc == true" class="row">
                                <div class="col-sm-12" v-if="vieweditmoduledocs.length > 0" > 
                                    <div v-for="(pdata, index) in vieweditmoduledocs" :key="index" >
                                        <div class="card-body px-1 py-0">
                                            <form>
                                                <fieldset class="fieldset_border_vi mb-2">
                                                    <legend>{{vieweditmoduledocs[index].projectname}} : {{vieweditmoduledocs[index].projectmodulename}}</legend>
                                                <div class="row">
                                                    <div class="form-group col-md-3 business_hidden_input_space">
                                                        <input hidden class="form-control" type="text" placeholder="Project Name" :disabled="isprojectSelected" :value="vieweditmoduledocs[index].projectname"/>
                                                    </div>
                                                    <div class="form-group col-md-3 business_hidden_input_space">
                                                        <input hidden class="form-control" type="text" placeholder="Project Module Name" :disabled="isprojectmoduleSelected" :value="vieweditmoduledocs[index].projectmodulename" />
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <label class="form-label" for="modulepriority">Project Module Priority </label>
                                                        <input class="form-control" type="number" placeholder="Enter project module priority in numbers" v-model="vieweditmoduledocs[index].modulepriority" v-if="loginpersonname==editRowData.productstackholder" :disabled="false"/>
                                                        <input class="form-control" type="number" placeholder="Enter project module priority in numbers" v-model="vieweditmoduledocs[index].modulepriority" :disabled="true" v-else/>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                        <label class="w-100" for="Department">Project Module Status</label>
                                                        <input placeholder="Select Project Module Status" class="form-control capitalisetext" :options="taskstatus" v-model="vieweditmoduledocs[index].modulestatus" v-if="vieweditmoduledocs[index].modulestatus && taskstatus.length<=1" disabled/>
                                                        <treeselect placeholder="Select Project Module Status" class="projectmdllistdd capitalisetext" :options="taskstatus" v-model="vieweditmoduledocs[index].modulestatus" v-else/>
                                                    </div>
                                                    <div class="form-group col-md-4">
                                                     <label for="Department"> &nbsp;</label>
                                                        <div class="text-right  rounded w-100 mb-1">
                                                    <button v-if="pagefeatures.producttask" type="button" class="btn btn-relief-success" :id="'submitbtnt3'+index" data-dismiss="modal" @click="redirecttourl('create', pdata, 'singlemodule')" href="javascript:void(0);" ><plus-circle-icon size="1.5x" class="custom-class mr-50"></plus-circle-icon>Add Task</button>
                                                    <div class="avatar btn-relief-primary rounded ml-1" v-if="vieweditmoduledocs[index].modulestatus =='Product Pipeline' || vieweditmoduledocs[index].modulestatus =='Product WIP' || vieweditmoduledocs[index].modulestatus =='Product Completed' || vieweditmoduledocs[index].modulestatus =='Product WIP 2'">
                                                        <div class="avatar-content_addbtn add_header-padding-y6" @click="addModuleFileField(doclist, pdata.modulefiles)">
                                                            <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3 " style="height:3"></plus-square-icon>
                                                            Add Document
                                                        </div>
                                                    </div>
                                                </div>
                                                    </div>
                                                </div>

                                                

                                                <div class="table-responsive" v-if="pdata.editedmodulefiles.length>0">
                                                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">SR#</th>
                                                                <th scope="col">Document Name</th>
                                                                <th scope="col">Title</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col">Comment</th>
                                                                <th scope="col">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(image, key) in pdata.editedmodulefiles" :key="key">
                                                                <td>{{ key+1 }}</td>
                                                                <td>{{ image.documentname | basename }}</td>
                                                                <td>{{ image.title }}</td>
                                                                <td>{{ image.docdescription }}</td>
                                                                <td>{{ image.comment }}</td>
                                                               <td>
                                                                    <div class="avatar bg-info rounded ml-1">
                                                                        <div class="avatar-content">
                                                                            <a @click="getDownloadedLink(image)" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white"></download-icon></a>
                                                                        </div>
                                                                    </div>
                                                                </td> 
                                                            </tr>
                                                         </tbody>
                                                    </table>
                                                </div>
                                                <div class="row mt-1" v-for="(doclist, idx) in pdata.modulefiles" :key="`phoneInput-${index+idx}`">   
                                                    <div class="form-group col-md-3" >
                                                        <label class="form-label">Document Title </label>
                                                        <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title"/>
                                                        <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label class="form-label">Description</label>
                                                        <textarea class="form-control"  rows="1" placeholder="Description" v-model="doclist.desc"></textarea>
                                                        <div class="errorinputmsg" v-if="!doclist.desc">Document description is required</div>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label class="form-label">Comment</label>
                                                        <textarea class="form-control" rows="1" placeholder="Comment" v-model="doclist.comment"/>
                                                        <div class="errorinputmsg" v-if="!doclist.comment">Comment is required</div>
                                                    </div>
                                                    <div class="form-group col-md-3" style="margin-top:25px">
                                                        <label for="attachment1" class="btn btn-relief-success mb-0">Browse</label>
                                                        <input type="file" id="attachment1" hidden :accept="accepttype" ref="myfile" @change="onSelectDoc($event)"/>
                                                        
                                                        <button class="btn btn-relief-info  ml-1 uploadbutton" :data-id="idx" v-on:click.stop.prevent="onUploadDoc($event, index, doclist,  idx)" v-if="vieweditmoduledocs[index].modulestatus =='Product Pipeline' || vieweditmoduledocs[index].modulestatus =='Product WIP' || vieweditmoduledocs[index].modulestatus =='Product Completed' || vieweditmoduledocs[index].modulestatus =='Product WIP 2'"> Upload</button>
                                                        <div v-if="(idx>0)" class="avatar btn-relief-danger rounded ml-1" :class="[{display:isActive}]">
                                                            <div class="avatar-content" @click="removeModuleFileField(index, idx, pdata.modulefiles)">
                                                                <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                            </div>
                                                        </div>
                                                        <div class="errorinputmsg" v-if="doclist.attachment == ''">Attchment is required</div>
                                                    </div>
                                                    <div class="table-responsive my-75" v-if="doclist.tempfilename">
                                                      <table class="table table-sm table-bordered mb-0" aria-describedby="mydesc">
                                                          <thead>
                                                              <tr>
                                                                  <th scope="col">Document Title</th>
                                                                  <th scope="col">Document Name</th>
                                                                  <th scope="col">Action</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                              <tr>
                                                                  <td>{{ doclist.tempdoctitile }}</td>
                                                                  <td>{{ doclist.tempfilename.filename | basename }}</td>
                                                                  <td>
                                                                      <button type="button" class="btn btn-sm danger" @click="removeImage(index, idx)">
                                                                          <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                                      </button>
                                                                  </td>
                                                              </tr>
                                                          </tbody>
                                                      </table>
                                                  </div>
                                                </div>

                                            </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div class="form-group form-group  mb-0 mt-1 text-right" >
                                    <button type="button" class="btn btn-relief-primary mr-1 " id="submitbtnt9" data-dismiss="modal" v-on:click.stop.prevent="saveData('t9',$event)">Submit</button>
                                    <button type="button" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{display:addocument},card,border_warning]"  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header px-0" style="cursor:pointer" v-on:click="myadddocrequestollapse">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="px-1 card-header w-100 text-left" style="border-bottom: 1px solid rgb(221, 221, 221); justify-content: initial; padding-bottom: 10px;">
                                    <div class="avatar bg-light-primary rounded d-inline-flex" >
                                        <div class="avatar-content">
                                            <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex text-primary ml-1" >Upload Business Task Document: {{this.tasktype.name}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                 
                    <div v-if="moduledocs.length > 0" style="display:none" > 
                    <div v-bind:class="[{show: isdocsRequestActive},card_content,collapse]" v-for="(pdata, index) in moduledocs" :key="index" >
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label class="form-label" for="project-name">Project Name </label>
                                        <input class="form-control" type="text" placeholder="Project Name" :disabled="isprojectSelected" :value="moduledocs[index].projectname"/>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label" for="redmineid">Project Module Name </label>
                                        <input class="form-control" type="text" placeholder="Project Module Name" :disabled="isprojectmoduleSelected" :value="moduledocs[index].projectmodulename" />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label" for="modulepriority">Project Module Priority </label>
                                        <input class="form-control" type="number" placeholder="Enter project module priority in numbers" v-model="moduledocs[index].modulepriority" v-if="loginpersonname==editRowData.productstackholder" :disabled="false"/>
                                        <input class="form-control" type="number" placeholder="Enter project module priority in numbers" v-model="moduledocs[index].modulepriority" v-else :disabled="true"/>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="Department">Project Module Status</label>
                                        <treeselect placeholder="Select Project Module Status" class="projectmdllistdd capitalisetext" :options="taskstatus" v-model="moduledocs[index].modulestatus" />
                                    </div>
                                </div>
                                <div class="text-right  rounded w-100">
                                    <button v-if="pagefeatures.producttask" type="button" class="btn btn-relief-success" :id="'submitbtnt3'+index" data-dismiss="modal" @click="redirecttourl('create',pdata, 'singlemodule')" href="javascript:void(0);" ><plus-circle-icon size="1.5x" class="custom-class mr-50"></plus-circle-icon>Add Task</button>
                                    <div class="avatar btn-relief-primary rounded ml-1">
                                        <div class="avatar-content_addbtn add_header-padding-y6" @click="addModuleFileField(doclist, pdata.modulefiles)">
                                            <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3 " style="height:3"></plus-square-icon>
                                            Add Document
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-for="(doclist, idx) in pdata.modulefiles" :key="`phoneInput-${index+idx}`">                                
                                    <div class="form-group col-md-3" >
                                        <label class="form-label">Document Title </label>
                                        <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title"/>
                                        <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label">Description</label>
                                        <textarea class="form-control"  rows="3" placeholder="Description" v-model="doclist.desc"></textarea>
                                        <div class="errorinputmsg" v-if="!doclist.desc">Document description is required</div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label">Comment</label>
                                        <textarea class="form-control" rows="3" placeholder="Comment" v-model="doclist.comment"/>
                                        <div class="errorinputmsg" v-if="!doclist.comment">Comment is required</div>
                                    </div>
                                    <div class="form-group col-md-3" style="margin-top:25px">
                                        <label for="attachment" class="btn btn-relief-success mb-0">Browse</label>
                                        <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelect($event) "/>
                                        <div class="errorinputmsg" v-if="doclist.attachment == ''">Attchment is required</div>
                                        <button class="btn btn-relief-info  ml-1 uploadbutton" :data-id="idx" v-on:click.stop.prevent="onUpload($event,index, doclist, 'modulefile')"> Upload</button>
                                        <div class="avatar btn-relief-danger rounded ml-1" :class="[{display:isActive}]">
                                            <div class="avatar-content" @click="removeModuleFileField(index, idx, pdata.modulefiles)">
                                                <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="form-group d-flex flex-wrap mb-0 ml-2" >
                        <button  type="button" class="btn btn-relief-primary mr-1"  data-dismiss="modal" v-on:click.stop.prevent="newSaveDocument()" :disabled="isAllModuleDocsAdded()">Save</button>
                        <button type="button" class="btn btn-outline-secondary"  @click="docsuploadcollapse">Cancel</button>
                    </div>
                    </div>
                        
            
                    <div>
                        <div class="row" >
                            <div class="card-body">
                            <form>
                                <fieldset class="fieldset_border_vi mb-2">
                                    <legend>Document List</legend>
                                    <div class="row" v-for="(doclist, index) in docs" :key="`phoneInput-${index}`">
                                        <div class="form-group col-md-3" >
                                            <label class="form-label">Document Title </label>
                                            <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title" />
                                            <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="form-label">Description</label>
                                            <textarea class="form-control"  rows="3" placeholder="Description" v-model="doclist.desc" ></textarea>
                                            <div class="errorinputmsg" v-if="!doclist.desc">Document description is required</div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="form-label">Comment</label>
                                    <textarea class="form-control" rows="3" placeholder="Comment" v-model="doclist.comment" />
                                            <div class="errorinputmsg" v-if="!doclist.comment">Comment is required</div>
                                        </div>
                                        <div class="form-group col-md-3 p-md-0" style="margin-top:25px">
                                            <label :for="'attachment'+index" class="btn btn-sm btn-relief-success mb-1 add_header-padding-bt-10 ">Browse</label>
                                            <input  type="file" :id="'attachment'+index" hidden :accept="accepttype"  ref="file" @change="onSelectSubTask($event, index, 'add')"/>
                                            <button class="btn btn-sm btn-relief-info add_header-padding-bt-10 ml-1 mb-1 uploadbutton add_header-padding-y6 business_mar_view-2"  :data-id="index" v-on:click.stop.prevent="onUploadSubTask($event, index, 'add')" > Upload</button>
                                            <div class="avatar btn-relief-success rounded ml-0 mb-1 btn_over_cursor_pointer">
                                                <div class="avatar-content_addbtn add_header-padding-y6 business_mar_view-2" @click="addFieldSubTask(doclist, docs)">
                                                    <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                    Add Document
                                                    
                                                </div>
                                            </div>
                                            <div class="avatar bg-danger rounded ml-1 mb-1 business_mar_view-2" :class="[{display:isActive}]">
                                                <div class="avatar-content " @click="removeField(index, docs)">
                                                    <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                </div>
                                            </div>                               
                                        </div>
                                        
                                        <div class="form-group col-md-12 p-md-1">
                                            <table v-if="doclist.attachment" class="table table-sm table-bordered" aria-describedby="Task Master">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Document Name</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ doclist.attachment.name }}</td>
                                                        <td class="text-center">
                                                                <button type="button" class="btn btn-sm danger" @click="removeImageSubTask(index, 'add')">
                                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </fieldset>
                                  
                            </form>
                            </div>
                        </div>
                        <div class="form-group d-flex flex-wrap mb-2 mx-2" >
                            <button  type="button" class="btn btn-relief-primary mr-1"  data-dismiss="modal" v-on:click.stop.prevent="saveSUbTAskDoc('add')" :disabled="isdisabled">Save</button>
                            <button type="button" class="btn btn-outline-secondary"  @click="docsuploadcollapse">Cancel</button>
                        </div>
                    </div>

                </div> 
                <div v-bind:class="[{show: isEditDocsRequestActive},card,border_warning,card_content,collapse]"  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                    <div class="card-header px-0" style="cursor:pointer" >
                        <div class="container-fluid">
                            <div class="row">
                                <div class="px-1 card-header w-100 text-left" style="border-bottom: 1px solid #ddd;justify-content: initial;padding-bottom: 10px;">
                                    <div class="avatar bg-light-primary rounded d-inline-flex" >
                                        <div class="avatar-content">
                                            <edit-2-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex text-primary ml-1" >Edit Upload Business Task Documents: {{this.tasktype.name}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="moduledocs.length" style="display:none">
                    <div v-bind:class="[{show: isEditDocsRequestActive},card_content,collapse]" v-for="(pdata, index) in projectlistofbusiness" :key="`projectlistofbusiness-${index}`" >
                        <div class="card-body">
                            <form>
                                <fieldset class="fieldset_border_vi mb-2">
                                    <legend>Document List</legend>
                                <div class="row">
                                    <div class="form-group col-md-3">
                                        <label class="form-label" for="project-name">Project Name {{index+1}} </label>
                                        <input class="form-control" type="text" placeholder="Project Name " :disabled="isprojectSelected" v-model="pdata.projectname"  />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label" for="redmineid">Project Module Name </label>
                                        <input class="form-control" type="text" placeholder="Project Module Name" :disabled="isprojectmoduleSelected" v-model="pdata.projectmodulename" />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="form-label" for="redmineid">Project Module Priority </label>
                                        <input class="form-control" type="number" placeholder="Project Module Priority" v-model="pdata.modulepriority" v-if="loginpersonname==editRowData.productstackholder" :disabled="false"/>
                                        <input class="form-control" type="number" placeholder="Project Module Priority" v-model="pdata.modulepriority" v-else :disabled="true"/>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="Department">Project Module Status</label>
                                        <treeselect placeholder="Select Project Module Status" class="projectmdllistdd capitalisetext" :options="taskstatus" v-model="pdata.modulestatus"/>
                                    </div>
                                </div>
                                <div  class="text-right  rounded w-100">
                                    <button v-if="pagefeatures.producttask" type="button" class="btn btn-relief-success" :id="'submitbtnt2'+index" @click="redirecttourl('create',pdata)" href="javascript:void(0);" ><plus-circle-icon size="1.5x" class="custom-class mr-50"></plus-circle-icon>Add Task</button>
                                    <div class="avatar btn-relief-primary rounded ml-1" v-if="pdata.modulestatus =='Product Pipeline' || pdata.modulestatus =='Product WIP' || pdata.modulestatus =='Product Completed' || pdata.modulestatus =='Product WIP 2'">
                                        <div class="avatar-content_addbtn add_header-padding-y6 business_mar_view-margin-2_add" @click="addModuleFileField(pdata, pdata.addNewModuleDocs, 'moduledocs')">
                                            <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon> Add Document </div>
                                    </div>
                                </div>
                              <hr/>
                            
                                <div class="row" v-for="(editdoclist, index) in tasktype.documentlist.filter(item => item.projectid == pdata.projectid && item.projectmoduleid == pdata.projectmoduleid)" :key="`phoneInput1-${index}`">
                                
                                <div class="form-group col-md-3" >
                                    <label class="form-label">Title </label>
                                    <input class="form-control" type="text" placeholder="Document Title" v-model="editdoclist.title"/>
                                    <div class="invalid-feedback">Document title is required</div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label">Description</label>
                                    <textarea class="form-control"  rows="2" placeholder="Task Description" v-model="editdoclist.description"></textarea>
                                    <div class="invalid-feedback">Document description is required</div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label">Comment</label>
                                    <textarea class="form-control" rows="2" placeholder="Comment" v-model="editdoclist.comment"/>
                                    <div class="invalid-feedback">Comment is required</div>
                                </div>
                                <div class="form-group col-md-3" style="margin-top:27px">
                                    <label for="attachment" class=" btn btn-relief-success mb-0 business_mar_view-margin-2">Browse</label>
                                    <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelect($event) "/>
                                    <div class="avatar bg-light rounded business_mar_view-margin-2">
                                        <button type="button" class="btn btn-relief-primary" :id="'submitbtnt'+index" data-dismiss="modal" v-on:click.stop.prevent="UpdateDocument(editdoclist, 'modulefilesupdate')" v-if="pdata.modulestatus =='Product Pipeline' || pdata.modulestatus =='Product WIP' || pdata.modulestatus =='Product Completed' || pdata.modulestatus =='Product WIP 2'">Update</button>
                                    </div>
                                    <div class="avatar bg-info rounded business_mar_view-margin-2">
                                        <div class="avatar-content">
                                            <a @click="getDownloadedLink(editdoclist)" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white"></download-icon></a>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="row"  v-for="(doclist, idx) in pdata.addNewModuleDocs" :key="`addNewModuleDocs-${idx}`">
                                    <div class="card-body">
                                        <form>
                                            <div class="row">
                                                <div class="form-group col-md-3" >
                                                    <label class="form-label">Document Title </label>
                                                    <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title"/>
                                                    <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="form-label">Description</label>
                                                    <textarea class="form-control"  rows="1" placeholder="Description" v-model="doclist.desc" ></textarea>
                                                    <div class="errorinputmsg" v-if="!doclist.desc">Description is required</div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="form-label">Comment</label>
                                                    <textarea class="form-control" rows="1" placeholder="Comment" v-model="doclist.comment" />
                                                    <div class="errorinputmsg" v-if="!doclist.comment">Comment is required.</div>
                                                </div>
                                                <div class="form-group col-md-3" style="margin-top:25px">
                                                    <label for="attachment" class="btn btn-relief-success mb-0 business_mar_view-margin-2">Browse</label>
                                                    <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelect($event) "/>
                                                    <button class="btn btn-relief-info uploadbutton business_mar_view-margin-2" :data-id="idx" v-on:click.stop.prevent="onUpload($event,idx, pdata.addNewModuleDocs, 'addNewModuleDocs')"> Upload</button>
                                                    <div class="avatar bg-danger rounded business_mar_view-margin-2" :class="[{display:isActive}]">
                                                        <div class="avatar-content" @click="removeAddNewDocsField(idx, pdata.addNewModuleDocs)">
                                                            <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                        </div>
                                                    </div>                               
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </fieldset>
                            </form>
                        </div>
                    </div>
                    <div class="form-group d-flex flex-wrap mb-2 px-2" >
                        <button type="button" class="btn btn-relief-primary mr-1" v-on:click.stop.prevent="addNewModuleDocsToDB()">Submit</button>
                        <button type="button" class="btn btn-outline-secondary" @click="handleCancel2()">Cancel</button>
                    </div>
                    </div>
                    <div>
                        <div v-bind:class="[{show: isEditDocsRequestActive},card_content,collapse]" >
                        <div class="card-body">
                            <form>
                                <div class="row" v-for="(editdoclist, index) in tasktype.documentlist" :key="`phoneInput1-${index}`">
                                <div class="form-group col-md-3" >
                                    <label class="form-label">Title </label>
                                    <input class="form-control" type="text" placeholder="Document Title" v-model="editdoclist.title"/>
                                    <div class="invalid-feedback">Document title is required</div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label">Description</label>
                                    <textarea class="form-control"  rows="2" placeholder="Task Description" v-model="editdoclist.description"></textarea>
                                    <div class="invalid-feedback">Document description is required</div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="form-label">Comment</label>
                                    <textarea class="form-control" rows="2" placeholder="Comment" v-model="editdoclist.comment"/>
                                    <div class="invalid-feedback">Comment is required</div>
                                </div>
                                <div class="form-group col-md-3" style="margin-top:25px">
                                    <label for="attachment" class="btn btn-relief-success mb-0" style="    margin-right: 5px;">Browse</label>
                                    <input  type="file" id="attachment" hidden :accept="accepttype"  ref="file" @change="onSelect($event) "/>
                                    <div class="avatar btn btn-relief-info rounded btmview-btpd-0">
                                        <div class="avatar-content" @click="addModuleFileField(null, addNewDocs,'addnewdocs')">
                                            <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                        </div>
                                    </div>
                                    <div class="avatar bg-danger btmview-btpd-0 rounded " v-if="!editdoclist.presignedUrl">
                                         <div class="avatar-content" @click="removeEditField(index,tasktype.documentlist,editdoclist)">
                                            <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                        </div>
                                    </div>
                                    <div class="avatar btn-relief-info rounded btmview-btpd-0" v-if="editdoclist.businessdocmasterid">
                                        <div class="avatar-content">
                                            <a @click="getDownloadedLink(editdoclist)" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor text-white"></download-icon ></a>
                                        </div>
                                    </div>
                                    <div class="avatar bg-light rounded btmview-btpd-0">
                                        <button type="button" class="btn btn-relief-primary  " :id="'submitbtnt'+index" data-dismiss="modal" v-on:click.stop.prevent="UpdateDocument(editdoclist)">Update</button>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 p-md-1">
                                    <table v-if="editdoclist.documentname" class="table table-sm table-bordered" aria-describedby="Task Master">
                                        <thead>
                                            <tr>
                                                <th scope="col">Document Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ editdoclist.documentname | basename}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="form-group col-md-12 p-md-1">
                                    <table v-if="files.length>0" class="table table-sm table-bordered" aria-describedby="Task Master">
                                        <thead>
                                            <tr>
                                                <th scope="col">New Document Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ files[index].name }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                                <div class="row"  v-for="(doclist, idx) in addNewDocs" :key="`addnewdocs-${idx}`">
                                    <div class="card-body">
                                        <form>
                                            <div class="row">
                                                <div class="form-group col-md-3" >
                                                    <label class="form-label">Document Title </label>
                                                    <input class="form-control" type="text" placeholder="Document Title" v-model="doclist.title"/>
                                                    <div class="errorinputmsg" v-if="!doclist.title">Document title is required</div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="form-label">Description</label>
                                                    <textarea class="form-control"  rows="1" placeholder="Description" v-model="doclist.desc" ></textarea>
                                                    <div class="errorinputmsg" v-if="!doclist.desc">Description is required</div>
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label class="form-label">Comment</label>
                                                    <textarea class="form-control" rows="1" placeholder="Comment" v-model="doclist.comment" />
                                                    <div class="errorinputmsg" v-if="!doclist.comment">Comment is required.</div>
                                                </div>
                                                <div class="form-group col-md-3" style="margin-top:25px">
                                                    <label :for="'attch'+idx" class="btn btn-relief-success mb-0" style="margin-right: 10px;">Browse </label>
                                                    <input  type="file" :id="'attch'+idx" hidden :accept="accepttype"  ref="file" @change="onSelectSubTask($event, idx, 'update') "/>
                                                    <button class="avatar btn btn-relief-primary  uploadbutton" :data-id="idx" v-on:click.stop.prevent="onUploadSubTask($event, idx, 'update')" style="margin-right:5px;"> Upload</button>
                                                    <div class="avatar btn-relief-info rounded btmview-btpd-0">
                                                        <div class="avatar-content" @click="addFieldSubTask(doclist, addNewDocs, 'edit')">
                                                            <plus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></plus-square-icon>
                                                        </div>
                                                    </div>
                                                    <div class="avatar bg-danger rounded btmview-btpd-0" :class="[{display:isActive}]">
                                                        <div class="avatar-content" @click="removeAddNewDocsField(idx, addNewDocs)">
                                                            <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                                        </div>
                                                    </div>                               
                                                </div>

                                                <div class="form-group col-md-12 p-md-1">
                                                    <table v-if="doclist.attachment" class="table table-sm table-bordered" aria-describedby="Task Master">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Document Name</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{{ doclist.attachment.name }}</td>
                                                                <td class="text-center">
                                                                        <button type="button" class="btn btn-sm danger" @click="removeImageSubTask(idx, 'update')">
                                                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="form-group d-flex flex-wrap mb-0" >
                                <button v-if="addNewDocs.length" type="button" class="btn btn-relief-primary mr-1" v-on:click.stop.prevent="saveSUbTAskDoc('update')" :disabled="addNewDocs.every(item => item.isUploaded === false)">Submit</button>
                                    <button type="button" class="btn btn-outline-secondary" @click="handleCancel2()">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div> 
                <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                         <div class="modal-header">
                            <h4 class="model-title" for="tstatus" style="margin-left: 200px;">Enter the Estimation hours in Tech WIP</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                        <div class="row">
                            <form class="form form-vertical">
                                <div class="form-group col-12">
                                    <label class="form-label" for="tstatus">Please enter the effort estimation required for this task (Development efforts + QA efforts + external dependency if any</label>
                                </div>
                                <div class="row mx-0">
                                    <div class="form-group col-md-4">
                                        <label for="">Estimation hours</label>
                                        <input id="swal-input1" class="form-control" v-model="estimatehrs" type="000:00" for="number" @blur="validateHHHMM(estimatehrs)" placeholder="Please enter efforts in hours" >
                                        <div class="errorinputmsg" v-if="this.errors.validestimatehrs" style="margin-top: 20px;">{{ this.errors.validestimatehrs }}</div>
                                    </div>
                                    <div class="form-group datePicker col-md-4" v-if="this.isLogActive==true">
                                        <label for="">Start Date</label>
                                        <date-picker placeholder="Select Start Date" v-model="logtime.startdate" valueType="format" class="startdate" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeToday"></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.startdate">{{ this.errors.startdate }}</div>
                                    </div>
                                    <div class="form-group datePicker col-md-4" v-if="this.isLogActive==true">
                                        <label for="">Estimated End Date</label>
                                        <date-picker placeholder="Select Estimated End Date" v-model="logtime.enddate" valueType="format" class="startdate" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeTodaydate"></date-picker>
                                        <div class="errorinputmsg" v-if="this.errors.enddate">{{ this.errors.enddate }}</div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12 flex--items">
                                    <input type="checkbox" id="accept" v-model="selcheckbox"/>
                                    <label class="form-label col-md-lg-12" style="margin-left: 10px;">Hereby I acknowledge that efforts entered are best of my knowledge and experience of engineering manager and projects leads on story points and efforts estimation</label>           
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.isChecked" style="margin-left: 10px;">{{ this.errors.isChecked }}</div>

                                <div class="form-group d-flex flex-wrap mt-1 mb-0 flex--items" v-if="this.isLogActive==false">
                                    <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" :disabled="!this.validateHHHMM(this.estimatehrs) || !this.isChecked()" v-on:click.stop.prevent="saveestimatedhrs($event)">Submit</button>
                                    <button type="reset" class="btn btn-outline-secondary" @click="closeModal()">Cancel</button>
                                </div>
                                <div class="form-group d-flex flex-wrap mt-1 mb-0 flex--items" v-else-if="this.isLogActive==true">
                                    <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" :disabled="!this.validateHHHMM(this.estimatehrs) || !this.isChecked() || !this.logtime.startdate || !this.logtime.enddate" v-on:click.stop.prevent="saveestimatedhrs($event)">Submit</button>
                                    <button type="reset" class="btn btn-outline-secondary" @click="closeModal()">Cancel</button>
                                </div>
                            </form>
                        </div>
                        </div>    
                    </div>
                    </div>
                 </div>
            </section>
        </div>           
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Trash2Icon,MonitorIcon,PlusIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,EditIcon,SearchIcon,MoreVerticalIcon,Edit2Icon,PackageIcon,PieChartIcon,
ClipboardIcon,EyeIcon,UploadCloudIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,CodesandboxIcon,PenToolIcon, PaperclipIcon, WatchIcon,FileTextIcon, ZoomInIcon, XIcon, PlusCircleIcon,UserPlusIcon} from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import presignurl from '../getPresignurl.vue';
import profilepic from '../profilepic.vue';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import BTCProgressBar from './BTCProgressBar.vue';
import commonMethods from '../../utils/commonMethods';
import BTCtypetext from "../constanttemplates/BTCtypetext";
import BTCcontexttext from "../constanttemplates/BTCcontexttext";

export default {
    name:'BTMView',
    components:{
        VueTimepicker,
        Loading,PlusSquareIcon,DownloadIcon,EyeIcon,UploadCloudIcon,CodesandboxIcon,PenToolIcon ,PackageIcon,
        VueElementLoading,MoreVerticalIcon,Edit2Icon,MinusSquareIcon,ClipboardIcon,
        vSelect,PlusIcon,CodepenIcon,PocketIcon,CalendarIcon,UserIcon,SettingsIcon,MonitorIcon,
        EditIcon,PlusCircleIcon,PieChartIcon,
        Treeselect,
        DatePicker,
        SearchIcon,
        Pagination,
        PaperclipIcon, WatchIcon,
        ckeditor: CKEditor.component,presignurl,
        FileTextIcon,UserPlusIcon,
        profilepic,ZoomInIcon,XIcon,
        Trash2Icon,BTCProgressBar, BTCtypetext, BTCcontexttext
    },
    mixins: [ commonMethods ],

    data() {
        return {
            securitylist: [
                { id: 1, label: 'Yes'},
                { id: 0,label: 'No'},
            ],
            parentflagg:null,
            attachmentviewParentBtc : [],
            defaultimagepath:apiUrl.defaultimagepath,
            statusconfig:apiUrl.statusconfig,
            accepttype:apiUrl.uploadfiletype,
            activePageFlag: null,
            prevarrlen: null,
            editmoduledocs:[],
            vieweditmoduledocs:[],
            projectmultiplename: [],
            multidimensionalArray: [],
            finalmultidimensionalArray: [],
            finalvalidationArray: [],
            isprojectSelected: true,
            isprojectmoduleSelected: true,
            viewdoclist:[],
            myfiles: [],
            subtaskfiles: [],
            selcheckbox:null,
            validestimatehrs:true,
            isinputtechwiphrs:false,
            estimatehrs: null,
            componentKey: 0,
            tasklistofbusiness: [],
            issuelistofbusiness: [],
            blockertaskslistofbusiness: [],
            rowsPerPage: 3,
            imgExt:['jpeg','jpg','png'],
            businesstaskcodelistopts:null,
            undt:null,
            isSearchRequestActive:false,
            istrue:false,
            show:'display',
            isdisabled:false,
            isActive:true,
            isLoading: false,
            fullPage: true,
            companymasterlist: [],
            tasktypelist: [],
            typenamelist: [],
            projectlist: [],
            taskassignmentList:[],
            showAddForm:false,
            selected: null,
            editRowData:[],
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            projectType:[],
            projectdescription:'',
            tasklevel:apiUrl.tasklevel,
            tasktypekeylist:apiUrl.tasktypekeylist.sort((a, b) => a.label.localeCompare(b.label)),
            requesterlist: [],
            requesterhodlist: [],
            readmore:'outer',
            ssreadmore:'outer',
            tasktype:{
                startdateaeging: null,
                edddeliverable: null,
                edddelayed: null,
                security:null,
                securitycomment: null,
                securitytag: null,
                requester:null,
                requester_hod:null,
                requestername:null,
                requester_hodname:null,
                projectist:null,
                ehrs:null,
                oldstatus:null,
                name:null,
                edit:false,
                projectid:null,
                subscriberslist: [],
                taskmanagerlist: [],
                taskownerlist:[],
                subscribers: null,
                projectmodules: null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                desc:null,
                level:null,
                descerror:null,
                levelerror:null,
                type: null,
                key: null,
                pmlist: null,
                emlist: null,
                proman: null,
                proglist: null,
                progman: null,
                engman: null,
                businessnamelist: null,
                businessname: null,
                redmineid: null,
                btmpriority: null,
                typelist:apiUrl.typelist,
                admintypelist:apiUrl.admintypelist,
                keylist:apiUrl.keylist,
                referenceslist:apiUrl.referenceslist,
                references: null,
                bsh: null,
                psh: null,
                taskmanager: null,
                taskowner: null,
                priorityerr: null,
                dept: null,
                depterr: null,
                deptlist:apiUrl.deptlist.sort((a, b) => a.label.localeCompare(b.label)),
                startdate: null,
                enddate: null,
                edd_reason: null,
                tstatus:null,
                isedit:false,
                taskid:null,
                lastmodifiedby:null,
                lastmodifieddate:null,
                subpriority: null,
                bshlist: [],
                businesstaskcode: null,
                project_success_criteria:null,
                project_fail_criteria:null,
                project_assumptions:null,
                project_roi:null,
                business_impact:null,
                impact_of_live_feature:null,
                totaltime: null,
                duedate: null,
                projectmoduleid: null,
                projectmodulelist:[],
                projectmodulename: null,
                modulepriority: '',
                modulestatus: null,
                taskestimates: null,
                createdby: null,
                qalead: null,
                qaleadlist:[],
                qamanager:null,
                qamnglist:[],
                billingcountry:null,
                billingcountrylist:apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
                businesscontextList:apiUrl.businesscontextList.sort((a, b) => a.label.localeCompare(b.label)),
                businesscontext:null,
                documentlist:[{
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:'',
                    projectname:'',
                    projectmodulename:'',
                    projectid:null,
                    projectmoduleid: null,
                    pdata:[{
                        projectname: '',
                        projectmodulename: '',
                        btmpriority: '',
                        tstatus:'',
                        projectid:'',
                        projectmoduleid: null,
                        isprojectSelected: true,
                        isprojectmoduleSelected: true,
                        modulepriority: null,
                        modulestatus: null
                    }],
                }],
            },
            view:{
                taskestimates: null,
                isapproved_edd: null,      
                updated_edd: null,
                plannertitle: null,  
                securitycomment: null,
                securitytag: null,
                plannerid: null,
            },
            tasknamemaster:apiUrl.tasknamemaster,
            taskstatus: [],
            taskBlured:false,
            valid : false, 
            projvalue:null,
            applvalue:null,
            applicoptions:[ {
                id: '',
                label: ''
            }],
            startdate:null,
            errors: {
                isChecked:null,
                validestimatehrs:null,
                spenttime:"",
                activity:null,
                status:null,
                comment: null,
                duedate: null,
                startdate: null,
                enddate: null,
                edd_reason: null,
                securitycomment: null,
                securitytag: null,
                taskestimates: null,
                tasktype:{
                    securitytag: null,
                    name:null,
                    projectid:null,
                    desc:null,
                    level:null,
                    descerror:null,
                    levelerror:null,
                    type: null,
                    key: null,
                    redmineid: null,
                    btmpriority: null,
                    references: null,
                    bsh: null,
                    psh: null,
                    priorityerr: null,
                    dept: null,
                    depterr: null,
                    startdate: null,
                    enddate: null,
                    tstatus:null,
                    createdby:null,
                    businesstaskcode:null,
                    subscribers: null,
                    project_success_criteria:null,
                    project_fail_criteria:null,
                    project_assumptions:null,
                    project_roi:null,
                    business_impact:null,
                    impact_of_live_feature:null,
                    businessname:null,
                    taskmanager: null,
                    taskowner:null,
                    duedate: null,
                    taskestimates: null,
                    projectmodules: null,
                    progman: null,
                },
                docs:{
                    title:null,
                    docs:null,
                    comment:null,
                    attachment:null
                } 
            },
            tasklevellist:[ {
                id: '',
                label: ''
            }],
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            projectnameist:[],
            loginuscondition:'',
            attachment: [],
            attachmentview: [],
            docdetails:[],
            docs:[
                {
                    title:'',
                    docs:'',
                    desc: '',
                    comment:'',
                    attachment:''
                }    
            ],
            doclist:[],
            editdoclist:[],
            file: '',
            alldocs:[],
            isDetActive:false,
            isRequestActive:false,
            disRequestActive:true,
            isdocsRequestActive:false,
            isEditDocsRequestActive: false,
            card_content:'card-content',
            collapse:'collapse',
            files:[],
            loginusername:'',
            addocument:true,
            editdocument: true,
            card:'card',
            border_warning:'border-warning',
            editor: ClassicEditor,
            editorConfig: {
               toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertTable',
                        '|',
                        'undo',
                        'redo', '|',
                    ],
                },
            },
            formActionLabel: 'Create Business Task',
            backpagedata:[],
            listActive:true,
            dislogActive: true,
            disBlockerActive: false,
            isLogActive: false,
            logtime:{
                acivitylist:apiUrl.acivitylist,
                spenttime:"",
                activity:null,
                status:null,
                comment:null,
                attachmentview:[],
                isTimeLogged: false,
                duedate: null,
                startdate: null,
                enddate: null,
                taskestimates: null,
                securitycomment: null,
                securitytag: null,
            },
            loginpersonname:'',
            logtimehistory: [],
            addLogDoc: true,
            docLogReq: true,
            logattachment: null,
            imagext: ['jpg', 'jpeg', 'png'],
            tokendata:null,
            workstepOfBusiness:[],
            project_success_criteriaBlured:null,
            project_fail_criteriaBlured:null,
            project_assumptionsBlured:null,
            project_roiBlured:null,
            business_impactBlured:null,
            impact_of_live_featureBlured:null,
            productstackholder:null,
            projectlistofbusiness: [],
            pdata:[
                {
                    projectname: '',
                    projectmodulename: '',    
                    modulepriority: null,
                    modulestatus: null  ,
                }
            ],
            moduledocs:[],
            taskmodule:0,
            modulepriority:'',
            modulestatus:null,
            addNewDocs: [],
            estimateshours: apiUrl.estimateshours,
            taskstartdate: this.dateFormatnew(new Date()),
            isdisableds: false,
            isenableestimatedate: true,
            userrole:null,
            issubtask: false,
            isshow: null,
            havingsubtasks: false,
            activetabflag: localStorage.getItem('activebtctab'),
            t_totalcount: 0,
            t_pageCount: 0,
            t_page:1,
            t_pagelimit:apiUrl.LIMIT,
            t_currPageNum: 0,
            t_pageoffset:1,
            t_pagefeatures:[],
            t_showtabledata:true,
            p_totalcount: 0,
            p_pageCount: 0,
            p_page:1,
            p_pagelimit:apiUrl.LIMIT,
            p_currPageNum: 0,
            p_pageoffset:1,
            p_pagefeatures:[],
            p_showtabledata:true,
            b_totalcount: 0,
            b_pageCount: 0,
            b_page:1,
            b_pagelimit: apiUrl.LIMIT,
            b_currPageNum: 0,
            b_pageoffset:1,
            b_pagefeatures:[],
            b_showtabledata:true,
            btmtasktypelist: [],
            isoldbtc: null,
            oldBTCFlagDate: apiUrl.oldBTCFlagDate,
            showUploaddedFIles : [],
            blockertaskdetails: {
                tasklabel: null,
                projectid: null,
                btcprojlist: [],
                tasklabelid: null,
                tasklabelpid: null,
                comment: null,
                status: null,
                blockerbtcs: null,
                moduletype: null,
                errors: {
                    comment: null,
                    status: null,
                    btcItemsErr: null,
                    blockerbtcs: null,
                },
                blockertypes: [
                { id: 'Blocks', label: 'Blocks' },
                { id: 'Is Blocked By', label: 'Is Blocked By' },
                { id: 'Clones', label: 'Clones' },
                { id: 'Is Cloned By', label: 'Is Cloned By' },
                { id: 'Duplicates', label: 'Duplicates' },
                { id: 'Is Duplicated By', label: 'Is Duplicated By' },
                { id: 'Relates To', label: 'Relates To' }
            ],
        },
        tasksblockerlist: [],
        tasklistoOrderBy : [
                {id: 'status', label:'status'},
                {id: 'project', label:'project'},
                {id: 'assignee', label:'assignee'},
                {id: 'asginor', label:'asignor'}
            ],
            issuelistoOrderBy : [
                {id: 'status', label:'status'},
                {id: 'priority', label:'priority'},
                {id: 'project', label:'project'},
                {id: 'assignee', label:'assignee'},
                // {id: 'asginor', label:'asignor'}
            ],
            blokerlistoOrderBy : [
                {id: 'status', label:'status'},
                {id: 'priority', label:'priority'},
                {id: 'project', label:'project'},
                {id: 'type', label:'type'},
                {id: 'name', label:'name'},
            ],
            orderBy: {
                task: null,
                issue: null,
                bloker: null,
            },
            searchTerm: null,
            tasksearch: false,
            statuslist:apiUrl.status,
            searchIssue: null,
            issuesearch : false,
            issuestatuslist: apiUrl.issueStatuslist,
            searchBlocker: null,
            blockersearch : false,
            blockerlist : apiUrl.blockertypes,
            parentTaskData: [],
            subtasksearch : false,
            subtasklist: [],
            s_totalcount: 0,
            s_pageCount: 0,
            s_page:1,
            s_pagelimit:apiUrl.LIMIT,
            s_currPageNum: 0,
            s_pageoffset:1,
            s_pagefeatures:[],
            s_showtabledata:true,
            searchSubBtc: null,
            subbtcSearch: false,
            readonlyflds: {
                hodnamefld: false,
                taskmanagerfld: false,
                taskownerfld: false,
                promanagerfld: false,
                engmanagerfld: false,
                progmanfld: false,
                qamanagerfld:false,
                qaleadfld: false,
            },
            bid: [this.$route.query.businessid],
            testcyclesetlist: [],
            tcs_totalcount: 0,
            tcs_pageCount: 0,
            tcs_page:1,
            tcs_pagelimit:apiUrl.LIMIT,
            tcs_currPageNum: 0,
            tcs_pageoffset:1,
            tcs_pagefeatures:[],
            tcs_showtabledata:true,
            searchcycleset: null,
            testcyclesetsearch: false,
            securitytagchk: null,
            securitycommentBlured: false,
            extratime: null,
            btcdelayedstatus: null,
            ballparkvalitestatus: [],
            disableballpark: false,
            btclifecycle: [],
            techpipelinedateaeging:null,
        }
    },  
    mounted(){
        window.addEventListener('storage', function(event){
          if (event.key == 'logout-mee') { 
              window.location.reload();
          }
        });
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.loginusername=userdata.username
            this.loginpersonname=userdata.firstname+' '+userdata.lastname;
            this.loginuscondition = userdata.userid
            this.pagefeatures = {}
            this.taskmodule = window.localStorage.getItem("taskmodule")
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
                if (element.page === '/businesstask/list') {
                    this.pagefeatures[element.featurename] = element.featureaccess
                }
            })
            this.tasktype.deptlist = apiUrl.deptlist
            this.tasktype.typelist = apiUrl.typelist
            this.getApplicationdata('t24','task');
            this.getbusinesstasklistbyid(this.$route.query.businessid)
            this.tokendata = window.localStorage.getItem('token');
            // this.getProjectList()
            this.getBusinessRelatedWorkstep(this.$route.query.businessid)
            this.getSubscribers(this.$route.query.businessid, 'businesstask')
            this.getBusinessname();
            this.getBusinessSpenttime(this.$route.query.businessid);
            this.getProjectByBusinessTask(this.$route.query.businessid)
            // this.getProjectAllocatedToEmp();
            this.getstatusbarpercentage(this.$route.query.businessid);
            this.$nextTick(() => {
                const contentContainers = document.querySelectorAll(".content-container");
                contentContainers.forEach(function (container) {
                const content = container.querySelector(".content02");
                const readMoreBtn = container.querySelector(".read-more");
                const readLessBtn = container.querySelector(".read-less");

                readMoreBtn.addEventListener("click", function () {
                    readMoreBtn.style.display = "none";
                    readLessBtn.style.display = "block";
                    content.classList.add("read-remove");
                });

                readLessBtn.addEventListener("click", function () {
                    readMoreBtn.style.display = "block";
                    readLessBtn.style.display = "none";
                    content.classList.remove("read-remove");
                });
                });
            });
        }
    },
   
    methods:{
        selRequester(state) {
            if(state){
                this.getRequesterHODOnSearch(state);
            }else{
                this.tasktype.requester_hod = null;
            }
        },
        securityJustReadmore(){
            if ($("#ssjudtid").hasClass("readmore")) {
                $("#ssjudtidid5").html("Read More");
                $("#ssjudtid").removeClass("readmore");
                this.ssreadmore = 'outer'
            } else {
                $("#ssjudtidid5").html("Read Less");
                $("#ssjudtid").addClass("readmore");
                this.ssreadmore = 'outer readmore'
            }
        },
        idclick5(){
            
            if ($("#cycle_readmore5").hasClass("readmore")) {
                $("#id5").html("Read More");
                $("#cycle_readmore5").removeClass("readmore");
                this.readmore = 'outer'
            } else {
                $("#id5").html("Read Less");
                $("#cycle_readmore5").addClass("readmore");
                this.readmore = 'outer readmore'
            }
        },
        idclick2(index5){
            
            if ($("#cycle_readmore2"+index5).hasClass("readmore")) {
                $("#id2"+index5).html("Read More");
                $("#cycle_readmore2"+index5).removeClass("readmore");
                this.readmore = 'outer'
            } else {
                $("#id2"+index5).html("Read Less");
                $("#cycle_readmore2"+index5).addClass("readmore");
                this.readmore = 'outer readmore'
            }
        },
        hourcalculator(time1, time2) {
            if(time1 != undefined && time1 != null && time1 != '' && time1 != 0 
            && time2 != undefined && time2 != null && time2 != '' && time2 != 0) {
                function strToMins(t) {
                var s = t.split(":");
                return Number(s[0]) * 60 + Number(s[1]);
                }
    
                function minsToStr(t) {
                return Math.trunc(t / 60)+':'+('00' + t % 60).slice(-2);
                }
                let result = '0 Hrs';
                if((strToMins(time1) - strToMins(time2)) > 0) {
                    result = minsToStr( strToMins(time1) - strToMins(time2) );
                } 
                this.extratime =  result;
            } else {
                this.extratime =  '0 Hrs';
            }
        },
        getRequesterOnSearch(node) {
            if(node != null && node != '' && node.length > 4) {
                this.isLoading = true;
                let url = "api/master/getemployeebyname";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    empname: node ? node.toLowerCase() : null,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.requesterlist = result.data.data.rows;
                        let decryptdesignationtext = this.requesterlist.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                    } else {
                        this.requesterlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } else {
                this.requesterlist = [];
            }
        },
        getRequesterOnSearchDefault(id) {
            this.isLoading = true;
            let url = "api/master/getemployeebyname";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                empname: null,
                empids: [id]
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.requesterlist = result.data.data.rows;
                    let decryptdesignationtext = this.requesterlist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.requesterlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getRequesterHODOnSearch(id) {
            this.isLoading = true;
            let url = "api/listing/getRequesterOnSearch";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                requesterid: id,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.requesterhodlist = result.data.data.rows;
                } else {
                    this.requesterhodlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getspecificbtcstatus(status) {
            let url = "api/bt/getspecificbtcstatus";
            this.input = {
                useremail: this.undt.username,
                empcode: parseInt(this.undt.userid),
                modulestatus: 'Tech Pipeline',
                moduletype: 'Businesstask',
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if (result.data.errorCode == 0) {
                    this.ballparkvalitestatus = result.data.data.rows.statuslist;
                    if(this.ballparkvalitestatus && this.ballparkvalitestatus != null && this.ballparkvalitestatus != '') {
                        let arr = this.ballparkvalitestatus.split(',');
                        const found = arr.some(el => el == status);
                        if(found) {
                            if(this.editRowData.taskestimates != null && this.editRowData.taskestimates != ''){
                                this.disableballpark = true;
                            }
                        }
                    }
                } else {
                    this.ballparkvalitestatus = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getParentTaskDetails(moduleid) {
            if(moduleid){
                let url = "api/master/businesstask/getBTCellData";
                this.input = {
                    businesstaskmasterid:parseInt(moduleid),
                    useremail: this.undt.username,
                    empid:this.undt.userid,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.parentTaskData = result.data.data;
                    } else {
                        this.parentTaskData = [];
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        }, 
        clickCallback_tasklists: function (pageNum) {
            this.t_pageoffset = pageNum;
            this.t_currPageNum = pageNum - 1;

            this.getTaskListByBusinessTask(this.$route.query.businessid)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#card-navigation").offset().top -70
                });
            }, 500);
        },
        clickCallback_issueslist: function (pageNum) {
            this.p_pageoffset = pageNum;
            this.p_currPageNum = pageNum - 1;

            this.getIssueListByBusinessTask(this.$route.query.businessid)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#card-navigation").offset().top -70
                });
            }, 500);
        },
        clickCallback_blockerlist: function (pageNum) {
            this.b_pageoffset = pageNum;
            this.b_currPageNum = pageNum - 1;

            this.getBlockerListByBusinessTask(this.$route.query.businessid)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#card-navigation").offset().top -70
                });
            }, 500);
        },
        removeImage (itemindex, rowindex) {
            if(itemindex!=null && rowindex!=null) {
                this.vieweditmoduledocs[itemindex]['modulefiles'][rowindex]['tempfilename'] = null; 
                this.vieweditmoduledocs[itemindex]['modulefiles'][rowindex]['tempdoctitile'] = null; 
            }
        },
        onSelectDoc(event){
            this.myfiles = event.target.files;
            if(this.myfiles.length>0){
                this.isdisabled=true     
            }
        },
        removeImageSubTask (i, type) {
            if(i!=null) {
                if(type == 'update') {
                    this.addNewDocs[i].attachment = null;
                } else {
                    this.docs[i].attachment = null;
                }
            }
        },
        onSelectSubTask(event, index, type) {
            this.subtaskfiles = event.target.files;
            if(this.subtaskfiles.length>0){
                this.isdisabled=true     
            }
            if(type == 'update') {
                this.addNewDocs[index].attachment = this.subtaskfiles[0];                
            } else {
                this.docs[index].attachment = this.subtaskfiles[0];                               
            }
        },
        addFieldSubTask(value, fieldType, opt='add') {
            if(opt=='edit') {
                let row = fieldType[fieldType.length-1];
                if(row.businessdocmasterid == undefined) return false
            }            
            this.isActive=false
            fieldType.push({ attachment: "", comment: "", desc: "", docs: "", title: "" });
        },
        async onUploadSubTask(event, index, type) {    
            if(type == 'update') {
                this.docs = this.addNewDocs;
            }
            this.apiURL = "api/s3upload/uploadDocumentToS3";
            if(this.docs.length) {
                this.isLoading=true
                this.docs.forEach(file => {
                    if (file.attachment.size > 1024 * 1024 * 21) {
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 21mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false; 
                    } 
                    if(file.attachment.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file.attachment);
                        formData.append("empcode", this.undt.userid);
                        formData.append("useremail", this.undt.username);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file.attachment;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            this.subtaskfiles = [];
                            if (result.data.status) {
                                this.isdisabled= false
                                //this.docs[index].attachment = result.data.data.filename
                                this.docs[index].filename = result.data.data.filename
                                this.docs[index].batchid = result.data.data.batchid
                                this.docs[index].isUploaded = true
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        }).catch(e => {
                            this.displayError(e)
                        })
                    } 
                })
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "Select document",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }            
        },
        saveSUbTAskDoc(type) {
            if(type == 'update') {
                this.docs = this.addNewDocs;
            }
            this.input=[]
            let newlogattach = []
            let isValid = true;
            this.docs.forEach(vfile => {
                if((vfile.title == null || vfile.title == "") || (vfile.desc == null || vfile.desc == "") || (vfile.comment == null || vfile.comment == "") || (attachment == null || attachment == "")) {
                    isValid = false;
                }
            });
            if(isValid){
                this.isLoading = true;
                if(this.docs && this.docs.length){
                    for(let index in this.docs){
                        if(this.docs[index].filename) {
                            this.input.push({
                                documentid:this.docs[index].batchid,
                                documentname:this.docs[index].filename,
                                title:this.docs[index].title,
                                desc:this.docs[index].desc,
                                comment:this.docs[index].comment,
                                version:'v1',
                                createdby:this.undt.username,
                                taskid:this.tasktype.taskid,
                                empcode: this.undt.userid,
                                useremail: this.undt.username
                            })
                        }
                        newlogattach.push({
                            info: this.docs[index].desc,
                            link: this.docs[index].filename,
                        })
                    }
                    this.logattachment = { data: newlogattach }
                    if(this.input.length>0){
                        let url = "api/workstep/btmdocinsert";
                        axios({
                            method: "POST",
                            url: url,
                            data: {document:this.input,empcode: this.undt.userid,useremail: this.undt.username},
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading = false;
                            this.attachment=[]
                            if (result.data.errorCode == 0) {                                
                                let rdata = {
                                    moduleid:this.tasktype.taskid
                                }
                                if(type == 'update') {
                                    this.addNewDocsUploadCollapse();
                                } else {
                                    this.docsuploadcollapse();
                                }
                                this.getDocumetList(rdata)
                                this.getBTMDocdetails(this.tasktype.taskid)
                            } else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                            }else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Failed to upload document",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        }).catch(e => {
                            this.displayError(e)
                        });
                    }else{
                        this.isLoading = false;
                    }
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: "File not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading = false;
                }
            } else {
                Swal.fire({
                    title: "Failed",
                    text: "All Fields are compulsory",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading = false;
            }
        },
        async onUploadDoc(event, index, doclist, rowindex) {    
            this.apiURL = "api/s3upload/uploadDocumentToS3";
            if(this.myfiles.length) {
                this.isLoading=true
                this.myfiles.forEach(file => {
                    if (file.size > 1024 * 1024 * 21) {
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 21mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false; 
                    } 
                    if(file.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file);
                        formData.append("empcode", this.undt.userid);
                        formData.append("useremail", this.undt.username);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            this.myfiles=[];
                            if (result.data.status) {
                                this.isdisabled=false
                                doclist.attachment= result.data.data
                                doclist.isUploaded = true
                                this.vieweditmoduledocs[index]['modulefiles'][rowindex]['tempfilename'] = result.data.data;
                                this.vieweditmoduledocs[index]['modulefiles'][rowindex]['tempdoctitile'] = doclist.title;
                               
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        })
                    } 
                })
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "Select document",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }            
        },
        removeDocFile(docid, mid, pid){
            axios({
                method: "POST",
                url: "api/bt/deletePModuleDoc",
                data: { docid:docid, mid: mid, pid: pid, bid: this.$route.query.businessid,empcode: this.undt.userid,useremail: this.undt.username },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0) {
                    this.vieweditmoduledocs.forEach(async element => {
                        const objWithIdIndex = element.editedmodulefiles.findIndex((obj) => obj.businessdocmasterid === docid);
                        if (objWithIdIndex > -1) {
                          element.editedmodulefiles.splice(objWithIdIndex, 1);
                        }
                    });
                }
            });
        },
        getProjectByBusinessTaskEDIT(moduleids, bid, type, removedadded){
            if(!removedadded){
              this.vieweditmoduledocs = []
            }
            this.input = { midarr:moduleids, type: type, bid: parseInt(bid),empcode: this.undt.userid,useremail: this.undt.username }
            let url = "api/bt/getProModuleDetailsWithProject";
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                if(result.data.errorCode == 0) {
                    for(let i = 0; i < result.data.data.length; i++){
                        let editedDocs = [];
                        if(removedadded){
                            let reqdata = { mid:result.data.data[i].moduleid, bid: parseInt(bid), pid: result.data.data[i].pid,empcode: this.undt.userid,useremail: this.undt.username}
                            let url = "api/bt/getProModuleDocDetailsWithProject";
                            axios({
                                method: "POST", url: url, data: reqdata,
                                'headers':{'authorization':this.tokendata}
                            }).then(result1 => { 
                                if(result1.data.errorCode == 0) {
                                for(let doc=0; doc<result1.data.data.length; doc++){
                                    let singleeditedDocs = {
                                        'businessdocmasterid':result1.data.data[doc].businessdocmasterid,
                                        'documentname':result1.data.data[doc].documentname,
                                        'title':result1.data.data[doc].title,
                                        'docdescription':result1.data.data[doc].description,
                                        'comment':result1.data.data[doc].comment,
                                        'createdby':result1.data.data[doc].createdby,
                                        'updatedby':result1.data.data[doc].updatedby,
                                    }
                                    editedDocs.push(singleeditedDocs)
                                }
                               }
                            });
                        }
                        let moduleArr = {
                            'projectid':result.data.data[i].pid,
                            'projectname': result.data.data[i].pname,
                            'projectmoduleid': result.data.data[i].moduleid,
                            'projectmodulename': result.data.data[i].projectmodulename,
                            'modulestatus': result.data.data[i].modulestatus,
                            'modulepriority': result.data.data[i].modulepriority,
                            'projectmoduleid': result.data.data[i].projectmoduleid,
                            'moduleid':result.data.data[i].businesstaskmasterid,
                            'businesstaskcode':result.data.data[i].businesstaskcode,
                            'projectmodulename':result.data.data[i].projectmodulename,
                            'businesstaskmoduleassignmentid':result.data.data[i].businesstaskmoduleassignmentid,
                            'modulename':result.data.data[i].taskname,
                            'projectist':result.data.data[i].pid,
                            'projectnameist':result.data.data[i].pname,
                            'projectmoduleist':result.data.data[i].moduleid,
                            'projectmodulenamenameist':result.data.data[i].projectmodulename,
                            'createdby': result.data.data[i].createdby,
                            'productstackholder': result.data.data[i].productstackholder,
                             modulefiles: [],
                             editedmodulefiles: (editedDocs)?editedDocs:[],
                        }
                        this.vieweditmoduledocs.push(moduleArr)
                    }
                }
            })
        },
        getProjectByBusinessTaskUPDATE(moduleids, bid, type){
            this.input = { midarr:moduleids, type: type, bid: parseInt(bid),empcode: this.undt.userid,useremail: this.undt.username }
            let url = "api/bt/getProModuleDetailsWithProject";
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                if(result.data.errorCode == 0) {
                    for(let i = 0; i < result.data.data.length; i++){
                        let prevData = this.vieweditmoduledocs;
                        let moduleArr = {
                            'projectid':result.data.data[i].pid,
                            'projectname': result.data.data[i].pname,
                            'projectmoduleid': result.data.data[i].moduleid,
                            'projectmodulename': result.data.data[i].projectmodulename,
                            'modulestatus': result.data.data[i].modulestatus,
                            'modulepriority': result.data.data[i].modulepriority,
                            'moduleid':result.data.data[i].businesstaskmasterid,
                            'businesstaskcode':result.data.data[i].businesstaskcode,
                            'businesstaskmoduleassignmentid':result.data.data[i].businesstaskmoduleassignmentid,
                            'modulename':result.data.data[i].taskname,
                            'projectist':result.data.data[i].pid,
                            'projectnameist':result.data.data[i].pname,
                            'projectmoduleist':result.data.data[i].moduleid,
                            'projectmodulenamenameist':result.data.data[i].projectmodulename,
                            'createdby': result.data.data[i].createdby,
                            'productstackholder': result.data.data[i].productstackholder,
                            modulefiles: [],
                            editedmodulefiles: [],
                        }
                        this.vieweditmoduledocs.push(moduleArr)
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        isPresentInProductRoles() {
            let projectmanagerlist = this.tasktype.pmlist && this.tasktype.pmlist.length ? this.tasktype.pmlist.map(i => i.id.toLowerCase()) : []
            let taskmanagerlist =this.tasktype.taskmanagerlist && this.tasktype.taskmanagerlist.length ? this.tasktype.taskmanagerlist.map(i => i.id.toLowerCase()) : []
            let taskownerlist = this.tasktype.taskmanagerlist && this.tasktype.taskownerlist.length ? this.tasktype.taskownerlist.map(i => i.id.toLowerCase()) : []
            return (this.loginusername == this.editRowData.createdby || projectmanagerlist.includes(this.loginpersonname.toLowerCase()) || taskmanagerlist.includes(this.loginpersonname.toLowerCase()) || taskownerlist.includes(this.loginpersonname.toLowerCase()))
        },
        isAllModuleDocsUploaded() {
            let temparr = []
            for(let i=0; i<this.projectlistofbusiness.length; i++) {
                let addNewModuleDocs = this.projectlistofbusiness[i].addNewModuleDocs
                addNewModuleDocs.forEach(item => temparr.push(item.isUploaded))
            }
            return temparr.every(item => item === false)
        },
        isAllModuleDocsAdded() {
            let temparr = []
            for(let i=0; i<this.moduledocs.length; i++) {
                let module = this.moduledocs[i]
                let modulefiles = module.modulefiles
                modulefiles.forEach(item => temparr.push(item.isUploaded))
            }
            return temparr.every(item => item === false)
        },
        getSubscribers(moduleid, moduletype){
            this.isLoading = true;
            let url = "api/subscribers/getSubscribersByModuleid";
            this.input = { moduleid, moduletype,empcode: this.undt.userid,useremail: this.undt.username };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) { 
                    let decryptdepartmenttext = result.data.data.rows.map(items =>{
                    let tempemplabel1 = items.label;
                    let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                    if(tempemplabel2 && tempemplabel2 != null){
                       items.label = tempemplabel1 +'('+tempemplabel2 +')';
                    }
                    return items;
                    })
                    this.tasktype.subscribers = result.data.data.rows.map(d => d.id)
                } else {
                    this.tasktype.subscribers = null
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        isChecked(){
            let z = document.getElementById('accept')
            if(!z) return false 
            if(!z.checked){
                this.errors.isChecked = "Please accept terms and conditions"
            
            }else {
                this.errors.isChecked = ""
            }
            return z.checked
        },
        closeModal(){
            this.validestimatehrs = false
            this.estimatehrs = null
            this.logtime.startdate = null
            this.errors.startdate =null
            this.logtime.enddate = null
            this.errors.enddate = null
            $('#technologypopup').modal('hide');
        },
        forceRerender() {
            this.componentKey += 1
        },
        formatName(authorstring) {
            let author=authorstring.replace('@firstcry.com','')
            if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
            author = author.replace('.',' ')
            author = author.charAt(0).toUpperCase() + author.slice(1);
            return author
        },
        getbusinesstasklistbyid(moduleid){
            this.isLoading = true;
            let url = "api/master/taskmaster/getBusinessTaskByID";
            this.input = {
                businesstaskmasterid:moduleid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
                userrole : this.userrole
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0]
                    this.backpagedata=this.editRowData[0]
                    this.isshow = this.editRowData.isshow;
                    let createddate = this.dateFormatnew(this.editRowData.createddate); 
                    this.isoldbtc = (createddate > this.oldBTCFlagDate) ? false : true
                    this.issubtask = this.editRowData.parentbtcid == null ? false : true;
                    this.parentflagg = this.editRowData.parentbtcid == null ? 0 : 1;
                    this.getTaskListByBusinessTask(this.$route.query.businessid)
                    this.getIssueListByBusinessTask(this.$route.query.businessid)
                    this.getBlockerListByBusinessTask(this.$route.query.businessid)
                    this.getBTCsubTaskIds(this.$route.query.businessid)
                    this.gettestcyclesetlistbybtc(this.$route.query.businessid);
                    this.openEditModule(this.editRowData,4)
                    if(this.issubtask) {
                        this.getParentTaskDetails(this.editRowData.parentbtcid)
                    }
                } else {
                    this.editRowData = [];
                    this.backpagedata = [];
                    this.$router.push({ name: 'Business Task List', params: { search: true }});
                    Swal.fire({
                        title: "Failed!",
                        text: "UnAuthorised User!!",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBusinessRelatedWorkstep(businessTaskMasterid) {
            this.isLoading = true
            let url = "api/workstep/list";
            this.input = {
                businessTaskMasterid,
                uniqueid:this.undt.userid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.workstepOfBusiness = result.data.data.rows
                } else {
                    this.workstepOfBusiness = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        redirectToTcycleCreate(btnstate,rowdata) {
            rowdata.backbtnstate=btnstate
            this.$router.push({
                name: 'TestCycleSetCreate',
                path: '/testexecution/testcyclesetadd',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        redirectToTLogCreate(btnstate, rowdata) {
            rowdata.backbtnstate = btnstate
            this.$router.push({
                name: 'TaskCreate',
                path: '/tasks/create',
                params: {
                    backbtnstate:btnstate,
                    taskcreationdata:rowdata
                }
            })
        },
        redirecttoplan:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            
            this.$router.push({ 

                path: '/planner/viewplan?plannermasterid='+rowdata.moduleid,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
   
        redirecttourl:function(btnstate,idx){
            let rowdata = []
            rowdata.id = this.testcase.groupmappingid[idx]
            rowdata.backbtnstate = btnstate
            if(btnstate=="groupview"){
                let routeData = this.$router.resolve({ 
                    path: '/testcase/groupbulkView/?groupmappingid='+rowdata.id,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }
            else if (btnstate == 'view')
             { 
                this.$router.push({ 
                    path: '/project/summary?pid='+idx
                })
            }
        },
        redirectToWsCreate(btnstate, rowdata) {
            rowdata.backbtnstate=btnstate
            if(!rowdata.projectmoduleid) {
                rowdata.projectmoduleid = rowdata.projmodid
                rowdata.projectmodulename = rowdata.projmodname
            }
            this.$router.push({
                name: 'Work Step',
                path: '/workstep',
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
            })
        },
        redirectToIssueCreate(btnstate, rowdata) {
            rowdata.backbtnstate = btnstate
            this.$router.push({ 
                name: 'IssueCreate',
                path: '/issues/create',
                params: {
                    clickeddata: rowdata,
                    backbtnstate:btnstate,
                }
            })
        },
        redirectToITB(btnstate,rowdata) {
            if (btnstate == 'business') {
                let routeData = this.$router.resolve({ 
                    path: '/businesstask/view?businessid='+parseInt(rowdata.blockerid) ,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            } else if(btnstate == 'task') {
                this.$router.push({ 
                path: '/tasks/view?taskid='+parseInt(rowdata.blockerid),
                    params: {
                        backbtnstate:rowdata.tid,
                    }
                })
            } else {
                this.$router.push({ 
                path: '/issues/view?issueid='+parseInt(rowdata.blockerid),
                    params: {
                        backbtnstate:rowdata.iid,
                    }
                })                
            }
        },


        redirectToIssue(btnstate,issueid) {
            this.$router.push({ 
                path: '/issues/view?issueid='+issueid,
                params: {
                    backbtnstate:btnstate,
                }
            })
        },
        redirectToTask(btnstate,taskid) {
            this.$router.push({ 
                path: '/tasks/view?taskid='+taskid,
                params: {
                    backbtnstate:btnstate,
                }
            })
        },
        redirectToPMOTask:function(btnstate,editRowData){
            if(btnstate == 'pmocreate') {
                this.$router.push({
                    name: 'TaskCreate',
                    path: '/tasks/create',
                    params: {
                        isPMOCreate:btnstate,
                        rowdata:editRowData
                    }
                })
            }
        },

        selectTaskOrderBy(state){
            if(state == 'status'){
                this.getTaskListByBusinessTask(this.$route.query.businessid,state)
            }  else if(state == 'project') {
                this.getTaskListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'assignee') {
                this.getTaskListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'asginor') {
                this.getTaskListByBusinessTask(this.$route.query.businessid,state)
            }
        },
        inputsearchtask(){
            if (this.searchTerm !== null)
            this.tasksearch = true
            this.getTaskListByBusinessTask(this.$route.query.businessid)
        },
        getTaskListByBusinessTask(businesstaskid,state){
            this.isLoading = true;
            let url = "api/master/businesstask/getTaskListByBusinessTask";
            this.input = { businesstaskid ,moduletype:this.taskmodule,empcode: this.undt.userid,useremail: this.undt.username, issubtask: this.issubtask, isshow: this.isshow, orderBy: state};
            if(this.taskmodule == 1) {
                this.input.allottedto = parseInt(this.undt.userid)
                this.input.useremail = this.undt.username
            }
            this.input.limit = apiUrl.LIMIT
            this.input.offset = this.t_pageoffset
            this.input.labelname = this.labelname,
            this.input.taskcode = 'true'
            this.input.taskname = 'true'
            this.input.taskstatus = 'true'
            this.input.tasktype = 'true'
            this.input.taskproject = 'true'
            this.input.taskassignee = 'true'
            this.input.taskassignor = 'true'
            this.input.parenttaskcode = 'true'
            this.input.order = this.order
            this.input.searchTask
            this.input.searchStatus
            if(this.searchTerm) {

                let code = this.searchTerm.split('-')[0]
                    if (code == 'TK' || code == 'tk' || code == 'BTC' || code == 'btc'){
                            this.input.searchCode = this.searchTerm.toUpperCase()
                        } 
                        else {
                            this.input.searchCode = null
                            this.input.searchTask = this.searchTerm
                            this.input.searchTask = this.input.searchTask.toLowerCase()
                        }
            }

            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasklistofbusiness = result.data.data.rows
                    this.t_totalcount = result.data.data.count;
                    this.t_pageCount = Math.ceil(this.t_totalcount / this.t_pagelimit)
                } else {
                    this.tasklistofbusiness = []
                    this.t_totalcount = 0
                    this.t_pageCount =0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },

        getsorting(labelName) {
            let globalthis = this
            // $(function () {
            // $('table').on('click', 'th.bussiness', function () {
                let index = $(this).index(),
                rows = [],
                thClass = $(this).hasClass('asc') ? 'desc' : 'asc';
                let listinglbl = '';
                if($(this).closest('table').attr('id')  ==  'table_businessceo1') {
                    listinglbl = 'table_businessceo1';
                }
                 else {
                    listinglbl = 'table_businessceo1';
                }
                $('#'+listinglbl+ ' th').removeClass('asc desc');

                $(this).addClass(thClass);
                globalthis.labelname = labelName;
                
                rows.sort(function (a, b) {

                    let aValue = $(a).find('td').eq(index).text(),
                        bValue = $(b).find('td').eq(index).text();
                        
                    if (!isNaN(aValue) && !isNaN(bValue)) {
                        aValue = Number(aValue)
                        bValue= Number(bValue)
                    }

                    return aValue > bValue
                        ? 1
                        : aValue < bValue
                        ? -1
                        : 0;
                });
                this.ordering = !this.ordering;
                if(this.ordering){
                    globalthis.order='DESC'
                }  else {
                    globalthis.order='ASC'
                }
                if(globalthis.labelname == 'Task Code' || globalthis.labelname == 'Task Name' || globalthis.labelname == 'Task Status' || globalthis.labelname == 'Type'|| globalthis.labelname == 'Project Name'|| globalthis.labelname == 'Assignee' || globalthis.labelname == 'Assignor' || globalthis.labelname == 'Parent Task Code' || globalthis.labelname == 'BTC Code'){
                    globalthis.getTaskListByBusinessTask(globalthis.$route.query.businessid,globalthis.order)
                } else if(globalthis.labelname == 'Issue Code' || globalthis.labelname == 'Issue Name' || globalthis.labelname == 'Type' || globalthis.labelname == 'Status' || globalthis.labelname == 'Priority' || globalthis.labelname == 'Severity' || globalthis.labelname == 'IProject Name' || globalthis.labelname == 'Assignee' || globalthis.labelname == 'Issue BTC Code'){
                    globalthis.getIssueListByBusinessTask(globalthis.$route.query.businessid,globalthis.order)
                } else if(globalthis.labelname == 'BTC Code' || globalthis.labelname == 'BTC Name' || globalthis.labelname == 'BProject Name' || globalthis.labelname == 'Related To' || globalthis.labelname == 'BStatus'|| globalthis.labelname == 'Module Type'){
                    globalthis.getBlockerListByBusinessTask(globalthis.$route.query.businessid,globalthis.order)
                } else if(globalthis.labelname == 'Code' || globalthis.labelname == 'Name' || globalthis.labelname == 'Project Name' || globalthis.labelname == 'Status' || globalthis.labelname == 'Task Type'|| globalthis.labelname == 'Sub Priority'){
                    globalthis.getBTCsubTaskIds(globalthis.$route.query.businessid,globalthis.order)
                } else if(globalthis.labelname == 'Cycleset Code' || globalthis.labelname == 'Cycleset Name' || globalthis.labelname == 'Cyclseset Status' || globalthis.labelname == 'Test BTC Code' || globalthis.labelname == 'Group Code' || globalthis.labelname == 'Group Status'){
                    globalthis.gettestcyclesetlistbybtc(globalthis.$route.query.businessid,globalthis.order)
                }
                $.each(rows, function (index, row) {
                    $('#'+listinglbl+' tbody').append(row);
                });
            // });
            // });
        },
        selectListOrderBy(state){
            if(state == 'status'){
                this.getIssueListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'priority') {
                this.getIssueListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'project') {
                this.getIssueListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'type') {
                this.getIssueListByBusinessTask(this.$route.query.businessid,state)
            }
        },
        inputsearchissue(){
            if (this.searchIssue !== null)
            this.issuesearch = true
            this.getIssueListByBusinessTask(this.$route.query.businessid)
        },
        getIssueListByBusinessTask(businesstaskid,state){
            this.isLoading = true;
            let url = "api/master/businesstask/getIssueListByBusinessTask";
            this.input = { businesstaskid,empcode: this.undt.userid,useremail: this.undt.username, issubtask: this.issubtask,isshow: this.isshow ,orderBy: state, moduletype:this.taskmodule };
            this.input.limit = apiUrl.LIMIT
            this.input.offset = this.p_pageoffset
            this.input.order = this.order
            this.input.labelname = this.labelname,
            this.input.issuecode = 'true'
            this.input.issuename = 'true'
            this.input.issuetype = 'true'
            this.input.issuestatus = 'true'
            this.input.issuepriority = 'true'
            this.input.issueseverity = 'true'
            this.input.issueproject = 'true'
            this.input.issueassignee = 'true'
            this.input.searchIssueStatus
            this.input.searchCodeStatus
            this.input.searchIssueCode
            this.input.searchIssueName
            if (this.searchIssue) {
                let code = this.searchIssue.split('-')[0]
                if (code == 'IS' || code == 'is' || code == 'BTC' || code == 'btc'){
                    this.input.searchIssueCode = this.searchIssue.toUpperCase()
                } else {
                    this.input.searchIssueCode = null
                    this.input.searchIssueName = this.searchIssue
                    this.input.searchIssueName = this.input.searchIssueName.toLowerCase()
                }
            }

            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.issuelistofbusiness = result.data.data.rows
                    this.p_totalcount = result.data.data.count;
                    this.p_pageCount = Math.ceil(this.p_totalcount / this.p_pagelimit)
                } else {
                    this.issuelistofbusiness = []
                    this.p_totalcount = 0
                    this.p_pageCount =0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },

        selectBlockerOrderBy(state){
            if(state == 'status'){
                this.getBlockerListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'priority') {
                this.getBlockerListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'project') {
                this.getBlockerListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'type') {
                this.getBlockerListByBusinessTask(this.$route.query.businessid,state)
            } else if(state == 'name') {
                this.getBlockerListByBusinessTask(this.$route.query.businessid,state)
            }
        },
        inputsearchbloker(){
            if (this.searchBlocker !== null)
            this.blockersearch = true
            this.getBlockerListByBusinessTask(this.$route.query.businessid)
        },
        getBlockerListByBusinessTask(id,state){
            this.isLoading = true;
            let url = "api/tasks/getBlockerListByBusinessTask";
            this.input = { id, empcode: this.undt.userid, useremail: this.undt.username };
            this.input.limit = apiUrl.LIMIT
            this.input.offset = this.b_pageoffset
            this.input.order = this.order
            this.input.labelname = this.labelname,
            this.input.btccode = 'true'
            this.input.btcname = 'true'
            this.input.btcproject = 'true'
            this.input.btcrelatedto = 'true'
            this.input.btcstatus = 'true'
            this.input.btcmoduletype = 'true'
            this.input.relatedto
            this.input.blockercode
            this.input.name
            this.input.issubtask = this.issubtask;
            if (this.searchBlocker) {
                let code = this.searchBlocker.split('-')[0]
                    if (code == 'BTC' || code == 'btc' || code == 'TK' || code == 'tk' || code == 'IS' || code == 'is') {
                        this.input.blockercode = this.searchBlocker.toUpperCase()
                    } else {
                        this.input.blockercode = null
                        this.input.name = this.searchBlocker
                        this.input.name = this.input.name.toLowerCase()
                    }
            }

            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.blockertaskslistofbusiness = result.data.data.rows
                    this.b_totalcount = result.data.data.count;
                    this.b_pageCount = Math.ceil(this.b_totalcount / this.b_pagelimit)
                } else {
                    this.blockertaskslistofbusiness = []
                    this.b_totalcount = 0
                    this.b_pageCount = 0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectByBusinessTask(businesstaskid){
            this.isLoading = true;
            let url = "api/master/businesstask/getProjectByBusinessTask";
            this.input = { businesstaskid, empid: parseInt(this.undt.userid),useremail: this.undt.username,empcode: this.undt.userid };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    let d = result.data.data
                    this.projectlistofbusiness = d.map(item => {
                        return { ...item, addNewModuleDocs: []}
                    })
                    this.moduledocs=[]
                    for(let i=0;i< this.projectlistofbusiness.length;i++){
                        let singleDoc = this.projectlistofbusiness[i]
                        let moduleArr ={
                            'projectid':singleDoc.projectid,
                            'projectname':singleDoc.projectname,
                            'moduleid':singleDoc.moduleid,
                            'projectmoduleid': singleDoc.projectmoduleid,
                            'projectmodulename':singleDoc.projectmodulename,
                            'modulestatus':singleDoc.modulestatus,
                            'modulepriority':singleDoc.modulepriority,
                            'businesstaskmoduleassignmentid':singleDoc.businesstaskmoduleassignmentid,
                            "moduleid": singleDoc.moduleid,
                            "modulename": singleDoc.modulename,
                            "projectist": singleDoc.projectist,
                            "businesstaskcode": singleDoc.businesstaskcode,
                            "projectnameist": singleDoc.projectnameist,
                            "projectmoduleist": singleDoc.projectmoduleist,
                            "projectmodulenamenameist": singleDoc.projectmodulenamenameist,
                            "createdby": singleDoc.createdby,
                            "productstackholder": singleDoc.productstackholder,
                            businesstaskmasterid:singleDoc.businesstaskmasterid,
                            modulefiles: []
                        }
                        this.moduledocs.push(moduleArr)
                    }

                } else {
                    this.projectlistofbusiness = [];
                    this.moduledocs=[];
                }
            });
        },
        redirecttoprojectsummary:function(btnstate,rowdata){
            console.log(rowdata)
            rowdata.backbtnstate=btnstate
            this.$router.push({ 
                path: '/project/summary?pid='+rowdata[0],
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                },
                
            })
        },
        getBTMLogTimeHistory(moduleid) {
            this.isLoading = true;
            let url = "api/master/businesstask/getBTMLogTimeById";
            this.input = {
                moduleid,
                useremail: this.undt.username,
                empid: parseInt(this.undt.userid),
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.logtimehistory = result.data.data;
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                    this.logtimehistory=[]
                }
            });
        },
        getBTMDocdetails(moduleid) {
            this.isLoading = true;
            let url = "api/master/businesstask/getBTMDocdetails";
            this.input = {
                moduleid,
                useremail: this.undt.username,
                empid: parseInt(this.undt.userid),
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.docdetails = result.data.data;
                } else {
                    this.docdetails=[]
                }
            });
        },
        getstatusbarpercentage(moduleid) {
            this.isLoading = true;
            let url = "api/master/businesstask/getstatusbarpercentage";
            this.input = {
                moduleid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.btclifecycle = result.data.data.map(( item ) => ({ name: item.status, data: [Math.round(item.percennt)], time: this.convertMilosecondsIntoMin(item.time) }));
                    var options = {
                    series: this.btclifecycle,
                    chart: {
                        type: 'bar',
                        height: 200,
                        stacked: true,
                    },
                    colors:[
                    '#F74B60',
                    '#7865D0',
                    '#FEB12F',
                    '#3DA0A9',
                    '#FF7F50',
                    '#6495ED',
                    '#32CD32',
                    '#BA55D3',
                    '#4682B4',
                    '#8A2BE2',
                    '#20B2AA',
                    '#87CEFA',
                    '#FFD700',
                    '#DAA520',
                    '#008080',
                    '#FF6347',
                    '#40E0D0',
                    '#7B68EE',
                    '#00FF7F',
                    '#B22222',
                    '#FF4500',
                    '#32CD32',
                    '#800000',
                    '#ADFF2F',
                    '#FFD700'
                    ],
                    plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900,
                                colors: ['#fff'] 
                            }
                            }
                        }
                    },
                    },
                    stroke: {
                        width: 2,
                        colors: ['#fff']
                    },
                    title: {
                    text: 'BTC LIFECYCLE TIMELINE'
                    },
                    xaxis: {
                    categories: [, , , , , , ],
                    enabled: false,
                    labels: {
                        formatter: function (val) {
                        return val + " % "
                        }
                    }
                    },
                    yaxis: {
                    title: {
                        text: undefined,
                        enabled: false,
                    },
                    },
                    tooltip: {
                    enabled: true,
                    y: {
                        // formatter: function (val) { 
                        //     return val + "%"
                        // }
                        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                            return w.config.series[seriesIndex].time; // Accessing dynamic text from series name
                        }
                    }
                    },
                    fill: {
                    opacity: 1
                    },
                    legend: {
                    position: 'bottom',
                    horizontalAlign: 'right',
                    offsetX: 40
                    }
                    };

                    var chart = new ApexCharts(document.querySelector("#columns_chart"), options);
                    chart.render();
                    document.querySelectorAll('.apexcharts-bar-series .apexcharts-datalabel').forEach(label => {
                        label.style.fill = '#fff';
                    });

                } else {
                    this.btclifecycle =[]
                }
            });
        },
        convertMilosecondsIntoMin(milliseconds) {
            // Calculate days, hours, minutes, and seconds
            const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
            const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
                                   
            // Format the result
            var formattedTime = `${days} Days, ${hours} Hrs, ${minutes} Min, ${seconds} Sec`
            
            return formattedTime;
        },
        getLogtimeDownloadLink(data, curr=null) {
            if(data) {
                var input =[]
                input.push({link:data,empcode: this.undt.userid,useremail: this.undt.username})
                let payload = {
                documents:input,
                empcode: this.undt.userid,
                useremail: this.undt.username
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    if(result.data.status) {
                        if(result.data.data[0].presignurl){
                            if(curr=='zoom'){
                                $('.imagepreview').attr('src', result.data.data[0].presignurl);
                                $('#imagemodal').modal('show');  
                                return 
                            }
                        window.location.href=result.data.data[0].presignurl
                        }
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getProjectIdbyBTCId (bid) {
            this.isLoading = true;
            let url = "api/project/btc/getProjectIdbyBTCId";
            this.input = {
                bid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data.rows) {
                        if(result.data.data.rows.length == 1){
                            this.blockertaskdetails.projectid = result.data.data.rows[0].id;
                        } else {
                            this.blockertaskdetails.btcprojlist = result.data.data.rows;
                        }
                    } else {
                        this.blockertaskdetails.btcprojlist = [];
                    }
                } else {
                    this.blockertaskdetails.projectid = null;
                    this.blockertaskdetails.btcprojlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        seltasklogcomments(state, value) {
            this.logtime.comment = null
            this.logtime.comment = state
            if(!state) {
                this.errors.comment = "Comment is required"
            } else {
                this.errors.comment = null
            }
        },
        openTaskList(){
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".taskList").offset().top - 70
            }, 2000);
        },
        seltaskblockercomments(state) {
            this.blockertaskdetails.comment = null
            this.blockertaskdetails.comment = state
            if(!state) {
                this.blockertaskdetails.errors.comment = "Comment is required"
            } else {
                this.blockertaskdetails.errors.comment = null
            }
        },
        seltaskblockerstatus(state) {
            this.blockertaskdetails.status = null
            this.blockertaskdetails.status = state
            if(!state) {
                this.blockertaskdetails.errors.status = "Related to is required"
            } else {
                this.blockertaskdetails.errors.status = null
            }
        },
        selBTcPro(state) {
            this.blockertaskdetails.projectid = null
            this.blockertaskdetails.projectid = state
            if(!state) {
                this.blockertaskdetails.errors.projectid = "BTC Project is required"
            } else {
                this.blockertaskdetails.errors.projectid = null
            }
        },
        seltBlockerBTCs(state) {
            this.blockertaskdetails.blockerbtcs = null
            this.blockertaskdetails.blockerbtcs = state
            
            if(!state) {
                this.blockertaskdetails.errors.blockerbtcs = "Blocker is required"
                this.blockertaskdetails.btcprojlist = [];
            } else {
                this.blockertaskdetails.errors.blockerbtcs = null
                if(this.blockertaskdetails.moduletype == undefined) {
                    this.getProjectIdbyBTCId(state);
                }
            }
        },
        openBlockerTaskModule(data, type){
            this.clearBlockermodulefields();
            if(data) {
                if(type == 'task') {
                    this.blockertaskdetails.tasklabel = data.taskname;
                    this.blockertaskdetails.tasklabelpid = data.projectid;
                    this.blockertaskdetails.tasklabelid = data.id;
                }else if(type == 'issue') {
                    this.blockertaskdetails.tasklabel = data.issuename; 
                    this.blockertaskdetails.tasklabelpid = data.project;
                    this.blockertaskdetails.tasklabelid = data.issueid;
                }
                this.blockertaskdetails.moduletype = type;
            }
            this.disBlockerActive = true
            this.isLogActive = false
            this.dislogActive = true;
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".addBlockerTaskDiv").offset().top - 70
            }, 2000);
        },
        openLogModule(){
            this.isRequestActive=false
            this.disRequestActive=true
            this.isdocsRequestActive=false
            this.addocument=true
            this.editdocument=false
            this.isEditDocsRequestActive = false
            this.addLogDoc = true
            this.docLogReq = true
            this.logtime.spenttime=null
            this.logtime.spenttime = { "HH": "", "mm": "" }
            this.logtime.activity=null
            this.logtime.comment=''
            this.editdoclist.description=null
            this.doclist.title=null
            this.doclist.comment=null
            this.logtime.status = this.editRowData.taskstatus
            this.logtime.duedate = this.editRowData.due_date
            this.logtime.taskestimates = this.editRowData.taskestimates
            this.logtime.startdate = this.editRowData.startdate
            this.logtime.enddate = this.editRowData.enddate
            this.dislogActive=false
            this.isLogActive=true
            this.disBlockerActive = false;
            $('html, body').animate({ scrollTop: $(document).height() }, 800);
        },
         saveestimatedhrs(e){      
            let z = document.getElementById('accept').checked
            if(this.estimatehrs && z){
                this.tasktype.ehrs = this.estimatehrs;
                if(this.isLogActive==true){
                    this.tasktype.startdate = this.logtime.startdate
                    this.tasktype.enddate = this.logtime.enddate
                }
                this.isinputtechwiphrs = true;
                this.validestimatehrs = true;
                $('#technologypopup').modal('hide');
                if(this.isLogActive==true){
                    this.savelogData()
                }else{
                    this.saveData('t9')
                }
            }       
        },
        getBStatusConfigList(pid, status) {
            this.taskstatus = [];
            if(this.statusconfig){
                let payload = {
                    empcode: this.undt.userid,
                    useremail: this.undt.username,
                    projectid: pid,
                    trackername: 'Businesstask',
                    currStatus: status,
                    bid: parseInt(this.$route.query.businessid),
                };
                axios({
                    method: "POST",
                    url: '/api/testcase/getBStatusConfigList',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    if(result.data.errorCode == 0){
                        this.taskstatus = result.data.data.rows;
                        this.taskstatus.sort((a, b) => a.label.localeCompare(b.label));
                    } else {
                        this.taskstatus = [];
                    }
                });
                let prevStatus = this.tasktype.tstatus;
                if (prevStatus == 'OnHold' || prevStatus == 'Discarded') {
                    axios({
                        method: "POST",
                        url: "api/master/businesstask/getPrevStatusFromLogs",
                        data: { status: prevStatus, businesstaskId: this.$route.query.businessid ,useremail: this.undt.username,empcode: this.undt.userid},
                        'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                        if (result.data.errorCode == 0) {
                            let pushedVar = {id: result.data.data[0].oldval,label: result.data.data[0].oldval }
                            const found = this.taskstatus.find(obj => obj.id == result.data.data[0].oldval);
                            if(!found){
                                this.taskstatus.push(pushedVar);
                            }
                        }
                    }).catch(e => {
                    this.displayError(e)
                });
                }
            }
            // else{
            //     if(this.issubtask != false){
            //         this.getTaskStatusByCondition(this.tasktype.tstatus);
            //     }
            //     //this.getTaskStatusByCondition(this.tasktype.tstatus);
            // }
        },
        saveBlockerTasks () {
            let valid = this.validateBlocekrForm();
            if(valid  &&  this.validestimatehrs){
                this.logtime.isTimeLogged = false;
                this.input = {
                    moduletype: (this.blockertaskdetails.moduletype != null) ? this.blockertaskdetails.moduletype : 'businesstask',
                    id: (this.blockertaskdetails.tasklabelid) ? this.blockertaskdetails.tasklabelid : parseInt(this.editRowData.moduleid),
                    projectid: (this.blockertaskdetails.tasklabelpid) ? this.blockertaskdetails.tasklabelpid : this.blockertaskdetails.projectid,
                    comment: this.blockertaskdetails.comment,
                    status: this.blockertaskdetails.status,
                    empcode: this.undt.userid,
                    useremail: this.undt.username,
                    blockertasklist: this.blockertaskdetails.blockerbtcs,
                }
                axios({
                    method: "POST",
                    url: 'api/tasks/saveBlockerTasks',
                    data: this.input,
                    'headers':{'authorization':this.tokendata }
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.disBlockerActive=false
                        this.isLoading = false
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.clearBlockermodulefields();
                        $('html, body').animate({ scrollTop: 0 }, 1200);
                        this.getBlockerListByBusinessTask(this.$route.query.businessid)
                    } else if(result.data.errorCode == 3) {
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                        } else {
                            Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        savelogData(){
            let valid = this.validatelog();
          
            if(this.isshow == 1 && this.editRowData.taskstatus != "Tech WIP"  && this.view.plannertitle == null && this.logtime.status == "Tech WIP"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please tag planner to this BTC and Try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                valid = false;
            }

            let openSecurityTasks = this.tasklistofbusiness.filter(el => el.status == 'open' && el.reviewers == "securityanalyst");
            if(openSecurityTasks.length>0 && this.logtime.status == "UAT"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the Security Setup tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                valid = false;
            }

            let openEstimationTasks = this.tasklistofbusiness.filter(el => el.status == 'open' && el.reviewers == "estimationteam");
            if(openEstimationTasks.length>0 && this.logtime.status == "Tech Pipeline"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the Estimation Review tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                valid = false;
            }

            let openTAskslist = this.tasklistofbusiness.filter(el => el.status == 'open' && el.tasktype == "WBS");
            if(openTAskslist.length>0 && this.logtime.status != "Tech Pipeline"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the WBS tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                valid = false;
            } 

            let openReviewTAskslist = this.tasklistofbusiness.filter(el => el.status != 'closed' && el.reviewers == "taskmanager");
            if(openReviewTAskslist.length>0 && this.logtime.status == "Tech and Business Review"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the review tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                valid = false;
            } 

            if(!this.tasktype.ehrs && this.logtime.status == "Tech WIP"){
                this.validestimatehrs = false;
                this.logtime.startdate = null;
                this.logtime.enddate = null;
                if(valid){
                    $('#technologypopup').modal('show');
                }
            }
            if(valid  &&  this.validestimatehrs){
                this.saveDoc()
                this.logtime.isTimeLogged = true
                this.input={
                    moduletype:'businesstask',
                    modulename: this.editRowData.modulename,
                    moduleid: parseInt(this.editRowData.moduleid),
                    projectname: this.editRowData.projectnameist,
                    moduleidstr: this.editRowData.projectist,
                    activity: this.logtime.activity,
                    coments: this.logtime.comment,
                    module_code: this.editRowData.businesstaskcode,
                    business_code: this.editRowData.businesstaskcode,
                    createdby: this.undt.username,
                    status: this.logtime.status,
                    attachment: this.logattachment ? JSON.stringify(this.logattachment) : null,
                    subscribers: this.tasktype.subscribers,
                    empid: parseInt(this.undt.userid),
                    duedate: this.logtime.duedate,
                    startdate: this.logtime.startdate,
                    enddate: this.logtime.enddate,
                    taskestimates: this.logtime.taskestimates,
                    empcode: this.undt.userid,
                    useremail: this.undt.username,
                    isshow: this.isshow,
                }
                
                if (typeof(this.logtime.spenttime)=='string' && this.logtime.spenttime) {
                    this.input.spenttime = this.logtime.spenttime;
                } else if(typeof(this.logtime.spenttime)!='string' && this.logtime.spenttime.HH !='' &&  this.logtime.spenttime.mm!='') {
                    this.input.spenttime = this.logtime.spenttime;
                }
                let proArr = (this.editRowData.projectist).split(',')
                this.input.projectid = proArr[0]?parseInt(proArr[0]):0
                this.isLoading=true

                if(this.tasktype.projectmodules == null || this.tasktype.projectmodules == 'undefined' ) {
                    this.isLoading = false;
                    Swal.fire({
                        title: "Failed!",
                        text: 'Please add the project modules and try again.',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    });
                // } else if(this.tasktype.qamanager==null || !this.tasktype.qamanager ) {
                //     this.isLoading = false;
                //     if(!this.tasktype.qalead || this.tasktype.qalead==null) {
                //         Swal.fire({
                //             title: "Failed!",
                //             text: 'Please add the QA Manager and QA Lead to this business task.',
                //             icon: 'info',
                //             customClass: {
                //                 confirmButton: "btn btn-primary"
                //             },
                //             buttonsStyling: !1
                //         });
                //     }
                //     else {
                //         Swal.fire({
                //             title: "Failed!",
                //             text: 'Please add the QA Manager to this business task.',
                //             icon: 'info',
                //             customClass: {
                //                 confirmButton: "btn btn-primary"
                //             },
                //             buttonsStyling: !1
                //         });
                //     }
                // } else if(!this.tasktype.qalead || this.tasktype.qalead==null) {
                //     this.isLoading = false;
                //     Swal.fire({
                //         title: "Failed!",
                //         text: 'Please add the QA Lead to this business task.',
                //         icon: 'info',
                //         customClass: {
                //             confirmButton: "btn btn-primary"
                //         },
                //         buttonsStyling: !1
                //     });
                } else if((this.logtime.status == "Tech and Business Review" || this.logtime.status == "UAT") && ((!this.tasktype.qamanager && this.tasktype.qamanager==null) || (!this.tasktype.qalead && this.tasktype.qalead==null))) {
                    this.isLoading = false;
                    Swal.fire({
                        title: "Failed!",
                        text: 'Please add the QA Manager and QA Lead to this business task.',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    });
                } else if((this.logtime.status == "Tech and Business Review" || this.logtime.status == "UAT") && ((this.parentTaskData.length>0 && this.parentTaskData[0].qalead==null) || (this.parentTaskData.length>0 && this.parentTaskData[0].qamanager==null))) {
                    this.isLoading = false;
                    Swal.fire({
                        title: "Failed!",
                        text: 'Please add the QA Manager and QA Lead to the Parent BTC of this Sub BTC.',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    });
                } else {
                    axios({
                        method: "POST",
                        url: 'api/business/saveBTMLogTime',
                        data: this.input,
                        'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                        this.isLoading = false;
                        this.logattachment = null
                        if (result.data.errorCode == 0) {
                            this.getBusinessSpenttime(this.$route.query.businessid);
                            this.getBTMLogTimeHistory(this.$route.query.businessid)
                            this.dislogActive=true
                            this.isLogActive=false
                            this.isLoading = false
                            this.tasktype.isedit =true
                            this.tasktype.tstatus = this.logtime.status
                            this.tasktype.duedate = this.logtime.duedate
                            this.tasktype.startdate = this.logtime.startdate
                            this.tasktype.enddate = this.logtime.enddate
                            this.tasktype.taskestimates = this.logtime.taskestimates

                            if(this.logtime.securitytag==1){
                                this.tasktype.securitytag = 1;
                                this.tasktype.securitycomment = this.logtime.securitycomment;
                            }else if(this.logtime.securitytag==0){
                                this.tasktype.securitytag = 0;
                            }else{
                                if(this.tasktype.tstatus =='Product Pipeline'||this.tasktype.tstatus =='Product Completed'||this.tasktype.tstatus =='Product WIP'||this.tasktype.tstatus =='Product WIP 2'||this.tasktype.tstatus =='Tech and Business Review'|| this.tasktype.tstatus == 'OnHold' || this.tasktype.tstatus == 'Discarded') {
                                    this.tasktype.securitytag = this.editRowData.securitytag;
                                    this.tasktype.securitycomment = this.editRowData.securitycomment;
                                }
                            }
                            
                            this.saveData();
                            this.logtime.comment=''
                        } else if(result.data.errorCode == 3) {
                            Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                                }).then(function() {
                                    window.location.href = "/#/login";
                                })
                            } else {
                            Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                            })
                        }
                        this.getBTMDocdetails(parseInt(this.editRowData.moduleid));
                    }).catch(e => {
                    this.displayError(e)
                });
                }  
            }
        },
        validateHHHMM:function(str){
            if(!str) return false
            let z = /^([0-9][0-9]|[0-9][0-9][0-9]):[0-5][0-9]$/.test(str); 
            if(!z){
                this.errors.validestimatehrs = "Please enter hours in HHH:MM format"
            
            }else {
                this.errors.validestimatehrs = ""
            }
            return z
        },
         validateDate(mdl) {
            var startDate = this.tasktype.startdate
            var endDate = this.tasktype.enddate
            if(mdl === 't9') {
                if ((Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.tasktype.enddate = "Estimated date should be greater than Start date"
                    this.tasktype.enddate = null
                } else {
                    this.errors.tasktype.enddate = ''
                }
            }
            let startDates = this.logtime.startdate
            let endDates = this.logtime.enddate
            if(mdl === 't9') {
                if ((Date.parse(endDates) < Date.parse(startDates))) {
                    this.errors.enddate = "Estimated date should be greater than Start date"
                } else {
                    this.errors.enddate = ''
                }
            } else if(mdl === 't9') {
                if ((Date.parse(endDates) < Date.parse(startDates))) {
                    this.errors.startdate = "Start date should be lesser than UAT date"
                } else {
                    this.errors.startdate = ''
                }
            }
        },
        validatelog: function(){
            let isValid=true;
            let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm
            if(!spenttime && typeof(this.logtime.spenttime)=='string')
            {
                let logstime = (this.logtime.spenttime).split(':')
                if(logstime){
                    if(logstime[0] && logstime[1]){
                        spenttime = `${logstime[0]}:${logstime[1]}`
                        this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
                    }
                }
            }
            
            if(spenttime && typeof(this.logtime.spenttime)!='string'){
              this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
            }
            
            if(!this.logtime.status){
                this.errors.status="Task status is required";
                isValid = false;
            }
            if((this.logtime.status != 'Completed') && (this.logtime.status != 'OnHold') && (this.logtime.status != 'Discarded')){
                if(this.logtime.status != this.editRowData.taskstatus){
                    if(!this.logtime.duedate){
                            this.errors.duedate="Status due date is required";
                            isValid = false;
                    }else{
                            this.errors.duedate = '';
                            isValid = true;
                    }
                }
            }
            if(this.logtime.duedate){
                if (!this.logtime.status) {
                    this.errors.status="Task status is required";
                    isValid = false;
                }else{
                    this.errors.status="";
                    isValid = true;
                }
            }
            if(this.logtime.status && (this.logtime.status == "Product WIP") || (this.logtime.status == "Product Pipeline") || (this.logtime.status == "Product Completed") || (this.logtime.status == "OnHold") || (this.logtime.status == "Discarded") || (this.logtime.status == "Product WIP 2") || (this.logtime.status == "Tech and Business Review") || (this.logtime.status == "Tech Issue Resolution") ) {
                    this.errors.taskestimates = '';
            }else{
                if(this.logtime.taskestimates){
                    this.errors.taskestimates = '';
                }else{
                    this.errors.taskestimates = 'Ballpark Estimate in days is required'
                    isValid = false
                }
            }
            if(!this.logtime.comment) {
                this.errors.comment = "Comment is required"
                isValid = false
            } else {
                this.errors.comment = null
            }
            if(this.logtime.activity && spenttime==''){
                this.errors.spenttime="Spent-time Hours & Minutes is required";
                isValid= false;
            }else{
                this.errors.spenttime='';
            }
            if(!this.logtime.activity && spenttime!=''){
                this.errors.activity="Activity is required";
                isValid= false;
            }else{
                this.errors.activity=null;
            }

            if(this.logtime.status && (this.logtime.status == "Product WIP") || (this.logtime.status == "Product Pipeline") || (this.logtime.status == "Product Completed") || (this.logtime.status == "OnHold") || (this.logtime.status == "Discarded") || (this.logtime.status == "Product WIP 2") || (this.logtime.status == "Tech and Business Review") ) {
                    this.errors.securitytag = '';
            }else{
                if(this.editRowData.securitytag == null) {
                    if(this.logtime.securitytag ==1 || this.logtime.securitytag == 0){
                        this.errors.securitytag = '';
                    }else {
                        if (this.logtime.securitytag == null) {
                            this.errors.securitytag = 'Security Sensitive is required'
                            isValid = false
                        }
                    }
                }
            }

            if(this.logtime.securitytag == 1) {
                if(!this.logtime.securitycomment){
                    this.errors.securitycomment="Business security justification is required";
                    isValid= false;
                }else{
                    this.errors.securitycomment='';
                }
            }

            return isValid
        },
        redirecttourl:function(btnstate,rowdata, flag=null){
            rowdata.backbtnstate=btnstate
            if(btnstate == 'create') {
                this.$router.push({
                    name: 'TaskCreate',
                    path: '/tasks/create',
                    params: {
                        backbtnstate:btnstate,
                        clickeddatafrombtmview:rowdata
                    }
                })
            } else if(btnstate=="view"){
                let routeData = this.$router.resolve({ 
                    path: '/businesstask/view?businessid='+rowdata.moduleid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }else if(btnstate=="cyclesetview"){
                let routeData = this.$router.resolve({ 
                    path: `/testexecution/testcycleview?cyclesetid=${parseInt(rowdata.cyclesetid)}`,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }else if(btnstate=="groupmappingview"){
                let routeData = this.$router.resolve({ 
                    path: `/testcase/groupbulkView/?groupmappingid=${parseInt(rowdata.mappingid)}`,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }
        },
        selTaskOwner(state){
            if(!state){
                this.errors.tasktype.taskowner = 'Task Owner is required'
            } else {
                this.errors.tasktype.taskowner = ''         
            }
        },
        openEditModule(currRowData,flag){
            this.activePageFlag = flag;
            if(!currRowData){
                currRowData = this.editRowData
            }
            this.attachment = null
            this.attachmentview = []
            this.vieweditmoduledocs = [];
            this.logtime.isTimeLogged = false
            this.isEditDocsRequestActive = false
            if(flag==0 && (this.undt.username != currRowData.createdby && this.loginpersonname != currRowData.productstackholder && this.loginpersonname != currRowData.taskowner && this.loginpersonname != currRowData.qalead && this.loginpersonname != currRowData.qamanager && this.loginpersonname != currRowData.projectmaanager && !this.pagefeatures.editbusinesstask && this.loginpersonname != currRowData.engineeringmaanager && this.loginpersonname != editRowData.programmanager)){
                this.editRowData=[]
                Swal.fire({
                    title: "Failed",
                    text: "Your are not authorised user to update this task",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.$router.push({ name: 'Business Task List', params: { search: true }});
            }
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle){
                $('#modulename'+inputEle).val(currRowData.modulename)
            }
            if(btnEle){
                $('#'+btnEle).find('#submitbtn'+inputEle).addClass(btnEle)
                $('#'+btnEle).find('#submitbtn'+inputEle).attr('data-isedit',true)
            }
            $('#glbsearchinpele').val('')
            this.tasktype.projectlist = JSON.parse(localStorage.getItem('projectlist'))
            this.tasktype.projectname = currRowData.projectid
            this.view.projectid=currRowData.projectist
            this.tasktype.name = currRowData.modulename
            this.tasktype.desc = currRowData.moduledesc
            this.tasktype.level = currRowData.modulelevel
            let arrprojidlist=currRowData.projectist.split(",")
            this.havingsubtasks = (parseInt(currRowData.havingsubtasks) > 0) ? true : false;
            this.issubtask = currRowData.parentbtcid == null ? false : true;
            this.isshow = currRowData.isshow;
            if(this.havingsubtasks == true){
                this.tasktype.projectid=arrprojidlist.map(d => d)
            }else{
                this.tasktype.projectid=arrprojidlist.map(d => parseInt(d))
            }
            //this.tasktype.projectid=arrprojidlist.map(d => parseInt(d))
            this.tasktype.redmineid = currRowData.redmineid
            // this.tasktype.type = currRowData.tasktype
            if(currRowData.tasktype =='Business Task'){
                this.tasktype.type = 'Business Task - New Feature'
            }
            else if(currRowData.tasktype =='Enhancement'){
                this.tasktype.type = 'Business Task - Enhancement'
            }
            else if(currRowData.tasktype =='CR'){
                this.tasktype.type = 'CR'
            }
            else if(currRowData.tasktype =='Escalation'){
                this.tasktype.type = 'Escalation'
            }
            else if(currRowData.tasktype =='Process'){
                this.tasktype.type = 'Process'
            }
            else if(currRowData.tasktype =='Program'){
                this.tasktype.type = 'Program'
            }
            else if(currRowData.tasktype =='Support'){
                this.tasktype.type = 'Support'
            }
            else if(currRowData.tasktype =='Tech Task'){
                this.tasktype.type = 'Tech Task'
            }
            this.tasktype.startdateaeging = currRowData.startdateaeging
            this.tasktype.edddelayed = currRowData.edddelayed
            this.tasktype.edddeliverable = currRowData.edddeliverable 
            this.techpipelinedateaeging = currRowData.techpipelinedateaeging
            if(currRowData.estimatedddate == null) {
                this.btcdelayedstatus = 'On Track'
            } else if(currRowData.estimatedddate != null && this.tasktype.edddeliverable == null) {
                this.btcdelayedstatus = 'Delayed'
            } else {
                this.btcdelayedstatus =  'Deliverable'
            }
            this.tasktype.key = currRowData.taskkey
            this.tasktype.securitytag = currRowData.securitytag
            this.tasktype.securitycomment = currRowData.securitycomment
            this.view.securitytag = currRowData.securitytag
            this.view.securitycomment = currRowData.securitycomment
            this.tasktype.btmpriority = currRowData.priority
            this.tasktype.btmsubpriority = currRowData.subpriority
            this.tasktype.references = currRowData.reference
            this.tasktype.bsh = currRowData.businessstackholder
            this.tasktype.proman = currRowData.projectmaanager
            this.tasktype.engman = currRowData.engineeringmaanager
            this.tasktype.dept = currRowData.department
            this.tasktype.startdate = currRowData.startdate
            this.tasktype.enddate = currRowData.estimatedddate
            this.view.isapproved_edd = currRowData.isapproved_edd
            this.view.updated_edd = currRowData.updated_edd
            this.tasktype.tstatus = currRowData.taskstatus
            this.tasktype.progman = currRowData.programmanager
            this.tasktype.oldstatus= currRowData.taskstatus
            this.tasktype.projectnamestr = currRowData.projectnameist
            this.tasktype.projectist = this.issubtask?(parseInt(currRowData.projectist)):currRowData.projectist
            this.tasktype.projectmodulename = currRowData.projectmodulename
            this.tasktype.projectmodulename = currRowData.projectmodulenamenameist
            this.tasktype.projectmoduleid = currRowData.projectmoduleid
            this.tasktype.taskid = currRowData.moduleid
            this.view.plannertitle = (currRowData.plannertitle)? currRowData.plannertitle : null;
            this.view.plannerid =(currRowData.plannerid)? currRowData.plannerid : null;
            this.tasktype.businesstaskcode = currRowData.businesstaskcode
            this.tasktype.createdby = currRowData.createdby
            this.tasktype.tasklevelname = currRowData.tasklevelname
            this.tasktype.createddate = currRowData.createddate
            this.tasktype.lastmodifiedby = currRowData.lastmodifiedby
            this.tasktype.lastmodifieddate = currRowData.lastmodifieddate
            this.tasktype.estimatedhours = currRowData.estimatedhours
            this.tasktype.ehrs = currRowData.estimatedhours
            this.tasktype.subpriority = currRowData.subpriority
            this.tasktype.project_success_criteria = currRowData.project_success_criteria
            this.tasktype.project_fail_criteria = currRowData.project_fail_criteria
            this.tasktype.project_assumptions = currRowData.project_assumptions
            this.tasktype.project_roi = currRowData.project_roi
            this.tasktype.business_impact = currRowData.business_impact
            this.tasktype.impact_of_live_feature = currRowData.impact_of_live_feature
            this.tasktype.businessname = currRowData.businessname
            this.tasktype.taskmanager = currRowData.productstackholder
            this.tasktype.requester = currRowData.requester
            this.tasktype.requester_hod = currRowData.requester_hod
            this.tasktype.requestername = currRowData.requestername
            this.tasktype.requester_hodname = currRowData.requesterhodname
            if(this.tasktype.requester && this.tasktype.requester!=null){
                this.getRequesterOnSearchDefault(this.tasktype.requester)
            }
            this.tasktype.taskowner = currRowData.taskowner
            this.tasktype.duedate = currRowData.due_date
            this.tasktype.taskestimates = currRowData.taskestimates
            this.view.taskestimates = currRowData.taskestimates
            this.tasktype.qalead = currRowData.qalead
            this.tasktype.security = currRowData.securitytag
            this.tasktype.qamanager = currRowData.qamanager
            this.tasktype.billingcountry = currRowData.billingcountry
            this.tasktype.businesscontext = currRowData.businesscontext
            let arrpmodulelist=currRowData.projectmoduleist.split(",")
            this.tasktype.pmodulelist=arrpmodulelist.map(d => parseInt(d))
            if(this.activePageFlag == 0){
                this.prevarrlen = this.tasktype.projectmodules ?this.tasktype.projectmodules.length:null;
                this.getProjectByBusinessTaskEDIT(this.tasktype.projectmodules, this.$route.query.businessid, 'update', true)
            }
            if(currRowData.parentbtcid == null && currRowData.isshow == null){
                this.taskstatus.push({id:'OnHold',label:'OnHold'}, {id:'Discarded',label:'Discarded'}, { id: currRowData.taskstatus, label: currRowData.taskstatus});
                let known = {}
                this.taskstatus = this.taskstatus.filter(item => !known.hasOwnProperty(item.id) && (known[item.id] = true)
                )
            } else {
                this.getBStatusConfigList(this.tasktype.projectid,this.tasktype.tstatus);
            }
            if(currRowData.tasktype && currRowData.tasktype != null && this.tasktype.isedit == true) {
              this.getTasktypeConfigList(null, currRowData.tasktype);
            }
            let filteredEstimatehr = this.estimateshours.filter(item => item.id === this.view.taskestimates)
            if(filteredEstimatehr.length){
                this.view.taskestimates = filteredEstimatehr[0].label
            }
            this.getDocumetList(currRowData, flag)
            if(this.issubtask == false) {
                this.getParentBTCDocumetList(currRowData, flag)
            }
            this.getBTMLogTimeHistory(currRowData.moduleid)
            // this.getTaskStatusByCondition(currRowData.taskstatus);
            //this.getModules(this.tasktype.projectid,currRowData.moduleid,'businesstask')
            this.getBTMDocdetails(currRowData.moduleid)
            this.getProjectByBusinessTask(currRowData.moduleid)
            this.getSubscribers(this.$route.query.businessid, 'businesstask')
            this.getprojectmaprolide(this.loginuscondition,'1', this.tasktype.projectid);
            this.getspecificbtcstatus(currRowData.taskstatus)

            for(const i in this.docs){
                this.docs[i].comment=null
                this.docs[i].desc=null
                this.docs[i].title=null
                if(i>0){
                    this.docs.splice(i, 1);
                }
            }
            if(flag==2){
                this.isdocsRequestActive=true
                this.addocument=false
                this.editdocument=true
                this.isRequestActive=false
                this.disRequestActive=true
                this.dislogActive=true
                this.isLogActive=false
                $('html, body').animate({ scrollTop: $(document).height() }, 1200);
            }

            if(flag==3 || flag =='moduledocs'){
                this.isdocsRequestActive=true
                this.editdocument=false
                this.isRequestActive=false
                this.disRequestActive=true
                this.isEditDocsRequestActive = true
                this.dislogActive=true
                this.isLogActive=false
                $('html, body').animate({ scrollTop: $(document).height() }, 1200);
            }
            if(flag==0  ){
                this.dislogActive=true
                this.isLogActive=false
                this.tasktype.isedit=true
                this.isRequestActive=true
                this.disRequestActive=false
                this.addocument=true
                this.editdocument=true
                this.formActionLabel = `Edit Business Task: ${currRowData.modulename}`
                $('html, body').animate({ scrollTop: $(document).height() }, 1200);
                this.getEmpallocatedtoProject(this.tasktype.projectid)
            }
            this.isAddState=true
            if(this.tasktype.startdate && this.tasktype.startdate!=null) {
                this.isenableestimatedate = false;
            } else {
                this.isenableestimatedate = true;
            }
            this.projectChange(this.tasktype.projectid ? this.tasktype.projectid : []);
        },
        getTasktypeConfigList(projectid) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: parseInt(projectid),
                trackername: 'BTM Task Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    slist.sort((a, b) => a.label.localeCompare(b.label));
                    this.btmtasktypelist = slist;                    
                } else {
                    this.btmtasktypelist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getDownloadLink(data) {
            if(data.data.length>0) {
                let payload = {
                documents:data.data.map(i => {
                    return {...i, useremail: this.undt.username, empcode: this.undt.userid}
                }),
                empcode: this.undt.userid,
                useremail: this.undt.username
                };
            axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    if(result.data.status) {
                        this.attachmentview = result.data.data;
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getDownloadLinkParentBTC(data) {
            if(data.data.length>0) {
                let payload = {
                documents:data.data.map(i => {
                    return {...i, useremail: this.undt.username, empcode: this.undt.userid}
                }),
                empcode: this.undt.userid,
                useremail: this.undt.username
                };
            axios({
                    method: "POST",
                    url: '/api/imageupload/getDownloadLinkParentBTC',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    if(result.data.status) {
                        this.attachmentviewParentBtc = result.data.data;
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        selectProMan(state){
            if(!state){
                this.errors.tasktype.proman = 'Project Manager is required'
            } else {
                this.errors.tasktype.proman = ''         
            }
        },
        selectEngMan(state){
            if(!state){
                this.errors.tasktype.engman = 'Engineering Manager is required'
            } else {
                this.errors.tasktype.engman = ''         
            }
        },
        selectQAMan(state){
            if(!state){
                this.errors.tasktype.qamanager = 'QA Manager is required'
            } else {
                this.errors.tasktype.qamanager = ''
            }
        },
        selectsecurity(state){
           if(this.tasktype.tstatus =='Product Pipeline'||this.tasktype.tstatus =='Product Completed'||this.tasktype.tstatus =='Product WIP'||this.tasktype.tstatus =='Product WIP 2'||this.tasktype.tstatus =='Tech and Business Review'|| this.tasktype.tstatus == 'OnHold' || this.tasktype.tstatus == 'Discarded')
                {
                    this.errors.tasktype.securitytag=''
                    this.tasktype.securitycomment=null
                }else if(state == null){
                    this.errors.tasktype.securitytag="Security Sensitive is required";
                    isValid=false;
                }else{
                    this.errors.tasktype.securitytag=''
                }
        },
        selectQALead(state){
            if(!state){
                this.errors.tasktype.qalead = 'QA Lead is required'
            } else {
                this.errors.tasktype.qalead = ''
            }
        },
        selectProgMan(state){
            // if(!state){
            //     this.errors.tasktype.progman = 'Program Manager is required'
            // } else {
            //     this.errors.tasktype.progman = ''         
            // }
        },
        getDownloadedLink(data, curr=null) {
            if(data) {
                let payload = {
                documents:[{link:data.documentname,empid: this.undt.userid,useremail: this.undt.username}],
                empcode: this.undt.userid,
                useremail: this.undt.username
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    this.isLoading = false
                    if(result.data.status) {
                        if(result.data.data[0].presignurl){
                            if(curr=='zoom'){
                            $('.imagepreview').attr('src', result.data.data[0].presignurl);
                            $('#imagemodal').modal('show');  
                            return 
                        }       
                            window.open(result.data.data[0].presignurl, '_blank')     
                        }
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getDownloadedzoom(data, curr=null) {
            if(data) {
                var input =[]
                input.push({link:data,empcode: this.undt.userid,useremail: this.undt.username})
                let payload = {
                documents:input,
                empcode: this.undt.userid,
                useremail: this.undt.username
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                })
                .then((result) => {
                    this.isLoading = false
                    if(result.data.status) {
                        if(result.data.data[0].presignurl){
                            if(curr=='zoom'){
                            $('.imagepreview').attr('src', result.data.data[0].presignurl);
                            $('#imagemodal').modal('show');  
                            return
                        }
                            window.location.href=result.data.data[0].presignurl;            
                        }
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        removeEditDoc(index, fieldType, data, key) {  
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                if(data.businessdocmasterid !== undefined) {
                    axios({
                    method: "POST",
                    url: 'api/workstep/deletebusinesstaskdoc',
                    data: {id:data.businessdocmasterid,deleteby:this.undt.username,empcode: this.undt.userid,useremail: this.undt.username},
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.status) {
                        const objWithIdIndex = this.vieweditmoduledocs[index].editedmodulefiles.findIndex((obj) => obj.businessdocmasterid === data.businessdocmasterid);
                        if (objWithIdIndex > -1) {
                          this.vieweditmoduledocs[index].editedmodulefiles.splice(objWithIdIndex, 1);
                        }
                        Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'success'
                        )
                    } else {
                       Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'error'
                        )                        
                    }
                });
                } else {
                    fieldType.splice(index, 1);
                }    
            }
            })
        },
        getDocumetList(currRowData, flag){
            this.isLoading = true;
            let url = "api/s3upload/getDocumentPresignedUrl";
            this.input = {
                moduleid:currRowData.moduleid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.documentlist = result.data.data;
                    let docs = this.tasktype.documentlist.map((doc, i) => {
                        return {
                            info: doc.title,
                            link: doc.documentname
                        }
                    })
                    let idata = { data: docs }
                    this.getDownloadLink(idata)
                    
                }  else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else {
                    this.tasktype.documentlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getParentBTCDocumetList(currRowData, flag){
            this.isLoading = true;
            let url = "api/s3upload/getParentBTCDocumetList";
            this.input = {
                moduleid:currRowData.moduleid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.documentlist = result.data.data;
                    let docs =  result.data.data.map((doc, i) => {
                        return {
                            info: doc.title,
                            link: doc.documentname,
                            uploaddate: doc.uploaddate,
                            uploadedby: doc.createdby,
                        }
                    })
                    let idata = { data: docs }
                    this.getDownloadLinkParentBTC(idata)
                }  else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        formatData(data) {
            return data
        },
        docsuploadcollapse:function(){
            if(this.docs){
                for(const i in this.docs){
                    this.docs[i].comment=null
                    this.docs[i].desc=null
                    this.docs[i].title=null
                     $('html, body').animate({ scrollTop: 0 }, 800); 
                    if(i>0){
                        this.docs.splice(i, 1);
                    }
                }
            }
            this.isDetActive=true
            this.addocument=true
            this.editdocument = true
            this.isdocsRequestActive=!this.isdocsRequestActive
            
        },
        addNewDocsUploadCollapse: function() {
            this.dislogActive=true
            this.isLogActive=false
            this.isEditDocsRequestActive=""
            this.isdisabled = false
            $('html, body').animate({ scrollTop: 0 }, 800);
        },
        handleCancel2() {
            this.dislogActive=true
            this.isLogActive=false
            this.isEditDocsRequestActive=""
            this.isdisabled = false
            $('html, body').animate({ scrollTop: 0 }, 800);
        },
        myeditdocrequestollapse: function() {
            this.isEditDocsRequestActive =!this.isEditDocsRequestActive
        },
        myadddocrequestollapse: function() {
            this.isdocsRequestActive =!this.isdocsRequestActive
        },
        myrequestollapse:function(){
            this.disRequestActive=!this.disRequestActive
            this.tasktype.project_success_criteria=null
            this.tasktype.project_fail_criteria=null
            this.tasktype.project_assumptions=null
            this.tasktype.project_roi=null
            this.tasktype.securitycomment = ''
            this.tasktype.securitytag = null
            this.tasktype.business_impact=null
            this.tasktype.impact_of_live_feature=null
            this.tasktype.projectmoduleid = null
        },
        handleCancel() {
            this.myrequestollapse()
            this.isdisabled = false
            $('html, body').animate({ scrollTop: 0 }, 1200);
        },
        handleLogCancel() {
            this.clearBlockermodulefields();
            this.dislogActive=true
            this.isLogActive=false
            this.disBlockerActive = false
            this.isdisabled = false
            this.logtime.comment = ''
            $('html, body').animate({ scrollTop: 0 }, 1200);
        },
        addField(value, fieldType,opt='add') {
            if(opt=='edit') {
                let row = fieldType[fieldType.length-1];
                if(row.businessdocmasterid == undefined) return false
            }            
            this.isActive=false
            fieldType.push({ value: "" });
        },
        addModuleFileField(pdata, fieldType,opt) {
            if(opt=='edit') {
                let row = fieldType[fieldType.length-1];
                if(row.businessdocmasterid == undefined) return false
            }            
            this.isActive=false
            if(opt === 'moduledocs') {
                fieldType.push({
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:'',
                    filename: null,
                    batchid: null,
                    isUploaded: false,
                    taskid:this.tasktype.taskid,
                    projectname: pdata.projectname,
                    projectid: pdata.projectid,
                    projectmodulename: pdata.projectmodulename,
                    projectmoduleid: pdata.moduleid,
                    modulepriority: pdata.modulepriority,
                    modulestatus: pdata.modulestatus,
                    businesstaskmoduleassignmentid:pdata.businesstaskmoduleassignmentid,
                    businesstaskmasterid:pdata.businesstaskmasterid,
                });
            } else {
                fieldType.push({
                    title:'',
                    docs:'',
                    comment:'',
                    attachment:'',
                    filename: null,
                    batchid: null,
                    isUploaded: false,
                    tempfilename: null,
                    tempdoctitile: null,
                })
            }
        },
        removeModuleFileField(index, idx, fieldType) {
            if(fieldType && fieldType.length>1){
                fieldType.splice(idx,1)
            }            
        },
        removeField(index, fieldType) {
            if(fieldType && fieldType.length>1){
                this.docs.splice(index,1);
                this.attachment.splice(index,1);
            }            
        },
        removeAddNewDocsField(index, fieldType) {
            if(fieldType && fieldType.length>0){
                fieldType.splice(index,1);
            }
        },
        removeEditField(index, fieldType,data) {            
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                if(data.businessdocmasterid !== undefined) {
                    axios({
                    method: "POST",
                    url: 'api/workstep/deletebusinesstaskdoc',
                    data: {id:data.businessdocmasterid,deleteby:this.undt.username,empcode: this.undt.userid,useremail: this.undt.username},
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    let currRowData = { moduleid: this.tasktype.taskid } 
                    this.getDocumetList(currRowData)
                    if (result.data.status) {
                        fieldType.splice(index, 1);
                        Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'success'
                        )
                    } else {
                       Swal.fire(
                        'Deleted!',
                        result.data.msg,
                        'error'
                        )                        
                    }
                });
                } else {
                    fieldType.splice(index, 1);
                }    
            }
            }).catch(e => {
                this.displayError(e)
            })
        },
        dateFormatterSales: function(dt) {
            let dateoptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
            };
            return dt.toLocaleDateString("en-GB", dateoptions);
        },
        UpdateDocument(objdata, type=null) {
            var cond = false
            if(type && type === 'modulefilesupdate') {
                cond = objdata.title == "" || objdata.title == undefined || objdata.description == "" || objdata.description == undefined || objdata.comment == "" || objdata.comment == undefined || !objdata.projectid || !objdata.projectmoduleid
            } else {
                cond = objdata.title == "" || objdata.title == undefined || objdata.description == "" || objdata.description == undefined || objdata.comment == "" || objdata.comment == undefined
            }
            if(cond) {
                Swal.fire({
                    title: "Failed",
                    text: "Some fields invalidated!",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                return false; 
            } else {
                if(this.files && this.files.length) {
                    this.isLoading=true
                    let url = "api/s3upload/editnewbusinessdocument";
                    this.files.forEach(file => {
                        if (file.size > 1024 * 1024 * 5) {
                            Swal.fire({
                                title: "Failed",
                                text: "Your document uploaded above 5mb not allow",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            this.isLoading=false
                            return false; 
                        } 
                        if(file.name) {
                            let formData = new FormData();
                            if(objdata.businessdocmasterid) {
                                let newversion =  objdata.docverison !== '' && objdata.docverison !== undefined && objdata.docverison !== null ? Number(objdata.docverison.substr(1)) +1:'v1';
                                formData.append("version","v"+newversion );
                                formData.append("updatedby",this.undt.username );
                                formData.append("taskid", objdata.taskid );
                                formData.append("businessdocid", objdata.businessdocmasterid);
                                url = "api/s3upload/updatebusinessdocument";
                            } else {
                                url = "api/s3upload/editnewbusinessdocument";
                                formData.append("createdby",this.undt.username );
                                formData.append("taskid", this.tasktype.taskid);
                            }
                            formData.append("imagefile", file);
                            formData.append("moduletype", 'businesstask')
                            formData.append("title", objdata.title);
                            formData.append("desc", objdata.description);
                            formData.append("comment", objdata.comment);
                            formData.append("empcode", this.undt.userid);
                            formData.append("useremail", this.undt.username);
                            
                            axios({
                                "content-type": "multipart/form-data",
                                method: "POST",
                                url: url,
                                data: formData,
                                'headers':{'authorization':this.tokendata}
                            }).then((result) => {
                                if (result.data.status) {
                                    this.files=[];
                                    let currRowData = {
                                        moduleid: this.tasktype.taskid
                                    } 
                                    this.getDocumetList(currRowData)
                                    Swal.fire({
                                        title: "Success!",
                                        text: result.data.msg,
                                        icon: "success",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    });
                                    this.isLoading=false
                                } else {
                                    Swal.fire({
                                        title: "Failed",
                                        text: "Select business task image/docs/videos",
                                        icon: "info",
                                        customClass: {
                                        confirmButton: "btn btn-primary",
                                        },
                                        buttonsStyling: !1,
                                    })
                                    this.isLoading=false
                                }
                            }).catch(e => {
                                this.displayError(e)
                            })
                        }
                    })                
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: "Select document",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }
        },
        saveData(mdl,objThis){
            var isValid = true;
            isValid = this.validateForm(mdl,objThis);
            if(this.logtime.isTimeLogged == true && isValid == false){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please update the required fields and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                isValid = false;
            }
            let openTAskslist = this.tasklistofbusiness.filter(el => el.status == 'open' && el.tasktype == "WBS");
            if(openTAskslist.length>0 && this.tasktype.tstatus != "Tech Pipeline"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please closed the WBS tasks and try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                isValid = false;
            }

            if(this.isshow == 1 && this.editRowData.taskstatus != "Tech WIP"  && this.view.plannertitle == null && this.tasktype.tstatus == "Tech WIP"){
                Swal.fire({
                    title: "Failed!",
                    text: 'Please tag planner to this BTC and Try again.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                });
                isValid = false;
            }
            if(!this.tasktype.ehrs && this.tasktype.tstatus == "Tech WIP"){
                if(isValid){
                    this.validestimatehrs = false;
                    $('#technologypopup').modal('show');
                }
            }else{
                this.validestimatehrs = true;
            }
            if (isValid && this.validestimatehrs) {
                this.isLoading = true;
                this.clearerrorForm();
                let nameobj=document.getElementById('modulename'+mdl)
                let tblNm='t9'
                if(mdl) tblNm=mdl
                this.input = {
                    createdby: this.undt.username,
                    empid: this.undt.userid,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                if(tblNm == 't9'){
                    this.input.name=this.tasktype.name
                    if(this.tasktype.desc){
                        this.input.desc=this.tasktype.desc
                    }

                    if(this.tasktype.securitytag == 1){
                        this.input.securitytag = 1;
                        this.input.securitycomment = this.tasktype.securitycomment;
                    }else if(this.tasktype.securitytag == 0){
                        this.input.securitytag = 0;
                    }else{
                        this.input.securitytag = null;
                    }

                    if(this.tasktype.project_success_criteria){
                        this.input.project_success_criteria=this.tasktype.project_success_criteria
                    }
                    if(this.tasktype.project_fail_criteria){
                        this.input.project_fail_criteria=this.tasktype.project_fail_criteria
                    }
                    if(this.tasktype.project_roi){
                        this.input.project_roi=this.tasktype.project_roi
                    }
                    if(this.tasktype.business_impact){
                        this.input.business_impact=this.tasktype.business_impact
                    }
                    if(this.tasktype.impact_of_live_feature){
                        this.input.impact_of_live_feature=this.tasktype.impact_of_live_feature
                    }
                    if(this.tasktype.project_assumptions){
                        this.input.project_assumptions=this.tasktype.project_assumptions
                    }
                    if(this.tasktype.level != null && this.tasktype.level != 'undefined'){
                        this.input.level=this.tasktype.level
                    }
                    if(this.tasktype.projectid != null && this.tasktype.projectid != 'undefined'){
                        this.input.projectid=JSON.stringify(this.tasktype.projectid)
                    }
                    if(this.tasktype.redmineid != null && this.tasktype.redmineid != ""){
                        this.input.redmineid = this.tasktype.redmineid
                    }else{
                        this.input.redmineid = null
                    }
                    if(this.tasktype.taskmanager != null && this.tasktype.taskmanager != ""){
                        this.input.productstackholder = this.tasktype.taskmanager
                    }else{
                        this.input.productstackholder = null
                    }
                    if(this.tasktype.taskowner != null && this.tasktype.taskowner != ""){
                        this.input.taskowner = this.tasktype.taskowner
                    }else{
                        this.input.taskowner = null
                    }    
                    if(this.tasktype.projectmodules != null && this.tasktype.projectmodules != 'undefined'){
                        this.input.projectmodules=JSON.stringify(this.tasktype.projectmodules)
                    }    
                    // this.input.tasktype = this.tasktype.type
                    if(this.tasktype.type == 'Business Task - New Feature'){
                        this.input.tasktype = 'Business Task'
                    } else
                    if(this.tasktype.type=='Business Task - Enhancement') {
                        this.input.tasktype ='Enhancement'
                    } else{
                        this.input.tasktype = this.tasktype.type
                    }
                    this.input.taskkey = this.tasktype.key
                    this.input.priority = this.tasktype.btmpriority
                    this.input.reference = this.tasktype.references
                    this.input.businessstackholder = this.tasktype.bsh
                    this.input.requester =  this.tasktype.requester?this.tasktype.requester:null
                    this.input.requester_hod = this.tasktype.requester_hod?this.tasktype.requester_hod:null
                    this.input.projectmaanager = this.tasktype.proman?(this.tasktype.proman).toLowerCase():null;
                    this.input.engineeringmaanager = this.tasktype.engman?(this.tasktype.engman).toLowerCase():null;
                    this.input.department = this.tasktype.dept
                    this.input.subscribers = this.tasktype.subscribers
                    this.input.startdate = this.tasktype.startdate
                    this.input.estimatedddate = this.tasktype.enddate?this.tasktype.enddate:null
                    this.input.attachment = null
                    this.input.taskstatus = this.tasktype.tstatus
                    this.input.estimatedhours = this.tasktype.ehrs
                    this.input.subpriority = this.tasktype.subpriority
                    this.input.module_code = this.editRowData.businesstaskcode
                    this.input.business_code = this.editRowData.businesstaskcode
                    this.input.businessname = this.tasktype.businessname
                    if(this.tasktype.taskstatus == 'Tech Pipeline'){
                        this.input.techpipelinedate = this.editRowData.techpipelinedate
                    }
                    this.input.duedate = this.tasktype.duedate
                    this.input.taskestimates = this.tasktype.taskestimates
                    this.input.projectmoduledata = this.vieweditmoduledocs
                    this.input.qalead = this.tasktype.qalead
                    this.input.qamanager = this.tasktype.qamanager

                    if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'Product WIP 2'){
                        if(this.editRowData.estimatedddate && this.editRowData.estimatedddate!=null){
                            this.input.prev_edd = this.editRowData.estimatedddate
                        }
                        if(this.editRowData.estimatedddate!=this.tasktype.enddate){
                            this.input.edd_reason = this.tasktype.edd_reason
                            this.input.edd_counter = this.editRowData.edd_counter + 1
                        }
                    }
                }

                this.input.programmanager = this.tasktype.progman?(this.tasktype.progman).toLowerCase():null;
                if(this.input.programmanager && this.input.programmanager != null){
                    const rowFound = this.tasktype.proglist.filter(el => el.id == this.input.programmanager);
                    if(rowFound.length>0) {
                        this.input.programmanagerid = rowFound?rowFound[0].employeeid:null;
                    }
                }
                if(this.input.qalead){
                    const rowFound1 = this.tasktype.qaleadlist.filter(el => el.id == this.input.qalead);
                    if(rowFound1.length>0) {
                        this.input.qaleadid = rowFound1?rowFound1[0].employeeid:null;
                    }
                }
                if(this.input.qamanager && this.input.qamanager != null){
                    const rowFound2 = this.tasktype.qamnglist.filter(el => el.id == this.input.qamanager);
                    if(rowFound2.length>0) {
                        this.input.qamanagerid = rowFound2?rowFound2[0].employeeid:null;
                    }
                }
                if(this.input.taskowner && this.input.taskowner != null){
                    const rowFound3 = this.tasktype.taskownerlist.filter(el => el.id == this.input.taskowner);
                    if(rowFound3.length>0) {
                        this.input.taskownerid = rowFound3?rowFound3[0].employeeid:null;
                    }
                }
                if(this.input.projectmaanager && this.input.projectmaanager != null){
                    const rowFound4 = this.tasktype.pmlist.filter(el => el.id == this.input.projectmaanager);
                    if(rowFound4.length>0) {
                        this.input.projectmanagerid = rowFound4?rowFound4[0].employeeid:null;
                    }
                }
                if(this.input.engineeringmaanager && this.input.engineeringmaanager != null){
                    const rowFound5 = this.tasktype.emlist.filter(el => el.id == this.input.engineeringmaanager);
                    if(rowFound5.length>0) {
                        this.input.engineeringmanagerid = rowFound5?rowFound5[0].employeeid:null;
                    }
                }
                this.input.billingcountry = this.tasktype.billingcountry
                this.input.businesscontext = this.tasktype.businesscontext
                this.input.id=this.editRowData.moduleid
                this.input.isTimeLogged = this.logtime.isTimeLogged
                this.input.projectnameist = this.editRowData.projectnameist
                this.input.lastmodifiedby=this.undt.username
                this.input.issubtask = this.issubtask;
                this.input.isshow = this.isshow;
                this.input.isupdatedependencies = true
                delete this.input.createdby;
                this.tasktype.isedit=false
                this.estimatehrs=null
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': 'api/master/businesstask/updateBusinessProject',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                    if(result.data.errorCode == 0){
                        this.forceRerender()
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })

                        this.logtime.isTimeLogged = false;
                        this.getbusinesstasklistbyid(this.editRowData.moduleid)
                       // this.getBTMLogTimeHistory(this.editRowData.moduleid)
                        this.getBTMDocdetails(this.editRowData.moduleid);
                        this.getTaskListByBusinessTask(this.editRowData.moduleid);
                        this.isRequestActive = false
                        this.disRequestActive=true
                        $('html, body').animate({ scrollTop: 0 }, 800);
                    }  else if(result.data.errorCode == 3){
                            Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                    }else {
                        Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        onchangedate(){
            if(this.tasktype.enddate == null){
                this.tasktype.edd_reason = null;
            }
        },
        onchangestartdate(){
            if(this.tasktype.startdate && this.tasktype.startdate!=null) {
                this.isenableestimatedate = false;
            } else {
                this.isenableestimatedate = true;
            }
        },
        clearBlockermodulefields() {
            this.blockertaskdetails.tasklabel = null;
            this.blockertaskdetails.tasklabelid = null;
            this.blockertaskdetails.tasklabelpid = null;
            this.blockertaskdetails.comment = '';
            this.blockertaskdetails.status = null;
            this.blockertaskdetails.moduletype = null;
            this.blockertaskdetails.errors.comment = null;
            this.blockertaskdetails.errors.status = null;
            this.blockertaskdetails.errors.blockerbtcs = null;
            this.blockertaskdetails.blockerbtcs = null;
            this.blockertaskdetails.btcprojlist = [];
            this.blockertaskdetails.projectid = null;
            this.tasksblockerlist = [];
        },
        validateBlocekrForm () {
            let isValid = true;
            if (this.blockertaskdetails.comment == null || this.blockertaskdetails.comment == "") {
                this.blockertaskdetails.errors.comment = "Comment is required"   
                isValid = false;
            }else{
                this.blockertaskdetails.errors.comment = null;
            }

            if (this.blockertaskdetails.status == null || this.blockertaskdetails.status == "") {
                this.blockertaskdetails.errors.status = "Status is required."   
                isValid = false;
            }else{
                this.blockertaskdetails.errors.status = null;
            }

            if (this.blockertaskdetails.blockerbtcs == null || this.blockertaskdetails.blockerbtcs.length == 0) {
                this.blockertaskdetails.errors.blockerbtcs = "Blocker is required."   
                isValid = false;
            }else{
                this.blockertaskdetails.errors.blockerbtcs = null;
            }

            if (this.blockertaskdetails.moduletype != 'task' && this.blockertaskdetails.moduletype != 'issue' && (this.blockertaskdetails.btcprojlist != null || this.blockertaskdetails.btcprojlist.length > 0)  && this.blockertaskdetails.projectid == null) {
                this.blockertaskdetails.errors.projectid = "BTC Project is required."   
                isValid = false;
            }else{
                this.blockertaskdetails.errors.projectid = null;
            }
            
            return isValid;
        },
        validateForm(mdl,objThis){
            this.clearerrorForm()
            this.isAddState=false
            this.project_success_criteriaBlured = true;
            this.project_fail_criteriaBlured = true;
            this.project_assumptionsBlured = true;
            this.project_roiBlured = true;
            this.business_impactBlured = true;
            this.impact_of_live_featureBlured = true;
            var isValid=true;
            isValid = this.readOnlyFieldsValidation(isValid);

            this.vieweditmoduledocs.forEach(element => {
                if(element.modulefiles && element.modulefiles.length>0)
                {
                    element.modulefiles.forEach(modulefilesele => {
                        if((!modulefilesele.title && modulefilesele.title == "") || 
                        (!modulefilesele.desc && modulefilesele.desc == "") || 
                        (!modulefilesele.comment && modulefilesele.comment == "") || modulefilesele.attachment == "") {
                            isValid = false;
                        } 
                    });
                }
            });

            if(mdl == 't9'){
                if(this.finalvalidationArray['Business Task Name']) {
                    this.errors.tasktype.name='';
                    if(this.finalvalidationArray['Business Task Name'] == 'Required')
                    {
                        if(!this.tasktype.name){
                            this.errors.tasktype.name="Business Task Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.name){
                        this.errors.tasktype.name="Business Task name is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.name='';
                    }
                }
                if(this.finalvalidationArray['Redmine ID']) {
                    this.errors.tasktype.redmineid='';
                    if(this.finalvalidationArray['Redmine ID'] == 'Required')
                    {
                        if(!this.tasktype.redmineid){
                            this.errors.tasktype.redmineid="Redmine/Reference ID is required!";
                            isValid= false;
                        }
                    }
                }
                let flag=false
                if(flag){
                    if(!this.tasktype.taskmanager){
                        this.errors.tasktype.taskmanager="Task manager is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.taskmanager='';
                    }
                    if(!this.tasktype.project_success_criteria){
                        this.errors.tasktype.project_success_criteria="Business success criteria is required";
                        isValid= false;
                    }
                    if(!this.tasktype.project_fail_criteria){
                        this.errors.tasktype.project_fail_criteria="Business failure criteria is required";
                        isValid= false;
                    }
                    if(!this.tasktype.project_assumptions){
                        this.errors.tasktype.project_assumptions="Business assumptions is required";
                        isValid= false;
                    }
                    if(!this.tasktype.project_roi){
                        this.errors.tasktype.project_roi="Business ROI is required";
                        isValid= false;
                    }
                    if(!this.tasktype.business_impact){
                        this.errors.tasktype.business_impact="Business impact is required";
                        isValid= false;
                    }
                    if(!this.tasktype.impact_of_live_feature){
                        this.errors.tasktype.impact_of_live_feature="Impact on other live features if any is required";
                        isValid= false;
                    }
                }
                if(this.finalvalidationArray['Task Description']) {
                    this.errors.tasktype.desc='';
                    if(this.finalvalidationArray['Task Description'] == 'Required')
                    {
                        if(!this.tasktype.desc){
                            this.errors.tasktype.desc="Task Description is required!";
                            isValid= false;
                        }
                    }
                }else{
                    if(!this.tasktype.desc){
                        this.errors.tasktype.desc="Business task description is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.desc='';
                    }
                }
                if(this.tasktype.tstatus =='Product Pipeline'||this.tasktype.tstatus =='Product Completed'||this.tasktype.tstatus =='Product WIP'||this.tasktype.tstatus =='Product WIP 2'||this.tasktype.tstatus =='Tech and Business Review'|| this.tasktype.tstatus == 'OnHold' || this.tasktype.tstatus == 'Discarded')
                {
                    this.errors.tasktype.securitytag=''
                }else if(this.tasktype.securitytag == null){
                    this.errors.tasktype.securitytag="Security Sensitive is required";
                    isValid=false;
                }
                if(this.tasktype.securitytag) {
                    if(this.finalvalidationArray['Security Justification']) {
                        this.errors.tasktype.securitycomment='';
                        if(this.finalvalidationArray['Security Justification'] == 'Required')
                        {
                            if(!this.tasktype.securitycomment){
                                this.errors.tasktype.securitycomment="security justification is required!";
                                isValid= false;
                            }
                        }
                    }else{
                        if(!this.tasktype.securitycomment){
                            this.errors.tasktype.securitycomment="Business security justification is required";
                            isValid= false;
                        }else{
                            this.errors.tasktype.securitycomment='';
                        }
                    }
                }

                if(this.finalvalidationArray['Project Name']) {
                    this.errors.tasktype.projectid = '';
                    if(this.finalvalidationArray['Project Name'] == 'Required')
                    {
                        if((!this.tasktype.projectid) || (this.tasktype.projectid && this.tasktype.projectid.length < 1)){
                            this.errors.tasktype.projectid="Project Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if((!this.tasktype.projectid) || (this.tasktype.projectid && this.tasktype.projectid.length < 1)){
                        this.errors.tasktype.projectid="Project required";
                        isValid= false;
                    }
                }

                if(this.finalvalidationArray['Task Level']) {
                    this.errors.tasktype.level='';
                    if(this.finalvalidationArray['Task Level'] == 'Required')
                    {
                        if(!this.tasktype.level){
                            this.errors.tasktype.level="Task Level is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.level){
                        this.errors.tasktype.level="Task level is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.level='';
                    }
                }
                if(this.finalvalidationArray['Task Type']) {
                    this.errors.tasktype.type='';
                    if(this.finalvalidationArray['Task Type'] == 'Required')
                    {
                        if(!this.tasktype.type){
                            this.errors.tasktype.type="Task Type is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.type){
                        this.errors.tasktype.type="Task type is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.type='';
                    }
                }

                if(this.finalvalidationArray['Task Severity']) {
                    this.errors.tasktype.key='';
                    if(this.finalvalidationArray['Task Severity'] == 'Required')
                    {
                        if(!this.tasktype.key){
                            this.errors.tasktype.key="Task Severity is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.key){
                        this.errors.tasktype.key="Task severity is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.key='';
                    }
                }

                if(this.finalvalidationArray['Reference']) {
                    this.errors.tasktype.references='';
                    if(this.finalvalidationArray['Reference'] == 'Required')
                    {
                        if(!this.tasktype.references){
                            this.errors.tasktype.references="Reference is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.references){
                        this.errors.tasktype.references="Task references is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.references='';
                    }
                }

                if(this.finalvalidationArray['Department'])
                {
                    this.errors.tasktype.dept='';
                    if(this.finalvalidationArray['Department'] == 'Required')
                    {
                        if(!this.tasktype.dept){
                        this.errors.tasktype.dept="Department is required!";
                        isValid= false;
                        }
                    }
                }
                else
                {
                    if(!this.tasktype.dept){
                        this.errors.tasktype.dept="Department is required";
                        isValid = false;
                    }else{
                        this.errors.tasktype.dept='';
                    }
                }

                if(this.finalvalidationArray['Task Status'])
                {
                    this.errors.tasktype.tstatus='';
                    if(this.finalvalidationArray['Task Status'] == 'Required')
                    {
                        if(!this.tasktype.tstatus){
                            this.errors.tasktype.tstatus="Task Status is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.tstatus){
                        this.errors.tasktype.tstatus="Task status is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.tstatus='';
                    }         
                }

                if(this.finalvalidationArray['Ballpark Estimate'])
                {
                    this.errors.tasktype.taskestimates = '';
                    if(this.finalvalidationArray['Ballpark Estimate'] == 'Required')
                    {
                        if(this.tasktype.tstatus && (this.tasktype.tstatus == "Product WIP") || (this.tasktype.tstatus == "Product Pipeline") || (this.tasktype.tstatus == "Product Completed") || (this.tasktype.tstatus == "OnHold") || (this.tasktype.tstatus == "Discarded")|| (this.tasktype.tstatus == "Tech and Business Review") || (this.tasktype.tstatus == "Product WIP 2") || (this.tasktype.tstatus == "Tech Issue Resolution")) {
                            this.errors.tasktype.taskestimates="";
                        }else{
                            if(this.tasktype.taskestimates){
                                this.errors.tasktype.taskestimates = '';
                            }else{
                                this.errors.tasktype.taskestimates = 'Ballpark Estimate in days is required!'
                                isValid = false
                            }
                        }
                    }
                } else {
                    if(this.tasktype.tstatus && (this.tasktype.tstatus == "Product WIP") || (this.tasktype.tstatus == "Product Pipeline") || (this.tasktype.tstatus == "Product Completed") || (this.tasktype.tstatus == "OnHold") || (this.tasktype.tstatus == "Discarded")|| (this.tasktype.tstatus == "Tech and Business Review") || (this.tasktype.tstatus == "Product WIP 2") || (this.tasktype.tstatus == "Tech Issue Resolution")) {
                        this.errors.tasktype.taskestimates="";
                    }else{
                        if(this.tasktype.taskestimates){
                            this.errors.tasktype.taskestimates = '';
                        }else{
                            this.errors.tasktype.taskestimates = 'Ballpark Estimate in days is required'
                            isValid = false
                        }
                    }
                }
                
                if(this.finalvalidationArray['Estimated End Date'])
                {
                    this.errors.tasktype.enddate = '';
                    this.errors.tasktype.edd_reason = '';
                    if(this.finalvalidationArray['Estimated End Date'] == 'Required')
                    {
                        if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product WIP 2' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'OnHold' && this.tasktype.tstatus != 'Discarded' && this.tasktype.tstatus != 'Tech and Business Review' && this.tasktype.tstatus != 'Tech Pipeline'){
                            if (this.tasktype.startdate && !this.tasktype.enddate) {
                                this.errors.tasktype.enddate="Estimated date is required!";
                                    isValid= false;
                            }
                            if(this.editRowData.estimatedddate &&  this.tasktype.enddate && this.tasktype.enddate != this.editRowData.estimatedddate){
                                if(!this.tasktype.edd_reason){
                                    this.errors.tasktype.edd_reason="Estimated Update Reason is required";
                                    isValid = false;
                                }
                            }
                        }
                        if(this.tasktype.tstatus == "Tech WIP"){
                            if(!this.tasktype.enddate && this.tasktype.enddate==null){
                                this.errors.tasktype.enddate="Estimated date is required";
                                isValid= false;
                            }
                        }
                    }
                } else {
                    if(this.tasktype.tstatus != 'Product Pipeline' && this.tasktype.tstatus != 'Product WIP' && this.tasktype.tstatus != 'Product WIP 2' && this.tasktype.tstatus != 'Product Completed' && this.tasktype.tstatus != 'OnHold' && this.tasktype.tstatus != 'Discarded' && this.tasktype.tstatus != 'Tech and Business Review' && this.tasktype.tstatus != 'Tech Pipeline'){
                        if (this.tasktype.startdate && !this.tasktype.enddate) {
                            this.errors.tasktype.enddate="Estimated date is required";
                                isValid= false;
                        }
                        if(this.editRowData.estimatedddate &&  this.tasktype.enddate && this.tasktype.enddate != this.editRowData.estimatedddate){
                            if(!this.tasktype.edd_reason){
                                this.errors.tasktype.edd_reason="Estimated Update Reason is required";
                                isValid = false;
                            }
                        }
                    }
                    if(this.tasktype.tstatus == "Tech WIP"){
                        if(!this.tasktype.enddate && this.tasktype.enddate==null){
                            this.errors.tasktype.enddate="Estimated date is required";
                            isValid= false;
                        }
                    }
                }
                
                if(this.finalvalidationArray['Start Date'])
                {
                    this.errors.tasktype.startdate = '';
                    if(this.finalvalidationArray['Start Date'] == 'Required')
                    {
                        if(this.tasktype.tstatus == "Tech WIP"){
                            if(!this.tasktype.startdate && this.tasktype.startdate==null){
                                this.errors.tasktype.startdate="Start Date is required!";
                                isValid= false;
                            }
                        }
                    }
                }else{
                    if(this.tasktype.tstatus == "Tech WIP"){
                        if(!this.tasktype.startdate && this.tasktype.startdate==null){
                            this.errors.tasktype.startdate="Start date is required";
                            isValid= false;
                        }
                    }
                }

                
                if(this.finalvalidationArray['Status Due Date'])
                {
                    this.errors.tasktype.duedate = ''
                    if(this.finalvalidationArray['Status Due Date'] == 'Required')
                    {
                        if(this.tasktype.tstatus != this.editRowData.taskstatus){
                            if((this.tasktype.tstatus != 'OnHold') && (this.tasktype.tstatus != 'Completed') && (this.tasktype.tstatus != 'Discarded')){
                                if(!this.tasktype.duedate || this.tasktype.duedate == "" || this.tasktype.duedate == null){
                                    this.errors.tasktype.duedate="Status Due Date is required!";
                                    isValid= false;
                                }
                            }
                        }
                    }
                } else {
                    if(this.tasktype.tstatus != this.editRowData.taskstatus){
                        if((this.tasktype.tstatus != 'OnHold') && (this.tasktype.tstatus != 'Completed') && (this.tasktype.tstatus != 'Discarded')){
                            if(!this.tasktype.duedate || this.tasktype.duedate == "" || this.tasktype.duedate == null){
                                this.errors.tasktype.duedate="Status due date is required";
                                isValid= false;
                            }
                        }else{
                            this.errors.tasktype.duedate = ''
                        }
                    }
                }
                
                if(this.finalvalidationArray['Business Project Name'])
                {
                    this.errors.tasktype.businessname='';
                    if(this.finalvalidationArray['Business Project Name'] == 'Required')
                    {
                        if(!this.tasktype.businessname){
                            this.errors.tasktype.businessname="Business Project Name is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(!this.tasktype.businessname){
                        this.errors.tasktype.businessname="Business project name is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.businessname='';
                    }
                }
                
                if(this.finalvalidationArray['Subscribers'])
                {
                    this.errors.tasktype.subscribers='';
                    if(this.finalvalidationArray['Subscribers'] == 'Required')
                    {
                        if((!this.tasktype.subscribers) || (this.tasktype.subscribers && this.tasktype.subscribers.length < 1)) {
                            this.errors.tasktype.subscribers="Subscribers is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if((!this.tasktype.subscribers) || (this.tasktype.subscribers && this.tasktype.subscribers.length < 1)) {
                        this.errors.tasktype.subscribers = 'Subscribers are required'
                        isValid = false
                    } else {
                        this.errors.tasktype.subscribers = ''
                    }
                }

                if(this.finalvalidationArray['Project Module'])
                {
                    this.errors.tasktype.projectmodules="";
                    if(this.finalvalidationArray['Project Module'] == 'Required')
                    {
                        if(this.tasktype.projectmodules == null || this.tasktype.projectmodules == '' || this.tasktype.projectmodules == []) {
                            this.errors.tasktype.projectmodules="Project Module is required!";
                            isValid= false;
                        }
                    }
                } else {
                    if(this.tasktype.projectmodules == null || this.tasktype.projectmodules == '' || this.tasktype.projectmodules == []) {
                        this.errors.tasktype.projectmodules="Project Module is required";
                        isValid= false;
                    }else{
                        this.errors.tasktype.projectmodules="";
                    }
                }

                let allDataFilled =  this.tasktype.type
                && this.tasktype.key
                && this.tasktype.references && this.tasktype.bsh
                && this.tasktype.dept 
                if(!allDataFilled) {
                    isValid = false
                }
            }else if(mdl=="doc"){
                for(const index in this.docs){
                    if(!this.docs[index].title || !this.docs[index].comment || !this.docs[index].desc){
                        isValid=false
                    }
                }                
            }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                 isChecked:null,
                validestimatehrs:null,
                tasktype:{
                    name:null,
                    projectid:null,
                    desc:null,
                    level:null,
                    startdate: null,
                    enddate: null,
                    duedate: null,
                    projectmoduleid: null,
                }
            };
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        getEmpallocatedtoProject(projectid) {
            axios({
                method: "POST",
                url: "api/listing/getEmpallocatedtoProject",
                data: { projectidarr: projectid, moduletype: 'businesstask',useremail: this.undt.username,empcode: this.undt.userid },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.subscriberslist = result.data.data;
                    let decryptdepartmenttext = this.tasktype.subscriberslist.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                } else {
                    this.tasktype.subscriberslist = [];
                }
            });
        },
        getModules(projectid,moduleid, moduletype){
            this.isLoading = true;
            let url = "api/master/businesstask/getmoduleid";
            this.input = {projectid,moduleid, moduletype,useremail: this.undt.username,empcode: this.undt.userid};
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.projectmodules = result.data.data.map(d => d.id);
                    let moduleids =[];
                    if(this.tasktype.projectmodules && this.tasktype.projectmodules.length > 0 && this.tasktype.projectmodulelist && this.tasktype.projectmodulelist.length > 0){
                        this.tasktype.projectmodules.forEach(item => {
                            let checkexistprojectmodules = this.tasktype.projectmodulelist.find(el => el.id == item);
                            if(!checkexistprojectmodules){
                                moduleids.push(item);
                            }
                        })
                    }
                    if(moduleids && moduleids.length>0){
                        this.getProjectbyMOdulesbyid(moduleids);
                    }
                
                } else {
                    this.tasktype.projectmodules = null
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectbyMOdulesbyid(moduleids) {
            axios({
                method: "POST",
                url: "api/master/businesstask/getprojectmodulebyprojectid",
                data: { moduleidarr: moduleids, moduletype: 'businesstask',useremail: this.undt.username,empcode: this.undt.userid},
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(result.data.data && result.data.data.length > 0){

                        if(result.data.data && result.data.data.length > 0 && this.tasktype.projectmodulelist && this.tasktype.projectmodulelist.length > 0){
                            result.data.data.forEach(item => {

                                let checkexistprojectmodules = this.tasktype.projectmodulelist.find(el => el.id == item.id);
                                if(!checkexistprojectmodules){
                                    this.tasktype.projectmodulelist.push(item);
                                }

                            })
                        }
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectbyMOdules(projectid) {
            axios({
                method: "POST",
                url: "api/master/businesstask/getprojectmodulebyprojectid",
                data: { projectidarr: projectid, moduletype: 'businesstask',useremail: this.undt.username,empcode: this.undt.userid, isfrompage: 'updatepage'},
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.projectmodulelist = result.data.data
                } else {
                    this.tasktype.projectmodulelist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBusinessSpenttime(moduleid){
            this.isLoading = true;
            let url = "api/master/businesstask/getBusinessSpenttime";
            this.input = {
                businesstaskmasterid:moduleid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    let d = result.data.data[0]
                    this.tasktype.totaltime = d.totaltime?(`${d.totaltime.hours ? d.totaltime.hours: 0}:${d.totaltime.minutes ? d.totaltime.minutes: 0}`):0
                    this.hourcalculator(this.tasktype.totaltime, this.tasktype.estimatedhours);
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBusinessname() {
            this.input = {
                empcode: this.undt.userid,
                useremail:this.undt.username
            }
            axios({
                method: "POST",
                url: "api/listing/getBusinessname",
                data: this.input,
                headers: { authorization : this.tokendata },
            })
            .then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.businessnamelist = result.data.data.rows
                } else {
                    this.tasktype.businessnamelist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectManagerDetails(roleid,projectid) {
            axios({
                method: "POST",
                url: "api/listing/getProjectManagerDetails",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,projectidarr:projectid,empcode: this.undt.userid, useremail: this.undt.username},
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==477){
                        this.tasktype.pmlist = result.data.data.rows
                    }else if(roleid==492){
                        this.tasktype.emlist = result.data.data.rows
                    }else if(roleid==503){
                        this.tasktype.proglist = result.data.data.rows
                    }
                }else{
                    if(roleid==477){
                        this.tasktype.pmlist = [];
                    }else if(roleid==492){
                        this.tasktype.emlist = [];
                    }else if(roleid==503){
                        this.tasktype.proglist = [];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getTaskmanager(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,useremail: this.undt.username,empcode: this.undt.userid},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==131){
                        this.tasktype.taskmanagerlist=result.data.data.rows;
                    }else if (roleid==513){
                        this.tasktype.taskownerlist=result.data.data.rows;
                    }
                    
                }else{
                    if(roleid==131){
                        this.tasktype.taskmanagerlist=[]
                    }else if (roleid==513){
                        this.tasktype.taskownerlist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getBTaskListToTagBlocker(node) {
            if(node != null && node != '' && node.length > 4) {
                this.isLoading = true;
                let url = "api/blocker/getBTaskListToTagBlocker";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    node: node ? node.toLowerCase() : null,
                    id: parseInt(this.$route.query.businessid),
                    moduletype: this.blockertaskdetails.moduletype ? this.blockertaskdetails.moduletype : 'businesstask',
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.tasksblockerlist = result.data.data.rows.map(item => ({ id: item.id, label: item.name+' - '+item.code }));
                    } else {
                        this.tasksblockerlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } else {
                this.tasksblockerlist = [];
            }
        },
        getProjectQAdata(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==509 && state.length >0){
                        this.tasktype.qaleadlist=result.data.data.rows;
                    }else if (roleid==508 && state.length >0){
                        this.tasktype.qamnglist=result.data.data.rows;
                    }
                }else{
                    if(roleid==509){
                        this.tasktype.qaleadlist=[]
                    }else if (roleid==508){
                        this.tasktype.qamnglist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        selSubscribers(state) {
            this.tasktype.subscribers = null
            this.tasktype.subscribers = state
            if(!state || (state && state.length < 1)) {
                this.errors.tasktype.subscribers = 'Subscribers are required'
            } else {
                this.errors.tasktype.subscribers = ''
            }
        },
        selTaskmanager(state){
            if(!state){
                this.errors.tasktype.taskmanager = 'Task Manager is required'
            } else {
                this.errors.tasktype.taskmanager = ''         
            }
        },
        selectBusinessname(state){
            if(!state){
                this.errors.tasktype.businessname = 'Business project name is required'
            } else {
                this.errors.tasktype.businessname = ''         
            }
        },
        validRedmineID : function(inputval) {
            var re = /[A-Za-z0-9].{2,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            if(tblNm=="t9"){
                if(this.tasktype.name){
                    this.inputval = this.tasktype.name
                    this.valid = true;
                }
                this.valid=false;
                if(this.tasktype.desc){
                    this.inputval = this.tasktype.desc
                    this.valid = true
                }else{
                    this.tasktype.descerror = true
                }
                this.valid=false;
                if(this.tasktype.level){
                    this.inputval = this.tasktype.level
                    this.valid = true
                }else{
                    this.tasktype.levelerror = true
                }
                return this.valid;
            }
            if( this.inputval && this.validText(this.inputval)){
                this.valid = true;
            }
            
        },
        dateFormatnew(value) {
            if (!value) return "";
            return moment(String(value)).format('YYYY-MM-DD');
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        dateFormat2(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        dateMonthFormat1(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY');
        },
        onZoom(e){
        this.getLogtimeDownloadLink(e, 'zoom');
        },
        inZoom(e) {
            this.getDownloadedzoom(e, 'zoom');
        },
        diffBetweenDates(d2, d1) {
            let date1 = moment(d1)
            let date2 = moment(d2)
            let years = date1.diff(date2, 'year');
            date2.add(years, 'years');

            let months = date1.diff(date2, 'months');
            date2.add(months, 'months');

            let days = date1.diff(date2, 'days');
            date2.add(days, 'days');

            let hours = date1.diff(date2, 'hours');
            date2.add(hours, 'hours');

            let minutes = date1.diff(date2, 'minutes');
            date2.add(minutes, 'minutes');
            let result = ''
            if(years) {
                if(years > 1) {
                    result += `${years} Years `
                } else {
                    result += `${months} Year `
                }
            }
            if(months) {
                if(months > 1) {
                    result += `${months} Months `
                } else {
                    result += `${months} Month `
                }
            }
            if(days) {
                if(days > 1) {
                    result += `${days} Days `
                } else {
                    result += `${days} Day `
                }
            }
            if(hours) {
                if(hours > 1) {
                    result += `${hours} Hours `
                } else {
                    result += `${hours} Hour `
                }
            }

            if(minutes) {
                if(minutes > 1) {
                    result += `${minutes} Minutes`
                } else {
                    result += `${minutes} Minute`
                }
            }
            return result
        },
        getProjectList() {
            this.isLoading = true;
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    localStorage.setItem('projectlist', JSON.stringify(result.data.data))
                } else {
                    this.tasktype.projectlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        projectChange: function (pid){
            if(pid && pid.length>0){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectidarr: pid,
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    isfrompage: 'isprojectid'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.tasktype.projectlist = result.data.data;
                    } else {
                        this.tasktype.projectlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getProjectAllocatedToEmp: function(node) {
            if(node && node.length > 3){
                this.isLoading = true
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname: node.toLowerCase(),
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    isfrompage: 'updatepage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers': { 'authorization': this.tokendata }
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.projectlist = result.data.data;
                    localStorage.setItem('projectlist', JSON.stringify(result.data.data))
                } else {
                    this.tasktype.projectlist = [];
                    localStorage.removeItem('projectlist')
                }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getApplicationdata(mdl,curracttab){
            this.isLoading=true
            this.pageoffset=1
            
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid
            }
            if(mdl=="t11"){
                this.inputappl.limit=500
            }
            let apiURL='api/master/commonmaster/list'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.projectType=[];
                    this.projectType=result.data.data.rows
                    let tmpClassNm=''
                        if(mdl == 't25'){
                            if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't17'){
                            if(curracttab == 'taskname-tab'){
                                tmpClassNm='tasknametaskleveldd'
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true                                
                            }
                        }else if(mdl == 't2'){
                            tmpClassNm='devBusinessVerticleData'
                        }else if(mdl == 't23'){
                            if(curracttab == 'tasktype-tab'){
                                this.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't24'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasklevel=result.data.data.rows
                                return true
                            }else if(curracttab == 'task'){
                                this.tasklevellist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't25'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasknamemaster=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true
                            }
                        }
                        else if(mdl == 't11'){
                            if(curracttab == 'task'){
                                this.tasktype.projectlist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.projectlist=result.data.data.rows
                                return true
                            }                                
                        }
                        else if(mdl == 't21'){
                            if(curracttab == 'taskassignment-tab'){
                                this.assignment.employeeoptions=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.employeeoptions=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't18'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskdefineList=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskassignment-tab'){
                                this.assignment.tasklist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't19'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskassignmentList=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't9'){
                            if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.businesstasklist=result.data.data.rows
                                return true
                            }
                        }
                        let payload={
                            'classnm':tmpClassNm
                        }
                        this.fillDropdown(payload);
                }
                else if(result.data.errorCode == 3){
	                Swal.fire({
		            title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
              		window.location.href = "/#/login";
            	})
                } 
            })
        },
        fillDropdown(data){
            return ;
        },
        selectserPriority(state, value) {
            this.tasktype.key = null
            this.tasktype.key = state
        },
        selecttypePriority(state, value) {
            this.tasktype.type = null
            this.tasktype.type = state
        },
        selectrefPriority(state, value) {
            this.tasktype.references = null
            this.tasktype.references = state
        },
        selecttaskstatus(state, value) {
            this.tasktype.tstatus = null
            this.tasktype.tstatus = state
            if((state != 'OnHold') && (state != 'Completed') && (state != 'Discarded')){
                if(this.tasktype.tstatus != this.editRowData.taskstatus){
                    this.tasktype.duedate = ''
                    this.errors.tasktype.duedate = 'Status due date is required' 
                    if(this.tasktype.tstatus == "Tech WIP"){
                        this.tasktype.startdate = null
                        this.tasktype.enddate = null
                    }               
                }
            }else{
                this.errors.tasktype.duedate = ''
            }
            if((state == "Product WIP") || (state== "Product Pipeline") || (state == "Product Completed") || (state == "OnHold") || (state == "Discarded") || (state == "Tech and Business Review") || (state == "Product WIP 2")) {
                this.errors.tasktype.taskestimates = ''
            } 
            if(this.tasktype.tstatus != this.editRowData.taskstatus){
                if (this.tasktype.tstatus == 'Product WIP') {
                    this.tasktype.startdate = this.taskstartdate
                    this.tasktype.enddate = ''
                    this.isdisableds= true
                }else{
                    this.isdisableds= false
                    this.tasktype.startdate = this.editRowData.startdate
                    this.tasktype.enddate = this.editRowData.estimatedddate
                }
            }
            if (this.tasktype.tstatus == 'Product WIP') {
                this.isdisableds= true
                if(this.tasktype.startdate && this.tasktype.startdate!=null) {
                    this.isenableestimatedate = false;
                } else {
                    this.isenableestimatedate = true;
                }
            }else{
                this.isdisableds= false
            }
            this.getprojectmaprolide(this.loginuscondition,'1',this.tasktype.projectid);
        },
        selestimateshours(tstatus) {
            if(tstatus && (this.tasktype.tstatus == "Product WIP") || (this.tasktype.tstatus == "Product Pipeline") || (this.tasktype.tstatus == "Product Completed") || (this.tasktype.tstatus == "OnHold") || (this.tasktype.tstatus == "Discarded")|| (this.tasktype.tstatus == "Tech and Business Review") || (this.tasktype.tstatus == "Product WIP 2")) {
                this.errors.tasktype.taskestimates = ''
            } else {
                if(tstatus){
                    this.errors.tasktype.taskestimates = '';
                }else{
                    this.errors.tasktype.taskestimates = 'Ballpark Estimate in days is required'
                }            
            }
        },
        sellogBallparkestimates(state) {
            if(!state) {
                this.errors.taskestimates = 'Ballpark estimate in days is required'
            } else {
                this.errors.taskestimates = ''
            }
        },
        notBeforeToday: function (date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        notBeforeTodaydate(date) {
            let dayBefore = moment(this.logtime.startdate).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; 
        },
        
        selectBSH(state) {
            if(!state){
                this.errors.tasktype.bsh = 'HOD name is required'
            } else {
                this.errors.tasktype.bsh = ''         
            }
        },
        selectbtmDept(state, value) {
            if(!state){
                this.tasktype.depterr = true
                this.tasktype.bshlist = []
            } else {                
                this.tasktype.depterr = false
                if(this.tasktype.projectid.length>0){
                    this.getBSHlist(state, this.tasktype.projectid)
                }
            }
        },
        selProjectMOdules(state){
            this.tasktype.projectmodules = null
            this.tasktype.projectmodules = state
            if(!state) {
                this.errors.tasktype.projectmodules = 'Project Module is required'
            } else {
                this.errors.tasktype.projectmodules = ''
            }
            if(this.activePageFlag==0){
            let newarr = state;
            let prevarr = this.tasktype.pmodulelist;
            let added = null;
            let addedarr = [];
            let finalArr = this.vieweditmoduledocs;
            let addedConter = 0;
            let removedConter = 0;

            
            if(newarr.length > this.prevarrlen){
              addedConter++; 
            }else if(newarr.length < this.prevarrlen){
              removedConter++;
            }
            
            for(let n=0; n<newarr.length; n++){
              let isfound = false;
              for(let p=0; p<prevarr.length; p++){
                if(newarr[n]===prevarr[p]){
                    isfound = true;
                }
              }
              if(!isfound){
                  added = newarr[n] 
                  addedarr.push(newarr[n]) 
              }
            }
            
            if(addedConter>0){
              if(added!=null && [added].length>0){
                if(!state.includes(added)==false){
                  const alredyexist = finalArr.find((obj) => obj.projectmoduleid === added);
                  if(alredyexist==undefined){
                      this.prevarrlen = state.length;
                      this.getProjectByBusinessTaskUPDATE([added])
                  }
                }
              }
                
                if(prevarr.includes(added)==false){
                  prevarr.forEach(preObj => {
                      const objWithIdIndex = finalArr.find((obj) => obj.projectmoduleid === preObj);
                      if(objWithIdIndex==undefined){
                        if(state.includes(preObj)==true){
                          this.getProjectByBusinessTaskEDIT([preObj], this.$route.query.businessid, 'update', true)
                        }
                      }
                  });
                } 
            }

            if((newarr.length != (finalArr.length+1))){
              finalArr.forEach(finalarrObj => {
                let foundq = false;
                let removednew = [];
                  newarr.forEach(onnk=>{
                    if(state.includes(finalarrObj.projectmoduleid)){
                      foundq = true;
                    }
                  })
                  if(!foundq){
                    const objWithIdIndex = finalArr.findIndex((obj) => obj.projectmoduleid === finalarrObj.projectmoduleid);
                    if (objWithIdIndex > -1) {
                      finalArr.splice(objWithIdIndex, 1);
                      this.prevarrlen = state.length;
                    }
                  }
              })
            }

            
            if(removedConter>0){
              prevarr.forEach(preObj => {
              const found = newarr.find(newObj => JSON.stringify(newObj) == JSON.stringify(preObj));
                if(!found) {
                  if(preObj){
                    const objWithIdIndex = finalArr.findIndex((obj) => obj.projectmoduleid === preObj);
                    if (objWithIdIndex > -1) {
                      finalArr.splice(objWithIdIndex, 1);
                      this.prevarrlen = state.length;
                    }
                  }
                }
              });
            }
            }
        },
        deSelectbtmDept() {
            this.tasktype.bsh = null
            this.tasktype.bshlist = []
        },
        getBSHlist(dept, projectid) {
            axios({
                method: "POST",
                url: "api/listing/getBTCHODlist",
                data: { dept:[dept],useremail: this.undt.username,empcode: this.undt.userid, projectid:projectid },
                headers: { authorization : this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.bshlist = result.data.data.rows
                } else {
                    this.tasktype.bshlist = []
                }
            }).catch(e => {
                    this.displayError(e)
                });
        },
        seltaskleveldata(state,value){
            this.tasktype.level=null
            this.tasktype.level=state
            if(!state && this.isAddState == false){
                this.tasktype.levelerror=true
            }else{                
                this.tasktype.levelerror=false
            }
        },
        seltpprojectdata(state,value){
            this.tasktype.projectid=null
            this.tasktype.projectid=state
            if(this.havingsubtasks == true) {
                this.tasktype.projectid=state.map(d => parseInt(d))
            } else {
                this.tasktype.projectid=state.map(d => d.toString())
            }
            this.tasktype.projectmodulelist = []
            this.tasktype.projectmodules = null
            if (!state.length >0) {
                this.tasktype.qalead = null
                this.tasktype.qamanager = null
                this.tasktype.qaleadlist = []
                this.tasktype.qamnglist = []
            }
            if(!state && this.isAddState == false){
                this.tasktype.projectlist = null
                this.tasktype.subscribers = null
                this.tasktype.projectmodules = [null]
                this.tasktype.projectmodulelist = []
                this.tasktype.qalead = null
                this.tasktype.qamanager = null
                this.tasktype.qaleadlist = []
                this.tasktype.qamnglist = []
                this.errors.tasktype.projectid="Project Required"
            }else{                
                this.errors.tasktype.projectid=null
                this.getProjectManagerDetails(492,state)
                this.getProjectManagerDetails(477,state)
                this.getProjectManagerDetails(503,state)
                this.getTaskmanager(131,state)
                this.getTaskmanager(513,state)
                this.getProjectQAdata(509,state);
                this.getProjectQAdata(508,state);
                if(this.tasktype.isedit == true) {
                    this.getEmpallocatedtoProject(state);
                    this.getProjectbyMOdules(state);
                    this.getTasktypeConfigList(state);
                }
                this.projectmultiplename = state;
                if(state && state.length > 0){
                    this.getModules(state,this.$route.query.businessid,'businesstask')
                }
                if(this.issubtask != false && state != this.editRowData.projectist){
                    this.getBStatusConfigList(state,this.tasktype.tstatus)
                }
                this.getprojectmaprolide(this.loginuscondition,'1', this.tasktype.projectid);
            }
        },
        seltasklogstatus(state, value) {
        this.logtime.status = null
        this.logtime.status = state
        if(state) {
            this.errors.status = ''
        }else{
            this.errors.status = 'Task status is required'
        }
        if((this.logtime.status != 'Completed') && (this.logtime.status != 'OnHold') && (this.logtime.status != 'Discarded')){
            if(this.logtime.status != this.editRowData.taskstatus){
                    this.logtime.duedate = ''
                    this.errors.duedate = 'Status due date is required'
                }
            }else{
                this.errors.duedate = ''
            }  
        },
        seltasklogactivity(state, value) {
        this.logtime.activity = null
        this.logtime.activity = state
        if(!state) {
        } else {
            this.errors.activity = null
        }
        },
        onSelect(event){
            this.files= event.target.files;
            if(this.files.length>0)
            this.isdisabled=true             
        },
        saveDocument(id){
            this.input=[]
            let newlogattach = []
            let isValid = this.validateForm('doc',this.docs)
            if(isValid){
                this.isLoading = true;
                if(this.attachment && this.attachment.length == this.docs.length){
                    for(let index in this.attachment){
                        this.input.push({
                            documentid:this.attachment[index].batchid,
                            documentname:this.attachment[index].filename,
                            title:this.docs[index].title,
                            desc:this.docs[index].desc,
                            comment:this.docs[index].comment,
                            version:'v1',
                            createdby:this.undt.username,
                            taskid:this.tasktype.taskid,
                            projectname: this.moduledocs[id].projectname,
                            projectid: this.moduledocs[id].projectid,
                            projectmodulename: this.moduledocs[id].projectmodulename,
                            projectmoduleid: this.moduledocs[id].moduleid,
                            modulepriority: this.moduledocs[id].modulepriority,
                            modulestatus: this.moduledocs[id].modulestatus,
                            businesstaskmoduleassignmentid:this.moduledocs[id].businesstaskmoduleassignmentid,
                            businesstaskmasterid:this.moduledocs[id].businesstaskmasterid,
                            useremail: this.undt.username,
                            empcode: this.undt.userid
                        })
                        newlogattach.push({
                            info: this.attachment[index].desc,
                            link: this.attachment[index].filename,
                        })
                    }
                    this.input.empcode = this.undt.userid,
                    this.input.useremail= this.undt.username
                    this.logattachment = { data: newlogattach }
                    if(this.input.length>0){
                        let url = "api/workstep/bulkinsert";
                        axios({
                            method: "POST",
                            url: url,
                            data: {document:this.input,useremail: this.undt.username,empcode: this.undt.userid},
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading = false;
                            this.attachment=[]
                            if (result.data.errorCode == 0) {                                
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                                this.docsuploadcollapse()
                                let rdata ={
                                    moduleid:this.tasktype.taskid
                                }
                                this.getDocumetList(rdata)
                            } else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Failed to upload document",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        });
                    }else{
                        this.isLoading = false;
                    }
                }else {
                    Swal.fire({
                        title: "Failed",
                        text: "All Fields are compulsory",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading = false;
                }
            }
        },
        validateModuleDocs(files) {
            var valid = true
            if(!files.length) return false
            for(let index in files){
                let modulefiles = files[index].modulefiles
                for(let i in modulefiles) {
                    if(!modulefiles[i].title || !modulefiles[i].comment || !modulefiles[i].desc || !modulefiles[i].attachment){
                        valid = false
                    }
                }
            }  
            return valid
        },
        newSaveDocument(){
            this.input=[]
            let newlogattach = []
            let isValid = true
            if(isValid){
                if(this.moduledocs.length){
                    let temparr = []
                    for(let i=0; i<this.moduledocs.length; i++) {
                        let uploadtedfiles = this.moduledocs[i].modulefiles
                        for(let j=0; j<uploadtedfiles.length; j++) {
                            let attachinfo = uploadtedfiles[j]
                            if(attachinfo.attachment) {
                                let obj = {
                                    documentid:attachinfo.attachment.batchid,
                                    documentname:attachinfo.attachment.filename,
                                    title:attachinfo.title,
                                    desc:attachinfo.desc,
                                    comment:attachinfo.comment,
                                    version:'v1',
                                    createdby:this.undt.username,
                                    updatedby:this.undt.username,
                                    taskid:this.tasktype.taskid,
                                    projectname: this.moduledocs[i].projectname,
                                    projectid: this.moduledocs[i].projectid,
                                    projectmodulename: this.moduledocs[i].projectmodulename,
                                    projectmoduleid: this.moduledocs[i].projectmoduleid,
                                    modulepriority: this.moduledocs[i].modulepriority,
                                    modulestatus: this.moduledocs[i].modulestatus,
                                    businesstaskmoduleassignmentid:this.moduledocs[i].businesstaskmoduleassignmentid,
                                    businesstaskmasterid:this.moduledocs[i].businesstaskmasterid,
                                    useremail: this.undt.username,
                                    empcode: this.undt.userid
                                }
                                temparr.push(obj)
                                newlogattach.push({
                                    info: attachinfo.desc,
                                    link: attachinfo.attachment.filename,
                                })
                            }
                        }
                    }
                    this.input = temparr
                    this.input.empcode = this.undt.userid,
                    this.input.useremail= this.undt.username
                    this.logattachment = { data: newlogattach }
                    if(this.input.length>0){
                        let url = "api/workstep/bulkinsert";
                        axios({
                            method: "POST",
                            url: url,
                            data: {document:this.input,empcode: this.undt.userid,useremail: this.undt.username},
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading = false;
                            this.attachment=[]
                            if (result.data.errorCode == 0) {                                
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                                this.docsuploadcollapse()
                                let rdata ={
                                    moduleid:this.tasktype.taskid
                                }
                                this.getDocumetList(rdata)
                            }  else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                            }else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Failed to upload document",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        });
                    }else{
                        this.isLoading = false;
                    }
                }else {
                    Swal.fire({
                        title: "Failed",
                        text: "All Fields are compulsory",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading = false;
                }
            }
        },

        saveDoc(){
            this.input=[]
            let newlogattach = []
            let isValid = this.validateForm('doc',this.docs)
            if(isValid){
                this.isLoading = true;
                if(this.attachment && this.attachment.length){
                    for(let index in this.attachment){
                        if(this.attachment[index].filename) {
                            this.input.push({
                                documentid:this.attachment[index].batchid,
                                documentname:this.attachment[index].filename,
                                title:this.docs[index].title,
                                desc:this.docs[index].desc,
                                comment:this.docs[index].comment,
                                version:'v1',
                                createdby:this.undt.username,
                                taskid:this.tasktype.taskid,
                                empcode: this.undt.userid,
                                useremail: this.undt.username
                            })
                        }
                        newlogattach.push({
                            info: this.attachment[index].desc,
                            link: this.attachment[index].filename,
                        })
                    }
                    this.logattachment = { data: newlogattach }
                    if(this.input.length>0){
                        let url = "api/workstep/btmdocinsert";
                        axios({
                            method: "POST",
                            url: url,
                            data: {document:this.input,empcode: this.undt.userid,useremail: this.undt.username},
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading = false;
                            this.attachment=[]
                            if (result.data.errorCode == 0) {                                
                                
                                this.docsuploadcollapse()
                                let rdata ={
                                    moduleid:this.tasktype.taskid
                                }
                                this.getDocumetList(rdata)
                            } else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                            }else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Failed to upload document",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        }).catch(e => {
                            this.displayError(e)
                        });

                    }else{
                        this.isLoading = false;
                    }
                }else {
                    Swal.fire({
                        title: "Failed",
                        text: "All Fields are compulsory",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                    this.isLoading = false;
                }
            }
        },
        addNewDocsToDB() {
            this.input=[]
            this.isLoading = true;
            if(this.addNewDocs.length){
                for(let index in this.addNewDocs){
                    if(this.addNewDocs[index].isUploaded) {
                        this.input.push({
                            documentid:this.addNewDocs[index].batchid,
                            documentname:this.addNewDocs[index].filename,
                            title:this.addNewDocs[index].title,
                            desc:this.addNewDocs[index].desc,
                            comment:this.addNewDocs[index].comment,
                            version:'v1',
                            createdby:this.undt.username,
                            taskid:this.tasktype.taskid,
                            useremail: this.undt.username,
                            empcode: this.undt.userid                     
                        })
                    }
                }
                if(this.input.length>0){
                    let url = "api/workstep/btmdocinsert";
                    axios({
                        method: "POST",
                        url: url,
                        data: {document:this.input,empcode: this.undt.userid,useremail: this.undt.username},
                        'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                        this.isLoading = false;
                        this.attachment=[]
                        this.addNewDocs = []
                        if (result.data.errorCode == 0) {                                
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            this.addNewDocsUploadCollapse()
                            let rdata ={
                                moduleid:this.tasktype.taskid
                            }
                            this.getDocumetList(rdata)
                           // window.location.reload();
                        } else if(result.data.errorCode == 3){
                                Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                            } else {
                            Swal.fire({
                                title: "Failed",
                                text: "Failed to upload document",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        }
                    }).catch(e => {
                        this.displayError(e)
                    });
                }else{
                    this.isLoading = false;
                }
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "All Fields are compulsory",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading = false;
            }
        },
        clickCallback_subtasklist: function (pageNum) {
            this.s_pageoffset = pageNum;
            this.s_currPageNum = pageNum - 1;

            this.getBlockerListByBusinessTask(this.$route.query.businessid)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#card-navigation").offset().top -70
                });
            }, 500);
        },
        inputsearchsub() {
            if (this.searchSubBtc !== null) {
                this.subtasksearch = true
                this.getBTCsubTaskIds(this.$route.query.businessid)
            }
        },
        getBTCsubTaskIds(id){
            this.isLoading = true;
            let url = 'api/bt/getBTCsubTaskId'
            this.input = { id, empcode: this.undt.userid, useremail: this.undt.username };
            this.input.limit = apiUrl.LIMIT
            this.input.offset = this.s_pageoffset
            this.input.order = this.order
            this.input.labelname = this.labelname,
            this.input.code = 'true'
            this.input.name = 'true'
            this.input.projectnm = 'true'
            this.input.status = 'true'
            this.input.tasktype = 'true'
            this.input.subpriority = 'true'

            if(this.searchSubBtc) {
                let code = this.searchSubBtc.split('-')[0]
                if (code == 'BTC' || code == 'btc') {
                    this.input.subbtccode = this.searchSubBtc.toUpperCase()
                } else  {
                    this.input.subbtccode = null
                    this.input.taskname = this.searchSubBtc
                    this.input.taskname = this.searchSubBtc.toLowerCase()
                }
            } 
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.subtasklist = result.data.data.rows
                    this.s_totalcount = result.data.data.count;
                    this.s_pageCount = Math.ceil(this.s_totalcount / this.s_pagelimit)
                } else {
                    this.subtasklist = []
                    this.s_totalcount = 0
                    this.s_pageCount = 0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        addNewModuleDocsToDB(){
            this.input=[]
            let isValid = true
            if(isValid){
                let temparr = []
                let updatedInfoArr = []
                for(let i=0; i<this.projectlistofbusiness.length; i++) {
                    let thisModuleDoc = this.projectlistofbusiness[i]
                    updatedInfoArr.push(thisModuleDoc)
                    let addNewModuleDocs = thisModuleDoc.addNewModuleDocs
                    for(let j=0; j<addNewModuleDocs.length; j++) {
                        let attachinfo = addNewModuleDocs[j]
                        if(attachinfo.attachment) {
                            let obj = {
                                documentid:attachinfo.batchid,
                                documentname:attachinfo.filename,
                                title:attachinfo.title,
                                desc:attachinfo.desc,
                                comment:attachinfo.comment,
                                version:'v1',
                                createdby:this.undt.username,
                                taskid:this.tasktype.taskid,
                                projectname: thisModuleDoc.projectname,
                                projectid: thisModuleDoc.projectid,
                                projectmodulename: thisModuleDoc.projectmodulename,
                                projectmoduleid: thisModuleDoc.projectmoduleid,
                                modulepriority: thisModuleDoc.modulepriority,
                                modulestatus: thisModuleDoc.modulestatus,
                                businesstaskmoduleassignmentid:thisModuleDoc.businesstaskmoduleassignmentid,
                                businesstaskmasterid:thisModuleDoc.businesstaskmasterid,
                                updatedby: this.loginusername
                            }
                            temparr.push(obj)
                        }
                    }
                }
                this.input = temparr
                this.input.empcode = this.undt.userid,
                this.input.useremail= this.undt.username
                this.isLoading = true;
                if(this.input.length>0){
                    let url = "api/workstep/bulkinsert";
                    axios({
                        method: "POST",
                        url: url,
                        data: {document:this.input,empcode: this.undt.userid,useremail: this.undt.username},
                        'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                        this.isLoading = false;
                        this.attachment=[]
                        this.addNewDocsUploadCollapse()
                        for(let i=0; i<this.projectlistofbusiness.length; i++) {
                            this.projectlistofbusiness[i].addNewModuleDocs = []
                        }
                        if (result.data.errorCode == 0) {                                
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            let rdata ={
                                moduleid:this.tasktype.taskid
                            }
                            this.getDocumetList(rdata)
                            window.location.reload();
                        } else if(result.data.errorCode == 3){
                            Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                        }else {
                            Swal.fire({
                                title: "Failed",
                                text: "Failed to upload document",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        }
                    }).catch(e => {
                    this.displayError(e)
                });
                }else{
                    let url = `api/master/businesstask/updateBTMDocModuleInfo`
                    let payload = []
                    for(let i=0; i<updatedInfoArr.length; i++) {
                        let p = updatedInfoArr[i]
                        let obj = {
                            projectid: p.projectid,
                            projectmoduleid: p.projectmoduleid,
                            modulepriority: p.modulepriority,
                            modulestatus: p.modulestatus,
                            businesstaskmoduleassignmentid: parseInt(p.businesstaskmoduleassignmentid),
                            businesstaskmasterid: parseInt(p.businesstaskmasterid),
                            updatedby: this.loginusername,
                            empcode : this.undt.userid,
                            useremail : this.undt.username
                        }
                        payload.push(obj)
                    }
                    axios({
                        method: "POST",
                        url: url,
                        data: payload,
                        'headers':{'authorization':this.tokendata}
                    }).then(result => {
                        this.isLoading = false;
                        this.addNewDocsUploadCollapse()
                        if (result.data.errorCode == 0) {                                
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        } else {
                            Swal.fire({
                                title: "Failed",
                                text: "Failed to upload document",
                                icon: "info",
                                customClass: {
                                confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        }
                    }).catch(e => {
                        this.displayError(e)
                    })
                }
            }
        },
        async onUpload(event,index, doclist, moduletype=null) {    
            this.apiURL = "api/s3upload/uploadDocumentToS3";
            if(this.files.length) {
                this.isLoading=true
                this.files.forEach(file => {
                    if (file.size > 1024 * 1024 * 21) {
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 21mb not allow",
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.isLoading=false
                        return false; 
                    } 
                    if(file.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file);
                        formData.append("empcode", this.undt.userid);
                        formData.append("useremail", this.undt.username);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            this.files=[];
                            if (result.data.status) {
                                this.isdisabled=false
                                if(moduletype !=='addNewDocs' && moduletype !== 'addNewModuleDocs') {
                                    if(moduletype !== 'modulefile') {
                                        if(this.attachment){
                                            this.attachment[index] = result.data.data;
                                        }else{
                                            this.attachment = []
                                            this.attachment[index]= result.data.data;
                                        }
                                    } else {
                                        doclist.attachment= result.data.data
                                        doclist.isUploaded = true
                                    }
                                } else {
                                    if(moduletype === 'addNewDocs') {
                                        this.addNewDocs[index].attachment = result.data.data.filename
                                        this.addNewDocs[index].filename = result.data.data.filename
                                        this.addNewDocs[index].batchid = result.data.data.batchid
                                        this.addNewDocs[index].isUploaded = true
                                    } else if (moduletype === 'addNewModuleDocs') {
                                        doclist[index].attachment = result.data.data.filename
                                        doclist[index].filename = result.data.data.filename
                                        doclist[index].batchid = result.data.data.batchid
                                        doclist[index].isUploaded = true
                                    }
                                }
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        })
                    } 
                })
            }else {
                Swal.fire({
                    title: "Failed",
                    text: "Select document",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }            
        },
        readOnlyFieldsValidation(isValid) {
            if(this.finalvalidationArray['HOD Name'])
            {
                this.errors.tasktype.bsh='';
                if(this.finalvalidationArray['HOD Name'] == 'Required')
                {
                    if(!this.tasktype.bsh){
                        this.errors.tasktype.bsh="HOD name is required!";
                        isValid= false;
                    }
                    this.readonlyflds.hodnamefld = false;
                } else if (this.finalvalidationArray['HOD Name'] == 'Read-Only') {
                    this.readonlyflds.hodnamefld = true;
                } 
            } else {
                if(!this.tasktype.bsh){
                    this.errors.tasktype.bsh="HOD name is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.bsh='';
                }
                this.readonlyflds.hodnamefld = false;
            }

            if(this.finalvalidationArray['Project Manager'])
            {
                this.errors.tasktype.proman='';
                if(this.finalvalidationArray['Project Manager'] == 'Required')
                {
                    if(!this.tasktype.proman){
                    this.errors.tasktype.proman="Project Manager is required!";
                    isValid= false;
                    }
                    this.readonlyflds.promanagerfld = false;
                } else if (this.finalvalidationArray['Project Manager'] == 'Read-Only') {
                    this.readonlyflds.promanagerfld = true;
                }
            } else {
                if(!this.tasktype.proman){
                    this.errors.tasktype.proman="Project Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.proman='';
                }
                this.readonlyflds.promanagerfld = false;
            }
              
            if(this.finalvalidationArray['Task Manager'])
            {
                this.errors.tasktype.taskmanager='';
                if(this.finalvalidationArray['Task Manager'] == 'Required')
                {
                    if(!this.tasktype.taskmanager){
                        this.errors.tasktype.taskmanager="Task Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.taskmanagerfld = false;
                } else if (this.finalvalidationArray['Task Manager'] == 'Read-Only') {
                    this.readonlyflds.taskmanagerfld = true;
                }
            } else {
                if(!this.tasktype.taskmanager){
                    this.errors.tasktype.taskmanager="Task Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.taskmanager='';
                }
                this.readonlyflds.taskmanagerfld = false;
            }

            if(this.finalvalidationArray['Task Owner'])
            {
                this.errors.tasktype.taskowner='';
                if(this.finalvalidationArray['Task Owner'] == 'Required')
                {
                    if(!this.tasktype.taskowner){
                        this.errors.tasktype.taskowner="Task Owner is required!";
                        isValid= false;
                    }
                    this.readonlyflds.taskownerfld = false;
                } else if (this.finalvalidationArray['Task Owner'] == 'Read-Only') {
                    this.readonlyflds.taskownerfld = true;
                }
            } else {
                if(!this.tasktype.taskowner){
                    this.errors.tasktype.taskowner="Task Owner is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.taskowner='';
                }
                this.readonlyflds.taskownerfld = false;
            }
            
            if(this.finalvalidationArray['Engineering Manager'])
            {
                this.errors.tasktype.engman='';
                if(this.finalvalidationArray['Engineering Manager'] == 'Required')
                {
                    if(!this.tasktype.engman){
                        this.errors.tasktype.engman="Engineering Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.engmanagerfld = false;
                } else if (this.finalvalidationArray['Engineering Manager'] == 'Read-Only') {
                    this.readonlyflds.engmanagerfld = true;
                } 
            } else {
                if(!this.tasktype.engman){
                    this.errors.tasktype.engman="Engineering Manager is required";
                    isValid= false;
                }else{
                    this.errors.tasktype.engman='';
                }
                this.readonlyflds.engmanagerfld = false;
            }

            if(this.finalvalidationArray['QA Manager'])
            {
                this.errors.tasktype.qamanager='';
                if(this.finalvalidationArray['QA Manager'] == 'Required')
                {
                    if(!this.tasktype.qamanager){
                        this.errors.tasktype.qamanager="QA Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.qamanagerfld = false;
                } else if (this.finalvalidationArray['QA Manager'] == 'Read-Only') {
                    this.readonlyflds.qamanagerfld = true;
                } 
            } else {
                if(this.tasktype.tstatus == "Tech and Business Review" || this.tasktype.tstatus == "UAT"){
                    if(!this.tasktype.qamanager && this.tasktype.qamanager==null){
                        this.errors.tasktype.qamanager="QA Manager is required.";
                        isValid= false;
                    }
                }
                else if(!this.tasktype.qamanager){
                    this.errors.tasktype.qamanager="QA Manager is required";
                    // isValid= false;
                } else {
                    this.errors.tasktype.qamanager='';
                }
                this.readonlyflds.qamanagerfld = false;
            }

            if(this.finalvalidationArray['QA Lead'])
            {
                this.errors.tasktype.qalead='';
                if(this.finalvalidationArray['QA Lead'] == 'Required')
                {
                    if(!this.tasktype.qalead){
                        this.errors.tasktype.qalead="QA Lead is required!";
                        isValid= false;
                    }
                    this.readonlyflds.qaleadfld = false;
                } else if (this.finalvalidationArray['QA Lead'] == 'Read-Only') {
                    this.readonlyflds.qaleadfld = true;
                } 
            } else {
                if(this.tasktype.tstatus == "Tech and Business Review" || this.tasktype.tstatus == "UAT"){
                    if(!this.tasktype.qalead && this.tasktype.qalead==null){
                        this.errors.tasktype.qalead="QA Lead is required.";
                        isValid= false;
                    }
                }
                else if(!this.tasktype.qalead){
                    this.errors.tasktype.qalead="QA Lead is required";
                    // isValid= false;
                } else {
                    this.errors.tasktype.qalead='';
                }
                this.readonlyflds.qaleadfld = false;
            }

            if(this.finalvalidationArray['Program Manager'])
            {
                this.errors.tasktype.progman='';
                if(this.finalvalidationArray['Program Manager'] == 'Required')
                {
                    if(!this.tasktype.progman){
                        this.errors.tasktype.progman="Program Manager is required!";
                        isValid= false;
                    }
                    this.readonlyflds.progmanfld = false;
                } else if (this.finalvalidationArray['Program Manager'] == 'Read-Only') {
                    this.readonlyflds.progmanfld = true;
                } 
            } else {
                this.readonlyflds.progmanfld = false;
            }
            return isValid;
        },
    getprojectmaprolide(empcode, trackername, valchk1)
    { 
        if(valchk1.length>0)
        {
            let url = "api/fieldtracker/getfieldconfigdataroleid";
            this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                projectid: valchk1
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {    
                if (result.data.errorCode == 0) {
                    this.newroleid = result.data.data[0]['roleid'];
                    this.getfieldconfigdatabyid(empcode, trackername, valchk1);
                } else {

                }
            });
        }
    },
    setSelectedParameter(variable) {
        const masterkey=[];
        this.multidimensionalArray = [];
        for (var k = 0; k < variable.length; k++) {
            var fieldconfigstring = JSON.stringify(variable[k].fieldconfig);
            var fieldconfigstringarray = JSON.parse(fieldconfigstring);
            var firstindex = "";
            var secondindex = "";
            for (var i = 0; i < fieldconfigstringarray.length; i++) {
            var data = "";
            firstindex = Object.keys(fieldconfigstringarray[i][0])[0];
            const textarray = [];
            masterkey.push(firstindex);
                for (var j = 0; j < fieldconfigstringarray[i][0][firstindex].length; j++) {
                    secondindex = Object.keys(fieldconfigstringarray[i][0][firstindex][j])[0];
                    var validationText='';
                    validationText = fieldconfigstringarray[i][0][firstindex][j][secondindex][0]['validation'][0];
                    textarray[secondindex]=validationText;
                    data = textarray;
                    if(this.tasktype.tstatus == secondindex )
                    {
                        const fixedKey = firstindex;
                        const newData = validationText;
                        if (!this.multidimensionalArray[fixedKey]) {
                            if(this.tasktype.tstatus == secondindex )
                            this.$set(this.multidimensionalArray, fixedKey, []);
                        }
                        else
                        {
                            if(this.tasktype.tstatus == secondindex )
                            this.$set(this.multidimensionalArray, fixedKey, []);
                        }    
                        this.multidimensionalArray[fixedKey].push(newData);
                    }
                }
            }
        }
        const uniqueArray = [...new Set(masterkey)];
        this.finalvalidationArray = [];
        for (var i = 0 ; i < uniqueArray.length; i++)
        {
            if(this.multidimensionalArray.hasOwnProperty(uniqueArray[i]))
            this.finalvalidationArray[uniqueArray[i]]=this.multidimensionalArray[uniqueArray[i]][0];
        }        
    },  
        async getfieldconfigdatabyid(empcode, trackername, valchk1) {
            try {
            let projectidarray = []
            if(valchk1)
            {
                for (var j = 0; j < valchk1.length; j++) {
                    projectidarray[j]= valchk1[j];
                }    
                if(this.newroleid)
                {
                    this.isLoading = true
                    let url = "api/fieldtracker/getmultiplefieldconfigdatabyid";
                    this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    roleid: this.newroleid,
                    projectidarray: projectidarray,
                    trackername: trackername
                    };
                    axios({
                        method: "POST",
                        url: url,
                        data: this.input,
                        'headers': { 'authorization': this.tokendata }
                    }).then((result) => {
                        this.isLoading = false;
                        if (result.data.errorCode == 0) {
                            this.setSelectedParameter(result.data.data)
                            this.readOnlyFieldsValidation();
                        } else {
                            this.finalvalidationArray = [];
                            this.multidimensionalArray = [];
                        }
                    });
                }
            }
        } catch (error) { 
            console.log('err');
        }
    },
    isKeyPresent(dataslabel, datassid) {
    if (this.chkselectfieldlist.hasOwnProperty(dataslabel) && this.chkselectfieldlist[dataslabel].hasOwnProperty(datassid)) {
      return true; // Return boolean true if condition is met
    } else {
      return false; // Return boolean false if condition is not met
    }
  },
  getVariable(dataslabel, datassid) {
    if (this.isKeyPresent(dataslabel, datassid)) {
      var parameter = Object.values(this.chkselectfieldlist[dataslabel][datassid]);
      const combinedString = parameter.join("");
      const combinedString1 = combinedString.replaceAll(',', '');
      return combinedString1;
    } else {
      return ''; // Return an empty string if condition is not met
    }
  },
    gettestcyclesetlistbybtc(businesstaskid,state){
        this.isLoading = true;
        let url = "api/master/businesstask/gettestcyclesetlistbybtc";
        this.input = { businesstaskid: businesstaskid,empcode: this.undt.userid,useremail: this.undt.username, issubtask: this.issubtask,isshow: this.isshow ,orderBy: state, moduletype:this.taskmodule };
        this.input.limit = apiUrl.LIMIT;
        this.input.offset = this.tcs_pageoffset;
        this.input.order = this.order;
        this.input.labelname = this.labelname;
        this.input.testcyclesetcode = 'true';
        this.input.testcyclesetname = 'true';
        this.input.testcyclesetstatus = 'true';
        this.input.testcyclesetproject = 'true';
        this.input.testcyclesetassignee = 'true';
        this.input.searchTestcyclesetCode;
        this.input.searchTestcyclesetName;
        this.input.searchTestcyclesetStatus;
            if (this.searchcycleset) {
                let code = this.searchcycleset.split('-')[0]
                if (code == 'CC' || code == 'cc' || code == 'BTC' || code == 'btc' || code == 'GM' || code == 'gm'){
                    this.input.searchTestcyclesetCode = this.searchcycleset.toUpperCase();
                } else {
                    this.input.searchTestcyclesetCode = null;
                    this.input.searchTestcyclesetName = this.searchcycleset;
                    this.input.searchTestcyclesetName = this.input.searchTestcyclesetName.toLowerCase();
                }
            }
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.testcyclesetlist = result.data.data.rows;
                    this.tcs_totalcount = result.data.data.count;
                    this.tcs_pageCount = Math.ceil(this.tcs_totalcount / this.tcs_pagelimit);
                } else {
                    this.testcyclesetlist = [];
                    this.tcs_totalcount = 0;
                    this.tcs_pageCount =0;
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        clickCallback_testcyclesetlist: function (pageNum) {
            this.tcs_pageoffset = pageNum;
            this.tcs_currPageNum = pageNum - 1;
            this.gettestcyclesetlistbybtc(this.$route.query.businessid)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#card-navigation").offset().top -70
                });
            }, 500);
        },
        inputsearchcycleset(){
            if (this.searchcycleset !== null){
                this.testcyclesetsearch = true;
                this.gettestcyclesetlistbybtc(this.$route.query.businessid);
            }
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+` <br/>`
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        
        previewpdf(geturl)
        {
            window.open(geturl, "_blank");
            // window.open(geturl, '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        }
    },
    filters: {
        basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
    }
  }
}
document.addEventListener("DOMContentLoaded", function () {
    const contentContainers = document.querySelectorAll(".content-container");

    contentContainers.forEach(function (container) {
      const content = container.querySelector(".content02");
      const readMoreBtn = container.querySelector(".read-more");
      const readLessBtn = container.querySelector(".read-less");

      readMoreBtn.addEventListener("click", function () {
        
        readMoreBtn.style.display = "none";
        readLessBtn.style.display = "block";
        content.classList.add("read-remove");
      });

      readLessBtn.addEventListener("click", function () {
        
        readMoreBtn.style.display = "block";
        readLessBtn.style.display = "none";
        content.classList.remove("read-remove");
      });
    });
});
</script>
<style>


/* #subtasklist .table.table-sm th{
    padding: 0.9rem 0.5rem;
} */
.ck-content{
    min-height: 150px !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
.display{
    display: none !important;
}

.pop-up {
    width: 70% !important;
}
.click-zoom input[type=checkbox] {
  display: none;
}

.click-zoom img {
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.click-zoom input[type=checkbox]:checked~img {
  transform: scale(10);
  cursor: zoom-out;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

div.search input[type=text] {
        padding: 5px 10px;
        border: 1px solid #e5e5e5d6;
        /* float: right; */
        width: 100%;
        background: #fff;
        font-size: 16px;
        border-radius: 6px;
         padding-right: 30px;
         transition: all .3s ease-in;
    }
    div.search input[type=text]:focus {
     width: 320px;
     transition: all .3s ease-in;
    }
 
    div.search::after {
        content: "";
        clear: both;
        display: table;
    }
    .d-table_icon_up.bussiness{
        cursor: pointer;
    }
    .columnbg_viewbgchart .apexcharts-xaxis-texts-g {
        display:none;
    }
</style>