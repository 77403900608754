<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots"
            :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false" :is-full-page="listfullPage" color="orange" loader="dots"
            :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-12 px-0">
                        <h3 class="content-header-title float-left mb-0">Planner Details</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><router-link to='/planner/planhub'>Planner</router-link></li>
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Planhub Details</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section id="basic-and-outline-pills">
                <div class="row match-height">
                    <div class=" col-lg-12">
                        <div class="card">
                            <div class="card-header text-editar_discription_jobs masters_detailstabing_pills px-2">
                                <ul class="nav nav-pills nav-pill-warning pt-1">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="profile-tab" data-toggle="pill" href="#profile"
                                            aria-expanded="false" @click="buttonactive()">Planner Details</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="about-tab" data-toggle="pill" href="#about"
                                            aria-expanded="false" @click="buttonDeactive()">Members Details </a>
                                    </li>
                                </ul>

                                    <div v-if="this.planD == true" class="col-xs-12 col-sm-12 col-md-12 text-right px-0">
                                        <div class="right btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" @click="openBTMList()">
                                            <em class="lar la-file-alt font-medium-1"></em>
                                            <span> Business Task List</span>
                                        </div>
                                        <div class="right btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" @click="openProgressBar()">
                                            
                                            <em class="lar la-chart-bar font-medium-1"></em>
                                            <span> Progress Bar</span>
                                        </div>
                                        <div class="right btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" @click="openDocList()">
                                            <em class="las la-file-medical font-medium-1"></em>
                                            <span> Document Details</span>
                                        </div>
                                        <div class="right btn btn-sm btn-outline-primary waves-effect waves-effect mr-1 mb-1" @click="openMOMdetails()">
                                            <em class="lar la-handshake font-medium-2"></em>
                                            <span> MOM Details</span>
                                        </div>
                                    </div>
                                   

                                <div class="tab-content w-100">
                                    <div class="tab-pane active" id="profile" role="tabpanel" aria-labelledby="profile-tab"
                                        aria-expanded="false">
                                        <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab"
                                            aria-expanded="false" v-for="(data, index) in plannermasterlist"
                                            v-bind:key="index">
                                            <div class="row match-height mt-1">
                                                <div class="col-12 col-md-3 col-lg-3">
                                                    <div class=" card-transaction ">
                                                        <div style="flex: 1 1 auto; min-height: 1px;margin-top: 10px;">
                                                            <div class="transaction-item ">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-success rounded">
                                                                        <div class="avatar-content">
                                                                            <em
                                                                                class="las la-clipboard-list font-medium-5"></em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h6 class="transaction-title"> Planner Code</h6>
                                                                        <small>{{ data.plannercode }} </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 col-lg-3 ">
                                                    <div class="card card-transaction mb-1">
                                                        <div class="card-body px-0 py-1">
                                                            <div class="transaction-item">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-primary rounded">
                                                                        <div class="avatar-content">
                                                                            <em class="las la-users font-medium-5"></em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h6 class="transaction-title"> Planner Title</h6>
                                                                        <small style="font-weight:500">{{ data.plannertitle
                                                                        }}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 col-lg-3 ">
                                                    <div class="card card-transaction mb-1">
                                                        <div class="card-body px-0 py-1">
                                                            <div class="transaction-item">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-warning rounded">
                                                                        <div class="avatar-content">
                                                                            <em
                                                                                class="las la-address-card font-medium-5"></em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h6 class="transaction-title"> Created By </h6>
                                                                        <small style="font-weight:500">{{
                                                                            data.createdby.split('@')[0] }}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 col-lg-3 ">
                                                    <div class="card card-transaction mb-1">
                                                        <div class="card-body px-0 py-1">
                                                            <div class="transaction-item">
                                                                <div class="media">
                                                                    <div class="avatar bg-light-warning rounded">
                                                                        <div class="avatar-content">
                                                                            <em
                                                                                class="las la-address-card font-medium-5"></em>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                        <h6 class="transaction-title"> Created Date </h6>
                                                                        <small style="font-weight:500">{{ data.createddate ?
                                                                            dateMonthFormat(data.createddate) : 'NA' }}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="scrollToListGrid"></div>
                                        <div id="card-navigation" >
                                        <fieldset class="progressBarDiv fieldset_border_vi mb-2 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                            <legend>Business Task List</legend>
                                            <ul class="listGrid float-right d-inline-flex px-sm-mobile-10 viewplan_top_15 business_width_autolist">
                                                <li class="cursorpointercls"  @click="myListView()" >
                                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                                                <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                                                <span>
                                                List View
                                                </span>
                                            </button>
                                            </li>
                                            <li class=" cursorpointercls" @click="myGridView()" >
                                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
                                                <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
                                                <span>
                                                Grid View
                                                </span>
                                            </button>
                                            </li>
                                                <li class="cursorpointercls" @click="boardShowView()" style="display:none;" > 
                                                <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect"   title="Board View" data-original-title="Board View">
                                                    <em class="las la-chalkboard-teacher custom-class mr-0 avatar-icon font-medium-1"></em>
                                                    
                                                    <span>
                                                    Board
                                                    
                                                    </span>
                                                </button>
                                                </li>
                                            </ul>
                                            <div class="clearfix"> </div>

                                       <div v-bind:class="[{ show: isListActive}]" style="margin-bottom: 1rem !important;">
                                          <div v-bind:class="[{show: isListDetActiveplan},card_content,collapse]">
                                            <div class="card-body px-0 pt-1 pb-0">
                                                <form >
                                                    <div class="col-md-12 px-0">
                                                        <div class="mb-0">
                                                            <div class="table-responsive">
                                                                <table class="table table-sm dataTableListing table-hover table-striped table-bordered" aria-describedby="mydesc">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="text-center">Business Task Details</th>
                                                                            <th scope="col" class="text-center">Project Name</th>
                                                                            <th scope="col" class="text-center">Department Details</th>
                                                                            <th scope="col" class="text-center">Task Manager</th>
                                                                            <th scope="col" class="text-center">project Manager</th>
                                                                            <th scope="col" class="text-center">Priority</th>
                                                                            <th scope="col" class="text-center">Sub Priority</th>
                                                                            <th scope="col" class="text-center">Status</th>
                                                                            <th scope="col" class="text-center">Date Details</th>
                                                                            <th scope="col" class="text-center">Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody v-for="data, index in taskPlan" v-bind:key="data.moduleid">
                                                                        <tr>
                                                                           
                                                                            <td class="text-nowrap details_column_table second-col-sticky"
                                                                                style="max-width:230px;">
                                                                                <span
                                                                                    class="down_arrow_details_bt arrow_btn sinaledit"
                                                                                    :id="data.moduleid"
                                                                                    @click="tabledetailTr(data.moduleid)"
                                                                                    style="display: none;">
                                                                                    <i
                                                                                        class="las la-long-arrow-alt-down"></i>
                                                                                </span>
                                                                                <span>
                                                                                    {{
                                                                                        data.businesstaskcode
                                                                                    }}</span>
                                                                                <hr
                                                                                    class="hr_border-bottom" />

                                                                                <span
                                                                                    class="heading_column_text">
                                                                                    Type:</span>
                                                                                <span
                                                                                    v-if="data.tasktype == 'Business Task'">Business
                                                                                    Task
                                                                                    -
                                                                                    New
                                                                                    Feature</span>
                                                                                <span
                                                                                    v-else-if="data.tasktype == 'Enhancement'">Business
                                                                                    Task
                                                                                    -
                                                                                    Enhancement</span>
                                                                                <span
                                                                                    v-else>{{
                                                                                        data.tasktype
                                                                                    }}</span>
                                                                                <hr
                                                                                    class="hr_border-bottom" />
                                                                                <span>
                                                                                    <strong>Name
                                                                                        :
                                                                                    </strong>
                                                                                </span>
                                                                                <span
                                                                                    class="font-weight-bold sitebluecolor" 
                                                                                    @click="redirecttourl('view', data)"
                                                                                    href="javascript:void(0);"
                                                                                    style="cursor: pointer;white-space: normal;">{{
                                                                                        data.modulename
                                                                                    }}
                                                                                </span>
                                                                            </td>
                                                                            <td class="text-center">{{data.projectnameist}}</td>
                                                                            <td class="text-center">{{decryptText(data.department)}}</td>
                                                                            <td class="text-center">{{data.productstackholder}}</td>
                                                                            <td class="text-center">{{data.projectmaanager}}</td>
                                                                            <td class="text-center">{{data.priority ? data.priority:'NA'}}</td>
                                                                            <td class="text-center">{{data.subpriority ? data.subpriority:'NA'}}</td>
                                                                            <td class="text-center">{{data.taskstatus}}</td>
                                                                            <td
                                                                                class="details_column_table">
                                                                                <span
                                                                                    class="heading_column_text">
                                                                                    CREATED
                                                                                    BY :
                                                                                </span>
                                                                                {{
                                                                                    data.createdby.split('@')[0]
                                                                                }}
                                                                                <hr
                                                                                    class="hr_border-bottom" />
                                                                                <span
                                                                                    class="heading_column_text">
                                                                                    Due
                                                                                    date
                                                                                    :
                                                                                </span>
                                                                                <span
                                                                                    v-if="data.due_date">
                                                                                    {{
                                                                                        dateMonthFormat(data.due_date)
                                                                                    }}
                                                                                </span>
                                                                                <span
                                                                                    v-else>
                                                                                    NA
                                                                                </span>
                                                                                <hr
                                                                                    class="hr_border-bottom" />
                                                                                <span
                                                                                    class="heading_column_text full_100">
                                                                                    Created
                                                                                    date
                                                                                    :
                                                                                </span>
                                                                                {{
                                                                                    dateMonthFormat(data.createddate)
                                                                                }}
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" class="btn btn-sm danger" @click="removeBTC(data)">
                                                                                    <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <template v-if="this.taskPlan.length == 0">
                                                                        <tr><td colspan="14" style="text-align: center;">No Record Found..!!</td></tr>
                                                                    </template>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="pageCenter text-center">
                                                <pagination v-model="page"
                                                    :records="totalcount"
                                                    :per-page="pagelimit"
                                                    @paginate="clickCallback" />
                                            </div>

                                          </div>
                                       </div>

                                       <div v-bind:class="[{displayblk:isgridView}, ]" style="  margin-bottom: 1rem !important;">
                                         <div v-bind:class="[{show: isListDetailsDataActives},card_content,collapse]">
                                            <div class="dw mx-n1 blog-list-wrapper">
                                                <div class="dw-panel p-1" v-for="(data, index) in taskPlan" v-bind:key="index">
                                                <div class="card border-warning card_style_box_shadow1" style="margin-bottom: 0rem !important;">
                                                <div class="card-header p-1">
                                                        <h4 class="card-title">{{data.projectnameist}} :{{data.businesstaskcode}}</h4>
                                                </div>
                                                <div class="card" style="margin-bottom:0 !important;">
                                                    <div class="card-body p-1">
                                                        <h4 class="card-title mb-1">
                                                            <span v-if="data.modulename.length > 50"  class="blog-title-truncate text-body-heading ">
                                                                {{data.modulename.substring(0,51)+ ' ...'}}
                                                            </span>
                                                            <span v-else  class="blog-title-truncate text-body-heading ">
                                                                {{data.modulename}}
                                                            </span>
                                                            
                                                        </h4>
                                                        <div class="media">
                                                            <div class="avatar mr-50">
                                                                <img alt=""  :src="defaultimagepath"  width="40" height="40" />
                                                            </div>
                                                            <div class="media-body">
                                                                <small class="text-muted mr-25">by</small>
                                                                <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                                                                <span class="text-muted ml-50 mr-25">|</span>
                                                                <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                                                            </div>
                                                        </div>
                                                        <div class="my-1">
                                                            <div v-if="data.priority" class="capitalisetext badge badge-pill badge-light-info mr-50 mb-25">{{data.priority}}</div>
                                                            <div v-else class="capitalisetext  mr-50 mb-25" v-bind:class="{'badge badge-pill badge-light-success': Boolean(data.priority)}">{{data.priority ? data.priority : 'NA'}}</div>

                                                            <div v-if="data.taskkey.toLowerCase()=='key' || data.taskkey.toLowerCase()=='urgent'" class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                                            <div  v-if="data.taskkey.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                                            <div v-if="data.taskkey.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                                            <div v-if="data.taskkey.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                                            <div v-if="data.taskkey.toLowerCase()=='normal'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                                            <div class="badge badge-pill badge-light-primary capitalisetext mr-50 mb-25">{{data.taskstatus}}</div>
                                                            <div v-if="data.tasktype=='Business Task'" class="badge badge-pill badge-light-success capitalisetext">Business Task - New Feature</div>
                                                            <div v-else-if="data.tasktype=='Enhancement'" class="badge badge-pill badge-light-success capitalisetext">Business Task - Enhancement</div>
                                                            <div v-else class="badge badge-pill badge-light-primary capitalisetext">{{data.tasktype}}</div>
                                                            <div v-if="(data.isapproved_edd && data.isapproved_edd == 'Pending')" class="badge badge-pill badge-light-warning mr-50 capitalisetext">Pending</div>
                                                        </div>
                                                        <div class="card-text blog-content-truncate" style="overflow:hidden">
                                                            <span v-if="data.moduledesc && data.moduledesc.length>150" v-html="data.moduledesc.substring(0, 151) + ' ...'"></span>
                                                            <span v-else v-html="data.moduledesc"></span>
                                                        </div>
                                                        <hr class="mx-n1 mt-0" />
                                                        <div class="d-flex justify-content-between align-items-center">
                                                            <a v-if="pagefeatures.editbusinesstask || loginusername==data.createdby || loginpersonname==data.projectmaanager || loginpersonname==data.qamanager || loginpersonname==data.qalead || loginpersonname==data.productstackholder || loginpersonname==data.taskowner || loginpersonname==data.engineeringmaanager" @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                                                                <div class="d-flex align-items-center sitebluecolor">
                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                                                    <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                                                                </div>
                                                            </a>
                                                            
                                                            <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                <div class="d-flex align-items-center sitebluecolor"> 
                                                                    <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                                                                <span class="text-body font-weight-bold">View Details</span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                </div>

                                            </div>
                                          </div>
                                       </div>
                                       
                                       
                                       <div v-bind:class="[{ show: chartDetails},]" style="margin-top: 1rem; margin-bottom: 1rem !important;">
                                            <div v-bind:class="[{show: isboardDetailsActives},card_content,collapse]">
                                                <div class="container px-0">
                                                    <div class="row_boardview">
                                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 row_boardview05" v-for="(blval, blkey) in this.boardApiList">
                                                                <div class="d-flex align-items-center justify-content-between board_titile_productlist  board_common_top1">
                                                                    <h5 class=" py-1 mb-0"> {{ blval[0] }} </h5>
                                                                    <div class="badge badge badge-light-primary">{{(blval[1]).length}}
                                                                    / {{boardApiListTemp[blkey][1].length}}
                                                                    </div>
                                                                </div>
                                                            <div id="listingTable" class="board_common_card" v-for="(list, listkey) in (blval[1])">
                                                                <div class="dw-panel blog-list-wrapper  p-0 mb-1" >
                                                                    <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 0rem !important;">
                                                                    <div class="card-header p-1">
                                                                            <h4 v-if="list.projectnameist" class="card-title">{{(list.projectnameist).toLowerCase()}} : {{list.businesstaskcode}} </h4>
                                                                    </div>
                                                                    <div class="card" style="margin-bottom:0 !important;">
                                                                        <div class="card-body p-1">
                                                                            <h4 class="card-title mb-1">
                                                                                <span v-if="list.modulename.length > 50"  class="blog-title-truncate text-body-heading ">
                                                                                    {{(list.modulename.substring(0,51)).toLowerCase()+ ' ...'}}
                                                                                </span>
                                                                                <span v-else  class="blog-title-truncate text-body-heading ">
                                                                                    {{(list.modulename).toLowerCase()}}
                                                                                </span>
                                                                            </h4>
                                                                            <div class="media">
                                                                                <div class="avatar mr-50">
                                                                                    <profilepic v-if="list.imagename && list.employeecode" v-bind:get-url="'empprofile/'+list.employeecode+'/'+list.imagename" :key="`${componentKey + listkey}`"></profilepic>
                                                                                    <img alt="" v-if="!list.imagename" :src="defaultimagepath" width="40" height="40" />
                                                                                </div>
                                                                                <div class="media-body">
                                                                                    <small class="text-muted mr-25">by</small>
                                                                                    <small><a href="javascript:void(0);"  class="capitalisetext">{{list.creator}}</a></small>
                                                                                    <small class="text-muted d-block">{{ dateMonthFormat(list.createddate) }}</small>
                                                                                </div>
                                                                            </div>
                                                                            <div class="my-1">
                                                                                <div  class="badge badge-pill badge-light-secondary mr-50 capitalisetext">{{list.taskkey}}</div>
                                                                                <div  class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{list.taskstatus}}</div>
                                                                                <div  class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{ list.tasktype }}</div>
                                                                            </div>
                                                                            <div class="card-text blog-content-truncate" style="overflow:hidden">
                                                                                <span v-if="list.moduledesc && list.moduledesc.length>150" v-html="list.moduledesc.substring(0, 151) + ' ...'"></span>
                                                                                <span v-else v-html="list.moduledesc"></span>
                                                                            </div>

                                                                            <hr style="cursor:pointer;margin:0;" v-if="list.estimatedddate && (list.taskstatus != 'Completed' && list.taskstatus != 'Discarded' && list.taskstatus != 'OnHold' && list.taskstatus != 'Live')" class="mx-n1 mt-0" />
                                                                            <div v-if="list.estimatedddate && (list.taskstatus != 'Completed' && list.taskstatus != 'Discarded' && list.taskstatus != 'OnHold' && list.taskstatus != 'Live')" style="cursor:pointer" class="row text-center mx-0">
                                                                                <div class="col-12" style="padding: 8px;">
                                                                                    <p class="card-text text-muted mb-0" style="font-size: 10px">End date : {{dateMonthFormat(list.estimatedddate)}}</p>
                                                                                    <p style="font-size: 11px" class="font-weight-bolder mb-0">
                                                                                        <span v-if="list.edddelayed == null">Ageing : {{list.createddateaeging}} | Deliverable BY : {{list.edddeliverable}}</span>
                                                                                        <span v-if="list.edddelayed != null">Ageing : {{list.createddateaeging}} | Delayed BY : {{list.edddelayed}}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <hr class="mx-n1 mt-0">
                                                                            <div class="d-flex justify-content-between align-items-center board_uiBottomsize">
                                                                                <a v-if="pagefeatures.editbusinesstask || loginusername==list.createdby || loginpersonname==list.projectmaanager || loginpersonname==list.qamanager || loginpersonname==list.qalead || loginpersonname==list.productstackholder || loginpersonname==list.taskowner || loginpersonname==list.engineeringmaanager" @click="redirecttourl('edit',list)" href="javascript:void(0);" >
                                                                                    <div class="d-flex align-items-center sitebluecolor">
                                                                                        <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                                                                        <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                                                                                    </div>
                                                                                </a>
                                                                                <a v-on:click="getBusinessTaskVersion(parseInt(list.moduleid), list.modulename, list.createdby, list.createddate)" href="javascript:void(0);">
                                                                                    <div class="d-flex align-items-center sitebluecolor ml-1"> 
                                                                                        <alert-circle-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></alert-circle-icon>
                                                                                    <span class="text-body font-weight-bold">Task History</span>
                                                                                    </div>
                                                                                </a>
                                                                                <a @click="redirecttourl('view',list)" href="javascript:void(0);">
                                                                                    <div class="d-flex align-items-center sitebluecolor"> 
                                                                                        <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                                                                                    <span class="text-body font-weight-bold">View Details</span>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>

                                                    <div class="pageCenter text-center">
                                                        <div class="VuePagination_view ">
                                                        <div class="VuePagination_view_02">
                                                            <a class="VuePagination_view_count" href="javascript:void(0);" @click="prevPage()" id="btn_prev">Prev </a>
                                                            <span  class="VuePagination_view_count" id="page"> 1 </span>
                                                            <a class="VuePagination_view_count" href="javascript:void(0);" @click="nextPage()" id="btn_next"> Next</a>
                                                        </div>  
                                                    </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                          
                                        </fieldset>
                                    </div>

                                    <div class="col-md-12 px-0" id="card-navigation1">
                                        <BTCProgressBar v-if="pagefeatures.btprogress && this.pannerbtcids.length>0" :plannerflag="true" :flagg="0" :bid="pannerbtcids" />
                                    </div>

                                    <div class="col-md-12 px-0" id="card-navigation2">
                                        <fieldset class="progressBarDiv fieldset_border_vi mb-2 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                            <legend>Documents Details</legend>

                                            <div class="tab-pane" id="document" role="tabpanel" aria-labelledby="document-tab"
                                                aria-expanded="false">
                                                <div class=" match-height ">
                                                    <div class="  documentGrid match-height custom_grid_watch_bt px-0">
                                                        <div class="row"
                                                            v-for="(data, index) in doclist" v-bind:key="index">
                                                                        <div class="col-6 col-sm-6 col-xl-2 col-md-3 mb-1"
                                                                            v-for="(res, index) in (data.documentname ? data.documentname.data : [])"
                                                                            v-bind:key="index">
                                                                            <div class="card card-bordered mb-50">
                                                                                <div class="text-center py-2"
                                                                                    style="background:#F8F8F8;">
                                                                                    <img alt=""
                                                                                        v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.') + 1))"
                                                                                        src="../../../public/images/icons/image.png"
                                                                                        height="35" />

                                                                                    <img alt=""
                                                                                        v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'pdf'"
                                                                                        src="../../../public/images/icons/ic-pdf.png"
                                                                                        height="35" />

                                                                                    <img alt=""
                                                                                        v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'docx'"
                                                                                        src="../../../public/images/icons/ic-doc.png"
                                                                                        height="35" />
                                                                                    <img alt=""
                                                                                        v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'csv'"
                                                                                        src="../../../public/images/icons/ic-csv.png"
                                                                                        height="35" />
                                                                                    <img alt=""
                                                                                        v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'mp4'"
                                                                                        src="../../../public/images/icons/ic-video.png"
                                                                                        height="35" />
                                                                                    <img alt=""
                                                                                        v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'xls' || 'xlsx'"
                                                                                        src="../../../public/images/icons/ic-xls.png"
                                                                                        height="35" />
                                                                                    <a v-on:click="getDocDownloadLink(res.link)"
                                                                                        target="_blank"
                                                                                        class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon
                                                                                            size="1.5x"
                                                                                            class="custom-class "></download-icon>
                                                                                    </a>
                                                                                    <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.') + 1))"
                                                                                        target="_blank"
                                                                                        v-on:click="onZoom(res.link)"
                                                                                        class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon
                                                                                            size="1.5x"
                                                                                            class="custom-class "></zoom-in-icon>
                                                                                    </a>
                                                                                </div>
                                                                                <a :href="res.link" class="py-50 px-50 border-top planner_imageuplodstext"
                                                                                    style="font-size:0.85rem; color:#888;"
                                                                                    target="_blank">
                                                                                    <span v-if="res.info">
                                                                                        {{ res.info }} -123
                                                                                    </span>
                                                                                    <span v-else-if="!res.info">
                                                                                        {{ res.link | basename }}
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </fieldset>
                                    </div>

                                    <div class="col-md-12 px-0" id="card-navigation3">
                                        <fieldset class="progressBarDiv fieldset_border_vi mb-2 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                            <legend>MOM Details</legend>
                                            <div class="tab-pane" id="mom" role="tabpanel" aria-labelledby="mom-tab"
                                        aria-expanded="false">
                                        <div class="ml-auto text-right">
                                            <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50"
                                                @click="redirectToMOMCreate('createMOM')">
                                                <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                                <span>Create MOM</span>
                                            </div>

                                        </div>
                                        <div v-bind:class="[{ show: isListActivemom }, cardmom]"
                                            style="margin-top: 1rem; margin-bottom: 0rem !important;background: #fafafa;">
                                            <div v-bind:class="[{ show: isListDetActivemom }, card_content, collapse]">
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="">
                                                                <div class="">
                                                                    <div class="tab-content" id="myTabContent">
                                                                        <div class="tab-pane fade show active" id="home"
                                                                            role="tabpanel" aria-labelledby="home-tab">
                                                                            <div class="row" id="table-small">
                                                                                <div class="col-12">
                                                                                    <div class="card mb-0">
                                                                                        <div class="table-responsive">
                                                                                            <table
                                                                                                class="table table-sm dataTableListing table-hover table-striped all_table_stickys"
                                                                                                id="table_businessceo1"
                                                                                                aria-describedby="mydesc">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle first-col-sticky"
                                                                                                            title=""
                                                                                                            style="min-width:45px;">
                                                                                                            Sr#</th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle second-col-sticky"
                                                                                                            style="min-width:230px"
                                                                                                            title="">
                                                                                                            Project Name
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            style="min-width:230px"
                                                                                                            title="">
                                                                                                            Project
                                                                                                            Module Name
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="Name"
                                                                                                            style="min-width:230px;">
                                                                                                            Description
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="">
                                                                                                            Start Time
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="">End
                                                                                                            Time</th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            style="min-width:140px"
                                                                                                            title="">
                                                                                                            Location
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="">
                                                                                                            Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    <template
                                                                                                        v-for="data, index  in momPlan">
                                                                                                        <tr
                                                                                                            v-bind:key="data.id">
                                                                                                            <td
                                                                                                                class="centeralign first-col-sticky">
                                                                                                                {{ index
                                                                                                                    + 1 +
                                                                                                                    currPageNum
                                                                                                                    *
                                                                                                                    pagelimit
                                                                                                                }}</td>

                                                                                                            <td class="text-normal details_column_table second-col-sticky"
                                                                                                                style="max-width:230px;">
                                                                                                                {{
                                                                                                                    data.projectname
                                                                                                                }}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="details_column_table">
                                                                                                                {{
                                                                                                                    data.projectmodulename
                                                                                                                }}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="details_column_table">
                                                                                                                <span v-html="formatData(data.description)"
                                                                                                                    ></span>
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                <div
                                                                                                                    class="capitalisetext badge badge-pill badge-light-info mr-50">
                                                                                                                    {{
                                                                                                                        data.starttime
                                                                                                                    }}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div
                                                                                                                    class="capitalisetext badge badge-pill badge-light-info mr-50">
                                                                                                                    {{
                                                                                                                        data.endtime
                                                                                                                    }}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="details_column_table">
                                                                                                                {{
                                                                                                                    data.location
                                                                                                                }}
                                                                                                            </td>

                                                                                                            <td class="clsaction" >
                                                                                                                <div class="dropdown">
                                                                                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                                                </button>
                                                                                                                <div class="dropdown-menu">
                                                                                                                    <a class="dropdown-item devEditEle"  @click="redirectToMOMUpdate('update', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                                                                                    <div class="d-flex align-items-center">
                                                                                                                        <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                                                        <span>Edit</span>
                                                                                                                    </div>
                                                                                                                    </a>
                                                                                                                    <a @click="redirectToMOMView('view', data)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                                                                                    <div class="d-flex align-items-center"> 
                                                                                                                        <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                                                                                        <span>View MOM Details</span>
                                                                                                                    </div>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </template>
                                                                                                   
                                                                                                </tbody>
                                                                                                <tr v-if="momPlan.length == 0">
                                                                                                    <td colspan="14" style="text-align: center;">
                                                                                                    No records found
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="pageCenter text-center">
                                                                            <pagination v-model="pagemom"
                                                                                :records="totalcountmom"
                                                                                :per-page="pagelimitmom"
                                                                                @paginate="clickCallback" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                        </fieldset>
                                    </div>

                                        <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab"
                                            aria-expanded="false">
                                            <div v-bind:class="[card, border_warning]"
                                                style=" margin-bottom: 1rem !important;">
                                                <div class="card-header" style="cursor:pointer;border-radius: 10px;">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <div class="col-md-9">
                                                                <div
                                                                    class="avatar bg-light-primary rounded d-inline-flex mr-2">
                                                                    <div class="avatar-content">
                                                                        <edit-2-icon size="1.5x"
                                                                            class="custom-class avatar-icon font-medium-3"></edit-2-icon>
                                                                    </div>
                                                                </div>
                                                                <h4 class="card-title text-primary d-inline-flex">Add
                                                                    Planner Comment </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-bind:class="[card_content]">
                                                    <div class="card-body">
                                                        <form class="form form-vertical">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group mg-bot0">
                                                                        <label for="reasonofissue">Planner Comment</label>
                                                                        <ckeditor :editor="editor" :config="editorConfig"
                                                                            id="reasonofissue" class="form-control" name=""
                                                                            placeholder="Enter Comments"
                                                                            v-model="plannerdoc.comment" v-bind:class="{
                                                                                'form-control': true,
                                                                                'is-invalid': !validText(plannerdoc.comment) && commentBlured,
                                                                            }" v-on:blur="commentBlured = true" />
                                                                        <div class="invalid-feedback">Comment is required</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-6" style="margin-top:24px">
                                                                    <div class="form-group">
                                                                        <div class="form-group">
                                                                            <label for="attachment"
                                                                                class="btn-sm btn btn-relief-secondary mb-75 mr-75">Select
                                                                                image/docs/videos</label>
                                                                            <div class="table-responsive"
                                                                                v-if="file.length > 0">
                                                                                <table class="table table-sm table-bordered"
                                                                                    aria-describedby="mydesc">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">SR#</th>
                                                                                            <th scope="col">Document Name
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(image, key) in file"
                                                                                            :key="key">
                                                                                            <td>{{ key + 1 }}</td>
                                                                                            <td>{{ image.name }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <button
                                                                                class="btn btn-relief-secondary btn-sm mb-75 mr-75 "
                                                                                v-on:click.stop.prevent="onUpload()">Upload</button>
                                                                            <input type="file" id="attachment" hidden
                                                                                multiple :accept="this.accepttype"
                                                                                ref="file" @change="onFileChange" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mg-top1 text-right">
                                                                    <button type="button"
                                                                        class="btn btn-relief-primary mr-1"
                                                                        @click="savePlannerDoc()" v-bind:disabled="plannerdoc.disblesavebtn">Submit</button>
                                                                    <button type="reset" class="btn btn-outline-secondary"
                                                                        @click="handleCancel()">Cancel</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <fieldset class="progressBarDiv fieldset_border_vi mb-2 business_fieldset_bgfieldset_border_vi mb-2 mx-0 business_fieldset_bg">
                                            <legend>Comments History</legend>
                                            <div class="customTimeline" v-for="(data, index) in commentHistory"
                                                v-bind:key="index">
                                                <div v-if="!data.comment == ''">
                                                    <div class="card-body py-1 px-0">
                                                        <div
                                                            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                                            <div class="text-muted mb-1" style="margin-top:-3px">
                                                                Commented on: {{ dateMonthFormat(data.uploaddate) }} <span
                                                                    class="mx-50">|</span> by <profilepic
                                                                    v-if="data.imagename"
                                                                    v-bind:get-url="'empprofile/' + data.employeecode + '/' + data.imagename"
                                                                    :key="`${componentKey + index}`"></profilepic>
                                                                <img alt="" v-if="!data.imagename"
                                                                :src="defaultimagepath"
                                                                    width="25" height="25" /> <strong
                                                                    class="text-secondary">{{ (data.createdby) }}</strong>
                                                            </div>
                                                        </div>

                                                        <div class="d-block">
                                                            <div for="" class="d-inline-flex mr-50 mb-0"><strong> Comment: </strong></div>
                                                            <div class="d-block">
                                                                <!-- <div v-html="data.comment"></div> -->
                                                                <div class="text-left tast_case_groupingview_readmore mx-0">
                                                                    <div class="col-md-12 px-0  micro_case_groupingview_readmore mb-1">
                                                                        <div class="custom-container case_bg_17">
                                                                            <div v-if="data.comment" class="outer" :id="'cycle_readmore6'+index">
                                                                                <span class="fc_m16_btcode_text" v-html="formatDataComment(data.comment)"></span>
                                                                                
                                                                            </div>
                                                                            
                                                                            <div class="cycle_viewmoretext" v-if="data.comment && data.comment.length > 150">
                                                                                <a class="btn">
                                                                                <strong :id="'id6'+index" @click="idclick6(index)">
                                                                        Read More
                                                                    </strong>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="row mt-1">
                                                            <div class="col-xl-2 col-md-4 col-sm-6 mb-1" v-for="(res,index) in (data.documentname ? data.documentname.data:[])" v-bind:key="index">
                                                            <div class="card card-bordered mb-0 ">
                                                                <div class="text-center py-2 ui_darktheme_bgdocument" style="background:#F8F8F8;">
                                                                <img alt="" v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1))"  src="../../../public/images/icons/image.png" height="35" />
                                                                
                                                                <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'pdf'" src="../../../public/images/icons/ic-pdf.png" height="35" />
                                                                            
                                                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'docx'" src="../../../public/images/icons/ic-doc.png" height="35" />
                                                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'csv'" src="../../../public/images/icons/ic-csv.png" height="35" />
                                                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'mp4'" src="../../../public/images/icons/ic-video.png" height="35"  />
                                                                    <img alt="" v-else-if="res.link.substring(res.link.lastIndexOf('.')+1) == 'xls' || 'xlsx'" src="../../../public/images/icons/ic-xls.png" height="35" />
                                                                    <a v-on:click="getDocDownloadLink(res.link)" target="_blank" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon size="1.5x" class="custom-class "></download-icon> </a>
                                                                    <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.')+1)) " target="_blank" v-on:click="onZoom(res.link)" class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon size="1.5x" class="custom-class "></zoom-in-icon> </a>
                                                                    </div>
                                                                    <a :href="res.link"  class="py-50 px-50 border-top planner_imageuplodstext" style="font-size:0.85rem; color:#888;"  target="_blank">
                                                                    <span v-if="res.info">
                                                                        {{res.info}} -123
                                                                    </span>
                                                                    <span v-else-if="!res.info">
                                                                        {{res.link | basename}}
                                                                    </span>
                                                                    </a>
                                                                    </div>
                                                            </div>
                                                         </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>

                                    </div>




                                    <div role="tabpanel" class="tab-pane" id="home" aria-labelledby="home-tab"
                                        aria-expanded="true">
                                        <div role="tabpanel" class="tab-pane active" id="home" aria-labelledby="home-tab"
                                            aria-expanded="true">
                                            <div v-bind:class="[{ show: isListActive }, card]"
                                                style=" box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top: 1rem; margin-bottom: 1rem !important;">
                                                <div v-bind:class="[{ show: isListDetActive }, card_content, collapse]">
                                                    <div class="">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="">
                                                                    <div class="">
                                                                        <div class="tab-content" id="myTabContent">
                                                                            <div class="tab-pane fade show active" id="home"
                                                                                role="tabpanel" aria-labelledby="home-tab">
                                                                                <div class="row" id="table-small">
                                                                                    <div class="col-12">
                                                                                        <div class="card">
                                                                                            <div class="table-responsive">
                                                                                                <table
                                                                                                    class="table table-sm dataTableListing table-hover table-striped all_table_stickys"
                                                                                                    id="table_businessceo1"
                                                                                                    aria-describedby="mydesc">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle first-col-sticky"
                                                                                                                title=""
                                                                                                                style="width:45px;">
                                                                                                                Sr#</th>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle second-col-sticky"
                                                                                                                title="Name"
                                                                                                                style="min-width:230px;">
                                                                                                                Business
                                                                                                                Task Code
                                                                                                            </th>


                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                style="min-width:200px"
                                                                                                                title="">
                                                                                                                Project</th>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                title=""
                                                                                                                style="min-width:200px">
                                                                                                                Department<br />
                                                                                                                Details</th>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                title="">
                                                                                                                Task Manager
                                                                                                            </th>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                title="">
                                                                                                                Project
                                                                                                                Manager</th>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                style="min-width:140px"
                                                                                                                title="">
                                                                                                                Priority
                                                                                                            </th>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                style="min-width:180px"
                                                                                                                title="">Sub
                                                                                                                Priority
                                                                                                            </th>
                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                title="">
                                                                                                                Status</th>

                                                                                                            <th scope="col"
                                                                                                                class="align-middle"
                                                                                                                title=""
                                                                                                                style="min-width:200px">
                                                                                                                Date Details
                                                                                                            </th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>

                                                                                                        <template
                                                                                                            v-for="data, index  in taskPlan">
                                                                                                            <tr
                                                                                                                v-bind:key="data.moduleid">
                                                                                                                <td
                                                                                                                    class="centeralign first-col-sticky">
                                                                                                                    {{ index
                                                                                                                        + 1 +
                                                                                                                        currPageNum
                                                                                                                        *
                                                                                                                        pagelimit
                                                                                                                    }}</td>

                                                                                                                <td class="text-normal details_column_table second-col-sticky"
                                                                                                                    style="max-width:230px;">
                                                                                                                    <span
                                                                                                                        class="down_arrow_details_bt arrow_btn sinaledit"
                                                                                                                        :id="data.moduleid"
                                                                                                                        @click="tabledetailTr(data.moduleid)"
                                                                                                                        style="display: none;">
                                                                                                                        <i
                                                                                                                            class="las la-long-arrow-alt-down"></i>
                                                                                                                    </span>
                                                                                                                    <span>
                                                                                                                        {{
                                                                                                                            data.businesstaskcode
                                                                                                                        }}</span>
                                                                                                                    <hr
                                                                                                                        class="hr_border-bottom" />

                                                                                                                    <span
                                                                                                                        class="heading_column_text">
                                                                                                                        Type
                                                                                                                        :</span>
                                                                                                                    <span
                                                                                                                        v-if="data.tasktype == 'Business Task'">Business
                                                                                                                        Task
                                                                                                                        -
                                                                                                                        New
                                                                                                                        Feature</span>
                                                                                                                    <span
                                                                                                                        v-else-if="data.tasktype == 'Enhancement'">Business
                                                                                                                        Task
                                                                                                                        -
                                                                                                                        Enhancement</span>
                                                                                                                    <span
                                                                                                                        v-else>{{
                                                                                                                            data.tasktype
                                                                                                                        }}</span>
                                                                                                                    <hr
                                                                                                                        class="hr_border-bottom" />
                                                                                                                    <span>
                                                                                                                        <strong>Name
                                                                                                                            :
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                    <span
                                                                                                                        class="font-weight-bold sitebluecolor"
                                                                                                                        @click="redirecttourl('view', data)"
                                                                                                                        href="javascript:void(0);"
                                                                                                                        style="cursor: pointer;">{{
                                                                                                                            data.modulename
                                                                                                                        }}</span>
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <span
                                                                                                                        class="font-weight-bold"
                                                                                                                        v-html="showprojectname(data.projectnameist)"></span>
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="details_column_table">

                                                                                                                    {{
                                                                                                                        decryptText(data.department)
                                                                                                                    }}

                                                                                                                    <hr
                                                                                                                        class="hr_border-bottom" />


                                                                                                                </td>
                                                                                                                <td class="capitalisetext"
                                                                                                                    v-if="data.productstackholder">
                                                                                                                    {{
                                                                                                                        data.productstackholder
                                                                                                                    }}
                                                                                                                </td>
                                                                                                                <td v-else>
                                                                                                                    NA</td>
                                                                                                                <td class="capitalisetext"
                                                                                                                    v-if="data.projectmaanager">
                                                                                                                    {{
                                                                                                                        data.projectmaanager
                                                                                                                    }}
                                                                                                                </td>
                                                                                                                <td v-else>
                                                                                                                    NA</td>
                                                                                                                <td>
                                                                                                                    <div
                                                                                                                        class="task_tatus_badge_set_pri">
                                                                                                                        <div v-if="data.priority"
                                                                                                                            class="capitalisetext badge badge-pill badge-light-info mr-50">
                                                                                                                            {{
                                                                                                                                data.priority
                                                                                                                            }}
                                                                                                                        </div>
                                                                                                                        <div v-else
                                                                                                                            class="capitalisetext mr-50"
                                                                                                                            v-bind:class="{ 'badge badge-pill badge-light-success': Boolean(data.priority) }">
                                                                                                                            {{
                                                                                                                                data.priority
                                                                                                                                ?
                                                                                                                                data.priority
                                                                                                                                :
                                                                                                                                'NA'
                                                                                                                            }}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <div
                                                                                                                        class="task_tatus_badge_edit">
                                                                                                                        <span
                                                                                                                            v-if="data.subpriority"
                                                                                                                            class="pull-left">{{
                                                                                                                                data.subpriority
                                                                                                                            }}
                                                                                                                        </span>
                                                                                                                        <span
                                                                                                                            v-else
                                                                                                                            class="pull-left">NA
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td
                                                                                                                    class="task_tatus_badge">
                                                                                                                    <div class="capitalisetext mr-50"
                                                                                                                        style="display: inline-flex;align-items: baseline;text-align: left;max-width: 160px;min-width: 145px;">
                                                                                                                        <span
                                                                                                                            class="buss_status_wrap">
                                                                                                                            {{
                                                                                                                                data.taskstatus
                                                                                                                            }}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                </td>

                                                                                                                <td
                                                                                                                    class="details_column_table">
                                                                                                                    <span
                                                                                                                        class="heading_column_text">
                                                                                                                        CREATED
                                                                                                                        BY :
                                                                                                                    </span>
                                                                                                                    {{
                                                                                                                        data.createdby.split('@')[0]
                                                                                                                    }}
                                                                                                                    <hr
                                                                                                                        class="hr_border-bottom" />
                                                                                                                    <span
                                                                                                                        class="heading_column_text">
                                                                                                                        Due
                                                                                                                        date
                                                                                                                        :
                                                                                                                    </span>
                                                                                                                    <span
                                                                                                                        v-if="data.due_date">
                                                                                                                        {{
                                                                                                                            dateMonthFormat(data.due_date)
                                                                                                                        }}
                                                                                                                    </span>
                                                                                                                    <span
                                                                                                                        v-else>
                                                                                                                        NA
                                                                                                                    </span>
                                                                                                                    <hr
                                                                                                                        class="hr_border-bottom" />
                                                                                                                    <span
                                                                                                                        class="heading_column_text full_100">
                                                                                                                        Created
                                                                                                                        date
                                                                                                                        :
                                                                                                                    </span>
                                                                                                                    {{
                                                                                                                        dateMonthFormat(data.createddate)
                                                                                                                    }}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </template>
                                                                                                        <template
                                                                                                            v-if="taskPlan.length == 0">
                                                                                                            <tr>
                                                                                                                <td colspan="14"
                                                                                                                    style="text-align: center;">
                                                                                                                    No
                                                                                                                    record
                                                                                                                    found
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </template>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane" id="about" role="tabpanel" aria-labelledby="about-tab"
                                        aria-expanded="false">
                                        <div class="row match-height ">
                                            <div class="row container  mt-1 mx-0 px-0" id="plannertable">
                                                <div class=" col-12 col-sm-6  col-md-4 col-lg-3 px-1"
                                                    v-for="(data, index) in plannermemberlist" v-bind:key="index">
                                                    <div class="card text-center card-transaction planer_view_card_st mb-1 card_member_details"
                                                        :style="{ 'background-color': colorlist[Math.floor(Math.random() * colorlist.length)] }">
                                                        <div class="card-body pt-1">
                                                                    <div class="avatar bg-light-primary p-50 mb-1">
                                                                        <div class="avatar-content">
                                                                            <user-icon size="1.5x"
                                                                                class="avatar-icon font-medium-3 "></user-icon>
                                                                        </div>
                                                                    </div>
                                                                    <div class="media-body">
                                                                            <div class="transaction-title capitalisetext">
                                                                                <span class="panner_title_name-3">Member Name:</span> 
                                                                                <span class="panner_title_disc-3"> {{ data.empname }} </span>
                                                                            </div>

                                                                            <div class="transaction-title"> 
                                                                               <span class="panner_title_name-3">Member Email ID:</span> 
                                                                               <span class="panner_title_disc-3">{{ data.emailid }} </span>
                                                                            </div>
                                                                            <div class="transaction-title capitalisetext">
                                                                               <span class="panner_title_name-3">Member Role:</span> 
                                                                               <span class="panner_title_disc-3"> {{ data.rolename }} </span> 
                                                                            </div>

                                                                    </div>
                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="document" role="tabpanel" aria-labelledby="document-tab"
                                        aria-expanded="false">
                                        <div class=" match-height ">
                                            <div class="row  documentGrid match-height custom_grid_watch_bt px-0">
                                                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 mb-1"
                                                    v-for="(data, index) in doclist" v-bind:key="index">
                                                    <div>
                                                        <div class="col-md-12">
                                                            <div class="row  documentGrid match-height">
                                                                <div class="col-xl-12 col-md-4 col-sm-6 mb-1"
                                                                    v-for="(res, index) in (data.documentname ? data.documentname.data : [])"
                                                                    v-bind:key="index">
                                                                    <div class="card card-bordered mb-50">
                                                                        <div class="text-center py-2"
                                                                            style="background:#F8F8F8;">
                                                                            <img alt=""
                                                                                v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.') + 1))"
                                                                                src="../../../public/images/icons/image.png"
                                                                                height="35" />

                                                                            <img alt=""
                                                                                v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'pdf'"
                                                                                src="../../../public/images/icons/ic-pdf.png"
                                                                                height="35" />

                                                                            <img alt=""
                                                                                v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'docx'"
                                                                                src="../../../public/images/icons/ic-doc.png"
                                                                                height="35" />
                                                                            <img alt=""
                                                                                v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'csv'"
                                                                                src="../../../public/images/icons/ic-csv.png"
                                                                                height="35" />
                                                                            <img alt=""
                                                                                v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'mp4'"
                                                                                src="../../../public/images/icons/ic-video.png"
                                                                                height="35" />
                                                                            <img alt=""
                                                                                v-else-if="res.link.substring(res.link.lastIndexOf('.') + 1) == 'xls' || 'xlsx'"
                                                                                src="../../../public/images/icons/ic-xls.png"
                                                                                height="35" />
                                                                            <a v-on:click="getDocDownloadLink(res.link)"
                                                                                target="_blank"
                                                                                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect dBtn"><download-icon
                                                                                    size="1.5x"
                                                                                    class="custom-class "></download-icon>
                                                                            </a>
                                                                            <a v-if="imgExt.includes(res.link.substring(res.link.lastIndexOf('.') + 1))"
                                                                                target="_blank"
                                                                                v-on:click="onZoom(res.link)"
                                                                                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary waves-effect modalBtn pop"><zoom-in-icon
                                                                                    size="1.5x"
                                                                                    class="custom-class "></zoom-in-icon>
                                                                            </a>
                                                                        </div>
                                                                        <a :href="res.link" class="py-50 px-50 border-top "
                                                                            style="font-size:0.85rem; color:#888;"
                                                                            target="_blank">
                                                                            <span v-if="res.info">
                                                                                {{ res.info }} -123
                                                                            </span>
                                                                            <span v-else-if="!res.info">
                                                                                {{ res.link | basename }}
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-body p-0">
                                            <button type="button" class="close imageModalClose" data-dismiss="modal"><x-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></x-icon></button>
                                            <img alt="" src="" class="imagepreview"  >
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane" id="mom" role="tabpanel" aria-labelledby="mom-tab"
                                        aria-expanded="false">
                                        <div class="ml-auto text-right">
                                            <div class="btn btn-sm btn-outline-primary waves-effect waves-effect ml-50 mb-50"
                                                @click="redirectToMOMCreate('createMOM')">
                                                <plus-icon size="1.5x" class="custom-class avatar-icon mr-50"></plus-icon>
                                                <span>Create MOM</span>
                                            </div>

                                        </div>
                                        <div v-bind:class="[{ show: isListActivemom }, cardmom]"
                                            style=" box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top: 1rem; margin-bottom: 1rem !important;">
                                            <div v-bind:class="[{ show: isListDetActivemom }, card_content, collapse]">
                                                <div class="">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="">
                                                                <div class="">
                                                                    <div class="tab-content" id="myTabContent">
                                                                        <div class="tab-pane fade show active" id="home"
                                                                            role="tabpanel" aria-labelledby="home-tab">
                                                                            <div class="row" id="table-small">
                                                                                <div class="col-12">
                                                                                    <div class="card">
                                                                                        <div class="table-responsive">
                                                                                            <table
                                                                                                class="table table-sm dataTableListing table-hover table-striped all_table_stickys"
                                                                                                id="table_businessceo1"
                                                                                                aria-describedby="mydesc">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle first-col-sticky"
                                                                                                            title=""
                                                                                                            style="width:45px;">
                                                                                                            Sr#</th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle second-col-sticky"
                                                                                                            style="min-width:230px"
                                                                                                            title="">
                                                                                                            Project Name
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            style="min-width:230px"
                                                                                                            title="">
                                                                                                            Project
                                                                                                            Module Name
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="Name"
                                                                                                            style="min-width:230px;">
                                                                                                            Description
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="">
                                                                                                            Start Time
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="">End
                                                                                                            Time</th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            style="min-width:140px"
                                                                                                            title="">
                                                                                                            Location
                                                                                                        </th>
                                                                                                        <th scope="col"
                                                                                                            class="align-middle"
                                                                                                            title="">
                                                                                                            Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    <template
                                                                                                        v-for="data, index  in momPlan">
                                                                                                        <tr
                                                                                                            v-bind:key="data.id">
                                                                                                            <td
                                                                                                                class="centeralign first-col-sticky">
                                                                                                                {{ index
                                                                                                                    + 1 +
                                                                                                                    currPageNum
                                                                                                                    *
                                                                                                                    pagelimit
                                                                                                                }}</td>

                                                                                                            <td class="text-normal details_column_table second-col-sticky"
                                                                                                                style="max-width:230px;">
                                                                                                                {{
                                                                                                                    data.projectname
                                                                                                                }}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="details_column_table">
                                                                                                                {{
                                                                                                                    data.projectmodulename
                                                                                                                }}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="details_column_table">
                                                                                                                <span v-html="formatData(data.description)"
                                                                                                                    ></span>
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                <div
                                                                                                                    class="capitalisetext badge badge-pill badge-light-info mr-50">
                                                                                                                    {{
                                                                                                                        data.starttime
                                                                                                                    }}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div
                                                                                                                    class="capitalisetext badge badge-pill badge-light-info mr-50">
                                                                                                                    {{
                                                                                                                        data.endtime
                                                                                                                    }}
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                class="details_column_table">
                                                                                                                {{
                                                                                                                    data.location
                                                                                                                }}
                                                                                                            </td>

                                                                                                            <td class="clsaction" >
                                                                                                                <div class="dropdown">
                                                                                                                <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                                                    <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                                                </button>
                                                                                                                <div class="dropdown-menu">
                                                                                                                    <a class="dropdown-item devEditEle"  @click="redirectToMOMUpdate('update', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                                                                                    <div class="d-flex align-items-center">
                                                                                                                        <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                                                        <span>Edit</span>
                                                                                                                    </div>
                                                                                                                    </a>
                                                                                                                    <a @click="redirectToMOMView('view', data)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                                                                                    <div class="d-flex align-items-center"> 
                                                                                                                        <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                                                                                        <span>View MOM Details</span>
                                                                                                                    </div>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </template>
                                                                                                    <template
                                                                                                        v-if="momPlan.length == 0">
                                                                                                        <tr>
                                                                                                            <td colspan="14"
                                                                                                                style="text-align: center;">
                                                                                                                No
                                                                                                                record
                                                                                                                found
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </template>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<style>
.fieldset_border_vi .badge{
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}
.fieldset_border_vi .card-text.blog-content-truncate span p{
    font-size: 16px;
}
.card .card-header {
    padding: 0.8rem;
}

.createbtnparentdiv {
    padding-right: 0% !important;
}

.displayblk {
    display: none !important;
}
#card-navigation1 .progressBarDiv{
  margin:0px!important;
  margin-bottom: 15px!important;
}
#card-navigation1 .container{
  padding: 0px;
}

.createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import { ExternalLinkIcon,FileTextIcon, ListIcon, PlusSquareIcon, DownloadIcon, SettingsIcon, PieChartIcon, PlayCircleIcon, ZoomInIcon, Trash2Icon, XIcon, PlusCircleIcon, Edit2Icon, PlusIcon, MoreVerticalIcon, PackageIcon, EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, UserIcon, MinusSquareIcon,AlertCircleIcon } from 'vue-feather-icons'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import presignurl from '../getPresignurl.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import BTCProgressBar from '../businesstask/BTCProgressBar.vue';
import profilepic from '../profilepic.vue';
import commonMethods from '../../utils/commonMethods';

export default {
    name: 'ViewPlans',
    components: {
        Loading,
        VueElementLoading,
        vSelect,
        ListIcon,
        ZoomInIcon,
        DownloadIcon,
        PlayCircleIcon,
        SettingsIcon,
        EditIcon,
        EyeIcon,
        Treeselect,
        PieChartIcon,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        Trash2Icon,
        PlusCircleIcon,
        XIcon,
        ExternalLinkIcon,
        UserIcon,
        MinusSquareIcon,
        FileTextIcon,
        presignurl,
        ckeditor: CKEditor.component,BTCProgressBar,profilepic,AlertCircleIcon,
    },
    mixins: [ commonMethods ],
    data() {
        return {
            componentKey: 0,
            imgExt: ['jpeg', 'jpg', 'png'],
            defaultimagepath:apiUrl.defaultimagepath,
            isLoading: false,
            islistLoading: false,
            fullPage: true,
            listfullPage: true,
            isistLoading: false,
            showAddForm: false,
            showfilterboxsection: false,
            currentmodule: '#projectmodulemaster',
            showaddmodulebtn: false,
            isSearchRequestActive: false,
            collapse: 'collapse',
            card_content: 'card-content',
            page: 1,
            totalcount: 0,
            isListActive: true,
            card: "card",
            isListDetActive: true,
            taskPlan: [],
            currPageNum: 0,
            pagelimit: 5,
            pagemom: 1,
            totalcountmom: 0,
            isListActivemom: true,
            cardmom: "card",
            isListDetActivemom: true,
            momPlan: [],
            currPageNummom: 0,
            pagelimitmom: apiUrl.LIMIT,
            totalcountplan: 0,
            showtabledata: true,
            plannermasterlist: [],
            colorlist: [],

            plannermemberlist: [],

            document: {
                selectedFiles: [],
                fields: [{
                    docname: '',
                    // comment: '',
                    attachment: '',
                }],
            },
            errors: {
                document: {
                }
            },
            documentname: '',
            newdocument: {
                selectedFiles: [],
                fields: [{
                    docname: '',
                    // comment: '',
                    attachment: '',
                }],
            },
            doclist: [],
            plannerdoc: {
                comment: '',
                attachmentview: [],
                disblesavebtn:false,
            },
            commentBlured: false,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading', '|', 'bold',
                        'italic', '|', 'bulletedList',
                        'numberedList', '|', 'insertTable', '|',
                        'undo', 'redo', '|',
                    ],
                },
            },
            routeplanner: null,
            loginusername: '',
            commentHistory: [],
            border_warning: 'border-warning',
            card: 'card',
            accepttype: apiUrl.uploadfiletype,
            file: [],
            validFileExtensions: apiUrl.validFileExtensions,
            attachment: null,
            attachmentnew: null,
            attachmentview: [],
            isRequestActive:false,
            isProgressRequestActive: false,
            tasksrows: [],
            // completedBTCsCount: [],
            btcidList:[],
            isListActive:true,
            isListDetActiveplan:true,
            isboardDetailsActives:false,
            btmversionlist: [],
            pagefeatures:[],
            displayblk:'displayblk',
            isgridView:true,
            activegridlink:false,
            activelistlink:false,
            businessTaskDetailsSum: [],
            boardApiList: [],
            isListDetailsDataActives:false,
            chartDetails:'',
            planD: true,
            pannerbtcids: [],
            current_page: 1,
            records_per_page: 2,
            glbMdl:'',
            glbst:'',
            glbMdlLbl:'company',
            btctasklist: [],
        }
    },
    created() {
        this.pagefeatures = {}
        if(window.localStorage.getItem('pagefeatures')){
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
                if (element.page === '/businesstask/list') {
                    this.pagefeatures[element.featurename] = element.featureaccess
                }
            })
        }
    },

    mounted() {
        // let permission = this.$Service.verifyAccess();

        let userdata = window.localStorage.getItem('userdata');
        if (userdata) {
            userdata = JSON.parse(userdata)
            this.undt = userdata
            this.loginusername = userdata.username
            this.loginpersonname = userdata.firstname + ' ' + userdata.lastname;
            this.loginuscondition = userdata.userid
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.getAssignPlannerList(this.$route.query.plannermasterid)
            this.getAssignMOMPlannerList(this.$route.query.plannermasterid)
            this.getplannerlist(this.$route.query.plannermasterid)
            this.getplannermemberlist(this.$route.query.plannermasterid)
            this.docListByPlannerID(this.$route.query.plannermasterid)
            this.getPlannerCommentsById(this.$route.query.plannermasterid)
            this.routeplanner = this.$route.query.plannermasterid
            this.getBusinessTaskDetailsVSSeverity()
        }
        this.colorlist = ['#ffcebe', '#ffe3c0', '#ffe9f0', '#F9D8D6', '#CDF5F6', '#CBE4F9']

        $(".filter_rotation_onlick").click(function () {
            $(this).toggleClass("common_arrow_rotetion");
        });

        $(".custom-file-input").on("change", function () {
            var fileName = $(this).val().split("\\").pop();
            $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });
    },
    methods: {
        removeBTC(data) {
            axios({
                method: "POST",
                url: "api/master/planner/removedBTCfromPlaner",
                headers: { authorization: this.tokendata },
                data: { empcode: this.undt.userid, useremail: this.undt.username, bid: data.id, pid:this.$route.query.plannermasterid }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    Swal.fire({
                        title: "Success!",
                        text: "Removed Business Task Successfully!",
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else {
                    Swal.fire({
                        title: "Failed!",
                        text: "Failed to update..!",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
                this.getAssignPlannerList(this.$route.query.plannermasterid)
            }).catch(e => {
                    this.displayError(e)
                });
        },
        openBTMList(){
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#card-navigation").offset().top - 70
            }, 2000);
        },
        openProgressBar(){
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#card-navigation1").offset().top - 70
            }, 2000);
        },
        openDocList(){
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#card-navigation2").offset().top - 70
            }, 2000);
        },
        openMOMdetails(){
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#card-navigation3").offset().top - 70
            }, 2000);
        },
        redirecttoprofile(empid){
            let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
            window.open(route.href);
        },
        getBusinessTaskVersion(businesstaskid, ...args) {
            let btmInfo = { taskname: args[0], author:args[1], createdat: args[2] }
            this.isLoading = true
            let apiUrl = 'api/master/businesstask/getHistoryByBusinesstaskid'
            this.input = { businesstaskid,empcode: this.undt.userid,useremail: this.undt.username }
            axios({method: "POST",'url':apiUrl, 'data':this.input,'headers': { authorization: this.tokendata }})
            .then(result => {
                this.isLoading = false
                $('#technologypopup').modal('show');
                if(result.data.errorCode == 0) {
                    this.btmversionlist = result.data.data.rows
                    this.btmversionlist.unshift(btmInfo)
                } else {
                    this.btmversionlist = []
                    this.btmversionlist.unshift(btmInfo)
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        formatData(data) {
            if(!data) {
            return 'NA'
            }
            if(data.length > 83) {
                return data.substring(0,84) + '...'
            }
            return data
        },
        formatDataComment(data) {
            return data
        },
        idclick6(indexx){
            
            if ($("#cycle_readmore6"+indexx).hasClass("readmore")) {
                $("#id6"+indexx).html("Read More");
                $("#cycle_readmore6"+indexx).removeClass("readmore");
            } else {
                $("#id6"+indexx).html("Read Less");
                $("#cycle_readmore6"+indexx).addClass("readmore");
            }
        },
        getAssignPlannerList(id) {
            axios({
                method: "POST",
                url: "api/getAssignPlannerList",
                headers: { authorization: this.tokendata },
                data: { empcode: this.undt.userid, useremail: this.undt.username, plannerid: parseInt(id),offset:this.pageoffset,limit:5 }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.taskPlan = result.data.data.getData
                    this.pannerbtcids = this.taskPlan.map(i => i.id)
                    this.totalcount = result.data.data.count;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                } else {
                    this.taskPlan = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBtcStatusList() {
            let url = "api/btc/getBtcStatusList";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.btctasklist = result.data.data.rows;
                } else {
                    this.btctasklist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });     
        },
        getTaskMasterBroadlist(id) {
            this.input = {
                empcode: this.undt.userid, 
                useremail: this.undt.username, 
                plannerid: parseInt(id),
                offset:null,
                limit:null,
            }
            axios({
                method: "POST",
                url: "api/getAssignPlannerList",
                headers: { authorization: this.tokendata },
                data: this.input,
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                let datat = result.data.data.getData.reduce((group, task) => {
                let { taskstatus } = task;
                group[taskstatus] = group[taskstatus] ? group[taskstatus] : [];
                group[taskstatus].push(task);
                return group;
                }, {});
                let tasklist = this.btctasklist;
                this.boardApiList = Object.entries(datat);
                this.boardApiList.forEach((status, k) => {
                    let statusval = status[0];
                    const found = tasklist.find(obj => obj.id === statusval);
                    if(found){
                        this.boardApiList[k].push(found.sno);
                    }
                });
                this.boardApiList = this.boardApiList.sort((a,b) => a[2] - b[2]);
                this.boardApiListTemp = this.boardApiList.map(item => item.slice());
                this.paginate(1, 2);                } 
            }).catch(e => {
                this.displayError(e)
            });
        },
        prevPage() {
            if (this.current_page > 1) {
                this.current_page--;
                this.paginate(this.current_page, this.records_per_page);
            }
        },
        nextPage() {
            if (parseInt(this.current_page) < (parseInt(this.numPages()))) {
                this.current_page++;
                this.paginate(this.current_page, this.records_per_page);
            }
        },
        paginate(page, pageSize) {
            this.boardApiListTemp.forEach((element, k) => {
                let startIndex = (page - 1) * pageSize
                let endIndex = Math.min(page * pageSize, element[1].length - 1);
                //if((element[1].length-1) == endIndex) { endIndex = element[1].length }
                this.boardApiList[k][1] = [];
                if(element[1].length>2) {
                    this.boardApiList[k][1] = element[1].slice(startIndex, endIndex);
                } else {
                    this.boardApiList[k][1] = element[1];
                }
            });
            this.boardApiList = this.boardApiList.map(item=> item.slice());
            $('#page').html(page);

            if (page < 1) page = 1;
            if (page > this.numPages()) page = this.numPages();

            if (page == 1) {
                $('#btn_prev').hide();
            } else {
                $('#btn_prev').show();
            }

            if (page == this.numPages()) {
                $('#btn_next').hide();               
            } else {
                $('#btn_next').show();
            }
        },
        numPages()
        {   
            let numArr = [];
            this.boardApiListTemp.forEach(ele => {
                numArr.push(ele[1].length)
            });
            let maxno = (numArr.length>0) ? Math.max.apply(Math, numArr) : null;
            if(maxno){
                return Math.ceil(maxno / this.records_per_page);
            }
        },
        getBusinessTaskDetailsVSSeverity: function(search){
          this.isLoading = true;
          let url = "api/business/getBusinessTaskDetailsVSSeverity";
          this.statusinput = {
            useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
            empcode: this.undt.userid,
            userrole:this.userrole,
          };
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.businessTaskDetailsSum = result.data.data.sum;
            } else if(result.data.errorCode == 3){
            Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            } else {
              this.businessTaskDetailsSum = [];
            }
          }).catch(e => {
             this.displayError(e)
          });
      },
        getAssignMOMPlannerList(id) {
            axios({
                method: "POST",
                url: "api/getMomPlannerList",
                headers: { authorization: this.tokendata },
                data: { empcode: this.undt.userid, useremail: this.undt.username, plannermasterid: parseInt(id) }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.momPlan = result.data.data.getData
                    this.totalcountmom = result.data.data.count
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        showprojectname(prname) {
            let responsestr = '';
            let prlist = prname.split(',')
            if (prlist.length > 1) {
                for (var i = 0; i < prlist.length; i++) {
                    responsestr += (i + 1) + '. ' + prlist[i] + ' <br/>'
                }
            } else {
                for (var i = 0; i < prlist.length; i++) {
                    responsestr += prlist[i]
                }
            }
            return responsestr
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        redirecttourl: function (btnstate, rowdata) {
            rowdata.backbtnstate = btnstate
            if (btnstate == "view") {
                let routeData = this.$router.resolve({
                    path: '/businesstask/view?businessid=' + rowdata.moduleid,
                    params: {
                        backbtnstate: btnstate,
                        clickeddata: rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }else if(btnstate=="edit"){
                this.$router.push({ 
                    path: '/businesstask/update?businessid='+rowdata.moduleid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        buttonDeactive(){
            this.planD =false
        },
        buttonactive(){
            this.planD =true
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl = this.glbMdl.split('##')
            let inputEle = '';
            let btnEle = '';
            if (arrGlbMdl.length > 0) {
                if (typeof arrGlbMdl[0] != 'undefined') {
                    inputEle = arrGlbMdl[0];
                }
                if (typeof arrGlbMdl[1] != 'undefined') {
                    btnEle = arrGlbMdl[1];
                }
            }
            this.getAssignPlannerList(this.$route.query.plannermasterid);
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $("#scrollToListGrid").offset().top - 70
                });
            }, 500);
        },
        redirecttoviewdetailsurl: function (btnstate, rowdata) {
            rowdata.backbtnstate = btnstate
            if (btnstate == "view") {
                let routeData = this.$router.resolve({
                    path: '/viewmomplanner/view?plannermasterid=' + rowdata.plannermasterid,
                    params: {
                        backbtnstate: btnstate,
                        clickeddata: rowdata.plannermasterid
                    }
                })
                window.open(routeData.href, '_blank');
            }
        },
        getplannerlist(id) {
            this.isLoading = true;
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                id: parseInt(id)
            }
            axios({
                'method': 'POST',
                'url': 'api/planner/list',
                'data': this.input,
                'headers': { 'authorization': this.tokendata }
            })
                .then(result => {
                    this.isLoading = false
                    if (result.data.errorCode == 0) {
                        this.plannermasterlist = result.data.data.rows;
                        this.totalcountplan = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                        setTimeout(function () {
                            $('html, body').animate({
                                scrollTop: $("#plannertable").offset().top - 70
                            });
                        }, 500);
                    } else if (result.data.errorCode == 3) {
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function () {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.companymasterlist = [];
                    }
            }).catch(e => {
                this.displayError(e)
            });
        },
        clickCallbackPlan: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getplannerlist();
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $("#plannertable").offset().top - 70
                });
            }, 500);
        },
        getplannermemberlist(id) {
            this.isLoading = true;
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                planermasterid: parseInt(id)
            }
            axios({
                'method': 'POST',
                'url': 'api/plannermemberdetails',
                'data': this.input,
                'headers': { 'authorization': this.tokendata }
            })
                .then(result => {
                    this.isLoading = false
                    if (result.data.errorCode == 0) {
                        this.plannermemberlist = result.data.data.rows;
                        this.totalcountplan = result.data.data.count;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                        setTimeout(function () {
                            $('html, body').animate({
                                scrollTop: $("#plannertable").offset().top - 70
                            });
                        }, 500);
                    } else if (result.data.errorCode == 3) {
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function () {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.companymasterlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
        },
        redirectToMOMUpdate: function (btnstate, rowdata) {
            this.$router.push({
                name: 'momupdate',
                path: '/fc_mom/momupdate',
                params: {
                    backbtnstate: btnstate,
                    id: rowdata.id,
                    plannermasterid: rowdata.plannermasterid
                }
            })
        },
        redirectToMOMView: function (btnstate, rowdata) {
            this.$router.push({
                name: 'viewmomplanner',
                path: '/viewmomplanner/view',
                params: {
                    backbtnstate: btnstate,
                    id: rowdata.id,
                    plannermasterid: rowdata.plannermasterid
                }
            })
        },
        redirectToMOMCreate(btnstate) {
            let plannerid = this.$route.query.plannermasterid
            this.$router.push({
                // name: 'momcreate',
                path: `/fc_mom/momcreate?plannermasterid=${plannerid}`,
                params: {
                    backbtnstate: btnstate,
                    plannermasterid: plannerid
                }
            })
        },

        onSelectDoc(event, idx) {
            let currFile = event.target.files[0]
            let currSelectedFilesLength = this.document.selectedFiles.length
            if (currSelectedFilesLength) {
                this.document.selectedFiles[idx] = currFile
            } else {
                let curLen = this.document.fields.length
                let arr = []
                if (curLen) {
                    for (let i = 0; i < curLen; i++) {
                        arr.push('')
                    }
                    this.document.selectedFiles = arr
                    this.document.selectedFiles[idx] = currFile
                } else {
                    this.document.selectedFiles.push(currFile);
                }
            }
            this.document.fields[idx].attachment = currFile.name
        },
        addDocument() {
            this.document.fields.push({
                docname: '',
                // comment: '',
                attachment: '',
                isuploaded: false
            })
        },
        onUpload() {
            if (this.file) {
                this.isLoading = true
                if (this.file.length !== 0) {
                    this.apiURL = "api/master/fileupload/uploadBulkFileToS3"
                    let formData = new FormData();
                    let maxsize = 0;
                    for (let index = 0; index < this.file.length; index++) {
                        formData.append("imagefile", this.file[index]);
                        maxsize += this.file[index].size
                    }
                    formData.append("empcode", this.undt.userid);
                    formData.append("useremail", this.undt.username);
                    formData.append("moduletype", 'plannerdoc');
                    formData.append("fcount", parseInt(this.file.length));

                    if (maxsize > 1000 * 1000 * 25) {
                        // this.isLoading = false;
                        Swal.fire({
                            title: "Failed",
                            text: "Your document uploaded above 25mb not allow",
                            icon: "info",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.plannerdoc.disblesavebtn = true
                        this.isLoading = false
                        return false;
                    }
                    axios({
                        'content-type': 'multipart/form-data',
                        'method': 'POST',
                        'url': this.apiURL,
                        'data': formData,
                        'headers': { 'authorization': this.tokendata, 'content-type': 'multipart/form-data' }
                    })
                        .then(result => {
                            this.isLoading = false;
                            if (result.data.status) {
                                this.plannerdoc.disblesavebtn = false

                                const bindinfo = result.data.data.map((file, index) => {
                                    return { "link": file }
                                });
                                let jsonobj = { data: bindinfo }
                                this.plannerdoc.attachmentnew = JSON.stringify(jsonobj);
                                Swal.fire({
                                    title: "Success!",
                                    text: "Image Uploaded Successfully",
                                    icon: 'success',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                            } else {
                                Swal.fire({
                                    title: "",
                                    text: result.data.msg,
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    },
                                    buttonsStyling: !1
                                })
                                this.plannerdoc.attachment = null
                            }
                        })
                } else {
                    this.isLoading = false;
                    Swal.fire({
                        title: "",
                        text: 'Select image/docs/videos',
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.plannerdoc.attachment = null
                }
            } else {
                this.isLoading = false;
                Swal.fire({
                    title: "",
                    text: 'Select image/docs/videos',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.plannerdoc.attachment = null
            }
        },
        removeDocument(idx, file, selectedFiles) {
            if (file && file.length > 1) {
                file.splice(idx, 1);
                selectedFiles.splice(idx, 1)
            }
        },
        removeImage(index) {
            if (this.file.length > 0) {
                this.file.splice(index, 1);
                this.plannerdoc.filedescp.splice(index, 1);
                var removeimg = JSON.parse(this.plannerdoc.attachmentnew)
                if (removeimg) {
                    var remove = removeimg.data.splice(index, 1)
                    if (remove.length > 0)
                        this.plannerdoc.attachmentnew = JSON.stringify({ data: removeimg.data })
                    else {
                        this.plannerdoc.attachmentnew = null
                    }
                }
            }
            if (this.file.length < 1) {
                this.issue.disblesavebtn = false
            }
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{1,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        savePlannerDoc() {
            this.commentBlured = true
            if (!this.plannerdoc.comment==null || !this.plannerdoc.comment=='' ) {
                this.commentBlured = true
                this.input = {
                plannermasterid: this.routeplanner,
                createdby: this.undt.username,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                comment: this.plannerdoc.comment,
                documentname: JSON.stringify(this.plannerdoc.attachmentnew)
            }
            if (this.plannerdoc.attachmentnew) {
                let jsonobj1 = JSON.parse(this.plannerdoc.attachmentnew);
                let arraymerge = [...jsonobj1.data]
                this.input.documentname = JSON.stringify({ data: arraymerge });
            }
            axios({
                method: 'POST',
                url: 'api/master/planner/createPlannerDocument',
                data: this.input,
                headers: { authorization: this.tokendata }
            })
                .then(result => {
                    this.isLoading = false
                    if (result.data.errorCode == 0) {
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.getPlannerCommentsById(this.routeplanner)
                        this.clearData()
                        this.docListByPlannerID(this.routeplanner)
                        // this.getAssignPlannerList()
                    } else if (result.data.errorCode == 3) {
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function () {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
                .catch(e => {
                    this.isLoading = false
                    Swal.fire({
                        title: "Failed",
                        text: e,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                })
            } 
            // this.commentBlured = true
            
        },
        clearData() {
            this.plannerdoc.comment = ''
            this.commentBlured= false,
            this.plannerdoc.selectedFiles = [],
            this.file = []
            // this.getPlannerCommentsById(id)
        },
        handleCancel() {
            this.$router.push(`/planner/viewplan?plannermasterid=${this.routeplanner}`)
            this.clearData()
        },

        docListByPlannerID(id) {
            this.isLoading = true;
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                planermasterid: parseInt(id)
            }
            axios({
                'method': 'POST',
                'url': 'api/doclistbyplannerid',
                'data': this.input,
                'headers': { 'authorization': this.tokendata }
            })
                .then(result => {
                    this.isLoading = false
                    if (result.data.errorCode == 0) {
                        this.doclist = result.data.data.rows;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                        setTimeout(function () {
                            $('html, body').animate({
                                scrollTop: $("#plannertable").offset().top - 70
                            });
                        }, 500);
                    } else if (result.data.errorCode == 3) {
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function () {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.companymasterlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
        },
        openEditModule(currRowData, flag) {
            if (currRowData && currRowData.length == 0) {
                currRowData = this.editRowData
            }
            this.plannerdoc.docname = currRowData.documentname
            if (currRowData.documentname && currRowData.documentname.hasOwnProperty('data')) {
                this.plannerdoc.attachmentview = [];
                this.getDownloadLink(currRowData.documentname);
            }
        },
    myListView: function () {  
            this.isListDetActive = true;
            this.isgridView=true;
            this.isListDetActiveplan = true;
            this.chartDetails=true;
            this.activegridlink=false;
            this.activelistlink=true;  
            this.isListDetailsDataActives=false;
            this.isboardDetailsActives=false; 
            localStorage.setItem('activebtctab', 'listview');
        },
        myGridView(){
            this.isListDetActive = false;
            this.isgridView=false;
            this.activegridlink=true;
            this.activelistlink=false;
            this.isListDetActiveplan=false;
            this.chartDetails=true;
            this.isListDetailsDataActives=true;
            this.isboardDetailsActives=false;
            localStorage.setItem('activebtctab', 'gridview');
        },
        boardShowView(){
            this.isListDetActive = false;
            this.isgridView=true;
            this.isListDetailsActive=true;
            this.chartDetails=false;
            this.activegridlink=false;
            this.activelistlink=false;
            this.isListDetActiveplan=false;
            this.isListDetailsDataActives=false;
            this.isboardDetailsActives=true;
            this.getTaskMasterBroadlist(this.$route.query.plannermasterid);
            localStorage.setItem('activebtctab', 'boardview');
        },

        getDocDownloadLink(data, curr = null) {
            if (data) {
                var input = []
                input.push({ link: data, empcode: this.undt.userid, useremail: this.undt.username })
                let payload = {
                    documents: input,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: '/api/imageupload/getdownloadlink',
                    data: payload,
                    'headers': { 'authorization': this.tokendata }
                })
                    .then((result) => {
                        if (result.data.status) {
                            if (result.data.data[0].presignurl) {
                                if (curr == 'zoom') {
                                    $('.imagepreview').attr('src', result.data.data[0].presignurl);
                                    $('#imagemodal').modal('show');
                                    return
                                }
                                window.location.href = result.data.data[0].presignurl;

                            }
                        }
                    });
            }
        },
        onZoom(e) {
            console.log(e);
            this.getDocDownloadLink(e, 'zoom');
        },
        getPlannerCommentsById(id) {
            this.isLoading = true
            let url = "api/master/planner/getPlannerDocDetailsById";
            this.input = {
                plannermasterid: parseInt(id),
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            })
                .then(result => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.commentHistory = result.data.data;
                    } else if (result.data.errorCode == 3) {
                        Swal.fire({
                            title: "Failed!",
                            text: "Your are not authorised user to view this documents",
                            icon: "info",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function () {
                            window.location.href = "/#/login";
                        })
                    } else {
                        this.commentHistory = []
                    }
                }).catch(e => {
                    this.displayError(e)
                });
        },

        onFileChange(e) {
            var validFileExtensions = apiUrl.validFileExtensions
            let selectedFiles = e.target.files;
            for (let i = 0; i < selectedFiles.length; i++) {
                var blnValid = false;
                for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
                    var sCurExtension = validFileExtensions[j];
                    if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                        blnValid = true;
                        break;
                    }
                }
                if (!blnValid) {
                    Swal.fire({
                        title: "ERROR",
                        text: "Sorry, Invalid Type of Extension File..!!",
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    return false;
                }
                this.file.push(selectedFiles[i]);
            }
            if(this.file.length > 0) {
              this.plannerdoc.disblesavebtn = true
             }
        },


    },
    filters: {
        basename: function (value) {
            if (!value) return ''

            return value.substr(value.lastIndexOf("/") + 1)
        }
    }
}
</script>
