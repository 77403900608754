<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-12 content-header-left pl-0">
                 <h3 class="content-header-title float-left mb-0">Test Execution Cycle wise Issue Count</h3>
                 <div class="breadcrumb-wrapper">
                   <ol class="breadcrumb">
                     <li class="breadcrumb-item sitebluecolor cursorpointercls " >
                     <router-link to='/reports/testcyclereport'>Test Execution Cycle wise Issue Count</router-link></li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
         </div>
 
        <div class="card border-warning filter_card_searchbgst" style="border-radius: 5px; border: none!important;">
          <div class="card-header filter_rotation_onlick " style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="w-auto px-1 mobile-padding-0-2 mobile_w_calc_38 d-inline-flex align-items-center">
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                            <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                            </search-icon>
                        </div>
                    </div>
                      <h4 class="card-title d-inline-flex ml-1">Search Test Execution Cycle wise Issue Count</h4>
                </div>
                <div class="ml-auto mr-lg-1 d-flex">
                      <span class="rotetions_view_arrow"> 
                        <em class="las la-angle-down"></em>
                      </span>
                  </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
            <div class="card-body pt-2">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker"> 
                  <label for="" class="d-block">From Date</label>
                  <date-picker placeholder="Select Action Start Date" v-model="search.actionstartdate" valueType="format" class="startdate" id="StartDate" :disabled-date="notBeforeToday" @input="selectedstartdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker"> 
                  <label for="" class="d-block">To Date</label>
                  <date-picker placeholder="Select Action End Date" v-model="search.actionenddate" valueType="format" class="startdate" id="EndDate" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject" :multiple="true" :clear-on-select="true"/>   
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Test Cycle Code</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 5 char to serach cycleset code" :multiple="true" :clear-on-select="true" v-model="search.testcyclecode" v-on:search-change="getcyclesetcodelist" :options="testcyclecodelist"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                <label class="form-label lable-left">QA Lead</label>
                <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Lead"  :options="qaleadlist" v-model="search.qalead" :multiple="true" v-on:search-change="getProjectQAdata(509,null,$event)" :clear-on-select="true"/> 
                </div>
                  <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">QA Engineer</label>
                  <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Engineer"  :options="qaenglist" v-model="search.qaeng" :multiple="true" v-on:search-change="getProjectQAdata(478,null,$event)" :clear-on-select="true"/> 
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3">
                  <label class="form-label lable-left">Business Task Name</label>
                  <treeselect class="projectmdllistdd" placeholder="Enter min 5 chars to search Business Task Name" v-model="search.searchBusinesstaskname" :options="businesstasknameopts" v-on:search-change="businessNameChangeSearch" :clear-on-select="true"/>                      
                </div>
                  <div class="col-sm-6 col-md-6 col-lg-3 ">
                    <button type="submit" :disabled="isBothDateSelected" class="btn btn-relief-primary mt-2 btn-margin-three" @click="getDateArr(search)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 btn-margin-three" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                    <button :disabled="isBothDateSelected" type="move" class="btn btn-relief-primary mt-2 mr-0" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div id="scrollToListGrid"></div>
        <div class="card border-warning" id="btDiv">
          <div class="card-body p-0 ">
            <div  class="card-header px-1 py-1 view_bg-gradient-primary">
              <h2  class="card-title heading_text_pro_summarys">Test Execution Cycle wise Issue Count </h2>
            </div>
            <div v-if="this.reportissuelist.length>0" class="table-responsive" >
              <table class="table table-hover table_stickys table-col-td-white" aria-describedby="mydesc">
                <thead class="d-none"><th scope=""></th></thead>
                <tbody>
                  <template>
                        <tr>
                          <th scope="col">
                            QA Lead
                          </th>
                          <th scope="col">
                            QA Engineer
                          </th>
                          <th scope="col">
                            Business Task
                          </th>
                          <th scope="col">
                            Cycle Code
                          </th>
                          <th scope="col" v-for="date in this.dateArr">
                            {{date}}
                          </th>
                          <th scope="col">Total</th>
                        </tr>
                        <tr>
                          <th scope="col">
                          </th>
                          <th scope="col">
                          </th>
                          <th scope="col">
                          </th>
                          <th scope="col">
                          </th>
                          <th scope="col" v-for="date in this.dateArr">
                            New | Reopen
                          </th>
                          <th scope="col"></th>
                        </tr>
                        <tr v-for="(rdata, i) in this.reportissuelist">
                            <td v-if="rdata[1][0].qalead">
                                {{ rdata[1][0].qalead.toUpperCase() }}
                            </td>
                            <td v-if="rdata[1][0].createdby">
                                {{ rdata[1][0].createdby.split('@')[0].replace('.', ' ').toUpperCase() }}
                            </td>
                            <td>
                              {{ rdata[1][0].businesstaskcode }}
                            </td>
                            <td>
                              {{ rdata[1][0].cyclesetcode }}
                            </td>
                            <td v-for="date in rdata['all_dates']">
                            <span>{{ rdata[date] }} &nbsp; &nbsp;&nbsp; | &nbsp; &nbsp; {{ rdata[date+'_reopen'] }}</span>
                            </td>
                            <td>
                              <b>{{ rdata['csum'] }}</b>
                            </td>                            
                        </tr>
                        <tr>
                          <td><b>Total</b></td>
                          <td><b></b></td>
                          <td><b></b></td>
                          <td><b></b></td>
                          <th scope="col" v-for="(sum,i) in this.rowsSum">
                            <td><b>{{sum}}</b> | <b>{{ rowsReopenSum[i] }}</b></td>
                          </th>
                        </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div v-else class="pageCenter text-center mt-1">
                <span v-if="search.actionstartdate == null && search.actionenddate == null">Please apply dates filter to found the data!</span><br/>
                Data Not Found
            </div>
            <div class="pageCenter text-center pt-1">
                <pagination v-if="this.reportissuelist.length>0" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            </div>
          </div>
        </div>
        </section>
     </div>
   </div>
 </template>
 
 <script>
 import axios from 'axios';
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Loading from 'vue-loading-overlay';
 import DatePicker from "vue2-datepicker";
 import 'vue-loading-overlay/dist/vue-loading.css';
 import '@riophae/vue-treeselect/dist/vue-treeselect.css';
 import { SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
 import Treeselect from '@riophae/vue-treeselect'
 import moment from "moment";
 import Pagination from 'vue-pagination-2';
 import commonMethods from '../../utils/commonMethods';

 export default {
   name: 'TestCycleReport',
   mixins: [ commonMethods ],
   components: {
     VueElementLoading,
     Loading,
     SearchIcon,
     XIcon,
     ExternalLinkIcon,
     Treeselect,DatePicker,Pagination,
   },
   data() {
     return {
        isBothDateSelected:false,
        projectnameList:[],
        businesstasknameopts: null,
        search: {
          actionstartdate:null,
          actionenddate: null,
          qalead: null,
          qaeng: null,
          searchBusinesstaskname:null,
          searchProjectname: null,
          testcyclecode: null
        },
        qaleadlist:[],
        qaenglist: [],
        isLoading: false,
        undt: null,
        tokendata: null,
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        addformbtntxt:'Next',
        reportissuelist: [],
        dateArr: [],
        totalcount: 0,
        pageCount: 0,
        current_page: 1,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures:[],
        startDate: null,
        endDate:null,
        pagelimit:apiUrl.LIMIT,
        page:1,
        glbMdl:'',
        glbst:'',
        glbMdlLbl:'company',
        rowsSum: [],
        rowsReopenSum: [],
        testcyclecodelist: []
     }
   },
   created() {},
   mounted() {
      var userdata = window.localStorage.getItem('userdata');
      if(userdata){
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.getDateArr(null);
      }
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      }); 
   },
   methods: {
      selectproject(state, value) {
        this.pageoffset=1
        if(!state){
          this.qaleadlist=[];
          this.qaenglist= [];
        }else{
          this.search.searchProjectname = state;
          this.getProjectQAdata(478,state, null);
          this.getProjectQAdata(509,state, null);
          this.searchactiveflag = false;
        }
      },
      getProjectQAdata(roleid, state, node){
          let inputappl = {
              empcode: this.undt.userid,
              useremail: this.undt.username,
              node: (node != null && node != '' && node.length > 2)? node : null,
              roleid: roleid,
              projectidarr:((state != null && state.length > 0))? state : null,
          }
          if((node != null && node.length>=2) || (state != null && state.length > 0)) {
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==509){
                        this.qaleadlist=result.data.data.rows;
                    }else if (roleid==478){
                        this.qaenglist=result.data.data.rows;
                    }
                }else{
                    if(roleid==509){
                        this.qaleadlist=[]
                    }else if (roleid==478){
                        this.qaenglist=[];
                    }
                }
            }).catch(e => {
    this.displayError(e)
    })
          } else {
            this.qaleadlist=[];
            this.qaenglist= [];
          }
      },
      selectedenddate() {
          if (this.search.actionstartdate && this.search.actionenddate) {
            this.isBothDateSelected = false;
            this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
          } else {
            this.isBothDateSelected = true;
          }
      },
      selectedstartdate() {
          if (this.search.actionstartdate && this.search.actionenddate) {
            this.isBothDateSelected = false;
            this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
          } else {
            this.isBothDateSelected = true;
          }
      },
      getDatesInRange(fromDate, toDate) {
          let dates = [];
          const current = new Date(fromDate);
          const end = new Date(toDate);
          const oneDay = 24 * 60 * 60 * 1000; 
          while (current <= end) {
              dates.push(current.toISOString().split('T')[0]);
              current.setTime(current.getTime() + oneDay);
          }
          return dates;
      },
      businessNameChangeSearch(node) {
        if(node != null && node != '' && node.length >= 5) {
            this.isLoading = true;
            let url = "api/reports/getBTCbyProject";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                node: node ? node.toLowerCase() : null,
                projectid: this.search.searchProjectname? this.search.searchProjectname : null,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.businesstasknameopts = result.data.data.rows;
                } else {
                    this.businesstasknameopts = [];
                }
            }).catch(e => {
    this.displayError(e)
    })
        } else {
          this.businesstasknameopts = [];
        }
      },
      clickCallback: function(pageNum) {
          this.pageoffset=pageNum
          this.currPageNum = pageNum - 1
          this.testCycleIssueReportList();
          setTimeout(function(){
              $('html, body').animate({
                  scrollTop: $("#scrollToListGrid").offset().top -70
              });
          }, 500);
      },
      testCycleIssueReportList() {
        if(this.dateArr.length>0){
          this.input = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            startDate: (this.search.actionstartdate!=null)?this.search.actionstartdate:this.startDate,
            endDate: (this.search.actionenddate!=null)?this.search.actionenddate:this.endDate,
            projectid: this.search.searchProjectname,
            qaeng: this.search.qaeng,
            qalead: this.search.qalead,
            btcid: this.search.searchBusinesstaskname,
            cyclesetid: this.search.testcyclecode
          }
          axios({
              method: "POST",
              url: "api/btc/cycle/testCycleIssueReportList",
              data: this.input,
              headers: { authorization : this.tokendata },
          })
          .then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                const groupByCategory = result.data.data.rows.reduce((group, product) => {
                  const { cyclesetid } = product;
                  group[cyclesetid] = group[cyclesetid] ?? [];
                  group[cyclesetid].push(product);
                  return group;
                }, {});
                let convertedArr = Object.entries(groupByCategory);
                let startIndex = null;
                if(convertedArr.length<this.pagelimit) {
                  startIndex = 0
                }else{
                  startIndex = (this.pageoffset - 1) * this.pagelimit
                }
                // let startIndex = (this.pageoffset - 1) * this.pagelimit
                let endIndex = Math.min(this.pageoffset * this.pagelimit, convertedArr.length);
                convertedArr = convertedArr.slice(startIndex, endIndex);
                this.rowsSum = [];
                this.rowsReopenSum = [];
                this.dateArr.forEach((date, i) => {
                  let rsum = 0;
                  let rreopensum = 0;
                  convertedArr.forEach((edata, i) => {
                    convertedArr[i]['all_dates'] = this.dateArr;
                    convertedArr[i][date] = parseInt((convertedArr[i][1].filter((obj) => obj.createddate == date)).length)
                    convertedArr[i][date+'_reopen'] = parseInt((convertedArr[i][1].filter((obj) => obj.createddate == date && obj.status == 'reopen')).length)
                    if(convertedArr[i]['csum'] == undefined) {
                      convertedArr[i]['csum'] = 0;
                    }
                    convertedArr[i]['csum'] +=(convertedArr[i][date]+convertedArr[i][date+'_reopen']); 
                    rsum += convertedArr[i][date];
                    rreopensum += convertedArr[i][date+'_reopen'];
                  });
                  this.rowsSum.push(rsum)
                  this.rowsReopenSum.push(rreopensum)
                });
                this.reportissuelist = convertedArr;
                this.totalcount = Object.entries(groupByCategory).length;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
              } else {
                this.reportissuelist = []
                this.totalcount = 0;
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
              }
          }).catch(e => {
    this.displayError(e)
    })
        }
      },
      getDateArr(search) {
        this.startDate = (search && search.actionstartdate!=null)?moment(search.actionstartdate): null;//moment(startdate);
        this.endDate = (search && search.actionenddate!=null)?(moment(search.actionenddate)+1): null;//moment(endate);
        let date = [];
        for (var m = moment(this.startDate); m.isBefore(this.endDate); m.add(1, 'days')) {
            date.push(m.format('YYYY-MM-DD'));
        }
        this.dateArr = date;
        this.testCycleIssueReportList();
      },
      dateMONFormat(value){
        if (!value) return "";
        return moment(String(value)).format('DD-MM-YYYY');
      },
      dateMonthFormat(value) {
        if (!value) return "NA";
        return moment(String(value)).format('DD MMM YYYY');
      },
      projectChange: function (node, instanceId){
        if(node && node.length>1){
          this.pageoffset=1
          this.isLoading = true;
          let url = "api/listing/getProjectAllocatedToEmp";
          this.input = {
            projectname:node.toLowerCase(),
            useremail: this.undt.username,
            empid: this.undt.userid,
            empcode: this.undt.userid,
            isfrompage: 'listingpage'
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.projectnameList = result.data.data;
            } else {
              this.projectnameList = [];
            }
          }).catch(e => {
    this.displayError(e)
    })
        }
      },  
      mysearchrequestollapse:function(){
        this.isSearchRequestActive=!this.isSearchRequestActive
      },   
      resetRecords: function() {
        this.search.searchBusinesstaskname = null
        this.search.actionstartdate=null
        this.search.actionenddate= null
        this.search.searchProjectname = null
        this.search.qalead = null
        this.search.qaeng = null
        this.search.testcyclecode =null;
        this.testcyclecodelist =[];
        this.qaleadlist=[];
        this.qaenglist= [];
        this.getDateArr(null);
        this.startDate = null;
        this.endDate = null;
        this.reportissuelist = [];
        this.pageoffset = 1;
        this.page = 1;
      },
      notBeforeToday: function (date) {
        this.search.actionenddate = null;
        return date > new Date(new Date().setHours(0, 0, 0, 0));     
      },
      disabledBefore(date) {
          const oneMonthFromFromDate = moment(this.search.actionstartdate).add(1, 'month');
          const startOfOneMonth = oneMonthFromFromDate.startOf('day').toDate();
          const startOfFromDate = moment(this.search.actionstartdate).startOf('day').toDate();
          return date > startOfOneMonth || date < startOfFromDate;
      },
      getcyclesetcodelist(node){
        if(node && node.length > 4){
          this.input={
            useremail  : this.undt.username,
            empcode: this.undt.userid,
            cyclesetcode: node.toLowerCase()
          }
          axios({
            'method': 'POST',
            'url': 'api/testcycle/getcyclesetcodelist',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {             
            if(result.data.errorCode == 0){
              this.testcyclecodelist = result.data.data;
            } else {
              this.testcyclecodelist = [];
            }
          }).catch(e => {
            this.displayError(e);
          })
        }
      },
      ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";

      if(this.dateArr.length>0){
          this.input = {
            empcode: this.undt.userid,
            useremail: this.undt.username,
            startDate: (this.search.actionstartdate!=null)?this.search.actionstartdate:this.startDate,
            endDate: (this.search.actionenddate!=null)?this.search.actionenddate:this.endDate,
            projectid: this.search.searchProjectname,
            qaeng: this.search.qaeng,
            qalead: this.search.qalead,
            btcid: this.search.searchBusinesstaskname,
            cyclesetid: this.search.testcyclecode
          }
          axios({
              method: "POST",
              url: "api/btc/cycle/testCycleIssueReportList",
              data: this.input,
              headers: { authorization : this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.exportxlsdata = result.data.data.rows;
              var filename = "";
              this.download(this.exportxlsdata, filename)
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }      
          }).catch(e => {
            this.displayError(e)
          })
        }
      },
      download(readExportdata,filename) {
          if (readExportdata.length > 0) {
            var ShowLabel = 'Cycle_wise_Issue_Count_'+filename;

            const groupByCategory = readExportdata.reduce((group, product) => {
              const { cyclesetid } = product;
              group[cyclesetid] = group[cyclesetid] ?? [];
              group[cyclesetid].push(product);
              return group;
            }, {});
            let convertedArr = Object.entries(groupByCategory);
            // let startIndex = (this.pageoffset - 1) * this.pagelimit
            // let endIndex = Math.min(this.pageoffset * this.pagelimit, convertedArr.length);
            // convertedArr = convertedArr.slice(startIndex, endIndex);

            this.dateArr.forEach((date, i) => {
              convertedArr.forEach((edata, i) => {
                convertedArr[i]['all_dates'] = this.dateArr;
                convertedArr[i][date] = (convertedArr[i][1].filter((obj) => obj.createddate == date)).length;
                convertedArr[i][date+'_reopen'] = (convertedArr[i][1].filter((obj) => obj.createddate == date && obj.status == 'reopen')).length;
                if(convertedArr[i]['csum'] == undefined) {
                  convertedArr[i]['csum'] = 0;
                }
                convertedArr[i]['csum'] +=(convertedArr[i][date]+convertedArr[i][date+'_reopen']); 
              });
            });
            var JSONData = convertedArr
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
            if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
              row = row.slice(0, -1)
              CSV = 'Sr#,QA Lead,QA Engineer,Business Task,Cycle Name';
              if(this.dateArr.length>0) {
                this.dateArr.forEach(datee => {
                  CSV += `,${datee},${datee}`
                });
              }
              CSV += ',Total';
              CSV += '\r\n';
              CSV += ' , , , ,';
              if(this.dateArr.length>0) {
                this.dateArr.forEach(datee => {
                  CSV += `,New, Reopen`
                });
              }
              CSV += ',';
              CSV += '\r\n';
            }
            var row = ''
            for (let i=0; i<arrData.length; i++) {
              let index = arrData[i]
              let srno = i+1
              let qalead = index[1][0].hasOwnProperty('qalead') ? index[1][0].qalead : 'NA';
              let businesstaskcode = index[1][0].hasOwnProperty('businesstaskcode') ? index[1][0].businesstaskcode : 'NA';
              let createdby = index[1][0].hasOwnProperty('createdby') ? index[1][0].createdby : 'NA';
              let cyclesetcode = index[1][0].hasOwnProperty('cyclesetcode') ? index[1][0].cyclesetcode : 'NA';
              row += '"' + srno + '",',
              row += '"' + qalead + '",',
              row += '"' + createdby.split('@')[0].replace('.', ' ') + '",',
              row += '"' + businesstaskcode + '",',
              row += '"' + cyclesetcode + '",'
              index['all_dates'].forEach(dateee => {
                let reopentxt = index[dateee+'_reopen'];
                row += '"' + index[dateee] + '",'
                row += '"' + reopentxt + '",'
              });
              row += '"' + index['csum'] + '",'
              row += '\r\n'
            }
            row += ',Total';
            row += ' , , , ,';
            this.rowsSum.forEach((summ,i) => {
              row += '"' + summ + '",',
              row += '"' + this.rowsReopenSum[i] + '",'
            });
            CSV += row
            if (CSV === '') {
              Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              return;
            }
            var fileName = 'TaskListReport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
            var link = document.createElement('a')
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = fileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            Swal.fire({
              title: "Success!",
              text: "Export Report Successful",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });      
        }
      },
      export (data, filename, mime) {
        var data
        let blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        })
        download(blob, filename, mime)
      },
   }
 }
 </script>
 <style> 
 .view_bg-gradient-primary{
   background: #7DA3F9!important;
 }
 </style>