<template>
    <div class="app-content content ">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
        <div class="content-header row">
            <div class="content-header-left col-md-9 col-12 mb-1 ">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                       
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb px-0">
                                <li class="breadcrumb-item"><a href="index.html">Home</a>
                                </li>
                                <li class="breadcrumb-item"><a href="#">Charts &amp; Maps</a>
                                </li>
                                <li class="breadcrumb-item active">Chartjs
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        <div class="content-body">
            <div class="row">
                <div class="col-12">
                    <p>
                        You can easily create reuseable chart components. Read full documentation
                        <a href="https://www.chartjs.org/docs/latest/getting-started/" target="_blank" rel=noopener>here</a>.
                    </p>
                </div>
            </div>
           <div class="container px-0 pb-1 rounded bg-white">
            <div id="ganttChart"></div>
            
            <div id="eventMessage"></div>
           </div>

        </div>

        <div class="container px-0">
          <div class="content-wrapper">
      <div class="row">
        <div class="col-sm-4">
          <div class="card main_articlas_views mb-2 px-1">
            <div class="card-body" id="chartjs-chart">
              <canvas class="doughnut-chart-ex chartjs" data-height="275"></canvas>
              <div class="d-flex justify-content-between mt-3 mb-1">
                                        <div class="d-flex align-items-center">
                                            <i data-feather="monitor" class="font-medium-2 text-primary"></i>
                                            <span class="font-weight-bold ml-75 mr-25">Desktop</span>
                                            <span>- 80%</span>
                                        </div>
                                        <div>
                                            <span>2%</span>
                                            <i data-feather="arrow-up" class="text-success"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1">
                                        <div class="d-flex align-items-center">
                                            <i data-feather="tablet" class="font-medium-2 text-warning"></i>
                                            <span class="font-weight-bold ml-75 mr-25">Mobile</span>
                                            <span>- 10%</span>
                                        </div>
                                        <div>
                                            <span>8%</span>
                                            <i data-feather="arrow-up" class="text-success"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <i data-feather="tablet" class="font-medium-2 text-success"></i>
                                            <span class="font-weight-bold ml-75 mr-25">Tablet</span>
                                            <span>- 10%</span>
                                        </div>
                                        <div>
                                            <span>-5%</span>
                                            <i data-feather="arrow-down" class="text-danger"></i>
                                        </div>
                                    </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <div class="card main_articlas_views mb-2">
                              <div class="card-body">
                                <canvas class="bar-chart-ex chartjs" data-height="400"></canvas>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                        <div class="col-12">
                            <div class="card py-1">
                                <div class="card-header px-2">
                                    <div>
                                        <h4 class="card-title">Statistics</h4>
                                        <span class="card-subtitle text-muted">Commercial networks and enterprises</span>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <canvas class="line-chart-ex chartjs" data-height="450"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Line Chart Ends-->
                        <div class="row">
                          <div class="col-sm-12">
                              <div class="card py-0">
                                <div class="card-body" id="apexchart">
                                    <div id="column-chart"></div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                              <div class="card py-2">
                                <div class="card-header px-2 d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                                    <div>
                                        <h4 class="card-title mb-25">Balance</h4>
                                        <span class="card-subtitle text-muted">Commercial networks & enterprises</span>
                                    </div>
                                    <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
                                        <h5 class="font-weight-bolder mb-0 mr-1">$ 100,000</h5>
                                        <div class="badge badge-light-secondary">
                                            <i class="text-danger font-small-3" data-feather="arrow-down"></i>
                                            <span class="align-middle">20%</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div id="line-chart"></div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                               <div class="card py-2">
                                <div class="card-header px-2 flex-column align-items-start">
                                    <h4 class="card-title mb-75">Expense Ratio</h4>
                                </div>
                                <div class="card-body">
                                    <div id="donut-chart"></div>
                                </div>
                               </div>
                            </div>
                            <div class="col-sm-6">
                               <div class="card py-2">
                                <div class="card-header px-2 flex-column align-items-start">
                                    <h4 class="card-title mb-75">Expense Ratio</h4>
                                </div>
                                <div class="card-body">
                                    <div id="radialbar-chart"></div>
                                </div>
                               </div>
                            </div>
                        </div>

                      </div>
                    </div>
    </div>
    </div>
                
</template>
<script>
import jqueryGanttView from '../../../public/css/jquery.ganttView.css'

import ganntDate from '../../../public/js/ganttDate.js'
import ganttViewjs from '../../../public/js/jquery.ganttView.js'

export default {
  name: "GanttChart",

  components: {
	jqueryGanttView,
	ganntDate,
	ganttViewjs,
	
	
   
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    var ganttData = [
	{
		id: 1, name: "Feature 1", series: [
			{ name: "Planned", start: new Date(2010,0,1), end: new Date(2010,0,3) },
			{ name: "Actual", start: new Date(2010,0,2), end: new Date(2010,0,5), color: "#ff9522" }
		]
	}, 
	{
		id: 2, name: "Feature 2", series: [
			{ name: "Planned", start: new Date(2010,0,5), end: new Date(2010,0,20) },
			{ name: "Actual", start: new Date(2010,0,6), end: new Date(2010,0,17), color: "#ff9522" },
			{ name: "Projected", start: new Date(2010,0,6), end: new Date(2010,0,17), color: "#82868b" }
		]
	}, 
	{
		id: 3, name: "Feature 3", series: [
			{ name: "Planned", start: new Date(2010,0,11), end: new Date(2010,1,3) },
			{ name: "Actual", start: new Date(2010,0,15), end: new Date(2010,1,3), color: "#ff9522" }
		]
	}, 
	{
		id: 4, name: "Feature 4", series: [
			{ name: "Planned", start: new Date(2010,1,1), end: new Date(2010,1,3) },
			{ name: "Actual", start: new Date(2010,1,1), end: new Date(2010,1,5), color: "#ff9522" }
		]
	},
	{
		id: 5, name: "Feature 5", series: [
			{ name: "Planned", start: new Date(2010,2,1), end: new Date(2010,2,6) },
			{ name: "Actual", start: new Date(2010,2,1), end: new Date(2010,2,15), color: "#ff9522" }
		]
	}, 
	{
		id: 6, name: "Feature 6", series: [
			{ name: "Planned", start: new Date(2010,0,5), end: new Date(2010,0,20) },
			{ name: "Actual", start: new Date(2010,0,6), end: new Date(2010,0,17), color: "#ff9522" },
			{ name: "Projected", start: new Date(2010,0,6), end: new Date(2010,0,20), color: "#82868b" }
		]
	}, 
	{
		id: 7, name: "Feature 7", series: [
			{ name: "Planned", start: new Date(2010,0,11), end: new Date(2010,1,3) }
		]
	}, 
	{
		id: 8, name: "Feature 8", series: [
			{ name: "Planned", start: new Date(2010,1,1), end: new Date(2010,1,3) },
			{ name: "Actual", start: new Date(2010,1,1), end: new Date(2010,1,5), color: "#ff9522" }
		]
	}
];
$("#ganttChart").ganttView({ 
    data: ganttData,
    slideWidth: 900,
    behavior: {
      onClick: function (data) { 
        var msg = "You clicked on an event: { start: " + data.start.toString("M/d/yyyy") + ", end: " + data.end.toString("M/d/yyyy") + " }";
        $("#eventMessage").text(msg);
      },
      onResize: function (data) { 
        var msg = "You resized an event: { start: " + data.start.toString("M/d/yyyy") + ", end: " + data.end.toString("M/d/yyyy") + " }";
        $("#eventMessage").text(msg);
      },
      onDrag: function (data) { 
        var msg = "You dragged an event: { start: " + data.start.toString("M/d/yyyy") + ", end: " + data.end.toString("M/d/yyyy") + " }";
        $("#eventMessage").text(msg);
      }
    }
  });
    
  },
  methods: {
    
  },
};
</script>

<style scoped>
  
</style>
