<template>
    <div class="app-content content">
        <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"  color="orange"  loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Release Planner Details</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">Release Planner List</li>
                        </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning filter_card_searchbgst">
                    <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                        <div class="container-fluid">
                            <div class="row row fc_my-05">
                                <div class="w-auto px-1 mobile-padding-0-2 d-flex align-items-center">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search Release Planner Details</h4>
                                </div>
                                <div class="ml-auto d-flex mr-md-2">
                                <div class="ml-auto px-1 mobile-padding-0-2" >
                                    <div class="input-group-append float-right">
                                    <router-link to="/createreleasenote"><button id="commonaddmodule" class="btn btn-sm btn-relief-success" data-backdrop="static" data-keyboard="false"> <span class="mobile_hide_bt"> Create Release Planner</span> <plus-circle-icon size="1.5x" class="custom-class"></plus-circle-icon> </button></router-link>
                                    </div>
                                </div>
                                <span class="rotetions_view_arrow"> 
                                    <em class="las la-angle-down"></em>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isRequestActive},card_content,collapse]">
                        <div class="card-body px-sm-mobile-10">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Release Code</label>
                                    <input type="text" class="form-control capitalisetext" placeholder="Enter release code"  v-model="search.releasecode" v-on:search-change="releaseCodeChangeSearch" @input="clearCodeList" :maxlength="maxLength"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Project Name</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.project" :multiple="true" :options="search.projectlist"  @input="selissuecsproject"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Engineering Manager</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Engineering Manager" v-model="search.approvedby" :multiple="true" :options="approvalmanager"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Project Manager</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Project Manager" v-model="search.pmname" :multiple="true" :options="pmlist"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">QA Lead</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select QA Lead" v-model="search.qaname" :multiple="true" :options="qalist"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Business Analyst</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Business Analyst" v-model="search.baname" :multiple="true" :options="balist"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                                    <div class="">
                                        <label for="projectmdllistdd">Status</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" v-model="search.status" :multiple="true" :options="statuslist"/>
                                    </div>
                                </div>
                                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-0">
                                    <label for="">Release Date From</label>
                                    <date-picker placeholder="Select Release Date From" v-model="search.createddatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                                    <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                                </div>
                                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-0">
                                    <label for="">Release Date To</label>
                                    <date-picker placeholder="Select Release Date To" v-model="search.createddateto" :disabled-date="disabledBefore" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 text-right mb-0">
                                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getreleasenotelist(search,'',1)"> 
                                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                                <button 
                                type="move" 
                                class="btn btn-relief-primary mt-2" 
                                @click="ExportXL()"
                                > <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                                </button>
                                </div>
                    

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="approvedRNRequest" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title">Approve Release Note Request</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closed()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body sm-overflow_scroll_div">
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="form-label">Release Note Code</label>
                                    <input class="form-control" type="text" v-bind:value="approavalData.rnotecode" disabled/>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label">Release Note Version</label>
                                    <input class="form-control" type="text" v-bind:value="approavalData.rnversion" disabled/>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label">Release Note Created By</label>
                                    <input class="form-control" type="text" v-bind:value="approavalData.createdby" disabled/>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="form-label">Release Note Created Date</label>
                                    <input class="form-control" type="text" v-bind:value="dateFormat(approavalData.createddate)" disabled/>
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="form-label">Comment</label>
                                    <textarea class="form-control" type="text" v-model="approavalData.comment" placeholder="Write comment here..."></textarea>
                                    <div class="errorinputmsg" v-if="approavalData.commentErr">Comment is required.</div> 
                                </div>
                                <div class="form-group col-md-12 text-right">
                                    <button type="button" id="approvebt" class="btn btn-primary mr-1" v-on:click="approvedRN(approavalData, 'Approved')">Approved</button>
                                    <button type="button" id="rejectbtn" class="btn btn-danger" v-on:click="approvedRN(approavalData, 'Rejected')">Reject</button>
                                </div>
                                
                            </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 taskmaster_tabbing_das022">
                        <ul class="listGrid float-right d-inline-flex px-sm-mobile-10 taskmaster_tabbing_das03">
                            <li class="cursorpointercls"  @click="myListView()" >
                                <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                                    <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                                    <span>
                                        List View
                                    </span>
                                </button>
                            </li>
                            <li class="cursorpointercls" @click="chartShowView()"> 
                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:chartactivelistlink}]" title="List View" data-original-title="List View">
                                <em class="las la-chart-pie custom-class mr-0 avatar-icon font-medium-1"></em>
                                <span>
                                    Chart
                                </span>
                            </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class=""  style="margin-top: 1rem; margin-bottom: 1rem !important;">
                    <div class="show card-content collapse">
                        <div v-bind:class="[{show: isListDetActive},card_content,collapse]">
                            <div class="row">
                                <div class="col-md-12 1">
                                    <div class="card ">
                                        <div class="">
                                            <div class="tab-content" id="myTabContent">
                                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div class="row" id="table-small">
                                                <div class="col-12">
                                                    <div class="card">
                                                    <div class="table-responsive datatableview" >
                                                        <table  class="table all_table_stickys" aria-describedby="Release Note List">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="text-center align-middle first-col-sticky" style="min-width:45px;">#</th>
                                                                <th scope="col" class="text-left align-middle second-col-sticky" style="min-width:160px;">Release Code</th>
                                                                <th scope="col" class="text-center align-middle" style="min-width:140px;">Project Name</th>
                                                                <th scope="col" class="text-center align-middle">Version</th>
                                                                <th scope="col" class="text-left align-middle">Created Details</th>
                                                                <th scope="col" class="text-center align-middle">Approval Status</th>
                                                                <th scope="col" class="text-left align-middle">Approved By</th>
                                                                <th scope="col" class="text-center align-middle">Deployement Status</th>
                                                                <th scope="col" class="text-left align-middle" style="min-width:140px;">Deployed Details</th>
                                                                <th scope="col" class="text-center align-middle">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(data,index) in relesenotelist"> 
                                                                <tr class="release_notemerge_font">
                                                                    <td data-column="#" class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                    <td data-column="Release Code" class="rolelistdd second-col-sticky" >
                                                                        <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">{{data.releasenotecode}}</span>
                                                                    </td>
                                                                    <td data-column="Project Name" class="font-weight capitalisetext cursorpointercls sitebluecolor"  @click="redirecttourl('projectID',data)">{{data.projectname}}</td>
                                                                    <td data-column="Version" class="text-center text-capitalize">{{data.version}}</td>
                                                                    <td data-column="Created Details" class="text-left text-capitalize">
                                                                        {{data.createdby.split('@')[0]}}
                                                                        <hr class="release_hr_tag_tab"/>
                                                                        <span class="release_hr_tag_span">  Date :</span>
                                                                        {{dateFormat(data.createddate)}}
                                                                    </td>
                                                                    <td data-column="Approval Status" class="text-center text-capitalize">{{data.releasestatus}}</td>
                                                                    <td data-column="Approved By" class="text-left text-capitalize">
                                                                        {{ (data.apprvedbyemail &&  data.releasestatus != 'Pending') ? data.apprvedbyemail.split('@')[0] : 'NA'}}
                                                                        <hr class="release_hr_tag_tab"/>
                                                                        <span class="release_hr_tag_span">  Date :</span>
                                                                        {{dateFormat(data.approveddate) ? dateFormat(data.approveddate):'NA'}}
                                                                    </td>
                                                                    <td data-column="Deployement Status" class="text-center text-capitalize">{{data.assignstatus? data.assignstatus : 'NA'}}</td>
                                                                    <td data-column="Deployed Details" class="text-left text-capitalize">
                                                                        {{data.releasedby ? data.releasedby.split('@')[0]:'NA'}}
                                                                        <hr class="release_hr_tag_tab"/>
                                                                        <span class="release_hr_tag_span">  Date :</span>
                                                                        {{dateFormat(data.releasedate) ? dateFormat(data.releasedate):'NA'}}
                                                                    
                                                                    </td>
                                                                    <td data-column="Action" class="clsaction" >
                                                                        <div class="dropdown">
                                                                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                            </button>
                                                                            <div class="dropdown-menu">
                                                                                <a class="dropdown-item devEditEle" v-if="(loginuseremail == data.createdby || data.apprvedbyemail == loginuseremail) && ['To Do', 'In Progress'].includes(data.releasestatus)"  @click="redirecttourl('edit', data)" style="cursor: pointer;" href="javascript:void(0);">
                                                                                <div class="d-flex align-items-center">
                                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                    <span>Edit</span>
                                                                                </div>
                                                                                </a>
                                                                                <a @click="redirecttourl('view',data)" href="javascript:void(0);" class="dropdown-item devEditEle">
                                                                                <div class="d-flex align-items-center"> 
                                                                                    <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                                                                    <span>View</span>
                                                                                </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>  
                                                            </template>
                                                            <template v-if="relesenotelist.length==0">
                                                                <tr><td colspan="10" style="text-align: center;">No record found</td></tr>
                                                            </template>
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="pageCenter text-center mt-4">
                                                <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-bind:class="[{show: ischartDetailsActives},card_content,collapse]">
                            <div class="row">
                            <!-- Donut Chart Start-->
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <div class="card py-2">
                                <div class="card-header px-2 flex-column align-items-start">
                                    <h4 class="card-title mb-75">NPS Distribution Donut-Chart: </h4>
                                </div>
                                <div class="card-body pb-0">
                                    <div class="chartpiechartviews">
                                    <div id="releasedonutchart"></div>
                                    </div>
                                    <div class="text-center">
                                        <h4 class="mt-1">Net Promoter Score (NPS): {{ (totalNPS ==0 ||totalNPS ==null || totalNPS =='') ? 0 : totalNPS.toFixed(2) }}</h4>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-8 col-lg-8">
                                <div class="card py-1">
                                    <div class="card-header px-2 pb-0">
                                        <div class="card-header px-0 flex-column align-items-start">
                                            <h4 class="card-title mb-75">Satisfaction Trends Line Graph:</h4>
                                        </div>
                                        
                                    </div>
                                    <div class="card-body">
                                        <div class="chartpiechartviews">
                                        <canvas class="releaselinechart chartjs" data-height="450"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Donut Chart End-->
                            <!-- Radialbar Chart Start-->
                            <!-- <div class="col-sm-6">
                                <div class="card py-2">
                                <div class="card-header px-2 flex-column align-items-start">
                                    <h4 class="card-title mb-75">Expense Ratio</h4>
                                </div>
                                <div class="card-body">
                                    <div id="radialbar-chart"></div>
                                </div>
                                </div>
                            </div> -->
                            <!-- Radialbar Chart End-->
                            </div>

                            <!-- Line Chart Start-->
                            <div class="row">
                            
                            </div>
                            <!-- Line Chart Ends-->

                            <div class="row">
                            <!-- Pie Chart Start-->
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                <div class="card main_articlas_views mb-2">
                                <div class="card-body" id="chartjs-chart">
                                    <div class="card mx-1">
                                    <div class="card-header">
                                        <div class="w-auto px-0 mobile_margin-px-0_00 mobile_w_calc_38" style="padding: 5px 0px;">
                                        <h4 class="card-title d-inline-flex  mobile_width-86">Feature Value And Innovation Pie Chart: </h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="chartpiechartviews">
                                        <canvas class="piechartmaster chartjs" data-height="275" id="piechartmasterid"></canvas>
                                        </div>
                                        <div v-for="(data1, idx) in piedata" v-bind:key="idx">
                                        <div class="d-flex justify-content-between mt-1 mb-1 chartpiechartcolor14">
                                            <div class="d-flex align-items-center">
                                            <em class="las la-stop font-medium-1" :style="{color: data1.colour,background: data1.colour}"></em>
                                            <span class="font-weight-normal ml-75 mr-25">{{ data1.label }}</span>
                                            </div>
                                            <div>
                                            <span>{{ data1.value ? data1.value : '0' }}</span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!-- Pie Chart Ends-->
                            <!-- Bar Chart Start-->
                            <div class="col-12 col-sm-6 col-md-8 col-lg-8">
                                <div class="card main_articlas_views mb-2">
                                <div class="card-body mx-1">
                                    <div class="card-header">
                                        <div class="w-auto px-0 mobile_margin-px-0_00 mobile_w_calc_38" style="padding: 5px 0px;">
                                            <h4 class="card-title d-inline-flex  mobile_width-86">Usability Trends Bar Chart:</h4>
                                        </div>
                                    </div>
                                    <div class="chartpiechartviews">
                                        <canvas class="barchartmaster chartjs" id="barchartmasterid" data-height="400"></canvas>
                                        <div class="releasenoteraitingview">
                                        <div class="releasenoteraitingview_sub" v-for="(data1, idx) in bardata" v-bind:key="idx">
                                            <div class="d-flex align-items-center">
                                              
                                            <em class="las la-stop font-medium-1" :style="{color: data1.colour,background: data1.colour}"></em>
                                            <span class="font-weight-normal ml-75 mr-25">{{ data1.label }}</span>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!-- Bar Chart End-->
                            </div>
                        
                            <!-- Column Chart Start-->
                            <!-- <div class="row">
                            <div class="col-sm-12">
                                <div class="card py-0">
                                    <div class="card-body" id="apexchart">
                                        <div id="columnbarchart"></div>
                                    </div>
                                </div>
                            </div>
                            </div> -->
                            <!-- Column Chart End-->

                            <!-- <div class="row">
                            <div class="col-sm-12">
                                <div class="card py-2">
                                    <div class="card-header px-2 d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                                        <div>
                                            <h4 class="card-title mb-25">Balance</h4>
                                            <span class="card-subtitle text-muted">Commercial networks & enterprises</span>
                                        </div>
                                        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
                                            <h5 class="font-weight-bolder mb-0 mr-1">$ 100,000</h5>
                                            <div class="badge badge-light-secondary">
                                                <i class="text-danger font-small-3" data-feather="arrow-down"></i>
                                                <span class="align-middle">20%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div id="line-chart"></div>
                                    </div>
                                </div>
                            </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import apiUrl from '../../constants';
import moment from 'moment'
import Loading from "vue-loading-overlay";
import Pagination from 'vue-pagination-2';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {BellIcon,InfoIcon,BookIcon,LinkIcon, ExternalLinkIcon,MessageSquareIcon,EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon } from 'vue-feather-icons'
import DatePicker from "vue2-datepicker";
import commonMethods from '../../utils/commonMethods';
export default {
    name: "ListReleaseNote",
    components: {
        Loading,Treeselect,DatePicker,ExternalLinkIcon,
        BellIcon,InfoIcon,BookIcon,LinkIcon, MessageSquareIcon,EditIcon, EyeIcon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon,ListIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon,
        Pagination
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            islistLoading:false,
            listfullPage:true,
            isRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            tokendata:null,
            undt:[],
            isBothDateSelected:false,
            statuslist:[],
            search:{
                project:null,
                projectlist:[],
                reponame:null,
                jenkinsjob:null,
                pmname:null,
                qaname:null,
                baname:null,
                approvedby:null,
                createddateto:null,
                createddatefrom:null,
                status:null,
                releasecode: null,
                releasecodelist:null
            },
            approvalmanager:[],
            pmlist:[],
            qalist:[],
            balist:[],
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            relesenotelist: [],
            maxLength:15,
            loginusername:'',
            loginuseremail:'',
            loginuserid: null,
            approavalData : {
                rnotecode : null,
                rnversion: null,
                createdby: null,
                createddate: null,
                rid: null,
                comment: null,
                commentErr: null,
            },
            isListDetActive: true,
            activelistlink: false,
            chartactivelistlink: false,
            ischartDetailsActives: false,
            linechartdata: [],
            piedata: [],
            donutchartlist: [],
            linechartlist: [],
            piechartlist: [],
            barchartlist: [],
            bardata: [],
            colourarray: ['#698ED0','#ED7D31','#A5A5A5','#FFC000','#5B9BD5','#70AD47','#264478','#9E480E','#636363','#997300','#255E91','#43682B','#826bf8','#F1975A','#B7B7B7','#FFCD33'],
            donutchartcolour: ['#ffe700', '#FFA1A1', '#826bf8', '#00d4bd', '#2b9bf4', '#7eefc7', '#06774f', '#a4f8cd'],
            totalNPS: 0,
            barChartExample2: '',
            piechartmaster: '',
            linemaster: '',
            donutChartmaster: ''
        }
    },
    mounted() {
        let userdata = window.localStorage.getItem("userdata");
        if (userdata) {
            userdata = JSON.parse(userdata);
            this.undt=userdata
            this.loginuserid = userdata.userid
            this.tokendata = window.localStorage.getItem('token');  
            this.loginusername= userdata.firstname+' '+userdata.lastname
            this.loginuseremail = userdata.emailaddress;
            this.getProjectAllocatedToEmp()
            
            if(this.$route.params && this.$route.params.search){
                let search = JSON.parse(localStorage.getItem('releasenotesearchItems'))
                if(search) {
                    this.getSearchItems(search);
                    this.getreleasenotelist(search, "", 1);     
                }else{
                    localStorage.removeItem('releasenotesearchItems');
                    this.getreleasenotelist();
                }
            } else if (this.$route.params.backbtnstate == "ReleaseNote") {
                let search = {
                    project: [this.$route.params.pid],
                    status: [this.$route.params.clickeddata],
                }
                this.getSearchItems(search);
            } else {
              localStorage.removeItem('releasenotesearchItems');
              let rowdata={}
              this.getreleasenotelist(rowdata);
            }
            this.getTrackerNameById();             
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
    });
    },
    methods:{
        getTrackerNameById() {
            this.inputfield= {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                tname: 'Release Planner'
            }
            axios({
                'method': 'POST',
                'url': 'api/tracker/getTrackerNameById',
                'data': this.inputfield,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status == true){
                    let id = result.data.data.rows[0].id;
                    this.getfieldallstatus(id);
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getfieldallstatus(tid){
            this.isLoading = true;
            this.inputfield= {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                trackername: tid
            }
            axios({
                'method': 'POST',
                'url': 'api/fieldtracker/getfieldallstatus',
                'data': this.inputfield,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;
                if(result.data.status == true){
                    this.statuslist = result.data.data.rows
                }else{
                    this.statuslist = []
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        reqToApprovedRNote(rdata) {
            this.approavalData.rnotecode = rdata.releasenotecode;
            this.approavalData.rnversion = rdata.version;
            this.approavalData.createdby = rdata.createdby;
            this.approavalData.createddate = rdata.createddate;
            this.approavalData.rid = rdata.releasenotemasterid;
            this.approavalData.approvedby = rdata.approvedby;
            $('#approvedRNRequest').modal('show');
        },
        closed(){
            $('#approvedRNRequest').modal('hide');
            this.approavalData.rnotecode = null;
            this.approavalData.rnversion = null;
            this.approavalData.createdby = null;
            this.approavalData.createddate = null;
            this.approavalData.rid = null;
            this.approavalData.comment = null;
            this.approavalData.commentErr = null;
            this.approavalData.approvedby = null;
        },
        approvedRN(approveddata, status) {
            let isvalid = true;
            if(approveddata.comment == '' || approveddata.comment == null){
                this.approavalData.commentErr = 'Comment is required.';
                isvalid = false;
            }
            if(isvalid){
                axios({
                    method: "POST",
                    url: "api/releasenote/approvedRNRequest",
                    data: { rid: parseInt(approveddata.rid), comment: approveddata.comment, status: status, empcode: this.undt.userid, useremail: this.undt.username },
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                    $('#approvedRNRequest').modal('hide');
                    this.approavalData = {};
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                        })
                        this.getreleasenotelist();
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                }).catch(e => {
                this.displayError(e)
                });
            }
        },
        notBeforeToday: function (date) {
            this.search.createddateto = "";
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        selectedfromdate(){
            if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
                this.isBothDateSelected = false
            }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
                this.isBothDateSelected = true
            }
        },
        selectedtodate(){
            if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
                this.isBothDateSelected = false
            }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
                this.isBothDateSelected = true
            }
        },
        disabledBefore(date) {
            let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; 
        },
        mysearchrequestollapse:function(){
            this.isRequestActive=!this.isRequestActive
        },
        selissuecsproject: function(state,value){
            this.search.project=null
            this.search.project=state
            if (!state) {
                this.search.approvedby = null
                this.approvalmanager = []
            }else{
                this.getEngManByPro(this.search.project, [apiUrl.EMroleid]);
                this.getEngManByPro(this.search.project, [apiUrl.PMroleid]);
                this.getEngManByPro(this.search.project, [apiUrl.BAroleid]);
                this.getEngManByPro(this.search.project, [apiUrl.QAroleid]);
            }
        },
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            isfrompage: 'listingpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.search.projectlist = result.data.data;
                    localStorage.setItem('releasenoteprojectlist', JSON.stringify(result.data.data))

                } else {
                    this.search.projectlist = [];
                    localStorage.removeItem('releasenoteprojectlist')

                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getEngManByPro(proid, roleid) {
            axios({
                method: "POST",
                url: "api/releasenote/getEMforreleasenote",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,projectidarr:proid,useremail: this.undt.username,empcode: this.undt.userid}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==apiUrl.EMroleid){
                    this.approvalmanager = result.data.data.rows
                    }else if(roleid==apiUrl.PMroleid) {
                        this.pmlist = result.data.data.rows;
                    }else if(roleid==apiUrl.QAroleid) {
                        this.qalist = result.data.data.rows;
                    }else if(roleid==apiUrl.BAroleid) {
                        this.balist = result.data.data.rows;
                    }
                }else{
                    if(roleid==apiUrl.EMroleid) {
                        this.approvalmanager = [];
                    }else if(roleid==apiUrl.PMroleid) {
                        this.pmlist = [];
                    }else if(roleid==apiUrl.QAroleid) {
                        this.qalist = [];
                    }else if(roleid==apiUrl.BAroleid) {
                        this.balist = [];
                    } 
                }
            }).catch(e => {
            this.displayError(e)
            });
        },
        clearCodeList(state) {
            if(!state) {
            this.search.releasecodelist = null
            }
        },
        releaseCodeChangeSearch(node) {
            if(node && node.length>1){
            axios({
                'method': 'POST',
                'url': 'api/releasenote/getreleasecodelist',
                'data': {
                releasecode: node.toUpperCase(),
                empid: this.undt.userid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                },
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                this.search.releasecodelist=result.data.data
                localStorage.setItem('releasecodelist', JSON.stringify(result.data.data))
                } else {
                this.serch.releasecodelist= null
                localStorage.removeItem('releasecodelist')
                }
            }).catch(e => {
                this.displayError(e)
             })
            }
        },
        getreleasenotelist(objThis,uvar,flag){
            if(objThis) {
                localStorage.setItem('releasenotesearchItems', JSON.stringify(objThis))
            }
            this.islistLoading = true
            let url = "api/releasenote/getReleaseNoteList";
            this.input = {
            offset:this.pageoffset,
            limit:apiUrl.LIMIT,
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empid: this.undt.userid
            };
            let search = JSON.parse(localStorage.getItem('releasenotesearchItems'))
            if(search) {
                this.input.releasecode = this.search.releasecode,
                this.input.project = this.search.project,
                this.input.approvedby = (this.search.approvedby),
                this.input.pmname = (this.search.pmname),
                this.input.qaname =(this.search.qaname),
                this.input.baname =(this.search.baname),
                this.input.status = this.search.status
                let assignedate=''
                if(this.search.createddateto){
                    assignedate = new Date(this.search.createddateto)
                    assignedate.setDate(assignedate.getDate() + 1)
                    moment(String(assignedate)).format('YYYY-MM-DD');
                }
                this.input.datefrom = this.search.createddatefrom?this.search.createddatefrom:null
                this.input.dateto = this.search.createddateto?assignedate:null
            }
            if(flag == 1) {
                this.page = 1;
                this.pageoffset = 1;
            }
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.islistLoading = false;
                if (result.data.errorCode == 0) {
                    this.relesenotelist = result.data.data.rows;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    this.relesenotelist = [];
                    this.totalcount = 0
                    this.pageCount = 0
                }
                if(this.ischartDetailsActives ==true && this.chartactivelistlink ==true){
                    this.getnpsdistributiondata();
                    this.getdataforlinegraph();
                    this.getpiechartdata();
                    this.getbarchartdata();
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        resetRecords: function(){
            this.search.releasecode = null;
            this.search.project=null;
            this.search.approvedby=null;
            this.search.pmname=null;
            this.search.qaname=null;
            this.search.baname=null;
            this.search.createddateto=null;
            this.search.createddatefrom=null;
            this.search.status=null;
            localStorage.removeItem('releasenotesearchItems');
            localStorage.removeItem('releasenoteprojectlist');
            this.getreleasenotelist();
            this.pageoffset = 1
            this.page = 1;
            this.isListDetActive = true;
            this.activelistlink = true;
            this.ischartDetailsActives=false;
            this.chartactivelistlink = false;
            localStorage.setItem('activebtctab', 'listview');
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            this.getreleasenotelist(this.search);     
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#myTabContent").offset().top -70
                });
            }, 500);
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateMONFormat(value){
        if (!value) return "";
          return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate=="edit"){
                this.$router.push({ 
                path: `/releasenote/update?releasenoteid=${rowdata.releasenotemasterid}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            }
            if(btnstate=="view"){
                this.$router.push({ 
                path: `/releasenote/view?releasenoteid=${rowdata.releasenotemasterid}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            }  else if (btnstate == 'projectID') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid
                })
            }
        },
        getSearchItems(search) {
            this.isRequestActive = true;
            this.search.releasecode = search.releasecode
            this.search.projectlist = JSON.parse(localStorage.getItem('releasenoteprojectlist'))
            this.search.project = search.project
            this.search.approvedby = search.approvedby
            this.search.status = search.status
            this.search.createddatefrom = search.createddatefrom
            this.search.createddateto = search.createddateto
            this.getreleasenotelist(this.search, "", 1);
        },
        myListView: function () {
            this.isListDetActive = true;
            this.activelistlink = true;
            this.chartactivelistlink = false;
            this.ischartDetailsActives = false;
            this.getreleasenotelist();
            localStorage.setItem('activebtctab', 'listview');
        },
        chartShowView(){
            if(this.search.createddatefrom && this.search.createddateto){
                this.isListDetActive = false;
                this.activelistlink = false;
                this.ischartDetailsActives=true;
                this.chartactivelistlink = true;
                localStorage.setItem('activebtctab', 'chartview');
                this.getnpsdistributiondata();
                this.getdataforlinegraph();
                this.getpiechartdata();
                this.getbarchartdata();
            }else{
                Swal.fire({
                    title: "Failed",
                    text: "Release Date From And Release Date To Are Required",
                    icon: 'info',
                    customClass: {
                    confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
        },
        getnpsdistributiondata(){
            this.isLoading = true;
            this.input={
                useremail: this.undt.username,
                empcode: this.undt.userid,
                charttype: 'donutchar',
            }
            if(this.search.releasecode && this.search.releasecode !=null){
                this.input.releasenotecode = this.search.releasecode;
            }
            if(this.search.project && this.search.project.length > 0){
                this.input.projectid = this.search.project;
            }
            if((this.search.createddatefrom && this.search.createddatefrom !=null && this.search.createddatefrom !='') && (this.search.createddateto && this.search.createddateto !=null && this.search.createddateto !='')){
                this.input.createddatefrom = this.search.createddatefrom;
                let xcreateddateto='';
                if(this.search.createddateto){
                    xcreateddateto = new Date(this.search.createddateto);
                    xcreateddateto.setDate(xcreateddateto.getDate() + 1);
                    moment(String(xcreateddateto)).format('YYYY-MM-DD');
                }
                this.input.createddateto = this.search.createddateto ? xcreateddateto:null;
            }
            axios({
                method: "POST",
                url: "api/releasenote/getnpsdistributiondata",
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                let datsx = [
                    {id:1,label: 'Promoters',value:0},
                    {id:2,label: 'Passives',value:0},
                    {id:3,label: 'Detractors',value:0},
                ]
                if (result.data.errorCode == 0) {
                    this.donutchartlist = result.data.data;
                    if(this.donutchartlist && this.donutchartlist.length > 0){
                        let promoters =0;
                        let passives =0;
                        let detractors =0;
                        for(let i=0; i<this.donutchartlist.length; i++){
                            if(this.donutchartlist[i].fieldvalue >=9 && this.donutchartlist[i].fieldvalue <=10){
                                promoters = promoters +1;
                            }
                            if(this.donutchartlist[i].fieldvalue >=7 && this.donutchartlist[i].fieldvalue <=8){
                                passives = passives +1;
                            }
                            if(this.donutchartlist[i].fieldvalue >=0 && this.donutchartlist[i].fieldvalue <=6){
                                detractors = detractors +1;
                            }
                        }
                        datsx[0].value = promoters;
                        datsx[1].value = passives;
                        datsx[2].value = detractors;
                        // calculate the NPS:
                        this.totalNPS = (promoters/100)*100 - (detractors/100)*100
                    }
                    if(datsx && datsx.length > 0){
                        this.drawdonutcharts(datsx);
                    }
                } else {
                    this.donutchartlist = [];
                    this.totalNPS =0;
                    this.drawdonutcharts(datsx);
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        drawdonutcharts(datass) {
            let divelement = document.querySelector('#releasedonutchart');
            if (divelement) {
                divelement.innerHTML = '';
            }
            if (datass && datass.length >0) {
                let chartlabels = [];
                let chartlabelsvalue = [];  
                
                for (let i = 0; i < datass.length; i++) {
                chartlabels.push(datass[i].label);
                chartlabelsvalue.push(datass[i].value ? parseInt(datass[i].value) :0);
                }

                this.donutChartmaster = document.querySelector('#releasedonutchart');
                let donutChartConfig = {
                chart: {
                    height: 350,
                    type: 'donut',
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                labels: chartlabels,
                series: chartlabelsvalue,
                colors: this.donutchartcolour,
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opt) {
                    return parseInt(val) + '%';
                    },
                },
                plotOptions: {
                    pie: {
                    donut: {
                        labels: {
                        show: true,
                        name: {
                            fontSize: '2rem',
                            fontFamily: 'Inter',
                        },
                        value: {
                            fontSize: '1rem',
                            fontFamily: 'Inter',
                            formatter: function (val) {
                            return parseInt(val) + '%';
                            },
                        },
                        total: {
                            show: true,
                            fontSize: '1.5rem',
                            label: chartlabels[0],
                            formatter: function () {
                            return chartlabelsvalue[0]+'%';
                            },
                        },
                        },
                    },
                    },
                },
                responsive: [
                    {
                    breakpoint: 992,
                    options: {
                        chart: {
                        height: 380,
                        },
                    },
                    },
                    {
                    breakpoint: 576,
                    options: {
                        chart: {
                        height: 320,
                        },
                        plotOptions: {
                        pie: {
                            donut: {
                            labels: {
                                show: true,
                                name: {
                                fontSize: '1.5rem',
                                },
                                value: {
                                fontSize: '1rem',
                                },
                                total: {
                                fontSize: '1.5rem',
                                },
                            },
                            },
                        },
                        },
                    },
                    },
                ],
                };
                if (this.donutChartmaster !== null) {
                let donutChart = new ApexCharts(this.donutChartmaster, donutChartConfig);
                donutChart.render();
                }
            }
        },
        getdataforlinegraph(){
            this.isLoading = true;
            this.input={
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            if(this.search.releasecode && this.search.releasecode !=null){
                this.input.releasenotecode = this.search.releasecode;
            }
            if(this.search.project && this.search.project.length > 0){
                this.input.projectid = this.search.project;
            }
            if((this.search.createddatefrom && this.search.createddatefrom !=null && this.search.createddatefrom !='') && (this.search.createddateto && this.search.createddateto !=null && this.search.createddateto !='')){
                this.input.createddatefrom = this.search.createddatefrom;
                let xcreateddateto='';
                if(this.search.createddateto){
                    xcreateddateto = new Date(this.search.createddateto);
                    xcreateddateto.setDate(xcreateddateto.getDate() + 1);
                    moment(String(xcreateddateto)).format('YYYY-MM-DD');
                }
                this.input.createddateto = this.search.createddateto ? xcreateddateto:null;
            }
            axios({
                method: "POST",
                url: "api/releasenote/getdataforlinegraph",
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.linechartlist = result.data.data;
                    let linechartdata = [];
                    let xaxislabel =[];
                    for(let l=0; l < this.linechartlist.length; l++){
                        if(linechartdata.some(obj => obj.id === this.linechartlist[l].projectid)){
                            for (let i = 0; i < linechartdata.length; i++) {
                                if (linechartdata[i].id === this.linechartlist[l].projectid) {                                
                                    linechartdata[i].datas.push(this.linechartlist[l].fieldvalue ? parseInt(this.linechartlist[l].fieldvalue) :0);
                                }
                            }
                            if(this.linechartlist[l].fieldvalue ==null || this.linechartlist[l].fieldvalue =='' || this.linechartlist[l].fieldvalue ==' '){
                                this.linechartlist[l].fieldvalue =0;
                            }
                            if(!xaxislabel.includes(this.linechartlist[l].fieldvalue)){
                                xaxislabel.push(this.linechartlist[l].fieldvalue);
                            }
                        }else{
                            let tempdatas = {datas: [this.linechartlist[l].fieldvalue ? parseInt(this.linechartlist[l].fieldvalue) :0],label: this.linechartlist[l].projectname, id: this.linechartlist[l].projectid}
                            linechartdata.push(tempdatas);

                            if(this.linechartlist[l].fieldvalue ==null || this.linechartlist[l].fieldvalue =='' || this.linechartlist[l].fieldvalue ==' '){
                                this.linechartlist[l].fieldvalue =0;
                            }
                            if(!xaxislabel.includes(this.linechartlist[l].fieldvalue)){
                                xaxislabel.push(this.linechartlist[l].fieldvalue);
                            }
                        }
                    }
                    // for(let l=0; l < this.linechartlist.length; l++){
                    //     if(linechartdata.some(obj => obj.id === this.linechartlist[l].projectid)){
                    //         for (let i = 0; i < linechartdata.length; i++) {
                    //             if (linechartdata[i].id === this.linechartlist[l].projectid) {                                
                    //                 linechartdata[i].datas.push(parseInt(this.linechartlist[l].version));
                    //             }
                    //         }
                    //     }else{
                    //         let tempdatas = {datas: [parseInt(this.linechartlist[l].version)],label: this.linechartlist[l].projectname, id: this.linechartlist[l].projectid}
                    //         linechartdata.push(tempdatas);
                    //     }
                    // }
                    if(linechartdata && linechartdata.length > 0){
                        this.drawlinecharts(linechartdata,xaxislabel);
                    }
                } else {
                    this.linechartlist = [];
                    let elselinedata =[
                        {
                            "datas": [0],
                            "label": "",
                            "id": "1"
                        },
                        {
                            "datas": [0],
                            "label": "",
                            "id": "2"
                        }]
                    this.drawlinecharts(elselinedata);
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        drawlinecharts(linechartdata,xaxislabel){
            if(this.linemaster){
                this.linemaster.destroy();
            }
            if(linechartdata){
                let lineChartEx = $('.releaselinechart');
                let tooltipShadow = 'rgba(0, 0, 0, 0.25)',
                labelColor = '#6e6b7b',
                grid_line_color = 'rgba(200, 200, 200, 0.2)';
                let allcolour = ['#ff4961', '#666ee8', '#ffe802', '#28dac6','#299AFF'];
                let maxnumber = null;
                let autosteps = null;
                let allarrays = [];
                let showdata =[];
                let xaxisarray = xaxislabel;

                for(let l=0; l < linechartdata.length; l++){

                    let value0 =0,value1 =0,value2=0,value3=0,value4=0,value5=0,value6=0,value7=0,value8=0,value9=0,value10=0;

                    for(let ldata of linechartdata[l].datas){
                        if(ldata ==0){
                            value0 =value0;
                        }
                        if(ldata ==1){
                            value1 =value1+1;
                        }
                        if(ldata ==2){
                            value2 = value2+1;
                        }
                        if(ldata ==3){
                            value3 = value3+1;
                        }
                        if(ldata ==4){
                            value4 = value4+1;
                        }
                        if(ldata ==5){
                            value5 = value5+1;
                        }
                        if(ldata ==6){
                            value6 = value6+1;
                        }
                        if(ldata ==7){
                            value7 = value7+1;
                        }
                        if(ldata ==8){
                            value8 = value8+1;
                        }
                        if(ldata ==9){
                            value9 = value9+1;
                        }
                        if(ldata ==10){
                            value10 = value10+1;
                        }
                    }
                    linechartdata[l].datas = [value1,value1,value2,value3,value4,value5,value6,value7,value8,value9,value10];

                allarrays = allarrays.concat(linechartdata[l].datas);
                let tempcolour = allcolour[Math.floor(Math.random() * allcolour.length)]
                let tempobje={
                    data: linechartdata[l].datas,
                    label: linechartdata[l].label,
                    borderColor: tempcolour,//lineChartDanger,
                    lineTension: 0.5,
                    pointStyle: 'circle',
                    backgroundColor: tempcolour,//lineChartDanger,
                    fill: false,
                    pointRadius: 1,
                    pointHoverRadius: 5,
                    pointHoverBorderWidth: 5,
                    pointBorderColor: 'transparent',
                    pointHoverBorderColor: window.colors.solid.white,
                    pointHoverBackgroundColor: tempcolour,
                    pointShadowOffsetX: 1,
                    pointShadowOffsetY: 1,
                    pointShadowBlur: 5,
                    pointShadowColor: tooltipShadow
                }
                showdata.push(tempobje);
                }
                maxnumber = Math.max.apply(null,allarrays);
                if(maxnumber ==0){
                    maxnumber =10;
                }
                maxnumber = Math.ceil(parseInt(maxnumber)/100) *100
                autosteps = (maxnumber/100)*5;
                if(maxnumber){
                let nosteps = 10;
                let stepSize = Math.floor(maxnumber / nosteps);

                // xaxisarray = Array.from({ length: nosteps }, (_, index) => index * stepSize);
                // if (xaxisarray[xaxisarray.length - 1] !== maxnumber) {
                //     xaxisarray.push(maxnumber);
                // }
                }

                if (lineChartEx.length) {
                this.linemaster = new Chart(lineChartEx, {
                    type: 'line',
                    plugins: [
                    // to add spacing between legends and chart
                    {
                        beforeInit: function (chart) {
                        chart.legend.afterFit = function () {
                            this.height += 20;
                        };
                        }
                    }
                    ],
                    options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    backgroundColor: false,
                    hover: {
                        mode: 'label',
                    },
                    tooltips: {
                        // Updated default tooltip UI
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowBlur: 8,
                        shadowColor: tooltipShadow,
                        backgroundColor: window.colors.solid.white,
                        titleFontColor: window.colors.solid.black,
                        bodyFontColor: window.colors.solid.black
                    },
                    layout: {
                        padding: {
                        top: -15,
                        bottom: -25,
                        left: -15
                        }
                    },
                    scales: {
                        xAxes: [
                        {
                            display: true,
                            scaleLabel: {
                            display: true
                            },
                            gridLines: {
                            display: true,
                            color: grid_line_color,
                            zeroLineColor: grid_line_color
                            },
                            ticks: {
                            stepSize: 1,
                            min: 0,
                            max: maxnumber,
                            fontColor: labelColor
                            }
                        }
                        ],
                        yAxes: [
                        {
                            display: true,
                            scaleLabel: {
                            display: true
                            },
                            ticks: {
                            stepSize: autosteps,
                            min: 0,
                            max: maxnumber,
                            fontColor: labelColor
                            },
                            gridLines: {
                            display: true,
                            color: grid_line_color,
                            zeroLineColor: grid_line_color
                            }
                        }
                        ]
                    },
                    legend: {
                        position: 'top',
                        align: 'start',
                        labels: {
                        usePointStyle: true,
                        padding: 25,
                        boxWidth: 9
                        }
                    }
                    },
                    data: {
                    labels: ['','Rating 1','Rating 2','Rating 3','Rating 4','Rating 5','Rating 6','Rating 7','Rating 8','Rating 9','Rating 10'],
                    datasets: showdata
                    }
                });
                }
            }
        },
        getpiechartdata(){
            this.isLoading = true;
            this.input={
                useremail: this.undt.username,
                empcode: this.undt.userid,
                charttype: 'piechart'
            }
            if(this.search.releasecode && this.search.releasecode !=null){
                this.input.releasenotecode = this.search.releasecode;
            }
            if(this.search.project && this.search.project.length && this.search.project.length > 0){
                this.input.projectid = this.search.project;
            }
            if((this.search.createddatefrom && this.search.createddatefrom !=null && this.search.createddatefrom !='') && (this.search.createddateto && this.search.createddateto !=null && this.search.createddateto !='')){
                this.input.createddatefrom = this.search.createddatefrom;
                let xcreateddateto='';
                if(this.search.createddateto){
                    xcreateddateto = new Date(this.search.createddateto);
                    xcreateddateto.setDate(xcreateddateto.getDate() + 1);
                    moment(String(xcreateddateto)).format('YYYY-MM-DD');
                }
                this.input.createddateto = this.search.createddateto ? xcreateddateto:null;
            }
            axios({
                method: "POST",
                url: "api/releasenote/getnpsdistributiondata",
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.piechartlist = result.data.data;
                    let piedata = [];
                    for(let l=0; l < this.piechartlist.length; l++){
                        let tempvalue = 0;
                        if(piedata.some(obj => obj.id === this.piechartlist[l].releasenoteid)){
                            for (let i = 0; i < piedata.length; i++) {
                                if (piedata[i].id === this.piechartlist[l].releasenoteid) {
                                    let tempasvalue = piedata[i].value;
                                    if(this.piechartlist[l].fieldvalue >=9 && this.piechartlist[l].fieldvalue <=10){
                                        tempasvalue = tempasvalue+1;
                                    }                            
                                    piedata[i].value = tempasvalue;
                                }
                            }
                        }else{
                            if(this.piechartlist[l].fieldvalue >=9 && this.piechartlist[l].fieldvalue <=10){
                                tempvalue = 1;
                            }
                            let tempdatas = {id: this.piechartlist[l].releasenoteid,label: this.piechartlist[l].releasenotecode,value: tempvalue,}
                            piedata.push(tempdatas);
                        }
                    }
                    if(piedata && piedata.length > 0){
                        this.drawpiecharts(piedata);
                    }
                } else {
                    this.piechartlist = [];
                    let elsepiedata =[
                        {id:1, label:'', value:0, colour:'#ff4961'},
                        {id:2, label:'', value:0, colour:'#666ee8'},
                        {id:3, label:'', value:0, colour:'#ffe802'},
                        {id:4, label:'', value:0, colour:'#28dac6'}
                    ]
                    this.drawpiecharts(elsepiedata);
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        drawpiecharts(datap){
            if(this.piechartmaster){
                this.piechartmaster.destroy();
            }
            if(datap){
                let tooltipShadow = 'rgba(0, 0, 0, 0.25)'
                let pielabel = [];
                let pievalue = [];  
                let piecolor = [];
                    
                for (let j = 0; j < datap.length; j++) {
                    pielabel.push(datap[j].label);
                    pievalue.push(parseInt(datap[j].value));
                    let tempcolour = this.colourarray[Math.floor(Math.random() * this.colourarray.length)];
                    datap[j]['colour'] = tempcolour;
                    piecolor.push(tempcolour);
                }
                this.piedata =[];
                this.piedata = datap;

                let doughnutChartEx1=$('.piechartmaster');

                if (doughnutChartEx1.length) {
                    this.piechartmaster = new Chart(doughnutChartEx1, {
                    type: 'doughnut',
                    options: {
                        responsive: true,
                        maintainAspectRatio: true,
                        responsiveAnimationDuration: 500,
                        cutoutPercentage: 60,
                        legend: { display: false },
                        tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                            let label = data.datasets[0].labels[tooltipItem.index] || '',
                                value = data.datasets[0].data[tooltipItem.index];
                            let output = ' ' + label + ' : ' + value;
                            return output;
                            }
                        },
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        shadowBlur: 8,
                        shadowColor: tooltipShadow,
                        backgroundColor: window.colors.solid.white,
                        titleFontColor: window.colors.solid.black,
                        bodyFontColor: window.colors.solid.black
                        }
                    },
                    data: {
                        datasets: [
                        {
                            labels: pielabel,
                            data: pievalue,
                            backgroundColor: piecolor,
                            borderWidth: 0,
                            pointStyle: 'rectRounded'
                        }
                        ]
                    }
                    });
                }
            }
        },
        getbarchartdata(){
            this.isLoading = true;
            this.input={
                useremail: this.undt.username,
                empcode: this.undt.userid,
                charttype: 'barchart'
            }
            if(this.search.releasecode && this.search.releasecode !=null){
                this.input.releasenotecode = this.search.releasecode;
            }
            if(this.search.project && this.search.project.length && this.search.project.length > 0){
                this.input.projectid = this.search.project;
            }
            if((this.search.createddatefrom && this.search.createddatefrom !=null && this.search.createddatefrom !='') && (this.search.createddateto && this.search.createddateto !=null && this.search.createddateto !='')){
                this.input.createddatefrom = this.search.createddatefrom;
                let xcreateddateto='';
                if(this.search.createddateto){
                    xcreateddateto = new Date(this.search.createddateto);
                    xcreateddateto.setDate(xcreateddateto.getDate() + 1);
                    moment(String(xcreateddateto)).format('YYYY-MM-DD');
                }
                this.input.createddateto = this.search.createddateto ? xcreateddateto:null;
            }
            axios({
                method: "POST",
                url: "api/releasenote/getnpsdistributiondata",
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                this.bardata= [
                    {id:1,label: 'Rating 1',value:0, colour: '#698ED0'},
                    {id:2,label: 'Rating 2',value:0,colour: '#ED7D31'},
                    {id:3,label: 'Rating 3',value:0,colour: '#B7B7B7'},
                    {id:4,label: 'Rating 4',value:0,colour: '#FFC000'},
                    {id:5,label: 'Rating 5',value:0,colour: '#2b9bf4'},
                    {id:6,label: 'Rating 6',value:0,colour: '#70AD47'},
                    {id:7,label: 'Rating 7',value:0,colour: '#264478'},
                    {id:8,label: 'Rating 8',value:0,colour: '#9E480E'},
                    {id:9,label: 'Rating 9',value:0,colour: '#636363'},
                    {id:10,label: 'Rating 10',value:0,colour: '#997300'}
                ]
                if (result.data.errorCode == 0) {
                    this.barchartlist = result.data.data;
                    let value1 =0,value2=0,value3=0,value4=0,value5=0,value6=0,value7=0,value8=0,value9=0,value10=0;
                    for(let b=0; b < this.barchartlist.length; b++){
                        if(this.barchartlist[b].fieldvalue ==1){
                            value1 =this.bardata[0].value+1;
                            this.bardata[0].value = value1;
                        }
                        if(this.barchartlist[b].fieldvalue ==2){
                            value2 = this.bardata[1].value+1;
                            this.bardata[1].value = value2;
                        }
                        if(this.barchartlist[b].fieldvalue ==3){
                            value3 = this.bardata[2].value+1;
                            this.bardata[2].value = value3;
                        }
                        if(this.barchartlist[b].fieldvalue ==4){
                            value4 = this.bardata[3].value+1;
                            this.bardata[3].value = value4;
                        }
                        if(this.barchartlist[b].fieldvalue ==5){
                            value5 = this.bardata[4].value+1;
                            this.bardata[4].value = value5;
                        }
                        if(this.barchartlist[b].fieldvalue ==6){
                            value6 = this.bardata[5].value+1;
                            this.bardata[5].value = value6;
                        }
                        if(this.barchartlist[b].fieldvalue ==7){
                            value7 = this.bardata[6].value+1;
                            this.bardata[6].value = value7;
                        }
                        if(this.barchartlist[b].fieldvalue ==8){
                            value8 = this.bardata[7].value+1;
                            this.bardata[7].value = value8;
                        }
                        if(this.barchartlist[b].fieldvalue ==9){
                            value9 = this.bardata[8].value+1;
                            this.bardata[8].value = value9;
                        }
                        if(this.barchartlist[b].fieldvalue ==10){
                            value10 = this.bardata[9].value+1;
                            this.bardata[9].value = value10;
                        }
                    }
                    if(this.bardata && this.bardata.length > 0){
                        this.showbarchartdata(this.bardata);
                    }
                } else {
                    this.barchartlist = [];
                    this.showbarchartdata(this.bardata);
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        showbarchartdata(datab){
            if(this.barChartExample2){
                this.barChartExample2.destroy();
            }
            if(datab){
                let tooltipShadow = 'rgba(0, 0, 0, 0.25)',
                labelColor = '#6e6b7b',
                grid_line_color = 'rgba(200, 200, 200, 0.2)'
                tooltipShadow = 'rgba(0, 0, 0, 0.25)',
                labelColor = '#6e6b7b';
                let barlabel = [];
                let barvalue = [];  
                let barcolor = [];
                let arraynumber = [];
                    
                for (let k = 0; k < datab.length; k++) {
                    barlabel.push(datab[k].label);
                    barvalue.push(parseInt(datab[k].value));
                    datab[k]['colour'] = datab[k].colour;
                    barcolor.push(datab[k].colour);
                    arraynumber.push(parseInt(datab[k].value));
                }
                this.bardata = [];
                if(datab){
                    this.bardata = datab;
                }
                let maxnumber = Math.max.apply( null, arraynumber );
                if(maxnumber ==0){
                    maxnumber =10;
                }
                let maxCount = Math.ceil(maxnumber/200) * 50

                let CountStep = (maxCount/100)*10;
                let barChartEx1=$('.barchartmaster');
                if (barChartEx1.length) {
                    this.barChartExample2 = new Chart(barChartEx1, {
                    type: 'bar',
                    options: {
                        elements: {
                        rectangle: {
                            borderWidth: 2,
                            borderSkipped: 'bottom'
                        }
                        },
                        responsive: true,
                        maintainAspectRatio: true,
                        responsiveAnimationDuration: 500,
                        legend: {
                        display: false
                        },
                        tooltips: {
                        shadowOffsetX: 1,
                        shadowOffsetY: 1,
                        // shadowBlur: 8,
                        shadowColor: tooltipShadow,
                        backgroundColor: window.colors.solid.white,
                        titleFontColor: window.colors.solid.black,
                        bodyFontColor: window.colors.solid.black
                        },
                        scales: {
                        xAxes: [
                            {
                            barThickness: 15,
                            display: true,
                            gridLines: {
                                display: true,
                                color: grid_line_color,
                                zeroLineColor: grid_line_color
                            },
                            scaleLabel: {
                                display: false
                            },
                            ticks: {
                                fontColor: labelColor
                            }
                            }
                        ],
                        yAxes: [
                            {
                            display: true,
                            gridLines: {
                                color: grid_line_color,
                                zeroLineColor: grid_line_color
                            },
                            ticks: {
                                stepSize: CountStep,
                                min: 0,
                                max: maxCount,
                                fontColor: labelColor
                            },
                            stacked: true
                            }
                        ]
                        }
                    },
                    data: {
                        labels: ['Rating 1','Rating 2','Rating 3','Rating 4','Rating 5','Rating 6','Rating 7','Rating 8','Rating 9','Rating 10'],
                        datasets: [{
                        borderWidth: 0,
                        data: barvalue,
                        backgroundColor: barcolor,
                        borderColor: 'transparent'
                        }]
                    }
                    });
                }
            }
        },
        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            let assignedate=''
            if(this.search.createddateto){
            assignedate = new Date(this.search.createddateto)
            assignedate.setDate(assignedate.getDate() + 1)
            moment(String(assignedate)).format('YYYY-MM-DD');
            }
            this.input = {
                limit:null,
                offset:null,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                empid: this.undt.userid,
                releasecode: this.search.releasecode ? this.search.releasecode : null,
                project:  this.search.project ? this.search.project : null,
                approvedby: this.search.approvedby ? (this.search.approvedby) : null,
                pmname: this.search.pmname ? (this.search.pmname) : null,
                status: this.search.status? this.search.status: null,
                datefrom: this.search.createddatefrom?this.search.createddatefrom:null,
                dateto: this.search.createddateto?assignedate:null
            }; 
            axios({
                method: "POST",
                url: `api/releasenote/getReleaseNoteList`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if (result.data.errorCode == 0) {
                this.exportxlsdata = result.data.data.rows;
                var filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
                this.islistLoading = false;
            }).catch(e => {
                this.displayError(e)
            });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                var ShowLabel = 'ReleaseNoteDetails_'+filename
                var JSONData = readExportdata
                var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    
                    CSV = 'Sr#,Release Code,Project Name,Version,Approval Status,Created by,Created date,Deployement Status,Deployed By,Deployed date,Engineering Manager,Project Manager,QA Lead,Business Analyst,Approved date' + '\r\n'

                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let releasenotecode = index.hasOwnProperty('releasenotecode') ? index.releasenotecode : '';
                let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
                let version = index.hasOwnProperty('version') ? index.version : '';
                let releasestatus = index.hasOwnProperty('releasestatus') ? index.releasestatus == null||index.releasestatus.toLowerCase() == 'na'?'':index.releasestatus : '';
                let createdby = index.hasOwnProperty('createdby') ? index.createdby == null||index.createdby.toLowerCase() == 'na'?'':index.createdby.split('@')[0] : '';
                let releasedate = index.releasedate?this.dateFormat(index.releasedate):'NA';
                let createddate = this.dateFormat(index.createddate);
                let assigneddate = index.assigneddate?this.dateFormat(index.assigneddate):'NA';
                let approveddate = index.approveddate?this.dateFormat(index.approveddate):'NA';
                let assignstatus = index.hasOwnProperty('assignstatus') ? index.assignstatus == null||index.assignstatus.toLowerCase() == 'na'?'NA':index.assignstatus : '';
                let apprvedbyemail = index.hasOwnProperty('apprvedbyemail') ? index.apprvedbyemail == null||index.apprvedbyemail.toLowerCase() == 'na'?'':index.apprvedbyemail.split('@')[0] : '';
                let projectemail = index.hasOwnProperty('projectemail') ? index.projectemail == null||index.projectemail.toLowerCase() == 'na'?'':index.projectemail.split('@')[0] : '';
                let qaemail = index.hasOwnProperty('qaemail') ? index.qaemail == null||index.qaemail.toLowerCase() == 'na'?'':index.qaemail.split('@')[0] : '';
                let baemail = index.hasOwnProperty('qaemail') ? index.baemail == null||index.baemail.toLowerCase() == 'na'?'':index.baemail.split('@')[0] : '';
                let releasedby = index.hasOwnProperty('releasedby') ? index.releasedby == null||index.releasedby.toLowerCase() == 'na'?'NA':index.releasedby.split('@')[0] : '';

                    row += '"' + srno + '",',
                    row += '"' + releasenotecode + '",',
                    row += '"' + projectname + '",',
                    row += '"' + version + '",',
                    row += '"' + releasestatus + '",',
                    row += '"' + createdby + '",',
                    row += '"' + createddate + '",',
                    //row += '"' + assigneddate + '",',
                    row += '"' + assignstatus + '",',
                    row += '"' + releasedby + '",',
                    row += '"' + releasedate + '",',
                    row += '"' + apprvedbyemail + '",'
                    row += '"' + projectemail + '",'
                    row += '"' + qaemail + '",'
                    row += '"' + baemail + '",'
                    row += '"' + approveddate + '",',
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                return;
                }
                var fileName = 'ReleaseNoteDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                title: "Success!",
                text: "Export Report successful",
                icon: "success",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });  
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
            },
    }
}
</script>