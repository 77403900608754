<template>
    <div class="app-content content devcontent">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-body">
                <div class="row" id="table-small">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Project Master</h4>
                            </div>
                            <div class="table-responsive" >
                                <table class="table" aria-describedby="mydesc">
                                    <thead>
                                        <tr>
                                            <th scope="col">Project</th>
                                            <th scope="col">HOD Name</th>
                                            <th scope="col">Users</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img src="../../public/images/icons/angular.svg" class="mr-75" alt="Angular" width="18" height="18" />
                                                <span class="font-weight-bold">Angular Project</span>
                                            </td>
                                            <td>Peter Charls</td>
                                            <td>
                                                <div class="avatar-group">
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                        <img src="../../public/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                                            <td>
                                                <div class="dropdown">
                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                        <em data-feather="more-vertical"></em>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="edit-2" class="mr-50"></em>
                                                            <span>Edit</span>
                                                        </a>
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="trash" class="mr-50"></em>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../../public/images/icons/react.svg" class="mr-75" alt="React" width="18" height="18" />
                                                <span class="font-weight-bold">React Project</span>
                                            </td>
                                            <td>Ronald Frest</td>
                                            <td>
                                                <div class="avatar-group">
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                        <img src="../../public/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                                            <td>
                                                <div class="dropdown">
                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                        <em data-feather="more-vertical"></em>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="edit-2" class="mr-50"></em>
                                                            <span>Edit</span>
                                                        </a>
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="trash" class="mr-50"></em>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../../public/images/icons/vuejs.svg" class="mr-75" alt="Vuejs" width="18" height="18" />
                                                <span class="font-weight-bold">Vuejs Project</span>
                                            </td>
                                            <td>Jack Obes</td>
                                            <td>
                                                <div class="avatar-group">
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                        <img src="../../public/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                                            <td>
                                                <div class="dropdown">
                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                        <em data-feather="more-vertical"></em>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="edit-2" class="mr-50"></em>
                                                            <span>Edit</span>
                                                        </a>
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="trash" class="mr-50"></em>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src="../../public/images/icons/bootstrap.svg" class="mr-75" alt="Bootstrap" width="18" height="18" />
                                                <span class="font-weight-bold">Bootstrap Project</span>
                                            </td>
                                            <td>Jerry Milton</td>
                                            <td>
                                                <div class="avatar-group">
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Lilian Nenez">
                                                        <img src="../../public/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                    <div data-toggle="tooltip" data-popup="tooltip-custom" data-placement="top" title="" class="avatar pull-up my-0" data-original-title="Alberto Glotzbach">
                                                        <img src="../../public/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                                            <td>
                                                <div class="dropdown">
                                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                        <em data-feather="more-vertical"></em>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="edit-2" class="mr-50"></em>
                                                            <span>Edit</span>
                                                        </a>
                                                        <a class="dropdown-item" href="javascript:void(0);">
                                                            <em data-feather="trash" class="mr-50"></em>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- Add Project Sidebar -->
                <div class="modal modal-slide-in fade" id="add-payment-sidebar" aria-hidden="true">
                    <div class="modal-dialog sidebar-lg">
                        <div class="modal-content p-0">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                            <div class="modal-header mb-1">
                                <h5 class="modal-title">
                                    <span class="align-middle">Add Project</span>
                                </h5>
                            </div>
                            <div class="modal-body flex-grow-1">
                                <form>
                                    <div class="form-group">
                                        <input id="business" class="form-control" type="text" value="firstcry.com" disabled />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="project-name">Project Name </label>
                                        <input id="pname" class="form-control" type="text" placeholder="Project Name " />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="payment-date">Project Kick off Date</label>
                                        <input id="project-date" class="form-control date-picker" type="text" />
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="payment-method">Country</label>
                                        <select class="form-control" id="pcountry">
                                            <option value="" selected disabled>Select Country</option>
                                            <option value="INDIA">INDIA</option>
                                            <option value="UAE">UAE</option>
                                            <option value="CHINA">CHINA</option>
                                            <option value="GLOBAL">Global</option>
                                            <option value="Paypal">OMAN</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label class="form-label" for="payment-note">Project Success Defination </label>
                                        <textarea class="form-control" id="payment-note" rows="5" placeholder="Project Success Defination "></textarea>
                                    </div>
                                    <div class="form-group d-flex flex-wrap mb-0" >
                                        <button type="button" class="btn btn-relief-primary mr-1 " id="type-success" data-dismiss="modal">Submit</button>
                                        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Add Project Sidebar -->
            </div>
        </div>
    
    </div>
</template>
<script>
export default ({
    name:'ProjectMaster',  
})

</script>

