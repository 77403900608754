<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-1">
                    <h4 class="card-title">
                      Sprint Details Report
                    </h4>
                    <button type="move" class="btn btn-relief-primary mt-0 mobile_margin-px-2" @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span> </button>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              
                              <div
                                class="table-responsive_sitcky"
                                v-if="showtabledata">
                                <table class="table table-sm table-hover table-striped dataTableListing all_table_stickys" aria-describedby="Red Project List Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="align-middle text-left first-col-sticky">Project Name</th>
                                      <th scope="col" class="align-middle">BTC</th>
                                      <th scope="col" class="align-middle">Sub BTC</th>
                                      <th scope="col" class="align-middle">Task Name</th>
                                      <th scope="col" class="align-middle">Project Manager</th>
                                      <th scope="col" class="align-middle">Program Manager</th>
                                      <th scope="col" class="align-middle">Engineering Manager</th>
                                      <th scope="col" class="align-middle">Parent Engineering Manager</th>
                                      <th scope="col" class="align-middle">Task Status</th>
                                      <th scope="col" class="align-middle">Parent Status</th>
                                      <th scope="col" class="align-middle">Task Type</th>
                                      <th scope="col" class="align-middle">Department</th>
                                      <th scope="col" class="align-middle">Created Date</th>
                                      <th scope="col" class="align-middle">Created By</th>
                                      <th scope="col" class="align-middle">Due Date</th>
                                      <th scope="col" class="align-middle">Start Date</th>
                                      <th scope="col" class="align-middle">Estimated Date</th>
                                      <th scope="col" class="align-middle">Tech Busniess Review Date</th>
                                      <th scope="col" class="align-middle">Tech Pipeline Date</th>
                                      <th scope="col" class="align-middle">Tech WIP Date</th>
                                      <th scope="col" class="align-middle">Qa Pipeline Date</th>
                                      <th scope="col" class="align-middle">Qa WIP Date</th>
                                      <th scope="col" class="align-middle">UAT Date</th>
                                      <th scope="col" class="align-middle">Tech Issue Resolution Date</th>
                                      <th scope="col" class="align-middle">UAT Sign Off Date</th>
                                      <th scope="col" class="align-middle">Live Date</th>
                                      <th scope="col" class="align-middle">Task Estimates</th>
                                      <th scope="col" class="align-middle">Estimated Hours</th>
                                      <th scope="col" class="align-middle">Development Delays</th>
                                      <th scope="col" class="align-middle">BTC Delays</th>
                                      <th scope="col" class="align-middle">Tech Pipeline Delays</th>
                                      <th scope="col" class="align-middle">Tech Issue Delays</th>
                                      <th scope="col" class="align-middle">TBR Delays</th>
                                      <th scope="col" class="align-middle">UAT SignOff Delays</th>
                                      <th scope="col" class="align-middle">Moved To TBR</th>
                                      <th scope="col" class="align-middle">Moved To Tech WIP</th>
                                      <th scope="col" class="align-middle">Moved To Tech Pipeline</th>
                                      <th scope="col" class="align-middle">Moved To QA Week</th>
                                      <th scope="col" class="align-middle">Moved To Live</th>
                                      <th scope="col" class="align-middle">Moved To Completed</th>
                                      <th scope="col" class="align-middle">Sprint Week</th>
                                      <th scope="col" class="align-middle">BusinessTaskMaster ID</th>
                                      <th scope="col" class="align-middle">Parent BTC ID</th>
                                      <th scope="col" class="align-middle">Project ID</th>
                                      <th scope="col" class="align-middle">Bucket Dev</th>
                                      <th scope="col" class="align-middle">Bucket BTC Delay</th>
                                      <th scope="col" class="align-middle">Bucket Tech Pipeline Delays</th>
                                      <th scope="col" class="align-middle">Bucket TBR Delays</th>
                                      <th scope="col" class="align-middle">Bucket UAT SignOff Delays</th>
                                      <th scope="col" class="align-middle">Bucket Tech Issue Delays</th>
                                      <th scope="col" class="align-middle">Sub Task Exists</th>
                                      <th scope="col" class="align-middle">Reporting To</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template  v-for="(data, index) in companymasterlist" >
                                      
                                      <tr v-bind:key="index">  
                                                                              
                                        <td :class="'capitalisetext text-left font-weight-normal first-col-sticky cursorpointercls sitebluecolor unique'+index+1+currPageNum*pagelimit" @click="redirecttourl('projectId',data)">
                                          {{data.projectname}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.btc ? data.btc:'NA'}}</td>
                                         <td class="capitalisetext font-weight-normal">
                                          {{data.subbtc ? data.subbtc:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.taskname ? data.taskname:'NA'}}</td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{data.projectmaanager ? data.projectmaanager:'NA'}}
                                        </td>

                                         <td class="capitalisetext font-weight-normal">
                                          {{data.programmanager ? data.programmanager:'NA'}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal">
                                          {{data.engineeringmaanager ? data.engineeringmaanager:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.parenteggmgr ? data.parenteggmgr:'NA'}}
                                        </td>
                                         <td class="font-weight-normal text-center">
                                          {{data.taskstatus  ? data.taskstatus:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.parentstatus  ? data.parentstatus:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.tasktype  ? data.tasktype:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.department  ? data.department:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.createddate  ? data.createddate:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.createdby  ? data.createdby:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.due_date  ? data.due_date:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.startdate  ? data.startdate:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.estimatedddate  ? data.estimatedddate:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.techbusinessreviewdt  ? data.techbusinessreviewdt:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.techpipelinedate  ? data.techpipelinedate:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.techwipdt  ? data.techwipdt:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.qapipelinedt  ? data.qapipelinedt:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.qawipdt  ? data.qawipdt:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.uatdate  ? data.uatdate:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.techissueresolutiondt  ? data.techissueresolutiondt:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.uatsignoffdt  ? data.uatsignoffdt:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.livedate  ? data.livedate:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.taskestimates  ? data.taskestimates:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.estimatedhours  ? data.estimatedhours:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.developmentdelays  ? data.developmentdelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.btcdelays  ? data.btcdelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.techpipelinedelays  ? data.techpipelinedelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.techissuedelays  ? data.techissuedelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.tbrdelays  ? data.tbrdelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.uatsignoffdelays  ? data.uatsignoffdelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.movedtotbr  ? data.movedtotbr:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.movedtotechwip  ? data.movedtotechwip:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.movedtotechpipeline  ? data.movedtotechpipeline:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.movedtoqaweek  ? data.movedtoqaweek:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.movedtolive  ? data.movedtolive:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.movedtocompleted  ? data.movedtocompleted:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.sprintweek  ? data.sprintweek:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.businesstaskmasterid  ? data.businesstaskmasterid:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.parentbtcid  ? data.parentbtcid:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.projectid  ? data.projectid:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.bucket_dev  ? data.bucket_dev:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.bucket_btcdelays  ? data.bucket_btcdelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.bucket_techpipelinedelays  ? data.bucket_techpipelinedelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.bucket_tbrdelays  ? data.bucket_tbrdelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.bucket_uatsignoffdelays  ? data.bucket_uatsignoffdelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.bucket_techissuedelays  ? data.bucket_techissuedelays:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.subtaskexistss  ? data.subtaskexistss:'NA'}}
                                        </td>
                                        <td class="font-weight-normal text-center">
                                          {{data.reportingto  ? data.reportingto:'NA'}}
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14"  style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "SprintDetails",
  mixins: [ commonMethods ],
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
  },
  data() {
    return {
      empid: null,
      searchOffset: 1,
      searchSession: false,
      project: {
        name: null,
        desc: null,
        projecttype: null,
        projecttypelist: [
          {
            id: "",
            label: "",
          },
        ],
        businesslist: [
          {
            id: "",
            label: "",
          },
        ],
        companylist: [
          {
            id: "",
            label: "",
          },
        ],
      },
      search: {
          searchProjectname:null,
          projectnameList:[],
          searchProjectmanager:null,
          projectManagers: [],
          searchEngmanager:null,
          engManagers:[],
          mngrname:null,
          programManagers: [],
      },
      
      mngrlist:[],
      isLoading: false,
      fullPage: true,
      showprojectdata: [],
      TimeFormat: apiUrl.TimeFormat,
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      allapicallauthkey: "",
      showtabledata: true,
      glbMdl: "",
      glbst: "",
      showfilterboxsection:false,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      companymasterlist:[],
      tokendata:null,
      isresponce:false,
      managersnamelist: []
    };

  },
 

  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.empid = userdata.userid
        this.tokendata = window.localStorage.getItem('token');
        this.getReportData();
        this.search.searchProjectname = [this.$route.params.pid];
      }
     }
     $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'listingpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.search.projectnameList = result.data.data;
            } else {
                this.search.projectnameList = [];
            }
            }).catch(e => {
                this.displayError(e)
            });
        },
    getProjectManagerDetails(roleid,projectid) {
      axios({
          method: "POST",
          url: "api/listing/getProjectManagerDetails",
          headers: { authorization : this.tokendata },
          data:{roleid:roleid,projectidarr:projectid,empcode: this.undt.userid,useremail: this.undt.username}
      }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              if(roleid==477){
                  this.search.projectManagers = result.data.data.rows
                  this.search.projectManagers.sort((a, b) => {
                   return a.label.localeCompare(b.label);
                  });
              }else if(roleid==492){
                  this.search.engManagers = result.data.data.rows
                  this.search.engManagers.sort((a, b) => {
                   return a.label.localeCompare(b.label);
                  });
              }else if(roleid==503){
                  this.search.programManagers = result.data.data.rows
                  this.search.programManagers.sort((a, b) => {
                   return a.label.localeCompare(b.label);
                  });
              }          
          }else{
              if(roleid==477){
                this.search.projectManagers = [];
              }else if(roleid==492){
                this.search.engManagers = [];
              }else if(roleid==503){
                this.search.programManagers = [];
              }
          }
      }).catch(e => {
          this.displayError(e)
      });
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.search.searchProjectname = state;
    }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: "listingpage"
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
      
    },
    resetRecords: function () {
      this.search.mngrname=null
      this.getReportData();
      this.search.searchProjectname = null;
      this.search.createdby = null;
      this.search.companyname = null;
      this.search.businessname = null;
      this.searchSession = false
      this.searchOffset = 1
      this.managersnamelist = [];
    },
    getcomapnylist: function () {
      this.isLoading = true;
      let url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: "t1",
        offset: 1,
        limit: 1000,
        isSearch: false,
        section: "project",
        isFilterSelect: 0,
        isFilterPayload: {},
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        console.log();
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companylist = result.data.data.rows;
        } else {
          this.companylist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    getReportData() {
      this.isLoading = true; 
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      }; 
      let url = "api/reports/sprint-details";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else {
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        }  
      }).catch(e => {
        this.displayError(e)
      });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      this.getReportData();
      $('html, body').animate({
        scrollTop: $("#reportTopScroll").offset().top -70
      });
    },
    setSelected(value) {
      this.selected = value;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        useremail: this.undt.username,
        empcode: this.undt.userid,   
      };
      axios({
        method: "POST",
        url: `api/reports/sprint-details`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'redprojectlistreport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Project ID,Project Name,BTC,Sub BTC,Task Name,Project Manager,Program Manager,Engineering Manager,Parent Engineering Manager,Task Status,Parent Status,Task Type,Department,Created Date,Created By,Due Date,Start Date,Estimated Date,Tech Busniess Review Date,Tech Pipeline Date,Tech WIP Date,Qa Pipeline Date,Qa WIP Date, UAT Date,Tech Issue Resolution Date,UAT Sign Off Date,Live Date,Task Estimates,Estimated Hours,Development Delays,BTC Delays,Tech Pipeline Delays,Tech Issue Delays,TBR Delays,UAT SignOff Delays,Moved To TBR,Moved To Tech WIP,Moved To Tech Pipeline,Moved To QA Week,Moved To Live,oved To Completed,Sprint Week,BusinessTaskMaster ID,Parent BTC ID,Bucket Dev,Bucket BTC Delay,Bucket Tech Pipeline Delays,Bucket TBR Delays,Bucket UAT SignOff Delays,Bucket Tech Issue Delays,Sub Task Exists,Reporting To' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let projectid = index.hasOwnProperty('projectid')?index.projectid:'';
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';  
          let btc = index.hasOwnProperty('btc') ? index.btc == null?'' :index.btc : '';  
          let subbtc = index.hasOwnProperty('subbtc') ? index.btc == null?'' :index.subbtc : ''; 
          let taskname = index.hasOwnProperty('taskname') ? index.taskname == null?'' :index.taskname : ''; 
          let projectmaanager= index.hasOwnProperty('projectmaanager') ? index.projectmaanager == null?'':index.projectmaanager : '';  
          let parenteggmgr = index.hasOwnProperty('parenteggmgr') ? index.parenteggmgr == null?'':index.parenteggmgr : '';
          let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager == null?'':index.programmanager : '';
          let engineeringmaanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager == null?'':index.engineeringmaanager : '';
          let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus : '';
          let parentstatus = index.hasOwnProperty('parentstatus') ? index.parentstatus : '';
          let tasktype = index.hasOwnProperty('tasktype') ? index.tasktype : '';
          let department = index.hasOwnProperty('department') ? index.department : '';
          let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
          let createdby = index.hasOwnProperty('createdby') ? index.createdby == null?'':index.createdby : '';
          let due_date = index.hasOwnProperty('due_date') ? index.due_date : '';
          let startdate = index.hasOwnProperty('startdate') ? index.startdate : '';
          let estimatedddate = index.hasOwnProperty('estimatedddate') ? index.estimatedddate : '';
          let techbusinessreviewdt = index.hasOwnProperty('techbusinessreviewdt') ? index.techbusinessreviewdt : '';
          let techpipelinedate = index.hasOwnProperty('techpipelinedate') ? index.techpipelinedate : '';
          let techwipdt = index.hasOwnProperty('techwipdt') ? index.techwipdt : '';
          let qapipelinedt = index.hasOwnProperty('qapipelinedt') ? index.qapipelinedt : '';
          let qawipdt = index.hasOwnProperty('qawipdt') ? index.qawipdt : '';
          let uatdate = index.hasOwnProperty('uatdate') ? index.uatdate : '';
          let techissueresolutiondt = index.hasOwnProperty('techissueresolutiondt') ? index.techissueresolutiondt : '';
          let uatsignoffdt = index.hasOwnProperty('uatsignoffdt') ? index.uatsignoffdt : '';
          let livedate = index.hasOwnProperty('livedate') ? index.livedate : '';
          let taskestimates = index.hasOwnProperty('taskestimates') ? index.taskestimates : '';
          let estimatedhours = index.hasOwnProperty('estimatedhours') ? index.estimatedhours : '';
          let developmentdelays = index.hasOwnProperty('developmentdelays') ? index.developmentdelays : '';
          let btcdelays = index.hasOwnProperty('btcdelays') ? index.btcdelays : '';
          let techpipelinedelays = index.hasOwnProperty('techpipelinedelays') ? index.techpipelinedelays : '';
          let techissuedelays = index.hasOwnProperty('techissuedelays') ? index.techissuedelays : '';
          let tbrdelays = index.hasOwnProperty('tbrdelays') ? index.tbrdelays : '';
          let uatsignoffdelays = index.hasOwnProperty('uatsignoffdelays') ? index.uatsignoffdelays : '';
          let movedtotbr = index.hasOwnProperty('movedtotbr') ? index.movedtotbr : '';
          let movedtotechwip = index.hasOwnProperty('movedtotechwip') ? index.movedtotechwip : '';
          let movedtotechpipeline = index.hasOwnProperty('movedtotechpipeline') ? index.movedtotechpipeline : '';
          let movedtoqaweek = index.hasOwnProperty('movedtoqaweek') ? index.movedtoqaweek : '';
          let movedtolive = index.hasOwnProperty('movedtolive') ? index.movedtolive : '';
          let movedtocompleted = index.hasOwnProperty('movedtocompleted') ? index.movedtocompleted : '';
          let sprintweek = index.hasOwnProperty('sprintweek') ? index.sprintweek : '';
          let businesstaskmasterid = index.hasOwnProperty('businesstaskmasterid') ? index.businesstaskmasterid : '';
          let parentbtcid = index.hasOwnProperty('parentbtcid') ? index.parentbtcid : '';
          let bucket_dev = index.hasOwnProperty('bucket_dev') ? index.bucket_dev : '';
          let bucket_btcdelays = index.hasOwnProperty('bucket_btcdelays') ? index.bucket_btcdelays : '';
          let bucket_techpipelinedelays = index.hasOwnProperty('bucket_techpipelinedelays') ? index.bucket_techpipelinedelays : '';
          let bucket_tbrdelays = index.hasOwnProperty('bucket_tbrdelays') ? index.bucket_tbrdelays : '';
          let bucket_uatsignoffdelays = index.hasOwnProperty('bucket_uatsignoffdelays') ? index.bucket_uatsignoffdelays : '';
          let bucket_techissuedelays = index.hasOwnProperty('bucket_techissuedelays') ? index.bucket_techissuedelays : '';
          let subtaskexistss = index.hasOwnProperty('subtaskexistss') ? index.subtaskexistss : '';
          let reportingto = index.hasOwnProperty('reportingto') ? index.reportingto : '';
            row += '"' + srno + '",',
            row += '"' + projectid + '",',
            row += '"' + projectname + '",',
            row += '"' + btc + '",',
            row += '"' + subbtc + '",',
            row += '"' + taskname + '",',
            row += '"' + projectmaanager + '",',
            row += '"' + programmanager + '",',
            row += '"' + parenteggmgr + '",',
            row += '"' + engineeringmaanager + '",',
            row += '"' + taskstatus + '",',
            row += '"' + parentstatus + '",',
            row += '"' + tasktype + '",',
            row += '"' + department + '",',
            row += '"' + createddate + '",',
            row += '"' + createdby + '",',
            row += '"' + due_date + '",',
            row += '"' + startdate + '",',
            row += '"' + estimatedddate + '",',
            row += '"' + techbusinessreviewdt + '",',
            row += '"' + techpipelinedate + '",',
            row += '"' + techwipdt + '",',
            row += '"' + qapipelinedt + '",',
            row += '"' + qawipdt + '",',
            row += '"' + uatdate + '",',
            row += '"' + techissueresolutiondt + '",',
            row += '"' + uatsignoffdt + '",',
            row += '"' + livedate + '",',
            row += '"' + taskestimates + '",',
            row += '"' + estimatedhours + '",',
            row += '"' + developmentdelays + '",',   
            row += '"' + btcdelays + '",',
            row += '"' + techpipelinedelays + '",',
            row += '"' + techissuedelays + '",',
            row += '"' + tbrdelays + '",',
            row += '"' + uatsignoffdelays + '",',
            row += '"' + movedtotbr + '",',
            row += '"' + movedtotechwip + '",',
            row += '"' + movedtotechpipeline + '",',
            row += '"' + movedtoqaweek + '",',
            row += '"' + movedtolive + '",',
            row += '"' + movedtocompleted + '",',
            row += '"' + sprintweek + '",',
            row += '"' + businesstaskmasterid + '",',
            row += '"' + parentbtcid + '",',
            row += '"' + bucket_dev + '",',
            row += '"' + bucket_btcdelays + '",',
            row += '"' + bucket_techpipelinedelays + '",',
            row += '"' + bucket_tbrdelays + '",',
            row += '"' + bucket_uatsignoffdelays + '",',
            row += '"' + bucket_techissuedelays + '",',
            row += '"' + subtaskexistss + '",',
            row += '"' + reportingto + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'redprojectlistreport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });     
    }
    },
    getallmanagerlistnewapi(node) {
      if (node && node.length>=3) {
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/searching/getallmanagerlistnewapi',
          'headers':{'authorization':this.tokendata},
          'data': {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            mngname: node.toLowerCase(),
          }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.managersnamelist = result.data.data;
            let decryptdepartmenttext = this.managersnamelist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.managersnamelist = [];
          }
        }).catch(e => {
          this.displayError(e)
        })
      }
    },
    export (data, filename, mime) {
          var data
          let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
          download(blob, filename, mime)
        },
        redirecttourl(state, rowdata) {
          if (state == 'projectId') {
            this.$router.push({
              path: '/project/summary?pid='+rowdata.projectid,
            })
          }
        }
      },
    };
</script>
