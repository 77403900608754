<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="card-navigation">
      <div class="file-manager-content-body">
        <div class="drives">
          
          <div class="row" id="reportTopScroll">
            <div class="col-md-12">
              <div class="card text-center mb-3">
                <div class="card-header p-2">
                  <h4 class="card-title">
                    PMO Daily Report 
                  </h4>
                </div>
                <div class="card-datatable">
                  <div class="" id="myTabContent">
                      <div class="table-responsive" >
                              <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Program Manager Project Details ">
                                <thead>
                                  <tr>
                                      <th scope="col" class="align-middle first-col-sticky" style="min-width:140px;">Activity</th>
                                      <th scope="col" class="align-middle">
                                          2-Feb
                                      </th>
                                      <th scope="col" class="align-middle">
                                          1-Feb
                                      </th>
                                      <th scope="col" class="align-middle">
                                          31-Jan
                                      </th>
                                      <th scope="col" class="align-middle">
                                          30-Jan
                                      </th>
                                      <th scope="col" class="align-middle">
                                          29-Jan
                                      </th>
                                      <th scope="col" class="align-middle">
                                          25-Jan
                                      </th>
                                  </tr>
                                  </thead>
                                  
                                  <tbody>
                             
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Delayed Task
                                      </td>
                                      <td>{{ DelayTaskvalue[0] }}</td>
                                      <td>{{ DelayTaskvalue[1] }}</td>
                                      <td>{{ DelayTaskvalue[2] }}</td>
                                      <td>{{ DelayTaskvalue[3] }}</td>
                                      <td>{{ DelayTaskvalue[4] }}</td>
                                      <td>{{ DelayTaskvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Today Moved to Tech Pipeline
                                      </td>
                                      <td>{{ TechPipelinevalue[0] }}</td>
                                      <td>{{ TechPipelinevalue[1] }}</td>
                                      <td>{{ TechPipelinevalue[2] }}</td>
                                      <td>{{ TechPipelinevalue[3] }}</td>
                                      <td>{{ TechPipelinevalue[4] }}</td>
                                      <td>{{ TechPipelinevalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Today's WBS created
                                      </td>
                                      <td>{{ WBSvalue[0] }}</td>
                                      <td>{{ WBSvalue[1] }}</td>
                                      <td>{{ WBSvalue[2] }}</td>
                                      <td>{{ WBSvalue[3] }}</td>
                                      <td>{{ WBSvalue[4] }}</td>
                                      <td>{{ WBSvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Today Moved to Tech WIP(Plan)
                                      </td>
                                      <td>{{ TechWIPvalue[0] }}</td>
                                      <td>{{ TechWIPvalue[1] }}</td>
                                      <td>{{ TechWIPvalue[2] }}</td>
                                      <td>{{ TechWIPvalue[3] }}</td>
                                      <td>{{ TechWIPvalue[4] }}</td>
                                      <td>{{ TechWIPvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Today Moved to UAT
                                      </td>
                                      <td>{{ UATvalue[0] }}</td>
                                      <td>{{ UATvalue[1] }}</td>
                                      <td>{{ UATvalue[2] }}</td>
                                      <td>{{ UATvalue[3] }}</td>
                                      <td>{{ UATvalue[4] }}</td>
                                      <td>{{ UATvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Pending tasks
                                      </td>
                                      <td>{{ PendingTaskvalue[0] }}</td>
                                      <td>{{ PendingTaskvalue[1] }}</td>
                                      <td>{{ PendingTaskvalue[2] }}</td>
                                      <td>{{ PendingTaskvalue[3] }}</td>
                                      <td>{{ PendingTaskvalue[4] }}</td>
                                      <td>{{ PendingTaskvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Task in Review 	>	2 days				
                                      </td>
                                      <td>{{ Reviewvalue[0] }}</td>
                                      <td>{{ Reviewvalue[1] }}</td>
                                      <td>{{ Reviewvalue[2] }}</td>
                                      <td>{{ Reviewvalue[3] }}</td>
                                      <td>{{ Reviewvalue[4] }}</td>
                                      <td>{{ Reviewvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Tasks in Pipeline >10 days
                                      </td>
                                      <td>{{ PipelineValue[0] }}</td>
                                      <td>{{ PipelineValue[1] }}</td>
                                      <td>{{ PipelineValue[2] }}</td>
                                      <td>{{ PipelineValue[3] }}</td>
                                      <td>{{ PipelineValue[4] }}</td>
                                      <td>{{ PipelineValue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Delay Risk	- Tasks may get delayed				
                                      </td>
                                      <td>{{ DelayRiskvalue[0] }}</td>
                                      <td>{{ DelayRiskvalue[0] }}</td>
                                      <td>{{ DelayRiskvalue[0] }}</td>
                                      <td>{{ DelayRiskvalue[0] }}</td>
                                      <td>{{ DelayRiskvalue[0] }}</td>
                                      <td>{{ DelayRiskvalue[0] }}</td>
                                    </tr>
                                 
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          Repriotised Task		
                                      </td>
                                      <td>{{ RepriotisedTaskvalue[0] }}</td>
                                      <td>{{ RepriotisedTaskvalue[1] }}</td>
                                      <td>{{ RepriotisedTaskvalue[2] }}</td>
                                      <td>{{ RepriotisedTaskvalue[3] }}</td>
                                      <td>{{ RepriotisedTaskvalue[4] }}</td>
                                      <td>{{ RepriotisedTaskvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          In UAT > 10 days		
                                      </td>
                                      <td>{{ InUATvalue[0] }}</td>
                                      <td>{{ InUATvalue[1] }}</td>
                                      <td>{{ InUATvalue[2] }}</td>
                                      <td>{{ InUATvalue[3] }}</td>
                                      <td>{{ InUATvalue[4] }}</td>
                                      <td>{{ InUATvalue[5] }}</td>
                                    </tr>
                                    <tr> 
                                      <td class="capitalisetext font-weight-normal first-col-sticky unique">
                                          UAT Feedback > 5 days		
                                      </td>
                                      <td>{{ UATFeedbackvalue[0] }}</td>
                                      <td>{{ UATFeedbackvalue[1] }}</td>
                                      <td>{{ UATFeedbackvalue[2] }}</td>
                                      <td>{{ UATFeedbackvalue[3] }}</td>
                                      <td>{{ UATFeedbackvalue[4] }}</td>
                                      <td>{{ UATFeedbackvalue[5] }}</td>
                                    </tr>
                                  
                                 
                                </tbody>
                               
                              </table>
                            </div>
                  </div>
                </div>
                
                 
               
               
              </div>
            </div>
          </div>

        </div>
      </div>
        </section>
      </div>
  </div>

</template>

<script>
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "PMODailyReport",
  components: {
    Loading
  },
  data(){
          return {
            DelayTaskvalue :[1,2,0,0,0,0],
            TechPipelinevalue: [0,8,0,2,0,0],
            WBSvalue:[0,10,0,1,1,1],
            TechWIPvalue: [0,10,0,1,1,1],
            UATvalue: [2,0,3,2,1,3],
            PendingTaskvalue:['NA','NA','NA','NA','NA'],
            Reviewvalue:[0,0,1,1,0,0],
            PipelineValue:[4,4,4,4,4,4],
            DelayRiskvalue:[1,1,1,1,1,0],
            RepriotisedTaskvalue:[1,1,2,2,2,2],
            InUATvalue:[10,15,15,18,17,17],
            UATFeedbackvalue:[1,1,1,1,1,1],

          };
        }
};
</script>