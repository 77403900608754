<template>
    <div class="app-content content ">
        <loading  :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper pb-1 mt-1">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-9 px-0">
                        <h4 class="content-header-title float-left mb-0">Appraisal</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Appraisal Rating Report</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2 d-flex align-items-center">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Appraisal Raiting Report</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                    <div class="card-body pt-1">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Form Name</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Form Name" :clear-on-select="true" :multiple="true" v-model="search.formname" :options="trackerlist" @input='selectTname'/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Employee Name/ID</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 4char Of Employee Name/ID" :clear-on-select="true" :multiple="true" v-model="search.empname" :options="employeenamelist" v-on:search-change="getallemplist($event,0)"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Reviewers Name/ID</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 4char Of Reviewers Name/ID" :clear-on-select="true" :multiple="true" v-model="search.reviewersname" :options="reviewersnamelist" v-on:search-change="getallemplist($event,1)"/>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                            <label class="form-label lable-left">Status</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" :clear-on-select="true" :multiple="true" v-model="search.status" :options="statuslist"/>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-8 mb-1 text-right">
                                <button type="move" :disabled="disabledexport" class="btn btn-relief-primary mt-2 ml-1" @click="ExportXL(search)">
                                    <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                                </button>
                                <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="resetRecords()">
                                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,MoreVerticalIcon,EyeIcon,XIcon,ExternalLinkIcon,MonitorIcon } from "vue-feather-icons";
import { SearchIcon } from "vue-feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'AppraisalRaitingReport',
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,MoreVerticalIcon,EyeIcon,
        SearchIcon,XIcon,ExternalLinkIcon,MonitorIcon,
        Treeselect
    },
    mixins: [ commonMethods ],
    data(){
        return{
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            isSearchRequestActive:true,
            search:{
                formname: null,
                empname: null,
                status: null,
                reviewersname: null
            },
            trackerlist:[],
            statuslist:[{
                id:'completed',
                label:'Completed'
            },
            {
                id:'pending',
                label:'Pending'
            }],
            loginuseremail: '',
            pagefeatures:[],
            employeenamelist: [],
            disabledexport: true,
            reviewersnamelist: []
        }
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.loginuseremail = userdata.emailaddress;
                this.undt=userdata
                localStorage.removeItem('searchItems')
                this.gettrackerlist();
            }
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        mysearchrequestollapse:function(){
            this.isSearchRequestActive = !this.isSearchRequestActive
        },
        selectTname(state){
            if(state.length==0){
                this.disabledexport = true;
            }else{
                this.disabledexport = false;
            }
        },
        resetRecords: function() {
            this.search.formname = null;
            this.employeenamelist = [];
            this.search.empname = null;
            this.search.status = null;
            this.reviewersnamelist = [];
            this.search.reviewersname = null;
            localStorage.removeItem('searchItems');
        },
        gettrackerlist() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'Appraisal Cycle',
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getallforms',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.trackerlist = result.data.data.rows
                } else {
                    this.trackerlist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        getallemplist(node,isflag){
            if (node && node.length>2) {
                this.isLoading = true;
                this.inputfield= {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empname: node.toLowerCase()
                }
                axios({
                    'method': 'POST',
                    'url': 'api/master/getemployeebyname',
                    'data': this.inputfield,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                    if(result.data.status == true){
                        if(isflag==0){
                            this.employeenamelist = result.data.data.rows;
                            let decryptdesignationtext = this.employeenamelist.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                        }else if(isflag==1){
                            this.reviewersnamelist = result.data.data.rows;
                            let decryptdesignationtext = this.reviewersnamelist.map(items =>{

                                let tempemplabel1 = items.label;
                                let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                                if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                                }
                                return items;
                            })
                        }
                    }else{
                        this.employeenamelist = [];
                        this.reviewersnamelist = [];
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        ExportXL() {
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'Appraisal Cycle',
                israting: 1,
                limit: null,
                offset: null
            }
            if ((this.search.formname && this.search.formname.length>0) || (this.search.empname && this.search.empname.length>0) || (this.search.status && this.search.status.length>0) || (this.search.reviewersname && this.search.reviewersname.length>0)) {
                this.input.formname = this.search.formname;
                this.input.empname = this.search.empname;
                this.input.status = this.search.status;
                this.input.reviewersname = this.search.reviewersname;
            }
            axios({
                method: "POST",
                url: `api/feedbackform/getfeedbackformreportExport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    let exportDataRes = result.data.data.exportDataRes;
                    let exportDataQueRes = result.data.data.exportDataQueRes;
                    this.exportxlsdata.forEach((element, key) => {
                        exportDataRes.forEach(ele => {
                            if(ele.empid == element.empcode && 
                            ele.masterid == element.masterid && 
                            ele.trackerid == element.trackerid) {
                                if(ele.factor != null){
                                    this.exportxlsdata[key][ele.factor] = {
                                        weightage : ele.weightage,
                                        weightedaverage : ele.weightedaverage,
                                    }
                                }
                            }
                        });
                        exportDataQueRes.forEach(ele1 => {
                            if(ele1.empid == element.empcode && 
                            ele1.masterid == element.masterid && 
                            ele1.trackerid == element.trackerid) {
                                this.exportxlsdata[key][ele1.fieldname] = {
                                    fid : ele1.fieldid,
                                    que : ele1.fieldname,
                                    ans : ele1.fieldvalue,
                                }
                            }
                        });
                    });
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
            var ShowLabel = 'AppraisalRaitingReport'
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#, EMPLOYEE NAME, DESIGNATION, DEPARTMENT NAME, FORM NAME, REPORTING MANAGER NAME, REVIEWERS NAME, STATUS, ASSIGNED DATE, FORM FILLED BY MAILID, SUBMITTED DATE';
                    if(arrData[0].fieldname != "") {
                        CSV += `',${arrData[0].fieldname}'`
                        CSV += `',${arrData[0].factorkeys}'`
                    }
                    CSV += ',GRADE';
                    CSV += ',RATING';
                    CSV += '\r\n';
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ? '' : index.createddate : '';
                let empname = index.hasOwnProperty('empname') ? index.empname == null || index.empname == 'NA' ? '' : index.empname : '';
                let empid = index.hasOwnProperty('empid') ? index.empid == null || index.empid == 'NA' ? '' : index.empid : '';
                // let designation = index.hasOwnProperty('designation') ? index.designation == null || index.designation == 'NA' ? '' : index.designation : '';
                let designation = index.designation ? this.decryptText(index.designation) : ''
                // let department = index.hasOwnProperty('department') ? index.department == null || index.department == 'NA' ? '' : index.department : '';
                let department = index.department ? this.decryptText(index.department) : ''
                let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype == null || index.moduletype == 'NA' ? '' : index.moduletype : '';
                let managername = index.hasOwnProperty('managername') ? index.managername == null || index.managername == 'NA' ? 'NA' : index.managername : 'NA';
                let rating = index.hasOwnProperty('rating') ? index.rating == null || index.rating == 'NA' ? 'NA' : index.rating : 'NA';
                let grade = index.hasOwnProperty('grade') ? index.grade == null || index.grade == 'NA' ? 'NA' : index.grade : 'NA';
                let status = index.hasOwnProperty('status') ? index.status == null || index.status == 'NA' ? '' : index.status : '';
                let reviewersname = index.hasOwnProperty('reviewersname') ? index.reviewersname == null || index.reviewersname == 'NA' ? 'NA' : index.reviewersname : 'NA';
                let lastmodifiedby = 'NA';
                let lastmodifieddate = 'NA';
                if(status=='completed') {
                    lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby == null || index.lastmodifiedby == 'NA' ? 'NA' : index.lastmodifiedby : 'NA';
                    lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate == null || index.lastmodifieddate == 'NA' ? 'NA' : this.dateMonthFormat(index.lastmodifieddate) : 'NA';
                }

                row += '"' + srno + '",',
                row += '"' + empname + ' - ('+ empid +')",',
                row += '"' + designation + '",',
                row += '"' + department.toUpperCase() + '",',
                row += '"' + moduletype + '",',
                row += '"' + managername + '",',
                row += '"' + reviewersname + '",',
                row += '"' + status + '",',
                row += '"' + this.dateMonthFormat(createddate) + '",',            
                row += '"' + lastmodifiedby + '",',            
                row += '"' + lastmodifieddate + '",',      
                
                arrData[0].fieldname.split(',').forEach(element1 => {
                    if(Object.keys(index).includes(element1)){
                        row += index[element1].ans+','
                     } else {
                        row += 'NA,'
                     }
                });
                arrData[0].factorkeys.split(',').forEach(element => {
                    if(Object.keys(index).includes(element)){
                        //row += index[element].weightage+','
                        row += index[element].weightedaverage+','
                    } else {
                        row += 'NA,'
                    }
                });
                row += '"' + grade + '",',      
                row += '"' + rating + '",',      
                row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                return;
                }
                var fileName = 'AppraisalRaitingReport'+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export Report successful",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });   
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        }
    }
}
</script>