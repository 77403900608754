<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-md-10 mobile_margin-px-0_00">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Daily Group Freeze Report</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                     <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <div class="date_picker_foundeds">
                        <label for="">From Date</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select From Date " v-model="search.fromdate" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                          <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-0">
                      <div class="date_picker_foundeds">
                        <label for="">To Date</label>
                        <div class="input-group input-group-merge">
                          
                          <date-picker placeholder="Select To Date " v-model="search.todate" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3">
                    <label class="form-label lable-left">QA Lead</label>
                    <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Lead"  :options="search.qaleadlist" v-model="search.qalead" :multiple="true" v-on:search-change="getProjectQAdata(509,$event)" :clear-on-select="true"/> 
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-3">
                    <label class="form-label lable-left">QA Engineer</label>
                    <treeselect class="projroleassprojectdd" placeholder="Enter min 3 chars to search QA Engineer"  :options="search.qaenglist" v-model="search.qamanager" :multiple="true" v-on:search-change="getProjectQAdata(478,$event)" :clear-on-select="true"/> 
                  </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 mb-0 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getDailyFreezeGroupCount(search)"><search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <button type="move" class="btn btn-relief-primary mt-2 " :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Daily Group Freeze Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive" v-if="this.userData.length>0"  style="overflow: auto;">
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="daily freeze Report">                                    
                                 
                                          <thead>
                                            <tr>
                                              <th scope="col">QA Lead</th>
                                              <th scope="col">QA Engineer</th>
                                              <th scope="col">Execution Type</th>
                                              <th scope="col" style="min-width: 110px;" v-for="date in dateRange" :key="date">
                                                {{ date }}
                                              </th>
                                              <th scope="col">TOTAL</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                        <tr v-for="(user, index) in userData" :key="index">
                                          <td class="capitalisetext" style="min-width: 210px;">{{ user.mngr.split("@")[0].replace(/\./g, " ") }}</td>
                                          <td class="capitalisetext" style="min-width: 210px;">{{ user.email.split("@")[0].replace(/\./g, " ") }}</td>
                                          <td class="capitalisetext" style="min-width: 210px;">{{ user.testexecutiontype }}</td>
                                          <td v-for="date in dateRange" :key="date">
                                            {{ user[date] || 0 }}
                                          </td>
                                          <td>{{ getTotalCountForRow(user) }}</td>
                                        </tr>
                                      </tbody>
                                        </table>
                                      
                              </div>
                              <div v-else class="pageCenter text-center mt-1">
                                <span v-if="search.fromdate == null && search.todate == null">Please apply dates filter to found the data !</span><br/>
                                Data not found..!!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2 text-center">
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>

</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon,UserIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';
export default {
name: "dailygroupfreeze",
mixins: [ commonMethods ],
components: {
  Loading,
  VueElementLoading,
  EyeIcon,
  Treeselect,
  SearchIcon,
  XIcon,
  ExternalLinkIcon,
  Pagination,
  DatePicker,
  UserIcon
},
data() {
   return {
    isBothDateSelected:false,
    projectnameList:[],
    isLoading: false,
    fullPage: true,
    islistLoading:false,
    listfullPage:true,
    companymasterlist: [],
    showprojectdata: [],
    showroledata: [],
    showempdata: [],      
    glbMdl: "",
    glbst: "",
    TimeFormat: apiUrl.TimeFormat,
    zingHrIntimeSources: apiUrl.zingHrIntimeSources,
    glbMdlLbl: "company",
    viewprojectlist: 0,
    totalcount: 0,
    pageCount: 0,
    page: 1,
    pagelimit: apiUrl.LIMIT + 79,
    currPageNum: 0,
    pageoffset: 1,
    pagefeatures: [],
    showtabledata: true,
    companylist: [],
    showproject: false,
    showcompanies: true,
    parentmoduledata: null,
    showfilterboxsection:false,
    isSearchRequestActive:false,
    card_content:'card-content',
    collapse:'collapse',
    addformbtntxt:'Next',
    DailyFreezeGroupCount:[],
    userData: [],
    dateRange: [],
    search:{
        fromdate:null,
        todate:null,
        searchProjectname:null,
        btcname:null,
        qaenglist:[],
        qamanager:null,
        qaleadlist:[],
        qalead:null,
      },
      btclist: [],
      tokendata:null,
      mngrlist:[],
      isresponce:false,
      dateRange: []
      };
  },
  mounted() {
  let permission = this.$Service.verifyAccess();
  if(permission){
    let userdata = window.localStorage.getItem("userdata");
    this.tokendata = window.localStorage.getItem("token");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.undt = userdata;
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  }
},
watch: {
  DailyFreezeGroupCount: {
    handler() {
      this.generateUserData();
    },
      deep: true,
      }
  },
  created() {
      let userdata = window.localStorage.getItem("userdata");
    this.tokendata = window.localStorage.getItem("token");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.undt = userdata;
    }
},
 methods: {
  selectedstartdate() {
      if (this.search.fromdate && this.search.todate) {
      this.isBothDateSelected = false;
      this.dateRange = this.getDatesInRange(this.search.fromdate, this.search.todate);
      } else {
      this.isBothDateSelected = true;
      }
  },
  selectedenddate() {
  if (this.search.fromdate && this.search.todate) {
    this.isBothDateSelected = false;
    this.dateRange = this.getDatesInRange(this.search.fromdate, this.search.todate);
  } else {
    this.isBothDateSelected = true;
    this.dateRange = [];
  }
},
  notBeforeToday(date) {
      this.search.todate = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
  },
  getDatesInRange(fromDate, toDate) {
    let dates = [];
    const current = new Date(fromDate);
    const end = new Date(toDate);
    const oneDay = 24 * 60 * 60 * 1000;

    while (current <= end) {
      dates.push(current.toISOString().split('T')[0]);
      current.setTime(current.getTime() + oneDay);
    }

    return dates;
  },

  getDailyFreezeGroupCount() {
    this.isLoading = true
    let apiUrl = "api/reports/getDailyGroupFreezeCount";
    this.input = {
      useremail: this.undt.username,
      empcode: this.undt.userid,
      qaengineer: this.search.qamanager,
      qalead: this.search.qalead
    };
    this.input.datefrom = this.search.fromdate?this.search.fromdate:null
    this.input.dateto = this.search.todate?this.search.todate:null
    axios({
      method: "POST",
      url: apiUrl,
      data: this.input,
      headers: { authorization: this.tokendata },
    })
      .then((result) => {
          this.isLoading = false

        if (result.data.errorCode == 0) {
          this.DailyFreezeGroupCount = result.data.data;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.populateDateRange();
        } else {
          this.DailyFreezeGroupCount = null;
        }
      })
      .catch(e => {
    this.displayError(e)
    })
  },
  getTotalCountForRow(user) {
    let total = 0;
    this.dateRange.forEach((date) => {
      total += user[date] || 0;
    });
    return total;
  },
  populateDateRange() {
    const uniqueDatesSet = new Set();
    this.DailyFreezeGroupCount.forEach((item) => uniqueDatesSet.add(item.freeze_date));
    this.dateRange = Array.from(uniqueDatesSet).sort((a, b) => new Date(b) - new Date(a));
  },

  getCountForDate(lastmodifiedby, date) {
    const record = this.DailyFreezeGroupCount.find(
      (item) => item.lastmodifiedby === lastmodifiedby && item.freeze_date === date
    );
    return record ? parseInt(record.count_status_freeze) : 0;
  },


generateUserData() {
  this.userData = [];   
  this.DailyFreezeGroupCount.forEach((item) => {
    const existingUserIndex = this.userData.findIndex((user) => user.email === item.lastmodifiedby);
    if (existingUserIndex > -1) {
      const existingUser = this.userData[existingUserIndex];
      if (existingUser[item.freeze_date]) {
        existingUser[item.freeze_date] += parseInt(item.count_status_freeze);
      } else {
        existingUser[item.freeze_date] = parseInt(item.count_status_freeze);
      }
    } else {
      const newUser = {
        email: item.lastmodifiedby,
        mngr: item.mngrid,
        testexecutiontype:item.testexecutiontype,
        [item.freeze_date]: parseInt(item.count_status_freeze),
      };
      this.userData.push(newUser);
    }
  });
},

  getTotalCountByUser(lastModifiedBy) {
    const user = this.DailyFreezeGroupCount.find((user) => user.email === lastModifiedBy);
    if (user) {
      return this.dateRange.reduce((total, date) => {
        return total + (Number(user[date]) || 0);
      }, 0);
    }
    return 0;
  },

  disabledBefore(date) {
      const oneMonthFromFromDate = moment(this.search.fromdate).add(1, 'month');
      const startOfOneMonth = oneMonthFromFromDate.startOf('day').toDate();
      const startOfFromDate = moment(this.search.fromdate).startOf('day').toDate();

      return date > startOfOneMonth || date < startOfFromDate;
  },


  mysearchrequestollapse:function(){
    this.isSearchRequestActive=!this.isSearchRequestActive
  },
  resetRecords: function () {
      this.search.searchProjectname = null;
      this.search.btcname = null;
      this.search.qamanager = null;
      this.search.qalead = null;
      this.search.fromdate = null;
      this.search.todate = null;
      this.userData = [];

      // this.getDailyFreezeGroupCount()
      this.isBothDateSelected = false
      let rowdata={}
  },
  getBTCListbyProject(projectid) {
    let apiUrl = "api/reports/getBTCbyProject"
    this.input = {
      projectid: projectid,
      useremail: this.undt.username,
      empcode: this.undt.userid,
    }
    axios({
          'method': 'POST',
          'url': apiUrl,
          'data': this.input,
          'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        if(result.data.errorCode == 0) {
          this.btclist = result.data.data;
        } else {
          this.btclist = null
        }
      }).catch(e => {
    this.displayError(e)
    })
  },
  getProjectQAdata(roleid,node){
      let inputappl = {
          // projectid: this.projectid,
          empcode: this.undt.userid,
          useremail: this.undt.username,
          roleid:roleid,
          node: (node != null && node != '' && node.length > 2)? node : null,
      }
      if((node != null && node.length>=2)) {
      axios({
      'method': 'POST',
      'url': 'api/reports/getGroupManagerDetails',
      'data': inputappl,
      'headers':{'authorization':this.tokendata}
      })
      .then(result => { 
          this.isLoading= false;         
          if(result.data.errorCode == 0){
              if(roleid==509){
                  this.search.qaleadlist=result.data.data.rows;
              }else if (roleid==478){
                  this.search.qaenglist=result.data.data.rows;
              }
          }else{
              if(roleid==509){
                  this.search.qaleadlist=[]
              }else if (roleid==478){
                  this.search.qaenglist=[];
              }
          }
      }).catch(e => {
    this.displayError(e)
    })
      }else{
        this.search.qaleadlist=[]
        this.search.qaenglist=[];
      }
  },
  projectChange: function (node, instanceId){
    if(node && node.length >1){
      
      this.isLoading = true;
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        projectname:node.toLowerCase(),
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectnameList = result.data.data;
        } else {
          this.projectnameList = [];
        }
      }).catch(e => {
    this.displayError(e)
    })
    }
  },

  clickCallback: function (pageNum) {
    this.pageoffset = pageNum;
    this.currPageNum = pageNum - 1;
    let arrGlbMdl = this.glbMdl.split("##");
    let inputEle = "";
    let btnEle = "";
    if (arrGlbMdl.length > 0) {
      if (typeof arrGlbMdl[0] != "undefined") {
        inputEle = arrGlbMdl[0];
      }
      if (typeof arrGlbMdl[1] != "undefined") {
        btnEle = arrGlbMdl[1];
      }
    }
    if (inputEle == "t22") {
      this.customlisting(inputEle, "");
    } else {
      this.parentmoduledata={}
      this.parentmoduledata.isPaginationreq=true

    }
    setTimeout(function(){
          $('html, body').animate({
              scrollTop: $("#reportTopScroll").offset().top -70
          });
      }, 500);
  },
  dateFormat(value) {
    if (!value) return "";
    return moment(String(value)).format('DD-MM-YYYY');
  },
  dateFormat2(value) {
    if (!value) return "";
    return moment(String(value)).format('DD-MM-YYYY HH:mm');
  },
  dateMONFormat(value){
    if (value) {
      return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
    } else{
      return "";
    }
  },

  timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1]/6)*10, 10);
    return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
  },
  dateFormat2(value) {
    if (!value) return "";
    return moment(String(value)).format('DD-MM-YYYY');
  },
  prepareExportData() {
  const exportData = [];
  const userCounts = {};

  this.DailyFreezeGroupCount.forEach((item) => {
    const { lastmodifiedby, mngrid,testexecutiontype, freeze_date, count_status_freeze } = item;
    const key = `${lastmodifiedby}_${mngrid}_${testexecutiontype}_${freeze_date}`;

    if (userCounts[key]) {
      userCounts[key] += parseInt(count_status_freeze);
    } else {
      userCounts[key] = parseInt(count_status_freeze);
    }
  });

  for (const key in userCounts) {
    const [lastmodifiedby, mngrid, testexecutiontype,freeze_date] = key.split('_');
    const count_status_freeze = userCounts[key];

    const existingIndex = exportData.findIndex((item) => item.lastmodifiedby === lastmodifiedby && item.mngrid === mngrid);

    if (existingIndex > -1) {
      if (exportData[existingIndex][freeze_date]) {
        exportData[existingIndex][freeze_date] += count_status_freeze;
      } else {
        exportData[existingIndex][freeze_date] = count_status_freeze;
      }

      exportData[existingIndex]['Total'] += count_status_freeze;
    } else {
      const newUser = {
        lastmodifiedby,
        mngrid,
        testexecutiontype,
        [freeze_date]: count_status_freeze,
        'Total': count_status_freeze,
      };
      exportData.push(newUser);
    }
  }

  this.exportData = exportData;
},


  ExportXL() {
    this.isLoading = true;
    this.exportxlsdata = [];
    this.exporterr = "";
    this.input = {
      empcode: this.undt.userid,
      useremail: this.undt.username,
      qaengineer: this.search.qamanager,
      qalead: this.search.qalead
    };
    this.input.datefrom = this.search.fromdate?this.search.fromdate:null
    this.input.dateto = this.search.todate?this.search.todate:null
    axios({
      method: "POST",
      url: `api/reports/getDailyGroupFreezeCount`,
      data: this.input,
      'headers':{'authorization':this.tokendata}
    }).then((result) => {
      this.isLoading = false;
      if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data;
          this.prepareExportData();
          var filename = "";
          this.download(this.exportData, filename);
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      }
    }).catch(e => {
    this.displayError(e)
    })
  },
  download(readExportdata, filename) {
  if (readExportdata.length > 0) {
    var ShowLabel = 'DailyGroupFreeze' + filename;
    var JSONData = readExportdata;
    var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';

    function formatDateForCSVHeader(date) {
      const parts = date.split('-');
      return parts[2] + '-' + parts[1] + '-' + parts[0];
    }

    if (ShowLabel) {
      let row = 'Sr#,QA Lead,QA Engineer,Execution Type,';
      for (let date of this.dateRange) {
        row += formatDateForCSVHeader(date) + ',';
      }
      row += 'Total,\r\n';
      CSV += row;
    }

    for (let i = 0; i < arrData.length; i++) {
      let index = arrData[i];
      let srno = i + 1;
      let mngrid = index.hasOwnProperty('mngrid') ? (index.mngrid == null || index.mngrid == 'NA' ? '' : index.mngrid) : '';
      let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? (index.lastmodifiedby == null || index.lastmodifiedby == 'NA' ? '' : index.lastmodifiedby) : '';
      let testexecutiontype = index.hasOwnProperty('testexecutiontype') ? (index.testexecutiontype == null || index.testexecutiontype == 'NA' ? '' : index.testexecutiontype) : '';
      let totalCountForRow = index['Total'] || 0; 

      let row = ''; 
      row += '"' + srno + '",';
      row += '"' + mngrid + '",';
      row += '"' + lastmodifiedby + '",';
      row += '"' + testexecutiontype + '",';
      
      for (let date of this.dateRange) {
        let countForDate = index[date] || 0;
        row += '"' + countForDate + '",';
      }

      row += '"' + totalCountForRow + '",'; 
      row += '\r\n';

      CSV += row; 
    }

    if (CSV === '') {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });
      return;
    }

    var fileName = 'DailyGroupFreeze' + filename + '_' + moment().format('DD_MM_YY_HH_mm_ss');
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    var link = document.createElement('a');
    link.href = uri;
    link.style = 'visibility:hidden';
    link.download = fileName + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    Swal.fire({
      title: "Success!",
      text: "Export Report Successful",
      icon: "success",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: !1,
    });
  } else {
    Swal.fire({
      title: "",
      text: "Data not found",
      icon: "info",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: !1,
    });
  }
},
 export (data, filename, mime) {
    var data
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    download(blob, filename, mime)
  },
},
};
</script>