<template>
    <div class="app-content content ">
        <loading :active.sync="isLoading" :can-cancel="false"  :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <loading :active.sync="isDashboardLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section class="pb-1" id="modern-horizontal-wizard">
                <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
                    <div class="container-fluid">
                        <div class="row breadcrumbs-top">
                        <div class="col-9 col-md-9 content-header-left px-0">
                            <h3 class="content-header-title float-left mb-0">Business Task Master</h3>
                            <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/businesstask/list'>Business Task Master List</router-link></li>
                            </ol>
                            </div>
                        </div>
                        <div  class="ml-auto px-0 text-right mobile_margin-px-0_00" >
                                <div  v-if="pagefeatures.listingconfig" class="btn btn-sm btn-outline-primary  mr-1"  @click="toggleVisibility" style="padding:5px 8px;">
                                    <div class="avatar-content">
                                        <settings-icon size="1x"  class="custom-class avatar-icon font-medium-3"></settings-icon>
                                    </div>
                                </div>  
                               
                                <div v-if="pagefeatures.createbusinesstask" class="input-group-append float-right">
                                    <router-link to="/businesstask/create"><button id="commonaddmodule" class="btn btn-sm bg_design_btn-relief-success "   data-backdrop="static" data-keyboard="false"> 
                                     <span class="mobile_hide_create_text">Create Business Task</span> <plus-circle-icon size="1.5x" class="custom-class">
                                    </plus-circle-icon></button></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" v-show="isVisible">
                    <ListingConfig />
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;border-radius: 15px 15px 0px 0px;">
                   <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                       <div class="container-fluid">
                        <div class="row mobile_co_mainheader_text">
                            <div class="w-auto px-1 mobile_margin-px-0_00 mobile_w_calc_38">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1 mobile_width-86">Search Business Task Master Details</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                        <div class="card-body px-sm-mobile-10">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Business Task Code</label>
                                    <input type="text" class="form-control" placeholder="Enter Businesstask Code"  v-model="search.searchBusinesstaskcode"  v-on:search-change="businessCodeChangeSearch" @input="clearCodeList" :maxlength="maxLength"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Business Task Name </label>
                                    <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search Business Task Name" v-model="search.searchBusinesstaskname" :options="search.businesstasknameopts" v-on:search-change="businessNameChangeSearch" @input="clearBusinessNameList"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Project Name</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="search.searchprojectname" :multiple="true" :clear-on-select="true" :options="search.projectnameopts" @input="selprojdata"/>
                                </div>
                                <div v-if="this.search.projectid" class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label for="projectlistdd">Project Module</label>
                                    <treeselect placeholder="Select Project Module" class="projectmodule" :multiple="true"  :clear-on-select="true" v-model="search.searchprojectmodule" :options="tasktype.projectmodulelist"/>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label" for="">Project Manager</label>
                                    <treeselect class="projroleassprojectdd" placeholder="Select Project Manager" :options="tasktype.pmlist" v-model="search.proman" :multiple="true" :clear-on-select="true"/> 
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Task Status</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Task Status" v-model="search.searchProjectstatus" :options="tasktype.searchtaskstatus" :multiple="true" />
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label" for="">Task Owner</label>
                                    <treeselect class="projroleassprojectdd" placeholder="Select Task Owner" :options="tasktype.taskownerlist" v-model="search.taskownername" :multiple="true" :clear-on-select="true"/> 
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label" for="">Task Manager</label>
                                    <treeselect class="projroleassprojectdd" placeholder="Select Task Manager" :options="tasktype.taskmanager" v-model="search.taskmngr" :multiple="true" :clear-on-select="true"/> 
                                </div>
                               
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Created-By</label>
                                    <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search Created By" v-model="search.searchProjectcreatedby" :multiple="true" :clear-on-select="true" :options="search.projectcreatedbyopts" v-on:search-change="createdByChangeSearch"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Task Type</label>
                                    <treeselect v-if="pagefeatures.admintypelist" class="projectmdllistdd" placeholder="Select Task Type" v-model="search.searchTasktype" :options="tasktype.admintypelist" :multiple="true" :clear-on-select="true"/>
                                    <treeselect v-else class="projectmdllistdd" placeholder="Select Task Type" v-model="search.searchTasktype" :options="tasktype.typelist" :multiple="true" :clear-on-select="true"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">BTC View Type</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select BTC View Type" v-model="search.btcviewtype" :options="search.btcviewtypelist" @input="selectedBTCView"/>
                                    <div class="errorinputmsg" v-if="!this.search.btcviewtype">{{ this.btcviewtypeErr }}</div> 
                                </div>

                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <div class="cycle_viewmoretext" style="text-align: left !important;padding-top: 12%;">
                                        <a class="btn readmore" :id="'ssjudtid'">
                                        <strong :id="'ssjudtidid5'" @click="securityJustReadmore()" style="font-size: 11px;">
                                            See all
                                        </strong>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="row" id="otherfields" style="display: none;">
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label for="Department">Business Project Name</label>
                                    <treeselect placeholder="Select Business Project Name" :options="search.businessnamelist" v-model="search.businessname" :multiple="true" :clear-on-select="true"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Redmine/Reference Id</label>
                                    <input type="text" class="form-control" placeholder="Enter Redmine/Reference Id"  v-model="search.redmineid"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Department</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Department" v-model="search.searchDepartment" @input="selectedBTCDEP" :options="tasktype.deptlist" :multiple="true" :clear-on-select="true"/>
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label" for="bsh">HOD Name</label>
                                    <treeselect class="projroleassprojectdd" placeholder="Select HOD Name " :options="bshlist" v-model="search.bsh" :multiple="true" :clear-on-select="true"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1 ">
                                    <label for="projectlistdd">Requester</label>
                                    <treeselect class="projectlistdd capitalisetext" placeholder="Enter min 4 chars to search requester name" :clear-on-select="true" v-model="search.requester" @input="selRequester" :options="requesterlist" v-on:search-change="getRequesterOnSearch"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1 ">
                                    <label for="projectlistdd">Requester HOD</label>
                                    <treeselect class="projectlistdd capitalisetext" placeholder="Select Requester HOD Name" :clear-on-select="true" v-model="search.requester_hod" :options="requesterhodlist" />
                                </div>

                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label" for="">Program Manager</label>
                                    <treeselect class="projroleassprojectdd" placeholder="Select Program Manager" :options="tasktype.proglist" v-model="search.progman" :multiple="true" :clear-on-select="true"/> 
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label for="Department">Engineering Manager</label>
                                    <treeselect placeholder="Select Engineering Manager" :options="tasktype.emlist" v-model="search.engman" :multiple="true" :clear-on-select="true"/>
                                </div>
                                
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label" for="">QA Manager</label>
                                    <treeselect class="projroleassprojectdd" placeholder="Select QA Manager" :options="tasktype.qamnglist" v-model="search.qamanager" :multiple="true" :clear-on-select="true"/> 
                                </div>
                                <div class="form-group col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label" for="">QA Lead</label>
                                    <treeselect class="projroleassprojectdd" placeholder="Select QA Lead" :options="tasktype.qaleadlist" v-model="search.qalead" :multiple="true" :clear-on-select="true"/> 
                                </div>
                                
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Task Severity</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Task Severity" v-model="search.searchTaskseverity" :options="tasktype.keylist"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Priority</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Priority" v-model="search.searchPriority" :options="search.priorityopts"/>
                                </div>
                                
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Ballpark Estimate in days</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Ballpark Estimate in days" v-model="search.searchEstimates" :options="tasktype.searchEstimatesopts" />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label lable-left">Is Redmine/Reference</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Redmine/Reference Filter" v-model="search.isredmine" :options="search.redminefilter"/>
                                </div>
                                
                                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3">
                                    <label for="">Business Task Start Date From</label>
                                        <date-picker placeholder="Select BusinessTask Start Date From" v-model="search.startdatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                                    <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                                </div>
                                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-3">
                                    <label for="">Business Task Start Date To</label>
                                        <date-picker placeholder="Select BusinessTask Start Date To" class="startdate" v-model="search.startdateto" :disabled-date="disabledBefore" valueType="format" @input="selectedtodate"></date-picker>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="pagefeatures.pendigapproval">
                                    <label class="form-label lable-left">Pending For Approval</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select " v-model="search.ispending" :options="tasktype.pending"/>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="pagefeatures.country_dept">
                                    <label class="form-label lable-left">Project Country</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Country Name" :options="tasktype.countrylist" v-model="search.country" :multiple="true" />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="pagefeatures.country_dept">
                                    <label class="form-label lable-left">User Department</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select User Department" :options="tasktype.userdeptlist" v-model="search.userdept" :multiple="true" />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1" v-if="pagefeatures.btcdeliverable">
                                    <label class="form-label lable-left">Business Task Delayed or Deliverable</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Business Task Delayed or Deliverable" v-model="search.searchBusinesstaskstatus" :options="search.btcstatusfilter"/>
                                </div>
                                
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <div class="form-group">
                                        <label for="projectmdllistdd">Billing Country</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Billing Country" v-model="search.billingcountry" :options="search.billingcountrylist" :multiple="true" />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <div class="form-group">
                                        <label for="projectmdllistdd">Planner Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 3 Chars To Search  Planner Name" v-model="search.plannersrch" v-on:search-change="getsrchPlannerList" :options="srchplannerlist" :multiple="true" :clear-on-select="true"  />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <div class="form-group">
                                        <label for="projectmdllistdd">Business Context
                                            <BTCcontexttext/>
                                        </label>
                                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Business Context" v-model="search.businesscontext" :options="search.businesscontextList" :multiple="true" />
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                                    <label class="form-label">Security Sensitive</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Security Sensitive" v-model="search.securitytag" :multiple="true" :options="securitylist"/>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-12 text-right mb-1">
                                <button v-if="isListActive" type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="(isBothDateSelected || isBTCviewtypeSelected)" @click="masterlistnew('t9', search,1)"> <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                                <button v-else type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="(isBothDateSelected || isBTCviewtypeSelected)" @click="masterlist('t9', search,1)"> <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                                <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                                <button v-if="this.isListDetailsDataActives==false" type="move" class="btn btn-relief-primary mt-2 mr-1" :disabled="(isBothDateSelected || isBTCviewtypeSelected)" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>                           
                                <button v-if="pagefeatures.bulkpriority" type="" class="btn btn-relief-primary mt-2 mr-1" :disabled="(isBothDateSelected || isBTCviewtypeSelected)" @click="resetBTPriority()"><refresh-ccw-icon size="1.5x" class="custom-class"></refresh-ccw-icon> <span>Reset Priority</span></button> 
                                <MilestoneBtcReport :search="this.search"></MilestoneBtcReport>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;border-radius: 15px 15px 0px 0px;">
                   <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="businessTaskDashboardCollapse(search, searchactiveflag)">
                       <div class="container-fluid">
                        <div class="row mobile_co_mainheader_text">
                            <div class="w-auto px-1 mobile_margin-px-0_00 mobile_w_calc_38">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <home-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></home-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1 mobile_width-86">Business Task Dashboard</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isBTDashboardActive},card_content,collapse]">
                        <div class="card-body px-sm-mobile-10">
                            <section id="dashboard-ecommerce">
                              <div class="card card-statistics" >
                                <div class="">
                                  <div class="dark_theme_style_table">
                                    <div class="table-responsive mb-1" >
                                      <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc" style="border-radius: 10px;margin-bottom:5px;">
                                        <thead class="d-none">
                                            <th scope="col"> </th>
                                        </thead>
                                        <tbody>
                                            <tr class="header-primary" >
                                              <td class="text-center first-col-sticky" style="min-width: 220px;"><strong>Type / Status</strong></td>
                                              <td class="text-center" v-for="(values, key) in tasktype.searchtaskstatus">
                                                    <strong>{{values.label}}</strong>
                                              </td>
                                              <td class="text-center"><strong>Grand Total</strong></td>
                                            </tr>
                                            <tr class="row1 " v-for="(valuess, keyss) in businessTaskDetails">
                                              <td class="text-left first-col-sticky">
                                                <strong>{{valuess.tasktype}}</strong>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Product Pipeline', valuess.tasktype)" v-if='valuess.productpipeline' style="cursor:pointer;">{{valuess.productpipeline}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Product WIP', valuess.tasktype)" v-if='valuess.productwip' style="cursor:pointer;">{{valuess.productwip}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Product Completed', valuess.tasktype)" v-if='valuess.productcomplete' style="cursor:pointer;">{{valuess.productcomplete}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Product WIP 2', valuess.tasktype)" v-if='valuess.productwip2' style="cursor:pointer;">{{valuess.productwip2}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Tech and Business Review', valuess.tasktype)" v-if='valuess.techreview' style="cursor:pointer;">{{valuess.techreview}}</span>
                                                  <span v-else>0</span>
                                                </td>
                                                <td class="text-center">
                                                  <span @click="countClickableToCount('Estimation Review', valuess.tasktype)" v-if='valuess.estimationreview' style="cursor:pointer;">{{valuess.estimationreview}}</span>
                                                  <span v-else>0</span>
                                                </td>
                                                <td class="text-center">
                                                  <span @click="countClickableToCount('Tech Pipeline', valuess.tasktype)" v-if='valuess.techpipeline' style="cursor:pointer;">{{valuess.techpipeline}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Tech WIP', valuess.tasktype)" v-if='valuess.techwip' style="cursor:pointer;">{{valuess.techwip}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('QA Pipeline', valuess.tasktype)" v-if='valuess.qapipeline' style="cursor:pointer;">{{valuess.qapipeline}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('QA WIP', valuess.tasktype)" v-if='valuess.qawip' style="cursor:pointer;">{{valuess.qawip}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Tech Issue Resolution', valuess.tasktype)" v-if='valuess.techissueresolution' style="cursor:pointer;">{{valuess.techissueresolution}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Design Review', valuess.tasktype)" v-if='valuess.designreview' style="cursor:pointer;">{{valuess.designreview}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('UAT', valuess.tasktype)" v-if='valuess.uat' style="cursor:pointer;">{{valuess.uat}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('UAT Feedback', valuess.tasktype)" v-if='valuess.uatfeedback' style="cursor:pointer;">{{valuess.uatfeedback}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('UAT Signoff', valuess.tasktype)" v-if='valuess.uatsignoff' style="cursor:pointer;">{{valuess.uatsignoff}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Live', valuess.tasktype)" v-if='valuess.live' style="cursor:pointer;">{{valuess.live}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Completed', valuess.tasktype)" v-if='valuess.completed' style="cursor:pointer;">{{valuess.completed}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('OnHold', valuess.tasktype)" v-if='valuess.onhold' style="cursor:pointer;">{{valuess.onhold}}</span>
                                                  <span v-else>0</span>
                                              </td>
                                              <td class="text-center">
                                                  <span @click="countClickableToCount('Discarded', valuess.tasktype)" v-if='valuess.discarded' style="cursor:pointer;">{{valuess.discarded}}
                                                </span>
                                                  <span v-else>0</span>
                                              </td>                                                                          <td class="text-center">
                                                  <span v-if='valuess.columntotal'><strong>{{valuess.columntotal}}</strong></span>
                                                  <span v-else>0</span>
                                              </td>                                  
                                            </tr>

                                            <tr v-if="businessTaskDetailsSum" class="row1">
                                                <td class="text-left first-col-sticky">
                                                  <strong>Grand Total</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.productpipelineSum != undefined">{{businessTaskDetailsSum.productpipelineSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.productwipSum">{{businessTaskDetailsSum.productwipSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.productcompleteSum">{{businessTaskDetailsSum.productcompleteSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.productwip2Sum">{{businessTaskDetailsSum.productwip2Sum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.techreviewSum">{{businessTaskDetailsSum.techreviewSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.estimationreviewSum">{{businessTaskDetailsSum.estimationreviewSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.techpipelineSum">{{businessTaskDetailsSum.techpipelineSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.techwipSum">{{businessTaskDetailsSum.techwipSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.qapipelineSum">{{businessTaskDetailsSum.qapipelineSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.qawipSum">{{businessTaskDetailsSum.qawipSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.techissueresolutionSum">{{businessTaskDetailsSum.techissueresolutionSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.designreviewSum">{{businessTaskDetailsSum.designreviewSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.uatSum">{{businessTaskDetailsSum.uatSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.uatfeedbackSum">{{businessTaskDetailsSum.uatfeedbackSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.uatsignoffSum">{{businessTaskDetailsSum.uatsignoffSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.liveSum">{{businessTaskDetailsSum.liveSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.completedSum">{{businessTaskDetailsSum.completedSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.onholdSum">{{businessTaskDetailsSum.onholdSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.discardedSum">{{businessTaskDetailsSum.discardedSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                                <td class="text-center">
                                                  <strong v-if="businessTaskDetailsSum.columntotalSum">{{businessTaskDetailsSum.columntotalSum}}</strong>
                                                  <strong v-else>0</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                      </table>       
                                    </div>
                                  </div>
                                </div>
                              </div>
                                          
                            </section>
                        </div>
                    </div>
                </div>
                <div id="scrollToListGrid"></div>
                <div class="row">
                    <div class="col-12 taskmaster_tabbing_das022">
                        <ul class="listGrid float-right d-inline-flex px-sm-mobile-10 taskmaster_tabbing_das03">
                           
                            <li class="cursorpointercls assign_Planner_padd" @click="alert(isChecked)" v-if="pagefeatures.asignplanner">
                                <button type="submit" class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect"  :disabled="!isChecked.some(bool => bool === true)"><span>Assign Planner</span>
                                </button>                    
                            </li>

                            <li class="cursorpointercls"  @click="myListView()" >
                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activelistlink}]"  title="List View" data-original-title="List View">
                                <list-icon size="1.5x" class="custom-class mr-0 "></list-icon>
                                <span>
                                List View
                                </span>
                            </button>
                            </li>
                            
                            <li class=" cursorpointercls" @click="myGridView()" >
                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect" v-bind:class="[{active:activegridlink}]"  title="Grid View" data-original-title="Grid View">
                                <grid-icon size="1.5x" class="custom-class mr-0 "></grid-icon>
                                <span>
                                Grid View
                                </span>
                            </button>
                            </li>
                            <li class="cursorpointercls" @click="boardShowView()" v-if="pagefeatures.kanbanview" > 
                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect"   title="Kanban View" data-original-title="Kanban View" v-bind:class="[{active:activeboardlink}]">
                                <em class="las la-chalkboard-teacher custom-class mr-0 avatar-icon font-medium-1"></em>
                                <span>
                                Kanban View
                                </span>
                            </button>
                            </li>
                            
                            <li class="cursorpointercls" @click="chartShowView()" v-if="pagefeatures.chartview" > 
                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect"   title="List View" data-original-title="List View">
                                
                                <em class="las la-chart-pie custom-class mr-0 avatar-icon font-medium-1"></em>
                                <span>
                                Chart
                                
                                </span>
                            </button>
                            </li>
                            <li class="cursorpointercls" @click="calendarShowView()" v-if="pagefeatures.calendarview" > 
                            <button class="btn btn-sm btn-outline-primary view-btn grid-view-btn waves-effect"   title="Calendar View" data-original-title="calendar">
                                <em class="las la-calendar custom-class mr-0 avatar-icon font-medium-1"></em>
                                <span>
                                Calendar View
                                
                                </span>
                            </button>
                            </li>
                            

                        </ul>
                    </div>
                    </div>
                    
                <div v-bind:class="[blog_list_wrapper,{displayblk:isgridView}]">
                    <div class="dw mx-n1">
                        <div class="dw-panel p-1"  v-for="(data,index) in companymasterlist" v-bind:key="index">
                        <div class="card border-warning card_style_box_shadow1" style=" margin-bottom: 0rem !important;">
                        <div v-if="btcviewtype == 'Parent BTC'" class="card-header p-1">
                            <h4 v-if="data.projectnameist" class="card-title">{{(data.projectnameist).toLowerCase()}} : {{data.businesstaskcode}} </h4>
                        </div>
                        <div v-else class="card-header p-1">
                            <h4 v-if="data.projectname" class="card-title">{{(data.projectname).toLowerCase()}} : {{data.businesstaskcode}} </h4>
                        </div>
                        <div class="card" style="margin-bottom:0 !important;">
                            <div class="card-body p-1">
                                <h4 class="card-title mb-1">
                                    <span v-if="data.modulename.length > 50"  class="blog-title-truncate text-body-heading ">
                                        {{(data.modulename.substring(0,51)).toLowerCase()+ ' ...'}}
                                    </span>
                                    <span v-else  class="blog-title-truncate text-body-heading ">
                                        {{(data.modulename).toLowerCase()}}
                                    </span>
                                </h4>
                                <div class="media">
                                    <div v-if="activegridlink == true" class="avatar mr-50">
                                        <profilepic v-if="data.imagename && data.employeecode" v-bind:get-url="'empprofile/'+data.employeecode+'/'+data.imagename" :key="`${componentKey + index}`"></profilepic>
                                        <img alt=""  v-if="!data.imagename" :src="defaultimagepath"  width="24" height="24" />
                                    </div>
                                    <div class="media-body">
                                        <small class="text-muted mr-25">by</small>
                                        <small><a href="javascript:void(0);" @click="redirecttoprofile(data.creatorid)" class="capitalisetext">{{data.creator?data.creator:''}}</a></small>
                                        <span class="text-muted ml-50 mr-25">|</span>
                                        <small class="text-muted">{{dateMonthFormat(data.createddate)}}</small>
                                    </div>
                                </div>
                                <div class="my-1">
                                    <div v-if="data.subpriority" class="capitalisetext badge badge-pill badge-light-info mr-50 mb-25">{{data.subpriority}}</div>
                                    <div v-else class="capitalisetext  mr-50 mb-25" v-bind:class="{'badge badge-pill badge-light-success': Boolean(data.subpriority)}">{{data.subpriority ? data.subpriority : 'NA'}}</div>

                                    <div v-if="data.taskkey.toLowerCase()=='key' || data.taskkey.toLowerCase()=='urgent'" class="badge badge-pill badge-light-danger mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                    <div  v-if="data.taskkey.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                    <div v-if="data.taskkey.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                    <div v-if="data.taskkey.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                    <div v-if="data.taskkey.toLowerCase()=='normal'" class="badge badge-pill badge-light-success mr-50 mb-25 capitalisetext">{{data.taskkey}}</div>
                                    <div class="badge badge-pill badge-light-primary capitalisetext mr-50 mb-25">{{data.taskstatus}}</div>
                                    <div v-if="data.tasktype=='Business Task'" class="badge badge-pill badge-light-success capitalisetext">Business Task - New Feature</div>
                                    <div v-else-if="data.tasktype=='Enhancement'" class="badge badge-pill badge-light-success capitalisetext">Business Task - Enhancement</div>
                                    <div v-else class="badge badge-pill badge-light-primary capitalisetext">{{data.tasktype}}</div>
                                    <div v-if="(data.isapproved_edd && data.isapproved_edd == 'Pending')" class="badge badge-pill badge-light-warning mr-50 capitalisetext">Pending</div>
                                </div>
                                <div class="card-text blog-content-truncate" style="overflow:hidden">
                                    <span v-if="data.moduledesc && data.moduledesc.length>150" v-html="data.moduledesc.substring(0, 151) + ' ...'"></span>
                                    <span v-else v-html="data.moduledesc"></span>
                                </div>
                                <hr style="cursor:pointer;margin:0;" v-if="data.estimatedddate && (data.taskstatus != 'Completed' && data.taskstatus != 'Discarded' && data.taskstatus != 'OnHold' && data.taskstatus != 'Live')" class="mx-n1 mt-0" />
                                <div v-if="data.estimatedddate && (data.taskstatus != 'Completed' && data.taskstatus != 'Discarded' && data.taskstatus != 'OnHold' && data.taskstatus != 'Live')" style="cursor:pointer" class="row text-center mx-0">
                                    <div class="col-12" style="padding: 8px;">
                                        <p class="card-text text-muted mb-0" style="font-size: 10px">End date : {{dateMonthFormat(data.estimatedddate)}}</p>
                                        <p style="font-size: 11px" class="font-weight-bolder mb-0">
                                            <span v-if="data.edddelayed == null">Ageing : {{data.createddateaeging}} | Deliverable BY : {{data.edddeliverable}}</span>
                                            <span v-if="data.edddelayed != null">Ageing : {{data.createddateaeging}} | Delayed BY : {{data.edddelayed}}</span>
                                        </p>
                                    </div>
                                </div>
                                <hr class="mx-n1 mt-0" />
                                <div class="d-flex justify-content-between align-items-center">
                                    <a v-if="pagefeatures.editbusinesstask || loginusername==data.createdby || loginpersonname==data.projectmaanager || loginpersonname==data.qamanager || loginpersonname==data.qalead || loginpersonname==data.productstackholder || loginpersonname==data.taskowner || loginpersonname==data.engineeringmaanager || loginpersonname==data.programmanager" @click="redirecttourl('edit',data)" href="javascript:void(0);" >
                                        <div class="d-flex align-items-center sitebluecolor">
                                            <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                            <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                                        </div>
                                    </a>
                                    <a v-on:click="getBusinessTaskVersion(parseInt(data.moduleid), data.modulename, data.createdby, data.createddate)" href="javascript:void(0);">
                                        <div class="d-flex align-items-center sitebluecolor ml-1"> 
                                            <alert-circle-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></alert-circle-icon>
                                        <span class="text-body font-weight-bold">Task History</span>
                                        </div>
                                    </a>
                                    <a @click="redirecttourl('view',data)" href="javascript:void(0);">
                                        <div class="d-flex align-items-center sitebluecolor"> 
                                            <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                                        <span class="text-body font-weight-bold">View Details</span>
                                        </div>
                                    </a>
                                    <a v-if="((data.isapproved_edd == 'Pending') && pagefeatures.approveedd)" v-on:click="reqToApprovedEstiDate(data.updated_edd, parseInt(data.moduleid),data.edd_reason,data.estimatedddate)" href="javascript:void(0);">
                                        <div class="d-flex align-items-center sitebluecolor"> 
                                            <check-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></check-icon>
                                        <span class="text-body font-weight-bold">Approve Estimate Date</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div class="pageCenter text-center pt-1">
                        <pagination v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                        <div v-if="companymasterlist.length == 0 && isresponce" colspan="14" style="text-align: center;">No record found</div>
                    </div>
                </div>

                <div v-bind:class="[{ show: isListActive}, card]" style=" box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top: 1rem; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: isListDetActive},card_content,collapse]">
                        <div class="">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="">
                                        <div class="">
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <div class="row" id="table-small">
                                                        <div class="col-12">
                                                            <div class="card">
                                                                <div v-if="(this.btclistingcolumns && this.btclistingcolumns.length>0)" class="table-responsive">
                                                                    <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" id="table_businessceo1" aria-describedby="mydesc">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" class="first-col-sticky py-1"></th>
                                                                                <th scope="col" class="align-middle first-col-sticky py-1" title="" style="min-width:45px;">Sr#</th>
                                                                                <th v-if="(btclistingcolumns && btclistingcolumns.length > 0)" v-for="(columnsdata, i) in btclistingcolumns" scope="col" class="align-middle py-1" :class="(i==0) ? 'second-col-sticky d-table_icon_up bussiness' : 'd-table_icon_up bussiness'" title="Name" style="min-width:230px;">{{columnsdata.fieldname}} </th>
                                                                                
                                                                                <th scope="col" class="align-middle" title="">Actions</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <template v-for="(data,index) in btclistingrows">  
      
                                                                                <tr v-bind:key="data.businesstaskmasterid">
                                                                                    <td class="centeralign first-col-sticky">
                                                                                        <span class="">
                                                                                            <label :for="index+'-'+data.businesstaskmasterid"></label>
                                                                                            <input type="checkbox" class="delete_checkbox" :id="index+'-'+data.businesstaskmasterid" v-model="isChecked[index]" @click="alterSelectAllCheck(index)">
                                                                                        </span>
                                                                                    </td>
                                                                                    <td class="centeralign first-col-sticky">{{index +1 + currPageNum * pagelimit }}</td>  

                                                                                    <td v-if="(btclistingcolumns && btclistingcolumns.length > 0)" v-for="(columnsdata, i) in btclistingcolumns" class="text-normal" :class="(i==0) ? 'details_column_table second-col-sticky' : 'details_column_table'" style="max-width:230px;">
                                                                                        <span v-if="data.isshow == null"  class="down_arrow_details_bt arrow_btn sinaledit 334"  :id="data.businesstaskmasterid"  @click="tabledetailTr(data.businesstaskmasterid)"> <em class="las la-long-arrow-alt-down"></em> </span>
                                                                                     
                                                                                        <span v-if="columnsdata.fieldnamekey == 'createdby'">
                                                                                            {{data[columnsdata.fieldnamekey] ? data[columnsdata.fieldnamekey].split('@')[0] : 'NA'}}
                                                                                        </span>
                                                                                        <span v-else-if="columnsdata.fieldnamekey == 'projectname'">
                                                                                            <span v-if="btcviewtype == 'Parent BTC'" class="font-weight-normal"  v-html="showprojectname(data[columnsdata.fieldnamekey])"></span>
                                                                                            <span v-else  class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectIDSub',data)" >{{ data[columnsdata.fieldnamekey] }}</span>
                                                                                        </span>
                                                                                        <span v-else-if="columnsdata.fieldnamekey == 'businesstaskcode'" class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">
                                                                                            {{data[columnsdata.fieldnamekey] ? data[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span>
                                                                                        <span class="d-flex align-items-center sitebluecolor" v-else-if="columnsdata.fieldnamekey == 'priority'">
                                                                                            {{data[columnsdata.fieldnamekey] ? data[columnsdata.fieldnamekey] : 'NA'}}
                                                                                            <a v-if="pagefeatures.updatepriority" v-on:click="editStatusCell(parseInt(data.businesstaskmasterid), 'priority')" href="javascript:void(0);">
                                                                                                <div class="ml-50"> 
                                                                                                <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                                </div>
                                                                                            </a>
                                                                                        </span>
                                                                                        <span class="d-flex align-items-center sitebluecolor" v-else-if="columnsdata.fieldnamekey == 'subpriority'">
                                                                                            {{data[columnsdata.fieldnamekey] ? data[columnsdata.fieldnamekey] : 'NA'}}
                                                                                            <a v-if="pagefeatures.updatemodulepriority" v-on:click="editStatusCell(parseInt(data.businesstaskmasterid), 'subpriority')" href="javascript:void(0);">
                                                                                                <div class="ml-50"> 
                                                                                                <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                                </div>
                                                                                            </a>
                                                                                        </span>
                                                                                        <span class="d-flex align-items-center sitebluecolor" v-else-if="columnsdata.fieldnamekey == 'taskstatus'">
                                                                                            <a v-if="pagefeatures.updatetaskstatus" v-on:click="editStatusCell(parseInt(data.businesstaskmasterid), 'status')" href="javascript:void(0);">
                                                                                                <div class="mr-50 "> 
                                                                                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                                </div>
                                                                                            </a>
                                                                                            {{data[columnsdata.fieldnamekey] ? data[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span>
                                                                                        <span v-else>
                                                                                            {{data[columnsdata.fieldnamekey] ? data[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span> 
                                                                                    </td>
                              
                                                                                    <td class="centeralign">
                                                                                        <div class="dropdown">
                                                                                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                            </button>
                                                                                            <div class="dropdown-menu">
                                                                                                <a class="dropdown-item" @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>View Details</span>
                                                                                                </a>
                                                                                                <a v-if="pagefeatures.editbusinesstask || loginusername== data.createdby || loginpersonname==data.projectmaanager || loginpersonname==data.qalead || loginpersonname==data.qamanager || loginpersonname==data.productstackholder || loginpersonname==data.taskowner || loginpersonname==data.engineeringmaanager || loginpersonname==data.programmanager" class="dropdown-item devEditEle"  @click="redirecttourl('edit',data)" href="javascript:void(0);">
                                                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                                    <span>Edit</span>
                                                                                                </a>
                                                                                                <a class="dropdown-item" v-on:click="getBusinessTaskVersion(parseInt(data.businesstaskmasterid), data.modulename, data.createdby, data.createddate)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>Task History</span>
                                                                                                </a>
                                                                                                 <a class="dropdown-item" v-if="(data.isapproved_edd == 'Pending' && pagefeatures.approveedd)" v-on:click="reqToApprovedEstiDate(data.updated_edd, parseInt(data.businesstaskmasterid),data.edd_reason,data.estimatedddate)" href="javascript:void(0);">
                                                                                                    <check-icon size="1.5x" class="custom-class mr-50"></check-icon>
                                                                                                    <span>Approve Estimate Date</span>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr v-for="(sbtc, i) in subtaskbtcwithid" v-if="data.businesstaskmasterid == selectedModuleId && btcviewtype == 'Parent BTC'" :class="'main-btc mov' + data.businesstaskmasterid" :id="'editabletable' + data.businesstaskmasterid" class="table inner_table_detailsbg" >
                                                                                    <td class="centeralign">
                                                                                        <span class="subbtcidmoduleid d-flex">
                                                                                              <span class="subbtc_idsubid01"> Sub</span>
                                                                                            <label :for="i+'-'+sbtc.moduleid"> </label>
                                                                                        </span>
                                                                                    </td>
                                                                                    <td class="centeralign"><b>{{i +1 + currPageNum * pagelimit }}</b></td>  

                                                                                    <td v-if="(btclistingcolumns && btclistingcolumns.length > 0)" v-for="(columnsdata, i) in btclistingcolumns" class="text-normal" :class="(i==0) ? 'details_column_table second-col-sticky' : 'details_column_table'" style="max-width:230px;">
                                                                                        <span v-if="sbtc.isshow == null"  class="down_arrow_details_bt arrow_btn sinaledit 334"  :id="sbtc.businesstaskmasterid"  @click="tabledetailTr(sbtc.businesstaskmasterid)"> <em class="las la-long-arrow-alt-down"></em> </span>
                                                                                     
                                                                                        <span v-if="columnsdata.fieldnamekey == 'createdby'">
                                                                                            {{sbtc[columnsdata.fieldnamekey] ? sbtc[columnsdata.fieldnamekey].split('@')[0] : 'NA'}}
                                                                                        </span>
                                                                                        <span v-else-if="columnsdata.fieldnamekey == 'projectname'">
                                                                                            <span v-if="btcviewtype == 'Parent BTC'" class="font-weight-normal"  v-html="showprojectname(sbtc[columnsdata.fieldnamekey])"></span>
                                                                                            <span v-else  class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectIDSub', sbtc)" >{{ sbtc[columnsdata.fieldnamekey] }}</span>
                                                                                        </span>
                                                                                        <span v-else-if="columnsdata.fieldnamekey == 'businesstaskcode'" class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',sbtc)" href="javascript:void(0);" style="cursor: pointer;">
                                                                                            {{sbtc[columnsdata.fieldnamekey] ? sbtc[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span>
                                                                                        <span v-else-if="columnsdata.fieldnamekey == 'priority'">
                                                                                            <a v-if="sbtc[columnsdata.fieldnamekey] && pagefeatures.updatepriority" v-on:click="editStatusCell(parseInt(data.businesstaskmasterid), 'priority')" href="javascript:void(0);">
                                                                                                <div class="d-flex align-items-center sitebluecolor"> 
                                                                                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                                </div>
                                                                                            </a>
                                                                                            {{sbtc[columnsdata.fieldnamekey] ? sbtc[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span>
                                                                                        <span v-else-if="columnsdata.fieldnamekey == 'subpriority'">
                                                                                            <a v-if="sbtc[columnsdata.fieldnamekey] && pagefeatures.updatemodulepriority" v-on:click="editStatusCell(parseInt(sbtc.businesstaskmasterid), 'subpriority')" href="javascript:void(0);">
                                                                                                <div class="d-flex align-items-center sitebluecolor"> 
                                                                                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                                </div>
                                                                                            </a>
                                                                                            {{sbtc[columnsdata.fieldnamekey] ? sbtc[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span>
                                                                                        <span v-else-if="columnsdata.fieldnamekey == 'taskstatus'">
                                                                                            <a v-if="sbtc[columnsdata.fieldnamekey] && pagefeatures.updatetaskstatus" v-on:click="editStatusCell(parseInt(sbtc.businesstaskmasterid), 'status')" href="javascript:void(0);">
                                                                                                <div class="d-flex align-items-center sitebluecolor"> 
                                                                                                    <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                                </div>
                                                                                            </a>
                                                                                            {{sbtc[columnsdata.fieldnamekey] ? sbtc[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span>
                                                                                        <span v-else>
                                                                                            {{sbtc[columnsdata.fieldnamekey] ? sbtc[columnsdata.fieldnamekey] : 'NA'}}
                                                                                        </span> 
                                                                                    </td>
                                                                                    
                                                                                    <td class="centeralign">
                                                                                        <div class="dropdown">
                                                                                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                            </button>
                                                                                            <div class="dropdown-menu">
                                                                                                <a class="dropdown-item"   @click="redirecttourl('view',sbtc)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>View Details</span>
                                                                                                </a>
                                                                                                <a v-if="pagefeatures.editbusinesstask || loginusername== sbtc.createdby || loginpersonname==sbtc.projectmaanager || loginpersonname==sbtc.qalead || loginpersonname==sbtc.qamanager || loginpersonname==sbtc.productstackholder || loginpersonname==sbtc.taskowner || loginpersonname==sbtc.engineeringmaanager || loginpersonname==sbtc.programmanager" class="dropdown-item devEditEle"  @click="redirecttourl('edit',sbtc)" href="javascript:void(0);">
                                                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                                    <span>Edit</span>
                                                                                                </a>
                                                                                                <a class="dropdown-item" v-on:click="getBusinessTaskVersion(parseInt(sbtc.businesstaskmasterid), sbtc.modulename, sbtc.createdby, sbtc.createddate)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>Task History</span>
                                                                                                </a>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companymasterlist.length==0">
                                                                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                            </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div v-else class="table-responsive">
                                                                    <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" id="table_businessceo1" aria-describedby="mydesc">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" class="first-col-sticky"></th>
                                                                                <th scope="col" class="align-middle second-col-sticky" style="min-width:200px" title="">SR# / Business Task Code / Project</th>
                                                                                <th scope="col" class="align-middle" title="" style="min-width:200px">BTC Details</th>
                                                                                <th scope="col" class="align-middle d-table_icon_up bussiness" style="min-width:140px" title="">Priority / Sub Priority</th>
                                                                                <th scope="col" class="align-middle" title="">Status</th>
                                                                                <th scope="col" class="align-middle" title="" style="min-width:250px">Date Details</th>
                                                                                <th scope="col" class="align-middle" title="">Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
  
                                                                            <template v-for="data,index  in companymasterlist">        
                                                                                <tr v-bind:key="data.moduleid">
                                                                                    <td class="centeralign first-col-sticky" style="min-width:40px;">
                                                                                        <span class="">
                                                                                            <label :for="index+'-'+data.moduleid"></label>
                                                                                            <input type="checkbox" class="delete_checkbox" :id="index+'-'+data.moduleid" v-model="isChecked[index]" @click="alterSelectAllCheck(index)">
                                                                                        </span>
                                                                                    </td>
                                                                                    <td class="text-normal details_column_table second-col-sticky" style="max-width:230px;">
                                                                                        <span v-if="data.isshow == null"  class="down_arrow_details_bt arrow_btn sinaledit 334 "  :id="data.moduleid"  @click="tabledetailTr(data.moduleid)"> <em class="las la-long-arrow-alt-down"></em> </span>
                                                                                        <!-- <spna>{{index +1 + currPageNum * pagelimit }}</spna> -->
                                                                                        <hr class="hr_border-bottom mt-1" />
                                                                                        <span> <strong>Name : </strong> </span>
                                                                                        <span class="font-weight-normal">{{data.modulename}}</span>
                                                                                       <hr class="hr_border-bottom" />
                                                                                       <span v-if="btcviewtype == 'Parent BTC'" class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',data)" href="javascript:void(0);" style="cursor: pointer;">{{data.businesstaskcode}}</span>
                                                                                       <span v-else class="capitalisetext">
                                                                                           <span> <strong>Sub: </strong> </span>
                                                                                           <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('view', data)" href="javascript:void(0);" style="cursor: pointer;">{{data.businesstaskcode}}</span>
                                                                                           <hr class="hr_border-bottom" />
                                                                                           <span> <strong>Parent: </strong> </span>
                                                                                           <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('parentview', data)" href="javascript:void(0);" style="cursor: pointer;">{{data.parentbcode}}</span>
                                                                                        </span>  
                                                                                        <hr class="hr_border-bottom" />
                                                                                        <div v-if="data.taskkey.toLowerCase()=='key' || data.taskkey.toLowerCase()=='urgent'" class="badge badge-pill badge-light-danger mr-50 capitalisetext">{{data.taskkey}}</div>
                                                                                        <div v-if="data.taskkey.toLowerCase()=='major'" class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{data.taskkey}}</div>
                                                                                        <div v-if="data.taskkey.toLowerCase()=='minor'" class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{data.taskkey}}</div>
                                                                                        <div v-if="data.taskkey.toLowerCase()=='cosmetic'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                                                                        <div v-if="data.taskkey.toLowerCase()=='normal'" class="badge badge-pill badge-light-success mr-50 capitalisetext">{{data.taskkey}}</div>
                                                                                        <div v-if="(data.isapproved_edd && data.isapproved_edd == 'Pending')" class="badge badge-pill badge-light-warning mr-50 capitalisetext">Pending</div>
                                                                                        <hr class="hr_border-bottom" />
                                                                                        <span v-if="btcviewtype == 'Parent BTC'" class="font-weight-normal"  v-html="showprojectname(data.projectnameist)"></span>
                                                                                        <span v-else  class="font-weight capitalisetext cursorpointercls sitebluecolor" @click="redirecttourl('projectID',data)" >{{ data.projectname }}</span>
                                                                                        <hr class="hr_border-bottom" />                                                                                      
                                                                                        <!-- <hr  class="hr_border-bottom" /> -->
                                                                                        <div v-if="(data.ageingdays && search.searchBusinesstaskstatus && search.searchBusinesstaskstatus=='delayed' )">
                                                                                         <span> <strong>Delayed By(days):</strong> </span>
                                                                                        <span class="font-weight-normal sitebluecolor" >{{data.ageingdays?data.ageingdays:'NA'}}</span>
                                                                                    </div>
                                                                                    <div v-if="(data.ageingdays && search.searchBusinesstaskstatus && search.searchBusinesstaskstatus=='deliverable' )">
                                                                                         <span> <strong>Deliverable By(days): </strong> </span>
                                                                                        <span class="font-weight-normal sitebluecolor" >{{data.ageingdays?data.ageingdays:'NA'}}</span>
                                                                                    </div>
                                                                                    </td> 
                                                                                   
                                                                                    <td class="details_column_table">
                                                                                        <span class="heading_column_text"> PD :</span>
                                                                                    {{data.department}}
                                                                                     
                                                                                     <hr class="hr_border-bottom" />
                                                                                     <span class="heading_column_text"> Type :</span>
                                                                                      <span v-if="data.tasktype=='Business Task'">Business Task - New Feature</span>
                                                                                      <span v-else-if="data.tasktype=='Enhancement'">Business Task - Enhancement</span>
                                                                                      <span v-else>{{data.tasktype}}</span>
                                                                                      <hr class="hr_border-bottom" />
                                                                                            <span v-if="data.taskowner || data.productstackholder" class="details_column_table" style="text-transform: capitalize;">
                                                                                                <b>TM :</b> {{data.productstackholder}}
                                                                                                <hr v-if="data.taskowner" class="hr_border-bottom" />
                                                                                                <span v-if="data.taskowner"><b>TO :</b> {{data.taskowner}}</span>
                                                                                            </span>   
                                                                                            <span class="font-weight-normal" v-else>NA</span>
                                                                                            <hr class="hr_border-bottom" />
                                                                                            <span class = "capitalisetext font-weight-normal" v-if="data.projectmaanager"><b>PM :</b> {{data.projectmaanager}}
                                                                                            </span>
                                                                                            <span class="font-weight-normal" v-else>NA</span>
                                                                                     </td>
                                                                                  
                                                                                    <td v-if="btcviewtype == 'Parent BTC'">
                                                                                       <div class="task_tatus_badge_set_pri font-weight-normal " >
                                                                                        <a v-if="pagefeatures.updatepriority" v-on:click="editStatusCell(parseInt(data.moduleid), 'priority', data.taskstatus,data.projectist)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <div v-if="data.priority" class="capitalisetext badge badge-pill font-weight-normal badge-light-info mr-50">{{data.priority}}</div>
                                                                                        <div v-else class="capitalisetext mr-50 font-weight-normal" v-bind:class="{'badge badge-pill badge-light-success': Boolean(data.priority)}">{{data.priority  ? data.priority : 'NA'}}</div>
                                                                                        </div>
                                                                                        <div class="task_tatus_badge_edit font-weight-normal">
                                                                                        <a v-if="data.isshow == 1 && pagefeatures.updatemodulepriority"  v-on:click="editStatusCell(parseInt(data.moduleid), 'subpriority', data.taskstatus,data.projectist)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <a v-else-if="data.isshow == 1 && loginpersonname==data.productstackholder && pagefeatures.mngrupdatemodulepriority"  v-on:click="editStatusCell(parseInt(data.moduleid), 'subpriority', data.taskstatus,data.projectist)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <span  v-if="data.subpriority" class="pull-left">{{data.subpriority}} </span>
                                                                                        </div>

                                                                                    </td>
                                                                                    <td v-else>
                                                                                        <div class="task_tatus_badge_edit font-weight-normal">
                                                                                        <a v-if="data.isshow == 1 && pagefeatures.updatemodulepriority"  v-on:click="editStatusCell(parseInt(data.moduleid), 'subpriority', data.taskstatus,data.projectist)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <a v-else-if="data.isshow == 1 && loginpersonname==data.productstackholder && pagefeatures.mngrupdatemodulepriority"  v-on:click="editStatusCell(parseInt(data.moduleid), 'subpriority', data.taskstatus,data.projectist)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <span  v-if="data.subpriority" class="pull-left">{{data.subpriority}} </span>
                                                                                        <span  v-else class="pull-left">NA </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="task_tatus_badge">
                                                                                        <div class="badge badge-pill badge-light-primary capitalisetext mr-50" style="display: inline-flex;align-items: baseline;text-align: left;max-width: 160px;min-width: 145px;">
                                                                                        <a style="float:left" v-if="pagefeatures.updatetaskstatus" v-on:click="editStatusCell(parseInt(data.moduleid), 'status', data.taskstatus,data.projectist)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <span class="buss_status_wrap"> 
                                                                                        {{data.taskstatus}}
                                                                                        </span>
                                                                                    </div>

                                                                                    <hr v-if="(data.taskstatus != 'Completed' && data.taskstatus != 'Discarded' && data.taskstatus != 'OnHold' && data.taskstatus != 'Live')" class="hr_border-bottom" />
                                                                                    <span v-if="(data.taskstatus != 'Completed' && data.taskstatus != 'Discarded' && data.taskstatus != 'OnHold' && data.taskstatus != 'Live')" class="heading_column_text badge badge-pill badge-light-info"> Ageing : {{data.createddateaeging}} </span>
                                                                                    <hr class="hr_border-bottom" v-if="data.edddelayed == null && data.edddeliverable" />
                                                                                    <span v-if="data.edddelayed == null && data.edddeliverable" class="heading_column_text badge badge-pill badge-light-success"> Deliverable BY : {{data.edddeliverable?data.edddeliverable: 'NA'}}</span>
                                                                                    <hr class="hr_border-bottom" v-if="data.edddelayed != null" />
                                                                                    <span v-if="data.edddelayed != null" class="heading_column_text badge badge-pill badge-light-danger"> Delayed BY : {{data.edddelayed}}</span>
                                                                                
                                                                                    </td>
                                                                                    <td class="details_column_table">
                                                                                     <span class="heading_column_text"> CREATED BY : </span>
                                                                                     {{data.createdby.split('@')[0]}}
                                                                                     <hr class="hr_border-bottom" />
                                                                                     <span class="heading_column_text"> Due date : </span>
                                                                                     <span v-if="data.due_date"> {{dateMonthFormat(data.due_date)}} </span>
                                                                                     <span v-else> NA </span>
                                                                                     <hr class="hr_border-bottom" />
                                                                                     <span class="heading_column_text "> Created date : </span>
                                                                                     {{dateMonthFormat(data.createddate)}}
                                                                                     
                                                                                    <span v-if="data.estimatedddate && (data.taskstatus != 'Completed' && data.taskstatus != 'Discarded' && data.taskstatus != 'OnHold' && data.taskstatus != 'Live')">
                                                                                     <hr class="hr_border-bottom" />
                                                                                     <span class="heading_column_text "> End date : </span>
                                                                                     {{dateMonthFormat(data.estimatedddate)}}
                                                                                      </span> 
                                                                                    </td>
                              
                                                                                    <td class="centeralign">
                                                                                        <div class="dropdown">
                                                                                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                            </button>
                                                                                            <div class="dropdown-menu">
                                                                                                <a class="dropdown-item"   @click="redirecttourl('view',data)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>View Details</span>
                                                                                                </a>
                                                                                                <a v-if="pagefeatures.editbusinesstask || loginusername== data.createdby || loginpersonname==data.projectmaanager || loginpersonname==data.qalead || loginpersonname==data.qamanager || loginpersonname==data.productstackholder || loginpersonname==data.taskowner || loginpersonname==data.engineeringmaanager || loginpersonname==data.programmanager" class="dropdown-item devEditEle"  @click="redirecttourl('edit',data)" href="javascript:void(0);">
                                                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                                    <span>Edit</span>
                                                                                                </a>
                                                                                                <a class="dropdown-item" v-on:click="getBusinessTaskVersion(parseInt(data.moduleid), data.modulename, data.createdby, data.createddate)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>Task History</span>
                                                                                                </a>
                                                                                                 <a class="dropdown-item" v-if="(data.isapproved_edd == 'Pending' && pagefeatures.approveedd)" v-on:click="reqToApprovedEstiDate(data.updated_edd, parseInt(data.moduleid),data.edd_reason,data.estimatedddate)" href="javascript:void(0);">
                                                                                                    <check-icon size="1.5x" class="custom-class mr-50"></check-icon>
                                                                                                    <span>Approve Estimate Date</span>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr v-for="(sbtc, i) in subtaskbtcwithid" v-if="data.moduleid == selectedModuleId && btcviewtype == 'Parent BTC'" :class="'main-btc mov' + data.moduleid" :id="'editabletable' + data.moduleid" class="table inner_table_detailsbg" >
                                                                                    <td class="centeralign">
                                                                                        <span class="subbtcidmoduleid d-flex">
                                                                                              <span class="subbtc_idsubid01"> Sub</span>
                                                                                            <label :for="i+'-'+sbtc.moduleid"> </label>
                                                                                        </span>
                                                                                    </td>
                                                                                   
                                                                                    <td class="text-normal details_column_table" style="max-width:230px;">
                                                                                        <!-- <b>{{i +1 + currPageNum * pagelimit }}</b>
                                                                                        <hr class="hr_border-bottom" /> -->
                                                                                       <span> {{sbtc.businesstaskcode}}</span>
                                                                                        <hr class="hr_border-bottom" />
                                                                                        <span> <strong>Name : </strong> </span>
                                                                                        <span class="font-weight-bold sitebluecolor"  @click="redirecttourl('view',sbtc)" href="javascript:void(0);" style="cursor: pointer;">{{sbtc.modulename}}</span>
                                                                                        <hr  class="hr_border-bottom" />
                                                                                        <span class="font-weight-bold sitebluecolor" @click="redirecttourl('projectIDSub',sbtc)" >{{ sbtc.projectname }}</span>
                                                                                        <hr  class="hr_border-bottom" />
                                                                                        <div v-if="(sbtc.ageingdays && search.searchBusinesstaskstatus && search.searchBusinesstaskstatus=='delayed' )">
                                                                                         <span> <strong>Delayed By(days):</strong> </span>
                                                                                        <span class="font-weight-bold sitebluecolor" >{{sbtc.ageingdays?sbtc.ageingdays:'NA'}}</span>
                                                                                    </div>
                                                                                    <div v-if="(sbtc.ageingdays && search.searchBusinesstaskstatus && search.searchBusinesstaskstatus=='deliverable' )">
                                                                                         <span> <strong>Deliverable By(days): </strong> </span>
                                                                                        <span class="font-weight-bold sitebluecolor" >{{sbtc.ageingdays?sbtc.ageingdays:'NA'}}</span>
                                                                                    </div>
                                                                                    </td> 
                                                                              
                                                                                    <td class="details_column_table">
                                                                                    {{sbtc.department}}
                                                                                     <hr class="hr_border-bottom" />
                                                                                     <span class="heading_column_text"> Type : </span>
                                                                                      <span v-if="sbtc.tasktype=='Business Task'">Business Task - New Feature</span>
                                                                                      <span v-else-if="sbtc.tasktype=='Enhancement'">Business Task - Enhancement</span>
                                                                                      <span v-else>{{sbtc.tasktype}}</span>
                                                                                      <hr  class="hr_border-bottom" />
                                                                                        <span class = "capitalisetext" v-if="sbtc.productstackholder"><b>TM: </b>{{sbtc.productstackholder}}</span>
                                                                                        <span v-else>NA</span>
                                                                                      <hr  class="hr_border-bottom" />
                                                                                        <span class = "capitalisetext" v-if="sbtc.projectmaanager"><b>PM: </b>{{sbtc.projectmaanager}}
                                                                                        </span>
                                                                                        <span v-else>NA</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="task_tatus_badge_edit">
                                                                                        <a v-if="pagefeatures.updatemodulepriority"  v-on:click="editStatusCell(parseInt(sbtc.moduleid), 'subpriority', sbtc.taskstatus,sbtc.projectid)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <a v-else-if="loginpersonname==sbtc.productstackholder && pagefeatures.mngrupdatemodulepriority"  v-on:click="editStatusCell(parseInt(sbtc.moduleid), 'subpriority', sbtc.taskstatus,sbtc.projectid)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <span  v-if="sbtc.subpriority" class="pull-left">{{sbtc.subpriority}} </span>
                                                                                        <span  v-else class="pull-left">NA </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="task_tatus_badge">
                                                                                        <div class="badge badge-pill badge-light-primary capitalisetext mr-50" style="display: inline-flex;align-items: baseline;text-align: left;max-width: 160px;min-width: 145px;">
                                                                                        <a style="float:left" v-if="pagefeatures.updatetaskstatus" v-on:click="editStatusCell(parseInt(sbtc.moduleid), 'status', sbtc.taskstatus, sbtc.projectid)" href="javascript:void(0);">
                                                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                                            </div>
                                                                                        </a>
                                                                                        <span class="buss_status_wrap"> 
                                                                                        {{sbtc.taskstatus}}
                                                                                        </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="details_column_table">
                                                                                     {{sbtc.createdby.split('@')[0]}}
                                                                                     <hr class="hr_border-bottom" />
                                                                                     {{dateMonthFormat(sbtc.createddate)}}
                                                                                     <hr class="hr_border-bottom" />
                                                                                     <span class="heading_column_text"> Due date : </span>
                                                                                     <span v-if="sbtc.due_date"> {{dateMonthFormat(sbtc.due_date)}} </span>
                                                                                     <span v-else> NA </span>
                                                                                    </td>
                              
                                                                                    <td class="centeralign">
                                                                                        <div class="dropdown">
                                                                                            <button type="button" class="btn btn-sm dropdown-toggle hide-arrow" data-toggle="dropdown" data-boundary="window">
                                                                                                <more-vertical-icon size="1.5x" class="custom-class"></more-vertical-icon>
                                                                                            </button>
                                                                                            <div class="dropdown-menu">
                                                                                                <a class="dropdown-item"   @click="redirecttourl('view',sbtc)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>View Details</span>
                                                                                                </a>
                                                                                                <a v-if="pagefeatures.editbusinesstask || loginusername== sbtc.createdby || loginpersonname==sbtc.projectmaanager || loginpersonname==sbtc.qalead || loginpersonname==sbtc.qamanager || loginpersonname==sbtc.productstackholder || loginpersonname==sbtc.taskowner || loginpersonname==sbtc.engineeringmaanager || loginpersonname==sbtc.programmanager" class="dropdown-item devEditEle"  @click="redirecttourl('edit',sbtc)" href="javascript:void(0);">
                                                                                                    <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                                                                    <span>Edit</span>
                                                                                                </a>
                                                                                                <a class="dropdown-item" v-on:click="getBusinessTaskVersion(parseInt(sbtc.moduleid), sbtc.modulename, sbtc.createdby, sbtc.createddate)" href="javascript:void(0);">
                                                                                                    <eye-icon size="1.5x" class="custom-class mr-50"></eye-icon>
                                                                                                    <span>Task History</span>
                                                                                                </a>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-if="companymasterlist.length==0">
                                                                                <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                            </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pageCenter text-center">
                                            <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div v-bind:class="[{ show: chartDetails},]" style="margin-top: 1rem; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: ischartDetailsActives},card_content,collapse]">
                        <div class="container">
                           <div class="row">
                        <div class="col-lg-4 col-12">
                            <div class="card">
                                <div class="card-header">
                                    <div class="w-auto px-1 mobile_margin-px-0_00 mobile_w_calc_38" style="padding: 5px 0px;">
                                        
                                        <h4 class="card-title d-inline-flex  mobile_width-86">Sessions By Status</h4>
                                    </div>
                                </div>
                                <div class="card-body">
                                     <div class="chartpiechartviews">
                                    <canvas class="containermaster123 chartjs" data-height="275" id="containermaster12"></canvas>
                                    </div>
                                    <div class="d-flex justify-content-between mt-3 mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                            <em class="las la-stop font-medium-1" style="background: #698ED0;color:#698ED0"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Product Pipeline</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusProductPipeline?this.btaskstatus.StatusProductPipeline:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                            <em class="las la-stop font-medium-1" style="color: #ED7D31;background: #ED7D31;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Product WIP</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusProductWIP?this.btaskstatus.StatusProductWIP:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                            <em class="las la-stop font-medium-1" style="color: #A5A5A5;background: #A5A5A5;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Product Completed</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusProductCompleted?this.btaskstatus.StatusProductCompleted:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #FFC000;background: #FFC000;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Product WIP 2</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusProductWIP2?this.btaskstatus.StatusProductWIP2:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #5B9BD5;background: #5B9BD5;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Tech and Business Review</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusTechAndBusinessReview?this.btaskstatus.StatusTechAndBusinessReview:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #5B9BD5;background: #5B9BD5;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Estimation Review</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusEstimationReview?this.btaskstatus.StatusEstimationReview:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #70AD47;background: #70AD47;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Tech Pipeline</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusTechPipeline?this.btaskstatus.StatusTechPipeline:'0'}}</span>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #264478;background: #264478;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Tech WIP</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusTechWIP?this.btaskstatus.StatusTechWIP:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #9E480E;background: #9E480E;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">QA Pipeline</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusQAPipeline?this.btaskstatus.StatusQAPipeline:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #636363;background: #636363;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">QA WIP</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusQAWIP?this.btaskstatus.StatusQAWIP:'0'}}</span>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #636363;background: #636363;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Tech Issue Resolution</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusTechIssueResolution?this.btaskstatus.StatusTechIssueResolution:'0'}}</span>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #997300;background: #997300;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">UAT</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusUAT?this.btaskstatus.StatusUAT:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                            <em class="las la-stop font-medium-1" style="color: #255E91;background: #255E91;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">UAT Signoff</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusUATSignoff?this.btaskstatus.StatusUATSignoff:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #43682B;background: #43682B;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">UAT Feedback</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusUATFeedback?this.btaskstatus.StatusUATFeedback:'0'}}</span>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #698ED0;background: #698ED0;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Live</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusLive?this.btaskstatus.StatusLive:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #F1975A;background: #F1975A;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Completed</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusCompleted?this.btaskstatus.StatusCompleted:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #B7B7B7;background: #B7B7B7;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">OnHold</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusOnHold?this.btaskstatus.StatusOnHold:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                             <em class="las la-stop font-medium-1" style="color: #FFCD33;background: #FFCD33;"></em>
                                            <span class="font-weight-normal ml-75 mr-25">Discarded</span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.StatusDiscarded?this.btaskstatus.StatusDiscarded:'0'}}</span>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mt-1 mb-1 chartpiechartcolor14">
                                        <div class="d-flex align-items-center">
                                            <span class="font-weight-bold ml-0 mr-25" style="font-size:16!important;">Total Count </span>
                                        </div>
                                        <div>
                                            <span>{{this.btaskstatus.totalsum?this.btaskstatus.totalsum:'0'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8 col-12">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column">
                                    <div class="card-header">
                                        <div class="w-auto px-0 mobile_margin-px-0_00 mobile_w_calc_38" style="padding:0px 0px;">
                                            <h4 class="card-title d-inline-flex ml-0 mobile_width-86" style="padding: 0px 9px;">Latest Status Statistics</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="chartpiechartviews">
                                    <canvas class="containermasterbarchart chartjs" id="containermasterbarchart1" data-height="400"></canvas>
                                    </div>
                                  </div>
                            </div>
                        </div>
                           </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{ show: chartDetails},]" style="margin-top: 1rem; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: isboardDetailsActives},card_content,collapse]">
                        <div class="container px-0">
                           <div class="row_boardview">
                               <div class="col-12 col-sm-6 col-md-4 col-lg-3 row_boardview05" v-for="(blval, blkey) in this.boardApiList">
                                        <div class="d-flex align-items-center justify-content-between board_titile_productlist  board_common_top1">
                                            <h5 class=" py-1 mb-0"> {{ blval[0] }} </h5>
                                            <div class="badge badge badge-light-primary">{{(blval[1]).length}}
                                            / {{boardApiListTemp[blkey][1].length}}
                                            </div>
                                        </div>
                                    <div id="listingTable" class="board_common_card" v-for="(list, listkey) in (blval[1])">
                                        <div class="dw-panel blog-list-wrapper  p-0 mb-1" >
                                            <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 0rem !important;">
                                            <div v-if="btcviewtype == 'Parent BTC'" class="card-header p-1">
                                                    <h4 v-if="list.projectnameist" class="card-title">{{(list.projectnameist).toLowerCase()}} : {{list.businesstaskcode}} </h4>
                                            </div>
                                            <div v-else class="card-header p-1">
                                                    <h4 v-if="list.projectname" class="card-title">{{(list.projectname).toLowerCase()}} : {{list.businesstaskcode}} </h4>
                                            </div>
                                            <div class="card" style="margin-bottom:0 !important;">
                                                <div class="card-body p-1">
                                                    <h4 class="card-title mb-1">
                                                        <span v-if="list.modulename.length > 50"  class="blog-title-truncate text-body-heading ">
                                                            {{(list.modulename.substring(0,51)).toLowerCase()+ ' ...'}}
                                                        </span>
                                                        <span v-else  class="blog-title-truncate text-body-heading ">
                                                            {{(list.modulename).toLowerCase()}}
                                                        </span>
                                                    </h4>
                                                    <div class="media">
                                                        <div v-if="activeboardlink == true" class="avatar mr-50">
                                                            <profilepic v-if="list.imagename && list.employeecode" v-bind:get-url="'empprofile/'+list.employeecode+'/'+list.imagename" :key="`${componentKey + listkey}`"></profilepic>
                                                            <img alt=""  v-if="!list.imagename" :src="defaultimagepath"  width="24" height="24" />
                                                        </div>
                                                        <div class="media-body">
                                                            <small class="text-muted mr-25">by</small>
                                                            <small><a href="javascript:void(0);"  class="capitalisetext">{{list.creator}}</a></small>
                                                            <small class="text-muted d-block">{{ dateMonthFormat(list.createddate) }}</small>
                                                        </div>
                                                    </div>
                                                    <div class="my-1">
                                                        <div  class="badge badge-pill badge-light-secondary mr-50 capitalisetext">{{list.taskkey}}</div>
                                                        <div  class="badge badge-pill badge-light-primary mr-50 capitalisetext">{{list.taskstatus}}</div>
                                                        <div  class="badge badge-pill badge-light-warning mr-50 capitalisetext">{{ list.tasktype }}</div>
                                                    </div>
                                                    <div class="card-text blog-content-truncate" style="overflow:hidden">
                                                        <span v-if="list.moduledesc && list.moduledesc.length>150" v-html="list.moduledesc.substring(0, 151) + ' ...'"></span>
                                                        <span v-else v-html="list.moduledesc"></span>
                                                    </div>

                                                    <hr style="cursor:pointer;margin:0;" v-if="list.estimatedddate && (list.taskstatus != 'Completed' && list.taskstatus != 'Discarded' && list.taskstatus != 'OnHold' && list.taskstatus != 'Live')" class="mx-n1 mt-0" />
                                                    <div v-if="list.estimatedddate && (list.taskstatus != 'Completed' && list.taskstatus != 'Discarded' && list.taskstatus != 'OnHold' && list.taskstatus != 'Live')" style="cursor:pointer" class="row text-center mx-0">
                                                        <div class="col-12" style="padding: 8px;">
                                                            <p class="card-text text-muted mb-0" style="font-size: 10px">End date : {{dateMonthFormat(list.estimatedddate)}}</p>
                                                            <p style="font-size: 11px" class="font-weight-bolder mb-0">
                                                                <span v-if="list.edddelayed == null">Ageing : {{list.createddateaeging}} | Deliverable BY : {{list.edddeliverable}}</span>
                                                                <span v-if="list.edddelayed != null">Ageing : {{list.createddateaeging}} | Delayed BY : {{list.edddelayed}}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <hr class="mx-n1 mt-0">
                                                    <div class="d-flex justify-content-between align-items-center board_uiBottomsize">
                                                        <a v-if="pagefeatures.editbusinesstask || loginusername==list.createdby || loginpersonname==list.projectmaanager || loginpersonname==list.qamanager || loginpersonname==list.qalead || loginpersonname==list.productstackholder || loginpersonname==list.taskowner || loginpersonname==list.engineeringmaanager" @click="redirecttourl('edit',list)" href="javascript:void(0);" >
                                                            <div class="d-flex align-items-center sitebluecolor">
                                                                <edit-2-icon size="1.5x" class="custom-class mr-50" style="color:blue"></edit-2-icon>
                                                                <span class="text-body font-weight-bold sitebluecolor" >Edit</span>
                                                            </div>
                                                        </a>
                                                        <a v-on:click="getBusinessTaskVersion(parseInt(list.moduleid), list.modulename, list.createdby, list.createddate)" href="javascript:void(0);">
                                                            <div class="d-flex align-items-center sitebluecolor ml-1"> 
                                                                <alert-circle-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></alert-circle-icon>
                                                            <span class="text-body font-weight-bold">Task History</span>
                                                            </div>
                                                        </a>
                                                        <a @click="redirecttourl('view',list)" href="javascript:void(0);">
                                                            <div class="d-flex align-items-center sitebluecolor"> 
                                                                <eye-icon size="1.5x" class="custom-class mr-50" style="color:blue" ></eye-icon>
                                                            <span class="text-body font-weight-bold">View Details</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                           </div>

                           <div class="pageCenter text-center">
                              <div class="VuePagination_view ">
                                <div class="VuePagination_view_02">
                                    <a class="VuePagination_view_count" href="javascript:void(0);" @click="prevPage()" id="btn_prev">Prev </a>
                                    <span  class="VuePagination_view_count" id="page"> 1 </span>
                                    <a class="VuePagination_view_count" href="javascript:void(0);" @click="nextPage()" id="btn_next"> Next</a>
                                </div>  
                            </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div v-bind:class="[{ show: chartDetails},]" style="margin-top: 1rem; margin-bottom: 1rem !important;">
                    <div v-bind:class="[{show: iscalendarDetailsActives},card_content,collapse]">
                        <div class="container px-0 ">
                           <div class="card col-sm-12 px-0">
                                <div class="col position-relative pb-3">
                                    <div class="card shadow-none border-0 mb-0 rounded-0">
                                        <div class="card-body app_calendar_view pb-0">
                                            <div id="calendar"></div>
                                        </div>
                                    </div>
                                </div>
                             
                           </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title">Task History Cycle</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body sm-overflow_scroll_div">
                            <div class="row">
                                <div class="col-md-12">
                                <ul class="timeline">
                                    <li class="timeline-item"  v-for="(data) in btmversionlist.slice(0,1)" v-bind:key="data.experienceid">
                                    <span class="timeline-point"><edit-icon size="1.5x" class="custom-class"></edit-icon></span>
                                    <div class="timeline-event">
                                        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <p>{{formatName(data.author)}} created <strong>{{data.taskname}}</strong></p>
                                        </div>
                                        <span class="timeline-event-time">At: {{dateMonthFormat(data.createdat)}}</span>
                                    </div>
                                    </li>
                                    <li class="timeline-item"  v-for="(data,i) in btmversionlist.slice(1)" v-bind:key="data.experienceid">
                                    <span v-if="i==1" class="timeline-point timeline-point-indicator"></span>
                                    <span v-if="i%2==0" class="timeline-point  timeline-point-warning timeline-point-indicator"></span>
                                    <span v-else class="timeline-point  timeline-point-info timeline-point-indicator"></span>
                                    <div class="timeline-event">
                                        <div v-if="data.field !== 'task description'" class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                        <p>{{formatName(data.modifiedby)}} updated <strong>{{data.field}}</strong> from <strong style="display:inline-block" v-html="data.oldval ? data.oldval : 'NA'"></strong> to <strong style="display:inline-block" v-html="data.newval ? data.newval : 'NA'"></strong></p>
                                        </div>
                                        <div v-else>
                                        <p>{{formatName(data.modifiedby)}} updated task description <a style="color: blue" @click="opentaskdesc(data)">view details</a></p>
                                        </div>
                                        <span class="timeline-event-time">At: {{dateMonthFormat(data.lastmodifieddate)}}</span>
                                    </div>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="estimatedatepop" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title">Approve Estimate Date</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closed()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body sm-overflow_scroll_div">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label class="form-label">Current Estimated Date</label>
                                    <input class="form-control" type="text" v-bind:value="dateFormat(muatdate)" disabled/>
                                </div>
                                <div class="form-group col-md-4">
                                    <label class="form-label">Updated Estimated Date</label>
                                    <input class="form-control" type="text" v-bind:value="dateFormat(estimatedate)" disabled/>
                                </div>
                                <div class="form-group col-md-8">
                                    <label class="form-label">Estimate Update Reason</label>
                                    <textarea class="form-control" v-bind:value="medd_reason" disabled></textarea>                                        
                                </div>
                                <div class="col-md-12">
                                    <button type="button" id="estimateapprovebtn" class="btn btn-primary" v-on:click="approvedEstiDate(estimatedate, businesstaskidED)">Approved</button>
                                </div>
                            </div>
                            </div>    
                        </div>
                    </div>
                </div>
                <!-- <div class="modal fade" id="listsettingPopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document"  style="width:100%; max-width:1100px;">
                        <div class="modal-content">
                            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title" style="margin-right: 2rem !important">BTC Listing Mapping</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <ListingConfig />
                            </div>
                            <div class="modal-footer">
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="modal fade" id="btPriorityPopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document"  style="width:100%; max-width:1100px;">
                        <div class="modal-content">
                            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title" style="margin-right: 2rem !important">Reset Priority</h4>
                            <treeselect style="width:60%;" class="mr-1" placeholder="Select pattern to reset priority" :options="bulk_priority_options" v-model="bulk_priority.reset_pattern" />
                            <button type="button" id="addPattern" class="btn btn-primary" v-on:click="addPatternAmt()" v-if="(bulk_priority.reset_pattern)">Add</button>

                            <button type="button" id="bulkUpdateBtn" class="btn btn-primary" v-on:click="bulkUpdatePrio()" style="display:none;">Bulk Update</button>

                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearAllBTPriorityPopupFields()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <table class="table table-bordered" aria-describedby="mydesc">
                                    <thead>
                                    <tr>
                                      <th scope="col">Business Task Code</th>
                                      <th scope="col">Redmine/Reference ID</th>
                                      <th scope="col">Description</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Old Priority</th>
                                      <th scope="col">Update Priority</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(data, index) in bulkPriorityRecords">
                                      <td>{{data.businesstaskcode}}  </td>
                                      <td>{{data.redmineid}}</td>
                                      <td>{{data.modulename}}</td>
                                      <td>{{data.taskstatus}}</td>
                                      <td>{{data.old_priority}}</td>
                                      <td>          
                                            <input class="form-control mr-1" type="number" placeholder="Priority" v-model="data.priority" style="float:left;width:60%" v-on:change="enableUpdateBtn(data.moduleid, data.priority)" pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 } " />

                                            <span v-bind:id="'update_'+data.moduleid" class="btn btn-icon btn-outline-primary" style="display:none" v-on:click.stop.prevent="editCurrentRow(data)">
                                              <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                            </span>
                                      </td>
                                    </tr>
                                    </tbody>
                                    </table>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" @click="clearAllBTPriorityPopupFields()" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="modal fade" id="statusEditPopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-sm " role="document">
                        <div class="modal-content">
                            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                            <h4 class="modal-title">Update Task Status</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearAllPopupFields()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" v-if="this.editCell.activeColumnFlag == 'status'">                            
                                        <label for="message-text" class="col-form-label">Task Status</label>
                                        <input class="form-control capitalisetext" placeholder="Select Task Status" v-model="editCell.editTaskStatus" :options="tasktype.taskstatus" @input="selecttaskstatus1" v-if="tasktype.taskstatus.length<=1" disabled/>
                                        <treeselect class="projectmdllistdd" placeholder="Select Task Status" v-model="editCell.editTaskStatus" :options="tasktype.taskstatus" @input="selecttaskstatus1" v-else/>
                                        <div class="errorinputmsg" v-if="this.editCell.errors.editTaskStatus">{{ this.editCell.errors.editTaskStatus }}</div> 
                                    </div>
                                    <div class="form-group" v-if="this.editCell.activeColumnFlag == 'priority'">                            
                                        <label class="form-label lable-left">Task Priority</label>
                                        <input pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" class="form-control" type="number" placeholder="Select Task Priority" v-model="editCell.btmpriority"/> 
                                        <div class="errorinputmsg" v-if="this.editCell.errors.btmpriority">{{ this.editCell.errors.btmpriority }}</div> 
                                    </div>
                                     <div class="form-group" v-if="this.editCell.activeColumnFlag == 'subpriority'">                            
                                        <label class="form-label" for="subpriority">Task Sub Priority</label>
                                        <input pattern="/^-?\d+\.?\d*$/" onkeypress="if(this.value.length==5){ return false;}else{ return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57 }" class="form-control" type="number" placeholder="Task Sub Priority" v-model="editCell.subpriority" />
                                        <div class="errorinputmsg" v-if="this.editCell.errors.subpriority">{{ this.editCell.errors.subpriority }}</div> 
                                    </div>
                                    <div class="form-group datePicker" v-if="(editCellData.taskstatus!=editCell.editTaskStatus) && this.editCell.activeColumnFlag == 'status'">                            
                                        <label for="message-text" class="col-form-label">Status Due Date</label><br/>
                                        <date-picker placeholder="Select Due Date" class="startdate" v-model="editCell.editDueDate" :disabled-date="notBeforeTodayDueDate" valueType="format" @input="selectedtodate" v-on:pick="validateCellFormData('t9',$event)"></date-picker>
                                        <div class="errorinputmsg" v-if="this.editCell.errors.editDueDate">{{ this.editCell.errors.editDueDate }}</div>
                                    </div>
                                    <div v-if="editCell.editTaskStatus != editCellData.taskstatus">  
                                        <div class="form-group datePicker" v-if="editCell.editTaskStatus == 'Product WIP'">
                                            <label for="" class="col-form-label">Start Date</label>
                                                <date-picker placeholder="Select Start Date" v-model="editCell.startdate" valueType="format" class="startdate" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeTodayDueDate" :disabled="isdisableds"></date-picker>
                                            <div class="errorinputmsg" v-if="this.editCell.errors.startdate">{{ this.editCell.errors.startdate }}</div>
                                        </div>
                                        <div class="form-group datePicker" v-if="editCell.editTaskStatus == 'Product WIP'">
                                            <label for="" class="col-form-label">Estimated End Date</label>
                                                <date-picker placeholder="Select Estimated End Date" v-model="editCell.enddate" valueType="format" class="startdate" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeTodayDueDate"></date-picker>
                                        </div>
                                    </div>
                                    <div>  
                                        <div class="col-form-label" v-if="editCell.editTaskStatus != 'Product Pipeline' && editCell.editTaskStatus != 'Product WIP' && editCell.editTaskStatus != 'Product Pipeline' && editCell.editTaskStatus != 'Product Completed' && editCell.editTaskStatus != 'OnHold' && editCell.editTaskStatus != 'Discarded'&& editCell.editTaskStatus != 'Tech and Business Review' && editCell.editTaskStatus != 'Product WIP 2' && editCell.editTaskStatus != 'Tech Issue Resolution'">
                                            <label for="message-text" class="col-form-label">Ballpark Estimate In Day's</label>
                                            <treeselect :disabled="disableballpark" class="projectmdllistdd" placeholder="Select Ballpark Estimate In Day's" v-model="editCell.editTaskEstimates" :options="tasktype.searchEstimatesopts" @input="selBallparkestimates" @close="validateCellFormData('t9',$event)"/>
                                            <div class="errorinputmsg" v-if="this.editCell.errors.editTaskEstimates">{{ this.editCell.errors.editTaskEstimates }}</div> 
                                        </div>
                                    </div>
                                    <div v-if="editCell.editTaskStatus != editCellData.taskstatus">  
                                    <div class="form-group datePicker" v-if="editCell.editTaskStatus == 'Tech WIP'">
                                        <label for="" class="col-form-label">Start Date</label>
                                            <date-picker placeholder="Select Start Date" v-model="editCell.startdate" valueType="format" class="startdate" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeTodayDueDate"></date-picker>
                                        <div class="errorinputmsg" v-if="this.editCell.errors.startdate">{{ this.editCell.errors.startdate }}</div>
                                    </div>
                                    <div class="form-group datePicker" v-if="editCell.editTaskStatus == 'Tech WIP'">
                                        <label for="" class="col-form-label">Estimated End Date</label>
                                            <date-picker @change="onchangedate()" placeholder="Select Estimated End Date" v-model="editCell.enddate" valueType="format" class="startdate" v-on:pick="validateDate('t9',$event)" :disabled-date="notBeforeTodayDueDate"></date-picker>
                                        <div class="errorinputmsg" v-if="this.editCell.errors.enddate">{{ this.editCell.errors.enddate }}</div>
                                    </div>
                                    <div class="form-group" v-if="editCellData.estimatedddate && editCell.enddate != editCellData.estimatedddate && editCell.editTaskStatus == 'Tech WIP'">
                                        <label class="col-form-label" for="edd_reason">Estimate Update Reason </label>
                                            <textarea class="form-control" placeholder="Enter the estimate update reason here" v-model="editCell.edd_reason" v-bind:class="{'form-control':true, 'is-invalid' : !validText(editCell.edd_reason)}" ></textarea>                                        
                                            <div class="invalid-feedback" v-if="this.editCell.errors.edd_reason">{{ this.editCell.errors.edd_reason }}</div>   
                                        </div>  
                                    </div> 
                                    <div class="form-group">
                                        <label for="message-label">Description</label>
                                        <textarea class="form-control"  rows="3" placeholder="Description" v-model="editCell.editDesc" v-bind:class="{'form-control':true, 'is-invalid' : !validText(editCell.editDesc)}"></textarea>
                                        <div class="invalid-feedback">Description is required</div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn  btn-relief-secondary" @click="clearAllPopupFields()" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-relief-primary" v-on:click.stop.prevent="updateCellData('t9',$event)">Update</button>
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="modal fade taskdesctabs" id="taskdesc" tabindex="1" role="dialog" aria-labelledby="taskDescmodal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                        <h4 class="modal-title">Task description</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                        <section id="card-navigation">
                            <div class="row match-height">
                            <div class="col-md-12">
                                <div class="card mb-3">
                                    <ul class="nav nav-tabs" id="nav-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="new-tab" data-toggle="tab" href="#new" role="tab" aria-controls="new" aria-selected="true">New</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="old-tab" data-toggle="tab" href="#old" role="tab" aria-controls="old" aria-selected="false">Old</a>
                                    </li>
                                    </ul>
                                <div class="card-body">
                                    <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="new" role="tabpanel" aria-labelledby="new-tab">
                                        <p class="card-text" v-html="this.currentTaskDescription.newval"></p>
                                    </div>
                                    <div class="tab-pane fade" id="old" role="tabpanel" aria-labelledby="old-tab">
                                        <p class="card-text" v-html="this.currentTaskDescription.oldval"></p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                        </div>    
                    </div>
                    </div>
                </div>
                <div class="modal fade" id="plannerpopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                        <h4 class="modal-title">Assign Planner</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body mb-2">
                        <div class="row  flex--items">
                            <div class="col-md-6">
                            <label class="form-label lable-left">Assign Planner</label>
                            <treeselect class="projectmdllistdd" placeholder="Enter Min 3 Chars To Search  Planner Name" v-model="planner" :options="plannerlist" v-on:search-change="getPlannerList" :multiple="true"/>
                            </div>
                            <div>
                            <button type="submit" class="btn btn-relief-primary mt-2" :disabled="!planner" @click="savePlanner()"> <check-square-icon size="1.5x" class="custom-class"></check-square-icon><span> Save Planner</span></button>
                            </div>
                        </div>
                        </div>    
                    </div>
                    </div>
                </div>
                    <div class="modal fade" id="calendarModal" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content full_calendar_style_02">
                <div class="modal-header" style="text-align: center;">
                <h4 id="title" class="modal-title"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <em class="las la-times-circle"></em>
                </button>
                </div>
                <div class="modal-body mb-0">
                <div class="row">

                <div class="col-md-12 from-group mb-1">
                <label class="form-label lable-left font-weight-bolder">Name :</label>
                <span class="font-weight-normal d-block"> <a id="btcnameurl" href = "" target="_blank" > <span id="btcname"></span></a> </span>

                </div>
                <div class="col-md-6 from-group mb-1">
                    <label class="form-label lable-left font-weight-bolder"> Due Date :</label>
                    <span class="font-weight-normal d-block" id="btcduedate"></span>
                </div>
                <div class="col-md-6 from-group mb-1">
                <label class="form-label lable-left font-weight-bolder"> Task Status : </label>
                <span class="font-weight-normal d-block" id="btctaskstatus"></span>
                </div>
                <div class="col-md-6 from-group mb-1">
                <label class="form-label lable-left font-weight-bolder"> Start Date : </label>
                <span class="font-weight-normal d-block" id="btcstartdate"></span>
                </div>
                <div class="col-md-6 from-group mb-1">
                <label class="form-label lable-left font-weight-bolder"> Estimated Date :</label>
                <span class="font-weight-normal d-block" id="btcestdate"></span>
                </div>

                <div>
                </div>
                </div>
                </div>    
                </div>
                </div>
                </div>
                </section>
            </div>             
        </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { MessageSquareIcon, PlusIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon,EditIcon,SearchIcon,MoreVerticalIcon,Edit2Icon,
ClipboardIcon,EyeIcon,UploadCloudIcon,ListIcon,GridIcon,PlusCircleIcon,XIcon,AlertCircleIcon,UserIcon,HomeIcon,ExternalLinkIcon,CheckIcon,RefreshCcwIcon,CheckSquareIcon,SettingsIcon } from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import profilepic from '../profilepic.vue';
import MilestoneBtcReport from './MilestoneBtcReport';
import commonMethods from '../../utils/commonMethods';
import BTCcontexttext from "../constanttemplates/BTCcontexttext";
import ListingConfig from './ListingConfig.vue';

export default {
    name:'Business Task List',
    components:{
        Loading,PlusSquareIcon,DownloadIcon,EyeIcon,UploadCloudIcon,PlusCircleIcon,XIcon,
        VueElementLoading,MoreVerticalIcon,Edit2Icon,MinusSquareIcon,ClipboardIcon,AlertCircleIcon,
        vSelect,PlusIcon,CheckIcon,SettingsIcon,
        EditIcon,ListIcon,GridIcon,CheckSquareIcon,
        Treeselect,
        DatePicker,HomeIcon,
        ExternalLinkIcon,
        SearchIcon,
        Pagination, MessageSquareIcon,
        profilepic,
        UserIcon,
        RefreshCcwIcon,MilestoneBtcReport, BTCcontexttext,ListingConfig
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isVisible: false,
               securitylist: [
            { id: '1', label: 'Yes'},
            { id: '0',label: 'No'},
            ],
            statusconfig:apiUrl.statusconfig,
            defaultimagepath:apiUrl.defaultimagepath,
            maxLength:19,
            componentKey: 0,
            currentTaskDescription: {},
            btmversionlist: [],
            businesstaskcodelistopts:null,
            editCell: {
                editTaskStatus:null,
                editTaskEstimates: null,
                editDueDate:null,
                editDesc:null,
                btmpriority: null,
                subpriority:null,
                startdate: null,
                enddate: null,
                edd_reason: null,
                errors: {
                    editTaskStatus:null,
                    editDesc:null,
                    btmpriority:null,
                    subpriority:null,
                    startdate: null,
                    enddate: null,
                    editDueDate:null,
                    editTaskEstimates: null,
                    edd_reason: null,
                },
                activeColumnFlag: null,
            },
            bulk_priority: {
                reset_pattern: 10,
            },
            bulk_priority_options: [{ 
                  id: 10,
                  label: '+10 Pattern',
                },
                {
                  id : 100,
                  label: '+100 Pattern',
                }],
            bulkPriorityRecords:[],
            tasktype:{
                pending:[,{
                    'id':'all',
                    'label':'All'
                },{
                    'id':'Pending',
                    'label':'Pending For Approval'
                }
                ],
                name:null,
                edit:false,
                projectid:null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                desc:null,
                level:null,
                descerror:null,
                levelerror:null,
                type: null,
                key: null,
                redmineid: null,
                btmpriority: null,
                typelist:apiUrl.typelist.sort((a, b) => a.label.localeCompare(b.label)),
                admintypelist:apiUrl.admintypelist.sort((a, b) => a.label.localeCompare(b.label)),
                keylist:apiUrl.keylist.sort((a, b) => a.label.localeCompare(b.label)),
                referenceslist:apiUrl.referenceslist,
                taskstatus: [],
                searchtaskstatus: [],
                searchEstimatesopts: apiUrl.estimateshours,
                references: null,
                psh: null,
                priorityerr: null,
                dept: null,
                depterr: null,
                deptlist:apiUrl.deptlist,
                startdate: null,
                enddate: null,
                tstatus:null,
                isedit:false,
                taskid:null,
                businessname: null,
                taskmanager: [],
                taskownerlist: [],
                projectmodulelist: [],
                qaleadlist:[],
                qalead: null,
                qamanager:null,
                qamnglist:[],
                userdeptlist: [],
                countrylist: apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
            },
            requesterlist: [],
            requesterhodlist: [],
            search:{
                securitytag: null,
                plannersrch: null,
                requester: null,
                requester_hod: null,
                isredmine:null,
                searchBusinesstaskcode :null,
                businesstaskcodelistopts :null,
                searchBusinesstaskname :null,
                businesstasknameopts : null,
                searchDepartment :null,
                searchprojectname :null,
                projectnameopts :null,
                departmentopts :null,
                searchTaskseverity :null,
                taskseverityopts :null,
                searchPriority :null,
                priorityopts :null,
                searchProjectstatus :null,
                projectstatusopts :null,
                searchTasktype : [],
                tasktypeopts :null,
                searchProjectcreatedby:null,
                projectcreatedbyopts :null,
                startdatefrom:null,
                startdateto:null,
                bsh: null,
                proman: null,
                progman: null,
                searchprojectmodule: null,
                taskmngr: null,
                taskownername: null,
                engman: null,
                businessname: null,
                businessnamelist: null,
                redmineidlist:null,
                redmineid:null,
                searchEstimates: null,
                qalead: null,
                qamanager:null,
                country: null,
                userdept: null,
                billingcountry:null,
                billingcountrylist:apiUrl.countrylist.sort((a, b) => a.label.localeCompare(b.label)),
                businesscontextList:apiUrl.businesscontextList.sort((a, b) => a.label.localeCompare(b.label)),
                businesscontext:null,
                redminefilter:[
                    {
                        id:'1',
                        label:'BTC With Redmine/Reference ID'
                    },
                    {
                        id:'2',
                        label:'BTC Without Redmine/Reference ID'
                    },
                ],
                ispending: 'all',
                searchBusinesstaskstatus: null,
                btcstatusfilter:[
                    {
                        id:'delayed',
                        label:'BTC Delayed'
                    },
                    {
                        id:'deliverable',
                        label:'BTC Deliverable'
                    },
                ],
                btcviewtypelist:[
                    {
                        id:'Parent BTC',
                        label:'Parent BTC'
                    },
                    {
                        id:'Sub BTC',
                        label:'Sub BTC'
                    },
                ],
                btcviewtype: 'Parent BTC',
                duedate:null
            },
            managersList: [{ 
              id: 'hod',
              label: 'HOD',
            },
            {
              id : 477,
              label: 'Project Manager',
            },
            {
              id : 492,
              label: 'Engineering Manager',
            }],
            showtabledata: true,
            isSearchRequestActive:false,
            isBTDashboardActive:false,
            isBothDateSelected:false,
            isBTCviewtypeSelected: false,
            istrue:false,
            bshlist : [],
            pmlist: [],
            proglist: [],
            emlist: [],
            show:'display',
            isdisabled:true,
            isActive:true,
            isLoading: false,
            fullPage: true,
            islistLoading: false,
            isDashboardLoading: false,
            listfullPage:true,
            companymasterlist: [],
            tasmanagersList: [{ 
                  id: 'hod',
                  label: 'HOD',
                },
                {
                  id : 477,
                  label: 'Project Manager',
                },
                {
                  id : 492,
                  label: 'Engineering Manager',
            }],
            ktypelist: [],
            typenamelist: [],
            projectlist: [],
            taskassignmentList:[],
            showAddForm:false,
            selected: null,
            editRowData:[],
            editCellData: [],
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            projectType:[],
            projectdescription:'',
            tasktypekeylist:apiUrl.tasktypekeylist,
            tasknamemaster:apiUrl.tasknamemaster,
            taskstatus: [],
            taskBlured:false,
            valid : false, 
            projvalue:null,
            applvalue:null,
            applicoptions:[ {
                id: '',
                label: ''
            }],
            startdate:null,
            tasklevel:null,
            tasklevellist:[ {
                id: '',
                label: ''
            }],
            isAddState:false,
            totalcount: 0,
            totalcountsubtask: 0,
            pageCount: 0,
            pageCountsubtask: 0,
            page:1,
            pagesubtask:1,
            pagelimit: 100,//apiUrl.LIMIT,
            pagelimitsubtask: 100,//apiUrl.LIMIT,
            currPageNum: 0,
            currPageNumsubtask: 0,
            pageoffset:1,
            pageoffsetsubtask:1,
            pagefeatures:[],
            projectnameist:[],
            loginuscondition:'',
            isDetActive:false,
            isListDetActive:true,
            isListActive:true,
            isListDetailsDataActive:true,
            isListDetailsActive:true, 
            chartDetails:true, 
            displayblk:'displayblk',
            isgridView:true,
            blog_list_wrapper :'blog-list-wrapper',
            isRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',
            files:[],
            loginusername:'',
            loginpersonname:'',
            addocument:true,
            editdocument: true,
            card:"card",
            border_warning:'border-warning',
            sitebluecolor:"sitebluecolor",
            activegridlink:false,
            activesubtasklink:false,
            activelistlink:false,
            tokendata:null,
            isresponce:false,
            order:null,
            labelname:null,
            businessTaskDetails:[],
            businessTaskDetailsSum: [],
            taskstartdate: this.dateFormatnew(new Date()),
            isdisableds: false,
            estimatedate: null,
            businesstaskidED: null,
            muatdate:null,
            medd_reason:null,
            userrole:null,
            isListDetailsDataActives:false,
            ischartDetailsActives:false,  
            isboardDetailsActives:false,
            iscalendarDetailsActives:false,
            isSelectAllChecked: false,
            isChecked: [],
            plannerlist: [],
            srchplannerlist: [],
            planner: [],
            subtaskbtcwithid: [],
            btaskstatus: [],
            chartdate: null,
            disblesavebtn:false,
            selectedModuleId: null,
            boardApiList: [],
            boardApiListTemp: [],
            activeboardlink:false,
            p_totalcount: 0,
            p_pageCount: 0,
            current_page: 1,
            records_per_page: 10,
            p_currPageNum: 0,
            p_pageoffset: 1,
            p_pagefeatures:[],
            p_showtabledata:true,
            calendarEvents : [],
            btcviewtype : 'Parent BTC',
            btcviewtypeErr :null,
            userDepName: null,
            depOfBAReviewTasks: apiUrl.depOfBAReviewTasks,
            searchactiveflag: false,
            btclistingcolumns: [],
            btclistingrows: [],
            loginroleid: null,
            ballparkvalitestatus: [],
            disableballpark: false,
        }
    },  
    created() {
        this.pagefeatures = {}
        if(window.localStorage.getItem('pagefeatures')){
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
                if (element.page === '/businesstask/list') {
                    this.pagefeatures[element.featurename] = element.featureaccess
                }
            })
        }
    },
    mounted(){
        this.calendar = this.initializeCalendar();
        window.addEventListener('storage', function(event){
            if (event.key == 'logout-mee') { 
              window.location.reload();
            }
        });
        
        let permission = this.$Service.verifyAccess();
        if(permission){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.loginusername=userdata.username
                this.loginroleid = this.undt.roleid
                this.loginpersonname=userdata.firstname+' '+userdata.lastname;
                this.loginuscondition = userdata.userid
                this.tokendata = window.localStorage.getItem('token');
                this.userrole = window.localStorage.getItem("userrole")
                localStorage.setItem('activebtctab', 'listview');
                if (this.$route.params.backbtnstate == "ProjectSummaryBTask") {
                  let dataSplit = this.$route.params.clickeddata.split('_');
                  this.search.btcviewtype = this.$route.params.btctype;
                  this.search.searchProjectstatus = [dataSplit[1]];
                  this.search.searchTasktype = [dataSplit[0]];
                  this.search.searchprojectname = [this.$route.params.pid];
                //   let search = {
                //     searchProjectstatus: [dataSplit[1]],
                //     searchTasktype: [dataSplit[0]],
                //     searchprojectname: [this.$route.params.pid]
                //   }
                  this.masterlist('t9', this.search, 1); 
                } else if (this.$route.params.backbtnstate === "HodBTReport") {
                  let search = this.$route.params.search;
                  this.masterlist('t9', search, 1);
                } else if (this.$route.params.search) {
                    let search = JSON.parse(localStorage.getItem('businesstasksearchItems'))
                    if(search) {
                        this.getSearchItems(search)
                    }
                    // this.masterlist('t9', search)
                    if(this.$route.params.activebtctab == "chartview") {
                        this.chartShowView();
                    }else if(this.$route.params.activebtctab == "gridview") {
                        this.myGridView();
                    }else if(this.$route.params.activebtctab == "boardview") {
                        this.boardShowView();
                    } else {
                        this.myListView();
                    }
                } else {
                    localStorage.removeItem('businesstasksearchItems');
                    this.search.searchTasktype = [];
                    this.search.btcviewtype= 'Parent BTC';
                    this.masterlistnew('t9');
                    this.getProjectAllocatedToEmp();

                }
                this.getBusinessname();
                this.getBusinessPriorityList()
                this.getBSHlist(null, null);
                this.getProjectManagerDetails(477,null)
                this.getProjectManagerDetails(492,null)
                this.getProjectManagerDetails(503,null)
                this.getsorting();
                this.getProjectAllocatedToEmp();
                this.getTaskManagerOwnerList(131,null)
                this.getTaskManagerOwnerList(513,null)
                this.getProjectQAdata(509,null);
                this.getProjectQAdata(508,null);
                this.getuserdept();
                this.getUserDepName();
                this.getBtcStatusList();
            }
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    watch: {
        calendarEvents: {
        handler() {
            
        this.calendar.removeAllEvents();
            this.calendar.addEventSource(this.calendarEvents);
        },
        deep: true
        }
    },
    methods:{
        toggleVisibility() {
            this.isVisible = !this.isVisible;
        },
        getspecificbtcstatus(status, taskestimates) {
            let url = "api/bt/getspecificbtcstatus";
            this.input = {
                useremail: this.undt.username,
                empcode: parseInt(this.undt.userid),
                modulestatus: 'Tech Pipeline',
                moduletype: 'Businesstask',
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if (result.data.errorCode == 0) {
                    this.ballparkvalitestatus = result.data.data.rows.statuslist;
                    if(this.ballparkvalitestatus && this.ballparkvalitestatus != null && this.ballparkvalitestatus != '') {
                        let arr = this.ballparkvalitestatus.split(',');
                        const found = arr.some(el => el == status);
                        if(found) {
                            if(taskestimates != null && taskestimates != ''){
                                this.disableballpark = true;
                            }
                        }
                    }
                } else {
                    this.ballparkvalitestatus = [];
                    this.disableballpark = false;
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getBtcStatusList() {
            let url = "api/btc/getBtcStatusList";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.searchtaskstatus = result.data.data.rows;
                } else {
                    this.tasktype.searchtaskstatus = [];
                }
            }).catch(e => {
                this.displayError(e)
            });     
        },
        selRequester(state) {
            if(state){
                this.getRequesterHODOnSearch(state);
                this.getSelRequester(state)
            } else {
                this.requesterlist = [];
            }
        },
        getSelRequester(id) {
            if(id && id != null) {
                this.isLoading = true;
                let url = "api/master/getemployeebyname";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    empids: [id],
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.requesterlist = result.data.data.rows;
                        let decryptdesignationtext = this.requesterlist.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                    } else {
                        this.requesterlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } else {
                this.requesterlist = [];
            }
        },
        getRequesterOnSearch(node) {
            if(node != null && node != '' && node.length > 4) {
                this.isLoading = true;
                let url = "api/master/getemployeebyname";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    empname: node ? node.toLowerCase() : null,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.requesterlist = result.data.data.rows;
                        let decryptdesignationtext = this.requesterlist.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                    } else {
                        this.requesterlist = [];
                    }
                }).catch(e => {
                this.displayError(e)
            });
            } else {
                this.requesterlist = [];
            }
        },
        getRequesterHODOnSearch(id) {
            this.isLoading = true;
            let url = "api/listing/getRequesterOnSearch";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                requesterid: id,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.requesterhodlist = result.data.data.rows;
                } else {
                    this.requesterhodlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getUserDepName(){
            this.apiURL="api/profile/getUserDepartment"
            this.input={
                empcode: this.undt.userid,
                useremail:this.undt.username,
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.status){
                    let response = result.data.data;
                    this.userDepName = response[0].department
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        prevPage() {
            if (this.current_page > 1) {
                this.current_page--;
                this.paginate(this.current_page, this.records_per_page);
            }
        },
        nextPage() {
            if (parseInt(this.current_page) < (parseInt(this.numPages()))) {
                this.current_page++;
                this.paginate(this.current_page, this.records_per_page);
            }
        },
        paginate(page, pageSize) {
            this.boardApiListTemp.forEach((element, k) => {
                let startIndex = (page - 1) * pageSize
                let endIndex = Math.min(page * pageSize, element[1].length - 1);
                if((element[1].length-1) == endIndex) { endIndex = element[1].length }
                this.boardApiList[k][1] = [];
                if(element[1].length>10) {
                    this.boardApiList[k][1] = element[1].slice(startIndex, endIndex);
                } else {
                    this.boardApiList[k][1] = element[1];
                }
            });
            this.boardApiList = this.boardApiList.map(item=> item.slice());
        
            $('#page').html(page);

            if (page < 1) page = 1;
            if (page > this.numPages()) page = this.numPages();

            if (page == 1) {
                $('#btn_prev').hide();
            } else {
                $('#btn_prev').show();
            }

            if (page == this.numPages()) {
                $('#btn_next').hide();               
            } else {
                $('#btn_next').show();
            }
        },
        numPages()
        {   
            let numArr = [];
            this.boardApiListTemp.forEach(ele => {
                numArr.push(ele[1].length)
            });
            let maxno = (numArr.length>0) ? Math.max.apply(Math, numArr) : null;
            if(maxno){
                return Math.ceil(maxno / this.records_per_page);
            }
        },
        getTaskMasterBroadlist(search) {
            this.isLoading = true;
            if(search) {
                search.limit = null;
                search.offset = null;
                this.input = search
            } else {
                this.input = {
                    empcode: this.undt.userid,
                    empid: this.undt.userid,
                    useremail:this.undt.username,
                    btcviewtype : this.btcviewtype,
                }
            }
            axios({
                method: "POST",
                url: "api/master/taskmasterbroadwise/getTaskMasterBroadlist",
                data: this.input,
                headers: { authorization : this.tokendata },
            })
            .then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    let tasklist = this.tasktype.searchtaskstatus;
                    this.boardApiList = Object.entries(result.data.data.rows);
                    this.boardApiList.forEach((status, k) => {
                        let statusval = status[0];
                        const found = tasklist.find(obj => obj.id === statusval);
                        if(found){
                            this.boardApiList[k].push(found.sno);
                        }
                    });
                    this.boardApiList = this.boardApiList.sort((a,b) => a[2] - b[2]);
                    this.boardApiListTemp = this.boardApiList.map(item => item.slice());
                    this.paginate(1, 10);
                } else {
                    this.boardApiList = [];
                    this.boardApiListTemp = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        selectAll() {
            if(!this.isSelectAllChecked) {
                this.isChecked = Array(this.totalcount).fill(true)
            } else {
                this.isChecked = Array(this.totalcount).fill(false)
            }
        },
        alterSelectAllCheck(index) {
            if(this.isChecked[index]) {
                this.isSelectAllChecked = false
            } else {
                this.isSelectAllChecked = this.isChecked.every((i,idx) => {
                    if(idx !== index) {
                       return i === true
                    } else {
                        return true
                    }
                })
            }
        },
        alert(isc) {
            if(this.isSelectAllChecked === true || this.isChecked.length > 0) {
                $('#plannerpopup').modal('show');   
            }
        },
        savePlanner() {
            let businessTaskId = []
            let blist = [];
            if(this.btclistingrows && this.btclistingrows.length>0) {
                blist = this.btclistingrows;
                blist.map((btm, idx) => {
                if(this.isChecked[idx]) {
                    businessTaskId.push(btm.businesstaskmasterid)
                }
            })
            } else {
                blist = this.companymasterlist;
                blist.map((btm, idx) => {
                if(this.isChecked[idx]) {
                    businessTaskId.push(btm.id)
                }
            })
            }
            this.inputappl = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                businessTaskId: businessTaskId,
                assignplan: this.planner,
            }
            let apiURL = 'api/assignPlan'
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.planner = null,
                $('#plannerpopup').modal('hide');
                if(result.data.errorCode === 0) {
                    this.masterlist();
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: "success",
                        customClass: { confirmButton: "btn btn-primary" },
                        buttonsStyling: !1,
                    })
                } else if (result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                    })
                } else {
                    Swal.fire({
                    text: result.data.msg,
                    icon: "info",
                    customClass: { confirmButton: "btn btn-primary" },
                    buttonsStyling: !1,
                    })
                }
                this.isChecked = []
            }).catch(e => {
                this.displayError(e)
            })
        },
        closed(){
            $('#estimatedatepop').modal('hide');
            this.estimatedate = null;
            this.businesstaskidED = null;
            this.medd_reason=null
            this.muatdate=null
        },
        tabledetailTr(id){
            this.subtaskbtcwithid = [];
            let isOpen = $('.mov'+id).is(':visible')
            $('.main-btc').toggle();
            $('.mov'+id).toggle();
            $('.mov'+id).toggle();
            this.selectedModuleId = id
            if(!isOpen) {
                this.getSubBTCData(id);   
            }
        },
        onchangedate(){
            if(this.editCell.enddate == null){
                this.editCell.edd_reason = null;
            }
        },
        reqToApprovedEstiDate(edate, businesstaskid,edd_reason,uatdate){
            this.estimatedate = edate
            this.businesstaskidED = businesstaskid
            this.medd_reason = edd_reason
            this.muatdate=uatdate
            $('#estimatedatepop').modal('show');
        },
        approvedEstiDate(edate, businesstaskid){
          axios({
            method: "POST",
            url: "api/businesstask/approvedEstimateDate",
            data: { businesstaskid: parseInt(businesstaskid), empid: this.undt.userid, updatedby: this.undt.username, edate: edate ,empcode: this.undt.userid,useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              $('#estimatedatepop').modal('hide');
              this.estimatedate = null;
              this.businesstaskidED = null;
              Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: 'success',
                  customClass: {
                    confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
                })
                this.masterlist('t9')
            } 
          }).catch(e => {
                this.displayError(e)
            });
        },
        getBusinessTaskDetailsVSSeverity: function(search){
          this.isDashboardLoading = true;
          let url = "api/business/getBusinessTaskDetailsVSSeverity";
          this.statusinput = {
            useremail: this.undt.username,
            empid: parseInt(this.undt.userid),
            redminefilter:this.search.isredmine,
            empcode: this.undt.userid,
            userrole:this.userrole,
            btcviewtype: this.btcviewtype,
          };
            if(search) {
                if(search.searchBusinesstaskcode && search.searchBusinesstaskcode != null && search.searchBusinesstaskcode != '') {
                    if(search.searchBusinesstaskcode.trim().length > 17) {
                        this.statusinput.btcviewtype = 'Sub BTC'
                    } else {
                        this.statusinput.btcviewtype = 'Parent BTC'
                    }
                }
                this.statusinput.businesstaskcode =  search.searchBusinesstaskcode,
                this.statusinput.taskname =  search.searchBusinesstaskname,
                this.statusinput.projectname = search.searchprojectname,
                this.statusinput.department =  search.searchDepartment,
                this.statusinput.taskkey =  search.searchTaskseverity,
                this.statusinput.priority =  search.searchPriority,
                this.statusinput.taskstatus =  search.searchProjectstatus,
                this.statusinput.taskestimates = search.searchEstimates,
                this.statusinput.tasktype =  search.searchTasktype,
                this.statusinput.createdby =  search.searchProjectcreatedby,
                this.statusinput.plannersrch = search.plannersrch
                let assignedate=''
                if(this.search.startdateto){
                    assignedate = new Date(this.search.startdateto)
                    assignedate.setDate(assignedate.getDate() + 1)
                    moment(String(assignedate)).format('YYYY-MM-DD');
                }
                this.statusinput.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
                this.statusinput.dateto = this.search.startdateto?assignedate:null
                this.statusinput.bsh = search.bsh?search.bsh:null
                this.statusinput.proman = search.proman?search.proman: null
                this.statusinput.progman = search.progman?search.progman: null
                this.statusinput.requester = search.requester?search.requester: null
                this.statusinput.requester_hod = search.requester_hod?search.requester_hod: null
                this.statusinput.engman = search.engman?search.engman: null
                this.statusinput.businessname = search.businessname?search.businessname: null
                this.statusinput.redmineid = search.redmineid?search.redmineid: null
                this.statusinput.taskownername = search.taskownername?search.taskownername: null
                this.statusinput.taskmngr = search.taskmngr?search.taskmngr: null
                this.statusinput.projectmodule = search.searchprojectmodule?search.searchprojectmodule: null
                this.statusinput.qamanager = search.qamanager?search.qamanager:null
                this.statusinput.qalead = search.qalead?search.qalead:null
                this.statusinput.country = search.country?search.country:null
                this.statusinput.userdept = search.userdept?search.userdept:null
                this.statusinput.billingcountry = this.search.billingcountry? this.search.billingcountry:null
                this.statusinput.businesscontext = this.search.businesscontext? this.search.businesscontext:null
                this.statusinput.securitytag = this.search.securitytag
            }
          axios({
            method: "POST",
            url: url,
            data: this.statusinput,
            'headers':{'authorization':this.tokendata}
            }).then((result) => {
            this.isDashboardLoading = false;
            if (result.data.errorCode == 0) {
              let myData = result.data.data.rows;
              this.businessTaskDetails = myData;
              this.businessTaskDetailsSum = result.data.data.sum;
            } else if(result.data.errorCode == 3){
            Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            } else {
              this.businessTaskDetails = [];
              this.businessTaskDetailsSum = [];
            }
          }).catch(e => {
                this.displayError(e)
            });
      },
      countClickableToCount(status, type){
        let assignedate = '';
        if(this.search.startdateto){
            assignedate = new Date(this.search.startdateto)
            assignedate.setDate(assignedate.getDate() + 1)
            moment(String(assignedate)).format('YYYY-MM-DD');
        }
        let search = {
            searchProjectstatus: [status],
            searchTasktype: [type],
            searchBusinesstaskcode: this.search.searchBusinesstaskcode,
            searchBusinesstaskname: this.search.searchBusinesstaskname,
            searchprojectname: this.search.searchprojectname,
            searchDepartment: this.search.searchDepartment,
            searchTaskseverity: this.search.searchTaskseverity,
            searchPriority: this.search.searchPriority,
            searchEstimates: this.search.searchEstimates,
            createdby: this.search.searchProjectcreatedby,
            proman: this.search.proman,
            progman: this.search.progman,
            requester: this.search.requester,
            requester_hod: this.search.requester_hod,
            engman: this.search.engman,
            taskownername: this.search.taskownername,
            taskmngr: this.search.taskmngr,
            projectmodule: this.search.searchprojectmodule,
            bsh: this.search.bsh,
            datefrom: this.search.startdatefrom,
            dateto: assignedate,
            businessname: this.search.businessname,
            redmineid: this.search.redmineid,
            qamanager: this.search.qamanager,
            qalead: this.search.qalead,
            country: this.search.country,
            userdept: this.search.userdept
        }
        this.masterlist('t9', search, 1, true); 
      },
        getsorting() {
            let globalthis = this
            $(function () {
            $('table')
                .on('click', 'th.bussiness', function () {
                let index = $(this).index(),
                    rows = [],
                    thClass = $(this).hasClass('asc') ? 'desc' : 'asc';

                let listinglbl = '';
                if($(this).closest('table').attr('id')  ==  'table_businessceo_subtask') {
                    listinglbl = 'table_businessceo_subtask';
                } else {
                    listinglbl = 'table_businessceo1';
                }
                $('#'+listinglbl+ ' th').removeClass('asc desc');

                $(this).addClass(thClass);
                globalthis.labelname = this.innerHTML

                $('#'+listinglbl+ ' tbody tr').each(function (index, row) {
                    rows.push($(row).detach());
                });

                rows.sort(function (a, b) {
                    let aValue = $(a).find('td').eq(index).text(),
                        bValue = $(b).find('td').eq(index).text();
                        
                    if (!isNaN(aValue) && !isNaN(bValue)) {
                        aValue = Number(aValue)
                        bValue= Number(bValue)
                    }

                    return aValue > bValue
                        ? 1
                        : aValue < bValue
                        ? -1
                        : 0;
                });

                if ($(this).hasClass('desc')) {
                    rows.reverse();
                    globalthis.order='DESC'
                }else{
                    globalthis.order='ASC'
                }
                
                globalthis.masterlist('t9')

                $.each(rows, function (index, row) {
                    $('#'+listinglbl+' tbody').append(row);
                });
                });
            });
        },
        getTaskStatusByCondition(prevStatus, bid) {
           let tasklist = this.tasktype.searchtaskstatus;
           let btcStatusBranching = [];
           let pushedCurrStatus =  { id: prevStatus, label: prevStatus }
           btcStatusBranching.push(pushedCurrStatus);
           tasklist.forEach(function(element, index) {
            if(element.parentkey != undefined)
                for(let i=0; i<=(element.parentkey).length;i++) {
                    if(element.parentkey[i] == prevStatus) {
                        btcStatusBranching.push(element);       
                    }
                }
            });

            if (prevStatus == 'OnHold' || prevStatus == 'Discarded') {
                axios({
                    method: "POST",
                    url: "api/master/businesstask/getPrevStatusFromLogs",
                    data: { status: prevStatus, businesstaskId: bid,empcode: this.undt.userid,useremail: this.undt.username },
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        let pushedVar = { id: result.data.data[0].oldval,label: result.data.data[0].oldval }
                        btcStatusBranching.push(pushedVar);
                    }
                    else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
           this.tasktype.taskstatus = btcStatusBranching;
        },
        forceRerender() {
            this.componentKey += 1;
        },
        redirecttoprofile(empid){
            let route = this.$router.resolve({ path: "/userprofile?empid="+empid});
            window.open(route.href);
        },
        formatData(data) {
            return data
        },
        getBusinessname() {
            this.input = {
                empcode: this.undt.userid,
                useremail:this.undt.username
            }
            axios({
                method: "POST",
                url: "api/listing/getBusinessname",
                data: this.input,
                headers: { authorization : this.tokendata },
            })
            .then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.search.businessnamelist = result.data.data.rows
                } else {
                    this.search.businessnamelist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectManagerDetails(roleid,projectid) {
            axios({
                method: "POST",
                url: "api/listing/getProjectManagerDetails",
                headers: { authorization : this.tokendata },
                data:{roleid:roleid,projectidarr:projectid,empcode: this.undt.userid,useremail: this.undt.username}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    if(roleid==477){
                        this.tasktype.pmlist = result.data.data.rows
                        this.tasktype.pmlist.sort((a, b) => {
                         return a.label.localeCompare(b.label);
                        });
                    }else if(roleid==492){
                        this.tasktype.emlist = result.data.data.rows
                        this.tasktype.emlist.sort((a, b) => {
                         return a.label.localeCompare(b.label);
                        });
                    }else if(roleid==503){
                        this.tasktype.proglist = result.data.data.rows
                        this.tasktype.proglist.sort((a, b) => {
                         return a.label.localeCompare(b.label);
                        });
                    }          
                }else{
                    if(roleid==477){
                        this.tasktype.pmlist = [];
                    }else if(roleid==492){
                        this.tasktype.emlist = [];
                    }else if(roleid==503){
                        this.tasktype.proglist = [];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getProjectmodulefromProject(projectid) {
            axios({
                method: "POST",
                url: "api/master/businesstask/getprojectmodulebyprojectid",
                data: { projectidarr:projectid, moduletype: 'businesstask',empcode: this.undt.userid,
                useremail: this.undt.username },
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                this.tasktype.projectmodulelist = result.data.data
                } else {
                this.tasktype.projectmodulelist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getTaskManagerOwnerList(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==131){
                        this.tasktype.taskmanager=result.data.data.rows;
                    }else if (roleid==513){
                        this.tasktype.taskownerlist=result.data.data.rows;
                    }
                }else{
                    if(roleid==131){
                        this.tasktype.taskmanager=[]
                    }else if (roleid==513){
                        this.tasktype.taskownerlist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getProjectQAdata(roleid,state){
            this.inputappl = {
                projectid: this.projectid,
                empcode: this.undt.userid,
                useremail: this.undt.username
            }
            axios({
            'method': 'POST',
            'url': 'api/listing/getProjectManagerDetails',
            'data': {roleid:roleid,projectidarr:state,empcode: this.undt.userid,
            useremail: this.undt.username},
            'headers':{'authorization':this.tokendata}
            })
            .then(result => { 
                this.isLoading= false;         
                if(result.data.errorCode == 0){
                    if(roleid==509){
                        this.tasktype.qaleadlist=result.data.data.rows;
                    }else if (roleid==508){
                        this.tasktype.qamnglist=result.data.data.rows;
                    }
                }else{
                    if(roleid==509){
                        this.tasktype.qaleadlist=[]
                    }else if (roleid==508){
                        this.tasktype.qamnglist=[];
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        selprojdata(state){
            this.search.projectmodule=null
            this.search.projectid=state
            this.search.taskmngr=null
            this.search.taskownername=null
            if(!state){
                this.tasktype.projectmodulelist =null
            }else{                
                this.getProjectmodulefromProject(state)
                this.getTaskManagerOwnerList(131,state)
                this.getTaskManagerOwnerList(513,state)
                this.getProjectQAdata(509,state);
                this.getProjectQAdata(508,state);
            }
        },
        addPatternAmt: function() {
            let pattern = this.bulk_priority.reset_pattern;
                let list = this.bulkPriorityRecords;
                let newArr = list.map(function(val, index){
                    return {
                        businesstaskcode:val.businesstaskcode, 
                        redmineid:val.redmineid, 
                        priority:(parseInt(val.priority)+parseInt(pattern)), 
                        old_priority:val.priority, 
                        subpriority:val.subpriority, 
                        modulename:val.modulename, 
                        taskstatus:val.taskstatus,
                        id:val.moduleid,
                    };
            });
            this.bulkPriorityRecords = newArr;
            $('#bulkUpdateBtn').show();
            $('#addPattern').hide();
        },
        bulkUpdatePrio: function() {
            let oldPrioIds = Array.from(this.bulkPriorityRecords, function (item, index) {
                return { id: item.id, old_priority:item.priority, taskstatus:item.taskstatus }
            });
            this.isLoading = true
            let apiurl = `api/master/businesstask/bulkUpdatePatternWise`
            axios({
              method: 'POST',
              url: apiurl,
              data: {
                btmdata: oldPrioIds,
                empid: this.undt.userid,
                lastmodifiedby: this.undt.username,
                reset_pattern: this.bulk_priority.reset_pattern,
                empcode: this.undt.userid,
                useremail: this.undt.username
              },
              headers: { authorization :this.tokendata }
            }).then(result => {
              this.isLoading = false
              if(result.data.errorCode == 0) {
                Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: 'success',
                  customClass: {
                    confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
                })
                this.resetBTPriority();
                $('#bulkUpdateBtn').hide();
                $('#addPattern').show();
                $('#update_'+data.moduleid).hide();
              } else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                Swal.fire({
                  title: "Failed",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                    confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
                })
              }
            }).catch(e => {
                this.displayError(e)
            })
        },
        editCurrentRow: function(data) {
            let apiurl = `api/master/businesstask/bulkUpdateBTPriorities`
            axios({
              method: 'POST',
              url: apiurl,
              data: {
                id: parseInt(data.moduleid),
                priority: data.priority,
                taskstatus: data.taskstatus,
                empid: this.undt.userid,
                lastmodifiedby: this.undt.username,
                empcode: this.undt.userid,
                useremail: this.undt.username
              },
              headers: { authorization :this.tokendata }
            }).then(result => {
              this.isLoading = false
              if(result.data.errorCode == 0) {
                Swal.fire({
                  title: "Success!",
                  text: result.data.msg,
                  icon: 'success',
                  customClass: {
                    confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
                })
                this.resetBTPriority();
                $('#update_'+data.moduleid).hide();
              } else if(result.data.errorCode == 3){
                Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                }).then(function() {
                    window.location.href = "/#/login";
                })
            } else {
                Swal.fire({
                  title: "Failed",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                    confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
                })
              }
            }).catch(e => {
                this.displayError(e)
            }) 
        },
        enableUpdateBtn(id, priority) {
            if(priority){
                $('#update_'+id).show();
            } else {
                $('#update_'+id).hide();
            }
        },
        clearAllBTPriorityPopupFields() {
            this.bulk_priority.reset_pattern = 10;
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate
            if(btnstate=="view"){
                let routeData = this.$router.resolve({ 
                    path: '/businesstask/view?businessid='+rowdata.businesstaskmasterid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            }else if(btnstate=="edit"){
                this.$router.push({ 
                    path: '/businesstask/update?businessid='+rowdata.businesstaskmasterid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            } else if(btnstate=="parentview"){
                rowdata.moduleid = rowdata.parentbusinesstaskmasterid;
                let routeData = this.$router.resolve({ 
                    path: '/businesstask/view?businessid='+rowdata.businesstaskmasterid,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
                window.open(routeData.href, '_blank');
            } else if (btnstate == 'projectID') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectist
                })
            } else if (btnstate == 'projectIDSub') {
                this.$router.push({ 
                    path: '/project/summary?pid='+rowdata.projectid
                })
            }
            
        },
        getBSHlist(dept, pid) {
          this.search.bsh=null
          this.bshlist = [];
          axios({
              method: "POST",
              url: "api/listing/getBTCHODlist",
              data: { dept:dept,empcode: this.undt.userid,useremail: this.undt.username,projectid:pid },
              headers: { authorization : this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.bshlist = result.data.data.rows
              } else {
                  this.bshlist = [];
              }
          }).catch(e => {
                this.displayError(e)
            });
        },
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        businessTaskDashboardCollapse:function(searchhh, searchflag){
            this.isBTDashboardActive=!this.isBTDashboardActive
            if(this.isBTDashboardActive==true)
            {
                let search = '';
                if (searchflag == true) {
                    search = searchhh;
                } 
                this.masterlist('t9', search);              
            }
        },
        mydetailsollapse:function(){
            this.isDetActive=!this.isDetActive
        },
        formatName(authorstring) {
            let author=authorstring.replace('@firstcry.com','')
            if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
            author = author.replace('.',' ')
            author = author.charAt(0).toUpperCase() + author.slice(1);
            return author
        },
        opentaskdesc(event) {
            this.currentTaskDescription = event
            $('#taskdesc').modal('show');
        },
        getSubBTCData(bid) {
            let url = 'api/bt/getBTCsubTasksModule'
            this.input = {
               useremail: this.undt.username,
               empcode: this.undt.userid,
               empid: this.undt.userid,
               id :  bid
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata},
            })
            .then(result => {  
                if(result.data.errorCode == 0){   
                    this.subtaskbtcwithid = result.data.data.rows;
                }else{
                    this.subtaskbtcwithid = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getBusinessTaskVersion(businesstaskid, ...args) {
            let btmInfo = { taskname: args[0], author:args[1], createdat: args[2] }
            this.isLoading = true
            let apiUrl = 'api/master/businesstask/getHistoryByBusinesstaskid'
            this.input = { businesstaskid,empcode: this.undt.userid,useremail: this.undt.username }
            axios({'method':'POST','url':apiUrl,'data': this.input,'headers':{'authorization':this.tokendata}})
            .then(result => {
                this.isLoading = false
                $('#technologypopup').modal('show');
                if(result.data.errorCode == 0) {
                    this.btmversionlist = result.data.data.rows
                    this.btmversionlist.unshift(btmInfo)
                } else {
                    this.btmversionlist = []
                    this.btmversionlist.unshift(btmInfo)
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        securityJustReadmore(){
            if ($("#ssjudtid").hasClass("readmore")) {
                $("#ssjudtidid5").html("See less");
                $("#ssjudtid").removeClass("readmore");
                this.ssreadmore = 'outer'
                $('#otherfields').show();
            } else {
                $("#ssjudtidid5").html("See all");
                $("#otherfields").hide();
                $("#ssjudtid").addClass("readmore");
                this.ssreadmore = 'outer readmore'
            }
        },
        editStatusCell(moduleid, type, currStatus, projectist) {
            console.log(type,moduleid)
            this.editCell.activeColumnFlag = type;
            this.isLoading = true
            if(moduleid){
                this.isLoading = false;
                $('#statusEditPopup').modal('show');

                let url = "api/master/businesstask/getBTCellData";
                this.input = {
                    businesstaskmasterid:moduleid,
                    useremail: this.undt.username,
                    empid:this.undt.userid,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.editCellData = result.data.data;
                        this.editCellData = this.editCellData[0]
                        this.editCell.editTaskStatus = this.editCellData.taskstatus
                        this.editCell.btmpriority = this.editCellData.priority
                        this.editCell.subpriority = this.editCellData.subpriority
                        this.editCell.editDueDate = this.editCellData.due_date
                        this.editCell.editTaskEstimates = this.editCellData.taskestimates
                        this.editCell.startdate = this.editCellData.startdate;
                        this.editCell.enddate = this.editCellData.estimatedddate;
                        this.editCell.department = this.editCellData.department;
                        this.editCell.productstackholder = this.editCellData.productstackholder;
                        if(this.editCellData.parentbtcid == null && this.editCellData.isshow == null){
                            this.tasktype.taskstatus = [];
                            this.tasktype.taskstatus.push({id:'OnHold',label:'OnHold'}, {id:'Discarded',label:'Discarded'}, { id: this.editCellData.taskstatus, label: this.editCellData.taskstatus});
                            let known = {}
                            this.tasktype.taskstatus = this.tasktype.taskstatus.filter(item => !known.hasOwnProperty(item.id) && (known[item.id] = true)
                            )
                        } else {
                            if(this.editCellData.projectidlist){
                                this.getBStatusConfigList(this.editCellData.projectidlist.split(","), this.editCellData.taskstatus, moduleid);
                            }
                        }
                        this.getspecificbtcstatus(this.editCellData.taskstatus, this.editCellData.taskestimates)

                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                            }).then(function() {
                                window.location.href = "/#/login";
                            })
                        }else {
                        this.editCellData = [];
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },       
        myListView: function () {  
            this.isListDetActive = true;
            this.isgridView=true;
            this.isListDetailsActive = true;
            this.chartDetails=true;
            this.activegridlink=false;
            this.activeboardlink = false;
            this.activesubtasklink = false;
            this.activelistlink=true  
            this.isListDetailsDataActives=false  
            this.ischartDetailsActives=false  
            this.isboardDetailsActives=false  
            this.iscalendarDetailsActives=false
            this.masterlistnew('t9');
            localStorage.setItem('activebtctab', 'listview');
        },
        myGridView(){
            this.isListDetActive = false;
            this.isgridView=false;
            this.activegridlink=true;
            this.activelistlink=false;
            this.isListDetailsActive=true;
            this.chartDetails=true;
            this.activeboardlink = false;
            this.activesubtasklink = false;
            this.isListDetailsDataActives=false;
            this.ischartDetailsActives=false;
            this.isboardDetailsActives=false; 
            this.iscalendarDetailsActives=false;
            localStorage.setItem('activebtctab', 'gridview');
            this.masterlist('t9');
        },
        chartShowView(){
            var chartWrapper = $('.chartjs')

            if (chartWrapper.length) {
            chartWrapper.each(function () {
            $(this).wrap($('<div style="height:' + this.getAttribute('data-height') + 'px"></div>'));
            });
            }
            this.isListDetActive = false;
            this.isgridView=true;
            this.isListDetailsActive=true;
            this.chartDetails=false;
            this.activesubtasklink = false;
            this.activegridlink=false;
            this.activelistlink=false;
            this.isListDetailsDataActives=false;
            this.activeboardlink = false;
            this.ischartDetailsActives=true;
            this.isboardDetailsActives=false;
            this.iscalendarDetailsActives=false
            localStorage.setItem('activebtctab', 'chartview');
            this.getbusinesstaskstatuscount();
           
        },
        boardShowView(){
            this.isListDetActive = false;
            this.isgridView=true;
            this.isListDetailsActive=false;
            this.chartDetails=false;
            this.activesubtasklink = false;
            this.activegridlink=false;
            this.activeboardlink = true;
            this.activelistlink=false;
            this.isListDetailsDataActives=false;
            this.ischartDetailsActives=false;
            this.isboardDetailsActives=true;
            localStorage.setItem('activebtctab', 'boardview');
            //this.getTaskMasterBroadlist();
            this.masterlist('t9');
            this.iscalendarDetailsActives=false;
        },
        calendarShowView(){
            this.isListDetActive = false;
            this.isgridView=true;
            this.isListDetailsActive=true;
            this.chartDetails=false;
            this.activesubtasklink = false;
            this.activegridlink=false;
            this.activelistlink=false;
            this.isListDetailsDataActives=false;
            this.ischartDetailsActives=false;
            this.isboardDetailsActives=false;
            this.iscalendarDetailsActives=true;
            localStorage.setItem('activebtctab', 'chartview');
            this.calendermasterlist();
            this.initializeCalendar();
        },
        openEditModule(currRowData,flag){
            this.attachment = null
            this.attachmentview = null
            this.editRowData = currRowData
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle){
                $('#modulename'+inputEle).val(currRowData.modulename)
            }
            if(btnEle){
                $('#'+btnEle).find('#submitbtn'+inputEle).addClass(btnEle)
                $('#'+btnEle).find('#submitbtn'+inputEle).attr('data-isedit',true)
            }
            $('#glbsearchinpele').val('')
            this.tasktype.name = currRowData.modulename
            this.tasktype.desc = currRowData.moduledesc
            this.tasktype.level = currRowData.modulelevel
            let arrprojidlist=currRowData.projectist.split(",")
            this.tasktype.projectid=arrprojidlist
            this.tasktype.redmineid = currRowData.redmineid
            this.tasktype.type = currRowData.tasktype
            this.tasktype.key = currRowData.taskkey
            this.tasktype.btmpriority = currRowData.priority
            this.tasktype.references = currRowData.reference
            this.tasktype.bsh = currRowData.businessstackholder
            this.tasktype.dept = currRowData.department
            this.tasktype.psh = currRowData.productstackholder
            this.tasktype.startdate = currRowData.startdate
            this.tasktype.enddate = currRowData.estimatedddate
            this.tasktype.tstatus = currRowData.taskstatus
            this.tasktype.projectnamestr = currRowData.projectnameist
            this.tasktype.taskid = currRowData.moduleid
            if(flag==1 || flag ==3){
                this.getDocumetList(currRowData)
            }

            if(this.docs){
                for(const i in this.docs){
                    this.docs[i].comment=null
                    this.docs[i].desc=null
                    this.docs[i].title=null
                    if(i>0){
                        this.docs.splice(i, 1);
                    }
                }
            }
            if(flag==2){
                this.isdocsRequestActive=true
                this.addocument=false
                this.editdocument=true
                this.mydetailsollapse()
            }

            if(flag==3){
                this.isdocsRequestActive=true
                this.editdocument=false
                this.addocument=true
                this.isEditDocsRequestActive = true
                this.mydetailsollapse()
            }
            if(flag==0){
                this.tasktype.isedit=true
                this.isRequestActive=true
                this.addocument = true
                this.mydetailsollapse()
                this.formActionLabel = `Edit Business Task: ${currRowData.modulename}`
            }
            this.isAddState=true
        },
        clearAllPopupFields() {
            this.editCell.errors.editDesc = null;
            this.editCell.errors.btmpriority = null;
            this.editCell.errors.subpriority = null;
            this.editCell.editDesc = null;
            this.editCell.editDueDate = null;
            this.editCell.btmpriority = null;
            this.editCell.subpriority = null;
            this.editCell.edd_reason = null;
        },
        clickCallback: function(pageNum) {
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            this.masterlist(inputEle,'','', true);
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#scrollToListGrid").offset().top -70
                });
            }, 500);
        },
        dateFormatterSales: function(dt) {
            let dateoptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
            };
            return dt.toLocaleDateString("en-GB", dateoptions);
        },
        notBeforeToday: function (date) {
            this.search.startdateto = "";
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        notBeforeTodayDueDate(date) {
          return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        disabledBefore(date) {
            let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; 
        },
        selectedfromdate(){
            if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
                this.isBothDateSelected = false
            }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
                this.isBothDateSelected = true
            }
        },
        selectedBTCDEP(state) {
            if(state.length>0){    
                if(this.search.searchprojectname.length>0){
                    this.getBSHlist(state, this.search.searchprojectname)
                } 
            } else { 
                this.getBSHlist(null, null)
            }
        },
        selectedBTCView(state) {
            if(!state) {
                this.btcviewtypeErr = "Please Select BTC View Type";
                this.btcviewtype = null
                this.isBTCviewtypeSelected = true;
            } else {
                this.isBTCviewtypeSelected = false;
            }
        },
        selectedtodate(){
            if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
                this.isBothDateSelected = false
            }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
                this.isBothDateSelected = true
            }
        },
        selBallparkestimates(state) {
            if(!state) {
                this.editCell.errors.editTaskEstimates = 'Ballpark estimate in days is required'
            } else {
                this.editCell.errors.editTaskEstimates = ''
            }
        },
        selecttaskstatus1(state, value) {
            this.editCell.editTaskStatus = null
            this.editCell.editTaskStatus = state
            if(this.editCell.editTaskStatus != this.editCellData.taskstatus){
                if (this.editCell.editTaskStatus == 'Product WIP') {
                    this.editCell.startdate = this.taskstartdate
                    this.editCell.enddate = ''
                    
                    this.isdisableds= true
                }else{
                    this.isdisableds= false
                    this.editCell.errors.enddate = ''
                }
            }
            if (this.editRowData.taskstatus == 'Product WIP') {
                this.isdisableds= true
            }
            if((this.editCell.editTaskStatus != 'OnHold') && (this.editCell.editTaskStatus != 'Completed') && (this.editCell.editTaskStatus != 'Discarded')){
                this.editCell.errors.editDueDate='Status due date is required';
            }else{
                this.editCell.errors.editDueDate = ''
            }
        },
        validText : function(inputval) {
            let re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        getSearchItems(search) {
            this.isSearchRequestActive = true
            this.businesstaskcodelistopts = JSON.parse(localStorage.getItem('businesstaskcodelistopts'))
            this.search.businesstasknameopts = JSON.parse(localStorage.getItem('businesstasknameopts'))
            this.search.projectnameopts = JSON.parse(localStorage.getItem('projectnameopts'))
            this.search.projectcreatedbyopts = JSON.parse(localStorage.getItem('projectcreatedbyopts'))
            this.search.searchprojectname = search.searchprojectname;
            this.search.searchBusinesstaskcode = search.searchBusinesstaskcode;
            this.search.searchBusinesstaskname = search.searchBusinesstaskname;
            this.search.searchDepartment= search.searchDepartment;
            this.search.searchTaskseverity = search.searchTaskseverity;
            this.search.searchPriority = search.searchPriority;
            this.search.searchProjectstatus = search.searchProjectstatus;
            this.search.bsh = search.bsh;
            this.search.searchTasktype = search.searchTasktype;
            this.search.searchProjectcreatedby = search.searchProjectcreatedby;
            this.search.businessname = search.businessname;
            this.search.redmineid = search.redmineid;
            this.search.searchEstimates = search.searchEstimates;
            this.search.qamanager = search.qamanager;
            this.search.qalead = search.qalead;
            this.search.country = search.country;
            this.search.userdept = search.userdept;
            this.search.proman = search.proman;
            this.search.progman = search.progman;
            this.search.requester = search.requester;
            this.search.requester_hod = search.requester_hod;
            this.search.engman = search.engman;
            this.search.taskownername = search.taskownername;
            this.search.taskmngr = search.taskmngr;
            this.search.startdatefrom = search.startdatefrom;
            this.search.startdateto = search.startdateto;
            this.search.searchBusinesstaskstatus = search.searchBusinesstaskstatus;
            this.search.btcviewtype = search.btcviewtype;
            if(search.ispending=='Pending'){
                this.search.ispending = search.ispending
            }else{
                this.search.ispending = null
            }
            if(!this.search.searchTasktype){
                this.search.searchTasktype = []
            }
            if(!this.search.btcviewtype){
                this.btcviewtype = 'Parent BTC'
                this.search.btcviewtype= 'Parent BTC'
            }
            this.masterlist('t9', this.search);
            this.getAllEmpEmailIds(search.searchProjectcreatedby)
        },
        clearErrorCellForm(){
            this.editCell.errors= {
                editTaskStatus:null,
                editDesc:null,
                btmpriority:null,
                edd_reason:null,
                subpriority:null,
                startdate: null,
                enddate: null,
                editTaskEstimates: null
            };
        },
        validateCellFormData(mdl,objThis){
            this.clearErrorCellForm()
            this.isAddState=false
            let isValid=true;
            if(mdl == 't9'){
                if(!this.editCell.editTaskStatus  && this.editCell.activeColumnFlag == 'status'){
                    this.editCell.errors.editTaskStatus="Task Status is required";
                    isValid = false;
                }
              
                if(!this.editCell.btmpriority && this.editCell.activeColumnFlag == 'priority'){
                    this.editCell.errors.btmpriority="Task Priority is required";
                    isValid= false;
                }

                if(!this.editCell.subpriority && this.editCell.activeColumnFlag == 'subpriority'){
                    this.editCell.errors.subpriority="Task Sub-Priority is required";
                    isValid= false;
                }
                
                if(this.editCell.activeColumnFlag == 'status' && (this.editCell.editTaskStatus != this.editCellData.taskstatus)){
                    if((this.editCell.editTaskStatus != 'OnHold') && (this.editCell.editTaskStatus != 'Completed') && (this.editCell.editTaskStatus != 'Discarded')){
                        if(!this.editCell.editDueDate){
                            this.editCell.errors.editDueDate="Status due date is required";
                            isValid= false;
                        }else{
                            this.editCell.errors.editDueDate="";
                            isValid= true;
                        }
                    }
                }

                if(this.editCell.activeColumnFlag == 'status' && (this.editCell.editTaskStatus != this.editCellData.taskstatus)){
                    if (this.editCell.editTaskStatus =='Tech WIP' || this.editCell.editTaskStatus =='Product WIP') {
                        if(!this.editCell.startdate){
                            this.editCell.errors.startdate="Start date is required";
                            isValid= false;
                        }else{
                            this.editCell.errors.startdate="";
                            isValid= true;
                        }
                        if(!this.editCell.enddate && this.editCell.editTaskStatus == "Tech WIP"){
                            this.editCell.errors.enddate="Estimated date is required";
                            isValid= false;
                        }else{
                            this.editCell.errors.enddate="";
                            isValid= true;
                        }
                    } 
                } 

                if(this.editCell.activeColumnFlag == 'status' && this.editCell.editTaskStatus == 'Tech WIP'){
                    if (this.editCell.startdate && !this.editCell.enddate) {
                        this.editCell.errors.enddate="Estimated date is required";
                            isValid= false;
                    }
                    if(this.editCellData.estimatedddate && this.editCell.enddate && this.editCell.enddate != this.editCellData.estimatedddate){
                        if(!this.editCell.edd_reason){
                            this.editCell.errors.edd_reason="Estimated Update Reason is required";
                            isValid = false;
                        }
                    }
                }

                if(!this.editCell.editDesc){
                    this.editCell.errors.editDesc="Description is required";
                    isValid= false;
                } 
                if(this.editCell.editTaskStatus && (this.editCell.editTaskStatus == "Product WIP") || (this.editCell.editTaskStatus == "Product Pipeline") || (this.editCell.editTaskStatus == "Product Completed") || (this.editCell.editTaskStatus == "OnHold") || (this.editCell.editTaskStatus == "Discarded") || (this.editCell.editTaskStatus == "Tech and Business Review")|| (this.editCell.editTaskStatus == "Product WIP 2")) {
                this.editCell.errors.editTaskEstimates="";
                }else{
                    this.editCell.errors.editTaskEstimates = 'Ballpark Estimate in days is required'
                    if(this.editCell.editTaskEstimates){
                        this.editCell.errors.editTaskEstimates = '';
                    }else{
                        isValid = false
                    }
                }

                if(this.editCell.activeColumnFlag == 'status' && (this.editCell.editTaskStatus == "Tech and Business Review" || this.editCell.editTaskStatus == "UAT") && !this.editCellData.qamanager && this.editCellData.qamanager==null && !this.editCellData.qalead && this.editCellData.qalead==null) {
                    Swal.fire({
                    title: "Failed!",
                    text: 'Please add the QA Manager and QA Lead to this business task.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                    });
                    isValid = false;
                }

                if((this.depOfBAReviewTasks.includes(this.userDepName)) && this.editCell.activeColumnFlag == 'status' && this.editCell.editTaskStatus == "Product Completed" && !this.editCellData.productstackholder && this.editCellData.productstackholder==null) {
                    Swal.fire({
                    title: "Failed!",
                    text: 'Please add the Task Manager to this business task.',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                    });
                    isValid = false;
                }
            }
            return isValid
        },
        validateDate(mdl) {
            let startDates = this.editCell.startdate
            let endDates = this.editCell.enddate
            if(mdl === 't9') {
                if ((Date.parse(endDates) < Date.parse(startDates))) {
                    this.editCell.errors.enddate = "UAT date should be greater than Start date"
                    this.editCell.enddate = null
                } else {
                    this.editCell.errors.enddate = ''
                }
            }
        },
        resetBTPriority(){
            this.islistLoading = true
            let apiUrl = 'api/master/businesstask/getBusinessProjectList'
            this.input = {
                useremail:this.undt.username,
                empid:this.undt.userid,
                bulk_priority: 'true',
                offset: 1,
                limit: null,
                empcode: this.undt.userid
            }
            axios({
                method: "POST",
                url: apiUrl,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.islistLoading = false
                $('#btPriorityPopup').modal('show');    
                if(result.data.errorCode == 0) {
                    let oldPrio = Array.from(result.data.data.rows, function (item, index) {
                         item['old_priority'] = item.priority; 
                         return item;
                    });
                    this.bulkPriorityRecords = oldPrio
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else {
                    this.bulkPriorityRecords = []
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        resetBTPriority(){
            $('#listsettingPopup').modal('show');    
        },
        updateCellData(mdl,objThis){
            var isValid = true;
            isValid = this.validateCellFormData(mdl,objThis);
            if (isValid) {
                this.isLoading = true;
                this.clearErrorCellForm();
                let tblNm='t11'
                if(mdl) tblNm=mdl
                this.input = {
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    projectidd : this.editCellData.projectidlist.split(",")[0],
                    qamanager : this.editCellData.qamanager,
                    qalead : this.editCellData.qalead,
                    issubtask : this.editCellData.parentbtcid == null ? false : true,
                    programmanager : this.editCellData.programmanager,
                    engineeringmaanager : this.editCellData.engineeringmaanager,
                    projectmaanager : this.editCellData.projectmaanager,
                    productstackholder: this.editCellData.productstackholder,
                }
                let apiURL='api/master/businesstask/updateCellBTData'
                if(mdl == 't9'){
                    this.input.id = parseInt(this.editCellData.moduleid)
                    this.input.update_task_status_desc = this.editCell.editDesc
                    this.input.taskestimates = this.editCell.editTaskEstimates
                }

                if (this.editCell.activeColumnFlag == 'priority') {
                    this.input.priority = this.editCell.btmpriority
                }

                if (this.editCell.activeColumnFlag == 'subpriority') {
                    this.input.subpriority = this.editCell.subpriority
                } 

                if (this.editCell.activeColumnFlag == 'status') {
                    this.input.taskstatus = this.editCell.editTaskStatus
                    this.input.duedate = this.editCell.editDueDate
                    this.input.startdate = this.editCell.startdate
                    this.input.enddate = this.editCell.enddate?this.editCell.enddate:null
                    this.input.businesstaskcode = this.editCellData.businesstaskcode

                    if(this.editCell.editTaskStatus == 'Tech WIP'){
                        if(this.editCellData.estimatedddate && this.editCellData.estimatedddate!=null){
                            this.input.prev_edd = this.editCellData.estimatedddate
                        }
                        if(this.editCellData.estimatedddate!=this.editCell.enddate){
                            this.input.edd_reason = this.editCell.edd_reason
                            this.input.edd_counter = this.editCellData.edd_counter + 1
                        }
                    }
                    
                }  
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {  
                    this.isLoading = false;
                    if(result.data.errorCode == 0){
                       Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        $('#statusEditPopup').modal('hide');
                        this.masterlist('t9');
                        let bid = parseInt(this.editCellData.moduleid);
                        this.getSubBTCData(bid);   
                       this.editCell.editDueDate = null;
                       this.editCell.editDesc = null;
                       this.editCell.startdate = null;
                       this.editCell.enddate = null;
                    } else if(result.data.errorCode == 3){
                        Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }else {
                        Swal.fire({
                            title: "",
                            text: result.data.msg,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                        this.editCell.editDueDate = null;
                       this.editCell.editDesc = null;
                       this.editCell.startdate = null;
                       this.editCell.enddate = null;
                        $('#statusEditPopup').modal('hide');
                    }
                }).catch(e => {
                this.displayError(e)
            })
            }
        },
        masterlistnew(mdl, objThis, flag, dashboardflag=null){
            if(objThis) {
                localStorage.setItem('businesstasksearchItems', JSON.stringify(objThis))
            }
            this.islistLoading=true
            this.formActionLabel = 'Create Business Task'
            let setmoduleform='projectmaster'
            let glbMdlLblval='Project'
            if(this.glbst && mdl == ''){
               mdl = this.glbst;
            }
            
            if(mdl == 't9'){
                setmoduleform='taskmaster'
                glbMdlLblval="Business Task"
            }
            if(flag==1){
                this.pageoffset=1
                this.page=1
                if(!this.search.btcviewtype) {
                    this.btcviewtypeErr = "Please Select BTC View Type";
                    this.btcviewtype = null;
                }
                this.searchactiveflag = true;
            }
            let tblNm='t9'
            if(mdl)
            tblNm=mdl
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                this.glbMdlLbl = glbMdlLblval;
            }
            this.input = {
                offset:this.pageoffset,
                limit:100, //apiUrl.LIMIT,
                order:this.order,
                labelname:this.labelname,
                empcode: this.undt.userid,
                useremail: this.undt.username,
                userrole: this.userrole,
                moduletype: 'businesstask',
                loginroleid: parseInt(this.loginroleid),
                btcviewtype: 'Parent BTC',
                submoduletype: (this.btcviewtype == 'Parent BTC') ? 'parentbtc' : 'subbtc'
            }
            this.input.tasktype =  this.search.searchTasktype
            let search = JSON.parse(localStorage.getItem('businesstasksearchItems'))
            if(search) {
                this.btcviewtype = search.btcviewtype
                this.input.btcviewtype = this.btcviewtype
                if(search.searchBusinesstaskcode && search.searchBusinesstaskcode != null && search.searchBusinesstaskcode != '') {
                    if(search.searchBusinesstaskcode.trim().length > 17) {
                        this.input.btcviewtype = 'Sub BTC'
                        this.btcviewtype = 'Sub BTC'
                    } else {
                        this.input.btcviewtype = 'Parent BTC'
                        this.btcviewtype = 'Parent BTC'
                    }
                }
                this.input.submoduletype = (this.input.btcviewtype == 'Parent BTC') ? 'parentbtc' : 'subbtc';
                this.input.businesstaskcode =  search.searchBusinesstaskcode,
                this.input.taskname =  search.searchBusinesstaskname,
                this.input.projectname = search.searchprojectname,
                this.input.department =  search.searchDepartment,
                this.input.taskkey =  search.searchTaskseverity,
                this.input.priority =  search.searchPriority,
                this.input.plannersrch = search.plannersrch,
                this.input.taskstatus =  search.searchProjectstatus,
                this.input.taskestimates = search.searchEstimates,
                this.input.tasktype =  search.searchTasktype,
                this.input.createdby =  search.searchProjectcreatedby
                this.input.securitytag = (this.search.securitytag != null ) ? this.search.securitytag : null
                let assignedate = ''
                if(this.search.startdateto){
                    assignedate = new Date(this.search.startdateto)
                    assignedate.setDate(assignedate.getDate() + 1)
                    assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
                }
                this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
                this.input.dateto = this.search.startdateto?assignedate:null
                this.input.bsh = search.bsh?search.bsh:null
                this.input.proman = search.proman?search.proman: null
                this.input.progman = search.progman?search.progman: null
                this.input.requester = search.requester?search.requester: null
                this.input.requester_hod = search.requester_hod?search.requester_hod: null
                this.input.engman = search.engman?search.engman: null
                this.input.taskownername = search.taskownername?search.taskownername: null
                this.input.taskmngr = search.taskmngr?search.taskmngr: null
                this.input.businessname = search.businessname?search.businessname: null
                this.input.redmineid = search.redmineid?search.redmineid: null
                this.input.projectmodule = search.searchprojectmodule?search.searchprojectmodule:null
                this.input.qamanager = search.qamanager?search.qamanager:null
                this.input.qalead = search.qalead?search.qalead:null
                this.input.country = search.country?search.country:null
                this.input.userdept = search.userdept?search.userdept:null
                this.input.btcstatus = search.searchBusinesstaskstatus?search.searchBusinesstaskstatus:null
                this.input.billingcountry = this.search.billingcountry? this.search.billingcountry:null
                this.input.businesscontext = this.search.businesscontext ? this.search.businesscontext:null

                if(this.search.ispending =='Pending'){
                    this.input.ispending = 'Pending'
                }else{
                    this.input.ispending=null
                }
            }
            let url = 'api/listingconfig/getBTClistingconfigdetails'
            this.getApplicationdata('t24','task');
            this.input.useremail=this.undt.username
            this.input.empid=this.undt.userid
            this.input.redminefilter = this.search.isredmine
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata},
            })
            .then(result => {  
                this.islistLoading=false;    
                if(result.data.errorCode == 0){   
                    this.isresponce=true
                    this.isDetActive=true;
                    this.forceRerender()
                    this.btclistingrows = result.data.data.btcrows;
                    this.btclistingcolumns = result.data.data.columnslist;
                    this.totalcount = result.data.data.count
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit)

                    if(dashboardflag!=true && this.isBTDashboardActive==true){
                        this.getBusinessTaskDetailsVSSeverity(search);
                    }     
                    if (this.ischartDetailsActives==true) {
                        this.getbusinesstaskstatuscount();
                    }
                    if (this.isboardDetailsActives==true) {
                        this.getTaskMasterBroadlist(this.input);
                    }
                    if(this.iscalendarDetailsActives == true) {
                        this.calendermasterlist();
                        this.initializeCalendar();
                    }
                    this.getProjectAllocatedToEmp();

                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else{
                    this.btclistingcolumns = [];
                    this.btclistingrows = [];
                    this.totalcount = 0;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                    this.masterlist('t9');
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        masterlist(mdl, objThis, flag, dashboardflag=null){
            if(objThis) {
                localStorage.setItem('businesstasksearchItems', JSON.stringify(objThis))
            }
            this.islistLoading=true
            this.formActionLabel = 'Create Business Task'
            let setmoduleform='projectmaster'
            let glbMdlLblval='Project'
            if(this.glbst && mdl == ''){
               mdl = this.glbst;
            }
            
            if(mdl == 't9'){
                setmoduleform='taskmaster'
                glbMdlLblval="Business Task"
            }
            if(flag==1){
                this.pageoffset=1
                this.page=1
                if(!this.search.btcviewtype) {
                    this.btcviewtypeErr = "Please Select BTC View Type";
                    this.btcviewtype = null;
                }
                this.searchactiveflag = true;
            }
            let tblNm='t9'
            if(mdl)
            tblNm=mdl
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                this.glbMdlLbl = glbMdlLblval;
            }
            this.input = {
                offset:this.pageoffset,
                limit:100, //apiUrl.LIMIT,
                order:this.order,
                labelname:this.labelname,
                empcode: this.undt.userid,
                useremail: this.undt.username,
                userrole: this.userrole
            }
            this.input.tasktype =  this.search.searchTasktype
            let search = JSON.parse(localStorage.getItem('businesstasksearchItems'))
            if(search) {
                this.btcviewtype = search.btcviewtype
                this.input.btcviewtype = this.btcviewtype
                if(search.searchBusinesstaskcode && search.searchBusinesstaskcode != null && search.searchBusinesstaskcode != '') {
                    if(search.searchBusinesstaskcode.trim().length > 17) {
                        this.input.btcviewtype = 'Sub BTC'
                        this.btcviewtype = 'Sub BTC'
                    } else {
                        this.input.btcviewtype = 'Parent BTC'
                        this.btcviewtype = 'Parent BTC'
                    }
                }
                this.input.businesstaskcode =  search.searchBusinesstaskcode,
                this.input.taskname =  search.searchBusinesstaskname,
                this.input.projectname = search.searchprojectname,
                this.input.department =  search.searchDepartment,
                this.input.taskkey =  search.searchTaskseverity,
                this.input.priority =  search.searchPriority,
                this.input.plannersrch = search.plannersrch,
                this.input.taskstatus =  search.searchProjectstatus,
                this.input.taskestimates = search.searchEstimates,
                this.input.tasktype =  search.searchTasktype,
                this.input.createdby =  search.searchProjectcreatedby
                this.input.securitytag = (this.search.securitytag != null ) ? this.search.securitytag : null
                let assignedate = ''
                if(this.search.startdateto){
                    assignedate = new Date(this.search.startdateto)
                    assignedate.setDate(assignedate.getDate() + 1)
                    assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
                }
                this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
                this.input.dateto = this.search.startdateto?assignedate:null
                this.input.bsh = search.bsh?search.bsh:null
                this.input.proman = search.proman?search.proman: null
                this.input.progman = search.progman?search.progman: null
                this.input.requester = search.requester?search.requester: null
                this.input.requester_hod = search.requester_hod?search.requester_hod: null
                this.input.engman = search.engman?search.engman: null
                this.input.taskownername = search.taskownername?search.taskownername: null
                this.input.taskmngr = search.taskmngr?search.taskmngr: null
                this.input.businessname = search.businessname?search.businessname: null
                this.input.redmineid = search.redmineid?search.redmineid: null
                this.input.projectmodule = search.searchprojectmodule?search.searchprojectmodule:null
                this.input.qamanager = search.qamanager?search.qamanager:null
                this.input.qalead = search.qalead?search.qalead:null
                this.input.country = search.country?search.country:null
                this.input.userdept = search.userdept?search.userdept:null
                this.input.btcstatus = search.searchBusinesstaskstatus?search.searchBusinesstaskstatus:null
                this.input.billingcountry = this.search.billingcountry? this.search.billingcountry:null
                this.input.businesscontext = this.search.businesscontext ? this.search.businesscontext:null

                if(this.search.ispending =='Pending'){
                    this.input.ispending = 'Pending'
                }else{
                    this.input.ispending=null
                }
            }
            let url = 'api/master/businesstask/getBusinessProjectList'
            this.getApplicationdata('t24','task');
            this.input.useremail=this.undt.username
            this.input.empid=this.undt.userid
            this.input.redminefilter = this.search.isredmine
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata},
            })
            .then(result => {  
                this.islistLoading=false;    
                if(result.data.errorCode == 0){   
                    if(tblNm == 't9'){
                        this.isresponce=true
                        this.isDetActive=true;
                        this.forceRerender()
                        this.companymasterlist=result.data.data.rows;
                        this.totalcount = result.data.data.count
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }else{
                        this.isDetActive=true;
                        this.totalcount = result.data.data.count
                        this.companymasterlist=result.data.data.rows;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                    
                    if(dashboardflag!=true && this.isBTDashboardActive==true){
                        this.getBusinessTaskDetailsVSSeverity(search);
                    }
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else{
                    this.companymasterlist = [];
                    this.totalcount = 0;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                }
            }).catch(e => {
                this.displayError(e)
            })

            if (this.ischartDetailsActives==true) {
                this.getbusinesstaskstatuscount();
            }
            if (this.isboardDetailsActives==true) {
                this.getTaskMasterBroadlist(this.input);
            }
            if(this.iscalendarDetailsActives == true) {
                this.calendermasterlist();
                this.initializeCalendar();
            }
            this.getProjectAllocatedToEmp()
        },
        clearCodeList(state) {
            if(!state) {
                this.businesstaskcodelistopts = null
            }
        },
        getPlannerList(node) {
            if(node && node.length>2){
            axios({
                method: "POST",
                url: "api/businesstask/getPlannerList",
                headers: { authorization : this.tokendata },
                data:{plannertitle: node.toLowerCase(),empcode: this.undt.userid,useremail: this.undt.username}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.plannerlist = result.data.data
                } else {
                    this.plannerlist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
            }
        },
        getsrchPlannerList(node) {
            if(node && node.length>2){
            axios({
                method: "POST",
                url: "api/businesstask/getPlannerList",
                headers: { authorization : this.tokendata },
                data:{plannertitle: node.toLowerCase(),empcode: this.undt.userid,useremail: this.undt.username}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.srchplannerlist = result.data.data
                } else {
                    this.srchplannerlist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
            }
        },
        businessCodeChangeSearch(node) {
            if(node && node.length>1) {
                axios({
                    'method': 'POST',
                    'url': 'api/business/getBusinessCode',
                    'data': {
                        businesscode: node.toUpperCase(),
                        empid: this.undt.userid,
                        useremail: this.undt.username,
                        empcode: this.undt.userid
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        this.businesstaskcodelistopts=result.data.data
                        localStorage.setItem('businesstaskcodelistopts', JSON.stringify(result.data.data))
                    } else {
                        this.businesstaskcodelistopts= null
                        localStorage.removeItem('businesstaskcodelistopts')
                    }
                }).catch(e => {
                this.displayError(e)
            })
            }
        },
        clearBusinessNameList(state) {
            if(!state) {
                this.search.businesstasknameopts = null
            }
        },
        businessNameChangeSearch(node) {
            if(node && node.length>2) {
                axios({
                    'method': 'POST',
                    'url': 'api/searching/getBusinessTaskname',
                    'data': {
                        empid: parseInt(this.undt.userid),
                        taskname: node.toLowerCase(),
                        useremail: this.undt.username,
                        empcode: this.undt.userid,
                        tabflag: (this.isListDetailsDataActives == true) ? 'true' : 'false',
                    },
                    'headers':{'authorization':this.tokendata}
                }).then(result => {
                    this.isLoading = false
                    if(result.data.errorCode == 0) {
                        this.search.businesstasknameopts=result.data.data
                        localStorage.setItem('businesstasknameopts', JSON.stringify(result.data.data));
                    } else {
                        this.search.businesstasknameopts= null
                        localStorage.removeItem('businesstasknameopts');
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            }
        },
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
                empid: parseInt(this.undt.userid),
                useremail: this.undt.username,
                empcode: this.undt.userid,
                isfrompage: 'listingpage'
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.search.projectnameopts = result.data.data;
                localStorage.setItem('projectnameopts', JSON.stringify(result.data.data));
            } else {
                this.search.projectnameopts = [];
                localStorage.removeItem('projectnameopts');
            }
            }).catch(e => {
                this.displayError(e)
            });
        },
        projectChangeSearch: function (node){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectnameopts = result.data.data;
                        localStorage.setItem('projectnameopts', JSON.stringify(result.data.data));
                    } else {
                        this.search.projectnameopts = [];
                        localStorage.removeItem('projectnameopts');
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } 
        },
        createdByChangeSearch: function (node){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getAllEmpEmailIds";
                this.input = {
                    emailid:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.search.projectcreatedbyopts = result.data.data;
                        localStorage.setItem('projectcreatedbyopts', JSON.stringify(result.data.data));
                    } else {
                        this.search.projectcreatedbyopts = [];
                        localStorage.removeItem('projectcreatedbyopts');
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            } 
        },
            getAllEmpEmailIds(createdby){
               if(createdby && createdby.length > 0){
                this.isLoading = true;
                let url = "api/listing/getAllEmpEmailIds";
                this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                created: createdby
                };
                axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
                }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.search.projectcreatedbyopts = result.data.data;
                    localStorage.setItem('projectcreatedbyopts', JSON.stringify(result.data.data))
                } else {
                    this.search.projectcreatedbyopts = [];
                    localStorage.removeItem('projectcreatedbyopts')
                }
                }).catch(e => {
                this.displayError(e)
            });
            }
            },
        getBusinessPriorityList() {
          axios({
              method: "POST",
              url: "api/business/getBusinessPriorityList",
              data: {empcode: this.undt.userid,useremail:this.undt.username},
              headers: { authorization : this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                  this.search.priorityopts = result.data.data
              } else {
                  this.search.priorityopts = [];
              }
          }).catch(e => {
            this.displayError(e)
        });
        },
        resetRecords: function () {
            this.search.searchprojectname = null;
            this.search.searchBusinesstaskcode = null;
            this.search.searchBusinesstaskname = null;
            this.search.searchDepartment= null;
            this.search.searchTaskseverity = null;
            this.search.searchPriority = null;
            this.search.searchProjectstatus = null;
            this.search.searchEstimates = null;
            this.search.searchTasktype = null;
            this.search.searchProjectcreatedby = null;
            this.businesstaskcodelistopts = null
            this.search.businesstasknameopts = null
            this.search.projectnameopts = []
            this.search.proman = null
            this.search.securitytag = null
            this.search.progman = null
            this.search.requester = null
            this.search.requester_hod = null
            this.search.engman = null
            this.search.taskownername = null
            this.search.taskmngr = null
            this.search.searchprojectmodule = null
            this.search.bsh = null
            this.search.projectcreatedbyopts = null
            this.search.startdatefrom = null;
            this.search.startdateto = null;
            this.search.businessname = null
            this.search.redmineid = null;
            this.search.isredmine = null;
            this.search.qalead = null;
            this.search.qamanager = null;
            this.search.ispending = 'all';
            this.search.country = null;
            this.search.requester = null;
            this.search.requester_hod = null;
            this.search.userdept = null;
            this.isBothDateSelected = false;
            this.isBTCviewtypeSelected = false;
            this.search.btcviewtype = 'Parent BTC';
            this.btcviewtype = 'Parent BTC';
            this.search.searchBusinesstaskstatus = null,
            localStorage.removeItem('businesstasksearchItems');
            localStorage.removeItem('businesstaskcodelistopts');
            localStorage.removeItem('businesstasknameopts');
            localStorage.removeItem('projectnameopts');
            localStorage.removeItem('projectcreatedbyopts');
            this.masterlist('t9');
            this.searchactiveflag = false;
        },
        dateFormat2(value) {
            if (!value) return "";
            return moment(value).format(this.TimeFormat);
        },
        getDocumetList(currRowData){
            this.isLoading = true;
            let url = "api/s3upload/getDocumentPresignedUrl";
            this.input = {
                moduleid:currRowData.moduleid,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.tasktype.documentlist = result.data.data;
                    if(result.data.data.length == 0) {
                        this.addocument=false
                        this.editdocument=true
                        this.isEditDocsRequestActive = true
                    } else {
                        this.editdocument=false
                        this.isEditDocsRequestActive = true
                    }
                    this.isdocsRequestActive=true 
                    
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                    window.location.href = "/#/login";
                    })
                } else {
                    this.tasktype.documentlist = [];
                }
            });
        },
        dateFormatnew(value) {
            if (!value) return "";
            return moment(String(value)).format('YYYY-MM-DD');
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        timeToDecimal(t) {
            if(!t){
                return ''
            }
            var arr = t.split(':');
            var dec = parseInt((arr[1]/6)*10, 10);
            return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
        },
        dateMONFormat(value){
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    empid: parseInt(this.undt.userid),
                    projectname:node.toLowerCase(),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.tasktype.projectlist = result.data.data;
                    } else {
                        this.tasktype.projectlist = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getApplicationdata(mdl,curracttab){
            this.isLoading=true
            this.pageoffset=1
            
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:100,//apiUrl.LIMIT,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            if(mdl=="t11"){
                this.inputappl.limit=500
            }
            let apiURL='api/master/commonmaster/list'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.projectType=[];
                    this.projectType=result.data.data.rows
                    let tmpClassNm=''
                        if(mdl == 't25'){
                            if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't17'){
                            if(curracttab == 'taskname-tab'){
                                tmpClassNm='tasknametaskleveldd'
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true                                
                            }
                        }else if(mdl == 't2'){
                            tmpClassNm='devBusinessVerticleData'
                        }else if(mdl == 't23'){
                            if(curracttab == 'tasktype-tab'){
                                this.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't24'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasklevel=result.data.data.rows
                                return true
                            }else if(curracttab == 'task'){
                                this.tasklevellist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't25'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasknamemaster=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true
                            }
                        }
                        else if(mdl == 't11'){
                            if(curracttab == 'task'){
                                this.tasktype.projectlist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.projectlist=result.data.data.rows
                                return true
                            }                                
                        }
                        else if(mdl == 't21'){
                            if(curracttab == 'taskassignment-tab'){
                                this.assignment.employeeoptions=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.employeeoptions=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't18'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskdefineList=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskassignment-tab'){
                                this.assignment.tasklist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't19'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskassignmentList=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't9'){
                            if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.businesstasklist=result.data.data.rows
                                return true
                            }
                        }
                        let payload={
                            'classnm':tmpClassNm
                        }
                        this.fillDropdown(payload);
                }
                else if(result.data.errorCode == 3){
                Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
            })
        },
        getBStatusConfigList(pid, status, moduleid) {
            this.tasktype.taskstatus = [];
            if(this.statusconfig){
                let payload = {
                    empcode: this.undt.userid,
                    useremail: this.undt.username,
                    projectid: pid,
                    trackername: 'Businesstask',
                    currStatus: status,
                };
                axios({
                    method: "POST",
                    url: '/api/testcase/getBStatusConfigList',
                    data: payload,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    if(result.data.errorCode == 0){
                        this.tasktype.taskstatus = result.data.data.rows;
                    } else {
                        this.tasktype.taskstatus = [];
                    }
                });
                let prevStatus = status;
                if (prevStatus == 'OnHold' || prevStatus == 'Discarded') {
                    axios({
                        method: "POST",
                        url: "api/master/businesstask/getPrevStatusFromLogs",
                        data: { status: prevStatus, businesstaskId: moduleid, useremail: this.undt.username, empcode: this.undt.userid},
                        'headers':{'authorization':this.tokendata}
                    }).then((result) => {
                        if (result.data.errorCode == 0) {
                            let pushedVar = {id: result.data.data[0].oldval,label: result.data.data[0].oldval }
                            const found = this.taskstatus.find(obj => obj.id == result.data.data[0].oldval);
                            if(!found){
                                this.tasktype.taskstatus.push(pushedVar);
                            }
                        }
                    }).catch(e => {
                    this.displayError(e)
                });
                }
            }
            // else{
            //     if(this.editCellData.parentbtcid != null){
            //         this.getTaskStatusByCondition(status,moduleid);
            //     }
            // }
        },
        fillDropdown(data){
            return ;
        },
        selprojmdldata(state,projvalue){
            this.taskdefine.module=null
            this.taskdefine.module=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.module='Project Module required'
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.module=null
                this.getfiltereddata('tdappmodule');
            }
        },
        selappldata(state,value){
            this.taskdefine.application=null
            this.taskdefine.application=state
            this.errors.taskdefine.application=null
            if(!state && this.isAddState == false){
                this.taskdefine.module=null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.application=null
                this.getfiltereddata('tdprojmodule');
            }
        },
        selapplmdldata(state,value){
            this.taskdefine.appmodule=null
            this.taskdefine.appmodule=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.appmodule='Application Module required'
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.appmodule=null
                this.getfiltereddata('tdappmdl');
            }
        },
        seltasktypedata(state,value){
            this.task.tasklevel=null
            this.task.tasklevel=state
            if(!state && this.isAddState == false){
                this.errors.task.tasklevel='Task Type level required'
            }else{                
                this.errors.task.tasklevel=null
            }
        },
        seldefinetypedata(state,value){
            this.taskdefine.type=null
            this.taskdefine.type=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.type='Task type required'
            }else{                
                this.errors.taskdefine.type=null
            }
        },
        seltasknmtypedata(state,value){
            this.task.tasknames=null
            this.task.tasknames=state
            if(!state && this.isAddState == false){
                this.errors.taskName.tasklevel='Task name level required'
            }else{                
                this.errors.taskName.tasklevel=null
            }
        },
        seltaskdftypedata(state,value){
            this.taskdefine.level=null
            this.taskdefine.level=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.level='Level required'
            }else{                
                this.errors.taskdefine.level=null
            }
        },
        getfiltereddata(tblNm){
            this.isLoading=true
            this.pageoffset=1
            var url = 'api/master/commonmaster/customselect'
            if(tblNm == 'tdprojmodule'){
                url='api/listing/getpromodulebyapplicationid'
                this.input = {
                    projecteid:this.taskdefine.projectid,
                    applicationid:this.taskdefine.application
                }
            }else if(tblNm == 'tdappmodule'){
                url='api/listing/getappmodulenamebypromoduleid'
                this.input = {
                    projectmoduleid:this.taskdefine.module
                }
            }else if(tblNm == 'tdapp'){
                url='api/listing/getappnamebyprojecteid'
                this.input = {
                    projecteid:this.taskdefine.projectid
                }
            }else if(tblNm == 'tdappmdl'){
                url='api/listing/getsectionamebyappmoduleid'
                this.input = {
                    applicationmoduleid:this.taskdefine.appmodule
                }
            }else if(tblNm == 'tdsection'){
                url='api/listing/getfunctionalityamebysectionid'
                this.input = {
                    sectionmasterid:this.taskdefine.devsection
                }
            }else if(tblNm == 'taemployees'){
                url='api/listing/getemployeesbytaskid'
                this.input = {
                    taskid:this.assignment.taskid,
                    taskfilter:'developer' 
                }
            }else if(tblNm == 'tasemployees'){
                url='api/listing/getemployeesbytaskid'
                this.input = {
                    taskid:this.statusassignment.taskdefineid,
                    taskfilter:'developer'
                }
            }
            this.input.empcode = this.undt.userid
            this.input.useremail= this.undt.username
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false;          
                if(result.data.errorCode == 0){
                    if(tblNm == 'tdprojmodule'){
                        this.taskdefine.projectmdllist=result.data.data
                    }else if(tblNm == 'tdappmodule'){
                        this.taskdefine.appmodulelist=result.data.data
                    }else if(tblNm == 'tdapp'){
                         this.taskdefine.applicoptions=result.data.data
                    }else if(tblNm == 'tdappmdl'){
                        this.taskdefine.sectionlist=result.data.data
                    }else if(tblNm == 'tdsection'){
                        this.taskdefine.featurelist=result.data.data
                    }else if(tblNm == 'taemployees'){
                        this.assignment.employeeoptions=result.data.data
                    }else if(tblNm == 'tasemployees'){
                        this.statusassignment.employeeoptions=result.data.data
                    }
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }else{
                    if(tblNm == 'taemployees'){
                        this.assignment.employeeoptions=null
                        this.errors.assignment.empid='employees not found'
                    }else if(tblNm == 'tasemployees'){
                        this.statusassignment.employeeoptions=null
                        this.errors.statusassignment.empid='employees not found'
                    }
                }
            })
        },
        selectBtmPriority(state, value) {
            this.tasktype.btmpriority = null
            this.tasktype.btmpriority = state
            if(!state){
                this.tasktype.priorityerr = true
            } else {                
                this.tasktype.priorityerr = false
            }
        },
        selectserPriority(state, value) {
            this.tasktype.key = null
            this.tasktype.key = state

        },
        selecttypePriority(state, value) {
            this.tasktype.type = null
            this.tasktype.type = state

        },
        selectrefPriority(state, value) {
            this.tasktype.references = null
            this.tasktype.references = state
        },
        selecttaskstatus(state, value) {
            this.tasktype.tstatus = null
            this.tasktype.tstatus = state
        },
        selectbtmDept(state, value) {
            this.tasktype.dept = null
            this.tasktype.dept = state
            if(!state){
                this.tasktype.depterr = true
            } else {                
                this.tasktype.depterr = false
            }
        },
        seltaskleveldata(state,value){
            this.tasktype.level=null
            this.tasktype.level=state
            if(!state && this.isAddState == false){
                this.tasktype.levelerror=true
            }else{                
                this.tasktype.levelerror=false
            }
        },
        seltpprojectdata(state,value){
            this.tasktype.projectid=null
            this.tasktype.projectid=state
            if(!state && this.isAddState == false){
                this.tasktype.projectlist = []
                this.errors.tasktype.projectid="Project Required"
            }else{                
                this.errors.tasktype.projectid=null
            }
        },
        showprojectname(prname){
            let responsestr='';
            let prlist=(prname)?prname.split(','):[];
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        onSelect(event){
            this.files= event.target.files;
            this.isdisabled=false             
        },
        getuserdept(){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            axios({
                'method': 'POST',
                'url': 'api/businesstask/getuserdept',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.tasktype.userdeptlist = result.data.data.rows;
                    let decryptdepartmenttext = this.tasktype.userdeptlist.map(items =>{
                        let tempemplabel1 = items.label ? this.decryptText(items.label) : null;
                        items.label = tempemplabel1
                        return items;
                    })
                    this.tasktype.userdeptlist .sort((a, b) => {
                 return a.label.localeCompare(b.label);
                });
                } else {
                    this.tasktype.userdeptlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        ExportXL() {
            this.islistLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input = {
                limit:null,
                offset:null,
                btcviewtype: this.search.btcviewtype,
                businesstaskcode : this.search.searchBusinesstaskcode,
                taskname :  this.search.searchBusinesstaskname,
                department :  this.search.searchDepartment,
                taskkey :  this.search.searchTaskseverity,
                priority :  this.search.searchPriority,
                taskstatus :  this.search.searchProjectstatus,
                tasktype :  this.search.searchTasktype,
                createdby : this.search.searchProjectcreatedby,
                projectname : this.search.searchprojectname,
                useremail:this.undt.username,
                empid:this.undt.userid,
                businessname:this.search.businessname,
                redmineid:this.search.redmineid,
                taskestimates: this.search.searchEstimates,   
                order:this.order,
                labelname:this.labelname,
                redminefilter:this.search.isredmine,
                userrole:this.userrole,
                qamanager:this.search.qamanager,
                qalead:this.search.qalead,
                country:this.search.country,
                userdept:this.search.userdept
            }
            if(this.search.searchBusinesstaskcode && this.search.searchBusinesstaskcode != null && this.search.searchBusinesstaskcode != '') {
                if(this.search.searchBusinesstaskcode.trim().length > 17) {
                    this.input.btcviewtype = 'Sub BTC'
                } else {
                    this.input.btcviewtype = 'Parent BTC'
                }
            }
            let assignedate =''
            if(this.search.startdateto){
                assignedate = new Date(this.search.startdateto)
                assignedate.setDate(assignedate.getDate() + 1)
                moment(String(assignedate)).format('YYYY-MM-DD');
            }
            this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
            this.input.dateto = this.search.startdateto?assignedate:null
            this.input.bsh = this.search.bsh?this.search.bsh:null
            this.input.proman = this.search.proman?this.search.proman: null
            this.input.progman = this.search.progman?this.search.progman: null
            this.input.plannersrch = this.search.plannersrch?this.search.plannersrch: null
            this.input.requester = this.search.requester?this.search.requester: null
            this.input.requester_hod = this.search.requester_hod?this.search.requester_hod: null
            this.input.engman = this.search.engman?this.search.engman: null
            this.input.taskownername = this.search.taskownername?this.search.taskownername: null
            this.input.taskmngr = this.search.taskmngr?this.search.taskmngr: null
            this.input.projectmodule = this.search.searchprojectmodule?this.search.searchprojectmodule: null
            this.input.btcstatus = this.search.searchBusinesstaskstatus?this.search.searchBusinesstaskstatus: null
            this.input.billingcountry = this.search.billingcountry?this.search.billingcountry: null
            this.input.businesscontext = this.search.businesscontext?this.search.businesscontext: null
            this.input.securitytag = (this.search.securitytag != null) ? this.search.securitytag : null
            if(this.search.ispending=='Pending'){
                this.input.ispending = 'Pending'
            }else{
                this.input.ispending=null
            }
            this.input.empcode = this.undt.userid
            this.input.useremail= this.undt.username

            axios({
                method: "POST",
                url: `api/master/businesstask/getBusinessProjectListExport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.islistLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    let filename = "";
                    this.download(result.data.data.rows,filename)
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                let ShowLabel = 'BusinessTaskMasterDetails_'+filename
                let JSONData = readExportdata
                let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                let CSV = ''
                let CSV1 = ''
                let excelagecolumn = ''
               if(arrData[0].csvcolumn && arrData[0].csvcolumn=='delayed'){
                excelagecolumn = ',Delayed By(days)'
               }

               if(arrData[0].csvcolumn && arrData[0].csvcolumn=='deliverable'){
                excelagecolumn = ',Deliverable By(days)'
               }
               if(this.userrole != "product"){
                    CSV1 +=',Revised EDD,Delay Reason,Total Spent Time'; 
                }
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                        CSV = 'Sr#,Business Task Code,HOD Name,Engineering Manager,Project Manager,Program Manager,Task Manager,Task Owner,QA Manager,QA Lead,Business Task Name,Project Name,Department,Reference,Task Severity,Priority,Module Priority,Redmine/Reference ID,Task Status,Task Type,Created by,Created date,Last Modified Date,Last Modified By,Due date,Estimate Start Date,Estimated End Date,Efforts Hours,Ballpark Estimate in days,Product Pipeline Date,Product WIP Date,Product Completed Date,Tech & Business Review Date,Tech Pipeline Date,Tech WIP Date,QA Pipeline Date,QA WIP Date,Tech Issue Resolution Date,UAT_Date,UAT Signoff Date,UAT Feedback Date,Live Date,Completed Date,Product WIP 2 Date,Tech WIP 2 Date,OnHold Date,Discarded Date,Updated QA Pipeline Date,Estimation Review Date,Billing Country,Requester,Requester HOD,Design Review Date,Planner Name,Parent BTC Code,Business Context'+ excelagecolumn + CSV1+ ',Security Sensitive,Security Justification,Tech Issue Resolution Count \r\n';                 
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                    let index = arrData[i] 
                    let srno = i+1
                    let businesstaskcode = index.hasOwnProperty('businesstaskcode') ? index.businesstaskcode : '';
                    let businessstackholder = index.hasOwnProperty('businessstackholder') ? index.businessstackholder : '';
                    let engmanager = index.hasOwnProperty('engineeringmaanager') ? index.engineeringmaanager : '';
                    let projectmanager = index.hasOwnProperty('projectmaanager') ? index.projectmaanager : '';
                    let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager : '';
                    let productstackholder = index.hasOwnProperty('productstackholder') ? index.productstackholder : '';
                    let qamanager = index.hasOwnProperty('qamanager') ? index.qamanager : '';
                    let qalead = index.hasOwnProperty('qalead') ? index.qalead : '';
                    let taskowner = index.hasOwnProperty('taskowner') ? index.taskowner : '';
                    let modulename = index.hasOwnProperty('modulename') ? index.modulename.replace(/[^a-zA-Z ]/g, '') : '';
                    let projectnameist = index.hasOwnProperty('projectnameist') ? index.projectnameist : index.hasOwnProperty('projectname') ? index.projectname : 'NA';
                    let department = index.department ? index.department : 'NA'

                    let taskkey = index.hasOwnProperty('taskkey') ? index.taskkey : 'NA';
                    let priority = index.hasOwnProperty('priority') ? index.priority : 'NA';
                    let subpriority = index.hasOwnProperty('subpriority') ? index.subpriority : 'NA';
                     let redmineid = index.hasOwnProperty('redmineid') ? index.redmineid == null ? 'NA' : index.redmineid : 'NA'; 
                    let taskstatus = index.hasOwnProperty('taskstatus') ? index.taskstatus : '';              
                    let tasktype = index.hasOwnProperty('tasktype') ? index.tasktype : 'NA';
                    let createdby = index.hasOwnProperty('createdby') ? index.createdby.split('@')[0] : '';
                    let createddate = index.hasOwnProperty('createddate') ? index.createddate : '';
                    let reference = index.hasOwnProperty('reference') ? index.reference : '';
                    let startdate = index.hasOwnProperty('startdate') ? index.startdate : '';
                    let due_date = index.hasOwnProperty('due_date') ? index.due_date : '';
                    let estimatedddate = index.hasOwnProperty('estimatedddate') ? index.estimatedddate : '';
                    let estimatedhours = index.hasOwnProperty('estimatedhours') ? index.estimatedhours : '';
                    let taskestimates = index.hasOwnProperty('taskestimates') ? index.taskestimates : '';

                    let techpipelinedate = index.hasOwnProperty('techpipelinedate') ? index.techpipelinedate : '';
                    let productpipelinedt = index.hasOwnProperty('productpipelinedt') ? index.productpipelinedt : '';
                    let productwipdt = index.hasOwnProperty('productwipdt') ? index.productwipdt : '';
                    let productcompleteddt = index.hasOwnProperty('productcompleteddt') ? index.productcompleteddt : '';
                    let techbusinessreviewdt = index.hasOwnProperty('techbusinessreviewdt') ? index.techbusinessreviewdt : '';
                    let uatdate = index.hasOwnProperty('uatdate') ? index.uatdate : '';
                    let livedate = index.hasOwnProperty('livedate') ? index.livedate : '';
                    let completeddate = index.hasOwnProperty('completeddate') ? index.completeddate : '';
                    let productwip2dt = index.hasOwnProperty('productwip2dt') ? index.productwip2dt : '';
                    let techwip2dt = index.hasOwnProperty('techwip2dt') ? index.techwip2dt : '';
                    let qapipelinedt = index.hasOwnProperty('qapipelinedt') ? index.qapipelinedt : '';
                    let estimationreviewdt = index.hasOwnProperty('estimationreviewdt') ? index.estimationreviewdt : '';
                    let update_qapipelinedate = index.hasOwnProperty('update_qapipelinedate') ? index.update_qapipelinedate : '';
                    let qawipdt = index.hasOwnProperty('qawipdt') ? index.qawipdt : '';
                    let desingreviewdt = index.hasOwnProperty('desingreviewdt') ? index.desingreviewdt : '';
                    let uatsignoffdt = index.hasOwnProperty('uatsignoffdt') ? index.uatsignoffdt : '';
                    let uatfeedbackdt = index.hasOwnProperty('uatfeedbackdt') ? index.uatfeedbackdt : '';
                    let onholddt = index.hasOwnProperty('onholddt') ? index.onholddt : '';
                    let discardeddt = index.hasOwnProperty('discardeddt') ? index.discardeddt : '';
                    let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate : '';
                    let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby.split('@')[0] : '';
                    let techwipdate = index.hasOwnProperty('techwipdt') ? index.techwipdt : '';
                    let ageingdays = index.hasOwnProperty('ageingdays') ? index.ageingdays : '';
                    let billingcountry = index.hasOwnProperty('billingcountry') ? index.billingcountry : '';
                    let requester = index.hasOwnProperty('requestername') ? index.requestername : '';
                    let requester_hod = index.hasOwnProperty('requesterhodname') ? index.requesterhodname : '';
                    let plannertitle = index.hasOwnProperty('plannertitle') ? index.plannertitle : '';   
                    let updated_edd = index.hasOwnProperty('updated_edd') ? index.updated_edd : '';
                    let edd_reason =  index.hasOwnProperty('edd_reason') ? index.edd_reason : '';
                    let spenttime = index.hasOwnProperty('spenttime') ? index.spenttime :'';
                    let parentbcode = index.hasOwnProperty('parentbcode') ? index.parentbcode :'';
                    let businesscontext = index.hasOwnProperty('businesscontext') ? index.businesscontext : '';
                    let securitytag = index.hasOwnProperty('securitytag') ? index.securitytag : '';
                    let securitycomment = index.hasOwnProperty('securitycomment') ? index.securitycomment : '';
                    let techresocount = index.hasOwnProperty('techresocount') ? index.techresocount : '';
                    
                    updated_edd = this.dateMONFormat(updated_edd)
                    estimatedhours = this.timeToDecimal(estimatedhours)
                    estimatedhours = (!estimatedhours) ? 'NA' : estimatedhours;
                    createddate = this.dateMONFormat(createddate)
                    estimatedddate = this.dateMONFormat(estimatedddate)
                    startdate = this.dateMONFormat(startdate)
                    due_date = this.dateMONFormat(due_date)

                    techpipelinedate = this.dateMONFormat(techpipelinedate);
                    techwipdate = this.dateMONFormat(techwipdate);
                    productpipelinedt = this.dateMONFormat(productpipelinedt);
                    productwipdt = this.dateMONFormat(productwipdt);
                    productcompleteddt = this.dateMONFormat(productcompleteddt);
                    techbusinessreviewdt = this.dateMONFormat(techbusinessreviewdt);
                    uatdate = this.dateMONFormat(uatdate);
                    livedate = this.dateMONFormat(livedate);
                    completeddate = this.dateMONFormat(completeddate);
                    productwip2dt = this.dateMONFormat(productwip2dt);
                    techwip2dt = this.dateMONFormat(techwip2dt);
                    qapipelinedt = this.dateMONFormat(qapipelinedt);
                    estimationreviewdt = this.dateMONFormat(estimationreviewdt);
                    update_qapipelinedate = this.dateMONFormat(update_qapipelinedate);
                    qawipdt = this.dateMONFormat(qawipdt);
                    desingreviewdt = this.dateMONFormat(desingreviewdt);
                    uatsignoffdt = this.dateMONFormat(uatsignoffdt);
                    uatfeedbackdt = this.dateMONFormat(uatfeedbackdt);
                    onholddt = this.dateMONFormat(onholddt);
                    discardeddt = this.dateMONFormat(discardeddt);
                    lastmodifieddate = this.dateMONFormat(lastmodifieddate);
                    let techissueresolutiondt = index.hasOwnProperty('techissueresolutiondt') ? index.techissueresolutiondt : '';
                    techissueresolutiondt = this.dateMONFormat(techissueresolutiondt);
                    row += '"' + srno + '",',
                    row += '"' + businesstaskcode + '",',
                    row += '"' + businessstackholder + '",'
                    row += (engmanager==null) ? "NA," : '"' + engmanager + '",'
                    row += (projectmanager==null) ? "NA," : '"' + projectmanager + '",'
                    row += (programmanager==null) ? "NA," : '"' + programmanager + '",'
                    row += (productstackholder==null) ? "NA," : '"' + productstackholder + '",'
                    row += (taskowner==null) ? "NA," : '"' + taskowner + '",'
                    row += (qamanager==null) ? "NA," : '"' + qamanager + '",'
                    row += (qalead==null) ? "NA," : '"' + qalead + '",'
                    row += '"' + modulename + '",',
                    row += (projectnameist==null || projectnameist=="") ? "NA," : '"' + projectnameist.replace(/"/g, '""') + '",'
                    row += '"' + department + '",',
                    row += (reference==null || reference=="") ? "NA," : '"' + reference.replace(/[^a-zA-Z ]/g, '') + '",'
                    row += '"' + taskkey + '",',
                    row += '"' + priority + '",', 
                    row += (subpriority==null) ? "NA," : '"' + subpriority + '",'
                    row += '"' + redmineid + '",',         
                    row += '"' + taskstatus + '",',
                    row += '"' + tasktype + '",',
                    row += '"' + createdby + '",',
                    row += '"' + createddate + '",'
                    row += '"' + lastmodifieddate + '",'
                    row += '"' + lastmodifiedby + '",'
                    row += '"' + due_date + '",' 
                    row += '"' + startdate + '",' 
                    row += '"' + estimatedddate + '",'
                    row += '"' + estimatedhours + '",'
                    row += (taskestimates == null) ? "NA," : '"'+ taskestimates + '",'
                    row += '"' + productpipelinedt + '",'
                    row += '"' + productwipdt + '",'
                    row += '"' + productcompleteddt + '",'
                    row += '"' + techbusinessreviewdt + '",'
                    row += '"' + techpipelinedate + '",'
                    row += '"' + techwipdate + '",'
                    row += '"' + qapipelinedt + '",'
                    row += '"' + qawipdt + '",'
                    row += '"' + techissueresolutiondt + '",'
                    row += '"' + uatdate + '",'
                    row += '"' + uatsignoffdt + '",'
                    row += '"' + uatfeedbackdt + '",'
                    row += '"' + livedate + '",'
                    row += '"' + completeddate + '",'
                    row += '"' + productwip2dt + '",'
                    row += '"' + techwip2dt + '",'
                    row += '"' + onholddt + '",'
                    row += '"' + discardeddt + '",'     
                    row += '"' + update_qapipelinedate + '",'  
                    row += '"' + estimationreviewdt + '",'  
                    row += (billingcountry==null) ? "NA," : '"' + billingcountry + '",'
                    row += (requester==null || requester==" ") ? "NA," : '"' + requester + '",'
                    row += (requester_hod==null || requester_hod==" ") ? "NA," : '"' + requester_hod + '",'
                    row += '"' + desingreviewdt + '",'
                    row += (plannertitle==null || plannertitle=="") ? "NA," : '"' + plannertitle.replace(/[^a-zA-Z ]/g, '') + '",'
                    row += '"' + parentbcode + '",'
                    row += (businesscontext==null) ? "NA," : '"' + businesscontext + '",'
                    if(index.ageingdays){
                    row += '"' + ageingdays + '",'
                    }
                    if (this.userrole != "product") {
                        row += (updated_edd==null || updated_edd=="") ? "NA," : '"' + updated_edd + '",'
                        row += (edd_reason==null || edd_reason=="") ? "NA," : '"' + edd_reason.replace(/[^a-zA-Z ]/g, '') + '",'
                        row += (spenttime==null || spenttime=="") ? "NA," : '"' + spenttime + '",'
                    }
                     if(index.securitytag==1){
                        row += '"' + 'Yes' + '",' 
                     }else{
                        row += '"No",'
                     }
                    if(index.securitytag){
                        row += (securitycomment==null || securitycomment=="") ? "NA," : '"' + securitycomment.replace(/[^a-zA-Z ]/g, '') + '",'
                    }else{
                        row += '"NA",'
                    }
                    row += (techresocount==null) ? "NA," : '"' + techresocount + '",'                   
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                    return;
                }
                let fileName = 'BusinessTaskMasterDetails_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                let link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export report successful",
                    icon: "success",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            }
        },
        export (data, filename, mime) {
            let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        },
        getbusinesstaskstatuscount(){
        this.isLoading = true;
        let url = "api/businesstask/getbusinesstaskstatuscount";
        this.dashboardinput = {
            useremail: this.undt.username,
            empcode: this.undt.userid
        }
        this.dashboardinput.btcviewtype =  this.btcviewtype
        if(this.search.searchBusinesstaskcode && this.search.searchBusinesstaskcode != null && this.search.searchBusinesstaskcode != '') {
            if(this.search.searchBusinesstaskcode.trim().length > 17) {
                this.dashboardinput.btcviewtype = 'Sub BTC'
            } else {
                this.dashboardinput.btcviewtype = 'Parent BTC'
            }
        }
        this.dashboardinput.businesstaskcode =  this.search.searchBusinesstaskcode,
        this.dashboardinput.taskname =  this.search.searchBusinesstaskname,
        this.dashboardinput.projectname = this.search.searchprojectname,
        this.dashboardinput.department =  this.search.searchDepartment,
        this.dashboardinput.taskkey =  this.search.searchTaskseverity,
        this.dashboardinput.priority =  this.search.searchPriority,
        this.dashboardinput.taskstatus =  this.search.searchProjectstatus,
        this.dashboardinput.taskestimates = this.search.searchEstimates,
        this.dashboardinput.tasktype =  this.search.searchTasktype,
        this.dashboardinput.createdby =  this.search.searchProjectcreatedby,
        this.dashboardinput.plannersrch = this.search.plannersrch
        let assignedate = ''
        if(this.search.startdateto){
            assignedate = new Date(this.search.startdateto)
            assignedate.setDate(assignedate.getDate() + 1)
            moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.dashboardinput.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
        this.dashboardinput.dateto = this.search.startdateto?assignedate:null
        this.dashboardinput.bsh = this.search.bsh?this.search.bsh:null
        this.dashboardinput.proman = this.search.proman?this.search.proman: null
        this.dashboardinput.progman = this.search.progman?this.search.progman: null
        this.dashboardinput.requester = this.search.requester?this.search.requester: null
        this.dashboardinput.requester_hod = this.search.requester_hod?this.search.requester_hod: null
        this.dashboardinput.engman = this.search.engman?this.search.engman: null
        this.dashboardinput.taskownername = this.search.taskownername?this.search.taskownername: null
        this.dashboardinput.taskmngr = this.search.taskmngr?this.search.taskmngr: null
        this.dashboardinput.businessname = this.search.businessname?this.search.businessname: null
        this.dashboardinput.redmineid = this.search.redmineid?this.search.redmineid: null
        this.dashboardinput.projectmodule = this.search.searchprojectmodule?this.search.searchprojectmodule:null
        this.dashboardinput.qamanager = this.search.qamanager?this.search.qamanager:null
        this.dashboardinput.qalead = this.search.qalead?this.search.qalead:null
        this.dashboardinput.country = this.search.country?this.search.country:null
        this.dashboardinput.userdept = this.search.userdept?this.search.userdept:null
        this.dashboardinput.businesscontext = this.search.businesscontext?this.search.businesscontext:null
        this.dashboardinput.securitytag = this.search.securitytag

        if(this.search.ispending =='Pending'){
            this.dashboardinput.ispending = 'Pending'
        }else{
            this.dashboardinput.ispending=null
        }
        if(this.chartdate!=null){
            this.dashboardinput.chartdate = this.chartdate;
        }

        axios({
          method: "POST",
          url: url,
          data: this.dashboardinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.btaskstatus = result.data.data[0];
            const totalsum = [parseInt(this.btaskstatus.StatusProductPipeline),parseInt(this.btaskstatus.StatusProductWIP),parseInt(this.btaskstatus.StatusProductCompleted),parseInt(this.btaskstatus.StatusProductWIP2),parseInt(this.btaskstatus.StatusTechAndBusinessReview),parseInt(this.btaskstatus.StatusEstimationReview),parseInt(this.btaskstatus.StatusTechPipeline),parseInt(this.btaskstatus.StatusTechWIP),parseInt(this.btaskstatus.StatusQAPipeline),parseInt(this.btaskstatus.StatusQAWIP),parseInt(this.btaskstatus.StatusTechIssueResolution),parseInt(this.btaskstatus.StatusUAT),parseInt(this.btaskstatus.StatusUATSignoff),parseInt(this.btaskstatus.StatusUATFeedback),parseInt(this.btaskstatus.StatusLive),parseInt(this.btaskstatus.StatusCompleted),parseInt(this.btaskstatus.StatusOnHold),parseInt(this.btaskstatus.StatusDiscarded)].reduce( (partialSum, a) => partialSum + a, 0);
            this.btaskstatus.totalsum=totalsum;
            this.getshowcharts(this.btaskstatus);
            this.getshowbarcharts(this.btaskstatus);
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            this.btaskstatus=[]
          }
        });
      },
      getshowcharts(datass){
        var newcolor1 = '#698ED0',
        newcolor2 = '#ED7D31',
        newcolor3 = '#A5A5A5',
        newcolor4 = '#FFC000',
        newcolor5 = '#5B9BD5',
        newcolor6 = '#70AD47',
        newcolor7 = '#264478',
        newcolor8 = '#9E480E',
        newcolor9 = '#636363',
        newcolor10 = '#997300',
        newcolor11 = '#255E91',
        newcolor12 = '#43682B',
        newcolor13 = '#698ED0',
        newcolor14 = '#F1975A',
        newcolor15 = '#B7B7B7',
        newcolor16 = '#FFCD33',
        tooltipShadow = 'rgba(0, 0, 0, 0.25)',
        labelColor = '#6e6b7b';
        let doughnutChartEx1=$('.containermaster123')
        if (doughnutChartEx1.length) {
            var doughnutExample = new Chart(doughnutChartEx1, {
                type: 'doughnut',
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    responsiveAnimationDuration: 500,
                    cutoutPercentage: 60,
                    legend: { display: false },
                    tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                        var label = data.datasets[0].labels[tooltipItem.index] || '',
                            value = data.datasets[0].data[tooltipItem.index];
                        var output = ' ' + label + ' : ' + value;
                        return output;
                        }
                    },
                    shadowOffsetX: 1,
                    shadowOffsetY: 1,
                    shadowBlur: 8,
                    shadowColor: tooltipShadow,
                    backgroundColor: window.colors.solid.white,
                    titleFontColor: window.colors.solid.black,
                    bodyFontColor: window.colors.solid.black
                    }
                },
                data: {
                    datasets: [
                        {
                        labels: ['Product Pipeline', 'Product WIP', 'Product Completed','Product WIP 2','Tech and Business Review','Estimation Review','Tech Pipeline','Tech WIP','QA Pipeline','QA WIP','Tech Issue Resolution','UAT','UAT Signoff','UAT Feedback','Live','Completed','OnHold','Discarded'],
                        data:[datass.StatusProductPipeline,datass.StatusProductWIP,datass.StatusProductCompleted,datass.StatusProductWIP2,datass.StatusTechAndBusinessReview,datass.StatusEstimationReview,datass.StatusTechPipeline,datass.StatusTechWIP,datass.StatusQAPipeline,datass.StatusQAWIP,datass.StatusTechIssueResolution,datass.StatusUAT,datass.StatusUATSignoff,datass.StatusUATFeedback,datass.StatusLive,datass.StatusCompleted,datass.StatusOnHold,datass.StatusDiscarded],
                        backgroundColor: [newcolor1, newcolor2, newcolor3,newcolor4,newcolor5,newcolor6,newcolor7,newcolor8,newcolor9,newcolor10,newcolor11,newcolor12,newcolor13,newcolor14,newcolor15,newcolor16],
                        borderWidth: 0,
                        pointStyle: 'rectRounded'
                    }
                    ]
                }
            });
        }
      },
      getshowbarcharts(datass){
        var tooltipShadow = 'rgba(0, 0, 0, 0.25)',
        labelColor = '#6e6b7b',
        grid_line_color = 'rgba(200, 200, 200, 0.2)',
        newcolor1 = '#698ED0',
        newcolor2 = '#ED7D31',
        newcolor3 = '#A5A5A5',
        newcolor4 = '#FFC000',
        newcolor5 = '#5B9BD5',
        newcolor6 = '#70AD47',
        newcolor7 = '#264478',
        newcolor8 = '#9E480E',
        newcolor9 = '#636363',
        newcolor10 = '#997300',
        newcolor11 = '#255E91',
        newcolor12 = '#43682B',
        newcolor13 = '#698ED0',
        newcolor14 = '#F1975A',
        newcolor15 = '#B7B7B7',
        newcolor16 = '#FFCD33',
        tooltipShadow = 'rgba(0, 0, 0, 0.25)',
        labelColor = '#6e6b7b';

        let arraynumber = Object.keys( datass ).map(function ( key ) { return datass[key]; });
        let arraynumber2 =arraynumber.pop()
        let minnumber = Math.min.apply( null, arraynumber );
        let maxnumber = Math.max.apply( null, arraynumber );
        let maxCount = Math.ceil(maxnumber/200) * 200 

        let CountStep = (maxCount/100)*10;
        let barChartEx1=$('.containermasterbarchart');
        if (barChartEx1.length) {
            var barChartExample2 = new Chart(barChartEx1, {
            type: 'bar',
            options: {
                elements: {
                rectangle: {
                    borderWidth: 2,
                    borderSkipped: 'bottom'
                }
                },
                responsive: true,
                maintainAspectRatio: false,
                responsiveAnimationDuration: 500,
                legend: {
                display: false
                },
                tooltips: {
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: tooltipShadow,
                backgroundColor: window.colors.solid.white,
                titleFontColor: window.colors.solid.black,
                bodyFontColor: window.colors.solid.black
                },
                scales: {
                xAxes: [
                    {
                    barThickness: 15,
                    display: true,
                    gridLines: {
                        display: true,
                        color: grid_line_color,
                        zeroLineColor: grid_line_color
                    },
                    scaleLabel: {
                        display: false
                    },
                    ticks: {
                        fontColor: labelColor
                    }
                    }
                ],
                yAxes: [
                    {
                    display: true,
                    gridLines: {
                        color: grid_line_color,
                        zeroLineColor: grid_line_color
                    },
                    ticks: {
                        stepSize: CountStep,
                        min: 0,
                        max: maxCount,
                        fontColor: labelColor
                    }
                    }
                ]
                }
            },
            data: {
                    labels: ['Product Pipeline', 'Product WIP', 'Product Completed','Product WIP 2','Tech and Business Review','Estimation Review','Tech Pipeline','Tech WIP','QA Pipeline','QA WIP','Tech Issue Resolution','UAT','UAT Signoff','UAT Feedback','Live','Completed','OnHold','Discarded'],
                    datasets: [{
                        data:[datass.StatusProductPipeline,datass.StatusProductWIP,datass.StatusProductCompleted,datass.StatusProductWIP2,datass.StatusTechAndBusinessReview,datass.StatusEstimationReview,datass.StatusTechPipeline,datass.StatusTechWIP,datass.StatusQAPipeline,datass.StatusQAWIP,datass.StatusTechIssueResolution,datass.StatusUAT,datass.StatusUATSignoff,datass.StatusUATFeedback,datass.StatusLive,datass.StatusCompleted,datass.StatusOnHold,datass.StatusDiscarded],
                        backgroundColor: [newcolor1, newcolor2, newcolor3,newcolor4,newcolor5,newcolor6,newcolor7,newcolor8,newcolor9,newcolor10,newcolor11,newcolor12,newcolor13,newcolor14,newcolor15,newcolor16],
                        borderColor: 'transparent'
                    }]
                }
            });
        }
      },
      resetcal:function(){
        this.chartdate = null;
        this.getbusinesstaskstatuscount();
      },
      calendermasterlist(startdate,enddate){
        this.input = {
        empcode: this.undt.userid,
        }
    if(startdate != undefined && enddate != undefined){
    this.input.startdate =  startdate
    this.input.enddate =  enddate

    }else{
  
    let gdate = new Date();
    let firstDay = new Date(gdate.getFullYear(), gdate.getMonth(), 1);
    let lastDay = new Date(gdate.getFullYear(), gdate.getMonth() + 1, 0);        
    this.input.startdate =  moment(String(firstDay)).format('YYYY-MM-DD');
    this.input.enddate =  moment(String(lastDay)).format('YYYY-MM-DD');
     }
    let url = 'api/master/taskmastercalendarwise/getTaskMasterCalendarlist'
     this.input.useremail=this.undt.username
    this.input.empid=this.undt.userid
    this.input.btcviewtype = this.btcviewtype
    axios({
    'method': 'POST',
    'url': url,
    'data': this.input,
    'headers':{'authorization':this.tokendata},
    })
    .then(result => {  
        this.iscalendlistLoading=false;    
        if(result.data.errorCode == 0){   
                 this.isresponce=true
                this.calendarEvents=result.data.data.rows;
                this.totalcount = result.data.data.count
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
               // this.calendar.removeAllEvents();
               // this.calendarEvents = [];
                this.calendarEvents.push(result.data.data.rows);
            // this.calendernewevents(this.calendarEvents,cs[0],cs[1])
        } else if(result.data.errorCode == 3){
            Swal.fire({
            title: "Session Expired...!!",
            text: result.data.msg,
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
            }).then(function() {
                window.location.href = "/#/login";
            })
        }else{
            this.calendarEvents = [];
            this.totalcount = 0;
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            // this.calendernewevents(this.calendarEvents,cs[0],cs[1])

        }
    })

}, 
        initializeCalendar() {

        const calendar = new FullCalendar.Calendar(document.getElementById('calendar'), {
        initialView: 'dayGridMonth',
        events: this.calendarEvents,
        headerToolbar: {
        start: 'sidebarToggle, prev,next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        },
        eventClick: function(info) {
        let fd = moment(String(info.event.start)).format('YYYY-MM-DD');
        $('#title').html(info.event.title);
        let customParam = info.event.extendedProps.customParam; 
        let mkurl = '#/businesstask/view?businessid='+customParam;
        $('#btcname').text(info.event.extendedProps.customParam2);
        $('#btctaskstatus').text(info.event.extendedProps.customParam3);
        $('#btcstartdate').text(info.event.extendedProps.customParam4);
        $('#btcestdate').text(info.event.extendedProps.customParam5);
        $('#btcnameurl').attr('href', mkurl);
        $('#btcduedate').text(fd);
        $('#calendarModal').modal();
        // var customParam = info.event.extendedProps.customParam; // Access the custom parameter
        // let mkurl = '#/businesstask/view?businessid='+customParam;
        // window.open(mkurl, '_blank');
        },
        datesSet: this.handleDatesSet,
        // eventMouseEnter: this.eventMouseEnter,
        // eventMouseLeave: this.eventMouseLeave,
        });

        calendar.render();
        return calendar;
        },

        handleDatesSet(info) {
        let calendarApi = info.view.calendar;
        let vstart = calendarApi.view.activeStart;
        let end = calendarApi.view.activeEnd;
        let startdate = moment(String(vstart)).format('YYYY-MM-DD');
        let enddate = moment(String(end)).format('YYYY-MM-DD');
        this.updateCalendar(startdate, enddate);
        },
        updateCalendar(startdate, enddate) {
        try{
        this.calendermasterlist(startdate,enddate);
        }catch(error){
        }
        },
        eventMouseEnter(info) {
        const eventElement = info.el;
         let name = 'Name: '+info.event.extendedProps.customParam2+'\n'
        name +=  info.event.title+'\n'
        name += 'Due Date:' + info.event.start
       eventElement.title = name;
       eventElement.classList.add('fc-tooltip');
      },
      eventMouseLeave(info) {
        const eventElement = info.el;
         eventElement.title = '';
        eventElement.classList.remove('fc-tooltip');
      },

    }
}

</script>
<style scoped>
.ck-content{
min-height: 150px !important;
}
.displayblk{
display:none !important;
}
.createbtn{
border-color: #053e52 !important;
color: #fff !important;
background-color: #008000 !important;
padding: 0.386rem 0.5rem !important;
}
.display{
display: none !important;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
.pop-up {
width: 70% !important;
}
.datePicker .startdate {
    width: 100% !important;
}
.modal-body.sm-overflow_scroll_div{
   max-height: 400px;
   overflow-y: scroll;
}
.d-table_icon_up.bussiness{
    cursor: pointer;
}
.accrdn_class tr td{
    border-top: none;
}
.down_arrow_details_bt{
    position: absolute;
    right: 6px;
    top: 2px;
    background: #607D8B;
    color: #fff;
    width: 22px;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
}
.inner_table_detailsbg{
    /* border: 2px solid #dedede!important; */
    border: 0px solid #dedede!important;
    border-bottom: 1px solid #dedede!important;
    border-left: none!important;
    border-right: none!important;
    border-bottom: 5px solid #fbc9c9!important;
}
.inner_table_detailsbg td{
    /* background: #f1f1f4; */
    background: #ffe5e5;
    border: none;
}
.assign_Planner_padd button{
    padding: 0.66rem 0.75rem;
    opacity: 9;
}
.fc-tooltip::before {
    content: attr(title);
    position: absolute;
    z-index: 9999;
    display: none;
    padding: 8px;
    background-color: rgb(34, 111, 184);
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    pointer-events: none;
  }

  .fc-tooltip {
    position: relative;
    background-color: rgb(34, 111, 184);
    color: #fff;
  }
  
  .fc-tooltip:hover::before {
    display: block;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fafafc;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

</style>