<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
            <div class="row">
                <div class="col-md-12">
                    <div class="card text-center mb-3">
                        <div class="card-header">
                            <ul class="nav nav-tabs card-header-tabs ml-0" id="nav-tabs" role="tablist">
                               <li class="nav-item" v-if="pagefeatures.businesstask">
                                <a class="nav-link active" id="task" data-toggle="tab" href="javascript:void();" @click="masterlist('t9',$event)" role="tab" aria-controls="task" aria-selected="false">Business Task Master</a>
                                </li>
                                <li class="nav-item" v-if="pagefeatures.taskmaster">
                                    <a class="nav-link" id="taskdefine-tab" data-toggle="tab" href="javascript:void();" @click="masterlist('t18',$event)" role="tab" aria-controls="taskdefineform" aria-selected="false">Task Master</a>
                                </li>
                                <li class="nav-item" v-if="pagefeatures.taskassignment">
                                    <a class="nav-link" id="taskassignment-tab" data-toggle="tab" href="javascript:void();" @click="masterlist('t19',$event)" role="tab" aria-controls="taskassignmentform" aria-selected="false">Task Assignment</a>
                                </li>
                                <li class="nav-item" v-if="pagefeatures.taskassignmentstatus">
                                    <a class="nav-link" id="taskfassignmentstatus-tab" data-toggle="tab" href="javascript:void();" @click="masterlist('t20',$event)" role="tab" aria-controls="taskfassignmentstatusform" aria-selected="false">Task Assignment Status</a>
                                </li>
                            </ul>
                        </div>
                            <div class="card-body">
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div class="row" id="table-small">
                                            <div class="col-12">
                                                <div class="card">
                                                    <div class="card-body" style="padding-left:0px !important;padding-top:10px !important;">
                                                        <div class="row">                            
                                                            <div class="col-md-6 col-12 mb-1">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <button class="btn btn-outline-primary" type="button">
                                                                            <search-icon size="1.5x" class="custom-class"></search-icon>
                                                                        </button>
                                                                    </div>
                                                                    <input id="glbsearchinpele" type="text" class="form-control" placeholder="Search" aria-label="Amount" />
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-outline-primary" type="button" data-clkstate="search" @click="masterlist('',$event)">Search !</button>
                                                                    </div>
                                                                </div>
                                                            </div>                         
                                                            <div class="col-md-6 col-12 mb-1">
                                                                <div class="input-group-append displayblk float-right">
                                                                    <button id="commonaddmodule" class="btn createbtn waves-effect waves-float waves-light"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#company" @click="addModule($event)" type="button" data-backdrop="static" data-keyboard="false">+ Add {{glbMdlLbl}}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div class="table-responsive">
                                                    <table class="table table-sm dataTableListing" aria-describedby="Business">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sr#</th>
                                                                <th scope="col" v-if="glbst =='t9'">Business Task Code</th>
                                                                <th scope="col" v-if="glbst =='t18'">Task Name</th>
                                                                <th scope="col" v-if="glbst =='t9'">Business Task Name</th>
                                                                <th scope="col" v-if="glbst =='t18'">Task Type</th>
                                                                <th scope="col" v-if="glbst =='t16' || glbst =='t17'">Type Name</th>
                                                                <th scope="col" v-if="glbst =='t16' || glbst =='t17' || glbst =='t18'">Task Level</th>
                                                                <th scope="col" v-if="glbst =='t18' || glbst =='t9'">Project</th>
                                                                <th scope="col" v-if="glbst =='t19' || glbst =='t20'">Employee</th>
                                                                <th scope="col" v-if="glbst =='t19' || glbst =='t20'">Task Action</th>
                                                                <th scope="col" v-if="glbst =='t19' || glbst =='t20'">Task Status</th>
                                                                <th scope="col" v-if="glbst =='t19' || glbst =='t20'">Task </th>
                                                                <th scope="col" v-if="glbst =='t20'">Task Assignment</th>
                                                                <th scope="col" v-if="glbst =='t9'">Department</th>
                                                                <th scope="col" v-if="glbst =='t9'">Task severity</th>
                                                                <th scope="col" v-if="glbst =='t9'">Priority</th>
                                                                <th scope="col" v-if="glbst =='t9'">Task Status</th>
                                                                <th scope="col" v-if="glbst =='t9'">Task Type</th>
                                                                <th scope="col" v-if="glbst =='t18'">Applliction</th>
                                                                <th scope="col" v-if="glbst =='t18'">Technology</th>
                                                                <th scope="col">Created by</th>
                                                                <th scope="col">Created date</th>
                                                                <th scope="col">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="data,index  in companymasterlist">        
                                                                <tr v-bind:key="data.moduleid">
                                                                    <td class="centeralign">{{ index+1+currPageNum*pagelimit }}</td>  
                                                                    <td v-if="glbst =='t9'" class="centeralign" >{{data.businesstaskcode}}</td> 
                                                                    <td v-if="glbst =='t9'" class="rolelistdd"  data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#Viewtaskmaster" @click="openEditModule(data,1)" style="cursor: pointer;" data-backdrop="static" data-keyboard="false">
                                                                        <span class="font-weight-bold sitebluecolor">{{data.modulename}}</span>
                                                                    </td> 
                                                                    <td v-if="glbst =='t18'">
                                                                        <span class="font-weight-bold">{{data.taskname}}</span>
                                                                    </td> 
                                                                    <td v-if="glbst =='t18'">
                                                                        <span class="font-weight-bold">{{data.tasktype}}</span>
                                                                    </td>   
                                                                    <td v-if="glbst =='t16' || glbst =='t17'">
                                                                        <span class="font-weight-bold">{{data.tasktypename}}</span>
                                                                    </td>   
                                                                       
                                                                    <td class="rolelistdd" v-if="glbst !='t9'">
                                                                        <span class="font-weight-bold">{{data.modulename}}</span>
                                                                    </td> 
                                                                    <td v-if="glbst =='t19' || glbst =='t20'">
                                                                        <span class="font-weight-bold rolelistdd">{{data.taskaction}}</span>
                                                                    </td>  
                                                                    <td v-if="glbst =='t19' || glbst =='t20'">
                                                                        <span class="font-weight-bold rolelistdd">{{data.taskstatus}}</span>
                                                                    </td>   
                                                                    <td v-if="glbst =='t19' || glbst =='t20'">
                                                                        <span class="font-weight-bold rolelistdd">{{data.taskname}}</span>
                                                                    </td>  
                                                                    <td v-if="glbst =='t20'">
                                                                        <span class="font-weight-bold rolelistdd">{{data.taskassignmentaction}}</span>
                                                                    </td>
                                                                    <td v-if="glbst =='t18'">
                                                                        <span class="font-weight-bold">{{data.projectname}}</span>
                                                                    </td>  
                                                                        <td v-if="glbst =='t18'">
                                                                        <span class="font-weight-bold">{{data.applicationname}}</span>
                                                                    </td> 
                                                                    <td v-if="glbst =='t18'">
                                                                        <span class="font-weight-bold">{{data.technology}}</span>
                                                                    </td> 
                                                                    <td v-if="glbst =='t9'">
                                                                        <span class="font-weight-bold" v-html="showprojectname(data.projectnameist)"></span>
                                                                    </td>   
                                                                    <td v-if="glbst =='t9'">{{data.department}}</td>
                                                                    <td v-if="glbst =='t9'">{{data.taskkey}}</td>
                                                                    <td v-if="glbst =='t9'">{{data.priority}}</td>
                                                                    <td v-if="glbst =='t9'">{{data.taskstatus}}</td>
                                                                    <td v-if="glbst =='t9'">{{data.tasktype}}</td>
                                                                    <td>{{data.createdby}}</td>
                                                                    <td>{{dateFormat(data.createddate)}}</td>
                                                                    <td class="centeralign" >
                                                                        <a v-if=" loginusername== data.createdby" class="dropdown-item devEditEle"   data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#updatebusiness" @click="openEditModule(data,0)" data-backdrop="static" data-keyboard="false">
                                                                            <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                            <template v-if="companymasterlist.length==0">
                                                                <tr><td colspan="14" style="text-align:center;">No record found</td></tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>      
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="tasktypemaster">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button id="closebtnt11" type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Task Type Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="modulenamet16">Task Name</label>
                                    <input type="text" ref="tasktypename" id="tasktypename" class="form-control" name="tasktypename" placeholder="Task Type Name" v-model="task.tasknames" v-on:blur="validateForm('t16',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.task.tasknames">{{ this.errors.task.tasknames }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskdescription">Task Description</label>
                                    <div class="input-group input-group-merge">
                                        <textarea class="form-control" id="taskdescription" rows="3" placeholder="Task Description" v-model="task.taskdesc" v-on:blur="validateForm('t16',$event)"></textarea>
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.task.taskdesc">{{ this.errors.task.taskdesc }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="tasknametaskleveldd">Task Level</label>
                                    <treeselect placeholder="Select Task Level" :options="task.tasklevellist" @input="seltasktypedata" v-model="task.tasklevel" v-on:close="validateForm('t16',$event)" />
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.task.tasklevel">{{ this.errors.task.tasklevel }}</div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-primary mr-1"  v-on:click="saveData('t16',$event)" id="submitbtnt16">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary">Reset</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="tasknamemaster">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title">
                            <span class="align-middle">Add/Update Task Name Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="modulenamet16">Task Name</label>
                                    <input type="text" id="taskname" class="form-control" name="taskname" placeholder="Task Name" v-model="taskName.tasknames" v-on:blur="validateForm('t17',$event)"/>
                                </div> 
                                <div class="errorinputmsg" v-if="this.errors.taskName.tasknames">{{ this.errors.taskName.tasknames }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskdescription">Task Description</label>
                                    <div class="input-group input-group-merge">
                                        <textarea class="form-control" id="taskdescription" rows="3" placeholder="Task Description" v-model="taskName.taskdesc" v-on:blur="validateForm('t17',$event)"></textarea>
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskName.taskdesc">{{ this.errors.taskName.taskdesc }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="tasknametaskleveldd">Task Level</label>
                                    <treeselect :options="taskName.tasklevellist" @input="seltasknmtypedata" v-model="taskName.tasklevel" v-on:close="validateForm('t17',$event)" />
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskName.tasklevel">{{ this.errors.taskName.tasklevel }}</div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-primary mr-1"  v-on:click="saveData('t17',$event)" id="submitbtnt17">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary">Reset</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="taskdefinemaster">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:#8c564b !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle">Add/Update Task Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskdefinename">Task Name</label>
                                    <div class="input-group input-group-merge">
                                        <input type="text" id="taskdefinename" class="form-control" name="taskdefinename" placeholder="Task Name" v-model="taskdefine.taskdefinename" v-on:blur="validateForm('t18',$event)"/>
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.taskdefinename">{{ this.errors.taskdefine.taskdefinename }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="tasktype">Task Type</label>
                                    <div class="input-group input-group-merge">
                                    <treeselect placeholder="Select Task Type" :options="tasktypelist" @input="seldefinetypedata" v-model="taskdefine.type" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.type">{{ this.errors.taskdefine.type }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskdefinetaskleveldd">Task Level</label>
                                    <treeselect placeholder="Select Task Level" :options="tasklevel" @input="seltaskdftypedata" v-model="taskdefine.level" v-on:close="validateForm('t18',$event)" />
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.level">{{ this.errors.taskdefine.level }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="businessstaskmaster">Business Task Name</label>
                                    <div class="input-group input-group-merge">
                                    <treeselect placeholder="Select Business Task Name" :options="taskdefine.businesstasklist" @input="seldefinebusinessdata" v-model="taskdefine.businesstype" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.businesstype">{{ this.errors.taskdefine.businesstype }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="">Project</label>
                                    <div class="input-group input-group-merge">
                                    <treeselect  class="projroleassprojectdd" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" :options="taskdefine.projectlist" @input="selprojdata" v-model="taskdefine.projectid" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.projectid">{{ this.errors.taskdefine.projectid }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="applicationtxt">Application</label>
                                    <div class="input-group input-group-merge">
                                        <treeselect placeholder="Select Application Name" class="applicationdd"  v-model="taskdefine.application" :options="taskdefine.applicoptions" @input="selappldata" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.application">{{ this.errors.taskdefine.application }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskdefineprojectmdldd">Project Module</label>
                                    <div class="input-group input-group-merge">
                                        <treeselect placeholder="Select Project Module" class="taskdefineprojectmdldd" :options="taskdefine.projectmdllist" @input="selprojmdldata" v-model="taskdefine.module" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.module">{{ this.errors.taskdefine.module }}</div>
                            </div>
                            
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="applicationmodule">Application Module</label>
                                    <div class="input-group input-group-merge">
                                        <treeselect placeholder="Select Application Module Name" class="applicationdd"  v-model="taskdefine.appmodule" :options="taskdefine.appmodulelist" @input="selapplmdldata" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.application">{{ this.errors.taskdefine.application }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="hostingplaturl">Section Module</label>
                                    <div class="input-group input-group-merge">
                                        <treeselect placeholder="Select Section Module Name" class="applicationdd"  v-model="taskdefine.devsection" :options="taskdefine.sectionlist" @input="tskdfnsectiondata" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.devsection">{{ this.errors.taskdefine.devsection }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="">Functionality </label>
                                    <div class="input-group input-group-merge">
                                        <treeselect placeholder="Select Functionality Name" class="applicationdd"  v-model="taskdefine.feature" :options="taskdefine.featurelist" @input="tskdfnfnlitydata" v-on:close="validateForm('t18',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.feature">{{ this.errors.taskdefine.feature }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="">Task Start Date</label>
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><em data-feather="user"></em></span>
                                        </div>
                                        <date-picker v-model="taskdefine.taskstartdate" valueType="format" class="startdate" v-on:pick="validateForm('t18',$event)"></date-picker>
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.taskstartdate">{{ this.errors.taskdefine.taskstartdate }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="">Task End Date</label>
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><em data-feather="user"></em></span>
                                        </div>
                                        <date-picker v-model="taskdefine.taskenddate" valueType="format" class="startdate" v-on:pick="validateForm('t18',$event)"></date-picker>
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.taskenddate">{{ this.errors.taskdefine.taskenddate }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="">Task Assign Date Time</label>
                                    <div class="input-group input-group-merge">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><em data-feather="user"></em></span>
                                        </div>
                                        <date-picker v-model="taskdefine.taskassigneddate" valueType="format" class="startdate" v-on:pick="validateForm('t18',$event)"></date-picker>
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.taskdefine.taskassigneddate">{{ this.errors.taskdefine.taskassigneddate }}</div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-primary mr-1"  @click="saveData('t18',$event)" id="submitbtnt18">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary">Reset</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div> 
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="taskassignmentform">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:rgb(214, 39, 40)  !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle">Add/Update Task Assignment Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="project-name-icon">Task Name</label>
                                    <div class="input-group input-group-merge">
                                        <treeselect  placeholder="Select Task Name" class="rolelistdd" v-model="assignment.taskid" :options="assignment.tasklist" @input="selastaskldata" v-on:close="validateForm('t19',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.assignment.taskid">{{ this.errors.assignment.taskid }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="project-name-icon">Employee Name</label>
                                    <div class="input-group input-group-merge">
                                        <treeselect placeholder="Select Employee Name" class="rolelistdd" v-model="assignment.empid" :options="assignment.employeeoptions" @input="selasempldata" v-on:close="validateForm('t19',$event)" />
                                    </div>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.assignment.empid">{{ this.errors.assignment.empid }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projecttypeid">Task Action</label>
                                    <select class="form-control" id="taskasction" v-model="assignment.taskaction" v-on:close="validateForm('t19',$event)">
                                        <option value="">Select  Task Action</option>
                                        <option value="Requirement Analysys">Requirement Analysys</option>
                                        <option value="Development">Development</option>
                                        <option value="QA Testing">QA Testing</option>
                                        <option value="UAT">UAT</option>
                                        <option value="Live">Live</option>
                                    </select>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.assignment.taskaction">{{ this.errors.assignment.taskaction }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskstatus">Task Status</label>
                                    <select class="form-control" id="taskstatus" v-model="assignment.taskstatus" v-on:close="validateForm('t19',$event)">
                                        <option value="">Select  Task Status</option>
                                        <option value="open">Open</option>
                                        <option value="resolved">Resolved</option>
                                        <option value="closed">Closed</option>
                                    </select>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.assignment.taskstatus">{{ this.errors.assignment.taskstatus }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskcomments">Task Comments</label>
                                    <textarea class="form-control" id="taskcomments" rows="3" placeholder="Task Comments" v-model="assignment.taskcomment" v-on:blur="validateForm('t19',$event)"></textarea>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.assignment.taskcomment">{{ this.errors.assignment.taskcomment }}</div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-primary mr-1"  @click="saveData('t19',$event)" id="submitbtnt19">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary">Reset</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="taskfassignmentstatusorm">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:rgb(255, 127, 14)  !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle">Add/Update Task Assignement Status Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                    <form class="form form-vertical">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="tasktype">Task Name</label>
                                    <treeselect placeholder="Select Task Name" v-model="statusassignment.taskdefineid" :options="statusassignment.taskdefineList" @input="seltaskdfndata" @close="validateForm('t20',$event)" />
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.statusassignment.taskdefineid">{{ this.errors.statusassignment.taskdefineid }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="tasktype">Employee Name</label>
                                    <treeselect placeholder="Select Employee Name" v-model="statusassignment.empid" :options="statusassignment.employeeoptions" @input="selastempldata" @close="validateForm('t20',$event)" />
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.statusassignment.empid">{{ this.errors.statusassignment.empid }}</div>
                            </div>
                             <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="projecttypeid">Task Action</label>
                                    <select class="form-control" id="staskasction" v-model="statusassignment.taskaction" v-on:blur="validateForm('t20',$event)">
                                        <option value="">Select  Task Action</option>
                                        <option value="Task Action 1">Task Action 1</option>
                                        <option value="Task Action 2">Task Action 2</option>
                                        <option value="Task Action 3">Task Action 3</option>
                                    </select>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.statusassignment.taskaction">{{ this.errors.statusassignment.taskaction }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskstatus">Task Status</label>
                                    <select class="form-control" id="staskstatus" v-model="statusassignment.taskstatus" v-on:select="validateForm('t20',$event)">
                                        <option value="">Select  Task Status</option>
                                        <option value="open">Open</option>
                                        <option value="resolved">Resolved</option>
                                        <option value="closed">Closed</option>
                                    </select>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.statusassignment.taskstatus">{{ this.errors.statusassignment.taskstatus }}</div>
                            </div>
                            <div class="col-12">
                                <div class="form-group mg-bot0">
                                    <label for="taskcomments">Task Comments</label>
                                    <textarea class="form-control" id="staskcomments" rows="3" placeholder="Task Comments" v-model="statusassignment.taskcomment" v-on:blur="validateForm('t20',$event)"></textarea>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.statusassignment.taskcomment">{{ this.errors.statusassignment.taskcomment }}</div>
                            </div>
                            <div class="col-12 mg-top1">
                                <button type="button" class="btn btn-primary mr-1" v-on:click="saveData('t20',$event)" id="submitbtnt20">Submit</button>
                                <button type="reset" class="btn btn-outline-secondary">Reset</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="taskmaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg" style="width:800px!important;">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:rgb(31, 119, 180)  !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle">Add/Update Business Task Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <form>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Business Task Name </label>
                                <input class="form-control" type="text" placeholder="Task Name " v-model="tasktype.name"/>
                                 <div class="errorinputmsg" v-if="this.errors.tasktype.name">{{ this.errors.tasktype.name }}</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="redmineid">Redmine ID </label>
                                <input class="form-control" type="text" placeholder="Redmine ID " v-model="tasktype.redmineid" />
                                
                            </div>
                            <div class="">
                                <div class="form-group mg-bot0">
                                    <label for="projectlistdd">Project</label>
                                    <treeselect placeholder="Select Project Name" class="projectlistdd" :multiple="true"  :clear-on-select="true" v-model="tasktype.projectid" :options="tasktype.projectlist" @input="seltpprojectdata" @close="validateForm('t9',$event)"/>
                                </div>
                                <div class="errorinputmsg" v-if="this.errors.tasktype.projectid">{{ this.errors.tasktype.projectid }}</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Task Description </label>
                                <textarea class="form-control" name=""  rows="5" placeholder="Task Description" v-model="tasktype.desc"></textarea>
                                <div class="errorinputmsg" v-if="this.errors.tasktype.desc">{{ this.errors.tasktype.desc }}</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="project-name">Task Level </label>
                                <treeselect placeholder="Select Task Level" :options="tasklevellist" @input="seltaskleveldata" v-model="tasktype.level" @close="validate('t9')" />
                                <div class="errorinputmsg" v-if="this.errors.tasktype.level">{{ this.errors.tasktype.level }}</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="type">Task Type </label>
                                <treeselect placeholder="Select Type" :options="tasktype.typelist" @input="selecttypePriority" v-model="tasktype.type" />
                                <div class="errorinputmsg" v-if="this.errors.tasktype.type">{{ this.errors.tasktype.type }}</div>
                            </div>
                            <div class="form-group">
                                <label for="Department">Task Status</label>
                                <treeselect placeholder="Select Task Status" :options="taskstatus" @input="selecttaskstatus" v-model="tasktype.tstatus" />
                                <div class="errorinputmsg" v-if="this.errors.tasktype.tstatus">{{ this.errors.tasktype.tstatus }}</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="key">Task severity </label>
                                <treeselect placeholder="Select severity" :options="tasktypekeylist" @input="selectserPriority" v-model="tasktype.key" />
                                <div class="errorinputmsg" v-if="this.errors.tasktype.key">{{ this.errors.tasktype.key }}</div>
                            </div>
                            <div class="form-group">
                                <label for="priority">Priority</label>
                                <input class="form-control" type="text" placeholder="Enter Task Priority" v-model="tasktype.btmpriority"/> 
                                <div class="errorinputmsg" v-if="this.errors.tasktype.btmpriority">{{ this.errors.tasktype.btmpriority }}</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="References">Reference (Please mention the origin from where this task came from) </label>
                                <input class="form-control" type="text" placeholder="Reference (Please mention the origin from where this task came from) " v-model="tasktype.references" v-bind:class="{'form-control':true, 'is-invalid' : !validText(tasktype.references)}" />
                                <div class="errorinputmsg" v-if="this.errors.tasktype.references">{{ this.errors.tasktype.references }}</div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="bsh">Business Stakeholder name </label>
                                <input class="form-control" type="text" placeholder="Business Stakeholder name" v-model="tasktype.bsh" />
                                <div class="errorinputmsg" v-if="this.errors.tasktype.bsh">{{ this.errors.tasktype.bsh }}</div>
                            </div>
                            <div class="form-group">
                                <label for="Department">Department</label>
                                <treeselect placeholder="Select Department" :options="tasktype.deptlist" @input="selectbtmDept" v-model="tasktype.dept" />
                                <div class="errorinputmsg" v-if="this.errors.tasktype.dept">{{ this.errors.tasktype.dept }}</div>
                            </div>                            
                            <div class="form-group">
                                <label class="form-label" for="bsh">Stakeholder 2 name</label>
                                <input class="form-control" type="text" placeholder="Stakeholder 2 name" v-model="tasktype.psh" />
                            </div>
                            <div class="form-group mg-bot0">
                                <label for="">Start Date</label>
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><em data-feather="user"></em></span>
                                    </div>
                                    <date-picker v-model="tasktype.startdate" valueType="format" class="startdate" v-on:pick="validateForm('t9',$event)"></date-picker>
                                </div>
                            </div>
                            <div class="form-group mg-bot0">
                                <label for="">Estimated End Date</label>
                                <div class="input-group input-group-merge">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><em data-feather="user"></em></span>
                                    </div>
                                    <date-picker v-model="tasktype.enddate" valueType="format" class="startdate" v-on:pick="validateForm('t9',$event)"></date-picker>
                                </div>
                            </div>
                            <div class="form-group" style="display:none">
                                <label for="lblattachment">Attachment</label>
                                <div class="form-group">
                                <label for="attachment" class="btn btn-sm btn-primary mb-75 mr-75">Select image/docs/videos</label>
                                <button class="btn btn-sm btn-primary mb-75 mr-75" v-on:click.stop.prevent="onUpload()">
                                    Upload
                                </button>
                                <input
                                    type="file"
                                    id="attachment"
                                    hidden
                                    accept="image/*"
                                    ref="file"
                                    @change="onSelect"
                                    multiple
                                />
                                </div>
                            </div>
                            <div class="form-group d-flex flex-wrap mb-0" >
                                <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" v-on:click.stop.prevent="saveData('t9',$event)">Submit</button>
                                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal modal-slide-in fade" id="Viewtaskmaster" aria-hidden="true">
            <div class="modal-dialog sidebar-lg">
                <div class="modal-content p-0">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                    <div class="modal-header mb-1" style="background-color:rgb(31, 119, 180)  !important;">
                        <h5 class="modal-title" style="color:#ffff !important">
                            <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                            <span class="align-middle">Business Task Master Details</span>
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 devUniqueForm">
                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                             <thead class="d-none;"> <th scope="col"> </th></thead>
                            <tr>
                                <td>Task Name</td>
                                <td><strong>{{this.tasktype.name}}</strong></td>
                            </tr>
                            <tr>
                                <td>Redmine ID</td>
                                <td><strong>{{this.tasktype.redmineid}}</strong></td>
                            </tr>
                             <tr>
                                <td>Project</td>
                                <td><strong>{{this.tasktype.projectnamestr}}</strong></td>
                            </tr>
                             <tr>
                                <td>Task Description</td>
                                <td><strong>{{this.tasktype.desc}}</strong></td>
                            </tr>
                             <tr>
                                <td>Task Level</td>
                                <td><strong>{{this.tasktype.level}}</strong></td>
                            </tr>
                             <tr>
                                <td>Task Type</td>
                                <td><strong>{{this.tasktype.type}}</strong></td>
                            </tr>
                             <tr>
                                <td>Task Status</td>
                                <td><strong>{{this.tasktype.tstatus}}</strong></td>
                            </tr>
                             <tr>
                                <td>Task severity</td>
                                <td><strong>{{this.tasktype.key}}</strong></td>
                            </tr>
                             <tr>
                                <td>Priority</td>
                                <td><strong>{{this.tasktype.btmpriority}}</strong></td>
                            </tr>
                             <tr>
                                <td>References</td>
                                <td><strong>{{this.tasktype.references}}</strong></td>
                            </tr>

                            <tr>
                                <td>Business Stack Holder</td>
                                <td><strong>{{this.tasktype.bsh}}</strong></td>
                            </tr>
                             <tr>
                                <td>Department</td>
                                <td><strong>{{this.tasktype.dept}}</strong></td>
                            </tr>
                             <tr>
                                <td>Stack Holder 2</td>
                                <td><strong>{{this.tasktype.psh}}</strong></td>
                            </tr>
                             <tr>
                                <td>Start Date</td>
                                <td><strong>{{dateFormat(this.tasktype.startdate)}}</strong></td>
                            </tr>
                             <tr>
                                <td>Estimated End Date</td>
                                <td><strong>{{dateFormat(this.tasktype.enddate)}}</strong></td>
                            </tr>
                        </table>
                        <div class="card">
                            <div class="card-body">
                                <h5 class="mb-1">Document List</h5>
                                <div class="row">
                                     <div class="table-responsive">
                                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">SR#</th> 
                                                        <th scope="col">Document Name</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(data,index) in tasktype.documentlist" v-bind:key="index">
                                                        <td>{{index+1}}</td>
                                                        <td>{{data.documentname.split("/")[3]}}</td>
                                                        <td style="text-align: center!important;"><a :href="data.presignedUrl" target="_blank"><download-icon size="1.5x" class="custom-class font-medium-3 sitebluecolor"></download-icon></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { PlusSquareIcon,EyeIcon,DownloadIcon,EditIcon,SearchIcon } from 'vue-feather-icons'
import Pagination from 'vue-pagination-2';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
    name:'Masters',
    components:{
        Loading,PlusSquareIcon,DownloadIcon,
        VueElementLoading,
        vSelect,
        EditIcon,
        Treeselect,
        DatePicker,
        SearchIcon,
        Pagination,
        EyeIcon,
        
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            companymasterlist: [],
            tasktypelist: [],
            typenamelist: [],
            projectlist: [],
            taskassignmentList:[],
            showAddForm:false,
            selected: null,
            countryName: [
                { label: 'select_value', id: 'Select Value' },
                { label: 'Russia', id: 'Russia' },
                { label: 'Canada', id: 'Canada' },
                { label: 'China', id: 'China' },
                { label: 'United States', id: 'United States' },
                { label: 'Brazil', id: 'Brazil' },
                { label: 'Australia', id: 'Australia' },
                { label: 'India', id: 'India' },
            ],
            editRowData:[],
            glbMdl:'',
            glbst:'',
            TimeFormat: apiUrl.TimeFormat,
            glbMdlLbl:'company',
            projectType:[],
            projectdescription:'',
            tasklevel:apiUrl.tasklevel,
            tasktypekeylist:apiUrl.tasktypekeylist,
            //tasktype:apiUrl.tasktype,
            /* tasktype:[ {
                id: '',
                label: ''
            }], */
            tasktype:{
                name:null,
                edit:false,
                projectid:null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                desc:null,
                level:null,
                descerror:null,
                levelerror:null,
                type: null,
                key: null,
                redmineid: null,
                btmpriority: null,
                typelist:apiUrl.typelist,
                keylist:apiUrl.keylist,
                referenceslist:apiUrl.referenceslist,
                references: null,
                bsh: null,
                psh: null,
                priorityerr: null,
                dept: null,
                depterr: null,
                deptlist: apiUrl.deptlist,
                startdate: null,
                enddate: null,
                tstatus:null
            },
            tasknamemaster:apiUrl.tasknamemaster,
            taskstatus: [],
            task:{
                tasknames:null,
                taskdesc:'',
                tasklevel:null,
                tasklevellist:[ {
                    id: '',
                    label: ''
                }],
                tasknamelist:[ {
                    id: '',
                    label: ''
                }],
            },
            taskName:{
                tasknames:null,
                taskdesc:'',
                tasklevel:null,
                tasknamemaster:[ {
                    id: '',
                    label: ''
                }],
                tasklevellist:[ {
                    id: '',
                    label: ''
                }],
            },
            taskdefine:{
                type:null,
                name:null,
                level:null,
                projectid:null,
                projectlist:[{
                    id:'',
                    label:''
                }],
                projectname:null,
                module:null,
                projectmdllist:[{
                    id:'',
                    label:''
                }],
                application:null,
                applicoptions:[{
                    id:'',
                    label:''
                }],
                applicationname:null,
                appmodule:null,
                appmodulelist:[{
                    id:'',
                    label:''
                }],
                applicationmodulename:'',
                devsection:null,
                sectionlist:[{
                    id:'',
                    label:''
                }],
                feature:null,
                featurelist:[{
                    id:'',
                    label:''
                }],
                taskstartdate:null,
                taskenddate:null,
                taskassignby:'',
                taskassigneddate:'',
                taskdefinename:'',
                businesstype:null,
                businesstasklist:[{
                    id:'',
                    label:''
                }],
                projectmodulename:null,
            },
            assignment:{
                empid:null,
                taskid:null,
                taskaction:'',
                taskstatus:'',
                taskcomment:'',
                employeeoptions:[ {
                    id: '',
                    label: ''
                }],
                tasklist:[ {
                    id: '',
                    label: ''
                }],
            },
            statusassignment:{
                taskassignmentid:null,
                taskdefineid:null,
                empid:null,
                taskaction:'',
                taskstatus:'',
                taskcomment:'',
                taskassignmentList:[ {
                    id: '',
                    label: ''
                }],
                taskdefineList:[ {
                    id: '',
                    label: ''
                }],
                employeeoptions:[ {
                    id: '',
                    label: ''
                }]
            },
            taskBlured:false,
            valid : false, 
            projvalue:null,
            applvalue:null,
            applicoptions:[ {
                id: '',
                label: ''
            }],
            startdate:null,
            errors: {
                task:{
                    tasknames:null,
                    taskdesc:'',
                    tasklevel:null,
                },
                taskName:{
                    tasknames:null,
                    taskdesc:'',
                    tasklevel:null,
                },
                taskdefine:{
                    type:null,
                    name:null,
                    level:null,
                    projectid:null,
                    module:'',
                    application:null,
                    appmodule:'',
                    devsection:'',
                    feature:'',
                    taskstartdate:null,
                    taskenddate:null,
                    taskassignby:'',
                    taskassigneddate:'',
                    taskdefinename:'',
                    businesstype:null
                },
                assignment:{
                    empid:null,
                    taskaction:'',
                    taskstatus:'',
                    taskcomment:'',
                    taskid:null,
                },
                statusassignment:{
                    taskassignmentid:null,
                    taskdefineid:null,
                    empid:null,
                    taskaction:'',
                    taskstatus:'',
                    taskcomment:'',
                },
                tasktype:{
                    name:null,
                    projectid:null,
                    desc:null,
                    level:null,
                    descerror:null,
                    levelerror:null,
                    type: null,
                    key: null,
                    redmineid: null,
                    btmpriority: null,
                    references: null,
                    bsh: null,
                    psh: null,
                    priorityerr: null,
                    dept: null,
                    depterr: null,
                    startdate: null,
                    enddate: null,
                    tstatus:null
                },
            },
            tasklevel:null,
            tasklevellist:[ {
                id: '',
                label: ''
            }],
            isAddState:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            pagefeatures:[],
            projectnameist:[],
            loginuscondition:'',
            attachment: [],
            attachmentview: null,
            loginusername:'',
            tokendata:null
        }
    },    
    created() {
        this.pagefeatures = {}
        var pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
        pagefeatures1.forEach(element => {
        if (element.page === '/worklogs') {
            this.pagefeatures[element.featurename] = element.featureaccess
        }
        })
    },
    mounted(){
        //this.masterlist('t9','');
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.tokendata = window.localStorage.getItem('token');
                this.loginusername= userdata.username
                /* if(userdata.designation.includes('developer') || userdata.designation.includes('Developer')){
                    this.loginuscondition = userdata.userid
                } */
                this.loginuscondition = userdata.userid
                this.getBtcStatusList();
            }
            if (this.$route.params.backbtnstate != undefined) {
                if (this.$route.params.backbtnstate == 'edit' || this.$route.params.backbtnstate == 'view') {
                    // this.getworksteplist(this.$route.params.clickeddata)
                    $('#taskdefine-tab').click()
                    $('#commonaddmodule').attr('data-target','#taskdefinemaster')
                    if(this.$route.params.backbtnstate == 'edit'){
                        $('#commonaddmodule').click();
                    }
                    this.masterlist('t18',this.$route.params.clickeddata);
                }
            }else{
                this.masterlist('t9','');
            }
        }
    },
    methods:{
        getBtcStatusList() {
            let url = "api/btc/getBtcStatusList";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.taskstatus = result.data.data.rows;
                } else {
                    this.taskstatus = [];
                }
            });     
        },
        clickCallback: function(pageNum) {
            //console.log(pageNum)
            this.pageoffset=pageNum
            this.currPageNum = pageNum - 1
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            this.masterlist(inputEle,'')
        },
        dateFormatterSales: function(dt) {
            let dateoptions = {
            year: "numeric",
            month: "numeric",
            day: "numeric"
            };
            return dt.toLocaleDateString("en-GB", dateoptions);
        },
        masterlist(mdl,objThis){
            this.isLoading=true
            let setmoduleform='projectmaster'
            let glbMdlLblval='Project'
            if(this.glbst && mdl == ''){
               mdl = this.glbst;
            }
            /* if(mdl == 't16'){
                setmoduleform='tasktypemaster'
                glbMdlLblval='Task Type'
            }else if(mdl == 't17'){
                setmoduleform='tasknamemaster'
                glbMdlLblval='Task Name'
            }else  */
            if(mdl == 't18'){
                setmoduleform='taskdefinemaster'
                glbMdlLblval='Task Master'
            }else if(mdl == 't19'){
                setmoduleform='taskassignmentform'
                glbMdlLblval='Task Assignment'
            }else if(mdl == 't20'){
                setmoduleform='taskfassignmentstatusorm'
                glbMdlLblval='Task Assignment Status'
            }else if(mdl == 't9'){
                setmoduleform='taskmaster'
                glbMdlLblval="Business Task"
            }
            $('#commonaddmodule').attr('data-target','#'+setmoduleform)
            this.companymasterlist = []
            let tblNm='t9'
            if(mdl)
            tblNm=mdl
            this.glbMdl=tblNm+'##'+setmoduleform
            this.glbst=tblNm
            if(setmoduleform){
                // this.glbMdlLbl = setmoduleform.charAt(0).toUpperCase() + setmoduleform.slice(1);
                this.glbMdlLbl = glbMdlLblval;
            }
            this.input = {
                tbl:tblNm,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            this.input.isSearch=false
            var url = 'api/master/commonmaster/list'
            if(mdl == 't16'){
                this.getApplicationdata('t24','tasktype-tab')
                /* this.getApplicationdata('t25','tasktype-tab') */
                this.input.section='worklogs'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
                url = 'api/master/commonmaster/customselect'
            }else if(mdl == 't17'){
                this.getApplicationdata('t24','taskname-tab')
                this.getApplicationdata('t25','taskname-tab')
                this.input.section='worklogs'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
                url = 'api/master/commonmaster/customselect'
            }else if (mdl == 't18'){
                this.getApplicationdata('t9','taskdefine-tab')
                this.getApplicationdata('t11','taskdefine-tab')
                this.getApplicationdata('t4','taskdefine-tab')
                //this.getApplicationdata('t16','taskdefine-tab')
                this.getApplicationdata('t24','taskdefine-tab')
                this.getApplicationdata('t25','taskdefine-tab')
                this.input.section='worklogs'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
                url = 'api/master/commonmaster/customselect'
            }else if (mdl == 't19'){
                this.getApplicationdata('t18','taskassignment-tab')
                // this.getApplicationdata('t21','taskassignment-tab')
                this.input.section='worklogs'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
                url = 'api/master/commonmaster/customselect'
            }else if (mdl == 't20'){
                this.getApplicationdata('t18','taskfassignmentstatus-tab')
                // this.getApplicationdata('t19','taskfassignmentstatus-tab')
                // this.getApplicationdata('t21','taskfassignmentstatus-tab')
                this.input.section='worklogs'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
                if(this.loginuscondition){
                    this.input.isFilterSelect=1
                    this.input.filterwithauthor=this.loginuscondition
                }
                url = 'api/master/commonmaster/customselect'
            }else if(tblNm == 't9'){
                this.getApplicationdata('t24','task');
                this.getApplicationdata('t11','task');
                url = 'api/master/commonmaster/customselect';
                this.input.section='worklogs'
                this.input.isFilterSelect=0
                this.input.isFilterPayload={}
            }
            axios({
            'method': 'POST',
            'url': url,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;    
                if(objThis){
                    this.taskdefine.projectid=objThis.projectid
                    this.taskdefine.module=objThis.projectmoduleid
                    this.taskdefine.application=objThis.applicationid
                    this.taskdefine.appmodule=objThis.applicationmoduleid
                    this.taskdefine.devsection=objThis.sectionmasterid
                    this.taskdefine.feature=objThis.functionlitymasterid
                }          
                if(result.data.errorCode == 0){   
                                 
                    if(mdl == 't16' || mdl == 't17' || mdl == 't18' || mdl == 't19'|| mdl == 't20' || tblNm == 't9'){
                        this.companymasterlist=result.data.data.rows;
                        this.totalcount = result.data.data.count
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }else{
                        this.totalcount = result.data.data.count
                        this.companymasterlist=result.data.data.rows;
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                    //$('.devEditEle').attr('data-target','#'+setmoduleform)
                    setTimeout(function(){ 
                        $('.devEditEle').attr('data-target','#'+setmoduleform)
                    }, 1000);
                    
                }else{
                    this.totalcount = 0
                }
            })
        },
        addModule(objThis){
            this.clearerrorForm();
            this.attachment = null;
            this.attachmentview = null;
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            $('.devUniqueForm').find('input[type=text]').val('')
            $(objThis.currentTarget.dataset.target).find('#submitbtn'+inputEle).attr('data-isedit',false)
            $('#glbsearchinpele').val('')
            if(inputEle == 't16'){
                this.task.taskdesc=''
                this.task.tasklevel=null
                this.task.tasknames=null
            }if(inputEle == 't17'){
                this.taskName.taskdesc=''
                this.taskName.tasklevel=null
                this.taskName.tasknames=null
            }else if(inputEle == 't18'){
                this.taskdefine.taskdefinename=''
                this.taskdefine.type=null
                this.taskdefine.name=null
                this.taskdefine.level=null
                this.taskdefine.projectid=null
                this.taskdefine.module=null
                this.taskdefine.application=null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
                this.taskdefine.taskstartdate=''
                this.taskdefine.taskenddate=''
                this.taskdefine.taskassigneddate=''
            }else if(inputEle=="t19"){
                this.assignment.empid=null
                this.assignment.taskaction=''
                this.assignment.taskstatus=''
                this.assignment.taskcomment=''
            }else if(inputEle=="t20"){
                this.statusassignment.taskdefineid=null
                this.statusassignment.taskassignmentid=null
                this.statusassignment.empid=null
                this.statusassignment.taskaction=''
                this.statusassignment.taskstatus=''
                this.statusassignment.taskcomment=''
            }else if(inputEle=="t9"){
                this.tasktype.name=null
                this.tasktype.desc=null
                this.tasktype.level=null
                this.tasktype.projectid=null
                this.tasktype.type=null
                this.tasktype.key=null
                this.tasktype.redmineid=null
                this.tasktype.btmpriority=null
                this.tasktype.references=null
                this.tasktype.bsh=null
                this.tasktype.psh=null
                this.tasktype.priorityerr=null
                this.tasktype.dept=null
                this.tasktype.depterr=null
                this.tasktype.startdate = null
                this.tasktype.enddate = null
                this.tasktype.edit = false
            }
            this.isAddState=true
            //$('.form-group').find('input:text').removeClass('errorinputele');
        },
        saveData(mdl,objThis){
            var isValid = true;
            /* if(document.getElementById('modulename'+mdl).value == ''){
                isValid = false
            } */
            isValid = this.validateForm(mdl,objThis);
            if (isValid) {
                this.clearerrorForm();
                let nameobj=document.getElementById('modulename'+mdl)
                let tblNm='t11'
                if(mdl) tblNm=mdl
                this.input = {
                    tbl: tblNm,
                    section: 'worklogs',
                    createdby: this.undt.username
                }
                let apiURL='api/master/commonmaster/save'
                if(nameobj != null && nameobj != 'undefined')
                {
                    this.input.name=nameobj.value
                }
                if(tblNm == 't16'){
                    this.input.tasktype=this.task.tasknames
                    this.input.name=this.task.taskdesc
                    this.input.tasklevel=this.task.tasklevel
                }else if(tblNm == 't17'){
                    this.input.tasktype=this.taskName.tasknames
                    this.input.name=this.taskName.taskdesc
                    this.input.tasklevel=this.taskName.tasklevel                    
                }else if(tblNm == 't18'){
                    //this.input.name=this.taskdefine.taskdefinename
                    this.input.businesstype=this.taskdefine.businesstype
                    this.input.taskdefinename=this.taskdefine.taskdefinename
                    this.input.type=this.taskdefine.type
                    this.input.nameid=1
                    this.input.level=this.taskdefine.level
                    this.input.projectid=this.taskdefine.projectid
                    this.input.module=this.taskdefine.module
                    if(this.taskdefine.application){
                        this.input.application=this.taskdefine.application
                    }else{
                        this.input.application=null
                    }
                    this.input.appmodule=this.taskdefine.appmodule
                    this.input.devsection=this.taskdefine.devsection
                    this.input.feature=this.taskdefine.feature
                    this.input.taskstartdate=this.taskdefine.taskstartdate
                    this.input.taskenddate=this.taskdefine.taskenddate
                    this.input.taskassigneddate=this.taskdefine.taskassigneddate
                }else if(tblNm=="t19"){
                    this.input.empid=this.assignment.empid
                    this.input.taskid=this.assignment.taskid
                    if(this.assignment.empid){
                        for(var i=0;i < this.assignment.employeeoptions.length;i++){
                            if(this.assignment.employeeoptions[i].id == this.assignment.empid){
                                this.input.empname=this.assignment.employeeoptions[i].label
                            }
                        }
                    }
                    this.input.taskaction=this.assignment.taskaction
                    this.input.taskstatus=this.assignment.taskstatus
                    this.input.taskcomment=this.assignment.taskcomment
                }else if(tblNm=="t20"){
                    this.input.taskdefineid=this.statusassignment.taskdefineid
                    this.input.taskassignmentid=1
                    this.input.empid=this.statusassignment.empid
                    this.input.taskaction=this.statusassignment.taskaction
                    this.input.taskstatus=this.statusassignment.taskstatus
                    this.input.taskcomment=this.statusassignment.taskcomment
                    if(this.statusassignment.empid){
                        for(var i=0;i < this.statusassignment.employeeoptions.length;i++){
                            if(this.statusassignment.employeeoptions[i].id == this.statusassignment.empid){
                                this.input.empname=this.statusassignment.employeeoptions[i].label
                            }
                        }
                    }
                }else if(mdl == 't9'){
                    this.input.name=this.tasktype.name
                    if(this.tasktype.desc){
                        this.input.desc=this.tasktype.desc
                    }
                    if(this.tasktype.level != null && this.tasktype.level != 'undefined'){
                        this.input.level=this.tasktype.level
                    }
                    if(this.tasktype.projectid != null && this.tasktype.projectid != 'undefined'){
                        this.input.projectid=JSON.stringify(this.tasktype.projectid)
                    }
                    if(this.tasktype.redmineid != null && this.tasktype.redmineid != ""){
                        this.input.redmineid = this.tasktype.redmineid
                    }else{
                        this.input.redmineid = null
                    }
                    if(this.tasktype.psh != null && this.tasktype.psh != ""){
                        this.input.productstackholder = this.tasktype.psh
                    }else{
                        this.input.productstackholder = null
                    }
                    
                    this.input.tasktype = this.tasktype.type
                    this.input.taskkey = this.tasktype.key
                    this.input.priority = this.tasktype.btmpriority
                    this.input.reference = this.tasktype.references
                    this.input.businessstackholder = this.tasktype.bsh
                    this.input.department = this.tasktype.dept
                    
                    this.input.startdate = this.tasktype.startdate
                    this.input.estimatedddate = this.tasktype.enddate
                    this.input.attachment = this.attachment
                    this.input.taskstatus = this.tasktype.tstatus
                    apiURL='api/master/commonmaster/customsave'
                }
                if(objThis.currentTarget.dataset.isedit == "true"){
                    apiURL='api/master/commonmaster/update'
                    if(mdl == 't9'){
                        apiURL='api/master/commonmaster/customupdate'
                    } 
                    this.input.id=this.editRowData.moduleid
                    this.input.lastmodifiedby=this.undt.username
                    delete this.input.createdby;
                    this.tasktype.name=null
                    this.tasktype.desc=null
                    this.tasktype.level=null
                    this.tasktype.projectid=null
                }
                this.isAddState=false
                // $('.form-group').find('input:text').removeClass('errorinputele');
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                       Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.masterlist(tblNm,'');
                        /* setTimeout(function(){
                            // document.getElementById('closebtn'+mdl).click()
                            $('.close').click();
                        }) */
                        $('.close').click();
                    }
                })
            }
        },
        validateForm(mdl,objThis){
            this.clearerrorForm()
            this.isAddState=false
            var isValid=true;
            if(mdl == 't16'){
                    if(!this.task.tasknames){
                        this.errors.task.tasknames="Task Type name required";
                        isValid= false;
                    }
                    if(!this.task.taskdesc){
                        this.errors.task.taskdesc="Task Type description required";
                        isValid= false;
                    }
                    if(!this.task.tasklevel){
                        this.errors.task.tasklevel="Task Type level required";
                        isValid= false;
                    }
            }else if(mdl == 't17'){
                if(!this.taskName.tasknames){
                    this.errors.taskName.tasknames="Task name required";
                    isValid= false;
                }
                if(!this.taskName.taskdesc){
                    this.errors.taskName.taskdesc="Task name description required";
                    isValid= false;
                }
                if(!this.taskName.tasklevel){
                    this.errors.taskName.tasklevel="Task name level required";
                    isValid= false;
                }
            }else if(mdl == 't18'){
                if(!this.taskdefine.businesstype){
                    this.errors.taskdefine.businesstype="Business required";
                    isValid= false;
                }
                if(!this.taskdefine.taskdefinename){
                    this.errors.taskdefine.taskdefinename="Task name required";
                    isValid= false;
                }
                if(!this.taskdefine.type){
                    this.errors.taskdefine.type="Task type required";
                    isValid= false;
                }
                if(!this.taskdefine.level){
                    this.errors.taskdefine.level="Level required";
                    isValid= false;
                }
                if(!this.taskdefine.projectid){
                    this.errors.taskdefine.projectid="Project required";
                    isValid= false;
                }
                if(!this.taskdefine.module){
                    this.errors.taskdefine.module="Module required";
                    isValid= false;
                }
                // if(!this.taskdefine.application){
                //     this.errors.taskdefine.application="Application required";
                //     isValid= false;
                // }
                if(!this.taskdefine.appmodule){
                    this.errors.taskdefine.appmodule="Appliction module required";
                    isValid= false;
                }
                if(!this.taskdefine.devsection){
                    this.errors.taskdefine.devsection="Development Section pr part required";
                    isValid= false;
                }
                if(!this.taskdefine.feature){
                    this.errors.taskdefine.feature="Feature required";
                    isValid= false;
                }
                if(!this.taskdefine.taskstartdate){
                    this.errors.taskdefine.taskstartdate="Start date required";
                    isValid= false;
                }
                if(!this.taskdefine.taskenddate){
                    this.errors.taskdefine.taskenddate="End date required";
                    isValid= false;
                }
                if(!this.taskdefine.taskassigneddate){
                    this.errors.taskdefine.taskassigneddate="Assign date required";
                    isValid= false;
                }
            }else if(mdl=="t19"){
                if(!this.assignment.empid){
                    this.errors.assignment.empid="Employee required";
                    isValid= false;
                }
                if(!this.assignment.taskaction){
                    this.errors.assignment.taskaction="Task action required";
                    isValid= false;
                }
                if(!this.assignment.taskstatus){
                    this.errors.assignment.taskstatus="Task status required";
                    isValid= false;
                }
                if(!this.assignment.taskcomment){
                    this.errors.assignment.taskcomment="Task comment required";
                    isValid= false;
                }
            }else if(mdl=="t20"){
                /* if(!this.statusassignment.taskassignmentid){
                    this.errors.statusassignment.taskassignmentid="Task assignment required";
                    isValid= false;
                } */
                if(!this.statusassignment.taskdefineid){
                    this.errors.statusassignment.taskdefineid="Task required";
                    isValid= false;
                }
                if(!this.statusassignment.empid){
                    this.errors.statusassignment.empid="Employee required";
                    isValid= false;
                }
                if(!this.statusassignment.taskaction){
                    this.errors.statusassignment.taskaction="Task action required";
                    isValid= false;
                }
                if(!this.statusassignment.taskstatus){
                    this.errors.statusassignment.taskstatus="Task status required";
                    isValid= false;
                }
                if(!this.statusassignment.taskcomment){
                    this.errors.statusassignment.taskcomment="Task comment required";
                    isValid= false;
                }
            }else if(mdl == 't9'){
                if(!this.tasktype.name){
                    this.errors.tasktype.name="Task name is  required";
                    isValid= false;
                }
                if(!this.tasktype.desc){
                    this.errors.tasktype.desc="Task Description is required";
                    isValid = false;
                }
                if(!this.tasktype.projectid){
                    this.errors.tasktype.projectid="Project required";
                    isValid= false;
                }
                // if(!this.tasktype.startdate){
                //     this.errors.tasktype.startdate="Start Date is required";
                //     isValid = false;
                // }
                if(!this.tasktype.bsh){
                    this.errors.tasktype.bsh="Business stakeholder name is required";
                    isValid = false;
                }

                if(!this.tasktype.level){
                    this.errors.tasktype.level="Task level is  required";
                    isValid= false;
                }
                if(!this.tasktype.type){
                    this.errors.tasktype.type="Task type is required";
                    isValid = false;
                }
                if(!this.tasktype.key){
                    this.errors.tasktype.key="Task severity is required";
                    isValid = false;
                }

                if(!this.tasktype.btmpriority){
                    this.errors.tasktype.btmpriority="Task priority is  required";
                    isValid= false;
                }
                if(!this.tasktype.references){
                    this.errors.tasktype.references="Task references is required";
                    isValid = false;
                }
                if(!this.tasktype.dept){
                    this.errors.tasktype.dept="Department is required";
                    isValid = false;
                }
                if(!this.tasktype.tstatus){
                    this.errors.tasktype.tstatus="Task Status is required";
                    isValid= false;
                }
                
                let allDataFilled =  this.tasktype.type
                && this.tasktype.key && this.tasktype.btmpriority
                && this.tasktype.references && this.tasktype.bsh
                && this.tasktype.dept                 
                if(!allDataFilled) {
                    isValid = false
                }
            }
            return isValid
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        clearerrorForm(){
            this.errors= {
                task:{
                    tasknames:null,
                    taskdesc:null,
                    tasklevel:null,
                },
                taskName:{
                    tasknames:null,
                    taskdesc:null,
                    tasklevel:null,
                },
                taskdefine:{
                    type:null,
                    name:null,
                    level:null,
                    projectid:null,
                    module:'',
                    application:null,
                    appmodule:'',
                    devsection:'',
                    feature:'',
                    taskstartdate:null,
                    taskenddate:null,
                    taskassignby:'',
                    taskassigneddate:'',
                    taskdefinename:'',
                    businesstype:null
                },
                assignment:{
                    empid:null,
                    taskaction:null,
                    taskstatus:null,
                    taskcomment:null,
                },
                statusassignment:{
                    taskassignmentid:null,
                    taskdefineid:null,
                    empid:null,
                    taskaction:null,
                    taskstatus:null,
                    taskcomment:null,
                },
                tasktype:{
                    name:null,
                    projectid:null,
                    desc:null,
                    level:null,
                    startdate: null,
                    enddate: null,
                }
            };
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{5,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validRedmineID : function(inputval) {
            var re = /[A-Za-z0-9].{2,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validate : function(tblNm){
            if(tblNm=="t9"){
                if(this.tasktype.name){
                    this.inputval = this.tasktype.name
                    this.valid = true;
                }
                this.valid=false;
                if(this.tasktype.desc){
                    this.inputval = this.tasktype.desc
                    this.valid = true
                }else{
                    this.tasktype.descerror = true
                }
                this.valid=false;
                if(this.tasktype.level){
                    this.inputval = this.tasktype.level
                    this.valid = true
                }else{
                    this.tasktype.levelerror = true
                }
                return this.valid;
            }
            if( this.inputval && this.validText(this.inputval)){
                this.valid = true;
            }
            
        },
        dateFormat2(value) {
            if (!value) return "";
            return moment(value).format(this.TimeFormat);
        },
        openEditModule(currRowData,flag){
            this.attachment = null
            this.attachmentview = null
            this.editRowData = currRowData
            let arrGlbMdl=this.glbMdl.split('##')
            let inputEle='';
            let btnEle='';
            if(arrGlbMdl.length > 0){
                if(typeof arrGlbMdl[0] != 'undefined'){
                    inputEle=arrGlbMdl[0];
                }
                if(typeof arrGlbMdl[1] != 'undefined'){
                    btnEle=arrGlbMdl[1];
                }
            }
            if(inputEle){
                $('#modulename'+inputEle).val(currRowData.modulename)
            }
            if(btnEle){
                $('#'+btnEle).find('#submitbtn'+inputEle).addClass(btnEle)
                $('#'+btnEle).find('#submitbtn'+inputEle).attr('data-isedit',true)
            }
            $('#glbsearchinpele').val('')
            if(inputEle == 't16'){
                this.task.tasknames=currRowData.tasktypename
                this.task.taskdesc=currRowData.taskdescription
                this.task.tasklevel=currRowData.tasklevel
            }else if(inputEle == 't17'){
                this.taskName.tasknames=currRowData.taskname
                this.taskName.taskdesc=currRowData.taskdescription
                this.taskName.tasklevel=currRowData.tasklevel
            }else if(inputEle == 't18'){
                this.taskdefine.businesstype=currRowData.businesstaskmasterid
                this.taskdefine.taskdefinename=currRowData.taskname
                this.taskdefine.type=currRowData.tasktypemasterid
                this.taskdefine.name=currRowData.tasknamemasterid
                this.taskdefine.level=currRowData.tasklevel
                this.taskdefine.projectid=currRowData.projectid
                this.taskdefine.module=currRowData.projectmodule
                this.taskdefine.application=currRowData.application
                this.taskdefine.appmodule=currRowData.applicationmodule
                this.taskdefine.devsection=currRowData.developmentsectionorpart
                this.taskdefine.feature=currRowData.functionalityorfeature
                this.taskdefine.taskstartdate=this.dateFormat2(currRowData.taskstartdate)
                this.taskdefine.taskenddate=this.dateFormat2(currRowData.taskenddate)
                this.taskdefine.taskassigneddate=this.dateFormat2(currRowData.taskassigndatetime)
            }else if(inputEle=="t19"){
                this.assignment.taskid=currRowData.taskmasterid
                this.getfiltereddata('taemployees')
                this.assignment.empid=currRowData.empid
                this.assignment.taskaction=currRowData.taskaction
                this.assignment.taskstatus=currRowData.taskstatus
                this.assignment.taskcomment=currRowData.taskcomments
            }else if(inputEle=="t20"){
                this.statusassignment.taskdefineid=currRowData.taskmasterid
                this.statusassignment.taskassignmentid=currRowData.taskassignmentid
                this.statusassignment.empid=currRowData.empid
                this.statusassignment.taskaction=currRowData.taskaction
                this.statusassignment.taskstatus=currRowData.taskstatus
                this.statusassignment.taskcomment=currRowData.taskcomments
            }else if(inputEle=="t9"){
                this.tasktype.name = currRowData.modulename
                this.tasktype.desc = currRowData.moduledesc
                this.tasktype.level = currRowData.modulelevel
                var arrprojidlist=currRowData.projectist.split(",")
                this.tasktype.projectid=arrprojidlist
                this.tasktype.redmineid = currRowData.redmineid
                this.tasktype.type = currRowData.tasktype
                this.tasktype.key = currRowData.taskkey
                this.tasktype.btmpriority = currRowData.priority
                this.tasktype.references = currRowData.reference
                this.tasktype.bsh = currRowData.businessstackholder
                this.tasktype.dept = currRowData.department
                this.tasktype.psh = currRowData.productstackholder
                this.tasktype.startdate = currRowData.startdate
                this.tasktype.enddate = currRowData.estimatedddate
                this.tasktype.tstatus = currRowData.taskstatus
                this.tasktype.projectnamestr = currRowData.projectnameist
                if(flag==0){
                    this.tasktype.edit=true
                }
                if(flag==1){
                    this.isLoading = true;
                    let url = "api/s3upload/getDocumentPresignedUrl";
                    this.input = {
                        moduleid:currRowData.moduleid,
                        useremail: this.undt.username,
                        empcode: this.undt.userid
                    };
                    axios({
                        method: "POST",
                        url: url,
                        data: this.input,
                        headers: { authorization: this.tokendata },
                    }).then((result) => {
                        this.isLoading = false;
                        if (result.data.errorCode == 0) {
                            this.tasktype.documentlist = result.data.data;
                        } else {
                            this.tasktype.documentlist = [];
                        }
                    });
                }
            }
            this.isAddState=true
            // $('.form-group').find('input:text').removeClass('errorinputele');
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        projectChange: function (node, instanceId){
            if(node && node.length>1){
                this.isLoading = true;
                let url = "api/searching/getProjectList";
                this.input = {
                projectname:node.toLowerCase(),
                useremail: this.undt.username,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.taskdefine.projectlist = result.data.data;
                    } else {
                        this.taskdefine.projectlist = [];
                    }
                });
            }
        },
        getApplicationdata(mdl,curracttab){
            this.isLoading=true
            this.pageoffset=1
            
            this.inputappl = {
                tbl:mdl,
                offset:this.pageoffset,
                limit:apiUrl.LIMIT
            }
            if(mdl=="t11"){
                this.inputappl.limit=500
            }
            let apiURL='api/master/commonmaster/list'                
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.inputappl,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false          
                if(result.data.errorCode == 0){
                    this.projectType=[];
                    this.projectType=result.data.data.rows
                    let tmpClassNm=''
                        if(mdl == 't25'){
                            if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't17'){
                            if(curracttab == 'taskname-tab'){
                                tmpClassNm='tasknametaskleveldd'
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true                                
                            }
                        }else if(mdl == 't2'){
                            tmpClassNm='devBusinessVerticleData'
                        }else if(mdl == 't23'){
                            if(curracttab == 'tasktype-tab'){
                                this.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasktypelist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't24'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasklevellist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.tasklevel=result.data.data.rows
                                return true
                            }else if(curracttab == 'task'){
                                this.tasklevellist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't25'){
                            if(curracttab == 'tasktype-tab'){
                                this.task.tasknamelist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskname-tab'){
                                this.taskName.tasknamemaster=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.typenamelist=result.data.data.rows
                                return true
                            }
                        }
                        else if(mdl == 't11'){
                            if(curracttab == 'task'){
                                this.tasktype.projectlist=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.projectlist=result.data.data.rows
                                return true
                            }                                
                        }
                        else if(mdl == 't21'){
                            if(curracttab == 'taskassignment-tab'){
                                this.assignment.employeeoptions=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.employeeoptions=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't18'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskdefineList=result.data.data.rows
                                return true
                            }else if(curracttab == 'taskassignment-tab'){
                                this.assignment.tasklist=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't19'){
                            if(curracttab == 'taskfassignmentstatus-tab'){
                                this.statusassignment.taskassignmentList=result.data.data.rows
                                return true
                            }
                        }else if(mdl == 't9'){
                            if(curracttab == 'taskdefine-tab'){
                                this.taskdefine.businesstasklist=result.data.data.rows
                                return true
                            }
                        }
                        let payload={
                            'classnm':tmpClassNm
                        }
                        this.fillDropdown(payload);
                }
            })
            // return retResponse;
        },
        fillDropdown(data){
            return ;
            /* $('.'+data.classnm).wrap('<div class="position-relative"></div>').select2({
                dropdownAutoWidth: true,
                dropdownParent: $('.'+classnm).parent(),
                width: '100%',
                data: this.projectType
            }); */
        },
        selprojdata(state,projvalue){
            this.taskdefine.projectid=null
            this.taskdefine.projectid=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.projectid='Project Module required'
                this.taskdefine.application=null
                this.taskdefine.module=null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.projectid=null
                this.getfiltereddata('tdapp');
                this.getfiltereddata('tdprojmodule');
            }
        },
        selprojmdldata(state,projvalue){
            this.taskdefine.module=null
            this.taskdefine.module=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.module='Project Module required'
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.module=null
                this.getfiltereddata('tdappmodule');
            }
        },
        selappldata(state,value){
            this.taskdefine.application=null
            this.taskdefine.application=state
            this.errors.taskdefine.application=null
            if(!state && this.isAddState == false){
                // this.errors.taskdefine.application='Application required'
                this.taskdefine.module=null
                this.taskdefine.appmodule=null
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.application=null
                this.getfiltereddata('tdprojmodule');
            }
        },
        selapplmdldata(state,value){
            this.taskdefine.appmodule=null
            this.taskdefine.appmodule=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.appmodule='Application Module required'
                this.taskdefine.devsection=null
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.appmodule=null
                this.getfiltereddata('tdappmdl');
            }
        },
        seltasktypedata(state,value){
            this.task.tasklevel=null
            this.task.tasklevel=state
            if(!state && this.isAddState == false){
                this.errors.task.tasklevel='Task Type level required'
            }else{                
                this.errors.task.tasklevel=null
            }
        },
        seldefinetypedata(state,value){
            this.taskdefine.type=null
            this.taskdefine.type=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.type='Task type required'
            }else{                
                this.errors.taskdefine.type=null
            }
        },
        seltasknmtypedata(state,value){
            this.task.tasknames=null
            this.task.tasknames=state
            if(!state && this.isAddState == false){
                this.errors.taskName.tasklevel='Task name level required'
            }else{                
                this.errors.taskName.tasklevel=null
            }
        },
        seltaskdftypedata(state,value){
            this.taskdefine.level=null
            this.taskdefine.level=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.level='Level required'
            }else{                
                this.errors.taskdefine.level=null
            }
        },
        seltaskdfnamedata(state,value){
            this.taskdefine.name=null
            this.taskdefine.name=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.name='Task name required'
            }else{                
                this.errors.taskdefine.name=null
            }
        },
        selastaskldata(state,value){
            this.assignment.taskid=null
            this.assignment.taskid=state
            if(!state && this.isAddState == false){
                this.errors.assignment.taskid='Employee required'
            }else{                
                this.errors.assignment.taskid=null
                this.getfiltereddata('taemployees')
            }
        },
        selasempldata(state,value){
            this.assignment.empid=null
            this.assignment.empid=state
            this.errors.assignment.empid=null
            if(!state && this.isAddState == false){
                this.errors.assignment.empid='Employee required'
            }else{                
                this.errors.assignment.empid=null
            }
        },
        selastempldata(state,value){
            this.statusassignment.empid=null
            this.statusassignment.empid=state
            this.errors.statusassignment.empid=null
            if(!state && this.isAddState == false){
                this.errors.statusassignment.empid='Employee required'
            }else{                
                this.errors.statusassignment.empid=null
            }
        },
        seltaskasdata(state,value){
            this.statusassignment.taskassignmentid=null
            this.statusassignment.taskassignmentid=state
            if(!state && this.isAddState == false){
                this.errors.statusassignment.taskassignmentid='Task assignment required'
            }else{                
                this.errors.statusassignment.taskassignmentid=null
            }
        },
        seltaskdfndata(state,value){
            this.statusassignment.taskdefineid=null
            this.statusassignment.taskdefineid=state
            if(!state && this.isAddState == false){
                this.errors.statusassignment.taskdefineid='Task required'
            }else{                
                this.errors.statusassignment.taskdefineid=null
                this.getfiltereddata('tasemployees')
            }
        },
        seldefinebusinessdata(state,value){
            this.taskdefine.businesstype=null
            this.taskdefine.businesstype=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.businesstype='Business required'
            }else{                
                this.errors.taskdefine.businesstype=null                
            }
        },
        tskdfnsectiondata(state,value){
            this.taskdefine.devsection=null
            this.taskdefine.devsection=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.devsection='Section required'
                this.taskdefine.feature=null
            }else{                
                this.errors.taskdefine.devsection=null
                this.getfiltereddata('tdsection');
            }
        },
        tskdfnfnlitydata(state,value){
            this.taskdefine.feature=null
            this.taskdefine.feature=state
            if(!state && this.isAddState == false){
                this.errors.taskdefine.feature='Functionality required'
            }else{                
                this.errors.taskdefine.feature=null
                //this.getfiltereddata('tdfunction');
            }
        },
        getfiltereddata(tblNm){
            this.isLoading=true
            this.pageoffset=1
            var url = 'api/master/commonmaster/customselect'
            if(tblNm == 'tdprojmodule'){
                url='api/listing/getpromodulebyapplicationid'
                this.input = {
                    projecteid:this.taskdefine.projectid,
                    applicationid:this.taskdefine.application
                }
            }else if(tblNm == 'tdappmodule'){
                // url='api/listing/getappnamebypromoduleid'
                url='api/listing/getappmodulenamebypromoduleid'
                this.input = {
                    projectmoduleid:this.taskdefine.module
                }
            }else if(tblNm == 'tdapp'){
                url='api/listing/getappnamebyprojecteid'
                this.input = {
                    projecteid:this.taskdefine.projectid
                }
            }else if(tblNm == 'tdappmdl'){
                url='api/listing/getsectionamebyappmoduleid'
                this.input = {
                    applicationmoduleid:this.taskdefine.appmodule
                }
            }else if(tblNm == 'tdsection'){
                url='api/listing/getfunctionalityamebysectionid'
                this.input = {
                    sectionmasterid:this.taskdefine.devsection
                }
            }else if(tblNm == 'taemployees'){
                url='api/listing/getemployeesbytaskid'
                this.input = {
                    taskid:this.assignment.taskid,
                    taskfilter:'developer' 
                }
            }else if(tblNm == 'tasemployees'){
                url='api/listing/getemployeesbytaskid'
                this.input = {
                    taskid:this.statusassignment.taskdefineid,
                    taskfilter:'developer'
                }
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {   
                this.isLoading=false;          
                if(result.data.errorCode == 0){
                    if(tblNm == 'tdprojmodule'){
                        this.taskdefine.projectmdllist=result.data.data
                    }else if(tblNm == 'tdappmodule'){
                        this.taskdefine.appmodulelist=result.data.data
                    }else if(tblNm == 'tdapp'){
                         this.taskdefine.applicoptions=result.data.data
                        // this.taskdefine.appmodulelist=result.data.data
                    }else if(tblNm == 'tdappmdl'){
                        this.taskdefine.sectionlist=result.data.data
                    }else if(tblNm == 'tdsection'){
                        this.taskdefine.featurelist=result.data.data
                    }else if(tblNm == 'taemployees'){
                        this.assignment.employeeoptions=result.data.data
                    }else if(tblNm == 'tasemployees'){
                        this.statusassignment.employeeoptions=result.data.data
                    }
                }else{
                    if(tblNm == 'taemployees'){
                        this.assignment.employeeoptions=null
                        this.errors.assignment.empid='employees not found'
                    }else if(tblNm == 'tasemployees'){
                        this.statusassignment.employeeoptions=null
                        this.errors.statusassignment.empid='employees not found'
                    }
                }
            })
        },
        selectBtmPriority(state, value) {
            this.tasktype.btmpriority = null
            this.tasktype.btmpriority = state
            if(!state){
                this.tasktype.priorityerr = true
            } else {                
                this.tasktype.priorityerr = false
            }
        },
        selectserPriority(state, value) {
            this.tasktype.key = null
            this.tasktype.key = state

        },
        selecttypePriority(state, value) {
            this.tasktype.type = null
            this.tasktype.type = state

        },
        selectrefPriority(state, value) {
            this.tasktype.references = null
            this.tasktype.references = state
        },
        selecttaskstatus(state, value) {
            this.tasktype.tstatus = null
            this.tasktype.tstatus = state
        },
        selectbtmDept(state, value) {
            this.tasktype.dept = null
            this.tasktype.dept = state
            if(!state){
                this.tasktype.depterr = true
            } else {                
                this.tasktype.depterr = false
            }
        },
        seltaskleveldata(state,value){
            this.tasktype.level=null
            this.tasktype.level=state
            if(!state && this.isAddState == false){
                this.tasktype.levelerror=true
            }else{                
                this.tasktype.levelerror=false
            }
        },
        seltpprojectdata(state,value){
            this.tasktype.projectid=null
            this.tasktype.projectid=state
            if(!state && this.isAddState == false){
                this.errors.tasktype.projectid="Project Required"
            }else{                
                this.errors.tasktype.projectid=null
                //this.getfiltereddata('t36');
            }
        },
        showprojectname(prname){
            let responsestr='';
            let prlist=prname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            // return JSON.stringify(prlist)
            return responsestr
        },
        onSelect(){
            this.files = this.$refs.file.files;
        },
        async onUpload() {
            this.isLoading=true
            this.attachment = []
            // this.apiURL = "api/master/fileupload/uploadBusineesTaskMasterFileToS3";
            this.apiURL = "api/s3upload/uploadDocumentToS3"
            if(this.files.length) {
                this.files.forEach(file => {
                    if(file.name) {
                        let formData = new FormData();
                        formData.append("imagefile", file);
                        formData.append("empcode", this.undt.userid);
                        formData.append("moduletype", 'businesstask')
                        formData.imagefile = file;
                        formData.empcode = this.undt.userid;
                        formData.moduletype = 'businesstask';
                        axios({
                            "content-type": "multipart/form-data",
                            method: "POST",
                            url: this.apiURL,
                            data: formData,
                            'headers':{'authorization':this.tokendata}
                        }).then((result) => {
                            this.isLoading=false
                            if (result.data.status) {
                                this.attachment.push(result.data.data);
                                this.attachmentview = result.data.data;
                                Swal.fire({
                                    title: "Success!",
                                    text: result.data.msg,
                                    icon: "success",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                });
                            } else {
                                Swal.fire({
                                    title: "Failed",
                                    text: "Select business task image/docs/videos",
                                    icon: "info",
                                    customClass: {
                                    confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                })
                            }
                        })
                    } 
                })
            }            
        },
    }
}
</script>
<style>
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>