<template>
<div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">

            <div class="card p-1">
                <div class="card-header px-1 pt-0 pb-1">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb px-0">
                            <li class="breadcrumb-item" @click="redirectpage('candidatepage',candidateid)"><a href="javascript:void(0)">{{ candidatedetails.candidatename }}</a></li>                           
                            <li class="breadcrumb-item active" aria-current="page">Schedule interview</li>
                        </ol>
                    </nav>
                </div>
                <div class=" mr-0 mt-0 mb-0 px-1 pl-0">
                    <span class="required-field text-warning"> All fields marked with asterisk are required.</span>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 ">
                            <div class="card-body p-0">
                              <div class="card-header px-0">
                                <h3 class="card-title">Scheduler for interview round  {{ roundno }} </h3>
                            </div>
                            <form class="form candidates_details-hr">
                                <div class="row ">
                                     <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="display-flex" for="first-name-column">Candidate Name <span class="required-field"></span></label>
                                            <input type="text" id="first-name-column" class="form-control" placeholder="Enter Candidate Name" v-model="candidatename" disabled/>
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="isshow">
                                        <div class="form-group">
                                            <label for="basicSelect"> Add Interviewers  <span class="required-field"></span> </label>
                                            <treeselect placeholder="Select Interviewer" class="allotedto capitalisetext" v-model="logtime.interviewer" :options="logtime.allottedtolist" @input="selLogAllotedTo"/>
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-else>
                                        <div class="form-group">
                                            <label for="basicSelect"> Add Interviewers  <span class="required-field"></span> </label>
                                            <treeselect placeholder="Enter Min 3Chars To Search Interviewer" class="allotedto capitalisetext" v-model="logtime.interviewer" :options="logtime.allottedtolist" @input="selLogAllotedTo" v-on:search-change="getEmpallocatedtoProject($event,0)"/>
                                        </div>
                                    </div>
                                   <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="basicSelect"> Add Optional Interviewer </label>
                                            <treeselect placeholder="Enter Min 3Chars To Search Optional Interviewer" class="allotedto capitalisetext" v-model="logtime.optionalinterviewer" :options="logtime.optionalinterviewerlist" @input="selLogAllotedTo" v-on:search-change="getEmpallocatedtoProject($event,1)"/>
                                        </div>
                                    </div>
                                   <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="display-flex" for="first-name-column">CC Email <span class="required-field"></span></label>
                                            <input type="text" id="email" class="form-control" placeholder="CC Email" v-model="logtime.ccEmail"  />
                                        </div>
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="ccEmail" v-model="logtime.scheduleflag" />
                                                <label class="custom-control-label" for="ccEmail"> Scheduler </label>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <div class="form-group mg-bot0 col-md-12 mb-0 px-0 datePicker">
                                            <label class="display-flex" for="first-name-column">Schedule Date <span class="required-field"></span></label>
                                            <date-picker valueType="format" v-model="logtime.scheduleStartDate" placeholder="Select Schedule Date" class="startdate" id="StartDate" :disabled-date="notBeforeTodaydate"></date-picker>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                     <div class="form-group">
                                         <label for="basicSelect"> Select Time<span class="required-field"></span> </label>
                                         <div id="spendtime">
                                            <vue-timepicker type="time"  v-model="logtime.spenttime" format="HH:mm"   ></vue-timepicker>
                                            <div class="errorinputmsg" v-if="this.errors.spenttime">{{ this.errors.spenttime }}</div>
                                        </div>
                                      </div>
                                   </div>
                                   <div class="col-md-12 text-right">
                                     <div class="form-group">
                                     <button type="button" class="btn btn-relief-success mr-1" @click="savewizard('t59',$event)" id="submitbtnt42" >Save</button>
                                     <button type="button" data-dismiss="modal" class="btn btn-relief-secondary" @click="redirectpage('candidatepage',candidateid)">Cancel</button>
                                     </div>
                                   </div>

                                </div>
                            </form>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="card-body p-0">
                              <div class="card-header px-0">
                                <!-- <h3 class="card-title">Consolidated Calendar </h3> -->
                              </div>
                              <div class="authorized_calendar_date text-center">
                                <div class="w-50 m-auto py-2">
                                <img class="round" src="../../../public/images/pages/free_calendar_pic.webp" alt="">
                               </div>

                                <!-- <h3 class="card-title">Would you like authorize Calendar? </h3> -->

                                <!-- <button type="reset" class="btn btn-relief-success">Authorize Calendar</button> -->



                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          </div>
   </div>
</template>
<script>
    import axios from 'axios';
    import VueElementLoading from 'vue-element-loading';
    import "vue-loading-overlay/dist/vue-loading.css";
    import DatePicker from "vue2-datepicker";
    import "vue2-datepicker/index.css";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import moment from 'moment';
    import profilepic from '../profilepic.vue';
    import Treeselect from '@riophae/vue-treeselect';
    import VueTimepicker from 'vue2-timepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import commonMethods from '../../utils/commonMethods';
    import apiUrl from '../../constants';

   export default {
     name: "candidatescheduler",     
     components: {
         DatePicker,
         VueTimepicker,
         VueElementLoading,
         profilepic,
         Loading,
         Treeselect        
     },
     mixins: [ commonMethods ],
     data() {
     return {
         defaultValue: new Date(),               
         scheduleStartDate:'',          
         fullPage: true,
         candidatename: null,
         isLoading: false,
         candidatedetails:{
            editRowData: [],
            candidateid: null,
            candidatename: null,
            status: null,
            applicationdate: null,
            candidatemail: null,
            candidatecontactno: null,
            candidatetotalexp: null,
            source: null,
            jobsourcename: null,
            imagename: null,
            interviewstage: [],
            stagewithstatus: [],
            reqid: null
        },       
        logtime:{          
            spenttime:"",
            interviewer:null,
            scheduleflag:false,
            ccEmail:null,
            allottedtolist: [],
            optionalinterviewerlist: [],
            activity:null,
            status:null,
            comment:null,
            attachmentview:[],
            isTimeLogged: false,
            optionalinterviewer: null
        },
        errors: {
            spenttime:"",
            interviewer:null,
        },
        allottedto: null,
        interviewer: null,
        allottedtolist: [],
        roundno: null,
        isshow: false,
        recruitmentprojectid: apiUrl.recruitmentprojectid,
        candidateid: null
     }
     },
   mounted() {        
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.candidateid = parseInt(this.$route.params.clickeddata.candidateid);
            this.roundno = parseInt(this.$route.params.clickeddata.roundno);
            if(this.candidateid && this.roundno){
                this.getcandidatedetailsbyid(this.candidateid);
            }
        }
    },
    methods:{
        getcandidatedetailsbyid(canid){
         this.isLoading = true;
            let url = "api/candidate/getcandidatedetailsbyid";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                candidateid: parseInt(canid)
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.candidatedetails = result.data.data[0];
                    this.candidatename=result.data.data[0]['candidatefname'].trim()+" "+result.data.data[0]['candidatelname'].trim();
                    this.hcid=result.data.data[0]['hcid'];
                    this.getrequisitionwiseinterviewers(this.candidatedetails.reqid,this.roundno);
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.candidatedetails.editRowData = [];
                    this.$router.push({ name: 'RaiseRequisition', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to update this candidate",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getEmpallocatedtoProject(node,isstate) {
            if (node && node.length >3 ) {
                axios({
                    method: "POST",
                    url: "api/listing/getEmpallocatedtoProject",
                    data: { useremail: this.undt.username,empcode: this.undt.userid, asigneename: node.toLowerCase(), projectid: this.recruitmentprojectid },
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        let decryptdepartmenttext = result.data.data.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                                items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                        if(isstate ==0){
                            this.logtime.allottedtolist = result.data.data;
                        }else if(isstate ==1){
                            this.logtime.optionalinterviewerlist = result.data.data;
                        }
                    } else {
                        this.logtime.allottedtolist = []; 
                        this.logtime.optionalinterviewerlist = [];           
                    }
                    
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getdataforrosterid(assigntoid) {
            this.isLoading =true;
            axios({
                method: "POST",
                url: "api/listing/getAllEmployeeList",
                data: { useremail: this.undt.username,empcode: this.undt.userid, employeeidarray: assigntoid},
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    let decryptdepartmenttext = result.data.data.map(items =>{

                        let tempemplabel1 = items.label;
                        let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                        if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                        }
                        return items;
                    })
                    this.logtime.allottedtolist = result.data.data;
                }
                
            }).catch(e => {
                this.displayError(e)
            });
        },
        savewizard(mdl,objThis){  
        //this.valid = this.validateForm(mdl,objThis);
        this.valid=true;
        if (this.valid) {
            this.isLoading = true;
            let tblNm='t59'
            if(mdl) tblNm=mdl
            this.input = {               
                section: 'candidateschedule',                
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            let apiURL='api/hrms/createschedule'
            //   candidatename,logtime.scheduleStartDate,logtime.spenttime,logtime.scheduleflag,logtime.ccEmail,logtime.optionalinterviewer,logtime.interviewer
            this.input.candidateid=this.candidateid;
            this.input.spenttime=this.logtime.spenttime;
            this.input.interviewer=this.logtime.interviewer;
            this.input.optionalinterviewer=this.logtime.optionalinterviewer;
            this.input.round=this.roundno;
            this.input.ccemail=this.logtime.ccEmail;
            this.input.scheduledate= this.logtime.scheduleStartDate;
            this.input.status="1";
            this.input.scheduleflag= '1';			
            this.input.section="candidateschedule";
            this.input.useremail= this.undt.username;
            this.input.empcode= this.undt.userid;
            

            this.isAddState=false
            this.input.useremail= this.undt.username
            this.input.empcode= this.undt.userid
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;             
                if(result.data.errorCode == 0){
                   
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                     path: 
                    this.$router.push(`/hrms/processongoing?candidateid=${parseInt(this.candidateid)}`)
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                    }).then(function() {
                    window.location.href = "/#/login";
                    })
                } else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })
        }
    },
    validatelog: function(){
      var isValid=true;
      let spenttime = this.logtime.spenttime && this.logtime.spenttime.HH && this.logtime.spenttime.mm
      
      if(!spenttime && typeof(this.logtime.spenttime)=='string')
      {
        let logstime = (this.logtime.spenttime).split(':')
        if(logstime){
            if(logstime[0] && logstime[1]){
                spenttime = `${logstime[0]}:${logstime[1]}`
                  this.logtime.spenttime = `${logstime[0]}:${logstime[1]}`
            }
        }
      }
      if(spenttime && typeof(this.logtime.spenttime)!='string'){
             this.logtime.spenttime = `${this.logtime.spenttime.HH}:${this.logtime.spenttime.mm}`
      }

      if(spenttime==''){
        this.errors.spenttime="Spent-time Hours & minutes is required";
        isValid= false;
      }else{
        this.errors.spenttime='';
      }       
      return isValid
    },
    validateForm(mdl,objThis){
        this.isAddState=false
        var isValid=true;
        this.issuenameBlured = true;
        this.scenarioBlured = true;
        this.reasonBlured = true;
        this.impactBlured = true;
        if(mdl=="t59"){
            if(!this.issue.issuename){
                this.errors.issue.issuename="Issue name required";
                isValid= false;
            } else {
              this.errors.issue.issuename = ""
            }
            if(!this.issue.project){
                this.errors.issue.project="Project is required";
                isValid= false;
            } else {
                this.errors.issue.project = null;
            }
            if(!this.issue.businesstaskid && !this.issue.businesstaskcode && (this.issue.type != 9 && this.issue.type != 18)){
                this.errors.issue.businesstaskid="Business task name is required";
                isValid= false;
            } else {
                this.errors.issue.businesstaskid = null;
            }
            if(!this.issue.businesstaskcode && !this.issue.businesstaskid && (this.issue.type != 9 && this.issue.type != 18)){
                this.errors.issue.businesstaskcode="Business task code is required";
                isValid= false;
            }else {
                this.errors.issue.businesstaskcode = null;
            }
            if(!this.issue.type){
                this.errors.issue.type="Issue type required";
                isValid= false;
            }
            if(!this.issue.scenario){
                this.errors.issue.scenario="Issue scenario required";
                isValid= false;
            } else {
                this.errors.issue.scenario="";
            }
            if(!this.issue.reasonofissue) {
              this.errors.issue.reasonofissue="Reason of issue required";
              isValid= false;
            } else {
                this.errors.issue.reasonofissue = ""
            }
            if(!this.issue.allotedto){
                this.errors.issue.allotedto="Assigned to required";
                isValid= false;
            } else {
                this.errors.issue.allotedto = "";
            }
            if(!this.issue.sevairty){
                this.errors.issue.sevairty="Issue severity required";
                isValid= false;
            }
            if(!this.issue.priority){
                this.errors.issue.priority="Issue priority required";
                isValid= false;
            }
            if(!this.issue.status){
                this.errors.issue.status="Issue status required";
                isValid= false;
            }
            if(!this.issue.impactofissue) {
              this.errors.issue.impactofissue="Impact of issue required";
              isValid= false;
            } else {
              this.errors.issue.impactofissue=""
            }
            if((!this.issue.subscribers) || (this.issue.subscribers && this.issue.subscribers.length < 1)) {
                this.errors.issue.subscribers = 'Subscribers are required'
                isValid = false
            } else {
                this.errors.issue.subscribers = ''
            }
        }
        return isValid
    },
    clearerrorForm: function(){
        this.errors.issue.issuename = null
        this.errors.issue.project = null
        this.errors.issue.functionality = null
        this.errors.issue.type = null
        this.errors.issue.scenario = null
        this.errors.issue.attachment = null
        this.errors.issue.sevairty = null
        this.errors.issue.priority = null
        this.errors.issue.status = null
        this.errors.issue.impactofissue = null
        this.errors.issue.businesstaskid = null
        this.errors.issue.businesstaskcode = null
    },
    selLogAllotedTo(state, value) {
        if(!state) {
            this.errors.interviewer = "interviewer to required",
            this.erorrs.optionalinterviewer="optional interviewer to required"
        } else {
            this.errors.interviewer = null,
            this.errors.optionalinterviewer = null
        }
    },
    getRosterDetails() {
        this.isLoading = true;
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            projectid: this.recruitmentprojectid,
            emailticket: 1
        }
        let apiURL = 'api/roaster/getrosterdetails'
        axios({
            method: "POST",
            url: apiURL,
            data: this.input,
            headers: { authorization: this.tokendata },   
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.isshow = true;
                let tempdata = [];
                let d= result.data.data;
                let test =d.map(a=>{
                    tempdata.push(a.allocatedto);
                })
                this.getdataforrosterid(tempdata);
            } else {
                this.isshow = false;
            }
        }).catch(e => {
            this.displayError(e);
        });
    },
    notBeforeTodaydate: function (date) {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    redirectpage:function(btnstate,candidateid){
        if(btnstate=="candidatepage"){
            this.$router.push({ 
                path: `/hrms/processongoing?candidateid=${parseInt(candidateid)}`,
                params: {
                    backbtnstate:btnstate
                }
            });
        }
    },
    getrequisitionwiseinterviewers(reqid,roundno) {
        this.isLoading =true;
        axios({
            method: "POST",
            url: "api/hrms/getrequisitionwiseinterviewers",
            data: { useremail: this.undt.username,empcode: this.undt.userid, reqid: reqid, roundno: roundno},
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.isshow = true;
                let decryptdepartmenttext = result.data.data.rows.map(items =>{

                    let tempemplabel1 = items.label;
                    let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                    if(tempemplabel2 && tempemplabel2 != null){
                        items.label = tempemplabel1 +'('+tempemplabel2 +')';
                    }
                    return items;
                })
                this.logtime.allottedtolist = result.data.data.rows;
            }else{
                this.getRosterDetails();
            }
            
        }).catch(e => {
            this.displayError(e)
        });
    },
   }    
};   
</script>