<template>
    <div class="app-content content">
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots"
            :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper mt-1">
            <section id="modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Test Case</h3>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">
                                    Test Case Management listing
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning"
                    style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;border-radius: 15px 15px 0px 0px;">
                    <div class="card-header filter_rotation_onlick" style="cursor:pointer"
                        v-on:click="mysearchrequestollapse">
                        <div class="container-fluid">
                            <div class="row mobile_co_mainheader_text">
                                <div class="w-auto px-1 mobile_margin-px-0_00 mobile_w_calc_38">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon size="1.5x"
                                                class="custom-class avatar-icon font-medium-3"></search-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex ml-1 mobile_width-86">Search Test Case Management
                                    </h4>
                                </div>
                                <span class="rotetions_view_arrow ml-auto mx-1">
                                    <em class="las la-angle-down"></em>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{ show: isSearchRequestActive }, card_content, collapse]">
                        <div class="card-body px-sm-mobile-10">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Test Cycle Code</label>
                                    <input type="text" class="form-control" placeholder="Enter Test Cycle Code" v-model="search.testCycleCode"  @input="selectedTestCycleCode"/>
                                    <div class="errorinputmsg" v-if="!this.search.testCycleCode">{{ this.inputTestCycleCodeErr }}</div> 
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Test Cycle Name</label>
                                    <treeselect class="projectmdllistdd capitalisetext"
                                        placeholder="Enter Min 5 Char Of Test Cycle Name"
                                        v-model="search.testCycleName" v-on:search-change="gettestcyclenamelist"
                                        :options="search.testcyclenamelist" :clear-on-select="true" @input="selectedTestCycleName"/>
                                        <div class="errorinputmsg" v-if="!this.search.testCycleName">{{ this.inputTestCycleNameErr }}</div> 
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Business Task Code </label>
                                    <treeselect class="projectmdllistdd capitalisetext"
                                        placeholder="Enter Min 8 Char Of Business Task Code"
                                        v-model="search.businesscode" @input="selectedBTCtaskCode"
                                        v-on:search-change="getbtccodelist" :options="search.btccodelist"
                                        :clear-on-select="true" />
                                        <div class="errorinputmsg" v-if="!this.search.businesscode">{{ this.inputBTCtaskCodeErr }}</div>
                                </div>
                                <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Business Task Name</label>
                                    <treeselect class="projectmdllistdd capitalisetext"
                                        placeholder="Enter Min 5 Char Of Business Task Name"
                                        v-model="search.tcname" @input="clearCodeList" v-on:search-change="getTCGlist"
                                        :options="search.tcnamelist" :clear-on-select="true" />
                                </div> -->
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Platform</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Platform Name"
                                        :multiple="true" v-model="search.platname" @input="clearCodeList"
                                        v-on:search-change="search.platnamelist" :options="search.platnamelist"
                                        :clear-on-select="true" />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Region</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Region"
                                        :multiple="true" v-model="search.regionname" @input="clearCodeList"
                                        v-on:search-change="search.regionnamelist" :options="search.regionnamelist"
                                        :clear-on-select="true" />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1" style="display: none;">
                                    <label class="form-label lable-left">Status</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Status" />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1 text-left">
                                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="(isTestCycleSelected && isTestCycleNameSelected && isBTCTaskCodeSelected)" @click="getlist(search, 1)" >
                                        <search-icon size="1.5x" class="custom-class"></search-icon>
                                        <span>Search</span></button>
                                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                        <span>Clear</span></button>
                                        <div class="text-danger mt-1" style="font-size: x-small;">[Note: Please select Test Cycle Code/ Test Cycle Name/ Business Task Code to Search]</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-0">
                    <div class="card">
                        <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys"
                                aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col" class="align-middle srno">Sr</th>
                                        <th scope="col" class="align-middle">TC Number / Name </th>
                                        <th scope="col" class="align-middle">Region</th>
                                        <th scope="col" class="align-middle text-left">Platform</th>
                                        <th scope="col" class="align-middle">Test case steps</th>
                                        <th scope="col" class="align-middle">CC - Status</th>
                                        <th scope="col" class="align-middle">Last Modified by</th>
                                        <th scope="col" class="align-middle">Last Modified date</th>
                                        <th scope="col" class="align-middle">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(ldata, index) in listDataShow">
                                        <tr>
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ ldata.tcscriptcode }}&nbsp;:&nbsp;{{ ldata.testcasename }}</td>
                                            <td>{{ ldata.country }}</td>
                                            <td>{{ ldata.enviornment }}</td>
                                            <td>
                                                <span class="fc_m16_btcode_text"
                                                    v-html="formatData(ldata.scriptsteps)"></span>
                                            </td>
                                            <td>{{ ldata.cyclesetstatus }}</td>
                                            <td>{{ ldata.lastmodifiedby }}</td>
                                            <td>{{ ldata.lastmodifieddate ? dateMonthFormat(ldata.lastmodifieddate) : 'NA' }}
                                            </td>
                                            <td>{{ ldata.createddate ? dateMonthFormat(ldata.createddate) : 'NA' }}</td>
                                        </tr>
                                    </template>
                                    <template v-if="listDataShow.length == 0">
                                        <tr>
                                            <td colspan="14" style="text-align: center;">No record found</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Pagination from "vue-pagination-2";
import commonMethods from "../../utils/commonMethods";
import {
    PlusCircleIcon,
    PlusSquareIcon,
    Edit2Icon,
    CalendarIcon,
    UserIcon,
    EyeIcon,
    WatchIcon,
    SettingsIcon,
    MonitorIcon,
    SearchIcon,
} from "vue-feather-icons";
export default {
    name: "TestCaseManagement_listView",
    components: {
        Loading,
        vSelect,
        Treeselect,
        VueElementLoading,
        Pagination,
        Edit2Icon,
        CalendarIcon,
        PlusCircleIcon,
        PlusSquareIcon,
        UserIcon,
        EyeIcon,
        SearchIcon,
        WatchIcon,
        SettingsIcon,
        MonitorIcon,
    },
    mixins: [commonMethods],
    data() {
        return {
            isLoading: false,
            islistLoading: false,
            fullPage: true,
            listfullPage: true,
            isistLoading: false,
            isSearchRequestActive: false,
            blog_list_wrapper: 'blog-list-wrapper',
            isRequestActive: false,
            card_content: 'card-content',
            collapse: 'collapse',
            cyclelist: [],
            tcnamelist: [],
            tcname: null,
            btccodelist: [],
            businesscode: null,
            testCycleName: null,
            testCycleCodeList: [],
            testCycleCode: null,
            incidentcodelist: null,
            testcyclenamelist: [],
            search: {
                empname: null,
                results: null,
                emplist: [],
                resultlist: [],
                empcode: null,
                testCycleCode: null,
                testCycleName: null,
                businesscode: null,
                tcname: null,
                statuses: null,
                btccodelist: [],
                tcnamelist: [],
                testcyclenamelist: [],
                platname: null,
                platnamelist: [
                    {
                        id: 'Android',
                        label: 'Android',
                    },
                    {
                        id: 'Desktop',
                        label: 'Desktop',
                    },
                    {
                        id: 'IOS',
                        label: 'IOS',
                    },
                 
                    {
                        id: 'Mobile',
                        label: 'Mobile',
                    }],
                regionname: null,
                regionnamelist: [
                    {
                        id: 'IND',
                        label: 'IND',
                    },
                    {
                        id: 'KSA',
                        label: 'KSA',
                    },
                    {
                        id: 'UAE',
                        label: 'UAE',
                    }
                ]
            },
            loginuseremail: '',
            pagefeatures: [],
            statuses: null,
            status: apiUrl.testcyclestatuslist,
            store_cyclesetid: null,
            store_cycleSetStatus: '',
            editRowDatas: [],
            listDataShow: [],
            getlistData: [],
            isTestCycleSelected: true,
            inputTestCycleCodeErr: '',
            isTestCycleNameSelected: true,
            inputTestCycleNameErr: '',
            isBTCTaskCodeSelected: true,
            inputBTCtaskCodeErr: '',
        };
    },
    created() {},
    mounted() {
        // let permission = this.$Service.verifyAccess();
        // if(permission){
        var userdata = window.localStorage.getItem('userdata');
        this.tokendata = window.localStorage.getItem('token');

        if (userdata) {
            userdata = JSON.parse(userdata);
            this.loginuseremail = userdata.emailaddress;
            this.undt = userdata;
            if (this.$route.params.ccID != undefined) {
                this.store_cyclesetid = this.$route.params.ccID;
                this.store_cycleSetStatus = this.$route.params.ccStatus;
                if(this.store_cyclesetid)
                {
                    this.search.testCycleName=this.store_cyclesetid;
                }
                this.getcyclesetviewresulttbl(this.store_cyclesetid, '', 0);
                this.gettestcyclename(this.store_cyclesetid);
            }
            else {
                this.listDataShow = [];
                this.getlistData = [];
            }
        }
        $(".filter_rotation_onlick").click(function () {
            $(this).toggleClass("common_arrow_rotetion");
        });
    },

    methods: {
        mysearchrequestollapse: function () {
            this.isSearchRequestActive = !this.isSearchRequestActive
        },
        formatData(data) {
            return data;
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getcyclesetviewresulttbl(this.store_cyclesetid, '', 0);
            setTimeout(function () {
                $('html, body').animate({
                    scrollTop: $("#myTabContent").offset().top - 70
                });
            }, 500);
        },
        resetRecords: function () {
            this.listDataShow = [];
            this.search.empcode = null
            this.search.results = null
            this.isBothDateSelected = false
            this.search.testCycleCode = null
            this.search.testCycleName = null
            this.search.businesscode = null
            // this.search.tcname = null
            this.search.platname = null
            this.search.regionname = null;
            this.datefrom = null;
            this.dateto = null;
            this.isTestCycleSelected = true;
            this.isTestCycleNameSelected = true;
            this.isBTCTaskCodeSelected = true;
            localStorage.removeItem('cyclesetsearchItems');
            localStorage.removeItem('cyclesetbtccodelist');
            localStorage.removeItem('cyclesettcnamelist');
            localStorage.removeItem('testcyclenamelist');
            this.page = 1
        },
        clearCodeList(state) {
            if (!state) {
                this.search.businesscode = null;
                this.search.testCycleCode = null;
                this.search.testCycleName = null;
                // this.search.tcname = null;
                this.search.platname = null;
                this.search.regionname = null;
            }
        },
        selectedTestCycleCode(state) {
            this.inputTestCycleNameErr = '';
            this.inputBTCtaskCodeErr = '';

            if(this.search.testCycleCode.trim() === '')
            {
                this.isTestCycleSelected = true;
                this.inputTestCycleCodeErr = "Please Select Test Cycle Set Code";
            }
            else {
                this.isTestCycleSelected = false;
            }
        },
        selectedTestCycleName(state) {
            this.inputTestCycleCodeErr = '';
            this.inputBTCtaskCodeErr = '';

            if(this.search.testCycleName === undefined)
            {
                this.isTestCycleNameSelected = true;
                this.inputTestCycleNameErr = "Please Select Test Cycle Set Name";
            }
            else {
                this.isTestCycleNameSelected = false;
            }
        },
        selectedBTCtaskCode(state) {
            this.inputTestCycleCodeErr = '';
            this.inputTestCycleNameErr = '';

            if(this.search.businesscode === undefined)
            {
                this.isBTCTaskCodeSelected = true;
                this.inputBTCtaskCodeErr = "Please Select BTC Task Code";
            }
            else {
                this.isBTCTaskCodeSelected = false;
            }
        },
        getlist(objThis, flag) {
            if (objThis) {
                localStorage.setItem('cyclesetsearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            let search = JSON.parse(localStorage.getItem('cyclesetsearchItems'))
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            if (search) {
                this.inputTestCycleCodeErr = '';
                this.inputTestCycleNameErr = '';
                this.inputBTCtaskCodeErr = '';
                this.input.testcyclecode = search.testCycleCode ? search.testCycleCode : null;
                this.input.testcyclename = search.testCycleName ? search.testCycleName : null;
                this.input.businesscode = search.businesscode ? search.businesscode : null;
                // this.input.tcname = search.tcname ? search.tcname : null;
                this.input.platname = search.platname ? search.platname : null;
                this.input.regionname = search.regionname ? search.regionname : null;
                this.input.cyclesetid = null;
            }
            if (this.datefrom && this.dateto) {
                this.input.datefrom = this.datefrom ? this.datefrom : null;
                this.input.dateto = this.dateto ? this.dateto : null;
            }
            this.getcyclesetviewresulttbl(null, this.input, 1)
        },
        getTCGlist(node) {
            if (node.length > 4) {
                this.input = {
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    taskname: node.toUpperCase(),
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getbtcnamelist',
                    'data': this.input,
                    'headers': { 'authorization': this.tokendata }
                })
                    .then(result => {
                        if (result.data.errorCode == 0) {
                            this.search.tcnamelist = result.data.data.rows;
                            localStorage.setItem('cyclesettcnamelist', JSON.stringify(this.search.tcnamelist));
                        } else {
                            this.search.tcnamelist = [];
                            localStorage.removeItem('cyclesettcnamelist');
                        }
                    }).catch(e => {
                        this.displayError(e)
                    })
            }
        },
        getbtccodelist(node) {
            if (node.length > 7) {
                this.input = {
                    useremail: this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    taskid: node.toUpperCase(),
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getsearchbtccodelist',
                    'data': this.input,
                    'headers': { 'authorization': this.tokendata }
                })
                    .then(result => {
                        if (result.data.errorCode == 0) {
                            this.search.btccodelist = result.data.data.rows;
                            localStorage.setItem('cyclesetbtccodelist', JSON.stringify(this.search.btccodelist));
                        } else {
                            this.search.btccodelist = [];
                            localStorage.removeItem('cyclesetbtccodelist');
                        }
                    }).catch(e => {
                        this.displayError(e)
                    })
            }
        },
        gettestcyclenamelist(node) { // search filter - Test Cycle Name
            if (node && node.length > 4) {
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    cyclename: node.toLowerCase(),
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/gettestcyclename',
                    'data': this.input,
                    'headers': { 'authorization': this.tokendata }
                })
                    .then(result => {
                        if (result.data.errorCode == 0) {
                            this.search.testcyclenamelist = result.data.data.rows;
                            localStorage.setItem('testcyclenamelist', JSON.stringify(this.search.testcyclenamelist));
                        } else {
                            this.search.testcyclenamelist = [];
                            localStorage.removeItem('testcyclenamelist');
                        }
                    }).catch(e => {
                        this.displayError(e)
                    })
            }
        },
        gettestresultlist(objThis) {
            if (objThis) {
                localStorage.setItem('cyclesetsearchItems', JSON.stringify(objThis))
            }
            this.isLoading = true;
            let search = JSON.parse(localStorage.getItem('cyclesetsearchItems'))
            this.input = {
                useremail: this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            if (search) {
                this.input.testcyclecode = this.search.testCycleCode
                this.input.testcyclename = this.search.testCycleName
                this.input.businesscode = this.search.businesscode
                // this.input.tcname = this.search.tcname
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/gettestresultlist',
                'data': this.input,
                'headers': { 'authorization': this.tokendata }
            }).then(result => {
                if (result.data.errorCode == 0) {
                    this.cyclelist = result.data.data
                    this.totalcount = result.data.data.count;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                } else if (result.data.errorCode == 3) {
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function () {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.cyclelist = []
                    this.totalcount = 0;
                    this.pageCount = 0;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        getcyclesetviewresulttbl(cyclesetid, searchInputPara, sFlag) { // main listing function
            this.isLoading = true;
            this.listDataShow = [];
            this.getlistData = [];
            let url = "api/testcycle/getcyclesetviewresulttbl";

            let searchInput = {}
            if(sFlag == 1){
                searchInput = searchInputPara
            }
            else
            {
                searchInput = {
                    cyclesetid: cyclesetid,
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
            }

            axios({
                method: "POST",
                url: url,
                data: searchInput,
                headers: { authorization: this.tokendata },
            })
                .then(async (result) => {
                    this.isLoading = false;

                    if (result.data.errorCode == 0) {
                        let apiResult = result.data.data.rows;

                        // grouping logic
                        let groupedObjects = apiResult.reduce((result, currentItem) => {
                                (result[currentItem['testcasemasterid']] = result[currentItem['testcasemasterid']] || []).push(currentItem);
                                return result;
                            }, {});
                        
                        let trackArray = Object.values(groupedObjects);
                        let finalArray = [];

                        // sorting priority wise logic
                        for (let i = 0; i < trackArray.length; i++) {
                            if (trackArray[i].length > 1) {
                                let jArray = trackArray[i];
                                const priorityList = ['android', 'ios', 'desktop', 'mobile'];

                                let sortedArray = jArray.sort((a, b) => {
                                    let var1 = a.enviornment.toLowerCase()
                                    const priorityA = priorityList.indexOf(var1);

                                    let var2 = b.enviornment.toLowerCase()
                                    const priorityB = priorityList.indexOf(var2);

                                    return priorityA - priorityB;
                                });

                                let sortedScriptCodes = [];
                                let sortedCountry = [];
                                let sortedEnv = [];

                                for (let j = 0; j < sortedArray.length; j++) {
                                    if (j < sortedArray.length) {
                                        sortedScriptCodes.push(sortedArray[j].tcscriptcode);
                                        sortedCountry.push(sortedArray[j].country);
                                        sortedEnv.push(sortedArray[j].enviornment);
                                    }
                                }

                                const uniqueTSCodeArray = [...new Set(sortedScriptCodes)];
                                const uniqueCountryArray = [...new Set(sortedCountry)];
                                const uniqueEnvArray = [...new Set(sortedEnv)];

                                const concatenatedScriptCodes = sortedScriptCodes.join('/\n');
                                const concatenatedCountry = uniqueCountryArray.join('/\n');
                                const concatenatedEnv = uniqueEnvArray.join('/\n');

                                sortedArray[0].tcscriptcode = concatenatedScriptCodes;
                                sortedArray[0].country = concatenatedCountry;
                                sortedArray[0].enviornment = concatenatedEnv;

                                finalArray.push(sortedArray[0]);

                                // const storeOneObj = Object.values(sortedArray[0]);
                                // finalArray.push(...storeOneObj);
                            }
                            else {
                                let storeOneObj = Object.values(trackArray[i]);
                                finalArray.push(...storeOneObj);
                            }
                        }
                        this.listDataShow = finalArray;
                        this.totalcount = result.data.data.count;
                    }
                    else {
                        this.listDataShow = [];
                        this.getlistData = [];
                        this.totalcount = 0;
                    }
                })
                .catch((e) => {
                    this.displayError(e);
                });
        },
        gettestcyclename(id) { //to get the test cycle name using id (received while redirection)
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                cyclesetid: id,
            }

            axios({
                'method': 'POST',
                'url': 'api/testcycle/gettestcyclename',
                'data': this.input,
                'headers': { 'authorization': this.tokendata }
            })
                .then(result => {
                    if (result.data.errorCode == 0) {
                        this.search.testcyclenamelist = result.data.data.rows;
                        this.search.testcyclename = [id]
                    }
                    else {
                        this.search.testcyclenamelist = [];
                    }
                })
                .catch(e => {
                    this.displayError(e)
                })
        },
    },

};
</script>

