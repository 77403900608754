<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1 mt-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left pl-0 ">
                 <h3 class="content-header-title float-left mb-0">Add Tracker</h3>
                 <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls " >
                    <router-link :to="{ name: 'TrackerListing', params: { search: true }}">Tracker List</router-link>
                  </li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" >Add Tracker</li>
                 </ol>
               </div>
               </div>
             </div>
           </div>
         </div>
         <div class="card border-warning filter_card_searchbgst" >
           <div class="card-header py-1 px-2" style="border-bottom:1px solid #d8d8d8">
                <h3 class="card-title">
                    Add Tracker Against the Department
                </h3>
           </div>
           <div class="card-body pt-1 px-sm-mobile-10">
               <div class="row">
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <label class="form-label lable-left">Tracker Department</label>
                     <treeselect placeholder="Select Department" class="projectlistdd" :options = "departmentList" v-model="departmentid" v-if="departmentList.length > 1" @close="validateForm($event)"/>
                    <input class="form-control" type="text" v-model="department" disabled v-else>
                    <div class="errorinputmsg" v-if="error.departmentid">{{ error.departmentid }}</div>
                </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                   <label class="form-label lable-left">Tracker Name</label>
                   <input type="text" class="form-control capitalisetext" placeholder="Select Tracker"  v-model="tracker" :clear-on-select="true" @close="validateForm($event)"/>
									 <div class="errorinputmsg" v-if="error.tracker">{{ error.tracker }}</div>
                 </div>
                <div class="col-sm-4 col-md-4 col-lg-4">
                    <label class="form-label lable-left">Tracker Type</label>
                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Type" v-model="type" :options="typelist" :clear-on-select="true" @close="validateForm($event)"/>
										<div class="errorinputmsg" v-if="error.type">{{ error.type }}</div>
                </div>
               </div>
               <div class="col-sm-12 col-md-12 col-lg-12 text-right px-0 ">
                   <button type="submit" class="btn btn-relief-primary mt-2 mr-0" @click="savedata()" ><span> Submit</span></button>
                 </div> 
             </div>
         </div>
       </section>
     </div>
   </div>
  </template>
  <style>
    .table_input_width_150 tbody td{
            min-width: 150px;
    }
    .createbtnparentdiv{
        padding-right: 0% !important;
    }
    .displayblk{
        display:block !important;
    }
    .createbtn{
        border-color: #053e52 !important;
        color: #fff !important;
        background-color: #008000 !important;
        padding: 0.386rem 0.5rem !important;
    }
  </style>  

  <script>
    import axios from 'axios'
    import apiUrl from '../../constants';
    import moment from 'moment'
    import VueElementLoading from 'vue-element-loading';
    import vSelect from 'vue-select'
    import { EditIcon, EyeIcon, Trash2Icon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, PlusCircleIcon } from 'vue-feather-icons'
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Pagination from 'vue-pagination-2'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
    import CKEditor from '@ckeditor/ckeditor5-vue2';
    import { json } from 'body-parser';
    import commonMethods from '../../utils/commonMethods';

    export default {
    name:'TrackerMaster',
        components:{
            Loading,
            VueElementLoading,PlusCircleIcon,
            vSelect,
            EditIcon,
            EyeIcon,
            Treeselect,
            SearchIcon,
            GridIcon,
            CodesandboxIcon,
            CloudIcon,
            ChromeIcon,
            BoxIcon,
            Link2Icon,
            PackageIcon,
            Pagination,
            MoreVerticalIcon,
            PlusIcon,
            DownloadIcon,
            Edit2Icon,
            PlusSquareIcon,
            ClipboardIcon,
            ckeditor: CKEditor.component,
            Trash2Icon,
        },
        mixins: [ commonMethods ],
        data() {
            return {
                loginuser:'',
                isLoading: false,
                fullPage: true,
                
                tracker : null,
                department: null,
                departmentid: null,
                departmentList: [],
                type: null,
                typelist : apiUrl.trackerlisttype.sort((a, b) => a.label.localeCompare(b.label)),

								error: {
									tracker:'',
									departmentid:'' ,
									type:''
								}
            }
        },
        mounted(){
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.loginusername=userdata.username
                this.loginuscondition = userdata.userid 
                this.tokendata = window.localStorage.getItem('token');  
            }
            this.getDepartmentList()
        },
        methods:{
            getDepartmentList() {
                this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empid: parseInt(this.undt.userid)
                }
                axios({
                    'method': 'POST',
                    'url': 'api/formconfigure/getEmpDepartList',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false
                    if(result.data.status == true){
                        this.departmentList = result.data.data.rows;
                        let decryptdepartmenttext = this.departmentList.map(items =>{
                            items.label = this.decryptText(items.label);
                            return items;
                        })
                        if(result.data && result.data.data){
                            if(result.data.data.orows && result.data.data.orows.length >0){
                                this.departmentid = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
                            }
                        }
 
                        let dp = this.departmentList.filter((v) => {
                            if(v.id==this.departmentid)
                            return v;
                            });
                        this.department = dp ? dp[0].label : null
                        this.departmentList.sort((a, b) => {
                            return a.label.localeCompare(b.label);
                        });
                      }else{
                        this.departmentList=[]
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            },
            savedata(objThis){
							this.isValid = this.validateForm(objThis)
							if (this.isValid){
                let dp = this.departmentList.filter((v) => {
                            if(v.id==this.departmentid)
                            return v;
                            });
                this.input = {
                    useremail: this.undt.username,
                    empcode: parseInt(this.undt.userid),
                    moduletype : this.tracker,
                    departmentid : this.departmentid,
                    department: dp ? dp[0].label : null,
                    type : this.type,
                    isactive: 1
                }                
                let apiURL='api/formconfigure/createTracker'
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result =>{
                    this.isLoading = false;
                    if(result.data.errorCode == 0){
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.cleardata();
                    this.$router.push({ name: 'TrackerListing', params: { search: true }});
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                                buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                this.displayError(e)
                })
							}
            },
            cleardata(){
                this.tracker = null,
                this.departmentid = null,
                this.department = null,
                this.type = null
                this.getDepartmentList()
            },
            selDepartment(state) {

            let dp = this.departmentList.filter((v) => {
            if(v.id==state)
            return v;
            });

            this.department = dp ? dp[0].label : null
            },
							validateForm(){
								var isValid = true 
								if (!this.tracker) {
									this.error.tracker = 'Enter Tracker Name'
									isValid = false
								} else {
									this.error.tracker = ''
								}
								if (!this.departmentid) {
									this.error.departmentid = 'Select Department'
									isValid = false
								} else {
									this.error.departmentid = ''
								}
								if (!this.type) {
									this.error.type = 'Select Tracker Type'
									isValid = false
								} else {
									this.error.type = ''
								}
								return isValid
							}
            }
            }
    
  </script>