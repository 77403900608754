<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="row">
        <div class="col-sm-4">
          <div class="card main_articlas_views mb-2">
            <div class="card-body" id="chartjs-chart">
              <canvas class="doughnut-chart-ex chartjs" data-height="275"></canvas>
              <div class="d-flex justify-content-between mt-3 mb-1">
                                        <div class="d-flex align-items-center">
                                            <i data-feather="monitor" class="font-medium-2 text-primary"></i>
                                            <span class="font-weight-bold ml-75 mr-25">Desktop</span>
                                            <span>- 80%</span>
                                        </div>
                                        <div>
                                            <span>2%</span>
                                            <i data-feather="arrow-up" class="text-success"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mb-1">
                                        <div class="d-flex align-items-center">
                                            <i data-feather="tablet" class="font-medium-2 text-warning"></i>
                                            <span class="font-weight-bold ml-75 mr-25">Mobile</span>
                                            <span>- 10%</span>
                                        </div>
                                        <div>
                                            <span>8%</span>
                                            <i data-feather="arrow-up" class="text-success"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex align-items-center">
                                            <i data-feather="tablet" class="font-medium-2 text-success"></i>
                                            <span class="font-weight-bold ml-75 mr-25">Tablet</span>
                                            <span>- 10%</span>
                                        </div>
                                        <div>
                                            <span>-5%</span>
                                            <i data-feather="arrow-down" class="text-danger"></i>
                                        </div>
                                    </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-8">
                            <div class="card main_articlas_views mb-2">
                              <div class="card-body">
                                <canvas class="bar-chart-ex chartjs" data-height="400"></canvas>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                        <div class="col-12">
                            <div class="card py-1">
                                <div class="card-header px-2">
                                    <div>
                                        <h4 class="card-title">Statistics</h4>
                                        <span class="card-subtitle text-muted">Commercial networks and enterprises</span>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <canvas class="line-chart-ex chartjs" data-height="450"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Line Chart Ends-->
                        <div class="row">
                          <div class="col-sm-12">
                              <div class="card py-0">
                                <div class="card-body" id="apexchart">
                                    <div id="column-chart"></div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                              <div class="card py-2">
                                <div class="card-header px-2 d-flex flex-sm-row flex-column justify-content-md-between align-items-start justify-content-start">
                                    <div>
                                        <h4 class="card-title mb-25">Balance</h4>
                                        <span class="card-subtitle text-muted">Commercial networks & enterprises</span>
                                    </div>
                                    <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
                                        <h5 class="font-weight-bolder mb-0 mr-1">$ 100,000</h5>
                                        <div class="badge badge-light-secondary">
                                            <i class="text-danger font-small-3" data-feather="arrow-down"></i>
                                            <span class="align-middle">20%</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div id="line-chart"></div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                               <div class="card py-2">
                                <div class="card-header px-2 flex-column align-items-start">
                                    <h4 class="card-title mb-75">Expense Ratio</h4>
                                </div>
                                <div class="card-body">
                                    <div id="donut-chart"></div>
                                </div>
                               </div>
                            </div>
                            <div class="col-sm-6">
                               <div class="card py-2">
                                <div class="card-header px-2 flex-column align-items-start">
                                    <h4 class="card-title mb-75">Expense Ratio</h4>
                                </div>
                                <div class="card-body">
                                    <div id="radialbar-chart"></div>
                                </div>
                               </div>
                            </div>
                        </div>

                      </div>
                    </div>
</template>

<script>
import axios from "axios";
import apiUrl from "../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Treeselect from "@riophae/vue-treeselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from "../utils/commonMethods";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import { Trash2Icon, CalendarIcon } from "vue-feather-icons";

export default {
  name: "Charts",
  mixins: [commonMethods],

  components: {
    VueElementLoading,
    Treeselect,
    Loading,
    ckeditor: CKEditor.component,
    Trash2Icon,
    CalendarIcon,
    DatePicker,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
