<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1 mt-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left pl-0 ">
                 <h3 class="content-header-title float-left mb-0">Escalation Matrix</h3>
                 <div class="breadcrumb-wrapper">
                   <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " >Escalation Matrix List</li>
                   </ol>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="card border-warning filter_card_searchbgst">
           <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
             <div class="container-fluid" >
               <div class="row" >
                 <div class="w-auto px-1 mobile-padding-0-2">
                   <div class="avatar bg-light-secondary rounded d-inline-flex">
                     <div class="avatar-content">
                       <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                     </div>
                   </div>
                 <h4 class="card-title d-inline-flex ml-1">Escalation Matrix Details</h4>
                 </div>
                 <div class="ml-auto d-flex">
                 <div class="px-1 text-right mobile-padding-0-2" >
                   <div class="input-group-append float-right">
                     <router-link to="/escalation_matrix/escalationcreate"><span class="btn btn-sm btn-relief-success" > <span class="mobile_hide_bt">Create Escalation Matrix Configuration </span> <plus-circle-icon size="1.5x"></plus-circle-icon> </span></router-link>
                   </div>
                 </div>
                 <span class="rotetions_view_arrow ml-auto mx-1"> 
                 <em class="las la-angle-down"></em>
               </span>
               </div>
               </div>
             </div>
           </div>
           
           <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
             <div class="card-body pt-1 px-sm-mobile-10">
               <div class="row">
                <div class="col-12 col-md-4 col-lg-3">
                    <div class="form-group mb-1">
                    <label for="projectmdllistdd">Project Name</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="project" :options="projectlist" :multiple="true" :clear-on-select="true"/>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <div class="form-group mb-1">
                    <label for="projectmdllistdd">Email Frequency</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Email Frequency" v-model="frequency" :options="frequencylist" :multiple="true" :clear-on-select="true"/>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3">
                    <div class="form-group mb-1">
                    <label for="allotedto">Active/Inactive</label>
                    <treeselect class="allotedto capitalisetext" placeholder="Select activation" v-model="searchisactive" :options="isactivelist" :multiple="true" :clear-on-select="true"/>
                </div>
                </div>
                 <div class="col-sm-12 col-md-12 col-lg-3 text-right  text-lg-left">
                  <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getEscalationlist(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                   <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="card border-warning" id="domain-list">
           <div class="card-body p-0 ps ps--active-x">
             <div class="table-responsive" >
               <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                 <thead>
                   <tr>
                     <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                     <th scope="col" class="text-center second-col-sticky">Project</th>
                     <th scope="col" class="text-center ">To Roles</th>
                     <th scope="col" class="text-center">CC Roles</th>
                     <th scope="col" class="text-center"> Email Frequency</th>
                     <th scope="col" class="text-center"> Start Time</th>
                     <th scope="col" class="text-center">IsActive</th>
                     <th scope="col" class="text-center">Action</th>
                   </tr>
                 </thead>
                 <tbody>
                     <template v-for="(data,index) in escalationlist"  >
                     <tr >
                      <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                      <td class="text-left capitalisetext second-col-sticky">{{ data.projectname }}</td>
                       <td v-if="data.toroles_designation_names && data.toroles_designation_names.length > 0" class="text-nowrap capitalisetext">
                            <ul>
                                <li v-for="(designation, index) in data.toroles_designation_names.split(',')" :key="index">
                                    {{ designation.trim() }}
                                </li>
                            </ul>
                        </td>
                        <td v-else class="text-center capitalisetext">
                            NA
                        </td>

                        <td v-if="data.ccroles_designation_names && data.ccroles_designation_names.length > 0" class="text-nowrap center capitalisetext">
                            <ul>
                                <li v-for="(designation, index) in data.ccroles_designation_names.split(',')" :key="index">
                                    {{ designation.trim() }}
                                </li>
                            </ul>
                        </td>
                        <td v-else class="text-center capitalisetext">
                            NA
                        </td>

                       <td class="text-center capitalisetext">{{ getEmailFrequencyDisplay(data.emailfrequency) }}</td>
                      <td class="text-left capitalisetext">{{ data.starttime }}</td>
                      <td class="text-center text-capitalize"  v-if="data.isactive === 1">
                      <em class="las la-check-circle custom-class"  style="color: #28c76f;font-size: 24px;"></em>
                        
                      </td>
                      <td class="text-center text-capitalize" v-else>
                        <em class="las la-times-circle custom-class" style="color: #b82323;font-size: 24px;"></em>
                      </td>
                      <td class="centeralign">
                         <span class=" devEditEle edit_open_box_exam" @click="redirecttourl('update_escalation', data)">
                           <em class="las la-pen custom-class" style="color: #4F46E5;font-size: 24px;cursor: pointer;"> </em>
                         </span>
                       </td>
                      </tr>
                   </template>
                   <template v-if="escalationlist.length==0">
                     <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                   </template>
                 </tbody>
               </table>
             </div>
             <div class="pageCenter text-center mt-2">
               <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
             </div>
           </div>
         </div>
       </section>
     </div>
   </div>
  </template>
  
  <script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import VueElementLoading from 'vue-element-loading';
  import Loading from 'vue-loading-overlay';
  import Pagination from 'vue-pagination-2'
  import Treeselect from '@riophae/vue-treeselect'
  import DatePicker from "vue2-datepicker";
  import commonMethods from '../../utils/commonMethods';
  import moment from "moment";
  
  import 'vue-loading-overlay/dist/vue-loading.css'
  import "vue2-datepicker/index.css";
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon,MinusSquareIcon,PlusSquareIcon } from "vue-feather-icons";
  import index from 'watch-size';
  
  export default {
    name: 'EscalationList',
    components: {
     VueElementLoading,
     Loading,
     Treeselect,
     DatePicker,
     PlusCircleIcon,
     EditIcon,
     CheckCircleIcon,
     XCircleIcon,
     Pagination,
     SearchIcon,
     ExternalLinkIcon,
     XIcon,
     MinusSquareIcon,
     PlusSquareIcon, 
   },
   mixins: [ commonMethods ],
   data() {
     return {
       formconfig: {
        formconfiglist: [],
         prevformconfiglist: [],
       },
       fieldnamelist : [],
       escalationlist:[],
       staticValue: ["Businesstask"],
       search: {
          tracker:null,
          trackerType:null,
       },
       isactive: true,
       isSearchBoxActive: false,
       isUpdateBoxActive: false,
       card_content:'card-content',
       collapse:'collapse',
       isLoading: false,
       undt: null,
       tokendata: null,
       totalcount: 0,
       pageCount: 0,
       page:1,
       pagelimit:apiUrl.LIMIT,
       currPageNum: 0,
       pageoffset:1,
       pagefeatures:[],
       TimeFormat: apiUrl.TimeFormat,
       purchase_date:null,
       expiry_date:null,
      type: apiUrl.trackerlisttype,
       arrayValue: [],
       statusConfigList: [],
       statusConfigUpdateList: [],
       updatelist: false,
       newedit: false,
       statuses:[],
       error: {
        tracker:'',
        type:''
      },
      trackerlisting: [],
      projectlist:[],
      project:null,
      frequencylist:[],
      frequency:null,
      isactivelist: [
          {id: 1, label:'Active'},
          {id: 0, label:'Inactive'},
        ],
        searchisactive:null
     }
   },
   mounted() {
    // let permission = this.$Service.verifyAccess();
    // if(permission){
       let userdata = window.localStorage.getItem('userdata');
    //    if(userdata) {
         userdata = JSON.parse(userdata)
         this.undt = userdata
         this.tokendata = window.localStorage.getItem('token');
        //  this.getTrackerList()
        this.getProjectAllocatedToEmp()
        this.getEscalationlist()
        this.getFrequencyList()
        this.searchisactive =[1];
          },
   methods: {
    getSearchItems(search) {
      this.isSearchBoxActive = true;
      this.getEscalationlist(search);
    },
    getEmailFrequencyDisplay(emailFrequency) {
      if (emailFrequency === 1) {
        return 'Every 2 hours';
      } else if (emailFrequency === 2) {
        return 'Every 8 hours'; 
      } else if (emailFrequency === 3) {
        return 'Daily';
      } else {
        return 'NA';
      }
    },
    getFrequencyList() {
        this.isLoading = true;
        let url = "api/escalation/getfrequencylist";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.frequencylist = result.data.data;
          } else {
            this.frequencylist = [];
          }
        });
      },
    searchBoxCollapse() {
       this.isSearchBoxActive = !this.isSearchBoxActive
     },
     clickCallback: function(pageNum) {
       this.pageoffset=pageNum
       this.currPageNum = pageNum - 1
       this.getEscalationlist();
       setTimeout(function(){
         $('html, body').animate({
           scrollTop: $("#domain-list").offset().top -70
         });
       }, 500);
     },
     getProjectAllocatedToEmp() {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage:'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectlist = result.data.data;
          } else {
            this.projectlist = [];
          }
        });
      },
    getEscalationlist(search) {
        this.isLoading = true
        let url = 'api/escalation/getescalationlist'
        let payload = new Object({
            limit: apiUrl.LIMIT,
            useremail  : this.undt.username,
            empcode: this.undt.userid,
            offset: this.pageoffset,

        })
        if(search){
            payload.project = this.project
            payload.frequency = this.frequency
            payload.isactive = this.searchisactive
        }
        axios({
          method: 'POST',
          url,
          data: payload,
          headers:{ authorization :this.tokendata }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.escalationlist = result.data.data.rows
            this.totalcount = result.data.data.count
            this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
          } else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
            this.escalationlist=[]
            this.totalcount = 0
           this.pageCount = 0   
          }
        }).catch(e => {
                  this.displayError(e)
          }); 
      },
      activateEditRow:function(data,idx){
        this.isLoading = true
        data.edit = true
        this.isLoading = false
     },
     redirecttourl(btnstate,rowdata) {
      rowdata.backbtnstate=btnstate
      if(btnstate === "update_escalation") {
        this.$router.push({
          path: `/escalation_matrix/escalationupdate?id=${rowdata.id}`,
          params: {
            backbtnstate:btnstate,
            clickeddata:rowdata
          }
        })
      }
    },
     cancelUpdate(idx) {
       this.escalationlist = this.escalationlist.map((data, i) => {
         if(i === idx) {
           return { ...this.escalationlist[idx], edit: false }
         }
         return data
       })
       this.getEscalationlist(this.search);
     },
      clearSearchFields() {
        this.project = null;
        this.frequency = null;
        this.searchisactive =[1];
        this.getEscalationlist();
     },
   }
  }
  
  </script>