<template>
<div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
          <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="card p-1 commonBgcolorOngoing">
                    <div class="bg-light p-1 border-radius rounded">
                        <div class="card-header bg-light px-0 pt-0 pb-1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb px-0">
                                    <li class="breadcrumb-item sitebluecolor cursorpointercls" @click="redirectto({name:'RaiseRequisition',path:'hrms/raiserequisition',datas: candidatedetails})">{{candidatedetails.reqcode ? candidatedetails.reqcode :''}}</li>
                                    <li class="breadcrumb-item active" aria-current="page">Candidate Details</li>
                                </ol>
                            </nav>
                            <div class="d-flex align-items-center">
                                <p class="card-text font-small-2 mr-25 mb-0">Applied on: {{ candidatedetails.applicationdate?dateMonthFormat(candidatedetails.applicationdate):'NA'}}</p>
                            </div>
                        </div>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-2">
                                    <profilepic v-if="candidatedetails.imagename && candidatedetails.candidateid" v-bind:get-url="'empprofile/'+candidatedetails.candidateid+'/'+candidatedetails.imagename"></profilepic>
                                    <img alt="" v-if="!candidatedetails.imagename" :src="defaultimagepath" width="40" height="40" />
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0 capitalisetext"> {{ candidatedetails.candidatename }} <span class="text-warning font-small-4 px-1 capitalisetext"> 
                                        <!-- {{ candidatedetails.status }}  -->
                                    </span></h4>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="action-right text-right">
                                <ul class="list-inline m-0">
                                    <li class="list-inline-item mail-delete">
                                        <span class="action-icon">
                                        <em data-feather='clock' class="font-medium-2"></em> </span>
                                    </li>
                                    <li class="list-inline-item mr-0 ongovingdownload">
                                        <div class="dropdown d-flex">
                                            <a href="javascript:void(0);" class="dropdown-toggle btn bg-transparent btn-flat-secondary" id="folder" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                
                                                <em data-feather='download-cloud' class="font-medium-2"></em>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="folder">
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                                                    <em data-feather="edit-2" class="font-small-4 mr-50"></em>
                                                    <span>Draft</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                                                    <em data-feather="info" class="font-small-4 mr-50"></em>
                                                    <span>Spam</span>
                                                </a>
                                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);">
                                                    <em data-feather="trash" class="font-small-4 mr-50"></em>
                                                    <span>Trash</span>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="list-inline-item mail-unread">
                                    <span class="action-icon">
                                        <em data-feather='file-plus' class="font-medium-2"></em>
                                        </span>
                                    </li>
                                    <li class="list-inline-item mail-delete">
                                    <span class="action-icon">
                                        <em data-feather='file-text' class="font-medium-2"></em>
                                    </span>
                                    </li>
                                    <li class="list-inline-item mail-delete">
                                    <span class="action-icon">
                                        <em data-feather='user' class="font-medium-2"></em>
                                    </span>
                                    </li>
                                    <li class="list-inline-item mail-delete">
                                    <span class="action-icon">
                                        <em data-feather='layers' class="font-medium-2"></em>
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                    <div class="row py-1">
                        <div class="col-md-3">
                            <div class="design-planning job_details_right_border">
                                <p class="card-text mb-25">Email ID</p>
                                <h6 class="mb-0"> {{ candidatedetails.candidatemail?candidatedetails.candidatemail:'NA' }} </h6>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="design-planning job_details_right_border">
                                <p class="card-text mb-25">Contact Number</p>
                                <h6 class="mb-0"> {{ candidatedetails.candidatecontactno?candidatedetails.candidatecontactno:'NA' }} </h6>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="design-planning job_details_right_border">
                                <p class="card-text mb-25">Total Experience</p>
                                <h6 class="mb-0"> {{ candidatedetails.candidatetotalexp?candidatedetails.candidatetotalexp:'NA' }} </h6>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="design-planning">
                                <p class="card-text mb-25">Source <em data-feather='edit-2' data-toggle="modal" data-target="#add_candidates_source" style="cursor:pointer"></em></p>
                                <h6 class="mb-0"> {{ candidatedetails.jobsourcename?candidatedetails.jobsourcename:'NA' }} </h6>
                            </div>
                        </div>
                        </div>
                        <div class="container px-0 capitalisetext">
                        <p v-html="candidatedetails.paragraphdata"></p>
                        </div>
                    </div>
                    <div class="mt-2" id="basic-table">
                        <div class="card_views_hrms
                        ">
                            <div class="table-responsive" style="min-height: 345px;">
                                <table class="table table-borderless border-0 requistion_scheduling_th table-hover table-striped" aria-describedby="mydesc">
                                    <thead>
                                        <tr>
                                        <th scope="col" class="text-center"> Interview Stage</th>
                                        <th scope="col" class="text-center"> Acted By </th>
                                        <th scope="col" class="text-center"> Stage Status </th>
                                        <th scope="col" class="text-center"> Completed Date </th>
                                        <th scope="col" class="text-center"> Ratings </th>
                                        <th scope="col" class="text-center"> Remarks </th>
                                        <th scope="col" class="text-center"> Actions </th>
                                        <th scope="col" class="text-center"> Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="(candidatedetails.interviewstage.calling)">
                                            <td class="text-center"> Calling </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.calling.statuschangeby ?candidatedetails.interviewstage.calling.statuschangeby:'NA' }} </td>
                                            <td class="text-center px-1">
                                                <div class="btn-group px-0">
                                                    <button class="btn btn-flat-warning dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="(candidatedetails.interviewstage.calling.stagestatus ==null || candidatedetails.interviewstage.calling.stagestatus =='')">
                                                        Pending for calling
                                                    </button>
                                                    <button class="btn btn-flat-success waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.calling.stagestatus && candidatedetails.interviewstage.calling.stagestatus =='Completed'">
                                                        {{ candidatedetails.interviewstage.calling.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-danger waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.calling.stagestatus && candidatedetails.interviewstage.calling.stagestatus =='Rejected in the Process'">
                                                        {{ candidatedetails.interviewstage.calling.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else>
                                                        {{ candidatedetails.interviewstage.calling.stagestatus }}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.calling.stagestatus !='Completed' && candidatedetails.interviewstage.calling.stagestatus !='Rejected in the Process'">
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Calling','On Hold')"> On Hold</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Calling','Rejected in the Process')"> Rejected in the Process</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Calling','Completed')"> Completed</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.calling.statuschangedate?dateMonthFormat(candidatedetails.interviewstage.calling.statuschangedate):'NA' }} </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> -</td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.calling.stagestatus ? candidatedetails.interviewstage.calling.stagestatus :'-' }} </td>
                                        </tr>
                                        <tr v-if="(candidatedetails.interviewstage.shortlisting && candidatedetails.interviewstage.shortlisting.value == 1)">
                                            <td class="text-center"> Shortlisting </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.shortlisting.statuschangeby ?candidatedetails.interviewstage.shortlisting.statuschangeby:'NA' }} </td>
                                            <td class="text-center px-1">
                                                <div class="btn-group px-0" v-if="candidatedetails.interviewstage.calling.stagestatus && candidatedetails.interviewstage.calling.stagestatus =='Completed'">
                                                    <button class="btn btn-flat-warning dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="(candidatedetails.interviewstage.shortlisting.stagestatus ==null || candidatedetails.interviewstage.shortlisting.stagestatus =='')">
                                                        Pending for shortlisting
                                                    </button>
                                                    <button class="btn btn-flat-success waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.shortlisting.stagestatus && candidatedetails.interviewstage.shortlisting.stagestatus =='Completed'">
                                                        {{ candidatedetails.interviewstage.shortlisting.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-danger waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.shortlisting.stagestatus && candidatedetails.interviewstage.shortlisting.stagestatus =='Rejected in the Process'">
                                                        {{ candidatedetails.interviewstage.shortlisting.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else>
                                                        {{ candidatedetails.interviewstage.shortlisting.stagestatus }}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.shortlisting.stagestatus !='Completed' && candidatedetails.interviewstage.shortlisting.stagestatus !='Rejected in the Process'">
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Shortlisting','On Hold')"> On Hold</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Shortlisting','Rejected in the Process')"> Rejected in the Process</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Shortlisting','Completed')"> Completed</a>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    NA
                                                </div>
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.shortlisting.statuschangedate?dateMonthFormat(candidatedetails.interviewstage.shortlisting.statuschangedate):'NA' }} </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> -</td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.shortlisting.stagestatus ? candidatedetails.interviewstage.shortlisting.stagestatus :'-' }} </td>
                                        </tr>
                                        <tr v-if="(candidatedetails.interviewstage.screening && candidatedetails.interviewstage.screening.value == 1)">
                                            <td class="text-center"> Screening  </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.screening.statuschangeby?candidatedetails.interviewstage.screening.statuschangeby:'NA' }} </td>
                                            <td class="text-center px-1">
                                                <div class="btn-group px-0" v-if="candidatedetails.interviewstage.shortlisting.stagestatus && candidatedetails.interviewstage.shortlisting.stagestatus =='Completed'">
                                                    <button class="btn btn-flat-warning dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="(candidatedetails.interviewstage.screening.stagestatus ==null || candidatedetails.interviewstage.screening.stagestatus =='')">
                                                        Pending for screening
                                                    </button>
                                                    <button class="btn btn-flat-success waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.screening.stagestatus && candidatedetails.interviewstage.screening.stagestatus =='Completed'">
                                                        {{ candidatedetails.interviewstage.screening.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-danger waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.screening.stagestatus && candidatedetails.interviewstage.screening.stagestatus =='Rejected in the Process'">
                                                        {{ candidatedetails.interviewstage.screening.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else>
                                                        {{ candidatedetails.interviewstage.screening.stagestatus }}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.screening.stagestatus !='Completed' && candidatedetails.interviewstage.screening.stagestatus !='Rejected in the Process'">
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Screening','On Hold')"> On Hold</a>
                                                        <!-- <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Screening','Rejected in the Process')"> Rejected in the Process</a> -->
                                                        <!-- <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Screening','Completed')"> Completed</a> -->
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    NA
                                                </div>
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.screening.statuschangedate?dateMonthFormat(candidatedetails.interviewstage.screening.statuschangedate):'NA' }}</td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center">
                                                <span v-if="candidatedetails.interviewstage.shortlisting.stagestatus && candidatedetails.interviewstage.shortlisting.stagestatus =='Completed'">
                                                    <span v-if="candidatedetails.interviewstage.screening.stagestatus && (candidatedetails.interviewstage.screening.stagestatus =='Completed' || candidatedetails.interviewstage.screening.stagestatus =='Rejected in the Process')">
                                                        <button class="btn btn-flat-success" type="button">
                                                            Pre Screening
                                                        </button>
                                                    </span>
                                                    <span v-else>
                                                        <button class="btn btn-flat-warning" type="button" data-toggle="modal" data-target="#pri_screening">
                                                            Pre Screening
                                                        </button>
                                                    </span>
                                                </span>
                                                <span v-else> NA</span>
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.screening.stagestatus ? candidatedetails.interviewstage.screening.stagestatus :'-' }} </td>
                                        </tr>

                                        <tr v-if="(candidatedetails.interviewstage.reviewresume)">
                                            <td class="text-center"> Resume Review </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.reviewresume.statuschangeby?candidatedetails.interviewstage.reviewresume.statuschangeby:'NA' }} </td>
                                            <td class="text-center px-1">  
                                                <div class="btn-group px-0" v-if="candidatedetails.interviewstage.screening.stagestatus && candidatedetails.interviewstage.screening.stagestatus =='Completed'">
                                                    <button class="btn btn-flat-warning dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="(candidatedetails.interviewstage.reviewresume.stagestatus ==null || candidatedetails.interviewstage.reviewresume.stagestatus =='')">
                                                        Pending For Assign
                                                    </button>
                                                    <button class="btn btn-flat-success waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.reviewresume.stagestatus && candidatedetails.interviewstage.reviewresume.stagestatus =='Completed'">
                                                        {{ candidatedetails.interviewstage.reviewresume.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-danger waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.reviewresume.stagestatus && candidatedetails.interviewstage.reviewresume.stagestatus =='Rejected in the Process'">
                                                        {{ candidatedetails.interviewstage.reviewresume.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else>
                                                        {{ candidatedetails.interviewstage.reviewresume.stagestatus }}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.reviewresume.stagestatus !='Completed' || candidatedetails.interviewstage.reviewresume.stagestatus !='Rejected in the Process'">
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Resume Review','Assign To Reviewer')"> Assign To Reviewer</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Resume Review','On Hold')"> On Hold</a>
                                                    </div>
                                                </div>
                                                <div v-else> NA </div>
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.reviewresume.statuschangedate?dateMonthFormat(candidatedetails.interviewstage.reviewresume.statuschangedate):'NA' }}</td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center" v-if="(candidatedetails.interviewstage.reviewresume.ticketid && candidatedetails.interviewstage.reviewresume.ticketid !=null)"> <span class="sitebluecolor cursorpointercls d-block w-100" @click="redirectpage('ticketview',candidatedetails.interviewstage.reviewresume.ticketid)"> {{ candidatedetails.interviewstage.reviewresume.ticketcode }}</span>
                                            </td>
                                            <td class="text-center" v-else>
                                                NA
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.reviewresume.stagestatus ? candidatedetails.interviewstage.reviewresume.stagestatus :'-' }} </td>
                                        </tr>

                                        <tr v-for="(noofround,nindx) in candidatedetails.interviewstage.noofround" v-bind:key="nindx">
                                            <td class="text-center"> Interview round {{ nindx+1 }}</td>
                                            <td class="text-center">
                                                <span v-if="candidatedetails.interviewstage.noofround ==1">
                                                    {{ candidatedetails.interviewstage.offerstage && candidatedetails.interviewstage.offerstage.statuschangeby ? candidatedetails.interviewstage.offerstage.statuschangeby :'NA' }}
                                                </span>
                                                <span v-else>
                                                    {{ candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].statuschangeby ? candidatedetails.interviewstage.noofround[nindx].statuschangeby:'NA' }} 
                                                </span>
                                            </td>
                                            <td class="text-center px-1">
                                                <div class="btn-group px-0" v-if="candidatedetails.interviewstage.reviewresume.stagestatus && candidatedetails.interviewstage.reviewresume.stagestatus =='Completed'">
                                                    <span v-if="candidatedetails.interviewstage.noofround.length >1 && candidatedetails.interviewstage.noofround[nindx-1] && candidatedetails.interviewstage.noofround[nindx-1].stagestatus !='Completed'">
                                                        NA
                                                    </span>
                                                    <span v-else>
                                                        <button class="btn btn-flat-warning dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="candidatedetails.interviewstage.noofround[nindx] && ( candidatedetails.interviewstage.noofround[nindx].stagestatus ==null || candidatedetails.interviewstage.noofround[nindx].stagestatus =='')">
                                                            Pending for scheduling
                                                        </button>
                                                        <button class="btn btn-flat-success waves-effect" type="button"  v-else-if="candidatedetails.interviewstage.noofround[nindx].stagestatus =='Completed' && ((candidatedetails.interviewstage.noofround[nindx+1] && candidatedetails.interviewstage.noofround[nindx+1].stagestatus) || (candidatedetails.interviewstage.reviewresume.stagestatus))">
                                                            {{ candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].stagestatus ? candidatedetails.interviewstage.noofround[nindx].stagestatus :'NA' }}
                                                        </button>
                                                        <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.noofround[nindx].stagestatus =='Completed'">
                                                            {{ candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].stagestatus ? candidatedetails.interviewstage.noofround[nindx].stagestatus :'NA' }}
                                                        </button>
                                                        <button class="btn btn-flat-danger waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.noofround[nindx].stagestatus =='Rejected in the Process'">
                                                            {{ candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].stagestatus ? candidatedetails.interviewstage.noofround[nindx].stagestatus :'NA' }}
                                                        </button>
                                                        <button class="btn btn-flat-success waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.noofround[nindx].stagestatus =='Not Required'">
                                                            {{ candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].stagestatus ? candidatedetails.interviewstage.noofround[nindx].stagestatus :'NA' }}
                                                        </button>
                                                        <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else>
                                                            {{ candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].stagestatus ? candidatedetails.interviewstage.noofround[nindx].stagestatus :'NA' }}
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.noofround[nindx].stagestatus !='Completed' && candidatedetails.interviewstage.noofround[nindx].stagestatus !='Rejected in the Process' && candidatedetails.interviewstage.noofround[nindx].stagestatus !='Not Required'">
                                                            <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Interview round'+' '+(nindx ? nindx+1 :1),'Schedule')" v-if="candidatedetails.interviewstage.noofround[nindx].stagestatus ==null || candidatedetails.interviewstage.noofround[nindx].stagestatus ==''"> Schedule</a>
                                                            <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Interview round'+' '+(nindx ? nindx+1 :1),'Completed')" v-if="candidatedetails.interviewstage.noofround[nindx].stagestatus =='Schedule' || candidatedetails.interviewstage.noofround[nindx].stagestatus =='On Hold'"> Completed</a>
                                                            <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Interview round'+' '+(nindx ? nindx+1 :1),'On Hold')" v-if="candidatedetails.interviewstage.noofround[nindx].stagestatus ==null || candidatedetails.interviewstage.noofround[nindx].stagestatus =='' || candidatedetails.interviewstage.noofround[nindx].stagestatus =='Schedule'"> On Hold</a>
                                                            <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Interview round'+' '+(nindx ? nindx+1 :1),'Rejected in the Process')" v-if="candidatedetails.interviewstage.noofround[nindx].stagestatus =='Schedule' || candidatedetails.interviewstage.noofround[nindx].stagestatus =='On Hold'"> Rejected in the Process</a>
                                                            <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Interview round'+' '+(nindx ? nindx+1 :1),'Not Required')"> Not Required</a>
                                                        </div>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.noofround[nindx].stagestatus =='Completed' && !candidatedetails.interviewstage.offerstage.stagestatus">
                                                            <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Interview round'+' '+(nindx ? nindx+1 :1),'Rejected in the Process')"> Rejected in the Process</a>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div v-else> NA </div>
                                            </td>
                                            <td class="text-center"> 
                                                <span v-if="candidatedetails.interviewstage.noofround ==1">
                                                    {{ candidatedetails.interviewstage.offerstage && candidatedetails.interviewstage.offerstage.statuschangedate ? dateMonthFormat(candidatedetails.interviewstage.offerstage.statuschangedate) :'NA' }}
                                                </span>
                                                <span v-else>
                                                    {{ candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].statuschangedate ? dateMonthFormat(candidatedetails.interviewstage.noofround[nindx].statuschangedate):'NA' }} 
                                                </span>
                                            </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center" v-if="(candidatedetails.interviewstage.noofround[nindx].interviewschedule && candidatedetails.interviewstage.noofround[nindx].interviewschedule ==1)"> <button type="text" class="btn  waves-effect p-0"><a class="btn btn-flat-success mb-0" href="javascript:void(0);">  Scheduled</a></button>
                                                <span class="sitebluecolor cursorpointercls d-block w-100" @click="redirectpage('ticketview',candidatedetails.interviewstage.noofround[nindx].ticketid)"> {{ candidatedetails.interviewstage.noofround[nindx].ticketcode }}</span>
                                            </td>
                                            <td class="text-center" v-else>
                                                <span v-if="candidatedetails.interviewstage.noofround[nindx].stagestatus && candidatedetails.interviewstage.noofround[nindx].stagestatus =='Schedule'">
                                                    <button type="text" class="btn  waves-effect p-0"><a class="btn badge badge-pill badge-light-warning" href="javascript:void(0);" style="margin-bottom : 0.5rem;" @click="redirectpage('viewpage', candidatedetails,nindx+1)">  Schedule </a></button>
                                                </span>
                                                <span v-else>
                                                    NA
                                                </span>
                                            </td>

                                            <td class="text-center"> 
                                                <span v-if="candidatedetails.interviewstage.noofround ==1">
                                                    {{ candidatedetails.interviewstage.offerstage && candidatedetails.interviewstage.offerstage.stagestatus ? candidatedetails.interviewstage.offerstage.stagestatus :'-' }}
                                                </span>
                                                <span v-else>
                                                    {{candidatedetails.interviewstage.noofround[nindx] && candidatedetails.interviewstage.noofround[nindx].stagestatus ? candidatedetails.interviewstage.noofround[nindx].stagestatus :'-'}} 
                                                </span>
                                            </td>
                                        </tr>
                                        <tr v-if="(candidatedetails.interviewstage.resumereview)">
                                            <td class="text-center"> Final Approval </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.resumereview.statuschangeby?candidatedetails.interviewstage.resumereview.statuschangeby:'NA' }} </td>
                                            <td class="text-center px-1">  
                                                <div class="btn-group px-0" v-if="candidatedetails.interviewstage.noofround && candidatedetails.interviewstage.noofround[candidatedetails.interviewstage.noofround.length-1].stagestatus && (candidatedetails.interviewstage.noofround[candidatedetails.interviewstage.noofround.length-1].stagestatus =='Completed' || candidatedetails.interviewstage.noofround[candidatedetails.interviewstage.noofround.length-1].stagestatus =='Not Required')">
                                                    <button class="btn btn-flat-warning dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="(candidatedetails.interviewstage.resumereview.stagestatus ==null || candidatedetails.interviewstage.resumereview.stagestatus =='')">
                                                        Pending For Assign
                                                    </button>
                                                    <button class="btn btn-flat-success waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.resumereview.stagestatus && candidatedetails.interviewstage.resumereview.stagestatus =='Completed'">
                                                        {{ candidatedetails.interviewstage.resumereview.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-danger waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.resumereview.stagestatus && candidatedetails.interviewstage.resumereview.stagestatus =='Rejected in the Process'">
                                                        {{ candidatedetails.interviewstage.resumereview.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else>
                                                        {{ candidatedetails.interviewstage.resumereview.stagestatus }}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.resumereview.stagestatus !='Completed' || candidatedetails.interviewstage.resumereview.stagestatus !='Rejected in the Process'">
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Final Approval','Assign To Reviewer')"> Assign To Reviewer</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Final Approval','On Hold')"> On Hold</a>
                                                    </div>
                                                </div>
                                                <div v-else> NA </div>
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.resumereview.statuschangedate?dateMonthFormat(candidatedetails.interviewstage.resumereview.statuschangedate):'NA' }}</td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center" v-if="(candidatedetails.interviewstage.resumereview.ticketid && candidatedetails.interviewstage.resumereview.ticketid !=null)"> <span class="sitebluecolor cursorpointercls d-block w-100" @click="redirectpage('ticketview',candidatedetails.interviewstage.resumereview.ticketid)"> {{ candidatedetails.interviewstage.resumereview.ticketcode }}</span>
                                            </td>
                                            <td class="text-center" v-else>
                                                NA
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.resumereview.stagestatus ? candidatedetails.interviewstage.resumereview.stagestatus :'-' }} </td>
                                        </tr>
                                        <tr v-if="(candidatedetails.interviewstage.offerstage && candidatedetails.interviewstage.offerstage.value == 1)">
                                            <td class="text-center"> Offer Stage </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.offerstage.statuschangeby?candidatedetails.interviewstage.offerstage.statuschangeby:'NA' }} </td>
                                            <td class="text-center px-1">  
                                                <div class="btn-group px-0" v-if="candidatedetails.interviewstage.resumereview && candidatedetails.interviewstage.resumereview.stagestatus && candidatedetails.interviewstage.resumereview.stagestatus =='Completed'">
                                                    <button class="btn btn-flat-warning dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-if="(candidatedetails.interviewstage.offerstage.stagestatus ==null || candidatedetails.interviewstage.offerstage.stagestatus =='')">
                                                        Pending For Offer
                                                    </button>
                                                    <button class="btn btn-flat-success waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.offerstage.stagestatus && candidatedetails.interviewstage.offerstage.stagestatus =='Make Offer'">
                                                        {{ candidatedetails.interviewstage.offerstage.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-danger waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else-if="candidatedetails.interviewstage.offerstage.stagestatus && candidatedetails.interviewstage.offerstage.stagestatus =='Rejected in the Process'">
                                                        {{ candidatedetails.interviewstage.offerstage.stagestatus }}
                                                    </button>
                                                    <button class="btn btn-flat-success dropdown-toggle waves-effect" type="button" id="dropdownMenuButton200" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-else>
                                                        {{ candidatedetails.interviewstage.offerstage.stagestatus }}
                                                    </button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton200" v-if="candidatedetails.interviewstage.offerstage.stagestatus !='Completed'">
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Offer Stage','On Hold')"> On Hold</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Offer Stage','Rejected in the Process')"> Rejected in the Process</a>
                                                        <a class="dropdown-item" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#onhold" @click="addmodule(candidatedetails,'Offer Stage','Make Offer')"> Make Offer</a>
                                                    </div>
                                                </div>
                                                <div v-else> NA </div>
                                            </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.offerstage.statuschangedate?dateMonthFormat(candidatedetails.interviewstage.offerstage.statuschangedate):'NA' }}</td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> - </td>
                                            <td class="text-center"> {{ candidatedetails.interviewstage.offerstage.stagestatus ? candidatedetails.interviewstage.offerstage.stagestatus :'-' }} </td>
                                        </tr>
                                        <template v-if="candidatedetails.interviewstage.length==0">
                                            <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal modal-slide-in new-user-modal fade" id="pri_screening">
                        <div class="modal-dialog">
                            <form class="add-new-user modal-content pt-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                                <div class="modal-header mb-1">
                                    <h5 class="modal-title" id="exampleModalLabel">Shortlisting/screening Comments</h5>
                                </div>
                                <div class="modal-body flex-grow-1">
                                    <div class="form-group col-md-12 px-0">
                                        <div class="form-group">
                                            <label for="basicSelect"> Add Tags</label>
                                            <input class="form-control" type="text" v-model="addtags" placeholder="Enter Tags">
                                             <div class="errorinputmsg" v-if="(this.errors.addtags)">{{ this.errors.addtags }}</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12 px-0">
                                        <label for="vertical-modern-first-name" class="form-label"> Comments: </label>
                                        <textarea name="" rows="1" required="required" class="form-control" v-model="rejectioncomments" placeholder="Enter Comment"></textarea>
                                        <div class="errorinputmsg" v-if="(this.errors.rejectioncomments)">{{ this.errors.rejectioncomments }}</div>
                                    </div>
                                     
                                    <div  class="form-group col-sm-12 px-0">
                                        <label for="">Activity</label>
                                          <treeselect class="capitalisetext" v-model="rejectionactivitys" placeholder="Select Activity" :options="rejectionactivity" />
                                          <div class="errorinputmsg" v-if="(this.errors.rejectionactivitys)">{{ this.errors.rejectionactivity }}</div>
                                    </div>
                                    
                                    <button type="reset" class="btn btn-relief-danger mr-1" data-dismiss="modal" v-on:click.stop.prevent="saveRejectionData('1',candidatedetails)">Reject</button>
                                    <button type="submit" class="btn btn-relief-primary  data-submit" v-on:click.stop.prevent="saveRejectionData('2',candidatedetails)">Screen</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="modal modal-slide-in new-user-modal fade" id="onhold">
                        <div class="modal-dialog">
                            <form class="add-new-user modal-content pt-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                                <div class="modal-header mb-1">
                                    <h5 class="modal-title" id="exampleModalLabel">Change Status</h5>
                                </div>
                                <div class="modal-body flex-grow-1">
                                    <div class="row">
                                        <div class="col-md-4"> <p>Interview Stage:</p> </div>
                                        <div class="col-md-4"> <p> Change Status To:</p> </div>
                                        <div class="col-md-4"> <p> Candidate Name: </p></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4"> <h6 class="card-text"> {{form.stage}} </h6> </div>
                                        <div class="col-md-4"> <h6 class="card-text"> {{form.status}} </h6> </div>
                                        <div class="col-md-4"> <h6 class="card-text"> {{form.candidatename}} </h6></div>
                                    </div>
                                    <div class="form-group col-md-7 px-0 mt-2 ml-0" v-if="form.stage == 'Offer Stage'">
                                        <label>Requisition Code:</label>
                                        <treeselect placeholder="Select Requisition" class="projectlistdd capitalisetext" v-model="form.reqid" :options="form.reqidlist" v-if="form.reqid" disabled/>
                                        <treeselect placeholder="Select Requisition" class="projectlistdd capitalisetext" v-model="form.reqid" :options="form.reqidlist" v-else/>
                                    </div>
                                    <div class="form-group col-md-12 px-0 mt-1">
                                        <label class="form-label" for="vertical-modern-first-name"> Comments: </label>
                                        <textarea class="form-control"  name="" rows="3" required="" v-model="form.comment"></textarea>
                                    </div>
                                    <button type="submit" class="btn btn-relief-primary mr-1 data-submit" @click="savestatuschange(form)">Submit</button>
                                    <button type="reset" class="btn btn-relief-secondary" data-dismiss="modal" @click="resetstatuschange">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                <div class="scrolling-inside-modal">
                        <div class="modal fade" id="add_candidates_source" tabindex="-1" role="dialog" aria-labelledby="add_candidates_source_scroll" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-scrollable modal-sm" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="add_candidates_source_scroll"> Update Source</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <form action="#"
                                        class="dropzones dropzone-areas"
                                        id="dpz-single-filess"
                                        >
                                        <div class="modal-body">
                                            <div
                                            class="row raise_requistionbg_upload card_views_hrms"
                                            id="raise_requistionbg_source">
                                                <div class="form-group col-md-6">
                                                    <div class="form-group">
                                                        <label for="basicSelect">Candidate Name
                                                        </label>
                                                        <input class="form-control" type="text" v-model="candidatedetails.candidatename" disabled>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <div class="form-group">
                                                        <label for="basicSelect">Source</label>
                                                        <treeselect placeholder="Select Source" class="projectmdllistdd capitalisetext" :options="candidatedetails.sourcelist" v-model="candidatedetails.source" />
                                                    </div>
                                                </div>
                                                
                                                <div class="invalid-feedback">
                                                project desc must be greater than 5
                                                char
                                                </div> 
                                            </div>
                                            <div class="modal-footer px-0">
                                                <button type="button" class="btn btn-relief-secondary" data-dismiss="modal" @click="cancelsourceform()">Cancel</button>
                                                <button type="button" class="btn btn-relief-success" data-dismiss="modal" id="submitbtnt70"  v-on:click.stop.prevent="updatesource($event)" > Submit </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
   </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from 'vue-loading-overlay';
import constants from '../../constants';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'
import apiUrl from "../../constants";
import moment from 'moment';
import profilepic from '../profilepic.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';
   
   export default {
     name: "Processongoing",
     components: {
        DatePicker,
        Loading,
        profilepic,
        Treeselect
        
     },
     mixins: [ commonMethods ],
     data() {
     return {
        defaultValue: new Date(),
        isLoading: false,
        fullPage: true,
        rejectionactivitys: null,
        defaultimagepath:apiUrl.defaultimagepath,
        addtags: null,
        rejectioncomments: null,
        rejectionactivity:constants.rejectionactivity,
        candidatedetails:{
            editRowData: [],
            candidateid: null,
            candidatename: null,
            status: null,
            applicationdate: null,
            candidatemail: null,
            candidatecontactno: null,
            candidatetotalexp: null,
            source: null,
            jobsourcename: null,
            imagename: null,
            interviewstage: [],
            stagewithstatus: [],
            reqid: null,
            paragraphdata: null,
            jdcode: null,
            jdid: null,
            reqcode: null
        },
        errors:
        {
            addtags: null,
            rejectioncomments: null,
            rejectionactivitys: null,
        },
        form:{
            candidateid: null,
            candidatename: null,
            stage: null,
            status: null,
            reqid: null,
            reqidlist: [],
            comment: null
        }
     }
   },
   mounted() {
        if (feather) {
            feather.replace({
                width: 14,
                height: 14
            });
        }

        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.getcandidatedetailsbyid(this.$route.query.candidateid);
        }

    },
    methods:{
        getcandidatedetailsbyid(canid){
         this.isLoading = true;
            let url = "api/candidate/getcandidatedetailsbyid";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                candidateid: parseInt(canid)
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.getJobsource();
                    this.candidatedetails.editRowData = result.data.data[0];
                    this.openEditModule(this.candidatedetails.editRowData, 0)
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.candidatedetails.editRowData = [];
                    this.$router.push({ name: 'RaiseRequisition', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to update this candidate",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        openEditModule(currRowData){
            this.candidatedetails.candidateid = currRowData.hcid;
            this.candidatedetails.candidatename = currRowData.candidatename.replace(/\s+/g, ' ');
            this.candidatedetails.status = currRowData.status;
            this.candidatedetails.applicationdate = currRowData.applicationdate;
            this.candidatedetails.candidatemail = currRowData.email;
            this.candidatedetails.candidatecontactno = currRowData.contactno;
            this.candidatedetails.candidatetotalexp = currRowData.totalexp;
            this.candidatedetails.source = currRowData.source;
            this.candidatedetails.jobsourcename = currRowData.jobsourcename;
            this.candidatedetails.imagename = currRowData.imagename;
            this.candidatedetails.reqid = currRowData.reqid;
            this.candidatedetails.jdcode = currRowData.jdcode;
            this.candidatedetails.jdid = currRowData.jdid;
            this.candidatedetails.reqcode = currRowData.reqcode;
            this.candidatedetails.paragraphdata = 
            currRowData.companyname+', '+currRowData.departmentname+', '+currRowData.designiation+', '+currRowData.employeetypename+', Grade '+currRowData.grade
            +', '+currRowData.displayname+', '+'('+ this.showmappingname(currRowData.joblocation)+ ')'+', '+currRowData.internaljobcode+', '+(currRowData.remotejob==1?'Remote Job':'Not Remote Job')
            +', From '+currRowData.expyear+', To '+currRowData.expmonth+'.'

            if (currRowData.reqid) {
                this.getcandidateinterviewstage(this.$route.query.candidateid,currRowData.reqid);
                // this.getcandidateinterviewstagestatus(this.$route.query.candidateid,currRowData.reqid);
            }
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'.'+prlist[i]+' '
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        getcandidateinterviewstage(canid,reqid){
         this.isLoading = true;
            let url = "api/candidate/getcandidateinterviewstage";
            this.input = {
                useremail: this.undt.username,
                empcode: this.undt.userid,
                candidateid: parseInt(canid),
                reqid: parseInt(reqid)
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.candidatedetails.interviewstage = result.data.data[0];

                    //calling stage
                    let tempobj0={};
                    tempobj0['stagename']='Calling';
                    tempobj0['value']=this.candidatedetails.interviewstage.calling;
                    tempobj0['stagestatus']=null;
                    tempobj0['statuschangeby']=null;
                    tempobj0['statuschangedate']=null;
                    this.candidatedetails.interviewstage.calling = tempobj0;
                    // calling stage

                    let tempobj6={};
                    tempobj6['stagename']='Final Approval';
                    tempobj6['value']=this.candidatedetails.interviewstage.resumereview;
                    tempobj6['stagestatus']=null;
                    tempobj6['statuschangeby']=null;
                    tempobj6['statuschangedate']=null;
                    tempobj6['ticketcode']=null;
                    tempobj6['ticketid']=null;
                    this.candidatedetails.interviewstage.resumereview = tempobj6;

                    let tempobj7={};
                    tempobj7['stagename']='Resume Review';
                    tempobj7['value']=this.candidatedetails.interviewstage.reviewresume;
                    tempobj7['stagestatus']=null;
                    tempobj7['statuschangeby']=null;
                    tempobj7['statuschangedate']=null;
                    tempobj7['ticketcode']=null;
                    tempobj7['ticketid']=null;
                    this.candidatedetails.interviewstage.reviewresume = tempobj7;

                    if (this.candidatedetails.interviewstage.shortlisting) {
                        let tempobj1={};
                        tempobj1['stagename']='Shortlisting';
                        tempobj1['value']=this.candidatedetails.interviewstage.shortlisting;
                        tempobj1['stagestatus']=null;
                        tempobj1['statuschangeby']=null;
                        tempobj1['statuschangedate']=null;
                        this.candidatedetails.interviewstage.shortlisting = tempobj1
                    }
                    if (this.candidatedetails.interviewstage.screening) {
                        let tempobj2={};
                        tempobj2['stagename']='Screening';
                        tempobj2['value']=this.candidatedetails.interviewstage.screening;
                        tempobj2['stagestatus']=null;
                        tempobj2['statuschangeby']=null;
                        tempobj2['statuschangedate']=null;
                        this.candidatedetails.interviewstage.screening = tempobj2
                    }
                    if (this.candidatedetails.interviewstage.offerstage) {
                        let tempobj3={};
                        tempobj3['stagename']='Offer Stage';
                        tempobj3['value']=this.candidatedetails.interviewstage.offerstage;
                        tempobj3['stagestatus']=null;
                        tempobj3['statuschangeby']=null;
                        tempobj3['statuschangedate']=null;
                        this.candidatedetails.interviewstage.offerstage = tempobj3
                    }
                    if (this.candidatedetails.interviewstage.noofround) {
                        if (this.candidatedetails.interviewstage.noofround>1) {

                            let tempi = []
                            for (let i = 0; i < this.candidatedetails.interviewstage.noofround; i++) {

                                let tempobj={};
                                tempobj['stagename']='Interview round '+(i+1);
                                tempobj['value']=1;
                                tempobj['stagestatus']=null;
                                tempobj['statuschangeby']=null;
                                tempobj['statuschangedate']=null;
                                tempobj['interviewschedule']=null;
                                tempobj['ticketcode']=null;
                                tempobj['ticketid']=null;
                                tempi.push(tempobj)
                            }
                            this.candidatedetails.interviewstage.noofround = tempi
                        }else if(this.candidatedetails.interviewstage.noofround !=0 && this.candidatedetails.interviewstage.noofround==1){
                            let tempobj4={};
                            let temp4 = []
                            tempobj4['stagename']='Interview round 1';
                            tempobj4['value']=1;
                            tempobj4['stagestatus']=null;
                            tempobj4['statuschangeby']=null;
                            tempobj4['statuschangedate']=null;
                            tempobj4['interviewschedule']=null;
                            tempobj4['ticketcode']=null;
                            tempobj4['ticketid']=null;
                            temp4.push(tempobj4);
                            this.candidatedetails.interviewstage.noofround = temp4;
                        }
                    }else{
                        let tempobj5={};
                        tempobj5['stagename']='Interview round 1';
                        tempobj5['value']=1;
                        tempobj5['stagestatus']=null;
                        tempobj5['statuschangeby']=null;
                        tempobj5['statuschangedate']=null;
                        tempobj5['interviewschedule']=null;
                        tempobj5['ticketcode']=null;
                        tempobj5['ticketid']=null;
                        this.candidatedetails.interviewstage.offerstage = JSON.parse(JSON.stringify(tempobj5));
                    }
                    //assignment end
                    this.getcandidateinterviewstagestatus(canid,reqid);

                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.candidatedetails.interviewstage = [];
                    // this.$router.push({ name: 'RaiseRequisition', params: { search: true }})
                    // Swal.fire({
                    //     title: "Failed!",
                    //     text: "Your are not authorised user to update this candidate",
                    //     icon: "info",
                    //     customClass: {
                    //     confirmButton: "btn btn-primary",
                    //     },
                    //     buttonsStyling: !1,
                    // })
                }
            }).catch(e => {
                 this.displayError(e)
                });
        },
        addmodule(cdetails,stage,status){
            this.isAddState = true;
            this.form.candidateid = cdetails.candidateid;
            this.form.candidatename = cdetails.candidatename;
            this.form.stage = stage;
            this.form.status = status;
            this.form.comment = null;
            this.form.reqid = cdetails.interviewstage.reqid;
            if (this.form.stage == 'Offer Stage') {
                this.getallrequisitioncodes();
            }
        },
        getallrequisitioncodes() {
            let url = 'api/candidate/getallrequisitioncodes'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.form.reqidlist = result.data.data.reqrows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.form.reqidlist = []
                }
            }).catch(e => {
                 this.displayError(e)
                })
        },
        savestatuschange(datas){
            if (datas.comment != null) {          
                this.isLoading = true;
                this.input = {
                    useremail: this.undt.username,
                    empcode: parseInt(this.undt.userid),
                    candidateid: parseInt(datas.candidateid),
                    reqid: parseInt(datas.reqid),
                    stage: datas.stage,
                    stagestatus: datas.status,
                    stagecomment: datas.comment
                }
                let apiURL='api/candidate/savecandidatestagestatus'
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.getcandidateinterviewstage(this.$route.query.candidateid,this.candidatedetails.reqid);
                        // this.getcandidateinterviewstagestatus(parseInt(this.candidatedetails.candidateid),this.candidatedetails.reqid);
                        this.resetstatuschange();
                        $(".close").click();
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                 this.displayError(e)
                })
            }
        },
        resetstatuschange: function(){
            this.form.candidateid = null;
            this.form.candidatename = null;
            this.form.stage = null;
            this.form.status = null;
            this.form.comment = null;
            this.form.reqid = null;
        },
        getcandidateinterviewstagestatus(canid,reqid){
         this.isLoading = true;
            let url = "api/candidate/getcandidateinterviewstagestatus";
            this.input = {
                useremail: this.undt.username,
                empcode: parseInt(this.undt.userid),
                candidateid: parseInt(canid),
                reqid: parseInt(reqid)
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.candidatedetails.stagewithstatus = result.data.data;

                    for (let l = 0; l < this.candidatedetails.stagewithstatus.length; l++) {
                        if (this.candidatedetails.stagewithstatus[l].stage =='Calling') {
                            this.candidatedetails.interviewstage.calling.stagestatus = this.candidatedetails.stagewithstatus[l].status;
                            this.candidatedetails.interviewstage.calling.statuschangeby = this.candidatedetails.stagewithstatus[l].lastmodifiedby;
                            this.candidatedetails.interviewstage.calling.statuschangedate = this.candidatedetails.stagewithstatus[l].lastmodifieddate;
                        }

                        if (this.candidatedetails.stagewithstatus[l].stage =='Shortlisting') {
                            this.candidatedetails.interviewstage.shortlisting.stagestatus = this.candidatedetails.stagewithstatus[l].status;
                            this.candidatedetails.interviewstage.shortlisting.statuschangeby = this.candidatedetails.stagewithstatus[l].lastmodifiedby;
                            this.candidatedetails.interviewstage.shortlisting.statuschangedate = this.candidatedetails.stagewithstatus[l].lastmodifieddate;
                        }
                        if (this.candidatedetails.stagewithstatus[l].stage =='Screening') {
                            this.candidatedetails.interviewstage.screening.stagestatus = this.candidatedetails.stagewithstatus[l].status;
                            this.candidatedetails.interviewstage.screening.statuschangeby = this.candidatedetails.stagewithstatus[l].lastmodifiedby;
                            this.candidatedetails.interviewstage.screening.statuschangedate = this.candidatedetails.stagewithstatus[l].lastmodifieddate;
                        }
                        if (this.candidatedetails.stagewithstatus[l].stage =='Offer Stage') {
                            this.candidatedetails.interviewstage.offerstage.stagestatus = this.candidatedetails.stagewithstatus[l].status;
                            this.candidatedetails.interviewstage.offerstage.statuschangeby = this.candidatedetails.stagewithstatus[l].lastmodifiedby;
                            this.candidatedetails.interviewstage.offerstage.statuschangedate = this.candidatedetails.stagewithstatus[l].lastmodifieddate;
                        }
                        if (this.candidatedetails.interviewstage && this.candidatedetails.interviewstage.noofround.length >0) {
                            
                            let findIndex = this.candidatedetails.interviewstage.noofround.find(ele =>{
                                return ele.stagename == this.candidatedetails.stagewithstatus[l].stage
                            })
                            if (findIndex) {
                                findIndex.stagestatus = this.candidatedetails.stagewithstatus[l].status;
                                findIndex.statuschangeby = this.candidatedetails.stagewithstatus[l].lastmodifiedby;
                                findIndex.statuschangedate = this.candidatedetails.stagewithstatus[l].lastmodifieddate;
                                findIndex.interviewschedule = this.candidatedetails.stagewithstatus[l].interviewschedule;
                                findIndex.ticketcode = this.candidatedetails.stagewithstatus[l].ticketcode;
                                findIndex.ticketid = this.candidatedetails.stagewithstatus[l].ticketid;
                            }
                        }

                        if (this.candidatedetails.stagewithstatus[l].stage =='Final Approval') {
                            this.candidatedetails.interviewstage.resumereview.stagestatus = this.candidatedetails.stagewithstatus[l].status;
                            this.candidatedetails.interviewstage.resumereview.statuschangeby = this.candidatedetails.stagewithstatus[l].lastmodifiedby;
                            this.candidatedetails.interviewstage.resumereview.statuschangedate = this.candidatedetails.stagewithstatus[l].lastmodifieddate;
                            this.candidatedetails.interviewstage.resumereview.ticketcode = this.candidatedetails.stagewithstatus[l].ticketcode;
                            this.candidatedetails.interviewstage.resumereview.ticketid = this.candidatedetails.stagewithstatus[l].ticketid;
                        }
                        if (this.candidatedetails.stagewithstatus[l].stage =='Resume Review') {
                            this.candidatedetails.interviewstage.reviewresume.stagestatus = this.candidatedetails.stagewithstatus[l].status;
                            this.candidatedetails.interviewstage.reviewresume.statuschangeby = this.candidatedetails.stagewithstatus[l].lastmodifiedby;
                            this.candidatedetails.interviewstage.reviewresume.statuschangedate = this.candidatedetails.stagewithstatus[l].lastmodifieddate;
                            this.candidatedetails.interviewstage.reviewresume.ticketcode = this.candidatedetails.stagewithstatus[l].ticketcode;
                            this.candidatedetails.interviewstage.reviewresume.ticketid = this.candidatedetails.stagewithstatus[l].ticketid;
                        }
                    }
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.candidatedetails.stagewithstatus = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getJobsource() {
            let url = 'api/hrms/getjobjobsourcelist'
            axios({
                method: 'POST',
                url,
                data:{useremail: this.undt.username,empcode: this.undt.userid},
                headers: { authorization: this.tokendata }
            }).then(result => {
                if(result.data.errorCode == 0) {
                    this.candidatedetails.sourcelist = result.data.data.rows
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.candidatedetails.sourcelist = []
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        updatesource(objThis){
            if (this.candidatedetails.source && this.candidatedetails.source !=null) {
                this.valid =true;
                if (this.valid) {
                    this.isLoading = true;
                    this.input = {                      
                        useremail: this.undt.username,
                        empcode: parseInt(this.undt.userid),
                        hcid: parseInt(this.candidatedetails.candidateid),
                        reqid: parseInt(this.candidatedetails.reqid),
                        jobportalsource: parseInt(this.candidatedetails.source),
                        candidatestatus: this.candidatedetails.status,
                    }                     
                    let apiURL= 'api/candidate/updatesource'         
                    axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {
                        this.isLoading = false;
                        if(result.data.errorCode == 0){
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            $(".close").click();
                            this.getcandidatedetailsbyid(this.$route.query.candidateid);
                        } else if(result.data.errorCode == 3){
                            Swal.fire({
                                title: "Session Expired...!!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                            .then(function() {
                                window.location.href = "/#/login";
                            })
                        } else {
                            Swal.fire({
                                title: "",
                                text: result.data.msg,
                                icon: "info",
                                customClass: {
                                   confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: !1,
                            })
                        }
                    })
                    .catch(e => {
                        this.displayError(e)
                        this.isLoading = false;
                        Swal.fire({
                            title: "",
                            text: e,
                            icon: "info",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        })
                    })
                }
            }else{
                Swal.fire({
                    title: "Failed!",
                    text: "Source Is Required",
                    icon: "info",
                    customClass: {
                       confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
            }
        },
        redirectpage:function(btnstate,rowdata,roundno){
            if(btnstate=="viewpage"){
                rowdata.backbtnstate=btnstate;
                rowdata.roundno =roundno;
                this.$router.push({
                    name: 'candidatescheduler',
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                });
            }
            if(btnstate=="ticketview"){
                let routePageData = this.$router.resolve({ 
                    path: `/dynamic_tickets/tcview?ticketid=${parseInt(rowdata)}`,
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                });
                window.open(routePageData.href, '_blank');
            }
        },
        cancelsourceform(){
            $(".close").click();
        },
        validatedata : function(type,tblNm){
            if(tblNm=="candidatestagestatus"){
                this.valid=true
                if(!this.addtags){
                    this.errors.addtags="Tags is required";
                    this.valid=false
                }
            if(!this.rejectionactivitys){
                    this.errors.rejectionactivitys="Activity is required";
                    this.valid=false
                }
                if(!this.rejectioncomments){
                    this.errors.rejectioncomments="Comments is required";
                    this.valid=false
                }                
            }
        },
        saveRejectionData(type,datas){
            this.validatedata(type,'candidatestagestatus');
            if(this.valid){
                this.isLoading=true               
                this.apiURL="api/candidate/updaterejectstatus";
                this.input = {
                    useremail: this.undt.username,
                    empcode: parseInt(this.undt.userid),
                    candidateid: parseInt(datas.candidateid),
                    reqid: parseInt(datas.reqid),
                    stage: 'Pre Screening',
                    rejectioncomment: this.rejectioncomments,
                    tags: this.addtags,
                    rejectionstatus:type,
                    rejectiontype: this.rejectionactivitys 
                }                                 
                this.input.useremail= this.undt.username,
                this.input.empcode= this.undt.userid
                if(type==1){
                    this.input.stagestatus = 'Reject';
                }else if(type==2){
                    this.input.stagestatus = 'Screen';
                }
                if(this.apiURL){
                    axios({
                        'method': 'POST',
                        'url': this.apiURL,
                        'data': this.input,
                         'headers':{'authorization':this.tokendata}
                    })
                    .then(result => {      
                        this.isLoading = false;
                       // this.clearerrorForm();           
                        if(result.data.errorCode == 0){
                            $(".close").click();
                            Swal.fire({
                                title: "Success!",
                                text: result.data.msg,
                                icon: 'success',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                            this.getcandidatedetailsbyid(this.$route.query.candidateid);                          
                        } else if(result.data.errorCode == 3){
                                Swal.fire({
                                    title: "Session Expired...!!",
                                    text: result.data.msg,
                                    icon: 'info',
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                    },
                                    buttonsStyling: !1,
                                }).then(function() {
                                    window.location.href = "/#/login";
                                })
                            }else{
                            Swal.fire({
                                title: "Failed!",
                                text: result.data.msg,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        }
                    }).catch(e => {
                    this.displayError(e)
                })
                }
            }
        },
        redirectto:function(objThis){
            if(objThis){
                objThis.btnstate ='candidateprofile'
                objThis.rowdata={}
                objThis.rowdata = {'jdcode': objThis.datas.jdcode,'jdcodeid': objThis.datas.jdid, 'name': 'requistionlisting'}
            
                this.$router.push({ 
                    name: objThis.name,
                    path: '/'+objThis.path,
                    params: {
                        clickeddata:objThis.rowdata
                    }
                })
            }
        },
    }    
};
   
</script>