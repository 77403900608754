<template>
    <div class="app-content content ">
     <loading
       :active.sync="isLoading"
       :can-cancel="false"
       :is-full-page="true"
       color="orange"
       loader="dots"
       :width="100"
       :height="100"
     ></loading>
     <div class="content-overlay"></div>
     <div class="header-navbar-shadow"></div>
     <div class="content-wrapper pb-1 mt-1">
       <section id="card-navigation modern-horizontal-wizard">
         <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
           <div class="container-fluid">
             <div class="row breadcrumbs-top">
               <div class="col-md-9 content-header-left pl-0 ">
                 <h3 class="content-header-title float-left mb-0">Email Configuration</h3>
                 <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls " >
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link to="/email_sanity/emailsanitylist">Email configuration List</router-link>
                  </li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Create </li>
                  </li>
                 </ol>
               </div>
               </div>
             </div>
           </div>
         </div>
         <div class="card border-warning filter_card_searchbgst" >
           <div class="card-header py-1 px-2" style="border-bottom:1px solid #d8d8d8">
                <h3 class="card-title">
                    Create Email Configuration
                </h3>
           </div>
           <div class="card-body pt-1 px-sm-mobile-10">
               <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <label class="form-label lable-left">Modules</label>
                        <treeselect placeholder="Select module types" class="projectlistdd" :options = "modulelists" :multiple="true" :clear-on-select="true" v-model="modules" @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="error.modules">{{ error.modules }}</div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-4">
                        <label class="form-label lable-left">Email Notifications</label>
                        <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Notifications Types" v-model="notification" :options="emailnotificationtypes" :clear-on-select="true" @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="error.notification">{{ error.notification }}</div>
                    </div>

                    <div v-if="this.notification == 'selectedprojects'" class="col-12 col-md-4">
                        <div class="form-group mb-1">
                        <label for="projectmdllistdd">Project Name</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" :multiple="true" :clear-on-select="true" v-model="project" :options="projectlist" @input="selproject" @close="validateForm($event)"/>
                            <div class="errorinputmsg" v-if="error.project">{{ error.project }}</div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3" v-if=" this.modules=='businesstask' && this.notification == 'selectedprojects'" >
                        <div class="form-group mb-1">
                            <label for="allotedto">Project Roles</label>
                            <treeselect class="allotedto capitalisetext" :multiple="true" placeholder="Select role" v-model="roleid" :options="rolelist" />
                        </div>
                    </div> -->
               </div>
               <div class="row mt-2">
                    <div class="col-md-12" style="margin-left: -1rem">
                        <div class="form-group mb-1">
                        <label for="isactive" :class="{'active': isactive}" class="toggle__button">
                            <input type="checkbox" id="isactive" v-model="isactive">
                            <span class="toggle__switch"></span>
                            <span :style="{'color': isactive ? 'green' : 'black'}" class="toggle__label">
                            {{ isactive ? 'Active' : 'Inactive' }}
                            </span>
                        </label>

                    </div>

                    </div>
                </div>

                 <div class="col-md-12  text-right">
                    <label class="w-100 d-block">&nbsp;</label>
                    <div class="btn btn-outline-info mr-1" style="padding-left: 1.4rem; padding-right: 1.4rem" v-on:mousedown="clearData()">
                        <span>Clear</span>
                    </div>
                    <button type="submit" class="btn btn-relief-primary" style="padding-left: 2rem; padding-right:2rem" v-on:click.stop.prevent="savedata()">Submit</button>
                </div>

             </div>
         </div>
       </section>
     </div>
   </div>
  </template>
  <style>
    .table_input_width_150 tbody td{
            min-width: 150px;
    }
    .createbtnparentdiv{
        padding-right: 0% !important;
    }
    .displayblk{
        display:block !important;
    }
    .createbtn{
        border-color: #053e52 !important;
        color: #fff !important;
        background-color: #008000 !important;
        padding: 0.386rem 0.5rem !important;
    }
  </style>  

  <script>
    import axios from 'axios'
    import apiUrl from '../../constants';
    import moment from 'moment'
    import VueElementLoading from 'vue-element-loading';
    import vSelect from 'vue-select'
    import { SearchIcon, CodesandboxIcon,  BoxIcon, Link2Icon, PackageIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon, PlusCircleIcon } from 'vue-feather-icons'
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import Pagination from 'vue-pagination-2'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import commonMethods from '../../utils/commonMethods';

    export default {
    name:'emailsanitycreate',
        components:{
            Loading,
            VueElementLoading,PlusCircleIcon,
            vSelect,
            Treeselect,
            SearchIcon,
            CodesandboxIcon,
            BoxIcon,
            Link2Icon,
            PackageIcon,
            Pagination,
            PlusIcon,
            DownloadIcon,
            Edit2Icon,
            PlusSquareIcon,
            ClipboardIcon,
        },
        mixins: [ commonMethods ],
        data() {
            return {
                loginuser:'',
                isLoading: false,
                fullPage: true,
                modules:null,
                project:null,
                notification:null,
                projectlist:[],
                modulelists : apiUrl.modulelists,
                emailnotificationtypes: apiUrl.emailnotificationtypes,
                isactive:false,
                error: {
                    modules:'',
                    project:'' ,
                    notification:''
                },
                emailsanityconfig:[],
                roleid:null,
                rolelist:[]
            }
        },
        mounted(){
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.loginusername=userdata.username
                this.loginuscondition = userdata.userid 
                this.tokendata = window.localStorage.getItem('token');  
            }
            this.getProjectAllocatedToEmp()
            // this.getEmailSanityConfigbyEmpid()
        },
        methods:{
            getProjectAllocatedToEmp() {
                this.isLoading = true
                let url = "api/listing/getProjectAllocatedToEmp";
                this.input = {
                    empid: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                    isfrompage:'createpage'
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers': { 'authorization': this.tokendata }
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                    this.projectlist = result.data.data;
                    } else {
                    this.projectlist = [];
                    }
                });
            },
            selproject: function (state, value) {
                this.project = null;
                this.project = state;
                if (!state) {
                } else{
                    this.error.project = "Project is required"
                    this.getEmpProjectWiseRoles(state)
                }
            },
            getEmpProjectWiseRoles(projectid){
                this.input = {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    projectid:projectid
                }
                axios({
                    'method': 'POST',
                    'url': 'api/emailsanity/getEmpProjectWiseRoles',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(result.data.status == true){
                        this.rolelist = result.data.data
                    }else{
                        this.rolelist=[]
                    }
                }).catch(e => {
                    this.displayError(e)
                })
            },
            validateForm(){
                var isValid = true 
                if (!this.modules) {
                    this.error.modules = 'Select Modules'
                    isValid = false
                } else {
                    this.error.modules = ''
                }
                if (this.notification == 'selectedprojects' && !this.project) {
                    this.error.project = 'Select Project'
                    isValid = false
                } else {
                    this.error.project = ''
                }
                if (!this.notification) {
                    this.error.notification = 'Select Email notification'
                    isValid = false
                } else {
                    this.error.notification = ''
                }
                return isValid
            },
            clearData(){
                this.modules = null,
                this.notification = null,
                this.project = null,
                this.isactive = false
            },

            savedata(objThis){
			    this.isValid = this.validateForm(objThis)
				if (this.isValid){
                this.input = {
                    useremail: this.undt.username,
                    empcode: parseInt(this.undt.userid),
                    modules : this.modules,
                    notificationtypes : this.notification,
                    projectid: this.project,
                    btcroles: this.roleid
                }     
                if(this.isactive == true){
                    this.input.isactive = 1
                } else{
                    this.input.isactive = 0
                }
                if(this.notification == 'allproject'){
                    this.input.isallproject = 1
                }
                else if(this.notification == 'subscribed'){
                    this.input.subscriber = 1
                }    
                else if(this.notification == 'asssigned'){
                    this.input.assigned = 1
                }  
                else if(this.notification == 'createdby'){
                    this.input.creator = 1
                }
                else if(this.notification == 'noevents'){
                    this.input.noevents = 1
                }  
                let apiURL='api/emailsanity/createEmailSanityConfig'
                axios({
                    'method': 'POST',
                    'url': apiURL,
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result =>{
                    this.isLoading = false;
                    if(result.data.errorCode == 0){
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.clearData();
                    this.$router.push(`/email_sanity/emailsanitylist`)
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                                buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    } else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                this.displayError(e)
                })
				}
            },

            getEmailSanityConfigbyEmpid() {
                this.isLoading = true
                let url = "api/emailsanity/getEmailSanityConfigbyEmp";
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                };
                axios({
                    method: "POST",
                    url: url,
                    data: this.input,
                    'headers': { 'authorization': this.tokendata }
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                    this.emailsanityconfig = result.data.data;
                    this.openEditModule(this.emailsanityconfig[0])
                    } else {
                    this.emailsanityconfig = [];
                    }
                });
            },
            openEditModule: function (currRowData) {
                this.editRowData = currRowData;
                this.modules = currRowData.modulename;
                this.notification = currRowData.notificationtypes;
                this.project = currRowData.projectid;
                this.isactive = currRowData.isactive;
            },

        }
    }
    
  </script>