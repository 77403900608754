<template>
  <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-12 content-header-left pl-0">
                <h3 class="content-header-title float-left mb-0">Test Execution Cycle Details Level Report</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " >
                    <router-link to='/report/testcycledetailslevelreport'>Test Execution Cycle Details Level Report</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst" style="border-radius: 5px; border: none!important;">
          <div class="card-header filter_rotation_onlick " style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
              <div class="row">
                <div class="w-auto px-1 mobile-padding-0-2 mobile_w_calc_38  d-inline-flex align-items-center">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                      <div class="avatar-content">
                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3">
                        </search-icon>
                      </div>
                  </div>
                  <h4 class="card-title d-inline-flex ml-1">Search Test Execution Cycle Details Level Report</h4>
                </div>
                <div class="ml-auto mr-lg-1 d-flex">
                  <span class="rotetions_view_arrow"> 
                    <em class="las la-angle-down"></em>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
            <div class="card-body pt-2">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker mb-1"> 
                    <label for="" class="d-block">From Date</label>
                    <date-picker placeholder="Select Test Cycle Set Created Date" v-model="search.actionstartdate" valueType="format" class="startdate" id="StartDate" :disabled-date="notBeforeToday" @input="selectedstartdate"></date-picker>
                    <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 form-group datePicker mb-1"> 
                    <label for="" class="d-block">To Date</label>
                    <date-picker placeholder="Select Test Cycle Set Created Date" v-model="search.actionenddate" valueType="format" class="startdate" id="EndDate" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Business Task Name</label>
                  <treeselect class="projectmdllistdd" placeholder="Enter Min 5 Chars To Search Business Task Name" v-model="search.searchBusinesstaskname" :options="businesstasknameopts" v-on:search-change="businessNameChangeSearch" :clear-on-select="true" :multiple="true"/>                      
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Project Name</label>
                  <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 3 Chars To Search Project Name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject" :multiple="true" :clear-on-select="true"/>   
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">QA Lead</label>
                  <treeselect class="projroleassprojectdd" placeholder="Enter Min 3 Chars To Search QA Lead"  :options="qaleadlist" v-model="search.qalead" :multiple="true" v-on:search-change="getProjectQAdata(509,null,$event)" :clear-on-select="true"/> 
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">QA Engineer</label>
                  <treeselect class="projroleassprojectdd" placeholder="Enter Min 3 Chars To Search QA Engineer"  :options="qaenglist" v-model="search.qaeng" :multiple="true" v-on:search-change="getProjectQAdata(478,null,$event)" :clear-on-select="true"/> 
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                  <label class="form-label lable-left">Status</label>
                  <treeselect class="projectmdllistdd" placeholder="Select Status" :options="statuslist" v-model="search.status" :multiple="true" :clear-on-select="true"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3 ">
                  <button type="submit" :disabled="isBothDateSelected" class="btn btn-relief-primary mt-2 btn-margin-three" @click="testcycledetailslevelreportlist(search,1)"> 
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                  </button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 btn-margin-three" @click="resetRecords()">
                    <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> 
                  </button>
                  <button :disabled="isBothDateSelected" type="move" class="btn btn-relief-primary mt-2 btn-margin-three mr-0" @click="ExportXL()">
                    <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                  </button>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div id="detailstable"></div>
        <div class="card border-warning" id="btDiv">
          <div class="card-body p-0 ">
            <div  class="card-header px-1 py-1 view_bg-gradient-primary">
              <h2  class="card-title heading_text_pro_summarys">Test Execution Cycle Details Level Report </h2>
            </div>
            <div class="table-responsive" v-if="this.reportlist.length>0">
              <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                <thead>
                  <tr>
                    <th scope="col" class="align-middle srno first-col-sticky" style="min-width:45px;">Sr#</th>
                    <th scope="col" class="align-middle second-col-sticky" style="min-width: 170px;">Test Cycle Set Code</th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 170px;">Test Cycle Set Name</th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Test Cycle Execution No</th>
                    <th scope="col" class="align-middle  text-center">Status </th>
                    <th scope="col" class="align-middle  text-center">QA lead </th>
                    <th scope="col" class="align-middle  text-center">QA Eng </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Created Date </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 150px;">Run Start Date </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 150px;">Run End Date </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 120px;">No of hours from Run Start Date </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 150px;">Test Case Cycles Execution Time - In Hours </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Total Test Scripts </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 150px;">QA Start Date </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 150px;">QA Finish Date </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 150px;">TEST CASE CYCLES EXECUTION TIME OF QA - IN HOURS </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Total Allocated To QA </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Total Pass Scripts </th>
                    <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Total Fail Scripts </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(data,index) in reportlist" >        
                      <tr v-bind:key="index">
                          <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                          <td class="second-col-sticky capitalisetext">
                            {{ data.cyclecode }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.cyclesetname }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.cyclerunno ? data.cyclerunno : 0 }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.status }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.qalead ? data.qalead : 'NA' }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.qaeng ? data.qaeng :'NA' }} 
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.createddate ? dateMonthFormat(data.createddate) : 'NA' }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.rundate ? dateMonthFormat(data.rundate) : 'NA' }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.finishdate ? dateMonthFormat(data.finishdate) : 'NA' }}
                          </td>
                          <td class="text-center">
                            {{ data.currenthrs ? data.currenthrs.toFixed(2) : 0}} Hrs
                          </td>
                          <td class="text-center">
                            {{ data.totalhrs ? data.totalhrs.toFixed(2) : 0}} Hrs
                          </td>
                          <td class="text-center">
                            {{ data.nooftestcase ? data.nooftestcase : 0 }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.qarundate ? dateMonthFormat(data.qarundate) : 'NA' }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.qafinishdate ? dateMonthFormat(data.qafinishdate) : 'NA' }}
                          </td>
                          <td class="text-center capitalisetext">
                            {{ data.qatotalhrs && data.qatotalhrs!=0 ? data.qatotalhrs.toFixed(2) : 0 }}
                          </td>
                          <td class="text-center">
                            {{ data.finishdate ? data.nooftestcase : data.noofassign ? data.noofassign : 0 }}
                          </td>
                          <td class="text-center">
                            {{ data.totalpass ? data.totalpass : 0 }}
                          </td>
                          <td class="text-center">
                            {{ data.totalfail ? data.totalfail : 0 }}
                          </td>
                      </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="pageCenter text-center mt-1" v-if="this.reportlist.length ==0">
              No record found
            </div> 
            <div class="pageCenter text-center pt-1">
              <pagination v-if="this.reportlist.length>0" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
 </template>
 
 <script>
 import axios from 'axios';
 import apiUrl from '../../constants';
 import VueElementLoading from 'vue-element-loading';
 import Loading from 'vue-loading-overlay';
 import DatePicker from "vue2-datepicker";
 import 'vue-loading-overlay/dist/vue-loading.css';
 import '@riophae/vue-treeselect/dist/vue-treeselect.css';
 import { SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
 import Treeselect from '@riophae/vue-treeselect'
 import moment from "moment";
 import Pagination from 'vue-pagination-2';
 import commonMethods from '../../utils/commonMethods';

 export default {
   name: 'TestcycleDetailsLevelReport',
   mixins: [ commonMethods ],
   components: {
     VueElementLoading,
     Loading,
     SearchIcon,
     XIcon,
     ExternalLinkIcon,
     Treeselect,DatePicker,Pagination,
   },
   data() {
     return {
        isBothDateSelected:false,
        projectnameList:[],
        businesstasknameopts: [],
        search: {
          actionstartdate:null,
          actionenddate: null,
          qalead: null,
          qaeng: null,
          searchBusinesstaskname:null,
          searchProjectname: null,
          status: null
        },
        qaleadlist:[],
        qaenglist: [],
        isLoading: false,
        undt: null,
        tokendata: null,
        isSearchRequestActive:false,
        card_content:'card-content',
        collapse:'collapse',
        reportlist: [],
        dateArr: [],
        totalcount: 0,
        pageCount: 0,
        current_page: 1,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures:[],
        startDate: null,
        endDate:null,
        pagelimit:apiUrl.LIMIT,
        page:1,
        rowsSum: [],
        rowsReopenSum: [],
        statuslist: apiUrl.testcyclestatuslist.sort((a, b) => a.label.localeCompare(b.label)),
     }
   },
   created() {},
   mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata){
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.getDateArr(null);
      }
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      }); 
    }
   },
   methods: {
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state) {
      if(!state){
        this.qaleadlist=[];
        this.qaenglist= [];
      }else{
        this.search.searchProjectname = state;
        this.getProjectQAdata(478,state, null);
        this.getProjectQAdata(509,state, null);
        this.searchactiveflag = false;
      }
    },
    getProjectQAdata(roleid, state, node){
      let inputappl = {
        empcode: this.undt.userid,
        useremail: this.undt.username,
        node: (node != null && node != '' && node.length > 2)? node : null,
        roleid: roleid,
        projectidarr:((state != null && state.length > 0))? state : null,
      }
      if((node != null && node.length>=2) || (state != null && state.length > 0)) {
        axios({
        'method': 'POST',
        'url': 'api/listing/getcyclestqalead',
        'data': inputappl,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => { 
          this.isLoading= false;         
          if(result.data.errorCode == 0){
            if(roleid==509){
              this.qaleadlist=result.data.data.rows;
            }else if (roleid==478){
              this.qaenglist=result.data.data.rows;
            }
          }else{
            if(roleid==509){
              this.qaleadlist=[]
            }else if (roleid==478){
              this.qaenglist=[];
            }
          }
        }).catch(e => {
    this.displayError(e)
    })
      } else {
        this.qaleadlist=[];
        this.qaenglist= [];
      }
    },
    selectedenddate(state) {
      if (this.search.actionstartdate && this.search.actionenddate) {
        this.isBothDateSelected = false;
        this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
      } else {
        this.isBothDateSelected = true;
        this.getDateArr(this.search);
      }
      if(state==null && this.search.actionstartdate ==null){
        this.isBothDateSelected = false;
      }
    },
    selectedstartdate(state) {
      if (this.search.actionstartdate && this.search.actionenddate) {
        this.isBothDateSelected = false;
        this.dateRange = this.getDatesInRange(this.search.actionstartdate, this.search.actionenddate);
      } else {
        this.isBothDateSelected = true;
        this.getDateArr(this.search);
      }
      if(state==null && this.search.actionenddate==null){
        this.isBothDateSelected = false;
      }
    },
    getDatesInRange(fromDate, toDate) {
      let dates = [];
      const current = new Date(fromDate);
      const end = new Date(toDate);
      const oneDay = 24 * 60 * 60 * 1000; 
      while (current <= end) {
          dates.push(current.toISOString().split('T')[0]);
          current.setTime(current.getTime() + oneDay);
      }
      return dates;
    },
    businessNameChangeSearch(node) {
      if(node != null && node != '' && node.length >= 5) {
        this.isLoading = true;
        let url = "api/reports/getBTCbyProject";
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          node: node ? node.toLowerCase() : null,
          projectid: this.search.searchProjectname? this.search.searchProjectname : null,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.businesstasknameopts = result.data.data.rows;
          } else {
            this.businesstasknameopts = [];
          }
        }).catch(e => {
    this.displayError(e)
    })
      } else {
        this.businesstasknameopts = [];
      }
    },
    clickCallback: function(pageNum) {
      this.pageoffset=pageNum;
      this.page = pageNum;
      this.currPageNum = pageNum - 1
      this.testcycledetailslevelreportlist();
      setTimeout(function(){
        $('html, body').animate({
          scrollTop: $("#detailstable").offset().top -70
        });
      }, 500);
    },
    testcycledetailslevelreportlist(search,flag) {
      if((this.search.searchProjectname && this.search.searchProjectname.length>0) || (this.search.qaeng && this.search.qaeng.length>0) || (this.search.qalead && this.search.qalead.length>0) || (this.search.searchBusinesstaskname && this.search.searchBusinesstaskname.length>0) || ((this.search.actionenddate && this.search.actionenddate !=null) || (this.search.startDate && this.search.startDate!=null)) || ((this.search.actionenddate && this.search.actionenddate!=null) || (this.endDate && this.endDate!=null)) || (this.search.status && this.search.status!=null && this.search.status.length >0)){
        this.isLoading = true;
        if(search) {
          localStorage.setItem('clubreportsearchItems', JSON.stringify(search))
        }
        let todate = (this.search.actionenddate!=null) ? this.search.actionenddate : this.endDate;
        let assignedate='';
        if(todate && todate !=null){
          assignedate = new Date(todate);
          assignedate.setDate(assignedate.getDate() + 1);
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          limit: apiUrl.LIMIT,
          startDate: (this.search.actionstartdate!=null) ? this.search.actionstartdate:this.startDate,
          endDate: assignedate,
          projectid: this.search.searchProjectname ? this.search.searchProjectname : null,
          qaeng: this.search.qaeng ? this.search.qaeng : null,
          qalead: this.search.qalead ? this.search.qalead : null,
          btcid: this.search.searchBusinesstaskname ? this.search.searchBusinesstaskname : [],
          status: this.search.status ? this.search.status : null
        }
        if(flag == 1){
          this.input.offset = 1;
          this.currPageNum = 0;
        }else{
          this.input.offset = this.pageoffset;
        }
        axios({
          method: "POST",
          url: "api/btc/cycle/testcycledetailslevelreportlist",
          data: this.input,
          headers: { authorization : this.tokendata },
        })
        .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.reportlist = result.data.data.rows;
              for(let i=0; i < this.reportlist.length; i++){
                if(this.reportlist[i].rundate && this.reportlist[i].finishdate){
                  let startDatetime = moment(this.reportlist[i].rundate);
                  let endDatetime = moment(this.reportlist[i].finishdate);

                  if (endDatetime.isBefore(startDatetime)) {
                    [startDatetime, endDatetime] = [endDatetime, startDatetime];
                  }

                  let durations = moment.duration(endDatetime.diff(startDatetime));
                  let totalhrs = durations.asHours();
                  this.reportlist[i]['totalhrs'] = totalhrs;
                }
                if(this.reportlist[i].finishdate ==null || this.reportlist[i].finishdate ==''){
                  let currentdatetime = moment().format('YYYY-MM-DD HH:mm');
                  let startDatetime = moment(this.reportlist[i].rundate);
                  let endDatetime = moment(currentdatetime);

                  if (endDatetime.isBefore(startDatetime)) {
                    [startDatetime, endDatetime] = [endDatetime, startDatetime];
                  }
                  
                  let durations = moment.duration(endDatetime.diff(startDatetime));
                  let currenthrs = durations.asHours();
                  this.reportlist[i]['currenthrs'] = currenthrs;
                }
                if(this.reportlist[i].qarundate && this.reportlist[i].qafinishdate){
                  let qastartDatetime = moment(this.reportlist[i].qarundate);
                  let qaendDatetime = moment(this.reportlist[i].qafinishdate);

                  if (qaendDatetime.isBefore(qastartDatetime)) {
                    [qastartDatetime, qaendDatetime] = [qaendDatetime, qastartDatetime];
                    // qaendDatetime.add(1, 'day');
                  }
                  let durations = moment.duration(qaendDatetime.diff(qastartDatetime));
                  let qatotalhrs = durations.asHours();
                  this.reportlist[i]['qatotalhrs'] = qatotalhrs;
                }
              }
              this.totalcount = result.data.data.count;
              this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            } else {
              this.reportlist = [];
              this.totalcount = 0;
              this.pageCount = 0;
            }
        }).catch(e => {
    this.displayError(e)
    })
      }
    },
    getDateArr(search) {
      this.startDate = (search && search.actionstartdate!=null)?moment(search.actionstartdate): null;
      this.endDate = (search && search.actionenddate!=null)?(moment(search.actionenddate)+1): null;
      let date = [];
      for (var m = moment(this.startDate); m.isBefore(this.endDate); m.add(1, 'days')) {
        date.push(m.format('YYYY-MM-DD'));
      }
      this.dateArr = date;
    },
    dateMONFormat(value){
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    projectChange: function (node){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage: 'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
    this.displayError(e)
    })
      }
    },     
    resetRecords: function() {
      this.isBothDateSelected = false;
      this.search.searchBusinesstaskname = null;
      this.search.actionstartdate=null;
      this.search.actionenddate= null;
      this.search.searchProjectname = null;
      this.search.qalead = null;
      this.search.qaeng = null;
      this.search.status = null;
      this.qaleadlist=[];
      this.qaenglist= [];
      this.startDate = null;
      this.endDate = null;
      this.reportlist = [];
      localStorage.removeItem('clubreportsearchItems');
      this.getDateArr(null);
    },
    notBeforeToday: function (date) {
      this.search.actionenddate = null;
      return date > new Date(new Date().setHours(0, 0, 0, 0));     
    },
    disabledBefore(date) {
      const oneMonthFromFromDate = moment(this.search.actionstartdate).add(1, 'month');
      const startOfOneMonth = oneMonthFromFromDate.startOf('day').toDate();
      const startOfFromDate = moment(this.search.actionstartdate).startOf('day').toDate();
      return date > startOfOneMonth || date < startOfFromDate;
    },
    ExportXL() {
      if((this.search.searchProjectname && this.search.searchProjectname.length>0) || (this.search.qaeng && this.search.qaeng.length>0) || (this.search.qalead && this.search.qalead.length>0) || (this.search.searchBusinesstaskname && this.search.searchBusinesstaskname.length>0) || ((this.search.actionstartdate && this.search.actionstartdate !=null) || (this.search.startDate  && this.search.startDate !=null)) || ((this.search.actionenddate && this.search.actionenddate !=null) || (this.endDate && this.endDate !=null)) || (this.search.status && this.search.status!=null && this.search.status.length >0)){
        this.isLoading = true;
        this.exportxlsdata = [];
        this.exporterr = "";
        let todates = (this.search.actionenddate!=null) ? this.search.actionenddate : this.endDate;
        let assignedates='';
        if(todates && todates !=null){
          assignedates = new Date(todates);
          assignedates.setDate(assignedates.getDate() + 1);
          moment(String(assignedates)).format('YYYY-MM-DD');
        }
        this.input = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          startDate: (this.search.actionstartdate!=null)?this.search.actionstartdate:this.startDate,
          endDate: assignedates,
          projectid: this.search.searchProjectname,
          qaeng: this.search.qaeng,
          qalead: this.search.qalead,
          btcid: this.search.searchBusinesstaskname,
          status: this.search.status
        }
        axios({
          method: "POST",
          url: "api/btc/cycle/testcycledetailslevelreportlist",
          data: this.input,
          headers: { authorization : this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            for(let i=0; i < this.exportxlsdata.length; i++){
              if(this.exportxlsdata[i].rundate && this.exportxlsdata[i].finishdate){
                let startDatetime = moment(this.exportxlsdata[i].rundate);
                let endDatetime = moment(this.exportxlsdata[i].finishdate);

                if (endDatetime.isBefore(startDatetime)) {
                  [startDatetime, endDatetime] = [endDatetime, startDatetime];
                }

                let durations = moment.duration(endDatetime.diff(startDatetime));
                let totalhrs = durations.asHours();
                this.exportxlsdata[i]['totalhrs'] = totalhrs;
              }
              if(this.exportxlsdata[i].finishdate ==null || this.exportxlsdata[i].finishdate ==''){
                let currentdatetime = moment().format('YYYY-MM-DD HH:mm');
                let startDatetime = moment(this.exportxlsdata[i].rundate);
                let endDatetime = moment(currentdatetime);

                if (endDatetime.isBefore(startDatetime)) {
                  [startDatetime, endDatetime] = [endDatetime, startDatetime];
                }

                let durations = moment.duration(endDatetime.diff(startDatetime));
                let currenthrs = durations.asHours();
                this.exportxlsdata[i]['currenthrs'] = currenthrs;
              }
              if(this.exportxlsdata[i].qarundate && this.exportxlsdata[i].qafinishdate){
                let qastartDatetime = moment(this.exportxlsdata[i].qarundate);
                let qaendDatetime = moment(this.exportxlsdata[i].qafinishdate);

                if (qaendDatetime.isBefore(qastartDatetime)) {
                  [qastartDatetime, qaendDatetime] = [qaendDatetime, qastartDatetime];
                }

                let durations = moment.duration(qaendDatetime.diff(qastartDatetime));
                let qatotalhrs = durations.asHours();
                this.exportxlsdata[i]['qatotalhrs'] = qatotalhrs;
              }
            }
            this.download(this.exportxlsdata);
          }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }      
        }).catch(e => {
    this.displayError(e)
    })
      }
    },
    download(readExportdata) {
      if (readExportdata.length > 0) {
      let ShowLabel = 'Testcycleset_Detailslevel_Report_'
      let JSONData = readExportdata
      let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
      let CSV = ''
        if (ShowLabel) {
          let row = ''
          for (let index in arrData[0]) {
            row += index + ','
          }
          row = row.slice(0, -1)
          CSV = 'Sr#, TEST CYCLE SET CODE	,TEST CYCLE SET NAME, TEST CYCLE EXECUTION NO, STATUS, CREATED DATE, QA LEAD, QA ENG ,RUN START DATE	,RUN END DATE	,NO OF HOURS FROM RUN START DATE,TEST CASE CYCLES EXECUTION TIME-IN HOURS, TOTAL TEST SCRIPTS, QA START DATE, QA FINISH DATE, TEST CASE CYCLES EXECUTION TIME OF QA - IN HOURS, TOTAL ALLOCATEDTO QA,TOTAL PASS SCRIPTS,TOTAL FAIL SCRIPTS';

          CSV += '\r\n';
        }
        let row = '';
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let cyclecode = index.hasOwnProperty('cyclecode') ? index.cyclecode == null || index.cyclecode == 'NA' ? 'NA' : index.cyclecode : 'NA';
          let cyclesetname = index.hasOwnProperty('cyclesetname') ? index.cyclesetname == null || index.cyclesetname == 'NA' ? 'NA' : index.cyclesetname : 'NA';
          let cyclerunno = index.hasOwnProperty('cyclerunno') ? index.cyclerunno == null || index.cyclerunno == 'NA' ? '0' : index.cyclerunno : '0';
          let status = index.hasOwnProperty('status') ? index.status == null || index.status == 'NA' ? 'NA' : index.status : 'NA';
          let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ? 'NA' : index.createddate : 'NA';
          createddate = createddate ? this.dateMonthFormat(createddate) : 'NA'
          let qalead = index.hasOwnProperty('qalead') ? index.qalead == null || index.qalead == 'NA' ? 'NA' : index.qalead : 'NA';
          let qaeng = index.hasOwnProperty('qaeng') ? index.qaeng == null || index.qaeng == 'NA' ? 'NA' : index.qaeng : 'NA';
          let rundate = index.hasOwnProperty('rundate') ? index.rundate == null || index.rundate == 'NA' ? 'NA' : index.rundate : 'NA';
          rundate = rundate && rundate!=null && rundate!='NA' ? this.dateMonthFormat(rundate) : 'NA';
          let finishdate = index.hasOwnProperty('finishdate') ? index.finishdate == null || index.finishdate == 'NA' ? 'NA' : index.finishdate : 'NA';
          finishdate = finishdate && finishdate!=null && finishdate!='NA' ? this.dateMonthFormat(finishdate) : 'NA';
          let currenthrs = index.hasOwnProperty('currenthrs') ? index.currenthrs == null || index.currenthrs == '0' ? '0' : index.currenthrs : '0';
          currenthrs = currenthrs && currenthrs !=0 ? currenthrs.toFixed(2) : 0;
          let totalhrs = index.hasOwnProperty('totalhrs') ? index.totalhrs == null || index.totalhrs == '0' ? '0' : index.totalhrs : '0';
          totalhrs = totalhrs && totalhrs !=0 ? totalhrs.toFixed(2) : 0;
          let nooftestcase = index.hasOwnProperty('nooftestcase') ? index.nooftestcase == null || index.nooftestcase == '0' ? '0' : index.nooftestcase : '0';
          let noofassign = index.hasOwnProperty('noofassign') ? index.noofassign == null || index.noofassign == '0' ? '0' : index.noofassign : '0';
          noofassign = finishdate && finishdate!=null && finishdate!='NA' ? nooftestcase : noofassign ? noofassign : 0
          let qarundate = index.hasOwnProperty('qarundate') ? index.qarundate == null || index.qarundate == 'NA' ? 'NA' : index.qarundate : 'NA';
          qarundate = qarundate && qarundate!=null && qarundate!='NA' ? this.dateMonthFormat(qarundate) : 'NA';
          let qafinishdate = index.hasOwnProperty('qafinishdate') ? index.qafinishdate == null || index.qafinishdate == 'NA' ? 'NA' : index.qafinishdate : 'NA';
          qafinishdate = qafinishdate && qafinishdate!=null && qafinishdate!='NA' ? this.dateMonthFormat(qafinishdate) : 'NA';
          let qatotalhrs = index.hasOwnProperty('qatotalhrs') ? index.qatotalhrs == null || index.qatotalhrs == '0' ? '0' : index.qatotalhrs.toFixed(2) : 0;
          let totalpass = index.hasOwnProperty('totalpass') ? index.totalpass == null || index.totalpass == '' ? '0' : index.totalpass : '0';
          let totalfail = index.hasOwnProperty('totalfail') ? index.totalfail == null || index.totalfail == '' ? '0' : index.totalfail : '0';

          row += '"' + srno + '",',
          row += '"' + cyclecode + '",',
          row += '"' + cyclesetname + '",',
          row += '"' + cyclerunno + '",',
          row += '"' + status + '",',
          row += '"' + createddate + '",',
          row += '"' + qalead + '",',
          row += '"' + qaeng + '",',
          row += '"' + rundate + '",',
          row += '"' + finishdate + '",',
          row += '"' + currenthrs + '",',
          row += '"' + totalhrs + '",',
          row += '"' + nooftestcase + '",',
          row += '"' + qarundate + '",',
          row += '"' + qafinishdate + '",',
          row += '"' + qatotalhrs + '",',
          row += '"' + noofassign + '",',
          row += '"' + totalpass + '",',
          row += '"' + totalfail + '",',
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        let fileName = 'Testcycleset_Detailslevel_Report_'+moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
              confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      } else {
        Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
        });   
      }
    },
    export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
  }
 }
 </script>
 <style> 
 .view_bg-gradient-primary{
   background: #7DA3F9!important;
 }
 </style>