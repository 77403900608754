<template>
    <div id="social-links-modern" class="content ">
    <div class="container mt-1">
        <div class="row">
        <div class="col-lg-8">
            <div class="card">
               <div class="card-body hirginworkflowstimeline">
                    <ul class="timeline" id="list">
                        <li class="timeline-item">
                            <span class="timeline-point">
                                <em data-feather='layers'></em>
                            </span>
                            <div class="card timeline-event border p-2 bg-bg-backgorund p-2 mb-0">
                                
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-inline-block">
                                         <h6> Shortlisting </h6>
                                    </div>
                                    <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                       <input type="checkbox" class="custom-control-input mr-1" id="business-checkbox-1" v-model="workflow.shortlisting"/>
                                       <label class="custom-control-label" for="business-checkbox-1"></label>
                                    </div>
                                </div>
                                      
                            </div>
                        </li>
                        <li class="timeline-item" >
                            <span class="timeline-point timeline-point-secondary">
                                <em data-feather="user"></em>
                            </span>
                             <div class="card timeline-event border p-2 bg-bg-backgorund p-2 mb-0 ">
                                
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-inline-block">
                                         <h6> Screening </h6>
                                    </div>
                                    <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                        <input type="checkbox" class="custom-control-input mr-1" id="business-checkbox-2" v-model="workflow.screening"/>
                                        <label class="custom-control-label" for="business-checkbox-2"></label>
                                    </div>
                                </div>
                                      
                            </div>
                        </li>
                        <div class="timeline-item" v-for="(data, index) in workflow.interviewRound">
                            <div  v-bind:key="index">
                            <li  >
                            <span class="timeline-point timeline-point-success">
                                <em class="las la-users hiring_bag_shopping"></em>
                            </span>
                             <div class="card timeline-event border p-2 p-2 bg-bg-backgorund p-2 mb-0 ">
                                
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-inline-block">
                                         <h6> Interview Round {{ index + 1 }}</h6>
                                    </div>
                                    <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                        <input type="checkbox" class="custom-control-input mr-1" v-bind:id="index" v-model="workflow.interviewRound[index].check"/>
                                        <label class="custom-control-label" v-bind:for="index"></label>

                                       <div class="badge badge-light-danger mr-0 inter_shotlisting_del" v-if="index >= 1"> 
                                            <trash-2-icon size="1.5x" class="custom-class" @click="remove(index)"></trash-2-icon> 
                                        </div>
                                       <div class="badge badge-light-success"> </div>
                                    </div>
                                </div>
                                      
                            </div>
                            </li>
                            </div>
                        </div>
                        <li class="timeline-item" >
                            <span class="timeline-point timeline-point-danger">
                                <em data-feather="shopping-bag"></em>
                            </span>
                             <div class="card timeline-event border p-2 p-2 bg-bg-backgorund p-2 mb-0 ">
                                
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="d-inline-block">
                                         <h6> Offer Stage </h6>
                                    </div>
                                    <div class=" d-flex custom-control custom-checkbox  ml-auto">
                                        <input type="checkbox" class="custom-control-input mr-1" id="business-checkbox-5" v-model="workflow.offer"/>
                                        <label class="custom-control-label" for="business-checkbox-5"></label>
                                       <div class="badge badge-light-success"> </div>
                                    </div>
                                </div>
                                      
                            </div>
                        </li>
                        
                    </ul>
                    <div class="timeline-item mt-2 float-right">
                            
                             <button type="button" class="btn btn-relief-primary round waves-effect" @click="addAnother()"> <em data-feather='plus'></em> Add Round</button>
                        </div>
                        
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <section class="basic-radio">
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <div class="card">
                               
                                <div class="card-body pb-0 pl-0">
                                    <div class="demo-inline-spacing hrms_radio_btn">
                                         <p class="card-text m-0">Can the Interviews/Evaluations Be Done In Parallel?</p>
                                        <div class="form-check form-check-inline mt-1">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" v-model="workflow.evaluations" checked />
                                            <label class="form-check-label nav-vertical_margin_x5" for="inlineRadio1">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-1">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="0" v-model="workflow.evaluations"/>
                                            <label class="form-check-label nav-vertical_margin_x5" for="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body px-0">
                                    <div class="demo-inline-spacing hrms_radio_btn">
                                         <p class="card-text m-0 d-block w-100">Enable Pre Offer?</p>
                                        <div class="form-check form-check-inline mt-1">
                                            <input class="form-check-input" type="radio" name="inlineRadio" id="inlineRadio3" value="1" v-model="workflow.preoffer" checked />
                                            <label class="form-check-label nav-vertical_margin_x5" for="inlineRadio1">Yes</label>
                                        </div>
                                        <div class="form-check form-check-inline mt-1">
                                            <input class="form-check-input" type="radio" name="inlineRadio" id="inlineRadio4" value="0" v-model="workflow.preoffer"/>
                                            <label class="form-check-label nav-vertical_margin_x5" for="inlineRadio2">No</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="card  hrms_background_descriptions">
                                    <div class="card-body ">
                                        
                                        <p class="card-text" style="color: #6e6b7b;">
                                            When Pre offer is enabled, please make sure the Pre offer
                                            Fields are configured in Job Application Workflow-Step3.

                                            This is your company's default recruitment workflow.
                                            Feel free to add/Delete and edit stages as you deem relevant
                                            for this job opening. Note that the first add last stage
                                            cannot be deleted. The stage names and evaluation types 
                                            can be changed on clicking the individual stage's edit button.
                                        </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
        </div>
        </div>
        </div>
        <div class="buttons_hrms_view text-right px-1">
            <button class="btn btn-relief-secondary btn-prev3 hrms-bt-mr-5" @click="prev2">
                <em data-feather="arrow-left" class="align-middle mr-sm-25 mr-0"></em>
                <span class="align-middle d-sm-inline-block d-none">Previous</span>
            </button>
            <button class="btn btn-relief-success  btn-next3 btn-next18 hrms-bt-mr-5" @click="savedatas('1')">
                <span class="align-middle d-sm-inline-block d-none">Save as Draft</span>
                <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></em>
            </button>
            <button class="btn btn-relief-primary  hrms-bt-mr-5" @click="savedatas('1')">
                <span class="align-middle d-sm-inline-block d-none">Save & Continue</span>
                <em data-feather="arrow-right" class="align-middle ml-sm-25 ml-0 btn12"></em>
            </button>
        </div>
    </div>
</template>

<script> 
import axios from 'axios'
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueTimepicker from 'vue2-timepicker'
import commonMethods from '../../../utils/commonMethods';

export default({
    name:'HiringWorkflow',
    mixins: [ commonMethods ],

    components:{
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,DatePicker,VueTimepicker,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
    },
    data() {
    return {
        workflow: {
            shortlisting: true,
            screening: true,
            interviewRound: [
                {
                    check: null,
                }
            ],
            evaluations: null,
            preoffer: null,
            offer: true,
            isactive: null
        },
        checked: true
    };
  },
  mounted() {
    $(function () {
            'use strict';
            var bsStepper = document.querySelectorAll('.bs-stepper'),
                select = $('.select2'),
                modernWizard = document.querySelector('.modern-wizard-example')
            // select2
            select.each(function () {
                var $this = $(this);
                $this.wrap('<div class="position-relative"></div>');
                $this.select2({
                placeholder: 'Select value',
                dropdownParent: $this.parent()
                });
            });
            let step1 = -1;
            if (typeof modernWizard !== undefined && modernWizard !== null) {
                var modernStepper = new Stepper(modernWizard, {
                linear: false
                });
                step1=modernStepper._currentIndex;
                $(modernWizard)
                .find('.btn-next18')
                .on('click', function () {
                    if(step1==0)
                    {
                        modernStepper._currentIndex=1
                    }
                    if(step1==1)
                    {
                        modernStepper._currentIndex=2
                    }
                    if(step1==2)
                    {
                        modernStepper._currentIndex=3
                    }
                    if(step1==3)
                    {
                        modernStepper._currentIndex=4
                    }
                    if(step1==4)
                    {
                        modernStepper._currentIndex=0
                    }
                    modernStepper._currentIndex=4
                    modernStepper.next();
                });
                $(modernWizard)
                .find('.btn-prev2')
                .on('click', function () {
                    modernStepper.previous();
                });
            }
        });
        if (feather) {
            feather.replace({
                width: 14,
                height: 14
            });
        }
        let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt=userdata
                this.tokendata = window.localStorage.getItem('token');
            }
    },
  props:['globaljdid'],
    methods: {
        addAnother () {
            this.workflow.interviewRound.push({
                check: null,
            })
    },
    removeAnother(e) {    
        alert("hhhhhhh");
        e.target.parentElement.removeChild(e.target);
      },
      savedatas(isactive){
        if (this.globaljdid && this.globaljdid!=0){
            this.isLoading = true
            this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                jdid: this.globaljdid,
                shortlisting: this.workflow.shortlisting == true ? 1 : 0,
                screening: this.workflow.screening == true ? 1 : 0,
                evaluations: parseInt(this.workflow.evaluations),
                preoffer: parseInt(this.workflow.preoffer),
                offer: this.workflow.offer == true ? 1 : 0,
                isactive: parseInt(isactive) 
            }
            let count = []
            if (this.workflow.interviewRound) {
                for ( const round of this.workflow.interviewRound) {
                    if (round.check == true) {
                        count.push(round)
                        this.input.interviewRound = count.length
                    }
                }
            }
            let apiURL = 'api/hrms/savehiringworkflow'
            
            axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            }).then(result => {
                this.isLoading = false;        
                if(result.data.errorCode == 0){
                    Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                    })
                   
                }else if(result.data.errorCode == 3){
                        Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                        }).then(function() {
                        window.location.href = "/#/login";
                    })
                }
                else {
                    Swal.fire({
                    title: "Failed",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                    })
                }
            }).catch(e => {
                this.displayError(e)
            })            
        }else{
                Swal.fire({
                    title: "Failed",
                    text: 'Job Details Id Is Required',
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
            }
      },
      remove(index){
        this.workflow.interviewRound.splice(index, 1)
      },
      nextpgn(){
        $('.btn-next4').click();
      },
      prev2(){
            $('.btn-prev2').click();
        }
    },
})
</script>


