<template>
  <div class="app-content content ">
   <loading
     :active.sync="isLoading"
     :can-cancel="false"
     :is-full-page="true"
     color="orange"
     loader="dots"
     :width="100"
     :height="100"
   ></loading>
   <div class="content-overlay"></div>
   <div class="header-navbar-shadow"></div>
   <div class="content-wrapper pb-1">
     <section class="project_summarysviews001" id="card-navigation modern-horizontal-wizard">
       <div class="content-header-left content-header-left mb-1 pt-0">
         <div class="container-fluid">
           <div class="row breadcrumbs-top">
             <div class="col-md-12 content-header-left pl-0">
               <h3 class="content-header-title float-left mb-0">Release Planner FeedBack Form</h3>
               
             </div>
           </div>
         </div>
       </div>
     
       <div v-if="feedbackdetails.length>0" class="card card open rounded mb-1 shadow-none " id="DEETAIZls">
         <div class="card-body p-0">
            <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="BDetailsCollapse">
             <h2  class="card-title heading_text_pro_summarys" >Release Planner Details</h2>
             <div class="ml-auto mr-lg-1 d-flex">
                   <span class="rotetions_view_arrow bg-white"> 
                     <em class="las la-angle-down"></em>
                   </span>
               </div>
           </div>
           <div class="table-responsive" v-bind:class="[{show: bdeatilsflag},card_content,collapse]" >      
            <div>
              <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div>
                    <div class="card-body">
                          <div class="container">
                            <div class="row">
                              <div class="col-sm">
                                <span><b>Feedback Deadline is: </b> {{ dateMonthFormat(feedbackdeadline) }}</span>
                              </div>
                            </div>
                          </div>
                          <hr>
                          <div class="container">
                            <div class="row">
                              <div class="col-sm">
                                <span><b>Release Planner Code:</b> {{ feedbackdetails[0].releasenotecode }}</span>
                              </div>
                              <div class="col-sm">
                                <span><b>Project Name:</b> {{ feedbackdetails[0].projectname }}</span>
                              </div>
                            </div>
                          </div>
                          <hr>
                          <div class="container">
                          <div class="row" v-for="(btc) in btcTaskDetails">
                            <div class="col-sm">
                              <span><b>BusinessTask Code:</b> {{ btc.businesstaskcode }}</span>
                            </div>
                            <div class="col-sm">
                              <span><b>BusinessTask Name:</b> {{ btc.taskname }}</span>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>

              </div>
           </div>
         </div>
       </div>


       <div class="card card open rounded mb-1 shadow-none " id="issueDiv">
         <div class="card-body p-0">
            <div  class="card-header px-1 py-1 filter_rotation_onlick" style="cursor:pointer;" v-on:click="IssuesummanryCollapse">
             <h2  class="card-title heading_text_pro_summarys" >FeedBack Questionnaire Form for Business Task Requester</h2>
             <div class="ml-auto mr-lg-1 d-flex">
                   <span class="rotetions_view_arrow bg-white"> 
                     <em class="las la-angle-down"></em>
                   </span>
               </div>
           </div>
           <div class="table-responsive" v-bind:class="[{show: isIssueActive},card_content,collapse]" >      
            <div>
              <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div>
                    <div class="card-body">
                        <form class="form form-vertical">
                            <div class="col-md-12">
                                <div v-if="(todaysdate>feedbackdeadline) || missedDedline" class="row">
                                     <span class="errorinputmsg"> Note : {{dateMonthFormat(feedbackdeadline)}} Missed Your deadline.</span>
                                </div>
                                <div v-else>
                                    <div class="row" v-if="feedbackdetails && feedbackdetails.length>0 && feedbackdetails[0].status == 'pending'">
                                      <div class="col-md-12" v-for="(fieldname,i) in fieldnamelist" v-bind:key="i">
                                          <div class="form-group mb-1">
                                              <label for="projectlistdd" class="required-field" v-if="fieldname.validationtype=='required'">{{fieldname.label}}:</label>
                                              <label for="projectlistdd" v-else>{{fieldname.label}}:</label>
                                              <span v-if="fieldname.fieldtype=='text'">
                                                  <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                  @blur="validatelog($event)"
                                                  v-bind:class="{ 
                                                  'form-control': true,
                                                  '': !validText(fieldsnamevalues[fieldname.id])}"
                                                  @close="validatelog($event)"
                                                  v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled/>
                                                  
                                                  <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name="" :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                  @blur="validatelog($event)"
                                                  v-bind:class="{ 
                                                  'form-control': true,
                                                  '': !validText(fieldsnamevalues[fieldname.id])}"
                                                  @close="validatelog($event)"
                                                  v-else />
                                                  <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span>
                                                  </p>
                                              </span>
                                              <span v-if="fieldname.fieldtype=='number'">
                                                  <input :type="fieldname.fieldtype" id="fieldname" class="form-control capitalisetext" name=""
                                                  pattern="^[0-9][0-9]*$" required oninput="if(!this.value.match('^[0-9][0-9]*$'))this.value='';"
                                                  :placeholder="fieldname.fieldplaceholder" v-model="fieldsnamevalues[fieldname.id]"
                                                  @blur="validatelog($event)"
                                                  v-bind:class="{
                                                  'form-control': true,
                                                  '': !validText2(fieldsnamevalues[fieldname.id])}"
                                                  @close="validatelog($event)"
                                                  />
                                                  <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && !validText2(fieldsnamevalues[fieldname.id])">{{fieldname.fielderrormsg}}</span></p>
                                              </span>
                                              <span v-if="fieldname.fieldtype=='drop-down'">
                                                  <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="statuslist"/>
                                                  <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                              </span>
                                              <span v-if="fieldname.fieldtype=='multiselect-dropdown'">
                                                  <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="statuslist" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                  <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                              </span>
                                              <span v-if="fieldname.fieldtype=='checkbox'">
                                                  <treeselect :placeholder="fieldname.fieldplaceholder" class="projectlistdd" v-model="fieldsnamevalues[fieldname.id]" :options="fieldname.optionalvalue" @input="selectinput($event, i)" :multiple="true" :clear-on-select="true"/>
                                                  <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&(!fieldsnamevalues[fieldname.id] || fieldsnamevalues[fieldname.id].length==0)">{{fieldname.fielderrormsg}}</span></p>
                                              </span>
                                              <span v-else-if="fieldname.fieldtype=='radio'">
                                                <div class="form-group">
                                                <div class=" form-check form-check-inline">
                                                  <span v-for="(radiofieldvalues,k) in fieldname.radiooptionalvalue" v-bind:key="k"><br />
                                                          <input class="form-check-input" type="radio" v-model="fieldsnamevalues[fieldname.id]" :value="radiofieldvalues.id" > <span class="pl-1" > {{Object.values(radiofieldvalues)[0]}} </span>
                                                        </span>
                                                      </div>
                                                      <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                                    </div>
                                              </span>
                                              <span v-else-if="fieldname.fieldtype=='date'">
                                                  <date-picker :placeholder="fieldname.fieldplaceholder" type="datetime" format="YYYY-MM-DD HH:mm" v-model="fieldsnamevalues[fieldname.id]" valueType="format"
                                                  class="startdate capitalisetext" id="EndDate" :minute-step="5" :disabled-date="notBeforeToday"></date-picker>
                                                  <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' &&fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                              </span>
                                              <span v-else-if="fieldname.fieldtype=='datetime-local'">
                                                  <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-if="(fieldname.validationtype=='readonly' || fieldname.validationtype=='hidden')" disabled></vue-timepicker>
                                                  <vue-timepicker class="" type="time" id="qahours" format="HH:mm" v-model="fieldsnamevalues[fieldname.id]" v-else></vue-timepicker>
                                                  <p><span class="errorinputmsg capitalisetext"  v-if="fieldname.validationtype=='required' && fieldsnamevalues[fieldname.id]==null">{{fieldname.fielderrormsg}}</span></p>
                                              </span>
                                          </div>
                                      </div>
                                      <div class="col-12 mg-top1 text-right">
                                          <button type="button" class="btn btn-relief-primary mr-1" @click="saveData()" v-bind:disabled="disblesavebtn">Submit</button>
                                          <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                                      </div>

                                    </div>

                                    <div class="row" v-if="queansSubList.length>0">
                                      <table aria-describedby="releasenoteoptn" id="releasenoteoptn"  class="table table-sm dataTableListing table-hover table-striped table-bordered">
                                          <thead class="d-none"> <th scope="col"></th></thead>
                                            <tbody>
                                                <tr v-for="(v, i) in  queansSubList" >
                                                    <td class="text-left" style="min-width:350px">
                                                        {{v.fieldname}}
                                                    </td>
                                                    <td class="text-center tast_case_groupingview_readmore">
                                                        <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                            <div class="custom-container case_bg_17">
                                                                <div v-bind:class="[readmore]" :id="'cycle_readmore'+i">
                                                                    <span class="fc_m16_btcode_text" v-html="formatData(v.fieldvalue)"></span>
                                                                </div>
                                                                <div class="cycle_viewmoretext" v-if="v.fieldvalue.length > 150">
                                                                    <a class="btn">
                                                                    <strong :id="i" @click="idclick(i)">
                                                                        Read More
                                                                    </strong>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
              </div>

              </div>
           </div>
           <div class="pageCenter text-center">
           </div>
         </div>
       </div>

      </section>
   </div>
   <!-- <div v-else class="w-100 text-center">
       <h2 class="mb-1"> You are not authorized! 🔐 </h2>
       <p class="mb-2"> You don’t have permission to access this page!! </p>
       <img  src="../../../public/images/pages/not-authorized.e9c47c33.svg" alt="Not authorized page" class="img-fluid" />
   </div> -->
 </div>
</template>

<script>
import axios from 'axios';
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { SearchIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Treeselect from '@riophae/vue-treeselect'
import moment from 'moment';
import {Trash2Icon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
 name: 'RNFeedbackForm',
 components: {
   VueElementLoading,
   Loading,
   SearchIcon,
   XIcon,
   ExternalLinkIcon,
   Treeselect,
   DatePicker,
   Trash2Icon,
 },
 mixins: [ commonMethods ],

 data() {
   return {
     isLoading: false,
     missedDedline: false,
     undt: null,
     tokendata: null,
     isSearchRequestActive:false,
     card_content:'card-content',
     collapse:'collapse',
     addformbtntxt:'Next',
     backpagedata: null,
     companycode : apiUrl.companycode,
     pagefeatures: [],
     isIssueActive:true,
     bdeatilsflag: true,
     customerFeedbackRP: apiUrl.CustomerFeedbackRP,
     requesterArr : [],
     fieldnamelist: [],
     fieldsnamevalues:{},
     disblesavebtn:false,
     formvisiablity : true,
     urlqryId: null,
     feedbackdetails: [],
     btcTaskDetails: [],
     queansSubList: [],
     readmore:'outer',
     feedbackdeadline: null,
     todaysdate: new Date(),
   }
 },
 created() {
   this.pagefeatures = {};
   if(window.localStorage.getItem("pagefeatures")){
     var pagefeatures1 = JSON.parse(
       window.atob(window.localStorage.getItem("pagefeatures"))
     );
     pagefeatures1.forEach((element) => {
       if (element.page === "/project") {
         this.pagefeatures[element.featurename] = element.featureaccess;
       }
     });
   }
 },
 mounted() {
   let userdata = window.localStorage.getItem('userdata');
   if(userdata){
     userdata = JSON.parse(userdata)
     this.undt = userdata
     this.tokendata = window.localStorage.getItem('token');
     this.userrole = window.localStorage.getItem("userrole");
    // this.verifyLoggedInUserForPS(this.urlId)
    this.convertQryString();
   }
 },
 methods: {  
      dateMonthFormat(value) {
          if (!value) return "";
          return moment(String(value)).format('DD MMM YYYY');
      },
      formatData(data) {
            return data
      },
      convertQryString() {
        if(this.$route.query.fid == undefined) {
          this.$router.push('/login')
        }
        let getqrystring = this.$route.query.fid;
        this.urlqryId = Buffer.from(getqrystring, 'base64').toString();
        if(this.urlqryId == null || this.urlqryId == '') {
          this.$router.push('/login')
        }
        this.getFeedbackDetails(this.urlqryId);
        this.getorientationfeedbackformlabels();
      },
      idclick(indexx){
          if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
              $("#"+indexx).html("Read More");
              $("#cycle_readmore"+indexx).removeClass("readmore");
              this.readmore = 'outer'
          } else {
              $("#"+indexx).html("Read Less");
              $("#cycle_readmore"+indexx).addClass("readmore");
              this.readmore = 'outer readmore'
          }
      },
      idclick5(){
          
          if ($("#cycle_readmore5").hasClass("readmore")) {
              $("#id5").html("Read More");
              $("#cycle_readmore5").removeClass("readmore");
              this.readmore = 'outer'
          } else {
              $("#id5").html("Read Less");
              $("#cycle_readmore5").addClass("readmore");
              this.readmore = 'outer readmore'
          }
      },
      getFeedbackDetails(id) {
          if(id) {
              axios({
                    method: "POST",
                    url: "api/releasenote/getFeedbackDetails",
                    headers: { authorization : this.tokendata },
                    data:{ fid: id, useremail: this.undt.username, empcode: this.undt.userid }
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                      this.feedbackdetails = result.data.data.rows;
                      this.btcTaskDetails = result.data.data.taskdetails;
                      if(this.feedbackdetails && this.feedbackdetails.length>0 && this.feedbackdetails[0].empid != this.undt.userid) {
                          Swal.fire({
                              title: "Failed",
                              text: "Your are not authorised!",
                              icon: "info",
                              customClass: {
                              confirmButton: "btn btn-primary",
                              },
                              buttonsStyling: !1,
                          });
                      }
                      
                      if(this.feedbackdetails && this.feedbackdetails.length>0) {
                        this.feedbackdeadline = this.formatDate(this.addDays(new Date(this.feedbackdetails[0].lastmodifieddate), apiUrl.FDeadlineDate));
                      }
                      
                      if(this.feedbackdetails && this.feedbackdetails.length>0 && this.feedbackdetails[0].status == 'completed') {
                        this.getFormSubmittedDetails(this.urlqryId);
                      }
                    }else{
                      Swal.fire({
                          title: "Failed",
                          text: "Your are not authorised!",
                          icon: "info",
                          customClass: {
                          confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      });
                      this.$router.push('/login')
                      this.feedbackdetails = []
                      this.btcTaskDetails = []
                    }
                }).catch(e => {
                    this.displayError(e)
            });
          }
      },
      addDays(date, days) {
          var result = new Date();
          result.setDate(date.getDate() + days);
          return result;
      },
      formatDate(date) {
          return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
      },
      validatelog: function(){
          var isValid=true;

          for (let i=0; i<this.fieldnamelist.length; i++) {
              if (this.fieldnamelist[i].validationtype=='required') {
              if (this.fieldsnamevalues[this.fieldnamelist[i].id] == '' || this.fieldsnamevalues[this.fieldnamelist[i].id] == null || this.fieldsnamevalues[this.fieldnamelist[i].id] == []) {
                      isValid=false;
                      if (this.fieldnamelist[i].fieldtype=='datetime-local' && this.fieldsnamevalues[this.fieldnamelist[i].id] == '') {
                          Swal.fire({
                              title: "Failed",
                              text: this.fieldnamelist[i].label+' '+'is required',
                              icon: 'info',
                              customClass: {
                                  confirmButton: "btn btn-primary"
                              },
                              buttonsStyling: !1
                          })
                      }
                  }
              }
              
              if (this.fieldnamelist[i].fieldtype=='datetime-local') {

                  if (typeof this.fieldsnamevalues[this.fieldnamelist[i].id] == undefined || typeof (this.fieldsnamevalues[this.fieldnamelist[i].id]) == 'undefined') {
                      isValid=false;
                  }else{
                      let hours = this.fieldsnamevalues[this.fieldnamelist[i].id]
                      let isValidHours = Boolean(hours) && Boolean(hours.HH) && Boolean(hours.mm)
                          if (this.fieldsnamevalues[this.fieldnamelist[i].id].HH=='' || this.fieldsnamevalues[this.fieldnamelist[i].id].mm=='') {
                              Swal.fire({
                                  title: "Failed",
                                  text: this.fieldnamelist[i].label+' '+'required in HH:mm format value',
                                  icon: 'info',
                                  customClass: {
                                      confirmButton: "btn btn-primary"
                                  },
                                  buttonsStyling: !1
                              })
                          }
                      if(typeof hours === 'string' && hours.length === 5){
                          isValidHours = true
                      }
                      if(isValidHours && hours !== null && typeof hours === 'object') {
                          this.fieldsnamevalues[this.fieldnamelist[i].id] = `${hours.HH}:${hours.mm}`
                      }
                  }
              }
          }
          return isValid
      },
      saveData(){
        let valid = this.validatelog();
        
        if(valid){ 
          this.input={
            useremail: this.undt.username,
            empcode: this.undt.userid,
          }
          this.input.trackerid = this.customerFeedbackRP
          this.input.fieldvalues = this.fieldsnamevalues
          this.input.fid = this.urlqryId
          this.input.depdate = (this.feedbackdetails && this.feedbackdetails.length>0)?this.feedbackdetails[0].lastmodifieddate:null

          this.isLoading=true
          axios({
            method: "POST",
            url: 'api/rnfeedback/savefeedback',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              if(result.data.data && result.data.data.status && result.data.data.status == 'missed deadline') {
                this.missedDedline = true;
                this.feedbackdeadline = this.dateMonthFormat(result.data.data.fdate);
              }

              this.getFeedbackDetails(this.urlqryId);
              this.getFormSubmittedDetails(this.urlqryId);
              
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: 'success',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            } else {
              Swal.fire({
                title: "Failed!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
          }).catch(e => {
              this.displayError(e)
          });
        }      
      },
      getFormSubmittedDetails(id) {
        this.isLoading = true;
        let url = "api/issue/getConfigurationFormDetails"
        this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empfid: id,
        };
        axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.queansSubList = result.data.data[0]
                for (const config of this.queansSubList) {
                    if (config.fieldvalue == "1") {
                        config.fieldvalue = "Yes"
                    } else if (config.fieldvalue == "0") {
                        config.fieldvalue = "No"
                    }
                }
            }else{
                this.queansSubList = [];
            }
        }).catch(e => {
            this.displayError(e)
        });
      },
      IssuesummanryCollapse:function(){
        this.isIssueActive=!this.isIssueActive
      }, 
      BDetailsCollapse:function(){
        this.bdeatilsflag=!this.bdeatilsflag
      },   
      verifyLoggedInUserForPS :function(pid) {
        this.isLoading = true;
        let url = "api/projectmodule/verifyLoggedInUserForPS";
        this.statusinput = {
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: this.undt.userid,
          projectid: parseInt(pid),
        };
        axios({
          method: "POST",
          url: url,
          data: this.statusinput,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.data.rows[0].empid==null) {
            this.$router.push('/login')
            Swal.fire({
                title: "Failed!",
                text: "Your are not authorised user to view this page",
                icon: "info",
                customClass: {
                confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            })
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      validText : function(inputval) {
        let re = /[A-Za-z0-9].{4,}/;
        if(inputval){
            return re.test(inputval.toLowerCase());
        }
      },
      validText2 : function(inputval) {
          var re = /[A-Za-z0-9].{1,}/;
          if(inputval){
              return re.test(inputval.toLowerCase());
          }
      },
      validText1: function (inputval) {
        let re = /[A-Za-z0-9].{1,}/;
        if (inputval) {
            return re.test(inputval.toLowerCase());
        }
      },
      getorientationfeedbackformlabels() {
          this.isLoading = true
          let url = 'api/feedbackform/getorientationfeedbackformlabels'
          let payload = new Object({
              empcode: this.undt.userid,
              useremail : this.undt.username,
              moduletracker: this.customerFeedbackRP,
          })
          axios({
              method: 'POST',
              url,
              data: payload,
              headers:{ authorization :this.tokendata }
          }).then(result => {
                  this.isLoading = false
                  if(result.data.errorCode == 0) {
                      this.fieldnamelist = result.data.data;
                      for (let i = 0; i < this.fieldnamelist.length; i++) {
                          if (this.fieldnamelist[i].fieldtype=="checkbox") {
                              let tempdata = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata[j] = {}
                                      tempdata[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['optionalvalue']=tempdata
                              }
                          }
                          if (this.fieldnamelist[i].fieldtype=="radio") {
                              let tempdata2 = [];
                              for (let j = 0; j < this.fieldnamelist[i].fieldvalues.length; j++) {
                                  if (this.fieldnamelist[i].fieldvalues[j].optionvalue.length>0) {
                                      tempdata2[j] = {}
                                      tempdata2[j].id=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                      tempdata2[j].label=this.fieldnamelist[i].fieldvalues[j].optionvalue
                                  }
                                  this.fieldnamelist[i]['radiooptionalvalue']=tempdata2
                              }
                          }
                      }
                  } else if(result.data.errorCode == 3){
                      Swal.fire({
                          title: "Session Expired...!!",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      }).then(function() {
                          window.location.href = "/#/login";
                      })
                  } else {
                      this.fieldnamelist = [];
                  }
              }).catch(e => {
                  this.displayError(e)
              })
      },
   

 }
}
</script>
<style> 
.view_bg-gradient-primary{
 background: #7DA3F9!important;
}
.project_summarysviews001 .card .card-header.filter_rotation_onlick{
 background:#fff!important;
}
.project_summarysviews001 .card .card-header.filter_rotation_onlick.filter_card_searchbggss{
 background: linear-gradient(135.23deg, #2E85BE -1.94%, #95DAF4 102.64%)!important;
}
.dark-layout .project_summarysviews001 .card .card-header.filter_rotation_onlick{
 background:#2f374c!important;
 border-radius: 0px;
}
.tast_case_groupingview_readmore{
      text-align: left;
  }

</style>