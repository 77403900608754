<template>
    <div>
        <loading
          :active.sync="isLoading"
          :can-cancel="true"
          :is-full-page="fullPage"
          color="orange"
          loader="dots"
          :width="64"
          :height="64"
        ></loading>
        <div class="app-content content devcontent ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper">
                <div class="content-header row">
                    <div class="content-header-left col-md-9 col-12 mb-1">
                        <div class="row breadcrumbs-top">
                            <div class="col-12 mobile_view_padd-05">
                                <h3 class="content-header-title float-left mb-0">HOD Approve Details</h3>
                                <div class="breadcrumb-wrapper">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/mydashboard'>My Dashboard</router-link></li>
                                        <li class="breadcrumb-item"><router-link to='/trs/hodtravelreqlist'>Travel Request Approval</router-link></li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-body">
                   <div class="row">
                        <div class="col-12 mobile_view_padd-05">
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" v-on:click="mysearchrequestollapse" style="cursor:pointer;">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                            <div class="w-auto px-1 mobile-padding-0-2">
                                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                    <div class="avatar-content">
                                                        <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                                    </div>
                                                </div>
                                                <h4 class="card-title d-inline-flex ml-1" >Search Request</h4>
                                            </div>
                                           
                                            <div class="ml-auto d-flex">
                                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                    <em class="las la-angle-down"></em>
                                                </span>
                                            </div>
                                        </div>
                                    </div>    
                                </div>
                                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-4 col-xs-12 mb-1">
                                                <label class="form-label">Request ID     </label>
                                                <input class="form-control" type="text" placeholder="Enter Request ID" v-model="search.requestid"/>
                                            </div>
                                            <div class="col-md-4 col-sm-4 col-xs-12 common_trs_buttons_res">
                                                <button type="submit" class="btn btn-primary mt-2 mr-1" @click="getHODList(search.requestid)" >Search</button>
                                                <button type="reset" class="btn btn-outline-secondary mt-2" @click="resetRecords()">Cancel</button>                                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card border-warning filter_card_searchbgst">
                                <div class="card-header filter_rotation_onlick" style="cursor:pointer;" v-on:click="mydetailsollapse">
                                    <div class="container-fluid" >
                                        <div class="row" >
                                                <div class="w-auto px-1 mobile-padding-0-2">
                                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                                        <div class="avatar-content">
                                                    <clipboard-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></clipboard-icon>
                                                    </div>
                                                    </div>
                                                    <h4 class="card-title d-inline-flex ml-1">HOD Approve Details</h4>
                                                </div>
                                    
                                                <div class="ml-auto d-flex">
                                                    <span class="rotetions_view_arrow ml-auto mx-1"> 
                                                        <em class="las la-angle-down"></em>
                                                    </span>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-bind:class="[{show: isDetActive},card_content,collapse]" style="margin-top:0px;">
                                    <div class="card-body px-0 pt-0">
                                        <div class="row mx-0">
                                            <div class="table-responsive" v-if="showtabledata">
                                                <table aria-describedby="mydesc" class="table table-sm dataTableListing table-striped theade_padding_y10">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Request ID</th>
                                                            <th class="text-left" scope="col">Travel Name</th>
                                                            <th class="text-left" scope="col" style="width:180px;">From City</th>
                                                            <th scope="col" style="width:180px;">To City</th>
                                                            <th scope="col" style="width:120px;">Group Travel</th>
                                                            <th scope="col" style="width:100px;">Mode of Transport</th>
                                                            <th scope="col" style="min-width:100px;">Start Date</th>
                                                            <th scope="col" style="min-width:100px;">End Date</th>
                                                            <th scope="col" style="min-width:50px;">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(data,index) in requestList" v-bind:key="index">
                                                            <td>{{data.ticketid}}</td>
                                                            <td class="text-left">{{data.reqname}}</td>
                                                            <td class="text-left">{{data.fromcityname}}</td>
                                                            <td>{{data.cityname}}</td>
                                                            <td>
                                                                <span v-if="data.isgrouptravelreq == 1 && data.nameofgrouptravel !=null">  
                                                                    <eye-icon size="1.5x" class="custom-class" @click="getGroupTravelList(data.nameofgrouptravel)"></eye-icon>
                                                                </span>
                                                                <span v-else>N/A</span>
                                                            </td>
                                                            <td class="tast_case_groupingview_ hod-readmore">
                                                            
                                                                <div class="col-md-12 mt-1 tast_case_groupingview_readmore">
                                                                    <div class="custom-container case_bg_17">
                                                                        <div class="outer" :id="'cycle_readmore'+index">
                                                                            <span class="fc_m16_btcode_text"  v-html="formatData(data.transportmode)"></span>
                                                                        </div>
                                                                        <div class="cycle_viewmoretext" v-if="data.transportmode && data.transportmode.trim().length > 40">
                                                                            <a class="btn">
                                                                            <strong :id="index" @click="idclick(index)">
                                                                                Read More
                                                                            </strong>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </td>
                                                            <td>{{data.traveldate}}</td>
                                                            <td>{{data.appointmentdate}}</td>
                                                            <td>
                                                                <div class="transaction-item view_request_buttons_view view_request_buttons_view_dotteds01">
                                                                    <div class="chart-dropdown">
                                                                      <b-icon icon="three-dots-vertical" class="cursor" font-scale="1.6" data-toggle="dropdown"></b-icon>
                                                                    <div class="dropdown-menu dropdown-menu-right view_request_buttons_view_dotteds">
                                                                        <a type="text" class="dropdown-item" @click="changeStatus(data,2,'approved')">
                                                                            <span class="checkCircleIcon_approved" style="top: 1px;">  
                                                                                <b-icon icon="check2" class="cursor" font-scale="1.2"></b-icon>
                                                                            </span>Approve
                                                                        </a>
                                                                        <a type="text" class="dropdown-item" @click="changeStatus(data,3,'rejected')">
                                                                            <span class="checkCircleIcon_approved">  
                                                                              <b-icon icon="x-lg" class="cursor" font-scale="0.8"></b-icon>
                                                                            </span> Reject
                                                                        </a>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                         <tr v-if="requestList.length ==0" >
                                                            <td colspan="9">No Record Found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pageCenter pt-0 text-center">
                                    <pagination class="pull-right" v-if="showtabledata" v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="grouptravelist" tabindex="2" role="dialog" aria-labelledby="grouptravelTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document" style="max-width:900px !important;">
                <div class="modal-content">
                    <div class="modal-header bg_background_popups_top">
                        <h5 class="modal-title" id="EmploadDetails">Group Employee Travel List</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body bg_background_popups">
                        <div class="row match-height">
                            <div>
                                <b-table striped hover :items="groputravelinfo"></b-table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import moment from 'moment'
    import { PlusIcon, PocketIcon,CodepenIcon,AtSignIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,CheckSquareIcon,
    MessageCircleIcon,XSquareIcon,
    SearchIcon,PlusCircleIcon,ClipboardIcon,UserIcon,MapPinIcon,InfoIcon,MoreVerticalIcon,EditIcon,EyeIcon,UploadIcon,PowerIcon,PlusSquareIcon,MinusSquareIcon,DownloadIcon } from 'vue-feather-icons'
    // Import component
    import Loading from 'vue-loading-overlay';
    //import DatePicker from 'vue2-datepicker';
     // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    
    import Pagination from "vue-pagination-2";
    import apiUrl from "../../../constants";
    //import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    
    export default({
        name:'ReportRequest',
        
        data() {
            return {
                isBothDateSelected:false,
                ticketdetails:[],
                isLoading: false,
                fullPage: true,
                search:{
                    requestid:''
                },
                errors:{
                    fromdate:'',
                    todate:'',
                    status:''
                },
                emptypeBlured:false,
                pagefeatures:[],
                requestList:[],
                groputravelinfo:[],
                isDetActive:true,
                isRequestActive:false,
                isSearchRequestActive:false,
                card_content:'card-content',
                collapse:'collapse',
                isActiveEndDate:false,
                showmanager:true,
                transportList:[],
                 totalcount: 0,
                pageCount: 0,
                page: 1,
                pagelimit: apiUrl.LIMIT,
                currPageNum: 0,
                pageoffset: 1,
                showtabledata:true,
                empid: '',
                emailaddress:'',
                tokendata:null,
            }
        } ,
        components: {
            PocketIcon,CodepenIcon,AtSignIcon,PhoneIcon,AwardIcon,CalendarIcon,MapIcon,
            EditIcon,CheckSquareIcon,MessageCircleIcon,XSquareIcon,
            EyeIcon,
            MoreVerticalIcon,
            Loading,
            PlusIcon,
            SearchIcon,
            PlusCircleIcon,
            ClipboardIcon,
            UserIcon,
            MapPinIcon,
            InfoIcon,
            UploadIcon,
            PowerIcon,
            PlusSquareIcon,
            MinusSquareIcon,
            DownloadIcon,
            Pagination,
        },
        created() {
            this.pagefeatures = {}
            let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
            pagefeatures1.forEach(element => {
            if (element.page === '/travelrequest') {
                this.pagefeatures[element.featurename] = element.featureaccess
            }
            })
        },
        mounted(){
            let userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.tokendata = window.localStorage.getItem("token");
                this.username = userdata.username
                this.emailaddress = userdata.emailaddress
                this.empid = userdata.userid
                this.loguser = userdata.firstname+' '+userdata.lastname
                this.getHODList(null,0)
            }
            if (feather) {
                feather.replace({
                    width: 14,
                    height: 14
                });
            }
           
        },
        
        methods:{
            formatData(data) {
                console.log("data",data,data.length);
                return data
            },
            changeStatus(data,changeto,operation){
                $(document).off('focusin.modal');             
                Swal.fire({
                    title: "Are you sure?",
                    text: "Are you sure you want to "+operation+" this travel request?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText:'No, go back',
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, proceed!",
                    }).then(async(result) => {
                    if (result.isConfirmed) {
                        let payload = {                        
                            status: changeto,
                            ticketid: data.ticketid,
                            id: data.id,
                            remark:'HOD has '+operation+' the request',
                            nextlevel: 'no',
                            createdby: this.emailaddress,
                            operation: operation,
                            createdname:this.loguser,
                            empcode:this.empid,
                            useremail: this.username
                        }
                        let hodpayload = {
                            hodid:this.empid,
                            ticketid: data.ticketid,
                            empcode:this.empid,
                            useremail: this.username
                        }
                        await this.getHODTravelTicketDetails(hodpayload).then(async(result) => {
                            if(result.data.status) {
                                if(result.data.data.length ==1 && [2,3].includes(changeto)) {
                                    let payload2 = {
                                        assignedto:5,
                                        ticketid: data.ticketid, 
                                        empcode: this.empid,
                                        useremail: this.username
                                    }
                                    await this.getTravelTicketStatus(payload2).then((result) => {
                                        let ticketdetailsinfo = result.data.data
                                        if(result.data.status && ((ticketdetailsinfo.includes(0) || ticketdetailsinfo.includes(2)) && [2,3].includes(changeto)) || (changeto ==2)){
                                            payload.nextlevel = 'yes'
                                            payload.nextl1name = data.nextleveluser
                                            payload.nextl1mail = data.nextlevelmail
                                        } else if((result.data.status && ticketdetailsinfo.every(element => element === 3) && changeto ==3) || (changeto ==3 && ticketdetailsinfo.length ==1)) {
                                            payload.nextlevel = 'no'
                                        }
                                    }).catch((error) =>{
                                        Swal.fire({
                                            title: "Failed!",
                                            text: error,
                                            icon: 'info',
                                            customClass: {
                                                confirmButton: "btn btn-primary"
                                            },
                                            buttonsStyling: !1
                                        })
                                    })
    
                                }
                            }
                        }).catch((error) =>{
                            Swal.fire({
                                title: "Failed!",
                                text: error,
                                icon: 'info',
                                customClass: {
                                    confirmButton: "btn btn-primary"
                                },
                                buttonsStyling: !1
                            })
                        })   
                        const { value: text } = await Swal.fire({
                            input: 'textarea',
                            inputLabel: 'Comment',
                            inputPlaceholder: 'Please comment here...',
                            inputAttributes: {
                                'aria-label': 'Please comment here'
                            },
                            showCancelButton: true,
                            customClass: {
                                validationMessage: 'my-validation-message'
                            },
                            preConfirm: (value) => {
                                if (!value) {
                                Swal.showValidationMessage(
                                    '<em class="fa fa-info-circle"></em> Comment is required'
                                )
                                }
                            }
                        })
                        if (text) {
                            payload.hod_feedback = text
                            this.updateStatus(payload)
                        }
                    } 
                });
            },
            idclick(indexx){
                if ($("#cycle_readmore"+indexx).hasClass("readmore")) {
                    $("#"+indexx).html("Read More");
                    $("#cycle_readmore"+indexx).removeClass("readmore");
                } else {
                    $("#"+indexx).html("Read Less");
                    $("#cycle_readmore"+indexx).addClass("readmore");
                }
            },
            getTravelTicketStatus(payload) {
                return axios({
                    'method': 'POST',
                    'url': "api/reimbursement/getTravelTicketStatus",
                    'data':payload,
                    headers: { authorization: this.tokendata },
                })
            },
            getHODTravelTicketDetails(payload) {
                return axios({
                    'method': 'POST',
                    'url': "api/listing/gethodtravelpendinglist",
                    'data':payload,useremail: this.username,
                    headers: { authorization: this.tokendata },
                })
            },
            updateStatus(payload) {
                this.apiURL="api/reimbursement/updateTicketStatusByHOD"
                 axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':payload,useremail: this.username,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.pageoffset =1
                        this.getHODList(null,'')
                        this.$forceUpdate()
                    }else{
                        Swal.fire({
                            title: "Failed!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }                
                })
            },
            getGroupTravelList(groupemp) {
                this.apiURL="api/listing/getemployeedetails"
                this.input={
                    empid: groupemp,
                    empcode: this.empid,
                    useremail: this.username
                }
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {
                    this.groputravelinfo=[]        
                    if(result.data.status){
                        this.groputravelinfo = result.data.data;
                        $("#grouptravelist").modal('show')
                        
                    }else{
                        this.groputravelinfo=[]
                        Swal.fire({
                            title: "Info!",
                            text: "Data Not Found",
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                })
            },
            clickCallback: function(pageNum) {
                this.pageoffset=pageNum
                this.currPageNum = pageNum - 1
                let payload= null
                let isSearch = this.search.fromdate || this.search.todate || this.search.status
                if(isSearch){
                    payload = {
                        fromdate:this.search.fromdate,
                        todate:this.search.todate,
                        status:this.search.status,
                        empcode:this.empid,
                        useremail: this.username
                    }
                }
                this.getHODList(payload,0)
            },
            getHODList(requestid){
                this.apiURL="api/listing/gethodlist"
                this.input={
                    offset:this.pageoffset,
                    limit: apiUrl.LIMIT,
                    empcode: this.empid,
                    useremail: this.username
                }
                
                if(requestid) 
                this.input.ticketid = this.search.requestid
                
                if(this.empid) 
                this.input.hodid = this.empid
    
                this.isLoading= true;
                axios({
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':this.input,
                    headers: { authorization: this.tokendata },
                })
                .then(result => {             
                    if(result.data.status){
                        this.isLoading= false;
                        this.requestList = result.data.data.responsedata;
                        this.totalcount = result.data.data.count
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }else{
                        this.requestList = []
                        this.isLoading= false;
                        this.totalcount = 0
                        this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                    }
                })
            },
            resetRecords: function () {
                this.search.requestid = null;
                
                localStorage.removeItem('searchItems')
                this.getHODList(null,0);
            },
            notBeforeToday: function (date) {
                this.search.todate = "";
                return date > new Date(new Date().setHours(0, 0, 0, 0));
                },
                disabledBefore(date) {
                let dayBefore = moment(this.search.fromdate).format(this.TimeFormat);
                const beforeToday = new Date(dayBefore);
                        beforeToday.setHours(0, 0, 0, 0);
                return date < beforeToday; // Assume < 25 May Not Selected
                },
            dateFormat(date) {
                if (!date) return '';
                return moment(date).format(this.TimeFormat);
            },
            onCancel() {
                console.log('User cancelled the loader.')
            },
            selectedfromdate(){
                if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
                    this.isBothDateSelected = false
                }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
                    this.isBothDateSelected = true
                }
            },
            selectedtodate(state){
                if((this.search.fromdate && this.search.todate) || (!this.search.fromdate && !this.search.todate)){
                    this.isBothDateSelected = false
                }else if((this.search.fromdate && !this.search.todate) ||( !this.search.fromdate && this.search.todate)){
                    this.isBothDateSelected = true
                }
                if(state && !this.search.status){
                    this.errors.status = "Status is required"
                }
            },
            selectstatus(state, value) {
                this.search.status = null
                this.search.status = state
                if(!state) {
                    this.errors.status = "Status is required"
                } else {
                    this.errors.status = null
                }
            },
                  
            mydetailsollapse:function(){
                this.isDetActive=!this.isDetActive
            },
            mysearchrequestollapse:function(){
                this.isSearchRequestActive=!this.isSearchRequestActive
            },
            
    
           
        }
    })
    </script>
    <style scoped>
    .display{
        display: none;
    }
    .mx-datepicker{
        width:100%!important;
    }
    
    .cycle_viewmoretext a{
      text-align: left;
    }
    .cycle_viewmoretext a strong{
            color: blue;
    }
    
    </style>
    