<template>
    <div class="app-content content">
        <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"  color="orange"  loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="modern-horizontal-wizard">
                <div class="card border-warning filter_card_searchbgst">
                    <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                        <div class="container-fluid">
                            <div class="row row fc_my-05">
                                <div class="w-auto px-1 mobile-padding-0-2 d-flex align-items-center">
                                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                                        <div class="avatar-content">
                                            <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                                        </div>
                                    </div>
                                    <h4 class="card-title d-inline-flex ml-1">Search Employee Line of Code Details</h4>
                                </div>
                                <span class="rotetions_view_arrow ml-auto mx-1"> 
                                    <em class="las la-angle-down"></em>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[{show: isRequestActive},card_content,collapse]">
                        <div class="card-body px-sm-mobile-10">
                            <div class="row">
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Repo Name</label>
                                    <treeselect class="projectmdllistdd" placeholder="Select Repo Name" v-model="search.project" :options="search.projectlist"  @input="selissuecsproject" :multiple="true" :clear-on-select="true" />
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                    <label class="form-label lable-left">Author Email</label>
                                    <treeselect class="projectmdllistdd" placeholder="Enter Min 3char Of Author Email" v-model="search.authoremail" :options="approvalmanager" :multiple="true" :clear-on-select="true" v-on:search-change="getAuthorEmailList"/>
                                </div>
                                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-0">
                                    <label for="">Commit Date From</label>
                                    <date-picker placeholder="Select Commit Date From" v-model="search.createddatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                                    <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div> 
                                </div>
                                <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4 mb-0">
                                    <label for="">Commit Date To</label>
                                    <date-picker placeholder="Select Commit Date To" v-model="search.createddateto" :disabled-date="disabledBefore" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 text-right mb-0">
                                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getlocreportlist(search,1)"> 
                                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                                    <button type="move" class="btn btn-relief-primary mt-2" :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" card border-warning"  style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-top:1rem; margin-bottom: 1rem !important;" id="reportTopScroll">
                    <div class="show card-content collapse">
                        <div class="row">
                            <div class="col-md-12 1">
                                <div class="card ">
                                    <div class="">
                                        <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div class="row" id="table-small">
                                            <div class="col-12">
                                                <div class="card">
                                                <div class="table-responsive" >
                                                    <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" class="text-center">#</th>
                                                            <th scope="col" class="text-center">Repo Name</th>
                                                            <th scope="col" class="text-center">Author Name</th>
                                                            <th scope="col" class="text-center">Author Email</th>
                                                            <th scope="col" class="text-center">Commit Date</th>
                                                            <th scope="col" class="text-center">LOC Added</th>
                                                            <th scope="col" class="text-center">LOC Deleted</th>
                                                            <th scope="col" class="text-center">LOC Commented</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <template  v-for="(data, index) in reportlist">        
                                                            <tr v-bind:key="index">
                                                                <td class="text-center">{{index+1+currPageNum*pagelimit}}</td>
                                                                <td class="text-center">{{data.name}}</td>
                                                                <td class="text-center">{{data.authorName}}</td>
                                                                <td class="text-center">{{data.authorEmail}}</td>
                                                                <td class="text-center">{{data.comitdate}}</td>
                                                                <td class="text-center">{{data.locadded}}</td>
                                                                <td class="text-center">{{data.locdeleted}}</td>
                                                                <td class="text-center">{{data.loccommented}}</td>
                                                                
                                                            </tr>
                                                        </template>
                                                        <template v-if="reportlist.length == 0">
                                                            <tr><td colspan="14" style="text-align: center;" >No record found</td></tr>
                                                        </template>
                                                    </tbody>
                                                    </table>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="pageCenter text-center">
                                            <pagination  v-model="page" :records="totalcount" :per-page="pagelimit"  @paginate="clickCallback"/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,XIcon,ExternalLinkIcon,UserIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
    name:'LOCReport',
    components: {
        Loading,
        VueElementLoading,
        Treeselect,
        SearchIcon,
        XIcon,
        ExternalLinkIcon,
        Pagination,
        DatePicker,
        UserIcon
    },
    mixins: [ commonMethods ],

    data() {
        return {
            isLoading: false,
            fullPage: true,
            isRequestActive:true,
            card_content:'card-content',
            collapse:'collapse',
            tokendata:null,
            undt:[],
            isBothDateSelected:false,
            search:{
                project:null,
                projectlist:[],
                reponame:null,
                jenkinsjob:null,
                authoremail:null,
                createddateto:null,
                createddatefrom:null,
                status:null
            },
            approvalmanager:[],
            reportlist:[],
            totalcount: 0,
            pageCount: 0,
            page: 1,
            pagelimit: apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset: 1,
            glbMdl: "",
        }
    },
    mounted() {
        let permission = this.$Service.verifyAccess();
        if(permission){
            let userdata = window.localStorage.getItem("userdata");
            this.tokendata = window.localStorage.getItem("token");
            if (userdata) {
                userdata = JSON.parse(userdata);
                this.undt = userdata;
                this.getProjectAllocatedToEmp()
                this.getlocreportlist(this.search,1)
            }
            
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        notBeforeToday: function (date) {
            this.search.createddateto = null;
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        selectedfromdate(){
            if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
                this.isBothDateSelected = false
            }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
                this.isBothDateSelected = true
            }
        },
        selectedtodate(){
            if((this.search.createddatefrom && this.search.createddateto) || (!this.search.createddatefrom && !this.search.createddateto)){
                this.isBothDateSelected = false
            }else if((this.search.createddatefrom && !this.search.createddateto) ||( !this.search.createddatefrom && this.search.createddateto)){
                this.isBothDateSelected = true
            }
        },
        disabledBefore(date) {
            let dayBefore = moment(this.search.createddatefrom).format(this.TimeFormat);
            const beforeToday = new Date(dayBefore);
                    beforeToday.setHours(0, 0, 0, 0);
            return date < beforeToday; 
        },
        mysearchrequestollapse:function(){
            this.isRequestActive=!this.isRequestActive
        },
        selissuecsproject: function(state,value){
            this.search.project=null
            this.search.project=state
        },
        getProjectAllocatedToEmp() {
            this.isLoading = true
            let url = "api/Reports/getRepoName";
            this.input = {
                repoid:null,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers': { 'authorization': this.tokendata }
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.search.projectlist = result.data.data;
                } else {
                    this.search.projectlist = [];
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        getAuthorEmailList(node) {
            if(node && node.length >=3){
                // this.search.authoremail=null
                this.isLoading = true;
                axios({
                    method: "POST",
                    url: "api/Reports/getEmployeeEmailList",
                    data: { emailid: node.toLowerCase(),useremail: this.undt.username,
                    empcode: this.undt.userid},
                    'headers':{'authorization':this.tokendata}
                }).then((result) => {
                    this.isLoading = false;
                    if (result.data.errorCode == 0) {
                        this.approvalmanager = result.data.data;
                    } else {
                        this.approvalmanager = [];
                    }
                }).catch(e => {
                    this.displayError(e)
                });
            }
        },
        getlocreportlist(search,flag){
            if(flag) {
                this.pageoffset = 1
                this.reportlist = []
                this.totalcount = 0;
                this.pageCount = 0;
                this.page= 1;
            } 
            this.isLoading = true;
            let fromdate = null
            let todate = null
            let authoremail= search.authoremail?search.authoremail:null
            let repoid = search.project?search.project:null
            if(authoremail !=null && typeof authoremail =='object' && authoremail.length==0){
                authoremail = null 
            }
            if(repoid !=null && typeof repoid =='object' && repoid.length==0){
                repoid = null 
            }
            if(repoid == null && authoremail == null  && !this.search.createddateto && !this.search.createddatefrom){
                fromdate = new Date()
                todate = new Date()
                fromdate.setDate(fromdate.getDate() - 1)
                fromdate = moment(String(fromdate)).format('YYYY-MM-DD');
                this.search.createddatefrom = fromdate

                todate.setDate(todate.getDate())
                todate = moment(String(todate)).format('YYYY-MM-DD');
                this.search.createddateto = todate
            }
            this.input={
                repoid:repoid,
                authoremail:authoremail,
                datefrom:search.createddatefrom?search.createddatefrom:fromdate,
                dateto:search.createddateto?search.createddateto:todate,
                limit:apiUrl.LIMIT,
                offset:this.pageoffset,
                useremail: this.undt.username,
                empcode: this.undt.userid,
            }
            axios({
                method: "POST",
                url: "api/Reports/getLocReport",
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.reportlist = result.data.data.rows;
                    this.totalcount = result.data.data.count;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                } else if(result.data.errorCode == 3){
	                Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
              		    window.location.href = "/#/login";
            	    })
                } else {
                    this.reportlist = [];
                    this.totalcount=0
                    this.pageCount=0
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.currPageNum = pageNum - 1;
            let arrGlbMdl = this.glbMdl.split("##");
            let inputEle = "";
            let btnEle = "";
            if (arrGlbMdl.length > 0) {
                if (typeof arrGlbMdl[0] != "undefined") {
                inputEle = arrGlbMdl[0];
                }
                if (typeof arrGlbMdl[1] != "undefined") {
                btnEle = arrGlbMdl[1];
                }
            }
            this.getlocreportlist(this.search,0)
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#reportTopScroll").offset().top -70
                });
            }, 500);
        },
        resetRecords: function(){
            this.search.project=null,
            this.search.authoremail=null,
            this.search.createddateto=null,
            this.search.createddatefrom=null,
            this.search.status=null
            this.approvalmanager = [];
            this.isBothDateSelected=false
            this.getlocreportlist(this.search,1)
        },
        ExportXL(){
            this.isLoading = true;
            let fromdate = null
            let todate = null
            let authoremail= this.search.authoremail?this.search.authoremail:null
            let repoid = this.search.project?this.search.project:null
            if(authoremail !=null && typeof authoremail =='object' && authoremail.length==0){
                authoremail = null 
            }
            if(repoid !=null && typeof repoid =='object' && repoid.length==0){
                repoid = null 
            }
            if(repoid == null && authoremail == null  && !this.search.createddateto && !this.search.createddatefrom){
                fromdate = new Date()
                todate = new Date()
                fromdate.setDate(fromdate.getDate() - 1)
                fromdate = moment(String(fromdate)).format('YYYY-MM-DD');
                this.search.createddatefrom = fromdate

                todate.setDate(todate.getDate())
                todate = moment(String(todate)).format('YYYY-MM-DD');
                this.search.createddateto = todate
            }
            this.input={
                repoid:repoid,
                authoremail:authoremail,
                datefrom:this.search.createddatefrom?this.search.createddatefrom:fromdate,
                dateto:this.search.createddateto?this.search.createddateto:todate,
                useremail: this.undt.username,
                empcode: this.undt.userid,
                limit:null,
                offset:null
            }
            axios({
                method: "POST",
                url: "api/Reports/getLocReport",
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    let filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                    Swal.fire({
                        title: "",
                        text: "Data not found",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    });
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
                let ShowLabel = 'LOCReport'+filename
                let JSONData = readExportdata
                let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
                let CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#,REPO NAME,AUTHOR NAME,AUTHOR EMAIL,COMMIT DATE,LOC ADDED,LOC DELETED	,LOC COMMENTED' + '\r\n'
                }
                let row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let name = index.hasOwnProperty('name') ? index.name == null || index.name == 'NA' ? '' : index.name : '';
                let authorName = index.hasOwnProperty('authorName') ? index.authorName == null || index.authorName == 'NA' ? '' : index.authorName : '';
                let authorEmail = index.hasOwnProperty('authorEmail') ? index.authorEmail == null?'' :index.authorEmail : '';
                let comitdate = index.hasOwnProperty('comitdate') ? index.comitdate == null || index.comitdate  : '';
                let locadded = index.hasOwnProperty('locadded') ? index.locadded == null || index.locadded == 'NA' ? '' : index.locadded : '';
                let locdeleted = index.hasOwnProperty('locdeleted') ? index.locdeleted == null?'' :index.locdeleted : '';
                let loccommented = index.hasOwnProperty('loccommented') ? index.loccommented==null?'':index.loccommented : '';
                    row += '"' + srno + '",',
                    row += '"' + name + '",',
                    row += '"' + authorName + '",',
                    row += '"' + authorEmail + '",',
                    row += '"' + comitdate+ '",',
                    row += '"' + locadded + '",',
                    row += '"' + locdeleted + '",',
                    row += '"' + loccommented+ '",',
                    row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                return;
                }
                let fileName = 'EmployeeLineOfCodeReport'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                let link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export Report successful",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });   
            }
        },
        export (data, filename, mime) {
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        },
    }
}
</script>