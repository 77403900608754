<template>
  <div class="app-content content ">
   <loading
     :active.sync="isLoading"
     :can-cancel="false"
     :is-full-page="true"
     color="orange"
     loader="dots"
     :width="100"
     :height="100"
   ></loading>
   <div class="content-overlay"></div>
   <div class="header-navbar-shadow"></div>
   <div class="content-wrapper">
     <section id="card-navigation modern-horizontal-wizard">
       <div class="content-header-left mb-1 pt-0 mobile-padding-0-10">
         <div class="container-fluid px-0">
           <div class="row breadcrumbs-top">
             <div class="col-md-9 content-header-left">
               <h3 class="content-header-title float-left mb-0">Project API Configuration</h3>
               <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                   <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link :to="{ name: 'ProjectApiConfigList', params: { search: true }}"
                    >Project API Configuration</router-link
                    >
                  </li>
                     <li class="breadcrumb-item"><router-link to='/projectapiconfig/create'>Create Project API Configuration</router-link></li>
                 </ol>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card">
         <div class="card-body p-1">
           <form class="validate-form">
             <div class="row">
               <div class="col-12 ">
                 <template v-for="(formconfigure, index) in formconfigure.formdata">
                   <div v-bind:key="index" class="card border-warning" style=" margin-bottom: 1rem !important;">
                     <div class="card-header p-1 cursor-pointer" v-on:click="handleCollapse(index)" style="background:#EDF4FF">
                       <h4 class="card-title text-primary" >Add Project API Configuration</h4>
                       <div class="heading-elements text-primary" >
                         <ul class="list-inline mb-0">
                           <li>
                             <a data-action="collapse" v-if="!formconfigure.showform"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                             <a data-action="collapse" v-else><minus-icon size="4x" class="custom-class"></minus-icon></a>
                           </li>
                         </ul>
                       </div>
                     </div>
                     <div v-bind:class="[{show: formconfigure.showform},card_content,collapse]">
                       <div class="card-body">
                         <form>
                           <div class="row">
                             <div class="form-group col-md-2">
                               <label for="ownername">Project</label>
                               <treeselect placeholder="Select Project" class="projectlistdd" v-model="formconfigure.project" :options="projectlist"   @close="validateFormConfig(index, $event)" @input="handleInputChange"/>
                               <div class="errorinputmsg" v-if="errors.formconfigure.formdata[index].project">{{ errors.formconfigure.formdata[index].project }}</div>
                             </div>
                             <div class="form-group col-md-8" >
                             
                              <div class="row" v-for="(singleDocField, idx) in formconfigure.optionvalue" :key="`phoneInput-${idx}`" >
                                  <div class="form-group col-md-3" >
                                      <label class="form-label">API URL </label>
                                      <input class="form-control" v-model="singleDocField.apiurl" type="text" placeholder="Enter API URL"  />
                                      <div class="errorinputmsg" v-if="!singleDocField.apiurl">API URL is required</div>
                                      </div>  

                                      <div class="form-group col-md-3" >
                                      <label class="form-label">API Code </label>
                                      <input class="form-control" v-model="singleDocField.apicode" type="text" placeholder="Enter API Code"  />
                                     
                                      <div class="errorinputmsg" v-if="!singleDocField.apicode">API Code is required</div>
                                      </div> 
                                      
                                      <div class="form-group col-md-3" >
                                      <label class="form-label">Module Name </label>
                                       <treeselect placeholder="Select Module" class="projectlistdd" v-model="singleDocField.modulename" :options="projectmodulelist"   />
 
                                    </div>
                                      
                                      
                                      
                                      <div class="form-group col-md-3" >
                                      <label class="form-label">Functionality Name </label>
                                      <treeselect placeholder="Select Functionality" class="projectlistdd" v-model="singleDocField.functionalityinfo" :options="functionalitylist"   />                                      
                                      </div> 
                                      

                                  </div>
                              </div> 
                          
                               <div class="form-group col-md-2" style="margin-top:20px">
                                      <div class="cofigfont-medium-3">
                                          <div class="btn btn-relief-danger mr-1" @click="removeDocumentField(index,idx)">
                                             <em class="las la-minus-square "></em>
                                          </div>
                                          <div class="btn btn-relief-primary" @click="addDocumentFields(index)">
                                          <span>
                                            <em class="las la-plus-square "></em>    
                                           </span>
                                      </div>
                                  </div>
                              </div>
                           <!-- <div class="form-group col-md-12">
                              <div class="form-check">
                                  <input class="form-check-input"  v-model="formconfigure.ispublish" type="checkbox"   id="myCheckbox1" >
                                      <label class="form-check-label  " for="myCheckbox1">Is Active </label>
                                  </div>
                              </div> -->
                          </div>
                           <div class="flex--items mb-1 mt-1">
                             <div v-if="index !== 0" class="btn btn-outline-danger"  style="padding-left: 1rem; padding-right: 1rem" @click="deleteField(index)">
                               <span>Delete</span>
                             </div>
                           </div>
                         </form>
                       </div>
                     </div>
                     
                     <div class="flex--items my-2">
                      <div class="btn btn-outline-info mr-1" style="padding-left: 1.4rem; padding-right: 1.4rem" v-on:mousedown="clearField(index)">
                        <span>Clear</span>
                      </div>
                     <button type="submit" class="btn btn-relief-primary" style="padding-left: 2rem; padding-right:2rem" v-on:click.stop.prevent="saveData(index)">Submit</button>
                    </div>
                   </div>
                 </template>
               </div>
             </div>
           </form>
         </div>
         <div class="flex--items mb-2">
         </div>
       </div>
     </section>
   </div>
 </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import DatePicker from "vue2-datepicker";
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import "vue2-datepicker/index.css";
import { PlusIcon, StarIcon, MinusIcon,MinusSquareIcon,PlusSquareIcon } from 'vue-feather-icons'
import moment from "moment";
import apiUrl from "../../constants";
import commonMethods from '../../utils/commonMethods';

export default {
 name: 'CreateFormConfiguration',
 mixins: [ commonMethods ],

 components: {
   VueElementLoading,
   Treeselect,
   DatePicker,
   Loading,
   PlusIcon,
   MinusIcon,
   StarIcon,
   MinusSquareIcon,
   PlusSquareIcon,  
 },
 data() {
   return {
      projectlist:[],
      formconfigure: {
       formdata: [
         {
           project:null,
           optionvalue: [{
            apiurl: '',apicode:'',modulename:null,functionalityinfo:null
            }
          ],
           ispublish:false,
           showform:true
         }
       ]
     },
      errors: {
       formconfigure: {
         formdata: [
           {
            project: null,
            optionvalue: [],
           }
         ]
       }
     },
     projectmodulelist:[],
     functionalitylist:[],
     isLoading: false,
     card_content:'card-content',
     collapse:'collapse',
     tab_pane:'tab-pane', 
     fade:'fade',
     TimeFormat: apiUrl.TimeFormat,
   }
 },
 mounted() {
     let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
       userdata = JSON.parse(userdata)
       this.undt = userdata
       this.tokendata = window.localStorage.getItem('token');
       this.getProjectAllocatedToEmp()
      //this.getprojectmodules()
      }
 },
 methods: {
  getProjectAllocatedToEmp() {
      this.isLoading = true;
      let url = "api/listing/getProjectAllocatedToEmp";
      this.input = {
        empid: parseInt(this.undt.userid),
        useremail: this.undt.username,
        empcode: this.undt.userid,
        isfrompage:'createpage'
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectlist = result.data.data;
        } else {
          this.projectlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
  addDocumentFields(index) {
    this.formconfigure.formdata[index].optionvalue.push({
       apiurl: null, 
       apicode: null, 
       modulename: null,
       functionalityinfo: null
     })
    },
    removeDocumentField(index, idx) {
      if(this.formconfigure.formdata[index].optionvalue && this.formconfigure.formdata[index].optionvalue.length>1) {
        this.formconfigure.formdata[index].optionvalue.splice(idx,1);
        
      }
    },
   handleCollapse(idx) {
     this.formconfigure.formdata[idx].showform = !this.formconfigure.formdata[idx].showform
   }, 
   
   clearField(idx) {
      this.formconfigure.formdata[idx].project = null
      this.formconfigure.formdata[idx].optionvalue = [{
      apiurl: '',apicode:'',modulename:null,functionalityinfo:null
       }],
      this.formconfigure.formdata[idx].ispublish = false,
      this.errors.formconfigure.formdata[idx].tracker = ``
   },
   deleteField(idx) {
     this.formconfigure.formdata.splice(idx, 1)
     this.errors.formconfigure.formdata.splice(idx, 1)
   },
   validateAllFormData() {
     let valid = true
     for(let idx=0; idx<this.formconfigure.formdata.length; idx++) {
       let singleformconfigureForm = this.formconfigure.formdata[idx]
       if(!singleformconfigureForm.project) {
         this.errors.formconfigure.formdata[idx].project = `Project is required`
         valid = false
       } else {
         this.errors.formconfigure.formdata[idx].project = ``
       }
           for(let i=0;i<singleformconfigureForm.optionvalue.length;i++){
          if(!singleformconfigureForm.optionvalue[i].apiurl){
            valid = false
          }
          if(!singleformconfigureForm.optionvalue[i].apicode){
            valid = false
          }
        }
      
     }
     return valid
   },
   validateFormConfig(idx) {
     let valid = true
     if(!this.formconfigure.formdata[idx].project) {
       this.errors.formconfigure.formdata[idx].project = `Project is required`
       valid = false
     } else {
       this.errors.formconfigure.formdata[idx].project = ``
     }
     return valid
   },
   addFormConfiguration() {
     this.formconfigure.formdata.push({
        showform: true,
        optionvalue: [{
          apiurl: '',apicode:'',modulename:null,functionalityinfo:null
          // tracker:
       }],
      ispublish:false,  
      project:null    
     })
     this.errors.formconfigure.formdata.push({
      optionvalue: null,
     })
   },
   saveData(idx) {
     let isValid = this.validateAllFormData()
      if(isValid) {
       this.isLoading = true
       let url = 'api/projectapiconfig/create'
       let payload = {
         createdby: this.undt.username,
         modulestatus: this.formconfigure.formdata,
         project: this.formconfigure.project,
         useremail  : this.undt.username,
         empcode: this.undt.userid
       }
      
       axios({
         method: 'POST',
         url,
         data: payload,
         headers: { authorization :this.tokendata }
       }).then(result => {
         this.isLoading = false
         if(result.data.errorCode == 0) {
           Swal.fire({
             title: "Success!",
             text: result.data.msg,
             icon: 'success',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
         this.$router.push({ name: 'ProjectApiConfigList', params: { search: true }})
         } else if(result.data.errorCode == 3){
             Swal.fire({
                   title: "Session Expired...!!",
                   text: result.data.msg,
                   icon: 'info',
                   customClass: {
                       confirmButton: "btn btn-primary",
                   },
                   buttonsStyling: !1,
               }).then(function() {
                 window.location.href = "/#/login";
             })
           } else {
           Swal.fire({
             title: "Failed",
             text: result.data.msg,
             icon: 'info',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
         }
       }).catch(e => {
        this.displayError(e)
      })
     }
   },
   getprojectmodules() {
    let url = 'api/listing/getpromodulebyapplicationid';
    this.input = {
         useremail: this.undt.username,
        empcode: this.undt.userid,
        projecteid:this.formconfigure.formdata[0].project? this.formconfigure.formdata[0].project : null
    };
     axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
    }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.projectmodulelist = result.data.data;
        } else {
        this.projectmodulelist = [];
        
        }
    }).catch(e => {
        this.displayError(e)
      });
  },
  getfunctionalitydata() {
    let url = 'api/listing/getfunctionalitybyprojectid';
    this.input = {
         useremail: this.undt.username,
        empcode: this.undt.userid,
        projectid:this.formconfigure.formdata[0].project? this.formconfigure.formdata[0].project : null
    };
     axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
    }).then((result) => {
         this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.functionalitylist = result.data.data;
        } else {
        this.functionalitylist = [];      
        }
    }).catch(e => {
        this.displayError(e)
      });
  }, 
  handleInputChange(state){
      this.projectmodulelist = []
      if(state!=null){
          this.getprojectmodules();
          this.getfunctionalitydata()
        }else{
        this.getprojectmodules = null
        }
        }
   
 }
}
</script>
 