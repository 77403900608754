<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h3 class="content-header-title float-left mb-1">Employee-Project-Role Allocation</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Add Allocation</li>
              </ol>
            </div>
          </div>
        </div>
        <div class=" text-editar_discription_jobs masters_detailstabing_pills px-0">
          <ul class="nav nav-pills nav-pill-warning pt-1" id="nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="project-tab" data-toggle="tab" href="javascript:void();" @click="projecttab($event,'projectallocation')" role="tab" aria-controls="project" aria-selected="false">Project-Role Allocation</a>
            </li>
            <li v-if ="this.companycode=='fc'" class="nav-item">
              <a class="nav-link" id="manager-tab" data-toggle="tab" href="javascript:void();" @click="managertab($event,'managerallocation')" role="tab" aria-controls="manager" aria-selected="true">Team Hierarchy Allocation</a>
            </li>
          </ul>
        </div>

        <div class="" id="projecttabmaster" v-if="ispage && ispage == 'projectallocation'">
          <div class="card filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="myequestcollapse">
              <div class="container-fluid">
                  <div class="row row fc_my-05">
                      <div class="w-auto px-1 mobile-padding-0-2">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                <plus-circle-icon size="1.5x"></plus-circle-icon>
                              </div>
                          </div>
                          <h4 class="card-title d-inline-flex ml-1">Add Employee-Project-Role Allocation</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                          <em class="las la-angle-down"></em>
                      </span>
                  </div>
              </div>
            </div>
            <div class="card-body pt-1" v-bind:class="[{show: isRequestActive},card_content,collapse]">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group mb-1">
                      <label for="allotedto">Employee Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4char To Search Employee/Emailid" :multiple="true" :clear-on-select="true" v-model="employee.empid" v-on:search-change="getallemplist($event,1)" :options="employee.emplist" @select="selectemp" @close="validateForm($event)"/>
                    <div class="errorinputmsg" v-if="this.errors.employee.empid">{{ this.errors.employee.empid }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb1">
                      <label for="allotedto">Project Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4char To Search Project Name" :multiple="true" :clear-on-select="true" v-model="employee.projectid" v-on:search-change="getallprojectlist($event)" :options="employee.projectlist" @close="validateForm($event)" @select="selectproject"/>
                      <div class="errorinputmsg" v-if="this.errors.employee.projectid">{{ this.errors.employee.projectid }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb-1">
                      <label for="allotedto">Role Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select Role Name" v-model="employee.roleid" :options="employee.rolelist" @select="selrolename" @close="validateForm($event)"/>
                    <div class="errorinputmsg" v-if="this.errors.employee.roleid">{{ this.errors.employee.roleid }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mg-top1 text-right px-0">
                  <button type="button" class="btn btn-relief-primary ml-2" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                  <button type="button" class="btn btn-relief-success ml-1" id="submitbtnt41" @click="getassignedemployeeprojectrole($event,1)" @close="validateForm($event)">Show Details</button>
                  <button type="button" class="btn btn-relief-primary ml-1"  @click="saveconfiguration($event)" id="submitbtnt42">Submit</button>
                  <button type="button" class="btn btn-relief-danger ml-1" id="submitbtnt41" @click="removedata(allproassid)" v-if="allproassid.length>0">
                    <trash-2-icon class="custom-class delicon bg-light-default rounded cursorpointercls"></trash-2-icon>
                    Deactive User Access
                  </button>
                  <button style="margin-left:8px;" type="button" class="btn btn-outline-secondary" @click="cleardata">Cancel</button>

                  <button type="move" class="btn btn-relief-success ml-1" v-on:click.stop.prevent="downloadtemplate()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Download Template</span></button>

                  <label for="attachment" class="btn btn-relief-secondary  ml-1" style="margin-top: 2px;">Select XLS/XLSX DOCS</label>
                  <button class="btn btn-relief-secondary  ml-1"  v-on:click.stop.prevent="onUpload()" >Upload</button>
                  <input type="file" id="attachment" hidden :accept="accepttype" ref="file" @change="onFileChange"/>

                  <div class="col-md-8 offset-md-4 px-0" style="margin-top:24px">
                    <div class="form-group">
                      <div class="form-group">
                        <div class="table-responsive" v-if="file.length>0">
                          <table class="table table-sm table-bordered" aria-describedby="mydesc">
                            <thead>
                              <tr>
                                <th scope="col" class="text-left" style="width:200px">Document Name</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(image, key) in file" :key="key">
                                <td class="text-left">{{ image.name }}</td>
                                <td>
                                  <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                    <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mydetailscollapse">
                <div class="container-fluid">
                  <div class="row row fc_my-05">
                    <div class="w-auto px-1 mobile-padding-0-2">
                      <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                          <monitor-icon
                            size="1.5x"
                            class="custom-class avatar-icon font-medium-3"
                          ></monitor-icon>
                        </div>
                      </div>
                      <h4 class="card-title d-inline-flex ml-1">Employee-Project-Role Allocation History</h4>
                    </div>
                    <div class="ml-auto">
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="card-body pt-1" v-bind:class="[{show: isDetailsActive},card_content,collapse]">
              <div class="row">
                <div class="col-md-12">
                  <div class="tab-content" id="projectroleconfigtable">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div class="row" id="proModuleList">
                        <div class="col-12">
                          <div class="card">
                            <div class="table-responsive">
                              <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                <thead>
                                  <tr>
                                    <th scope="col" class="align-middle first-col-sticky"></th>
                                    <th scope="col" class="align-middle srno second-col-sticky">Sr#</th>
                                    <th scope="col" class="align-middle" style="width: 180px;">Employee Name</th>
                                    <th scope="col" class="align-middle">Project Name </th>
                                    <th scope="col" class="align-middle">Role Name</th>
                                    <th scope="col" class="align-middle clsaction">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template v-for="(empdata,empindex) in employee.empprojectrolelist" >        
                                    <tr v-bind:key="empindex">
                                      <td class="text-right first-col-sticky">
                                        <input type="checkbox" class="form-check-input" :id="'business-checkbox-2'+empdata.projectroleassiid" @change="changevalue($event,empdata.projectroleassiid)" :checked="false">
                                        <label class="form-check-label" :for="'business-checkbox-2'+empdata.projectroleassiid"></label>
                                      </td>
                                      <td class="srno first-col-sticky">{{ empindex+1+currPageNum*pagelimit }}</td>
                                      <td class="capitalisetext second-col-sticky capitalisetext">
                                        {{ empdata.empname }}
                                      </td>
                                      <td class="capitalisetext">
                                        {{ empdata.projectname }}
                                      </td>
                                      <td class="capitalisetext">
                                        {{ empdata.rolename }}
                                      </td>
                                      <td class="text-center">
                                        <trash-2-icon size="1.5" class="custom-class delicon mt-25 bg-light-danger rounded cursorpointercls" @click="removedata([empdata.projectroleassiid])"></trash-2-icon>
                                      </td>
                                    </tr>
                                  </template>
                                  <template v-if="employee.empprojectrolelist.length==0">
                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pageCenter text-center">
                    <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                    </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div class="" id="managertabmaster" v-if="ispage && ispage == 'managerallocation'">
          <div class="card filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="managerequestcollapse">
              <div class="container-fluid">
                  <div class="row row fc_my-05">
                      <div class="w-auto px-1 mobile-padding-0-2">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                <plus-circle-icon size="1.5x"></plus-circle-icon>
                              </div>
                          </div>
                          <h4 class="card-title d-inline-flex ml-1">Add Team Hierarchy</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                          <em class="las la-angle-down"></em>
                      </span>
                  </div>
              </div>
            </div>
            <div class="card-body pt-1" v-bind:class="[{show: isRequestActive2},card_content,collapse]">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group mb-1">
                      <label for="allotedto">Employee Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4char To Search Employee" :multiple="true" :clear-on-select="true" v-model="employee.empid" v-on:search-change="getallemplist($event,4)" :options="employee.emplist1" @select="selectemp" @close="validateForm($event)"/>
                      <div class="errorinputmsg" v-if="this.errors.employee.empid">{{ this.errors.employee.empid }}</div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb1">
                      <label for="allotedto">Reporting Manager Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4char To Search Reporting Manager" v-model="employee.mngid" v-on:search-change="getallemplist($event,2)" :options="employee.mnglist" @select="selectmng" @close="validateForm($event)"/>
                      <div class="errorinputmsg" v-if="this.errors.employee.mngid">{{ this.errors.employee.mngid }}</div>
                    </div>
                  </div>
                  <!-- <div class="col-md-4">
                    <div class="form-group mb1">
                      <label for="allotedto">Shift To Reporting Manager</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4char To Search Reporting Manager" v-model="employee.shiftmngid" v-on:search-change="getallemplist($event,3)" :options="employee.shiftmnglist" @select="selectshiftmng"/>
                    </div>
                  </div> -->
                </div>
                <div class="col-12 mg-top1 text-right px-0">
                  <button type="button" class="btn btn-relief-success ml-1" id="submitbtnt411" @click="getassignedreportingmnglist(1)">Show Details</button>
                  <button type="button" class="btn btn-relief-primary ml-1"  @click="saveemprmconfiguration($event)" id="submitbtnt421">Submit</button>
                  <button style="margin-left:8px;" type="cancel" class="btn btn-outline-secondary" @click="cleardata">Cancel</button>
                </div>
              </form>
            </div>
          </div>
          <div class="card filter_card_searchbgst">
            <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="managerdetailscollapse">
                <div class="container-fluid">
                  <div class="row row fc_my-05">
                    <div class="w-auto px-1 mobile-padding-0-2">
                      <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                          <monitor-icon
                            size="1.5x"
                            class="custom-class avatar-icon font-medium-3"
                          ></monitor-icon>
                        </div>
                      </div>
                      <h4 class="card-title d-inline-flex ml-1">Team Hierarchy History</h4>
                    </div>
                    <div class="ml-auto">
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="card-body pt-1" v-bind:class="[{show: isDetailsActive2},card_content,collapse]">
              <div class="row">
                <div class="col-md-12">
                  <div class="tab-content" id="empmanagerconfigtable">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <div class="row" id="proModuleList">
                        <div class="col-12">
                          <div class="card">
                            <div class="table-responsive">
                              <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                <thead>
                                  <tr>
                                    <th scope="col" class="text-center srno first-col-sticky">Sr#</th>
                                    <th scope="col" class="text-center second-col-sticky" style="width: 180px;">Employee Name</th>
                                    <th scope="col" class="text-center">Reporting Manager Name </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template v-for="(mngdata,mngindex) in employee.empmanagerlist" >        
                                    <tr v-bind:key="mngindex">
                                      <td class="text-center srno first-col-sticky">{{ mngindex+1+currPageNum*pagelimit }}</td>
                                      <td class="text-center capitalisetext second-col-sticky capitalisetext">
                                        {{ mngdata.empname ? mngdata.empname :'NA' }}
                                      </td>
                                      <td class="text-center capitalisetext">
                                        {{ mngdata.sbreportingmailid ? mngdata.sbreportingmailid :'NA' }}
                                      </td>
                                    </tr>
                                  </template>
                                  <template v-if="employee.empmanagerlist.length==0">
                                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pageCenter text-center">
                    <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                    </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="tablepoup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
                  <h4 class="modal-title">Document Invalid Details:</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <!-- <h4>Following Mailids Are Not Existed:</h4> -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                          <div class="row" id="table-small">
                            <div class="col-12">
                                <template v-for="(datax, indexx) in invaliddetails">
                                  <p v-bind:key="indexx">
                                    {{ indexx+1 }}. {{ datax.mailid ? datax.mailid :'' }} {{ datax.role ? datax.role :'' }}
                                  </p>
                                </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>    
              </div>
            </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
  .table_input_width_150 tbody td{
    min-width: 150px;
  }
  .createbtnparentdiv{
    padding-right: 0% !important;
  }
  .displayblk{
    display:block !important;
  }
  .createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  .btn_deletedata .btn_deletedata_icons{
    width: 24px;
    height: 24px;
    margin: 5px 10px;
  }
</style>
<script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
  import { EyeIcon, SearchIcon, MoreVerticalIcon, Edit2Icon, DownloadIcon,ExternalLinkIcon,PlusSquareIcon, PlusCircleIcon, MonitorIcon, Trash2Icon } from 'vue-feather-icons'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Pagination from 'vue-pagination-2'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { json } from 'body-parser';
  import commonMethods from '../../utils/commonMethods';
  import XlsxPopulate from 'xlsx-populate';

  
  export default {
    name:'EmpProjectRoleConfiguration',
    mixins: [ commonMethods ],
    components:{
      Loading,
      VueElementLoading,PlusCircleIcon,
      vSelect,
      EyeIcon,
      Treeselect,
      SearchIcon,
      Pagination,
      MoreVerticalIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ExternalLinkIcon,
      MonitorIcon,Trash2Icon
    },    
    data() {
      return {
        isThisBusinessRole: false,
        loginuser:'',
        isLoading: false,
        fullPage: true,
        companymasterlist: [],
        editRowData:[],
        chkselectfieldlist: [],
        TimeFormat: apiUrl.TimeFormat,
        isAddState:false,
        totalcount: 0,
        pageCount: 0,
        page:1,
        pagelimit:apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset:1,
        pagefeatures:[],
        isRequestActive: true,
        isDetailsActive: false,
        card_content:'card-content',
        collapse:'collapse',
        employee:{
          empid: null,
          emplist: [],
          emplist1: [],
          projectid: null,
          projectlist: [],
          roleid: null,
          rolename: null,
          rolelist: [],
          empprojectrolelist: [],
          mngid: null,
          managermailid: null,
          mnglist: [],
          empmanagerlist: [],
          shiftmngid: null,
          shiftmnglist: [],
          shiftmanagermailid: null
        },
        errors:{
          employee:{
            empid:null,
            projectid:null,
            roleid: null,
            mngid: null
          }
        },
        emplist: [],
        tokendata: null,
        allproassid: [],
        ispage: 'projectallocation',
        isRequestActive2: true,
        isDetailsActive2: false,
        companycode : apiUrl.companycode,
        exportxlsdata2: [],
        accepttype: ".xls,.xlsx",
        file:[],
        filedescp: [],
        attachmentnew: null,
        attachment: null,
        invaliddetails: []
      }
    },
    mounted(){
      let permission = this.$Service.verifyAccess();
      if(permission){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');  
          this.getThirdPartyAPIcall();
        }
      }
    },
    methods:{
      myequestcollapse:function(){
        this.isRequestActive == this.isRequestActive
      },
      mydetailscollapse:function(){
        this.isDetailsActive = !this.isDetailsActive
      },
      managerequestcollapse:function(){
        this.isRequestActive2 == this.isRequestActive2
      },
      managerdetailscollapse:function(){
        this.isDetailsActive2 = !this.isDetailsActive2
      },
      selectemp(state){
        this.employee.empid = null;
        this.employee.empid = state.id;
        if (!state.id) {
          this.errors.employee.empid = "Employee is required";
        } else {
          this.errors.employee.empid = '';
        }
      },
      selectmng(state){
        this.employee.mngid = null;
        this.employee.mngid = state.id;
        this.employee.managermailid = state.emailid;
        if (!state.id) {
          this.errors.employee.mngid = "Reporting manager is required";
        } else {
          this.errors.employee.mngid = '';
        }
      },
      selectshiftmng(state){
        this.employee.shiftmngid = null;
        this.employee.shiftmngid = state.id;
        this.employee.shiftmanagermailid = state.emailid;
      },
      selectproject(state) {
        this.employee.projectid = null;
        this.employee.projectid = state.id;
        if (!state.id) {
          this.errors.employee.projectid = "Project is required";
        } else {
          this.errors.employee.projectid = '';
        }
      },
      selrolename(state) {
        this.employee.roleid = null;
        this.employee.roleid = state.id;
        this.employee.rolename = state.label;
        if (!state.id) {
          this.errors.employee.roleid = "Role is required";
        } else {
          this.errors.employee.roleid = '';
        }
      },
      saveconfiguration: function(objThis){
        this.valid = this.validateForm(objThis);
        if (this.valid) {
          this.isLoading = true;
          this.input = {
            useremail: this.undt.username,
            empcode: parseInt(this.undt.userid),
            employeeid: this.employee.empid,
            projectid: this.employee.projectid,
            roleid: this.employee.roleid,
            rolename: this.employee.rolename
          }
          let apiURL='api/roleassignment/saveempprojectroleconfiguration'
          axios({
            'method': 'POST',
            'data': this.input,
            'url': apiURL,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
          this.isLoading = false;             
          if(result.data.errorCode == 0){
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: 'success',
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
            this.cleardata();
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "Failed",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
            })
          }
        }).catch(e => {
          this.displayError(e)
        });
        }
      },
      validateForm: function(objThis){
        let isValid=true;
        if(!this.employee.empid){
          this.errors.employee.empid = 'Employee is required';
          isValid= false;
        }else if(this.employee.empid.length ==0){
          this.errors.employee.empid = 'Employee is required';
          isValid= false;
        }else{
          this.errors.employee.empid = null;
        }
        if((this.ispage && this.ispage !='managerallocation') && !this.employee.projectid) {
          this.errors.employee.projectid = 'Project is required';
          isValid= false;
        }else if((this.ispage && this.ispage !='managerallocation') && this.employee.projectid.length ==0){
          this.errors.employee.projectid = 'Project is required';
          isValid= false;
        }else {
          this.errors.employee.projectid = null;
        }
        if((this.ispage && this.ispage !='managerallocation') && !this.employee.roleid) {
          this.errors.employee.roleid = 'Role is required';
          isValid= false;
        } else {
          this.errors.employee.roleid = null;
        }
        if((this.ispage && this.ispage =='managerallocation') && !this.employee.mngid){
          this.errors.employee.mngid = 'Reporting manager is required';
          isValid= false;
        }else{
          this.errors.employee.mngid = null;
        }
        return isValid;
      },
      cleardata(){
        this.employee.empid = null;
        this.employee.emplist = [];
        this.employee.emplist1 = [];
        this.errors.employee.empid = null;
        this.employee.projectid = null;
        this.employee.projectlist = [];
        this.errors.employee.projectid = null;
        this.employee.roleid = null;
        this.employee.rolename = null;
        this.errors.employee.roleid = null;
        this.employee.empprojectrolelist = [];
        this.isDetailsActive = false;
        this.totalcount = 0;
        this.allproassid = [];
        this.employee.mngid = null;
        this.employee.mnglist = [];
        this.errors.employee.mngid = null;
        this.employee.managermailid = null;
        this.employee.empmanagerlist = [];
        this.employee.shiftmngid = null;
        this.employee.shiftmnglist = [];
        this.employee.shiftmanagermailid = null;
        this.file = [];
        this.attachment = null;
        this.attachmentnew = null;
      },
      getallemplist(node,isflag){
        if (node && node.length>2) {
          this.isLoading = true;
          this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empname: node.toLowerCase()
          }
          let url;
          if(isflag == 1){
            url = 'api/roleassignment/getemployeebynamebyrole'
          }else{
            url='api/master/getemployeebyname'
          }
          axios({
            'method': 'POST',
            'url': url,
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
              let empdatalist = result.data.data.rows;
              let decryptdesignationtext = empdatalist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
              if(isflag == 1){
                this.employee.emplist = empdatalist;
                this.employee.emplist.sort((a, b) => {
                return a.label.localeCompare(b.label);
              });
              }else if(isflag == 2){
                this.employee.mnglist = empdatalist;
                this.employee.mnglist.sort((a, b) => {
                return a.label.localeCompare(b.label);
              });
              }else if(isflag == 3){
                this.employee.shiftmnglist = empdatalist;
                this.employee.shiftmnglist.sort((a, b) => {
                return a.label.localeCompare(b.label);
              });
              }else if(isflag == 4){
                this.employee.emplist1 = empdatalist;
                this.employee.emplist1.sort((a, b) => {
                return a.label.localeCompare(b.label);
              });
              }
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }else{
              this.employee.emplist = [];
              this.employee.mnglist = [];
              this.employee.emplist1 = [];
            }
          }).catch(e => {
            this.displayError(e);
          });
        }
      },
      getallprojectlist(node) {
        if (node && node.length>3) {
          this.isLoading = true;
          this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            projectname: node.toLowerCase(),
            isfrompage: 'listingpage'
          }
          axios({
            'method': 'POST',
            'url': 'api/project/getallprojectlist',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
              this.employee.projectlist = result.data.data;
              this.employee.projectlist.sort((a, b) => {
                return a.label.localeCompare(b.label);
              });
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }else{
              this.employee.projectlist = [];
            }
          }).catch(e => {
            this.displayError(e)
          });
        }
      },
      getThirdPartyAPIcall(){
        this.isLoading = true
        this.pageoffset=1
        this.inputthrid = {
            tbl:'t10',
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
        }
        axios({
          'method': 'POST',
          'url': 'api/projectonlyroleassignment/getdesignations',
          'data': this.inputthrid,
          'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          this.isLoading = false;
          if(result.data.status == true){
            this.employee.rolelist = result.data.data.rows;
            this.employee.rolelist.sort((a, b) => {
              return a.label.localeCompare(b.label);
            });
          }else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else{
            this.employee.rolelist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      getassignedemployeeprojectrole(objThis,flag) {
        if((this.employee.empid && this.employee.empid !=null && this.employee.empid.length>0) || (this.employee.projectid && this.employee.projectid !=null && this.employee.projectid.length>0)){
          this.errors.employee.empid = null;
          this.errors.employee.projectid = null;
          this.errors.employee.roleid = null;
          this.isLoading = true;
          this.inputfield= {
            useremail: this.undt.username,
            empcode: parseInt(this.undt.userid),
            employeeid: this.employee.empid,
            projectid: this.employee.projectid,
            roleid: this.employee.roleid,
            limit: apiUrl.LIMIT,
          }
          if(flag == 1){
            this.inputfield.offset = 1;
            this.currPageNum = 0;
          }else{
            this.inputfield.offset = this.pageoffset;
          }
          axios({
            'method': 'POST',
            'url': 'api/roleassignment/getassignedemployeeprojectrole',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
              this.isDetailsActive =true;
              this.employee.empprojectrolelist = result.data.data.rows;
              this.totalcount = result.data.data.count;
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }else{
              this.employee.empprojectrolelist = [];
              this.totalcount =0;
              this.isDetailsActive =true;
            }
          }).catch(e => {
          this.displayError(e)
        });
        }else {
          Swal.fire({
            title: "Failed",
            text: "Employee Name Or Project Name Is Required",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.employee.empprojectrolelist = [];
          this.totalcount =0;
        }
      },
      clickCallback: function (pageNum) {
        this.pageoffset = pageNum;
        this.page = pageNum;
        this.currPageNum = pageNum - 1;
        if(this.ispage && this.ispage =='projectallocation'){
          this.getassignedemployeeprojectrole();
          setTimeout(function(){
            $('html, body').animate({
              scrollTop: $("#projectroleconfigtable").offset().top -70
            });
          }, 500);
        }else if(this.ispage && this.ispage =='managerallocation'){
          this.getassignedreportingmnglist();
          setTimeout(function(){
            $('html, body').animate({
              scrollTop: $("#empmanagerconfigtable").offset().top -70
            });
          }, 500);
        }
      },
      removedata(projectroleassiid){
        if(projectroleassiid){
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.isLoading = true;
              this.input ={
                useremail: this.undt.username,
                empcode: this.undt.userid,
                projectroleassiid: projectroleassiid
              }      
              axios({
                'method': 'POST',
                'url': 'api/roleassignment/removeemployeeprojectrole',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
              })
              .then(result => {
                this.isLoading = false;           
                if(result.data.status){
                  Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                      confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                  })
                  this.allproassid =[];
                  this.employee.empprojectrolelist = [];
                  this.getassignedemployeeprojectrole();
                }else{
                  Swal.fire({
                    title: "Failed!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                      confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                  })
                }
              }).catch(e => {
              this.displayError(e)
            })
            }
          })
        }
      },
      changevalue(event,proassid){
        if (event.target.checked ==true) {
          this.allproassid.push(proassid);
        }else if(event.target.checked ==false){
          let findIndex = this.allproassid.indexOf(proassid)
          this.allproassid.splice(findIndex, 1);
        }
      },
      projecttab(event,setpage){
        this.ispage = setpage;
        this.cleardata();
        this.totalcount = 0;
        this.pageCount = 0;
        this.page = 1;
        this.currPageNum = 0;
        this.pageoffset = 1;
        this.isDetailsActive = false;
        this.isDetailsActive2 = false;
      },
      managertab(event,setpage){
        this.ispage = setpage;
        this.cleardata();
        this.totalcount = 0;
        this.pageCount = 0;
        this.page = 1;
        this.currPageNum = 0;
        this.pageoffset = 1;
        this.isDetailsActive = false;
        this.isDetailsActive2 = false;
      },
      getassignedreportingmnglist(flag) {
        if((this.employee.empid && this.employee.empid !=null && this.employee.empid.length>0) || (this.employee.mngid && this.employee.mngid !=null)){
          this.errors.employee.empid = null;
          this.errors.employee.mngid = null;
          this.isLoading = true;
          this.inputfield= {
            useremail: this.undt.username,
            empcode: parseInt(this.undt.userid),
            employeeid: this.employee.empid,
            managerid: this.employee.mngid,
            limit: apiUrl.LIMIT,
          }
          if(flag == 1){
            this.inputfield.offset = 1;
            this.currPageNum = 0;
          }else{
            this.inputfield.offset = this.pageoffset2;
          }
          axios({
            'method': 'POST',
            'url': 'api/roleassignment/getassignedemployeemng',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
              this.isDetailsActive2 =true;
              this.employee.empmanagerlist = result.data.data.rows;
              this.totalcount = result.data.data.count;
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }else{
              this.employee.empmanagerlist = [];
              this.totalcount =0;
              this.isDetailsActive2 =true;
            }
          }).catch(e => {
            this.displayError(e)
          });
        }else {
          Swal.fire({
            title: "Failed",
            text: "Employee Name Or Reporting Manager Name Is Required",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.employee.empmanagerlist = [];
          this.totalcount =0;
        }
      },
      saveemprmconfiguration: function(objThis){
        this.valid = this.validateForm(objThis);
        if (this.valid) {
          this.isLoading = true;
          this.input = {
            useremail: this.undt.username,
            empcode: parseInt(this.undt.userid),
            employeeid: this.employee.empid,
            managerid: this.employee.mngid,
            managermailid: this.employee.managermailid
          }
          let apiURL='api/roleassignment/saveemprmconfiguration';
          axios({
            'method': 'POST',
            'data': this.input,
            'url': apiURL,
            'headers':{'authorization':this.tokendata}
          })
          .then(result => {
            this.isLoading = false;             
            if(result.data.errorCode == 0){
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: 'success',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
              this.cleardata();
            }else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
          }).catch(e => {
            this.displayError(e)
          });
        }
      },
      dateMONFormat(value){
          if (value){
            return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
          } else{
            return ""
          }
        },
      ExportXL() {
      if((this.employee.empid && this.employee.empid !=null && this.employee.empid.length>0) || (this.employee.projectid && this.employee.projectid !=null && this.employee.projectid.length>0)){
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        useremail: this.undt.username,
        empcode: parseInt(this.undt.userid),
        employeeid: this.employee.empid,
        projectid: this.employee.projectid,
        roleid: this.employee.roleid,
      };
      axios({
        method: "POST",
        url: `api/roleassignment/getassignedemployeeprojectrole`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
          this.download(result.data.data.rows,filename)
        }else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }      
        this.exportxlsdata = [];  
      }).catch(e => {
        this.displayError(e)
      });
      }else {
          Swal.fire({
            title: "Failed",
            text: "Employee Name Or Project Name Is Required",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.exportxlsdata = [];
        }
    },
    download(readExportdata) {
      if(readExportdata.length > 0) {
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let row = ''
        let CSV = 'Sr#,Employee Name,Email ID,Project Name,Role Name,Created Date, Created By' + '\r\n'
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let employename = index.hasOwnProperty('empname') ? index.empname ==null || index.empname.toLowerCase() =='na' ? '':index.empname : '';
          let emailid = index.hasOwnProperty('emailid') ? (index.emailid?index.emailid:'NA') : ''
          let projectName = index.hasOwnProperty('projectname') ? (index.projectname?index.projectname:'NA') : ''
          let roleName = index.hasOwnProperty('rolename') ? (index.rolename?index.rolename:'NA') : ''
          let createddate = index.hasOwnProperty('createddate') ? index.createddate ==null || index.createddate.toLowerCase() =='na'?'':index.createddate : '';
          let createdby = index.hasOwnProperty('createdby') ? index.createdby : ''
          createddate = this.dateMONFormat(createddate)
          row += '"' + srno + '",'
          row += '"' + employename + '",'
          row += '"' + emailid + '",'
          row += '"' + projectName + '",'
          row += '"' + roleName + '",'
          row += '"' + createddate + '",'
          row += '"' + createdby + '",'
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        let fileName = ' Employee-Project-Role Allocation_details_' + moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: " Employee-Role Allocation details Exported successfully",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });    
      }
    },
    async downloadtemplate() {
      this.isLoading =true;
      let dropdownOptions = [];
      const errorMessage = 'Please select a valid Role Name.';

      this.employee.rolelist.map(i =>{
        dropdownOptions.push(i.label);
      })
      
      let wb = await XlsxPopulate.fromBlankAsync();
      let ws = wb.sheet(0);
      ws.cell('A1').value('EmployeeMailid');
      ws.cell('B1').value('RoleName');

      for (let i = 0; i < dropdownOptions.length; i++) {
        ws.cell(1 + i, 26).value(dropdownOptions[i]);
      }

      let dataValidation = {
        type: 'list',
        formula1: `='${ws.name()}'!$Z$1:$Z${dropdownOptions.length}`,
        showErrorMessage: true,
        errorTitle: '',
        error: errorMessage,
        errorTitleLanguage: 'en-US',
        errorLanguage: 'en-US'
      };

      ws.range('B2:B500').dataValidation(dataValidation);

      let blob = await wb.outputAsync();

      let url = window.URL.createObjectURL(new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

      let link = document.createElement('a');
      let filename = 'Sampletemplate'+ moment().format('DD_MM_YY_HH_mm_ss');
      link.href = url;
      link.download = filename + '.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.isLoading =false;
    },
    onFileChange(e) {
      this.attachmentnew =null;
      this.file =[];
      let selectedFiles = e.target.files;
      let blnValid = false;
      let CurExtension = ['.xls','.xlsx'];
      // let curtype =selectedFiles[0].type.split('/wps-office')[1];
      let curtype = selectedFiles && selectedFiles[0] && selectedFiles[0].name ? selectedFiles[0].name.split('.').pop().toLowerCase() :null;
      if (curtype && CurExtension.includes(`.${curtype}`)) {
        blnValid = true;
      }
      if (!blnValid) {
        Swal.fire({
          title: "Failed",
          text: "Sorry, Invalid Type of Extension File!",
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
        })
        return false;
      }else{
        this.file.push(selectedFiles[0]);
      }
      document.getElementById('attachment').value = ""
    },
    onUpload(){
      if(this.file && this.employee.projectid && this.employee.projectid.length >0){
        this.isLoading=true
        if(this.file.length !== 0){
          this.apiURL="api/master/fileupload/csvfileuploadwithgetdata"
          let formData = new FormData();
          let maxsize = 0;
          for (let index = 0; index < this.file.length; index++) {
            formData.append("imagefile", this.file[index]);
            maxsize+= this.file[index].size
          }
          formData.append("empcode", this.undt.userid);
          formData.append("useremail", this.undt.username);
          formData.append("moduletype", 'employeeroleconfiguration');
          formData.append("fcount", parseInt(this.file.length));
          // formData.append("filefor", 'readcsvfile');
          formData.append("projectidarr", this.employee.projectid);
          
          if (maxsize > 1000 * 1000 * 25) {
            this.isLoading = false;
            Swal.fire({
              title: "Failed",
              text: "Your document uploaded above 25mb not allow",
              icon: "info",
              customClass: {
              confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            })
            this.isLoading=false
            return false; 
          }
          axios({
            'content-type':'multipart/form-data',
            'method': 'POST',
            'url': this.apiURL,
            'data':formData,
            'headers':{'authorization':this.tokendata,'content-type':'multipart/form-data'}
          })
          .then(result => { 
            this.isLoading=false;    
            if(result.data.status){
              // const bindinfo = result.data.data.map((file, index) => {
              //   return {"link":file,"info":this.filedescp[index]}                
              // });
              // let jsonobj = {data:bindinfo}
              // this.attachmentnew = JSON.stringify(jsonobj);
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: 'success',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
              this.attachment =null;
              this.attachmentnew =null;
              this.file =[];
            }else if(result.data.errorCode ==1 && result.data.data.length >0){
              this.invaliddetails = [...new Set(result.data.data)];
              $('#tablepoup').modal('show');
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
              this.attachment=null
            }
          }).catch(e => {
            this.displayError(e)
          })
        }else{
          this.isLoading=false; 
          Swal.fire({
            title: "",
            text: 'Select docs',
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
          this.attachment=null
        }
      } else {
        this.isLoading=false; 
        Swal.fire({
          title: "",
          text: this.file.length ==0 ? 'Select docs' : !this.employee.projectid || this.employee.projectid.length ==0 ? 'Projects Are Required':'',
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
        })
        this.attachment=null;
        this.errors.employee.empid ='';
        this.errors.employee.roleid ='';
        this.errors.employee.projectid = "Project is required";
      }
    },
    removeImage (index) {
      if(this.file.length>0) {
        this.file.splice(index, 1);
        this.filedescp.splice(index, 1);
        let removeimg = JSON.parse(this.attachmentnew);
        if(removeimg){
          let remove = removeimg.data.splice(index,1);
          if(remove.length > 0){
            this.attachmentnew = JSON.stringify({data:removeimg.data})}
          else {
            this.attachmentnew = null;
            this.file = [];
          }
        }
      }     
    },
  },
  filters: {
    basename: function (value) {
      if (!value) return ''
      return value.substr(value.lastIndexOf("/") + 1)
    }
  }
}
</script>