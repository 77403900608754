<template>
<div class="app-content content">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
          <div class="content-wrapper card bg-white p-0 content_wrp_top_b_10"  >
           <div class="content-body">
                <section>
                    <div class="app-calendar overflow-hidden border">
                        <div class="row no-gutters">
                            <div class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column" id="app-calendar-sidebar">
                                <div class="sidebar-wrapper">
                                    <div class="card-body d-flex justify-content-center">
                                        <button class="btn btn-primary btn-toggle-sidebar btn-block" data-toggle="modal" data-target="#add-new-sidebar">
                                            <span class="align-middle">Add</span>
                                        </button>
                                    </div>
                                    <div class="card-body pb-0">
                                        <div class="custom-control  custom-checkbox mb-1" @click="getDailyUserAssignedData(moduletype='allmodule')">
                                            <input type="checkbox" class="custom-control-input select-all" id="select-all" />
                                            
                                            <label class="custom-control-label" for="select-all">View All</label>
                                        </div>
                                        <div class="calendar-events-filter">
                                            <div class="custom-control custom-control-success custom-checkbox mb-1" @click="getMonthlyUserAssignedData(moduletype)">
                                                <input type="checkbox" class="custom-control-input input-filter" id="personal" data-value="personal"  />
                                                
                                                <label class="custom-control-label" for="personal">Business task </label>
                                            </div>

                                            <div class="custom-control custom-control-warning custom-checkbox mb-1" @click="getDailyUserAssignedData(moduletype='incident')">
                                                <input type="checkbox" class="custom-control-input input-filter" id="business" data-value="business"  />
                                              
                                                <label class="custom-control-label" for="business">Incident</label>
                                            </div>

                                            <div class="custom-control custom-control-danger custom-checkbox mb-1" @click="getDailyUserAssignedData(moduletype='issue')">
                                                <input type="checkbox" class="custom-control-input input-filter" id="family" data-value="family"  />
                                                
                                                <label class="custom-control-label" for="family">Issues</label>
                                            </div>

                                            <div class="custom-control custom-control-success custom-checkbox mb-1" @click="getDailyUserAssignedData(moduletype='task')">
                                                <input type="checkbox" class="custom-control-input input-filter" id="holiday" data-value="holiday"  />
                                                
                                                <label class="custom-control-label" for="holiday">Task</label>
                                            </div>
                                            <div class="custom-control custom-control-info custom-checkbox" @click="getWeeklyUserAssignedData()">
                                                <input type="checkbox" class="custom-control-input input-filter" id="etc" data-value="etc"  />
                                            
                                                <label class="custom-control-label" for="etc">ETC</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-auto">
                                    
                                </div>
                            </div>
                            <div class="col position-relative">
                                <div class="card shadow-none border-0 mb-0 rounded-0">
                                    <div class="card-body pb-0">
                                        <div id="calendar"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="body-content-overlay"></div>
                        </div>
                    </div>
                    <div class="modal modal-slide-in event-sidebar fade" id="add-new-sidebar">
                        <div class="modal-dialog sidebar-lg">
                            <div class="modal-content p-0">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
                                <div class="modal-header mb-1">
                                    <h5 class="modal-title">Add Details</h5>
                                </div>
                                <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
                                    <form class="event-form needs-validation" data-ajax="false" novalidate>
                                        <div class="form-group">
                                            <label for="title" class="form-label">Title</label>
                                            <input type="text" class="form-control" id="title" name="title" placeholder="Event Title" required />
                                        </div>
                                        <div class="form-group">
                                            <label for="select-label" class="form-label">Label</label>
                                            <select class="select2 select-label form-control w-100" id="select-label" name="select-label">
                                                <option data-label="primary" value="Business" selected>Business</option>
                                                <option data-label="danger" value="Personal">Personal</option>
                                                <option data-label="warning" value="Family">Family</option>
                                                <option data-label="success" value="Holiday">Holiday</option>
                                                <option data-label="info" value="ETC">ETC</option>
                                            </select>
                                        </div>
                                        <div class="form-group position-relative">
                                            <label for="start-date" class="form-label">Start Date</label>
                                            <input type="text" class="form-control" id="start-date" name="start-date" placeholder="Start Date" />
                                        </div>
                                        <div class="form-group position-relative">
                                            <label for="end-date" class="form-label">End Date</label>
                                            <input type="text" class="form-control" id="end-date" name="end-date" placeholder="End Date" />
                                        </div>
                                        <div class="form-group">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input allDay-switch" id="customSwitch3" />
                                                <label class="custom-control-label" for="customSwitch3">All Day</label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="event-url" class="form-label">Event URL</label>
                                            <input type="url" class="form-control" id="event-url" placeholder="https://www.google.com" />
                                        </div>
                                        <div class="form-group select2-primary">
                                            <label for="event-guests" class="form-label">Add Guests</label>
                                            <select class="select2 select-add-guests form-control w-100" id="event-guests" multiple>
                                                <option data-avatar="1-small.png" value="Jane Foster">Jane Foster</option>
                                                <option data-avatar="3-small.png" value="Donna Frank">Donna Frank</option>
                                                <option data-avatar="5-small.png" value="Gabrielle Robertson">Gabrielle Robertson</option>
                                                <option data-avatar="7-small.png" value="Lori Spears">Lori Spears</option>
                                                <option data-avatar="9-small.png" value="Sandy Vega">Sandy Vega</option>
                                                <option data-avatar="11-small.png" value="Cheryl May">Cheryl May</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="event-location" class="form-label">Location</label>
                                            <input type="text" class="form-control" id="event-location" placeholder="Enter Location" />
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Description</label>
                                            <textarea name="event-description-editor" id="event-description-editor" class="form-control"></textarea>
                                        </div>
                                        <div class="form-group d-flex">
                                            <button type="submit" class="btn btn-primary add-event-btn mr-1">Add</button>
                                            <button type="button" class="btn btn-outline-secondary btn-cancel" data-dismiss="modal">Cancel</button>
                                            <button type="submit" class="btn btn-primary update-event-btn d-none mr-1">Update</button>
                                            <button class="btn btn-outline-danger btn-delete-event d-none">Delete</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
      </div>
   </div>
</template>
<script>
   import axios from "axios";
   import apiUrl from "../../constants";
   import moment from "moment";
   import { SearchIcon } from "vue-feather-icons";   
   import DatePicker from "vue2-datepicker";
   import "vue2-datepicker/index.css";
   import Loading from 'vue-loading-overlay';
   import 'vue-loading-overlay/dist/vue-loading.css';
   import VueElementLoading from 'vue-element-loading';
   
   export default {
     name: "mycalendars",
     components: {
        SearchIcon,
        DatePicker,
        Loading,
        VueElementLoading
     },
     data() {
        return{
            defaultValue: new Date(),
            isLoading: false,
            fullPage: true,
            fromStartDate:'',
            toStartDate:'',
            isSearchRequestActive:true,
            card_content:'card-content',
            collapse:'collapse',
            moduletypedata:[],
            userassigneddata:[],
            monthlyuserassigneddata:[]
        }
     },
    mounted(){
        var userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.tokendata = window.localStorage.getItem('token');
            this.empid = userdata.userid
            this.useremail = userdata.emailaddress
        }
    },
    methods: {
        mysearchrequestollapse:function(){
            this.isSearchRequestActive=!this.isSearchRequestActive
        },
        getDailyUserAssignedData(moduletype){
            this.isLoading = true
            this.apiURL="api/calendar/getDailyUserAssignedData"
            this.input={
                empcode:this.empid,
                useremail: this.useremail,
                moduletype:moduletype
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.status){
                   this.moduletypedata=result.data.data.rows
                   this.totalcount = result.data.data.count;
                   this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else {
                    this.totalcount = 0
                    this.moduletypedata=[]
                    this.pageCount =0
                }
            })
        },

        getWeeklyUserAssignedData(){
            this.isLoading = true
            this.apiURL="api/calendar/getWeeklyUserAssignedData"
            this.input={
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
                empcode:this.empid,
                useremail: this.useremail,
            }
            var currdate = new Date();
            var previousdate = new Date();
            previousdate.setDate(previousdate.getDate() -7)
            currdate.setDate(currdate.getDate() +1)
            this.input.datefrom =   moment(String(previousdate)).format('YYYY-MM-DD');
            this.input.dateto = moment(String(currdate)).format('YYYY-MM-DD');
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.status){
                   this.userassigneddata=result.data.data.rows
                   this.totalcount = result.data.data.count;
                   this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else {
                    this.totalcount = 0
                    this.userassigneddata=[]
                    this.pageCount =0
                }
            })
        },
        getMonthlyUserAssignedData(){
            this.isLoading = true
            this.apiURL="api/calendar/getMonthlyUserAssignedData"
            this.input={
                offset: this.pageoffset,
                limit: apiUrl.LIMIT,
                empcode:this.empid,
                useremail: this.useremail,
            }
            var currdate = new Date();
            var previousdate = new Date();
            previousdate.setDate(previousdate.getDate() -30)
            currdate.setDate(currdate.getDate() +1)
            this.input.datefrom =   moment(String(previousdate)).format('YYYY-MM-DD');
            this.input.dateto = moment(String(currdate)).format('YYYY-MM-DD');
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false
                if(result.data.status){
                   this.monthlyuserassigneddata=result.data.data.rows
                   this.totalcount = result.data.data.count;
                   this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
                } else {
                    this.totalcount = 0
                    this.monthlyuserassigneddata=[]
                    this.pageCount =0
                }
            })
        },
   } 
}
</script>
