<template>
<div>
    <div class="app-content content devcontent ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header-left mb-1 pt-0 ">
                <div class="container-fluid">
                    <div class="row breadcrumbs-top">
                    <div class="col-md-9 content-header-left px-0 ">
                        <h3 class="content-header-title float-left mb-0">Sonarqube</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to=''>Sonarqube</router-link></li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            <div class="content-body">
                <section id="dashboard-ecommerce">
                <!-- start -->
                <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid" >
                  <div class="row fc_my-05" >
                    <div
                    class="px-1 w-auto mobile-padding-0-2"
                    
                  >
                    <div class="avatar bg-light-secondary rounded d-inline-flex">
                        <div class="avatar-content">
                          <search-icon
                            size="1.5x"
                            class="custom-class avatar-icon font-medium-3"
                          ></search-icon>
                        </div>
                      </div>
                    <h4 class="card-title d-inline-flex ml-1">Search Sonar Qube Details </h4>
                  </div>
                      <div class="ml-auto d-flex">
                       <span class="rotetions_view_arrow"> 
                        <em class="las la-angle-down"></em>
                    </span>
                    </div>

                  </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                    <div class="row ">
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                            <div class="form-group">
                                <label for="projectmasterdd">Project Name</label>
                                <treeselect v-model="project" :options="projectList" placeholder="Enter Min 4Chars To Search Project" :multiple="true" :clear-on-select="true" v-on:search-change="getProjectList"/>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                            <label class="form-label lable-left">Perspective</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Perspective" v-model="perspective" :options="perspectiveList"/>
                        </div>
                        <!-- <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                            <label class="form-label lable-left">Sort By</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Sort By"  v-model="sort" :options="sortingList"/>
                        </div> -->
                    
                    <div class="col-sm-12 col-md-12 col-lg-12 text-right px-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getSonarQubeData()"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mx-1" @click="resetRecords()">
                      <x-icon  size="1.5x" class="custom-class"></x-icon><span>Clear</span></button>
                    </div>
                </div>
                </div>
              </div>
           
            </div>

            <div class="tab-content" id="myTabContent">

                <div v-if="this.totalcount !== 0">
                    <div v-for="(component, componentIndex) in convertedArr" :key="componentIndex">
                        <!-- <td class="srno first-col-sticky py-1">{{ componentIndex+1+currPageNum*pagelimit }}</td> -->
                        <div class="card p-1 mb-1 shadow-none border-warning">
                            <div class="container card-app-design px-0">
                                <div class="border-bottom pb-1">
                                    <div class="d-sm-flex justify-content-between align-items-center mb-1">

                                        <div class="d-flex">
                                            <div class="text-title d-flex align-items-center mr-2">
                                                <span class="bullet sonar_project_status_iconstar">
                                                    <em class="las la-star text-warning"></em>
                                                </span>
                                                <h4 class="text-primary cursor-pointer" @click="redirecttourl(component)">{{ component[0] }}</h4>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mr-2">
                                            <span class="sonar_alert_icon_padd bullet bullet-success font-small-3 mr-50 cursor-pointer rounded alert-success">
                                                <em class="las la-check"></em>
                                            </span>
                                            <span>Passed</span>
                                        </div>
                                    </div>
                                    <div class="sonarqube_title">
                                        <strong>Last analysis on: </strong>{{ component[1][0].updatedAt.slice(0, 10) }}<a href=""></a>
                                    </div>
                                </div>
                                <div class="design-planning-wrapper soanrdesign-planning-wrapper pt-1">
                            <!-- Updated start -->
                            <div v-for="(metric, metricIndex) in organizedMetrics(component[1])" :key="metricIndex" class="design-planning">
                                        
                                    <div v-if="metric.name === 'Bugs' || metric.name === 'New Bugs'">
                                        <div class="mb-25 design-planning_top_40">
                                            <span class="card-text font-weight-normal">{{ metric.value ? metric.value:'0' }}</span>

                                            <span :class="'sonar_count bg-light-' + calculateRating(metric.value).className + ' rounded-circle with-border'">
                                                {{ calculateRating(metric.value).rating }}
                                            </span>

                                        </div>
                                        <div class="mb-0">
                                            <span class="sonar_icon_fa">
                                                <em class="las la-bug font-medium-3"></em>
                                            </span>
                                            <span class="sonar_title_bug">Bugs</span>
                                        </div>
                                    </div>
                                    <div v-if="metric.name === 'Vulnerabilities' || metric.name === 'New Vulnerabilities'">
                                        <div class="mb-25 design-planning_top_40">
                                            <span class="card-text font-weight-normal">{{ metric.value ? metric.value:'0' }}</span>
                                            <span :class="'sonar_count bg-light-' + calculateRating(metric.value).className + ' rounded-circle with-border'">
                                                {{ calculateRating(metric.value).rating }}
                                            </span>

                                        </div>
                                        <div class="mb-0">
                                            <span class="sonar_icon_fa">
                                                <em class="las la-unlock font-medium-3"></em>
                                            </span>
                                            <span class="sonar_title_bug">Vulnerabilities</span>
                                        </div>
                                    </div>
                                    <div v-if="metric.name === 'Security Hotspots Reviewed' || metric.name === 'New Security Hotspots'">
                                        <div class="mb-25 design-planning_top_40">
                                            <span class="card-text font-weight-normal">{{ metric.value ? metric.value:'0' }}</span>
                                            <span :class="'sonar_count bg-light-' + calculateRating(metric.value).className + ' rounded-circle with-border'">
                                                {{ calculateRating(metric.value).rating }}
                                            </span>
                                        </div>
                                        <div class="mb-0">
                                            <span class="sonar_icon_fa">
                                                <em class="las la-broadcast-tower font-medium-3"></em>
                                            </span>
                                            <span class="sonar_title_bug">Security Hotspots Reviewed</span>
                                        </div>
                                    </div>
                                    <div v-if="metric.name === 'Code Smells' || metric.name === 'New Code Smells'">
                                        <div class="mb-25 design-planning_top_40">
                                            <span class="card-text font-weight-normal">{{ metric.value ? metric.value:'0' }}</span>
                                            <span :class="'sonar_count bg-light-' + calculateRating(metric.value).className + ' rounded-circle with-border'">
                                                {{ calculateRating(metric.value).rating }}
                                            </span>
                                        </div>
                                        <div class="mb-0">
                                            <span class="sonar_icon_fa">
                                                <em class="las la-code font-medium-3"></em>
                                            </span>
                                            <span class="sonar_title_bug">Code Smells</span>
                                        </div>
                                    </div>
                                    <div v-if="metric.name === 'Coverage' || metric.name === 'Coverage on New Code'">
                                        <div class="mb-25 design-planning_top_40">
                                            <span class="card-text font-weight-normal">{{ metric.value ? metric.value:'0' }}</span>
                                        </div>
                                        <div class="mb-0">
                                            <span class="sonar_title_bug">Coverage</span>
                                        </div>
                                    </div>

                                    <div v-if="metric.name === 'Duplicated Lines (%)' || metric.name === 'Duplicated Lines (%) on New Code'">
                                        <div class="mb-25 design-planning_top_40">
                                            <span class="card-text font-weight-normal">{{ metric.value ? metric.value:'0' }}</span>
                                        </div>
                                        <div class="mb-0">
                                            <span class="sonar_title_bug">Duplicated Lines (%)</span>
                                        </div>
                                    </div>




                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pageCenter text-center mt-1" >
                    <pagination
                    v-if="showtabledata"
                    v-model="page"
                    :records="parseInt(totalcount)"
                    :per-page="pagelimit"
                    @paginate="clickCallback"/>
                </div>


                </div>
                <div v-else class="pageCenter text-center mt-1">
                    <p class="blink" style="font-size: 15px;"> &#9888; No Records Found..!! &#9888;</p>
                </div>
            </div>

            

            </section>

            </div>
        </div>
    </div>
    

</div>
</template>

<script>

import axios from 'axios'
import apiURL from '../constants';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  SearchIcon,XIcon,
  } from 'vue-feather-icons'
import CountryFlag from 'vue-country-flag'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import usercommondashboard from './dashboards/Usercommondashboard';
import commonMethods from '../utils/commonMethods';
import Pagination from "vue-pagination-2";

export default({
    name:'Sonarcube',
    components:{
        SearchIcon,Treeselect,XIcon,
        Loading,CountryFlag,Pagination
    },
    mixins: [ commonMethods ],
    data() {
        return {
            isLoading: false,
            fullPage: true,
            un:'',
            createdby:'',
            isSearchRequestActive:false,
            card_content:'card-content',
            collapse:'collapse',

            project: null,
            projectList: [],
            tokendata:null,
            pagefeatures:[],     
            perspective:null,
            perspectiveList:[
                {
                    id:'overall',
                    label:'Overall Code'
                },{
                    id:'new',
                    label:'New Code'
                },
            ],
            sort:null,
            sortingList:[
                {
                    id:'name',
                    label:'Name'
                },
                {
                    id:'date',
                    label:'Last Updated'
                }
            ],
            sonarqubeData:[],
            convertedArr:[],
            component: [], // Your component data
            totalcount: 0,
            pageCount: 0,
            page: 1,
            pagelimit: apiURL.LIMIT,
            currPageNum: 0,
            pageoffset: 1,
            showtabledata: true,
            parentmoduledata: null,

        }  
    } ,
    computed: {
        circleBorderColor() {
            const className = this.calculateRating(metric.measureValue).className;
            const backgroundColor = getComputedStyle(document.documentElement)
                .getPropertyValue(`--bg-light-${className}`);
            return backgroundColor;
        },
    },

    
    mounted(){  
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.tokendata = window.localStorage.getItem('token');
        }
        this.getSonarQubeData()
        this.perspective = 'overall'
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{

        organizedMetrics(metrics) {
            const metricOrder = ['Bugs', 'Vulnerabilities', 'Security Hotspots Reviewed', 'Code Smells', 'Coverage', 'Duplicated Lines (%)'];
            const organizedMetrics = {};
            metricOrder.forEach(metricName => {
                organizedMetrics[metricName] = 0;
            });
            if (Array.isArray(metrics)) {
                metrics.forEach(metric => {
                    if (metricOrder.includes(metric.metricName)) {
                        organizedMetrics[metric.metricName] = metric.measureValue ? metric.measureValue : 0;
                    }
                });
            } else {
                Object.keys(metrics).forEach(metricName => {
                    if (metricOrder.includes(metricName)) {
                        organizedMetrics[metricName] = metrics[metricName] ? metrics[metricName].measureValue : 0;
                    }
                });
            }

            return Object.keys(organizedMetrics).map(name => ({ name, value: organizedMetrics[name] }));
        },
        
        mysearchrequestollapse:function(){
         this.isSearchRequestActive=!this.isSearchRequestActive
        },

        calculateRating(measureValue) {
            if (measureValue <= 5) {
                return { rating: 'A', className: 'success' };
            } else if (measureValue <= 10) {
                return { rating: 'B', className: 'info' };
            } else if (measureValue <= 20) {
                return { rating: 'C', className: 'primary' };
            } else if (measureValue <= 50) {
                return { rating: 'D', className: 'warning' };
            } else {
                return { rating: 'E', className: 'danger' };
            }
        },
        calculateRatingPer(measureValue) {
            if (measureValue <= 0.05) {
                return 'A';
            } else if (measureValue <= 0.1) {
                return 'B';
            } else if (measureValue <= 0.2) {
                return 'C';
            } else if (measureValue <= 0.5) {
                return 'D';
            } else {
                return 'E';
            }
        },
        getProjectList(node) {
          if(node && node.length > 3){
            this.isLoading = true
            let url = "api/listing/getProjectAllocatedToEmp";
            this.input = {
              empid: parseInt(this.undt.userid),
              useremail: this.undt.username,
              empcode: this.undt.userid,
              projectname: node.toLowerCase(),
              isfrompage: 'listingpage'
            };
            axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
            }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                this.projectList = result.data.data;
              } else {
                this.projectList = [];
              }
            }).catch(e => {
              this.displayError(e)
            })
          }
        },

        getSonarQubeData() {
            this.isLoading = true
            let url = 'api/sonarqube/getSonarQubeReport'
            let payload = new Object({
                offset: this.pageoffset,
                limit: apiURL.LIMIT,
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                project: [this.project] ? this.project:[],
                perspective : this.perspective ? this.perspective:'overall'
            })
            axios({
            method: 'POST',
            url,
            data: payload,
            headers:{ authorization :this.tokendata }
            }).then(result => {
            this.isLoading = false
            if(result.data.errorCode == 0) {
                this.sonarqubeData = result.data.data.rows
                const groupBySonarData = result.data.data.rows.reduce((group, product) => {
                  const { componentKey } = product;
                  group[componentKey] = group[componentKey] ?? [];
                  group[componentKey].push(product);
                  return group;
                }, {});
                this.convertedArr = Object.entries(groupBySonarData);
                this.totalcount = result.data.data.count
                this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
            }
             else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
                this.sonarqubeData=[]
                this.totalcount = 0;
                this.pageCount =0;
            }
                }).catch(e => {
                this.displayError(e)
            }); 
        },
        resetRecords: function(){
            this.project = [];
            this.perspective = 'overall';
            this.getSonarQubeData();
        },
        redirecttourl: function(rowdata) {
            this.$router.push({ 
                path: '/sonarqube-details',
                query: {
                repo: rowdata[0]
                }
            });
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.currPageNum = pageNum - 1;
            this.getSonarQubeData();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#dashboard-ecommerce").offset().top -70
                });
            }, 500);
        },

    }
})
</script>
<style scoped>
.alignment{
    margin-left: 20px;
    margin-bottom: 20px;
}
.cardcenter{
    text-align: center;
    color: white;
}

@keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
    .blink {
        animation: blink 1.5s infinite;
        color: red;
    }

    .with-border {
        border-width: 0.2px;
        border-style: solid;
    }
</style>
