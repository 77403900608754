<template>
  <div class="app-content content ">
   <loading
     :active.sync="isLoading"
     :can-cancel="false"
     :is-full-page="true"
     color="orange"
     loader="dots"
     :width="100"
     :height="100"
   ></loading>
   <div class="content-overlay"></div>
   <div class="header-navbar-shadow"></div>
   <div class="content-wrapper">
     <section id="card-navigation modern-horizontal-wizard">
       <div class="content-header-left mb-1 pt-0 mobile-padding-0-10">
         <div class="container-fluid px-0">
           <div class="row breadcrumbs-top">
             <div class="col-md-9 content-header-left">
               <h3 class="content-header-title float-left mb-0">Status Configuration</h3>
               <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                   <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link :to="{ name: 'statusConfigList', params: { search: true }}"
                    >Status Configuration</router-link
                    >
                  </li>
                     <li class="breadcrumb-item"><router-link to='/statusconfig/create'>Create Status Configuration</router-link></li>
                 </ol>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card">
         <div class="card-body p-1">
           <form class="validate-form">
             <div class="row">
               <div class="col-12 ">
                 <template v-for="(formconfigure, index) in formconfigure.formdata">
                   <div v-bind:key="index" class="card border-warning" style=" margin-bottom: 1rem !important;">
                     <div class="card-header p-1 cursor-pointer" v-on:click="handleCollapse(index)" style="background:#EDF4FF">
                       <h4 class="card-title text-primary" >Add Status Configuration</h4>
                       <div class="heading-elements text-primary" >
                         <ul class="list-inline mb-0">
                           <li>
                             <a data-action="collapse" v-if="!formconfigure.showform"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                             <a data-action="collapse" v-else><minus-icon size="4x" class="custom-class"></minus-icon></a>
                           </li>
                         </ul>
                       </div>
                     </div>
                     <div v-bind:class="[{show: formconfigure.showform},card_content,collapse]">
                       <div class="card-body">
                         <form>
                           <div class="row">
                             <div class="form-group col-md-4">
                               <label for="ownername">Tracker</label>
                               <treeselect placeholder="Select Tracker" class="projectlistdd" v-model="formconfigure.tracker" :options="trackerlist"   @close="validateFormConfig(index, $event)"/>
                               <div class="errorinputmsg" v-if="errors.formconfigure.formdata[index].tracker">{{ errors.formconfigure.formdata[index].tracker }}</div>
                             </div>
                             <div class="form-group col-md-5" >
                             
                              <div class="row" v-for="(singleDocField, idx) in formconfigure.optionvalue" :key="`phoneInput-${idx}`" >
                                  <div class="form-group col-md-6" >
                                      <label class="form-label">Status Option Values </label>
                                      <input class="form-control" v-model="singleDocField.optionvalue" type="text" placeholder="Enter Option values"  />
                                      <div class="errorinputmsg" v-if="!singleDocField.optionvalue">Status Option value is required</div>
                                      </div>  

                                      <div class="form-group col-md-6" >
                                      <label class="form-label">Sequence Values </label>
                                      <input class="form-control" v-model="singleDocField.sequence" type="number" placeholder="Enter Sequence in number"  />
                                      <div class="errorinputmsg" v-if="!singleDocField.sequence">Sequence value is required</div>
                                      </div>                              
                                  </div>
                              </div> 
                          
                               <div class="form-group col-md-3" style="margin-top:20px">
                                      <div class="cofigfont-medium-3">
                                          <div class="btn btn-relief-danger mr-1" @click="removeDocumentField(index,idx)">
                                             <em class="las la-minus-square "></em>
                                          </div>
                                          <div class="btn btn-relief-primary" @click="addDocumentFields(index)">
                                          <span>
                                            <em class="las la-plus-square "></em>    
                                           </span>
                                      </div>
                                  </div>
                              </div>
                          
                          </div>
                           <div class="flex--items mb-1 mt-1">
                             <div v-if="index !== 0" class="btn btn-outline-danger"  style="padding-left: 1rem; padding-right: 1rem" @click="deleteField(index)">
                               <span>Delete</span>
                             </div>
                           </div>
                         </form>
                       </div>
                     </div>
                     
                     <div class="flex--items my-2">
                      <div class="btn btn-outline-info mr-1" style="padding-left: 1.4rem; padding-right: 1.4rem" v-on:mousedown="clearField(index)">
                        <span>Clear</span>
                      </div>
                     <button type="submit" class="btn btn-relief-primary" style="padding-left: 2rem; padding-right:2rem" v-on:click.stop.prevent="saveData(index)">Submit</button>
                    </div>
                   </div>
                 </template>
               </div>
             </div>
           </form>
         </div>
         <div class="flex--items mb-2">
         </div>
       </div>
     </section>
   </div>
 </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import DatePicker from "vue2-datepicker";
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import "vue2-datepicker/index.css";
import { PlusIcon, StarIcon, MinusIcon,MinusSquareIcon,PlusSquareIcon } from 'vue-feather-icons'
import moment from "moment";
import apiUrl from "../../constants";
import commonMethods from '../../utils/commonMethods';
export default {
 name: 'CreateFormConfiguration',
 components: {
   VueElementLoading,
   Treeselect,
   DatePicker,
   Loading,
   PlusIcon,
   MinusIcon,
   StarIcon,
   MinusSquareIcon,
   PlusSquareIcon,  
 },
 mixins: [ commonMethods ],
 data() {
   return {
    document: {
         fields: [{
          optionvalue: ''       
        }]
      },
      trackerlist:[],
      formconfigure: {
       formdata: [
         {
           tracker:null,
           optionvalue: [{
            optionvalue: '',sequence:''
            }
          ],
           ispublish:false,
           showform:true
         }
       ]
     },
      errors: {
       formconfigure: {
         formdata: [
           {
            tracker: null,
            optionvalue: [],
           }
         ]
       }
     },
     isLoading: false,
     card_content:'card-content',
     collapse:'collapse',
     tab_pane:'tab-pane', 
     fade:'fade',
     TimeFormat: apiUrl.TimeFormat,
   }
 },
 mounted() {
     let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
       userdata = JSON.parse(userdata)
       this.undt = userdata
       this.tokendata = window.localStorage.getItem('token');
      this.getTrackerList()
      }
 },
 methods: {
  getTrackerList() {
      this.isLoading = true
      let url = 'api/formconfigure/geTrackerList'
      let payload = new Object({
        moduletype: null,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.trackerlist = result.data.data
          this.trackerlist.sort((a, b) => {
              return a.label.localeCompare(b.label);
          });
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.trackerlist=[]
        }
      }).catch(e => {
                this.displayError(e)
      });
    },
  addDocumentFields(index) {
    this.formconfigure.formdata[index].optionvalue.push({
       optionvalue: null, 
       sequence: null, 
     })
    },
    removeDocumentField(index, idx) {
      if(this.formconfigure.formdata[index].optionvalue && this.formconfigure.formdata[index].optionvalue.length>1) {
        this.formconfigure.formdata[index].optionvalue.splice(idx,1);
        
      }
    },
   handleCollapse(idx) {
     this.formconfigure.formdata[idx].showform = !this.formconfigure.formdata[idx].showform
   }, 
   
   clearField(idx) {
      this.formconfigure.formdata[idx].tracker = null
      this.formconfigure.formdata[idx].optionvalue = [{
      optionvalue: '',sequence:''
       }],
      this.formconfigure.formdata[idx].ispublish = false,
      this.errors.formconfigure.formdata[idx].tracker = ``
   },
   deleteField(idx) {
     this.formconfigure.formdata.splice(idx, 1)
     this.errors.formconfigure.formdata.splice(idx, 1)
   },
   validateAllFormData() {
     let valid = true
     for(let idx=0; idx<this.formconfigure.formdata.length; idx++) {
       let singleformconfigureForm = this.formconfigure.formdata[idx]
       if(!singleformconfigureForm.tracker) {
         this.errors.formconfigure.formdata[idx].tracker = `Tracker is required`
         valid = false
       } else {
         this.errors.formconfigure.formdata[idx].tracker = ``
       }
       if(singleformconfigureForm.db_value==false && (singleformconfigureForm.titletype=='radio' || singleformconfigureForm.titletype == 'checkbox' || singleformconfigureForm.titletype == 'drop-down' || singleformconfigureForm.titletype == 'multiselect-dropdown' )) {
          for(let i=0;i<singleformconfigureForm.optionvalue.length;i++){
          if(!singleformconfigureForm.optionvalue[i].optionvalue){
            valid = false
          }
          if(!singleformconfigureForm.optionvalue[i].sequence){
            valid = false
          }
        }
        }  
     }
     return valid
   },
   validateFormConfig(idx) {
     let valid = true
     if(!this.formconfigure.formdata[idx].tracker) {
       this.errors.formconfigure.formdata[idx].tracker = `Tracker is required`
       valid = false
     } else {
       this.errors.formconfigure.formdata[idx].tracker = ``
     }
     return valid
   },
   addFormConfiguration() {
     this.formconfigure.formdata.push({
        showform: true,
        optionvalue: [{
          optionvalue: '',sequence:''
          // tracker:
       }],
      ispublish:false,  
      tracker:null    
     })
     this.errors.formconfigure.formdata.push({
      optionvalue: null,
     })
   },
   saveData(idx) {
     let isValid = this.validateAllFormData()
      if(isValid) {
        let matchingObj2 = this.trackerlist.find(obj2 => obj2.id === this.formconfigure.formdata[0].tracker);
       this.isLoading = true
       let url = 'api/statusdynamicconfig/create'
       let payload = {
         createdby: this.undt.username,
         modulestatus: this.formconfigure.formdata,
         tracker: this.formconfigure.tracker,
         useremail  : this.undt.username,
         empcode: this.undt.userid,
         moduletype:matchingObj2.label ? matchingObj2.label : ''
       }
      
       axios({
         method: 'POST',
         url,
         data: payload,
         headers: { authorization :this.tokendata }
       }).then(result => {
         this.isLoading = false
         if(result.data.errorCode == 0) {
           Swal.fire({
             title: "Success!",
             text: result.data.msg,
             icon: 'success',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
           this.$router.push({ name: 'statusConfigList', params: { search: true }})
         } else if(result.data.errorCode == 3){
             Swal.fire({
                   title: "Session Expired...!!",
                   text: result.data.msg,
                   icon: 'info',
                   customClass: {
                       confirmButton: "btn btn-primary",
                   },
                   buttonsStyling: !1,
               }).then(function() {
                 window.location.href = "/#/login";
             })
           } else {
           Swal.fire({
             title: "Failed",
             text: result.data.msg,
             icon: 'info',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
         }
       })
     }
   },
   
 }
}
</script>
 