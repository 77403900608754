<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <section id="dashboard-analytics">
                    <div class="row match-height">
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-header flex-column align-items-start pb-0">
                                    <div class="avatar bg-light-primary p-50 m-0">
                                        <div class="avatar-content">
                                            <codepen-icon size="4x" class="custom-class font-medium-5"></codepen-icon>
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder mt-1">{{this.totalprojectcount}}</h2>
                                    <p class="card-text">Total Projects</p>
                                </div>
                                <div id="gained-chart"></div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-header flex-column align-items-start pb-0">
                                    <div class="avatar bg-light-warning p-50 m-0">
                                        <div class="avatar-content">
                                            <package-icon size="4x" class="custom-class font-medium-5"></package-icon>
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder mt-1">{{this.totalfunctionality}}</h2>
                                    <p class="card-text">Total Module or Funcitonality </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-header flex-column align-items-start pb-0">
                                    <div class="avatar bg-light-warning p-50 m-0">
                                        <div class="avatar-content">
                                            <package-icon size="4x" class="custom-class font-medium-5"></package-icon>
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder mt-1">{{this.totaltasks}}</h2>
                                    <p class="card-text">Total Tasks </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="card">
                                <div class="card-header flex-column align-items-start pb-0">
                                    <div class="avatar bg-light-warning p-50 m-0">
                                        <div class="avatar-content">
                                            <package-icon size="4x" class="custom-class font-medium-5"></package-icon>
                                        </div>
                                    </div>
                                    <h2 class="font-weight-bolder mt-1">{{this.totalroles}}</h2>
                                    <p class="card-text">Total Roles </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row match-height">
                        <div class="col-lg-6 col-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row pb-50">
                                        <div class="col-sm-6 col-12 d-flex justify-content-between flex-column order-sm-1 order-2 mt-1 mt-sm-0">
                                            <div class="mb-1 mb-sm-0">
                                                <h2 class="font-weight-bolder mb-25">2.7K</h2>
                                                <p class="card-text font-weight-bold mb-2">User Statistics</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row avg-sessions pt-50">
                                        <div class="col-6 mb-2">
                                            <p class="mb-50">Total Open Task: {{this.totalopentasks}}</p>
                                            <div class="progress progress-bar-primary" style="height: 6px">
                                                <div class="progress-bar" role="progressbar" :aria-valuenow="totalopentasks" aria-valuemin="25" aria-valuemax="100" :style="{width: totalopentasks+'%'}"></div>
                                            </div>
                                        </div>
                                        <div class="col-6 mb-2">
                                            <p class="mb-50">Task Completed: {{this.totalcompletedtasks}}</p>
                                            <div class="progress progress-bar-warning" style="height: 6px">
                                                <div class="progress-bar" role="progressbar" :aria-valuenow="totalcompletedtasks" aria-valuemin="10" aria-valuemax="100" :style="{width: totalcompletedtasks+'%'}"></div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <p class="mb-50">Task Dealyed: {{this.totaltaskdelayed}}</p>
                                            <div class="progress progress-bar-danger" style="height: 6px">
                                                <div class="progress-bar" role="progressbar" :aria-valuenow="totaltaskdelayed" aria-valuemin="5" aria-valuemax="100" :style="{width: totaltaskdelayed+'%'}"></div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <p class="mb-50">Total Issues Count: {{this.totalissues}}</p>
                                            <div class="progress progress-bar-success" style="height: 6px">
                                                <div class="progress-bar" role="progressbar" :aria-valuenow="totalissues" aria-valuemin="90" aria-valuemax="100" :style="{width: 95+'%'}"></div>
                                            </div>
                                        </div>
                                        <div class="col-6 my-2">
                                            <p class="mb-50">Total CR: {{this.totalcr}}</p>
                                            <div class="progress progress-bar-success" style="height: 6px">
                                                <div class="progress-bar" role="progressbar" :aria-valuenow="totalcr" aria-valuemin="3" aria-valuemax="100" :style="{width: totalcr+'%'}"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between pb-0">
                                    <h4 class="card-title">Support Tracker</h4>
                                    <div class="dropdown chart-dropdown">
                                        <button class="btn btn-sm border-0 dropdown-toggle p-50" type="button" id="dropdownItem4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Last 7 Days
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownItem4">
                                            <a class="dropdown-item" href="javascript:void(0);">Last 28 Days</a>
                                            <a class="dropdown-item" href="javascript:void(0);">Last Month</a>
                                            <a class="dropdown-item" href="javascript:void(0);">Last Year</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 d-flex flex-column flex-wrap col-auto">
                                            <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">85</h1>
                                            <p class="card-text">Total task duration</p>
                                        </div>
                                        <div class="col-sm-10 col-12 d-flex justify-content-center">
                                            <div id="support-trackers-chart"></div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mt-1">
                                        <div class="text-center">
                                            <p class="card-text mb-50">Total Time</p>
                                            <span class="font-large-1 font-weight-bold">65hr</span>
                                        </div>
                                        <div class="text-center">
                                            <p class="card-text mb-50">Extra Time Spent</p>
                                            <span class="font-large-1 font-weight-bold">20hr</span>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../constants';
import { CodepenIcon } from 'vue-feather-icons'
import { PackageIcon } from 'vue-feather-icons'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default({
    name:'UserDashboard',
    components: {
        Loading,
        CodepenIcon,
        PackageIcon,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            un:'',
            totalprojectcount:0,
            totalfunctionality:0,
            totaltasks:0,
            totalroles:0,
            totalopentasks:0,
            totalcompletedtasks:0,
            totaltaskdelayed:0,
            totalissues:0,
            totalcr:0,
            tokendata:null
        }  
    } ,
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.undt = userdata;
                this.un=userdata.firstname+' '+userdata.lastname
                this.udata=userdata
                this.tokendata = window.localStorage.getItem('token');
                this.getfiltereddata();
                //this.sendloadmail();
            }
        }
        
    },
    methods:{
        getfiltereddata(){
            this.isLoading=true
            this.pageoffset=1
            var url = 'api/dashboard/getUserDashboardCount'
            this.input = {
                section:'dashboard',
                createdby:this.udata.emailaddress,
                empcode: this.undt.userid,
                useremail: this.undt.emailaddress
            }
            axios({
                'method': 'POST',
                'url': url,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading=false;           
                if(result.data.errorCode == 0){
                    this.totalprojectcount=result.data.data.projectcount
                    this.totalfunctionality=result.data.data.functionalitycount
                    this.totaltasks=result.data.data.taskscount
                    this.totalroles=result.data.data.rolecount
                    this.totalopentasks=result.data.data.opentaskscount
                    this.totalcompletedtasks=result.data.data.completedtaskscount
                    this.totaltaskdelayed=result.data.data.taskdelayedcount
                    this.totalissues=result.data.data.issuecount
                    this.totalcr=result.data.data.crcount
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                }else{
                    
                }
            })
        },
        sendloadmail(){      
            let input = {}
            input.email = this.udata.username
            var url = 'api/taskmaster/sendTaskCreatedMail'
            axios({
                method: "POST",
                url: url,
                data: input,
                'headers':{'authorization':this.tokendata}
            }).then(
                result => {
                if (result.data.errorCode == 100) {
                    console.log(result)
                } else {
                    this.$alertify.error(result.data.message);
                }
                // this.listPermissionData = result.data.data[0];
                },
                error => {
                console.error(error);
                check_unauthorized.unauthorized(error.response.status, error.response.data.returnMessage)
                }
            )
        }
    }
})
</script>
