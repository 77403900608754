<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-12 content-header-left px-0">
                <h3 class="content-header-title float-left mb-0">Course Module Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/training/list'>Course List</router-link></li>
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link to='/training/create'>Create Course Module</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card  filter_card_searchbgst">
          <div class="card-header py-1 px-2 border-bottom" >
            <h3 class="card-title">
                Create Course Module
            </h3>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-12">
                <div class="form form-vertical">
                  <div class="row">
                    <div class="col-md-12 mb-2">
                      <label class="font-weight-bold">Course Type: </label>
                      <div style="display: inline-block; vertical-align:bottom">
                        <label><div class="radio-cntrl ml-xs-1 ml-sm-2 mr-2 radio_button_cntr "><input type="radio" value="normal" v-model="course.coursetype" @input="isselectnumber"><span class="font-weight-bold"> Normal </span></div></label>
                        <label><div class="radio-cntrl radio_button_cntr"><input type="radio" value="one_time" v-model="course.coursetype" @input="isselectnumber"><span class="font-weight-bold">One-time (Auto publishable)</span></div></label>
                        <label><div class="radio-cntrl radio_button_cntr ml-2"><input type="radio" value="article" v-model="course.coursetype" @input="isselectnumber"><span class="font-weight-bold">Article</span></div></label>
                        <label><div class="radio-cntrl radio_button_cntr ml-2"><input type="radio" value="periodic" v-model="course.coursetype" @input="isselectnumber"><span class="font-weight-bold">Periodically</span></div></label>
                      </div>
                    </div>

                    <div class="col-md-12 mb-2" v-if="course.coursetype == 'periodic'">
                      <label class="font-weight-bold">Periodic Type: </label>
                      <div style="display: inline-block; vertical-align:bottom">
                        <label><div class="radio-cntrl ml-xs-1 ml-sm-2 mr-5 radio_button_cntr1 "><input type="radio" value="quarterly" v-model="course.periodictype" @input="isSelPeriodicType"><span class="font-weight-bold"> Quarterly </span></div></label>
                        <label><div class="radio-cntrl radio_button_cntr1"><input type="radio" value="biquarterly" v-model="course.periodictype" @input="isSelPeriodicType"><span class="font-weight-bold">Bi-Quarterly</span></div></label>
                        <label><div class="radio-cntrl radio_button_cntr1 ml-5"><input type="radio" value="annually" v-model="course.periodictype" @input="isSelPeriodicType"><span class="font-weight-bold">Annually</span></div></label>
                        <label><div class="radio-cntrl radio_button_cntr1 ml-5"><input type="radio" value="monthly" v-model="course.periodictype" @input="isSelPeriodicType"><span class="font-weight-bold">Monthly</span></div></label>
                        <label><div class="radio-cntrl radio_button_cntr1 ml-5"><input type="radio" value="daily" v-model="course.periodictype" @input="isSelPeriodicType"><span class="font-weight-bold">Daily</span></div></label>
                      </div>
                      <div class="errorinputmsg" v-if="this.errors.course.periodictype">{{ this.errors.course.periodictype }}</div>
                    </div>
                    
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                      <div class="form-group ">
                        <label for="taskdefinename">Course Name</label>
                        <input type="text" id="coursename" class="form-control" name="coursename" placeholder="Course Name" v-model="course.coursename" @blur="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="this.errors.course.coursename">{{ this.errors.course.coursename }}</div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="course.coursetype && course.coursetype == 'article'">
                      <div class="form-group">
                        <label class="form-label">Categorization/Tagging</label>
                        <treeselect placeholder="Select Categorization/Tagging"  class="projectmdllistdd capitalisetext" v-model="course.categorization" :options="categorizationlist"/>
                      </div>
                    </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 ">
                    <label for="">Course Start Date</label>
                    <div class="input-group input-group-merge date_picker_foundeds">
                      
                      <date-picker placeholder="Select Course Start Date" v-model="course.startdate" valueType="format" class="Startdate w-100" :disabled-date="notBeforeToday" @input="selectedstartdate"></date-picker>
                      <div class="errorinputmsg" v-if="this.errors.course.startdate">{{ this.errors.course.startdate}}</div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <label for="">Course End Date</label>
                    <div class="input-group input-group-merge date_picker_foundeds">
                      
                      <date-picker placeholder="Select Course End Date" v-model="course.enddate" valueType="format" class="enddate" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                      <div class="errorinputmsg" v-if="this.errors.course.enddate">{{ this.errors.course.enddate}}</div>
                    </div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6 col-md-6 col-lg-3" v-if="course.coursetype !=null && course.coursetype == 'article'">
                      <label for="projectlistdd">Number of Courses</label>
                      <treeselect placeholder="Select Number of Course" class="projectlistdd" v-model="course.nooftraining" :options="tutoriallist" @input="tutorialcount" disabled/>
                      <div class="errorinputmsg" v-if="this.errors.course.nooftraining">{{ this.errors.course.nooftraining }}</div>
                  </div>
                  <div class="form-group col-xs-12 col-sm-6 col-md-6 col-lg-3" v-else>
                      <label for="projectlistdd">Number of Courses</label>
                      <treeselect placeholder="Select Number of Course" class="projectlistdd" v-model="course.nooftraining" :options="tutoriallist" @input="tutorialcount"/>
                      <div class="errorinputmsg" v-if="this.errors.course.nooftraining">{{ this.errors.course.nooftraining }}</div>
                  </div>
                  <div class="form-group col-12">
                        <label class="form-label" for="projectmdllistdd">Course Description</label>
                        <ckeditor :editor="editor" placeholder="Course Details" v-model="course.coursedetails" :config="editorConfig" tag-name="textarea" @blur="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="this.errors.course.coursedetails">{{ this.errors.course.coursedetails }}</div>
                  </div>
                  <div v-for="(n,index) in Array(this.course.nooftraining ? this.course.nooftraining:0).fill(0)" v-bind:key="index" style="width:100%">
                      <div class="col-sm-12 "><hr></div>
                        <div class="form-group col-12" v-if="course.coursetype != 'article'">
                          <label for="projectlistdd">Course Name {{index+1}}</label>
                          <input type="text" id="" class="form-control" name="" placeholder="Course Name" v-model="trainingArr[index].trainingname" @blur="validateTraining(index, $event)"/>
                          <div class="errorinputmsg" v-if="errors.trainingArr[index].trainingname">{{ errors.trainingArr[index].trainingname }}</div>
                       </div>
                        <div class="form-group col-12" v-if="course.coursetype != 'article'">
                            <label class="form-label" for="projectmdllistdd">Course Details {{index+1}}</label>
                            <ckeditor :editor="editor" placeholder="Course Description" v-model="trainingArr[index].description" :config="editorConfig" tag-name="textarea" @blur="validateTraining(index, $event)" />
                            <div class="errorinputmsg" v-if="errors.trainingArr[index].description">{{ errors.trainingArr[index].description }}</div>
                        </div>

                        <div class="col-md-12" style="margin-top:24px">
                        <div class="form-group">
                            <div class="form-group">
                                  <div style="display:flex; align-items:center">
                                    <label :for="'attachment'+index" class="btn btn-sm btn-relief-secondary mb-0 mr-75">Select image/docs/videos</label>  
                                      <input type="file" :id="'attachment'+index" hidden multiple :accept="accepttype" ref="file" @change="onFileChange($event,index)"/>
                                      <button class="btn btn-sm btn-relief-secondary mr-75"  v-on:click.stop.prevent="onUpload(index)" >Upload</button>
                                      <progress v-if="trainingArr[index].isUploading" max="100" style="width:73%" :value.prop="trainingArr[index].uploadPercentage"></progress>
                                  </div>
                                    <div class="table-responsive my-75" v-if="trainingArr[index].attachmentview">
                                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, k) in trainingArr.attachmentview.data" :key="k">
                                                    <td>{{k+1}}</td>
                                                    <td>
                                                        <a :href="data.presignurl">{{
                                                        data.link | basename
                                                        }}</a>
                                                    </td>
                                                    <td>{{ data.info }}</td>
                                                    <td>
                                                        <button type="button" class="btn btn-sm danger" @click="removes3file(k,trainingArr.attachmentview.data)">
                                                        <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="table-responsive my-75" v-if="trainingArr[index].file.length>0">
                                        <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                            <thead>
                                                <tr>
                                                    <th scope="col">SR#</th>
                                                    <th scope="col">Document Name</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(image, key) in trainingArr[index].file" :key="key">
                                                    <td>{{ key+1 }}</td>
                                                    <td>{{ image.name}}</td>
                                                    <td>
                                                        <input type="text" class="form-control form-control-sm" placeholder="Optional description" v-model="trainingArr[index].filedescp[key]"/>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm danger" @click="removeImage(key,index)">
                                                            <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                        </div>
                  </div>
                  </div>
                  <div class="form-group col-12 mb-2 mt-2" style="margin-left:-1rem">
                    <label for="ispublished" :class="{'active': course.ispublished}" class="toggle__button">
                      <input type="checkbox" id="ispublished" v-model="course.ispublished">
                      <span class="toggle__switch"></span>
                      <span :class="{'off-lable-color': !course.ispublished, 'on-label-color': course.ispublished, 'toggle__label': true}">Publish Course</span>
                    </label>
                  </div>                    
                  <div class="col-md-6 mb-2">
                     <div class="form-group row"> 
                       <div class="col-sm-4 pr-md-0 col-form-label">
                         <label class="font-weight-bold" >Course Subscription Type: </label>
                       </div>
                       <div class="col-sm-7">
                          <div style="display: inline-block; vertical-align:bottom">
                          <label><div class="radio-cntrl ml-xs-1 ml-sm-0 mr-2 radio_button_cntr"><input type="radio" value="all_users" v-model="course.subs"><span > All Users </span></div></label>
                          <label><div class="radio-cntrl mr-2 radio_button_cntr"><input type="radio" value="dept" v-model="course.subs"><span > Subscribe by Department/Designations </span></div></label>
                          <label><div class="radio-cntrl mr-2 radio_button_cntr"><input type="radio" value="emails" v-model="course.subs"><span > Subscribe by Email IDs </span></div></label>
                          <label><div class="radio-cntrl mr-2 radio_button_cntr"><input type="radio" value="doj" v-model="course.subs"><span > Subscribe by Date of Joining </span></div></label>
                        </div>
                       </div>
                     </div>
                    
                    
                  </div>
                  <div class="col-md-6 row mb-2 px-0" v-if="course.subs === 'dept'">
                    <div class="col-md-6 form-group">
                      <label for="projectlistdd">Department</label>
                      <treeselect placeholder="Select department" class="projectlistdd" :multiple="true"  :clear-on-select="true" v-model="course.departmentid" :options="course.departmentlist" @input="selDepartment" @deselect="nullifyDesignations"/>
                      <div class="errorinputmsg" v-if="this.errors.course.departmentid">{{ this.errors.course.departmentid }}</div>
                    </div>
                    <div class="col-md-6 form-group">
                      <label for="projectlistdd">Designation</label>
                      <treeselect placeholder="Select designation" class="projectlistdd" :multiple="true"  :clear-on-select="true" :value-consists-of="valueConsistsOf" v-model="course.designationid" :options="course.designationlist" @input="selDesignation"/>
                      <div class="errorinputmsg" v-if="this.errors.course.designationid">{{ this.errors.course.designationid }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 row mb-2" v-if="course.subs === 'emails'">
                    <div class="col-md-6  form-group">
                      <label for="projectlistdd">Email Ids: <span v-if="course.emailids && course.emailids.length" class="ml-2">( Selected <strong>{{ course.emailids.length}}</strong> )</span></label>
                      <treeselect placeholder="Enter min 3 chars to search Email Ids" class="projectlistdd" :multiple="true" :clear-on-select="true" v-model="course.emailids" :options="course.empemailist" v-on:search-change="createdByChangeSearch" @input="selectEmailIds"/>
                      <div class="errorinputmsg" v-if="this.errors.course.emailids">{{ this.errors.course.emailids }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 row mb-2" v-if="course.subs === 'doj'">
                    <div class="col-md-6">
                      <label for="">Joining Date From</label>
                      <div class="input-group input-group-merge date_picker_foundeds">
                        <date-picker placeholder="Select Joining Date From" v-model="course.dojfrom" valueType="format" class="Startdate w-100" @input="selectdojstart"></date-picker>
                        <div class="errorinputmsg" v-if="this.errors.course.dojfrom">{{ this.errors.course.dojfrom}}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label for="">Joining Date To</label>
                      <div class="input-group input-group-merge date_picker_foundeds">
                        
                        <date-picker placeholder="Select Joining Date To" v-model="course.dojto" valueType="format" class="enddate w-100" :disabled-date="disabledDOJBefore" @input="selectdojto"></date-picker>
                        <div class="errorinputmsg" v-if="this.errors.course.dojto">{{ this.errors.course.dojto}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                  <div class=" mg-top1 text-right px-1">
                    <button type="button" class="btn btn-relief-primary mr-1" @click="savewizard('t48',$event)" id="submitbtnt39" v-bind:disabled="course.disblesavebtn">Submit</button>
                    <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Reset</button>
                    <router-link :to="{ name: 'TrainingList', params: { search: true }}">  <button style="margin-left:8px;" type="cancel" class="btn btn-outline-secondary">Cancel</button></router-link>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants'
import moment from "moment";
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import { Trash2Icon, CalendarIcon } from 'vue-feather-icons'

export default {
  name: 'TrainingCreate',
  components: {
    VueElementLoading,
    Treeselect,
    Loading,
    ckeditor: CKEditor.component,
    Trash2Icon,
    CalendarIcon, DatePicker
  },
  mixins: [ commonMethods ],
  data() {
    return {
      accepttype:apiUrl.uploadfiletype,
      valueConsistsOf: 'LEAF_PRIORITY',
      isActive: false,
      editor: ClassicEditor,
      date: null,
      TimeFormat: apiUrl.TimeFormat,
      course:{
        coursename: null,
        filedescp:[],
        courseduration: null,
        attachmentnew:null,
        coursedetails: null,
        nooftraining: null,
        disblesavebtn:false,
        ispublished: false,
        startdate: null,
        enddate:null,
        departmentid: null,
        designationid: null,
        designationDeptWise: {},
        departmentlist: [],
        designationlist: [],
        emailids: null,
        empemailist: [],
        dojfrom: null,
        dojto: null,
        coursetype: 'normal',
        periodictype: null,
        subs: 'all_users',
        categorization: null,
      },
      // trainingArr: Array(14).fill({trainingname:null,description:null,attachmentnew:null,filedescp:[],
      // file:[],attachmentview:null}),

      trainingArr: [
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
      ],
      errors: {
        course:{
          coursename: null,
          courseduration: null,
          coursedetails: null,
          nooftraining: null,
          disblesavebtn:false,
          startdate: null,
          enddate:null,
          departmentid: null,
          designationid: null,
          emailids:null,
          dojfrom:null,
          dojto:null,
          periodictype: null
        },
        trainingArr: [
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null},
        ],
      },
      file:[],
      isLoading: false,
      tutoriallist: [
          { id: 1, label: "1" },
          { id: 2, label: "2" },
          { id: 3, label: "3" },
          { id: 4, label: "4" },
          { id: 5, label: "5" },
          { id: 6, label: "6" },
          { id: 7, label: "7" },
          { id: 8, label: "8" },
          { id: 9, label: "9" },
          { id: 10, label: "10" },
          { id: 11, label: "11" },
          { id: 12, label: "12" },
          { id: 13, label: "13" },
          { id: 14, label: "14" },
        ],
      tokendata:null,
      validFileExtensions:apiUrl.validFileExtensions,
      categorizationlist: apiUrl.categorizationlist.sort((a, b) => a.label.localeCompare(b.label)),
    }
  },
  computed: {
    editorConfig() {
      let permission = this.$Service.verifyAccess();
        if(permission){
          let userdata = window.localStorage.getItem('userdata');
          if(userdata) {
            userdata = JSON.parse(userdata)
            let baseUrl = apiUrl.api_url;
            let empcode = userdata.userid;
            let useremail = userdata.username;
            let token = window.localStorage.getItem('token');
          
            return {
              toolbar: {
                items: [
                  'heading', '|', 'bold',
                  'italic', '|', 'bulletedList',
                  'numberedList', '|', 'insertTable', '|',
                  'undo', 'redo', '|','uploadImage', '|'
                ],
              },
              ckfinder: {
                uploadUrl: baseUrl+'api/files/s3/uploadSingleFile1?type=Files&responseType=json&empcode='+empcode+'&useremail='+useremail+'&token='+token,
                options: {
                  resourceType: 'Images',      
                },
              }
            }
          }
        }
     }
  },
  created() {},
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.empid = userdata.userid
        this.tokendata = window.localStorage.getItem('token');
        this.getDepartments()
      }
    }
  },
  methods: {
    notBeforeToday: function (date) {
      if(this.course.periodictype && this.course.periodictype != null) {
        return date < new Date(new Date().setHours(0, 0, 0, 0)) || date > moment().add(1, 'years');
      } else {
        return date < new Date(new Date().setHours(0, 0, 0, 0));
      }
    },
    disabledBefore(date) {
      let dayBefore = moment(this.course.startdate).format(this.TimeFormat);
      let dday = new Date(dayBefore)
      const beforeToday = new Date(dday.setDate(dday.getDate() + 30));
      beforeToday.setHours(0, 0, 0, 0);
      let dday2 = new Date(dayBefore)
      const dd2 = new Date(dday.setDate(dday2.getDate() + 180))
      dd2.setHours(0, 0, 0, 0);
      if(this.course.periodictype && this.course.periodictype != null) {
        return date < beforeToday;
      } else {
        return date < beforeToday || date > dd2; // Assume < 25 May Not Selected
      }
    },
    disabledDOJBefore(date) {
      let dayBefore = moment(this.course.dojfrom).format(this.TimeFormat);
      let dday = new Date(dayBefore)
      const beforeToday = new Date(dday.setDate(dday.getDate()));
      beforeToday.setHours(0, 0, 0, 0);
      let dday2 = new Date(dayBefore)
      const dd2 = new Date(dday.setDate(dday2.getDate()))
      dd2.setHours(0, 0, 0, 0)
      return date < beforeToday; // Assume < 25 May Not Selected
    },
    selectedstartdate(state){
      if(!state && this.course.coursetype !='article') {
        this.errors.course.startdate = 'Course start date is required';
        this.course.enddate = null;
      } else {
        this.errors.course.startdate = '';
      }
    },
    selectedenddate(state){
      if(!state && this.course.coursetype !='article') {
        this.errors.course.enddate = 'Course end date is required';
      } else {
        this.errors.course.enddate = '';
      }
    },
    selectdojstart(state) {
      this.course.dojto = null
      if(!state) {
        this.errors.course.dojfrom = 'DOJ From is required'
      } else {
        this.errors.course.dojfrom = ''
      }
    },
    selectdojto(state) {
      if(!state) {
        this.errors.course.dojto = 'DOJ To is required'
      } else {
        this.errors.course.dojto = ''
      }
    },
    selectEmailIds(state) {
      if(!state || (state && !state.length)) {
        this.errors.course.emailids = 'Email IDs required'
      } else {
        this.errors.course.emailids = ''
      }
    },
    isSelPeriodicType(event) {
        console.log(event)
    },  
    isselectnumber(event){
      if(event.target && event.target.defaultValue && event.target.defaultValue =='article'){
        this.course.nooftraining =1;
        this.errors.course.startdate ='';
        this.errors.course.enddate ='';
      }else{
        this.course.nooftraining =null;
      }
    },
    createdByChangeSearch: function (node){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getAllEmpEmailIds";
        this.input = {
          emailid:node.toLowerCase(),
          empcode: this.undt.userid,
          useremail: this.undt.username
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.course.empemailist = result.data.data;
          } else {
            this.course.empemailist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      } 
    },
    validText : function(inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if(inputval){
          return re.test(inputval.toLowerCase());
      }
    },
    onFileChange(e,index) {
      var validFileExtensions = apiUrl.validFileExtensions
      let selectedFiles = e.target.files;
      for (let i=0; i < selectedFiles.length; i++)
      {
        var blnValid = false;
        for (var j = 0; j < apiUrl.validFileExtensions.length; j++) {
          var sCurExtension = validFileExtensions[j];
          if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
          }
        }
          
          if (!blnValid) {
            Swal.fire({
                  title: "ERROR",
                  text: "Sorry, Invalid Type of Extension File..!!",
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
            })
            return false;
          }
            this.trainingArr[index].file.push(selectedFiles[i]);
      }
      // if(this.file.length>0) {
      //     this.trainingArr.disblesavebtn=true
      // }
    },
    removeImage (index,id) {
            if(this.trainingArr[id].file.length>0) {
                this.trainingArr[id].file.splice(index, 1);
                this.trainingArr[id].filedescp.splice(index, 1);
                this.trainingArr[id].attachmentnew && this.trainingArr[id].attachmentnew.splice(index, 1)
                // var removeimg = JSON.parse(this.trainingArr[id].attachmentnew)
                // if(removeimg){
                //     var remove = removeimg.data.splice(index,1)
                //     console.log(remove, '--remove--', removeimg.data, '--removeimg.data')
                //     if(remove.length > 0) {
                //     this.trainingArr[id].attachmentnew = JSON.stringify({data:removeimg.data})
                //     console.log(this.trainingArr[id].attachmentnew, 'attachnew---')
                //     } else {
                //     this.trainingArr[id].attachmentnew = null
                //   }
                // }
            }   
            // if(this.file.length < 1) {
            //     this.training.disblesavebtn = false
            // }      
        },
    onSelect(e){
        this.file = e.target.files[0];            
    },
    validateTraining(index) {
      if(index < 0 || index > 14) return false
      let valid = true
      if(!this.trainingArr[index].trainingname) {
        this.errors.trainingArr[index].trainingname = `Course name ${index+1} required`
        valid = false
      } else {
        this.errors.trainingArr[index].trainingname = ''
      }
      if(!this.trainingArr[index].description) {
        this.errors.trainingArr[index].description = `Course description ${index+1} required`
        valid = false
      } else {
        this.errors.trainingArr[index].description = ''
      }
      return valid
    },
    validateAllTrainingFields(index) {
      if(index < 0 || index > 14) return false
      let valid  = true;
      if(this.course.coursetype && this.course.coursetype == 'article'){
        this.trainingArr[0].trainingname = this.course.coursename;
        this.trainingArr[0].description = this.course.coursedetails;
      }
      for(let i=0; i<index; i++) {
        if(!this.trainingArr[i].trainingname) {
          this.errors.trainingArr[i].trainingname = `Course name ${i+1} required`
          valid = false
        } else {
          this.errors.trainingArr[i].trainingname = ''
        }
        if(!this.trainingArr[i].description) {
          this.errors.trainingArr[i].description = `Course description ${i+1} required`
          valid = false
        } else {
          this.errors.trainingArr[i].description = ''
        }
      }
      return valid
    },
    validateForm() {
      let valid = true
      if(!this.course.coursename) {
        this.errors.course.coursename = 'Course name is required'
        valid = false
      } else {
        this.errors.course.coursename = ''
      }
      if(!this.course.coursedetails) {
        this.errors.course.coursedetails = 'Course details required'
        valid = false
      } else {
        this.errors.course.coursedetails = ''
      }
      if(this.course.coursetype == 'periodic') {
        if(!this.course.periodictype) {
          this.errors.course.periodictype = 'Periodic Type is required.'
          valid = false
        } else {
          this.errors.course.periodictype = null
        }
      } else {
        this.errors.course.periodictype = null
      }
      // if(!this.course.courseduration) {
      //   this.errors.course.courseduration = 'Course duration required'
      //   valid = false
      // } else {
      //   this.errors.course.courseduration = ''
      // }
      if(!this.course.nooftraining) {
        this.errors.course.nooftraining = 'Number of trainings required'
        valid = false
      } else {
        this.errors.course.nooftraining = ''
      }
      if(!this.course.startdate && this.course.coursetype !='article') {
        this.errors.course.startdate = 'Course start date is required';
        valid = false;
      } else {
        this.errors.course.startdate = '';
      }
      if(!this.course.enddate && this.course.coursetype !='article') {
        this.errors.course.enddate = 'Course end date is required';
        valid = false;
      } else {
        this.errors.course.enddate = '';
      }
      if(this.course.subs !== 'all_users') {
        if(this.course.subs === 'dept') {
          if(!this.course.departmentid || (this.course.departmentid && !this.course.departmentid.length)) {
            this.errors.course.departmentid = 'Department is required'
            valid = false
          }
          if(!this.course.designationid || (this.course.designationid && !this.course.designationid.length)) {
            this.errors.course.designationid = 'Designation is required'
            valid = false
          }
        } else if(this.course.subs === 'emails') {
          if(!this.course.emailids || (this.course.emailids && !this.course.emailids.length)) {
            this.errors.course.emailids = "Email Ids required"
            valid = false
          }
        } else if(this.course.subs === 'doj') {
          if(!this.course.dojfrom) {
            this.errors.course.dojfrom = 'DOJ From is required'
            valid = false
          }
          if(!this.course.dojto) {
            this.errors.course.dojto = 'DOJ To is required'
            valid = false
          }
        }
      }
      return valid
    },
    savewizard(){    
      let valid = this.validateForm()
      let valid2 = this.validateAllTrainingFields(this.course.nooftraining)
      if(valid && valid2) {
        // this.isLoading = true;  
        this.input = {
            createdby: this.undt.username,
            useremail: this.undt.username,
            empcode: this.undt.userid, 
        }
        let apiURL='api/coursemaster/create'
        this.input.coursename=this.course.coursename
        this.input.nooftraining=this.course.nooftraining
        this.input.description=this.course.coursedetails
        // this.input.courseduration=this.course.courseduration
        this.input.startdate = this.course.startdate
        this.input.enddate = this.course.enddate

        this.input.ispublished=this.course.ispublished
        this.input.traininginfo=this.trainingArr.filter((i,index)=> index < this.course.nooftraining)
        this.isAddState=false
        this.input.coursetype = this.course.coursetype
        this.input.periodictype = this.course.periodictype
        this.input.subsriptiontype = this.course.subs
        this.input.category = this.course.categorization
        if(this.course.subs !== 'all_users') {
          this.input.isDeptSubscribed = this.course.subs === 'dept'
          if(this.course.subs === 'dept') {
            this.input.department = this.course.departmentid.map(i => parseInt(i))
            this.input.designation = this.course.designationid.map(i => parseInt(i))
            this.input.designationDeptWise = this.course.designationDeptWise
            this.input.emailids = null
            this.input.dojfrom = null
            this.input.dojto = null
          } else if(this.course.subs === 'emails') {
            this.input.emailids = this.course.emailids
            this.input.department = null
            this.input.designation = null
            this.input.dojfrom = null
            this.input.dojto = null
          } else if(this.course.subs === 'doj') {
            this.input.dojfrom = this.course.dojfrom
            this.input.dojto = this.course.dojto
            this.input.emailids = null
            this.input.department = null
            this.input.designation = null
          }
        } else {
          this.input.isDeptSubscribed = false
        }
        axios({
        'method': 'POST',
        'url': apiURL,
        'data': this.input,
        'headers':{'authorization':this.tokendata}
        })
        .then(result => {    
            this.isLoading = false;         
            if(result.data.errorCode == 0){
                this.isRequestActive = false
                this.isDetActive = true
                Swal.fire({
                    title: "Success!",
                    text: result.data.msg,
                    icon: 'success',
                    customClass: {
                        confirmButton: "btn btn-primary"
                    },
                    buttonsStyling: !1
                })
                this.$router.push({ name: 'TrainingList', params: { search: true }})
            } else if(result.data.errorCode == 3){
              Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
              })
            }else {
              Swal.fire({
                title: "",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
              })
            }
        }).catch(e => {
          this.displayError(e)
        })
        }           
    },
    resetForm() {
        this.course.coursename = null
        this.course.nooftraining = null
        this.course.coursedetails = ''
        this.course.designationid = null
        this.course.departmentid = null
        this.isActive = false
        this.course.subs = 'all_users'
        this.course.emailids = null
        this.course.empemailist = null
        this.course.dojfrom = null
        this.course.dojto = null
        this.course.ispublished = false
        this.course.coursetype = 'normal'
        this.course.startdate = null
        this.course.enddate = null
        this.file = []
        this.course.filedescp = []
        this.trainingArr = [
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
          {trainingname:null,description:null,attachmentnew:null,filedescp:[],file:[],attachmentview:null, uploadPercentage: 0, isUploading: false },
        ]
    },

    tutorialcount(state) {
      if(!state) {
        this.errors.course.nooftraining = 'Number of trainings required'
      } else {
        this.errors.course.nooftraining = null
      }
    },
    onUpload(id){
      if(this.trainingArr[id].file.length){
        this.isLoading=true
        this.trainingArr[id].isUploading = true
        this.apiURL="api/master/fileupload/uploadBulkFileToS3"
        let formData = new FormData();
        let maxsize = 0;
        let tempmodule ='training';
        if(this.course.coursetype && this.course.coursetype =='article'){
          tempmodule = 'training-article';
        }
        for (let index = 0; index < this.trainingArr[id].file.length; index++) {
            formData.append("imagefile", this.trainingArr[id].file[index]);
            maxsize+= this.trainingArr[id].file[index].size
        }
        formData.append("empcode", this.undt.userid);
        formData.append("useremail", this.undt.username);
        formData.append("moduletype", tempmodule);
        formData.append("fcount", parseInt(this.trainingArr[id].file.length));
          
          axios({
              'content-type':'multipart/form-data',
              'method': 'POST',
              'url': this.apiURL,
              'data':formData,
              'headers':{'authorization':this.tokendata},            onUploadProgress: function( progressEvent ) {
                this.trainingArr[id].uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
              }.bind(this)
          })
          .then(result => { 
              this.isLoading=false;
              this.trainingArr[id].isUploading = false
              if(result.data.status){
                  this.course.disblesavebtn=false
                  // const bindinfo = result.data.data.map((file, index) => {
                  //   return {"link":file,"info":this.trainingArr[id].filedescp[index]}                
                  // });
                      // let jsonobj = {data:bindinfo}
                      // this.trainingArr[id].attachmentnew = JSON.stringify(jsonobj);
                      this.trainingArr[id].attachmentnew = result.data.data.map((file, index) => {
                        return { link: file, info: this.trainingArr[id].filedescp[index] }
                      })
                  Swal.fire({
                      title: "Success!",
                      text: result.data.msg,
                      icon: 'success',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
              } else {
                  Swal.fire({
                      title: "",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary"
                      },
                      buttonsStyling: !1
                  })
                  this.course.documents=null
              }
          }).catch(e => {
          this.displayError(e)
        })
      
      } else {
          this.isLoading=false; 
          Swal.fire({
              title: "",
              text: 'Select image/docs/videos',
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary"
              },
              buttonsStyling: !1
          })
          // this.course.documents=null
      }
    },
    getDepartments() {
      let url = 'api/coursemaster/getDepartments'
       axios({
        method: 'POST',
        url,
        data:{useremail: this.undt.username,empcode: this.undt.userid},
        headers: { authorization: this.tokendata }
      }).then(result => {
        if(result.data.errorCode == 0) {
          this.course.departmentlist = result.data.data.rows;

          let decryptdepartmenttext = this.course.departmentlist.map(items =>{

            let tempemplabel1 = items.label ? this.decryptText(items.label) : null;
            items.label = tempemplabel1;
            return items;
          })
        } else {
          this.course.departmentlist = [];
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    getDesignationsByDept(deptid) {
      let url = 'api/master/performance/getDesignations'
       axios({
        method: 'POST',
        url,
        data: { deptid,useremail: this.undt.username, empcode: this.undt.userid },
        headers: { authorization: this.tokendata }
      }).then(result => {
        if(result.data.errorCode == 0) {
          let d = result.data.data.rows
          let dtext = d.map(iteam =>{
            let tempemplabel2 = iteam.label ? this.decryptText(iteam.label) : null;
            iteam.label = tempemplabel2;
            return iteam;
          })
          let temp = []
          deptid.forEach((e, idx) => {
            let detLabel = this.course.departmentlist.filter(d => d.id == e)
            if(detLabel && detLabel.length) {
              temp.push({id: e, label: detLabel[0].label, children: []})
              let des = d.filter(data => data.departmentid == e)
              if(des && des.length) {
                des.forEach(l => {
                  temp[idx].children.push(l)
                })
              }
            }
          })
          this.course.designationlist = temp;
        } else {
          this.course.designationlist = [];
        }
      }).catch(e => {
          this.displayError(e)
        })
    },
    selDepartment(state) {
      if(state && state.length > 0) {
        this.errors.course.departmentid = ''
        for(let deptIdKey of state) {
          if(!this.course.designationDeptWise.hasOwnProperty(deptIdKey)) {
            this.course.designationDeptWise[deptIdKey] = []
          }
        }
        for(let key in this.course.designationDeptWise) {
          if(!state.includes(parseInt(key))) {
            delete this.course.designationDeptWise[key]
          }
        }
        this.getDesignationsByDept(state)
      } else {
        this.course.designationid = null
        this.course.designationlist = []
        this.errors.course.departmentid = 'Department is required.'
      }
    },
    selDesignation(state) {
      if(state && state.length > 0) {
        this.errors.course.designationid = ''
        this.arrangeDesignationsDeptWise(state)
      } else {
        this.errors.course.designationid = 'Designation is required.'
      }
    },
    nullifyDesignations() {
      this.course.designationid = null
    },
    arrangeDesignationsDeptWise(state) {
      for(let dept of this.course.designationlist) {
        if(!this.course.designationDeptWise.hasOwnProperty(dept.id)) {
          this.course.designationDeptWise[dept.id] = []
          // get selected designations of this dept key
          let deptDetails = this.course.designationlist.find(i => i.id == dept.id)
          let currDeptDesignations = deptDetails ? deptDetails.children.map(i => i.id) : []
          for(let item of currDeptDesignations) {
            let isDesingSel = state.includes(item)
            if(isDesingSel && !this.course.designationDeptWise[dept.id].includes(item)) {
              this.course.designationDeptWise[dept.id].push(item)
            }
            if(!isDesingSel && this.course.designationDeptWise[dept.id].includes(item)) {
              const idx = this.course.designationDeptWise[dept.id].indexOf(item)
              if(idx > -1) {
                this.course.designationDeptWise[dept.id].splice(idx, 1)
              }
            }
          }
        } else {
          let deptDetails = this.course.designationlist.find(i => i.id == dept.id)
          let currDeptDesignations = deptDetails ? deptDetails.children.map(i => i.id) : []
          for(let item of currDeptDesignations) {
            let isDesingSel = state.includes(item)
            if(isDesingSel && !this.course.designationDeptWise[dept.id].includes(item)) {
              this.course.designationDeptWise[dept.id].push(item)
            }
            if(!isDesingSel && this.course.designationDeptWise[dept.id].includes(item)) {
              const idx = this.course.designationDeptWise[dept.id].indexOf(item)
              if(idx > -1) {
                this.course.designationDeptWise[dept.id].splice(idx, 1)
              }
            }
          }
        }
      }
    }
  }

}
</script>
<style>
.radio_button_cntr1 input[type="radio"] {
    border: 2px solid #ff9f43;
    width: 16px;
    height: 16px;
}

.radio_button_cntr1 input[type="radio"]::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: scale(0);
    transition: 87ms transform ease-in-out;
    box-shadow: inset 1em 1em #ff9f43;
    background-color: #ff9f43;
    cursor: pointer;
}


</style>