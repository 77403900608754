<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-1">Test Cycle Set</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">Test Execution</li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TestCycleSetList', params: { search: true }}">Test Cycle Set's</router-link></li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">Create Test Cycle Set</li>
                        </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header py-1 px-2" style="background-color: #fff; border-bottom:1px solid #dadddd">
                  <h3 class="card-title">
                      Create Test Cycle Set
                  </h3>
                </div>
                <div>
                    <div class="card-body">
                        <form class="form form-vertical">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectlistdd">Test Cycle Name</label>
                                        <input type="text" id="testcasename" class="form-control" name="" placeholder="Enter Test Cycle Name" v-model="testcycle.cyclename" @blur="validateForm('t39',$event)"
                                        v-bind:class="{ 
                                        'form-control': true,
                                        '': !validText(testcycle.cyclename) && nameBlured, }"
                                        v-on:blur="nameBlured = true" @close="validateForm('t39',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.testcycle.cyclename && !validText(testcycle.cyclename)">{{ this.errors.testcycle.cyclename }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Test Case Group Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" :multiple="true" :clear-on-select="true" placeholder="Enter Min 4 Chars To Search Test Case Group Name" v-model="testcycle.tcgroup" :options="testcycle.tcgrouplist" @select="seltestcstcgroup" @close="validateForm('t39',$event)" v-on:search-change="getTestCaseApprovedstatus"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.tcgroup">{{ this.errors.testcycle.tcgroup }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Business Task Code</label>
                                        <treeselect class="projectmdllistdd capitalisetext"  placeholder="Enter min 8 char of business task code" v-model="testcycle.businesscode" :options="testcycle.btccodelist" @input="selectbusinesscode" :disabled="isBTMcodeselected" @close="validateForm('t39',$event)" v-on:search-change="getbtccodelist"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.businesscode">{{ this.errors.testcycle.businesscode }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Business Task Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext"  placeholder="Enter min 5 char of Business Task Name" v-model="testcycle.businessname" :disabled="isBTMnameselected" :options="testcycle.btcnamelist" @input="selectbusinessname" @close="validateForm('t39',$event)" v-on:search-change="getbtcnamelist"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.businessname">{{ this.errors.testcycle.businessname }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectlistdd">Version</label>
                                        <input type="text"  class="form-control capitalisetext"  v-model="testcycle.version" placeholder="Enter version" @blur="validateForm('t39',$event)" v-bind:class="{ 
                                        'form-control': true,
                                        '': !validText2(testcycle.version) && versionBlured, }"
                                        v-on:blur="versionBlured = true" @close="validateForm('t39',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.testcycle.version && !validText2(testcycle.version)">{{ this.errors.testcycle.version }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group datePicker">
                                    <label for="" class="d-block">Start Date</label>
                                        <date-picker placeholder="Select Start Date"
                                        type="datetime" format="YYYY-MM-DD HH:mm" v-model="testcycle.startdate" valueType="format" class="startdate" id="StartDate" v-on:change="validateDate('startdate', $event)" :minute-step="5" :disabled-date="notBeforeTodaydate"></date-picker>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.startdate">{{ this.errors.testcycle.startdate }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group datePicker">
                                    <label for="" class="d-block">End Date</label>
                                        <date-picker placeholder="Select End Date" type="datetime" format="YYYY-MM-DD HH:mm" v-model="testcycle.enddate" valueType="format" class="startdate" id="EndDate" v-on:change="validateDate('enddate', $event)" :minute-step="5" :disabled-date="notBeforeTodaydate"></date-picker>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.enddate">{{ this.errors.testcycle.enddate }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Status</label>
                                        <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select Status" v-model="testcycle.status" :options="statuslist" @close="validateForm('t39',$event)" @input="selectstatus" disabled/>
                                        <div class="errorinputmsg" v-if="this.errors.testcycle.status">{{ this.errors.testcycle.status }}</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="col-12 mg-top1 text-right px-0">
                            <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard($event)" id="submitbtnt39">Submit</button>
                            <button style="margin-right:2px;" type="reset" class="btn btn-outline-secondary" @click="resetForm()">Reset</button>
                            <router-link :to="{ name: 'TestCycleSetList', params: { search: true }}">  <button style="margin-left:8px;" type="cancel" class="btn btn-outline-secondary">Cancel</button></router-link>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </div>        
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';

export default {
    name:'tcreate',
    mixins: [ commonMethods ],
    components:{
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,DatePicker,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
    },    
    data() {
        return {
            isLoading: false,
            fullPage: true,
            TimeFormat: apiUrl.TimeFormat,
            statuslist: apiUrl.tcscriptstatuslist.sort((a, b) => a.label.localeCompare(b.label)),
            isBTMcodeselected: false,
            isBTMnameselected: false,
            testcycle:{
                cyclename: null,
                testcycle: null,
                tcgrouplist: [],
                businesscode: null,
                businessname: null,
                assignto: null,
                startdate: null,
                enddate: null,
                tcgroup: null,
                btccodelist: [],
                btcnamelist: [],
                assigntolist: [],
                status: null,
                version: null
            },
            isAddState:false,
            pagefeatures:[],
            errors:{
                testcycle:{
                    cyclename: null,
                    businesscode: null,
                    businessname: null,
                    assignto: null,
                    startdate: null,
                    enddate: null,
                    tcgroup: null,
                    status: null,
                    version: null
                }
            },
            tokendata:null,
            nameBlured: false,
            versionBlured: false,
        }
    },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            if (this.$route.params && this.$route.params.backbtnstate === 'createTcycle') {
                this.getbtccodelist(null,this.$route.params.clickeddata.moduleid);
                this.getbtcnamelist(null,this.$route.params.clickeddata.moduleid);
                this.getTestCaseApprovedstatusbybct(this.$route.params.clickeddata.moduleid);
                
                this.isBTMnameselected = true
                this.isBTMcodeselected = true
                this.testcycle.status = 'Created'
            }else{
                this.testcycle.status = 'Created'
            }
        }
    },
    methods:{
        selectassignee(state) {
            if(!state) {
                this.errors.testcycle.assignto = "Assign to required"
            } else {
                this.errors.testcycle.assignto = ""
            }
        },
        selectstatus(state) {
            if(!state) {
                this.errors.testcycle.status = "Status required"
            } else {
                this.errors.testcycle.status = ""
            }
        },
        selectbusinesscode(state) {
            if(!state) {
                this.errors.testcycle.businesscode = "Business task code required"
                this.isBTMcodeselected = false
                this.isBTMnameselected = false
                this.testcycle.businessname = null
                this.testcycle.businesscode = null
                this.testcycle.assignto = null
            } else {
                this.errors.testcycle.businesscode = ""
                if (!this.$route.params.clickeddata) {
                    if (this.testcycle.btccodelist!=[]) {
                        this.getbtcnamelist(null,state)
                    }else{
                        let bname = this.testcycle.btcnamelist.find(l => l.id == state)
                        this.testcycle.businessname = bname.id
                    }
                    this.isBTMnameselected = false
                    this.isBTMcodeselected = true
                }
            }
        },
        selectbusinessname(state) {
            if(!state) {
                this.errors.testcycle.businessname = "Business task name required"
                this.isBTMnameselected = false
                this.isBTMcodeselected = false
                this.testcycle.businessname = null
                this.testcycle.businesscode = null
                this.testcycle.assignto = null
            } else {
                this.errors.testcycle.businessname = ""
                if (!this.$route.params.clickeddata) {
                    if (this.testcycle.btcnamelist!=[]) {
                        this.getbtccodelist(null,state)
                    }else{
                        let code = this.testcycle.btccodelist.find(j => j.businesstaskmasterid == state)
                        this.testcycle.businesscode = code.id
                    }

                    this.isBTMcodeselected = false
                    this.isBTMnameselected = true
                    
                }
            }
        },
        notBeforeTodaydate: function (date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        getTestCaseApprovedstatus(node){
            if(node && node.length > 3){
                this.input={
                    useremail  : this.undt.username,
                    empcode: this.undt.userid,
                    groupname: node.toLowerCase()
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getTestCaseApprovedstatus',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.testcycle.tcgrouplist = result.data.data.rows;
                    } else {
                        this.testcycle.tcgrouplist = [];
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        getbtccodelist(node,data1){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            if (node && node.length>8) {
                this.input.taskcode = node.toUpperCase()
            }
            if (!node) {
                this.input.businessid=parseInt(data1)
            }
            if ((this.input.taskcode) || this.input.businessid) {
                this.isLoading=true
                
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getbtccodelist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {      
                this.isLoading=false       
                if(result.data.errorCode == 0){
                    this.testcycle.btccodelist = result.data.data.rows;
                    if (data1) {
                        this.testcycle.businesscode = result.data.data.rows[0].id;
                    }
                } else {
                    this.testcycle.btccodelist = [];
                }
            }).catch(e => {
    this.displayError(e)
    })
        }
        },
        getbtcnamelist:function(node, instanceId){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            if (node && node.length>4) {
                this.input.taskname = node.toUpperCase()
            }
            if (!node) {
                this.input.businessid = parseInt(instanceId)
            }
            if ((this.input.taskname) || this.input.businessid) {
                this.isLoading=true
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getbtcnamelist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {    
                this.isLoading=false         
                if(result.data.errorCode == 0){
                    this.testcycle.btcnamelist = result.data.data.rows;
                    if (instanceId) {
                        this.testcycle.businessname = result.data.data.rows[0].id;
                    }
                } else {
                    this.testcycle.btcnamelist = []
                }
            }).catch(e => {
    this.displayError(e)
    })
        }
        },
        getassigntolist(id){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                projectid: id,
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getassigntolist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.testcycle.assigntolist = result.data.data.rows;
                } else {
                    this.testcycle.assigntolist = []
                }
            }).catch(e => {
    this.displayError(e)
    })
        },
        validateDate(mdl) {
            let startDate = this.testcycle.startdate;
            let endDate = this.testcycle.enddate;
            if(mdl === 'enddate') {
                if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.testcycle.enddate = "End date should be greater than start date";
                    this.testcycle.enddate = '';
                } else {
                this.errors.testcycle.enddate = '';
                }
            } else if(mdl === 'startdate') {
                if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.testcycle.startdate = "Start date should be lesser than end date";
                    this.testcycle.startdate = '';
                } else {
                    this.errors.testcycle.startdate = '';
                }
            }
        },
        seltestcstcgroup(state){
            this.testcycle.tcgroup = null
            this.testcycle.tcgroup = state.id
            if(!state && !this.isAddState){
                this.errors.testcycle.tcgroup='Test case group name required';
            }else{                
                this.errors.testcycle.tcgroup=null;
            }
            if(state && (state.businesstaskmasterid && state.businesstaskmasterid !=null)){
                this.getbtccodelistbyid([state.businesstaskmasterid]);
            }
        },
        resetForm() {
            this.nameBlured = false;
            this.testcycle.cyclename = null;
            this.testcycle.tcgroup = null;
            this.testcycle.businesscode = null;
            this.testcycle.businessname = null;
            this.testcycle.assignto = null;
            this.testcycle.startdate = null;
            this.testcycle.enddate = null;
            this.testcycle.version = null;
            this.versionBlured = false;
            this.testcycle.tcgrouplist = [];
            this.testcycle.btccodelist = [];
            this.testcycle.btcnamelist = [];
            this.clearerrorForm();
        },
        savewizard(objThis){
            this.valid = this.validateForm(objThis);
            if (this.valid) {
                this.isLoading = true;  
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/testcycle/testcyclecreate'
                this.input.cyclename=this.testcycle.cyclename
                this.input.tcgroup=this.testcycle.tcgroup
                // this.input.businesscode=this.testcycle.businesscode
                this.input.businesstaskmasterid=this.testcycle.businessname
                this.input.startdate=this.testcycle.startdate
                this.input.enddate=this.testcycle.enddate
                this.input.empcode = this.undt.userid
                this.input.status= this.testcycle.status
                this.input.version = this.testcycle.version
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.resetForm()
                        this.$router.push({ name: 'TestCycleSetList', params: { search: true }})
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        validateForm(mdl, objThis){
            this.clearerrorForm();
            this.isAddState=false
            let isValid=true;
            this.nameBlured = true;
            this.versionBlured = true
            this.scenarioBlured = true;
            if(mdl=="t39"){
                if(!this.testcycle.cyclename){
                    this.errors.testcycle.cyclename="Test cycle name required";
                    isValid= false;
                }
                if(!this.testcycle.startdate){
                    this.errors.testcycle.startdate="Start date required";
                    isValid= false;
                }
                if(!this.testcycle.enddate){
                    this.errors.testcycle.enddate="End date required";
                    isValid= false;
                }
                if(!this.testcycle.assignto){
                    this.errors.testcycle.assignto="Assign to required";
                    isValid= false;
                }
                if(!this.testcycle.businesscode){
                    this.errors.testcycle.businesscode="Business task code required";
                    isValid= false;
                }
                if(!this.testcycle.businessname){
                    this.errors.testcycle.businessname="Business task name required";
                    isValid= false;
                }
                if (this.testcycle.tcgroup ==null || this.testcycle.tcgroup ==[] || this.testcycle.tcgroup.length==0) {
                    this.errors.testcycle.tcgroup="Test case group name required";
                    isValid= false;
                }
                if(!this.testcycle.status){
                    this.errors.testcycle.status="Status required";
                    isValid= false;
                }
                if(!this.testcycle.version){
                    this.errors.testcycle.version="Version is required";
                    isValid= false;
                }
            }
            return isValid
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        clearerrorForm(){
            this.errors={
                testcycle:{
                    cyclename: null,
                    tcgroup: null,
                    businesscode: null,
                    businessname: null,
                    assignto: null,
                    startdate: null,
                    enddate: null,
                    assigntolist: [],
                    version: null
                }
            }
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        getbtccodelistbyid(btcode){
            if (btcode && btcode.length>0) {
                this.input={
                    useremail  : this.undt.username,
                    empid: this.undt.userid,
                    empcode: this.undt.userid,
                    btcode: btcode,
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getsearchbtccodelist',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    if(result.data.errorCode == 0){
                        this.testcycle.btccodelist = result.data.data.rows;
                        this.testcycle.businesscode = this.testcycle.btccodelist[0].id;
                    } else {
                        this.testcycle.btccodelist = [];
                    }
                }).catch(e => {
    this.displayError(e)
    })
            }
        },
        getTestCaseApprovedstatusbybct(btcid){
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
            }
            if(btcid && btcid !=null){
                this.input.businesstaskid = parseInt(btcid);
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getTestCaseApprovedstatus',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.testcycle.tcgroup = result.data.data.rows.map(d => d.id);
                    this.testcycle.tcgrouplist = result.data.data.rows;
                } else {
                    this.testcycle.tcgroup = null;
                    this.testcycle.tcgrouplist =[];
                }
            }).catch(e => {
    this.displayError(e)
    })
        },
    },
    filters: {
      basename: function (value) {
          if (!value) return ''
          return value.substr(value.lastIndexOf("/") + 1)
      }
    }
}
</script>