<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12 pb-1">
              <h3 class="content-header-title float-left mb-0">Escalation Matrix Configuration</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                    <router-link to="/escalation_matrix/escalationlist">Escalation Matrix List</router-link>
                  </li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Update </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card">
         <div class="card-body p-1">
           <form class="validate-form">
             <div class="row">
               <div class="col-12 ">
                 <template>
                   <div class="card border-warning" style=" margin-bottom: 1rem !important;">
                     <div class="card-header p-1 cursor-pointer" v-on:click="handleCollapse(index)" style="background:#EDF4FF">
                       <h4 class="card-title text-primary" >Add Escalation Matrix Configuration</h4>
                       <div class="heading-elements text-primary" >
                         <ul class="list-inline mb-0">
                           <li>
                             <a data-action="collapse" v-if="!formconfigure.showform"><plus-icon size="4x" class="custom-class"></plus-icon></a>
                             <a data-action="collapse" v-else><minus-icon size="4x" class="custom-class"></minus-icon></a>
                           </li>
                         </ul>
                       </div>
                     </div>
                     <div>
                       <div class="card-body">
                         <form>
                           <div class="row">
                             <div class="col-12 col-md-3">
                                <div class="form-group mb-1">
                                <label for="projectmdllistdd">Project Name</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="esc.project" :options="esc.projectlist" @input="selproject" @close="validateForm"/>
                                    <div class="errorinputmsg" v-if="errors.project">{{ errors.project }}</div>
                                </div>

                            </div>
                            <div class="col-12 col-md-3">
                                <div class="form-group mb-1">
                                <label for="projectmdllistdd">Email Frequency</label>
                                    <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Email Frequency" v-model="esc.frequency" :options="esc.frequencylist"  @close="validateForm($event)"/>
                                    <div class="errorinputmsg" v-if="this.errors.frequency">{{ this.errors.frequency }}</div>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-3">
                                <div class="form-group mg-bot0">
                                <label class="form-label" for="spendtime">Start Time</label>
                                    <div id="spendtime">
                                    <vue-timepicker type="time" v-model="esc.starttime" format="HH:mm"  @close="validateForm()"></vue-timepicker>
                                    </div>
                                    <div class="errorinputmsg" v-if="this.errors.starttime">{{ this.errors.starttime }}</div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3" v-for="dlEmailOption in projectwisedllist" :key="dlEmailOption.id" >
                              <label for="allotedto">DL Email :- </label>
                              <label style="color: blue;">{{ dlEmailOption.label }}</label>
                                    <treeselect class="allotedto capitalisetext" placeholder="Select role" v-model="esc.dlemail" :options="isdlemaillist"/>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col-md-12">
                            <div class="form-group">
                                
                                <div class="form-check">
                                <input class="form-check-input"  v-model="isrolewise"  type="checkbox" id="toroleCheckbox" >
                                <label class="form-check-label" for="myCheckbox">
                                    Role Wise</label>
                                </div>
                                </div>
                            </div>
                            <div class="col-md-3" v-if="isrolewise==true" >
                                <div class="form-group mb-1">
                                    <label for="allotedto">To Roles</label>
                                    <treeselect class="allotedto capitalisetext" :multiple="true" placeholder="Select role" v-model="esc.roleid" :options="rolelist" @close="validateForm('t42',$event)"/>
                                    <!-- <div class="errorinputmsg" v-if="this.errors.esc.roleid">{{ this.errors.esc.roleid }}</div> -->
                            </div>
                        </div>
                        <div class="col-md-3" v-if="isrolewise==true" >
                            <div class="form-group mb-1">
                                <label for="allotedto">CC Roles</label>
                                <treeselect class="allotedto capitalisetext" :multiple="true" placeholder="Select role" v-model="esc.ccroleid" :options="rolelist" @close="validateForm('t42',$event)"/>
                                <!-- <div class="errorinputmsg" v-if="this.errors.esc.ccroleid">{{ this.errors.esc.ccroleid }}</div> -->
                            </div>

                        </div>
                        <div class="col-md-4" v-for="(formconfigure, index) in formconfigure.formdata">
                        <div class="form-group col-md-12" v-if="isrolewise==false">
                             
                              <div class="row " v-for="(singleDocField, idx) in formconfigure.optionvalue" :key="`phoneInput-${idx}`" >
                                
                                  <div class="form-group col-md-8 px-0" >
                                      <label class="form-label">To Email </label>
                                      <input class="form-control" v-model="singleDocField.toemail" type="text" placeholder="Enter Option values" @input="validateEmail(singleDocField)"  />
                                      <div class="errorinputmsg" v-if="!isEmailValid(singleDocField.toemail)">
                                      {{ getEmailValidationMessage(singleDocField.toemail) }} </div> 
                                    </div> 
                                  <div class="cofigfont-medium-3 col-sm-4">
                                        <label class="w-100 d-block">&nbsp;</label>
                                          <div class="btn btn-relief-danger mr-1" @click="removeDocumentField(index,idx)">
                                             <em class="las la-minus-square "></em>
                                          </div>
                                          <div class="btn btn-relief-primary" @click="addDocumentFields(index)">
                                          <span>
                                            <em class="las la-plus-square "></em>    
                                           </span>
                                      </div>                            
                                  </div>                                     
                              </div>
                            </div>
                            </div>
          <!-- ccemail -->
                        <div class="col-md-4" v-for="(formconfigure1, index) in formconfigure1.formdata">
                        <div class="form-group col-md-12" v-if="isrolewise==false">
                              <div class="row " v-for="(singleDocField, idx) in formconfigure1.optionvalue" :key="`phoneInput-${idx}`" >
                                    <div class="form-group col-md-8 px-0" >
                                    <label class="form-label">CC Email </label>
                                    <input class="form-control" v-model="singleDocField.ccemail" type="text" placeholder="Enter CC Email" @input="validateCCEmail(singleDocField)"  />
                                    <div class="errorinputmsg" v-if="!isCCEmailValid(singleDocField)">
                                      {{ getCCEmailValidationMessage(singleDocField) }}
                                    </div>
                                    </div>  
                                    <div class="cofigfont-medium-3 col-sm-4">
                                      <label class="w-100 d-block">&nbsp;</label>
                                        <div class="btn btn-relief-danger mr-1" @click="removeDocumentField1(index,idx)">
                                           <em class="las la-minus-square "></em>
                                        </div>
                                        <div class="btn btn-relief-primary" @click="addDocumentFields1(index)">
                                        <span>
                                          <em class="las la-plus-square "></em>    
                                         </span>
                                    </div>                            
                                </div>                                     
                            </div>
                        </div>
                        </div> 
                            <div class="col-md-12" style="margin-left: -1rem">
                            <label for="isactive" :class="{'active': esc.isactive}" class="toggle__button">
                              <input type="checkbox" id="isactive" v-model="esc.isactive">
                              <span class="toggle__switch"></span>
                              <span :style="{'color': esc.isactive ? 'green' : 'black'}" class="toggle__label">
                                {{ esc.isactive ? 'Active' : 'Inactive' }}
                              </span>
                            </label>
                          </div>


                            <div class="col-md-12  text-right">
                                <label class="w-100 d-block">&nbsp;</label>
                                <div class="btn btn-outline-info mr-1" style="padding-left: 1.4rem; padding-right: 1.4rem" v-on:mousedown="clearField(index)">
                                    <span>Clear</span>
                                </div>
                                <button type="submit" class="btn btn-relief-primary" style="padding-left: 2rem; padding-right:2rem" v-on:click.stop.prevent="updateData(index)">Submit</button>
                            </div>
                          </div>
                           
                         </form>
                       </div>
                     </div>
                     
                     
                   </div>
                 </template>
               </div>
             </div>
           </form>
         </div>
         <div class="flex--items mb-2">
         </div>
       </div>
        </section>
      </div>
    </div>
  </template>
  <style>
  .createbtnparentdiv {
    padding-right: 0% !important;
  }
  .displayblk {
    display: block !important;
  }
  .createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  </style>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import vSelect from "vue-select";
  import commonMethods from '../../utils/commonMethods';
  import { PlusIcon,MinusSquareIcon,PlusSquareIcon,MinusIcon } from 'vue-feather-icons'
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import Pagination from "vue-pagination-2";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import VueTimepicker from 'vue2-timepicker'
  
  export default {
    name: "escalationupdate",
    mixins: [ commonMethods ],
  
    components: {
      Loading,
      VueElementLoading,
      Treeselect,
      PlusIcon,
      DatePicker,
      VueTimepicker,
      MinusIcon,
      MinusSquareIcon,PlusSquareIcon
    },
    data() {
      return {
        rolelist: [],

        indItHelpDesk: apiUrl.indItHelpDesk,
        ifItHelpDeskNotFound: apiUrl.ifItHelpDeskNotFound,
        isThisBusinessRole: false,
        roleid: 0,
        productlabelname: null,
        loginuser: "",
        isLoading: false,
        fullPage: true,
        stylevariation: apiUrl.stylevariation,
        companymasterlist: [],
        editRowData: [],
        // isEmailValid:false,
        isrolewise:true,
        projectwisedllist:[],
        esc: {
          project: null,
          dlemail:null,
          roleid:null,
          ccroleid: null,
          isrolewise:true,
          nonrole:false,
          projectlist: null,
          frequency:null,
          frequencylist:apiUrl.frequencylist,
          starttime:'',
          email:'',
          ccemail:'',
          isactive: false,
          mrtypelist:[]
        },
        file: [],
        isAddState: false,
        totalcount: 0,
        pageCount: 0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures: [],
        isSearchRequestActive: false,
        card_content: "card-content",
        collapse: "collapse",
        errors: {
            project: null,
            field: null,
            email: '',
            ccemail:'',
            roleid:null,
            ccroleid: null,
            isrolewise:true,
            nonrole:false,
            frequency:null,
            starttime:'',
            email:'',
            ccemail:'',
            isactive: false,
        },
        index:0,
        isDetActive: true,
        isRequestActive: true,
        isedit: false,
        card: "card",
        border_warning: "border-warning",
        tokendata: null,
        userrole:null,


        formconfigure: {
        formdata: [
            {
            project:null,
            isrolewise:null,
            roleid:null,
            ccroleid: null,
            isrolewise:true,
            nonrole:false,
            frequency:null,
            starttime:'',
            email:'',
            ccemail:'',
            isactive: false,

            optionvalue: [{
                optionvalue: '',
                sequence:'',
                toemail: "",
                ccemail: "",

                }
            ],
            ispublish:false,
            showform:true
            },
            
        ]
      },
      formconfigure1: {
        formdata: [
            {
            email:'',
            ccemail:'',
            optionvalue: [{
                optionvalue: '',sequence:''
                }
            ],
            ispublish:false,
            showform:true
            },
            
        ]
      },
        isdlemaillist:[
            {
            "id":"to","label":"To",
            },
            {
            "id":"cc","label":"Cc",
            },
            {
            "id":"both","label":"Both",
            },
        ],
        escalationbyid:[],
        errors: {
            singleDocField: {
            optionvalue: '',
            toemail:'',
            ccemail:''
            },
        }
        
      };
    },
    mounted() {
      let userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.loginusername = userdata.username;
        this.loginuscondition = userdata.userid;
        this.tokendata = window.localStorage.getItem("token");
        this.userrole = window.localStorage.getItem("userrole")
        this.roleid = this.undt.roleid;
        this.isThisBusinessRole = true;
        this.getEscalationByIds(this.$route.query.id)
        this.getProjectAllocatedToEmp(); 
        this.getThirdPartyAPIcall();
        this.getFrequencyList()
      }
  
    },
    computed:{
        mappedDLEmailList() {
        return this.projectwisedllist.map((dlEmail) => {
          const matchingType = this.isdlemaillist.find(
            (type) => dlEmail.label.toLowerCase().includes(type.label.toLowerCase())
          );
          return {
            ...dlEmail,
            id: matchingType ? matchingType.id : null,
          };
        });
      },
    },
    methods: {
        handleCollapse(idx) {
            this.formconfigure.formdata[idx].showform = !this.formconfigure.formdata[idx].showform
        }, 
        handleCollapse1(idx) {
            this.formconfigure1.formdata[idx].showform = !this.formconfigure1.formdata[idx].showform
        }, 

        validateEmail(singleDocField) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const inRegex = /\.in$/i;
        const comRegex = /\.com$/i;

        if (emailRegex.test(singleDocField.toemail)) {
          if (inRegex.test(singleDocField.toemail) || comRegex.test(singleDocField.toemail)) {
            this.$set(singleDocField, 'isEmailValid', true);
            this.$set(this.errors.singleDocField, 'optionvalue', '');
          } else {
            this.$set(singleDocField, 'isEmailValid', false);
            this.$set(this.errors.singleDocField, 'optionvalue', 'Only allowed .in or .com');
          }
        } 
        else {
          this.$set(singleDocField, 'isEmailValid', false);
          this.$set(this.errors.singleDocField, 'optionvalue', 'Invalid Email');
        }
      },
      isEmailValid(email) {
        return email && email.isEmailValid;
      },
      getEmailValidationMessage(email) {
        return email ? this.errors.singleDocField.optionvalue : 'Email is required';
      },

      validateCCEmail(singleDocField) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const isValidEmail = emailRegex.test(singleDocField.ccemail);

          this.$set(this.errors.singleDocField, 'ccemail', isValidEmail ? '' : 'Invalid CC Email');
        },
        isCCEmailValid(singleDocField) {
          return this.errors.singleDocField.ccemail === '';
        },
        getCCEmailValidationMessage(singleDocField) {
          return this.errors.singleDocField.ccemail;
        },

        addDocumentFields1(index) {
            this.formconfigure1.formdata[index].optionvalue.push({
                optionvalue: null, 
                email:'',
                ccemail:'',
            })
        },

        addDocumentFields(index) {
            this.formconfigure.formdata[index].optionvalue.push({
                optionvalue: null, 
                email:'',
                ccemail:'',
            })
        },
        removeDocumentField(index, idx) {
            if(this.formconfigure.formdata[index].optionvalue && this.formconfigure.formdata[index].optionvalue.length>1) {
                this.formconfigure.formdata[index].optionvalue.splice(idx,1);
                
            }
        },
        removeDocumentField1(index, idx) {
            if(this.formconfigure1.formdata[index].optionvalue && this.formconfigure1.formdata[index].optionvalue.length>1) {
                this.formconfigure1.formdata[index].optionvalue.splice(idx,1);
                
            }
        },

        getEscalationByIds(id){
            this.input = {
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
                id: parseInt(id)
            }
            axios({
                'method': 'POST',
                'url': 'api/escalation/getEscalationById',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.status == true){
                this.escalationbyid = result.data.data.rows
                this.openEditModule(this.escalationbyid[0])
                }else{
                    this.escalationbyid=[]
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        openEditModule: function (currRowData) {
          this.editRowData = currRowData;
          this.esc.project = currRowData.projectid;
          this.esc.frequency = currRowData.emailfrequency;
          this.esc.starttime = currRowData.starttime;
          this.esc.dlemail = currRowData.dlemail;
          this.esc.roleid = currRowData.toroles;
          this.esc.ccroleid = currRowData.ccroles;
          this.esc.isactive = currRowData.isactive
          this.isrolewise = currRowData.isrolewise
            if(currRowData.isrolewise == false){
                const optionValues = currRowData.toemail.map((toEmail, index) => ({
                    toemail: toEmail,
                    // ccemail: currRowData.ccemail[index] || "", 
                }));

                const optionValues1 = currRowData.ccemail.map((ccEmail, index) => ({
                    ccemail: currRowData.ccemail[index] || "",
                }));

                this.formconfigure.formdata[0].optionvalue = optionValues;
                this.formconfigure1.formdata[0].optionvalue = optionValues1;
            }
        },
        selrolename(state, value) {
        this.esc.roleid = null;
        this.esc.roleid = state;
        if (!state) {
            this.errors.esc.roleid = "Role is required";
        } else {
            this.errors.esc.roleid = '';
        }
      },
      selccroles(state){
        this.esc.ccroleid = null;
        this.esc.ccroleid = state;
        if (!state) {
            this.errors.esc.roleid = "CC Role is required";
        } else {
            this.errors.esc.roleid = '';
        }
      },
      toggleToRole() {
          if (this.esc.nonrole) {
              this.esc.isrolewise = false;
          }
      },
      getThirdPartyAPIcall(){
        this.pageoffset=1
        this.inputthrid = {
            tbl:'t10',
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
        }
        axios({
            'method': 'POST',
            'url': 'api/projectonlyroleassignment/getdesignations',
            'data': this.inputthrid,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            if(result.data.status == true){
                this.rolelist = result.data.data.rows
            }else{
                this.rolelist=[]
            }
        }).catch(e => {
            this.displayError(e)
        })
      },
      getDesignation(toroles){
        this.pageoffset=1
        this.inputthrid = {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            toroles: toroles
        }
        axios({
            'method': 'POST',
            'url': 'api/escalation/getDesignations',
            'data': this.inputthrid,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            if(result.data.status == true){
                this.rolelist = result.data.data.rows
                if (this.rolelists && Array.isArray(this.rolelists)) {
                    this.esc.roleid = this.rolelists.map(role => role.id);
                } else {
                    console.error("this.rolelists is undefined or not an array");
                }

            }else{
                this.rolelist=[]
            }
        }).catch(e => {
            this.displayError(e)
        })
      },
      clearField(index) {
        this.esc.project=null
        this.esc.frequency=null
        this.esc.starttime = { "HH": "", "mm": "" }
        this.esc.dlemail = null
        this.esc.roleid =null
        this.esc.ccroleid =null
        this.esc.isactive = false
        this.formconfigure.formdata.forEach((config) => {
          config.optionvalue.forEach((field) => {
            field.toemail = null;
          });
        });
        this.formconfigure1.formdata.forEach((config) => {
          config.optionvalue.forEach((field) => {
            field.ccemail = null;
          });
        });
      },
      getProjectwiseDLEmail(projectid){
        this.input = {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            projectid: projectid
        }
        axios({
            'method': 'POST',
            'url': 'api/escalation/getProjectwiseDLEmails',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            if(result.data.status == true){
              this.projectwisedllist = result.data.data.rows 
            }else{
                this.projectwisedllist=[]
            }
        }).catch(e => {
            this.displayError(e)
        })
      },

    updateData() {
        // this.valid = this.validateForm();
        // if (this.valid) {
          this.isLoading = true;
          this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            id: this.$route.query.id
          };
          this.input.project = this.input.project = parseInt(this.esc.project);//this.esc.project
          this.input.frequency = this.esc.frequency
          this.input.roleid = this.esc.roleid;
          this.input.ccroleid= this.esc.ccroleid;
          this.input.starttime = this.esc.starttime,
          this.input.dlemail = this.esc.dlemail
          if(this.esc.isactive == true){
          this.input.isactive = 1;
          }else{
            this.input.isactive = 0;
          }
          if(this.isrolewise == true){
            this.input.isrolewise = 1;
            }else{
              this.input.isrolewise = 0;
            }
            if(this.isrolewise == false){
              const emailArray = [];
                this.formconfigure.formdata.forEach(item => {
                if (item.optionvalue && item.optionvalue.length > 0) {

                  const optionsArray = item.optionvalue.map(option => ({
                    toemail: option.toemail
                  }));
                  emailArray.push(optionsArray);
                }
              });
              const toemailArray = [];
              const ccemailArray = [];
                emailArray.reduce((_, optionsArray) => {
                  optionsArray.forEach(option => {
                    toemailArray.push(option.toemail);
                  });
                  return null;
                }, null);
                this.input.toemail = toemailArray ? toemailArray:[]
                this.input.roleid = [];
                this.input.ccroleid= [];
            }
            if(this.isrolewise == false){
              const emailArray = [];
                this.formconfigure1.formdata.forEach(item => {
                if (item.optionvalue && item.optionvalue.length > 0) {

                  const optionsArray = item.optionvalue.map(option => ({
                    ccemail: option.ccemail,
                  }));
                  emailArray.push(optionsArray);
                }
              });
              const ccemailArray = [];
                emailArray.reduce((_, optionsArray) => {
                  optionsArray.forEach(option => {
                    ccemailArray.push(option.ccemail);
                  });
                  return null;
                }, null);
                this.input.ccemail = ccemailArray ? ccemailArray :[]
            }
        //   this.isAddState = false;
        let apiURL = "api/escalation/updateEscalation";

          axios({
            method: "POST",
            url: apiURL,
            data: this.input,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              Swal.fire({
                title: "Success..!!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.clearform();
              this.$router.push(`/escalation_matrix/escalationlist`)
            }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          }).catch(e => {
        this.displayError(e)
      });
         //}
      },
    clearform() { 
        this.isedit = false;
        this.esc.project = null;
        this.esc.roleid = null;
        this.esc.ccroleid =null;
        this.esc.email = null;
        this.esc.ccemail = null;
        this.esc.frequency = null;
        this.esc.starttime = { "HH": "", "mm": "" };
      },
      handleCancel(){
            this.$router.push({ name: '', params: { search: true }});
        },

      getProjectAllocatedToEmp() {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage:'createpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.esc.projectlist = result.data.data;
          } else {
            this.esc.projectlist = [];
          }
        });
      },
      getFrequencyList() {
        this.isLoading = true;
        let url = "api/escalation/getfrequencylist";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.esc.frequencylist = result.data.data;
          } else {
            this.esc.frequencylist = [];
          }
        });
      },

      validateForm() {
        this.isAddState = false;
        var isValid = true;
          if (!this.esc.project || this.esc.project == null) {
            this.errors.project = "Project is required";
            isValid = false;
          } else {
            this.errors.project = '';
          }     
          if (!this.esc.roleid) {
            this.errors.roleid = "Role is required";
            isValid = false;
          } else {
            this.errors.roleid = null;
          }  
          if (!this.esc.ccroleid) {
            this.errors.ccroleid = "CC Role is required";
            isValid = false;
          } else {
            this.errors.ccroleid = null;
          }  
          if (!this.esc.email) {
            this.errors.email = "Email to is required";
            isValid = false;
          } else {
            this.errors.email = "";
          }
          if (!this.esc.ccemail) {
            this.errors.ccemail = "CC Email is required";
            isValid = false;
          } else {
            this.errors.ccemail = "";
          }
          if (!this.esc.frequency) {
            this.errors.frequency = "Email frequency required";
            isValid = false;
          }else {
            this.errors.frequency = null;
          }
          if (!this.esc.starttime) {
            this.errors.starttime = "Start time is required";
            isValid = false;
          }else {
            this.errors.starttime = null;
          }          
        return isValid;
      },
  
      dateFormat(value) {
        if (!value) return "";
        return moment(String(value)).format("DD-MM-YYYY");
      },
      selproject: function (state, value) {
        this.esc.project = null;
        this.esc.project = state;
         if (!state) {
         } else{
         this.getProjectwiseDLEmail(state)
         }
      },
      selFrequency: function (state, value) {
        this.esc.frequency = null;
        this.esc.frequency = state;
         if (!state) {
          this.errors.esc.frequency = "Email frequency is required";
        
         } else {
          this.esc.frequency = null
        }
      },
    },
    filters: {
      basename: function (value) {
        if (!value) return "";
        return value.substr(value.lastIndexOf("/") + 1);
      },
    },
  };
  </script>
  