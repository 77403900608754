<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <loading :active.sync="islistLoading" :can-cancel="false"  :is-full-page="listfullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-sm-10 col-md-10 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Employee Zing Hr Details</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                        <em class="las la-angle-down"></em>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                     <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="date_picker_foundeds w-100">
                        <label for="">LogTime Date From</label>
                        <div class="input-group input-group-merge">
                          <date-picker placeholder="Select Logtime Date From" v-model="search.taskassigneddatefrom" :disabled-date="notBeforeToday" valueType="format" class="Startdate w-100" @input="selectedstartdate"></date-picker>
                          <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search or export reports.</div>                        
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <div class="date_picker_foundeds">
                        <label for="">LogTime Date To</label>
                        <div class="input-group input-group-merge">
                          <date-picker  placeholder="Select Logtime Date To" v-model="search.taskassigneddateto" valueType="format" class="enddate w-100" :default-value="new Date()" :disabled-date="disabledBefore" @input="selectedenddate"></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                      <div class="">
                        <label for="allotedto">User Name</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Enter Min 4Chars To Search User Name" v-model="search.allotedto" :multiple="true" :clear-on-select="true" :options="search.allotedtolist" @input="selassignedto" v-on:search-change="getEmpListByProjectAssigned"/>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Reporting Manager Name</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Enter Min 3char Of Reporting Manager Name" :multiple="true" :clear-on-select="true" v-model="search.mngrname" :options="mngrlist" v-on:search-change="getallmanagerlistnewapi"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                        <label for="intimesource">Intime Source</label>
                        <treeselect class="intimesource capitalisetext" placeholder="Select ZingHR Intime Source" v-model="search.intimesource" :multiple="true" :clear-on-select="true" :options="zingHrIntimeSources"/>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 mb-0 text-right">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getBusinessTaskReport(true,search)"><i class="custom-class las la-at" style="font-size: 15px;"></i> <span> Mail Me</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"><x-icon  size="1.5x" class="custom-class" ></x-icon> <span> Clear </span></button>
                      <!-- <button type="move" class="btn btn-relief-primary mt-2 " :disabled="isBothDateSelected" @click="ExportXL()"><external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span></button> -->
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
            <div class="row" id="reportTopScroll" style="display:none;">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Employee Zing HR Report 
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="" id="table-small">
                          <div class="">
                            <div class="">
                              <div class="table-responsive_sitcky"  v-if="showtabledata">
                                <table class="table table-hover table-striped dataTable table-sm dataTableListing all_table_stickys" aria-describedby="Employee Zing HR Report">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno first-col-sticky align-middle" style="min-width:45px;">Sr No</th>
                                      <th scope="col" class="align-middle second-col-sticky text-left" style="min-width:180px;">LogTimeDate</th>
                                      <th scope="col" class="align-middle text-left" style="min-width:130px;">WeekDays</th>
                                      <th scope="col" class="align-middle text-left" style="min-width:130px;">User Name</th>
                                      <th scope="col" class="align-middle text-left" style="min-width:130px;">Manager Name</th>
                                      <th scope="col" class="align-middle">Smart Works Log Time</th>
                                      <th scope="col" class="align-middle">Zing HR Log Time</th>
                                      <th scope="col" class="align-middle">Intime/Regularise In Time</th>
                                      <th scope="col" class="align-middle">Outtime/Regularise Out Time</th>
                                      <th scope="col" class="align-middle">Intime Source</th>
                                      <th scope="col" class="align-middle">Out Time Source</th>
                                      <th scope="col" class="align-middle">Attendance Status</th>
                                      <th scope="col" class="align-middle">Leave apply from date</th>
                                      <th scope="col" class="align-middle">Leave apply to date</th>
                                      <th scope="col" class="align-middle">Number of Leave days</th>
                                      <th scope="col" class="align-middle">Leave appove date</th>
                                      <th scope="col" class="align-middle">Reporting To/Leave Approved By</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-if="data.attendancestatus == 'Leaves'" class="table2 danger" v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                         <td class="capitalisetext text-left font-weight-normal">
                                          {{data.logtimedate?dateFormat(data.logtimedate):''}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                            {{formatDateToDayOfWeek(data.logtimedate)}}
                                        </td>
                                         <td class="capitalisetext text-left font-weight-normal">
                                            {{data.username}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{data.reportingmanager ? data.reportingmanager:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.spenttime && data.spenttime.hours ? data.spenttime.hours:'00'}}.{{data.spenttime && data.spenttime.minutes ? data.spenttime.minutes:'00'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">{{ convertHrsToTimestring(data.zinghours, data.regularizedhours, data.intimesource) }}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualstarttime, data.regin, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualendtime, data.regout, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.outtimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatAttendanceStatusValue(data.attendancestatus)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavefromdate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavetodate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavenoofdays)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{data.leaveapprovedon ? dateFormat2(data.leaveapprovedon) : '--'}}</td>
                                        <td class="text-capitalize font-weight-normal">{{formatEmailId(data.eleaveapprovedby)}}</td>
                                      </tr>

                                      <tr v-else-if="data.attendancestatus == 'WO' || data.attendancestatus == 'H'" class="table warning-yellow" v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                         <td class="capitalisetext text-left font-weight-normal">
                                          {{data.logtimedate?dateFormat(data.logtimedate):''}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                            {{formatDateToDayOfWeek(data.logtimedate)}}
                                        </td>
                                         <td class="capitalisetext text-left font-weight-normal">
                                            {{data.username}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{data.reportingmanager ? data.reportingmanager:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.spenttime && data.spenttime.hours ? data.spenttime.hours:'00'}}.{{data.spenttime && data.spenttime.minutes ? data.spenttime.minutes:'00'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">{{ convertHrsToTimestring(data.zinghours, data.regularizedhours, data.intimesource) }}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualstarttime, data.regin, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualendtime, data.regout, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.outtimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatAttendanceStatusValue(data.attendancestatus)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavefromdate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavetodate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavenoofdays)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{data.leaveapprovedon ? dateFormat2(data.leaveapprovedon) : '--'}}</td>
                                        <td class="text-capitalize font-weight-normal">{{formatEmailId(data.eleaveapprovedby)}}</td>
                                      </tr>

                                      <tr v-else-if="data.intimesource == 'Regularization' || data.intimesource == 'OutDoor'" class="table1 my_projects-bgs-5" v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                         <td class="capitalisetext text-left font-weight-normal">
                                          {{data.logtimedate?dateFormat(data.logtimedate):''}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                            {{formatDateToDayOfWeek(data.logtimedate)}}
                                        </td>
                                         <td class="capitalisetext text-left font-weight-normal">
                                            {{data.username}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{data.reportingmanager ? data.reportingmanager:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.spenttime && data.spenttime.hours ? data.spenttime.hours:'00'}}.{{data.spenttime && data.spenttime.minutes ? data.spenttime.minutes:'00'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">{{ convertHrsToTimestring(data.zinghours, data.regularizedhours, data.intimesource) }}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualstarttime, data.regin, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualendtime, data.regout, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.outtimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatAttendanceStatusValue(data.attendancestatus)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavefromdate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavetodate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavenoofdays)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{data.leaveapprovedon ? dateFormat2(data.leaveapprovedon) : '--'}}</td>
                                        <td class="text-capitalize font-weight-normal">{{formatEmailId(data.eleaveapprovedby)}}</td>
                                      </tr>

                                      <tr v-else v-bind:key="index+Math.floor(Math.random() * 1000000000)"> 
                                        <td class="srno font-weight-normal">{{ index+1+currPageNum*pagelimit }}</td>                                       
                                         <td class="capitalisetext text-left font-weight-normal">
                                          {{data.logtimedate?dateFormat(data.logtimedate):''}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                            {{formatDateToDayOfWeek(data.logtimedate)}}
                                        </td>
                                         <td class="capitalisetext text-left font-weight-normal">
                                            {{data.username}}
                                        </td>
                                        <td class="capitalisetext text-left font-weight-normal">
                                          {{data.reportingmanager ? data.reportingmanager:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">
                                          {{data.spenttime && data.spenttime.hours ? data.spenttime.hours:'00'}}.{{data.spenttime && data.spenttime.minutes ? data.spenttime.minutes:'00'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal">{{ convertHrsToTimestring(data.zinghours, data.regularizedhours, data.intimesource) }}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualstarttime, data.regin, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue2(data.actualendtime, data.regout, data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.intimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.outtimesource)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatAttendanceStatusValue(data.attendancestatus)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavefromdate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavetodate)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{formatValue(data.eleavenoofdays)}}</td>
                                        <td class="capitalisetext font-weight-normal">{{data.leaveapprovedon ? dateFormat2(data.leaveapprovedon) : '--'}}</td>
                                        <td class="text-capitalize font-weight-normal">{{formatEmailId(data.eleaveapprovedby)}}</td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2 text-center">
                      <!-- <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
input.mx-input::-webkit-input-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input:-moz-placeholder {
color: #b9b9c3;
font-size: 14px;
}
input.mx-input{
  color: #000 !important
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon,UserIcon,LinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';
import ExcelJS from 'exceljs'

export default {
  name: "ZingHrReport",
  mixins: [ commonMethods ],

  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
    DatePicker,
    UserIcon,
    LinkIcon
  },
  data() {
     return {
      isBothDateSelected:false,
      projectnameList:[],
      isLoading: false,
      fullPage: true,
      islistLoading:false,
      listfullPage:true,
      companymasterlist: [],
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      zingHrIntimeSources: apiUrl.zingHrIntimeSources.sort((a, b) => a.label.localeCompare(b.label)),
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT + 79,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      isSearchRequestActive:true,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      search:{
          allotedto:null,
          allotedtolist:[],
          mngrname:null,
          intimesource: null,
      },
      tokendata:null,
      mngrlist:[],
      isresponce:false,
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        // this.getBusinessTaskReport()
      }
      $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
      });
    }
  },
   methods: {
    convertHrsToTimestring(hrs, hrs2, type) {
      if(!hrs && !hrs2 && hrs==null && hrs2 == null) return 0
      if(hrs && type !== 'Regularization') {
        return new Date(hrs * 1000).toISOString().substring(11, 16)
      }
      if(hrs2) {
        return new Date(hrs2 * 1000).toISOString().substring(11, 16)
      }
      return 0
    },
    formatValue(val) {
      if(!val)  return '-'
      return val
    },
    formatValue2(val, val2, type) {
      if(val2 && type === 'Regularization') {
        return val2
      }
      if(!val) return '-'
      return val
    },
    formatAttendanceStatusValue(value) {
      if(!value) return '-'
      if(value === 'A') return 'Absent'
      else if(value === 'P') return 'Present'
      else if(value === 'H') return 'Holiday'
      else if(value === 'WO') return 'Weekly Off'
      else if(value === 'Leaves') return 'On Leave'
      else return value
    },
    formatEmailId(value) {
      if(!value)  return '-'
      let name = value.split('@')
      if(name && name.length) {
        let name2 = name[0]
        name = name2.replace('.', ' ')
      } else {
        return value
      }
      return name
    },
    selectedstartdate(){
      if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    selectedenddate(){
       if((this.search.taskassigneddatefrom && this.search.taskassigneddateto) || (!this.search.taskassigneddatefrom && !this.search.taskassigneddateto)){
        this.isBothDateSelected = false
      }else if((this.search.taskassigneddatefrom && !this.search.taskassigneddateto) ||( !this.search.taskassigneddatefrom && this.search.taskassigneddateto)){
        this.isBothDateSelected = true
      }
    },
    notBeforeToday: function (date) {
      this.search.taskassigneddateto = "";
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.taskassigneddatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
            beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    getDropdownApplicationdata(mdl) {
      let apiUrl = "api/master/commonmaster/getModuleDropdownOption"
      this.input = {
        tbl: mdl,
        useremail: this.undt.username,
        companymasterid: this.search.companyname,
        businessmasterid: this.search.businessname,
        empcode: this.undt.userid,
      }
      axios({
            'method': 'POST',
            'url': apiUrl,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
          if(result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = null
          }
        }).catch(e => {
        this.displayError(e)
      })
    },
    projectChange: function (node, instanceId){
      if(node && node.length >1){
        
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getallmanagerlistnewapi(node) {
      if (node && node.length>=3) {
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/searching/getallmanagerlistnewapi',
          'headers':{'authorization':this.tokendata},
          'data': {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            mngname: node.toLowerCase(),
          }
        }).then(result => {
          this.isLoading = false
          if(result.data.errorCode == 0) {
            this.mngrlist = result.data.data;
            let decryptdepartmenttext = this.mngrlist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.mngrlist = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    getBusinessTaskReport(flag = false,...search){
      if(flag) {
        this.pageoffset = 1
        this.companymasterlist = []
        this.totalcount = 0;
        this.pageCount = 0;
        this.page= 1;
      } 
        
        this.isLoading = true;
        let url = "api/reports/empzingreport";
        this.input = {
            // offset: this.pageoffset,
            // limit: this.pagelimit,
            useremail: this.undt.username,
            mangerid:this.search.mngrname,
            empid: this.search.allotedto,
            empcode: this.undt.userid,
            intimesource: this.search.intimesource,
      };
      // if(flagValue){
      //   this.input.mailme = 1
      //   this.input.offset = null,
      //   this.input.limit =null
      // }
      if(this.search.taskassigneddateto){
        var assignedate = new Date(this.search.taskassigneddateto)
        assignedate.setDate(assignedate.getDate() + 1)
        moment(String(assignedate)).format('YYYY-MM-DD');
      }
      this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
      this.input.empid = this.search.allotedto?this.search.allotedto:null
      this.input.datefrom = this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:null
      this.input.dateto = this.search.taskassigneddateto?assignedate:null
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.islistLoading = false;
        this.isLoading = false
        if (result.data.errorCode == 0) {
          this.isresponce=true
          Swal.fire({
            title: "Success!",
            text: "Export file link generated on your email",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
            Swal.fire({
              title: "",
              text: "Employee report data not found",
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = 0;
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    resetRecords: function () {
      this.search.searchProjectname = null;
      this.search.searchTaskStatus = null;
      this.search.allotedto = null;
      this.search.intimesource = null;
      this.search.taskassigneddateto = null;
      this.search.taskassigneddatefrom = null;
      this.search.mngrname=null;
      this.isBothDateSelected = false
      this.search.allotedtolist = [];
      this.mngrlist = [];
      localStorage.removeItem('searchItems')
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.parentmoduledata={}
        this.parentmoduledata.isPaginationreq=true
        this.getBusinessTaskReport(false,this.parentmoduledata);

      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateFormat2(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY HH:mm');
    },
    dateMONFormat(value){
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY HH:mm:ss');
      } else{
        return "";
      }
    },
       timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1]/6)*10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
}   ,
    getEmpListByProjectAssigned(node) {
      if (node && node.length >=3) {
        this.pageoffset=1
        axios({
          'method': 'POST',
          'url': 'api/listing/getEmpListByProjectAssigned',
          'data': {
            empid: parseInt(this.undt.userid),
            useremail: this.undt.username,
            empcode: this.undt.userid,
            empname: node.toLowerCase()
          },
          'headers':{'authorization':this.tokendata}
        }).then(result => {
          // this.isLoading = false
          if(result.data.errorCode == 0) {
            this.search.allotedtolist = result.data.data;
            let decryptdepartmenttext = this.search.allotedtolist.map(items =>{

              let tempemplabel1 = items.label;
              let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

              if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
              }
              return items;
            })
          } else {
            this.search.allotedtolist = [];
          }
        }).catch(e => {
        this.displayError(e)
      })
      }
    },
    selassignedto(state, value) {
      if(!state) {
        this.search.allotedto=null
      } else {
        this.search.allotedto=state
      }
    },
    ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
      this.input = {
        limit:null,
        offset:null,
        empid:this.search.allotedto,
       
        useremail: this.undt.username,
        mangerid:this.search.mngrname,
        empcode: this.undt.userid,
      };
      let assignedate =''
        if(this.search.taskassigneddateto){
          assignedate = new Date(this.search.taskassigneddateto)
          assignedate.setDate(assignedate.getDate() + 1)
          moment(String(assignedate)).format('YYYY-MM-DD');
        }
        this.input.projectid = this.search.searchProjectname ? this.search.searchProjectname : null
        this.input.empid = this.search.allotedto?this.search.allotedto:null
        this.input.datefrom = this.search.taskassigneddatefrom?this.search.taskassigneddatefrom:null
        this.input.dateto = this.search.taskassigneddateto?assignedate:null
        this.input.intimesource = this.search.intimesource
      axios({
        method: "POST",
        url: `api/reports/empzingreport`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
            this.exportxlsdata = result.data.data.rows;
            var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    async download(readExportdata, filename) {
      if (readExportdata.length > 0) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Sheet 1');

          // Add headers
          const headerStyle = {
            font: { bold: true },
          };

          worksheet.addRow([
              'Sr#', 'LogTimeDate', 'User Name', 'Manager Name', 'SMART WORKS LOG TIME',
              'ZING HR LOG TIME', 'Intime/Regularise In Time', 'Outtime/Regularise Out Time',
              'Intime Source', 'Out Time Source', 'Attendance Status', 'Leave apply from date',
              'Leave apply to date', 'Number of Leave days', 'Leave approve date',
              'Reporting To/Leave Approved By', 'WeekDays'
          ]).eachCell((cell) => {
            cell.style = headerStyle;
          });

          function getRowColor(attendanceStatus, intimeSource) {
            if (attendanceStatus === 'WO' || attendanceStatus === 'H') {
                return 'FFFF00';
            } else if (attendanceStatus === 'Leaves') {
                return 'FF0000'; 
            } else if (intimeSource === 'Regularization' || intimeSource === 'OutDoor') {
                return 'ff9f43'; 
            } else {
                return null; // Default color (no color)
            }
          }

            for (let i = 0; i < readExportdata.length; i++) {
              const index = readExportdata[i];
              const srno = i + 1;
              let logtimedate = index.hasOwnProperty('logtimedate') ? index.logtimedate == null || index.logtimedate == 'NA' ? '' : index.logtimedate : '';
              let username = index.hasOwnProperty('username') ? index.username == null || index.username == 'NA' ? '' : index.username : '';
              let reportingmanager = index.hasOwnProperty('reportingmanager') ? index.reportingmanager == null?'' :index.reportingmanager : '';
              let spenttime = index.hasOwnProperty('spenttime') ? index.spenttime == null || index.spenttime  : '';
              
              const row = worksheet.addRow([
                  srno, 
                  logtimedate, 
                  username, reportingmanager,
                  `${spenttime && spenttime.hours ? spenttime.hours : '00'}.${spenttime && spenttime.minutes ? spenttime.minutes : '00'}`,
                  this.convertHrsToTimestring(index.zinghours, index.regularizedhours, index.intimesource),
                  this.formatValue2(index.actualstarttime, index.regin, index.intimesource),
                  this.formatValue2(index.actualendtime, index.regout, index.intimesource),
                  this.formatValue(index.intimesource),
                  this.formatValue(index.outtimesource),
                  this.formatAttendanceStatusValue(index.attendancestatus),
                  this.formatValue(index.eleavefromdate),
                  this.formatValue(index.eleavetodate),
                  this.formatValue(index.eleavenoofdays),
                  this.dateFormat2(index.leaveapprovedon),
                  this.formatValue(index.eleaveapprovedby),
                  this.formatDateToDayOfWeek(index.logtimedate)
              ]);

              const rowColor = getRowColor(index.attendancestatus, index.intimesource);
              if (rowColor) {
                row.eachCell({ includeEmpty: true }, (cell) => {
                  cell.fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: rowColor },
                  };
                });
              }
            }

          // Save the workbook to a file
          const buffer = await workbook.xlsx.writeBuffer();

          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const fileName = 'ZingHrReport' + filename + '_' + moment().format('DD_MM_YY_HH_mm_ss') + '.xlsx';

          // Create a download link and trigger the download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();

          Swal.fire({
              title: 'Success!',
              text: 'Export Report successful',
              icon: 'success',
              customClass: {
                  confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
          });
      } else {
          Swal.fire({
              title: '',
              text: 'Data not found',
              icon: 'info',
              customClass: {
                  confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
          });
      }
    },
    formatDateToDayOfWeek (dates) {
      const date = new Date(dates.toString());
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      return dayOfWeek
    }
  },
};
</script>
<style>
  .table-responsive_sitcky .table.warning-yellow {
    color: #333 !important;
    background-color: #ffc107;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
  .table-responsive_sitcky .table1.my_projects-bgs-5{
    background: linear-gradient(135.71deg, #ff9f43 -6.87%, #ff9f43 106.12%);
  }
  .table-responsive_sitcky .table2.danger{
    background-image: linear-gradient(to right bottom, #ea6142, #ea6142) !important;
  }
</style>