<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <section id="modern-horizontal-wizard">
          <div class="row breadcrumbs-top">
            <div class="col-12 pb-1">
              <h3 class="content-header-title float-left mb-0">DL Email Configuration</h3>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'dlemailist', params: { search: true }}">Dlemail List</router-link></li>
                  <li class="breadcrumb-item sitebluecolor cursorpointercls">Update Dlemail</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="card border-warning">
            <div v-bind:class="[{ show: isRequestActive }, card_content, collapse]">
              <div class="card-header py-1 px-2" style="background-color: #f1f2f3">
                <h3 class="card-title">Update Dlemail</h3>
              </div>
              <div class="card-body pt-1">
                <form class="form form-vertical">
                  <div class="row">
                    <div class="row mx-0">
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Project Name</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select project name" v-model="ticket.project" :options="ticket.projectlist" @select="selissuecsproject" :disabled="this.isThisMerchandiseProject" @close="validateForm($event)"/>
                            <div class="errorinputmsg" v-if="this.errors.ticket.project">{{ this.errors.ticket.project }}</div>
                            </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                        <label for="projectlistdd">Sender Host </label>
                        <input type="text"  class="form-control" name="" placeholder="Enter Host" v-model="ticket.host" @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="this.errors.ticket.host">{{ this.errors.ticket.host }}</div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group mb-1">
                        <label for="projectlistdd">Receiver Host</label>
                        <input type="text"  class="form-control" name="" placeholder="Enter Host" v-model="ticket.rhost" @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="this.errors.ticket.rhost">{{ this.errors.ticket.rhost }}</div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group mb-1">
                        <label for="projectlistdd">Dl Email</label>
                        <input type="text"  class="form-control" name="" placeholder="Enter Email" v-model="ticket.email"
                        @input="validateEmail"  @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="this.errors.ticket.email">{{ this.errors.ticket.email }}</div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group mb-1">
                        <label for="projectlistdd">Dl Password</label>
                        <input type="text"  class="form-control" name="" placeholder="Enter Password" v-model="ticket.password" @close="validateForm($event)"/>
                        <div class="errorinputmsg" v-if="this.errors.ticket.password">{{ this.errors.ticket.password }}</div>
                        </div>
                        </div>
                        <div class="col-md-4">
                        <div class="form-group mb-1"><label for="projectmdllistdd">Ticket Type</label>
                          <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket type" v-model="ticket.type" :options="ticket.mrtypelist"  @close="validateForm($event)"/>
                          <div class="errorinputmsg" v-if="this.errors.ticket.type"> {{ this.errors.ticket.type }}</div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Ticket Severity</label>
                          <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket severity" v-model="ticket.sevairty" :options="ticket.severitylist"  @close="validateForm($event)"/>
                          <div class="errorinputmsg" v-if="this.errors.ticket.sevairty">{{ this.errors.ticket.sevairty }}</div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Priority</label>
                          <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select ticket Priority" v-model="ticket.priority" :options="ticket.prioritylist"  @close="validateForm($event)"/>
                          <div class="errorinputmsg" v-if="this.errors.ticket.priority">{{ this.errors.ticket.priority }}</div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="projectmdllistdd">Field Name </label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Field" v-model="ticket.field" @input="selissuecsfield" :options="ticket.fieldlist" @close="validateForm($event)"/>
                            <div class="errorinputmsg" v-if="this.errors.ticket.field">{{ this.errors.ticket.field }}</div>
                            </div>
                      </div>
                      <div class="col-md-4">
                          <div class="form-group mb-1">
                            <label for="projectmdllistdd">Values</label>
                            <treeselect
                              class="projectmdllistdd capitalisetext"
                              placeholder="Select Values"
                              v-model="ticket.values"
                              :options="ticket.valueslist"
                              v-if="this.inputtypeselection == 'drop-down'"
                              @select="selvalue"
                            />
                              <input v-else-if="this.inputtypeselection == 'text'" class="form-control"  v-model="ticket.values" type="text" placeholder="Enter text">
                              <input v-else-if="this.inputtypeselection == 'number'"  class="form-control"  v-model="ticket.values" type="number" placeholder="Enter Number">
                              <input v-else class="form-control"  v-model="ticket.values" type="text" placeholder="Enter Values">
                              <date-picker v-if="this.inputtypeselection == 'date'" placeholder="Select date" v-model="ticket.values" valueType="format" class="enddate w-100" :default-value="new Date()" @select="selvalue"></date-picker>
                            <div class="errorinputmsg" v-if="this.errors.ticket.values">{{ this.errors.ticket.values }}</div>
                          </div>
                        </div>
                      <div class="col-md-4">
                        <div class="form-group mb-1">
                          <label for="allotedto">Assigned to</label>
                          <treeselect class="allotedto capitalisetext"  placeholder="select Assigned to" v-model="ticket.allotedto" :options="ticket.allotedtolist" @select="selallotedto" @close="validateForm($event)" />
                          <div class="errorinputmsg" v-if="this.errors.ticket.allotedto"> {{ this.errors.ticket.allotedto }}</div>
                        </div>
                      </div>
                      <div class="col-md-4 mt-2" style="margin-left:-1rem">
                        <label for="isactive" :class="{'active': ticket.isactive}" class="toggle__button">
                          <input type="checkbox" id="isactive" v-model="ticket.isactive">
                          <span class="toggle__switch"></span>
                          <span :class="{'off-lable-color': !ticket.isactive, 'on-label-color': ticket.isactive, 'toggle__label': true}">Inactive/Active</span>
                        </label>
                      </div>
                    <div class="col-md-4 mg-top1 text-right">
                      <label class="w-100 d-block" for="allotedto">&nbsp;</label>
                            <button type="button" class="btn btn-relief-primary mr-1" @click="savewizard($event)" id="submitbtnt42" v-bind:disabled="ticket.disblesavebtn">Submit</button>
                            <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                        </div>
                    </div>
                   
                  </div>
                    
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <style>
  .createbtnparentdiv {
    padding-right: 0% !important;
  }
  .displayblk {
    display: block !important;
  }
  .createbtn {
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
  }
  </style>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import vSelect from "vue-select";
  import commonMethods from '../../utils/commonMethods';

  import {
    EditIcon,
    EyeIcon,
    Trash2Icon,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    DownloadIcon,
    PlusSquareIcon,
    ClipboardIcon,
  } from "vue-feather-icons";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import Pagination from "vue-pagination-2";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import CKEditor from "@ckeditor/ckeditor5-vue2";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  import VueTimepicker from 'vue2-timepicker'
  
  export default {
    name: "IssueCreate",
    mixins: [ commonMethods ],

    components: {
      Loading,
      VueElementLoading,
      vSelect,
      EditIcon,
      EyeIcon,
      Treeselect,
      SearchIcon,
      GridIcon,
      CodesandboxIcon,
      CloudIcon,
      ChromeIcon,
      BoxIcon,
      Link2Icon,
      PackageIcon,
      Pagination,
      MoreVerticalIcon,
      PlusIcon,
      DownloadIcon,
      Edit2Icon,
      PlusSquareIcon,
      ClipboardIcon,
      ckeditor: CKEditor.component,
      Trash2Icon,
      DatePicker,
      VueTimepicker,
    },
    data() {
      return {
        indItHelpDesk: apiUrl.indItHelpDesk,
        ifItHelpDeskNotFound: apiUrl.ifItHelpDeskNotFound,
        isThisBusinessRole: false,
        roleid: 0,
        productlabelname: null,
        loginuser: "",
        isLoading: false,
        fullPage: true,
        stylevariation: apiUrl.stylevariation,
        companymasterlist: [],
        editRowData: [],
        ticket: {
          project: null,
          projectlist: null,
          fieldlist:null,
          valueslist:null,
          allotedto: null,
          allotedtolist:null,
          values:null,
          host: null,
          rhost:null,
          email: null,
          password: null,
          priority: null,
          sevairty: null,
          severitylist: null,
          typelist: apiUrl.ticketTypeList,
          prioritylist:apiUrl.issueclassification,
          type: null,
          mrtypelist:[],
          iseditstate:false,
          isactive: false
        },
        file: [],
        isAddState: false,
        totalcount: 0,
        pageCount: 0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        pagefeatures: [],
        isSearchRequestActive: false,
        card_content: "card-content",
        collapse: "collapse",
        errors: {
          ticket: {
            project: null,
            field: null,
            values:null,
            allotedto: null,
            type: null,
            priority: null,
            sevairty: null, 
            host: null,
            rhost:null,
            email: null,
            password: null,
          },
        },
        isDetActive: true,
        isRequestActive: true,
        isedit: false,
        card: "card",
        border_warning: "border-warning",
        editor: ClassicEditor,
        editorConfig: {
          toolbar: {
            items: [
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "insertTable",
              "|",
              "undo",
              "redo",
              "|",
            ],
          },
        },
        tokendata: null,
        accepttype:apiUrl.uploadfiletype,
        userrole:null,
        merchandisingProId: apiUrl.merchandisingProId,
        isThisMerchandiseProject: false,
        itHelpDesk: apiUrl.indItHelpDesk,
        companycode: apiUrl.companycode,
        TKRosterFields:apiUrl.TKRosterFields.sort((a, b) => a.label.localeCompare(b.label)),
        inputtypeselection: null,
        isEmailValid:false,

      };
    },
    mounted() {
      let userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        this.loginusername = userdata.username;
        this.loginuscondition = userdata.userid;
        this.tokendata = window.localStorage.getItem("token");
        this.userrole = window.localStorage.getItem("userrole")
        this.roleid = this.undt.roleid;
        this.isThisBusinessRole = true;
        this.getProjectAllocatedToEmp();
        this.getApplicationdata("t44", "ticket");
        this.getdlemailById(this.$route.query.rid);
        this.rid=this.$route.query.rid
      }

    },
    methods: {
      validateEmail() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const inRegex = /\.in$/i;
          const comRegex = /\.com$/i;

          if (emailRegex.test(this.ticket.email)) {
              if (inRegex.test(this.ticket.email) || comRegex.test(this.ticket.email)) {
                  this.isEmailValid = true;
                  this.errors.ticket.email = '';
              } else {
                  this.isEmailValid = false;
                  this.errors.ticket.email = 'Only allowed .in or .com';
              }
          } else {
              this.isEmailValid = false;
              this.errors.ticket.email = 'Invalid Email';
          }
      },
      getEmpallocatedtoProject: function (node) {
          this.isLoading = true;
          let currtab = 'ticket'
          this.input = { projectid: this.ticket.project,useremail: this.undt.username,empcode: this.undt.userid};
        if (this.isThisBusinessRole) {
          this.input.roleid = 484;
        }
        if (this.ticket.project == this.itHelpDesk) {
          this.input.roleid = null;
        }
        axios({
          method: "POST",
          url: "api/listing/getEmpallocatedtoProject",
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
              this.ticket.allotedtolist = result.data.data;
              let decryptdepartmenttext = this.ticket.allotedtolist.map(items =>{

                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;

                if(tempemplabel2 && tempemplabel2 != null){
                    items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
              
          }
        });
      },
      getfieldame(field) {
      let fiel = this.ticket.fieldlist.filter(fiel => fiel.id == field)
      if(fiel && fiel.length) {
        return fiel[0].label
      }
      return null
    },
      savewizard: function (mdl, objThis) {
        this.valid = this.validateForm(mdl, objThis);
        if (this.valid) {
          this.isLoading = true;
          this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            rid:this.rid,
          };
          let apiURL = "api/roaster/updatedlemail";
          this.input.project = this.ticket.project;
          this.input.sevairty = this.ticket.sevairty
          this.input.priority = this.ticket.priority
          this.input.host = this.ticket.host
          this.input.rhost = this.ticket.rhost
          this.input.password = this.ticket.password
          this.input.email = this.ticket.email
          this.input.type = this.ticket.type
          if(!this.TKRosterFields.some(i=>i.id ==this.ticket.field)){
          this.input.fieldname = this.getfieldame(this.ticket.field)
          this.input.field = this.ticket.field ? this.ticket.field :null;
          }
          if(this.TKRosterFields.some(i=>i.id ==this.ticket.field)){
          this.input.fieldname = this.ticket.field
          }
          this.input.values = this.ticket.values.toString();
          this.input.allotedto = this.ticket.allotedto;
          if(this.ticket.allotedto && this.ticket.allotedto !=null){
            this.input.allotedto = this.ticket.allotedto;
            } else{
                this.input.allotedto = this.editRowData.assigneto;
            }
          if(this.ticket.isactive == true){
            this.input.isactive = 1;
          }else{
            this.input.isactive = 0;
          }
          this.isAddState = false;
          
          axios({
            method: "POST",
            url: apiURL,
            data: this.input,
            headers: { authorization: this.tokendata },
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.file = [];
              this.ticket.filedescp = [];
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
              this.myrequestcollapse();
              this.$router.push(`/configuration/dlemailist`)

            }else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            } else {
              Swal.fire({
                title: "Failed",
                text: result.data.msg,
                icon: "info",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            }
          }).catch(e => {
        this.displayError(e)
      });
        }
      },
    myrequestcollapse() {
        this.isedit = false;
        this.ticket.iseditstate = false
        this.ticket.project = null;
        this.ticket.field = null;
        this.ticket.values = null;
        this.ticket.allotedto = null;
        this.ticket.password = null;
        this.ticket.email = null;
        this.ticket.host = null;
        this.ticket.rhost = null;
        this.ticket.priority = null;
        this.ticket.sevairty = null;
        this.ticket.type = null;
        this.clearerrorForm();
      },
     getticketfield(project,isfield) {
        this.isLoading = true;
        let url = "api/roaster/getticketfield";
        this.input = {
          empcode: this.undt.userid,
          useremail  : this.undt.username,
          projectid: [project]
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.fieldlist = result.data.data.concat(this.TKRosterFields);
            if(isfield){
              let dp = this.ticket.fieldlist.filter((v) => {
              if(v.id==isfield) 
                return v;
              });
              this.inputtypeselection = dp.length > 0 ? dp[0].fieldtype : null
            }
          } else {
            this.ticket.fieldlist = [];
          }
        });
      },

      getticketfieldvalues() {
        this.isLoading = true;
        let url = "api/roaster/getticketfieldvalues";
        this.input = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          projectid: this.ticket.project,
          field: this.ticket.field
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        })
          .then((result) => {
            this.isLoading = false;
            if (result.data.errorCode === 0) {
              this.ticket.valueslist = result.data.data[0].label.map((item) => {
                return {
                  label: item.optionvalue,
                  id: item.optionvalue,
                };
              });
            } else {
              this.ticket.valueslist = [];
            }
          })
          .catch((error) => {
            this.ticket.valueslist = [];
          });
      },


      getProjectAllocatedToEmp() {
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          empid: parseInt(this.undt.userid),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          isfrompage:'updatepage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          headers: { authorization: this.tokendata },
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.projectlist = result.data.data;
            if(this.ticket.projectlist && this.ticket.projectlist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
                const checkexistproject = this.ticket.projectlist.some(item =>item.id === this.ticket.project);
                if(!checkexistproject){
                    this.getProjectnamebyid(this.ticket.project);
                }
            }else if(this.ticket.projectlist.length ==0 && (this.ticket.project && this.ticket.project !=null)){
                this.getProjectnamebyid(this.ticket.project);
            }
            if(this.ticket.projectlist.length ==1){
                this.ticket.project = this.ticket.projectlist[0].id
                this.isThisMerchandiseProject = true
            }else{
              this.isThisMerchandiseProject = false
            }
          } else {
            this.ticket.projectlist = [];
          }
        });
      },
      getProjectnamebyid(pid){
            this.isLoading = true;
            this.apiURL="api/listing/getProjectAllocatedToEmp"
            this.input={
                useremail: this.undt.username,
                empid :this.undt.userid,
                empcode: this.undt.userid,
                projectid: [pid],
                isfrompage: 'isprojectid'
            }
            axios({
                'method': 'POST',
                'url': this.apiURL,
                'data':this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {  
                this.isLoading = false;         
                if(result.data.errorCode == 0){
                    if(result.data.data && result.data.data.length > 0){
                        this.ticket.projectlist.push(result.data.data[0]);
                    }
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
      validateForm: function (mdl, objThis) {
         this.clearerrorForm();
        this.isAddState = false;
        var isValid = true;
          if (!this.ticket.project) {
            this.errors.ticket.project = "Project is required";
            isValid = false;
          } else {
            this.errors.ticket.project = null;
          }     
          if (!this.ticket.field) {
            this.errors.ticket.field = "field is required";
            isValid = false;
          } else {
            this.errors.ticket.field = null;
          }  
          if (!this.ticket.values || this.ticket.values ==null || this.ticket.values =='' || this.ticket.values ==' ') {
            this.errors.ticket.values = "values is required";
            isValid = false;
          } else {
            this.errors.ticket.values = null;
          }
          if (!this.ticket.allotedto) {
            this.errors.ticket.allotedto = "Assigned to required";
            isValid = false;
          } else {
            if(this.ticket.allotedto && this.ticket.allotedto.length ==0){
              this.errors.ticket.allotedto = "Assigned to required";
              isValid = false;
              return
            }else{
              this.errors.ticket.allotedto = "";
            }
          }
          if (!this.ticket.type) {
            this.errors.ticket.type = "Ticket type required";
            isValid = false;
            }else {
                this.errors.ticket.type = null;
            }
            if (!this.ticket.sevairty) {
            this.errors.ticket.sevairty = "Ticket severity required";
            isValid = false;
            }else {
                this.errors.ticket.sevairty = null;
            }
            if (!this.ticket.priority) {
            this.errors.ticket.priority = "Ticket priority required";
            isValid = false;
            }else {
                this.errors.ticket.priority = null;
            }
            if (!this.ticket.host) {
                this.errors.ticket.host = " Sender Host is required";
                isValid = false;
            }else {
                this.errors.ticket.host = null;
            }
            if (!this.ticket.rhost) {
                this.errors.ticket.rhost = " Receiver Host is required";
                isValid = false;
            }else {
                this.errors.ticket.rhost = null;
            }
            if (!this.ticket.email) {
                this.errors.ticket.email = "Email is required";
                isValid = false;
            }else {
                this.errors.ticket.email = null;
            }
            if (!this.ticket.password) {
                this.errors.ticket.password = "Password is required";
                isValid = false;
            }else {
                this.errors.ticket.password = null;
            }
        return isValid;
      },
  
      clearerrorForm: function () {
        this.errors.ticket.project = null;
        this.errors.ticket.field = null;
        this.errors.ticket.values = null;
        this.errors.ticket.allotedto = null;
        this.errors.ticket.password = null;
        this.errors.ticket.email = null;
        this.errors.ticket.host = null;
        this.errors.ticket.rhost = null;
        this.errors.ticket.priority = null;
        this.errors.ticket.sevairty = null;
        this.errors.ticket.type = null;
      },
      dateFormat(value) {
        if (!value) return "";
        return moment(String(value)).format("DD-MM-YYYY");
      },
      selissuecsproject: function (state, value) {
        this.ticket.project = null;
        this.ticket.project = state.id;
        this.ticket.allotedtolist = [];
         if (!state && !state.id) {
          this.ticket.fieldlist = [];
          this.ticket.valueslist =[];
          this.ticket.values = null;
          this.ticket.allotedto = null;
          this.ticket.field =null;
          this.ticket.allotedtolist = [];
          this.errors.ticket.project = "Project name is required";
        
         } else {
          this.errors.ticket.project ='';
          this.ticket.fieldlist =[];
          this.ticket.valueslist =[];
          this.ticket.values = null;
          this.ticket.allotedto = null;
          this.ticket.field =null;
          // if(state.id == this.merchandisingProId) {
          //     $('#disabledfield').hide();
          // } else {
          //     $('#disabledfield').show();
          // }
          this.getticketfield(state.id);
          this.getEmpallocatedtoProject(state.id);
          this.tickettypeformrp(state)
          this.ticket.type = null
        }
      },
      selissuecsfield: function (state, value) {
        if (!state) {
          this.errors.ticket.field ='Field is required';
          this.ticket.values = null;
          this.ticket.valueslist = [];
        }else{
          if(state !='Ticket Category' && state !='Ticket Sub Category'){
            if(state !=this.editRowData.fieldid){
              this.ticket.values = null;
            }
            this.getticketfieldvalues();
          }
          if(!this.TKRosterFields.some(i=>i.id === state)){
            this.ticket.field = state;
            if (!state) {
              this.errors.ticket.field ='Field is required';
              this.ticket.values = null;
              this.ticket.valueslist = [];
            } else {
              this.errors.ticket.field ='';
              if(this.ticket.fieldlist && this.ticket.fieldlist.length >0){
                let dp = this.ticket.fieldlist.filter((v) => {
                if(v.id==state) 
                  return v;
                });
                this.inputtypeselection = dp.length > 0 ? dp[0].fieldtype : null;
              }
            }
          }
          if(this.TKRosterFields.some(i=>i.id === state)){
            if(state=='Ticket Category'){
              if(state !=this.editRowData.fieldname){
                this.ticket.values = null;
                this.ticket.valueslist = [];
              }
              this.getprojectmodules(this.ticket.project)
            }
            if(state=='Ticket Sub Category'){
              if(state !=this.editRowData.fieldname){
                this.ticket.values = null;
                this.ticket.valueslist = [];
              }
              this.getAppModulesbyprojectid(this.ticket.project)
            }
          }
        }
        },
        getprojectmodules(projecteid) {
          let url = '';
            url = 'api/listing/getpromodulebyapplicationid';
        
          this.input = {
            projecteid: projecteid,
            useremail: this.undt.username,
            empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.inputtypeselection = 'drop-down'
              this.ticket.valueslist = result.data.data;
            }else {
              this.ticket.valueslist = [];
            }  
          }).catch(e => {
            this.displayError(e)
          });
        },
        getAppModulesbyprojectid(projectid) {
        this.input = {
          projectid: projectid,
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: 'api/listing/getappmodulenamebyprojectid',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.inputtypeselection = 'drop-down'
            this.ticket.valueslist = result.data.data;
          } else {
            this.ticket.valueslist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      },
      autoAssignItHelpDeskUser(id) {
          this.input = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            projectid: id,
            roleid: 484,
            flag: 0
          };
          axios({
            method: "POST",
            url: 'api/ticket/autoAssignItHelpDeskUser',
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.ticket.allotedto = result.data.data[0].empid;
            } else {
              this.ticket.allotedto = this.ifItHelpDeskNotFound;
            }
          });
      },
      selallotedto: function (state, value) {
        this.ticket.allotedto = null;
        this.ticket.allotedto = state.id;
        if (!state && this.isAddState == false) {
          this.errors.ticket.allotedto = "Assigned To required";
        } else {
          this.errors.ticket.allotedto = null;
        }
      },
      getdlemailById(id){
          this.isLoading = true;
          let url = "api/roaster/getdlemailById";
          this.input = {
              rid:id,
              useremail: this.undt.username,
              empid: parseInt(this.undt.userid),
              empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
              this.isLoading = false;
               (result.data.errorCode == 0) 
                this.editRowData = result.data.data;
                this.editRowData=this.editRowData[0]
                this.openEditModule(this.editRowData,0)
            });
          },
          handleCancel() {
            this.$router.push({ name: 'dlemailist', params: { search: true }})
          },
          openEditModule: function(currRowData, flag){
            this.editRowData = currRowData
            this.ticket.project = currRowData.projectid
            this.ticket.host = currRowData.sendhost
            this.ticket.rhost = currRowData.dlhost
            this.ticket.email = currRowData.dlemail
            this.ticket.password = currRowData.dlpassword
            this.ticket.type = currRowData.tktype
            this.ticket.priority = currRowData.priority
            this.ticket.sevairty = currRowData.sevairty
            this.ticket.field = currRowData.fieldid
            this.ticket.values = currRowData.fieldvalue
            if(currRowData.projectid && currRowData.projectid !=null){
                this.tickettypeformrp(currRowData.projectid);
              }
            if(currRowData.fieldid && currRowData.fieldid !=null){
              this.getticketfield(this.ticket.project,this.ticket.field)
            }
            if(currRowData.fieldname && (currRowData.fieldname =='Ticket Category' || currRowData.fieldname =='Ticket Sub Category') && (currRowData.fieldid ==null || currRowData.fieldid =='')){
              this.ticket.field = null;
              this.ticket.field = currRowData.fieldname;
              this.getticketfield(this.ticket.project);
            }
            this.ticket.allotedto = currRowData.assigneto
            this.getEmpallocatedtoProject(currRowData.assigneto);
            this.ticket.isactive = currRowData.isactive;
            this.ticket.iseditstate = true
            this.isAddState=true

            if(currRowData.fieldname && (currRowData.fieldid && (currRowData.fieldid !='Ticket Category' || currRowData.fieldid !='Ticket Sub Category'))){
              this.getticketfieldvalues();
            }else if(currRowData.fieldname =='Ticket Category' && (currRowData.fieldid ==null || currRowData.fieldid =='' || currRowData.fieldid ==undefined || currRowData.fieldid =='Ticket Category')){
              this.getprojectmodules();
            }else if(currRowData.fieldname =='Ticket Sub Category' && (currRowData.fieldid ==null || currRowData.fieldid =='') || currRowData.fieldid ==undefined || currRowData.fieldid =='Ticket Sub Category'){
              this.getAppModulesbyprojectid();
            }

            if(flag==0){
              this.isedit=true
              this.isRequestActive=true
            }
            if(this.ticket.projectlist && this.ticket.projectlist.length > 0 && (this.ticket.project && this.ticket.project !=null)){
                const checkexist = this.ticket.projectlist.some(item =>item.id === this.ticket.project);
                if(!checkexist){
                    this.getProjectnamebyid(this.ticket.project);
                }
            }else if(this.ticket.projectlist.length ==0 && (this.ticket.project && this.ticket.project !=null)){
                this.getProjectnamebyid(this.ticket.project);
            }
          },
          selvalue(state){
            this.ticket.values =null;
            this.ticket.values =state.id;
            if(!state && !state.id){
              this.errors.ticket.values ='Value is required';
            }else{
              this.errors.ticket.values ='';
            }
          },
        getTasktypeConfigLists(projectid) {
          let payload = {
          empcode: this.undt.userid,
          useremail: this.undt.username,
          projectid: parseInt(projectid.toString()),
          trackername: 'Ticket Request Type',
            };
            axios({
                method: "POST",
                url: '/api/businesstask/getTasktypeConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    let slist = [];
                    result.data.data.rows.forEach((rec) => {
                        if(Object.values(rec.statusconfig).length>0){
                            Object.entries(rec.statusconfig).forEach(([skey, statusnames]) => {
                                if(statusnames.length>0) {
                                    statusnames.forEach(val => {
                                        if(!slist.some(sdata => sdata.id === val)){
                                            slist.push({ id: val, label: val });
                                        }
                                    });
                                }
                            });
                        }
                    })
                    if(this.ticket.project != null && this.ticket.project == apiUrl.merchandisingProId){
                        this.sortTypeList(slist, 'merchandising')
                    } else{
                        this.sortTypeList(slist, 'otherprojects')
                    }
                } else {
                    this.ticket.mrtypelist = [];
                }
            });
    },
    tickettypeformrp(state){
      this.getTasktypeConfigLists(state);
    },
    sortTypeList(typelist, type){
      let numArrFromTypelist = [];
      typelist.forEach(element => {
        if(element.id != null) {
          numArrFromTypelist.push(element.id);
        }
      });
      if(numArrFromTypelist.length>0) {
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid,
          moduletype: 'ticket',
          typearr: numArrFromTypelist,
          projecttype: type
        };
        axios({
          method: "POST",
          url: 'api/ticket/sortTypeList',
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.ticket.mrtypelist = result.data.data.rows;
          } else {
            this.ticket.mrtypelist = [];
          }
        });
      }
    },
    getApplicationdata: function (mdl, curracttab) {
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      let apiURL = "api/master/commonmaster/list";
       if (mdl == "t44") {
        apiURL = "api/master/commonmaster/customselect";
        /* this.inputappl.projectmoduleid=this.ticket.project */
        this.inputappl.isSearch = false;
        this.inputappl.section = "issue";
        this.inputappl.isFilterSelect = 1;
        this.inputappl.isFilterPayload = {};
      } 
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        headers: { authorization: this.tokendata },
      }).then((result) => {
        if (result.data.errorCode == 0) {
            if (mdl == "t44") {
            if (curracttab == "ticket") {
              this.ticket.severitylist = result.data.data.rows;
              return true;
            }
          } 
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    },
    filters: {
      basename: function (value) {
        if (!value) return "";
        return value.substr(value.lastIndexOf("/") + 1);
      },
    },
  };
  </script>
  