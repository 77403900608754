<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-10 col-md-9 mobile_margin-px-0_00 d-flex align-items-center">
                          <div class="avatar bg-light-secondary rounded d-inline-flex">
                              <div class="avatar-content">
                                  <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                              </div>
                          </div>
                           <h4 class="card-title d-inline-flex ml-1">Search Project List Report</h4>
                      </div>
                      <span class="rotetions_view_arrow ml-auto mx-1"> 
                      <em class="las la-angle-down"></em>
                    </span>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" >
                <div class="card-body pt-2 px-sm-mobile-10">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 mb-0">
                      <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getprojectlist(search)"> <search-icon size="1.5x" class="custom-class" ></search-icon> <span>Search</span></button>
                      <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()"> <x-icon size="1.5x" class="custom-class" ></x-icon> <span>Clear</span> </button>
                      <button type="move" class="btn btn-relief-primary mt-2 mr-1" @click="ExportXL()"> <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span> Export</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="reportTopScroll">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-header p-2">
                    <h4 class="card-title">
                      Project List Report
                    </h4>
                  </div>
                  <div class="card-datatable">
                    <div class="" id="myTabContent">
                      <div
                        class=""
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="">
                              
                              <div
                                class="table-responsive_sitcky"
                                v-if="showtabledata"
                              >
                              <table class="table table-hover table-striped table-bordered table-sm all_table_stickys" aria-describedby="Project List Report">
                                  <thead>
                                    <tr style="color:blue;">
                                      <th scope="col" colspan="4" class="title align-middle  py-1">Project Details</th>
                                      <th scope="col" colspan="6" class="title align-middle">Task allocated count</th>
                                      <th scope="col" colspan="6" class="title align-middle">Issues allocated count</th>
                                      <th scope="col" colspan="6" class="title align-middle">Incident allocated count</th>
                                    </tr>
                                    <tr>
                                      <th scope="col" class="align-middle text-left first-col-sticky" style="min-width:180px;padding:5px;">Project Name</th>
                                      <th scope="col" class="align-middle text-left">Project Manager</th>
                                      <th scope="col" class="align-middle text-left">APM/PM</th>
                                      <th scope="col" class="align-middle text-left">Engg. Manager</th>

                                      <th scope="col" class="text-nowrap align-middle">1 Year</th>
                                      <th scope="col" class="text-nowrap align-middle">6 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">3 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">1 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Week</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Day</th>

                                      <th scope="col" class="text-nowrap align-middle">1 Year</th>
                                      <th scope="col" class="text-nowrap align-middle">6 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">3 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">1 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Week</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Day</th>

                                      <th scope="col" class="text-nowrap align-middle">1 Year</th>
                                      <th scope="col" class="text-nowrap align-middle">6 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">3 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">1 Month</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Week</th>
                                      <th scope="col" class="text-nowrap align-middle">Last Day</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)">                                        
                                        <td :class="'capitalisetext text-left font-weight-normal first-col-sticky cursorpointercls sitebluecolor unique'+index+1+currPageNum*pagelimit" @click="redirecttourl('projectId',data)">
                                          {{data.projectname}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal text-left">
                                          {{data.programmanager? data.programmanager:'NA'}}
                                        </td>
                                         <td class="capitalisetext font-weight-normal text-left">
                                          {{data.apm? data.apm:'NA'}}
                                        </td>
                                        <td class="capitalisetext font-weight-normal text-left">
                                          {{data.enggmanger? data.enggmanger:'NA'}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.oneyrtask}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.sixmnthtask}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.threemnthtask}}
                                        </td>  
                                         <td class="title font-weight-normal">
                                          {{data.onemnthtask}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.lastweektask}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.lastdaytask}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.oneyrissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.sixmnthissue}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.threemnthissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.onemnthissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.lastweekissue}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.lastdayissue}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.oneyrincident}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.sixmnthincident}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.threemnthincident}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.onemnthincident}}
                                        </td>
                                         <td class="title font-weight-normal">
                                          {{data.lastweekincident}}
                                        </td>
                                        <td class="title font-weight-normal">
                                          {{data.lastdayincident}}
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="companymasterlist.length == 0 && isresponce" >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                                <table class="table table-bordered table-sm dataTableListing" style="display:none;" aria-describedby="Project List Report">
                                  <thead>
                                    <tr>
                                      <th scope="col">Project Name</th>
                                      <th scope="col">Project Manager</th>
                                      <th scope="col">APM/PM</th>
                                      <th scope="col">Engg. Manager</th>
                                      <th scope="col">Task allocated count</th>
                                      <th scope="col">Issues allocated count</th>
                                      <th scope="col">Incident allocated count</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="data.empid+Math.floor(Math.random() * 1000000000)">                                        
                                        <td :class="'capitalisetext cursorpointercls sitebluecolor unique'+index+1+currPageNum*pagelimit" @click="redirecttourl('projectIdDisable',data)">
                                          {{data.projectname}}
                                        </td>
                                        <td class="capitalisetext">
                                          {{data.programmanager}}
                                        </td>
                                         <td class="capitalisetext">
                                          {{data.apm}}
                                        </td>
                                        <td class="capitalisetext">
                                          {{data.enggmanger}}
                                        </td>
                                         <td class="title">
                                          {{data.totaltaskcount}}
                                        </td>
                                         <td class="title">
                                          {{data.totalissuecount}}
                                        </td>
                                        <td class="title">
                                          {{data.totalincidentcount}}
                                        </td>  
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pageCenter pt-2">
                        <pagination
                          v-if="showtabledata"
                          v-model="page"
                          :records="totalcount"
                          :per-page="pagelimit"
                          @paginate="clickCallback"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Treeselect from "@riophae/vue-treeselect";
import { SearchIcon,EyeIcon,XIcon,ExternalLinkIcon } from "vue-feather-icons";
import Pagination from "vue-pagination-2";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    EyeIcon,
    Treeselect,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    Pagination,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      search: {
        searchProjectname:null,
      },
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      stylevariation: apiUrl.stylevariation,
      showprojectdata: [],
      showroledata: [],
      showempdata: [],      
      glbMdl: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      viewprojectlist: 0,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      tokendata:null,
      isresponce:false
    };
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem('token');
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.getprojectlist(rowdata);
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.pageoffset=1
      this.search.searchProjectname = state;
    }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.pageoffset=1
        this.isLoading = true;
        let url = "api/listing/getProjectAllocatedToEmp";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empid: this.undt.userid,
          empcode: this.undt.userid,
          isfrompage:'listingpage'
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
          this.projectnameList.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        }).catch(e => {
          this.displayError(e)
        });
      }
      
    },
    resetRecords: function () {
      let rowdata={}
      this.getprojectlist(rowdata);
      this.search.searchProjectname = null;
    },
    getprojectlist(rowdata) {
      this.isLoading = true; 
      this.parentmoduledata = rowdata;   
      this.input = {
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        uniqueid: this.undt.userid,
        isFilterSelect: 0,
        isFilterPayload: rowdata,
        empcode: this.undt.userid,
      };
      let url = "api/reports/projectlistreportFunction";      
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Report data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.project.company = this.activecompanyid;
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata);
      }
      setTimeout(function(){
            $('html, body').animate({
                scrollTop: $("#reportTopScroll").offset().top -70
            });
        }, 500);
    },
    setSelected(value) {
      this.selected = value;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
 ExportXL() {
      this.isLoading = true;
      this.exportxlsdata = [];
      this.exporterr = "";
       this.input = {
        limit:null,
        offset:null,
        isFilterPayload:{searchProjectname:this.search.searchProjectname},
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: `api/reports/projectlistreportFunction`,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        if (result.data.errorCode == 0) {
          this.exportxlsdata = result.data.data.rows;
          var filename = "";
            this.download(result.data.data.rows,filename)
        } else {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
        }
        this.isLoading = false;
      }).catch(e => {
          this.displayError(e)
        });
    },
    download(readExportdata,filename) {
      if (readExportdata.length > 0) {
          var ShowLabel = 'projectlistreport_'+filename
          var JSONData = readExportdata
          var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
          var CSV = ''
          if (ShowLabel) {
              let row = ''
              for (let index in arrData[0]) {
                  row += index + ','
              }
            row = row.slice(0, -1)
            CSV = 'Sr#,Project Name,Project Manager,APM/PM,Engg.Manger,1 Year Task allocated count,6 Month Task allocated count,3 Month Task allocated count,1 Month Task allocated count, Last Week Task allocated count, Last Day Task allocated count, 1 Year Issues allocated count,6 Month Issues allocated count,3 Month Issues allocated count,1 Month Issues allocated count, Last Week Issues allocated count, Last Day Issues allocated count,1 Year Incident allocated count,6 Month Incident allocated count,3 Month Incident allocated count,1 Month Incident allocated count, Last Week Incident allocated count, Last Day Incident allocated count ' + '\r\n'
          }
        var row = ''
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let projectname = index.hasOwnProperty('projectname') ? index.projectname : '';
          let programmanager = index.hasOwnProperty('programmanager') ? index.programmanager == null?'':index.programmanager : '';
          let apm = index.hasOwnProperty('apm') ? index.apm == null?'' :index.apm : '';
          let enggmanger = index.hasOwnProperty('enggmanger') ? index.enggmanger == null?'':index.enggmanger : '';
          let oneyrtask = index.hasOwnProperty('oneyrtask') ? index.oneyrtask : '';
          let sixmnthtask = index.hasOwnProperty('sixmnthtask') ? index.sixmnthtask : '';
          let threemnthtask = index.hasOwnProperty('threemnthtask') ? index.threemnthtask : '';
          let onemnthtask = index.hasOwnProperty('onemnthtask') ? index.onemnthtask : '';              
          let lastweektask = index.hasOwnProperty('lastweektask') ? index.lastweektask : '';
          let lastdaytask = index.hasOwnProperty('lastdaytask') ? index.lastdaytask : '';
          let oneyrissue = index.hasOwnProperty('oneyrissue') ? index.oneyrissue : '';
          let sixmnthissue = index.hasOwnProperty('sixmnthissue') ? index.sixmnthissue : '';
          let threemnthissue = index.hasOwnProperty('threemnthissue') ? index.threemnthissue : '';
          let onemnthissue = index.hasOwnProperty('onemnthissue') ? index.onemnthissue : '';              
          let lastweekissue = index.hasOwnProperty('lastweekissue') ? index.lastweekissue : '';
          let lastdayissue = index.hasOwnProperty('lastdayissue') ? index.lastdayissue : '';
          let oneyrincident = index.hasOwnProperty('oneyrincident') ? index.oneyrincident : '';
          let sixmnthincident = index.hasOwnProperty('sixmnthincident') ? index.sixmnthincident : '';
          let threemnthincident = index.hasOwnProperty('threemnthincident') ? index.threemnthincident : '';
          let onemnthincident = index.hasOwnProperty('onemnthincident') ? index.onemnthincident : '';              
          let lastweekincident = index.hasOwnProperty('lastweekincident') ? index.lastweekincident : '';
          let lastdayincident = index.hasOwnProperty('lastdayincident') ? index.lastdayincident : '';
            row += '"' + srno + '",',
            row += '"' + projectname + '",',
            row += '"' + programmanager + '",',
            row += '"' + apm + '",',
            row += '"' + enggmanger + '",',
            row += '"' + oneyrtask + '",',
            row += '"' + sixmnthtask + '",',
            row += '"' + threemnthtask + '",',
            row += '"' + onemnthtask + '",',
            row += '"' + lastweektask + '",',
            row += '"' + lastdaytask + '",',
            row += '"' + oneyrissue + '",',
            row += '"' + sixmnthissue + '",',
            row += '"' + threemnthissue + '",',
            row += '"' + onemnthissue + '",',
            row += '"' + lastweekissue + '",',
            row += '"' + lastdayissue + '",',
            row += '"' + oneyrincident + '",',
            row += '"' + sixmnthincident + '",',
            row += '"' + threemnthincident + '",',
            row += '"' + onemnthincident + '",',
            row += '"' + lastweekincident + '",',
            row += '"' + lastdayincident + '",',
            row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        var fileName = 'projectlistreport_'+filename+'_'+moment().format('DD_MM_YY_HH_mm_ss')
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        var link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Export Report successful",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
    } else {
      Swal.fire({
        title: "",
        text: "Data not found",
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: !1,
      });
    }
},
export (data, filename, mime) {
      var data
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      })
      download(blob, filename, mime)
    },
    redirecttourl(state, rowdata) {
      if (state == 'projectId') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid,
        })
      } else if (state == 'projectIdDisable') {
        this.$router.push({
          path: '/project/summary?pid='+rowdata.projectid,
        })
      }
    }
  },
};
</script>
