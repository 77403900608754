<template>
    <div class="app-content content ">
        <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
        ></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <section id="card-navigation modern-horizontal-wizard">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <h3 class="content-header-title float-left mb-2">Test Cycle Set</h3>
                        <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">Test Execution</li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls" ><router-link :to="{ name: 'TestCycleSetList', params: { search: true }}">Test Cycle Set's</router-link></li>
                            <li class="breadcrumb-item sitebluecolor cursorpointercls">Update Test Cycle Set</li>
                        </ol>
                        </div>
                    </div>
                </div>
                <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header py-1 px-2" style="background-color: #f1f2f3;">
                  <h3 class="card-title">
                      Update Test Cycle Set
                  </h3>
                </div>
                <div>
                    <div class="card-body">
                        <form class="form form-vertical">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectlistdd">Test Cycle Name</label>
                                        <input type="text" id="testcasename" class="form-control" name="" placeholder="Enter Test Cycle Name" v-model="testcycle.cyclename" @blur="validateForm('t39',$event)"
                                        v-bind:class="{ 
                                        'form-control': true,
                                        '': !validText(testcycle.cyclename) && nameBlured, }"
                                        v-on:blur="nameBlured = true" @close="validateForm('t39',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.testcycle.cyclename && !validText(testcycle.cyclename)">{{ this.errors.testcycle.cyclename }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Test Case Group Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext" :multiple="true" :clear-on-select="true" placeholder="Enter Min 4 Chars To Search Test Case Group Name" v-model="testcycle.tcgroup" :options="testcycle.tcgrouplist" @input="seltestcstcgroup" @close="validateForm('t39',$event)" v-on:search-change="getTestCaseApprovedstatus"/>
                                        <div class="errorinputmsg" v-if="this.errors.testcycle.tcgroup">{{ this.errors.testcycle.tcgroup }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Business Task Code</label>
                                        <treeselect class="projectmdllistdd capitalisetext"  placeholder="Enter min 8 char of business task code" v-model="testcycle.businesscode" :options="testcycle.btccodelist" @input="selectbusinesscode" :disabled="isBTMcodeselected" @close="validateForm('t39',$event)" v-on:search-change="getbtccodelist"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.businesscode">{{ this.errors.testcycle.businesscode }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Business Task Name</label>
                                        <treeselect class="projectmdllistdd capitalisetext"  placeholder="Enter min 5 char of Business Task Name" v-model="testcycle.businessname" :disabled="isBTMnameselected" :options="testcycle.btcnamelist" @input="selectbusinessname" @close="validateForm('t39',$event)" v-on:search-change="getbtcnamelist"/>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.businessname">{{ this.errors.testcycle.businessname }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectlistdd">Version</label>
                                        <input type="text"  class="form-control capitalisetext"  v-model="testcycle.version" placeholder="Enter version" @blur="validateForm('t39',$event)" v-bind:class="{ 
                                        'form-control': true,
                                        '': !validText2(testcycle.version) && versionBlured, }"
                                        v-on:blur="versionBlured = true" @close="validateForm('t39',$event)"/>
                                        <div class="errorinputmsg" v-if="this.errors.testcycle.version && !validText2(testcycle.version)">{{ this.errors.testcycle.version }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group datePicker">
                                    <label for="" class="d-block">Start Date</label>
                                        <date-picker placeholder="Select Start Date"
                                        type="datetime" format="YYYY-MM-DD HH:mm" v-model="testcycle.startdate" valueType="format" class="startdate" id="StartDate" v-on:change="validateDate('startdate', $event)" :minute-step="5" :disabled-date="notBeforeTodaydate"></date-picker>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.startdate">{{ this.errors.testcycle.startdate }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group datePicker">
                                    <label for="" class="d-block">End Date</label>
                                        <date-picker placeholder="Select End Date" type="datetime" format="YYYY-MM-DD HH:mm" v-model="testcycle.enddate" valueType="format" class="startdate" id="EndDate" v-on:change="validateDate('enddate', $event)" :minute-step="5" :disabled-date="notBeforeTodaydate"></date-picker>
                                    <div class="errorinputmsg" v-if="this.errors.testcycle.enddate">{{ this.errors.testcycle.enddate }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group mb-1">
                                        <label for="projectmdllistdd">Status</label>
                                        <input placeholder="Select Status" class="form-control capitalisetext" v-model="testcycle.status" :options="statuslist" @close="validateForm('t39',$event)" @input="selectstatus" v-if="statuslist.length<=1" disabled/>
                                        <treeselect class="projectmdllistdd capitalisetext"  placeholder="Select Status" v-model="testcycle.status" :options="statuslist" @close="validateForm('t39',$event)" @input="selectstatus" v-else/>
                                        <div class="errorinputmsg" v-if="this.errors.testcycle.status">{{ this.errors.testcycle.status }}</div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="col-12 mg-top1 text-right">
                            <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard($event)" id="submitbtnt39">Submit</button>
                            <router-link :to="{ name: 'TestCycleSetList', params: { search: true }}">  <button style="margin-left:8px;" type="cancel" class="btn btn-outline-secondary">Cancel</button></router-link>
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </div>        
    </div>
</template>
<style>
.card .card-header{
    padding: 0.8rem;
}
.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Pagination from 'vue-pagination-2';
import { EditIcon, EyeIcon, SearchIcon, CodesandboxIcon, GridIcon, ChromeIcon, CloudIcon, MoreVerticalIcon, PlusSquareIcon,PackageIcon, BoxIcon, Link2Icon, PlusIcon, Edit2Icon, MinusSquareIcon, ClipboardIcon,Trash2Icon,DownloadIcon } from 'vue-feather-icons';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';

export default {
    name:'tcreate',
    components:{
        Loading,Trash2Icon,DownloadIcon,
        VueElementLoading,DatePicker,
        vSelect,
        EditIcon,
        EyeIcon,
        Treeselect,
        SearchIcon,
        GridIcon,
        CodesandboxIcon,
        CloudIcon,
        ChromeIcon,
        BoxIcon,
        Link2Icon,
        PackageIcon,
        Pagination,
        MoreVerticalIcon,
        PlusIcon,
        Edit2Icon,
        PlusSquareIcon,
        MinusSquareIcon,
        ClipboardIcon,
        ckeditor: CKEditor.component,
    },    
    mixins: [ commonMethods ],
    data() {
        return {
            isRedirectedFromFunc: false,
            isLoading: false,
            fullPage: true,
            editRowData:[],
            TimeFormat: apiUrl.TimeFormat,
            statuslist: [],
            isBTMcodeselected: false,
            isBTMnameselected: false,
            testcycle:{
                cyclename: null,
                testcycle: null,
                tcgrouplist: [],
                businesscode: null,
                businessname: null,
                assignto: null,
                startdate: null,
                enddate: null,
                tcgroup: null,
                btccodelist: [],
                btcnamelist: [],
                assigntolist: [],
                status: null,
                version: null
            },
            isAddState:false,
            pagefeatures:[],
            errors:{
                testcycle:{
                    cyclename: null,
                    testcycle: null,
                    businesscode: null,
                    businessname: null,
                    assignto: null,
                    startdate: null,
                    enddate: null,
                    tcgroup: null,
                    status: null,
                    version: null
                }
            },
            tokendata:null,
            nameBlured: false,
            versionBlured: false,
        }
    },
    // created() {
    //     this.pagefeatures = {}
    //     let pagefeatures1 = JSON.parse(window.atob(window.localStorage.getItem('pagefeatures')))
    //     pagefeatures1.forEach(element => {
    //     if (element.page === '/testcase') {
    //         this.pagefeatures[element.featurename] = element.featureaccess
    //     }
    //     })
    // },
    mounted(){
        let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.getcyclesetidbyid(this.$route.query.cyclesetid)
            // this.getbtccodelist();
            // this.getbtcnamelist();
            this.isBTMcodeselected = true
        }
    },
    methods:{
        selectassignee(state) {
            if(!state) {
                this.errors.testcycle.assignto = "Assign to required"
            } else {
                this.errors.testcycle.assignto = ""
            }
        },
        selectstatus(state) {
            if(!state) {
                this.errors.testcycle.status = "Status required"
            } else {
                this.errors.testcycle.status = ""
            }
        },
        selectbusinesscode(state) {
            if(!state) {
                this.errors.testcycle.businesscode = "Business task code required"
                this.isBTMcodeselected = false
                this.isBTMnameselected = false
                this.testcycle.businessname = null
                this.testcycle.businesscode = null
                this.testcycle.assignto = null
                // this.getbtcnamelist()
            } else {
                this.errors.testcycle.businesscode = ""
                if (this.testcycle.btcnamelist.length > 0) {
                    if (this.testcycle.btccodelist!=[]) {
                        this.getbtcnamelist(null,state)
                    }else{
                        let bname = this.testcycle.btcnamelist.find(l => l.id == state)
                        this.testcycle.businessname = bname.id
                    }
                    this.isBTMnameselected = false
                    this.isBTMcodeselected = true
                }
            }
        },
        selectbusinessname(state) {
            if(!state) {
                this.errors.testcycle.businessname = "Business task name required"
                this.isBTMnameselected = false
                this.isBTMcodeselected = false
                this.testcycle.businessname = null
                this.testcycle.businesscode = null
                this.testcycle.assignto = null
                // this.getbtccodelist()
            } else {
                this.errors.testcycle.businessname = ""
                if (this.testcycle.btccodelist.length>0) {
                    if (this.testcycle.btcnamelist!=[]) {
                        this.getbtccodelist(null,state)
                    }else{
                        let code = this.testcycle.btccodelist.find(j => j.businesstaskmasterid == state)
                        this.testcycle.businesscode = code.id
                        code.projectist=code.projectist.split(",")
                    }
                    this.isBTMcodeselected = false
                    this.isBTMnameselected = true
                    // this.getassigntolist(code.projectist);
                }
            }
        },
        notBeforeTodaydate: function (date) {
            return date < new Date(new Date().setHours(0, 0, 0, 0));
        },
        getTestCaseApprovedstatus(node){
            if(node && node.length > 3){
                this.input={
                    useremail  : this.undt.username,
                    empcode: this.undt.userid,
                    groupname: node.toLowerCase()
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getTestCaseApprovedstatus',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.testcycle.tcgrouplist = result.data.data.rows;
                    } else {
                        this.testcycle.tcgrouplist = [];
                    }
                })
            }
        },
        getTestCaseApprovedstatusbyid(gid){
            if(gid && gid.length > 0){
                this.input={
                    useremail  : this.undt.username,
                    empcode: this.undt.userid,
                    groupids: gid
                }
                axios({
                    'method': 'POST',
                    'url': 'api/testcycle/getTestCaseApprovedstatus',
                    'data': this.input,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {             
                    if(result.data.errorCode == 0){
                        this.testcycle.tcgrouplist = result.data.data.rows;
                    } else {
                        this.testcycle.tcgrouplist = [];
                    }
                }).catch(e => {
                   this.displayError(e)
                })
            }
        },
        getbtccodelist(node,data1){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            if (node && node.length>8) {
                this.input.taskcode = node.toUpperCase()
            }
            if (!node) {
                this.input.businessid=parseInt(data1)
            }
            if ((this.input.taskcode) || this.input.businessid) {
                this.isLoading=true
                
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getbtccodelist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {      
                this.isLoading=false       
                if(result.data.errorCode == 0){
                    this.testcycle.btccodelist = result.data.data.rows;
                    if (this.input.businessid) {
                        this.testcycle.businesscode=this.testcycle.btccodelist[0].id
                    }
                } else {
                    this.testcycle.btccodelist = []
                }
            }).catch(e => {
                   this.displayError(e)
            })
        }
        },
        getbtcnamelist:function(node, instanceId){
                
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
            }
            if (node && node.length>4) {
                this.input.taskname = node.toUpperCase()
            }
            if (!node) {
                this.input.businessid = parseInt(instanceId)
            }
            if ((this.input.taskname) || this.input.businessid) {
                this.isLoading=true
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getbtcnamelist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {    
                this.isLoading=false         
                if(result.data.errorCode == 0){
                    this.testcycle.btcnamelist = result.data.data.rows;
                    if (this.input.businessid) {
                        this.testcycle.businessname = this.testcycle.btcnamelist[0].id
                    }
                } else {
                    this.testcycle.btcnamelist = []
                }
            }).catch(e => {
                   this.displayError(e)
                })
        }
        },
        getassigntolist(id){
            this.input={
                useremail  : this.undt.username,
                empid: this.undt.userid,
                empcode: this.undt.userid,
                projectid: id,
            }
            axios({
                'method': 'POST',
                'url': 'api/testcycle/getassigntolist',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {             
                if(result.data.errorCode == 0){
                    this.testcycle.assigntolist = result.data.data.rows;
                } else {
                    this.testcycle.assigntolist = []
                }
            }).catch(e => {
                   this.displayError(e)
            })
        },
        validateDate(mdl) {
            let startDate = this.testcycle.startdate;
            let endDate = this.testcycle.enddate;
            if(mdl === 'enddate') {
                if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.testcycle.enddate = "End date should be greater than start date";
                    this.testcycle.enddate = '';
                } else {
                    this.errors.testcycle.enddate = '';
                }
            } else if(mdl === 'startdate') {
                if (endDate && (Date.parse(endDate) < Date.parse(startDate))) {
                    this.errors.testcycle.startdate = "Start date should be lesser than end date";
                    this.testcycle.startdate = '';
                } else {
                    this.errors.testcycle.startdate = '';
                }
            }
        },
        seltestcstcgroup(state){
            this.testcycle.tcgroup = null
            this.testcycle.tcgroup = state
            if(!state && !this.isAddState){
                this.errors.testcycle.tcgroup='Test case group name required'
            }else{                
                this.errors.testcycle.tcgroup=null
            }
        },
        getcyclesetidbyid(cyclesetid){
         this.isLoading = true;
            let url = "api/testcycle/getcyclesetidbyid";
            this.input = {
                cyclesetid:cyclesetid,
                useremail: this.undt.username,
                empid:this.undt.userid,
                empcode: this.undt.userid,
            };
            axios({
                method: "POST",
                url: url,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.editRowData = result.data.data;
                    this.editRowData=this.editRowData[0]
                    this.openEditModule(this.editRowData, 0)
                } else {
                    this.editRowData = [];
                    this.$router.push({ name: 'TestCycleSetList', params: { search: true }})
                    Swal.fire({
                        title: "Failed!",
                        text: "Your are not authorised user to update this test case",
                        icon: "info",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    })
                }
            }).catch(e => {
                   this.displayError(e)
            })
        },
        getStatusConfigList(pid, status) {
            let payload = {
                empcode: this.undt.userid,
                useremail: this.undt.username,
                projectid: pid,
                trackername: 'Test Cycle Set',
                currStatus: status,
            };
            axios({
                method: "POST",
                url: '/api/testcase/getBStatusConfigList',
                data: payload,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                if(result.data.errorCode == 0){
                    this.statuslist = result.data.data.rows;
                    this.statuslist.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
                } else {
                    this.statuslist = [];
                }
            }).catch(e => {
                   this.displayError(e)
            })
        },
        openEditModule(currRowData, flag){
            this.testcycle.cyclename=currRowData.cyclesetname
            this.testcycle.businesscode=currRowData.businesstaskmasterid
            this.getbtccodelist(null,currRowData.businesstaskmasterid);
            this.testcycle.businessname=currRowData.businesstaskmasterid
            this.getbtcnamelist(null,currRowData.businesstaskmasterid);
            this.testcycle.version=currRowData.versionno
            this.testcycle.status=currRowData.status
            if (currRowData.status && currRowData.projectist) {
                this.getStatusConfigList(currRowData.projectist.split(","), currRowData.status);
            }else{
                this.getStatusConfigList(null, currRowData.status);
            }
            this.testcycle.assignto=currRowData.assignedto
            this.testcycle.startdate=this.dateYMDHMSFormat(currRowData.startdate)
            this.testcycle.enddate=this.dateYMDHMSFormat(currRowData.enddate)
            this.testcycle.tcgroup=currRowData.groupnameidlist.split(',')
            if(this.testcycle.tcgroup){
                this.getTestCaseApprovedstatusbyid(currRowData.groupnameidlist.split(','));
            }
            this.isAddState=true
        },
        savewizard(objThis){
            this.valid = this.validateForm(objThis);
            if (this.valid) {
                this.isLoading = true;  
                this.input = {
                    useremail: this.undt.username,
                    empcode: this.undt.userid,
                }
                let apiURL='api/testcycle/testcycleupdate'
                this.input.cyclename=this.testcycle.cyclename
                this.input.tcgroup=this.testcycle.tcgroup
                this.input.businesstaskmasterid=this.testcycle.businessname
                this.input.startdate=this.testcycle.startdate
                this.input.enddate=this.testcycle.enddate
                this.input.status= this.testcycle.status
                this.input.version = this.testcycle.version
                this.input.cyclesetid = parseInt(this.editRowData.cyclesetid),
                // this.input.revieweddate = this.editRowData.revieweddate,
                // this.input.approveddate = this.editRowData.approveddate,
                this.input.cyclecode = this.editRowData.cyclesetcode,
                this.input.qalead = this.editRowData.qalead,
                this.input.qamanager = this.editRowData.qamanager,
                this.input.cyclecreatedby = this.editRowData.createdby
                this.isAddState=false
                axios({
                'method': 'POST',
                'url': apiURL,
                'data': this.input,
                'headers':{'authorization':this.tokendata}
                })
                .then(result => {    
                    this.isLoading = false;         
                    if(result.data.errorCode == 0){
                        Swal.fire({
                            title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.$router.push({ name: 'TestCycleSetList', params: { search: true }})
                    }else if(result.data.errorCode == 3){
                        Swal.fire({
                            title: "Session Expired...!!",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: !1,
                        }).then(function() {
                            window.location.href = "/#/login";
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Failed",
                            text: result.data.msg,
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                    }
                }).catch(e => {
                   this.displayError(e)
                })
            }
        },
        validateForm(mdl){
            this.clearerrorForm();
            this.isAddState=false
            let isValid=true;
            this.nameBlured = true;
            this.versionBlured = true
            this.scenarioBlured = true;
            if(mdl=="t39"){
                if(!this.testcycle.cyclename){
                    this.errors.testcycle.cyclename="Test cycle name required";
                    isValid= false;
                }
                if(!this.testcycle.startdate){
                    this.errors.testcycle.startdate="Start date required";
                    isValid= false;
                }
                if(!this.testcycle.enddate){
                    this.errors.testcycle.enddate="End date required";
                    isValid= false;
                }
                if(!this.testcycle.assignto){
                    this.errors.testcycle.assignto="Assign to required";
                    isValid= false;
                }
                if(!this.testcycle.businesscode){
                    this.errors.testcycle.businesscode="Business task code required";
                    isValid= false;
                }
                if(!this.testcycle.businessname){
                    this.errors.testcycle.businessname="Business task name required";
                    isValid= false;
                }
                if (this.testcycle.tcgroup ==null || this.testcycle.tcgroup ==[] || this.testcycle.tcgroup.length==0) {
                    this.errors.testcycle.tcgroup="Test case group name required";
                    isValid= false;
                }
                if(!this.testcycle.status){
                    this.errors.testcycle.status="Status required";
                    isValid= false;
                }
                if(!this.testcycle.version){
                    this.errors.testcycle.version="Version is required";
                    isValid= false;
                }
            }
            return isValid
        },
        validText : function(inputval) {
            var re = /[A-Za-z0-9].{4,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        validText2 : function(inputval) {
            var re = /[A-Za-z0-9].{0,}/;
            if(inputval){
                return re.test(inputval.toLowerCase());
            }
        },
        clearerrorForm(){
            this.errors={
                testcycle:{
                    cyclename: null,
                    tcgroup: null,
                    businesscode: null,
                    businessname: null,
                    assignto: null,
                    startdate: null,
                    enddate: null,
                    btccodelist: [],
                    btcnamelist: [],
                    assigntolist: [],
                    version: null
                }
            }
        },
        dateFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD-MM-YYYY');
        },
        dateYMDHMSFormat(value){
            if (!value) return "";
            return moment(String(value)).format('YYYY-MM-DD HH:mm:ss');
        },
    },
    filters: {
      basename: function (value) {
          if (!value) return ''
          return value.substr(value.lastIndexOf("/") + 1)
      }
    }
}
</script>