<template>
    <div class="app-content content ">
        <loading  :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="orange" loader="dots" :width="100" :height="100"></loading>
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper pb-1 mt-1">
            <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5">
                <div class="row breadcrumbs-top">
                    <div class="col-9 px-0">
                        <h4 class="content-header-title float-left mb-0">Appraisal</h4>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item sitebluecolor cursorpointercls">Appraisal Form Report</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="dashboardmysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <monitor-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></monitor-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Employee Appraisal Form Dashboard</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div> 
                </div>

                <div class="card-body pt-1">
                    <div class="row">
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                            <label class="form-label lable-left">Appraisal Cycle</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Appraisal Cycle" v-model="search.appraisalcyclename1" :options="appraisalcyclenamelist"/>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                            <label for="form-label lable-left">Department Name</label>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Department Name" :options = "deparmentlist" v-model="search.department1" v-if="deparmentlist.length>1"/>
                            <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Department Name" :options = "deparmentlist" v-model="search.department1" disabled v-else/>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-0  text-right">
                            <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getTrackerDetails(search,1)"> 
                                <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                            </button>
                            <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="resetRecords()">
                                <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="card-body pt-1" v-bind:class="[{show: dashboardisSearchRequestActive},card_content,collapse]">
                    <div class="tab-content" id="dashboardtable">
                        <div class="row">
                            <div class="col-md-6 col-xl-4" v-for="(datavalue, datakey) in trackerdetails">
                                <div class="card shadow-none bg-transparent" v-bind:class="'border-'+getcolours(datakey)">
                                    <div class="card-body">
                                        <h4 v-bind:class="'text-'+getcolours(datakey)" class="card-title" style="cursor:pointer" @click="openListing(datavalue)">{{datavalue.moduletype}}</h4>
                                        <p class="card-text">Some quick example text to build on the card title and make up.</p>
                                        <div class="row borde text-center mx-0">
                                            <div style="cursor:pointer" @click="openListing(datavalue, 'total')" class="col-4 border-right py-1">
                                                <p class="card-text text-muted mb-0">Total</p>
                                                <h5 class="font-weight-bolder mb-0">{{parseInt(datavalue.completedtasks)+parseInt(datavalue.pendingtasks)}}</h5>
                                            </div>
                                            <div style="cursor:pointer" @click="openListing(datavalue, 'completed')" class="col-4 border-right py-1">
                                                <p class="card-text text-muted mb-0">Completed</p>
                                                <h5 class="font-weight-bolder mb-0">{{datavalue.completedtasks}}</h5>
                                            </div>
                                            <div style="cursor:pointer" @click="openListing(datavalue, 'pending')" class="col-4 py-1">
                                                <p class="card-text text-muted mb-0">Pending</p>
                                                <h5 class="font-weight-bolder mb-0">{{datavalue.pendingtasks}}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center" v-if="trackerdetails.length==0">
                            No record found
                        </div>
                        <div class="pageCenter text-center">
                        <pagination  v-model="page1" :records="totalcount1" :per-page="pagelimit" @paginate="pageclickCallback"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border-warning filter_card_searchbgst">
                <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                    <div class="container-fluid">
                        <div class="row row fc_my-05">
                            <div class="w-auto px-1 mobile-padding-0-2">
                                <div class="avatar bg-light-secondary rounded d-inline-flex">
                                    <div class="avatar-content">
                                        <search-icon
                                            size="1.5x"
                                            class="custom-class avatar-icon font-medium-3"
                                        ></search-icon>
                                    </div>
                                </div>
                                <h4 class="card-title d-inline-flex ml-1">Employee Appraisal Form Report</h4>
                            </div>
                            <span class="rotetions_view_arrow ml-auto mx-1"> 
                                <em class="las la-angle-down"></em>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
                    <div class="card-body pt-1">
                        <div class="row">

                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Appraisal Cycle</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Appraisal Cycle" v-model="search.appraisalcyclename" :options="appraisalcyclenamelist"/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label for="form-label lable-left">Department Name</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Department Name" :options = "deparmentlist" v-model="search.department" v-if="deparmentlist.length>1"/>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Department Name" :options = "deparmentlist" v-model="search.department" disabled v-else/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Form Name</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Form Name" :clear-on-select="true" :multiple="true" v-model="search.formname" :options="trackerlist" @input='selectTname'/>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                                <label class="form-label lable-left">Employee Name/ID</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter Min 4char Of Employee Name/ID" :clear-on-select="true" :multiple="true" v-model="search.empname" :options="employeenamelist" v-on:search-change="getallemplist"/>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                            <label class="form-label lable-left">Status</label>
                                <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Status" :clear-on-select="true" :multiple="true" v-model="search.status" :options="statuslist"/>
                            </div>

                            <div class="col-sm-6 col-md-6 col-lg-4 text-right mb-1">
                                <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getformlist(search,1)"> 
                                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span>
                                </button>
                                <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span>
                                </button>
                                <button type="move" :disabled="disabledexport" class="btn btn-relief-primary mt-2 " @click="ExportXL()">
                                    <external-link-icon size="1.5x" class="custom-class"></external-link-icon> <span>Export</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <section id="card-navigation">
                        <div class="file-manager-content-body">
                            <div class="drives">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="tab-content" id="apprisalformtable">
                                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                <div class="row" id="proModuleList">
                                                    <div class="col-12">
                                                        <div class="card">
                                                            <div class="table-responsive">
                                                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col" class="align-middle srno first-col-sticky" style="min-width:45px;">Sr#</th>
                                                                            <th scope="col" class="align-middle second-col-sticky">Employee Name</th>
                                                                            <th scope="col" class="align-middle  text-center">Designation</th>
                                                                            <th scope="col" class="align-middle text-center">Department Name</th>
                                                                            <th scope="col" class="align-middle  text-center" style="min-width: 200px;">Appraisal Cycle</th>
                                                                            <th scope="col" class="align-middle  text-center" style="min-width: 200px;">Form Name </th>
                                                                            <th scope="col" class="align-middle  text-center">Reporting Manager</th>
                                                                            <th scope="col" class="align-middle  text-center">Reviewers Name</th>
                                                                            <th scope="col" class="align-middle  text-center">Status </th>
                                                                            <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Assigned Date </th>
                                                                            <th scope="col" class="align-middle  text-center" style="min-width: 180px;">Form Filled By Mailid</th>
                                                                            <th scope="col" class="align-middle  text-center" style="min-width: 120px;">Submitted Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-for="(data,index) in appraisalformlist" >        
                                                                            <tr v-bind:key="index">
                                                                                <td class="srno first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                                                                <td class="second-col-sticky capitalisetext">
                                                                                    {{ data.empname }} - ({{ data.empid }})
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{ decryptText(data.designation) }}
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{ decryptText(data.department) }}
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{ data.appraisalcyclename ? data.appraisalcyclename : 'NA' }}
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{ data.moduletype }}
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{  data.managername ? data.managername ==null || data.managername =='' || data.managername ==' ' ?'NA': data.managername :'NA' }}
                                                                                </td>
                                                                                <td class="text-center capitalisetext">
                                                                                    {{  data.reviewersname }}
                                                                                </td>
                                                                                <td class="capitalisetext">
                                                                                    {{  data.status }}
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    {{dateMonthFormat(data.createddate)}}
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <span v-if="data.status == 'completed'">{{ data.lastmodifiedby }}</span>
                                                                                    <span v-else>NA</span>
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <span v-if="data.status == 'completed'">{{dateMonthFormat(data.lastmodifieddate)}}</span>
                                                                                    <span v-else>NA</span>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-if="appraisalformlist.length==0">
                                                                            <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pageCenter text-center">
                                            <pagination  v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Pagination from 'vue-pagination-2'
import { PlusCircleIcon,PlusSquareIcon,Edit2Icon,MoreVerticalIcon,EyeIcon,XIcon,ExternalLinkIcon,MonitorIcon } from "vue-feather-icons";
import { SearchIcon } from "vue-feather-icons";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import commonMethods from '../../utils/commonMethods';
export default {
    name:'ListAppraisalCycle',
    components:{
        Loading,
        VueElementLoading,
        Pagination,Edit2Icon,
        PlusCircleIcon,PlusSquareIcon,MoreVerticalIcon,EyeIcon,
        SearchIcon,XIcon,ExternalLinkIcon,MonitorIcon,
        Treeselect
    },
    mixins: [ commonMethods ],
    data(){
        return{
            card_content: "card-content",
            collapse: "collapse",
            isLoading: false,
            islistLoading:false,
            fullPage: true,
            listfullPage:true,
            isistLoading:false,
            totalcount: 0,
            pageCount: 0,
            page:1,
            totalcount1: 0,
            pageCount1: 0,
            page1: 1,
            pageoffset1:1,
            pagelimit:apiUrl.LIMIT,
            currPageNum: 0,
            pageoffset:1,
            appraisalformlist:[],
            isSearchRequestActive:false,
            dashboardisSearchRequestActive:true,
            search:{
                formname: null,
                empname: null,
                status: null,
                department: null,
                appraisalcyclename: null,
                department1: null,
                appraisalcyclename1: null
            },
            trackerlist:[],
            statuslist:[{
                id:'completed',
                label:'Completed'
            },{
                id:'pending',
                label:'Pending'
            }],
            loginuseremail: '',
            pagefeatures:[],
            employeenamelist: [],
            trackerdetails: [],
            colors: ['primary', 'secondary', 'success', 'danger', 'warning', 'info'],
            disabledexport: true,
            deparmentlist: [],
            appraisalcyclenamelist: []
        }
    },
    mounted(){
        let permission = this.$Service.verifyAccess();
        if(permission){
            var userdata = window.localStorage.getItem('userdata');
            this.tokendata = window.localStorage.getItem('token');
            if(userdata){
                userdata = JSON.parse(userdata)
                this.loginuseremail = userdata.emailaddress;
                this.undt=userdata
                localStorage.removeItem('searchItems')
                this.gettrackerlist();
                // this.getTrackerDetails();
                this.getEmpDepartList();
                this.getallappraisalcycle();

            }
        }
        $(".filter_rotation_onlick").click(function(){
        $(this).toggleClass("common_arrow_rotetion");
        });
    },
    methods:{
        mysearchrequestollapse:function(){
            this.isSearchRequestActive = !this.isSearchRequestActive
        },
        dashboardmysearchrequestollapse:function(){
            this.dashboardisSearchRequestActive = !this.dashboardisSearchRequestActive
        },
        selectTname(state){
            if(state.length==0){
                this.disabledexport = true;
            }
        },
        clickCallback: function (pageNum) {
            this.pageoffset = pageNum;
            this.page = pageNum;
            this.currPageNum = pageNum - 1;
            this.getformlist();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#apprisalformtable").offset().top -70
                });
            }, 500);
        },
        pageclickCallback: function(pageNum){
            this.pageoffset1 = pageNum;
            this.page1 = pageNum;
            this.getTrackerDetails();
            setTimeout(function(){
                $('html, body').animate({
                    scrollTop: $("#dashboardtable").offset().top -70
                });
            }, 500);
        },
        resetRecords: function() {
            this.search.appraisalcyclename = null;
            this.search.department = null;
            this.search.department1 = null,
            this.search.appraisalcyclename1 = null;
            this.search.formname = null;
            this.search.empname = null;
            this.search.status = null;
            localStorage.removeItem('searchItems')
            this.page=1
            this.appraisalformlist = [];
            this.totalcount = 0;
            this.dashboardisSearchRequestActive = true;
            this.isSearchRequestActive = false;
            this.getEmpDepartList();
        },
        getformlist(objThis,flag){
            if(objThis) {
                if((!this.search.formname || this.search.formname ==null) || this.search.formname.length==0) {
                    Swal.fire({
                    title: "Failed",
                    text: "Please select appraisal form name, to fetch the results.",
                    icon: "info",
                    customClass: { confirmButton: "btn btn-primary" },
                       buttonsStyling: !1,
                    });
                    this.appraisalformlist=[]
                    this.totalcount = 0;
                    this.pageCount =0;
                    this.disabledexport = true;
                    return;
                } else {
                    this.disabledexport = false;
                }
                localStorage.setItem('searchItems', JSON.stringify(objThis))
            }
            this.isLoading = true
            let search = JSON.parse(localStorage.getItem('searchItems'))
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                userid: this.search.allotedto,
                limit: apiUrl.LIMIT,
                type: 'Appraisal Cycle'
            }
            if(flag == 1){
                this.input.offset = 1;
                this.currPageNum = 0;
            }else{
                this.input.offset = this.pageoffset;
            }
            if(this.search.department1 && this.search.department1 !=null && !flag){
                this.search.department = this.search.department1;
                this.input.department = this.search.department;
            }else{
                this.input.department = this.search.department;
            }
            if(this.search.appraisalcyclename1 && this.search.appraisalcyclename1 !=null && !flag){
                this.search.appraisalcyclename = this.search.appraisalcyclename1;
            }
            if (search && ((this.search.formname && this.search.formname.length>0) || (this.search.empname && this.search.empname.length>0) || (this.search.status && this.search.status.length>0) || this.search.appraisalcyclename)) {
                this.input.formname = this.search.formname;
                this.input.status = this.search.status;
                this.input.empname = this.search.empname;
                this.input.appraisalcyclename = this.search.appraisalcyclename;
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getfeedbackformreport',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false             
                if(result.data.errorCode == 0){
                    this.appraisalformlist = result.data.data.rows;
                    this.totalcount = result.data.data.count;
                    this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.appraisalformlist=[]
                    this.totalcount = 0;
                    this.pageCount =0;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        gettrackerlist() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'Appraisal Cycle',
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getallforms',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.trackerlist = result.data.data.rows
                } else {
                    this.trackerlist = []
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        redirecttourl:function(btnstate,rowdata){
            rowdata.backbtnstate=btnstate;
            rowdata.moduletypes='Appraisal Cycle'
            if(btnstate == "create"){
                this.$router.push({ 
                name: 'CreateAppraisalCycle',
                path: `/appraisal/create?trackerid=${parseInt(rowdata.trackerid)}`,
                params: {
                    backbtnstate:btnstate,
                    clickeddata:rowdata
                }
                })
            } else if(btnstate == "view"){
                this.$router.push({
                    name: 'FeedbackViewForm',
                    path: '/form/formview?masterid='+parseInt(rowdata.id),
                    params: {
                        backbtnstate:btnstate,
                        clickeddata:rowdata
                    }
                })
            }
        },
        showmappingname(mappingname){
            let responsestr='';
            let prlist=mappingname.split(',')
            if(prlist.length > 1){
                for(var i=0;i<prlist.length;i++){
                    responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
                }
            }else{
                for(var i=0;i<prlist.length;i++){
                    responsestr +=prlist[i]
                }
            }
            return responsestr
        },
        dateMonthFormat(value) {
            if (!value) return "";
            return moment(String(value)).format('DD MMM YYYY HH:mm');
        },
        getallemplist(node){
            if (node && node.length>2) {
                this.isLoading = true;
                this.inputfield= {
                    empcode: parseInt(this.undt.userid),
                    useremail: this.undt.username,
                    empname: node.toLowerCase()
                }
                axios({
                    'method': 'POST',
                    'url': 'api/master/getemployeebyname',
                    'data': this.inputfield,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => {
                    this.isLoading = false;
                    if(result.data.status == true){
                        this.employeenamelist = result.data.data.rows;
                        let decryptdesignationtext = this.employeenamelist.map(items =>{

                            let tempemplabel1 = items.label;
                            let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;

                            if(tempemplabel2 && tempemplabel2 != null){
                            items.label = tempemplabel1 +'('+tempemplabel2 +')';
                            }
                            return items;
                        })
                    }else{
                        this.employeenamelist=[];
                    }
                }).catch(e => {
                this.displayError(e)
            });
            }
        },
        getTrackerDetails(search, flag) {
            this.isSearchRequestActive = false;
            this.dashboardisSearchRequestActive = true;
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                type: 'Appraisal Cycle',
                limit: apiUrl.LIMIT,
                department: this.search.department1,
                appraisalcyclename: this.search.appraisalcyclename1
            }
            if(flag == 1){
                this.input.offset = 1;
            }else{
                this.input.offset = this.pageoffset1;
            }
            axios({
                'method': 'POST',
                'url': 'api/feedbackform/getTrackerDetails',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.trackerdetails = result.data.data.rows;
                    this.totalcount1 = result.data.data.count;
                    this.pageCount1 = Math.ceil(this.totalcount1 / this.pagelimit);
                } else {
                    this.trackerdetails = [];
                    this.totalcount1 = 0;
                    this.pageCount1 = 0;
                }
            }).catch(e => {
                this.displayError(e)
            })
        },
        openListing(data, status) {
            this.dashboardisSearchRequestActive = false;
            this.isSearchRequestActive = true;
            this.search.formname = [data.id];
            if(status) {
                this.search.status = [status]
                if(status=='total') {
                    this.search.status = ['completed', 'pending'] 
                }
            }
            this.getformlist(this.search)
        },
        getEmpDepartList() {
            this.isLoading = true
            let url = 'api/formconfigure/getEmpDepartList'
            let payload = new Object({
                empid: this.undt.userid,
                useremail  : this.undt.username,
                empcode: this.undt.userid
            })
            axios({
                method: 'POST',
                url,
                data: payload,
                headers:{ authorization :this.tokendata }
            }).then(result => {
                this.isLoading = false
                if(result.data.errorCode == 0) {
                    this.deparmentlist = result.data.data.rows;
                    const decryptedDepartmentList = this.deparmentlist.map(item => {
                        item.label = this.decryptText(item.label);
                        return item;
                    });
                    this.deparmentlist.sort((a, b) => {
                        return a.label.localeCompare(b.label);
                    });
            
                    if(result.data && result.data.data){
                        if(result.data.data.orows && result.data.data.orows.length >0){
                            this.search.department = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
                            this.search.department1 = result.data.data.orows[0].deptid ? result.data.data.orows[0].deptid :null;
                        }
                    }
                    this.getTrackerDetails();
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                        title: "Session Expired...!!",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: !1,
                    }).then(function() {
                        window.location.href = "/#/login";
                    })
                } else {
                    this.deparmentlist=[];
                    this.search.department = null;
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getallappraisalcycle() {
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
            }
            axios({
                'method': 'POST',
                'url': 'api/master/getallappraisalcycle',
                'data': this.input,
                'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                if(result.data.errorCode == 0){
                    this.appraisalcyclenamelist = result.data.data.rows;
                    this.appraisalcyclenamelist.sort((a, b) => {
                    return a.label.localeCompare(b.label);
                    });
 
                } else {
                    this.appraisalcyclenamelist = [];
                }
            }).catch(e => {
                    this.displayError(e)
            });
        },
        getcolours(ind) {
            let colorind = ind % this.colors.length;
            return this.colors[colorind];
        },
        ExportXL() {
            this.isLoading = true;
            this.exportxlsdata = [];
            this.exporterr = "";
            this.input={
                useremail  : this.undt.username,
                empcode: this.undt.userid,
                userid: this.search.allotedto,
                type: 'Appraisal Cycle',
                limit: null,
                offset: null
            }
            let search = JSON.parse(localStorage.getItem('searchItems'))
            if (search && ((this.search.formname && this.search.formname.length>0) || (this.search.empname && this.search.empname.length>0) || (this.search.status && this.search.status.length>0) || this.search.appraisalcyclename)) {
                this.input.formname = this.search.formname;
                this.input.empname = this.search.empname;
                this.input.status = this.search.status;
                this.input.appraisalcyclename = this.search.appraisalcyclename;
            }
            this.input.department = this.search.department;
            axios({
                method: "POST",
                url: `api/feedbackform/getfeedbackformreportExport`,
                data: this.input,
                'headers':{'authorization':this.tokendata}
            }).then((result) => {
                this.isLoading = false;
                if (result.data.errorCode == 0) {
                    this.exportxlsdata = result.data.data.rows;
                    let exportDataRes = result.data.data.exportDataRes;
                    let exportDataQueRes = result.data.data.exportDataQueRes;
                    this.exportxlsdata.forEach((element, key) => {
                        exportDataRes.forEach(ele => {
                            if(ele.empid == element.empcode && 
                            ele.masterid == element.masterid && 
                            ele.trackerid == element.trackerid) {
                                if(ele.factor != null){
                                    this.exportxlsdata[key][ele.factor] = {
                                        weightage : ele.weightage,
                                        weightedaverage : ele.weightedaverage,
                                    }
                                }
                            }
                        });
                        exportDataQueRes.forEach(ele1 => {
                            if(ele1.empid == element.empcode && 
                            ele1.masterid == element.masterid && 
                            ele1.trackerid == element.trackerid) {
                                this.exportxlsdata[key][ele1.fieldname] = {
                                    fid : ele1.fieldid,
                                    que : ele1.fieldname,
                                    ans : ele1.fieldvalue,
                                }
                            }
                        });
                    });
                    var filename = "";
                    this.download(result.data.data.rows,filename)
                } else {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                }
            }).catch(e => {
                this.displayError(e)
            });
        },
        download(readExportdata,filename) {
            if (readExportdata.length > 0) {
            var ShowLabel = 'AppraisalFormReport'
            var JSONData = readExportdata
            var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
            var CSV = ''
                if (ShowLabel) {
                    let row = ''
                    for (let index in arrData[0]) {
                        row += index + ','
                    }
                    row = row.slice(0, -1)
                    CSV = 'Sr#, EMPLOYEE NAME, DESIGNATION, DEPARTMENT NAME, APPRAISAl CYCLE, FORM NAME, REPORTING MANAGER NAME, REVIEWERS NAME, STATUS, ASSIGNED DATE, FORM FILLED BY MAILID, SUBMITTED DATE';
                    if(arrData[0].fieldname != "") {
                        CSV += `',${arrData[0].fieldname}'`
                        CSV += `',${arrData[0].factorkeys}'`
                    }
                    CSV += ',GRADE';
                    CSV += ',RATING';
                    CSV += '\r\n';
                }
                var row = ''
                for (let i=0; i<arrData.length; i++) {
                let index = arrData[i]
                let srno = i+1
                let createddate = index.hasOwnProperty('createddate') ? index.createddate == null || index.createddate == 'NA' ? '' : index.createddate : '';
                let empname = index.hasOwnProperty('empname') ? index.empname == null || index.empname == 'NA' ? '' : index.empname : '';
                let empid = index.hasOwnProperty('empid') ? index.empid == null || index.empid == 'NA' ? '' : index.empid : '';
                // let designation = index.hasOwnProperty('designation') ? index.designation == null || index.designation == 'NA' ? '' : index.designation : '';
                let designation = index.designation ? this.decryptText(index.designation) : ''
                // let department = index.hasOwnProperty('department') ? index.department == null || index.department == 'NA' ? '' : index.department : '';
                let department = index.department ? this.decryptText(index.department) : ''
                let appraisalcyclename = index.hasOwnProperty('appraisalcyclename') ? index.appraisalcyclename == null || index.appraisalcyclename == 'NA' ? 'NA' : index.appraisalcyclename : 'NA';
                let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype == null || index.moduletype == 'NA' ? '' : index.moduletype : '';
                let managername = index.hasOwnProperty('managername') ? index.managername == null || index.managername == 'NA' ? 'NA' : index.managername : 'NA';
                let rating = index.hasOwnProperty('rating') ? index.rating == null || index.rating == 'NA' ? 'NA' : index.rating : 'NA';
                let grade = index.hasOwnProperty('grade') ? index.grade == null || index.grade == 'NA' ? 'NA' : index.grade : 'NA';
                let status = index.hasOwnProperty('status') ? index.status == null || index.status == 'NA' ? '' : index.status : '';
                let reviewersname = index.hasOwnProperty('reviewersname') ? index.reviewersname == null || index.reviewersname == 'NA' ? 'NA' : index.reviewersname : 'NA';
                let lastmodifiedby = 'NA';
                let lastmodifieddate = 'NA';
                if(status=='completed') {
                    lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby == null || index.lastmodifiedby == 'NA' ? 'NA' : index.lastmodifiedby : 'NA';
                    lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? index.lastmodifieddate == null || index.lastmodifieddate == 'NA' ? 'NA' : this.dateMonthFormat(index.lastmodifieddate) : 'NA';
                }

                row += '"' + srno + '",',
                row += '"' + empname + ' - ('+ empid +')",',
                row += '"' + designation + '",',
                row += '"' + department.toUpperCase() + '",',
                row += '"' + appraisalcyclename + '",',
                row += '"' + moduletype + '",',
                row += '"' + managername + '",',
                row += '"' + reviewersname + '",',
                row += '"' + status + '",',
                row += '"' + this.dateMonthFormat(createddate) + '",',            
                row += '"' + lastmodifiedby + '",',            
                row += '"' + lastmodifieddate + '",',      
                
                arrData[0].fieldname.split(',').forEach(element1 => {
                    if(Object.keys(index).includes(element1)){
                        row += index[element1].ans+','
                     } else {
                        row += 'NA,'
                     }
                });
                arrData[0].factorkeys.split(',').forEach(element => {
                    if(Object.keys(index).includes(element)){
                        //row += index[element].weightage+','
                        row += index[element].weightedaverage+','
                    } else {
                        row += 'NA,'
                    }
                });
                row += '"' + grade + '",',      
                row += '"' + rating + '",',      
                row += '\r\n'
                }
                CSV += row
                if (CSV === '') {
                Swal.fire({
                    title: "",
                    text: "Data not found",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
                return;
                }
                var fileName = 'AppraisalFormReport'+'_'+moment().format('DD_MM_YY_HH_mm_ss')
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
                var link = document.createElement('a')
                link.href = uri
                link.style = 'visibility:hidden'
                link.download = fileName + '.csv'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                Swal.fire({
                    title: "Success!",
                    text: "Export Report successful",
                    icon: "success",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                });
            } else {
                Swal.fire({
                title: "",
                text: "Data not found",
                icon: "info",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
                });   
            }
        },
        export (data, filename, mime) {
            var data
            let blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            })
            download(blob, filename, mime)
        }
    }
}
</script>