<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header-left mb-1 pt-0 ">
        <div class="container-fluid">
            <div class="row breadcrumbs-top">
                <div class="col-md-9 content-header-left px-0 ">
                    <h3 class="content-header-title float-left mb-0">BTC</h3>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item sitebluecolor cursorpointercls"><router-link
                                    to=''>Delay Dashboard</router-link></li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <section id="modern-horizontal-wizard">  
        <div class="btc_delay_Detail_04 px-0">
          <ul class="nav nav-pills  pt-0 mb-0" id="nav-tabs" role="tablist">
            <li class="nav-item" @click="toggleTab1('tab1')" :class="{ active: activeTab === 'tab1' }">
              <a class="nav-link">Delay</a>
            </li>
            <li  class="nav-item" @click="toggleTab2('tab2')" :class="{ active: activeTab === 'tab2' }">
              <a class="nav-link"> Edge Task </a>
            </li>
            <li  class="nav-item" @click="toggleTab3('tab3')" :class="{ active: activeTab === 'tab3' }">
              <a class="nav-link" > Pipeline </a>
            </li>
            <li  class="nav-item" @click="toggleTab4('tab4')" :class="{ active: activeTab === 'tab4' }">
              <a class="nav-link"  > UAT Signoffs </a>
            </li>
          </ul>
        </div>
        <div class="card rounded-0">
           <div class="containermain_btc_tab" v-if="activeTab === 'tab1'">
              <div class="card-header border-bottom py-1 px-2">
                 <div class="container-fluid">
                    <div class="row">
                         <div class="col-sm-auto px-0 m-side_form_head_580">
                          <h2 class="card-title"> Delay % </h2>
                         </div>
                         <div class="ml-auto d-flex">
                            <div class="btc_delay_title_text">
                               <span> BTC Status Considered- Tech WIP, QA WIP, UAT Feedback ,QA Pipeline, TechIssue Resolution</span>
                            </div>
                         </div>
                    </div>
                 </div>
               
              </div>
              <div class="card-body mobileview_dountchart_pd10 py-2">
                  <div class="row match-height ">
                    <div  v-if="pmochartdeatils && pmochartdeatils.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card mb-0">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>                                  
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">PMO</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata in pmochartdeatils">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata.programmanager }}</td>
                                    <td class="py-1"> {{ pmodaata.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.totalrecords }} </td>
                                  </tr>
                                </tbody>
                               </table>
                          </div>
                      </div>
                    </div>
                    <div  v-if="pmochartdeatils && pmochartdeatils.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                      <div class="btc_delay_dashboard_piechart " id="pmotab1">
                      <apexchart type="donut" :series="pmoseries"></apexchart>
                    </div>
                    </div>
                 
                    <div  v-if="techchartdeatils && techchartdeatils.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card mb-0">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">Tech</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata in techchartdeatils">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata.reportingto }}</td>
                                    <td class="py-1"> {{ pmodaata.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.totalrecords }} </td>
                                  </tr>
                                </tbody>
                                
                               </table>
                          </div>
                      </div>
                    </div>
                    <div  v-if="techchartdeatils && techchartdeatils.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                      <div class="btc_delay_dashboard_piechart " id="techtab1">
                      <apexchart type="donut" :series="techseries"></apexchart>
                    </div>
                    </div>

                    <div v-if="pmchartdeatils && pmchartdeatils.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">APM / PM</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata in pmchartdeatils">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata.projectmaanager }}</td>
                                    <td class="py-1"> {{ pmodaata.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.totalrecords }} </td>
                                  </tr>
                                </tbody>
                                
                               </table>
                          </div>
                      </div>
                    </div>
                    <div v-if="pmchartdeatils && pmchartdeatils.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-1"> 
                      <div class="btc_delay_dashboard_piechart">
                         <div id="pmtab1" :series="pmseries"></div>
                      </div>
                      </div>

                      <div v-if="qaleadchartdetails && qaleadchartdetails.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">QA Leads</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata in qaleadchartdetails">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata.qalead }}</td>
                                    <td class="py-1"> {{ pmodaata.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.totalrecords }} </td>
                                  </tr>
                                </tbody>
                                
                               </table>
                          </div>
                      </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-5 mb-1"> 
                        <div class="btc_delay_dashboard_piechart">
                            <div id="qatab1" :series="qaseries"></div>
                        </div>
                      </div>

                      <div v-if="emchartdeatils && emchartdeatils.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">Tech M/Leads</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata in emchartdeatils">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata.engineeringmaanager }}</td>
                                    <td class="py-1"> {{ pmodaata.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.totalrecords }} </td>
                                  </tr>
                                </tbody>
                                
                               </table>
                          </div>
                      </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-5 mb-1"> 
                      <div class="btc_delay_dashboard_piechart">
                         <div id="emtab1" :series="emseries"></div>
                      </div>
                      </div>                      
                  </div>
                  
              </div>
           </div>
           <div class="containermain_btc_tab" v-if="activeTab === 'tab2'">
            <div class="card-header border-bottom py-1 px-2">
                 <div class="container-fluid">
                    <div class="row">
                         <div class="col-sm-auto px-0 m-side_form_head_580">
                          <h2 class="card-title"> Edge Task % </h2>
                         </div>
                         <div class="ml-auto d-flex">
                            <div class="btc_delay_title_text">
                               <span> BTC Status Considered- UAT , UAT Feedback </span>
                            </div>
                         </div>
                    </div>
                 </div>
               
              </div>
              <div class="card-body mobileview_dountchart_pd10 py-2">
                <div class="row match-height ">
                    <div v-if="pmochartdeatils2 && pmochartdeatils2.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card mb-0">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">PMO</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata in pmochartdeatils2">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata.programmanager }}</td>
                                    <td class="py-1"> {{ pmodaata.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata.totalrecords }} </td>
                                  </tr>
                                </tbody>                                
                               </table>
                          </div>
                      </div>
                    </div>
                    <div v-if="pmochartdeatils2 && pmochartdeatils2.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                      <div class="btc_delay_dashboard_piechart" id="pmotab2">
                      <apexchart type="donut" :series="pmoseries2"></apexchart>
                    </div>
                    </div>                    
                  </div>                  
              </div>

              <div v-if="techchartdeatils2 && techchartdeatils2.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                <div class="card  btc_delay_dashboard_card mb-0">
                    <div class="table-responsive">
                      <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                        <thead>
                        <tr>
                            <th scope="col" class="align-middle py-1" style="min-width:200px;">Tech</th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                            <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                            <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                            <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                          </tr>
                          </thead>
                          <tbody v-for="pmodaata in techchartdeatils2">
                            <tr> 
                              <td class="py-1"> {{ pmodaata.reportingto }}</td>
                              <td class="py-1"> {{ pmodaata.zerotofiveaeging }} </td>
                              <td class="py-1"> {{ pmodaata.fivetotenaeging }} </td>
                              <td class="py-1"> {{ pmodaata.tentotwentyaeging }} </td>
                              <td class="py-1"> {{ pmodaata.twentytothirtyaeging }} </td>
                              <td class="py-1"> {{ pmodaata.thirtytoplusaeging }} </td>
                              <td class="py-1"> {{ pmodaata.totalrecords }} </td>
                            </tr>
                          </tbody>
                          
                          </table>
                    </div>
                </div>
              </div>
              <div v-if="techchartdeatils2 && techchartdeatils2.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                <div class="btc_delay_dashboard_piechart" id="techtab2">
                <apexchart type="donut" :series="techseries2"></apexchart>
              </div>
              </div>
           </div>
           <div class="containermain_btc_tab" v-if="activeTab === 'tab3'">
            <div class="card-header border-bottom py-1 px-2">
                 <div class="container-fluid">
                    <div class="row">
                         <div class="col-sm-auto px-0 m-side_form_head_580">
                          <h2 class="card-title"> Pipeline % </h2>
                         </div>
                         <div class="ml-auto d-flex">
                            <div class="btc_delay_title_text">
                               <span> BTC Status Considered - Tech Pipeline , Tech and Business Review </span>
                            </div>
                         </div>
                    </div>
                 </div>
               
              </div>
              <div class="card-body mobileview_dountchart_pd10 py-2">
                  <div class="row match-height ">
                    <div v-if="pmochartdeatils3 && pmochartdeatils3.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card mb-0">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">PMO</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata3 in pmochartdeatils3">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata3.programmanager }}</td>
                                    <td class="py-1"> {{ pmodaata3.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata3.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata3.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata3.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata3.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata3.totalrecords }} </td>
                                  </tr>
                                </tbody> 
                                
                               </table>
                          </div>
                      </div>
                    </div>
                    <div v-if="pmochartdeatils3 && pmochartdeatils3.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                      <div class="btc_delay_dashboard_piechart" id="pmotab3">
                      <apexchart type="donut" :series="pmoseries3"></apexchart>
                    </div>
                    </div>                   
                  </div>
                  
              </div>

              <div  v-if="techchartdeatils3 && techchartdeatils3.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                <div class="card  btc_delay_dashboard_card mb-0">
                    <div class="table-responsive">
                      <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                        <thead>
                        <tr>
                            <th scope="col" class="align-middle py-1" style="min-width:200px;">Tech</th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                            <th scope="col" class="alitechchartdeatils3gn-middle">  <span class="d-block"> 21-30  </span> Days </th>
                            <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                            <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                          </tr>
                          </thead>
                          <tbody v-for="techdaata3 in techchartdeatils3">
                            <tr> 
                              <td class="py-1"> {{ techdaata3.reportingto }}</td>
                              <td class="py-1"> {{ techdaata3.zerotofiveaeging }} </td>
                              <td class="py-1"> {{ techdaata3.fivetotenaeging }} </td>
                              <td class="py-1"> {{ techdaata3.tentotwentyaeging }} </td>
                              <td class="py-1"> {{ techdaata3.twentytothirtyaeging }} </td>
                              <td class="py-1"> {{ techdaata3.thirtytoplusaeging }} </td>
                              <td class="py-1"> {{ techdaata3.totalrecords }} </td>
                            </tr>
                          </tbody>
                          
                          </table>
                    </div>
                </div>
              </div>
              <div  v-if="techchartdeatils3 && techchartdeatils3.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                <div class="btc_delay_dashboard_piechart " id="techtab3">
                <apexchart type="donut" :series="pmoseries4"></apexchart>
              </div>
              </div>
           </div>
           <div class="containermain_btc_tab" v-if="activeTab === 'tab4'">
            <div class="card-header border-bottom py-1 px-2">
                 <div class="container-fluid">
                    <div class="row">
                         <div class="col-sm-auto px-0 m-side_form_head_580">
                          <h2 class="card-title"> UAT Signoff % </h2>
                         </div>
                         <div class="ml-auto d-flex">
                            <div class="btc_delay_title_text">
                               <span> BTC Status Considered -  UAT Signoff </span>
                            </div>
                         </div>
                    </div>
                 </div>
               
              </div>
              <div class="card-body mobileview_dountchart_pd10 py-2">
                  <div class="row match-height ">
                    <div v-if="pmochartdeatils4 && pmochartdeatils4.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                      <div class="card  btc_delay_dashboard_card mb-0">
                          <div class="table-responsive">
                            <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                              <thead>
                              <tr>
                                  <th scope="col" class="align-middle py-1" style="min-width:200px;">PMO</th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                                  <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                                  <th scope="col" class="align-middle">  <span class="d-block"> 21-30  </span> Days </th>
                                  <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                                 <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                                </tr>
                                </thead>
                                <tbody v-for="pmodaata4 in pmochartdeatils4">
                                  <tr> 
                                    <td class="py-1"> {{ pmodaata4.programmanager }}</td>
                                    <td class="py-1"> {{ pmodaata4.zerotofiveaeging }} </td>
                                    <td class="py-1"> {{ pmodaata4.fivetotenaeging }} </td>
                                    <td class="py-1"> {{ pmodaata4.tentotwentyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata4.twentytothirtyaeging }} </td>
                                    <td class="py-1"> {{ pmodaata4.thirtytoplusaeging }} </td>
                                    <td class="py-1"> {{ pmodaata4.totalrecords }} </td>
                                  </tr>
                                </tbody> 
                                
                               </table>
                          </div>
                      </div>
                    </div>
                    <div v-if="pmochartdeatils4 && pmochartdeatils4.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                      <div class="btc_delay_dashboard_piechart" id="pmotab4">
                      <apexchart type="donut" :series="pmoseries4"></apexchart>
                    </div>
                    </div>                   
                  </div>
                  
              </div>

              <div  v-if="techchartdeatils4 && techchartdeatils4.length>0" class="col-12 col-sm-12 col-md-6 col-lg-7 mb-2"> 
                <div class="card  btc_delay_dashboard_card mb-0">
                    <div class="table-responsive">
                      <table class="table table-sm dataTableListing table-hover table-striped table-hover all_table_stickys" aria-describedby="mydesc">
                        <thead>
                        <tr>
                            <th scope="col" class="align-middle py-1" style="min-width:200px;">Tech</th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 0-5  </span> Days </th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 6-10  </span> Days </th>
                            <th scope="col" class="text-nowrap align-middle"> <span class="d-block"> 11-20  </span> Days </th>
                            <th scope="col" class="alitechchartdeatils3gn-middle">  <span class="d-block"> 21-30  </span> Days </th>
                            <th scope="col" class="align-middle"> <span class="d-block"> 30+</span> Days </th>
                            <th scope="col" class="text-nowrap align-middle">Grand Total</th>
                          </tr>
                          </thead>
                          <tbody v-for="techdaata4 in techchartdeatils4">
                            <tr> 
                              <td class="py-1"> {{ techdaata4.reportingto }}</td>
                              <td class="py-1"> {{ techdaata4.zerotofiveaeging }} </td>
                              <td class="py-1"> {{ techdaata4.fivetotenaeging }} </td>
                              <td class="py-1"> {{ techdaata4.tentotwentyaeging }} </td>
                              <td class="py-1"> {{ techdaata4.twentytothirtyaeging }} </td>
                              <td class="py-1"> {{ techdaata4.thirtytoplusaeging }} </td>
                              <td class="py-1"> {{ techdaata4.totalrecords }} </td>
                            </tr>
                          </tbody>
                          
                          </table>
                    </div>
                </div>
              </div>
              <div v-if="techchartdeatils4 && techchartdeatils4.length>0" class="col-12 col-sm-12 col-md-6 col-lg-5 mb-2"> 
                <div class="btc_delay_dashboard_piechart" id="techtab4">
                <apexchart type="donut" :series="techseries4"></apexchart>
              </div>
              </div>
           </div>
          
        </div>
      </section>
    </div>
  </div>
</template>
<style>

</style>
<script>
  import axios from 'axios'
  import apiUrl from '../../constants';
  import moment from 'moment'
  import VueElementLoading from 'vue-element-loading';
  import vSelect from 'vue-select'
  import { EyeIcon, PlusCircleIcon} from 'vue-feather-icons'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import commonMethods from '../../utils/commonMethods';

  export default {
    name:'btcDelayDetails',
    mixins: [ commonMethods ],
    components:{
      Loading,
      apiUrl,
      VueElementLoading,PlusCircleIcon,
      vSelect,
      EyeIcon,
    },    
    data() {
      return {
        activeTab: 'tab1',
        isLoading: false,
        fullPage: true,
        companymasterlist: [],
        userrole: null,
        isRequestActive: true,
        isDetailsActive: false,
        card_content:'card-content',
        collapse:'collapse',
        pmochartdeatils: [],
        pmchartdeatils: [],
        qaleadchartdetails: [],
        emchartdeatils: [],
        techchartdeatils:[],
        isRequestActive2: true,
        isDetailsActive2: false,
        chartOptions:{
          series: [99, 55, 41],
        },
        series: [44, 55, 41],
        chartOptions1: {
        series: [44, 55, 41],
        },
        pmoseries: [],
        pmolables: [],
        emseries: [],
        emlables: [],
        techseries: [],
        techlables: [],
        pmseries: [],
        pmlables: [],
        qaseries: [],
        qalables: [],
        chartsvalue: ['0-5','6-10','11-20','21-30','30+'],
        pmochartdeatils2: [],
        pmoseries2: [],
        pmolables2: [],
        pmochartdeatils3: [],
        pmoseries3: [],
        pmolables3: [],
        techchartdeatils2: [],
        techseries2: [],
        techlables2: [],
        techchartdeatils3: [],
        techseries3: [],
        techlables3: [],
        pmochartdeatils4: [],
        pmoseries4: [],
        pmolables4: [],
        techchartdeatils4: [],
        techseries4: [],
        techlables4: [],
      }
    },
    mounted(){
      let userdata = window.localStorage.getItem('userdata');
        if(userdata){
            userdata = JSON.parse(userdata)
            this.undt=userdata
            this.tokendata = window.localStorage.getItem('token');
            this.userrole = window.localStorage.getItem("userrole")
            this.toggleTab1('tab1');   
            this.getDelayedTask('pmo');      
            this.getDelayedTask('pm');      
            this.getDelayedTask('tech');
        }
    },
    methods:{ 
      getDelayedTaskTab4(type){
        this.isLoading = true
          let url = "api/master/chart/getDelayedTask";
          this.input = {charttype: type, useremail: this.undt.username,empcode: this.undt.userid, status: ['UAT Signoff']};
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false
              if (result.data.errorCode == 0) {
                  if(type == 'pmo') {
                    this.pmochartdeatils4 = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.pmochartdeatils4.length>0) {
                      this.pmoseries4 = this.pmochartdeatils4.map(d => d.programmanager)
                      const total = this.pmochartdeatils4.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.pmochartdeatils4.length; i++) {
                        let per = (this.pmochartdeatils4[i].totalrecords / total) * 100;
                        this.pmochartdeatils4[i].percennt = per;
                      }
                      this.pmolables4 = this.pmochartdeatils4.map(d => Math.round(d.percennt));
                      //this.renderDonut4PMO(this.pmoseries4,this.pmolables4,'pmotab4', 'PMO');
                    }
                  } else if(type == 'tech') {
                    this.techchartdeatils4 = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.techchartdeatils4.length>0) {
                      this.techseries4 = this.techchartdeatils4.map(d => d.reportingto)
                      const total = this.techchartdeatils4.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.techchartdeatils4.length; i++) {
                        let per = (this.techchartdeatils4[i].totalrecords / total) * 100;
                        this.techchartdeatils4[i].percennt = per;
                      }
                      this.techlables4 = this.techchartdeatils4.map(d => Math.round(d.percennt))
                      //this.renderDonut4PMO(this.techseries4,this.techlables4,'techtab4', 'Tech');
                    }
                  }
              } else {
                  this.clearall()
              }
          }).catch(e => {
              this.displayError(e)
          });
      },
      getDelayedTaskTab3(type){
        this.isLoading = true
          let url = "api/master/chart/getDelayedTask";
          this.input = {charttype: type, useremail: this.undt.username,empcode: this.undt.userid, status: ['Tech Pipeline' , 'Tech and Business Review']};
          this.input.tabname = 'tab3'
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false
              if (result.data.errorCode == 0) {
                  if(type == 'pmo') {
                    this.pmochartdeatils3 = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.pmochartdeatils3.length>0) {
                      this.pmolables3 = this.pmochartdeatils3.map(d => d.programmanager)
                      const total = this.pmochartdeatils3.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.pmochartdeatils3.length; i++) {
                        let per = (this.pmochartdeatils3[i].totalrecords / total) * 100;
                        this.pmochartdeatils3[i].percennt = per;
                      }
                      this.pmoseries3 = this.pmochartdeatils3.map(d => Math.round(d.percennt))
                      //this.renderDonut4PMO(this.pmoseries3,this.pmolables3,'pmotab3', 'PMO');
                    }
                  } else if(type == 'tech') {
                    this.techchartdeatils3 = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.techchartdeatils3.length>0) {
                      this.techlables3 = this.techchartdeatils3.map(d => d.reportingto)
                      const total = this.techchartdeatils3.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.techchartdeatils3.length; i++) {
                        let per = (this.techchartdeatils3[i].totalrecords / total) * 100;
                        this.techchartdeatils3[i].percennt = per;
                      }
                      this.techseries3 = this.techchartdeatils3.map(d => Math.round(d.percennt))
                      //this.renderDonut4PMO(this.techseries3,this.techlables3,'techtab3', 'Tech');
                    }
                  }
              } else {
                  this.clearall()
              }
          }).catch(e => {
              this.displayError(e)
          });
      }, 
      getDelayedTaskTab2(type){
        this.isLoading = true
          let url = "api/master/chart/getDelayedTask";
          this.input = {charttype: type, useremail: this.undt.username,empcode: this.undt.userid, status: ['UAT', 'UAT Feedback']};
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false
              if (result.data.errorCode == 0) {
                  if(type == 'pmo') {
                    this.pmochartdeatils2 = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.pmochartdeatils2.length>0) {
                      this.pmolables2 = this.pmochartdeatils2.map(d => d.programmanager)
                      const total = this.pmochartdeatils2.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.pmochartdeatils2.length; i++) {
                        let per = (this.pmochartdeatils2[i].totalrecords / total) * 100;
                        this.pmochartdeatils2[i].percennt = per;
                      }
                      this.pmoseries2 = this.pmochartdeatils2.map(d => Math.round(d.percennt))
                      //this.renderDonut4PMO(this.pmoseries2,this.pmolables2,'pmotab2','PMO');
                    }
                  } else if(type == 'tech') {
                    this.techchartdeatils2 = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.techchartdeatils2.length>0) {
                      this.techlables2 = this.techchartdeatils2.map(d => d.reportingto)
                      const total = this.techchartdeatils2.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.techchartdeatils2.length; i++) {
                        let per = (this.techchartdeatils2[i].totalrecords / total) * 100;
                        this.techchartdeatils2[i].percennt = per;
                      }
                      this.techseries2 = this.techchartdeatils2.map(d => Math.round(d.percennt))
                      //this.renderDonut4PMO(this.techseries2,this.techlables2,'techtab2','Tech');
                    }
                  }
              } else {
                  this.clearall()
              }
          }).catch(e => {
              this.displayError(e)
          });
      }, 
      getDelayedTask(type){
        this.isLoading = true
          let url = "api/master/chart/getDelayedTask";
          this.input = {charttype: type, useremail: this.undt.username,empcode: this.undt.userid, status: ['Tech WIP', 'QA WIP', 'UAT Feedback' ,'QA Pipeline', 'TechIssue Resolution']};
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false
              if (result.data.errorCode == 0) {
                  if(type == 'pmo') {
                    this.pmochartdeatils = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.pmochartdeatils.length>0) {
                      this.pmolables = this.pmochartdeatils.map(d => d.programmanager)
                      const total = this.pmochartdeatils.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.pmochartdeatils.length; i++) {
                        let per = (this.pmochartdeatils[i].totalrecords / total) * 100;
                        this.pmochartdeatils[i].percennt = per;
                      }
                      this.pmoseries = this.pmochartdeatils.map(d => Math.round(d.percennt))
                      this.renderDonut4PMO(this.pmoseries,this.pmolables, 'pmotab1','PMO');
                    }
                  } else if(type == 'pm') {
                    this.pmchartdeatils = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.pmchartdeatils.length>0) {
                      this.pmlables = this.pmchartdeatils.map(d => d.projectmaanager)
                      const total = this.pmchartdeatils.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.pmchartdeatils.length; i++) {
                        let per = (this.pmchartdeatils[i].totalrecords / total) * 100;
                        this.pmchartdeatils[i].percennt = per;
                      }
                      this.pmseries = this.pmchartdeatils.map(( item ) => ({ name: item.projectmaanager, data: [item.zerotofiveaeging,  item.fivetotenaeging, item.tentotwentyaeging, item.twentytothirtyaeging, item.thirtytoplusaeging]}));
                      this.renderStackedChart(this.pmseries, this.pmlables, 'pmtab1', 'PM');
                    }
                  } else if(type == 'qa') {
                    this.qaleadchartdetails = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.qaleadchartdetails.length>0) {
                      this.qalables = this.qaleadchartdetails.map(d => d.qalead)
                      const total = this.qaleadchartdetails.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.qaleadchartdetails.length; i++) {
                        let per = (this.qaleadchartdetails[i].totalrecords / total) * 100;
                        this.qaleadchartdetails[i].percennt = per;
                      }
                      this.qaseries = this.qaleadchartdetails.map(( item ) => ({ name: item.qalead, data: [item.zerotofiveaeging,  item.fivetotenaeging, item.tentotwentyaeging, item.twentytothirtyaeging, item.thirtytoplusaeging]}));
                      this.renderStackedChart(this.qaseries, this.qalables, 'qatab1', 'QALead');
                    }
                  } else if(type == 'tech') {
                    this.techchartdeatils = result.data.data.rows.filter(d => d.totalrecords>0);
                      if(this.techchartdeatils.length>0) {
                      this.techlables = this.techchartdeatils.map(d => d.reportingto)
                      const total = this.techchartdeatils.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.techchartdeatils.length; i++) {
                        let per = (this.techchartdeatils[i].totalrecords / total) * 100;
                        this.techchartdeatils[i].percennt = per;
                      }
                      this.techseries = this.techchartdeatils.map(d => Math.round(d.percennt))
                      this.renderDonut4PMO(this.techseries,this.techlables,'techtab1', 'Tech');
                    }
                  } else if(type == 'em') {
                    this.emchartdeatils = result.data.data.rows.filter(d => d.totalrecords>0);
                    if(this.emchartdeatils.length>0) {
                      this.emlables = this.emchartdeatils.map(d => d.engineeringmaanager)
                      const total = this.emchartdeatils.map(item => item.totalrecords).reduce((accumulator, currentValue) => accumulator + currentValue);
                      for (let i = 0; i < this.emchartdeatils.length; i++) {
                        let per = (this.emchartdeatils[i].totalrecords / total) * 100;
                        this.emchartdeatils[i].percennt = per;
                      }
                      this.emseries = this.emchartdeatils.map(( item ) => ({ name: item.engineeringmaanager, data: [item.zerotofiveaeging,  item.fivetotenaeging, item.tentotwentyaeging, item.twentytothirtyaeging, item.thirtytoplusaeging]}));
                      this.renderStackedChart(this.emseries, this.emlables, 'emtab1', 'Tech M');
                    }
                  }
              } else {
                  this.clearall()
              }
          }).catch(e => {
              this.displayError(e)
          });
      },
      clearall() {
        this.pmochartdeatils = []
        this.qaleadchartdetails = []
        this.techchartdeatils = []
        this.emchartdeatils = []
        this.pmchartdeatils = []
        this.pmoseries = []
        this.pmolables = []
        this.pmlables = []
        this.pmseries = []
        this.techseries = []
        this.techlables = []
        this.emlables = []
        this.emseries = []
        this.qalables = []
        this.qaseries = []
        
        this.pmochartdeatils2 = []
        this.pmoseries2 = []
        this.pmolables2 = []
        this.techchartdeatils2 = []
        this.techseries2 = []
        this.techlables2 = []

        this.pmochartdeatils3 = []
        this.pmoseries3 = []
        this.pmolables3 = []
        this.techchartdeatils3 = []
        this.techseries3 = []
        this.techlables3 = []

        this.pmochartdeatils4 = []
        this.pmoseries4 = []
        this.pmolables4 = []
        this.techchartdeatils4 = []
        this.techseries4 = []
        this.techlables4 = []

      },
    toggleTab1(tab) {
      this.activeTab = tab;
      this.getDelayedTask('pmo');      
      this.getDelayedTask('pm');      
      this.getDelayedTask('qa');      
      this.getDelayedTask('em');      
      this.getDelayedTask('tech');  
    },
    toggleTab2(tab) {
      this.activeTab = tab;
      this.getDelayedTaskTab2('pmo'); 
      this.getDelayedTaskTab2('tech');        
    },
    toggleTab3(tab) {
      this.activeTab = tab;
      this.getDelayedTaskTab3('pmo'); 
      this.getDelayedTaskTab3('tech');        
    },
    toggleTab4(tab) {
      this.activeTab = tab;
      this.getDelayedTaskTab4('pmo'); 
      this.getDelayedTaskTab4('tech');        
    },
    renderDonut4PMO(series, lable, chartid, titlename) {
      let options = {
        series: series,
        chart: {
          type: 'donut',
          height: 550,  
          textTransform: 'lowercase',
        },
        labels: lable,
        colors:[         
          '#ADFF2F',
          '#FFD700',
          '#BA55D3',
          '#4682B4',
          '#8A2BE2',
          '#20B2AA',
          '#800000',
          '#ADFF2F',
          '#FFD700',
          '#DAA520',
          '#F74B60',
          '#7865D0',
          '#FEB12F',
          '#3DA0A9',
          '#FF7F50',
          '#008080',
          '#FF6347',
          '#40E0D0',
          '#7B68EE',
          '#87CEFA',
          '#FFD700',
          '#00FF7F',
          '#B22222',
          '#FF4500',
          '#32CD32',
          '#6495ED',
          '#32CD32',
          '#00FF7F',
          '#B22222',
          '#FF4500',
          '#32CD32',
          '#800000',
        ],
        title: {
          text: titlename,
          align: 'right',
          style: {
            fontSize: '14px',
          },
        },
        plotOptions: {
            pie: {
                donut: {
                  startAngle: 0,
                  endAngle: 360,
                    customScale: 0.8,
                    size: '65%', 
                },
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px', 
            align: 'left',
            style: {
                textTransform: 'lowercase', 
            },
            horizontalAlign: 'left',
            offsetX: 0
           
        },
        responsive: [
          {
            breakpoint: 360,
            options: {
                chart: {
                    width: '290',
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                title: {
                text: titlename,
                align: 'left',
                style: {
                  fontSize: '18px',
                },
              },
            },
            
        },
          {
            breakpoint: 460,
            options: {
                chart: {
                    width: '100%',
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                title: {
                text: titlename,
                align: 'left',
                style: {
                  fontSize: '14px',
                },
              },
            },
            
        },
        {
            breakpoint: 767,
            options: {
                chart: {
                    width: '65%',
                },
                legend: {
                    show: true,
                    position: 'bottom',
                },
                
            },
        },
        ],
      };

      let chart = new ApexCharts(document.querySelector("#"+chartid), options);
      if(chart) {
        chart.render();
        const legendMarkers = document.querySelectorAll('.apexcharts-legend-marker');
        legendMarkers.forEach(marker => {
            marker.style.borderRadius = '2px';
        });
        if (window.innerWidth <= 767) {
          const legendMarkers = document.querySelectorAll('.apexcharts-legend-marker');
          legendMarkers.forEach(marker => {
              marker.style.borderRadius = '2px';
          });
        }
      }
    },
    renderStackedChart(series, lables, chartid, titlename) {
      const fixedBarHeight = 40; 
      let options = {
        series: series,
        chart: {
          type: 'bar',
          height: 550,
          stacked: true,
        },
        colors: this.color,
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: fixedBarHeight,
            dataLabels: {
              color: '#ffffff',
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '22px',
                  fontWeight: 600,
                  color: '#fff',
                },
                fill: {
                  color: '#ffffff',
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: titlename
        },
        xaxis: {
          categories: this.chartsvalue,
          labels: {
            formatter: function (val) {
              return val 
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "K"
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetX: 0,
          height: 'auto', 
        }
      };

      let chart = new ApexCharts(document.querySelector("#"+chartid), options);
      if(chart) {
        chart.render();
        document.querySelectorAll('.apexcharts-bar-series .apexcharts-datalabel').forEach(label => {
          label.style.fill = '#fff'; 
        });  

      }
    },
    }, 
  }
</script>