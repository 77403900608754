<template>
<div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" data-scroll-to-active="true" style="margin-top:70px !important;">
    
    
    <div class="main-menu-content">
        <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
            <li v-bind:class="{'active':item.name+'-'+index ==currentindex,'nav-item':navFlag,'has-sub':item.children.length>0}"  v-bind:key="item.name" v-for="(item,index) in sidebarData" @click="getopenflag(item.name+'-'+index)" >
                <a v-if="item.children.length>0" href="#" :class="[{active:menuActive},menuclass1]" >
                    <em v-bind:class="[item.icon,las]" class="i_icon_st"></em>
                    <span class="menu-title text-truncate rolelistdd" data-i18n="">{{item.name}}</span>
                </a>
                <router-link v-if="item.children.length==0" v-bind:to="item.url" :class="[{active:menuActive},menuclass]" @click="deactivateSub()"  >
                   <em v-bind:class="[item.icon,las]" class="i_icon_st"></em>
                    <span class="menu-title text-truncate rolelistdd" data-i18n="">{{item.name}}</span>
                </router-link>
                <ul class="menu-content sub-menu" v-if="item.children" >
                    <li  v-bind:class="{'nav-item':navFlag,'':get_current_url=='#'+submenu.url}" v-bind:key="submenu.url" v-for="(submenu,i) in item.children" @click="activate(index+''+i)"   >
                        <router-link v-bind:to="submenu.url" class="d-flex align-items-center" >
                        <em data-feather="circle"></em>
                        <span class="menu-item text-truncate rolelistdd" data-i18n="">{{submenu.name}}</span></router-link>
                    </li>
                </ul>
            </li> 
        </ul>

    </div>
</div>    
</template>

<script>

import { FolderIcon,SettingsIcon,AlertOctagonIcon,BookOpenIcon,CpuIcon,UsersIcon,BoxIcon,PackageIcon,CheckCircleIcon,LifeBuoyIcon,HomeIcon,BriefcaseIcon,BookIcon,CodepenIcon,GridIcon,CalendarIcon,GlobeIcon,CreditCardIcon,CodesandboxIcon,LayersIcon, } from 'vue-feather-icons'
import apiUrl from '../constants';


export default({
    name:'VMenu',
    components: {
        HomeIcon,SettingsIcon,AlertOctagonIcon,BookOpenIcon,CpuIcon,UsersIcon,BoxIcon,CheckCircleIcon,LifeBuoyIcon,
        BriefcaseIcon,PackageIcon,
        BookIcon,
        CodepenIcon,
        GridIcon,
        CalendarIcon,
        CreditCardIcon,
        GlobeIcon,
        CodesandboxIcon,
        LayersIcon,
        FolderIcon
    },
    data(){
        return{
            las:'las lab',
            currentindex:0,
            c:0,
            openFlag:false,
            navFlag:true,
            open:'open',
            nav_item:'nav-item',
            sidebarData: [],
            menuclass1:'dropdown-toggle d-flex align-items-center',
            menuclass:'d-flex align-items-center',
            menuActive:false,
            get_current_url:window.location.hash.trim(),
            SBSystemID:apiUrl.SBSystemID
        }
    },
    mounted(){
        this.systemID = window.localStorage.getItem("systemid") ?JSON.parse(window.atob(window.localStorage.getItem("systemid"))):this.SBSystemID;
        if(window.localStorage.getItem('check')){
            if(this.systemID == this.SBSystemID){
                this.sidebarData = JSON.parse(window.atob(window.localStorage.getItem("sidebar")));
            }else if(this.systemID != this.SBSystemID){
                this.sidebarData = JSON.parse(window.atob(window.localStorage.getItem("sidebarnew")));
            }
        }
        //sidebarnew
        else{
            this.$router.push({
                name: "Login"
            });
        }
        
    },
    methods:{
        activatemenu(){
            this.menuActive=true
        },
        getopenflag(index){
            $('.main-menu.menu-light .navigation > li, .sub-menu li').removeClass('active');
            this.currentindex=index
        },
        activate(i){
             $('.sub-menu li').removeClass('active');
            this.c= i;
        }
        
        
        
    }
})
</script>
