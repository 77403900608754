<template>
  <div class="app-content content ">
   <loading
     :active.sync="isLoading"
     :can-cancel="false"
     :is-full-page="true"
     color="orange"
     loader="dots"
     :width="100"
     :height="100"
   ></loading>
   <div class="content-overlay"></div>
   <div class="header-navbar-shadow"></div>
   <div class="content-wrapper pb-1">
     <section id="card-navigation modern-horizontal-wizard">
       <div class="content-header-left mb-1 pt-0 pad_top_mar_bot-5">
         <div class="container-fluid">
           <div class="row breadcrumbs-top">
             <div class="col-md-9 content-header-left pl-0 ">
               <h3 class="content-header-title float-left mb-0">Status Configuration</h3>
               <div class="breadcrumb-wrapper">
                 <ol class="breadcrumb">
                  <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/statusconfig/list'>Status Configuration</router-link></li>
                 </ol>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card border-warning filter_card_searchbgst">
         <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
           <div class="container-fluid" >
             <div class="row" >
               <div class="w-auto px-1 mobile-padding-0-2 d-flex align-items-center">
                 <div class="avatar bg-light-secondary rounded d-inline-flex">
                   <div class="avatar-content">
                     <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                   </div>
                 </div>
               <h4 class="card-title d-inline-flex ml-1">Search Status Configuration Details</h4>
               </div>
               <div class="ml-auto d-flex">
               <div class="px-1 text-right mobile-padding-0-2" >
                 <div class="input-group-append float-right">
                   <router-link to="/statusconfig/create"><span class="btn btn-sm btn-relief-success" > <span class="mobile_hide_bt">Create Status Configuration </span> <plus-circle-icon size="1.5x"></plus-circle-icon> </span></router-link>
                 </div>
               </div>
               <span class="rotetions_view_arrow ml-auto mx-1"> 
               <em class="las la-angle-down"></em>
             </span>
             </div>
             </div>
           </div>
         </div>
         <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
           <div class="card-body pt-1 px-sm-mobile-10">
             <div class="row">
              
               <div class="col-sm-6 col-md-6 col-lg-3">
                 <label class="form-label lable-left">Tracker</label>
                 <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Tracker" v-model="search.tracker" :options="trackerlist" :clear-on-select="true" :multiple="true"/>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-3">
                 <label class="form-label lable-left"> Active/Inactive</label>
                 <treeselect class="projectmdllistdd capitalisetext" placeholder="Select Tracker" v-model="search.isactive" :options="publishlist" :clear-on-select="true" :multiple="true"/>
               </div>
               <div class="col-sm-6 col-md-6 col-lg-6 ">
                <button type="submit" class="btn btn-relief-primary mt-2 mr-1" @click="getAllStatusConfigData(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                 <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                 <button type="move" class="btn btn-relief-primary mt-2 mr-1" @click="ExportXL(search, true)"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div class="card border-warning" id="domain-list">
         <div class="card-body p-0 ps ps--active-x">
           <div class="table-responsive" >
             <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
               <thead>
                 <tr>
                   <th scope="col" class="text-center first-col-sticky" style="min-width:45px;padding-right: 5px;padding-left: 5px;">Sr. No</th>
                   <th scope="col" class="text-center second-col-sticky">Tracker</th>
                   <th scope="col" class="text-center">Status Option Values</th>
                   <th scope="col" class="text-center">Sequence</th>
                   <th scope="col" class="text-center">Created By</th>
                   <th scope="col" class="text-center">Created Date</th>
                   <th scope="col" class="text-center">IsActive</th>
                   <th scope="col" class="text-center">Action</th>
                 </tr>
               </thead>
               <tbody>
                   <template v-for="(data,index) in statusConfigList"  >
                   <tr v-if="!data.edit" v-bind:key="index" >
                     <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                   <td class="text-center second-col-sticky">{{ data.moduletype }}</td>
                   <td class="text-center ">{{ data.modulestatus }}</td>
                   <td class="text-center ">{{ data.sequence }}</td>
                    <td class="text-center ">{{ data.createdby }}</td>
                    <td class="text-center ">{{ dateMonthFormat(data.createddate) }}</td>
                     <td class="text-center text-capitalize"  v-if="data.isactive === 1">
                       <em class="las la-check-circle custom-class" style="font-size:22px;color: #28c76f" ></em>
                       
                     </td>
                     <td class="text-center text-capitalize" v-else>
                     <em class="las la-times-circle custom-class" style="font-size:22px;color: #color: #b82323" ></em>
                      
                    </td>                      
                     <td class="centeralign">
                       <span class="devEditEle edit_open_box_exam" @click="activateEditRow(data,index)" style="cursor:pointer;">
                         
                         <em class="las la-pen custom-class" style="font-size:22px;color:#474d4b"></em>
                       </span>
                     </td>
                   </tr>
                   <tr v-else v-bind:key="index">
                   <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>

                   <td class="text-center second-col-sticky">{{ data.moduletype }}</td>
                   <td class="box-inc ">
                      <input type="text" class="form-control" placeholder="status" v-model="data.modulestatus"/>
                      <div class="errorinputmsg" v-if="!data.modulestatus">status required</div>
                    </td>
                    <td class="box-inc ">
                      <input type="text" class="form-control" placeholder="sequence" v-model="data.sequence"/>
                      <div class="errorinputmsg" v-if="!data.sequence">Sequence required</div>
                    </td>
                   <td class="text-center">{{ data.createdby }}</td>
                   <td class="text-center">{{ dateMonthFormat(data.createddate) }}</td>
              
                   <td :class="{'isactive==': data.isactive}" class="toggle__button2">
                       <label :for="'isactive-'+index+'-'+data.id" :class="{'active': data.isactive}" class="toggle__button2">
                         <input type="checkbox" :id="'isactive-'+index+'-'+data.id" v-model="data.isactive">
                         <span class="toggle__switch"></span>
                         <span :class="{'off-lable-color': !data.isactive, 'on-label-color': data.isactive }"></span>
                       </label>
                   </td>
                   <td class="centeralign flex--items">
                     <button type="submit" class="btn btn-relief-primary mr-1" style="padding-left: 1rem; padding-right:1rem" v-on:click.stop.prevent="saveData(data)"><em class="las la-check-circle custom-class" style="font-size:18px" ></em></button>
                     <button type="submit" class="btn btn-relief-info" style="padding-left: 1rem; padding-right:1rem;" v-on:click.stop.prevent="cancelUpdate(index)"><em class="las la-times-circle custom-class" style="font-size:18px"></em></button>
                   </td>
                </tr>
                 </template>
                 <template v-if="statusConfigList.length==0">
                   <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                 </template>
               </tbody>
             </table>
           </div>
           <div class="pageCenter text-center mt-2">
             <pagination v-model="page" :records="totalcount" :per-page="pagelimit" @paginate="clickCallback"/>
           </div>
         </div>
       </div>
     </section>
   </div>
 </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Pagination from 'vue-pagination-2'
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon,MinusSquareIcon,PlusSquareIcon } from "vue-feather-icons";
import index from 'watch-size';
import commonMethods from '../../utils/commonMethods';

export default {
 name: 'FormConfigureList',
 components: {
   VueElementLoading,
   Loading,
   Treeselect,
   DatePicker,
   PlusCircleIcon,
   EditIcon,
   CheckCircleIcon,
   XCircleIcon,
   Pagination,
   SearchIcon,
   ExternalLinkIcon,
   XIcon,
   MinusSquareIcon,
   PlusSquareIcon, 
 },
 mixins: [ commonMethods ],
 data() {
   return {
     formconfig: {
      formconfiglist: [],
       prevformconfiglist: [],
     },
     fieldnamelist : [],
     trackerlist:[],
     staticValue: ["Businesstask"],
     search: {
        tracker:null,
        title:null,
        isactive:null,
        ispublish:null
     },
     isactive: true,
     isSearchBoxActive: false,
     isUpdateBoxActive: false,
     card_content:'card-content',
     collapse:'collapse',
     isLoading: false,
     undt: null,
     tokendata: null,
     totalcount: 0,
     pageCount: 0,
     page:1,
     pagelimit:apiUrl.LIMIT,
     currPageNum: 0,
     pageoffset:1,
     pagefeatures:[],
     TimeFormat: apiUrl.TimeFormat,
     purchase_date:null,
     expiry_date:null,
      publishlist: [
        { label: 'Active', id: '1' },
        { label: 'Inactive', id: '0' },
      ],
     arrayValue: [],
     statusConfigList: [],
     statusConfigUpdateList: [],
     updatelist: false,
     newedit: false,
     statuses:[]
   }
 },
 mounted() {
  let permission = this.$Service.verifyAccess();
  if(permission){
     let userdata = window.localStorage.getItem('userdata');
     if(userdata) {
       userdata = JSON.parse(userdata)
       this.undt = userdata
       this.tokendata = window.localStorage.getItem('token');
       this.getFieldNameList()
       this.getTrackerList()
        if(this.$route.params && this.$route.params.search){
          let search = JSON.parse(localStorage.getItem('statussearchItems'))
          if(search) {
            this.getSearchItems(search);
          }else{
            localStorage.removeItem('statussearchItems');
            this.getAllStatusConfigData();
          }
        }else{
          localStorage.removeItem('statussearchItems');
          this.getAllStatusConfigData();
        }
     }
   }
   $(".filter_rotation_onlick").click(function(){
     $(this).toggleClass("common_arrow_rotetion");
   });
 },
 methods: {
  addDocumentFields(index) {
    this.updatelist =true
     if(!this.modulestatuslist){
       this.modulestatuslist 
      //  = [{
      //       optionvalue: '',
      //        }]
    }else{

      this.modulestatuslist
    //   .push({
    //    optionvalue: null,
    //  })
    }

    },
    removeDocumentField(index, idx) {
   if(this.modulestatuslist && this.modulestatuslist.length>1) {
    this.modulestatuslist.splice();
      }
    },
  getTrackerList() {
      this.isLoading = true
      let url = 'api/formconfigure/geTrackerList'
      let payload = new Object({
        moduletype: null,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.trackerlist = result.data.data
          this.trackerlist.sort((a, b) => {
            return a.label.localeCompare(b.label);
          });
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.trackerlist=[]
        }
      }).catch(e => {
                this.displayError(e)
      })
    },
   validNumber : event => {
     let keyCode = event.keyCode;
       if (keyCode < 48 || keyCode > 57) {
         event.preventDefault();
       }
     }, updateArray() {
      this.arrayValue = this.inputValue.split(',');
    },
   notBeforeToday: function (date) {
     this.expiry_date = "";
     return date > new Date(new Date().setHours(0, 0, 0, 0));     
   },
   disabledBefore(date) {
     let dayBefore = moment(this.purchase_date).format(this.TimeFormat);
     const beforeToday = new Date(dayBefore);
           beforeToday.setHours(0, 0, 0, 0);
           console.log(beforeToday,'purchase_date');
     return date < beforeToday; 
   },
   showCommaSepName(){
        // this.modulestatuslist = prlists
        let responsestrs='';
        let prlists=modulestatuslist.split(',')
        if(prlists.length > 1){
            for(let i=0;i<prlists.length;i++){
                responsestrs +='<li>'+prlists[i]+'</li>'
            }
        }else{
            for(let i=0;i<prlists.length;i++){
                responsestrs +=prlists[i]
            }
        }
        return '<ul>'+responsestrs+'</ul>'
        }, 

        showmodulestatus(status){
          let responsestr='';
          let prlist=status.split(',')
          if(prlist.length > 1){
              for(var i=0;i<prlist.length;i++){
                  responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
              }
          }else{
              for(var i=0;i<prlist.length;i++){
                  responsestr +=prlist[i]
              }
          }
          return responsestr
      },   
   getAllStatusConfigData(search) {
    if(search) {
      localStorage.setItem('statussearchItems', JSON.stringify(search))
    }
     this.isLoading = true
     let url = 'api/statusdynamicconfig/statusconfiglist'
     let payload = new Object({
       limit: apiUrl.LIMIT,
       offset: this.pageoffset,
       useremail: this.undt.username,
       empcode: this.undt.userid,
       empid: parseInt(this.undt.userid),
     })
     if(search) {
     payload.tracker = search.tracker ? search.tracker : null
     payload.isactive = search.isactive ? search.isactive : null
   }
     axios({
       method: 'POST',
       url,
       data: payload,
       headers:{ authorization :this.tokendata }
     }).then(result => {
       this.isLoading = false
       if(result.data.errorCode == 0) {
        let d= result.data.data.rows
        this.statusConfigList = d.map(i =>{
          return {...i, edit:false}
        })
        this.statusConfigListRemove = d
         this.totalcount = result.data.data.count
         this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
       } else {
        this.statusConfigList = []
         this.totalcount = 0
         this.pageCount = 0
       }
     }).catch(e => {
                this.displayError(e)
      });
   },
   activateEditRow:function(data,idx){
     data.edit = true
     this.getConfiguredStatusList(data)
   },
   getConfiguredStatusList(data) {
      this.isLoading = true
      let url = 'api/statusdynamicconfig/configuredstatuslist'
      let payload = new Object({
        moduletype:data.moduletype,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.configuredstatuslist = result.data.data.rows

          for(let item of this.configuredstatuslist){
          let statusconfig = item.statusconfig
          for(let key in statusconfig){
            let cstatus = statusconfig[key]
            for (let singlestatuskey of cstatus){
              if(!this.statuses.includes(singlestatuskey)){
                this.statuses.push(singlestatuskey)
              }
            }
          }
        }
       // console.log(this.statuses,"new api=======statuses>")

        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.configuredstatuslist=[]
        }
      }).catch(e => {
                this.displayError(e)
      })
    },
   getFieldNameList() {
      this.isLoading = true
      let url = 'api/formconfigure/geFieldNameList'
      let payload = new Object({
        fieldname:this.search.fieldname,
        useremail  : this.undt.username,
        empcode: this.undt.userid
      })
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          this.fieldnamelist = result.data.data
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          this.fieldnamelist=[]
        }
      }).catch(e => {
                this.displayError(e)
      });
    },
   ExportXL(search,isExportedFlag) {
     this.islistLoading = true;
     this.exportxlsdata = [];
     this.exporterr = "";
     this.input = {
       limit:null,
       offset:null,
       useremail: this.undt.username,
       empcode: this.undt.userid,
       empid: parseInt(this.undt.userid),
      tracker :search.tracker ? search.tracker : null,
      isactive : search.isactive ? search.isactive : null,
     };
     axios({
       method: "POST",
       url: `api/statusdynamicconfig/statusconfiglist`,
       data: this.input,
       'headers':{'authorization':this.tokendata}
     }).then((result) => {
       this.islistLoading = false;
       if (result.data.errorCode == 0) {
         this.exportxlsdata = result.data.data.rows;
         var filename = "";
         this.download(result.data.data.rows,filename)
       }else if(result.data.errorCode == 3){
           Swal.fire({
                 title: "Session Expired...!!",
                 text: result.data.msg,
                 icon: 'info',
                 customClass: {
                     confirmButton: "btn btn-primary",
                 },
                 buttonsStyling: !1,
             }).then(function() {
               window.location.href = "/#/login";
           })
         } else {
         Swal.fire({
           title: "",
           text: "Data not found",
           icon: "info",
           customClass: {
           confirmButton: "btn btn-primary",
           },
           buttonsStyling: !1,
         });
       }        
     }).catch(e => {
                this.displayError(e)
      })
   },
   download(readExportdata) {
      if(readExportdata.length > 0) {
       let JSONData = readExportdata
       let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
       let row = ''
       let CSV = 'Sr#, Tracker, Status Option Values, Created By , Created Date, Lastmodified By, Lastmodified Date, IsActive' + '\r\n'
       for (let i=0; i<arrData.length; i++) {
         let index = arrData[i]
         let srno = i+1
         let moduletype = index.hasOwnProperty('moduletype') ? index.moduletype   : ''
         let modulestatus = index.hasOwnProperty('modulestatus') ? index.modulestatus : ''
         let isactive = index.hasOwnProperty('isactive') ? index.isactive : ''              
         let createddate = index.hasOwnProperty('createddate') ? this.dateMONFormat(index.createddate) : ''
         let createdby = index.hasOwnProperty('createdby') ? index.createdby : ''
         let lastmodifieddate = index.hasOwnProperty('lastmodifieddate') ? this.dateMONFormat(index.lastmodifieddate) : ''
         let lastmodifiedby = index.hasOwnProperty('lastmodifiedby') ? index.lastmodifiedby : ''
         row += '"' + srno + '",'
         row += '"' + moduletype + '",'
         row += '"' + modulestatus + '",'
         row += '"' + createdby + '",'
         row += '"' + createddate + '",'
         row += '"' + lastmodifiedby + '",'
         row += '"' + lastmodifieddate + '",'           
         row += '"' + isactive + '",'
         row += '\r\n'
       }
       CSV += row
       if (CSV === '') {
         Swal.fire({
           title: "",
           text: "Data not found",
           icon: "info",
           customClass: {
             confirmButton: "btn btn-primary",
           },
           buttonsStyling: !1,
         });
         return;
       }
       let fileName = 'status_config' + moment().format('DD_MM_YY_HH_mm_ss')
       let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
       let link = document.createElement('a')
       link.href = uri
       link.style = 'visibility:hidden'
       link.download = fileName + '.csv'
       document.body.appendChild(link)
       link.click()
       document.body.removeChild(link)
       Swal.fire({
         title: "Success!",
         text: "Status Configuration Exported Successfully",
         icon: "success",
         customClass: {
           confirmButton: "btn btn-primary",
         },
         buttonsStyling: !1,
       });
     } else {
       Swal.fire({
         title: "",
         text: "Data not found",
         icon: "info",
         customClass: {
           confirmButton: "btn btn-primary",
         },
         buttonsStyling: !1,
       });    
     }
   },
   dateMONFormat(value){
     if (!value) return "";
     return moment(String(value)).format('DD-MM-YYYY');
   },
   dateMonthFormat(value) {
     if (!value) return "NA";
     return moment(String(value)).format('DD MMM YYYY');
   },
   searchBoxCollapse() {
     this.isSearchBoxActive = !this.isSearchBoxActive
   },
   clearSearchFields() {
    this.search.tracker = null;
    this.search.isactive = null;
    this.search.ispublish = null;
    localStorage.removeItem('statussearchItems');
    this.getAllStatusConfigData();
   },
   clickCallback: function(pageNum) {
     this.pageoffset=pageNum
     this.currPageNum = pageNum - 1
     this.getAllStatusConfigData();
     setTimeout(function(){
       $('html, body').animate({
         scrollTop: $("#formconfig-list").offset().top -70
       });
     }, 500);
   },
   validateForm(data) {
     let valid = true
     if(!data.id || !data.fieldname) {
       valid = false
     }
     if(!data.trackeroption.length > 0) {
       valid = false
     }
     if((data.fieldtype=='radio' || data.fieldtype == 'checkbox' || data.fieldtype == 'drop-down' || data.fieldtype == 'multiselect-dropdown' )) {
          for(let i=0;i<data.fieldvalues.length;i++){
          if(!data.fieldvalues[i].optionvalue){
            valid = false
          }
          if(!data.fieldvalues[i].sequence){
            valid = false
          }
        }
        }  
     return valid
   },
   cancelUpdate(idx) {
     this.statusConfigList = this.statusConfigList.map((data, i) => {
       if(i === idx) {
         return { ...this.statusConfigListRemove[idx], edit: false }
       }
       return data
     })
   },
   saveData(data) {
    let mstatus = data.modulestatus
     if(this.statuses.includes(mstatus)) {
      Swal.fire({
             title: "Failed",
             text: "Please remove the current configuration status from Rolewise Status Configuration",
             icon: 'info',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
      }else{
       data.edit = false
       let apiurl = `api/statusdynamicconfig/update`
       this.isLoading = true
       axios({
         method: 'POST',
         url: apiurl,
         data: {
           id: data.id,
           modulestatus: data.modulestatus,
           isactive: data.isactive ? 1 : 0,
           lastmodifiedby: this.undt.username,
           useremail  : this.undt.username,
           empcode: this.undt.userid,
           sequence: data.sequence,
           trackerid:data.tid
          },
         headers: { authorization :this.tokendata }
       }).then(result => {
         this.isLoading = false
         if(result.data.errorCode == 0) {
           Swal.fire({
             title: "Success!",
             text: result.data.msg,
             icon: 'success',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
           this.getAllStatusConfigData(this.search);
         } else {
           Swal.fire({
             title: "Failed",
             text: result.data.msg,
             icon: 'info',
             customClass: {
               confirmButton: "btn btn-primary"
             },
             buttonsStyling: !1
           })
         }
       }).catch(e => {
                this.displayError(e)
      })
      }
   },
   getSearchItems(search) {
      this.isSearchBoxActive = true;
      this.search.tracker = search.tracker;
      this.search.isactive = search.isactive;
      this.getAllStatusConfigData(this.search);
    },
 }
}
</script>
<style scoped>
 .box-inc {
   min-width: 200px;
 }
.box-inc .projectlistdd.vue-treeselect.vue-treeselect--multi{
  z-index: 0;
}
</style>