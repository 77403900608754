<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pt-0">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left px-0">
                <h3 class="content-header-title float-left mb-0">Document Management</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls" >
                      <router-link :to="{ name: 'DocumentList', params:{ search: true }}"> Document Management List</router-link>
                    </li>
                    <li class="breadcrumb-item"><router-link to='/document/create'>Create Document</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-0">
            <form class="validate-form">
              <div class="row">
                <div class="col-12 ">
                  <div class="card-body">
                    <form>
                      <div class="row">
                        <div class="form-group col-sm-6  col-md-4">
                          <label class="form-label">Document Group Name </label>
                          <input class="form-control" v-model="document.docgroupname" type="text" placeholder="Document Group Name" />
                          <div class="errorinputmsg" v-if="!document.docgroupname">Document group name is required</div>
                        </div>
                        <div class="mb-0 mt-2 text-right px-1 px-md-0 mobile_margin-px-top0" >
                          <div class="btn btn-relief-primary mr-0" @click="addDocumentFields()"><span><plus-square-icon size="1x"></plus-square-icon> Add Document</span></div>
                        </div>
                      </div>
                      <div class="row" v-for="(singleDocField, idx) in document.fields" :key="`phoneInput-${idx}`" style="border-bottom: 0.5px solid rgba(221, 215, 215, 0.51); margin-top: 12px">
                        <div class="form-group col-sm-6 col-md-6 col-lg-4" >
                            <label class="form-label">Document Title </label>
                            <input class="form-control" type="text" placeholder="Enter Document Title" v-model="singleDocField.title"/>
                            <div class="errorinputmsg" v-if="!singleDocField.title">Document title is required</div>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-4">
                            <label class="form-label">Link</label>
                            <input class="form-control" type="text" placeholder="Enter Link" v-model="singleDocField.link"/>
                            <div class="errorinputmsg" v-if="!singleDocField.link">Link is required</div>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-4">
                          <label class="form-label lable-left">Subsribers</label>
                          <treeselect class="projectmdllistdd text-capitalize" placeholder="Enter min 3 chars to search Employee Name" v-model="singleDocField.subscribers" :options="search.emplist" :multiple="true" :clear-on-select="true" v-on:search-change="empNameChange"/>
                          <div class="errorinputmsg" v-if="!singleDocField.subscribers.length">Document subscribers is required</div>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-4">
                            <label class="form-label">Keywords</label>
                            <div class='tag-input'>
                              <div v-for='(tag, index) in singleDocField.keywords' :key='tag' class='tag-input__tag'>
                                {{ tag }}
                                <span @click='removeTag(singleDocField.keywords, index)'>x</span>
                              </div>
                              <input
                                type='text'
                                placeholder="Enter a Keyword"
                                class='tag-input__text'
                                :id="idx"
                                @keydown.enter='addTag'
                                @keydown.188='addTag'
                                @keydown.delete='removeLastTag'
                              />
                            </div>
                           
                            <div class="errorinputmsg" v-if="!singleDocField.keywords.length">Document keywords is required</div>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-4">
                            <label class="form-label">Description</label>
                            <textarea class="form-control"  rows="1" placeholder="Enter Description" v-model="singleDocField.desc"></textarea>
                            <div class="errorinputmsg" v-if="!singleDocField.desc">Document description is required</div>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-4" style="margin-top:25px">
                            <label :for="'attachment'+idx" class="btn btn-relief-success mb-0">Browse</label>
                            <input  type="file" :id="'attachment'+idx" hidden  ref="file" @change="onSelectDoc($event, idx) "/>
                            <button class="btn btn-relief-info  ml-1 uploadbutton" :data-id="idx" v-on:click.stop.prevent="onUpload($event, idx, singleDocField)" :disabled="!singleDocField.attachment"> {{singleDocField.isuploaded ? 'Uploaded' : 'Upload'}}</button>
                            <div class="avatar btn-relief-danger rounded ml-1">
                                <div class="avatar-content" @click="removeDocumentField(idx, document.fields, document.selectedFiles)">
                                    <minus-square-icon size="1.5x" class="custom-class avatar-icon font-medium-3" style="height:3"></minus-square-icon>
                                </div>
                            </div>
                            <div class="errorinputmsg" v-if="!singleDocField.attachment">Attachment is required</div>
                        </div>
                        <div style="margin:8px auto; font-weight: 600">{{singleDocField.attachment}}</div>
                      </div>
                      <div class="row">
                        <div class="col-sm-8">
                          <div class="form-group mt-1">
                          <div class="note_document_creates"> <strong> Note: </strong> <span> You can upload docx, xls, xlsx, png, jpeg, jpg, pdf, odg, txt, mp4 type files. The maximum individual file size allowed is 25MB.</span></div>
                          </div>
                      </div>
                      <div class="form-group  mt-1 mb-0 ml-auto px-1" >
                        
                        <button type="button" class="btn btn-relief-primary mr-1" id="submitbtnt9" data-dismiss="modal" :disabled="checkIfAllDocsUploaded() || !document.docgroupname" v-on:click.stop.prevent="saveData()">Submit</button>
                        <button type="reset" class="btn btn-outline-secondary" @click="handleCancel()">Cancel</button>
                      </div>
                      
                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import VueElementLoading from 'vue-element-loading';
import Treeselect from '@riophae/vue-treeselect'
import Loading from 'vue-loading-overlay';
import DatePicker from "vue2-datepicker";
import 'vue-loading-overlay/dist/vue-loading.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import "vue2-datepicker/index.css";
import { PlusSquareIcon, MinusSquareIcon, PlusIcon, StarIcon, MinusIcon } from 'vue-feather-icons'
import moment from "moment";
import apiUrl from "../../constants";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'DocumentCreate',
  mixins: [ commonMethods ],

  components: {
    VueElementLoading,
    Treeselect,
    DatePicker,
    Loading,
    PlusIcon,
    MinusIcon,
    StarIcon,
    PlusSquareIcon,
    MinusSquareIcon
  },
  data() {
    return {
      document: {
        selectedFiles: [],
        fields: [{
          title: '',
          desc: '',
          link: '',
          attachment: '',
          filename: '',
          subscribers: [],
          keywords: [],
        }],
        docgroupname: null
      },
      errors: {
        document: {
        }
      },
      search: {
        emplist: []
      },
      isLoading: false,
      card_content:'card-content',
      collapse:'collapse',
      tab_pane:'tab-pane', 
      fade:'fade',
      TimeFormat: apiUrl.TimeFormat,
    }
  },
  mounted() {
    // let permission = this.$Service.verifyAccess();
    // if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
      }
    // }
  },
  methods: {
    addTag(event) {
      event.preventDefault()
      let currTags = this.document.fields[parseInt(event.target.id)]
      let val = event.target.value.trim()
      if (val.length > 0) {
        if (this.document.fields[parseInt(event.target.id)].keywords.length >= 1) {
          for (let i = 0; i < this.document.fields[parseInt(event.target.id)].keywords.length; i++) {
            if (this.document.fields[parseInt(event.target.id)].keywords[i] === val) {
              return false
            }
          }
        }
        this.document.fields[parseInt(event.target.id)].keywords.push(val)
        event.target.value = ''
        console.log(this.document.fields[parseInt(event.target.id)].keywords)
      }
    },
    removeTag(tags, index) {
      tags.splice(index, 1)
      console.log(this.document.fields)
    },
    removeLastTag(event) {
      let currTags = this.document.fields[parseInt(event.target.id)].keywords
      if (event.target.value.length === 0) {
        this.removeTag(currTags, currTags.length - 1)
      }
      console.log(this.document.fields)
    },
    empNameChange: function (node, instanceId){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/manageemp/getAllEmplist";
        this.input = {
          empname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: parseInt(this.undt.userid),
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            let decryptdepartmenttext = result.data.data.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
                })
            this.search.emplist = result.data.data;
          } else {
            this.search.emplist = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    addDocumentFields() {
      this.document.fields.push({
        title: '',
        desc: '',
        link: '',
        attachment: '',
        filename: '',
        subscribers: [],
        keywords: [],
        isuploaded: false
      })
    },
    onSelectDoc(event, idx){
      let currFile = event.target.files[0]
      if (currFile.size > 1024 * 1024 * 25) {
        Swal.fire({
          title: "Failed",
          text: "Document of size above 25MB is not allowed",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        })
        return
      }
      let currSelectedFilesLength = this.document.selectedFiles.length
      if(currSelectedFilesLength) {
        this.document.selectedFiles[idx] = currFile
      } else {
        let curLen = this.document.fields.length
        let arr = []
        if(curLen) {
          for(let i=0; i<curLen; i++) {
            arr.push('')
          }
          this.document.selectedFiles = arr
          this.document.selectedFiles[idx] = currFile
        } else {
          this.document.selectedFiles.push(currFile);
        }
      }
      this.document.fields[idx].attachment = currFile.name
    },
    removeDocumentField(idx, fields, selectedFiles) {
      if(fields && fields.length>1) {
        fields.splice(idx,1);
        selectedFiles.splice(idx, 1)
      }
    },
    checkIfAllDocsUploaded() {
      let data = this.document.fields
      if(!this.document.fields.length) {
        return true
      }
      let f = data.every(i => {
        return i.attachment && i.title && i.desc && i.link && i.isuploaded && i.subscribers.length && i.keywords.length
      })
      return !f
    },
    restForm() {
      this.document.docgroupname = null
      this.document.selectedFiles = []
      this.document.fields = []
    },
    async onUpload(event, index, file) {
      this.apiURL = "api/s3upload/uploadDocumentToS3";
      let currFile = this.document.selectedFiles[index]
      if(currFile && currFile.name) {
        this.isLoading=true
        let formData = new FormData();
        formData.append("imagefile", currFile);
        formData.append("empcode", this.undt.userid);
        formData.append("useremail", this.undt.username);
        formData.append("moduletype", 'documentcontrol')
        formData.imagefile = file;
        formData.empcode = this.undt.userid;
        formData.moduletype = 'documentcontrol';
        axios({
            "content-type": "multipart/form-data",
            method: "POST",
            url: this.apiURL,
            data: formData,
            'headers':{'authorization':this.tokendata}
        }).then((result) => {
            this.isLoading=false
            if (result.data.status) {
              this.document.fields[index].isuploaded = true
              this.document.fields[index].filename = result.data.data.filename
              Swal.fire({
                title: "Success!",
                text: result.data.msg,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
              });
            } else {
              Swal.fire({
                  title: "Failed",
                  text: result.data.msg,
                  icon: "info",
                  customClass: {
                  confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              })
            }
        }).catch(e => {
          this.displayError(e)
        });
      } else {
        Swal.fire({
            title: "Failed",
            text: "Select document",
            icon: "info",
            customClass: {
            confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
        })
      }            
    },
    handleCancel() {
      this.$router.push({ name: 'DocumentList', params:{ search : true }});
    },
    saveData() {
      // this.isLoading = true
      let url = 'api/document/create'
      let payload = {
        createdby: this.undt.username,
        useremail  : this.undt.username,
        empcode: this.undt.userid,
        documentgroupname: this.document.docgroupname,
        documentlist: this.document.fields
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers: { authorization :this.tokendata }
      })
      .then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          Swal.fire({
            title: "Success!",
            text: result.data.msg,
            icon: 'success',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
          // this.restForm()
          this.$router.push({ name: 'DocumentList', params:{ search : true }});
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
          }).then(function() {
            window.location.href = "/#/login";
          })
        } else {
          Swal.fire({
            title: "Failed",
            text: result.data.msg,
            icon: 'info',
            customClass: {
              confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
          })
        }
      })
      .catch(e => {
        this.displayError(e)
        this.isLoading = false
        Swal.fire({
          title: "Failed",
          text: e,
          icon: 'info',
          customClass: {
            confirmButton: "btn btn-primary"
          },
          buttonsStyling: !1
      })
      })
    }
  },
}
</script>