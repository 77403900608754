<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-2 pt-1">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0">
                <h3 class="float-left mb-0"><span class="text-capitalize">{{this.moduletype ? this.moduletype : 'Module' }}</span> Code: {{this.searchTerm}}</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" id="performanceList">
          <div class="card-body p-0">
            <div class="table-responsive" >
              <table class="table dataTableListing table-hover" aria-describedby="mydesc">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">{{moduletype ? moduletype : 'Module' }} Code</th>
                    <th scope="col" class="text-center">{{moduletype ? moduletype : 'Module' }} Name</th>

                    <th scope="col" class="text-center" v-if="moduletype !== 'training' && moduletype !== 'cycleset'">Project Name</th>

                    <th scope="col" class="text-center" v-if="moduletype === 'task' || moduletype === 'issue'">Assigned To</th>
                    
                    <th scope="col" class="text-center" v-if="moduletype !== 'training'  && moduletype !== 'task' && moduletype !== 'cycleset'">Priority</th>

                    <th scope="col" class="text-center" v-if="moduletype !== 'training'">Status</th>

                    <th scope="col" class="text-center" v-if="moduletype !== 'training' && moduletype !== 'cycleset'">Type</th>
                    <th scope="col" class="text-center">Created By</th>
                    <th scope="col" class="text-center">Created Date</th>
                    <th scope="col" class="text-center">Lastmodified By</th>
                    <th scope="col" class="text-center">Lastmodified Date</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(data,index) in searchresults"  >
                  <tr v-bind:key="index" >
                    <td class="text-center text-capitalize" v-if="moduletype !== 'cycleset'">{{data.searchcode}}</td>
                    <td class="text-center text-capitalize" v-else-if="moduletype == 'cycleset'">{{data.cyclesetcode}}</td>

                    <td class="text-center text-capitalize font-weight-bold cursorpointercls sitebluecolor" @click="redirectToViewDetails(data.id,data.moduletype)" v-if="moduletype !== 'cycleset'">{{data.searchname}}</td>

                    <td class="text-center text-capitalize font-weight-bold cursorpointercls sitebluecolor" @click="redirectToViewDetails(data.cyclesetid,'cycleset')" v-else-if="moduletype === 'cycleset'">{{data.cyclesetname}}</td>

                    <td class="text-center text-capitalize" v-if="moduletype !== 'training' && moduletype !== 'cycleset'">{{data.searchproject}}</td>

                    <td class="text-center text-capitalize" v-if="moduletype === 'task' || moduletype === 'issue'">{{data.searchassignedto}}</td>

                    <td class="text-center text-capitalize" v-if="moduletype !== 'training' && moduletype !== 'task' && moduletype !== 'cycleset'">{{data.searchpriority ? data.searchpriority : 'NA'}}</td>

                    <td class="text-center text-capitalize" v-if="moduletype !== 'training' && moduletype !== 'cycleset'">{{data.searchstatus ? data.searchstatus : 'NA'}}</td>

                    <td class="text-center text-capitalize" v-if="moduletype === 'cycleset'">{{data.status ? data.status : 'NA'}}</td>

                    <td class="text-center text-capitalize" v-if="moduletype !== 'training' && moduletype !== 'cycleset'">{{data.searchtype ? data.searchtype : 'NA'}}</td>

                    <td class="text-center text-capitalize">{{formatName(data.createdby)}}</td>
                    <td class="text-center text-capitalize">{{dateMonthFormat(data.createddate)}}</td>
                    <td class="text-center text-capitalize">{{formatName(data.lastmodifiedby)}}</td>
                    <td class="text-center text-capitalize">{{dateMonthFormat(data.lastmodifieddate)}}</td>
                  </tr>
                  </template>
                  <template v-if="searchresults.length==0">
                    <tr><td colspan="14" style="text-align:center">No record found</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import commonMethods from '../utils/commonMethods';

import 'vue-loading-overlay/dist/vue-loading.css'
import { EventBus } from '../main'
import apiUrl from "../constants";

export default {
  name: 'SearchDetails',
  mixins: [ commonMethods ],

  components: {
    VueElementLoading,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      undt: null,
      tokendata: null,
      pagefeatures:[],
      searchTerm: null,
      componentKey: 0,
      searchresults: [],
      moduletype: null,
      companycode: apiUrl.companycode,
    }
  },
  created() {
  },
  mounted() {
    let userdata = window.localStorage.getItem('userdata');
    if(userdata) {
      userdata = JSON.parse(userdata)
      this.undt = userdata
      this.tokendata = window.localStorage.getItem('token');
      this.searchTerm = this.$route.query.code
      this.getSearchResults(this.searchTerm)
      try {
        if(typeof EventBus !== 'undefined') {
          EventBus.$on('search', searchQ => {
            this.searchTerm = searchQ
            this.getSearchResults(this.searchTerm)
          })
        }
      } catch(e) {
        this.$router.push('/dashboard')
      }
    }
  },
  methods: {
    redirectToViewDetails(id,ticketModule) {
      if (ticketModule) {
        this.moduletype = ticketModule
      }
      let moduletypes = ['task', 'issue', 'incident', 'testcase', 'businesstask', 'training','ticket','cycleset']
      let urlPath = ['tasks', 'issues', 'incident', 'testcasetask', 'businesstask', 'training','ticket','cycleset']
      let queryParam = ['taskid', 'issueid', 'incidentid', 'testcaseid', 'businessid', 'courseid','ticketid','cyclesetid']

      if(this.companycode=='xb' && ticketModule=='ticket'){
        this.$router.push({
          path: `/dynamic_tickets/tcview?ticketid=${id}`
        })
      }
      else if(moduletypes.includes(this.moduletype)) {
        let p = moduletypes.filter(item => item === this.moduletype)
        let idx = moduletypes.indexOf(p[0])
        if(ticketModule =='cycleset'){
          this.$router.push({
            path: `/testexecution/testcycleview?cyclesetid=${id}`
          })
        }else{
          this.$router.push({
            path: `/${urlPath[idx]}/view?${queryParam[idx]}=${id}`
          })
        }
      }
    },
    formatName(authorstring) {
      if(!authorstring) return 'NA'
      let author=authorstring.replace('@firstcry.com','')
      if(author.includes('@firstcry.in')) author=author.replace('@firstcry.in','')
      author = author.replace('.',' ')
      author = author.charAt(0).toUpperCase() + author.slice(1);
      return author
    },
    dateMonthFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD MMM YYYY HH:mm');
    },
    findModuleType: (code) => {
      let prefix = code.split('-')[0]
      let moduletype, url, field
      switch(prefix) {
        case 'BTC':
          moduletype = 'businesstask'
          url = 'api/master/businesstask/getBusinessProjectList'
          field = 'businesstaskcode'
          break
        case 'TK':
          moduletype = 'task'
          url = 'api/master/taskmaster/getTaskMasterlist'
          field = 'taskcode'
          break
        case 'IC':
          moduletype = 'incident'
          url = 'api/incidents/list'
          field = 'incidentcode'
          break
        case 'TC':
          moduletype = 'testcase'
          url = 'api/listing/gettestcaselist'
          field = 'testcasecode'
          break
        case 'IS':
          moduletype = 'issue'
          url = 'api/issue/getissuelist'
          field = 'issuecode'
          break
        case 'ITC':
          moduletype = 'training'
          url = 'api/coursemaster/list'
          field = 'code'
          break
        case 'ITC':
          moduletype = 'training-article'
          url = 'api/s3upload/getallDocumentPresignedUrl'
          field = 'trainingcode'
          break
        case 'CC':
          moduletype = 'cycleset'
          url = 'api/testcycle/gettestcyclelisting'
          field = 'cyclesesetcode'
          break
      }
      return { moduletype, url, field }
    },
    verifyModuleCode(code) {
      if(!this.searchTerm) return
      let regex = new RegExp(/^(BTC|TK|IC|TC|IS|ITC|CC)-\d{11,20}$/gi)
      let r = regex.test(code)
      return r
    },
    getSearchResults(code) {
      let { moduletype, url, field } = this.findModuleType(this.searchTerm)
      this.moduletype = moduletype
      if(!this.verifyModuleCode(this.searchTerm)) {
        this.searchresults = []
        return
      }
      let payload = new Object({
        useremail: this.undt.username,
        empid: this.undt.userid,
        empcode: this.undt.userid
      })
      payload[field] = code    
      this.isLoading = true
      if(code.split('-')[0] == 'BTC') {
        payload['globalsearchflag'] = 'true';    
      }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        if(result.data.errorCode == 0) {
          let d = result.data.data.rows
          this.searchresults = d
          // this.totalcount = result.data.data.count
          // this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else {
          this.searchresults = []
          // this.totalcount = 0
          // this.pageCount = 0
        }
      }).catch(e => {
      this.displayError(e)
      })
      }
  }
}
</script>
<style scoped>
  /* keep this styling scoped */
  .table th, .table td {
    padding: 0.6rem 0.3rem !important;
  }
</style>