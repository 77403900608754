<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="modern-horizontal-wizard">
        <div class="row breadcrumbs-top">
          <div class="col-12 new_dash_mar_top10">
            <h3 class="content-header-title float-left mb-1">Role And Tracker Wise Status Configuration</h3>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item sitebluecolor cursorpointercls">Add Configuration</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card " >
          <div v-bind:class="[{show: isRequestActive},card_content,collapse]" >
              <div class="card-header indent_border_bottom border-bottom py-1 px-2 py-2">
                  <h3 class="card-title ">
                      Add Role And Tracker Wise Status Configuration
                  </h3>
              </div>
            <div class="card-body pt-1">
              <form class="form form-vertical">
                <div class="row mb-1">
                  <div class="col-md-6">
                    <div class="form-group mb-1">
                      <label for="allotedto">Role</label>
                      <treeselect class="allotedto capitalisetext" placeholder="Select role" v-model="roletrack.roleid" :options="rolelist" @input="selrolename" @close="validateForm('t42',$event)"/>
                    <div class="errorinputmsg" v-if="this.errors.roletrack.roleid">{{ this.errors.roletrack.roleid }}</div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb1">
                        <label for="allotedto">Tracker</label>
                        <treeselect class="allotedto capitalisetext" placeholder="Select tracker" v-model="roletrack.trackername" :options="trackerownerlist"  @close="validateForm('t42',$event)" @input="seltrackername"/>
                        <div class="errorinputmsg" v-if="this.errors.roletrack.trackername">{{ this.errors.roletrack.trackername }}</div>
                    </div>
                  </div>
                </div>
                <div class="card " id="performanceList">
                    <div class="card-body p-0">
                        <div class="table-responsive">
                            
                            <!-- Release Planner -->
                            <table class="table table-sm dataTableListing" v-if="roletrack.trackername && roletrack.trackername != null && roletrack.trackername != ''" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col" class="text-center">Current / New Status</th>
                                      <th scope="col" class="text-center" v-for="(data,indexs) in listingArr" v-bind:key="indexs">{{data.label}}</th>
                                  </tr>
                              </thead>

                              <tbody>
                                  <template>
                                      <tr v-for="(datalist,indexx) in listingArr" v-bind:key="indexx" >
                                        <td class="text-center text-capitalize">{{datalist.label}}</td>
                                        <td class="text-center text-capitalize" v-for="(statusdata,indexxx) in listingArr" v-bind:key="indexxx">
                                          <input type="checkbox" v-if="datalist.checked_val != undefined && (datalist.checked_val).includes(statusdata.id) ==true" class="productfilter masterid ml-1"
                                              name="productfilter" v-bind:id="'checkallusers'+datalist.id+statusdata.id" value=""
                                              style=" margin:10px 0px 0px 0px;" v-on:change="onfilterchangeRN(datalist.id,statusdata.id, $event)" checked>
                                              <input type="checkbox" v-else="datalist.checked_val != undefined && (datalist.checked_val).includes(statusdata.id) == false" class="productfilter masterid ml-1"
                                              name="productfilter" v-bind:id="'checkallusers'+datalist.id+statusdata.id" value=""
                                              style=" margin:10px 0px 0px 0px;" v-on:change="onfilterchangeRN(datalist.id,statusdata.id, $event)" >
                                          </td>
                                      </tr>  
                                  </template>
                              </tbody>
                            </table>
                            <div v-if="roletrack.trackername && roletrack.trackername != null && roletrack.trackername != ''">
                                <div class="errorinputmsg" v-if="this.errors.roletrack.checkArr">{{ this.errors.roletrack.checkArr }}</div>
                            </div>
                            <!-- End Release Planner -->
                        </div>
                            <div class="projectmapping_text_bg" v-if="!roletrack.trackername" style="text-align: center; padding:10px 0px;border-radius: 10px;">Please select role and tracker</div>
                    </div>
                </div>
                <div class="col-12 mg-top1 text-center">
                    <button type="button" class="btn btn-relief-primary mr-1"  @click="savewizard('t42',$event)" id="submitbtnt42">Submit</button>
                    
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<style>

.createbtnparentdiv{
    padding-right: 0% !important;
}
.displayblk{
    display:block !important;
}
.createbtn{
    border-color: #053e52 !important;
    color: #fff !important;
    background-color: #008000 !important;
    padding: 0.386rem 0.5rem !important;
}
</style>
<script>
import axios from 'axios'
import apiUrl from '../../constants';
import moment from 'moment'
import VueElementLoading from 'vue-element-loading';
import vSelect from 'vue-select'
import { EditIcon, EyeIcon, Trash2Icon, SearchIcon, GridIcon, CodesandboxIcon, CloudIcon, ChromeIcon, BoxIcon, Link2Icon, PackageIcon, MoreVerticalIcon, Edit2Icon, PlusIcon, DownloadIcon, PlusSquareIcon, ClipboardIcon } from 'vue-feather-icons'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Pagination from 'vue-pagination-2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import commonMethods from '../../utils/commonMethods';

export default {
  name:'RoleTracker',
  components:{
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    PlusIcon,
    DownloadIcon,
    Edit2Icon,
    PlusSquareIcon,
    ClipboardIcon,
    ckeditor: CKEditor.component,
    Trash2Icon,
  },    
  mixins: [ commonMethods ],
  data() {
    return {
      isThisBusinessRole: false,
      loginuser:'',
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      editRowData:[],
      TimeFormat: apiUrl.TimeFormat,
      btcstatuslist: [],
      releaseplanerstatuslist: [],
      taskstatuslist:apiUrl.status,
      issustatuslist:apiUrl.issueStatuslist,
      tcscriptstatuslist:apiUrl.tcscriptstatuslist,
      tcsbulkmappingstatuslist: apiUrl.tcsbulkmappingstatuslist,
      testcyclestatuslist:apiUrl.testcyclestatuslist,
      incidenttypelist:[],
      incidentstatusopts: [{
            id:'open',
            label:'Open'
        },{
            id:'resolved',
            label:'Resolved'
        },{
            id:'closed',
            label:'Closed'
        },{
            id:'reopen',
            label:'Re-open'
        }],
        dynamicTCstatusopts:[],
      roletrack:{
        rolename:null,
        roleid: null,
        trackername:null,
        checkarray: {}
      },
      isAddState:false,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      showtabledata:true,
      showproject:false,
      modulelist:[],
      isRequestActive:true,
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      errors:{
        roletrack:{
          rolename:null,
          roleid: null,
          trackername:null,
          checkArr: null,
        }
      },
      taskownerlist: [
        { id: "Product", label: "Product" },
        { id: "Tech", label: "Tech" },
        { id: "QA", label: "QA" },
        { id: "Security", label: "Security" },
        { id: "Infra", label: "Infra" },
      ],
      trackerownerlist: [],
      isedit: false,
      card:'card',
      border_warning:'border-warning',
      statusrowid: null,
      statuscolumnid: null,
      rolelist: [],
      admintypelist:apiUrl.admintypelist,
      blockertypes: apiUrl.blockertypes,
      requisitionstatuslist: [],
      tickettrackerstatus: [],
      issuetypelist : [],
      tickettypelist: [],
      tasktypelist: [],
      testcaselist: [],
      moduletrackerid : apiUrl.moduletrackerid,
      listingArr : [],
      listingEditArr : [],
      checkArr: {},
    }
  },
  mounted(){
    let permission = this.$Service.verifyAccess();
    if(permission){
      var userdata = window.localStorage.getItem('userdata');
      if(userdata){
          userdata = JSON.parse(userdata)
          this.undt=userdata
          this.loginusername=userdata.username
          this.loginuscondition = userdata.userid 
          this.tokendata = window.localStorage.getItem('token');  
          this.getThirdPartyAPIcall();
          this.gettrackerlist();
          this.admintypelist =  this.admintypelist.map((file) => {
            return {id:file.label,label:file.label}                
          });
      }
    }
  },
  methods:{
    getBtcStatusList(moduletype) {
      let url = "api/btc/getBtcStatusList";
      this.input = {
        useremail: this.undt.username,
        empcode: this.undt.userid,
        moduletype: moduletype
      };
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.listingArr = result.data.data.rows;
          if(this.listingArr.length>0) {
            this.getEditData(this.listingArr);
          }
        } else {
          this.listingArr = [];
        }
      }).catch(e => {
        this.displayError(e)
      });   
    },
    getDynamicfieldallstatus(){
      this.inputfield= {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        trackername:this.moduletrackerid
      }
      axios({
        'method': 'POST',
        'url': 'api/fieldtracker/getfieldallstatus',
        'data': this.inputfield,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        if(result.data.status == true){
          this.listingArr = result.data.data.rows;
          if(this.listingArr.length>0) {
            this.getEditData(this.listingArr);
          }
        }else{
          this.listingArr = []
        }
      }).catch(e => {
      this.displayError(e)
      })
    },
    getTrackerNameById() {
        this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            tname: 'Release Planner'
        }
        axios({
            'method': 'POST',
            'url': 'api/tracker/getTrackerNameById',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            if(result.data.status == true){
                let id = result.data.data.rows[0].id;
                this.getfieldallstatus(id);
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    getfieldallstatus(tid){
        this.isLoading = true;
        this.inputfield= {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            trackername: tid
        }
        axios({
            'method': 'POST',
            'url': 'api/fieldtracker/getfieldallstatus',
            'data': this.inputfield,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            this.isLoading = false;
            if(result.data.status == true){
                this.listingArr = result.data.data.rows;
                if(this.listingArr.length>0) {
                  this.getEditData(this.listingArr);
                }
              }else{
                this.listingArr = []
            }
        }).catch(e => {
            this.displayError(e)
        })
    },
    gettrackerlist() {
        this.input={
            useremail  : this.undt.username,
            empcode: this.undt.userid,
        }
        axios({
            'method': 'POST',
            'url': 'api/feedbackform/getallforms',
            'data': this.input,
            'headers':{'authorization':this.tokendata}
        })
        .then(result => {
            if(result.data.errorCode == 0){
                this.trackerownerlist = result.data.data.rows.map(item => ({ id: item.label, label: item.label }));
                this.trackerownerlist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
            } else {
                this.trackerownerlist = []
            }
        }).catch(e => {
                this.displayError(e)
      });
    },
    getThirdPartyAPIcall(){
      this.pageoffset=1
      this.inputthrid = {
        tbl:'t10',
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
      }
      axios({
        'method': 'POST',
        'url': 'api/projectonlyroleassignment/getdesignations',
        'data': this.inputthrid,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        if(result.data.status == true){
          this.rolelist = result.data.data.rows
          this.rolelist.sort((a, b) => {
              return a.label.localeCompare(b.label);
          });
        }else{
          this.rolelist=[]
        }
      }).catch(e => {
                this.displayError(e)
      })
    },
    gettrackerwisestatuslist(trackers){
      this.pageoffset=1
      this.input = {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        tracker: trackers
      }
      axios({
        'method': 'POST',
        'url': 'api/tasks/gettrackerwisestatuslist',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        if(result.data.status == true){
          this.listingArr = result.data.data.rows;
          if(this.listingArr.length>0) {
            this.getEditData(this.listingArr);
          }
        }else{
          this.listingArr = []
        }
      }).catch(e => {
                this.displayError(e)
      })
    },
    getTicketTrackerStatusList(trackers){
      this.pageoffset=1
      this.input = {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        tracker: trackers
      }
      axios({
        'method': 'POST',
        'url': 'api/tasks/getTicketTrackerStatus',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        if(result.data.status == true){
          this.listingArr = result.data.data.rows.map(i => {
              return { id: i.id, label:i.statusname }
          });

          if(this.listingArr.length>0) {
            this.getEditData(this.listingArr);
          }
        }else{
          this.listingArr = []
        }
      }).catch(e => {
          this.displayError(e)
      })
    },
    getEditData(listingArr) {
      if(listingArr.length > 0) {
          this.isLoading = true
          let url = "api/tasks/getconfigdatabyid";
          this.input = {
            empcode: parseInt(this.undt.userid),
            useremail: this.undt.username,
            roleid: this.roletrack.roleid,
            trackername: this.roletrack.trackername
          };
          axios({
              method: "POST",
              url: url,
              data: this.input,
              'headers': { 'authorization': this.tokendata }
          }).then((result) => {
              this.isLoading = false;
              if (result.data.errorCode == 0) {
                this.editRowData = result.data.data;
                this.editRowData = this.editRowData[0]
                this.openEditModule(this.editRowData, 0, listingArr)
              } else {
                  this.editRowData = [];
                  if(listingArr != undefined) {
                    for(var i=0; i<listingArr.length;i++) {
                      listingArr[i]['checked_val'] = [];
                    }
                  }
              }
          }).catch(e => {
                  this.displayError(e)
        })
      }
    },
    async getconfigdatabyid(roleid, trackername) {
      this.roletrack.trackername = trackername;
      this.roletrack.roleid = roleid;
      console.log(this.roletrack.trackername, '---')
      
      if(this.roletrack.trackername === 'Raise Requisition'){
        console.log('--1')
        await this.gettrackerwisestatuslist(this.roletrack.this.roletrack.trackername);
      } 
      if(this.roletrack.trackername === 'Ticket Request'){
        console.log('--2')
        await this.getTicketTrackerStatusList(this.roletrack.this.roletrack.trackername);
      } 
      if(this.roletrack.trackername === 'Issue Type'){
        console.log('--3')
        await this.getModuleTypesList(this.roletrack.trackername, 'issue');
      }
      if(this.roletrack.trackername === 'Ticket Request Type'){
        console.log('--4')
        await this.getModuleTypesList(this.roletrack.trackername, 'ticket');
      }
      if(this.roletrack.trackername === 'Incident Type'){
        console.log('--5')
        await this.getModuleTypesList(this.roletrack.trackername, 'incident');
      }
      if (this.roletrack.trackername === 'Task Type') {
        console.log('--6')
        await this.getModuleTypesList(this.roletrack.trackername, 'task');
      }
      if (this.roletrack.trackername === 'Testcase Type') {
        console.log('--7')
        await this.getModuleTypesList(this.roletrack.trackername, 'testcase');
      }
      if (this.roletrack.trackername === 'Businesstask') {
        console.log('--8')
        await this.getBtcStatusList('Businesstask');
      }
      if (this.roletrack.trackername === 'Task') {
        console.log('--9')
        await this.getBtcStatusList('Task');
      }
      if (this.roletrack.trackername === 'dynamic_tickets') {
        console.log('--10')
        await this.getDynamicfieldallstatus();
      }  
      if (this.roletrack.trackername === 'Release Planner') {
        console.log('--11')
        await this.getTrackerNameById();
      }
      if (this.roletrack.trackername === 'Issue') {
        console.log('--12')
        this.listingArr = apiUrl.issueStatuslist;
        if(this.listingArr.length>0) {
          this.getEditData(this.listingArr);
        }
      }
      if (this.roletrack.trackername === 'Incident') {
        console.log('--13')
        this.listingArr = this.incidentstatusopts;
        if(this.listingArr.length>0) {
          this.getEditData(this.listingArr);
        }
      }
      if (this.roletrack.trackername === 'Testcase') {
        console.log('--14')
        this.listingArr = this.tcscriptstatuslist;
        if(this.listingArr.length>0) {
          this.getEditData(this.listingArr);
        }
      }
      if (this.roletrack.trackername === 'TC Bulk Mapping') {
        console.log('--15')
        this.listingArr = this.tcsbulkmappingstatuslist;
        if(this.listingArr.length>0) {
          this.getEditData(this.listingArr);
        }
      }
      if(this.roletrack.trackername == 'Test Cycle Set'){
        console.log('--16')
        this.listingArr = this.testcyclestatuslist;
        if(this.listingArr.length>0) {
          this.getEditData(this.listingArr);
        }
      }
      if(this.roletrack.trackername == 'BTM Task Type'){
        console.log('--17')
        this.listingArr = this.admintypelist;
        if(this.listingArr.length>0) {
          this.getEditData(this.listingArr);
        }
      }
      if(this.roletrack.trackername == 'Blocker Type'){
        console.log('--18')
        this.listingArr = this.blockertypes;
        if(this.listingArr.length>0) {
          this.getEditData(this.listingArr);
        }
      }

    },
    openEditModule: function(currRowData, flag, listingArr){
      this.editRowData = currRowData;
      let editedData = [];
      this.checkArr = this.editRowData.statusconfig
      editedData = this.checkArr;

      if(editedData != undefined) {
        for(var i=0; i<listingArr.length;i++) {
          listingArr[i]['checked_val'] = [];
            var result = editedData.hasOwnProperty(listingArr[i]['id']);
            if(result == true) { 
                for(var j=0;j<editedData[listingArr[i]['id']].length;j++) {
                  listingArr[i]['checked_val'].push(editedData[listingArr[i]['id']][j]);
                } 
            }
        }
      }
    },
    selrolename(state, value) {
      this.roletrack.roleid = null;
      this.roletrack.roleid = state;

      this.listingArr = [];
      this.checkArr = {}
      this.errors.roletrack.checkArr = null;
      
      if (!state) {
        this.errors.roletrack.roleid = "Role is required";
      } else {
        this.errors.roletrack.roleid = '';
      }
      if (this.roletrack.roleid && this.roletrack.trackername) {
        this.getconfigdatabyid(this.roletrack.roleid, this.roletrack.trackername)       
      }
    },
    seltrackername(state, value) {
      this.roletrack.trackername = null;
      this.roletrack.trackername = state;

      this.listingArr = [];
      this.checkArr = {}
      this.errors.roletrack.checkArr = null;

      if (!state) {
        this.errors.roletrack.trackername = "Tracker is required";
      } else {
        this.errors.roletrack.trackername = '';
      }
      if (this.roletrack.roleid &&  this.roletrack.trackername) {
        this.getconfigdatabyid(this.roletrack.roleid,  this.roletrack.trackername)       
      }
    },
    getModuleTypesList(trackername, modulename) {
      this.input = {
        empcode: parseInt(this.undt.userid),
        useremail: this.undt.username,
        tracker: trackername,
        modulename: modulename,
      }
      axios({
        'method': 'POST',
        'url': 'api/typelist/getModuleTypesList',
        'data': this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        if(result.data.status == true){
          this.listingArr = result.data.data.rows;
          if(this.listingArr.length>0) {
            this.getEditData(this.listingArr);
          }
        }else{
          this.listingArr = []
        }
      }).catch(e => {
          this.displayError(e)
      })
    },
    onfilterchangeRN(datas, datass, event){
        this.statusrowid = datas;
        this.statuscolumnid = datass;
console.log(this.checkArr, '--ahe')
        if (event.target.checked == true) {
          if(this.checkArr.hasOwnProperty(this.statusrowid)){
              this.checkArr[this.statusrowid].push(this.statuscolumnid)
          }else{
              this.checkArr[this.statusrowid] = []
              this.checkArr[this.statusrowid].push(this.statuscolumnid)
          }
          this.errors.roletrack.checkArr = null
        }else{
          const findIndex = this.checkArr[this.statusrowid].indexOf(this.statuscolumnid)
          this.checkArr[this.statusrowid].splice(findIndex, 1)
        }
    },
    handleCancel() {
        this.$router.push('/roletracker/list')
    },
    savewizard: function(mdl,objThis){
        this.valid = this.validateForm(mdl,objThis);
        if (this.valid) {
            this.isLoading = true;
            this.input = {
                createdby: this.undt.username,
                empcode: parseInt(this.undt.userid),
                useremail: this.undt.username,
            }

            let apiURL='api/tasks/saverolestatusconfig'
            this.input.roleid = this.roletrack.roleid
            this.input.trackername = this.roletrack.trackername
            this.input.statusarray = this.checkArr
            axios({
            'method': 'POST',
            'data': this.input,
            'url': apiURL,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;             
                if(result.data.errorCode == 0){
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.cleardata();
                }else if(result.data.errorCode == 3){
                    Swal.fire({
                          title: "Session Expired...!!",
                          text: result.data.msg,
                          icon: 'info',
                          customClass: {
                              confirmButton: "btn btn-primary",
                          },
                          buttonsStyling: !1,
                      }).then(function() {
                        window.location.href = "/#/login";
                    })
                  } else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
                this.displayError(e)
      })
        }
    },
    validateForm: function(mdl,objThis){
        var isValid=true;
        if(this.roletrack.roleid == null || this.roletrack.roleid == '' || this.roletrack.roleid == []){
            this.errors.roletrack.roleid = 'Role is required';
            isValid= false;
        }else{
            this.errors.roletrack.roleid = null;
        }

        if(!this.roletrack.trackername) {
            this.errors.roletrack.trackername = 'Tracker is required';
            isValid= false;
        } else {
            this.errors.roletrack.trackername = null;
        }

        if(Object.keys(this.checkArr).length === 0){
            this.errors.roletrack.checkArr = 'Select current status';
            isValid= false;
        }
        
        return isValid;
    },
    cleardata(){
      this.roletrack.roleid = null;
      this.roletrack.trackername = null;
      this.errors.roletrack.roleid = null;
      this.errors.roletrack.trackername = null;
      this.errors.roletrack.checkArr = null;
      this.checkArr = {};
    },
  }, 
  filters: {
    basename: function (value) {
        if (!value) return ''
        return value.substr(value.lastIndexOf("/") + 1)
    }
}
}
</script>
