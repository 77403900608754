<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card border-warning" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
                <div class="card-header" style="cursor:pointer" v-on:click="mysearchrequestollapse">
                  <div class="row" style="width:100%">
                    <div class="col-md-2">
                        <div class="avatar bg-light-secondary rounded">
                            <div class="avatar-content">
                                <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9" style="margin-left:-130px;margin-top:5px;">
                        <h4 class="card-title">Search Project Details</h4>
                    </div>
                  </div>
                </div>
              <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]" style="margin-top:10px">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <label class="form-label lable-left">Project Name</label>
                      <treeselect class="projectmdllistdd capitalisetext" placeholder="Enter min 3 chars to search project name" v-on:search-change="projectChange" v-model="search.searchProjectname" :options="projectnameList" @input="selectproject"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-3 mb-1">
                      <button type="submit" class="btn btn-primary mt-2 mr-1" @click="getprojectlist(search)"> Search</button>
                      <button type="reset" class="btn btn-outline-secondary mt-2" @click="resetRecords()"> Clear </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="card-body">
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div class="card-body" style="padding-left: 0px !important; padding-top: 0px !important;">
                                <div class="row">
                                  <div class="col-md-10">
                                    <div class="input-group-append">
                                    </div>
                                  </div>
                                  <div class="col-md-2 createbtnparentdiv">
                                    <div class="input-group-append displayblk">
                                      <button
                                        id="commonaddmodule"
                                        class="btn waves-effect waves-float waves-light createbtn"
                                        data-toggle="modal"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :data-target="currentmodule"
                                        data-backdrop="static" data-keyboard="false"
                                        @click="addModule($event)"
                                        type="button"
                                      >
                                        + Create project role
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="table-responsive"  v-if="showtabledata">
                                <table class="table table-sm dataTableListing" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno">Sr#</th>
                                      <th scope="col">Project Name</th>
                                      <th scope="col" class="centeralign">Employee Count</th>
                                      <th scope="col" class="clsaction centeralign">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template v-for="(data, index) in roleassignmasterlist">
                                      <tr v-bind:key="data.projectroleassiid">
                                        <td class="srno">{{ index + 1 + currPageNum * pagelimit }}</td>
                                        <td>
                                          <span
                                            class="font-weight-bold cursorpointercls sitebluecolor capitalisetext"
                                            @click="redirecttourl('view', data)"
                                            >{{ data.projectname }}</span
                                          >
                                        </td>
                                        <td class="centeralign" data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#ViewRoleAlloc" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false" style="cursor: pointer;">
                                          {{ data.empcount }}
                                        </td>
                                        <td class="clsaction">
                                          <a class="devEditEle" data-toggle="modal" aria-haspopup="true" aria-expanded="false" data-target="#updatebusiness" data-currState="reguler" @click="openEditModule(data)" data-backdrop="static" data-keyboard="false">
                                            <edit-icon size="1.5x" class="custom-class"></edit-icon>
                                          </a>
                                        </td>
                                      </tr>
                                    </template>
                                    <template v-if="roleassignmasterlist.length == 0">
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show" id="projectmaster">
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            id="closebtnt11"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #ffff !important"
            @click="closeForm()"
          >
            ×
          </button>
          <div
            class="modal-header mb-1"
            style="background-color: #1f77b4 !important"
          >
            <h5 class="modal-title" style="color: #ffff !important">
              <plus-square-icon
                size="1.5x"
                class="custom-class"
              ></plus-square-icon>
              <span class="align-middle ml-50" 
                >Add/Update Project Details</span
              >
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <form class="form form-vertical">
              <div class="row">
                <div id="addproject2nd" v-if="project.currttbl == 't15'">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">Project Name</label>
                      <treeselect
                        v-bind:disabled="project.disbleprojid"
                        class="projroleassprojectdd"
                        v-model="projvalue"
                        :options="projoptions"
                        @input="selroleassprojdata"
                        @close="validateForm('t14', $event)"
                      />
                      <div
                        class="errorinputmsg"
                        v-if="this.errors.roleassign.proj"
                      >
                        {{ this.errors.roleassign.proj }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Select Program manager</label
                      >
                      <treeselect
                        class="programodd capitalisetext"
                        v-model="project.programvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Select project Manager</label
                      >
                      <treeselect
                        class="projectmgrdd capitalisetext"
                        v-model="project.projectmgrvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">Select APM</label>
                      <treeselect
                        class="apmdd capitalisetext"
                        v-model="project.apmvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Senior Engg. Manager</label
                      >
                      <treeselect
                        class="srengmanagerdd capitalisetext"
                        v-model="project.srengmanagervalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">Engg. Manager</label>
                      <treeselect
                        class="engmanagerdd capitalisetext"
                        v-model="project.engmanagervalue"
                        :multiple="true"
                        :options="project.tmpnoempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">Project lead</label>
                      <treeselect
                        class="proleaddd capitalisetext"
                        v-model="project.proleadvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">Module lead</label>
                      <treeselect
                        class="modleaddd capitalisetext"
                        v-model="project.modleadvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Senior solution Architect</label
                      >
                      <treeselect
                        class="srsoluarchdd capitalisetext"
                        v-model="project.srsoluarchvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Solution Architect</label
                      >
                      <treeselect
                        class="soluarchdd capitalisetext"
                        v-model="project.soluarchvalue"
                        :multiple="true"
                        :options="project.tmpnoempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Senior QA manager</label
                      >
                      <treeselect
                        class="soluarchdd capitalisetext"
                        v-model="project.srqamgrvalue"
                        :multiple="true"
                        :options="project.tmpnoempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">QA manager</label>
                      <treeselect
                        class="qamgrdd capitalisetext"
                        v-model="project.qamgrvalue"
                        :multiple="true"
                        :options="project.tmpnoempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Senior security Manager</label
                      >
                      <treeselect
                        class="srsecumgrdd capitalisetext"
                        v-model="project.srsecumgrvalue"
                        :multiple="true"
                        :options="project.tmpnoempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd"
                        >Sr. Business Analyst</label
                      >
                      <treeselect
                        class="srbusianlydd capitalisetext"
                        v-model="project.srbusianlyvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="projroleassprojectdd">Business Analyst</label>
                      <treeselect
                        class="busianlydd capitalisetext"
                        v-model="project.busianlyvalue"
                        :multiple="true"
                        :options="project.projectempllist"
                        :clear-on-select="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-relief-primary mr-1"
                    v-on:click.stop.prevent="savewizard(project.currttbl, $event, 'draft')"
                    :id="'submitsvbtn' + project.currttbl"
                    :value="project.savedraft"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary mr-1"
                    v-on:click.stop.prevent="closeForm()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-slide-in fade" id="ViewRoleAlloc" aria-hidden="true">
        <div class="modal-dialog sidebar-lg">
            <div class="modal-content p-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#ffff !important">×</button>
                <div class="modal-header mb-1" style="background-color:rgb(31, 119, 180)  !important;">
                    <h5 class="modal-title" style="color:#ffff !important">
                        <plus-square-icon size="1.5x" class="custom-class"></plus-square-icon>
                        <span class="align-middle"> Role Allocation Details</span>
                    </h5>
                </div>
                <div class="modal-body flex-grow-1 devUniqueForm">
                    <h5 v-if="this.viewRoles.projectName" style="text-align: center; margin-bottom: 14px; background-color:#eee; padding:5px 0">{{this.viewRoles.projectName}}</h5>
                    <table class="table table-sm table-bordered" aria-describedby="mydesc">
                        <thead>
                          <tr>
                            <th scope="col">Role Name</th>
                            <th scope="col">Resource</th>
                          </tr>
                        </thead>
                        <tr v-if="this.viewRoles.programManager.length">
                          <td>Program Manager</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.programManager)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.projectManager.length">
                          <td>Project Manager</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.projectManager)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.APM.length">
                          <td>APM</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.APM)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.srEnggManager.length">
                          <td>Senior Engg Manager</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.srEnggManager)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.enggManager.length">
                          <td>Engg Manager</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.enggManager)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.projectLead.length">
                          <td>Project Lead</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.projectLead)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.moduleLead.length">
                          <td>Module Lead</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.moduleLead)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.srSolutionArch.length">
                          <td>Senior Solution Architect</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.srSolutionArch)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.solutionArch.length">
                          <td>Solution Architect</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.solutionArch)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.srQAmanager.length">
                          <td>Senior QA Manager</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.srQAmanager)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.qaManager.length">
                          <td>QA Manager</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.qaManager)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.srSecurityManager.length">
                          <td>Senior Security Manager</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.srSecurityManager)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.srBusinessAnalyst.length">
                          <td>Senior Business Analyst</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.srBusinessAnalyst)"></span>
                          </td>
                        </tr>
                        <tr v-if="this.viewRoles.BusinessAnalyst.length">
                          <td>Business Analyst</td>
                          <td>
                            <span class="font-weight-bold" v-html="showEmpNames(this.viewRoles.BusinessAnalyst)"></span>
                          </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<style>
.card .card-header {
  padding: 0.8rem;
}
.createbtnparentdiv {
  padding-right: 0%;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}

.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import moment from "moment";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";

import Treeselect from "@riophae/vue-treeselect";

import { PlusSquareIcon,PlusIcon,Edit2Icon,MoreVerticalIcon,PackageIcon,Link2Icon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon } from "vue-feather-icons";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import commonMethods from '../../utils/commonMethods';

export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    SearchIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    PlusSquareIcon,
    /* BDropdown,
        BDropdownDivider,
        BDropdownItem, */
  },
  /* directives: {
        Ripple,
    }, */
    mixins: [ commonMethods ],
  data() {
    return {
      search: {
        searchCompany: null,
        createdbyOpts: [],
        createdby: null,
        searchProjectname:null,
        companyname: null,
        businessname: null,
        companynameList: [],
        businessnameList: [],
      },
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      roleassignmasterlist: [],
      stylevariation: apiUrl.stylevariation,
      showprojectdata: [],
      showroledata: [],
      showempdata: [],
      isProjectSel: 0,
      isProjectRoleSel: 0,
      showAddForm: false,
      selected: null,
      countryName: [
        { label: "select_value", id: "Select Value" },
        { label: "Russia", id: "Russia" },
        { label: "Canada", id: "Canada" },
        { label: "China", id: "China" },
        { label: "United States", id: "United States" },
        { label: "Brazil", id: "Brazil" },
        { label: "Australia", id: "Australia" },
        { label: "India", id: "India" },
      ],
      editRowData: [],
      glbMdl: "",
      isRoleAlloc: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      projectType: [],
      projectdescription: "",
      isProjectMsr: "",
      viewprojectlist: 0,
      projoptions: [
        {
          id: "",
          label: "",
        },
      ],
      roleoptions: [
        {
          id: "",
          label: "",
        },
      ],
      employeeoptions: [
        {
          id: "",
          label: "",
        },
      ],
      projvalue: null,
      emplvalue: null,
      rolevalue: null,
      project: {
        name: null,
        desc: null,
        projecttype: null,
        projecttypelist: [
          {
            id: "",
            label: "",
          },
        ],
        businesslist: [
          {
            id: "",
            label: "",
          },
        ],
        companylist: [
          {
            id: "",
            label: "",
          },
        ],
        business: null,
        company: null,
        country: "india",
        currttbl: "t15",
        prevtbl: null,
        iseditstate: false,
        disbleprojid: false,
        rolevalue: null,
        roleoptions: [
          {
            id: "",
            label: "",
          },
        ],
        savedraft: "draft",
        nextbtn: "next",
        projectid: null,
        programvalue: null,
        programoptions: null,
        projectmgrvalue: null,
        projectmgroptions: null,
        apmvalue: null,
        apmoptions: null,
        srengmanagervalue: null,
        srengmanager: null,
        engmanagervalue: null,
        engmanager: null,
        proleadvalue: null,
        prolead: null,
        modleadvalue: null,
        modlead: null,
        srsoluarchvalue: null,
        srsoluarch: null,
        soluarchvalue: null,
        soluarch: null,
        qamgrvalue: null,
        qamgr: null,
        srqamgrvalue: null,
        srqamgr: null,
        srsecumgrvalue: null,
        srsecumgr: null,
        srbusianlyvalue: null,
        srbusianly: null,
        busianlyvalue: null,
        busianly: null,
        programrolevalue: null,
        projectmgrrolevalue: null,
        apmrolevalue: null,
        srengmanagerrolevalue: null,
        engmanagerrolevalue: null,
        proleadrolevalue: null,
        modleadrolevalue: null,
        srsoluarchrolevalue: null,
        soluarchrolevalue: null,
        qamgrrolevalue: null,
        srqamgrrolevalue: null,
        srsecumgrrolevalue: null,
        srbusianlyrolevalue: null,
        busianlyrolevalue: null,
        companyname: null,
        projectempllist: [],
        tmpnoempllist: [
          {
            id: "",
            label: "No Role defined yet in CAMP system",
          },
        ],
      },
      roleallocation: {
        role: null,
        employee: null,
        employelist: [
          {
            id: "",
            label: "",
          },
        ],
        rolelist: [
          {
            id: "",
            label: "",
          },
        ],
      },
      applinameBlured: false,
      appenvhosturlBlured: false,
      appenvhostdetailspathBlured: false,
      appenvhostpathBlured: false,
      appenvhostBlured: false,
      appenvnameBlured: false,
      appenvtypeBlured: false,
      projecttypeBlured: false,
      roleallocationBlured: false,
      employeeBlured: false,
      projectBlured: false,
      projectdescBlured: false,
      projectcountryBlured: false,
      projectmodule: {
        name: null,
        project: null,
        projectlist: [
          {
            id: "",
            label: "",
          },
        ],
        desc: null,
        attachment: null,
        attachmentview: null,
        disblesavebtn: false,
      },
      errors: {
        project: {
          name: null,
          desc: null,
          projecttype: null,
          business: null,
          company: null,
          country: "india",
        },
        roleallocation: {
          role: null,
          employee: null,
        },
        roleassign: {
          proj: null,
          role: null,
          empl: null,
        },
        projectmodule: {
          name: null,
          project: null,
          desc: null,
          attachment: null,
          attachmentview: null,
        },
      },
      isAddState: false,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      showtabledata: true,
      companylist: [],
      showproject: false,
      showcompanies: true,
      projectcurrentstate: "local",
      activecompanyid: "",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Next',
      viewRoles: {
        projectName: null,
        programManager: [],
        projectManager: [],
        APM: [],
        srEnggManager: [],
        enggManager: [],
        projectLead: [],
        moduleLead: [],
        srSolutionArch: [],
        solutionArch: [],
        srQAmanager: [],
        qaManager: [],
        srSecurityManager: [],
        srBusinessAnalyst: [],
        BusinessAnalyst: [],
      },
      tokendata:null,
    };
  },
  created() {
    this.pagefeatures = {};
    var pagefeatures1 = JSON.parse(
      window.atob(window.localStorage.getItem("pagefeatures"))
    );
    pagefeatures1.forEach((element) => {
      if (element.page === "/project") {
        this.pagefeatures[element.featurename] = element.featureaccess;
      }
    });
  },
  mounted() {
    this.getApplicationdata("t11", "projectroleassign-tab");
    this.getfiltereddata("projectempllist");
    var userdata = window.localStorage.getItem("userdata");
    if (userdata) {
      userdata = JSON.parse(userdata);
      this.undt = userdata;
      this.tokendata = window.localStorage.getItem('token');
    }
    this.projectcurrentstate = "local";
    if (window.location.host === "smartworks.firstcry.tech") {
      this.projectcurrentstate = "live";
    } else if (window.location.host === "65.0.57.28:9000") {
      this.projectcurrentstate = "stage";
    }
    this.project.rolevalue = [ "169", "155", "156", "158", "160", "161", "157", "164", "165", ];
    if (this.projectcurrentstate == "live") {
      this.project.rolevalue = [ "138", "139", "140", "142", "143", "144", "141", "146", "147", ];
    }
    let rowdata={}
    this.getprojectlist(rowdata)
  },
  methods: {
    showEmpNames(prlist){
      let responsestr='';
      if(prlist.length > 1){
        for(var i=0;i<prlist.length;i++){
            responsestr +=(i+1)+'. '+prlist[i]+' <br/>'
        }
      }else{
        for(var i=0;i<prlist.length;i++){
            responsestr +=prlist[i]
        }
      }
      return responsestr
    },
    closeForm() {
      $('.close').click()
      this.project.programvalue = [];
      this.project.projectmgrvalue = [];
      this.project.apmvalue = [];
      this.project.srengmanagervalue = [];
      this.project.proleadvalue = [];
      this.project.modleadvalue = [];
      this.project.srsoluarchvalue = [];
      this.project.srbusianlyvalue = [];
      this.project.busianlyvalue = [];
    },
    companyCollapse: function () {
      this.showcompanies = !this.showcompanies;
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectproject(state, value) {
      this.search.searchProjectname = state;
    }, 
    projectChange: function (node, instanceId){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/searching/getProjectList";
        this.input = {
          projectname:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.projectnameList = result.data.data;
          } else {
            this.projectnameList = [];
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
      
    },
    redirecttourl: function (btnstate, rowdata) {
      rowdata.backbtnstate = btnstate;
      let redirectmdlnm='Project Module'
      let redirectmdlurl='/projectmodule'
      if(btnstate == 'addapplication'){
        redirectmdlnm='Project Application'
        redirectmdlurl='/application'
      }
      this.$router.push({
        name: redirectmdlnm,
        path: redirectmdlurl,
        params: {
          backbtnstate: btnstate,
          clickeddata: rowdata,
        },
      });
    },
    resetRecords: function () {
      this.search.searchProjectname = null;
      let rowdata={}
      this.getprojectlist(rowdata);
    },
    getprojectlist(rowdata) {
      this.isLoading = true;
      this.parentmoduledata = rowdata;
      let setmoduleform = "projectmaster";
      this.currentmodule=`#`+setmoduleform
      let glbMdlLblval = "Project";
      this.companymasterlist = [];
      let tblNm = "t11";
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      
      this.input = {
        tbl: "t15",
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        projectid:rowdata.searchProjectname,
        empcode: this.undt.userid,
      };
      this.input.isSearch = false;
      let url = "api/roleassignment/list";
      axios({
        method: "POST",
        url: url,
        data: this.input,
      'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.roleassignmasterlist = result.data.data.rows
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else if(result.data.errorCode == 3){
            Swal.fire({
                  title: "Session Expired...!!",
                  text: result.data.msg,
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: !1,
              }).then(function() {
                window.location.href = "/#/login";
            })
          } else {
          Swal.fire({
            title: "",
            text: "Project data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          this.showproject = true;
          this.roleassignmasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          this.project.company = this.activecompanyid;
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        }
      }).catch(e => {
          this.displayError(e)
        });
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t22") {
        this.customlisting(inputEle, "");
      } else {
        this.getprojectlist(this.parentmoduledata);
      }
    },
    validText: function (inputval) {
      var re = /[A-Za-z0-9].{5,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    valid2Text: function (inputval) {
      var re = /[A-Za-z0-9].{0,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    validate: function (tblNm) {
      this.projectBlured = true;
      this.projectdescBlured = true;
      this.projectcountryBlured = true;
      this.appenvhosturlBlured = true;
      this.appenvhostdetailspathBlured = true;
      this.appenvhostpathBlured = true;
      this.appenvhostBlured = true;
      this.appenvnameBlured = true;
      this.appenvtypeBlured = true;
      this.applinameBlured = true;

      if (
        tblNm == "t11" &&
        this.validText(this.project.name) &&
        this.validText(this.project.desc)
      ) {
        this.valid = true;
      }
      if (tblNm == "t12" && this.validText(this.application.name)) {
        this.valid = true;
      }
      if (
        tblNm == "t13" &&
        this.validText(this.appenv.name) &&
        this.validText(this.appenv.host) &&
        this.validText(this.appenv.hostpath) &&
        this.validText(this.appenv.hostdetails) &&
        this.validText(this.appenv.hosturl) &&
        this.valid2Text(this.appenv.type)
      ) {
        this.valid = true;
      }
    },
    setSelected(value) {
      this.selected = value;
    },
    addModule(objThis) {
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle == "t36") {
        this.projectmodule.disblesavebtn = true;
      } else if (inputEle == "t11") {
        this.project.iseditstate = false;
        this.addformbtntxt='Next'
      }
      $(".devUniqueForm").find("input[type=text]").val("");
      $(objThis.currentTarget.dataset.target)
        .find("#submitbtn" + inputEle)
        .attr("data-isedit", false);
      $("#glbsearchinpele").val("");
      this.isAddState = true;
      this.projectmodule.name = null;
      this.projectmodule.project = null;
      this.project.projecttype = null;
      this.project.business = null;
      this.project.desc = null;
      this.project.name = null;
      this.rolevalue = null;
      this.emplvalue = null;
      this.projvalue = null;
      this.roleallocation.role = null;
      this.roleallocation.employee = null;
      this.project.currttbl = "t15";
      this.project.prevtbl = "";
    },
    savewizard(mdl, objThis, currbtnstate) {
      this.valid = this.validateForm(mdl, objThis);
      if (this.valid) {
        let nameobj = document.getElementById("modulename" + mdl);
        let tblNm = "t15";
        if (mdl) tblNm = mdl;
        this.input = {
          tbl: tblNm,
          section: "assignment",
          createdby: this.undt.username,
          empcode: this.undt.userid,
        };
        if (nameobj != null && nameobj != "undefined") {
          this.input.name = nameobj.value;
        }
        let apiURL = "api/roleassignment/saveRoleAssign";
        this.input.rolename = "test";
        this.emplvalue = [];
        this.project.rolevalue = [];
        var empdata = [];
        var tmpindx = 0;
        if (this.project.programvalue) {
          if (this.project.programvalue.length > 0) {
            this.emplvalue.push(this.project.programvalue);
            this.project.rolevalue.push(this.project.programrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.programvalue),
              rolevalue: this.project.programrolevalue,
              rolename: 'Program Manager',
            });
            tmpindx++;
          }
        }
        if (this.project.projectmgrvalue) {
          if (this.project.projectmgrvalue.length > 0) {
            this.emplvalue.push(this.project.projectmgrvalue);
            this.project.rolevalue.push(this.project.projectmgrrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.projectmgrvalue),
              rolevalue: this.project.projectmgrrolevalue,
              rolename: 'Project Manager',
            });
            tmpindx++;
          }
        }
        if (this.project.apmvalue) {
          if (this.project.apmvalue.length > 0) {
            this.emplvalue.push(this.project.apmvalue);
            this.project.rolevalue.push(this.project.apmrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.apmvalue),
              rolevalue: this.project.apmrolevalue,
              rolename: 'APM',
            });
            tmpindx++;
          }
        }
        if (this.project.srengmanagervalue) {
          if (this.project.srengmanagervalue.length > 0) {
            this.emplvalue.push(this.project.srengmanagervalue);
            this.project.rolevalue.push(this.project.srengmanagerrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.srengmanagervalue),
              rolevalue: this.project.srengmanagerrolevalue,
              rolename: 'Senior Engg. Manager',
            });
            tmpindx++;
          }
        }
        if (this.project.engmanagervalue) {
          if (this.project.engmanagervalue.length > 0) {
            this.emplvalue.push(this.project.engmanagervalue);
            this.project.rolevalue.push(this.project.engmanagerrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.engmanagervalue),
              rolevalue: this.project.engmanagerrolevalue,
              rolename: 'Engg. Manager',
            });
            tmpindx++;
          }
        }
        if (this.project.proleadvalue) {
          if (this.project.proleadvalue.length > 0) {
            this.emplvalue.push(this.project.proleadvalue);
            this.project.rolevalue.push(this.project.proleadrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.proleadvalue),
              rolevalue: this.project.proleadrolevalue,
              rolename: 'Project lead',
            });
            tmpindx++;
          }
        }
        if (this.project.modleadvalue) {
          if (this.project.modleadvalue.length > 0) {
            this.emplvalue.push(this.project.modleadvalue);
            this.project.rolevalue.push(this.project.modleadrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.modleadvalue),
              rolevalue: this.project.modleadrolevalue,
              rolename: 'Module lead',
            });
            tmpindx++;
          }
        }
        if (this.project.srsoluarchvalue) {
          if (this.project.srsoluarchvalue.length > 0) {
            this.emplvalue.push(this.project.srsoluarchvalue);
            this.project.rolevalue.push(this.project.srsoluarchrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.srsoluarchvalue),
              rolevalue: this.project.srsoluarchrolevalue,
              rolename: 'Senior solution Architect',
            });
            tmpindx++;
          }
        }
        if (this.project.soluarchvalue) {
          if (this.project.soluarchvalue.length > 0) {
            this.emplvalue.push(this.project.soluarchvalue);
            this.project.rolevalue.push(this.project.soluarchrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.soluarchvalue),
              rolevalue: this.project.soluarchrolevalue,
              rolename: 'Solution Architect',
            });
            tmpindx++;
          }
        }
        if (this.project.srqamgrvalue) {
          if (this.project.srqamgrvalue.length > 0) {
            this.emplvalue.push(this.project.srqamgrvalue);
            this.project.rolevalue.push(this.project.qamgrrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.srqamgrvalue),
              rolevalue: this.project.qamgrrolevalue,
              rolename: 'Senior QA manager',
            });
            tmpindx++;
          }
        }
        if (this.project.qamgrvalue) {
          if (this.project.qamgrvalue.length > 0) {
            this.emplvalue.push(this.project.qamgrvalue);
            this.project.rolevalue.push(this.project.srqamgrrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.qamgrvalue),
              rolevalue: this.project.srqamgrrolevalue,
              rolename: 'QA manager',
            });
            tmpindx++;
          }
        }
        if (this.project.srsecumgrvalue) {
          if (this.project.srsecumgrvalue.length > 0) {
            this.emplvalue.push(this.project.srsecumgrvalue);
            this.project.rolevalue.push(this.project.srsecumgrrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.srsecumgrvalue),
              rolevalue: this.project.srsecumgrrolevalue,
              rolename: 'Senior security Manager',
            });
            tmpindx++;
          }
        }
        if (this.project.srbusianlyvalue) {
          if (this.project.srbusianlyvalue.length > 0) {
            this.emplvalue.push(this.project.srbusianlyvalue);
            this.project.rolevalue.push(this.project.srbusianlyrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.srbusianlyvalue),
              rolevalue: this.project.srbusianlyrolevalue,
              rolename: 'Sr. Business Analyst',
            });
            tmpindx++;
          }
        }
        if (this.project.busianlyvalue) {
          if (this.project.busianlyvalue.length > 0) {
            this.emplvalue.push(this.project.busianlyvalue);
            this.project.rolevalue.push(this.project.busianlyrolevalue);
            empdata.push({
              emplvalue: JSON.stringify(this.project.busianlyvalue),
              rolevalue: this.project.busianlyrolevalue,
              rolename: 'Business Analyst',
            });
            tmpindx++;
          }
        }
        this.input.projectassignroleidd = JSON.stringify(
          this.project.rolevalue
        );
        if (this.emplvalue.length == 0) {
          Swal.fire({
            title: "Failed",
            text: "Please select at least 1 employee",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return false;
        }
        this.input.employeemasterdd = JSON.stringify(this.emplvalue);
        this.input.empdata = JSON.stringify(empdata);
        this.input.projroleassprojectdd = this.projvalue;
        if (this.project.iseditstate == true) {
          apiURL = "api/roleassignment/updateRoleAssign";          
          this.input.id = this.roleassitabRowData.batchid;
          this.input.lastmodifiedby = this.undt.username;
          delete this.input.createdby;
        }
        this.isAddState = false;
        this.input.useremail= this.undt.username
        this.input.empcode= this.undt.userid
        axios({
          method: "POST",
          url: apiURL,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          if (result.data.errorCode == 0) {
            this.isLoading = false
            let rowdata = {}
            this.getprojectlist(rowdata)
            Swal.fire({
              title: "Success!",
              text: result.data.msg,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });  
            $(".close").click();
      }  else if(result.data.errorCode == 3){
              Swal.fire({
                    title: "Session Expired...!!",
                    text: result.data.msg,
                    icon: 'info',
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                }).then(function() {
                  window.location.href = "/#/login";
              })
            }else{
            Swal.fire({
              title: "Failed!",
              text: result.data.msg,
              icon: "Info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            });
          }
        }).catch(e => {
          this.displayError(e)
        });
      }
    },
    getroleassignmentdata(mdl, clickedstate) {
      let apiURL = "api/listing/getroleassignmentdatabyprojectid";
      this.isAddState = false;
      this.input = {
        tbl: "t11",
        projectid: this.projvalue,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      axios({
        method: "POST",
        url: apiURL,
        data: this.input,
         'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.project.iseditstate = false;
        if (mdl == "t11" && clickedstate == "draft") {
          this.project.iseditstate = true;
        }
        if (result.data.errorCode == 0) {
          this.project.programvalue = [];
          this.project.projectmgrvalue = [];
          this.project.apmvalue = [];
          this.project.srengmanagervalue = [];
          this.project.proleadvalue = [];
          this.project.modleadvalue = [];
          this.project.srsoluarchvalue = [];
          this.project.srbusianlyvalue = [];
          this.project.busianlyvalue = [];

          this.clearForm()
          this.viewRoles.projectName = result.data.data.length ? result.data.data[0].projectname : ''

          for (var i = 0; i < result.data.data.length; i++) {
            if (this.projectcurrentstate == "live") {
              if (result.data.data[i].roleid == "138") {
                this.project.programvalue.push(result.data.data[i].empid);
                this.viewRoles.programManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "139") {
                this.project.projectmgrvalue.push(result.data.data[i].empid);
                this.viewRoles.projectManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "140") {
                this.project.apmvalue.push(result.data.data[i].empid);
                this.viewRoles.APM.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "142") {
                this.project.srengmanagervalue.push(result.data.data[i].empid);
                this.viewRoles.srEnggManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "0") {
                this.project.engmanagervalue = null;
                this.viewRoles.enggManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "143") {
                this.project.proleadvalue.push(result.data.data[i].empid);
                this.viewRoles.projectLead.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "144") {
                this.project.modleadvalue.push(result.data.data[i].empid);
                this.viewRoles.moduleLead.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "141") {
                this.project.srsoluarchvalue.push(result.data.data[i].empid);
                this.viewRoles.srSolutionArch.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "0") {
                this.project.soluarchvalue = null;
                this.project.qamgrvalue = null;
                this.project.srqamgrvalue = null;
                this.project.srsecumgrvalue = null;
                this.viewRoles.solutionArch.push(result.data.data[i].employeename)
                this.viewRoles.qaManager.push(result.data.data[i].employeename)
                this.viewRoles.srQAmanager.push(result.data.data[i].employeename)
                this.viewRoles.srSecurityManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "146") {
                this.project.srbusianlyvalue.push(result.data.data[i].empid);
                this.viewRoles.srBusinessAnalyst.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "147") {
                this.project.busianlyvalue.push(result.data.data[i].empid);
                this.viewRoles.BusinessAnalyst.push(result.data.data[i].employeename)
              }
            } else {
              if (result.data.data[i].roleid == "138") {
                this.project.programvalue.push(result.data.data[i].empid);
                this.viewRoles.programManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "139") {
                this.project.projectmgrvalue.push(result.data.data[i].empid);
                this.viewRoles.projectManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "140") {
                this.project.apmvalue.push(result.data.data[i].empid);
                this.viewRoles.APM.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "142") {
                this.project.srengmanagervalue.push(result.data.data[i].empid);
                this.viewRoles.srEnggManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "0") {
                this.project.engmanagervalue = null;
                this.viewRoles.enggManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "143") {
                this.project.proleadvalue.push(result.data.data[i].empid);
                this.viewRoles.projectLead.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "144") {
                this.project.modleadvalue.push(result.data.data[i].empid);
                this.viewRoles.moduleLead.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "141") {
                this.project.srsoluarchvalue.push(result.data.data[i].empid);
                this.viewRoles.srSolutionArch.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "0") {
                this.project.soluarchvalue = null;
                this.viewRoles.solutionArch.push(result.data.data[i].employeename)
                this.project.qamgrvalue = null;
                this.viewRoles.qaManager.push(result.data.data[i].employeename)
                this.project.srqamgrvalue = null;
                this.viewRoles.srQAmanager.push(result.data.data[i].employeename)
                this.project.srsecumgrvalue = null;
                this.viewRoles.srSecurityManager.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "146") {
                this.project.srbusianlyvalue.push(result.data.data[i].empid);
                this.viewRoles.srBusinessAnalyst.push(result.data.data[i].employeename)
              } else if (result.data.data[i].roleid == "147") {
                this.project.busianlyvalue.push(result.data.data[i].empid)
                this.viewRoles.BusinessAnalyst.push(result.data.data[i].employeename)
              }
            }
          }
          this.project.iseditstate = true;
          this.roleassitabRowData = result.data.data[0];
        }
         else if(result.data.errorCode == 3){
          Swal.fire({
                title: "Session Expired...!!",
                text: result.data.msg,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
          }).catch(e => {
          this.displayError(e)
        })
        }
        this.addformbtntxt='Submit'
      });
    },
    validateForm(mdl, objThis) {
      this.clearerrorForm();
      this.isAddState = false;
      var isValid = true;
      if (mdl == "t11") {
        if (!this.project.projecttype) {
          this.errors.project.projecttype = "Project Type required";
          isValid = false;
        }
        if (!this.project.business) {
          this.errors.project.business = "Business required";
          isValid = false;
        }
        if (!this.project.company) {
          this.errors.project.company = "Company required";
          isValid = false;
        }
        /* if(!this.project.country){
                    this.errors.project.country="Country required";
                    isValid= false;
                } */
      } else if (mdl == "t15") {
        if (!this.projvalue) {
          this.errors.roleassign.proj = "Project required";
          isValid = false;
        }
        if (!this.project.rolevalue) {
          this.errors.roleassign.role = "Role required";
          isValid = false;
        }
        /* if(this.emplvalue == null || this.emplvalue == '' || this.emplvalue.length == 0){
                    this.errors.roleassign.empl="Employee required";
                    isValid= false;
                } */
      } else if (mdl == "t36") {
        /* if(!this.projectmodule.name){
                    this.errors.projectmodule.name="Project required";
                    isValid= false;
                } */
        if (
          this.projectmodule.project == null ||
          this.projectmodule.project == "" ||
          this.projectmodule.project.length == 0
        ) {
          this.errors.projectmodule.project = "Project required";
          isValid = false;
        }
      }
      return isValid;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    clearerrorForm() {
      this.errors = {
        project: {
          name: null,
          desc: null,
          projecttype: null,
          business: null,
          company: null,
          country: null,
        },
        roleallocation: {
          role: null,
          employee: null,
        },
        roleselection: {
          project: null,
          role: null,
        },
        roleassign: {
          proj: null,
          role: null,
          empl: null,
        },
        projectmodule: {
          name: null,
          project: null,
          desc: null,
          attachment: null,
          attachmentview: null,
          disblesavebtn: false,
        },
      };
    },
    clearForm() {
      this.viewRoles.projectName = null
      this.viewRoles.programManager = []
      this.viewRoles.projectManager = []
      this.viewRoles.APM = []
      this.viewRoles.srEnggManager = []
      this.viewRoles.enggManager = []
      this.viewRoles.projectLead = []
      this.viewRoles.moduleLead = []
      this.viewRoles.srSolutionArch = []
      this.viewRoles.solutionArch = []
      this.viewRoles.srQAmanager = []
      this.viewRoles.qaManager = []
      this.viewRoles.srSecurityManager = []
      this.viewRoles.srBusinessAnalyst = []
      this.viewRoles.BusinessAnalyst = []
    },
    openEditModule(currRowData) {
      this.clearForm()
      this.editRowData = currRowData;
      this.projvalue = currRowData.projectid
      this.getroleassignmentdata("t11")
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle) {
        $("#modulename" + inputEle).val(currRowData.modulename);
      }
      if (btnEle) {
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .addClass(btnEle);
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .attr("data-isedit", true);
      }
      $("#glbsearchinpele").val("");

      if (inputEle == "t11") {
        this.projecttabRowData = currRowData;
        this.project.projecttype = currRowData.projecttypeid;
        this.project.business = currRowData.businessverticleid;
        this.project.desc = currRowData.projectdescription;
        $("#businessname").val(currRowData.businessname);
        this.project.company = currRowData.companyid;
        this.project.name = currRowData.projectname;
        this.project.iseditstate = true;
      }
      
      this.isAddState = true;
    },
    dateFormat(value) {
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    getApplicationdata(mdl, curracttab) {
      this.isLoading = true;
      this.pageoffset = 1;
      this.inputappl = {
        tbl: mdl,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      let apiURL = "api/master/commonmaster/dropdownlist";
      if (
        curracttab == "project-tab" ||
        curracttab == "projectroleassign-tab"
      ) {
        this.inputappl = {
          tbl: mdl,
          useremail: this.undt.username,empcode: this.undt.userid,
        };
      }
      axios({
        method: "POST",
        url: apiURL,
        data: this.inputappl,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          let tmpClassNm = "";
          if (mdl == "t11") {
            if (curracttab == "projectrolesele-tab") {
              tmpClassNm = "projroleprojectdd";
              this.roleselection.projectlist = result.data.data.rows;
              return true;
            } else if (curracttab == "projectroleassign-tab") {
              tmpClassNm = "projroleassprojectdd";
              this.projoptions = result.data.data.rows;
              return true;
            }
          }
          $("." + tmpClassNm)
            .wrap('<div class="position-relative"></div>')
            .select2({
              dropdownAutoWidth: true,
              dropdownParent: $("." + tmpClassNm).parent(),
              width: "100%",
              data: this.projectType,
            });
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
    selroleassprojdata(state, value) {
      this.projvalue = null;
      this.projvalue = state;
      if (!state && this.isAddState == false) {
        this.errors.roleassign.proj = "Project required";
        this.roleoptions = null;
        this.rolevalue = null;
      } else {
        this.errors.roleassign.proj = null;
        
      }
    },
    selroleassroledata(state, value) {
      this.rolevalue = null;
      this.rolevalue = state;
      if (!state && this.isAddState == false) {
        this.errors.roleassign.role = "Role required";
      } else {
        this.errors.roleassign.role = null;
      }
    },
    getfiltereddata(tblNm) {
      this.isLoading = true;
      this.pageoffset = 1;
      var url = "api/master/commonmaster/customselect";
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
        empcode: this.undt.userid,
      };
      this.input.isSearch = true;
      this.input.section = "assignment";
      this.input.isFilterSelect = 1;
      this.input.isFilterPayload = {};
      if (tblNm == "projectempllist") {
        url = "api/listing/getallemoloyeedetails";
        this.project.programrolevalue = "138";
        this.project.projectmgrrolevalue = "139";
        this.project.apmrolevalue = "140";
        this.project.srengmanagerrolevalue = "142";
        this.project.engmanagerrolevalue = "0";
        this.project.proleadrolevalue = "143";
        this.project.modleadrolevalue = "144";
        this.project.srsoluarchrolevalue = "141";
        this.project.soluarchrolevalue = "0";
        this.project.qamgrrolevalue = "0";
        this.project.srqamgrrolevalue = "0";
        this.project.srsecumgrrolevalue = "0";
        this.project.srbusianlyrolevalue = "146";
        this.project.busianlyrolevalue = "147";
        if (this.projectcurrentstate == "live") {
          this.project.programrolevalue = "138";
          this.project.projectmgrrolevalue = "139";
          this.project.apmrolevalue = "140";
          this.project.srengmanagerrolevalue = "142";
          this.project.engmanagerrolevalue = "0";
          this.project.proleadrolevalue = "143";
          this.project.modleadrolevalue = "144";
          this.project.srsoluarchrolevalue = "141";
          this.project.soluarchrolevalue = "0";
          this.project.qamgrrolevalue = "0";
          this.project.srqamgrrolevalue = "0";
          this.project.srsecumgrrolevalue = "0";
          this.project.srbusianlyrolevalue = "146";
          this.project.busianlyrolevalue = "147";
        }
        this.input = {
          dept: "it",
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
      }
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.project.projectempllist = result.data.data;
          let decryptdesignationtext = this.project.projectempllist.map(items =>{
            let tempemplabel1 = items.label;
            let tempemplabel2 = items.designation ? this.decryptText(items.designation) : null;
            if(tempemplabel2 && tempemplabel2 != null){
                items.label = tempemplabel1 +'('+tempemplabel2 +')';
            }
            return items;
          })
        }
      }).catch(e => {
        this.displayError(e)
      });
    },
  },
};
</script>
