<template>
   <div class="app-content content ">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper pb-1">
      <section id="card-navigation modern-horizontal-wizard">
        <div class="content-header-left mb-1 pad_top_mar_bot-5">
          <div class="container-fluid">
            <div class="row breadcrumbs-top">
              <div class="col-md-9 content-header-left pl-0 ">
                <h3 class="content-header-title float-left mb-0">Employee Leave Transactions</h3>
                <div class="breadcrumb-wrapper">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item sitebluecolor cursorpointercls " ><router-link to='/reports/Employeeleavetransactions'>Employee Leave Transactions Report</router-link></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning filter_card_searchbgst" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important; margin-bottom: 1rem !important;">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="searchBoxCollapse">
            <div class="container-fluid" >
              <div class="row" >
                <div class="col-9 px-1 mobile-padding-0-2 d-flex align-items-center">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                      <search-icon size="1.5x" class="custom-class avatar-icon font-medium-3"></search-icon>
                    </div>
                  </div>
                <h4 class="card-title d-inline-flex ml-1">Search Employee Leave Transaction Details</h4>
                </div>
                <div class="ml-auto d-flex">
                <span class="rotetions_view_arrow ml-auto mx-1"> 
                <em class="las la-angle-down"></em>
              </span>
              </div>
              </div>
            </div>
          </div>
          <div v-bind:class="[{show: isSearchBoxActive},card_content,collapse]" >
            <div class="card-body pt-1 px-sm-mobile-10">
              <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4">
                  <label class="form-label lable-left">Employee Name</label>
                  <treeselect class="projectmdllistdd text-capitalize" placeholder="Enter min 3char Of Emplyee Name" v-model="search.EmployeeCode" :options="search.emplist"  :clear-on-select="true" v-on:search-change="empNameChange"/>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 form-group datePicker">
                  <label for="" class="d-block">From Date</label>
                  <date-picker placeholder="Select Action Start Date" v-model="search.actionstartdate" valueType="format" class="startdate" id="StartDate" :disabled-date="notBeforeToday"></date-picker>
                  <div class="errorinputmsg" v-if="this.errors.actionstartdate">{{ this.errors.actionstartdate }}</div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 form-group datePicker">
                  <label for="" class="d-block">To Date</label>
                  <date-picker placeholder="Select Action End Date" v-model="search.actionenddate" valueType="format" class="startdate" id="EndDate" :disabled-date="disabledBefore"></date-picker>
                  <div class="errorinputmsg" v-if="this.errors.actionenddate">{{ this.errors.actionenddate }}</div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-12 text-right">
                  <button type="submit" class="btn btn-relief-primary mt-2 ml-1" @click="getAllDomainData(search)"><search-icon size="1.5x" class="custom-class"></search-icon><span> Search</span></button>
                  <button type="reset" class="btn btn-outline-secondary mt-2 ml-1" @click="clearSearchFields()"><x-icon  size="1.5x" class="custom-class" ></x-icon><span> Clear</span></button>
                  <button type="move" class="btn btn-relief-primary mt-2 ml-1" @click="getAllDomainData(search, true)"><external-link-icon size="1.5x" class="custom-class"></external-link-icon><span> Export</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card border-warning" id="domain-list">
          <div class="card-body p-0">
            <div class="table-responsive" >
              <table class="table dataTableListing table-hover all_table_stickys" aria-describedby="mydesc">
                <thead>
                  <tr>
                    <th scope="col" class="text-center first-col-sticky" style="min-width:45px;">Sr. No</th>
                    <th scope="col" class="text-center second-col-sticky" style="min-width:160px;">Approved By</th>
                    <th scope="col" class="text-center" style="min-width:90px;">Leave From Date</th>
                    <th scope="col" class="text-center" style="min-width:90px;">Leave To date</th>
                    <th scope="col" class="text-center" style="min-width:100px;">Leave Type</th>
                    <th scope="col" class="text-center" style="min-width:100px;">Status</th>
                    <th scope="col" class="text-center">Number of days</th>
                    <th scope="col" class="text-center" style="min-width:165px">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(data,index) in domain.empLeaveTransactionList"  >
                    <tr v-bind:key="index" >
                      <td class="text-center first-col-sticky">{{ index+1 }}</td>
                      <td class="text-center text-capitalize second-col-sticky">{{data.approvedby ? data.approvedby : ''}}<br><span style="text-transform: uppercase">{{` (${data.Approver1code})`}}</span></td>
                      <td class="text-center text-capitalize">{{data.Applicationfromdate}}</td>
                      <td class="text-center text-capitalize">{{data.Applicationtodate}}</td>
                      <td class="text-center text-capitalize">{{data.LeaveTypeDescription}}</td>
                      <td class="text-center">{{data.Status}}</td>
                      <td class="text-center text-capitalize">{{data.Numberofdays}}</td>
                      <td class="text-center text-capitalize">{{data.Reason}}</td>
                    </tr>
                  </template>
                  <template v-if="domain.empLeaveTransactionList.length==0">
                    <tr><td colspan="14" style="text-align: center;">No record found</td></tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import apiUrl from '../../constants';
import VueElementLoading from 'vue-element-loading';
import Loading from 'vue-loading-overlay';
import Treeselect from '@riophae/vue-treeselect'
import DatePicker from "vue2-datepicker";
import moment from "moment";

import 'vue-loading-overlay/dist/vue-loading.css'
import "vue2-datepicker/index.css";
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { PlusCircleIcon, EditIcon, CheckCircleIcon, XCircleIcon, SearchIcon, ExternalLinkIcon, XIcon } from "vue-feather-icons";
import commonMethods from '../../utils/commonMethods';

export default {
  name: 'Employeeleavetransactions',
  components: {
    VueElementLoading,
    Loading,
    Treeselect,
    DatePicker,
    PlusCircleIcon,
    EditIcon,
    CheckCircleIcon,
    XCircleIcon,
    SearchIcon,
    ExternalLinkIcon,
    XIcon,
  },
  mixins: [ commonMethods ],

  data() {
    return {
      domain: {
        empLeaveTransactionList: [],
      },
      domainnamelist:[],
      search: {
        emplist: [],
        EmployeeCodeList: [],
        EmployeeCode: null,
        actionstartdate:null,
        actionenddate: null
      },
      errors: {
        actionstartdate: null,
        actionenddate: null
      },
      isactive: true,
      isSearchBoxActive: false,
      card_content:'card-content',
      collapse:'collapse',
      isLoading: false,
      undt: null,
      tokendata: null,
      totalcount: 0,
      pageCount: 0,
      page:1,
      pagelimit:apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset:1,
      pagefeatures:[],
      TimeFormat: apiUrl.TimeFormat,
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      let userdata = window.localStorage.getItem('userdata');
      if(userdata) {
        userdata = JSON.parse(userdata)
        this.undt = userdata
        this.tokendata = window.localStorage.getItem('token');
        this.search.EmployeeCode = this.undt.employeeid
        this.getAllDomainData()
        this.getEmpNameList(this.undt.userid, this.undt.employeeid)
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    notBeforeToday: function (date) {
      this.search.actionenddate = null;
      return date > new Date(new Date().setHours(0, 0, 0, 0));     
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.actionstartdate).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
      beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday;
    },
    getEmpNameList(empid) {
      axios({
        'method': 'POST',
        'url': 'api/manageemp/getAllEmplist',
        'data': {
          empid,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          searchedempid: this.search.EmployeeCode ? this.search.EmployeeCode.toLowerCase() : null,
        },
        'headers':{'authorization':this.tokendata}
      }).then(result => {
        // this.isLoading = false
        if(result.data.errorCode == 0) {
          this.search.emplist = result.data.data
          let decryptdepartmenttext = result.data.data.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
        } else {
          this.search.emplist = []
        }
      }).catch(e => {
        this.displayError(e)
      })
    },
    empNameChange: function (node, instanceId){
      if(node && node.length>=3){
        this.isLoading = true;
        let url = "api/manageemp/getAllEmplist";
        this.input = {
          empname: node.toLowerCase(),
          useremail: this.undt.username,
          empid: parseInt(this.undt.userid),
          empcode: parseInt(this.undt.userid),
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            let decryptdepartmenttext = result.data.data.map(items =>{
                let tempemplabel1 = items.label;
                let tempemplabel2 = items.department ? this.decryptText(items.department) : null;
                if(tempemplabel2 && tempemplabel2 != null){
                  items.label = tempemplabel1 +'('+tempemplabel2 +')';
                }
                return items;
              })
            this.search.emplist = result.data.data;
          } else {
            this.search.emplist = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    getAllDomainData(search, isExportedFlag) {
      if(!this.search.EmployeeCode) {
        Swal.fire({
          title: "",
          text: "Please select employee name, to fetch/export results.",
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
        this.domain.empLeaveTransactionList = []
        return
      }
      this.isLoading = true
      let url = 'api/Reports/Employeeleavetransactions'
      let payload = {
        "SubscriptionName": "firstcry",
        "Token": "a0c274f9b46b49ebb2eb63d56578ecc6",
        "EmployeeCode": this.search.EmployeeCode,
        useremail: this.undt.username,
        empcode: this.undt.userid
      }
      if(search) {
        payload.EmployeeCode = search.EmployeeCode// ? search.EmployeeCode : "bb-6653"
        payload.Actionfromdate = search.actionstartdate ? moment(search.actionstartdate).format('DD-MM-YYYY') : null
        payload.Actiontodate = search.actionenddate ? moment(search.actionenddate).format('DD-MM-YYYY') : null
      }
      // if(isExportedFlag) {
      //   payload.isExported = true
      // }
      axios({
        method: 'POST',
        url,
        data: payload,
        headers:{ authorization :this.tokendata }
      }).then(result => {
        this.isLoading = false
        // console.log(result)
        if(result.data.data.Code == 1) {
          let d = result.data.data.EmployeeLeaveTransactions
          this.domain.empLeaveTransactionList = d
          console.log(d)
          if(isExportedFlag) {
            this.download(d)
          }
          // this.totalcount = result.data.data.count
          // this.pageCount = Math.ceil(this.totalcount / this.pagelimit)
        } else {
          this.domain.empLeaveTransactionList = []
          // this.totalcount = 0
          // this.pageCount = 0
          if(isExportedFlag) {
            Swal.fire({
              title: "",
              text: "Data not found",
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            });
          } else {
            Swal.fire({
              title: "",
              text: result.data.data.Message,
              icon: "info",
              customClass: { confirmButton: "btn btn-primary" },
              buttonsStyling: !1,
            });
          }
        }
      }).catch(e => {
        this.isLoading = false
        Swal.fire({
          title: "",
          text: e,
          icon: "info",
          customClass: { confirmButton: "btn btn-primary" },
          buttonsStyling: !1,
        });
      }) 
    },
    download(readExportdata) {
      if(readExportdata.length > 0) {
        let JSONData = readExportdata
        let arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData
        let row = ''
        let CSV = 'Sr#, approvedby, Applicationfromdate, Applicationtodate, LeaveTypeDescription, Status, Numberofdays, Reason, Approver1Status' + '\r\n'
        for (let i=0; i<arrData.length; i++) {
          let index = arrData[i]
          let srno = i+1
          let approvedby = index.hasOwnProperty('approvedby') ? (index.approvedby?index.approvedby.replace(/"/g, '""'):'')  : ''
          let Applicationfromdate = index.hasOwnProperty('Applicationfromdate') ? (index.Applicationfromdate?index.Applicationfromdate.replace(/"/g, '""'):'') : ''
          let Applicationtodate = index.hasOwnProperty('Applicationtodate') ? (index.Applicationtodate?index.Applicationtodate.replace(/"/g, '""'):'') : ''
          let LeaveTypeDescription = index.hasOwnProperty('LeaveTypeDescription') ? index.LeaveTypeDescription : ''
          let Status = index.hasOwnProperty('Status') ? (index.Status?index.Status.replace(/"/g, '""'):'') : ''
          let Numberofdays = index.hasOwnProperty('Numberofdays') ? index.Numberofdays : ''
          let Reason = index.hasOwnProperty('Reason') ? index.Reason : ''              
          let Approver1Status = index.hasOwnProperty('Approver1Status') ? (index.Approver1Status?index.Approver1Status.replace(/"/g, '""'):'') : ''
  
          row += '"' + srno + '",'
          row += '"' + approvedby + '",'
          row += '"' + Applicationfromdate + '",'
          row += '"' + Applicationtodate + '",'
          row += '"' + LeaveTypeDescription + '",'
          row += '"' + Status + '",'
          row += '"' + Numberofdays + '",'           
          row += '"' + Reason + '",'
          row += '"' + Approver1Status + '",'
          row += '\r\n'
        }
        CSV += row
        if (CSV === '') {
          Swal.fire({
            title: "",
            text: "Data not found",
            icon: "info",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: !1,
          });
          return;
        }
        let fileName = 'employeeLeaveTransactions' + moment().format('DD_MM_YY_HH_mm_ss')
        let uri = 'data:text/csv;charset=utf-8,' + escape(CSV)
        let link = document.createElement('a')
        link.href = uri
        link.style = 'visibility:hidden'
        link.download = fileName + '.csv'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        Swal.fire({
          title: "Success!",
          text: "Domains Exported successfully",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });
      } else {
        Swal.fire({
          title: "",
          text: "Data not found",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: !1,
        });    
      }
    },
    dateMONFormat(value){
      if (!value) return "";
      return moment(String(value)).format('DD-MM-YYYY');
    },
    dateMonthFormat(value) {
      if (!value) return "NA";
      return moment(String(value)).format('DD MMM YYYY');
    },
    searchBoxCollapse() {
      this.isSearchBoxActive = !this.isSearchBoxActive
    },
    clearSearchFields() {
      // this.search.EmployeeCode = null
      this.search.actionstartdate = null
      this.search.actionenddate = null
      this.getAllDomainData()
    },
  }
}
</script>
<style scoped>
  .box-inc {
    min-width: 200px;
  }
</style>