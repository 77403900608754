<template>
  <div class="app-content content">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage"
      color="orange"
      loader="dots"
      :width="100"
      :height="100"
    ></loading>
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header-left col-md-9 col-12 mb-1">
        <div class="row breadcrumbs-top">
            <div class="col-12 px-0">
                <h3 class="content-header-title float-left mb-0">Manage Questions</h3>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><router-link to="/assessment/examsetting">Exam Setting</router-link></li>
                      <li class="breadcrumb-item"><router-link to='/assessment/ManageQuestions'> Question & Answer List</router-link></li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="card border-warning filter_card_searchbgst">
          <div class="card-header filter_rotation_onlick" style="cursor:pointer" v-on:click="mysearchrequestollapse">
            <div class="container-fluid">
            <div class="row row fc_my-05">
              <div class="w-auto px-1 mobile-padding-0-2">
                <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                        <search-icon
                        size="1.5x"
                        class="custom-class avatar-icon font-medium-3"
                        ></search-icon>
                    </div>
                </div>
                <h4 class="card-title d-inline-flex ml-1">Search Course & Assessments</h4>
            </div>
            <span class="rotetions_view_arrow ml-auto mx-1"> 
              <em class="las la-angle-down"></em>
            </span>
            </div>
          </div>
          </div>
          <div v-bind:class="[{show: isSearchRequestActive},card_content,collapse]">
            <div class="card-body pt-1">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Course code</label>
                    <input type="text" class="form-control" placeholder="Enter Course code" v-model="search.coursecode"/>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Course Name</label>
                    <treeselect class="capitalisetext" placeholder="Enter Min 4 Chars To Search Course Name"  v-model="search.courseid" :multiple="true" :clear-on-select="true" v-on:search-change="courseChange" :options="coursenamelist"></treeselect>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-1">
                    <label class="form-label lable-left">Created-By</label>
                    <treeselect class="projectmdllistdd" placeholder="Enter min 4 chars to search createdby" v-model="search.createdby" :options="search.projectcreatedbyopts" v-on:search-change="createdByChangeSearch"/>
                   </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0">
                    <label class="form-label lable-left">Assessment Title</label>
                    <treeselect  class="capitalisetext" placeholder="Select assessment title"  v-model="search.courseassessmentmasterid" :multiple="true" :clear-on-select="true" :options="question.asslist" />
                    </div>
                    <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4" >
                   <label for=""> Created From Date</label>
                    <date-picker placeholder="Select Created Date From" v-model="search.startdatefrom" :disabled-date="notBeforeToday" valueType="format" class="startdate" @input="selectedfromdate"></date-picker>
                  <div class="errorinputmsg" v-if="isBothDateSelected">Please select both dates to search.</div> 
                  </div>
                  <div class="form-group datePicker col-sm-6 col-md-6 col-lg-4">
                    <label for="">Created To Date</label>
                      <date-picker placeholder="Select Created Date To" v-model="search.startdateto" :disabled-date="disableDate" valueType="format" class="startdate" :default-value="new Date()" @input="selectedtodate"></date-picker>
                  </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 mb-0  mobile-px-0">
                    <button type="submit" class="btn btn-relief-primary mt-2 mr-1" :disabled="isBothDateSelected" @click="getquestionlist(search,'',1)"> 
                    <search-icon size="1.5x" class="custom-class"></search-icon> <span>Search</span></button>
                    <button type="reset" class="btn btn-outline-secondary mt-2 mr-1" @click="resetRecords()">
                    <x-icon  size="1.5x" class="custom-class"></x-icon> <span>Clear</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section id="card-navigation">
        <div class="file-manager-content-body">
          <div class="drives">
            <div class="card  filter_card_searchbgst" >
            <div class="card-header" style="background:#eee">
              <div class="container-fluid px-0" >
                <div class="row" >
                    <div class="w-auto px-1 mobile-padding-0-2">
                       <h4 class="card-title d-inline-flex ml-0 exam-header-padding-y6">Questions & Answer</h4>
                    </div>
                  <div v-if="pagefeatures.createquestion" class="ml-auto text-right">
                    <div class="ml-auto px-1 text-right mobile-padding-0-2">
                      <button
                        id="commonaddmodule"
                        class="btn btn-sm btn-relief-success"
                        data-toggle="modal"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :data-target="currentmodule"
                        @click="addModule($event)"
                        type="button"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                       <span> Create {{ glbMdlLbl }}</span> <plus-circle-icon  size="1.5x" class="custom-class"></plus-circle-icon> 
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card text-center mb-3">
                  <div class="">
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div class="row" id="table-small">
                          <div class="col-12">
                            <div class="card">
                              <div class="">
                                <div class="row">
                                  <div class="col-md-10 ">
                                    <div class="input-group-append">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="table-responsive" v-if="showtabledata">
                                <table class="table table-sm dataTableListing table-hover table-striped all_table_stickys" aria-describedby="mydesc">
                                  <thead>
                                    <tr>
                                      <th scope="col" class="srno first-col-sticky align-middle py-1" style="width:45px;">Sr#</th>
                                      <th scope="col" class="text-center align-middle second-col-sticky">Course Code</th>
                                      <th scope="col" class=" text-center align-middle" style="width:220px;">Course Name </th>
                                      <th scope="col" class="align-middle text-left" style="width:220px;">Assessment Title</th>
                                      <th scope="col" class="align-middle text-left" style="width:140px;">Question Title</th>
                                      <th scope="col" class="align-middle text-left" style="width:220px;">Answer Options</th>
                                      <th scope="col" class="align-middle">Answer</th>
                                      <th scope="col" class="align-middle">Status</th>
                                      <th scope="col" class="align-middle">Default</th>
                                      <th scope="col" class="clsaction align-middle">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <template  v-for="(data, index) in companymasterlist">
                                      <tr v-bind:key="index+Math.floor(Math.random() * 1000000000)">
                                        <td class="text-center first-col-sticky">{{ index+1+currPageNum*pagelimit }}</td>
                                        <td class=" text-capitalize text-nowrap second-col-sticky">{{data.coursecode}}</td>
                                        <!-- <td class="text-left second-col-sticky">
                                          <span class=" text-capitalize sitebluecolor cursor-pointer">{{data.coursename}}</span>
                                        </td> -->
                                        <td class=" text-capitalize">{{data.coursename}}</td>
                                        <td class="text-left">
                                          <span class="font-weight-bold ">{{data.assessmenttitle}}</span>
                                        </td>
                                        <!-- <td class="text-left">
                                          <span class="font-weight-bold ">{{data.questiontitle}}</span>
                                        </td> -->
                                        <td class="text-left tast_case_groupingview_readmore">
                                              <div class="col-md-12 mt-1 px-0 tast_case_groupingview_readmore">
                                                  <div class="custom-container case_bg_17">
                                                      <div v-if="data.questiontitle" class="outer" :id="'cycle_readmore3'+index">
                                                          <span class="fc_m16_btcode_text" v-html="formatData(data.questiontitle)"></span>
                                                          
                                                      </div>
                                                      <div v-else class="outer" :id="'cycle_readmore3'+index">
                                                          <span class="fc_m16_btcode_text">NA</span>
                                                      </div>
                                                      <div class="cycle_viewmoretext" v-if="data.questiontitle && data.questiontitle.length > 150">
                                                          <a class="btn">
                                                          <strong :id="'id3'+index" @click="idclick3(index)">
                                                  Read More
                                              </strong>
                                                          </a>
                                                      </div>
                                                  </div>
                                              </div>
                                          </td>

                                        <td class="text-left">
                                          <span class="font-weight-bold ">A: {{ data.optiona}}</span><br>
                                          <span class="font-weight-bold ">B: {{ data.optionb}}</span><br>
                                          <span class="font-weight-bold ">C: {{ data.optionc}}</span><br>
                                          <span class="font-weight-bold ">D: {{ data.optiond}}</span>
                                        </td>
                                        <td>
                                          <span class="font-weight-bold capitalisetext">{{data.answer}}</span>
                                        </td>
                                        <td>
                                          <span class="font-weight-bold capitalisetext" v-if="data.status !=1">Inactive</span>
                                          <span class="font-weight-bold capitalisetext" v-else-if="data.status ==1" >Active</span>
                                        </td>
                                        <td>
                                          <span class="font-weight-bold capitalisetext" v-if="data.isdefault !=1" >No</span>
                                          <span class="font-weight-bold capitalisetext" v-else-if="data.isdefault ==1">Yes</span>
                                        </td>                              
                                        <td>
                                          <div class="dropdown">
                                            <button
                                              type="button"
                                              class="btn btn-sm dropdown-toggle hide-arrow"
                                              data-toggle="dropdown"
                                              data-boundary="window"
                                            >
                                              <more-vertical-icon
                                                size="1.5x"
                                                class="custom-class"
                                              ></more-vertical-icon>
                                            </button>
                                            <div class="dropdown-menu">
                                              <a
                                                class="dropdown-item devEditEle"
                                                href="javascript:void(0);"
                                                data-toggle="modal"
                                                :data-target="currentmodule"
                                                @click="openEditModule(data)"
                                                data-backdrop="static" data-keyboard="false"
                                              >
                                                <edit-2-icon size="1.5x" class="custom-class mr-50"></edit-2-icon>
                                                <span> Edit</span>
                                              </a>
                                              <a class="dropdown-item devEditEle" v-on:click="getQuestionById(data)" href="javascript:void(0);">
                                              <div class="d-flex align-items-center"> 
                                              <eye-icon size="1.5x" class="custom-class mr-50" ></eye-icon>
                                              <span>View Question</span>
                                              </div>
                                            </a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </template>
                                    <template
                                      v-if="companymasterlist.length == 0 && isresponce"
                                    >
                                      <tr>
                                        <td colspan="14" style="text-align: center;">No record found</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-1 pageCenter" >
                      <pagination
                        v-if="showtabledata"
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </div>
     <!-- Add/Update Questions & Answer Start-->
    <div
      class="col-md-6 col-12 modal modal-slide-in fade col-md-6 col-12 show"
      id="projectmaster" 
    >
      <div class="modal-dialog sidebar-lg">
        <div class="modal-content p-0">
          <button
            id="closebtnt11"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            style="color: #ffff !important"
            @click="clearEditFields()"
          >
            ×
          </button>
          <div
            class="modal-header mb-1"
            style="background-color: #1f77b4 !important"
          >
            <h5 class="modal-title" style="color: #ffff !important">
              <plus-square-icon
                size="1.5x"
                class="custom-class"
              ></plus-square-icon>
              <span class="align-middle ml-50"
                >Add/Update Questions & Answer</span
              >
            </h5>
          </div>
          <div class="modal-body flex-grow-1 devUniqueForm">
            <form class="form form-vertical">
              <div class="row">
                <div id="addproject1st" v-if="project.currttbl == 't11'">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="message-text" class="col-form-label pt-0">Course Name</label>
                      <treeselect
                        class="projectmdllistdd"
                        placeholder="Select course name" :options="question.coursenamelist"
                        v-model="question.coursename" @input='selectCoursename' @blur="validateForm('t11',$event)" v-bind:disabled="coursedisabled"
                      />
                      <div class="errorinputmsg" v-if="!valid2Text(question.coursename) && courseBlured" >
                        Course name required
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="message-text" class="col-form-label pt-0">Assessment Title</label>
                      <treeselect
                        class="projectmdllistdd"
                        placeholder="Select assessment title" :options="question.assessmentlist"
                        v-model="question.id" @input='selectAssessment' @blur="validateForm('t11',$event)"/>
                      <div class="errorinputmsg" v-if="!valid2Text(question.id) && assessmentBlured" >
                        Assessment title is required
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group " style="margin-bottom:5px;">
                      <label for="modulenamet11">Question Title :</label>
                      <div class="input-group input-group-merge">
                        <input
                          style="border: 1px solid #d8d6de !important"
                          type="text"
                          class="form-control"
                          name=""
                          placeholder="Enter question title"
                          v-model="question.questionname"
                          v-bind:class="{'form-control': true,'': !validText(question.questionname), }"
                        />
                        <div class="errorinputmsg" v-if="!validText(question.questionname) && questionnameBlured">
                          Question title required
                        </div>
                      </div>
                    </div>
                    <div class="col-12 px-0 text-right add_question_butttons_design border-bottom">
                      <div class="form-group">  
                        <label for="attachment" class="btn btn-sm btn-relief-secondary mb-0 mr-75">Select Question Image</label>
                        <input type="file" id="attachment" hidden multiple ref="file" @change="onFileChange"/>
                        <div class="errorinputmsg" v-if="this.errors.question.attachment">{{ this.errors.question.attachment }}</div>
                        <button class="btn btn-sm btn-relief-success "  v-on:click.stop.prevent="onUpload()" id="attachmentbtn">Upload</button>
                      </div>

                      <div class="table-responsive my-75" v-if="file.length>0">
                          <table class="table table-sm table-bordered mb-0" aria-describedby="mydesc">
                              <thead>
                                  <tr>
                                      <th scope="col">Document Name</th>
                                      <th scope="col">Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(image, key) in file" :key="key">
                                      <td>{{ image.name }}</td>
                                      <td>
                                          <button type="button" class="btn btn-sm danger" @click="removeImage(key)">
                                              <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="table-responsive my-75" v-if="question.attachmentview">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc"> 
                              <thead>
                                    <tr>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, k) in question.attachmentview.data" :key="k">
                                        <td>
                                            <a :href="data.presignurl" target="_blank">{{
                                            data.link.substring(data.link.lastIndexOf('/')+1)
                                            }}</a>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removes3file(k,question.attachmentview.data)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                  </div>
                  <div class="col-12" v-for="(options, index) in optionFile" v-bind:key="index">
                        <div class="form-group">
                          <div class="col-12 px-0 my-1 d-flex"> 
                          <span class="question_options">{{options.optionTitle}} </span>
                             <div class="row w-100 add_from_questions ">
                            <div class="col-sm-12">
                            <input
                              style="border: 1px solid #d8d6de !important; margin-bottom:5px;"
                              type="text"
                              class="form-control"
                              name=""
                              placeholder="Enter option"
                              v-model="optionFile[index].optiontext"
                              @blur="blurEventHandler($event, index)"
                            />
                            <div>
                            <div class="errorinputmsg" v-if="optionFile[index].req_error && optionFile[index].reqBlured" >
                                {{options.optionTitle}} is required
                            </div>
                            </div>
                            </div>
                            <div class="col-sm-12 text-right px-1 add_question_butttons_design" >
                            <div class="form-group mb-0">  
                                  <label :for="'attachment'+index" class="btn btn-sm btn-relief-secondary mb-0 mr-75">{{options.optionTitle}} Image</label>  
                                  <input type="file" :id="'attachment'+index" hidden accept="image/*" ref="file" @change="onOptionFileChange($event,index)" v-bind:class="{'': !valid2Text(optionFile[index].file) && optionBlured }"
                                    v-on:blur="optionBlured = true"/>
                                  <button class="btn btn-sm btn-relief-success "  v-on:click.stop.prevent="onOptionUpload(index)" >Upload</button>

                            </div>
                          </div>
                          </div>
                        </div>
                        <div class="table-responsive my-75" v-if="optionFile[index].file[0]">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ optionFile[index].file[0].name }}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removeOptionImage(index)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive my-75" v-if="(optionFile[index].attachmentview.data)">
                            <table class="table table-sm table-bordered" aria-describedby="mydesc">
                                <thead>
                                    <tr>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-if="optionFile[index].attachmentview.data">
                                            <a target="_blank">{{
                                            optionFile[index].attachmentview.data[0].link.substring(optionFile[index].attachmentview.data[0].link.lastIndexOf('/')+1)
                                            }}</a>
                                        </td>
                                        <td>
                                            <button type="button" class="btn btn-sm danger" @click="removesOptionnfile(index)">
                                                <trash-2-icon size="1.5x" class="custom-class"></trash-2-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="devBusinessVerticleData">Answer :</label>
                      <treeselect class="projectmdllistdd" placeholder="Select answer option" v-model="question.answer" :options="optionslist" @input="selectoptionslist"
                          v-bind:class="{
                            '': true,
                            '':
                              !valid2Text(question.answer) && answerBlured,
                          }"
                      v-on:blur="answerBlured = true" />
                    <div class="errorinputmsg" v-if="!valid2Text(question.answer) && answerBlured" >
                        Answer required
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group mt-75">
                        <div class="custom-control custom-control-success custom-checkbox">
                            <input type="checkbox" class="custom-control-input" name="ismastertypeOptions" v-model="question.isdefault" checked  id="ismastertype0"  @click:="onActiveselect">
                            
                            <label class="custom-control-label" for="ismastertype0">Default</label>
                        </div>
                    </div>
                  </div>
                  <div class="col-12" v-if="this.question.questionid">
                    <div class="form-group">
                      <label for="devBusinessVerticleData">Status :</label>
                      <treeselect class="projectmdllistdd" placeholder="Select status" v-model="question.status" :options="statuslist" @input="selectstatuslist" @close="validateForm('t11',$event)"/>
                    <div class="errorinputmsg" v-if="this.errors.question.status">{{ this.errors.question.status }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-relief-primary mr-1"
                    v-bind:disabled="disblebtn"
                    v-on:click.stop.prevent="
                      savewizard(question.currttbl, $event)
                    "
                    :id="'submitnxtbtn' + question.currttbl"
                    :value="question.nextbtn"
                  >
                    {{addformbtntxt}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Add/Update Questions & Answer End-->
    <!-- View Question Details Start -->
    <div class="modal fade" id="technologypopup" tabindex="2" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;margin-bottom: 1.2rem">
              <h4 class="modal-title">View Question Details </h4>
              <button @click="clearViewModelData()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body flex-grow-1 devUniqueForm">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-md-12">
                  <ul class="timeline question_details_view-popup m-0">
                    <li class="timeline-item p-0"  v-for="(data) in taskversionlist" v-bind:key="data.questionid">
                    <div class="col-12 ">
                      <div class="form-group">
                        <div class="media-body">
                          <h6 class="transaction-title"> <span class="firstword_text"> Course Name </span> : <small class="badge badge-pill badge-light-warning">{{data.coursename}}</small></h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 ">
                      <div class="form-group">
                        <div class="media-body">
                          <h6 class="transaction-title"> <span class="firstword_text"> Question Id </span> : <small class="badge badge-pill badge-light-primary">Q.{{data.questionid}}</small></h6>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <div class="media-body">
                          <div class="transaction-title question_view_pop_images"> <span class="firstword_text ques"> Question Title </span> &nbsp;&nbsp;: <span class="firstword_text_right"> <small class="d-block">{{data.questiontitle}}</small> 
                           <div v-for="(dataque, kb) in viewModelQueImgs.data" class="question_images_pop" v-if="(viewModelQueImgs)">
                              <img alt="" v-if="imgExt.includes(dataque.link.substring(dataque.link.lastIndexOf('.')+1))" :src="dataque.presignurl" />
                          </div>
                          </span> </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <div class="media-body">
                          <h6 class="transaction-title"> Options :</h6>
                          <div class="media-body ml-3 question_mobile_view_mr">
                            <div class="row px-0 question_potions_pop02 mt-1 ">
                              
                              <div class="question_images_top_02">
                                <div class="question_images_text_top_02 mb-1">
                                  <span class="aphabetical_charater_pop">A</span>
                                  <small class="right_options_width ml-1">{{data.optiona}}</small>
                                </div> 
                                  <div v-for="(datab, kb) in viewModelOptionAImgs" v-if="(viewModelOptionAImgs)" class="question_images_pop2">
                                    <img alt="" v-if="imgExt.includes(datab.data[0].link.substring(datab.data[0].link.lastIndexOf('.')+1))" :src="datab.data[0].presignurl" />
                                </div>
                              </div>

                              <div class="question_images_top_02">
                                <div class="question_images_text_top_02 mb-1">
                                  <span class="aphabetical_charater_pop">B</span>
                                  <small class="right_options_width ml-1">{{data.optionb}}</small>
                                </div> 
                                  <div v-for="(datab, kb) in viewModelOptionBImgs" v-if="(viewModelOptionBImgs)" class="question_images_pop2">
                                    <img alt="" v-if="imgExt.includes(datab.data[0].link.substring(datab.data[0].link.lastIndexOf('.')+1))" :src="datab.data[0].presignurl" />
                                </div>
                              </div>

                              <div class="question_images_top_02">
                                <div class="question_images_text_top_02 mb-1">
                                  <span class="aphabetical_charater_pop">C</span>
                                  <small class="right_options_width ml-1">{{data.optionc}}</small>
                                </div> 
                                  <div v-for="(datab, kb) in viewModelOptionCImgs" v-if="(viewModelOptionCImgs)" class="question_images_pop2">
                                    <img alt="" v-if="imgExt.includes(datab.data[0].link.substring(datab.data[0].link.lastIndexOf('.')+1))" :src="datab.data[0].presignurl" />
                                </div>
                              </div>

                              <div class="question_images_top_02">
                                <div class="question_images_text_top_02 mb-1">
                                  <span class="aphabetical_charater_pop">D</span>
                                  <small class="right_options_width ml-1">{{data.optiond}}</small>
                                </div> 
                                  <div v-for="(datab, kb) in viewModelOptionDImgs" v-if="(viewModelOptionDImgs)" class="question_images_pop2">
                                    <img alt="" v-if="imgExt.includes(datab.data[0].link.substring(datab.data[0].link.lastIndexOf('.')+1))" :src="datab.data[0].presignurl" />
                                </div>
                              </div>
                            
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <div class="media-body">
                          <h6 class="transaction-title"> <span class="firstword_text"> Answer </span> :  <small>Option <span class="question_ansar_test"> {{data.answer}} </span> </small></h6>
                        </div>
                      </div>
                    </div>   
                  </li>
                  </ul>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    <!-- View Question Details End -->
    </div>
</template>   
<style>
.card .card-header {
  padding: 0.8rem;
}
.createbtnparentdiv {
  padding-right: 0%;
}
.displayblk {
  display: block !important;
}
.createbtn {
  border-color: #053e52 !important;
  color: #fff !important;
  background-color: #008000 !important;
  padding: 0.386rem 0.5rem !important;
}

.title {
  text-align: center;
}
</style>
<script>
import axios from "axios";
import apiUrl from "../../constants";
import VueElementLoading from "vue-element-loading";
import vSelect from "vue-select";
import moment from "moment";
import {ExternalLinkIcon,EditIcon,EyeIcon,SearchIcon,GridIcon,CodesandboxIcon,CloudIcon,ChromeIcon,BoxIcon,Link2Icon,PackageIcon,MoreVerticalIcon,Edit2Icon,PlusIcon,PlusCircleIcon,XIcon,PlusSquareIcon,Trash2Icon,CheckIcon } from "vue-feather-icons";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from "vue-pagination-2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import commonMethods from '../../utils/commonMethods';
export default {
  name: "Projects",
  components: {
    Loading,
    VueElementLoading,
    vSelect,
    EditIcon,
    EyeIcon,
    Treeselect,
    DatePicker,
    SearchIcon,
    XIcon,
    ExternalLinkIcon,
    GridIcon,
    CodesandboxIcon,
    CloudIcon,
    ChromeIcon,
    BoxIcon,
    Link2Icon,
    PackageIcon,
    Pagination,
    MoreVerticalIcon,
    Edit2Icon,
    PlusIcon,
    PlusSquareIcon,
    PlusCircleIcon,
    CheckIcon,Trash2Icon
  },
  mixins: [ commonMethods ],
  data() {
    return {
      imgExt:['jpeg','jpg','png'],
      projectnameList:[],
      createdbyOpts:[],
      isLoading: false,
      fullPage: true,
      companymasterlist: [],
      taskversionlist: null,
      showprojectdata: [],
      selected: null,
      editRowData: [],
      glbMdl: "",
      isRoleAlloc: "",
      glbst: "",
      TimeFormat: apiUrl.TimeFormat,
      glbMdlLbl: "company",
      projectdescription: "",
      isProjectMsr: "",
      statuslist:[
        {id:1,label:'Active'},
        {id:2,label:'Inactive'},
      ],
      optionslist:[
          {id:"A",label:"A"},
          {id:"B",label:"B"},
          {id:"C",label:"C"},
          {id:"D",label:"D"},
        ],
      question:{
        questionid:null,
        courseid:null,
        questionname: null,
        questionoptiona: null,
        questionoptionb: null,
        questionoptionc: null,
        questionoptiond: null,
        questionanswer: null,
        optionaimg: null,
        optionbimg: null,
        optioncimg: null,
        optiondimg: null,
        coursename: null,
        coursenamelist:null,
        statuslist: null,
        status: null,
        answer:null,
        desc:null,
        attachment:null,
        attachmentview:null,
        filedescp:[],
        attachmentnew:null,
        isdefault: 1,
        assessmenttitle:null,
        assessmentlist:[],
        id: null,
        asslist:[],
      },
      project: {
        name: null,
        desc: null,
        business: null,
        company: null,
        country: "india",
        currttbl: "t11",
        prevtbl: null,
        iseditstate: false,
        rolevalue: null,
        nextbtn: "next",
      },
      questionnameBlured: false,
      optionBlured: false,
      answerBlured: false,
      courseBlured: false,
      assessmentBlured: false,
      errors: {
        question: {
          questionname: null,
          questionoptiona: null,
          questionoptionb: null,
          questionoptionc: null,
          questionoptiond: null,
          questionanswer: null,
          optionaimg: null,
          optionbimg: null,
          optioncimg: null,
          optiondimg: null,
          coursename: null,
          optionslist: null,
          statuslist: null,
          status: null,
          answer:null,
          desc:null,
          attachment:null,
          assessmenttitle:null,
          assessmentlist:[],
          id: null
        },
      },
      isAddState: false,
      maxLength:20,
      totalcount: 0,
      pageCount: 0,
      page: 1,
      pagelimit: apiUrl.LIMIT,
      currPageNum: 0,
      pageoffset: 1,
      pagefeatures: [],
      currcalltype: "CAMP",
      allapicallauthkey: "",
      prevtabRowData: [],
      projecttabRowData: [],
      roleassitabRowData: [],
      coursenamelist:[],
      showtabledata: true,
      showproject: false,
      showcompanies: true,
      projectcurrentstate: "local",
      disablecompanydd: true,
      parentmoduledata: null,
      showfilterboxsection:false,
      currentmodule:'projectmaster',
      isSearchRequestActive:false,
      card_content:'card-content',
      collapse:'collapse',
      addformbtntxt:'Submit',
      tokendata: null,
      isProjLevelDis: false,
      isresponce:false,
      coursedisabled: false,
      file:[],
      optionFile:[
          {optiontext: null, attachmentnew:[],file:[],attachmentview:[], reqBlured: false ,req_error: null, optionTitle: 'A', id: 'optionaid', isupload: false},
          {optiontext: null, attachmentnew:[],file:[],attachmentview:[], reqBlured: false ,req_error: null, optionTitle: 'B', id: 'optionbid', isupload: false},
          {optiontext: null, attachmentnew:[],file:[],attachmentview:[], reqBlured: false ,req_error: null, optionTitle: 'C', id: 'optioncid', isupload: false},
          {optiontext: null, attachmentnew:[],file:[],attachmentview:[], reqBlured: false ,req_error: null, optionTitle: 'D', id: 'optiondid', isupload: false},
      ],
      disblebtn: false,
      holdcoursecode: null,
      holdassessmentid: null,
      isBothDateSelected:false,
      search:{
        courseassessmentmasterid: null,
        assessmenttitle: null,
        courseid: null,
        coursename: null,
        coursecode: null,
        createdby: null,
        projectcreatedbyopts: null,
        startdatefrom:null,
        startdateto:null,
      },
      viewModelOptionAImgs : [],
      viewModelOptionBImgs : [],
      viewModelOptionCImgs : [],
      viewModelOptionDImgs : [],
      viewModelQueImgs : [],
    };
  },
  created() {
    this.pagefeatures = {};
    if(window.localStorage.getItem("pagefeatures")){
      var pagefeatures1 = JSON.parse(
        window.atob(window.localStorage.getItem("pagefeatures"))
      );
      pagefeatures1.forEach((element) => {
        if (element.page === "/assessment/managequestions") {
          this.pagefeatures[element.featurename] = element.featureaccess;
        }
      });
    }
  },
  mounted() {
    let permission = this.$Service.verifyAccess();
    if(permission){
      this.tokendata = window.localStorage.getItem('token');
      var userdata = window.localStorage.getItem("userdata");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.undt = userdata;
        let rowdata={}
        this.getquestionlist(rowdata);
        this.getCoursename()
        this.getAsstitles()
      }
    }
    $(".filter_rotation_onlick").click(function(){
      $(this).toggleClass("common_arrow_rotetion");
    });
  },
  methods: {
    clearViewModelData(){
      this.viewModelOptionAImgs = [];
      this.viewModelOptionBImgs = [];
      this.viewModelOptionCImgs = [];
      this.viewModelOptionDImgs = [];
      this.viewModelQueImgs = [];
    },
    mysearchrequestollapse:function(){
      this.isSearchRequestActive=!this.isSearchRequestActive
    },
    selectoptionslist(state, value) {
      if(!state) {
        this.errors.question.answer = 'Answer option is required'
      } else {
        this.errors.question.answer = ''
      }
    },
    selectstatuslist(state, value) {
      if(!state) {
        this.errors.question.status = 'Status required'
      } else {
        this.errors.question.status = ''
      }
    },
    selectCoursename(state) {
        if(!state){
            this.errors.question.coursename = 'Course name is required'
        } else {
            this.errors.question.coursename = ''       
            this.getAssessentTitle(state);  
        }
    },
    selectAssessment(state) {
        if(!state){
            this.errors.question.assessmenttitle = 'Assessment title is required'
        } else {
            this.errors.question.assessmenttitle = ''       
        }
    },
    onActiveselect(event){
        this.question.isdefault = !this.question.isdefault
        if(event.target.checked == true){
            this.question.isdefault = 1
        }else{
            this.question.isdefault = 0
        }
    },
    getCoursename() {
        axios({
            method: "post",
            url: "api/managequestions/getCoursename",
            data: {empcode: this.undt.userid,useremail: this.undt.username},
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.question.coursenamelist = result.data.data.rows
                this.question.coursenamelist.sort((a, b) => {
                  return a.label.localeCompare(b.label);
                });
            } else {
                this.question.coursenamelist = []
            }
        }).catch(e => {
            this.displayError(e)
        });
    },
    courseChange: function (node){
        if(node && node.length>1){
          this.isLoading = true;
          let url = "api/coursemaster/getallcoursenamelist";
          this.input = {
          coursename:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
            method: "POST",
            url: url,
            data: this.input,
            'headers':{'authorization':this.tokendata}
          }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
              this.coursenamelist = result.data.data;
              this.coursenamelist.sort((a, b) => {
                 return a.label.localeCompare(b.label);
              });
            } else {
              this.coursenamelist = [];
            }
          });
        } 
      },
    getAsstitles() {
        axios({
            method: "post",
            url: "api/managequestions/getAsstitles",
            data: {empcode: this.undt.userid,useremail: this.undt.username},
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.question.asslist = result.data.data.rows
            } else {
                this.question.asslist = []
            }
        }).catch(e => {
            this.displayError(e)
        });
    },
    getAssessentTitle(courseid) {
        axios({
            method: "post",
            url: "api/managequestions/getAssessmenttitle",
            data:{courseid:courseid,empcode:this.undt.userid,useremail: this.undt.username},
            headers: { authorization : this.tokendata }
        }).then((result) => {
            this.isLoading = false;
            if (result.data.errorCode == 0) {
                this.question.assessmentlist = result.data.data.rows
            } else {
                this.question.assessmentlist = []
            }
        }).catch(e => {
          this.displayError(e)
        });
    },
    getQuestionById(currRowData) {
      this.editRowData = currRowData;
      this.isLoading = true
      let apiUrl = 'api/managequestions/getQuestionId'
      this.input.questionid = currRowData.questionid
      this.input.courseid = currRowData.courseid
      this.input.useremail = this.undt.username
      this.input.empid = this.undt.userid
      this.input.empcode = this.undt.userid
      axios({
        method: "POST",
        url: apiUrl,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      })
      .then(result => {
        this.isLoading = false
        $('#technologypopup').modal('show');
        if(result.data.errorCode == 0) {
          this.viewModelOptionAImgs = [];
          this.viewModelOptionBImgs = [];
          this.viewModelOptionCImgs = [];
          this.viewModelOptionDImgs = []; 
          this.viewModelQueImgs = [];
          this.taskversionlist = result.data.data
          
          if(result.data.data[0].questionimages && result.data.data[0].questionimages.hasOwnProperty('data')) {
              this.getDownloadLinkVM(result.data.data[0].questionimages);
          }
          if(result.data.data[0].optionaimg && result.data.data[0].optionaimg.hasOwnProperty('data')) {
              this.getDownloadOptionsLinkVMA(result.data.data[0].optionaimg);
          }
          if(result.data.data[0].optionbimg && result.data.data[0].optionbimg.hasOwnProperty('data')) {
              this.getDownloadOptionsLinkVMB(result.data.data[0].optionbimg);
          }
          if(result.data.data[0].optioncimg && result.data.data[0].optioncimg.hasOwnProperty('data')) {
              this.getDownloadOptionsLinkVMC(result.data.data[0].optioncimg);
          }
          if(result.data.data[0].optiondimg && result.data.data[0].optiondimg.hasOwnProperty('data')) {
              this.getDownloadOptionsLinkVMD(result.data.data[0].optiondimg);
          }
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          } else {
          this.taskversionlist = []
          this.viewModelOptionsImgs = [];
          this.viewModelQueImgs = [];
        }
      }).catch(e => {
          this.displayError(e)
        })
    },
    getquestionlist(rowdata) {
      this.isLoading = true;
      if(rowdata) {
        localStorage.setItem('questionsearchItems', JSON.stringify(rowdata))
      }
      this.parentmoduledata = rowdata;
      let setmoduleform = "projectmaster";
      this.currentmodule=`#`+setmoduleform
      let glbMdlLblval = "Question";
      this.companymasterlist = [];
      let tblNm = "t11";
      this.glbMdl = tblNm + "##" + setmoduleform;
      this.glbst = tblNm;
      if (setmoduleform) {
        this.glbMdlLbl = glbMdlLblval;
      }
      this.input = {
        tbl: tblNm,
        offset: this.pageoffset,
        limit: apiUrl.LIMIT,
        useremail: this.undt.username,
      };
      let search = JSON.parse(localStorage.getItem('questionsearchItems'))
        if(search) {
            this.input.courseid= this.search.courseid
            this.input.courseassessmentmasterid= this.search.courseassessmentmasterid
            this.input.coursecode= this.search.coursecode
            this.input.createdby  = this.search.createdby 
            this.input.datefrom = this.search.startdatefrom?this.search.startdatefrom:null
            let assignedate = ''
              if(this.search.startdateto){
                assignedate = new Date(this.search.startdateto)
                assignedate.setDate(assignedate.getDate() + 1)
                assignedate = moment(String(assignedate)).format('YYYY-MM-DD');
              }
            this.input.dateto = this.search.startdateto?assignedate:null
        }
      this.input.isSearch = false;
      let url = "api/managequestions/getQuestionList";
      this.input.section = "project";
      this.input.isFilterSelect = 1;
      this.input.empcode = this.undt.userid
      this.input.useremail= this.undt.username
      axios({
        method: "POST",
        url: url,
        data: this.input,
        'headers':{'authorization':this.tokendata}
      }).then((result) => {
        this.isLoading = false;
        if (result.data.errorCode == 0) {
          this.isresponce=true
          this.showcompanies = false;
          this.showproject = true;
          this.companymasterlist = result.data.data.rows;
          this.totalcount = result.data.data.count;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        } else if(result.data.errorCode == 3){
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: 'info',
              customClass: {
                  confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function() {
              window.location.href = "/#/login";
            })
          }else {
          this.showproject = true;
          this.companymasterlist = [];
          this.totalcount = 0;
          this.pageCount = Math.ceil(this.totalcount / this.pagelimit);
          setTimeout(function () {
            $("#commonaddmodule").attr("data-target", "#" + setmoduleform);
            $(".devEditEle").attr("data-target", "#" + setmoduleform);
          }, 1000);
        }
      }).catch(e => {
            this.displayError(e)
      });
    },
    createdByChangeSearch: function (node){
      if(node && node.length>1){
        this.isLoading = true;
        let url = "api/listing/getAllEmpEmailIds";
        this.input = {
          emailid:node.toLowerCase(),
          useremail: this.undt.username,
          empcode: this.undt.userid,
        };
        axios({
          method: "POST",
          url: url,
          data: this.input,
          'headers':{'authorization':this.tokendata}
        }).then((result) => {
          this.isLoading = false;
          if (result.data.errorCode == 0) {
            this.search.projectcreatedbyopts = result.data.data;
          } else {
            this.search.projectcreatedbyopts = [];
          }
        }).catch(e => {
        this.displayError(e)
      });
      }
    },
    disabledBefore(date) {
      let dayBefore = moment(this.search.startdatefrom).format(this.TimeFormat);
      const beforeToday = new Date(dayBefore);
              beforeToday.setHours(0, 0, 0, 0);
      return date < beforeToday; 
    },
    disableDate(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      let selectedFromDate = new Date(this.search.startdatefrom);
      selectedFromDate.setDate(selectedFromDate.getDate()-1);
      return date < selectedFromDate;
    },
    notBeforeToday: function (date) {
        this.search.startdateto = "";
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
    selectedfromdate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    selectedtodate(){
      if((this.search.startdatefrom && this.search.startdateto) || (!this.search.startdatefrom && !this.search.startdateto)){
          this.isBothDateSelected = false
      }else if((this.search.startdatefrom && !this.search.startdateto) ||( !this.search.startdatefrom && this.search.startdateto)){
          this.isBothDateSelected = true
      }
    },
    clickCallback: function (pageNum) {
      this.pageoffset = pageNum;
      this.currPageNum = pageNum - 1;
      this.getquestionlist(this.parentmoduledata);
      setTimeout(function(){
        $('html, body').animate({
            scrollTop: $("#myTabContent").offset().top -70
        });
      }, 500);
    },
    validText: function (inputval) {
      var re = /[!-A-Za-z0-9-_-|-~-].{0,}/;
      if (inputval) {
        return re.test(inputval.toLowerCase());
      }
    },
    valid2Text: function (inputval) {
      var re = /[A-Za-z0-9].{0,}/;
      if (inputval) {
        return re.test(inputval);
      }
    },
    validate: function (tblNm) {
      this.clearerrorForm();
      this.questionnameBlured = true;
      this.optionFile[0].reqBlured = true;
      this.optionFile[1].reqBlured = true;
      this.optionFile[2].reqBlured = true;
      this.optionFile[3].reqBlured = true;
      this.answerBlured = true;
      this.courseBlured = true;
      this.assessmentBlured = true;

      if (
        tblNm == "t11" &&
        this.validText(this.question.questionname) &&
        this.valid2Text(this.optionFile[0].optiontext) &&
        this.valid2Text(this.optionFile[1].optiontext) &&
        this.valid2Text(this.optionFile[2].optiontext) &&
        this.valid2Text(this.optionFile[3].optiontext) &&
        this.validText(this.question.questionanswer) &&
        this.validText(this.question.coursename)
      ) {
        this.valid = true;
      }
    },
    clearEditFields(){
      this.question.attachmentnew =null
      this.question.optionaimg =null
      this.question.optionbimg =null
      this.question.optioncimg =null
      this.question.optiondimg =null
      this.question.attachmentview =null
      this.optionFile[0].optiontext = null;
      this.optionFile[1].optiontext = null;
      this.optionFile[2].optiontext = null;
      this.optionFile[3].optiontext = null;
      this.optionFile[0].attachmentnew = [];
      this.optionFile[1].attachmentnew = [];
      this.optionFile[2].attachmentnew = [];
      this.optionFile[3].attachmentnew = [];
      this.optionFile[0].attachmentview = [];
      this.optionFile[1].attachmentview = [];
      this.optionFile[2].attachmentview = [];
      this.optionFile[3].attachmentview = [];
    },
    addModule(objThis) {
      this.isAddState = true;
      this.iseditstate= false;
      this.coursedisabled = false;
      this.question.questionid = null;
      this.questionnameBlured = null;
      this.optionBlured = null;
      this.courseBlured = null;
      this.answerBlured = null;
      this.assessmentBlured = null;
      this.errors.question.answer = null
      this.errors.question.status = null
      this.question.questionname = null;
      this.question.questionoptiona = null;
      this.question.questionoptionb = null;
      this.question.questionoptionc = null;
      this.question.questionoptiond = null;
      this.question.coursename = null;
      this.question.answer = null,
      this.question.status = null,
      this.question.desc =null
      this.question.attachmentnew =null
      this.question.optionaimg =null
      this.question.optionbimg =null
      this.question.optioncimg =null
      this.question.optiondimg =null
      this.question.attachmentview =null
      this.question.isdefault = 1;
      this.file= [];
      this.optionFile[0].file = [];
      this.optionFile[1].file = [];
      this.optionFile[2].file = [];
      this.optionFile[3].file = [];

      this.optionFile[0].req_error = null;
      this.optionFile[1].req_error = null;
      this.optionFile[2].req_error = null;
      this.optionFile[3].req_error = null;

      this.optionFile[0].reqBlured = null;
      this.optionFile[1].reqBlured = null;
      this.optionFile[2].reqBlured = null;
      this.optionFile[3].reqBlured = null;

      this.optionFile[0].optiontext = null;
      this.optionFile[1].optiontext = null;
      this.optionFile[2].optiontext = null;
      this.optionFile[3].optiontext = null;

      this.optionFile[0].attachmentnew = [];
      this.optionFile[1].attachmentnew = [];
      this.optionFile[2].attachmentnew = [];
      this.optionFile[3].attachmentnew = [];

      this.optionFile[0].attachmentview = [];
      this.optionFile[1].attachmentview = [];
      this.optionFile[2].attachmentview = [];
      this.optionFile[3].attachmentview = [];
      this.question.id = null;
      this.question.currttbl = "t11";
      this.isSearchRequestActive = true
    },
    blurEventHandler(e, index){
      if((e.target.value=='' || e.target.value==null) && (this.optionFile[index].file.length<1) &&(this.optionFile[index].attachmentview.length<1)){
          this.optionFile[index].req_error = 'Option '+ this.optionFile[index].optionTitle +' is required';  
          this.optionFile[index].reqBlured = true 
      }else{
        this.optionFile[index].req_error = null;
        this.optionFile[index].reqBlured = false;
      }
    },
    savewizard: function(mdl,objThis){ 
      this.valid = this.validateForm(mdl,objThis);
        if (this.valid) {
          this.isLoading = true;
            let tblNm='t11'
            if(mdl) tblNm=mdl
            this.input = {
              createdby: this.undt.username,
            }
            let apiURL='api/managequestions/createquestionanswer'
            this.input.attachment = this.question.attachmentnew
            this.optionFile.forEach((element, idx) => {
                if(element.optionTitle == 'A'){
                  if(element.attachmentnew.length>0){
                    this.input.optionaimg = JSON.stringify({data:[{"link": element.attachmentnew[0].link}]})
                  }else if((element.attachmentview.data != undefined) && (element.attachmentview.data.length>0)) {
                    this.input.optionaimg = JSON.stringify({data:[{"link": element.attachmentview.data[0].link}]})
                  }else{
                    this.input.optionaimg = null
                  }
                  this.input.questionoptiona=element.optiontext
                }

                if(element.optionTitle == 'B'){
                  if(element.attachmentnew.length>0){
                    this.input.optionbimg = JSON.stringify({data:[{"link": element.attachmentnew[0].link}]})
                  }else if((element.attachmentview.data != undefined) && (element.attachmentview.data.length>0)) {
                    this.input.optionbimg = JSON.stringify({data:[{"link": element.attachmentview.data[0].link}]})
                  }else{
                    this.input.optionbimg = null
                  }
                  this.input.questionoptionb=element.optiontext
                }
                if(element.optionTitle == 'C'){
                  if(element.attachmentnew.length>0){
                    this.input.optioncimg = JSON.stringify({data:[{"link": element.attachmentnew[0].link}]})
                  }else if((element.attachmentview.data != undefined) && (element.attachmentview.data.length>0)) {
                    this.input.optioncimg = JSON.stringify({data:[{"link": element.attachmentview.data[0].link}]})
                  }else{
                    this.input.optioncimg = null
                  }
                  this.input.questionoptionc=element.optiontext
                }
                if(element.optionTitle == 'D'){
                  if(element.attachmentnew.length>0){
                    this.input.optiondimg = JSON.stringify({data:[{"link": element.attachmentnew[0].link}]})
                  }else if((element.attachmentview.data != undefined) && (element.attachmentview.data.length>0)) {
                    this.input.optiondimg = JSON.stringify({data:[{"link": element.attachmentview.data[0].link}]})
                  }else{
                    this.input.optiondimg = null
                  }
                  this.input.questionoptiond=element.optiontext
                }
            })
            this.input.questionname=this.question.questionname
            this.input.coursename=this.question.coursename
            this.input.answer = this.question.answer
            this.input.id = this.question.id
            if (!this.question.status) {
              this.input.status = 1;
            } else {
              this.input.status = this.question.status;
            }
            if (this.question.isdefault == 1) {
              this.input.isdefault = 1;
            } else {
              this.input.isdefault = 0;
            }
            if (this.iseditstate == true) {
              apiURL = "api/managequestions/updateQuestion";
              this.input.questionid = this.question.questionid;
              if((this.question.attachmentnew !=null && this.question.attachmentnew.length>0) && this.question.attachment != null) {
                  let jsonobj1 = JSON.parse(this.question.attachmentnew);
                  let jsonobj2 = this.question.attachment;
                  if(jsonobj1 && jsonobj2) {
                      let arraymerge = [...jsonobj1.data,...jsonobj2.data]
                      this.input.attachment= JSON.stringify({data: arraymerge});
                  }
              }
              this.input.lastmodifiedby = this.undt.username;
              this.input.createdby = this.undt.username;
            }

            this.isAddState=false
            this.input.empcode = this.undt.userid
            this.input.useremail= this.undt.username
            axios({
            'method': 'POST',
            'url': apiURL,
            'data': this.input,
            'headers':{'authorization':this.tokendata}
            })
            .then(result => {
                this.isLoading = false;     
                if(result.data.errorCode == 0){
                  let rowdata = {}
                  this.getquestionlist(this.search);
                    $(".close").click();
                    this.file =[]
                    this.question.filedescp =[]
                    this.question.attachmentnew = null
                    this.question.attachment = null
                    this.optionFile[0].file = [];
                    this.optionFile[1].file = [];
                    this.optionFile[2].file = [];
                    this.optionFile[3].file = [];
                    this.optionFile[0].optiontext = null;
                    this.optionFile[1].optiontext = null;
                    this.optionFile[2].optiontext = null;
                    this.optionFile[3].optiontext = null;
                    this.optionFile[0].attachmentnew = [];
                    this.optionFile[1].attachmentnew = [];
                    this.optionFile[2].attachmentnew = [];
                    this.optionFile[3].attachmentnew = [];
                    this.optionFile[0].attachmentview = [];
                    this.optionFile[1].attachmentview = [];
                    this.optionFile[2].attachmentview = [];
                    this.optionFile[3].attachmentview = [];
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                } else if(result.data.errorCode == 3){
                    Swal.fire({
                      title: "Session Expired...!!",
                      text: result.data.msg,
                      icon: 'info',
                      customClass: {
                          confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: !1,
                    }).then(function() {
                      window.location.href = "/#/login";
                    })
                  }else {
                    Swal.fire({
                        title: "Failed",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            }).catch(e => {
          this.displayError(e)
        })
        }
    },
    validateForm(mdl, objThis) {
      this.clearerrorForm();
      this.isAddState = false;
      var isValid = true;
      this.questionnameBlured = true;
      this.optionFile[0].reqBlured = true;
      this.optionFile[1].reqBlured = true;
      this.optionFile[2].reqBlured = true;
      this.optionFile[3].reqBlured = true;
      this.answerBlured = true;
      this.courseBlured = true;
      this.assessmentBlured = true;
      this.optionFile.forEach((element, idx) => {
        if((this.optionFile[idx].optiontext == null || this.optionFile[idx].optiontext == '') && this.optionFile[idx].file.length<1 && this.optionFile[idx].attachmentview.length<1)
        {
          this.optionFile[idx].optiontext = null;
          this.optionFile[idx].req_error = 'Option '+ element.optionTitle +' is required';
          isValid = false;
        }
      });
      return isValid;
    },
    clearerrorForm() {
      this.errors = {
        question: {
          questionname: null,
          questionoptiona: null,
          questionoptionb: null,
          questionoptionc: null,
          questionoptiond: null,
          questionnameBlured: null,
          answerBlured:null,
          optionslist:null,
          statuslist:null,
          status:null,
          answer:null
        },
      };
    },
    openEditModule(currRowData) {
      this.question.currttbl = "t11";
      this.addformbtntxt='Submit'
      this.editRowData = currRowData;
      let arrGlbMdl = this.glbMdl.split("##");
      let inputEle = "";
      let btnEle = "";
      if (arrGlbMdl.length > 0) {
        if (typeof arrGlbMdl[0] != "undefined") {
          inputEle = arrGlbMdl[0];
        }
        if (typeof arrGlbMdl[1] != "undefined") {
          btnEle = arrGlbMdl[1];
        }
      }
      if (inputEle) {
        $("#modulename" + inputEle).val(currRowData.modulename);
      }
      if (btnEle) {
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .addClass(btnEle);
        $("#" + btnEle)
          .find("#submitbtn" + inputEle)
          .attr("data-isedit", true);
      }
      $("#glbsearchinpele").val("");
      if (inputEle == "t11") {
        this.projecttabRowData = currRowData;
        this.iseditstate = true;
        if (this.iseditstate = true) {
            this.coursedisabled = true;
            this.question.coursename = currRowData.courseid;
          }
        this.question.questionname = currRowData.questiontitle
        this.optionFile[0].optiontext = currRowData.optiona;
        this.optionFile[1].optiontext = currRowData.optionb;
        this.optionFile[2].optiontext = currRowData.optionc;
        this.optionFile[3].optiontext = currRowData.optiond;

        this.question.questionid = currRowData.questionid;
        this.question.answer = currRowData.answer;
        this.question.status = currRowData.status;
        this.question.isdefault = currRowData.isdefault
        this.question.id = currRowData.courseassessmentmasterid;
        this.question.attachment = currRowData.questionimages
        this.question.optionaimg = JSON.stringify(currRowData.optionaimg)
        this.question.optionbimg = JSON.stringify(currRowData.optionbimg)
        this.question.optioncimg = JSON.stringify(currRowData.optioncimg)
        this.question.optiondimg = JSON.stringify(currRowData.optiondimg)
        if(currRowData.questionimages && currRowData.questionimages.hasOwnProperty('data')) {
            this.getDownloadLink(currRowData.questionimages);
        }
        if(currRowData.optionaimg && currRowData.optionaimg.hasOwnProperty('data')) {
            this.getDownloadOptionsLink(currRowData.optionaimg, 'optionaimg');
        }
        if(currRowData.optionbimg && currRowData.optionbimg.hasOwnProperty('data')) {
            this.getDownloadOptionsLink(currRowData.optionbimg, 'optionbimg');
        }
        if(currRowData.optioncimg && currRowData.optioncimg.hasOwnProperty('data')) {
            this.getDownloadOptionsLink(currRowData.optioncimg, 'optioncimg');
        }
        if(currRowData.optiondimg && currRowData.optiondimg.hasOwnProperty('data')) {

            this.getDownloadOptionsLink(currRowData.optiondimg, 'optiondimg');
        }
        this.isProjLevelDis = true
      }
      this.isAddState = true;
    },
  onUpload(){ 
    if(this.file){
        this.isLoading=true
        if(this.file.length !== 0){
            this.apiURL="api/master/fileupload/uploadBulkFileToS3"
            let formData = new FormData();
            let maxsize = 0;
            for (let index = 0; index < this.file.length; index++) {
                formData.append("imagefile", this.file[index]);
                maxsize+= this.file[index].size
            }
            formData.append("empcode", this.undt.userid);
            formData.append("useremail", this.undt.username)
            formData.append("moduletype", 'tr_questionmaster');
            formData.append("fcount", parseInt(this.file.length));
            if (maxsize > 1000 * 1000 * 10) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 10mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.disblebtn = true
                return false; 
            } else {
                axios({
                    'content-type':'multipart/form-data',
                    'method': 'POST',
                    'url': this.apiURL,
                    'data':formData,
                    'headers':{'authorization':this.tokendata}
                })
                .then(result => { 
                    this.isLoading=false;            
                    if(result.data.status){
                      this.disblebtn=false
                        const bindinfo = result.data.data.map((file, index) => {
                          return {"link":file,"info":this.question.filedescp[index]}
                        });
                        let jsonobj = {data:bindinfo}
                        this.question.attachmentnew = JSON.stringify(jsonobj);
                        Swal.fire({
                          title: "Success!",
                            text: result.data.msg,
                            icon: 'success',
                            customClass: {
                              confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.optionFile.forEach((ele, idx) => {
                            if(ele.file.length>0 && ele.attachmentnew.length==0){
                              this.disblebtn = true;
                            }
                        });
                    } else {
                        Swal.fire({
                            title: "",
                            text: 'Failed to upload',
                            icon: 'info',
                            customClass: {
                                confirmButton: "btn btn-primary"
                            },
                            buttonsStyling: !1
                        })
                        this.question.attachment=null
                    }
                }).catch(e => {
              this.displayError(e)
            })
            }
        }else{
             this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Please Select Image',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
            this.question.attachment=null
        }
    } else {
        this.isLoading=false; 
        Swal.fire({
            title: "",
            text: 'Please Select Image',
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
        })
        this.question.attachment=null
    }
  },
  removeImage (index) {
    if(this.file.length>0) {
        this.file.splice(index, 1);
        this.question.filedescp.splice(index, 1);
        var removeimg = JSON.parse(this.question.attachmentnew)
        if(removeimg){
        var remove = removeimg.data.splice(index,1)
        if(remove.length > 0){
          this.question.attachmentnew = JSON.stringify({data:removeimg.data})
        }
        else{
          this.question.attachmentnew = null
        }
      }
    }
    if(this.file.length < 1) {
        this.disblebtn = false
    }            
  },
  removeOptionImage (index) {
    if(this.optionFile[index].file.length>0) {
        this.optionFile[index].file = [];
    }
    if(this.optionFile[index].file.length < 1) {
        this.disblebtn = false
    }            
  },
  removes3file(key,data) {
      if(data.length>0) {                
          Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to delete this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
              if (result.isConfirmed) {
                  if(this.editRowData.questionid !== undefined) {
                      let deletefile = data[key];
                      data.splice(key, 1);
                      const result = data.map(({presignurl,fileinfo,...rest}) => ({...rest}));
                      let payload = result.length > 0 ? JSON.stringify({ data: result }) : null;
                      this.input.empcode = this.undt.userid
                      this.input.useremail= this.undt.username
                      axios({
                      method: "POST",
                      url: 'api/managequestions/s3DeletequestionImage',
                      data: {
                          questionid:this.editRowData.questionid,
                          attachment:payload,
                          deletefile: JSON.stringify(deletefile) 
                      },
                      'headers':{'authorization':this.tokendata}
                  }).then((result) => {
                      if (result.data.status) {
                          Swal.fire(
                          'Deleted!',
                          result.data.msg,
                          'success'
                          )
                      } else {
                      Swal.fire(
                          'Deleted!',
                          result.data.msg,
                          'error'
                          )                        
                      }
                  });
                  } else {
                      Swal.fire(
                          'QuestionId!',
                          'QuestionId should not blank',
                          'info'
                          )
                  }    
              }
          })
      }
  },
  removesOptionnfile(index) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
          if (result.isConfirmed) {
             this.optionFile[index].attachmentview = [];
             Swal.fire(
              'File has been Deleted!',
             ) 
          }
      })
  },
  onFileChange(e) {
    let selectedFiles = e.target.files;
    for (let i=0; i < selectedFiles.length; i++)
    {
        var _validFileExtensions = [".jpg", ".jpeg", ".png"];    
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (selectedFiles[i].name.substr(selectedFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
          }
        }
        
        if (!blnValid) {
          Swal.fire({
                title: "ERROR",
                text: "Sorry, Filename is invalid, allowed extensions are: " + _validFileExtensions,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
          })
          return false;
        }
        this.file.push(selectedFiles[i]);
    }
    if(this.file.length>0) {
        this.disblebtn=true
    }
  },
  onOptionFileChange(e, index) {
    if(this.optionFile[index].file.length<1 && this.optionFile[index].attachmentview.length<1) {
      let selFiles = e.target.files;
      for (let i=0; i < selFiles.length; i++)
      {   
        var _validFileExtensions = [".jpg", ".jpeg", ".png"];    
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (selFiles[i].name.substr(selFiles[i].name.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true;
              break;
          }
        }
        
        if (!blnValid) {
          Swal.fire({
                title: "ERROR",
                text: "Sorry, Filename is invalid, allowed extensions are: " + _validFileExtensions,
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
          })
          return false;
        }
        this.optionFile[index].file.push(selFiles[i]);
      }
      this.optionFile[index].isupload = true;
      this.disblebtn = true
    } else {
      Swal.fire({
            title: "ERROR",
            text: 'Can not upload more than one file!',
            icon: 'info',
            customClass: {
                confirmButton: "btn btn-primary"
            },
            buttonsStyling: !1
      })
    }
  },
  onOptionUpload(id){ 
    if(this.optionFile[id].file.length){
        if(this.optionFile[id].file.length>1) {
            Swal.fire({
                  title: "ERROR",
                  text: 'Can not upload more than one file!',
                  icon: 'info',
                  customClass: {
                      confirmButton: "btn btn-primary"
                  },
                  buttonsStyling: !1
            })
        } else {
          this.isLoading=true
          this.optionFile[id].isUploading = true
          this.apiURL="api/master/fileupload/uploadBulkFileToS3"
          let formData = new FormData();
          let maxsize = 0;
          for (let index = 0; index < this.optionFile[id].file.length; index++) {
              formData.append("imagefile", this.optionFile[id].file[0]);
              maxsize+= this.optionFile[id].file[0].size
          }
          formData.append("empcode", this.undt.userid);
          formData.append("useremail", this.undt.username);
          formData.append("moduletype", 'tr_questionmaster');
          formData.append("fcount", parseInt(this.optionFile[id].file.length));
          if (maxsize > 1000 * 1000 * 10) {
                this.isLoading = false;
                Swal.fire({
                    title: "Failed",
                    text: "Your document uploaded above 10mb not allow",
                    icon: "info",
                    customClass: {
                    confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: !1,
                })
                this.isLoading=false
                this.disblebtn = true
                return false; 
          } else { 
            axios({
                'content-type':'multipart/form-data',
                'method': 'POST',
                'url': this.apiURL,
                'data':formData,
                'headers':{'authorization':this.tokendata}
            }).then(result => { 
                this.isLoading=false;
                if(result.data.status){
                    this.optionFile[id].attachmentnew = result.data.data.map((file, index) => {
                      return { link: file, info: this.optionFile[id].file[0] }
                    })
                    Swal.fire({
                        title: "Success!",
                        text: result.data.msg,
                        icon: 'success',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                    this.optionFile[id].isupload = false;
                    this.optionFile[id].reqBlured = false;
                    this.optionFile[id].req_error = null;
                    if(this.optionFile[0].isupload == false && this.optionFile[1].isupload == false && this.optionFile[2].isupload == false && this.optionFile[3].isupload == false){
                        this.disblebtn = false;
                    }else{
                        this.disblebtn = true;
                    }
                } else {
                  console.log('failed')
                    Swal.fire({
                        title: "",
                        text: result.data.msg,
                        icon: 'info',
                        customClass: {
                            confirmButton: "btn btn-primary"
                        },
                        buttonsStyling: !1
                    })
                }
            })
          }
        }     
      } else {
            this.isLoading=false; 
            Swal.fire({
                title: "",
                text: 'Please Select Image',
                icon: 'info',
                customClass: {
                    confirmButton: "btn btn-primary"
                },
                buttonsStyling: !1
            })
      }
  },
  getDownloadOptionsLink(data, option) {
      if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
          .then((result) => {
            if(result.data.status) {
                if(option=='optionaimg'){
                  this.optionFile[0].attachmentview = result.data;
                }
                if(option=='optionbimg'){
                  this.optionFile[1].attachmentview = result.data;
                }
                if(option=='optioncimg'){
                  this.optionFile[2].attachmentview = result.data;
                }
                if(option=='optiondimg'){
                  this.optionFile[3].attachmentview = result.data;
                }
            }
          }).catch(e => {
          this.displayError(e)
        });
      }
  },
  getDownloadLink(data) {
      if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
          .then((result) => {
          if(result.data.status) {
              this.question.attachmentview = result.data;
          }
          }).catch(e => {
          this.displayError(e)
        });
      }
  },
  getDownloadOptionsLinkVMA(data) {
      if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
              method: "POST",
              url: '/api/imageupload/getdownloadlink',
              data: payload,
              'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            if(result.data.status) {
              this.viewModelOptionAImgs.push(result.data);
            }
          }).catch(e => {
          this.displayError(e)
        });
      }
  },
  getDownloadOptionsLinkVMB(data) {
      if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid,
          };
          axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
            })
          .then((result) => {
            if(result.data.status) {
              this.viewModelOptionBImgs.push(result.data);
            }
          }).catch(e => {
          this.displayError(e)
        });
      }
  },
  formatData(data) {
            return data
        },
  idclick3(indexx){
            
            if ($("#cycle_readmore3"+indexx).hasClass("readmore")) {
                $("#id3"+indexx).html("Read More");
                $("#cycle_readmore3"+indexx).removeClass("readmore");
            } else {
                $("#id3"+indexx).html("Read Less");
                $("#cycle_readmore3"+indexx).addClass("readmore");
            }
        },
  getDownloadOptionsLinkVMC(data) {
      if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid
          };
          axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            if(result.data.status) {
              this.viewModelOptionCImgs.push(result.data);
            }
          }).catch(e => {
          this.displayError(e)
        });
      }
  },
  getDownloadOptionsLinkVMD(data) {
      if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid
          };
          axios({
                method: "POST",
                url: '/api/imageupload/getdownloadlink',
                data: payload,
                'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            if(result.data.status) {
              this.viewModelOptionDImgs.push(result.data);
            }
          }).catch(e => {
          this.displayError(e)
        });
      }
  },
  getDownloadLinkVM(data) {
      if(data.data.length>0) {
          let payload = {
          documents:data.data,
          useremail: this.undt.username,
          empcode: this.undt.userid
          };
          axios({
              method: "POST",
              url: '/api/imageupload/getdownloadlink',
              data: payload,
              'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
          if(result.data.status) {
              this.viewModelQueImgs = result.data;
          }
          }).catch(e => {
          this.displayError(e)
        });
      }
  },
  resetRecords: function() {
    this.search.coursename=null;
    this.search.courseid=null;
    this.search.createdby = null;
    this.search.coursecode = null;
    this.search.courseassessmentmasterid=null;
    this.search.startdatefrom = null;
    this.search.startdateto = null;
    this.search.assessmenttitle=null;
    this.holdcoursecode = null;
    this.holdassessmentid = null;
    this.isBothDateSelected = false
    localStorage.removeItem('questionsearchItems')
    this.getquestionlist();
  },
  },
};
</script>
