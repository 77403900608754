<template>
    <div class="app-content content">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <loading
        :active.sync="islistLoading"
        :can-cancel="false"
        :is-full-page="listfullPage"
        color="orange"
        loader="dots"
        :width="100"
        :height="100"
      ></loading>
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-header-left col-md-12 col-12 mb-1 pad_top_mar_bot-5 px-0">
          <div class="row breadcrumbs-top">
            <div class="col-9">
              <h4 class="content-header-title float-left mb-0">
                Bulk Uploaded History
              </h4>
              <div class="breadcrumb-wrapper">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">Bulk Uploaded History</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card border-warning filter_card_searchbgst">
          <div
            class="card-header filter_rotation_onlick"
            style="cursor: pointer"
            v-on:click="mysearchrequestollapse"
          >
            <div class="container-fluid">
              <div class="row row fc_my-05">
                <div class="w-auto px-1 mobile-padding-0-2">
                  <div class="avatar bg-light-secondary rounded d-inline-flex">
                    <div class="avatar-content">
                      <search-icon
                        size="1.5x"
                        class="custom-class avatar-icon font-medium-3"
                      ></search-icon>
                    </div>
                  </div>
                  <h4 class="card-title d-inline-flex ml-1">
                    Search Document Details
                  </h4>
                </div>
                <span class="rotetions_view_arrow ml-auto mx-1">
                  <em class="las la-angle-down"></em>
                </span>
              </div>
            </div>
          </div>
          <div v-bind:class="[{ show: isSearchRequestActive }, card_content, collapse]">
            <div class="card-body pt-1">
              <div class="row">
              </div>
            </div>
          </div>
        </div> -->
        <section id="card-navigation">
          <div class="file-manager-content-body">
            <div class="drives">
              <div class="row">
                <div class="col-md-12">
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div class="row" id="proModuleList">
                        <div class="col-12">
                          <div class="card">
                            <div class="table-responsive datatableview">
                              <table
                                class="table table-sm dataTableListing table-hover table-striped all_table_stickys"
                                aria-describedby="mydesc"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      class="align-middle srno first-col-sticky py-1"
                                      style="min-width: 45px"
                                    >
                                      Sr#
                                    </th>
                                    <th scope="col" class="align-middle second-col-sticky" style="width:300px;">File Name</th>
                                    <th scope="col" class="align-middle ">Uploaded By</th>
                                    <th scope="col" class="align-middle ">Uploaded Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <template v-for="(data, index) in docdetailslist">
                                    <tr v-bind:key="index">
                                      <td data-column="Sr#" class="srno first-col-sticky py-1">
                                        {{ index + 1 + currPageNum * pagelimit }}
                                      </td>
                                      <td
                                        data-column="Test Cycle Details"
                                        class="second-col-sticky sitebluecolor cursorpointercls" @click="getcsvDownloadLink(data.doclink)"
                                      >{{ data.doclink ? showmappingname(data.doclink) :'NA' }}
                                      </td>
                                      <td
                                        data-column="Test Cycle Details"
                                        class=""
                                      >{{ data.createdby ? data.createdby :'NA' }}
                                      </td>
                                      <td
                                        data-column="Test Cycle Details"
                                        class=""
                                      >{{ data.createddate ? dateMonthFormat(data.createddate) :'NA' }}
                                      </td>
                                    </tr>
                                  </template>
                                  <template v-if="docdetailslist.length == 0">
                                    <tr>
                                      <td colspan="14" style="text-align: center">
                                        No record found
                                      </td>
                                    </tr>
                                  </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pageCenter text-center">
                      <pagination
                        v-model="page"
                        :records="totalcount"
                        :per-page="pagelimit"
                        @paginate="clickCallback"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import apiUrl from "../../constants";
  import moment from "moment";
  import VueElementLoading from "vue-element-loading";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import Pagination from "vue-pagination-2";
  import {
    PlusCircleIcon,
    PlusSquareIcon,
    Edit2Icon,
    MoreVerticalIcon,
    EyeIcon,
    XIcon,
    ExternalLinkIcon,
  } from "vue-feather-icons";
  import { SearchIcon } from "vue-feather-icons";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import commonMethods from "../../utils/commonMethods";
  
  export default {
    name: "TestCycleSetProStatusList",
    mixins: [commonMethods],
    components: {
      Loading,
      VueElementLoading,
      Pagination,
      Edit2Icon,
      PlusCircleIcon,
      PlusSquareIcon,
      MoreVerticalIcon,
      EyeIcon,
      SearchIcon,
      XIcon,
      ExternalLinkIcon,
      Treeselect,
    },
    data() {
      return {
        card_content: "card-content",
        collapse: "collapse",
        isLoading: false,
        islistLoading: false,
        fullPage: true,
        listfullPage: true,
        isistLoading: false,
        totalcount: 0,
        pageCount: 0,
        page: 1,
        pagelimit: apiUrl.LIMIT,
        currPageNum: 0,
        pageoffset: 1,
        docdetailslist: [],
        isSearchRequestActive: false,
        tcnamelist: [],
        loginuseremail: "",
        pagefeatures: [],
      };
    },
    mounted() {
      // let permission = this.$Service.verifyAccess();
      // if(permission){
      var userdata = window.localStorage.getItem("userdata");
      this.tokendata = window.localStorage.getItem("token");
      if (userdata) {
        userdata = JSON.parse(userdata);
        this.loginuseremail = userdata.emailaddress;
        this.undt = userdata;
        this.getcsvuploadedfiledetails();
      }
      // }
      $(".filter_rotation_onlick").click(function () {
        $(this).toggleClass("common_arrow_rotetion");
      });
    },
    methods: {
      mysearchrequestollapse: function () {
        this.isSearchRequestActive = !this.isSearchRequestActive;
      },
      clickCallback: function (pageNum) {
        this.pageoffset = pageNum;
        this.page = pageNum;
        this.currPageNum = pageNum - 1;
        this.getcsvuploadedfiledetails();
        setTimeout(function () {
          $("html, body").animate({
            scrollTop: $("#myTabContent").offset().top - 70,
          });
        }, 500);
      },
      getcsvuploadedfiledetails() {
        this.islistLoading = true;
        this.input = {
          useremail: this.undt.username,
          empcode: this.undt.userid
        };
        axios({
          method: "POST",
          url: "api/roaster/getcsvuploadedfiledetails",
          data: this.input,
          headers: { authorization: this.tokendata },
        })
        .then((result) => {
          this.islistLoading = false;
          if (result.data.errorCode == 0) {
            this.docdetailslist = result.data.data.rows;
            for (let i = 0; i < this.docdetailslist.length; i++) {
              if(this.docdetailslist[i] && this.docdetailslist[i].filedata && this.docdetailslist[i].filedata.length >0){
                let parsedData = JSON.parse(this.docdetailslist[i].filedata);
                if(parsedData.data){
                  this.docdetailslist[i]['doclink'] = parsedData.data[0].link;
                }
            }
            }
            this.totalcount = result.data.data.rows.length;
          } else if (result.data.errorCode == 3) {
            Swal.fire({
              title: "Session Expired...!!",
              text: result.data.msg,
              icon: "info",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: !1,
            }).then(function () {
              window.location.href = "/#/login";
            });
          } else {
            this.docdetailslist = [];
            this.totalcount = 0;
          }
        })
        .catch((e) => {
          this.displayError(e);
        });
      },
      getcsvDownloadLink(link) {
        if(link) {
          let payload = {
            useremail: this.undt.username,
            empcode: this.undt.userid,
            link: link
          };
          axios({
            method: "POST",
            url: '/api/master/fileupload/getcsvDownloadLink',
            data: payload,
            'headers':{'authorization':this.tokendata}
          })
          .then((result) => {
            if(result.data.status) {
              window.open(result.data.data[0].presignurl, '_blank');
            }
          }).catch(e => {
            this.displayError(e)
          })
        }
      },
      dateMonthFormat(value) {
        if (!value) return "";
        return moment(String(value)).format("DD MMM YYYY HH:mm");
      },
      showmappingname(mappingname) {
        let responsestr = "";
        let testmap = mappingname.split('/');
        mappingname = testmap.slice(3).join('/');
        let prlist = mappingname.split(",");
        if (prlist.length > 1) {
          for (var i = 0; i < prlist.length; i++) {
            responsestr += i + 1 + ". " + prlist[i] + " <br/>";
          }
        } else {
          for (var i = 0; i < prlist.length; i++) {
            responsestr += prlist[i];
          }
        }
        return responsestr;
      },
    },
  };
  </script>
  